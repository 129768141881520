import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';
import { Provider } from 'react-redux';
import { Trans } from 'react-i18next';

import { store } from '../../../../';
import Logo from '../../../components/logo';
import { whitelabeledHost } from '../../../../utils/whitelabeledHost';
import '../styles.css';

const styles = {
  helpButton: {
    margin: 3,
    height: 48,
    width: 48,
    minWidth: 'none',
    backgroundColor: 'rgba(246,246,246,1)',
  },
  helpButtonLabel: {
    padding: 0,
    fontSize: '12px',
  },
  exitButton: {
    margin: '0 0.75rem',
  },
};

class SimpleToolbar extends Component {
  static propTypes = {
    title: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    lastVisited: PropTypes.string,
    displayLogo: PropTypes.bool,
    history: PropTypes.object.isRequired,
    onExit: PropTypes.func,
    integrationType: PropTypes.string,
    displayLogoutLink: PropTypes.bool,
  };

  static defaultProps = {
    title: null,
    lastVisited: null,
    displayLogo: true,
    onExit: undefined,
    integrationType: '',
    displayLogoutLink: false,
  };

  onExit = () => {
    const { onExit } = this.props;

    if (onExit) {
      return onExit();
    }

    return this.props.history.goBack();
  };

  getLogoLink = () => {
    if (!this.props.isAuthenticated || this.wixOutsideDashboard()) {
      return '/';
    }

    if (!this.props.lastVisited) {
      return '/create';
    }

    return `/${this.props.lastVisited}/editor`;
  };

  wixOutsideDashboard = () => {
    if (window.self === window.top && this.props.integrationType === 'Wix') {
      return true;
    }
    return false;
  };

  render() {
    const { title, displayLogo, isAppAdmin } = this.props;
    const isWhiteLabelledHost = whitelabeledHost();
    return (
      <Provider store={store}>
        <section className="toolbar simple-toolbar transparent">
          <div className="toolbar-group">
            <div className="logo-container">
              {!isWhiteLabelledHost && displayLogo && !isAppAdmin && (
                <Link to={this.getLogoLink()}>
                  <Logo width={90} />
                </Link>
              )}
            </div>
            {!isWhiteLabelledHost && displayLogo && !isAppAdmin && (
              <span className="seperator" />
            )}
          </div>
          <h1 className="toolbar-title">{title}</h1>
          <div className="toolbar-group">
            {/* <Link to="/help">
            <FlatButton
              style={styles.helpButton}
              labelStyle={styles.helpButtonLabel}
              label="Help"
            />
          </Link> */}
            {!isWhiteLabelledHost && displayLogo && !isAppAdmin && (
              <span className="seperator" />
            )}
            {!this.props.displayLogoutLink ? (
              <IconButton style={styles.exitButton} onClick={this.onExit}>
                <Close />
              </IconButton>
            ) : (
              <Link className="toolbar-logout" onClick={this.onExit} to="/">
                <Trans>Logout</Trans>
              </Link>
            )}
          </div>
        </section>
      </Provider>
    );
  }
}

export default SimpleToolbar;
