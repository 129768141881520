import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'material-ui/Slider';

// this will remove default material-ui styles so we can use css without needing to !important
const negatedStyles = {
  display: null,
  boxSizing: null,
  fontFamily: null,
  fontWeight: null,
  fontSize: null,
  padding: null,
  margin: null,
  // required to pass this
  backgroundColor: 'transparent',
  border: null,
  borderRadius: null,
  width: '100%',
};

const Div = styled.div`
  position: relative;
  width: ${props => props.width || '100%'};
  max-width: 100%;
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;

  > p {
    font-size: 12px;
    text-align: left;
    color: rgba(0, 0, 0, 0.4);
  }
`;

const Value = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
`;

const Range = props => (
  <Div {...props}>
    <Value>{props.label}</Value>
    <Slider
      style={negatedStyles}
      axis={props.axis}
      defaultValue={props.defaultValue}
      disableFocusRipple={props.disableFocusRipple}
      disabled={props.disabled}
      max={props.max}
      min={props.min}
      name={props.name}
      onChange={props.onChange}
      onDragStart={props.onDragStart}
      onDragStop={props.onDragStop}
      required={props.required}
      sliderStyle={negatedStyles}
      step={props.step}
      value={props.value}
    />
  </Div>
);

Range.propTypes = {
  axis: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableFocusRipple: PropTypes.bool,
  disabled: PropTypes.bool,
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDragStart: PropTypes.func,
  onDragStop: PropTypes.func,
  required: PropTypes.bool,
  sliderStyle: PropTypes.object,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
};

Range.defaultProps = {
  axis: 'x',
  defaultValue: 0,
  disableFocusRipple: false,
  disabled: false,
  max: 1,
  min: 0,
  name: '',
  onDragStart: null,
  onDragStop: null,
  required: true,
  sliderStyle: {},
  step: 0.01,
  style: {},
  label: null,
};

export default Range;
