import { validateField } from '../../../validators';
import firebase from '../../../../../../../firebase';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';
import { CPD_UPDATED } from '../../../config';
import { STAFF_ADMIN } from '../../../../../../../config';

export const EDIT_FIELD_CHANGE_CPD = 'EDIT_FIELD_CHANGE_CPD';
export const CLOSE_EDIT_DIALOG_CPD = 'CLOSE_EDIT_DIALOG_CPD';
export const UPDATE_REQUEST_CPD = 'UPDATE_REQUEST_CPD';
export const UPDATED_CPD = 'UPDATED_CPD';
export const LOADING_CPD = 'LOADING_CPD';
export const LOADED_DETAILS_CPD = 'LOADED_DETAILS_CPD';
export const RESET_CPD = 'RESET_CPD';

const fieldChange = (prop, id, value, isValid) => ({
  type: EDIT_FIELD_CHANGE_CPD,
  prop,
  id,
  value,
  isValid,
});

export const closeDialog = () => ({ type: CLOSE_EDIT_DIALOG_CPD });

export const resetData = () => ({
  type: RESET_CPD,
});

export const loadingCPD = () => ({
  type: LOADING_CPD,
});

export const loadCPD = prop => ({
  type: LOADED_DETAILS_CPD,
  prop,
});

export const formFieldChange = (isRequired, prop, id, value, index) => (
  dispatch,
  getState,
) => {
  let data = value;
  const valid = data ? validateField[prop](data) : null;
  const isValid = isRequired ? valid : true;
  // merge dates to array [fromDate, toDate]
  if (prop === 'DateRange') {
    const dateRange = getState().staff.cpds.edit.fieldsData[id].value;
    if (index === 0) {
      data = dateRange[1] ? [value, dateRange[1]] : [value, value];
    } else {
      data = dateRange[0] ? [dateRange[0], value] : [value, value];
    }
  }
  return dispatch(fieldChange(prop, id, data, isValid));
};

export const closeModal = (app, history, role) => dispatch => {
  dispatch(closeDialog());
  if (role === STAFF_ADMIN) {
    history.push(`/${app}/staff/cpds`);
  } else {
    history.push(`/${app}/members/cpds`);
  }
};

export const getCPDDetails = (appname, cpd, memberbody) => dispatch => {
  dispatch(loadingCPD());
  return firebase
    .database()
    .ref(`app_cpds/${appname}/cpds/${memberbody}/${cpd}`)
    .once('value')
    .then(snapshot => {
      const data = snapshot.exists() ? snapshot.val() : {};
      dispatch(loadCPD(data));
    });
};

export const updateCPD = (cpdID, history, appname, memberbody) => (
  dispatch,
  getState,
) => {
  dispatch({ type: UPDATE_REQUEST_CPD });
  const { fieldsData } = getState().staff.cpds.edit;
  const { role } = getState().account.profile;
  fieldsData.updatedAt = Date.now();
  fieldsData.memberbody = memberbody;
  return firebase
    .database()
    .ref(`app_cpds/${appname}/cpds/${memberbody}/${cpdID}`)
    .update(fieldsData)
    .then(() => {
      dispatch(closeModal(appname, history, role));
      dispatch({
        type: UPDATED_CPD,
        key: cpdID,
        data: fieldsData,
      });
      dispatch(openSnackbar(CPD_UPDATED));
    });
};
