import { combineReducers } from 'redux';

import memberBodies from './modules/memberBodies/reducer';
import events from './modules/events/reducer';
import cpds from './modules/cpds/reducer';
import courses from './modules/courses/reducer';
import eventsAttendees from './modules/events-attendees/reducer';
import coursesAttendees from './modules/courses-attendees/reducer';
import { structure } from './reducers';

export default combineReducers({
  memberBodies,
  events,
  cpds,
  courses,
  structure,
  eventsAttendees,
  coursesAttendees,
});
