import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CreateField from './CreateField';

import {
  addField,
  closeUserFilterFieldDialog,
  formFieldChange,
} from './actions';

const mapStateToProps = state => ({
  ...state.ecommerce.marketplace.filters.edit.createField,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () =>
    dispatch(
      addField(ownProps.match.params.appname, ownProps.match.params.categoryID),
    ),
  onRequestClose: () => dispatch(closeUserFilterFieldDialog()),
  onFieldChange: (field, value) => dispatch(formFieldChange(field, value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateField),
);
