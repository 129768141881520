import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import DraggableListWrapper from '../../../../../components/draggableListWrapper';
import ComponentItem from '../../../../../components/componentItem';
import DatePicker from '../../../../../components/datePicker';
import ItemContainer from '../../../../../components/itemContainer';

class TimeSlot extends Component {
  static propTypes = {
    order: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    timestamp: PropTypes.string,
    chunks: PropTypes.object,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onAddItem: PropTypes.func.isRequired,
    onRemoveSlot: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
    rearrangeItems: PropTypes.func.isRequired,
  };

  static defaultProps = {
    order: [],
    date: new Date(),
    timestamp: new Date(),
    chunks: {},
  };

  getComponentItems = () => {
    const {
      order,
      id,
      chunks,
      match,
      location,
      history,
      onRemoveItem,
      t,
    } = this.props;

    return order.map(item => (
      <ComponentItem
        id={item}
        key={item}
        label={(chunks[item] && chunks[item].title) || t('Untitled')}
        onClick={() =>
          history.push({
            pathname: `/${match.params.appname}/editor/build/item`,
            search: `${location.search}&itinerary=${id}&item=${item}`,
          })
        }
        onDeleteTooltip={t('Delete Item')}
        onDelete={() => onRemoveItem(item)}
      />
    ));
  };

  handleEditDate = () => this.datePicker.open();

  handleMove = (el, target, source, sibling) => {
    if (sibling) {
      return this.props.rearrangeItems(el.id, sibling.id);
    }

    return this.props.rearrangeItems(el.id, null);
  };

  render() {
    const { timestamp, onRemoveSlot, onAddItem, onDateChange } = this.props;

    const date = new Date(timestamp);
    return (
      <ItemContainer
        title={moment(date).format('Do MMM YYYY, h:mm a')}
        onClick={() => {}}
        onDelete={onRemoveSlot}
        onEdit={this.handleEditDate}
        onCreateTouchTap={onAddItem}
      >
        <DraggableListWrapper
          className="entry-container"
          items={this.getComponentItems()}
          onPositionChange={this.handleMove}
        />
        <DatePicker
          ref={node => {
            this.datePicker = node;
          }}
          onChange={onDateChange}
          ts={timestamp}
        />
      </ItemContainer>
    );
  }
}

export default withRouter(withTranslation()(TimeSlot));
