import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '../../../modules/account/components/modal';
import { CTAButton } from '../../components/buttons';
import './styles.css';

const Container = styled.div`
  padding: 0 15px 15px;
  text-align: center;
  /* flex-grow: 1; */
  /* display: flex;
  flex-flow: inherit; */
  display: grid;
  place-items: center;
  height: 30vh;
  /* .img_wrapper {
    position: relative;
    display: block;
    height: 65%;
    img {
      max-width: 65%;
      max-height: 60%;
      padding: 10px 20px 0;
    }
  } */
`;

const HalloweenOfferNotice = ({
  closeHalloweenOfferPopup,
  openHalloweenOfferPopup,
  showPopup,
  history,
  appname,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    openHalloweenOfferPopup();
  }, []);

  const handleClose = () => {
    closeHalloweenOfferPopup();
  };

  const onUpdate = () => {
    history.push(`/${appname}/choose_plan`);
    closeHalloweenOfferPopup();
  };
  const stateContentText = () => {
    const currentDate = moment().format('DD-MM-YYYY');

    let content = '';

    switch (currentDate) {
      case '27-10-2021':
        content = (
          <p>
            <Trans>
              Hello there! Remember that you only have 4 days to grab your
              exclusive 40% discount on all Beezer plans. The offer is valid
              until 00:00 am (GMT +01:00) on Sunday, 31st of October. Upgrade
              today to enjoy all
            </Trans>{' '}
            <a
              style={{ color: '#231CC4' }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.beezer.com/features-beezer/"
            >
              <Trans>integrations and premium features</Trans>
            </a>{' '}
            <Trans>for less.</Trans>
          </p>
        );

        break;
      case '28-10-2021':
        content = (
          <p>
            <Trans>
              Hello there! Remember that you only have 4 days to grab your
              exclusive 40% discount on all Beezer plans. The offer is valid
              until 00:00 am (GMT +01:00) on Sunday, 31st of October. Upgrade
              today to enjoy all
            </Trans>{' '}
            <a
              style={{ color: '#231CC4' }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.beezer.com/features-beezer/"
            >
              <Trans>integrations and premium features</Trans>
            </a>{' '}
            <Trans>for less.</Trans>
          </p>
        );

        break;
      case '29-10-2021':
        content = (
          <p>
            <Trans>
              Hello! Glad to see you around. Don’t forget to grab your spooky
              gift for Halloween by claiming your limited-time 40% discount on
              any Beezer plan. Hurry up - you’ve only got 3 days to get the
              offer! It will be valid until 00:00 am (GMT +01:00) on Sunday,
              31st of October.
            </Trans>
          </p>
        );

        break;
      case '30-10-2021':
        content = (
          <p>
            <Trans>
              Hello! This is a reminder that you only have 2 days left to claim
              your limited-time 40% discount on all monthly and yearly Beezer
              plans. Are you ready to get the full experience? See the list of
              all features included in our premium plans
            </Trans>{' '}
            <a
              style={{ color: '#231CC4' }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.beezer.com/pricing/"
            >
              <Trans>here</Trans>
            </a>
            .
          </p>
        );

        break;
      case '31-10-2021':
        content = (
          <p>
            <Trans>
              Hi! This is your last chance to claim your 40% discount on all
              Beezer plans - the offer expires at 00:00 am (GMT +01:00) today.
              Make the most out of our no-code editor without any limitations by
              upgrading today.
            </Trans>
          </p>
        );

        break;
      default:
        break;
    }
    return (
      <div id="offer-content">
        <center>
          <div id="offer-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/beezer-c947f.appspot.com/o/halloween%2FHalloween%20popup.png?alt=media&token=dfcba440-0f67-43ab-93c5-2e7b79a4f028" />
          </div>
        </center>

        {content}
      </div>
    );
  };

  return (
    <Modal
      open={showPopup}
      onRequestClose={handleClose}
      showHeader={true}
      bodyClassName="beezer-modal"
      modalHeight={'60vh'}
    >
      <Container>
        {stateContentText()}

        <CTAButton
          height={50}
          label={t('Upgrade today')}
          // borderColor="rgb(156, 150, 6)"
          outlineRGB={'195, 207, 47'}
          style={{ margin: '10px auto', display: 'block', maxWidth: '230px' }}
          onClick={onUpdate}
        />
      </Container>
    </Modal>
  );
};

export default HalloweenOfferNotice;
