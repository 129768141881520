import firebase from '../../../../../../../firebase';

const vimeoUrl = url => {
  const vimeoReg = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;
  const match = url.match(vimeoReg);

  if (match) {
    return match[3];
  }
  return null;
};

export const urlChange = (appname, dataId, urlString) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  const videoId = vimeoUrl(urlString);

  if (videoId) {
    dataRef.child('videoId').set(videoId);
  }

  dataRef.child('rawUrl').set(urlString);

  return {
    type: 'TEXT_VALUE_CHANGE',
    appname,
    dataId,
    field: 'rawUrl',
    urlString,
  };
};
