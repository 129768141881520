/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';
import { Trans, withTranslation } from 'react-i18next';
import {
  BILLING_CYCLE,
  STRIPE_CURRENCIES,
} from '../../../../../config/constants';
import TextField from '../../../../../app/components/textField';
import Toggle from '../../../../../app/components/toggle';
import { FlatButton } from '../../../../../app/components/buttons';
import CircularValidation from '../../../../../app/components/circularValidation';
import { CircularProgress } from '../../../../../app/components/progress';
import StaticInputLabel from '../../../../../app/components/inputLabel';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import SelectField from '../../../../../app/components/selectField';
import ImageUpload from '../../../../editor/internals/imageUpload';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ReactReduxContext, Provider } from 'react-redux';
import MenuItem from 'material-ui/MenuItem';
import { Link } from 'react-router-dom';
import SubscriptionFeatures from './features';
import { objectToArray, arrayToObject } from '../../../../../utils/object';
import './styles.css';

class NewGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySubscriptionError: false,
      subscriptionErrorMessage: '',
    };
  }

  userSubscriptionNotEnabled = (
    <div>
      <p className="subscription-error-msg">
        <Trans>Enable user subscriptions to add a subscription.</Trans>
      </p>
      <p className="subscription-link">
        <Link
          to={{
            pathname: `/${this.props.match.params.appname}/members/settings`,
            hash: '#user-app-subscriptions',
          }}
        >
          <Trans>Click here</Trans>
        </Link>{' '}
        <Trans>to go to user subscriptions</Trans>
      </p>
    </div>
  );

  stripeAccountNotAvailable = (
    <div>
      <p className="subscription-error-msg">
        <Trans>Add Stripe API keys to enable subscriptions</Trans>
      </p>
      <p className="subscription-link">
        <Link
          to={{
            pathname: `/${this.props.match.params.appname}/members/settings`,
            hash: '#user-app-subscriptions',
          }}
        >
          <Trans>Click here</Trans>
        </Link>{' '}
        <Trans>to go to user subscriptions and edit your Stripe API Keys</Trans>
      </p>
    </div>
  );

  stripeAccountError = (
    <div>
      <p className="subscription-error-msg">
        <Trans>
          Error creating subscription. Verify if your Stripe keys are correct
        </Trans>
      </p>
      <p className="subscription-link">
        <Link
          to={{
            pathname: `/${this.props.match.params.appname}/members/settings`,
            hash: '#user-app-subscriptions',
          }}
        >
          <Trans>Click here</Trans>
        </Link>{' '}
        <Trans>to go to user subscriptions and edit your Stripe API Keys</Trans>
      </p>
    </div>
  );

  componentDidMount() {
    this.props.loadContent(this.props.match.params.appname);
    this.props.getGroups(this.props.match.params.appname);
  }

  componentDidUpdate(prevProps) {
    if (this.props.order.value === 0) {
      this.props.onFieldChange(
        'order',
        Object.keys(this.props.groups).length + 1,
      );
    }
    if (this.props.error !== prevProps.error) {
      if (this.props.error) {
        this.setState({
          displaySubscriptionError: true,
          subscriptionErrorMessage: this.stripeAccountError,
        });
      } else {
        this.setState({
          displaySubscriptionError: false,
          subscriptionErrorMessage: '',
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.onComponentUnmount();
  }
  toggleSubscription = value => {
    if (this.props.isUserSubscriptionEnabled) {
      const { groups } = this.props;

      const formatGroups = objectToArray(groups);

      const subscriptionPlans = formatGroups.filter(item => {
        return item.value.subscriptionPlan;
      });

      if (this.props.isStripeAccountAdded) {
        this.setState({
          displaySubscriptionError: false,
          subscriptionErrorMessage: '',
        });
        this.props.onFieldChange('hasSubscriptionPlan', value);
        this.props.onFieldChange(
          'order',
          Object.keys(subscriptionPlans).length + 1,
        );
      } else {
        this.setState({
          displaySubscriptionError: true,
          subscriptionErrorMessage: this.stripeAccountNotAvailable,
        });
      }
    } else {
      this.setState({
        displaySubscriptionError: true,
        subscriptionErrorMessage: this.userSubscriptionNotEnabled,
      });
    }
  };

  onCloseDialog = () => {
    this.setState({
      displaySubscriptionError: false,
      subscriptionErrorMessage: '',
    });
    this.props.onRequestClose();
  };

  handleSubmit = async () => {
    const convertGroups = objectToArray(this.props.groups);
    if (
      this.props.order.value !== convertGroups.length + 1 &&
      this.props.hasSubscriptionPlan.value
    ) {
      const getPreviousGroupWithOrderNo = convertGroups.filter(
        item => item.value.order === this.props.order.value,
      );
      this.props.updateGroupData(
        this.props.match.params.appname,
        getPreviousGroupWithOrderNo[0].id,
        {
          order: convertGroups.length + 1,
        },
      );
    }
    this.props.onSubmit();
  };

  groupsWithSubscription = () => {
    const { groups } = this.props;

    const formatGroups = objectToArray(groups);

    const subscriptionPlans = formatGroups.filter(item => {
      return item.value.subscriptionPlan;
    });

    return subscriptionPlans.length > 0 ? arrayToObject(subscriptionPlans) : {};
  };

  render() {
    const {
      open,
      pending,
      error,
      onSubmit,
      name,
      order,
      price,
      currency,
      onFieldChange,
      description,
      billingType,
      billingCycle,
      hasSubscriptionPlan,
      match,
      groupImage,
      hasFreeTrial,
      freeTrialPeriod,
      groups,
      t,
      enableTax,
    } = this.props;

    const subscriptionPlans = this.groupsWithSubscription();

    const displayError = error ? (
      <div>
        <strong>{error.message}</strong>
      </div>
    ) : null;

    const buttonSubmit = !pending ? (
      <FlatButton
        label={t('Create')}
        onClick={this.handleSubmit}
        disabled={
          !name.isValid || (hasSubscriptionPlan.value && !price.isValid)
        }
        primary
      />
    ) : (
      <CircularProgress size={18} />
    );

    const infoTip = (
      <span>
        <span>
          <Trans>Add subscription plans for your users.</Trans>
        </span>{' '}
        <a
          href="https://beezer.zendesk.com/hc/en-us/articles/360049266472-Subscription-Manager"
          target="_blank"
          rel="noopener"
        >
          <Trans>Know More</Trans>
        </a>
      </span>
    );
    const orderTip = (
      <span>
        <span>
          <Trans>Change the default order of this subscription plan.</Trans>
        </span>
      </span>
    );
    return (
      <ReactReduxContext.Consumer>
        {ctx => (
          <Dialog
            open={open}
            onRequestClose={this.onCloseDialog}
            autoScrollBodyContent
          >
            <div className="create-group-form">
              <div className="modal-controls-container">
                <IconButton onClick={this.onCloseDialog}>
                  <Close />
                </IconButton>
              </div>
              <div className="list-fieldset">{displayError}</div>
              <div>
                <h5>
                  <Trans>Create a group</Trans>
                </h5>
                <TextField
                  id="name"
                  placeholder={t('Name')}
                  {...name}
                  onChange={value => onFieldChange('name', value)}
                  rightInputAction={
                    <CircularValidation
                      isValid={name.isValid}
                      defaultMsg={t("Group's name required")}
                      errorMsg={t('Name must be at least 2 characters long')}
                      showDefault={!name.value}
                    />
                  }
                />

                <div className={`ml-switch-container`}>
                  <span className="ml-switch-label">
                    <Trans>Subscription Plan</Trans>
                  </span>
                  <Toggle
                    style={{ margin: '0' }}
                    onToggle={(e, value) => {
                      this.toggleSubscription(value);
                    }}
                    toggled={hasSubscriptionPlan.value}
                  />
                  <Tooltip title={infoTip}>
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </div>

                {hasSubscriptionPlan.value && (
                  <React.Fragment>
                    <div className="item-order">
                      <SelectField
                        {...order}
                        className="offset-top"
                        label={t('Order')}
                        options={Array.from({
                          length: Object.keys(subscriptionPlans).length + 1,
                        }).map((_, index) => (
                          <MenuItem
                            key={index}
                            value={index + 1}
                            primaryText={index + 1}
                          />
                        ))}
                        onChange={(i, payload) =>
                          onFieldChange('order', payload)
                        }
                        autoWidth
                      />
                      <Tooltip title={orderTip}>
                        <InfoOutlinedIcon fontSize="small" />
                      </Tooltip>
                    </div>
                    <Provider store={ctx.store}>
                      <ImageUpload
                        title={t('Image for push notification')}
                        src={groupImage}
                        firebaseUrl={`/apps/${match.params.appname}/temp_data/groupImage`}
                        showCropAreaSizes={false}
                        height="220px"
                        width="320px"
                      />
                    </Provider>

                    <TextField
                      id="description"
                      placeholder={t('Description')}
                      {...description}
                      onChange={value => onFieldChange('description', value)}
                    />
                    <TextField
                      type="number"
                      min="0"
                      id="price"
                      placeholder={t('Price')}
                      {...price}
                      onChange={value => onFieldChange('price', value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      rightInputAction={
                        <CircularValidation
                          isValid={price.isValid}
                          defaultMsg={t('Price required')}
                          errorMsg={t('Price required')}
                          showDefault={!price.value}
                        />
                      }
                    />
                    <SelectField
                      {...currency}
                      className="offset-top"
                      label={t('Select currency')}
                      options={STRIPE_CURRENCIES.map(obj => (
                        <MenuItem
                          key={obj.value}
                          value={obj.value}
                          primaryText={obj.label}
                        />
                      ))}
                      onChange={(i, payload) =>
                        onFieldChange('currency', payload)
                      }
                      disabled={STRIPE_CURRENCIES.length === 0}
                      autoWidth
                    />

                    <div>
                      <StaticInputLabel>Billing cycle</StaticInputLabel>
                      <RadioButtonGroup
                        className="radio-button-group"
                        label={t('Show Popup on')}
                        name="showPopupAs"
                        onChange={(el, val) => {
                          onFieldChange('billingType', val);
                        }}
                        valueSelected={billingType.value}
                      >
                        {[
                          { label: t('One time'), value: 'Onetime' },
                          { label: t('Recurring'), value: 'Recurring' },
                        ].map(item => (
                          <RadioButton
                            key={item.value}
                            value={item.value.toLowerCase()}
                            label={item.label}
                            style={{
                              fontSize: 12,
                              lineHeight: 22,
                            }}
                          />
                        ))}
                      </RadioButtonGroup>
                    </div>
                    <div className={`ml-switch-container`}>
                      <span className="ml-switch-label">Enable Tax</span>
                      <Toggle
                        style={{ margin: '0' }}
                        onToggle={(e, value) =>
                          onFieldChange('enableTax', value)
                        }
                        toggled={enableTax.value}
                      />
                    </div>
                    {enableTax.value && (
                      <>
                        <span style={{ color: 'red', fontSize: '0.9rem' }}>
                          Note: payment feature won't work if tax details are
                          missing in stripe dashboard.
                        </span>
                        <br />
                        <a
                          href="https://stripe.com/docs/tax/set-up"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: 'underline',
                            fontSize: '0.9rem',
                          }}
                        >
                          check here to know about adding tax details.{' '}
                        </a>
                      </>
                    )}
                    {billingType.value === 'recurring' && (
                      <React.Fragment>
                        <SelectField
                          {...billingCycle}
                          className="offset-top"
                          label={t('Billing Period')}
                          options={BILLING_CYCLE.map(obj => (
                            <MenuItem
                              key={obj.value}
                              value={obj.value}
                              primaryText={obj.label}
                            />
                          ))}
                          onChange={(i, payload) =>
                            onFieldChange('billingCycle', payload)
                          }
                          disabled={BILLING_CYCLE.length === 0}
                          autoWidth
                        />

                        <div className={`ml-switch-container`}>
                          <span className="ml-switch-label">
                            <Trans>Free Trial</Trans>
                          </span>
                          <Toggle
                            style={{ margin: '0' }}
                            onToggle={(e, value) =>
                              onFieldChange('hasFreeTrial', value)
                            }
                            toggled={hasFreeTrial.value}
                          />
                        </div>
                        {hasFreeTrial.value && (
                          <TextField
                            id="free-trial"
                            placeholder={t('Free Trial Period')}
                            {...freeTrialPeriod}
                            onChange={value =>
                              onFieldChange('freeTrialPeriod', value)
                            }
                            rightInputAction={
                              <InputAdornment position="end">
                                <Trans>Days</Trans>
                              </InputAdornment>
                            }
                          />
                        )}
                      </React.Fragment>
                    )}
                    <SubscriptionFeatures {...this.props} />
                  </React.Fragment>
                )}
              </div>
              {buttonSubmit}
              {this.state.displaySubscriptionError &&
                this.state.subscriptionErrorMessage}
            </div>
          </Dialog>
        )}
      </ReactReduxContext.Consumer>
    );
  }
}

NewGroup.propTypes = {
  groups: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  name: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  onFieldChange: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  updateGroupData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

NewGroup.defaultProps = {
  error: null,
};

export default withTranslation()(NewGroup);
