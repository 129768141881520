import styled from 'styled-components';

const TextAreaContainer = styled.div`
  font-size: 13px;
  margin: 36px 0;
  margin: 24px auto;
  width: 324px;
`;

export default TextAreaContainer;
