import { connect } from 'react-redux';

import History from './History';
import {
  getPushHistory,
  changeSelected,
  filterPushHistory,
  deleteSelected,
  selectNotification,
  selectAllNotifications,
  deselectAllNotifications,
  sortTableData,
  cancelPush,
  fetchNotificationData,
} from './actions';
import { getGroups, getCountries } from '../push/actions';
import { openHistoryDetailDialog } from './historyDetails/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...ownProps.match.params,
  ...state.engage.history.data,
  accountId: state.account.auth.profile.account,
  subscription:
    state.account.subscriptions.items[ownProps.match.params.appname] || {},
  groups: state.auth.groups.data.chunks,
  push: state.engage.push,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPushHistory: () => dispatch(getPushHistory(ownProps.match.params.appname)),
  onChangeSelected: selected =>
    dispatch(changeSelected(ownProps.match.params.appname, selected)),
  filterPushHistory: filter => dispatch(filterPushHistory(filter)),
  onDeleteSelected: selected =>
    dispatch(deleteSelected(ownProps.match.params.appname, selected)),
  onSelect: key => dispatch(selectNotification(key)),
  onSelectAllResponses: () => dispatch(selectAllNotifications()),
  onDeselectAllResponses: () => dispatch(deselectAllNotifications()),
  onSort: cols => dispatch(sortTableData(cols)),
  onCancelPush: key => dispatch(cancelPush(key)),
  onOpenHistoryDetail: key => dispatch(openHistoryDetailDialog(key)),
  onFetchNotificationData: messageId =>
    dispatch(fetchNotificationData(ownProps.match.params.appname, messageId)),
  getGroups: () => dispatch(getGroups(ownProps.match.params.appname)),
  onGetCountries: () => dispatch(getCountries(ownProps.match.params.appname)),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
