import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';

import {
  getUsers,
  selectUser,
  selectAllUsers,
  deselectAllUsers,
  filterUserData,
  resetUsersData,
  deleteSelected,
} from 'modules/memberLogin/modules/users/actions';
import { getGroups } from 'modules/memberLogin/modules/groups/actions';

import Forum from './Forum';

import { updateChecked, addForumComponent } from './actions';
import { appHasComponent } from '../../../../utils';

const mapStateToProps = (state, ownProps) => {
  const forumComponentEnabled = appHasComponent(
    state.editor.structure,
    'Forum',
  );
  const appname = ownProps.match.params.appname;
  return {
    ...ownProps,
    ...state.auth.users,
    accountId: state.account.auth.profile.account,
    appname: _get(
      state,
      `editor.shared.appName.${appname}.meta.title`,
      appname,
    ),
    forumComponentEnabled,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getGroups: () => dispatch(getGroups(ownProps.match.params.appname)),
  getUsers: () => dispatch(getUsers(ownProps.match.params.appname)),
  onUpdateChecked: (hasForumAccess, user) =>
    dispatch(
      updateChecked(ownProps.match.params.appname, hasForumAccess, user),
    ),
  onAddForumComponent: () =>
    dispatch(
      addForumComponent(ownProps.match.params.appname, ownProps.history),
    ),

  onSelect: key => dispatch(selectUser(key)),
  onSelectAllUsers: () => dispatch(selectAllUsers()),
  onDeselectAllUsers: () => dispatch(deselectAllUsers()),
  onFilterUserData: filter => dispatch(filterUserData(filter)),
  resetUsersData: () => dispatch(resetUsersData()),
  onDeleteSelected: (selected, chunks) =>
    dispatch(deleteSelected(ownProps.match.params.appname, selected, chunks)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forum));
