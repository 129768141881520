import moment from 'moment';

const appendEvent = (state = {}, type, event) => {
  const timeKey = moment(event.timestamp).fromNow();
  const nextMap = new Map(state.eventMap);
  const nextEvents = [];

  const segment = nextMap.get(timeKey);

  if (!segment) {
    nextMap.set(timeKey, {
      [type]: 1,
      timestamp: event.timestamp,
    });
  } else if (segment[type] === undefined) {
    nextMap.set(timeKey, {
      ...segment,
      [type]: 1,
      timestamp: event.timestamp,
    });
  } else {
    nextMap.set(timeKey, {
      ...segment,
      [type]: segment[type] + 1,
      timestamp: event.timestamp,
    });
  }

  nextMap.forEach((value, timeAgo) =>
    nextEvents.push({
      ...value,
      timeAgo,
    }),
  );

  nextEvents.sort((a, b) => {
    const nameA = a.timestamp;
    const nameB = b.timestamp;
    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }

    return 0;
  });

  return {
    eventMap: nextMap,
    events: nextEvents,
  };
};

const eventsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ANALYTICS_EVENT': {
      const nextState = appendEvent(
        state[action.appname],
        action.eventType,
        action.value,
      );

      return {
        ...state,
        [action.appname]: nextState,
      };
    }

    case 'CLEAR_ANALYTICS_EVENTS':
      return {};

    default:
      return state;
  }
};

export default eventsReducer;
