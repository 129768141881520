import React from 'react';

import './styles.css';

const Loyalty = () => (
  <svg
    className="icon loyalty-icon"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 512.003 512.003"
    style={{ enableBackground: 'new 0 0 512.003 512.003' }}
    xmlSpace="preserve"
  >
    <g>
      <line
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        x1="7.503"
        y1="357.519"
        x2="7.503"
        y2="287.064"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        d="&#10;&#9;&#9;M264.805,12.965l20.628,41.661c2.48,5.008,7.239,8.498,12.76,9.358l46.962,7.312c8.079,1.258,11.174,11.259,5.219,16.861&#10;&#9;&#9;l-33.603,31.609c-4.214,3.964-6.151,9.781-5.155,15.48l8.229,47.095c1.42,8.125-7.2,14.276-14.422,10.291l-41.116-22.687&#10;&#9;&#9;c-5.169-2.852-11.44-2.852-16.609,0l-41.116,22.687c-7.221,3.985-15.841-2.167-14.422-10.291l8.229-47.095&#10;&#9;&#9;c0.996-5.699-0.942-11.516-5.155-15.48l-33.603-31.609c-5.955-5.602-2.86-15.603,5.219-16.861l46.962-7.312&#10;&#9;&#9;c5.522-0.86,10.281-4.35,12.76-9.358L247.2,12.965C250.807,5.68,261.198,5.68,264.805,12.965z"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        d="&#10;&#9;&#9;M66.822,319.868l-6.574,13.276c-0.79,1.596-2.307,2.708-4.066,2.982l-14.965,2.33c-2.574,0.401-3.561,3.588-1.663,5.373&#10;&#9;&#9;l10.708,10.073c1.343,1.263,1.96,3.117,1.643,4.933l-2.622,15.008c-0.452,2.589,2.295,4.549,4.596,3.28l13.103-7.23&#10;&#9;&#9;c1.647-0.909,3.646-0.909,5.293,0l13.103,7.23c2.301,1.27,5.048-0.69,4.596-3.28l-2.622-15.008c-0.317-1.816,0.3-3.67,1.643-4.933&#10;&#9;&#9;l10.708-10.073c1.898-1.785,0.911-4.972-1.663-5.373l-14.965-2.33c-1.76-0.274-3.276-1.386-4.066-2.982l-6.574-13.276&#10;&#9;&#9;C71.283,317.546,67.972,317.546,66.822,319.868z"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        d="&#10;&#9;&#9;M160.01,319.868l-6.574,13.276c-0.79,1.596-2.307,2.708-4.066,2.982l-14.965,2.33c-2.574,0.401-3.561,3.588-1.663,5.373&#10;&#9;&#9;l10.708,10.073c1.343,1.263,1.96,3.117,1.643,4.933l-2.622,15.008c-0.452,2.589,2.295,4.549,4.596,3.28l13.103-7.23&#10;&#9;&#9;c1.647-0.909,3.646-0.909,5.293,0l13.103,7.23c2.301,1.27,5.048-0.69,4.596-3.28l-2.622-15.008c-0.317-1.816,0.3-3.67,1.643-4.933&#10;&#9;&#9;l10.708-10.073c1.898-1.785,0.911-4.972-1.663-5.373l-14.965-2.33c-1.76-0.274-3.276-1.386-4.066-2.982l-6.574-13.276&#10;&#9;&#9;C164.471,317.546,161.159,317.546,160.01,319.868z"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        d="&#10;&#9;&#9;M253.197,319.868l-6.574,13.276c-0.79,1.596-2.307,2.708-4.066,2.982l-14.965,2.33c-2.574,0.401-3.561,3.588-1.663,5.373&#10;&#9;&#9;l10.708,10.073c1.343,1.263,1.96,3.117,1.643,4.933l-2.622,15.008c-0.452,2.589,2.295,4.549,4.596,3.28l13.103-7.23&#10;&#9;&#9;c1.647-0.909,3.646-0.909,5.293,0l13.103,7.23c2.301,1.27,5.048-0.69,4.596-3.28l-2.622-15.008c-0.317-1.816,0.3-3.67,1.643-4.933&#10;&#9;&#9;l10.708-10.073c1.898-1.785,0.911-4.972-1.663-5.373l-14.965-2.33c-1.76-0.274-3.276-1.386-4.066-2.982l-6.574-13.276&#10;&#9;&#9;C257.658,317.546,254.347,317.546,253.197,319.868z"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        d="&#10;&#9;&#9;M346.385,319.868l-6.574,13.276c-0.79,1.596-2.307,2.708-4.066,2.982l-14.965,2.33c-2.574,0.401-3.561,3.588-1.663,5.373&#10;&#9;&#9;l10.708,10.073c1.343,1.263,1.96,3.117,1.643,4.933l-2.622,15.008c-0.452,2.589,2.295,4.549,4.596,3.28l13.103-7.23&#10;&#9;&#9;c1.647-0.909,3.646-0.909,5.293,0l13.103,7.23c2.301,1.27,5.048-0.69,4.596-3.28l-2.622-15.008c-0.317-1.816,0.3-3.67,1.643-4.933&#10;&#9;&#9;l10.708-10.073c1.898-1.785,0.911-4.972-1.663-5.373l-14.965-2.33c-1.76-0.274-3.276-1.386-4.066-2.982l-6.574-13.276&#10;&#9;&#9;C350.846,317.546,347.534,317.546,346.385,319.868z"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        d="&#10;&#9;&#9;M439.572,319.868l-6.574,13.276c-0.79,1.596-2.307,2.708-4.066,2.982l-14.965,2.33c-2.574,0.401-3.561,3.588-1.663,5.373&#10;&#9;&#9;l10.708,10.073c1.343,1.263,1.96,3.117,1.643,4.933l-2.622,15.008c-0.452,2.589,2.295,4.549,4.596,3.28l13.103-7.23&#10;&#9;&#9;c1.647-0.909,3.646-0.909,5.293,0l13.103,7.23c2.301,1.27,5.048-0.69,4.596-3.28l-2.622-15.008c-0.317-1.816,0.3-3.67,1.643-4.933&#10;&#9;&#9;l10.708-10.073c1.898-1.785,0.911-4.972-1.663-5.373l-14.965-2.33c-1.76-0.274-3.276-1.386-4.066-2.982l-6.574-13.276&#10;&#9;&#9;C444.033,317.546,440.722,317.546,439.572,319.868z"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        d="&#10;&#9;&#9;M66.822,410.323l-6.574,13.276c-0.79,1.596-2.307,2.708-4.066,2.982l-14.965,2.33c-2.574,0.401-3.561,3.588-1.663,5.373&#10;&#9;&#9;l10.708,10.073c1.343,1.263,1.96,3.117,1.643,4.933l-2.622,15.008c-0.452,2.589,2.295,4.549,4.596,3.28l13.103-7.23&#10;&#9;&#9;c1.647-0.909,3.646-0.909,5.293,0l13.103,7.23c2.301,1.27,5.048-0.69,4.596-3.28L87.35,449.29c-0.317-1.816,0.3-3.67,1.643-4.933&#10;&#9;&#9;l10.708-10.073c1.898-1.785,0.911-4.972-1.663-5.373l-14.965-2.33c-1.76-0.274-3.276-1.386-4.066-2.982l-6.574-13.276&#10;&#9;&#9;C71.283,408.001,67.972,408.001,66.822,410.323z"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        d="&#10;&#9;&#9;M160.01,410.323l-6.574,13.276c-0.79,1.596-2.307,2.708-4.066,2.982l-14.965,2.33c-2.574,0.401-3.561,3.588-1.663,5.373&#10;&#9;&#9;l10.708,10.073c1.343,1.263,1.96,3.117,1.643,4.933l-2.622,15.008c-0.452,2.589,2.295,4.549,4.596,3.28l13.103-7.23&#10;&#9;&#9;c1.647-0.909,3.646-0.909,5.293,0l13.103,7.23c2.301,1.27,5.048-0.69,4.596-3.28l-2.622-15.008c-0.317-1.816,0.3-3.67,1.643-4.933&#10;&#9;&#9;l10.708-10.073c1.898-1.785,0.911-4.972-1.663-5.373l-14.965-2.33c-1.76-0.274-3.276-1.386-4.066-2.982l-6.574-13.276&#10;&#9;&#9;C164.471,408.001,161.159,408.001,160.01,410.323z"
      />

      <line
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        x1="96.731"
        y1="85.277"
        x2="118.752"
        y2="85.277"
      />

      <line
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        x1="116.19"
        y1="132.007"
        x2="131.752"
        y2="116.444"
      />

      <line
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        x1="116.08"
        y1="38.564"
        x2="131.651"
        y2="54.135"
      />

      <line
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        x1="415.274"
        y1="85.277"
        x2="393.253"
        y2="85.277"
      />

      <line
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        x1="395.815"
        y1="132.007"
        x2="380.252"
        y2="116.444"
      />

      <line
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        x1="395.925"
        y1="38.564"
        x2="380.354"
        y2="54.135"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        d="&#10;&#9;&#9;M504.503,287.064v182.438c0,19.33-15.67,35-35,35h-427c-19.33,0-35-15.67-35-35v-76.982"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        d="&#10;&#9;&#9;M317.44,162.812H469.5c19.33,0,35,15.67,35,35v42.66"
      />

      <line
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        x1="154.752"
        y1="162.812"
        x2="194.57"
        y2="162.812"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        d="&#10;&#9;&#9;M7.5,240.473v-42.66c0-19.33,15.67-35,35-35h76.252"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        d="&#10;&#9;&#9;M274.299,461.575h-36.594c-2.761,0-5-2.239-5-5v-36.594c0-2.761,2.239-5,5-5h36.594c2.761,0,5,2.239,5,5v36.594&#10;&#9;&#9;C279.299,459.336,277.061,461.575,274.299,461.575z"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        d="&#10;&#9;&#9;M367.487,461.575h-36.594c-2.761,0-5-2.239-5-5v-36.594c0-2.761,2.239-5,5-5h36.594c2.761,0,5,2.239,5,5v36.594&#10;&#9;&#9;C372.487,459.336,370.248,461.575,367.487,461.575z"
      />
      <path
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        d="&#10;&#9;&#9;M460.674,461.575h-36.594c-2.761,0-5-2.239-5-5v-36.594c0-2.761,2.239-5,5-5h36.594c2.761,0,5,2.239,5,5v36.594&#10;&#9;&#9;C465.674,459.336,463.436,461.575,460.674,461.575z"
      />

      <polyline
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        points="&#10;&#9;&#9;457.111,240.47 504.502,240.47 504.502,287.064 7.502,287.064 7.502,240.47 422.111,240.47 &#9;"
      />

      <polyline
        style={{
          fill: 'none',
          stroke: 'var(--primaryColor)',
          strokeWidth: '15',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '10',
        }}
        points="&#10;&#9;&#9;54.096,193.876 38.565,209.408 100.69,209.408 &#9;"
      />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export default Loyalty;
