import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from 'material-ui/LinearProgress';

import Logo from '../../logo';

import './styles.css';
import { whitelabeledHost } from '../../../../utils/whitelabeledHost';

const styles = {
  progress: {
    marginTop: '8px',
    width: '150px',
    height: '2px',
    borderRadius: '10px',
    backgroundColor: 'rgb(240, 240, 240)',
  },
};

class LoadingScreen extends Component {
  static propTypes = {
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: true,
  };

  state = {
    className: 'loading-screen',
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.loading === true && nextProps.loading === false) {
      this.onEnter();
    }
  }

  onEnter = () => {
    this.setState({ className: 'loading-screen enter' });

    this.timeout = setTimeout(
      () => this.setState({ className: 'loading-screen loaded' }),
      500,
    );
  };

  render() {
    const isWhiteLabeledHost = whitelabeledHost();

    return (
      <div className={`${this.state.className} background-texture`}>
        <div className="loading-widget">
          {!isWhiteLabeledHost && <Logo width={150} />}
          <LinearProgress color="#261ed5" style={styles.progress} />
        </div>
      </div>
    );
  }
}

export default LoadingScreen;
