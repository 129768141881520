import React from 'react';

const AppDomain = () => (
  <svg
    className="icon app-domain"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39 24"
  >
    <title>App Name</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M35.6,0H3.4A3.41,3.41,0,0,0,0,3.4V20.6A3.41,3.41,0,0,0,3.4,24H35.6A3.41,3.41,0,0,0,39,20.6V3.4A3.41,3.41,0,0,0,35.6,0ZM3.4,1.5H35.6a1.9,1.9,0,0,1,1.9,1.9V5.94H1.5V3.4A1.9,1.9,0,0,1,3.4,1.5Zm32.2,21H3.4a1.9,1.9,0,0,1-1.9-1.9V7.44h36V20.6A1.9,1.9,0,0,1,35.6,22.5Z"
        />
        <polygon
          class="cls-1"
          points="25.75 14.38 23.25 11.88 20.75 14.38 18.25 11.88 15.75 14.38 13.25 11.88 10.22 14.91 11.28 15.97 13.25 14 15.75 16.5 18.25 14 20.75 16.5 23.25 14 25.75 16.5 28.78 13.47 27.72 12.41 25.75 14.38"
        />
      </g>
    </g>
  </svg>
);

export default AppDomain;
