import firebase from '../../../../../../../firebase';

export const openCreateTabUI = () => ({ type: 'OPEN_CREATE_TAB_UI' });

export const closeCreateTabUI = () => ({ type: 'CLOSE_CREATE_TAB_UI' });

export const titleChange = value => ({
  type: 'CREATE_TAB_TITLE_CHANGE',
  value,
});

const resetForm = () => ({
  type: 'RESET_CREATE_TAB_FORM',
});

const creatingTab = () => ({
  type: 'CREATING_TAB',
});

export const createTab = (appname, page) => (dispatch, getState) => {
  dispatch(creatingTab());

  if (!appname || !page) return null;

  const pageRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${page}`);
  const tab = { title: getState().editor.build.create.createTab.title.value };
  let index;

  return pageRef
    .child('tabs')
    .transaction(value => {
      if (!value) {
        index = 0;

        return [tab];
      }

      index = value.length + 1;

      return [...value, tab];
    })
    .then(() => {
      dispatch(closeCreateTabUI());
      dispatch(resetForm());

      return Promise.resolve(index);
    });
};
