import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import Toggle from 'material-ui/Toggle';
import { useSelector } from 'react-redux';
import {
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import { TextField, Button, FormControl } from '@material-ui/core';
import {
  setMetaData,
  getMetaData,
  getStripeDetails,
  getAllAppointments,
} from '../../api';
import { STRIPE_CURRENCIES } from '../../../../../../config/constants';
import styles from './styles.module.scss';
import { whitelabeledHost } from '../../../../../../utils/whitelabeledHost';
import themeStyles from '../../../../../../_export.scss';
import { Trans, useTranslation } from 'react-i18next';

const TrackSwitchedColor = { backgroundColor: themeStyles.primaryColorLight };
const ThumbSwitchedColor = { backgroundColor: themeStyles.primaryColor };
const isWhiteLabelledHost = whitelabeledHost();

const MetaData = props => {
  const { t } = useTranslation();
  const { appname } = props;
  const { mutateAsync, isLoading } = useMutation(setMetaData);
  const { data, error, isLoading: isLoadingData, isError } = useQuery(
    ['ecommerceMetaData', { appname }],
    getMetaData,
  );
  const { data: stripeData, isLoading: stripeisLoading } = useQuery(
    ['ecommerceStripeSecret', { appname }],
    getStripeDetails,
  );

  const {
    data: appointmentsData,
    error: appointmentsError,
    isLoading: appointmentsLoading,
    isError: appointmentsIsError,
  } = useQuery(['appointmentItems', { appname }], getAllAppointments);

  const isAppAdmin = useSelector(
    state => state.account.auth.profile.admin_appname || false,
  );

  const isSubAdminWithMulipleApps = useSelector(
    state =>
      (state.account.auth.profile.admin_appnames &&
        Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
      false,
  );

  const [paidAppointments, setPaidAppointments] = useState([]);
  const [currency, setCurrency] = useState('GBP');
  const [stripePublishableKey, setStripePublishableKey] = useState('');
  const [stripeApiTestMode, setStripeApiTestMode] = useState('');
  const [stripeSecretKey, setStripeSecretKey] = useState('');
  const [mapApiKey, setMapApiKey] = useState('');

  const [email, setEmail] = useState('');
  const [hasError, setHasError] = useState(false);
  const [hasStripeErrror, setHasStripeError] = useState(false);
  const [profileFields, setProfileFields] = useState({
    firstNameRequired: false,
    lastNameRequired: false,
    dobRequired: false,
    phoneNumberRequired: false,
    emailRequired: false,
    showPaidBookingsOnly: false,
    showFreeBookingsOnly: false,
    filterByDate: false,
    filterByTitle: false,
  });

  const getPaidAppointments = appointments => {
    if (Object.keys(appointments).length === 0) return [];
    return Object.entries(appointments).filter(([, b]) => b.isBookingPaid);
  };

  useEffect(() => {
    if (data) {
      setCurrency(data.currency || 'GBP');
      setStripePublishableKey(data.stripePublishableKey || '');
      setStripeApiTestMode(data.stripeApiTestMode || '');
      setMapApiKey(data.mapApiKey || '');
      setEmail(data.email);
      setProfileFields({ ...profileFields, ...data.profileFields });
    }
  }, [data]);

  useEffect(() => {
    if (appointmentsData) {
      const result = getPaidAppointments(appointmentsData);
      setPaidAppointments(result);
    } else setPaidAppointments([]);
  }, [appointmentsData]);

  useEffect(() => {
    if (stripeData) {
      setStripeSecretKey(stripeData.stripeSecretKey || '');
    }
  }, [stripeData]);

  const handleSubmit = async e => {
    e.preventDefault();
    setHasStripeError(false);

    if (!currency) {
      setHasError(true);
      return;
    }

    if (
      (!stripeSecretKey || !stripePublishableKey) &&
      paidAppointments.length > 0
    ) {
      setHasStripeError(true);
      return;
    }

    if (hasError) setHasError(false);

    const data = {
      appname,
      currency,
      stripePublishableKey,
      stripeSecretKey,
      mapApiKey,
      email,
      stripeApiTestMode,
    };
    await mutateAsync({ ...data });
  };

  // const updateField = (value, key) => {
  //   const currentFields = { ...profileFields };
  //   currentFields[key] = value;
  //   setProfileFields(currentFields);
  // };

  if (isLoadingData || stripeisLoading || isLoading) {
    return (
      <div style={{ margin: 'auto' }}>
        <CircularProgress color="primary" size={40} />;
      </div>
    );
  }

  if (isError) {
    return (
      <span>
        <Trans>Error</Trans>: {error.message}
      </span>
    );
  }
  return (
    <div className="scroll-container">
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className={styles.settingContainer}>
          {!isAppAdmin && !isSubAdminWithMulipleApps && (
            <a
              href="https://beezer.zendesk.com/hc/en-us/articles/5842576009105-Booking-Settings"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'block',
                color: themeStyles.primaryColor,
                marginBottom: 18,
                marginTop: 5,
                fontWeight: 'bold',
              }}
            >
              💡{' '}
              <Trans>
                Find out more about how to set up the Bookings settings
              </Trans>
            </a>
          )}

          <div className={styles.settingTitle}>
            <Trans>Payment Settings</Trans>
          </div>
          <div className={styles.settingField}>
            <div>
              <Trans>Currency</Trans>
            </div>
            <FormControl style={{ minWidth: '300px' }}>
              <Select
                value={currency}
                onChange={e => setCurrency(e.target.value)}
                displayEmpty
                variant="standard"
              >
                {STRIPE_CURRENCIES.map(c => (
                  <MenuItem key={c.value} value={c.value}>
                    {c.label}
                  </MenuItem>
                ))}
              </Select>
              {hasError && (
                <FormHelperText style={{ color: 'red' }}>
                  <Trans>This is required!</Trans>
                </FormHelperText>
              )}
            </FormControl>
          </div>

          <div className={styles.settingField}>
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => setStripeApiTestMode(b)}
              toggled={stripeApiTestMode}
              label={t('Test Mode')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
          </div>
          <div className={styles.settingField}>
            <TextField
              id="stripePublishableKey"
              variant="standard"
              className={styles.field}
              value={stripePublishableKey}
              label={t('Stripe Publishable key')}
              onChange={e => setStripePublishableKey(e.target.value)}
            />
          </div>
          <div className={styles.settingField}>
            <TextField
              id="stripeSecretKey"
              variant="standard"
              type="password"
              autoComplete="off"
              className={styles.field}
              value={stripeSecretKey}
              label={t('Stripe Secret key')}
              onChange={e => setStripeSecretKey(e.target.value)}
            />
          </div>
          <a
            href="https://support.stripe.com/questions/getting-started-with-stripe-create-or-connect-an-account"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'block',
              color: themeStyles.primaryColor,
              marginBottom: 5,
              marginTop: 5,
              fontWeight: 'bold',
            }}
          >
            💡 <Trans>How to create a Stripe Business account?</Trans>
          </a>

          {/* <div className={styles.tax}>
            <h4>Enable Tax</h4>
            <Toggle
              toggled={isToggle}
              onToggle={(e, toggle) => setIsToggled(toggle)}
            />
            <label htmlFor="taxRate">Tax rate in %</label>
            <input
              id="taxRate"
              type="number"
              min="0"
              max="100"
              placeholder="8"
              disabled={!isToggle}
              value={taxRate}
              onChange={e => setTaxRate(e.target.value)}
              required={isToggle}
            />
          </div> */}
          <div className={styles.settingContainer}>
            <div className={styles.settingTitle}>
              <Trans>Map Settings</Trans>
            </div>
            <div className={styles.settingField}>
              <TextField
                id="mapApiKey"
                variant="standard"
                className={styles.field}
                value={mapApiKey}
                onChange={e => setMapApiKey(e.target.value)}
                label={t('Google Maps API key')}
              />
            </div>
          </div>
          {!props.isAppAdmin && !isWhiteLabelledHost && (
            <a
              href="https://beezer.zendesk.com/hc/en-us/articles/360030653652-How-to-create-Google-Maps-API-Key-and-Markers-"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'block',
                color: themeStyles.primaryColor,
                marginBottom: 5,
                marginTop: 0,
                fontWeight: 'bold',
              }}
            >
              💡 <Trans>How to create Google Maps API Key and Markers?</Trans>
            </a>
          )}

          <div className={styles.settingContainer}>
            <div className={styles.settingTitle}>
              <Trans>Notification settings</Trans>
            </div>
            <div className={styles.settingField}>
              <TextField
                id="notifEmail"
                variant="standard"
                className={styles.field}
                value={email}
                onChange={e => setEmail(e.target.value)}
                label={t('Email for booking updates')}
                placeholder="janedoe@gmail.com"
                required
              />
            </div>
          </div>

          {hasStripeErrror && (
            <span style={{ color: 'red', margin: '3vh 0' }}>
              Stripe API Keys are required for paid booking.
            </span>
          )}

          <Button
            type="submit"
            variant="contained"
            className={styles.submitBtn}
          >
            {isLoading ? t('SAVING...') : t('SAVE')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default MetaData;
