import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import EventList from './EventList';

import { updateData, addFilterItem } from './actions';
import { launchRemoveDialog } from '../../remove/actions';

const mapStateToProps = (state, ownProps) => ({ ...ownProps });

const mapDispatchToProps = (dispatch, ownProps) => ({
  onUpdateData: (key, value) =>
    dispatch(
      updateData(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        key,
        value,
      ),
    ),
  onAddItem: name =>
    dispatch(
      addFilterItem(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        name,
      ),
    ),
  onRemoveItem: item =>
    dispatch(
      launchRemoveDialog(
        ownProps.match.params.appname,
        {
          ...queryString.parse(ownProps.location.search),
          item,
        },
        'ITEM',
      ),
    ),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EventList),
);
