import {
  NEW_FIELD_CHANGE_EVENT,
  OPEN_NEW_DIALOG_EVENT,
  CLOSE_NEW_DIALOG_EVENT,
  ADD_NEW_EVENT,
  ADDED_EVENT,
  SET_RECURRING_DATA,
} from './actions';

import { INITIATE_FIELDS_EVENT } from '../../actions';

const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  fieldsData: {},
  isRecurringEvent: false,
  recurringType: 'weeks',
  recurringValue: 2,
};

const newEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIATE_FIELDS_EVENT:
      return {
        ...state,
        fieldsData: action.value,
        fieldsMockData: action.value,
      };

    case OPEN_NEW_DIALOG_EVENT:
      return {
        ...state,
        open: true,
      };

    case CLOSE_NEW_DIALOG_EVENT:
      return {
        ...state,
        open: false,
      };

    case NEW_FIELD_CHANGE_EVENT:
      return {
        ...state,
        fieldsData: {
          ...state.fieldsData,
          [action.id]: {
            value: action.value,
            isValid: action.isValid,
            type: action.prop,
          },
        },
      };

    case SET_RECURRING_DATA:
      return {
        ...state,
        [action.id]: action.value,
      };

    case ADD_NEW_EVENT:
      return {
        ...state,
        pending: true,
      };

    case ADDED_EVENT:
      return {
        ...initialState,
        fieldsData: state.fieldsMockData,
        fieldsMockData: state.fieldsMockData,
      };

    default:
      return state;
  }
};

export default newEventReducer;
