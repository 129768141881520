import { connect } from 'react-redux';

import Groups from './Groups';
import {
  selectGroup,
  searchGroups,
  selectAllGroups,
  deselectAllGroups,
  deleteGroups,
  filterGroupData,
  getGroups,
  filterData,
} from './actions';
import { loadGroup } from './editGroup/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    users: state.auth.users.filteredChunks,
    pending: state.auth.groups.data.pending,
    chunks: state.auth.groups.data.chunks,
    filteredChunks: state.auth.groups.data.filteredChunks,
    error: state.auth.groups.data.error,
    items: state.auth.groups.data.items,
    selected: state.auth.groups.data.selected,
    selectAll: state.auth.groups.data.selectAll,
    isAppAdmin:
      (state.account.auth.profile.admin_appname &&
        state.account.auth.profile.admin_appname.length > 0) ||
      false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadGroup: (appnme, group) => dispatch(loadGroup(appnme, group)),
  onSelect: key => dispatch(selectGroup(key)),
  onSearch: query => dispatch(searchGroups(query)),
  onSelectAllGroups: () => dispatch(selectAllGroups()),
  onDeselectAllGroups: () => dispatch(deselectAllGroups()),
  onFilterData: (filteredChunks, sortedItems) =>
    dispatch(filterData(filteredChunks, sortedItems)),
  onDeleteSelected: () => dispatch(deleteGroups(ownProps.match.params.appname)),
  onFilterGroupData: filter => dispatch(filterGroupData(filter)),
  getGroups: () => dispatch(getGroups(ownProps.match.params.appname)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
