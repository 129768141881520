import React from 'react';

const Paypal = () => (
  <svg
    className="icon paypal"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
  >
    <g>
      <path
        d="M15.5,42.9c-0.3,0-8.7,0-9,0c-1.6-0.3-1.9,2.1,4.8-39c0.4-2.6,0.2-2.3,16.9-2.1C35,1.8,40.9,4.2,41.1,11
		c0,0.6-0.1,2.3-0.3,2.9c-3.5-1.9-9.4-1.4-18.8-1.4C18.7,12.5,20.5,15.6,15.5,42.9z"
      />
      <path
        d="M19.7,27.4C23.9,27,37.9,29.6,40.9,14c6.8,3.4,4.8,20.5-9,21.4c-3.6,0.2-4.9-0.2-5.2,1.6
		c-1.4,9.3-1.2,11.3-3.3,11.3c-7.6,0.1-8.7,0.4-8.5-1.3C17.7,27.4,17.5,27.7,19.7,27.4z"
      />
      <path
        d="M40.9,14c-1.5,8-6.7,13-15.1,13.3c-5.4,0.2-7.3-0.4-8.1,1.6c0.1-1.3-0.2,0.6,1.4-9.7c0.8-5.4,0.8-6.7,2.9-6.7
		C31.5,12.5,37.4,12,40.9,14C40.8,13.9,40.9,13.9,40.9,14z"
      />
    </g>
  </svg>
);

export default Paypal;
