import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import ViewItem from '../viewItem';
import TabHeader from './TabHeader';
import TabItem from './TabItem';
import NestedPages from '../nestedPages';
/**
 * This is a custom version of the material-ui ListItem
 * Created specifically for the design requireents of the Beezer platform
 */
class TabViewItem extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    active: PropTypes.bool,
    tabs: PropTypes.array,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    tabs: [],
    active: false,
  };

  getTabHref = (key, i) => ({
    pathname: `/${this.props.match.params.appname}/editor/build/list`,
    search: `page=${key}&tab=${i}`,
  });

  isActiveTab = (key, i) =>
    key === queryString.parse(this.props.location.search).page &&
    i === Number(queryString.parse(this.props.location.search).tab);

  renderTabs = () =>
    this.props.tabs.map((tab, i) => (
      <TabItem
        // tabs are array based
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        label={tab.title}
        href={this.getTabHref(this.props.id, i)}
        active={this.isActiveTab(this.props.id, i)}
        hasUnavailaleComponent={tab.hasUnavailaleComponent}
      />
    ));

  render() {
    const tabs = this.renderTabs();

    return (
      <>
        <div id={this.props.id}>
          <ViewItem type="TabView" {...this.props} />
          {this.props.active && (
            <>
              <div className="menu-tabs">
                <TabHeader {...this.props} />
                {tabs}
              </div>
            </>
          )}
          {this.props.nestedPages.length > 0 && (
            <NestedPages nestedPages={this.props.nestedPages} />
          )}
        </div>
      </>
    );
  }
}

export default withRouter(TabViewItem);
