import queryString from 'query-string';
import _get from 'lodash/get';
import update from 'lodash/update';
import omit from 'lodash/omit';
import i18n from 'i18next';

import firebase from '../../../../../../../firebase';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';
import { loadDiyComponents } from '../../../../../../../app/actions';
import { objectToArray } from '../../../../../../../utils';
import { logEventsToFirebase } from '../../../../../../../utils/analytics';
import seedComponent from './seedComponent';

export const openCreateComponentUI = (label = '') => ({
  type: 'OPEN_CREATE_COMPONENT_UI',
  value: label,
});

export const closeCreateComponentUI = () => ({
  type: 'CLOSE_CREATE_COMPONENT_UI',
});

export const componentTypeChange = value => ({
  type: 'CREATE_COMPONENT_TYPE_CHANGE',
  value,
});

export const resetComponentForm = () => ({
  type: 'RESET_COMPONENT_FORM',
});

const creatingComponent = value => ({
  type: 'CREATING_COMPONENT',
  value,
});

const getHref = (target, router) => {
  const { match, location } = router;
  const { page, tab, parentPage } = queryString.parse(location.search);

  const tabQuery = tab ? `tab=${tab}&` : '';

  return {
    pathname: `/${match.params.appname}/editor/build/edit`,
    search: `page=${page}&${tabQuery}component=${target}${
      parentPage ? `&parentPage=${parentPage}` : ``
    }`,
  };
};

export const createComponent = (appname, query, type, router) => async (
  dispatch,
  getState,
) => {
  const alwaysData = getState().editor.data.alwaysData;
  const allComponents = getState().editor.data.lazyData;
  const formatComponents = Object.values(allComponents);
  const eventCalendarComponents = formatComponents.filter(
    item => item && item.event,
  );
  const lastEventCalendarComponent =
    eventCalendarComponents[eventCalendarComponents.length - 1];

  if (
    (query.page === 'homepage' ||
      (alwaysData &&
        alwaysData[query.page] &&
        alwaysData[query.page].isHomepage)) &&
    (type === 'WordPress' || type === 'Shopify' || type === 'Woocommerce')
  ) {
    dispatch(
      openSnackbar(
        `${type} ${i18n.t('component can not be placed on the homepage')}`,
      ),
    );
  } else {
    if (type === 'Zoom') {
      const pageRef = firebase
        .database()
        .ref(`apps/${appname}/draft/always_data/${query.page}`);
      const snapshot = await pageRef.once('value');
      const value = snapshot.val();
      if (value && value.components && value.components.length) {
        dispatch(
          openSnackbar(
            `${type} ${i18n.t(
              'component can not be placed on a page that has other components',
            )}`,
          ),
        );
        return;
      }
      if (value && value.type === 'TabView') {
        dispatch(
          openSnackbar(
            `${type} ${i18n.t('component can not be placed on a tab page')}`,
          ),
        );
        return;
      }
    } else {
      const pageRef = firebase
        .database()
        .ref(`apps/${appname}/draft/always_data/${query.page}/unique`);
      const snapshot = await pageRef.once('value');
      const value = snapshot.val();
      if (value) {
        dispatch(
          openSnackbar(
            `${type} ${i18n.t(
              "component can't be placed on a page that has a Zoom component",
            )}`,
          ),
        );
        return;
      }
    }
    const alwaysDataRef = firebase
      .database()
      .ref(`apps/${appname}/draft/always_data`);
    const lazyDataRef = firebase
      .database()
      .ref(`apps/${appname}/draft/lazy_data`);
    const account = getState().account.profile.account;
    const palette = _get(getState(), `editor.design.${appname}.palette`);
    const seedResult = seedComponent(
      type,
      account,
      palette,
      query.tab ? parseInt(query.tab, 10) : null,
    );

    let seedData = seedResult;
    if (type === 'Schedule' && account === '-MMKU9pDXwd51PAHu_Jb') {
      seedData = update(seedResult, 'content', content =>
        omit(content, ['itinerary', 'categories']),
      );
    }

    const componentsArrayRef = query.tab
      ? alwaysDataRef.child(`${query.page}/tabs/${query.tab}/components`)
      : alwaysDataRef.child(`${query.page}/components`);

    const { content, design, layout } = seedData;
    let finalContent = {};

    if (type === 'EventCalendar' && lastEventCalendarComponent) {
      finalContent = {
        ...content,
        event: lastEventCalendarComponent.event,
        course: lastEventCalendarComponent.course,
      };
    }
    lazyDataRef
      .push(
        type === 'EventCalendar' && lastEventCalendarComponent
          ? finalContent
          : content,
      )
      .then(snapshot => {
        // update not_private permissions for new components
        // so that new components will be public when Member Login is enabled
        firebase
          .database()
          .ref(`app_groups/${appname}/not_private`)
          .update({ [snapshot.key]: true });

        return snapshot;
      })
      .then(snapshot => {
        // Set all ratings to 0 on rating creation
        // Rating requires this array to work correctly
        if (type === 'Rating') {
          firebase
            .database()
            .ref(`ratings/${appname}/${snapshot.key}/totals`)
            .set([0, 0, 0, 0, 0]);
        }

        if (type === 'EventCalendar') {
          firebase
            .database()
            .ref(`app_events/${appname}/lazy_data`)
            .set(snapshot.key);
          firebase
            .database()
            .ref(`app_courses/${appname}/lazy_data`)
            .set(snapshot.key);
        }
        const setType = type === 'EventOrganizers' ? 'Memberbodies' : type;
        return alwaysDataRef.push({
          type: setType,
          lazy_data: snapshot.key,
          design,
          layout,
          created: Date.now(),
        });
      })
      .then(snapshot => {
        if (type === 'Zoom') {
          alwaysDataRef
            .child(`${query.page}/unique`)
            .transaction(value => true);
        } else {
          alwaysDataRef
            .child(`${query.page}/unique`)
            .transaction(value => false);
        }
        componentsArrayRef.transaction(value => {
          if (!value) return [snapshot.key];

          return [...value, snapshot.key];
        });
        return snapshot.key;
      })
      .then(key => {
        router.history.push(getHref(key, router));
        logEventsToFirebase('create_component', {
          appname,
          account,
          type,
          query,
          type,
          router,
        });
        dispatch(closeCreateComponentUI());
        dispatch(resetComponentForm());
        dispatch(loadDiyComponents(appname));
        return key;
      });

    dispatch(creatingComponent(lazyDataRef.key));
  }
};

export const getAllComponents = appname => dispatch => {
  const ref = firebase.database().ref(`apps/${appname}/draft`);
  ref.once('value').then(snapshot => {
    const data = snapshot.val();
    if (data === null) return null;

    dispatch({ type: 'RECEIVE_DRAFT_DATA', data });
    return null;
  });
};

export const getSpecialComponents = () => async (dispatch, getState) => {
  const profile = getState().account.profile;
  let account = profile.account;
  try {
    if (profile.parent) {
      const ref = firebase.database().ref(`users/${profile.parent}`);
      const data = await ref.get();
      account = data.val().account;
    }
    await firebase
      .database()
      .ref(`accounts/${account}/specialAddons`)
      .once('value')
      .then(snapshot => {
        const data = snapshot.val();
        if (data !== null) {
          const formatData = objectToArray(data);
          const addons = formatData.map(item => ({
            ...item.value,
            id: item.id,
          }));
          dispatch({ type: 'SET_SPECIAL_ADDONS', value: addons });
        }
      });
  } catch (error) {
    console.log('errorr-->', error);
  }
};
