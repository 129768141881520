import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { Trans, useTranslation } from 'react-i18next';

import TryAgain from 'app/components/tryAgain';
import Header from 'app/components/header';
import ActionMenu from 'app/components/actionMenu';
import ResultsTable from 'app/components/resultsTable';
import { downloadCSV, formatDate } from 'utils';
import TableCheckbox from '../../components/tableCheckbox';
import { size } from 'lodash';
import { whitelabeledHost } from '../../../../../../utils/whitelabeledHost';
import './styles.css';
import themeStyles from '../../../../../../_export.scss';

const isWhiteLabelledHost = whitelabeledHost();
const isCheckboxDisabled = (error, pending) => error && !pending;

const ProductsTable = ({
  history,
  match,
  pending,
  items,
  chunks,
  filteredChunks,
  onFilterProductData,
  selected,
  onSelectAllProducts,
  onSelect,
  onDeselectAllProducts,
  error,
  selectAll,
  getProducts,
  stopGetProducts,
  onDeleteSelected,
  isAppAdmin,
  onSort,
  colSorted,
  order,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    getProducts();
    return stopGetProducts;
  }, []);

  const [productStatusFilter, setProductStatusFilter] = useState('all');
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    onFilterProductData({
      searchText,
      productStatusFilter,
    });
  }, [searchText, productStatusFilter]);
  const getTitle = (selected, products) => (
    <span>
      <Trans>Products</Trans>:{' '}
      {selected.length
        ? `${selected.length} / ${Object.keys(products).length}`
        : Object.keys(products).length}
    </span>
  );

  const getCsvData = (selected, products) => {
    const csvColumns = [
      t('Name'),
      t('Price'),
      t('Stock'),
      t('Created'),
      t('Product status'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selected.forEach(key => {
      data += `${products[key].name},${products[key].price},${
        products[key].stock
      },${formatDate(products[key].created_at)},  ${
        products[key].productStatus
      }\n`;
    });

    return data;
  };

  const getSelectAllCheckBox = () => (
    <div style={{ display: 'flex' }}>
      <Checkbox
        checked={selectAll}
        onChange={(e, isChecked) =>
          isChecked ? onSelectAllProducts() : onDeselectAllProducts()
        }
        disabled={isCheckboxDisabled(error, pending)}
        style={{ marginLeft: -2, color: themeStyles.primaryColor }}
        disableRipple
      />
      <ActionMenu
        selected={selected}
        onDeleteSelected={() => onDeleteSelected(selected, chunks)}
        onDownloadSelected={() => {
          downloadCSV(getCsvData(selected, chunks), 'products');
        }}
        text={t('products')}
      />
    </div>
  );

  const productFilters = (
    <div className="product-filters">
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" style={{ left: '-15px' }}>
          <Trans>Product Status</Trans>
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          variant="standard"
          fullWidth
          color="primary"
          value={productStatusFilter}
          onChange={e => {
            setProductStatusFilter(e.target.value);
          }}
        >
          <MenuItem value="all">
            <Trans>All</Trans>
          </MenuItem>
          <MenuItem value="pending">
            <Trans>Pending</Trans>
          </MenuItem>
          <MenuItem value="approved">
            <Trans>Approved</Trans>
          </MenuItem>
          <MenuItem value="rejected">
            <Trans>Rejected</Trans>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );

  const generateTableColumns = () => {
    const columns = [
      {
        id: 'name',
        header: t('Product Name'),
        classNames: 'col-xs-3',
        containerElement: ({ value }) => <div>{value}</div>,
      },
      {
        id: 'price',
        header: t('Price'),
        classNames: 'col-xs-1',
        containerElement: ({ value }) => <div>{value}</div>,
      },
      {
        id: 'stock',
        header: t('Stock'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) => <div>{value}</div>,
      },
      {
        id: 'productStatus',
        header: t('Product Status'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) => (
          <div>
            {value === 'approved' ? (
              <span style={{ color: '#2e7d32' }}>
                <Trans>Approved</Trans>
              </span>
            ) : value === 'rejected' ? (
              <span style={{ color: '#d32f2f' }}>
                <Trans>Rejected</Trans>
              </span>
            ) : (
              <span style={{ color: '#261ed5' }}>
                <Trans>Pending</Trans>
              </span>
            )}
          </div>
        ),
      },
      {
        id: 'created_at',
        header: (
          <span>
            <Trans>Date Created</Trans>
          </span>
        ),
        classNames: 'col-xs-3 created-at',
        containerElement: ({ value }) => (
          <span>{value && moment(value).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        id: 'selected',
        header: getSelectAllCheckBox(),
        classNames: 'col-xs-1 fix-left',
        containerElement: ({ value, rowId }) => (
          <>
            <TableCheckbox value={value} onSelect={() => onSelect(rowId)} />
            <ActionMenu
              selected={[rowId]}
              onDeleteSelected={() => onDeleteSelected([rowId], chunks)}
              onDownloadSelected={() => {
                downloadCSV(getCsvData([rowId], chunks), 'product');
              }}
              onEditSelected={() =>
                history.push(
                  `/${match.params.appname}/ecommerce/marketplace/products/${rowId}`,
                )
              }
              text={t('product')}
            />
          </>
        ),
      },
    ];
    return columns;
  };

  const getPlaceholderText = () => (
    <>
      <p>
        <Trans>
          No products have been added to your app yet. To invite users to
          register their products & services on your marketplace app, click on
          View App, share your app URL with them and invite them to sign up as a
          seller.
        </Trans>
      </p>
      <p>
        <Trans>
          In this section you will see all products and services which have been
          listed in your marketplace app by your marketplace sellers. You can
          approve or reject products from under this section if you enable
          Product approval in the Settings (hyperlink) section. Only approved
          products will be listed and displayed to buyers in your marketplace
          app.
        </Trans>
        {!isAppAdmin && !isWhiteLabelledHost && (
          <span>
            <Trans>To find out more about this section, please click</Trans>{' '}
            <a
              href="https://beezer.zendesk.com/hc/en-us/articles/6185359080849#h_01G54FF7DR1KRD70EJJFQYJR1Y"
              rel="noopener noreferrer"
              style={{
                color: '#261ed5',
                fontWeight: 'bold',
                textDecoration: 'none',
              }}
              download
              target="_blank"
            >
              <Trans>here</Trans>
            </a>
            .
          </span>
        )}
      </p>
    </>
  );

  return (
    <>
      {error ? (
        <TryAgain
          message={t('Opps, something went wrong...')}
          onRetry={getProducts}
        />
      ) : (
        <>
          <div style={{ height: 'calc(90%)' }} className="products-auth">
            <Header
              title={getTitle(selected, filteredChunks)}
              onChange={size(chunks) > 0 ? setSearchText : null}
              dataFilters={size(chunks) > 0 ? productFilters : null}
              // selectAll={selectAll}
              // onSelectAllResponses={onSelectAllProducts}
              // onDeselectAllResponses={onDeselectAllProducts}
              // actionMenu={
              //   size(chunks) ? (
              //     <ActionMenu
              //       selected={selected}
              //       onDeleteSelected={() => onDeleteSelected(selected, chunks)}
              //       onDownloadSelected={() => {
              //         downloadCSV(getCsvData(selected, chunks), 'products');
              //       }}
              //       text="products"
              //     />
              //   ) : null
              // }
            />
            <ResultsTable
              items={items}
              chunks={filteredChunks}
              pending={pending}
              error={Object.keys(filteredChunks).length === 0}
              columns={generateTableColumns()}
              emptyMessage={getPlaceholderText()}
              sortTable={cols => onSort(cols)}
              order={order}
              colSorted={colSorted}
              colsToSort={['created_at']}
              onRowTouchTap={productID =>
                history.push(
                  `/${match.params.appname}/ecommerce/marketplace/products/${productID}`,
                )
              }
            />
          </div>
        </>
      )}
    </>
  );
};
ProductsTable.propTypes = {
  pending: PropTypes.bool.isRequired,
  filteredChunks: PropTypes.object,
  items: PropTypes.array.isRequired,
  error: PropTypes.string,
  filter: PropTypes.string,
  chunks: PropTypes.object,
  getProducts: PropTypes.func.isRequired,
  onFilterProductData: PropTypes.func.isRequired,
  selected: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onSelectAllProducts: PropTypes.func.isRequired,
  onDeselectAllProducts: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

ProductsTable.defaultProps = {
  error: null,
  filter: '',
  filteredChunks: {},
  chunks: {},
  selected: [],
};

export default ProductsTable;
