import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import TableCheckbox from '../../components/tableCheckbox';
import TryAgain from '../../../../../../app/components/tryAgain';
import Header from '../../../../../../app/components/header';
import ActionMenu from '../../../../../../app/components/actionMenu';
import ResultsTable from '../../../../../../app/components/resultsTable';
import { downloadCSV, formatDate } from '../../../../../../utils';
import { whitelabeledHost } from '../../../../../../utils/whitelabeledHost';
import './styles.css';
import Checkbox from '@material-ui/core/Checkbox';
import Done from '@material-ui/icons/Done';
import themeStyles from '../../../../../../_export.scss';

import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Box,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { get, size } from 'lodash';

const isWhiteLabelledHost = whitelabeledHost();
const isCheckboxDisabled = (error, pending) => error && !pending;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
}

const Vendors = ({
  history,
  match,
  pending,
  items,
  chunks,
  filteredChunks,
  onFilterVendorData,
  selected,
  onSelectAllVendors,
  onSelect,
  onDeselectAllVendors,
  error,
  selectAll,
  getVendors,
  getAllVendorReports,
  vendorReports,
  stopGetVendors,
  onDeleteSelected,
  onEmailSelected,
  vendorApproval,
  isAppAdmin,
  onSort,
  colSorted,
  order,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    getAllVendorReports(match.params.appname);
    getVendors();
    return stopGetVendors;
  }, []);

  const [vendorStatusFilter, setVendorStatusFilter] = useState('all');
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    onFilterVendorData({
      searchText,
      vendorStatusFilter,
    });
  }, [searchText, vendorStatusFilter]);
  const getTitle = (selected, vendors) => (
    <span>
      <Trans>Sellers</Trans>:{' '}
      {selected.length
        ? `${selected.length} / ${Object.keys(vendors).length}`
        : Object.keys(vendors).length}
    </span>
  );

  const getCsvData = (selected, contacts) => {
    const csvColumns = [
      t('First name'),
      t('Last name'),
      t('Email'),
      t('Created'),
      t('Groups'),
      t('Phone'),
      t('Verified'),
      t('Organisation'),
      t('Role'),
      t('Allow chat'),
      t('Active'),
      t('Show email in search'),
      t('Show profile in search'),
      t('Social links'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selected.forEach(key => {
      const groupkeys = Object.keys(contacts[key].groups);
      const socialLinksString =
        (contacts[key].socialLinks && contacts[key].socialLinks.join(', ')) ||
        '';

      data += `${contacts[key].firstName},${contacts[key].lastName},${
        contacts[key].email
      },${formatDate(contacts[key].created_at)},${groupkeys.join(' ')},${
        contacts[key].phoneNumber
      },${contacts[key].emailVerified ? t('yes') : t('no')},${
        contacts[key].organisation
      },${contacts[key].role},${contacts[key].allowChat ? 'yes' : 'no'},${
        contacts[key].isActive ? t('yes') : t('no')
      },${contacts[key].showEmailInSearch ? t('yes') : t('no')},${
        contacts[key].showProfileInSearch ? t('yes') : t('no')
      },${socialLinksString}\n`;
    });

    return data;
  };

  const getSelectAllCheckBox = () => (
    <div style={{ display: 'flex' }}>
      <Checkbox
        checked={selectAll}
        onChange={(e, isChecked) =>
          isChecked ? onSelectAllVendors() : onDeselectAllVendors()
        }
        disabled={isCheckboxDisabled(error, pending)}
        style={{ marginLeft: -2, color: themeStyles.primaryColor }}
        disableRipple
      />
      <ActionMenu
        selected={selected}
        onDeleteSelected={() => onDeleteSelected(selected, chunks)}
        onDownloadSelected={() => {
          downloadCSV(getCsvData(selected, chunks), 'sellers');
        }}
        onEmailSelected={emailFields =>
          onEmailSelected(selected, chunks, emailFields)
        }
        text={t('sellers')}
      />
    </div>
  );

  const vendorFilters = (
    <div className="vendor-filters">
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" style={{ left: '-15px' }}>
          <Trans>Seller Status</Trans>
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          variant="standard"
          fullWidth
          color="primary"
          value={vendorStatusFilter}
          onChange={e => {
            setVendorStatusFilter(e.target.value);
          }}
        >
          <MenuItem value="all">
            <Trans>All</Trans>
          </MenuItem>
          <MenuItem value="pending">
            <Trans>Pending</Trans>
          </MenuItem>
          <MenuItem value="approved">
            <Trans>Approved</Trans>
          </MenuItem>
          <MenuItem value="rejected">
            <Trans>Rejected</Trans>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );

  const generateTableColumns = () => {
    const columns = [
      {
        ids: ['firstName', 'lastName'],
        header: t('Name'),
        classNames: 'col-xs-3',
        containerElement: ({ value }) => <div>{value}</div>,
      },
      {
        id: 'email',
        header: t('Email'),
        classNames:
          vendorApproval && vendorApproval.isEnabled ? 'col-xs-3' : 'col-xs-4',
        containerElement: ({ value }) => <div>{value}</div>,
      },
      {
        id: 'emailVerified',
        header: t('Verified'),
        classNames: `col-xs-1 email-verified`,
        containerElement: ({ value }) => (
          <div>{value ? <Done /> : <ClearOutlinedIcon />}</div>
        ),
      },
      {
        id: 'created_at',
        header: (
          <span>
            <Trans>Date Created</Trans>
          </span>
        ),
        classNames: `${
          vendorApproval && vendorApproval.isEnabled ? 'col-xs-2' : 'col-xs-3'
        } created-at`,
        containerElement: ({ value }) => (
          <span>{value && moment(value).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        id: 'selected',
        header: getSelectAllCheckBox(),
        classNames: `col-xs-1 ${items.length < 4 ? 'fix-left' : 'selected'}`,
        containerElement: ({ value, rowId }) => (
          <>
            <TableCheckbox value={value} onSelect={() => onSelect(rowId)} />
            <ActionMenu
              selected={[rowId]}
              onDeleteSelected={() => onDeleteSelected([rowId], chunks)}
              onDownloadSelected={() => {
                downloadCSV(getCsvData([rowId], chunks), 'sellers');
              }}
              onEmailSelected={emailFields =>
                onEmailSelected([rowId], chunks, emailFields)
              }
              onEditSelected={() =>
                history.push(
                  `/${match.params.appname}/ecommerce/marketplace/sellers/${rowId}`,
                )
              }
              text={t('seller')}
            />
          </>
        ),
      },
    ];

    if (vendorApproval && vendorApproval.isEnabled) {
      const sellerStatusCell = {
        id: 'vendorApprovalStatus',
        header: t('Seller Status'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) => (
          <div>
            {value === true ? (
              <span style={{ color: themeStyles.primaryColor }}>
                <Trans>Approved</Trans>
              </span>
            ) : value === false ? (
              <span style={{ color: '#d32f2f' }}>
                <Trans>Rejected</Trans>
              </span>
            ) : (
              <span style={{ color: themeStyles.primaryColor }}>
                <Trans>Pending</Trans>
              </span>
            )}
          </div>
        ),
      };
      columns.splice(3, 0, sellerStatusCell);
    }

    return columns;
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getRows = reports =>
    reports &&
    reports.map((report, key) => (
      <TableRow key={key}>
        <TableCell component="td" scope="row">
          {moment(get(report, 'createdAt', '')).format('MMMM Do YYYY, h:mm a')}
        </TableCell>
        <TableCell component="td" scope="row">
          <Link
            to={`/${match.params.appname}/ecommerce/marketplace/buyers/${get(
              report,
              'uid',
              '',
            )}`}
            className="page-link"
          >
            {get(report, 'buyerName', '')}
          </Link>
        </TableCell>

        <TableCell component="td" scope="row">
          {get(report, 'report', 'missing report')}
        </TableCell>

        <TableCell component="td" scope="row">
          <Link
            className="page-link"
            to={`/${match.params.appname}/ecommerce/marketplace/products/${get(
              report,
              'productId',
              '',
            )}`}
          >
            {get(report, 'productName', '')}
          </Link>
        </TableCell>
        <TableCell component="td" scope="row">
          <Link
            className="page-link"
            to={`/${match.params.appname}/ecommerce/marketplace/sellers/${get(
              report,
              'sellerId',
              '',
            )}`}
          >
            {get(report, 'sellerName', '')}
          </Link>
        </TableCell>
      </TableRow>
    ));

  const getPlaceholderText = () => (
    <>
      <p>
        <Trans>
          No sellers have registered to your app yet. To invite users to
          register as Sellers on your marketplace app, click on View App, share
          your app URL with them and invite them to sign up as a seller.
        </Trans>
      </p>
      <p>
        <Trans>
          In this section you will see the list of sellers who registered on
          your marketplace app. You can click on a buyer from the list and check
          their profile info, download their data, and delete their account. You
          can also enable Seller profile approval in the Settings section. If
          you enable approval, each seller profile would beed to be approved by
          you before it gets listed in the marketplace app., and only approved
          sellers would be able to list products on your marketplace app. To get
          a new seller registered, send your app link to users, so they can Sign
          up and complete their seller profile.
        </Trans>
        <span>
          <Trans>
            To find out more about how your app users can sign up as sellers in
            your app, click
          </Trans>{' '}
          <a
            href={
              !isAppAdmin && !isWhiteLabelledHost
                ? 'https://beezer.zendesk.com/hc/en-us/articles/6741770642961-Marketplace-How-to-become-a-seller'
                : 'https://kb-articles.s3.eu-west-2.amazonaws.com/How+to+become+a+seller.pdf'
            }
            rel="noopener noreferrer"
            style={{
              color: themeStyles.primaryColor,
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
            download
            target="_blank"
          >
            <Trans>here</Trans>
          </a>
          .
        </span>
      </p>
    </>
  );

  return (
    <>
      {error ? (
        <TryAgain
          message={t('Opps, something went wrong...')}
          onRetry={getVendors}
        />
      ) : (
        <>
          <div style={{ height: 'calc(90%)' }} className="vendors-auth">
            {/* <Tabs value={value} onChange={handleChange} variant="fullWidth">
              <Tab label="Sellers" />
              <Tab label="Disputes" />
            </Tabs> */}
            {/* <TabPanel value={value} index={0}> */}
            <Header
              title={getTitle(selected, filteredChunks)}
              selectAll={selectAll}
              onChange={size(chunks) > 0 ? setSearchText : null}
              onSelectAllResponses={onSelectAllVendors}
              onDeselectAllResponses={onDeselectAllVendors}
              dataFilters={
                vendorApproval && vendorApproval.isEnabled && size(chunks) > 0
                  ? vendorFilters
                  : null
              }
              actionMenu={
                size(chunks) > 0 ? (
                  <ActionMenu
                    selected={selected}
                    onDeleteSelected={() => onDeleteSelected(selected, chunks)}
                    onDownloadSelected={() => {
                      downloadCSV(getCsvData(selected, chunks), 'sellers');
                    }}
                    onEmailSelected={emailFields =>
                      onEmailSelected(selected, chunks, emailFields)
                    }
                    text={t('sellers')}
                  />
                ) : null
              }
            />
            <div style={{ height: '60vh' }}>
              <ResultsTable
                items={items}
                chunks={filteredChunks}
                pending={pending}
                error={Object.keys(filteredChunks).length === 0}
                columns={generateTableColumns()}
                emptyMessage={getPlaceholderText()}
                onRowTouchTap={vendorID =>
                  history.push(
                    `/${match.params.appname}/ecommerce/marketplace/sellers/${vendorID}`,
                  )
                }
              />
            </div>
            {/* </TabPanel> */}
            {/* <TabPanel value={value} index={1}>
              <div style={{ height: '60vh' }}>
                <ResultsTable
                  items={items}
                  chunks={filteredChunks}
                  pending={pending}
                  error={Object.keys(filteredChunks).length === 0}
                  columns={generateTableColumns()}
                  emptyMessage={getPlaceholderText()}
                  onRowTouchTap={vendorID =>
                    history.push(
                      `/${match.params.appname}/ecommerce/marketplace/sellers/${vendorID}`,
                    )
                  }
                  text="sellers"
                />
              }
            />
            <div style={{ height: '60vh' }}>
              <ResultsTable
                items={items}
                chunks={filteredChunks}
                pending={pending}
                error={Object.keys(filteredChunks).length === 0}
                columns={generateTableColumns()}
                emptyMessage="No sellers to see here."
                sortTable={cols => onSort(cols)}
                order={order}
                colSorted={colSorted}
                colsToSort={['created_at']}
                // onRowTouchTap={vendorID =>
                //   history.push(
                //     `/${match.params.appname}/ecommerce/marketplace/sellers/${vendorID}`,
                //   )
                // }
              />
            </div>
            {/* </TabPanel> */}
            {/* <TabPanel value={value} index={1}>
              <TableContainer style={{ maxHeight: 650 }} component={Paper}>
                <Table stickyHeader aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Reported on</TableCell>
                      <TableCell>Buyer</TableCell>
                      <TableCell>Report</TableCell>
                      <TableCell>Product</TableCell>
                      <TableCell>Seller</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{getRows(vendorReports)}</TableBody>
                </Table>
              </TableContainer>
            </TabPanel> */}
          </div>
        </>
      )}
    </>
  );
};
Vendors.propTypes = {
  pending: PropTypes.bool.isRequired,
  filteredChunks: PropTypes.object,
  items: PropTypes.array.isRequired,
  error: PropTypes.string,
  filter: PropTypes.string,
  chunks: PropTypes.object,
  getVendors: PropTypes.func.isRequired,
  getAllVendorReports: PropTypes.func.isRequired,
  vendorReports: PropTypes.array,
  onFilterVendorData: PropTypes.func.isRequired,
  selected: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onSelectAllVendors: PropTypes.func.isRequired,
  onDeselectAllVendors: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  vendorApproval: PropTypes.object,
};

Vendors.defaultProps = {
  error: null,
  filter: '',
  filteredChunks: {},
  chunks: {},
  selected: [],
  vendorApproval: {
    isEnabled: false,
  },
  vendorReports: [],
};

export default Vendors;
