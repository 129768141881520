import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const PageHeaderCounter = ({ amount, title }) => {
  const getCounterClass = /[0-9]/g.test(amount) && 'numeric';
  return (
    <div className="header-counter-container">
      <h1 className={`header-counter ${getCounterClass}`}>{amount}</h1>
      <h5 className="header-counter-title">{title}</h5>
    </div>
  );
};

PageHeaderCounter.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
};

PageHeaderCounter.defaultProps = {
  actions: null,
};

export default PageHeaderCounter;
