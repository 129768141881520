import React, { useState } from 'react';
import { objectToArray } from '../../../../../../utils';
import * as Styled from './styles';
import { get } from 'lodash';
import { Button } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import DialogConfirmation from '../../../../../../app/components/dialogConfirmation';
import { useSelector } from 'react-redux';
import ApprovalDialog from '../../../../../../app/components/approvalDialog/ApprovalDialog';

const UserInfo = ({ user, changeVendorApproval, type }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vendorApproval, setVendorApproval] = useState(false);
  const [message, setMessage] = useState('');
  const marketplaceSettings = useSelector(
    state => state.ecommerce.marketplace.settings.data.marketplace_settings,
  );
  const approveVendor = () => {
    setVendorApproval(true);
    setLoading(true);
    setMessage(t('Are you sure you want to approve this seller?'));
    setOpen(true);
  };

  const rejectVendor = () => {
    setVendorApproval(false);
    setLoading(true);

    setMessage(t('Are you sure you want to reject this seller?'));
    setOpen(true);
  };

  return (
    <Styled.container>
      <Styled.imageContainer>
        {user.photoURL ? (
          <Styled.profileImage src={get(user, 'photoURL', '')} />
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Trans>No profile image added</Trans>
          </div>
        )}
      </Styled.imageContainer>
      <Styled.details>
        <Styled.field>
          <Styled.fieldTitle>
            <Trans>Name</Trans>:{' '}
          </Styled.fieldTitle>
          <Styled.fieldContent>{`${get(user, 'firstName', 'N/A')} ${get(
            user,
            'lastName',
            'N/A',
          )}`}</Styled.fieldContent>
        </Styled.field>
        <Styled.field>
          <Styled.fieldTitle>
            <Trans>Username</Trans>:{' '}
          </Styled.fieldTitle>
          <Styled.fieldContent>
            {get(user, 'userName', 'N/A')}
          </Styled.fieldContent>
        </Styled.field>
        <Styled.field>
          <Styled.fieldTitle>
            <Trans>Email</Trans>:{' '}
          </Styled.fieldTitle>
          <Styled.fieldContent>{get(user, 'email', 'N/A')}</Styled.fieldContent>
        </Styled.field>
        <Styled.field>
          <Styled.fieldTitle>
            <Trans>Phone Number</Trans>:{' '}
          </Styled.fieldTitle>
          <Styled.fieldContent>
            {get(user, 'phoneNumber', 'N/A')}
          </Styled.fieldContent>
        </Styled.field>
        <Styled.field>
          <Styled.fieldTitle>
            <Trans>Postal Address</Trans>:{' '}
          </Styled.fieldTitle>
          <Styled.fieldContent>{`${get(user, 'postalAddress', 'N/A')} ${get(
            user,
            'postCode',
            'N/A',
          )}`}</Styled.fieldContent>
        </Styled.field>
        {type === 'seller' &&
          get(marketplaceSettings, 'vendorApproval.isEnabled', false) && (
            <Styled.actionContainer>
              <Button
                color="primary"
                disabled={user.vendorApprovalStatus || loading}
                onClick={approveVendor}
              >
                <Trans>Approve Seller</Trans>
              </Button>
              <Button
                color="secondary"
                disabled={!user.vendorApprovalStatus || loading}
                onClick={rejectVendor}
              >
                <Trans>Reject Seller</Trans>
              </Button>
            </Styled.actionContainer>
          )}
      </Styled.details>

      <ApprovalDialog
        open={open}
        onClose={() => {
          setOpen(false);
          setLoading(false);
        }}
        dialogTitle={message}
        action={async msg => {
          await changeVendorApproval(vendorApproval, msg);
          setLoading(false);
        }}
      />
    </Styled.container>
  );
};

export default UserInfo;
