import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import RedeemRequest from './RedeemRequest';
import { validateRequestData, updateRequestStatus } from './actions';

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    ...state.account.referral.redeemRequest
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateRequestStatus:(status) => dispatch(updateRequestStatus(status)),
    validateRequestData: () => dispatch(validateRequestData(queryString.parse(ownProps.location.search).acc, queryString.parse(ownProps.location.search).token)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RedeemRequest),
);
