import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '../../../../app/components/progress';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';
import { getHeaders } from '../../api';
import { userGroupStatus } from '../../helpers/userGroupStatus';

import './styles.scss';

function FkGroupCard({
  groupId,
  availableSlots,
  filledSlots,
  coach,
  description,
  imageUrl,
  title,
  parents,
  children,
  user,
}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const userId = useSelector(({ fkAcademy }) => fkAcademy.fkUserId);
  const appname = useSelector(({ app }) => app.appContext.appname);

  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!user) return <CircularProgress />;

  const [isGroupMember, message] = userGroupStatus(
    { ...user, userId },
    parents,
    children,
  );

  async function handleSubmit() {
    if (!isGroupMember || availableSlots - filledSlots < 1) return;
    setIsSubmitting(true);
    try {
      const headers = await getHeaders();
      if (user.fkAcademyUserType === 'parent') {
        await fetch(
          `${process.env.REACT_APP_FK_ACADEMY}/parent-group-status?appname=${appname}`,
          {
            method: 'PUT',
            headers,
            body: JSON.stringify({
              userId,
              groupId,
              remove: !!parents[userId],
            }),
          },
        );
      } else {
        await fetch(
          `${process.env.REACT_APP_FK_ACADEMY}/child-group-status?appname=${appname}`,
          {
            method: 'PUT',
            headers,
            body: JSON.stringify({
              childId: userId,
              groupId,
              remove: !!children[userId],
            }),
          },
        );
      }
      queryClient.invalidateQueries('fkAcademyGroups');
      queryClient.invalidateQueries(['fkAcademyUser', userId]);

      dispatch(openSnackbar('Group status updated!'));
    } catch (err) {
      console.error(err);
      dispatch(openSnackbar('Something went wrong. Try again.'));
    }
    setIsSubmitting(false);
  }

  return (
    <div className="fk-groups-card">
      <img src={imageUrl} alt={title} />
      <div className="fk-groups-card-content">
        <h3 className="fk-groups-card-title">{title}</h3>
        <p className="fk-groups-card-coach">
          Coached by {coach.firstName} {coach.lastName}
        </p>
        <p className="fk-groups-card-description">{description}</p>
        <p className="fk-groups-card-slots">
          {availableSlots - (filledSlots || 0)} slots left
        </p>
        <div className="fk-groups-card-slots-border" />
        {user && (
          <button
            onClick={handleSubmit}
            disabled={isSubmitting}
            className="fk-groups-card-button"
          >
            {isSubmitting
              ? 'Updating...'
              : availableSlots - filledSlots < 1
              ? 'No slots left!'
              : message}
          </button>
        )}
      </div>
    </div>
  );
}

export default FkGroupCard;
