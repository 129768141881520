import i18n from 'i18next';
import firebase from '../../../../firebase';
export const REQUEST_VOTE_DATA = 'REQUEST_VOTE_DATA';
export const RECEIVE_VOTE_DATA = 'RECEIVE_VOTE_DATA';
export const WATCHING_VOTE_DATA = 'WATCHING_VOTE_DATA';
export const RECEIVE_VOTE_DATA_UPDATE = 'RECEIVE_VOTE_DATA_UPDATE';
export const REMOVING_VOTE_DATA_LISTENER = 'REMOVING_VOTE_DATA_LISTENER';
export const REQUEST_ALL_VOTES = 'REQUEST_ALL_VOTES';
export const RECEIVE_ALL_VOTES = 'RECEIVE_ALL_VOTES';
export const REPORT_VOTE_DATA_REQUEST_ERROR = 'REPORT_VOTE_DATA_REQUEST_ERROR';
export const FILTER_VOTE_DATA = 'FILTER_VOTE_DATA';
export const FAVOURITE_SELECTED = 'FAVOURITE_SELECTED';
export const DOWNLOAD_SELECTED = 'DOWNLOAD_SELECTED';
export const DELETE_SELECTED_VOTES = 'DELETE_SELECTED_VOTES';
export const ACTION_ERROR = 'ACTION_ERROR';
export const SELECT_VOTE = 'SELECT_VOTE';
export const SELECT_ALL_VOTES = 'SELECT_ALL_VOTES';
export const DESELECT_ALL_VOTES = 'DESELECT_ALL_VOTES';
export const SORT_VOTES = 'SORT_VOTES';

const requestVoteData = appname => ({
  type: REQUEST_VOTE_DATA,
  appname,
});

const receiveVoteData = (appname, data) => ({
  type: RECEIVE_VOTE_DATA,
  appname,
  data,
});

const watchingVoteData = appname => ({
  type: WATCHING_VOTE_DATA,
  appname,
});

const receiveVoteDataUpdate = (appname, data) => ({
  type: RECEIVE_VOTE_DATA_UPDATE,
  appname,
  data,
});

const offVoteData = appname => ({
  type: REMOVING_VOTE_DATA_LISTENER,
  appname,
});

const requestAllVotes = appname => ({
  type: REQUEST_ALL_VOTES,
  appname,
});

const receiveAllVotes = (appname, data) => ({
  type: RECEIVE_ALL_VOTES,
  appname,
  data,
});

const reportError = err => ({
  type: REPORT_VOTE_DATA_REQUEST_ERROR,
  err: err.message || err,
});

export const filterVoteData = filter => dispatch =>
  dispatch({
    type: FILTER_VOTE_DATA,
    payload: filter,
  });

export const favouriteSelectedSuccess = (appname, selected, data) => dispatch =>
  dispatch({
    type: FAVOURITE_SELECTED,
    appname,
    selected,
    data,
  });

export const deleteSelectedSuccess = (appname, selected) => dispatch =>
  dispatch({
    type: DELETE_SELECTED_VOTES,
    appname,
    selected,
  });

export const reportActionError = (
  error,
  appname,
  selected,
  action,
) => dispatch =>
  dispatch({ type: ACTION_ERROR, error, appname, selected, action });

export const getVoteData = appname => dispatch => {
  dispatch(requestVoteData(appname));

  return firebase
    .database()
    .ref(`voting/${appname}`)
    .once('value')
    .then(snapshot => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return dispatch(receiveVoteData(appname, data));
      }
      return dispatch(reportError(i18n.t('No vote responses')));
    })
    .catch(err => dispatch(reportError(err)));
};

export const watchVoteData = appname => dispatch => {
  dispatch(watchingVoteData(appname));

  return firebase
    .database()
    .ref(`voting/${appname}`)
    .on('value', snapshot => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return dispatch(receiveVoteDataUpdate(appname, data));
      }
      dispatch(receiveVoteDataUpdate(appname, {}));
      return dispatch(reportError('No vote responses'));
    });
};

export const removeVoteDataWatcher = appname => dispatch => {
  firebase
    .database()
    .ref(`voting/${appname}`)
    .off('value');

  return dispatch(offVoteData(appname));
};

export const getAllVotes = appname => dispatch => {
  dispatch(requestAllVotes(appname));

  return firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data`)
    .once('value')
    .then(snapshot => {
      if (snapshot.exists()) {
        const data = [];
        Object.entries(snapshot.val()).forEach(item => {
          if (item[1].type === 'Vote') {
            data[item[0]] = { ...item[1] };
          }
        });
        return dispatch(receiveAllVotes(appname, data));
      }
      return dispatch(reportError('No vote components in app'));
    })
    .catch(err => dispatch(reportError(err)));
};

export const selectResponse = key => ({
  type: SELECT_VOTE,
  key,
});

export const selectAllResponses = () => ({ type: SELECT_ALL_VOTES });

export const deselectAllResponses = () => ({ type: DESELECT_ALL_VOTES });

export const favouriteSelected = (appname, selected) => dispatch =>
  firebase
    .database()
    .ref(`voting/${appname}`)
    .transaction(responses => {
      if (responses === null) return null;
      Object.entries(responses).forEach(response => {
        if (selected.indexOf(response[0]) >= 0) {
          response[1].favourite = !response[1].favourite;
        }
      });
      return responses;
    })
    .then(data =>
      dispatch(
        favouriteSelectedSuccess(appname, selected, data.snapshot.val()),
      ),
    )
    .catch(error => reportActionError(error, appname, selected, 'favourite'));

export const deleteSelected = (appname, selected) => dispatch =>
  firebase
    .database()
    .ref(`voting/${appname}`)
    .transaction(responses => {
      if (responses === null) return null;
      const result = {};
      Object.entries(responses).forEach(response => {
        if (selected.indexOf(response[0]) < 0) {
          result[response[0]] = response[1];
        }
      });
      return result;
    })
    .then(data =>
      dispatch(deleteSelectedSuccess(appname, selected, data.snapshot.val())),
    )
    .catch(error => reportActionError(error, appname, selected, 'delete'));

export const sortTableData = cols => dispatch =>
  dispatch({ type: SORT_VOTES, column: cols });
