import React from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import Star from './Star';
import Container from './Container';

const Rating = ({ value, setRating, color, max }) => {
  const calcFillPercentage = index => {
    const remaining = value - index;

    let fillPercentage;

    if (remaining > 1) {
      fillPercentage = 100;
    } else if (remaining < 0) {
      fillPercentage = 0;
    } else {
      fillPercentage = remaining * 100;
    }

    return `${fillPercentage}%`;
  };

  const renderStar = index => (
    <Star
      key={`${index}`}
      fill="#fff"
      layout="Star"
      onClick={() => setRating(index + 1)}
      percentage={calcFillPercentage(index)}
      color={color}
      style={{ height: 36, width: 36, stroke: color || '#333' }}
    />
  );

  const renderStarArray = () => {
    const stars = map([...Array(5)], (item, index) => renderStar(index, max));
    return stars;
  };

  return <Container>{renderStarArray()}</Container>;
};

Rating.propTypes = {
  value: PropTypes.number.isRequired,
  setRating: PropTypes.func.isRequired,
  color: PropTypes.string,
  max: PropTypes.number,
};

Rating.defaultProps = {
  color: '#261ed5',
  max: 5,
};

export default Rating;
