import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HiddenRadioInput from './HiddenRadioInput';
import ButtonShapeIcon from './ButtonShapeIcon';

const ButtonShapeRadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

const RadioContainer = styled.div`
  position: relative;
  cursor: pointer;
  flex: 1;
  height: 62px;
  margin: 12px 0;

  &:first-child {
    margin-left: 0 !important;
  }

  &:last-child {
    margin-right: 0 !important;
  }
`;

class ButtonShapeRadio extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
  };

  state = {
    isKeyboardFocused: false,
  };

  handleFocus = () =>
    this.setState({
      isKeyboardFocused: true,
    });

  handleBlur = () =>
    this.setState({
      isKeyboardFocused: false,
    });

  handleMouseEnter = () =>
    this.setState({
      isHovered: true,
    });

  handleMouseLeave = () =>
    this.setState({
      isHovered: false,
    });

  render() {
    const hiddenInput = !this.props.disabled ? (
      <HiddenRadioInput
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        {...this.props}
      />
    ) : null;

    return (
      <RadioContainer>
        <ButtonShapeIcon {...this.props} {...this.state} />
        {hiddenInput}
      </RadioContainer>
    );
  }
}

export { ButtonShapeRadioGroup, ButtonShapeRadio };
