export const WORKFORCE_DELETED = 'Workforce member deleted';
export const WORKFORCE_CREATED = 'Workforce member created';
export const WORKFORCE_GROUP_CREATED = 'Workforce group assigned';
export const WORKFORCE_GROUP_UPDATED = 'Workforce group details updated';
export const WORKFORCE_GROUP_DELETED = 'Workforce group deleted';
export const WORKFORCE_GROUP_APPROVED = 'Workforce group activated';
export const WORKFORCE_GROUP_DISAPPROVED = 'Workforce group disapproved';
export const COD_APPROVED = 'Code of Conduct approved';
export const COD_DISAPPROVED = 'Code of Conduct disapproved';

export const ethnicGroups = [
  'White Welsh',
  'White English',
  'White Scottish',
  'White Northern Irish',
  'White British',
  'White Gypsy',
  'White Roma',
  'White Irish',
  'White Irish Traveller',
  'White Other',
  'Mixed White and Black Caribbean',
  'Mixed White and Black African',
  'Mixed White and Asian',
  'Mixed Other',
  'Asian British',
  'Asian Indian',
  'Asian Pakistani',
  'Asian Bangladeshi',
  'Asian Chinese',
  'Asian Other',
  'Black British',
  'Black African',
  'Black Carribean',
  'Black Other',
  'Arab',
  'Other',
  'Prefer not to say',
];

export const religions = [
  'Christianity',
  'Islam',
  'Hinduism',
  'Sikhism',
  'Judaism',
  'Buddhism',
  'Other',
  'No religion',
  'Atheist',
  'Prefer not to say',
];

export const sexualOrientations = [
  'Heterosexual',
  'Gay woman/Lesbian',
  'Gay man',
  'Bisexual',
  'Identify in another way/prefer to self describe',
  'Prefer not to say',
];

export const genderReassignments = ['Yes', 'No', 'Prefer not to say'];

export const leaveTypes = ['Yes', 'No', 'Prefer not to say'];

export const martialStatus = [
  'Married/civil partnership',
  'Single',
  'Divorced',
  'Widowed',
  'Prefer not to say',
];

export const localAuthorities = [
  'Aberdeen',
  'Aberdeenshire',
  'Angus',
  'Argyll and Bute',
  'Clackmannanshire',
  'Dumfries and Galloway',
  'Dundee',
  'East Ayrshire',
  'East Dunbartonshire',
  'East Lothian',
  'East Renfrewshire',
  'Edinburgh',
  'Falkirk',
  'Fife',
  'Glasgow',
  'Highland',
  'Inverclyde',
  'Midlothian',
  'Moray',
  'Na h-Eileanan Siar',
  'North Ayrshire',
  'North Lanarkshire',
  'Orkney',
  'Perth and Kinross',
  'Renfrewshire',
  'Scottish Borders',
  'Shetland',
  'South Ayrshire',
  'South Lanarkshire',
  'Stirling',
  'West Dunbartonshire',
  'West Lothian',
  'England',
  'Ireland',
  'Wales',
  'Northern Ireland',
  'Other',
];

export const gender = ['Male', 'Female', 'Identify in another way/self describe', 'Prefer not to say'];

export const disability = ['Yes', 'No', 'Prefer not to say'];
