import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Design from '../design';
import ComponentList from '../componentList';
import PageSettings from '../pageSettings';
import ComponentInterface from '../componentInterface';
import ItemInterface from '../itemInterface';
import AppIcon from '../../../shared/appIcon';
import AppName from '../../../shared/appName';
import PaypalSetting from '../../../shared/paypalSetting';
import CustomDomain from '../../../shared/customDomain';
import AppNavigationMenu from '../../../shared/appNavigationMenu';
import AppSplashScreen from '../../../shared/appSplashScreen';
import SidebarHeader from '../sidebarHeader';
import Sidebar from '../../../../../../app/components/sidebar';
import Accessibility from '../../../shared/accessibility';
import Privacy from '../../../shared/privacy';
import CustomEmailsAdmin from '../customEmailsAdmins';
import Admins from '../admins';
import { displayNotification } from '../../modules/create/createPage/actions';

const BuildInterface = props => {
  const dispatch = useDispatch();
  const appData = useSelector(state => state.editor.data.alwaysData) || {};
  const showNotification = useSelector(
    state => state.editor.build.create.createPage.showNotification,
  );
  const appAdmin = useSelector(
    state => state.account.auth.profile.admin_appname,
  );
  const subAdminWithMulipleApps = useSelector(
    state => state.account.auth.profile.admin_appnames,
  );

  const closeNotification = () => {
    dispatch(displayNotification(false));
  };

  const isCurrentAPopupPage = () => {
    if (Object.keys(appData).length > 0) {
      const pageKey = new URLSearchParams(window.location.search).get('page');
      const page = appData[pageKey];
      return !!(page && page.type && page.type === 'Popup');
    }
    return false;
  };
  return (
    <Sidebar
      isAppAdmin={
        (appAdmin && appAdmin.length > 0) ||
        (subAdminWithMulipleApps &&
          Object.keys(subAdminWithMulipleApps).length > 1)
      }
      isPopupPage={isCurrentAPopupPage()}
      type="large"
      showNotification={showNotification}
      closeNotification={() => closeNotification()}
      header={<SidebarHeader />}
      animate
      fullWidth={props.location.pathname.includes('admins')}
    >
      <Switch>
        <Route
          path="/:appname/editor/build/app_icon_design"
          component={AppIcon}
        />
        <Route path="/:appname/editor/build/app_name" component={AppName} />
        <Route
          path="/:appname/editor/build/paypal_settings"
          component={PaypalSetting}
        />
        <Route
          path="/:appname/editor/build/custom_domain"
          component={CustomDomain}
        />
        <Route
          path="/:appname/editor/build/navigation_menu"
          component={AppNavigationMenu}
        />
        <Route
          path="/:appname/editor/build/app_splash_screen"
          component={AppSplashScreen}
        />
        <Route path="/:appname/editor/build/design" component={Design} />
        <Route path="/:appname/editor/build/list" component={ComponentList} />
        <Route
          path="/:appname/editor/build/edit"
          component={ComponentInterface}
        />
        <Route path="/:appname/editor/build/item" component={ItemInterface} />
        <Route
          path="/:appname/editor/build/page_settings"
          component={PageSettings}
        />
        <Route
          path="/:appname/editor/build/accessibility"
          component={Accessibility}
        />
        <Route path="/:appname/editor/build/privacy" component={Privacy} />
        {/* <Route
          path="/:appname/editor/build/admins"
          render={() => (
            <div className="row middle-xs">
              <div className="col" style={{ paddingRight: '10px' }}>
                <FloatingActionButton icon={<AddIcon />} />
              </div>
              <div className="col" style={{ paddingRight: '10px' }}>
                <CTAButton
                  label="Publish"
                  {...publishButtonProps()}
                  onClick={props.onPublish}
                />
              </div>
            </div>
          )}
        /> */}
        <Route path="/:appname/editor/build/admins" component={Admins} />
        <Route
          path="/:appname/editor/build/custom-emails-admins"
          component={CustomEmailsAdmin}
        />
      </Switch>
    </Sidebar>
  );
};

export default withRouter(BuildInterface);
