import React from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { StyledVideo, PlayPause, VideoWrapper } from './styles';
import Carousel from '../../../../../../app/components/carousel';

const VideoCarousel = ({ urls = [] }) => {
  const toggleVideoState = e => {
    const overlayBtn = e.target;
    const video = e.target.previousSibling;
    if (!video) return;
    if (video.paused) {
      video.play();
      overlayBtn.style.opacity = 0;
      overlayBtn.style.backgroundImage =
        'url(https://beezer-img-storage.s3.eu-west-2.amazonaws.com/771baca7-1a66-4e8f-a109-a6a3decbdb07/pause-circle.svg)';
    } else {
      video.pause();
      overlayBtn.style.opacity = 1;
      overlayBtn.style.backgroundImage =
        'url(https://beezer-img-storage.s3.eu-west-2.amazonaws.com/771baca7-1a66-4e8f-a109-a6a3decbdb07/play-circle.svg)';
    }
  };

  const displayPauseButton = e => {
    const video = e.target;
    if (!video) return;
    const overlayBtn = video.nextElementSibling;
    overlayBtn.style.backgroundImage =
      'url(https://beezer-img-storage.s3.eu-west-2.amazonaws.com/771baca7-1a66-4e8f-a109-a6a3decbdb07/pause-circle.svg)';
  };

  const displayPlayButton = e => {
    const video = e.target;
    if (!video) return;
    const overlayBtn = video.nextElementSibling;
    overlayBtn.style.backgroundImage =
      'url(https://beezer-img-storage.s3.eu-west-2.amazonaws.com/771baca7-1a66-4e8f-a109-a6a3decbdb07/play-circle.svg)';
  };

  return (
    <Carousel>
      {map(urls, (url, key) => (
        <VideoWrapper key={key}>
          <StyledVideo
            src={url}
            controls
            preload="metadata"
            controlsList="nodownload noplaybackrate"
            disablePictureInPicture
            onPause={displayPlayButton}
            onPlay={displayPauseButton}
            onClick={e => {
              e.preventDefault();
            }}
          />
          <PlayPause
            tabIndex={0}
            onClick={toggleVideoState}
            onKeyDown={toggleVideoState}
          />
        </VideoWrapper>
      ))}
    </Carousel>
  );
};

VideoCarousel.propTypes = {
  urls: PropTypes.array.isRequired,
};
export default VideoCarousel;
