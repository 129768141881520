import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import Toggle from 'material-ui/Toggle';
import Divider from 'material-ui/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import { Trans, useTranslation } from 'react-i18next';

import themeStyles from '../../../../../../_export.scss';
import '../../styles.scss';

const styles = {
  root: {
    width: '100%',
  },
  underline: {
    borderColor: 'rgb(0, 124, 143)',
    borderWidth: '1px',
  },
  chip: {
    margin: 4,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const SignupAs = ({
  enabled,
  toggleSignupAs,
  updateSelectedGroups,
  chunks,
  signupGroups,
}) => {
  const { t } = useTranslation();
  const [groups, setGroups] = useState([]);

  const handleChange = event => {
    setGroups(event.target.value);
    updateSelectedGroups(event.target.value);
  };

  React.useEffect(() => {
    setGroups(signupGroups);
  }, [signupGroups]);

  return (
    <div>
      <div className="auth-panel">
        <div className="auth-panel-title">
          <Trans>Signup As</Trans>
        </div>
        <div className="auth-panel">
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => toggleSignupAs(b)}
            toggled={enabled}
            label={t('Enabled')}
            trackSwitchedStyle={{
              backgroundColor: themeStyles.primaryColorLight,
            }}
            thumbSwitchedStyle={{ backgroundColor: themeStyles.primaryColor }}
          />
          <p>
            <Trans>
              On the signup page of your app, dropdown will be displayed to your
              users to select groups they can sign-up to
            </Trans>
          </p>
          <p style={{ color: 'red', fontSize: '.6rem' }}>
            <Trans>
              Note - Default group and Groups with active subscription plans are
              not displayed.
            </Trans>
          </p>
          <InputLabel id="demo-mutiple-checkbox-label">
            <Trans>Select Groups</Trans>
          </InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            disabled={!enabled}
            value={groups}
            style={{ ...styles.root }}
            onChange={handleChange}
            input={<Input />}
            renderValue={selected =>
              Object.entries(chunks).length > 0 &&
              selected.length > 0 && (
                <div className={styles.chips}>
                  {selected.map(value => (
                    <Chip
                      key={value}
                      label={chunks[value] && chunks[value].name}
                      className={styles.chip}
                    />
                  ))}
                </div>
              )
            }
            MenuProps={MenuProps}
          >
            {Object.keys(chunks).map(
              key =>
                key !== 'default' &&
                !chunks[key].subscriptionPlan && (
                  <MenuItem key={chunks[key].name} value={key}>
                    <Checkbox
                      checked={groups.indexOf(key) > -1}
                      style={{ color: themeStyles.primaryColor }}
                    />
                    <ListItemText primary={chunks[key].name} />
                  </MenuItem>
                ),
            )}
          </Select>
          <Divider />
        </div>
      </div>
    </div>
  );
};

SignupAs.propTypes = {
  enabled: PropTypes.bool,
  url: PropTypes.string,
  toggleSignupAs: PropTypes.func.isRequired,
  changePrivacyPolicyUrl: PropTypes.func.isRequired,
};

SignupAs.defaultProps = {
  enabled: false,
  url: '',
};

export default SignupAs;
