import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppSumoBilling from './AppSumoBilling';
import { getAppSumoBilling } from './actions';
import { getSpecialComponents } from '../../../editor/modules/build/modules/create/createComponent/actions';
import { launchCancelSpecialAddonDialog } from '../subscriptions/actions';

const mapStateToProps = state => ({
  billing: state.account.appSumoBilling,
  intl: state.account.intl,
  createdAt: state.account.auth.profile.created_at,
  profile: state.account.auth.profile,
  specialAddons: state.editor.build.create.createComponent.specialAddons,
  appname: state.app.appContext.appname,
});

const mapDispatchToProps = dispatch => {
  return {
    getAppSumoBilling: () => dispatch(getAppSumoBilling()),
    getSpecialComponents: () => dispatch(getSpecialComponents()),
    onCancelAddon: (component, period, subscriptionId, id, appname) =>
      dispatch(
        launchCancelSpecialAddonDialog(
          component,
          period,
          subscriptionId,
          id,
          appname,
        ),
      ),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppSumoBilling),
);
