import firebase from '../../../../../../../firebase';
import { capitaliseString } from '../../../../../../../utils';

const addingVoteItem = lazyDataId => ({
  type: 'ADDING_VOTE_ITEM',
  key: lazyDataId,
});

const reportErr = (lazyDataId, err) => ({
  type: 'VOTE_ITEM_ERROR',
  key: lazyDataId,
  err,
});

export const fieldChange = (appname, dataId, field, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child(field).set(value);

  return {
    type: 'VOTE_FIELD_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};
export const addVoteItem = (appname, dataId, title) => dispatch => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}/options`);
  const baseItem = {
    subtitle: '',
    avatar: '',
    title,
  };

  dispatch(addingVoteItem(dataId));

  const itemRef = dataRef.child('chunks').push(baseItem);
  return dataRef
    .child('order')
    .transaction(items => {
      if (!items) return [itemRef.key];

      return items.concat(itemRef.key);
    })
    .then(data => data.snapshot.val())
    .catch(err => dispatch(reportErr(err)));
};

const findIndex = (array, element) => {
  if (element === null) {
    return array.length;
  }

  return array.indexOf(element);
};

const moveArray = (oldIndex, newIndex, arr) => {
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const rearrangeItems = (appname, lazydata, id, siblingId) => {
  const itemsRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${lazydata}/options/order`);
  itemsRef.transaction(array => {
    if (array === null) return null;

    const oIndex = findIndex(array, id);
    let nIndex = findIndex(array, siblingId);

    if (nIndex > oIndex) {
      nIndex -= 1;
    }

    return moveArray(oIndex, nIndex, array);
  });

  return {
    type: 'REARRANGE_VOTE_ITEM',
    id,
    siblingId,
  };
};

export const setPropVote = (
  appname,
  componentId,
  propName,
  value,
  ref,
) => dispatch =>
  firebase
    .database()
    .ref(
      `apps/${appname}/draft/lazy_data/${componentId}${
        ref !== '' ? `/${ref}` : ''
      }`,
    )
    .child(propName)
    .set(value)
    .then(() =>
      dispatch({
        type: 'SET_PROP_VOTE',
        appname,
        componentId,
        propName,
      }),
    )
    .catch(error => dispatch(reportErr(componentId, error)));

export const handleButtonDesignChange = (appname, key, type, value, prop) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${key}/design`);
  const attr = prop ? `${prop}/${type}` : type;

  dataRef.child(attr).set(value);

  return {
    type: 'BUTTON_DESIGN_CHANGE',
    appname,
    key,
    designType: type,
    value,
  };
};

const receiveGaTrackingId = gaTrackingId => ({
  type: 'RECEIVE_GA_TRACKING_ID',
  data: gaTrackingId,
});

export const loadGoogleAnalytics = appname => async dispatch => {
  const gaRef = firebase.database().ref(`apps/${appname}/gaTrackingId`);
  try {
    const snapshot = await gaRef.once('value');
    const gaTrackingId = await snapshot.val();
    return dispatch(receiveGaTrackingId(gaTrackingId));
  } catch (err) {
    console.error(err);
  }
};

const addingFormField = (appname, id, value) => ({
  type: 'ADDING_VOTE_ITEM',
  appname,
  id,
  value,
});

export const addField = (appname, dataId, value) => dispatch => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}/fields`);
  const key = dataRef.child('chunks').push({
    type: value,
    label: capitaliseString(value),
    required: true,
  }).key;

  dispatch(addingFormField(appname, dataId, value));

  return dataRef
    .child('order')
    .transaction(items => {
      if (!items) return [key];

      return [...items, key];
    })
    .then(data => data.snapshot.val())
    .catch(err => dispatch(reportErr(err)));
};

export const removeField = (appname, dataId, index, key) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}/fields`);

  dataRef
    .child('order')
    .transaction(items => [
      ...items.slice(0, index),
      ...items.slice(index + 1),
    ]);

  dataRef
    .child('chunks')
    .child(key)
    .remove();

  return {
    type: 'FORM_REMOVE_FIELD',
    appname,
    dataId,
    key,
    index,
  };
};

export const rearrangeField = (
  appname,
  lazydata,
  id,
  siblingId,
) => dispatch => {
  const itemsRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${lazydata}/fields/order`);

  itemsRef
    .transaction(array => {
      if (array === null) return null;

      const oIndex = findIndex(array, id);
      let nIndex = findIndex(array, siblingId);

      if (nIndex > oIndex) {
        nIndex -= 1;
      }

      return moveArray(oIndex, nIndex, array);
    })
    .then(
      dispatch({
        type: 'REARRANGE_FORM_FIELD',
        id,
        siblingId,
      }),
    );
};
