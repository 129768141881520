import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { CircularProgress } from '../../../../../../../../app/components/progress';
import { FlatButton } from '../../../../../../../../app/components/buttons';
import DialogConfirmation from '../../../../../../../../app/components/dialogConfirmation';
import './styles.css';

const Create = ({
  children,
  title,
  subtext,
  legend,
  isValid,
  pending,
  submitLabel,
  onCancel,
  onSubmit,
  className,
  match,
  loginEnabled,
  toggleAuth,
}) => {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);
  const displaySubtext = subtext ? <p>{subtext}</p> : null;
  const displayLegend = legend ? (
    <div className="radio-menu legend">{legend}</div>
  ) : null;
  const pendingIndicator = pending ? <CircularProgress /> : null;

  const handleSubmit = () => {
    if (submitLabel.includes('Feedback') && !loginEnabled) {
      setShowDialog(true);
      return;
    }
    onSubmit();
  };

  const handleConfirm = () => {
    toggleAuth(match.params.appname, true);
    onSubmit();
  };
  const buttonCancel =
    !pending && onCancel ? (
      <FlatButton
        style={{ margin: 20 }}
        label={t('Cancel')}
        onClick={onCancel}
        disabled={pending}
        primaryWhite
      />
    ) : null;

  const buttonContinue = !pending ? (
    <FlatButton
      style={{ margin: 20 }}
      label={submitLabel}
      onClick={handleSubmit}
      disabled={!isValid || pending}
      primary
    />
  ) : null;

  return (
    <div>
      <div className={`create-content ${className}`}>
        <h2>{title}</h2>
        {displaySubtext}
        {displayLegend}
        <div className="create-form">{children}</div>
        <div className="create-controls">
          {buttonCancel}
          {pendingIndicator}
          {buttonContinue}
        </div>
      </div>
      {showDialog && (
        <DialogConfirmation
          open={showDialog}
          onClose={() => setShowDialog(false)}
          message={`${t(
            'To use video feedback component, you need to enable Member login.',
          )} <br/>${t('Would you like to enable it now?')}'
          `}
          action={handleConfirm}
        />
      )}
    </div>
  );
};

Create.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtext: PropTypes.string,
  legend: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  pending: PropTypes.bool,
  submitLabel: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loginEnabled: PropTypes.bool.isRequired,
  toggleAuth: PropTypes.func.isRequired,
};

Create.defaultProps = {
  title: null,
  subtext: null,
  legend: null,
  pending: false,
  isValid: true,
  onCancel: null,
};

export default withRouter(Create);
