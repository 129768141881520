import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import EnhancedButton from 'material-ui/internal/EnhancedButton';
import VisibilityOff from 'material-ui/svg-icons/action/visibility-off';

import Pip from '../../../../../../components/pip';

import '../viewItem/styles.css';

const styles = {
  iconButton: {
    display: 'block',
    position: 'absolute',
    top: 0,
  },
  icon: {
    width: 18,
    height: 18,
  },
};

const TabItem = ({
  label,
  href,
  hidden,
  active,
  history,
  hasUnavailaleComponent,
}) => {
  const pip = hasUnavailaleComponent ? <Pip /> : null;
  const hiddenIcon = hidden && (
    <VisibilityOff
      color="rgb(167, 169, 172)"
      style={{ height: 18, width: 18, marginRight: 12 }}
    />
  );

  return (
    <EnhancedButton
      onClick={() => history.push(href)}
      containerElement={
        <button className={`tab-item ${active ? 'active' : ''}`} tabIndex={0} />
      }
    >
      <div className="tab-item-inner-div" style={styles.innerDiv}>
        <span className="item-validation" />
        <span className="tab-item-label">
          {label}
          {pip}
        </span>
        {hiddenIcon}
      </div>
    </EnhancedButton>
  );
};

TabItem.propTypes = {
  label: PropTypes.string,
  href: PropTypes.object,
  hidden: PropTypes.bool,
  active: PropTypes.bool,
  history: PropTypes.object.isRequired,
  hasUnavailaleComponent: PropTypes.bool.isRequired,
};

TabItem.defaultProps = {
  href: null,
  hidden: false,
  active: false,
};

TabItem.defaultProps = {
  label: '',
  tabs: [],
};

export default withRouter(TabItem);
