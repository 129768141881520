import styles from './_export.scss';

export default {
  palette: {
    primary1Color: styles.primaryColor,
    primary2Color: styles.primaryColorDark,
    accent1Color: styles.secondaryColor,
    accent2Color: styles.secondaryColor,
    textColor: '#000',
    alternateTextColor: '#fff',
  },
  fontFamily: 'Raleway, sans-serif',
  checkbox: {
    boxColor: styles.primaryColorDark,
    checkedColor: styles.primaryColor,
  },
  stepper: {
    iconColor: styles.primaryColor,
    inactiveIconColor: styles.mainGrey,
  },
  toggle: {
    trackOnColor: styles.primaryColorLight,
    thumbOnColor: styles.primaryColor,
  },
  tabs: {
    backgroundColor: styles.mainGrey,
    textColor: styles.primaryColor,
    selectedTextColor: styles.primaryColor,
  },
  inkBar: {
    backgroundColor: styles.primaryColor,
  },
  radioButton: {
    borderColor: styles.primaryColor,
    checkedColor: styles.primaryColor,
  },
  slider: {
    trackColorSelected: styles.secondaryColor,
    handleFillColor: styles.primaryColor,
    selectionColor: styles.primaryColor,
    handleColorZero: styles.secondaryColor,
    rippleColor: styles.primaryColor,
    disabledColor: styles.disabledColor,
  },
};
