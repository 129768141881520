import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Contacts from './Contacts';

import {
  loadContacts,
  searchContacts,
  selectContact,
  selectAllContacts,
  deselectAllContacts,
  deleteMultipleContacts,
  getWorkforceGroups,
  setType,
  selectGroup,
  selectAllGroups,
  deselectAllGroups,
  selectWorkforceGroup,
  loadGroups,
  getGroups,
  getUsers,
} from './actions';
import { openDistributeDialog, closeDistributeDialog } from '../../actions';

import { openEditContactDialog } from './editContact/actions';
import { openNewContactDialog } from './newContact/actions';
import { getDefaultWhatsappCredits } from '../../../../utils';
const mapStateToProps = (state, ownProps) => {
  const isAppAdmin =
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false;
  let whatsappSMSCredit = state.account.organisation.whatsapp_sms_credit;

  const trialExpired = state.account.organisation.trial_expired;

  const appSubscription = state.app.appContext.subscription;
  //if whatsapp credits does not exists on account get the default amount
  if (whatsappSMSCredit === undefined || whatsappSMSCredit === null) {
    whatsappSMSCredit = getDefaultWhatsappCredits({
      isAppAdmin,
      trialExpired,
      appSubscription,
    });
  }
  return {
    ...ownProps,
    ...state.distribute.contacts.data,
    sms_restricted: state.account.organisation.sms_restricted || false,
    subscription: state.app.appContext.subscription,
    smsCredit: state.account.organisation.sms_credit,
    organisation: state.account.organisation,
    integration: state.account.organisation.integration,
    customDomain: state.app.customDomain,
    //groups section below
    groupDataPending: state.distribute.contacts.data.pending,
    groupFilteredChunks: state.auth.groups.data.filteredChunks,
    groupItems: state.auth.groups.data.items,
    isAppAdmin,
    whatsappSMSCredit,
    accountId: state.account.profile.account,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  openDistributeDialog: () => dispatch(openDistributeDialog()),
  closeDistributeDialog: customDomain =>
    dispatch(closeDistributeDialog(customDomain)),
  loadContacts: () => dispatch(loadContacts(ownProps.match.params.appname)),
  loadGroups: () => dispatch(loadGroups(ownProps.match.params.appname)),

  addContact: () => dispatch(openNewContactDialog()),
  onSearch: query => dispatch(searchContacts(query)),
  onSelect: key => dispatch(selectContact(key)),
  onGroupSelect: key => dispatch(selectGroup(key)),
  onSelectAllGroups: key => dispatch(selectAllGroups(key)),
  onDeselectAllGroups: key => dispatch(deselectAllGroups(key)),
  onWorkforceGroupSelect: key => dispatch(selectWorkforceGroup(key)),
  onSelectAllContacts: () => dispatch(selectAllContacts()),
  onDeselectAllContacts: () => dispatch(deselectAllContacts()),
  onDeleteSelected: selected =>
    dispatch(deleteMultipleContacts(ownProps.match.params.appname, selected)),
  onEditContact: key => dispatch(openEditContactDialog(key)),
  setType: f => dispatch(setType(f)),
  resetSelection: () => dispatch(deselectAllContacts()),
  getGroups: () => dispatch(getGroups(ownProps.match.params.appname)),
  getWorkforceGroups: () =>
    dispatch(getWorkforceGroups(ownProps.match.params.appname)),
  getUsers: () => dispatch(getUsers(ownProps.match.params.appname)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contacts),
);
