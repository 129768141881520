import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { changePageAccess } from './actions';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';

const PagesSelector = ({ open, onClose, match }) => {
  const { t } = useTranslation();
  const pages = useSelector(state => get(state, `editor.structure.routes`, []));
  const appGroups = useSelector(state =>
    get(state, 'auth.groups.data.chunks', {}),
  );

  const [searchText, setSearchText] = useState('');
  const [publicPages, setPublicPages] = useState(
    pages.filter(page => get(page, 'publicPage', false)),
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const publicPageKeys = publicPages.map(page => page.key);

  useEffect(() => {
    !isEmpty(pages) &&
      setPublicPages(pages.filter(page => get(page, 'publicPage', false)));
  }, [pages]);

  const getPagesInGroup = () => {
    let groupPages = [];
    if (Object.keys(appGroups).length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in appGroups) {
        if (appGroups[key].pages !== undefined) {
          groupPages = [...groupPages, ...Object.keys(appGroups[key].pages)];
        }
      }
    }
    return groupPages;
  };

  const addPublicPage = page => {
    const pagesInGroup = getPagesInGroup();
    if (pagesInGroup.includes(page.key)) {
      dispatch(openSnackbar(t('This page already exist in a group.')));
      return;
    }
    setPublicPages([...publicPages, page]);
  };

  const removePublicPage = page => {
    const modifiedPublicPages = [...publicPages];

    const pageToRemove = modifiedPublicPages.findIndex(
      publicPage => publicPage.key === page.key,
    );
    if (pageToRemove > -1) {
      modifiedPublicPages.splice(pageToRemove, 1);
      setPublicPages(modifiedPublicPages);
    }
  };

  const filteredPrivatePages = pages.filter(
    page =>
      !publicPageKeys.includes(get(page, 'key', '')) &&
      get(page, 'title', '')
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase()),
  );
  // .sort((page1, page2) =>
  //   get(page1, 'title', '').localeCompare(get(page2, 'title', '')),
  // );

  const filteredPublicPages = publicPages
    .filter(page =>
      get(page, 'title', '')
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase()),
    )
    .sort((page1, page2) =>
      get(page1, 'title', '').localeCompare(get(page2, 'title', '')),
    );

  const saveAccessSettings = async () => {
    const privatePages = pages.filter(
      page => !publicPageKeys.includes(get(page, 'key', '')),
    );
    setLoading(true);
    await dispatch(
      changePageAccess({
        appname: match.params.appname,
        publicPages,
        privatePages,
      }),
    );
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="page-selector-dialog"
      fullWidth
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        <Trans>Add Public Pages</Trans>
      </DialogTitle>
      <DialogContent className="page-selector-dialog-body">
        {!loading ? (
          <>
            <div className="page-selector-body scroll-container">
              <TextField
                onChange={e => setSearchText(e.target.value)}
                value={searchText}
                variant="outlined"
                color="primary"
                fullWidth
                placeholder={t('Search pages')}
              />

              <div className="page-selector-all-pages">
                <div className="private-pages">
                  <p className="page-list-section-title">
                    <Trans>Private Pages</Trans>
                  </p>
                  {!isEmpty(pages) ? (
                    !isEmpty(filteredPrivatePages) ? (
                      filteredPrivatePages.map(page => (
                        <div className="page-data">
                          <div className="title">{get(page, 'title', '')}</div>
                          <IconButton
                            color="primary"
                            onClick={() => addPublicPage(page)}
                          >
                            <AddCircle className="align-icon" />
                          </IconButton>
                        </div>
                      ))
                    ) : (
                      <p className="pages-selector-empty-message">
                        <Trans>No matching pages found.</Trans>
                      </p>
                    )
                  ) : (
                    <p className="pages-selector-empty-message">
                      <Trans>
                        No pages available. Start by adding pages to your app.
                      </Trans>
                    </p>
                  )}
                </div>

                <div className="public-pages">
                  <p className="page-list-section-title">
                    <Trans>Public Pages</Trans>
                  </p>

                  {!isEmpty(publicPages) ? (
                    !isEmpty(filteredPublicPages) ? (
                      filteredPublicPages.map(page => (
                        <div className="page-data">
                          <div className="title">{get(page, 'title', '')}</div>
                          <IconButton
                            color="error"
                            onClick={() => removePublicPage(page)}
                          >
                            <RemoveCircle className="align-icon" />
                          </IconButton>
                        </div>
                      ))
                    ) : (
                      <p className="pages-selector-empty-message">
                        <Trans>No matching pages found.</Trans>
                      </p>
                    )
                  ) : (
                    <p className="pages-selector-empty-message">
                      <Trans>No public pages added yet.</Trans> <br />
                      <Trans>
                        Click
                      </Trans> <AddCircle className="align-icon" />{' '}
                      <Trans>button</Trans>{' '}
                      <Trans>
                        next to the page to add that page as a public page.
                      </Trans>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="page-selector-action-container">
              <Button
                variant="contained"
                color="primary"
                onClick={saveAccessSettings}
              >
                <Trans>Save</Trans>
              </Button>
            </div>
          </>
        ) : (
          <CircularProgress
            size={80}
            color="primary"
            className="page-selector-loading"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default withRouter(PagesSelector);
