import React from 'react';
import PropTypes from 'prop-types';
import Sidebar, { SidebarItem } from '../../../../app/components/sidebar';

import { PlMatchSupportIcon, RegionalPerformanceIcon } from '../../icons';
import ViewApp from '../../../../app/components/buttons/viewApp/';

const PgmolMenu = ({ match, location }) => {
  const baseHref = `/${match.params.appname}/pgmol`;
  const activeCheck = prefix =>
    location.pathname === `/${match.params.appname}/pgmol/${prefix}`;

  return (
    <Sidebar animate>
      <ViewApp isPublished match={match} />
      <SidebarItem
        label="Regional Performance Centres"
        href={`${baseHref}/regional-performance-centre`}
        active={activeCheck('regional-performance-centre')}
        icon={<RegionalPerformanceIcon />}
      />
      <SidebarItem
        label="PL Match Day Support"
        href={`${baseHref}/pl-match-support`}
        active={activeCheck('pl-match-support')}
        icon={<PlMatchSupportIcon />}
      />

      {/* <SidebarItem
        label="Blog"
        href={`${baseHref}/blog`}
        active={activeCheck('blog')}
        icon={<PlMatchSupportIcon />}
      /> */}
    </Sidebar>
  );
};

PgmolMenu.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isPublished: PropTypes.bool,
};

PgmolMenu.defaultProps = {
  integration: null,
  isPublished: false,
};

export default PgmolMenu;
