import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Equalities from './Equalities';
import { getTypes } from '../../../../../memberLogin/modules/memberTypes/modules/listing/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  workforceGroups: state.auth.memberGroups.listPage.data.chunks,
  usersData: state.auth.users.chunks,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getTypes: () => dispatch(getTypes(ownProps.match.params.appname)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Equalities),
);
