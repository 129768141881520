export const industries = [
  { name: 'Accounting' },
  { name: 'Airlines/Aviation' },
  { name: 'Alternative Medicine' },
  { name: 'Animation' },
  { name: 'Apparel/Fashion' },
  { name: 'Architecture/Planning' },
  { name: 'Arts/Crafts' },
  { name: 'Automotive' },
  { name: 'Aviation/Aerospace' },
  { name: 'Banking/Mortgage' },
  { name: 'Biotechnology/Greentech' },
  { name: 'Broadcast Media' },
  { name: 'Building Materials' },
  { name: 'Business Supplies/Equipment' },
  { name: 'Capital Markets/Hedge Fund/Private Equity' },
  { name: 'Chemicals' },
  { name: 'Civic/Social Organization' },
  { name: 'Civil Engineering' },
  { name: 'Commercial Real Estate' },
  { name: 'Computer Games' },
  { name: 'Computer Hardware' },
  { name: 'Computer Software/Engineering' },
  { name: 'Construction' },
  { name: 'Consumer Electronics' },
  { name: 'Consumer Goods' },
  { name: 'Consumer Services' },
  { name: 'Cosmetics' },
  { name: 'Design' },
  { name: 'E-Learning' },
  { name: 'Education Management' },
  { name: 'Entertainment/Movie Production' },
  { name: 'Environmental Services' },
  { name: 'Events Services' },
  { name: 'Farming' },
  { name: 'Financial Services' },
  { name: 'Fine Art' },
  { name: 'Food Production' },
  { name: 'Fundraising' },
  { name: 'Furniture' },
  { name: 'Gambling/Casinos' },
  { name: 'Glass/Ceramics/Concrete' },
  { name: 'Government Administration' },
  { name: 'Government Relations' },
  { name: 'Graphic Design/Web Design' },
  { name: 'Health/Fitness' },
  { name: 'Higher Education/Acadamia' },
  { name: 'Hospital/Health Care' },
  { name: 'Hospitality' },
  { name: 'Human Resources/HR' },
  { name: 'Import/Export' },
  { name: 'Individual/Family Services' },
  { name: 'Industrial Automation' },
  { name: 'Information Services' },
  { name: 'Information Technology/IT' },
  { name: 'Insurance' },
  { name: 'International Affairs' },
  { name: 'International Trade/Development' },
  { name: 'Internet' },
  { name: 'Investment Banking/Venture' },
  { name: 'Investment Management/Hedge Fund/Private Equity' },
  { name: 'Judiciary' },
  { name: 'Law Enforcement' },
  { name: 'Law Practice/Law Firms' },
  { name: 'Legal Services' },
  { name: 'Legislative Office' },
  { name: 'Leisure/Travel' },
  { name: 'Library' },
  { name: 'Logistics/Procurement' },
  { name: 'Luxury Goods/Jewelry' },
  { name: 'Machinery' },
  { name: 'Management Consulting' },
  { name: 'Maritime' },
  { name: 'Market Research' },
  { name: 'Marketing/Advertising/Sales' },
  { name: 'Mechanical or Industrial Engineering' },
  { name: 'Media Production' },
  { name: 'Medical Equipment' },
  { name: 'Medical Practice' },
  { name: 'Mental Health Care' },
  { name: 'Military Industry' },
  { name: 'Mining/Metals' },
  { name: 'Motion Pictures/Film' },
  { name: 'Museums/Institutions' },
  { name: 'Music' },
  { name: 'Newspapers/Journalism' },
  { name: 'Non-Profit/Volunteering' },
  { name: 'Oil/Energy/Solar/Greentech' },
  { name: 'Online Publishing' },
  { name: 'Outsourcing/Offshoring' },
  { name: 'Package/Freight Delivery' },
  { name: 'Packaging/Containers' },
  { name: 'Paper/Forest Products' },
  { name: 'Performing Arts' },
  { name: 'Pharmaceuticals' },
  { name: 'Philanthropy' },
  { name: 'Photography' },
  { name: 'Plastics' },
  { name: 'Political Organization' },
  { name: 'Primary/Secondary Education' },
  { name: 'Printing' },
  { name: 'Professional Training' },
  { name: 'Program Development' },
  { name: 'Public Relations/PR' },
  { name: 'Public Safety' },
  { name: 'Publishing Industry' },
  { name: 'Railroad Manufacture' },
  { name: 'Ranching' },
  { name: 'Real Estate/Mortgage' },
  { name: 'Recreational Facilities/Services' },
  { name: 'Religious Institutions' },
  { name: 'Renewables/Environment' },
  { name: 'Research Industry' },
  { name: 'Restaurants' },
  { name: 'Retail Industry' },
  { name: 'Security/Investigations' },
  { name: 'Semiconductors' },
  { name: 'Shipbuilding' },
  { name: 'Sporting Goods' },
  { name: 'Sports Club' },
  { name: 'Staffing/Recruiting' },
  { name: 'Supermarkets' },
  { name: 'Telecommunications' },
  { name: 'Textiles' },
  { name: 'Tobacco' },
  { name: 'Translation/Localization' },
  { name: 'Transportation' },
  { name: 'Utilities' },
  { name: 'Venture Capital/VC' },
  { name: 'Veterinary' },
  { name: 'Warehousing' },
  { name: 'Wholesale' },
  { name: 'Wine/Spirits' },
  { name: 'Writing/Editing' },
];
