import moment from 'moment';
import { validateField } from '../../validators';
import firebase from '../../../../../../firebase';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import { COURSE_CREATED, MEMBERBODY_REQUIRED } from '../../config';
import { objectToArray } from '../../../../../../utils';

export const NEW_FIELD_CHANGE_COURSES = 'NEW_FIELD_CHANGE_COURSES';
export const OPEN_NEW_DIALOG_COURSES = 'OPEN_NEW_DIALOG_COURSES';
export const CLOSE_NEW_DIALOG_COURSES = 'CLOSE_NEW_DIALOG_COURSES';
export const ADD_NEW_COURSES = 'ADD_NEW_COURSES';
export const ADDED_COURSES = 'ADDED_COURSES';
export const SET_RECURRING_DATA = 'SET_RECURRING_DATA';

const newFieldChange = (prop, id, value, isValid) => ({
  type: NEW_FIELD_CHANGE_COURSES,
  prop,
  id,
  value,
  isValid,
});

export const setRecurringData = (id, value) => ({
  type: SET_RECURRING_DATA,
  id,
  value,
});

export const openNewCourseDialog = () => (dispatch, getState) => {
  const { memberbody } = getState().staff.courses.list;
  if (memberbody) {
    dispatch({
      type: OPEN_NEW_DIALOG_COURSES,
    });
  } else {
    dispatch(openSnackbar(MEMBERBODY_REQUIRED));
  }
};

export const closeNewCourseDialog = () => ({
  type: CLOSE_NEW_DIALOG_COURSES,
});

export const formFieldChange = (isRequired, prop, id, value, index) => (
  dispatch,
  getState,
) => {
  let data = value;
  const valid = data ? validateField[prop](data) : null;
  const isValid = isRequired ? valid : true;
  // merge dates to array [fromDate, toDate]
  if (prop === 'DateRange' || prop === 'Daterange') {
    const dateRange = getState().staff.courses.create.fieldsData[id].value;
    if (index === 0) {
      data = dateRange[1] ? [value, dateRange[1]] : [value, value];
    } else {
      data = dateRange[0] ? [dateRange[0], value] : [value, value];
    }
  }
  return dispatch(newFieldChange(prop, id, data, isValid));
};

export const addCourse = appname => (dispatch, getState) => {
  const {
    fieldsData,
    isRecurringCourse,
    recurringType,
    recurringValue,
  } = getState().staff.courses.create;
  const { memberbody, fields } = getState().staff.courses.list;

  fieldsData.createdAt = Date.now();
  fieldsData.memberbody = memberbody;
  dispatch({ type: ADD_NEW_COURSES });
  if (isRecurringCourse) {
    const allFields = objectToArray(fields);

    const dateRangeField = allFields.find(
      item => item.value.type === 'DateRange',
    );

    const { id } = dateRangeField;
    const courseDate = fieldsData[id].value[0];
    for (let i = 0; i < parseInt(recurringValue, 10); i += 1) {
      const newDate = moment(courseDate)
        .add(i, recurringType)
        .valueOf();
      fieldsData[id].value[0] = newDate;
      fieldsData[id].value[1] = newDate;
      fieldsData.isRecurringCourse = isRecurringCourse;
      fieldsData.recurringType = recurringType;
      fieldsData.recurringValue = recurringValue;
      const ref = firebase
        .database()
        .ref(`app_courses/${appname}/courses/${memberbody}`);
      const newRef = ref.push(fieldsData);
      dispatch({
        type: ADDED_COURSES,
        key: newRef.key,
        data: fieldsData,
      });
    }

    dispatch(openSnackbar(COURSE_CREATED));
  } else {
    return firebase
      .database()
      .ref(`app_courses/${appname}/courses/${memberbody}`)
      .push(fieldsData)
      .then(ref => {
        dispatch({
          type: ADDED_COURSES,
          key: ref.key,
          data: fieldsData,
        });
        dispatch(openSnackbar(COURSE_CREATED));
      });
  }
};
