import React from 'react';
import PropTypes from 'prop-types';

import { InnerLayout } from '../../components';
import NewUserForm from '../../newUser';

import illustrationExpired from './offer-expired.png';
import illustrationUnavailable from './offer-unavailable.png';

import './styles.css';

const expiredTextIntro = (
  <span>
    Oh no, it looks like the promotion you were looking for has expired. We’re
    so sorry that you missed out on this one.
  </span>
);
const unavailableTextIntro = (
  <span>
    Ooops! There appears to have been a problem, as the promotion you are
    looking for doesn’t seem to exist on our records.
  </span>
);
const expiredTextDescription = (
  <span>
    However, you’re still welcome to sign up to our platform at any time!{' '}
    <b>
      Enjoy our fully featured 30-day trial by providing your details{' '}
      <span className="desktop-text">on the right</span>
      <span className="mobile-text">below</span>
    </b>
    , and you can get on with building an awesome mobile app with Beezer’s
    platform… What are you waiting for?
  </span>
);
const unavailableTextDescription = (
  <span>
    You can go back and double-check that you have the correct link, or
    <b>
      {' '}
      simply fill in your details{' '}
      <span className="desktop-text">on the right</span>
      <span className="mobile-text">below</span> and start your 30-day fully
      featured trial with Beezer straight away!
    </b>{' '}
    Create, customize and distribute awesome mobile apps… What are you waiting
    for?
  </span>
);

const OfferError = ({ onSubmit, expired }) => (
  <InnerLayout
    content={
      <div>
        <p>{expired ? expiredTextIntro : unavailableTextIntro}</p>
        <p>
          <img
            className="error-illustration"
            src={expired ? illustrationExpired : illustrationUnavailable}
            alt=""
            width={190}
          />
        </p>
        <p>{expired ? expiredTextDescription : unavailableTextDescription}</p>
      </div>
    }
    form={
      <div>
        <NewUserForm
          onSubmit={() => onSubmit(() => this.props.history.push('/create'))}
        />
      </div>
    }
  />
);

OfferError.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  expired: PropTypes.bool.isRequired,
};

export default OfferError;
