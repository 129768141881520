import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Tabs, Tab } from '../../../../../components/tabs';
import TextField from '../../../../../../../app/components/textField';

import Container from '../../../../../../../app/components/container';
import ErrorMessage from '../../../../../../../app/components/errorMessage';
import ImageUpload from '../../../../../internals/imageUpload';
import TextEditor from '../../../../../../../app/components/textEditor';
import SectionHeader from '../../../../../components/sectionHeader';
import DesignToggle from '../../../../../components/designToggle';
import ColorPicker from '../../../../../components/colorPicker';
import Toggle from '../../../../../../../app/components/toggle';
import firebase from '../../../../../../../firebase';

import styles from './loyalty.module.scss';

const Loyalty = props => {
  const {
    data,
    onStampsChange,
    onFreeItemChange,
    component,
    onDesignChange,
    design,
    onGetIconUrls,
  } = props;
  const [stampsNumberError, setStampsNumberError] = useState(false);
  const [freeItemError, setFreeItemError] = useState(false);
  const [iconUrls, setIconUrls] = useState([]);
  const [lockStampImage, setLockStampImage] = useState(false);
  const [lockStampImageUrl, setLockStampImageUrl] = useState('');
  const [unlockStampImage, setUnlockStampImage] = useState(false);
  const [unlockStampImageUrl, setUnlockStampImageUrl] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    firebase
      .database()
      .ref('loyalty_icons')
      .update({
        iconsArray: [
          'bagel.svg',
          'beach-chair.svg',
          'beach-umbrella.svg',
          'breakfast.svg',
          'burger (1).svg',
          'burger (2).svg',
          'burger.svg',
          'cake (1).svg',
          'cake (2).svg',
          'cake (3).svg',
          'cake (4).svg',
          'cake-slice.svg',
          'cake.svg',
          'cheeseburger.svg',
          'coffee (1).svg',
          'coffee (2).svg',
          'coffee (3).svg',
          'coffee-cup (1).svg',
          'coffee-cup.svg',
          'coffee.svg',
          'cup.svg',
          'cupcake.svg',
          'dinner.svg',
          'drink.svg',
          'dumbbell.svg',
          'eco-food.svg',
          'fast-food.svg',
          'food-donation.svg',
          'food.svg',
          'football-ball-variant.svg',
          'football-chalice-shaped-trophy.svg',
          'football-player-attempting-to-kick-ball.svg',
          'football.svg',
          'hot-cup.svg',
          'hot-tea.svg',
          'man.png',
          'meal.svg',
          'palm-tree.svg',
          'sandals-for-spa.svg',
          'soup.svg',
          'sweet-food.svg',
          'sweet.svg',
          'tea-cup.svg',
          'tea-pot.svg',
          'tea.svg',
          'weight-lifting (1).svg',
          'weight-lifting.svg',
          'weight.svg',
          'whisky (1).svg',
          'whisky (2).svg',
          'whisky.svg',
          'zumba.svg',
        ],
      });

    onGetIconUrls().then(allUrls => {
      setIconUrls(allUrls.iconUrls);
    });
  }, []);

  useEffect(() => {
    setLockStampImage(design.lockStampImage);
  }, [design.lockStampImage]);

  useEffect(() => {
    setLockStampImageUrl(design.lockStampImageUrl);
  }, [design.lockStampImageUrl]);

  useEffect(() => {
    setUnlockStampImage(design.unlockStampImage);
  }, [design.unlockStampImage]);

  useEffect(() => {
    setUnlockStampImageUrl(design.unlockStampImageUrl);
  }, [design.unlockStampImageUrl]);

  const handleDesign = (prop, value) => props.onDesignChange(prop, !value);

  const handleStampsChange = prop => value => {
    if (stampsNumberError) {
      setStampsNumberError(false);
    }

    if (isNaN(value) || value < 1 || value > 50) {
      setStampsNumberError(true);
      return;
    }
    onStampsChange(prop, value);
  };

  const handleTextChange = prop => value => props.onChange(prop, value);

  const handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    props.onDesignChange(prop, rgba);
  };

  const handleStampNumberChange = (prop, value) => {
    props.onDesignChange(prop, value);
  };

  const handleStampLogoChange = (prop, url) => {
    props.onDesignChange(prop, url);
  };

  const handleFreeItemChange = prop => value => {
    if (freeItemError) {
      setFreeItemError(false);
    }

    if (!value.length) {
      setFreeItemError(true);
      return;
    }
    onFreeItemChange(prop, value);
  };

  const toolbarButtons = {
    fonts: true,
    fontSize: true,
    bold: true,
    italic: true,
    underline: true,
    url: true,
    image: true,
    video: true,
    orderedList: true,
    unorderedList: true,
    negativeIntent: true,
    positiveIndent: true,
    textAlign: true,
    textColor: true,
    textBackground: true,
    clearTextFormatting: true,
    codeBlock: true,
    blockquote: true,
  };

  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container>
          <SectionHeader
            title={t('Title')}
            tooltip={t('A heading for your loyalty card.')}
            borderTop
          />
          <TextEditor
            id="title"
            value={data.title}
            onChange={handleTextChange('title')}
            placeholder={t('Title')}
            toolbar={toolbarButtons}
          />
          <SectionHeader
            title={t('Image Upload')}
            tooltip={t('Upload an image for your loyalty card')}
          />
          <ImageUpload
            title={t('Box > Crop Image')}
            src={props.data.image}
            firebaseUrl={`/apps/${props.match.params.appname}/draft/lazy_data/${props.component.lazy_data}/image`}
            showCropAreaSizes
            height="220px"
            width="320px"
          />
          <SectionHeader
            title={t('Message')}
            tooltip={t('A message to be displayed below your stamps')}
            borderTop
          />
          <TextEditor
            id="message"
            value={data.message}
            onChange={handleTextChange('message')}
            placeholder={t('Message')}
            toolbar={toolbarButtons}
          />
          <SectionHeader
            title={t('Winning Text')}
            tooltip={t(
              'A winning message to be displayed when all stamps are collected',
            )}
            borderTop
          />
          <TextEditor
            id="winningText"
            value={data.winningText}
            onChange={handleTextChange('winningText')}
            placeholder={t('Message')}
            toolbar={toolbarButtons}
          />
          <SectionHeader
            title="Not signed in text"
            tooltip="Text to display if user is not signed in"
            borderTop
          />
          <TextEditor
            id="notSignedInText"
            value={data.notSignedInText}
            onChange={handleTextChange('notSignedInText')}
            placeholder="Not Signed In Text"
            toolbar={toolbarButtons}
          />
          <SectionHeader
            title={t('Number of stamps')}
            tooltip={t('Number of stamps for your loyalty card')}
            borderTop
          />
          <TextField
            id="numberOfVisits"
            value={data.numberOfVisits}
            onChange={handleStampsChange('numberOfVisits')}
            placeholder={t('Number Of Stamps')}
          />
          {stampsNumberError && (
            <ErrorMessage>
              <Trans>Please enter a valid number between 1 and 50</Trans>
            </ErrorMessage>
          )}
          <SectionHeader
            title={t('Free Item')}
            tooltip={t(
              "Name of the free item you'll give on collection of all the stamps",
            )}
            borderTop
          />
          <TextField
            id="freeItem"
            value={data.freeItem}
            onChange={handleFreeItemChange('freeItem')}
            placeholder={t('Free Product')}
          />
          {freeItemError && (
            <ErrorMessage>
              <Trans>Please enter a free item</Trans>
            </ErrorMessage>
          )}
        </Container>
      </Tab>
      <Tab label={t('design')}>
        <Container>
          <DesignToggle component={component} onDesignChange={onDesignChange} />
          <div className={styles.stampbox}>
            <div className={styles.stampbox_heading}>
              <Trans>Number Of Stamps Per Row</Trans>
            </div>
            {[3, 4, 6].map((stamps, i) => (
              <div
                onClick={() => handleStampNumberChange('stampsPerRow', stamps)}
                className={`${
                  design.stampsPerRow === stamps || design.stampsPerRow === 5 // older versions included 5 stamps for component
                    ? `${styles.stamp_container} ${styles.stamp_container_active}`
                    : styles.stamp_container
                } ${!design.stampsPerRow &&
                  stamps === 6 &&
                  styles.stamp_container_active}`}
                key={i}
              >
                {[...Array(stamps)].map((stamp, j) => (
                  <div key={j} className={styles.stamp}>
                    {j + 1}
                  </div>
                ))}
              </div>
            ))}
          </div>

          <SectionHeader
            title={t('Locked Stamp Image')}
            actions={
              <Toggle
                onToggle={() =>
                  handleDesign(
                    'lockStampImage',
                    design.lockStampImage ? design.lockStampImage : false,
                  )
                }
                toggled={design.lockStampImage ? design.lockStampImage : false}
              />
            }
            tooltip={t('Choose an image to display inside a locked stamp')}
            borderTop
          />
          {lockStampImage && (
            <div className={styles.logos_container}>
              {iconUrls &&
                iconUrls.length > 0 &&
                iconUrls.map(url => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <img
                    onClick={() =>
                      handleStampLogoChange('lockStampImageUrl', url)
                    }
                    style={{
                      border: `${
                        url === lockStampImageUrl
                          ? '4px solid green'
                          : '4px solid white'
                      }`,
                    }}
                    key={url}
                    src={url}
                    alt=""
                  />
                ))}
            </div>
          )}

          <SectionHeader
            title={t('Unlocked Stamp Image')}
            actions={
              <Toggle
                onToggle={() =>
                  handleDesign(
                    'unlockStampImage',
                    design.unlockStampImage ? design.unlockStampImage : false,
                  )
                }
                toggled={
                  design.unlockStampImage ? design.unlockStampImage : false
                }
              />
            }
            tooltip={t('Choose an image to display inside a unlocked stamp')}
            borderTop
          />
          {unlockStampImage && (
            <div className={styles.logos_container}>
              {iconUrls &&
                iconUrls.length > 0 &&
                iconUrls.map(url => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <img
                    onClick={() =>
                      handleStampLogoChange('unlockStampImageUrl', url)
                    }
                    style={{
                      border: `${
                        url === unlockStampImageUrl
                          ? '4px solid green'
                          : '4px solid white'
                      }`,
                    }}
                    key={url}
                    src={url}
                    alt=""
                  />
                ))}
            </div>
          )}

          <ColorPicker
            label={t('Background Color')}
            color={design.backgroundColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('backgroundColor')}
          />
          <ColorPicker
            label={t('Stamp Border Color')}
            color={design.stampBorderColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('stampBorderColor')}
          />
          <ColorPicker
            label={t('Stamp Text Color')}
            color={design.stampTextColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('stampTextColor')}
          />
          <ColorPicker
            label={t('Stamp Locked Color')}
            color={design.stampLockedColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('stampLockedColor')}
          />
          <ColorPicker
            label={t('Stamp Unlocked Color')}
            color={design.stampUnlockedColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('stampUnlockedColor')}
          />
        </Container>
      </Tab>
      <Tab
        label={t('layout')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
    </Tabs>
  );
};

export default Loyalty;
