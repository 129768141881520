import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import { deSanitize } from '../../../utils/string';

import './styles.scss';

class TableRow extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    cols: PropTypes.array,
    data: PropTypes.object,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    cols: [],
    data: {},
    onClick: undefined,
  };

  shouldComponentUpdate(nextProps) {
    return !_isEqual(this.props, nextProps);
  }

  mapCol = (col, i) => {
    let innerContent = col.containerElement
      ? col.ids
        ? React.createElement(col.containerElement, {
            value: col.ids.reduce(
              (string, id) => `${string} ${this.props.data[id]}`,
              '',
            ),
          })
        : React.createElement(col.containerElement, {
            value: this.props.data[col.id],
            rowId: this.props.id,
          })
      : this.props.data[col.id] || '';

    if (typeof innerContent === 'string') {
      innerContent = deSanitize(innerContent);
    }
    return (
      <div
        key={`${this.props.data.id}_${col.id}`}
        className={`td ${col.classNames} ${i}`}
      >
        {innerContent}
      </div>
    );
  };

  render() {
    const cells = this.props.cols.map(this.mapCol);
    if (this.props.onClick) {
      return (
        <div
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            this.props.onClick();
          }}
          className={`row middle-xs table-row ${
            this.props.onClick ? 'touchable' : ''
          }`}
        >
          {cells}
        </div>
      );
    }

    return (
      <div
        className={`row middle-xs table-row ${
          this.props.onClick ? 'touchable' : ''
        }`}
      >
        {cells}
      </div>
    );
  }
}

export default TableRow;
