import {
  RECEIVE_BUYER_DETAILS,
  REQUEST_BUYER_DETAILS,
  RECEIVED_VENDORS,
  RECEIVED_PRODUCTS,
  STOP_RECEIVING_BUYER_DETAILS,
  NO_PRODUCTS_FOUND,
  NO_VENDORS_FOUND,
} from './types';
import firebase from '../../../../../../firebase';
import { objectToArray } from '../../../../../../utils';

const request = () => ({
  type: REQUEST_BUYER_DETAILS,
});

const receivedProducts = allProducts => ({
  type: RECEIVED_PRODUCTS,
  value: allProducts,
});

const receivedVendors = allVendors => ({
  type: RECEIVED_VENDORS,
  value: allVendors,
});

const noProductsFound = () => ({
  type: NO_PRODUCTS_FOUND,
});

const noVendorsFound = () => ({
  type: NO_VENDORS_FOUND,
});

export const getBuyerDetails = (appname, buyerID) => dispatch => {
  dispatch(request());
  firebase
    .database()
    .ref(`app_users/${appname}/${buyerID}`)
    .on('value', snap => {
      const value = snap.exists() ? snap.val() : {};
      dispatch({
        type: RECEIVE_BUYER_DETAILS,
        value,
      });
    });
};

export const stopGetBuyerDetails = (appname, buyerID) => dispatch => {
  firebase
    .database()
    .ref(`app_users/${appname}/${buyerID}`)
    .off('value');
  dispatch({
    type: STOP_RECEIVING_BUYER_DETAILS,
  });
};

export const getFavoriteProducts = (appname, favoriteProducts) => dispatch => {
  const productsRef = firebase
    .database()
    .ref(`marketplace_products/${appname}`);
  let allProducts = [];
  productsRef.once('value', async snapshot => {
    if (snapshot.exists()) {
      allProducts = objectToArray(snapshot.val());
      allProducts = allProducts.filter(product =>
        favoriteProducts.includes(product.id),
      );
      dispatch(receivedProducts(allProducts));
    } else {
      dispatch(noProductsFound());
    }
  });
};

export const getFavoriteVendors = (appname, favoriteVendors) => dispatch => {
  const vendorsRef = firebase.database().ref(`app_users/${appname}`);

  vendorsRef.once('value', async snapshot => {
    if (snapshot.exists()) {
      let vendors = objectToArray(snapshot.val());
      let allVendors = [];
      allVendors = vendors.filter(vendor => vendor.value.userType == 'vendor');

      allVendors = allVendors.filter(vendor =>
        favoriteVendors.includes(vendor.id),
      );

      dispatch(receivedVendors(allVendors));
    } else {
      dispatch(noVendorsFound());
    }
  });
};
