import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

class RecoverEmail extends Component {
  componentWillMount() {
    this.props.handleRecoverEmail(this.props.oobCode);
  }

  render() {
    const { errorMessage, email } = this.props;
    return (
      <div className="center">
        <h1>
          <Trans>Recover your Email</Trans>
        </h1>
        <div>
          {email && <p>{email}</p>}
          {errorMessage && <p>{errorMessage}</p>}
        </div>
      </div>
    );
  }
}

RecoverEmail.propTypes = {
  email: PropTypes.string,
  handleRecoverEmail: PropTypes.func.isRequired,
  oobCode: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

export default RecoverEmail;
