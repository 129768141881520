import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Trans, useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';

import { Empty as EmptyIcon } from '../../../../../app/components/icons';

const useStyles = makeStyles({
  container: {
    maxHeight: 440,
    overflowX: 'auto !important',
  },
  table: {
    width: '100%',
  },
  center: {
    textAlign: 'center',
    width: '100%',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  labelIcon: {
    marginRight: '1px',
  },
  labelText: {
    fontWeight: 'bold',
    flexGrow: 1,
    color: 'rgba(0, 0, 0, 0.54) !important',
  },
  width60: {
    width: '60%',
  },
  PL25: {
    paddingLeft: '25px',
  },
  cell: {
    background: '#f0f0f0',
    color: 'black',
    whiteSpace: 'nowrap',
    width: '100%',
  },
});
const Modal = ({ columns, rows, open, onClose }) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = React.useState(open);
  React.useEffect(() => {
    setOpen(open);
  }, [open]);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { maxWidth: '90vw' } }}
    >
      <DialogTitle id="form-dialog-title">
        <Trans>Details</Trans>
      </DialogTitle>
      <DialogContent>
        <TableContainer
          id="equalities-table"
          component={Paper}
          className={`${classes.container} scroll-container`}
        >
          <Table
            stickyHeader
            className={classes.table}
            aria-label={t('equalities table')}
          >
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell classes={{ root: classes.cell }} key={column.key}>
                    {column.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {rows.length ? (
              <TableBody>
                {rows.map(data => (
                  <TableRow key={`${Math.random()}${data.created_at}`}>
                    {columns.map(column => (
                      <TableCell
                        key={`sub-${column.key}`}
                        component="th"
                        scope="row"
                      >
                        {column.render
                          ? column.render(data[column.key], data)
                          : data[column.key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={columns.length}>
                    <EmptyIcon />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Trans>Cancel</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Modal.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  columns: [],
  rows: [],
  open: false,
};

export default Modal;
