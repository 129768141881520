import firebase from '../../../../../../../firebase';
// eslint-disable-next-line
export const fieldChange = (appname, dataId, field, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child(field).set(value);

  return {
    type: 'HEADER_FIELD_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

export const designChange = (appname, dataId, field, value) => {
  const dataRef =
    field === 'layout'
      ? firebase.database().ref(`apps/${appname}/draft/always_data/${dataId}`)
      : firebase
          .database()
          .ref(`apps/${appname}/draft/always_data/${dataId}/design`);

  dataRef.child(field).set(value);

  return {
    type: 'BOX_FIELD_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

export const updateCaptionToggle = (
  appname,
  componentId,
  value,
) => async dispatch => {
  const dbRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${componentId}`);

  return await dbRef.update({ showCaption: value });
};

export const updateDownloadToggle = (
  appname,
  componentId,
  value,
) => async dispatch => {
  // console.log(appname, componentId, value);
  const dbRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${componentId}`);

  return await dbRef.update({ allowDownloads: value });
};

export const updateCaptionText = (
  appname,
  componentId,
  data,
) => async dispatch => {
  console.log(appname, componentId, data);

  const dbRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${componentId}/gallery`);

  await dbRef.update(data);
};
