const designData = (state = {}, action) => {
  switch (action.type) {
    case 'RECEIVE_DESIGN_DATA':
      return {
        ...state,
        [action.appname]: action.data,
      };

    default:
      return state;
  }
};

export default designData;
