import firebase from '../../../../../../../firebase';
import { INTERNAL_APP_PATHS } from '../../../../../../../config/constants';
import { objectToArray } from '../../../../../../../utils';
export const openCreatePageUI = () => ({ type: 'OPEN_CREATE_PAGE_UI' });

export const closeCreatePageUI = () => ({ type: 'CLOSE_CREATE_PAGE_UI' });

export const titleChange = value => ({
  type: 'CREATE_PAGE_TITLE_CHANGE',
  value,
});

export const displayNotification = value => ({
  type: 'SHOW_NOTIFICATION',
  value,
});

export const tabChange = (index, value) => ({
  type: 'CREATE_PAGE_TAB_CHANGE',
  index,
  value,
});

export const typeChange = value => ({
  type: 'CREATE_PAGE_TYPE_CHANGE',
  value,
});

export const addTab = () => ({
  type: 'CREATE_PAGE_ADD_TAB',
});

export const removeTab = index => ({
  type: 'CREATE_PAGE_REMOVE_TAB',
  index,
});

export const parentPageChange = value => ({
  type: 'CREATE_PAGE_PARENT_PAGE_CHANGE',
  value,
});

const resetForm = () => ({
  type: 'RESET_CREATE_PAGE_FORM',
});

const creatingPage = () => ({
  type: 'CREATING_PAGE',
});

const reportError = err => ({
  type: 'CREATE_PAGE_ERROR',
  err,
});

const getHref = (page, appname, type, parentPageKey = '') => {
  const tabQuery =
    type === 'TabView' || type === 'NestedTabPage' ? '&tab=0' : '';
  const parentPageQuery =
    type === 'NestedPage' || type === 'NestedTabPage'
      ? `&parentPage=${parentPageKey}`
      : ``;

  return `/${appname}/editor/build/list?page=${page}${tabQuery}${parentPageQuery}`;
};

const toPath = title =>
  title
    .toLowerCase()
    .replace(/[!@#$%^&*()_+=[\]{};':"\\|,.<>/?]/g, '')
    .trim()
    .replace(/\s+/g, '-');

//Add nested pages to same group as parent page upon creation
const checkIfParentPageHasGroups = (parentPageKey, subKey, appname) => {
  return async dispatch => {
    const groupsRef = firebase.database().ref(`app_groups/${appname}/groups`);
    const parentGroupKeys = [];
    groupsRef.once('value').then(snapshot => {
      if (snapshot.exists()) {
        const values = objectToArray(snapshot.val());
        values.map(item => {
          if ('draft' in item.value) {
            const pages = Object.keys(item.value.draft);
            if (pages.includes(parentPageKey)) {
              parentGroupKeys.push(item.id);
            }
          }
        });
      }

      if (parentGroupKeys.length > 0) {
        parentGroupKeys.map(value => {
          groupsRef
            .child(value)
            .child('draft')
            .update({
              [subKey]: true,
            });
        });
      }
    });
  };
};

/**
 * Adds a new page to the apps
 * @param {string} appname - the domain of the app we are adding the page to
 */
export const createPage = (appname, history) => (dispatch, getState) => {
  const childRoutesRef = firebase
    .database()
    .ref(`apps/${appname}/draft/layout_data/childRoutes`);
  const alwaysDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data`);

  const layoutData = getState().editor.data.layoutData[appname] || {
    childRoutes: [],
  };
  const alwaysData = getState().editor.data.alwaysData;
  const form = getState().editor.build.create.createPage;

  dispatch(creatingPage());
  const pageType = form.type;
  const parentPageKey =
    form.type === 'NestedPage' || form.type === 'NestedTabPage'
      ? form.parentPage
      : null;

  // Get all current paths
  const paths = layoutData.childRoutes
    ? layoutData.childRoutes.map(key => alwaysData[key].path)
    : [];
  paths.push(...INTERNAL_APP_PATHS);

  const parentPagePath = alwaysData[parentPageKey]
    ? alwaysData[parentPageKey].path
    : null;
  // make sure new path does not clash with current path
  let path = toPath(form.title.value);

  //append parent page path if parent page exists
  if (parentPagePath) {
    path = `${parentPagePath}/${path}`;
  }
  // if path already exists, append a timestamp
  if (paths.indexOf(path) !== -1) {
    path = path.concat('-', Date.now().toString());
  }

  // normalize tabs or return null if not TabView type
  const tabs =
    form.type === 'TabView' || form.type === 'NestedTabPage'
      ? form.tabs.map(tab => ({
          title: tab.title.value,
        }))
      : null;

  // add parent page key for nested pages

  let newPageKey;

  return alwaysDataRef
    .push({
      title: form.title.value,
      type: form.type,
      path,
      tabs,
      parentPageKey,
    })
    .then(pageRef => {
      newPageKey = pageRef.key;
      if (parentPageKey) {
        dispatch(
          checkIfParentPageHasGroups(parentPageKey, newPageKey, appname),
        );
      }
      return childRoutesRef
        .transaction(value => {
          if (!value) return [newPageKey];
          history.push(getHref(newPageKey, appname, form.type, parentPageKey));
          return [...value, pageRef.key];
        })
        .then(() => {
          dispatch(closeCreatePageUI());
          dispatch(resetForm());
          if (pageType === 'Popup') {
            dispatch(displayNotification(true));
          }
        });
    })
    .catch(err => dispatch(reportError(err)));
};
