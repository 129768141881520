import React from 'react';

const FacebookIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 65.79 51.85"
  >
    <title>Facebook component</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          class="cls-1"
          points="9.28 44.05 0 34.77 9.28 25.49 10.34 26.55 2.12 34.77 10.34 42.99 9.28 44.05"
        />
        <polygon
          class="cls-1"
          points="56.51 44.05 55.45 42.99 63.66 34.77 55.45 26.55 56.51 25.49 65.79 34.77 56.51 44.05"
        />
        <path
          class="cls-1"
          d="M38.92,51.85H27.35V27H22V16.78h5.37V9a9,9,0,0,1,9-9H47V10.23h-8v6.55H47l-.8,9.56H38.92Zm-10.07-1.5h8.57V24.84h7.44l.55-6.56h-8V8.73h8V1.5H36.35A7.51,7.51,0,0,0,28.85,9v9.28H23.48v7.23h5.37Z"
        />
      </g>
    </g>
  </svg>
);

export default FacebookIcon;
