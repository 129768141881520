import React from 'react';

import './styles.css';

const Distribute = () => (
  <svg
    className="icon distribute"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35.25 44.68"
  >
    <title>Distribute</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M12.22,14.81a4.26,4.26,0,0,1,4.25-4.25H19a4.26,4.26,0,0,1,4.25,4.25v2.9h1.5v-2.9A5.76,5.76,0,0,0,19.8,9.13a4.81,4.81,0,1,0-4.17,0,5.76,5.76,0,0,0-4.91,5.68v2.9h1.5Zm2.19-10a3.31,3.31,0,1,1,3.31,3.31A3.31,3.31,0,0,1,14.41,4.81Z"
        />
        <path
          className="cls-1"
          d="M16.88,17.56V23.7a.75.75,0,0,0,.74.75.76.76,0,0,0,.76-.75V17.56a.76.76,0,0,0-.76-.75A.75.75,0,0,0,16.88,17.56Z"
        />
        <path
          className="cls-1"
          d="M22,26.9a.79.79,0,0,0,.53.22A.75.75,0,0,0,23,26.9L27,23a.75.75,0,0,0-1.07-1.06L22,25.84A.75.75,0,0,0,22,26.9Z"
        />
        <path
          className="cls-1"
          d="M12.21,26.9a.75.75,0,0,0,.53.22.79.79,0,0,0,.53-.22.75.75,0,0,0,0-1.06L9.51,22.08a.75.75,0,0,0-1.06,1.06Z"
        />
        <path
          className="cls-1"
          d="M31.19,39.2V33.06a3.75,3.75,0,0,0-3.75-3.75H20.78a3.24,3.24,0,0,0-6.31,0h-6a3.75,3.75,0,0,0-3.75,3.75V39.2H0v5.48H10.25V39.2H6.19V33.06a2.25,2.25,0,0,1,2.25-2.25h6a3.27,3.27,0,0,0,2.41,2.41v6H12.5v5.48H22.75V39.2H18.38v-6a3.27,3.27,0,0,0,2.4-2.41h6.66a2.25,2.25,0,0,1,2.25,2.25V39.2H25v5.48H35.25V39.2Zm-22.44,4H1.5V40.7H8.75Zm12.5,0H14V40.7h7.25ZM17.62,31.81a1.75,1.75,0,1,1,1.76-1.75A1.74,1.74,0,0,1,17.62,31.81ZM33.75,43.18H26.5V40.7h7.25Z"
        />
      </g>
    </g>
  </svg>
);

export default Distribute;
