import React from 'react';
import Toggle from 'material-ui/Toggle';
import themeStyles from '../../../_export.scss';

const CustomToggle = props => (
  <Toggle
    style={{ width: 42 }}
    trackStyle={{ height: 10 }}
    thumbStyle={{ top: -1, boxShadow: null }}
    // elementStyle={{ width: 42 }} because of issue with material-ui
    // elementStyle is not applied to enhancedSwitch so iconStyle must be passed.
    // Ignore any unrecognized props warnings for iconStyle
    iconStyle={{ width: 42 }}
    trackSwitchedStyle={{
      backgroundColor: props.trackSwitchedColor
        ? props.trackSwitchedColor
        : themeStyles.primaryColorLight,
    }}
    thumbSwitchedStyle={{
      backgroundColor: props.thumbSwitchedColor
        ? props.thumbSwitchedColor
        : themeStyles.primaryColor,
    }}
    {...props}
  />
);

export default CustomToggle;
