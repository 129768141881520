import { combineReducers } from 'redux';

import createUser from './modules/createUser/reducer';
import defaultGroup from './modules/defaultGroup/reducer';
import profileFields from './modules/profileFields/reducer';
import restrictedAccess from './modules/restrictedAccess/reducer';
import signupAs from './modules/signupAs/reducer';
export default combineReducers({
  createUser,
  defaultGroup,
  profileFields,
  restrictedAccess,
  signupAs,
});
