import { connect } from 'react-redux';

import Profile from './Profile';
import { openLegalModal } from '../legal/actions';
import { fieldChange, saveChanges } from './actions';

const mapStateToProps = state => ({
  ...state.account.staffProfile,
});

const mapDispatchToProps = dispatch => ({
  onNameChange: (prop, value) => dispatch(fieldChange(prop, value)),
  onSubmit: () => dispatch(saveChanges()),
  onOpenLegalModal: selectedModal => dispatch(openLegalModal(selectedModal)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
