import React from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import './styles.css';
import themeStyles from '../../../../_export.scss';

const CTAButton = ({
  borderColor,
  label,
  labelPosition,
  disabled,
  onClick,
  openInNewWindow,
  style,
  href,
  height,
  minWidth,
  icon,
}) => (
  <RaisedButton
    label={label}
    onClick={onClick}
    href={href}
    disabled={disabled}
    backgroundColor={
      disabled ? themeStyles.primaryColorLight : themeStyles.primaryColor
    }
    style={{
      ...style,
      display: 'flex',
      overflow: 'hidden',
      border: borderColor ? `2px solid ${borderColor}` : null,
      borderRadius: 30,
      minWidth,
    }}
    buttonStyle={{
      overflow: 'hidden',
      lineHeight: height ? `${height}px` : '38px',
      height: height || 38,
      cursor: 'pointer',
      backgroundColor: disabled
        ? themeStyles.primaryColorLight
        : themeStyles.primaryColor,
    }}
    overlayStyle={{
      height: height || 38,
    }}
    labelStyle={{
      color: themeStyles.primaryTextColor,
      fontSize: '.85em',
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: 600,
    }}
    target={openInNewWindow ? '_blank' : null}
    labelPosition={labelPosition}
    icon={icon}
  />
);

CTAButton.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  label: PropTypes.string.isRequired,
  minWidth: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  openInNewWindow: PropTypes.bool,
  outlineRGB: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.element,
  labelPosition: PropTypes.string,
};

CTAButton.defaultProps = {
  backgroundColor: themeStyles.primaryColorLight,
  borderColor: null,
  href: undefined,
  height: 38,
  minWidth: 142,
  onClick: undefined,
  openInNewWindow: false,
  disabled: false,
  outlineRGB: '220, 220, 220',
  style: undefined,
  icon: null,
  labelPosition: 'before',
};

export default CTAButton;
