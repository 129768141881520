const features = {
  UserChat: {
    name: 'User chat',
    price: 1,
    subscriptionPlan: 'addon',
  },
  Notification: {
    name: 'Push Notifications',
    price: 8,
    subscriptionPlan: 'addon',
  },
};

export default features;
