import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import Divider from 'material-ui/Divider';
import Checkbox from 'material-ui/Checkbox';
import Toggle from 'material-ui/Toggle';
import { Trans, withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import Done from 'material-ui/svg-icons/action/done';
import { FlatButton } from '../../../../app/components/buttons';
import Subheader from '../../../../app/components/subheader';
import { formatDate } from '../../../../utils';
import UserDataTable from './userDataTable';
import { SCOTTISH_CURLING_FEATURES_ENABLED_APPS } from '../../../../config/constants';
import AnalyticsData from './AnalyticsData';
import './styles.css';

class UserGroups extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    selectedUsers: PropTypes.object.isRequired,
    selected: PropTypes.array.isRequired,
    groups: PropTypes.object,
    chunks: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    selectedGroups: PropTypes.array.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    toggleSelected: PropTypes.func.isRequired,
    onSaveUserGroupChanges: PropTypes.func.isRequired,
    selectAllGroups: PropTypes.bool.isRequired,
    onSelectAllGroups: PropTypes.func.isRequired,
    onDeselectAllGroups: PropTypes.func.isRequired,
    onApproveScottishAppUser: PropTypes.func.isRequired,
    pageAnalyticsData: PropTypes.object.isRequired,
    pageAnalyticsDataPending: PropTypes.bool.isRequired,
    onVerifyUser: PropTypes.func.isRequired,
    onToogleUserDiaryPermission: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    enabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    groups: {},
  };

  listUsers = users => {
    const { t } = this.props;
    const defaultGroup = this.props.groups.default;
    let rowData = [];
    defaultGroup &&
      Object.keys(users).map(key => {
        let customFieldData = [];
        defaultGroup &&
          defaultGroup.customProfileFields &&
          Object.entries(defaultGroup.customProfileFields).forEach(
            ([i, value]) => {
              if (value.isVisible.value) {
                customFieldData.push({
                  ...value,
                  value:
                    users[key].customFieldInfo && users[key].customFieldInfo[i]
                      ? users[key].customFieldInfo[i].value
                      : null,
                });
              }
            },
          );
        const socialLinks =
          users[key].socialLinks && users[key].socialLinks.filter(n => n);
        rowData = [
          ...rowData,
          {
            firstName: defaultGroup.firstNameVisible && users[key].firstName,
            lastName: defaultGroup.lastNameVisible && users[key].lastName,
            email: users[key].email.replace(
              this.props.match.params.appname,
              '',
            ),
            organisation:
              defaultGroup.organisationVisible && users[key].organisation,
            role: defaultGroup.roleVisible && users[key].role,
            phoneNumber: users[key].phoneNumber,
            dob: users[key].dob,
            allowChat: users[key].allowChat ? t('Yes') : t('No'),
            isActive: users[key].isActive ? t('Yes') : t('No'),
            showEmailInSearch: users[key].showEmailInSearch
              ? t('Yes')
              : t('No'),
            emailVerified: users[key].emailVerified ? <Done /> : null,
            createdAt: formatDate(users[key].created_at),
            lastLoggedIn: users[key].lastLoggedIn
              ? formatDate(users[key].lastLoggedIn)
              : t('No Data'),
            socialLinks:
              socialLinks && socialLinks.length > 0
                ? socialLinks.join(', ')
                : null,
            customFieldData,
          },
        ];
      });

    return rowData;
  };

  isChecked = (selectedUsers, selectedGroups, group) =>
    selectedGroups.indexOf(group) >= 0;

  listGroups = (groups, selectedGroups, selectedUsers, selected) =>
    groups &&
    Object.entries(groups).map(group => (
      <div className="group" key={group[0]}>
        <span className="name">{group[1].name}</span>
        <span className="select">
          <Checkbox
            checked={this.isChecked(
              selectedUsers,
              selectedGroups,
              group[0],
              selected,
            )}
            onCheck={() => this.props.toggleSelected(group[0])}
          />
        </span>
      </div>
    ));

  handleCloseDeleteDialog = () =>
    this.setState({ confirmDeleteDialogOpen: false });

  updateNotNeeded = (selected, selectedUsers, selectedGroups) => {
    if (selected.length === 1) {
      if (selectedUsers[selected[0]]) {
        const groups = selectedGroups.map(key => {
          let result = [];

          if (
            selectedUsers &&
            selectedUsers[selected[0]].groups &&
            Object.keys(selectedUsers[selected[0]].groups)
          ) {
            result = Object.keys(selectedUsers[selected[0]].groups).map(
              userGroup => userGroup === key,
            );
          }

          return result.indexOf(true) >= 0;
        });
        if (groups.indexOf(false) < 0) {
          return (
            groups.length ===
            Object.keys(selectedUsers[selected[0]].groups).length
          );
        }
        return false;
      }
    }
    return !selectedGroups.length;
  };

  handleSaveClicked = () => {
    this.props.onSaveUserGroupChanges(
      this.props.selected,
      this.props.selectedGroups,
      this.updateNotNeeded(
        this.props.selected,
        this.props.selectedUsers,
        this.props.selectedGroups,
      ),
      this.props.chunks,
      this.props.groups,
    );
  };

  renderScottishAppContent = () => {
    const { t } = this.props;
    const selectedUsers = this.props.selectedUsers;
    const selectedUserKeys = Object.keys(selectedUsers);

    let verificationEmailSent = false;
    if (
      selectedUsers[selectedUserKeys[0]] &&
      selectedUsers[selectedUserKeys[0]].verificationEmailSent !== undefined
    ) {
      verificationEmailSent =
        selectedUsers[selectedUserKeys[0]].verificationEmailSent;
    }

    if (
      selectedUserKeys.length === 1 &&
      selectedUsers[selectedUserKeys[0]] &&
      selectedUsers[selectedUserKeys[0]].adminVerified === false
    ) {
      const grpAccessRequested = this.props.groups[
        selectedUsers[selectedUserKeys[0]].requestedGroup
      ];
      return (
        <div>
          <span style={{ margin: '10px' }}>
            <center>
              <Trans>User has requested Access to Group:</Trans>{' '}
              {(grpAccessRequested && grpAccessRequested.name) || ''}
            </center>
          </span>
          <div className="submit-button-container">
            {verificationEmailSent && (
              <center>
                {' '}
                <h4>
                  <Trans>Verification email already sent to the user</Trans>
                </h4>
              </center>
            )}
            {this.props.isSubmitting ? (
              <CircularProgress size={30} thickness={5} color={'inherit'} />
            ) : (
              <FlatButton
                label={`${
                  verificationEmailSent
                    ? t('Send link again')
                    : t('Approve user')
                }`}
                primary
                height={24}
                minWidth={100}
                onClick={() => {
                  this.props.onApproveScottishAppUser(
                    selectedUserKeys[0],
                    selectedUsers[selectedUserKeys[0]],
                    this.props.groups,
                  );
                }}
              />
            )}
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="group-list-container">
          <div className="group-list">
            <div className="group-list-header">
              <span>
                <Trans>Select Groups</Trans>
              </span>
              <span className="select-all">
                <Subheader title={t('Select All')} />
                <Checkbox
                  checked={this.selectAllGroups}
                  onCheck={(e, isChecked) =>
                    isChecked
                      ? this.onSelectAllGroups()
                      : this.onDeselectAllGroups()
                  }
                />
              </span>
            </div>
            <div className="groups">
              {this.listGroups(
                this.props.groups,
                this.props.selectedGroups,
                this.props.selectedUsers,
                this.props.selected,
              )}
            </div>
          </div>
        </div>
        <Divider />
        <div className="submit-button-container">
          <FlatButton
            label={t('Save')}
            primary
            height={24}
            minWidth={100}
            disabled={Object.keys(this.props.selectedGroups).length === 0}
            onClick={this.handleSaveClicked}
          />
        </div>
      </>
    );
  };

  render() {
    const {
      open,
      onRequestClose,
      selectedUsers,
      groups,
      selectedGroups,
      match,
      getPageCountAnalytics,
      pageAnalyticsData,
      pageAnalyticsDataPending,
      onVerifyUser,
      onToogleUserDiaryPermission,
      t,
      enabled,
    } = this.props;

    const rowData = this.listUsers(selectedUsers);
    const key = Object.keys(selectedUsers)[0];
    const emailVerified = key && selectedUsers[key].emailVerified;

    const checkDiaryPermission = () => {
      const userId = Object.keys(selectedUsers)[0];
      const userData = this.props.selectedUsers[userId];
      if (userData === undefined || userData.diaryEnabled === undefined)
        return true;

      return userData.diaryEnabled;
    };

    return (
      <Dialog
        open={open}
        onRequestClose={onRequestClose}
        autoScrollBodyContent
        contentClassName="user-groups-modal"
        paperClassName="user-groups-paper"
      >
        {selectedUsers && (
          <div className="user-groups-modal-content">
            <div className="modal-heading-container">
              <h5>
                <Trans>Edit Users Group Access</Trans>
              </h5>
            </div>

            <Divider />
            <div className="inner-container">
              <div className="users-list-container">
                <span className="users-list-total">
                  <Trans>Selected Users:</Trans>{' '}
                  {Object.keys(selectedUsers).length}
                </span>
                <UserDataTable
                  rowData={rowData}
                  defaultGroup={groups.default}
                  enabled={enabled}
                />
              </div>
              <Divider />
              <br />
              <br />
              {Object.keys(this.props.selectedUsers).length === 1 && (
                <AnalyticsData
                  selectedUsers={this.props.selectedUsers}
                  getPageCountAnalytics={getPageCountAnalytics}
                  pageAnalyticsData={pageAnalyticsData}
                  pageAnalyticsDataPending={pageAnalyticsDataPending}
                />
              )}
              {SCOTTISH_CURLING_FEATURES_ENABLED_APPS.indexOf(
                match.params.appname,
              ) > -1 ? (
                <div>{this.renderScottishAppContent()}</div>
              ) : (
                <>
                  <div className="group-list-container">
                    <div className="group-list">
                      <div className="group-list-header">
                        <span>
                          <Trans>Select Groups</Trans>
                        </span>
                        <span className="select-all">
                          <Subheader title={t('Select All')} />
                          <Checkbox
                            checked={this.selectAllGroups}
                            onCheck={(e, isChecked) =>
                              isChecked
                                ? this.onSelectAllGroups()
                                : this.onDeselectAllGroups()
                            }
                          />
                        </span>
                      </div>
                      <div className="groups">
                        {this.listGroups(
                          this.props.groups,
                          this.props.selectedGroups,
                          this.props.selectedUsers,
                          this.props.selected,
                        )}
                      </div>
                    </div>
                  </div>

                  {/* {Object.keys(this.props.selectedUsers).length === 1 && (
                    <Toggle
                      style={{ margin: 0 }}
                      onToggle={(e, value) =>
                        onToogleUserDiaryPermission(key, value)
                      }
                      toggled={checkDiaryPermission()}
                      label="User Diary Enabled"
                    />
                  )} */}

                  <br />
                  <Divider />
                  <div className="submit-button-container">
                    {emailVerified === undefined && enabled ? (
                      <FlatButton
                        label={t('Verify User')}
                        primary
                        height={24}
                        minWidth={100}
                        disabled={Object.keys(selectedGroups).length === 0}
                        onClick={() => onVerifyUser(key)}
                      />
                    ) : (
                      <FlatButton
                        label={t('Save')}
                        primary
                        height={24}
                        minWidth={100}
                        disabled={Object.keys(selectedGroups).length === 0}
                        onClick={this.handleSaveClicked}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withTranslation()(UserGroups);
