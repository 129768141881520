import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import IconButton from 'material-ui/IconButton';
import { withTranslation } from 'react-i18next';
import Container from '../../../../../../../app/components/container';
import { Close } from '../../../../../../../app/components/icons';
import EditableText from '../../../../../../../app/components/editableText';
import ColorPicker from '../../../../../components/colorPicker';

import './styles.css';

const styles = {
  removeButton: {
    height: '24px',
    width: '24px',
    padding: '0',
    margin: '6px',
  },
  picker: {
    height: '22px',
    width: '22px',
    minWidth: 0,
    borderRadius: '5px',
  },
};

// eslint-disable-next-line
class Filters extends Component {
  static propTypes = {
    filters: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
  };

  static defaultProps = {
    filters: {},
  };

  render() {
    const { filters, onChange, onRemove, t } = this.props;

    const mappedFilters = _map(filters, (filter, key) => (
      <div className="filter-item" key={key}>
        <ColorPicker
          color={filter.color}
          height={22}
          width={22}
          onChange={value => onChange(key, 'color', value.hex)}
          style={styles.picker}
        />
        <EditableText
          className="filter-label"
          placeholder={t('Category Name')}
          text={filter.label}
          onSave={value => onChange(key, 'label', value)}
        />
        <IconButton style={styles.removeButton} onClick={() => onRemove(key)}>
          <Close />
        </IconButton>
      </div>
    ));

    return <Container title={t('Categories')}>{mappedFilters}</Container>;
  }
}

export default withTranslation()(Filters);
