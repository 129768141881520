import React from 'react';

const Lightening = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23.18 28.61"
      fill="currentColor"
      {...props}
    >
      <title>Build</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <polygon points="7.15 0 23.18 0.03 13.64 11.36 21.56 11.53 3.1 28.61 8.07 16.53 0 16.44 7.15 0" />
        </g>
      </g>
    </svg>
  );
};

export default Lightening;
