import firebase from '../../../../../../firebase';

export const TOGGLE_VERIFICATION_PAGE = 'TOGGLE_VERIFICATION_PAGE';
export const UPDATE_VERIFICATION_PAGE_FIELD = 'UPDATE_VERIFICATION_PAGE_FIELD';
export const UPDATE_VERIFICATION_PAGE_REDIRECT_FIELD =
  'UPDATE_VERIFICATION_PAGE_REDIRECT_FIELD';

export const toggleVerificationPage = (appname, toggled) => dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/verification_page`)
    .update({ enabled: toggled })
    .then(() =>
      dispatch({
        type: TOGGLE_VERIFICATION_PAGE,
        appname,
        toggled,
      }),
    );
};

export const updateField = (appname, field, value) => dispatch =>
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default`)
    .child('verification_page')
    .update({ [`${field}`]: value })
    .then(() =>
      dispatch({
        type: UPDATE_VERIFICATION_PAGE_FIELD,
        appname,
        field,
        value,
      }),
    );

export const handleRadioChange = (appname, field, value) => dispatch => {
  return firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/verification_page`)
    .child(field)
    .set(value)
    .then(() =>
      dispatch({
        type: UPDATE_VERIFICATION_PAGE_FIELD,
        appname,
        field,
        value,
      }),
    );
};

export const loadLayoutData = appname => dispatch => {
  const appRef = firebase.database().ref(`apps/${appname}`);
  const draftRef = appRef.child('draft');
  draftRef.once('value').then(draftSnapshot => {
    const data = draftSnapshot.val();
    if (data) {
      dispatch(receiveLayoutData(appname, data.layout_data));
      dispatch(hydrateAlwaysData(data.always_data));
    }
  });
};

export const updateRedirectPageField = ({
  appname,
  field,
  value,
}) => dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default`)
    .child('redirect_verification_page')
    .update({ [`${field}`]: value })
    .then(() =>
      dispatch({
        type: UPDATE_VERIFICATION_PAGE_REDIRECT_FIELD,
        appname,
        field,
        value,
      }),
    );
};

const receiveLayoutData = (appname, data) => ({
  type: 'RECEIVE_LAYOUT_DATA',
  data,
  appname,
});

const hydrateAlwaysData = data => ({
  type: 'HYDRATE_ALWAYS_DATA',
  data,
});
