import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import Container from '../../../../../../../app/components/container';
import TextField from '../../../../../../../app/components/textField';
import SectionHeader from '../../../../../components/sectionHeader';
import CircularValidation from '../../../../../../../app/components/circularValidation';
import themeStyles from '../../../../../../../_export.scss';

class EventCalendarItem extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  getTooltipText = () => {
    const { t } = this.props;
    return this.requiredDisabled()
      ? t('At least one field must be required')
      : t(
          'Users will have to fill in this field to be able to submit the form',
        );
  };
  handleChange = field => value => this.props.onChange(field, value);
  handleToggle = (field, value) => this.props.onChange(field, value);
  requiredDisabled = () => {
    const { calendarType, item } = queryString.parse(
      this.props.location.search,
    );
    const { fields } = this.props;
    return (
      fields &&
      fields[calendarType] &&
      fields[calendarType].fields &&
      Object.entries(fields[calendarType].fields.chunks).filter(
        field => field[1].isRequired && item !== field[0],
      ).length === 0 &&
      this.props.data.isRequired
    );
  };

  render() {
    const {
      label,
      type,
      isRequired,
      isPublic,
      isSearchable,
      options,
    } = this.props.data;
    const { t } = this.props;
    const { calendarType } = queryString.parse(this.props.location.search);
    const styles = {
      toggleContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
    };

    return (
      <Container>
        <SectionHeader
          title={t('Field Label')}
          tooltip={t('Provide a helpful label to your users for this field')}
        />
        <div style={styles.toggleContainer}>
          <SectionHeader
            title={t('Required field')}
            tooltip={this.getTooltipText()}
            tooltipType={this.requiredDisabled() ? 'warning' : 'info'}
            actions={
              <Toggle
                id="form-field-required-toggle"
                toggled={isRequired}
                onToggle={(field, value) =>
                  this.requiredDisabled()
                    ? () => {}
                    : this.handleToggle('isRequired', value)
                }
                disabled={this.requiredDisabled()}
                trackSwitchedStyle={{
                  backgroundColor: themeStyles.primaryColorLight,
                }}
                thumbSwitchedStyle={{
                  backgroundColor: themeStyles.primaryColor,
                }}
              />
            }
          />
          <SectionHeader
            title={t('Public field')}
            tooltip={t('Users will be able to see this field')}
            tooltipType={'info'}
            actions={
              <Toggle
                id="form-field-required-toggle"
                toggled={isPublic}
                onToggle={(field, value) =>
                  this.requiredDisabled()
                    ? () => {}
                    : this.handleToggle('isPublic', value)
                }
                disabled={this.requiredDisabled()}
                trackSwitchedStyle={{
                  backgroundColor: themeStyles.primaryColorLight,
                }}
                thumbSwitchedStyle={{
                  backgroundColor: themeStyles.primaryColor,
                }}
              />
            }
          />
          <SectionHeader
            title={t('Enable as a filter')}
            tooltip={t(
              'Allow users to filter events by this field, e.g event type, age group etc.',
            )}
            tooltipType={'info'}
            actions={
              <Toggle
                id="form-field-required-toggle"
                toggled={isSearchable}
                onToggle={(field, value) =>
                  this.requiredDisabled()
                    ? () => {}
                    : this.handleToggle('isSearchable', value)
                }
                disabled={this.requiredDisabled()}
                trackSwitchedStyle={{
                  backgroundColor: themeStyles.primaryColorLight,
                }}
                thumbSwitchedStyle={{
                  backgroundColor: themeStyles.primaryColor,
                }}
              />
            }
          />
        </div>
        <TextField
          id="form-field-label-field"
          value={label === 'Daterange' ? 'Date Range' : label || ''}
          onChange={this.handleChange('label')}
          placeholder={t('Label')}
          required={!label}
          isValid={label !== ''}
          errorText={t('You need to provide a label')}
        />
        {(type === 'Dropdown' || type === 'dropdown') && (
          <TextField
            id="form-field-label-field"
            placeholder={t('Dropdown Options')}
            value={options || ''}
            multiLine
            onChange={this.handleChange('options')}
            rightInputAction={
              <CircularValidation
                isValid={options}
                defaultMsg={t('Add multiple options using comma separated')}
                errorMsg={t('Options must be at least 2 characters long')}
                showDefault={!options}
              />
            }
          />
        )}
      </Container>
    );
  }
}

export default withTranslation()(EventCalendarItem);
