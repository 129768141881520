import { connect } from 'react-redux';

import NewUser from './NewUser';

import { fieldChange } from '../actions';
import { openLegalModal } from '../../legal/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.account.register,
});

const mapDispatchToProps = dispatch => ({
  onFieldChange: field => value => dispatch(fieldChange(field, value)),
  onOpenLegalModal: selectedModal => () =>
    dispatch(openLegalModal(selectedModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
