const initialState = {
    isPending: false,
    isValidRequest: false,
    details: {},
    approvalRequestStatus: false,
    error: false,
    isApprovalSubmitted: false
};

const redeemRequest = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_REDEEM_REQUEST_PENDING_STATUS':
            return {
                ...state,
                isPending: action.payload,
            };
        case 'VALIDATE_REDEEM_REQUEST':
            return {
                ...state,
                isValidRequest: action.payload,
            };
        case 'UPDATE_REDEEM_REQUEST_DETAIL':
            return {
                ...state,
                details: action.payload,
            };
        case 'TOGGLE_APPROVAL_REQUEST_STATUS':
            return {
                ...state,
                approvalRequestStatus: action.payload,
            };
        case 'APPROVAL_REQUEST_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        case 'APPROVAL_REQUEST_STATUS_UPDATION':
            return {
                ...state,
                isApprovalSubmitted: action.payload,
            };
        default:
            return state;
    }
};

export default redeemRequest;