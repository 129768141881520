import React from 'react';
import { Trans } from 'react-i18next';
import './styles.css';

const Form = () => (
  <svg
    className="icon form-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44.3 41.5"
  >
    <title>
      <Trans>Form Data</Trans>
    </title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M22.5,38.1A1.9,1.9,0,0,1,20.6,40H3.4a1.9,1.9,0,0,1-1.9-1.9V35.3H20.12V33.8H1.5V3.4A1.9,1.9,0,0,1,3.4,1.5H20.6a1.9,1.9,0,0,1,1.9,1.9V6.3H4.5V7.8h18v6.7H24V3.4A3.41,3.41,0,0,0,20.6,0H3.4A3.41,3.41,0,0,0,0,3.4V38.1a3.41,3.41,0,0,0,3.4,3.4H20.6A3.41,3.41,0,0,0,24,38.1v-10H22.5Z"
        />
        <path
          class="cls-1"
          d="M42.31,17.93h-.59V17H18.44V17l-.11.07H16.94v.79L10.15,21.7l6.79,3.88v.71h1.23l.27.15v-.15H41.72v-.82h.59a2,2,0,0,0,2-2V19.93A2,2,0,0,0,42.31,17.93ZM14.13,22.25v-1.1l2.81-1.61v4.32Zm23.5,2.54H18.44V18.53H37.63Zm1.5-6.26h1.09v6.26H39.13Zm3.67,4.94a.49.49,0,0,1-.49.5h-.59V19.43h.59a.5.5,0,0,1,.49.5Z"
        />
      </g>
    </g>
  </svg>
);

export default Form;
