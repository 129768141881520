import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';

import translationES from './locales/es/translations.json';

const resources = {
  es: { translation: translationES },
};

const url = window.location.href;
const appName = url.split('/')[3];

const appLang = {
  anything2: 'es',
  testapp202608: 'es',
  testapp7700: 'es',
  testingplatformemails: 'es',
  spanishap: 'es',
  spanishapp101test: 'es',
  adrimomfit: 'es',
  agure: 'es',
  ascavas: 'es',
  avocado: 'es',
  avocadodemo: 'es',
  connectaton: 'es',
  csaltocostanera: 'es',
  cuidamostubienestar: 'es',
  eldemanolin: 'es',
  eliasgarciapt: 'es',
  fitevolutio: 'es',
  foodtogo: 'es',
  grupovida: 'es',
  hamburgueseriasharky: 'es',
  indalfood: 'es',
  inmobiliaria: 'es',
  laslomas: 'es',
  marketp: 'es',
  marketracin: 'es',
  metodocampeona: 'es',
  personaltrainingapp: 'es',
  piconauta: 'es',
  plisplass: 'es',
  retai: 'es',
  rincongallego: 'es',
  starfit: 'es',
  taboaonline: 'es',
  tradeboom: 'es',
  ukelelehawaiifood: 'es',
  vergarasport: 'es',
  testapp4march2024: 'es',
};

if (initReactI18next) {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: get(appLang, `${appName}`, 'en'),
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
}

export default withRouter(i18n);
