import React from 'react';

const AccountsIcon = props => (
  <svg
    style={{ width: '32px', height: '32px', margin: '6px' }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 79.47 79.47"
    fill="currentColor"
    {...props}
  >
    <title>Accounts</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M69.66,79.47H9.82A9.82,9.82,0,0,1,0,69.66V9.82A9.82,9.82,0,0,1,9.82,0H69.66a9.82,9.82,0,0,1,9.81,9.82V69.66A9.82,9.82,0,0,1,69.66,79.47ZM9.82,3.67A6.16,6.16,0,0,0,3.67,9.82V69.66a6.16,6.16,0,0,0,6.15,6.15H69.66a6.16,6.16,0,0,0,6.15-6.15V9.82a6.16,6.16,0,0,0-6.15-6.15Z"
        />
        <path
          className="cls-1"
          d="M39.74,47.81A15.39,15.39,0,1,1,55.12,32.43,15.4,15.4,0,0,1,39.74,47.81Zm0-27.1A11.72,11.72,0,1,0,51.46,32.43,11.73,11.73,0,0,0,39.74,20.71Z"
        />
        <path
          className="cls-1"
          d="M67.35,78.31H63.68v-12A18.81,18.81,0,0,0,44.89,47.55H34.58A18.81,18.81,0,0,0,15.79,66.34v12H12.12v-12A22.49,22.49,0,0,1,34.58,43.88H44.89A22.48,22.48,0,0,1,67.35,66.34Z"
        />
        <path
          className="cls-1"
          d="M26.34,35.48c-.76,0-1.24,0-1.36,0l.17-3.67c.18,0,18.2.71,23.85-8.46l3.12,1.92C46.28,34.73,31,35.48,26.34,35.48Z"
        />
        <rect
          className="cls-1"
          x="37.9"
          y="45.85"
          width="3.67"
          height="31.79"
        />
      </g>
    </g>
  </svg>
);

export default AccountsIcon;
