import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Vote from './Vote';

import { launchRemoveDialog } from '../../remove/actions';

import {
  addVoteItem,
  rearrangeItems,
  setPropVote,
  handleButtonDesignChange,
  fieldChange,
  loadGoogleAnalytics,
  addField,
  removeField,
  rearrangeField,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  googleAnalytics: state.app.googleAnalytics,
  ...ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onLoadGoogleAnalytics: () =>
    dispatch(loadGoogleAnalytics(ownProps.match.params.appname)),
  onAddItem: title =>
    dispatch(
      addVoteItem(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        title,
      ),
    ),
  onRemoveItem: item =>
    dispatch(
      launchRemoveDialog(
        ownProps.match.params.appname,
        {
          ...queryString.parse(ownProps.location.search),
          item,
        },
        'ITEM',
      ),
    ),
  rearrangeItems: (id, siblingId) =>
    dispatch(
      rearrangeItems(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        id,
        siblingId,
      ),
    ),
  onButtonDesignChange: property => (type, value) =>
    dispatch(
      handleButtonDesignChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        type,
        value,
        property,
      ),
    ),
  setPropVote: (propName, value, ref = '') =>
    dispatch(
      setPropVote(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        propName,
        value,
        ref,
      ),
    ),
  onAddField: value =>
    dispatch(
      addField(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
      ),
    ),
  onRemoveField: (index, key) =>
    dispatch(
      removeField(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        index,
        key,
      ),
    ),
  onRearrangeField: (id, siblingId) =>
    dispatch(
      rearrangeField(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        id,
        siblingId,
      ),
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Vote));
