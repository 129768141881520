import React from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import SettingsImage from './images/bookingSettings.jpg';
import StaffTableImage from './images/staffTable.jpg';
import AppointmentTableImage from './images/appointmentsTable.jpg';
import BookingTableImage from './images/bookingsTable.jpg';
import BookingImage1 from './images/booking1.jpg';
import BookingImage2 from './images/booking2.jpg';
import BookingImage3 from './images/booking3.jpg';
import BookingImage4 from './images/booking4.jpg';
import BookingImage5 from './images/booking5.jpg';
import BookingImage6 from './images/booking6.jpg';
import BookingImage7 from './images/booking7.jpg';

import styles from './styles.module.scss';

// eslint-disable-next-line react/prop-types
const Features = ({ isAppAdmin = false }) => {
  const { t } = useTranslation();
  const isSubAdminWithMulipleApps = useSelector(
    state =>
      (state.account.auth.profile.admin_appnames &&
        Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
      false,
  );

  return (
    <div style={{ overflow: 'scroll', padding: 0 }}>
      <div className={styles.container_top}>
        {/* <h1>Features</h1> */}
        <p>
          <Trans>
            The Booking feature works hand in hand with the Booking component
            which can be added from the Components menu in the section Build
            -&gt; Pages.
          </Trans>
        </p>
        <p>
          <Trans>
            You can add the appointments items in the Appointments section, and
            these will be reflected in your Booking componnent.
          </Trans>
        </p>
        {!isAppAdmin && !isSubAdminWithMulipleApps && (
          <p>
            <Trans>Plus, check out our</Trans>{' '}
            <a
              href="https://beezer.zendesk.com/hc/en-us/articles/5608165130641-Business-Tools-Bookings"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#261ed5', fontWeight: 'bold' }}
            >
              <Trans>article</Trans>
            </a>{' '}
            <Trans>to learn more about how Booking feature works.</Trans>
          </p>
        )}
      </div>
      <div className={styles.container_middle}>
        <h1>
          <Trans>CORE FUNCTIONALITIES</Trans>
        </h1>
        <p>
          • <Trans>Add and update appointments</Trans>
        </p>
        <p>
          • <Trans>Payments through Stripe</Trans>
        </p>
        <p>
          • <Trans>Updates via email and notifications</Trans>
        </p>
        <p>
          • <Trans>Assign appointment to staff</Trans>
        </p>
      </div>
      <div className={styles.container_bottom}>
        <div className={styles.card}>
          <div>
            <h3>
              <Trans>STAFF</Trans>
            </h3>
            <p>
              <Trans>
                You can add all your staff members here. To add a Staff Member
                click the '+' button on top. You can assign appointments to your
                staff members. You can add multiple Staff Members for your app.
              </Trans>
            </p>
            <p>
              <Trans>
                You can always update your staff member data later if you want.
              </Trans>
            </p>
          </div>
          <img className={styles.image} src={StaffTableImage} alt="staff" />
        </div>
        <div className={styles.card_reverse}>
          <div>
            <h3>
              <Trans>APPOINTMENTS</Trans>
            </h3>
            <p>
              <Trans>
                Appointments is the place to go to view all of your created
                appointments, both active and expired. You can also create new
                appointment here and assign it to your staff.
              </Trans>
            </p>
            <p>
              <Trans>
                You can filter through all your appointments in case you need to
                find a specific appointment. Finally, you can view appointment
                details to get detailed information about an appointment.
              </Trans>
            </p>
          </div>
          <img
            className={styles.image}
            src={AppointmentTableImage}
            alt={t('appointments')}
          />
        </div>
        <div className={styles.card}>
          <div>
            <h3>
              <Trans>BOOKINGS</Trans>
            </h3>
            <p>
              <Trans>
                Bookings is the place to go to view all of your booked
                appointments. You can change the staff assignment for a booking
                here.
              </Trans>
            </p>
            <p>
              <Trans>
                You can filter through all your bookings in case you need to
                find a specific booking. Finally, you can view booking details
                to get detailed information about a booking.
              </Trans>
            </p>
          </div>
          <img
            className={styles.image}
            src={BookingTableImage}
            alt={t('bookings')}
          />
        </div>
        <div className={styles.card_reverse}>
          <div>
            <h3>
              <Trans>MAP, NOTIFICATION & PAYMENTS</Trans>
            </h3>
            <p>
              <Trans>
                You can add your Google Map API key here to display a map with
                the location of your appointment.
              </Trans>
            </p>
            <p>
              <Trans>
                Here you are required to add your Stripe API keys in order to
                connect the app to your Stripe Business account and process
                payments for your booked appointments.
              </Trans>
            </p>
            <p>
              <Trans>
                There are also of other features you can configure such as an
                the currency you want to accept payments in, the email for
                appointment updates.
              </Trans>
            </p>
          </div>
          <img
            className={styles.image}
            src={SettingsImage}
            alt={t('settings')}
          />
        </div>
        <div className={styles.container_appshell}>
          <h1>
            <Trans>CHECK IT OUT</Trans>
          </h1>

          <p>
            <Trans>
              Below you can see how an app with booking component will look like
            </Trans>
          </p>

          <div>
            <img src={BookingImage1} alt={t('booking app 1')} />
            <img src={BookingImage2} alt={t('booking app 2')} />
            <img src={BookingImage3} alt={t('booking app 3')} />
            <img src={BookingImage4} alt={t('booking app 4')} />
            <img src={BookingImage5} alt={t('booking app 5')} />
            <img src={BookingImage6} alt={t('booking app 6')} />
            <img src={BookingImage7} alt={t('booking app 7')} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
