import { connect } from 'react-redux';
import _get from 'lodash/get';
import AppSplashScreen from './AppSplashScreen';
import {
  onColorSubmit,
  saveSplashScreenSetting,
  saveSplashBackground,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  splashScreen: {
    ..._get(
      state,
      `editor.design.${ownProps.match.params.appname}.splash_screen`,
    ),
  },
  newColorChosen: state.editor.shared.appSplashScreen.newColorChosen,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSplashColorChange: color =>
    dispatch(saveSplashBackground(ownProps.match.params.appname, color.rgb)),
  onSplashScreenSettingUpdate: (attr, value) =>
    dispatch(
      saveSplashScreenSetting(ownProps.match.params.appname, attr, value),
    ),
  onColorSubmit: () => dispatch(onColorSubmit(ownProps.match.params.appname)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppSplashScreen);
