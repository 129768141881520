import React from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Tabs, Tab } from '../../../../../components/tabs';
import SectionHeader from '../../../../../components/sectionHeader';
import Container from '../../../../../../../app/components/container';

const PgmolPlMatchSupport = props => {
  const { t } = useTranslation();
  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container>
          <SectionHeader title={t('PL Match Day Support')} tooltip="" />
          <p style={{ padding: '0px 18px' }}>
            <Trans>To edit the PL Match Day Support, please go to PGMOL</Trans>{' '}
            -&gt;{' '}
            <Link to={`/${props.match.params.appname}/pgmol/pl-match-support`}>
              <Trans>PL Match Day Support.</Trans>
            </Link>
          </p>
        </Container>
      </Tab>
      <Tab
        label={t('design')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
      <Tab
        label={t('layout')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
    </Tabs>
  );
};

export default PgmolPlMatchSupport;
