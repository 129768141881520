import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CarouselItem = styled.div`
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
`;

export const CarouselItemCounter = styled.div`
  color: #000000;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
`;
const CarouselButton = styled(IconButton)`
  && {
    position: absolute;
    cursor: pointer;
    top: 50%;
    z-index: 1;
    transition: transform 0.1s ease-in-out;
    background: white;
    border-radius: 50%;
    border: none;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    &:hover {
      background: #fff;
    }
  }
`;

export const LeftCarouselButton = styled(CarouselButton)`
  left: 10px;
  transform: translate(0%, -50%);
  visibility: ${({ hasItemsOnLeft }) => (hasItemsOnLeft ? `all` : `hidden`)};
`;

export const RightCarouselButton = styled(CarouselButton)`
  right: 10px;
  transform: translate(0%, -50%);
  visibility: ${({ hasItemsOnRight }) => (hasItemsOnRight ? `all` : `hidden`)};
`;

export const CarouselContainerInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: scroll;
  column-gap: 1rem;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    scroll-snap-align: center;
  }
`;
