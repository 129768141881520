import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import { Trans, useTranslation } from 'react-i18next';

import themeStyles from '../../../../../../_export.scss';
import '../../styles.scss';

const TrackSwitchedColor = { backgroundColor: themeStyles.primaryColorLight };
const ThumbSwitchedColor = { backgroundColor: themeStyles.primaryColor };

const FieldsVisibility = ({
  profileEnabled,
  landlineVisible,
  genderVisible,
  disabilityVisible,
  ethnicGroupVisible,
  religionVisible,
  sexualOrientationVisible,
  postalAddressVisible,
  postCodeVisible,
  localAuthorityVisible,
  dobVisible,
  organisationVisible,
  roleVisible,
  linkedInAddressVisible,
  twitterAddressVisible,
  websiteAddressVisible,
  facebookAddressVisible,
  interestsVisible,
  martialStatusVisible,
  genderReassignmentVisible,
  leaveTypeVisible,
  updateField,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="auth-panel">
        <div className="auth-panel-title">
          <Trans>Visible User Profile Fields</Trans>
        </div>
        {profileEnabled ? (
          <div className="auth-panel">
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'organisationVisible')}
              toggled={organisationVisible}
              label={t('Organization')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'roleVisible')}
              toggled={roleVisible}
              label={t('Role')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'landlineVisible')}
              toggled={landlineVisible}
              label={t('Landline Number')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'interestsVisible')}
              toggled={interestsVisible}
              label={t('Interests')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'genderVisible')}
              toggled={genderVisible}
              label={t('Gender')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'dobVisible')}
              toggled={dobVisible}
              label={t('Date of Birth')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'disabilityVisible')}
              toggled={disabilityVisible}
              label={t('Disability')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'ethnicGroupVisible')}
              toggled={ethnicGroupVisible}
              label={t('Ethnic Group')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'religionVisible')}
              toggled={religionVisible}
              label={t('Religion')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'sexualOrientationVisible')}
              toggled={sexualOrientationVisible}
              label={t('Sexual Orientation')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'genderReassignmentVisible')}
              toggled={genderReassignmentVisible}
              label={t('Gender Reassignment (Transidentity)')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'leaveTypeVisible')}
              toggled={leaveTypeVisible}
              label={t('Pregnant or on Maternity/Paternity leave')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'martialStatusVisible')}
              toggled={martialStatusVisible}
              label={t('Marriage/Civil Partnership')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'postalAddressVisible')}
              toggled={postalAddressVisible}
              label={t('Postal Address')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'postCodeVisible')}
              toggled={postCodeVisible}
              label={t('Post Code')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'localAuthorityVisible')}
              toggled={localAuthorityVisible}
              label={t('Local Authority')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'linkedInAddressVisible')}
              toggled={linkedInAddressVisible}
              label={t('LinkedIn Address')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'twitterAddressVisible')}
              toggled={twitterAddressVisible}
              label={t('Twitter Handle')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'websiteAddressVisible')}
              toggled={websiteAddressVisible}
              label={t('Website URL')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'facebookAddressVisible')}
              toggled={facebookAddressVisible}
              label={t('Facebook URL')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
          </div>
        ) : (
          <p>
            <Trans>User Profile is turned off</Trans>
          </p>
        )}
      </div>
    </div>
  );
};

FieldsVisibility.propTypes = {
  profileEnabled: PropTypes.bool,
  organisationVisible: PropTypes.bool.isRequired,
  roleVisible: PropTypes.bool.isRequired,
  linkedInAddressVisible: PropTypes.bool.isRequired,
  twitterAddressVisible: PropTypes.bool.isRequired,
  websiteAddressVisible: PropTypes.bool.isRequired,
  facebookAddressVisible: PropTypes.bool.isRequired,
  interestsVisible: PropTypes.bool.isRequired,
  landlineVisible: PropTypes.bool.isRequired,
  genderVisible: PropTypes.bool.isRequired,
  disabilityVisible: PropTypes.bool.isRequired,
  ethnicGroupVisible: PropTypes.bool.isRequired,
  religionVisible: PropTypes.bool.isRequired,
  sexualOrientationVisible: PropTypes.bool.isRequired,
  postalAddressVisible: PropTypes.bool.isRequired,
  postCodeVisible: PropTypes.bool.isRequired,
  localAuthorityVisible: PropTypes.bool.isRequired,
  dobVisible: PropTypes.bool.isRequired,
  updateField: PropTypes.func.isRequired,
};

FieldsVisibility.defaultProps = {
  profileEnabled: true,
  signupEnabled: true,
};

export default FieldsVisibility;
