import React from 'react';

const Web = () => (
  <svg
    enableBackground="new 0 0 32 32"
    version="1.1"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Style_2_copy_3">
      <g>
        <g>
          <path
            d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16s16-7.164,16-16C32,7.163,24.837,0,16,0z"
            fill="#333333"
          />
        </g>
      </g>
      <g>
        <path
          d="M4.905,13.267l0.436,2.236c0.111,0.57,0.223,1.174,0.313,1.8h0.022c0.112-0.626,0.269-1.252,0.402-1.789    l0.582-2.247h1.342l0.547,2.18c0.146,0.615,0.291,1.23,0.403,1.856h0.022c0.078-0.626,0.19-1.241,0.313-1.867l0.47-2.169h1.666    l-1.678,5.467H8.147L7.632,16.81c-0.134-0.537-0.235-1.029-0.346-1.699H7.264c-0.101,0.682-0.212,1.185-0.358,1.699l-0.548,1.923    H4.76l-1.588-5.467H4.905z"
          fill="#FFFFFF"
        />
        <path
          d="M13.608,13.267l0.436,2.236c0.111,0.57,0.223,1.174,0.313,1.8h0.022c0.112-0.626,0.269-1.252,0.402-1.789    l0.582-2.247h1.342l0.547,2.18c0.146,0.615,0.291,1.23,0.403,1.856h0.022c0.078-0.626,0.19-1.241,0.313-1.867l0.47-2.169h1.666    l-1.678,5.467h-1.598l-0.514-1.923c-0.134-0.537-0.235-1.029-0.346-1.699h-0.023c-0.101,0.682-0.212,1.185-0.358,1.699    l-0.548,1.923h-1.598l-1.588-5.467H13.608z"
          fill="#FFFFFF"
        />
        <path
          d="M22.31,13.267l0.436,2.236c0.111,0.57,0.223,1.174,0.313,1.8h0.022c0.112-0.626,0.269-1.252,0.402-1.789    l0.582-2.247h1.342l0.547,2.18c0.146,0.615,0.291,1.23,0.403,1.856h0.022c0.078-0.626,0.19-1.241,0.313-1.867l0.47-2.169h1.666    l-1.678,5.467h-1.598l-0.514-1.923c-0.134-0.537-0.235-1.029-0.346-1.699h-0.023c-0.101,0.682-0.212,1.185-0.358,1.699    l-0.548,1.923h-1.598l-1.588-5.467H22.31z"
          fill="#FFFFFF"
        />
      </g>
    </g>
  </svg>
);

export default Web;
