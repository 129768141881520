const initialState = {
  diyComponents: {},
  isProcessing: false,
};

const addons = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_DIY_COMPONENTS':
      return {
        ...state,
        diyComponents: action.value,
      };
    case 'SET_PROCESSING_SPINNER':
      return {
        ...state,
        isProcessing: action.value,
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default addons;
