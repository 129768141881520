import { validateField } from '../../../validators';
import firebase from '../../../../../../../firebase';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';
import { COURSE_UPDATED } from '../../../config';
import { STAFF_ADMIN } from '../../../../../../../config';

export const EDIT_FIELD_CHANGE_COURSES = 'EDIT_FIELD_CHANGE_COURSES';
export const CLOSE_EDIT_DIALOG_COURSES = 'CLOSE_EDIT_DIALOG_COURSES';
export const UPDATE_REQUEST_COURSES = 'UPDATE_REQUEST_COURSES';
export const UPDATED_COURSES = 'UPDATED_COURSES';
export const LOADING_COURSES = 'LOADING_COURSES';
export const LOADED_DETAILS_COURSES = 'LOADED_DETAILS_COURSES';
export const RESET_COURSES = 'RESET_COURSES';

const fieldChange = (prop, id, value, isValid) => ({
  type: EDIT_FIELD_CHANGE_COURSES,
  prop,
  id,
  value,
  isValid,
});

export const closeDialog = () => ({ type: CLOSE_EDIT_DIALOG_COURSES });

export const resetData = () => ({
  type: RESET_COURSES,
});

export const loadingCourse = () => ({
  type: LOADING_COURSES,
});

export const loadCourse = prop => ({
  type: LOADED_DETAILS_COURSES,
  prop,
});

export const formFieldChange = (isRequired, prop, id, value, index) => (
  dispatch,
  getState,
) => {
  let data = value;
  const valid = data ? validateField[prop](data) : null;
  const isValid = isRequired ? valid : true;
  // merge dates to array [fromDate, toDate]
  if (prop === 'DateRange' || prop === 'Daterange') {
    if (getState().staff.courses.edit.fieldsData[id]) {
      const dateRange = getState().staff.courses.edit.fieldsData[id].value;
      if (index === 0) {
        data = dateRange[1] ? [value, dateRange[1]] : [value, value];
      } else {
        data = dateRange[0] ? [dateRange[0], value] : [value, value];
      }
    } else {
      data = [value, value];
    }
  }
  return dispatch(fieldChange(prop, id, data, isValid));
};

export const closeModal = (app, history, role) => dispatch => {
  dispatch(closeDialog());
  if (role === STAFF_ADMIN) {
    history.push(`/${app}/staff/courses`);
  } else {
    history.push(`/${app}/members/courses`);
  }
};

export const getCourseDetails = (appname, course, memberbody) => dispatch => {
  dispatch(loadingCourse());
  return firebase
    .database()
    .ref(`app_courses/${appname}/courses/${memberbody}/${course}`)
    .once('value')
    .then(snapshot => {
      const data = snapshot.exists() ? snapshot.val() : {};
      dispatch(loadCourse(data));
    });
};

export const updateCourse = (courseID, history, appname, memberbody) => (
  dispatch,
  getState,
) => {
  dispatch({ type: UPDATE_REQUEST_COURSES });
  const { fieldsData } = getState().staff.courses.edit;
  const { role } = getState().account.profile;
  fieldsData.updatedAt = Date.now();
  fieldsData.memberbody = memberbody;
  return firebase
    .database()
    .ref(`app_courses/${appname}/courses/${memberbody}/${courseID}`)
    .update(fieldsData)
    .then(() => {
      dispatch(closeModal(appname, history, role));
      dispatch({
        type: UPDATED_COURSES,
        key: courseID,
        data: fieldsData,
      });
      dispatch(openSnackbar(COURSE_UPDATED));
    });
};
