import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import CircularProgress from '../../../../../../app/components/progress/circularProgress';
import UserInfo from '../../components/userInfo';
import Products from '../products';
import * as Styled from './styles';
const Vendor = ({
  vendor,
  allProducts,
  pending,
  getVendorDetails,
  getProducts,
  stopGetProducts,
  stopGetVendorDetails,
  changeVendorApproval,
}) => {
  useEffect(() => {
    getVendorDetails();
    getProducts();
    return () => {
      stopGetVendorDetails();
      stopGetProducts();
    };
  }, []);

  return (
    <>
      {pending ? (
        <CircularProgress />
      ) : (
        <Styled.container>
          <UserInfo
            user={vendor}
            changeVendorApproval={changeVendorApproval}
            type="seller"
          />
          <Styled.section>
            <h2>
              <Trans>Products</Trans>
            </h2>
            <Products allProducts={allProducts} showProductActions={true} />
          </Styled.section>
        </Styled.container>
      )}
    </>
  );
};

Vendor.propTypes = {
  vendor: PropTypes.object.isRequired,
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
};

export default Vendor;
