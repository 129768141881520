import React, { Component } from 'react';

class OlarkChat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      identifier: process.env.REACT_APP_OLARK_IDENTIFIER,
      nodeEnv: process.env.NODE_ENV,
    };
  }

  componentDidMount() {
    if (!this.shouldInjectChat()) {
      return;
    }
    this.injectChat(this.state.identifier);
  }

  /**
   * Check if the running envioroment is production
   * and that the olark's identifier is defined to validate
   * chat's injection
   */
  shouldInjectChat = () =>
    this.state.identifier && this.state.NODE_ENV === 'production';

  injectChat = () => {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('async', true);
    script.innerHTML = `;(function(o,l,a,r,k,y){if(o.olark)return;
      r="script";y=l.createElement(r);r=l.getElementsByTagName(r)[0];
      y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r);
      y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)};
      y.extend=function(i,j){y("extend",i,j)};
      y.identify=function(i){y("identify",k.i=i)};
      y.configure=function(i,j){y("configure",i,j);k.c[i]=j};
      k=y._={s:[],t:[+new Date],c:{},l:a};
      })(window,document,"static.olark.com/jsclient/loader.js");
      /* Add configuration calls below this comment */
      olark.configure('features.attention_grabber', false);
      olark.identify('${this.state.identifier}');
      `;
    document.getElementById('olark-chat-wrapper').appendChild(script);
  };

  render() {
    return <div id="olark-chat-wrapper" />;
  }
}

export default OlarkChat;
