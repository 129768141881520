import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import CreateTab from './CreateTab';

import { createTab, titleChange, closeCreateTabUI } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  form: state.editor.build.create.createTab,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { appname } = ownProps.match.params;
  const { page } = queryString.parse(ownProps.location.search);

  return {
    onSubmit: form => dispatch(createTab(appname, page, form)),
    onCancel: () => dispatch(closeCreateTabUI()),
    onTitleChange: value => dispatch(titleChange(value)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CreateTab),
);
