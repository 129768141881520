import {
  NEW_ADMIN_FIELD_CHANGE,
  OPEN_NEW_ADMIN_DIALOG,
  CLOSE_NEW_ADMIN_DIALOG,
  REGISTRATION_ERROR,
  ADD_NEW_ADMIN,
  NEW_ADMIN_ADDED,
  HAS_CUSTOM_DOMAIN,
  NEW_ADMIN_SECTIONS_ACCESS,
} from './actions';

const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  hasCustomDomain: true,
  data: null,
  firstName: {
    value: '',
    isValid: null,
  },
  lastName: {
    value: '',
    isValid: null,
  },
  email: {
    value: '',
    isValid: null,
  },
  password: {
    value: '',
    isValid: null,
  },
  adminAppname: {
    value: [],
    isValid: null,
  },
  adminSMSCredits: {
    value: 0,
    isValid: true,
  },
  adminWhatsappSMSCredits: {
    value: 0,
    isValid: true,
  },
  sectionsAccess: {
    build: true,
    distribute: true,
    engage: true,
    analytics: true,
    member_login: true,
    business_tools: true,
  },
};

const newAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_NEW_ADMIN_DIALOG:
      return {
        ...state,
        open: true,
      };

    case CLOSE_NEW_ADMIN_DIALOG:
      return {
        ...state,
        open: false,
      };

    case NEW_ADMIN_FIELD_CHANGE:
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };

    case REGISTRATION_ERROR:
      return {
        ...state,
        error: action.err,
        pending: false,
      };
    case HAS_CUSTOM_DOMAIN:
      return {
        ...state,
        hasCustomDomain: action.value,
      };

    case ADD_NEW_ADMIN:
      return {
        ...state,
        pending: true,
      };

    case NEW_ADMIN_ADDED:
      return initialState;

    case NEW_ADMIN_SECTIONS_ACCESS:
      return {
        ...state,
        sectionsAccess: {
          ...state.sectionsAccess,
          [action.field]: action.value,
        },
      };

    default:
      return state;
  }
};

export default newAdminReducer;
