import { RECEIVED_ACCESSIBILITY_SETTINGS } from './types';

const accessibility = (state = {}, action) => {
  switch (action.type) {
    case RECEIVED_ACCESSIBILITY_SETTINGS:
      return {
        ...state,
       ...action.payload
      };

    default:
      return {
        ...state,
      };
  }
};

export default accessibility;
