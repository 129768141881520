
import {
  TOGGLE_FK_GROUP_DIALOG,
  TOGGLE_FK_GROUP_DETAILS_DIALOG,
  SET_FK_GROUP_ID,
  TOGGLE_FK_USER_DIALOG,
  SET_FK_USER_ID,
  TOGGLE_FK_USER_DETAILS_DIALOG,
} from './types';

const initialState = {
  fkGroupDialogOpen: false,
  fkGroupDetailsDialogOpen: false,
  fkUserDetailsDialogOpen: false,
  fkGroupId: '',
  fkGroupUserOpen: false,
  fkUserId: '',
};

const fkAcademyReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_FK_GROUP_DIALOG:
      return {
        ...state,
        fkGroupDialogOpen: action.payload,
      };
    case TOGGLE_FK_GROUP_DETAILS_DIALOG:
      return {
        ...state,
        fkGroupDetailsDialogOpen: action.payload,
      };
    case TOGGLE_FK_USER_DETAILS_DIALOG:
      return {
        ...state,
        fkUserDetailsDialogOpen: action.payload,
      };
    case SET_FK_GROUP_ID:
      return {
        ...state,
        fkGroupId: action.payload,
      };
    case TOGGLE_FK_USER_DIALOG:
      return {
        ...state,
        fkUserDialogOpen: action.payload,
      };
    case SET_FK_USER_ID:
      return {
        ...state,
        fkUserId: action.payload,
      };
    default:
      return state;
  }
};

export default fkAcademyReducer;
