import { connect } from 'react-redux';
import Settings from './Settings';
import { getUsers } from '../../../modules/users/actions';
import { updateSelectedUsers, onSetDefaultGroupData } from '../actions';
const mapStateToProps = (state, ownProps) => {
  const defaultGroup = state.auth.groups.data.chunks.default;
  return {
    ...ownProps,
    ...state.auth.diaryData,
    chunks: state.auth.users.chunks,
    enabled: defaultGroup ? defaultGroup.diaryPublicAccess : false,
    publicAccessUsers: defaultGroup ? defaultGroup.publicAccessUsers : [],
    userPrivateAccess: defaultGroup ? defaultGroup.userPrivateAccess : false,
    diaryPages: defaultGroup ? defaultGroup.diaryPages : {},
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUsers: () => dispatch(getUsers(ownProps.match.params.appname)),
  updateSelectedUsers: users =>
    dispatch(updateSelectedUsers(ownProps.match.params.appname, users)),
  onTogglePublicAccess: value =>
    dispatch(
      onSetDefaultGroupData(ownProps.match.params.appname, {
        diaryPublicAccess: value,
      }),
    ),
  onToggleUserPrivateAccess: value =>
    dispatch(
      onSetDefaultGroupData(ownProps.match.params.appname, {
        userPrivateAccess: value,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
