import {
  REQUEST_VENDOR_DETAILS,
  RECEIVE_VENDOR_DETAILS,
  REQUEST_VENDOR_PRODUCTS,
  RECEIVE_VENDOR_PRODUCTS,
  NO_VENDOR_PRODUCTS_EXIST,
} from './types';

const initialState = {
  pending: false,
  error: null,
  vendor: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    postCode: '',
    postalAddress: '',
    photoURL: '',
  },
  allProducts: [],
};

const vendor = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_VENDOR_DETAILS:
      return {
        ...state,
        pending: true,
      };

    case RECEIVE_VENDOR_DETAILS:
      return {
        ...state,
        vendor: action.value,
        pending: false,
        error: null,
      };

    case REQUEST_VENDOR_PRODUCTS:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_VENDOR_PRODUCTS: {
      return {
        ...state,
        allProducts: action.value,
        pending: false,
        error: null,
      };
    }

    case NO_VENDOR_PRODUCTS_EXIST:
      return {
        ...state,
        pending: false,
        allProducts: [],
      };

    default:
      return state;
  }
};

export default vendor;
