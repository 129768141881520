import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import { useTranslation } from 'react-i18next';

import Container from '../../../../../../../app/components/container';
import { Tabs, Tab } from '../../../../../components/tabs';
import TooltipElement from '../../../../../../../app/components/tooltipElement/TooltipElement';
import ColorPicker from '../../../../../components/colorPicker';
import DesignToggle from '../../../../../components/designToggle';
import ForumDisabledWrapper from './ForumDisabledWrapper';
import style from './forum.module.scss';

const styles = {
  toggle: {
    marginTop: 24,
    marginBottom: 12,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 6,
    width: '90%',
  },
  toggleLabel: {
    fontSize: 14,
  },
  tooltip: {
    right: 18,
    top: 7,
    position: 'relative',
  },
};

const Forum = props => {
  const {
    data,
    component,
    onDesignChange,
    design,
    onChangeLikes,
    memberLoginEnabled,
    appname,
    toggleAuth,
  } = props;
  const handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    onDesignChange(prop, rgba);
  };

  const handleToggleLikes = prop => (e, isToggled) =>
    onChangeLikes(prop, isToggled);
  const { t } = useTranslation();

  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container style={{ display: 'flex', flexDirection: 'row' }}>
          <Toggle
            label={t('Enable Likes')}
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.likes}
            onToggle={handleToggleLikes(data.likes)}
          />
          <TooltipElement
            className={style.tooltip}
            tooltip={t('Allows users to like posts and comments.')}
            verticalPosition="top"
            horizontalPosition="left"
          />

          {!memberLoginEnabled && (
            <ForumDisabledWrapper
              appname={appname}
              open={!memberLoginEnabled}
              toggleAuth={toggleAuth}
            />
          )}
        </Container>
      </Tab>
      <Tab label={t('design')}>
        <Container>
          <DesignToggle component={component} onDesignChange={onDesignChange} />

          <ColorPicker
            label={t('Background Color')}
            color={design.backgroundColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('backgroundColor')}
          />
          <ColorPicker
            label={t('Post Hover Color')}
            color={design.hover}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('hover')}
          />
          <ColorPicker
            label={t('Primary Text Color')}
            color={design.primary}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('primary')}
          />
          <ColorPicker
            label={t('Secondary Text Color')}
            color={design.secondary}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('secondary')}
          />
          <ColorPicker
            label={t('Post Details Color')}
            color={design.tertiary}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('tertiary')}
          />
          <ColorPicker
            label={t('Border Color')}
            color={design.border}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('border')}
          />
          <ColorPicker
            label={t('Post Button Color')}
            color={design.button}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('button')}
          />
          <ColorPicker
            label={t('Post Button Disabled Color')}
            color={design.buttonDisabled}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('buttonDisabled')}
          />
          {/* <ColorPicker
            label="Quaternary Text Color"
            color={design.readMore}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('readMore')}
          /> */}
        </Container>
      </Tab>
      <Tab
        label={t('layout')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
    </Tabs>
  );
};

Forum.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeLoop: PropTypes.func.isRequired,
  onChangeMuted: PropTypes.func.isRequired,
  onChangeAutoPlay: PropTypes.func.isRequired,
  onChangeComments: PropTypes.func.isRequired,
  onChangeCommentsAuth: PropTypes.func.isRequired,
  onChangeCommentsBanBadWords: PropTypes.func.isRequired,
  appname: PropTypes.string.isRequired,
};

export default Forum;
