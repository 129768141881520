import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'material-ui/Avatar';

import img from './avatar.png';

const styles = {
  avatar: {
    margin: 0,
    padding: 0,
    borderRadius: '27%',
    border: '1px solid #a3a3a3',
  },
};

const CustomAvatar = ({ onClick, size }) => (
  <Avatar style={styles.avatar} size={size} src={img} onClick={onClick} />
);

CustomAvatar.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
};

CustomAvatar.defaultProps = {
  onClick: undefined,
  size: 36,
};

export default CustomAvatar;
