import React, { useState, useEffect } from 'react';
import { get, isEmpty, filter } from 'lodash';
import { TextField, Button } from '@material-ui/core';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import StyledDialog from '../../../../../../app/components/StyledDialog';
import VideoCarousel from '../../components/VideoCarousel';
import Rating from '../../../../../../app/components/rating';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import { addFeedback } from './api';
import { routeHasComponent } from '../../../../../../utils/routes';
import './styles.css';

const FormField = styled(TextField)`
  && {
    margin: 10px 0;
    width: 100%;
    input,
    textarea {
      font-family: 'Raleway SemiBold', 'Roboto Light', 'Rubik', Arial,
        sans-serif;
      &::placeholder {
        color: #000000;
      }
    }
    fieldset {
      border: 1px solid #000000;
      border-radius: 12px;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    width: 150px;
    height: 60px;
    font-size: 1.5rem;
    text-transform: none;
    border-radius: 12px;
  }
`;

const FeedbackDialog = ({ feedback, onClose, match }) => {
  const { t } = useTranslation();
  const [feedbackText, setFeedbackText] = useState('');
  const [rating, setRating] = useState(0);
  const appname = get(match, 'params.appname', '');
  const dispatch = useDispatch();
  const routes = useSelector(state =>
    get(state, 'editor.structure.routes', []),
  );
  const uploadFeedbackMutation = useMutation(addFeedback, {
    onSuccess: () => {
      dispatch(
        openSnackbar(t('Your feedback has been successfully submitted.')),
      );
      onClose();
    },
    onError: async () => {
      dispatch(openSnackbar(t('Something went wrong. Try again later')));
    },
  });
  const pending = uploadFeedbackMutation.isLoading;

  useEffect(() => {
    setFeedbackText(get(feedback, 'feedbackText', ''));
    setRating(get(feedback, 'rating', 0));
  }, [feedback]);

  const submitFeedback = async e => {
    e.preventDefault();
    const feedbackId = get(feedback, 'id', '');
    const userId = get(feedback, 'userId', '');
    const name = get(feedback, 'name', '');
    const complete = get(feedback, 'complete', false);
    const feedbackPageRoute = filter(routes, route =>
      routeHasComponent(route, 'Feedback'),
    )[0];
    let feedbackPagePath = get(feedbackPageRoute, 'path', '');
    feedbackPagePath =
      feedbackPagePath === 'homepage' || feedbackPagePath === ''
        ? ''
        : `/${feedbackPagePath}`;
    if (feedbackId && appname) {
      uploadFeedbackMutation.mutate({
        feedbackId,
        name,
        userId,
        appname,
        feedbackText,
        rating,
        complete,
        feedbackPagePath,
      });
    }
  };

  const feedbackForm = (
    <form className="feedback-container" onSubmit={submitFeedback}>
      <div className="feedback-title">
        <span>{get(feedback, 'name', '')}</span>
      </div>
      <div className="feedback-videos">
        <VideoCarousel urls={get(feedback, 'videos', [])} />
      </div>

      <div className="feedback-description">
        <span className="title">
          <Trans>Description:</Trans>{' '}
        </span>
        <p>{get(feedback, 'description', '')}</p>
      </div>
      <div className="feedback-rating">
        <Rating
          value={rating}
          setRating={value => {
            setRating(value);
          }}
        />
      </div>
      <div className="feedback-text">
        <FormField
          fullWidth
          value={feedbackText}
          type="text"
          onChange={e => setFeedbackText(e.target.value)}
          placeholder={t('Add your feedback here...')}
          variant="outlined"
          name="feedbackText"
          multiline
          disabled={pending}
          rows={5}
          required
        />
      </div>
      <StyledButton
        variant="contained"
        color="primary"
        type="submit"
        disabled={pending}
      >
        <Trans>Submit</Trans>
      </StyledButton>
    </form>
  );
  return (
    <StyledDialog
      maxWidth="lg"
      fullWidth
      open={!isEmpty(feedback)}
      content={feedbackForm}
      onClose={onClose}
    />
  );
};

FeedbackDialog.propTypes = {
  feedback: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
export default withRouter(FeedbackDialog);
