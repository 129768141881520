import { connect } from 'react-redux';

import DuplicateApp from './DuplicateApp';

import { domainChange, titleChange, duplicateApp, resetData } from './actions';
import { changeApp } from '../../../../../internals/accountsDropdown/actions';
import { updateAccount } from '../../../../../../account/actions';
const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.editor.build.create.duplicateApp,
  // external modules
  organisation: state.account.organisation,
  profile: state.account.profile,
  appToCopy: ownProps.location.state && ownProps.location.state.appToCopy,
  lastVisitedApp: state.account.profile.lastVisited,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDomainChange: value => dispatch(domainChange(value)),
  onTitleChange: value => dispatch(titleChange(value)),
  onSubmit: ({ appToCopy, newAppDomain, newAppTitle, callback }) => {
    dispatch(
      duplicateApp({
        appToCopy,
        newAppDomain,
        newAppTitle,
        callback,
      }),
    );
  },
  resetData: () => dispatch(resetData()),
  onAppChange: (newApp, lastVisitedApp) =>
    dispatch(changeApp(newApp, lastVisitedApp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateApp);
