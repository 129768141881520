import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroup, RadioButton } from 'material-ui/RadioButton';
import styled from 'styled-components';
import { Trans, withTranslation } from 'react-i18next';

import Range from 'app/components/range';
import Subtitle from 'app/components/subtitle';

import ButtonShapeMenu from '../buttonShapeMenu';
import ColorPicker from '../colorPicker';

const Div = styled.div`
  width: 100%;
`;

class ButtonDesignMenu extends Component {
  static propTypes = {
    component: PropTypes.object.isRequired,
    onDesignChange: PropTypes.func.isRequired,
  };

  handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    this.props.onDesignChange(prop, rgba);
  };

  isActionDisabled = () => {
    if (this.props.component.design.buttonDesign.enabled === undefined) {
      return !this.props.component.design.enabled;
    }
    return (
      !this.props.component.design.enabled ||
      !this.props.component.design.buttonDesign.enabled
    );
  };

  hasBorderStyle = () => {
    const { component, onDesignChange, t } = this.props;

    const { border } =
      component.type === 'Button'
        ? component.design
        : component.design.buttonDesign;

    const borderStyles = [
      { label: t('Solid'), value: 'Solid' },
      { label: t('Double'), value: 'Double' },
      { label: t('Dotted'), value: 'Dotted' },
      { label: t('Dashed'), value: 'Dashed' },
      { label: t('Ridge'), value: 'Ridge' },
      { label: t('Groove'), value: 'Groove' },
      { label: t('Inset'), value: 'Inset' },
      { label: t('Outset'), value: 'Outset' },
    ];

    return (
      border && (
        <Div>
          <ColorPicker
            label={t('Border Color')}
            color={border.color}
            height={22}
            disabled={this.isActionDisabled()}
            onChange={this.handleColorChange('border/color')}
          />
          <Subtitle className={this.isActionDisabled() && 'disabled'}>
            <Trans>Border Width</Trans>
          </Subtitle>
          <Range
            min={0}
            max={3}
            step={1}
            label={border.width === 0 ? '0px' : `${border.width}px`}
            value={border.width}
            onChange={(el, val) => onDesignChange('border/width', val)}
            disabled={this.isActionDisabled()}
            width="320px"
          />
          <Subtitle className={this.isActionDisabled() && 'disabled'}>
            <Trans>Border Style</Trans>
          </Subtitle>
          <RadioButtonGroup
            className="radio-button-group"
            label={t('Border Style')}
            style={{
              padding: '0 10px',
              margin: '0 auto',
              flexDirection: 'column',
            }}
            name="background-type"
            onChange={(el, val) => onDesignChange('border/style', val)}
            valueSelected={border.style}
          >
            {borderStyles.map(style => (
              <RadioButton
                key={style.value}
                value={style.value.toLowerCase()}
                label={style.label}
                style={{
                  fontSize: 12,
                  lineHeight: 22,
                }}
                disabled={this.isActionDisabled()}
              />
            ))}
          </RadioButtonGroup>
        </Div>
      )
    );
  };

  render() {
    const { component, onDesignChange, t } = this.props;
    const buttonDesign =
      component.type === 'Button'
        ? component.design
        : component.design.buttonDesign || {};
    return (
      <Div>
        <ButtonShapeMenu
          value={buttonDesign.borderRadius}
          onSelect={val => onDesignChange('borderRadius', val)}
          disabled={this.isActionDisabled()}
        />
        <ColorPicker
          label={t('Background Color')}
          color={buttonDesign.backgroundColor}
          height={22}
          disabled={this.isActionDisabled()}
          onChange={this.handleColorChange('backgroundColor')}
        />
        <ColorPicker
          label={t('Text Color')}
          color={buttonDesign.textColor}
          height={22}
          disabled={this.isActionDisabled()}
          onChange={this.handleColorChange('textColor')}
        />
        {this.hasBorderStyle()}
      </Div>
    );
  }
}

export default withTranslation()(ButtonDesignMenu);
