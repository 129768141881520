import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Modal from '@material-ui/core/Modal';
import Counter from '../../components/counter';
import './styles.css';
import { isFreePlan } from '../../../account/modules/integration/actions';
import DatePicker from './DateRangePicker';
import { withTranslation, Trans } from 'react-i18next';

class Chart extends Component {
  static propTypes = {
    labels: PropTypes.array,
    maxValue: PropTypes.number.isRequired,
    installs: PropTypes.object,
    shares: PropTypes.object,
    visits: PropTypes.object,
    settings: PropTypes.object,
    dropdownRangeOptions: PropTypes.object,
    callback: PropTypes.func.isRequired,
    plans: PropTypes.object,
    integrationType: PropTypes.string,
    appname: PropTypes.string,
    history: PropTypes.object.isRequired,
    openWixPlans: PropTypes.func.isRequired,
    accountId: PropTypes.string,
    trialExpired: PropTypes.bool,
  };

  static defaultProps = {
    labels: [],
    maxValue: 15,
    installs: {},
    shares: {},
    visits: {},
    settings: {},
    dropdownRangeOptions: {},
    integrationType: '',
    appname: '',
    plans: {},
    accountId: '',
    trialExpired: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
    };
  }

  onCriteriaChange = newCriteria => this.props.callback(newCriteria);

  getChartOptions = () => ({
    maintainAspectRatio: false,
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: 'rgba(232, 241, 250, 1)',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: this.props.maxValue,
          },
          gridLines: {
            color: 'rgba(254, 254, 254, 1)',
          },
        },
      ],
    },
  });

  getChartData = () => {
    const { t } = this.props;
    return {
      labels: this.props.labels,
      datasets: [
        this.datasetBuilder(
          t('Installs'),
          this.getValues(this.props.installs.values),
          'rgba(120, 255, 197, 0.5)',
          'rgba(120, 255, 197, 1)',
        ),
        this.datasetBuilder(
          t('Shares'),
          this.getValues(this.props.shares.values),
          'transparent',
          '#1e2023',
        ),
        this.datasetBuilder(
          t('Views'),
          this.getValues(this.props.visits.values),
          'rgba(38,30,213,0.5)',
          'rgba(38,30,213,1)',
        ),
      ],
    };
  };

  getValues = values => {
    if (this.isWixWeeblyFreePlan()) {
      return values.map(value => 0);
    }
    return values;
  };

  getInstalls = () => {
    const { installs } = this.props;
    const installsCount = this.getCount(installs);
    return installsCount;
  };

  getShares = () => {
    const { shares } = this.props;
    const sharesCount = this.getCount(shares);
    return sharesCount;
  };

  getVisits = () => {
    const { visits } = this.props;
    const visitsCount = visits.count;
    return visitsCount;
  };

  getCount = item => {
    const itemCount =
      this.isWixWeeblyFreePlan() && this.isWixWeeblyExpired()
        ? '-'
        : item.count;
    return itemCount;
  };

  datasetBuilder = (label, data = [], bgColor, primaryColor) => ({
    label,
    fill: true,
    lineTension: 0.333,
    backgroundColor: bgColor,
    borderColor: primaryColor,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: primaryColor,
    pointBackgroundColor: primaryColor,
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: primaryColor,
    pointHoverBorderColor: primaryColor,
    pointHoverBorderWidth: 2,
    pointRadius: 3,
    pointHitRadius: 10,
    data,
  });

  // displayOptions = formats => {
  //   const settings = this.props.settings;

  //   return (
  //     <RadioButtonGroup
  //       name="displayFormat"
  //       valueSelected={settings.step}
  //       onChange={(e, val) => this.onCriteriaChange({ step: val })}
  //       style={{ display: 'flex', width: '256px', fontSize: '12px' }}
  //     >
  //       {formats.map(format => (
  //         <RadioButton
  //           key={format.step}
  //           value={format.step}
  //           label={format.title}
  //         />
  //       ))}
  //     </RadioButtonGroup>
  //   );
  // };

  rangeDropdown = () => {
    const { dropdownRangeOptions, settings, t } = this.props;
    const data = this.getChartData();
    const isWixWeeblyFreePlan = this.isWixWeeblyFreePlan();
    const isWixWeeblyExpired = this.isWixWeeblyExpired();
    return (
      <div className="col-xs-12  analytics-action">
        <div className="flex-column justify-space-between">
          {/* {this.displayOptions(dropdownRangeOptions.displayFormats)} */}
          <SelectField
            value={settings.numWeeks ? settings.numWeeks : 'Custom Date'}
            // onChange={(e, i, val) => this.onCriteriaChange({ numWeeks: val })}
          >
            {dropdownRangeOptions.options.map(opt => (
              <MenuItem
                key={opt.title}
                value={opt.numWeeks}
                primaryText={t(opt.title)}
                onClick={() =>
                  this.onCriteriaChange({ numWeeks: opt.numWeeks })
                }
              />
            ))}
            <MenuItem
              key="Custom Date"
              value="Custom Date"
              primaryText={t('Custom Date')}
              onClick={() => this.setState({ openModal: true })}
            />
          </SelectField>
        </div>
        <div className="counters">
          <Counter
            count={this.getVisits()}
            subtitle={t('Views')}
            borderBottom
            borderColor={data.datasets[2].borderColor}
          />
          <Counter
            count={this.getInstalls()}
            subtitle={t('Installs')}
            borderBottom
            borderColor={data.datasets[0].borderColor}
            isWixWeeblyFreePlan={isWixWeeblyFreePlan && isWixWeeblyExpired}
          />
          <Counter
            count={this.getShares()}
            subtitle={t('Shares')}
            borderBottom
            borderColor={data.datasets[1].borderColor}
            isWixWeeblyFreePlan={isWixWeeblyFreePlan && isWixWeeblyExpired}
          />
        </div>
      </div>
    );
  };

  isWixWeeblyFreePlan = () => {
    const { plans, integrationType, appname } = this.props;
    return isFreePlan(plans, integrationType, appname);
  };

  isWixWeeblyExpired = () => {
    const { plans, appname, integrationType } = this.props;

    if (integrationType !== 'Wix') {
      return false;
    }

    if (!plans[appname].current_period_end) {
      return true;
    }

    const subscriptionEndDate = plans[appname].current_period_end;

    const todaysDate = new Date();

    const endDate = new Date(subscriptionEndDate);

    return endDate.getTime() < todaysDate.getTime();
  };

  isOnFreeTrial = () => {
    const { trialExpired, integrationType } = this.props;

    if (integrationType === 'Wix' && !trialExpired) {
      return true;
    }
    return false;
  };

  handleUpgrade = () => {
    const {
      integrationType,
      appname,
      history,
      openWixPlans,
      accountId,
    } = this.props;

    if (integrationType === 'Wix') {
      const plan = 'premium';
      return openWixPlans(appname, plan, history, accountId);
    }
    return this.props.history.push(`/${appname}/choose_plan`);
  };

  upgradeModal = () => {
    if (
      (!this.isWixWeeblyFreePlan() && !this.isWixWeeblyExpired()) ||
      this.isOnFreeTrial()
    )
      return <></>;
    return (
      <div className="modal-upgrade-container">
        <div className="modal-upgrade">
          <div className="modal-upgrade-content">
            <div className="modal-upgrade-text">
              <Trans>Upgrade your plan to unlock detailed analytics.</Trans>
            </div>
            <div>
              <button className="button-upgrade" onClick={this.handleUpgrade}>
                <Trans>Upgrade</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const data = this.getChartData();
    const options = this.getChartOptions();
    const isWixWeeblyFreePlan = this.isWixWeeblyFreePlan();

    const dateModalStyles = {
      border: '2px solid black',
      borderRadius: '10px',
      backgroundColor: '#fff',
      padding: '5vh',
      cursor: 'pointer',
    };

    return (
      <div className="chart">
        <div className="date-modal-container">
          <Modal
            open={this.state.openModal}
            onClose={() => this.setState({ openModal: false })}
            className="date-modal"
          >
            <DatePicker
              onDateChange={this.onCriteriaChange}
              style={dateModalStyles}
            />
          </Modal>
        </div>
        {this.rangeDropdown()}

        {this.upgradeModal()}
        <div
          className={
            isWixWeeblyFreePlan ? 'line-chart-free-plan' : 'line-chart'
          }
        >
          <Line height={300} width={600} data={data} options={options} />
        </div>
      </div>
    );
  }
}

export default withTranslation()(Chart);
