import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CreateGroup from './CreateGroup';

import {
  addMemberInGroup,
  closeMemberGroupFieldDialog,
  formFieldChange,
  groupChange,
  deleteFile,
} from './actions';

const mapStateToProps = state => ({
  ...state.auth.workforceMembers.edit.createGroup,
  groups: { ...state.auth.memberGroups.listPage.data.chunks },
  workforceGroups:
    state.auth.workforceMembers.edit.editGroup.groupDetails.workforceGroups,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () =>
    dispatch(
      addMemberInGroup(
        ownProps.match.params.appname,
        ownProps.match.params.memberID,
      ),
    ),
  onRequestClose: () => dispatch(closeMemberGroupFieldDialog()),
  onFieldChange: (isRequired, field, id, value, groupID, latlong) =>
    dispatch(formFieldChange(isRequired, field, id, value, latlong)),
  groupChange: (prop, value, defaultFieldValues) =>
    dispatch(groupChange(prop, value, defaultFieldValues)),
  deleteFile: (isRequired, field, id, value) =>
    dispatch(deleteFile(isRequired, field, id, value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateGroup),
);
