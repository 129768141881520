import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ViewAttendee from './ViewAttendee';
import { getAttendeeDetails, closeModal } from './actions';

const mapStateToProps = state => ({
  ...state.staff.coursesAttendees.viewAttendee,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAttendeeDetails: attendeeId =>
    dispatch(
      getAttendeeDetails(
        ownProps.match.params.appname,
        ownProps.history,
        attendeeId,
      ),
    ),
  onRequestClose: () =>
    dispatch(closeModal(ownProps.match.params.appname, ownProps.history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewAttendee),
);
