import React from 'react';

const Appointedd = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 77.79 45.14"
  >
    <title>Appointedd component</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          class="cls-1"
          points="9.28 31.63 0 22.35 9.28 13.07 10.34 14.13 2.12 22.35 10.34 30.57 9.28 31.63"
        />
        <polygon
          class="cls-1"
          points="68.5 31.63 67.44 30.57 75.67 22.35 67.44 14.13 68.5 13.07 77.79 22.35 68.5 31.63"
        />
        <path
          class="cls-1"
          d="M40.81,33.9A16.29,16.29,0,1,1,57.1,17.61,16.31,16.31,0,0,1,40.81,33.9Zm0-31.08A14.79,14.79,0,1,0,55.6,17.61,14.81,14.81,0,0,0,40.81,2.82Z"
        />
        <path
          class="cls-1"
          d="M40.81,37.71a20.1,20.1,0,0,1-14-34.46l-2-2A.75.75,0,0,1,25.85.22l3.07,3.07-.58.53A18.6,18.6,0,1,0,54.41,30.3l.53-.57,4.32,4.32a.76.76,0,0,1-1.07,1.07L55,31.88A20.19,20.19,0,0,1,40.81,37.71Z"
        />
        <path
          class="cls-1"
          d="M48.11,19.31H39.74V7.78a.75.75,0,0,1,1.5,0v10h6.87a.75.75,0,0,1,0,1.5Z"
        />
        <path
          class="cls-1"
          d="M40.73,45.14a.74.74,0,0,1-.75-.75v-7a.75.75,0,0,1,1.5,0v7A.75.75,0,0,1,40.73,45.14Z"
        />
        <path
          class="cls-1"
          d="M47.73,45.14h-14a.75.75,0,0,1,0-1.5h14a.75.75,0,1,1,0,1.5Z"
        />
      </g>
    </g>
  </svg>
);

export default Appointedd;
