import { objectToArray } from '../../../../utils/array';

//format user personal information
//return [{col1:val1, col2:val2}]
//where col1 is column name and val1 is col1 value
export const formatPersonalData = (users, allGroups) => {
  const formatAllGroups = objectToArray(allGroups);
  return users.map(({ value }) => {
    const workForceGroups = value.workforceGroups
      ? Object.keys(value.workforceGroups)
      : [];
    const getWorkForceGroups = formatAllGroups.filter(item =>
      workForceGroups.includes(item.id),
    );

    return {
      'First name': value.firstName,
      'Last name': value.lastName,
      'Workforce Groups': getWorkForceGroups.map(i => i.value.name).join(', '),
      'Phone number': value.phoneNumber,
      Organization: value.organisation,
      Role: value.role,
      'Landline Number': value.landline,
      Gender: value.gender,
      'Date of Birth': value.dob ? new Date(value.dob) : null,
      Disability: value.disability,
      'Postal Address': value.postalAddress,
      'Post Code': value.postCode,
      'Local Authority': value.localAuthority,
      'Ethnic Group': value.ethnicGroup,
      Religion: value.religion,
      'Sexual Orientation': value.sexualOrientation,
      'Gender Reassignment': value.genderReassignment,
      'Maternity / Paternity Leave': value.leaveType,
      'Marriage / Civil Patnership': value.martialStatus,
      'Linkedin Address':
        value.socialLinks && value.socialLinks[0] ? value.socialLinks[0] : '',
      'Twitter Address':
        value.socialLinks && value.socialLinks[1] ? value.socialLinks[1] : '',
      'Website Address':
        value.socialLinks && value.socialLinks[2] ? value.socialLinks[2] : '',
      'Facebook Address':
        value.socialLinks && value.socialLinks[3] ? value.socialLinks[3] : '',
    };
  });
};
//Format all groups Data
// return [{name:"groupName": data:[{col1:val1, col2:val2}]}]
export const formatAllGroupsData = (allGroups, allGroupsData, allUsers) => {
  const formatAllGroups = objectToArray(allGroups);

  const data = Object.keys(allGroupsData).map(item => {
    const groupInfo = formatAllGroups.find(group => group.id === item);
    const groupName = groupInfo.value.name;
    const groupFields = objectToArray(groupInfo.value.fields);

    const result = Object.keys(allGroupsData[item]).map(groupData => {
      const formattedItem = objectToArray(allGroupsData[item][groupData]);

      const formattedGroupFields = groupFields.map((value, i) => {
        if (formattedItem[i]) {
          const prop = formattedItem[i].value.prop;
          const finalValue = formattedItem[i].value.value;
          let res;
          if (prop === 'Dropdown' && typeof finalValue === 'object') {
            res = finalValue.join(' , ');
          } else if (prop === 'Date' || prop === 'ExpiryDate') {
            res = new Date(finalValue);
          } else if (prop === 'File') {
            res = finalValue
              ? Array.isArray(finalValue)
                ? finalValue.map(f => f.preview).join(' , ')
                : Object.keys(finalValue)
                    .map(i => finalValue[i].preview)
                    .join(' , ')
              : '';
          } else {
            res = finalValue;
          }
          return {
            [`${value.value.name}`]: res,
          };
        }
      });
      const newFormattedGroupFields = Object.assign(
        {},
        ...formattedGroupFields,
      );
      return {
        Profile: allUsers[groupData]
          ? `${allUsers[groupData].firstName} ${allUsers[groupData].lastName}`
          : '',
        ...newFormattedGroupFields,
      };
    });
    return {
      name: groupName,
      data: result,
    };
  });
  return data;
};
//Format user groups Data
// return [{name:"groupName": data:[{col1:val1, col2:val2}]}]
export const formatGroupData = (allGroups, userGroups) => {
  const formatAllGroups = objectToArray(allGroups);
  const formatUserGroups = objectToArray(userGroups);
  const data = formatUserGroups.map(item => {
    const groupInfo = formatAllGroups.find(group => group.id === item.id);

    const groupName = groupInfo.value.name;
    const groupFields = objectToArray(groupInfo.value.fields);
    const formattedItem = objectToArray(item.value);

    const formattedUserGroups = groupFields.map((value, i) => {
      const prop = formattedItem[i].value.prop;
      const finalValue = formattedItem[i].value.value;
      let res;
      if (prop === 'Dropdown' && typeof finalValue === 'object') {
        res = finalValue.join(' , ');
      } else if (prop === 'Date' || prop === 'ExpiryDate') {
        res = new Date(finalValue);
      } else if (prop === 'File') {
        res = finalValue.map(f => f.preview).join(' , ');
      } else {
        res = finalValue;
      }
      return {
        [`${value.value.name}`]: res,
      };
    });

    return {
      name: groupName,
      data: [Object.assign({}, ...formattedUserGroups)],
    };
  });

  return data;
};
