import { connect } from 'react-redux';

import MemberBodies from './MemberBodies';
import {
  selectMemberBody,
  selectAllMemberBodies,
  deselectAllMemberBodies,
  deleteMemberBodies,
  filterMemberBodyData,
  getMemberBodies,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  pending: state.auth.memberBodies.data.pending,
  chunks: state.auth.memberBodies.data.chunks,
  filteredChunks: state.auth.memberBodies.data.filteredChunks,
  error: state.auth.memberBodies.data.error,
  items: state.auth.memberBodies.data.items,
  selected: state.auth.memberBodies.data.selected,
  selectAll: state.auth.memberBodies.data.selectAll,
  filter: state.auth.memberBodies.data.filter,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelect: key => dispatch(selectMemberBody(key)),
  onSelectAllMemberBodies: () => dispatch(selectAllMemberBodies()),
  onDeselectAllMemberBodies: () => dispatch(deselectAllMemberBodies()),
  onDeleteSelected: () =>
    dispatch(deleteMemberBodies(ownProps.match.params.appname)),
  onFilterMemberBodyData: filter => dispatch(filterMemberBodyData(filter)),
  getMemberBodies: () =>
    dispatch(getMemberBodies(ownProps.match.params.appname)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberBodies);
