import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import './styles.css';
import { FormattedNumber } from 'react-intl';
import { Trans, useTranslation } from 'react-i18next';
import Thing from '../../components/thing';
import Badge from '../../components/badge';
import './styles.css';
import {
  plansAppSumo,
  TIER_1_PLAN_ID,
  TIER_2_PLAN_ID,
  TIER_3_PLAN_ID,
  TIER_4_PLAN_ID,
  TIER_5_PLAN_ID,
  componentNewNames,
} from '../../../../plans';
import { getNextRenewalDate } from '../../../../utils/subscription';

const AppSumoBilling = ({
  billing,
  getAppSumoBilling,
  intl,
  createdAt,
  specialAddons,
  onCancelAddon,
  appname,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    getAppSumoBilling();
  }, []);

  const cancelSubscription = async (component, period, subscriptionId, id) => {
    await onCancelAddon(component, period, subscriptionId, id, appname);
  };

  const specialAddonWithSubscription = specialAddons.filter(
    item => item.subscription_id !== undefined,
  );
  const getDate = created => moment(created).format('DD/MM/YY');

  const getInvoiceLabel = invoice => {
    if (invoice.plan) {
      switch (invoice.plan) {
        case TIER_1_PLAN_ID:
          return plansAppSumo[TIER_1_PLAN_ID].title;
        case TIER_2_PLAN_ID:
          return plansAppSumo[TIER_2_PLAN_ID].title;
        case TIER_3_PLAN_ID:
          return plansAppSumo[TIER_3_PLAN_ID].title;
        case TIER_4_PLAN_ID:
          return plansAppSumo[TIER_4_PLAN_ID].title;
        case TIER_5_PLAN_ID:
          return plansAppSumo[TIER_5_PLAN_ID].title;
        default:
          return 'Error';
      }
    }
    return 'Charge';
  };

  return (
    <div className="billing">
      <div className="appsumo-invoice">
        <h3 className="header">
          <Trans>Invoice History</Trans>
        </h3>
        <Thing className="billing">
          <div className="plan-details">
            <span>
              <Trans>Plan</Trans>
            </span>
            <Badge label={getInvoiceLabel(billing)} />
          </div>
          <div className="plan-details">
            <span>
              <Trans>Price</Trans>
            </span>
            <span>
              {billing.amount}
              {/* <FormattedNumber
                maximumFractionDigits={2}
                format={intl.currency}
                currency={billing.currency ? billing.currency : intl.currency}
                value={getAmount(billing)}
              /> */}
            </span>
          </div>
          <div className="plan-details">
            <span>
              <Trans>Access</Trans>
            </span>
            <h5 className="title">
              <Trans>LIFETIME</Trans>
            </h5>
          </div>
          <div className="plan-details">
            <span>
              <Trans>Subscribed On</Trans>
            </span>
            <h5 className="title">{getDate(createdAt)}</h5>
          </div>
        </Thing>
      </div>
      {specialAddonWithSubscription.length > 0 && (
        <div className="appsumo-invoice">
          <h3 className="header">
            <Trans>Special Addons</Trans>
          </h3>
          {specialAddonWithSubscription.map(addon => {
            return (
              <Thing className="billing" key={addon.id}>
                <div className="plan-details">
                  <span>
                    <Trans>Component Name</Trans>
                  </span>
                  <h5 className="title">
                    {componentNewNames[addon.component]
                      ? componentNewNames[addon.component]
                      : `${addon.component}`}{' '}
                    <Trans>Component</Trans>
                  </h5>
                  <a
                    href={addon.invoiceDetails.invoice_pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: '#231fd1',
                      fontWeight: 500,
                      fontSize: '0.8rem',
                    }}
                  >
                    <Trans>Download Invoice</Trans>
                  </a>
                </div>
                <div className="plan-details">
                  <span>
                    <Trans>Price</Trans>
                  </span>
                  <span>
                    <FormattedNumber
                      maximumFractionDigits={2}
                      format={intl.currency}
                      currency={addon.currency.toUpperCase()}
                      value={addon.price}
                    />
                  </span>
                </div>
                <div className="plan-details">
                  <span>
                    <Trans>Access</Trans>
                  </span>
                  <h5 className="title">{t(addon.period)}</h5>
                </div>
                <div className="plan-details">
                  <span>
                    <Trans>Subscribed On</Trans>
                  </span>
                  <h5 className="title">{getDate(addon.subscription_date)}</h5>

                  <button
                    onClick={() =>
                      cancelSubscription(
                        addon.component,
                        addon.period,
                        addon.subscription_id,
                        addon.id,
                      )
                    }
                    rel="noopener noreferrer"
                    style={{
                      color: '#231fd1',
                      fontWeight: 500,
                      fontSize: '0.8rem',
                    }}
                  >
                    <Trans>Cancel</Trans>
                  </button>
                </div>
                <div className="plan-details">
                  <span>
                    <Trans>Next Renewal</Trans>
                  </span>
                  <h5 className="title">
                    {addon.hasActiveSubscription
                      ? getDate(
                          getNextRenewalDate(
                            addon.period === 'monthly' ? 'month' : 'year',
                            1,
                            addon.subscription_date,
                          ),
                        )
                      : '---'}
                  </h5>
                </div>
                <div className="plan-details">
                  <span>
                    <Trans>Cancelled On</Trans>
                  </span>
                  <h5 className="title">
                    {addon.cancelledSubscription && !addon.hasActiveSubscription
                      ? getDate(addon.dateCancelled)
                      : '---'}
                  </h5>
                </div>
              </Thing>
            );
          })}
        </div>
      )}
    </div>
  );
};

AppSumoBilling.propTypes = {
  billing: PropTypes.array,
  getAppSumoBilling: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    currency: PropTypes.string.isRequired,
  }).isRequired,
  specialAddons: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  onCancelAddon: PropTypes.func.isRequired,
  appname: PropTypes.string.isRequired,
};

AppSumoBilling.defaultProps = {
  billing: [],
};

export default AppSumoBilling;
