import React from 'react';

import './styles.css';

const Linkedin = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 59.02 56.4"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        style={{ fill: '#fff' }}
        d={`M59,34.58V56.4H46.37V36c0-5.11-1.83-8.6-6.41-8.6a6.92,6.92,0,0,0-6.49,4.63,8.66,8.66,0,0,0-.42,3.08V56.4H20.4s.17-34.49,
        0-38.06H33.05v5.39l-.08.13h.08v-.12a12.56,12.56,0,0,1,11.4-6.29C52.78,17.45,59,22.89,59,34.58ZM7.16,0C2.83,0,0,2.84,0,6.57s2.75,
        6.58,7,6.58h.08c4.41,0,7.16-2.92,7.16-6.58S11.49,0,7.16,0ZM.75,56.4H13.4V18.34H.75Zm0,0`}
      />
    </g>
  </svg>
);

export default Linkedin;
