import firebase from '../../../../../../../firebase';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';

export const onSubmit = (appname, dataId, value, type) => dispatch => {
  try {
    const dataRef = firebase
      .database()
      .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

    dataRef.child('subscriptionItems').set(value);

    dispatch(openSnackbar('Subscription plans successfully saved.'));
    return {
      type: 'SUBSCRIPTION_PLANS_FIELD_CHANGE',
      appname,
      dataId,
      value,
    };
  } catch (error) {
    console.log(error);
  }
};
export const designChange = (appname, key, type, value, prop) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${key}/design`);
  const attr = prop ? `${prop}/${type}` : type;

  dataRef.child(attr).set(value);

  return {
    type: 'SUBSCRIPTION_BUTTON_DESIGN_CHANGE',
    appname,
    key,
    designType: type,
    value,
  };
};
