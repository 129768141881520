import React, { useEffect } from 'react';
import Dialog from 'material-ui/Dialog';
import { Trans, useTranslation } from 'react-i18next';

import { FlatButton } from '../../../../app/components/buttons';
import { accelerate } from '../../../../plans/images';
import { DIARY_ADDON_PLAN } from '../../../../plans/constants';
import components from '../../../../plans/components';
import CreateComponentItem from '../../../editor/modules/build/modules/create/createComponent/CreateComponentItem';
import CustomDomainIcon from '../../../editor/components/icons/CustomDomain';
import './styles.css';

const UserGroups = props => {
  const {
    history,
    match,
    open,
    onRequestClose,
    plan,
    feature,
    addon_payload,
    subscriptionName,
    subscription,
    subscriptionDetails,
    getNextInvoiceAmount,
    subscriptionItem,
    accountId,
    appname,
    nextInvoiceAmount,
    addon,
  } = props;
  const { t } = useTranslation();

  const addon_plan =
    addon_payload && addon_payload['type'] === 'diary' ? 'Everything' : '';

  const addonComponent = addon
    ? components[addon].price *
      (subscriptionDetails.period === 'monthly' ? 1 : 12)
    : DIARY_ADDON_PLAN[subscriptionDetails.period];

  useEffect(() => {
    return onRequestClose;
  }, []);

  useEffect(() => {
    if (subscription && subscription.includes('diy') && open) {
      const s = subscriptionItem.items[appname];
      const subscriptionId = s.id;

      getNextInvoiceAmount(accountId, subscriptionId);
    }
  }, [subscription, open]);

  return (
    <Dialog
      open={open}
      onRequestClose={onRequestClose}
      autoScrollBodyContent
      style={{ textAlign: 'center' }}
      actionsContainerStyle={{ textAlign: 'center' }}
      contentClassName="customModal"
      paperClassName="customModalPaper"
      title={t('Upgrade your plan')}
    >
      <div
        className="ml-upgrade-dialog-content"
        // style={{
        //   height: `${
        //     subscription && subscription.includes('diy') ? '500px' : '300px'
        //   }`,
        // }}
      >
        <img
          width={24}
          src={accelerate}
          alt={t('Upgrade Plan')}
          style={{ position: 'relative', top: 1 }}
        />
        <p>
          <Trans>You need to upgrade your plan to</Trans>{' '}
          <Trans>use this</Trans> {feature} <Trans>feature.</Trans>
        </p>

        <FlatButton
          label={t('Upgrade')}
          onClick={() => history.push(`/${match.params.appname}/choose_plan`)}
          primary
        />
        {subscription && subscription.includes('diy') && (
          <>
            <p>
              <Trans>Or get the feature as an addon for</Trans> {addonComponent}{' '}
              GBP {subscriptionDetails.period}{' '}
              <Trans>to your current plan</Trans>.
            </p>
            {/* <FlatButton label="Buy Addon" onClick={() => null} primary /> */}
            {addon ? (
              <CreateComponentItem
                {...props}
                diaryUpgrade
                label={t(components[addon].name)}
                value={addon}
                text={t(components[addon].name)}
                svg={<CustomDomainIcon />}
                disabled
                width="100%"
                // nextInvoiceAmount={this.state.nextInvoiceAmount}
                nextInvoiceAmount={nextInvoiceAmount}
              />
            ) : (
              <CreateComponentItem
                diaryUpgrade
                {...props}
                label={t(`Diary`)}
                value={'Diary'}
                text={t(`diary platform`)}
                svg={<CustomDomainIcon />}
                disabled
                width="100%"
                // nextInvoiceAmount={this.state.nextInvoiceAmount}
                nextInvoiceAmount={nextInvoiceAmount}
              />
            )}
          </>
        )}
      </div>
    </Dialog>
  );
};

export default UserGroups;
