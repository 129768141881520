import firebase from '../../../firebase';
import { loadSubscription, selectApp } from '../appContext/actions';
import { loadContent, removeListeners } from '../../../modules/editor/actions';
import { resetEngageState } from '../../../modules/engage/modules/push/actions';
import { loadProfile } from '../../../modules/account/modules/auth/login/actions';
const changingApp = appname => ({
  type: 'CHANGING_APP',
  appname,
});

const appChangeSuccess = appname => ({
  type: 'APP_CHANGE_SUCCESS',
  appname,
});

const appChangeError = (appname, err) => ({
  type: 'APP_CHANGE_ERROR',
  appname,
  err,
});

export const toggleAppMenu = open => ({
  type: 'TOGGLE_APP_MENU',
  open,
});

export const toggleUserMenu = open => ({
  type: 'TOGGLE_USER_MENU',
  open,
});

export const changeApp = (appname, oldAppname) => dispatch => {
  const user = firebase.auth().currentUser;
  dispatch(changingApp(appname));
  return firebase
    .database()
    .ref(`users/${user.uid}`)
    .update({ lastVisited: appname })
    .then(() => dispatch(selectApp(appname)))
    .then(() => dispatch(resetEngageState()))
    .then(() => dispatch(removeListeners(oldAppname)))
    .then(() => dispatch(loadContent(appname)))
    .then(() => dispatch(appChangeSuccess(appname)))
    .then(() => dispatch(loadProfile()))
    .then(() => dispatch(loadSubscription(appname)))
    .catch(err => dispatch(appChangeError(appname, err)));
};

export const receivePublished = (appname, isPublished) => ({
  type: 'IS_PUBLISHED',
  appname,
  isPublished,
});

export const addIsPublishedListener = appname => dispatch => {
  const appRef = firebase.database().ref(`apps/${appname}`);

  appRef
    .child('published/meta_data')
    .on('value', snapshot =>
      dispatch(receivePublished(appname, snapshot.val() !== null)),
    );

  appRef
    .child('published/meta_data')
    .once('value')
    .then(snapshot =>
      dispatch(receivePublished(appname, snapshot.val() !== null)),
    );
};

export const removeIsPublishedListener = appname => {
  const appRef = firebase.database().ref(`apps/${appname}`);
  appRef.child('published/meta_data').off('value');

  return {
    type: 'REMOVE_IS_PUBLISHED_LISTENER',
    appname,
  };
};
