import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { Delete } from '../../components/icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Thumbnail = ({
  files,
  imageHandler,
  currentCropIndex,
  removeImageFromDraft,
}) => {
  const thumbs =
    files &&
    files.map((item, index) => {
      const className =
        currentCropIndex === index ? 'thumb thumbSelected' : 'thumb';
      return (
        <div className={className} key={item.file.name}>
          <div
            className="thumbButton"
            onClick={() => {
              removeImageFromDraft(index);
            }}
          >
            <IconButton aria-label="delete" color="secondary">
              <Delete iconStyle={{}} />
            </IconButton>
          </div>
          <div
            className="thumbInner pointer"
            onClick={() => {
              imageHandler(index, item.file.preview);
            }}
          >
            <LazyLoadImage
              alt=""
              height={100}
              src={item.file.preview}
              width={100}
            />
          </div>
        </div>
      );
    });
  return <aside className="thumbsContainer scroll-container">{thumbs}</aside>;
};

Thumbnail.propTypes = {
  files: PropTypes.array,
};

export default Thumbnail;
