import React from 'react';

const Email = () => (
  <svg
    enableBackground="new 0 0 128 128"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="64" cy="64" fill="#4B5F83" id="circle" r="64" />
    <g id="icon">
      <path
        d="M64.3,93.6c-17.4,0-30.6-12.2-30.6-29.4c0-17.3,13.4-29.9,30.9-29.9c16.3,0,29.7,11.2,29.7,27.1   c0,7.8-3.2,17.6-14,17.6c-4.1,0-7.2-1.5-8.7-4.4c-2.2,2.7-5.5,4.2-9.8,4.2c-8,0-14.3-5.7-14.3-14.8c0-9.3,6.4-14.9,14.3-14.9   c3.6,0,6.6,1.2,8.8,3.3v-2.7h8v20c0,2.2,1.7,3.3,3.6,3.3c4,0,5.8-6.1,5.8-11.5c0-13-10.5-21.6-23.5-21.6c-14,0-24.6,10.2-24.6,24   c0,13.8,10.6,23.6,24.2,23.6c6.1,0,12-1.9,17.3-6l3.2,5.1C79.4,91.1,72.1,93.6,64.3,93.6 M70.6,64.1c0-4.5-3.3-7.3-6.9-7.3   c-3.5,0-6.8,2.7-6.8,7.3s3.3,7.3,6.8,7.3C67.3,71.5,70.6,68.6,70.6,64.1z"
        fill="#FFFFFF"
        id="_x40_"
      />
    </g>
  </svg>
);

export default Email;
