import styled from 'styled-components';

const HiddenFileInput = styled.input.attrs({
  type: 'file',
  accept: 'image/jpeg,image/jpg,image/png,image/gif,video/mp4',
})`
  display: none;
`;

export default HiddenFileInput;
