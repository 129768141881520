import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const SelectableCardMenu = ({ children, image, subtitle, title, toggle }) => (
  <div className="selectable-card-menu">
    <h1 className="selectable-card-menu-title">{title}</h1>
    <p className="selectable-card-menu-subtitle">{subtitle}</p>
    {image && (
      <div className="selectable-card-menu-image-container">
        <img src={image} alt="sms component illustration" />
      </div>
    )}
    {toggle}
    <div className="selectable-card-container">{children}</div>
  </div>
);

SelectableCardMenu.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  toggle: PropTypes.node,
};

SelectableCardMenu.defaultProps = {
  image: null,
  toggle: null,
  subtitle: '',
};

export default SelectableCardMenu;
