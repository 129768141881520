import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { ButtonShapeRadioGroup, ButtonShapeRadio } from './ButtonShapeRadio';
import StaticInputLabel from '../../../../app/components/inputLabel';

const shapes = [0, 6, 32];

const ButtonShapeMenu = ({ value, disabled, className, onSelect }) => {
  const radioOptions = shapes.map(val => (
    <ButtonShapeRadio
      key={val}
      value={val}
      selected={value === val}
      onChange={() => onSelect(val)}
      disabled={disabled}
    />
  ));

  return (
    <div className={className}>
      <StaticInputLabel>
        <Trans>Button Shape</Trans>
      </StaticInputLabel>
      <ButtonShapeRadioGroup>{radioOptions}</ButtonShapeRadioGroup>
    </div>
  );
};

ButtonShapeMenu.propTypes = {
  value: PropTypes.oneOf(shapes).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

ButtonShapeMenu.defaultProps = {
  disabled: false,
};

export default styled(ButtonShapeMenu)`
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
`;
