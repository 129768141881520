import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import LinearProgress from 'material-ui/LinearProgress';
import { FlatButton } from '../../../app/components/buttons';

const StandardDialog = ({
  title,
  text,
  buttonLabel,
  cancelLabel,
  open,
  pending,
  error,
  handleClose,
  handleSubmit,
  router,
}) => {
  const actions = [
    <FlatButton
      label={cancelLabel}
      onClick={handleClose}
      disabled={pending}
      secondaryWhite
    />,
    <FlatButton
      label={buttonLabel}
      onClick={() => handleSubmit()}
      disabled={pending}
      primary
    />,
  ];

  return (
    <Dialog
      title={title}
      actions={actions}
      open={open}
      router={router}
      modal
      style={{ textAlign: 'center' }}
      actionsContainerStyle={{ textAlign: 'center' }}
      contentClassName="customModal"
      paperClassName="customModalPaper"
      disablePortal
      disableEnforceFocus
    >
      {!pending && <p>{text}</p>}
      {pending && <LinearProgress color="#261ed5" mode="indeterminate" />}
      {error && <p>{error}</p>}
    </Dialog>
  );
};

StandardDialog.propTypes = {
  cancelLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  error: PropTypes.string,
  open: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

StandardDialog.defaultProps = {
  cancelLabel: 'Cancel',
  error: null,
};

export default StandardDialog;
