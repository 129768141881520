import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import { openSnackbar } from '../../../../../app/modules/snackbar/actions';

import EditGroup from './EditGroup';

import {
  loadContent,
  loadGroup,
  updateGroup,
  formFieldChange,
  loadHideNav,
  loadHideSubscription,
  updateHideNavData,
  resetGroup,
  updateGroupData,
  updateVisibility,
  setUpdatedImageStatus,
  resetGroupOrder,
} from './actions';

import { deleteTempImage } from '../../../../../modules/editor/internals/imageUpload/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.auth.groups.editGroup,
  groups: state.auth.groups.data.chunks,
  items: state.auth.groups.data.items,
  groupImage: _get(state, 'auth.groups.editGroup.groupImage') || null,
  isUserSubscriptionEnabled: _get(
    state,
    'auth.groups.data.chunks.default.userSubscriptions',
    false,
  ),
  isStripeAccountAdded:
    _get(state, 'auth.groups.data.chunks.default.stripeApiKey', '').length >
      0 &&
    _get(state, 'auth.groups.data.chunks.default.stripeApiPublicKey', '')
      .length > 0,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteTempImage: appName => dispatch(deleteTempImage(appName)),
  setUpdatedImageStatus: value => dispatch(setUpdatedImageStatus(value)),
  loadContent: appName => dispatch(loadContent(appName)),
  loadHideNav: (appName, groupId) => dispatch(loadHideNav(appName, groupId)),
  loadHideSubscription: (appName, groupId) =>
    dispatch(loadHideSubscription(appName, groupId)),
  loadGroup: (appnme, group) => dispatch(loadGroup(appnme, group)),
  updateHideNavData: (appnme, payload, groupId) =>
    dispatch(updateHideNavData(appnme, payload, groupId)),
  updateVisibility: (appnme, payload, groupId) =>
    dispatch(updateVisibility(appnme, payload, groupId)),
  updateGroup: (appnme, group, payload) =>
    dispatch(updateGroup(appnme, group, payload)),
  updateGroupData: (appnme, group, payload) =>
    dispatch(updateGroupData(appnme, group, payload)),
  resetGroupOrder: (appnme, groups, orderNos) =>
    dispatch(resetGroupOrder(appnme, groups, orderNos)),
  formFieldChange: (prop, value) => dispatch(formFieldChange(prop, value)),
  onAddFeature: text =>
    dispatch({ type: 'EDIT_GROUP_FEATURE_ADDED', value: text }),
  onRemoveFeature: value =>
    dispatch({ type: 'EDIT_GROUP_REMOVE_FEATURE', value }),
  onUnmount: appname => dispatch(resetGroup(appname)),
  showWarning: message => dispatch(openSnackbar(message)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditGroup),
);
