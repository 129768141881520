import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from 'material-ui/MenuItem';
import { objectToArray, sortArray, downloadCSV } from 'utils';
import styled from 'styled-components';
import { Trans, withTranslation } from 'react-i18next';
import TableCheckbox from '../../components/tableCheckbox';
import SelectField from '../../../../app/components/selectField';
import ResultsTable from '../../../../app/components/resultsTable';
import TryAgain from '../../../../app/components/tryAgain';
import ActionMenu from '../../../../app/components/actionMenu';
import Header from '../../components/header';
import NewItem from './modules/create';
import EditItem from './modules/edit';
import { Empty as EmptyIcon } from '../../../../app/components/icons';
import { STAFF_ADMIN, HS_FEATURES_ENABLED_APPS } from '../../../../config';

import EventCalendarDisabledWrapper from '../eventCalendarDisabledWrapper';
import { CircularProgress } from '../../../../app/components/progress';
import themeStyles from '../../../../_export.scss';

const isCheckboxDisabled = (error, pending) => error && !pending;

let memberBodyFlag = false;

const ButtonWrapper = styled.button`
  border: 10px;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
  height: 40px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  text-align: center;
  color: ${props => `${props.color}`};
  padding: 10px 20px;
  font-weight: 600;
  font-size: 1rem;
`;

const LabelWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const RequiredText = styled.span`
  color: #ff0000;
  font-size: 2rem;
  display: inline-block;
  margin-left: 3px;
  margin-bottom: -10px;
`;

const DefaultLabel = () => (
  <LabelWrapper>
    <span>
      <Trans>Select Event Organizer</Trans>{' '}
    </span>
    <RequiredText>*</RequiredText>
  </LabelWrapper>
);
class Events extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    chunks: PropTypes.object,
    getEvents: PropTypes.func.isRequired,
    onGetEventFields: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func.isRequired,
    onSelectAllEvents: PropTypes.func.isRequired,
    onDeselectAllEvents: PropTypes.func.isRequired,
    selectMemberBody: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    onFilterEventData: PropTypes.func.isRequired,
    onAddEventCalendarComponent: PropTypes.func,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    filter: PropTypes.string,
    profile: PropTypes.object.isRequired,
    fields: PropTypes.object,
    memberbody: PropTypes.string.isRequired,
    memberBodies: PropTypes.object,
    componentsPending: PropTypes.bool,
    eventComponentEnabled: PropTypes.bool,
    role: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: '',
    chunks: {},
    filteredChunks: {},
    items: [],
    query: '',
    selected: [],
    selectAll: false,
    fields: {},
    memberbody: '',
    memberBodies: {},
  };

  componentDidMount() {
    memberBodyFlag =
      HS_FEATURES_ENABLED_APPS.indexOf(this.props.match.params.appname) > -1;
    this.props.selectMemberBody();
    this.props.getEvents();
    this.props.onGetEventFields();
  }

  // componentWillReceiveProps(nextProps) {
  //   if (
  //     this.props.location &&
  //     this.props.location.pathname !== nextProps.location.pathname &&
  //     nextProps.location.pathname ===
  //       `/${this.props.match.params.appname}/staff/events`
  //   ) {
  //     this.props.selectMemberBody();
  //     this.props.getEvents();
  //   }
  // }

  getSelectAllCheckBox = () => {
    const {
      error,
      pending,
      chunks,
      selected,
      selectAll,
      onSelectAllEvents,
      onDeselectAllEvents,
      onDeleteSelected,
      t,
    } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllEvents() : onDeselectAllEvents()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDownloadSelected={() => {
            downloadCSV(this.getCsvData(selected, chunks), 'events');
          }}
          onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          text={t('events')}
        />
      </div>
    );
  };

  getTitle = (selected, events) => (
    <span>
      <Trans>Events</Trans>:{' '}
      {selected.length
        ? `${selected.length} / ${Object.keys(events).length}`
        : Object.keys(events).length}
    </span>
  );

  getCsvData = (selected, chunks) => {
    const { fields: rawFieldsData } = this.props;
    const csvColumns = objectToArray(rawFieldsData)
      .filter(i => i.value.isGridVisible)
      .map(field => field.value.label);
    const head = `${csvColumns.join(',')}\n`;

    const fields = objectToArray(rawFieldsData)
      .filter(i => i.value.isGridVisible)
      .map(field => field.id);

    const body = selected
      .map(key =>
        fields.map(field =>
          field === 'status'
            ? `${chunks[key][field]}\n`
            : `${chunks[key][field]}`,
        ),
      )
      .join('');

    const table = head + body;
    return table;
  };

  getColumns = type => {
    const {
      fields,
      onSelect,
      onDeleteSelected,
      chunks,
      memberbody,
      match,
      profile,
      history,
      items,
      onDuplicateSelected,
      t,
    } = this.props;

    const fieldsArr = objectToArray(fields).filter(i => i.value.isGridVisible);

    if (type === 'columns') {
      let dateValues = '';
      const columns = fieldsArr.map((field, index) => ({
        id: field.id,
        header:
          field.value.label === 'Active' ? t('Status') : field.value.label,
        classNames: index === 0 ? 'col-xs-3' : 'col-xs-2',
        containerElement: ({ value }) => {
          if (field.value.label === 'Event Dates') {
            dateValues = value.split(' ');
          }
          if (field.id === 'status') {
            if (value) {
              const today = new Date();
              const endDate = new Date(dateValues[2] || dateValues[0]);

              return today.getTime() > endDate.getTime() &&
                today.toDateString() !== endDate.toDateString() ? (
                <Chip color="error" size="small" label={t('Inactive')} />
              ) : (
                <Chip color="primary" size="small" label={t('Active')} />
              );
            }
            return (
              <Chip color="secondary" size="small" label={t('Inactive')} />
            );
          }
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span
                style={
                  field.value.label === 'Title'
                    ? {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        maxWidth: '200px',
                      }
                    : field.value.label === 'Event Dates'
                    ? { textAlign: 'center' }
                    : null
                }
              >
                {value}
              </span>
            </div>
          );
        },
      }));

      columns.push({
        id: 'selected',
        header: this.getSelectAllCheckBox(),
        classNames: `col-xs-1 ${items.length < 4 ? 'fix-left' : 'selected'}`,
        containerElement: ({ value, rowId }) => {
          return (
            rowId !== 'default' && (
              <>
                <TableCheckbox value={value} onSelect={() => onSelect(rowId)} />
                <ActionMenu
                  selected={[[rowId]]}
                  onDownloadSelected={() => {
                    downloadCSV(this.getCsvData([rowId], chunks), 'events');
                  }}
                  onDeleteSelected={() => onDeleteSelected([rowId], chunks)}
                  onDuplicateSelected={() => onDuplicateSelected(rowId)}
                  text={t('event')}
                  onEditSelected={() =>
                    history.push(
                      profile.role === STAFF_ADMIN
                        ? `/${match.params.appname}/staff/events/${memberbody}/${rowId}`
                        : `/${match.params.appname}/members/events/${memberbody}/${rowId}`,
                    )
                  }
                />
              </>
            )
          );
        },
      });
      return columns;
    }
    return fieldsArr.map(i => i.id);
  };

  rightSection = () => {
    const {
      memberBodies,
      selectMemberBody,
      getEvents,
      memberbody,
      onFilterEventData,
      history,
      match,
      t,
    } = this.props;
    return Object.keys(memberBodies).length > 0 ? (
      <SelectField
        style={{ width: '200px', marginTop: '-20px' }}
        key="memberbody-filter"
        options={sortArray(Object.entries(memberBodies), 'name').map(option => (
          <MenuItem
            key={option[0]}
            value={option[0]}
            primaryText={option[1].name}
          />
        ))}
        label={memberBodyFlag ? t('Select Member Body') : <DefaultLabel />}
        value={memberbody}
        onChange={(i, payload) => {
          selectMemberBody(payload);

          getEvents();
          onFilterEventData('', this.getColumns());
        }}
      />
    ) : (
      <ButtonWrapper
        color={themeStyles.primaryColor}
        onClick={() =>
          history.push(`/${match.params.appname}/members/member-bodies`)
        }
      >
        + <Trans>Add Event Organizer</Trans>
      </ButtonWrapper>
    );
  };

  render() {
    const {
      pending,
      items,
      error,
      getEvents,
      chunks,
      filteredChunks,
      onFilterEventData,
      selected,
      history,
      match,
      profile,
      onSelectAllEvents,
      onDeselectAllEvents,
      onDeleteSelected,
      memberbody,
      memberBodies,
      selectMemberBody,
      eventComponentEnabled,
      componentsPending,
      onAddEventCalendarComponent,
      role,
      t,
    } = this.props;
    console.log(items);
    console.log(filteredChunks);
    console.log(this.props);

    const isMemberBodies =
      profile.role === STAFF_ADMIN ? profile.memberBodies : true;

    const eventInstructions = (
      <p className="instructions-container">
        <p className="instructions-section-1">
          <Trans>
            Click on the '+' button to add a new event, complete the event
            fields and choose the preferred visibility settings.
          </Trans>
        </p>
        <p className="instructions-section-2">
          <span>
            <Trans>
              Please note: Events will be displayed on the app page where the
              Event Calendar was added.
            </Trans>
          </span>{' '}
          <span>
            <Trans>
              To add or delete fields e.g event name, date, etc. navigate to
              Build -> Pages -> Page -> Event Calendar component.
            </Trans>
          </span>
        </p>
          
      </p>
    );
    const body = error ? (
      <TryAgain
        message={t('Oops, something went wrong...')}
        onRetry={() => getEvents()}
      />
    ) : (
      <ResultsTable
        items={items}
        chunks={filteredChunks}
        loading={pending}
        pending={pending}
        error={Object.keys(filteredChunks).length === 0 ? 'yes' : null}
        emptyMessage={
          memberbody
            ? t('Looks like no events have been created yet')
            : `${t('Please select')} ${
                memberBodyFlag ? t('memberbody') : t('event organizer')
              } ${t('from above')}`
        }
        optionalMessage={
          !memberBodyFlag && profile.role === STAFF_ADMIN
            ? null
            : eventInstructions
        }
        onRowTouchTap={event =>
          history.push(
            profile.role === STAFF_ADMIN
              ? `/${match.params.appname}/staff/events/${memberbody}/${event}`
              : `/${match.params.appname}/members/events/${memberbody}/${event}`,
          )
        }
        columns={this.getColumns('columns')}
      />
    );

    const content = isMemberBodies ? (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <Header
          {...this.props}
          title={this.getTitle(selected, chunks)}
          onChange={val => onFilterEventData(val, this.getColumns())}
          // onSelectAllResponses={onSelectAllEvents}
          // onDeselectAllResponses={onDeselectAllEvents}
          // actionMenu={
          //   <ActionMenu
          //     selected={selected}
          //     onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          //     text="events"
          //   />
          // }
          rightAfterSearch={
            profile.role !== STAFF_ADMIN ? this.rightSection() : null
          }
        />
        {body}
        <NewItem />
        <EditItem />
      </section>
    ) : (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <div className="empty-state">
          <EmptyIcon />
          <Typography
            variant="h5"
            gutterBottom
            style={{ color: '#261ed5', marginTop: '17px' }}
          >
            <WarningIcon style={{ verticalAlign: 'middle' }} />{' '}
            {memberBodyFlag
              ? t('No member bodies assigned to you yet')
              : t('No events assigned to you yet')}
          </Typography>
        </div>
      </section>
    );

    return !componentsPending ? (
      eventComponentEnabled ? (
        content
      ) : (
        <EventCalendarDisabledWrapper
          history={history}
          onAddEventCalendarComponent={onAddEventCalendarComponent}
          appname={match.params.appname}
          role={role}
        >
          {content}
        </EventCalendarDisabledWrapper>
      )
    ) : (
      <CircularProgress />
    );
  }
}

export default withTranslation()(Events);
