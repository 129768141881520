import _pick from 'lodash/pick';
import i18n from 'i18next';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';
import firebase from '../../../../firebase';

export const REQUEST_USERS = 'REQUEST_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const NO_USERS_EXIST = 'NO_USERS_EXIST';
export const RECEIVE_GROUPS = 'RECEIVE_GROUPS';
export const NO_GROUPS_EXIST = 'NO_GROUPS_EXIST';
export const FILTER_USER_DATA = 'FILTER_USER_DATA';
export const SELECT_USER = 'SELECT_USER';
export const SELECT_ALL_USERS = 'SELECT_ALL_USERS';
export const DESELECT_ALL_USERS = 'DESELECT_ALL_USERS';
export const DELETE_SELECTED = 'DELETE_SELECTED';
export const ACTION_ERROR = 'ACTION_ERROR';
export const OPEN_USER_GROUPS_DIALOG = 'OPEN_USER_GROUPS_DIALOG';
export const CLOSE_USER_GROUPS_DIALOG = 'CLOSE_USER_GROUPS_DIALOG';
export const TOGGLE_SELECTED_GROUP = 'TOGGLE_SELECTED_GROUP';
export const SAVE_USER_GROUP_CHANGES = 'SAVE_USER_GROUP_CHANGES';
export const SAVE_USER_GROUP_CHANGES_SUCCESSFULLY =
  'SAVE_USER_GROUP_CHANGES_SUCCESSFULLY';
export const SAVE_USER_GROUP_CHANGES_ERROR = 'SAVE_USER_GROUP_CHANGES_ERROR';
export const REMOVE_USER_GROUP = 'REMOVE_USER_GROUP';
export const REMOVE_USER_GROUP_SUCCESSFULLY = 'REMOVE_USER_GROUP_SUCCESSFULLY';
export const REMOVE_USER_GROUP_ERROR = 'REMOVE_USER_GROUP_ERROR';
export const SELECT_ALL_GROUPS = 'SELECT_ALL_GROUPS';
export const DESELECT_ALL_GROUPS = 'DESELECT_ALL_GROUPS';
export const APPROVED_SCOTTISH_APP_USER = 'APPROVED_SCOTTISH_APP_USER';
export const REQUEST_PAGE_COUNT_ANALYTICS = 'REQUEST_PAGE_COUNT_ANALYTICS';
export const RECEIVE_PAGE_COUNT_ANALYTICS = 'RECEIVE_PAGE_COUNT_ANALYTICS';
export const NO_PAGE_COUNT_ANALYTICS_EXIST = 'NO_PAGE_COUNT_ANALYTICS_EXIST';
export const FILTER_USERS_DATA = 'FILTER_USERS_DATA';
export const VIEW_ALL_USERS = 'VIEW_ALL_USERS';
export const VIEW_BANNED_USERS = 'VIEW_BANNED_USERS';
export const RESET_USERS_DATA = 'RESET_USERS_DATA';
export const EMAIL_VERIFIED = 'EMAIL_VERIFIED';
export const IS_SUBMITTING = 'IS_SUBMITTING';
export const TOGGLE_DIARY_PERMISSION = 'TOGGLE_DIARY_PERMISSION';

export const setIsSubmitting = data => ({
  type: IS_SUBMITTING,
  payload: data,
});

export const openUserGroupDialog = () => dispatch =>
  dispatch({
    type: OPEN_USER_GROUPS_DIALOG,
  });

export const closeUserGroupDialog = () => ({
  type: CLOSE_USER_GROUPS_DIALOG,
});

const requestUsers = appname => ({
  type: REQUEST_USERS,
  appname,
});

const requestPageCountAnalytics = (appname, uid) => ({
  type: REQUEST_USERS,
  appname,
  uid,
});

export const getPageCountAnalytics = (appname, uid) => async dispatch => {
  dispatch(requestPageCountAnalytics(appname, uid));

  const analyticsRef = firebase
    .database()
    .ref(`analytics/${appname}/user_page_views/${uid}`);

  const snapshot = await analyticsRef.once('value');

  if (!snapshot.exists()) {
    dispatch({
      type: NO_PAGE_COUNT_ANALYTICS_EXIST,
    });
    return;
  }

  dispatch({
    type: RECEIVE_PAGE_COUNT_ANALYTICS,
    value: snapshot.val(),
  });
};

export const getUsers = appname => dispatch => {
  dispatch(requestUsers(appname));

  firebase
    .database()
    .ref(`app_users/${appname}`)
    .on('value', snap => {
      if (!snap.exists()) {
        dispatch({
          type: NO_USERS_EXIST,
        });
      } else {
        const users = {};
        snap.forEach(childSnapshot => {
          if (
            childSnapshot.hasChild('email') &&
            childSnapshot.hasChild('firstName') &&
            childSnapshot.hasChild('lastName') &&
            childSnapshot.hasChild('created_at')
          ) {
            users[childSnapshot.key] = childSnapshot.val();
          }
        });
        dispatch({
          type: RECEIVE_USERS,
          value: users,
        });
      }
    });
};

export const filterUserData = filter => dispatch =>
  dispatch({
    type: FILTER_USER_DATA,
    payload: filter,
  });

export const filterData = (filteredChunks, sortedItems) => dispatch =>
  dispatch({
    type: FILTER_USERS_DATA,
    payload: {
      filteredChunks,
      sortedItems,
    },
  });

export const selectUser = key => ({
  type: SELECT_USER,
  key,
});

export const selectAllUsers = () => ({ type: SELECT_ALL_USERS });

export const deselectAllUsers = () => ({ type: DESELECT_ALL_USERS });

export const deleteSelectedSuccess = (appname, selected) => dispatch =>
  dispatch({
    type: DELETE_SELECTED,
    appname,
    selected,
  });

export const reportActionError = (
  error,
  appname,
  selected,
  action,
) => dispatch =>
  dispatch({ type: ACTION_ERROR, error, appname, selected, action });

const deleteUser = (appname, email) =>
  new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;

    return user.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_MEMBER_API}/member`, {
        method: 'delete',
        mode: 'cors',
        body: JSON.stringify({
          appname,
          email,
          accessToken: token,
          requestOrigin: 'platform',
        }),
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            return resolve(res);
          }

          throw new Error(
            i18n.t('Oops, something went wrong. Please try again.'),
          );
        })
        .catch(reject);
    });
  });

export const deleteSelected = (appname, selected, chunks) => dispatch => {
  Object.values(_pick(chunks, selected)).forEach(user => {
    deleteUser(appname, user.email)
      .then(data => {
        dispatch(deleteSelectedSuccess(appname, selected, data));
        dispatch(getUsers(appname));
      })
      .catch(error => {
        dispatch(reportActionError(error));
      });
  });
};

export const toggleSelectedGroup = key => dispatch => {
  dispatch({
    type: TOGGLE_SELECTED_GROUP,
    key,
  });
};

export const approveScottishAppUser = (
  appname,
  uid,
  selectedUser,
  groups,
  groupSelected,
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(setIsSubmitting(true));
    const user = firebase.auth().currentUser;

    return user.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      return fetch(
        `${process.env.REACT_APP_MEMBER_API}/scottish-curling/approve-user`,
        {
          method: 'post',
          mode: 'cors',
          body: JSON.stringify({
            appname,
            uid,
            selectedUser,
            groups,
            groupSelected,
          }),
          headers,
        },
      )
        .then(res => {
          if (res.status === 200) {
            dispatch({ type: APPROVED_SCOTTISH_APP_USER });
            dispatch(
              openSnackbar(
                i18n.t(
                  'The user has been approved by admin. The user will now receive an email request to verify their email address.',
                ),
              ),
            );
            dispatch(setIsSubmitting(false));

            return resolve(res);
          }
          return dispatch(
            openSnackbar(
              i18n.t('Oops, something went wrong. Please try again later.'),
            ),
          );
        })
        .catch(reject);
    });
  });

export const saveUserGroupChanges = (
  appname,
  usersSelected,
  groupsSelected,
  updateNotNeeded,
  chunks,
  groups,
) => dispatch => {
  if (!updateNotNeeded) {
    dispatch({ type: SAVE_USER_GROUP_CHANGES });

    const users = {};
    usersSelected.forEach(user => {
      users[user] = chunks[user];
    });

    firebase
      .database()
      .ref(`queues/update_user_group/tasks`)
      .push({
        groupsSelected,
        appname,
        users,
        groups,
      })
      .then(() => {
        dispatch({ type: SAVE_USER_GROUP_CHANGES_SUCCESSFULLY });
        setTimeout(() => {
          dispatch(openSnackbar(i18n.t('Profile updated.')));
        });
      }, 6000)
      .catch(error => {
        dispatch({ type: SAVE_USER_GROUP_CHANGES_ERROR, error });
      });
  }
  dispatch({ type: CLOSE_USER_GROUPS_DIALOG });
  dispatch(openSnackbar(i18n.t('Please wait. Update in progress...')));
};

export const verifyUserEmail = (appname, userId) => dispatch => {
  firebase
    .database()
    .ref(`/app_users/${appname}/${userId}`)
    .update({
      emailVerified: true,
    })
    .then(() => {
      dispatch({
        type: EMAIL_VERIFIED,
        payload: i18n.t('Email Successfully Verified.'),
      });
      setTimeout(() => {
        dispatch({
          type: EMAIL_VERIFIED,
          payload: null,
        });
      }, 3000);
    })
    .catch(error => {
      dispatch({
        type: EMAIL_VERIFIED,
        payload: i18n.t('Unable to verify user.'),
      });
      setTimeout(() => {
        dispatch({
          type: EMAIL_VERIFIED,
          payload: null,
        });
      }, 3000);
    });

  dispatch({ type: CLOSE_USER_GROUPS_DIALOG });
};

export const selectAllGroups = () => dispatch => {
  dispatch({ type: SELECT_ALL_GROUPS });
};
export const deselectAllGroups = () => dispatch => {
  dispatch({ type: DESELECT_ALL_GROUPS });
};

export const viewAllUsers = () => dispatch =>
  dispatch({ type: VIEW_ALL_USERS });

export const viewBannedUsers = () => dispatch =>
  dispatch({ type: VIEW_BANNED_USERS });

export const resetUsersData = () => dispatch =>
  dispatch({ type: RESET_USERS_DATA });

export const toogleUserDiaryPermission = (
  appname,
  userId,
  value,
) => dispatch => {
  firebase
    .database()
    .ref(`/app_users/${appname}/${userId}`)
    .update({
      diaryEnabled: value,
    })
    .then(() => {
      dispatch({
        type: TOGGLE_DIARY_PERMISSION,
        payload: i18n.t('Permission Changed Successfully.'),
      });
      setTimeout(() => {
        dispatch({
          type: EMAIL_VERIFIED,
          payload: null,
        });
      }, 3000);
    })
    .catch(error => {
      dispatch({
        type: EMAIL_VERIFIED,
        payload: i18n.t('Unable to change permission for user.'),
      });
      setTimeout(() => {
        dispatch({
          type: EMAIL_VERIFIED,
          payload: null,
        });
      }, 3000);
    });

  dispatch({ type: CLOSE_USER_GROUPS_DIALOG });
};
