import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { TableContainer, Checkbox } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import Toggle from '../../../../app/components/toggle';
import SectionHeader from '../../../editor/components/sectionHeader';

import styles from './groupsTable.module.scss';

const StyledTableCell = withStyles(theme => ({
  head: {
    background: '#EAEEFF',
    color: 'black',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Raleway, sans-serif',
    height: 73,
    textAlign: 'center',
  },
  body: {
    height: '80px',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Raleway, sans-serif',
    textAlign: 'center',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'white',
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableContainer: {
    maxHeight: 200,
    borderRadius: 15,
    boxShadow: 'none',
  },
});

export default function GroupsTable(props) {
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [filteredIds, setFilteredIds] = useState({});
  const [toggled, setToggled] = useState(false);
  const { t } = useTranslation();

  const classes = useStyles();

  const handleToggle = (e, value) => {
    if (toggled === false) {
      props.onSelectedFilterChange('groups');
    }
    setToggled(!toggled);
  };
  useEffect(() => {
    if (toggled === true) {
      props.onEnableGroupsFilter();
    } else if (toggled === false) {
      props.onDisableGroupsFilter();
    }
  }, [toggled]);

  useEffect(() => {
    if (props.selectedFilter !== 'groups') {
      props.onDisableGroupsFilter();
      setToggled(false);
    }
  }, [props.selectedFilter]);

  const handleCheckboxChange = (e, group, groups) => {
    if (e.target.checked) {
      setFilteredGroups([...filteredGroups, group]);

      for (const user in groups.users) {
        if (!filteredIds[props.users[user].oneSignalID]) {
          filteredIds[props.users[user].oneSignalID] = 1;
        } else {
          filteredIds[props.users[user].oneSignalID]++;
        }
      }
      setFilteredIds({ ...filteredIds });
    } else {
      setFilteredGroups(filteredGroups.filter(g => g !== group));

      for (const user in groups.users) {
        if (filteredIds[props.users[user].oneSignalID]) {
          filteredIds[props.users[user].oneSignalID]--;
        }
        setFilteredIds({ ...filteredIds });
      }
    }
  };

  useEffect(() => {
    let keys = [];

    if (props.groupsFiltered) {
      keys = Object.keys(filteredIds).filter(key => filteredIds[key] >= 1);
      keys = keys.filter(element => element !== 'undefined');
    }

    props.onPlayerIdChange(keys);
    props.onFilteredGroupsListChange(filteredGroups);
  }, [filteredGroups, props.groupsFiltered]);
  return (
    <>
      <div className={styles.section_header}>
        <SectionHeader
          title={t('Filter By Groups')}
          actions={<Toggle onToggle={e => handleToggle(e)} toggled={toggled} />}
          tooltip={t('Choose which groups will recieve the notification')}
          // borderTop={borderTop}
        />
      </div>

      <TableContainer
        className={`overflow-scroll-container ${!props.groupsFiltered &&
          styles.disabled} ${styles.table_container}  ${
          classes.tableContainer
        }`}
        component={Paper}
      >
        <Table aria-label="customized table" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Trans>Send Notification</Trans>
              </StyledTableCell>
              <StyledTableCell>
                <Trans>Group Name</Trans>
              </StyledTableCell>
              <StyledTableCell>
                <Trans>Users</Trans>
              </StyledTableCell>
              <StyledTableCell>
                <Trans>Created At</Trans>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.groupsItems &&
              props.groups &&
              props.groupsItems.map(row => (
                <StyledTableRow key={props.groups[row].name}>
                  <StyledTableCell>
                    <Checkbox
                      disabled={!props.groupsFiltered}
                      onChange={e =>
                        handleCheckboxChange(e, row, props.groups[row])
                      }
                    />
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    {props.groups[row].name || '-'}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {props.groups[row].users
                      ? Object.keys(props.groups[row].users).length
                      : 0}
                  </StyledTableCell>
                  <StyledTableCell>
                    {moment(props.groups[row].date).format('DD/MM/YYYY') || '-'}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
