import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
.st0{fill:#FFFFFF;}
.st1{fill:none;stroke:#D0D2D3;stroke-width:5.1639;stroke-miterlimit:6.8852;}
.st2{fill:#D0D2D3;}
.st3{fill:none;stroke:#F1F1F2;stroke-width:1.7213;stroke-miterlimit:6.8852;}
.st4{fill:none;stroke:#DBDBDB;stroke-width:1.7213;stroke-miterlimit:6.8852;}
.st5{fill:#F1F1F2;}
.st6{fill:none;stroke:#DBDBDB;stroke-width:2.5819;stroke-miterlimit:6.8855;}
.st7{fill:none;stroke:#DBDBDB;stroke-width:2.5819;stroke-miterlimit:6.8852;}
.st8{fill:none;stroke:#D0D2D3;stroke-width:5.1639;stroke-miterlimit:17.2129;}
.st9{fill:#E5E5E5;}
.st10{fill:#FFFFFF;stroke:#CBCBCB;stroke-miterlimit:10;}
.st11{fill:#F6F6F6;}
.st12{fill:#D1D2D2;}
`;

const VerticalSliderCaption = () => (
  <Svg
    className="layout layout-header-large"
    width="750.000000pt"
    height="1334.000000pt"
    viewBox="0 0 750 1334"
    preserveAspectRatio="xMidYMid meet"
  >
    <g id="Layer_2_1_">
	<g id="Layer_1-2">
		<path class="st0" d="M58.3,210.6h-2.4c-1.9,0-3.4-1.5-3.4-3.4V157c0-1.9,1.5-3.4,3.4-3.4h2.4"/>
		<path class="st1" d="M58.3,210.6h-2.4c-1.9,0-3.4-1.5-3.4-3.4V157c0-1.9,1.5-3.4,3.4-3.4h2.4"/>
		<path class="st0" d="M58.3,375.8h-2.1c-1.9,0-3.4-1.5-3.4-3.4v-94c0-1.9,1.5-3.4,3.4-3.4h2.1"/>
		<path class="st1" d="M58.3,375.8h-2.1c-1.9,0-3.4-1.5-3.4-3.4v-94c0-1.9,1.5-3.4,3.4-3.4h2.1"/>
		<path class="st0" d="M691.4,274.9h1.4c2.9,0,5.2,2.3,5.2,5.2v90.5c0,2.9-2.3,5.2-5.2,5.2h-1.4"/>
		<path class="st1" d="M691.4,274.9h1.4c2.9,0,5.2,2.3,5.2,5.2v90.5c0,2.9-2.3,5.2-5.2,5.2h-1.4"/>
		<path class="st0" d="M58.4,500.1H53c-1.9,0,0-1.5,0-3.4v-94c0-1.9-1.9-3.4,0-3.4h5.5"/>
		<path class="st1" d="M58.4,500.1H53c-1.9,0,0-1.5,0-3.4v-94c0-1.9-1.9-3.4,0-3.4h5.5"/>
		<path class="st0" d="M147.6,1335.4c-47.9,0-86.7-38.9-86.8-86.8V93.3c0-47.9,38.9-86.7,86.8-86.8h454.6
			c47.9,0,86.7,38.9,86.8,86.8v1155.3c0,47.9-38.9,86.7-86.8,86.8L147.6,1335.4L147.6,1335.4z"/>
		<path class="st2" d="M602,9.2c46.4,0.1,84.1,37.7,84.2,84.2v1155.3c0,46.5-37.7,84.2-84.2,84.2H147.6
			c-46.4-0.1-84.1-37.7-84.2-84.2V93.3c0-46.5,37.7-84.2,84.2-84.2L602,9.2 M602,4H147.6C98.3,4,58.3,44,58.3,93.3v1155.3
			c0,49.3,40,89.3,89.4,89.3h454.6c49.3,0,89.3-40,89.3-89.3V93.3C691.4,44,651.4,4.1,602,4z"/>
		<path class="st3" d="M670.2,1179.6c0,2.9-2.3,5.2-5.2,5.2H83.7c-2.9,0-5.2-2.3-5.2-5.2V146.9c0.1-2.8,2.3-5.1,5.2-5.2H665
			c2.9,0,5.2,2.3,5.2,5.2V1179.6L670.2,1179.6z"/>
		<path class="st4" d="M670.2,1179.6c0,2.9-2.3,5.2-5.2,5.2H83.7c-2.9,0-5.2-2.3-5.2-5.2V146.9c0.1-2.8,2.3-5.1,5.2-5.2H665
			c2.9,0,5.2,2.3,5.2,5.2V1179.6L670.2,1179.6z"/>
		
			<ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 149.6091 327.7163)" class="st5" cx="267.4" cy="75.9" rx="11.4" ry="11.4"/>
		
			<ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 149.6091 327.7163)" class="st6" cx="267.4" cy="75.9" rx="11.4" ry="11.4"/>
		<circle class="st5" cx="373.4" cy="30.5" r="6.9"/>
		<circle class="st7" cx="373.4" cy="30.5" r="6.9"/>
		<circle class="st8" cx="373.4" cy="1262.3" r="51.8"/>
		<path class="st9" d="M424.2,76.3c0,2.9-2.3,5.2-5.2,5.2h-92.9c-2.9,0-5.2-2.3-5.2-5.2v-0.2c0-2.9,2.3-5.2,5.2-5.2H419
			C421.9,71.1,424.2,73.4,424.2,76.3z"/>
		<path class="st4" d="M424.2,76.3c0,2.9-2.3,5.2-5.2,5.2h-92.9c-2.9,0-5.2-2.3-5.2-5.2v-0.2c0-2.9,2.3-5.2,5.2-5.2H419
			C421.9,71.1,424.2,73.4,424.2,76.3z"/>
	</g>
</g>
<g>
	<g id="change3_2_">
		<rect x="319.6" y="324.8" class="st10" width="285.1" height="249.8"/>
	</g>
	<g id="change2_2_">
		<path class="st11" d="M387.1,432c41.8,0,48.3,89.6,65.2,89.6s24.4-53.5,48.3-53.5c32.9,0,49.3,96.9,104.5,96.9c0.1,0,0.1,0,0.2,0
			V325.1H319.6v222.1C329.5,511.2,354.8,432,387.1,432z M542.2,359c22.5,0,40.7,18.2,40.7,40.7c0,22.5-18.2,40.7-40.7,40.7
			c-22.5,0-40.6-18.2-40.6-40.7C501.5,377.2,519.8,359,542.2,359z"/>
	</g>
	<g id="change1_2_">
		<path class="st12" d="M605.2,573.9v-9.1c-0.1,0-0.1,0-0.2,0c-55.2,0-71.6-96.9-104.5-96.9c-23.9,0-31.4,53.5-48.3,53.5
			c-16.9,0-23.5-89.6-65.2-89.6c-32.3,0-57.6,79.3-67.4,115.3v26.7H605.2L605.2,573.9z"/>
		<circle class="st12" cx="542.2" cy="399.6" r="40.7"/>
	</g>
</g>
<g>
	<g id="change3_3_">
		<rect x="319.6" y="777.6" class="st10" width="285.1" height="249.8"/>
	</g>
	<g id="change2_3_">
		<path class="st11" d="M387.1,884.8c41.8,0,48.3,89.6,65.2,89.6s24.4-53.5,48.3-53.5c32.9,0,49.3,96.9,104.5,96.9
			c0.1,0,0.1,0,0.2,0V777.9H319.6V1000C329.5,964,354.8,884.8,387.1,884.8z M542.2,811.8c22.5,0,40.7,18.2,40.7,40.7
			c0,22.5-18.2,40.7-40.7,40.7c-22.5,0-40.6-18.2-40.6-40.7C501.5,829.9,519.8,811.8,542.2,811.8z"/>
	</g>
	<g id="change1_3_">
		<path class="st12" d="M605.2,1026.7v-9.1c-0.1,0-0.1,0-0.2,0c-55.2,0-71.6-96.9-104.5-96.9c-23.9,0-31.4,53.5-48.3,53.5
			c-16.9,0-23.5-89.6-65.2-89.6c-32.3,0-57.6,79.3-67.4,115.3v26.7H605.2L605.2,1026.7z"/>
		<circle class="st12" cx="542.2" cy="852.4" r="40.7"/>
	</g>
</g>
<g>
	<g id="change3_1_">
		<rect x="262.1" y="501.6" class="st10" width="400" height="350.5"/>
	</g>
	<g id="change2_1_">
		<path class="st11" d="M356.7,652c58.6,0,67.8,125.7,91.5,125.7s34.2-75.1,67.7-75.1c46.1,0,69.1,135.9,146.6,135.9
			c0.1,0,0.2,0,0.3,0V502.1H262.1v311.6C275.9,763.1,311.4,652,356.7,652z M574.4,549.6c31.5,0,57.1,25.5,57.1,57.1
			c0,31.5-25.5,57.1-57.1,57.1c-31.5,0-57-25.5-57-57.1C517.3,575.1,542.9,549.6,574.4,549.6z"/>
	</g>
	<g id="change1_1_">
		<path class="st12" d="M662.8,851.1v-12.7c-0.1,0-0.2,0-0.3,0c-77.5,0-100.5-135.9-146.6-135.9c-33.5,0-44,75.1-67.7,75.1
			c-23.7,0-32.9-125.7-91.5-125.7c-45.3,0-80.8,111.2-94.6,161.8v37.4H662.8L662.8,851.1z"/>
		<circle class="st12" cx="574.4" cy="606.6" r="57.1"/>
	</g>
</g>
<g>
	<g id="change3_4_">
		<rect x="92.6" y="153.1" class="st10" width="131.2" height="114.9"/>
	</g>
	<g id="change2_4_">
		<path class="st11" d="M123.7,202.5c19.2,0,22.2,41.2,30,41.2c7.8,0,11.2-24.6,22.2-24.6c15.1,0,22.7,44.6,48.1,44.6
			c0,0,0.1,0,0.1,0V153.3H92.6v102.2C97.2,238.9,108.8,202.5,123.7,202.5z M195,168.9c10.3,0,18.7,8.4,18.7,18.7
			c0,10.3-8.4,18.7-18.7,18.7c-10.3,0-18.7-8.4-18.7-18.7C176.3,177.2,184.7,168.9,195,168.9z"/>
	</g>
	<g id="change1_4_">
		<path class="st12" d="M224,267.7v-4.2c0,0-0.1,0-0.1,0c-25.4,0-33-44.6-48.1-44.6c-11,0-14.4,24.6-22.2,24.6s-10.8-41.2-30-41.2
			c-14.9,0-26.5,36.5-31,53.1v12.3H224L224,267.7z"/>
		<circle class="st12" cx="195" cy="187.6" r="18.7"/>
	</g>
</g>
<g>
	<g id="change3_5_">
		<rect x="92.6" y="306.8" class="st10" width="131.2" height="114.9"/>
	</g>
	<g id="change2_5_">
		<path class="st11" d="M123.7,356.2c19.2,0,22.2,41.2,30,41.2c7.8,0,11.2-24.6,22.2-24.6c15.1,0,22.7,44.6,48.1,44.6
			c0,0,0.1,0,0.1,0V307H92.6v102.2C97.2,392.6,108.8,356.2,123.7,356.2z M195,322.6c10.3,0,18.7,8.4,18.7,18.7
			c0,10.3-8.4,18.7-18.7,18.7c-10.3,0-18.7-8.4-18.7-18.7C176.3,330.9,184.7,322.6,195,322.6z"/>
	</g>
	<g id="change1_5_">
		<path class="st12" d="M224,421.4v-4.2c0,0-0.1,0-0.1,0c-25.4,0-33-44.6-48.1-44.6c-11,0-14.4,24.6-22.2,24.6s-10.8-41.2-30-41.2
			c-14.9,0-26.5,36.5-31,53.1v12.3H224L224,421.4z"/>
		<circle class="st12" cx="195" cy="341.3" r="18.7"/>
	</g>
</g>
<g>
	<g id="change3_6_">
		<rect x="92.5" y="460.6" class="st10" width="131.2" height="114.9"/>
	</g>
	<g id="change2_6_">
		<path class="st11" d="M123.5,509.9c19.2,0,22.2,41.2,30,41.2c7.8,0,11.2-24.6,22.2-24.6c15.1,0,22.7,44.6,48.1,44.6
			c0,0,0.1,0,0.1,0V460.7H92.5v102.2C97,546.3,108.7,509.9,123.5,509.9z M194.9,476.3c10.3,0,18.7,8.4,18.7,18.7
			c0,10.3-8.4,18.7-18.7,18.7c-10.3,0-18.7-8.4-18.7-18.7C176.2,484.7,184.6,476.3,194.9,476.3z"/>
	</g>
	<g id="change1_6_">
		<path class="st12" d="M223.9,575.2V571c0,0-0.1,0-0.1,0c-25.4,0-33-44.6-48.1-44.6c-11,0-14.4,24.6-22.2,24.6s-10.8-41.2-30-41.2
			c-14.9,0-26.5,36.5-31,53.1v12.3H223.9L223.9,575.2z"/>
		<circle class="st12" cx="194.9" cy="495" r="18.7"/>
	</g>
</g>
<g>
	<g id="change3_7_">
		<rect x="92.6" y="614.3" class="st10" width="131.2" height="114.9"/>
	</g>
	<g id="change2_7_">
		<path class="st11" d="M123.7,663.6c19.2,0,22.2,41.2,30,41.2s11.2-24.6,22.2-24.6c15.1,0,22.7,44.6,48.1,44.6c0,0,0.1,0,0.1,0
			V614.4H92.6v102.2C97.2,700,108.8,663.6,123.7,663.6z M195,630c10.3,0,18.7,8.4,18.7,18.7c0,10.3-8.4,18.7-18.7,18.7
			c-10.3,0-18.7-8.4-18.7-18.7C176.3,638.4,184.7,630,195,630z"/>
	</g>
	<g id="change1_7_">
		<path class="st12" d="M224,728.9v-4.2c0,0-0.1,0-0.1,0c-25.4,0-33-44.6-48.1-44.6c-11,0-14.4,24.6-22.2,24.6
			c-7.8,0-10.8-41.2-30-41.2c-14.9,0-26.5,36.5-31,53.1v12.3H224L224,728.9z"/>
		<circle class="st12" cx="195" cy="648.7" r="18.7"/>
	</g>
</g>
<g>
	<g id="change3_8_">
		<rect x="92.6" y="768" class="st10" width="131.2" height="114.9"/>
	</g>
	<g id="change2_8_">
		<path class="st11" d="M123.7,817.3c19.2,0,22.2,41.2,30,41.2c7.8,0,11.2-24.6,22.2-24.6c15.1,0,22.7,44.6,48.1,44.6
			c0,0,0.1,0,0.1,0V768.1H92.6v102.2C97.2,853.7,108.8,817.3,123.7,817.3z M195,783.7c10.3,0,18.7,8.4,18.7,18.7
			c0,10.3-8.4,18.7-18.7,18.7c-10.3,0-18.7-8.4-18.7-18.7C176.3,792.1,184.7,783.7,195,783.7z"/>
	</g>
	<g id="change1_8_">
		<path class="st12" d="M224,882.6v-4.2c0,0-0.1,0-0.1,0c-25.4,0-33-44.6-48.1-44.6c-11,0-14.4,24.6-22.2,24.6s-10.8-41.2-30-41.2
			c-14.9,0-26.5,36.5-31,53.1v12.3H224L224,882.6z"/>
		<circle class="st12" cx="195" cy="802.4" r="18.7"/>
	</g>
</g>
<g>
	<g id="change3_9_">
		<rect x="92.4" y="921.7" class="st10" width="131.2" height="114.9"/>
	</g>
	<g id="change2_9_">
		<path class="st11" d="M123.4,971c19.2,0,22.2,41.2,30,41.2s11.2-24.6,22.2-24.6c15.1,0,22.7,44.6,48.1,44.6c0,0,0.1,0,0.1,0V921.8
			H92.4V1024C96.9,1007.4,108.6,971,123.4,971z M194.8,937.4c10.3,0,18.7,8.4,18.7,18.7c0,10.3-8.4,18.7-18.7,18.7
			c-10.3,0-18.7-8.4-18.7-18.7C176.1,945.8,184.5,937.4,194.8,937.4z"/>
	</g>
	<g id="change1_9_">
		<path class="st12" d="M223.8,1036.3v-4.2c0,0-0.1,0-0.1,0c-25.4,0-33-44.6-48.1-44.6c-11,0-14.4,24.6-22.2,24.6
			c-7.8,0-10.8-41.2-30-41.2c-14.9,0-26.5,36.5-31,53.1v12.3H223.8L223.8,1036.3z"/>
		<circle class="st12" cx="194.8" cy="956.1" r="18.7"/>
	</g>
</g>
<g>
	<g id="change3_10_">
		<rect x="92.3" y="1062.7" class="st10" width="131.2" height="114.9"/>
	</g>
	<g id="change2_10_">
		<path class="st11" d="M123.3,1112c19.2,0,22.2,41.2,30,41.2s11.2-24.6,22.2-24.6c15.1,0,22.7,44.6,48.1,44.6c0,0,0.1,0,0.1,0
			v-110.3H92.3V1165C96.8,1148.4,108.5,1112,123.3,1112z M194.7,1078.4c10.3,0,18.7,8.4,18.7,18.7c0,10.3-8.4,18.7-18.7,18.7
			c-10.3,0-18.7-8.4-18.7-18.7C176,1086.8,184.4,1078.4,194.7,1078.4z"/>
	</g>
	<g id="change1_10_">
		<path class="st12" d="M223.7,1177.3v-4.2c0,0-0.1,0-0.1,0c-25.4,0-33-44.6-48.1-44.6c-11,0-14.4,24.6-22.2,24.6
			c-7.8,0-10.8-41.2-30-41.2c-14.9,0-26.5,36.5-31,53.1v12.3H223.7L223.7,1177.3z"/>
		<circle class="st12" cx="194.7" cy="1097.1" r="18.7"/>
	</g>
</g>
  </Svg>
);

export default VerticalSliderCaption;
