import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
// Material Imports
import AddIcon from 'material-ui/svg-icons/content/add';

import { requiresUpgrade } from './helpers/requiresUpgrade';

import PageHeader from '../../app/components/pageHeader';
import { CTAButton, FloatingActionButton } from '../../app/components/buttons';
import Marketplace from './modules/marketplace/';
import Features from './modules/features';
import BookingFeatures from './modules/bookings/modules/features';
import Settings from './modules/settings';
import Inventory from './modules/inventory';
import Drivers from './modules/drivers';
import Orders from './modules/orders';
import PrivateRoute from '../../app/components/privateRoute';
import AccountsDropdown from '../editor/internals/accountsDropdown';

import Appointments from './modules/bookings/modules/appointments';
import Staff from './modules/bookings/modules/staff';
import BookingSettings from './modules/bookings/modules/settings';
import AppointmentBookings from './modules/bookings/modules/booking';
import BookingDisabledWrapper from './modules/bookings/components/bookingDisabledWrapper';
import BookingMissingStaffWrapper from './modules/bookings/components/bookingMissingStaffWrapper';
import EcommerceIntro from './Intro';
import './styles.css';
import firebase from '../../firebase';
import { size } from 'lodash';

import { AppointmentProvider } from './modules/bookings/modules/appointments/context/AppointmentContext';
import { StaffProvider } from './modules/bookings/modules/staff/context/StaffContext';

class Ecommerce extends Component {
  static propTypes = {
    marketplaceEnabled: PropTypes.bool.isRequired,
    addInventoryDialogOpen: PropTypes.bool.isRequired,
    isImageUploaded: PropTypes.bool.isRequired,
    onAddInventoryDialogOpen: PropTypes.func.isRequired,
    onImageUploaded: PropTypes.func.isRequired,
    isAppAdmin: PropTypes.bool,
  };

  state = {
    staffExists: null,
  };

  checkStaffExists = async () => {
    const appname = this.props.appname;
    await firebase
      .database()
      .ref(`bookings/${appname}/staff`)
      .on('value', snap => {
        const staffExists = snap.exists() && size(snap.val()) > 0;
        this.setState({
          staffExists,
        });
      });
  };
  componentDidMount() {
    this.props.loadContent();
    this.props.getAuth();
    this.checkStaffExists();
  }

  componentWillUnmount() {
    const appname = this.props.appname;

    this.props.stopGetAuth();
    firebase
      .database()
      .ref(`bookings/${appname}/staff`)
      .off('value');
  }
  render() {
    const { t } = this.props;
    return (
      <AppointmentProvider>
        <StaffProvider>
          <div className="flex-container sidebar-extended background-texture">
            <PageHeader
              title={t('Business tools')}
              userSettings={<AccountsDropdown />}
              actions={
                <div style={{ display: 'flex', paddingRight: '10px' }}>
                  <Route
                    path="/:appname/ecommerce/marketplace/categories/:categoryID"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={this.props.onAddField}
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path="/:appname/ecommerce/marketplace/categories"
                    exact
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={this.props.onAddFilter}
                          />
                        </div>
                      </div>
                    )}
                  />

                  <Route
                    path="/:appname/ecommerce/inventory"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col">
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={() =>
                              this.props.onAddInventoryDialogOpen(true)
                            }
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path="/:appname/ecommerce/drivers"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col">
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={() =>
                              this.props.history.push({
                                pathname: `/${this.props.match.params.appname}/distribute/contacts`,
                                cameFromDrivers: true,
                                type: 'create',
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path="/:appname/ecommerce/marketplace/:page/:pageItem"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col">
                          <CTAButton
                            label={t('Back')}
                            onClick={() => this.props.history.goBack()}
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path="/:appname/ecommerce/appointments"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col">
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={() =>
                              this.props.onAddAppointmentDialogOpen(true)
                            }
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path="/:appname/ecommerce/bookings/staff"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col">
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={() =>
                              this.props.onAddStaffMemberDialog(true)
                            }
                          />
                        </div>
                      </div>
                    )}
                  />
                </div>
              }
            />
            {!this.props.foodOrderEnabled &&
              !this.props.bookingEnabled &&
              !this.props.marketplaceEnabled && <EcommerceIntro />}
            <Switch>
              {!this.props.foodOrderEnabled && (
                <Redirect
                  exact
                  from={`/${this.props.match.params.appname}/ecommerce/inventory`}
                  to={`/${this.props.match.params.appname}/ecommerce`}
                />
              )}

              {!this.props.foodOrderEnabled && (
                <Redirect
                  exact
                  from={`/${this.props.match.params.appname}/ecommerce/orders`}
                  to={`/${this.props.match.params.appname}/ecommerce`}
                />
              )}

              {!this.props.foodOrderEnabled && (
                <Redirect
                  exact
                  from={`/${this.props.match.params.appname}/ecommerce/drivers`}
                  to={`/${this.props.match.params.appname}/ecommerce`}
                />
              )}

              {!this.props.foodOrderEnabled && (
                <Redirect
                  exact
                  from={`/${this.props.match.params.appname}/ecommerce/settings`}
                  to={`/${this.props.match.params.appname}/ecommerce`}
                />
              )}

              {this.props.foodOrderEnabled && (
                <Redirect
                  exact
                  from={`/${this.props.match.params.appname}/ecommerce`}
                  to={`/${this.props.match.params.appname}/ecommerce/inventory`}
                />
              )}

              {/* {!this.props.bookingEnabled && (
            <Redirect
              exact
              from={`/${this.props.match.params.appname}/ecommerce/bookings/features`}
              to={`/${this.props.match.params.appname}/ecommerce`}
            />
          )} */}

              {!this.props.bookingEnabled && (
                <Redirect
                  exact
                  from={`/${this.props.match.params.appname}/ecommerce/bookings/list`}
                  to={`/${this.props.match.params.appname}/ecommerce`}
                />
              )}

              {!this.props.bookingEnabled && (
                <Redirect
                  exact
                  from={`/${this.props.match.params.appname}/ecommerce/appointments`}
                  to={`/${this.props.match.params.appname}/ecommerce`}
                />
              )}

              {!this.props.bookingEnabled && (
                <Redirect
                  exact
                  from={`/${this.props.match.params.appname}/ecommerce/bookings/staff`}
                  to={`/${this.props.match.params.appname}/ecommerce`}
                />
              )}

              {!this.props.bookingEnabled && (
                <Redirect
                  exact
                  from={`/${this.props.match.params.appname}/ecommerce/bookings/settings`}
                  to={`/${this.props.match.params.appname}/ecommerce`}
                />
              )}

              {this.props.bookingEnabled && (
                <Redirect
                  exact
                  from={`/${this.props.match.params.appname}/ecommerce`}
                  to={`/${this.props.match.params.appname}/ecommerce/bookings/features`}
                />
              )}

              {!this.props.marketplaceEnabled && (
                <Redirect
                  exact
                  from={`/${this.props.match.params.appname}/ecommerce/marketplace/*`}
                  to={`/${this.props.match.params.appname}/ecommerce`}
                />
              )}

          <PrivateRoute
            {...this.props}
            path="/:appname/ecommerce/features"
            component={Features}
            heading={`${t('Upgrade to')} ${
              this.props.isAppSumoAccount ? 'Tier-2' : 'Anything Plan'
            } ${t('or above.')}`}
            requiresUpgrade={requiresUpgrade(this.props.plan)}
            content={t('You need to upgrade your plan to use business tools.')}
          />

          <PrivateRoute
            {...this.props}
            path="/:appname/ecommerce/inventory"
            component={Inventory}
            heading={`${t('Upgrade to')} ${
              this.props.isAppSumoAccount ? 'Tier-2' : 'Anything Plan'
            } ${t('or above.')}`}
            requiresUpgrade={requiresUpgrade(this.props.plan)}
            content={t('You need to upgrade your plan to use business tools.')}
          />

          <PrivateRoute
            {...this.props}
            path="/:appname/ecommerce/orders"
            component={Orders}
            heading={`${t('Upgrade to')} ${
              this.props.isAppSumoAccount ? 'Tier-2' : 'Anything Plan'
            } ${t('or above.')}`}
            requiresUpgrade={requiresUpgrade(this.props.plan)}
            content={t('You need to upgrade your plan to use business tools.')}
          />

          <PrivateRoute
            {...this.props}
            path="/:appname/ecommerce/drivers"
            component={Drivers}
            heading={`${t('Upgrade to')} ${
              this.props.isAppSumoAccount ? 'Tier-2' : 'Anything Plan'
            } ${t('or above.')}`}
            requiresUpgrade={requiresUpgrade(this.props.plan)}
            content={t('You need to upgrade your plan to use business tools.')}
          />

          <PrivateRoute
            {...this.props}
            path="/:appname/ecommerce/settings"
            component={Settings}
            heading={`${t('Upgrade to')} ${
              this.props.isAppSumoAccount ? 'Tier-2' : 'Anything Plan'
            } ${t('or above.')}`}
            requiresUpgrade={requiresUpgrade(this.props.plan)}
            content={t('You need to upgrade your plan to use this feature.')}
          />

          <PrivateRoute
            {...this.props}
            path="/:appname/ecommerce/bookings/features"
            component={() =>
              !this.props.memberLoginEnabled ? (
                <BookingDisabledWrapper
                  history={this.props.history}
                  match={this.props.match}
                  enableMemberLogin={this.props.enableMemberLogin}
                >
                  <BookingFeatures {...this.props} />
                </BookingDisabledWrapper>
              ) : (
                <BookingFeatures {...this.props} />
              )
            }
            heading={`${t('Upgrade to')} ${
              this.props.isAppSumoAccount ? 'Tier-2' : 'Anything Plan'
            } ${t('or above.')}`}
            requiresUpgrade={requiresUpgrade(this.props.plan)}
            content={t('You need to upgrade your plan to use this feature.')}
          />

          <PrivateRoute
            {...this.props}
            path="/:appname/ecommerce/appointments"
            component={() =>
              !this.props.memberLoginEnabled ? (
                <BookingDisabledWrapper
                  history={this.props.history}
                  enableMemberLogin={this.props.enableMemberLogin}
                >
                  <Appointments {...this.props} />
                </BookingDisabledWrapper>
              ) : !this.state.staffExists ? (
                <BookingMissingStaffWrapper
                  history={this.props.history}
                  match={this.props.match}
                >
                  <Appointments {...this.props} />
                </BookingMissingStaffWrapper>
              ) : (
                <Appointments {...this.props} />
              )
            }
            heading={`${t('Upgrade to')} ${
              this.props.isAppSumoAccount ? 'Tier-2' : 'Anything Plan'
            } ${t('or above.')}`}
            requiresUpgrade={requiresUpgrade(this.props.plan)}
            content={t('You need to upgrade your plan to use this feature.')}
          />
          <PrivateRoute
            {...this.props}
            path="/:appname/ecommerce/bookings/list"
            component={() =>
              !this.props.memberLoginEnabled ? (
                <BookingDisabledWrapper
                  history={this.props.history}
                  enableMemberLogin={this.props.enableMemberLogin}
                >
                  <AppointmentBookings {...this.props} />
                </BookingDisabledWrapper>
              ) : (
                <AppointmentBookings {...this.props} />
              )
            }
            heading={`${t('Upgrade to')} ${
              this.props.isAppSumoAccount ? 'Tier-2' : 'Anything Plan'
            } ${t('or above.')}`}
            requiresUpgrade={requiresUpgrade(this.props.plan)}
            content={t('You need to upgrade your plan to use this feature.')}
          />
          <PrivateRoute
            {...this.props}
            path="/:appname/ecommerce/bookings/staff"
            component={() =>
              !this.props.memberLoginEnabled ? (
                <BookingDisabledWrapper
                  history={this.props.history}
                  enableMemberLogin={this.props.enableMemberLogin}
                >
                  <Staff {...this.props} />
                </BookingDisabledWrapper>
              ) : (
                <Staff {...this.props} />
              )
            }
            heading={`${t('Upgrade to')} ${
              this.props.isAppSumoAccount ? 'Tier-2' : 'Anything Plan'
            } ${t('or above.')}`}
            requiresUpgrade={requiresUpgrade(this.props.plan)}
            content={t('You need to upgrade your plan to use this feature.')}
          />
          <PrivateRoute
            {...this.props}
            path="/:appname/ecommerce/bookings/settings"
            component={() =>
              !this.props.memberLoginEnabled ? (
                <BookingDisabledWrapper
                  history={this.props.history}
                  enableMemberLogin={this.props.enableMemberLogin}
                >
                  <BookingSettings {...this.props} />
                </BookingDisabledWrapper>
              ) : (
                <BookingSettings {...this.props} />
              )
            }
            heading={`${t('Upgrade to')} ${
              this.props.isAppSumoAccount ? 'Tier-2' : 'Anything Plan'
            } ${t('or above.')}`}
            requiresUpgrade={requiresUpgrade(this.props.plan)}
            content={t('You need to upgrade your plan to use this feature.')}
          />

          <PrivateRoute
            {...this.props}
            path="/:appname/ecommerce/marketplace"
            component={Marketplace}
            requiresUpgrade={requiresUpgrade(this.props.plan)}
            content={t(
              'You need to upgrade to the everything plan to use the Share via Email feature.',
            )}
          />
        </Switch>
      </div>
      </StaffProvider>
      </AppointmentProvider>
    );
  }
}

export default withTranslation()(Ecommerce);
