import {
  ADD_STAFF_DIALOG,
  STAFF_MEMBER_CHANGE,
  IMAGE_UPLOAD_SUCCESS,
  RESET_STAFF_DATA,
} from './types';

const initialState = {
  addStaffDialogOpen: false,
  staffMember: {},
  isImageUploaded: false,
};

const staff = (state = initialState, action) => {
  switch (action.type) {
    case ADD_STAFF_DIALOG:
      return {
        ...state,
        addStaffDialogOpen: action.payload,
      };
    case STAFF_MEMBER_CHANGE:
      return {
        ...state,
        staffMember: action.payload,
      };

    case IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        isImageUploaded: true,
      };

    case RESET_STAFF_DATA:
      return initialState;
    default:
      return state;
  }
};

export default staff;
