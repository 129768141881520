import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Intro from './Intro';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isAppSumoUser:
    (state.account.organisation.appsumo_id &&
      state.account.organisation.appsumo_id.length > 0) ||
    false,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});

export default withRouter(connect(mapStateToProps)(Intro));
