import { connect } from 'react-redux';
import _get from 'lodash/get';

import AppNavigationMenu from './AppNavigationMenu';
import {
  updateAppMenuBarDesign,
  updateAppMenuLayout,
  toggleShowShareMenu,
  updateTextAlign,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ..._get(
    state,
    `editor.design.${ownProps.match.params.appname}.components.Navigation`,
  ),
  skin: _get(state, `editor.design.${ownProps.match.params.appname}.skin`),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onPropChange: (attr, barDef) => color =>
    dispatch(
      updateAppMenuBarDesign(
        ownProps.match.params.appname,
        attr,
        color.rgb,
        barDef,
      ),
    ),
  onLayoutChange: layout =>
    dispatch(updateAppMenuLayout(ownProps.match.params.appname, layout)),
  onToggleShowShareMenu: layout =>
    dispatch(toggleShowShareMenu(ownProps.match.params.appname, layout)),
  onChangeTextAlign: align =>
    dispatch(updateTextAlign(ownProps.match.params.appname, align)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppNavigationMenu);
