import firebase from '../../../../../../../firebase';
// eslint-disable-next-line
export const fieldChange = (appname, dataId, field, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child(field).set(value);

  return {
    type: 'COUNTDOWN_FIELD_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

export const designChange = (appname, dataId, field, value) => {
  const dataRef =
    field === 'layout'
      ? firebase.database().ref(`apps/${appname}/draft/always_data/${dataId}`)
      : firebase
          .database()
          .ref(`apps/${appname}/draft/always_data/${dataId}/design`);

  dataRef.child(field).set(value);

  return {
    type: 'BOX_FIELD_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

const receiveGaTrackingId = gaTrackingId => ({
  type: 'RECEIVE_GA_TRACKING_ID',
  data: gaTrackingId,
});

export const loadGoogleAnalytics = appname => async dispatch => {
  const gaRef = firebase.database().ref(`apps/${appname}/gaTrackingId`);
  try {
    const snapshot = await gaRef.once('value');
    const gaTrackingId = await snapshot.val();
    return dispatch(receiveGaTrackingId(gaTrackingId));
  } catch (err) {
    console.error(err);
  }
};
