import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppMenu from './AppMenu';
import { deleteApp, getAppsDetails, deleteAppFromSubAdmins } from './actions';
import { changeApp } from '../../../../internals/accountsDropdown/actions';
import { getSubscriptions } from '../../../../../account/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  userIsLegacy: state.account.organisation.legacy_flag,
  apps: state.account.organisation.apps,
  appAdmins: state.auth.appAdmins.data.items,
  appsData: state.editor.build.appMenu.appsData,
  loading: state.editor.build.appMenu.loading,
  lastVisitedApp: state.account.profile.lastVisited,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAppsDetails: apps => dispatch(getAppsDetails(apps)),
  onAppChange: (newApp, lastVisitedApp) =>
    dispatch(changeApp(newApp, lastVisitedApp)),
  onDeleteApp: (appAdmins, appname) => dispatch(deleteApp(appAdmins, appname)),
  getSubscriptions: () => dispatch(getSubscriptions()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppMenu),
);
