import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { fetchAppLogo } from '../../modules/appLogo/action';
import AppSidebar from './AppSidebar';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  integration: state.account.organisation.integration,
  subscription:
    state.account.subscriptions.items[ownProps.match.params.appname],
  appname: ownProps.match.params.appname,
  role: state.account.auth.profile.role,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
  isSubAdminWithMulipleApps: state.account.auth.profile.admin_appnames
    ? Object.keys(state.account.auth.profile.admin_appnames).length > 1
    : false,
  appLogo: state.app.appPreference.appLogo,
  sectionsAccess: state.account.profile.sectionsAccess,
});

export default withRouter(connect(mapStateToProps)(AppSidebar));
