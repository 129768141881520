import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const CardChip = ({ label, showBorder, highlightOnHover, showBoldLabel }) => (
  <div
    className={`card-chip ${showBorder ? '' : 'no-border'} ${
      highlightOnHover ? '' : 'no-highlight-on-hover'
    } ${showBoldLabel ? 'bold-label' : ''}`}
  >
    {label}
  </div>
);

CardChip.propTypes = {
  label: PropTypes.string,
  showBorder: PropTypes.bool,
  highlightOnHover: PropTypes.bool,
  showBoldLabel: PropTypes.bool,
};

CardChip.defaultProps = {
  label: '',
  showBorder: true,
  highlightOnHover: true,
  showBoldLabel: false,
};

export default CardChip;
