import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcon from 'material-ui/svg-icons/content/add';
import { formatDate, downloadCSV, sortArray } from 'utils';
import { FloatingActionButton } from '../../../../../../app/components/buttons';
import ResultsTable from '../../../../../../app/components/resultsTable';
import TableCheckbox from '../../../../../memberLogin/components/tableCheckbox';
import TryAgain from '../../../../../../app/components/tryAgain';
import Header from '../../../../../memberLogin/components/header';
import ActionMenu from '../../../../../../app/components/actionMenu';
import NewAdmin from './newAdmin';
import EditAdmin from './editAdmin';
import themeStyles from '../../../../../../_export.scss';
import { Trans, withTranslation } from 'react-i18next';

const isCheckboxDisabled = (error, pending) => error && !pending;

class Admins extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    chunks: PropTypes.object,
    getAppAdmins: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func.isRequired,
    onSelectAllAdmins: PropTypes.func.isRequired,
    onDeselectAllAdmins: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    onFilterAdminData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    filter: PropTypes.string,
  };

  static defaultProps = {
    error: null,
    filter: null,
    chunks: {},
    filteredChunks: {},
    items: [],
    query: '',
    selected: [],
    selectAll: false,
  };

  state = {
    sortedItems: this.props.items,
    dateSortArrowUp: true,
  };

  componentDidMount() {
    this.sortByDate();
    // this.sortByName();
  }

  componentDidUpdate(prevProps) {
    let sortedItems = [];
    if (prevProps.filteredChunks !== this.props.filteredChunks) {
      if (this.state.dateSortArrowUp !== null) {
        sortedItems = sortArray(
          Object.entries(this.props.filteredChunks),
          'created_at',
          this.state.dateSortArrowUp,
        );
      }

      sortedItems = sortedItems.map(entity => entity[0]);
      this.setState({ sortedItems });
    }
  }

  getSelectAllCheckBox = () => {
    const {
      error,
      pending,
      selected,
      chunks,
      selectAll,
      onSelectAllAdmins,
      onDeselectAllAdmins,
      onDeleteSelected,
      t,
    } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllAdmins() : onDeselectAllAdmins()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          onDownloadSelected={() => {
            downloadCSV(this.getCsvData(selected, chunks), 'admins');
          }}
          text={t('admins')}
        />
      </div>
    );
  };

  getCsvData = (selected, chunks) => {
    const { t } = this.props;

    const csvColumns = [
      t('Name'),
      t('Email'),
      t('App Names'),
      t('Date Created'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selected.forEach(key => {
      data += `${chunks[key].firstName} ${chunks[key].lastName},${
        chunks[key].email
      },${chunks[key].adminAppname.replace(/,/g, ' | ')}, ${formatDate(
        chunks[key].createdAt,
      )}\n`;
    });

    return data;
  };

  getTitle = (selected, admin) => (
    <span>
      <Trans>App Admins</Trans>:{' '}
      {selected.length
        ? `${selected.length} / ${Object.keys(admin).length}`
        : Object.keys(admin).length}
    </span>
  );

  publishButtonProps = () => {
    const { groups, isPublished } = this.props;

    if (!groups) {
      return {};
    }

    const hasUpdateReady = Object.keys(groups).reduce((val, key) => {
      const group = groups[key];

      if (val === true || !group.last_updated) {
        return val;
      }

      if (
        (group.last_updated && !group.last_published) ||
        group.last_updated > group.last_published
      ) {
        return true;
      }

      return val;
    }, false);

    if (hasUpdateReady || !isPublished) {
      return {
        // borderColor: 'rgb(156, 150, 6)',
        outlineRGB: '195, 207, 47',
      };
    }

    return {};
  };

  sortByDate = () => {
    let filteredChunks = {};
    const sortedItems = [];
    let myList = Object.entries(this.props.filteredChunks);

    if (this.state.dateSortArrowUp) {
      myList = myList.sort((x, y) => x[1].createdAt - y[1].createdAt);
    } else {
      myList = myList.sort((x, y) => y[1].createdAt - x[1].createdAt);
    }
    myList.forEach(item => {
      filteredChunks = {
        ...filteredChunks,
        [item[0]]: {
          ...item[1],
        },
      };
      sortedItems.push(item[0]);
    });

    this.setState({
      sortedItems,
      dateSortArrowUp: !this.state.dateSortArrowUp,
    });

    // this.props.onFilterData(filteredChunks, sortedItems);
  };

  render() {
    const {
      pending,
      items,
      error,
      getAppAdmins,
      chunks,
      onSelect,
      filteredChunks,
      onFilterAdminData,
      onSelectAllAdmins,
      onDeselectAllAdmins,
      selected,
      onDeleteSelected,
      history,
      match,
      t,
    } = this.props;

    const suffixClass = items.length < 4 ? 'fix-left' : 'selected';

    const body = error ? (
      <TryAgain
        message={t('Opps, something went wrong...')}
        onRetry={getAppAdmins}
      />
    ) : (
      <ResultsTable
        items={this.state.sortedItems}
        chunks={filteredChunks}
        error={Object.keys(filteredChunks).length === 0 ? 'yes' : null}
        pending={pending}
        loading={pending}
        emptyMessage={t('Looks like no app admins have been added yet')}
        onRowTouchTap={admin =>
          history.push(`/${match.params.appname}/editor/build/admins/${admin}`)
        }
        columns={[
          {
            ids: ['firstName', 'lastName'],
            header: t('Name'),
            classNames: 'col-xs-3',
            containerElement: ({ value }) => <div>{value}</div>,
          },
          {
            id: 'email',
            header: t('Email'),
            classNames: 'col-xs-5',
          },
          {
            id: 'createdAt',
            // header: t('Date Created'),
            header: (
              <>
                <Trans>Date Created</Trans>
                {this.state.dateSortArrowUp ? (
                  <ArrowDropUpIcon
                    onClick={() => this.sortByDate()}
                    className="arrowUpDown"
                    style={{
                      position: 'absolute',
                      top: -5,
                      color: this.state.dateSortArrowUp !== null && '#261ed5',
                    }}
                  />
                ) : (
                  <ArrowDropDownIcon
                    onClick={() => this.sortByDate()}
                    className="arrowUpDown"
                    style={{
                      position: 'absolute',
                      top: -5,
                      color: this.state.dateSortArrowUp !== null && '#261ed5',
                    }}
                  />
                )}
              </>
            ),
            classNames: 'col-xs-3',
            containerElement: ({ value }) => (
              <span>{value && moment(value).format('DD/MM/YYYY')}</span>
            ),
          },
          {
            id: 'selected',
            header: this.getSelectAllCheckBox(),
            classNames: `col-xs-1 ${suffixClass}`,
            containerElement: ({ value, rowId }) =>
              rowId !== 'default' && (
                <>
                  <TableCheckbox
                    value={value}
                    onSelect={() => onSelect(rowId)}
                  />
                  <ActionMenu
                    selected={[rowId]}
                    onDeleteSelected={() => onDeleteSelected([rowId], chunks)}
                    onDownloadSelected={() => {
                      downloadCSV(this.getCsvData([rowId], chunks), 'admins');
                    }}
                    text={t('admin')}
                  />
                </>
              ),
          },
        ]}
      />
    );

    return (
      <section style={{ height: '70vh', padding: '0 0.7vw' }}>
        {/* <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <span>
            <Trans>Add new admin</Trans>{' '}
          </span>
          <FloatingActionButton
            icon={<AddIcon />}
            onClick={this.props.openNewAdminDialog}
          />
        </div> */}

        <Header
          {...this.props}
          title={this.getTitle(selected, chunks)}
          onChange={onFilterAdminData}
          onSelectAllResponses={onSelectAllAdmins}
          onDeselectAllResponses={onDeselectAllAdmins}
          showAppAdminBtn
          openNewAdminDialog={this.props.openNewAdminDialog}

          // actionMenu={
          //   <ActionMenu
          //     selected={selected}
          //     onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          //     onDownloadSelected={() => {
          //       downloadCSV(this.getCsvData(selected, chunks), 'admins');
          //     }}
          //     text="admins"
          //   />
          // }
        />
        {body}
        <NewAdmin />
        <EditAdmin />
      </section>
    );
  }
}

export default withTranslation()(Admins);
