import React from 'react';
import PropTypes from 'prop-types';
import { FlatButton } from '../../../../../app/components/buttons';

const offerExpired = ({ content, history, appname }) => {
  const handleClick = () => {
    history.push(`/${appname}/choose_plan`);
  };

  return (
    <div className="invalid-offer-overlay">
      <div>
        <img src={content.offerLogo} alt="dragons den logo" width={300} />
      </div>
      <div className="invalid-offer">
        <span>Unfortunately this offer has now expired.</span>
        <span>
          If you would like to sign up to one of our plans please click the
          button below.
        </span>
        <FlatButton label="Let's go" primary onClick={handleClick} />
      </div>
    </div>
  );
};

offerExpired.propTypes = {
  appname: PropTypes.string.isRequired,
  content: PropTypes.shape({
    offerLogo: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default offerExpired;
