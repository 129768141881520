import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import TextField from '../../../../../../../app/components/textField';
import Container from '../../../../../../../app/components/container';
import SectionHeader from '../../../../../components/sectionHeader';
import { Tabs, Tab } from '../../../../../components/tabs';
import logo from './logo.svg';
import { whitelabeledHost } from '../../../../../../../utils/whitelabeledHost';
const isWhiteLabeledHost = whitelabeledHost();

class Ecwid extends Component {
  state = {
    isDirty: false,
    isValid: true,
  };

  componentDidMount() {
    this.validate();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.component.storeId !== this.props.component.storeId) {
      this.setState({
        isValid: typeof nextProps.component.storeId === 'string',
      });
    }
  }

  onBlur = () => {
    this.validate();
  };

  validate = () => {
    const { component } = this.props;

    if (typeof component.rawId === 'string' && component.rawId.length > 0) {
      this.setState({
        isDirty: true,
        isValid: typeof component.storeId === 'string',
      });
    }
  };

  render() {
    const { component, onEcwidIdChange, isAppAdmin, t } = this.props;

    return (
      <Tabs>
        <Tab label={t('content')}>
          <SectionHeader
            title="Ecwid"
            tooltip={
              <span>
                <Trans>Follow the instructions below</Trans> <br />
                <Trans>to add your Ecwid storefront</Trans>
              </span>
            }
          />
          <div style={{ padding: '0 30px', marginTop: 24 }}>
            <p>
              <Trans>Enter your</Trans>&nbsp;
              <span style={{ color: '#DA1A26' }}>
                <Trans>Ecwid storefront location</Trans>
              </span>
              &nbsp;{' '}
              <Trans>
                below if you have already your ECWID online store. If you still
                have to setup your ECWID online store
              </Trans>
              &nbsp;
              <a
                href="https://my.ecwid.com/cp/?partner=ecwid_e3c#register"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Trans>Sign Up</Trans>
              </a>
              &nbsp;<Trans>for a free account today.</Trans>
            </p>
          </div>
          <div style={{ padding: '0 12px' }}>
            <TextField
              id="ecwid"
              name="ecwid"
              placeholder={t('Ecwid storefront location')}
              hintText="https://store13279319.ecwid.com"
              value={component.rawId}
              onChange={onEcwidIdChange}
              onBlur={this.onBlur}
              feedback={
                !this.state.isValid &&
                this.state.isDirty &&
                t(
                  'Please provide a valid ecwid storefront url or an eight digit storefront ID',
                )
              }
              isValid={this.state.isValid}
            />
          </div>
          {!isAppAdmin && !isWhiteLabeledHost && (
            <div style={{ padding: '0 30px', marginTop: 48 }}>
              <p>
                <a
                  href="https://www.beezer.com/ecwid"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>Learn more</Trans>
                </a>
                &nbsp;
                <Trans>Learn more about your Ecwid Storefront location.</Trans>
              </p>
            </div>
          )}
          <Container>
            <img
              style={{ maxWidth: 190, marginTop: 24 }}
              src={logo}
              alt={t('Ecwid logo')}
            />
          </Container>
        </Tab>
        <Tab
          label={t('design')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
        <Tab
          label={t('layout')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    );
  }
}

Ecwid.propTypes = {
  component: PropTypes.object.isRequired,
  onEcwidIdChange: PropTypes.func.isRequired,
};

export default withTranslation()(Ecwid);
