import * as React from 'react';
import styled from 'styled-components';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { FlatButton } from 'app/components/buttons';
import './styles.css';

class FilterPopup extends React.Component {
  static displayName = 'FilterPopup';

  static getDerivedStateFromProps = ({ items }, { itemsSaved }) =>
    items !== itemsSaved ? { itemsDraft: items, itemsSaved: items } : null;

  state = {
    value: '',
    initialItems: Object.keys(this.props.items).reduce(
      (object, key) => ({ ...object, [key]: false }),
      {},
    ),
    itemsDraft: this.props.items,
    itemsSaved: this.props.items,
  };

  handleSearchChange = event => this.setState({ value: event.target.value });

  handleCheckItems = label => {
    const { itemsDraft } = this.state;
    const prevChecked = itemsDraft[label];
    const updatedItemsDraft = { ...itemsDraft, [label]: !prevChecked };
    this.setState({ itemsDraft: updatedItemsDraft });
  };

  handleResetItems = () =>
    this.setState({ itemsDraft: this.state.initialItems });

  handleConfirmItems = () => {
    const { handleFilterConfirm, handleFilterClose } = this.props;
    const { itemsDraft } = this.state;
    handleFilterConfirm(itemsDraft);
    this.setState({ itemsSaved: itemsDraft, value: '' });
    handleFilterClose();
  };

  handleDiscardItemsDraft = () => {
    const { itemsSaved } = this.state;
    const { handleFilterClose } = this.props;
    this.setState({ itemsDraft: itemsSaved, value: '' });
    handleFilterClose();
  };

  render() {
    const { open, name } = this.props;
    const { value, itemsDraft } = this.state;

    const doesMatchInputValue = label =>
      label.toLowerCase().includes(value.toLowerCase());

    const filteredItemsDraft = Object.entries(itemsDraft)
      .filter(([label]) => doesMatchInputValue(label))
      .reduce(
        (newFilteredItems, [label, checked]) => ({
          ...newFilteredItems,
          [label]: checked,
        }),
        {},
      );

    return (
      <Dialog
        open={open}
        onClose={this.handleDiscardItemsDraft}
        style={{ zIndex: 1500 }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflow: 'visible',
          },
        }}
        className="filter-popup-container"
      >
        <DialogContent>
          <Layer className="filter-popup-back" />
          <Layer className="filter-popup">
            <div className="search-input-wrapper">
              <input
                type="text"
                className="search"
                name={name}
                value={value}
                placeholder={`Filter by ${name}`}
                onChange={this.handleSearchChange}
              />
              <SearchIcon className="search-icon" />
            </div>
            <div className="filter-items">
              {Object.entries(filteredItemsDraft).map(([label, checked]) => (
                <div className="filter-item" key={label}>
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={checked}
                    onChange={() => this.handleCheckItems(label)}
                    name={label}
                    id={label}
                  />
                  <label htmlFor={label} className="label">
                    <span className="custom-checkbox" />
                    {label}
                  </label>
                </div>
              ))}
            </div>
            <hr className="divider" />
            <div className="filter-controls">
              <FlatButton
                label="Reset"
                onClick={this.handleResetItems}
                secondaryWhite
                labelFont="raleway"
                labelWeight="normal"
                labelNoPadding
              />
              <FlatButton
                label="Show Results"
                onClick={this.handleConfirmItems}
                primary
                labelFont="raleway"
                labelWeight="normal"
                labelNoPadding
              />
            </div>
          </Layer>
        </DialogContent>
      </Dialog>
    );
  }
}

const Layer = styled.div`
  width: 367px;
  height: fit-content;
  border-radius: 26px;
  background-color: ${props =>
    props.className === 'filter-popup-back' ? '#261ed5' : '#fff'};
  position: absolute;
  top: ${props => (props.className === 'filter-popup-back' ? -307 : -217)}px;
  left: ${props => (props.className === 'filter-popup-back' ? -239 : -154)}px;
`;

FilterPopup.propTypes = {
  items: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleFilterClose: PropTypes.func.isRequired,
  handleFilterConfirm: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default FilterPopup;
