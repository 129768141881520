import React from 'react';
import { Route } from 'react-router-dom';

import EditAppAdminModal from './modules/modal';

function EditAppAdminModalRouter() {
  return (
    <Route path="/:appname/editor/build/admins/:admin?">
      {props => <EditAppAdminModal {...props} />}
    </Route>
  );
}

export default EditAppAdminModalRouter;
