import React from 'react';
import PropTypes from 'prop-types';
import CheckCirle from 'material-ui/svg-icons/action/check-circle';
import { Trans, useTranslation } from 'react-i18next';

import TextField from '../../../../../../../app/components/textField';
import { CircularProgress } from '../../../../../../../app/components/progress';

import './styles.css';

const MetaData = ({ title, domain, onTitleChange, onDomainChange }) => {
  const { t } = useTranslation();

  return (
    <div className="create-app-fieldset">
      <div className="create-title-container">
        <TextField
          id="title"
          placeholder={`${t('Your App')}'s ${t('Title')}`}
          {...title}
          onChange={onTitleChange}
          accessCheck={true}
        />
      </div>
      <div className="create-domain-container">
        <div className="create-domain-input-container">
          <TextField
            disableOverflow
            id="domain"
            {...domain}
            hintText={t('Your Domain')}
            onChange={onDomainChange}
            debounce={true}
            rightActionContianerStyle={{
              bottom: 2,
            }}
            rightInputAction={
              domain.pending ? (
                <CircularProgress size={16} thickness={3} />
              ) : (
                domain.isAvailable &&
                domain.isValid && (
                  <CheckCirle
                    color="#78ffc5"
                    style={{ height: 18, width: 18 }}
                  />
                )
              )
            }
            accessCheck={true}
          />
        </div>
      </div>
    </div>
  );
};

MetaData.propTypes = {
  title: PropTypes.object.isRequired,
  domain: PropTypes.object.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onDomainChange: PropTypes.func.isRequired,
};

export default MetaData;
