import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';

import {
  getUsers,
  selectUser,
  selectAllUsers,
  deselectAllUsers,
  filterUserData,
  resetUsersData,
  deleteSelected,
} from 'modules/memberLogin/modules/users/actions';
import { getGroups } from 'modules/memberLogin/modules/groups/actions';

import Zoom from './Zoom';
import {
  updateChecked,
  updateAssistantChecked,
  addZoomComponent,
} from './actions';
import { appHasComponent } from '../../../../utils';

const mapStateToProps = (state, ownProps) => {
  const zoomComponentEnabled = appHasComponent(state.editor.structure, 'Zoom');
  const appname = ownProps.match.params.appname;
  return {
    ...ownProps,
    ...state.auth.users,
    accountId: state.account.auth.profile.account,
    appname: _get(
      state,
      `editor.shared.appName.${appname}.meta.title`,
      appname,
    ),
    zoomComponentEnabled,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUsers: () => dispatch(getUsers(ownProps.match.params.appname)),
  getGroups: () => dispatch(getGroups(ownProps.match.params.appname)),
  onUpdateChecked: (isZoomHost, user) =>
    dispatch(updateChecked(ownProps.match.params.appname, isZoomHost, user)),
  onUpdateAssistantChecked: (isZoomAssistant, user) =>
    dispatch(
      updateAssistantChecked(
        ownProps.match.params.appname,
        isZoomAssistant,
        user,
      ),
    ),
  onAddZoomComponent: () =>
    dispatch(addZoomComponent(ownProps.match.params.appname, ownProps.history)),

  onSelect: key => dispatch(selectUser(key)),
  onSelectAllUsers: () => dispatch(selectAllUsers()),
  onDeselectAllUsers: () => dispatch(deselectAllUsers()),
  onFilterUserData: filter => dispatch(filterUserData(filter)),
  resetUsersData: () => dispatch(resetUsersData()),
  onDeleteSelected: (selected, chunks) =>
    dispatch(deleteSelected(ownProps.match.params.appname, selected, chunks)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Zoom));
