import React from 'react';
import { useQuery } from 'react-query';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { ProgressOverlay } from '../../../../app/components/progress';
import TryAgain from '../../../../app/components/tryAgain';
import { Empty as EmptyIcon } from '../../../../app/components/icons';
import {
  StyledTableCell,
  StyledTableRow,
} from '../../../../app/components/table';
import {
  setFkAcademyGroupId,
  toggleFkAcademyGroupDialog,
  toggleFkAcademyGroupDetailsDialog,
} from '../../actions';
import { getAllFkAcademyGroups } from '../../api';

import styles from './styles.module.scss';

function GroupsTable({ match }) {
  // const { appname } = useParams();

  const { appname } = match.params;
  const dispatch = useDispatch();

  const { data: { data: groups } = {}, error, isLoading, isError } = useQuery(
    'fkAcademyGroups',
    () => getAllFkAcademyGroups(appname),
    {
      enabled: !!appname,
    },
  );

  if (isLoading) return <ProgressOverlay />;

  if (isError) return <TryAgain message={error.message} />;

  if (groups && !groups.length)
    return (
      <div className="empty-state">
        <EmptyIcon />
        <h5>You haven't added any groups yet.</h5>
      </div>
    );

  return (
    <>
      <TableContainer style={{ maxHeight: 650 }} component={Paper}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell max-width="25%">Decsription</StyledTableCell>
              <StyledTableCell width="5%">Available Slots</StyledTableCell>
              <StyledTableCell width="5%">Filled Slots</StyledTableCell>
              <StyledTableCell>Coach</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Edit</StyledTableCell>
              <StyledTableCell>Details</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups &&
              groups.length &&
              groups.map(group => (
                <StyledTableRow key={group.key}>
                  <StyledTableCell component="th" scope="row">
                    {group.title || '-'}
                  </StyledTableCell>
                  <StyledTableCell max-width="25%" component="th" scope="row">
                    {group.description || '-'}
                  </StyledTableCell>
                  <StyledTableCell width="5%" component="th" scope="row">
                    {group.availableSlots || 0}
                  </StyledTableCell>
                  <StyledTableCell width="5%" component="th" scope="row">
                    {group.filledSlots || 0}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {group.coach.firstName || '-'}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {group.status || '-'}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <button
                      className={styles.button}
                      onClick={() => {
                        dispatch(setFkAcademyGroupId(group.key));
                        dispatch(toggleFkAcademyGroupDialog(true));
                      }}
                    >
                      EDIT
                    </button>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <button
                      className={styles.button}
                      onClick={() => {
                        dispatch(setFkAcademyGroupId(group.key));
                        dispatch(toggleFkAcademyGroupDetailsDialog(true));
                      }}
                    >
                      VIEW DETAILS
                    </button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>

  );
}

export default withRouter(GroupsTable);
