import React from 'react';

const MemberLogin = () => (
  <svg
    className="icon memberLogin"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39 39"
  >
    <title>Member Login</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M32.5,5A19.49,19.49,0,1,0,9.1,36l1.15.73v-.06a19.38,19.38,0,0,0,18.5,0v.06L29.9,36A19.48,19.48,0,0,0,32.5,5ZM19.5,37.5a17.89,17.89,0,0,1-9.25-2.59V29.25a6.51,6.51,0,0,1,6.5-6.5h5.5a6.51,6.51,0,0,1,6.5,6.5v5.66A17.92,17.92,0,0,1,19.5,37.5Zm0-16.25a5.5,5.5,0,1,1,5.5-5.5A5.51,5.51,0,0,1,19.5,21.25ZM30.25,33.92V29.25a8,8,0,0,0-6.61-7.87,7,7,0,1,0-8.28,0,8,8,0,0,0-6.61,7.87v4.67a18,18,0,1,1,21.5,0Z"
        />
      </g>
    </g>
  </svg>
);

export default MemberLogin;
