import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import './styles.css';

const Wrapper = styled.div`
  border-bottom-color: ${props => props.borderColor} !important;
`;

const Counter = ({
  count,
  subtitle,
  borderBottom,
  borderColor,
  isWixWeeblyFreePlan,
}) => (
  <Wrapper
    borderColor={isWixWeeblyFreePlan ? '#00000050' : borderColor}
    className={`counter${borderBottom ? ' border-bottom' : ''}`}
  >
    <h3
      className={
        isWixWeeblyFreePlan ? 'counter-number-free-plan' : 'counter-number'
      }
    >
      {count}
    </h3>
    <span
      className={
        isWixWeeblyFreePlan ? 'counter-subtitle-free-plan' : 'counter-subtitle'
      }
    >
      {subtitle}
    </span>
  </Wrapper>
);

Counter.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isWixWeeblyFreePlan: PropTypes.bool,
  subtitle: PropTypes.string,
  borderBottom: PropTypes.bool,
  borderColor: PropTypes.string,
};

Counter.defaultProps = {
  count: 0,
  subtitle: '',
  borderBottom: false,
  borderColor: '',
  isWixWeeblyFreePlan: false,
};

export default Counter;
