import firebase from '../../../../../../../../firebase';
import { validateField } from './validators';
import { objectToArray } from '../../../../../../../../utils';
import { openSnackbar } from '../../../../../../../../app/modules/snackbar/actions';
import { WORKFORCE_GROUP_CREATED } from '../../../../config';
import { uploadFiles } from '../groups/actions';

export const NEW_GROUP_FIELD_CHANGE = 'NEW_GROUP_FIELD_CHANGE';
export const NEW_GROUP_TYPE_CHANGE = 'NEW_GROUP_TYPE_CHANGE';
export const OPEN_NEW_GROUP_FIELD_DIALOG = 'OPEN_NEW_GROUP_FIELD_DIALOG';
export const CLOSE_NEW_GROUP_FIELD_DIALOG = 'CLOSE_NEW_GROUP_FIELD_DIALOG';
export const GROUP_ASSIGNMENT_ERROR = 'GROUP_ASSIGNMENT_ERROR';
export const ADD_NEW_GROUP_FIELD = 'ADD_NEW_GROUP_FIELD';
export const MEMBER_GROUP_ASSIGNED = 'MEMBER_GROUP_ASSIGNED';

const newGroupFieldChange = (prop, id, value, isValid, latlong) => ({
  type: NEW_GROUP_FIELD_CHANGE,
  prop,
  id,
  value,
  isValid,
  latlong,
});

const groupTypeChange = (prop, value, isValid, defaultFieldValues) => ({
  type: NEW_GROUP_TYPE_CHANGE,
  prop,
  value,
  isValid,
  defaultFieldValues,
});

export const openMemberDetailDialog = () => ({
  type: OPEN_NEW_GROUP_FIELD_DIALOG,
});

export const closeMemberGroupFieldDialog = () => ({
  type: CLOSE_NEW_GROUP_FIELD_DIALOG,
});

export const formFieldChange = (isRequired, prop, id, value, latlong) => (
  dispatch,
  getState,
) => {
  let data = value;

  // To merge multiple files
  if (prop === 'File') {
    const { fieldsData } = getState().auth.workforceMembers.edit.createGroup;
    const files = value.map(i => ({
      preview: i.preview,
      name: i.name,
    }));
    data = fieldsData[id] ? [...fieldsData[id].value, ...files] : files;
  }

  const valid = data ? validateField[prop](data) : null;
  const isValid = isRequired ? valid : true;

  return dispatch(newGroupFieldChange(prop, id, data, isValid, latlong));
};

export const deleteFile = (id, index) => (dispatch, getState) => {
  // Remove file from files array
  const { fieldsData } = getState().auth.workforceMembers.edit.createGroup;
  const data = fieldsData[id] ? fieldsData[id].value : [];

  data.splice(index, 1);
  const isValid = !!data.length;

  return dispatch(newGroupFieldChange('File', id, data, isValid));
};

export const groupChange = (prop, value, defaultFieldValues) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;

  return dispatch(groupTypeChange(prop, value, isValid, defaultFieldValues));
};

export const assigningError = err => ({
  type: GROUP_ASSIGNMENT_ERROR,
  err,
});

export const saveDetails = (
  type,
  memberID,
  appName,
  fields,
  groupName,
  isCOC,
) =>
  new Promise((resolve, reject) => {
    const { currentUser } = firebase.auth();
    const fieldsData = objectToArray(fields).map(i => ({
      [i.id]: i.value,
    }));
    return currentUser.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);
      return fetch(`${process.env.REACT_APP_WORKFORCE_API}/assign-group`, {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
          groupID: type,
          memberID,
          appName,
          fieldsData,
          groupName,
          isCOC,
        }),
        headers,
      })
        .then(res => {
          res.json().then(data => {
            resolve(data);
          });
        })
        .catch(reject);
    });
  });

export const addMemberInGroup = (appName, memberID) => (dispatch, getState) => {
  const {
    type,
    fieldsData,
  } = getState().auth.workforceMembers.edit.createGroup;

  const group = getState().auth.memberGroups.listPage.data.chunks[type.value];
  const groupName = group.name;
  const isCOC = group.isCOC && group.cOCDoc;
  const filesArray = objectToArray(fieldsData).filter(
    i => i.value.prop === 'File',
  );

  const field = {
    type: type.value,
    memberID,
    appName,
    fieldsData,
  };

  dispatch({ type: ADD_NEW_GROUP_FIELD });

  return saveDetails(...Object.values(field), groupName, isCOC)
    .then(res => {
      if (!res.uid) {
        dispatch(assigningError(res));
      } else if (filesArray.length) {
        Promise.all(
          filesArray.map(data =>
            // uplaod files to S3 bucket by using pre builded function
            dispatch(
              uploadFiles(
                data.value.value,
                appName,
                memberID,
                type.value,
                data.id,
              ),
            ),
          ),
        ).then(() => {
          dispatch({
            type: MEMBER_GROUP_ASSIGNED,
            key: res.uid,
            data: field,
          });
          dispatch(openSnackbar(WORKFORCE_GROUP_CREATED));
        });
      } else {
        dispatch({
          type: MEMBER_GROUP_ASSIGNED,
          key: res.uid,
          data: field,
        });
        dispatch(openSnackbar(WORKFORCE_GROUP_CREATED));
      }
    })
    .catch(error => dispatch(assigningError(error)));
};
