import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import debounce from 'lodash/debounce';
import { Trans, useTranslation } from 'react-i18next';
import TextField from '../../../../../../../app/components/textField';
import Container from '../../../../../../../app/components/container';

import { Tabs, Tab } from '../../../../../components/tabs';
import themeStyles from '../../../../../../../_export.scss';
const styles = {
  picker: {
    width: 290,
  },
  toggle: {
    marginTop: 24,
    marginBottom: 12,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 6,
  },
  toggleLabel: {
    fontSize: 14,
  },
};
const TrackSwitchedColor = { backgroundColor: themeStyles.primaryColorLight };
const ThumbSwitchedColor = { backgroundColor: themeStyles.primaryColor };

const FacebookPage = ({ data, onChange, onToggle }) => {
  const handleInputChange = debounce(value => onChange('pageId', value), 500);
  const { t } = useTranslation();

  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container>
          <TextField
            id="pageId"
            name="pageId"
            placeholder={t('Enter a Facebook page id')}
            hintText={t('page id')}
            value={data.pageId}
            onChange={handleInputChange}
          />
          {/* <Toggle
            label="Show Timeline Tab"
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.showTimeline}
            onToggle={(field, value) => onToggle('showTimeline', value)}
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
          <Toggle
            label="Show Events Tab"
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.showEvents}
            onToggle={(field, value) => onToggle('showEvents', value)}
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
          <Toggle
            label="Show Messages Tab"
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.showMessages}
            onToggle={(field, value) => onToggle('showMessages', value)}
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          /> */}
          <Toggle
            label={t('Show Cover Photo')}
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.showCover}
            onToggle={(field, value) => onToggle('showCover', value)}
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
          <Toggle
            label={t("Show Friend's Faces")}
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.showFaces}
            onToggle={(field, value) => onToggle('showFaces', value)}
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
          {/* <Toggle
            label="Show Large Header"
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.showLargeHeader}
            onToggle={(field, value) => onToggle('showLargeHeader', value)}
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          /> */}
        </Container>
      </Tab>
      <Tab
        label={t('design')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
      <Tab
        label={t('layout')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
    </Tabs>
  );
};

FacebookPage.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default FacebookPage;
