import firebase from '../../../../../../firebase';

export const itemFieldChange = (
  appname,
  lazyDataId,
  type,
  itemKey,
  field,
  value,
  itinerary,
  calendarType,
  isFormField,
) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${lazyDataId}`);
  // TODO: We will need to change this in future as this will cause problems if we
  // introduce more components that save their data in different ways, i.e itinerary
  // in place of options/items -- ideally the system should support a range of parent keys
  // for { chunks, order }
  if (type === 'Vote') {
    isFormField
      ? dataRef.child(`fields/chunks/${itemKey}/${field}`).set(value)
      : dataRef.child(`options/chunks/${itemKey}/${field}`).set(value);
  } else if (type === 'Schedule') {
    dataRef
      .child(`itenerary/${itinerary}/items/chunks/${itemKey}/${field}`)
      .set(value);
  } else if (type === 'Slider') {
    dataRef.child(`slides/chunks/${itemKey}/${field}`).set(value);
  } else if (type === 'GoogleMaps') {
    dataRef.child(`markers/chunks/${itemKey}/${field}`).set(value);
  } else if (type === 'Form') {
    dataRef.child(`fields/chunks/${itemKey}/${field}`).set(value);
  } else if (type === 'EventList') {
    dataRef.child(`filters/${itemKey}/${field}`).set(value);
  } else if (type === 'EventCalendar') {
    dataRef
      .child(`${calendarType}/fields/chunks/${itemKey}/${field}`)
      .set(value);
  } else {
    dataRef.child(`items/chunks/${itemKey}/${field}`).set(value);
  }

  return {
    type: 'ITEM_FIELD_CHANGE',
    appname,
    lazyDataId,
    itemKey,
    field,
    value,
    isFormField,
  };
};

const receiveItemData = (key, type, itemKey, data) => {
  return {
    type: 'RECEIVE_ITEM_DATA',
    component: type,
    key,
    itemKey,
    data,
  };
};

export const loadItemData = (
  appname,
  dataId,
  type,
  itemKey,
  itinerary,
  calendarType,
) => dispatch => {
  const draftRef = firebase.database().ref(`apps/${appname}/draft`);
  let itemRef = draftRef.child(`lazy_data/${dataId}/items/chunks/${itemKey}`);
  let isVoteForm = false;
  if (type === 'Vote') {
    draftRef
      .child(`lazy_data/${dataId}/options/chunks/${itemKey}`)
      .once('value', snapshot => {
        if (snapshot.exists()) {
          itemRef = draftRef.child(
            `lazy_data/${dataId}/options/chunks/${itemKey}`,
          );
        } else {
          isVoteForm = true;
          itemRef = draftRef.child(
            `lazy_data/${dataId}/fields/chunks/${itemKey}`,
          );
        }
      });
    // itemRef = draftRef.child(`lazy_data/${dataId}/options/chunks/${itemKey}`);
  }

  if (type === 'Slider') {
    itemRef = draftRef.child(`lazy_data/${dataId}/slides/chunks/${itemKey}`);
  }

  if (type === 'Schedule') {
    itemRef = draftRef.child(
      `lazy_data/${dataId}/itinerary/${itinerary}/items/chunks/${itemKey}`,
    );
  }

  if (type === 'GoogleMaps') {
    itemRef = draftRef.child(`lazy_data/${dataId}/markers/chunks/${itemKey}`);
  }

  if (type === 'Form') {
    itemRef = draftRef.child(`lazy_data/${dataId}/fields/chunks/${itemKey}`);
  }

  if (type === 'EventList') {
    itemRef = draftRef.child(`lazy_data/${dataId}/filters/${itemKey}`);
  }

  if (type === 'EventCalendar') {
    itemRef = draftRef.child(
      `lazy_data/${dataId}/${calendarType}/fields/chunks/${itemKey}`,
    );
  }

  dispatch({
    type: 'LOADING_ITEM_DATA',
    key: dataId,
    component: type,
    itemKey,
    appname,
  });

  itemRef.on('value', snapshot => {
    let data;
    if (isVoteForm) {
      data = { ...snapshot.val(), voteForm: true };
    } else {
      data = snapshot.val();
    }
    const key = snapshot.key;

    dispatch(receiveItemData(dataId, type, key, data));
  });
};

export const offItemData = (appname, dataId, type, itemKey, itinerary) => {
  const draftRef = firebase.database().ref(`apps/${appname}/draft`);
  let itemRef = draftRef.child(`lazy_data/${dataId}/items/chunks/${itemKey}`);

  if (type === 'Vote') {
    draftRef
      .child(`lazy_data/${dataId}/options/chunks/${itemKey}`)
      .once('value', snapshot => {
        if (snapshot.exists()) {
          itemRef = draftRef.child(
            `lazy_data/${dataId}/options/chunks/${itemKey}`,
          );
        } else {
          itemRef = draftRef.child(
            `lazy_data/${dataId}/fields/chunks/${itemKey}`,
          );
        }
      });
  }

  if (type === 'Slider') {
    itemRef = draftRef.child(`lazy_data/${dataId}/slides/chunks/${itemKey}`);
  }

  if (type === 'Schedule') {
    itemRef = draftRef.child(
      `lazy_data/${dataId}/itinerary/${itinerary}/items/chunks/${itemKey}`,
    );
  }

  if (type === 'GoogleMaps') {
    itemRef = draftRef.child(`lazy_data/${dataId}/markers/chunks/${itemKey}`);
  }

  if (type === 'Form') {
    itemRef = draftRef.child(`lazy_data/${dataId}/fields/chunks/${itemKey}`);
  }

  if (type === 'EventList') {
    itemRef = draftRef.child(`lazy_data/${dataId}/filters/${itemKey}`);
  }

  if (type === 'EventCalendar') {
    itemRef = draftRef.child(`lazy_data/${dataId}/fields/chunks/${itemKey}`);
  }

  itemRef.off('value');

  return {
    type: 'REMOVING_ITEM_DATA_LISTENER',
    key: dataId,
    appname,
    itemKey,
  };
};

export const designChange = (appname, key, type, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${key}/design`);

  dataRef.child(type).set(value);

  return {
    type: 'ITEM_DESIGN_CHANGE',
    appname,
    key,
    designType: type,
    value,
  };
};

const getDefaultLink = link => {
  switch (link) {
    case 'instagram':
      return { instagram: 'https://instagram.com/' };
    case 'facebook':
      return { facebook: 'https://facebook.com/' };
    case 'twitter':
      return { twitter: 'https://twitter.com/' };
    case 'linkedin':
      return { linkedin: 'https://linkedin.com/in/' };
    case 'web':
      return { web: 'https://example.com/' };
    case 'email':
      return { email: 'no-reply@example.com' };
    case 'youtube':
      return { youtube: 'https://youtube.com/' };
    case 'phone':
      return { phone: '+44 131 4960000' };
    case 'tripadvisor':
      return { tripadvisor: 'https://www.tripadvisor.com/' };
    case 'googlemap':
      return { googlemap: 'https://www.google.com/maps' };
    default:
      return null;
  }
};

export const addSocialLinkToDelegate = (
  appname,
  lazyDataKey,
  itemKey,
  link,
) => async dispatch => {
  try {
    const newLink = getDefaultLink(link);
    await firebase
      .database()
      .ref(
        `apps/${appname}/draft/lazy_data/${lazyDataKey}/items/chunks/${itemKey}`,
      )
      .update(newLink);

    return dispatch({
      type: 'ADDED_SOCIAL_LINK_TO_DELEGATE',
      link,
    });
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const removeSocialLinkFromDelegate = (
  appname,
  lazyDataKey,
  itemKey,
  link,
) => async dispatch => {
  try {
    await firebase
      .database()
      .ref(
        `apps/${appname}/draft/lazy_data/${lazyDataKey}/items/chunks/${itemKey}`,
      )
      .child(link.type)
      .set(null);

    return dispatch({
      type: 'REMOVED_SOCIAL_LINK_FROM_DELEGATE',
      link,
    });
  } catch (error) {
    console.error(error);
    return null;
  }
};
