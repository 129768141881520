import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OldCheckbox from 'material-ui/Checkbox';
import Checkbox from '@material-ui/core/Checkbox';
import { Trans, withTranslation } from 'react-i18next';

import ActionMenu from 'app/components/actionMenu';
import ResultsTable from 'app/components/resultsTable';
import Header from 'app/components/header';
import { formatDate, lowerCase, capitaliseString, downloadCSV } from 'utils';
import themeStyles from '../../../../_export.scss';

const isCheckboxDisabled = (error, pending) => error && !pending;

class History extends Component {
  static propTypes = {
    query: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    chunks: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    setListener: PropTypes.func.isRequired,
    removeListener: PropTypes.func.isRequired,
    pending: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.setListener();
  }

  getCsvData = (selected, histories) => {
    const { t } = this.props;
    const csvColumns = [t('Date'), t('Destination'), t('Type'), t('Message')];
    let data = `${csvColumns.join(',')}\n`;

    selected.forEach(key => {
      data += `${formatDate(histories[key].date)},${
        histories[key].destination
      },${histories[key].mode},${histories[key].message}\n`;
    });

    return data;
  };

  componentWillUnmount() {
    this.props.removeListener();
  }

  getColumns = () => {
    const { items, t } = this.props;

    const suffixClass = items.length < 3 ? 'fix-left' : 'selected';
    return [
      {
        id: 'date',
        header: t('Date'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) => (
          <span>{value && formatDate(value)}</span>
        ),
      },
      {
        id: 'destination',
        header: t('Destination'),
        classNames: 'col-xs-3',
      },
      {
        id: 'mode',
        header: t('Type'),
        classNames: 'col-xs-1',
        containerElement: ({ value }) => (
          <span>
            {typeof value === 'string' && value === 'SMS'
              ? 'SMS'
              : capitaliseString(lowerCase(value))}
          </span>
        ),
      },
      {
        id: 'message',
        header: t('Message'),
        classNames: 'col-xs-5',
      },
      {
        id: 'selected',
        header: (
          <div style={{ display: 'flex' }}>
            <Checkbox
              checked={this.props.selectAll}
              onChange={(e, isChecked) => {
                isChecked
                  ? this.props.onSelectAllHistories()
                  : this.props.onDeselectAllHistories();
              }}
              disabled={isCheckboxDisabled(
                this.props.error,
                this.props.pending,
              )}
              style={{ marginLeft: -2, color: themeStyles.primaryColor }}
              disableRipple
            />
            <ActionMenu
              selected={this.props.selected}
              onDownloadSelected={() => {
                downloadCSV(
                  this.getCsvData(this.props.selected, this.props.chunks),
                  'history',
                );
              }}
              text={t('history')}
            />
          </div>
        ),
        classNames: `col-xs-1 ${suffixClass}`,
        containerElement: ({ value, rowId }) => (
          <>
            <OldCheckbox
              checked={value}
              onClick={e => {
                e.stopPropagation();
              }}
              onCheck={e => {
                e.stopPropagation();
                this.props.onSelect(rowId);
              }}
            />
            <ActionMenu
              selected={[rowId]}
              onDownloadSelected={() => {
                downloadCSV(
                  this.getCsvData([rowId], this.props.chunks),
                  'history',
                );
              }}
              text={t('history')}
            />
          </>
        ),
      },
    ];
  };

  getHeaderTitle = () => (
    <span>
      <Trans>Shared</Trans>: <b>{this.props.items.length}</b>
    </span>
  );

  render() {
    const containerHeight = { height: 'calc(100% - 180px)' };
    const { onSearch, query, items, chunks, pending, t } = this.props;

    return (
      <div style={containerHeight}>
        <Header
          filter={query}
          title={this.getHeaderTitle()}
          onChange={onSearch}
          pending={pending}
          disabled={!items.length}
        />
        <ResultsTable
          items={items}
          chunks={chunks}
          loading={pending}
          columns={this.getColumns()}
          pending={pending}
          error={items.length === 0}
          emptyMessage={t("Looks like you've not shared via SMS or Email yet")}
        />
      </div>
    );
  }
}

export default withTranslation()(History);
