import { connect } from 'react-redux';
import Diary from './Diary';
import {
  loadDiaryData,
  filterDiaryData,
  selectDiary,
  selectAllDiaries,
  deselectAllDiaries,
  deleteDiaryEntry,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.auth.diaryData,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadDiaryData: appname => dispatch(loadDiaryData(appname)),
  onFilterDiaryData: filter => dispatch(filterDiaryData(filter)),
  onSelect: key => dispatch(selectDiary(key)),
  onSelectAllDiaries: () => dispatch(selectAllDiaries()),
  onDeselectAllDiaries: () => dispatch(deselectAllDiaries()),
  deleteDiaryEntry: key =>
    dispatch(deleteDiaryEntry(ownProps.match.params.appname, key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Diary);
