import firebase from '../../../../../../../firebase';

export const updateMarketplaceSettings = (appname, componentID, name, data) => {
  const draftDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${componentID}/marketplace_settings`);

  const marketplaceRef = firebase
    .database()
    .ref(`marketplace/${appname}/marketplace_settings`);

  draftDataRef.child(name).set(data);
  marketplaceRef.child(name).set(data);

  return {
    type: 'UPDATED_MARKETPLACE_SETTINGS',
  };
};
