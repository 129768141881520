import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function ReferralsTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);

  const { headers, rows, dataAttributes, loading } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getTableData = () => {
    if (loading) {
      return Array.from({ length: 4 });
    }
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  function formatAmount(input) {
    const index = input.search(/\d/);

    if (index !== -1) {
      // Split the string into the currency symbol and the numeric value
      const symbol = input.slice(0, index).trim();
      const value = input.slice(index).trim();

      // Return the reversed format with the currency symbol capitalized
      return `${value} ${symbol.toUpperCase()}`;
    }

    return input;
  }
  const tableData = getTableData();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {headers.map(header => (
                  <TableCell align="left" key={`header_${header}`}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) =>
                row ? (
                  <TableRow hover tabIndex={-1} key={`tr_data_${index}`}>
                    {dataAttributes.map((item, key) => {
                      return row.hasOwnProperty(item) ? (
                        <TableCell align="left" key={`td_data_${key}_${index}`}>
                          {item === 'formattedAmount'
                            ? formatAmount(row[item])
                            : row[item]}
                        </TableCell>
                      ) : (
                        ''
                      );
                    })}
                  </TableRow>
                ) : (
                  <TableRow key={index}>
                    {headers.map(header => (
                      <TableCell align="left" key={`skeleton_${header}`}>
                        <Skeleton animation="wave" height={20} />
                      </TableCell>
                    ))}
                  </TableRow>
                ),
              )}
              {!loading && rows.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    <Trans>No Record Found</Trans>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length > 4 ? (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : (
          ''
        )}
      </Paper>
    </div>
  );
}
