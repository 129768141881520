import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import { ChromePicker } from 'react-color';

import './styles.css';

const styles = {
  swatch: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, .298039)',
    minWidth: 'none',
  },
};

class ColorPicker extends Component {
  static propTypes = {
    label: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    height: 20,
    width: 60,
  };

  state = {
    active: false,
  };

  componentWillUnmount() {
    if (this.state.active) {
      this.el.removeEventListener('click', this.onElClick);
      document.body.removeEventListener('click', this.offClick);
    }
  }

  onClick = e => {
    e.preventDefault();

    const { top, left } = this.colorSwatchRef.getBoundingClientRect();

    let topVal = top + (this.props.height - 6);
    if (topVal + 240 > document.body.offsetHeight) {
      topVal = document.body.offsetHeight - 250;
    }

    this.setState({
      active: true,
      left: left + (this.props.width - 18),
      top: topVal,
    });

    document.body.addEventListener('click', this.offClick);
  };

  getButtonStyle = () => ({
    ...styles.swatch,
    height: this.props.height,
    width: this.props.width,
    backgroundColor: this.props.color,
  });

  offClick = e => {
    const x = e.pageX;
    const y = e.pageY;
    const rect = this.el.getBoundingClientRect();

    const xBoundary = {
      left: rect.left,
      right: rect.right + rect.width,
    };
    const yBoundary = {
      top: rect.top,
      bottom: rect.top + rect.height,
    };

    if (
      x < xBoundary.left ||
      x > xBoundary.right ||
      y < yBoundary.top || y > yBoundary.bottom
    ) {
      this.setState({ active: false });
      document.body.removeEventListener('click', this.offClick);
    }
  };

  render() {
    const { label, color, height, width, disabled, onChange } = this.props;

    return (
      <div className={`design-color ${disabled && 'disabled'}`}>
        {label && (
          <h5
            className="design-color-label"
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}
        <div
          style={{ height, width }}
          className="color-swatch"
          ref={node => {
            this.colorSwatchRef = node;
          }}
        >
          <FlatButton
            label="&nbsp;"
            style={this.getButtonStyle()}
            onClick={this.onClick}
            disabled={disabled}
          />
          <div
            className={`color-picker-wrapper ${
              this.state.active ? 'active' : ''
            } no-select`}
            tabIndex="-1"
            ref={node => {
              this.el = node;
            }}
            style={{ top: this.state.top, left: this.state.left }}
          >
            <ChromePicker
              color={color}
              onChange={onChange}
              onChangeComplete={onChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ColorPicker;
