const initialState = {
  open: false,
  name: {
    value: '',
    isValid: null,
  },
  email: {
    value: '',
    isValid: null,
  },
  mobile: {
    value: '',
    isValid: null,
  },
  country: {
    value: {
      code: 'GB',
      dialCode: '+44',
      name: 'United Kingdom',
    },
  },
  key: null,
  pending: false,
  isDirty: false,
};

const editContactDialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_EDIT_CONTACT_DIALOG':
      return {
        ...state,
        name: {
          value: action.name,
          isValid: null,
        },
        email: {
          value: action.email,
          isValid: null,
        },
        mobile: {
          value: action.mobile,
          isValid: null,
        },
        country: {
          value: action.country,
          isValid: null,
        },
        key: action.key,
        open: true,
      };

    case 'CLOSE_EDIT_CONTACT_DIALOG':
      return initialState;

    case 'CONTACT_FIELD_CHANGE':
      return {
        ...state,
        [action.field]: {
          value: action.value,
          isValid: action.isValid,
        },
        isDirty: true,
      };

    case 'SAVE_CONTACT_CHANGE':
      return {
        ...state,
        pending: true,
        isDirty: false,
      };

    case 'CONTACT_UPDATED':
      return {
        ...state,
        pending: false,
        open: false,
      };

    default:
      return state;
  }
};

export default editContactDialogReducer;
