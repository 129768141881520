import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Trans, withTranslation } from 'react-i18next';
import { LoadingScreen } from '../../../../app/components/progress';

class ClickbankThankYou extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired,
    recordUserInfo: PropTypes.func.isRequired,
    failedClickbank: PropTypes.func,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { history } = this.props;
    const script = document.createElement('script');
    // load the clcikbank trust Badge
    script.src = `//cbtb.clickbank.net/?vendor=${process.env.REACT_APP_CLICKBANK_ACCOUNT_NAME}`;
    document.head.appendChild(script);
    if (history.location.search.includes('iv')) {
      this.props.recordUserInfo(queryString.parse(history.location.search));
    } else {
      this.props.failedClickbank();
    }
  }
  render() {
    const { pending, success, failed, t } = this.props;
    if (pending) {
      return <LoadingScreen loading />;
    }
    if (failed) {
      return (
        <div className="error-message">
          <h3 style={{ textAlign: 'center' }}>
            <Trans>
              Something Went Wrong due to which we are unable to subscribe you
              to the Beezer. Please contact support@beezer.com
            </Trans>
          </h3>
        </div>
      );
    }
    if (!pending && success) {
      return (
        <iframe
          src="https://www.beezer.com/clickbank-thank-you-page/"
          width="100%"
          height="100%"
          title={t('Beezer Thank You Page')}
        />
      );
    }
    return (
      <div className="success-message">
        <p>
          <Trans>
            This email id is already associated with a Beezer account.
          </Trans>
        </p>
        <p>
          <Trans>Please</Trans>{' '}
          <a href="/login">
            <u>
              <Trans>log into your account</Trans>
            </u>
          </a>{' '}
          <Trans>and your account will be upgraded to new plan.</Trans>
        </p>
        <p>
          <Trans>
            If you have any questions please contact support@beezer.com
          </Trans>
        </p>
      </div>
    );
  }
}

export default withTranslation()(ClickbankThankYou);
