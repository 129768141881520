import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import CircularProgress from '../../../../../../app/components/progress/circularProgress';
import UserInfo from '../../components/userInfo';
import Products from '../products';
import FavoriteVendors from '../favoriteVendors';
import * as Styled from './styles';
const Buyer = ({
  buyer,
  pending,
  allProducts,
  allVendors,
  getBuyerDetails,
  getFavoriteProducts,
  getFavoriteVendors,
  stopGetBuyerDetails,
}) => {
  useEffect(() => {
    getBuyerDetails();
    return stopGetBuyerDetails;
  }, []);

  useEffect(() => {
    if (
      Object.keys(buyer).length > 0 &&
      buyer.favorites &&
      buyer.favorites.products
    ) {
      getFavoriteProducts(buyer.favorites.products);
    }
  }, [buyer]);

  return (
    <>
      {pending ? (
        <CircularProgress />
      ) : (
        <Styled.container>
          <UserInfo user={buyer} type="buyer" />
          <h2>
            <Trans>Favorite Products</Trans>
          </h2>
          <Styled.section>
            <Products allProducts={allProducts} showProductActions={false} />
          </Styled.section>
        </Styled.container>
      )}
    </>
  );
};

Buyer.propTypes = {
  buyer: PropTypes.object.isRequired,
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
};

export default Buyer;
