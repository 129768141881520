import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useQuery, useQueryClient } from 'react-query';
import { makeStyles } from '@material-ui/styles';
import {
  Checkbox,
  TableContainer,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import { get, set, reduce, keys, pickBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import ActionMenu from 'app/components/actionMenu';
import { downloadCSV } from 'utils';
import { openEditContactDialog } from 'modules/distribute/modules/contacts/editContact/actions';
import {
  loadContacts,
  deleteMultipleContacts,
} from 'modules/distribute/modules/contacts/actions';

import { Empty as EmptyIcon } from '../../../../app/components/icons';

import { getAllContacts } from '../../api';

import styles from './styles.module.scss';

import themeStyles from '../../../../_export.scss';

const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-head': {
      color: 'black',
      backgroundColor: '#EAEEFF',
      fontSize: 16,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
    },
    '& .MuiTableCell-body': {
      color: 'black',
      fontSize: 16,
      fontWeight: 400,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
      borderBottom: 'none',
    },
    '& .options-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .MuiCheckbox-root': {
      width: 24,
      height: 24,
    },
    '& .action-menu-container': {
      width: 24,
      height: 24,
    },
  },
});

const DriversTable = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [chunks, setChunks] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const { appname, isAppAdmin, history } = props;

  const queryClient = useQueryClient();
  const { data, error, isLoading, isError, refetch } = useQuery(
    ['contacts', { appname }],
    getAllContacts,
    { refetchOnWindowFocus: false },
  );
  const dispatch = useDispatch();

  const handleEditSelected = key => {
    dispatch(openEditContactDialog(key));
    history.push({
      pathname: `/${appname}/distribute/contacts`,
      cameFromDrivers: true,
      type: 'edit',
    });
  };

  const onLoadContacts = () => dispatch(loadContacts(appname));

  const onDeleteSelected = selected =>
    dispatch(deleteMultipleContacts(appname, selected, refetch));

  const handleCheck = (isDriver, key, name, type) => {
    confirmAlert({
      title:
        type === 'driverStatus'
          ? `${
              isDriver
                ? t('Confirm to remove as a driver.')
                : t('Confirm to make a driver.')
            } `
          : t('Confirm to delete contact.'),
      message: `${
        type === 'driverStatus'
          ? isDriver
            ? `${t('Are you sure you want to remove')} ${name} ${t(
                'as a driver?',
              )}`
            : `${t('Are you sure you want to make')} ${name} ${t('a driver.')}`
          : `${t('Are you sure you want to delete')} ${name} ${t(
              'as a contact?',
            )}`
      }`,
      buttons: [
        {
          label: t('Yes'),
          onClick: async () => {
            type === 'driverStatus'
              ? await fetch(
                  `${process.env.REACT_APP_FOOD_API}/update_contact`,
                  {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ appname, key, isDriver }),
                  },
                )
              : await fetch(
                  `${process.env.REACT_APP_FOOD_API}/delete_contact`,
                  {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ appname, key }),
                  },
                );
            queryClient.invalidateQueries('contacts');
          },
        },
        {
          label: t('No'),
          onClick: () => {},
        },
      ],
    });
  };

  const docLink =
    'https://beezer.zendesk.com/hc/en-us/articles/360020307797-Drivers-Section-Business-Tools';
  const docPDFLink =
    'https://firebasestorage.googleapis.com/v0/b/beezer-c947f.appspot.com/o/Instructions%2FDrivers%20Section%20-%20Business%20Tools.pdf?alt=media&token=af95cb08-844c-42e4-a3b0-19664f6be618';
  const getInstructionLink = () =>
    isAppAdmin ? (
      <a
        href={docPDFLink}
        rel="noopener noreferrer"
        style={{ color: themeStyles.primaryColor, fontWeight: 'bold' }}
        download
        target="_blank"
      >
        <Trans>Learn how to add drivers.</Trans>
      </a>
    ) : (
      <a
        href={docLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: themeStyles.primaryColor, fontWeight: 'bold' }}
      >
        <Trans> Learn how to add drivers.</Trans>
      </a>
    );

  const onSelect = id => {
    const modifiedChunks = { ...chunks };
    const prevValue = get(modifiedChunks, `${id}.selected`, false);
    set(modifiedChunks, `${id}.selected`, !prevValue);
    setChunks(modifiedChunks);
    const selectedItems = keys(pickBy(modifiedChunks, chunk => chunk.selected));
    setSelected(selectedItems);
  };

  const selectAllDrivers = () => {
    const allIds = keys(chunks);
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: true,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected(allIds);
    setSelectAll(true);
  };

  const deselectAllDrivers = () => {
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: false,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected([]);
    setSelectAll(false);
  };

  const getCsvData = (selectedItems, chunks) => {
    const csvColumns = [t('Name'), t('Email'), t('Mobile'), t('Driver Access')];
    let itemsData = `${csvColumns.join(',')}\n`;

    selectedItems.forEach(key => {
      itemsData += `${chunks[key].name || '-'},${chunks[key].email ||
        '-'},${`${chunks[key].country && chunks[key].country.dialCode} ${
        chunks[key].mobile
      }` || '-'},${!!chunks[key].isDriver}\n`;
    });

    return itemsData;
  };

  useEffect(() => {
    setChunks(data);
  }, [data]);

  useEffect(() => {
    onLoadContacts();
  }, []);

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  if (isError) {
    return (
      <span>
        <Trans>Error:</Trans> {error.message}
      </span>
    );
  }

  return (
    <div>
      {!data || Object.keys(data).length === 0 ? (
        <div className="empty-state">
          <EmptyIcon />
          <h5>
            <Trans>You haven't added any drivers yet.</Trans>
          </h5>
          <p>
            <Trans>
              Use your app to allow your app users to place food orders. Add
              food inventory, accept orders, assign orders to drivers and
              deliver them directly to your customers.
            </Trans>
          </p>
          <h5>{getInstructionLink()}</h5>
        </div>
      ) : (
        <TableContainer
          style={{ maxHeight: 650, borderRadius: 15, boxShadow: 'none' }}
          component={Paper}
        >
          <Table
            className={classes.table}
            stickyHeader
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Email</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Mobile</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Driver Access</Trans>
                </TableCell>
                <TableCell>
                  <div className="options-container">
                    <Checkbox
                      disableRipple
                      checked={selectAll || false}
                      onChange={(e, isChecked) =>
                        isChecked ? selectAllDrivers() : deselectAllDrivers()
                      }
                      style={{ color: themeStyles.primaryColor }}
                    />
                    <ActionMenu
                      selected={selected}
                      onDeleteSelected={() => {
                        onDeleteSelected(selected);
                      }}
                      onDownloadSelected={() => {
                        downloadCSV(getCsvData(selected, chunks), 'drivers');
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chunks &&
                Object.keys(chunks).map((key, i) => (
                  <TableRow
                    key={key}
                    hover
                    onClick={() => {
                      handleEditSelected(key);
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {chunks[key].name || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {chunks[key].email || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {`${chunks[key].country &&
                        chunks[key].country.dialCode} ${chunks[key].mobile}` ||
                        '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Checkbox
                        style={{ color: themeStyles.primaryColor }}
                        checked={!!chunks[key].isDriver}
                        onClick={() =>
                          handleCheck(
                            !!data[key].isDriver,
                            key,
                            chunks[key].name,
                            'driverStatus',
                          )
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="options-container">
                        <Checkbox
                          disableRipple
                          checked={chunks[key].selected || false}
                          onClick={e => e.stopPropagation()}
                          onChange={e => {
                            e.stopPropagation();
                            onSelect(key);
                          }}
                          style={{ color: themeStyles.primaryColor }}
                        />
                        <ActionMenu
                          selected={[key]}
                          onEditSelected={() => {
                            handleEditSelected(key);
                          }}
                          onDeleteSelected={() => {
                            onDeleteSelected([key]);
                          }}
                          onDownloadSelected={() => {
                            downloadCSV(
                              getCsvData([key], chunks),
                              t('drivers'),
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default DriversTable;
