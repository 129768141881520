import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EventListener from 'react-event-listener';
import keycode from 'keycode';
import Overlay from 'material-ui/internal/Overlay';
import RenderToLayer from 'material-ui/internal/RenderToLayer';
import Paper from 'material-ui/Paper';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';

import './styles.css';

function getStyles(props, context) {
  const { zIndex } = context.muiTheme;

  return {
    root: {
      zIndex: 1499,
    },
    content: {
      zIndex: zIndex.dialog,
    },
    paper: {
      padding: 48,
      position: 'relative',
      height: '100%',
      zIndex: 1500,
      borderRadius: 12,
    },
    overlay: {
      zIndex: zIndex.dialogOverlay,
    },
  };
}

class DialogInline extends Component {
  static propTypes = {
    header: PropTypes.node,
    showHeader: PropTypes.bool,
    children: PropTypes.node,
    modal: PropTypes.bool,
    bodyClassName: PropTypes.string,
    onRequestClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    title: PropTypes.node,
  };
  static defaultProps = {
    onRequestClose: null,
    children: null,
    header: null,
    showHeader: true,
    bodyClassName: '',
  };

  static contextTypes = {
    muiTheme: PropTypes.object.isRequired,
  };

  requestClose = buttonClicked => {
    if (!buttonClicked && this.props.modal) {
      return;
    }

    if (this.props.onRequestClose) {
      this.props.onRequestClose(!!buttonClicked);
    }
  };

  handleTouchTapOverlay = () => {
    this.requestClose(false);
  };

  handleKeyUp = event => {
    if (keycode(event) === 'esc') {
      this.requestClose(false);
    }
  };

  render() {
    const {
      header,
      children,
      open,
      showHeader,
      bodyClassName,
      modalHeight,
    } = this.props;
    const styles = getStyles(this.props, this.context);

    return (
      <div className={`modal ${open ? 'open' : 'closed'}`} style={styles.root}>
        {open && <EventListener target="window" onKeyUp={this.handleKeyUp} />}
        {open && (
          <div
            className={`modal-content ${bodyClassName}`}
            style={{ height: `${modalHeight ? modalHeight : '100%'}` }}
          >
            <Paper style={styles.paper} zDepth={4}>
              {showHeader && (
                <div className="modal-header">
                  {header}
                  <IconButton onClick={this.requestClose}>
                    <Close />
                  </IconButton>
                </div>
              )}
              {children}
            </Paper>
          </div>
        )}
        <Overlay
          show={open}
          style={styles.overlay}
          onClick={this.handleTouchTapOverlay}
        />
      </div>
    );
  }
}
// eslint-disable-next-line
class Dialog extends Component {
  static propTypes = {
    /**
     * Action buttons to display below the Dialog content (`children`).
     * This property accepts either a React element, or an array of React elements.
     */
    actions: PropTypes.node,
    /**
     * If set to true, the height of the `Dialog` will be auto detected. A max height
     * will be enforced so that the content does not extend beyond the viewport.
     */
    autoDetectWindowHeight: PropTypes.bool,
    /**
     * If set to true, the body content of the `Dialog` will be scrollable.
     */
    autoScrollBodyContent: PropTypes.bool,
    /**
     * The `className` to add to the content's root element under the title.
     */
    bodyClassName: PropTypes.string,
    /**
     * Overrides the inline-styles of the content's root element under the title.
     */
    bodyStyle: PropTypes.object,
    /**
     * The contents of the `Dialog`.
     */
    children: PropTypes.node,
    /**
     * The css class name of the root element.
     */
    className: PropTypes.string,
    /**
     * The `className` to add to the content container.
     */
    contentClassName: PropTypes.string,
    /**
     * Overrides the inline-styles of the content container.
     */
    contentStyle: PropTypes.object,
    /**
     * Force the user to use one of the actions in the `Dialog`.
     * Clicking outside the `Dialog` will not trigger the `onRequestClose`.
     */
    modal: PropTypes.bool,
    /**
     * Fired when the `Dialog` is requested to be closed by a click outside the `Dialog` or on the buttons.
     *
     * @param {bool} buttonClicked Determines whether a button click triggered this request.
     */
    onRequestClose: PropTypes.func,
    /**
     * Controls whether the Dialog is opened or not.
     */
    open: PropTypes.bool.isRequired,
    /**
     * The `className` to add to the `Overlay` component that is rendered behind the `Dialog`.
     */
    overlayClassName: PropTypes.string,
    /**
     * Overrides the inline-styles of the `Overlay` component that is rendered behind the `Dialog`.
     */
    overlayStyle: PropTypes.object,
    /**
     * Determines whether the `Dialog` should be repositioned when it's contents are updated.
     */
    repositionOnUpdate: PropTypes.bool,
    /**
     * Override the inline-styles of the root element.
     */
    style: PropTypes.object,
    /**
     * The title to display on the `Dialog`. Could be number, string, element or an array containing these types.
     */
    title: PropTypes.node,
    /**
     * The `className` to add to the title's root container element.
     */
    titleClassName: PropTypes.string,
    /**
     * Overrides the inline-styles of the title's root container element.
     */
    titleStyle: PropTypes.object,
  };

  static contextTypes = {
    muiTheme: PropTypes.object.isRequired,
  };

  static defaultProps = {
    autoDetectWindowHeight: true,
    autoScrollBodyContent: false,
    modal: false,
    repositionOnUpdate: true,
  };

  renderLayer = () => <DialogInline {...this.props} />;

  render() {
    return (
      <RenderToLayer
        render={this.renderLayer}
        useLayerForClickAway={false}
        open
      />
    );
  }
}

export default Dialog;
