import { connect } from 'react-redux';

import Privacy from './Privacy';
import { updatePrivacySettings, getPrivacySettings } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...state.editor.shared.privacy,
  appname: ownProps.match.params.appname,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updatePrivacySettings: (field, value) =>
    dispatch(
      updatePrivacySettings(ownProps.match.params.appname, field, value),
    ),
  getPrivacySettings: () =>
    dispatch(getPrivacySettings(ownProps.match.params.appname)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
