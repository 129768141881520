import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

const iconStyle = {
  height: 30,
  width: 16,
  fill: '#1b1b1b',
  fillRule: 'evenodd',
};

/* eslint-disable max-len */
const Phone = () => (
  <SvgIcon viewBox="0 0 32 60" style={iconStyle}>
    <path d="M27.250,60.000 L4.750,60.000 C2.131,60.000 0.000,57.816 0.000,55.133 L0.000,4.867 C0.000,2.184 2.131,0.000 4.750,0.000 L27.250,0.000 C29.869,0.000 32.000,2.184 32.000,4.867 L32.000,55.133 C32.000,57.816 29.869,60.000 27.250,60.000 ZM30.000,4.867 C30.000,3.286 28.767,2.000 27.250,2.000 L4.750,2.000 C3.233,2.000 2.000,3.286 2.000,4.867 L2.000,55.133 C2.000,56.714 3.233,58.000 4.750,58.000 L27.250,58.000 C28.767,58.000 30.000,56.714 30.000,55.133 L30.000,4.867 ZM19.000,8.000 L13.000,8.000 C12.448,8.000 12.000,7.552 12.000,7.000 C12.000,6.448 12.448,6.000 13.000,6.000 L19.000,6.000 C19.553,6.000 20.000,6.448 20.000,7.000 C20.000,7.552 19.553,8.000 19.000,8.000 ZM16.100,52.000 C17.202,52.000 18.100,52.897 18.100,54.000 C18.100,55.103 17.202,56.000 16.100,56.000 C14.997,56.000 14.100,55.103 14.100,54.000 C14.100,52.897 14.997,52.000 16.100,52.000 Z" />
  </SvgIcon>
);

export default Phone;
