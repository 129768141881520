import React from 'react';
import { Route } from 'react-router-dom';

import ViewEventAttendeeModal from './modules/modal';

function ViewEventAttendeeRouter() {
  return (
    <Route path="/:appname/members/events-attendees/:attendeeId?">
      {props => <ViewEventAttendeeModal {...props} />}
    </Route>
  );
}

export default ViewEventAttendeeRouter;
