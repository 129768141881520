import { connect } from 'react-redux';

import Design from './Design';
import { handleColorChange } from './actions';

const mapStateToProps = (state, ownProps) => {
  const designData = state.editor.design[ownProps.match.params.appname];

  if (!designData) {
    return {
      ...ownProps,
      palette: {},
    };
  }

  return {
    ...ownProps,
    ...designData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onColorChange: (value, prop) =>
    dispatch(handleColorChange(ownProps.match.params.appname, value, prop)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Design);
