import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';

import { Tabs, Tab } from '../../../../../components/tabs';
import TextField from '../../../../../../../app/components/textField';

import Container from '../../../../../../../app/components/container';

import firebase from '../../../../../../../firebase';

class PayNow extends Component {
  static propTypes = {
    appname: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    design: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
    pages: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onDesignChange: PropTypes.func.isRequired,
    onButtonDesignChange: PropTypes.func.isRequired,
  };

  state = {
    productionId: '',
    sandboxId: '',
    currency: '',
  };

  componentDidMount() {
    firebase
      .database()
      .ref(`apps/${this.props.appname}/paypal`)
      .on('value', snapshot => {
        const { productionId, sandboxId, currency } = snapshot.val() || {};
        this.setState({ productionId, sandboxId, currency });
      });
  }

  componentWillUnmount() {
    firebase
      .database()
      .ref(`apps/${this.props.appname}/paypal`)
      .off('value');
  }

  getDimensions = index =>
    this.dimensions.medium && this.dimensions.medium[index];

  handleChange = prop => value => this.props.onChange(prop, value);
  handleDesign = prop => (e, value) => this.props.onDesignChange(prop, value);
  handleChangeLinkSection = (prop, value) => this.props.onChange(prop, value);
  handleToggle = prop => (e, isToggled) => this.props.onChange(prop, isToggled);
  onRadioChange = event =>
    this.props.onChange('popupShowAs', event.target.value);

  handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    this.props.onDesignChange(prop, rgba);
  };

  dropdownNavigationOptions = () =>
    this.props.pages.map(
      (page, index) =>
        page.type === 'Popup' && (
          <MenuItem
            key={page.path}
            value={index ? page.path : '/'}
            primaryText={page.title}
          />
        ),
    );

  render() {
    const { data, appname, t } = this.props;
    const { productionId, sandboxId, currency } = this.state;

    const sectionToggle = section => null;

    return (
      <Tabs>
        <Tab label={t('content')}>
          <Container>
            {(!productionId || !sandboxId || !currency) && (
              <Link
                style={{
                  color: 'blue',
                  padding: '10px 30px',
                  fontWeight: 'bold',
                }}
                to={`/${appname}/editor/build/paypal_settings`}
              >
                <Trans>
                  You need to add a sandbox id, production id & currency for
                  paypal first! Click here to do so.
                </Trans>
              </Link>
            )}
            <TextField
              id="product"
              value={data.product}
              onChange={this.handleChange('product')}
              placeholder={t('Product Name')}
            />
            <TextField
              id="amount"
              value={data.amount}
              onChange={this.handleChange('amount')}
              placeholder={t('Amount')}
            />
          </Container>
        </Tab>
        <Tab
          label={t('design')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
        <Tab
          label={t('layout')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    );
  }
}

export default withTranslation()(PayNow);
