import { connect } from 'react-redux';
import queryString from 'query-string';
import _get from 'lodash/get';

import AccountsDropdown from './AccountsDropdown';
import {
  loadContent,
  addPageContentUpdateListner,
  removeListeners,
  checkUsersSubscription,
  addOfferNotice,
  noticeSet,
  toggleAppMenu,
  toggleUserMenu,
  changeApp,
  addIsPublishedListener,
  removeIsPublishedListener,
} from './actions';

import { launchPublishDialog } from '../../../../app/actions';
import { setOfferEligibility } from '../../../account/modules/register/actions';
import {
  toggleAccountModal,
  logout,
} from '../../../../modules/account/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    user: state.account.auth.user,
    account: state.account.organisation,
    apps: state.account.organisation.apps,
    appMenu: state.app.toolbar.appMenu,
    userMenu: state.app.toolbar.userMenu,
    profile: state.account.auth.profile,
    isSubAccount:
      (state.account.auth.profile.admin_appname &&
        state.account.auth.profile.admin_appname.length > 0) ||
      false,
    isSubAdminWithMulipleApps:
      (state.account.auth.profile.admin_appnames &&
        Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
      false,
    ...state.account.organisation.integration,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onPublish: () =>
      dispatch(launchPublishDialog(ownProps.match.params.appname)),

    removeListeners: () =>
      dispatch(removeListeners(ownProps.match.params.appname)),
    checkUsersSubscription: appName =>
      dispatch(checkUsersSubscription(appName)),
    addOfferNotice: (offer, account) =>
      dispatch(addOfferNotice(offer, account)),
    setOfferEligibility: (account, offer) =>
      setOfferEligibility(account, offer),
    noticeSet: () => dispatch(noticeSet()),

    // Merging Toolbar in Page header
    toggleAppMenu: open => dispatch(toggleAppMenu(open)),
    onAppChange: newApp =>
      dispatch(changeApp(newApp, ownProps.match.params.appname)),
    toggleUserMenu: open => dispatch(toggleUserMenu(open)),

    onLogout: () => {
      dispatch(toggleUserMenu(false));
      dispatch(logout());
    },
    onAccountSettingsTouchTap: () => {
      dispatch(toggleUserMenu(false));
      dispatch(toggleAccountModal());
    },
    onAddIsPublishedListener: () =>
      dispatch(addIsPublishedListener(ownProps.match.params.appname)),
    onRemoveIsPublishedListener: () =>
      dispatch(removeIsPublishedListener(ownProps.match.params.appname)),
    onDocumentationTouchTap: () => dispatch(toggleUserMenu(false)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsDropdown);
