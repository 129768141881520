import React from 'react';
import { Route } from 'react-router-dom';

import EditStaffModal from './modules/modal';

function EditStaffModalRouter() {
  return (
    <Route path="/:appname/members/staff/:staff?">
      {props => <EditStaffModal {...props} />}
    </Route>
  );
}

export default EditStaffModalRouter;
