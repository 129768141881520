import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TextField from '../../../../../../../app/components/textField';
import DesignToggle from '../../../../../components/designToggle';
import Container from '../../../../../../../app/components/container';
import { Tabs, Tab } from '../../../../../components/tabs';

import LayoutOption from '../../tabs/layoutTab/layoutOption';
import LayoutTab from '../../tabs/layoutTab';
import CanvaFullScreen from './CanvaFullScreen';
import CanvaMediumScreen from './CanvaMediumScreen';

import { whitelabeledHost } from '../../../../../../../utils/whitelabeledHost';

const isWhiteLabelledHost = whitelabeledHost();

const infoWrapperStyles = {
  width: '324px',
  textAlign: 'left',
  paddingLeft: '7px',
  fontSize: '12px',
  fontWeight: 600,
};

const Canva = ({
  data,
  onChange,
  component,
  onDesignChange,
  appname,
  isAppAdmin,
  isSubAdminWithMulipleApps,
}) => {
  const { t } = useTranslation();

  const canvaLayoutOption = () => {
    const style = {
      width: '100%',
      heigth: '100%',
    };
    return {
      options: [
        <LayoutOption
          key="canva_layout"
          wrapperKey="canva_layout"
          grid={2}
          tooltip={t('Change your canva component template')}
          title={t('Canva templates')}
          active={component.layout}
          items={[
            { value: 'FullScreen', icon: CanvaFullScreen, style },
            { value: 'MediumScreen', icon: CanvaMediumScreen, style },
          ]}
        />,
      ],
    };
  };
  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container>
          <TextField
            id="title"
            name="title"
            placeholder={t('Enter a heading for your widget')}
            value={data.title}
            onChange={value => onChange('title', value)}
          />
          <TextField
            id="url"
            name="url"
            placeholder={t('Canva Design ID')}
            value={data.designId}
            onChange={value => onChange('designId', value)}
          />
          <div style={infoWrapperStyles}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={
                isAppAdmin || isSubAdminWithMulipleApps || isWhiteLabelledHost
                  ? 'https://kb-articles.s3.eu-west-2.amazonaws.com/Canva+Component.pdf'
                  : 'https://beezer.zendesk.com/hc/en-us/articles/360056378891-Canva-Component'
              }
              style={{
                color: 'blue',
              }}
            >
              Find your Canva Design ID
            </a>
          </div>
        </Container>
      </Tab>
      {/* <Tab
        label={t('design')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      >
        <DesignToggle
          component={component}
          onDesignChange={(el, isToggled) =>
            onDesignChange(null)(el, isToggled)
          }
        />
        <TextField
          id="size"
          name="size"
          disabled={!component.design.enabled}
          placeholder={t('Enter size in px or vh. For full size use 100vh')}
          value={component.design.height}
          onChange={value => onDesignChange(null)('height', value)}
        />
      </Tab> */}
      <Tab label={t('layout')}>
        <LayoutTab
          componentKey={component.key}
          appname={appname}
          {...canvaLayoutOption()}
        />
      </Tab>
    </Tabs>
  );
};
Canva.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  component: PropTypes.object.isRequired,
  onDesignChange: PropTypes.func.isRequired,
  isAppAdmin: PropTypes.bool.isRequired,
  isSubAdminWithMulipleApps: PropTypes.bool.isRequired,
};

export default Canva;
