import React from 'react';

import './styles.css';

const Empty = () => (
  <svg
    className="icon empty-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 179.5 173.96"
  >
    <title>No users</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          className="cls-1"
          points="7.53 105.67 31.3 105.86 31.3 78.14 7.53 105.67"
        />
        <path
          className="cls-1"
          d="M89.46,82.4a39,39,0,1,0-39-39A39.05,39.05,0,0,0,89.46,82.4Zm14.1-50.93a2.17,2.17,0,1,1-2.17,2.17A2.17,2.17,0,0,1,103.56,31.47Zm1.8,25.12a2.2,2.2,0,0,1-3.61,2.51,14.86,14.86,0,0,0-24.09-1.16,2.19,2.19,0,0,1-3.37-2.81,19.52,19.52,0,0,1,15-7.19A19.76,19.76,0,0,1,105.36,56.59Zm-30-25.12a2.17,2.17,0,1,1-2.17,2.17A2.17,2.17,0,0,1,75.37,31.47Z"
        />
        <polygon
          className="cls-1"
          points="147.75 106.75 172.63 106.95 147.75 78.14 147.75 106.75"
        />
        <path
          className="cls-1"
          d="M104.7,123.81a3.86,3.86,0,0,1-3.85,3.82h0l-23-.18A3.85,3.85,0,0,1,74,123.58l.1-13L4.78,110l4.15,58.27,160.74,1.24,5-58.2-69.91-.54Z"
        />
        <path
          className="cls-2"
          d="M178.46,107l.07.07a2.2,2.2,0,0,1-.23,3.1,2.17,2.17,0,0,1-1.43.53,2.15,2.15,0,0,1-1.66-.76l-2.58-3-24.88-.2v1.31h-4.39v-1.34l-39.07-.3h0a3.84,3.84,0,0,0-3.84,3.81l-.1,13-22-.17.1-13a3.85,3.85,0,0,0-3.81-3.87l-39-.3v2.17H31.3v-2.2l-23.78-.19-3.68,4.26a2.15,2.15,0,0,1-1.66.76,2.17,2.17,0,0,1-1.43-.53,2.12,2.12,0,0,1-.39-.46l4.48,63L173.69,174l5.81-67Zm-8.79,62.56L8.93,168.31,4.78,110l69.29.53-.1,13a3.85,3.85,0,0,0,3.81,3.87l23,.18h0a3.86,3.86,0,0,0,3.85-3.82l.1-13,69.91.54Z"
        />
        <path
          className="cls-2"
          d="M1.77,105.63h0a1.57,1.57,0,0,0-1.58,1.68l0,.26a2.25,2.25,0,0,1,.31-.51Z"
        />
        <path
          className="cls-2"
          d="M147.75,72.25v5.89L172.63,107l5.83,0-31.9-36.94H133.22a2.2,2.2,0,0,0,0,4.39h10.14V72.25Z"
        />
        <path
          className="cls-2"
          d="M175.21,109.93a2.15,2.15,0,0,0,1.66.76,2.18,2.18,0,0,0,1.82-1,2.26,2.26,0,0,0-.23-2.73h0l-5.83,0Z"
        />
        <path
          className="cls-2"
          d="M.36,109.7l-.15-2.13A2.16,2.16,0,0,0,.36,109.7Z"
        />
        <path
          className="cls-2"
          d="M31.3,78.14V72.25h4.39v2.19h9.77a2.2,2.2,0,0,0,0-4.39h-13L1.77,105.63l5.75,0Z"
        />
        <path
          className="cls-2"
          d="M2.18,110.69a2.15,2.15,0,0,0,1.66-.76l3.68-4.26-5.75,0L.52,107.06a2.25,2.25,0,0,0-.31.51l.15,2.13a2.12,2.12,0,0,0,.39.46A2.17,2.17,0,0,0,2.18,110.69Z"
        />
        <polygon
          className="cls-2"
          points="31.3 78.14 31.3 105.86 35.69 105.89 35.69 74.44 34.5 74.44 31.3 78.14"
        />
        <polygon
          className="cls-2"
          points="31.3 108.06 35.69 108.06 35.69 105.89 31.3 105.86 31.3 108.06"
        />
        <polygon
          className="cls-2"
          points="31.3 72.25 31.3 78.14 34.5 74.44 35.69 74.44 35.69 72.25 31.3 72.25"
        />
        <polygon
          className="cls-2"
          points="147.75 78.14 144.55 74.44 143.36 74.44 143.36 106.72 147.75 106.75 147.75 78.14"
        />
        <polygon
          className="cls-2"
          points="147.75 108.06 147.75 106.75 143.36 106.72 143.36 108.06 147.75 108.06"
        />
        <polygon
          className="cls-2"
          points="147.75 78.14 147.75 72.25 143.36 72.25 143.36 74.44 144.55 74.44 147.75 78.14"
        />
        <path
          className="cls-2"
          d="M89.46,86.79a43.4,43.4,0,1,0-43.39-43.4A43.45,43.45,0,0,0,89.46,86.79Zm0-82.4a39,39,0,1,1-39,39A39.05,39.05,0,0,1,89.46,4.39Z"
        />
        <path
          className="cls-2"
          d="M74.29,55.13a2.19,2.19,0,0,0,3.37,2.81,14.86,14.86,0,0,1,24.09,1.16,2.2,2.2,0,0,0,3.61-2.51,19.76,19.76,0,0,0-16.09-8.65A19.52,19.52,0,0,0,74.29,55.13Z"
        />
        <path
          className="cls-2"
          d="M75.37,35.8a2.17,2.17,0,1,0-2.17-2.16A2.17,2.17,0,0,0,75.37,35.8Z"
        />
        <path
          className="cls-2"
          d="M103.56,35.8a2.17,2.17,0,1,0-2.17-2.16A2.16,2.16,0,0,0,103.56,35.8Z"
        />
      </g>
    </g>
  </svg>
);

export default Empty;
