import {
  NEW_MEMBER_BODY_FIELD_CHANGE,
  OPEN_NEW_MEMBER_BODY_DIALOG,
  CLOSE_NEW_MEMBER_BODY_DIALOG,
  ADD_NEW_MEMBER_BODY,
  NEW_MEMBER_BODY_ADDED,
  GET_AVAILABLE_STAFF,
  NEW_MEMBER_BODY_STAFF_FIELD_CHANGE,
  LOADED_BANNER_DETAILS,
} from './actions';

const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  name: {
    value: '',
    isValid: null,
  },
  postalAddress: {
    value: '',
    isValid: null,
  },
  postCode: {
    value: '',
    isValid: null,
  },
  adminUsername: {
    value: '',
    isValid: null,
  },
  adminEmail: {
    value: '',
    isValid: null,
  },
  primaryContactName: {
    value: '',
    isValid: null,
  },
  primaryContactEmail: {
    value: '',
    isValid: null,
  },
  primaryContactNumber: {
    value: '',
    isValid: null,
  },
  secondaryContactName: {
    value: '',
    isValid: null,
  },
  secondaryContactEmail: {
    value: '',
    isValid: null,
  },
  secondaryContactNumber: {
    value: '',
    isValid: null,
  },
  landline: {
    value: '',
    isValid: null,
  },
  mobile: {
    value: '',
    isValid: null,
  },
  memberNumber: {
    value: '',
    isValid: null,
  },
  memberNumberMale: {
    value: '',
    isValid: null,
  },
  memberNumberFemale: {
    value: '',
    isValid: null,
  },
  memberNumberDisabled: {
    value: '',
    isValid: null,
  },
  memberNumberSmid: {
    value: '',
    isValid: null,
  },
  staffMembers: {},
};

const newMemberBodyReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_NEW_MEMBER_BODY_DIALOG:
      return {
        ...state,
        open: true,
      };

    case CLOSE_NEW_MEMBER_BODY_DIALOG:
      return {
        ...state,
        open: false,
      };

    case NEW_MEMBER_BODY_FIELD_CHANGE:
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };
    case NEW_MEMBER_BODY_STAFF_FIELD_CHANGE:
      return {
        ...state,
        [action.prop]: action.value,
      };

    case ADD_NEW_MEMBER_BODY:
      return {
        ...state,
        pending: true,
      };

    case NEW_MEMBER_BODY_ADDED:
      return initialState;

    case LOADED_BANNER_DETAILS:
      return {
        ...state,
        banner: action.value,
      };

    default:
      return state;
  }
};

export default newMemberBodyReducer;
