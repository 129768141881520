import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroup, RadioButton } from 'material-ui/RadioButton';
import { Trans, withTranslation } from 'react-i18next';

import Container from 'app/components/container';
import TextEditor from 'app/components/textEditor';
import Toggle from 'app/components/toggle';
import Range from 'app/components/range';
import Subtitle from 'app/components/subtitle';

import { Tabs, Tab } from 'modules/editor/components/tabs';
import SectionHeader from 'modules/editor/components/sectionHeader';
import ButtonDesignMenu from 'modules/editor/components/buttonDesignMenu';
import ButtonLinkMenu from 'modules/editor/components/buttonLinkMenu';
import ImageDesignMenu from 'modules/editor/components/imageDesignMenu';
import ColorPicker from 'modules/editor/components/colorPicker';
import DesignToggle from 'modules/editor/components/designToggle';
import ImageUpload from 'modules/editor/internals/imageUpload';

import LayoutTab from '../../tabs/layoutTab';
import LayoutOption from '../../tabs/layoutTab/layoutOption';
import headerSmall from './headerSmall';
import headerMedium from './headerMedium';
import headerLarge from './headerLarge';
import headerFullScreen from './headerFullScreen';
import FullScreen from './desktop/fullscreen';
import TwoThird from './desktop/twoThird';
import Half from './desktop/half';
import ColumnImageLeft from './desktop/columnImageLeft';
import ColumnImageRight from './desktop/columnImageRight';
import TwoThirdImageLeft from './desktop/twoThirdImageLeft';
import TwoThirdImageRight from './desktop/twoThirdImageRight';
import OneThirdCenter from './desktop/oneThirdCenter';
import TwoThirdCenter from './desktop/twoThirdCenter';
import themeStyles from '../../../../../../../_export.scss';

class Header extends Component {
  static propTypes = {
    appname: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    design: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
    pages: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    googleAnalytics: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onDesignChange: PropTypes.func.isRequired,
    onLoadGoogleAnalytics: PropTypes.func.isRequired,
    onButtonDesignChange: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    this.props.onLoadGoogleAnalytics();
  }

  getDimensions = index =>
    this.dimensions.medium && this.dimensions.medium[index];
  s;

  handleChange = prop => value => this.props.onChange(prop, value);
  handleDesign = prop => (e, value) => this.props.onDesignChange(prop, value);
  handleChangeLinkSection = (prop, value) => this.props.onChange(prop, value);
  handleToggle = prop => (e, isToggled) => this.props.onChange(prop, isToggled);

  handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    this.props.onDesignChange(prop, rgba);
  };

  headerLayoutsOptions = () => {
    const { t } = this.props;
    const style = {
      width: '100%',
      heigth: '100%',
    };
    return {
      options: [
        <LayoutOption
          key="header_layout"
          wrapperKey="header_layout"
          grid={2}
          tooltip={t('Change your mobile header component template')}
          title={t('Mobile Header templates')}
          active={this.props.component.layout}
          items={[
            {
              value: 'FullScreen',
              icon: headerFullScreen,
              style,
              size: '430px by 873px',
            },
            {
              value: 'Large',
              icon: headerLarge,
              style,
              size: '430px by 699px',
            },
            {
              value: 'Medium',
              icon: headerMedium,
              style,
              size: '430px by 466px',
            },
            {
              value: 'Small',
              icon: headerSmall,
              style,
              size: '430px by 279px',
            },
          ]}
        />,
        <LayoutOption
          type="desktop"
          key="header_layout"
          wrapperKey="header_layout"
          grid={1}
          tooltip={t('Change your desktop header component template')}
          title={t('Desktop Header templates')}
          active={this.props.component.desktopLayout}
          items={[
            {
              value: 'Desktop_FullScreen',
              icon: FullScreen,
              style,
              size: '1630px by 730px',
            },
            {
              value: 'Desktop_TwoThird',
              icon: TwoThird,
              style,
              size: '1630px by 635px',
            },
            {
              value: 'Desktop_Half',
              icon: Half,
              style,
              size: ' 1630px by  423px',
            },
            {
              value: 'Desktop_ColumnImageLeft',
              icon: ColumnImageLeft,
              style,
              size: '827px by 730px',
            },
            {
              value: 'Desktop_ColumnImageRight',
              icon: ColumnImageRight,
              style,
              size: '827px by 730px',
            },
            {
              value: 'Desktop_TwoThirdImageLeft',
              icon: TwoThirdImageLeft,
              style,
              size: '992px by 730px',
            },
            {
              value: 'Desktop_TwoThirdImageRight',
              icon: TwoThirdImageRight,
              style,
              size: '992px by 730px',
            },
            {
              value: 'Desktop_OneThirdCenter',
              icon: OneThirdCenter,
              style,
              size: '1630px by 730px',
            },
            {
              value: 'Desktop_TwoThirdCenter',
              icon: TwoThirdCenter,
              style,
              size: '1630px by 730px',
            },
          ]}
        />,
      ],
    };
  };

  render() {
    const borderStyles = [
      'Solid',
      'Double',
      'Dotted',
      'Dashed',
      'Ridge',
      'Groove',
      'Inset',
      'Outset',
    ];

    const {
      appname,
      data,
      component,
      design,
      pages,
      match,
      onDesignChange,
      onButtonDesignChange,
      t,
    } = this.props;

    const buttonToggle = (
      <Toggle
        onToggle={this.handleDesign('/buttonDesign/enabled')}
        toggled={component.design.buttonDesign.enabled}
        disabled={!component.design.enabled}
      />
    );
    const toolbarButtons = {
      fonts: true,
      fontSize: true,
      bold: true,
      italic: true,
      underline: true,
      url: true,
      image: true,
      video: true,
      orderedList: true,
      unorderedList: true,
      negativeIntent: true,
      positiveIndent: true,
      textAlign: true,
      textColor: true,
      textBackground: true,
      clearTextFormatting: true,
      codeBlock: true,
      blockquote: true,
    };

    return (
      <Tabs>
        <Tab label={t('content')}>
          <Container>
            <SectionHeader
              title={t('Mobile Image / Video Upload')}
              tooltip={t('This image/video will show up for mobile users')}
            />
            <ImageUpload
              targetId="header_component_upload"
              title={t('Media Upload')}
              src={data.image}
              firebaseUrl={`/apps/${match.params.appname}/draft/lazy_data/${component.lazy_data}/image`}
              showCropAreaSizes
              height="220px"
              width="320px"
              isVideoAllowed
            />
            <SectionHeader
              title={t('Desktop Image / Video Upload')}
              tooltip={t('This image/video will show up for desktop users')}
            />
            <ImageUpload
              targetId="desktop_header_component_upload"
              title={t('Desktop Media Upload')}
              src={data.desktopImage}
              firebaseUrl={`/apps/${match.params.appname}/draft/lazy_data/${component.lazy_data}/desktopImage`}
              showCropAreaSizes
              height="220px"
              width="320px"
              isVideoAllowed
            />
            <SectionHeader
              title={t('Mobile Title')}
              tooltip={t('A caption for background image for mobile users')}
              borderTop
            />
            <TextEditor
              id="title"
              value={data.title}
              onChange={this.handleChange('title')}
              placeholder={t('Mobile Title')}
              toolbar={toolbarButtons}
            />
            <SectionHeader
              title={t('Mobile Subtitle')}
              tooltip={t('A caption for background image for mobile users')}
              borderTop
            />
            <TextEditor
              id="subtitle"
              value={data.subtitle}
              onChange={this.handleChange('subtitle')}
              placeholder={t('Mobile Subtitle')}
              toolbar={toolbarButtons}
            />

            <div style={{ margin: '50px 0px' }}>
              <div style={{ marginBottom: '20px' }}>
                <SectionHeader
                  title={t('Enable Desktop Title & Subtitle')}
                  enabled={data.desktopEnabled || false}
                  actions={
                    <Toggle
                      toggled={data.desktopEnabled}
                      onToggle={this.handleToggle('desktopEnabled')}
                      trackSwitchedStyle={{
                        backgroundColor: themeStyles.primaryColorLight,
                      }}
                      thumbSwitchedStyle={{
                        backgroundColor: themeStyles.primaryColor,
                      }}
                    />
                  }
                  tooltip={t('Add title & subtitle for desktop users')}
                  borderTop
                  borderBottom
                />
              </div>
              {data.desktopEnabled && (
                <React.Fragment>
                  <SectionHeader
                    title={t('Desktop Title')}
                    tooltip={t(
                      'A caption for background image for desktop users',
                    )}
                    borderTop
                    enabled={data.desktopEnabled || false}
                  />
                  <TextEditor
                    id="desktopTitle"
                    value={
                      data.desktopTitle ||
                      `<p>${t(
                        "Click on the editor pane on the left to start typing the 'Title' you want to",
                      )} <strong>${t('display')}</strong>! ${t(
                        'and ensure you click on',
                      )} <strong>'Save'</strong> ${t('once done!')}</p>`
                    }
                    onChange={this.handleChange('desktopTitle')}
                    placeholder={t('Desktop Title')}
                    toolbar={toolbarButtons}
                    disabled={!data.desktopEnabled}
                  />
                  <SectionHeader
                    title={t('Desktop Subtitle')}
                    tooltip={t(
                      'A caption for background image for desktop users',
                    )}
                    borderTop
                    enabled={data.desktopEnabled || false}
                  />
                  <TextEditor
                    id="desktopSubtitle"
                    value={
                      data.desktopSubtitle ||
                      `<p>${t(
                        "Click on the editor pane on the left to start typing the 'Subtitle' you want to",
                      )} <strong>${t('display')}</strong>! ${t(
                        'and ensure you click on',
                      )} <strong>'${t('Save')}'</strong> ${t('once done!')}</p>`
                    }
                    onChange={this.handleChange('desktopSubtitle')}
                    placeholder={t('Desktop Subtitle')}
                    toolbar={toolbarButtons}
                    disabled={!data.desktopEnabled}
                  />
                </React.Fragment>
              )}
            </div>

            <ButtonLinkMenu
              link={data.link}
              pages={pages}
              buttonOptional
              borderTop
              onChange={this.handleChangeLinkSection}
              lazyData={component.lazy_data}
              googleAnalytics={this.props.googleAnalytics}
              gaEnabled={this.props.data.gaEnabled}
              gaCategory={this.props.data.gaCategory}
              gaAction={this.props.data.gaAction}
            />
          </Container>
        </Tab>
        <Tab label={t('design')}>
          <Container>
            <DesignToggle
              component={component}
              onDesignChange={onDesignChange}
            />
            <ColorPicker
              label={t('Text Color')}
              color={component.design.textColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={this.handleColorChange('textColor')}
            />
            <ColorPicker
              label={t('Background Color')}
              color={design.backgroundColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={this.handleColorChange('backgroundColor')}
            />
            <ColorPicker
              label={t('Border Color')}
              color={design.borderColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={this.handleColorChange('borderColor')}
            />
            <Subtitle className={!component.design.enabled}>
              <Trans>Border Width</Trans>
            </Subtitle>
            <Range
              min={0}
              max={100}
              step={1}
              label={
                !design.borderWidth || design.borderWidth === 0
                  ? '0px'
                  : `${design.borderWidth}px`
              }
              value={design.borderWidth}
              onChange={(el, val) => onDesignChange('borderWidth', val)}
              disabled={!component.design.enabled}
              width="320px"
            />
            <Subtitle className={!component.design.enabled}>
              <Trans>Border Style</Trans>
            </Subtitle>
            <RadioButtonGroup
              className="radio-button-group"
              label={t('Border Style')}
              style={{
                padding: '0 10px',
                margin: '0 auto',
                flexDirection: 'column',
              }}
              name="background-type"
              onChange={(el, val) => onDesignChange('borderStyle', val)}
              valueSelected={design.borderStyle}
            >
              {borderStyles.map(style => (
                <RadioButton
                  key={style}
                  value={style.toLowerCase()}
                  label={style}
                  style={{
                    fontSize: 12,
                    lineHeight: 22,
                  }}
                  disabled={!component.design.enabled}
                />
              ))}
            </RadioButtonGroup>
            <ImageDesignMenu
              component={component}
              onDesignChange={onDesignChange}
            />
            <SectionHeader
              title={t('Button')}
              actions={buttonToggle}
              tooltip={t('Define the styles for your button')}
              borderTop
            />
            <ButtonDesignMenu
              component={component}
              onDesignChange={onButtonDesignChange}
            />
          </Container>
        </Tab>
        <Tab label={t('layout')}>
          <LayoutTab
            componentKey={component.key}
            appname={appname}
            {...this.headerLayoutsOptions()}
          />
        </Tab>
      </Tabs>
    );
  }
}

export default withTranslation()(Header);
