import { connect } from 'react-redux';

import RedeemBonus from './RedeemBonus';
import { openDialog, redeemConfirmation, closeDialog, fetchBonusPoints } from './actions';
import { fetchRedeemHistory } from '../redeemHistory/actions';

const mapStateToProps = state => ({
    ...state.account.referral.redeemBonus,
    intl: state.account.intl,
    hasCard: state.account.payment.useDefaultCard
});

const mapDispatchToProps = dispatch => ({
    redeemConfirmation: () => dispatch(redeemConfirmation()),
    openDialog: () => dispatch(openDialog()),
    closeDialog: () => dispatch(closeDialog()),
    fetchBonusPoints: () => dispatch(fetchBonusPoints()),
    fetchRedeemHistory: () => dispatch(fetchRedeemHistory())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RedeemBonus);
