import React from 'react';

const Booking = () => {
  return (
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080.000000 1080.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <title>Booking component</title>

      <g
        transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path d="M1853 10121 c-26 -12 -52 -35 -70 -62 l-28 -43 -3 -4605 -2 -4605 21 -43 c13 -27 38 -55 62 -71 l41 -27 3526 0 3526 0 41 27 c24 16 49 44 62 71 l21 43 -2 4605 -3 4605 -28 43 c-18 27 -44 50 -70 62 -41 18 -125 19 -3547 19 -3422 0 -3506 -1 -3547 -19z m987 -4721 l0 -4370 -360 0 -360 0 0 4370 0 4370 360 0 360 0 0 -4370z m3650 3258 c0 -1072 1 -1115 19 -1155 36 -78 141 -121 227 -93 29 10 96 69 263 232 l224 219 216 -215 c138 -137 229 -221 253 -230 79 -33 172 -8 224 60 l29 37 3 1129 2 1128 365 0 365 0 0 -4370 0 -4370 -2730 0 -2730 0 0 4370 0 4370 1635 0 1635 0 0 -1112z m1090 250 l0 -861 -124 122 c-127 124 -168 151 -231 151 -65 0 -107 -27 -237 -158 l-128 -127 0 868 0 867 360 0 360 0 0 -862z" />
      </g>
    </svg>
  );
};

export default Booking;
