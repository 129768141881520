import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import * as yup from 'yup';
import { toggleFkAcademyUserDialog, setFkAcademyUserId } from '../../actions';
import ErrorMessage from '../../../../app/components/errorMessage';
import {
  getAllFkAcademyUsers,
  getFkAcademyUser,
  updateFkAcademyAppUser,
} from '../../api';
import { ProgressOverlay } from '../../../../app/components/progress';
import TryAgain from '../../../../app/components/tryAgain';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';

import styles from './styles.module.scss';

const approvalRegex = /^granted$|^denied$/;

const schema = yup.object().shape({
  allowedSlots: yup
    .number()
    .positive('available slots must be a positive number')
    .integer()
    .required('available slots should be 1 or greater')
    .typeError('available slots should be 1 or greater'),
  fkAcademyApprovalStatus: yup
    .string()
    .matches(approvalRegex, 'User needs to be approved or disapproved')
    .required(),
});

function UsersDialog({ match }) {
  const dispatch = useDispatch();
  const { appname } = match.params;
  const queryClient = useQueryClient();
  const {
    mutateAsync,
    isLoading: isSubmitting,
  } = useMutation(({ appname, userId, data }) =>
    updateFkAcademyAppUser(appname, userId, data),
  );
  const fkUserId = useSelector(({ fkAcademy }) => fkAcademy.fkUserId);
  const fkUserDialogOpen = useSelector(
    ({ fkAcademy }) => fkAcademy.fkUserDialogOpen,
  );

  async function onSubmit(data) {
    if (user.fkAcademyUserType !== 'parent') delete data.allowedSlots;
    try {
      await mutateAsync({
        appname,
        data,
        userId: fkUserId,
      });
    } catch (error) {
      dispatch(openSnackbar('Something went wrong. Try again.'));
      return;
    }
    queryClient.invalidateQueries('appUsers');
    reset({
      allowedSlots: 1,
    });
    dispatch(openSnackbar(`User updated successfully!`));
    dispatch(setFkAcademyUserId(''));
    dispatch(toggleFkAcademyUserDialog(false));
  }

  const { data: { data: user } = {}, error, isLoading, isError } = useQuery(
    ['fkAcademyUser', fkUserId],
    () => getFkAcademyUser(appname, fkUserId),
    { enabled: !!(fkUserId && appname) },
  );

  const defaultValues = {
    allowedSlots: (user && user.allowedSlots) || 1,
    fkAcademyApprovalStatus: user && user.fkAcademyApprovalStatus,
  } || {
    allowedSlots: 1,
    fkAcademyApprovalStatus: 'pending',
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const fkAcademyApprovalStatus = watch('fkAcademyApprovalStatus');

  useEffect(() => {
    if (user) reset(defaultValues);
  }, [user]);

  if (isLoading) return <ProgressOverlay />;

  if (isError) return <TryAgain message={error.message} />;

  return (
    <Dialog
      open={fkUserDialogOpen}
      onClose={() => dispatch(toggleFkAcademyUserDialog(false))}
    >
      <DialogContent>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={styles.h2}>
            {(user && user.firstName) || ''} {(user && user.lastName) || ''}
          </h2>
          {user && user.fkAcademyUserType === 'parent' && (
            <div>
              <label htmlFor="allowedSlots">Allowed Slots</label>
              <input {...register('allowedSlots')} />
              <ErrorMessage>
                {errors && errors.allowedSlots && errors.allowedSlots.message}
              </ErrorMessage>
            </div>
          )}
          <div>
            <div className={styles.appoval_status_buttons}>
              <span
                className={styles.approve}
                onClick={() =>
                  setValue('fkAcademyApprovalStatus', 'granted', {
                    shouldValidate: true,
                  })
                }
              >
                Approve
              </span>
              <span
                className={styles.disapprove}
                onClick={() =>
                  setValue('fkAcademyApprovalStatus', 'denied', {
                    shouldValidate: true,
                  })
                }
              >
                Disapprove
              </span>
              {fkAcademyApprovalStatus === 'granted' && (
                <p>
                  {(user && user.firstName) || ''}{' '}
                  {(user && user.lastName) || ''} will be approved.
                </p>
              )}
              {fkAcademyApprovalStatus === 'denied' && (
                <p>
                  {(user && user.firstName) || ''}{' '}
                  {(user && user.lastName) || ''} will be disapproved.
                </p>
              )}
            </div>
            <ErrorMessage>
              {errors &&
                errors.fkAcademyApprovalStatus &&
                errors.fkAcademyApprovalStatus.message}
            </ErrorMessage>
          </div>

          <input
            className={styles.save}
            type="submit"
            value={isSubmitting ? 'SAVING...' : 'SAVE'}
            disabled={isSubmitting}
          />
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(UsersDialog);
