import firebase from '../../../../../firebase';

export const requestLogout = () => ({
  type: 'LOGOUT_REQUEST',
});

export const logoutError = err => ({
  type: 'LOGOUT_ERROR',
  err,
});

export const receiveLogout = () => ({
  type: 'LOGOUT_SUCCESS',
});

/**
 * LOGOUT
 * Calls firebase logout.
 * Next step is handled by startListeningToAuthChange
 */
export const logout = () => dispatch => {
  dispatch(requestLogout());

  return firebase
    .auth()
    .signOut()
    .catch(err => dispatch(logoutError(err)));
};
