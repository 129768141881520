import firebase from '../../../../../../../firebase';

export const cycleChange = (appname, dataId, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('cycle').set(value);

  return {
    type: 'CYCLE_VALUE_CHANGE',
    appname,
    dataId,
    value,
  };
};

export const titleChange = (appname, dataId, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('title').set(value);

  return {
    type: 'TITLE_VALUE_CHANGE',
    appname,
    dataId,
    value,
  };
};

export const changeSettingsStatus = (appname, dataId, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('allow_settings').set(value);

  return {
    type: 'SETTINGS_VALUE_CHANGE',
    appname,
    dataId,
    value,
  };
};

export const setsChange = (appname, dataId, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('sets').set(value);

  return {
    type: 'SETS_VALUE_CHANGE',
    appname,
    dataId,
    value,
  };
};

export const warmupChange = (appname, dataId, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('warmup').set(value);

  return {
    type: 'WARMUP_VALUE_CHANGE',
    appname,
    dataId,
    value,
  };
};

export const exerciseChange = (appname, dataId, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('exercise').set(value);

  return {
    type: 'EXERCISE_VALUE_CHANGE',
    appname,
    dataId,
    value,
  };
};

export const restChange = (appname, dataId, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('rest').set(value);

  return {
    type: 'REST_VALUE_CHANGE',
    appname,
    dataId,
    value,
  };
};
