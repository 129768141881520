import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Delegates from './Delegates';
import { addDelegatesItem, rearrangeItems } from './actions';
import { launchRemoveDialog } from '../../remove/actions';

const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddItem: title =>
    dispatch(
      addDelegatesItem(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        title,
      ),
    ),
  onRemoveItem: item =>
    dispatch(
      launchRemoveDialog(
        ownProps.match.params.appname,
        {
          ...queryString.parse(ownProps.location.search),
          item,
        },
        'ITEM',
      ),
    ),
  rearrangeItems: (id, siblingId) =>
    dispatch(
      rearrangeItems(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        id,
        siblingId,
      ),
    ),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Delegates),
);
