import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EnhancedButton from 'material-ui/internal/EnhancedButton';

import './styles.css';

const getClassName = (active, border, disabled, className) =>
  `menu-item${active ? ' active' : ''}${border ? ' border' : ''}${
    disabled ? ' disabled' : ''
  } ${className}`;

const SidebarItem = ({
  label,
  icon,
  href,
  border,
  active,
  rightAction,
  leftAction,
  displayRightAction,
  displayLeftAction,
  enabled,
  className,
}) => (
  <EnhancedButton
    containerElement={
      enabled ? (
        <Link
          to={href}
          className={getClassName(active, border, !enabled, className)}
        />
      ) : (
        <span className={getClassName(active, border, !enabled, className)} />
      )
    }
  >
    {displayLeftAction && leftAction && (
      <span className="menu-item-left-action">{leftAction}</span>
    )}
    {displayLeftAction && leftAction ? (
      <div className="flex-column flex-align-center">
        <span className="menu-item-icon-container">{icon}</span>
        <span className="menu-item-label">{label}</span>
      </div>
    ) : (
      <>
        <span className="menu-item-icon-container">{icon}</span>
        <span className="menu-item-label">{label}</span>
      </>
    )}
    {displayRightAction && rightAction && (
      <span className="menu-item-right-action">{rightAction}</span>
    )}
  </EnhancedButton>
);

SidebarItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  rightAction: PropTypes.node,
  leftAction: PropTypes.node,
  href: PropTypes.string.isRequired,
  border: PropTypes.bool,
  active: PropTypes.bool,
  displayLeftAction: PropTypes.bool,
  displayRightAction: PropTypes.bool,
  enabled: PropTypes.bool,
};

SidebarItem.defaultProps = {
  icon: null,
  rightAction: null,
  leftAction: null,
  border: false,
  active: false,
  displayRightAction: true,
  displayLeftAction: true,
  enabled: true,
};

export default SidebarItem;
