import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Vendors from './Vendors';
import {
  getVendors,
  getAllVendorReports,
  stopGetVendors,
  filterVendorData,
  selectVendor,
  selectAllVendors,
  deselectAllVendors,
  deleteSelected,
  emailSelected,
  sortTableData,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.ecommerce.marketplace.vendors,
  ...state.account.profile,
  vendorApproval:
    state.ecommerce.marketplace.settings.data.marketplace_settings
      .vendorApproval,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAllVendorReports: () =>
    dispatch(getAllVendorReports(ownProps.match.params.appname)),
  getVendors: () => dispatch(getVendors(ownProps.match.params.appname)),
  stopGetVendors: () => dispatch(stopGetVendors(ownProps.match.params.appname)),
  onFilterVendorData: filter => dispatch(filterVendorData(filter)),
  onSort: cols => dispatch(sortTableData(cols)),
  onSelect: key => dispatch(selectVendor(key)),
  onSelectAllVendors: () => dispatch(selectAllVendors()),
  onDeselectAllVendors: () => dispatch(deselectAllVendors()),
  onDeleteSelected: (selected, chunks) =>
    dispatch(deleteSelected(ownProps.match.params.appname, selected, chunks)),
  onEmailSelected: (selected, chunks, emailFields) =>
    dispatch(
      emailSelected(
        ownProps.match.params.appname,
        selected,
        chunks,
        emailFields,
      ),
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Vendors),
);
