import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import EditAdmin from './EditAdmin';

import {
  updateAccount,
  closeModal,
  formFieldChange,
  getAdminDetails,
  updateSubAdminSectionAccess,
} from './actions';
import { getDefaultWhatsappCredits } from '../../../../../../../../../utils';

const mapStateToProps = (state, ownProps) => {
  const isAppAdmin =
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false;
  const trialExpired = state.account.organisation.trial_expired;
  const appSubscription = state.app.appContext.subscription;
  let whatsappSMSCredits = state.account.organisation.whatsapp_sms_credit;
  //if whatsapp credits does not exists on account get the default amount
  if (whatsappSMSCredits === undefined || whatsappSMSCredits === null) {
    whatsappSMSCredits = getDefaultWhatsappCredits({
      isAppAdmin,
      trialExpired,
      appSubscription,
    });
  }

  return {
    ...state.editor.build.appAdmins.editAdmin,
    match: ownProps.match,
    chunks: state.editor.build.appAdmins.data.chunks,
    smsCredits: state.account.organisation.sms_credit,
    whatsappSMSCredits,
    apps: state.account.organisation.apps,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAdminDetails: () => dispatch(getAdminDetails(ownProps.match.params.admin)),
  onSubmit: () =>
    dispatch(
      updateAccount(
        ownProps.match.params.admin,
        ownProps.history,
        ownProps.match.params.appname,
      ),
    ),
  onRequestClose: () =>
    dispatch(closeModal(ownProps.match.params.appname, ownProps.history)),
  onFieldChange: (field, value) => dispatch(formFieldChange(field, value)),
  updateSubAdminSectionAccess: (sectionName, value) =>
    dispatch(updateSubAdminSectionAccess(sectionName, value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditAdmin),
);
