import firebase from '../../../../../firebase';
import { validateField } from '../validators';
import { STAFF_ADMIN } from '../../../../../config';
import { openSnackbar } from '../../../../../app/modules/snackbar/actions';
import { PROFILE_CREATED } from '../config';

export const NEW_STAFF_FIELD_CHANGE = 'NEW_STAFF_FIELD_CHANGE';
export const OPEN_NEW_STAFF_DIALOG = 'OPEN_NEW_STAFF_DIALOG';
export const CLOSE_NEW_STAFF_DIALOG = 'CLOSE_NEW_STAFF_DIALOG';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const ADD_NEW_STAFF = 'ADD_NEW_STAFF';
export const NEW_STAFF_ADDED = 'NEW_STAFF_ADDED';

const newStaffFieldChange = (prop, value, isValid) => ({
  type: NEW_STAFF_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

export const openNewStaffDialog = () => ({ type: OPEN_NEW_STAFF_DIALOG });

export const closeNewStaffDialog = () => ({ type: CLOSE_NEW_STAFF_DIALOG });

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;

  return dispatch(newStaffFieldChange(prop, value, isValid));
};

export const registrationError = err => ({
  type: REGISTRATION_ERROR,
  err,
});

export const createAccount = (
  firstName,
  lastName,
  email,
  password,
  parentAccount,
  appName,
) =>
  new Promise((resolve, reject) => {
    const { currentUser } = firebase.auth();
    return currentUser.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);
      return fetch(`${process.env.REACT_APP_STAFF_API}/create`, {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
          parentOwner: currentUser.uid,
          role: STAFF_ADMIN,
          parentAccount,
          appName,
        }),
        headers,
      })
        .then(res => {
          res.json().then(data => {
            resolve(data);
          });
        })
        .catch(reject);
    });
  });

export const addStaff = appName => (dispatch, getState) => {
  const {
    firstName,
    lastName,
    email,
    password,
  } = getState().auth.staff.newStaff;
  const parentAccount = getState().account.auth.profile.account;
  dispatch({ type: ADD_NEW_STAFF });

  return createAccount(
    firstName.value,
    lastName.value,
    email.value,
    password.value,
    parentAccount,
    appName,
  )
    .then(res => {
      if (!res.uid) {
        return dispatch(registrationError(res.message));
      }
      dispatch({
        type: NEW_STAFF_ADDED,
        key: res.uid,
        data: {
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
        },
      });
      return dispatch(openSnackbar(PROFILE_CREATED));
    })
    .catch(error => dispatch(registrationError(error.message)));
};
