import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import FieldsVisibility from './FieldsVisibility';

import { updateField } from './actions';

const mapStateToProps = (state, ownProps) => {
  const defaultGroup = state.auth.groups.data.chunks.default;
  return {
    ...ownProps,
    profileFields: defaultGroup ? defaultGroup.profileFields : true,
    landlineVisible: defaultGroup ? defaultGroup.landlineVisible : false,
    genderVisible: defaultGroup ? defaultGroup.genderVisible : false,
    disabilityVisible: defaultGroup ? defaultGroup.disabilityVisible : false,
    ethnicGroupVisible: defaultGroup ? defaultGroup.ethnicGroupVisible : false,
    religionVisible: defaultGroup ? defaultGroup.religionVisible : false,
    sexualOrientationVisible: defaultGroup
      ? defaultGroup.sexualOrientationVisible
      : false,
    postalAddressVisible: defaultGroup
      ? defaultGroup.postalAddressVisible
      : false,
    postCodeVisible: defaultGroup ? defaultGroup.postCodeVisible : false,
    localAuthorityVisible: defaultGroup
      ? defaultGroup.localAuthorityVisible
      : false,
    dobVisible: defaultGroup ? defaultGroup.dobVisible : false,
    organisationVisible: defaultGroup
      ? defaultGroup.organisationVisible
      : false,
    roleVisible: defaultGroup ? defaultGroup.roleVisible : false,
    linkedInAddressVisible: defaultGroup
      ? defaultGroup.linkedInAddressVisible
      : false,
    twitterAddressVisible: defaultGroup
      ? defaultGroup.twitterAddressVisible
      : false,
    websiteAddressVisible: defaultGroup
      ? defaultGroup.websiteAddressVisible
      : false,
    facebookAddressVisible: defaultGroup
    ? defaultGroup.facebookAddressVisible
    : false,
    interestsVisible: defaultGroup ? defaultGroup.interestsVisible : false,
    genderReassignmentVisible: defaultGroup ? defaultGroup.genderReassignmentVisible : false,
    leaveTypeVisible: defaultGroup ? defaultGroup.leaveTypeVisible : false,
    martialStatusVisible: defaultGroup ? defaultGroup.martialStatusVisible : false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateField: (value, field) =>
    dispatch(updateField(ownProps.match.params.appname, value, field)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FieldsVisibility),
);
