import React from 'react';

const VimeoIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 77.79 39.09"
  >
    <title>vimeo component</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          class="cls-1"
          points="9.28 32.41 0 23.13 9.28 13.85 10.34 14.91 2.12 23.13 10.34 31.35 9.28 32.41"
        />
        <polygon
          class="cls-1"
          points="68.5 32.41 67.44 31.35 75.67 23.13 67.44 14.91 68.5 13.85 77.79 23.13 68.5 32.41"
        />
        <path
          class="cls-1"
          d="M36.75,39.09a5.32,5.32,0,0,1-3.82-1.64,14.45,14.45,0,0,1-3.6-6.5c-.8-2.8-1.57-5.62-2.33-8.43L25.76,18a20.51,20.51,0,0,0-1.51-4c-.14-.25-.28-.5-.44-.74-.81-1.2-1.06-1.25-2.27-.5-.54.34-1.08.69-1.6,1.06a1.16,1.16,0,0,1-1.75-.25c-.44-.6-.9-1.18-1.37-1.76l-.09-.11A1.16,1.16,0,0,1,16.86,10C17.92,9.08,19,8.13,20,7.21A56.39,56.39,0,0,1,26.1,2.1,7.43,7.43,0,0,1,30.58.49a5.29,5.29,0,0,1,4.29,2.27,11.82,11.82,0,0,1,1.89,5.12c.15.89.29,1.78.44,2.68.47,3,1,6.07,1.72,9a14.53,14.53,0,0,0,1.3,3.56c.41.74.69.82.77.83s.49-.18.83-.54a24.33,24.33,0,0,0,5.23-8.62,5.27,5.27,0,0,0,.2-2,1.66,1.66,0,0,0-1.73-1.77,6.53,6.53,0,0,0-2.7.36,1.16,1.16,0,0,1-1.27-.16A1.15,1.15,0,0,1,41.38,10c1.21-3.54,2.72-5.87,4.92-7.57A11.43,11.43,0,0,1,56.35.37C58.94,1,60.48,2.83,61.07,6c.08.44.13.9.17,1.33l.07.59a16.24,16.24,0,0,1-1.2,6.46A43,43,0,0,1,54.69,24a59.94,59.94,0,0,1-9.95,11.39A15.52,15.52,0,0,1,38,39,6.14,6.14,0,0,1,36.75,39.09Zm-14-28.34c.85,0,1.56.54,2.31,1.66a9.68,9.68,0,0,1,.52.88,20.83,20.83,0,0,1,1.63,4.29c.42,1.52.83,3,1.24,4.55.77,2.8,1.53,5.61,2.33,8.41A13,13,0,0,0,34,36.38a3.87,3.87,0,0,0,3.71,1.11,13.85,13.85,0,0,0,6.08-3.27A58.82,58.82,0,0,0,53.47,23.1a42,42,0,0,0,5.25-9.22A15,15,0,0,0,59.81,8l-.06-.5c0-.4-.08-.81-.16-1.22-.47-2.6-1.61-4-3.58-4.48a10,10,0,0,0-8.79,1.79,13,13,0,0,0-4.17,6.16,7.9,7.9,0,0,1,2.59-.23,3.16,3.16,0,0,1,3.11,3.19,6.61,6.61,0,0,1-.29,2.59,25.72,25.72,0,0,1-5.55,9.15,2.48,2.48,0,0,1-2.1,1,2.66,2.66,0,0,1-1.9-1.58A16.11,16.11,0,0,1,37.47,20c-.78-3-1.27-6.16-1.76-9.18-.14-.89-.28-1.78-.43-2.66a10.44,10.44,0,0,0-1.63-4.49A3.82,3.82,0,0,0,30.52,2,5.92,5.92,0,0,0,27,3.34a57,57,0,0,0-6,5c-.95.87-1.93,1.77-2.93,2.62.4.51.81,1,1.2,1.54.51-.36,1-.68,1.5-1A3.9,3.9,0,0,1,22.74,10.75Z"
        />
      </g>
    </g>
  </svg>
);

export default VimeoIcon;
