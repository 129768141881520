import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bell from 'material-ui/svg-icons/social/notifications-none';
import moment from 'moment';
import Panel from '../../../../app/components/panel';
import ListItem from '../../../../app/components/listItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import { withTranslation } from 'react-i18next';

class Events extends Component {
  static propTypes = {
    events: PropTypes.array,
    setListener: PropTypes.func.isRequired,
    removeListener: PropTypes.func.isRequired,
    clearEvents: PropTypes.func.isRequired,
  };

  static defaultProps = {
    events: [],
  };

  state = {
    collapsed: true,
  };

  componentWillMount() {
    this.props.setListener('installs');
    this.props.setListener('shares');
    this.props.setListener('visits');
  }

  componentWillUnmount() {
    this.props.removeListener('installs');
    this.props.removeListener('shares');
    this.props.removeListener('visits');
    this.props.clearEvents();
  }

  getEventBody = ({ installs, shares, visits }) => {
    const { t } = this.props;
    let body = '';

    if (installs) {
      body += `${installs} ${installs > 1 ? t('installs') : t('install')}${
        shares || visits ? ', ' : ''
      }`;
    }

    if (shares) {
      body += `${shares} ${shares > 1 ? t('shares') : t('share')}${
        visits ? ', ' : ''
      }`;
    }

    if (visits) {
      body += `${visits} ${visits > 1 ? t('views') : t('view')}`;
    }

    return body;
  };

  togglePanel = () => this.setState({ collapsed: !this.state.collapsed });
  render() {
    const panelStyle = { marginTop: '2rem', marginBottom: '2rem' };
    const { t } = this.props;
    const summaryStyle = {
      display: 'flex',
      columnGap: '10px',
      alignItems: 'center',
    };
    const children = this.props.events.map(event => {
      return (
        <ListItem
          key={event.timeAgo}
          title={this.getEventBody(event)}
          subtitle={`(${moment(event.timestamp).format('DD/MM/YYYY hh:mm A')})`}
          leftAction={<div className="notification-dial" />}
        />
      );
    });

    return (
      <Panel
        icon={<Bell />}
        title={`${t('You have')} ${children.length} ${t('new alerts')}`}
        style={panelStyle}
        action={
          <button onClick={this.togglePanel}>
            {this.state.collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </button>
        }
      >
        <Collapse in={!this.state.collapsed}>
          <div>{children}</div>
        </Collapse>
      </Panel>
    );
  }
}

export default withTranslation()(Events);
