import Firebase from 'firebase';
import firebase from '../../../../firebase';

export const fieldChange = (prop, value) => ({
  type: 'RESET_EMAIL_FIELD_CHANGE',
  prop,
  value,
});

const resetEmailError = err => ({
  type: 'RESET_EMAIL_ERROR',
  err,
});

const invalidateField = prop => ({
  type: 'INVALIDATE_RESET_EMAIL_FIELD',
  prop,
});

const reportSuccess = () => ({
  type: 'RESET_EMAIL_SUCCESS',
});

export const clearForm = () => ({
  type: 'CLEAR_RESET_EMAIL_FORM',
});

export const submitResetEmail = () => (dispatch, getState) => {
  const { oldEmail, newEmail, password } = getState().account.resetEmail;
  const { account } = getState().account.profile;
  dispatch({ type: 'SUBMIT_RESET_EMAIL' });

  if (oldEmail.value === '') {
    return dispatch(invalidateField('oldEmail'));
  }

  if (newEmail.value === '') {
    return dispatch(invalidateField('newEmail'));
  }
  const user = firebase.auth().currentUser;
  const credential = Firebase.auth.EmailAuthProvider.credential(
    oldEmail.value,
    password.value,
  );

  return (
    user
      .reauthenticateWithCredential(credential)
      .then(() => user.updateEmail(newEmail.value))
      //update user's email in account details
      .then(
        () =>
          account &&
          firebase
            .database()
            .ref(`accounts/${account}/default_email`)
            .set(newEmail.value),
      )
      // TODO: send email to verify
      .then(() => dispatch(reportSuccess()))
      .catch(err => dispatch(resetEmailError(err)))
  );
};
