import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SimpleToolbar } from '../../../../app/modules/toolbar';
import { Payment } from '../..';

import { store } from '../../../..';
import { useStripe } from '@stripe/react-stripe-js';
const BuyCredits = props => {
  const { t } = useTranslation();
  const stripe = useStripe();
  useEffect(() => {
    if (!props.location.query) {
      return props.history.replace('/choose_whatsapp_credits_bundle', {
        state: { origin: props.location.pathname },
      });
    }
    props.setAmount(props.integration);
  }, []);

  const handleRedirect = () => {
    if (props.location.state && props.location.state.origin) {
      props.history.push(props.location.state.origin);
    } else {
      props.history.push(`/${props.profile.lastVisited}/editor`);
    }
  };

  const buyCreditsError = () => {
    props.history.push(
      `/${props.profile.lastVisited}/distribute/share_whatsapp`,
    );
    props.displayPaymentError(
      t('Your credits payment was unsuccessful. Try again later'),
    );
  };

  return (
    <div className="overlay">
      <Provider store={store}>
        <SimpleToolbar />
      </Provider>
      <Payment
        title={t("Let's add some credits to your account!")}
        subtitle={`${props.amount} ${t('Credit Package')}`}
        label={`${props.amount} ${t('Credits')}`}
        cost={props.cost}
        onSubmit={() =>
          props.handleCharge(stripe, props.cost, {
            amount: props.amount,
          })
        }
        submitLabel={t('Purchase')}
        stripe={stripe}
        stripeAuthSuccessCallback={handleRedirect}
        stripeAuthErrorCallback={buyCreditsError}
        currency={props.intl.currency}
      />
    </div>
  );
};

BuyCredits.propTypes = {
  cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  amount: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  setAmount: PropTypes.func.isRequired,
  handleCharge: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  integration: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.shape({
    currency: PropTypes.string.isRequired,
  }).isRequired,
};

BuyCredits.defaultProps = {
  integration: null,
};
export default BuyCredits;
