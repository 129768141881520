const initialState = {
    pending: false,
    success: false,
    failed: false
  };
  
  const clickbankThankyou = (state = initialState, action) => {
    switch (action.type) {
      case 'INITIATE_CLICKBANK_REGISTRATION':
        return {
          ...state,
          pending: true,
          failed: false
				};
				
      case 'SUCCESS_CLICKBANK_REGISTRATION':
        return {
					...state,
					pending: false,
					success: true,
          failed: false
        };
  
      case 'ERROR_CLICKBANK_REGISTRATION':
        return {
          ...state,
          pending: false,
          success: false,
          failed: false
        };

      case 'FAILED_CLICKBANK':
        return {
          ...state,
          pending: false,
          failed: true
        };

        case 'LOGOUT_SUCCESS':
          return initialState;

        default:
        return state;
    }
  };
  
  export default clickbankThankyou;
  