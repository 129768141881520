import React from 'react';

import './styles.css';

const EcommerceIcon = () => (
  <svg
    className="icon ecommerce"
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 755.82495 721.7533"
  >
    <g
      transform="matrix(0.1,0,0,-0.1,-162.17586,900.8065)"
      stroke="none"
      id="g18"
    >
      <path
        d="m 5175,9004 c -111,-16 -264,-49 -347,-74 -738,-227 -1273,-851 -1384,-1616 -23,-161 -16,-484 15,-636 78,-390 249,-721 509,-990 l 113,-117 -238,-3 c -268,-3 -285,-8 -353,-84 -45,-52 -60,-93 -60,-166 0,-111 63,-204 159,-236 52,-18 3572,-16 3622,2 200,71 215,356 24,459 -37,21 -57,22 -278,25 l -239,3 99,102 c 296,304 480,684 539,1112 20,147 15,442 -10,580 -76,418 -241,745 -526,1039 -305,314 -656,504 -1080,581 -91,17 -487,30 -565,19 z m 385,-831 v -178 l 67,-25 c 166,-63 295,-177 363,-321 29,-64 60,-190 60,-250 v -29 h -160 -160 v 28 c 0,49 -29,127 -64,174 -62,81 -202,136 -321,125 -132,-13 -252,-87 -268,-167 -12,-56 -8,-131 9,-165 33,-70 90,-99 360,-180 356,-107 498,-204 570,-390 24,-60 27,-85 28,-180 1,-124 -14,-182 -70,-276 -72,-120 -216,-222 -354,-251 l -60,-12 v -173 -173 h -160 -160 v 173 173 l -57,18 c -144,45 -272,145 -340,266 -44,77 -81,194 -89,279 l -7,71 h 162 161 v -32 c 0,-73 40,-165 92,-215 62,-59 119,-78 243,-78 124,0 171,11 237,55 108,71 118,249 19,319 -47,33 -147,76 -236,101 -173,48 -304,94 -389,138 -192,98 -286,248 -286,452 1,252 141,444 386,526 40,13 80,24 88,24 14,0 16,22 16,175 v 175 h 160 160 z"
        id="path12"
      />
      <path
        d="m 1991,7684 c -178,-48 -329,-209 -361,-385 -7,-36 -9,-729 -8,-2015 l 3,-1959 22,-57 c 45,-119 127,-214 233,-272 110,-60 -140,-56 3521,-56 3344,0 3367,0 3432,20 123,38 232,131 291,249 58,114 56,20 56,2106 0,2094 2,1993 -57,2115 -33,69 -141,179 -210,214 -110,55 -118,56 -741,56 h -574 l 5,-22 c 3,-13 19,-86 35,-163 l 30,-140 528,-5 529,-5 37,-23 c 21,-13 45,-40 57,-66 21,-42 21,-44 21,-1694 V 3930 H 5400 1960 v 1656 c 0,1855 -6,1700 73,1752 l 41,27 529,5 530,5 29,140 c 16,77 32,150 35,163 l 5,22 -578,-1 c -473,0 -589,-3 -633,-15 z"
        id="path14"
      />
      <path
        d="m 4316,2383 c -21,-126 -40,-236 -42,-245 -5,-16 -23,-18 -202,-18 -185,0 -199,-2 -237,-23 -45,-25 -85,-92 -85,-142 1,-46 34,-109 71,-136 l 34,-24 1533,-3 c 1719,-3 1577,-9 1630,71 53,81 25,195 -59,237 -34,18 -59,20 -233,20 -177,0 -195,2 -200,18 -2,9 -21,119 -42,245 l -37,227 H 5400 4353 Z"
        id="path16"
      />
    </g>
  </svg>
);

export default EcommerceIcon;
