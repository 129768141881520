import _find from 'lodash/find';

const initialState = {
  routes: [],
  enabled: false,
  pending: true,
};

const mapComponent = alwaysData => componentKey => ({
  ...alwaysData[componentKey],
  key: componentKey,
});

// defines the App's routes, views and tabs
// this is only required for editor previews and development
// as the structure is provided in the initial payload in production

const structure = (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVE_DRAFT_DATA': {
      return {
        ...state,
        pending: false,
        routes: action.data.layout_data.childRoutes
          ? action.data.layout_data.childRoutes.map(key => {
              const prevState =
                _find(state.routes, route => route.key === key) || {};

              const route = {
                ...prevState,
                ...action.data.always_data[key],
                components: action.data.always_data[key].components,
                key,
              };

              if (route.type === 'TabView') {
                return {
                  ...route,
                  tabs: route.tabs
                    ? route.tabs.map(tab => ({
                        ...tab,
                        components: tab.components
                          ? tab.components.map(
                              mapComponent(action.data.always_data),
                            )
                          : [],
                      }))
                    : [],
                };
              }

              return {
                ...route,
                components: route.components
                  ? route.components.map(mapComponent(action.data.always_data))
                  : [],
              };
            })
          : [],
      };
    }

    case 'TOGGLE_PUSH':
      return {
        ...state,
        enabled: action.value,
      };

    default:
      return state;
  }
};

export default structure;
