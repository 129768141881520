import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DialogConfirmation from '../../components/DialogConfirmation';
import rewardImg from '../../assets/reward.PNG';
import DialogInfo from '../../components/DialogInfo';
import Price from '../../components/Price';

const RedeemBonus = props => {
  const {
    openDialog,
    closeDialog,
    bonusPoints,
    intl,
    fetchBonusPoints,
    isPending,
    hasCard,
  } = props;
  const { t } = useTranslation();
  const [infoDialog, setInfoDialog] = useState(false);
  const toggleHandler = () => {
    setInfoDialog(!infoDialog);
  };

  useEffect(() => {
    fetchBonusPoints();
  }, []);

  const redeemHandler = () => {
    if (bonusPoints > 0) {
      hasCard ? openDialog(true) : toggleHandler();
    }
  };

  return (
    <div className="referral-section redeem-bonus-container">
      <h3 className="header">
        <Trans>Redeem Bonus Points</Trans>
      </h3>
      <div className="redeem-section">
        <div className="reward-image">
          <img src={rewardImg} />
        </div>
        <div className="reward-amount">
          <span>
            <Price cost={bonusPoints} intl={intl} />
          </span>
        </div>
        <div className="reward-redeem-btn details flex-row subscription">
          <div className="outer-redeem">
            <span
              className={bonusPoints > 0 ? '' : 'disable-input'}
              onClick={redeemHandler}
            >
              <Trans>Redeem</Trans>
            </span>
          </div>
        </div>
      </div>
      <DialogConfirmation
        open={props.dialogOpen}
        onClose={() => closeDialog()}
        message={`${t('Are you sure you would like to redeem your points?')}`}
        action={() => {
          props.redeemConfirmation().then(() => {
            props.fetchRedeemHistory();
          });
        }}
        pending={isPending}
      />
      <DialogInfo
        open={infoDialog}
        onClose={toggleHandler}
        title={t('Redeem Instructions')}
      >
        <div>
          <Trans>
            To redeem your bonus points, please add a valid payment method in
            the Billing section of your account. The points can then be redeemed
            as cash back, credited to that payment method.
          </Trans>
        </div>
      </DialogInfo>
    </div>
  );
};

export default RedeemBonus;
