import firebase from '../../../../../../firebase';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import { WORKFORCE_DELETED } from '../../config';
import { objectToArray, arrayToObject } from '../../../../../../utils';

export const REQUEST_WORKFORCE_MEMBERS = 'REQUEST_WORKFORCE_MEMBERS';
export const RECEIVE_WORKFORCE_MEMBERS = 'RECEIVE_WORKFORCE_MEMBERS';
export const RECEIVE_WORKFORCE_GROUP_DATA = 'RECEIVE_WORKFORCE_GROUP_DATA';
export const REPORT_WORKFORCE_MEMBERS_REQUEST_ERROR =
  'REPORT_WORKFORCE_MEMBERS_REQUEST_ERROR';
export const SELECT_WORKFORCE_MEMBER = 'SELECT_WORKFORCE_MEMBER';
export const FILTER_WORKFORCE_MEMBER_DATA = 'FILTER_WORKFORCE_MEMBER_DATA';
export const SELECT_ALL_WORKFORCE_MEMBERS = 'SELECT_ALL_WORKFORCE_MEMBERS';
export const DESELECT_ALL_WORKFORCE_MEMBERS = 'DESELECT_ALL_WORKFORCE_MEMBERS';
export const WORKFORCE_DELETE_ERROR = 'WORKFORCE_DELETE_ERROR';
export const WORKFORCE_MEMBER_DELETED = 'WORKFORCE_MEMBER_DELETED';

const requestTypes = () => ({
  type: REQUEST_WORKFORCE_MEMBERS,
});

export const selectType = key => ({
  type: SELECT_WORKFORCE_MEMBER,
  key,
});

export const getWorkforceList = app => dispatch => {
  dispatch(requestTypes());

  firebase
    .database()
    .ref(`app_users/${app}`)
    .on('value', snap => {
      if (snap.exists()) {
        const users = objectToArray(snap.val());
        const workforceMembers = arrayToObject(
          users.filter(i => i.value.workforceMember),
        );
        dispatch({
          type: RECEIVE_WORKFORCE_MEMBERS,
          value: workforceMembers,
        });
      } else {
        dispatch({
          type: RECEIVE_WORKFORCE_MEMBERS,
          value: {},
        });
      }
    });
};

export const getWorkforceGroupData = app => dispatch => {
  dispatch(requestTypes());

  firebase
    .database()
    .ref(`workforce_groups_data/${app}`)
    .on('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_WORKFORCE_GROUP_DATA,
          value: snap.val(),
        });
      } else {
        dispatch({
          type: RECEIVE_WORKFORCE_GROUP_DATA,
          value: {},
        });
      }
    });
};

export const filterTypeData = filter => dispatch =>
  dispatch({
    type: FILTER_WORKFORCE_MEMBER_DATA,
    payload: filter,
  });

export const selectAllTypes = () => ({ type: SELECT_ALL_WORKFORCE_MEMBERS });
export const deselectAllTypes = () => ({
  type: DESELECT_ALL_WORKFORCE_MEMBERS,
});

export const reportActionError = (
  error,
  appname,
  selected,
  action,
) => dispatch =>
  dispatch({ type: WORKFORCE_DELETE_ERROR, error, appname, selected, action });

const deleteUser = (appname, memberID) =>
  new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;

    return user.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_WORKFORCE_API}/delete`, {
        method: 'delete',
        mode: 'cors',
        body: JSON.stringify({
          appname,
          memberID,
          requestOrigin: 'platform',
        }),
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            return resolve(res);
          }

          throw new Error('Opps, something went wrong. Please try again.');
        })
        .catch(reject);
    });
  });

export const deleteMember = appname => (dispatch, getState) => {
  const { selected } = getState().auth.workforceMembers.listPage.data;
  selected.map(key =>
    deleteUser(appname, key)
      .then(data => {
        dispatch({
          type: WORKFORCE_MEMBER_DELETED,
          appname,
          data,
        });
        dispatch(openSnackbar(WORKFORCE_DELETED));
      })
      .catch(error => {
        dispatch(reportActionError(error));
      }),
  );
};
