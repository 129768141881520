import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';
import { Trans, useTranslation } from 'react-i18next';

import TextField from '../../../../../app/components/textField';
import { FlatButton } from '../../../../../app/components/buttons';
import CircularValidation from '../../../../../app/components/circularValidation';
import { CircularProgress } from '../../../../../app/components/progress';

import './styles.css';

const NewStaff = ({
  open,
  pending,
  error,
  onSubmit,
  onRequestClose,
  firstName,
  lastName,
  email,
  password,
  onFieldChange,
}) => {
  const { t } = useTranslation();

  const displayError = error ? (
    <div>
      <strong>{error}</strong>
    </div>
  ) : null;

  const form = (
    <div>
      <h5>
        <Trans>Create an Event Organizer Admin</Trans>
      </h5>
      <TextField
        id="firstName"
        placeholder={t('First Name')}
        {...firstName}
        onChange={value => onFieldChange('firstName', value)}
        rightInputAction={
          <CircularValidation
            isValid={firstName.isValid}
            defaultMsg={t("Staff's first name required")}
            errorMsg={t('First name must be at least 2 characters long')}
            showDefault={!firstName.value}
          />
        }
      />
      <TextField
        id="lastName"
        placeholder={t('Last Name')}
        {...lastName}
        onChange={value => onFieldChange('lastName', value)}
        rightInputAction={
          <CircularValidation
            isValid={lastName.isValid}
            defaultMsg={t("Staff's last name required")}
            errorMsg={t('Last name must be at least 2 characters long')}
            showDefault={!lastName.value}
          />
        }
      />
      <TextField
        id="email"
        placeholder={t('Email')}
        {...email}
        onChange={value => onFieldChange('email', value)}
        rightInputAction={
          <CircularValidation
            isValid={email.isValid}
            defaultMsg={t('Email address required')}
            errorMsg={t('Please use a valid email address')}
            showDefault={!email.value}
          />
        }
      />
      <TextField
        id="password"
        placeholder={t('Password')}
        type="password"
        {...password}
        onChange={value => onFieldChange('password', value)}
        rightInputAction={
          <CircularValidation
            isValid={password.isValid}
            defaultMsg={t('Password required')}
            errorMsg={t('Password must be at least 6 characters long')}
            showDefault={!password.value}
          />
        }
      />
    </div>
  );

  const buttonSubmit = !pending ? (
    <FlatButton
      label={t('Create')}
      onClick={onSubmit}
      disabled={
        !firstName.isValid ||
        !lastName.isValid ||
        !email.isValid ||
        !password.isValid
      }
      primary
    />
  ) : (
    <CircularProgress size={18} />
  );

  return (
    <Dialog open={open} onRequestClose={onRequestClose} autoScrollBodyContent>
      <div className="contacts-form">
        <div className="modal-controls-container">
          <IconButton onClick={onRequestClose}>
            <Close />
          </IconButton>
        </div>
        <div className="list-fieldset">{displayError}</div>
        {form}
        {buttonSubmit}
      </div>
    </Dialog>
  );
};

NewStaff.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  firstName: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  lastName: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  email: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  password: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

NewStaff.defaultProps = {
  error: null,
};

export default NewStaff;
