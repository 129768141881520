/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Dialog from 'material-ui/Dialog';
import Divider from 'material-ui/Divider';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Autocomplete, ListItemText } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Provider, ReactReduxContext } from 'react-redux';
import RegionalPerformanceCentreImg from './regional_performance_centres.png';
import TextField from '../../../../app/components/textField';
import MultipleImageUpload from '../../../editor/internals/multipleImageUpload';
import CircularValidation from '../../../../app/components/circularValidation';
import { FlatButton } from '../../../../app/components/buttons';
import { CircularProgress } from '../../../../app/components/progress';
import TextArea from '../../../../app/components/textArea';

const regionalPerformanceServices = [
  {
    label: 'Nutrition',
    value: 'nutrition',
  },
  {
    label: 'Physiotherapy',
    value: 'physiotherapy',
  },
  {
    label: 'Pitch Session',
    value: 'pitch_session',
  },

  {
    label: 'Rehab Facilities',
    value: 'rehab_facilities',
  },
  {
    label: 'Soft Tissue Therapy',
    value: 'soft_tissue_therapy',
  },
  {
    label: 'Strength & Conditioning',
    value: 'strength_and_conditioning',
  },
];

const RegionalPerformanceModal = props => {
  const {
    data,
    togglePerformanceModal,
    appname,
    onFieldChangeWithUniqueCheck,
    onFieldChange,
    onMultipleValueFieldChange,
    onSubmit,
    fetchRegionalCentreImages,
    onServicesInfoFieldChange,
    onToggleRegionalSnackbar,
    onDeleteImage,
  } = props;

  const {
    modalOpen,
    location,
    address,
    images,
    services,
    filteredChunks,
    pending,
    isUpdate,
    servicesInfo,
    address_lat,
    address_lng,
    snackbarOpen,
    additional_info,
    rowId,
  } = data;

  const [servicesCurrentValue, setServicesCurrentValue] = useState('');
  const [currentTabValue, setCurrentTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTabValue(newValue);
  };

  useEffect(() => {
    if (location && location.value.length && location.isValid) {
      fetchRegionalCentreImages(
        `pgmol/tempImages/regional-performance-centre/${location.value}`,
      );
    }
  }, [location && location.isValid]);

  const handleModalClose = () => {
    togglePerformanceModal(!modalOpen || false);
  };

  const buttonSubmit = !pending ? (
    <FlatButton
      label={isUpdate ? 'Update' : 'Add'}
      onClick={onSubmit}
      disabled={!location.isValid}
      primary
    />
  ) : (
    <CircularProgress size={18} />
  );

  const handleChange = (event, value) => {
    const sortedValue = [...value].sort((a, b) =>
      a.label.localeCompare(b.label),
    );

    if (services.value.length < value.length) {
      const lastSelected = value.length ? value[value.length - 1] : null;

      if (lastSelected) {
        const index = sortedValue.findIndex(
          v => v.value === lastSelected.value,
        );
        if (index > -1) {
          setCurrentTabValue(index);
        } else {
          setCurrentTabValue(0);
        }
      }
    } else {
      setCurrentTabValue(0);
    }

    onMultipleValueFieldChange('services', sortedValue);
  };

  const isInvalid = serviceValue => {
    const contact1 = serviceValue.contact_1_name;
    const phone = serviceValue.contact_1_phone;
    const email = serviceValue.contact_1_email;
    const timetable = serviceValue.timetable;

    return (
      contact1.isValid === false ||
      phone.isValid === false ||
      email.isValid === false ||
      timetable.isValid === false
    );
  };

  return (
    <ReactReduxContext.Consumer>
      {ctx => (
        <Dialog
          open={modalOpen || false}
          // onRequestClose={handleModalClose}
          autoScrollBodyContent
          style={{ zIndex: 1200, width: '90%' }}
          className="pgmol-modal-main-container pgmol-modal-regional-main-container"
        >
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={snackbarOpen}
            onClose={onToggleRegionalSnackbar}
            message="Please complete all required fields."
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={onToggleRegionalSnackbar}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
          <div className="user-memberBodies-modal-content pgmol-modal-content">
            <div className="modal-heading pgmol-modal-heading">
              <img src={RegionalPerformanceCentreImg} alt="imgg" />
              <h5> Regional Performance Centre</h5>
              <HighlightOff
                className="pgmol-heading-close-icon"
                onClick={handleModalClose}
              />
            </div>
          </div>
          <div>
            <Divider />
            <div className="row">
              <div className="col-sm-6">
                <div className="users-list-container pgmol-user-list">
                  <TextField
                    id="name"
                    placeholder={'Location'}
                    {...location}
                    onChange={value =>
                      onFieldChangeWithUniqueCheck(
                        'location',
                        value,
                        filteredChunks,
                      )
                    }
                    disabled={isUpdate}
                    rightInputAction={
                      <CircularValidation
                        isValid={location.isValid}
                        errorMsg={'Location already exists.'}
                        hideValidIcon
                      />
                    }
                    showRequiredAsterick
                    applyPgmolLabelStyle
                  />

                  <TextArea
                    id="address"
                    placeholder="Address"
                    {...address}
                    onChange={value => onFieldChange('address', value)}
                    label="Address"
                    minHeight="170px"
                    required
                    showRequiredAsterick
                    errorText={'Field is required.'}
                    applyPgmolLabelStyle
                  />

                  <TextField
                    id="address_lat"
                    placeholder={'Latitude'}
                    {...address_lat}
                    onChange={value => onFieldChange('address_lat', value)}
                    applyPgmolLabelStyle
                  />

                  <TextField
                    id="address_lng"
                    placeholder={'Longitude'}
                    {...address_lng}
                    onChange={value => onFieldChange('address_lng', value)}
                    applyPgmolLabelStyle
                  />
                  <br />
                  <p className="pgmol-regional-services-label">Services</p>
                  <Autocomplete
                    multiple
                    id="pgmol-regional-services-autocomplete"
                    disableCloseOnSelect
                    options={[...regionalPerformanceServices]}
                    getOptionLabel={option => {
                      if (option) return option.label;
                      return 'NA';
                    }}
                    renderOption={(props, { label }) => {
                      return (
                        <li {...props} key={label}>
                          <ListItemText primary={`${label}`} />
                        </li>
                      );
                    }}
                    style={{
                      zIndex: 2000,
                      width: 'calc(100% - 20px)',
                    }}
                    renderInput={params => (
                      <MuiTextField
                        {...params}
                        variant="standard"
                        error={services.isValid === false}
                        helperText={
                          services.isValid === false ? 'Field is required.' : ''
                        }
                        className="pgmol-auto-complete"
                      />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={handleChange}
                    value={[...services.value]}
                    defaultValue={[...services.value]}
                  />
                  <br />
                  {services && services.value && services.value.length > 0 && (
                    <div className="regional-performance-tabs-container">
                      <AppBar position="static" color="default">
                        <Tabs
                          value={currentTabValue}
                          onChange={handleTabChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                        >
                          {services.value.map((service, index) => (
                            <Tab
                              key={service.value}
                              label={service.label}
                              id={`scrollable-auto-tab-${index}`}
                              aria-controls={`${index}`}
                              style={{ textTransform: 'initial' }}
                              className={`${isInvalid(
                                servicesInfo.value[service.value],
                              ) && 'pgmol-regional-service-label'}`}
                            />
                          ))}
                        </Tabs>
                      </AppBar>
                      {services.value.map((service, i) => (
                        <div
                          role="tabpanel"
                          hidden={currentTabValue !== i}
                          id={`scrollable-auto-tabpanel-${i}`}
                          aria-labelledby={`scrollable-auto-tab-${i}`}
                        >
                          {currentTabValue === i && (
                            <>
                              <div className="pgmol_contacts_fields_container">
                                <div>
                                  <TextField
                                    id="contact_1_name"
                                    placeholder={`${service.label} Contact Name (1)`}
                                    value={
                                      servicesInfo.value[service.value]
                                        .contact_1_name.value
                                    }
                                    required
                                    showRequiredAsterick
                                    isValid={
                                      servicesInfo.value[service.value]
                                        .contact_1_name.isValid
                                    }
                                    errorText={'Field is required.'}
                                    onChange={value =>
                                      onServicesInfoFieldChange(
                                        service.value,
                                        'contact_1_name',
                                        value,
                                      )
                                    }
                                    applyPgmolLabelStyle
                                  />
                                  <TextField
                                    id="contact_1_phone"
                                    placeholder={`${service.label} Telephone Number (1)`}
                                    value={
                                      servicesInfo.value[service.value]
                                        .contact_1_phone.value
                                    }
                                    required
                                    showRequiredAsterick
                                    isValid={
                                      servicesInfo.value[service.value]
                                        .contact_1_phone.isValid
                                    }
                                    errorText={'Field is required.'}
                                    onChange={value =>
                                      onServicesInfoFieldChange(
                                        service.value,
                                        'contact_1_phone',
                                        value,
                                      )
                                    }
                                    applyPgmolLabelStyle
                                  />
                                  <TextField
                                    id="contact_1_email"
                                    placeholder={`${service.label} Email Address (1)`}
                                    value={
                                      servicesInfo.value[service.value]
                                        .contact_1_email.value
                                    }
                                    type="email"
                                    required
                                    showRequiredAsterick
                                    isValid={
                                      servicesInfo.value[service.value]
                                        .contact_1_email.isValid
                                    }
                                    errorText={'Field is required.'}
                                    onChange={value =>
                                      onServicesInfoFieldChange(
                                        service.value,
                                        'contact_1_email',
                                        value,
                                      )
                                    }
                                    applyPgmolLabelStyle
                                  />
                                </div>
                                <div>
                                  <TextField
                                    id="contact_2_name"
                                    placeholder={`${service.label} Contact Name (2)`}
                                    value={
                                      servicesInfo.value[service.value]
                                        .contact_2_name.value
                                    }
                                    required
                                    isValid={
                                      servicesInfo.value[service.value]
                                        .contact_2_name.isValid
                                    }
                                    errorText={'Field is required.'}
                                    onChange={value =>
                                      onServicesInfoFieldChange(
                                        service.value,
                                        'contact_2_name',
                                        value,
                                      )
                                    }
                                    applyPgmolLabelStyle
                                  />
                                  <TextField
                                    id="contact_2_phone"
                                    placeholder={`${service.label} Telephone Number (2)`}
                                    value={
                                      servicesInfo.value[service.value]
                                        .contact_2_phone.value
                                    }
                                    required
                                    showRequiredAsterick={
                                      servicesInfo.value[service.value]
                                        .contact_2_name.value.length > 0
                                    }
                                    isValid={
                                      servicesInfo.value[service.value]
                                        .contact_2_phone.isValid
                                    }
                                    errorText={'Field is required.'}
                                    onChange={value =>
                                      onServicesInfoFieldChange(
                                        service.value,
                                        'contact_2_phone',
                                        value,
                                      )
                                    }
                                    applyPgmolLabelStyle
                                  />
                                  <TextField
                                    id="contact_2_email"
                                    placeholder={`${service.label} Email Address (2)`}
                                    value={
                                      servicesInfo.value[service.value]
                                        .contact_2_email.value
                                    }
                                    required
                                    showRequiredAsterick={
                                      servicesInfo.value[service.value]
                                        .contact_2_name.value.length > 0
                                    }
                                    isValid={
                                      servicesInfo.value[service.value]
                                        .contact_2_email.isValid
                                    }
                                    type="email"
                                    errorText={'Field is required.'}
                                    onChange={value =>
                                      onServicesInfoFieldChange(
                                        service.value,
                                        'contact_2_email',
                                        value,
                                      )
                                    }
                                    applyPgmolLabelStyle
                                  />
                                </div>
                              </div>

                              <div className="pgmol_contacts_fields_timetable">
                                <TextArea
                                  id="timetable"
                                  placeholder={`${service.label} Timetable`}
                                  value={
                                    servicesInfo.value[service.value].timetable
                                      .value
                                  }
                                  required
                                  showRequiredAsterick
                                  isValid={
                                    servicesInfo.value[service.value].timetable
                                      .isValid
                                  }
                                  errorText={'Field is required.'}
                                  onChange={value =>
                                    onServicesInfoFieldChange(
                                      service.value,
                                      'timetable',
                                      value,
                                    )
                                  }
                                  label="Timetable"
                                  minHeight="80px"
                                  applyPgmolLabelStyle
                                />
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                  <TextArea
                    id="additional_info"
                    placeholder={'Additional Information'}
                    label="Additional Information"
                    {...additional_info}
                    onChange={value => onFieldChange('additional_info', value)}
                    applyPgmolLabelStyle
                    minHeight="170px"
                  />

                  {location && location.value.length > 0 && location.isValid && (
                    <>
                      <div className="modal-heading-container">
                        <h5 className="pgmol-label">Gallery</h5>
                      </div>
                      <div className="row staffs-list">
                        <div className="col-sm-12" style={{ flexBasis: 0 }}>
                          <div className="member-bodies">
                            <Provider store={ctx.store}>
                              <MultipleImageUpload
                                data={{}}
                                firebaseUrl={`pgmol/tempImages/regional-performance-centre/${appname}/${location.value}`}
                              />
                            </Provider>
                          </div>
                        </div>
                      </div>
                      <div
                        className="pgmol-image-container"
                        style={{
                          height: images.value.length > 0 ? '30vh' : '0vh',
                        }}
                      >
                        {images &&
                          images.value &&
                          images.value.length > 0 &&
                          images.value.map((img, i) => (
                            <div key={img.key} className="image-container">
                              <img src={img.url} alt="pgmol-images" />
                              <div className="delete-image-icon">
                                <IconButton
                                  size="small"
                                  aria-label="close"
                                  color="inherit"
                                  onClick={() =>
                                    onDeleteImage(img.url, appname, rowId, i)
                                  }
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <Divider />
          </div>
          <Divider />

          <div className="submit-button-container">{buttonSubmit}</div>
        </Dialog>
      )}
    </ReactReduxContext.Consumer>
  );
};

export default RegionalPerformanceModal;
