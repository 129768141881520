import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NewContact from './NewContact';

import {
  importList,
  addContact,
  closeNewListDialog,
  formFieldChange,
  cancelImport,
  overwriteContacts,
} from './actions';

const mapStateToProps = state => ({
  ...state.distribute.contacts.newContact,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFileUpload: (acceptedFiles, rejectedFiles) =>
    dispatch(
      importList(ownProps.match.params.appname, acceptedFiles, rejectedFiles),
    ),
  onSubmit: (cameFromDrivers, cameFromBookingStaff, history) =>
    dispatch(
      addContact(
        ownProps.match.params.appname,
        cameFromDrivers,
        cameFromBookingStaff,
        history,
      ),
    ),
  onCancel: () => dispatch(cancelImport(ownProps.match.params.appname)),
  onDuplicate: () => dispatch(overwriteContacts(ownProps.match.params.appname)),
  onRequestClose: () => dispatch(closeNewListDialog()),
  onFieldChange: (field, value, other = null) =>
    dispatch(formFieldChange(field, value, other)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewContact),
);
