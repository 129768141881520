import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DistributeMenu from './DistributeMenu';
import {
  addIsPublishedListener,
  removeIsPublishedListener,
} from '../../../../app/modules/toolbar/actions';


const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  integration: state.account.organisation.integration,
  isPublished: state.editor.data.isPublished[ownProps.match.params.appname] || false,
  customDomain: state.app.customDomain,

});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddIsPublishedListener: () =>
    dispatch(addIsPublishedListener(ownProps.match.params.appname)),
  onRemoveIsPublishedListener: () =>
    dispatch(removeIsPublishedListener(ownProps.match.params.appname)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DistributeMenu));
