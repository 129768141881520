import firebase from '../../../../../../firebase';

const receiveLazyData = (appname, key, type, data) => ({
  type: 'RECEIVE_LAZY_DATA',
  component: type,
  key,
  data,
  appname,
});

export const loadLazyData = (appname, dataId, type) => dispatch => {
  const draftRef = firebase.database().ref(`apps/${appname}/draft`);
  const lazyDataRef = draftRef.child(`lazy_data/${dataId}`);

  dispatch({
    type: 'LOADING_LAZY_DATA',
    key: dataId,
    component: type,
    appname,
  });

  lazyDataRef.on('value', snapshot => {
    const data = snapshot.val();
    const key = snapshot.key;

    dispatch(receiveLazyData(appname, key, type, data));
  });
};

export const offLazyData = (appname, lazyDataId) => {
  const draftRef = firebase.database().ref(`apps/${appname}/draft`);
  const lazyDataRef = draftRef.child(`lazy_data/${lazyDataId}`);

  lazyDataRef.off('value');

  return {
    type: 'REMOVING_LAZY_DATA_LISTENER',
    appname,
    key: lazyDataId,
  };
};
