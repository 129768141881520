import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import UpgradePlan from './UpgradePlan';
import { openWixPlans } from '../../../modules/account/modules/integration/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  integration: state.account.organisation.integration,
  appname: ownProps.match.params.appname,
  history: ownProps.history,
  accountId: state.account.auth.profile.account,
  subscription: state.app.appContext.subscription,
});

const mapDispatchToProps = dispatch => ({
  displayWixPayment: (appname, history, plan = 'premium', accountId) =>
    dispatch(openWixPlans(appname, plan, history, accountId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UpgradePlan),
);
