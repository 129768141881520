import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import Toggle from '../../../../app/components/toggle';

import SectionHeader from '../../../editor/components/sectionHeader';
import styles from './locationFilter.module.scss';

const LocationFilter = props => {
  const {
    appname,
    onFilteredLocationListChange,
    onSelectedFilterChange,
    onEnableLocationFilter,
    onDisableLocationFilter,
    onFiltersChange,
    locationFiltered,
    onGetCountries,
    filteredCountries,
    onGetStates,
    filteredStates,
    onGetCities,
    filteredCities,
  } = props;
  const { t } = useTranslation();

  const [toggled, setToggled] = useState(false);

  const [filteredLocationList, setFilteredLoctionList] = useState([]);

  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState('');
  const [countryValue, setCountryValue] = useState('');

  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState('');
  const [stateValue, setStateValue] = useState('');

  const [cities, setCities] = useState([]);
  const [cityValue, setCityValue] = useState('');

  useEffect(() => {
    const getCountries = async () => {
      await onGetCountries();
    };
    getCountries();
  }, []);

  useEffect(() => {
    setCountries(filteredCountries);
  }, [filteredCountries]);

  useEffect(() => {
    if (countryId) {
      const getStates = async () => {
        await onGetStates(countryId);
      };
      getStates();
    }
  }, [countryId, filteredLocationList]);

  useEffect(() => {
    setStates(filteredStates);
  }, [filteredStates]);

  useEffect(() => {
    if (stateId) {
      const getStates = async () => {
        await onGetCities(countryId, stateId);
      };
      getStates();
    }
  }, [stateId]);

  useEffect(() => {
    setCities(filteredCities);
  }, [filteredCities]);

  useEffect(() => {
    onFilteredLocationListChange(filteredLocationList);
  }, [filteredLocationList]);

  const handleCountryChange = selectedOption => {
    setFilteredLoctionList([selectedOption.value]);
    setCountryId(selectedOption.name);
    setStateValue('');
    setCityValue('');
    setCountryValue(selectedOption);
  };

  const handleStateChange = selectedOption => {
    setFilteredLoctionList([countryValue.value, selectedOption.value]);
    setStateId(selectedOption.value);
    setCityValue('');
    setStateValue(selectedOption);
  };

  const handleCityChange = selectedOption => {
    setFilteredLoctionList([
      countryValue.value,
      stateValue.value,
      selectedOption.value,
    ]);
    setCityValue(selectedOption);
  };

  const handleToggle = (e, value) => {
    if (toggled === false) {
      onSelectedFilterChange('location');
    }
    setToggled(!toggled);
  };

  useEffect(() => {
    if (toggled === true) {
      onEnableLocationFilter();
    } else if (toggled === false) {
      onDisableLocationFilter();
    }
  }, [toggled]);

  useEffect(() => {
    if (props.selectedFilter !== 'location') {
      onDisableLocationFilter();
      setToggled(false);
    }
  }, [props.selectedFilter]);

  useEffect(() => {
    const locationFilterPush = [];
    if (locationFiltered) {
      filteredLocationList.forEach((location, index) => {
        if (index === 0) {
          locationFilterPush.push({
            field: 'tag',
            key: 'country',
            relation: '=',
            value: location,
          });
        } else if (index === 1) {
          locationFilterPush.push({
            field: 'tag',
            key: 'state',
            relation: '=',
            value: location,
          });
        } else if (index === 2) {
          locationFilterPush.push({
            field: 'tag',
            key: 'city',
            relation: '=',
            value: location,
          });
        }
      });
    }

    onFiltersChange(locationFilterPush);
  }, [filteredLocationList, locationFiltered]);

  return (
    <>
      <div className={styles.section_header}>
        <SectionHeader
          title={t('Filter By Location')}
          actions={<Toggle onToggle={e => handleToggle(e)} toggled={toggled} />}
          tooltip={t(
            'App users will receive only an in-app notification when you enable filter by location',
          )}
        />
      </div>

      <div>
        <Trans>Sending notifications to</Trans>{' '}
        {!locationFiltered
          ? '-'
          : cityValue.label || stateValue.label || countryValue.label || '-'}
      </div>

      <div className={styles.select_container}>
        <div className={styles.select}>
          <Select
            placeholder={t('Select Country')}
            value={countryValue}
            options={countries}
            onChange={handleCountryChange}
            isDisabled={!locationFiltered}
          />
        </div>

        <div className={styles.select}>
          <Select
            placeholder={t('Select State')}
            value={stateValue}
            options={states}
            onChange={handleStateChange}
            isDisabled={!locationFiltered || !countryId}
          />
        </div>

        <div className={styles.select}>
          <Select
            placeholder={t('Select City')}
            value={cityValue}
            options={cities}
            onChange={handleCityChange}
            isDisabled={!locationFiltered || !stateValue}
          />
        </div>
      </div>
    </>
  );
};

LocationFilter.propTypes = {
  locationFiltered: PropTypes.bool.isRequired,
  onFilteredLocationListChange: PropTypes.func.isRequired,
  onEnableLocationFilter: PropTypes.func.isRequired,
  onDisableLocationFilter: PropTypes.func.isRequired,
  onSelectedFilterChange: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  appname: PropTypes.string.isRequired,
  onGetCountries: PropTypes.func.isRequired,
  filteredCountries: PropTypes.array.isRequired,
  onGetStates: PropTypes.func.isRequired,
  filteredStates: PropTypes.array.isRequired,
  onGetCities: PropTypes.func.isRequired,
  filteredCities: PropTypes.array.isRequired,
};

export default LocationFilter;
