import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  fillOne: {
    fill: '#414042',
  },
  fillTwo: {
    fill: '#00ff52',
  },
  fillThree: {
    fill: '#ffffff',
  },
};

const Logo = ({ height, width, color }) => (
  <svg
    className="logo"
    width={width}
    fill={color || '#261ed5'}
    height={height || '100%'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 962 315.4"
  >
    <title>Asset 1</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          className="cls-1"
          points="517.52 0 617.69 0.18 557.1 71.75 607.58 72.05 492.17 178.79 523.26 103.31 472.82 102.73 517.52 0"
        />
        <path
          className="cls-1"
          d="M136.41,217.93c16.65,5.37,27.39,18.26,27.39,36,0,44-37.06,61.49-74.65,61.49H0L29.27,130.13h73.84c27.39,0,65,3.49,65,39.74C168.09,194.57,154.94,210.41,136.41,217.93ZM89.42,241H70.35l-4.56,29H85.66c11,0,19.6-5.9,19.6-17.72C105.26,243.17,96.93,241,89.42,241Zm4.83-66.86H80.82l-4,26.32H90c10.21,0,18.26-4.83,18.26-16.11C108.21,176,101,174.16,94.25,174.16Z"
        />
        <path
          className="cls-1"
          d="M328.13,130.13l-7.78,48.06H252.14l-3.22,19.6h64.45l-7.52,48.07H241.4l-3.22,20.94h70.89l-7.79,48.6H171.86l29.26-185.27Z"
        />
        <path
          className="cls-1"
          d="M479.05,130.13l-7.79,48.06H403.05l-3.22,19.6h64.45l-7.52,48.07H392.31l-3.22,20.94H460l-7.79,48.6H322.77L352,130.13Z"
        />
        <polygon
          className="cls-1"
          points="518.87 178.73 567.12 178.73 475.29 272.17 468.57 315.4 625.12 315.4 632.91 265.46 552.09 265.46 643.92 172.01 650.36 130.13 571.42 130.13 518.87 178.73"
        />
        <path
          className="cls-1"
          d="M799.62,130.13l-7.78,48.06H723.63l-3.22,19.6h64.45l-7.52,48.07H712.89l-3.22,20.94h70.89l-7.79,48.6H643.35l29.26-185.27Z"
        />
        <path
          className="cls-1"
          d="M922.8,245.05l29,70.35H884.13L865.6,253.91h-7.25l-9.67,61.49h-58.8l29.27-185.27h77.6c32.76,0,65.25,10.47,65.25,49.14C962,207.19,947,231.63,922.8,245.05Zm-37.87-69H870.44l-5.11,31.69h15.31c11.55,0,21.21-6.71,21.21-19.07C901.85,179.27,893,176,884.93,176Z"
        />
      </g>
    </g>
  </svg>
);

Logo.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Logo.defaultProps = {
  height: null,
  width: 90,
};

export default Logo;
