import {
  NEW_FIELD_CHANGE_CPD,
  OPEN_NEW_DIALOG_CPD,
  CLOSE_NEW_DIALOG_CPD,
  ADD_NEW_CPD,
  ADDED_CPD,
} from './actions';

import { INITIATE_FIELDS_CPD } from '../../actions';

const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  fieldsData: {},
};

const newCPDReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIATE_FIELDS_CPD:
      return {
        ...state,
        fieldsData: action.value,
        fieldsMockData: action.value,
      };

    case OPEN_NEW_DIALOG_CPD:
      return {
        ...state,
        open: true,
      };

    case CLOSE_NEW_DIALOG_CPD:
      return {
        ...state,
        open: false,
      };

    case NEW_FIELD_CHANGE_CPD:
      return {
        ...state,
        fieldsData: {
          ...state.fieldsData,
          [action.id]: {
            value: action.value,
            isValid: action.isValid,
            type: action.prop,
          },
        },
      };

    case ADD_NEW_CPD:
      return {
        ...state,
        pending: true,
      };

    case ADDED_CPD:
      return {
        ...initialState,
        fieldsData: state.fieldsMockData,
        fieldsMockData: state.fieldsMockData,
      };

    default:
      return state;
  }
};

export default newCPDReducer;
