import React from 'react';

import './styles.css';

const Orders = () => (
  <svg
    className="icon orders"
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 759.67584 742.02228"
  >
    <g transform="matrix(0.1,0,0,-0.1,-160.5,911.13771)" stroke="none" id="g44">
      <path
        d="m 5350,9094 c -217,-57 -400,-242 -455,-459 -23,-89 -23,-2021 0,-2110 56,-221 239,-404 460,-460 40,-10 108,-15 207,-15 81,0 148,-2 148,-4 0,-2 -65,-137 -145,-300 -101,-208 -145,-308 -145,-332 0,-48 37,-84 86,-84 40,0 -5,-25 796,432 l 506,288 h 929 c 797,0 937,2 988,15 220,56 404,240 460,460 23,89 22,2008 -1,2105 -52,223 -235,408 -459,465 -87,22 -3290,22 -3375,-1 z m 3270,-174 c 154,-20 286,-117 354,-260 l 31,-65 V 7580 6565 l -31,-65 c -68,-144 -200,-240 -354,-260 -43,-5 -464,-10 -980,-10 h -904 l -311,-177 c -170,-97 -394,-224 -497,-283 -146,-84 -185,-102 -178,-85 4,12 54,112 109,223 111,222 119,254 76,297 -24,25 -26,25 -217,25 -106,0 -224,5 -263,10 -203,30 -359,187 -385,390 -13,101 -13,1813 0,1905 13,91 64,196 127,258 62,63 173,118 256,127 97,11 3080,11 3167,0 z"
        id="path12"
      />
      <path
        d="m 6803,8710 c -52,-31 -63,-64 -63,-193 0,-74 4,-117 12,-125 7,-7 39,-12 75,-12 h 63 v -45 c 0,-40 -3,-45 -22,-45 -35,0 -155,-34 -243,-68 -177,-69 -395,-248 -500,-410 -93,-145 -153,-311 -170,-475 l -7,-68 48,3 49,3 7,75 c 4,41 19,113 33,160 198,628 947,896 1502,537 247,-159 410,-423 448,-724 l 7,-53 h 45 46 l -7,72 c -24,265 -173,540 -383,710 -137,111 -326,202 -478,229 l -65,12 v 44 43 h 60 c 84,0 94,16 88,152 -4,113 -19,152 -68,182 -24,13 -61,16 -237,16 -189,0 -211,-2 -240,-20 z m 428,-80 c 16,-9 19,-22 19,-85 v -75 h -210 -210 v 69 c 0,100 2,101 213,101 97,0 177,-4 188,-10 z"
        id="path14"
      />
      <path
        d="m 6082,8504 c -12,-8 -22,-21 -22,-29 0,-16 104,-249 126,-283 12,-18 23,-22 47,-20 51,5 48,40 -18,187 -51,112 -83,161 -104,161 -4,0 -17,-7 -29,-16 z"
        id="path16"
      />
      <path
        d="m 7953,8514 c -6,-3 -41,-71 -78,-151 -57,-125 -65,-149 -56,-169 8,-18 19,-24 41,-24 30,0 33,5 91,128 70,147 81,189 54,208 -19,14 -31,16 -52,8 z"
        id="path18"
      />
      <path
        d="m 6358,8459 c -20,-11 -23,-71 -9,-147 11,-55 31,-77 64,-68 35,8 39,28 26,118 -9,60 -16,82 -32,93 -25,17 -26,18 -49,4 z"
        id="path20"
      />
      <path
        d="m 7676,8457 c -25,-19 -46,-178 -27,-201 16,-19 54,-21 69,-3 6,6 16,50 23,96 12,83 11,85 -11,103 -27,22 -31,22 -54,5 z"
        id="path22"
      />
      <path
        d="m 5936,8254 c -26,-26 -19,-43 50,-120 67,-75 90,-87 118,-58 28,27 18,52 -50,124 -68,72 -91,82 -118,54 z"
        id="path24"
      />
      <path
        d="m 8034,8203 c -35,-38 -65,-76 -68,-86 -5,-20 26,-57 47,-57 8,0 44,31 80,69 71,74 85,111 48,131 -33,17 -43,12 -107,-57 z"
        id="path26"
      />
      <path
        d="m 5692,8134 c -26,-18 -28,-45 -4,-66 36,-33 252,-148 276,-148 29,0 50,31 41,59 -4,14 -266,171 -286,171 -2,0 -15,-7 -27,-16 z"
        id="path28"
      />
      <path
        d="m 8215,8067 c -127,-72 -130,-75 -130,-108 0,-31 3,-34 30,-37 23,-2 62,15 150,64 149,82 147,81 143,118 -5,55 -44,47 -193,-37 z"
        id="path30"
      />
      <path
        d="m 6615,7927 c -111,-59 -244,-217 -275,-328 -13,-43 2,-69 40,-69 26,0 32,7 66,78 46,96 151,205 235,245 58,27 72,47 58,85 -12,31 -52,28 -124,-11 z"
        id="path32"
      />
      <path
        d="m 5710,7840 c -19,-35 -3,-58 50,-74 125,-37 165,-29 158,31 -3,25 -9,30 -68,45 -92,23 -127,22 -140,-2 z"
        id="path34"
      />
      <path
        d="m 8240,7843 c -67,-18 -85,-33 -76,-66 9,-36 33,-40 117,-21 84,19 99,27 99,58 0,48 -40,56 -140,29 z"
        id="path36"
      />
      <path
        d="m 5819,7173 c -46,-28 -59,-63 -59,-156 0,-85 0,-86 -30,-103 -50,-30 -60,-66 -60,-216 0,-146 6,-166 57,-187 21,-8 365,-11 1330,-9 l 1301,3 26,24 c 26,24 26,26 26,170 0,127 -3,150 -20,178 -10,17 -28,37 -39,43 -18,9 -21,20 -21,86 0,87 -16,133 -58,163 l -30,21 H 7043 c -1090,-1 -1200,-2 -1224,-17 z m 2399,-85 c 7,-7 12,-40 12,-80 v -68 H 7040 5850 v 68 c 0,40 5,73 12,80 17,17 2339,17 2356,0 z m 90,-260 c 8,-8 12,-49 12,-120 V 6600 H 7045 5770 v 113 c 0,63 3,117 7,120 3,4 572,7 1263,7 963,0 1259,-3 1268,-12 z"
        id="path38"
      />
      <path
        d="m 3375,6885 c -55,-8 -149,-30 -210,-50 -453,-148 -778,-510 -881,-985 -26,-120 -26,-400 0,-520 52,-241 162,-450 329,-626 195,-207 418,-331 697,-391 116,-25 380,-24 498,1 312,66 567,217 756,446 116,141 186,267 241,435 152,463 26,977 -325,1325 -239,237 -534,365 -870,375 -86,3 -171,-1 -235,-10 z m 395,-281 c 190,-39 370,-135 510,-274 365,-358 416,-898 127,-1324 -126,-185 -353,-345 -578,-405 -180,-48 -350,-48 -535,-1 -269,69 -522,278 -653,538 -129,256 -142,578 -35,842 45,111 115,219 208,316 250,266 604,380 956,308 z"
        id="path40"
      />
      <path
        d="m 2760,3993 c -233,-37 -396,-98 -570,-214 -292,-195 -490,-492 -567,-851 -16,-74 -18,-141 -18,-620 v -538 l 38,-37 37,-38 1873,-3 c 1854,-2 1872,-2 1905,18 18,11 41,34 52,52 20,32 21,44 17,563 -4,501 -5,535 -26,620 -75,314 -222,558 -450,746 -187,156 -371,243 -608,289 -99,19 -141,20 -881,19 -427,-1 -788,-4 -802,-6 z m 1600,-278 c 402,-61 741,-363 855,-760 35,-123 45,-262 45,-657 V 1960 H 3565 1870 v 373 c 0,492 13,590 104,783 80,168 176,287 320,396 137,104 355,195 506,211 19,2 357,4 750,5 580,2 733,-1 810,-13 z"
        id="path42"
      />
    </g>
  </svg>
);

export default Orders;
