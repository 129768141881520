import React from 'react';
import PropTypes from 'prop-types';

import ImageGradientMenu from '../imageGradientMenu';
import ImagePositionMenu from '../imagePositionMenu';

const ImageDesignMenu = ({ component, onDesignChange }) => (
  <div>
    <ImageGradientMenu component={component} onDesignChange={onDesignChange} />
    <ImagePositionMenu component={component} onDesignChange={onDesignChange} />
  </div>
);

ImageDesignMenu.propTypes = {
  component: PropTypes.object.isRequired,
  onDesignChange: PropTypes.func.isRequired,
};

export default ImageDesignMenu;
