import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from 'material-ui/internal/Tooltip';

import InfoIcon from './Info';
import WarningIcon from './Warning';

class TooltipElement extends Component {
  static propTypes = {
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
    className: PropTypes.string,
    verticalPosition: PropTypes.string,
    horizontalPosition: PropTypes.string,
    tooltipStyle: PropTypes.object,
    tooltipType: PropTypes.string,
  };

  static defaultProps = {
    className: undefined,
    verticalPosition: 'top',
    horizontalPosition: 'left',
    tooltipStyle: {},
    tooltipType: 'info',
  };

  state = {
    tooltipShown: false,
  };

  onMouseEnter = () =>
    this.setState({
      tooltipShown: true,
    });

  onMouseLeave = () =>
    this.setState({
      tooltipShown: false,
    });

  render() {
    const {
      tooltip,
      className,
      verticalPosition,
      horizontalPosition,
      tooltipStyle,
      tooltipType,
    } = this.props;

    return (
      <span
        className={className}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {tooltipType === 'warning' ? <WarningIcon /> : <InfoIcon />}
        <Tooltip
          label={tooltip}
          show={this.state.tooltipShown}
          verticalPosition={verticalPosition}
          horizontalPosition={horizontalPosition}
          style={{ ...tooltipStyle, fontSize: 11 }}
        />
      </span>
    );
  }
}

export default styled(TooltipElement)`
  display: inline-block;
  position: relative;
  height: 24px;
  width: 24px;
  padding: 3px;
  margin-left: 5px;
  cursor: help;
`;
