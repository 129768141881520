import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';
import styled from 'styled-components';
import moment from 'moment';
import { Trans } from 'react-i18next';

const DetailWrapper = styled.div`
  width: 60%;
  padding-bottom: 30px;
  div {
    margin-top: 30px;
  }
  h6 {
    font-size: 1rem;
    margin: 0px;
  }
  p {
    font-size: 0.9rem;
    margin: 0px;
    margin-top: 6px;
  }
`;

const ViewAttendee = props => {
  const { onRequestClose, attendeeDetails, match, getAttendeeDetails } = props;

  useEffect(() => {
    if (match.params.attendeeId) {
      getAttendeeDetails(match.params.attendeeId);
    }
  }, [match]);
  return (
    <Dialog
      open={!!(match && match.params.attendeeId)}
      onRequestClose={onRequestClose}
      autoScrollBodyContent
    >
      <div className="contacts-form">
        <div className="modal-controls-container">
          <IconButton onClick={onRequestClose}>
            <Close />
          </IconButton>
        </div>
        {attendeeDetails && (
          <DetailWrapper>
            <h5>
              <Trans>View Attendee</Trans>
            </h5>
            <div>
              <h6>
                <Trans>Full Name</Trans>
              </h6>
              <p>{attendeeDetails.name}</p>
            </div>
            <div>
              <h6>
                <Trans>Email Address</Trans>
              </h6>
              <p>{attendeeDetails.email}</p>
            </div>
            <div>
              <h6>
                <Trans>User Type</Trans>
              </h6>
              <p>{attendeeDetails.userType}</p>
            </div>
            <div>
              <h6>
                <Trans>Date Registered</Trans>
              </h6>
              <p>{moment(attendeeDetails.date).format('DD/MM/YYYY')}</p>
            </div>
            <div>
              <h6>
                <Trans>Message</Trans>
              </h6>
              <p>{attendeeDetails.message}</p>
            </div>
          </DetailWrapper>
        )}
      </div>
    </Dialog>
  );
};

ViewAttendee.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  attendeeDetails: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getAttendeeDetails: PropTypes.func.isRequired,
};

export default ViewAttendee;
