import { TOGGLE_WELCOME_MODAL } from './actions';
import { REQUEST_PUBLISH, APP_PUBLISHED } from '../../../../../../app/actions';

const welcomeModal = (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_WELCOME_MODAL:
      return {
        ...state,
        open: !state.open,
      };

    case REQUEST_PUBLISH:
      return {
        ...state,
        publishing: true,
      };

    case APP_PUBLISHED:
      return {
        ...state,
        publishing: false,
      };

    default:
      return state;
  }
};

export default welcomeModal;
