import React from 'react';

const headerLarge = () => (
  <svg
    className="layout layout-header-large"
    viewBox="0 0 378.19 775"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M 5.09 142.475 L 3.69 142.475 C 2.585 142.475 1.69 141.394 1.69 140.061 L 1.69 104.938 C 1.69 103.605 2.585 102.525 3.69 102.525 L 5.09 102.525"
          style={{ fill: 'rgb(255, 255, 255)' }}
        />
        <path
          className="cls-2"
          d="M 5.09 142.475 L 3.69 142.475 C 2.585 142.475 1.69 141.394 1.69 140.061 L 1.69 104.938 C 1.69 103.605 2.585 102.525 3.69 102.525 L 5.09 102.525"
          style={{
            fill: 'none',
            stroke: 'rgb(208, 210, 211)',
            strokeLineJoin: 'round',
            strokeWidth: '3px',
          }}
        />
        <path
          className="cls-1"
          d="M 5.09 258.342 L 3.89 258.342 C 2.785 258.342 1.89 257.261 1.89 255.928 L 1.89 190.028 C 1.89 188.695 2.785 187.615 3.89 187.615 L 5.09 187.615"
          style={{ fill: 'rgb(255, 255, 255)' }}
        />
        <path
          className="cls-2"
          d="M 5.09 258.342 L 3.89 258.342 C 2.785 258.342 1.89 257.261 1.89 255.928 L 1.89 190.028 C 1.89 188.695 2.785 187.615 3.89 187.615 L 5.09 187.615"
          style={{
            fill: 'none',
            stroke: 'rgb(208, 210, 211)',
            strokeLineJoin: 'round',
            strokeWidth: '3px',
          }}
        />
        <path
          className="cls-1"
          d="M 372.89 187.615 L 373.69 187.615 C 375.347 187.615 376.69 189.236 376.69 191.235 L 376.69 254.721 C 376.69 256.721 375.347 258.342 373.69 258.342 L 372.89 258.342"
          style={{ fill: 'rgb(255, 255, 255)' }}
        />
        <path
          className="cls-2"
          d="M 372.89 187.615 L 373.69 187.615 C 375.347 187.615 376.69 189.236 376.69 191.235 L 376.69 254.721 C 376.69 256.721 375.347 258.342 373.69 258.342 L 372.89 258.342"
          style={{
            fill: 'none',
            stroke: 'rgb(208, 210, 211)',
            strokeLineJoin: 'round',
            strokeWidth: '3px',
          }}
        />
        <path
          className="cls-1"
          d="M 5.19 345.484 L 2 345.484 C 0.9 345.484 2 344.398 2 343.07 L 2 277.17 C 2 275.843 0.9 274.756 2 274.756 L 5.2 274.756"
          style={{ fill: 'rgb(255, 255, 255)' }}
        />
        <path
          className="cls-2"
          d="M 5.19 345.484 L 2 345.484 C 0.9 345.484 2 344.398 2 343.07 L 2 277.17 C 2 275.843 0.9 274.756 2 274.756 L 5.2 274.756"
          style={{
            fill: 'none',
            stroke: 'rgb(208, 210, 211)',
            strokeLineJoin: 'round',
            strokeWidth: '3px',
          }}
        />
        <path
          className="cls-1"
          d="M57,773.5a50.45,50.45,0,0,1-50.4-50.4V51.9A50.45,50.45,0,0, 1,57,1.5h264.1a50.45,50.45,0,0,1,50.4,50.4V723.1a50.44,50.44, 0,0,1-50.4,50.4Z"
          style={{ fill: 'rgb(255, 255, 255)' }}
        />
        <path
          className="cls-3"
          d="M321,3a49,49,0,0,1,48.9,48.9V723.1A48.91,48.91,0,0,1,321, 772H56.89A49,49,0,0,1,8,723.1V51.9A48.91,48.91,0,0,1,56.89, 3H321m0-3H56.89A51.92,51.92,0,0,0,5,51.9V723.1A51.92,51.92, 0,0,0,56.89,775H321a51.92,51.92,0,0,0,51.9-51.9V51.9A51.92, 51.92,0,0,0,321,0Z"
          style={{ fill: 'rgb(208, 210, 211)' }}
        />
        <path
          className="cls-4"
          d="M360.59,683a3,3,0,0,1-3,3H19.89a3,3,0,0,1-3-3V83a3.08,3.08,0,0,1,3-3h337.7a3,3,0,0,1,3,3Z"
          style={{
            fill: 'none',
            stroke: 'rgb(241, 241, 242)',
            strokeMiterlimit: 10,
          }}
        />
        <path
          className="cls-5"
          d="M360.59,683a3,3,0,0,1-3,3H19.89a3,3,0,0,1-3-3V83a3.08,3.08,0,0,1,3-3h337.7a3,3,0,0,1,3,3Z"
          style={{
            fill: 'none',
            stroke: 'rgb(219, 219, 219)',
            strokeMiterLimit: 10,
            strokeWidth: '2px',
          }}
        />
        <circle
          className="cls-6"
          cx="126.59"
          cy="41.8"
          r="6.6"
          style={{ fill: 'rgb(241, 241, 242)' }}
          transform="matrix(1, 0, 0, 1.20695, 0, -2.359228)"
        />
        <circle
          className="cls-7"
          cx="126.59"
          cy="41.8"
          r="6.6"
          style={{
            fill: 'none',
            stroke: 'rgb(219, 219, 219)',
            strokeMiterLimit: 10,
            strokeWidth: '1.5px',
          }}
          transform="matrix(1, 0, 0, 1.20695, 0, -2.359228)"
        />
        <circle
          className="cls-6"
          cx="188.19"
          cy="15.4"
          r="4"
          style={{ fill: 'rgb(241, 241, 242)' }}
          transform="matrix(1, 0, 0, 1.20695, 0, -2.359228)"
        />
        <circle
          className="cls-7"
          cx="188.19"
          cy="15.4"
          r="4"
          style={{
            fill: 'none',
            stroke: 'rgb(219, 219, 219)',
            strokeMiterLimit: 10,
            strokeWidth: '1.5px',
          }}
          transform="matrix(1, 0, 0, 1.20695, 0, -2.359228)"
        />
        <circle
          className="cls-8"
          cx="188.19"
          cy="731"
          r="30.1"
          style={{
            fill: 'none',
            stroke: 'rgb(208, 210, 211)',
            strokeMiterLimit: 10,
            strokeWidth: '3px',
          }}
        />
        <path
          className="cls-9"
          d="M 217.69 48.332 C 217.69 50.332 216.347 51.954 214.69 51.954 L 160.69 51.954 C 159.033 51.954 157.69 50.332 157.69 48.332 L 157.69 48.212 C 157.69 46.212 159.033 44.591 160.69 44.591 L 214.79 44.591 C 216.428 44.707 217.703 46.352 217.69 48.332 Z"
          style={{ fill: 'rgb(229, 229, 229)' }}
        />
        <path
          className="cls-5"
          d="M 217.69 48.332 C 217.69 50.332 216.347 51.954 214.69 51.954 L 160.69 51.954 C 159.033 51.954 157.69 50.332 157.69 48.332 L 157.69 48.212 C 157.69 46.212 159.033 44.591 160.69 44.591 L 214.79 44.591 C 216.428 44.707 217.703 46.352 217.69 48.332 Z"
          style={{
            fill: 'none',
            stroke: 'rgb(219, 219, 219)',
            strokeMiterLimit: 10,
            strokeWidth: '2px',
          }}
        />
        <path
          className="cls-10"
          d="M 361.102 590.37 C 361.107 592.484 359.767 594.198 358.11 594.198 L 20.41 594.198 C 18.753 594.198 17.407 592.484 17.402 590.37 L 16.378 82.649 C 16.415 80.559 17.731 78.876 19.37 78.822 L 357.07 78.822 C 358.727 78.822 360.073 80.535 360.078 82.649 L 361.102 590.37 Z"
          style={{
            fill: 'rgb(239, 239, 239)',
            stroke: 'rgb(219, 219, 219)',
            strokeMiterLimit: 10,
            strokeWidth: '2px',
          }}
        />
      </g>
    </g>
  </svg>
);

export default headerLarge;
