import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { TableContainer, Checkbox, CircularProgress } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Trans, useTranslation } from 'react-i18next';

import ActionMenu from 'app/components/actionMenu';
import Search from 'app/components/search';
import { Empty as EmptyIcon } from 'app/components/icons';
import { downloadCSV, formatDate } from 'utils';

import styles from './styles.module.scss';
import ForumDisabledWrapper from './components/ForumDisabledWrapper';
import themeStyles from '../../../../_export.scss';

const useStyles = makeStyles(() => ({
  appBar: {
    position: 'relative',
    color: themeStyles.primaryTextColor,
    backgroundColor: themeStyles.priamryColor,
  },
  title: {
    marginLeft: 20,
    flex: 1,
  },
  table: {
    '& .MuiTableCell-head': {
      color: 'black',
      backgroundColor: '#EAEEFF',
      fontSize: 14,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
      textAlign: 'center',
      borderBottom: 'none',
    },
    '& .MuiTableCell-body': {
      color: 'black',
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Raleway, sans-serif',
      textAlign: 'center',
      borderBottom: '1px solid #d3d3d3',
    },
  },
  tableLeftCorner: { borderTopLeftRadius: 10 },
  tableRightCorner: { borderTopRightRadius: 10 },
}));

const Scan = ({
  chunks,
  filteredChunks,
  getUsers,
  getGroups,
  history,
  pending,
  onToggleBan,
  onUpdateChecked,
  onAddLivestreamComponent,
  livestreamComponentEnabled,
  onViewAllUsers,
  onViewBannedUsers,
  onFilterUserData,
  onSelectAllUsers,
  onDeselectAllUsers,
  selectAll,
  selected,
  onSelect,
  groups,
  filter,
  resetUsersData,
  forumComponentEnabled,
  onAddForumComponent,
  onDeleteSelected,
}) => {
  const { t } = useTranslation();

  const [totalUsers, setTotalUsers] = useState(0);

  const classes = useStyles();

  const getCsvData = (selectedUserKeys, users) => {
    const csvColumns = [
      t('First name'),
      t('Last name'),
      t('Email'),
      t('Date created'),
      t('Groups'),
      t('Phone'),
      t('Verified'),
      t('Forum moderator'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selectedUserKeys.forEach(userKey => {
      const groupNames = [];
      const groupKeys = Object.keys(
        users[userKey].groups ? users[userKey].groups : {},
      );
      groupKeys.forEach(groupKey => {
        if (groups[groupKey]) groupNames.push(groups[groupKey].name);
      });

      data += `${users[userKey].firstName},${users[userKey].lastName},${
        users[userKey].email
      },${formatDate(users[userKey].created_at)},"${groupNames.join(
        ',',
      )}",${users[userKey].phoneNumber || '-'},${
        users[userKey].emailVerified ? t('yes') : t('no')
      },${users[userKey].hasForumAccess ? t('yes') : t('no')}\n`;
    });

    return data;
  };

  const handleCheck = (hasForumAccess, user) => {
    confirmAlert({
      title: `${t('Confirm to')} ${
        hasForumAccess ? t('remove') : t('grant')
      } access.`,
      message: `${
        hasForumAccess
          ? `${t('Are you sure you want to remove')} ${
              filteredChunks[user].email
            } ${t('from having moderator access?')}`
          : `${t('Are you sure you want to grant')} ${
              filteredChunks[user].email
            } ${t(
              'moderator access? Please make sure this is the right email to prevent misuse.',
            )}`
      }`,
      buttons: [
        {
          label: t('Yes'),
          onClick: () => onUpdateChecked(hasForumAccess, user),
        },
        {
          label: t('No'),
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    resetUsersData();
    getUsers();
    getGroups();
    return () => {
      resetUsersData();
    };
  }, []);

  useEffect(() => {
    const size = Object.keys(filteredChunks).length;
    setTotalUsers(size);
  }, [filteredChunks]);

  const content = (
    <>
      <div className={styles.header}>
        <span className={styles.header_text}>
          <Trans>Total Users</Trans> : <b>{totalUsers}</b>
        </span>
        <div className={styles.header_search}>
          <Search
            name="search"
            placeholder={t('Search...')}
            value={filter}
            onChange={onFilterUserData}
            onClear={() => onFilterUserData('')}
          />
        </div>
      </div>

      {pending && <CircularProgress />}
      {totalUsers === 0 && !pending ? (
        <div className="empty-state">
          <EmptyIcon />
          <h5>
            <Trans>You don't have any users.</Trans>
          </h5>
        </div>
      ) : (
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <Table
            aria-label={t('customized table')}
            className={classes.table}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell width="18%" className={classes.tableLeftCorner}>
                  <Trans>User Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Email</Trans>
                </TableCell>
                <TableCell className={classes.tableRightCorner}>
                  <Trans>Moderator Access</Trans>
                </TableCell>
                <TableCell className={classes.tableRightCorner}>
                  <div className="options-container">
                    <Checkbox
                      disableRipple
                      style={{ color: themeStyles.primaryColor }}
                      checked={!!selectAll}
                      onChange={(e, isChecked) =>
                        isChecked ? onSelectAllUsers() : onDeselectAllUsers()
                      }
                    />
                    <ActionMenu
                      selected={selected}
                      onDeleteSelected={() =>
                        onDeleteSelected(selected, chunks)
                      }
                      onDownloadSelected={() => {
                        downloadCSV(getCsvData(selected, chunks), 'users');
                      }}
                      text={t('users')}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredChunks &&
                Object.keys(filteredChunks).map(key => (
                  <TableRow key={filteredChunks[key].email}>
                    <TableCell component="th" scope="row">
                      {`${filteredChunks[key].firstName} ${filteredChunks[key].lastName}` ||
                        '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {filteredChunks[key].email || '-'}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        style={{ color: themeStyles.primaryColor }}
                        checked={!!filteredChunks[key].hasForumAccess}
                        onClick={() =>
                          handleCheck(!!filteredChunks[key].hasForumAccess, key)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <div className="options-container">
                        <Checkbox
                          checked={!!filteredChunks[key].selected}
                          onChange={() => onSelect(key)}
                          style={{ color: themeStyles.primaryColor }}
                          disableRipple
                        />
                        <ActionMenu
                          selected={[key]}
                          onDownloadSelected={() =>
                            downloadCSV(
                              getCsvData([key], chunks),
                              'livestream-users',
                            )
                          }
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );

  return !forumComponentEnabled ? (
    <ForumDisabledWrapper
      onAddForumComponent={onAddForumComponent}
      history={history}
    >
      {content}
    </ForumDisabledWrapper>
  ) : (
    content
  );
};

export default Scan;
