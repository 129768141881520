import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import CreateField from '../../../../editor/components/createField';
import Container from '../../../../../app/components/container';

const SubscriptionFeatures = ({ onAddFeature, features, onRemoveFeature }) => {
  const { t } = useTranslation();

  const handleRemove = index => {
    onRemoveFeature(index);
  };
  return (
    <Container>
      <CreateField
        id="google-maps-label"
        hintText={t('Add an Item')}
        tooltip={t('Create New Feature Item')}
        onSubmit={onAddFeature}
      />
      {features && (
        <List>
          {features.map((feature, index) => (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={feature} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label={t('delete')}
                  onClick={e => handleRemove(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </Container>
  );
};

export default SubscriptionFeatures;
