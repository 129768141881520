import filter from 'lodash/filter';
import get from 'lodash/get';
import firebase from '../../../../firebase';

export const REQUEST_TRANSACTION_HISTORY = 'REQUEST_TRANSACTION_HISTORY';
export const FINISH_REQUEST_TRANSACTION_HISTORY =
  'FINISH_REQUEST_TRANSACTION_HISTORY';
export const RECEIVE_TRANSACTION_HISTORY = 'RECEIVE_TRANSACTION_HISTORY';
export const FILTER_TRANSACTION_DATA = 'FILTER_TRANSACTION_DATA';
export const ERROR_RECEIVING_TRANSACTIONS = 'ERROR_RECEIVING_TRANSACTIONS';
export const SELECT_TRANSACTION = 'SELECT_TRANSACTION';
export const SELECT_ALL_TRANSACTIONS = 'SELECT_ALL_TRANSACTIONS';
export const DESELECT_ALL_TRANSACTIONS = 'DESELECT_ALL_TRANSACTIONS';

const requestTransactions = appname => ({
  type: REQUEST_TRANSACTION_HISTORY,
  appname,
});

const finishRequestTransactions = appname => ({
  type: FINISH_REQUEST_TRANSACTION_HISTORY,
  appname,
});

const receiveTransactions = transactions => ({
  type: RECEIVE_TRANSACTION_HISTORY,
  data: transactions,
});

const errorReceivingTransactions = () => ({
  type: ERROR_RECEIVING_TRANSACTIONS,
});
export const loadTransactions = appname => dispatch => {
  const myHeaders = new Headers();
  dispatch(requestTransactions(appname));
  firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);
      const payload = {
        appname,
      };
      return fetch(
        `${process.env.REACT_APP_PAYMENT_API}/fetch_transaction_history`,
        {
          method: 'post',
          headers: myHeaders,
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      )
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw response.json();
          }
        })
        .then(response => {
          if (response && response.data) {
            const appTransactions = filter(
              response.data,
              item => get(item, 'customer.metadata.appname') === appname,
            );
            if (appTransactions.length > 0) {
              dispatch(receiveTransactions(appTransactions));
            }
            dispatch(finishRequestTransactions(appname));
          } else {
            dispatch(finishRequestTransactions(appname));
          }
        })
        .catch(error => dispatch(errorReceivingTransactions()));
    });
};

export const filterData = filter => dispatch =>
  dispatch({
    type: FILTER_TRANSACTION_DATA,
    payload: filter,
  });

export const searchGroups = query => dispatch =>
  dispatch({
    type: 'SEARCH_GROUPS',
    query,
  });

export const deleteGroup = (appname, key) => dispatch => {
  if (key !== 'default') {
    firebase
      .database()
      .ref(`app_groups/${appname}/groups/${key}`)
      .remove()
      .then(() =>
        dispatch({
          type: 'GROUP_DELETED',
          appname,
          key,
        }),
      );
  }
};

export const deleteGroups = appname => (dispatch, getState) => {
  const { selected } = getState().auth.groups.data;

  Promise.all(selected.map(key => dispatch(deleteGroup(appname, key))));
};

export const selectTransaction = key => ({
  type: SELECT_TRANSACTION,
  key,
});

export const selectAllTransactions = () => ({ type: SELECT_ALL_TRANSACTIONS });
export const deselectAllTransactions = () => ({
  type: DESELECT_ALL_TRANSACTIONS,
});
