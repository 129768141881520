import React, { Fragment } from 'react';
import TwoThirdCenterImage from './images/twoThirdCenter.png';
import './styles.css';

const TwoThirdCenter = () => {
  return (
    <Fragment>
      <img
        className="imageWrapper"
        src={TwoThirdCenterImage}
        alt="Two Third Center"
      />
    </Fragment>
  );
};

export default TwoThirdCenter;
