/* eslint-disable no-plusplus */
/**
 * Returns the key of the first item within an object which matches the provided
 * function. Expects func to be curried, for example:
 * findFirst(allNotices, o => k => o[k].auto_popup);
 */
export const findFirst = (object, func) =>
  Object.keys(object).find(func(object));

const getKey = (obj, keys) =>
  obj && keys.length ? getKey(obj[keys[0]], keys.slice(1)) : obj;

export const get = (obj, path) =>
  obj === undefined || obj === null ? undefined : getKey(obj, path.split('.'));

export const filter = (obj, filterFunc) =>
  Object.keys(obj)
    .filter(key => filterFunc(obj[key]))
    .reduce((res, key) => ({ ...res, [key]: obj[key] }), {});

/**
 * Returns "array of objects" from firebase data "object of objects"
 * @param object object of Objects
 * @example objectToArray({someID: {someObject}, someID: {someObject}})
 * @returns [{id: someID, value: {someObject}}, {id: someID, value: {someObject}}]
 */
export const objectToArray = object =>
  object ? Object.entries(object).map(e => ({ id: e[0], value: e[1] })) : [];

export const arrayToObject = array => {
  const object = {};
  array.forEach(i => {
    object[i.id] = i.value;
  });
  return object;
};
