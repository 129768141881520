import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import FormDetails from './FormDetails';

import {
  closeFormDetailDialog,
  previousResponse,
  nextResponse,
} from './actions';
import { favouriteSelected, deleteSelected } from '../actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.analytics.form.formDetails,
  responseKey: state.analytics.form.formDetails.key,
  totalResponses: state.analytics.form.data.items.length,
  currentResponse:
    state.analytics.form.data.items
      .map(key => key === state.analytics.form.formDetails.key)
      .indexOf(true) + 1,
  forms: state.analytics.form,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRequestClose: () => dispatch(closeFormDetailDialog()),
  onPreviousResponse: (currentResponse, forms) =>
    dispatch(previousResponse(currentResponse, forms)),
  onNextResponse: (currentResponse, forms) =>
    dispatch(nextResponse(currentResponse, forms)),
  onFavourite: selected =>
    dispatch(favouriteSelected(ownProps.match.params.appname, selected)),
  onDelete: selected =>
    dispatch(deleteSelected(ownProps.match.params.appname, selected)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormDetails),
);
