import firebase from '../../../../../../../firebase';
// eslint-disable-next-line
export const fieldChange = (ref, value) => {
  const dataRef = firebase.database().ref(ref);

  dataRef.set(value);

  return {
    type: 'CUSTOM_BUTTON_FIELD_CHANGE',
    ref,
    value,
  };
};

export const handleDesignChange = (appname, key, type, value, prop) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${key}/design`);
  const attr = prop ? `${prop}/${type}` : type;

  dataRef.child(attr).set(value);

  return {
    type: 'BUTTON_DESIGN_CHANGE',
    appname,
    key,
    designType: type,
    value,
  };
};

const receiveGaTrackingId = gaTrackingId => ({
  type: 'RECEIVE_GA_TRACKING_ID',
  data: gaTrackingId,
});

export const loadGoogleAnalytics = appname => async dispatch => {
  const gaRef = firebase.database().ref(`apps/${appname}/gaTrackingId`);
  try {
    const snapshot = await gaRef.once('value');
    const gaTrackingId = await snapshot.val();
    return dispatch(receiveGaTrackingId(gaTrackingId));
  } catch (err) {
    console.error(err);
  }
};

const updateIconsArray = data => ({
  type: 'UPDATE_ICONS_ARRAY',
  payload: data,
});

export const getIconsFromStorage = () => async dispatch => {
  const storageRef = firebase.storage().ref();
  try {
    const icons = await storageRef.child('loyalty-icons').listAll();
    const urls = await Promise.all(
      icons.items.map(async ref => {
        const iconUrl = await ref.getDownloadURL();
        return iconUrl;
      }),
    );

    return dispatch(updateIconsArray(urls));
  } catch (err) {
    console.log(err);
  }
};
