import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import { setIsUpdating } from '../../../editor/modules/shared/appName/actions';
import { attemptSaveAppDomain } from '../../internals/appDomainInput/actions';
import { saveMetaData } from '../../actions';
import DomainChangeModal from './DomainChangeModal';
import { toggleDomainChangeModal } from './actions';

const mapStateToProps = (state, ownProps) => ({
  open: state.editor.internals.domainChangeModal,
  metaPendingState: _get(
    state,
    `editor.shared.appName.${ownProps.match.params.appname}.meta`,
  ),
  domain: _get(state, `editor.internals.appDomainInput.domain`),
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => {
    dispatch(toggleDomainChangeModal());
    dispatch(setIsUpdating(false));
  },
  handleSave: (metaData, domain) => {
    dispatch(toggleDomainChangeModal());
    dispatch(saveMetaData(ownProps.match.params.appname, metaData));
    dispatch(
      attemptSaveAppDomain(
        ownProps.match.params.appname,
        domain,
        ownProps.history,
      ),
    );
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DomainChangeModal),
);
