import React from 'react';

import './styles.css';

const Twitter = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 67.69 55.01"
  >
    <g id="Layer_2" data-name="Layer 2">
      <path
        style={{ fill: '#fff' }}
        d={`M67.69,6.51a27.71,27.71,0,0,1-8,2.19A13.93,13.93,0,0,0,65.82,1,27.79,27.79,0,0,1,57,4.38,13.9,13.9,0,0,0,33.33,17.05,39.42,39.42,0,0,
          1,4.71,2.54,13.9,13.9,0,0,0,9,21.08a13.81,13.81,0,0,1-6.29-1.74c0,.06,0,.12,0,.18A13.9,13.9,0,0,0,13.86,33.13a13.92,13.92,0,0,1-6.27.24,
          13.9,13.9,0,0,0,13,9.64A27.86,27.86,0,0,1,3.31,49,28.06,28.06,0,0,1,0,48.77,39.28,39.28,0,0,0,21.29,55C46.83,55,60.8,33.85,60.8,
          15.5q0-.9,0-1.8a28.18,28.18,0,0,0,6.93-7.19Zm0,0`}
      />
    </g>
  </svg>
);

export default Twitter;
