import i18n from 'i18next';
import firebase from '../../../../firebase';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';
import { isSpanishApp } from '../../../../config/constants';

export const setEmailType = emailType => dispatch => {
  dispatch({
    type: 'SET_EMAIL_TYPE',
    emailType,
  });
};

export const onSubjectChange = value => dispatch => {
  dispatch({
    type: 'SET_SUBJECT',
    value,
  });
};

export const onMailToChange = value => dispatch => {
  dispatch({
    type: 'SET_MAIL_TO',
    value,
  });
};

export const onFooterTeamNameChange = value => dispatch => {
  dispatch({
    type: 'SET_FOOTER_TEAM_NAME',
    value,
  });
};

// export const onFromEmailChange = value => dispatch => {
//   dispatch({
//     type: 'SET_FROM_EMAIL',
//     value,
//   });
// };

export const onMessageChange = (value, contentType) => dispatch => {
  dispatch({
    type: 'SET_MESSAGE',
    value,
    contentType,
  });
};

const saveCustomEmailComplete = () => ({
  type: 'SAVE_CUSTOM_EMAIL_COMPLETE',
});

export const updateEmailHeaderImage = image => ({
  type: 'SET_HEADER_IMAGE',
  value: image,
});

export const clearHeaderImage = (
  appname,
  nodeName = 'emailImage',
) => dispatch => {
  const pushImageRef = firebase.database().ref(`apps/${appname}/temp_data`);
  pushImageRef.update({
    [nodeName]: '',
  });
};

export const loadContent = (appname, nodeName = 'emailImage') => dispatch => {
  const emailImageRef = firebase
    .database()
    .ref(`apps/${appname}/temp_data/${nodeName}`);
  emailImageRef.on('value', snapshot => {
    let image = snapshot.val();
    dispatch(updateEmailHeaderImage(image));
  });
};

export const getEmailList = appname => dispatch => {
  let type = 'english';

  if (isSpanishApp(appname)) {
    type = 'spanish';
  }

  return firebase
    .database()
    .ref(`default_email_template/${type}`)
    .once('value', snapshot => {
      const data = Object.entries(snapshot.val()).map(item => ({
        key: item[0],
        value: item[1].title,
      }));

      dispatch({
        type: 'GET_EMAIL_LIST',
        value: data,
      });
    });
};

export const setTempData = (
  appname,
  emailType,
  emailNodeName = 'emailImage',
  headerNodeName = 'headerImage',
) => dispatch => {
  const emailImageRef = firebase
    .database()
    .ref(`apps/${appname}/temp_data/${emailNodeName}`);
  const headerImageRef = firebase
    .database()
    .ref(`apps/${appname}/custom_email/${emailType}/${headerNodeName}`);
  headerImageRef.once('value', snapshot => {
    let image = snapshot.val();
    emailImageRef.set(image);
  });
};

export const getCustomEmailTemplate = (appname, emailType) => dispatch => {
  return firebase
    .database()
    .ref(`apps/${appname}/custom_email/${emailType}`)
    .on('value', snapshot => {
      if (snapshot.exists()) {
        const emailData = snapshot.val();
        dispatch({
          type: 'SET_EMAIL_DATA',
          emailData,
        });
      } else {
        dispatch({
          type: 'SET_EMAIL_DATA',
          emailData: {},
        });
      }
    });
};

export const getDefaultTemplateText = emailType => async dispatch => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const user = firebase.auth().currentUser;

  headers.append('x-access-token', user);
  return fetch(`${process.env.REACT_APP_ADMIN_API}/get-email-template`, {
    method: 'POST',
    mode: 'cors',
    headers,
    body: JSON.stringify({
      emailType,
    }),
  })
    .then(response => response.json())
    .then(response => {
      dispatch({
        type: 'GET_TEMPLATE_TEXT',
        value: decodeURI(response.data),
      });
    })
    .catch(() =>
      dispatch({
        type: 'GET_TEMPLATE_TEXT',
        value: i18n.t('Something Went Wrong. Please try again later.'),
      }),
    );
};

export const saveCustomEmail = (appname, emailType) => async (
  dispatch,
  getState,
) => {
  let customEmail = getState().auth.customEmails.data.customEmail;

  await firebase
    .database()
    .ref(`apps/${appname}/${emailType}/image`)
    .on('value', snapshot => {
      if (snapshot.exists()) {
        console.log('snapshot', snapshot.val());
        customEmail.headerImage = snapshot.val();
      }
    });

  return await firebase
    .database()
    .ref(`apps/${appname}/custom_email/${emailType}`)
    .set(customEmail)
    .then(() => {
      dispatch(saveCustomEmailComplete());
      dispatch(openSnackbar(i18n.t('Email Templates changes are saved')));
    });
};

export const setEmailContent = value => dispatch => {
  dispatch({
    type: 'GET_TEMPLATE_TEXT',
    value,
  });
};
