import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import TextArea from '../../../../../../../app/components/textArea';
import Container from '../../../../../../../app/components/container';
import SectionHeader from '../../../../../components/sectionHeader';
import { Tabs, Tab } from '../../../../../components/tabs';
import { whitelabeledHost } from '../../../../../../../utils/whitelabeledHost';
import logo from './logo.png';
const isWhiteLabeledHost = whitelabeledHost();

const Appointedd = ({ component, onChange, isAppAdmin }) => {
  const { t } = useTranslation();

  return (
    <Tabs>
      <Tab label="content">
        <SectionHeader
          title={t('Appointedd')}
          tooltip={
            <span>
              <Trans>Follow the instructions below</Trans> <br />
              <Trans>to add an Appointedd booking app</Trans>
            </span>
          }
        />
        <div style={{ padding: '0 30px', marginTop: 24 }}>
          <p>
            <Trans>Enter your</Trans>{' '}
            <span style={{ color: '#DA1A26' }}>
              &quot;<Trans>Booking App</Trans>&quot;
            </span>{' '}
            <Trans>below if you have already set up an</Trans>&nbsp;
            <span style={{ color: '#DA1A26' }}>
              <Trans>Appointedd Account</Trans>
            </span>{' '}
            <Trans>otherwise</Trans>&nbsp;
            <a
              href="https://setup.appointedd.com/signup"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Sign Up</Trans>
            </a>
            &nbsp;<Trans>for a new account today.</Trans>
          </p>
        </div>
        <div style={{ padding: '0 12px' }}>
          <TextArea
            id="appointedd"
            name="Appointedd"
            label={t('Enter your Appointedd app embed code')}
            value={component.rawSrc}
            onChange={onChange}
          />
        </div>
        {!isAppAdmin && !isWhiteLabeledHost && (
          <div style={{ padding: '0 30px' }}>
            <p>
              <a
                href="https://www.beezer.com/appointedd"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Trans>Learn more</Trans>
              </a>
              &nbsp;
              <Trans>about how to setup your Appointedd booking app.</Trans>
            </p>
          </div>
        )}
        <Container>
          <img
            style={{ maxWidth: 190, marginTop: 24 }}
            src={logo}
            alt={t('Appointedd logo')}
          />
        </Container>
      </Tab>
      <Tab
        label={t('design')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
      <Tab
        label={t('layout')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
    </Tabs>
  );
};

Appointedd.propTypes = {
  component: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

Appointedd.defaultProps = {
  component: {},
};

export default Appointedd;
