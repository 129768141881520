import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import Reply from '@material-ui/icons/Reply';
import { BrowserRouter as Router } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import DefineApp from './DefineApp';
import { CreateWithSteps } from '../components/create';
import { SimpleToolbar } from '../../../../../../../app/modules/toolbar';
import {
  RadioMenu,
  RadioItem,
} from '../../../../../../../app/components/radioMenu';

import { store } from '../../../../../../../';
import Search from '../../../../../../../app/components/search/Search';

class CreateApp extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredAppTemplates: PropTypes.object.isRequired,
    title: PropTypes.shape({
      value: PropTypes.string,
      isValid: PropTypes.bool,
      maxLength: PropTypes.number,
      required: PropTypes.bool,
    }).isRequired,
    domain: PropTypes.shape({
      value: PropTypes.string,
      isValid: PropTypes.bool,
      isAvailable: PropTypes.bool,
      pending: PropTypes.bool,
      type: PropTypes.string,
      maxLength: PropTypes.number,
      required: PropTypes.bool,
    }).isRequired,
    skin: PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.string,
        content: PropTypes.string,
      }),
      PropTypes.string,
    ]).isRequired,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        subtext: PropTypes.string,
        isValid: PropTypes.bool,
      }),
    ).isRequired,
    error: PropTypes.string,
    profile: PropTypes.object.isRequired,
    organisation: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onSkinChange: PropTypes.func.isRequired,
    onTitleChange: PropTypes.func.isRequired,
    onDomainChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
  };

  state = {
    searchValue: '',
  };

  static defaultProps = {
    error: null,
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;

    onSubmit(() => {
      this.props.history.push(`/${this.props.domain.value}/editor`);
    });
  };

  handleCancel = () => {
    const { profile, organisation, history, onLogout } = this.props;

    // if the account has apps navigate to editor
    if (organisation.apps) {
      return history.push(`/${profile.lastVisited}/editor`);
    }

    // otherwise logout
    history.push('/');
    return onLogout();
  };

  handleSearch = searchText => {
    this.setState({ searchValue: searchText });
    this.props.onFilterTemplates(searchText);
  };

  renderPreview = (skin, newReturn) => (
    <a
      className="preview-skin-link"
      href={
        newReturn
          ? `https://${skin}.beezer.com/`
          : `https://preview${skin}.beezer.com/`
      }
      rel="noopener noreferrer"
      target="_blank"
    >
      <Trans>Preview</Trans>
      <Reply />
    </a>
  );

  renderContent = () => {
    const {
      skin,
      title,
      domain,
      onSkinChange,
      onTitleChange,
      onDomainChange,
      filteredAppTemplates,
      t,
    } = this.props;

    return [
      <>
        <Search
          name="search"
          placeholder={t('Search Templates')}
          value={this.state.searchValue}
          onChange={val => this.handleSearch(val)}
          onClear={() => this.handleSearch('')}
        />
        <RadioMenu
          selected={skin}
          onChange={onSkinChange}
          imageStyle={{ width: 128, marginLeft: 24, marginRight: 24 }}
        >
          {Object.keys(filteredAppTemplates).length > 0 ? (
            Object.keys(filteredAppTemplates).map(appTemplateId => (
              <RadioItem
                label={filteredAppTemplates[appTemplateId].label}
                value={{
                  type: filteredAppTemplates[appTemplateId].type,
                  content: filteredAppTemplates[appTemplateId].content,
                }}
                image={filteredAppTemplates[appTemplateId].image}
                className={
                  skin.type == filteredAppTemplates[appTemplateId].type
                    ? 'highlight'
                    : ''
                }
              >
                {this.renderPreview(
                  filteredAppTemplates[appTemplateId].appTitle,
                  true,
                )}
              </RadioItem>
            ))
          ) : (
            <p>
              <Trans>No results found</Trans>
            </p>
          )}
        </RadioMenu>
      </>,
      <DefineApp
        title={title}
        domain={domain}
        onTitleChange={onTitleChange}
        onDomainChange={onDomainChange}
      />,
    ];
  };

  render() {
    const { steps, organisation, pending, error, t } = this.props;
    const content = this.renderContent();
    const displayCancelButton = typeof organisation.apps !== 'undefined';

    return (
      <Router>
        <div>
          <Provider store={store}>
            <SimpleToolbar
              onExit={this.handleCancel}
              displayLogoutLink={true}
            />
            <CreateWithSteps
              title={t('Create New App')}
              steps={steps}
              stepContent={content}
              submitLabel={t('Create App')}
              onSubmit={this.handleSubmit}
              onCancel={displayCancelButton ? this.handleCancel : undefined}
              error={error}
              pending={pending}
              className="create-app"
            />
          </Provider>
        </div>
      </Router>
    );
  }
}

export default withTranslation()(CreateApp);
