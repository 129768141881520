import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import MuiTextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Trans, withTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import TextField from '../../../../../../../app/components/textField';
import { FlatButton } from '../../../../../../../app/components/buttons';
import CircularValidation from '../../../../../../../app/components/circularValidation';
import { CircularProgress } from '../../../../../../../app/components/progress';
import Toggle from '../../../../../../../app/components/toggle';
import { beezerSections } from '../editAdmin/modules/modal/EditAdmin';
import './styles.css';

const NewAdmin = props => {
  const {
    open,
    pending,
    error,
    onSubmit,
    onRequestClose,
    firstName,
    lastName,
    email,
    password,
    onFieldChange,
    adminAppname,
    match,
    apps,
    smsCredits,
    whatsappSMSCredits,
    adminSMSCredits,
    adminWhatsappSMSCredits,
    hasCustomDomain,
    checkAppDetails,
    newSubAdminSectionAccess,
    sectionsAccess,
    t,
  } = props;

  const handleAppChange = e => {
    onFieldChange('adminAppname', e.target ? e.target.value : [e]);
    checkAppDetails(e.target ? e.target.value : [e]);
  };

  useEffect(() => {
    if (Object.keys(apps).length === 1) {
      handleAppChange(Object.keys(apps)[0]);
    }
  }, [open]);

  const superAdminApps = Object.keys(apps);

  const displayError = error ? (
    <div>
      <strong>{error}</strong>
    </div>
  ) : null;

  const form = (
    <div>
      <h5>
        <Trans>Create an App Admin</Trans>
      </h5>
      <TextField
        id="firstName"
        placeholder={t('First Name')}
        {...firstName}
        onChange={value => onFieldChange('firstName', value)}
        rightInputAction={
          <CircularValidation
            isValid={firstName.isValid}
            defaultMsg={t("Admin's first name required")}
            errorMsg={t('First name must be at least 2 characters long')}
            showDefault={!firstName.value}
          />
        }
      />
      <TextField
        id="lastName"
        placeholder={t('Last Name')}
        {...lastName}
        onChange={value => onFieldChange('lastName', value)}
        rightInputAction={
          <CircularValidation
            isValid={lastName.isValid}
            defaultMsg={t("Admin's last name required")}
            errorMsg={t('Last name must be at least 2 characters long')}
            showDefault={!lastName.value}
          />
        }
      />
      <TextField
        id="email"
        placeholder={t('Email')}
        {...email}
        onChange={value => onFieldChange('email', value)}
        rightInputAction={
          <CircularValidation
            isValid={email.isValid}
            defaultMsg={t('Email address required')}
            errorMsg={t('Please use a valid email address')}
            showDefault={!email.value}
          />
        }
      />
      <TextField
        id="password"
        placeholder={t('Password')}
        type="password"
        {...password}
        onChange={value => onFieldChange('password', value)}
        rightInputAction={
          <CircularValidation
            isValid={password.isValid}
            defaultMsg={t('Password required')}
            errorMsg={t('Password must be at least 6 characters long')}
            showDefault={!password.value}
          />
        }
      />

      <div className="text-field-wrapper">
        <FormControl fullWidth>
          <InputLabel id="app-select-label" style={{ left: '-15px' }}>
            <Trans>Select app</Trans>
          </InputLabel>
          <Select
            labelId="app-select-label"
            id="app-select"
            multiple
            value={adminAppname.value || []}
            onChange={e => handleAppChange(e)}
            variant="standard"
          >
            {Object.keys(apps).map(appId => (
              <MenuItem value={appId}>{apps[appId].title}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="text-field-wrapper" style={{ marginTop: '14px' }}>
        <MuiTextField
          variant="standard"
          type="number"
          fullWidth
          label={t('SMS Credits')}
          value={adminSMSCredits.value}
          onChange={e =>
            e.target.value >= 0 &&
            e.target.value <= smsCredits &&
            onFieldChange('adminSMSCredits', e.target.value)
          }
          InputProps={{
            endAdornment: `/${smsCredits}`,
            min: 0,
            max: smsCredits,
          }}
          helperText={t('These credits will be deducted from your account.')}
        />
      </div>
      <div className="text-field-wrapper" style={{ marginTop: '14px' }}>
        <MuiTextField
          variant="standard"
          type="number"
          fullWidth
          label={t('Whatsapp Credits')}
          value={adminWhatsappSMSCredits.value}
          onChange={e =>
            e.target.value >= 0 &&
            e.target.value <= whatsappSMSCredits &&
            onFieldChange('adminWhatsappSMSCredits', e.target.value)
          }
          InputProps={{
            endAdornment: `/${whatsappSMSCredits}`,
            min: 0,
            max: whatsappSMSCredits,
          }}
          helperText={t('These credits will be deducted from your account.')}
        />
      </div>
      <div
        className="text-field-wrapper"
        style={{ marginTop: '14px', padding: '0px' }}
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span style={{ fontSize: '14px' }}>Platform Access</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="restricted_access_container">
              {beezerSections.map(section => (
                <div key={section.value}>
                  <p>{section.label}</p>
                  <Toggle
                    style={{ margin: '0' }}
                    onToggle={(v, b) => {
                      newSubAdminSectionAccess(section.value, b);
                    }}
                    toggled={sectionsAccess[section.value]}
                  />
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );

  const buttonSubmit = !pending ? (
    <FlatButton
      label={t('Create')}
      onClick={onSubmit}
      disabled={
        !firstName.isValid ||
        !lastName.isValid ||
        !email.isValid ||
        !password.isValid ||
        !adminAppname.isValid ||
        !superAdminApps.some(v => adminAppname.value.includes(v))
      }
      primary
    />
  ) : (
    <CircularProgress size={18} />
  );

  return (
    <Dialog open={open} onClose={onRequestClose} autoScrollBodyContent>
      <DialogTitle>
        <div className="modal-controls-container">
          <IconButton onClick={onRequestClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="scroll-container">
        <div className="add-admin-form">
          <div className="list-fieldset">{displayError}</div>
          {form}
          {!hasCustomDomain && (
            <section className="info-box">
              <div className="instruction-box">
                <p className="info">
                  <Trans>We recommend adding a custom domain to your</Trans>{' '}
                  <a
                    href="https://beezer.zendesk.com/hc/en-us/articles/360048189151-Custom-Domain"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ fontWeight: 700 }}
                  >
                    <Trans>appshell</Trans>
                  </a>{' '}
                  <Trans>and to your</Trans>{' '}
                  <a
                    href="https://beezer.zendesk.com/hc/en-us/articles/360048189151-Custom-Domain"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ fontWeight: 700 }}
                  >
                    <Trans>admin dashboard</Trans>
                  </a>{' '}
                  <Trans>
                    before creating an app admin. By setting up a custom domain
                    you will hide the “.beezer.com” URL in the admin dashboard
                    and the appshell.
                  </Trans>
                </p>

                <p className="info">
                  <Trans>Set up</Trans>{' '}
                  <Link
                    to={`/${match.params.appname}/editor/build/custom_domain`}
                  >
                    <Trans>custom domain</Trans>
                  </Link>{' '}
                  <Trans>for appshell.</Trans>
                </p>
                <p>
                  <Trans>Set up</Trans>{' '}
                  <Link
                    to={`/${match.params.appname}/editor/build/custom_domain`}
                  >
                    <Trans>custom domain</Trans>
                  </Link>{' '}
                  <Trans>for admin dashboard.</Trans>
                </p>
              </div>
            </section>
          )}
          {buttonSubmit}
        </div>
      </DialogContent>
    </Dialog>
  );
};

NewAdmin.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  firstName: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  adminAppname: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  lastName: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  email: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  password: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  onFieldChange: PropTypes.func.isRequired,
  apps: PropTypes.object.isRequired,
  newSubAdminSectionAccess: PropTypes.func.isRequired,
  sectionsAccess: PropTypes.object.isRequired,
};

NewAdmin.defaultProps = {
  error: null,
};

export default withTranslation()(NewAdmin);
