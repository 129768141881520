import { newValidator } from '../../../../../utils';

const emailValidator = newValidator({
  type: 'email',
  required: true,
});

export const validateField = {
  email: value => emailValidator(value),
};
