import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';

class HiddenDatePicker extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    ts: PropTypes.string,
  };

  componentWillMount() {
    let date = new Date();

    if (this.props.ts) {
      date = new Date(this.props.ts);
    } else {
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
    }

    this.setState({ date });
  }

  open = () => this.datePickerRef.focus();

  handleDateChange = (e, ts) => {
    const nextDate = new Date(ts);
    const date = new Date(this.state.date.getTime());

    const d = nextDate.getDate();
    const m = nextDate.getMonth();
    const y = nextDate.getFullYear();

    date.setDate(d);
    date.setMonth(m);
    date.setFullYear(y);

    this.setState({ date });

    this.timePickerRef.focus();
  };

  handleTimeChange = (e, ts) => {
    const nextDate = new Date(ts);
    const date = new Date(this.state.date.getTime());

    const m = nextDate.getMinutes();
    const h = nextDate.getHours();

    date.setMinutes(m);
    date.setHours(h);

    this.setState({ date });

    this.props.onChange(date.toISOString());
  };

  handleTimeDismiss = () => this.datePickerRef.focus();

  render() {
    return (
      <span>
        <span className={this.props.show ? '' : 'hidden'}>
          <DatePicker
            id="date_picker_hidden"
            ref={node => {
              this.datePickerRef = node;
            }}
            mode="landscape"
            value={this.state.date}
            onChange={this.handleDateChange}
            formatDate={d =>
              moment(d).format(
                this.props.onlyDate ? 'MMMM Do YYYY' : 'MMMM Do YYYY, h:mm a',
              )
            }
          />
          <TimePicker
            id="time_picker_hidden"
            ref={node => {
              this.timePickerRef = node;
            }}
            cancelLabel="Back"
            value={this.state.date}
            onDismiss={this.handleTimeDismiss}
            onChange={this.handleTimeChange}
          />
        </span>
      </span>
    );
  }
}

export default HiddenDatePicker;
