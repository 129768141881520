/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { parseDomain } from 'parse-domain';
import { Trans, withTranslation } from 'react-i18next';
import TextField from '../../../../../app/components/textField';
import { FlatButton } from '../../../../../app/components/buttons';
import Container from '../../../../../app/components/container';
import SectionHeader from '../../../components/sectionHeader';
import firebase from '../../../../../firebase';
import CreateComponentItem from '../../build/modules/create/createComponent/CreateComponentItem';
import CustomDomainIcon from '../../../components/icons/CustomDomain';
import DialogConfirmation from '../../../../../app/components/dialogConfirmation/DialogConfirmation';
import { Tabs, Tab } from '../../../components/tabs';
import './styles.css';
import { whitelabeledHost } from '../../../../../utils/whitelabeledHost';

const getNextInvoiceAmount = ({ appname, accountId, subscriptionItem }) => {
  const subscription = subscriptionItem.items[appname];
  const subscriptionId = subscription.id;

  const payload = {
    subscriptionId,
    accountId,
  };

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      const options = {
        method: 'post',
        body: JSON.stringify(payload),
        headers,
        mode: 'cors',
      };
      return fetch(
        `${process.env.REACT_APP_PAYMENT_API}/get_next_invoice_amount`,
        options,
      )
        .then(res => res.json())
        .then(body => {
          const { amount } = body;
          return amount;
        })
        .catch(error => null);
    });
};

class customDomain extends Component {
  static propTypes = {
    customDomain: PropTypes.object,
    appname: PropTypes.string,
    updateCustomDomainData: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    hasAccess: PropTypes.bool.isRequired,
    hasAccessPlatform: PropTypes.bool.isRequired,
    showAddonCard: PropTypes.bool.isRequired,
    showAddonCardPlatform: PropTypes.bool.isRequired,
    deleteCustomDomain: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    customDomain: null,
    appname: '',
  };

  state = {
    domain: (this.props.customDomain && this.props.customDomain.domain) || '',
    platformDomain:
      (this.props.customDomain && this.props.customDomain.platformDomain) || '',
    hasChanged: false,
    isVerifiyingAppDomain: false,
    isVerifiyingPlatformDomain: false,

    domainSetupStatus:
      (this.props.customDomain &&
        this.props.customDomain.domain_setup_status) ||
      false,
    platformDomainSetupStatus:
      (this.props.customDomain &&
        this.props.customDomain.platform_domain_setup_status) ||
      false,
    platformHasChanged: false,
    enabled:
      (this.props.customDomain && this.props.customDomain.enabled) || false,
    platformEnabled:
      (this.props.customDomain && this.props.customDomain.platformEnabled) ||
      false,
    errorMsg: '',
    platformErrorMsg: '',
    resourceRecords:
      (this.props.customDomain && this.props.customDomain.resource_records) ||
      [],
    cloudfrontDomain:
      (this.props.customDomain && this.props.customDomain.cloudfront_domain) ||
      '',
    platformResourceRecords:
      (this.props.customDomain &&
        this.props.customDomain.platform_resource_records) ||
      [],
    platformCloudfrontDomain:
      (this.props.customDomain &&
        this.props.customDomain.platform_cloudfront_domain) ||
      '',
    fetching: false,
    platformFetching: false,
    removeDomainDialog: false,
    removePlatformDomainDialog: false,
    nextInvoiceAmount: 0,
  };

  checkIsValidDomain = domainName => {
    const re = new RegExp(
      /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,63})$/,
    );

    return domainName.match(re);
  };

  domainNotAvailable = this.props.t(
    'Requested domain is not available. If you own that domain, follow the instructions and try again.',
  );

  enableSetUpDomain = () => {
    const { hasChanged, domain, enabled, resourceRecords } = this.state;
    const isValid = this.checkIsValidDomain(domain);

    if (isValid && !hasChanged && enabled && resourceRecords.length === 0) {
      return true;
    }

    return hasChanged && isValid && enabled;
  };

  enableSetUpPlatformDomain = () => {
    const {
      platformHasChanged,
      platformDomain,
      platformEnabled,
      platformResourceRecords,
    } = this.state;
    const isValid = this.checkIsValidDomain(platformDomain);

    if (
      isValid &&
      !platformHasChanged &&
      platformEnabled &&
      platformResourceRecords.length === 0
    ) {
      return true;
    }

    return platformHasChanged && isValid && platformEnabled;
  };

  async componentDidMount() {
    if (this.props.subscription && this.props.subscription.includes('diy')) {
      const amount = await getNextInvoiceAmount({
        appname: this.props.appname,
        accountId: this.props.accountId,
        subscriptionItem: this.props.subscriptionItem,
      });

      this.setState({
        nextInvoiceAmount: amount,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.customDomain &&
      prevProps.customDomain &&
      this.props.customDomain.domain !== prevProps.customDomain.domain
    ) {
      this.setState({
        domain: this.props.customDomain.domain || '',
        enabled: this.props.customDomain.enabled || false,
        errorMsg: '',
        resourceRecords: this.props.customDomain.resource_records || [],
        cloudfrontDomain: this.props.customDomain.cloudfront_domain || '',
        fetching: false,
      });
    }
    if (
      this.props.customDomain &&
      prevProps.customDomain &&
      this.props.customDomain.platformDomain !==
        prevProps.customDomain.platformDomain
    ) {
      this.setState({
        platformDomain: this.props.customDomain.platformDomain || '',
        platformEnabled: this.props.customDomain.platformEnabled || false,
        platformErrorMsg: '',
        platformResourceRecords:
          this.props.customDomain.platform_resource_records || [],
        platformCloudfrontDomain:
          this.props.customDomain.platform_cloudfront_domain || '',
        platformFetching: false,
      });
    }

    if (
      this.props.customDomain &&
      prevProps.customDomain &&
      this.props.customDomain.domain_setup_status !==
        prevProps.customDomain.domain_setup_status
    ) {
      this.setState({
        domainSetupStatus: this.props.customDomain.domain_setup_status,
      });
    }
    if (
      this.props.customDomain &&
      prevProps.customDomain &&
      this.props.customDomain.platform_domain_setup_status !==
        prevProps.customDomain.platform_domain_setup_status
    ) {
      this.setState({
        platformDomainSetupStatus: this.props.customDomain
          .platform_domain_setup_status,
      });
    }
  }
  onValidateDomain = async () => {
    const { t } = this.props;
    try {
      // Validation
      const { domain } = this.state;
      const isValid = this.checkIsValidDomain(domain);
      const { appname, updateCustomDomainData } = { ...this.props };

      if (!isValid) {
        return null;
      }

      this.setState({ fetching: true, errorMsg: '' });

      // Set up custom domain
      const myHeaders = new Headers();
      const user = firebase.auth().currentUser;
      const customDomainApi = `${process.env.REACT_APP_CUSTOM_DOMAIN_API}/verify`;

      const token = await user.getIdToken();
      myHeaders.append('x-access-token', token);
      myHeaders.append('Content-Type', 'application/json');

      const res = await fetch(customDomainApi, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify({
          appname,
        }),
        mode: 'cors',
      });

      // Handle response
      const data = await res.json();

      const { type, cloudfront_domain: cloudfrontDomain } = { ...data };

      if (type === 'pending_validation') {
        this.setState({
          errorMsg: t(
            "We couldn't find the DNS records, please make sure you have entered them correctly and try again in 15 minutes.",
          ),
          fetching: false,
        });
        return null;
      }

      if (res.status !== 200) {
        const message = (data && data.message) || '';
        throw new Error(message);
      }

      if (!cloudfrontDomain) {
        throw new Error(t('Undefined cloudfrontDomain'));
      }

      this.setState({
        cloudfrontDomain,
        errorMsg: '',
        hasChanged: false,
        enabled: true,
        fetching: false,
      });
      updateCustomDomainData({
        cloudfront_domain: cloudfrontDomain,
      });
    } catch (error) {
      let errorMessage = t('Something went wrong, please try again');

      if (
        error &&
        error.message &&
        error.message.includes('CNAMEAlreadyExists')
      ) {
        errorMessage = t(
          'Invalid DNS records. You need to remove the CNAME entry that is pointing to the cloudfront.net domain. After that, try again.',
        );
      }
      this.setState({
        errorMsg: errorMessage,
        fetching: false,
      });

      if (process.env.REACT_APP_PLATFORM_ENV !== 'production') {
        console.log(t('Error at onValidateDomain'), error);
      }
    }

    return null;
  };

  onValidatePlatformDomain = async () => {
    const { t } = this.props;
    try {
      // Validation
      const { platformDomain } = this.state;
      const isValid = this.checkIsValidDomain(platformDomain);
      const { appname, updateCustomDomainData } = { ...this.props };

      if (!isValid) {
        return null;
      }

      this.setState({ platformFetching: true, platformErrorMsg: '' });

      // Set up custom domain
      const myHeaders = new Headers();
      const user = firebase.auth().currentUser;
      const customDomainApi = `${process.env.REACT_APP_CUSTOM_DOMAIN_API}/verify-platform`;

      const token = await user.getIdToken();
      myHeaders.append('x-access-token', token);
      myHeaders.append('Content-Type', 'application/json');

      const res = await fetch(customDomainApi, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify({
          appname,
        }),
        mode: 'cors',
      });

      // Handle response
      const data = await res.json();

      const { type, platform_cloudfront_domain: platformCloudfrontDomain } = {
        ...data,
      };

      if (type === 'pending_validation') {
        this.setState({
          platformErrorMsg: t(
            "We couldn't find the DNS records, please make sure you have entered them correctly and try again in 15 minutes.",
          ),
          platformFetching: false,
        });
        return null;
      }

      if (res.status !== 200) {
        const message = (data && data.message) || '';
        throw new Error(message);
      }

      if (!platformCloudfrontDomain) {
        throw new Error(t('Undefined cloudfrontDomain'));
      }

      this.setState({
        platformCloudfrontDomain,
        platformErrorMsg: '',
        platformHasChanged: false,
        platformEnabled: true,
        platformFetching: false,
      });
      updateCustomDomainData({
        platform_cloudfront_domain: platformCloudfrontDomain,
      });
    } catch (error) {
      let errorMessage = t('Something went wrong, please try again');

      if (
        error &&
        error.message &&
        error.message.includes('CNAMEAlreadyExists')
      ) {
        errorMessage = t(
          'Invalid DNS records. You need to remove the CNAME entry that is pointing to the cloudfront.net domain. After that, try again.',
        );
      }
      this.setState({
        platformErrorMsg: errorMessage,
        platformFetching: false,
      });

      if (process.env.REACT_APP_PLATFORM_ENV !== 'production') {
        console.log(t('Error at onValidateDomain'), error);
      }
    }

    return null;
  };

  onSetUpCustomDomain = async () => {
    const { t } = this.props;
    try {
      // Validation
      const { domain, enabled } = this.state;
      const { appname, updateCustomDomainData } = this.props;

      this.setState({
        fetching: true,
        errorMsg: '',
      });

      // Set up custom domain
      const myHeaders = new Headers();
      const user = firebase.auth().currentUser;
      const customDomainApi = `${process.env.REACT_APP_CUSTOM_DOMAIN_API}`;

      const token = await user.getIdToken();
      myHeaders.append('x-access-token', token);
      myHeaders.append('Content-Type', 'application/json');

      const res = await fetch(customDomainApi, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify({
          appname,
          domain,
          enabled,
        }),
        mode: 'cors',
      });

      // Handle response
      const data = await res.json();

      const { type, resource_records: resourceRecords } = { ...data };

      if (type === 'domain_not_available') {
        this.setState({
          errorMsg: this.domainNotAvailable,
          fetching: false,
        });
        return null;
      }

      if (res.status !== 200) {
        throw new Error(`Status ${res.status}`);
      }

      this.setState({
        resourceRecords,
        hasChanged: false,
        fetching: false,
        cloudfrontDomain: '',
      });
      updateCustomDomainData({
        resource_records: resourceRecords,
        cloudfront_domain: '',
      });
    } catch (error) {
      this.setState({
        errorMsg: t('Something went wrong, please try again'),
        fetching: false,
      });

      if (process.env.REACT_APP_PLATFORM_ENV !== 'production') {
        console.log(t('Error at onSetUpCustomDomain'), error);
      }
    }

    return null;
  };

  onSetUpCustomPlatformDomain = async () => {
    const { t } = this.props;
    try {
      // Validation
      const { platformDomain, platformEnabled } = this.state;
      const { appname, updateCustomDomainData } = this.props;

      this.setState({
        platformFetching: true,
        platformErrorMsg: '',
      });

      // Set up custom domain
      const myHeaders = new Headers();
      const user = firebase.auth().currentUser;
      const customDomainApi = `${process.env.REACT_APP_CUSTOM_DOMAIN_API}/platform`;

      const token = await user.getIdToken();
      myHeaders.append('x-access-token', token);
      myHeaders.append('Content-Type', 'application/json');

      const res = await fetch(customDomainApi, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify({
          appname,
          platformDomain,
          platformEnabled,
        }),
        mode: 'cors',
      });

      // Handle response
      const data = await res.json();

      const { type, platform_resource_records: platformResourceRecords } = {
        ...data,
      };

      if (type === 'domain_not_available') {
        this.setState({
          platformErrorMsg: this.domainNotAvailable,
          platformFetching: false,
        });
        return null;
      }

      if (res.status !== 200) {
        throw new Error(`${t('Status')} ${res.status}`);
      }

      this.setState({
        platformResourceRecords,
        platformHasChanged: false,
        platformFetching: false,
        platformCloudfrontDomain: '',
      });
      updateCustomDomainData({
        platform_resource_records: platformResourceRecords,
        platform_cloudfront_domain: '',
      });
    } catch (error) {
      this.setState({
        platformErrorMsg: t('Something went wrong, please try again'),
        platformFetching: false,
      });

      if (process.env.REACT_APP_PLATFORM_ENV !== 'production') {
        console.log(t('Error at onSetUpCustomDomain'), error);
      }
    }

    return null;
  };

  onVerifyDomainSetup = async domainType => {
    const { t } = this.props;
    try {
      if (domainType === 'platform') {
        this.setState({
          platformFetching: true,
          isVerifiyingPlatformDomain: true,
        });
      } else {
        this.setState({ fetching: true, isVerifiyingAppDomain: true });
      }
      const user = await firebase.auth().currentUser;
      this.props.checkIsDomainSetupDone(user, domainType).then(() => {
        if (domainType === 'platform') {
          this.setState({ platformFetching: false });
        } else {
          this.setState({ fetching: false });
        }
      });
    } catch (e) {
      this.setState({
        errorMsg: t('Domain could not be deleted, please try again later.'),
      });
    }
  };
  onDeleteCustomDomain = async () => {
    const { t } = this.props;
    try {
      const user = await firebase.auth().currentUser;
      this.props.deleteCustomDomain(user);
    } catch (e) {
      this.setState({
        errorMsg: t('Domain could not be deleted, please try again later.'),
      });
    }
  };

  onDeletePlatformCustomDomain = () => {
    const user = firebase.auth().currentUser;
    this.props.deletePlatformCustomDomain(user);
  };

  toggleRemoveDomainDialog = dialogState =>
    this.setState({
      removeDomainDialog: dialogState,
    });

  toggleRemovePlatformDomainDialog = dialogState =>
    this.setState({
      removePlatformDomainDialog: dialogState,
    });

  copyToClipboard = e => {
    var range = document.createRange();
    range.selectNode(e.target);
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    const newClipText = window.getSelection().toString();
    navigator.clipboard.writeText(newClipText);
    this.props.copyTextSuccess('Copied to Clipboard');
    window.getSelection().removeAllRanges(); // to deselect
  };

  validateDomainInstructions = resourceRecords => {
    const { t } = this.props;
    return (
      <>
        <SectionHeader
          title={t('Domain validation')}
          tooltip={t(
            'Add the following CNAME records to proof the domain ownership',
          )}
        />
        <div>
          <small className="custom-domain-message">
            <Trans>
              Visit the website where you registered your domain (e.g. GoDaddy,
              HostGator or Namecheap) Go to the DNS settings of your domain.
            </Trans>
          </small>
        </div>
        <small className="custom-domain-message">
          <span>
            <Trans>Create a CNAME entry, and enter the following record:</Trans>
          </span>
          <div className="host_container_text">
            <div className="title">
              <Trans>Host</Trans>
            </div>
            <div
              onClick={this.copyToClipboard}
              className="host_record_text"
              title={t('Copy to Clipboard')}
            >
              {resourceRecords[0].Name}
            </div>
          </div>
          <div className="host_container_text">
            <div className="title">
              <Trans>Points To</Trans>
            </div>
            <div
              onClick={this.copyToClipboard}
              className="host_record_text"
              title={t('Copy to Clipboard')}
            >
              {resourceRecords[0].Value}
            </div>
          </div>
        </small>
        {resourceRecords[1] && resourceRecords[1].Name && (
          <div>
            <small className="custom-domain-message">
              <span>
                <Trans>
                  Create another CNAME entry, and enter the following record:
                </Trans>
              </span>
              <div className="host_container_text">
                <div className="title">
                  <Trans>Host</Trans>
                </div>
                <div
                  onClick={this.copyToClipboard}
                  className="host_record_text"
                  title={t('Copy to Clipboard')}
                >
                  {resourceRecords[1].Name}
                </div>
              </div>
              <div className="host_container_text">
                <div className="title">
                  <Trans>Points To</Trans>
                </div>
                <div
                  onClick={this.copyToClipboard}
                  className="host_record_text"
                  title={t('Copy to Clipboard')}
                >
                  {resourceRecords[1].Value}
                </div>
              </div>
            </small>
          </div>
        )}
        <div>
          <small className="custom-domain-validation-message">
            <Trans>
              If you have successfully added the records to your DNS settings
              you can
            </Trans>{' '}
            {this.state.errorMsg === this.domainNotAvailable
              ? t('setup your')
              : t('validate your')}{' '}
            <Trans>domain</Trans>
          </small>
        </div>
      </>
    );
  };

  getSubdomainPrefix = url => {
    const { subDomains } = parseDomain(url);

    return (subDomains.length > 0 && subDomains[0]) || '';
  };

  configureDnsInstructions = (cloudfrontDomain, url) => {
    let prefix = this.getSubdomainPrefix(url);
    let subdomain = url;

    if (!prefix) {
      prefix = 'WWW';
      subdomain = `www.${url}`;
    }
    const { t } = this.props;
    return (
      <>
        <SectionHeader
          title={t('DNS Settings')}
          tooltip={t('Configure the DNS and connect to your Beezer App')}
        />

        <small className="custom-domain-message">
          {t(
            `Visit the website where you registered your domain (e.g. GoDaddy,  HostGator or Namecheap) Go to the DNS settings of your domain.`,
          )}
        </small>
        <small className="custom-domain-message">
          <strong>
            <Trans>Connect your domain</Trans>{' '}
          </strong>
        </small>
        <small className="custom-domain-message">
          <span>
            <Trans>Create a CNAME entry, and enter the following record:</Trans>
          </span>
          <div className="host_container_text">
            <div className="title">
              <Trans>Host</Trans>
            </div>
            <div
              onClick={this.copyToClipboard}
              className="host_record_text"
              title={t('Copy to Clipboard')}
            >
              {prefix}
            </div>
          </div>
          <div className="host_container_text">
            <div className="title">
              <Trans>Points To</Trans>
            </div>
            <div
              onClick={this.copyToClipboard}
              className="host_record_text"
              title={t('Copy to Clipboard')}
            >
              {cloudfrontDomain}
            </div>
          </div>
        </small>
        {subdomain.includes('www.') && (
          <>
            <small className="custom-domain-message">
              <strong>
                <Trans>Setup redirect for your domain</Trans>
              </strong>
            </small>
            <small className="custom-domain-message">
              {subdomain.includes('www.') && (
                <div className="host_container_text">
                  {`${t(
                    'Redirect the traffic from your domain to',
                  )} ${subdomain}`}
                  <div>
                    {`${t(
                      "Find the 'Forwarding' option and select the settings 'forward only', the forward type 'Permanent (301)' and point it to",
                    )} `}
                    <div
                      onClick={this.copyToClipboard}
                      className="host_record_text"
                      title={t('Copy to Clipboard')}
                    >
                      {`http://${subdomain}`}
                    </div>
                  </div>
                </div>
              )}
            </small>
          </>
        )}
      </>
    );
  };

  domainSetupSuccessfull = (cloudfrontDomain, url) => {
    return (
      <>
        <SectionHeader title={this.props.t('Domain setup')} />
        <small className="domain-setup-success-msg">
          <Trans>Your custom domain has been setup successfully</Trans>
        </small>
      </>
    );
  };

  showDomainErrorMsg = errorMsg => (
    <small className="error-message error-in-panel">{errorMsg}</small>
  );

  showDomainSetupFailedMsg = () => (
    <small className="error-message error-in-panel">
      <Trans>Domain setup verification is not complete yet.</Trans>
      <br />{' '}
      <Trans>
        It might take up to 15 minutes for the new DNS settings to be enabled.
      </Trans>
      <br />{' '}
      <Trans>
        Check that you have entered the correct DNS settings and click on Verify
        Domain Setup again in a few minutes.
      </Trans>
    </small>
  );

  renderButton = () => {
    const { t } = this.props;
    const {
      resourceRecords,
      enabled,
      cloudfrontDomain,
      hasChanged,
      fetching,
      domainSetupStatus,
    } = {
      ...this.state,
    };
    if (
      fetching ||
      (this.props.customDomain &&
        this.props.customDomain.domain &&
        !this.props.customDomain.resource_records)
    ) {
      return <CircularProgress size={30} thickness={5} />;
    }

    const hasResourceRecords =
      (resourceRecords && resourceRecords.length) || false;

    if (!hasResourceRecords || hasChanged || !enabled) {
      return (
        <FlatButton
          label={t('Set up Domain')}
          onClick={() => this.onSetUpCustomDomain()}
          disabled={!this.enableSetUpDomain()}
          primary
        />
      );
    }

    if (!cloudfrontDomain) {
      return (
        <FlatButton
          label={t('Validate Domain')}
          onClick={() => this.onValidateDomain()}
          primary
        />
      );
    }

    if (cloudfrontDomain && !domainSetupStatus) {
      return (
        <FlatButton
          label={t('Verify Domain Setup')}
          onClick={() => this.onVerifyDomainSetup('appshell')}
          primary
        />
      );
    }
    return null;
  };

  renderPlatformButton = () => {
    const {
      platformResourceRecords,
      platformEnabled,
      platformCloudfrontDomain,
      platformHasChanged,
      platformFetching,
      platformDomainSetupStatus,
    } = {
      ...this.state,
    };
    const { t } = this.props;

    if (
      platformFetching ||
      (this.props.customDomain &&
        this.props.customDomain.platformDomain &&
        !this.props.customDomain.platform_resource_records)
    ) {
      return <CircularProgress size={30} thickness={5} />;
    }

    const hasResourceRecords =
      (platformResourceRecords && platformResourceRecords.length) || false;

    if (!hasResourceRecords || platformHasChanged || !platformEnabled) {
      return (
        <FlatButton
          label={t('Set up Domain')}
          onClick={() => this.onSetUpCustomPlatformDomain()}
          disabled={!this.enableSetUpPlatformDomain()}
          primary
        />
      );
    }

    if (!platformCloudfrontDomain) {
      return (
        <FlatButton
          label={t('Validate Domain')}
          onClick={() => this.onValidatePlatformDomain()}
          primary
        />
      );
    }

    if (platformCloudfrontDomain && !platformDomainSetupStatus) {
      return (
        <FlatButton
          label={t('Verify Domain Setup')}
          onClick={() => this.onVerifyDomainSetup('platform')}
          primary
        />
      );
    }

    return null;
  };

  renderRemoveDomainButton = () =>
    this.props.customDomain &&
    Object.keys(this.props.customDomain).length > 0 &&
    this.props.customDomain.domain && (
      <FlatButton
        label={this.props.t('Remove Domain')}
        onClick={() => this.toggleRemoveDomainDialog(true)}
      />
    );

  renderRemovePlatformDomainButton = () => {
    const { t } = this.props;
    return (
      this.props.customDomain &&
      this.props.customDomain.platformDomain && (
        <FlatButton
          label={t('Remove Domain')}
          onClick={() => this.toggleRemovePlatformDomainDialog(true)}
        />
      )
    );
  };
  noAccess = type => {
    const { t } = this.props;
    return (
      <Container>
        <div className={'upgrade-plan-content'}>
          <h3>
            <Trans>Upgrade your plan</Trans>
          </h3>
          <p>
            <Trans>
              You need to upgrade your plan or purchase the custom domain
            </Trans>{' '}
            {type} <Trans>addon to use this feature.</Trans>
          </p>
          {this.props.showAddonCard || this.props.showAddonCardPlatform ? (
            <CreateComponentItem
              {...this.props}
              label={`${t('Custom Domain')} ${type}`}
              onSetUpCustomDomain
              value={
                type === 'appshell' ? 'CustomDomain' : 'CustomDomainPlatform'
              }
              text={`${t('Use your own')} ${type} ${t('domain name.')}`}
              svg={<CustomDomainIcon />}
              disabled
              width="100%"
              nextInvoiceAmount={this.state.nextInvoiceAmount}
            />
          ) : (
            <FlatButton
              label={t('Upgrade')}
              onClick={() =>
                this.props.history.push(
                  `/${this.props.match.params.appname}/choose_plan`,
                )
              }
              primary
            />
          )}
        </div>
      </Container>
    );
  };

  renderHelperText(type) {
    const { domain, platformDomain, t } = this.props;
    let helperText;
    if (type === 'platform' && domain) return null;

    if (type === 'appshell')
      helperText = `${t(
        'The appshell custom domain is the custom domain of your app which will be shared with your end-users.',
      )} ${
        !this.props.isAppAdmin
          ? t(
              "If you don't set up a custom domain for your app, the app domain will be automatically generated as appname.beezer.com.",
            )
          : ''
      }`;

    if (type === 'platform')
      helperText = t(
        'Please set up an appshell custom domain in order to white label your account and enable custom domains.',
      );

    return (
      <div
        style={{
          padding: 20,
          fontWeight: 'bold',
          border: '1px solid',
          borderRadius: 10,
          background: '#ccc',
          marginBottom: 15,
        }}
      >
        {helperText}
      </div>
    );
  }

  render() {
    const {
      resourceRecords,
      enabled,
      cloudfrontDomain,
      errorMsg,
      domain,
      hasChanged,
      removeDomainDialog,
      platformDomain,
      platformEnabled,
      platformErrorMsg,
      platformCloudfrontDomain,
      platformHasChanged,
      platformResourceRecords,
      removePlatformDomainDialog,
      domainSetupStatus,
      platformDomainSetupStatus,
      fetching,
      platformFetching,
      isVerifiyingAppDomain,
      isVerifiyingPlatformDomain,
    } = {
      ...this.state,
    };
    const {
      customDomain: domainProps,
      hasAccess,
      hasAccessPlatform,
      isAppAdmin,
    } = {
      ...this.props,
    };
    const { t } = this.props;

    const isWhiteLabeledHost = whitelabeledHost();

    return (
      <React.Fragment>
        <Tabs initialSelectedIndex={0}>
          <Tab label="appshell">
            <Container>
              {!hasAccess ? (
                this.noAccess('appshell')
              ) : (
                <React.Fragment>
                  <SectionHeader
                    title={t('Appshell Domain name')}
                    tooltip={t('Add your Appshell custom domain')}
                  />
                  <TextField
                    id="custom_domain"
                    value={domain}
                    disabled={
                      this.props.customDomain &&
                      Object.keys(this.props.customDomain).length > 0 &&
                      this.props.customDomain.domain
                    }
                    hintText={t('Appshell Domain')}
                    onChange={newDomain => {
                      const isValid = this.checkIsValidDomain(newDomain);
                      const newErrorMsg = isValid
                        ? ''
                        : t(
                            'Domains should be lowercase and not contain any spaces or special characters.',
                          );

                      this.setState({
                        domain: newDomain,
                        hasChanged:
                          domainProps.domain !== newDomain ||
                          domainProps.enabled !== enabled,
                        errorMsg: newErrorMsg,
                      });
                    }}
                  />
                  <SectionHeader
                    title={t('Enable Appshell custom domain')}
                    tooltip={t(
                      'Enable/disable Appshell custom domain for your app',
                    )}
                  />
                  <Toggle
                    label=""
                    toggled={enabled}
                    onToggle={(i, toggled) =>
                      this.setState({
                        enabled: toggled,
                        hasChanged:
                          domainProps.domain !== domain ||
                          domainProps.enabled !== toggled,
                      })
                    }
                    className="full-width toggle-days-sec"
                  />
                  {errorMsg === this.domainNotAvailable
                    ? this.validateDomainInstructions([
                        {
                          Name: firebase.auth().currentUser.uid,
                          Value: 'beezer.com',
                        },
                      ])
                    : null}

                  {!cloudfrontDomain &&
                    !hasChanged &&
                    enabled &&
                    resourceRecords &&
                    resourceRecords.length > 0 &&
                    this.validateDomainInstructions(resourceRecords)}
                  {cloudfrontDomain &&
                    !hasChanged &&
                    enabled &&
                    !domainSetupStatus &&
                    this.configureDnsInstructions(cloudfrontDomain, domain)}

                  {domainSetupStatus && this.domainSetupSuccessfull()}
                  {errorMsg ? this.showDomainErrorMsg(errorMsg) : null}
                  {cloudfrontDomain &&
                    !domainSetupStatus &&
                    !fetching &&
                    isVerifiyingAppDomain &&
                    this.showDomainSetupFailedMsg()}

                  {this.renderButton()}
                  {this.renderRemoveDomainButton()}
                  <DialogConfirmation
                    open={removeDomainDialog}
                    onClose={() => this.toggleRemoveDomainDialog(false)}
                    action={this.onDeleteCustomDomain}
                    message={t('Are you sure you want to remove this domain ?')}
                  />
                  {this.renderHelperText('appshell')}
                </React.Fragment>
              )}
            </Container>
          </Tab>
          {!isAppAdmin && (
            <Tab label={t('platform')}>
              <Container>
                {!hasAccessPlatform ? (
                  this.noAccess('platform')
                ) : (
                  <React.Fragment>
                    <SectionHeader
                      title={t('Platform Domain name')}
                      tooltip={t('Add your Platform custom domain')}
                    />
                    <TextField
                      id="custom_domain"
                      value={platformDomain}
                      disabled={
                        this.props.customDomain &&
                        this.props.customDomain.platformDomain
                      }
                      hintText={t('Platform Domain')}
                      onChange={newDomain => {
                        const isValid = this.checkIsValidDomain(newDomain);
                        const newErrorMsg = isValid
                          ? ''
                          : t(
                              'Domains should be lowercase and not contain any spaces or special characters.',
                            );

                        this.setState({
                          platformDomain: newDomain,
                          platformHasChanged:
                            domainProps.platformDomain !== newDomain ||
                            domainProps.platformDlatformEnabled !==
                              platformEnabled,
                          platformErrorMsg: newErrorMsg,
                        });
                      }}
                    />
                    <SectionHeader
                      title={t('Enable Platform custom domain')}
                      tooltip={t(
                        'Enable/disable Platform custom domain for your app',
                      )}
                    />
                    <Toggle
                      label=""
                      toggled={platformEnabled}
                      onToggle={(i, toggled) =>
                        this.setState({
                          platformEnabled: toggled,
                          platformHasChanged:
                            domainProps.platformDomain !== platformDomain ||
                            domainProps.platformEnabled !== toggled,
                        })
                      }
                      className="full-width toggle-days-sec"
                    />

                    {platformErrorMsg === this.domainNotAvailable
                      ? this.validateDomainInstructions([
                          {
                            Name: firebase.auth().currentUser.uid,
                            Value: 'beezer.com',
                          },
                        ])
                      : null}
                    {!platformCloudfrontDomain &&
                      !platformHasChanged &&
                      platformEnabled &&
                      platformResourceRecords &&
                      platformResourceRecords.length > 0 &&
                      this.validateDomainInstructions(platformResourceRecords)}
                    {platformCloudfrontDomain &&
                      !platformHasChanged &&
                      platformEnabled &&
                      !platformDomainSetupStatus &&
                      this.configureDnsInstructions(
                        platformCloudfrontDomain,
                        platformDomain,
                      )}

                    {platformDomainSetupStatus && this.domainSetupSuccessfull()}
                    {platformErrorMsg
                      ? this.showDomainErrorMsg(platformErrorMsg)
                      : null}
                    {platformCloudfrontDomain &&
                      !platformDomainSetupStatus &&
                      !platformFetching &&
                      isVerifiyingPlatformDomain &&
                      this.showDomainSetupFailedMsg()}

                    {this.renderPlatformButton()}
                    {this.renderRemovePlatformDomainButton()}
                    <DialogConfirmation
                      open={removePlatformDomainDialog}
                      onClose={() =>
                        this.toggleRemovePlatformDomainDialog(false)
                      }
                      action={this.onDeletePlatformCustomDomain}
                      message={t(
                        'Are you sure you want to remove this domain ?',
                      )}
                    />
                    {this.renderHelperText('platform')}
                  </React.Fragment>
                )}
              </Container>
            </Tab>
          )}
        </Tabs>
      </React.Fragment>
    );
  }
}

export default withTranslation()(customDomain);
