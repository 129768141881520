/* eslint-disable no-confusing-arrow */
import { connect } from 'react-redux';

import NoticeDialog from './Dialog';
import { closeNotice, closeAutoPopup } from '../../actions';
import { findFirst } from '../../../../../utils';

const mapStateToProps = (state, ownProps) => {
  const noticeId = state.app.notices.openNotice;
  const allNotices = state.account.organisation.notices;
  const account = state.account.organisation.key;
  const appname = state.app.appContext.appname;
  const isOn14DaysTrial = state.account.organisation.isOn14DaysTrial;
  if (!allNotices) {
    return {
      ...ownProps,
      isOn14DaysTrial,
      open: false,
    };
  }

  const autoPopup = findFirst(allNotices, o => k => o[k].autoPopup);

  if (autoPopup) {
    return {
      ...ownProps,
      ...allNotices[autoPopup],
      noticeId: autoPopup,
      open: true,
      isOn14DaysTrial,
      account,
      appname,
    };
  }

  if (noticeId === null) {
    return {
      ...ownProps,
      open: false,
      account,
      isOn14DaysTrial,
      noticeId,
      appname,
    };
  }

  return {
    ...ownProps,
    ...allNotices[noticeId],
    open: true,
    account,
    noticeId,
    appname,
    isOn14DaysTrial,
    country:
      (state.account.auth.profile.ip_data &&
        state.account.auth.profile.ip_data.showDynamicPricing &&
        state.account.auth.profile.ip_data.country &&
        state.account.auth.profile.ip_data.country
          .replace(/\d+/g, ' ')
          .split(/ |\B(?=[A-Z])/)
          .map(word => word.toLowerCase())
          .join('_')) ||
      '',
  };
};

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeNotice()),
  handleCloseAutoPopup: (account, noticeId) =>
    dispatch(closeAutoPopup(account, noticeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoticeDialog);
