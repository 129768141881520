import FormData from 'form-data';

import firebase from '../../../../../../../firebase';

export const fieldChange = (appname, dataId, field, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);
  dataRef.child(field).set(value);

  return {
    type: 'TEXT_VALUE_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

export const designChange = (appname, key, type, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${key}/design`);

  dataRef.child(type).set(value);

  return {
    type: 'FILE_DESIGN_CHANGE',
    appname,
    key,
    designType: type,
    value,
  };
};

const FileUploadStart = () => ({
  type: 'FILE_UPLOAD_START',
});

const FileUploadSuccess = () => ({
  type: 'FILE_UPLOAD_SUCCESS',
});

const FileUploadError = message => ({
  type: 'FILE_UPLOAD_FAILURE',
  message,
});

export const uploadFile = (file, firebaseUrl) => dispatch => {
  const form = new FormData();
  const user = firebase.auth().currentUser;
  const myHeaders = new Headers();

  form.append('firebaseUrl', firebaseUrl);
  form.append('file', file);

  return user
    .getIdToken()
    .then(token => {
      dispatch(FileUploadStart());
      myHeaders.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_IMAGES_API}/upload_file`, {
        method: 'post',
        headers: myHeaders,
        body: form,
        mode: 'cors',
      });
    })
    .then(res => res.json())
    .then(res => {
      if (res.error) {
        dispatch(FileUploadError(res.error));
      } else {
        dispatch(FileUploadSuccess());
      }
    })
    .catch(error => dispatch(FileUploadError(error.message)));
};
