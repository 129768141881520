import React from 'react';
import { Trans } from 'react-i18next';
import { plans, TIER_5_PLAN_ID } from '../../../../plans';
import Badge from '../../components/badge';
import { RoundedThinButton } from '../../../../app/components/buttons';
import './styles.css';

const AppsumoSubscriptions = props => {
  const { appsumoPlans, appsumoBilling, domain } = props;
  const getAppsumoPlanInfo = domain => (
    <span className="plan-name">
      {plans[appsumoPlans[domain].plan].title} -{' '}
      {appsumoBilling && appsumoBilling.amount}
    </span>
  );

  return appsumoPlans &&
    domain &&
    appsumoPlans[domain] &&
    plans[appsumoPlans[domain].plan] ? (
    <>
      <Badge label={getAppsumoPlanInfo(domain)} />

      {plans[appsumoPlans[domain].plan].plan_id !== TIER_5_PLAN_ID && (
        <div className="controls">
          <RoundedThinButton
            className="success"
            onClick={() => props.handleSubscribe(domain)}
          >
            <Trans>Upgrade</Trans>
          </RoundedThinButton>
        </div>
      )}
    </>
  ) : null;
};

export default AppsumoSubscriptions;
