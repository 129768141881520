import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import { FlatButton } from '../../../../../app/components/buttons';
import './styles.css';

const actions = (onClose) => [
    <FlatButton
        style={{ margin: 20 }}
        label="OK"
        primary
        onClick={() => onClose()}
    />
];

const DialogInfo = (props) => {
    return (
        <Dialog
            title={props.title}
            actions={actions(props.onClose)}
            open={props.open}
            onRequestClose={() => props.onClose()}
            style={{ textAlign: 'center' }}
            actionsContainerStyle={{ textAlign: 'center' }}
        >
            {<p className="info">{props.children}</p>}
        </Dialog>
    );
};

DialogInfo.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default DialogInfo;
