import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import Weebly from './weebly.png';
import { WeeblyWixToolbar } from '../../../../../app/modules/toolbar';
import Wix from './wix.png';
import { CTAButton } from '../../../../../app/components/buttons';
import Layout from './components/layout';

const headerStyle = {
  marginBottom: '30px',
  marginTop: '-55px',
};
const marginStyle = {
  marginBottom: '28px',
  marginTop: '28px',
};

class NotPublished extends Component {
  constructor(props) {
    super(props);
    this.state = {
      integrationName: null,
    };
  }
  componentWillMount() {
    const url = new URL(window.location.href);
    const integrationName = url.searchParams.get('integrationName');

    if (integrationName === 'wix' || integrationName === 'weebly') {
      this.setState({ integrationName });
    } else {
      console.error('Not from weebly or wix');
      window.top.location.replace('/');
    }
  }

  render() {
    const { t } = this.props;
    if (this.state.integrationName === null) {
      return <div />;
    }

    let width = window.innerWidth;
    if (width > 600) {
      width = '60%';
    } else {
      width = '100%';
    }

    // Set parameter for Wix / Weebly
    let webName = 'Weebly';
    let imgSrc = Weebly;
    let webUrl = 'https://www.weebly.com/editor/main.php#/';
    let btnLabel = t('back to weebly');
    const isWix = this.state.integrationName === 'wix';
    if (isWix) {
      webName = 'Wix';
      imgSrc = Wix;
      webUrl = 'https://www.wix.com/my-account/sites';
      btnLabel = t('back to wix');
      width = width === '60%' ? '65%' : width;
    }

    return (
      <div>
        <WeeblyWixToolbar isWix={isWix} />
        <Layout>
          <div className="row center-xs">
            <div className="col-sm-12 col-xs-12" key="content">
              <header style={headerStyle}>
                <h1>
                  <Trans>Publish Your</Trans> {webName} <Trans>website</Trans>
                </h1>
              </header>
              <p>
                <Trans>
                  D’oh! You haven’t published your website yet. You’ll
                </Trans>{' '}
                <br />{' '}
                <Trans>
                  need to do that before you can start making your app.
                </Trans>
              </p>
              <p>
                <Trans>How to fix it: go back to</Trans> {webName}{' '}
                <Trans>and click the ‘publish’ button.</Trans> <br />
                <Trans>
                  Then relaunch your Beezer dashboard. We’ll meet you back here.
                </Trans>
                <br />
              </p>
              <div style={marginStyle}>
                <img alt="Publish website" src={imgSrc} width={width} />
              </div>
              {!isWix && (
                <a href={webUrl} style={marginStyle} target="_parent">
                  <CTAButton
                    label={btnLabel}
                    borderColor="#73b9f8"
                    outlineRGB="186, 212, 235"
                  />
                </a>
              )}
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default withTranslation()(NotPublished);
