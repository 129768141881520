import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextField from '../../../../../../../app/components/textField';
import Container from '../../../../../../../app/components/container';
import Toggle from '../../../../../../../app/components/toggle';
import SelectField from '../../../../../../../app/components/selectField';
import MenuItem from 'material-ui/MenuItem';

import { Tabs, Tab } from '../../../../../components/tabs';

import './twitter.scss';

const Twitter = ({ data, onChange }) => {
  const { t } = useTranslation();
  const handleToggle = prop => (e, isToggled) => onChange(prop, isToggled);

  const componentAlignment = field => {
    return (
      <SelectField
        label={t('Select component alignment')}
        value={data[field]}
        options={[
          <MenuItem key="left" value="left" primaryText={t('Left')} />,
          <MenuItem key="center" value="center" primaryText={t('Center')} />,
          <MenuItem key="right" value="right" primaryText={t('Right')} />,
        ]}
        onChange={(index, value) => onChange(field, value)}
      />
    );
  };

  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container>
          <Toggle
            label={t('Twitter Feed')}
            toggled={data.twitterFeed}
            onToggle={handleToggle('twitterFeed')}
            className="full-width toggle-button"
          />
          {data.twitterFeed && (
            <React.Fragment>
              <TextField
                id="feed"
                name="feed"
                placeholder={t('Enter a Twitter account URL')}
                hintText={t('Twitter account URL')}
                value={data.feedUser}
                onChange={value =>
                  onChange('feedUser', value.replace(/@/i, ''))
                }
              />
              {componentAlignment('feedAlignment')}
            </React.Fragment>
          )}
          <Toggle
            label={t('Twitter Follow')}
            toggled={data.twitterFollow}
            onToggle={handleToggle('twitterFollow')}
            className="full-width toggle-button"
          />
          {data.twitterFollow && (
            <React.Fragment>
              <TextField
                id="follow"
                name="follow"
                placeholder={t('Enter a Twitter account URL')}
                hintText={t('Twitter account URL')}
                value={data.followUser}
                onChange={value =>
                  onChange('followUser', value.replace(/@/i, ''))
                }
              />
              {componentAlignment('followAlignment')}
            </React.Fragment>
          )}
          <Toggle
            label={t('Twitter Hashtag')}
            toggled={data.twitterHashtag}
            onToggle={handleToggle('twitterHashtag')}
            className="full-width toggle-button"
          />
          {data.twitterHashtag && (
            <React.Fragment>
              <TextField
                id="hashtag"
                name="hashtag"
                placeholder={t('Enter a Twitter Hashtag')}
                hintText={t('Hashtag')}
                value={data.hashtag}
                onChange={value => onChange('hashtag', value.replace(/#/i, ''))}
              />
              {componentAlignment('hashtagAlignment')}
            </React.Fragment>
          )}
          <Toggle
            label={t('Twitter Mention')}
            toggled={data.twitterMention}
            onToggle={handleToggle('twitterMention')}
            className="full-width toggle-button"
          />
          {data.twitterMention && (
            <React.Fragment>
              <TextField
                id="mention"
                name="mention"
                placeholder={t('Enter a Twitter account URL')}
                hintText={t('Twitter account URL')}
                value={data.mention}
                onChange={value => onChange('mention', value.replace(/@/i, ''))}
              />
              {componentAlignment('mentionAlignment')}
            </React.Fragment>
          )}
          <Toggle
            label={t('Twitter Share')}
            toggled={data.twitterShare}
            onToggle={handleToggle('twitterShare')}
            className="full-width toggle-button"
          />
          {data.twitterShare && (
            <React.Fragment>
              <TextField
                id="share"
                name="share"
                placeholder={t('Enter a Share url')}
                hintText={t('URL')}
                value={data.shareUrl}
                onChange={value => onChange('shareUrl', value)}
              />
              {componentAlignment('shareAlignment')}
            </React.Fragment>
          )}
          <Toggle
            label={t('Tweet')}
            toggled={data.tweet}
            onToggle={handleToggle('tweet')}
            className="full-width toggle-button"
          />
          {data.tweet && (
            <React.Fragment>
              <TextField
                id="tweet"
                name="tweet"
                placeholder={t('Enter a Tweet Id')}
                hintText={t('Tweet Id')}
                value={data.tweetId}
                onChange={value => onChange('tweetId', value)}
              />
              {componentAlignment('tweetAlignment')}
            </React.Fragment>
          )}
        </Container>
      </Tab>
      <Tab
        label={t('design')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
      <Tab
        label={t('layout')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
    </Tabs>
  );
};

Twitter.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

Twitter.defaultProps = {
  data: {},
};

export default Twitter;
