import React from 'react';

const Text = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64.5 36.61"
  >
    <title>button</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M52,24.81H48.81v-1.5H52A6.32,6.32,0,0,0,58.33,17V7.82A6.32,6.32,0,0,0,52,1.5H7.82A6.32,6.32,0,0,0,1.5,7.82V17a6.32,6.32,0,0,0,6.32,6.31h20.7v1.5H7.82A7.82,7.82,0,0,1,0,17V7.82A7.83,7.83,0,0,1,7.82,0H52a7.82,7.82,0,0,1,7.81,7.82V17A7.81,7.81,0,0,1,52,24.81Z"
        />
        <path
          class="cls-1"
          d="M29.92,29.48h-19a6.25,6.25,0,0,1-6.24-6.24h1.5A4.75,4.75,0,0,0,10.91,28h19Z"
        />
        <path
          class="cls-1"
          d="M58.25,29.48H54.36V28h3.89A4.75,4.75,0,0,0,63,23.24V10.91a4.75,4.75,0,0,0-4.75-4.74V4.67a6.25,6.25,0,0,1,6.25,6.24V23.24A6.25,6.25,0,0,1,58.25,29.48Z"
        />
        <path
          class="cls-1"
          d="M49.94,36.61l-9.76-9.75-5.24,5.23-5.25-19.6,19.6,5.26L44.05,23l9.76,9.76ZM40.18,24.74l9.76,9.75,1.75-1.75L41.93,23l4.46-4.46L31.81,14.61,35.72,29.2Z"
        />
      </g>
    </g>
  </svg>
);

export default Text;
