import React from 'react';
import ImagePreviewButton from './ImagePreviewButton';
import ImagePreviewCTA from './ImagePreviewCTA';
import { ProgressOverlay } from '../../../../../app/components/progress';
import ThumbsContainer from './ThumbsContainer';
import { PGMOL_APPS } from '.././../../../../config';

const ImagePreview = ({
  handleClick,
  pending,
  sources,
  deleteImage,
  mainImage,
  onChangeMainImage,
  appName,
}) => {
  const cta = !pending && <ImagePreviewCTA>Upload Images</ImagePreviewCTA>;
  const loader = pending && <ProgressOverlay />;
  return (
    <div>
      <ImagePreviewButton
        height={PGMOL_APPS.indexOf(appName) > -1 ? '75px' : ''}
        width={PGMOL_APPS.indexOf(appName) > -1 ? '150px' : ''}
        disabled={pending}
        onClick={handleClick}
      >
        {cta}
        {loader}
      </ImagePreviewButton>
      <ThumbsContainer
        sources={sources}
        deleteImage={deleteImage}
        mainImage={mainImage}
        onChangeMainImage={onChangeMainImage}
      />
    </div>
  );
};

export default ImagePreview;
