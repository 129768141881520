import React from 'react';

const InstagramIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 77.79 43.5"
  >
    <title>Instagram component</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          class="cls-1"
          points="9.28 29.98 0 20.7 9.28 11.42 10.34 12.48 2.12 20.7 10.34 28.92 9.28 29.98"
        />
        <polygon
          class="cls-1"
          points="68.5 29.98 67.44 28.92 75.67 20.7 67.44 12.48 68.5 11.42 77.79 20.7 68.5 29.98"
        />
        <path
          class="cls-1"
          d="M47.52,43.5H29.93A13,13,0,0,1,17,30.55V13A13,13,0,0,1,29.93,0H47.52a13,13,0,0,1,13,13v17.6A13,13,0,0,1,47.52,43.5ZM29.93,1.5A11.46,11.46,0,0,0,18.48,13v17.6A11.46,11.46,0,0,0,29.93,42H47.52A11.47,11.47,0,0,0,59,30.55V13A11.47,11.47,0,0,0,47.52,1.5Z"
        />
        <path
          class="cls-1"
          d="M48,39.5H29.43A8.46,8.46,0,0,1,21,31.05V12.45A8.46,8.46,0,0,1,29.43,4H48a8.47,8.47,0,0,1,8.46,8.45v18.6A8.47,8.47,0,0,1,48,39.5ZM29.43,5.5a7,7,0,0,0-6.95,7v18.6a7,7,0,0,0,7,7H48a7,7,0,0,0,7-6.95V12.45A7,7,0,0,0,48,5.5Z"
        />
        <path
          class="cls-1"
          d="M48.73,13.5a2.75,2.75,0,1,1,2.75-2.75A2.75,2.75,0,0,1,48.73,13.5Zm0-4A1.25,1.25,0,1,0,50,10.75,1.25,1.25,0,0,0,48.73,9.5Z"
        />
        <path
          class="cls-1"
          d="M38.73,31.5a9.75,9.75,0,1,1,9.75-9.75A9.76,9.76,0,0,1,38.73,31.5Zm0-18A8.25,8.25,0,1,0,47,21.75,8.26,8.26,0,0,0,38.73,13.5Z"
        />
        <path
          class="cls-1"
          d="M38.73,27.5a5.75,5.75,0,1,1,5.75-5.75A5.75,5.75,0,0,1,38.73,27.5Zm0-10A4.25,4.25,0,1,0,43,21.75,4.25,4.25,0,0,0,38.73,17.5Z"
        />
      </g>
    </g>
  </svg>
);

export default InstagramIcon;
