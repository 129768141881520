import React from 'react';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import MuiTextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Trans, withTranslation } from 'react-i18next';
import TextField from '../../../../../../../../../app/components/textField';
import { FlatButton } from '../../../../../../../../../app/components/buttons';
import CircularValidation from '../../../../../../../../../app/components/circularValidation';
import { CircularProgress } from '../../../../../../../../../app/components/progress';
import Toggle from '../../../../../../../../../app/components/toggle';
import './styles.css';

export const beezerSections = [
  {
    label: 'Build',
    value: 'build',
  },
  {
    label: 'Distribute',
    value: 'distribute',
  },
  {
    label: 'Engage',
    value: 'engage',
  },
  {
    label: 'Analytics',
    value: 'analytics',
  },
  {
    label: 'Member Login',
    value: 'member_login',
  },
  {
    label: 'Business Tools',
    value: 'business_tools',
  },
];

class EditAdmin extends React.Component {
  componentDidMount() {
    if (this.props.match && this.props.match.params.admin) {
      this.props.getAdminDetails();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match &&
      this.props.match.params.admin &&
      this.props.match.params.admin !== prevProps.match.params.admin
    ) {
      this.props.getAdminDetails();
    }
  }

  render() {
    const {
      pending,
      error,
      onSubmit,
      onRequestClose,
      firstName,
      lastName,
      email,
      onFieldChange,
      match,
      adminAppname,
      adminSMSCredits,
      adminWhatsappSMSCredits,
      smsCredits,
      whatsappSMSCredits,
      creditsToAdd,
      whatsappCreditsToAdd,
      apps,
      t,
    } = this.props;

    const displayError = error ? (
      <div>
        <strong>{error}</strong>
      </div>
    ) : null;

    const form = (
      <div>
        <h5>
          <Trans>Edit an App Admin</Trans>
        </h5>
        <TextField
          id="firstName"
          placeholder={t('First Name')}
          {...firstName}
          onChange={value => onFieldChange('firstName', value)}
          rightInputAction={
            <CircularValidation
              isValid={firstName.isValid}
              defaultMsg={t("Admin's first name required")}
              errorMsg={t('First name must be at least 2 characters long')}
              showDefault={!firstName.value}
            />
          }
        />
        <TextField
          id="lastName"
          placeholder={t('Last Name')}
          {...lastName}
          onChange={value => onFieldChange('lastName', value)}
          rightInputAction={
            <CircularValidation
              isValid={lastName.isValid}
              defaultMsg={t("Admin's last name required")}
              errorMsg={t('Last name must be at least 2 characters long')}
              showDefault={!lastName.value}
            />
          }
        />
        <TextField
          id="email"
          placeholder={t('Email')}
          {...email}
          disabled
          onChange={value => onFieldChange('email', value)}
          rightInputAction={
            <CircularValidation
              isValid={email.isValid}
              defaultMsg={t('Email address required')}
              errorMsg={t('Please use a valid email address')}
              showDefault={!email.value}
            />
          }
        />
        <div className="text-field-wrapper">
          <FormControl fullWidth>
            <InputLabel id="app-select-label" style={{ left: '-15px' }}>
              <Trans>Select app</Trans>
            </InputLabel>
            <Select
              labelId="app-select-label"
              id="app-select"
              multiple
              value={adminAppname.value || []}
              onChange={e => onFieldChange('adminAppname', e.target.value)}
              variant="standard"
            >
              {Object.keys(apps).map(appId => (
                <MenuItem value={appId}>{apps[appId].title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="text-field-wrapper" style={{ marginTop: '14px' }}>
          <MuiTextField
            id="smsCredits"
            label={t('SMS Credits')}
            fullWidth
            variant="standard"
            value={adminSMSCredits.value}
            disabled
          />
        </div>
        <div className="text-field-wrapper" style={{ marginTop: '14px' }}>
          <MuiTextField
            variant="standard"
            type="number"
            fullWidth
            label={t('Add SMS Credits')}
            value={creditsToAdd.value}
            onChange={e =>
              e.target.value >= 0 &&
              e.target.value <= smsCredits &&
              onFieldChange('creditsToAdd', e.target.value)
            }
            InputProps={{
              endAdornment: `/${smsCredits}`,
              min: 0,
              max: smsCredits,
            }}
            helperText={t('These credits will be deducted from your account.')}
          />
        </div>

        <div className="text-field-wrapper" style={{ marginTop: '14px' }}>
          <MuiTextField
            id="whatsappCredits"
            label={t('Whatsapp Credits')}
            fullWidth
            variant="standard"
            value={adminWhatsappSMSCredits.value}
            disabled
          />
        </div>

        <div className="text-field-wrapper" style={{ marginTop: '14px' }}>
          <MuiTextField
            variant="standard"
            type="number"
            fullWidth
            label={t('Add Whatsapp Credits')}
            value={whatsappCreditsToAdd.value}
            onChange={e =>
              e.target.value >= 0 &&
              e.target.value <= whatsappSMSCredits &&
              onFieldChange('whatsappCreditsToAdd', e.target.value)
            }
            InputProps={{
              endAdornment: `/${whatsappSMSCredits}`,
              min: 0,
              max: whatsappSMSCredits,
            }}
            helperText={t('These credits will be deducted from your account.')}
          />
        </div>

        <div
          className="text-field-wrapper"
          style={{ marginTop: '14px', padding: '0px' }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span style={{ fontSize: '14px' }}>
                <Trans>Platform Access</Trans>
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="restricted_access_container">
                {beezerSections.map(section => (
                  <div key={section.value}>
                    <p>{t(section.label)}</p>
                    <Toggle
                      style={{ margin: '0' }}
                      onToggle={(v, b) => {
                        this.props.updateSubAdminSectionAccess(
                          section.value,
                          b,
                        );
                      }}
                      toggled={this.props.sectionsAccess[section.value]}
                    />
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    );

    const buttonSubmit = !pending ? (
      <FlatButton
        label={t('Save')}
        onClick={onSubmit}
        disabled={!firstName.isValid || !lastName.isValid || !email.isValid}
        primary
      />
    ) : (
      <CircularProgress size={18} />
    );
    return (
      <Dialog
        open={!!(match && match.params.admin)}
        onClose={onRequestClose}
        autoScrollBodyContent
      >
        <DialogTitle>
          <div className="modal-controls-container">
            <IconButton onClick={onRequestClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="scroll-container">
          <div className="edit-admin-form">
            <div className="list-fieldset">{displayError}</div>
            {form}
            {buttonSubmit}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

EditAdmin.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getAdminDetails: PropTypes.func.isRequired,
  firstName: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  lastName: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  email: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  adminAppname: PropTypes.shape({
    value: PropTypes.array,
    isValid: PropTypes.bool,
  }).isRequired,
  onFieldChange: PropTypes.func.isRequired,
  apps: PropTypes.object.isRequired,
  sectionsAccess: PropTypes.object.isRequired,
  updateSubAdminSectionAccess: PropTypes.func.isRequired,
  chunks: PropTypes.object.isRequired,
};

EditAdmin.defaultProps = {
  error: null,
};

export default withTranslation()(EditAdmin);
