import Firebase from 'firebase'; // used for timestamp
import i18n from 'i18next';

import firebase from '../../../../firebase';
import { formatDate } from '../../../../utils';

import { arrayHasComponent } from '../../../../utils/routes';

import { openSnackbar } from '../../../../app/modules/snackbar/actions';
import { publishApp } from '../../../../app/modules/publishDialog/actions';
import { INTERNAL_APP_PATHS } from '../../../../config/constants';
export const GET_INSTALLS = 'GET_INSTALLS';

export const ENABLE_FILTER = 'ENABLE_FILTER';
export const DISABLE_FILTER = 'DISABLE_FILTER';
export const NO_USERS_EXIST = 'NO_USERS_EXIST';
export const FILTERED_LIST_CHANGE = 'FILTERED_LIST_CHANGE';
export const ENABLE_GROUPS_FILTER = 'ENABLE_GROUPS_FILTER';
export const DISABLE_GROUPS_FILTER = 'DISABLE_GROUPS_FILTER';
export const FILTERED_GROUPS_LIST_CHANGE = 'FILTERED_GROUPS_LIST_CHANGE';
export const REQUEST_GROUPS = 'REQUEST_GROUPS';
export const RECEIVE_GROUPS = 'RECEIVE_GROUPS';

export const FILTERED_LOCATION_LIST_CHANGE = 'FILTERED_LOCATION_LIST_CHANGE';
export const ENABLE_LOCATION_FILTER = 'ENABLE_LOCATION_FILTER';
export const DISABLE_LOCATION_FILTER = 'DISABLE_LOCATION_FILTER';

export const FILTERS_CHANGE = 'FILTERS_CHANGE';
export const PLAYER_ID_CHANGE = 'PLAYER_ID_CHANGE';
export const SELECTED_FILTER_CHANGE = 'SELECTED_FILTER_CHANGE';

export const REQUEST_USERS = 'REQUEST_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const SUBMIT_PUSH = 'SUBMIT_PUSH';
export const PUSH_SUCCESS = 'PUSH_SUCCESS';
export const PUSH_FAILURE = 'PUSH_FAILURE';
export const PUSH_FORM_CHANGE = 'PUSH_FORM_CHANGE';
export const RECEIVE_PUSH_RECIPIENTS = 'RECEIVE_PUSH_RECIPIENTS';
export const RESET_ENGAGE_STATE = 'RESET_ENGAGE_STATE';
export const PUSH_LINK_FORM_CHANGE = 'PUSH_LINK_FORM_CHANGE';
export const RESET_USER_INPUTS = 'RESET_USER_INPUTS';
export const NOTIFICATION_PAGE_CREATED = 'NOTIFICATION_PAGE_CREATED';
export const ADD_PAGE_ERROR = 'ADD_PAGE_ERROR';
export const NOTIFICATION_COMPONENT_ADDED = 'NOTIFICATION_COMPONENT_ADDED';
export const ADD_NOTIFICATION_COMPONENT_ERROR =
  'ADD_NOTIFICATION_COMPONENT_ERROR';
export const PUSH_DATE_CHANGE = 'PUSH_DATE_CHANGE';
export const PUSH_DATE_TOGGLE_CHANGE = 'PUSH_DATE_TOGGLE_CHANGE';
export const UPDATE_PUSH_IMAGE = 'UPDATE_PUSH_IMAGE';
export const PUSH_IMAGE_CLEARED = 'PUSH_IMAGE_CLEARED';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_STATES = 'GET_STATES';
export const GET_CITIES = 'GET_CITIES';

export const getInstalls = installs => ({
  type: GET_INSTALLS,
  payload: installs,
});

export const filteredListChange = list => ({
  type: FILTERED_LIST_CHANGE,
  payload: list,
});

export const filteredGroupsListChange = list => ({
  type: FILTERED_GROUPS_LIST_CHANGE,
  payload: list,
});

export const filteredLocationListChange = list => ({
  type: FILTERED_LOCATION_LIST_CHANGE,
  payload: list,
});

export const filtersChange = list => ({
  type: FILTERS_CHANGE,
  payload: list,
});

export const playerIdChange = list => ({
  type: PLAYER_ID_CHANGE,
  payload: list,
});

export const selectedFilterChange = value => ({
  type: SELECTED_FILTER_CHANGE,
  payload: value,
});

const requestUsers = appname => ({
  type: REQUEST_USERS,
  appname,
});

const requestGroups = appname => ({
  type: REQUEST_GROUPS,
  appname,
});

export const getUsers = appname => dispatch => {
  dispatch(requestUsers(appname));

  firebase
    .database()
    .ref(`app_users/${appname}`)
    .on('value', snap => {
      if (!snap.exists()) {
        dispatch({
          type: NO_USERS_EXIST,
        });
      } else {
        dispatch({
          type: RECEIVE_USERS,
          value: snap.val(),
        });
      }
    });
};

export const getGroups = appname => dispatch => {
  dispatch(requestGroups(appname));

  firebase
    .database()
    .ref(`app_groups/${appname}/groups`)
    .once('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_GROUPS,
          value: snap.val(),
        });
      }
    });
};

export const enableFilter = () => ({
  type: ENABLE_FILTER,
});

export const disableFilter = () => ({
  type: DISABLE_FILTER,
});

export const enableGroupsFilter = () => ({
  type: ENABLE_GROUPS_FILTER,
});

export const disableGroupsFilter = () => ({
  type: DISABLE_GROUPS_FILTER,
});

export const enableLocationFilter = () => ({
  type: ENABLE_LOCATION_FILTER,
});

export const disableLocationFilter = () => ({
  type: DISABLE_LOCATION_FILTER,
});

export const reportPushSuccess = () => ({
  type: PUSH_SUCCESS,
});

export const reportPushError = err => ({
  type: PUSH_FAILURE,
  err,
});

export const reportAddNotificationComponentError = err => ({
  type: ADD_NOTIFICATION_COMPONENT_ERROR,
  err,
});

export const reportAddPageError = err => ({
  type: ADD_PAGE_ERROR,
  err,
});

export const handlePropChange = (prop, value) => ({
  type: PUSH_FORM_CHANGE,
  prop,
  value,
});

export const resetUserInputs = () => ({
  type: RESET_USER_INPUTS,
});

export const pushImageCleared = () => ({
  type: PUSH_IMAGE_CLEARED,
});

export const onLinkFormChange = (prop, value) => dispatch => {
  const webValid =
    prop === 'web' &&
    /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w-]+)+[\w\-_~:/?#[\]@!$&'()*+,;=.]+$/gm.test(
      value,
    );
  const videoValid =
    prop === 'video' &&
    /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w-]+)+[\w\-_~:/?#[\]@!$&'()*+,;=.]+$/gm.test(
      value,
    );

  dispatch({
    type: PUSH_LINK_FORM_CHANGE,
    prop,
    payload: {
      webValid,
      videoValid,
      value,
    },
  });
};

export const onDateChange = value => ({
  type: PUSH_DATE_CHANGE,
  scheduledTime: value,
});

export const onToggleChange = value => ({
  type: PUSH_DATE_TOGGLE_CHANGE,
  isScheduled: value,
});

export const resetEngageState = () => ({
  type: RESET_ENGAGE_STATE,
});

export const installs = appname => async dispatch => {
  try {
    const insallRef = firebase.database().ref(`analytics/${appname}/installs/`);
    const snapshot = await insallRef.once('value');
    const value = await snapshot.val();
    const numberOfInstalls = value ? Object.keys(value).length : 0;
    dispatch(getInstalls(numberOfInstalls));
  } catch (err) {
    console.error(err);
  }
};

export const getRecipients = appname => dispatch =>
  fetch(`${process.env.REACT_APP_PUSH_API}/players/${appname}`)
    .then(res => res.json())
    .then(body => {
      dispatch({
        type: RECEIVE_PUSH_RECIPIENTS,
        value: body.players,
      });
    });

export const sendPush = () => (dispatch, getState) => {
  dispatch({
    type: SUBMIT_PUSH,
    pushImage: getState().engage.push.pushImage || '',
  });

  const { profile } = getState().account.auth;
  const { appname } = getState().app.appContext;

  const {
    message,
    title,
    link,
    scheduledTime,
    isScheduled,
    filtered,
    filteredList,
    groupsFiltered,
    groupsFilteredList,
    locationFiltered,
    locationFilteredList,
    filters,
    playerIds,
    selectedFilter,
  } = getState().engage.push;

  const trialExpired = getState().account.organisation.trial_expired || false;
  const is14DayTrialUser =
    getState().account.organisation.isOn14DaysTrial || false;
  const planName = getState().account.subscriptions.items[appname].plan;

  if (planName === 'something' && is14DayTrialUser && trialExpired) {
    if (
      filteredList.length > 0 ||
      groupsFilteredList.length > 0 ||
      locationFilteredList.length > 0
    ) {
      dispatch({ type: 'UPGRADE_AUTH_MODEL', open: true });
      return;
    }
  }

  const appAlwaysData = getState().engage.structure;
  let NotificationsPageLink = '';

  appAlwaysData.routes.find(arr => {
    if (arrayHasComponent(arr.components, 'Notification')) {
      return (NotificationsPageLink = {
        url: `${arr.path}`,
      });
    }
  });
  let pushLink = {};
  if (link.enabled) {
    pushLink = {
      type: link.type,
      ref: link[link.type],
      linksTo:
        link.type === 'video' ? NotificationsPageLink.url : link[link.type],
    };
  } else {
    pushLink = {
      type: 'page',
      ref: NotificationsPageLink.url,
      linksTo: NotificationsPageLink.url,
    };
  }

  if (
    !message.value ||
    message.value === '' ||
    !title.value ||
    title.value === ''
  ) {
    return null;
  }

  const payload = {
    message: message.value,
    segments: 'all',
    title: title.value,
    pushImage: getState().engage.push.pushImage || '',
    appname,
    link: pushLink,
    account: profile.account,
    scheduledTime: isScheduled && scheduledTime ? scheduledTime : new Date(),
    isScheduled,
    filtered,
    filteredList,
    groupsFiltered,
    groupsFilteredList,
    locationFiltered,
    locationFilteredList,
    filters,
    playerIds,
    selectedFilter,
  };

  if (
    payload.groupsFiltered &&
    payload.playerIds.length === 0 &&
    groupsFilteredList.length > 0
  ) {
    payload.playerIds = ['nobody'];
  }

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_PUSH_API}/send_push`, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify(payload),
        mode: 'cors',
      });
    })
    .then(res => {
      console.log(res, JSON.stringify(payload));
      if (res.status === 200) {
        dispatch(getRecipients(appname));
        dispatch(installs(appname));
        dispatch(reportPushSuccess());
        dispatch(clearPushImage(appname));
        dispatch(
          openSnackbar(
            isScheduled
              ? `${i18n.t('Push Notification scheduled for')} ${formatDate(
                  scheduledTime,
                )}`
              : i18n.t(
                  'Push Notification sent, your notifications will be delivered shortly.',
                ),
          ),
        );
      } else {
        res.json().then(body => {
          dispatch(reportPushError(body));
        });
      }
    });
};

// var starCountRef = firebase.database().ref();

// starCountRef.on('value', function(snapshot) {
//   console.log(snapshot.val());
// });

const toPath = title =>
  title
    .toLowerCase()
    .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '')
    .replace(/\s+/g, '-')
    .trim();

const createNotificationPage = (appname, getState, alwaysDataRef) => {
  const childRoutesRef = firebase
    .database()
    .ref(`apps/${appname}/draft/layout_data/childRoutes`);
  const layoutData = getState().engage.structure.routes || [];
  const form = getState().editor.build.create.createPage;
  const pageTitle = 'Notifications';
  const paths = layoutData ? layoutData.map(item => item.path) : [];
  paths.push(...INTERNAL_APP_PATHS);
  const tabs = null;
  let newPageKey;
  let path = toPath(pageTitle);

  if (paths.indexOf(path) !== -1) {
    path = path.concat('-', Date.now().toString());
  }

  return alwaysDataRef
    .push({
      title: pageTitle,
      type: form.type,
      path,
      tabs,
    })
    .then(pageRef => {
      newPageKey = pageRef.key;

      return childRoutesRef
        .transaction(value => {
          if (!value) return [newPageKey];
          return [...value, pageRef.key];
        })
        .then(() => newPageKey);
    });
};

const createNotificationComponent = (appname, pageKey, alwaysDataRef) => {
  const lazyDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data`);
  const componentsArrayRef = alwaysDataRef.child(`${pageKey}/components`);

  const notficiationComponentSeedData = {
    content: {
      type: 'Notification',
    },
    layout: 'Standard',
    design: {},
  };

  return lazyDataRef
    .push(notficiationComponentSeedData.content)
    .then(snapshot => {
      firebase
        .database()
        .ref(`app_groups/${appname}/not_private`)
        .update({ [snapshot.key]: true });

      return snapshot;
    })
    .then(snapshot =>
      alwaysDataRef.push({
        type: 'Notification',
        lazy_data: snapshot.key,
        design: notficiationComponentSeedData.design,
        layout: notficiationComponentSeedData.layout,
      }),
    )
    .then(snapshot => {
      componentsArrayRef.transaction(value => {
        if (!value) return [snapshot.key];

        return [...value, snapshot.key];
      });
      return snapshot.key;
    })
    .then(key => key);
};

export const addNotificationComponent = (appname, history) => (
  dispatch,
  getState,
) => {
  const alwaysDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data`);
  createNotificationPage(appname, getState, alwaysDataRef)
    .then(newPageKey => {
      dispatch({ type: NOTIFICATION_PAGE_CREATED, newPageKey });
      createNotificationComponent(appname, newPageKey, alwaysDataRef)
        .then(key => {
          dispatch({ type: NOTIFICATION_COMPONENT_ADDED, key });
          dispatch(publishApp(appname));
          history.push(
            history.location.pathname.replace(
              'engage/push',
              `editor/build/list?page=${newPageKey}&component=${key}`,
            ),
          );
        })
        .catch(err => dispatch(reportAddNotificationComponentError(err)));
    })
    .catch(err => dispatch(reportAddPageError(err)));
};

export const updatePushImage = pushImage => ({
  type: 'UPDATE_PUSH_IMAGE',
  pushImage,
});

export const loadContent = appname => dispatch => {
  const pushImageRef = firebase
    .database()
    .ref(`apps/${appname}/temp_data/pushImage`);
  pushImageRef.on('value', snapshot => {
    let image = snapshot.val();
    dispatch(updatePushImage(image));
  });
};

export const clearPushImage = appname => dispatch => {
  const pushImageRef = firebase.database().ref(`apps/${appname}/temp_data`);
  pushImageRef
    .update({
      pushImage: '',
    })
    .then(() => {
      dispatch(pushImageCleared());
    });
};

const getAnalytics = async (
  appname,
  dispatch,
  type,
  name,
  url,
  country,
  state,
) => {
  await firebase
    .auth()
    .currentUser.getIdToken()
    .then(async token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      const request = await fetch(
        `${process.env.REACT_APP_LOCATION_ANALYTICS_API}/${url}`,
        {
          headers,
          method: 'post',
          mode: 'cors',
          body: JSON.stringify({
            appname,
            country,
            state,
          }),
        },
      );
      const response = await request.json();
      const payload = response[name];

      dispatch({
        type,
        payload,
      });
    });
};

export const getCountries = appname => async dispatch => {
  await getAnalytics(
    appname,
    dispatch,
    GET_COUNTRIES,
    'filteredCountries',
    'get_country_analytics',
    null,
    null,
  );
};

export const getStates = (appname, country) => async dispatch => {
  await getAnalytics(
    appname,
    dispatch,
    GET_STATES,
    'filteredStates',
    'get_state_analytics',
    country,
    null,
  );
};

export const getCities = (appname, country, state) => async dispatch => {
  await getAnalytics(
    appname,
    dispatch,
    GET_CITIES,
    'filteredCities',
    'get_city_analytics',
    country,
    state,
  );
};
