import firebase from '../../firebase';
import { publishApp } from '../../app/modules/publishDialog/actions';
import seedComponent from '../editor/modules/build/modules/create/createComponent/seedComponent';
import { _get } from 'lodash/get';
import { INTERNAL_APP_PATHS } from '../../config/constants';
const ADD_EVENT_CALENDAR_COMPONENT_ERROR = 'ADD_EVENT_CALENDAR_COMPONENT_ERROR';
const ADD_PAGE_ERROR = 'ADD_PAGE_ERROR';
const EVENT_CALENDAR_PAGE_CREATED = 'EVENT_CALENDAR_PAGE_CREATED';
const EVENT_CALENDAR_COMPONENT_ADDED = 'EVENT_CALENDAR_COMPONENT_ADDED';
export const updateDraftData = data => ({
  type: 'RECEIVE_DRAFT_DATA',
  data,
});

export const reportAddEventCalendarComponentError = err => ({
  type: ADD_EVENT_CALENDAR_COMPONENT_ERROR,
  err,
});

export const reportAddPageError = err => ({
  type: ADD_PAGE_ERROR,
  err,
});

export const loadContent = appname => dispatch => {
  const ref = firebase.database().ref(`apps/${appname}/draft`);

  ref.once('value').then(snapshot => {
    const data = snapshot.val();
    if (data === null) return null;

    dispatch(updateDraftData(data));
    return null;
  });
};

export const removeGroupsAndUsersListeners = async appname => {
  try {
    await Promise.all([
      firebase
        .database()
        .ref(`app_users/${appname}`)
        .off(),
      firebase
        .database()
        .ref(`app_groups/${appname}/groups`)
        .off(),
    ]);
  } catch (err) {
    console.error(err);
  }
};

const toPath = title =>
  title
    .toLowerCase()
    .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '')
    .replace(/\s+/g, '-')
    .trim();

const createEventCalendarPage = (appname, getState, alwaysDataRef) => {
  const childRoutesRef = firebase
    .database()
    .ref(`apps/${appname}/draft/layout_data/childRoutes`);
  const layoutData = getState().engage.structure.routes || [];
  const form = getState().editor.build.create.createPage;
  const pageTitle = 'Events';
  const paths = layoutData ? layoutData.map(item => item.path) : [];
  paths.push(...INTERNAL_APP_PATHS);
  const tabs = null;
  let newPageKey;
  let path = toPath(pageTitle);

  if (paths.indexOf(path) !== -1) {
    path = path.concat('-', Date.now().toString());
  }

  return alwaysDataRef
    .push({
      title: pageTitle,
      type: form.type,
      path,
      tabs,
    })
    .then(pageRef => {
      newPageKey = pageRef.key;

      return childRoutesRef
        .transaction(value => {
          if (!value) return [newPageKey];
          return [...value, pageRef.key];
        })
        .then(() => newPageKey);
    });
};

const createEventCalendarComponent = (
  appname,
  pageKey,
  alwaysDataRef,
  account,
  palette,
) => {
  const lazyDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data`);
  const componentsArrayRef = alwaysDataRef.child(`${pageKey}/components`);

  const eventCalendarComponentSeedData = seedComponent(
    'EventCalendar',
    account,
    palette,
    null,
  );

  return lazyDataRef
    .push(eventCalendarComponentSeedData.content)
    .then(snapshot => {
      // update not_private permissions for new components
      // so that new components will be public when Member Login is enabled
      firebase
        .database()
        .ref(`app_groups/${appname}/not_private`)
        .update({ [snapshot.key]: true });

      return snapshot;
    })
    .then(snapshot => {
      firebase
        .database()
        .ref(`app_events/${appname}/lazy_data`)
        .set(snapshot.key);
      firebase
        .database()
        .ref(`app_courses/${appname}/lazy_data`)
        .set(snapshot.key);
      return alwaysDataRef.push({
        type: 'EventCalendar',
        lazy_data: snapshot.key,
        design: eventCalendarComponentSeedData.design,
        layout: eventCalendarComponentSeedData.layout,
      });
    })
    .then(snapshot => {
      componentsArrayRef.transaction(value => {
        if (!value) return [snapshot.key];

        return [...value, snapshot.key];
      });
      return snapshot.key;
    })
    .then(key => key);
};

export const addEventCalendarComponent = (appname, history) => (
  dispatch,
  getState,
) => {
  const alwaysDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data`);
  createEventCalendarPage(appname, getState, alwaysDataRef)
    .then(newPageKey => {
      dispatch({ type: EVENT_CALENDAR_PAGE_CREATED, newPageKey });
      const account = getState().account.profile.account;
      const palette = getState().editor.design[appname].palette;
      createEventCalendarComponent(
        appname,
        newPageKey,
        alwaysDataRef,
        account,
        palette,
      )
        .then(key => {
          dispatch({ type: EVENT_CALENDAR_COMPONENT_ADDED, key });
          dispatch(publishApp(appname));
          const memberRoute = /members(.*)/;
          history.push(
            history.location.pathname.replace(
              memberRoute,
              `editor/build/list?page=${newPageKey}&component=${key}`,
            ),
          );
        })
        .catch(err => dispatch(reportAddEventCalendarComponentError(err)));
    })
    .catch(err => dispatch(reportAddPageError(err)));
};
