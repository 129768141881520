import firebase from '../../../../firebase';

const REQUEST_FORM_DATA = 'REQUEST_FORM_DATA';
export const RECEIVE_AUTH_STATUS = 'RECEIVE_AUTH_STATUS';

const requestFormData = appname => ({
  type: REQUEST_FORM_DATA,
  appname,
});

export const getData = appname => dispatch => {
  firebase
    .database()
    .ref(`forms/${appname}`)
    .off('value');

  return dispatch(requestFormData(appname));
};

export const toggleAuth = (appname, value, type = 'single') => {
  if (value) {
    firebase
      .database()
      .ref(`apps/${appname}/draft/always_data/auth`)
      .set({
        type: 'auth',
        authType: type,
      });
  } else {
    firebase
      .database()
      .ref(`apps/${appname}/draft/always_data/auth`)
      .set(null);
  }

  return {
    type: 'TOGGLE_AUTH',
    appname,
    toggle: value,
    authType: type,
  };
};

export const getAuth = appname => dispatch => {
  firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/auth`)
    .on('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_AUTH_STATUS,
          value: true,
          authType: snap.val().authType,
        });
      } else {
        dispatch({
          type: RECEIVE_AUTH_STATUS,
          value: false,
          authType: null,
        });
      }
    });
};
