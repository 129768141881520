import firebase from '../../../../firebase';
import { objectToArray, arrayToObject, formatDate } from '../../../../utils';

export const SET_SELECTED_COURSE = 'SET_SELECTED_COURSE';
export const SET_COURSE_ATTENDEES = 'SET_COURSE_ATTENDEES';
export const SET_COURSE_ATTENDEES_ITEMS = 'SET_COURSE_ATTENDEES_ITEMS';
export const FILTER_COURSE_ATTENDEE_DATA = 'FILTER_COURSE_ATTENDEE_DATA';
export const SELECT_ALL_COURSE_ATTENDEES = 'SELECT_ALL_COURSE_ATTENDEES';
export const DESELECT_ALL_COURSE_ATTENDEES = 'DESELECT_ALL_COURSE_ATTENDEES';
export const SELECT_COURSE_ATTENDEE = 'SELECT_COURSE_ATTENDEE';

export const setSelectedCourse = payload => ({
  type: SET_SELECTED_COURSE,
  value: payload,
});

export const selectAllCourseAttendees = () => ({
  type: SELECT_ALL_COURSE_ATTENDEES,
});

export const deselectAllCourseAttendees = () => ({
  type: DESELECT_ALL_COURSE_ATTENDEES,
});

export const selectAttendee = key => ({
  type: SELECT_COURSE_ATTENDEE,
  key,
});
export const getCourseAttendees = appname => (dispatch, getState) => {
  const { selectedCourse } = getState().staff.coursesAttendees.listAttendees;
  return firebase
    .database()
    .ref(`subscribers/${appname}/course/${selectedCourse}`)
    .on('value', snap => {
      if (snap.exists()) {
        const result = snap.val();
        let allUsers = [];
        const users = objectToArray(result);

        users.map(item => {
          const allItems = objectToArray(item.value).map(user => {
            return {
              ...user,
              value: {
                ...user.value,
                userType: item.id === 'user' ? 'registered user' : item.id,
              },
            };
          });
          allUsers = [...allUsers, ...allItems];
        });
        const finalValue = arrayToObject(allUsers);
        dispatch({
          type: SET_COURSE_ATTENDEES,
          value: finalValue,
        });
        dispatch({
          type: SET_COURSE_ATTENDEES_ITEMS,
          value: Object.keys(finalValue),
        });
      } else {
        dispatch({
          type: SET_COURSE_ATTENDEES,
          value: [],
        });
      }
    });
};

export const filterCourseData = filter => dispatch =>
  dispatch({
    type: FILTER_COURSE_ATTENDEE_DATA,
    payload: filter,
  });
