import firebase from '../../../../firebase';

import { openSnackbar } from '../../../../app/modules/snackbar/actions';

export const fieldChange = (prop, value) => ({
  type: 'PROFILE_FIELD_CHANGE',
  prop,
  value,
});

const profileUpdated = () => ({
  type: 'PROFILE_UPDATED',
});

const reportError = err => ({
  type: 'PROFILE_UPDATE_ERROR',
  err,
});

export const saveChanges = () => (dispatch, getState) => {
  const { firstName, lastName } = getState().account.staffProfile;

  const { uid } = firebase.auth().currentUser;
  const userRef = firebase.database().ref(`users`);

  const data = {
    firstName: firstName.value,
    lastName: lastName.value,
  };

  return userRef
    .child(uid)
    .update(data)
    .then(() => {
      dispatch(profileUpdated());
      dispatch(openSnackbar('Profile updated.'));
    })
    .catch(err => reportError(err.message));
};
