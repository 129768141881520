/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import { Trans, withTranslation } from 'react-i18next';
import { objectToArray } from '../../../../../../../utils';

import { FlatButton } from '../../../../../../../app/components/buttons';
import FormFields from '../../../../../components/form';

import './styles.css';

class Edit extends React.Component {
  componentDidMount() {
    if (this.props.match && this.props.match.params.cpd) {
      this.props.getCPDDetails();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match &&
      JSON.stringify(this.props.match) !== JSON.stringify(prevProps.match)
    ) {
      this.props.getCPDDetails();
    }
  }

  render() {
    const {
      pending,
      error,
      onSubmit,
      onRequestClose,
      onFieldChange,
      match,
      fields,
      fieldsData,
      role,
      t,
    } = this.props;

    return (
      <Dialog
        open={!!(match && match.params.cpd)}
        onRequestClose={() => onRequestClose(role)}
        autoScrollBodyContent
        contentClassName="user-cpds-modal"
        paperClassName="user-cpds-paper"
      >
        <div className="user-cpds-modal-content">
          <div className="modal-heading">
            <h5>
              <Trans>Edit CPD</Trans>
            </h5>
          </div>
          <div>
            <FormFields
              fields={fields}
              onFieldChange={onFieldChange}
              fieldsData={fieldsData}
            />
            <div className="submit-button-container">
              <FlatButton
                label={pending ? t('Saving...') : t('Save')}
                onClick={onSubmit}
                primary
                disabled={
                  pending ||
                  !fieldsData ||
                  !!objectToArray(fieldsData).find(
                    i =>
                      !i.value.isValid &&
                      i.id !== 'createdAt' &&
                      i.id !== 'memberbody' &&
                      i.id !== 'updatedAt',
                  )
                }
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

Edit.propTypes = {
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  match: PropTypes.object,
  onRequestClose: PropTypes.func.isRequired,
  getCPDDetails: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  fieldsData: PropTypes.object.isRequired,
  role: PropTypes.string,
};

Edit.defaultProps = {
  error: null,
  fields: {},
  fieldsData: {},
  match: {},
  role: 'admin',
};

export default withTranslation()(Edit);
