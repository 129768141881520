import React from 'react';

import './styles.css';

const GroupsIcon = () => (
  <svg
    className="icon groups-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64.58 25.06"
  >
    <title>Groups</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M57.26,11.17a6.29,6.29,0,1,0-7.94,0,9.21,9.21,0,0,0-6.53,5.27,9.19,9.19,0,0,0-6.53-5.27,6.29,6.29,0,1,0-7.94,0,9.21,9.21,0,0,0-6.53,5.27,9.19,9.19,0,0,0-6.53-5.27,6.29,6.29,0,1,0-7.94,0,9.19,9.19,0,0,0-7.32,9v4.9H1.5v-4.9a7.69,7.69,0,0,1,7.68-7.68h1a6.55,6.55,0,0,0,2.28,0h1A7.69,7.69,0,0,1,21,19.35c0,.27,0,.54,0,.81v4.9h1.58v-4.9a7.32,7.32,0,0,0,0-.81,7.69,7.69,0,0,1,7.64-6.87h1a6.55,6.55,0,0,0,2.28,0h1A7.69,7.69,0,0,1,42,19.35c0,.27,0,.54,0,.81v4.9h1.58v-4.9a7.32,7.32,0,0,0,0-.81,7.69,7.69,0,0,1,7.64-6.87h1a6.55,6.55,0,0,0,2.28,0h1a7.68,7.68,0,0,1,7.68,7.68v4.9h1.5v-4.9A9.18,9.18,0,0,0,57.26,11.17ZM6.5,6.29A4.79,4.79,0,1,1,12.29,11h-2A4.81,4.81,0,0,1,6.5,6.29Zm21,0A4.79,4.79,0,1,1,33.29,11h-2A4.81,4.81,0,0,1,27.5,6.29Zm21,0A4.79,4.79,0,1,1,54.29,11h-2A4.81,4.81,0,0,1,48.5,6.29Z"
        />
      </g>
    </g>
  </svg>
);

export default GroupsIcon;
