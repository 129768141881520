import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Notification from './Notification';
import { fieldChange } from './actions';

const mapStateToProps = (state, ownProps) => {
  return {
    toggle_notification : ownProps.data.toggle_notification
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    )
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Notification),
);
