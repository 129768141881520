import _pickBy from 'lodash/pickBy';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import {
  SELECT_VENDOR,
  DESELECT_VENDOR,
} from './types';

const initialState = {
  pending: false,
  selectedVendor: null,
  vendorDialogOpen: false,
};

const vendors = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_VENDOR: {
      const selectedVendor = action.value;
      return {
        ...state,
        selectedVendor,
      };
    }

    case DESELECT_VENDOR: {
      return {
        ...state,
        selectedVendor:null,
      };
    }

    default:
      return state;
  }
};

export default vendors;
