import React from 'react';

const Text = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 103.29 39"
  >
    <title>roster</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          class="cls-1"
          points="103.29 36.88 28.99 36.88 28.99 35.38 101.79 35.38 101.79 3.43 28.99 3.43 28.99 1.93 103.29 1.93 103.29 36.88"
        />
        <rect class="cls-1" x="43.99" y="9.93" width="49.55" height="1.5" />
        <rect class="cls-1" x="46.99" y="18.93" width="46.55" height="1.5" />
        <rect class="cls-1" x="43.99" y="27.93" width="49.55" height="1.5" />
        <path
          class="cls-1"
          d="M32.5,5A19.49,19.49,0,1,0,9.1,36l1.15.73v-.06a19.38,19.38,0,0,0,18.5,0v.06L29.9,36A19.48,19.48,0,0,0,32.5,5ZM19.5,37.5a17.89,17.89,0,0,1-9.25-2.59V29.25a6.51,6.51,0,0,1,6.5-6.5h5.5a6.51,6.51,0,0,1,6.5,6.5v5.66A17.92,17.92,0,0,1,19.5,37.5Zm0-16.25a5.5,5.5,0,1,1,5.5-5.5A5.51,5.51,0,0,1,19.5,21.25ZM30.25,33.92V29.25a8,8,0,0,0-6.61-7.87,7,7,0,1,0-8.28,0,8,8,0,0,0-6.61,7.87v4.67a18,18,0,1,1,21.5,0Z"
        />
      </g>
    </g>
  </svg>
);

export default Text;
