import _ from 'lodash';
import firebase from '../../../../../../firebase';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import { WORKFORCE_GROUP_DELETED } from '../../config';

export const REQUEST_WORKFORCE_TYPES = 'REQUEST_WORKFORCE_TYPES';
export const RECEIVE_WORKFORCE_TYPES = 'RECEIVE_WORKFORCE_TYPES';
export const REPORT_WORKFORCE_TYPES_REQUEST_ERROR =
  'REPORT_WORKFORCE_TYPES_REQUEST_ERROR';
export const SELECT_WORKFORCE_TYPE = 'SELECT_WORKFORCE_TYPE';
export const FILTER_WORKFORCE_TYPE_DATA = 'FILTER_WORKFORCE_TYPE_DATA';
export const SELECT_ALL_WORKFORCE_TYPES = 'SELECT_ALL_WORKFORCE_TYPES';
export const DESELECT_ALL_WORKFORCE_TYPES = 'DESELECT_ALL_WORKFORCE_TYPES';
export const WORKFORCE_TYPE_DELETED = 'WORKFORCE_TYPE_DELETED';
export const REQUEST_WORKFORCE_TYPES_DATA = 'REQUEST_WORKFORCE_TYPES_DATA';
export const RECEIVE_WORKFORCE_TYPES_DATA = 'RECEIVE_WORKFORCE_TYPES_DATA';

const requestTypes = () => ({
  type: REQUEST_WORKFORCE_TYPES,
});

const requestTypesData = () => ({
  type: REQUEST_WORKFORCE_TYPES_DATA,
});

export const selectType = key => ({
  type: SELECT_WORKFORCE_TYPE,
  key,
});

export const getTypes = app => dispatch => {
  dispatch(requestTypes());

  firebase
    .database()
    .ref(`workforce_groups/${app}`)
    .on('value', snap => {
      dispatch({
        type: RECEIVE_WORKFORCE_TYPES,
        value: snap.exists() ? snap.val() : [],
      });
    });
};

export const filterTypeData = filter => dispatch =>
  dispatch({
    type: FILTER_WORKFORCE_TYPE_DATA,
    payload: filter,
  });

export const selectAllTypes = () => ({ type: SELECT_ALL_WORKFORCE_TYPES });
export const deselectAllTypes = () => ({
  type: DESELECT_ALL_WORKFORCE_TYPES,
});

export const deleteType = (appName, groupID) => (dispatch, getState) => {
  const { chunks } = getState().auth.users;
  const memberIDs = Object.keys(
    _.pickBy(chunks, i => i.workforceGroups && i.workforceGroups[groupID]),
  );
  firebase
    .database()
    .ref(`workforce_groups/${appName}/${groupID}`)
    .remove()
    .then(() => {
      dispatch({
        type: WORKFORCE_TYPE_DELETED,
        appName,
        groupID,
      });
      dispatch(openSnackbar(WORKFORCE_GROUP_DELETED));
      // Delete the groups data
      firebase
        .database()
        .ref(`workforce_groups_data/${appName}/${groupID}`)
        .remove();
      // Delete all the references from app_users node
      const auth = firebase.auth().currentUser;
      const myHeaders = new Headers();
      auth.getIdToken().then(token => {
        myHeaders.append('x-access-token', token);
        myHeaders.append('Content-Type', 'application/json');
        return fetch(`${process.env.REACT_APP_WORKFORCE_API}/delete-group`, {
          method: 'post',
          headers: myHeaders,
          body: JSON.stringify({
            groupID,
            appName,
            memberIDs,
          }),
          mode: 'cors',
        });
      });
    });
};

export const deleteTypes = appname => (dispatch, getState) => {
  const { selected } = getState().auth.memberGroups.listPage.data;
  Promise.all(selected.map(key => dispatch(deleteType(appname, key))));
};

export const getTypesData = app => dispatch => {
  dispatch(requestTypesData());

  firebase
    .database()
    .ref(`workforce_groups_data/${app}`)
    .on('value', snap => {
      dispatch({
        type: RECEIVE_WORKFORCE_TYPES_DATA,
        value: snap.exists() ? snap.val() : {},
      });
    });
};
