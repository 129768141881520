import styled from 'styled-components';

export const container = styled.div`
  width: 70%;
  min-width: 500px;
  height: fit-content;
  max-height: 300px;
  border: 1px solid gray;
  border-radius: 15px;
  display: flex;
  padding: 1em;
  margin: 0.5em 0em;
`;

export const imageContainer = styled.div`
  width: 50%;
  text-align: center;
  height: 100%;
`;

export const profileImage = styled.img.attrs(props => ({ src: props.src }))`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
`;

export const details = styled.div`
  padding: 0.5em;
  width: 60%;
`;

export const field = styled.div`
  margin: 1em;
`;

export const fieldTitle = styled.span`
  font-weight: bolder;
  padding: 0.2em;
  font-size: 1.05em;
`;

export const fieldContent = styled.span`
  padding: 0.2em;
  word-wrap: break-word;
`;

export const actionContainer = styled.div`
  display: flex;
  margin: 0 1em;
`;
