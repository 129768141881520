import React from 'react';
import PropTypes from 'prop-types';
import Container from '../../../../../../app/components/container';
import ImageUpload from '../../../../../../modules/editor/internals/imageUpload';

const PreferenceLogo = ({ appLogo, location }) => {
  return (
    <Container>
      <ImageUpload
        label="Your Custom Logo"
        targetId="account_logo_interface"
        caption="This Logo will be displayed on your dashboard"
        title="Account Settings > Preference > Logo"
        src={appLogo}
        firebaseUrl={`apps/${location.pathname.split('/')[1]}/preferences/logo`}
        fileType="png"
        height="160px"
        width="300px"
        config={[{ aspect: 91 / 50, dimensions: ['91x50'] }]}
        imageType={'customLogo'}
        noDelete
      />
    </Container>
  );
};

PreferenceLogo.propTypes = {
  appLogo: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export default PreferenceLogo;
