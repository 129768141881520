import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Subheader = ({ title }) => <h3 className="subheader"> {title} </h3>;

Subheader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Subheader.defaultProps = {
  title: '',
};

export default Subheader;
