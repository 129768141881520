import React, { Fragment } from 'react';
import FullScreenImage from './images/fullscreen.png';
import './styles.css';

const FullScreen = () => {
  return (
    <Fragment>
      <img className="imageWrapper" src={FullScreenImage} alt="Full Screen" />
    </Fragment>
  );
};

export default FullScreen;
