import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../../../app/components/avatar';

import './styles.css';

const AccountHeader = ({ email, name }) => (
  <div className="user-details">
    <Avatar />
    <div>
      <h5 className="username">{name}</h5>
      <small className="user-email">
        {email.includes('@localhost.com') ? '' : email}
      </small>
    </div>
  </div>
);

AccountHeader.propTypes = {
  email: PropTypes.string,
  name: PropTypes.string.isRequired,
};

AccountHeader.defaultProps = {
  email: '',
};

export default AccountHeader;
