import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import IntegrationsLogin from './IntegrationsLogin';
import { logInUser } from './action';
import { logout } from '../../auth/logout/actions';

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...queryString.parse(ownProps.location.search),
  ...ownProps,
});

const mapDispatchToProps = dispatch => ({
  onReceiveToken: (token, platform) => dispatch(logInUser(token, platform)),
  onLogout: () => dispatch(logout()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(IntegrationsLogin),
);
