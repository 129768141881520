import firebase from '../../../../firebase';
import { objectToArray, arrayToObject } from '../../../../utils';

export const REQUEST_MEMBER_BODIES = 'REQUEST_MEMBER_BODIES';
export const RECEIVE_MEMBER_BODIES = 'RECEIVE_MEMBER_BODIES';
export const RECEIVE_MEMBER_BODIES_ALL = 'RECEIVE_MEMBER_BODIES_ALL';
export const REPORT_MEMBER_BODIES_REQUEST_ERROR =
  'REPORT_MEMBER_BODIES_REQUEST_ERROR';
export const SELECT_MEMBER_BODY = 'SELECT_MEMBER_BODY';
export const FILTER_MEMBER_BODY_DATA = 'FILTER_MEMBER_BODY_DATA';
export const SELECT_ALL_MEMBER_BODIES = 'SELECT_ALL_MEMBER_BODIES';
export const DESELECT_ALL_MEMBER_BODIES = 'DESELECT_ALL_MEMBER_BODIES';
export const GET_AVAILABLE_STAFF = 'GET_AVAILABLE_STAFF';
export const SEARCH_MEMBER_BODIES = 'SEARCH_MEMBER_BODIES';

export const requestMemberBodies = appname => ({
  type: REQUEST_MEMBER_BODIES,
  appname,
});

export const selectMemberBody = key => ({
  type: SELECT_MEMBER_BODY,
  key,
});

export const getMemberBodies = appname => dispatch => {
  dispatch(requestMemberBodies(appname));
  firebase
    .database()
    .ref(`member_bodies/${appname}`)
    .on('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_MEMBER_BODIES,
          value: snap.val(),
        });
      } else {
        dispatch({
          type: RECEIVE_MEMBER_BODIES,
          value: [],
        });
      }
    });
};

export const getStaffMemberBodies = appname => dispatch => {
  dispatch(requestMemberBodies(appname));
  const currentUser = firebase.auth().currentUser.uid;
  firebase
    .database()
    .ref(`member_bodies/${appname}`)
    .on('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_MEMBER_BODIES_ALL,
          value: snap.val(),
        });
        const allData = objectToArray(snap.val());
        const havingStaff = allData.filter(
          i =>
            i.value.staffMembers &&
            Object.values(i.value.staffMembers).indexOf(currentUser) > -1,
        );
        dispatch({
          type: RECEIVE_MEMBER_BODIES,
          value: arrayToObject(havingStaff),
        });
      } else {
        dispatch({
          type: RECEIVE_MEMBER_BODIES,
          value: [],
        });
        dispatch({
          type: RECEIVE_MEMBER_BODIES_ALL,
          value: [],
        });
      }
    });
};

export const filterMemberBodyData = filter => dispatch =>
  dispatch({
    type: FILTER_MEMBER_BODY_DATA,
    payload: filter,
  });

export const selectAllMemberBodies = () => ({
  type: SELECT_ALL_MEMBER_BODIES,
});
export const deselectAllMemberBodies = () => ({
  type: DESELECT_ALL_MEMBER_BODIES,
});

export const deleteMemberBody = (appname, memberBodyID) => {
  if (memberBodyID !== 'default') {
    firebase
      .database()
      .ref(`member_bodies/${appname}/${memberBodyID}`)
      .once('value', snap => {
        if (snap.exists()) {
          const staffs = snap.val().staffMembers
            ? objectToArray(snap.val().staffMembers)
            : [];
          Promise.all(
            staffs.map(data =>
              firebase
                .database()
                .ref(`users/${data.id}/memberBodies`)
                .child(memberBodyID)
                .set(null),
            ),
            firebase
              .database()
              .ref(`app_events/${appname}/events/${memberBodyID}`)
              .remove(),
          ).then(() => {
            firebase
              .database()
              .ref(`member_bodies/${appname}/${memberBodyID}`)
              .remove();
          });
        }
      });
  }
};

export const deleteMemberBodies = appname => (dispatch, getState) => {
  const { selected } = getState().auth.memberBodies.data;
  Promise.all(selected.map(key => deleteMemberBody(appname, key)));
};
