import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import PublishDialog from './PublishDialog';
import { publishApp, closePublishDialog, resetPublishState } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...state.app.publish,
  customDomain: state.app.customDomain,
  title:
    state.editor.data.metaData[ownProps.match.params.appname] &&
    state.editor.data.metaData[ownProps.match.params.appname].title,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handlePublish: () => dispatch(publishApp(ownProps.match.params.appname)),
  handleClose: () => dispatch(closePublishDialog()),
  handleCloseAfterPublish: () => {
    dispatch(resetPublishState());
    dispatch(closePublishDialog());
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PublishDialog),
);
