import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const VisitLink = ({ className }) => (
  <p className={className}>
    <a
      href="https://beezer.com/"
      className="visit-website"
      target="_blank"
      rel="noopener noreferrer"
    >
      Visit our website to find out more
      <br />
    </a>
  </p>
);

VisitLink.propTypes = {
  className: PropTypes.string,
};

VisitLink.defaultProps = {
  className: '',
};

export default VisitLink;
