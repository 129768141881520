import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chip from 'material-ui/Chip';
import MenuItem from 'material-ui/MenuItem';
import Toggle from 'material-ui/Toggle';
import { Trans, withTranslation } from 'react-i18next';
import TextField from '../../../../../../../app/components/textField';
import DesignToggle from '../../../../../components/designToggle';
import ColorPicker from '../../../../../components/colorPicker';
import Container from '../../../../../../../app/components/container';
import { FlatButton } from '../../../../../../../app/components/buttons';
import { CircularProgress } from '../../../../../../../app/components/progress';
import { Tabs, Tab } from '../../../../../components/tabs';
import SelectField from '../../../../../../../app/components/selectField';
import themeStyles from '../../../../../../../_export.scss';
import './styles.css';

const mediaFormat = /\.(mp4|mp3|wav)$/i;

class Media extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    onReceiveMedia: PropTypes.func.isRequired,
    onDesignToggle: PropTypes.func.isRequired,
    onDesignChange: PropTypes.func.isRequired,
    openSnackbar: PropTypes.func.isRequired,
    pending: PropTypes.bool,
  };

  static defaultProps = {
    pending: false,
  };

  mediaChange = event => {
    const { target } = event;
    const { t } = this.props;
    if (target && target.files) {
      if (mediaFormat.test(target.files[0].name)) {
        this.props.onReceiveMedia(
          target.files[0],
          this.props.component.lazy_data,
        );
      } else {
        this.props.openSnackbar(t('Invalid file format'));
      }
    } else {
      this.props.openSnackbar(t('No file selected'));
    }
  };

  openMediaSelector = () => this.mediaSelector.click();

  handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    this.props.onDesignChange(prop, rgba);
  };

  renderMediaAlignSelect = () => {
    const { data, handleChange, t } = this.props;
    return (
      <React.Fragment>
        <Toggle
          style={{ padding: '10px 30px' }}
          onToggle={(v, b) => handleChange('loop', b)}
          toggled={data.loop || false}
          label={t('Loop Media')}
          trackSwitchedStyle={{
            backgroundColor: themeStyles.primaryColorLight,
          }}
          thumbSwitchedStyle={{ backgroundColor: themeStyles.primaryColor }}
        />
        <SelectField
          label={t('Select media alignment')}
          value={data.alignMedia}
          options={[
            <MenuItem key="left" value="left" primaryText={t('Left')} />,
            <MenuItem key="center" value="center" primaryText={t('Center')} />,
            <MenuItem key="right" value="right" primaryText={t('Right')} />,
          ]}
          onChange={(index, value) => handleChange('alignMedia', value)}
        />
        <SelectField
          label={t('Select title alignment')}
          value={data.alignTitle}
          options={[
            <MenuItem key="left" value="left" primaryText={t('Left')} />,
            <MenuItem key="center" value="center" primaryText={t('Center')} />,
            <MenuItem key="right" value="right" primaryText={t('Right')} />,
          ]}
          onChange={(index, value) => handleChange('alignTitle', value)}
        />
      </React.Fragment>
    );
  };

  render() {
    const { component, data, handleChange, pending, t } = this.props;

    let mediaControls = [];
    if (data.url) {
      const medianame = data.url
        .substr(data.url.lastIndexOf('/') + 1)
        .split('%20')
        .join(' ');

      mediaControls = [
        <Chip
          style={{ margin: '10px auto' }}
          onRequestDelete={() => handleChange('url', '')}
        >
          <a href={data.url} className="text-ellipsis">
            {medianame}
          </a>
        </Chip>,
      ];

      const extension = medianame.substr(medianame.lastIndexOf('.') + 1);

      if (extension === 'mp3' || extension === 'wav') {
        mediaControls.unshift(
          <div style={{ margin: '10px 30px' }}>
            <audio controls>
              <source src={data.url} type="audio/mpeg" />
              <source src={data.url} type="audio/wav" />
              <track
                src="captions_en.vtt"
                kind="captions"
                srcLang="en"
                label="english_captions"
              />
              <Trans>Your browser does not support the audio element.</Trans>
            </audio>
          </div>,
        );
      } else {
        mediaControls.unshift(
          <div style={{ margin: '10px 30px' }}>
            <video width="100%" controls>
              <source src={data.url} type="video/mp4" />
              <source src={data.url} type="video/webm" />
              <track
                src="captions_en.vtt"
                kind="captions"
                srcLang="en"
                label="english_captions"
              />
              <Trans>Your browser does not support the video tag.</Trans>
            </video>
          </div>,
        );
      }
    } else if (pending) {
      mediaControls = [
        <div
          style={{
            margin: '10px 30px',
            textAlign: 'center',
          }}
        >
          <CircularProgress size={24} thickness={3} />
        </div>,
      ];
    } else {
      mediaControls = [
        <div>
          <FlatButton
            label={t('Select Media')}
            onClick={this.openMediaSelector}
            disabled={pending}
          />
        </div>,
      ];
    }

    return (
      <Tabs>
        <Tab label={t('content')}>
          <Container>
            <TextField
              id="mediaTitle"
              placeholder={t('Text to display')}
              value={data.title}
              onChange={value => handleChange('title', value)}
            />
            <input
              type="file"
              className="hidden-input"
              accept="audio/*,video/*"
              ref={node => {
                this.mediaSelector = node;
              }}
              onChange={this.mediaChange}
            />
            {mediaControls}
            {this.renderMediaAlignSelect()}
          </Container>
        </Tab>
        <Tab label={t('design')}>
          <Container>
            <DesignToggle
              component={component}
              onDesignChange={(e, isToggled) =>
                this.props.onDesignToggle(isToggled)
              }
            />
            <ColorPicker
              label={t('Title Color')}
              color={component.design.titleColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={this.handleColorChange('titleColor')}
            />
            <ColorPicker
              label={t('Background Color')}
              color={component.design.backgroundColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={this.handleColorChange('backgroundColor')}
            />
          </Container>
        </Tab>
        <Tab
          label={t('layout')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    );
  }
}

export default withTranslation()(Media);
