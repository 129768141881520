import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import RestrictedAccess from './RestrictedAccess';

import { updateField, handleRadioChange, loadLayoutData } from './actions';

const mapStateToProps = (state, ownProps) => {
  const defaultGroup = state.auth.groups.data.chunks.default;
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];
  const alwaysData = state.editor.data.alwaysData;
  const restrictedAccess =
    defaultGroup && defaultGroup.restrictedaccess
      ? defaultGroup.restrictedaccess
      : null;
  return {
    ...ownProps,
    headerText: restrictedAccess ? restrictedAccess.headerText : '',
    subHeaderText: restrictedAccess ? restrictedAccess.subHeaderText : '',
    link: restrictedAccess ? restrictedAccess.link : '',
    buttonLabel: restrictedAccess ? restrictedAccess.buttonLabel : '',
    pageLayout: restrictedAccess ? restrictedAccess.layout : '',
    loginRedirectEnabled: restrictedAccess
      ? restrictedAccess.loginRedirectEnabled
      : false,
    pages:
      layout && layout.childRoutes && Object.keys(alwaysData).length > 0
        ? layout.childRoutes.map(key => alwaysData[key])
        : [],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateField: (field, value) =>
    dispatch(updateField(ownProps.match.params.appname, field, value)),
  handleNestedChange: (field, value) =>
    dispatch(handleRadioChange(ownProps.match.params.appname, field, value)),
  loadLayoutData: () => dispatch(loadLayoutData(ownProps.match.params.appname)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RestrictedAccess),
);
