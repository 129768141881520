export const OPEN_RATING_DETAIL = 'OPEN_RATING_DETAIL';
export const CLOSE_RATING_DETAIL_DIALOG = 'CLOSE_RATING_DETAIL_DIALOG';
export const PREVIOUS_RATING_RESPONSE = 'PREVIOUS_RATING_RESPONSE';
export const NEXT_RATING_RESPONSE = 'NEXT_RATING_RESPONSE';
export const PREVIOUS_RATING_FEEDBACK = 'PREVIOUS_RATING_FEEDBACK';
export const NEXT_RATING_FEEDBACK = 'NEXT_RATING_FEEDBACK';

export const openRatingDetailDialog = key => (dispatch, getState) => {
  const rating = getState().analytics.rating.data.chunks[key];
  return dispatch({
    type: OPEN_RATING_DETAIL,
    key,
    rating,
  });
};

export const closeRatingDetailDialog = () => ({
  type: CLOSE_RATING_DETAIL_DIALOG,
});

export const previousResponse = (key, items) => (dispatch, getState) => {
  const ratings = getState().analytics.rating.data.chunks;

  return dispatch({
    type: PREVIOUS_RATING_RESPONSE,
    key,
    ratings,
    items,
  });
};

export const nextResponse = (key, items) => (dispatch, getState) => {
  const ratings = getState().analytics.rating.data.chunks;

  return dispatch({
    type: NEXT_RATING_RESPONSE,
    key,
    ratings,
    items,
  });
};

export const previousFeedback = key => ({
  type: PREVIOUS_RATING_FEEDBACK,
  key,
});

export const nextFeedback = key => ({
  type: NEXT_RATING_FEEDBACK,
  key,
});
