import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReferralsTable from '../../components/ReferralsTable';

const ReferralsList = props => {
  const { isPending, fetchReferrals, items } = props;
  const { t } = useTranslation();

  const headers = [
    t('Name of Referral'),
    t('Date of Paid Subscription'),
    t('Subscription Plan'),
    t('Bonus Credit'),
  ];

  const dataAttributes = ['name', 'created_at', 'planName', 'formattedAmount'];

  useEffect(() => {
    fetchReferrals();
  }, []);

  return (
    <ReferralsTable
      headers={headers}
      rows={items}
      loading={isPending}
      dataAttributes={dataAttributes}
    />
  );
};

export default ReferralsList;
