import { combineReducers } from 'redux';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import _pickBy from 'lodash/pickBy';

import newGroup from './newGroup/reducer';
import editGroup from './editGroup/reducer';
import { objectToArray, arrayToObject } from '../../../../utils/object';

import {
  REQUEST_GROUPS,
  RECEIVE_GROUPS,
  REPORT_GROUPS_REQUEST_ERROR,
  SELECT_GROUP,
  FILTER_GROUP_DATA,
  SELECT_ALL_GROUPS,
  DESELECT_ALL_GROUPS,
  FILTER_GROUPS_DATA,
} from './actions';

const initialState = {
  pending: false,
  chunks: {},
  selected: [],
  items: [],
  filteredChunks: {},
  subscriptionGroups: [],
  filter: '',
  error: null,
};

const filterChunksData = (chunks, sortedItems) => {
  let filteredChunks = chunks;

  filteredChunks = _pickBy(chunks, (chunk, key) => {
    return sortedItems.includes(key);
  });
  return filteredChunks;
};

const filterChunks = (chunks, filterHolder = '') => {
  let filteredChunks = chunks;
  const filter = filterHolder.replace(/[^a-zA-Z0-9 ]/g, '');

  if (filter !== '') {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`(${filter})`, 'gi');

      return rgx.test(chunk.name);
    });
  }
  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const groups = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GROUPS:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_GROUPS: {
      const subscriptions = objectToArray(action.value);
      const filterSubscriptions = subscriptions.filter(
        item => item.value.subscriptionPlan !== undefined,
      );
      return {
        ...state,
        chunks: action.value,
        selected: [],
        filteredChunks: action.value,
        items: _keys(action.value),
        subscriptionGroups:
          filterSubscriptions.length > 0
            ? arrayToObject(filterSubscriptions)
            : [],
        pending: false,
        error: null,
      };
    }
    case FILTER_GROUP_DATA:
      return {
        ...state,
        ...filterChunks(state.chunks, action.payload),
        filter: action.payload,
      };
    case FILTER_GROUPS_DATA:
      return {
        ...state,
        filteredChunks: filterChunksData(
          action.payload.filteredChunks,
          action.payload.sortedItems,
        ),
        items: action.payload.sortedItems,
      };
    case REPORT_GROUPS_REQUEST_ERROR:
      return {
        pending: false,
        error: action.err,
      };

    case SELECT_GROUP: {
      const prevState = state.filteredChunks[action.key].selected;
      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case SELECT_ALL_GROUPS: {
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: true,
      }));
      const selected = _union(state.selected, Object.keys(filteredChunks));

      return {
        ...state,
        filteredChunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_GROUPS:
      return {
        ...state,
        filteredChunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    default:
      return state;
  }
};

export default combineReducers({
  data: groups,
  newGroup,
  editGroup,
});
