import React from 'react';

import './styles.css';

const Analytics = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 30.25"
    className="icon analytics"
  >
    <title>Analytics</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M33.25,28.75v-25H25.5V7.5H20.75V0H13V15H6.75V28.75H0v1.5H40v-1.5Zm-25,0V16.5H13V28.75Zm6.25,0V1.5h4.75V28.75Zm6.25,0V9H25.5V28.75Zm6.25,0V5.25h4.75v23.5Z"
        />
      </g>
    </g>
  </svg>
);

export default Analytics;
