import { connect } from 'react-redux';

import Profile from './Profile';
import { openLegalModal } from '../legal/actions';
import { fieldChange, saveChanges } from './actions';

const mapStateToProps = state => ({
  ...state.account.profile,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});

const mapDispatchToProps = dispatch => ({
  onNameChange: value => dispatch(fieldChange('name', value)),
  onOrgChange: value => dispatch(fieldChange('organisation', value)),
  onCityChange: value => dispatch(fieldChange('city', value)),
  onCountryChange: value => dispatch(fieldChange('country', value)),
  onWorkfieldChange: value => dispatch(fieldChange('workfield', value)),
  onSubmit: () => dispatch(saveChanges()),
  onOpenLegalModal: selectedModal => dispatch(openLegalModal(selectedModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
