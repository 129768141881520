import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateAppThemeColor } from '../../../../app/modules/appPreference/action';
import Preferences from './Preferences';

const mapStateToProps = state => ({
  appColor: state.app.appPreference.appColor,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onUpdateAppThemeColor: color =>
    dispatch(
      updateAppThemeColor(ownProps.location.pathname.split('/')[1], color),
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Preferences),
);
