import i18n from 'i18next';
import firebase from '../../../../../../firebase';
import { validateField } from './validators';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';

const newGroupFieldChange = (prop, value, isValid) => ({
  type: 'NEW_FIELD_CHANGE',
  prop,
  value,
  isValid,
});

export const openFieldDialog = () => ({
  type: 'OPEN_FIELD_DIALOG',
});

export const closeFieldDialog = () => ({
  type: 'CLOSE_FIELD_DIALOG',
});

export const updateToggleField = (appname, toggled, field) => dispatch =>
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default`)
    .update({ [`${field}`]: toggled })
    .then(() =>
      dispatch({
        type: 'TOGGLE_FIELD',
        appname,
        field,
        toggled,
      }),
    );

export const updateCustomToggleField = (
  appname,
  toggled,
  field,
  key,
) => dispatch =>
  firebase
    .database()
    .ref(
      `app_groups/${appname}/groups/default/customProfileFields/${key}/${field}`,
    )
    .update({ value: toggled });

export const handleDelete = (appname, key) => dispatch =>
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/customProfileFields/${key}`)
    .set(null)
    .then(() => dispatch(openSnackbar(i18n.t('Field Deleted'))));

const getNextBase64String = currentString => {
  const base64Chars =
    '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';
  let currentIndex = currentString.length - 1;
  const newString = currentString.split('');

  while (currentIndex >= 0) {
    const char = currentString[currentIndex];
    const charIndex = base64Chars.indexOf(char);

    // If the current character is the last in the base64Chars, wrap around
    if (charIndex === base64Chars.length - 1) {
      newString[currentIndex] = base64Chars[0]; // reset to first character in base64Chars
      currentIndex -= 1; // move to the previous character in the string
    } else {
      // Increment the current character
      newString[currentIndex] = base64Chars[charIndex + 1];
      return newString.join(''); // return the new string with incremented character
    }
  }

  // If all characters are the last in the base64Chars, prepend the first character
  return base64Chars[0] + newString.join('');
};

export const handleDuplicate = (
  appname,
  data,
  previousKey,
) => async dispatch => {
  const newCharacter = getNextBase64String(previousKey.slice(-2));
  const newKey = `${previousKey.slice(0, -2)}${newCharacter}`;
  return firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/customProfileFields/${newKey}`)
    .set({
      ...data,
      name: { ...data.name, value: `Copy of ${data.name.value}` },
    })
    .then(() =>
      dispatch(openSnackbar(i18n.t('Field duplicated successfully.'))),
    );
};

export const handleCustomFieldClick = (key, index) => dispatch =>
  dispatch({ type: 'CUSTOM_FIELD_CLICK', key, index });

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;

  return dispatch(newGroupFieldChange(prop, value, isValid));
};

export const addField = appname => (dispatch, getState) => {
  const {
    name,
    type,
    options,
    visibleToGroups,
    isMultiple,
    isRequired,
    isVisible,
  } = getState().auth.settings.profileFields.userProfileField;

  const field = {
    name: { value: name.value, isValid: true },
    options: { value: options.value, isValid: true },
    type: { value: type.value, isValid: true },
    visibleToGroups: { value: visibleToGroups.value, isValid: true },
    isRequired: { value: isRequired.value },
    isMultiple: { value: isMultiple.value },
    isVisible: { value: isVisible.value },
    createdAt: Date.now(),
  };

  dispatch({ type: 'ADD_UPDATE_FIELD' });
  return firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/customProfileFields`)
    .push(field)
    .then(ref => {
      dispatch({
        type: 'NEW_FIELD_ADDED',
        key: ref.key,
        data: field,
      });
      dispatch(openSnackbar('Field Created'));
    });
};

export const updateCustomField = (appname, key) => (dispatch, getState) => {
  const {
    name,
    type,
    options,
    isMultiple,
    isRequired,
    isVisible,
    visibleToGroups,
  } = getState().auth.settings.profileFields.userProfileField;

  const field = {
    name: { value: name.value, isValid: true },
    options: { value: options.value, isValid: true },
    type: { value: type.value, isValid: true },
    visibleToGroups: { value: visibleToGroups.value, isValid: true },
    isRequired: { value: isRequired.value },
    isMultiple: { value: isMultiple.value },
    isVisible: { value: isVisible.value },
    updatedAt: Date.now(),
  };

  dispatch({ type: 'ADD_UPDATE_FIELD' });
  return firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/customProfileFields/${key}`)
    .update(field)
    .then(ref => {
      dispatch({
        type: 'UPDATE_CUSTOM_FIELD',
        key,
        data: field,
      });
      dispatch(openSnackbar(i18n.t('Field Updated')));
    });
};

export const fetchCustomUserProfileField = appname => dispatch =>
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/customProfileFields`)
    .on('value', snapshot => {
      if (snapshot.exists()) {
        dispatch({
          type: 'CUSTOM_PROFILE_FIELDS',
          data: Object.entries(snapshot.val()),
        });
      }
    });
