import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';
import MenuItem from 'material-ui/MenuItem';
import { Trans, useTranslation } from 'react-i18next';

import Container from '../../../../app/components/container';
import SelectField from '../../../../app/components/selectField';
// import TextArea from '../../../../app/components/textArea';
import { CircularProgress } from '../../../../app/components/progress';
import { FlatButton } from '../../../../app/components/buttons';

import { USER_CLICKED_BUY_SMS } from '../../../../utils';

import './styles.css';

const ShareSmsForm = ({
  closeDistributeDialog,
  message,
  messageOptions,
  onMessageChange,
  selected,
  pending,
  onSendSMS,
  location,
  smsCredit,
  customDomain,
  onButtonClick,
  isAppAdmin,
}) => {
  const { t } = useTranslation();

  const hintMsg = `💡 ${t(
    'To add username in message body please add',
  )} {{user_name}}`;
  return (
    <div className="contacts-form">
      <h5>
        <Trans>Distribute to Selected Contacts</Trans>
      </h5>
      <div className="modal-controls-container">
        <IconButton onClick={() => closeDistributeDialog(customDomain)}>
          <Close />
        </IconButton>
      </div>
      <Container className="send-sms">
        <SelectField
          {...message}
          className="offset-top"
          label={t('Select Message')}
          options={messageOptions.map(messageOpt => (
            <MenuItem
              key={messageOpt}
              value={messageOpt}
              primaryText={messageOpt}
            />
          ))}
          onChange={(i, payload) => onMessageChange(payload)}
          disabled={messageOptions.length === 0}
          autoWidth
        />
        <span
          style={{ marginTop: '10px', fontSize: '0.8rem', color: '#696969' }}
        >
          {hintMsg}
        </span>
        <div id="sms-body" className="sms_body">
          {message.value}
        </div>
        {/* <TextArea
        id="sms-body"
        label="Message"
        value={message.value}
        disabled={pending}
        onChange={value => onMessageChange(value)}
        maxLength={1600}
      /> */}
      </Container>
      <div className="sms-container">
        <div className="sms-left-container">
          <div className="sms-left-title">
            <Trans>SMS LEFT</Trans>
          </div>
          <br />
          <div className="sms-left-number">{smsCredit}</div>
        </div>
        {!isAppAdmin && (
          <div className="sms-button-container">
            <FlatButton
              label={t('Buy more SMS')}
              onClick={() => {
                const nextPage = {
                  pathname: '/choose_sms_bundle',
                  state: { origin: location.pathname },
                };
                onButtonClick({
                  notificationTitle: USER_CLICKED_BUY_SMS,
                  nextPage,
                });
                closeDistributeDialog(customDomain);
              }}
              secondary
            />
          </div>
        )}
      </div>
      <p className="confirmation-message">
        <Trans>Share with</Trans> <strong>{selected}</strong>&nbsp;{' '}
        {selected > 1 ? t('selected contacts') : t('selected contact')}{' '}
        <Trans>by SMS</Trans>
      </p>
      <Container>
        {pending && <CircularProgress size={18} />}
        {!pending && (
          <FlatButton
            label={t('Confirm')}
            onClick={onSendSMS}
            style={{
              margin: 12,
            }}
            disabled={!message.value}
            primary
          />
        )}
      </Container>
    </div>
  );
};

ShareSmsForm.propTypes = {
  open: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  message: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  smsCredit: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  messageOptions: PropTypes.array.isRequired,
  openDistributeDialog: PropTypes.func.isRequired,
  closeDistributeDialog: PropTypes.func.isRequired,
  onMessageChange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  setDefaultMessage: PropTypes.func.isRequired,
  onAddContact: PropTypes.func.isRequired,
  onSendSMS: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  selected: PropTypes.number,
};

ShareSmsForm.defaultProps = {
  selected: 0,
};

export default withRouter(ShareSmsForm);
