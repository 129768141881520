import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Design = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16.19 63.97"
  >
    <g id="Layer_1" data-name="Layer 1">
      <path
        id="_Path_"
        data-name="&lt;Path&gt;"
        style={{ fill: '#a7a9ac' }}
        d="M13.87,1H2.32A1.27,1.27,0,0,0,1,2.26V9H15.16V2.26A1.29,1.29,0,0,0,13.87,1Z"
      />
      <path
        d={`M0,53.71v0s0,.1,0,.14a.08.08,0,0,0,0,0,1,1,0,0,0,.05.16s0,0,0,0l.07.11,0,0,0,0,7.07,
        9.27a1,1,0,0,0,.79.39,1,1,0,0,0,.79-.39l7.07-9.27,0,0,0,0,.07-.11s0,0,0,0,0-.11.05-.16a.08.08,
        0,0,1,0,0,.62.62,0,0,0,0-.14v0l0-40.18a1,1,0,0,0-2,0l0,39.18H9.08l0-39.13a1,1,0,0,0-2,0l0,
        39.13H2l0-39.13a1,1,0,0,0-2,0L0,53.71Zm13.12,1L8.05,61.33,3,54.69H13.12Z`}
      />
      <path
        d={`M12.58,0h-9A3.58,3.58,0,0,0,0,3.58V9a1,1,0,0,0,1,1H15.15a1,1,0,0,0,1-1V3.57A3.57,3.57,0,0,
        0,12.58,0Zm1.59,8H2V3.57A1.59,1.59,0,0,1,3.61,2h9a1.59,1.59,0,0,1,1.59,1.59Z`}
      />
    </g>
  </svg>
);

Design.propTypes = {
  fill: PropTypes.string,
};

Design.defaultProps = {
  fill: '#5eaa61',
};

export default Design;
