import { connect } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';

import { closeSnackbar } from './actions';

const mapStateToProps = state => ({
  open: state.app.snackbar.open,
  message: state.app.snackbar.message,
  autoHideDuration: state.app.snackbar.autoHideDuration,
});

const mapDispatchToProps = dispatch => ({
  onRequestClose: () => dispatch(closeSnackbar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
