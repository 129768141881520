import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Edit from './modules/edit';
import Listing from './modules/listing';

import './styles.css';

const Types = () => (
  <Switch>
    <Route path="/:appname/members/types/:groupID" component={Edit} />
    <Route path="/:appname/members/types" component={Listing} />
  </Switch>
);

export default Types;
