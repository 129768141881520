import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import styled from 'styled-components';
import { filter } from 'lodash';
import MenuItem from 'material-ui/MenuItem';
import { Trans, useTranslation } from 'react-i18next';

import TextField from '../../../../../../../app/components/textField';
import Container from '../../../../../../../app/components/container';
import SectionHeader from '../../../../../components/sectionHeader';
import { Tabs, Tab } from '../../../../../components/tabs';
import SelectField from '../../../../../../../app/components/selectField';
import logo from './logo.png';

const FullWidth = styled.div`
  width: 100%;
`;

const categoryChange = (field, value, categories, onFieldChange) => {
  const activeCategory = filter(categories, { id: value });

  onFieldChange(field, activeCategory[0]);
};

const Woocommerce = ({ component, onFieldChange, match, isAppAdmin }) => {
  const { t } = useTranslation();

  const switches = (title, field, propsValue, defaultValue) => {
    return (
      <FullWidth>
        <SectionHeader
          title={title}
          actions={
            <Toggle
              toggled={propsValue !== undefined ? propsValue : defaultValue}
              onToggle={(e, isToggled) => onFieldChange(field, isToggled)}
            />
          }
        />
        <br />
      </FullWidth>
    );
  };

  const displayOutOfStockItems = (title, field, propsValue, defaultValue) => (
    <FullWidth>
      <SectionHeader
        title={title}
        actions={
          <Toggle
            toggled={propsValue !== undefined ? propsValue : defaultValue}
            onToggle={(e, isToggled) => onFieldChange(field, isToggled)}
          />
        }
      />
      <br />
    </FullWidth>
  );

  const Categories = () => {
    const categories = component.categories || [];

    const categoryLinks = categories.map((category, index) => {
      const name = category.name;
      const slug = category.slug;
      let primaryText = name;

      if (
        name.trim().toLowerCase() !== slug.trim().toLowerCase() &&
        match.params.appname === 'cls'
      ) {
        primaryText = slug.replace(/-/g, ' ');
      }

      return (
        <MenuItem
          key={category.slug}
          value={category.id}
          primaryText={primaryText}
        />
      );
    });

    return categoryLinks.length ? (
      <SelectField
        label={t('Select a category')}
        value={component.activeCategory ? component.activeCategory.id : null}
        options={categoryLinks}
        onChange={(index, value) =>
          categoryChange(
            'activeCategory',
            value,
            component.categories,
            onFieldChange,
          )
        }
        disabled={!component.showProductsOfSpecificCategory}
      />
    ) : null;
  };

  const renderChildren = () => (
    <FullWidth>
      <SectionHeader
        title={t('Woocommerce')}
        tooltip={
          <span>
            <Trans>Follow the instructions below</Trans> <br />
            <Trans>to add an Woocommerce eCommerce component</Trans>
          </span>
        }
      />
      <div style={{ padding: '0 30px', marginTop: 24 }}>
        <p>
          <Trans>Enter your</Trans>&nbsp;
          <span style={{ color: '#DA1A26' }}>
            &quot;<Trans>Woocommerce Domain</Trans>&quot;
          </span>
          &nbsp;<Trans>and</Trans>&nbsp;
          <span style={{ color: '#DA1A26' }}>
            &quot;<Trans>REST API token and Secret key</Trans>&quot;
          </span>
          &nbsp;<Trans>below if you have already set up an account and</Trans>
          &nbsp;
          <span style={{ color: '#DA1A26' }}>
            <Trans>Rest API</Trans>
          </span>
          &nbsp;<Trans>with Woocommerce.</Trans>
        </p>
      </div>
      <div style={{ padding: '0 12px' }}>
        <TextField
          id="title"
          name="title"
          placeholder={t('Enter your Woocommerce Domain')}
          value={component.src}
          onChange={value => onFieldChange('src', value)}
        />
        <TextField
          id="Consumer Key"
          name="Consumer Key"
          placeholder={t('Enter your Consumer Key')}
          value={component.ckey}
          onChange={value => onFieldChange('ckey', value)}
        />
        <TextField
          id="Consumer Secret"
          name="Consumer Secret"
          placeholder={t('Enter your Consumer Secret')}
          value={component.csecret}
          onChange={value => onFieldChange('csecret', value)}
        />
        {component.categories &&
          component.categories.length > 0 &&
          switches(
            t('Show products of specific category'),
            'showProductsOfSpecificCategory',
            component.showProductsOfSpecificCategory,
            false,
          )}
        {Categories()}
      </div>
      {!isAppAdmin && (
        <div style={{ padding: '0 30px' }}>
          <p>
            <a
              href="https://beezer.zendesk.com/hc/en-us/articles/360038935011-WooCommerce"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Learn more</Trans>
            </a>
            &nbsp;
            <Trans>
              about how to setup your Woocommerce eCommerce component.
            </Trans>
          </p>
        </div>
      )}
      <Container>
        <img
          style={{ maxWidth: 190, marginTop: 24 }}
          src={logo}
          alt={t('Woocommerce logo')}
        />
      </Container>
    </FullWidth>
  );

  return (
    <Tabs>
      <Tab label={t('content')}>{renderChildren()}</Tab>
      <Tab
        label={t('design')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
      <Tab
        label={t('layout')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
    </Tabs>
  );
};

Woocommerce.propTypes = {
  component: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

Woocommerce.defaultProps = {
  component: {},
};

export default Woocommerce;
