import styled from 'styled-components';

export const container = styled.div`
display: flex;
flex-direction: column; 
overflow:auto;
`

export const section = styled.section`
padding-bottom:1em;
` 
