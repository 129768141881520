import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EnhancedButton from 'material-ui/internal/EnhancedButton';
import { PGMOL_APPS } from '../../../config/constants';

import './styles.css';

const getClassName = (active, borderTop, appname) =>
  `main-menu-item${active ? ' active' : ''}${borderTop ? ' border-top' : ''} ${
    PGMOL_APPS.indexOf(appname) > -1 ? 'pgmol-menu-item' : ''
  }`;

const MainMenuItem = ({ label, icon, href, borderTop, active, appname }) => (
  <EnhancedButton
    containerElement={
      <Link to={href} className={getClassName(active, borderTop, appname)} />
    }
  >
    {icon}
    <span className="main-menu-item-title">{label}</span>
  </EnhancedButton>
);

MainMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  href: PropTypes.string.isRequired,
  borderTop: PropTypes.bool,
  active: PropTypes.bool,
  appname: PropTypes.string.isRequired,
};

MainMenuItem.defaultProps = {
  icon: null,
  borderTop: false,
  active: false,
};

export default MainMenuItem;
