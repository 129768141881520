import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const onFocus = keyframes`
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.04);
  }
  66% {
    transform: scale(0.96);
  }
  100% {
    transform: scale(1);
  }
`;

const ButtonShapeIcon = styled.label`
  display: inline-block;
  width: calc(100% - 18px);
  height: calc(100% - 24px);
  margin: 12px 9px;
  border-radius: ${props => props.value}px;
  background-color: ${props => {
    if (props.selected) {
      return props.theme.mainWhite;
    } else if (props.isHovered) {
      return props.theme.primaryColorLight;
    }

    return props.theme.mainWhite;
  }};
  border: 1px solid ${props => props.theme.mainGrey};
  transition: all 300ms ease-out;
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  &:hover {
    background-color: ${props =>
      props.selected && !props.disabled && '#dddddd'};
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 3px;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${props =>
      props.selected && !props.disabled
        ? props.theme.primaryColor
        : 'transparent'};
    opacity: ${props => props.isKeyboardFocused && !props.selected && 0.7};
    animation: ${props =>
      props.isKeyboardFocused &&
      !props.selected &&
      `1s infinite ${onFocus} linear`};
  }
`;

ButtonShapeIcon.propTypes = {
  value: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default ButtonShapeIcon;
