import { connect } from 'react-redux';
import Events from './Events';
import {
  filterEventData,
  getEvents,
  selectEvent,
  selectAllEvents,
  deselectAllEvents,
  deleteEvents,
  getEventFields,
  selectMemberBody,
  onDuplicateSelected,
} from './actions';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.staff.events.list,
  profile: state.account.profile,
  memberBodies: state.auth.memberBodies.data.chunks,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFilterEventData: (filter, keys) => dispatch(filterEventData(filter, keys)),
  getEvents: () => dispatch(getEvents(ownProps.match.params.appname)),
  onSelect: key => dispatch(selectEvent(key)),
  onSelectAllEvents: () => dispatch(selectAllEvents()),
  onDeselectAllEvents: () => dispatch(deselectAllEvents()),
  onDeleteSelected: () => dispatch(deleteEvents(ownProps.match.params.appname)),
  onDuplicateSelected: rowId =>
    dispatch(onDuplicateSelected(ownProps.match.params.appname, rowId)),
  onGetEventFields: () =>
    dispatch(getEventFields(ownProps.match.params.appname)),
  selectMemberBody: key => dispatch(selectMemberBody(key)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Events));
