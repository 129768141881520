import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popover from 'material-ui/Popover';
import IconButton from 'material-ui/IconButton';
import { withTranslation } from 'react-i18next';

import TextField from '../../../../../../../app/components/textField';
import SectionHeader from '../../../../../components/sectionHeader';
import { Create } from '../../../../../components/icons';
import { Close } from '../../../../../../../app/components/icons';
import LinkButton from './LinkButton';

import './styles.css';

const displayOrder = [
  'Twitter',
  'LinkedIn',
  'Facebook',
  'Instagram',
  'Youtube',
  'Email',
  'Phone',
  'Web',
];
const additionalDisplayOrder = ['TripAdvisor', 'GoogleMap'];

const getPlaceholder = item => {
  if (item === 'email') {
    return 'Email address';
  } else if (item === 'phone') {
    return 'Phone number';
  }

  return `${item.charAt(0).toUpperCase() + item.slice(1)} page`;
};

const isLink = key =>
  key === 'twitter' ||
  key === 'instagram' ||
  key === 'email' ||
  key === 'facebook' ||
  key === 'linkedin' ||
  key === 'web' ||
  key === 'phone' ||
  key === 'youtube' ||
  key === 'tripadvisor' ||
  key === 'googlemap';

const extractLinksFromItem = item => {
  const result = {};
  Object.keys(item).forEach(key => {
    if (key && isLink(key)) {
      result[key] = {
        type: key,
        url: item[key],
        placeholder: getPlaceholder(key),
      };
    }
  });

  return result;
};

class SocialLinks extends Component {
  state = {
    linksDialogOpen: false,
    anchorEl: null,
  };

  showAddPageDialog = e => {
    e.preventDefault();

    this.setState({
      linksDialogOpen: true,
      anchorEl: e.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      linksDialogOpen: false,
      anchorEl: null,
    });
  };

  addLink = type => {
    if (!this.props.data[type]) {
      this.props.addSocialLinkToDelegate(type);
    }
  };

  handleChange = field => value => this.props.onChange(field, value);

  render() {
    const { removeSocialLinkFromDelegate, t } = this.props;
    const unorderedLinks = extractLinksFromItem(this.props.data);
    const linkCreation = current => {
      const link = unorderedLinks[current.toLowerCase()];
      if (link) {
        return (
          <div className="social-link-text-field" key={link.type}>
            <TextField
              value={link.url}
              hintText={link.url}
              onChange={this.handleChange(link.type)}
              placeholder={link.placeholder}
            />
            <IconButton
              onClick={() => removeSocialLinkFromDelegate(link)}
              tooltip={t('Remove this page')}
              tooltipPosition="bottom-left"
            >
              <Close />
            </IconButton>
          </div>
        );
      }
      return null;
    };
    const orderedLinks = displayOrder.map(current => {
      return linkCreation(current);
    });

    const additionalOrderedLinks = additionalDisplayOrder.map(current => {
      return linkCreation(current);
    });

    return (
      <div className="social-links-container">
        <SectionHeader
          className="social-section-header"
          title={t('Contact links')}
          actions={
            <IconButton
              onClick={this.showAddPageDialog}
              tooltip={t('Add page')}
            >
              <Create />
            </IconButton>
          }
          borderTop
        />
        <Popover
          open={this.state.linksDialogOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          onRequestClose={this.handleRequestClose}
          className="social-links-icons-list"
        >
          {displayOrder.map(link => (
            <LinkButton
              key={link}
              type={link}
              onClick={() => this.addLink(link.toLowerCase())}
            />
          ))}
          {this.props.component.type === 'Roster' &&
            additionalDisplayOrder.map(link => (
              <LinkButton
                key={link}
                type={link}
                onClick={() => this.addLink(link.toLowerCase())}
              />
            ))}
        </Popover>
        <div className="social-links-list">
          <React.Fragment>
            {orderedLinks}
            {additionalOrderedLinks}
          </React.Fragment>
        </div>
      </div>
    );
  }
}

SocialLinks.propTypes = {
  addSocialLinkToDelegate: PropTypes.func.isRequired,
  removeSocialLinkFromDelegate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withTranslation()(SocialLinks);
