import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CreateWithSteps } from '../components/create';
import { SimpleToolbar } from '../../../../../../../app/modules/toolbar';
import DefineApp from '../createApp/DefineApp';
import { store } from '../../../../../../../index';

const DuplicateApp = ({
  steps,
  history,
  error,
  pending,
  onSubmit,
  title,
  domain,
  onTitleChange,
  onDomainChange,
  organisation,
  appToCopy,
  lastVisitedApp,
  resetData,
  onAppChange,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!appToCopy) {
      history.push(`/${lastVisitedApp}/editor`);
    }
  }, []);

  const handleSubmit = () => {
    onSubmit({
      appToCopy,
      newAppDomain: domain.value,
      newAppTitle: title.value,
      callback: async () => {
        await onAppChange(domain.value, lastVisitedApp);
        history.push(`/app_menu`);
        resetData();
      },
    });
  };

  const handleCancel = () => {
    resetData();
    history.goBack();
  };

  const displayCancelButton = typeof organisation.apps !== 'undefined';

  const renderContent = () => {
    return [
      <DefineApp
        title={title}
        domain={domain}
        onTitleChange={onTitleChange}
        onDomainChange={onDomainChange}
      />,
    ];
  };
  return (
    <div>
      <Provider store={store}>
        <SimpleToolbar onExit={handleCancel} />
        <CreateWithSteps
          title={t('Duplicate App')}
          steps={steps}
          stepContent={renderContent()}
          submitLabel={t('Duplicate App')}
          onSubmit={handleSubmit}
          onCancel={displayCancelButton ? handleCancel : undefined}
          error={error}
          pending={pending}
          className="create-app"
        />
      </Provider>
    </div>
  );
};

export default DuplicateApp;
