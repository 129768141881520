import firebase from '../../../../firebase';
import { objectToArray, arrayToObject } from '../../../../utils/object';
import { updateGroupData } from './editGroup/actions';

export const REQUEST_GROUPS = 'REQUEST_GROUPS';
export const RECEIVE_GROUPS = 'RECEIVE_GROUPS';
export const STOP_RECEIVE_GROUPS = 'STOP_RECEIVE_GROUPS';

export const REPORT_GROUPS_REQUEST_ERROR = 'REPORT_GROUPS_REQUEST_ERROR';
export const SELECT_GROUP = 'SELECT_GROUP';
export const FILTER_GROUP_DATA = 'FILTER_GROUP_DATA';
export const FILTER_GROUPS_DATA = 'FILTER_GROUPS_DATA';
export const SELECT_ALL_GROUPS = 'SELECT_ALL_GROUPS';
export const DESELECT_ALL_GROUPS = 'DESELECT_ALL_GROUPS';

export const filterData = (filteredChunks, sortedItems) => dispatch =>
  dispatch({
    type: FILTER_GROUPS_DATA,
    payload: {
      filteredChunks,
      sortedItems,
    },
  });

const requestGroups = appname => ({
  type: REQUEST_GROUPS,
  appname,
});

export const selectGroup = key => ({
  type: SELECT_GROUP,
  key,
});

export const getGroups = appname => dispatch => {
  dispatch(requestGroups(appname));

  firebase
    .database()
    .ref(`app_groups/${appname}/groups`)
    .on('value', snap => {
      if (snap.exists()) {
        const data = snap.val();
        const subscriptionGroups = objectToArray(data);
        const hasSubscriptionPlans = subscriptionGroups.filter(
          item => item.value.subscriptionPlan !== undefined,
        );
        const hasNoSubscription = subscriptionGroups.filter(
          item => item.value.subscriptionPlan === undefined,
        );

        if (hasSubscriptionPlans.length > 0) {
          let i = 1;
          // eslint-disable-next-line no-restricted-syntax
          for (const key in hasSubscriptionPlans) {
            if (
              Object.prototype.hasOwnProperty.call(hasSubscriptionPlans, key)
            ) {
              hasSubscriptionPlans[key].value.order =
                hasSubscriptionPlans[key].value.order || i;
              i++;
            }
          }
        }

        const allSubscriptions = [
          ...hasSubscriptionPlans,
          ...hasNoSubscription,
        ];
        dispatch({
          type: RECEIVE_GROUPS,
          value: arrayToObject(allSubscriptions),
        });
      }
    });
};

export const getGroupsData = appname => dispatch => {
  dispatch(requestGroups(appname));

  firebase
    .database()
    .ref(`app_groups/${appname}/groups`)
    .once('value', snap => {
      if (snap.exists()) {
        const data = snap.val();
        const subscriptionGroups = objectToArray(data);
        const hasSubscriptionPlans = subscriptionGroups.filter(
          item => item.value.subscriptionPlan !== undefined,
        );
        const hasNoSubscription = subscriptionGroups.filter(
          item => item.value.subscriptionPlan === undefined,
        );

        if (hasSubscriptionPlans.length > 0) {
          let i = 1;
          // eslint-disable-next-line no-restricted-syntax
          for (const key in hasSubscriptionPlans) {
            if (
              Object.prototype.hasOwnProperty.call(hasSubscriptionPlans, key)
            ) {
              hasSubscriptionPlans[key].value.order =
                hasSubscriptionPlans[key].value.order || i;
              i++;
            }
          }
        }

        const allSubscriptions = [
          ...hasSubscriptionPlans,
          ...hasNoSubscription,
        ];
        dispatch({
          type: RECEIVE_GROUPS,
          value: arrayToObject(allSubscriptions),
        });
      }
    });
};

export const removeGroupsListener = appname => dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups`)
    .off('value');
  dispatch({
    type: STOP_RECEIVE_GROUPS,
  });
};
export const filterGroupData = filter => dispatch =>
  dispatch({
    type: FILTER_GROUP_DATA,
    payload: filter,
  });

export const selectAllGroups = () => ({ type: 'SELECT_ALL_GROUPS' });
export const deselectAllGroups = () => ({ type: 'DESELECT_ALL_GROUPS' });

export const searchGroups = query => dispatch =>
  dispatch({
    type: 'SEARCH_GROUPS',
    query,
  });

export const deleteGroup = (appname, key) => (dispatch, getState) => {
  if (key !== 'default') {
    firebase
      .database()
      .ref(`app_groups/${appname}/groups/${key}`)
      .remove()
      .then(() =>
        dispatch({
          type: 'GROUP_DELETED',
          appname,
          key,
        }),
      );
    const defaultGroup = getState().auth.groups.data.chunks.default;
    const signupAsGroup =
      (defaultGroup.signupAs && defaultGroup.signupAs.groups) || [];
    if (signupAsGroup.length > 0) {
      const removedGroupIndex = signupAsGroup.indexOf(key);
      signupAsGroup.splice(removedGroupIndex, 1);
      if (signupAsGroup.length > 0) {
        firebase
          .database()
          .ref(`app_groups/${appname}/groups/default/signupAs/groups`)
          .set(signupAsGroup);
      } else {
        firebase
          .database()
          .ref(`app_groups/${appname}/groups/default/signupAs`)
          .set(null);
      }
    }
  }
};

export const deleteGroups = appname => (dispatch, getState) => {
  const { selected, subscriptionGroups } = getState().auth.groups.data;
  const subscriptionPlans = objectToArray(subscriptionGroups);
  const subscriptionPlansLeft = subscriptionPlans.filter(
    item => !selected.includes(item),
  );

  if (selected.length === subscriptionPlans.length) {
    Promise.all(
      selected
        .filter(key => key !== 'default')
        .map(key => dispatch(deleteGroup(appname, key))),
    );
  } else if (selected.length > 1) {
    Promise.all(
      selected
        .filter(key => key !== 'default')
        .map(key => dispatch(deleteGroup(appname, key))),
    ).then(() => {
      Promise.all(
        subscriptionPlansLeft.map(item =>
          dispatch(updateGroupData(appname, item.id, { order: null })),
        ),
      );
    });
  } else if (selected.length === 1) {
    const selectedOrderNo = subscriptionPlans.find(
      item => item.id === selected[0],
    );

    let plansHigherThanSelected = [];
    if (selectedOrderNo) {
      plansHigherThanSelected = subscriptionPlans.filter(
        item =>
          selectedOrderNo.value &&
          item.value.order > selectedOrderNo.value.order,
      );
    }

    if (plansHigherThanSelected.length > 0) {
      const sortPlansHigherThanSelected = plansHigherThanSelected.sort(
        (a, b) => a.value.order - b.value.order,
      );
      Promise.all(
        selected
          .filter(key => key !== 'default')
          .map(key => dispatch(deleteGroup(appname, key))),
      ).then(() => {
        Promise.all(
          sortPlansHigherThanSelected.map((item, index) =>
            dispatch(
              updateGroupData(appname, item.id, {
                order: selectedOrderNo.value.order + index,
              }),
            ),
          ),
        );
      });
    } else {
      Promise.all(
        selected
          .filter(key => key !== 'default')
          .map(key => dispatch(deleteGroup(appname, key))),
      );
    }
  }
};
