import * as actions from './types';

const initialState = {
  data: {
    marketplace_settings: {
      vendorApproval: {
        isEnabled: false,
      },
    },
  },
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case actions.RECEIVED_MARKETPLACE_SETTINGS: {
      return {
        ...state,
        data: action.value,
      };
    }

    case actions.NO_MARKETPLACE_SETTINGS_FOUND: {
      return state;
    }

    default:
      return state;
  }
};

export default products;
