import _mapValues from 'lodash/mapValues';
import firebase from '../../../../firebase';
import { TIER_1_PLAN_ID } from '../../../../plans';
import {
  RECEIVE_MARKETPLACE_STATUS,
  STOP_RECEIVING_MARKETPLACE_STATUS,
  RECEIVE_FOOD_ORDER_STATUS,
  STOP_RECEIVING_FOOD_ORDER_STATUS,
  RECEIVE_BOOKING_STATUS,
  STOP_RECEIVING_BOOKING_STATUS,
} from '../../types';

export const toggleMarketplace = (appname, value) => (dispatch, getState) => {
  const subscription = getState().account.subscriptions.items[appname];
  const legacyFlag = getState().account.organisation.legacy_flag;
  const trialExpired = getState().account.organisation.trial_expired;

  // Allow a user to enable marketplace if they are on the plan accelerate
  // or if they are an expert customer (only expert customers have a legacy flag)
  if (
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('accelerate')) ||
    (subscription && subscription.plan && subscription.plan.includes('diy')) ||
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('launch')) ||
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('deluxe')) ||
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('expert')) ||
    legacyFlag ||
    !trialExpired
  ) {
    if (value) {
      Promise.all([
        firebase
          .database()
          .ref(`app_groups/${appname}/groups`)
          .once('value'),
        firebase
          .database()
          .ref(`apps/${appname}/draft/always_data/marketplace`)
          .set({
            enabled: true,
          }),
      ]).then(([snap]) => {
        if (!snap.exists()) {
          firebase
            .database()
            .ref(`apps/${appname}/draft/lazy_data`)
            .once('value')
            .then(lazyData => {
              firebase
                .database()
                .ref(`app_groups/${appname}`)
                .set({
                  groups: {
                    default: {
                      name: 'My Default Group',
                      default: true,
                      date: Date.now(),
                      access: {
                        // provide seed key, so access node exists
                        // ...and because we aren't handling this correctly in other places at the moment
                        someKey: true,
                      },
                    },
                  },
                  not_private: _mapValues(lazyData.val(), () => true),
                });
            });
        }
      });
    } else {
      firebase
        .database()
        .ref(`apps/${appname}/draft/always_data/marketplace`)
        .set(null);
    }
  } else {
    dispatch({
      type: 'UPGRADE_AUTH_MODEL',
      open: true,
    });
  }
};

export const toggleFoodOrder = (appname, value) => (dispatch, getState) => {
  const subscription = getState().account.subscriptions.items[appname];
  const legacyFlag = getState().account.organisation.legacy_flag;
  const trialExpired = getState().account.organisation.trial_expired;
  const isAppAdmin =
    (getState().account.auth.profile.admin_appname &&
      getState().account.auth.profile.admin_appname.length > 0) ||
    false;

  if (subscription.plan === TIER_1_PLAN_ID) {
    return;
  }

  // Allow a user to enable marketplace if they are on the plan accelerate
  // or if they are an expert customer (only expert customers have a legacy flag)
  if (
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('accelerate')) ||
    (subscription && subscription.plan && subscription.plan.includes('diy')) ||
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('launch')) ||
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('deluxe')) ||
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('expert')) ||
    legacyFlag ||
    isAppAdmin ||
    !trialExpired
  ) {
    if (value) {
      Promise.all([
        firebase
          .database()
          .ref(`app_groups/${appname}/groups`)
          .once('value'),
        firebase
          .database()
          .ref(`apps/${appname}/draft/always_data/food_order`)
          .set({
            enabled: true,
          }),
      ]).then(([snap]) => {
        if (!snap.exists()) {
          firebase
            .database()
            .ref(`apps/${appname}/draft/lazy_data`)
            .once('value')
            .then(lazyData => {
              firebase
                .database()
                .ref(`app_groups/${appname}`)
                .set({
                  groups: {
                    default: {
                      name: 'My Default Group',
                      default: true,
                      date: Date.now(),
                      access: {
                        // provide seed key, so access node exists
                        // ...and because we aren't handling this correctly in other places at the moment
                        someKey: true,
                      },
                    },
                  },
                  not_private: _mapValues(lazyData.val(), () => true),
                });
            });
        }
      });
    } else {
      firebase
        .database()
        .ref(`apps/${appname}/draft/always_data/food_order`)
        .set(null);
    }
  } else {
    dispatch({
      type: 'UPGRADE_AUTH_MODEL',
      open: true,
    });
  }
};

export const stopGetMarketplaceStatus = appname => dispatch => {
  firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/marketplace`)
    .off('value');
  dispatch({
    type: STOP_RECEIVING_MARKETPLACE_STATUS,
    value: appname,
  });
};

export const getMarketplaceStatus = appname => dispatch => {
  firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/marketplace`)
    .on('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_MARKETPLACE_STATUS,
          value: true,
        });
        return;
      }

      dispatch({
        type: RECEIVE_MARKETPLACE_STATUS,
        value: false,
      });
    });
};

export const getFoodOrderStatus = appname => dispatch => {
  firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/food_order`)
    .on('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_FOOD_ORDER_STATUS,
          value: true,
        });
        return;
      }

      dispatch({
        type: RECEIVE_FOOD_ORDER_STATUS,
        value: false,
      });
    });
};

export const stopGetFoodOrderStatus = appname => dispatch => {
  firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/food_order`)
    .off('value');
  dispatch({
    type: STOP_RECEIVING_FOOD_ORDER_STATUS,
    value: appname,
  });
};

const getAuth = async appname => {
  const snap = await firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/auth`)
    .once('value');

  if (snap.exists()) return true;

  return false;
};

export const toggleBooking = (appname, value) => async (dispatch, getState) => {
  const subscription = getState().account.subscriptions.items[appname];
  const legacyFlag = getState().account.organisation.legacy_flag;
  const trialExpired = getState().account.organisation.trial_expired;
  const memberLoginEnabled = await getAuth(appname);
  if (
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('accelerate')) ||
    (subscription && subscription.plan && subscription.plan.includes('diy')) ||
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('launch')) ||
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('deluxe')) ||
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('expert')) ||
    legacyFlag ||
    !trialExpired
  ) {
    if (value) {
      const emailRef = firebase
        .database()
        .ref(`bookings/${appname}/meta_data/email`);
      emailRef.once('value', snap => {
        const email = snap.val();
        if (!email) {
          const adminEmail = getState().account.organisation.default_email;
          emailRef.set(adminEmail);
        }
      });

      Promise.all([
        firebase
          .database()
          .ref(`app_groups/${appname}/groups`)
          .once('value'),
        firebase
          .database()
          .ref(`apps/${appname}/draft/always_data/booking`)
          .set({
            enabled: value,
          }),
      ]).then(([snap]) => {
        if (!snap.exists()) {
          firebase
            .database()
            .ref(`apps/${appname}/draft/lazy_data`)
            .once('value')
            .then(lazyData => {
              firebase
                .database()
                .ref(`app_groups/${appname}`)
                .set({
                  groups: {
                    default: {
                      name: 'My Default Group',
                      default: true,
                      date: Date.now(),
                      access: {
                        // provide seed key, so access node exists
                        // ...and because we aren't handling this correctly in other places at the moment
                        someKey: true,
                      },
                    },
                  },
                  not_private: _mapValues(lazyData.val(), () => true),
                });
            });
        }
      });
    } else {
      firebase
        .database()
        .ref(`apps/${appname}/draft/always_data/booking`)
        .set(null);
    }
  } else {
    dispatch({
      type: 'UPGRADE_AUTH_MODEL',
      open: true,
    });
  }
};

export const getBookingStatus = appname => dispatch => {
  firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/booking`)
    .on('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_BOOKING_STATUS,
          value: true,
        });
        return;
      }

      dispatch({
        type: RECEIVE_BOOKING_STATUS,
        value: false,
      });
    });
};

export const stopGetBookingStatus = appname => dispatch => {
  firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/booking`)
    .off('value');
  dispatch({
    type: STOP_RECEIVING_BOOKING_STATUS,
    value: appname,
  });
};
