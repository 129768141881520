import {
  OPEN_VOTE_DETAIL,
  CLOSE_VOTE_DETAIL_DIALOG,
  PREVIOUS_VOTE_RESPONSE,
  NEXT_VOTE_RESPONSE,
  OPEN_FORM_DETAIL,
  CLOSE_FORM_DETAIL_DIALOG,
} from './actions';
import { FAVOURITE_SELECTED, DELETE_SELECTED_VOTES } from '../actions';

const initialState = {
  open: false,
  data: null,
  key: null,
  formDetailOpen: false,
  formDetailData: null,
  formDetailKey: null,
};

const voteDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_VOTE_DETAIL:
      return {
        ...state,
        open: true,
        data: action.vote,
        key: action.key,
      };
    case OPEN_FORM_DETAIL:
      return {
        ...state,
        formDetailOpen: true,
        formDetailKey: action.vote,
        formDetailKey: action.key,
      };

    case CLOSE_FORM_DETAIL_DIALOG:
      return initialState;

    case CLOSE_VOTE_DETAIL_DIALOG:
      return initialState;

    case PREVIOUS_VOTE_RESPONSE: {
      const index = action.index - 1;
      const key = action.items[index - 1];
      if (key) {
        return {
          ...state,
          open: true,
          data: action.votes[key],
          key,
        };
      }
      return {
        ...state,
      };
    }

    case NEXT_VOTE_RESPONSE: {
      const index = action.index - 1;
      const key = action.items[index + 1];
      if (key) {
        return {
          ...state,
          open: true,
          data: action.votes[key],
          key,
        };
      }
      return {
        ...state,
      };
    }

    case FAVOURITE_SELECTED: {
      return {
        ...state,
        data: action.data[action.selected],
      };
    }

    case DELETE_SELECTED_VOTES: {
      return {
        ...state,
        open: false,
      };
    }

    default:
      return state;
  }
};

export default voteDetailsReducer;
