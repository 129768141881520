import firebase from '../../../../../../firebase';

export const updateField = (appname, field, value) => dispatch =>
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default`)
    .child('restrictedaccess')
    .update({ [`${field}`]: value })
    .then(() =>
      dispatch({
        type: 'UPDATE_RESTRICTED_ACCESS_FIELD',
        appname,
        field,
        value,
      }),
    );

export const handleRadioChange = (appname, field, value) => dispatch => {
  return firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/restrictedaccess`)
    .child(field)
    .set(value)
    .then(() =>
      dispatch({
        type: 'UPDATE_RESTRICTED_ACCESS_FIELD',
        appname,
        field,
        value,
      }),
    );
};

export const loadLayoutData = appname => dispatch => {
  const appRef = firebase.database().ref(`apps/${appname}`);
  const draftRef = appRef.child('draft');
  draftRef.once('value').then(draftSnapshot => {
    const data = draftSnapshot.val();
    if (data) {
      dispatch(receiveLayoutData(appname, data.layout_data));
      dispatch(hydrateAlwaysData(data.always_data));
    }
  });
};

const receiveLayoutData = (appname, data) => ({
  type: 'RECEIVE_LAYOUT_DATA',
  data,
  appname,
});

const hydrateAlwaysData = data => ({
  type: 'HYDRATE_ALWAYS_DATA',
  data,
});
