const initialState = {
  pending: false,
  isDirty: false,
  message: null,
  firstName: {
    value: '',
  },
  lastName: {
    value: '',
  },
  name: {
    value: '',
  },
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case 'HYDRATE_PROFILE':
      return {
        ...state,
        ...action.data,
        firstName: {
          // extend firstName field for validation
          value: action.data.firstName || '',
        },
        lastName: {
          // extend lastName field for validation
          value: action.data.lastName || '',
        },
      };

    case 'SAVE_PROFILE_CHANGES':
      return {
        ...state,
        pending: true,
      };

    case 'PROFILE_UPDATED':
      return {
        ...state,
        isDirty: false,
        pending: false,
      };

    case 'UPDATE_PROFILE_ERROR':
      return {
        ...state,
        pending: false,
        error: action.err,
      };

    case 'PROFILE_FIELD_CHANGE':
      return {
        ...state,
        isDirty: true,
        [action.prop]: {
          value: action.value,
          isDirty: true,
        },
      };

    // To reset profile data once user sign off
    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default profile;
