import React, { useEffect, useContext } from 'react';
import { ShepherdTourContext } from 'react-shepherd';
import { Trans } from 'react-i18next';
import 'shepherd.js/dist/css/shepherd.css';
import './styles.css';

function Button() {
  return (
    <button
      className="button dark shepherd-start-btn"
      // onClick={() => {
      //   tour.start();
      // }}
      style={{ position: 'fixed', top: '0', left: '0', visibility: 'hidden' }}
    >
      <Trans>Start Tour</Trans>
    </button>
  );
}

const Appcues = ({ start, type, onSubmit }) => {
  const tour = useContext(ShepherdTourContext);
  const submitSurveyForm = (formType, data) => {
    onSubmit(formType, data);
    tour.complete();
  };

  useEffect(() => {
    if (start) {
      tour.start();
      if (type === 'ratingForm') {
        let ratingForm1Value = '';
        const ratingValueRadioBtn = document.getElementsByName(
          'beezer_cx_rating_input',
        );
        const ratingValueRadioLabel = document.getElementsByName(
          'beezer_cx_rating_label',
        );
        const surveyFormSubmitBtn = document.getElementsByName(
          'beezer_rating_form_submit',
        );
        if (ratingValueRadioBtn && surveyFormSubmitBtn.length > 0) {
          surveyFormSubmitBtn[0].addEventListener('click', () => {
            submitSurveyForm('ratingForm1', {
              rating: ratingForm1Value,
            });
          });
          ratingValueRadioBtn.forEach((btn, index) =>
            btn.addEventListener('change', () => {
              ratingValueRadioLabel.forEach(label => (label.className = ''));
              ratingValueRadioLabel[index].classList.add('appcues-active');
              ratingForm1Value = btn.value;
            }),
          );
        }
      } else if (type === 'surveyForm') {
        let surveyForm1Value = '';
        const surveyValueBtn = document.getElementsByName('beezer_heard_from');
        const surveyFormComment = document.getElementsByName(
          'beezer_survey_form_comment',
        );
        const surveyFormSubmitBtn = document.getElementsByName(
          'beezer_survey_form_submit',
        );
        if (surveyValueBtn && surveyFormSubmitBtn.length > 0) {
          surveyFormSubmitBtn[0].addEventListener('click', () => {
            submitSurveyForm('surveyForm1', {
              heardFrom: surveyForm1Value,
              comment: surveyFormComment[0].value,
            });
          });
          surveyValueBtn.forEach(btn =>
            btn.addEventListener('change', () => {
              surveyForm1Value = btn.value;
            }),
          );
        }
      } else if (type === 'referFriend') {
        const referralTabBtn = document.getElementsByName(
          'beezer_refer_submit',
        );
        if (referralTabBtn.length > 0) {
          referralTabBtn[0].addEventListener('click', () => {
            submitSurveyForm('referFriend');
          });
        }
      }
    }
  }, [start]);

  return (
    <div>
      <Button />
    </div>
  );
};

export default Appcues;
