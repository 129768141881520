const initialState = {
  errorMessage: '',
  email: '',
  message: '',
};

const firebaseActions = (state = initialState, action) => {
  switch (action.type) {
    case 'RECOVERING_EMAIL':
      return {
        ...state,
        isFetching: true,
        errorMessage: '',
      };

    case 'RECOVER_EMAIL_ERROR':
      return {
        ...state,
        isFetching: false,
        errorMessage: action.err.message,
      };

    case 'RECOVER_EMAIL_SUCCESS':
      return {
        ...state,
        isFetching: false,
        errorMessage: '',
        email: action.email,
      };

    case 'VERIFYING_EMAIL':
      return {
        ...state,
        isFetching: false,
        message: 'We are Verifying your Email',
      };

    case 'VERIFY_EMAIL_SUCCESS':
      return {
        ...state,
        isFetching: false,
        message: action.message,
      };

    case 'VERIFY_EMAIL_ERROR':
      return {
        ...state,
        isFetching: false,
        message: action.err.message,
      };

    case 'RESETTING_PASSWORD':
      return {
        ...state,
        isFetching: false,
        message: 'We are Resetting your Password',
      };

    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        isFetching: false,
        message: action.message,
      };

    case 'RESET_PASSWORD_ERROR':
      return {
        ...state,
        isFetching: false,
        errorMessage: action.err.message,
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
};

export default firebaseActions;
