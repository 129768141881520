import {
  OPEN_FORM_DETAIL,
  CLOSE_FORM_DETAIL_DIALOG,
  PREVIOUS_FORM_RESPONSE,
  NEXT_FORM_RESPONSE,
} from './actions';
import { FAVOURITE_SELECTED, DELETE_SELECTED } from '../actions';

const initialState = {
  open: false,
  data: null,
  key: null,
};

const formDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_FORM_DETAIL:
      return {
        ...state,
        open: true,
        data: action.form,
        key: action.key,
      };

    case CLOSE_FORM_DETAIL_DIALOG:
      return initialState;

    case PREVIOUS_FORM_RESPONSE: {
      const key =
        action.forms.data.items[action.forms.data.items.indexOf(state.key) - 1];
      if (key) {
        return {
          ...state,
          open: true,
          data: action.forms.data.chunks[key],
          key,
        };
      }
      return {
        ...state,
      };
    }

    case NEXT_FORM_RESPONSE: {
      const key =
        action.forms.data.items[action.forms.data.items.indexOf(state.key) + 1];
      if (key) {
        return {
          ...state,
          open: true,
          data: action.forms.data.chunks[key],
          key,
        };
      }
      return {
        ...state,
      };
    }

    case FAVOURITE_SELECTED: {
      return {
        ...state,
        data: action.data[action.selected],
      };
    }

    case DELETE_SELECTED: {
      return {
        ...state,
        open: false,
      };
    }

    default:
      return state;
  }
};

export default formDetailsReducer;
