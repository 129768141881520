import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import EventCalendarItem from './EventCalendarItem';
import { itemFieldChange } from '../actions';

const mapStateToProps = (state, ownProps) => ({
  fields: state.editor.data.lazyData[ownProps.component.lazy_data],
  ...ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) => {
    const tpl = ['primary', 'secondary', 'content']
    const { item, calendarType, itinerary } = queryString.parse(ownProps.location.search);
    value = (field === 'isPublic' && ((tpl).indexOf(ownProps.data.tpl) > -1 || ownProps.data.type === 'DateRange' || ownProps.data.type === 'Daterange')) ? true : value
    return dispatch(
      itemFieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        ownProps.component.type,
        item,
        field,
        value,
        itinerary,
        calendarType
      ),
    );
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EventCalendarItem),
);
