import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Material Imports
import AddIcon from 'material-ui/svg-icons/content/add';

import PageHeader from '../../app/components/pageHeader';
import { FloatingActionButton } from '../../app/components/buttons';

import PrivateRoute from '../../app/components/privateRoute';
import AccountsDropdown from '../editor/internals/accountsDropdown';
import RegionalPerformanceCentre from './modules/RegionalPerformanceCentre';
import PlMatchSupport from './modules/PlMatchSupport';

const PGMOL = props => {
  const {
    match: {
      params: { appname },
    },
    togglePerformanceModal,
    toggleMatchSupportModal,
    pgmol,
    toggleBlogModal,
    location,
  } = props;

  let pageTitle = 'Regional Performance Centres';

  if (location.pathname && location.pathname.includes('/pl-match-support')) {
    pageTitle = 'PL Match Day Support';
  } else if (location.pathname && location.pathname.includes('/blog')) {
    pageTitle = 'Blog';
  }

  return (
    <div className="flex-container sidebar-extended background-texture">
      <PageHeader
        title={pageTitle}
        userSettings={<AccountsDropdown />}
        actions={
          <div style={{ paddingRight: '10px' }}>
            <Route
              path="/:appname/pgmol/regional-performance-centre"
              render={() => (
                <div className="row middle-xs">
                  <div className="col">
                    <FloatingActionButton
                      icon={<AddIcon />}
                      onClick={() =>
                        togglePerformanceModal(
                          !pgmol.regionalPerformanceCentre.modalOpen,
                        )
                      }
                    />
                  </div>
                </div>
              )}
            />
            <Route
              path="/:appname/pgmol/pl-match-support"
              render={() => (
                <div className="row middle-xs">
                  <div className="col">
                    <FloatingActionButton
                      icon={<AddIcon />}
                      onClick={() =>
                        toggleMatchSupportModal(!pgmol.plMatchSupport.modalOpen)
                      }
                    />
                  </div>
                </div>
              )}
            />

            {/* <Route
              path="/:appname/pgmol/blog"
              render={() => (
                <div className="row middle-xs">
                  <div className="col">
                    <FloatingActionButton
                      icon={<AddIcon />}
                      onClick={() => toggleBlogModal(!pgmol.blog.modalOpen)}
                    />
                  </div>
                </div>
              )}
            /> */}
          </div>
        }
      />
      <Switch>
        <Redirect
          exact
          from={`/${appname}/pgmol`}
          to={`/${appname}/pgmol/regional-performance-centre`}
        />
        <PrivateRoute
          path="/:appname/pgmol/regional-performance-centre"
          component={RegionalPerformanceCentre}
          requiresUpgrade={false}
        />
        <PrivateRoute
          path="/:appname/pgmol/pl-match-support"
          component={PlMatchSupport}
        />
      </Switch>
    </div>
  );
};

export default PGMOL;
