import React from 'react';
import PropTypes from 'prop-types';
import ReactDropzone from 'react-dropzone';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import CloudUpload from 'material-ui/svg-icons/file/cloud-upload';

const DropzoneTitle = styled.h5`
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  margin: 12px 0;
  color: #c1c1c1;
`;

const DropzoneText = styled.p`
  color: #010101;
`;

const Dropzone = ({ className, accept, onDrop }) => (
  <ReactDropzone className={className} accept={accept} onDrop={onDrop}>
    <DropzoneTitle>
      <Trans>Drop your files here</Trans>
    </DropzoneTitle>
    <CloudUpload style={{ color: '#c1c1c1', height: '75px', width: '75px' }} />
    <DropzoneText>
      <Trans>or</Trans>{' '}
      <u>
        <Trans>select a file from your computer</Trans>
      </u>
    </DropzoneText>
  </ReactDropzone>
);

Dropzone.propTypes = {
  className: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
};

export default styled(Dropzone)`
  height: ${props => props.height || '200px'};
  width: ${props => props.width || '100%'};
  border: 1px solid #d3d3d3;
  background-color: #f6f6f6;
  border-radius: 3px;
  padding: 12px;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
  cursor: pointer;
  transition: all 300ms ease-out;

  &:hover {
    background-color: #dddddd;
  }
`;
