import React, { Fragment } from 'react';
import ColumnImageRightImage from './images/columnImageRight.png';
import './styles.css';

const ColumnImageRight = () => {
  return (
    <Fragment>
      <img
        className="imageWrapper"
        src={ColumnImageRightImage}
        alt="Column Right"
      />
    </Fragment>
  );
};

export default ColumnImageRight;
