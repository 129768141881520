import React from 'react';

import './styles.css';

const css = `
.st0{fill:none;stroke:var(--primaryColor);stroke-miterlimit:10;}
.st1{fill:var(--primaryColor);stroke:var(--primaryColor);stroke-miterlimit:10;}
.st2{fill:var(--primaryColor)}
`;

const AppAnalytics = () => (
  <svg
    className="app-analytics-icon"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 46 44"
    xmlSpace="preserve"
  >
    <style type="text/css">{css}</style>
    <g>
      <g id="__id12_see9hapwhq">
        <path className="st0" d="M0.3,28.4l14.2-13l13.1,11.2l13.4-10.7" />
        <g id="__id13_see9hapwhq">
          <path
            className="st1"
            d="M17.4,15.2c0.1,1.6-1.2,3-2.8,3.1c-1.6,0.1-3-1.2-3.1-2.8c-0.1-1.6,1.2-3,2.8-3.1
               C16,12.4,17.4,13.6,17.4,15.2"
          />
        </g>
        <path
          className="st0"
          d="M17.4,15.2c0.1,1.6-1.2,3-2.8,3.1c-1.6,0.1-3-1.2-3.1-2.8c-0.1-1.6,1.2-3,2.8-3.1
           C16,12.4,17.4,13.6,17.4,15.2L17.4,15.2z"
        />
        <g id="__id14_see9hapwhq">
          <path
            className="st1"
            d="M30.8,26.5c0.1,1.6-1.2,3-2.8,3.1c-1.6,0.1-3-1.2-3.1-2.8s1.2-3,2.8-3.1C29.3,23.6,30.7,24.9,30.8,26.5"
          />
        </g>
        <path
          className="st0"
          d="M30.8,26.5c0.1,1.6-1.2,3-2.8,3.1c-1.6,0.1-3-1.2-3.1-2.8s1.2-3,2.8-3.1C29.3,23.6,30.7,24.9,30.8,26.5z"
        />
        <g id="__id15_see9hapwhq">
          <path className="st1" d="M38.2,13.7l7-1.3l-2.4,6.8L38.2,13.7z" />
        </g>
      </g>
      <g id="__id4_sz2voeanhi8">
        <path
          className="st2"
          d="M31,0H15c-2.8,0-5,2.2-5,5v34c0,2.8,2.2,5,5,5h16c2.8,0,5-2.2,5-5V5C36,2.2,33.8,0,31,0z M23,42
           c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S24.7,42,23,42z M32,34H14V6h18V34z"
        />
      </g>
    </g>
  </svg>
);

export default AppAnalytics;
