import { combineReducers } from 'redux';

import appName from './appName/reducer';
import appIcon from './appIcon/reducer';
import appSplashScreen from './appSplashScreen/reducer';
import customDomain from './customDomain/reducer';
import paypal from './paypalSetting/reducer';
import accessibility from './accessibility/reducer';
import privacy from './privacy/reducer';

export default combineReducers({
  appName,
  appIcon,
  appSplashScreen,
  customDomain,
  paypal,
  accessibility,
  privacy,
});
