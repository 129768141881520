import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import Modal from './Modal';

const EndUserLicense = ({ open, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      title={t("Beezer's End User License")}
      handleClose={handleClose}
    >
      <h3>
        <Trans>1. Definitions</Trans>:
      </h3>
      <table className="definitions">
        <tbody>
          <tr>
            <td>“Beezer”</td>
            <td>
              <Trans>
                means Beezer (PWA) LTD a company registered in Scotland under
                registration number SC492158 whose registered office is at 7
                Palmerston Place Lane, Edinburgh, Scotland, EH12 5AE
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>Content</Trans>”
            </td>
            <td>
              <Trans>
                means any text, graphics, images, audio, video, software, data
                compilations and any other form of information capable of being
                stored in a computer that appears on, is uploaded to or forms
                part of a Beezer App and has been uploaded by you;
              </Trans>
            </td>
          </tr>
        </tbody>
      </table>
      <h3>
        <Trans>
          2. When using a Beezer App you should do so in accordance with the
          following rules
        </Trans>
        :
      </h3>
      <ul>
        <li>
          <Trans>You must not use obscene or vulgar language;</Trans>
        </li>
        <li>
          <Trans>
            You must not submit Content that is unlawful or otherwise
            objectionable. This includes, but is not limited to, Content that is
            abusive, threatening, harassing, defamatory, ageist, sexist or
            racist;
          </Trans>
        </li>
        <li>
          <Trans>
            You must not submit Content that is intended to promote or incite
            violence;
          </Trans>
        </li>
        <li>
          <Trans>
            It is advised that submissions are made using the English language
            as we may be unable to respond to enquiries submitted in any other
            languages;
          </Trans>
        </li>
        <li>
          <Trans>
            The means by which you identify yourself must not violate these
            Terms and Conditions or any applicable laws;
          </Trans>
        </li>
        <li>
          <Trans>
            You must not impersonate other people, particularly employees and
            representatives of Beezer or their/our affiliates; and
          </Trans>
        </li>
        <li>
          <Trans>
            You must not use a Beezer App for unauthorised mass-communication
            such as “spam” or “junk mail”.
          </Trans>
        </li>
        <li>
          <Trans>
            You must not use Content which infringes the intellectual property
            rights of any third party including, but not limited to, copyright,
            trademarks, patents and designs;
          </Trans>
        </li>
        <li>
          <Trans>
            You must not make links to other websites containing any of the
            above types of material;
          </Trans>
        </li>
        <li>
          <Trans>
            You must not upload Content or any material that may contain viruses
            or other software or instructions that may damage or disrupt other
            software, computer hardware or communications networks;
          </Trans>
        </li>
        <li>
          <Trans>
            You must not use a Beezer App for the purpose of uploading files
            solely to have them hosted by Beezer; and
          </Trans>
        </li>
        <li>
          <Trans>
            You must not upload any Content that is adult in nature including,
            but not limited to, that which is pornographic or otherwise of a
            sexual nature.
          </Trans>
        </li>
      </ul>
      <h3>
        <Trans>3. Monitoring</Trans>
      </h3>
      <p>
        <Trans>
          You acknowledge that we and Beezer reserve the right to monitor any
          and all communications made by you whilst using a Beezer App.
        </Trans>
      </p>
      <h3>
        <Trans>4. Copies</Trans>
      </h3>
      <p>
        <Trans>
          You acknowledge that both we and Beezer may retain copies of any and
          all communications made whilst using a Beezer App.
        </Trans>
      </p>
      <h3>
        <Trans>5. Information</Trans>
      </h3>
      <p>
        <Trans>
          You acknowledge that any information you send to us through our System
          or post on the blogs/forums/chat or similar may be modified by us and
          Beezer in any way and you hereby waive your moral right to be
          identified as the author of such information. Any restrictions you may
          wish to place upon our use of such information must be communicated to
          us in advance and we reserve the right to reject such terms and
          associated information.
        </Trans>
      </p>
      <h3>
        <Trans>6. Privacy Policy</Trans>
      </h3>
      <p>
        <Trans>
          You will be subject to Beezer’s Privacy Policy which can be viewed
        </Trans>
        &nbsp;
        <a href="https://www.beezer.com/privacy-policy/">
          <Trans>here</Trans>
        </a>
        .
      </p>
    </Modal>
  );
};

EndUserLicense.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EndUserLicense;
