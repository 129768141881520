import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import moment from 'moment';
import SelectPlan from './SelectPlan';
import { enableFreeTrial, removeReferalFromAccount } from './actions';
import { updateContact } from '../auth/login/actions';
import { getAppSumoBilling } from '../appSumoBilling/actions';
import { getNextInvoiceAmount } from '../../../memberLogin/modules/upgradeDialog/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.account.subscribe,
  lastRedeemedCoupon: state.account.organisation.lastRedeemedCoupon,

  integrationType: _get(state, 'account.organisation.integration.type'),
  subscription:
    state.account.subscriptions.items[ownProps.match.params.appname],

  offers: state.account.register.offers,
  offerEligibility: state.account.organisation.offerEligibility,
  referralOfferEligibility: state.account.organisation.referralOfferEligibility,
  redeemedCoupons: state.account.organisation.redeemedCoupons,
  referrer: state.account.organisation.referrer,
  account: state.account.organisation.key,
  accountId: _get(state, 'account.auth.profile.account'),
  country:
    (state.account.auth.profile.ip_data &&
      state.account.auth.profile.ip_data.showDynamicPricing &&
      state.account.auth.profile.ip_data.country &&
      state.account.auth.profile.ip_data.country
        .replace(/\d+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_')) ||
    '',
  isAppSumoAccount:
    (state.account.organisation.appsumo_id &&
      state.account.organisation.appsumo_id.length > 0) ||
    false,
  appsumo22User:
    state.account.organisation && state.account.organisation.appsumo22,
  createdAt: state.account.organisation.created_at,
  appSumoBilling: state.account.appSumoBilling,
  nextInvoiceAmount:
    (state.auth.authUpgradeDialog &&
      state.auth.authUpgradeDialog.nextInvoiceAmount) ||
    0,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  enableFreeTrial: () => dispatch(enableFreeTrial()),
  onPlanUpgradePageViewed: account =>
    dispatch(
      updateContact({
        account,
        plan_upgrade_page_viewed: moment(new Date()).format(
          'DD/MM/YYYY hh:mm:ss',
        ),
      }),
    ),

  removeReferalFromAccount: () => dispatch(removeReferalFromAccount()),
  getAppSumoBilling: () => dispatch(getAppSumoBilling()),
  getNextInvoiceAmount: (accountId, subscriptionId) =>
    dispatch(getNextInvoiceAmount(accountId, subscriptionId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectPlan),
);
