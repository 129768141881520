import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';

import Sidebar, { SidebarItem } from '../../../../app/components/sidebar';
import Toggle from '../../../../app/components/toggle';
import { loginMenu, eventUrls, diaryUrl } from './menu';
import ViewApp from '../../../../app/components/buttons/viewApp/';
import WarningDialog from '../../../../app/components/warningDialog';
import './styles.css';

class MemberLoginMenu extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    toggleAuth: PropTypes.func.isRequired,
    getAuth: PropTypes.func.isRequired,
    stopAuth: PropTypes.func.isRequired,
    enabled: PropTypes.bool.isRequired,
    diaryEnabled: PropTypes.bool.isRequired,
    eventsEnabled: PropTypes.bool.isRequired,
    toggleDiaryEnabled: PropTypes.func.isRequired,
    toggleEventsEnabled: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    subscription: PropTypes.object.isRequired,
    hasEnabledAuth: PropTypes.bool.isRequired,
    getToggledAuth: PropTypes.func.isRequired,
    setToggledAuth: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    role: '',
    hasEnabledAuth: false,
  };

  state = {
    enabled: true,
  };

  componentWillMount() {
    this.props.onAddIsPublishedListener();
  }

  componentDidMount() {
    this.props.getAuth();
    this.props.loadDiyComponents();
    this.props.getToggledAuth();
  }

  componentWillUnmount() {
    this.props.stopAuth();
    this.props.onRemoveIsPublishedListener();
  }

  handleCloseModal = () => {
    const { setToggledAuth } = this.props;

    setToggledAuth();
    this.setState({ enabled: true });
  };

  handleAuthToggle = v => {
    const { hasEnabledAuth, toggleAuth } = this.props;
    if (v && !hasEnabledAuth) {
      this.setState({ enabled: false });
    }
    toggleAuth(v, null, 'MemberLogin');
  };

  render() {
    const {
      match,
      location,
      enabled,
      subscription,
      groups,
      toggleDiaryEnabled,
      diaryEnabled,
      toggleEventsEnabled,
      eventsEnabled,
      t,
    } = this.props;
    const { appname } = { ...match.params };
    const plan = (subscription && subscription.plan) || '';
    const links = loginMenu(appname, plan, groups);

    return (
      <div>
        <Sidebar animate>
          <ViewApp {...this.props} />
          <div className={`ml-switch-container ${enabled ? 'enabled' : ''}`}>
            <span className="ml-switch-label">
              <Trans>Enable Login</Trans>
            </span>
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => this.handleAuthToggle(b)}
              toggled={enabled}
            />
          </div>
          {links.map(path => {
            switch (path.label) {
              case 'Diary': {
                return (
                  <>
                    <div
                      className={`ml-switch-container ${
                        enabled ? 'enabled' : ''
                      }`}
                    >
                      <span className="ml-switch-label">
                        <Trans>Diary</Trans>
                      </span>
                      <Toggle
                        style={{ margin: '0' }}
                        onToggle={(v, b) =>
                          toggleDiaryEnabled(b, 'diaryEnabled')
                        }
                        toggled={diaryEnabled}
                        disabled={enabled ? false : true}
                      />
                    </div>
                    {diaryEnabled &&
                      diaryUrl.map(path => (
                        <SidebarItem
                          style={{ margin: '10' }}
                          key={path.label}
                          label={t(path.label)}
                          href={`/${appname}${path.href}`}
                          active={
                            path.flexibleRoute
                              ? location.pathname.indexOf(
                                  `/${appname}${path.href}`,
                                ) > -1
                              : location.pathname === `/${appname}${path.href}`
                          }
                          enabled={
                            path.href.indexOf('admins') > -1 ? true : enabled
                          }
                          icon={path.icon}
                        />
                      ))}
                  </>
                );
              }

              case 'Events': {
                return (
                  <div>
                    <div
                      className={`ml-switch-container ${
                        enabled ? 'enabled' : ''
                      }`}
                    >
                      <span className="ml-switch-label">
                        <Trans>Events & Courses</Trans>
                      </span>
                      <Toggle
                        style={{ margin: '0' }}
                        onToggle={(v, b) =>
                          toggleEventsEnabled(b, 'eventsEnabled')
                        }
                        toggled={eventsEnabled}
                        disabled={enabled ? false : true}
                      />
                    </div>
                    {eventsEnabled &&
                      eventUrls.map(path => (
                        <SidebarItem
                          style={{ margin: '10' }}
                          key={path.label}
                          label={t(path.label)}
                          href={`/${appname}${path.href}`}
                          active={
                            path.flexibleRoute
                              ? location.pathname.indexOf(
                                  `/${appname}${path.href}`,
                                ) > -1
                              : location.pathname === `/${appname}${path.href}`
                          }
                          enabled={
                            path.href.indexOf('admins') > -1 ? true : enabled
                          }
                          icon={path.icon}
                        />
                      ))}
                  </div>
                );
              }
              default: {
                return (
                  <SidebarItem
                    key={path.label}
                    label={t(path.label)}
                    href={`/${appname}${path.href}`}
                    active={
                      path.flexibleRoute
                        ? location.pathname.indexOf(`/${appname}${path.href}`) >
                          -1
                        : location.pathname === `/${appname}${path.href}`
                    }
                    enabled={path.href.indexOf('admins') > -1 ? true : enabled}
                    icon={path.icon}
                  />
                );
              }
            }
          })}
        </Sidebar>
        <WarningDialog
          open={!this.state.enabled}
          onRequestClose={this.handleCloseModal}
        />
      </div>
    );
  }
}

export default withTranslation()(MemberLoginMenu);
