import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';

const styles = {
  button: {
    overflow: 'hidden',
    height: 'auto',
    borderRadius: 3,
    marginTop: 12,
    marginBottom: 12,
    minWidth: '100%',
  },
  buttonContainer: {
    margin: '12px',
    height: '38px',
    width: 'calc(100% - 24px)',
  },
  buttonLabel: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '38px',
  },
};

const Flat = ({
  style,
  label,
  labelStyle,
  backgroundColor,
  hoverColor,
  onClick,
  disabled,
}) => (
  <FlatButton
    style={{ ...styles.button, ...style }}
    labelStyle={{ ...styles.buttonLabel, ...labelStyle }}
    backgroundColor={backgroundColor}
    hoverColor={hoverColor}
    label={label}
    onClick={onClick}
    disabled={disabled}
  />
);

Flat.propTypes = {
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  backgroundColor: PropTypes.string,
  hoverColor: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Flat.defaultProps = {
  style: {},
  labelStyle: {},
  icon: null,
  backgroundColor: 'rgba(246, 246, 246, 0.85)',
  hoverColor: 'rgba(196, 196, 196, 1)',
  disabled: false,
};

export default Flat;
