let deferred;

class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}

export const openDialog = ({
  title,
  text,
  buttonLabel = 'Submit',
  cancelLabel = 'Cancel',
}) => dispatch => {
  if (deferred instanceof Deferred) {
    // don't allow another dialog to open while a promise exists
    return null;
  }

  dispatch({
    type: 'OPEN_DIALOG',
    title,
    text,
    buttonLabel,
    cancelLabel,
  });

  deferred = new Deferred();

  return deferred.promise;
};

export const closeDialog = () => {
  // remove dialog promise
  if (deferred) {
    deferred.resolve({ action: 'cancel' });
  }

  return {
    type: 'CLOSE_DIALOG',
  };
};

export const reportDialogError = err => {
  // clean deferred
  deferred = undefined;

  return {
    type: 'REPORT_DIALOG_ERROR',
    err: err instanceof Error ? err.message : err,
  };
};

export const submitDialogAction = () => {
  // resolve dialog promise
  if (deferred) {
    deferred.resolve({ action: 'submit' });
  }

  return {
    type: 'SUBMIT_DIALOG_ACTION',
  };
};

export const dialogActionComplete = () => {
  // clean up
  deferred = undefined;

  return {
    type: 'DIALOG_ACTION_COMPLETE',
  };
};
