import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { components } from '../../../../plans';
import AuthUpgradeDialog from './AuthUpgradeDialog';

import { closeUpgradeDialog, getNextInvoiceAmount } from './actions';
import { addToDiyComponents, loadDiyComponents } from '../../../../app/actions';

import {
  closeCreateComponentUI,
  createComponent,
  componentTypeChange,
} from '../../../editor/modules/build/modules/create/createComponent/actions';

const mapStateToProps = (state, ownProps) => {
  const freeTrialPlan = state.account.organisation.trial_expired
    ? null
    : 'accelerate';
  const legacyFlag = state.account.organisation.legacy_flag || false;
  const isInTrial = !state.account.organisation.trial_expired || false;
  const subscriptionDetails =
    state.account.subscriptions.items[ownProps.match.params.appname] || {};
  const subscription =
    state.account.subscriptions.items[ownProps.match.params.appname] || {};
  const plan = (subscription && subscription.plan) || '';
  const isAppAdmin =
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false;
  const nextInvoiceAmount =
    (state.auth.authUpgradeDialog &&
      state.auth.authUpgradeDialog.nextInvoiceAmount) ||
    0;

  return {
    ...ownProps,
    ...state.auth.authUpgradeDialog,
    legacyFlag,
    isInTrial,
    isAppSumAccount: state.account.organisation.appsumo_id !== '',
    subscriptionDetails,
    plan,
    isAppAdmin,
    subscription: freeTrialPlan || state.app.appContext.subscription || 'start',
    accountId: state.account.auth.profile.account,
    diyComponents: state.app.addons.diyComponents || {},
    isProcessing: state.app.addons.isProcessing || false,
    subscriptionItem: state.account.subscriptions,
    intl: state.account.intl,
    appname: ownProps.match.params.appname,
    nextInvoiceAmount,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { appname } = ownProps.match.params;
  const query = queryString.parse(ownProps.location.search);
  const router = {
    history: ownProps.history,
    location: ownProps.location,
    match: ownProps.match,
  };
  return {
    onRequestClose: () => dispatch(closeUpgradeDialog()),
    getNextInvoiceAmount: (accountId, subscriptionId) =>
      dispatch(getNextInvoiceAmount(accountId, subscriptionId)),
    onLoad: () => {
      dispatch(loadDiyComponents(appname));
    },
    onSubmit: componentType =>
      dispatch(createComponent(appname, query, componentType, router)),
    onCancel: () => dispatch(closeCreateComponentUI()),
    onTypeChange: value => dispatch(componentTypeChange(value)),
    onConfirm: (componentType, currency) =>
      dispatch(
        addToDiyComponents(
          componentType,
          components[componentType],
          appname,
          currency,
        ),
      ).then(() => {
        dispatch({ type: 'TOGGLE_DIY_POPUP_STATE', value: false });
      }),
    toggleDiyPopup: value => {
      dispatch({ type: 'TOGGLE_DIY_POPUP_STATE', value });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthUpgradeDialog),
);
