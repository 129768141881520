import firebase from '../firebase';

export const sendSlackNotification = ({
  appname,
  notificationTitle,
  account,
  currentPlan,
  slackChannel,
}) => {
  const payload = {
    appname,
    notificationTitle,
    account,
    currentPlan: currentPlan || 'Free Trial',
    slackChannel,
  };

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      const options = {
        method: 'post',
        body: JSON.stringify(payload),
        headers,
        mode: 'cors',
      };
      return fetch(
        `${process.env.REACT_APP_PAYMENT_API}/send_slack_notification`,
        options,
      );
    });
};

export const USER_CLICKED_BUY_SMS = 'User clicked on Buy SMS button';
export const USER_CLICKED_UPGRADE = 'User clicked on Upgrade button';
export const PLATFORM_EVENTS = (() => {
  const env = process.env.REACT_APP_PLATFORM_ENV;

  if (env === 'production') {
    return 'platform_events';
  } else if (env) {
    return `platform_events_${env}`;
  }
  return 'platform_events_testing';
})();

export const USER_DOWNLOADED_CSV = (number, type) =>
  `User downloaded ${number} ${type} records to csv`;
export const USER_DELETED_RECORDS = (number, type) =>
  `User deleted ${number} ${type} records`;
export const USER_AUTO_ADD_NOTIFICATION_COMPONENT =
  'User clicked the auto add notification component in push notification section';
export const ENGAGE_EVENTS = (() => {
  const env = process.env.REACT_APP_PLATFORM_ENV;

  if (env === 'production') {
    return 'engage_events';
  } else if (env) {
    return `engage_events_${env}`;
  }
  return 'engage_events_testing';
})();
