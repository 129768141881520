import React, { useEffect, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Trans, useTranslation } from 'react-i18next';

const AnalyticsData = ({
  selectedUsers,
  getPageCountAnalytics,
  pageAnalyticsDataPending,
  pageAnalyticsData,
}) => {
  const { t } = useTranslation();
  const [anaylyticsDataExpanded, setAnaylyticsDataExpanded] = useState(false);

  const handleAccordionOpen = panel => (event, isExpanded) => {
    setAnaylyticsDataExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getPageCountAnalytics(Object.keys(selectedUsers)[0]);
  }, []);

  return (
    <div>
      <Accordion
        expanded={anaylyticsDataExpanded === 'panel1'}
        onChange={handleAccordionOpen('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            <Trans>User Analytics</Trans>
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            <Trans>App Pages Visited</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {pageAnalyticsData && Object.keys(pageAnalyticsData).length > 0 ? (
            <TableContainer component={Paper}>
              <Table
                aria-label="analytics page table"
                style={{ border: '1px solid black' }}
              >
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: 'black',
                    }}
                  >
                    <TableCell align="left" style={{ color: 'white' }}>
                      <Trans>Page Name</Trans>
                    </TableCell>
                    <TableCell align="center" style={{ color: 'white' }}>
                      <Trans>Number of visits</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(pageAnalyticsData).map(pageName => (
                    <>
                      <TableRow
                        key={pageName}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell align="left">
                          {decodeURIComponent(pageName)}
                        </TableCell>
                        <TableCell align="center">
                          {pageAnalyticsData[pageName]}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>
              <Trans>No data collected for this user.</Trans>
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AnalyticsData;
