import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chip from 'material-ui/Chip';
import Toggle from 'material-ui/Toggle';
import { withTranslation } from 'react-i18next';

import TextField from '../../../../../../../app/components/textField';
import DesignToggle from '../../../../../components/designToggle';
import ButtonDesignMenu from '../../../../../components/buttonDesignMenu';
import Container from '../../../../../../../app/components/container';
import { FlatButton } from '../../../../../../../app/components/buttons';
import { Tabs, Tab } from '../../../../../components/tabs';
import { ProgressOverlay } from '../../../../../../../app/components/progress';
import themeStyles from '../../../../../../../_export.scss';
import './styles.css';

class File extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    onReceivePdf: PropTypes.func.isRequired,
    onDesignToggle: PropTypes.func.isRequired,
    onButtonDesignChange: PropTypes.func.isRequired,
    pending: PropTypes.bool,
  };

  static defaultProps = {
    pending: false,
  };

  fileChange = event => {
    const { target } = event;
    if (target && target.files) {
      this.props.onReceivePdf(target.files[0], this.props.component.lazy_data);
    }
  };

  openFileSelector = () => this.fileSelector.click();

  render() {
    const { component, data, handleChange, pending, t } = this.props;

    let fileControls;
    if (data.url) {
      const filename = data.url.substr(data.url.lastIndexOf('/') + 1);
      fileControls = (
        <Chip
          style={{ margin: 6 }}
          labelStyle={{ maxWidth: '300px', overflow: 'hidden' }}
          onRequestDelete={() => handleChange('url', '')}
          title={filename}
        >
          <a href={data.url}>{filename}</a>
        </Chip>
      );
    } else {
      fileControls = (
        <div>
          <FlatButton
            label={t('Select File')}
            onClick={this.openFileSelector}
            disabled={pending}
          />
          {pending ? <ProgressOverlay /> : null}
        </div>
      );
    }

    return (
      <Tabs>
        <Tab label={t('content')}>
          <Container>
            <TextField
              id="pdfTitle"
              placeholder={t('Text to display')}
              value={data.title}
              onChange={value => handleChange('title', value)}
            />
            <input
              type="file"
              className="hidden-input"
              ref={node => {
                this.fileSelector = node;
              }}
              onChange={this.fileChange}
            />
            {fileControls}
            <Toggle
              label={t('Show Preview in App')}
              style={{
                marginTop: 24,
                marginBottom: 12,
                paddingLeft: 18,
                paddingRight: 18,
                paddingTop: 6,
              }}
              labelStyle={{ fontSize: 14 }}
              toggled={data.showPreview}
              onToggle={(e, enabled) => handleChange('showPreview', enabled)}
              trackSwitchedStyle={{
                backgroundColor: themeStyles.primaryColorLight,
              }}
              thumbSwitchedStyle={{ backgroundColor: themeStyles.primaryColor }}
            />
            <Toggle
              label={t('Allow File Download')}
              style={{
                marginTop: 24,
                marginBottom: 12,
                paddingLeft: 18,
                paddingRight: 18,
                paddingTop: 6,
              }}
              labelStyle={{ fontSize: 14 }}
              toggled={data.allowDownload}
              onToggle={(e, enabled) => handleChange('allowDownload', enabled)}
              trackSwitchedStyle={{
                backgroundColor: themeStyles.primaryColorLight,
              }}
              thumbSwitchedStyle={{ backgroundColor: themeStyles.primaryColor }}
            />
          </Container>
        </Tab>
        <Tab label={t('design')}>
          <Container>
            <DesignToggle
              component={component}
              onDesignChange={(e, isToggled) =>
                this.props.onDesignToggle(isToggled)
              }
            />
            <ButtonDesignMenu
              component={component}
              onDesignChange={this.props.onButtonDesignChange}
            />
          </Container>
        </Tab>
        <Tab
          label={t('layout')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    );
  }
}

export default withTranslation()(File);
