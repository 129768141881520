import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RaisedButton } from '../../../../../app/components/buttons';
import themeStyles from '../../../../../_export.scss';
const styles = {
  PrimaryLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.primaryTextColor,
  },
  buttonRoot: {
    margin: '12px',
    width: '70px',
    minWidth: '70px',
  },
};

const ReEnableFreeTrial = ({
  offer,
  history,
  match,
  billingPeriodToggle,
  handleCardTap,
}) => {
  const {
    price,
    plan,
    is_yearly_disabled: isYearlyDisabled,
    initial_price_to_pay: initialPriceToPay,
    content,
  } = offer;

  const onhandleCardTap = () => {
    handleCardTap();
  };

  return (
    <div className="plan-card-holder">
      <div className={`plan-card `}>
        <img
          height={'80px'}
          width={'auto'}
          id="offerImage"
          src={content.imageSmall}
          alt="Offer Plan Icon"
          style={{ position: 'absolute', top: '-83px' }}
        />
        <span className="plan-title">Offer</span>
        <div className="separator" />
        <h3 className="flex-column align-center">
          <strong className="plan-price">FREE</strong>
        </h3>

        <span className="plan-desc">
          <p
            className="plan-description"
            dangerouslySetInnerHTML={{
              __html: content.planDescription || content.description,
            }}
          />
        </span>
      </div>

      <RaisedButton
        label="Go!"
        backgroundColor={themeStyles.primaryColor}
        disabledBackgroundColor={themeStyles.primaryColorLight}
        style={styles.buttonRoot}
        labelStyle={styles.PrimaryLabelStyle}
        onClick={() => onhandleCardTap()}
      />
    </div>
  );
};

ReEnableFreeTrial.propTypes = {
  offer: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  billingPeriodToggle: PropTypes.bool.isRequired,
};

export default withRouter(ReEnableFreeTrial);
