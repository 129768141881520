import React from 'react';
import PropTypes from 'prop-types';

import { Delete } from '../icons';

const styles = {
  error: {
    marginTop: '20px',
    fontSize: '80%',
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontStyle: 'italic',
  },
  icon: {
    color: 'red',
    marginRight: '10px',
  },
};

const DeleteError = ({ text, number }) => (
  <span style={styles.error}>
    <Delete iconStyle={styles.icon} />
    <span>
      Hold on - The minimum number of {text} is {number}
    </span>
  </span>
);

DeleteError.propTypes = {
  text: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
};

export default DeleteError;
