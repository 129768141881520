import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Slider from './Slider';
import { addSliderItem, rearrangeItems, designChange } from './actions';
import { launchRemoveDialog } from '../../remove/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  appname: ownProps.match.params.appname,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddItem: title =>
    dispatch(
      addSliderItem(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        title,
      ),
    ),
  onRemoveItem: item =>
    dispatch(
      launchRemoveDialog(
        ownProps.match.params.appname,
        {
          ...queryString.parse(ownProps.location.search),
          item,
        },
        'ITEM',
      ),
    ),
  rearrangeItems: (id, siblingId) =>
    dispatch(
      rearrangeItems(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        id,
        siblingId,
      ),
    ),
  onButtonDesignChange: (type, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        `buttonDesign/${type}`,
        value,
      ),
    ),
  onDesignChange: (type, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        type,
        value,
      ),
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Slider));
