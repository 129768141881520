import {
  EDIT_ADMIN_FIELD_CHANGE,
  CLOSE_EDIT_ADMIN_DIALOG,
  REGISTRATION_ERROR,
  LOADING_ADMIN,
  LOADED_ADMIN_DETAILS,
  ADMIN_DETAILS_UPDATE_REQUEST,
  ADMIN_DETAILS_UPDATED,
  UPDATE_ADMIN_SECTIONS_ACCESS,
} from './actions';

const initialState = {
  pending: true,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  firstName: {
    value: '',
    isValid: null,
  },
  lastName: {
    value: '',
    isValid: null,
  },
  email: {
    value: '',
    isValid: null,
  },
  adminAppname: {
    value: '',
    isValid: null,
  },
  adminSMSCredits: {
    value: 0,
    isValid: null,
  },
  adminWhatsappSMSCredits: {
    value: 0,
    isValid: null,
  },
  creditsToAdd: {
    value: 0,
    isValid: null,
  },
  whatsappCreditsToAdd: {
    value: 0,
    isValid: null,
  },
  sectionsAccess: {
    build: true,
    distribute: true,
    engage: true,
    analytics: true,
    member_login: true,
    business_tools: true,
  },
};

const editAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_EDIT_ADMIN_DIALOG:
      return initialState;

    case EDIT_ADMIN_FIELD_CHANGE:
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };

    case REGISTRATION_ERROR:
      return {
        ...state,
        error: action.err,
        pending: false,
      };

    case LOADING_ADMIN:
      return {
        ...state,
        pending: true,
      };

    case ADMIN_DETAILS_UPDATE_REQUEST:
      return {
        ...state,
        pending: true,
      };

    case LOADED_ADMIN_DETAILS:
      return {
        ...state,
        pending: false,
        ...action.prop,
      };

    case ADMIN_DETAILS_UPDATED:
      return {
        ...state,
        key: action.admin,
        data: action.update,
      };

    case UPDATE_ADMIN_SECTIONS_ACCESS:
      return {
        ...state,
        sectionsAccess: {
          ...state.sectionsAccess,
          [action.field]: action.value,
        },
      };

    default:
      return state;
  }
};

export default editAdminReducer;
