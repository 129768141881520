import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import Toggle from 'material-ui/Toggle';
import { RadioButtonGroup, RadioButton } from 'material-ui/RadioButton';

import Container from '../../../../../app/components/container';
import { RadioMenu, RadioItem } from '../../../../../app/components/radioMenu';
import { Tabs, Tab } from '../../../components/tabs';
import ColorPicker from '../../../components/colorPicker';
import SectionHeader from '../../../components/sectionHeader';
import themeStyles from '../../../../../_export.scss';

class AppMenuBar extends Component {
  static propTypes = {
    Appbar: PropTypes.shape({
      backgroundColor: PropTypes.string,
      itemColor: PropTypes.string,
    }),
    TopRightMenu: PropTypes.shape({
      backgroundColor: PropTypes.string,
      itemColor: PropTypes.string,
    }),
    Topbar: PropTypes.shape({
      backgroundColor: PropTypes.string,
      textColor: PropTypes.string,
    }),
    layout: PropTypes.string.isRequired,
    textAlign: PropTypes.string,
    skin: PropTypes.string.isRequired,
    onPropChange: PropTypes.func.isRequired,
    onLayoutChange: PropTypes.func.isRequired,
    showShareButton: PropTypes.bool,
    onToggleShowShareMenu: PropTypes.func.isRequired,
    onChangeTextAlign: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    Appbar: {
      backgroundColor: '',
      itemColor: '',
    },
    TopRightMenu: {
      backgroundColor: '',
      itemColor: '',
    },
    Topbar: {
      backgroundColor: '',
      textolor: '',
    },
    showShareButton: true,
    textAlign: 'left',
  };

  shouldShowTopbar = () => {
    const { Topbar, skin, onPropChange, t } = this.props;
    return (
      skin !== 'integration' && (
        <section style={{ width: '100%' }}>
          <SectionHeader
            title={t('Top Bar Style')}
            tooltip={t("Change the style your app's top bar")}
          />
          <ColorPicker
            label={t('Background Color')}
            color={Topbar.backgroundColor}
            height={22}
            onChange={onPropChange('backgroundColor', 'Topbar')}
          />
          <ColorPicker
            label={t('Title Color')}
            color={Topbar.textColor}
            height={22}
            onChange={onPropChange('textColor', 'Topbar')}
          />
          {this.renderShareButtonToggle()}
        </section>
      )
    );
  };

  designTab = (Appbar, TopRightMenu, onPropChange, skin) => {
    const { t } = this.props;
    return (
      <Container>
        {this.shouldShowTopbar()}
        <SectionHeader
          title={t('Navigation Bar Style')}
          tooltip={t(
            "Change the background and text colors of your app's menu bar",
          )}
        />

        <ColorPicker
          label={t('Background Color')}
          color={Appbar.backgroundColor}
          height={22}
          onChange={onPropChange('backgroundColor', 'Appbar')}
        />
        <ColorPicker
          label={t('Link Color')}
          color={Appbar.itemColor}
          height={22}
          onChange={onPropChange('itemColor', 'Appbar')}
        />
        <SectionHeader
          title={t('Top Right Menu Navigation Bar Style')}
          tooltip={t(
            'Change the background and text colors of the top right menu navigation',
          )}
        />
        <ColorPicker
          label={t('Background Color')}
          color={TopRightMenu.backgroundColor || 'rgba(255, 255, 255, 1)'}
          height={22}
          onChange={onPropChange('backgroundColor', 'TopRightMenu')}
        />
        <ColorPicker
          label={t('Link Color')}
          color={TopRightMenu.itemColor || 'rgba(0, 0, 0, 1)'}
          height={22}
          onChange={onPropChange('itemColor', 'TopRightMenu')}
        />
        {skin === 'integration' && this.renderShareButtonToggle()}
      </Container>
    );
  };

  layoutTab = (layout, onLayoutChange, onChangeTextAlign, textAlign) => {
    const { t } = this.props;
    return (
      <>
        <div style={{ paddingTop: 24 }}>
          <RadioMenu selected={layout} onChange={onLayoutChange}>
            <RadioItem
              label={t('Drawer Menu')}
              value="Drawer"
              image={require('./ice.png')}
            />
            <RadioItem
              label={t('Bottom Tabs')}
              value="BottomTabsResponsive"
              image={require('./frankfurter.png')}
            />
          </RadioMenu>
        </div>
        {layout === 'Drawer' && (
          <>
            <SectionHeader
              title={t('Text Alignment')}
              tooltip={t('Select alignment for page titles in the menu.')}
            />
            <RadioButtonGroup
              className="radio-button-group-splash"
              label={t('Text Alignment')}
              name="text-align"
              onChange={(el, val) => onChangeTextAlign(val)}
              valueSelected={textAlign}
            >
              <RadioButton
                key="left"
                value="left"
                label={t('Left Align')}
                style={{ width: 'auto' }}
              />
              <RadioButton
                key="center"
                value="center"
                label={t('Center Align')}
                style={{ width: 'auto' }}
              />
            </RadioButtonGroup>
          </>
        )}
      </>
    );
  };

  renderShareButtonToggle = () => {
    const { showShareButton, onToggleShowShareMenu, t } = this.props;

    return (
      <Toggle
        className="design-color"
        labelStyle={{
          fontWeight: 400,
          fontSize: '12px',
          textTransform: 'uppercase',
        }}
        onToggle={(e, isToggled) => onToggleShowShareMenu(isToggled)}
        label={t('Show Share Button')}
        toggled={showShareButton}
        trackSwitchedStyle={{ backgroundColor: themeStyles.primaryColorLight }}
        thumbSwitchedStyle={{ backgroundColor: themeStyles.primaryColor }}
      />
    );
  };

  render() {
    const {
      Appbar,
      TopRightMenu,
      skin,
      layout,
      textAlign,
      onLayoutChange,
      onPropChange,
      onChangeTextAlign,
      t,
    } = this.props;

    return (
      <div>
        {skin !== 'integration' ? (
          <Tabs initialSelectedIndex={1}>
            <Tab label={t('design')}>
              {this.designTab(Appbar, TopRightMenu, onPropChange, skin)}
            </Tab>
            <Tab
              label={t('layout')}
              style={
                skin === 'integration'
                  ? {
                      color: '#d9d9d9',
                    }
                  : {}
              }
              disabled={skin === 'integration'}
            >
              <SectionHeader
                title={t('Menu Layout')}
                tooltip={t(
                  'Select your preferred style of navigation menu from the options below.',
                )}
              />
              {this.layoutTab(
                layout,
                onLayoutChange,
                onChangeTextAlign,
                textAlign,
              )}
            </Tab>
          </Tabs>
        ) : (
          this.designTab(Appbar, TopRightMenu, onPropChange, skin)
        )}
      </div>
    );
  }
}

export default withTranslation()(AppMenuBar);
