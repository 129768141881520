import React from 'react';
import PropTypes from 'prop-types';
import InfoOutline from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import themeStyles from '../../../../_export.scss';
import { Trans, useTranslation } from 'react-i18next';

import Sidebar, { SidebarItem } from '../../../../app/components/sidebar';
import {
  AppAnalytics as AppAnalyticsIcon,
  Form as FormIcon,
  Vote as VoteIcon,
  Rating as RatingIcon,
  PaymentHistory as PaymentHistoryIcon,
} from '../../components/icons';

import ViewApp from '../../../../app/components/buttons/viewApp';

const AnalyticsMenu = ({
  match,
  location,
  integration,
  isPublished,
  isAppAdmin,
  isSubAdminWithMulipleApps,
}) => {
  const { t } = useTranslation();
  const baseHref = `/${match.params.appname}/analytics`;
  const activeCheck = prefix =>
    location.pathname === `/${match.params.appname}/analytics/${prefix}`;
  const openLink = link => {
    window.open(link, '_blank');
  };

  const infoIcon = pdf => (
    <Tooltip
      title={t('How to use')}
      onClick={() => openLink(pdf)}
      style={{ color: themeStyles.primaryColor }}
    >
      <InfoOutline fontSize="small" />
    </Tooltip>
  );

  return (
    <Sidebar animate>
      <ViewApp isPublished={isPublished} match={match} />
      <SidebarItem
        label={t('App Analytics')}
        href={`${baseHref}/app`}
        active={activeCheck('app')}
        icon={<AppAnalyticsIcon />}
      />
      {!integration && (
        <SidebarItem
          label={t('Form Data')}
          href={`${baseHref}/form`}
          active={activeCheck('form')}
          icon={<FormIcon />}
          {...((isAppAdmin || isSubAdminWithMulipleApps) && {
            rightAction: infoIcon(
              'https://kb-articles.s3.eu-west-2.amazonaws.com/Form+Data.pdf',
            ),
          })}
          {...((isAppAdmin || isSubAdminWithMulipleApps) && {
            displayRightAction: true,
          })}
        />
      )}
      {!integration && (
        <SidebarItem
          label={t('Vote Data')}
          href={`${baseHref}/vote`}
          active={activeCheck('vote')}
          icon={<VoteIcon />}
        />
      )}
      {!integration && (
        <SidebarItem
          label={t('Rating Data')}
          href={`${baseHref}/rating`}
          active={activeCheck('rating')}
          icon={<RatingIcon />}
        />
      )}
      {!integration && (
        <SidebarItem
          label={t('Paypal Payments')}
          href={`${baseHref}/payment`}
          active={activeCheck('payment')}
          icon={<PaymentHistoryIcon />}
        />
      )}
    </Sidebar>
  );
};

AnalyticsMenu.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  integration: PropTypes.object,
  isAppAdmin: PropTypes.bool.isRequired,
  isSubAdminWithMulipleApps: PropTypes.bool.isRequired,
};

AnalyticsMenu.defaultProps = {
  integration: null,
};

export default AnalyticsMenu;
