import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FlatButton } from '../../../../app/components/buttons';

const actions = (action, onClose, pending) => [
    <FlatButton
        style={{ margin: 20 }}
        label="Cancel"
        secondaryWhite
        disabled={pending}
        onClick={() => onClose()}
    />,
    <FlatButton
        style={{ margin: 20 }}
        label="Confirm"
        primary
        onClick={() => action()}
        disabled={pending}
    />,
];

const DialogConfirmation = ({ open, onClose, message, action, pending }) => {
    return (
        <Dialog
            actions={
                action !== null ? actions(action, onClose, pending) : [actions(action, onClose, pending)[0]]
            }
            open={open}
            onRequestClose={() => onClose()}
            style={{ textAlign: 'center' }}
            actionsContainerStyle={{ textAlign: 'center' }}
        >
            {!pending && <p>{message}</p>}
            {pending && <CircularProgress />}
        </Dialog>
    );
};

DialogConfirmation.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    action: PropTypes.func,
    pending: PropTypes.bool.isRequired,
};

DialogConfirmation.defaultProps = {
    action: null,
};

export default DialogConfirmation;
