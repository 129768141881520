import { accelerate, launch, start, reseller } from './images';
import {
  startComponents,
  somethingComponents,
  launchComponents,
  diyComponents,
  accelerateComponents,
} from './planComponents';
import {
  START,
  LAUNCH,
  ACCELERATE,
  ACCELERATE_YEARLY,
  EXPERT,
  DIY,
  DIY_YEARLY,
  DELUX,
  DELUX_YEARLY,
  EXPERT_NEW,
  SOMETHING,
  SOMETHING_YEARLY,
  EXPERT_5,
} from './constants';

import { plans as appSumoPlans } from './plansAppSumo';

const plans = {
  ...appSumoPlans,
  [SOMETHING]: {
    yearly_product_id: 'prod_PECNtaq1Fzm3ba',
    monthly_product_id: 'prod_PECMtS8LKmsylc',
    title: 'Something',
    icon: start,
    components: somethingComponents,
    description:
      'Build your first app using a combination of interactive features, distribute it to your audience and engage them with Push notifications.',
    price: 14.99,
    year_price: 13.49,
    buttonTitle: 'CHOOSE PLAN',
    grayOut: false,
    united_states: {
      price: 19,
      year_price: 17.1,
      format: 'USD',
      currency: 'USD',
    },
    usd: {
      price: 19,
      year_price: 17.1,
    },
    austria: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    belgium: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    bulgaria: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    croatia: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    cyprus: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    czechia: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    denmark: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    estonia: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    finland: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    france: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    germany: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    greece: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    hungary: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    ireland: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    italy: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    latvia: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    lithuania: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    luxembourg: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    malta: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    netherlands: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    poland: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    portugal: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    romania: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    slovakia: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    slovenia: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    spain: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    sweden: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    eur: {
      price: 17.5,
      year_price: 15.61,
    },
    australia: {
      price: 28,
      year_price: 25.2,
      format: 'AUD',
      currency: 'AUD',
    },
    aud: {
      price: 28,
      year_price: 25.2,
    },
    new_zealand: {
      price: 30,
      year_price: 27,
      format: 'NZD',
      currency: 'NZD',
    },
    nzd: {
      price: 30,
      year_price: 27,
    },
    canada: {
      price: 25.5,
      year_price: 23,
      format: 'CAD',
      currency: 'CAD',
    },
    cad: {
      price: 25.5,
      year_price: 23,
    },
    south_africa: {
      price: 384,
      year_price: 345.6,
      format: 'ZAR',
      currency: 'ZAR',
    },
    zar: {
      price: 384,
      year_price: 345.6,
    },
  },

  [SOMETHING_YEARLY]: {
    yearly_product_id: 'prod_PECNtaq1Fzm3ba',
    monthly_product_id: 'prod_PECMtS8LKmsylc',
    title: 'Something',
    hide: true,
    icon: start,
    components: somethingComponents,
    description:
      'Build your first app using a combination of interactive features, distribute it to your audience and engage them with Push notifications.',
    price: 14.99,
    year_price: 13.49,
    buttonTitle: 'CHOOSE PLAN',
    grayOut: false,
    united_states: {
      price: 19,
      year_price: 17.1,
      format: 'USD',
      currency: 'USD',
    },
    usd: {
      price: 19,
      year_price: 17.1,
    },
    austria: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    belgium: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    bulgaria: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    croatia: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    cyprus: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    czechia: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    denmark: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    estonia: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    finland: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    france: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    germany: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    greece: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    hungary: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    ireland: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    italy: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    latvia: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    lithuania: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    luxembourg: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    malta: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    netherlands: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    poland: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    portugal: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    romania: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    slovakia: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    slovenia: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    spain: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    sweden: {
      price: 17.5,
      year_price: 15.61,
      format: 'EUR',
      currency: 'EUR',
    },
    eur: {
      price: 17.5,
      year_price: 15.61,
    },
    australia: {
      price: 28,
      year_price: 25.2,
      format: 'AUD',
      currency: 'AUD',
    },
    aud: {
      price: 28,
      year_price: 25.2,
    },
    new_zealand: {
      price: 30,
      year_price: 27,
      format: 'NZD',
      currency: 'NZD',
    },
    nzd: {
      price: 30,
      year_price: 27,
    },
    canada: {
      price: 25.5,
      year_price: 23,
      format: 'CAD',
      currency: 'CAD',
    },
    cad: {
      price: 25.5,
      year_price: 23,
    },
    south_africa: {
      price: 384,
      year_price: 345.6,
      format: 'ZAR',
      currency: 'ZAR',
    },
    zar: {
      price: 384,
      year_price: 345.6,
    },
  },

  [START]: {
    title: 'Start',
    icon: start,
    components: startComponents,
    buttonTitle: 'CHOOSE PLAN',
    grayOut: false,
  },

  [LAUNCH]: {
    title: 'Launch',
    icon: launch,
    components: launchComponents,
    description:
      'Perfect for businesses looking to build customer relationships and engage their audience.',
    price: 9.99,
    year_price: 7.99,
    buttonTitle: 'CHOOSE PLAN',
    grayOut: false,
    hide: true,
  },

  [DIY]: {
    yearly_product_id: 'prod_PECOLnpkqdDXt1',
    monthly_product_id: 'prod_PECNWi8Pva4GhX',
    title: 'Anything',
    icon: launch,
    components: diyComponents,
    description:
      'Perfect for all you bold self-starters who know what you want. Customise a perfectly tailored app for you or your business.',
    price: 24.99,
    year_price: 22.49,
    buttonTitle: 'CHOOSE PLAN',
    grayOut: false,
    united_states: {
      price: 32,
      year_price: 28.8,
      format: 'USD',
      currency: 'USD',
    },
    usd: {
      price: 32,
      year_price: 28.8,
    },
    austria: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    belgium: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    bulgaria: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    croatia: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    cyprus: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    czechia: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    denmark: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    estonia: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    finland: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    france: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    germany: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    greece: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    hungary: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    ireland: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    italy: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    latvia: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    lithuania: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    luxembourg: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    malta: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    netherlands: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    poland: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    portugal: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    romania: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    slovakia: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    slovenia: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    spain: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    sweden: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    eur: {
      price: 29,
      year_price: 26.02,
    },
    australia: {
      price: 47,
      year_price: 42.3,
      format: 'AUD',
      currency: 'AUD',
    },
    aud: {
      price: 47,
      year_price: 42.3,
    },
    new_zealand: {
      price: 50,
      year_price: 45,
      format: 'NZD',
      currency: 'NZD',
    },
    nzd: {
      price: 50,
      year_price: 45,
    },
    canada: {
      price: 42.5,
      year_price: 38.25,
      format: 'CAD',
      currency: 'CAD',
    },
    cad: {
      price: 42.5,
      year_price: 38.25,
    },
    south_africa: {
      price: 581,
      year_price: 522.9,
      format: 'ZAR',
      currency: 'ZAR',
    },
    zar: {
      price: 581,
      year_price: 522.9,
    },
  },

  [DIY_YEARLY]: {
    yearly_product_id: 'prod_PECOLnpkqdDXt1',
    monthly_product_id: 'prod_PECNWi8Pva4GhX',
    title: 'Anything',
    hide: true,
    icon: launch,
    components: diyComponents,
    description:
      'Perfect for all you bold self-starters who know what you want. Customise a perfectly tailored app for you or your business.',
    price: 24.99,
    year_price: 22.49,
    buttonTitle: 'CHOOSE PLAN',
    grayOut: false,
    united_states: {
      price: 32,
      year_price: 28.8,
      format: 'USD',
      currency: 'USD',
    },
    usd: {
      price: 32,
      year_price: 28.8,
    },
    austria: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    belgium: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    bulgaria: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    croatia: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    cyprus: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    czechia: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    denmark: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    estonia: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    finland: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    france: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    germany: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    greece: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    hungary: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    ireland: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    italy: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    latvia: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    lithuania: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    luxembourg: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    malta: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    netherlands: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    poland: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    portugal: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    romania: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    slovakia: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    slovenia: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    spain: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    sweden: {
      price: 29,
      year_price: 26.02,
      format: 'EUR',
      currency: 'EUR',
    },
    eur: {
      price: 29,
      year_price: 26.02,
    },
    australia: {
      price: 47,
      year_price: 42.3,
      format: 'AUD',
      currency: 'AUD',
    },
    aud: {
      price: 47,
      year_price: 42.3,
    },
    new_zealand: {
      price: 50,
      year_price: 45,
      format: 'NZD',
      currency: 'NZD',
    },
    nzd: {
      price: 50,
      year_price: 45,
    },
    canada: {
      price: 42.5,
      year_price: 38.25,
      format: 'CAD',
      currency: 'CAD',
    },
    cad: {
      price: 42.5,
      year_price: 38.25,
    },
    south_africa: {
      price: 581,
      year_price: 522.9,
      format: 'ZAR',
      currency: 'ZAR',
    },
    zar: {
      price: 581,
      year_price: 522.9,
    },
  },

  [ACCELERATE]: {
    title: 'Accelerate',
    icon: accelerate,
    components: accelerateComponents,
    description:
      'Ideal for businesses looking to drive additional revenue from a mobile app.',
    price: 19.99,
    year_price: 15.99,
    buttonTitle: 'CHOOSE PLAN',
    grayOut: false,
    hide: true,
  },

  [ACCELERATE_YEARLY]: {
    title: 'Accelerate',
    icon: accelerate,
    components: accelerateComponents,
    description:
      'Ideal for businesses looking to drive additional revenue from a mobile app.',
    price: 19.99,
    year_price: 15.99,
    buttonTitle: 'CHOOSE PLAN',
    grayOut: false,
    hide: true,
  },

  [DELUX]: {
    monthly_product_id: 'prod_PECO05gHag3hfY',
    yearly_product_id: 'prod_PECPZPYMrKpvAO',
    title: 'Everything',
    icon: accelerate,
    components: accelerateComponents,
    description:
      'Why pick and choose features when you can have them all? Help your business increase revenue with a kickass mobile app – bells & whistles included.',
    price: 65,
    year_price: 58.5,
    buttonTitle: 'CHOOSE PLAN',
    grayOut: false,
    united_states: {
      price: 82,
      year_price: 74,
      format: 'USD',
      currency: 'USD',
    },
    usd: {
      price: 82,
      year_price: 74,
    },
    austria: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    belgium: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    bulgaria: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    croatia: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    cyprus: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    czechia: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    denmark: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    estonia: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    finland: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    france: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    germany: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    greece: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    hungary: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    ireland: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    italy: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    latvia: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    lithuania: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    luxembourg: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    malta: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    netherlands: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    poland: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    portugal: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    romania: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    slovakia: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    slovenia: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    spain: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    sweden: {
      price: 75,
      year_price: 67.5,
      format: 'EUR',
      currency: 'EUR',
    },
    eur: {
      price: 75,
      year_price: 67.5,
    },
    australia: {
      price: 122,
      year_price: 110,
      format: 'AUD',
      currency: 'AUD',
    },
    aud: {
      price: 122,
      year_price: 110,
    },
    new_zealand: {
      price: 131,
      year_price: 118,
      format: 'NZD',
      currency: 'NZD',
    },
    nzd: {
      price: 131,
      year_price: 118,
    },
    canada: {
      price: 110,
      year_price: 99,
      format: 'CAD',
      currency: 'CAD',
    },
    cad: {
      price: 110,
      year_price: 99,
    },
    south_africa: {
      price: 1509,
      year_price: 1358,
      format: 'ZAR',
      currency: 'ZAR',
    },
    zar: {
      price: 1509,
      year_price: 1358,
    },
  },

  [DELUX_YEARLY]: {
    title: 'Everything',
    icon: accelerate,
    components: accelerateComponents,
    description:
      'Why pick and choose features when you can have them all? Help your business increase revenue with a kickass mobile app – bells & whistles included.',
    price: 58.5,
    year_price: 702,
    buttonTitle: 'CHOOSE PLAN',
    grayOut: false,
    hide: true,
  },

  [EXPERT]: {
    title: 'Reseller',
    icon: reseller,
    components: accelerateComponents,
    description:
      'If your company is looking to enhance its product portfolio this is perfect tool that helps you to offer your customers stunning mobile apps',
    price: 350,
    year_price: 325,
    old_price: 120,
    old_year_price: 96,
    buttonTitle: 'CONTACT US',
    grayOut: true,
    united_states: {
      price: 447,
      year_price: 415,
      format: 'USD',
      currency: 'USD',
    },
    usd: {
      price: 447,
      year_price: 415,
    },
    austria: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    belgium: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    bulgaria: {
      price: 180,
      year_price: 120,
      format: 'EUR',
      currency: 'EUR',
    },
    croatia: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    cyprus: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    czechia: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    denmark: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    estonia: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    finland: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    france: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    germany: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    greece: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    hungary: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    ireland: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    italy: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    latvia: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    lithuania: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    luxembourg: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    malta: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    netherlands: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    poland: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    portugal: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    romania: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    slovakia: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    slovenia: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    spain: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    sweden: {
      price: 411,
      year_price: 382,
      format: 'EUR',
      currency: 'EUR',
    },
    eur: {
      price: 411,
      year_price: 382,
    },
    australia: {
      price: 672,
      year_price: 624,
      format: 'AUD',
      currency: 'AUD',
    },
    aud: {
      price: 672,
      year_price: 624,
    },
    new_zealand: {
      price: 724,
      year_price: 672,
      format: 'NZD',
      currency: 'NZD',
    },
    nzd: {
      price: 724,
      year_price: 672,
    },
    canada: {
      price: 611,
      year_price: 567,
      format: 'CAD',
      currency: 'CAD',
    },
    cad: {
      price: 611,
      year_price: 567,
    },
    south_africa: {
      price: 8337,
      year_price: 7742,
      format: 'ZAR',
      currency: 'ZAR',
    },
    zar: {
      price: 8337,
      year_price: 7742,
    },
  },

  [EXPERT_NEW]: {
    title: 'Reseller',
    icon: reseller,
    components: accelerateComponents,
    description:
      'If your company is looking to enhance its product portfolio this is perfect tool that helps you to offer your customers stunning mobile apps',
    price: 150,
    year_price: 2700,
    buttonTitle: 'CONTACT US',
    grayOut: true,
    hide: true,
  },

  [EXPERT_5]: {
    title: 'Reseller',
    icon: reseller,
    components: accelerateComponents,
    description:
      'If your company is looking to enhance its product portfolio this is perfect tool that helps you to offer your customers stunning mobile apps',
    price: 150,
    year_price: 2700,
    buttonTitle: 'CONTACT US',
    grayOut: true,
    hide: true,
  },
};

export const oldPlansMapping = {
  [ACCELERATE]: DELUX,
  [LAUNCH]: DIY,
  [EXPERT]: EXPERT_NEW,
};

export default plans;
