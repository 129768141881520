import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import MemberLoginMenu from './MemberLoginMenu';

import {
  toggleAuth,
  getAuth,
  stopGetAuth,
  toggleDiaryEnabled,
  toggleEventsEnabled,
  getToggledAuth,
  setToggledAuth,
} from './actions';
import {
  addIsPublishedListener,
  removeIsPublishedListener,
} from '../../../../app/modules/toolbar/actions';
import { APP_SUMO_PLANS } from '../../../../plans';
import { loadDiyComponents } from '../../../../app/actions';

const mapStateToProps = (state, ownProps) => {
  const defaultGroup = state.auth.groups.data.chunks.default;
  const enabled = state.auth.structure.enabled || false;
  const hasEnabledAuth = state.auth.structure.hasEnabledAuth || false;
  const diaryEnabled = defaultGroup ? defaultGroup.diaryEnabled : false;
  const eventsEnabled = defaultGroup ? defaultGroup.diaryEnabled : false;
  const legacyFlag = state.account.organisation.legacy_flag || false;
  const isInTrial = !state.account.organisation.trial_expired || true;
  const subscription =
    state.account.subscriptions.items[ownProps.match.params.appname] || {};

  return {
    ...ownProps,
    enabled:
      enabled &&
      (diaryEnabled ||
        eventsEnabled ||
        legacyFlag ||
        isInTrial ||
        (subscription && subscription.plan === 'deluxe') ||
        (subscription && subscription.plan === 'accelerate') ||
        (subscription && subscription.plan === 'launch') ||
        (subscription && subscription.plan === 'diy') ||
        (subscription &&
          subscription.plan &&
          APP_SUMO_PLANS.includes(subscription.plan))),
    hasEnabledAuth,
    diaryEnabled: defaultGroup ? defaultGroup.diaryEnabled : false,
    eventsEnabled: defaultGroup ? defaultGroup.eventsEnabled : false,
    legacyFlag: state.account.organisation.legacy_flag,
    subscription:
      state.account.subscriptions.items[ownProps.match.params.appname],
    type: state.auth.structure.type,
    role: state.account.auth.profile.role,
    groups: state.auth.groups,
    isPublished:
      state.editor.data.isPublished[ownProps.match.params.appname] || false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAuth: () => dispatch(getAuth(ownProps.match.params.appname)),
  getToggledAuth: () => dispatch(getToggledAuth(ownProps.match.params.appname)),
  setToggledAuth: () => dispatch(setToggledAuth(ownProps.match.params.appname)),
  stopAuth: () => dispatch(stopGetAuth(ownProps.match.params.appname)),
  toggleAuth: (v, type, addon) =>
    dispatch(toggleAuth(ownProps.match.params.appname, v, type, addon)),
  onAddIsPublishedListener: () =>
    dispatch(addIsPublishedListener(ownProps.match.params.appname)),
  onRemoveIsPublishedListener: () =>
    dispatch(removeIsPublishedListener(ownProps.match.params.appname)),
  toggleDiaryEnabled: (value, field) =>
    dispatch(toggleDiaryEnabled(ownProps.match.params.appname, value, field)),
  toggleEventsEnabled: (value, field) =>
    dispatch(toggleEventsEnabled(ownProps.match.params.appname, value, field)),
  loadDiyComponents: () =>
    dispatch(loadDiyComponents(ownProps.match.params.appname)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MemberLoginMenu),
);
