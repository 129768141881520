import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Container from '../../../../../../app/components/container';
import { Toggle } from 'material-ui';
import {
  MenuItem,
  FormControl,
  Select,
  TextField,
  Fade,
} from '@material-ui/core';
import { newValidator } from '../../../../../../utils/validation';
import TooltipElement from '../../../../../../app/components/tooltipElement';
import _get from 'lodash/get';
import styles from './marketplace.module.scss';
import { debounce } from 'lodash';
import { whitelabeledHost } from '../../../../../../utils/whitelabeledHost';
const isWhiteLabelledHost = whitelabeledHost();

const style = {
  toggleLabel: {
    fontSize: 14,
  },

  toggle: {
    margin: '24px 10px 12px 0px',
    paddingRight: 18,
    width: '100%',
  },
};

const STRIPE_APPLICATION_FEE = 3;

const Settings = ({
  data,
  profile,
  updateMarketplaceSettings,
  isAppAdmin,
  isSubAdminWithMulipleApps,
}) => {
  const { t } = useTranslation();
  const settings = {
    selectedCurrency: '',
    messagesEmail: {
      isEnabled: false,
      email: '',
    },
    productEmail: {
      isEnabled: false,
      email: '',
    },
    productApproval: {
      isEnabled: false,
    },
    vendorApproval: {
      isEnabled: false,
    },
    vendorEmail: {
      isEnabled: false,
      email: '',
    },
    vendorPolicyLink: '',
    payments: {
      isEnabled: false,
    },
    deliveryCost: {
      isEnabled: false,
    },
    stripePublicKey: '',
    stripeSecretKey: '',
    applicationFee: STRIPE_APPLICATION_FEE,
    payoutSchedule: 1,
  };

  const validationFields = {
    messagesEmail: true,
    productEmail: true,
  };

  const [marketplaceSettings, setMarketplaceSettings] = useState(settings);

  const [currencies, setCurrencies] = useState({});

  const [emailValidation, setEmailValidation] = useState(validationFields);

  useEffect(() => {
    const fetchCurrencies = async () => {
      const req = await fetch(
        'https://openexchangerates.org/api/currencies.json',
      );
      const data = await req.json();
      setCurrencies(data);
    };
    fetchCurrencies();
  }, []);

  useEffect(() => {
    if (data && data.marketplace_settings) {
      setMarketplaceSettings({
        ...marketplaceSettings,
        messagesEmail: {
          isEnabled: _get(
            data,
            'marketplace_settings.messagesEmail.isEnabled',
            marketplaceSettings.messagesEmail.isEnabled,
          ),
          email: _get(
            data,
            'marketplace_settings.messagesEmail.email',
            profile.email,
          ),
        },
        productEmail: {
          isEnabled: _get(
            data,
            'marketplace_settings.productEmail.isEnabled',
            marketplaceSettings.productEmail.isEnabled,
          ),
          email: _get(
            data,
            'marketplace_settings.productEmail.email',
            profile.email,
          ),
        },
        vendorEmail: {
          isEnabled: _get(
            data,
            'marketplace_settings.vendorEmail.isEnabled',
            marketplaceSettings.vendorEmail.isEnabled,
          ),
          email: _get(
            data,
            'marketplace_settings.vendorEmail.email',
            profile.email,
          ),
        },
        selectedCurrency: _get(
          data,
          'marketplace_settings.selectedCurrency',
          marketplaceSettings.selectedCurrency,
        ),
        productApproval: _get(
          data,
          'marketplace_settings.productApproval',
          marketplaceSettings.productApproval,
        ),
        vendorApproval: _get(
          data,
          'marketplace_settings.vendorApproval',
          marketplaceSettings.vendorApproval,
        ),
        payments: _get(
          data,
          'marketplace_settings.payments',
          marketplaceSettings.payments,
        ),
        deliveryCost: _get(
          data,
          'marketplace_settings.deliveryCost',
          marketplaceSettings.deliveryCost,
        ),
        stripePublicKey: _get(
          data,
          'marketplace_settings.stripePublicKey',
          marketplaceSettings.stripePublicKey,
        ),
        stripeSecretKey: _get(
          data,
          'marketplace_settings.stripeSecretKey',
          marketplaceSettings.stripeSecretKey,
        ),
        vendorPolicyLink: _get(
          data,
          'marketplace_settings.vendorPolicyLink',
          marketplaceSettings.vendorPolicyLink,
        ),
        applicationFee: _get(
          data,
          'marketplace_settings.applicationFee',
          marketplaceSettings.applicationFee,
        ),
        payoutSchedule: _get(
          data,
          'marketplace_settings.payoutSchedule',
          marketplaceSettings.payoutSchedule,
        ),
      });
    }
    setEmailValidation(validationFields);
  }, [data]);

  const emailValidator = newValidator({
    type: 'email',
    required: true,
  });

  const debouncedUpdate = debounce(updateMarketplaceSettings, 300, {
    leading: false,
  });
  const toggleSettings = name => (e, isToggled) => {
    const saveData = {
      isEnabled: isToggled,
    };
    setMarketplaceSettings({
      ...marketplaceSettings,
      [name]: {
        ...marketplaceSettings[name],
        isEnabled: isToggled,
      },
    });
    return debouncedUpdate(name, saveData);
  };

  const toggleEmailSettings = name => (e, isToggled) => {
    const saveData = {
      isEnabled: isToggled,
      email: _get(
        data,
        `data.marketplace_settings[${name}].email`,
        profile.email,
      ),
    };
    setMarketplaceSettings({
      ...marketplaceSettings,
      [name]: {
        ...marketplaceSettings[name],
        isEnabled: isToggled,
      },
    });
    return debouncedUpdate(name, saveData);
  };

  const changeDefaultEmail = name => email => {
    const isEmailValid = emailValidator(email);

    setMarketplaceSettings({
      ...marketplaceSettings,
      [name]: {
        ...marketplaceSettings[name],
        email,
      },
    });

    if (isEmailValid) {
      const saveData = {
        isEnabled: marketplaceSettings[name].isEnabled,
        email,
      };
      setEmailValidation({
        ...emailValidation,
        [name]: true,
      });
      return debouncedUpdate(name, saveData);
    } else {
      setEmailValidation({
        ...emailValidation,
        [name]: false,
      });
    }
  };

  const selectCurrency = e => {
    const selectedCurrency = e.target.value;
    setMarketplaceSettings({
      ...marketplaceSettings,
      selectedCurrency,
    });
    return debouncedUpdate('selectedCurrency', selectedCurrency);
  };

  const updatePublicKey = e => {
    const publicKey = e.target.value;
    setMarketplaceSettings({
      ...marketplaceSettings,
      stripePublicKey: publicKey,
    });
    return debouncedUpdate('stripePublicKey', publicKey);
  };

  const updateSecretKey = e => {
    const secretKey = e.target.value;
    setMarketplaceSettings({
      ...marketplaceSettings,
      stripeSecretKey: secretKey,
    });
    return debouncedUpdate('stripeSecretKey', secretKey);
  };

  const updatePolicyLink = e => {
    const policyLink = e.target.value;
    setMarketplaceSettings({
      ...marketplaceSettings,
      vendorPolicyLink: policyLink,
    });
    return debouncedUpdate('vendorPolicyLink', policyLink);
  };

  const updateApplicationFee = e => {
    const applicationFee = e.target.value;
    setMarketplaceSettings({
      ...marketplaceSettings,
      applicationFee,
    });
    return (
      applicationFee >= STRIPE_APPLICATION_FEE &&
      debouncedUpdate('applicationFee', applicationFee)
    );
  };

  const updatePayoutSchedule = e => {
    const payoutSchedule = parseInt(e.target.value);
    setMarketplaceSettings({
      ...marketplaceSettings,
      payoutSchedule,
    });
    if (payoutSchedule >= 1) {
      return debouncedUpdate('payoutSchedule', payoutSchedule);
    }
  };
  const {
    productEmail,
    productApproval,
    vendorApproval,
    selectedCurrency,
    stripePublicKey,
    stripeSecretKey,
    vendorPolicyLink,
    applicationFee,
    payments,
    deliveryCost,
    vendorEmail,
    payoutSchedule,
  } = marketplaceSettings;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: 'fit-content',
      }}
      className="scroll-container"
    >
      <div className={styles.settingContainer}>
        <div className={styles.settingTitle}>
          <Trans>Product Settings</Trans>
        </div>
        <div className={styles.settingField}>
          <Toggle
            style={style.toggle}
            labelStyle={style.toggleLabel}
            label={t('Product Approval')}
            toggled={productApproval.isEnabled}
            onToggle={toggleSettings('productApproval')}
          />

          <TooltipElement
            tooltipType="info"
            className={styles.tooltip}
            tooltip={t(
              'Product Approval allows you to approve any product or service before it gets published into your marketplace.',
            )}
            verticalPosition="top"
          />
        </div>

        <div className={styles.settingField}>
          <Toggle
            style={style.toggle}
            labelStyle={style.toggleLabel}
            label={t('Product email updates')}
            toggled={productEmail.isEnabled}
            onToggle={toggleEmailSettings('productEmail')}
          />

          <TooltipElement
            tooltipType="info"
            className={styles.tooltip}
            tooltip={t(
              'Add an admin to receive product updates, e.g. a new product has been added to the app.',
            )}
            verticalPosition="top"
            horizontalPosition="left"
          />
        </div>

        <Fade in={productEmail.isEnabled} unmountOnExit mountOnEnter>
          <div className={styles.toggleContent}>
            <TextField
              id="product-email"
              value={productEmail.email}
              variant="standard"
              className={styles.field}
              label={t('Email for receiving product updates')}
              onChange={e => changeDefaultEmail('productEmail')(e.target.value)}
              error={!productEmail.email}
              helperText={
                !productEmail.email && t('Email is required to receive updates')
              }
            />
          </div>
        </Fade>

        <div className={styles.settingField}>
          <div>
            <span className={styles.settingFieldTitle}>
              <Trans>Currency</Trans>
            </span>
          </div>
          <FormControl style={{ minWidth: '300px' }}>
            <Select
              value={selectedCurrency}
              onChange={selectCurrency}
              displayEmpty
              variant="standard"
            >
              <MenuItem key={'defaultValue'} value="" disabled>
                <em>
                  <Trans>Choose a currency</Trans>
                </em>
              </MenuItem>
              {Object.keys(currencies).length > 0 &&
                Object.keys(currencies).map(c => (
                  <MenuItem key={c} value={c}>
                    {currencies[c]}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={styles.settingContainer}>
        <div className={styles.settingTitle}>
          <Trans>Seller Settings</Trans>
        </div>
        <div className={styles.settingField}>
          <Toggle
            style={style.toggle}
            labelStyle={style.toggleLabel}
            label={t('Seller Approval')}
            toggled={vendorApproval.isEnabled}
            onToggle={toggleSettings('vendorApproval')}
          />

          <TooltipElement
            tooltipType="info"
            className={styles.tooltip}
            tooltip={t(
              'Seller Approval allows you to approve a seller account before it gets published into your marketplace.',
            )}
            verticalPosition="top"
          />
        </div>
        <div className={styles.settingField}>
          <Toggle
            style={style.toggle}
            labelStyle={style.toggleLabel}
            label={t('Seller email updates')}
            toggled={vendorEmail.isEnabled}
            onToggle={toggleEmailSettings('vendorEmail')}
          />

          <TooltipElement
            tooltipType="info"
            className={styles.tooltip}
            tooltip={t(
              'Add an admin to receive seller updates, e.g. a new seller has registered on the app.',
            )}
            verticalPosition="top"
            horizontalPosition="left"
          />
        </div>
        <Fade in={vendorEmail.isEnabled} unmountOnExit mountOnEnter>
          <div className={styles.toggleContent}>
            <TextField
              id="vendor-email"
              value={vendorEmail.email}
              variant="standard"
              className={styles.field}
              label={t('Email for receiving seller updates')}
              onChange={e => changeDefaultEmail('vendorEmail')(e.target.value)}
              error={!vendorEmail.email}
              helperText={
                !vendorEmail.email && t('Email is required to receive updates')
              }
            />
          </div>
        </Fade>
        <div className={styles.settingField}>
          <TextField
            id="vendor-policy"
            fullWidth
            value={vendorPolicyLink}
            className={styles.field}
            label={t('Seller Policy Link')}
            onChange={updatePolicyLink}
            variant="standard"
          />
          <TooltipElement
            tooltipType="info"
            className={styles.tooltip}
            tooltip={t(
              'Add a link to the privacy policy which sellers would need to agree in order to create a seller account on your app.',
            )}
            verticalPosition="top"
            horizontalPosition="left"
          />
        </div>
      </div>

      <div className={styles.settingContainer}>
        <div className={styles.settingTitle}>
          <Trans>Payment Settings</Trans>
        </div>
        <div className={`${styles.settingField} ${styles.paymentToggle}`}>
          <Toggle
            style={style.toggle}
            labelStyle={style.toggleLabel}
            label={t('Enable Payments')}
            toggled={payments.isEnabled}
            onToggle={toggleSettings('payments')}
          />
          <TooltipElement
            tooltipType="info"
            className={styles.tooltip}
            tooltip={t(
              'Enable payment to allow products & services to be sold via the app.',
            )}
            verticalPosition="top"
          />
        </div>
        <Fade in={payments.isEnabled} unmountOnExit mountOnEnter>
          <div>
            <div className={styles.settingSubtitle}>
              <Trans>
                Add Stripe API keys to allow online payments via the app
              </Trans>
            </div>
            <div className={styles.settingField}>
              <TextField
                id="stripe-public-key"
                fullWidth
                value={stripePublicKey}
                className={styles.field}
                label={'Stripe Publishable Key'}
                onChange={updatePublicKey}
                variant="standard"
              />
            </div>
            <div className={styles.settingField}>
              <TextField
                id="stripe-secret-key"
                fullWidth
                value={stripeSecretKey}
                type="text"
                className={styles.field}
                onChange={updateSecretKey}
                label="Stripe Secret Key"
                variant="standard"
              />
            </div>

            {isAppAdmin || isSubAdminWithMulipleApps ? (
              <a
                href="https://kb-articles.s3.eu-west-2.amazonaws.com/How+to+Set+Up+Stripe+Connect.pdf"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: 'block',
                  color: 'blue',
                  marginBottom: 5,
                  marginTop: 5,
                  fontWeight: 'bold',
                }}
              >
                💡 <Trans>How to set up Stripe Connect for Marketplace.</Trans>
              </a>
            ) : (
              <a
                href="https://beezer.zendesk.com/hc/en-us/articles/10670088929937-How-to-Set-Up-Stripe-Connect"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: 'block',
                  color: 'blue',
                  marginBottom: 5,
                  marginTop: 5,
                  fontWeight: 'bold',
                }}
              >
                💡 <Trans>How to set up Stripe Connect for Marketplace.</Trans>
              </a>
            )}

            <div className={styles.settingSubtitle}>
              <Trans>
                Add a transaction fee if you would like to receive a fee for
                each transaction paid via the app. Please ensure to inform the
                app sellers about the transaction fee within your app policy.
              </Trans>
            </div>
            <div className={styles.settingField}>
              <div>
                <span className={styles.settingFieldTitle}>
                  <Trans>Transaction Fee</Trans>
                </span>
              </div>
              <div className={styles.spaceRight}>
                <TextField
                  value={applicationFee}
                  type="number"
                  inputProps={{ min: 3, max: 100 }}
                  onChange={updateApplicationFee}
                  variant="standard"
                  InputProps={{
                    endAdornment: '%',
                  }}
                />
              </div>
            </div>
            <div className={styles.settingField}>
              <div>
                <span className={styles.settingFieldTitle}>
                  <Trans>Payout Schedule</Trans>
                </span>
              </div>
              <div className={`${styles.spaceRight} ${styles.payoutSchedule}`}>
                <TextField
                  id="payout-schedule"
                  fullWidth
                  type="number"
                  inputProps={{ min: 1 }}
                  InputProps={{
                    endAdornment: (
                      <span>
                        <Trans>Days</Trans>
                      </span>
                    ),
                  }}
                  value={payoutSchedule}
                  className={styles.field}
                  onChange={updatePayoutSchedule}
                  variant="standard"
                />
              </div>
            </div>
          </div>
        </Fade>
        <div className={`${styles.settingField} ${styles.paymentToggle}`}>
          <Toggle
            style={style.toggle}
            labelStyle={style.toggleLabel}
            label={t('Enable Delivery Cost')}
            toggled={deliveryCost.isEnabled}
            onToggle={toggleSettings('deliveryCost')}
          />
          <TooltipElement
            tooltipType="info"
            className={styles.tooltip}
            tooltip={t('Enable delivery cost for your products.')}
            verticalPosition="top"
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;
