import styled from 'styled-components';
import { get } from 'lodash';

export const StyledVideo = styled.video`
  height: 100%;
  width: 100%;
  border-radius: 17px;
  object-fit: cover;
`;

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const PlayPause = styled.div`
  background-image: url(https://beezer-img-storage.s3.eu-west-2.amazonaws.com/771baca7-1a66-4e8f-a109-a6a3decbdb07/play-circle.svg);
  width: 25%;
  @media (max-width: ${props =>
      get(props.theme, 'breakpoints.values.sm', '600')}px) {
    display: none;
  }
  display: block;
  position: absolute;
  aspect-ratio: 1 / 1;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 102%;
  border-radius: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  background-image: url(https://beezer-img-storage.s3.eu-west-2.amazonaws.com/771baca7-1a66-4e8f-a109-a6a3decbdb07/play-circle.svg);
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  ${VideoWrapper}:hover & {
    opacity: 1 !important;
  }
`;
