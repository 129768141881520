import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import ImageUpload from '../../../../../internals/imageUpload';
import Range from '../../../../../../../app/components/range';
import TextField from '../../../../../../../app/components/textField';
import { Tabs, Tab } from '../../../../../components/tabs';
import SectionHeader from '../../../../../components/sectionHeader';
import DesignToggle from '../../../../../components/designToggle';
import AppLinkMenu from '../../../../../components/appLinkMenu';

class Image extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onLinkChange: PropTypes.func.isRequired,
    onDesignChange: PropTypes.func.isRequired,
    createLink: PropTypes.func.isRequired,
    childRoutes: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
      .isRequired,
  };

  handleChange = prop => value => this.props.onChange(prop, value);

  designTab = () => {
    const { component, onDesignChange, t } = this.props;

    const { width } = component.design;

    const rangeProps = {
      min: 0,
      max: 100,
      step: 1,
      disabled: !component.design.enabled,
      marginLeft: 5,
      marginRight: 5,
      paddingLeft: '1rem',
      paddingRight: '1rem',
      width: '325px',
    };

    return (
      component.design && (
        <Tab label={t('design')}>
          <DesignToggle
            component={component}
            onDesignChange={(el, isToggled) =>
              onDesignChange(null)(el, isToggled)
            }
          />
          <SectionHeader
            title={t('Image Size')}
            tooltip={t("Define the image's size")}
          />
          <Range
            {...rangeProps}
            label={`${width.value}%`}
            value={width.value}
            onChange={(el, val) => onDesignChange('width')('value', val)}
          />
        </Tab>
      )
    );
  };

  render() {
    const { data, component, match, t } = this.props;

    return (
      <Tabs>
        <Tab label={t('content')}>
          <SectionHeader
            title={t('Mobile Image Upload')}
            tooltip={t('Select an image and caption for mobile users')}
          />
          <TextField
            id="box-caption"
            value={data.caption}
            onChange={this.handleChange('caption')}
            placeholder={t('Caption')}
          />
          <ImageUpload
            targetId="image_component_upload"
            title={t('Image > Crop Image')}
            src={data.src}
            showCropAreaSizes
            firebaseUrl={`/apps/${match.params.appname}/draft/lazy_data/${component.lazy_data}/src`}
            height="220px"
            width="320px"
          />
          <SectionHeader
            title={t('Desktop Image Upload')}
            tooltip={t('Select an image for desktop users')}
          />
          <ImageUpload
            targetId="desktop_image_component_upload"
            title={t('Desktop Image > Crop Image')}
            src={data.desktopImage}
            showCropAreaSizes
            firebaseUrl={`/apps/${match.params.appname}/draft/lazy_data/${component.lazy_data}/desktopImage`}
            height="220px"
            width="320px"
          />
          <AppLinkMenu
            showLabel={false}
            link={data.link}
            pages={this.props.childRoutes}
            onChange={this.props.onLinkChange}
            createLink={this.props.createLink}
          />
        </Tab>
        {this.designTab()}
        <Tab
          label={t('layout')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    );
  }
}

export default withTranslation()(Image);
