import React from 'react';
import { Route } from 'react-router-dom';

import EditMemberBodyModal from './EditMemberBody';

function EditMemberBodyModalRouter() {
  return (
    <Route path="/:appname/staff/member-bodies/:memberBody?">
      {props => <EditMemberBodyModal {...props} />}
    </Route>
  );
}

export default EditMemberBodyModalRouter;
