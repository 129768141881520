import { combineReducers } from 'redux';
import buyers from './modules/buyers/reducer';
import buyer from './modules/buyer/reducer';
import vendors from './modules/vendors/reducer';
import favoriteVendors from './modules/favoriteVendors/reducer';
import vendor from './modules/vendor/reducer';
import product from './modules/product/reducer';
import products from './modules/products/reducer';
import productsTable from './modules/productsTable/reducer';

import filters from './modules/filters/reducer';
import settings from './modules/settings/reducer';

export default combineReducers({
  buyers,
  buyer,
  vendors,
  favoriteVendors,
  vendor,
  product,
  productsTable,
  products,
  filters,
  settings,
});
