import { validateField } from './validateEmailField';

const deleteUserFieldChange = (prop, value, isValid) => ({
  type: 'DELETE_USER_FIELD_CHANGE',
  prop,
  value,
  isValid,
});

export const openDeleteUserDialog = () => ({ type: 'OPEN_DELETE_USER_DIALOG' });

export const closeDeleteUserDialog = () => ({
  type: 'CLOSE_DELETE_USER_DIALOG',
});

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;

  return dispatch(deleteUserFieldChange(prop, value, isValid));
};

const handleDelete = async (dispatch, getState) => {
  try {
    const { email } = getState().account.deleteUserByEmailReducer;
    const headers = new Headers({
      'Content-Type': 'application/json',
    });

    const body = JSON.stringify({
      providedEmail: email.value,
      requestOrigin: 'app',
    });

    const response = await fetch(
      `${process.env.REACT_APP_USERS_API}/delete-user-by-email`,
      {
        method: 'POST',
        mode: 'cors',
        headers,
        body,
      },
    );

    const json = await response.json();

    if (response.status === 200) {
      dispatch({ type: 'USER_DELETED' });
      window.location.reload();
    } else {
      throw new Error(json.message);
    }
  } catch (error) {
    dispatch({ type: 'ERROR', error });
    console.error('Something went wrong...', error);
  }
};

export const deleteUser = () => (dispatch, getState) => {
  dispatch({ type: 'DELETE_USER' });
  handleDelete(dispatch, getState);
};
