import React from 'react';

import './styles.css';

function Money() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon push-history-icon"
      version="1.1"
      viewBox="0 0 44.22 43.63"
      xmlSpace="preserve"
    >
      <path d="M48.207 52.447c.24 0 .43.191.43.43v.221c.59.07 1.09.26 1.549.549.16.09.301.24.301.48a.55.55 0 01-.561.549.566.566 0 01-.299-.09 3.652 3.652 0 00-1.029-.439v1.92c1.52.379 2.168.988 2.168 2.059 0 1.1-.859 1.828-2.129 1.949v.6c0 .24-.189.43-.43.43s-.439-.189-.439-.43v-.619a4.333 4.333 0 01-2.049-.781.548.548 0 01-.27-.479c0-.311.24-.551.549-.551.121 0 .24.041.33.111.471.34.93.568 1.48.668v-1.969c-1.461-.379-2.141-.93-2.141-2.059 0-1.068.85-1.809 2.1-1.908v-.211c.001-.238.2-.43.44-.43zm-.398 3.408v-1.768c-.641.059-.961.398-.961.838 0 .421.191.68.961.93zm.789 1.391v1.818c.639-.07.988-.391.988-.869 0-.439-.219-.711-.988-.949z"></path>
      <path
        fill="none"
        stroke="#261ed5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M66.75 69.766H71a2 2 0 002-2V45.762a2 2 0 00-2-2H25a2 2 0 00-2 2v22.004a2 2 0 002 2h35.875"
      ></path>
      <circle
        cx="48"
        cy="56.764"
        r="7.656"
        fill="none"
        stroke="#261ed5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      ></circle>
      <g
        fill="none"
        stroke="#261ed5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      >
        <path d="M65.896 27.593a1.828 1.828 0 00-2.242-1.295L22.967 37.199a1.83 1.83 0 00-1.296 2.243"></path>
        <path d="M70.23 43.766l-4.334-16.173a1.828 1.828 0 00-2.242-1.295L22.967 37.199a1.83 1.83 0 00-1.296 2.243L23 44.647"></path>
        <path d="M62.885 39.66L47.578 43.762"></path>
        <path d="M49.541 35.811L66.855 31.172 68.713 38.099 65.797 38.88"></path>
        <path d="M22.63 43.021L43.949 37.31"></path>
      </g>
    </svg>
  );
}

export default Money;
