import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RenderToLayer from 'material-ui/internal/RenderToLayer';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { Link } from '@material-ui/core';
import moment from 'moment';
import { store } from '../../../../';
import { planConstants } from './planConstants';

import { CardChip } from '../../../../app/components/selectableCardMenu';
import { RaisedButton } from '../../../../app/components/buttons';
import {
  plans,
  pricing,
  plansWixWeebly,
  EXPERT,
  START,
  FREE,
  oldPlansMapping,
  JAN_50_OFF,
  plansAppSumo,
} from '../../../../plans';
import OfferCard from './OfferCard';
import ReEnableFreeTrial from './ReEnableFreeTrial';
import { addOffersAnalytics } from '../../../analytics/actions';
import { getPriceAfterCoupon } from './actions';
import './styles.css';
import themeStyles from '../../../../_export.scss';
import { referrerList } from '../register/referrerList';
import { PROMO_DETAILS } from '../../../../config';

const styles = {
  helpButton: {
    margin: 3,
    height: 48,
    width: 48,
    minWidth: 'none',
    backgroundColor: 'rgba(246,246,246,1)',
  },
  helpButtonLabel: {
    padding: 0,
    fontSize: '12px',
  },
  exitButton: {
    margin: '0 0.75rem',
    position: 'absolute',
    right: 0,
  },
  PrimaryLabelStyle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: themeStyles.primaryTextColor,
  },
  SecondaryLabelStyle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: themeStyles.secondaryTextColor,
  },
  buttonRoot: {
    minWidth: '70px',
    width: '20%',
    margin: '40px auto 0 auto',
  },
  contactUsButton: {
    margin: '12px',
    minWidth: '70px',
  },
};

class SelectPlan extends Component {
  static propTypes = {
    account: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    accountId: PropTypes.object.isRequired,
    integrationType: PropTypes.string,
    subscription: PropTypes.object,
    offerEligibility: PropTypes.object,
    offers: PropTypes.object.isRequired,
    country: PropTypes.string.isRequired,
    isAppSumoAccount: PropTypes.bool.isRequired,
    getAppSumoBilling: PropTypes.func.isRequired,
    appSumoBilling: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
      .isRequired,
  };

  static defaultProps = {
    integrationType: '',
    subscription: {},
    offerEligibility: null,
  };

  state = {
    billingPeriod: 'yearly',
  };

  componentDidMount() {
    const {
      account,
      offerEligibility,
      accountId,
      isAppSumoAccount,
      getAppSumoBilling,
      subscription,
      getNextInvoiceAmount,
    } = this.props;

    if (subscription && subscription.id && subscription.addOn && accountId) {
      getNextInvoiceAmount(accountId, subscription.id);
    }

    if (isAppSumoAccount) {
      getAppSumoBilling();
    }

    this.props.onPlanUpgradePageViewed(accountId);
    const offerCode = offerEligibility
      ? Object.keys(offerEligibility)[0]
      : null;

    const tempOfferCode =
      offerEligibility && Object.keys(offerEligibility).length > 1
        ? Object.keys(offerEligibility)[1]
        : null;

    if (offerCode) {
      if (window.fbq) {
        // Facebook analytics
        window.fbq('track', 'ViewContent');
      }
      addOffersAnalytics('offersOpened', offerCode, account);
      ReactGA.event({
        category: 'User',
        action: `Offers page opened with offer ${offerCode}`,
      });
    }
    if (tempOfferCode) {
      if (window.fbq) {
        // Facebook analytics
        window.fbq('track', 'ViewContent');
      }
      addOffersAnalytics('offersOpened', offerCode, account);
      ReactGA.event({
        category: 'User',
        action: `Offers page opened with offer ${offerCode}`,
      });
    }
  }

  onCardClick = plan => {
    if (plan === EXPERT) {
      window.location.href = 'mailto:support@beezer.com?Subject=Expert%20Plan';
    } else {
      this.handleCardTap(plan);
    }
  };

  isPromoValidForPlan = plan => {
    const currentDate = Date.now();
    const promoPlan = PROMO_DETAILS.plan;
    const isPromoInDuration =
      PROMO_DETAILS.startDate <= currentDate &&
      PROMO_DETAILS.endDate >= currentDate;
    const currentPlan = plan;
    const subscribedPlan = this.props.subscription.plan || '';
    const subscribedToPromoPlan = subscribedPlan.includes(promoPlan);
    const isPromoForCurrentPlan = currentPlan.includes(promoPlan);
    return isPromoInDuration && isPromoForCurrentPlan && !subscribedToPromoPlan;
  };

  getCurrentBillingPeriod = () => {
    const { subscription } = this.props;
    const period = (subscription && subscription.period) || 'monthly';

    return period;
  };

  isCurrentPlanOld = () => {
    const { subscription } = this.props;
    return subscription.old_plan;
  };

  showOldPricing = plan =>
    this.isCurrentPlan(plan) &&
    this.isCurrentPlanOld() &&
    this.getCurrentBillingPeriod() === this.state.billingPeriod;

  oldPricing = () => {
    const { subscription } = this.props;
    const planName = subscription && subscription.plan;

    let price = 0;
    if (planName && plans[planName]) {
      const plan = plans[planName];

      if (planName === EXPERT) {
        price =
          this.getCurrentBillingPeriod() === 'monthly'
            ? plan.old_price
            : plan.old_year_price;
      } else {
        price =
          this.getCurrentBillingPeriod() === 'monthly'
            ? plan.price
            : plan.year_price;
      }
    }

    return price;
  };

  getCurrentPlanName = () => {
    const { subscription } = this.props;
    let planName = (subscription && subscription.plan) || '';

    // if old plan then get old plan name
    if (oldPlansMapping[planName]) {
      planName = oldPlansMapping[planName];
    }

    if (planName.includes('diy')) planName = 'diy';
    if (planName.includes('deluxe')) planName = 'deluxe';
    if (planName.includes('something')) planName = 'something';
    if (planName.includes('expert')) planName = 'expert';

    return planName;
  };

  getBillingPeriod = () => {
    const billingPeriod =
      this.state.billingPeriod === 'monthly' ? 'monthly' : 'yearly';

    return billingPeriod;
  };

  toggleBillingPeriod = () => {
    const billingPeriod =
      this.state.billingPeriod === 'monthly' ? 'yearly' : 'monthly';
    this.setState({ billingPeriod });
  };

  handleCardTap = plan => {
    let product_id = '';
    if (this.state.billingPeriod === 'monthly') {
      product_id = plans[plan].monthly_product_id;
    } else if (this.state.billingPeriod === 'yearly') {
      product_id = plans[plan].yearly_product_id;
    }

    this.props.history.push({
      pathname: `/${this.props.match.params.appname}/subscribe`,
      query: {
        plan,
        product_id,
        billingPeriod: this.state.billingPeriod,
        integrationType: this.props.integrationType,
      },
    });
  };

  handleFreeOfferTap = () => {
    this.props.enableFreeTrial();
    this.props.history.push({
      pathname: `/${this.props.match.params.appname}/editor/build`,
    });
  };

  calculatePrice = price =>
    +(price * (pricing[this.state.billingPeriod].comparison || 1)).toFixed(2);

  calculatePriceUpdated = (plan, country) => {
    if (country && plan[country.toLowerCase()])
      return this.state.billingPeriod === 'monthly'
        ? plan[country.toLowerCase()].price
        : plan[country.toLowerCase()].year_price;
    return this.state.billingPeriod === 'monthly'
      ? plan.price
      : plan.year_price;
  };

  hasPlan = () => {
    const planName = this.getCurrentPlanName();
    const hasPlan = planName && planName !== FREE;

    return hasPlan;
  };

  isLifeTimePlan = () => this.getCurrentBillingPeriod() === 'lifetime';

  isCurrentPlan = plan => {
    if (this.isLifeTimePlan()) {
      return plan === this.getCurrentPlanName();
    }
    return (
      plan === this.getCurrentPlanName() &&
      this.getBillingPeriod() === this.getCurrentBillingPeriod()
    );
  };

  renderPrice = (plan, planList, format, offerCode, country) => {
    const { nextInvoiceAmount, subscription } = this.props;

    if (this.isLifeTimePlan() && this.isCurrentPlan(plan)) {
      return (
        <h3>
          <strong className="plan-price">LIFETIME</strong>
        </h3>
      );
    }

    let referralOfferEligibility =
      referrerList[this.props.referrer] &&
      referrerList[this.props.referrer].offer
        ? true
        : false;

    if (this.props.referrer === 'halloweenOffer') {
      const offerExpired = !moment(moment().format('YYYY-MM-DD')).isBefore(
        '2021-11-01',
        'day',
      );

      if (offerExpired) {
        referralOfferEligibility = false;
        delete this.props.referralOfferEligibility['halloween_40_off_yearly'];
        delete this.props.referralOfferEligibility['halloween_40_off_monthly'];
        this.props.removeReferalFromAccount();
      }
    }

    this.props &&
      this.props.referralOfferEligibility &&
      this.props.referrer &&
      Object.keys(referrerList[this.props.referrer].offer.coupons).forEach(
        offer => {
          if (
            (!(
              (
                this.props.referralOfferEligibility &&
                this.props.referralOfferEligibility[offer]
              ) //if offer eligibility is false then coupon has been redeemed
            ) &&
              this.props.redeemedCoupons &&
              this.props.redeemedCoupons[offer] &&
              this.props.redeemedCoupons[offer].end < Date.now()) || // check if the coupon duration is over
            (this.props.redeemedCoupons &&
              this.props.redeemedCoupons[offer] &&
              referrerList[this.props.referrer].offer.coupons[offer] //check if coupon is applicable to plan in this billing period
                .billingPeriod !== this.state.billingPeriod)
          ) {
            referralOfferEligibility = false;
          }
        },
      );

    let originalPrice = this.showOldPricing(plan)
      ? this.oldPricing()
      : this.calculatePriceUpdated(planList[plan], country);
    originalPrice = +String(originalPrice).slice(0, 4);

    let priceAfterCoupon = getPriceAfterCoupon(offerCode, originalPrice);
    if (
      referralOfferEligibility &&
      referrerList[this.props.referrer].offer &&
      referrerList[this.props.referrer].offer.products.includes(plan)
    ) {
      priceAfterCoupon = getPriceAfterCoupon(
        referrerList[this.props.referrer].offer,
        originalPrice,
      );
    }

    if (
      this.props.lastRedeemedCoupon &&
      (this.props.lastRedeemedCoupon.duration === 'forever' ||
        this.props.lastRedeemedCoupon.endDate >= Date.now()) &&
      (planList[plan].monthly_product_id ===
        this.props.lastRedeemedCoupon.product ||
        planList[plan].yearly_product_id ===
          this.props.lastRedeemedCoupon.product)
    ) {
      priceAfterCoupon = getPriceAfterCoupon(
        { discountPercent: this.props.lastRedeemedCoupon.percent_off },
        originalPrice,
      );
    }

    if (this.isPromoValidForPlan(plan)) {
      priceAfterCoupon = getPriceAfterCoupon(
        { discountPercent: PROMO_DETAILS.discountPercent },
        originalPrice,
      );
    }
    const hasOffer = originalPrice > priceAfterCoupon;
    let price = priceAfterCoupon;
    let showOfferPrice = hasOffer;

    const renderPriceNumber = (number, hasLineThrough) => (
      <strong
        className={hasLineThrough ? 'plan-price-line-through' : 'plan-price'}
      >
        {number}
        <span className="plan-format">{format}</span>
      </strong>
    );

    const renderPeriodText = type => {
      let periodText = 'per month';
      const isYearly = this.state.billingPeriod === 'yearly';

      if (showOfferPrice && offerCode === JAN_50_OFF) {
        periodText = isYearly ? 'per month for first year' : 'first month';
        const ongoingPrice = `(${originalPrice} GBP ongoing)`;

        return (
          <>
            <span className="plan-period">{periodText}</span>
            <span className="ongoing-price">{ongoingPrice}</span>
          </>
        );
      }

      if (type) {
        periodText = 'for 12 months';
      }
      return <span className="plan-period">{periodText}</span>;
    };

    if (this.hasPlan() && hasOffer) {
      // If the promo is active show discounted price
      if (this.isPromoValidForPlan(plan)) {
        showOfferPrice = true;
        price = price;
      } else {
        // If the user has subscribed using an offer, show plans at their original price except the one the user subscribed to
        showOfferPrice = this.isCurrentPlan(plan);
        price = this.isCurrentPlan(plan) ? price : originalPrice;
      }
    }

    if (
      subscription &&
      subscription.addOn &&
      subscription.plan === plan &&
      nextInvoiceAmount
    ) {
      price = nextInvoiceAmount / 100;
    }

    return (
      <h3 className="flex-column align-center relative">
        {/* {plan === 'diy' ? (
          <span className="plan-subtext">Starting at</span>
        ) : null} */}

        {renderPriceNumber(price, false)}
        {showOfferPrice
          ? renderPriceNumber(originalPrice, showOfferPrice)
          : null}

        {showOfferPrice &&
          referralOfferEligibility &&
          referrerList[this.props.referrer].offer.content && (
            <h3
              style={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
                position: 'absolute',
                top: '-110%',
                whiteSpace: 'nowrap',
                right: -25,
              }}
              className="offer-terms-link"
            >
              {this.props.referrer === 'halloweenOffer' ? (
                <img
                  style={{ width: '120%', height: '120%' }}
                  src="https://firebasestorage.googleapis.com/v0/b/beezer-c947f.appspot.com/o/halloween%2FHalloween%20offer%20icon.png?alt=media&token=b65a0ed0-b2f7-493f-ae69-dde4f615cf6d"
                />
              ) : (
                'Special Offer'
              )}
              <span role="img" aria-label="offer">
                {this.props.referrer === 'halloweenOffer' ? '' : '💸'}
              </span>
            </h3>
          )}
        {showOfferPrice &&
          referralOfferEligibility &&
          referrerList[this.props.referrer].offer.conditionsLink && (
            <a
              className="offer-terms-link"
              href={referrerList[this.props.referrer].offer.conditionsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              T&C Applied*
            </a>
          )}
        {showOfferPrice &&
          referralOfferEligibility &&
          referrerList[this.props.referrer].offer.content && (
            <p
              style={{
                fontSize: '1.6rem',
                fontWeight: 'bold',
                marginBottom: -20,
                marginTop: 10,
              }}
              className="offer-terms-link"
            >
              {referrerList[this.props.referrer].offer.content}
            </p>
          )}
        {renderPeriodText(
          showOfferPrice &&
            referralOfferEligibility &&
            referrerList[this.props.referrer].offer.content,
        )}
      </h3>
    );
  };

  renderImage = ({ plan, planList }) => (
    <div className="plan-image-container">
      <img
        src={planList[plan].icon}
        alt={`${planList[plan].title} Plan Icon`}
      />
    </div>
  );

  renderButton = ({ plan, planList }) => {
    const label = this.isCurrentPlan(plan)
      ? 'CURRENT PLAN'
      : planList[plan].buttonTitle;
    const isCurrentPlan = this.isCurrentPlan(plan);

    return (
      <CardChip
        label={label}
        showBorder={!isCurrentPlan}
        highlightOnHover={!isCurrentPlan}
        showBoldLabel={isCurrentPlan}
      />
    );
  };

  renderPlans = (planList, format, offerCode, country) => {
    const subscription = this.props.subscription;
    return Object.keys(planList).map(
      plan =>
        plan !== START &&
        plan !== FREE &&
        !planList[plan].isAppSumoPlan &&
        !planList[plan].hide && (
          <div className="plan-card-holder">
            <div
              className={`plan-card ${
                this.isCurrentPlan(plan) ? 'active' : ''
              }`}
            >
              <div />
              {this.isPromoValidForPlan(plan) && (
                <div className="promo-offer-content">
                  <img src={PROMO_DETAILS.promoSaleImage} />
                  <a
                    href={PROMO_DETAILS.termsAndConditionsLink}
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    T&C*
                  </a>
                </div>
              )}
              <span className="plan-title">{planList[plan].title}</span>
              <div className="separator" />
              {this.renderPrice(
                plan,
                planList,
                (country &&
                  planList[plan][country.toLowerCase()] &&
                  planList[plan][country.toLowerCase()].format) ||
                  format,
                offerCode,
                country,
              )}
              <span className="plan-desc">
                <p className="plan-description">{planList[plan].description}</p>
              </span>
            </div>
            {this.isCurrentPlan(plan) ? null : (
              <RaisedButton
                label={plan !== EXPERT ? 'Go!' : 'Contact Us!'}
                backgroundColor={themeStyles.primaryColor}
                disabledBackgroundColor={themeStyles.primaryColorLight}
                style={styles.contactUsButton}
                labelStyle={styles.PrimaryLabelStyle}
                onClick={() => this.onCardClick(plan)}
              />
            )}
          </div>
        ),
    );
  };

  onExit = () => {
    const { onExit } = this.props;

    if (onExit) {
      return onExit();
    }

    return this.props.history.goBack();
  };

  isAppSumoCurrentPlan = plan => {
    if (this.props.subscription.plan == plan.plan_id) {
      return true;
    }
    return false;
  };

  onAppSumoPlanClick = plan => {
    window.location.href = `mailto:support@beezer.com?subject=I would like to change my subscription to ${plan.title}&body=Dear Support,`;
    // this.props.subscription &&
    //   this.props.subscription.item_id &&
    //   window.open(
    //     `https://appsumo.com/account/redemption/${this.props.subscription.item_id}#change-plan`,
    //     '_blank',
    //   );
  };

  featuresAppSumo = plan => {
    return plan.features.map(item => {
      return (
        <li className="feature" key={item.feature}>
          <span className="feature-value">
            {item.value === true ? (
              <b style={{ position: 'relative', left: 5 }}>✅</b>
            ) : item.value === false ? (
              <b style={{ position: 'relative', left: 5 }}>❌</b>
            ) : (
              <b>{item.value}</b>
            )}
          </span>
          <span className="feature-name">{item.feature}</span>
        </li>
      );
    });
  };

  renderAppSumoPlans = () => {
    const {
      appSumoBilling: { amount },
    } = this.props;

    return Object.keys(plansAppSumo).map(plan => {
      const planDetails = plansAppSumo[plan];
      return (
        <div className="plan-card-holder">
          <div
            className={`plan-card ${
              this.isAppSumoCurrentPlan(planDetails) ? 'active' : ''
            }`}
          >
            <div />
            <span className="plan-title">{planDetails.title}</span>
            <div className="separator" />
            <span className="plan-desc" style={{ marginBottom: 10 }}>
              <p className="plan-description">{planDetails.subTitle}</p>
              <b>
                {this.isAppSumoCurrentPlan(planDetails)
                  ? amount
                  : planDetails.dealPrice}
              </b>{' '}
              | <strike>{planDetails.annualRetailValue}</strike>
            </span>
            <ul className="app-sumo-features">
              {this.featuresAppSumo(planDetails)}
            </ul>
          </div>
          {this.isAppSumoCurrentPlan(planDetails) ? null : (
            <RaisedButton
              label="Buy Now"
              backgroundColor={themeStyles.primaryColor}
              disabledBackgroundColor={themeStyles.primaryColorLight}
              style={styles.contactUsButton}
              labelStyle={styles.PrimaryLabelStyle}
              onClick={() => this.onAppSumoPlanClick(planDetails)}
            />
          )}
        </div>
      );
    });
  };

  getOfferCard = (offerCode, offerToUse) => {
    const { country } = this.props;

    if (offerCode === 'freetrialenable') {
      return (
        <ReEnableFreeTrial
          handleCardTap={this.handleFreeOfferTap}
          offer={offerToUse}
          calculatePrice={this.calculatePriceUpdated}
          history={this.props.history}
          billingPeriodToggle={this.state.billingPeriod}
        />
      );
    } else if (offerCode === JAN_50_OFF) {
      // Don't show offer card when using JAN_50_OFF offer code
      return null;
    } else if (offerCode === 'beezeroneyear19') {
      return (
        <OfferCard
          handleCardTap={this.handleCardTap}
          offer={offerToUse}
          calculatePrice={this.calculatePriceUpdated}
          history={this.props.history}
          billingPeriodToggle={this.state.billingPeriod}
          currency="GBP"
        />
      );
    }

    return (
      <OfferCard
        handleCardTap={this.handleCardTap}
        offer={offerToUse}
        calculatePrice={this.calculatePriceUpdated}
        history={this.props.history}
        billingPeriodToggle={this.state.billingPeriod}
        currency={
          country &&
          planConstants.lifetime[country.toLowerCase()] &&
          planConstants.lifetime[country.toLowerCase()].currency
        }
        dynamicPrice={
          country &&
          planConstants.lifetime[country.toLowerCase()] &&
          planConstants.lifetime[country.toLowerCase()].price
        }
      />
    );
  };

  renderLayer = () => {
    const isWeebly = this.props.integrationType === 'Weebly';
    const { country, isAppSumoAccount } = this.props;
    const title = this.hasPlan()
      ? 'Would you like to change your subscription?'
      : "Let's get you on the right plan!";
    const { offerEligibility, offers } = this.props;

    let offerCode = offerEligibility ? Object.keys(offerEligibility)[0] : null;
    let tempOfferCode =
      offerEligibility && Object.keys(offerEligibility).length > 1
        ? Object.keys(offerEligibility)[1]
        : null;
    //if offerCode is false then set offerCode

    offerCode =
      offerCode !== null && offerEligibility[offerCode] === false
        ? null
        : offerCode;

    const offerToUse = offerEligibility ? offers[offerCode] : null;

    tempOfferCode =
      tempOfferCode !== null && offerEligibility[tempOfferCode] === false
        ? null
        : tempOfferCode;

    const tempOfferToUse = offerEligibility ? offers[tempOfferCode] : null;

    return (
      <Provider store={store}>
        {!isAppSumoAccount ? (
          <div className="overlay select-plans">
            <div className="flex-row justify-center flex-align-center select-plan-header">
              <div className="flex-column align-center">
                <h1>{title}</h1>
                <span>
                  You can downgrade or cancel your subscription at any time.
                </span>
              </div>
              <IconButton style={styles.exitButton} onClick={this.onExit}>
                <Close style={{ fontSize: 70 }} />
              </IconButton>
            </div>

            <div className="flex-row justify-center">
              <label className="preview-toggle">
                <span>Monthly</span>
                <div className="switch">
                  <input
                    className="toggle"
                    type="checkbox"
                    onClick={this.toggleBillingPeriod}
                    value="billingPeriod"
                    checked={this.state.billingPeriod === 'yearly'}
                  />
                  <span className="slider round" />
                </div>
                <span>Yearly</span>
              </label>
            </div>

            <div className="plan-container">
              <div className="flex-row justify-center">
                {isWeebly
                  ? this.renderPlans(plansWixWeebly, 'USD', offerCode)
                  : this.renderPlans(plans, 'GBP', offerCode, country || '')}

                {/* {offerCode ? this.getOfferCard(offerCode, offerToUse) : null}
                {tempOfferCode
                  ? this.getOfferCard(tempOfferCode, tempOfferToUse)
                  : null} */}
              </div>
            </div>

            <Link
              href={
                isWeebly
                  ? 'https://www.weebly.com/uk/app-center/beezer-mobile#app-pricing-data'
                  : 'https://beezer.com/pricing'
              }
              target="_blank"
            >
              <RaisedButton
                label="Compare Features"
                backgroundColor={themeStyles.secondaryColor}
                style={styles.buttonRoot}
                labelStyle={styles.secondaryTextColor}
              />
            </Link>
          </div>
        ) : (
          <div className="overlay select-plans">
            <div className="flex-row justify-center flex-align-center select-plan-header">
              <div className="flex-column align-center">
                <h1>{title}</h1>
                <span>
                  You can upgrade, downgrade or cancel your subscription at any
                  time.
                </span>
              </div>
              <IconButton style={styles.exitButton} onClick={this.onExit}>
                <Close style={{ fontSize: 70 }} />
              </IconButton>
            </div>
            <div className="plan-container">
              <div className="flex-row justify-center">
                {this.renderAppSumoPlans()}
              </div>
            </div>
          </div>
        )}
      </Provider>
    );
  };

  render() {
    return (
      <RenderToLayer
        render={this.renderLayer}
        useLayerForClickAway={false}
        open
      />
    );
  }
}

export default SelectPlan;
