import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import { FlatButton } from '../../../../../../../app/components/buttons';
import CircularProgress from '@material-ui/core/CircularProgress';

import './styles.scss';

const UploadDialog = ({
  open,
  onCancel,
  openFileSelector,
  filesList,
  onUpload,
  pending,
}) => {
  const { t } = useTranslation();

  const actions = [
    <FlatButton label={t('Cancel')} onClick={onCancel} secondaryWhite />,
  ];

  useEffect(() => {
    if (filesList.length) {
      actions[1] = (
        <FlatButton
          label={`${t('Upload')} ${filesList.length} ${t('files')}`}
          onClick={onUpload}
          secondaryWhite
        />
      );
    }
  }, [filesList]);

  useEffect(() => {
    if (pending) {
      actions[1] = <CircularProgress />;
    }
  }, [pending]);

  return (
    <Dialog
      title={t('Upload PDF Files')}
      open={open}
      actions={actions}
      actionsContainerStyle={{
        textAlign: 'center',
        padding: 16,
      }}
      bodyStyle={{
        position: 'relative',
        overflowY: 'auto',
        padding: '0px 24px',
        minHeight: '350px',
      }}
      contentStyle={{
        textAlign: 'center',
        maxHeight: '100%',
        transform: 'translate(0px, 32px)',
      }}
      onRequestClose={onCancel}
      autoScrollBodyContent
    >
      <div className="upload_dialog_main_container">
        <button className="url_upload_button_file_2" onClick={openFileSelector}>
          <Trans>Select Files</Trans>
        </button>
        <div className="upload_dialog_files_list">
          {filesList &&
            filesList.map((file, i) => (
              <p key={file.name}>
                {i + 1} - {file.name}
              </p>
            ))}
        </div>
      </div>
    </Dialog>
  );
};

UploadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  openFileSelector: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  filesList: PropTypes.array.isRequired,
  onUpload: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
};

export default UploadDialog;
