import _debounce from 'lodash/debounce';
import firebase from '../../../../../../../firebase';

const isValidEmbedCode = value =>
  //eslint-disable-next-line max-len
  /^\x3Ciframe\swidth\x3D\x22100\x25\x22\sheight\x3D\x22550px\x22\ssrc\x3D\x22https:\/\/book.appointedd.com\/app\/[a-z0-9]{24}\x22\x3E\x3C\/iframe\x3E$/.test(
    value,
  );

const isValidSrc = value =>
  /^https:\/\/book.appointedd.com\/app\/[a-z0-9]{24}$/.test(value);

const updateIfValid = (dataRef, value) => {
  if (isValidSrc(value)) {
    dataRef.child('src').set(value);
  } else if (isValidEmbedCode(value)) {
    const src = value.substr(41, 56);
    dataRef.child('src').set(src);
  } else {
    dataRef.child('src').set(null);
  }
};

const debouncedUpdate = _debounce(updateIfValid, 800, { leading: false });

export const handleSrcChange = (appname, alwaysDataId, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${alwaysDataId}`);

  dataRef.child('rawSrc').set(value);

  debouncedUpdate(dataRef, value.trim());

  return {
    type: 'APPOINTEDD_SRC_CHANGE',
    value,
  };
};
