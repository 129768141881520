import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import { useTranslation, Trans } from 'react-i18next';

import SectionHeader from '../sectionHeader';
import themeStyles from '../../../../_export.scss';
const DesignToggle = ({ component, onDesignChange }) => {
  const { t } = useTranslation();
  const toggleButton = (enabled, onChange, attr) => (
    <Toggle
      toggled={enabled}
      onToggle={(e, isToggled) => {
        onChange(attr, isToggled);
      }}
      trackSwitchedStyle={{ backgroundColor: themeStyles.primaryColorLight }}
      thumbSwitchedStyle={{ backgroundColor: themeStyles.primaryColor }}
    />
  );

  return (
    <SectionHeader
      title={t('Override Design')}
      actions={toggleButton(
        component && component.design && component.design.enabled
          ? component.design.enabled
          : false,
        onDesignChange,
        'enabled',
      )}
      tooltip={
        <div>
          <Trans>Toggle this to customize the look of your component</Trans>
        </div>
      }
      borderBottom
    />
  );
};

DesignToggle.propTypes = {
  component: PropTypes.object.isRequired,
  onDesignChange: PropTypes.func.isRequired,
};

export default DesignToggle;
