import firebase from '../../../../../../../../firebase';
import { validateField } from '../../validators';
import { openSnackbar } from '../../../../../../../../app/modules/snackbar/actions';
import { WORKFORCE_CREATED } from '../../../../config';

export const NEW_WORKFORCEM_FIELD_CHANGE = 'NEW_WORKFORCEM_FIELD_CHANGE';
export const OPEN_NEW_WORKFORCE_MEMBER_DIALOG =
  'OPEN_NEW_WORKFORCE_MEMBER_DIALOG';
export const CLOSE_NEW_WORKFORCE_MEMBER_DIALOG =
  'CLOSE_NEW_WORKFORCE_MEMBER_DIALOG';
export const WORKFORCE_VALIDATION_ERROR = 'WORKFORCE_VALIDATION_ERROR';
export const WORKFORCE_REGISTRATION_ERROR = 'WORKFORCE_REGISTRATION_ERROR';
export const ADD_NEW_WORKFORCE_MEMBER = 'ADD_NEW_WORKFORCE_MEMBER';
export const NEW_WORKFORCE_MEMBER_ADDED = 'NEW_WORKFORCE_MEMBER_ADDED';

const newGroupFieldChange = (prop, value, isValid) => ({
  type: NEW_WORKFORCEM_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

export const openWorkforceMemberDialog = () => ({
  type: OPEN_NEW_WORKFORCE_MEMBER_DIALOG,
});

export const closeMemberGroupDialog = () => ({
  type: CLOSE_NEW_WORKFORCE_MEMBER_DIALOG,
});

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;

  return dispatch(newGroupFieldChange(prop, value, isValid));
};

export const registrationError = err => ({
  type: WORKFORCE_REGISTRATION_ERROR,
  err,
});

export const createAccount = (firstName, lastName, email, password, appName) =>
  new Promise((resolve, reject) => {
    const { currentUser } = firebase.auth();
    return currentUser.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);
      return fetch(`${process.env.REACT_APP_WORKFORCE_API}/create`, {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
          parentOwner: currentUser.uid,
          appName,
        }),
        headers,
      })
        .then(res => {
          res.json().then(data => {
            resolve(data);
          });
        })
        .catch(reject);
    });
  });

export const addGroup = appName => (dispatch, getState) => {
  const {
    firstName,
    lastName,
    email,
    password,
  } = getState().auth.workforceMembers.listPage.createModal;
  dispatch({ type: ADD_NEW_WORKFORCE_MEMBER });

  return createAccount(
    firstName.value,
    lastName.value,
    email.value,
    password.value,
    appName,
  )
    .then(res => {
      if (!res.uid) {
        dispatch(registrationError(res));
      } else {
        dispatch({
          type: NEW_WORKFORCE_MEMBER_ADDED,
          key: res.uid,
          data: {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
          },
        });
        dispatch(openSnackbar(WORKFORCE_CREATED));
      }
    })
    .catch(error => dispatch(registrationError(error)));
};
