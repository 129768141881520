import React, { Fragment } from 'react';
import ColumnImageLeftImage from './images/columnImageLeft.png';
import './styles.css';

const ColumnImageLeft = () => {
  return (
    <Fragment>
      <img
        className="imageWrapper"
        src={ColumnImageLeftImage}
        alt="Column Left"
      />
    </Fragment>
  );
};

export default ColumnImageLeft;
