import {
  NEW_GROUP_FIELD_CHANGE,
  NEW_GROUP_TYPE_CHANGE,
  OPEN_NEW_GROUP_FIELD_DIALOG,
  CLOSE_NEW_GROUP_FIELD_DIALOG,
  GROUP_ASSIGNMENT_ERROR,
  ADD_NEW_GROUP_FIELD,
  MEMBER_GROUP_ASSIGNED,
} from './actions';

const initialState = {
  pending: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  type: {
    value: '',
    isValid: null,
  },
  fieldsData: {},
};

const newGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_NEW_GROUP_FIELD_DIALOG:
      return {
        ...state,
        open: true,
      };

    case CLOSE_NEW_GROUP_FIELD_DIALOG:
      return {
        ...state,
        open: false,
      };

    case NEW_GROUP_FIELD_CHANGE:
      return {
        ...state,
        fieldsData: {
          ...state.fieldsData,
          [action.id]: {
            value: action.value,
            isValid: action.isValid,
            prop: action.prop,
            additionalData: action.latlong ? action.latlong : null,
          },
        },
      };

    case GROUP_ASSIGNMENT_ERROR:
      return {
        ...state,
        error: action.err,
        pending: false,
      };

    case NEW_GROUP_TYPE_CHANGE:
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
        fieldsData: action.defaultFieldValues,
      };

    case ADD_NEW_GROUP_FIELD:
      return {
        ...state,
        pending: true,
      };

    case MEMBER_GROUP_ASSIGNED:
      return initialState;

    default:
      return state;
  }
};

export default newGroupReducer;
