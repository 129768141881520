import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Trans, withTranslation } from 'react-i18next';

import ResultsTable from '../../../../../../app/components/resultsTable';
import CreateGroup from './modules/createGroup';
import TableCheckbox from '../../../../components/tableCheckbox';
import TryAgain from '../../../../../../app/components/tryAgain';
import Header from '../../../../components/header';
import ActionMenu from '../../../../../../app/components/actionMenu';

class Listing extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    chunks: PropTypes.object,
    getTypes: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func.isRequired,
    onSelectAllTypes: PropTypes.func.isRequired,
    onDeselectAllTypes: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    onFilterTypeData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    filter: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
    chunks: {},
    filteredChunks: {},
    items: [],
    query: '',
    selected: [],
    selectAll: false,
  };

  getTitle = (selected, staff) => (
    <span>
      <Trans>Workforce Groups:</Trans>{' '}
      {selected.length
        ? `${selected.length} / ${Object.keys(staff).length}`
        : Object.keys(staff).length}
    </span>
  );

  render() {
    const {
      pending,
      items,
      error,
      getTypes,
      chunks,
      onSelect,
      filteredChunks,
      onFilterTypeData,
      onSelectAllTypes,
      onDeselectAllTypes,
      selected,
      onDeleteSelected,
      history,
      match,
      t,
    } = this.props;

    const body = error ? (
      <TryAgain
        message={t('Oops, something went wrong...')}
        onRetry={getTypes}
      />
    ) : (
      <ResultsTable
        items={items}
        chunks={filteredChunks}
        error={Object.keys(filteredChunks).length === 0 ? 'yes' : null}
        pending={pending}
        loading={pending}
        emptyMessage={t('Looks like no workforce groups have added yet')}
        onRowTouchTap={fieldId =>
          history.push(`/${match.params.appname}/members/types/${fieldId}`)
        }
        columns={[
          {
            id: 'name',
            header: t('Name'),
            classNames: 'col-xs-8',
          },
          {
            id: 'createdAt',
            header: t('Date Created'),
            classNames: 'col-xs-3',
            containerElement: ({ value }) => (
              <span>{value && moment(value).format('DD/MM/YYYY')}</span>
            ),
          },
          {
            id: 'selected',
            header: '',
            classNames: 'col-xs-1 selected',
            containerElement: ({ value, rowId }) =>
              rowId !== 'default' && (
                <div>
                  <TableCheckbox
                    value={value}
                    onSelect={() => onSelect(rowId)}
                  />
                </div>
              ),
          },
        ]}
      />
    );

    return (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <Header
          {...this.props}
          title={this.getTitle(selected, chunks)}
          onChange={onFilterTypeData}
          onSelectAllResponses={onSelectAllTypes}
          onDeselectAllResponses={onDeselectAllTypes}
          actionMenu={
            <ActionMenu
              selected={selected}
              onDeleteSelected={() => onDeleteSelected(selected, chunks)}
              text={t(
                'fields. This will also delete a field which is linked with any group or user',
              )}
            />
          }
        />
        {body}
        <CreateGroup />
      </section>
    );
  }
}

export default withTranslation()(Listing);
