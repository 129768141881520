import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import IconButton from 'material-ui/IconButton';
import { People, Security } from '@material-ui/icons';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { ShepherdTour } from 'react-shepherd';
import i18n from 'i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'shepherd.js/dist/css/shepherd.css';
import ViewItem from './components/viewItem';
import TabItem from './components/tabItem';
import NestedTabPage from './components/nestedTabPageItem';
import { stringToKey } from '../../../../../../utils/string';
import DraggableListWrapper from '../../../../components/draggableListWrapper';
import Sidebar, { SidebarItem } from '../../../../../../app/components/sidebar';
import {
  AppIcon,
  AppDomain,
  CustomDomain,
  SplashScreen,
  AppMenu,
  Pages,
  Pencil,
  Paypal,
  Back,
  Create,
} from '../../../../components/icons';
import ViewApp from '../../../../../../app/components/buttons/viewApp/';
import { PLANS_WITH_ADMIN_ACCESS } from '../../../../../../plans';
import Appcues from '../../../../../../app/modules/appcues/Appcues';

import '../../../../../../app/modules/appcues/styles.css';
import { STAFF_ADMIN, ADD_APP_ADMINS_APPS } from '../../../../../../config';

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};

class BuildMenu extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    /* the apps pages */
    childRoutes: PropTypes.array,
    removePage: PropTypes.func.isRequired,
    onRearrangePage: PropTypes.func.isRequired,
    onAddTab: PropTypes.func.isRequired,
    onAddPage: PropTypes.func.isRequired,
    duplicatePage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    childRoutes: [],
    duplicatePage: () => {},
  };

  state = {
    navigationOpts: [
      {
        label: this.props.t('App Name'),
        href: 'app_name',
        icon: <AppDomain />,
      },
      {
        label: this.props.t('Custom Domain'),
        href: 'custom_domain',
        icon: <CustomDomain />,
      },
      {
        label: this.props.t('App Icon Design'),
        href: 'app_icon_design',
        icon: <AppIcon />,
      },
      {
        label: this.props.t('App Splash Screen'),
        href: 'app_splash_screen',
        icon: <SplashScreen />,
      },
      {
        label: this.props.t('Navigation Menu'),
        href: 'navigation_menu',
        icon: <AppMenu />,
      },
      {
        label: this.props.t('Paypal Settings'),
        href: 'paypal_settings',
        icon: <Paypal />,
      },
      {
        label: this.props.t('Accessibility'),
        href: 'accessibility',
        icon: <AppMenu />,
      },
      {
        label: this.props.t('Privacy'),
        href: 'privacy',
        icon: <Security />,
      },
      {
        label: this.props.t('App Admins'),
        href: 'admins',
        icon: <People />,
      },
      {
        label: this.props.t('Custom Emails (App Admins)'),
        href: 'custom-emails-admins',
        icon: <People />,
      },
    ],
  };

  componentWillMount() {
    this.props.onAddIsPublishedListener();
  }

  componentWillUnmount() {
    this.props.onRemoveIsPublishedListener();
  }
  componentDidMount() {
    this.props.getAppAdmins();
    this.props.checkAppcuesVisibleStatus();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.hash === '#?refer' &&
      prevProps.location.hash !== this.props.location.hash
    ) {
      const referBtn = document.getElementsByName('beezer_refer_submit');
      referBtn[0].click();
      this.props.onAccountSettingsTouchTap();
      this.props.setActiveAccountTab(5);
    }
  }

  isActive = key =>
    key === queryString.parse(this.props.location.search).page ||
    key === queryString.parse(this.props.location.search).parentPage;

  handleCreateClick = e => {
    e.preventDefault();

    this.props.onAddPage();
  };

  handleBackClick = e => {
    e.preventDefault();

    this.props.history.push(`/${this.props.match.params.appname}/editor/build`);
  };

  handleItemMove = (el, target, source, sibling) => {
    const newCollection = [];
    if (target) {
      const pageElements = target.childNodes.values();
      for (const elem of pageElements) {
        newCollection.push(elem.id);
      }
    }
    this.props.onRearrangePage({
      idToMove: el.id,
      moveTo: _get(sibling, 'id'),
      collection: this.props.childRoutes,
      newCollection,
    });
  };

  isPathRelevantToPages = () => {
    const relevantPaths = new RegExp(
      /^\/\w+\/editor\/build\/(pages|list|edit|item|page_settings)/,
    );

    return relevantPaths.test(this.props.location.pathname);
  };

  pagetItemProperties = (page, index) => {
    const { appname } = this.props.match.params;
    const nestedPages = [];
    const { key, type, tabs, hidden, hasUnavailaleComponent } = page;
    const search =
      type === 'TabView' || type === 'NestedTabPage'
        ? `page=${key}&tab=${0}`
        : `page=${key}`;
    const label = page.title;
    if (this.props.childRoutes.length > 0) {
      this.props.childRoutes.forEach(route => {
        route.parentPageKey === key &&
          nestedPages.push({
            ...route,
            href: {
              pathname: `/${appname}/editor/build/list`,
              search: `page=${route.key}&parentPage=${route.parentPageKey}`,
            },
          });
      });
    }
    const onDelete =
      this.props.childRoutes.length > 1
        ? () => this.props.removePage(key)
        : null;
    const onDuplicate = () => this.props.duplicatePage(key);

    return {
      id: key,
      key,
      index,
      label,
      type,
      tabs,
      nestedPages,
      hidden,
      active: this.isActive(key),
      onDelete,
      onDuplicate,
      onAddTab: this.props.onAddTab,
      hasUnavailaleComponent,
      href: {
        pathname: `/${appname}/editor/build/list`,
        search,
      },
      pages: this.props.childRoutes,
    };
  };

  renderPageItem = (page, index) => {
    switch (page.type) {
      case 'NestedTabPage':
        return <NestedTabPage {...this.pagetItemProperties(page, index)} />;
      case 'TabView':
        return <TabItem {...this.pagetItemProperties(page, index)} />;

      case 'View':
        return <ViewItem {...this.pagetItemProperties(page, index)} />;

      case 'Popup':
        return <ViewItem {...this.pagetItemProperties(page, index)} />;

      default:
        return null;
    }
  };

  renderPages = () => {
    const pages = this.props.childRoutes.map(this.renderPageItem);
    // return pages
    return (
      <DraggableListWrapper
        items={pages}
        options={{
          moves: (el, container, handle, sibling) => {
            if (handle) {
              return (
                !el.classList.contains('nested-pages-container') &&
                !(
                  handle.parentElement.classList.contains('nested-page') ||
                  handle.classList.contains('nested-page')
                )
              );
            }
            return true;
          },
        }}
        onPositionChange={this.handleItemMove}
      />
    );
  };

  renderMenuItems = () => {
    const { match, t } = this.props;

    return [
      <SidebarItem
        key="design"
        index={2}
        label={t('Design')}
        href={`/${match.params.appname}/editor/build/design`}
        icon={<Pencil />}
        active={this.isActive('design')}
      />,
      ...this.renderNavigationsItems(),
    ];
  };

  renderNavigationsItems = () => {
    const { t } = this.props;
    const { appname } = this.props.match.params;
    const { navigationOpts } = this.state;
    if (this.props.isAppAdmin || this.props.isSubAdminWithMulipleApps) {
      const customDomainIndex = navigationOpts.findIndex(
        elem => elem.label === t('Custom Domain'),
      );
      const appAdminIndex = navigationOpts.findIndex(
        elem => elem.label === t('App Admins'),
      );

      if (customDomainIndex >= 0) {
        navigationOpts.splice(customDomainIndex, 1);
      }

      if (appAdminIndex === 7) {
        navigationOpts.splice(appAdminIndex, 1);
      }
    }
    if (
      (this.props.app && !this.props.app.appContext.subscription) ||
      (this.props.plan &&
        !PLANS_WITH_ADMIN_ACCESS.includes(this.props.plan) &&
        !ADD_APP_ADMINS_APPS.includes(appname) &&
        this.props.account !== '-MyRhUIHAiekYsydTBdC')
    ) {
      const appAdminIndex = navigationOpts.findIndex(
        option => option.label === t('App Admins'),
      );
      if (appAdminIndex >= 0) {
        navigationOpts.splice(appAdminIndex, 2);
      }
    }

    return _map(navigationOpts, item => {
      const key = stringToKey(item.href);

      return (
        <SidebarItem
          key={key}
          label={item.label}
          href={`/${appname}/editor/build/${key}`}
          active={this.isActive(key)}
          icon={item.icon}
        />
      );
    });
  };

  render() {
    const {
      match,
      submitAppcuesForms,
      history,
      openReferalModal,
      appcuesVisibilityStatus,
      role,
      isAppAdmin,
      isSubAdminWithMulipleApps,
      submitAppcuesReferFriend,
      t,
    } = this.props;
    const { appname } = match.params;
    const isPathRelevant = this.isPathRelevantToPages();
    const appTourSteps = [
      {
        id: 'welcome',
        title: t('Welcome'),
        text: `
          <div class="flow-1-container">
          <h3>
            ${t(
              'This is your Beezer platform – your one-stop-shop for everything related to your app.',
            )}
          </h3>
          <p>
            ${t(
              'Take a few moments to learn how to use our features and functionalities. We’ll cover:',
            )}
          </p>
          <div class="two-list-container">
          <ul>
            <li> ${t(
              'How to build your app with plenty of bells and whistles',
            )}</li>
            <li> ${t('Publishing your app and updating it at will')}</li>
            <li> ${t('How to distribute your app directly to your users')}</li>
          </ul>
          <ul>
            <li>${t(
              'How to engage your users with push notifications and SMS',
            )}</li>
            <li> ${t('Enabling member log-in for your users')}</li>
            <li>${t('Analytics and how to learn about your users')}</li>
          </ul>
        </div>
        </div>
        
      `,

        classes: 'shepherd shepherd-welcome',
        buttons: [
          {
            type: 'cancel',
            classes: 'shepherd-button-secondary',
            text: t('Exit'),
          },
          {
            type: 'next',
            text: t('Next'),
          },
        ],
      },
      {
        id: 'installation',
        title: t('Features'),
        text: `<div className="appcues-normal-flow-container">
                <h3>${t('Build your app')}</h3>
                <p>${t(
                  'Use your Build section to build and design your app.',
                )} </p>
           </div>
         `,
        attachTo: {
          element:
            '#sidebar > div.main-menu > div:nth-child(1) > div > a:nth-child(1) > div',
          on: 'right',
        },
        buttons: [
          {
            type: 'back',
            classes: 'shepherd-button-secondary',
            text: t('Back'),
          },
          {
            type: 'next',
            text: t('Next'),
          },
        ],
      },
      {
        id: 'usage',
        title: t('Features'),
        text: ` <div className="appcues-normal-flow-container">
        <h3>${t('Add Pages')}</h3>
        <p>
          ${t(
            'Your pages are the skeleton of your app. Add a new page with the plus button in the top right of the second column, or edit the pages provided by your template by clicking on the page you want to edit.',
          )}
        </p>
      </div>`,
        attachTo: {
          element:
            '#sidebar > div.sidebar.sidebar-small.animate > div > div > div > a.menu-item.build-pages-item > div ',
          on: 'right',
        },

        buttons: [
          {
            type: 'back',
            classes: 'shepherd-button-secondary',
            text: t('Back'),
          },
          {
            type: 'next',
            text: t('Next'),
          },
        ],
      },
      {
        id: 'centered-example',
        title: t('Add Components'),
        text: ` <div className="appcues-normal-flow-container">
        <h3>${t('Add components to your page')}</h3>
        <p>
          ${t(
            'Components are the flesh and blood of your app – they bring it to life. Add images, text, buttons, and links to other pages, plus much more, with components. Add a new component with the plus button in the top right of the third column, or edit the components provided by your template by clicking on the component you want to edit. For detailed guidance and How-Tos for each component, go to our',
          )} <a href="https://beezer.zendesk.com/hc/en-us" target="_blank">${t(
          'help site',
        )}</a>.
        </p>
      </div>`,
        attachTo: {
          element:
            '#main > div > div.sidebar.sidebar-large.animate > div:nth-child(1) > div > div',
          on: 'right',
        },
        beforeShowPromise: () =>
          new Promise(resolve => {
            history.push(`/${appname}/editor/build/list?page=homepage`);

            setTimeout(() => {
              resolve();
            }, 500);
          }),
        buttons: [
          {
            type: 'back',
            classes: 'shepherd-button-secondary',
            text: t('Back'),
          },
          {
            type: 'next',
            text: t('Next'),
          },
        ],
      },
      {
        id: 'followup',
        title: t('Features'),
        beforeShowPromise: () =>
          new Promise(resolve => {
            history.push(`/${appname}/editor/build/design`);
            setTimeout(() => {
              resolve();
            }, 500);
          }),
        text: `<div className="appcues-normal-flow-container">
        <h3>${t('Choose your look & feel')}</h3>
        <p>
          ${t(
            'The Design section, and all the sections below it, are about the overall look and feel of your app. Choose your colours, the name and URL for your app, the icon that shows on your users’ homescreen, and the design of your splash screen (think – loading screen) and navigation menu.',
          )}
        </p>
      </div>`,
        attachTo: {
          element:
            '#sidebar > div.sidebar.sidebar-small.animate > div > div > div > a:nth-child(4) > div',
          on: 'right',
        },
        scrollTo: true,
        buttons: [
          {
            type: 'back',
            classes: 'shepherd-button-secondary',
            text: t('Back'),
          },
          {
            type: 'next',
            text: t('Next'),
          },
        ],
      },
      {
        id: 'publish',
        title: t('Publish'),
        text: `<div className="appcues-normal-flow-container">
        <h3>${t('Publish your app')}</h3>
        <p>
          ${t(
            'Built something you like? Click on this Publish button and your app will be out there in the world, in an instant. Don’t worry if you’re still working on your app and want to update it – you can hit this button and republish with your new changes as often as you want, anytime you want. The changes will be instantly available to your customers.',
          )}
        </p>
      </div>`,
        attachTo: {
          element:
            '#main > div > div > div:nth-child(1) > div > div > div.flex-row.flex-align-center > button',
          on: 'bottom',
        },
        scrollTo: true,
        buttons: [
          {
            type: 'back',
            classes: 'shepherd-button-secondary',
            text: t('Back'),
          },
          {
            type: 'next',
            text: t('Next'),
          },
        ],
      },
      {
        id: 'Distribute',
        title: t('Distribute'),
        beforeShowPromise: () =>
          new Promise(resolve => {
            history.push(`/${appname}/distribute/contacts`);
            setTimeout(() => {
              resolve();
            }, 500);
          }),
        text: ` <div className="appcues-normal-flow-container">
        <h3>${t('Distribute your app')}</h3>
        <p>
         ${t(
           'One of the many great things about a Beezer-built app is that it’s a Progressive Web App – which means you don’t need the app stores. You can get it to your users yourself, directly, with a simple URL. Use this section to distribute your app via email, SMS, whatsapp, social media, a clickable widget that sits on a website, or even on print media with a QR code.',
         )}
        </p>
      </div>`,
        attachTo: {
          element:
            '#sidebar > div.main-menu > div:nth-child(1) > div > a:nth-child(2) > div',
          on: 'right',
        },
        scrollTo: true,
        buttons: [
          {
            type: 'back',
            classes: 'shepherd-button-secondary',
            text: t('Back'),
          },
          {
            type: 'next',
            text: t('Next'),
          },
        ],
      },
      {
        id: 'AddContacts',
        title: t('Add Contacts'),
        text: `<div className="appcues-normal-flow-container">
        <h3>${t('Add your contacts')}</h3>
        <p>
         ${t(
           'This section is where you add the contacts that you want to send your app to via SMS or email. Click on the blue plus button in the top right corner to start adding. You can add contacts one at a time, or import a big list of contacts by uploading a CSV file.',
         )}
        </p>
      </div>`,
        attachTo: {
          element:
            '#main > div > div.page-header > div > div.flex-row.flex-align-center > div:nth-child(1) > div > div > span > span > div > button > div',
          on: 'bottom',
        },
        scrollTo: true,
        buttons: [
          {
            type: 'back',
            classes: 'shepherd-button-secondary',
            text: t('Back'),
          },
          {
            type: 'next',
            text: t('Next'),
          },
        ],
      },
      {
        id: 'share',
        title: t('Share with your contacts'),
        text: `<div className="appcues-normal-flow-container">
        <h3>${t('Distribute directly via whatsapp')}</h3>
        <p>
          ${t(
            'Now that you’ve got your list of contacts, you can send your app to them via SMS, email, or both – depending on what information of theirs you have. You can view the history of your shares in the Share History section. And even if you have no contacts, you can always share your app with the world via social media, a website widget, or a QR code on pamphlets or posters.',
          )}
        </p>
      </div>`,
        attachTo: {
          element:
            '#sidebar > div.sidebar.sidebar-small.animate > div > div > div > a:nth-child(3) > div',
          on: 'right',
        },
        scrollTo: true,
        buttons: [
          {
            type: 'back',
            classes: 'shepherd-button-secondary',
            text: t('Back'),
          },
          {
            type: 'next',
            text: t('Next'),
          },
        ],
      },
      {
        id: 'engage',
        title: t('Engage'),
        beforeShowPromise: () =>
          new Promise(resolve => {
            history.push(`/${appname}/engage/push`);
            setTimeout(() => {
              resolve();
            }, 500);
          }),
        text: ` <div className="appcues-normal-flow-container">
       <h3>${t('Engage with your users')}</h3>
        <p>
          ${t(
            'In this section, you can send push notifications to all your app users or to select groups. You can also send SMS messages to any contacts for whom you have a phone number.',
          )} ${t(
          'Check out our',
        )} <a href="https://beezer.zendesk.com/hc/en-us" target="_blank">${t(
          'help site',
        )}</a> ${t(
          'for detailed instructions on how to create, schedule, and send notifications.',
        )}
        </p>
      </div>`,
        attachTo: {
          element:
            '#sidebar > div.main-menu > div:nth-child(1) > div > a:nth-child(3) > div',
          on: 'right',
        },
        scrollTo: true,
        buttons: [
          {
            type: 'back',
            classes: 'shepherd-button-secondary',
            text: t('Back'),
          },
          {
            type: 'next',
            text: t('Next'),
          },
        ],
      },
      {
        id: 'analytics',
        title: t('Analytics'),
        beforeShowPromise: () =>
          new Promise(resolve => {
            history.push(`/${appname}/analytics/app`);
            setTimeout(() => {
              resolve();
            }, 500);
          }),
        text: ` <div className="appcues-normal-flow-container">
       <h3> ${t('Analytics')}</h3>
        <p>
          ${t(
            'In this section, you get to learn about your app’s performance. Toggle between weekly and monthly views in the top bar, and select the time-frame which you want to see on the left of your view count. You’ll be able to track views of your app, installs (think – who actually added it to their homescreen), and shares (how many customers are passing it on to their friends?)',
          )}
        </p>
      </div>`,
        attachTo: {
          element:
            '#sidebar > div.main-menu > div:nth-child(1) > div > a:nth-child(4) > div',
          on: 'right',
        },
        scrollTo: true,
        buttons: [
          {
            type: 'back',
            classes: 'shepherd-button-secondary',
            text: t('Back'),
          },
          {
            type: 'next',
            text: t('Next'),
          },
        ],
      },
      {
        id: 'member-login',
        title: t('Member Login'),
        beforeShowPromise: () =>
          new Promise(resolve => {
            history.push(`/${appname}/members/groups`);
            setTimeout(() => {
              resolve();
            }, 500);
          }),
        text: ` <div className="appcues-normal-flow-container">
      <h3> ${t('Member Login')}</h3>
        <p>
          ${t(
            'The Member Login feature allows you to provide access to specific pages for your app users. Your app users can sign-up to your app with their e-mail address. Once registered, they will be able to sign in to access exclusive restricted content/pages which are only available for registered members of your app.',
          )}
        </p>
      </div>`,
        attachTo: {
          element:
            '#sidebar > div.main-menu > div:nth-child(1) > div > a:nth-child(5) > div',
          on: 'right',
        },
        scrollTo: true,
        buttons: [
          {
            type: 'back',
            classes: 'shepherd-button-secondary',
            text: t('Back'),
          },
          {
            type: 'next',
            text: t('Next'),
          },
        ],
      },
      {
        id: 'business-tools',
        title: t('Business Tools'),
        beforeShowPromise: () =>
          new Promise(resolve => {
            history.push(`/${appname}/ecommerce/features`);
            setTimeout(() => {
              resolve();
            }, 500);
          }),
        text: ` <div className="appcues-normal-flow-container">
      <h3> ${t('Business Tools')}</h3>
        <p>
          ${t(
            'The Business Tools section allows you to configure the components which are used to monetize your apps - Food Orders, Booking and Marketplace. You can enable these business tools and start earning using your app.',
          )}
        </p>
      </div>`,
        attachTo: {
          element:
            '#sidebar > div.main-menu > div:nth-child(1) > div > a:nth-child(6) > div',
          on: 'right',
        },
        scrollTo: true,
        buttons: [
          {
            type: 'back',
            classes: 'shepherd-button-secondary',
            text: t('Back'),
          },
          {
            type: 'next',
            text: t('Done'),
          },
        ],
      },
    ];

    const referFriendSteps = [
      {
        id: 'refer',
        title: t('Referral'),
        // beforeShowPromise: () => {
        //   return new Promise(resolve => {
        //     openReferalModal();
        //     setTimeout(() => {
        //       const referralTabBtn = document.querySelector(
        //         'body > div:nth-child(16) > div > div.modal-content > div > div.account-tabs > div > div:nth-child(1) > button:nth-child(6)',
        //       );
        //       if (referralTabBtn) {
        //         referralTabBtn.click();
        //       }
        //       resolve();
        //     }, 1000);
        //   });
        // },
        text: `
          <div class="flow-1-container">
          <h3>
           <a href="#?refer" style="text-decoration:underline;">Refer a friend</a> and get cash back!.
          </h3>
          <button name="beezer_refer_submit" class="appcues-submit-btn">${t(
            'Exit',
          )}</button>

        </div>
        
      `,
        classes: 'shepherd shepherd-welcome',
      },
    ];

    const beezerSurveyForm1 = [
      {
        id: 'refer',
        title: t('Where did you find about beezer?'),

        text: `
          <div class="flow-2-container">
          <h3>
           ${t('Where did you hear about us?')}
          </h3>
        <div>
          <div>
          <input
            type="radio"
            id="google_search"
            name="beezer_heard_from"
            value="google_search"
          />
          <label for="google_search">${t('Google Search')}</label>
        </div>
        <div>
          
          <input
            type="radio"
            id="google_ads"
            name="beezer_heard_from"
            value="google_ads"
          />
         <label for="google_ads">${t('Google Ads')}</label>
        </div>
        <div>
         
          <input
            type="radio"
            id="dragons_den"
            name="beezer_heard_from"
            value="dragons_den"
          />
           <label for="dragons_den">${t("Dragon's Den")}</label>
        </div>

        <div>
          <input
            type="radio"
            id="linkedin"
            name="beezer_heard_from"
            value="linkedin"
          />
           <label for="linkedin">${t('Linkedin')}</label>
        </div>

        <div>
          <input
            type="radio"
            id="facebook"
            name="beezer_heard_from"
            value="facebook"
          />
          <label for="facebook">${t('Facebook')}</label>
        </div>

        <div>
          <input
            type="radio"
            id="other"
            name="beezer_heard_from"
            value="other"
          />
          <label for="other">${t('Other')}</label>
        </div>

        <input type="text" name="beezer_survey_form_comment" placeholder="${t(
          'Leave a comment',
        )}"/>

        <button name="beezer_survey_form_submit" class="appcues-submit-btn">${t(
          'Submit',
        )}</button>
        
        </div>


        </div>
        
      `,
        classes: 'shepherd shepherd-welcome',
      },
    ];

    const ratingForm1 = [
      {
        id: 'rating',
        title: t('Rate Beezer'),
        text: `
          <div class="flow-3-container">
          <h3>${t('Do you find the Beezer platform easy to use?')}</h3>

           <div>
          <div>
            <input type="radio" id="1" name="beezer_cx_rating_input" value="1" />
            <label for="1" name="beezer_cx_rating_label">${t('1')}</label>
          </div>
          <div>
            <input type="radio" id="2" name="beezer_cx_rating_input" value="2" />
            <label for="2" name="beezer_cx_rating_label">${t('2')}</label>
          </div>
          <div>
            <input type="radio" id="3" name="beezer_cx_rating_input" value="3" />
            <label for="3" name="beezer_cx_rating_label">${t('3')}</label>
          </div>
          <div>
            <input type="radio" id="4" name="beezer_cx_rating_input" value="4" />
            <label for="4" name="beezer_cx_rating_label">${t('4')}</label>
          </div>
          <div>
            <input type="radio" id="5" name="beezer_cx_rating_input" value="5" />
            <label for="5" name="beezer_cx_rating_label">${t('5')}</label>
          </div>
          <div>
            <input type="radio" id="6" name="beezer_cx_rating_input" value="6" />
            <label for="6" name="beezer_cx_rating_label">${t('6')}</label>
          </div>
          <div>
            <input type="radio" id="7" name="beezer_cx_rating_input" value="7" />
            <label for="7" name="beezer_cx_rating_label">${t('7')}</label>
          </div>
          <div>
            <input type="radio" id="8" name="beezer_cx_rating_input" value="8" />
            <label for="8" name="beezer_cx_rating_label">${t('8')}</label>
          </div>
          <div>
            <input type="radio" id="9" name="beezer_cx_rating_input" value="9" />
            <label for="9" name="beezer_cx_rating_label">${t('9')}</label>
          </div>
          <div>
            <input type="radio" id="10" name="beezer_cx_rating_input" value="10" />
            <label for="10" name="beezer_cx_rating_label">${t('10')}</label>
          </div>
        </div>
        <button name="beezer_rating_form_submit" class="appcues-submit-btn">${t(
          'Submit',
        )}</button>
        </div>
        
      `,
        classes: 'shepherd shepherd-welcome',
      },
    ];

    const createPageButton = (
      <IconButton tooltip={t('New page')} onClick={this.handleCreateClick}>
        <Create />
      </IconButton>
    );

    const backButton = (
      <IconButton tooltip={t('Back')} onClick={this.handleBackClick}>
        <Back />
      </IconButton>
    );

    return (
      <Sidebar animate>
        <ViewApp {...this.props} />
        {appcuesVisibilityStatus &&
          appcuesVisibilityStatus.showBeezerAppTour &&
          !appcuesVisibilityStatus.recentlyShowedAppcues && (
            <ShepherdTour
              steps={appTourSteps}
              tourOptions={{ ...tourOptions, confirmCancel: true }}
            >
              <Appcues start />
            </ShepherdTour>
          )}
        {!isAppAdmin &&
          !isSubAdminWithMulipleApps &&
          role !== STAFF_ADMIN &&
          appcuesVisibilityStatus &&
          appcuesVisibilityStatus.showBeezerSurveyForm1 &&
          !appcuesVisibilityStatus.recentlyShowedAppcues && (
            <ShepherdTour steps={beezerSurveyForm1} tourOptions={tourOptions}>
              <Appcues
                start
                type={'surveyForm'}
                onSubmit={submitAppcuesForms}
              />
            </ShepherdTour>
          )}
        {!isAppAdmin &&
          !isSubAdminWithMulipleApps &&
          role !== STAFF_ADMIN &&
          appcuesVisibilityStatus &&
          appcuesVisibilityStatus.showBeezerRatingForm1 &&
          !appcuesVisibilityStatus.recentlyShowedAppcues && (
            <ShepherdTour steps={ratingForm1} tourOptions={tourOptions}>
              <Appcues
                start
                type={'ratingForm'}
                onSubmit={submitAppcuesForms}
              />
            </ShepherdTour>
          )}
        {!isAppAdmin &&
          !isSubAdminWithMulipleApps &&
          role !== STAFF_ADMIN &&
          appcuesVisibilityStatus &&
          appcuesVisibilityStatus.showBeezerReferFriend &&
          !appcuesVisibilityStatus.recentlyShowedAppcues && (
            <ShepherdTour steps={referFriendSteps} tourOptions={tourOptions}>
              <Appcues
                start
                type={'referFriend'}
                onSubmit={submitAppcuesReferFriend}
              />
            </ShepherdTour>
          )}
        <SidebarItem
          key="pages"
          index={1}
          label={t('Pages')}
          href={`/${match.params.appname}/editor/build/pages`}
          active={isPathRelevant}
          rightAction={createPageButton}
          leftAction={backButton}
          displayRightAction={isPathRelevant}
          displayLeftAction={isPathRelevant}
          icon={<Pages />}
          className="build-pages-item"
        />
        {isPathRelevant ? this.renderPages() : this.renderMenuItems()}
      </Sidebar>
    );
  }
}

export default withTranslation()(BuildMenu);
