import { connect } from 'react-redux';

import HalloweenOfferNotice from './HalloweenOfferNotice';
import { closeHalloweenOfferPopup, openHalloweenOfferPopup } from './actions';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  auth: state.account.auth,
  account: state.account.organisation,
  accountId: state.account.profile.account,
  showPopup: state.app.halloweenMsg.open || false,
  createdAt: state.account.organisation.created_at,
  appname: ownProps.match.params.appname,
});

const mapDisptachToProps = (dispatch, ownProps) => ({
  closeHalloweenOfferPopup: () => dispatch(closeHalloweenOfferPopup()),
  openHalloweenOfferPopup: () => dispatch(openHalloweenOfferPopup()),
});

export default withRouter(
  connect(mapStateToProps, mapDisptachToProps)(HalloweenOfferNotice),
);
