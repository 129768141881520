import { connect } from 'react-redux';

import Vote from './Vote';
import {
  getVoteData,
  watchVoteData,
  removeVoteDataWatcher,
  getAllVotes,
  selectResponse,
  selectAllResponses,
  deselectAllResponses,
  filterVoteData,
  favouriteSelected,
  deleteSelected,
  sortTableData,
} from './actions';
import {
  openVoteDetailDialog,
  openFormDetailDialog,
} from './voteDetails/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...ownProps.match.params,
  ...state.analytics.vote,
  ...state.analytics.vote.data,
  accountId: state.account.auth.profile.account,
  subscription:
    state.account.subscriptions.items[ownProps.match.params.appname] || {},
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getVoteData: () => dispatch(getVoteData(ownProps.match.params.appname)),
  watchVoteData: () => dispatch(watchVoteData(ownProps.match.params.appname)),
  removeVoteDataWatcher: () =>
    dispatch(removeVoteDataWatcher(ownProps.match.params.appname)),
  getAllVotes: () => dispatch(getAllVotes(ownProps.match.params.appname)),
  onSelect: key => dispatch(selectResponse(key)),
  onSelectAllResponses: () => dispatch(selectAllResponses()),
  onDeselectAllResponses: () => dispatch(deselectAllResponses()),
  onOpenVoteDetail: key => dispatch(openVoteDetailDialog(key)),
  onOpenFormDetail: key => dispatch(openFormDetailDialog(key)),
  onFilterVoteData: filter => dispatch(filterVoteData(filter)),
  onFavouriteSelected: selected =>
    dispatch(favouriteSelected(ownProps.match.params.appname, selected)),
  onDeleteSelected: selected =>
    dispatch(deleteSelected(ownProps.match.params.appname, selected)),
  onSort: cols => dispatch(sortTableData(cols)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Vote);
