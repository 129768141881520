import firebase from '../../../../../../firebase';
import { deleteUser } from '../admins/actions';

export const RECEIVED_APPS_DATA = 'RECEIVED_APPS_DATA';
export const LOADING_APPS_DATA = 'LOADING_APPS_DATA';
export const getAppsDetails = apps => async dispatch => {
  const appList = Object.keys(apps);
  const appsData = [];
  dispatch({ type: LOADING_APPS_DATA });
  for (const appname of appList) {
    const appSnapShot = await firebase
      .database()
      .ref(`apps/${appname}`)
      .once('value');
    const app = appSnapShot.val();
    const appData =
      app && app.draft
        ? {
            name: appname,
            title: app.draft.meta_data ? app.draft.meta_data.title : '',
            description: app.draft.meta_data
              ? app.draft.meta_data.description
              : '',
            logo: app.draft.meta_data ? app.draft.meta_data.logo : null,
            version: app.version,
            customDomain: app.custom_domain,
            last_published: app.last_published,
          }
        : {};
    Object.keys(appData).length > 0 && appsData.push(appData);
  }

  dispatch({
    type: RECEIVED_APPS_DATA,
    data: appsData,
  });
};

const replaceAppsInUserAccount = (accountId, apps) => {
  const accountRef = firebase.database().ref(`accounts/${accountId}`);
  accountRef.child(`apps`).set(apps);
};

export const deleteAppFromSubAdmins = (
  appAdmins,
  appname,
) => async dispatch => {
  dispatch({ type: LOADING_APPS_DATA });
  appAdmins.map(async admin => {
    const userRef = firebase.database().ref(`users/${admin}`);
    userRef.once('value', snapshot => {
      const data = snapshot.val();
      if ('admin_appname' in data && data.admin_appname === appname) {
        //delete Sub Admin with single app same as deleted app
        deleteUser(appname, admin).then(res => {
          userRef.set(null);
        });
      }

      if ('admin_appnames' in data && data.admin_appnames.includes(appname)) {
        data.admin_appnames = data.admin_appnames.filter(
          item => item !== appname,
        );

        if (data.lastVisited === appname) {
          data.lastVisited   = data.admin_appnames[0];
        }

        //convert app list to an object
        const apps = data.admin_appnames.reduce((obj, item) => {
          obj[item] = { title: item };
          return obj;
        }, {});

        //Replace apps in user's account
        replaceAppsInUserAccount(data.account, apps);

        if (data.admin_appnames.length === 0) {
          deleteUser(appname, admin).then(res => {
            userRef.set(null);
          });
        } else {
          userRef.set(data);
        }
      }
    });
  });
};

export const deleteApp = (appAdmins, appname) => async dispatch => {
  dispatch({ type: LOADING_APPS_DATA });
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  const uid = user.uid;
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('x-access-token', token);
  const result = fetch(`${process.env.REACT_APP_EDIT_APP_API}/delete_app`, {
    method: 'post',
    mode: 'cors',
    body: JSON.stringify({
      appname,
      uid,
      requestOrigin: 'platform',
    }),
    headers,
  });
  dispatch(deleteAppFromSubAdmins(appAdmins, appname));
};
