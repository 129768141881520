import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import { withRouter, BrowserRouter as Router } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import RenderToLayer from 'material-ui/internal/RenderToLayer';
import { SimpleToolbar } from '../../../../../../../app/modules/toolbar';
import TextField from '../../../../../../../app/components/textField';
import { store } from '../../../../../../../';
import { CircularProgress } from '../../../../../../../app/components/progress';
import { RaisedButton } from '../../../../../../../app/components/buttons';

import {
  duplicatePage,
  titleChange,
  closeDuplicatePageDialog,
} from './actions';
import themeStyles from '../../../../../../../_export.scss';

const styles = {
  buttonRoot: {
    margin: '12px',
    width: '200px',
  },
  buttonFlat: {
    margin: '12px',
    width: '150px',
    boxShadow: 'none',
  },
  CancelLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.secondaryTextColor,
    textTransform: 'capitalize',
  },
  NextLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.primaryTextColor,
    textTransform: 'capitalize',
  },
};

class DuplicatePage extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onTitleChange: PropTypes.func.isRequired,
  };

  renderLayer = () => {
    const { form, onCancel, onSubmit, onTitleChange, t } = this.props;

    const pendingIndicator = form.pending ? (
      <CircularProgress size={20} thickness={3} />
    ) : null;
    const displayError = form.error ? <p>{form.error}</p> : null;

    const buttonCancel =
      !form.pending && onCancel ? (
        <RaisedButton
          label={t('Cancel')}
          onClick={onCancel}
          disabled={form.pending}
          backgroundColor={themeStyles.mainWhite}
          disabledBackgroundColor={themeStyles.mainGrey}
          style={styles.buttonFlat}
          labelStyle={styles.CancelLabelStyle}
        />
      ) : null;

    const buttonContinue = !form.pending ? (
      <RaisedButton
        label={t('Submit')}
        onClick={onSubmit}
        disabled={form.pending}
        backgroundColor={themeStyles.primaryColor}
        disabledBackgroundColor={themeStyles.primaryColorLight}
        style={styles.buttonRoot}
        labelStyle={styles.NextLabelStyle}
      />
    ) : null;

    return (
      <Router>
        <div className="overlay create-page">
          <Provider store={store}>
            <SimpleToolbar onExit={onCancel} />
          </Provider>
          <div className={`create-content`}>
            <h2>
              <Trans>Duplicate Page</Trans>
            </h2>
            <div className="create-form">
              <div key="standardForm">
                <TextField
                  id="page_title"
                  {...form.title}
                  onChange={onTitleChange}
                  placeholder={t('Page Title')}
                />
              </div>
            </div>
            <div className="buttons-container flex-row">
              {buttonCancel}
              {pendingIndicator}
              {buttonContinue}
            </div>
            <div> {displayError} </div>
          </div>
        </div>
      </Router>
    );
  };

  render() {
    return (
      <RenderToLayer
        open={this.props.form.open}
        render={this.renderLayer}
        useLayerForClickAway={false}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  form: state.editor.build.create.duplicatePage,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () =>
    dispatch(duplicatePage(ownProps.match.params.appname, ownProps.history)),
  onCancel: () => dispatch(closeDuplicatePageDialog()),
  onTitleChange: title => dispatch(titleChange(title)),
});

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DuplicatePage)),
);
