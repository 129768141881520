import React from 'react';

const Text = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 19.5"
  >
    <title>slider</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect class="cls-1" y="9" width="27" height="1.5" />
        <rect class="cls-1" x="45" y="9" width="51" height="1.5" />
        <path
          class="cls-1"
          d="M36,19.5a9.75,9.75,0,1,1,9.75-9.75A9.76,9.76,0,0,1,36,19.5Zm0-18a8.25,8.25,0,1,0,8.25,8.25A8.26,8.26,0,0,0,36,1.5Z"
        />
      </g>
    </g>
  </svg>
);

export default Text;
