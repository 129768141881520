/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import Modal from '../components/Modal';

const TermsAndConditions = ({ open, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      title={t("Beezer's Terms and Conditions")}
      handleClose={handleClose}
    >
      <h2>
        <Trans>BACKGROUND</Trans>:
      </h2>
      <p>
        <Trans>
          This agreement applies as between you, the User of this Website and
          Beezer (PWA) LTD, a company registered in Scotland under registration
          number SC492158 whose registered office is at at Digital House, 7
          Palmerston Place Lane, EDINBURGH, EH12 5AE, trading as Beezer, the
          owner(s) of this Website. Your agreement to comply with and be bound
          by these Terms and Conditions is deemed to occur upon your first use
          of the Website. If you do not agree to be bound by these Terms and
          Conditions, you should stop using the Website immediately.
        </Trans>
      </p>
      <p>
        <Trans>
          No part of this Website is intended to constitute a contractual offer
          capable of acceptance. Your order constitutes a contractual offer and
          our acceptance of that offer is deemed to occur upon our sending a
          dispatch email to you indicating that your order has been fulfilled
          and is on its way to you.
        </Trans>
      </p>
      <h3>
        <Trans>1. Definitions and Interpretation</Trans>
      </h3>
      <p>
        <Trans>
          In this Agreement the following terms shall have the following
          meanings
        </Trans>
        :
      </p>
      <table className="definitions">
        <tbody>
          <tr>
            <td>
              “<Trans>Account</Trans>”
            </td>
            <td>
              <Trans>
                means collectively the personal/business information, Payment
                Information and credentials (user name and password) used by
                Users to access the Services;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>“Beezer”</td>
            <td>
              <Trans>means the said Beezer (PWA) LTD;</Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>Database</Trans>”
            </td>
            <td>
              <Trans>
                means the database stored on the Platform which contains inter
                alia User Data;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>Account</Trans>”
            </td>
            <td>
              <Trans>
                means collectively the personal/business information, Payment
                Information and credentials (user name and password) used by
                Users to access the Services;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>DPA</Trans>”
            </td>
            <td>
              <Trans>
                means the Data Protection Act 1998 and any modification,
                amendment or re-enactment thereof;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>End User</Trans>”
            </td>
            <td>
              <Trans>means any third party using a User App;</Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>End User Data</Trans>”
            </td>
            <td>
              <Trans>means data uploaded to a User App by and End User;</Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>End User Terms</Trans>”
            </td>
            <td>
              <Trans>means the terms specified in Clause 10;</Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>Payment Information</Trans>”
            </td>
            <td>
              <Trans>
                means any details required for the purchase of Services from
                this Website. This includes, but is not limited to, credit /
                debit card numbers, bank account numbers and sort codes;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>Personal Data</Trans>”
            </td>
            <td>
              <Trans>Has the meaning ascribed to it in the DPA;</Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>Platform</Trans>”
            </td>
            <td>
              <Trans>
                means the hardware and software environment in which the
                Services operate, which comprises one or more server computers
                (whether virtual or not), mirroring/duplicating/back-up and
                storage systems and relative hardware operating software,
                virtual machine software (where relevant), operating system
                software, database software, anti-virus and security software,
                switches, power supplies and telecommunications infrastructure;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>Purchase Information</Trans>”
            </td>
            <td>
              <Trans>
                means collectively any orders, invoices, receipts or similar
                that may be in hard copy or electronic form;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>Premises</Trans>”
            </td>
            <td>
              <Trans>
                means our place(s) of business located at Digital House, 7
                Palmerston Place Lane, Edinburgh, EH12 5AE.;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>Service(s)</Trans>”
            </td>
            <td>
              <Trans>
                means collectively any online facilities, tools, services or
                information that Beezer makes available through the Website
                either now or in the future;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>SMS</Trans>”
            </td>
            <td>
              <Trans>
                means Short Message Service which includes simple plain texts
                and multi media messages;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>SMS Service</Trans>”
            </td>
            <td>
              <Trans>
                means any text messaging and/or multi media messaging service
                supplied by Beezer from the Platform or via a third party
                operator;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>System</Trans>”
            </td>
            <td>
              <Trans>
                means any online communications infrastructure that Beezer makes
                available through the Website and/or the Platform either now or
                in the future and whether administered by Beezer or by a third
                party. This includes, the Services and also, but is not limited
                to, web-based email, message boards, SMS Services, live chat
                facilities and email links;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>User</Trans>” / “<Trans>Users</Trans>”
            </td>
            <td>
              <Trans>
                means you/any third party that accesses the Website and is not
                employed by Beezer and acting in the course of their employment;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>User Content</Trans>”
            </td>
            <td>
              <Trans>
                means any text, graphics, images, audio, video, software, data
                compilations and any other form of information capable of being
                stored in a computer that appears on, is uploaded to or forms
                part of a User App or the Services and has been uploaded by a
                User;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>User Data</Trans>”
            </td>
            <td>
              <Trans>
                means data created via a User App and stored in the Database
                which shall include End User Data;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>User App</Trans>”
            </td>
            <td>
              <Trans>
                means an App created by a User using the Services which shall
                contain User Content and shall be hosted on the Platform;
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>Website</Trans>”
            </td>
            <td>
              <Trans>
                means the website that you are currently using
                (http://www.beezer.com/) and any sub-domains of this site (e.g.
                subdomain. beezer.com) unless expressly excluded by their own
                terms and conditions.
              </Trans>
            </td>
          </tr>
          <tr>
            <td>
              “<Trans>We/Us/Our</Trans>”
            </td>
            <td>
              <Trans>means Beezer;</Trans>
            </td>
          </tr>
        </tbody>
      </table>
      <h3>
        <Trans>2. Business Customers</Trans>
      </h3>
      <p>
        <Trans>
          These Terms and Conditions apply to business customers and
          non-business customers. If you are a non-business customer please
          consult our consumer terms and conditions located in sections 11.8,
          21.6, 21.8, 23.6 and 28.2.
        </Trans>
      </p>
      <h3>
        <Trans>3. Intellectual Property</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            Subject to the exceptions in Clause 4 of these Terms and Conditions,
            all content included on the Website, including any material
            contained within User Apps that is not User Content, including, but
            not limited to, text, graphics, logos, icons, images, sound clips,
            video clips, data compilations, page layout, underlying code and
            software and the Database is the property of Beezer, or Our
            affiliates. By continuing to use the Website you acknowledge that
            such material is protected by applicable United Kingdom and
            international intellectual property and other laws.
          </Trans>
        </li>
        <li>
          <Trans>
            You may print, reproduce, copy, distribute, store or in any other
            fashion re-use Content from the Website as specified in Clause 5 of
            these Terms and Conditions and for personal or educational purposes
            only unless otherwise indicated on the Website or unless given Our
            express written permission to do so. Specifically, you agree that
          </Trans>
          :
          <ul>
            <li>
              <Trans>
                You will not systematically copy Content from the Website with a
                view to creating or compiling any form of comprehensive
                collection, compilation, directory or database unless given Our
                express written permission to do so.
              </Trans>
            </li>
          </ul>
        </li>
      </ul>
      <h3>
        <Trans>4. User App Intellectual Property</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            The intellectual property rights subsisting in the User Content of
            User Apps belong to the User to which that/those User App(s)
            belong(s) unless it is expressly stated otherwise.
          </Trans>
        </li>
        <li>
          <Trans>
            Where expressly indicated, certain Content available through User
            Apps and the intellectual property rights subsisting therein belongs
            to other parties.
          </Trans>
        </li>
        <li>
          <Trans>
            The Content described in this Clause 4, unless expressly stated to
            be so, is not covered by any permission granted by Clause 3 of these
            Terms and Conditions to use Content from the Website. The exceptions
            in Clause 5 continue to apply.
          </Trans>
        </li>
        <li>
          <Trans>
            For the avoidance of doubt, the Database shall not be considered
            User Content.
          </Trans>
        </li>
      </ul>
      <h3>
        <Trans>5. Fair Use of Intellectual Property</Trans>
      </h3>
      <p>
        <Trans>
          Material from the Website may be re-used without written permission
          where any of the exceptions detailed in Chapter III of the Copyright
          Designs and Patents Act 1988 apply.
        </Trans>
      </p>
      <h3>
        <Trans>6. Third Party Intellectual Property</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            Unless otherwise expressly indicated, all Intellectual Property
            rights including, but not limited to, Copyright and Trademarks, in
            product images, films, music and other media and descriptions and
            designs belong to the manufacturers or distributors of such products
            as may be applicable.
          </Trans>
        </li>
        <li>
          <Trans>
            Subject to Clause 5 you may not reproduce, copy, distribute, store
            or in any other fashion re-use such material unless otherwise
            indicated on the Website or unless given express written permission
            to do so by the relevant manufacturer or supplier.
          </Trans>
        </li>
      </ul>
      <h3>
        <Trans>7. Links to Other Websites</Trans>
      </h3>
      <p>
        <Trans>
          This Website may contain links to other sites. Unless expressly
          stated, these sites are not under the control of Beezer or that of our
          affiliates. We assume no responsibility for the content of such
          websites and disclaim liability for any and all forms of loss or
          damage arising out of the use of them. The inclusion of a link to
          another site on this Website does not imply any endorsement of the
          sites themselves or of those in control of them.
        </Trans>
      </p>
      <h3>
        <Trans>8. Links to this Website</Trans>
      </h3>
      <p>
        <Trans>
          Those wishing to place a link to this Website on other sites may do so
          only to the home page of the Website without prior permission. Deep
          linking (i.e. links to specific pages within the site) requires the
          express permission of Beezer. To find out more please contact us by
          email at
        </Trans>
        <a href="mailto:sales@beezer.com" style={{ color: '#261ED5' }}>
          &nbsp;sales@beezer.com
        </a>{' '}
        <Trans>or Phone</Trans>:{' '}
        <a href="tel:+447000123789" style={{ color: '#261ED5' }}>
          07000 123 789.
        </a>
      </p>
      <h3>
        <Trans>9. User Apps</Trans>
      </h3>
      <ul className="sub-clause">
        <li>
          <span className="sub-clause-item">
            <span>9.1.</span>{' '}
            <span>
              <Trans>
                When creating a User App using the Services; you should do so in
                accordance with the following rules
              </Trans>
              :
            </span>
          </span>
          <ul>
            <li>
              <Trans>You must not use obscene or vulgar language;</Trans>
            </li>
            <li>
              <Trans>
                Your User App may not contain any material that is unlawful or
                otherwise objectionable (including that which may be in breach
                of rules, regulations or legislation in force in the United
                Kingdom or any other jurisdiction in which your User App can be
                lawfully accessed. This does not extend to material which may be
                automatically blocked in certain jurisdictions but that is
                lawful in your home country);
              </Trans>
            </li>
            <li>
              <Trans>
                Your User App may not contain any material that is intended to
                promote or incite violence or any other unlawful conduct against
                any group, individual or animal. This includes, but is not
                limited to, the provision of instructions on how to assemble
                weapons of any kind, bombs, grenades or other explosive devices;
              </Trans>
            </li>
            <li>
              <Trans>
                Your User App must be honest and fair, should not make any
                unsubstantiated or unsupportable claims and should, in
                particular, not be used to sell or offer for sale any item, good
                or service that
              </Trans>
              :
              <ul>
                <li>
                  <Trans>
                    Violates any applicable rules, regulations or legislation;
                  </Trans>
                </li>
                <li>
                  <Trans>
                    You do not have the full right and entitlement to sell
                    including, where relevant, any licences or authorisations;
                    or
                  </Trans>
                </li>
                <li>
                  <Trans>
                    That Beezer determines, in Our sole discretion, is not
                    appropriate for sale through Our Service.
                  </Trans>
                </li>
              </ul>
            </li>
            <li>
              <Trans>
                Your User App may not infringe the intellectual property rights
                of any third party including, but not limited to, copyright,
                trademarks, patents and designs;
              </Trans>
            </li>
            <li>
              <Trans>
                Your User App may not contain links to other websites containing
                any of the above types of material;
              </Trans>
            </li>
            <li>
              <Trans>
                Your User App may not contain any material that may contain
                viruses or other software or instructions that may damage or
                disrupt other software, computer hardware or communications
                networks;
              </Trans>
            </li>
            <li>
              <Trans>
                Your User App may not be used for unauthorised
                mass-communications such as “spam” or “junk mail”;
              </Trans>
            </li>
            <li>
              <Trans>
                Your User App may not forge headers or otherwise manipulate
                identifiers in order to disguise the origin of any material
                transmitted through the Service (including, but not limited to,
                your User App);
              </Trans>
            </li>
            <li>
              <Trans>
                You may not use your User App to impersonate any person or
                entity including, but not limited to, any employee, agent or
                sub-contractor of Beezer or any of our affiliates;
              </Trans>
            </li>
            <li>
              <Trans>
                You may not use your User App for phishing purposes;
              </Trans>
            </li>
            <li>
              <Trans>
                You may not use your User App for the purpose of uploading files
                solely to have them hosted by Beezer; and
              </Trans>
            </li>
            <li>
              <Trans>
                Your User App may not contain any material that is adult in
                nature including, but not limited to, that which is pornographic
                or otherwise of a sexual nature.
              </Trans>
            </li>
          </ul>
        </li>
        <li>
          <span className="sub-clause-item">
            <span>9.2. </span>
            <span>
              <Trans>
                Beezer does not screen or pre-approve any User App submitted for
                publication (although you acknowledge that Beezer may do so if
                it wishes), however on notification from Users We may examine
                User Apps and such User Apps may be edited and/or taken down.
              </Trans>
            </span>
          </span>
        </li>
        <li>
          <span className="sub-clause-item">
            <span>9.3. </span>
            <span>
              <Trans>
                Beezer may edit your User App to comply with the provisions of
                sub-Clause 9.1 without prior consultation. In cases of severe
                breaches of the provisions of sub-Clause 9.1, your User App may
                be taken down and your Account may be suspended or terminated.
                You will not be informed in writing of the reasons for such
                alterations or take downs.
              </Trans>
            </span>
          </span>
        </li>
        <li>
          <span className="sub-clause-item">
            <span>9.4. </span>
            <span>
              <Trans>
                By creating and submitting a User App you warrant and represent
                that you are the author of the User Content on that User App or
                that you have acquired all of the appropriate rights and / or
                permissions to use it. We accept no responsibility or liability
                for any infringement of third party rights by User Apps.
              </Trans>
            </span>
          </span>
        </li>
        <li>
          <span className="sub-clause-item">
            <span>9.5. </span>
            <span>
              <Trans>
                Beezer will not be liable in any way or under any circumstances
                for any loss or damage that you or any other User may incur as a
                result of such User Apps, or Beezer exercising its rights under
                these Terms and Conditions, nor for any errors or omissions in
                User Apps. Use of and reliance upon User Apps is entirely at
                your own risk.
              </Trans>
            </span>
          </span>
        </li>
        <li>
          <span className="sub-clause-item">
            <span>9.6. </span>
            <span>
              <Trans>
                You acknowledge that We may retain copies of any and all
                communications, information, User Content and User Apps sent to
                Us or submitted for publication.
              </Trans>
            </span>
          </span>
        </li>
      </ul>
      <h3>
        <Trans>10. End User Terms</Trans>
      </h3>
      <p>
        <Trans>
          You will ensure that the terms specified in the End User Agreement
          will be included in all of your User Apps.
        </Trans>
      </p>
      <h3>
        <Trans>11. Accounts and Subscription Fees</Trans>
      </h3>
      <ul className="sub-clause">
        <li>
          <span className="sub-clause-item">
            <span>11.1. </span>
            <span>
              <Trans>
                In order to create a User App you must create an Account which
                will contain certain personal and/or business details. By
                creating an Account you represent and warrant that
              </Trans>
              :
            </span>
          </span>
          <ul>
            <li>
              <Trans>
                all information you submit is accurate and truthful; and
              </Trans>
            </li>
            <li>
              <Trans>
                you will keep this information accurate and up-to-date.
              </Trans>
            </li>
          </ul>
        </li>
        <li>
          <span className="sub-clause-item">
            <span>11.2. </span>
            <span>
              <Trans>
                Sharing of accounts is not permitted unless expressly authorised
                in writing by Beezer. You must keep your Account details
                confidential and should not reveal your username or password to
                any unauthorised third parties.
              </Trans>
            </span>
          </span>
        </li>
        <li>
          <span className="sub-clause-item">
            <span>11.3. </span>
            <span>
              <Trans>
                Some features of the Service require the payment of a
                Subscription Fee. Subscription Fees are detailed here. Your
                credit / debit card will be billed at the time at which you sign
                up for such features and monthly thereafter for a minimum
                contract period of one month and automatically renewing on a
                monthly basis until cancellation or termination.
              </Trans>
            </span>
          </span>
        </li>
        <li>
          <span className="sub-clause-item">
            <span>11.4. </span>
            <span>
              <Trans>
                No part of this Website constitutes a contractual offer capable
                of acceptance. Your signing up for the Services constitutes a
                contractual offer that We may, at Our sole discretion, accept.
                Our acceptance is indicated by Us sending to you a confirmation
                email. Only once We have sent you an order confirmation will
                there be a binding contract between Beezer and you.
              </Trans>
            </span>
          </span>
        </li>
        <li>
          <span className="sub-clause-item">
            <span>11.5. </span>
            <span>
              <Trans>
                Confirmation emails under sub-Clause 11.4 shall contain the
                following information
              </Trans>
              :
            </span>
          </span>
          <ul>
            <li>
              <Trans>
                Confirmation of the Services and features ordered including full
                details of the main characteristics of those features;
              </Trans>
            </li>
            <li>
              <Trans>
                Fully itemised pricing for the Services ordered including, where
                appropriate, taxes and any other additional charges;
              </Trans>
            </li>
            <li>
              <Trans>
                A confirmation of your express request that the Services are
                made available to you immediately and that this will constitute
                a waiver of your statutory right to cancel as detailed below in
                sub-Clause 11.8.
              </Trans>
            </li>
          </ul>
        </li>
        <li>
          <span className="sub-clause-item">
            <span>11.6. </span>
            <span>
              <Trans>
                Your first payment will be at the price advertised on the
                Website. We reserve the right to change Subscription Fees from
                time to time and any such changes may affect your recurring
                Subscription Fees
              </Trans>
              :
            </span>
          </span>
          <ul>
            <li>
              <Trans>
                increases in price will not be reflected in your recurring
                Subscription Fees; and
              </Trans>
            </li>
            <li>
              <Trans>
                decreases in price will not be reflected in your recurring
                Subscription Fees.
              </Trans>
            </li>
          </ul>
        </li>
        <li>
          <span className="sub-clause-item">
            <span>11.7. </span>
            <span>
              <Trans>
                Services requiring a Subscription Fee will be made available to
                you immediately upon Our confirmation of your signing up. When
                completing the order process you will be required to expressly
                acknowledge that you wish the Services to be made available
                immediately. You will also be required to expressly acknowledge
                that by doing so, you will lose your statutory right (if any) to
                cancel your contract with Beezer as detailed below in sub-Clause
                11.8.
              </Trans>
            </span>
          </span>
        </li>
        <li>
          <span className="sub-clause-item">
            <span>11.8. </span>
            <span>
              <Trans>
                If you are a consumer based in the European Union, you have a
                statutory right to a “cooling-off” period with respect to the
                purchase of certain goods and services. This period, if
                applicable, begins once the contract between the seller and you,
                the buyer, is formed and ends at the end of 14 calendar days
                after that date. Under normal circumstances, premium features
                requiring the payment of Subscription Fees on this Website are
                made available immediately upon our confirmation of your order
                for them. As set out in sub-Clause 11.7, by expressly requesting
                this, you waive your right to the cooling-off period and may not
                cancel the contract merely because you have changed your mind.
              </Trans>
            </span>
          </span>
        </li>
        <li>
          <span className="sub-clause-item">
            <span>11.9. </span>
            <span>
              <Trans>
                If you terminate a User App and/or your Account, your User
                App(s) will be taken down immediately and you will not be
                rebilled after the current insert time period, e.g. month.
              </Trans>
            </span>
          </span>
        </li>
        <li>
          <span className="sub-clause-item">
            <span>11.10. </span>
            <span>
              <Trans>
                All payments for Subscription Fees due under these Terms and
                Conditions must be made using a valid debit or credit card via
                Our chosen payment partner, stripe. Payments made via stripe are
                subject to stripe’s own terms and conditions of service and We
                make no representations or warranties with respect to their
                services.
              </Trans>
            </span>
          </span>
        </li>
      </ul>
      <h3>
        <Trans>12. Termination and/or Suspension</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            In the event that any of the provisions of sub-Clause 9.1, above,
            are not followed, We reserve the right to suspend or terminate your
            access to the Service(s). Any Users banned in this way must not
            attempt to use the Website under any other name or by using the
            access credentials of another User, with or without the permission
            of that User.
          </Trans>
        </li>
        <li>
          <Trans>
            If We terminate or suspend your Account as a result of your breach
            of these Terms and Conditions, you will not be entitled to any
            refund. If you have signed up for Services, you will not be rebilled
            after the current month. If you have multiple User Apps and not all
            are deleted, you will continue to be billed as normal for any
            relevant premium features.
          </Trans>
        </li>
      </ul>
      <h3>
        <Trans>13. Use of Communications Facilities</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            When using any System on the Website you should do so in accordance
            with the following rules
          </Trans>
          :
          <ul>
            <li>
              <Trans>You must not use obscene or vulgar language;</Trans>
            </li>
            <li>
              <Trans>
                You must not submit User Content that is unlawful or otherwise
                objectionable. This includes, but is not limited to, User
                Content that is abusive, threatening, harassing, defamatory,
                ageist, sexist or racist;
              </Trans>
            </li>
            <li>
              <Trans>
                You must not submit User Content that is intended to promote or
                incite violence;
              </Trans>
            </li>
            <li>
              <Trans>
                It is advised that submissions are made using the English
                language as we may be unable to respond to enquiries submitted
                in any other languages;
              </Trans>
            </li>
            <li>
              <Trans>
                The means by which you identify yourself must not violate these
                Terms and Conditions or any applicable laws;
              </Trans>
            </li>
            <li>
              <Trans>
                You must not impersonate other people, particularly employees
                and representatives of Beezer or our affiliates; and
              </Trans>
            </li>
            <li>
              <Trans>
                You must not use our System for unauthorised mass-communication
                such as “spam” or “junk mail”.
              </Trans>
            </li>
          </ul>
        </li>
        <li>
          <Trans>
            You acknowledge that Beezer reserves the right to monitor any and
            all communications made to us or using our System.
          </Trans>
        </li>
        <li>
          <Trans>
            You acknowledge that Beezer may retain copies of any and all
            communications made to us or using our System.
          </Trans>
        </li>
        <li>
          <Trans>
            You acknowledge that any information you send to us through our
            System or post on the blogs/forums/chat or similar may be modified
            by us in any way and you hereby waive your moral right to be
            identified as the author of such information. Any restrictions you
            may wish to place upon our use of such information must be
            communicated to us in advance and we reserve the right to reject
            such terms and associated information.
          </Trans>
        </li>
      </ul>
      <h3>
        <Trans>14. Accounts</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            In order to procure Services on this Website and to use the
            blogs/forums/chat or similar facilities you are required to create
            an Account which will contain certain personal/business details and
            Payment Information which may vary based upon your use of the
            Website and/or the Platform as we may not require payment
            information until you wish to make a purchase. By continuing to use
            the Website and/or the Platform, you represent and warrant that
          </Trans>
          :
          <ul>
            <li>
              <Trans>
                all information you submit is accurate and truthful;
              </Trans>
            </li>
            <li>
              <Trans>
                you have permission to submit Payment Information where
                permission may be required; and
              </Trans>
            </li>
            <li>
              <Trans>
                you will keep this information accurate and up-to-date.
              </Trans>
            </li>
          </ul>
          <Trans>
            Your creation of an Account is further affirmation of your
            representation and warranty.
          </Trans>
        </li>
        <li>
          <Trans>
            It is recommended that you do not share your Account details,
            particularly your username and password. Beezer accepts no liability
            for any losses or damages incurred as a result of your Account
            details being shared by you. If you use a shared computer, it is
            recommended that you do not save your Account details in your
            internet browser.
          </Trans>
        </li>
        <li>
          <Trans>
            If you have reason to believe that your Account details have been
            obtained by another without consent, you should contact Beezer
            immediately to suspend your Account and cancel any unauthorised
            orders or payments that may be pending. Please be aware that orders
            or payments can only be cancelled up until provision of Services has
            commenced. In the event that an unauthorised provision commences
            prior to your notifying us of the unauthorised nature of the order
            or payment, Beezer accepts no liability or responsibility and you
            should make contact with the third party service provider detailed
            in the Purchase Information.
          </Trans>
        </li>
        <li>
          <Trans>
            When choosing your username you are required to adhere to the terms
            set out above in Clause 15. Any failure to do so could result in the
            suspension and/or deletion of your Account.
          </Trans>
        </li>
      </ul>
      <h3>
        <Trans>15. Termination and Cancellation</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            If Beezer terminates your Account, you will be notified by email and
            an explanation for the termination will be provided. Notwithstanding
            the foregoing, we reserve the right to terminate without giving
            reasons.
          </Trans>
        </li>
        <li>
          <Trans>
            If Beezer terminates your Account, any current or pending orders or
            payments on your Account will be cancelled and provision of Services
            will not commence.
          </Trans>
        </li>
        <li>
          <Trans>
            Beezer reserves the right to cancel orders or payments without
            stating reasons, for any reason prior to processing payment or
            commencing Services provision.
          </Trans>
        </li>
        <li>
          <Trans>
            If orders or payments are cancelled for any reason other than due to
            your breach of these Terms and Conditions, prior to commencement of
            Services provision you will be refunded any monies paid in relation
            to those purchases; however, you will NOT be refunded for unused SMS
            bundles purchased from a SMS Service or any other credits in respect
            of any Service. Only the basic Subscription Fee will be refunded.
          </Trans>
        </li>
      </ul>
      <h3>
        <Trans>16. Payment</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            Any and all monies are due for payment on completion of the order or
            on the dates, or intervals specified in that order as may be
            appropriate, unless alternative arrangements are agreed between the
            You and Beezer.
          </Trans>
        </li>
        <li>
          <Trans>
            Interest will be charged on a daily basis, commercial interest at 5%
            above the base rate of the Bank of England obtaining at the time.
          </Trans>
        </li>
      </ul>
      <h3>
        <Trans>17. Services, Pricing and Availability</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            Whilst every effort has been made to ensure that all descriptions of
            Services available from Beezer correspond to the actual Services,
            Beezer is not responsible for any variations from these
            descriptions. This does not exclude our liability for mistakes due
            to negligence on our part and refers only to variations of the
            correct Services, not different Services altogether.
          </Trans>
        </li>
        <li>
          <Trans>
            Where appropriate, you may be required to select the required
            package Services.
          </Trans>
        </li>
        <li>
          <Trans>
            Beezer does not represent or warrant that such Services will be
            available. Availability indications are not provided on the Website.
          </Trans>
        </li>
        <li>
          <Trans>
            All pricing information on the Website is correct at the time of
            going online. Beezer reserves the right to change prices and alter
            or remove any special offers from time to time and as necessary.
          </Trans>
        </li>
        <li>
          <Trans>
            In the event that prices are changed during the period between an
            order being placed for Services and Beezer processing that order and
            taking payment, provision of Services shall commence as per your
            order and you will be charged the original price.
          </Trans>
        </li>
        <li>
          <Trans>
            All prices on the Website do not include VAT. Beezer’s VAT number is
            209052729.
          </Trans>
        </li>
      </ul>
      <h3>
        <Trans>18. Provision of Services</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            Provision of Services shall commence when full payment has been
            received or as otherwise detailed in the Terms & Conditions
            pertaining directly to those Services.
          </Trans>
        </li>
        <li>
          <Trans>
            Provision of all Services shall be subject to the Terms and
            Conditions pertaining directly to those Services.
          </Trans>
        </li>
        <li>
          <Trans>
            In the event that Services are provided that are not in conformity
            with your order and thus incorrect, you should contact us within 10
            days to inform us of the mistake. Beezer will ensure that any
            necessary corrections to the Services provided are made within 7
            working days.
          </Trans>
        </li>
        <li>
          <Trans>
            Beezer reserves the right to exercise discretion with respect to any
            alterations to Services under the provisions of this Clause 19.
            Factors which may be taken into account in the exercise of this
            discretion include, but are not limited to
          </Trans>
          :
          <ul>
            <li>
              <Trans>
                Any use or enjoyment that you may have already derived from the
                Services;
              </Trans>
            </li>
            <li>
              <Trans>
                Any characteristics of the Services which may mean that
                cessation of provision is impossible without significant further
                work on the part and at the expense of Beezer.
              </Trans>
            </li>
          </ul>
          <Trans>
            Such discretion to be exercised only within the confines of the law.
          </Trans>
        </li>
      </ul>
      <h3>
        <Trans>19. Privacy</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            This Privacy Policy applies only to your use of the Website. It does
            not extend to any websites that are linked to from the Website
            (whether We provide those links or whether they are shared by other
            Users). We have no control over how your data is collected, stored
            or used by other websites and We advise you to check the privacy
            policies of any such websites before providing any data to them.
          </Trans>
        </li>
        <li>
          <Trans>
            Some or all of your data may be stored or transferred outside of the
            European Economic Area (“the EEA”) (The EEA consists of all EU
            member states, plus Norway, Iceland and Liechtenstein). You are
            deemed to accept and agree to this by using the Website and
            submitting information to Us. If We do store or transfer data
            outside the EEA, We will take all reasonable steps to ensure that
            your data is treated as safely and securely as it would be within
            the EEA and under the Data Protection Act 1998. Such steps may
            include, but not be limited to, the use of legally binding
            contractual terms between Us and any third parties We engage and the
            use of the EU-approved Model Contractual Arrangements.
          </Trans>
        </li>
        <li>
          <Trans>
            Data security is of great importance to Us, and to protect your data
            We have put in place suitable physical, electronic and managerial
            procedures to safeguard and secure data collected through the
            Website and the Platform.
          </Trans>
        </li>
        <li>
          <Trans>
            Notwithstanding the security measures that We take, it is important
            to remember that the transmission of data via the internet may not
            be completely secure and that you are advised to take suitable
            precautions when transmitting to Us data via the internet.
          </Trans>
        </li>
        <li>
          <Trans>
            We may share your data with other companies in Our group. This
            includes Our subsidiaries and/or Our holding company and its
            subsidiaries.
          </Trans>
        </li>
        <li>
          <Trans>
            We may sometimes contract with third parties to supply products and
            services to you on Our behalf. These may include payment processing,
            delivery of goods, search engine facilities, SMS Services,
            advertising and marketing. In some cases, the third parties may
            require access to some or all of your data. Where any of your data
            is required for such a purpose, We will take all reasonable steps to
            ensure that your data will be handled safely, securely, and in
            accordance with your rights, Our obligations, and the obligations of
            the third party under the law.
          </Trans>
        </li>
        <li>
          <Trans>
            We may compile statistics about the use of the Website and/or the
            Platform including data on traffic, usage patterns, user numbers,
            sales and other information. All such data will be anonymised and
            will not include any personally identifying information. We may from
            time to time share such data with third parties such as prospective
            investors, affiliates, partners and advertisers. Data will only be
            shared and used within the bounds of the law.
          </Trans>
        </li>
        <li>
          <Trans>
            In certain circumstances We may be legally required to share certain
            data held by Us, which may include your personal information, for
            example, where We are involved in legal proceedings, where We are
            complying with the requirements of legislation, a court order, or a
            governmental authority. We do not require any further consent from
            you in order to share your data in such circumstances and will
            comply as required with any legally binding request that is made of
            Us. We may, from time to time, expand or reduce Our business and
            this may involve the sale and/or the transfer of control of all or
            part of Our business. Data provided by users will, where it is
            relevant to any part of Our business so transferred, be transferred
            along with that part and the new owner or newly controlling party
            will, under the terms of this Privacy Policy, be permitted to use
            the data for the purposes for which it was originally collected by
            Us.
          </Trans>
        </li>
        <li>
          <Trans>
            In the event that any of your data is to be transferred in such a
            manner, you will not be contacted in advance and informed of the
            changes. When contacted you will not, however, be given the choice
            to have your data deleted or withheld from the new owner or
            controller.
          </Trans>
        </li>
        <li>
          <Trans>
            When you submit information via the Website and/or the Platform, you
            may be given options to restrict Our use of your data. In
            particular, We aim to give you strong controls on Our use of your
            data for direct marketing purposes (including the ability to opt-out
            of receiving emails from Us which you may do by unsubscribing using
            the links provided in Our emails and at the point of providing your
            details and/or by managing your Account).
          </Trans>
        </li>
        <li>
          <Trans>
            You may also wish to sign up to one or more of the preference
            services operating in the UK: The Telephone Preference Service (“the
            TPS”), the Corporate Telephone Preference Service (“the CTPS”), and
            the Mailing Preference Service (“the MPS”). These may help to
            prevent you receiving unsolicited marketing. Please note, however,
            that these services will not prevent you from receiving marketing
            communications that you have consented to receiving.
          </Trans>
        </li>
        <li>
          <Trans>
            You may access certain areas of the Website and/or the Platform
            without providing any data at all. However, to use all features and
            functions available on the Website and/or the Platform you may be
            required to submit or allow for the collection of certain data. You
            may restrict your internet browser’s use of Cookies.
          </Trans>
        </li>
        <li>
          <Trans>
            You have the legal right to ask for a copy of any of your personal
            data held by Us (where such data is held) on payment of a small fee
            which will not exceed £30.00. Please contact us for more details at
            sales@beezer.com, or using the contact details below in section 13.
          </Trans>
        </li>
        <li>
          <Trans>
            The Website may place and access certain first party Cookies on your
            computer or device. First party Cookies are those placed directly by
            Us and are used only by Us. We use Cookies to facilitate and improve
            your experience of the Website and to provide and improve the
            Services. By using the Website you may also receive certain third
            party Cookies on your computer or device. Third party Cookies are
            those placed by websites, services, and/or parties other than Us.
          </Trans>
        </li>
        <li>
          <Trans>
            All Cookies used by and on the Website are used in accordance with
            current UK and EU Cookie Law.
          </Trans>
        </li>
        <li>
          <Trans>
            Certain features of the Website depend on Cookies to function. UK
            and EU Cookie Law deems these Cookies to be “strictly necessary”.
            Your consent will not be sought to place these Cookies. You may
            still block these Cookies by changing your internet browser’s
            settings, but please be aware that the Website may not work as
            intended if you do so. We have taken care to ensure that your
            privacy is not at risk by allowing them.
          </Trans>
        </li>
        <li>
          <Trans>
            Some data will be collected automatically by the Website (for
            further details, please see Our use of Cookies below), other data
            will only be collected if you voluntarily submit it, for example,
            when signing up for an Account. Depending upon your use of the
            Website, We may collect some or all of the following data
          </Trans>
          :
          <ul>
            <li>
              <Trans>name</Trans>
            </li>
            <li>
              <Trans>date of birth</Trans>
            </li>
            <li>
              <Trans>gender</Trans>
            </li>
            <li>
              <Trans>business/company name</Trans>
            </li>
            <li>
              <Trans>job title</Trans>
            </li>
            <li>
              <Trans>profession</Trans>
            </li>
            <li>
              <Trans>
                contact information such as email addresses and telephone
                numbers
              </Trans>
            </li>
            <li>
              <Trans>
                demographic information such as credit / debit card numbers
                (automatically collected)
              </Trans>
            </li>
            <li>
              <Trans>IP Address (automatically collected)</Trans>
            </li>
            <li>
              <Trans>
                web browser type and version (automatically collected)
              </Trans>
            </li>
            <li>
              <Trans>operating system (automatically collected)</Trans>
            </li>
            <li>
              <Trans>
                a list of URLS starting with a referring site, your activity on
                the Website, and the site you exit to (automatically collected)
              </Trans>
            </li>
          </ul>
        </li>
        <li>
          <Trans>
            The Website places the following cookies onto your computer or
            device. These cookies are used for the purposes described herein.
            Full details of the cookies used by the Website and your legal
            rights with respect to them are included in our privacy policy. By
            accepting these terms and conditions, you are giving consent to
            Beezer to place cookies on your computer or device. Please read the
            information contained in the Privacy Policy prior to acceptance.
          </Trans>
        </li>
      </ul>
      <table className="cookies">
        <thead>
          <tr>
            <th>
              <Trans>Name of Cookie</Trans>
            </th>
            <th>
              <Trans>1st / 3rd Party</Trans>
            </th>
            <th>
              <Trans>Purpose</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Trans>Google Analytics</Trans>
            </td>
            <td>
              <Trans>3rd</Trans>
            </td>
            <td>
              <Trans>Analysis of website data</Trans>
            </td>
          </tr>
        </tbody>
      </table>
      <h3>
        <Trans>20. How We Use Personal Data (Data Protection)</Trans>
      </h3>
      <ul>
        {/* <li>
        All Personal Data that We may collect (including, but not limited to,
        your details and those of End Users) will be collected, used and held in
        accordance with the provisions of the DPA.
      </li> */}
        <li>
          {/* We may use Personal Data to: */}
          <Trans>
            The Company may use Personal Data for the following purposes
          </Trans>
          :
          <ul>
            <li>
              <Trans>
                To provide and maintain our Service, including to monitor the
                usage of our Service.
              </Trans>
            </li>
            <li>
              <Trans>To manage Your Account</Trans>:{' '}
              <Trans>
                to manage Your registration as a user of the Service. The
                Personal Data You provide can give You access to different
                functionalities of the Service that are available to You as a
                registered user.
              </Trans>
            </li>
            <li>
              <Trans>For the performance of a contract</Trans>:{' '}
              <Trans>
                the development, compliance and undertaking of the purchase
                contract for the products, items, or services You have purchased
                or of any other contract with Us through the Service.
              </Trans>
            </li>
            <li>
              <Trans>To contact You</Trans>:{' '}
              <Trans>
                To contact You by email, telephone calls, SMS, or other
                equivalent forms of electronic communication, such as a mobile
                application’s push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </Trans>
            </li>
            <li>
              <Trans>
                To provide You with news, special offers and general information
                about other goods, services and events which we offer that are
                similar to those that you have already purchased or enquired
                about unless You have opted not to receive such information.
              </Trans>
            </li>
            <li>
              <Trans>To manage Your requests</Trans>:{' '}
              <Trans>To attend and manage Your requests to Us.</Trans>
            </li>

            <li>
              <Trans>For business transfers</Trans>:
              <Trans>
                We may use Your information to evaluate or conduct a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of Our assets, whether as
                a going concern or as part of bankruptcy, liquidation, or
                similar proceeding, in which Personal Data held by Us about our
                Service users is among the assets transferred.
              </Trans>
            </li>
            <li>
              <Trans>For other purposes</Trans>:
              <Trans>
                We may use Your information for other purposes, such as data
                analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns and to evaluate and
                improve our Service, products, services, marketing and your
                experience.
              </Trans>
            </li>
            {/* <li></li>
          <li></li>
          <li></li> */}
          </ul>
          {/* <ul>
          <li>Provide Our Services to you and End Users;</li>
          <li>Process your payment for the Service; and</li>
          <li>
            Inform you and End Users of new products and services available from
            Us.
          </li>
          <li>Providing and managing your Account and those of End Users;</li>
          <li>
            Providing and managing your access to the Website and/or the
            Platform;
          </li>
          <li>
            Personalising and tailoring your experience on the Website and/or
            the Platform and that of End Users;
          </li>
          <li>Supplying the Services to you and End Users;</li>
          <li>Personalising and tailoring the Services for you;</li>
          <li>Responding to communications from you;</li>
          <li>
            Supplying you with email, newsletters, alerts etc. that you have
            subscribed to (you may unsubscribe or opt-out at any time by
            clicking on the unsuscribe link at the top of communication.
          </li>
          <li>Market research;</li>
          <li>
            Analysing your use of the Website and/or the Platform and gathering
            feedback to enable Us to continually improve the Website and/or the
            Platform and your user experience.
          </li>
        </ul> */}
        </li>
        {/* <li>We will not pass on Personal Data to any other third parties.</li> */}
        <li>
          {/* We will use anonymised data including End User Data for statistical
        analysis, including (but not limited) for the purposes of: */}
          <Trans>
            We may share Your personal information in the following situations
          </Trans>
          :
          <ul>
            <li>
              <Trans>With Service Providers</Trans>:{' '}
              <Trans>
                We may share Your personal information with Service Providers to
                monitor and analyze the use of our Service, to contact You
              </Trans>
            </li>
            <li>
              <Trans>For business transfers</Trans>:{' '}
              <Trans>
                We may share or transfer Your personal information in connection
                with, or during negotiations of, any merger, sale of Company
                assets, financing, or acquisition of all or a portion of Our
                business to another company.
              </Trans>
            </li>
            <li>
              <Trans>
                With Affiliates: We may share Your information with Our
                affiliates, in which case we will require those affiliates to
                honor this Privacy Policy. Affiliates include Our parent company
                and any other subsidiaries, joint venture partners or other
                companies that We control or that are under common control with
                Us.
              </Trans>
            </li>
            <li>
              <Trans>With business partners</Trans>:{' '}
              <Trans>
                We may share Your information with Our business partners to
                offer You certain products, services or promotions.
              </Trans>
            </li>{' '}
            <li>
              <Trans>With other users</Trans>:{' '}
              <Trans>
                when You share personal information or otherwise interact in the
                public areas with other users, such information may be viewed by
                all users and may be publicly distributed outside.
              </Trans>
            </li>
            <li>
              <Trans>With Your consent</Trans>:{' '}
              <Trans>
                We may disclose Your personal information for any other purpose
                with Your consent.
              </Trans>
            </li>
          </ul>
          {/* <ul>
          <li>improving the Services;</li>
          <li>providing statistical information to third parties;</li>
          <li>for research and development;</li>
          <li>for the purposes of targeted advertising.</li>
        </ul> */}
        </li>
        <li>
          <Trans>
            The Company will not perform, or facilitate or support others in
            performing, any of the following prohibited practices (collectively,
            “Prohibited Practices”)
          </Trans>
          :
          <ul>
            <li>
              <Trans>
                Processing Platform Data to discriminate or encourage
                discrimination against people based on personal attributes
                including race, ethnicity, color, national origin, religion,
                age, sex, sexual orientation, gender identity, family status,
                disability, medical or genetic condition, or any other
                categories prohibited by applicable law, regulation, or Meta
                policy.
              </Trans>
            </li>
            <li>
              <Trans>
                Processing Platform Data to make eligibility determinations
                about people, including for housing, employment, insurance,
                education opportunities, credit, government benefits, or
                immigration status. By eligibility determinations, we mean
                determining whether to provide, deny, or take away a particular
                benefit (for example, housing or scholarships) as well as
                determining the terms under which the benefit will be provided,
                denied, or taken away.
              </Trans>
            </li>
            <li>
              <Trans>
                Processing Platform Data to perform, facilitate, or provide
                tools for surveillance. Surveillance includes the Processing of
                Platform Data about people, groups, or events for law
                enforcement or national security purposes.
              </Trans>{' '}
              -
            </li>
            <li>
              <Trans>Selling, licensing, or purchasing Platform Data.</Trans>
            </li>
            <li>
              <Trans>
                Placing Platform Data on, or otherwise making Platform Data
                available to, a search engine or directory without our prior
                express written consent.
              </Trans>
            </li>
            <li>
              <Trans>
                Attempting to decode, circumvent, re-identify, de-anonymize,
                unscramble, unencrypt, or reverse hash, or reverse-engineer
                Platform Data that is provided to you.
              </Trans>
            </li>
            <li>
              <Trans>
                Changing your App’s core functionality or data Processing so
                that Users would view it as an unfamiliar or different App, or
                materially changing the scope of Processing of previously
                collected Platform Data, unless in each case you first re-submit
                your App and receive our approval through App Review.
              </Trans>{' '}
            </li>
            <li>
              <Trans>
                Processing friend lists from Facebook to establish social
                connections in your App unless each person in that connection
                has granted you access to that information for that purpose.
              </Trans>
            </li>
          </ul>
        </li>
        <li>
          <Trans>Retention of your personal data</Trans>
          <ul>
            <li>
              <Trans>
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of Our Service, or We
                are legally obligated to retain this data for longer time
                periods.
              </Trans>
            </li>
          </ul>
        </li>
        <li>
          <Trans>Deletion of your personal data</Trans>
          <ul>
            <li>
              <Trans>
                To request deletion of your personal data, you can contact the
                Company at
              </Trans>{' '}
              <a href="support@beezer.com" style={{ color: '#261ED5' }}>
                support@beezer.com
              </a>
              .{' '}
              <Trans>
                The Company ensures that all personal data will be deleted
                within 24 hours upon receiving the deletion request.
              </Trans>
            </li>
            <li>
              <Trans>
                When retaining the Platform Data is no longer necessary for a
                legitimate business purpose that is consistent with these Terms
                and all other applicable terms and policies;
              </Trans>
            </li>
            <li>
              <Trans>
                When you stop operating the product or service through which the
                Platform Data was acquired;
              </Trans>
            </li>
            <li>
              <Trans>
                When we request you delete the Platform Data for the protection
                of Users (which we will determine at our sole discretion);
              </Trans>
            </li>
            <li>
              <Trans>
                When a User requests their Platform Data be deleted or no longer
                has an account with you (unless the Platform Data has been
                aggregated, obscured, or de-identified so that it cannot be
                associated with a particular User, browser, or device), or for
                Tech Providers, when a User or the Client requests their
                Platform Data be deleted or the Client no longer has an account
                with you;
              </Trans>
            </li>
            <li>
              <Trans>
                When required by applicable law or regulations; or As required
                under Section 7 (“Compliance Review Rights and Suspension and
                Termination of these Terms”)
              </Trans>
            </li>
          </ul>
        </li>
        <li>
          <Trans>Transfer of your personal data</Trans>
          <ul>
            <li>
              <Trans>
                Your information, including Personal Data, is processed at the
                Company’s operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country, or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction. Your consent to
                this Privacy Policy followed by Your submission of such
                information represents Your agreement to that transfer. The
                Company will take all steps reasonably necessary to ensure that
                Your data is treated securely and in accordance with this
                Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                personal information.
              </Trans>
            </li>
          </ul>
        </li>
        <li>
          <Trans>Disclosure of your personal data</Trans>
          <ul>
            <li>
              <Trans>Business transactions</Trans>
              <ul>
                <li>
                  <Trans>
                    If the Company is involved in a merger, acquisition or asset
                    sale, Your Personal Data may be transferred. We will provide
                    notice before Your Personal Data is transferred and becomes
                    subject to a different Privacy Policy.
                  </Trans>
                </li>
              </ul>
            </li>
            <li>
              <Trans>Law enforcement</Trans>
              <ul>
                <li>
                  <Trans>
                    Under certain circumstances, the Company may be required to
                    disclose Your Personal Data if required to do so by law or
                    in response to valid requests by public authorities (e.g. a
                    court or a government agency).
                  </Trans>
                </li>
              </ul>
            </li>
            <li>
              <Trans>Other legal requirements</Trans>
              <br />
              <br />{' '}
              <Trans>
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to
              </Trans>
              :
              <ul>
                <li>
                  <Trans>Comply with a legal obligation</Trans>
                </li>
                <li>
                  <Trans>
                    Protect and defend the rights or property of the Company
                  </Trans>
                </li>
                <li>
                  <Trans>
                    Prevent or investigate possible wrongdoing in connection
                    with the Service
                  </Trans>
                </li>
                <li>
                  <Trans>
                    Protect the personal safety of Users of the Service or the
                    public
                  </Trans>
                </li>
                <li>
                  <Trans>Protect against legal liability</Trans>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <Trans>Security of your personal data</Trans>
          <ul>
            <li>
              <Trans>
                The security of Your Personal Data is important to Us, but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While We strive to
                use commercially acceptable means to protect Your Personal Data,
                We cannot guarantee its absolute security.
              </Trans>
            </li>
          </ul>
        </li>
        <li>
          <Trans>Children's privacy</Trans>
          <ul>
            <li>
              <Trans>
                Our Service does not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                anyone under the age of 13. If You are a parent or guardian and
                You are aware that Your child has provided Us with Personal
                Data, please contact Us. If We become aware that We have
                collected Personal Data from anyone under the age of 13 without
                verification of parental consent, We take steps to remove that
                information from Our servers. If We need to rely on consent as a
                legal basis for processing Your information and Your country
                requires consent from a parent, We may require Your parent’s
                consent before We collect and use that information.
              </Trans>
            </li>
          </ul>
        </li>
        <li>
          <Trans>Links to other websites</Trans>
          <ul>
            <li>
              <Trans>
                Our Service may contain links to other websites that are not
                operated by Us. If You click on a third party link, You will be
                directed to that third party’s site. We strongly advise You to
                review the Privacy Policy of every site You visit. We have no
                control over and assume no responsibility for the content,
                privacy policies or practices of any third-party sites or
                services.
              </Trans>
            </li>
          </ul>
        </li>
        {/* <li>
        Both We and advertisers whose content appears on the Website/and or the
        Platform may engage in what is known as “behavioural advertising” –
        advertising which is tailored to your preferences, based on your
        activity. Your activity is monitored using Cookies, as detailed above.
        You can control and limit your data used in this way by adjusting your
        web browser’s privacy settings. Please note that We do not control the
        activities of such advertisers, nor the information they collect and
        use. Limiting the use of your data in this way will not remove the
        advertising, but it will make it less relevant to your interests and
        activities on the Website/and or the Platform.
      </li> */}
      </ul>

      <h3>
        <Trans>
          21. Data Use Concerning Meta Platform Integrations: Facebook app &
          Instagram app
        </Trans>
      </h3>
      <ol style={{ listStyleType: 'lower-alpha' }}>
        <li>
          <strong>
            Prohibited Practices. Beezer will not perform, or facilitate or
            support others in performing, any of the following prohibited
            practices (collectively, “Prohibited Practices”):
          </strong>
          <ul style={{ listStyleType: 'lower-roman' }}>
            <li>
              Processing Platform Data to discriminate or encourage
              discrimination against people based on personal attributes
              including race, ethnicity, color, national origin, religion, age,
              sex, sexual orientation, gender identity, family status,
              disability, medical or genetic condition, or any other categories
              prohibited by applicable law, regulation, or Meta policy.
            </li>
            <li>
              Processing Platform Data to make eligibility determinations about
              people, including for housing, employment, insurance, education
              opportunities, credit, government benefits, or immigration status.
              By eligibility determinations, we mean determining whether to
              provide, deny, or take away a particular benefit (for example,
              housing or scholarships) as well as determining the terms under
              which the benefit will be provided, denied, or taken away.
            </li>
            <li>
              Processing Platform Data to perform, facilitate, or provide tools
              for surveillance. Surveillance includes the Processing of Platform
              Data about people, groups, or events for law enforcement or
              national security purposes.
            </li>
            <li>Selling, licensing, or purchasing Platform Data.</li>
            <li>
              Placing Platform Data on, or otherwise making Platform Data
              available to, a search engine or directory without our prior
              express written consent.
            </li>
            <li>
              Attempting to decode, circumvent, re-identify, de-anonymize,
              unscramble, unencrypt, or reverse hash, or reverse-engineer
              Platform Data that is provided to Beezer.
            </li>
            <li>
              Changing Beezer's App’s core functionality or data Processing so
              that Users would view it as an unfamiliar or different App, or
              materially changing the scope of Processing of previously
              collected Platform Data, unless in each case Beezer first
              re-submit Beezer's App and receive our approval through App
              Review.
            </li>
            <li>
              Processing friend lists from Facebook to establish social
              connections in Beezer's App unless each person in that connection
              has granted Beezer access to that information for that purpose.
            </li>
          </ul>
        </li>
        <li>
          <strong>Additional Terms for Restricted Platform Data</strong>
          <ul style={{ listStyleType: 'lower-roman' }}>
            <li>
              Beezer will not request Restricted Platform Data unless it is
              necessary to meaningfully improve the quality of the applicable
              User's experience in the specific product or service for which the
              User shared the data.
            </li>
            <li>
              It is made clear to the User why Beezer is requesting their
              Restricted Platform Data in order to improve the quality of their
              experience.
            </li>
            <li>
              For clarity, Beezer's Processing of Restricted Platform Data
              complies with the applicable Developer Docs and other provisions
              of these Terms (including the Prohibited Practices).
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Sharing Platform Data. Beezer may only share Platform Data in
            compliance with these Terms (including Sections 3.a (“Prohibited
            Practices”), 3.b (“Additional Terms for Restricted Platform Data”),
            4 (“Privacy Policy”), and 5 (“Service Providers and Tech
            Providers”)), applicable law and regulations, and all other
            applicable terms and policies, and only in the following
            circumstances:
          </strong>
          <ul style={{ listStyleType: 'lower-roman' }}>
            <li>
              With respect to Platform Data collected as a Tech Provider, solely
              as described below in Section 5.b (“Tech Providers”);
            </li>
            <li>
              With respect to Platform Data not collected as a Tech Provider,
              <ul style={{ listStyleType: 'decimal' }}>
                <li>
                  when required under applicable law or regulation (Beezer will
                  retain proof of the applicable legal or regulatory requirement
                  or request and provide it to Meta if Meta asks for it);
                </li>
                <li>with Beezer's Service Provider;</li>
                <li>
                  when a User expressly directs Beezer to share the data with a
                  third party (Beezer will retain proof of the User’s express
                  direction and provide it to us if we ask for it); or
                </li>
                <li>
                  solely with respect to Platform Data that is not Restricted
                  Platform Data, with other third parties, so long as:
                  <ul style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Beezer first contractually prohibits them from using the
                      Platform Data in a way that would violate these Terms or
                      any other applicable terms or policies (Beezer will retain
                      proof of the contractual prohibition and provide it to
                      Meta if Meta asks for it); and
                    </li>
                    <li>
                      Beezer ensures that any such third parties comply with
                      these Terms and all other applicable terms and policies as
                      if they were in Beezer’s place, and Beezer is responsible
                      for their acts and omissions, including their
                      noncompliance.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Retention, Deletion, and Accessibility of Platform Data
          </strong>
          <ul style={{ listStyleType: 'lower-roman' }}>
            <li>
              Unless required to keep Platform Data under applicable law or
              regulation, Beezer will (and will make reasonable efforts to
              ensure Beezer's Service Providers) do the following:
              <ul style={{ listStyleType: 'decimal' }}>
                <li>
                  Make reasonable efforts to keep Platform Data up to date,
                  including Platform Data that has been modified or deleted.
                  Beezer will update Platform Data promptly after receiving a
                  request from Meta or the User to do so. Beezer will give Users
                  an easily accessible and clearly marked way to ask for the
                  Platform Data to be modified or deleted. All users can request
                  data deletion by sending a request to support@beezer.com.
                </li>
                <li>
                  Delete all Platform Data as soon as reasonably possible in the
                  following cases:
                  <ul style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      When retaining the Platform Data is no longer necessary
                      for a legitimate business purpose that is consistent with
                      these Terms and all other applicable terms and policies;
                    </li>
                    <li>
                      When Beezer stops operating the product or service through
                      which the Platform Data was acquired;
                    </li>
                    <li>
                      When Meta requests Beezer to delete the Platform Data for
                      the protection of Users (which Meta will determine at our
                      sole discretion);
                    </li>
                    <li>
                      When a User requests their Platform Data be deleted or no
                      longer has an account with Beezer (unless the Platform
                      Data has been aggregated, obscured, or de-identified so
                      that it cannot be associated with a particular User,
                      browser, or device), or for Tech Providers, when a User or
                      the Client requests their Platform Data be deleted or the
                      Client no longer has an account with Beezer;
                    </li>
                    <li>When required by applicable law or regulations; or</li>
                    <li>
                      As required under Section 7 (“Compliance Review Rights and
                      Suspension and Termination of these Terms”).
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              If Beezer will keep Platform Data under applicable law or
              regulation, Beezer will retain proof of the applicable legal or
              regulatory requirement or request and provide it if Meta asks for
              it.
            </li>
            <li>
              If Beezer has received Platform Data in error, Beezer will
              immediately report this to Meta, delete that Platform Data, and
              provide proof of deletion if Meta asks for it.
            </li>
          </ul>
        </li>
        <li>
          <strong>
            Exceptions to Restrictions. The above provisions of this section
            (Section 3.a-d) do not apply to certain Platform Data as described
          </strong>{' '}
          <a
            href="https://developers.facebook.com/terms/3e/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline', color: '#261ED5' }}
          >
            here
          </a>
        </li>
      </ol>

      <p>
        Beezer is committed to maintaining strict adherence to the applicable
        <a
          href="https://developers.facebook.com/docs/instagram-basic-display-api"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: '#261ED5' }}
        >
          Developer Docs
        </a>{' '}
        and other provisions outlined in the{' '}
        <a
          href="https://developers.facebook.com/terms/dfc_platform_terms/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: '#261ED5' }}
        >
          Terms
        </a>
        , including the avoidance of any{' '}
        <a
          href="https://developers.facebook.com/terms/dfc_platform_terms/#datause"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: '#261ED5' }}
        >
          Prohibited Practices
        </a>
        .
      </p>

      <h3>
        <Trans>
          22. Platform Data Deletion Concerning Meta Platform Integrations:
          Facebook app & Instagram app
        </Trans>
      </h3>
      <p>
        Platform Data includes any information, data, or other content obtained
        from Facebook, through Platform or through Beezer , including app
        tokens, page tokens, access tokens, app secret, and user tokens.
      </p>
      <p>
        <strong>Process for Platform Data Deletion:</strong>
      </p>
      <ul style={{ listStyleType: 'lower-alpha' }}>
        <li>
          Whenever Platform Data is no longer necessary to provide an app
          experience or service to users - Data deletion in less than 30 days.
        </li>
        <li>When requested by a user - Data deletion in less than 24 hours.</li>
        <li>
          When a user deletes their account associated with your app - Data
          deletion in less than 24 hours.
        </li>
        <li>When requested by Facebook - Data deletion in less than 24 hrs.</li>
        <li>When required by law or regulation - Data deletion immediately.</li>
      </ul>

      <h3>
        <Trans>23. Legal Rights and Disclaimers</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            We make no warranty or representation that the Website or the
            Service will be compatible with all systems, or that it will be
            secure.
          </Trans>
        </li>
        <li>
          <Trans>
            Save for the discretionary screening and approval of User Apps as
            detailed in Clause 9, We have neither control over, nor involvement
            in, any User Apps or User Content and accept no responsibility for
            any actions taken, or any goods or services provided, by any Users.
          </Trans>
        </li>
        <li>
          <Trans>
            Whilst reasonable endeavours have been made to ensure that all
            information provided on this Website will be accurate and up to
            date, We make no warranty or representation that this is the case.
            We make no guarantee of any specific results from the use of our
            services.
          </Trans>
        </li>
        <li>
          <Trans>
            No part of this Website is intended to constitute advice and the
            Content of this Website should not be relied upon when making any
            decisions or taking any action of any kind.
          </Trans>
        </li>
        <li>
          <Trans>
            We make no representation or warranty that any part of this Website
            is suitable for use in commercial situations or that it constitutes
            accurate data and / or advice on which business decisions can be
            based.
          </Trans>
        </li>
        <li>
          <Trans>
            When providing digital content to consumers, We are required to
            provide digital content that is of satisfactory quality, fit for any
            purpose made known to us (whether expressly or impliedly), and in
            accordance with any descriptions (and pre-contract information)
            given by Us. This includes any digital content we may subsequently
            modify. If We fail to comply with Our legal obligations, consumers
            may be entitled to certain legal remedies including repair or
            replacement or price reductions. For full details of consumers’
            legal rights, including those relating to digital content, please
            contact your local Citizens Advice Bureau or Trading Standards
            Office.
          </Trans>
        </li>
        <li>
          <Trans>
            Whilst We exercise all reasonable skill and care to ensure that the
            Website is secure and free of errors, viruses and other malware, you
            are strongly advised to take responsibility for your own internet
            security, that of your personal details and your computers.
          </Trans>
        </li>
        <li>
          <Trans>
            If, as a result of Our failure to exercise reasonable care and
            skill, any digital content from the Website damages your device or
            other digital content belonging to you, you may be entitled to
            certain legal remedies. For more details concerning your rights and
            remedies as a consumer, please contact your local Citizens Advice
            Bureau or Trading Standards Office.
          </Trans>
        </li>
      </ul>
      <h3>
        <Trans>24. Availability of the Website and Modifications</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            We accept no liability for any disruption or non-availability of the
            Website resulting from external causes including, but not limited
            to, ISP equipment failure, host equipment failure, communications
            network failure, power failure, natural events, acts of war or legal
            restrictions and state-mandated censorship.
          </Trans>
        </li>
        <li>
          <Trans>
            We reserve the right to alter, suspend or discontinue any part (or
            the whole of) the Website including, but not limited to, the Content
            available. Where anything you have paid for is made unavailable and
            you are entitled to a refund as a result, We will inform you of the
            refund due and it will be paid within 14 days using the same method
            originally used by you. These Terms and Conditions shall continue to
            apply to any modified version of the Website unless it is expressly
            stated otherwise.
          </Trans>
        </li>
      </ul>
      <h3>
        <Trans>25. Limitation of Liability</Trans>
      </h3>
      <li>
        <Trans>
          To the fullest extent permissible by law, We accept no liability to
          any user for any loss or damage, whether foreseeable or otherwise, in
          contract, delict (including negligence), for breach of statutory duty,
          or otherwise, arising out of or in connection with the use of (or
          inability to use) the Website or the use of or reliance upon any
          content included on the Website including the Service and the Content.
        </Trans>
      </li>
      <li>
        <Trans>
          To the fullest extent permissible by law, We exclude all
          representations, warranties, and guarantees (whether express or
          implied) that may apply to the Website or any content included on it.
        </Trans>
      </li>
      <li>
        <Trans>
          If you are a commercial user, We accept no liability for loss of
          profits, sales, business or revenue; loss of business opportunity,
          goodwill or reputation; loss of anticipated savings; business
          interruption; or for any indirect or consequential loss or damage.
        </Trans>
      </li>
      <li>
        <Trans>
          We exercise all reasonable skill and care to ensure that the Website
          is free from viruses and other malware. We accept no liability for any
          loss or damage resulting from a virus or other malware, a distributed
          denial of service attack, or other harmful material or event that may
          adversely affect your hardware, software, data or other material that
          occurs as a result of your use of the Website (including the
          downloading of any content from it) or any other site referred to on
          the Website.
        </Trans>
      </li>
      <li>
        <Trans>
          We neither assume nor accept responsibility or liability arising out
          of any disruption or non-availability of the Website resulting from
          external causes including, but not limited to, ISP equipment failure,
          host equipment failure, Platform failure, communications network
          failure, natural events, acts of war, or legal restrictions and
          censorship.
        </Trans>
      </li>
      <li>
        <Trans>
          Nothing in these Terms and Conditions excludes or restricts Our
          liability for fraud or fraudulent misrepresentation, for death or
          personal injury resulting from negligence, or for any other forms of
          liability which cannot be excluded or restricted by law. For full
          details of consumers’ legal rights, including those relating to
          digital content, please contact your local Citizens’ Advice Bureau or
          Trading Standards Office.
        </Trans>
      </li>
      <h3>
        <Trans>26. No Waiver</Trans>
      </h3>
      <p>
        <Trans>
          In the event that any party to these Terms and Conditions fails to
          exercise any right or remedy contained herein, this shall not be
          construed as a waiver of that right or remedy.
        </Trans>
      </p>
      <h3>
        <Trans>27. Previous Terms and Conditions</Trans>
      </h3>
      <p>
        <Trans>
          In the event of any conflict between these Terms and Conditions and
          any prior versions thereof, the provisions of these Terms and
          Conditions shall prevail unless it is expressly stated otherwise.
        </Trans>
      </p>
      <h3>
        <Trans>28. Third Party Rights</Trans>
      </h3>
      <p>
        <Trans>
          Nothing in these Terms and Conditions shall confer any rights upon any
          third party. The agreement created by these Terms and Conditions is
          between you and Beezer.
        </Trans>
      </p>
      <h3>
        <Trans>29. Communications</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            All notices / communications shall be given to Us either by post to
            Our premises at the address given above or by email to
            sales@beezer.com. Such notice will be deemed received 3 days after
            posting if sent by first class post, the day of sending if the email
            is received in full on a business day and on the next business day
            if the email is sent on a weekend or public holiday.
          </Trans>
        </li>
        <li>
          <Trans>
            We may from time to time, if you opt to receive it, send you
            information about Our products and/or services.
          </Trans>
        </li>
      </ul>
      <h3>
        <Trans>30. Law and Jurisdiction</Trans>
      </h3>
      <ul>
        <li>
          <Trans>
            These Terms and Conditions, and the relationship between you and Us
            (whether contractual or otherwise) shall be governed by, and
            construed in accordance with Scots law.
          </Trans>
        </li>
        <li>
          <Trans>
            If you are a consumer, any disputes concerning these Terms and
            Conditions, the relationship between you and Us, or any matters
            arising therefrom or associated therewith (whether contractual or
            otherwise) shall be subject to the jurisdiction of the courts of
            England, Wales, Scotland, or Northern Ireland, as determined by your
            residency.
          </Trans>
        </li>
        <li>
          <Trans>
            If you are a business, any disputes concerning these Terms and
            Conditions, the relationship between you and Us, or any matters
            arising therefrom or associated therewith (whether contractual or
            otherwise) shall be subject to the exclusive jurisdiction of the
            Scottish courts.
          </Trans>
        </li>
      </ul>
    </Modal>
  );
};

TermsAndConditions.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default TermsAndConditions;
