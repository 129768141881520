import i18n from 'i18next';
import firebase from '../../../../../firebase';
import { validateField } from '../validators';
import { openSnackbar } from '../../../../../app/modules/snackbar/actions';
import { objectToArray, arrayToObject } from '../../../../../utils/object';

export const updateGroupImage = groupImage => ({
  type: 'EDIT_GROUP_IMAGE',
  groupImage,
});

const setLoading = value => ({
  type: 'EDIT_GROUP_LOADING',
  value,
});

const setGroupData = payload => ({
  type: 'EDIT_GROUP_DATA',
  payload,
});

export const setUpdatedImageStatus = payload => ({
  type: 'SET_UPDATED_IMAGE_STATUS',
  value: payload,
});

const setHideNavData = payload => ({
  type: 'EDIT_HIDE_NAV_DATA',
  payload,
});

const updatedGroupData = () => ({
  type: 'UPDATED_GROUP_DATA',
  value: true,
});

const editGroupFieldChange = (prop, value, isValid) => ({
  type: 'EDIT_GROUP_FIELD_CHANGE',
  prop,
  value,
  isValid,
});

export const errorUpdatingSubscription = () => ({
  type: 'ERROR_UPDATING_SUBSCRIPTION_PRODUCT',
});

export const formFieldChange = (prop, value) => dispatch => {
  const isValid =
    prop === 'name' && value.length > 0 ? validateField[prop](value) : null;

  return dispatch(editGroupFieldChange(prop, value, isValid));
};

const updateSubscriptionProduct = payload =>
  new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(token => {
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('x-access-token', token);
        fetch(`${process.env.REACT_APP_PAYMENT_API}/update_subscription_plan`, {
          method: 'post',
          headers: myHeaders,
          body: JSON.stringify(payload),
          mode: 'cors',
        })
          .then(data => {
            if (data.status == 200) {
              return data.json();
            } else if (data.status === 422) {
              throw data.json();
            }
          })
          .then(response => resolve(response))
          .catch(err => {
            reject(err);
          });
      });
  });

export const loadContent = appname => dispatch => {
  const groupImageRef = firebase
    .database()
    .ref(`apps/${appname}/temp_data/editGroupImage`);
  groupImageRef.on('value', snapshot => {
    const image = snapshot.val();
    dispatch(updateGroupImage(image));
  });
};

export const loadHideNav = (appname, groupId) => dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/${groupId}/hideNav`)
    .once('value')
    .then(snapshot => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return dispatch({ type: 'EDIT_HIDE_NAV_DATA', payload: data });
      }
    });
};

export const loadHideSubscription = (appname, groupId) => dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/${groupId}/hideSubscription`)
    .once('value')
    .then(snapshot => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return dispatch({ type: 'EDIT_HIDE_SUBSCRIPTION_DATA', payload: data });
      }
    });
};

export const loadGroup = (appname, group) => (dispatch, getState) => {
  const groupState = getState().auth.groups.editGroup;
  if (!groupState.loading) {
    // set to loading if not already
    dispatch(setLoading(true));
  }
  firebase
    .database()
    .ref(`app_groups/${appname}/groups`)
    .on('value', snap => {
      if (snap.exists()) {
        const result = snap.val();
        const subscriptionGroups = objectToArray(result);
        const hasSubscriptionPlans = subscriptionGroups.filter(
          item => item.value.subscriptionPlan !== undefined,
        );

        if (hasSubscriptionPlans.length > 0) {
          let i = 1;
          // eslint-disable-next-line no-restricted-syntax
          for (const key in hasSubscriptionPlans) {
            if (
              Object.prototype.hasOwnProperty.call(hasSubscriptionPlans, key)
            ) {
              hasSubscriptionPlans[key].value.order =
                hasSubscriptionPlans[key].value.order || i;
              i++;
            }
          }
        }

        const groups = hasSubscriptionPlans.map(value => value.id);

        firebase
          .database()
          .ref(`app_groups/${appname}/groups/${group}`)
          .once('value')
          .then(snapshot => {
            if (snapshot.exists()) {
              const data = snapshot.val();
              const payload = {
                selected: data.draft || {},
                name: { value: data.name, isValid: true },
                loading: false,
                groupImage: null,
                order: {
                  value: data.order || groups.indexOf(group) + 1,
                  isValid: true,
                },
              };
              if (data.subscriptionPlan) {
                const { subscriptionPlan } = data;
                payload.hasSubscriptionPlan = { value: true, isValid: true };
                payload.description = {
                  value: subscriptionPlan.description,
                  isValid: true,
                };
                payload.subscriptionPlan = { ...subscriptionPlan };
                payload.price = {
                  value: subscriptionPlan.price,
                  isValid: true,
                };
                payload.oldPrice = {
                  value: subscriptionPlan.price,
                  isValid: true,
                };
                payload.currency = {
                  value: subscriptionPlan.currency,
                  isValid: true,
                };

                payload.features = subscriptionPlan.features || [];
                if (
                  subscriptionPlan.images &&
                  subscriptionPlan.images.length > 0
                ) {
                  payload.groupImage = subscriptionPlan.images[0];
                }
              } else {
                payload.hasSubscriptionPlan = { value: false, isValid: true };
              }

              dispatch(setGroupData(payload));
              setTimeout(() => {
                dispatch(setLoading(false));
              }, 2000);
            }
          });
      }
    });
};

const submitGroupSubscriptionData = (appname, group, payload) =>
  new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`app_groups/${appname}/groups/${group}/subscriptionPlan`)
      .update(payload)
      .then(response => resolve(response))
      .catch(err => reject(err));
  });

const submitGroupData = (appname, group, payload) =>
  new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`app_groups/${appname}/groups/${group}`)
      .update(payload)
      .then(response => resolve(response))
      .catch(err => reject(err));
  });

export const updateGroupData = (appname, group, payload) => async dispatch => {
  try {
    await submitGroupData(appname, group, payload);
  } catch (error) {
    console.log(error);
  }
};

export const updateGroup = (appname, group, payload) => dispatch => {
  try {
    dispatch(openSnackbar(i18n.t('Update in progress.')));

    if (payload.hasSubscriptionPlan) {
      payload.appname = appname;
      updateSubscriptionProduct(payload)
        .then(subscriptionResponse => {
          const {
            name,
            order,
            draft,
            last_updated,
            images,
            description,
            features,
            price,
            currency,
          } = payload;
          submitGroupData(appname, group, {
            name,
            ...(payload.hasSubscriptionPlan && {
              order,
            }),
            draft,
            last_updated,
          }).then(response => {
            submitGroupSubscriptionData(appname, group, {
              images: images || null,
              description,
              features,
              price,
              currency,
              ...(payload.hasPriceChanged && {
                pricing_id: subscriptionResponse.currentPriceId,
              }),
            }).then(() => {
              dispatch(updatedGroupData());
              setTimeout(() => {
                dispatch(openSnackbar(i18n.t('Group successfully updated.')));
              }, 3000);
            });
          });
        })
        .catch(err => {
          console.log(err);
          dispatch(errorUpdatingSubscription());
        });
    } else {
      return submitGroupData(appname, group, payload).then(() => {
        dispatch(updatedGroupData());
        setTimeout(() => {
          dispatch(openSnackbar(i18n.t('Group successfully updated.')));
        }, 3000);
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const resetGroupOrder = (
  appname,
  groups,
  orderNos,
) => async dispatch => {
  try {
    groups.map((item, index) => {
      dispatch(
        updateGroupData(appname, item.id, {
          order: orderNos[index],
        }),
      );
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateHideNavData = (appname, payload, groupId) => dispatch =>
  new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`app_groups/${appname}/groups/${groupId}`)
      .update({ hideNav: payload })
      .then(response => resolve(response))
      .catch(err => reject(err));
  });

export const updateVisibility = (appname, payload, groupId) => dispatch =>
  new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`app_groups/${appname}/groups/${groupId}`)
      .update({ hideSubscription: payload })
      .then(response => resolve(response))
      .catch(err => reject(err));
  });

export const resetGroup = appname => dispatch => {
  const groupImageRef = firebase
    .database()
    .ref(`apps/${appname}/temp_data/editGroupImage`);
  groupImageRef.off('value');
  dispatch({ type: 'RESET_EDIT_GROUP_FORM' });
};
