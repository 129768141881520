import firebase from '../../../../../../firebase';

import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';

export const openDialog = () => dispatch => {
  dispatch({ type: 'TOGGLE_DIALOG', payload: true });
};

export const closeDialog = () => dispatch => {
  dispatch({ type: 'TOGGLE_DIALOG', payload: false });
};

export const fetchBonusPoints = () => (dispatch, getState) => {
  const { account } = getState().account.profile;
  return new Promise((resolve, reject) => {
    const accountRef = firebase.database().ref(`accounts/${account}`);
    accountRef.child('referral_bonus').on('value', snap => {
      if (snap.exists()) {
        dispatch({ type: 'UPDATE_BONUS_POINTS', payload: snap.val() });
        resolve();
      }
    });
  });
};

export const redeemConfirmation = () => (dispatch, getState) => {
  const { account } = getState().account.profile;
  const { appname } = getState().app.appContext;
  dispatch({ type: 'TOGGLE_PENDING_STATE', payload: true });
  return new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;
    return user.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_REFERRAL_API}/redeem-bonus`, {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({ account, appname }),
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            dispatch(
              openSnackbar(
                'Your bonus points redeem request has been received.',
              ),
            );
            dispatch({ type: 'TOGGLE_PENDING_STATE', payload: false });
            dispatch({ type: 'TOGGLE_DIALOG', payload: false });
            return resolve(res);
          } else {
            dispatch({ type: 'TOGGLE_PENDING_STATE', payload: false });
            dispatch({ type: 'TOGGLE_DIALOG', payload: false });
            dispatch(
              openSnackbar(
                'An error occurred while processing your request, Please contact customer support.',
              ),
            );
            return reject();
          }
        })
        .catch(error => {
          dispatch({ type: 'TOGGLE_PENDING_STATE', payload: false });
          dispatch({ type: 'TOGGLE_DIALOG', payload: false });
          dispatch(
            openSnackbar(
              'An error occurred while processing your request, Please contact customer support.',
            ),
          );
          return reject(error);
        });
    });
  });
};
