import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import InfoOutline from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation, Trans } from 'react-i18next';

import { DISABLE_HOW_TO_LINK_ACCOUNTS } from '../../../config';
import { SelectableCard } from '../selectableCardMenu';
import { includes } from 'lodash';

import './styles.css';

const RadioItem = (
  {
    comingSoon,
    disabled,
    disabledIcon,
    disabledNotice,
    label,
    value,
    text,
    image,
    children,
    svg,
    className,
    instructionLink,
    instructionSubAdminLink,
    category = '',
  },
  { imageStyle, onSelect, selected },
) => {
  const isAppAdmin = useSelector(
    state => state.account.auth.profile.admin_appname || false,
  );
  const isSubAdmin = useSelector(
    state =>
      (state.account.auth.profile.admin_appname &&
        state.account.auth.profile.admin_appname.length > 0) ||
      false,
  );

  const { t } = useTranslation();

  const isSubAdminWithMulipleApps = useSelector(
    state =>
      (state.account.auth.profile.admin_appnames &&
        Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
      false,
  );

  const disableTouchTap = disabled || selected === value;
  const parent = useSelector(
    state => state.account.auth.profile.parent || null,
  );

  const appAdminCheck =
    (!isAppAdmin && !isSubAdminWithMulipleApps && instructionLink) ||
    ((isAppAdmin || isSubAdminWithMulipleApps) &&
      instructionSubAdminLink !== null);
  const restrictedAccess = () => {
    if (isSubAdmin) {
      return parent && !includes(DISABLE_HOW_TO_LINK_ACCOUNTS, parent);
    }
    return true;
  };
  const showHowToInstructions =
    category === 'components'
      ? appAdminCheck && restrictedAccess()
      : appAdminCheck;
  return (
    <SelectableCard
      active={selected === value}
      onClick={disableTouchTap ? () => ({}) : () => onSelect(value)}
      disabled={disabled}
      disabledIcon={disabledIcon}
      disabledNotice={disabledNotice}
      comingSoon={comingSoon}
      className={className}
    >
      <div className="card-content">
        <div className="radio-menu-item-image-container">
          {image ? (
            <img
              className="radio-menu-item-image"
              style={imageStyle}
              src={image}
              alt={`${label} component illustration`}
            />
          ) : svg ? (
            svg
          ) : null}
        </div>
        <h5 className="radio-menu-item-label">{label}</h5>
        <p className="radio-menu-item-text">{text}</p>
      </div>
      {showHowToInstructions && (
        <div className="card-actions">
          <Button
            title={`${label} ${t('Component Instructions')}`}
            href={
              isAppAdmin || isSubAdminWithMulipleApps
                ? instructionSubAdminLink
                : instructionLink
            }
            variant="text"
            color="info"
            endIcon={
              <Tooltip
                title={t(
                  'Clicking here will redirect you to the instructions page',
                )}
              >
                <InfoOutline fontSize="small" />
              </Tooltip>
            }
            target="_blank"
            rel="noopener"
            disableRipple={true}
            onMouseDown={e => e.stopPropagation()}
            onClick={e => e.stopPropagation()}
          >
            <Trans>How to use</Trans>
          </Button>
        </div>
      )}
      {children}
    </SelectableCard>
  );
};

RadioItem.propTypes = {
  comingSoon: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledIcon: PropTypes.node,
  disabledNotice: PropTypes.node,
  label: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.node,
  instructionSubAdminLink: PropTypes.string,
  instructionLink: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

RadioItem.defaultProps = {
  comingSoon: false,
  disabled: false,
  disabledIcon: null,
  disabledNotice: null,
  label: '',
  text: '',
  image: null,
  value: null,
  children: null,
  instructionSubAdminLink: null,
  instructionLink: null,
};

RadioItem.contextTypes = {
  imageStyle: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
};

export default RadioItem;
