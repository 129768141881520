import { connect } from 'react-redux';

import Settings from './Settings';
import { getData, toggleAuth } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  users: state.auth.users.chunks,
  enabled: state.auth.structure.enabled,
  type: state.auth.structure.type,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getData: () => dispatch(getData(ownProps.match.params.appname)),
  toggleAuth: (v, type) =>
    dispatch(toggleAuth(ownProps.match.params.appname, v, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
