import firebase from '../../../../../../../firebase';
import { parseDomain } from '../../../../../../../utils/string';

import { toggleWelcomeModal } from '../../welcomeModal/actions';
import { newValidator } from '../../../../../../../utils';
import { getSubscriptions } from '../../../../../../account/modules/subscriptions/actions';
import { publishApp } from '../../../../../../../app/modules/publishDialog/actions';

export const CHECKING_DOMAIN_AVAILABILITY = 'CHECKING_DOMAIN_AVAILABILITY';
export const ASSERT_DOMAIN_AVAILABILITY = 'ASSERT_DOMAIN_AVAILABILITY';
export const DOMAIN_UPDATED = 'DOMAIN_UPDATED';
export const SHOW_DOMAIN_INPUT = 'SHOW_DOMAIN_INPUT';
export const CREATING_INTEGRATION_APP = 'CREATING_INTEGRATION_APP';
export const INTEGRATION_APP_CREATED = 'INTEGRATION_APP_CREATED';
export const CREATE_APP_INTEGRATION_ERROR = 'CREATE_APP_INTEGRATION_ERROR';

const reportError = err => ({
  type: CREATE_APP_INTEGRATION_ERROR,
  err,
});

const checkingDomainAvailability = () => ({
  type: CHECKING_DOMAIN_AVAILABILITY,
});

const domainUpdate = domain => ({
  type: DOMAIN_UPDATED,
  domain,
});

const assertDomainAvailability = available => ({
  type: ASSERT_DOMAIN_AVAILABILITY,
  available,
});

const creatingIntegrationApp = (form, accountId) => ({
  type: CREATING_INTEGRATION_APP,
  form,
  accountId,
});

const integrationAppCreated = () => ({
  type: INTEGRATION_APP_CREATED,
});

const showDomainInput = () => ({
  type: SHOW_DOMAIN_INPUT,
});

// validate domain in the action so we know whether to check domain availability
const domainValidator = newValidator({
  required: true,
  maxLength: 60,
  type: 'domain',
});

export const createNewApp = (domain, callback) => (dispatch, getState) => {
  const {
    siteUrl,
    siteTitle,
    type,
    premiumPackageId,
  } = getState().account.organisation.integration;

  const { account } = getState().account.profile;
  const legacy = getState().account.organisation.legacy_flag;
  const user = firebase.auth().currentUser;
  const apps = getState().account.organisation.apps;
  const skin = 'integration';
  const content = 'integration';
  const appLimit = typeof legacy === 'number' ? legacy : 50;
  const title = domain;

  dispatch(creatingIntegrationApp({ domain, siteTitle, skin }, account));

  if (legacy || apps === null) {
    if (apps === null || Object.keys(apps).length < appLimit) {
      return (
        user
          .getIdToken()
          .then(token => {
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('x-access-token', token);
            return fetch(`${process.env.REACT_APP_CREATE_API}/app`, {
              headers,
              method: 'post',
              mode: 'cors',
              body: JSON.stringify({
                domain,
                title,
                skin,
                content,
                siteUrl,
                type,
              }),
            });
          })
          // we need to refresh the subscription state after creating a new app
          .then(res => {
            if (res.status === 200) {
              if (premiumPackageId && type.toLowerCase() === 'wix') {
                setWixPremiumPlan(domain, account);
              }
              dispatch(publishApp(domain)).then(() => {
                dispatch(toggleWelcomeModal());
              });
              return dispatch(getSubscriptions());
            }
            return res.json();
          })
          .then(result => {
            if (result.error) {
              throw new Error(result.error);
            }
          })
          .then(() => {
            firebase
              .database()
              .ref(`accounts/${account}/apps`)
              .once('child_added');
          })
          .then(() => {
            dispatch(integrationAppCreated());
            callback(domain);
          })
          .catch(err => dispatch(reportError(err.message)))
      );
    }

    return dispatch(
      reportError(`Sorry, but you cannot have more than ${appLimit} apps.`),
    );
  }

  return dispatch(
    reportError('Sorry, but you can only have one app per account.'),
  );
};

export const domainChange = (domain, autoSubmit = false) => dispatch => {
  const isValid = domainValidator(domain);
  dispatch(domainUpdate(domain));
  if (isValid) {
    dispatch(checkingDomainAvailability());
    firebase
      .database()
      .ref('apps_directory')
      .child(domain)
      .once('value', snapshot => {
        const isAvailable = !snapshot.exists();
        dispatch(assertDomainAvailability(isAvailable));
        if (autoSubmit && isAvailable) {
          dispatch(createNewApp(domain));
        } else {
          dispatch(showDomainInput());
        }
      });
  }

  return dispatch({
    type: 'CREATE_APP_DOMAIN_CHANGE',
    domain,
    isValid,
  });
};

export const createNewDomain = title => dispatch => {
  const domain = parseDomain(title);
  dispatch(domainChange(domain, true));
  dispatch(domainUpdate(domain));
};

const setWixPremiumPlan = (appname, accountId) =>
  new Promise((resolve, reject) => {
    const apiUrl = `${process.env.REACT_APP_PAYMENT_API}/wix_plan`;

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return fetch(apiUrl, {
      headers,
      method: 'post',
      mode: 'cors',
      body: JSON.stringify({
        appname,
        accountId,
        plan: 'premium',
        period: 'monthly',
      }),
    })
      .then(res => {
        if (res.status === 200) {
          return resolve();
        }
        return reject();
      })
      .catch(() => reject());
  });
