import _indexOf from 'lodash/indexOf';
import firebase from '../firebase';
import { parseDomain } from 'parse-domain';

export const getAppDomain = () => {
  switch (process.env.REACT_APP_PLATFORM_ENV) {
    case 'staging':
      return '.staging.beezer.com';
    case 'testing':
      return '.test.beezer.com';
    case 'development':
      return '.test.beezer.com';
    default:
      return '.beezer.com';
  }
};

export const getAppUrl = (appname, customDomain, fullUrl = true) => {
  const httpsStr = 'https://';
  const prefix = fullUrl ? httpsStr : '';
  const isCustomDomainSetUp =
    (customDomain && customDomain.cloudfront_domain && customDomain.enabled) ||
    null;
  const customDomainUrl = (customDomain && customDomain.domain) || null;

  if (!isCustomDomainSetUp) {
    return `${prefix}${appname}${getAppDomain()}`;
  }

  const { subDomains } = parseDomain(customDomainUrl);

  const subDomainPrefix =
    (subDomains && subDomains.length > 0 && subDomains[0]) || '';
  const modifiedCustomDomainUrl = subDomainPrefix
    ? `${httpsStr}${customDomainUrl}`
    : `${httpsStr}www.${customDomainUrl}`;

  return customDomainUrl.indexOf(httpsStr) > -1
    ? customDomainUrl
    : modifiedCustomDomainUrl;
};

export const isAppSubjectToNotificationsCredit = (plan, trialExpired) => {
  const unlimitedCredit = [
    'accelerate',
    'expert',
    'premium',
    'deluxe-zar',
    'deluxe-cad',
    'deluxe-nzd',
    'deluxe-aud',
    'deluxe-eur',
    'deluxe-usd',
    'deluxe',
    'deluxe_yearly',
    'deluxe-usd_yearly',
    'deluxe-eur_yearly',
    'deluxe-aud_yearly',
    'deluxe-nzd_yearly',
    'deluxe-cad_yearly',
    'deluxe-zar_yearly',
    'expert_new',
    'beezer_tier1',
    'beezer_tier2',
    'beezer_tier3',
    'beezer_tier4',
    'beezer_tier5',
  ];

  return trialExpired ? _indexOf(unlimitedCredit, plan) < 0 : false;
};

export const getAppSubscription = appname =>
  new Promise((resolve, reject) =>
    firebase
      .database()
      .ref(`apps/${appname}/subscription`)
      .once('value')
      .then(snapshot => {
        if (snapshot.exists()) {
          return resolve(snapshot.val());
        }
        return resolve(null);
      }),
  );
