import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import EcommerceMenu from './EcommerceMenu';
import {
  addIsPublishedListener,
  removeIsPublishedListener,
} from '../../../../app/modules/toolbar/actions';
import {
  toggleMarketplace,
  toggleFoodOrder,
  getMarketplaceStatus,
  stopGetMarketplaceStatus,
  getFoodOrderStatus,
  stopGetFoodOrderStatus,
  toggleBooking,
  getBookingStatus,
  stopGetBookingStatus,
} from './actions';

import { toggleAuth } from '../../../memberLogin/modules/memberLoginMenu/actions';

const mapStateToProps = (state, ownProps) => {
  const isAppAdmin =
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false;
  const isSubAdminWithMulipleApps =
    (state.account.auth.profile.admin_appnames &&
      Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
    false;
  return {
    ...ownProps,
    isAppAdmin,
    isSubAdminWithMulipleApps,
    marketplaceEnabled: state.ecommerce.structure.marketplaceEnabled,
    bookingEnabled: state.ecommerce.structure.bookingEnabled,
    foodOrderEnabled: state.ecommerce.structure.foodOrderEnabled,
    integration: state.account.organisation.integration,
    isPublished:
      state.editor.data.isPublished[ownProps.match.params.appname] || false,
    customDomain: state.app.customDomain,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddIsPublishedListener: () =>
    dispatch(addIsPublishedListener(ownProps.match.params.appname)),
  onRemoveIsPublishedListener: () =>
    dispatch(removeIsPublishedListener(ownProps.match.params.appname)),
  getMarketplaceStatus: () =>
    dispatch(getMarketplaceStatus(ownProps.match.params.appname)),
  stopGetMarketplaceStatus: () =>
    dispatch(stopGetMarketplaceStatus(ownProps.match.params.appname)),
  getFoodOrderStatus: () =>
    dispatch(getFoodOrderStatus(ownProps.match.params.appname)),
  stopGetFoodOrderStatus: () =>
    dispatch(stopGetFoodOrderStatus(ownProps.match.params.appname)),
  toggleMarketplace: v =>
    dispatch(toggleMarketplace(ownProps.match.params.appname, v)),
  toggleFoodOrder: v =>
    dispatch(toggleFoodOrder(ownProps.match.params.appname, v)),
  toggleBooking: v => dispatch(toggleBooking(ownProps.match.params.appname, v)),
  getBookingStatus: () =>
    dispatch(getBookingStatus(ownProps.match.params.appname)),
  stopGetBookingStatus: () =>
    dispatch(stopGetBookingStatus(ownProps.match.params.appname)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EcommerceMenu),
);
