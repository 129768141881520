import firebase from '../../../../../../firebase';
import { formatDate } from '../../../../../../utils';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';

export const fetchRedeemHistory = () => (dispatch, getState) => {
    dispatch({ type: 'TOGGLE_HISTORY_PENDING_STATE', payload: true });
    const { account } = getState().account.profile;
    return new Promise((resolve, reject) => {
        firebase.
            database()
            .ref(`accounts/${account}`)
            .child('referral_bonus_payments')
            .once('value')
            .then(snapshot => {
                const data = snapshot.val();
                if (!snapshot.exists()) {
                    return reject('Referrals not found');
                }
                return data;
            })
            .then((data) => {
                const results = [];
                for (let key in data) {
                    if (data.hasOwnProperty(key)) {
                        const item = data[key];
                        item['created_at'] = formatDate(item['created_at'], 'YYYY/MM/DD');
                        results.push(item);
                    }
                }
                dispatch({ type: 'UPDATE_HISTORY_ITEMS', payload: results.reverse() });
                dispatch({ type: 'TOGGLE_HISTORY_PENDING_STATE', payload: false });
                return resolve(true);
            })
            .catch((error) => {
                dispatch({ type: 'TOGGLE_HISTORY_PENDING_STATE', payload: false });
                dispatch(openSnackbar('An error occurred while fetch Redeem History, Please contact customer support.'));
                return reject(error);
            });
    });
}