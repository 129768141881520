import styled from 'styled-components';
export const vendorContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  justify-content: center;
`;
export const vendorInnerContainer = styled.div`
  width: 40vw;
  height: fit-content;
  max-height: 90vh;
  margin: auto;
  border-radius: 2px;
  background: white;
  overflow: auto;
  position: relative;
`;

export const vendorTitle = styled.h2`
  font-weight: bold;
  text-align: center;
  margin: 0.5em;
`;

export const vendorImageContainer = styled.div`
  display: flex;
  padding: 1em;
  height: 60%;
  justify-content: center;
  flex-direction: column;
`;

export const vendorImage = styled.img.attrs(props => ({ src: props.src }))`
  width: 100%;
  height: 100%;
  border-radius: 2px;
  object-fit: contain;
`;

export const vendorDetails = styled.div`
  padding: 0.5em;
  overflow-y: auto;
`;
export const field = styled.div`
  margin: 0.5em;
`;

export const fieldTitle = styled.span`
  font-weight: bold;
  padding: 0.2em;
`;

export const fieldContent = styled.span`
  padding: 0.2em;
`;

export const vendorActions = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5em;
  button {
    margin: 0.5em;
  }
`;
