const eventsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'THREE_MONTHS_VISITS': {
      return {
        ...state,
        threeMonthsVisits: action.value,
      };
    }

    case 'SIX_MONTHS_VISITS': {
      return {
        ...state,
        sixMonthsVisits: action.value,
      };
    }

    case 'ONE_YEAR_VISITS': {
      return {
        ...state,
        oneYearVisits: action.value,
      };
    }

    case 'THREE_MONTHS_INSTALLS': {
      return {
        ...state,
        threeMonthsInstalls: action.value,
      };
    }

    case 'SIX_MONTHS_INSTALLS': {
      return {
        ...state,
        sixMonthsInstalls: action.value,
      };
    }

    case 'ONE_YEAR_INSTALLS': {
      return {
        ...state,
        oneYearInstalls: action.value,
      };
    }

    default:
      return state;
  }
};

export default eventsReducer;
