import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { get } from 'lodash';
import VerificationPage from './VerificationPage';
import {
  toggleVerificationPage,
  updateField,
  handleRadioChange,
  loadLayoutData,
  updateRedirectPageField,
} from './actions';

const mapStateToProps = (state, ownProps) => {
  const defaultGroup = state.auth.groups.data.chunks.default;
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];
  const alwaysData = state.editor.data.alwaysData;
  const verificationPage = get(defaultGroup, 'verification_page', null);

  const redirectVerificationPage = get(
    defaultGroup,
    'redirect_verification_page',
    null,
  );

  return {
    ...ownProps,
    enabled: (verificationPage && verificationPage.enabled) || false,
    headerText: get(verificationPage, 'headerText', ''),
    subHeaderText: get(verificationPage, 'subHeaderText', ''),
    link: get(verificationPage, 'link', ''),
    buttonLabel: get(verificationPage, 'buttonLabel', ''),
    pages:
      layout && layout.childRoutes && Object.keys(alwaysData).length > 0
        ? layout.childRoutes.map(key => alwaysData[key])
        : [],
    imageSrc: get(verificationPage, 'image_src', ''),
    appname: ownProps.match.params.appname,
    redirectVerificationPageType: get(redirectVerificationPage, 'type', ''),
    redirectVerificationAppPage: get(redirectVerificationPage, 'appPage', ''),
    redirectVerificationPageUrl: get(redirectVerificationPage, 'url', ''),
    uid: get(state, 'account.auth.user.uid', ''),
    parentUid: get(state, 'account.auth.profile.parent', ''),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleVerificationPage: value =>
    dispatch(toggleVerificationPage(ownProps.match.params.appname, value)),
  updateField: (field, value) =>
    dispatch(updateField(ownProps.match.params.appname, field, value)),
  handleNestedChange: (field, value) =>
    dispatch(handleRadioChange(ownProps.match.params.appname, field, value)),
  loadLayoutData: () => dispatch(loadLayoutData(ownProps.match.params.appname)),
  updateRedirectPageField: ({ field, value }) =>
    dispatch(
      updateRedirectPageField({
        appname: ownProps.match.params.appname,
        field,
        value,
      }),
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerificationPage),
);
