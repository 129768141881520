import React from 'react';

const MarketplaceFilters = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080.000000 1080.000000"
      className="icon marketplace-filters"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M3230 8655 l0 -495 -590 0 -590 0 0 -200 0 -200 590 0 590 0 0 -495
0 -495 790 0 790 0 0 495 0 495 1970 0 1970 0 0 200 0 200 -1970 0 -1970 0 0
495 0 495 -790 0 -790 0 0 -495z m1180 -695 l0 -780 -390 0 -390 0 0 780 0
780 390 0 390 0 0 -780z"
        />
        <path
          d="M5990 6090 l0 -490 -1970 0 -1970 0 0 -200 0 -200 1970 0 1970 0 0
-495 0 -495 790 0 790 0 0 495 0 495 590 0 590 0 0 200 0 200 -590 0 -590 0 0
490 0 490 -790 0 -790 0 0 -490z m1180 -695 l0 -785 -390 0 -390 0 0 785 0
785 390 0 390 0 0 -785z"
        />
        <path
          d="M3820 3530 l0 -490 -885 0 -885 0 0 -205 0 -205 885 0 885 0 0 -490
0 -490 793 2 792 3 3 488 2 487 1670 0 1670 0 0 205 0 205 -1670 0 -1670 0 -2
488 -3 487 -792 3 -793 2 0 -490z m1180 -695 l0 -785 -390 0 -390 0 0 785 0
785 390 0 390 0 0 -785z"
        />
      </g>
    </svg>
  );
};

export default MarketplaceFilters;
