import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cryptoRandomString from 'crypto-random-string';
import NewUserForm from '../newUser';
import { InnerLayout } from '../components';
import SocialMedia from './Social Media';
import './styles.css';
import { referrerList } from '../referrerList';
import { PROMO_DETAILS } from '../../../../../config';
import {
  logEventsToFirebase,
  increaseAdilloRegisterPageVisites,
} from '../../../../../utils/analytics';

class Register extends Component {
  state = {
    token: '',
  };

  componentDidMount() {
    const crypto = cryptoRandomString(20);
    this.setState({ token: crypto });
    const params = new URLSearchParams(this.props.location.search);
    const referrer = params.get('referrer');

    if (referrer === 'adillo') {
      this.addAdiloLogEvent();
    }
  }

  componentWillUnmount() {
    this.props.onLeave();
  }

  verifyCaptcha = async () => {
    const captchaToken = window.grecaptcha.getResponse();
    const body = JSON.stringify({
      captchaToken,
    });
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const res = await fetch(`${process.env.REACT_APP_CAPTCHA_URL}/verify`, {
      method: 'POST',
      mode: 'cors',
      body,
      headers,
    });
    const resObj = await res.json();
    const verificationResult = resObj.verificationResult;
    return verificationResult.success;
  };
  handleSubmit = async () => {
    if (process.env.NODE_ENV === 'production') {
      const isCaptchaVerified = await this.verifyCaptcha();
      if (!isCaptchaVerified) {
        window.grecaptcha.reset();
        return;
      }
      if (!this.state.token) {
        return;
      }
    }

    this.verifyUserAgent()
      .then(() => {
        this.props.onSubmit((email, password) =>
          this.props.onLogin(email, password, () =>
            this.props.history.push('/create'),
          ),
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  verifyUserAgent = () =>
    new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      return fetch(`${process.env.REACT_APP_CREATE_API}/verify-user-agent`, {
        method: 'post',
        mode: 'cors',
        body: null,
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            return resolve(res);
          }
          throw new Error('Opps, something went wrong. Please try again.');
        })
        .catch(reject);
    });

  isPromoActive = () => {
    const currentDate = Date.now();
    const isPromoInDuration =
      PROMO_DETAILS.startDate <= currentDate &&
      PROMO_DETAILS.endDate >= currentDate;

    return isPromoInDuration && !this.props.appsumoUser;
  };

  getFormTitle = () => {
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const referral = query.get('ref');
    if (this.props.appsumoUser) {
      return 'WELCOME SUMO-LING!';
    } else if (this.props.referrer) {
      if (this.props.referrer.name === 'halloweenOffer') {
        return `Register now and  grab your exclusive 40% discount`;
      } else if (this.props.referrer.name === 'adillo') {
        return `WELCOME!`;
      } else {
        return `WELCOME ${this.props.referrer.name &&
          this.props.referrer.name.toUpperCase()} USER`;
      }
    } else if (this.isPromoActive()) {
      return PROMO_DETAILS.registerPageTitle;
    } else if (referral === 'dealfuel') {
      return 'Activate Your Account to access Premium Features';
    } else {
      return 'SIGN UP FOR A BEEZER ACCOUNT NOW';
    }
  };

  getContent = type => {
    if (this.isPromoActive()) {
      if (type === 'content2') {
        return PROMO_DETAILS.registerPageContent2;
      }
      return PROMO_DETAILS.registerPageContent;
    } else {
      return `
      &lt;p&gt; 
      Welcome to the brave new world of Beezer mobile apps. We’re going to have you up and running with your own app in no time.
      &lt;/p&gt; 
      &lt;p&gt; 
      With the Beezer platform you can Create, Distribute and Manage
      your mobile app from one location. Customize your app, choose from
      a great range of features and start engaging with your audiences
      on your own mobile channel – what are you waiting for!
      &lt;/p&gt; 
    </p>`;
    }
  };

  getFormSubTitle = () =>
    this.props.appsumoUser || this.props.referrer
      ? 'Fill in the details mentioned below to get started with Beezer'
      : '';

  htmlDecode(input) {
    let e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  }

  addAdiloLogEvent() {
    logEventsToFirebase('adillo_page_visited', {});
    increaseAdilloRegisterPageVisites();
  }

  render() {
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const referral = query.get('ref');
    const isDealFuelUser = referral === 'dealfuel';
    return (
      <InnerLayout
        content={
          <div className="content">
            <p>
              {this.props.referrer &&
              referrerList[this.props.referrer.name] &&
              referrerList[this.props.referrer.name].registerPageContent ? (
                referrerList[this.props.referrer.name].registerPageContent
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.htmlDecode(this.getContent()),
                  }}
                ></p>
              )}
            </p>
            {/* <p>
              {this.props.referrer &&
              referrerList[this.props.referrer.name] &&
              referrerList[this.props.referrer.name].registerPageContent2 ? (
                referrerList[this.props.referrer.name].registerPageContent2
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.htmlDecode(this.getContent('content2')),
                  }}
                ></p>
              )}
            </p> */}
            {this.props.referrer &&
            referrerList[this.props.referrer.name] &&
            referrerList[this.props.referrer.name].registerPageContent2 ? (
              <></>
            ) : (
              <SocialMedia />
            )}
          </div>
        }
        form={
          <div className="sign-up-form">
            <NewUserForm
              onSubmit={this.handleSubmit}
              isDealFuelUser={isDealFuelUser}
            />
            {!this.props.appsumoUser && !this.props.referrer && (
              <Link className="small-text" to="/">
                Or use an existing account
              </Link>
            )}
          </div>
        }
        formTitle={this.getFormTitle()}
        formSubTitle={this.getFormSubTitle()}
        promoImage={this.isPromoActive() ? PROMO_DETAILS.promoImageLight : ''}
        showPartnerLogo={this.props.appsumoUser ? true : false}
        referrerLogo={
          this.props.referrer ? this.props.referrer.logoImageUrl : null
        }
        referrerLogo2={
          this.props.referrer ? this.props.referrer.logoImageUrl2 : null
        }
        referrerStatusText={
          this.props.referrer ? this.props.referrer.statusText : null
        }
        headerText={this.props.referrer ? this.props.referrer.headerText : null}
        subHeaderText={
          this.props.referrer ? this.props.referrer.subHeaderText : null
        }
      />
    );
  }
}

Register.propTypes = {
  history: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
};

Register.defaultProps = {
  error: null,
};

export default Register;
