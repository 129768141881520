import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Subscriptions from './Subscriptions';

import {
  updateField,
  updateStripeApiKey,
  setDefaultRestrictedAccess,
  updatePortalLink,
} from './actions';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';

const mapStateToProps = (state, ownProps) => {
  const defaultGroup = state.auth.groups.data.chunks.default;
  return {
    ...ownProps,
    userSubscriptions: defaultGroup ? defaultGroup.userSubscriptions : false,
    horizontalLayout: defaultGroup ? defaultGroup.horizontalLayout : false,
    desktopLayoutType: defaultGroup
      ? defaultGroup.desktopLayoutType
      : 'standard-layout',
    mobileLayoutType: defaultGroup
      ? defaultGroup.mobileLayoutType
      : 'standard-layout',
    desktopLayoutSize: defaultGroup
      ? defaultGroup.desktopLayoutSize
      : 'full-screen',
    mobileLayoutSize: defaultGroup
      ? defaultGroup.mobileLayoutSize
      : 'full-screen',
    stripeApiTestMode: defaultGroup ? defaultGroup.stripeApiTestMode : false,
    paymentMethodChange: defaultGroup
      ? defaultGroup.paymentMethodChange
      : false,
    stripeApiKey: defaultGroup ? defaultGroup.stripeApiKey : '',
    stripeApiPublicKey: defaultGroup ? defaultGroup.stripeApiPublicKey : '',
    customerPortalLink: defaultGroup ? defaultGroup.customerPortalLink : '',
    isAppAdmin:
      (state.account.auth.profile.admin_appname &&
        state.account.auth.profile.admin_appname.length > 0) ||
      false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateField: (value, field) =>
    dispatch(updateField(ownProps.match.params.appname, value, field)),
  updateStripeApiKey: (value, type) =>
    dispatch(updateStripeApiKey(ownProps.match.params.appname, value, type)),
  updatePortalLink: (value, type) =>
    dispatch(updatePortalLink(ownProps.match.params.appname, value, type)),
  setDefaultRestrictedAccess: isSubscriptionEnabled =>
    dispatch(
      setDefaultRestrictedAccess(
        ownProps.match.params.appname,
        isSubscriptionEnabled,
      ),
    ),
  copyTextSuccess: message => dispatch(openSnackbar(message)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subscriptions),
);
