import { combineReducers } from 'redux';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import _pickBy from 'lodash/pickBy';

import newFilter from './newFilter/reducer';
import edit from './editFilter/reducer';

import {
  REQUEST_FILTERS,
  RECEIVE_FILTERS,
  REPORT_FILTERS_REQUEST_ERROR,
  SELECT_FILTER,
  FILTER_DATA,
  SELECT_ALL_FILTERS,
  DESELECT_ALL_FILTERS,
} from './types';

const initialState = {
  pending: false,
  chunks: {},
  selected: [],
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
};

const filterChunks = (chunks, filterHolder = '') => {
  let filteredChunks = chunks;
  const filter = filterHolder.replace(/[^a-zA-Z0-9 ]/g, '');

  if (filter !== '') {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`(${filter})`, 'gi');

      return rgx.test(chunk.name);
    });
  }
  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const filters = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FILTERS:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_FILTERS:
      return {
        ...state,
        chunks: action.value,
        selected: [],
        filteredChunks: action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };

    case FILTER_DATA:
      return {
        ...state,
        ...filterChunks(state.chunks, action.payload),
        filter: action.payload,
      };

    case REPORT_FILTERS_REQUEST_ERROR:
      return {
        pending: false,
        error: action.err,
      };

    case SELECT_FILTER: {
      const prevState = state.filteredChunks[action.key].selected;
      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case SELECT_ALL_FILTERS: {
      console.log('selected all');
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: !chunk.default,
      }));
      const selected = _union(
        state.selected,
        Object.keys(filteredChunks).filter(chunk => chunk !== 'default'),
      );

      return {
        ...state,
        filteredChunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_FILTERS:
      return {
        ...state,
        filteredChunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    default:
      return state;
  }
};

export default combineReducers({
  data: filters,
  newFilter,
  edit,
});
