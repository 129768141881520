import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './styles.css';
import BookingImage from './images/booking.jpg';
import FoodImage from './images/food-orders.jpg';
import MarketplaceImage from './images/marketplace.jpg';

const EcommerceIntro = () => {
  const { t } = useTranslation();
  const businessTools = [
    {
      id: 1,
      title: t('Food Orders'),
      description: t(
        'The Food orders component can be used to allow your app users to place food orders. Add food inventory, accept orders, assign orders to drivers and deliver them directly to your customers.',
      ),
      image: BookingImage,
    },
    {
      id: 2,
      title: t('Booking'),
      description: t(
        'The Booking Component can be used to create Appointments for certain services offered by you and accept paid or free Bookings for these Appointments.',
      ),
      image: './images/booking.jpg',
    },
    {
      id: 3,
      title: t('Marketplace'),
      description: t(
        'The Marketplace Component can be used to buy and sell products or services using your app. You can set up an online storefront where your app users can signup to buy products. You can also let validated users sell their products/services in your marketplace app.',
      ),
      image: './images/marketplace.jpg',
    },
  ];
  return (
    <div className="intro-wrapper">
      <div>
        <h4 className="page-subTitle">
          <Trans>Business Tools</Trans>
        </h4>
        <p className="page-description">
          <Trans>
            The Business Tools section allows you to configure the components
            which are used to monetize your apps. You can enable the following
            components under Business Tools:
          </Trans>
        </p>
      </div>

      <div className="section-wrapper">
        {businessTools.map((item, index) => (
          <div
            key={item.id}
            className={`section ${index < 2 ? 'section-border' : ''}`}
          >
            <h6 className="page-subTitle">{item.title}</h6>
            <p className="page-description">{item.description}</p>
            <div>
              <img
                src={
                  index === 0
                    ? FoodImage
                    : index === 1
                    ? BookingImage
                    : MarketplaceImage
                }
                alt={item.title}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EcommerceIntro;
