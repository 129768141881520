import firebase from '../../firebase';
import { STAFF_ADMIN } from '../../config';

// expose logout, getSubscriptions method
export { logout } from './modules/auth/actions';
export { getSubscriptions } from './modules/subscriptions/actions';

export const HYDRATE_INTL_CONFIG = 'HYDRATE_INTL_CONFIG';
export const UPDATE_WIX_SMS_URLS = 'UPDATE_WIX_SMS_URLS';

export const toggleAccountModal = () => ({
  type: 'TOGGLE_ACCOUNT_MODAL',
});

export const hydrateAccount = data => ({
  type: 'HYDRATE_ACCOUNT',
  data,
});

const hydrateIntlConf = data => ({
  type: HYDRATE_INTL_CONFIG,
  data,
});

const updateWixSmsUrls = (prop, data) => ({
  type: UPDATE_WIX_SMS_URLS,
  prop,
  data,
});

export const updateAccount = (prop, data) => ({
  type: 'UPDATE_ACCOUNT',
  prop,
  data,
});

export const listenToAccount = accountId => dispatch => {
  dispatch({ type: 'LISTEN_TO_ACCOUNT', accountId });

  const accountRef = firebase.database().ref(`accounts/${accountId}`);
  const populate = prop => snapshot => {
    const data = snapshot.val();
    dispatch(updateAccount(prop, data));
  };

  // values
  accountRef.child('apps').on('value', populate('apps'));
  accountRef.child('cards').on('value', populate('cards'));
  accountRef.child('free_trial').on('value', populate('free_trial'));
  accountRef.child('sms_credit').on('value', populate('sms_credit'));
  accountRef
    .child('push_notifications_credit')
    .on('value', populate('push_notifications_credit'));
  accountRef.child('sms_flag').on('value', populate('sms_flag'));
  accountRef.child('notices').on('value', populate('notices'));
  accountRef
    .child('offerEligibility')
    .on('value', populate('offerEligibility'));
  accountRef.child('legacy_flag').on('value', populate('legacy_flag'));
  accountRef
    .child('whatsapp_sms_credit')
    .on('value', populate('whatsapp_sms_credit'));
};

// Fetch the wix URLs where the user can purchase SMS
const fetchWixSMSPackages = () => dispatch => {
  const onError = err =>
    console.log('Error at Wix.Dashboard.getProduct():', err);

  const onSuccess = data => {
    const wixSMSPackages = {};

    // In the editor, we use data, in the dashboard there's an extra object called 'packages' and we'll use data.packages
    const packages = data.packages || data;

    packages.forEach(premiumPackage => {
      const { name } = premiumPackage;
      if (name !== 'Premium Plan') {
        let url = premiumPackage.oneTime.url || premiumPackage.oneTime.Link;

        if (url) {
          url = url.replace(
            'www.wix.com/apps/upgrade/order-checkout',
            'premium.wix.com/wix/api/tpaStartPurchase',
          );

          url = url.replace('planId', 'vendorProductId');
        }

        wixSMSPackages[name] = url;
      }
    });

    dispatch(updateWixSmsUrls('wixSMSPackages', wixSMSPackages));
  };

  window.Wix.Dashboard.getProducts(onSuccess, onError);
};

/**
 * {string} - accountId
 * @returns {Promise} - loads account from id
 */
export const loadAccount = accountId => dispatch => {
  dispatch({ type: 'LOADING_ACCOUNT' });
  return new Promise((resolve, reject) => {
    const accountRef = firebase.database().ref(`accounts/${accountId}`);
    accountRef.on('value', snap => {
      if (snap.val().role === STAFF_ADMIN) {
        // accountRef = firebase
        //   .database()
        //   .ref(`accounts/${snap.val().parentAccount}`);
        // // eslint-disable-next-line no-param-reassign
        // accountId = snap.val().parentAccount;
      }
    });
    Promise.all([
      accountRef.child('apps').once('value'),
      accountRef.child('cards').once('value'),
      accountRef.child('free_trial').once('value'),
      accountRef.child('sms_credit').once('value'),
      accountRef.child('push_notifications_credit').once('value'),
      accountRef.child('organisation').once('value'),
      accountRef.child('workfield').once('value'),
      accountRef.child('city').once('value'),
      accountRef.child('country').once('value'),
      accountRef.child('notices').once('value'),
      accountRef.child('trial_expired').once('value'),
      accountRef.child('sms_flag').once('value'),
      accountRef.child('legacy_flag').once('value'),
      accountRef.child('integration').once('value'),
      accountRef.child('siteUrl').once('value'),
      accountRef.child('siteTitle').once('value'),
      accountRef.child('weeblyOAuthFinish').once('value'),
      accountRef.child('premiumPackageId').once('value'),
      accountRef.child('offerEligibility').once('value'),
      accountRef.child('createdAt').once('value'),
      accountRef.child('default_email').once('value'),
      accountRef.child('appsumo_id').once('value'),
      accountRef.child('referrer').once('value'),
      accountRef.child('referralOfferEligibility').once('value'),
      accountRef.child('redeemedCoupons').once('value'),
      accountRef.child('is_on_14_days_trial_period').once('value'),
      accountRef.child('account_type').once('value'),
      accountRef.child('lastRedeemedCoupon').once('value'),
      accountRef.child('under_review').once('value'),
      accountRef.child('passed_review').once('value'),
      accountRef.child('appsumo_2022').once('value'),
      accountRef.child('whatsapp_sms_credit').once('value'),
      accountRef.child('sms_restricted').once('value'),
      accountRef.child('parentAccount').once('value'),
      accountRef.child('ref').once('value'),
      accountRef.child('license_key').once('value'),
    ])
      .then(
        ([
          apps,
          cards,
          freeTrial,
          smsCredit,
          pushNotificationCredit,
          organisation,
          workfield,
          city,
          country,
          notices,
          trialExpired,
          smsFlag,
          legacyFlag,
          integration,
          siteUrl,
          siteTitle,
          weeblyOAuthFinish,
          premiumPackageId,
          offerEligibility,
          createdAt,
          email,
          appsumo_id,
          referrer,
          referralOfferEligibility,
          redeemedCoupons,
          isOn14DaysTrial,
          accountType,
          lastRedeemedCoupon,
          underReview,
          passedReview,
          appsumo22,
          whatsappSMSCredit,
          sms_restricted,
          parentAccount,
          ref,
          license_key,
        ]) => {
          const basicData = {
            apps: apps.val(),
            cards: cards.val(),
            free_trial: freeTrial.val(),
            sms_credit: smsCredit.val(),
            push_notifications_credit: pushNotificationCredit.val(),
            organisation: organisation.val(),
            workfield: workfield.val(),
            city: city.val(),
            country: country.val(),
            notices: notices.val(),
            key: accountId,
            legacy_flag: legacyFlag.val() || false,
            trial_expired: trialExpired.val(),
            offerEligibility: offerEligibility.val(),
            created_at: createdAt.val(),
            default_email: email.val(),
            appsumo_id: appsumo_id.val() || '',
            referrer: referrer.val() || null,
            referralOfferEligibility: referralOfferEligibility.val() || null,
            redeemedCoupons: redeemedCoupons.val() || null,
            isOn14DaysTrial: isOn14DaysTrial.val() || false,
            accountType: accountType.val() || null,
            lastRedeemedCoupon: lastRedeemedCoupon.val() || null,
            underReview: underReview.val() || null,
            passedReview: passedReview.val() || null,
            appsumo22: appsumo22.val() || false,
            whatsapp_sms_credit: whatsappSMSCredit.val(),
            sms_restricted: sms_restricted.val() || false,
            parentAccount: parentAccount.val() || null,
            ref: ref.val() || false,
            license_key: license_key.val() || false,
          };

          const integrationData = integration.val() && {
            type: integration.val(),
            siteUrl: siteUrl.val(),
            siteTitle: siteTitle.val(),
            weeblyOAuthFinish: weeblyOAuthFinish.val(),
            premiumPackageId: premiumPackageId.val(),
          };

          if (integrationData && integrationData.type === 'Wix') {
            dispatch(fetchWixSMSPackages());
          }

          dispatch(
            hydrateAccount({
              ...basicData,
              integration: integrationData,
            }),
          );

          dispatch(
            hydrateIntlConf({
              currency: integration.val() ? 'USD' : 'GBP',
            }),
          );
          resolve(accountId);

          dispatch(listenToAccount(accountId));
        },
      )
      .catch(reject);
  });
};

export const closeAppSumoWelcome = accountId => dispatch => {
  //dispatch(updateAccount('appsumo_welcome', false));
  const accountRef = firebase.database().ref(`accounts/${accountId}`);
  accountRef.update({ appsumo_welcome: false });
};
