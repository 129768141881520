import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';

import TextField from '../../../../../../../../app/components/textField';
import { FlatButton } from '../../../../../../../../app/components/buttons';
import CircularValidation from '../../../../../../../../app/components/circularValidation';
import { CircularProgress } from '../../../../../../../../app/components/progress';

import './styles.css';

const NewMember = ({
  open,
  pending,
  error,
  onSubmit,
  onRequestClose,
  firstName,
  lastName,
  email,
  password,
  onFieldChange,
}) => {
  const displayError = error ? (
    <div>
      <strong>{error.message}</strong>
    </div>
  ) : null;

  const groupForm = (
    <div>
      <h5>Create a Workforce Member</h5>
      <TextField
        id="firstName"
        placeholder="First Name"
        {...firstName}
        onChange={value => onFieldChange('firstName', value)}
        rightInputAction={
          <CircularValidation
            isValid={firstName.isValid}
            defaultMsg="Staff's first name required"
            errorMsg="First name must be at least 2 characters long"
            showDefault={!firstName.value}
          />
        }
      />
      <TextField
        id="lastName"
        placeholder="Last Name"
        {...lastName}
        onChange={value => onFieldChange('lastName', value)}
        rightInputAction={
          <CircularValidation
            isValid={lastName.isValid}
            defaultMsg="Staff's last name required"
            errorMsg="Last name must be at least 2 characters long"
            showDefault={!lastName.value}
          />
        }
      />
      <TextField
        id="email"
        placeholder="Email"
        {...email}
        onChange={value => onFieldChange('email', value)}
        rightInputAction={
          <CircularValidation
            isValid={email.isValid}
            defaultMsg="Email address required"
            errorMsg="Please use a valid email address"
            showDefault={!email.value}
          />
        }
      />
      <TextField
        id="password"
        placeholder="Password"
        type="password"
        {...password}
        onChange={value => onFieldChange('password', value)}
        rightInputAction={
          <CircularValidation
            isValid={password.isValid}
            defaultMsg="Password required"
            errorMsg="Password must be at least 6 characters long"
            showDefault={!password.value}
          />
        }
      />
    </div>
  );

  const buttonSubmit = !pending ? (
    <FlatButton
      label={'Create'}
      onClick={onSubmit}
      disabled={
        !firstName.isValid ||
        !lastName.isValid ||
        !email.isValid ||
        !password.isValid
      }
      primary
    />
  ) : (
    <CircularProgress size={18} />
  );

  return (
    <Dialog open={open} onRequestClose={onRequestClose} autoScrollBodyContent>
      <div className="add-group">
        <div className="modal-controls-container">
          <IconButton onClick={onRequestClose}>
            <Close />
          </IconButton>
        </div>
        <div className="list-fieldset">{displayError}</div>
        {groupForm}
        {buttonSubmit}
      </div>
    </Dialog>
  );
};

NewMember.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.object,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  firstName: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  lastName: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  email: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  password: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
};

NewMember.defaultProps = {
  error: null,
};

export default NewMember;
