import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { deleteItemFromInventory } from '../../../api';
import DialogConfirmation from '../../../../../app/components/dialogConfirmation';
import styles from './styles.module.scss';

const DeleteButton = props => {
  const queryClient = useQueryClient();
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const { mutateAsync, isLoading } = useMutation(deleteItemFromInventory);
  const { t } = useTranslation();

  const {
    isButtonDisabled,
    setIsButtonDisabled,
    onAddInventoryDialogOpen,
    onInventoryItemChange,
    appname,
    category,
    itemKey,
  } = props;

  const handleDelete = async () => {
    setIsButtonDisabled(true);
    await mutateAsync({ appname, category, key: itemKey });
    queryClient.invalidateQueries('inventoryItems');
    onAddInventoryDialogOpen(false);
    onInventoryItemChange({});
    setIsButtonDisabled(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setDeleteConfirmDialog(true)}
        className={styles.button_delete}
        disabled={isButtonDisabled}
      >
        {isLoading ? t('DELETING...') : t('DELETE')}
      </button>
      <DialogConfirmation
        open={deleteConfirmDialog}
        onClose={() => setDeleteConfirmDialog(false)}
        action={handleDelete}
        message={t('Are you sure you want to permanently delete this item?')}
      ></DialogConfirmation>
    </>
  );
};

export default DeleteButton;
