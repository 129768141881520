import firebase from '../../../../../firebase';

export const ADD_ICON_LISTENER = 'ADD_ICON_LISTENER';
export const ICON_DATA_UPDATED = 'ICON_DATA_UPDATED';
export const REMOVE_ICON_LISTENER = 'REMOVE_ICON_LISTENER';

const iconListenerAdded = appname => ({
  type: ADD_ICON_LISTENER,
  appname,
});

const iconDataUpdated = (appname, modified) => ({
  type: ICON_DATA_UPDATED,
  appname,
  modified,
});

const iconListenerRemoved = appname => ({
  type: REMOVE_ICON_LISTENER,
  appname,
});

export const addIconListener = appname => dispatch => {
  const iconRef = firebase.database().ref(`icons/${appname}/`);
  iconRef.on('child_changed', () => dispatch(iconDataUpdated(appname, true)));
  dispatch(iconListenerAdded(appname));
};

export const removeIconListener = appname => dispatch => {
  const iconRef = firebase.database().ref(`icons/${appname}/`);
  iconRef.off('child_changed');
  dispatch(iconListenerRemoved(appname));
};
