import i18n from 'i18next';
import firebase from '../../../../../../firebase';

export const updateField = (appname, toggled, field) => dispatch =>
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default`)
    .update({ [`${field}`]: toggled })
    .then(() =>
      dispatch({
        type: 'TOGGLE_FIELD',
        appname,
        field,
        toggled,
      }),
    );

export const updateStripeApiKey = (appname, key, index) => dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default`)
    .update({ [index]: key })
    .then(() =>
      dispatch({
        type: 'UPDATE_STRIPE_API_KEY',
        key,
      }),
    );
};
export const updatePortalLink = (appname, key, index) => dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default`)
    .update({ [index]: key })
    .then(() =>
      dispatch({
        type: 'UPDATE_PORTAL_LINK',
        key,
      }),
    );
};

export const setDefaultRestrictedAccess = (
  appname,
  isSubscriptionEnabled,
) => dispatch => {
  if (isSubscriptionEnabled) {
    const defaultRestrictedAccessLayout = {
      headerText: i18n.t('Subscribe Now'),
      subHeaderText: i18n.t('Subscribe now to get more features'),
      layout: 'subscriptions',
    };
    firebase
      .database()
      .ref(`app_groups/${appname}/groups/default/restrictedaccess`)
      .once('value', snapshot => {
        if (!snapshot.exists()) {
          firebase
            .database()
            .ref(`app_groups/${appname}/groups/default/restrictedaccess`)
            .set(defaultRestrictedAccessLayout);
          dispatch({
            type: 'SET_DEFAULT_RESTRICTED_ACCESS_LAYOUT',
            payload: defaultRestrictedAccessLayout,
          });
        }
      });
  }
};
