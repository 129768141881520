import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '../../../modules/account/components/modal';
import { rocketSignUp } from './images';
import { CTAButton } from '../../../app/components/buttons';
import './styles.css';

const Container = styled.div`
  padding: 0 15px 15px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-flow: inherit;
  max-height: 100%;
  .img_wrapper {
    position: relative;
    display: block;
    height: 50%;
    img {
      max-width: 75%;
      max-height: 70%;
      padding: 10px 20px 0;
    }
  }
`;

const marginStyle = {
  marginTop: '35px',
};

const AppSumoPublishNotice = ({
  accountId,
  isAppSumAccount,
  appsumo_publish,
  closePublish,
  checkAppSumoPublishNoticeOccurence,
  appname,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    checkAppSumoPublishNoticeOccurence(accountId);
  }, [accountId]);

  const handleClose = publish => {
    closePublish(appname, accountId, publish);
  };

  const stateContentText = () => {
    return (
      <span style={marginStyle}>
        <strong>
          <Trans>So, you have started building your app?! Great news!</Trans>
        </strong>
        <div>
          <p>
            <Trans>
              Please click on the Publish button to be able to see the first
              version of your app. You can re-publish the app with new content
              as many times as you like, and click on VIEW APP to check out the
              Desktop version of the app. If you’d like to view your app version
              on Mobile, simply copy the app URL:
            </Trans>
            {appname}.{' '}
            <Trans>
              beezer.com and paste it in the browser of your mobile device. To
              add it as an app - please click on the Add to Home screen pop-up
              and follow the instructions.
            </Trans>
          </p>
        </div>
      </span>
    );
  };

  return (
    <Modal
      open={appsumo_publish && isAppSumAccount}
      onRequestClose={() => handleClose(false)}
      showHeader={true}
      bodyClassName="beezer-modal"
    >
      <Container>
        {stateContentText()}
        <div className="img_wrapper">
          <img alt="modal_image" src={rocketSignUp} />
        </div>
        <CTAButton
          label={t('Publish Your App Now')}
          // borderColor="rgb(156, 150, 6)"
          outlineRGB={'195, 207, 47'}
          style={{ margin: '10px auto', display: 'block', maxWidth: '230px' }}
          onClick={() => handleClose(true)}
        />
      </Container>
    </Modal>
  );
};

export default AppSumoPublishNotice;
