import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import Reports from './Reports';
import { getMemberBodies } from '../../../memberLogin/modules/memberBodies/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  plans: state.account.subscriptions.items,
  integrationType: _get(state, 'account.organisation.integration.type'),
  appname: ownProps.match.params.appname,
  memberBodies: state.auth.memberBodies.data.chunks,
  workforceGroups: state.auth.memberGroups.listPage.data.chunks,
  typesData: state.auth.memberGroups.listPage.data.typesData,
  workforceMembers: state.auth.workforceMembers.listPage.data.chunks,
  staffUsers: state.auth.staff.data.chunks,
  users: state.auth.users.chunks,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMemberBodies: () =>
    dispatch(getMemberBodies(ownProps.match.params.appname)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Reports),
);
