import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import { withTranslation } from 'react-i18next';

import './styles.css';
import themeStyles from '../../../_export.scss';

const styles = {
  input: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  underline: {
    borderColor: 'rgba(0, 0, 0, 0.42)',
    borderWidth: '1px',
  },
  underlineFocus: {
    borderColor: themeStyles.primaryColor,
    borderWidth: '1px',
  },
  hintText: {
    // fontSize: 12,
    // color: 'rgba(0, 0, 0, 0.45)',
    // maxWidth: '100%',
    // overflow: 'hidden',
    // whiteSpace: 'nowrap',
    color: '#00000!important',
    fontFamily: 'Raleway, Karla, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '23px',
    letterSpacing: '0.2px',
  },
  hintTextFocus: {
    color: themeStyles.primaryColor,
  },
  errorText: {
    fontSize: 12,
    position: 'absolute',
    bottom: -22,
    height: 25,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.45)',
  },
  asyncInput: {
    paddingRight: '22px',
  },
  helperText: {
    fontSize: '12px',
    display: 'inline-block',
    marginTop: '12px',
  },
};

const applyStyle = (base, custom) => ({ ...base, ...custom });

const getBasicType = type => {
  switch (type) {
    case 'domain':
      return 'text';

    case 'confirm_password':
      return 'password';

    default:
      return type;
  }
};

class BeezerTextField extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.oneOf([
      'text',
      'email',
      'password',
      'confirm_password',
      'domain',
      'number',
    ]),
    className: PropTypes.string,
    value: PropTypes.string,
    isValid: PropTypes.bool,
    pending: PropTypes.bool,
    multiLine: PropTypes.bool,
    placeholder: PropTypes.string, // TODO change to floatingLabel
    hidden: PropTypes.bool,
    hintText: PropTypes.string,
    helperText: PropTypes.string,
    required: PropTypes.bool,
    rowsMax: PropTypes.number,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    isAvailable: PropTypes.bool,
    debounce: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    showCounter: PropTypes.bool,
    style: PropTypes.object,
    inputStyle: PropTypes.object,
    labelStyle: PropTypes.object,
    underlineStyle: PropTypes.object,
    rightInputAction: PropTypes.node,
    rightActionContianerStyle: PropTypes.object,
    feedback: PropTypes.string,
    disabled: PropTypes.bool,
    errorText: PropTypes.string,
    showRequiredAsterick: PropTypes.bool,
    applyPgmolLabelStyle: PropTypes.bool,
    rows: PropTypes.number,
    t: PropTypes.func.isRequired,
    disableOverflow: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    type: 'text',
    className: '',
    isValid: null,
    isAvailable: null,
    debounce: false,
    pending: false,
    multiLine: false,
    rowsMax: 5,
    required: false,
    placeholder: null,
    hidden: false,
    hintText: null,
    helperText: null,
    maxLength: null,
    minLength: null,
    onBlur: null,
    onChange: () => {},
    showCounter: false,
    style: {},
    inputStyle: {},
    labelStyle: {},
    underlineStyle: {},
    rightInputAction: null,
    rightActionContianerStyle: {},
    feedback: null,
    disabled: false,
    errorText: '',
    onKeyPress: () => {},
    showRequiredAsterick: false,
    applyPgmolLabelStyle: false,
    rows: 1,
    disableOverflow: false,
    min: null,
  };

  state = {
    value: this.props.value || '',
    isValid: this.props.isValid,
    isAvailable: this.props.isAvailable,
    type: getBasicType(this.props.type),
  };

  componentWillMount() {
    this.isExecuting = false;
    this.next = null;

    this.lastUpdatedValue = this.state.value;
  }

  componentWillReceiveProps(nextProps) {
    const update = {};
    let shouldUpdate = false;

    if (!this.props.debounce && nextProps.value !== this.state.value) {
      update.value = nextProps.value;
      shouldUpdate = true;
    }

    if (this.lastUpdatedValue !== nextProps.value) {
      update.value = nextProps.value;
      shouldUpdate = true;
    }

    if (this.state.isValid !== nextProps.isValid) {
      update.isValid = nextProps.isValid;
      shouldUpdate = true;
    }

    if (this.state.pending !== nextProps.pending) {
      update.pending = nextProps.pending;
      shouldUpdate = true;
    }

    if (this.state.isAvailable !== nextProps.isAvailable) {
      update.isAvailable = nextProps.isAvailable;
      shouldUpdate = true;
    }

    // e.g. if we want to validate an input on submit
    if (this.props.isValid === undefined && nextProps.isValid !== undefined) {
      update.isValid = nextProps.isValid;
      shouldUpdate = true;
    }

    if (shouldUpdate) {
      this.setState(update);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.debounce) {
      return (
        this.state.value !== nextState.value ||
        this.state.isValid !== nextState.isValid ||
        this.state.isAvailable !== nextState.isAvailable ||
        this.state.pending !== nextState.pending
      );
    }

    return true;
  }

  onChange = (e, value) => {
    let escapedValue = this.props.accessCheck ? this.escapedStr(value) : value;
    this.setState({ value: escapedValue }, () => {
      if (this.props.debounce) {
        return this.debounce();
      }
      return this.props.onChange(escapedValue);
    });
  };

  escapedStr = rawStr =>
    String(rawStr)
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;');

  onBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  getFeedback = () => {
    const { t } = this.props;
    if (this.props.isValid === false) {
      if (this.props.required && this.props.errorText) {
        return this.props.errorText;
      }
      if (this.props.required && this.state.value === '') {
        return `* ${t('This field is required')}`;
      }

      if (this.props.type === 'password') {
        if (this.props.minLength - this.state.value.length > 0) {
          return `${t('Your password must be at least')} ${
            this.props.minLength
          } ${t('characters long')}`;
        }

        return t('Password incorrect');
      }

      if (this.props.type === 'confirm_password') {
        return t('These passwords do not match');
      }
      // !(/^[a-z0-9_]*$/g.test(this.state.value))
      if (this.props.type === 'domain') {
        return t(
          'Domains should be lowercase and not contain any spaces or special characters.',
        );
      }

      if (this.props.type === 'email') {
        return t('Please use a valid email address');
      }

      if (this.props.maxLength) {
        return `${this.props.maxLength - this.state.value.length} ${t(
          'characters left',
        )}`;
      }
    }

    if (this.state.isAvailable === false && !this.props.pending) {
      return `${this.state.value} ${t('is already in use.')}`;
    }

    return '';
  };

  getCounter = () => {
    const { t } = this.props;
    return `${t('Required field')}: ${this.props.maxLength -
      this.state.value.length} ${t('characters left')}`;
  };

  getErrorStyle = () =>
    this.props.isValid !== false && this.state.isAvailable !== false
      ? { ...styles.errorText, ...styles.hintText }
      : styles.errorText;

  applyDelayedUpdate = value => {
    this.isExecuting = true;

    setTimeout(() => {
      this.lastUpdatedValue = value;
      this.props.onChange(value);
      this.isExecuting = false;
    }, 300);
  };

  queueUpdate = value => {
    if (this.next) {
      clearTimeout(this.next);
    }

    this.next = setTimeout(() => {
      this.applyDelayedUpdate(value);
      this.next = null;
    }, 300);
  };

  debounce = () => {
    const { value } = this.state;

    if (this.isExecuting) {
      this.queueUpdate(value);
      return null;
    }

    return this.applyDelayedUpdate(value);
  };

  render() {
    const { helperText, disableOverflow } = this.props;
    if (this.props.hidden) {
      return null;
    }

    const errorStyle = this.getErrorStyle();
    const feedback =
      this.getFeedback() ||
      this.props.feedback ||
      (this.props.showCounter ? this.getCounter() : null);

    const inputStyle =
      this.props.pending !== undefined ? styles.asyncInput : {};
    return (
      <div
        style={this.props.style}
        className={`text-field-wrapper ${this.props.className}`}
      >
        <TextField
          id={this.props.id}
          {...(this.props.min && { min: this.props.min })}
          min={this.props.min}
          className={disableOverflow ? 'overflow-disable' : ''}
          type={this.state.type}
          value={this.state.value}
          multiLine={this.props.multiLine}
          rowsMax={this.props.rowsMax}
          rows={this.props.rows}
          onChange={this.onChange}
          onBlur={this.onBlur}
          style={applyStyle(styles.input, this.props.inputStyle)}
          inputStyle={inputStyle}
          floatingLabelText={
            <span
              className={`${this.props.showRequiredAsterick &&
                'required-asterik'} ${this.props.applyPgmolLabelStyle &&
                'pgmol-label-style '}`}
            >
              {this.props.placeholder}
            </span>
          }
          hintText={this.props.hintText}
          hintStyle={styles.hintText}
          floatingLabelStyle={applyStyle(
            styles.hintText,
            this.props.labelStyle,
          )}
          floatingLabelFocusStyle={styles.hintTextFocus}
          underlineFocusStyle={applyStyle(
            styles.underlineFocus,
            this.props.underlineStyle,
          )}
          errorText={feedback}
          errorStyle={errorStyle}
          underlineStyle={applyStyle(
            styles.underline,
            this.props.underlineStyle,
          )}
          disabled={this.props.disabled}
          onKeyPress={this.props.onKeyPress}
          fullWidth
        />
        {helperText && <span style={styles.helperText}>{helperText}</span>}
        {this.props.rightInputAction && (
          <span
            style={this.props.rightActionContianerStyle}
            className="right-input-action"
          >
            {this.props.rightInputAction}
          </span>
        )}
      </div>
    );
  }
}

export default withTranslation()(BeezerTextField);
