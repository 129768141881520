import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import History from './History';

import {
  listenForEvent,
  removeEventListener,
  searchShareHistory,
  selectHistory,
  selectAllHistories,
  deselectAllHistories,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.distribute.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSearch: query => dispatch(searchShareHistory(query)),
  setListener: () => dispatch(listenForEvent(ownProps.match.params.appname)),
  removeListener: type =>
    dispatch(removeEventListener(ownProps.match.params.appname, type)),
  onSelect: key => dispatch(selectHistory(key)),
  onSelectAllHistories: () => dispatch(selectAllHistories()),
  onDeselectAllHistories: () => dispatch(deselectAllHistories()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(History),
);
