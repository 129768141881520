import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import { FormattedNumber } from 'react-intl';
import { withTranslation } from 'react-i18next';
import { CircularProgress } from '../progress';

import { FlatButton } from '../buttons';

class AddToPlanDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    heading: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  };

  static defaultProps = {
    open: false,
    heading: '',
    content: '',
  };

  componentWillUnmount() {
    this.props.onRequestClose();
  }

  render() {
    const {
      open,
      onRequestClose,
      component,
      heading,
      image,
      onAddonAdd,
      isProcessing,
      subscription,
      intl,
      t,
    } = this.props;

    const period = subscription.period;
    const perPeriodText = period === 'yearly' ? t('year') : t('month');
    const price = period === 'yearly' ? component.price * 12 : component.price;

    return (
      <Dialog
        open={open}
        onRequestClose={onRequestClose}
        autoScrollBodyContent
        contentClassName="user-groups-modal"
        paperClassName="user-groups-paper"
      >
        {isProcessing ? (
          <CircularProgress color="secondary" />
        ) : (
          <div className="ml-upgrade-dialog-content">
            <h3>{heading}</h3>
            <img
              width={24}
              src={image}
              alt="DIY Addon"
              style={{ position: 'relative', top: 1 }}
            />
            <p>
              {`${t('Do you want to add')} ${component.name} ${t('at')} `}
              <FormattedNumber
                maximumFractionDigits={2}
                format={intl.currency}
                value={price}
              />
              {`/${perPeriodText}`}
            </p>
            <FlatButton
              label={t('Add')}
              onClick={() => {
                onAddonAdd(component);
              }}
              primary
            />
          </div>
        )}
      </Dialog>
    );
  }
}

export default withTranslation()(AddToPlanDialog);
