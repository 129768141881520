import { TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: 'black',
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default StyledTableCell;
