import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Tabs, Tab } from '../../../../../components/tabs';
import TextField from '../../../../../../../app/components/textField';

import Container from '../../../../../../../app/components/container';
import ErrorMessage from '../../../../../../../app/components/errorMessage';
import SectionHeader from '../../../../../components/sectionHeader';

const Loyalty = props => {
  const { data } = props;
  const { t } = useTranslation();

  const [error, setError] = useState(false);

  const isUrlValid = userInput => {
    const res = userInput.match(/^(https?:\/\/)/i);
    if (res == null) return false;
    else return true;
  };

  const handleTextChange = prop => value => {
    console.log(prop, value);
    if (prop === 'zoomLeaveUrl' && !isUrlValid(value)) {
      setError(true);
      return;
    }
    if (error) {
      setError(false);
    }
    props.onChange(prop, value);
  };

  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container>
          <SectionHeader
            title={t('SDK Key or Client ID')}
            tooltip={t('SDK Key or Client ID of your zoom app')}
            borderTop
          />
          <TextField
            id="test"
            placeholder={t('SDK Key or Client ID of your zoom app')}
            value={data.sdkKey}
            onChange={handleTextChange('sdkKey')}
          />
          <SectionHeader
            title={t('SDK Secret or Client Secret')}
            tooltip={t('SDK Secret or Client Secret of your zoom app.')}
            borderTop
          />
          <TextField
            id="test"
            placeholder={t('Enter your SDK Secret or Client Secret')}
            value={data.secretKey}
            onChange={handleTextChange('secretKey')}
          />
          <SectionHeader
            title={t('Personal Meeting ID (PMI)')}
            tooltip={t('Your ten digit zoom PMI number.')}
            borderTop
          />
          <TextField
            id="test"
            placeholder={t('E.g. 6853770209')}
            value={data.pmi}
            onChange={handleTextChange('pmi')}
          />
          <SectionHeader
            title={t('Password')}
            tooltip={t('Your six digit zoom PMI number.')}
            borderTop
          />
          <TextField
            id="test"
            placeholder={t('E.g. 4aA2dL')}
            value={data.zoomPassword}
            onChange={handleTextChange('zoomPassword')}
          />
          <SectionHeader
            title={t('Leave Url')}
            tooltip={t('Url you want to redirect to when the meeting ends.')}
            borderTop
          />
          <TextField
            id="test"
            placeholder={t('URL of the website')}
            hintText="https://www.example.com/"
            value={data.zoomLeaveUrl}
            onChange={handleTextChange('zoomLeaveUrl')}
          />
          {error && (
            <ErrorMessage>
              <Trans>Url should start with http or https</Trans>
            </ErrorMessage>
          )}
          <div style={{ padding: 18, fontWeight: 'bold' }}>
            <Trans>Go to Engage -</Trans>&gt;{' '}
            <Trans>
              Zoom in order to set zoom meeting host and assistant(s) if any.
            </Trans>
          </div>
        </Container>
      </Tab>
      <Tab
        label={t('design')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
      <Tab
        label={t('layout')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
    </Tabs>
  );
};

export default Loyalty;
