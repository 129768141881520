import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import { Trans, withTranslation } from 'react-i18next';
import ResultsTable from 'app/components/resultsTable';
import Header from 'app/components/header';
import ActionMenu from 'app/components/actionMenu';
import { formatDate, downloadCSV } from 'utils';
import VoteDetails from './voteDetails';
import TableCheckbox from '../../components/tableCheckbox';
import './styles.css';
import themeStyles from '../../../../_export.scss';

const isCheckboxDisabled = (error, pending) => error && !pending;

class Vote extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    items: PropTypes.array.isRequired,
    chunks: PropTypes.object,
    error: PropTypes.string,
    filter: PropTypes.string,
    getVoteData: PropTypes.func.isRequired,
    watchVoteData: PropTypes.func.isRequired,
    removeVoteDataWatcher: PropTypes.func.isRequired,
    getAllVotes: PropTypes.func.isRequired,
    onFilterVoteData: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSelectAllResponses: PropTypes.func.isRequired,
    onDeselectAllResponses: PropTypes.func.isRequired,
    onOpenVoteDetail: PropTypes.func.isRequired,
    onOpenFormDetail: PropTypes.func.isRequired,
    selectAll: PropTypes.bool,
    votes: PropTypes.array.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
    onSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    colSorted: PropTypes.string.isRequired,
    appname: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    subscription: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: '',
    filteredChunks: {},
    chunks: {},
    selectAll: false,
  };

  componentDidMount() {
    this.props.getVoteData();
    this.props.watchVoteData();
    this.props.getAllVotes();
  }

  componentWillUnmount() {
    this.props.removeVoteDataWatcher();
  }

  getSelectAllCheckbox = () => {
    const {
      onSelectAllResponses,
      onDeselectAllResponses,
      error,
      pending,
      selectAll,
    } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) => {
            isChecked ? onSelectAllResponses() : onDeselectAllResponses();
          }}
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        {this.getActionMenu()}
      </div>
    );
  };

  getTotalVotesCount = votes =>
    Object.entries(votes)
      .map(vote => (typeof vote[1] === 'number' ? vote[1] : 0))
      .reduce((a, b) => a + b, 0);

  getWinningOption = votes => {
    const max = { count: 0, key: '' };
    Object.entries(votes).forEach(vote => {
      if (vote[1] > max.count) {
        max.key = vote[0];
        max.count = vote[1];
      }
    });
    return max;
  };

  getPercentage = (a, b) => `${Math.round((a / b) * 100)}%`;

  getTableData = (voteResults, voteComponents, items) => {
    const tableData = {};
    items.forEach(key => {
      if (
        this.getWinningOption(voteResults[key]).key !== '' &&
        voteComponents[key]
      ) {
        tableData[key] = {
          date: voteResults[key].date,
          title: voteComponents[key].title || '',
          total: this.getTotalVotesCount(voteResults[key]),
          winning: get(
            voteComponents[key],
            `options.chunks[${
              this.getWinningOption(voteResults[key]).key
            }].title`,
            '',
          ),
          closing: voteComponents[key].end.enabled
            ? formatDate(voteComponents[key].end.timestamp)
            : '',
          percentage: this.getPercentage(
            this.getWinningOption(voteResults[key]).count,
            this.getTotalVotesCount(voteResults[key]),
          ),
          selected: voteResults[key].selected,
        };
      }
    });
    return tableData;
  };

  getCSV = (data, votes, selected) => {
    const { t } = this.props;
    const cols = [
      t('Date'),
      t('Name'),
      t('Total votes'),
      t('Winning option'),
      t('Winning option vote %'),
    ];
    let body = `"${cols.join('","')}"\n`;
    selected.forEach(vote => {
      const values = [];
      values.push(
        formatDate(votes[vote].date),
        votes[vote].title,
        this.getTotalVotesCount(data[vote]),
        votes[vote].options.chunks[this.getWinningOption(data[vote]).key].title,
        this.getPercentage(
          this.getWinningOption(data[vote]).count,
          this.getTotalVotesCount(data[vote]),
        ),
      );
      body += `"${values.join('","')}"\n`;
    });
    return body;
  };

  getActionMenu = () => {
    const { selected, filteredChunks, votes, onDeleteSelected, t } = this.props;
    return (
      <ActionMenu
        selected={selected}
        onDownloadSelected={() => {
          downloadCSV(this.getCSV(filteredChunks, votes, selected), 'votes');
        }}
        onDeleteSelected={() => onDeleteSelected(selected)}
        text={t('vote results')}
      />
    );
  };

  subHeaderContent = () => (
    <span>
      <Trans>Total vote components</Trans>:{' '}
      <b>{Object.keys(this.props.chunks).length}</b>
    </span>
  );

  generateTableColumns = () => {
    const { items, t } = this.props;
    const columns = [
      {
        id: 'date',
        header: (
          <span>
            <Trans>Date Created</Trans>
          </span>
        ),
        classNames: 'col-xs-2 created-at fix-left',
        containerElement: ({ value }) => (
          <span>{value && formatDate(value)}</span>
        ),
      },
      {
        id: 'title',
        header: t('Name'),
        classNames: 'col-xs-2 fix-left',
      },
      {
        id: 'total',
        header: t('Total Votes'),
        classNames: 'col-xs-2 fix-left',
      },
      {
        id: 'winning',
        header: t('Winning Option'),
        classNames: 'col-xs-3',
      },
      {
        id: 'percentage',
        header: t('Winning Vote %'),
        classNames: 'col-xs-2 fix-left',
      },
      {
        id: 'selected',
        header: this.getSelectAllCheckbox(),
        classNames: `col-xs-1 ${items.length < 4 ? 'fix-left' : 'selected'}`,
        containerElement: ({ value, rowId }) => (
          <>
            <TableCheckbox
              value={value}
              onSelect={() => this.props.onSelect(rowId)}
            />
            <ActionMenu
              selected={[rowId]}
              onDownloadSelected={() => {
                downloadCSV(
                  this.getCSV(this.props.filteredChunks, this.props.votes, [
                    rowId,
                  ]),
                  'votes',
                );
              }}
              onDeleteSelected={() => this.props.onDeleteSelected([rowId])}
              onEditSelected={() => this.props.onOpenVoteDetail(rowId)}
              text={t('vote result')}
            />
          </>
        ),
      },
    ];
    return columns;
  };

  render() {
    const {
      items,
      filteredChunks,
      votes,
      onOpenVoteDetail,
      onOpenFormDetail,
      onFilterVoteData,
      onSort,
      order,
      colSorted,
      filter,
      selectAll,
      onSelectAllResponses,
      onDeselectAllResponses,
      t,
    } = this.props;

    const voteInstructions = (
      <p className="instructions-container">
        <p className="instructions-section-1">
          <span>
            <Trans>To use this feature add a Vote component to your app.</Trans>
          </span>
          <br />
          <span>
            <Trans>
              To add Vote component go to the Components menu and click on Add
              Vote component.
            </Trans>
          </span>
          <br />
        </p>
        <p className="instructions-section-2">
          <span>
            <Trans>
              When your app users submit a Vote, the Vote Data will be displayed
              here.
            </Trans>
             
          </span>
        </p>
          
      </p>
    );

    return (
      <section className="vote-table-container">
        <Header
          title={this.subHeaderContent()}
          actionMenu={this.getActionMenu()}
          onChange={onFilterVoteData}
          filter={filter}
          selectAll={selectAll}
          onSelectAllResponses={onSelectAllResponses}
          onDeselectAllResponses={onDeselectAllResponses}
        />
        <ResultsTable
          {...this.props}
          columns={this.generateTableColumns()}
          chunks={this.getTableData(filteredChunks, votes, items)}
          emptyMessage={t('Looks like you have no vote components yet')}
          optionalMessage={voteInstructions}
          onRowTouchTap={onOpenVoteDetail}
          sortTable={cols => onSort(cols)}
          order={order}
          colSorted={colSorted}
          colsToSort={['date']}
        />
        {items && filteredChunks && <VoteDetails />}
      </section>
    );
  }
}

export default withTranslation()(Vote);
