const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  email: {
    value: '',
    isValid: null,
  },
};

const deleteUserByEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_DELETE_USER_DIALOG':
      return {
        ...state,
        open: true,
      };

    case 'CLOSE_DELETE_USER_DIALOG':
      return {
        ...state,
        open: false,
      };

    case 'DELETE_USER_FIELD_CHANGE':
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };

    case 'DELETE_USER':
      return {
        ...state,
        pending: true,
      };

    case 'ERROR':
      return {
        ...state,
        error: action.error,
        pending: false,
      };

    case 'USER_DELETED':
      return initialState;

    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default deleteUserByEmailReducer;
