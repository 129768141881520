const initialState = {
  selectedComponent: null,
  open: false,
  pending: false,
  error: null,
  selectedPage: '',
};

const duplicateComponent = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DUPLICATE_COMPONENT':
      return {
        ...state,
        selectedComponent: action.payload,
      };
    case 'SET_SELECTED_PAGE':
      return {
        ...state,
        selectedPage: action.payload,
      };
    case 'SET_PENDING':
      return {
        ...state,
        pending: action.payload,
      };
    case 'OPEN_DUPLICATE_COMPONENT_DIALOG':
      return {
        ...state,
        open: true,
      };

    case 'CLOSE_DUPLICATE_COMPONENT_DIALOG':
      return {
        ...state,
        open: false,
      };
    case 'RESET_DUPLICATE_COMPONENT_FORM':
      return initialState;
    default:
      return state;
  }
};

export default duplicateComponent;
