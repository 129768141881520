import React from 'react';

const Text = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.46 34.1"
  >
    <title>text</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M19,26c0,5.46.4,5.81,4.65,6.11v2H6v-2c4-.3,4.45-.65,4.45-6.11V3.89H9.55C6.16,3.89,4.8,4.5,4,5.41c-.6.75-1.21,2.07-1.87,4.9H0C.2,6.06.51,2.58.61,0H2.17C2.93,1.16,3.44,1.26,4.8,1.26H25c1.31,0,1.57-.3,2.37-1.26H28.9c.05,2.12.3,6.42.56,10.16l-2.23.15c-.76-3-1.31-4.35-2.07-5.21s-2.53-1.21-4.95-1.21H19Z"
        />
      </g>
    </g>
  </svg>
);

export default Text;
