import React from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import TextField from '@material-ui/core/TextField';
import scriptLoader from 'react-async-script-loader';

import './styles.css';

const PLACES_API_KEY = 'AIzaSyBtm0fe0-bUXXmf5qDugt0ayiVf3WUhwiE';
class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      latLng: this.props.latlong,
      isSuggestion: false,
    };
  }

  handleChange = address => {
    this.setState({ address, latLng: '', isSuggestion: true });
    this.props.onChange(address, '');
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({ latLng });
        this.props.onChange(address, latLng);
      })
      .catch(error =>
        console.error('Google Maps API returned error with status', error),
      );
  };

  render() {
    const {
      multiLine,
      rightInputAction,
      key,
      id,
      value,
      onChange,
      label,
      latlong,
      required,
    } = this.props;

    const onError = (status, clearSuggestions) => {
      clearSuggestions();
    };

    const { address, isSuggestion } = this.state;
    return window.google ? (
      <PlacesAutocomplete
        value={address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        key={key}
        id={id}
        onError={onError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ position: 'relative' }}>
            <TextField
              {...getInputProps({})}
              fullWidth
              autoComplete="off"
              required={required}
              multiline={!!multiLine}
              onBlur={() => {
                if (!latlong) {
                  this.setState({ isSuggestion: false });
                  onChange('');
                }
              }}
              variant="standard"
              label={label}
              value={value}
            />
            {rightInputAction && (
              <span className="right-input-action">{rightInputAction}</span>
            )}
            <div
              className={`text-field-wrapper autocomplete-dropdown-container ${!loading &&
                isSuggestion &&
                suggestions.length > 0 &&
                'scroll-container'}`}
              style={{ minWidth: '324px' }}
            >
              {loading && <div>Loading...</div>}
              {!loading &&
                isSuggestion &&
                suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const css = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        css,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    ) : (
      false
    );
  }
}

LocationSearchInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  multiLine: PropTypes.bool.isRequired,
  rightInputAction: PropTypes.node.isRequired,
  key: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  underlineStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  latlong: PropTypes.any,
};

LocationSearchInput.defaultProps = {
  style: {},
  labelStyle: {},
  underlineStyle: {},
  latlong: null,
  key: 'google-autocomplete',
};

export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=${PLACES_API_KEY}&libraries=places`,
])(LocationSearchInput);
