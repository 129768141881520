import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import Chip from '@material-ui/core/Chip';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { Trans, withTranslation } from 'react-i18next';

import { Empty as EmptyIcon } from 'app/components/icons';
import { CircularProgress } from 'app/components/progress';
import SelectField from 'app/components/selectField';
import ResultsTable from 'app/components/resultsTable';
import TryAgain from 'app/components/tryAgain';
import ActionMenu from 'app/components/actionMenu';
import { objectToArray, sortArray, downloadCSV } from 'utils';
import TableCheckbox from '../../components/tableCheckbox';
import Header from '../../components/header';
import NewItem from './modules/create';
import EditItem from './modules/edit';
import EventCalendarDisabledWrapper from '../eventCalendarDisabledWrapper';
import { STAFF_ADMIN, HS_FEATURES_ENABLED_APPS } from '../../../../config';
import themeStyles from '../../../../_export.scss';

const isCheckboxDisabled = (error, pending) => error && !pending;

let memberBodyFlag = false;
class Courses extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    chunks: PropTypes.object,
    getCourses: PropTypes.func.isRequired,
    onGetCourseFields: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func.isRequired,
    onSelectAllCourses: PropTypes.func.isRequired,
    onDeselectAllCourses: PropTypes.func.isRequired,
    selectMemberBody: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    onFilterCourseData: PropTypes.func.isRequired,
    onAddEventCalendarComponent: PropTypes.func,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    filter: PropTypes.string,
    profile: PropTypes.object.isRequired,
    fields: PropTypes.object,
    memberbody: PropTypes.string.isRequired,
    memberBodies: PropTypes.object,
    componentsPending: PropTypes.bool,
    eventComponentEnabled: PropTypes.bool,
    role: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: '',
    chunks: {},
    filteredChunks: {},
    items: [],
    query: '',
    selected: [],
    selectAll: false,
    fields: {},
    memberbody: '',
    memberBodies: {},
  };

  componentDidMount() {
    memberBodyFlag =
      HS_FEATURES_ENABLED_APPS.indexOf(this.props.match.params.appname) > -1;
    this.props.selectMemberBody();
    this.props.getCourses();
    this.props.onGetCourseFields();
  }

  getCsvData = (selected, chunks) => {
    const { fields: rawFieldsData } = this.props;
    const csvColumns = objectToArray(rawFieldsData)
      .filter(i => i.value.isGridVisible)
      .map(field => field.value.label);
    const head = `${csvColumns.join(',')}\n`;

    const fields = objectToArray(rawFieldsData)
      .filter(i => i.value.isGridVisible)
      .map(field => field.id);

    const body = selected
      .map(key =>
        fields.map(field =>
          field === 'status'
            ? `${chunks[key][field]}\n`
            : `${chunks[key][field]}`,
        ),
      )
      .join('');

    const table = head + body;
    return table;
  };

  getSelectAllCheckBox = () => {
    const {
      error,
      pending,
      chunks,
      selected,
      selectAll,
      onSelectAllCourses,
      onDeselectAllCourses,
      onDeleteSelected,
      t,
    } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllCourses() : onDeselectAllCourses()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDownloadSelected={() => {
            downloadCSV(this.getCsvData(selected, chunks), 'events');
          }}
          onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          text={t('courses')}
        />
      </div>
    );
  };

  getTitle = (selected, courses) => (
    <span>
      <Trans>Courses</Trans>:{' '}
      {selected.length
        ? `${selected.length} / ${Object.keys(courses).length}`
        : Object.keys(courses).length}
    </span>
  );

  getColumns = type => {
    const {
      fields,
      onSelect,
      onDeleteSelected,
      chunks,
      memberbody,
      match,
      profile,
      history,
      items,
      onDuplicateSelected,
      t,
    } = this.props;
    const fieldsArr = objectToArray(fields).filter(i => i.value.isGridVisible);
    if (type === 'columns') {
      let dateValues = '';
      const columns = fieldsArr.map((field, index) => ({
        id: field.id,
        header:
          field.value.label === 'Active' ? t('Status') : field.value.label,
        classNames: index === 0 ? 'col-xs-3' : 'col-xs-2',
        containerElement: ({ value }) => {
          if (field.value.label === 'Course Dates') {
            dateValues = value.split(' ');
          }
          if (field.id === 'status') {
            if (value) {
              const today = new Date();
              const endDate = new Date(dateValues[2] || dateValues[0]);

              return today.getTime() > endDate.getTime() &&
                today.toDateString() !== endDate.toDateString() ? (
                <Chip color="error" size="small" label={t('Inactive')} />
              ) : (
                <Chip color="primary" size="small" label={t('Active')} />
              );
            }
            return (
              <Chip color="secondary" size="small" label={t('Inactive')} />
            );
          }
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span
                style={
                  field.value.label === 'Title'
                    ? {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        maxWidth: '200px',
                      }
                    : field.value.label === 'Event Dates'
                    ? { textAlign: 'center' }
                    : null
                }
              >
                {value}
              </span>
            </div>
          );
        },
      }));

      columns.push({
        id: 'selected',
        header: this.getSelectAllCheckBox(),
        classNames: `col-xs-1 ${items.length < 4 ? 'fix-left' : 'selected'}`,
        containerElement: ({ value, rowId }) =>
          rowId !== 'default' && (
            <>
              <TableCheckbox value={value} onSelect={() => onSelect(rowId)} />
              <ActionMenu
                selected={[[rowId]]}
                onDownloadSelected={() => {
                  downloadCSV(this.getCsvData([rowId], chunks), 'events');
                }}
                onDeleteSelected={() => onDeleteSelected([rowId], chunks)}
                onDuplicateSelected={() => onDuplicateSelected(rowId)}
                text="course"
                onEditSelected={() =>
                  history.push(
                    profile.role === STAFF_ADMIN
                      ? `/${match.params.appname}/staff/courses/${memberbody}/${rowId}`
                      : `/${match.params.appname}/members/courses/${memberbody}/${rowId}`,
                  )
                }
              />
            </>
          ),
      });
      return columns;
    }
    return fieldsArr.map(i => i.id);
  };

  render() {
    const {
      pending,
      items,
      error,
      getCourses,
      chunks,
      filteredChunks,
      onFilterCourseData,
      selected,
      history,
      match,
      profile,
      onSelectAllCourses,
      onDeselectAllCourses,
      onDeleteSelected,
      memberbody,
      memberBodies,
      selectMemberBody,
      eventComponentEnabled,
      componentsPending,
      onAddEventCalendarComponent,
      role,
      t,
    } = this.props;

    const isMemberBodies =
      profile.role === STAFF_ADMIN ? profile.memberBodies : true;

    const courseInstructions = (
      <p className="instructions-container">
        <p className="instructions-section-1">
          <Trans>
            Click on the '+' button to add a new course, complete the course
            fields and choose the preferred visibility settings.
          </Trans>
        </p>
        <p className="instructions-section-2">
          <span>
            <Trans>
              Please note: Courses will be displayed in the page where Event
              Calendar is added.
            </Trans>
          </span>{' '}
          <span>
            <Trans>
              To add or delete fields in courses simply go to the Event Calendar
              component.
            </Trans>
          </span>
        </p>
          
      </p>
    );
    const body = error ? (
      <TryAgain
        message={t('Oops, something went wrong...')}
        onRetry={() => getCourses()}
      />
    ) : (
      <ResultsTable
        items={items}
        chunks={filteredChunks}
        loading={pending}
        pending={pending}
        error={Object.keys(filteredChunks).length === 0 ? 'yes' : null}
        emptyMessage={
          memberbody
            ? t('Looks like no courses have been created yet')
            : `${t('Please select')} ${
                memberBodyFlag ? t('memberbody') : t('event organizer')
              } ${t('from above')}`
        }
        optionalMessage={
          !memberBodyFlag && profile.role === STAFF_ADMIN
            ? null
            : courseInstructions
        }
        onRowTouchTap={course =>
          history.push(
            profile.role === STAFF_ADMIN
              ? `/${match.params.appname}/staff/courses/${memberbody}/${course}`
              : `/${match.params.appname}/members/courses/${memberbody}/${course}`,
          )
        }
        columns={this.getColumns('columns')}
      />
    );
    const content = isMemberBodies ? (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <Header
          {...this.props}
          title={this.getTitle(selected, chunks)}
          onChange={val => onFilterCourseData(val, this.getColumns())}
          // onSelectAllResponses={onSelectAllCourses}
          // onDeselectAllResponses={onDeselectAllCourses}
          // actionMenu={
          //   <ActionMenu
          //     selected={selected}
          //     onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          //     text="courses"
          //   />
          // }
          rightAfterSearch={
            profile.role !== STAFF_ADMIN ? (
              <SelectField
                style={{ width: '200px', marginTop: '-20px' }}
                key="memberbody-filter"
                options={sortArray(Object.entries(memberBodies), 'name').map(
                  option => (
                    <MenuItem
                      key={option[0]}
                      value={option[0]}
                      primaryText={option[1].name}
                    />
                  ),
                )}
                label={
                  memberBodyFlag
                    ? t('Select Member Body')
                    : t('Select Event Organizer')
                }
                value={memberbody}
                onChange={(i, payload) => {
                  selectMemberBody(payload);
                  getCourses();
                  onFilterCourseData('', this.getColumns());
                }}
              />
            ) : null
          }
        />
        {body}
        <NewItem />
        <EditItem />
      </section>
    ) : (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <div className="empty-state">
          <EmptyIcon />
          <Typography
            variant="h5"
            gutterBottom
            style={{ color: '#261ed5', marginTop: '17px' }}
          >
            <WarningIcon style={{ verticalAlign: 'middle' }} />
            {memberBodyFlag
              ? t('No member bodies assigned to you yet')
              : t('No courses assigned to you yet')}
          </Typography>
        </div>
      </section>
    );
    return !componentsPending ? (
      eventComponentEnabled ? (
        content
      ) : (
        <EventCalendarDisabledWrapper
          history={history}
          onAddEventCalendarComponent={onAddEventCalendarComponent}
          appname={match.params.appname}
          role={role}
        >
          {content}
        </EventCalendarDisabledWrapper>
      )
    ) : (
      <CircularProgress />
    );
  }
}

export default withTranslation()(Courses);
