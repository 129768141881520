import { newValidator } from '../../../../utils';

const nameValidator = newValidator({
  minLength: 1,
  required: true,
});

const dateValidator = newValidator({
  date: true,
});

const dateRangeValidator = newValidator({
  dateRange: true,
});

const numberValidator = newValidator({
  number: true,
});

const emailValidator = newValidator({
  type: 'email',
  required: true,
});

export const validateField = {
  Text: value => nameValidator(value),
  Email: value => emailValidator(value),
  Date: value => dateValidator(value),
  DateRange: value => dateRangeValidator(value),
  Daterange: value => dateRangeValidator(value),
  Location: value => nameValidator(value),
  Number: value => numberValidator(value),
  Dropdown: value => nameValidator(value),
  Toggle: value => nameValidator(value),
};
