import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Print from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Trans, useTranslation } from 'react-i18next';

import { NoOfSystems, Subscribers, Attended } from './components';
import { downloadPrintPDF } from '../../../../../../utils';
import DownloadExcel from '../../components/DownloadExcel';

const SportScotlandData = ({
  getTypes,
  workforceGroups,
  getTypesData,
  typesData,
  getSubscribers,
  processingSubscribers,
  subscribers,
  attended,
  processingAttended,
  getAttended,
  users,
  getEventsCoursesCPDs,
  eventsCoursesCpds,
}) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    getEventsCoursesCPDs();
    getTypes();
    getTypesData();
    getSubscribers();
    getAttended();
  }, []);
  const childRefSystems = React.useRef();
  const childRefAttending = React.useRef();
  const childRefAttended = React.useRef();

  const print = () => {
    const childRefSystemsData = childRefSystems.current.prepareReportData();
    const childRefAttendingData = childRefAttending.current.prepareReportData();
    const childRefAttendedData = childRefAttended.current.prepareReportData();
    downloadPrintPDF(
      [childRefSystemsData, childRefAttendingData, childRefAttendedData],
      ['Numbers on Our Systems', 'Subscriptions Data', 'Attended Data'],
      'dvTable',
    );
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          paddingBottom: '6px',
          justifyContent: 'flex-end',
          paddingRight: '24px',
        }}
      >
        <DownloadExcel
          element={
            <Tooltip title={t('Export To Excel')} placement="top">
              <IconButton aria-label={t('Export To Excel')} edge="end">
                <SaveAlt fontSize="small" />
              </IconButton>
            </Tooltip>
          }
          data={[
            {
              multiDataSet: childRefSystems.current
                ? childRefSystems.current.prepareReportData()
                : [],
              name: 'Numbers on Our Systems',
            },
            {
              multiDataSet: childRefAttending.current
                ? childRefAttending.current.prepareReportData()
                : [],
              name: 'Subscriptions Data',
            },
            {
              multiDataSet: childRefAttended.current
                ? childRefAttended.current.prepareReportData()
                : [],
              name: 'Attended Data',
            },
          ]}
          filename="Sportscotland Required Data"
        />
        <Tooltip title={t('Print')} placement="top">
          <IconButton
            aria-label={t('Print')}
            onClick={() => print('print')}
            edge="end"
          >
            <Print fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <Trans>Numbers on Our Systems</Trans>
          </Typography>
        </AccordionSummary>
        <NoOfSystems
          ref={childRefSystems}
          workforceGroups={workforceGroups}
          workforceTypesData={typesData}
          users={users}
        />
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <Trans>
              Number of individuals attending Event/CPD/Course sessions
            </Trans>
          </Typography>
        </AccordionSummary>
        <Subscribers
          ref={childRefAttending}
          subscribersData={subscribers}
          processingSubscribers={processingSubscribers}
          workforceGroups={workforceGroups}
          users={users}
          eventsCoursesCpds={eventsCoursesCpds}
        />
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <Trans>
              Number of Event/CPD/Course sessions attended by each type of
              workforce member
            </Trans>
          </Typography>
        </AccordionSummary>
        <Attended
          ref={childRefAttended}
          attendedData={attended}
          processingAttended={processingAttended}
          workforceGroups={workforceGroups}
          eventsCoursesCpds={eventsCoursesCpds}
          users={users}
        />
      </Accordion>
      {/*Used for to generate temporary tables for export */}
      <div id="dvTable" style={{ display: 'none' }} />
      {/*Used for to generate printed pdf */}
      <iframe
        title={t("Content to Print")}
        id="ifmcontentstoprint"
        style={{
          height: '0px',
          width: '0px',
          position: 'absolute',
          display: 'none',
        }}
      />
    </div>
  );
};
SportScotlandData.propTypes = {
  getTypesData: PropTypes.func.isRequired,
  getTypes: PropTypes.func.isRequired,
  getAttended: PropTypes.func.isRequired,
  getSubscribers: PropTypes.func.isRequired,
  getEventsCoursesCPDs: PropTypes.func.isRequired,
  workforceGroups: PropTypes.object,
  typesData: PropTypes.object,
  processingSubscribers: PropTypes.bool,
  subscribers: PropTypes.object,
  processingAttended: PropTypes.bool,
  attended: PropTypes.object,
  eventsCoursesCpds: PropTypes.object,
  users: PropTypes.object,
};

SportScotlandData.defaultProps = {
  integrationType: '',
  workforceGroups: {},
  typesData: {},
  processingSubscribers: false,
  subscribers: { event: {}, course: {}, cpd: {} },
  processingAttended: false,
  attended: { event: {}, course: {}, cpd: {} },
  eventsCoursesCpds: null,
  users: {},
};

export default SportScotlandData;
