import { combineReducers } from 'redux';

import chart from './modules/chart/reducer';
import events from './modules/events/reducer';
import map from './modules/map/reducer';
import form from './modules/form/reducer';
import vote from './modules/vote/reducer';
import rating from './modules/rating/reducer';
import payment from './modules/payment/reducer';

export default combineReducers({
  chart,
  events,
  map,
  form,
  vote,
  rating,
  payment,
});
