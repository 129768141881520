import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

class ModalDetailsCharts extends Component {
  static propTypes = {
    charts: PropTypes.array.isRequired,
    maxIndex: PropTypes.number,
    minIndex: PropTypes.number,
  };

  static defaultProps = {
    maxIndex: 0,
    minIndex: 0,
  };

  state = {
    chartIndex: 0,
  };

  previousButton = () => (
    <button
      className={`chart-controls previous-chart ${
        this.state.chartIndex === this.props.minIndex ? 'disabled' : ''
      }`}
      onClick={() => this.setState({ chartIndex: this.state.chartIndex - 1 })}
      disabled={this.state.chartIndex === this.props.minIndex}
    >
      <Trans>Previous Chart</Trans>{' '}
    </button>
  );

  nextButton = () => (
    <button
      className={`chart-controls next-chart ${
        this.state.chartIndex === this.props.maxIndex ? 'disabled' : ''
      }`}
      onClick={() => this.setState({ chartIndex: this.state.chartIndex + 1 })}
      disabled={this.state.chartIndex === this.props.maxIndex}
    >
      {' '}
      <Trans>Next Chart</Trans>
    </button>
  );

  render() {
    const { charts } = this.props;
    return (
      <div className="charts-container">
        {this.previousButton()}
        {charts[this.state.chartIndex]}
        {this.nextButton()}
      </div>
    );
  }
}

export default ModalDetailsCharts;
