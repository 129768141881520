import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { assignStaffToBooking, cancelBooking } from '../../api';
import './styles.css';
import firebase from 'firebase';
import { get, isEmpty } from 'lodash';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import { useDispatch } from 'react-redux';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PublicIcon from '@material-ui/icons/Public';
import moment from 'moment';
import Avatar from './avatar.jpg';
import { Trans, useTranslation } from 'react-i18next';

import DialogConfirmation from '../../../../../../app/components/dialogConfirmation';
const useStyles = makeStyles(() => ({
  appBar: {
    position: 'relative',
    color: '#fffff',
    backgroundColor: '#261ed5',
  },
}));

export const getPrice = (price, currency = 'USD') =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(price);

const BookingDetails = props => {
  const {
    openDialog,
    setOpenDialog,
    bookingDetails,
    metaData,
    appname,
    isButtonDisabled,
  } = props;
  const { t } = useTranslation();
  const [staffAssignKey, setStaffAssignKey] = useState('');
  const [staffData, setStaffData] = useState([]);
  const [bookingCancelDialog, setBookingCancelDialog] = useState(false);
  const [changeStaffDialog, setChangeStaffDialog] = useState(false);
  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
  const dispatch = useDispatch();
  const getAppointmentStaff = appointmentId => {
    firebase
      .database()
      .ref(
        `bookings/${appname}/appointments/${appointmentId}/staffMemberAvailability`,
      )
      .once('value', snap => {
        let staffMembers = snap.val();
        if (!staffMembers) return;
        staffMembers = staffMembers.filter(
          staff =>
            staff.availableCapacity > 0 ||
            get(
              bookingDetails.bookingDetail,
              'selectedStaffMember.member.key',
              '',
            ) === staff.key,
        );
        setStaffData(staffMembers);
      });
  };
  useEffect(() => {
    if (!isEmpty(bookingDetails)) {
      setStaffAssignKey(
        get(bookingDetails, 'bookingDetail.selectedStaffMember.member.key', ''),
      );
      getAppointmentStaff(bookingDetails.appointmentDetail.key);
    }
  }, [bookingDetails]);

  const queryClient = useQueryClient();
  const { mutateAsync: assignStaff } = useMutation(assignStaffToBooking);
  const [disabledButton, setDisabledButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const today = Date.now();

  const onCancelBooking = async () => {
    try {
      setLoading(true);

      setDisabledButton(true);
      await cancelBooking({
        appname,
        staffDetails: staffData.find(staff => staff.key === staffAssignKey),
        bookingSummary: bookingDetails,
        metaData,
        source: 'platform',
      });
      queryClient.invalidateQueries('appointmentBookings');
      setDisabledButton(false);
      setLoading(false);
      dispatch(openSnackbar(t('Appointment Cancelled Sucessfully')));
      handleClose();
    } catch (err) {
      dispatch(
        openSnackbar(t('Something went wrong could not cancel booking')),
      );
    }
  };

  const changeStaff = async () => {
    try {
      setDisabledButton(true);

      setLoading(true);
      await assignStaff({
        appname,
        staffDetails: staffData.find(staff => staff.key === staffAssignKey),
        bookingSummary: bookingDetails,
        metaData,
        source: 'platform',
      });
      queryClient.invalidateQueries('appointmentBookings');
      setLoading(false);
      dispatch(openSnackbar(t('Staff Member Changed Sucessfully')));
      setChangeStaffDialog(false);
      setDisabledButton(false);
    } catch (err) {
      dispatch(
        openSnackbar(t('Something went wrong could not change staff member')),
      );
    }
  };

  const bookingCancelled =
    get(bookingDetails, 'bookingDetail.cancelled', false) ||
    get(bookingDetails, 'bookingDetail.cancelledByUser', false) ||
    today > get(bookingDetails, 'appointmentDetail.date', 0);

  const getBookingStatus = () => {
    if (bookingDetails.bookingDetail.cancelled) {
      return (
        <span className="error">
          <Trans>Suspended</Trans>
        </span>
      );
    }
    if (bookingDetails.bookingDetail.cancelledByUser) {
      return (
        <span className="error">
          <Trans>Cancelled</Trans>
        </span>
      );
    }
    if (today > bookingDetails.appointmentDetail.date) {
      return (
        <span className="error">
          <Trans>Expired</Trans>
        </span>
      );
    }
    return (
      <span className="success">
        <Trans>Active</Trans>
      </span>
    );
  };
  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleClose}
        className="scroll-container"
      >
        <AppBar className={classes.appBar}>
          <div className="booking-header">
            <h1 className="title">
              <Trans>Booking Details</Trans>
            </h1>

            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              size="large"
              className="close-btn"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </AppBar>
        {loading ? (
          <div className="bookingLoadingContainer">
            <CircularProgress color="primary" size={40} />
          </div>
        ) : (
          <div className="bookingContent">
            <div className="dup-title">
              <h1>
                <Trans>Booking Details</Trans>
              </h1>
            </div>
            <div className="bookingCard">
              <div className="date-time-container">
                <div className="date">
                  {moment(
                    get(bookingDetails.appointmentDetail, 'date', 0),
                  ).format(`Do MMM`)}
                </div>
                <div className="time">
                  {moment(
                    get(bookingDetails.appointmentDetail, 'date', 0),
                  ).format('HH:mm ')}
                  -
                  {moment(get(bookingDetails.appointmentDetail, 'date', 0))
                    .add(
                      'minutes',
                      get(bookingDetails.bookingDetail, 'duration', 0),
                    )
                    .format('HH:mm ')}
                </div>
              </div>
              <div className="booking-details">
                <div className="appointment-name">
                  <span>
                    {get(bookingDetails.appointmentDetail, 'name', '')}
                  </span>
                </div>
                <div className="booked-on">
                  <span>
                    <Trans>Booked on</Trans>{' '}
                    {moment(get(bookingDetails, 'orderedAt', 0)).format(
                      'MMMM Do YYYY',
                    )}
                  </span>
                </div>
                {get(bookingDetails, 'isBookingPaid', false) && (
                  <div className="price">
                    <span>
                      <Trans>Price</Trans> :{' '}
                      {getPrice(
                        get(bookingDetails, 'finalBill', 0),
                        get(bookingDetails, 'currency', 'USD'),
                      )}{' '}
                    </span>
                  </div>
                )}
                <div className="status">{getBookingStatus()}</div>
                <div
                  className="customer-details-btn"
                  onClick={() => setOpenCustomerDialog(true)}
                >
                  <Trans>See customer details</Trans>
                </div>
                <div className="location">
                  <LocationOnIcon color="primary" />
                  <span>
                    {get(
                      bookingDetails,
                      'appointmentDetail.location.value',
                      '',
                    )}
                  </span>
                </div>
                {bookingDetails && bookingDetails.appointmentDetail.meetingUrl && (
                  <div className="location">
                    <PublicIcon color="primary" />
                    <a
                      href={get(
                        bookingDetails,
                        'appointmentDetail.meetingUrl',
                        '',
                      )}
                      target="_blank"
                      style={{ textDecoration: 'underline', color: '#261ed5' }}
                    >
                      View online meeting link
                    </a>
                  </div>
                )}
                <div className="staff">
                  <span>
                    {get(
                      bookingDetails.bookingDetail,
                      'selectedStaffMember.member.name',
                      '',
                    )}
                  </span>
                  {!bookingCancelled && (
                    <div
                      className="change-staff-btn"
                      onClick={() => setChangeStaffDialog(true)}
                    >
                      <Trans>Change Staff</Trans>
                    </div>
                  )}
                </div>
              </div>
              {!bookingCancelled && (
                <div className="action-container">
                  <button
                    className="booking-cancel-btn"
                    onClick={() => setBookingCancelDialog(true)}
                  >
                    <Trans>Cancel Booking</Trans>
                  </button>
                </div>
              )}
            </div>
            {/* <div className="bookingCustomerStaff">
              <div className="customerCard">
                <div className="title">Customer Details</div>
                <div className="image-container">
                  <img
                    src={get(bookingDetails.userData, 'photoURL', '') || Avatar}
                    alt="customer-image"
                  />
                </div>
                <div className="name">
                  <span>
                    {get(bookingDetails.bookingDetail, 'firstName', '')}{' '}
                    {get(bookingDetails.bookingDetail, 'lastName', '')}
                  </span>
                </div>
                <div className="email">
                  <span>{get(bookingDetails.bookingDetail, 'email', '')}</span>
                </div>
                <div className="phone">
                  <span>{get(bookingDetails.bookingDetail, 'mobile', '')}</span>
                </div>
              </div>
              <div className="staffCard">
                <div className="title">Staff Details</div>

                <div className="image-container">
                  <img
                    src={
                      get(
                        bookingDetails.bookingDetail,
                        'selectedStaffMember.member.imageUrl',
                        '',
                      ) || Avatar
                    }
                    alt="customer-image"
                  />
                </div>
                <div className="name">
                  <span>
                    {get(
                      bookingDetails.bookingDetail,
                      'selectedStaffMember.member.name',
                      '',
                    )}
                  </span>
                </div>
                <div className="email">
                  <span>
                    {get(
                      bookingDetails.bookingDetail,
                      'selectedStaffMember.member.email',
                      '',
                    )}
                  </span>
                </div>
                <div className="phone">
                  <span>
                    {get(
                      bookingDetails.bookingDetail,
                      'selectedStaffMember.member.mobile',
                      '',
                    )}
                  </span>
                </div>
                {!bookingCancelled && (
                  <button
                    className="booking-change-staff-btn"
                    onClick={() => setChangeStaffDialog(true)}
                  >
                    Change Staff
                  </button>
                )}
              </div>
            </div> */}
          </div>
        )}
      </Dialog>
      <DialogConfirmation
        open={bookingCancelDialog}
        onClose={() => setBookingCancelDialog(false)}
        message={`${t('Are you sure you want to cancel this booking?')}`}
        action={onCancelBooking}
      />
      <Dialog
        open={changeStaffDialog}
        onClose={() => setChangeStaffDialog(false)}
      >
        <DialogTitle>
          <Trans>Select a staff member</Trans>
        </DialogTitle>
        <DialogContent>
          <Select
            disabled={bookingDetails.status}
            value={staffAssignKey}
            onChange={e => setStaffAssignKey(e.target.value)}
            displayEmpty
            variant="standard"
          >
            <MenuItem value="" disabled>
              <Trans>Select a staff</Trans>
            </MenuItem>
            {!isEmpty(staffData) &&
              staffData.map(staff => (
                <MenuItem
                  key={staff.key}
                  value={staff.key}
                  disabled={
                    get(
                      bookingDetails,
                      'bookingDetail.selectedStaffMember.member.key',
                      '',
                    ) === staff.key
                  }
                >
                  {staff.name} ({staff.email}) ({staff.availableCapacity})
                </MenuItem>
              ))}
          </Select>
          <button
            onClick={e => changeStaff(e)}
            className="booking-assign-staff-btn"
            disabled={!staffAssignKey || disabledButton}
          >
            {disabledButton
              ? t('Assigning...')
              : staffAssignKey
              ? t('Assign')
              : t('Select a staff to assign')}
          </button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openCustomerDialog}
        onClose={() => setOpenCustomerDialog(false)}
      >
        <DialogTitle>
          <Trans>Customer Details</Trans>
        </DialogTitle>
        <DialogContent>
          <div className="customer-details">
            <div className="field">
              <span className="title">
                <Trans>Name</Trans>:
              </span>

              <span>
                {get(bookingDetails.bookingDetail, 'firstName', '')}{' '}
                {get(bookingDetails.bookingDetail, 'lastName', '')}
              </span>
            </div>
            <div className="field">
              <span className="title">
                <Trans>Email</Trans>:
              </span>
              <span>{get(bookingDetails.bookingDetail, 'email', '')}</span>
            </div>
            <div className="field">
              <span className="title">
                <Trans>Mobile</Trans>:
              </span>{' '}
              <span>{get(bookingDetails.bookingDetail, 'mobile', '')}</span>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BookingDetails;
