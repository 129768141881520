
import {
  TOGGLE_FK_GROUP_DIALOG,
  SET_FK_GROUP_ID,
  TOGGLE_FK_USER_DIALOG,
  SET_FK_USER_ID,
  TOGGLE_FK_GROUP_DETAILS_DIALOG,
  TOGGLE_FK_USER_DETAILS_DIALOG,
} from './types';

export const toggleFkAcademyGroupDialog = payload => ({
  type: TOGGLE_FK_GROUP_DIALOG,
  payload,
});

export const toggleFkAcademyGroupDetailsDialog = payload => ({
  type: TOGGLE_FK_GROUP_DETAILS_DIALOG,
  payload,
});

export const toggleFkAcademyUserDetailsDialog = payload => ({
  type: TOGGLE_FK_USER_DETAILS_DIALOG,
  payload,
});

export const setFkAcademyGroupId = payload => ({
  type: SET_FK_GROUP_ID,
  payload,
});

export const toggleFkAcademyUserDialog = payload => ({
  type: TOGGLE_FK_USER_DIALOG,
  payload,
});

export const setFkAcademyUserId = payload => ({
  type: SET_FK_USER_ID,
  payload,
});

