import { newValidator } from '../../../../../../../utils';

const initialState = {
  pending: false,
  error: null,
  title: {
    value: '',
    isValid: null,
    maxLength: 42,
    required: true,
  },
  domain: {
    value: '',
    isValid: null,
    isAvailable: null,
    pending: null,
    type: 'domain',
    maxLength: 21,
    required: true,
  },
  steps: [
    {
      title: 'App Name',
      subtext:
        'Choose a name for your Mobile App (App Title) and, a web address for your Mobile App (Domain Name). These will be visible to your App users.',
      isValid: false,
    },
  ],
};

const validateTitle = newValidator(initialState.title);

const getSteps = state => [
  {
    title: 'App Name',
    isValid:
      state.domain.isValid &&
      state.domain.isAvailable &&
      !state.domain.pending &&
      state.title.isValid,
    subtext:
      'Choose a name for your Mobile App (App Title) and, a web address for your Mobile App (Domain Name). These will be visible to your App users.',
  },
];
const duplicateApp = (state = initialState, action) => {
  switch (action.type) {
    case 'DUPLICATE_APP_TITLE_CHANGE': {
      const nextState = {
        ...state,
        title: {
          ...state.title,
          value: action.value,
          isValid: validateTitle(action.value),
        },
      };

      return {
        ...nextState,
        steps: getSteps(nextState),
      };
    }

    case 'DUPLICATE_APP_DOMAIN_CHANGE': {
      const nextState = {
        ...state,
        domain: {
          ...state.domain,
          value: action.value,
          isValid: action.isValid,
          // validation occurs in action to prevent unnecessary domain checks
        },
      };

      return {
        ...nextState,
        steps: getSteps(nextState),
      };
    }

    case 'CHECKING_DOMAIN_AVAILABILITY':
      return {
        ...state,
        domain: {
          ...state.domain,
          pending: true,
        },
      };

    case 'ASSERT_DOMAIN_AVAILABILITY': {
      const nextState = {
        ...state,
        domain: {
          ...state.domain,
          isAvailable: action.value,
          pending: false,
        },
      };

      return {
        ...nextState,
        steps: getSteps(nextState),
      };
    }

    case 'DUPLICATING_APP': {
      return {
        ...state,
        pending: true,
      };
    }

    case 'APP_DUPLICATED': {
      return {
        ...state,
        pending: false,
      };
    }
    case 'RESET_DUPLICATE_APP_DATA': {
      return initialState;
    }
    default:
      return state;
  }
};

export default duplicateApp;
