import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { TableContainer, Checkbox } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import Toggle from '../../../../app/components/toggle';
import Search from '../../../../app/components/search';
import SectionHeader from '../../../editor/components/sectionHeader';

import styles from './usersTable.module.scss';

const StyledTableCell = withStyles(theme => ({
  head: {
    background: '#EAEEFF',
    color: 'black',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Raleway, sans-serif',
    height: 73,
    textAlign: 'center',
  },
  body: {
    height: '80px',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Raleway, sans-serif',
    textAlign: 'center',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'white',
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableContainer: {
    maxHeight: 200,
    borderRadius: 15,
    boxShadow: 'none',
  },
});

export default function UsersTable(props) {
  const { t } = useTranslation();
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [filteredChunks, setFilteredChunks] = useState([]);
  const [toggled, setToggled] = useState(false);
  const [filter, setFilter] = useState('');

  const classes = useStyles();

  const onSearchChange = val => {
    setFilter(val);

    if (val === '') {
      setFilteredChunks(props.items);
      return;
    }

    const filtered = props.items.filter(
      t =>
        (props.users[t].email &&
          props.users[t].email.toLowerCase().includes(val)) ||
        (props.users[t].firstName &&
          props.users[t].firstName.toLowerCase().includes(val)) ||
        (props.users[t].lastName &&
          props.users[t].lastName.toLowerCase().includes(val)),
    );
    setFilteredChunks(filtered);
  };

  const handleToggle = (e, value) => {
    if (toggled === false) {
      props.onSelectedFilterChange('users');
    }
    setToggled(!toggled);
  };

  useEffect(() => {
    if (toggled === true) {
      props.onEnableFilter();
    } else if (toggled === false) {
      props.onDisableFilter();
    }
  }, [toggled]);

  useEffect(() => {
    if (props.selectedFilter !== 'users') {
      props.onDisableFilter();
      setToggled(false);
    }
  }, [props.selectedFilter]);

  const handleCheckboxChange = (e, email) => {
    if (e.target.checked) {
      setFilteredEmails([...filteredEmails, email]);
    } else {
      setFilteredEmails(filteredEmails.filter(mail => mail !== email));
    }
  };

  useEffect(() => {
    const usersFilterPush = [];
    if (props.filtered) {
      filteredEmails.forEach((email, index) => {
        if (index === 0) {
          usersFilterPush.push({
            field: 'tag',
            key: 'email',
            relation: '=',
            value: email,
          });
        } else {
          usersFilterPush.push({ operator: 'OR' });
          usersFilterPush.push({
            field: 'tag',
            key: 'email',
            relation: '=',
            value: email,
          });
        }
      });
    }

    props.onFiltersChange(usersFilterPush);
    props.onFilteredListChange(filteredEmails);
  }, [filteredEmails, props.filtered]);

  useEffect(() => {
    setFilteredChunks(props.items);
  }, [props.items]);
  return (
    <>
      <div className={styles.section_header}>
        <SectionHeader
          title={t('Filter By User')}
          actions={<Toggle onToggle={e => handleToggle(e)} toggled={toggled} />}
          tooltip={t('Choose which user will recieve the notification')}
          // borderTop={borderTop}
        />
      </div>
      <div style={{ width: '100%' }}>
        <Search
          name="search"
          placeholder={t('Search...')}
          value={filter}
          onChange={val => onSearchChange(val)}
          onClear={() => onSearchChange('')}
        />
      </div>
      <TableContainer
        className={`overflow-scroll-container ${!props.filtered &&
          styles.disabled} ${styles.table_container} ${classes.tableContainer}`}
        component={Paper}
      >
        <Table aria-label="customized table" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Trans>Send Notification</Trans>
              </StyledTableCell>
              <StyledTableCell>
                <Trans>First Name</Trans>
              </StyledTableCell>
              <StyledTableCell>
                <Trans>Last Name</Trans>
              </StyledTableCell>
              <StyledTableCell>
                <Trans>Email</Trans>
              </StyledTableCell>
              <StyledTableCell>
                <Trans>Verified</Trans>
              </StyledTableCell>
              <StyledTableCell>
                <Trans>Created At</Trans>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredChunks &&
              props.users &&
              filteredChunks.map(row => (
                <StyledTableRow key={props.users[row].email}>
                  <StyledTableCell>
                    <Checkbox
                      disabled={!props.filtered}
                      onChange={e =>
                        handleCheckboxChange(e, props.users[row].email)
                      }
                    />
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    {props.users[row].firstName || '-'}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {props.users[row].lastName || '-'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {props.users[row].email || '-'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {props.users[row].emailVerified ? '✔' : '✕'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {moment(props.users[row].created_at).format('DD/MM/YYYY') ||
                      '-'}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
