import { connect } from 'react-redux';

import ResetPassword from './ResetPassword';

import { fieldChange, clearForm, submitResetPassword } from './actions';

const mapStateToProps = state => ({
  ...state.account.resetPassword,
});

const mapDispatchToProps = dispatch => ({
  onOldPasswordChange: value => dispatch(fieldChange('oldPassword', value)),
  onNewPasswordChange: value => dispatch(fieldChange('newPassword', value)),
  onConfirmPasswordChange: value =>
    dispatch(fieldChange('confirmPassword', value)),
  onSubmit: () => dispatch(submitResetPassword()),
  clearForm: () => dispatch(clearForm()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPassword);
