import { connect } from 'react-redux';
import queryString from 'query-string';

import ComponentInterface from './ComponentInterface';
import { loadLazyData, offLazyData } from './actions';
import { loadDiyComponents } from '../../../../../../app/actions';

const mapStateToProps = (state, ownProps) => {
  const { component, page } = queryString.parse(ownProps.location.search);
  const loaded = state.editor.data.loaded[ownProps.match.params.appname];
  const freeTrialPlan = state.account.organisation.trial_expired
    ? null
    : 'accelerate';
  const subscription =
    freeTrialPlan || state.app.appContext.subscription || 'start';

  if (!loaded) {
    return {
      ...ownProps,
      subscription,
      loading: true,
    };
  }

  const viewData = state.editor.data.alwaysData[page];
  const componentData = state.editor.data.alwaysData[component];

  if (componentData === undefined) {
    return {
      ...ownProps,
      subscription,
      loading: true,
      view: viewData,
    };
  }

  const lazyData = state.editor.data.lazyData[componentData.lazy_data];

  if (lazyData === undefined) {
    return {
      ...ownProps,
      subscription,
      loading: true,
      view: viewData,
      component: componentData,
    };
  }

  return {
    ...ownProps,
    subscription,
    loading: false,
    view: viewData,
    component: componentData,
    data: lazyData,
    diyComponents: state.app.addons.diyComponents || {},
    isAppSumAccount: state.account.organisation.appsumo_id !== '' ? true : false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setListener: (lazyDataId, type) =>
    dispatch(loadLazyData(ownProps.match.params.appname, lazyDataId, type)),
  removeListener: lazyDataId =>
    dispatch(offLazyData(ownProps.match.params.appname, lazyDataId)),
  loadDiyComponents: appname => dispatch(loadDiyComponents(appname)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentInterface);
