import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Buyer from './Buyer';
import {
  getBuyerDetails,
  stopGetBuyerDetails,
  getFavoriteProducts,
  getFavoriteVendors,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.ecommerce.marketplace.buyer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getBuyerDetails: () =>
    dispatch(
      getBuyerDetails(
        ownProps.match.params.appname,
        ownProps.match.params.buyerID,
      ),
    ),
  stopGetBuyerDetails: () =>
    dispatch(
      stopGetBuyerDetails(
        ownProps.match.params.appname,
        ownProps.match.params.buyerID,
      ),
    ),
  getFavoriteProducts: favoriteProducts =>
    dispatch(
      getFavoriteProducts(ownProps.match.params.appname, favoriteProducts),
    ),
  getFavoriteVendors: favoriteVendors =>
    dispatch(
      getFavoriteVendors(ownProps.match.params.appname, favoriteVendors),
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Buyer));
