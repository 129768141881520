import React from 'react';

const EventList = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54.59 54.5"
  >
    <title>Event List component</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path class="cls-1" d="M54.59,54.5H0V0H54.59ZM1.5,53H53.09V1.5H1.5Z" />
        <rect class="cls-1" x="7.84" y="9.28" width="21" height="1.5" />
        <rect class="cls-1" x="7.84" y="12.28" width="21" height="1.5" />
        <rect class="cls-1" x="7.84" y="27.28" width="21" height="1.5" />
        <rect class="cls-1" x="7.84" y="43.28" width="21" height="1.5" />
        <rect class="cls-1" x="7.84" y="24.28" width="21" height="1.5" />
        <rect class="cls-1" x="7.84" y="40.28" width="21" height="1.5" />
        <polygon
          class="cls-1"
          points="44.38 16.78 35.63 16.78 35.63 7.28 44.38 7.28 44.38 8.78 37.13 8.78 37.13 15.28 44.38 15.28 44.38 16.78"
        />
        <polygon
          class="cls-1"
          points="41.92 14.14 39.01 11.23 40.07 10.17 41.92 12.02 46.44 7.5 47.5 8.56 41.92 14.14"
        />
        <polygon
          class="cls-1"
          points="44.84 31.78 36.09 31.78 36.09 22.28 44.84 22.28 44.84 23.78 37.59 23.78 37.59 30.28 44.84 30.28 44.84 31.78"
        />
        <polygon
          class="cls-1"
          points="42.38 29.14 39.46 26.23 40.52 25.17 42.38 27.02 46.89 22.5 47.95 23.56 42.38 29.14"
        />
        <polygon
          class="cls-1"
          points="44.84 46.78 36.09 46.78 36.09 37.28 44.84 37.28 44.84 38.78 37.59 38.78 37.59 45.28 44.84 45.28 44.84 46.78"
        />
        <polygon
          class="cls-1"
          points="42.38 44.14 39.46 41.23 40.52 40.17 42.38 42.02 46.89 37.5 47.95 38.56 42.38 44.14"
        />
      </g>
    </g>
  </svg>
);

export default EventList;
