import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import { useTranslation } from 'react-i18next';

import TextField from '../../../../../app/components/textField';
import CircularValidation from '../../../../../app/components/circularValidation';
import MobileTextField from '../../../../../app/components/mobileTextField';
import SelectField from '../../../../../app/components/selectField';

import { countries } from '../../../utils';

const countryOptions = countries().map(country => (
  <MenuItem
    key={country.code}
    value={JSON.stringify(country)}
    primaryText={country.name}
  />
));

const ContactForm = ({ onFieldChange, name, email, mobile, country }) => {
  const { t } = useTranslation();
  return (
    <div>
      <TextField
        id="name"
        placeholder={t('Name')}
        {...name}
        onChange={value => onFieldChange('name', value)}
        rightInputAction={
          <CircularValidation
            isValid={name.isValid}
            defaultMsg={t("Contact's name required")}
            errorMsg={t('Name must not be more than 20 characters long')}
            showDefault={!name.value}
          />
        }
      />
      <TextField
        id="email"
        placeholder={t('Email')}
        {...email}
        onChange={value => onFieldChange('email', value)}
        rightInputAction={
          <CircularValidation
            isValid={email.isValid}
            errorMsg={t('Email address is invalid')}
            defaultMsg={t('Email or phone number required')}
            showDefault={!email.value && !mobile.value}
          />
        }
      />
      <SelectField
        value={JSON.stringify(country.value)}
        label={t('Please select the contacts country')}
        options={countryOptions}
        onChange={(value, count) => {
          onFieldChange('country', JSON.parse(count), mobile.value);
        }}
      />
      <MobileTextField
        id="number"
        placeholder={t('Mobile')}
        {...mobile}
        onChange={value => onFieldChange('mobile', value, country.value)}
        dialCode={country.value.dialCode}
        rightInputAction={
          <CircularValidation
            isValid={mobile.isValid}
            errorMsg={t('Mobile number must have 11 digits')}
            defaultMsg={t('Email or phone number required')}
            showDefault={!email.value && !mobile.value}
          />
        }
      />
    </div>
  );
};

ContactForm.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
  name: PropTypes.object.isRequired,
  email: PropTypes.object.isRequired,
  mobile: PropTypes.object.isRequired,
  country: PropTypes.object.isRequired,
};

export default ContactForm;
