import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { makeStyles } from '@material-ui/styles';
import {
  TableContainer,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from '@material-ui/core';
import { get, set, reduce, keys, pickBy } from 'lodash';

import ActionMenu from 'app/components/actionMenu';
import { Empty as EmptyIcon } from 'app/components/icons';
import { downloadCSV } from 'utils';

import { getAllItems, deleteItemFromInventory } from '../../api';
import themeStyles from '../../../../_export.scss';

import styles from './styles.module.scss';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-head': {
      color: 'black',
      backgroundColor: '#EAEEFF',
      fontSize: 16,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
    },
    '& .MuiTableCell-body': {
      color: 'black',
      fontSize: 16,
      fontWeight: 400,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
      borderBottom: 'none',
    },
    '& .options-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .MuiCheckbox-root': {
      width: 24,
      height: 24,
    },
    '& .action-menu-container': {
      width: 24,
      height: 24,
    },
  },
});

const InventoryTable = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { mutateAsync: deleteItem } = useMutation(deleteItemFromInventory);
  const [chunks, setChunks] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const {
    appname,
    onAddInventoryDialogOpen,
    onInventoryItemChange,
    isAppAdmin,
  } = props;

  const docLink =
    'https://beezer.zendesk.com/hc/en-us/articles/360020392438-How-to-add-Inventory-Business-Tools-Food-Orders';
  const docPDFLink =
    'https://firebasestorage.googleapis.com/v0/b/beezer-c947f.appspot.com/o/Instructions%2FHow%20to%20add%20Inventory%20-%20Food%20Orders%20(1).pdf?alt=media&token=dd992431-8bb4-49db-8ae8-22d40b213e0d';

  const getInstructionLink = () =>
    isAppAdmin ? (
      <a
        href={docPDFLink}
        rel="noopener noreferrer"
        style={{ color: themeStyles.primaryColor, fontWeight: 'bold' }}
        download
        target="_blank"
      >
        <Trans>Learn how to setup your inventory.</Trans>
      </a>
    ) : (
      <a
        href={docLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: themeStyles.primaryColor, fontWeight: 'bold' }}
      >
        <Trans>Learn how to setup your inventory.</Trans>
      </a>
    );

  const { data, error, isLoading, isError } = useQuery(
    ['inventoryItems', { appname }],
    getAllItems,
    { refetchOnWindowFocus: false },
  );

  const onSelect = id => {
    const modifiedChunks = { ...chunks };
    const prevValue = get(modifiedChunks, `${id}.selected`, false);
    set(modifiedChunks, `${id}.selected`, !prevValue);
    setChunks(modifiedChunks);
    const selectedItems = keys(pickBy(modifiedChunks, chunk => chunk.selected));
    setSelected(selectedItems);
  };

  const selectAllItems = () => {
    const allIds = keys(chunks);
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: true,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected(allIds);
    setSelectAll(true);
  };

  const deselectAllItems = () => {
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: false,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected([]);
    setSelectAll(false);
  };

  const getCsvData = (selectedItems, chunks) => {
    const csvColumns = [
      t('ID'),
      t('Name'),
      t('Previous Price'),
      t('Price'),
      t('Stock'),
      t('Stock Sold'),
      t('Category'),
      t('Status'),
    ];
    let itemsData = `${csvColumns.join(',')}\n`;

    selectedItems.forEach(key => {
      itemsData += `${chunks[key].id || '-'},${chunks[key].name ||
        '-'},${chunks[key].prevPrice || '-'},${chunks[key].price ||
        '-'},${chunks[key].stock || '-'},${chunks[key].stockSold || 0},${chunks[
        key
      ].category || '-'},${chunks[key].status || '-'}\n`;
    });

    return itemsData;
  };

  useEffect(() => {
    setChunks(data && data.finalItems ? data.finalItems : {});
  }, [data]);

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  if (isError) {
    return (
      <span>
        <Trans>Error:</Trans> {error.message}
      </span>
    );
  }

  const onEditSelected = key => {
    onAddInventoryDialogOpen(true);
    onInventoryItemChange(chunks[key]);
  };

  return (
    <div>
      {!data.finalItems ? (
        <div className="empty-state">
          <EmptyIcon />
          <h5>
            <Trans>You haven't added any inventory yet.</Trans>
          </h5>
          <p>
            {' '}
            <p>
              <Trans>
                Use your app to allow your app users to place food orders. Add
                food inventory, accept orders, assign orders to drivers and
                deliver them directly to your customers.
              </Trans>
            </p>
          </p>
          <h5>{getInstructionLink()}</h5>
        </div>
      ) : (
        <TableContainer
          style={{ maxHeight: 650, borderRadius: 15, boxShadow: 'none' }}
          component={Paper}
        >
          <Table
            stickyHeader
            aria-label="customized table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>ID</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Previous Price</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Price</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Stock</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Stock Sold</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Category</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Status</Trans>
                </TableCell>
                <TableCell>
                  <div className="options-container">
                    <Checkbox
                      disableRipple
                      checked={selectAll || false}
                      onChange={(e, isChecked) =>
                        isChecked ? selectAllItems() : deselectAllItems()
                      }
                      style={{ color: themeStyles.primaryColor }}
                    />
                    <ActionMenu
                      selected={selected}
                      onDeleteSelected={() => {
                        selected.forEach(key => {
                          deleteItem({
                            appname,
                            category: chunks[key].category,
                            key,
                          });
                          queryClient.invalidateQueries('inventoryItems');
                          onInventoryItemChange({});
                        });
                      }}
                      onDownloadSelected={() => {
                        downloadCSV(
                          getCsvData(selected, chunks),
                          t('inventory'),
                        );
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chunks &&
                Object.keys(chunks).map(key => (
                  <TableRow key={key} hover onClick={() => onEditSelected(key)}>
                    <TableCell component="th" scope="row">
                      {chunks[key].id || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {chunks[key].name || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {chunks[key].prevPrice || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {chunks[key].price || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {chunks[key].stock || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {chunks[key].stockSold || 0}
                    </TableCell>
                    <TableCell
                      className={styles.capitilize}
                      component="th"
                      scope="row"
                    >
                      {t(chunks[key].category) || '-'}
                    </TableCell>
                    <TableCell
                      className={styles.capitilize}
                      component="th"
                      scope="row"
                    >
                      {t(chunks[key].status) || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="options-container">
                        <Checkbox
                          disableRipple
                          checked={chunks[key].selected || false}
                          onClick={e => e.stopPropagation()}
                          onChange={e => {
                            e.stopPropagation();
                            onSelect(key);
                          }}
                          style={{ color: themeStyles.primaryColor }}
                        />
                        <ActionMenu
                          selected={[key]}
                          onEditSelected={() => onEditSelected(key)}
                          onDeleteSelected={() => {
                            deleteItem({
                              appname,
                              category: chunks[key].category,
                              key,
                            });
                            queryClient.invalidateQueries('inventoryItems');
                            onInventoryItemChange({});
                          }}
                          onDownloadSelected={() =>
                            downloadCSV(
                              getCsvData([key], chunks),
                              t('inventory'),
                            )
                          }
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default InventoryTable;
