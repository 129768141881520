import _join from 'lodash/join';
import _last from 'lodash/last';
import i18n from 'i18next';

import firebase from '../../../../../../../firebase';

export const toggleDialog = (
  appname,
  dataId,
  item,
  itinerary,
  toggled,
) => dispatch =>
  firebase
    .database()
    .ref(
      `apps/${appname}/draft/lazy_data/${dataId}/itinerary/${itinerary}/items/chunks/${item}/dialog/enabled`,
    )
    .set(toggled)
    .then(() =>
      dispatch({
        type: 'TOGGLE_DIALOG_SUCCESS',
        appname,
        dataId,
        item,
        toggled,
      }),
    );

export const scheduleFieldChange = (
  appname,
  dataId,
  itemKey,
  itinerary,
  pathArray,
  value,
) => {
  const inputPath = _join(pathArray, '/');
  const dataRef = firebase
    .database()
    .ref(
      `apps/${appname}/draft/lazy_data/${dataId}/itinerary/${itinerary}/items/chunks/`,
    );

  dataRef.child(`${itemKey}/${inputPath}`).set(value);

  return {
    type: 'SCHEDULE_FIELD_CHANGE',
    key: dataId,
    appname,
    itemKey,
    field: _last(pathArray),
    value,
  };
};

export const createLink = (
  appname,
  dataId,
  itemKey,
  itinerary,
  value,
) => dispatch => {
  const dataRef = firebase
    .database()
    .ref(
      `apps/${appname}/draft/lazy_data/${dataId}/itinerary/${itinerary}/items/chunks/`,
    );

  dataRef
    .child(`${itemKey}/link`)
    .set({
      enabled: value,
      label: i18n.t('My Link'),
      linkType: {
        page: '/',
        web: 'https://www.example.com',
        phone: '+1-555-555-5555',
        email: 'support@example.com',
        selectedOption: 'page',
      },
    })
    .then(() =>
      dispatch({
        type: 'TOGGLE_DIALOG_SUCCESS',
        appname,
        dataId,
        itemKey,
        value: true,
      }),
    );
};

const hydrateScheduleFilters = (dataId, data) => ({
  type: 'HYDRATE_SCHEDULE_FILTERS',
  key: dataId,
  data,
});

export const getScheduleFilters = (appname, dataId) => dispatch => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  if (!dataId) return null;

  dataRef.child('categories').once('value', snapshot => {
    const data = snapshot.val();

    dispatch(hydrateScheduleFilters(dataId, data));
  });

  return {
    type: 'GETTING_SCHEDULE_FITLERS',
    appname,
    dataId,
  };
};
