import { connect } from 'react-redux';

import MemberBodies from './MemberBodies';
import { filterMemberBodyData, getMemberBodies } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  pending: state.staff.memberBodies.data.pending,
  chunks: state.staff.memberBodies.data.chunks,
  filteredChunks: state.staff.memberBodies.data.filteredChunks,
  error: state.staff.memberBodies.data.error,
  items: state.staff.memberBodies.data.items,
  selected: state.staff.memberBodies.data.selected,
  profile: state.account.profile,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFilterMemberBodyData: filter => dispatch(filterMemberBodyData(filter)),
  getMemberBodies: memberBodies =>
    dispatch(getMemberBodies(ownProps.match.params.appname, memberBodies)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberBodies);
