import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import { Trans } from 'react-i18next';
import { FlatButton } from '../buttons';
import { accelerate } from '../../../plans/images';

class UpgradeDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    heading: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  };

  static defaultProps = {
    open: false,
    heading: '',
    content: '',
  };

  componentWillUnmount() {
    this.props.onRequestClose();
  }

  render() {
    const {
      history,
      match,
      open,
      onRequestClose,
      content,
      heading,
    } = this.props;
    return (
      <Dialog
        open={open}
        onRequestClose={onRequestClose}
        autoScrollBodyContent
        style={{ textAlign: 'center' }}
        actionsContainerStyle={{ textAlign: 'center' }}
        contentClassName="customModal"
        paperClassName="customModalPaper"
        title={heading}
      >
        <div className="ml-upgrade-dialog-content" style={{ height: '100%' }}>
          <img
            width={24}
            src={accelerate}
            alt="Upgrade Plan"
            style={{ position: 'relative', top: 1 }}
          />
          <p>{content}</p>
          <FlatButton
            label={<Trans>Upgrade</Trans>}
            onClick={() => history.push(`/${match.params.appname}/choose_plan`)}
            primary
          />
        </div>
      </Dialog>
    );
  }
}

export default UpgradeDialog;
