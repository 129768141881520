import { launch, start } from './images';
import { FREE, PREMIUM } from './constants';

const startComponents = [
  'Image',
  'Text',
  'Twitter',
  'YouTubeVideo',
  'TwitterFeed',
  'YouTube',
  'Livestream',
  'VimeoVideo',
  'Button',
  'FacebookPage',
  'InstagramFeed',
  'Zoom',
  'Header',
  'Slider',
];

const launchComponents = [
  ...startComponents,
  'Form',
  'Roster',
  'Schedule',
  'File',
  'Media',
  'Vote',
  'Delegates',
];

const accelerateComponents = [
  ...launchComponents,
  'IFrame',
  'WordPress',
  'Notification',
  'Countdown',
];

const plansWixWeebly = {
  [FREE]: {
    title: 'Free',
    icon: start,
    components: accelerateComponents,
    buttonTitle: 'CHOOSE PLAN',
    grayOut: false,
  },
  [PREMIUM]: {
    title: 'Premium',
    icon: launch,
    components: accelerateComponents,
    description:
      'Perfect for businesses looking to build customer relationships and engage their audience.',
    price: 9.99,
    buttonTitle: 'CHOOSE PLAN',
    grayOut: false,
  },
};

export default plansWixWeebly;
