const initialState = {
  open: false,
  selectedModal: null,
};

const legal = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_LEGAL_MODAL':
      return {
        open: true,
        selectedModal: action.selectedModal,
      };

    case 'LOGOUT_SUCCESS':
    case 'CLOSE_LEGAL_MODAL':
      return initialState;

    default:
      return state;
  }
};

export default legal;
