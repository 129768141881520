import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Dialog from './Dialog';
import { closeDialog, submitDialogAction } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...state.app.dialog,
  router: {
    history: ownProps.history,
    location: ownProps.location,
    match: ownProps.match,
  },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(closeDialog()),
  handleSubmit: () =>
    dispatch(submitDialogAction(ownProps.match.params.appname)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Dialog),
);
