import {
  RECEIVE_WORKFORCE_MEMBER_FIELD_UPDATE,
  EDIT_GROUP_FIELD_CHANGE,
  SAVE_GROUP_ASSIGNMENT_ERROR,
  REQUEST_UPDATE_GROUP_DETAILS,
  RESPONSE_UPDATE_GROUP_DETAILS,
  DELETE_WORKFORCE_FILE,
  WF_FILE_UPLOAD_FAILURE,
  WF_FILE_UPLOAD_SUCCESS,
  WF_FILE_UPLOAD_START,
} from './actions';

import { RECEIVE_SAVED_GROUP_DATA } from '../../actions';

const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  savedData: {},
  filesToDelete: [],
};

const groupTabs = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_UPDATE_GROUP_DETAILS:
      return {
        ...state,
        pending: true,
      };

    case RECEIVE_WORKFORCE_MEMBER_FIELD_UPDATE:
      return {
        ...state,
        data: action.data,
      };

    case RECEIVE_SAVED_GROUP_DATA:
      return {
        ...state,
        savedData: {
          ...state.savedData,
          [action.groupID]: action.value,
        },
        pending: false,
        error: null,
      };

    case RESPONSE_UPDATE_GROUP_DETAILS:
      return {
        ...state,
        pending: false,
        error: null,
        filesToDelete: [],
      };

    case EDIT_GROUP_FIELD_CHANGE:
      return {
        ...state,
        savedData: {
          ...state.savedData,
          [action.groupID]: {
            ...state.savedData[action.groupID],
            [action.id]: {
              value: action.value,
              isValid: action.isValid,
              prop: action.prop,
              additionalData: action.latlong ? action.latlong : null,
            },
          },
        },
      };

    case SAVE_GROUP_ASSIGNMENT_ERROR:
      return {
        ...state,
        error: action.err,
        pending: false,
      };

    case WF_FILE_UPLOAD_START:
      return {
        ...state,
        pending: true,
      };

    case WF_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
      };

    case WF_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        error: action.message,
        pending: false,
      };

    case DELETE_WORKFORCE_FILE:
      return {
        ...state,
        filesToDelete: [...state.filesToDelete, action.file],
      };

    default:
      return state;
  }
};

export default groupTabs;
