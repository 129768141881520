import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Container from '../../../../../../../app/components/container';
import Toggle from '../../../../../../../app/components/toggle';

const Notification = props => {
  const { t } = useTranslation();

  const handleToggle = prop => (e, isToggled) =>
    props.onChange(prop, isToggled);

  const style = {
    padding: '1rem',
  };

  return (
    <Container>
      <Toggle
        label={t('Show read notification')}
        toggled={props.data.toggle_notification}
        onToggle={handleToggle('toggle_notification')}
        className="full-width toggle-days-sec"
      />

      <p style={style}>
        <Trans>
          This component allows your users to see a chronological list of all
          notifications sent.
        </Trans>
      </p>
      <p style={style}>
        <Trans>
          You can send notifications to your users via the Engage section.
        </Trans>
      </p>
    </Container>
  );
};

export default Notification;
