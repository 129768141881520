import { connect } from 'react-redux';

import Media from './Media';
import { designChange, fieldChange, uploadMedia } from './actions';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';

const mediaFirebaseUrl = (appName, dataId) =>
  `apps/${appName}/draft/lazy_data/${dataId}/url`;

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.editor.build.mediaUpload,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onReceiveMedia: media =>
    dispatch(
      uploadMedia(
        media,
        mediaFirebaseUrl(
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
        ),
      ),
    ),
  handleChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onDesignChange: (field, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        field,
        value,
      ),
    ),
  onButtonDesignChange: (type, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        `buttonDesign/${type}`,
        value,
      ),
    ),
  onDesignToggle: value =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        'enabled',
        value,
      ),
    ),
  openSnackbar: message => dispatch(openSnackbar(message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Media);
