import firebase from '../../../../../../firebase';

import {
  emailValidator,
  passwordValidator,
  validationError,
  registrationError,
} from '../../actions';

const userValidationPassed = email => {
  // push validated user info to db
  // incase of dropoff
  const ref = firebase
    .database()
    .ref('offer_drop_off')
    .push({
      email,
      location: window.location.href,
    });

  return {
    type: 'NEW_USER_VALIDATION_PASSED',
    key: ref.key,
  };
};

export const removeFromDropOffList = () => (dispatch, getState) => {
  const { validatedUserKey } = getState().account.register;

  // remove from dropoff once signup is successful
  return firebase
    .database()
    .ref('offer_drop_off')
    .child(validatedUserKey)
    .remove()
    .catch();
};

export const validateUser = callback => (dispatch, getState) => {
  const { name, email, password, agreements } = getState().account.register;

  dispatch({ type: 'VALIDATING_NEW_USER' });

  if (name.value === '') {
    return dispatch(validationError('name'));
  }

  if (!agreements.value) {
    return dispatch(validationError('agreements'));
  }

  if (!emailValidator(email.value)) {
    return dispatch(validationError('email'));
  }

  if (!passwordValidator(password.value)) {
    return dispatch(validationError('password'));
  }

  return firebase
    .auth()
    .signInWithEmailAndPassword(email.value, ' ')
    .catch(error => {
      if (error.code === 'auth/user-not-found') {
        callback(true);

        return dispatch(userValidationPassed(email.value));
      }

      return dispatch(
        registrationError(
          'The email address is already in use by another account.',
        ),
      );
    });
};
