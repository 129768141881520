import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { withTranslation } from 'react-i18next';
import 'react-dragula/dist/dragula.min.css';
import Container from 'app/components/container';
import DesignToggle from 'modules/editor/components/designToggle';
import ColorPicker from 'modules/editor/components/colorPicker';
import DraggableListWrapper from '../../../../../components/draggableListWrapper';
import ComponentItem from '../../../../../components/componentItem';
import SectionHeader from '../../../../../components/sectionHeader';
import CreateField from '../../../../../components/createField';
import { Tabs, Tab } from '../../../../../components/tabs';

class Roster extends Component {
  static propTypes = {
    data: PropTypes.object,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onAddItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    rearrangeItems: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: {},
  };

  getItemHref = key => {
    const { match, location } = this.props;

    return {
      pathname: `/${match.params.appname}/editor/build/item`,
      search: `${location.search}&item=${key}`,
    };
  };

  handleMove = (el, target, source, sibling) => {
    if (sibling) {
      return this.props.rearrangeItems(el.id, sibling.id);
    }

    return this.props.rearrangeItems(el.id, null);
  };

  handleNavigate = key => () => {
    const href = this.getItemHref(key);

    return this.props.history.push(href);
  };

  renderItems = () => {
    const { data } = this.props;

    const items = _get(data, 'items');
    const order = _get(data, 'items.order');
    if (!items || !order) return [];

    return order.map(key => {
      const title = _get(items, `chunks.${key}.title`);

      return (
        <ComponentItem
          id={key}
          key={key}
          label={title}
          onClick={this.handleNavigate(key)}
          onDelete={() => this.props.onRemoveItem(key)}
        />
      );
    });
  };

  handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    this.props.onDesignChange(prop, rgba);
  };

  render() {
    const { component, onDesignChange, t } = this.props;
    return (
      <React.Fragment>
        <Tabs>
          <Tab label={t('Content')}>
            <SectionHeader title={t('Roster Items')} />
            <CreateField
              id="roster-label"
              hintText={t('Create Roster Item')}
              tooltip={t('Create New Item')}
              onSubmit={this.props.onAddItem}
            />
            {this.props.data && (
              <DraggableListWrapper
                items={this.renderItems()}
                onPositionChange={this.handleMove}
              />
            )}
          </Tab>
          <Tab
            label={t('design')}
            disabled
            style={{
              color: '#d9d9d9',
            }}
          />
          <Tab label={t('design')}>
            <Container>
              <DesignToggle
                component={component}
                onDesignChange={onDesignChange}
              />

              <ColorPicker
                label={t('Title Color')}
                color={
                  component.design ? component.design.titleColor : '#000000'
                }
                height={22}
                disabled={component.design ? !component.design.enabled : true}
                onChange={this.handleColorChange('titleColor')}
              />
              <ColorPicker
                label={t('Subtitle Color')}
                color={
                  component.design ? component.design.subTitleColor : '#000000'
                }
                height={22}
                disabled={component.design ? !component.design.enabled : true}
                onChange={this.handleColorChange('subTitleColor')}
              />
              <ColorPicker
                label={t('Description Color')}
                color={
                  component.design
                    ? component.design.descriptionColor
                    : '#000000'
                }
                height={22}
                disabled={component.design ? !component.design.enabled : true}
                onChange={this.handleColorChange('descriptionColor')}
              />
            </Container>
          </Tab>
          <Tab
            label={t('layout')}
            disabled
            style={{
              color: '#d9d9d9',
            }}
          />
        </Tabs>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Roster);
