import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { SimpleToolbar } from '../../../../app/modules/toolbar';
import { Payment } from '../../../account';

import { store } from '../../../../';
import { ElementsConsumer } from '@stripe/react-stripe-js';

class BuySMS extends Component {
  static propTypes = {
    cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    amount: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    setAmount: PropTypes.func.isRequired,
    handleCharge: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    integration: PropTypes.object,
    history: PropTypes.object,
    stripe: PropTypes.object,
    intl: PropTypes.shape({
      currency: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    integration: null,
  };

  componentDidMount() {
    if (!this.props.location.query) {
      return this.props.history.replace('/choose_sms_bundle', {
        state: { origin: this.props.location.pathname },
      });
    }
    return this.props.setAmount(this.props.integration);
  }

  handleRedirect = () => {
    if (this.props.location.state && this.props.location.state.origin) {
      this.props.history.push(this.props.location.state.origin);
    } else {
      this.props.history.push(`/${this.props.profile.lastVisited}/editor`);
    }
  };

  buySMSError = () => {
    this.props.history.push(
      `/${this.props.profile.lastVisited}/distribute/share_sms`,
    );
    this.props.displayPaymentError(
      this.props.t('Your sms payment was unsuccessful. Try again later'),
    );
  };
  render() {
    const { t } = this.props;
    return (
      <div className="overlay">
        <Provider store={store}>
          <SimpleToolbar />
        </Provider>
        <Payment
          title={t("Let's add some credits to your account!")}
          subtitle={`${this.props.amount} ${t('Credit Package')}`}
          label={`${this.props.amount} ${t('SMS')}`}
          cost={this.props.cost}
          onSubmit={() =>
            this.props.handleCharge(this.props.stripe, this.props.cost, {
              amount: this.props.amount,
            })
          }
          submitLabel={t('Purchase')}
          stripe={this.props.stripe}
          stripeAuthSuccessCallback={this.handleRedirect}
          stripeAuthErrorCallback={this.buySMSError}
          currency={this.props.intl.currency}
        />
      </div>
    );
  }
}

const InjectedBuySMS = props => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <BuySMS elements={elements} stripe={stripe} {...props} />
      )}
    </ElementsConsumer>
  );
};
export default withTranslation()(InjectedBuySMS);
