import React from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';

const InstagramTermsAndConditions = ({ open, handleClose }) => (
  <Modal
    open={open}
    title="Beezer’s Data Use Processes for Instagram Basic Display API"
    handleClose={handleClose}
  >
    <p>
      1. Beezer uses the Restricted Platform Data included in Instagram Basic
      Display API: Get User Profiles and User Media.
    </p>

    <p>
      2. Beezer only requests data with the sole purpose of enhancing the
      quality of user experience within Beezer’s specific product (the{' '}
      <a
        href="https://www.beezer.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: '#261ED5' }}
      >
        Beezer Progressive Web App building platform
      </a>
      ). The Beezer application utilizes the Instagram API to access user data
      through the GET /me?fields=fields&access_token=access-token and GET
      /me/media endpoints, as specified in the
      <a
        href="https://developers.facebook.com/docs/instagram-basic-display-api/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: '#261ED5' }}
      >
        documentation
      </a>
      . The obtained data is strictly used for the purpose of displaying the
      Instagram user posts within the Beezer app which belongs to that user aka
      the Instagram account owner can only display their own Instagram account
      posts within their Beezer app.
    </p>

    <p>
      3. Beezer’s data processing practices align with the guidelines specified
      in the Meta Platform{' '}
      <a
        href="https://developers.facebook.com/terms/dfc_platform_terms/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: '#261ED5' }}
      >
        documentation
      </a>
      . Our data processing workflow adheres to the following steps, as outlined
      in the{' '}
      <a
        href="https://api.instagram.com/oauth/authorize"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: '#261ED5' }}
      >
        Instagram API Developer Docs
      </a>
      :
    </p>
    <ol>
      <li>
        Authorization: Beezer initiates the authorization process through the
        Instagram API using the following endpoint:
        <a
          href="https://api.instagram.com/oauth/authorize"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: '#261ED5' }}
        >
          https://api.instagram.com/oauth/authorize.
        </a>
      </li>
      <li>
        Upon successful authorization, Beezer exchanges the code received for an
        access_token as per the{' '}
        <a
          href="https://developers.facebook.com/docs/instagram-basic-display-api/guides/long-lived-access-tokens"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: '#261ED5' }}
        >
          documented procedure
        </a>
      </li>
      <li>
        Token Refresh: Recognizing the one-hour validity period of
        access_tokens, Beezer promptly exchanges them for long-lived tokens to
        ensure continued, uninterrupted service
      </li>
      <li>
        Token Storage: The obtained long-lived token is securely stored in
        Beezer’s database to facilitate subsequent interactions with the
        Instagram API. This secure storage mechanism is implemented under{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: '#261ED5' }}
          href="https://firebase.google.com/docs/firestore/security/rules-structure"
        >
          best practices for data protection and privacy.
        </a>
      </li>
      <li>
        Data Retrieval: Using the acquired long-lived token, Beezer accesses
        user profile and media data through the designated API endpoints, such
        as /me and /me/media, in accordance with the{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: '#261ED5' }}
          href="https://developers.facebook.com/docs/instagram-basic-display-api/guides/getting-profiles-and-media"
        >
          documentation
        </a>{' '}
        .
      </li>
    </ol>
    <p>4. Data Use</p>
    <ol style={{ listStyleType: 'lower-alpha' }}>
      <li>
        <strong>
          Prohibited Practices. Beezer will not perform, or facilitate or
          support others in performing, any of the following prohibited
          practices (collectively, “Prohibited Practices”):
        </strong>
        <ul style={{ listStyleType: 'lower-roman' }}>
          <li>
            Processing Platform Data to discriminate or encourage discrimination
            against people based on personal attributes including race,
            ethnicity, color, national origin, religion, age, sex, sexual
            orientation, gender identity, family status, disability, medical or
            genetic condition, or any other categories prohibited by applicable
            law, regulation, or Meta policy.
          </li>
          <li>
            Processing Platform Data to make eligibility determinations about
            people, including for housing, employment, insurance, education
            opportunities, credit, government benefits, or immigration status.
            By eligibility determinations, we mean determining whether to
            provide, deny, or take away a particular benefit (for example,
            housing or scholarships) as well as determining the terms under
            which the benefit will be provided, denied, or taken away.
          </li>
          <li>
            Processing Platform Data to perform, facilitate, or provide tools
            for surveillance. Surveillance includes the Processing of Platform
            Data about people, groups, or events for law enforcement or national
            security purposes.
          </li>
          <li>Selling, licensing, or purchasing Platform Data.</li>
          <li>
            Placing Platform Data on, or otherwise making Platform Data
            available to, a search engine or directory without our prior express
            written consent.
          </li>
          <li>
            Attempting to decode, circumvent, re-identify, de-anonymize,
            unscramble, unencrypt, or reverse hash, or reverse-engineer Platform
            Data that is provided to Beezer.
          </li>
          <li>
            Changing Beezer's App’s core functionality or data Processing so
            that Users would view it as an unfamiliar or different App, or
            materially changing the scope of Processing of previously collected
            Platform Data, unless in each case Beezer first re-submit Beezer's
            App and receive our approval through App Review.
          </li>
          <li>
            Processing friend lists from Facebook to establish social
            connections in Beezer's App unless each person in that connection
            has granted Beezer access to that information for that purpose.
          </li>
        </ul>
      </li>
      <li>
        <strong>Additional Terms for Restricted Platform Data</strong>
        <ul style={{ listStyleType: 'lower-roman' }}>
          <li>
            Beezer will not request Restricted Platform Data unless it is
            necessary to meaningfully improve the quality of the applicable
            User's experience in the specific product or service for which the
            User shared the data.
          </li>
          <li>
            It is made clear to the User why Beezer is requesting their
            Restricted Platform Data in order to improve the quality of their
            experience.
          </li>
          <li>
            For clarity, Beezer's Processing of Restricted Platform Data
            complies with the applicable Developer Docs and other provisions of
            these Terms (including the Prohibited Practices).
          </li>
        </ul>
      </li>
      <li>
        <strong>
          Sharing Platform Data. Beezer may only share Platform Data in
          compliance with these Terms (including Sections 3.a (“Prohibited
          Practices”), 3.b (“Additional Terms for Restricted Platform Data”), 4
          (“Privacy Policy”), and 5 (“Service Providers and Tech Providers”)),
          applicable law and regulations, and all other applicable terms and
          policies, and only in the following circumstances:
        </strong>
        <ul style={{ listStyleType: 'lower-roman' }}>
          <li>
            With respect to Platform Data collected as a Tech Provider, solely
            as described below in Section 5.b (“Tech Providers”);
          </li>
          <li>
            With respect to Platform Data not collected as a Tech Provider,
            <ul style={{ listStyleType: 'decimal' }}>
              <li>
                when required under applicable law or regulation (Beezer will
                retain proof of the applicable legal or regulatory requirement
                or request and provide it to Meta if Meta asks for it);
              </li>
              <li>with Beezer's Service Provider;</li>
              <li>
                when a User expressly directs Beezer to share the data with a
                third party (Beezer will retain proof of the User’s express
                direction and provide it to us if we ask for it); or
              </li>
              <li>
                solely with respect to Platform Data that is not Restricted
                Platform Data, with other third parties, so long as:
                <ul style={{ listStyleType: 'lower-alpha' }}>
                  <li>
                    Beezer first contractually prohibits them from using the
                    Platform Data in a way that would violate these Terms or any
                    other applicable terms or policies (Beezer will retain proof
                    of the contractual prohibition and provide it to Meta if
                    Meta asks for it); and
                  </li>
                  <li>
                    Beezer ensures that any such third parties comply with these
                    Terms and all other applicable terms and policies as if they
                    were in Beezer’s place, and Beezer is responsible for their
                    acts and omissions, including their noncompliance.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <strong>Retention, Deletion, and Accessibility of Platform Data</strong>
        <ul style={{ listStyleType: 'lower-roman' }}>
          <li>
            Unless required to keep Platform Data under applicable law or
            regulation, Beezer will (and will make reasonable efforts to ensure
            Beezer's Service Providers) do the following:
            <ul style={{ listStyleType: 'decimal' }}>
              <li>
                Make reasonable efforts to keep Platform Data up to date,
                including Platform Data that has been modified or deleted.
                Beezer will update Platform Data promptly after receiving a
                request from Meta or the User to do so. Beezer will give Users
                an easily accessible and clearly marked way to ask for the
                Platform Data to be modified or deleted. All users can request
                data deletion by sending a request to support@beezer.com.
              </li>
              <li>
                Delete all Platform Data as soon as reasonably possible in the
                following cases:
                <ul style={{ listStyleType: 'lower-alpha' }}>
                  <li>
                    When retaining the Platform Data is no longer necessary for
                    a legitimate business purpose that is consistent with these
                    Terms and all other applicable terms and policies;
                  </li>
                  <li>
                    When Beezer stops operating the product or service through
                    which the Platform Data was acquired;
                  </li>
                  <li>
                    When Meta requests Beezer to delete the Platform Data for
                    the protection of Users (which Meta will determine at our
                    sole discretion);
                  </li>
                  <li>
                    When a User requests their Platform Data be deleted or no
                    longer has an account with Beezer (unless the Platform Data
                    has been aggregated, obscured, or de-identified so that it
                    cannot be associated with a particular User, browser, or
                    device), or for Tech Providers, when a User or the Client
                    requests their Platform Data be deleted or the Client no
                    longer has an account with Beezer;
                  </li>
                  <li>When required by applicable law or regulations; or</li>
                  <li>
                    As required under Section 7 (“Compliance Review Rights and
                    Suspension and Termination of these Terms”).
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            If Beezer will keep Platform Data under applicable law or
            regulation, Beezer will retain proof of the applicable legal or
            regulatory requirement or request and provide it if Meta asks for
            it.
          </li>
          <li>
            If Beezer has received Platform Data in error, Beezer will
            immediately report this to Meta, delete that Platform Data, and
            provide proof of deletion if Meta asks for it.
          </li>
        </ul>
      </li>
      <li>
        <strong>
          Exceptions to Restrictions. The above provisions of this section
          (Section 3.a-d) do not apply to certain Platform Data as described
        </strong>{' '}
        <a
          href="https://developers.facebook.com/terms/3e/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: '#261ED5' }}
        >
          here
        </a>
      </li>
    </ol>

    <p>
      Beezer is committed to maintaining strict adherence to the applicable
      <a
        href="https://developers.facebook.com/docs/instagram-basic-display-api"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: '#261ED5' }}
      >
        Developer Docs
      </a>{' '}
      and other provisions outlined in the{' '}
      <a
        href="https://developers.facebook.com/terms/dfc_platform_terms/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: '#261ED5' }}
      >
        Terms
      </a>
      , including the avoidance of any{' '}
      <a
        href="https://developers.facebook.com/terms/dfc_platform_terms/#datause"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: '#261ED5' }}
      >
        Prohibited Practices
      </a>
      .
    </p>
  </Modal>
);

InstagramTermsAndConditions.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default InstagramTermsAndConditions;
