import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'material-ui/IconButton';
import ChevronRight from 'material-ui/svg-icons/navigation/chevron-right';
import ChevronLeft from 'material-ui/svg-icons/navigation/chevron-left';
import { Trans, useTranslation } from 'react-i18next';
import './styles.css';

const ModalDetailsNavigation = ({
  onPreviousItem,
  currentItem,
  totalItems,
  onNextItem,
  text,
  content,
}) => {
  const { t } = useTranslation();
  return (
    <div className="navigation">
      <IconButton
        onClick={() => onPreviousItem()}
        tooltip={currentItem === 1 ? null : `${t('Previous')}`}
        disabled={currentItem === 1}
      >
        <ChevronLeft className={currentItem === 1 ? 'disabled' : ''} />
      </IconButton>
      {text && (
        <div className="navigation-text">
          <span className="current-response">{currentItem}</span>
          <span>
            <Trans>of</Trans>
          </span>
          <span className="total-responses">{totalItems}</span>
          <span>{text}</span>
        </div>
      )}
      {content}
      <IconButton
        onClick={() => onNextItem()}
        tooltip={currentItem === totalItems ? null : `${t('Next')}`}
        disabled={currentItem === totalItems}
      >
        <ChevronRight
          className={currentItem === totalItems ? 'disabled' : ''}
        />
      </IconButton>
    </div>
  );
};

ModalDetailsNavigation.propTypes = {
  onPreviousItem: PropTypes.func.isRequired,
  currentItem: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  onNextItem: PropTypes.func.isRequired,
  text: PropTypes.string,
  content: PropTypes.element,
};

ModalDetailsNavigation.defaultProps = {
  text: null,
  content: null,
};

export default ModalDetailsNavigation;
