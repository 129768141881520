import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationModal from './notificationModal';
import { CircularProgress } from '../progress';
import { Trans, withTranslation } from 'react-i18next';
import './styles.css';

class Sidebar extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['large', 'small']),
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool,
    animate: PropTypes.bool,
    header: PropTypes.node,
    fullWidth: PropTypes.bool,
    isPopupPage: PropTypes.bool,
    showNotification: PropTypes.bool,
    closeNotification: PropTypes.bool,
    location: PropTypes.object.isRequired,
    isAppAdmin: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    type: 'small',
    loading: false,
    animate: false,
    header: null,
    isPopupPage: false,
    showNotification: false,
    closeNotification: null,
  };

  notificationText = () => {
    const { isAppAdmin } = this.props;
    return (
      <p style={{ marginTop: '8px' }}>
        <Trans>
          Please note that this page is a Popup Box and it will not be visible
          in the app navigation menu. The page will be used in a Popup component
          which can be added to any other page within the app. Find out how to
          complete the Popup Box setup
        </Trans>{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            isAppAdmin
              ? 'https://kb-articles.s3.eu-west-2.amazonaws.com/Popup+Component.pdf'
              : 'https://beezer.zendesk.com/hc/en-us/articles/360040021131-Popup-Component'
          }
        >
          <Trans>here</Trans>
        </a>
        .
      </p>
    );
  };

  render() {
    const {
      children,
      type,
      animate,
      loading,
      header,
      fullWidth,
      isPopupPage,
      showNotification,
      closeNotification,
      location,
    } = this.props;
    const notification = this.notificationText();

    const query = new URLSearchParams(location.search);
    const component = query.get('component');

    return (
      <>
        <div
          className={`sidebar sidebar-${type}${animate ? ' animate' : ''} ${
            fullWidth ? 'sidebar-full' : ''
          }`}
        >
          {header && (
            <div
              ref={node => {
                this.headerContainer = node;
              }}
            >
              {header}
            </div>
          )}
          <div className="flex-wrapper">
            <div className={`scroll-container ${loading ? 'loading' : ''}`}>
              {loading && <CircularProgress size={24} thickness={3} />}

              {!loading && (
                <div className="scroll-section">
                  {type === 'large' && isPopupPage && !component && (
                    <div className="sidebarNote">{notification}</div>
                  )}
                  {children}
                </div>
              )}
            </div>
          </div>
        </div>
        <NotificationModal
          showNotification={showNotification}
          closeNotification={closeNotification}
          text={notification}
        />
      </>
    );
  }
}

export default withRouter(withTranslation()(Sidebar));
