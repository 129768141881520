import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { useQuery } from 'react-query';

import { toggleFkAcademyGroupDetailsDialog } from '../../actions';
import { getFkAcademyEvents } from '../../api';
import { CircularProgress } from '../../../../app/components/progress';
import FkEventCard from '../FkEventCard/FkEventCard';

function GroupsDetailsDialog({ match }) {
  const dispatch = useDispatch();
  const { appname } = match.params;
  
  const groupId = useSelector(({ fkAcademy }) => fkAcademy.fkGroupId);
  const fkGroupDetailsDialogOpen = useSelector(
    ({ fkAcademy }) => fkAcademy.fkGroupDetailsDialogOpen,
  );

  const { data: { data: events } = {}, isLoading } = useQuery(
    ['fkAcademyEvents', groupId],
    () => getFkAcademyEvents(appname, groupId),
    {
      enabled: !!appname && !!groupId,
    },
  );

  if (isLoading) return <CircularProgress />;

  return (
    <Dialog
      fullScreen
      open={fkGroupDetailsDialogOpen}
      onClose={() => dispatch(toggleFkAcademyGroupDetailsDialog(false))}
    >
      <AppBar>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>Download Attendance Data</h2>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => dispatch(toggleFkAcademyGroupDetailsDialog(false))}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent
        style={{
          marginTop: 64,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
          gap: 15,
        }}
      >
        {!events && <h2>No Events yet!</h2>}
        {events &&
          events.length &&
          events.map(event => (
            <FkEventCard
              key={event.key}
              createdAt={event.createdAt}
              date={event.date}
              description={event.description}
              groupId={event.groupId}
              eventId={event.key}
              title={event.title}
              venue={event.venue}
            />
          ))}
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(GroupsDetailsDialog);
