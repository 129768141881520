import React from 'react';
import PropTypes from 'prop-types';

import Dialog from 'material-ui/Dialog';
import { FlatButton } from '../../../../../app/components/buttons';

const Modal = ({ children, open, title, handleClose }) => {
  const actions = (
    <p>
      <FlatButton label="Close" onClick={handleClose} secondaryWhite />
    </p>
  );

  return (
    <Dialog
      actions={actions}
      autoScrollBodyContent
      className="legal"
      open={open}
      title={title}
    >
      {children}
    </Dialog>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Modal;
