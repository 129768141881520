import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EditMemberBody from './EditMemberBody';
import { objectToArray } from '../../../../../../../utils';

import {
  updateMemberBody,
  resetData,
  formFieldChange,
  formFieldUniqueCheck,
  formFieldChangeNonRequired,
  getMemberBodyDetails,
  formStaffFieldChangeNonRequired,
  closeDialog,
} from './actions';

const disabledStaffs = (filteredChunks, memberbodyID) => {
  let usedSatffs = {};
  objectToArray(filteredChunks)
    .filter(i => i.id !== memberbodyID)
    .forEach(i => {
      usedSatffs = { ...usedSatffs, ...i.value.staffMembers };
    });
  return usedSatffs;
};

const mapStateToProps = (state, ownProps) => ({
  ...state.auth.memberBodies.editMemberBody,
  filteredChunks: state.auth.memberBodies.data.filteredChunks,
  filteredChunksStaff: state.auth.staff.data.filteredChunks,
  availableStaffMembers: state.auth.memberBodies.data.availableStaffMembers,
  staffs: objectToArray(state.auth.staff.data.chunks),
  disabledStaffs: disabledStaffs(
    state.auth.memberBodies.data.filteredChunks,
    ownProps.match.params.memberBody,
  ),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMemberBodyDetails: () =>
    dispatch(
      getMemberBodyDetails(
        ownProps.match.params.appname,
        ownProps.match.params.memberBody,
      ),
    ),
  onSubmit: () =>
    dispatch(
      updateMemberBody(
        ownProps.match.params.memberBody,
        ownProps.history,
        ownProps.match.params.appname,
      ),
    ),
  onRequestClose: callback => {
    dispatch(closeDialog());
    dispatch(resetData());
    ownProps.history.push(
      `/${ownProps.match.params.appname}/members/member-bodies`,
    );
    callback();
  },
  onFieldChange: (field, value) => dispatch(formFieldChange(field, value)),
  onFormFieldChangeNonRequired: (field, value, staffID) =>
    dispatch(formFieldChangeNonRequired(field, value, staffID)),
  onFieldChangeWithUniqueCheck: (field, value, filteredChunks) =>
    dispatch(
      formFieldUniqueCheck(
        field,
        value,
        filteredChunks,
        ownProps.match.params.memberBody,
      ),
    ),
  onFormStaffFieldChangeNonRequired: (field, value) =>
    dispatch(formStaffFieldChangeNonRequired(field, value)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EditMemberBody),
);
