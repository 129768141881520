import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import { withTranslation, Trans } from 'react-i18next';
import Divider from 'material-ui/Divider';
import Star from 'material-ui/svg-icons/toggle/star';
import StarBorder from 'material-ui/svg-icons/toggle/star-border';

import ModalDetails from '../../../components/modalDetails';
import ModalDetailsNavigation from '../../../components/modalDetailsNavigation';
import {
  formatDate,
  downloadCSV,
  capitaliseString,
} from '../../../../../utils';

import './styles.css';

class RatingDetails extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    data: PropTypes.object,
    responseKey: PropTypes.string,
    onRequestClose: PropTypes.func.isRequired,
    totalResponses: PropTypes.number.isRequired,
    currentResponse: PropTypes.number.isRequired,
    onPreviousResponse: PropTypes.func.isRequired,
    onNextResponse: PropTypes.func.isRequired,
    ratings: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    onDelete: PropTypes.func,
    currentFeedbackKey: PropTypes.string,
    onPreviousFeedback: PropTypes.func.isRequired,
    onNextFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: null,
    responseKey: null,
    onReply: null,
    onFavorite: null,
    onDownload: null,
    onDelete: null,
    onForward: null,
    currentFeedbackKey: null,
  };

  state = {
    confirmDeleteDialogOpen: false,
  };

  getRatingFields = data => {
    const { t } = this.props;
    return data.order.map(key => (
      <div className="field" key={data.chunks[key].label}>
        <span className="label">{data.chunks[key].label}</span>
        <span className="value">
          {typeof data.chunks[key].value !== 'boolean'
            ? data.chunks[key].value || 'n/a'
            : `${data.chunks[key].value}` === 'true'
            ? t('Selected')
            : t('Not Selected')}
        </span>
      </div>
    ));
  };
  getRatingPercentage = (numberOfRatings, totalRatings) =>
    numberOfRatings
      ? `${Math.round((numberOfRatings / totalRatings) * 100)}%`
      : `0%`;

  getCSV = data => {
    const { t } = this.props;
    const cols = [t('date'), t('rating'), t('name'), t('email'), t('comment')];
    let values = '';
    Object.values(data).forEach(feedback => {
      values += `"${formatDate(feedback.date)}",${feedback.userRating},"${
        feedback.name
      }","${feedback.email}","${feedback.comment}"\n`;
    });
    let body = `"${cols.join('","')}"\n`;
    body += `${values}\n`;
    return body;
  };

  getTotalRatingsCount = ratings =>
    Object.entries(ratings)
      .map(rating => (typeof rating[1] === 'number' ? rating[1] : 0))
      .reduce((a, b) => a + b, 0);

  getWinningOption = ratings => {
    const max = {
      count: 0,
      key: '',
    };
    Object.entries(ratings[1]).forEach(rating => {
      if (rating[1] > max.count) {
        max.key = rating[0];
        max.count = rating[1];
      }
    });
    return max;
  };

  getFormFields = data => {
    const { t } = this.props;
    const formData = Object.entries(
      data.feedback[this.props.currentFeedbackKey],
    );
    const sortOrder = ['userRating', 'name', 'date', 'email', 'comment'];
    let sortedFormData = [];
    sortedFormData = formData
      .map(formData => {
        const n = sortOrder.indexOf(formData[0]);
        sortOrder[n] = '';
        return [n, formData];
      })
      .sort()
      .map(j => j[1]);
    return (
      data && (
        <div className="feedback-fields">
          {sortedFormData.map(field => (
            <div className="field" key={field[0]}>
              <span className="label">
                {field[0] === 'userRating'
                  ? t('User Rating')
                  : capitaliseString(field[0])}
              </span>
              <span className="value">
                {field[0] === 'date'
                  ? formatDate(field[1])
                  : field[0] === 'userRating'
                  ? this.generateStars(field[1])
                  : field[1]}
              </span>
            </div>
          ))}
        </div>
      )
    );
  };

  getAverageOption = totals => {
    let count = 0;
    Object.entries(totals).forEach(item => {
      count += (parseInt(item[0], 10) + 1) * item[1];
    });
    return Math.round(count / this.getTotalRatingsCount(totals));
  };

  getBar = (numberOfRatings, totalRatings) => (
    <div className="bar">
      <div
        className="progress"
        style={{ width: `${(numberOfRatings / totalRatings) * 100}%` }}
      />
    </div>
  );

  getTotalRatingsCount = totals =>
    Object.values(totals).reduce((a, b) => a + b);

  getRatings = totals => {
    const stars = [
      <span className="average-rating-container" key={uuidv1()}>
        <span className="text">
          <Trans>Average Rating</Trans>
        </span>
        <span className={`rating rating-average`}>
          {this.generateStars(this.getAverageOption(totals))}
        </span>
        <span className="number">({this.getTotalRatingsCount(totals)})</span>
      </span>,
    ];
    Object.keys(totals)
      .reverse()
      .forEach(rating => {
        const starRating = parseInt(rating, 10) + 1;
        const numberOfRatings = totals[rating];
        stars.push(
          <span className={`rating rating-${starRating}`} key={uuidv1()}>
            {this.generateStars(starRating)}
            {this.getBar(numberOfRatings, this.getTotalRatingsCount(totals))}
            <span className="number">({numberOfRatings})</span>
            <br />
          </span>,
        );
      });
    return stars;
  };

  getCurrentItem = () =>
    Object.keys(this.props.data.feedback).indexOf(
      this.props.currentFeedbackKey,
    ) + 1;

  getModalTitle = rating => {
    const { t } = this.props;

    return rating.title || rating.caption
      ? `${t('Rating')} - ${rating.title || rating.caption}`
      : t('Rating');
  };

  getModalContent = data => (
    <div className="ratings-modal-content">
      <div className="ratings-container">
        <div className="ratings">{this.getRatings(data.totals)}</div>
      </div>
      <Divider />
      <div className="feedback">
        <ModalDetailsNavigation
          onNextItem={() =>
            this.props.onNextFeedback(this.props.currentFeedbackKey)
          }
          onPreviousItem={() =>
            this.props.onPreviousFeedback(this.props.currentFeedbackKey)
          }
          totalItems={Object.keys(data.feedback).length}
          currentItem={this.getCurrentItem()}
          content={this.getFormFields(data)}
        />
      </div>
    </div>
  );

  handleCloseDeleteDialog = () =>
    this.setState({ confirmDeleteDialogOpen: false });

  generateStars = starRating => {
    const stars = [...Array(starRating)].map(() => <Star key={uuidv1()} />);
    const emptyStars = [...Array(5 - starRating)].map(() => (
      <StarBorder key={uuidv1()} />
    ));
    return [...stars, ...emptyStars];
  };

  render() {
    const {
      data,
      responseKey,
      totalResponses,
      currentResponse,
      onPreviousResponse,
      onNextResponse,
      ratings,
      items,
      onDelete,
      currentFeedbackKey,
      filteredChunks,
      t,
    } = this.props;

    const filteredRatings = Object.fromEntries(
      Object.entries(ratings).filter(([key]) => items.includes(key)),
    );
    const nextResponseKey = items[currentResponse];
    const previousResponseKey = items[currentResponse - 2];

    const filteredItems = Object.keys(filteredRatings);
    return ratings[responseKey] && data ? (
      <ModalDetails
        {...this.props}
        title={this.getModalTitle(ratings[responseKey])}
        onNextItem={() =>
          onNextResponse(nextResponseKey, ratings, filteredItems)
        }
        onPreviousItem={() =>
          onPreviousResponse(previousResponseKey, ratings, filteredItems)
        }
        totalItems={totalResponses}
        currentItem={currentResponse}
        navigationText={t('of rating components')}
        onDownloadCsv={() =>
          downloadCSV(this.getCSV(data.feedback), 'ratings-export')
        }
        deleteConfirmationMessage={t(
          'Are you sure you want to permanently delete all user ratings for this Rating Component?',
        )}
        onDelete={() => onDelete(responseKey, currentFeedbackKey)}
        content={this.getModalContent(data)}
      />
    ) : null;
  }
}

export default withTranslation()(RatingDetails);
