import { Button, Chip } from '@material-ui/core';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import SectionHeader from './SectionHeader';
import PagesSelector from './PagesSelector';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import themeStyles from '../../../../../../_export.scss';
import { get, isEmpty } from 'lodash';
import DialogConfirmation from '../../../../../../app/components/dialogConfirmation';
import { changePageAccess } from './actions';

const PublicPages = props => {
  const { t } = useTranslation();
  const { match, history, appname } = props;
  const pages = useSelector(state => get(state, `editor.structure.routes`, []));

  const [pageSelectorDialog, setPageSelectorDialog] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [pageToRemove, setPageToRemove] = useState({});
  const dispatch = useDispatch();
  const togglePageSelector = () => setPageSelectorDialog(!pageSelectorDialog);
  const toggleConfirmationDialog = () =>
    setConfirmationDialog(!confirmationDialog);
  const [loading, setLoading] = useState(false);
  const publicPages = pages.filter(page => page.publicPage);
  const removePagePublicAccess = async () => {
    setLoading(true);
    await dispatch(
      changePageAccess({
        appname: match.params.appname,
        privatePages: [pageToRemove],
        publicPages: publicPages.filter(page => page.key !== pageToRemove.key),
      }),
    );
    setLoading(false);
  };
  return (
    <React.Fragment>
      <div className="auth-panel">
        <div className="auth-panel-title">
          <Trans>Public Access Pages</Trans>
        </div>
        <div className="auth-panel public-pages-main">
          <SectionHeader title={t('Public Pages')} className="section-header" />

          <div className="public-pages">
            {!isEmpty(publicPages) ? (
              publicPages
                .sort((page1, page2) =>
                  get(page1, 'title', '').localeCompare(
                    get(page2, 'title', ''),
                  ),
                )
                .map(page => (
                  <Chip
                    disabled={loading}
                    label={get(page, 'title', '')}
                    onDelete={() => {
                      toggleConfirmationDialog();
                      setPageToRemove(page);
                    }}
                  />
                ))
            ) : (
              <p style={{ marginBottom: 0 }}>
                <Trans>
                  You haven't added any Public Pages yet. To make pages
                  available to users who are not signed in add the pages here by
                  clicking on the 'Add Page' button.
                </Trans>
              </p>
            )}
            <p style={{ margin: 0 }}>
              <Trans>
                Here you can set up specific pages as Public and thus make them
                available to non-signed in (public) users. A new page which
                hasn't been set up as public or added to any group in Member
                Login -> Groups & Subscriptions, will not be available to both
                non-signed in and signed in users. Please ensure that if you are
                adding public pages, you also edit the pages selected in your
                Groups & Subscriptions
              </Trans>{' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push(`/${appname}/members/groups`);
                }}
              >
                <Trans>here</Trans>
              </a>
              .
            </p>
          </div>
          <div className="add-pages-section">
            <Button
              disabled={loading}
              variant="contained"
              style={{
                backgroundColor: themeStyles.primaryColor,
              }}
              onClick={togglePageSelector}
            >
              {!isEmpty(publicPages) ? t('Edit Public Pages') : t('Add Page')}
            </Button>
          </div>
        </div>
      </div>
      <PagesSelector
        open={pageSelectorDialog}
        onClose={togglePageSelector}
      ></PagesSelector>
      <DialogConfirmation
        open={confirmationDialog}
        onClose={toggleConfirmationDialog}
        message={`${t('Are you sure you want to remove')} "${get(
          pageToRemove,
          'title',
          '',
        )}" ${t('page from public pages?')}`}
        action={removePagePublicAccess}
      />
    </React.Fragment>
  );
};

export default withRouter(PublicPages);
