import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
.st0{fill:#FFFFFF;}
.st1{fill:none;stroke:#D0D2D3;stroke-width:5.1639;stroke-miterlimit:6.8852;}
.st2{fill:#D0D2D3;}
.st3{fill:none;stroke:#F1F1F2;stroke-width:1.7213;stroke-miterlimit:6.8852;}
.st4{fill:none;stroke:#DBDBDB;stroke-width:1.7213;stroke-miterlimit:6.8852;}
.st5{fill:#F1F1F2;}
.st6{fill:none;stroke:#DBDBDB;stroke-width:2.5819;stroke-miterlimit:6.8854;}
.st7{fill:none;stroke:#DBDBDB;stroke-width:2.5819;stroke-miterlimit:6.8852;}
.st8{fill:none;stroke:#D0D2D3;stroke-width:5.1639;stroke-miterlimit:17.2129;}
.st9{fill:#E5E5E5;}
.st10{fill:#FFFFFF;stroke:#CBCBCB;stroke-miterlimit:10;}
.st11{fill:#F6F6F6;}
.st12{fill:#D1D2D2;}
`;

const HorizontalSlider = () => (
  <Svg
    className="layout layout-header-large"
    width="750.000000pt"
    height="1334.000000pt"
    viewBox="0 0 750 1334"
    preserveAspectRatio="xMidYMid meet"
  >
    <g id="Layer_2_1_">
      <g id="Layer_1-2">
        <path class="st0" d="M58.3,206.6h-2.4c-1.9,0-3.4-1.5-3.4-3.4V153c0-1.9,1.5-3.4,3.4-3.4h2.4" />
        <path class="st1" d="M58.3,206.6h-2.4c-1.9,0-3.4-1.5-3.4-3.4V153c0-1.9,1.5-3.4,3.4-3.4h2.4" />
        <path class="st0" d="M58.3,371.8h-2.1c-1.9,0-3.4-1.5-3.4-3.4v-94c0-1.9,1.5-3.4,3.4-3.4h2.1" />
        <path class="st1" d="M58.3,371.8h-2.1c-1.9,0-3.4-1.5-3.4-3.4v-94c0-1.9,1.5-3.4,3.4-3.4h2.1" />
        <path class="st0" d="M691.4,270.9h1.4c2.9,0,5.2,2.3,5.2,5.2v90.5c0,2.9-2.3,5.2-5.2,5.2h-1.4" />
        <path class="st1" d="M691.4,270.9h1.4c2.9,0,5.2,2.3,5.2,5.2v90.5c0,2.9-2.3,5.2-5.2,5.2h-1.4" />
        <path class="st0" d="M58.4,496.1H53c-1.9,0,0-1.5,0-3.4v-94c0-1.9-1.9-3.4,0-3.4h5.5" />
        <path class="st1" d="M58.4,496.1H53c-1.9,0,0-1.5,0-3.4v-94c0-1.9-1.9-3.4,0-3.4h5.5" />
        <path class="st0" d="M147.6,1331.4c-47.9,0-86.7-38.9-86.8-86.8V89.3c0-47.9,38.9-86.7,86.8-86.8h454.6
			c47.9,0,86.7,38.9,86.8,86.8v1155.3c0,47.9-38.9,86.7-86.8,86.8L147.6,1331.4L147.6,1331.4z"/>
        <path class="st2" d="M602,5.2c46.4,0.1,84.1,37.7,84.2,84.2v1155.3c0,46.5-37.7,84.2-84.2,84.2H147.6
			c-46.4-0.1-84.1-37.7-84.2-84.2V89.3c0-46.5,37.7-84.2,84.2-84.2L602,5.2 M602,0H147.6C98.3,0,58.3,40,58.3,89.3v1155.3
			c0,49.3,40,89.3,89.4,89.3h454.6c49.3,0,89.3-40,89.3-89.3V89.3C691.4,40,651.4,0.1,602,0z"/>
        <path class="st3" d="M670.2,1175.6c0,2.9-2.3,5.2-5.2,5.2H83.7c-2.9,0-5.2-2.3-5.2-5.2V142.9c0.1-2.8,2.3-5.1,5.2-5.2H665
			c2.9,0,5.2,2.3,5.2,5.2V1175.6L670.2,1175.6z"/>
        <path class="st4" d="M670.2,1175.6c0,2.9-2.3,5.2-5.2,5.2H83.7c-2.9,0-5.2-2.3-5.2-5.2V142.9c0.1-2.8,2.3-5.1,5.2-5.2H665
			c2.9,0,5.2,2.3,5.2,5.2V1175.6L670.2,1175.6z"/>

        <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 153.5898 324.3667)" class="st5" cx="267.4" cy="71.9" rx="11.4" ry="11.4" />

        <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 153.5898 324.3667)" class="st6" cx="267.4" cy="71.9" rx="11.4" ry="11.4" />
        <circle class="st5" cx="373.4" cy="26.5" r="6.9" />
        <circle class="st7" cx="373.4" cy="26.5" r="6.9" />
        <circle class="st8" cx="373.4" cy="1258.3" r="51.8" />
        <path class="st9" d="M424.2,72.3c0,2.9-2.3,5.2-5.2,5.2h-92.9c-2.9,0-5.2-2.3-5.2-5.2v-0.2c0-2.9,2.3-5.2,5.2-5.2H419
			C421.9,67.1,424.2,69.4,424.2,72.3z"/>
        <path class="st4" d="M424.2,72.3c0,2.9-2.3,5.2-5.2,5.2h-92.9c-2.9,0-5.2-2.3-5.2-5.2v-0.2c0-2.9,2.3-5.2,5.2-5.2H419
			C421.9,67.1,424.2,69.4,424.2,72.3z"/>
      </g>
    </g>
    <g>
      <g id="change3_2_">
        <rect x="78.5" y="600.4" class="st10" width="182.5" height="159.9" />
      </g>
      <g id="change2_2_">
        <path class="st11" d="M121.7,669c26.7,0,30.9,57.4,41.7,57.4c10.8,0,15.6-34.3,30.9-34.3c21,0,31.5,62,66.9,62c0,0,0.1,0,0.1,0
			V600.6H78.5v142.2C84.8,719.7,101,669,121.7,669z M221,622.3c14.4,0,26.1,11.6,26.1,26.1c0,14.4-11.6,26.1-26.1,26.1
			c-14.4,0-26-11.6-26-26.1C194.9,633.9,206.6,622.3,221,622.3z"/>
      </g>
      <g id="change1_2_">
        <path class="st12" d="M261.3,759.8V754c0,0-0.1,0-0.1,0c-35.4,0-45.9-62-66.9-62c-15.3,0-20.1,34.3-30.9,34.3
			c-10.8,0-15-57.4-41.7-57.4c-20.7,0-36.9,50.7-43.2,73.8v17.1H261.3L261.3,759.8z"/>
        <circle class="st12" cx="221" cy="648.3" r="26.1" />
      </g>
    </g>
    <g>
      <g id="change3_3_">
        <rect x="487.4" y="597.4" class="st10" width="182.5" height="159.9" />
      </g>
      <g id="change2_3_">
        <path class="st11" d="M530.5,666c26.7,0,30.9,57.4,41.7,57.4s15.6-34.3,30.9-34.3c21,0,31.5,62,66.9,62c0,0,0.1,0,0.1,0V597.6
			H487.4v142.2C493.7,716.7,509.9,666,530.5,666z M629.9,619.3c14.4,0,26.1,11.6,26.1,26.1c0,14.4-11.6,26.1-26.1,26.1
			c-14.4,0-26-11.6-26-26.1C603.8,630.9,615.5,619.3,629.9,619.3z"/>
      </g>
      <g id="change1_3_">
        <path class="st12" d="M670.2,756.8V751c0,0-0.1,0-0.1,0c-35.4,0-45.9-62-66.9-62c-15.3,0-20.1,34.3-30.9,34.3s-15-57.4-41.7-57.4
			c-20.7,0-36.9,50.7-43.2,73.8v17.1H670.2L670.2,756.8z"/>
        <circle class="st12" cx="629.9" cy="645.3" r="26.1" />
      </g>
    </g>
    <g>
      <g id="change3_1_">
        <rect x="218.2" y="548.1" class="st10" width="301.7" height="264.4" />
      </g>
      <g id="change2_1_">
        <path class="st11" d="M289.5,661.6c44.2,0,51.1,94.8,69,94.8c17.9,0,25.8-56.6,51.1-56.6c34.8,0,52.1,102.5,110.6,102.5
			c0.1,0,0.2,0,0.2,0V548.5H218.2v235C228.6,745.4,255.3,661.6,289.5,661.6z M453.7,584.3c23.8,0,43.1,19.2,43.1,43.1
			c0,23.8-19.2,43.1-43.1,43.1c-23.8,0-43-19.2-43-43.1C410.6,603.6,430,584.3,453.7,584.3z"/>
      </g>
      <g id="change1_1_">
        <path class="st12" d="M520.4,811.7v-9.6c-0.1,0-0.2,0-0.2,0c-58.5,0-75.8-102.5-110.6-102.5c-25.3,0-33.2,56.6-51.1,56.6
			s-24.8-94.8-69-94.8c-34.2,0-60.9,83.9-71.4,122v28.2H520.4L520.4,811.7z"/>
        <circle class="st12" cx="453.7" cy="627.3" r="43.1" />
      </g>
    </g>
  </Svg>
);

export default HorizontalSlider;
