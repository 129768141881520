import { connect } from 'react-redux';
import NotFound from './NotFound';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    apps: state.account.organisation.apps,
  };
};



export default connect(mapStateToProps)(NotFound);
