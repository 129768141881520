import firebase from '../../../../firebase';

export const listenForEvent = appname => dispatch => {
  dispatch({ type: 'LOAD_SHARE_HISTORY' });

  return firebase
    .database()
    .ref(`distribute_history/${appname}`)
    .orderByKey()
    .on('value', snapshot => {
      const data = snapshot.val();
      const sortedEventHistory = data ? sortEventHistoryByDate(data) : null;
      dispatch({
        type: 'RECEIVE_SHARE_EVENT',
        appname,
        data: sortedEventHistory,
      });
    });
};

//sort in descending order
const sortEventHistoryByDate = eventHistory =>
  Object.fromEntries(
    Object.entries(eventHistory).sort(
      ([, event1], [, event2]) =>
        new Date(event2.date).getTime() - new Date(event1.date).getTime(),
    ),
  );

export const removeEventListener = appname => {
  firebase
    .database()
    .ref(`distribute_history/${appname}`)
    .off();

  return {
    type: 'OFF_SHARE_HISTORY_LISTENER',
    appname,
  };
};

export const searchShareHistory = query => dispatch =>
  dispatch({
    type: 'SEARCH_SHARE_HISTORY',
    query,
  });

export const selectHistory = key => ({
  type: 'SELECT_HISTORY',
  key,
});

export const selectAllHistories = () => ({ type: 'SELECT_ALL_HISTORY' });

export const deselectAllHistories = () => ({ type: 'DESELECT_ALL_HISTORY' });
