import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Blog from './Blog';
import { designChange, fieldChange } from '../header/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  design: ownProps.component.design,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: (field, value) =>
      dispatch(
        fieldChange(
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
          field,
          value,
        ),
      ),
    onDesignChange: (field, value) => {
      return dispatch(
        designChange(
          ownProps.match.params.appname,
          queryString.parse(ownProps.location.search).component,
          field,
          value,
        ),
      );
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Blog));
