import { connect } from 'react-redux';

import Notices from './Notices';
import { closeNotice } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  notices: state.app.notices,
  ...state.account.organisation.notices,
});

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeNotice()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notices);
