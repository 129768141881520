import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from 'material-ui/MenuItem';
import styled from 'styled-components';
import moment from 'moment';
import { Trans, withTranslation } from 'react-i18next';
import { objectToArray, sortArray, downloadCSV } from 'utils';
import TableCheckbox from '../../components/tableCheckbox';

import SelectField from '../../../../app/components/selectField';
import ResultsTable from '../../../../app/components/resultsTable';
import TryAgain from '../../../../app/components/tryAgain';
import ActionMenu from '../../../../app/components/actionMenu';
import Header from '../../components/header';
import ViewAttendee from './viewAttendees';
import { STAFF_ADMIN, HS_FEATURES_ENABLED_APPS } from '../../../../config';

import EventCalendarDisabledWrapper from '../eventCalendarDisabledWrapper';
import { CircularProgress } from '../../../../app/components/progress';
import themeStyles from '../../../../_export.scss';

const isCheckboxDisabled = (error, pending) => error && !pending;

let memberBodyFlag = false;

const SelectSection = styled.div`
  display: flex;

  & div {
    width: auto;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

class CoursesAttendees extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    chunks: PropTypes.object,
    getCourses: PropTypes.func.isRequired,
    onGetEventFields: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func.isRequired,
    onSelectAllCourseAttendees: PropTypes.func.isRequired,
    onDeselectAllCourseAttendees: PropTypes.func.isRequired,
    selectMemberBody: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    onFilterEventData: PropTypes.func.isRequired,
    onAddEventCalendarComponent: PropTypes.func,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    filter: PropTypes.string,
    profile: PropTypes.object.isRequired,
    fields: PropTypes.object,
    memberbody: PropTypes.string.isRequired,
    memberBodies: PropTypes.object,
    componentsPending: PropTypes.bool,
    eventComponentEnabled: PropTypes.bool,
    role: PropTypes.string,
    coursesList: PropTypes.object.isRequired,
    selectedCourse: PropTypes.string.isRequired,
    setSelectedCourse: PropTypes.func.isRequired,
    getCourseAttendees: PropTypes.func.isRequired,
    eventOrganizer: PropTypes.string.isRequired,
    filterCourseData: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: '',
    chunks: {},
    filteredChunks: {},
    items: [],
    query: '',
    selected: [],
    selectAll: false,
    fields: {},
    memberbody: '',
    memberBodies: {},
  };

  componentDidMount() {
    memberBodyFlag =
      HS_FEATURES_ENABLED_APPS.indexOf(this.props.match.params.appname) > -1;
    //     this.props.selectMemberBody();
    //     this.props.getEvents();
    //     this.props.onGetEventFields();
  }

  getSelectAllCheckBox = () => {
    const {
      error,
      pending,
      chunks,
      selected,
      selectAll,
      onSelectAllCourseAttendees,
      onDeselectAllCourseAttendees,
      onDeleteSelected,
      t,
    } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked
              ? onSelectAllCourseAttendees()
              : onDeselectAllCourseAttendees()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDownloadSelected={() => {
            downloadCSV(this.getCsvData(selected, chunks), 'course-attendees');
          }}
          text={t('courses')}
        />
      </div>
    );
  };

  getTitle = (selected, courses) => {
    const { t } = this.props;
    return (
      <span>
        <Trans>Courses Attendees</Trans>:{' '}
        {selected.length
          ? `${selected.length} / ${Object.keys(courses).length}`
          : Object.keys(courses).length}
      </span>
    );
  };

  getCsvData = (selected, chunks) => {
    const { t } = this.props;
    const csvColumns = [
      t('Full Name'),
      t('Email'),
      t('Message'),
      t('Type of User'),
      t('Course'),
      t('Date'),
    ];

    const head = `${csvColumns.join(',')}\n`;
    const data = objectToArray(chunks);
    const courseInfo = objectToArray(this.props.coursesList).filter(
      course => course.id === this.props.selectedCourse,
    );

    const body = data
      .filter(values => selected.includes(values.id))
      .map(item => {
        const details = {
          name: item.value.name,
          email: item.value.email,
          message: item.value.message,
          userType: item.value.userType,
          course: Object.values(courseInfo[0].value)[0],
          date: `${moment(new Date(item.value.created_at)).format(
            'DD/MM/YYYY',
          )}\n`,
        };
        return Object.values(details);
      })
      .join('');

    const table = head + body;
    return table;
  };

  getColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'name',
        header: t('Full Name'),
        classNames: 'col-xs-3',
      },
      {
        id: 'email',
        header: t('Email Address'),
        classNames: 'col-xs-3',
      },
      {
        id: 'userType',
        header: t('User Category'),
        classNames: 'col-xs-3',
      },

      {
        id: 'created_at',
        header: t('Date Registered'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) => (
          <span>{value && moment(value).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        id: 'selected',
        header: this.getSelectAllCheckBox(),
        classNames: `col-xs-1`,
        containerElement: ({ value, rowId }) => (
          <>
            <TableCheckbox
              value={value}
              onSelect={() => this.props.onSelect(rowId)}
            />
            <ActionMenu
              isItemOption
              selected={[rowId]}
              text={t('user')}
              onDownloadSelected={() => {
                downloadCSV(
                  this.getCsvData([rowId], this.props.chunks),
                  'course-attendees',
                );
              }}
            />
          </>
        ),
      },
    ];
  };

  render() {
    const {
      pending,
      items,
      error,
      getCourses,
      chunks,
      onFilterEventData,
      selected,
      history,
      match,
      profile,
      filteredChunks,
      memberbody,
      memberBodies,
      selectMemberBody,
      eventComponentEnabled,
      componentsPending,
      onAddEventCalendarComponent,
      role,
      coursesList,
      selectedCourse,
      setSelectedCourse,
      getCourseAttendees,
      eventOrganizer,
      t,
      filterCourseData,
    } = this.props;

    const isMemberBodies =
      profile.role === STAFF_ADMIN ? profile.memberBodies : true;

    const courseInstructions =
      Object.keys(coursesList).length === 0 ? (
        <p className="instructions-container">
          <p className="instructions-section-1">
            <Trans>
              Click on the '+' button to add a new course, complete the course
              fields and choose the preferred visibility settings.
            </Trans>
          </p>
          <p className="instructions-section-2">
            <span>
              <Trans>
                Please note: Courses will be displayed in the page where Event
                Calendar is added.
              </Trans>
            </span>{' '}
            <span>
              <Trans>
                To add or delete fields in courses simply go to the Event
                Calendar component.
              </Trans>
            </span>
          </p>
            
        </p>
      ) : null;
    const body = error ? (
      <TryAgain
        message={t('Oops, something went wrong...')}
        onRetry={() => getCourses()}
      />
    ) : (
      <ResultsTable
        items={items}
        chunks={filteredChunks}
        loading={pending}
        pending={pending}
        error={Object.keys(filteredChunks).length === 0 ? 'yes' : null}
        emptyMessage={
          selectedCourse
            ? t('Looks like there are no course attendees yet')
            : t(`Please select a course from above`)
        }
        optionalMessage={
          !memberBodyFlag && profile.role === STAFF_ADMIN
            ? null
            : courseInstructions
        }
        onRowTouchTap={course =>
          history.push(
            `/${match.params.appname}/members/courses-attendees/${course}`,
          )
        }
        columns={this.getColumns('columns')}
      />
    );

    const content = (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <Header
          {...this.props}
          title={this.getTitle(selected, chunks)}
          onChange={val => filterCourseData(val)}
          // onSelectAllResponses={onSelectAllEvents}
          // onDeselectAllResponses={onDeselectAllEvents}
          // actionMenu={
          //   <ActionMenu
          //     selected={selected}
          //     onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          //     text="events"
          //   />
          // }
          rightAfterSearch={
            profile.role !== STAFF_ADMIN ? (
              <SelectSection>
                <SelectField
                  style={{ width: '200px', marginTop: '-20px' }}
                  key="memberbody-filter"
                  options={sortArray(Object.entries(memberBodies), 'name').map(
                    option => (
                      <MenuItem
                        key={option[0]}
                        value={option[0]}
                        primaryText={option[1].name}
                      />
                    ),
                  )}
                  label={t('Select Event Organizer')}
                  value={eventOrganizer}
                  onChange={(i, payload) => {
                    selectMemberBody(payload);

                    getCourses();
                    //  onFilterEventData('', this.getColumns());
                  }}
                />
                {Object.keys(coursesList).length > 0 && (
                  <SelectField
                    style={{ width: '200px', marginTop: '-20px' }}
                    key="memberbody-filter"
                    options={objectToArray(coursesList).map(option => {
                      return (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          primaryText={Object.values(option.value)[0]}
                        />
                      );
                    })}
                    label={t('Select Course')}
                    value={selectedCourse}
                    onChange={(i, payload) => {
                      setSelectedCourse(payload);
                      getCourseAttendees();
                      //  onFilterEventData('', this.getColumns());
                    }}
                  />
                )}
              </SelectSection>
            ) : null
          }
        />
        {body}
        <ViewAttendee />
      </section>
    );

    return !componentsPending ? (
      eventComponentEnabled ? (
        content
      ) : (
        <EventCalendarDisabledWrapper
          history={history}
          onAddEventCalendarComponent={onAddEventCalendarComponent}
          appname={match.params.appname}
          role={role}
        >
          {content}
        </EventCalendarDisabledWrapper>
      )
    ) : (
      <CircularProgress />
    );
  }
}

export default withTranslation()(CoursesAttendees);
