import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import iPhoneImage from '../../../assets/images/MockPhone11.png';
import macDesktopImage from '../../../assets/images/MockMac.png';

import './styles.css';

const styles = {
  appLogo: {
    height: '100%',
    width: 'auto',
    objectPosition: '50% 50%',
    objectFit: 'contain',
    maxWidth: '100%',
    margin: '0px',
    padding: '0px',
    verticalAlign: 'bottom',
  },
  logoWrapper: {
    maxWidth: '70%',
    cursor: 'pointer',
    margin: '0 auto',
    height: '64px',
  },
  appTitle: {
    maxWidth: '155px',
    padding: '9px 0px',
    fontSize: '1.1rem',
    lineHeight: '1.1',
    textAlign: 'center',
    margin: '0px',
    fontWeight: '600',
  },
};

const PushNotification = props => {
  const { t } = useTranslation();
  const date = new Date();
  return (
    <div
      className="pn-container"
      style={
        props.view === 'mobile' ? { height: '768px' } : { height: '360px' }
      }
    >
      <img
        width={'100%'}
        src={props.view === 'mobile' ? iPhoneImage : macDesktopImage}
        alt={t('push notification preview')}
        className={props.view === 'mobile' ? 'mobile-image' : 'desktop-image'}
      />

      {props.view === 'mobile' ? (
        <div
          className="mobile-toolbar"
          style={{ background: props.topBarColor.backgroundColor }}
        />
      ) : (
        ''
      )}

      <div className={props.view === 'mobile' ? 'mobile-pn' : 'desktop-pn'}>
        <div
          className="mock-header"
          style={{ background: props.topBarColor.backgroundColor }}
        >
          <div className="mock-menu" />
          <div
            className="mock-appname"
            style={{ color: props.topBarColor.textColor }}
          >
            {props.appSettings.meta && props.appSettings.meta.logo ? (
              <div style={styles.logoWrapper}>
                <img
                  alt={props.appSettings.meta.title}
                  style={styles.appLogo}
                  src={props.appSettings.meta.logo}
                />
              </div>
            ) : (
              <h1 style={styles.appTitle}>{props.appname}</h1>
            )}
          </div>
          <div className="mock-action-menu" />
        </div>
        <div className="notification-component">
          <div className="title" style={{ color: props.textColor }}>
            <Trans>Notifications</Trans>
          </div>
          {props.title || props.message ? (
            <div className="notification">
              <div className="time" style={{ color: props.textColor }}>
                {moment(date).format('h:mm a, dddd, MMMM Do YYYY')}
              </div>
              <div
                className="notification-title"
                style={{ color: props.textColor }}
              >
                {props.title}
              </div>
              <div
                className="notification-message"
                style={{ color: props.textColor }}
              >
                {props.message}
              </div>
              {props.image ? (
                <img
                  className="notification-image"
                  src={props.image}
                  alt={t('notification')}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

PushNotification.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default PushNotification;
