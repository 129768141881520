import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const InputGroupAppendWrapper = styled.div`
  position: relative;
  display: flex;
  width: 324px;
  align-items: baseline;
  /* Based on design the space between the input and the suffix is required 
  .text-field-wrapper {
    padding-right: 0px;
  } */
  .group_append {
    background: rgba(245, 245, 245, 1);
    padding: 8px;
    font-size: 75%;
    margin-right: 13px;
  }
`;

const InputGroupAppend = ({ children, appendText }) => (
  <InputGroupAppendWrapper>
    {children}
    <div className="group_append"> {appendText} </div>
  </InputGroupAppendWrapper>
);

InputGroupAppend.propTypes = {
  children: PropTypes.object.isRequired,
  appendText: PropTypes.string.isRequired,
};

export default InputGroupAppend;
