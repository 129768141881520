import firebase from '../../../../firebase';

export const closeUpgradeDialog = () => ({
  type: 'UPGRADE_AUTH_MODEL',
  open: false,
  loading: false,
});

export const getNextInvoiceAmount = (accountId, subscriptionId) => dispatch => {
  const payload = {
    subscriptionId,
    accountId,
  };

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      const options = {
        method: 'post',
        body: JSON.stringify(payload),
        headers,
        mode: 'cors',
      };
      return fetch(
        `${process.env.REACT_APP_PAYMENT_API}/get_next_invoice_amount`,
        options,
      )
        .then(res => res.json())
        .then(body => {
          const { amount } = body;

          dispatch({
            type: 'UPGRADE_AUTH_MODEL_INVOICE_AMOUNT',
            payload: amount,
          });
        })
        .catch(error => null);
    });
};
