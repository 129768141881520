import { RECEIVED_PRIVACY_SETTINGS } from './types';

const privacy = (state = {}, action) => {
  switch (action.type) {
    case RECEIVED_PRIVACY_SETTINGS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default privacy;
