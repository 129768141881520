import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import Checkbox from 'material-ui/Checkbox';
import Divider from 'material-ui/Divider';
import { Trans, withTranslation } from 'react-i18next';

import firebase from '../../../../../firebase';

import TextField from '../../../../../app/components/textField';
import CircularValidation from '../../../../../app/components/circularValidation';
import Subheader from '../../../../../app/components/subheader';
import { FlatButton } from '../../../../../app/components/buttons';
import { CircularProgress } from '../../../../../app/components/progress';
import { ReactReduxContext, Provider } from 'react-redux';
import ImageUpload from '../../../../editor/internals/imageUpload';
import PricingDetails from './pricingDetails';
import SubscriptionFeatures from '../newGroup/features';
import Toggle from '../../../../../app/components/toggle';
import { Link } from 'react-router-dom';
import './styles.css';
import SelectField from '../../../../../app/components/selectField';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from 'material-ui/MenuItem';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';
import themeStyles from '../../../../../_export.scss';
import { objectToArray, arrayToObject } from '../../../../../utils/object';
import { STRIPE_CURRENCIES } from '../../../../../config/constants';

class EditGroup extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    showWarning: PropTypes.func.isRequired,
    groups: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  initialState = {
    name: {
      value: '',
      isValid: false,
    },
    order: {
      value: 0,
      isValid: null,
    },
    hasSubscriptionPlan: {
      value: false,
      isValid: true,
    },
    description: {
      value: '',
      isValid: false,
    },
    price: {
      value: 0,
      isValid: true,
    },
    oldPrice: {
      value: 0,
      isValid: true,
    },
    groupImage: null,
    prevOrderNo: 0,
    prevOrderWeight: null,
    pages: [],
    selected: {},
    pending: false,
    // loading initial data
    loading: true,
    hideNav: false,
    subscriptionErrorMsg: '',
    defaultGroupDialog: false,
    hideSubscription: false,
  };
  state = this.initialState;

  componentDidMount() {
    if (this.props.match && this.props.match.params.group) {
      this.props.loadGroup(appname, group);
    }
    this.listenToPages();
    const { appname, group } = this.props.match.params;
    const { match, groups } = this.props;
    const hideNav =
      match.params.group &&
      groups[match.params.group] &&
      groups[match.params.group].hideNav
        ? groups[match.params.group].hideNav
        : false;
    const hideSubscription =
      match.params.group &&
      groups[match.params.group] &&
      groups[match.params.group].hideSubscription
        ? groups[match.params.group].hideSubscription
        : false;

    this.setState({
      hideNav,
      hideSubscription,
    });
    if (
      this.props.match &&
      this.props.match.params.group &&
      this.props.order.value === 0
    ) {
      // this.props.loadContent(appname);
      this.props.loadGroup(appname, group);
      this.props.loadHideNav(appname, this.props.match.params.group);
      this.props.loadHideSubscription(appname, this.props.match.params.group);
    }

    if (this.props.subscriptionPlan) {
      this.setState({
        price: {
          value: this.props.subscriptionPlan.price,
          ...this.state.price,
        },
        oldPrice: {
          value: this.props.subscriptionPlan.price,
          ...this.state.price,
        },
      });
    }

    if (this.props.isUserSubscriptionEnabled) {
      if (this.props.isStripeAccountAdded) {
        this.setState({ subscriptionErrorMsg: '' });
      } else {
        this.setState({
          subscriptionErrorMsg: this.stripeAccountNotAvailable,
        });
      }
    } else {
      this.setState({
        subscriptionErrorMsg: this.userSubscriptionNotEnabled,
      });
    }
  }

  isDefaultGroup = () => this.props.match.params.group === 'default';
  userSubscriptionNotEnabled = (
    <div>
      <p className="subscription-error-msg">
        <Trans>Enable user subscriptions to edit subscription plan</Trans>
      </p>
      <p className="subscription-link">
        <Link
          to={{
            pathname: `/${this.props.match.params.appname}/members/settings`,
            hash: '#user-app-subscriptions',
          }}
        >
          <Trans>Click here</Trans>
        </Link>{' '}
        <Trans>to go to user subscriptions</Trans>
      </p>
    </div>
  );

  stripeAccountNotAvailable = (
    <div>
      <p className="subscription-error-msg">
        <Trans>Add Stripe API keys to edit subscription plan</Trans>
      </p>
      <p className="subscription-link">
        <Link
          to={{
            pathname: `/${this.props.match.params.appname}/members/settings`,
            hash: '#user-app-subscriptions',
          }}
        >
          <Trans>Click here</Trans>
        </Link>{' '}
        <Trans>to go to user subscriptions and edit your Stripe API Keys</Trans>
      </p>
    </div>
  );

  stripeAccountError = (
    <div>
      <p className="subscription-error-msg">
        <Trans>
          Error creating subscription. Verify if your Stripe keys are correct
        </Trans>
      </p>
      <p className="subscription-link">
        <Link
          to={{
            pathname: `/${this.props.match.params.appname}/members/settings`,
            hash: '#user-app-subscriptions',
          }}
        >
          <Trans>Click here</Trans>
        </Link>{' '}
        <Trans>to go to user subscriptions and edit your Stripe API Keys</Trans>
      </p>
    </div>
  );
  componentDidUpdate(prevProps) {
    const { appname, group } = this.props.match.params;
    const { groups, match } = this.props;

    if (
      this.props.order.value !== prevProps.order.value &&
      prevProps.order.value !== 0
    ) {
      this.setState({
        prevOrderNo: prevProps.order.value,
      });
    }

    if (
      this.props.hasUpdatedImage &&
      this.props.hasUpdatedImage !== prevProps.hasUpdatedImage
    ) {
      this.props.loadContent(appname);
    }
    if (
      this.props.match &&
      this.props.match.params.group !== prevProps.match.params.group
    ) {
      const hideNav =
        match.params.group &&
        groups[match.params.group] &&
        groups[match.params.group].hideNav
          ? groups[match.params.group].hideNav
          : false;
      const hideSubscription =
        match.params.group &&
        groups[match.params.group] &&
        groups[match.params.group].hideSubscription
          ? groups[match.params.group].hideSubscription
          : false;
      this.props.loadHideNav(appname, this.props.match.params.group);
      this.props.loadHideSubscription(appname, this.props.match.params.group);
      this.props.loadGroup(appname, group);
      this.setState({ hideNav, hideSubscription });
    }
    if (
      this.props.isUpdated !== prevProps.isUpdated &&
      this.props.isUpdated === true
    ) {
      this.setState({ pending: false });
      this.props.history.push(`/${appname}/members/groups`);
    }
    if (this.props.selected && this.props.selected !== prevProps.selected) {
      this.setState({ selected: this.props.selected });
    }

    if (
      this.props.isStripeAccountAdded !== prevProps.isStripeAccountAdded ||
      this.props.isUserSubscriptionEnabled !==
        prevProps.isUserSubscriptionEnabled
    ) {
      if (this.props.isUserSubscriptionEnabled) {
        if (this.props.isStripeAccountAdded) {
          this.setState({ subscriptionErrorMsg: '' });
        } else {
          this.setState({
            subscriptionErrorMsg: this.stripeAccountNotAvailable,
          });
        }
      } else {
        this.setState({
          subscriptionErrorMsg: this.userSubscriptionNotEnabled,
        });
      }
    }
    if (this.props.error !== prevProps.error) {
      if (this.props.error) {
        this.setState({
          pending: false,
          subscriptionErrorMsg: this.stripeAccountError,
        });
      } else {
        this.setState({
          subscriptionErrorMsg: '',
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.listener) {
      this.draftPagesRef().off('value', this.listener);
    }
    this.props.onUnmount(this.props.match.params.appname);
  }

  onSubmit = async () => {
    const { appname, group } = this.props.match.params;
    const convertGroups = objectToArray(this.props.groups);
    const subscriptionGroups = convertGroups.filter(
      item => item.value.subscriptionPlan && item.id !== group,
    );

    this.props.updateHideNavData(appname, this.state.hideNav, group);
    this.props.updateVisibility(appname, this.state.hideSubscription, group);

    this.setState({ pending: true });
    const update = {
      name: this.props.name.value,
      draft: this.state.selected,
      last_updated: Date.now(),
    };

    if (this.props.hasSubscriptionPlan.value) {
      update.order = this.props.order.value;
      update.description = this.props.description.value;
      update.price = this.props.price.value;
      update.currency = this.props.currency.value;
      update.hasSubscriptionPlan = true;
      update.hasPriceChanged =
        this.props.price.value !== this.props.oldPrice.value;
      update.features = this.props.features;
      update.id = this.props.subscriptionPlan.product_id;
      update.billingType = this.props.subscriptionPlan.billingType;
      update.billingCycle = this.props.subscriptionPlan.billingCycle;
      update.hasFreeTrial = this.props.subscriptionPlan.hasFreeTrial;
      update.freeTrialPeriod = this.props.subscriptionPlan.freeTrialPeriod;
      update.old_pricing_id = this.props.subscriptionPlan.pricing_id;
      if (this.props.groupImage) {
        update.images = [this.props.groupImage];
      }

      const getPreviousGroupWithOrderNo = convertGroups.filter(
        item => item.value.order === this.props.order.value,
      );

      //reset order nos if order no not found or has multiple occurance
      if (
        getPreviousGroupWithOrderNo.length === 0 ||
        getPreviousGroupWithOrderNo.length > 1
      ) {
        const generateOrderNos = Array.from(
          { length: subscriptionGroups.length + 1 },
          (_, index) => index + 1,
        );
        const orderNos = generateOrderNos.filter(
          item => item != this.props.order.value,
        );

        await this.props.resetGroupOrder(appname, subscriptionGroups, orderNos);
      } else {
        this.props.updateGroupData(appname, getPreviousGroupWithOrderNo[0].id, {
          order: this.state.prevOrderNo,
        });
      }
    }

    this.props.updateGroup(appname, group, update);
    this.props.deleteTempImage(this.props.match.params.appname);
    this.props.setUpdatedImageStatus(false);
    this.props.loadHideNav(appname, this.props.match.params.group);
    this.props.loadHideSubscription(appname, this.props.match.params.group);
  };

  listenToPages = async () => {
    this.listener = this.draftPagesRef().on('value', snapshot => {
      if (snapshot && snapshot.exists()) {
        const pages = snapshot.val();

        this.loadPages(pages);
      }
    });
  };

  draftPagesRef = () => {
    const { appname } = this.props.match.params;

    return firebase
      .database()
      .ref(`apps/${appname}/draft/layout_data/childRoutes`);
  };

  loadPages = async (pageKeys = []) => {
    const { appname } = this.props.match.params;

    if (pageKeys === null) {
      return this.setState({ pages: [] });
    }

    const promises = pageKeys.map(key =>
      firebase
        .database()
        .ref(`apps/${appname}/draft/always_data/${key}`)
        .once('value'),
    );

    const snapshots = await Promise.all(promises);

    const pages = snapshots
      .map(snap => (snap.exists() ? { key: snap.key, ...snap.val() } : null))
      .filter(Boolean);

    return this.setState({ pages });
  };

  toggleAll = value => {
    const nextState = this.state.pages
      .filter(item => item.publicPage !== true)
      .reduce(
        (next, val) => ({
          ...next,
          [val.key]: value,
        }),
        {},
      );

    this.setState({ selectAll: value, selected: nextState });
  };

  closeDialog = () => {
    this.props.onUnmount();
  };

  toggleDefaultGroupDialog = () => {
    this.setState({ defaultGroupDialog: !this.state.defaultGroupDialog });
  };

  getGroupswithHideNav = () => {
    const { groups, name } = this.props;
    const groupValues = Object.values(groups);
    let groupsToShow = [];
    groupValues.forEach(group => {
      if (!group.hideNav) {
        groupsToShow.push(group.name);
      }
    });
    if (groupsToShow.includes(name.value)) {
      groupsToShow = [
        name.value,
        ...groupsToShow.filter(item => item !== name.value),
      ];
    }
    return groupsToShow;
  };

  groupsWithSubscription = () => {
    const { groups } = this.props;

    const formatGroups = objectToArray(groups);

    const subscriptionPlans = formatGroups.filter(item => {
      return item.value.subscriptionPlan;
    });

    return subscriptionPlans.length > 0 ? arrayToObject(subscriptionPlans) : {};
  };

  render() {
    const { history, match, showWarning, groups, t } = this.props;
    const { hideNav, hideSubscription } = this.state;
    const groupsWithHideNav = this.getGroupswithHideNav();
    const subscriptionPlans = this.groupsWithSubscription();

    const orderTip = (
      <span>
        <span>
          <Trans>Change the default order of this subscription plan.</Trans>
        </span>
      </span>
    );

    return (
      <>
        <ReactReduxContext.Consumer>
          {ctx => (
            <Dialog
              open={!!(match && match.params.group)}
              onRequestClose={() => {
                this.closeDialog();
                this.props.deleteTempImage(match.params.appname);
                this.props.setUpdatedImageStatus(false);
                history.push(`/${match.params.appname}/members/groups`);
              }}
              onEntering={() => {
                this.setState({ hideNav: this.props.hideNav });
              }}
              autoScrollBodyContent
              contentClassName="user-groups-modal"
              paperClassName="user-groups-paper"
            >
              <div className="user-groups-modal-content">
                <div className="modal-heading-container">
                  <h5>
                    <Trans>Edit a Group</Trans>
                  </h5>
                </div>
                <div>
                  {this.props.loading ? (
                    <div style={{ margin: '24px auto', width: 18 }}>
                      <CircularProgress size={18} />
                    </div>
                  ) : (
                    <React.Fragment>
                      <Divider />
                      <div className="inner-container">
                        <div className="users-list-container">
                          <TextField
                            id="name"
                            placeholder={t('Group Name')}
                            {...this.props.name}
                            onChange={value =>
                              this.props.formFieldChange('name', value)
                            }
                            rightInputAction={
                              <CircularValidation
                                defaultMsg={t("Group's name required")}
                                errorMsg={t(
                                  'Name must be at least 2 characters long',
                                )}
                                isValid={this.props.name.isValid}
                                showDefault={!this.props.name.value}
                              />
                            }
                          />

                          {this.props.hasSubscriptionPlan.value && (
                            <React.Fragment>
                              <div className="item-order">
                                <SelectField
                                  {...this.props.order}
                                  className="offset-top"
                                  label={t('Order')}
                                  options={Array.from({
                                    length: Object.keys(subscriptionPlans)
                                      .length,
                                  }).map((_, index) => (
                                    <MenuItem
                                      key={index}
                                      value={index + 1}
                                      primaryText={index + 1}
                                    />
                                  ))}
                                  onChange={(i, payload) =>
                                    this.props.formFieldChange('order', payload)
                                  }
                                  autoWidth
                                />
                                <Tooltip title={orderTip}>
                                  <InfoOutlinedIcon fontSize="small" />
                                </Tooltip>
                              </div>
                              <Provider store={ctx.store}>
                                <ImageUpload
                                  title={t('Image for push notification')}
                                  src={this.props.groupImage}
                                  firebaseUrl={`/apps/${this.props.match.params.appname}/temp_data/editGroupImage`}
                                  showCropAreaSizes={false}
                                  height="220px"
                                  width="320px"
                                  source="group"
                                />{' '}
                              </Provider>
                              <TextField
                                id="description"
                                placeholder={t('Description')}
                                {...this.props.description}
                                onChange={value => {
                                  this.props.formFieldChange(
                                    'description',
                                    value,
                                  );
                                }}
                              />

                              <TextField
                                type="number"
                                min="0"
                                id="price"
                                placeholder={t('Price')}
                                {...this.props.price}
                                onChange={value =>
                                  this.props.formFieldChange('price', value)
                                }
                              />

                              <SelectField
                                {...this.props.currency}
                                className="offset-top"
                                label={t('Currency')}
                                options={STRIPE_CURRENCIES.map(obj => (
                                  <MenuItem
                                    key={obj.value}
                                    value={obj.value}
                                    primaryText={obj.label}
                                  />
                                ))}
                                onChange={(i, payload) =>
                                  this.props.formFieldChange(
                                    'currency',
                                    payload,
                                  )
                                }
                                disabled={STRIPE_CURRENCIES.length === 0}
                                autoWidth
                              />
                              <div style={{ marginTop: '20px' }}>
                                <PricingDetails {...this.props} />
                              </div>
                              <SubscriptionFeatures {...this.props} />
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: '16px 0px',
                                }}
                              >
                                <span>
                                  <Trans>
                                    Change subscription plan visibility to
                                    Hidden
                                  </Trans>
                                </span>
                                <Toggle
                                  toggled={hideSubscription}
                                  onToggle={() =>
                                    this.setState({
                                      hideSubscription: !this.state
                                        .hideSubscription,
                                    })
                                  }
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                        <div
                          style={{
                            margin: '20px 0',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            marginRight: '10px',
                          }}
                        >
                          {this.isDefaultGroup() && (
                            <Button
                              style={{
                                borderRadius: '32px',
                                color: themeStyles.primaryTextColor,
                                backgroundColor: themeStyles.primaryColor,
                                top: '-9px',
                              }}
                              color="primary"
                              onClick={this.toggleDefaultGroupDialog}
                              variant="contained"
                            >
                              <Trans>More on My Default Group</Trans>
                            </Button>
                          )}
                          <div style={{ display: 'flex' }}>
                            <span>
                              <Trans>Hide restricted pages from navbar</Trans>
                            </span>
                            <Toggle
                              toggled={hideNav}
                              onToggle={() =>
                                this.setState({ hideNav: !this.state.hideNav })
                              }
                            />
                          </div>
                        </div>
                        <p
                          style={{
                            fontSize: 'smaller',
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          {/* Please note that when you hide restricted pages from the
                      navigation menu this would be applied to all groups. */}
                        </p>
                      </div>
                      {groupsWithHideNav.length > 0 && (
                        <div className="group-notification">
                          <span>
                            <Trans>
                              Please note that the restricted pages in
                            </Trans>{' '}
                            {groupsWithHideNav.join(', ')}{' '}
                            <Trans>have not been hidden from the navbar.</Trans>
                          </span>
                        </div>
                      )}
                      <div className="group-list-container">
                        <div className="group-list">
                          <div className="group-list-header">
                            <span style={{ paddingRight: 24 }}>
                              <Trans>
                                Once published, only group members will be able
                                to view the selected pages.
                              </Trans>
                            </span>
                            <span className="select-all">
                              <Subheader title={t('Select All')} />
                              <Checkbox
                                checked={this.state.selectAll}
                                onCheck={(e, isChecked) =>
                                  this.toggleAll(isChecked)
                                }
                              />
                            </span>
                          </div>
                          <div className="groups">
                            {this.props.loading ? (
                              <div style={{ margin: '24px auto', width: 18 }}>
                                <CircularProgress size={18} />
                              </div>
                            ) : (
                              this.state.pages.map((page, i) => (
                                <div className="group" key={page.key}>
                                  <div>
                                    <span
                                      className="name"
                                      style={{
                                        display: 'inline-block',
                                        height: '24px',
                                        verticalAlign: 'middle',
                                      }}
                                    >
                                      {page.title}
                                    </span>
                                  </div>
                                  <span className="select">
                                    <Checkbox
                                      checked={
                                        this.state.selected[page.key] ||
                                        page.publicPage
                                      }
                                      onCheck={(e, isChecked) => {
                                        e.stopPropagation();
                                        page.publicPage
                                          ? showWarning(
                                              t(
                                                'This page is a public page and it is visible to all users. Remove it from Public Pages in Member login -> Settings.',
                                              ),
                                            )
                                          : this.setState({
                                              selected: {
                                                ...this.state.selected,
                                                [page.key]: isChecked,
                                              },
                                            });
                                      }}
                                    />
                                  </span>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                      <Divider />
                      <div className="submit-button-container">
                        <FlatButton
                          label={
                            this.state.pending ? t('Saving...') : t('Save')
                          }
                          onClick={this.onSubmit}
                          disabled={
                            !this.props.name.isValid ||
                            this.state.pending ||
                            (!this.props.isUserSubscriptionEnabled &&
                              this.props.hasSubscriptionPlan.value) ||
                            (!this.props.isStripeAccountAdded &&
                              this.props.hasSubscriptionPlan.value) ||
                            this.props.error
                          }
                          primary
                        />
                      </div>
                      {this.props.hasSubscriptionPlan.value &&
                        this.state.subscriptionErrorMsg}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Dialog>
          )}
        </ReactReduxContext.Consumer>
        <MuiDialog
          style={{ zIndex: 1500 }}
          open={this.state.defaultGroupDialog}
          onClose={() => this.toggleDefaultGroupDialog()}
        >
          <MuiDialogTitle>
            <Trans>What is My Default Group?</Trans>
          </MuiDialogTitle>
          <MuiDialogContent>
            <div style={{ padding: '1em 0em' }}>
              <p>
                <Trans>
                  Any users who signup via Member Login feature on your app with
                  their e-mail address will be automatically allocated to the
                  default group with limited or zero access to your app
                  pages/content.
                </Trans>
              </p>
              <p>
                <Trans>
                  Please select the pages that you wish to be seen by your
                  Default Group users. When the users try to access any of the
                  non-selected pages, they will see a Restricted Access page.
                </Trans>{' '}
              </p>
              <p>
                <Trans>
                  You can edit the Restricted Access page layout in Settings.
                </Trans>{' '}
                <Link
                  to={{
                    pathname: `/${this.props.match.params.appname}/members/settings`,
                    hash: 'restricted-access-pages',
                  }}
                >
                  <Trans>Click here</Trans>
                </Link>{' '}
                to go to Restricted Access page layout.
              </p>
            </div>
          </MuiDialogContent>
        </MuiDialog>
      </>
    );
  }
}

export default withTranslation()(EditGroup);
