import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import HistoryDetails from './HistoryDetails';

import {
  closeHistoryDetailDialog,
  previousResponse,
  nextResponse,
} from './actions';
import { deleteSelected } from '../actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.engage.history.historyDetails,
  responseKey: state.engage.history.historyDetails.key,
  totalResponses: state.engage.history.data.items.length,
  currentResponse:
    state.engage.history.data.items
      .map(key => key === state.engage.history.historyDetails.key)
      .indexOf(true) + 1,
  historys: state.engage.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRequestClose: () => dispatch(closeHistoryDetailDialog()),
  onPreviousResponse: (currentResponse, historys) =>
    dispatch(
      previousResponse(
        ownProps.match.params.appname,
        currentResponse,
        historys,
      ),
    ),
  onNextResponse: (currentResponse, historys) =>
    dispatch(
      nextResponse(ownProps.match.params.appname, currentResponse, historys),
    ),
  onDeleteSelected: selected =>
    dispatch(deleteSelected(ownProps.match.params.appname, selected)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(HistoryDetails),
);
