import { SELECT_VENDOR_PRODUCT, DESELECT_VENDOR_PRODUCT } from './types';

export const selectProduct = product => ({
  type: SELECT_VENDOR_PRODUCT,
  value: product,
});

export const deselectProduct = () => ({
  type: DESELECT_VENDOR_PRODUCT,
});
