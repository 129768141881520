import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';

import NewGroup from './NewGroup';

import {
  addGroup,
  closeNewGroupDialog,
  formFieldChange,
  loadContent,
} from './actions';

import { updateGroupData } from '../editGroup/actions';

import { getGroupsData } from '../actions';

const mapStateToProps = state => {
  return {
    ...state.auth.groups.newGroup,
    groups: state.auth.groups.data.subscriptionGroups,
    groupImage: _get(state, 'auth.groups.newGroup.groupImage') || null,
    isUserSubscriptionEnabled: _get(
      state,
      'auth.groups.data.chunks.default.userSubscriptions',
      false,
    ),
    isStripeAccountAdded:
      _get(state, 'auth.groups.data.chunks.default.stripeApiKey', '').length >
        0 &&
      _get(state, 'auth.groups.data.chunks.default.stripeApiPublicKey', '')
        .length > 0,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(addGroup(ownProps.match.params.appname)),
  onRequestClose: () => dispatch(closeNewGroupDialog()),
  onFieldChange: (field, value) => dispatch(formFieldChange(field, value)),
  loadContent: appName => dispatch(loadContent(appName)),
  getGroups: appName => dispatch(getGroupsData(appName)),
  onAddFeature: text =>
    dispatch({ type: 'NEW_GROUP_FEATURE_ADDED', value: text }),
  onRemoveFeature: value =>
    dispatch({ type: 'NEW_GROUP_REMOVE_FEATURE', value }),
  onComponentUnmount: () => dispatch({ type: 'RESET_STATE' }),
  updateGroupData: (appnme, group, payload) =>
    dispatch(updateGroupData(appnme, group, payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewGroup),
);
