import { connect } from 'react-redux';
import _get from 'lodash/get';
import { getGroups } from '../../../../../../memberLogin/modules/groups/actions';
import SubscriptionPlans from './subscriptionPlans';
import { onSubmit, designChange } from './actions';

const mapStateToProps = (state, ownProps) => {
  const appname = ownProps.match.params.appname;
  const palette = _get(state, `editor.design.${appname}.palette`);
  const componentDesign = ownProps.component.design || {};
  const border = {
    width: 1,
    style: 'solid',
    color: palette.alternateTextColor,
    ...componentDesign.border,
  };
  const design = {
    ...ownProps.component.design,
    enabled: componentDesign.enabled,
    buttonDesign: {
      enabled: componentDesign.enabled,
      textColor: componentDesign.textColor || palette.alternateTextColor,
      backgroundColor: componentDesign.backgroundColor || palette.primary1Color,
      borderRadius: componentDesign.borderRadius,
      border,
    },
  };
  return {
    ...ownProps,
    component: {
      design,
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getGroups: () => dispatch(getGroups(ownProps.match.params.appname)),
  onSubmit: (value, type) => {
    dispatch(
      onSubmit(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
        type,
      ),
    );
  },
  onDesignToggle: value =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        'enabled',
        value,
      ),
    ),
  onBtnDesignChange: property => (type, value) => {
    return dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        type,
        value,
        property,
      ),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlans);
