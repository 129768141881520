import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AddIcon from 'material-ui/svg-icons/content/add';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from 'material-ui/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Trans, useTranslation } from 'react-i18next';
import {
  Fade,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FloatingActionButton } from '../../../../app/components/buttons';

import Search from '../../../../app/components/search';

const StyledIconButton = styled(IconButton)`
  && {
    position: absolute !important;
    right: 0;
    top: 18px;
    svg {
      color: #261ed5;
    }
  }
`;

const useStyles = makeStyles({
  inputRoot: {
    borderRadius: '25px',
    '& fieldset': {
      border: '2px solid #707070',
    },
  },
  inputLabelRoot: {
    fontSize: '20px',
    fontFamily: "'Raleway SemiBold', 'Rubik', 'Arial', sans-serif !important",
  },
  paperRoot: {
    minWidth: '500px',
    height: 'fit-content',
    margin: '0 12px',
    marginTop: '64px',
    overflow: 'hidden',
    borderRadius: '16px',
  },
});
export const TopBarContainer = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #f0e8e8;
  align-items: center;

  height: 64px;
  transition: filter 0.3s;
  position: relative;
  .back-btn {
    position: absolute;
    justify-content: flex-start;
  }
  .booking-text {
    justify-content: center;
    color: #7c7c7c;
  }
  > div {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 16px;

    form {
      margin: 0;
    }
    .search-bar {
      display: flex;
      align-items: center;
      border: 1px solid #707070;
      border-radius: 25px;
      max-height: 35px;

      > .search-icon {
        color: #6d6d6d;
        margin: 0 16px;
        position: absolute;
      }
    }

    > .filters-btn {
      margin-left: 16px;
      > button {
        font-size: 14px;
        font-family: 'Raleway SemiBold', 'Rubik', 'Arial', sans-serif !important;

        color: #000000;
        text-transform: none;
      }
    }

    @media (max-width: 640px) {
      > .search-bar {
        width: 70%;
      }
      > .filters-btn {
        width: 30%;
      }
    }
  }
`;

export const FilterContainer = styled.div`
  padding: 0.5em;
  > .filter-field-title {
    padding: 0.5em;
    margin-left: 6px;
    font-size: 20px;
    font-family: 'Raleway SemiBold', 'Rubik', 'Arial', sans-serif;
  }
  > .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .filter-field {
      margin: 0.5em;
      margin-left: 16px;
      border-radius: 25px;
    }
  }
`;

const Filters = styled.div`
  width: 70%;
  padding: 24px;
  padding-top: 0px;
  overflow: auto;
  @media (max-width: 640px) {
    width: 100%;
    padding: 12px;
  }
`;

const FiltersBody = styled.div`
  overflow: auto;
  width: 100%;
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b3a9a9;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    outline: none;
  }
`;

export const StyledDialogTitle = styled.p`
  font-size: 1.5rem;
  margin-left: 46px;
  margin-top: 24px;
  width: 100%;
  font-family: 'Raleway Bold', 'Rubik', 'Arial', sans-serif;
  color: #151515;
  @media (max-width: 640px) {
    font-size: 1.5rem;

    margin-left: 34px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  border-radius: 16px !important;
`;

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

const Header = ({
  title,
  filter,
  onChange,
  filteredColumns,
  groupsFilterSelected,
  handleGroupFilterChange,
  handleVerifiedFilterChange,
  verifiedFilterSelected,
  groups,
  fullWidthHeader,
  showAppAdminBtn,
  openNewAdminDialog,
  type,
  subscriptionPlanFilterSelected,
  handleSubscriptionPlanSelected,
}) => {
  const { t } = useTranslation();
  const [filterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const toggleFilterDialog = () => setFilterDialogOpen(!filterDialogOpen);
  const classes = useStyles();

  const resetFilter = () => {
    handleGroupFilterChange();
    handleVerifiedFilterChange();
    handleSubscriptionPlanSelected();
  };

  return (
    <div className="row middle-xs">
      <div className={`col-xs-4 ${fullWidthHeader && 'col-xs-6'}`}>
        <span
          style={{
            fontFamily: 'Raleway, sans-serif',
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          {title}
        </span>
      </div>
      <div className="col-xs-7" style={{ position: 'relative' }}>
        <Search
          name="search"
          placeholder={t('Search...')}
          value={filter}
          onChange={val => onChange(val)}
          onClear={() => onChange('')}
          fullWidthHeader
        />
        {filteredColumns.length ? (
          <StyledIconButton tooltip={t('Filters')} onClick={toggleFilterDialog}>
            <FilterListIcon style={{ color: '#261ed5' }} />
          </StyledIconButton>
        ) : null}

        {filterDialogOpen ? (
          <Dialog
            open={filterDialogOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={toggleFilterDialog}
            PaperProps={{
              classes: {
                root: classes.paperRoot,
              },
            }}
          >
            <StyledDialogTitle>
              {type === 'group' ? (
                <Trans>Filter Groups & Subscriptions</Trans>
              ) : (
                <Trans>Filter Users</Trans>
              )}
            </StyledDialogTitle>
            <FiltersBody>
              <Filters>
                {filteredColumns.includes('groups') ? (
                  <FilterContainer>
                    <span className="filter-field-title">
                      <Trans>Groups</Trans>
                    </span>
                    <div className="filter">
                      <FormControl fullWidth className="filter-field">
                        <InputLabel id="select-group-label">
                          <Trans>Select a group from below</Trans>
                        </InputLabel>
                        <Select
                          labelId="select-group-label"
                          id="select-group"
                          value={groupsFilterSelected}
                          onChange={handleGroupFilterChange}
                          variant="outlined"
                          label={t('Select a group from below')}
                        >
                          <MenuItem value="none" key="none">
                            <Trans>All</Trans>
                          </MenuItem>

                          {Object.keys(groups).map(key => (
                            <MenuItem value={key}>{groups[key].name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FilterContainer>
                ) : null}
                {filteredColumns.includes('emailVerified') ? (
                  <FilterContainer>
                    <span className="filter-field-title">
                      <Trans>Email Verified</Trans>
                    </span>
                    <div className="filter">
                      <FormControl fullWidth className="filter-field">
                        <InputLabel id="select-group-label">
                          <Trans>Select if the user's email is verified</Trans>
                        </InputLabel>
                        <Select
                          labelId="select-email-verified-label"
                          id="select-email-verified"
                          value={verifiedFilterSelected}
                          onChange={handleVerifiedFilterChange}
                          variant="outlined"
                          label={t("Select if the user's email is verified")}
                        >
                          {[t('All'), t('Verified'), t('Not Verified')].map(
                            value => (
                              <MenuItem value={value} key={value}>
                                {value}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </FilterContainer>
                ) : null}
                {filteredColumns.includes('subscriptionPlan') ? (
                  <FilterContainer>
                    <span className="filter-field-title">
                      <Trans>Subscription Plan</Trans>
                    </span>
                    <div className="filter">
                      <FormControl fullWidth className="filter-field">
                        <InputLabel id="select-group-label">
                          <Trans>
                            Select if the group has a subscription plan
                          </Trans>
                        </InputLabel>
                        <Select
                          labelId="select-subscription-plan-label"
                          id="select-subscription-plan"
                          value={subscriptionPlanFilterSelected}
                          onChange={handleSubscriptionPlanSelected}
                          variant="outlined"
                          label={t(
                            'Select if the group has a subscription plan',
                          )}
                        >
                          {[t('All'), t('Yes'), t('No')].map(value => (
                            <MenuItem value={value} key={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </FilterContainer>
                ) : null}
              </Filters>
            </FiltersBody>
            <DialogActions>
              <ButtonWrapper>
                <StyledButton onClick={() => resetFilter()}>
                  <Trans>Reset</Trans>
                </StyledButton>
                <StyledButton onClick={toggleFilterDialog}>
                  <Trans>Close</Trans>
                </StyledButton>
              </ButtonWrapper>
            </DialogActions>
          </Dialog>
        ) : null}
      </div>
      {showAppAdminBtn && (
        <div>
          <FloatingActionButton
            icon={<AddIcon />}
            onClick={openNewAdminDialog}
          />
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.element.isRequired,
  filter: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  filteredColumns: PropTypes.arrayOf(PropTypes.string),
  groups: PropTypes.array,
  groupsFilterSelected: PropTypes.bool,
  handleGroupFilterChange: PropTypes.func,
  handleVerifiedFilterChange: PropTypes.func,
  verifiedFilterSelected: PropTypes.string,
  fullWidthHeader: PropTypes.bool,
  showAppAdminBtn: PropTypes.bool,
  openNewAdminDialog: PropTypes.func,
  subscriptionPlanFilterSelected: PropTypes.string,
  type: PropTypes.string,
  handleSubscriptionPlanSelected: PropTypes.func,
};

Header.defaultProps = {
  actionMenu: null,
  error: '',
  subscriptionPlanFilterSelected: '',
  selectAll: false,
  filter: '',
  filteredColumns: [],
  groups: [],
  groupsFilterSelected: false,
  handleGroupFilterChange: () => {},
  handleVerifiedFilterChange: () => {},
  handleSubscriptionPlanSelected: () => {},
  verifiedFilterSelected: '',
  fullWidthHeader: false,
  showAppAdminBtn: false,
  openNewAdminDialog: () => {},
  type: '',
};

export default Header;
