const initialState = {};

const defaultGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVE_AUTH_GROUP':
      return {
        ...state,
        ...action.value,
      };

    default:
      return state;
  }
};

export default defaultGroupReducer;
