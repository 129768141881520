import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import Done from '@material-ui/icons/Done';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Clear from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Snackbar from '@material-ui/core/Snackbar';

import CancelIcon from '@material-ui/icons/Cancel';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import Checkbox from '@material-ui/core/Checkbox';
import { Trans, withTranslation } from 'react-i18next';
import TableCheckbox from '../../components/tableCheckbox';
import TryAgain from '../../../../app/components/tryAgain';
import Header from '../../components/header';
import ActionMenu from '../../../../app/components/actionMenu';
import UserGroupsDialog from './UserGroupsDialog';
import ResultsTable from '../../../../app/components/resultsTable';
import {
  downloadCSV,
  formatDate,
  sortArray,
  formatDateNoTime,
  isValidDate,
} from '../../../../utils';

import themeStyles from '../../../../_export.scss';
import './styles.css';
import { SCOTTISH_CURLING_FEATURES_ENABLED_APPS } from '../../../../config/constants';

const isCheckboxDisabled = (error, pending) => error && !pending;

class Users extends Component {
  static propTypes = {
    verficationMessage: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    items: PropTypes.array.isRequired,
    error: PropTypes.string,
    filter: PropTypes.string,
    chunks: PropTypes.object,
    getUsers: PropTypes.func.isRequired,
    onFilterUserData: PropTypes.func.isRequired,
    selected: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
    onSelectAllUsers: PropTypes.func.isRequired,
    onDeselectAllUsers: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    onOpenUserGroupDialog: PropTypes.func.isRequired,
    userGroupsDialogOpen: PropTypes.bool.isRequired,
    toggleSelectedGroup: PropTypes.func.isRequired,
    selectedGroups: PropTypes.array.isRequired,
    onRemoveGroup: PropTypes.func.isRequired,
    groups: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    getPageCountAnalytics: PropTypes.func.isRequired,
    pageAnalyticsDataPending: PropTypes.bool.isRequired,
    pageAnalyticsData: PropTypes.object,
    resetUsersData: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: '',
    filteredChunks: {},
    chunks: null,
    selected: [],
    selectedGroups: [],
    pageAnalyticsData: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      sortedItems: this.props.items,
      dateSortArrowUp: true,
      nameSortArrowUp: null,
      lastNameSortArrowUp: null,
      emailSortArrowUp: null,
      verfiedMenuSelected: null,
      adminVerifiedFilterSelected: '',
      adminVerifiedFilterModal: false,
      verifiedFilterSelected: 'All',
      groupFilterModal: false,
      groupsFilterSelected: 'none',
      enableInfoDialog: false,
      showSnackBar: false,
    };
  }

  componentDidMount() {
    this.sortByDate();
    // this.sortByName();
  }

  componentDidUpdate(prevProps) {
    let sortedItems = [];
    if (prevProps.filteredChunks !== this.props.filteredChunks) {
      if (this.state.dateSortArrowUp !== null) {
        sortedItems = sortArray(
          Object.entries(this.props.filteredChunks),
          'created_at',
          this.state.dateSortArrowUp,
        );
      }
      if (this.state.nameSortArrowUp !== null) {
        sortedItems = sortArray(
          Object.entries(this.props.filteredChunks),
          'firstName',
          this.state.nameSortArrowUp,
        );
      }
      if (this.state.lastNameSortArrowUp !== null) {
        sortedItems = sortArray(
          Object.entries(this.props.filteredChunks),
          'lastName',
          this.state.lastNameSortArrowUp,
        );
      }
      if (this.state.emailSortArrowUp !== null) {
        sortedItems = sortArray(
          Object.entries(this.props.filteredChunks),
          'email',
          this.state.emailSortArrowUp,
        );
      }
      sortedItems = sortedItems.map(entity => entity[0]);
      this.setState({ sortedItems });
    }

    if (prevProps.verficationMessage !== this.props.verficationMessage) {
      this.setState({
        showSnackBar: true,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetUsersData();
  }

  setShowSnackBar = () => {
    this.setState({ showSnackBar: false });
  };
  getSelectAllCheckBox = () => {
    const {
      error,
      pending,
      selected,
      chunks,
      selectAll,
      onSelectAllUsers,
      onDeselectAllUsers,
      onDeleteSelected,
      onOpenUserGroupDialog,
      t,
    } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllUsers() : onDeselectAllUsers()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          onDownloadSelected={() => {
            downloadCSV(this.getCsvData(selected, chunks), 'users');
          }}
          onMoveSelected={() => onOpenUserGroupDialog(selected)}
          text={t('users')}
        />
      </div>
    );
  };

  toggleInfoDialog = () => {
    this.setState({ enableInfoDialog: !this.state.enableInfoDialog });
  };

  getTitle = (selected, users) => {
    const { t } = this.props;

    return (
      <span
        style={{
          fontFamily: 'Raleway, sans-serif',
          fontWeight: 600,
          fontSize: 20,
        }}
      >
        <Trans>Users</Trans>:{' '}
        {selected.length
          ? `${selected.length} / ${Object.keys(users).length}`
          : Object.keys(users).length}
        <IconButton
          color="primary"
          aria-label={t('information')}
          component="span"
          onClick={this.toggleInfoDialog}
        >
          <InfoOutlinedIcon />
        </IconButton>
      </span>
    );
  };

  getGroupChips = (value, rowId) =>
    value && Object.keys(this.props.groups).length > 0 ? (
      <div className="group-tags">
        {Object.keys(value).map(
          group =>
            this.props.groups[group] &&
            this.props.groups[group].name && (
              <Chip
                onDelete={() => {
                  if (
                    Object.keys(this.props.chunks[rowId].groups).length !== 1
                  ) {
                    const result = [];
                    Object.keys(value).forEach(groupKey => {
                      if (groupKey !== group) {
                        result.push(groupKey);
                      }
                    });
                    this.props.onRemoveGroup(
                      result,
                      [rowId],
                      this.props.chunks,
                      this.props.groups,
                    );
                  }
                }}
                variant="outlined"
                key={group}
                style={{
                  margin: 2,
                  borderRadius: '4px',
                }}
                classes={{
                  deleteIcon:
                    this.props.chunks &&
                    Object.keys(this.props.chunks[rowId].groups).length === 1
                      ? 'disabled'
                      : '',
                }}
                label={
                  this.props.groups[group] && this.props.groups[group].name
                }
              />
            ),
        )}
      </div>
    ) : null;

  getCsvData = (selected, contacts) => {
    const { t } = this.props;
    let customFields = [];
    const groupValues = Object.values(this.props.groups);
    groupValues.map(group => {
      if (group.customProfileFields) {
        customFields = [
          ...customFields,
          ...Object.entries(group.customProfileFields),
        ];
      }
    });

    const customFieldLabels =
      customFields.length > 0
        ? customFields.map(item => item[1].name.value)
        : [];
    const customFieldKeys =
      customFields.length > 0 ? customFields.map(item => item[0]) : [];

    const captializeCustomLabels = customFieldLabels.map(
      item => `${item[0].toUpperCase()}${item.substring(1)}`,
    );

    const csvColumns = [
      t('First name'),
      t('Last name'),
      t('Email'),
      t('Created'),
      t('Groups'),
      t('Phone'),
      t('DOB'),
      t('Verified'),
      t('Organisation'),
      t('Role'),
      t('Allow chat'),
      t('Active'),
      t('Show email in search'),
      t('Show profile in search'),
      t('LinkedIn Address'),
      t('Twitter Handle'),
      t('Website URL'),
      t('Interest'),
      ...captializeCustomLabels,
    ];
    let data = `${csvColumns.join(',')}\n`;

    selected.forEach(key => {
      const customFieldValues = [];

      customFieldKeys.map(customKey => {
        const value =
          contacts[key].customFieldInfo &&
          contacts[key].customFieldInfo[customKey]
            ? contacts[key].customFieldInfo[customKey].value
            : '';
        const finalValue = isValidDate(value) ? formatDateNoTime(value) : value;
        customFieldValues.push(finalValue);
      });

      const groupNames = [];
      const groupKeys = Object.keys(
        contacts[key].groups ? contacts[key].groups : {},
      );
      groupKeys.forEach(groupKey => {
        this.props.groups[groupKey] &&
          groupNames.push(this.props.groups[groupKey].name);
      });
      const socialLinksString = contacts[key].socialLinks || [];
      const interestsString = contacts[key].interests || [];

      data += `${contacts[key].firstName},${contacts[key].lastName},${
        contacts[key].email
      },${formatDate(contacts[key].created_at)},"${groupNames.join(',')}",${
        contacts[key].phoneNumber
      },${formatDateNoTime(contacts[key].dob)},${
        contacts[key].emailVerified ? t('yes') : t('no')
      },${contacts[key].organisation},${contacts[key].role},${
        contacts[key].allowChat ? t('yes') : t('no')
      },${contacts[key].isActive ? t('yes') : t('no')},${
        contacts[key].showEmailInSearch ? t('yes') : t('no')
      },${
        contacts[key].showProfileInSearch ? t('yes') : t('no')
      },${socialLinksString.join(', ')},"${interestsString.join(
        ', ',
      )}",${customFieldValues.join(',')}\n`;
    });

    return data;
  };

  sortChunks = showAdminVerfiedProfile => {
    const myList = Object.entries(this.props.chunks);
    const sortedItems = [];
    let filteredChunks = {};

    let showVerfiedProfile = null;

    if (this.state.verifiedFilterSelected === 'Verified') {
      showVerfiedProfile = true;
    } else if (this.state.verifiedFilterSelected === 'Not Verified') {
      showVerfiedProfile = false;
    }

    myList
      .filter(item => {
        let verifiedFilterCheck = false;
        let adminVerifiedFilterCheck = false;
        let groupFilterCheck = false;

        //filter order is verified email then group filters
        if (showVerfiedProfile !== null) {
          if (showVerfiedProfile && item[1].emailVerified) {
            verifiedFilterCheck = true;
          }

          if (!showVerfiedProfile && !item[1].emailVerified) {
            verifiedFilterCheck = true;
          }
        } else {
          verifiedFilterCheck = true;
        }

        if (
          showAdminVerfiedProfile !== null &&
          showAdminVerfiedProfile !== undefined
        ) {
          if (item[1].showAdminVerfiedProfile === showAdminVerfiedProfile) {
            adminVerifiedFilterCheck = true;
          }
        } else {
          adminVerifiedFilterCheck = true;
        }

        if (
          this.state.groupsFilterSelected === undefined ||
          this.state.groupsFilterSelected === 'none' ||
          this.state.groupsFilterSelected === ''
        ) {
          groupFilterCheck = true;
        } else if (
          item[1].groups &&
          item[1].groups[this.state.groupsFilterSelected]
        ) {
          groupFilterCheck = true;
        }
        return (
          verifiedFilterCheck && adminVerifiedFilterCheck && groupFilterCheck
        );
      })
      .forEach(item => {
        filteredChunks = {
          ...filteredChunks,
          [item[0]]: {
            ...item[1],
          },
        };
        sortedItems.push(item[0]);
      });

    this.setState({ sortedItems });
    this.props.onFilterData(filteredChunks, sortedItems);
  };

  handleVerifiedMenuClick = e => {
    this.setState({ verfiedMenuSelected: e.currentTarget });
  };

  handleAdminVerifiedMenuClick = () => {
    this.setState({ adminVerifiedFilterModal: true });
  };

  handleVerifiedMenuClose = async e => {
    const { myValue } = e.currentTarget.dataset;
    await this.setState({ selectedFilter: myValue });
    this.setState({ verfiedMenuSelected: null });
    this.sortChunks();
  };

  handleAdminVerifiedMenuClose = async e => {
    const { myValue } = e.currentTarget.dataset;
    await this.setState({ selectedFilter: myValue });
    this.setState({ adminVerfiedMenuSelected: null });
    this.sortChunks();
  };

  sortByDate = () => {
    let filteredChunks = {};
    const sortedItems = [];
    let myList = Object.entries(this.props.filteredChunks);
    if (this.state.dateSortArrowUp) {
      myList = myList.sort((x, y) => x[1].created_at - y[1].created_at);
    } else {
      myList = myList.sort((x, y) => y[1].created_at - x[1].created_at);
    }
    myList.forEach(item => {
      filteredChunks = {
        ...filteredChunks,
        [item[0]]: {
          ...item[1],
        },
      };
      sortedItems.push(item[0]);
    });

    this.setState({
      sortedItems,
      dateSortArrowUp: !this.state.dateSortArrowUp,
      nameSortArrowUp: null,
      emailSortArrowUp: null,
      lastNameSortArrowUp: null,
    });

    this.props.onFilterData(filteredChunks, sortedItems);
  };

  sortByFirstName = () => {
    let filteredChunks = {};
    const sortedItems = [];
    let myList = Object.entries(this.props.filteredChunks);
    if (this.state.nameSortArrowUp) {
      myList = myList.sort(
        (x, y) => x[1].firstName.toLowerCase() - y[1].firstName.toLowerCase(),
      );
    } else {
      myList = myList.sort(
        (x, y) => y[1].firstName.toLowerCase() - x[1].firstName.toLowerCase(),
      );
    }
    myList.forEach(item => {
      filteredChunks = {
        ...filteredChunks,
        [item[0]]: {
          ...item[1],
        },
      };
      sortedItems.push(item[0]);
    });

    this.setState({
      sortedItems,
      nameSortArrowUp: !this.state.nameSortArrowUp,
      dateSortArrowUp: null,
      emailSortArrowUp: null,
      lastNameSortArrowUp: null,
    });

    this.props.onFilterData(filteredChunks, sortedItems);
  };

  sortByLastName = () => {
    let filteredChunks = {};
    const sortedItems = [];
    let myList = Object.entries(this.props.filteredChunks);
    if (this.state.lastNameSortArrowUp) {
      myList = myList.sort((x, y) => {
        return x[1].lastName.toLowerCase() - y[1].lastName.toLowerCase();
      });
    } else {
      myList = myList.sort((x, y) => {
        return y[1].lastName.toLowerCase() - x[1].lastName.toLowerCase();
      });
    }

    myList.forEach(item => {
      filteredChunks = {
        ...filteredChunks,
        [item[0]]: {
          ...item[1],
        },
      };
      sortedItems.push(item[0]);
    });

    this.setState({
      sortedItems,
      lastNameSortArrowUp: !this.state.lastNameSortArrowUp,
      dateSortArrowUp: null,
      emailSortArrowUp: null,
      nameSortArrowUp: null,
    });

    this.props.onFilterData(filteredChunks, sortedItems);
  };

  sortByEmail = () => {
    let filteredChunks = {};
    const sortedItems = [];
    let myList = Object.entries(this.props.filteredChunks);
    if (this.state.emailSortArrowUp) {
      myList = myList.sort(
        (x, y) => x[1].email.toLowerCase() - y[1].email.toLowerCase(),
      );
    } else {
      myList = myList.sort(
        (x, y) => y[1].email.toLowerCase() - x[1].email.toLowerCase(),
      );
    }
    myList.forEach(item => {
      filteredChunks = {
        ...filteredChunks,
        [item[0]]: {
          ...item[1],
        },
      };
      sortedItems.push(item[0]);
    });

    this.setState({
      sortedItems,
      emailSortArrowUp: !this.state.emailSortArrowUp,
      dateSortArrowUp: null,
      nameSortArrowUp: null,
      lastNameSortArrowUp: null,
    });

    this.props.onFilterData(filteredChunks, sortedItems);
  };

  generateTableColumns = () => {
    const {
      onDeleteSelected,
      openSingleUserDialog,
      chunks,
      items,
      enabled,
      t,
    } = this.props;

    const suffixClass = items.length < 4 ? 'fix-left' : 'selected';

    const columns = [
      {
        id: 'firstName',
        header: (
          <>
            <Trans>First Name</Trans>{' '}
            {this.state.nameSortArrowUp ? (
              <ArrowDropUpIcon
                onClick={() => this.sortByFirstName()}
                className="arrowUpDown"
                style={{
                  position: 'absolute',
                  top: -5,
                  color: this.state.nameSortArrowUp !== null && '#261ed5',
                }}
              />
            ) : (
              <ArrowDropDownIcon
                onClick={() => this.sortByFirstName()}
                className="arrowUpDown"
                style={{
                  position: 'absolute',
                  top: -5,
                  color: this.state.nameSortArrowUp !== null && '#261ed5',
                }}
              />
            )}
          </>
        ),
        classNames: 'col-xs-1',
        containerElement: ({ value }) => <div>{value}</div>,
      },
      {
        id: 'lastName',
        header: (
          <>
            Last Name{' '}
            {this.state.lastNameSortArrowUp ? (
              <ArrowDropUpIcon
                onClick={() => this.sortByLastName()}
                className="arrowUpDown"
                style={{
                  position: 'absolute',
                  top: -5,
                  color: this.state.lastNameSortArrowUp !== null && '#261ed5',
                }}
              />
            ) : (
              <ArrowDropDownIcon
                onClick={() => this.sortByLastName()}
                className="arrowUpDown"
                style={{
                  position: 'absolute',
                  top: -5,
                  color: this.state.lastNameSortArrowUp !== null && '#261ed5',
                }}
              />
            )}
          </>
        ),
        classNames: 'col-xs-1',
        containerElement: ({ value }) => <div>{value}</div>,
      },
      {
        id: 'email',
        header: (
          <>
            <Trans>Email</Trans>{' '}
            {this.state.emailSortArrowUp ? (
              <ArrowDropUpIcon
                onClick={() => this.sortByEmail()}
                className="arrowUpDown"
                style={{
                  position: 'absolute',
                  top: -5,
                  color: this.state.emailSortArrowUp !== null && '#261ed5',
                }}
              />
            ) : (
              <ArrowDropDownIcon
                onClick={() => this.sortByEmail()}
                className="arrowUpDown"
                style={{
                  position: 'absolute',
                  top: -5,
                  color: this.state.emailSortArrowUp !== null && '#261ed5',
                }}
              />
            )}
          </>
        ),
        classNames: enabled ? 'col-xs-2' : 'col-xs-3',
        containerElement: ({ value }) => <div>{value}</div>,
      },
      enabled && {
        id: 'emailVerified',
        header: t('Verified'),
        classNames: 'col-xs-1 email-verified',
        containerElement: ({ value }) => (
          <div>{value ? <Done /> : <Clear />}</div>
        ),
      },
      {
        id: 'created_at',
        classNames: 'created-at',
        header: (
          <>
            <Trans>Date Created</Trans>
            {this.state.dateSortArrowUp ? (
              <ArrowDropUpIcon
                onClick={() => this.sortByDate()}
                className="arrowUpDown"
                style={{
                  position: 'absolute',
                  top: -5,
                  color: this.state.dateSortArrowUp !== null && '#261ed5',
                }}
              />
            ) : (
              <ArrowDropDownIcon
                onClick={() => this.sortByDate()}
                className="arrowUpDown"
                style={{
                  position: 'absolute',
                  top: -5,
                  color: this.state.dateSortArrowUp !== null && '#261ed5',
                }}
              />
            )}
          </>
        ),
        classNames:
          this.props.account === '-MFen9if6b0eo4pH4v34' ||
          SCOTTISH_CURLING_FEATURES_ENABLED_APPS.indexOf(
            this.props.match.params.appname,
          ) > -1
            ? 'col-xs-1'
            : 'col-xs-2',
        containerElement: ({ value }) => (
          <span>{value && moment(value).format('DD/MM/YYYY')}</span>
        ),
      },

      {
        id: 'groups',
        header: t('Groups'),
        classNames:
          this.props.account === '-MFen9if6b0eo4pH4v34' ||
          SCOTTISH_CURLING_FEATURES_ENABLED_APPS.indexOf(
            this.props.match.params.appname,
          ) > -1
            ? 'col-xs-3'
            : 'col-xs-4',
        containerElement: ({ value, rowId }) =>
          this.getGroupChips(value, rowId),
      },
      {
        id: 'selected',
        header: this.getSelectAllCheckBox(),
        classNames: `col-xs-1 ${suffixClass}`,
        containerElement: ({ value, rowId }) => (
          <>
            <TableCheckbox
              value={value}
              onSelect={() => this.props.onSelect(rowId)}
            />
            <ActionMenu
              isItemOption
              selected={[rowId]}
              text={t('user')}
              onEditSelected={() => this.openSingleUserDialog(rowId)}
              onDeleteSelected={() => onDeleteSelected([rowId], chunks)}
              onDownloadSelected={() => {
                downloadCSV(this.getCsvData([rowId], chunks), 'users');
              }}
              onMoveSelected={() => this.openSingleUserDialog(rowId)}
            />
          </>
        ),
      },
    ];
    const relation = {
      id: 'companyRole',
      header: t('Relation'),
      classNames: 'col-xs-1',
      containerElement: ({ value }) => <div>{value}</div>,
    };
    const adminVerfied = {
      id: 'adminVerified',
      header: (
        <div className="adminVerified-header">
          <Trans>Admin Verified</Trans>
          <IconButton
            aria-label={t('more')}
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={this.handleAdminVerifiedMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      ),
      classNames: 'col-xs-1 email-verified',
      containerElement: ({ value }) => (
        <div>
          {value && value === true ? (
            <VerifiedUser style={{ color: 'green' }} />
          ) : (
            <CancelIcon style={{ color: 'red' }} />
          )}
        </div>
      ),
    };
    if (this.props.account === '-MFen9if6b0eo4pH4v34')
      columns.splice(4, 0, relation);
    if (
      SCOTTISH_CURLING_FEATURES_ENABLED_APPS.indexOf(
        this.props.match.params.appname,
      ) > -1
    )
      columns.splice(5, 0, adminVerfied);

    return columns;
  };

  openSingleUserDialog = rowId => {
    if (
      this.props.selected.indexOf(rowId) >= 0 &&
      this.props.selected.length === 1
    ) {
      this.props.onOpenUserGroupDialog(this.props.selected);
    } else if (this.props.selected.length === 0) {
      this.props.onSelect(rowId);
      this.props.onOpenUserGroupDialog(this.props.selected);
    } else if (
      this.props.selected.length > 0 ||
      this.props.selected.indexOf(rowId) >= 0
    ) {
      this.props.onDeselectAllUsers();
      this.props.onSelect(rowId);
      this.props.onOpenUserGroupDialog(this.props.selected);
    }
  };

  handleGroupFilterChange = event => {
    this.setState(
      { groupsFilterSelected: event ? event.target.value : 'none' },
      () => this.sortChunks(),
    );
  };

  handleVerifiedFilterChange = event => {
    this.setState(
      { verifiedFilterSelected: event ? event.target.value : 'All' },
      () => {
        this.sortChunks();
      },
    );
  };

  handleAdminVerifedFilterChange = event => {
    this.setState({ adminVerifiedFilterModal: false });
    this.sortChunks(event.target.value);
  };

  resetFilters = () => {
    this.handleVerifiedFilterChange();
    this.handleGroupFilterChange();
  };

  getPlaceholderText = () => {
    const {
      sortedItems,
      groupsFilterSelected,
      verifiedFilterSelected,
    } = this.state;

    const { filter, filteredChunks } = this.props;

    if (
      sortedItems.length === 0 &&
      (groupsFilterSelected !== 'none' || verifiedFilterSelected !== 'All')
    ) {
      return (
        <React.Fragment>
          <Trans>Looks like there are no such users yet.</Trans>
          <br />
          <a className="customModalLink" onClick={() => this.resetFilters()}>
            <Trans>Clear all filters.</Trans>
          </a>
        </React.Fragment>
      );
    } else if (filter.length > 0 && Object.keys(filteredChunks).length === 0) {
      return (
        <React.Fragment>
          <Trans>Looks like there are no such users yet.</Trans>
        </React.Fragment>
      );
    }

    return (
      <>
        <Trans>Looks like no users have signed up yet</Trans>
      </>
    );
  };

  render() {
    const {
      pending,
      filteredChunks,
      onFilterUserData,
      selected,
      onSelectAllUsers,
      onDeselectAllUsers,
      onOpenUserGroupDialog,
      userGroupsDialogOpen,
      toggleSelectedGroup,
      error,
      getUsers,
      getPageCountAnalytics,
      pageAnalyticsDataPending,
      pageAnalyticsData,
      verficationMessage,
      t,
    } = this.props;

    const infoDialog = (
      <Dialog
        open={this.state.enableInfoDialog}
        onClose={this.toggleInfoDialog}
      >
        <DialogTitle>
          <Trans>How to use Users Feature</Trans>
        </DialogTitle>
        <DialogContent>
          <p>
            <Trans>
              In this section you can see all Users who have registered on your
              app.
            </Trans>
          </p>
          <p>
            <Trans>
              Under Groups/Subscriptions, you can see which Group or/and
              Subscription Plan the users are part of. You can click on a user
              to view their full user profile information.
            </Trans>
          </p>
          <p>
            <Trans>
              All user data can be downloaded in a CSV file by clicking on the
              checkbox at the top right corner of the page.
            </Trans>
          </p>
        </DialogContent>
      </Dialog>
    );

    const adminVerfiedDialog = (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={this.state.adminVerifiedFilterModal}
        onClose={() => this.setState({ adminVerifiedFilterModal: false })}
      >
        <DialogTitle>
          <Trans>Select From Below</Trans>
        </DialogTitle>
        <DialogContent>
          <form className="">
            <FormControl className="groupFilterForm">
              <InputLabel htmlFor="select-grp-filter">
                <Trans>Select</Trans>
              </InputLabel>
              <Select
                value={this.state.adminVerifiedFilterSelected}
                onChange={this.handleAdminVerifedFilterChange}
                input={<Input id="select-grp-filter" />}
              >
                <option aria-label="verified" value className="groupFilterList">
                  <Trans>Verifed by Admin</Trans>
                </option>
                <option
                  aria-label="notVerified"
                  value={false}
                  className="groupFilterList"
                >
                  <Trans>Not verifed by Admin</Trans>
                </option>
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.setState({ adminVerifiedFilterModal: false })}
            color="primary"
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            onClick={() => this.setState({ adminVerifiedFilterModal: false })}
            color="primary"
          >
            <Trans>Ok</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    );

    const groupFilterDialog = (
      <Dialog
        disableEscapeKeyDown
        open={this.state.groupFilterModal}
        onClose={() => this.setState({ groupFilterModal: false })}
      >
        <DialogTitle>
          <Trans>Select a group from below</Trans>
        </DialogTitle>
        <DialogContent>
          <FormControl className="groupFilterForm">
            <Select
              id="select-group"
              value={this.state.groupsFilterSelected}
              onChange={this.handleChange}
              variant="outlined"
            >
              <MenuItem value="none" className="groupFilterList">
                <Trans>All</Trans>
              </MenuItem>

              {Object.keys(this.props.groups).map(key => (
                <MenuItem value={key} className="groupFilterList">
                  {this.props.groups[key].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ groupFilterModal: false });
            }}
            color="primary"
          >
            <Trans>Close</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    );

    const body = error ? (
      <TryAgain
        message={t('Oops, something went wrong...')}
        onRetry={getUsers}
      />
    ) : (
      <ResultsTable
        items={this.state.sortedItems}
        chunks={filteredChunks}
        pending={pending}
        error={Object.keys(filteredChunks).length === 0}
        columns={this.generateTableColumns()}
        emptyMessage={this.getPlaceholderText()}
        onRowTouchTap={rowId => this.openSingleUserDialog(rowId)}
      />
    );

    return (
      <div
        style={{ height: 'calc(100% - 166px)' }}
        className="notification-panel users-auth"
      >
        <Header
          {...this.props}
          title={this.getTitle(selected, filteredChunks)}
          onChange={onFilterUserData}
          onSelectAllResponses={onSelectAllUsers}
          onDeselectAllResponses={onDeselectAllUsers}
          filteredColumns={['emailVerified', 'groups']}
          groupsFilterSelected={this.state.groupsFilterSelected}
          verifiedFilterSelected={this.state.verifiedFilterSelected}
          handleGroupFilterChange={this.handleGroupFilterChange}
          handleVerifiedFilterChange={this.handleVerifiedFilterChange}
        />

        {body}
        {infoDialog}

        <UserGroupsDialog
          {...this.props}
          open={userGroupsDialogOpen}
          toggleSelected={toggleSelectedGroup}
          getPageCountAnalytics={getPageCountAnalytics}
          pageAnalyticsDataPending={pageAnalyticsDataPending}
          pageAnalyticsData={pageAnalyticsData}
        />

        {groupFilterDialog}
        {adminVerfiedDialog}
        {verficationMessage && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.showSnackBar}
            onClose={this.setShowSnackBar}
            message={verficationMessage}
            key={'notification'}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(Users);
