import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'material-ui/DatePicker';
import MenuItem from 'material-ui/MenuItem';
import Grid from '@material-ui/core/Grid';
import Toggle from 'material-ui/Toggle';
import { Trans, useTranslation } from 'react-i18next';

import TextField from '../../../../app/components/textField';
import SelectField from '../../../../app/components/selectField';
import CircularValidation from '../../../../app/components/circularValidation';
import { objectToArray, capitaliseString } from '../../../../utils';
import themeStyles from '../../../../_export.scss';

import './styles.css';

const styles = {
  underline: {
    borderColor: 'rgba(0, 0, 0, 0.42)',
    borderWidth: '1px',
  },
  underlineFocus: {
    borderColor: themeStyles.primaryColor,
    borderWidth: '1px',
  },
  hintText: {
    color: '#333!important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
  },
  hintTextFocus: {
    color: themeStyles.primaryColor,
  },
};

const Form = ({ fields, onFieldChange, fieldsData, isRecurringEvent }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isRecurringEvent) {
      const allFields = objectToArray(fields);
      const dateRangeField = allFields.find(
        item => item.value.type === 'DateRange',
      );

      if (dateRangeField) {
        const { id, value } = dateRangeField;
        onFieldChange(
          value.isRequired,
          value.type,
          id,
          fieldsData[id].value[0]
            ? fieldsData[id].value[0]
            : new Date().getTime(),
          1,
        );
      }
    }
  }, [isRecurringEvent]);

  const processField = obj => {
    const fieldID = obj.id;
    const data = obj.value;
    switch (capitaliseString(data.type)) {
      case 'Date': {
        return (
          <div
            className="date-field-wrapper"
            key={`field-container-${fieldID}`}
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label
              className={
                fieldsData && fieldsData[fieldID] && fieldsData[fieldID].value
                  ? 'with-data'
                  : 'without-data'
              }
            >{`${data.label} ${data.isRequired ? '*' : ''}`}</label>
            <DatePicker
              style={{ width: '100%' }}
              name={fieldID}
              key={`field${fieldID}`}
              className="date-picker"
              value={
                fieldsData && fieldsData[fieldID] && fieldsData[fieldID].value
                  ? new Date(fieldsData[fieldID].value)
                  : null
              }
              onChange={(e, timestamp) => {
                onFieldChange(
                  data.isRequired,
                  data.type,
                  fieldID,
                  timestamp.getTime(),
                );
              }}
            />
          </div>
        );
      }
      case 'DataRange':
      case 'Daterange': {
        return (
          <div
            className="date-field-wrapper"
            key={`field-container-${fieldID}`}
          >
            {fieldsData && fieldsData[fieldID] && fieldsData[fieldID].value && (
              // eslint-disable-next-line jsx-a11y/label-has-for
              <label className="with-data">{`${data.label} ${
                data.isRequired ? '*' : ''
              }`}</label>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DatePicker
                  underlineStyle={styles.underline}
                  underlineFocusStyle={styles.underlineFocus}
                  floatingLabelStyle={styles.hintText}
                  floatingLabelFocusStyle={styles.hintTextFocus}
                  style={{ width: '100%' }}
                  name={fieldID}
                  key={`field${fieldID}`}
                  className={
                    fieldsData &&
                    fieldsData[fieldID] &&
                    fieldsData[fieldID].value
                      ? 'date-picker filled'
                      : 'date-picker'
                  }
                  hintText={t('From Date')}
                  maxDate={
                    fieldsData &&
                    fieldsData[fieldID] &&
                    fieldsData[fieldID].value &&
                    !isRecurringEvent
                      ? new Date(fieldsData[fieldID].value[1])
                      : null
                  }
                  value={
                    fieldsData &&
                    fieldsData[fieldID] &&
                    fieldsData[fieldID].value
                      ? new Date(fieldsData[fieldID].value[0])
                      : null
                  }
                  onChange={(e, timestamp) => {
                    onFieldChange(
                      data.isRequired,
                      data.type,
                      fieldID,
                      timestamp.getTime(),
                      0,
                    );

                    if (isRecurringEvent) {
                      onFieldChange(
                        data.isRequired,
                        data.type,
                        fieldID,
                        timestamp.getTime(),
                        1,
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  style={{ width: '100%' }}
                  underlineStyle={styles.underline}
                  underlineFocusStyle={styles.underlineFocus}
                  floatingLabelStyle={styles.hintText}
                  floatingLabelFocusStyle={styles.hintTextFocus}
                  name={fieldID}
                  key={`field${fieldID}`}
                  className={
                    fieldsData &&
                    fieldsData[fieldID] &&
                    fieldsData[fieldID].value
                      ? 'date-picker filled'
                      : 'date-picker'
                  }
                  minDate={
                    fieldsData &&
                    fieldsData[fieldID] &&
                    fieldsData[fieldID].value
                      ? new Date(fieldsData[fieldID].value[0])
                      : null
                  }
                  value={
                    fieldsData &&
                    fieldsData[fieldID] &&
                    fieldsData[fieldID].value
                      ? new Date(fieldsData[fieldID].value[1])
                      : null
                  }
                  hintText={t('To Date')}
                  onChange={(e, timestamp) => {
                    onFieldChange(
                      data.isRequired,
                      data.type,
                      fieldID,
                      timestamp.getTime(),
                      1,
                    );
                  }}
                  disabled={isRecurringEvent}
                />
              </Grid>
            </Grid>
          </div>
        );
      }
      case 'Text':
      case 'Location':
      case 'Email':
      case 'Number': {
        return (
          <div>
            <TextField
              multiLine
              type={
                data.type === 'Number'
                  ? 'number'
                  : data.type === 'Email'
                  ? 'email'
                  : 'text'
              }
              key={`field${fieldID}`}
              id={`field${fieldID}`}
              placeholder={`${data.label} ${data.isRequired ? '*' : ''}`}
              value={
                fieldsData && fieldsData[fieldID]
                  ? fieldsData[fieldID].value
                  : ''
              }
              onChange={value =>
                onFieldChange(data.isRequired, data.type, fieldID, value)
              }
              maxLength={
                data.label === 'Event Name' || data.label === 'Course Name'
                  ? 75
                  : data.label === 'Description'
                  ? 1000
                  : null
              }
              isValid={
                fieldsData &&
                fieldsData[fieldID] &&
                fieldsData[fieldID].value.length < 75
              }
              rightInputAction={
                <CircularValidation
                  defaultMsg={`${data.label} ${t('is required')}`}
                  errorMsg={`${data.label} ${t('is required')}`}
                  showDefault={data.isRequired}
                />
              }
            />

            {(data.label === 'Event Name' || data.label === 'Course Name') &&
              fieldsData &&
              fieldsData[fieldID] &&
              fieldsData[fieldID].value &&
              fieldsData[fieldID].value.length > 75 && (
                <span className="hintText">
                  {data.label === 'Event Name' ? t('Event') : t('Course')}{' '}
                  <Trans>Name can not exceed 75 characters.</Trans>
                </span>
              )}
            {data.label === 'Description' &&
              fieldsData &&
              fieldsData[fieldID] &&
              fieldsData[fieldID].value &&
              fieldsData[fieldID].value.length > 1000 && (
                <span className="hintText">
                  <Trans>Description can not exceed 1000 characters.</Trans>
                </span>
              )}
            {data.label === 'Description' &&
              fieldsData &&
              fieldsData[fieldID] &&
              fieldsData[fieldID].value &&
              fieldsData[fieldID].value.length > 1000 && (
                <span className="hintText">
                  Description can not exceed 1000 characters.
                </span>
              )}
          </div>
        );
      }
      case 'Dropdown': {
        var options = {};
        if (typeof data.options === 'string') {
          data.options.split(',').forEach(option => {
            options[option.trim()] = option.trim();
          });
        } else {
          options = data.options;
        }

        return (
          <SelectField
            key={`field${fieldID}`}
            className="item-location custom-select-field"
            options={objectToArray(options).map(option => (
              <MenuItem
                key={option.id}
                value={option.id}
                primaryText={option.value}
              />
            ))}
            label={`${data.label} ${data.isRequired ? '*' : ''}`}
            value={
              fieldsData && fieldsData[fieldID] ? fieldsData[fieldID].value : ''
            }
            onChange={(i, payload) => {
              onFieldChange(data.isRequired, data.type, fieldID, payload);
            }}
            autoWidth
            rightInputAction={
              <CircularValidation
                defaultMsg={`${data.label} ${t('is required')}`}
                errorMsg={`${data.label} ${t('is required')}`}
                showDefault={data.isRequired}
              />
            }
          />
        );
      }
      case 'Toggle': {
        return (
          <Toggle
            key={`field${fieldID}`}
            className="small-toggle"
            onToggle={(v, b) =>
              onFieldChange(data.isRequired, data.type, fieldID, b)
            }
            toggled={
              fieldsData && fieldsData[fieldID]
                ? fieldsData[fieldID].value
                : false
            }
            label={data.label}
          />
        );
      }
      default: {
        return false;
      }
    }
  };

  const formFields = objectToArray(fields).map((obj, index) =>
    processField(obj, index),
  );

  return (
    <div className="add-field" style={{ minHeight: '100px' }}>
      {formFields}
    </div>
  );
};

Form.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  fieldsData: PropTypes.object.isRequired,
  isRecurringEvent: PropTypes.bool,
};

Form.defaultProps = {
  error: null,
  fieldsData: {},
  fields: {},
  deleteFile: null,
  isRecurringEvent: false,
};

export default Form;
