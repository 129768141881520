import _pickBy from 'lodash/pickBy';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import {
  SELECT_VENDOR_PRODUCT,
  DESELECT_VENDOR_PRODUCT,
} from './types';

const initialState = {
  pending: false,
  selectedProduct: null,
  productDialogOpen: false,
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_VENDOR_PRODUCT: {
      const selectedProduct = action.value;
      return {
        ...state,
        selectedProduct,
      };
    }

    case DESELECT_VENDOR_PRODUCT: {
      return {
        ...state,
        selectedProduct:null,
      };
    }

    default:
      return state;
  }
};

export default products;
