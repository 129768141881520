import React from 'react';

const ZoomIcon = () => (
  <svg
    className="icon zoomicon"
    viewBox="0 0 1080.000000 1080.000000"
  >
    <title>Zoom</title>
    <g
      transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M3705 8959 c-456 -48 -891 -254 -1230 -581 -327 -314 -528 -691 -617
 -1153 l-23 -120 0 -1705 0 -1705 28 -143 c112 -581 436 -1066 929 -1389 196
 -129 482 -247 705 -292 232 -47 187 -46 1913 -46 1528 0 1643 1 1750 18 365
 57 691 193 976 405 107 80 327 298 406 402 252 335 398 724 429 1146 7 100 9
 659 7 1704 -4 1431 -5 1563 -22 1660 -41 244 -93 412 -191 617 -108 226 -229
 396 -411 575 -322 317 -679 505 -1124 590 l-115 22 -1665 2 c-916 1 -1701 -2
 -1745 -7z m3395 -353 c280 -36 553 -144 794 -315 94 -66 263 -225 339 -319
 221 -272 355 -606 386 -968 15 -162 15 -3078 1 -3229 -58 -615 -428 -1154
 -975 -1420 -191 -93 -391 -150 -617 -174 -133 -15 -3117 -15 -3258 0 -234 23
 -412 76 -630 186 -484 243 -822 690 -932 1235 l-23 113 0 1690 0 1690 23 105
 c86 393 272 719 556 972 275 247 622 402 986 441 19 2 755 5 1635 5 1343 2
 1619 0 1715 -12z"
      />
      <path
        d="M7355 6796 c-37 -17 -783 -477 -962 -593 -21 -14 -23 -12 -57 61
 -104 225 -298 394 -538 468 l-73 23 -1201 3 c-1333 3 -1246 7 -1301 -65 l-28
 -36 0 -956 c0 -1066 -4 -1007 70 -1167 101 -221 302 -400 520 -462 147 -43
 172 -43 1371 -40 l1150 3 42 27 c75 50 77 56 80 381 2 158 6 287 9 287 3 0
 200 -121 438 -270 238 -148 452 -279 476 -292 60 -32 183 -32 250 -1 66 30
 124 86 156 151 l28 57 0 1110 0 1110 -25 50 c-34 69 -99 129 -166 154 -77 29
 -173 28 -239 -3z m81 -2263 c-2 -3 -230 137 -505 309 l-501 313 2 329 3 329
 500 313 500 313 3 -951 c1 -523 0 -953 -2 -955z m-1725 1857 c70 -21 168 -76
 212 -118 45 -43 106 -142 130 -212 22 -65 22 -69 25 -872 l3 -808 -1038 0
 c-1139 0 -1112 -1 -1234 61 -120 60 -228 198 -258 329 -7 29 -11 332 -11 843
 l0 797 1054 0 c1019 0 1056 -1 1117 -20z"
      />
    </g>
  </svg>
);

export default ZoomIcon;
