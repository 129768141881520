import { connect } from 'react-redux';
import queryString from 'query-string';
import Appointedd from './Appointedd';
import { handleSrcChange } from './actions';

const mapStateToProps = state => ({
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});
export default connect(mapStateToProps, (dispatch, ownProps) => ({
  onChange: value =>
    dispatch(
      handleSrcChange(
        ownProps.match.params.appname,
        queryString.parse(ownProps.location.search).component,
        value,
      ),
    ),
}))(Appointedd);
