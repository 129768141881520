/* eslint no-underscore-dangle: "off" */
import { plans as _plans } from '../plans';

/**
 * getRouteComponents - Returns an array containing all the components within the
 * provided route object.
 *
 * @param {object} route - Route object to be queried.
 */
export const getRouteComponents = route => {
  if (!route) {
    return [];
  }

  return (
    route.components ||
    (route.tabs &&
      route.tabs.reduce((acc, cur) => acc.concat(cur.components), [])) ||
    []
  );
};

/**
 * arrayHasComponent - Returns true/false depending on whether the provided array
 * contains a component of the requested type.
 *
 * @param {array} array - Array object to be queried.
 * @param {string} component - Component type to search for.
 */
export const arrayHasComponent = (array, component) => {
  if (!Array.isArray(array)) {
    return false;
  }

  return !!array.reduce(
    (acc, cur) => cur.type && acc + +(cur.type === component),
    0,
  );
};

/**
 * routeHasComponent - Returns true/false depending on whether the provided route
 * object contains a component of the requested type.
 *
 * @param {object} route - Route object to be queried.
 * @param {string} component - Component type to search for.
 */
export const routeHasComponent = (route, component) => {
  if (!route) {
    return false;
  }

  return (
    (route.components && arrayHasComponent(route.components, component)) ||
    (route.tabs &&
      !!route.tabs.reduce(
        (acc, cur) =>
          cur.components && acc + +arrayHasComponent(cur.components, component),
        0,
      )) ||
    false
  );
};

/**
 * appHasComponent - Returns true/false depending on whether the provided app
 * structure array contains a component of the requested type.
 *
 * @param {object} app - App to be queried.
 * @param {string} component - Component type to search for.
 */
export const appHasComponent = (app, component) => {
  if (!Array.isArray(app.routes)) {
    return false;
  }

  return !!app.routes.reduce(
    (acc, cur) => acc + +routeHasComponent(cur, component),
    0,
  );
};

/**
 * routeHasUnavailableComponent - Returns true/false depending on whether the
 * provided route contains a component which is unavailable on the user's current
 * plan.
 *
 * @param {object} plans - All available plans.
 * @param {string} plan - Selected plan for the current app.
 * @param {object} route - Route object to be queried.
 */
const _routeHasUnavailableComponent = plans => plan => (
  route,
  addons,
  specialAddonWithoutSubscription,
  isAppsumoUser,
) => {
  if (!plans || !plan || !route || !plans[plan] || !addons) {
    return false;
  }

  const availableComponents = plans[plan].components;
  const routeComponents = getRouteComponents(route);

  return !!routeComponents.reduce((acc, cur) => {
    let lockedComponent;

    if (isAppsumoUser) {
      if (specialAddonWithoutSubscription.length === 0) lockedComponent = false;
      else
        lockedComponent = specialAddonWithoutSubscription.some(
          item => item.component === cur.type,
        );
    } else {
      lockedComponent =
        !availableComponents.includes(cur.type) && !addons.includes(cur.type);
    }

    return acc + +lockedComponent;
  }, 0);
};

export const routeHasUnavailableComponent = _routeHasUnavailableComponent(
  _plans,
);
