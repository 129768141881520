import {
  UPDATE_PAYPAL_SETTING,
  HYDRATE_APP_PAYPAL_DATA,
  REPLACE_APP_NAME_DATA,
  FIELD_VALIDATION_CHECK,
  RECEIVE_META_DATA,
} from './actions';
import { SAVE_APP_NAME } from '../../../actions';

const paypal = (state = {}, action) => {
  switch (action.type) {
    case HYDRATE_APP_PAYPAL_DATA:
      return {
        ...state,
        [action.appname]: {
          paypal: {
            ...action.data,
          },
        },
      };

    case 'RECEIVE_PAYPAL_DATA':
      return {
        ...state,
        [action.appname]: {
          paypal: action.paypal,
        },
      };

    case UPDATE_PAYPAL_SETTING:
      console.log({
        ...state,
        [action.appname]: {
          ...state[action.appname],
          paypal: {
            ...state[action.appname].paypal,
            ...action.paypal,
          },
          modified: true,
        },
      });
      return {
        ...state,
        [action.appname]: {
          ...state[action.appname],
          paypal: {
            ...state[action.appname].paypal,
            ...action.paypal,
          },
          modified: true,
        },
      };

    case RECEIVE_META_DATA: {
      return {
        ...state,
        [action.appname]: {
          ...state[action.appname],
          meta: {
            ...action.data,
          },
        },
      };
    }

    case REPLACE_APP_NAME_DATA: {
      return {
        [action.appname]: {
          ...state[action.previusAppname],
          modified: false,
        },
      };
    }

    case SAVE_APP_NAME:
      return {
        ...state,
        [action.appname]: {
          ...state[action.appname],
          modified: false,
        },
      };

    default:
      return state;
  }
};

export default paypal;
