import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EventsAttendees from './Event-Attendees';
import { getEvents, selectMemberBody } from '../events/actions';
import {
  setSelectedEvent,
  getEventAttendees,
  filterEventData,
  selectAllEventAttendees,
  deselectAllEventAttendees,
  selectAttendee,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.staff.eventsAttendees.listAttendees,
  eventsList: state.staff.events.list.filteredChunks,
  eventOrganizer: state.staff.events.list.memberbody,
  profile: state.account.profile,
  memberBodies: state.auth.memberBodies.data.chunks,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectMemberBody: key => dispatch(selectMemberBody(key)),
  setSelectedEvent: eventId => dispatch(setSelectedEvent(eventId)),
  getEvents: () => dispatch(getEvents(ownProps.match.params.appname)),
  getEventAttendees: () =>
    dispatch(getEventAttendees(ownProps.match.params.appname)),
  filterEventData: filter => dispatch(filterEventData(filter)),
  onSelect: key => dispatch(selectAttendee(key)),
  onSelectAllEventAttendees: () => dispatch(selectAllEventAttendees()),
  onDeselectAllEventAttendees: () => dispatch(deselectAllEventAttendees()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventsAttendees),
);
