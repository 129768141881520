import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';

import { CircularProgress } from '../../../../../../../../app/components/progress';
import { RaisedButton } from '../../../../../../../../app/components/buttons';

import './styles.css';
import themeStyles from '../../../../../../../../_export.scss';
const styles = {
  buttonRoot: {
    margin: '12px',
    width: '200px',
  },
  buttonFlat: {
    margin: '12px',
    width: '150px',
    boxShadow: 'none',
    border: `1px solid ${themeStyles.mainGrey}`,
  },
  CancelLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.secondaryTextColor,
    textTransform: 'capitalize',
  },
  NextLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.primaryTextColor,
    textTransform: 'capitalize',
  },
};

class CreateWithSteps extends Component {
  static propTypes = {
    title: PropTypes.string,
    pending: PropTypes.bool,
    error: PropTypes.string,
    steps: PropTypes.arrayOf(PropTypes.object).isRequired,
    stepContent: PropTypes.arrayOf(PropTypes.node).isRequired,
    submitLabel: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    title: null,
    pending: false,
    error: null,
    steps: [],
    onCancel: null,
  };

  state = {
    stepIndex: 0,
  };

  getCurrentStepValidity = () => {
    const { steps } = this.props;
    const { stepIndex } = this.state;

    if (!steps.length) return true;

    return steps[stepIndex].isValid;
  };

  handleNext = () => {
    const { stepIndex } = this.state;
    const { steps } = this.props;

    if (steps.length === 0 || stepIndex === steps.length - 1) {
      this.props.onSubmit();
    } else {
      this.setState({
        stepIndex: stepIndex + 1,
      });
    }
  };

  handlePrev = () => {
    const { stepIndex } = this.state;

    if (stepIndex > 0) {
      this.setState({
        stepIndex: stepIndex - 1,
      });
    } else {
      this.props.onCancel();
    }
  };

  renderCurrentStepContent = () => {
    const { stepIndex } = this.state;
    const { stepContent } = this.props;

    return stepContent[stepIndex];
  };

  // renderSteps = () => {
  //   const { steps } = this.props;

  //   return steps.map(step => (
  //     <Step key={step.title}>
  //       <StepLabel>{step.title}</StepLabel>
  //     </Step>
  //   ));
  // };

  render() {
    const {
      title,
      steps,
      pending,
      error,
      submitLabel,
      onCancel,
      className,
      t,
    } = this.props;

    const { stepIndex } = this.state;
    const content = this.renderCurrentStepContent();
    // const stepLabels = steps && this.renderSteps();
    const currentStepIsValid = this.getCurrentStepValidity();

    const pendingIndicator = pending ? (
      <CircularProgress size={20} thickness={3} />
    ) : null;
    const displayError = error ? <p>{error}</p> : null;

    // const stepper = steps ? (
    //   <Stepper style={styles.stepper} activeStep={stepIndex}>
    //     {stepLabels}
    //   </Stepper>
    // ) : null;

    const buttonCancel =
      !pending && onCancel ? (
        <RaisedButton
          label={stepIndex === 0 ? t('Cancel') : t('Back')}
          onClick={this.handlePrev}
          disabled={pending}
          backgroundColor={themeStyles.mainWhite}
          disabledBackgroundColor={themeStyles.mainGrey}
          style={styles.buttonFlat}
          labelStyle={styles.CancelLabelStyle}
        />
      ) : null;

    const buttonContinue = !pending ? (
      <RaisedButton
        label={
          steps.length === 0 || stepIndex === steps.length - 1
            ? submitLabel
            : t('Next')
        }
        onClick={this.handleNext}
        disabled={pending || !currentStepIsValid}
        backgroundColor={themeStyles.secondaryTextColor}
        disabledBackgroundColor={themeStyles.primaryColorLight}
        style={styles.buttonRoot}
        labelStyle={styles.NextLabelStyle}
      />
    ) : null;

    return (
      <div>
        <div className={`create-content ${className}`}>
          <h2>{title}</h2>
          <p className="subtext">{steps[stepIndex].subtext}</p>
          <div className="create-form"> {content} </div>
          <div className="buttons-container flex-row">
            {buttonCancel}
            {pendingIndicator}
            {buttonContinue}
          </div>
          <div> {displayError} </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CreateWithSteps);
