import firebase from '../../../../../../firebase';

const reportError = err => ({
  type: 'REARRANGE_COMPONENTS_ERROR',
  err,
});

const findIndex = (array, element) => {
  if (element === null) {
    return array.length;
  }

  return array.indexOf(element);
};

const moveArray = (oldIndex, newIndex, arr) => {
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const rearrangeComponents = (
  id,
  siblingId,
  page,
  appname,
) => dispatch => {
  dispatch({
    type: 'REARRANGE_COMPONENTS',
    id,
    siblingId,
    page,
    appname,
  });

  const componentsRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${page}/components`);

  componentsRef
    .transaction(compArray => {
      if (compArray === null) return null;

      const oIndex = findIndex(compArray, id);
      let nIndex = findIndex(compArray, siblingId);

      if (nIndex > oIndex) {
        nIndex -= 1;
      }

      return moveArray(oIndex, nIndex, compArray);
    })
    .catch(err => dispatch(reportError(err)));
};

export const rearrangeTabComponents = (
  id,
  siblingId,
  tab,
  page,
  appname,
) => dispatch => {
  dispatch({
    type: 'REARRANGE_TAB_COMPONENTS',
    id,
    siblingId,
    page,
    tab,
    appname,
  });

  const componentsRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${page}/tabs/${tab}/components`);

  componentsRef
    .transaction(compArray => {
      if (compArray === null) return null;

      const oIndex = findIndex(compArray, id);
      let nIndex = findIndex(compArray, siblingId);

      if (nIndex > oIndex) {
        nIndex -= 1;
      }

      return moveArray(oIndex, nIndex, compArray);
    })
    .catch(err => dispatch(reportError(err)));
};

export const getAllLazyData = appname => dispatch => {
  firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data`)
    .once('value', snapshot => {
      if (snapshot.exists()) {
        dispatch({ type: 'RECEIVE_ALL_LAZY_DATA', data: snapshot.val() });
      }
    });
};

export const duplicateComponentDialog = component => dispatch => {
  dispatch({ type: 'OPEN_DUPLICATE_COMPONENT_DIALOG' });
  dispatch({ type: 'SET_DUPLICATE_COMPONENT', payload: component });
};
