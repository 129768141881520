import React, { useRef, useState, useEffect, useCallback } from 'react';
import Dialog from 'material-ui/Dialog';
import Divider from 'material-ui/Divider';
import Toggle from 'material-ui/Toggle';
import debounce from 'lodash.debounce';
import { Autocomplete, ListItemText } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import HighlightOff from '@material-ui/icons/HighlightOff';
import JoditEditor from 'jodit-react';
import { Provider, ReactReduxContext } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import BlogIcon from '../../components/icons/pgmolblog.png';
import TextArea from '../../../../app/components/textArea';
import ImageUpload from '../../../../modules/editor/internals/imageUpload';
import themeStyles from '../../../../_export.scss';
import { FlatButton } from '../../../../app/components/buttons';
import { CircularProgress } from '../../../../app/components/progress';
import TooltipElement from '../../../../app/components/tooltipElement';
// import TableCheckbox from '../../../memberLogin/components/tableCheckbox';
import './styles.css';

const BlogModal = props => {
  const {
    data,
    onMultipleValueFieldChange,
    onFieldChange,
    appname,
    toggleBlogModal,
    onSubmit,
    getBlogCategories,
    blogCategories,
  } = props;

  const {
    modalOpen,
    title,
    published,
    content,
    cover_image,
    categories,
    pending,
    isUpdate,
  } = data;

  const { t } = useTranslation();

  const [categ, setCateg] = useState('');

  const editor = useRef(null);
  const initialContent = useRef(content.value || '');

  const titleEditor = useRef(null);
  const initialTitle = useRef(title.value || '');

  useEffect(() => {
    getBlogCategories();
  }, []);

  useEffect(() => {
    if (blogCategories) {
      let temp = [];
      for (const categId in blogCategories) {
        temp.push({ label: blogCategories[categId], value: categId });
      }
      setCateg(temp);
    }
  }, [blogCategories]);

  const handleFieldChange = useCallback(
    debounce((fieldName, newContent) => {
      onFieldChange(fieldName, newContent);
    }, 1000), // Adjust debounce time as needed
    [],
  );

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: t('Start typing...'),
    uploader: {
      insertImageAsBase64URI: true,
    },
    removeButtons: ['br'],
  };

  const blogTitleConfig = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: t('Start typing...'),
    toolbar: true,
    removeButtons: [
      'image',
      'video',
      'file',
      'hr',
      'source',
      'table',
      'link',
      'unlink',
      'print',
      'fullsize',
      'preview',
      'br',
    ],
    required: true,
  };

  const handleChange = (event, value) => {
    onMultipleValueFieldChange('categories', [...value]);
  };

  const handleModalClose = () => {
    toggleBlogModal(!modalOpen || false);
  };

  const buttonSubmit = !pending ? (
    <FlatButton
      label={isUpdate ? t('Update') : t('Add')}
      onClick={() => {
        onFieldChange('content', initialContent.current);
        // onFieldChange('title', initialTitle.current);
        setTimeout(() => {
          onSubmit();
        }, 500);
      }}
      primary
    />
  ) : (
    <CircularProgress size={18} />
  );

  return (
    <ReactReduxContext.Consumer>
      {ctx => (
        <Dialog
          open={modalOpen || false}
          // onRequestClose={handleModalClose}
          autoScrollBodyContent
          style={{ zIndex: 1200 }}
          className="pgmol-blog-modal-main-container pgmol-modal-main-container"
        >
          <div className="user-memberBodies-modal-content">
            <div className="modal-heading pgmol-modal-heading">
              <img src={BlogIcon} alt="imgg" width={64} height={64} />
              <h5>
                <Trans>Blog</Trans>
              </h5>
              <HighlightOff
                className="pgmol-heading-close-icon"
                onClick={handleModalClose}
              />
            </div>
          </div>
          <div>
            <Divider />
            <div className="row">
              <div className="blog-modal-container">
                {/* <TextField
                  id="title"
                  placeholder={'Title'}
                  {...title}
                  onChange={value => onFieldChange('title', value)}
                  required
                  showRequiredAsterick
                  applyPgmolLabelStyle
                /> */}
                {/* <TextArea
                  id="title"
                  placeholder={t('Title')}
                  {...title}
                  onChange={value => handleFieldChange('title', value)}
                  label={t('Title')}
                  minHeight="120px"
                  required
                  errorText={t('Field is required.')}
                  showRequiredAsterick
                  applyPgmolLabelStyle
                /> */}

                <div className="pgmol-blog-editor-container">
                  <br />
                  {/* <JoditEditor
                    ref={titleEditor}
                    value={initialTitle.current}
                    config={blogTitleConfig}
                    tabIndex={1}
                    onChange={newContent => {
                      initialTitle.current = newContent;
                      handleFieldChange('title', newContent);
                    }}
                  /> */}
                  <TextArea
                    id="title"
                    {...title}
                    onChange={value => onFieldChange('title', value)}
                    label={t('Title')}
                    minHeight="120px"
                    width={'100%'}
                    required
                    errorText={t('Field is required.')}
                    showRequiredAsterick
                    applyPgmolLabelStyle
                  />
                </div>

                <br />
                <div
                  style={{
                    display: 'flex',
                    width: '45%',
                    alignItems: 'center',
                  }}
                >
                  <Toggle
                    onToggle={(v, b) => onFieldChange('published', b)}
                    toggled={published.value}
                    label={t('Blog Published?')}
                    trackSwitchedStyle={{
                      backgroundColor: themeStyles.primaryColorLight,
                    }}
                    thumbSwitchedStyle={{
                      backgroundColor: 'rgb(120, 255, 197)',
                    }}
                    className="pgmol-blog-toggle"
                    style={{
                      width: 'calc(100% - 20px)',
                      fontSize: `${published.value ? '0.74rem' : '0.95rem'}`,
                      color: '#000',
                      flex: 1,
                    }}
                  />
                  <TooltipElement
                    tooltip={t(
                      'Unpublished blogs will not be visible to the users.',
                    )}
                    verticalPosition="top"
                    horizontalPosition="right"
                  />
                </div>
                <p className="pgmol-regional-services-label">
                  <Trans>Categories</Trans>
                </p>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  disableCloseOnSelect
                  options={[...categ]}
                  getOptionLabel={option => {
                    if (option) return option.label;
                    return 'NA';
                  }}
                  renderOption={(props, { label }) => {
                    return (
                      <li {...props} key={label}>
                        <ListItemText primary={`${label}`} />
                      </li>
                    );
                  }}
                  className="pgmol-blog-autocomplete"
                  renderInput={params => (
                    <MuiTextField
                      {...params}
                      variant="standard"
                      className="pgmol-auto-complete"
                    />
                  )}
                  onChange={handleChange}
                  value={[...categories.value]}
                  defaultValue={[...categories.value]}
                />
                <Provider store={ctx.store}>
                  <div>
                    <h5 className="pgmol-label">
                      <Trans>Cover Image</Trans>
                    </h5>
                    <ImageUpload
                      targetId="blog_cover_image_upload"
                      title={t('Blog cover image')}
                      src={cover_image.value}
                      firebaseUrl={`blog/${appname}/tempImages`}
                      showCropAreaSizes
                      height="220px"
                      width="320px"
                    />
                  </div>
                </Provider>
                <div className="pgmol-blog-editor-container">
                  <h5 className="pgmol-label">
                    <Trans>Blog Article Content</Trans>
                  </h5>
                  <br />
                  <JoditEditor
                    ref={editor}
                    value={initialContent.current}
                    config={config}
                    tabIndex={1}
                    onChange={newContent => {
                      // setBlogContent(newContent);
                      initialContent.current = newContent;

                      handleFieldChange('content', newContent);
                    }}
                  />
                </div>

                <br />
              </div>
            </div>
            <br />
            <Divider />
          </div>
          <Divider />
          <br />
          <div className="submit-button-container">{buttonSubmit}</div>
        </Dialog>
      )}
    </ReactReduxContext.Consumer>
  );
};

export default BlogModal;
