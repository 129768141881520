import React from 'react';
import AddInventoryItem from '../../components/addInventoryItem/AddInventoryItem';
import InventoryTable from '../../components/inventoryTable/InventoryTable';

const Inventory = props => {
  const {
    addInventoryDialogOpen,
    onAddInventoryDialogOpen,
    onInventoryItemChange,
    inventoryItem,
    isImageUploaded,
    appname,
    isAppAdmin,
  } = props;
  return (
    <div>
      <AddInventoryItem
        appname={appname}
        addInventoryDialogOpen={addInventoryDialogOpen}
        onAddInventoryDialogOpen={onAddInventoryDialogOpen}
        isImageUploaded={isImageUploaded}
        onInventoryItemChange={onInventoryItemChange}
        inventoryItem={inventoryItem}
      />
      <InventoryTable
        isAppAdmin={isAppAdmin}
        appname={appname}
        onAddInventoryDialogOpen={onAddInventoryDialogOpen}
        onInventoryItemChange={onInventoryItemChange}
      />
    </div>
  );
};

export default Inventory;
