import React from 'react';
import { useQuery } from 'react-query';

import BookingsTable from '../../components/bookingsTable/BookingsTable';

import { getAllStaff, getMetaData } from '../../api';

const Booking = props => {
  const { appname } = props;

  const { data: staffData } = useQuery(
    ['staffMembers', { appname }],
    getAllStaff,
  );

  const { data: metaData } = useQuery(
    ['bookingMetaData', { appname }],
    getMetaData,
  );

  return (
    <BookingsTable
      staffData={staffData}
      metaData={metaData}
      appname={appname}
    />
  );
};

export default Booking;
