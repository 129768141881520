import firebase from '../../../../../../../firebase';

export const fieldChange = (appname, dataId, field, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child(field).set(value);

  return {
    type: 'TEXT_VALUE_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

export const handleDesignChange = (appname, key, type, value, prop) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${key}/design`);
  const attr = prop ? `${prop}/${type}` : type;

  dataRef.child(attr).set(value);

  return {
    type: 'DESIGN_CHANGE',
    appname,
    key,
    designType: type,
    value,
  };
};
