const initialState = {
  enabled: false,
  signupGroups: [],
};

const defaultGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_SIGNUP_AS':
      return {
        ...state,
        enabled: action.value,
      };
    case 'SET_SIGNUP_GROUPS':
      return {
        ...state,
        signupGroups: action.value,
      };

    default:
      return state;
  }
};

export default defaultGroupReducer;
