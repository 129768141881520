import _get from 'lodash/get';

import { saveMetaData } from '../../../actions';
import { toggleDomainChangeModal } from '../../../internals/domainChangeModal/actions';
import { newValidator } from '../../../../../utils';

export const RECEIVE_META_DATA = 'RECEIVE_META_DATA';
export const UPDATE_APP_NAME = 'UPDATE_APP_NAME';
export const HYDRATE_APP_NAME = 'HYDRATE_APP_NAME';
export const LOADING_APP_NAME = 'LOADING_APP_NAME';
export const REPLACE_APP_NAME_DATA = 'REPLACE_APP_NAME_DATA';
export const FIELD_VALIDATION_CHECK = 'FIELD_VALIDATION_CHECK';
export const IS_UPDATING = 'IS_UPDATING';

const titleValidator = newValidator({
  required: true,
  maxLength: 45,
  minLength: 3,
  type: 'title',
});

const descriptionValidator = newValidator({
  maxLength: 320,
  type: 'description',
});

const loadingAppName = appname => ({
  type: LOADING_APP_NAME,
  appname,
});

export const setIsUpdating = value => ({
  type: IS_UPDATING,
  value,
});

const updateAppName = (meta, appname) => ({
  type: UPDATE_APP_NAME,
  appname,
  meta,
});

const hydrateAppData = (meta, appname) => ({
  type: HYDRATE_APP_NAME,
  meta,
  appname,
});

export const replaceAppData = (appname, previusAppname) => ({
  type: REPLACE_APP_NAME_DATA,
  previusAppname,
  appname,
});

export const loadAppNameData = appname => (dispatch, getState) => {
  dispatch(loadingAppName(appname));
  const metaData = _get(getState(), `editor.data.metaData.${appname}`);
  dispatch(hydrateAppData(metaData, appname));
};

export const validationChecker = (appname, invalid, attr, validations) => ({
  type: FIELD_VALIDATION_CHECK,
  appname,
  attr,
  invalid,
  validations,
});

const fieldValidator = (appname, attr, value, validations) => {
  switch (attr) {
    case 'title': {
      let isValid = titleValidator(value);
      if (isValid) {
        isValid = !(value.indexOf('"') > -1 || value.indexOf("'") > -1);
      }

      return validationChecker(appname, isValid, attr, validations);
    }
    case 'description': {
      let isValid = descriptionValidator(value);
      if (isValid) {
        isValid = !(value.indexOf('"') > -1 || value.indexOf("'") > -1);
      }

      return validationChecker(appname, isValid, attr, validations);
    }
    default:
      return null;
  }
};

export const updateMetaData = (
  appname,
  attr,
  value,
  validations,
) => dispatch => {
  dispatch(fieldValidator(appname, attr, value, validations));
  dispatch(updateAppName({ [attr]: value }, appname));
};

export const saveChanges = (appname, nextState, domain) => dispatch => {
  if (appname !== domain) {
    dispatch(toggleDomainChangeModal(appname, domain));
    dispatch(setIsUpdating(true));
    return;
  }
  dispatch(saveMetaData(appname, nextState));
};
