import React, { Fragment } from 'react';
import TwoThirdImage from './images/twoThird.png';
import './styles.css';

const TwoThird = () => {
  return (
    <Fragment>
      <img className="imageWrapper" src={TwoThirdImage} alt="Two Third" />
    </Fragment>
  );
};

export default TwoThird;
