import { combineReducers } from 'redux';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import _pickBy from 'lodash/pickBy';

import {
  REQUEST_PAYMENT_HISTORY,
  RECEIVE_PAYMENT_HISTORY,
  FILTER_PAYMENT_DATA,
  SORT_PAYMENT_DATA,
} from './actions';

const initialState = {
  pending: false,
  chunks: {},
  selected: [],
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  availableStaffMembers: [],
  filteredChunksAll: {},
  colSorted: 'createdAt',
  order: 'desc',
};

const sortItems = (filteredChunks, sortBy, order) =>
  _keys(filteredChunks).sort((a, b) => {
    let keyA = filteredChunks[a][sortBy];
    let keyB = filteredChunks[b][sortBy];
    if (sortBy === 'createdAt') {
      keyA = new Date(filteredChunks[a][sortBy]);
      keyB = new Date(filteredChunks[b][sortBy]);
    }

    if (sortBy === 'amount') {
      keyA = parseFloat(keyA);
      keyB = parseFloat(keyB);
    }

    if (order === 'desc') {
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
    } else if (order === 'asc') {
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
    }
    return 0;
  });

const filterChunks = (chunks, filter = '') => {
  let filteredChunks = chunks || {};

  if (filter) {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`(${filter})`, 'g');
      return (
        rgx.test(chunk.email) ||
        rgx.test(chunk.firstName) ||
        rgx.test(chunk.lastName) ||
        rgx.test(chunk.email) ||
        rgx.test(chunk.amount)
      );
    });
  }
  return { filteredChunks };
};

const paymentHistory = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PAYMENT_HISTORY:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_PAYMENT_HISTORY:
      return {
        ...state,
        chunks: action.value,
        selected: [],
        filteredChunks: action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };
    case FILTER_PAYMENT_DATA:
      const { filteredChunks } = filterChunks(state.chunks, action.payload);
      return {
        ...state,
        filter: action.payload,
        ...filteredChunks,
        items: sortItems(filteredChunks, state.colSorted, state.order),
      };

    case SORT_PAYMENT_DATA:
      return {
        ...state,
        order: state.order === 'desc' ? 'asc' : 'desc',
        items: sortItems(
          state.filteredChunks,
          action.column.id,
          state.order === 'desc' ? 'asc' : 'desc',
        ),
        colSorted: action.column.id,
      };

    default:
      return state;
  }
};

export default combineReducers({
  data: paymentHistory,
});
