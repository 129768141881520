import React from 'react';
import { FormattedNumber } from 'react-intl';

const Price = (props) => (
    <strong>
        <FormattedNumber
            maximumFractionDigits={2}
            format={props.intl.currency}
            value={props.cost || 0}
        />
    </strong>
);

export default Price;