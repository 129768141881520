import styled from 'styled-components';
import PropTypes from 'prop-types';

const HiddenRadioInput = styled.input.attrs({
  type: 'radio',
  name: 'button-shape',
  checked: props => props.selected === props.value,
})`
  position: absolute;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: all;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0px;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
`;

HiddenRadioInput.propTypes = {
  selected: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

HiddenRadioInput.defaultProps = {
  selected: false,
  value: '0px',
};

export default HiddenRadioInput;
