import { connect } from 'react-redux';
import queryString from 'query-string';
import GoogleMaps from './GoogleMaps';
import { addGoogleMapsItem, rearrangeItems, fieldChange } from './actions';
import { launchRemoveDialog } from '../../remove/actions';

const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onAddItem: title =>
    dispatch(
      addGoogleMapsItem(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        title,
      ),
    ),
  onRemoveItem: item =>
    dispatch(
      launchRemoveDialog(
        ownProps.match.params.appname,
        {
          ...queryString.parse(ownProps.location.search),
          item,
        },
        'ITEM',
      ),
    ),
  rearrangeItems: (id, siblingId) =>
    dispatch(
      rearrangeItems(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        id,
        siblingId,
      ),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoogleMaps);
