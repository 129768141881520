import { ICON_DATA_UPDATED } from './actions';

const appIcon = (state = {}, action) => {
  switch (action.type) {
    case ICON_DATA_UPDATED:
      return {
        ...state,
        modified: action.modified,
      };

    default:
      return {
        ...state,
      };
  }
};

export default appIcon;
