import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import Divider from 'material-ui/Divider';
import { Trans, withTranslation } from 'react-i18next';
import firebase from '../../../../../firebase';
import TextField from '../../../../../app/components/textField';
import CircularValidation from '../../../../../app/components/circularValidation';
import { FlatButton } from '../../../../../app/components/buttons';
import { validateField } from './../../../../memberLogin/modules/memberBodies/validators';
import './styles.css';
import { HS_FEATURES_ENABLED_APPS } from '../../../../../config/constants';

class EditMemberBody extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    filteredChunks: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      name: {
        value: '',
        isValid: false,
      },
      postalAddress: {
        value: '',
        isValid: false,
      },
      postCode: {
        value: '',
        isValid: false,
      },
      adminUsername: {
        value: '',
        isValid: false,
      },
      adminEmail: {
        value: '',
        isValid: false,
      },
      primaryContactName: {
        value: '',
        isValid: false,
      },
      primaryContactEmail: {
        value: '',
        isValid: false,
      },
      primaryContactNumber: {
        value: '',
        isValid: false,
      },
      secondaryContactName: {
        value: '',
        isValid: true,
      },
      secondaryContactEmail: {
        value: '',
        isValid: true,
      },
      secondaryContactNumber: {
        value: '',
        isValid: true,
      },
      landline: {
        value: '',
        isValid: true,
      },
      mobile: {
        value: '',
        isValid: true,
      },
      memberNumber: {
        value: '',
        isValid: true,
      },
      memberNumberMale: {
        value: '',
        isValid: true,
      },
      memberNumberFemale: {
        value: '',
        isValid: true,
      },
      memberNumberDisabled: {
        value: '',
        isValid: true,
      },
      memberNumberSmid: {
        value: '',
        isValid: true,
      },
      selected: {},
      pending: false,
      loading: true,
    };
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params.memberBody) {
      this.loadmMemberBody();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match &&
      this.props.match.params.memberBody !== prevProps.match.params.memberBody
    ) {
      this.loadmMemberBody();
    }
  }

  isHorseScotlandApp = () =>
    HS_FEATURES_ENABLED_APPS.indexOf(this.props.match.params.appname) > -1;

  onSubmit = async () => {
    const { appname, memberBody } = this.props.match.params;
    this.setState({ pending: true });
    const update = {
      name: this.state.name.value ? this.state.name.value : '',
      postalAddress: this.state.postalAddress.value
        ? this.state.postalAddress.value
        : '',
      postCode: this.state.postCode.value ? this.state.postCode.value : '',
      adminUsername: this.state.adminUsername.value
        ? this.state.adminUsername.value
        : '',
      adminEmail: this.state.adminEmail.value
        ? this.state.adminEmail.value
        : '',
      primaryContactName: this.state.primaryContactName.value
        ? this.state.primaryContactName.value
        : '',
      primaryContactEmail: this.state.primaryContactEmail.value
        ? this.state.primaryContactEmail.value
        : '',
      primaryContactNumber: this.state.primaryContactNumber.value
        ? this.state.primaryContactNumber.value
        : '',
      secondaryContactName: this.state.secondaryContactName.value
        ? this.state.secondaryContactName.value
        : '',
      secondaryContactEmail: this.state.secondaryContactEmail.value
        ? this.state.secondaryContactEmail.value
        : '',
      secondaryContactNumber: this.state.secondaryContactNumber.value
        ? this.state.secondaryContactNumber.value
        : '',
      landline: this.state.landline.value ? this.state.landline.value : '',
      mobile: this.state.mobile.value ? this.state.mobile.value : '',
      memberNumber: this.state.memberNumber.value
        ? this.state.memberNumber.value
        : '',
      memberNumberMale: this.state.memberNumberMale.value
        ? this.state.memberNumberMale.value
        : '',
      memberNumberFemale: this.state.memberNumberFemale.value
        ? this.state.memberNumberFemale.value
        : '',
      memberNumberDisabled: this.state.memberNumberDisabled.value
        ? this.state.memberNumberDisabled.value
        : '',
      memberNumberSmid: this.state.memberNumberSmid.value
        ? this.state.memberNumberSmid.value
        : '',
      last_updated: Date.now(),
    };

    firebase
      .database()
      .ref(`member_bodies/${appname}/${memberBody}`)
      .update(update);

    this.props.history.push(`/${appname}/staff/member-bodies`);
    return this.setState({ pending: false });
  };

  onFormFieldChangeHook = (key, value, isRequired) => {
    const { filteredChunks } = this.props;
    const { memberBody } = this.props.match.params;
    let isValid = true;
    if (isRequired) {
      if (value.length < 2) {
        isValid = false;
      } else if (key === 'adminEmail' || key === 'primaryContactEmail') {
        isValid = value.length > 0 ? validateField[key](value) : null;
      } else if (key === 'name') {
        Object.keys(filteredChunks).forEach(filteredChunkKey => {
          if (
            memberBody !== filteredChunkKey &&
            filteredChunks[filteredChunkKey].name &&
            filteredChunks[filteredChunkKey].name === value
          ) {
            isValid = false;
          }
          return true;
        });
      }
    }
    this.setState({
      [key]: { value, isValid },
    });
  };

  toggleAll = value => {
    const nextState = this.state.staffMembers.reduce(
      (next, val) => ({
        ...next,
        [val.key]: value,
      }),
      {},
    );

    this.setState({ selectAll: value, selected: nextState });
  };

  loadmMemberBody = async () => {
    const { appname, memberBody } = this.props.match.params;
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    const snapshot = await firebase
      .database()
      .ref(`member_bodies/${appname}/${memberBody}`)
      .once('value');

    if (snapshot.exists()) {
      const data = snapshot.val();
      return this.setState({
        selected: data.staffMembers || {},
        name: { value: data.name, isValid: true },
        postalAddress: { value: data.postalAddress, isValid: true },
        postCode: { value: data.postCode, isValid: true },
        adminUsername: { value: data.adminUsername, isValid: true },
        adminEmail: { value: data.adminEmail, isValid: true },
        primaryContactName: {
          value: data.primaryContactName,
          isValid: true,
        },
        primaryContactEmail: {
          value: data.primaryContactEmail,
          isValid: true,
        },
        primaryContactNumber: {
          value: data.primaryContactNumber,
          isValid: true,
        },
        secondaryContactName: {
          value: data.secondaryContactName,
          isValid: true,
        },
        secondaryContactEmail: {
          value: data.secondaryContactEmail,
          isValid: true,
        },
        secondaryContactNumber: {
          value: data.secondaryContactNumber,
          isValid: true,
        },
        landline: { value: data.landline, isValid: true },
        mobile: { value: data.mobile, isValid: true },
        memberNumber: { value: data.memberNumber, isValid: true },
        memberNumberMale: {
          value: data.memberNumberMale,
          isValid: true,
        },
        memberNumberFemale: {
          value: data.memberNumberFemale,
          isValid: true,
        },
        memberNumberDisabled: {
          value: data.memberNumberDisabled,
          isValid: true,
        },
        memberNumberSmid: {
          value: data.memberNumberSmid,
          isValid: true,
        },
        loading: false,
      });
    }
    return this.setState({ loading: false });
  };

  render() {
    const { history, match, t } = this.props;
    return (
      <Dialog
        open={!!(match && match.params.memberBody)}
        onRequestClose={() =>
          history.push(`/${match.params.appname}/staff/member-bodies`)
        }
        autoScrollBodyContent
        contentClassName="user-memberBodies-modal"
        paperClassName="user-memberBodies-paper"
      >
        <div className="user-memberBodies-modal-content">
          <div className="modal-heading">
            <h5>
              <Trans>Edit Member Body</Trans>
            </h5>
          </div>
          <div>
            <Divider />
            <div className="row">
              <div className="col-sm-6">
                <div className="users-list-container">
                  {this.isHorseScotlandApp() ? (
                    <TextField
                      id="name"
                      placeholder={t('Member Body Name')}
                      {...this.state.name}
                      onChange={value =>
                        this.onFormFieldChangeHook('name', value, true)
                      }
                      rightInputAction={
                        <CircularValidation
                          defaultMsg={t('Member Body Name required')}
                          errorMsg={t('Member Body Name already exists')}
                          isValid={this.state.name.isValid}
                          showDefault={!this.state.name.value}
                        />
                      }
                    />
                  ) : (
                    <TextField
                      id="name"
                      placeholder={t('Event Organizer Name')}
                      {...this.state.name}
                      onChange={value =>
                        this.onFormFieldChangeHook('name', value, true)
                      }
                      rightInputAction={
                        <CircularValidation
                          defaultMsg={t('Event Organizer Name required')}
                          errorMsg={t('Event Organizer Name already exists')}
                          isValid={this.state.name.isValid}
                          showDefault={!this.state.name.value}
                        />
                      }
                    />
                  )}
                  <TextField
                    id="postalAddress"
                    placeholder={t('Postal Address')}
                    {...this.state.postalAddress}
                    onChange={value =>
                      this.onFormFieldChangeHook('postalAddress', value, true)
                    }
                    rightInputAction={
                      <CircularValidation
                        defaultMsg={t('Postal Address required')}
                        errorMsg={t(
                          'Postal Address must be at least 2 characters long',
                        )}
                        isValid={this.state.postalAddress.isValid}
                        showDefault={!this.state.postalAddress.value}
                      />
                    }
                  />
                  <TextField
                    id="postCode"
                    placeholder={t('Post Code')}
                    {...this.state.postCode}
                    onChange={value =>
                      this.onFormFieldChangeHook('postCode', value, true)
                    }
                    rightInputAction={
                      <CircularValidation
                        defaultMsg={t('Post code required')}
                        errorMsg={t(
                          'Post code must be at least 2 characters long',
                        )}
                        isValid={this.state.postCode.isValid}
                        showDefault={!this.state.postCode.value}
                      />
                    }
                  />
                  {this.isHorseScotlandApp() && (
                    <>
                      <TextField
                        id="adminUsername"
                        placeholder={t('Admin Username')}
                        {...this.state.adminUsername}
                        onChange={value =>
                          this.onFormFieldChangeHook(
                            'adminUsername',
                            value,
                            true,
                          )
                        }
                        rightInputAction={
                          <CircularValidation
                            defaultMsg={t('admin username required')}
                            errorMsg={t(
                              'admin username must be at least 2 characters long',
                            )}
                            isValid={this.state.adminUsername.isValid}
                            showDefault={!this.state.adminUsername.value}
                          />
                        }
                      />
                      <TextField
                        id="adminEmail"
                        placeholder={t('Admin Email')}
                        {...this.state.adminEmail}
                        onChange={value =>
                          this.onFormFieldChangeHook('adminEmail', value, true)
                        }
                        rightInputAction={
                          <CircularValidation
                            defaultMsg={t('admin email required')}
                            errorMsg={t('Please use a valid email address')}
                            isValid={this.state.adminEmail.isValid}
                            showDefault={!this.state.adminEmail.value}
                          />
                        }
                      />
                    </>
                  )}
                  <TextField
                    id="primaryContactName"
                    placeholder={t('Primary Contact Name')}
                    {...this.state.primaryContactName}
                    onChange={value =>
                      this.onFormFieldChangeHook(
                        'primaryContactName',
                        value,
                        true,
                      )
                    }
                    rightInputAction={
                      <CircularValidation
                        defaultMsg={t('Primary Contact Name required')}
                        errorMsg={t(
                          'Primary Contact Name must be at least 2 characters long',
                        )}
                        isValid={this.state.primaryContactName.isValid}
                        showDefault={!this.state.primaryContactName.value}
                      />
                    }
                  />

                  <TextField
                    id="primaryContactEmail"
                    placeholder={t('Primary Contact Email')}
                    {...this.state.primaryContactEmail}
                    onChange={value =>
                      this.onFormFieldChangeHook(
                        'primaryContactEmail',
                        value,
                        true,
                      )
                    }
                    rightInputAction={
                      <CircularValidation
                        defaultMsg={t('Primary Contact Email required')}
                        errorMsg={t('Please use a valid email address')}
                        isValid={this.state.primaryContactEmail.isValid}
                        showDefault={!this.state.primaryContactEmail.value}
                      />
                    }
                  />
                  <TextField
                    id="primaryContactNumber"
                    placeholder={t('Primary Contact Number')}
                    {...this.state.primaryContactNumber}
                    onChange={value =>
                      this.onFormFieldChangeHook(
                        'primaryContactNumber',
                        value,
                        true,
                      )
                    }
                    rightInputAction={
                      <CircularValidation
                        defaultMsg={t('Primary Contact Number required')}
                        errorMsg={t(
                          'Primary Contact Number must be at least 2 characters long',
                        )}
                        isValid={this.state.primaryContactNumber.isValid}
                        showDefault={!this.state.primaryContactNumber.value}
                      />
                    }
                  />
                  <TextField
                    id="secondaryContactName"
                    placeholder={t('Secondary Contact Name')}
                    {...this.state.secondaryContactName}
                    onChange={value =>
                      this.onFormFieldChangeHook(
                        'secondaryContactName',
                        value,
                        false,
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <TextField
                  id="secondaryContactEmail"
                  placeholder={t('Secondary Contact Email')}
                  {...this.state.secondaryContactEmail}
                  onChange={value =>
                    this.onFormFieldChangeHook(
                      'secondaryContactEmail',
                      value,
                      false,
                    )
                  }
                />
                <TextField
                  id="secondaryContactNumber"
                  placeholder={t('Secondary Contact Number')}
                  {...this.state.secondaryContactNumber}
                  onChange={value =>
                    this.onFormFieldChangeHook(
                      'secondaryContactNumber',
                      value,
                      false,
                    )
                  }
                />
                <TextField
                  id="landline"
                  placeholder={t('Landline')}
                  {...this.state.landline}
                  onChange={value =>
                    this.onFormFieldChangeHook('landline', value, false)
                  }
                />
                <TextField
                  id="mobile"
                  placeholder={t('Mobile')}
                  {...this.state.mobile}
                  onChange={value =>
                    this.onFormFieldChangeHook('mobile', value, false)
                  }
                />
                {this.isHorseScotlandApp() && (
                  <>
                    <TextField
                      id="memberNumber"
                      placeholder={t('Number of Members')}
                      {...this.state.memberNumber}
                      onChange={value =>
                        this.onFormFieldChangeHook('memberNumber', value, false)
                      }
                    />
                    <TextField
                      id="memberNumberMale"
                      placeholder={t('Number of Male Members')}
                      {...this.state.memberNumberMale}
                      onChange={value =>
                        this.onFormFieldChangeHook(
                          'memberNumberMale',
                          value,
                          false,
                        )
                      }
                    />
                    <TextField
                      id="memberNumberFemale"
                      placeholder={t('Number of Female Members')}
                      {...this.state.memberNumberFemale}
                      onChange={value =>
                        this.onFormFieldChangeHook(
                          'memberNumberFemale',
                          value,
                          false,
                        )
                      }
                    />
                    <TextField
                      id="memberNumberDisabled"
                      placeholder={t('Number of Disabled Members')}
                      {...this.state.memberNumberDisabled}
                      onChange={value =>
                        this.onFormFieldChangeHook(
                          'memberNumberDisabled',
                          value,
                          false,
                        )
                      }
                    />

                    <TextField
                      id="memberNumberSmid"
                      placeholder={t('Number of SMID Members')}
                      {...this.state.memberNumberSmid}
                      onChange={value =>
                        this.onFormFieldChangeHook(
                          'memberNumberSmid',
                          value,
                          false,
                        )
                      }
                    />
                  </>
                )}
              </div>
            </div>
            <Divider />

            <div className="submit-button-container">
              <FlatButton
                label={this.state.pending ? t('Saving...') : t('Save')}
                onClick={this.onSubmit}
                disabled={
                  !this.state.name.isValid ||
                  this.state.pending ||
                  this.state.disabled
                }
                primary
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  filteredChunks: state.auth.memberBodies.data.filteredChunksAll,
});

export default withRouter(
  connect(mapStateToProps, null)(withTranslation()(EditMemberBody)),
);
