import firebase from '../firebase';

export const increaseTotalSubscribed = plan => {
  firebase
    .database()
    .ref(`analytics/totals/subscribed/${plan}/count`)
    .transaction(number => {
      if (number === null) return null;
      const result = number + 1;
      return result;
    });
};

export const increaseTotalCancelled = plan => {
  firebase
    .database()
    .ref(`analytics/totals/cancelled/${plan}/count`)
    .transaction(number => {
      if (number === null) return null;
      const result = number + 1;
      return result;
    });
};

export const increaseTotalNotificationsSent = () => {
  firebase
    .database()
    .ref(`analytics/totals/notifications/count`)
    .transaction(number => {
      if (number === null) return null;
      const result = number + 1;
      return result;
    });
};

export const increaseTotalSmsSent = () => {
  firebase
    .database()
    .ref(`analytics/totals/sms/count`)
    .transaction(number => {
      if (number === null) return null;
      const result = number + 1;
      return result;
    });
};

export const increaseTotalNewSignUps = () => {
  firebase
    .database()
    .ref(`analytics/totals/signups/count`)
    .transaction(number => {
      if (number === null) return null;
      const result = number + 1;
      return result;
    });
};

export const increaseTotalUpgradeClicks = () => {
  firebase
    .database()
    .ref(`analytics/totals/upgradeClicked/count`)
    .transaction(number => {
      if (number === null) return null;
      const result = number + 1;
      return result;
    });
};

export const increaseAdilloRegisterPageVisites = () => {
  firebase
    .database()
    .ref(`offers_analytics/adillo_visits/`)
    .transaction(number => {
      if (number === null) return 1;
      const result = number + 1;
      return result;
    });
};

export const increaseTotalTrialsExpired = () => {
  firebase
    .database()
    .ref(`analytics/totals/trialsExpired/count`)
    .transaction(number => {
      if (number === null) return null;
      const result = number + 1;
      return result;
    });
};

export const logEventsToFirebase = (event, payload) => {
  if (payload.account) {
    firebase.analytics().setUserId(payload.account);
  }
  if (payload.appname) {
    const { appname } = payload;
    firebase.analytics().setUserProperties({ appname });
  }

  return firebase.analytics().logEvent(event, payload);
};
