import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Widget from './Widget';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isPublished: state.editor.data.isPublished[ownProps.match.params.appname],
  customDomain: state.app.customDomain
});

export default withRouter(connect(mapStateToProps)(Widget));
