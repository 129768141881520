import React from 'react';

import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Sidebar, { SidebarItem } from '../../../../app/components/sidebar';
import {
  Features as FeaturesIcon,
  Inventory as InventoryIcon,
} from '../../icons';

import ViewApp from '../../../../app/components/buttons/viewApp';

function FkAcademyMenu({ location, match }) {
  // const match = useRouteMatch();
  // const { appname } = useParams();

  const { appname } = match.params;

  const isPublished =
    useSelector(state => state.editor.data.isPublished[appname]) || false;

  function activeCheck(prefix) {
    return location.pathname === `/${appname}/fkacademy/${prefix}`;
  }

  const baseHref = `/${appname}/fkacademy`;

  return (
    <Sidebar animate>
      <ViewApp isPublished={isPublished} match={match} />
      <SidebarItem
        label="Groups"
        href={`${baseHref}/groups`}
        active={activeCheck('groups')}
        icon={<FeaturesIcon />}
      />
      <SidebarItem
        label="Users"
        href={`${baseHref}/users`}
        active={activeCheck('users')}
        icon={<InventoryIcon />}
      />

    </Sidebar>
  );
}

export default withRouter(FkAcademyMenu);
