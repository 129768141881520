import { connect } from 'react-redux';
import queryString from 'query-string';
import _get from 'lodash/get';

import EditorPreview from './EditorPreview';
import {
  loadContent,
  addPageContentUpdateListner,
  removeListeners,
  checkUsersSubscription,
  addOfferNotice,
  noticeSet,
  toggleAppMenu,
  toggleUserMenu,
  changeApp,
  addIsPublishedListener,
  removeIsPublishedListener,
  isAppValid,
} from './actions';

import { launchPublishDialog } from '../../app/actions';
import { setOfferEligibility } from '../account/modules/register/actions';
import { toggleAccountModal, logout } from '../../modules/account/actions';
import { accessibilitySettingsListener } from './modules/shared/accessibility/actions';
import { privacySettingsListener } from './modules/shared/privacy/actions';

const mapStateToProps = (state, ownProps) => {
  const { page } = queryString.parse(ownProps.location.search);
  const pageData = state.editor.data.alwaysData[page];
  const componentInfo =
    pageData &&
    (pageData.components || []).map(component => {
      if (state.editor.data.alwaysData[component]) {
        return state.editor.data.alwaysData[component];
      }
      return null;
    });

  const componentData = (componentInfo || []).map(data => {
    if (state.editor.data.lazyData[data.lazy_data]) {
      return state.editor.data.lazyData[data.lazy_data];
    }
    return null;
  });

  const appname = ownProps.match.params.appname;
  const moduleName = ownProps.location.pathname
    .replace(/(.*?\/|)editor(\/|$)/, '')
    .match(/(.*?)(?=\/|$)/);
  const subscriptions = state.account.subscriptions;

  const subscription = subscriptions && subscriptions.items[appname];
  const isWixUser =
    state.account.organisation &&
    state.account.organisation.integration &&
    state.account.organisation.integration.type === 'Wix';

  const hasPlan =
    subscription && subscription.plan && subscription.plan !== 'free';

  return {
    ...ownProps,
    splashSettings: {
      ..._get(state, `editor.design.${appname}.splash_screen`),
      icons: state.editor.data.iconData,
    },
    topBarColor: {
      ..._get(state, `editor.design.${appname}.components.Navigation.Topbar`),
    },
    edited:
      !!_get(state, 'editor.data.editionData.start') ||
      !!_get(state, 'editor.shared.appIcon.modified'),
    // external module
    user: state.account.auth.user,
    account: state.account.organisation,
    apps: state.account.organisation.apps,
    integration: state.account.organisation.integration,
    // pass the path of the current page so we can update preview as required
    // - empty string represents root path
    currentPath: page ? pageData && pageData.path : '',
    pageData,
    componentInfo,
    componentData,
    pageTitle: moduleName && moduleName[1],
    appname: ownProps.match.params.appname,
    subscription: state.app.appContext.subscription,
    isPublished: state.editor.data.isPublished[ownProps.match.params.appname],
    promotion: state.account.offer,
    offers: state.account.register.offers,
    offerNoticeSet: state.editor.offerNoticeSet,
    // Merging Toolbar in Page header
    appMenu: state.app.toolbar.appMenu,
    userMenu: state.app.toolbar.userMenu,
    profile: state.account.auth.profile,
    validatingApp: state.editor.validatingApp,
    hasPlan,
    isWixUser,
    buildSectionsAccess: state.account.profile.sectionsAccess.build,
    ...state.account.organisation.integration,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { page } = queryString.parse(ownProps.location.search);

  return {
    onPublish: () => {
      return dispatch(isAppValid(ownProps.match.params.appname)).then(
        appIsValid =>
          appIsValid &&
          dispatch(launchPublishDialog(ownProps.match.params.appname)),
      );
    },
    loadContent: () =>
      dispatch(loadContent(ownProps.match.params.appname, page)),
    addPageContentUpdateListner: (currentPage, prevPage) =>
      dispatch(
        addPageContentUpdateListner(
          ownProps.match.params.appname,
          currentPage,
          prevPage,
        ),
      ),
    removeListeners: () =>
      dispatch(removeListeners(ownProps.match.params.appname)),
    checkUsersSubscription: appName =>
      dispatch(checkUsersSubscription(appName)),
    addOfferNotice: (offer, account) =>
      dispatch(addOfferNotice(offer, account)),
    setOfferEligibility: (account, offer) =>
      setOfferEligibility(account, offer),
    noticeSet: () => dispatch(noticeSet()),

    // Merging Toolbar in Page header
    toggleAppMenu: open => dispatch(toggleAppMenu(open)),
    onAppChange: newApp =>
      dispatch(changeApp(newApp, ownProps.match.params.appname)),
    toggleUserMenu: open => dispatch(toggleUserMenu(open)),

    onLogout: () => {
      dispatch(toggleUserMenu(false));
      dispatch(logout());
    },
    onAccountSettingsTouchTap: () => {
      dispatch(toggleUserMenu(false));
      dispatch(toggleAccountModal());
    },
    onAddIsPublishedListener: () =>
      dispatch(addIsPublishedListener(ownProps.match.params.appname)),
    onRemoveIsPublishedListener: () =>
      dispatch(removeIsPublishedListener(ownProps.match.params.appname)),
    onDocumentationTouchTap: () => dispatch(toggleUserMenu(false)),
    accessibilitySettingsListener: (prevAppname, currentAppname) =>
      dispatch(accessibilitySettingsListener(prevAppname, currentAppname)),
    privacySettingsListener: (prevAppname, currentAppname) =>
      dispatch(privacySettingsListener(prevAppname, currentAppname)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorPreview);
