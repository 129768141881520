import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Material Imports
import AddIcon from 'material-ui/svg-icons/content/add';

import PageHeader from '../../app/components/pageHeader';
import { FloatingActionButton } from '../../app/components/buttons';

import PrivateRoute from '../../app/components/privateRoute';
import AccountsDropdown from '../editor/internals/accountsDropdown';
import Groups from './modules/Groups';
import Users from './modules/Users';
import { toggleFkAcademyGroupDialog } from './actions';

function FkAcademy({ match }) {
  const dispatch = useDispatch();
  // const { appname } = useParams();
  const { appname } = match.params;

  return (
    <>
      <div className="flex-container sidebar-extended background-texture">
        <PageHeader
          title="FkAcademy"
          userSettings={<AccountsDropdown />}
          actions={
            <div style={{ paddingRight: '10px' }}>
              <Route
                path="/:appname/fkacademy/groups"
                render={() => (
                  <div className="row middle-xs">
                    <div className="col">
                      <FloatingActionButton
                        icon={<AddIcon />}
                        onClick={() =>
                          dispatch(toggleFkAcademyGroupDialog(true))
                        }
                      />
                    </div>
                  </div>
                )}
              />
            </div>
          }
        />

        <Switch>
          <Redirect
            exact
            from={`/${appname}/fkacademy`}
            to={`/${appname}/fkacademy/groups`}
          />
          <PrivateRoute
            path="/:appname/fkacademy/groups"
            component={Groups}
            requiresUpgrade={false}
          />
          <PrivateRoute path="/:appname/fkacademy/users" component={Users} />
        </Switch>
      </div>
    </>
  );
}

export default withRouter(FkAcademy);
