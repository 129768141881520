import firebase from '../../../../../../../firebase';

const addingDelegatesItem = lazyDataId => ({
  type: 'ADDING_DELEGATES_ITEM',
  key: lazyDataId,
});

const reportErr = (lazyDataId, err) => ({
  type: 'DELEGATES_ITEM_ERROR',
  key: lazyDataId,
  err,
});

export const addDelegatesItem = (appname, dataId, title) => dispatch => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);
  const baseItem = {
    avatar:
      'https://s3.eu-west-2.amazonaws.com/beezer-img-storage/UploadImageForUser.png',
    subtitle: 'Subtitle',
    text: 'Description',
    company: 'Company',
    twitter: 'https://www.twitter.com',
    linkedin: 'https://www.linkedin.com',
    title,
  };

  dispatch(addingDelegatesItem(dataId));

  const itemRef = dataRef.child('items/chunks').push(baseItem);

  return dataRef
    .child('items/order')
    .transaction(items => {
      if (!items) return [itemRef.key];

      return items.concat(itemRef.key);
    })
    .catch(err => dispatch(reportErr(err)));
};

const findIndex = (array, element) => {
  if (element === null) {
    return array.length;
  }

  return array.indexOf(element);
};

const moveArray = (oldIndex, newIndex, arr) => {
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const rearrangeItems = (appname, lazydata, id, siblingId) => {
  const itemsRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${lazydata}/items/order`);

  itemsRef.transaction(array => {
    if (array === null) return null;

    const oIndex = findIndex(array, id);
    let nIndex = findIndex(array, siblingId);

    if (nIndex > oIndex) {
      nIndex -= 1;
    }

    return moveArray(oIndex, nIndex, array);
  });

  return {
    type: 'REARRANGE_DELEGATES_ITEM',
    id,
    siblingId,
  };
};
