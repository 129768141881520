import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import UpgradeButton from './UpgradeButton';
import { openWixPlans } from '../../../modules/account/modules/integration/actions';
import { addToDiyComponents } from '../../actions';
import { updateContact } from '../../../modules/account/modules/auth/login/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  integration: state.account.organisation.integration,
  subscription:
    state.account.subscriptions.items[ownProps.match.params.appname] || {},
  appname: ownProps.match.params.appname,
  history: ownProps.history,
  accountId: state.account.auth.profile.account,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
  isSubAdminWithMulipleApps: state.account.auth.profile.admin_appnames
    ? Object.keys(state.account.auth.profile.admin_appnames).length > 1
    : false,
  organisation: state.account.organisation,
  popupState: state.app.upgrade.visible,
  isProcessing: state.app.addons.isProcessing || false,
  intl: state.account.intl,
  isAppSumoAccount:
    (state.account.organisation.appsumo_id &&
      state.account.organisation.appsumo_id.length > 0) ||
    false,
  trialExpired:
    (state.account.organisation && state.account.organisation.trial_expired) ||
    true,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  openWixPlans: (appname, plan, history, accountId) =>
    dispatch(openWixPlans(appname, plan, history, accountId)),
  togglePopup: value => {
    dispatch({ type: 'TOGGLE_DIY_ADDON_PROMPT', value });
  },
  onAddonAdd: (type, component, currency) => {
    dispatch(
      addToDiyComponents(
        type,
        component,
        ownProps.match.params.appname,
        currency,
      ),
    ).then(() => {
      dispatch({ type: 'TOGGLE_DIY_ADDON_PROMPT', value: false });
      dispatch(
        updateContact({
          account: ownProps.accountId,
          appname: ownProps.match.params.appname,
          component_type: type,
        }),
      );
    });
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpgradeButton),
);
