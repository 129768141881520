import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { loadOffers } from './offer/actions';
import {
  saveOfferFromQueryString,
  fetchAppsumoUserDetail,
  getReferrerDetials,
} from './actions';
import { CircularProgress } from '../../../../app/components/progress';
import Container from '../../../../app/components/container';
import Offer, { OfferError } from './offer';
import Standard from './standard';

class RegisterWrapper extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    offers: PropTypes.object.isRequired,
    isFetchingOffers: PropTypes.bool.isRequired,
    onLoadOffers: PropTypes.func.isRequired,
    saveOfferFromQueryString: PropTypes.func.isRequired,
    offerFromQuery: PropTypes.string,
    appsumoUser: PropTypes.object,
  };

  static defaultProps = {
    offerFromQuery: null,
    appsumoUser: null,
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    let query = {};
    if (!urlParams) {
      query = queryString.parse(this.props.location.search);
    } else {
      query.offer = urlParams.get('offer');
      query.source = urlParams.get('source');
      query.id = urlParams.get('id');
    }

    this.props.onGetReferrerDetials();

    if (query.offer) {
      this.props.saveOfferFromQueryString(query.offer);
      this.props.onLoadOffers();
    }
    if (query.source === 'appsumo') {
      this.props.fetchAppsumoUserDetail(query.id);
    }
  }

  renderOffer = () => {
    const { offers, offerFromQuery, isFetchingOffers } = this.props;

    const offer = offers[offerFromQuery];
    const currTime = Date.now();

    if (isFetchingOffers) {
      return (
        <Container>
          <CircularProgress />
        </Container>
      );
    }

    if (offer === undefined) {
      return <OfferError expired={false} />;
    }

    if (
      offer.coupon === 'lifetime' &&
      offer.totalRedemptionUI >= offer.maxRedemptionUI
    ) {
      return <OfferError expired />;
    }

    if (currTime < offer.expires) {
      return <Offer offer={offer} offerId={offerFromQuery} />;
    }

    return <OfferError expired />;
  };

  renderContent = () => {
    const { offerFromQuery } = this.props;

    if (offerFromQuery) {
      return this.renderOffer();
    }

    return <Standard />;
  };

  render() {
    const content = this.renderContent();
    return <div>{content}</div>;
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  offers: state.account.register.offers,
  isFetchingOffers: state.account.register.isFetchingOffers,
  offerFromQuery: state.account.offer.offer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoadOffers: () => dispatch(loadOffers()),
  saveOfferFromQueryString: offer => dispatch(saveOfferFromQueryString(offer)),
  fetchAppsumoUserDetail: id => dispatch(fetchAppsumoUserDetail(id)),
  onGetReferrerDetials: () =>
    dispatch(
      getReferrerDetials(queryString.parse(ownProps.location.search).referrer),
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterWrapper),
);
