import i18n from 'i18next';
import firebase from '../../../../firebase';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';
import { PROFILE_DELETED } from './config';

export const REQUEST_STAFFS = 'REQUEST_STAFFS';
export const RECEIVE_STAFFS = 'RECEIVE_STAFFS';
export const REPORT_STAFFS_REQUEST_ERROR = 'REPORT_STAFFS_REQUEST_ERROR';
export const SELECT_STAFF = 'SELECT_STAFF';
export const FILTER_STAFF_DATA = 'FILTER_STAFF_DATA';
export const SELECT_ALL_STAFFS = 'SELECT_ALL_STAFFS';
export const DESELECT_ALL_STAFFS = 'DESELECT_ALL_STAFFS';
export const DELETE_VALIDATION_ERROR = 'DELETE_VALIDATION_ERROR';
export const STAFF_DELETE_ERROR = 'STAFF_DELETE_ERROR';
export const SEARCH_STAFFS = 'SEARCH_STAFFS';
export const STAFF_DELETED = 'STAFF_DELETED';
export const REQUEST_STAFFS_DELETE = 'REQUEST_STAFFS_DELETE';

const requestStaffs = () => ({
  type: REQUEST_STAFFS,
});

const requestStaffsDelete = () => ({
  type: REQUEST_STAFFS_DELETE,
});

export const selectStaff = key => ({
  type: SELECT_STAFF,
  key,
});

export const validationError = prop => ({
  type: DELETE_VALIDATION_ERROR,
  prop,
});

export const getStaffs = appname => dispatch => {
  dispatch(requestStaffs());
  const parent = firebase.auth().currentUser.uid;
  const userRef = firebase.database().ref('users');
  userRef.child(parent).on('value', snap => {
    const staffs = snap.val().staffs ? Object.keys(snap.val().staffs) : [];
    const result = {};

    Promise.all(
      staffs.map(async userID => {
        // Getting firstName, lastName, email of userID
        const { firstName, lastName, email, createdAt, account } = await userRef
          .child(userID)
          .once('value')
          .then(snapshot => ({
            firstName: snapshot.val().firstName,
            lastName: snapshot.val().lastName,
            email: snapshot.val().email,
            account: snapshot.val().account,
            createdAt: snapshot.val().createdAt,
          }));

        await firebase
          .database()
          .ref(`accounts/${account}/apps`)
          .child(appname)
          .once('value')
          .then(snapshot => {
            const data = snapshot.val();
            if (data !== null) {
              // Done Getting details of userID
              result[userID] = {
                firstName,
                lastName,
                email,
                createdAt,
              };
            }
          });
      }),
    ).then(() => {
      dispatch({
        type: RECEIVE_STAFFS,
        value: result,
      });
    });
  });
};

export const filterStaffData = filter => dispatch =>
  dispatch({
    type: FILTER_STAFF_DATA,
    payload: filter,
  });

export const selectAllStaffs = () => ({ type: SELECT_ALL_STAFFS });
export const deselectAllStaffs = () => ({ type: DESELECT_ALL_STAFFS });

export const reportActionError = (
  error,
  appname,
  selected,
  action,
) => dispatch =>
  dispatch({ type: STAFF_DELETE_ERROR, error, appname, selected, action });

const deleteUser = (appname, userID) =>
  new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;

    return user.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_STAFF_API}/delete`, {
        method: 'delete',
        mode: 'cors',
        body: JSON.stringify({
          appname,
          userID,
          user: user.uid,
          requestOrigin: 'platform',
        }),
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            return resolve(res);
          }

          throw new Error(
            i18n.t('Oops, something went wrong. Please try again.'),
          );
        })
        .catch(reject);
    });
  });

export const deleteStaffs = appname => (dispatch, getState) => {
  dispatch(requestStaffsDelete());
  const { selected } = getState().auth.staff.data;
  selected.map(key =>
    deleteUser(appname, key)
      .then(data => {
        dispatch({
          type: STAFF_DELETED,
          appname,
          data,
        });
        dispatch(openSnackbar(PROFILE_DELETED));
      })
      .catch(error => {
        dispatch(reportActionError(error));
      }),
  );
};
