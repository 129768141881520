import _map from 'lodash/map';

const initialState = [];

const billing = (state = initialState, action) => {
  switch (action.type) {
    case 'HYDRATE_BILLING':
      return _map(action.data, (invoice, key) => ({ ...invoice, key }));

    case 'LOGOUT_SUCCESS':
      return []
    default:
      return state;
  }
};

export default billing;
