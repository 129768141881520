import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Linkedin as LinkedinIcon,
  Google as GoogleIcon,
} from '../icons';
import { getAppUrl } from '../../../../utils/appDetails';

import './styles.css';

const SocialIcons = ({ match, customDomain }) => {
  const { t } = useTranslation();

  return (
    <div className="dist-pane">
      <div className="title-container">
        <h3>
          <Trans>
            Share your app directly on your social platforms so that your
            friends can access it
          </Trans>
        </h3>
        <p>
          <Trans>
            It is time to wave goodbye to the app store hassle. Simply click on
            one of the buttons below and you will share your app on the social
            media channel of your choice
          </Trans>
        </p>
      </div>
      <div className="social-buttons-container">
        <a
          className="social-button-link"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            getAppUrl(match.params.appname, customDomain),
          )}`}
        >
          <span className="social-icon facebook">
            <FacebookIcon />
          </span>
          <span className="social-title facebook">
            <Trans>Share on Facebook</Trans>
          </span>
        </a>
        <a
          className="social-button-link"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.linkedin.com/cws/share?url=${getAppUrl(
            match.params.appname,
            customDomain,
            false,
          )}`}
        >
          <span className="social-icon linkedin">
            <LinkedinIcon />
          </span>
          <span className="social-title linkedin">
            <Trans>Share on Linkedin</Trans>
          </span>
        </a>
        <a
          className="social-button-link"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://twitter.com/intent/tweet?text=${t(
            'Check out our awesome ap',
          )}' @ &url=${encodeURIComponent(
            getAppUrl(match.params.appname, customDomain),
          )}`}
        >
          <span className="social-icon twitter">
            <TwitterIcon />
          </span>
          <span className="social-title twitter">
            <Trans>Share on Twitter</Trans>
          </span>
        </a>
      </div>
    </div>
  );
};
SocialIcons.propTypes = {
  match: PropTypes.object.isRequired,
  customDomain: PropTypes.object,
};

SocialIcons.defaultProps = {
  customDomain: {},
};

export default withRouter(SocialIcons);
