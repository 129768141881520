import { newValidator } from '../../../../../../../utils';
import { appTemplates } from '../../../../../../../config';

const initialState = {
  pending: false,
  error: null,
  allTemplates: appTemplates,
  filteredAppTemplates: appTemplates,
  title: {
    value: '',
    isValid: null,
    maxLength: 42,
    required: true,
  },
  domain: {
    value: '',
    isValid: null,
    isAvailable: null,
    pending: null,
    type: 'domain',
    maxLength: 21,
    required: true,
  },
  skin: {
    type: 'cafe',
    content: 'cafe',
  },
  steps: [
    {
      title: 'Select Skin',
      subtext: 'You’re just about ready to start building your app.',
      isValid: true,
    },
    {
      title: 'App Name',
      subtext:
        'Choose a name for your Mobile App (App Title) and, a web address for your Mobile App (Domain Name). These will be visible to your App users.',
      isValid: false,
    },
  ],
};

const validateTitle = newValidator(initialState.title);

const getSteps = state => [
  {
    title: 'Select Skin',
    isValid: state.skin !== null,
    subtext: 'You’re just about ready to start building your app.',
  },
  {
    title: 'App Name',
    isValid:
      state.domain.isValid &&
      state.domain.isAvailable &&
      !state.domain.pending &&
      state.title.isValid,
    subtext:
      'Choose a name for your Mobile App (App Title) and, a web address for your Mobile App (Domain Name). These will be visible to your App users.',
  },
];

const getFilteredTemplates = searchText => {
  let filteredTemplates = {};
  Object.keys(initialState.allTemplates).forEach(appTemplateId => {
    if (
      initialState.allTemplates[appTemplateId].label
        .toLowerCase()
        .includes(searchText.toLowerCase())
    ) {
      filteredTemplates = {
        ...filteredTemplates,
        [appTemplateId]: initialState.allTemplates[appTemplateId],
      };
    }
  });
  return filteredTemplates;
};
const createApp = (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_APP_SKIN_CHANGE': {
      const nextState = {
        ...state,
        skin: action.value,
      };

      return {
        ...nextState,
        steps: getSteps(nextState),
      };
    }

    case 'CREATE_APP_TITLE_CHANGE': {
      const nextState = {
        ...state,
        title: {
          ...state.title,
          value: action.value,
          isValid: validateTitle(action.value),
        },
      };

      return {
        ...nextState,
        steps: getSteps(nextState),
      };
    }

    case 'CREATE_APP_DOMAIN_CHANGE': {
      const nextState = {
        ...state,
        domain: {
          ...state.domain,
          value: action.value,
          isValid: action.isValid,
          // validation occurs in action to prevent unnecessary domain checks
        },
      };

      return {
        ...nextState,
        steps: getSteps(nextState),
      };
    }

    case 'CHECKING_DOMAIN_AVAILABILITY':
      return {
        ...state,
        domain: {
          ...state.domain,
          pending: true,
        },
      };

    case 'ASSERT_DOMAIN_AVAILABILITY': {
      const nextState = {
        ...state,
        domain: {
          ...state.domain,
          isAvailable: action.value,
          pending: false,
        },
      };

      return {
        ...nextState,
        steps: getSteps(nextState),
      };
    }

    case 'CREATING_APP':
      return {
        ...state,
        pending: true,
        error: null,
      };

    case 'CREATE_APP_ERROR':
      return {
        ...state,
        pending: false,
        error: action.err,
      };

    case 'FILTER_TEMPLATES':
      let newFilteredTemplates = getFilteredTemplates(action.value);
      return {
        ...state,
        filteredAppTemplates: newFilteredTemplates,
      };

    case 'RESET_APP_FORM':
      return initialState;

    default:
      return state;
  }
};

export default createApp;
