import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CreditCard from '../../components/creditCard';
import CreditCardForm from '../../components/creditCardForm';
import Billing from './Billing';
import ErrorMessage from '../../../../app/components/errorMessage';

import { getBilling } from './actions';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  handleFormChange,
  getDefaultCard,
  useNewCard,
  useDefaultCard,
  saveCard,
  removeExistingCard,
} from '../payment/actions';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

class BillingInterface extends Component {
  static propTypes = {
    apps: PropTypes.object,
    account: PropTypes.object,
    payment: PropTypes.object.isRequired,
    billing: PropTypes.array.isRequired,
    addNewCard: PropTypes.func.isRequired,
    removeCard: PropTypes.func.isRequired,
    useExistingCard: PropTypes.func.isRequired,
    handleCardChange: PropTypes.func.isRequired,
    getDefaultCard: PropTypes.func.isRequired,
    getBilling: PropTypes.func.isRequired,
    saveCardDetails: PropTypes.func.isRequired,
    intl: PropTypes.shape({
      currency: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    apps: {},
    account: {},
  };

  componentDidMount() {
    this.props.getDefaultCard();
    this.props.getBilling();
  }

  render() {
    const {
      apps,
      payment,
      billing,
      addNewCard,
      removeCard,
      useExistingCard,
      handleCardChange,
      saveCardDetails,
      intl,
      subscription,
      lastRedeemedCoupon,
      account,
    } = this.props;

    return (
      <Elements stripe={stripePromise}>
        <Billing
          apps={apps}
          billing={billing}
          intl={intl}
          subscription={subscription}
          lastRedeemedCoupon={lastRedeemedCoupon}
          account={account}
        >
          {payment.useDefaultCard && (
            <CreditCard
              {...payment.defaultCard}
              onAddCard={addNewCard}
              onRemoveCard={removeCard}
            />
          )}
          {!payment.useDefaultCard && (
            <CreditCardForm
              {...payment}
              onChange={handleCardChange}
              useExistingCard={
                payment.hasDefaultCard ? useExistingCard : undefined
              }
              onSubmit={saveCardDetails}
            />
          )}
          {this.props.payment.error && (
            <ErrorMessage>{this.props.payment.error}</ErrorMessage>
          )}
        </Billing>
      </Elements>
    );
  }
}

const mapStateToProps = state => ({
  apps: state.account.organisation.apps,
  account: state.account.organisation,
  payment: state.account.payment,
  billing: state.account.billing,
  intl: state.account.intl,
  subscription: state.account.subscriptions.items[state.app.appContext.appname],
  lastRedeemedCoupon: state.account.organisation.lastRedeemedCoupon,
});

const mapDispatchToProps = dispatch => ({
  handleCardChange: (prop, value, error) =>
    dispatch(handleFormChange(prop, value, error)),
  getDefaultCard: () => dispatch(getDefaultCard()),
  addNewCard: () => dispatch(useNewCard()),
  removeCard: () => dispatch(removeExistingCard()),
  useExistingCard: () => dispatch(useDefaultCard()),
  getBilling: () => dispatch(getBilling()),
  saveCardDetails: stripe => dispatch(saveCard(stripe)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingInterface);
