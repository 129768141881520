import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { whitelabeledHost } from '../../../utils/whitelabeledHost';
import Lightening from './icons/lightening';
import './styles.css';

const isWhiteLabelledHost = whitelabeledHost();

const PageHeader = props => {
  const {
    title,
    actions,
    preAction,
    expert,
    userSettings,
    isWixUser,
    history,
    hideTitle,
  } = props;
  const isAppAdmin = useSelector(
    state => state.account.auth.profile.admin_appname || false,
  );

  const pathname =
    (history && history.location && history.location.pathname) || undefined;

  return (
    <div className="page-header">
      <div className="inner-container">
        {expert || null}
        {isWixUser ? (
          <>
            {pathname &&
              !(
                pathname.includes(
                  '/wixstagingtest/editor/customise/app_name',
                ) ||
                pathname.includes(
                  '/wixstagingtest/editor/customise/app_icon',
                ) ||
                pathname.includes(
                  '/wixstagingtest/editor/customise/app_splash_screen',
                ) ||
                pathname.includes(
                  '/wixstagingtest/editor/customise/navigation_menu',
                )
              ) && (
                <div className="logo-container">
                  {!isAppAdmin && !isWhiteLabelledHost && (
                    <Lightening className="lightening-logo" />
                  )}
                  <h1 className="page-title">{title}</h1>
                  {preAction}
                </div>
              )}
          </>
        ) : (
          <div className="logo-container">
            {!hideTitle && (
              <>
                {' '}
                {!isAppAdmin && !isWhiteLabelledHost && (
                  <Lightening className="lightening-logo" />
                )}
                <h1 className="page-title">{title}</h1>
              </>
            )}

            {preAction}
          </div>
        )}

        {/* <div className="logo-container">
          {!isAppAdmin &&
            !isWhiteLabelledHost &&
            title &&
            !title.includes('customise') && (
              <Lightening className="lightening-logo" />
            )}
          <h1 className="page-title">
            {title && title.includes('customise') ? '' : title}
          </h1>
          {preAction}
        </div> */}

        <div className="flex-row flex-align-center">
          {actions}
          {userSettings ? (
            <div className="toolbar-group-right">
              <span className="seperator" />
              {userSettings}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  actions: PropTypes.node,
  hideTitle: PropTypes.bool,
};

PageHeader.defaultProps = {
  actions: null,
  hideTitle: false,
};

export default PageHeader;
