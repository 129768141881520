import { connect } from 'react-redux';

import Canva from './Canva';
import { fieldChange, handleDesignChange } from './actions';

const mapStateToProps = (state, ownProps) => {
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];

  return {
    ...ownProps,
    googleAnalytics: state.app.googleAnalytics,
    pages: layout
      ? layout.childRoutes.map(key => state.editor.data.alwaysData[key])
      : [],
    design: ownProps.component.design,
    layoutType: ownProps.component.layout,
    appname: ownProps.match.params.appname,
    isAppAdmin:
      (state.account.auth.profile.admin_appname &&
        state.account.auth.profile.admin_appname.length > 0) ||
      false,
    isSubAdminWithMulipleApps:
      (state.account.auth.profile.admin_appnames &&
        Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
      false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onDesignChange: property => (type, value) =>
    dispatch(
      handleDesignChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        type,
        value,
        property,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Canva);
