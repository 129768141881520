import React, { useEffect } from 'react';
import ActionMenu from 'app/components/actionMenu';
import Checkbox from '@material-ui/core/Checkbox';
import ResultsTable from 'app/components/resultsTable';
import TryAgain from 'app/components/tryAgain';
import { sortArray } from 'utils';
import TableCheckbox from '../../../memberLogin/components/tableCheckbox';
import Header from '../../../memberLogin/components/header';
import themeStyles from '../../../../_export.scss';
import PlMatchSupportModal from './PlMatchSupportModal';
import './styles.css';

const isCheckboxDisabled = (error, pending) => error && !pending;

const PlMatchSupport = props => {
  const {
    plMatchSupportData,
    toggleMatchSupportModal,
    match: {
      params: { appname },
    },
    onFieldChangeWithUniqueCheck,
    onFieldChange,
    onSubmit,
    getPlMatchSupportCentre,
    onFilterMatchSupportData,
    onSelectAllMatchCentres,
    onDeselectAllMatchCentres,
    onDeleteSelected,
    onSelect,
    onUpdatePlMatchSupportCentre,
    onTogglePlSnackbar,
  } = props;

  const {
    selected,
    chunks,
    error,
    filteredChunks,
    pending,
    items,
    selectAll,
    filter,
  } = plMatchSupportData;

  useEffect(() => {
    getPlMatchSupportCentre();
  }, []);

  const getTitle = (centreSelected, PlSupportCentres) => (
    <span>
      PL Match Day Support:{' '}
      {centreSelected.length
        ? `${centreSelected.length} / ${Object.keys(PlSupportCentres).length}`
        : Object.keys(PlSupportCentres).length}
    </span>
  );

  const getSortedItems = () => {
    let sortedItems = sortArray(Object.entries(filteredChunks), 'location');
    sortedItems = sortedItems.map(entity => entity[0]);
    return sortedItems;
  };

  const getSelectAllCheckBox = () => {
    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllMatchCentres() : onDeselectAllMatchCentres()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          text={'plMatchSupportCentres'}
          customDeleteText="Are you sure you wish to delete?"
        />
      </div>
    );
  };

  const body = error ? (
    <TryAgain
      message="Oops, something went wrong..."
      onRetry={getPlMatchSupportCentre}
    />
  ) : (
    <ResultsTable
      items={getSortedItems()}
      chunks={filteredChunks}
      loading={pending}
      pending={pending}
      error={Object.keys(filteredChunks).length === 0 ? 'yes' : null}
      emptyMessage={`Sorry, no results found.`}
      onRowTouchTap={rowId =>
        onUpdatePlMatchSupportCentre(rowId, filteredChunks[rowId])
      }
      pgmolTable
      columns={[
        {
          id: 'location',
          header: 'Club',
          classNames: 'col-xs-3 pl-table-text-left',
        },
        {
          id: 'hotel',
          header: 'Hotel',
          classNames: 'col-xs-5 pl-table-text-left',
          containerElement: ({ value }) => (
            <pre className="pl-match-support-hotel-address">{value}</pre>
          ),
        },
        {
          id: 'therapist',
          header: 'Therapist',
          classNames: 'col-xs-3 pl-table-text-left',
        },

        {
          id: 'selected',
          header: getSelectAllCheckBox(),
          classNames: `col-xs-1 ${items.length < 4 ? 'fix-left' : 'selected'}`,
          containerElement: ({ value, rowId }) =>
            rowId !== 'default' && (
              <>
                <TableCheckbox value={value} onSelect={() => onSelect(rowId)} />
                <ActionMenu
                  selected={[rowId]}
                  onDeleteSelected={() => onDeleteSelected([rowId], chunks)}
                  onEditSelected={() =>
                    onUpdatePlMatchSupportCentre(rowId, filteredChunks[rowId])
                  }
                  text={'plMatchSupoortCentre'}
                  customDeleteText="Are you sure you wish to delete?"
                />
              </>
            ),
        },
      ]}
    />
  );

  return (
    <section
      style={{
        height: '80vh',
        overflow: 'hidden',
      }}
    >
      <Header
        {...plMatchSupportData}
        title={getTitle(selected, chunks)}
        onChange={onFilterMatchSupportData}
        onSelectAllResponses={onSelectAllMatchCentres}
        onDeselectAllResponses={onDeselectAllMatchCentres}
        actionMenu={
          <ActionMenu
            selected={selected}
            onDeleteSelected={() => onDeleteSelected(selected, chunks)}
            text={'plMatchSupportCentre'}
            customDeleteText="Are you sure you wish to delete?"
          />
        }
        fullWidthHeader
      />
      <div className="pgmol-table-container">{body}</div>
      {plMatchSupportData.modalOpen && (
        <PlMatchSupportModal
          data={plMatchSupportData}
          toggleMatchSupportModal={toggleMatchSupportModal}
          appname={appname}
          onFieldChangeWithUniqueCheck={onFieldChangeWithUniqueCheck}
          onFieldChange={onFieldChange}
          onSubmit={onSubmit}
          onTogglePlSnackbar={onTogglePlSnackbar}
        />
      )}
    </section>
  );
};

export default PlMatchSupport;
