import i18n from 'i18next';
import firebase from '../../../../firebase';

import {
  submitPayment,
  submitCardDetails,
  reportPaymentIntentCreated,
  reportPaymentError,
} from '../payment/actions';
import smsBundles from '../../../../plans/smsBundles';

export const setAmount = (query = {}, integration = null) => {
  const prices =
    smsBundles[query.bundle] && smsBundles[query.bundle].prices.total;
  const exactCost = prices && integration ? prices.USD : prices.GBP;
  const cost = Math.round(exactCost);

  switch (query.bundle) {
    case 'small':
    default:
      return {
        type: 'SET_SMS_BUY_AMOUNT',
        amount: 100,
        cost,
        bundle: query.bundle,
      };

    case 'medium':
      return {
        type: 'SET_SMS_BUY_AMOUNT',
        amount: 250,
        cost,
        bundle: query.bundle,
      };

    case 'large':
      return {
        type: 'SET_SMS_BUY_AMOUNT',
        amount: 500,
        cost,
        bundle: query.bundle,
      };

    case 'extraLarge':
      return {
        type: 'SET_SMS_BUY_AMOUNT',
        amount: 1000,
        cost,
        bundle: query.bundle,
      };

    case 'gigantic':
      return {
        type: 'SET_SMS_BUY_AMOUNT',
        amount: 10000,
        cost,
        bundle: query.bundle,
      };
  }
};

export const createPaymentIntent = (stripe, cost, metadata = {}) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const account = state.account.organisation;
  const { payment, auth } = state.account;
  const card =
    state.account.organisation.cards && state.account.organisation.cards[0];
  dispatch(submitPayment());

  // response will be present if a card is being submitted with the request
  const handlePayment = response => {
    const myHeaders = new Headers();
    const payload = {
      amount: cost * 100, // convert from pounds to pence
      account: account.key,
      appname: auth.profile.lastVisited,
      paymentIntentDescription: 'Beezer SMS Bundle',
      card,
      metadata: {
        appname: auth.profile.lastVisited,
        accountId: account.key,
        ...metadata,
      },
    };

    if (response) {
      payload.token = response.id;
      payload.card = response.card;
    }

    return firebase
      .auth()
      .currentUser.getIdToken()
      .then(token => {
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('x-access-token', token);

        return fetch(
          `${process.env.REACT_APP_PAYMENT_API}/create_payment_intent`,
          {
            method: 'post',
            headers: myHeaders,
            body: JSON.stringify(payload),
            mode: 'cors',
          },
        );
      })
      .then(async res => {
        if (res.status === 200) {
          const paymentIntent = await res.json();
          dispatch(reportPaymentIntentCreated(paymentIntent));
        } else {
          dispatch(
            reportPaymentError(i18n.t('Failed to Process, please try again')),
          );
        }
      });
  };

  if (payment.useDefaultCard) {
    return handlePayment().catch(error =>
      dispatch(reportPaymentError(error.message)),
    );
  }

  return dispatch(submitCardDetails(stripe))
    .then(handlePayment)
    .catch(error => dispatch(reportPaymentError(error.message)));
};
