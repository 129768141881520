const initialState = {
  pending: false,
  isDirty: false,
  message: null,
  name: {
    value: '',
  },
  organisation: {
    value: '',
  },
  city: {
    value: '',
  },
  country: {
    value: '',
  },
  workfield: {
    value: null,
  },
  created_at: '',
  default_email: '',
  sectionsAccess: {
    build: true,
    distribute: true,
    engage: true,
    analytics: true,
    member_login: true,
    business_tools: true,
  },
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case 'HYDRATE_PROFILE':
      return {
        ...state,
        ...action.data,
        name: {
          // extend name field for validation
          value: action.data.name || '',
        },
      };
    // organisation, city and country is current related to account
    // rather than profile which contains the user's name
    case 'HYDRATE_ACCOUNT':
      return {
        ...state,
        organisation: {
          value: action.data.organisation || '',
        },
        city: {
          value: action.data.city || '',
        },
        country: {
          value: action.data.country || '',
        },
        workfield: {
          value: action.data.workfield || null,
        },
        created_at: action.data.created_at || '',
      };

    case 'SAVE_PROFILE_CHANGES':
      return {
        ...state,
        pending: true,
      };

    case 'PROFILE_UPDATED':
      return {
        ...state,
        isDirty: false,
        pending: false,
      };

    case 'UPDATE_PROFILE_ERROR':
      return {
        ...state,
        pending: false,
        error: action.err,
      };

    case 'PROFILE_FIELD_CHANGE':
      return {
        ...state,
        isDirty: true,
        [action.prop]: {
          value: action.value,
          isDirty: true,
        },
      };

    // To reset profile data once user sign off
    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default profile;
