import _debounce from 'lodash/debounce';
import firebase from '../../../../../../../firebase';

const isValidUrl = value => /^https:\/\/store[0-9]{8}\.ecwid\.com$/.test(value);

const isValidId = value => /^[0-9]{8}$/.test(value);

const updateIfValid = (dataRef, value) => {
  if (isValidUrl(value)) {
    const id = value.match(/[0-9]{8}/);

    dataRef.child('storeId').set(id[0]);
  } else if (isValidId(value)) {
    dataRef.child('storeId').set(value);
  } else {
    dataRef.child('storeId').set(null);
  }
};

const debouncedUpdate = _debounce(updateIfValid, 800, { leading: false });

export const storeIdChange = (appname, alwaysDataId, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${alwaysDataId}`);

  dataRef.child('rawId').set(value);

  debouncedUpdate(dataRef, value.trim());

  return {
    type: 'ECWID_STORE_ID_CHANGE',
    value,
  };
};
