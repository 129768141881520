import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Logo from '../../../components/logo';
import '../styles.css';

class WeeblyWixToolbar extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    lastVisited: PropTypes.string,
    displayLogo: PropTypes.bool,
    history: PropTypes.object.isRequired,
    onExit: PropTypes.func,
  };

  static defaultProps = {
    title: null,
    lastVisited: null,
    displayLogo: true,
    onExit: undefined,
  };

  onExit = () => {
    const { onExit } = this.props;

    if (onExit) {
      return onExit();
    }

    return this.props.history.goBack();
  };

  getLogoLink = () => {
    if (!this.props.isAuthenticated) {
      return '/';
    }

    if (!this.props.lastVisited) {
      return '/create';
    }

    return `/${this.props.lastVisited}/editor`;
  };

  render() {
    const { displayLogo } = this.props;
    return (
      <section className="toolbar simple-toolbar transparent">
        <div className="toolbar-group">
          <div className="logo-container">
            {displayLogo && (
              <Link to={this.getLogoLink()}>
                <Logo width={90} />
              </Link>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default WeeblyWixToolbar;
