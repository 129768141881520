import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { objectToArray } from '../../../../../../utils';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import { get } from 'lodash';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

const Vendor = ({ deselectVendor, vendor }) => {
  return (
    <Styled.vendorContainer onClick={deselectVendor}>
      <Styled.vendorInnerContainer onClick={e => e.stopPropagation()}>
        <IconButton
          style={{ position: 'absolute', right: '0.1em', top: '0.1em' }}
          onClick={deselectVendor}
          aria-label="close"
          size="medium"
        >
          <CloseIcon />
        </IconButton>
        <Styled.vendorTitle>{`${get(vendor.value, 'firstName', 'N/A')} ${get(
          vendor.value,
          'lastName',
          'N/A',
        )}`}</Styled.vendorTitle>
        <Styled.vendorImageContainer>
          {vendor.value.photoURL ? (
            <Styled.vendorImage
              src={get(vendor.value, 'photoURL', '')}
            ></Styled.vendorImage>
          ) : (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Trans>No profile image added</Trans>
            </div>
          )}
        </Styled.vendorImageContainer>
        <Styled.vendorDetails>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Styled.field>
                <Styled.fieldTitle>
                  <Trans>User Name</Trans>:
                </Styled.fieldTitle>
                <Styled.fieldContent>
                  {get(vendor.value, 'userName', 'N/A')}
                </Styled.fieldContent>
              </Styled.field>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Styled.field>
                <Styled.fieldTitle>
                  <Trans>Email</Trans>:
                </Styled.fieldTitle>
                <Styled.fieldContent>
                  {get(vendor.value, 'email', 'N/A')}
                </Styled.fieldContent>
              </Styled.field>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Styled.field>
                <Styled.fieldTitle>
                  <Trans>Phone Number</Trans>:
                </Styled.fieldTitle>
                <Styled.fieldContent>
                  {get(vendor.value, 'phoneNumber', 'N/A')}
                </Styled.fieldContent>
              </Styled.field>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Styled.field>
                <Styled.fieldTitle>
                  <Trans>Address</Trans>:
                </Styled.fieldTitle>
                <Styled.fieldContent>
                  {`${get(vendor.value, 'postalAddress', 'N/A')} ${get(
                    vendor.value,
                    'postCode',
                    'N/A',
                  )}`}
                </Styled.fieldContent>
              </Styled.field>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Styled.field>
                <Styled.fieldTitle>
                  <Trans>Type</Trans>:
                </Styled.fieldTitle>
                <Styled.fieldContent>
                  {vendor.value.filter.name}
                </Styled.fieldContent>
              </Styled.field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Styled.field>
                <Styled.fieldTitle>
                  <Trans>Type Description</Trans>:
                </Styled.fieldTitle>
                <Styled.fieldContent>
                  {vendor.value.filter.description}
                </Styled.fieldContent>
              </Styled.field>
            </Grid>
            {vendor.value.filter &&
              objectToArray(vendor.value.filter.fields).map(field => (
                <Grid item xs={12} sm={6} key={field.id}>
                  <Styled.field>
                    <Styled.fieldTitle>{field.value.name}:</Styled.fieldTitle>
                    <Styled.fieldContent>
                      {field.value.data.value}
                    </Styled.fieldContent>
                  </Styled.field>
                </Grid>
              ))}
            <Grid item xs={12} sm={6}>
              <Styled.field>
                <Styled.fieldTitle>
                  <Trans>Joined On</Trans>:
                </Styled.fieldTitle>
                <Styled.fieldContent>
                  {moment(vendor.created_at).format('DD-MM-YYYY')}
                </Styled.fieldContent>
              </Styled.field>
            </Grid>
          </Grid>
        </Styled.vendorDetails>
      </Styled.vendorInnerContainer>
    </Styled.vendorContainer>
  );
};

Vendor.propTypes = {
  deselectVendor: PropTypes.func.isRequired,
  vendor: PropTypes.object.isRequired,
};

Vendor.defaultProps = {
  vendor: {},
};
export default Vendor;
