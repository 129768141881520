import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Close from 'material-ui/svg-icons/navigation/close';

const Div = styled.div`
  &,
  p {
    color: #000;
    text-align: center;
  }

  p {
    font-size: 16px;
    margin: 1em auto;
    max-width: 480px;
  }

  hr {
    border: none;
    border-top: 2px dashed #6c6c6c;
    margin: 0 40px;
  }

  .close-notice {
    position: absolute;
    right: 24px;
    top: 24px;

    svg {
      vertical-align: middle;
    }
  }
`;

const Standard = ({ children, content, onRequestClose, title }) => (
  <Div>
    <h1>{title}</h1>
    <span className="close-notice" onClick={onRequestClose}>
      <Close />
    </span>
    <div dangerouslySetInnerHTML={{ __html: content }} />
    {children}
  </Div>
);

Standard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  content: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

Standard.defaultProps = {
  children: null,
  content: '',
};

export default Standard;
