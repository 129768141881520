import React from 'react';

import './styles.css';

const QR = () => (
  <svg
    className="icon qr"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39.5 39.5"
  >
    <title>QR Code</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M35.75,0l-32,0h0A3.77,3.77,0,0,0,0,3.75l0,32a3.77,3.77,0,0,0,3.75,3.76l32,0h0a3.77,3.77,0,0,0,3.76-3.75l0-32A3.77,3.77,0,0,0,35.75,0ZM38,35.75A2.26,2.26,0,0,1,35.71,38h0l-32,0A2.26,2.26,0,0,1,1.5,35.71l0-32A2.26,2.26,0,0,1,3.79,1.5h0l32,0A2.26,2.26,0,0,1,38,3.79Z"
        />
        <polygon
          class="cls-1"
          points="16.27 6.71 32.8 6.72 32.79 22.84 34.29 22.84 34.31 5.22 16.27 5.21 16.27 6.71"
        />
        <polygon
          class="cls-1"
          points="6.71 16.58 5.21 16.58 5.19 34.27 22.91 34.29 22.91 32.79 6.7 32.78 6.71 16.58"
        />
        <path
          class="cls-1"
          d="M14.46,5.19H5.19v9.27h9.27ZM13,13H6.69V6.69H13Z"
        />
        <rect class="cls-1" x="8.53" y="8.53" width="2.59" height="2.59" />
        <path
          class="cls-1"
          d="M25,34.31h9.27V25.06H25Zm1.51-7.76H32.8v6.26H26.52Z"
        />
        <rect
          class="cls-1"
          x="28.37"
          y="28.39"
          width="2.59"
          height="2.59"
          transform="translate(-0.06 59.31) rotate(-89.94)"
        />
        <rect class="cls-1" x="8.53" y="19" width="22.43" height="1.5" />
        <rect class="cls-1" x="8.53" y="16.41" width="22.43" height="1.5" />
        <rect class="cls-1" x="8.53" y="21.59" width="22.43" height="1.5" />
        <rect class="cls-1" x="8.53" y="24.18" width="14.67" height="1.5" />
        <rect class="cls-1" x="8.53" y="26.77" width="14.67" height="1.5" />
        <rect class="cls-1" x="8.53" y="29.35" width="14.67" height="1.5" />
        <rect class="cls-1" x="16.3" y="8.65" width="14.67" height="1.5" />
        <rect class="cls-1" x="16.3" y="11.23" width="14.67" height="1.5" />
        <rect class="cls-1" x="16.3" y="13.82" width="14.67" height="1.5" />
      </g>
    </g>
  </svg>
);

export default QR;
