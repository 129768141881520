import React from 'react';

import './styles.css';

const UsersIcon = () => (
  <svg
    className="icon user-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22.58 33.77"
  >
    <title>Users</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M14.47,21.47a3.2,3.2,0,1,0-6.39,0v1.8h-3v10.5h12.5V23.27H14.47Zm-4.89,0a1.7,1.7,0,1,1,3.39,0v1.8H9.58Zm6.5,3.3v7.5H6.58v-7.5h9.5Z"
        />
        <path
          className="cls-1"
          d="M15.26,11.17a6.29,6.29,0,1,0-7.94,0,9.19,9.19,0,0,0-7.32,9v4.9H1.5v-4.9a7.69,7.69,0,0,1,7.68-7.68h1a6.55,6.55,0,0,0,2.28,0h1a7.68,7.68,0,0,1,7.68,7.68v4.9h1.5v-4.9A9.18,9.18,0,0,0,15.26,11.17ZM6.5,6.29A4.79,4.79,0,1,1,12.29,11h-2A4.81,4.81,0,0,1,6.5,6.29Z"
        />
      </g>
    </g>
  </svg>
);

export default UsersIcon;
