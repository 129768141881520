import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Popup from './Popup';
import { designChange, fieldChange } from './actions';

const mapStateToProps = (state, ownProps) => {
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];

  return {
    ...ownProps,
    pages: layout
      ? layout.childRoutes.map(key => state.editor.data.alwaysData[key])
      : [],
    design: ownProps.component.design,
    appname: ownProps.match.params.appname,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onDesignChange: (field, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        field,
        value,
      ),
    ),
  onButtonDesignChange: (type, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        `buttonDesign/${type}`,
        value,
      ),
    ),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Popup),
);
