import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import * as svgIcons from './icons';

const LinkButton = ({ onClick, type }) =>
  svgIcons[type] ? (
    <IconButton onClick={onClick} style={{ width: 58, height: 58 }}>
      {svgIcons[type]()}
    </IconButton>
  ) : null;

LinkButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default LinkButton;
