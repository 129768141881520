import Firebase from 'firebase';
import firebase from '../../../../firebase';

export const fieldChange = (prop, value) => ({
  type: 'RESET_PASSWORD_FIELD_CHANGE',
  prop,
  value,
});

const resetPasswordError = err => ({
  type: 'RESET_PASSWORD_ERROR',
  err,
});

const invalidateField = prop => ({
  type: 'INVALIDATE_RESET_PASSWORD_FIELD',
  prop,
});

const reportSuccess = () => ({
  type: 'RESET_PASSWORD_SUCCESS',
});

export const clearForm = () => ({
  type: 'CLEAR_RESET_PASSWORD_FORM',
});

export const submitResetPassword = () => (dispatch, getState) => {
  const {
    oldPassword,
    newPassword,
    confirmPassword,
  } = getState().account.resetPassword;

  dispatch({ type: 'SUBMIT_RESET_PASSWORD' });

  if (oldPassword.value === '') {
    return dispatch(invalidateField('oldPassword'));
  }

  if (newPassword.value === '') {
    return dispatch(invalidateField('newPassword'));
  }

  if (
    confirmPassword.value === '' ||
    newPassword.value !== confirmPassword.value
  ) {
    return dispatch(invalidateField('confirmPassword'));
  }

  const user = firebase.auth().currentUser;
  const credential = Firebase.auth.EmailAuthProvider.credential(
    user.email,
    oldPassword.value,
  );

  return (
    user
      .reauthenticateWithCredential(credential)
      .then(() => user.updatePassword(newPassword.value))
      // TODO: send email to verify
      .then(() => dispatch(reportSuccess()))
      .catch(err => dispatch(resetPasswordError(err)))
  );
};
