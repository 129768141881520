import firebase from '../../../../../firebase';
import {
  RECEIVED_ACCESSIBILITY_SETTINGS,
  UPDATED_ACCESSIBILITY_SETTINGS,
} from './types';

export const receivedAccessibilitySettings = settings => ({
  type: RECEIVED_ACCESSIBILITY_SETTINGS,
  payload: settings,
});

export const updatedAccessibilitySettings = () => ({
  type: UPDATED_ACCESSIBILITY_SETTINGS,
});

export const updateAccessibilitySettings = (
  appname,
  field,
  value,
) => dispatch => {
  if (field === 'rtlSupport' && value) {
    value = {
      [field]: value,
      forceRTLSupport: false,
    };
  } else if (field === 'forceRTLSupport' && value) {
    value = {
      [field]: value,
      rtlSupport: false,
    };
  } else {
    value = {
      [field]: value,
    };
  }
  firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/accessibility`)
    .update(value)
    .then(() => dispatch(updatedAccessibilitySettings()));
};

export const accessibilitySettingsListener = (
  prevAppname,
  currentAppname,
) => dispatch => {
  firebase
    .database()
    .ref(`apps/${prevAppname}/draft/always_data/accessibility`)
    .off('value');
  firebase
    .database()
    .ref(`apps/${currentAppname}/draft/always_data/accessibility`)
    .on('value', snapshot => {
      // console.log(snapshot.val());
      if (snapshot.exists()) {
        dispatch(receivedAccessibilitySettings(snapshot.val()));
      }
    });
};
