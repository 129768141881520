import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';

import './styles.css';

const BeezerCheckbox = ({ id, checked, isValid, onChange, label }) => {
  const className =
    isValid === false ? 'checkbox-container invalid' : 'checkbox-container';

  return (
    <label htmlFor={id} className={className}>
      <Checkbox
        id={id}
        checked={checked}
        onCheck={onChange}
        style={{
          left: 0,
          marginTop: '-12px',
          position: 'absolute',
          top: '50%',
          width: 24,
        }}
      />
      {label}
    </label>
  );
};

BeezerCheckbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool,
  isValid: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.element,
};

BeezerCheckbox.defaultProps = {
  checked: false,
  isValid: null,
  onChange: () => {},
  label: null,
};

export default BeezerCheckbox;
