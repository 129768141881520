import React from 'react';
import { Route } from 'react-router-dom';

import EditMemberBodyModal from './module';

function EditMemberBodyModalRouter() {
  return (
    <Route path="/:appname/members/member-bodies/:memberBody?" exact>
      {props => <EditMemberBodyModal {...props} />}
    </Route>
  );
}

export default EditMemberBodyModalRouter;
