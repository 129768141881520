import _join from 'lodash/join';
import firebase from '../../../../../../../firebase';
// eslint-disable-next-line
export const fieldChange = (appname, dataId, field, value) => {
  const boxRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  boxRef.child(field).set(value);

  return {
    type: 'BOX_FIELD_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

export const fieldLinkChange = (appname, dataId, fields, value) => {
  const boxRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  const inputPath = _join(fields, '/');
  boxRef.child(inputPath).set(value);

  return {
    type: 'BOX_FIELD_CHANGE',
    appname,
    dataId,
    field: inputPath,
    value,
  };
};

export const handleDesignChange = (appname, key, type, value, prop) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${key}/design`);
  const attr = prop ? `${prop}/${type}` : type;

  dataRef.child(attr).set(value);

  return {
    type: 'DESIGN_CHANGE',
    appname,
    key,
    designType: type,
    value,
  };
};

export const createLink = (appname, dataId, value) => dispatch => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}/link`);

  dataRef
    .set({
      enabled: value,
      label: 'My Link',
      linkType: {
        page: '/',
        web: 'https://www.example.com',
        selectedOption: 'page',
      },
    })
    .then(() =>
      dispatch({
        type: 'BOX_FIELD_CHANGE',
        appname,
        dataId,
        field: 'link',
        value: true,
      }),
    );
};
