import React from 'react';

const Appointment = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={1440}
      height={1440}
      viewBox="0 0 1080 1080"
      className="icon drivers"
    >
      <path d="M434.5 109.2c-4.9 1.7-9.2 5.5-11.4 10.1-2.1 4.3-2.1 5-2.1 89.8v85.4l2.3 4c1.2 2.1 4.2 5.3 6.5 7 3.9 2.7 5.2 3 11.7 3s7.8-.3 11.6-3c2.3-1.7 5.3-5 6.6-7.4l2.3-4.5-.2-86.1-.3-86.1-2.8-4.3c-4.8-7.3-15.7-10.9-24.2-7.9zm310.7 1.3c-3.5 1.8-6.3 4.2-8 6.8l-2.7 4.1-.3 86.1-.2 86.1 2.3 4.5c7.8 14.7 29 14.4 37-.5l2.2-4.1.3-81.3c.2-53.4-.1-83.3-.8-87-1.8-9.9-8.6-16-18.8-16.9-4.8-.4-6.5 0-11 2.2z" />
      <path d="M257 220c-16 2.8-30.3 13.7-37.9 28.7-5.2 10.4-5.1 5.9-5.1 159.5 0 78.6.1 142.8.3 142.8s4.3-1.6 9.3-3.6c43.6-17.6 94.3-20.6 142.1-8.3 41.7 10.7 82.4 35.4 111.7 67.7 36.7 40.6 57.4 90 60.3 143.9l.6 11.3h198.1c219.2 0 203.8.4 217.8-6.6 8.7-4.4 17-12.4 21.5-21 6.8-12.7 6.3 5.6 6.3-243.9 0-197.2-.2-226.8-1.5-231.8-4.4-16.8-16.4-30-33.1-36.5l-6.9-2.7-71.7-.3-71.8-.3v35.9c0 20.1-.4 38.3-1 41.3-2.8 14.8-14.1 27.5-28.9 32.4-8.5 2.8-21.7 1.8-30.3-2.2-12.7-6-21.8-18.4-23.8-32.5-.5-3.5-1-21.7-1-40.6V219H484v35.3c0 21.3-.4 37.7-1.1 41.3-2.4 13.3-11.4 24.9-23.6 30.7-6.4 3-7.5 3.2-17.8 3.2-10.2 0-11.5-.2-17.5-3.1-8.3-3.9-13.3-8.3-18.1-15.7-6.3-9.8-6.9-14.4-6.9-55.6V219l-68.7.1c-37.9.1-70.8.5-73.3.9z" />
      <path d="M286.5 553.6c-38.9 4.6-74.6 18.5-105.2 41.3-10.2 7.5-28.5 25.1-36.6 35.1-21.2 26.4-36.2 57.8-43.3 91.3-3.5 16.4-4.5 49.6-2 68.4 5.9 44.8 25.8 86 57 118.2 51.3 52.7 122.6 74.4 195.6 59.5 12.5-2.6 33.8-10.1 47.5-16.9C452.9 924.2 493 876.1 509 819c22.6-81.1-5.6-168-71.7-220.7-29.7-23.7-64.3-38.5-102.5-43.8-9.8-1.3-39.4-1.9-48.3-.9zm32.7 49.9c2.3 1.7 5.2 5 6.5 7.4l2.3 4.4V741h33.8c39.5 0 41.5.3 48 7.4 4.6 5 5.7 7.9 5.6 15.1 0 9.5-8.1 18.6-17.8 20-2.2.3-24.8.5-50.2.3l-46.1-.3-4.9-3c-3.7-2.3-5.6-4.4-7.6-8.4l-2.8-5.5v-74.8c0-81.8-.2-78.8 5.8-85.2 6.9-7.3 19.3-8.7 27.4-3.1z" />
    </svg>
  );
};

export default Appointment;
