import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { SMS_REVIEW_ACCOUNTS } from '../../../config/constants';
import './styles.css';

const NotificationPanel = props => {
  const {
    title,
    actions,
    selected,
    credit,
    selectionKey,
    message,
    state,
    accountId,
    sms_restricted,
  } = props;
  const { t } = useTranslation();

  const getActionMessage = () =>
    selected > 0 ? (
      <span>
        <Trans>Click on</Trans>{' '}
        <strong>
          {' '}
          <Trans>Share</Trans>{' '}
        </strong>{' '}
        <Trans>to send a message to the selected contacts</Trans>
      </span>
    ) : (
      <span>
        <Trans>
          You must select at least one contact to be able to share with them
        </Trans>
      </span>
    );
  return (
    <>
      {' '}
      {!sms_restricted ? (
        <div className={`notification-panel-wrapper ${state}`}>
          <div className="inner-container">
            <>
              <div className="selection-counter">
                {selected !== null && (
                  <ul>
                    <li>{title}</li>
                    <li className="total-selection">{selected}</li>
                    {selectionKey && (
                      <li className="credit-section">
                        /<strong>{credit}</strong>
                        <span className="total-selection-key">
                          {t(selectionKey)}
                        </span>
                      </li>
                    )}
                  </ul>
                )}
              </div>
              <div className="notification-message">
                <h3> {message || getActionMessage()} </h3>
              </div>

              <div className="notification-actions">
                {credit > 0 || !actions.secondary
                  ? actions.primary
                  : actions[state]}
              </div>
            </>
          </div>
        </div>
      ) : (
        <div className="notification-review">
          <h3>
            {' '}
            Your Account is under review. Please contact us at{' '}
            <a
              href="mailto:support@beezer.com"
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: '#ffffff' }}
            >
              support@beezer.com
            </a>
          </h3>
        </div>
      )}
    </>
  );
};

NotificationPanel.propTypes = {
  title: PropTypes.string.isRequired,
  selectionKey: PropTypes.string,
  selected: PropTypes.number,
  credit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  actions: PropTypes.shape({
    primary: PropTypes.node,
    secondary: PropTypes.node,
  }),
  state: PropTypes.oneOf(['primary', 'secondary']),
  message: PropTypes.object.isRequired,
};

NotificationPanel.defaultProps = {
  actions: null,
  selectionKey: null,
  selected: null,
  credit: null,
  message: null,
  state: 'primary',
};

export default NotificationPanel;
