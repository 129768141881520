import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import DisabledWrapper from '../../components/disabledWrapper';
import { CTAButton } from '../../../../app/components/buttons';

const PushDisabledWrapper = ({
  history,
  onAddNotificationComponent,
  children,
  appname,
}) => {
  const { t } = useTranslation();

  const [btnLabel, setBtnLabel] = useState(t('Do it for me!'));
  const [btnDisabled, setBtnDisabled] = useState(false);

  return (
    <DisabledWrapper
      title={t('Notification Component has not been added to your app yet!')}
      message={
        <span>
          <span
            role="button"
            tabIndex={0}
            onClick={() =>
              history.push(
                history.location.pathname.replace(
                  'engage/push',
                  'editor/build/list?page=homepage',
                ),
              )
            }
            style={{ borderBottom: '1px solid black', cursor: 'pointer' }}
          >
            <Trans>Go to pages</Trans>
          </span>
          &ndash;&gt; <Trans>Select a page</Trans> &ndash;&gt;{' '}
          <Trans>Click the</Trans>
          &quot;<Trans>Add Component</Trans>&quot; <Trans>button</Trans> (
          <span style={{ fontWeight: 600 }}>&#43;</span> <Trans>icon</Trans>)
          &ndash;&gt; <Trans>Select Notification component</Trans> &ndash;&gt;
          <Trans>Click button</Trans> &quot;
          <Trans>Add Notification Component</Trans>&quot;
        </span>
      }
      content={
        <div>
          <br />
          <CTAButton
            disabled={btnDisabled}
            label={btnLabel}
            height={35}
            minWidth={100}
            onClick={() => {
              onAddNotificationComponent();
              setBtnDisabled(true);
              setBtnLabel(t('Adding...'));
            }}
          />
        </div>
      }
    >
      {children}
    </DisabledWrapper>
  );
};

PushDisabledWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  history: PropTypes.object.isRequired,
  onAddNotificationComponent: PropTypes.func.isRequired,
  appname: PropTypes.string.isRequired,
};

PushDisabledWrapper.defaultProps = {
  content: null,
};

export default PushDisabledWrapper;
