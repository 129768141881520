import i18n from 'i18next';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import firebase from '../../../../../../firebase';
import * as actions from './types';
const receivedMarketplaceSettings = settings => ({
  type: actions.RECEIVED_MARKETPLACE_SETTINGS,
  value: settings,
});

const noMarketplaceSettingsFound = () => ({
  type: actions.NO_MARKETPLACE_SETTINGS_FOUND,
});

export const getMarketplaceSettings = appname => dispatch => {
  firebase
    .database()
    .ref(`marketplace/${appname}`)
    .once('value', async snap => {
      if (snap.exists()) {
        dispatch(receivedMarketplaceSettings(snap.val()));
      } else {
        dispatch(noMarketplaceSettingsFound());
      }
    });
};

export const updateMarketplaceSettings = (appname, name, data) => dispatch => {
  try {
    const marketplaceRef = firebase
      .database()
      .ref(`marketplace/${appname}/marketplace_settings`);

    marketplaceRef.child(name).set(data);

    dispatch({
      type: actions.UPDATED_MARKETPLACE_SETTINGS,
    });
    dispatch(openSnackbar(i18n.t('Settings updated successfully.')));
  } catch (error) {
    console.error(error);
    dispatch(openSnackbar(i18n.t('Error updating settings try again later')));
  }
};
