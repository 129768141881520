import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import NestedPages from '../nestedPages';

import {
  Drag,
  TabView as TabViewIcon,
  View as ViewIcon,
} from '../../../../../../components/icons';

import './styles.css';

const RightIconWrapper = styled.div`
  margin-top: 4px;
  padding: 0 10px;
`;

/**
 * This is a custom version of the material-ui ListItem
 * Created specifically for the design requireents of the Beezer platform
 */
class NestedTabPage extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
    href: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    onDelete: PropTypes.func,
    hasUnavailaleComponent: PropTypes.bool.isRequired,
    pages: PropTypes.array.isRequired,
    onDuplicate: PropTypes.func,
  };

  static defaultProps = {
    hidden: false,
    onDelete: () => {},
    onDuplicate: () => {},
  };

  componentDidMount = () => {
    // remove fade-in animation after mount to prevent recyling of animation during drag
    this.mounted = true;
  };

  getIcon = () => {
    switch (this.props.type) {
      case 'View':
        return <ViewIcon />;

      case 'TabView':
      case 'NestedTabPage':
        return <TabViewIcon />;

      default:
        return null;
    }
  };

  handleDeleteButtonClick = event => {
    // Stop the event from bubbling up to the list-item
    event.stopPropagation();
    this.props.onDelete();
  };

  handleDuplicateButtonClick = event => {
    // Stop the event from bubbling up to the list-item
    event.stopPropagation();
    this.props.onDuplicate();
  };

  handleKeyboardFocus = () => {};

  handleTouchTap = () => {
    this.props.history.push(this.props.href);
  };

  isHomepage = () => {
    // if (!this.props.index) {
    //   return (
    //     <RightIconWrapper className="icon-right">
    //       <Home />
    //     </RightIconWrapper>
    //   );
    // }

    return (
      <RightIconWrapper className="hover-icon-button icon-right">
        <Drag />
      </RightIconWrapper>
    );
  };

  render() {
    return (
      <>
        <div id={this.props.id}>
          {this.props.active &&
            this.props.type !== 'TabView' &&
            this.props.nestedPages.length > 0 && (
              <NestedPages nestedPages={this.props.nestedPages} />
            )}
        </div>
      </>
    );
  }
}

export default withRouter(NestedTabPage);
