import { combineReducers } from 'redux';
import _keys from 'lodash/keys';
import createGroup from './modules/create/reducer';
import groupTabs from './modules/groups/reducer';

import {
  REQUEST_WORKFORCE_MEMBER_DETAILS,
  REQUEST_WORKFORCE_MEMBER_FIELD_UPDATE,
  RECEIVE_WORKFORCE_MEMBER_FIELD_UPDATE,
  RECEIVE_WORKFORCE_MEMBER_DETAILS,
} from './actions';

const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  type: {
    value: '',
    isValid: null,
  },
  groupDetails: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    landline: '',
    dob: '',
    disability: '',
    phoneNumber: '',
    ethnicGroup: '',
    religion: '',
    sexualOrientation: '',
    postalAddress: '',
    postCode: '',
    localAuthority: '',
  },
  fields: [],
};

const editGroup = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_WORKFORCE_MEMBER_DETAILS:
      return {
        ...state,
      };

    case REQUEST_WORKFORCE_MEMBER_FIELD_UPDATE:
      return {
        ...state,
      };

    case RECEIVE_WORKFORCE_MEMBER_FIELD_UPDATE:
      return {
        ...state,
        data: action.data,
      };

    case RECEIVE_WORKFORCE_MEMBER_DETAILS:
      return {
        ...state,
        groupDetails: action.value,
        fields: _keys(action.value.fields),
        pending: false,
        error: null,
      };

    default:
      return state;
  }
};

export default combineReducers({
  editGroup,
  createGroup,
  groupTabs,
});
