import { connect } from 'react-redux';

import ResetEmail from './ResetEmail';

import { fieldChange, clearForm, submitResetEmail } from './actions';

const mapStateToProps = state => ({
  ...state.account.resetEmail,
});

const mapDispatchToProps = dispatch => ({
  onOldEmailChange: value => dispatch(fieldChange('oldEmail', value)),
  onPasswordChange: value => dispatch(fieldChange('password', value)),
  onNewEmailChange: value => dispatch(fieldChange('newEmail', value)),
  onSubmit: () => dispatch(submitResetEmail()),
  clearForm: () => dispatch(clearForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetEmail);
