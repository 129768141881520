import React from 'react';
import PropTypes from 'prop-types';
import DisabledWrapper from '../../../../../engage/components/disabledWrapper';
import { get } from 'lodash';
import CTAButton from '../../../../../../app/components/buttons/cta/CTA';
import { Trans, useTranslation } from 'react-i18next';

const BookingMissingStaffWrapper = ({ history, match, children }) => {
  const appname = get(match, 'params.appname', '');
  const { t } = useTranslation();

  return (
    <DisabledWrapper
      title={t('No staff members present')}
      message={
        <span>
          <span>
            <Trans>To use this feature add staff members to your app.</Trans>
          </span>
          <br />
        </span>
      }
      content={
        <div>
          <br />
          <CTAButton
            label={t('Add Staff Members')}
            height={35}
            minWidth={100}
            onClick={() => {
              history.push(`/${appname}/ecommerce/bookings/staff`);
            }}
          />
        </div>
      }
    >
      {children}
    </DisabledWrapper>
  );
};

BookingMissingStaffWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  history: PropTypes.object.isRequired,
  appname: PropTypes.string.isRequired,
};

export default BookingMissingStaffWrapper;
