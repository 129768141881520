import React from 'react';

import './styles.css';

const Social = () => (
  <svg
    className="icon contacts"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36.25 43.31"
  >
    <title>Contacts</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M9.08,28.13a4.81,4.81,0,1,0-4.16,0A5.76,5.76,0,0,0,0,33.81v9.5H14v-9.5A5.76,5.76,0,0,0,9.08,28.13ZM3.69,23.81A3.31,3.31,0,1,1,7,27.12,3.31,3.31,0,0,1,3.69,23.81ZM12.5,41.57v.24H1.5v-8a4.26,4.26,0,0,1,4.25-4.25h2.5a4.26,4.26,0,0,1,4.25,4.25Z"
        />
        <path
          class="cls-1"
          d="M31.33,28.13a4.81,4.81,0,1,0-4.16,0,5.76,5.76,0,0,0-4.92,5.68v9.5h14v-9.5A5.76,5.76,0,0,0,31.33,28.13Zm-5.39-4.32a3.31,3.31,0,1,1,3.31,3.31A3.31,3.31,0,0,1,25.94,23.81Zm8.81,18h-11v-8A4.26,4.26,0,0,1,28,29.56h2.5a4.26,4.26,0,0,1,4.25,4.25v8Z"
        />
        <path
          class="cls-1"
          d="M23.75,14.81v2.9h1.5v-2.9a5.76,5.76,0,0,0-4.92-5.68,4.81,4.81,0,1,0-4.16,0,5.76,5.76,0,0,0-4.92,5.68v2.9h1.5v-2.9A4.26,4.26,0,0,1,17,10.56h2.5A4.26,4.26,0,0,1,23.75,14.81Zm-8.81-10a3.31,3.31,0,1,1,3.31,3.31A3.31,3.31,0,0,1,14.94,4.81Z"
        />
      </g>
    </g>
  </svg>
);

export default Social;
