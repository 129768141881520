import FormData from 'form-data';

import firebase from '../../../../../../../firebase';

export const fieldChange = (appname, dataId, field, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);
  dataRef.child(field).set(value);

  return {
    type: 'TEXT_VALUE_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

export const designChange = (appname, key, type, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${key}/design`);

  dataRef.child(type).set(value);

  return {
    type: 'MEDIA_DESIGN_CHANGE',
    appname,
    key,
    designType: type,
    value,
  };
};

const MediaUploadStart = () => ({
  type: 'MEDIA_UPLOAD_START',
});

const MediaUploadSuccess = () => ({
  type: 'MEDIA_UPLOAD_SUCCESS',
});

const MediaUploadError = message => ({
  type: 'MEDIA_UPLOAD_FAILURE',
  message,
});

export const uploadMedia = (media, firebaseUrl) => dispatch => {
  const form = new FormData();
  const user = firebase.auth().currentUser;
  const myHeaders = new Headers();

  form.append('firebaseUrl', firebaseUrl);
  form.append('file', media);
  return user
    .getIdToken()
    .then(token => {
      dispatch(MediaUploadStart());
      myHeaders.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_IMAGES_API}/upload_file`, {
        method: 'post',
        headers: myHeaders,
        body: form,
        mode: 'cors',
      });
    })
    .then(res => res.json())
    .then(res => {
      if (res.error) {
        dispatch(MediaUploadError(res.error));
      } else {
        dispatch(MediaUploadSuccess());
      }
    })
    .catch(error => dispatch(MediaUploadError(error.message)));
};
