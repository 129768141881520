import React from 'react';

const Text = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 84.05 34.95"
  >
    <title>delegates</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path class="cls-1" d="M84.05,35H0V0H84.05ZM1.5,33.45H82.55V1.5H1.5Z" />
        <path
          class="cls-1"
          d="M66.77,20.36a6.3,6.3,0,1,0-7.94,0,9.21,9.21,0,0,0-6.53,5.28,9.24,9.24,0,0,0-6.53-5.28,6.3,6.3,0,1,0-7.94,0,9.21,9.21,0,0,0-6.53,5.28,9.24,9.24,0,0,0-6.53-5.28,6.3,6.3,0,1,0-7.94,0,9.2,9.2,0,0,0-7.33,9v4.9H11v-4.9a7.7,7.7,0,0,1,7.69-7.68h1a6.12,6.12,0,0,0,1.14.11,5.93,5.93,0,0,0,1.13-.11h1a7.7,7.7,0,0,1,7.64,6.87,7.34,7.34,0,0,0-.05.81v4.9h1.59v-4.9c0-.27,0-.54,0-.81a7.7,7.7,0,0,1,7.64-6.87h1a6.12,6.12,0,0,0,1.14.11,5.93,5.93,0,0,0,1.13-.11h1a7.7,7.7,0,0,1,7.64,6.87,7.34,7.34,0,0,0,0,.81v4.9h1.59v-4.9c0-.27,0-.54,0-.81a7.7,7.7,0,0,1,7.64-6.87h1a6.12,6.12,0,0,0,1.14.11,5.93,5.93,0,0,0,1.13-.11h1a7.69,7.69,0,0,1,7.68,7.68v4.9h1.5v-4.9A9.2,9.2,0,0,0,66.77,20.36ZM16,15.49a4.8,4.8,0,1,1,5.8,4.68h-2A4.79,4.79,0,0,1,16,15.49Zm21,0a4.8,4.8,0,1,1,5.8,4.68h-2A4.79,4.79,0,0,1,37,15.49Zm21,0a4.8,4.8,0,1,1,5.8,4.68h-2A4.79,4.79,0,0,1,58,15.49Z"
        />
      </g>
    </g>
  </svg>
);

export default Text;
