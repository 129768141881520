import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Done, CloudDownload } from '@material-ui/icons';
import Chip from '@material-ui/core/Chip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ResultsTable from 'app/components/resultsTable';
import TryAgain from 'app/components/tryAgain';
import TableCheckbox from '../../components/tableCheckbox';
import Header from '../../components/header';
import ActionMenu from '../../../../app/components/actionMenu';
import './styles.css';
import XLSX from 'xlsx';
import { formatTransations } from './exportData';
import themeStyles from '../../../../_export.scss';

const isCheckboxDisabled = (error, pending) => error && !pending;

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableInfoDialog: false,
    };
  }

  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.array,
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    chunks: PropTypes.array,
    loadTransactions: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    filter: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
    chunks: [],
    filteredChunks: [],
    items: [],
    query: '',
    selected: [],
    selectAll: false,
    pending: true,
  };

  componentDidMount() {
    this.props.loadTransactions();
  }

  handleExportData = () => {
    const { t } = this.props;
    const data = formatTransations(this.props.chunks);
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, t('Transactions'));

    XLSX.writeFile(wb, `${t('Transactions')}.xlsx`);
  };

  getTitle = (selected, transactions) => {
    const { t } = this.props;
    return (
      <span
        style={{
          fontFamily: 'Raleway, sans-serif',
          fontWeight: 600,
          fontSize: 20,
        }}
      >
        <Trans>Transactions</Trans>:{' '}
        {selected.length
          ? `${selected.length} / ${Object.keys(transactions).length}`
          : Object.keys(transactions).length}
        <IconButton
          color="primary"
          aria-label={t('information')}
          component="span"
          onClick={this.toggleInfoDialog}
        >
          <InfoOutlinedIcon />
        </IconButton>
      </span>
    );
  };

  getSelectAllCheckBox = () => {
    const {
      error,
      pending,
      selected,
      chunks,
      selectAll,
      onSelectAllTransactions,
      onDeselectAllTransactions,
      t,
    } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllTransactions() : onDeselectAllTransactions()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDownloadSelected={
            this.handleExportData
            //   () => {
            //   selected.forEach(s => {
            //     if (chunks[s].invoice && chunks[s].invoice.invoice_pdf) {
            //       window.open(chunks[s].invoice.invoice_pdf);
            //     }
            //   });
            // }
          }
          text={t('transactions')}
        />
      </div>
    );
  };

  toggleInfoDialog = () => {
    this.setState({ enableInfoDialog: !this.state.enableInfoDialog });
  };

  render() {
    const {
      pending,
      items,
      error,
      loadTransactions,
      chunks,
      filteredChunks,
      onFilterTransactionData,
      selected,
      t,
    } = this.props;

    const errorMsg = (
      <p>
        <span>
          <Trans>Oops, something went wrong, verify your</Trans>{' '}
          <Link
            to={{
              pathname: `/${this.props.match.params.appname}/members/settings`,
              hash: 'user-app-subscriptions',
            }}
            className="link"
          >
            <Trans>Stripe API Keys</Trans>
          </Link>{' '}
          <Trans>or try again</Trans>
        </span>
      </p>
    );
    const body = error ? (
      <TryAgain message={errorMsg} onRetry={loadTransactions} />
    ) : (
      <ResultsTable
        items={items}
        chunks={filteredChunks}
        pending={pending}
        error={items.length === 0 ? t('No transactions found') : null}
        emptyMessage={t('Looks like there are no transactions to display')}
        columns={[
          {
            id: 'amount',
            header: t('Amount Paid'),
            classNames: 'col-xs-2',
            containerElement: ({ value }) => {
              return (
                <strong>{value ? `${(value / 100).toFixed(2)}` : ''}</strong>
              );
            },
          },
          {
            id: 'currency',
            header: '',
            classNames: 'col-xs-1',
            containerElement: ({ value }) => value.toUpperCase(),
          },
          {
            id: 'status',
            header: '',
            classNames: 'col-xs-2',
            containerElement: ({ value }) => (
              <Chip
                label={value}
                color="primary"
                onDelete={() => {}}
                deleteIcon={<Done />}
                variant="outlined"
              />
            ),
          },
          {
            id: 'description',
            header: t('Description'),
            classNames: 'col-xs-2',
          },
          {
            id: 'customer',
            header: t('Customer'),
            classNames: 'col-xs-3',
            containerElement: ({ value }) => {
              return (
                <span>
                  {value ? (value.email ? value.email : value.description) : ''}
                </span>
              );
            },
          },
          {
            id: 'invoice',
            header: t('Invoice'),
            classNames: 'col-xs-1',
            containerElement: ({ value }) => (
              <span>
                {value && (
                  <a href={value.invoice_pdf || '#'}>
                    <CloudDownload />
                  </a>
                )}
              </span>
            ),
          },
          {
            id: 'selected',
            header: this.getSelectAllCheckBox(),
            classNames: `col-xs-1 ${
              items.length < 4 ? 'fix-left' : 'selected'
            }`,
            containerElement: ({ value, rowId }) => {
              return (
                <>
                  <TableCheckbox
                    value={value}
                    onSelect={() => this.props.onSelect(rowId)}
                  />
                  <ActionMenu
                    selected={[rowId]}
                    onDownloadSelected={() => {
                      [rowId].forEach(s => {
                        if (
                          chunks[s].invoice &&
                          chunks[s].invoice.invoice_pdf
                        ) {
                          window.open(chunks[s].invoice.invoice_pdf);
                        }
                      });
                    }}
                    text={t('transaction')}
                  />
                </>
              );
            },
          },
        ]}
      />
    );

    const infoDialog = (
      <Dialog
        open={this.state.enableInfoDialog}
        onClose={this.toggleInfoDialog}
      >
        <DialogTitle>
          <Trans>How to use Transaction History</Trans>
        </DialogTitle>
        <DialogContent>
          <p>
            <Trans>
              In this section you can see the transaction history of
              subscription payments for all users in a chronological order.
            </Trans>
          </p>
          <p>
            <Trans>
              For further details, you can also visit the Payments section of
              your Stripe Business account.
            </Trans>
          </p>
        </DialogContent>
      </Dialog>
    );

    return (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <Header
          {...this.props}
          title={this.getTitle(selected, chunks)}
          onChange={onFilterTransactionData}
        />
        {body}
        {infoDialog}
      </section>
    );
  }
}

export default withTranslation()(Transactions);
