const editionData = (state = {}, action) => {
  switch (action.type) {
    case 'DRAFT_EDITION_START_MODIFIED':
      return {
        ...state,
        ...action,
      };

    case 'RECEIVE_DRAFT_EDITION_START':
      return {
        ...state,
        ...action,
      };

    default:
      return state;
  }
};

export default editionData;
