import React, { useEffect, useState } from 'react';
import { FlatButton } from '../../../../../../app/components/buttons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isEmpty } from 'lodash';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import DialogConfirmation from '../../components/DialogConfirmation';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttons: {
    width: '100%',
    marginTop: '30px',
  },
  displayCost: {
    textAlign: 'center',
    marginTop: '30px',
  },
  mt30: {
    marginTop: '30px',
  },
}));

const RedeemRequest = props => {
  const classes = useStyles();

  const [dialog, setDialog] = useState(false);
  const [status, setStatus] = useState('');

  const {
    validateRequestData,
    isValidRequest,
    details,
    isPending,
    approvalRequestStatus,
    isApprovalSubmitted,
    error,
  } = props;

  useEffect(() => {
    validateRequestData();
  }, []);

  const renderInvalidRedeemRequest = () => {
    return <Typography>Invalid Redeem Request.</Typography>;
  };

  const action = status => {
    setStatus(status);
    setDialog(true);
  };

  const renderApproval = () => {
    return (
      <div className={classes.displayCost}>
        <Typography>Bonus Amount : {details.formattedAmount}</Typography>
        <div className={classes.buttons}>
          <FlatButton
            style={{ margin: 20 }}
            label="Reject"
            secondaryWhite
            onClick={() => action('REJECTED')}
          />
          <FlatButton
            style={{ margin: 20 }}
            label="Approve"
            primary
            onClick={() => action('SUCCESSFUL')}
          />
        </div>
      </div>
    );
  };

  const getMessage = () => {
    if (status === 'SUCCESSFUL') {
      return `Are you sure you want to Approve this request?`;
    }
    return `Are you sure you want to Reject this request?`;
  };

  const renderAfterApproval = () => {
    return (
      <div className={classes.mt30}>
        <Typography>Redeem Request successfully submited.</Typography>
      </div>
    );
  };

  const renderErrors = () => {
    return (
      <div className={classes.mt30}>
        <Typography>
          There are some error while processing this request. please try again
          later.
        </Typography>
      </div>
    );
  };

  const renderAlreadyProcessedRequest = () => {
    return (
      <div className={classes.mt30}>
        <Typography>This Redeem Request is Already Processed</Typography>
      </div>
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Redeem Request Approval/Rejection
        </Typography>
        <Typography component="h1" variant="h5">
          {isPending && (
            <div className={classes.mt30}>
              <CircularProgress />
            </div>
          )}
        </Typography>
        {isApprovalSubmitted && renderAfterApproval()}
        {error && renderErrors()}
        {!isPending &&
          !isValidRequest &&
          !isApprovalSubmitted &&
          renderInvalidRedeemRequest()}
        {!isPending &&
          isValidRequest &&
          !isApprovalSubmitted &&
          !isEmpty(details) && (
            <div>
              {details.status === 'PROCESSING'
                ? renderApproval()
                : renderAlreadyProcessedRequest()}
            </div>
          )}
      </div>
      <DialogConfirmation
        open={dialog}
        onClose={() => {
          setDialog(false);
          setStatus('');
        }}
        message={getMessage()}
        action={() => {
          props
            .updateRequestStatus(status)
            .then(() => {
              setDialog(false);
            })
            .catch(error => {
              setDialog(false);
            });
        }}
        pending={approvalRequestStatus}
      />
    </Container>
  );
};

export default RedeemRequest;
