import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RTE from 'react-rte';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';
import MenuItem from 'material-ui/MenuItem';
import FormatAlignLeft from 'material-ui/svg-icons/editor/format-align-left';
import FormatAlignCenter from 'material-ui/svg-icons/editor/format-align-center';
import FormatAlignRight from 'material-ui/svg-icons/editor/format-align-right';
import FormatAlignJustified from 'material-ui/svg-icons/editor/format-align-justify';
import { Trans, withTranslation } from 'react-i18next';

import Container from '../../../../app/components/container';
import SelectField from '../../../../app/components/selectField';
import TextField from '../../../../app/components/textField';
import { CircularProgress } from '../../../../app/components/progress';
import { FlatButton } from '../../../../app/components/buttons';
import './styles.css';
import { HS_FEATURES_ENABLED_APPS } from '../../../../config/constants';

class ShareEmailForm extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    message: PropTypes.object.isRequired,
    subject: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired,
    smsCredit: PropTypes.number.isRequired,
    match: PropTypes.object.isRequired,
    messageOptions: PropTypes.array.isRequired,
    openDistributeDialog: PropTypes.func.isRequired,
    closeDistributeDialog: PropTypes.func.isRequired,
    onMessageChange: PropTypes.func.isRequired,
    onSubjectChange: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    setDefaultMessage: PropTypes.func.isRequired,
    onAddContact: PropTypes.func.isRequired,
    onSendEmail: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    organisation: PropTypes.object.isRequired,
    customDomain: PropTypes.object,
    selected: PropTypes.number,
    t: PropTypes.func.isRequired,
  };

  hintMsg = `💡 ${this.props.t(
    'To add username in message body please add',
  )} {{user_name}}`;

  static defaultProps = {
    selected: 0,
    customDomain: {},
    align: 'left',
  };

  state = {
    value: RTE.createValueFromString(this.props.message.value, 'html'),
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.message.value !== this.stringVal) {
      this.setState({
        value: RTE.createValueFromString(this.props.message.value, 'html'),
      });
    }
  }

  handleChange = value => {
    this.setState({ value }, () => {
      this.stringVal = value.toString('html');

      this.props.onMessageChange(this.stringVal);
    });
  };

  isHorseScotlandApps = () =>
    HS_FEATURES_ENABLED_APPS.indexOf(this.props.match.params.appname) > -1;
  handleAlign = align => {
    this.setState({ align }, () => {
      this.props.onMessageChange(
        `<div style="text-align: ${align}">${this.props.message.value}</div>`,
      );
    });
  };

  render() {
    const {
      closeDistributeDialog,
      message,
      subject,
      messageOptions,
      onMessageChange,
      onSubjectChange,
      selected,
      pending,
      onSendEmail,
      customDomain,
      t,
    } = this.props;

    return (
      <div className="contacts-form share-email-form">
        <h5>
          <Trans>Distribute to Selected Contacts</Trans>
        </h5>
        <div className="modal-controls-container">
          <IconButton onClick={() => closeDistributeDialog(customDomain)}>
            <Close />
          </IconButton>
        </div>
        <Container>
          <SelectField
            {...message}
            className="offset-top"
            label={t('Select Message')}
            options={messageOptions.map(messageOpt => (
              <MenuItem
                key={messageOpt}
                value={messageOpt}
                primaryText={messageOpt}
              />
            ))}
            onChange={(i, payload) => onMessageChange(payload)}
            disabled={messageOptions.length === 0}
            autoWidth
          />
          <TextField
            id="email-subject"
            value={subject ? subject.value : ''}
            disabled={pending}
            onChange={value => onSubjectChange(value)}
            maxLength={75}
            isValid={subject ? subject.value.length < 75 : true}
            placeholder={t('Subject')}
          />

          <span
            style={{ marginTop: '10px', fontSize: '0.8rem', color: '#696969' }}
          >
            {this.hintMsg}
          </span>
          <div className="message-editor-container">
            <div className="align-buttons-container">
              <IconButton
                onClick={() => this.handleAlign('left')}
                tooltip={t('Left Align')}
                className={this.state.align === 'left' ? 'selected' : ''}
                disabled={this.state.align === 'left'}
              >
                <FormatAlignLeft />
              </IconButton>
              <IconButton
                onClick={() => this.handleAlign('center')}
                tooltip={t('Center Align')}
                className={this.state.align === 'center' ? 'selected' : ''}
                disabled={this.state.align === 'center'}
              >
                <FormatAlignCenter />
              </IconButton>
              <IconButton
                onClick={() => this.handleAlign('right')}
                tooltip={t('Right Align')}
                className={this.state.align === 'right' ? 'selected' : ''}
                disabled={this.state.align === 'right'}
              >
                <FormatAlignRight />
              </IconButton>
              <IconButton
                onClick={() => this.handleAlign('justify')}
                tooltip={t('Fully Justify')}
                className={this.state.align === 'justify' ? 'selected' : ''}
                disabled={this.state.align === 'justify'}
              >
                <FormatAlignJustified />
              </IconButton>
            </div>
            <RTE
              label={t('Body')}
              className="rte"
              editorClassName="rte-editor"
              toolbarClassName="rte-toolbar"
              value={this.state.value}
              onChange={this.handleChange}
              autoFocus
            />
          </div>
        </Container>
        {!this.isHorseScotlandApps() && (
          <>
            <p>
              <Trans>
                The sender address for these emails will be no-reply@appurl
              </Trans>
            </p>
            <p>
              <Trans>
                If you enable a custom domain for your app, the sender address
                will be no-reply@appname.customdomain.
              </Trans>
            </p>
          </>
        )}
        <p className="confirmation-message">
          <Trans>Share with</Trans> <strong>{selected}</strong>&nbsp;{' '}
          {selected > 1 ? t('selected contacts') : t('selected contact')}{' '}
          <Trans>by Email</Trans>
        </p>
        <Container>
          {pending && <CircularProgress size={18} />}
          {!pending && (
            <FlatButton
              label={t('Confirm')}
              onClick={onSendEmail}
              style={{
                margin: 12,
              }}
              disabled={
                subject
                  ? subject.value.length >= 75 || !message.value
                  : !message.value
              }
              primary
            />
          )}
        </Container>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ShareEmailForm));
