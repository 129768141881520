import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { CloseButton } from './styles';

const useStyles = makeStyles(() => ({
  input: {
    padding: '1em',
  },
  paperRoot: {
    height: 'fit-content',
    overflow: 'hidden',
    borderRadius: '16px !important',
  },
}));
const StyledDialog = ({ open, onClose, content, title, ...props }) => {
  const classes = useStyles();
  return (
    <Dialog
      classes={{
        root: classes.root,
      }}
      open={open}
      PaperProps={{
        classes: {
          root: classes.paperRoot,
        },
      }}
      {...props}
    >
      <DialogTitle>
        {title}
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </DialogTitle>
      <DialogContent className="scroll-container" style={{ marginTop: '2rem' }}>
        {content}
      </DialogContent>
    </Dialog>
  );
};

StyledDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.node || PropTypes.string,
  title: PropTypes.string,
};

StyledDialog.defaultProps = {
  content: '',
  title: '',
};

export default StyledDialog;
