import React from 'react';
import GroupsTable from '../../components/GroupsTable';
import GroupsDialog from '../../components/GroupsDialog';
import GroupsDetailsDialog from '../../components/GroupsDetailsDialog';

function Groups() {
  return (
    <div>
      <GroupsTable />
      <GroupsDialog />
      <GroupsDetailsDialog />
    </div>
  );
}

export default Groups;
