import React from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

function FkEventCard({
  createdAt,
  date,
  description,
  groupId,
  eventId,
  title,
  venue,
}) {
  const appname = useSelector(({ app }) => app.appContext.appname);

  return (
    <div
      style={{
        display: 'flex',
        borderRadius: 10,
        padding: '15px 5px',
        alignItems: 'center',
        boxShadow: '0 2px 4px rgb(0 0 0 / 0.2)',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginRight: 10,
          background: 'purple',
          color: 'white',
          padding: 10,
          borderRadius: 10,
          fontWeight: 'bold',
        }}
      >
        <div>{dayjs(date).format('D')}</div>
        <div>{dayjs(date).format('MMM')}</div>
        <div>{dayjs(date).format('YYYY')}</div>
        <div style={{ whiteSpace: 'nowrap' }}>
          {dayjs(date).format('h:mm A')}
        </div>
      </div>
      <div>
        <h3 style={{ margin: 0, fontWeight: 'bold' }}>{title}</h3>
        <p style={{ margin: 0 }}>{description}</p>
        <p style={{ margin: 0 }}>📍 {venue}</p>
      </div>
      <button
        style={{
          marginLeft: 'auto',
          fontWeight: 'bold',
          color: 'white',
          padding: 10,
          borderRadius: 10,
          background: '#261ED5',
        }}
        onClick={() =>
          window.open(
            `${process.env.REACT_APP_FK_ACADEMY}/event-attendance-csv?appname=${appname}&groupId=${groupId}&eventId=${eventId}`,
          )
        }
      >
        Download Attendance
      </button>
    </div>
  );
}

export default FkEventCard;
