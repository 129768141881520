import firebase from '../../../../firebase';
import { JAN_50_OFF, plans, plansWixWeebly, pricing } from '../../../../plans';
import { loadAccount } from '../../actions';
import {
  submitPayment,
  submitCardDetails,
  reportPaymentSuccess,
  reportPaymentError,
  clearAutopopupNotices,
  subscriptionPaymentRequiresAuthentication,
} from '../payment/actions';
import { cancelPlan } from '../subscriptions/actions';
import { resetQueryStringOffer } from '../register/actions';
import { updateContact, getListByPlanName } from '../auth/login/actions';
import { referrerList } from '../register/referrerList';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';

export const RECEIVED_PRORATING = 'RECEIVED_PRORATING';
export const FAILED_FETCHING_PRORATING = 'FAILED_FETCHING_PRORATING';
export const FETCHING_PRORATING = 'FETCHING_PRORATING';

export const setPlan = (query, isOffer, country) => {
  const plan = query ? query.plan : 'accelerate';
  const billingPeriod = query ? query.billingPeriod : 'monthly';
  const integrationType = query ? query.integrationType : '';
  const planList = integrationType === 'Weebly' ? plansWixWeebly : plans;

  const cost = isOffer
    ? query.cost
    : (billingPeriod === 'yearly'
        ? (planList[plan][country] && planList[plan][country].year_price) ||
          planList[plan].year_price
        : (planList[plan][country] && planList[plan][country].price) ||
          planList[plan].price
      ).toFixed(2);

  let currency;

  if (query && query.coupon === 'beezeroneyear19') {
    currency = 'GBP';
  } else {
    currency =
      (planList[plan][country] && planList[plan][country].currency) || 'GBP';
  }

  return {
    type: 'SET_SUBSCRIPTION_PURCHASE_PLAN',
    displayName: planList[plan].title,
    cost,
    billingPeriod,
    plan,
    currency,
  };
};

/**
 * submitPaymentForm
 * Submits the payment form to the backend.
 * If the user doesn't have a plan, it creates a stripe customer with an active plan for the user
 * If the user has a plan, it updates the stripe subscription
 */
export const subscribe = ({
  appname,
  plan,
  period,
  callback,
  coupon,
  price,
  isChangingPlan = false,
  planName,
  currency,
  stripe,
  productId,
}) => (dispatch, getState) => {
  const { payment, subscriptions, profile, organisation } = getState().account;
  const { prorating, billingPeriod } = getState().account.subscribe;
  const subscription = subscriptions.items[appname];
  const hasClickbankSubscription = subscription.paymentType === 'clickbank';
  const hasPlan =
    subscription && subscription.plan && subscription.plan !== 'free';
  const isChangingPeriod =
    subscription.period !== pricing[billingPeriod].title.toLowerCase();
  const endpoint =
    isChangingPlan && !hasClickbankSubscription
      ? 'change_subscription'
      : 'subscribe';

  const isEverything3MonthsOff = coupon === 'everything-3-months-off';
  const lifetime = coupon === 'lifetime';
  const beezeroneyear = coupon === 'beezeroneyear';
  const beezeroneyear19 = coupon === 'beezeroneyear19';
  const jan50Off = coupon === JAN_50_OFF;
  const hasCoupon =
    isEverything3MonthsOff ||
    lifetime ||
    beezeroneyear ||
    jan50Off ||
    beezeroneyear19;
  if (hasCoupon && hasPlan && !hasClickbankSubscription) {
    const currentPlan = subscription.plan;
    dispatch(cancelPlan(currentPlan, appname));
  }

  if (hasPlan && !isChangingPlan && !subscription.cancelled && !hasCoupon) {
    dispatch(openSnackbar('You are already subscribed with this app'));
    dispatch({
      type: 'PAYMENT_FAILURE',
      err: 'You are already subscribed with this app',
    });
    return dispatch(
      reportPaymentError('You are already subscribed with this app'),
    );
  }

  dispatch(submitPayment());
  // response will be present if a card is being submitted with the request
  const paymentHandler = ({ response, useDefaultCard }) => {
    const payload = {
      appname,
      plan:
        currency.toLowerCase() === 'gbp'
          ? period === 'yearly'
            ? plan
            : `${plan}-2023`
          : period === 'yearly'
          ? `${plan}-${currency.toLowerCase()}`
          : `${plan}-${currency.toLowerCase()}-2023`,
      useDefaultCard,
      period,
      coupon,
      price,
      productId,
      planName:
        currency.toLowerCase() === 'gbp'
          ? planName
          : `${planName} - ${currency.toUpperCase()}`,
      currency: currency.toLowerCase(),
      ...(isChangingPeriod !== null &&
        isChangingPeriod !== undefined && { isChangingPeriod }),
      ...(prorating && prorating.adjustment && { prorating }),
    };

    if (response) {
      payload.token = response.id;
      payload.card = response.card;
    }

    return firebase
      .auth()
      .currentUser.getIdToken()
      .then(token => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('x-access-token', token);

        const options = {
          method: 'post',
          headers,
          body: JSON.stringify(payload),
          mode: 'cors',
        };

        return fetch(
          `${process.env.REACT_APP_PAYMENT_API}/${endpoint}`,
          options,
        );
      })
      .then(async res => {
        // console.log('res2-->', res);

        if (res.status === 200) {
          dispatch(reportPaymentSuccess());
          dispatch(
            updateContact({
              account: profile.account,
              lists: getListByPlanName(plan, organisation),
              currentPlan: plan,
              coupon,
            }),
          );
          if (
            coupon === 'adilloeverything50offmonthly' ||
            coupon === 'adilloeverything50offyearly'
          ) {
            dispatch(
              updateContact({
                account: profile.account,
                lists: 'adillo',
                isPayment: true,
              }),
            );
          }

          dispatch(clearAutopopupNotices());
          // callback after successful payment for redirect
          callback();
        } else if (res.status === 202) {
          const { subscription, diySubToCancel } = await res.json();
          dispatch(
            subscriptionPaymentRequiresAuthentication(
              subscription,
              diySubToCancel,
            ),
          );
        } else {
          const result = await res.json();
          // console.log('result-->', result);
          let message = 'Failed to Process, please try again';
          const matches = result.message.match(
            /currencies of `(\w+)`.*currency of `(\w+)`/,
          );

          if (matches && matches.length === 3) {
            const supportedCurrency = matches[1]; // 'cad' in the example
            const expectedCurrency = matches[2]; // 'usd' in the example

            // Display a user-friendly message
            message = `Your previous subscription was in ${expectedCurrency.toUpperCase()}. You can only subscribe to plans in ${expectedCurrency.toUpperCase()}.`;
          } else if (
            result.message ===
            'Halloween Coupon is first time subscribers only.'
          ) {
            message = 'Halloween Coupon is first time subscribers only.';
          }
          dispatch(openSnackbar(message));
          dispatch({
            type: 'PAYMENT_FAILURE',
            err: message,
          });
          dispatch(reportPaymentError(message));
        }
      });
  };

  const { useDefaultCard } = payment;
  if (useDefaultCard) {
    return paymentHandler({ useDefaultCard })
      .then(() => dispatch(loadAccount(profile.account)))
      .catch(error => {
        dispatch(openSnackbar(error.message));
        dispatch({
          type: 'PAYMENT_FAILURE',
          err: error.message,
        });
        dispatch(reportPaymentError(error.message));
      });
  }

  return dispatch(submitCardDetails(stripe))
    .then(response => paymentHandler({ useDefaultCard, response }))
    .then(() => dispatch(loadAccount(profile.account)))
    .catch(error => {
      const errorMsg =
        error.message ===
        'Your card was declined. Your request was in live mode, but used a known test card.'
          ? 'Your card was declined. You are using a known Test Card.'
          : error.message || 'We are unable to process your payment.';
      dispatch(openSnackbar(errorMsg));
      dispatch({ type: 'PAYMENT_FAILURE', err: errorMsg });
      dispatch(reportPaymentError(errorMsg));
    });
};

/**
 updates the user's subscription after successful authentication of card
 */
export const updateSubscription = ({
  appname,
  plan,
  period,
  callback,
  coupon,
  price,
  isChangingPlan = false,
  planName,
  currency,
  productId,
}) => (dispatch, getState) => {
  const { profile, organisation } = getState().account;
  const { subscription, diySubToCancel } = getState().account.payment;

  const payload = {
    appname,
    plan:
      currency.toLowerCase() === 'gbp'
        ? period === 'yearly'
          ? plan
          : `${plan}-2023`
        : period === 'yearly'
        ? `${plan}-${currency.toLowerCase()}`
        : `${plan}-${currency.toLowerCase()}-2023`,
    period,
    coupon,
    price,
    planName:
      currency.toLowerCase() === 'gbp'
        ? planName
        : `${planName} - ${currency.toUpperCase()}`,
    currency: currency.toLowerCase(),
    subscription,
    diySubToCancel,
    productId,
    isChangingPlan,
  };
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      const options = {
        method: 'post',
        headers,
        body: JSON.stringify(payload),
        mode: 'cors',
      };

      return fetch(
        `${process.env.REACT_APP_PAYMENT_API}/update_subscription`,
        options,
      );
    })
    .then(async res => {
      // console.log('res-->', res);
      if (res.status === 200) {
        dispatch(reportPaymentSuccess());
        dispatch(
          updateContact({
            account: profile.account,
            lists: getListByPlanName(plan, organisation),
            currentPlan: plan,
            coupon,
          }),
        );
        dispatch(clearAutopopupNotices());
        // callback after successful payment for redirect
        callback();
      } else {
        dispatch(openSnackbar('Failed to Process, please try again'));
        dispatch({
          type: 'PAYMENT_FAILURE',
          err: 'Failed to Process, please try again',
        });
        dispatch(reportPaymentError('Failed to Process, please try again'));
      }
    })

    .then(() => dispatch(loadAccount(profile.account)))
    .catch(error => {
      dispatch(openSnackbar(error.message));
      dispatch({
        type: 'PAYMENT_FAILURE',
        err: error.message,
      });
      dispatch(reportPaymentError(error.message));
    });
};

export const receivedProrating = ({ proratingAdjustment, prorationDate }) => ({
  type: RECEIVED_PRORATING,
  proratingAdjustment,
  prorationDate,
});

/**
 * Calculates the plan needed for stripe
 * @param {string} plan - the plan the user is subscripting too
 * @param {string} period - the billing period the user wants
 * @returns {string}
 */
const calculatePlan = ({ plan, period }) => {
  if (period === 'yearly') {
    return `${plan}_yearly`;
  }

  return plan;
};

export const getPriceAfterCoupon = (offerCode, price) => {
  if (offerCode && offerCode === JAN_50_OFF) {
    return String(price * 0.5).slice(0, 4);
  }
  if (offerCode && offerCode.discountPercent) {
    return String(price - price * (offerCode.discountPercent / 100)).slice(
      0,
      4,
    );
  }

  return +String(price);
};

export const getProratingAdjustment = ({ appname }) => (dispatch, getState) => {
  const { subscriptions, auth } = getState().account;
  let { plan } = getState().account.subscribe;
  const { billingPeriod, currency } = getState().account.subscribe;
  const accountId = auth.profile.account;
  const subscription = subscriptions.items[appname];
  const period = subscription.period;

  const hasSelectedSamePlanName = subscription.plan === plan;
  const hasSelectedSamePeriod =
    period === pricing[billingPeriod].title.toLowerCase();

  if (currency && currency.toLowerCase() !== 'gbp')
    plan = `${plan}-${currency.toLowerCase()}`;

  if (!hasSelectedSamePlanName && hasSelectedSamePeriod && subscription.id) {
    dispatch({ type: FETCHING_PRORATING });
    const subscriptionId = subscription.id;
    const selectedPlan = `${calculatePlan({ plan, period })}-2023`;

    const payload = {
      subscriptionId,
      selectedPlan,
      accountId,
    };

    return firebase
      .auth()
      .currentUser.getIdToken()
      .then(token => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('x-access-token', token);

        const options = {
          method: 'post',
          body: JSON.stringify(payload),
          headers,
          mode: 'cors',
        };
        return fetch(
          `${process.env.REACT_APP_PAYMENT_API}/get_prorating_adjustment`,
          options,
        )
          .then(res => res.json())
          .then(body => {
            const { proratingAdjustment, prorationDate } = body;

            if (isNaN(proratingAdjustment)) {
              return Promise.reject('proratingAdjustment is not a number');
            }
            return dispatch(
              receivedProrating({ proratingAdjustment, prorationDate }),
            );
          })
          .catch(error =>
            dispatch({ type: FAILED_FETCHING_PRORATING, data: error }),
          );
      });
  }
  return dispatch(
    receivedProrating({
      proratingAdjustment: null,
      prorationDate: null,
    }),
  );
};

export const enableFreeTrial = () => (dispatch, getState) => {
  const accountId = getState().account.profile.account;
  const accountRef = firebase.database().ref(`accounts/${accountId}`);
  return accountRef
    .child('trial_expired')
    .set(false)
    .then(() => {
      const epoch = new Date().getTime() + 1000 * 60 * 60 * 24 * 14;
      const freeTrialEnd = new Date(epoch).toISOString(); // 2 weeks from now
      return accountRef.child('free_trial').set(freeTrialEnd);
    })
    .then(() => {
      dispatch(resetQueryStringOffer());
      return accountRef.child('offerEligibility').set({});
    })
    .then(() => dispatch(loadAccount(accountId)));
};

export const removeReferalFromAccount = () => (dispatch, getState) => {
  const accountId = getState().account.profile.account;
  const accountRef = firebase.database().ref(`accounts/${accountId}`);

  return accountRef
    .child('referrer')
    .set(null)
    .then(() => accountRef.child('referralOfferEligibility').set(null))
    .catch(err => console.log(err));
};
