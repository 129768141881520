import { connect } from 'react-redux';
import LayoutTab from './LayoutTab';
import { changeLayout } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLayoutChange: (value, type) =>
    dispatch(
      changeLayout(
        ownProps.componentKey,
        ownProps.appname,
        value,
        ownProps.layoutId,
        type,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutTab);
