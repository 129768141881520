import {
  SELECT_IMAGE,
  PRODUCT_STATUS_UPDATED,
  OPEN_PRODUCT_APPROVAL_DIALOG,
  CLOSE_PRODUCT_APPROVAL_DIALOG,
  PRODUCT_UPDATE_ERROR,
} from './types';

import { deselectProduct } from '../products/actions';
import firebase from '../../../../../../firebase';

export const selectImage = (imageSrc, index) => ({
  type: SELECT_IMAGE,
  value: { imageSrc, index },
});

export const productStatusUpdated = () => ({
  type: PRODUCT_STATUS_UPDATED,
});

export const openProductApprovalDialog = () => ({
  type: OPEN_PRODUCT_APPROVAL_DIALOG,
});

export const closeProductApprovalDialog = () => ({
  type: CLOSE_PRODUCT_APPROVAL_DIALOG,
});

export const reportError = errMsg => ({
  type: PRODUCT_UPDATE_ERROR,
  value: errMsg,
});
export const updateProductApprovalStatus = (
  appname,
  product,
  productApprovalStatus,
  message,
) => async dispatch => {
  const user = firebase.auth().currentUser;
  const { uid } = user;
  const headers = new Headers();
  const accessToken = await user.getIdToken();
  headers.append('Content-Type', 'application/json');
  headers.append('x-access-token', accessToken);

  const body = JSON.stringify({
    uid,
    appname,
    productID: product.id,
    productData: product,
    productApprovalStatus,
    message,
    accessToken,
    requestOrigin: 'platform',
  });
  const response = await fetch(
    `${process.env.REACT_APP_MARKETPLACE_API}/update-product-status`,
    {
      method: 'PUT',
      mode: 'cors',
      headers,
      body,
    },
  );

  if (!response.ok) {
    dispatch(reportError(await response.json().message));
  }
  dispatch(productStatusUpdated);
  dispatch(deselectProduct());
};
