import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import Chip from '@material-ui/core/Chip';
import MenuItem from 'material-ui/MenuItem';
import { Trans, withTranslation } from 'react-i18next';
import TableCheckbox from '../../components/tableCheckbox';
import SelectField from '../../../../app/components/selectField';
import ResultsTable from '../../../../app/components/resultsTable';
import TryAgain from '../../../../app/components/tryAgain';
import ActionMenu from '../../../../app/components/actionMenu';
import Header from '../../components/header';
import NewItem from './modules/create';
import EditItem from './modules/edit';
import { Empty as EmptyIcon } from '../../../../app/components/icons';
import { objectToArray, sortArray } from '../../../../utils';
import { STAFF_ADMIN } from '../../../../config';

class CPDs extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    chunks: PropTypes.object,
    getCPDs: PropTypes.func.isRequired,
    onGetCPDFields: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func.isRequired,
    onSelectAllCPDs: PropTypes.func.isRequired,
    onDeselectAllCPDs: PropTypes.func.isRequired,
    selectMemberBody: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    onFilterCPDData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    filter: PropTypes.string,
    profile: PropTypes.object.isRequired,
    fields: PropTypes.object,
    memberbody: PropTypes.string.isRequired,
    memberBodies: PropTypes.object,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: '',
    chunks: {},
    filteredChunks: {},
    items: [],
    query: '',
    selected: [],
    selectAll: false,
    fields: {},
    memberbody: '',
    memberBodies: {},
  };

  componentDidMount() {
    this.props.selectMemberBody();
    this.props.getCPDs();
    this.props.onGetCPDFields();
  }

  getTitle = (selected, cpds) => (
    <span>
      CPDs:{' '}
      {selected.length
        ? `${selected.length} / ${Object.keys(cpds).length}`
        : Object.keys(cpds).length}
    </span>
  );

  getColumns = type => {
    const { fields, onSelect, t } = this.props;
    const fieldsArr = objectToArray(fields).filter(i => i.value.isGridVisible);
    if (type === 'columns') {
      const columns = fieldsArr.map((field, index) => ({
        id: field.id,
        header: field.value.label,
        classNames: index === 0 ? 'col-xs-3' : 'col-xs-2',
        containerElement: ({ value }) =>
          field.id === 'status' ? (
            <div>
              {value ? (
                <Chip color="primary" size="small" label={t('Active')} />
              ) : (
                <Chip color="secondary" size="small" label={t('Inactive')} />
              )}
            </div>
          ) : (
            <div>{value}</div>
          ),
      }));

      columns.push({
        id: 'selected',
        header: '',
        classNames: 'col-xs-1 selected',
        containerElement: ({ value, rowId }) =>
          rowId !== 'default' && (
            <div>
              <TableCheckbox value={value} onSelect={() => onSelect(rowId)} />
            </div>
          ),
      });
      return columns;
    }
    return fieldsArr.map(i => i.id);
  };

  render() {
    const {
      pending,
      items,
      error,
      getCPDs,
      chunks,
      filteredChunks,
      onFilterCPDData,
      selected,
      history,
      match,
      profile,
      onSelectAllCPDs,
      onDeselectAllCPDs,
      onDeleteSelected,
      memberbody,
      memberBodies,
      selectMemberBody,
      t,
    } = this.props;

    const isMemberBodies =
      profile.role === STAFF_ADMIN ? profile.memberBodies : true;

    const body = error ? (
      <TryAgain
        message={t('Oops, something went wrong...')}
        onRetry={() => getCPDs(profile.memberBodies)}
      />
    ) : (
      <ResultsTable
        items={items}
        chunks={filteredChunks}
        loading={pending}
        pending={pending}
        error={Object.keys(filteredChunks).length === 0 ? 'yes' : null}
        emptyMessage={
          memberbody
            ? t('Looks like no cpds have been created yet')
            : t('Please select memberbody from above')
        }
        onRowTouchTap={cpd =>
          history.push(
            profile.role === STAFF_ADMIN
              ? `/${match.params.appname}/staff/cpds/${memberbody}/${cpd}`
              : `/${match.params.appname}/members/cpds/${memberbody}/${cpd}`,
          )
        }
        columns={this.getColumns('columns')}
      />
    );
    return isMemberBodies ? (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <Header
          {...this.props}
          title={this.getTitle(selected, chunks)}
          onChange={val => onFilterCPDData(val, this.getColumns())}
          onSelectAllResponses={onSelectAllCPDs}
          onDeselectAllResponses={onDeselectAllCPDs}
          actionMenu={
            <ActionMenu
              selected={selected}
              onDeleteSelected={() => onDeleteSelected(selected, chunks)}
              text={'cpds'}
            />
          }
          rightAfterSearch={
            profile.role !== STAFF_ADMIN ? (
              <SelectField
                style={{ width: '200px', marginTop: '-20px' }}
                key="memberbody-filter"
                options={sortArray(Object.entries(memberBodies), 'name').map(
                  option => (
                    <MenuItem
                      key={option[0]}
                      value={option[0]}
                      primaryText={option[1].name}
                    />
                  ),
                )}
                label={t('Select Member Body')}
                value={memberbody}
                onChange={(i, payload) => {
                  selectMemberBody(payload);
                  getCPDs();
                  onFilterCPDData('', this.getColumns());
                }}
              />
            ) : null
          }
        />
        {body}
        <NewItem />
        <EditItem />
      </section>
    ) : (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <div className="empty-state">
          <EmptyIcon />
          <Typography
            variant="h5"
            gutterBottom
            style={{ color: '#261ed5', marginTop: '17px' }}
          >
            <WarningIcon style={{ verticalAlign: 'middle' }} />{' '}
            <Trans>No member bodies assigned to you yet</Trans>
          </Typography>
        </div>
      </section>
    );
  }
}

export default withTranslation()(CPDs);
