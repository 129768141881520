import firebase from '../../../firebase';

export const openNotice = noticeId => ({
  type: 'OPEN_NOTICE',
  noticeId,
});

export const closeNotice = () => ({
  type: 'CLOSE_NOTICE',
});

export const markNoticeRead = (account, noticeId) => dispatch => {
  dispatch({ type: 'MARK_NOTICE_READ' });

  firebase
    .database()
    .ref(`accounts/${account}/notices/${noticeId}/read`)
    .set(true);
};

export const removeNotice = (account, noticeId) => dispatch => {
  dispatch({ type: 'REMOVE_NOTICE' });

  firebase
    .database()
    .ref(`accounts/${account}/notices/${noticeId}`)
    .remove();
};

export const closeAutoPopup = (account, noticeId) => dispatch => {
  dispatch({ type: 'CLOSE_AUTOPOPUP' });

  firebase
    .database()
    .ref(`accounts/${account}/notices/${noticeId}`)
    .update({ autoPopup: false, read: true });
};

export const setAutoPopup = (account, noticeId) => dispatch => {
  dispatch({ type: 'SET_AUTOPOPUP', account, noticeId });

  firebase
    .database()
    .ref(`accounts/${account}/notices/${noticeId}`)
    .update({ autoPopup: true, read: false });
};
