import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import EnhancedButton from 'material-ui/internal/EnhancedButton';
import IconButton from 'material-ui/IconButton';
import VisibilityOff from 'material-ui/svg-icons/action/visibility-off';
import styled from 'styled-components';
import NestedPages from '../nestedPages';
import { withTranslation } from 'react-i18next';

import {
  Delete,
  Home,
  Drag,
  TabView as TabViewIcon,
  View as ViewIcon,
} from '../../../../../../components/icons';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import Pip from '../../../../../../components/pip';

import './styles.css';

const styles = {
  iconButton: {
    display: 'block',
    height: 24,
    width: 24,
    padding: 3,
  },
};

const RightIconWrapper = styled.div`
  margin-top: 4px;
  padding: 0 10px;
`;

/**
 * This is a custom version of the material-ui ListItem
 * Created specifically for the design requireents of the Beezer platform
 */
class ViewItem extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
    href: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    onDelete: PropTypes.func,
    hasUnavailaleComponent: PropTypes.bool.isRequired,
    pages: PropTypes.array.isRequired,
    onDuplicate: PropTypes.func,
  };

  static defaultProps = {
    hidden: false,
    onDelete: () => {},
    onDuplicate: () => {},
  };

  componentDidMount = () => {
    // remove fade-in animation after mount to prevent recyling of animation during drag
    this.mounted = true;
  };

  getIcon = () => {
    switch (this.props.type) {
      case 'View':
        return <ViewIcon />;

      case 'TabView':
      case 'NestedTabPage':
        return <TabViewIcon />;

      default:
        return null;
    }
  };

  handleDeleteButtonClick = event => {
    // Stop the event from bubbling up to the list-item
    event.stopPropagation();
    this.props.onDelete();
  };

  handleDuplicateButtonClick = event => {
    // Stop the event from bubbling up to the list-item
    event.stopPropagation();
    this.props.onDuplicate();
  };

  handleKeyboardFocus = () => {};

  handleTouchTap = () => {
    this.props.history.push(this.props.href);
  };

  isHomepage = () => {
    // if (!this.props.index) {
    //   return (
    //     <RightIconWrapper className="icon-right">
    //       <Home />
    //     </RightIconWrapper>
    //   );
    // }

    return (
      <RightIconWrapper className="hover-icon-button icon-right">
        <Drag />
      </RightIconWrapper>
    );
  };

  render() {
    const { t } = this.props;
    const icon = this.getIcon();
    const pip = this.props.hasUnavailaleComponent ? <Pip /> : null;
    const hiddenIcon = this.props.hidden && (
      <VisibilityOff
        color="rgb(167, 169, 172)"
        style={{ height: 18, width: 18, marginRight: 12 }}
      />
    );
    const disabledStyle =
      this.props.pages.length <= 1 ? { display: 'none' } : {};
    const tooltipText = this.props.pages.length <= 1 ? '' : t('Delete Page');

    return (
      <>
        <div id={this.props.id}>
          <EnhancedButton
            ref={node => {
              this.button = node;
            }}
            containerElement={
              <div
                className={`view-item${this.mounted ? '' : ' fade-in'}${
                  this.props.active ? ' active' : ''
                } ${!this.props.index && 'homepage'}`}
              />
            }
            onKeyboardFocus={this.handleKeyboardFocus}
            onClick={this.handleTouchTap}
          >
            <div className="view-item-inner-div">
              <span className="button-group">
                <IconButton
                  disabled={this.props.pages.length <= 1}
                  className="hover-icon-button"
                  tooltip={tooltipText}
                  style={styles.iconButton}
                  onClick={this.handleDeleteButtonClick}
                >
                  <Delete className="small" iconStyle={disabledStyle} />
                </IconButton>
                <IconButton
                  className="hover-icon-button"
                  tooltip={t('Duplicate a Page')}
                  style={styles.iconButton}
                  onClick={this.handleDuplicateButtonClick}
                >
                  <FileCopyOutlinedIcon color="primary" fontSize="small" />
                </IconButton>
              </span>
              <span className="view-item-label">
                {this.props.label}
                {pip}
              </span>
              {hiddenIcon}
              {this.isHomepage()}
            </div>
          </EnhancedButton>
          {this.props.active &&
            this.props.type !== 'TabView' &&
            this.props.nestedPages.length > 0 && (
              <NestedPages nestedPages={this.props.nestedPages} />
            )}
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(ViewItem));
