import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Title from './Title';
import { designChange, fieldChange } from '../header/actions';

const mapStateToProps = (state, ownProps) => {
  const dataLink = ownProps.data.link;
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];

  const link = {
    button: {},
    linkType: {
      page: dataLink.page,
      web: dataLink.web,
      phone: dataLink.phone,
      email: dataLink.email,
      selectedOption: dataLink.selectedOption,
    },
    enabled: ownProps.data && ownProps.data.link && ownProps.data.link.enabled,
  };

  return {
    ...ownProps,
    design: ownProps.component.design,
    appname: ownProps.match.params.appname,
    editorContent: ownProps.data.editorContent,
    pages: layout
      ? layout.childRoutes.map(key => state.editor.data.alwaysData[key])
      : [],
    link,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: (field, value) =>
      dispatch(
        fieldChange(
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
          field,
          value,
        ),
      ),
    onDesignChange: (field, value) => {
      return dispatch(
        designChange(
          ownProps.match.params.appname,
          queryString.parse(ownProps.location.search).component,
          field,
          value,
        ),
      );
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Title));
