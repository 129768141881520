import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Close from 'material-ui/svg-icons/navigation/close';
import Dialog from 'material-ui/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { Trans, useTranslation } from 'react-i18next';
import { OPTIONS } from '../../../../../../config';
import MenuItem from 'material-ui/MenuItem';
import DialogConfirmation from '../../../../../../app/components/dialogConfirmation';
import TextField from '../../../../../../app/components/textField';
import CircularValidation from '../../../../../../app/components/circularValidation';
import SelectField from '../../../../../../app/components/selectField';
import { FlatButton } from '../../../../../../app/components/buttons';
import { CircularProgress } from '../../../../../../app/components/progress';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { objectToArray } from '../../../../../../utils';

import themeStyles from '../../../../../../_export.scss';
import '../../styles.scss';

const TrackSwitchedColor = { backgroundColor: themeStyles.primaryColorLight };
const ThumbSwitchedColor = { backgroundColor: themeStyles.primaryColor };

const ProfileFields = ({
  profileEnabled,
  firstNameRequired,
  lastNameRequired,
  organisationRequired,
  roleRequired,
  dobRequired,
  phoneNumberRequired,
  linkedInAddressRequired,
  twitterAddressRequired,
  websiteAddressRequired,
  interestsRequired,
  updateToggleField,
  firstNameVisible,
  lastNameVisible,
  organisationVisible,
  roleVisible,
  dobVisible,
  phoneNumberVisible,
  linkedInAddressVisible,
  twitterAddressVisible,
  websiteAddressVisible,
  interestsVisible,
  onFieldChange,
  userProfileField,
  onSubmit,
  fetchCustomUserProfileField,
  customProfileFields,
  handleDelete,
  open,
  openFieldDialog,
  closeFieldDialog,
  handleCustomFieldClick,
  customFieldKey,
  updateCustomField,
  updateCustomToggleField,
  groupsData,
  handleDuplicate,
}) => {
  const { t } = useTranslation();

  const fixedProfileFields = [
    {
      title: t('First Name'),
      requiredField: 'firstNameRequired',
      visibleField: 'firstNameVisible',
      toggleRequiredField: firstNameRequired,
      toggleVisibleField: firstNameVisible,
    },
    {
      title: t('Last Name'),
      requiredField: 'lastNameRequired',
      visibleField: 'lastNameVisible',
      toggleRequiredField: lastNameRequired,
      toggleVisibleField: lastNameVisible,
    },
    {
      title: t('Organisation'),
      requiredField: 'organisationRequired',
      visibleField: 'organisationVisible',
      toggleRequiredField: organisationRequired,
      toggleVisibleField: organisationVisible,
    },
    {
      title: t('Role'),
      requiredField: 'roleRequired',
      visibleField: 'roleVisible',
      toggleRequiredField: roleRequired,
      toggleVisibleField: roleVisible,
    },
    {
      title: t('Date Of Birth'),
      requiredField: 'dobRequired',
      visibleField: 'dobVisible',
      toggleRequiredField: dobRequired,
      toggleVisibleField: dobVisible,
    },
    {
      title: t('Phone Number'),
      requiredField: 'phoneNumberRequired',
      visibleField: 'phoneNumberVisible',
      toggleRequiredField: phoneNumberRequired,
      toggleVisibleField: phoneNumberVisible,
    },
    {
      title: t('LinkedIn Address'),
      requiredField: 'linkedInAddressRequired',
      visibleField: 'linkedInAddressVisible',
      toggleRequiredField: linkedInAddressRequired,
      toggleVisibleField: linkedInAddressVisible,
    },
    {
      title: t('Twitter Handle'),
      requiredField: 'twitterAddressRequired',
      visibleField: 'twitterAddressVisible',
      toggleRequiredField: twitterAddressRequired,
      toggleVisibleField: twitterAddressVisible,
    },
    {
      title: t('Website URL'),
      requiredField: 'websiteAddressRequired',
      visibleField: 'websiteAddressVisible',
      toggleRequiredField: websiteAddressRequired,
      toggleVisibleField: websiteAddressVisible,
    },
    {
      title: t('Interests'),
      requiredField: 'interestsRequired',
      visibleField: 'interestsVisible',
      toggleRequiredField: interestsRequired,
      toggleVisibleField: interestsVisible,
    },
  ];
  const [isOptions, setVisible] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  useEffect(() => {
    fetchCustomUserProfileField();
  }, []);

  const fieldOptions = OPTIONS.map(data => (
    <MenuItem key={data.id} value={data.id} primaryText={data.value} />
  ));

  const groupOptions = objectToArray(groupsData).map(data => (
    <MenuItem key={data.id} value={data.id} primaryText={data.value.name} />
  ));

  const onChangetype = payload => {
    setVisible(payload === 'Dropdown');
    onFieldChange('type', payload);
  };

  const onGroupSelect = payload => {
    onFieldChange('visibleToGroups', payload);
  };

  // const handleField

  const form = (
    <div>
      <h5>
        <Trans>Create a field</Trans>
      </h5>
      <TextField
        id="name"
        placeholder={t('Name')}
        {...userProfileField.name}
        multiLine
        onChange={value => onFieldChange('name', value)}
        rightInputAction={
          <CircularValidation
            isValid={userProfileField && userProfileField.name.isValid}
            defaultMsg={t("Group's name required")}
            errorMsg={
              userProfileField.name.value.length > 73
                ? t('Name must not be more than 73 characters long')
                : t('Name must be at least 2 characters long')
            }
            showDefault={userProfileField && !userProfileField.name.value}
          />
        }
      />
      <SelectField
        className="offset-top"
        options={fieldOptions}
        label={t('Select Type')}
        onChange={(i, payload) => onChangetype(payload)}
        {...userProfileField.type}
      />

      {(isOptions ||
        (userProfileField.options.value &&
          userProfileField.type.value === 'Dropdown')) && (
        <React.Fragment>
          <TextField
            id="options"
            placeholder={t('Dropdown Options')}
            {...userProfileField.options}
            multiLine
            onChange={value => onFieldChange('options', value)}
            rightInputAction={
              <CircularValidation
                isValid={userProfileField && userProfileField.options.isValid}
                defaultMsg={t('Add multiple options using comma separated')}
                errorMsg={t('Options must be at least 2 characters long')}
                showDefault={
                  userProfileField && !userProfileField.options.value
                }
              />
            }
          />
          <Toggle
            className="small-toggle"
            onToggle={(v, b) => onFieldChange('isMultiple', b)}
            toggled={userProfileField && userProfileField.isMultiple.value}
            label={t('Multiple Selection')}
          />
        </React.Fragment>
      )}

      <SelectField
        className="offset-top"
        options={groupOptions}
        label={t('Select group visibility')}
        onChange={(i, payload) => onGroupSelect(payload)}
        {...userProfileField.visibleToGroups}
        warning={
          !userProfileField.visibleToGroups.isValid &&
          userProfileField.visibleToGroups.isDirty
            ? t('Select at least 1 group')
            : ''
        }
        multiple
      />

      <Toggle
        className="small-toggle"
        onToggle={(v, b) => {
          if (userProfileField && userProfileField.isVisible.value) {
            onFieldChange('isRequired', b);
          }
        }}
        toggled={userProfileField && userProfileField.isRequired.value}
        label={t('Required')}
      />
      <Toggle
        className="small-toggle"
        onToggle={(v, b) => {
          onFieldChange('isVisible', b);
          if (!b) {
            onFieldChange('isRequired', b);
          }
        }}
        toggled={userProfileField && userProfileField.isVisible.value}
        label={t('Publicly Visible')}
      />
    </div>
  );

  const buttonSubmit = !userProfileField.pending ? (
    <FlatButton
      label={customFieldKey ? t('Update') : t('Create')}
      onClick={() =>
        customFieldKey ? updateCustomField(customFieldKey) : onSubmit()
      }
      disabled={
        !userProfileField.name.isValid ||
        !userProfileField.type.isValid ||
        !userProfileField.visibleToGroups.isValid ||
        (userProfileField.type.value === 'Dropdown' &&
          !userProfileField.options.isValid)
      }
      primary
    />
  ) : (
    <CircularProgress size={18} />
  );
  return (
    <div>
      <div className="auth-panel">
        <div className="auth-panel-title">
          <Trans>User Profile Fields</Trans>
        </div>
        {profileEnabled ? (
          <>
            <div className="auth-panel">
              <Grid container>
                <Grid item xs={8} />
                <Grid item xs={2}>
                  <span className="label">
                    <Trans>Visibility</Trans>
                  </span>
                </Grid>
                <Grid item xs={2}>
                  <span className="label">
                    <Trans>Required</Trans>
                  </span>
                </Grid>
                {fixedProfileFields.map((field, i) => (
                  <>
                    <Grid item xs={8}>
                      <span className="label">{field.title}</span>
                    </Grid>
                    <Grid item xs={2}>
                      <Toggle
                        style={{ margin: '0' }}
                        onToggle={(v, b) => {
                          updateToggleField(b, field.visibleField);
                          if (!b) {
                            updateToggleField(b, field.requiredField);
                          }
                        }}
                        toggled={field.toggleVisibleField}
                        trackSwitchedStyle={TrackSwitchedColor}
                        thumbSwitchedStyle={ThumbSwitchedColor}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Toggle
                        style={{ margin: '0' }}
                        onToggle={(v, b) => {
                          if (
                            field.toggleVisibleField ||
                            !field.requiredField
                          ) {
                            updateToggleField(b, field.requiredField);
                          }
                        }}
                        toggled={field.toggleRequiredField}
                        trackSwitchedStyle={TrackSwitchedColor}
                        thumbSwitchedStyle={ThumbSwitchedColor}
                      />
                    </Grid>
                  </>
                ))}
                {customProfileFields &&
                  customProfileFields.map((field, i) => {
                    return (
                      <>
                        <Grid item xs={8}>
                          <div className="custom-field">
                            <IconButton
                              onClick={() =>
                                setShowDeleteDialog({
                                  open: true,
                                  field: field[0],
                                })
                              }
                              color="secondary"
                            >
                              <RemoveCircleOutline />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleDuplicate(field[1], field[0])
                              }
                              color="secondary"
                            >
                              <FileCopyOutlinedIcon
                                color="primary"
                                fontSize="small"
                              />
                            </IconButton>
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() =>
                                handleCustomFieldClick(field[0], i)
                              }
                              className="btnField"
                            >
                              <span className="label">
                                {field[1].name.value}
                              </span>
                            </Link>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <Toggle
                            style={{ margin: '0' }}
                            onToggle={(v, b) => {
                              updateCustomToggleField(b, 'isVisible', field[0]);
                              if (!b) {
                                updateCustomToggleField(
                                  b,
                                  'isRequired',
                                  field[0],
                                );
                              }
                            }}
                            toggled={field[1].isVisible.value}
                            trackSwitchedStyle={TrackSwitchedColor}
                            thumbSwitchedStyle={ThumbSwitchedColor}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Toggle
                            style={{ margin: '0' }}
                            onToggle={(v, b) => {
                              if (field[1].isVisible.value || !b) {
                                updateCustomToggleField(
                                  b,
                                  'isRequired',
                                  field[0],
                                );
                              }
                            }}
                            toggled={field[1].isRequired.value}
                            trackSwitchedStyle={TrackSwitchedColor}
                            thumbSwitchedStyle={ThumbSwitchedColor}
                          />
                        </Grid>
                      </>
                    );
                  })}
              </Grid>
              <Link
                component="button"
                variant="body2"
                onClick={() => openFieldDialog()}
                className="btnField"
              >
                <span>
                  <Trans>Add User Profile Field</Trans>
                </span>
              </Link>
              <Dialog
                open={open}
                onRequestClose={() => closeFieldDialog()}
                autoScrollBodyContent
              >
                <div className="add-field">
                  <div className="modal-controls-container">
                    <IconButton onClick={() => closeFieldDialog()}>
                      <Close />
                    </IconButton>
                  </div>
                  {form}
                  {buttonSubmit}
                </div>
              </Dialog>
              {/* <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => updateField(b, 'firstNameRequired')}
            toggled={firstNameRequired}
            label="First Name"
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => updateField(b, 'lastNameRequired')}
            toggled={lastNameRequired}
            label="Last Name"
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => updateField(b, 'organisationRequired')}
            toggled={organisationRequired}
            label="Organisation"
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => updateField(b, 'roleRequired')}
            toggled={roleRequired}
            label="Role"
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => updateField(b, 'dobRequired')}
            toggled={dobRequired}
            label="Date Of Birth"
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => updateField(b, 'phoneNumberRequired')}
            toggled={phoneNumberRequired}
            label="Phone Number"
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => updateField(b, 'linkedInAddressRequired')}
            toggled={linkedInAddressRequired}
            label="LinkedIn Address"
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => updateField(b, 'twitterAddressRequired')}
            toggled={twitterAddressRequired}
            label="Twitter Handle"
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => updateField(b, 'websiteAddressRequired')}
            toggled={websiteAddressRequired}
            label="Website URL"
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => updateField(b, 'interestsRequired')}
            toggled={interestsRequired}
            label="Interests"
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          /> */}
            </div>
            {showDeleteDialog.open && (
              <DialogConfirmation
                open={showDeleteDialog.open}
                onClose={() =>
                  setShowDeleteDialog({
                    open: false,
                    field: null,
                  })
                }
                title="Delete Field"
                message={t(`Are you sure you want to delete this field?`)}
                action={() => handleDelete(showDeleteDialog.field)}
              />
            )}
          </>
        ) : (
          <p>
            <Trans>User Profile is turned off</Trans>
          </p>
        )}
      </div>
    </div>
  );
};

ProfileFields.propTypes = {
  profileEnabled: PropTypes.bool,
  firstNameRequired: PropTypes.bool.isRequired,
  lastNameRequired: PropTypes.bool.isRequired,
  organisationRequired: PropTypes.bool,
  dobRequired: PropTypes.bool,
  roleRequired: PropTypes.bool,
  phoneNumberRequired: PropTypes.bool,
  linkedInAddressRequired: PropTypes.bool,
  twitterAddressRequired: PropTypes.bool,
  websiteAddressRequired: PropTypes.bool,
  interestsRequired: PropTypes.bool,
  updateToggleField: PropTypes.func.isRequired,
};

ProfileFields.defaultProps = {
  profileEnabled: true,
  signupEnabled: true,
};

export default ProfileFields;
