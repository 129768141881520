const appSettings = (state = {}, action) => {
  switch (action.type) {
    case 'HYDRATE_ICON_DATA':
      return {
        ...state,
        [action.appname]: {
          icons: action.data,
          icon: action.data ? action.data['512x512'] : undefined,
        },
      };

    default:
      return state;
  }
};

export default appSettings;
