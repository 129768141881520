import React from 'react';
import Toggle from 'material-ui/Toggle';
import { Trans, useTranslation } from 'react-i18next';
import Container from 'app/components/container';
import SectionHeader from 'modules/editor/components/sectionHeader';
import TooltipElement from 'app/components/tooltipElement';
import './styles.css';

const Accessibility = ({
  updateAccessibilitySettings,
  rtlSupport,
  forceRTLSupport,
  isPinchZoomDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <SectionHeader
        title={t('RTL support')}
        tooltip={t('RTL(Right To Left Language) support for your app')}
      />
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <Toggle
          label={t('Enable RTL layout')}
          toggled={rtlSupport}
          onToggle={(i, toggled) => {
            updateAccessibilitySettings('rtlSupport', toggled);
          }}
          className="full-width toggle-days-sec"
        />
        <TooltipElement
          tooltip={t('Enable RTL layout only for supported languages')}
          tooltipType="info"
        />
      </div>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <Toggle
          label={t('Force RTL layout')}
          toggled={forceRTLSupport}
          onToggle={(i, toggled) => {
            updateAccessibilitySettings('forceRTLSupport', toggled);
          }}
          className="full-width toggle-days-sec"
        />
        <TooltipElement
          tooltip={t('Enable RTL layout for all languages')}
          tooltipType="info"
        />
      </div>
      <SectionHeader
        title={t('Zoom Setting')}
        tooltip={t('Page zoom settings for your app')}
      />
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <Toggle
          label={t('Disable pinch zoom')}
          toggled={isPinchZoomDisabled}
          onToggle={(i, toggled) => {
            updateAccessibilitySettings('isPinchZoomDisabled', toggled);
          }}
          className="full-width toggle-days-sec"
        />
        <TooltipElement
          tooltip={t('Disable pinch zoom for app pages')}
          tooltipType="info"
        />
      </div>
    </Container>
  );
};

export default Accessibility;
