const initialState = {
  open: false,
};

const welcomeMsg = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_HALLOWEEN_MSG':
      return {
        ...state,
        open: action.data,
      };
    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default welcomeMsg;
