/* eslint no-underscore-dangle: 0 */
const parseWPCategories = receivedCategories =>
  receivedCategories.reduce(
    (acc, category) => {
      if (category.count) {
        acc[category.slug] = {
          id: category.id,
          name: category.name,
          slug: category.slug,
          pages: {},
          count: category.count,
          pageCount: Math.ceil(category.count / 10),
          ...(category.description
            ? {
                description: category.description,
              }
            : {}),
        };
      }

      return acc;
    },
    {
      all: {
        id: 'all',
        name: 'All',
        slug: 'page',
        pages: {},
        count: null,
        pageCount: null,
      },
    },
  );

export default parseWPCategories;
