import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalDetails from '../../../components/modalDetails';
import Table from '../../../../../app/components/table';
import ResultsTable from '../../../../../app/components/resultsTable';
import TableCheckbox from '../../../components/tableCheckbox';
import { withTranslation, Trans } from 'react-i18next';

import {
  downloadCSV,
  formatDate,
  capitaliseString,
} from '../../../../../utils';

import './styles.css';

class VoteDetails extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    data: PropTypes.object,
    responseKey: PropTypes.string,
    onRequestClose: PropTypes.func.isRequired,
    totalResponses: PropTypes.number.isRequired,
    currentResponse: PropTypes.number.isRequired,
    onPreviousResponse: PropTypes.func.isRequired,
    onNextResponse: PropTypes.func.isRequired,
    votes: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    filteredChunks: PropTypes.object,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    data: null,
    responseKey: null,
    onReply: null,
    onFavorite: null,
    onDownload: null,
    onDelete: null,
    onForward: null,
    filteredChunks: {},
  };

  state = {
    confirmDeleteDialogOpen: false,
  };

  getVoteFields = data => {
    const { t } = this.props;
    return data.order.map(key => (
      <div className="field" key={data.chunks[key].label}>
        <span className="label">{data.chunks[key].label}</span>
        <span className="value">
          {typeof data.chunks[key].value !== 'boolean'
            ? data.chunks[key].value || 'n/a'
            : `${data.chunks[key].value}` === 'true'
            ? t('Selected')
            : t('Not Selected')}
        </span>
      </div>
    ));
  };

  getVotePercentage = (numberOfVotes, totalVotes) =>
    numberOfVotes ? `${Math.round((numberOfVotes / totalVotes) * 100)}%` : `0%`;

  getCSV = (data, votes, current) => {
    const { t } = this.props;
    const cols = [t('option'), t('number of votes'), 'votes %'];
    let values = '';
    votes[current].options.order.forEach(key => {
      values += `"${votes[current].options.chunks[key].title}",${data[key] ||
        0},"${this.getVotePercentage(
        data[key],
        this.getTotalVotesCount(data),
      )}"\n`;
    });
    let body = `"${cols.join('","')}"\n`;
    body += `${values}\n`;

    if (data.usersData) {
      body += `\n\n\n\n`;

      const formCols = [t('date'), t('title')];

      let formValues = '';

      Object.keys(data.usersData).map((key1, i) => {
        formValues += `"${formatDate(data.usersData[key1].date)}","${
          data.usersData[key1].title
        }",`;
        data.usersData[key1].order.forEach(key2 => {
          if (i === 0) formCols.push(data.usersData[key1].chunks[key2].label);
          formValues += `"${data.usersData[key1].chunks[key2].value ||
            'n/a'}",`;
        });
        formValues += `\n`;
      });
      body += `"${formCols.join('","')}"\n`;
      body += `${formValues}\n`;
    }

    return body;
  };

  getVotesTable = () => {
    const { filteredChunks, votes } = this.props;
    const tableCols = this.generateTableColumns();
    const tableData = this.getTableData(filteredChunks, votes);
    const tableItems = this.getTableItems(filteredChunks, votes);

    return (
      tableItems &&
      tableData &&
      tableCols && (
        <Table items={tableItems} chunks={tableData} cols={tableCols} />
      )
    );
  };

  getFormColumns = (unique, maxFields = 3) => {
    const { filteredChunks, items } = this.props;
    const formCols = {};
    Object.values(filteredChunks[this.props.responseKey].usersData).forEach(
      (chunk, index) => {
        const responseKey = items[0];

        formCols[responseKey] = [];
        chunk.order.forEach((key, i) => {
          const field = chunk.chunks[key];
          formCols[responseKey].push(
            unique ? `field-${i}` : field.label.toLowerCase(),
          );
        });
      },
    );

    return formCols;
  };

  noUniqueColumns = formCols =>
    Object.values(formCols).reduce((acc, current) =>
      JSON.stringify(current) !== JSON.stringify(acc) ? false : current,
    );

  getFormTable = () => {
    let formCols = this.getFormColumns(false);
    const noUnique = this.noUniqueColumns(formCols);
    if (!noUnique) {
      formCols = this.getFormColumns(true);
    }

    return this.generateFormTableColumns(formCols, noUnique);
  };

  getTotalVotesCount = votes =>
    Object.entries(votes)
      .map(vote => (typeof vote[1] === 'number' ? vote[1] : 0))
      .reduce((a, b) => a + b, 0);

  getWinningOption = votes => {
    const max = {
      count: 0,
      key: '',
    };
    Object.entries(votes[1]).forEach(vote => {
      if (vote[1] > max.count) {
        max.key = vote[0];
        max.count = vote[1];
      }
    });
    return max;
  };

  getTableData = (voteResults, voteComponents) => {
    const tableData = {};
    Object.entries(voteComponents).forEach(component => {
      if (component[0] === this.props.responseKey) {
        component[1].options.order.forEach(key => {
          if (voteResults[this.props.responseKey]) {
            const voteOption = component[1].options.chunks[key];
            tableData[key] = {
              option: voteOption.title,
              number: `${voteResults[this.props.responseKey][key] || 0}`,
              percentage: `${this.getVotePercentage(
                voteResults[this.props.responseKey][key],
                this.getTotalVotesCount(voteResults[this.props.responseKey]),
              )}`,
            };
          }
        });
      }
    });
    return tableData;
  };

  generateFormTableData = noUniqueColumns => {
    const { filteredChunks, items, votes, responseKey, t } = this.props;
    const tableData = {};
    const tempArr = Object.keys(filteredChunks[responseKey].usersData);
    tempArr.forEach(index => {
      const chunk = filteredChunks[responseKey].usersData[index];
      tableData[index] = {
        ...chunk,
        id: (tempArr.indexOf(chunk.id) + 1).toString(),
      };
      chunk.order.forEach((key, i) => {
        const field = chunk.chunks[key];
        tableData[index][
          !noUniqueColumns ? `field-${i}` : field.label.toLowerCase()
        ] =
          typeof field.value !== 'boolean'
            ? field.value
            : `${field.value}` === 'true'
            ? t('Selected')
            : t('Not Selected');
      });
    });

    return tableData;
  };

  getFormTableData = (voteResults, voteComponents) => {
    if (
      Object.values(this.props.filteredChunks).length &&
      this.props.items.length
    ) {
      const formCols = this.getFormColumns(false);
      const noUnique = this.noUniqueColumns(formCols);
      return this.generateFormTableData(noUnique);
    }
    return {};
  };

  getTableItems = (voteResults, voteComponents) => {
    const tableItems = [];
    Object.entries(voteComponents).forEach(component => {
      if (component[0] === this.props.responseKey) {
        component[1].options.order.forEach(key => {
          if (tableItems.indexOf(key) < 0) {
            tableItems.push(key);
          }
        });
      }
    });
    return tableItems;
  };

  getModalContent = () => {
    const { t } = this.props;
    return (
      <>
        <div className="vote-modal-content">{this.getVotesTable()}</div>

        {this.props.filteredChunks[this.props.responseKey].usersData && (
          <ResultsTable
            {...this.props}
            columns={this.getFormTable()}
            chunks={this.getFormTableData()}
            emptyMessage={t('Looks like you have no form responses yet')}
            optionalMessage={t('hello')}
            pending={false}
            onRowTouchTap={() => console.log('touched')}
            items={Object.keys(
              this.props.filteredChunks[this.props.responseKey].usersData,
            )}
          />
        )}
      </>
    );
  };

  handleCloseDeleteDialog = () =>
    this.setState({ confirmDeleteDialogOpen: false });

  generateTableColumns = () => {
    const { t } = this.props;
    const columns = [
      {
        id: 'option',
        header: t('Vote Option'),
        classNames: 'col-xs-4',
      },
      {
        id: 'number',
        header: t('Number of Votes'),
        classNames: 'col-xs-4',
      },
      {
        id: 'percentage',
        header: t('Vote %'),
        classNames: 'col-xs-4',
      },
    ];
    return columns;
  };
  getFieldNames = (formCols, noUniqueColumns, maxFieldCols) => {
    const columns = [];
    if (!noUniqueColumns) return columns;
    const nonUniqueColumns = this.noUniqueColumns(formCols);
    nonUniqueColumns.forEach((field, index) => {
      if (index < maxFieldCols) {
        columns.push({
          id: `${field}`,
          header: `${capitaliseString(field)}`,
          classNames: `col-xs-${nonUniqueColumns.length > 2 ? '2' : '3'}`,
        });
      }
    });
    return columns;
  };

  getGenericFieldNames = (formCols, noUniqueColumns, maxFieldCols) => {
    const columns = [];
    if (noUniqueColumns) return columns;
    let maxCols = 0;
    Object.values(formCols).forEach(field => {
      maxCols = maxCols < field.length ? field.length : maxCols;
    });
    for (let i = 0; i < maxCols && i < maxFieldCols; i += 1) {
      columns.push({
        id: `field-${i}`,
        header: `Field ${i + 1}`,
        classNames: 'col-xs-2',
      });
    }
    return columns;
  };

  generateFormTableColumns = (formCols, noUniqueColumns) => {
    const { t } = this.props;
    const maxFieldCols = 3;
    const columns = [
      {
        id: 'date',
        header: t('Date'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) => (
          <>
            <span>{value && formatDate(value)}</span>
          </>
        ),
      },
      {
        id: 'title',
        header: t('Form Name'),
        classNames: 'col-xs-2',
      },
      ...this.getFieldNames(formCols, noUniqueColumns, maxFieldCols),
      ...this.getGenericFieldNames(formCols, noUniqueColumns, maxFieldCols),
    ];
    return columns;
  };

  render() {
    const {
      data,
      responseKey,
      totalResponses,
      currentResponse,
      onPreviousResponse,
      onNextResponse,
      votes,
      items,
      onDelete,
      t,
    } = this.props;

    return data ? (
      <ModalDetails
        {...this.props}
        title={
          votes[responseKey] && votes[responseKey].title
            ? `${t('Vote  Results')} - ${votes[responseKey].title}`
            : t('Vote Results')
        }
        onNextItem={() => onNextResponse(currentResponse, votes, items)}
        onPreviousItem={() => onPreviousResponse(currentResponse, votes, items)}
        totalItems={totalResponses}
        currentItem={currentResponse}
        navigationText="vote components"
        onDownloadCsv={() =>
          downloadCSV(this.getCSV(data, votes, responseKey), 'votes-export')
        }
        deleteConfirmationMessage={t(
          'Are you sure you want to permanently delete all user votes for this Vote Component?',
        )}
        onDelete={() => onDelete([responseKey])}
        content={this.getModalContent()}
      />
    ) : null;
  }
}

export default withTranslation()(VoteDetails);
