import React from 'react';
import { withRouter } from 'react-router-dom';
import Icon from './pgmolIcon.png';
import selectedIcon from './pgmolIconSelected.png';
import './styles.css';

const PgmolIcon = props => {
  const appname = props.match.params.appname;

  return props.location.pathname.includes(`/${appname}/pgmol`) ? (
    <img src={selectedIcon} alt="" width={32} height={32} />
  ) : (
    <img src={Icon} alt="" width={32} height={32} />
  );
};

export default withRouter(PgmolIcon);
