import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';

import Map from './Map';

import {
  threeMonthsVisits,
  sixMonthsVisits,
  oneYearVisits,
  threeMonthsInstalls,
  sixMonthsInstalls,
  oneYearInstalls,
} from './actions';

const mapStateToProps = (state, ownProps) => {
  return {
    // plans: state.account.subscriptions.items,
    map: state.analytics.map,
    // integrationType: _get(state, 'account.organisation.integration.type'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onThreeMonthsVisits: () =>
    dispatch(threeMonthsVisits(ownProps.match.params.appname)),
  onSixMonthsVisits: () =>
    dispatch(sixMonthsVisits(ownProps.match.params.appname)),
  onOneYearVisits: () => dispatch(oneYearVisits(ownProps.match.params.appname)),
  onThreeMonthsInstalls: () =>
    dispatch(threeMonthsInstalls(ownProps.match.params.appname)),
  onSixMonthsInstalls: () =>
    dispatch(sixMonthsInstalls(ownProps.match.params.appname)),
  onOneYearInstalls: () =>
    dispatch(oneYearInstalls(ownProps.match.params.appname)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Map));
