import { connect } from 'react-redux';
import Courses from './Courses';
import { withRouter } from 'react-router-dom';

import {
  filterCourseData,
  getCourses,
  selectCourse,
  selectAllCourses,
  deselectAllCourses,
  deleteCourses,
  getCourseFields,
  selectMemberBody,
  onDuplicateSelected,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.staff.courses.list,
  profile: state.account.profile,
  memberBodies: state.auth.memberBodies.data.chunks,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFilterCourseData: (filter, keys) =>
    dispatch(filterCourseData(filter, keys)),
  getCourses: () => dispatch(getCourses(ownProps.match.params.appname)),
  onSelect: key => dispatch(selectCourse(key)),
  onSelectAllCourses: () => dispatch(selectAllCourses()),
  onDeselectAllCourses: () => dispatch(deselectAllCourses()),
  onDeleteSelected: () =>
    dispatch(deleteCourses(ownProps.match.params.appname)),
  onDuplicateSelected: rowId =>
    dispatch(onDuplicateSelected(ownProps.match.params.appname, rowId)),
  onGetCourseFields: () =>
    dispatch(getCourseFields(ownProps.match.params.appname)),
  selectMemberBody: key => dispatch(selectMemberBody(key)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Courses),
);
