import { combineReducers } from 'redux';

import auth from './modules/auth/reducer';
import register from './modules/register/reducer';
import profile from './modules/profile/reducer';
import staffProfile from './modules/staffProfile/reducer';
import billing from './modules/billing/reducer';
import appSumoBilling from './modules/appSumoBilling/reducer';
import deleteUserByEmailReducer from './modules/account/deleteUserByEmailModal/reducer';
import subscriptions from './modules/subscriptions/reducer';
import subscribe from './modules/subscribe/reducer';
import buySMS from './modules/buySMS/reducer';
import buyCredits from './modules/buyCredits/reducer';
import payment from './modules/payment/reducer';
import legal from './modules/legal/reducer';
import firebaseActions from './modules/firebaseActions/reducer';
import forgottenPassword from './modules/forgottenPassword/reducer';
import resetEmail from './modules/resetEmail/reducer';
import resetPassword from './modules/resetPassword/reducer';
import integration from './modules/integration/reducer';
import offer from './modules/offers/reducer';
import referral from './modules/referral/reducer';
import clickbankThankyou from './modules/clickbankThankyou/reducer';
import updatedPasswordReducer from './modules/updatePassword/reducer';
import { HYDRATE_INTL_CONFIG, UPDATE_WIX_SMS_URLS } from './actions';

const initialState = { apps: {}, appsumo_id: '' };

const organisation = (state = initialState, action) => {
  switch (action.type) {
    case 'HYDRATE_ACCOUNT':
      return {
        ...action.data,
      };

    case 'UPDATE_ACCOUNT':
      return {
        ...state,
        [action.prop]: action.data,
      };

    case UPDATE_WIX_SMS_URLS:
      return {
        ...state,
        integration: {
          ...state.integration,
          [action.prop]: action.data,
        },
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

const modal = (state = false, action) => {
  switch (action.type) {
    case 'TOGGLE_ACCOUNT_MODAL':
      return !state;

    case 'LOGOUT_SUCCESS':
      return false;
    default:
      return state;
  }
};

const currentApp = (state = '', action) => {
  switch (action.type) {
    case 'CURRENT_APP_CHANGE':
      return action.value;

    case 'LOGOUT_SUCCESS':
      return '';
    default:
      return state;
  }
};

const intl = (state = {}, action) => {
  switch (action.type) {
    case HYDRATE_INTL_CONFIG:
      return {
        ...action.data,
      };
    case 'LOGOUT_SUCCESS':
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  modal,
  organisation,
  currentApp,
  auth,
  profile,
  staffProfile,
  register,
  deleteUserByEmailReducer,
  billing,
  appSumoBilling,
  subscriptions,
  subscribe,
  buySMS,
  buyCredits,
  payment,
  legal,
  intl,
  firebaseActions,
  forgottenPassword,
  resetEmail,
  resetPassword,
  integration,
  offer,
  referral,
  clickbankThankyou,
  updatedPasswordReducer,
});
