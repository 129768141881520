import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import TextField from '../../../../app/components/textField';
import { FlatButton } from '../../../../app/components/buttons';

import './styles.css';

class ResetEmail extends Component {
  static propTypes = {
    oldEmail: PropTypes.object.isRequired,
    password: PropTypes.object.isRequired,
    newEmail: PropTypes.object.isRequired,
    pending: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    success: PropTypes.bool.isRequired,
    onOldEmailChange: PropTypes.func.isRequired,
    onPasswordChange: PropTypes.func.isRequired,
    onNewEmailChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    clearForm: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    errorMessage: null,
  };

  componentWillUnmount() {
    this.props.clearForm();
  }

  render() {
    const {
      oldEmail,
      password,
      newEmail,
      pending,
      success,
      errorMessage,
      onOldEmailChange,
      onPasswordChange,
      onNewEmailChange,
      onSubmit,
      t,
    } = this.props;

    return (
      <div className="reset-email">
        <div>
          <div className="reset-email-fieldset">
            <TextField
              id="old-email"
              type="email"
              placeholder={t('Old Email')}
              {...oldEmail}
              onChange={onOldEmailChange}
              required
            />
            <TextField
              id="password"
              type="password"
              placeholder={t('Password')}
              {...password}
              onChange={onPasswordChange}
              required
            />
            <TextField
              id="new-email"
              type="email"
              placeholder={t('New Email')}
              {...newEmail}
              onChange={onNewEmailChange}
              required
            />
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <FlatButton
            label={t('Change Email')}
            onClick={onSubmit}
            disabled={pending}
            primary
          />
          {success && (
            <div className="success-message">
              <p>
                <Trans>Your email has been reset.</Trans>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ResetEmail);
