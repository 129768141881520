import React from 'react';

import ResponsiveIconButton from './ResponsiveIconButton';
import { Desktop } from '../icons';

const DesktopButton = props => (
  <ResponsiveIconButton {...props}>
    <Desktop />
  </ResponsiveIconButton>
);

export default DesktopButton;
