import { connect } from 'react-redux';

import AppIcon from './AppIcon';
import { addIconListener, removeIconListener } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...state.editor.data.metaData[ownProps.match.params.appname],
  ...state.editor.settings[ownProps.match.params.appname],
  account: state.account.organisation,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddIconListener: () =>
    dispatch(addIconListener(ownProps.match.params.appname)),
  onRemoveIconListener: () =>
    dispatch(removeIconListener(ownProps.match.params.appname)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppIcon);
