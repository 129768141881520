import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';

import Range from '../../../../app/components/range';
import Subtitle from '../../../../app/components/subtitle';
import ColorPicker from '../../components/colorPicker';

class ImageGradientMenu extends Component {
  static propTypes = {
    component: PropTypes.object.isRequired,
    onDesignChange: PropTypes.func.isRequired,
  };

  getColorFromValue = () => {
    const { design } = this.props.component;

    return design.gradientColor
      ? `rgb(${design.gradientColor.r}, ${design.gradientColor.g}, ${
          design.gradientColor.b
        })`
      : 'rgb(0, 0, 0)';
  };

  handleDesign = prop => (e, value) => this.props.onDesignChange(prop, value);

  handleGradientColor = value =>
    this.props.onDesignChange('gradientColor', _omit(value.rgb, 'a'));

  render() {
    const { component } = this.props;

    return (
      component && (
        <div>
          <Subtitle className={!component.design.enabled && 'disabled'}>
            Gradient Overlay
          </Subtitle>
          <Range
            min={0}
            max={100}
            step={1}
            disabled={!component.design.enabled}
            label={`Opacity: ${component.design.gradientOverlay}%`}
            value={component.design.gradientOverlay}
            onChange={this.handleDesign('gradientOverlay')}
            width="320px"
          />
          <ColorPicker
            label="Gradient Color"
            color={this.getColorFromValue()}
            height={22}
            disabled={!component.design.enabled}
            onChange={this.handleGradientColor}
          />
        </div>
      )
    );
  }
}

export default ImageGradientMenu;
