import url from 'url';
import firebase from '../../../../../../../firebase';

export const urlChange = (appname, dataId, urlString) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  // const parsedUrl = url.parse(urlString, true);

  // const listId = parsedUrl.query.list;
  // const videoId = parsedUrl.query.v;

  // if (listId) {
  //   dataRef.child('videoId').set(null);
  //   dataRef.child('listId').set(listId);
  // } else if (videoId) {
  //   dataRef.child('videoId').set(videoId);
  //   dataRef.child('listId').set(null);
  // }

  // if (urlString) {
  dataRef.child('rawUrl').set(urlString);
  // }

  return {
    type: 'TEXT_VALUE_CHANGE',
    appname,
    dataId,
    field: 'rawUrl',
    urlString,
  };
};

export const iframeUrl = (appname, dataId, iframeUrl) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('iframeUrl').set(iframeUrl);

  return {
    type: 'IFRAME_VALUE_CHANGE',
    appname,
    dataId,
    field: 'iframeUrl',
    iframeUrl,
  };
};

export const comments = (appname, dataId, enableComments) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('comments').set(enableComments);

  return {
    type: 'COMMENTS_VALUE_CHANGE',
    appname,
    dataId,
    field: 'comments',
    enableComments,
  };
};

export const commentsAuth = (appname, dataId, enableCommentsAuth) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('commentsAuth').set(enableCommentsAuth);

  return {
    type: 'COMMENTS_AUTH_VALUE_CHANGE',
    appname,
    dataId,
    field: 'commentsAuth',
    enableCommentsAuth,
  };
};

export const banBadWords = (appname, dataId, enableBanBadWords) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('banBadWords').set(enableBanBadWords);

  return {
    type: 'BAN_BAD_WORDS_CHANGE',
    appname,
    dataId,
    field: 'banBadWords',
    enableBanBadWords,
  };
};

export const loop = (appname, dataId, enableLoop) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('loop').set(enableLoop);

  return {
    type: 'LOOP_VALUE_CHANGE',
    appname,
    dataId,
    field: 'loop',
    enableLoop,
  };
};

export const muted = (appname, dataId, enableMuted) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('muted').set(enableMuted);

  return {
    type: 'MUTED_VALUE_CHANGE',
    appname,
    dataId,
    field: 'muted',
    enableMuted,
  };
};

export const autoPlay = (appname, dataId, enableAutoPlay) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('autoPlay').set(enableAutoPlay);

  return {
    type: 'AUTOPLAY_VALUE_CHANGE',
    appname,
    dataId,
    field: 'autoPlay',
    enableAutoPlay,
  };
};

export const urlType = (appname, dataId, urlType) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('urlType').set(urlType);

  return {
    type: 'URL_TYPE_VALUE_CHANGE',
    appname,
    dataId,
    field: 'urlType',
    urlType,
  };
};
