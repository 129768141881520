import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import firebase from '../../../../../../../firebase';

import IconButton from 'material-ui/IconButton';
import { FormattedNumber } from 'react-intl';
import DiyDialog from './DiyDialog';
import {
  PLANS_WITH_ADDONS,
  TIER_1_PLAN_ID,
  APP_SUMO_PLANS,
} from '../../../../../../../plans/constants';
import { specialAddonComponents } from '../../../../../../../plans/planComponents';
import { store } from '../../../../../../..';

import { RadioItem } from '../../../../../../../app/components/radioMenu';
import { components, plans } from '../../../../../../../plans';
import { comingSoon, lock, money } from '../createComponent/images';

import {
  RoundedThinButton,
  FlatButton,
} from '../../../../../../../app/components/buttons';

const disabledIconStyles = {
  border: 0,
  height: 32,
  padding: 0,
  verticalAlign: 'top',
  width: 32,
};

const getNextInvoiceAmount = ({ appname, accountId, subscriptionItem }) => {
  const subscription = subscriptionItem.items[appname];
  const subscriptionId = subscription.id;

  const payload = {
    subscriptionId,
    accountId,
  };

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      const options = {
        method: 'post',
        body: JSON.stringify(payload),
        headers,
        mode: 'cors',
      };
      return fetch(
        `${process.env.REACT_APP_PAYMENT_API}/get_next_invoice_amount`,
        options,
      )
        .then(res => res.json())
        .then(body => {
          const { amount } = body;
          return amount;
        })
        .catch(error => null);
    });
};

class CreateComponentItem extends React.Component {
  state = {
    displayConfirmation: false,
    nextInvoiceAmount: null,
    isFectching: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.diyPopupOpen !== this.props.diyPopupOpen)
      this.setState({ displayConfirmation: nextProps.diyPopupOpen });
  }

  handleClick = () => {
    const { appname, subscriptionItem, accountId } = this.props;
    this.setState({ isFectching: true, displayConfirmation: true });
    const subscription = subscriptionItem.items[appname];
    const subscriptionId = subscription.id;
    if (subscriptionId) {
      getNextInvoiceAmount({ appname, accountId, subscriptionItem }).then(
        amount => {
          this.setState({
            nextInvoiceAmount: amount,
            isFectching: false,
          });
        },
      );
    } else {
      this.setState({ isFectching: false });
    }
  };

  handleUpgrade = () => {
    this.props.onCancel('upgrade');
    this.props.history.push(`/${this.props.appname}/choose_plan`);
  };

  closePopup = () => {
    this.setState({ displayConfirmation: false });
  };

  confirmAction = () => {
    let currency;
    if (this.props.subscription && this.props.subscription.includes('-')) {
      const formattedSubscription = this.props.subscription.replace('2023', '');

      currency = formattedSubscription.split('-')[1];
    }

    if (
      specialAddonComponents.includes(this.props.value) &&
      this.props.isAppSumAccount
    ) {
      this.props.addSpecialAddons(
        this.props.value,
        currency,
        this.closePopup,
        this.props.hasTrialExpired,
        this.props.history,
        this.props.handleChange,
      );
    } else {
      this.props.onConfirm(this.props.value, currency);
    }
  };

  render() {
    const { subscriptionItem, appname, width, diaryUpgrade, t } = this.props;
    let subscriptions;
    if (this.props.subscription && this.props.subscription.includes('diy'))
      subscriptions = 'diy';
    if (this.props.subscription && this.props.subscription.includes('deluxe'))
      subscriptions = 'deluxe';
    if (
      this.props.subscription &&
      this.props.subscription.includes('something')
    )
      subscriptions = 'something';

    let period = 'monthly';
    if (
      subscriptionItem &&
      subscriptionItem.items &&
      subscriptionItem.items[appname]
    ) {
      period = subscriptionItem.items[appname].period;
    }

    const disabledIcon = this.props.comingSoon ? (
      <img className="coming-soon" src={comingSoon} alt="" />
    ) : (
      <span className="disabled-icon">
        <IconButton tooltip={t('Premium Component')} style={disabledIconStyles}>
          <img
            src={
              PLANS_WITH_ADDONS.includes(
                subscriptions || this.props.subscription,
              ) || specialAddonComponents.includes(this.props.value)
                ? money
                : lock
            }
            alt=""
          />
        </IconButton>
      </span>
    );
    //  }
    const plan =
      (components[this.props.value] && components[this.props.value].plan) ||
      'deluxe';

    let disabledNotice = null;

    if (!this.props.comingSoon) {
      disabledNotice = (
        <div className="disabled-notice">
          <Link
            className="disabled-notice-message"
            onClick={e => {
              e.preventDefault();
              this.handleUpgrade();
            }}
            to={`/${this.props.appname}/choose_plan`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span>
              <Trans>Upgrade to the</Trans> {plans[plan].title}{' '}
              <Trans>plan to access this component.</Trans>
            </span>
            <br />
            <RoundedThinButton className="success">
              <Trans>upgrade</Trans>
            </RoundedThinButton>
          </Link>
        </div>
      );

      if (
        PLANS_WITH_ADDONS.includes(subscriptions || this.props.subscription) ||
        specialAddonComponents.includes(this.props.value)
      ) {
        let currency;
        let value;

        if (this.props.subscription && this.props.subscription.includes('-')) {
          const formattedSubscription = this.props.subscription.replace(
            '2023',
            '',
          );

          currency = formattedSubscription.split('-')[1];
          if (currency.includes('_')) {
            currency = currency.split('_')[0];
          }
        }
        if (currency) {
          value =
            period === 'yearly'
              ? components[this.props.value][currency] &&
                components[this.props.value][currency].price * 12
              : components[this.props.value][currency] &&
                components[this.props.value][currency].price;
        } else {
          value =
            period === 'yearly'
              ? components[this.props.value].price * 12
              : components[this.props.value].price;
        }

        disabledNotice = (
          <div className="disabled-notice">
            {this.props.subscription === TIER_1_PLAN_ID &&
            !specialAddonComponents.includes(this.props.value) ? (
              <div style={{ fontWeight: 'bold' }}>
                <Trans>
                  You need a Tier 2 plan or higher to use this component.
                </Trans>
              </div>
            ) : (
              <React.Fragment>
                {' '}
                {this.props.isAppAdmin ||
                this.props.isSubAdminWithMulipleApps ? (
                  <></>
                ) : (
                  <Link
                    className="disabled-notice-message bold flex-column flex-align-center"
                    onClick={e => {
                      e.preventDefault();
                      this.handleClick();
                    }}
                    to={`/${this.props.appname}/choose_plan`}
                  >
                    {APP_SUMO_PLANS.includes(
                      subscriptions || this.props.subscription,
                    ) &&
                      !this.props.hasTrialExpired && (
                        <span className="">
                          <Trans>Add on a 30-day free trial.</Trans>
                          {/* <FormattedNumber
                          maximumFractionDigits={2}
                          format={this.props.intl.currency}
                          currency={currency || 'gbp'}
                          value={value}
                        />{' '}
                        per month */}
                        </span>
                      )}
                    {APP_SUMO_PLANS.includes(
                      subscriptions || this.props.subscription,
                    ) &&
                      this.props.hasTrialExpired && (
                        <span>
                          {' '}
                          <FormattedNumber
                            maximumFractionDigits={2}
                            format={this.props.intl.currency}
                            currency={currency || 'gbp'}
                            value={value}
                          />{' '}
                          <Trans>per month</Trans>
                        </span>
                      )}
                    {!APP_SUMO_PLANS.includes(
                      subscriptions || this.props.subscription,
                    ) && (
                      <span className="">
                        +{' '}
                        <FormattedNumber
                          maximumFractionDigits={2}
                          format={this.props.intl.currency}
                          currency={currency || 'gbp'}
                          value={value}
                        />{' '}
                        <Trans>in</Trans>{' '}
                        {APP_SUMO_PLANS.includes(this.props.subscription)
                          ? plans[this.props.subscription].title
                          : 'Anything'}{' '}
                        <Trans>plan</Trans>
                      </span>
                    )}
                    <RoundedThinButton className="success disabled-buttons">
                      <Trans>Add</Trans>
                    </RoundedThinButton>
                  </Link>
                )}
                {!APP_SUMO_PLANS.includes(this.props.subscription) ? (
                  <Link
                    className="disabled-notice-message flex-column flex-align-center"
                    onClick={e => {
                      e.preventDefault();
                      this.handleUpgrade();
                    }}
                    to={`/${this.props.appname}/choose_plan`}
                  >
                    <span className="">
                      <Trans>Included in</Trans> Everything <Trans>and</Trans>{' '}
                      Reseller plan.
                    </span>
                    <RoundedThinButton className="success disabled-buttons">
                      <Trans>Upgrade</Trans>
                    </RoundedThinButton>
                  </Link>
                ) : (
                  <div className="disabled-notice-message bold flex-column flex-align-center">
                    {this.props.isAppAdmin ||
                    this.props.isSubAdminWithMulipleApps ? (
                      <span>Contact the admin to enable this AddOn.</span>
                    ) : (
                      <>
                        {!this.props.hasTrialExpired ? (
                          <span>
                            <Trans>After 30 days add component for</Trans>
                          </span>
                        ) : (
                          <span>
                            {' '}
                            <Trans>You will be charged</Trans>
                          </span>
                        )}
                        <FormattedNumber
                          maximumFractionDigits={2}
                          format={this.props.intl.currency}
                          currency={currency || 'gbp'}
                          value={value}
                        />{' '}
                        <span>
                          <Trans>per month</Trans>
                        </span>
                      </>
                    )}
                  </div>
                )}
                <DiyDialog
                  {...this.props}
                  open={this.state.displayConfirmation}
                  onClose={() => {
                    this.setState({ displayConfirmation: false });
                  }}
                  action={this.confirmAction}
                  component={components[this.props.value]}
                  hasTrialExpired={this.props.hasTrialExpired}
                  nextInvoiceAmount={this.state.nextInvoiceAmount}
                  isLoading={this.state.isFectching}
                />
              </React.Fragment>
            )}
          </div>
        );

        if (diaryUpgrade) {
          return (
            <div>
              <FlatButton
                label={t('Get As Addon')}
                onClick={this.handleClick}
                primary
              />
              <DiyDialog
                {...this.props}
                open={this.state.displayConfirmation}
                onClose={() => {
                  this.setState({ displayConfirmation: false });
                }}
                action={this.confirmAction}
                component={components[this.props.value]}
                hasTrialExpired={this.props.hasTrialExpired}
                isLoading={this.state.isFectching}
              />
            </div>
          );
        }
      }
    }

    return (
      <div
        style={{ width: width || '20%', margin: '20px', minHeight: '250px' }}
      >
        <Provider store={store}>
          <RadioItem
            disabledIcon={disabledIcon}
            disabledNotice={disabledNotice}
            {...this.props}
          />
        </Provider>
      </div>
    );
  }
}

CreateComponentItem.propTypes = {
  comingSoon: PropTypes.bool,
  match: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  instructionLink: PropTypes.string,
  handleChange: PropTypes.func,
  appname: PropTypes.string,
};

CreateComponentItem.defaultProps = {
  comingSoon: false,
  appname: '',
  handleChange: () => {},
};

export default withRouter(withTranslation()(CreateComponentItem));
