import {
  NEW_MEMBER_GROUP_WORKFORCE_TYPE_CHANGE,
  OPEN_NEW_MEMBER_GROUP_DIALOG,
  CLOSE_NEW_MEMBER_GROUP_DIALOG,
  ADD_NEW_MEMBER_GROUP,
  NEW_MEMBER_GROUP_ADDED,
} from './actions';

const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  name: {
    value: '',
    isValid: null,
  },
};

const newGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_NEW_MEMBER_GROUP_DIALOG:
      return {
        ...state,
        open: true,
      };

    case CLOSE_NEW_MEMBER_GROUP_DIALOG:
      return {
        ...state,
        open: false,
      };

    case NEW_MEMBER_GROUP_WORKFORCE_TYPE_CHANGE:
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };

    case ADD_NEW_MEMBER_GROUP:
      return {
        ...state,
        pending: true,
      };

    case NEW_MEMBER_GROUP_ADDED:
      return initialState;

    default:
      return state;
  }
};

export default newGroupReducer;
