import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
// Material Imports
import Dialog from 'material-ui/Dialog';
import AddIcon from 'material-ui/svg-icons/content/add';
import { withTranslation } from 'react-i18next';

import PageHeader from '../../app/components/pageHeader';
import { FlatButton, FloatingActionButton } from '../../app/components/buttons';
import PageHeaderCounter from '../../app/components/pageHeaderCounter';
import {
  sendSlackNotification,
  USER_CLICKED_BUY_SMS,
  PLATFORM_EVENTS,
} from '../../utils';

import Contacts from './modules/contacts';
import SocialMedia from './modules/socialMedia';
import Widget from './modules/widget';
import History from './modules/history';
import Qr from './modules/qr';
import ShareEmailForm from './components/shareEmailForm';
import ShareSmsForm from './components/shareSmsForm';
import ShareWhatsappForm from './components/shareWhatsappForm';
import PrivateRoute from '../../app/components/privateRoute';
import './styles.css';
import AccountsDropdown from '../editor/internals/accountsDropdown';

class Distribute extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    message: PropTypes.object.isRequired,
    subject: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired,
    selected: PropTypes.array,
    smsCredit: PropTypes.number.isRequired,
    match: PropTypes.object.isRequired,
    messageOptions: PropTypes.array.isRequired,
    openDistributeDialog: PropTypes.func.isRequired,
    closeDistributeDialog: PropTypes.func.isRequired,
    onMessageChange: PropTypes.func.isRequired,
    onSubjectChange: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    setDefaultMessage: PropTypes.func.isRequired,
    onAddContact: PropTypes.func.isRequired,
    onSendSMS: PropTypes.func.isRequired,
    onSendEmail: PropTypes.func.isRequired,
    subscription: PropTypes.string,
    location: PropTypes.object.isRequired,
    organisation: PropTypes.object.isRequired,
    onLoadAppTitle: PropTypes.func.isRequired,
    customDomain: PropTypes.object,
    is14DayTrialUser: PropTypes.bool.isRequired,
    ditributeSectionsAccess: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    customDomain: {},
    selected: [],
    subscription: null,
  };

  componentDidMount() {
    this.props.onLoadAppTitle();
    this.props.loadCustomDomain();
  }

  onButtonClick = ({ notificationTitle, nextPage }) => {
    const { match, subscription, organisation, history } = this.props;

    sendSlackNotification({
      appname: match.params.appname,
      notificationTitle,
      account: organisation.key,
      currentPlan: subscription,
      slackChannel: PLATFORM_EVENTS,
    });
    history.push(nextPage);
  };

  calculateDialog() {
    const pathname = this.props.location.pathname;

    if (pathname.match(/share_sms/i)) {
      return (
        <ShareSmsForm
          {...this.props}
          onButtonClick={({ notificationTitle, nextPage }) =>
            this.onButtonClick({ notificationTitle, nextPage })
          }
        />
      );
    } else if (pathname.match(/share_email/i)) {
      return <ShareEmailForm {...this.props} />;
    } else if (pathname.match(/share_whatsapp/i)) {
      return <ShareWhatsappForm {...this.props} />;
    }

    return null;
  }

  getTypePage = () => {
    const path = this.props.location.pathname
      .replace(/(.*?\/|)distribute(\/|$)/, '')
      .match(/(.*?)(?=\/|$)/)[0];

    switch (path) {
      case 'share_email':
        return 'Email';

      case 'share_sms':
        return 'SMS';

      case 'share_whatsapp':
        return 'Whatsapp';
      default:
        return 'contacts';
    }
  };

  renderSocialMedia = () => (
    <SocialMedia customDomain={this.props.customDomain} />
  );

  render() {
    const { t } = this.props;
    const inFreeTrial = !this.props.organisation.trial_expired || false;
    const upgradeRequired =
      !inFreeTrial && !this.props.subscription && this.props.is14DayTrialUser;

    return (
      <div className="flex-container sidebar-extended background-texture">
        <PageHeader
          title={t('Distribute your app')}
          userSettings={<AccountsDropdown />}
          actions={
            <div style={{ paddingRight: '10px' }}>
              <Route
                path="/:appname/distribute/contacts"
                render={() => (
                  <div className="row middle-xs">
                    <div className="col">
                      <FloatingActionButton
                        icon={<AddIcon />}
                        onClick={this.props.onAddContact}
                      />
                    </div>
                  </div>
                )}
              />

              <Route
                path="/:appname/distribute/share_sms"
                render={() => (
                  <>
                    <div className="row middle-xs">
                      {!this.props.isAppAdmin && (
                        <div className="col">
                          <FlatButton
                            label={t('Buy SMS')}
                            onClick={() => {
                              const nextPage = {
                                pathname: '/choose_sms_bundle',
                                state: { origin: this.props.location.pathname },
                              };
                              this.onButtonClick({
                                notificationTitle: USER_CLICKED_BUY_SMS,
                                nextPage,
                              });
                            }}
                            secondary
                          />
                        </div>
                      )}
                      <div className="col">
                        <PageHeaderCounter
                          amount={this.props.smsCredit}
                          title={t('SMS Left')}
                        />
                      </div>
                    </div>
                  </>
                )}
              />

              <Route
                path="/:appname/distribute/share_whatsapp"
                render={() => (
                  <>
                    <div className="row middle-xs">
                      {!this.props.isAppAdmin && (
                        <div className="col">
                          <FlatButton
                            label={t('Buy Credits')}
                            onClick={() => {
                              const nextPage = {
                                pathname: '/choose_whatsapp_credits_bundle',
                                state: { origin: this.props.location.pathname },
                              };
                              this.onButtonClick({
                                notificationTitle: USER_CLICKED_BUY_SMS,
                                nextPage,
                              });
                            }}
                            secondary
                          />
                        </div>
                      )}
                      <div className="col">
                        <PageHeaderCounter
                          amount={this.props.whatsappSMSCredit}
                          title={t('Credits Left')}
                        />
                      </div>
                    </div>
                  </>
                )}
              />
            </div>
          }
        />

        {this.props.ditributeSectionsAccess && (
          <Switch>
            <Redirect
              exact
              from={`/${this.props.match.params.appname}/distribute`}
              to={`/${this.props.match.params.appname}/distribute/contacts`}
            />
            <Route path="/:appname/distribute/contacts" component={Contacts} />
            <Route path="/:appname/distribute/share_sms" component={Contacts} />
            <Route
              path="/:appname/distribute/share_whatsapp"
              component={Contacts}
            />
            <PrivateRoute
              {...this.props}
              path="/:appname/distribute/share_email"
              component={Contacts}
              requiresUpgrade={upgradeRequired}
              content={t(
                'You need to upgrade your plan to use the Share via Email feature.',
              )}
            />
            <Route path="/:appname/distribute/history" component={History} />
            <PrivateRoute
              {...this.props}
              path="/:appname/distribute/social"
              component={this.renderSocialMedia}
              requiresUpgrade={upgradeRequired}
              content={t(
                'You need to upgrade your plan to use the Social Media feature.',
              )}
            />
            <Route path="/:appname/distribute/widget" component={Widget} />
            <PrivateRoute
              {...this.props}
              path="/:appname/distribute/qr"
              component={Qr}
              requiresUpgrade={upgradeRequired}
              content={t(
                'You need to upgrade your plan to use the QR Code feature.',
              )}
            />
          </Switch>
        )}

        <Dialog
          open={this.props.open}
          onRequestClose={() =>
            this.props.closeDistributeDialog(this.props.customDomain)
          }
          autoScrollBodyContent
        >
          {this.calculateDialog()}
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(Distribute);
