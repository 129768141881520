import React, { useState, useEffect } from 'react';
import * as L from 'leaflet';
import { Trans } from 'react-i18next';
import { Map, TileLayer, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import osm from './osm-providers';
import { useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import useFetch from '../../../../utils/useFetch';
import { setLocationData, deleteLocationData } from './helpers';

import styles from './styles.module.scss';

const PolygonMap = props => {
  const { appname } = props;
  const req = useFetch(
    `${process.env.REACT_APP_FOOD_API}/get_delivery_locations`,
    {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        appname,
      }),
    },
  );

  let locations = [];
  if (req.response) {
    locations = req.response.locations ? req.response.locations : [];
  }

  const [center, setCenter] = useState({
    lat: 55.947325930220636,
    lng: -3.216802761198238,
  });
  const [mapLayers, setMapLayers] = useState(locations);
  const [mapUpdated, setMapUpdated] = useState(false);
  const [deleteIds, setDeleteIds] = useState([]);
  const [addedInitialLayers, setAddedInitialLayers] = useState(false);

  const ZOOM_LEVEL = 12;
  const mapRef = useRef();
  const featureRef = useRef();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
    function showPosition(position) {
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    }
  }, []);

  useEffect(() => {
    if (locations && Object.keys(locations).length) setMapLayers(locations);
  }, [locations]);

  useEffect(() => {
    if (mapUpdated) {
      if (deleteIds.length > 0) {
        deleteLocationData(appname, deleteIds);
        setDeleteIds([]);
      } else {
        setLocationData(appname, mapLayers);
      }
      setMapUpdated(false);
    }
  }, [mapLayers, mapUpdated, deleteIds]);

  useEffect(() => {
    if (
      mapRef.current &&
      featureRef.current &&
      featureRef.current.leafletElement &&
      !addedInitialLayers &&
      mapLayers.length > 0
    ) {
      setAddedInitialLayers(true);
      mapLayers.forEach(layer => {
        featureRef.current.leafletElement.addLayer(L.polygon(layer));
      });

      const allLayers = featureRef.current.leafletElement._layers;
      const newLayers = Object.keys(allLayers).map(key => ({
        id: allLayers[key]._leaflet_id,
        latlngs: allLayers[key]._latlngs[0],
      }));
      setMapLayers(newLayers);
      setMapUpdated(true);
    }
  }, [mapRef.current, featureRef.current, mapLayers]);

  const _onCreate = e => {
    const { layerType, layer } = e;
    if (layerType === 'polygon') {
      const { _leaflet_id } = layer;

      setMapLayers(layers => [
        ...layers,
        { id: _leaflet_id, latlngs: layer.getLatLngs()[0] },
      ]);
      setMapUpdated(true);
    }
  };

  const _onEdited = e => {
    const {
      layers: { ..._layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      setMapLayers(layers =>
        layers.map(l =>
          l.id === _leaflet_id ? { ...l, latlngs: editing.latlngs[0] } : l,
        ),
      );
    });
    setMapUpdated(true);
  };

  const _onDeleted = e => {
    const {
      layers: { _layers },
    } = e;

    let deleteIdsArr = [];
    Object.values(_layers).map(({ _leaflet_id }) => {
      deleteIdsArr.push(_leaflet_id);
      setMapLayers(layers => layers.filter(l => l.id !== _leaflet_id));
    });
    setDeleteIds(deleteIdsArr);
    setMapUpdated(true);
  };

  return (
    <div className="auth-panel">
      <div className="auth-panel-title">
        <Trans>Set Delivery Locations on Map</Trans>
      </div>

      <Map
        className={styles.container}
        center={center}
        zoom={ZOOM_LEVEL}
        ref={mapRef}
      >
        <FeatureGroup ref={featureRef}>
          <EditControl
            position="topright"
            onCreated={_onCreate}
            onEdited={_onEdited}
            onDeleted={_onDeleted}
            draw={{
              rectangle: false,
              polyline: false,
              circle: false,
              circlemarker: false,
              marker: false,
            }}
          />
        </FeatureGroup>
        <TileLayer
          url={osm.maptiler.url}
          attribution={osm.maptiler.attribution}
        />
      </Map>
    </div>
  );
};

export default PolygonMap;
