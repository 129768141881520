import React, { Fragment } from 'react';
import TwoThirdImageRightImage from './images/twoThirdImageRight.png';
import './styles.css';

const TwoThirdImageRight = () => {
  return (
    <Fragment>
      <img
        className="imageWrapper"
        src={TwoThirdImageRightImage}
        alt="Two Third Right"
      />
    </Fragment>
  );
};

export default TwoThirdImageRight;
