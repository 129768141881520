import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LinearProgress from 'material-ui/LinearProgress';

import Modal from '../../../../../account/components/modal';
import { CTAButton } from '../../../../../../app/components/buttons';
import { rocket } from './images';

const Header = styled.h1`
  font-size: 40px;
  color: rgba(30, 30, 30, 0.6);
  text-align: center;
  text-transform: capitalize;
  margin: 0 0 5px;
`;

const Container = styled.div`
  padding: 0 15px 15px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-flow: inherit;
  max-height: 100%;
  .img_wrapper {
    position: relative;
    display: block;
    height: -webkit-fill-available;
    img {
      max-width: 65%;
      max-height: 35%;
      padding: 10px 20px 0;
    }
  }
`;

const marginStyle = {
  marginBottom: '35px',
};

class WelcomeModal extends Component {
  static propTypes = {
    type: PropTypes.string,
    open: PropTypes.bool,
    publishing: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    domain: PropTypes.string,
  };

  static defaultProps = {
    type: null,
    publishing: false,
    open: false,
    domain: '',
  };

  stateContentText = () => {
    const { domain, publishing } = this.props;
    return !publishing ? (
      <span style={marginStyle}>
        <strong>
          Your Beezer app is live. See it in action on <br />
          your mobile at {`https://${domain}.beezer.com`}
        </strong>
      </span>
    ) : (
      <span style={marginStyle}>
        <strong>Welcome to Beezer. Your app is just seconds away.</strong>
        <div>
          <p>
            We’re setting up your account and building your app. This won’t take
            long.
          </p>
        </div>
        <LinearProgress
          color="#261ed5"
          mode="indeterminate"
          style={{ margin: '15px 0', padding: '1px' }}
        />
      </span>
    );
  };

  render() {
    const { open, publishing } = this.props;
    return (
      <Modal open={open} showHeader={false} bodyClassName="beezer-modal">
        <Header style={marginStyle}>
          {publishing ? `3...2...1` : `Blast off!`}
        </Header>
        <Container>
          {this.stateContentText()}
          <div className="img_wrapper">
            <img alt="modal_image" src={rocket} />
          </div>
          {!publishing && (
            <div>
              <strong style={{ marginTop: '-30px' }}>
                Room for improvement?
              </strong>
            </div>
          )}
        </Container>
        <CTAButton
          disabled={publishing}
          label="Customize your app"
          // borderColor="rgb(156, 150, 6)"
          outlineRGB={publishing ? '227,227,227' : '195, 207, 47'}
          style={{ margin: '10px auto', display: 'block', maxWidth: '230px' }}
          onClick={this.props.handleClose}
        />
      </Modal>
    );
  }
}

export default WelcomeModal;
