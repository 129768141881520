import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dragula from 'react-dragula';

import 'react-dragula/dist/dragula.min.css';
import './style.css';

class DraggableListWrapper extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    options: PropTypes.object,
    onPositionChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: {
      isContainer: (/*el*/) => false,
      moves: (/*el, source, handle, sibling*/) => true,
      accepts: (/*el, target, source, sibling*/) => true,
      invalid: (/*el, handle*/) => false,
      direction: 'vertical', // Y axis is considered when determining where an element would be dropped
      copy: false, // elements are moved by default, not copied
      copySortSource: false, // elements in copy-source containers can be reordered
      revertOnSpill: true, // spilling will put the element back where it was dragged from, if this is true
      removeOnSpill: false, // spilling will `.remove` the element, if this is true
      mirrorContainer: document.body, // set the element that gets mirror elements appended
      ignoreInputTextSelection: true, // allows users to select input text, see details below
    },
  };

  dragulaDecorator = componentBackingInstance => {
    if (componentBackingInstance) {
      const drake = Dragula([componentBackingInstance], {
        ...this.options,
        ...this.props.options,
      });
      drake.on('drop', this.props.onPositionChange);
    }
  };

  render() {
    return (
      <div
        key="page-list"
        ref={this.dragulaDecorator}
        className={this.props.className}
      >
        {this.props.items}
      </div>
    );
  }
}

export default DraggableListWrapper;
