import firebase from '../../../../../../firebase';

export const toggleSignupAs = (appname, toggled) => async dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/signupAs`)
    .update({ enabled: toggled })
    .then(() =>
      dispatch({
        type: 'TOGGLE_SIGNUP_AS',
        appname,
        toggled,
      }),
    );
};

export const updateSelectedGroups = (appname, group) => dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/signupAs/groups`)
    .set(group)
    .then(() =>
      dispatch({
        type: 'SET_SIGNUP_GROUPS',
        appname,
        group,
      }),
    );
};
