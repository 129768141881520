import React from 'react';

const TwitterIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 77.79 33.56"
  >
    <title>twitter component</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          class="cls-1"
          points="9.28 24.02 0 14.74 9.28 5.46 10.34 6.53 2.12 14.74 10.34 22.96 9.28 24.02"
        />
        <polygon
          class="cls-1"
          points="68.5 24.02 67.44 22.96 75.67 14.74 67.44 6.53 68.5 5.46 77.79 14.74 68.5 24.02"
        />
        <path
          class="cls-1"
          d="M32.82,33.56a23.48,23.48,0,0,1-12.69-3.72l-2.75-1.76,3.24.38a15.74,15.74,0,0,0,1.82.11,15.24,15.24,0,0,0,7.94-2.2,8.74,8.74,0,0,1-6.18-5.82l-.39-1.2,1.24.24.2,0a8.8,8.8,0,0,1-3.9-7.4l0-1.26,1.1.61a6.55,6.55,0,0,0,.8.39,8.8,8.8,0,0,1-.67-9.8l.55-.94.68.84a22.11,22.11,0,0,0,15,8,10,10,0,0,1,0-1.64A8.77,8.77,0,0,1,53.67,2.78,15.34,15.34,0,0,0,58.13,1l1.7-1-.6,1.88a8.9,8.9,0,0,1-1.56,2.87,15.82,15.82,0,0,0,1.62-.61l2.36-1L60.22,5.24a17.11,17.11,0,0,1-3.86,4.1c0,.22,0,.45,0,.67C56.37,21.58,47.56,33.56,32.82,33.56ZM23.59,30a22,22,0,0,0,9.23,2c13.8,0,22-11.21,22-22.05,0-.34,0-.67,0-1V8.6l.32-.24a14.57,14.57,0,0,0,2.25-2,18.48,18.48,0,0,1-2.12.42l-.6.07-.45-1.16.53-.37a7.35,7.35,0,0,0,2.1-2,16.9,16.9,0,0,1-3.29,1l-.41.08-.28-.3A7.27,7.27,0,0,0,40.33,8.55a7.61,7.61,0,0,0,.17,2.19l.22,1-1-.05a23.57,23.57,0,0,1-16.3-7.77,7.12,7.12,0,0,0-.51,2.67,7.26,7.26,0,0,0,3.23,6.05l2.18,1.45L25.71,14a8.68,8.68,0,0,1-2.77-.54,7.24,7.24,0,0,0,5.74,6l3.11.63-3.06.83a8.68,8.68,0,0,1-2.64.3,7.27,7.27,0,0,0,6.32,3.93l2.11,0-1.66,1.3A16.68,16.68,0,0,1,23.59,30Z"
        />
      </g>
    </g>
  </svg>
);

export default TwitterIcon;
