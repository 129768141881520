import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Groups from './Groups';

import {
  updateGroupDetails,
  formFieldChange,
  deleteFile,
  updateStatus,
  updateCOCStatus,
  // getSubscriptionStatus,
} from './actions';

const mapStateToProps = state => ({
  ...state.auth.workforceMembers.edit.editGroup.groupDetails,
  allGroups: state.auth.memberGroups.listPage.data.chunks,
  ...state.auth.workforceMembers.edit.groupTabs,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: groupID =>
    dispatch(
      updateGroupDetails(
        ownProps.match.params.appname,
        ownProps.match.params.memberID,
        groupID,
      ),
    ),
  updateStatus: (groupID, status) =>
    dispatch(
      updateStatus(
        ownProps.match.params.appname,
        ownProps.match.params.memberID,
        groupID,
        status,
      ),
    ),
  updateCOCStatus: (groupID, status) =>
    dispatch(
      updateCOCStatus(
        ownProps.match.params.appname,
        ownProps.match.params.memberID,
        groupID,
        status,
      ),
    ),
  onFieldChange: (isRequired, field, id, value, groupID, latlong) =>
    dispatch(formFieldChange(isRequired, field, id, value, groupID, latlong)),
  deleteFile: (isRequired, field, id, value, groupID) =>
    dispatch(deleteFile(isRequired, field, id, value, groupID)),
  // getSubscriptionStatus: groupId => dispatch(getSubscriptionStatus(groupId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Groups));
