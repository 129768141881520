import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RenderToLayer from 'material-ui/internal/RenderToLayer';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SimpleToolbar } from '../../../../app/modules/toolbar';
import Legal from '../legal';

import { store } from '../../../../';

import * as offers from './Templates';

import './styles.css';

class Offers extends Component {
  static propTypes = {
    account: PropTypes.string.isRequired,
    addThankYouNotification: PropTypes.func.isRequired,
    onSubscribe: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    onOpenLegalModal: PropTypes.func.isRequired,
    promotion: PropTypes.object.isRequired,
    resetOffer: PropTypes.func.isRequired,
    addToAnalytics: PropTypes.func.isRequired,
  };

  handleClose = () => {
    const { resetOffer, history, match } = this.props;

    resetOffer();
    return history.push(`/${match.params.appname}/editor/build`);
  };

  handleSubscribe = () => {
    const {
      onSubscribe,
      addThankYouNotification,
      addToAnalytics,
      account,
      promotion,
    } = this.props;

    const { id } = promotion.offer;

    const { plan, period, coupon, welcomeNotice } = this.props.promotion.offer;
    const callback = () => {
      addToAnalytics(account, id);
      addThankYouNotification(account, welcomeNotice);
      this.handleClose();
    };

    onSubscribe({ plan, period, coupon, callback });
  };

  createOfferComponent = (Comp, props) => (
    <div className="overlay">
      <Provider store={store}>
        <SimpleToolbar onExit={this.handleClose} />
      </Provider>
      <Comp {...props} />
      <Legal />
    </div>
  );

  renderLayer = () => {
    const { onOpenLegalModal, history, match } = this.props;
    const { offer, isUserSubscribed } = this.props.promotion;
    const { id, price, priceMultiplier } = offer;

    const offerCost = price * priceMultiplier;
    const { appname } = match.params;

    const isOfferExpired = offer.expires < Date.now();

    if (isOfferExpired) {
      return this.createOfferComponent(offers.expired, {
        content: offer.content,
        history,
        appname,
      });
    }

    if (isUserSubscribed) {
      return this.createOfferComponent(offers.subscribed, {
        content: offer.content,
      });
    }

    if (!isUserSubscribed && !isOfferExpired) {
      return this.createOfferComponent(offers[id], {
        content: offer.content,
        cost: offerCost.toFixed(2),
        onOpenLegalModal,
        onSubscribe: this.handleSubscribe,
      });
    }

    return this.createOfferComponent(offers.expired, {
      content: offer.content,
      history,
      appname,
    });
  };

  render() {
    const { offer } = this.props.promotion;
    const { appname } = this.props.match.params;

    if (!offer || !offer.id) {
      return (
        <Redirect
          from="/:appname/offer"
          exact
          to={{
            pathname: `/${appname}/editor`,
          }}
        />
      );
    }

    return (
      <RenderToLayer
        render={this.renderLayer}
        useLayerForClickAway={false}
        open
      />
    );
  }
}

export default Offers;
