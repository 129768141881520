import _keys from 'lodash/keys';
import firebase from '../../../../firebase';
import { objectToArray, arrayToObject, formatDate } from '../../../../utils';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';
import {
  COURSES_DELETED,
  COURSE_DUPLICATED,
  COURSE_DUPLICATE_ERROR,
} from './config';
import { HS_FEATURES_ENABLED_APPS } from '../../../../config';

export const REQUEST_COURSES = 'REQUEST_COURSES';
export const RECEIVE_COURSES = 'RECEIVE_COURSES';
export const FILTER_COURSES = 'FILTER_COURSES';
export const SELECT_COURSES = 'SELECT_COURSES';
export const SELECT_ALL_COURSES = 'SELECT_ALL_COURSES';
export const DESELECT_ALL_COURSES = 'DESELECT_ALL_COURSES';
export const GET_FIELDS_COURSES = 'GET_FIELDS_COURSES';
export const INITIATE_FIELDS_COURSES = 'INITIATE_FIELDS_COURSES';
export const INITIATE_MEMBER_BODY_COURSES = 'INITIATE_MEMBER_BODY_COURSES';
export const DELETED_COURSES = 'DELETED_COURSES';

export const request = appname => ({
  type: REQUEST_COURSES,
  appname,
});

export const selectCourse = key => ({
  type: SELECT_COURSES,
  key,
});

export const deletedDone = data => ({
  type: DELETED_COURSES,
  data,
});

export const initiateMemberBody = key => ({
  type: INITIATE_MEMBER_BODY_COURSES,
  key,
});

export const filterCourseData = (filter, keys) => dispatch => {
  dispatch({
    type: FILTER_COURSES,
    payload: filter,
    keys,
  });
};

export const selectAllCourses = () => ({
  type: SELECT_ALL_COURSES,
});

export const deselectAllCourses = () => ({
  type: DESELECT_ALL_COURSES,
});

export const getCourseFields = appname => dispatch => {
  const isHorsescotland = HS_FEATURES_ENABLED_APPS.indexOf(appname) > -1;
  if (isHorsescotland) {
    firebase
      .database()
      .ref(`app_courses/${appname}/fields`)
      .on('value', snap => {
        if (snap.exists()) {
          const fields = snap.val();
          dispatch({
            type: GET_FIELDS_COURSES,
            value: fields,
          });
          // create fieldsData object
          const fieldsData = {};
          Object.keys(fields).forEach(key => {
            fieldsData[key] = {
              value: fields[key].defaultValue,
              isValid: fields[key].isRequired ? null : true,
            };
          });
          dispatch({
            type: INITIATE_FIELDS_COURSES,
            value: fieldsData,
          });
        } else {
          dispatch({
            type: GET_FIELDS_COURSES,
            value: {},
          });
        }
      });
  } else {
    firebase
      .database()
      .ref(`app_courses/${appname}/lazy_data`)
      .on('value', snap => {
        if (snap.exists()) {
          firebase
            .database()
            .ref(`apps/${appname}/draft/lazy_data/${snap.val()}/course`)
            .on('value', snap => {
              if (snap.exists()) {
                const fields = snap.val().fields.chunks;
                dispatch({
                  type: GET_FIELDS_COURSES,
                  value: fields,
                });
                // create fieldsData object
                const fieldsData = {};
                Object.keys(fields).forEach(key => {
                  fieldsData[key] = {
                    value: fields[key].defaultValue || '',
                    isValid: fields[key].isRequired ? null : true,
                  };
                });
                dispatch({
                  type: INITIATE_FIELDS_COURSES,
                  value: fieldsData,
                });
              } else {
                dispatch({
                  type: GET_FIELDS_COURSES,
                  value: {},
                });
              }
            });
        }
      });
  }
};

export const selectMemberBody = key => (dispatch, getState) => {
  if (key) {
    dispatch(initiateMemberBody(key));
  } else {
    const { memberBodies } = getState().account.profile;
    dispatch(initiateMemberBody(_keys(memberBodies)[0]));
  }
};

export const getCourses = appname => (dispatch, getState) => {
  dispatch(request(appname));

  const { memberbody } = getState().staff.courses.list;

  return firebase
    .database()
    .ref(`app_courses/${appname}/courses/${memberbody}`)
    .on('value', snap => {
      if (snap.exists()) {
        // convert object to compatible version for table
        const list = arrayToObject(
          objectToArray(snap.val()).map(data => ({
            id: data.id,
            value: arrayToObject(
              objectToArray(data.value).map(i => ({
                id: i.id,
                value:
                  i.value.type === 'DateRange'
                    ? i.value.value
                        .map(date => formatDate(date, 'YYYY/MM/DD'))
                        .reduce((final, date, index) =>
                          index === 0
                            ? `${date}`
                            : date === final
                            ? final
                            : `${final} - ${date}`,
                        )
                    : i.value.value,
              })),
            ),
          })),
        );
        dispatch({
          type: RECEIVE_COURSES,
          value: list,
        });
      } else {
        dispatch({
          type: RECEIVE_COURSES,
          value: {},
        });
      }
    });
};

export const deleteCourses = appname => (dispatch, getState) => {
  const { selected, memberbody } = getState().staff.courses.list;
  return Promise.all(
    selected.map(courseID =>
      firebase
        .database()
        .ref(`app_courses/${appname}/courses/${memberbody}/${courseID}`)
        .remove()
        .then(async () => {
          // delete the courses dependencies
          const subscribers = await firebase
            .database()
            .ref(`subscribers/${appname}/course/${courseID}`)
            .once('value')
            .then(snapshot => (snapshot.exists() ? snapshot.val() : {}));
          if (subscribers.workforce) {
            const workforces = objectToArray(subscribers.workforce);
            await Promise.all(
              workforces.map(item =>
                firebase
                  .database()
                  .ref(
                    `subscriber_attendees/${appname}/course/workforce/${item.value.uid}`,
                  )
                  .orderByChild('eventID')
                  .equalTo(courseID)
                  .once('value')
                  .then(snapshot => {
                    if (snapshot.exists()) {
                      Object.keys(snapshot.val()).map(deleteKey =>
                        firebase
                          .database()
                          .ref(
                            `subscriber_attendees/${appname}/course/workforce/${item.value.uid}/${deleteKey}`,
                          )
                          .remove(),
                      );
                    }
                  }),
              ),
            );
          }
          if (subscribers.user) {
            const users = objectToArray(subscribers.user);
            await Promise.all(
              users.map(item =>
                firebase
                  .database()
                  .ref(
                    `subscriber_attendees/${appname}/course/user/${item.value.uid}`,
                  )
                  .orderByChild('eventID')
                  .equalTo(courseID)
                  .once('value')
                  .then(snapshot => {
                    if (snapshot.exists()) {
                      Object.keys(snapshot.val()).map(deleteKey =>
                        firebase
                          .database()
                          .ref(
                            `subscriber_attendees/${appname}/course/user/${item.value.uid}/${deleteKey}`,
                          )
                          .remove(),
                      );
                    }
                  }),
              ),
            );
          }

          await firebase
            .database()
            .ref(`subscribers/${appname}/course/${courseID}`)
            .remove();
        }),
    ),
  ).then(() => {
    dispatch(deletedDone(selected));
    dispatch(openSnackbar(COURSES_DELETED));
  });
};

export const onDuplicateSelected = (appname, rowId) => (dispatch, getState) => {
  const { memberbody } = getState().staff.courses.list;
  return firebase
    .database()
    .ref(`app_courses/${appname}/courses/${memberbody}/${rowId}`)
    .on('value', snap => {
      if (snap.exists()) {
        const data = snap.val();

        const dataKeys = Object.keys(data);
        const courseName = data[dataKeys[0]].value;
        data[dataKeys[0]].value = `${courseName} Copy`;

        return firebase
          .database()
          .ref(`app_courses/${appname}/courses/${memberbody}`)
          .push(data)
          .then(ref => {
            dispatch(openSnackbar(COURSE_DUPLICATED));
          });
      } else {
        dispatch(openSnackbar(COURSE_DUPLICATE_ERROR));
      }
    });
};
