import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Switch, Route, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
// Lodash Imports
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
import { Trans, withTranslation } from 'react-i18next';

// Material Imports
import Popover, { PopoverAnimationVertical } from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import ExpandMore from 'material-ui/svg-icons/navigation/expand-more';
import Divider from 'material-ui/Divider';

import { BuildInterface } from '../../modules/editor/modules/build';
import { CustomiseInterface } from '../../modules/editor/modules/customise';
import PageHeader from '../../app/components/pageHeader';
import Preview from './components/preview';
import { NoticeDialog } from '../../app/modules/notices';
import WelcomeModal from '../editor/modules/customise/modules/welcomeModal';
import DomainChangeModal from '../editor/internals/domainChangeModal';
import {
  CreateComponent,
  CreatePage,
  CreateTab,
  DuplicatePage,
  DuplicateComponent,
} from './modules/build/modules/create';
import { addOffersAnalytics } from '../analytics/actions';

import AccountsIcon from './assets/Accounts';

import './style.css';
import RoundedThin from '../../app/components/buttons/roundedThin';

import AccountsDropdown from './internals/accountsDropdown';
import BeezerRadioButton from '../../app/components/radiobutton/RadioButton';
import { ElementsConsumer } from '@stripe/react-stripe-js';

const styles = {
  menuStyle: {
    padding: '2px 0',
  },
  menuItem: {
    minHeight: 36,
    fontSize: 13,
  },
  divider: {
    margin: 0,
  },
  menuButton: {
    padding: 0,
    margin: 6,
  },
  underline: {
    borderTop: 'none',
  },
  helpButton: {
    margin: 3,
    height: 48,
    width: 48,
    minWidth: 'none',
    backgroundColor: 'rgba(246,246,246,1)',
  },
  helpButtonLabel: {
    padding: 0,
    fontSize: 14,
  },
  tooltip: {
    top: '12px',
    left: '20px',
    paddingRight: '20px',
  },
};
class EditorPreview extends Component {
  static propTypes = {
    splashSettings: PropTypes.object,
    topBarColor: PropTypes.object,
    edited: PropTypes.bool,
    integration: PropTypes.object,
    appname: PropTypes.string.isRequired,
    account: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    subscription: PropTypes.string,
    pageTitle: PropTypes.string,
    currentPath: PropTypes.string,
    pageData: PropTypes.object,
    componentInfo: PropTypes.object,
    componentData: PropTypes.object,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    onPublish: PropTypes.func.isRequired,
    loadContent: PropTypes.func.isRequired,
    removeListeners: PropTypes.func.isRequired,
    isPublished: PropTypes.bool,
    checkUsersSubscription: PropTypes.func.isRequired,
    promotion: PropTypes.object.isRequired,
    offers: PropTypes.object.isRequired,
    addOfferNotice: PropTypes.func.isRequired,
    setOfferEligibility: PropTypes.func.isRequired,
    addPageContentUpdateListner: PropTypes.func.isRequired,
    noticeSet: PropTypes.func.isRequired,
    offerNoticeSet: PropTypes.bool.isRequired,
    buildSectionsAccess: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    splashSettings: {},
    topBarColor: {},
    edited: false,
    pageTitle: '',
    integration: {},
    subscription: null,
    currentPath: null,
    pageData: {},
    componentInfo: {},
    componentData: {},
    isPublished: false,
  };

  state = {
    view: 'mobile',
  };

  componentDidMount() {
    setTimeout(this.props.loadContent, 600);
    const {
      account,
      addOfferNotice,
      appname,
      checkUsersSubscription,
      offers,
      promotion,
      setOfferEligibility,
      noticeSet,
      offerNoticeSet,
      integration,
    } = this.props;
    this.props.accessibilitySettingsListener('', appname);
    this.props.privacySettingsListener('', appname);

    if (
      promotion &&
      promotion.offer &&
      offers[promotion.offer] &&
      !offerNoticeSet &&
      !integration
    ) {
      addOfferNotice(offers[promotion.offer], account.key);
      checkUsersSubscription(appname);

      if (offers[promotion.offer].coupon !== 'lifetime')
        setOfferEligibility(account.key, offers[promotion.offer]);

      addOffersAnalytics('visited', promotion.offer, account.key);
      ReactGA.event({
        category: 'User',
        action: `User logged in with ${promotion.offer}`,
      });
      noticeSet();
    }
  }

  componentDidUpdate = prevProps => {
    const prevPage = queryString.parse(prevProps.location.search).page;
    const currentPage = queryString.parse(this.props.location.search).page;

    if (
      prevProps.pageData &&
      this.props.pageData &&
      (prevPage || currentPage) &&
      (!_isEqual(prevProps.pageData, this.props.pageData) ||
        !_isEqual(prevProps.componentInfo, this.props.componentInfo) ||
        !_isEqual(prevProps.componentData, this.props.componentData))
    ) {
      this.props.addPageContentUpdateListner(currentPage, prevPage);
    }

    if (prevProps.appname !== this.props.appname) {
      this.props.accessibilitySettingsListener(
        prevProps.appname,
        this.props.appname,
      );
      this.props.privacySettingsListener(prevProps.appname, this.props.appname);
    }
  };

  componentWillUnmount() {
    this.props.removeListeners();
  }

  getCurrentAppTitle = () => {
    const { apps, match } = this.props;
    const current = _find(
      apps,
      (app, domain) => domain === match.params.appname,
    );

    return current && current.title;
  };

  handleAppMenuClick = event => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    this.props.toggleAppMenu();
  };

  handleUserMenuClick = event => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    this.props.toggleUserMenu();
  };

  handleAppClick = appname => {
    this.props.toggleAppMenu(false);

    if (appname) {
      this.props.history.push(`/${appname}/editor`);
      this.props.onAppChange(appname);
    }
  };

  getClassName = () =>
    /^\/\w+\/editor\/(build|customise)\/(?!pages)\w+/.test(
      this.props.location.pathname,
    )
      ? 'flex-container sidebar-fully-extended'
      : 'flex-container sidebar-extended';

  handleViewChange = value => this.setState({ view: value });

  publishButtonProps = () =>
    this.props.edited || !this.props.isPublished
      ? {
          borderColor: 'rgb(0, 255, 82)',
          outlineRGB: '176, 255, 202',
        }
      : {};

  defineRoutingForUser = () => {
    const { integration } = this.props;
    const component = integration ? CustomiseInterface : BuildInterface;
    const sharedPaths =
      'app_name|app_icon|app_icon_design|app_splash_screen|navigation_menu|paypal_settings|custom_domain|accessibility|admins|custom-emails-admins|privacy';
    const paths = integration
      ? `/:appname/editor/customise/(${sharedPaths})`
      : `/:appname/editor/build/(design|list|edit|item|page_settings|${sharedPaths})`;

    return <Route path={paths} component={component} />;
  };

  shouldDisableView = () => _get(this.props.integration, 'type') === 'Wix';

  actions = () => (
    <>
      <BeezerRadioButton
        id="previewButton"
        className="preview-toggle"
        label1={this.props.t('Mobile')}
        label2={this.props.t('Desktop')}
        onChange={() => {
          this.setState({
            view: this.state.view === 'mobile' ? 'desktop' : 'mobile',
          });
        }}
        checked={this.state.view !== 'mobile'}
      />

      <RoundedThin
        className="publish"
        {...this.publishButtonProps()}
        disabled={
          this.props.validatingApp ||
          (!this.props.hasPlan &&
            !this.props.isWixUser &&
            this.props.account.underReview &&
            !this.props.account.passedReview)
        }
        onClick={this.props.onPublish}
      >
        <Trans>Publish</Trans>
      </RoundedThin>
    </>
  );

  checkBuildMenu = location => {
    const invalidRoutes = [
      '/build/app_icon_design',
      '/build/custom_domain',
      '/build/app_name',
      '/build/navigation_menu',
      '/build/app_splash_screen',
      '/build/design',
      '/build/list',
      '/build/edit',
      '/build/item',
      '/build/page_settings',
      '/build/admins',
      '/build/custom-emails-admins',
    ];
    const split = location.split('editor');

    if (_includes(invalidRoutes, split[1])) {
      return true;
    }
    return false;
  };

  goToAppMenu = () => {
    this.props.history.push({
      pathname: `/app_menu`,
      state: { currentApp: this.props.appname },
    });
  };
  expertDropdown = () => {
    const location = this.props.location.pathname;
    if (this.checkBuildMenu(location)) {
      return null;
    }

    const currentApp = this.getCurrentAppTitle();
    const appQuantity = this.props.apps && Object.keys(this.props.apps).length;
    const menuItems = _map(this.props.apps, (app, domain) => (
      <MenuItem
        key={domain}
        value={domain}
        primaryText={app.title}
        onClick={() => this.handleAppClick(domain)}
      />
    ));

    const userIsLegacy = this.props.account.legacy_flag;
    const isSubAdminWithMulipleApps = this.props.profile.admin_appnames
      ? Object.keys(this.props.profile.admin_appnames).length > 1
      : false;

    if (!userIsLegacy && !isSubAdminWithMulipleApps) {
      return null;
    }

    const appLimit = typeof userIsLegacy === 'number' ? userIsLegacy : 50;

    const appMenu =
      userIsLegacy || isSubAdminWithMulipleApps ? (
        <div className="app-menu-container">
          <button className="app-menu-btn" onClick={this.handleAppMenuClick}>
            <span>{currentApp}</span>
            <ExpandMore color="#1e2023" />
          </button>
          {userIsLegacy && (
            <RoundedThin className="all-apps-btn" onClick={this.goToAppMenu}>
              <Trans>View Apps</Trans>
            </RoundedThin>
          )}
        </div>
      ) : null;

    const createNewApp =
      userIsLegacy && appQuantity < appLimit
        ? [
            <Divider key="divider" style={styles.divider} />,
            <MenuItem
              key="create-new-app"
              value={null}
              primaryText="Create New App"
              onClick={() => this.props.history.push('/create')}
            />,
          ]
        : null;
    return (
      <div
        ref={node => {
          this.appMenuRef = node;
        }}
      >
        {appMenu}

        <Popover
          open={this.props.appMenu}
          anchorEl={this.appMenuRef}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          animation={PopoverAnimationVertical}
          onRequestClose={this.handleAppMenuClick}
        >
          <Menu style={styles.menuStyle} menuItemStyle={styles.menuItem}>
            {menuItems}
            {createNewApp}
          </Menu>
        </Popover>
      </div>
    );
  };

  mailTo = () => {
    // TODO: this should be a _blank link?
    window.location.href = 'mailto:support@beezer.com';
  };

  userSettings = () => {
    const { t } = this.props;
    return (
      <div
        ref={node => {
          this.userMenuRef = node;
        }}
      >
        <div onClick={this.handleUserMenuClick}>
          <AccountsIcon style={styles.menuButton} />
        </div>
        <Popover
          open={this.props.userMenu}
          anchorEl={this.userMenuRef}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          animation={PopoverAnimationVertical}
          onRequestClose={this.handleUserMenuClick}
        >
          <Menu style={styles.menuStyle} menuItemStyle={styles.menuItem}>
            <MenuItem
              onClick={this.props.onAccountSettingsTouchTap}
              primaryText={t('Account Settings')}
              innerDivStyle={styles.menuItem}
            />
            <Divider style={styles.divider} />
            <a
              href="https://beezer.zendesk.com/hc"
              target="blank"
              rel="noopener noreferrer"
            >
              <MenuItem
                onClick={this.props.onDocumentationTouchTap}
                primaryText={t('Documentation')}
                innerDivStyle={styles.menuItem}
              />
            </a>
            <Divider style={styles.divider} />

            <MenuItem
              onClick={this.mailTo}
              primaryText="support@beezer.com"
              innerDivStyle={styles.menuItem}
            />
            {!this.isWixDashboard() && <Divider style={styles.divider} />}
            {!this.isWixDashboard() && (
              <MenuItem
                onClick={this.props.onLogout}
                primaryText={t('Log Out')}
                innerDivStyle={styles.menuItem}
              />
            )}
          </Menu>
        </Popover>
      </div>
    );
  };

  render() {
    return (
      <div className="editor-body background-texture">
        <div
          className={this.getClassName()}
          style={{
            paddingLeft: `${!this.props.buildSectionsAccess && '20px'}`,
          }}
        >
          <div>
            <PageHeader
              title={this.props.pageTitle}
              actions={this.actions()}
              expert={this.expertDropdown()}
              userSettings={<AccountsDropdown />}
              isWixUser={this.props.isWixUser}
              history={this.props.history}
              hideTitle={!this.props.buildSectionsAccess}
            />
          </div>
          {!this.props.hasPlan &&
            !this.props.isWixUser &&
            this.props.account.underReview &&
            !this.props.account.passedReview && (
              <div className="suspension-banner">
                <p>
                  <Trans>
                    Your app is under review, we will inform you when it is
                    ready for publishing.
                  </Trans>
                </p>
                <p>
                  <Trans>You should receive an email from</Trans>{' '}
                  <a href="mailto:support@beezer.com">support@beezer.com</a>{' '}
                  <Trans>once its reviewed and published.</Trans>
                </p>
              </div>
            )}

          <div className="scroll-container">
            <Preview
              integration={this.props.integration}
              disableView={this.shouldDisableView()}
              subscription={this.props.subscription}
              trialExpired={this.props.account.trial_expired}
              currentPath={this.props.currentPath}
              splashSettings={this.props.splashSettings}
              desktop={this.state.view === 'desktop'}
              topBarColor={this.props.topBarColor}
            />
          </div>
          <CreatePage />
          <CreateTab />
          <ElementsConsumer>
            {({ elements, stripe }) => <CreateComponent stripe={stripe} />}
          </ElementsConsumer>
          <WelcomeModal />
          <DomainChangeModal />
          <NoticeDialog />
          <DuplicatePage />
          <DuplicateComponent />
        </div>
        <Switch>
          <Redirect
            from="/:appname/editor"
            exact
            to={`/${this.props.appname}/editor/${
              this.props.integration ? 'customise' : 'build'
            }/`}
          />
          {this.defineRoutingForUser()}
        </Switch>
      </div>
    );
  }
}

export default withTranslation()(EditorPreview);
