import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import TextField from '../../../../app/components/textField';
import Container from '../../../../app/components/container';
import { FlatButton } from '../../../../app/components/buttons';
import Legal from '../legal';
import MaterialTextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { industries } from '../../constants';
import { createFilterOptions } from '@material-ui/core';
import './styles.css';
import { makeStyles } from '@material-ui/styles';
import { whitelabeledHost } from '../../../../utils/whitelabeledHost';
const filter = createFilterOptions();
const isWhiteLabelledHost = whitelabeledHost();

const useStyles = makeStyles({
  //Autocomplete input styles to match with current text field styles
  textFieldroot: {
    position: 'relative !important',
    marginTop: '1em !important',
    '& .MuiInput-root': {
      borderBottom: '1px solid #D3D3D3',
      '&::before': {
        borderBottom: '0px !important',
        fontFamily: "'Raleway', 'Karla', sans-serif !important",
      },
      '&::after': {
        borderBottom: '1px solid rgb(0, 124, 143) !important',
        fontFamily: "'Raleway', 'Karla', sans-serif !important",
      },
      '&:hover': {
        '&:not(.Mui-disabled)': {
          '&::before': {
            borderBottom: '1px !important',
          },
        },
      },
    },
    '& .MuiInputLabel-root': {
      position: 'absolute',
      lineHeight: '22px',
      pointerEvents: 'none',
      userSelect: 'none',
      color: 'rgba(0, 0, 0, 0.45) !important',
      fontSize: '12px',
      maxWidth: '100%',
      fontFamily: 'Raleway Karla sans-serif',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  autoCompletePopper: {
    zIndex: '1500 !important',
  },
  autoCompleteRoot: {
    position: 'relative',
    overflow: 'visible',
    width: '324px',
    maxWidth: '100%',
    paddingLeft: '6px',
    paddingRight: '6px',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const Profile = ({
  name,
  organisation,
  city,
  country,
  workfield,
  pending,
  isDirty,
  error,
  onNameChange,
  onOrgChange,
  onCityChange,
  onCountryChange,
  onWorkfieldChange,
  onSubmit,
  onOpenLegalModal,
  isAppAdmin,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className="profile-form-container">
      <div className="profile-form">
        <div className="profile-fieldset">
          <Legal />
          <div>
            <TextField
              id="name"
              placeholder={t('Name')}
              {...name}
              onChange={onNameChange}
            />
            <TextField
              id="organisation"
              placeholder={t('Organisation')}
              {...organisation}
              onChange={onOrgChange}
            />
            <Autocomplete
              value={t(workfield.value)}
              style={{
                height: '72px',
                lineHeight: '24px',
              }}
              classes={{
                root: classes.autoCompleteRoot,
                popper: classes.autoCompletePopper,
              }}
              id="workfield"
              onChange={(event, newValue) => {
                if (newValue && newValue.name && !newValue.inputValue) {
                  onWorkfieldChange(newValue.name);
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  onWorkfieldChange(newValue.inputValue);
                } else if (newValue === null) {
                  onWorkfieldChange(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    suggestion: `${t('Add')} "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={industries}
              getOptionLabel={option => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.suggestion;
                }
                // Regular option
                return t(option.name);
              }}
              freeSolo
              fullWidth
              renderInput={params => (
                <MaterialTextField
                  {...params}
                  InputProps={{
                    ref: params.InputProps.ref,
                    endAdornment: params.InputProps.endAdornment,
                  }}
                  classes={{ root: classes.textFieldroot }}
                  label={t('Workfield')}
                  variant="standard"
                />
              )}
            />
          </div>
          <div>
            <TextField
              id="city"
              placeholder={t('City')}
              {...city}
              onChange={onCityChange}
            />
            <TextField
              id="country"
              placeholder={t('Country')}
              {...country}
              onChange={onCountryChange}
            />
          </div>
        </div>
        <Container>
          <small>{error}</small>
        </Container>
        <Container>
          <FlatButton
            label={t('Save Changes')}
            onClick={onSubmit}
            disabled={pending || !isDirty}
            primary
          />
        </Container>
        {!isAppAdmin && !isWhiteLabelledHost && (
          <Container className="legal-links-container">
            <button
              className="inline-button underline-text"
              onClick={() => onOpenLegalModal('terms-and-conditions')}
            >
              <Trans>Terms of Service</Trans>
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              className="inline-button underline-text"
              onClick={() => onOpenLegalModal('end-user-license')}
            >
              <Trans>End User Licence</Trans>
            </button>
          </Container>
        )}
      </div>
    </div>
  );
};

Profile.propTypes = {
  name: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  city: PropTypes.object.isRequired,
  country: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onNameChange: PropTypes.func.isRequired,
  onOrgChange: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onOpenLegalModal: PropTypes.func.isRequired,
  isAppAdmin: PropTypes.bool,
};

Profile.defaultProps = {
  error: null,
  isAppAdmin: false,
};

export default Profile;
