import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Step, Stepper, StepLabel, StepButton } from 'material-ui/Stepper';
import ExpandTransition from 'material-ui/internal/ExpandTransition';

import { InnerLayout } from '../components';
import Payment from '../../payment';
import Register from './validateUser';
import NewUserForm from '../newUser';

import illustration from './output-onlinepngtools.png';
import './styles.css';

class OfferWrapper extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    offer: PropTypes.object.isRequired,
    onRegisterUser: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onLogin: PropTypes.func.isRequired,
  };

  state = {
    stepIndex: 0,
    transition: false,
  };

  transitionStep = cb => {
    this.setState({ transition: true }, () => {
      this.timer = setTimeout(cb, 600);
    });
  };

  nextStep = () => {
    const { stepIndex, transition } = this.state;

    if (!transition) {
      this.transitionStep(() =>
        this.setState({
          stepIndex: stepIndex + 1,
          transition: false,
        }),
      );
    }
  };

  prevStep = () => {
    const { stepIndex, transition } = this.state;

    if (!transition && stepIndex > 0) {
      this.transitionStep(() =>
        this.setState({
          stepIndex: stepIndex - 1,
          transition: false,
        }),
      );
    }
  };

  handleSubmit = () => {
    const { onSubmit, onLogin, onRegisterUser, history, offer } = this.props;
    if (offer.hasPaywall) {
      return onRegisterUser(() => this.props.history.push('/create'));
    }
    return onSubmit(
      (email, password) =>
        onLogin(email, password, () => history.push('/create')),
      offer,
    );
  };

  renderStepContent = () => {
    const { offer } = this.props;

    switch (this.state.stepIndex) {
      case 0:
        return <Register onNextStep={this.nextStep} />;

      case 1:
        return (
          <Payment
            // eslint-disable-next-line react/no-danger
            subtitle={
              <span dangerouslySetInnerHTML={{ __html: offer.discount_text }} />
            }
            cost={offer.price}
            submitLabel="Subscribe"
            onSubmit={this.handleSubmit}
          />
        );

      default:
        return null;
    }
  };

  render() {
    const { offer } = this.props;
    const stepContent = this.renderStepContent();

    return (
      <InnerLayout
        content={
          <div className="content">
            {offer.content.title && (
              <h3 dangerouslySetInnerHTML={{ __html: offer.content.title }} />
            )}
            {offer.content.intro && (
              <p dangerouslySetInnerHTML={{ __html: offer.content.intro }} />
            )}
            <img
              className="offer-illustration"
              style={{
                width: 'calc(100% + 150px)',
                margin: '-20px -75px 0 -75px',
              }}
              src={offer.content.image || illustration}
              alt=""
            />
            {offer.content.description && (
              <p
                dangerouslySetInnerHTML={{ __html: offer.content.description }}
              />
            )}
          </div>
        }
        form={
          <div>
            {offer.hasPaywall ? (
              <div className="col-sm-6 col-xs-12">
                <Stepper
                  style={{ marginLeft: 48, marginRight: 48 }}
                  activeStep={this.state.stepIndex}
                >
                  <Step>
                    <StepButton onClick={this.prevStep}>
                      Create Account
                    </StepButton>
                  </Step>
                  <Step>
                    <StepLabel>Payment Details</StepLabel>
                  </Step>
                </Stepper>
                <ExpandTransition
                  enterDelay={200}
                  loading={this.state.transition}
                  open
                >
                  {stepContent}
                </ExpandTransition>
              </div>
            ) : (
              <NewUserForm onSubmit={data => this.handleSubmit(data)} />
            )}
          </div>
        }
      />
    );
  }
}

export default OfferWrapper;
