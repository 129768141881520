import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Countdown from './Countdown';
import { designChange, fieldChange, loadGoogleAnalytics } from './actions';

const mapStateToProps = (state, ownProps) => {
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];

  return {
    ...ownProps,
    googleAnalytics: state.app.googleAnalytics,
    pages: layout
      ? layout.childRoutes.map(key => state.editor.data.alwaysData[key])
      : [],
    design: ownProps.component.design,
    appname: ownProps.match.params.appname,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onLoadGoogleAnalytics: () =>
    dispatch(loadGoogleAnalytics(ownProps.match.params.appname)),
  onDesignChange: (field, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        field,
        value,
      ),
    ),
  onButtonDesignChange: (type, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        `buttonDesign/${type}`,
        value,
      ),
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Countdown),
);
