import firebase from '../../../../../../../firebase';
import { validateField } from '../../../validators';
import { getStaffs } from '../../../actions';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';
import { PROFILE_UPDATED } from '../../../config';

export const EDIT_STAFF_FIELD_CHANGE = 'EDIT_STAFF_FIELD_CHANGE';
export const OPEN_EDIT_STAFF_DIALOG = 'OPEN_EDIT_STAFF_DIALOG';
export const CLOSE_EDIT_STAFF_DIALOG = 'CLOSE_EDIT_STAFF_DIALOG';
export const STAFF_VALIDATION_ERROR = 'STAFF_VALIDATION_ERROR';
export const STAFF_NOT_AVAILABLE = 'STAFF_NOT_AVAILABLE';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const LOADING_STAFF = 'LOADING_STAFF';
export const LOADED_STAFF_DETAILS = 'LOADED_STAFF_DETAILS';
export const STAFF_DETAILS_UPDATE_REQUEST = 'STAFF_DETAILS_UPDATE_REQUEST';
export const STAFF_DETAILS_UPDATED = 'STAFF_DETAILS_UPDATED';

const fieldChange = (prop, value, isValid) => ({
  type: EDIT_STAFF_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

export const closeDialog = () => ({ type: CLOSE_EDIT_STAFF_DIALOG });

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;

  return dispatch(fieldChange(prop, value, isValid));
};

export const validationError = prop => ({
  type: STAFF_VALIDATION_ERROR,
  prop,
});

export const registrationError = err => ({
  type: REGISTRATION_ERROR,
  err,
});

export const loadingStaff = () => ({
  type: LOADING_STAFF,
});

export const loadStaff = prop => ({
  type: LOADED_STAFF_DETAILS,
  prop,
});

export const closeModal = (app, history) => dispatch => {
  dispatch(closeDialog());
  history.push(`/${app}/members/staff`);
};

export const getStaffDetails = staff => (dispatch, getState) => {
  dispatch(loadingStaff());
  const { chunks } = getState().auth.staff.data;
  const staffDetails = chunks[staff];
  if (staffDetails) {
    const data = {
      firstName: { value: staffDetails.firstName, isValid: true },
      lastName: { value: staffDetails.lastName, isValid: true },
      email: { value: staffDetails.email, isValid: true },
      loading: false,
    };
    dispatch(loadStaff(data));
  }
};

export const updateAccount = (staff, history, app) => (dispatch, getState) => {
  dispatch({ type: STAFF_DETAILS_UPDATE_REQUEST });
  const { firstName, lastName } = getState().auth.staff.editStaff;
  const update = {
    firstName: firstName.value,
    lastName: lastName.value,
    lastUpdated: Date.now(),
  };

  return firebase
    .database()
    .ref(`users/${staff}`)
    .update(update)
    .then(() => {
      dispatch({
        type: STAFF_DETAILS_UPDATED,
        key: staff,
        data: update,
      });
      dispatch(getStaffs(app));
      dispatch(closeModal(app, history));
      dispatch(openSnackbar(PROFILE_UPDATED));
    });
};
