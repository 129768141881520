import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import New from './New';

import { addCPD, closeNewCPDDialog, formFieldChange } from './actions';

const mapStateToProps = state => ({
  ...state.staff.cpds.create,
  fields: state.staff.cpds.list.fields,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(addCPD(ownProps.match.params.appname)),
  onRequestClose: () => dispatch(closeNewCPDDialog()),
  onFieldChange: (isRequired, type, field, value, index) =>
    dispatch(formFieldChange(isRequired, type, field, value, index)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(New),
);
