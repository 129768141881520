import _mapValues from 'lodash/mapValues';
import i18n from 'i18next';
import firebase from '../../../../firebase';
import { APP_SUMO_PLANS, DIARY_ADDON_PLAN } from '../../../../plans/constants';

export const RECEIVE_AUTH_STATUS = 'RECEIVE_AUTH_STATUS';
export const RECEIVE_AUTH_TOGGLE_STATUS = 'RECEIVE_AUTH_TOGGLE_STATUS';
export const DIARY_TOGGLE_FIELD = 'DIARY_TOGGLE_FIELD';
export const EVENTS_TOGGLE_FIELD = 'EVENTS_TOGGLE_FIELD';

export const toggleAuth = (appname, value, type = 'multi', addon) => (
  dispatch,
  getState,
) => {
  const subscription = getState().account.subscriptions.items[appname];
  const legacyFlag = getState().account.organisation.legacy_flag;
  const trialExpired = getState().account.organisation.trial_expired;
  const diyComponents = getState().app.addons.diyComponents;

  // Allow a user to enable auth if they are on the plan accelerate
  // or if they are an expert customer (only expert customers have a legacy flag)
  if (
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('accelerate')) ||
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('launch')) ||
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('deluxe')) ||
    (subscription &&
      subscription.plan &&
      subscription.plan.includes('expert')) ||
    (subscription &&
      subscription.plan &&
      APP_SUMO_PLANS.includes(subscription.plan)) ||
    (addon && diyComponents && diyComponents[addon]) ||
    !trialExpired ||
    legacyFlag
  ) {
    if (value) {
      Promise.all([
        firebase
          .database()
          .ref(`app_groups/${appname}/groups`)
          .once('value'),
        firebase
          .database()
          .ref(`apps/${appname}/draft/always_data/auth`)
          .set({
            type: 'auth',
            authType: type,
          }),
      ]).then(([snap]) => {
        if (!snap.exists()) {
          firebase
            .database()
            .ref(`apps/${appname}/draft/lazy_data`)
            .once('value')
            .then(lazyData => {
              firebase
                .database()
                .ref(`app_groups/${appname}`)
                .set({
                  groups: {
                    default: {
                      name: i18n.t('My Default Group'),
                      default: true,
                      date: Date.now(),
                      access: {
                        // provide seed key, so access node exists
                        // ...and because we aren't handling this correctly in other places at the moment
                        someKey: true,
                      },
                    },
                  },
                  not_private: _mapValues(lazyData.val(), () => true),
                });
            });
        }
      });
    } else {
      firebase
        .database()
        .ref(`apps/${appname}/draft/always_data/auth`)
        .set(null);
    }

    dispatch({
      type: 'TOGGLE_AUTH',
      appname,
      toggle: value,
      authType: type,
    });
  } else {
    dispatch({
      type: 'UPGRADE_AUTH_MODEL',
      open: true,
      addon,
    });
  }
};

export const stopGetAuth = appname => {
  firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/auth`)
    .off('value');

  return {
    type: 'STOP_AUTH_LISTENER',
    appname,
  };
};

export const getAuth = appname => dispatch => {
  firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/auth`)
    .on('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_AUTH_STATUS,
          value: true,
          authType: snap.val().authType,
        });
        return;
      }

      dispatch({
        type: RECEIVE_AUTH_STATUS,
        value: false,
        authType: null,
      });
    });
};

export const getToggledAuth = appname => dispatch => {
  firebase
    .database()
    .ref(`apps/${appname}/hasToggleAuth`)
    .on('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_AUTH_TOGGLE_STATUS,
          value: snap.val(),
        });
        return;
      }

      dispatch({
        type: RECEIVE_AUTH_TOGGLE_STATUS,
        value: false,
      });
    });
};

export const setToggledAuth = appname => dispatch => {
  firebase
    .database()
    .ref(`apps/${appname}/hasToggleAuth`)
    .set(true);
};

export const toggleDiaryEnabled = (appname, toggled, field) => (
  dispatch,
  getState,
) => {
  if (toggled) {
    const state = getState();
    const subscription = state.account.subscriptions.items[appname];
    const legacyFlag = state.account.organisation.legacy_flag;
    const plan = (subscription && subscription.plan) || '';

    const diaryAddon =
      (state.app.addons &&
        state.app.addons.diyComponents &&
        state.app.addons.diyComponents.Diary &&
        state.app.addons.diyComponents.Diary.name) ||
      '';

    const showAddonCard = plan.indexOf('diy') !== -1 && diaryAddon === '';

    if (
      (subscription &&
        subscription.plan &&
        subscription.plan.includes('expert')) ||
      (subscription &&
        subscription.plan &&
        APP_SUMO_PLANS.includes(subscription.plan)) ||
      legacyFlag
    ) {
      firebase
        .database()
        .ref(`app_groups/${appname}/groups/default`)
        .update({ [`${field}`]: toggled })
        .then(() =>
          dispatch({
            type: DIARY_TOGGLE_FIELD,
            value: toggled,
          }),
        );
    } else if (showAddonCard || plan.includes('something')) {
      dispatch({
        type: 'UPGRADE_AUTH_MODEL_ADDON',
        payload: {
          open: true,
          addon_payload: {
            type: 'diary',
            plan: DIARY_ADDON_PLAN,
          },
        },
      });
    } else {
      firebase
        .database()
        .ref(`app_groups/${appname}/groups/default`)
        .update({ [`${field}`]: toggled })
        .then(() =>
          dispatch({
            type: DIARY_TOGGLE_FIELD,
            value: toggled,
          }),
        );
    }
  } else {
    firebase
      .database()
      .ref(`app_groups/${appname}/groups/default`)
      .update({ [`${field}`]: toggled })
      .then(() =>
        dispatch({
          type: DIARY_TOGGLE_FIELD,
          value: toggled,
        }),
      );
  }
};

export const toggleEventsEnabled = (appname, toggled, field) => dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default`)
    .update({ [`${field}`]: toggled })
    .then(() =>
      dispatch({
        type: EVENTS_TOGGLE_FIELD,
        value: toggled,
      }),
    );
};
