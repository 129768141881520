import { connect } from 'react-redux';

import Booking from './Booking';

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    appname: ownProps.match.params.appname
});

export default connect(mapStateToProps, null)(Booking);
