import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import _pickBy from 'lodash/pickBy';

import {
  TOGGLE_BLOG_MODAL,
  ADD_NEW_BLOG,
  DESELECT_ALL_BLOGS,
  FILTER_BLOG_DATA,
  NEW_BLOG_FIELD_CHANGE,
  NEW_BLOG_ADDED,
  OPEN_BLOG_DIALOG_UPDATE_DATA,
  RECEIVE_BLOG,
  REQUEST_BLOG,
  SELECT_BLOG,
  SELECT_ALL_BLOGS,
  RECEIVE_BLOG_CATEGORIES,
  REQUEST_BLOG_CATEGORIES,
  TOGGLE_BLOG_CATEGORIES_MODAL,
  TOGGLE_SAVE_BLOG,
} from './actions';

const initialState = {
  pending: false,
  chunks: {},
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  selected: [],
  modalOpen: false,
  selectAll: false,
  isUpdate: false,
  rowId: '',
  blogCategories: {},
  categoriesLoading: true,
  categoriesModalOpen: false,
  saveBlog: false,
  title: {
    value: '',
    isValid: null,
  },
  published: {
    value: true,
    isValid: null,
  },
  categories: {
    value: [],
    isValid: null,
  },
  content: {
    value: '',
    isValid: null,
  },
  cover_image: {
    value: '',
    isValid: null,
  },
};

function parseHTMLString(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc.body.textContent.substring(0, 250) || '';
}

const filterChunks = (chunks, filterHolder = '') => {
  let filteredChunks = chunks;
  const filter = filterHolder.replace(/[^a-zA-Z0-9 ]/g, '');

  if (filter !== '') {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`(${filter})`, 'gi');
      let categoriesMatched = false;

      if (chunk.categories) {
        chunk.categories.forEach(cat => {
          if (rgx.test(cat.label)) {
            categoriesMatched = true;
          }
        });
      }

      return (
        rgx.test(chunk.title) ||
        categoriesMatched ||
        rgx.test(parseHTMLString(chunk.content))
      );
    });
  }
  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_BLOG_MODAL: {
      if (action.value) {
        return {
          ...state,
          modalOpen: action.value,
        };
      }
      return {
        ...state,
        modalOpen: action.value,
        isUpdate: false,
        rowId: '',
        title: {
          value: '',
          isValid: null,
        },
        published: {
          value: true,
          isValid: null,
        },
        content: {
          value: '',
          isValid: null,
        },
        cover_image: {
          value: '',
          isValid: null,
        },
      };
    }
    case ADD_NEW_BLOG: {
      return {
        ...state,
        pending: true,
      };
    }
    case DESELECT_ALL_BLOGS: {
      return {
        ...state,
        filteredChunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };
    }
    case FILTER_BLOG_DATA: {
      return {
        ...state,
        ...filterChunks(state.chunks, action.payload),
        filter: action.payload,
      };
    }
    case NEW_BLOG_FIELD_CHANGE: {
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };
    }
    case NEW_BLOG_ADDED: {
      return {
        ...state,
        modalOpen: false,
        pending: false,
        isUpdate: false,
        rowId: '',
        title: {
          value: '',
          isValid: null,
        },
        published: {
          value: true,
          isValid: null,
        },
        categories: {
          value: [],
          isValid: null,
        },
        content: {
          value: '',
          isValid: null,
        },
        cover_image: {
          value: '',
          isValid: null,
        },
      };
    }
    case OPEN_BLOG_DIALOG_UPDATE_DATA: {
      return {
        ...state,
        modalOpen: true,
        isUpdate: true,
        rowId: action.key,
        title: {
          value: action.data.title || '',
          isValid: true,
        },
        published: {
          value: action.data.published || true,
          isValid: null,
        },
        categories: {
          value: action.data.categories || [],
          isValid: null,
        },
        content: {
          value: action.data.content || '',
          isValid: null,
        },
        cover_image: {
          value: action.data.cover_image || '',
          isValid: null,
        },
      };
    }
    case RECEIVE_BLOG: {
      return {
        ...state,
        chunks: action.value,
        selected: [],
        filteredChunks: action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };
    }
    case REQUEST_BLOG: {
      return {
        ...state,
        pending: true,
        error: null,
      };
    }
    case SELECT_BLOG: {
      const prevState =
        (state.filteredChunks[action.key] &&
          state.filteredChunks[action.key].selected) ||
        false;

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }
    case SELECT_ALL_BLOGS: {
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: !chunk.default,
      }));
      const selected = _union(
        state.selected,
        Object.keys(filteredChunks).filter(chunk => chunk !== 'default'),
      );

      return {
        ...state,
        filteredChunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }
    case REQUEST_BLOG_CATEGORIES: {
      return {
        ...state,
        categoriesLoading: true,
        error: null,
      };
    }
    case RECEIVE_BLOG_CATEGORIES: {
      return {
        ...state,
        blogCategories: action.value,
        categoriesLoading: false,
        error: null,
      };
    }
    case TOGGLE_BLOG_CATEGORIES_MODAL: {
      return {
        ...state,
        categoriesModalOpen: action.value,
      };
    }
    case TOGGLE_SAVE_BLOG: {
      return {
        ...state,
        saveBlog: action.value,
      };
    }
    default:
      return state;
  }
};

export default blogReducer;
