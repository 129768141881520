import React from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import InventoryImage from './images/inventory.jpg';
import OrdersImage from './images/orders.jpg';
import DriversImage from './images/drivers.jpg';
import SettingsImage from './images/settings.jpg';
import FoodAppImage1 from './images/food-app-1.jpg';
import FoodAppImage2 from './images/food-app-2.jpg';
import FoodAppImage3 from './images/food-app-3.jpg';
import FoodAppImage4 from './images/food-app-4.jpg';

import styles from './styles.module.scss';

const Features = ({ isAppAdmin }) => {
  const { t } = useTranslation();
  const isSubAdminWithMulipleApps = useSelector(
    state =>
      (state.account.auth.profile.admin_appnames &&
        Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
      false,
  );
  return (
    <div style={{ overflow: 'scroll', padding: 0 }}>
      <div className={styles.container_top}>
        <p>
          <Trans>
            The Food Orders feature works hand in hand with the Food Delivery
            component which can be added from the Components menu in the section
            Build → Pages.
          </Trans>
        </p>
        <p>
          <Trans>
            You can add the inventory items in the Inventory section, and these
            will be reflected in your Food Delivery component.
          </Trans>
        </p>
        {!isAppAdmin && !isSubAdminWithMulipleApps && (
          <p>
            <Trans>Plus, check out our</Trans>{' '}
            <a
              href="https://beezer.zendesk.com/hc/en-us/articles/360020064817-Business-Tools-Food-Orders"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#261ed5', fontWeight: 'bold' }}
            >
              <Trans>article</Trans>
            </a>{' '}
            <Trans>to learn more about how Food Orders feature works.</Trans>
          </p>
        )}
      </div>
      <div className={styles.container_middle}>
        <h1>
          <Trans>CORE FUNCTIONALITIES</Trans>
        </h1>
        <p>
          • <Trans>Inventory to add & update items</Trans>
        </p>
        <p>
          • <Trans>Payments through Stripe</Trans>
        </p>
        <p>
          • <Trans>Updates via email and notifications</Trans>
        </p>
        <p>
          • <Trans>Map to setup a delivery zone</Trans>
        </p>
        <p>
          • <Trans>Features like tax, currency & coupons</Trans>
        </p>
        <p>
          • <Trans>Automatic stock counting</Trans>
        </p>
        <p>
          • <Trans>Assign drivers to deliver food</Trans>
        </p>
      </div>
      <div className={styles.container_bottom}>
        <div className={styles.card}>
          <div>
            <h3>
              <Trans>INVENTORY</Trans>
            </h3>
            <p>
              <Trans>
                With Inventory you can add items to display in your food
                component. After filling in your item details you can decide if
                you want to make your item active or leave it as draft. If you
                ever want to remove an item from your Food Delivery component
                you can always come back and delete your item or change its
                status to draft.
              </Trans>
            </p>
            <p>
              <Trans>
                Have an item that is special? You can set the item category to
                special in order to display it at the top of your Food Delivery
                component in the Specials section.
              </Trans>
            </p>
          </div>
          <img className={styles.image} src={InventoryImage} alt="inventory" />
        </div>
        <div className={styles.card_reverse}>
          <div>
            <h3>
              <Trans>ORDERS</Trans>
            </h3>
            <p>
              <Trans>
                Orders is the place to go to view all of your incoming orders,
                both delivered and those that are not yet delivered. You can
                choose a driver to deliver an order and once the driver does so
                they can mark the order as delivered which will be reflected
                here.
              </Trans>
            </p>
            <p>
              <Trans>
                You can also filter through all your orders in case you need to
                find a specific order. Finally, you can view order details to
                get detailed information about an order.
              </Trans>
            </p>
          </div>
          <img className={styles.image} src={OrdersImage} alt="orders" />
        </div>
        <div className={styles.card}>
          <div>
            <h3>
              <Trans>MAP, TAX, COUPONS & MORE</Trans>
            </h3>
            <p>
              <Trans>
                To restrict your order delivery location we provide you with a
                map. You can use this map to select the zones you will deliver
                to. Only people in your selected zone will be able to place an
                order.
              </Trans>
            </p>
            <p>
              <Trans>
                Here you are required to add your Stripe API keys in order to
                connect the app to your Stripe Business account.
              </Trans>
            </p>
            <p>
              <Trans>
                There are also tons of other features you can configure such as
                an optional tax, the currency you want to accept payments in,
                the email to send all order updates to & coupons to offer
                discounts to your customers.
              </Trans>
            </p>
          </div>
          <img className={styles.image} src={SettingsImage} alt="settings" />
        </div>
        <div className={styles.card_reverse}>
          <div>
            <h3>
              <Trans>DRIVERS</Trans>
            </h3>
            <p>
              <Trans>
                Here you can create a new Contact and add them as a Driver. Once
                added as a Driver, you can assign Orders to the particular user,
                and they will receive the order details in an email
                notification. They will be able to change the status of the
                Order from Not Delivered to Delivered by clicking on the link
                within the email notification.
              </Trans>
            </p>
          </div>
          <img className={styles.image} src={DriversImage} alt="drivers" />
        </div>
      </div>
      {!isAppAdmin && !isSubAdminWithMulipleApps && (
        <div className={styles.container_appshell}>
          <h1>
            <Trans>CHECK IT OUT</Trans>
          </h1>
          <p>
            <Trans>
              Take a look at one of our apps to see what you can build with the
              Food Delivery component.
            </Trans>
          </p>
          <a
            href="https://burgermenu.beezer.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>VIEW APP</Trans>
          </a>
          <div>
            <img src={FoodAppImage1} alt={t('food app 1')} />
            <img src={FoodAppImage2} alt={t('food app 2')} />
            <img src={FoodAppImage3} alt={t('food app 3')} />
            <img src={FoodAppImage4} alt={t('food app 4')} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Features;
