import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { components, APP_SUMO_PLANS } from '../../../../../../../plans';
import { appHasComponent } from '../../../../../../../utils';
import CreateComponent from './CreateComponent';
import {
  closeCreateComponentUI,
  createComponent,
  componentTypeChange,
  getAllComponents,
  getSpecialComponents,
} from './actions';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';
import { reportPaymentSuccess } from '../../../../../../account/modules/payment/actions';
import { setProcessingSpinner } from '../../../../../../../app/modules/diyComponents/actions';
import {
  toggleAuth,
  getAuth,
} from '../../../../../../../modules/memberLogin/modules/memberLoginMenu/actions';

import {
  addToDiyComponents,
  updateAddOnSubscription,
  loadDiyComponents,
  addSpecialAddonComponents,
} from '../../../../../../../app/actions';

const mapStateToProps = (state, ownProps) => {
  const defaultGroup = state.auth.groups.data.chunks.default;
  const freeTrialPlan =
    state.account.organisation.trial_expired || state.account.profile.parent
      ? null
      : 'accelerate';
  const enabled = state.auth.structure.enabled || false;
  const diaryEnabled = defaultGroup ? defaultGroup.diaryEnabled : false;
  const eventsEnabled = defaultGroup ? defaultGroup.diaryEnabled : false;
  const legacyFlag = state.account.organisation.legacy_flag || false;
  const isInTrial = !state.account.organisation.trial_expired || true;
  const subscription =
    state.account.subscriptions.items[ownProps.match.params.appname] || {};
  const isAppAdmin =
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false;
  const isSubAdminWithMulipleApps =
    (state.account.auth.profile.admin_appnames &&
      Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
    false;
  return {
    ...ownProps,
    isAppAdmin,
    isSubAdminWithMulipleApps,
    ...state.editor.build.create.createComponent,
    payment: state.account.payment,
    appname: ownProps.match.params.appname,
    accountId: state.account.auth.profile.account,
    intl: state.account.intl,
    subscription: freeTrialPlan || state.app.appContext.subscription || 'start',
    diyComponents: state.app.addons.diyComponents || {},
    isProcessing: state.app.addons.isProcessing || false,
    subscriptionItem: state.account.subscriptions,
    diyPopupOpen:
      state.editor.build.create.createComponent.diyPopupOpen || false,
    specialAddons: state.editor.build.create.createComponent.specialAddons,
    isAppSumAccount:
      state.account.organisation.appsumo_id !== '' ||
      state.account.auth.profile.parent,
    loginEnabled:
      enabled &&
      (diaryEnabled ||
        eventsEnabled ||
        legacyFlag ||
        isInTrial ||
        (subscription && subscription.plan === 'deluxe') ||
        (subscription && subscription.plan === 'accelerate') ||
        (subscription && subscription.plan === 'launch') ||
        (subscription && subscription.plan === 'diy') ||
        (subscription &&
          subscription.plan &&
          APP_SUMO_PLANS.includes(subscription.plan))),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { appname } = ownProps.match.params;
  const query = queryString.parse(ownProps.location.search);
  const router = {
    history: ownProps.history,
    location: ownProps.location,
    match: ownProps.match,
  };

  return {
    getAllComponents: () => dispatch(getAllComponents(appname)),
    onLoad: () => {
      dispatch(loadDiyComponents(appname));
    },
    onSubmit: componentType =>
      dispatch(createComponent(appname, query, componentType, router)),
    onCancel: () => dispatch(closeCreateComponentUI()),
    onTypeChange: value => dispatch(componentTypeChange(value)),
    onConfirm: (componentType, currency) =>
      dispatch(
        addToDiyComponents(
          componentType,
          components[componentType],
          appname,
          currency,
        ),
      ).then(errorCode => {
        //if payment does not require auth add the component
        if (!(errorCode === 'invoice_payment_intent_requires_action')) {
          dispatch(createComponent(appname, query, componentType, router));
        }
        dispatch({ type: 'TOGGLE_DIY_POPUP_STATE', value: false });
      }),
    addSpecialAddons: (
      componentType,
      currency,
      closePopup,
      hasTrialExpired,
      history,
      handleChange,
    ) =>
      dispatch(
        addSpecialAddonComponents(
          componentType,
          currency,
          closePopup,
          hasTrialExpired,
          history,
          ownProps.match.params.appname,
          handleChange,
        ),
      ),
    updateAddOnSubscription: (componentType, currency) => {
      dispatch(
        updateAddOnSubscription(
          componentType,
          components[componentType],
          appname,
          currency,
        ),
      ).then(() => {
        dispatch(createComponent(appname, query, componentType, router));
      });
    },
    addOnPaymentFailed: err => {
      dispatch(setProcessingSpinner(false));
      dispatch(openSnackbar('Your payment for addon failed'));
      dispatch({ type: 'PAYMENT_FAILURE', err });
    },
    toggleDiyPopup: value => {
      dispatch({ type: 'TOGGLE_DIY_POPUP_STATE', value });
    },
    addOnPaymentSucceeded: () => dispatch(reportPaymentSuccess()),
    getSpecialComponents: () => dispatch(getSpecialComponents()),
    toggleAuth: (v, type) =>
      dispatch(toggleAuth(ownProps.match.params.appname, v, type)),
    getAuth: () => dispatch(getAuth(ownProps.match.params.appname)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateComponent),
);
