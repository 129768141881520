import { combineReducers } from 'redux';
import _pickBy from 'lodash/pickBy';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';

import {
  REQUEST_VOTE_DATA,
  RECEIVE_VOTE_DATA,
  RECEIVE_VOTE_DATA_UPDATE,
  REQUEST_ALL_VOTES,
  RECEIVE_ALL_VOTES,
  REPORT_VOTE_DATA_REQUEST_ERROR,
  FILTER_VOTE_DATA,
  SELECT_VOTE,
  SELECT_ALL_VOTES,
  DESELECT_ALL_VOTES,
  FAVOURITE_SELECTED,
  DELETE_SELECTED_VOTES,
  SORT_VOTES,
} from './actions';
import voteDetails from './voteDetails/reducer';

const initialState = {
  pending: false,
  chunks: {},
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  selected: [],
  selectAll: false,
  votes: [],
  filteredVotes: [],
  order: 'desc',
  colSorted: 'date',
};

const filterChunks = (chunks, votes, filter = '') => {
  let filteredVotes = votes;
  let filteredChunks = chunks;
  let items = Object.keys(filteredChunks);

  if (filter !== '') {
    const rgx = new RegExp(`(${filter})`, 'g');
    filteredVotes = _pickBy(votes, chunk => {
      const results = Object.values(chunk.options.chunks).filter(item =>
        rgx.test(item.title),
      );
      return rgx.test(chunk.title) || results.length > 0;
    });
    items = Object.keys(filteredVotes);
    filteredChunks = Object.keys(chunks)
      .filter(chunk => items.indexOf(chunk) >= 0)
      .reduce(
        (obj, key) => ({
          ...obj,
          [key]: chunks[key],
        }),
        {},
      );
  }

  return { filteredChunks, filteredVotes, items };
};

const sortItems = (filteredChunks, sortBy, order) =>
  _keys(filteredChunks).sort((a, b) => {
    let keyA = filteredChunks[a][sortBy];
    let keyB = filteredChunks[b][sortBy];
    if (sortBy === 'date') {
      keyA = new Date(filteredChunks[a][sortBy]);
      keyB = new Date(filteredChunks[b][sortBy]);
    }
    if (order === 'desc') {
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
    } else if (order === 'asc') {
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
    }
    return 0;
  });

const vote = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_VOTE_DATA: {
      return {
        ...state,
        pending: true,
        error: null,
      };
    }

    case RECEIVE_VOTE_DATA: {
      return {
        ...state,
        chunks: action.data,
        items: sortItems(action.data, state.colSorted, state.order),
        pending: false,
        filteredChunks: action.data,
        filter: action.filter,
        error: null,
      };
    }

    case RECEIVE_VOTE_DATA_UPDATE: {
      return {
        ...state,
        chunks: action.data,
        ...filterChunks(action.data, state.filteredVotes, state.filter),
        items: sortItems(action.data, state.colSorted, state.order),
        pending: false,
        error: null,
      };
    }

    case REQUEST_ALL_VOTES: {
      return {
        ...state,
        pending: true,
        error: state.error ? state.error : null,
      };
    }

    case RECEIVE_ALL_VOTES: {
      const { filteredChunks, filteredVotes } = filterChunks(
        state.filteredChunks,
        action.data,
        state.filter,
      );
      return {
        ...state,
        votes: action.data,
        filteredChunks,
        filteredVotes,
        pending: false,
        error: state.error ? state.error : null,
      };
    }

    case REPORT_VOTE_DATA_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.err,
        selected: [],
      };

    case FILTER_VOTE_DATA: {
      return {
        ...state,
        filter: action.payload,
        ...filterChunks(state.chunks, state.votes, action.payload),
      };
    }

    case SELECT_VOTE: {
      const prevState = state.filteredChunks[action.key].selected;

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case SELECT_ALL_VOTES: {
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: true,
      }));
      const selected = _union(state.selected, _keys(filteredChunks));

      return {
        ...state,
        filteredChunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_VOTES:
      return {
        ...state,
        filteredChunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    case FAVOURITE_SELECTED:
      return {
        ...state,
      };

    case DELETE_SELECTED_VOTES:
      return {
        ...state,
      };

    case SORT_VOTES:
      return {
        ...state,
        order: state.order === 'desc' ? 'asc' : 'desc',
        items: sortItems(
          state.filteredChunks,
          action.column.id,
          state.order === 'desc' ? 'asc' : 'desc',
        ),
        colSorted: action.column.id,
      };

    default:
      return state;
  }
};

export default combineReducers({
  data: vote,
  voteDetails,
});
