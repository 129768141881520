import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from 'material-ui/Checkbox';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { Trans, withTranslation } from 'react-i18next';

import PageUpdateModal from './pageUpdateModal';
import TabPage from '../../../../components/tabPage';
import Container from '../../../../../../app/components/container';
import SelectField from '../../../../../../app/components/selectField';
import TextField from '../../../../../../app/components/textField';
import { FlatButton } from '../../../../../../app/components/buttons';

import { newValidator } from '../../../../../../utils/validation';
import SectionHeader from '../../../../components/sectionHeader';
import Toggle from '../../../../../../app/components/toggle';
import { PGMOL_APPS } from '../../../../../../config/constants';

import './style.css';

class PageSettings extends Component {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    parentPageKey: PropTypes.string,
    pages: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    type: PropTypes.string,
    hidden: PropTypes.bool,
    tabs: PropTypes.array,
    scrollDown: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    launchRemoveDialog: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    appname: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isDirty: false,
    autoScrollEdited: false,
    title: '',
    subtitle: '',
    hidden: false,
    scrollDown: {
      enabled: false,
      type: 'continuous',
    },
    type: undefined,
    tabs: undefined,
  };

  titleValidator = newValidator({
    maxLength: PGMOL_APPS.indexOf(this.props.appname) > -1 ? 30 : 21,
    required: true,
  });

  subtitleValidator = newValidator({
    maxLength: 21,
  });

  state = {
    open: false,
    isDirty: false,
    autoScrollEdited: false,
    scrollDown: this.props.scrollDown,
    title: {
      value: this.props.title,
      isValid: this.titleValidator(this.props.title),
    },
    subtitle: {
      value: this.props.subtitle,
      isValid: null,
    },
    type: this.props.type,
    hidden: this.props.hidden,
    parentPageKey: this.props.parentPageKey,
    tabs: this.props.tabs
      ? this.props.tabs.map(tab => ({
          ...tab,
          title: {
            value: tab && tab.title,
            isValid: this.titleValidator(tab.title),
          },
        }))
      : [],
  };

  handleSubmit = () => {
    this.setState({ isDirty: false, autoScrollEdited: false, open: false });
    this.props.onSubmit(this.state);
  };

  handleTitleChange = value => {
    this.setState({
      isDirty: true,
      title: {
        value,
        isValid: this.titleValidator(value),
      },
    });
  };

  handleTypeChange = (index, value) => {
    if (value === 'TabView' && !this.state.tabs) {
      return this.setState({
        isDirty: true,
        type: 'TabView',
        tabs: [
          {
            title: {
              value: '',
              isValid: null,
            },
          },
          {
            title: {
              value: '',
              isValid: null,
            },
          },
        ],
      });
    }

    return this.setState({
      isDirty: true,
      type: value,
    });
  };

  handleTabChange = (index, value) => {
    const { tabs } = this.state;

    this.setState({
      isDirty: true,
      tabs: [
        ...tabs.slice(0, index),
        {
          ...tabs[index],
          title: {
            value,
            isValid: this.titleValidator(value),
          },
        },
        ...tabs.slice(index + 1),
      ],
    });
  };

  handleAddTab = () => {
    this.setState({
      isDirty: true,
      tabs: this.state.tabs.concat({
        title: {
          value: '',
          isValid: null,
        },
      }),
    });
  };

  handleRadioChange = prop => (f, value) => this.props.onChange(prop, value);

  handleRemoveTab = index => {
    this.setState({
      isDirty: true,
      tabs: [
        ...this.state.tabs.slice(0, index),
        ...this.state.tabs.slice(index + 1),
      ],
    });
  };

  handleCheck = (e, isChecked) => {
    this.setState({
      isDirty: true,
      hidden: isChecked,
    });
  };

  pageEdited = () =>
    this.state &&
    this.state.isDirty &&
    this.state.title.isValid &&
    (this.state.type === 'TabView'
      ? this.state.tabs.every(tab => tab.title.isValid)
      : true) &&
    (this.state.type === 'NestedPage' ? this.state.parentPageKey : true) &&
    (this.state.type === 'NestedTabPage' ? this.state.parentPageKey : true);

  renderToggle = () => (
    <Toggle
      onToggle={(e, isToggled) => {
        this.setState({
          autoScrollEdited: true,
          scrollDown: {
            ...this.state.scrollDown,
            enabled: isToggled,
          },
        });
      }}
      toggled={this.isScrollDownEnabled()}
    />
  );

  isScrollDownEnabled = () =>
    !!(this.state.scrollDown && this.state.scrollDown.enabled);

  onChangeAutoScrollType = value => {
    if (value) {
      this.setState({
        autoScrollEdited: true,
        scrollDown: {
          ...this.state.scrollDown,
          type: value,
        },
      });
    }
  };

  onParentPageChange = value => {
    this.setState({
      isDirty: true,
      parentPageKey: value,
    });
  };

  dropdownNavigationOptions = () =>
    this.props.pages.map(
      (page, index) =>
        page.type !== 'Popup' &&
        page.type !== 'NestedPage' &&
        page.key !== this.props.pageKey && (
          <MenuItem key={page.key} value={page.key} primaryText={page.title} />
        ),
    );

  handleSave = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { type, appname, t } = this.props;
    const { scrollDown, autoScrollEdited, open } = this.state;
    const displayTypeWarning = this.state.type !== type;
    const scrollDownType = (scrollDown && scrollDown.type) || 'continuous';
    const canUpdate = autoScrollEdited || this.pageEdited() || open;

    return (
      <Container>
        <SelectField
          label={t('Page Type')}
          value={this.state.type}
          options={[
            <MenuItem key="view" value="View" primaryText="Standard" />,
            <MenuItem
              key="nestedpage"
              value="NestedPage"
              primaryText={t('Nested Page')}
            />,
          ]}
          onChange={this.handleTypeChange}
          warning={
            displayTypeWarning
              ? t(
                  'Note: Changing a page type may result in components being rearranged.',
                )
              : null
          }
        />
        <TextField
          id="page-title"
          {...this.state.title}
          placeholder={t('Title')}
          onChange={this.handleTitleChange}
          maxLength={PGMOL_APPS.indexOf(appname) > -1 ? 30 : 21}
          required
          helperText={`${t('Title should not be more than')} ${
            PGMOL_APPS.indexOf(appname) > -1 ? 30 : 21
          } ${t('characters')}`}
        />
        {(this.state.type === 'NestedPage' ||
          this.state.type === 'NestedTabPage') && (
          <SelectField
            label={t('Parent Page')}
            value={this.state.parentPageKey}
            options={this.dropdownNavigationOptions()}
            onChange={(i, val) => this.onParentPageChange(val)}
          />
        )}
        <Checkbox
          id="hide-page"
          label={t('Hide page from main navigation menu')}
          style={{
            paddingLeft: 18,
            paddingRight: 18,
            marginTop: 18,
            marginBottom: 18,
          }}
          labelStyle={{ fontSize: 12 }}
          checked={this.state.hidden}
          onCheck={this.handleCheck}
        />
        {(this.state.type === 'TabView' ||
          this.state.type === 'NestedTabPage') && (
          <TabPage
            tabs={this.state.tabs}
            onTabChange={this.handleTabChange}
            onRemove={this.handleRemoveTab}
            onAdd={this.handleAddTab}
          />
        )}

        <SectionHeader
          title={t('Automatically Scroll Down')}
          actions={this.renderToggle()}
          tooltip={t('Automatically scroll down to the page bottom')}
          borderTop={false}
        />
        <div className="button-container">
          <RadioButtonGroup
            className="radio-wrapper"
            name="timingButton"
            defaultSelected={scrollDownType}
            onChange={(e, val) => this.onChangeAutoScrollType(val)}
          >
            <RadioButton
              id="continuous"
              value="continuous"
              disabled={!this.isScrollDownEnabled()}
            />
            <RadioButton
              id="page_bottom"
              value="page_bottom"
              disabled={!this.isScrollDownEnabled()}
            />
            <RadioButton
              id="page_top"
              value="page_top"
              disabled={!this.isScrollDownEnabled()}
            />
          </RadioButtonGroup>
          <div className="radio-option-wrapper">
            <p className="text-wrapper">
              <Trans>Continuous autoscroll (no pause)</Trans>
            </p>
            <p className="text-wrapper">
              <Trans>Reach page bottom and pause</Trans>
            </p>
            <p className="text-wrapper">
              <Trans>Take back to page top and pause</Trans>
            </p>
          </div>
        </div>
        <FlatButton
          label={t('Save Changes')}
          disabled={!canUpdate}
          onClick={() => this.handleSave()}
          primary
        />
        <PageUpdateModal
          handleClose={this.handleClose}
          handleSave={this.handleSubmit}
          open={open}
        />
      </Container>
    );
  }
}

export default withTranslation()(PageSettings);
