import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import debounce from 'lodash/debounce';
import { withTranslation } from 'react-i18next';

import TextField from '../../../../../../../app/components/textField';
import DraggableListWrapper from '../../../../../components/draggableListWrapper';
import ComponentItem from '../../../../../components/componentItem';
import CreateField from '../../../../../components/createField';

import { Tabs, Tab } from '../../../../../components/tabs';

class GoogleMaps extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onAddItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    rearrangeItems: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: {},
  };

  getItemHref = key => {
    const { match, location } = this.props;

    return {
      pathname: `/${match.params.appname}/editor/build/item`,
      search: `${location.search}&item=${key}`,
    };
  };

  handleMove = (el, target, source, sibling) => {
    if (sibling) {
      return this.props.rearrangeItems(el.id, sibling.id);
    }

    return this.props.rearrangeItems(el.id, null);
  };

  handleNavigate = key => () => {
    const href = this.getItemHref(key);

    return this.props.history.push(href);
  };

  renderItems = () => {
    const { data } = this.props;

    const items = _get(data, 'markers');
    const order = _get(data, 'markers.order');
    if (!items || !order) return [];

    return order.map(key => {
      const title = _get(items, `chunks.${key}.title`);

      return (
        <ComponentItem
          id={key}
          key={key}
          label={title}
          onClick={this.handleNavigate(key)}
          onDelete={() => this.props.onRemoveItem(key)}
        />
      );
    });
  };

  render() {
    const handleInputChange = debounce(
      value => this.props.onChange('apiKey', value),
      500,
    );
    const { t } = this.props;

    return (
      <Tabs>
        <Tab label={t('content')}>
          <TextField
            id="apiKey"
            name="apiKey"
            placeholder={t('Enter a Google API Key')}
            hintText={t(
              'You can find your Google API Key in your google dashboard',
            )}
            value={this.props.data.apiKey}
            onChange={handleInputChange}
          />
          <CreateField
            id="google-maps-label"
            hintText={t('Create Google Maps Marker')}
            tooltip={t('Create New Marker')}
            onSubmit={this.props.onAddItem}
          />
          {this.props.data && (
            <DraggableListWrapper
              items={this.renderItems()}
              onPositionChange={this.handleMove}
            />
          )}
        </Tab>
        <Tab
          label={t('design')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
        <Tab
          label={t('layout')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    );
  }
}

export default withTranslation()(GoogleMaps);
