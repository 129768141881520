export const OPEN_VOTE_DETAIL = 'OPEN_VOTE_DETAIL';
export const CLOSE_VOTE_DETAIL_DIALOG = 'CLOSE_VOTE_DETAIL_DIALOG';
export const PREVIOUS_VOTE_RESPONSE = 'PREVIOUS_VOTE_RESPONSE';
export const NEXT_VOTE_RESPONSE = 'NEXT_VOTE_RESPONSE';
export const OPEN_FORM_DETAIL = 'OPEN_FORM_DETAIL';
export const CLOSE_FORM_DETAIL_DIALOG = 'CLOSE_FORM_DETAIL_DIALOG';

export const openVoteDetailDialog = key => (dispatch, getState) => {
  const vote = getState().analytics.vote.data.chunks[key];

  return dispatch({
    type: OPEN_VOTE_DETAIL,
    key,
    vote,
  });
};

export const closeVoteDetailDialog = () => ({
  type: CLOSE_VOTE_DETAIL_DIALOG,
});

export const openFormDetailDialog = key => (dispatch, getState) => {
  const vote = getState().analytics.vote.data.chunks[key];

  return dispatch({
    type: OPEN_FORM_DETAIL,
    key,
    vote,
  });
};

export const closeFormDetailDialog = () => ({
  type: CLOSE_FORM_DETAIL_DIALOG,
});

export const previousResponse = (index, votes, items) => ({
  type: PREVIOUS_VOTE_RESPONSE,
  index,
  votes,
  items,
});

export const nextResponse = (index, votes, items) => ({
  type: NEXT_VOTE_RESPONSE,
  index,
  votes,
  items,
});
