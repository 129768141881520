import Chip from 'material-ui/Chip';
import React from 'react';
/* eslint-disable no-plusplus */
export const downloadCSV = (csvData, name = '') => {
  const filename = `${name}-export.csv`;
  const csv = csvData;
  if (csv == null) return;

  const data = new Blob(['\ufeff', csv], { type: 'text/csv' });

  const download = document.createElement('a');

  download.download = filename;
  download.href = window.URL.createObjectURL(data);
  document.body.appendChild(download);
  download.click();
};

export const downloadImage = (imgData, name = '', type = 'png') => {
  const filename = `${name}.${type}`;
  if (imgData == null) return;

  const download = document.createElement('a');

  download.download = filename;
  download.href = imgData;
  document.body.appendChild(download);
  download.click();
};

const createTable = (data, tableID, clearPrevious = true, title = '') => {
  //Create a HTML Table element.
  const table = document.createElement('TABLE');
  table.border = '1';
  table.id = tableID;

  //Get the count of columns.
  const columnCount = data[0].length;

  //Add the header row.
  let row = table.insertRow(-1);
  for (let i = 0; i < columnCount; i++) {
    const headerCell = document.createElement('TH');
    headerCell.innerHTML = data[0][i];
    row.appendChild(headerCell);
  }

  //Add the data rows.
  for (let i = 1; i < data.length; i++) {
    row = table.insertRow(-1);
    for (let j = 0; j < columnCount; j++) {
      const cell = row.insertCell(-1);
      cell.innerHTML = data[i][j];
    }
  }

  const dvTable = document.getElementById('dvTable');
  if (clearPrevious) {
    dvTable.innerHTML = '';
  }
  if (title) {
    dvTable.innerHTML = `${dvTable.innerHTML}<br/><b>${title}</b>`;
  }
  dvTable.appendChild(table);
};

export const downloadPrintPDF = (dataList, titlesList, contentId) => {
  dataList.forEach((data, index) => {
    const dataTable = [data[0].columns, ...data[0].data];
    createTable(dataTable, `table${index}`, !index, titlesList[index]);
  });
  const content = document.getElementById(contentId);
  const pri = document.getElementById('ifmcontentstoprint').contentWindow;
  pri.document.open();
  pri.document.write(content.innerHTML);
  pri.document.close();
  pri.focus();
  pri.print();
};

export const downloadFile = value => {
  if (value) {
    const filename = value.substr(value.lastIndexOf('/') + 1);
    return (
      <Chip
        style={{ margin: 6 }}
        labelStyle={{ maxWidth: '300px', overflow: 'hidden' }}
        title={filename}
      >
        <a href={value} target="_blank" rel="noopener noreferrer">
          {filename}
        </a>
      </Chip>
    );
  }
  return <></>;
};
