import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { Trans, useTranslation } from 'react-i18next';
import { RaisedButton } from '../../../../app/components/buttons';
import { CircularProgress } from '../../../../app/components/progress';
import ErrorMessage from '../../../../app/components/errorMessage';
import './styles.css';
import visa from './visa.svg';
import mastercard from './mastercard.svg';
import amex from './amex.svg';
import themeStyles from '../../../../_export.scss';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';

const styles = {
  buttonRoot: {
    margin: '12px',
    width: '200px',
  },
  primaryLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.primaryTextColor,
  },
};

const getDiscount = (cost, discountPercent) =>
  (parseFloat(cost) * parseFloat(discountPercent)) / 100;
const price = (cost, intl, currency, promoCode, lastRedeemedCoupon) => {
  // console.log(
  //   'details-->',
  //   cost,
  //   intl,
  //   currency,
  //   promoCode,
  //   lastRedeemedCoupon,
  // );
  if (promoCode && promoCode.isValid) {
    if (!lastRedeemedCoupon || promoCode.coupon.id !== lastRedeemedCoupon.id) {
      const discountedCost =
        cost - getDiscount(cost, promoCode.coupon.percent_off);
      return (
        <span>
          <del>
            <FormattedNumber
              maximumFractionDigits={2}
              format={intl.currency}
              currency={currency}
              value={cost || 0}
            />
          </del>{' '}
          <strong>
            <FormattedNumber
              maximumFractionDigits={2}
              format={intl.currency}
              currency={currency}
              value={discountedCost || 0}
            />
          </strong>
        </span>
      );
    }
    return (
      <FormattedNumber
        maximumFractionDigits={2}
        format={intl.currency}
        currency={currency}
        value={cost || 0}
      />
    );
  }

  return (
    <strong>
      <FormattedNumber
        maximumFractionDigits={2}
        format={intl.currency}
        currency={currency || 'gbp'}
        value={cost || 0}
      />
    </strong>
  );
};

const billing = (
  cost,
  intl,
  isCurrentPlanDIY,
  currency,
  promoCode,
  typeOfPayment,
  billingPeriod,
  toggleBillingPeriod,
  t,
  lastRedeemedCoupon,
) => {
  if (isCurrentPlanDIY) {
    return (
      <React.Fragment>
        {!promoCode && (
          <h3 style={{ width: '50%', margin: '0 auto' }}>
            <Trans>
              A reimbursement of the payment that you have already made under
              your existing plan, prorated to the remaining days of this month,
              will be issued to you in the form of a discount (if applicable)
            </Trans>
          </h3>
        )}
        <h3>
          {t('Billing')}{' '}
          <strong>
            {price(cost, intl, currency, promoCode, lastRedeemedCoupon)}
          </strong>
        </h3>
      </React.Fragment>
    );
  }

  if (typeOfPayment === 'specialAddons') {
    const finalCost = billingPeriod === 'monthly' ? cost : cost * 12;
    return (
      <div>
        <div className="flex-row justify-center">
          <label className="preview-toggle">
            <span>
              <Trans>Monthly</Trans>
            </span>
            <div className="switch">
              <input
                className="toggle"
                type="checkbox"
                onClick={toggleBillingPeriod}
                value="billingPeriod"
                checked={billingPeriod === 'yearly'}
              />
              <span className="slider round" />
            </div>
            <span>
              <Trans>Yearly</Trans>
            </span>
          </label>
        </div>
        <h3>
          {t('Billing')}{' '}
          <strong>{price(finalCost, intl, currency, promoCode)}</strong>
        </h3>
      </div>
    );
  }
  return (
    <h3>
      {t('Billing')} <strong>{price(cost, intl, currency, promoCode)}</strong>
    </h3>
  );
};

const billingWithProration = (
  cost,
  intl,
  prorating,
  period,
  currency,
  promoCode = null,
  t,
  lastRedeemedCoupon,
) => {
  let { adjustment } = prorating;

  if (adjustment < 0) {
    adjustment = 0;
  }

  if (prorating.isFetching) {
    return (
      <div style={{ padding: '13px' }}>
        <CircularProgress thickness={5} />
      </div>
    );
  }

  if (prorating.hasError) {
    return (
      <h3 style={{ color: 'red' }}>
        <Trans>Please try again</Trans>
      </h3>
    );
  }

  if (promoCode) {
    return (
      <h3>
        <Trans>Billing</Trans>
        <strong>
          {price(cost, intl, currency, promoCode, lastRedeemedCoupon)}
        </strong>
      </h3>
    );
  }

  return (
    <h3>
      {t('You have')} <strong>{price(adjustment, intl, currency)}</strong>{' '}
      {t("to pay just now, then it's")}{' '}
      <strong>{price(cost, intl, currency, promoCode)}</strong>{' '}
      {`${t('per')} ${period}`}
    </h3>
  );
};

const shouldDisable = (
  pending,
  showProrating,
  prorating,
  number,
  cvc,
  expiry,
  useDefaultCard,
) => {
  if (pending) {
    return true;
  } else if (showProrating) {
    if (prorating.hasError || prorating.isFetching) {
      return true;
    }
  } else if (!useDefaultCard && number && cvc && expiry) {
    if (
      !number.value ||
      !cvc.value ||
      !expiry.value ||
      number.error ||
      cvc.error ||
      expiry.error
    ) {
      return true;
    }
  }
  return false;
};

const Payment = props => {
  const {
    title,
    subtitle,
    cost,
    children,
    submitLabel,
    onSubmit,
    pending,
    error,
    intl,
    showProrating,
    prorating,
    period,
    number,
    cvc,
    expiry,
    isCurrentPlanDIY,
    useDefaultCard,
    currency,
    stripe,
    stripeAuthSuccessCallback,
    stripeAuthErrorCallback,
    requiresAuthentication,
    paymentIntentClientSecret,
    getPromoCodeDetails,
    productId,
    payment,
    enablePromoBox,
    lastRedeemedCoupon,
    typeOfPayment,
  } = props;
  const { t } = useTranslation();
  const [promoCode, setPromoCode] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [billingPeriod, setBillingPeriod] = useState('monthly');

  const toggleBillingPeriod = () => {
    const value = billingPeriod === 'monthly' ? 'yearly' : 'monthly';
    setBillingPeriod(value);
  };

  const handleSubmit = () => {
    if (typeOfPayment === 'specialAddons') {
      onSubmit(billingPeriod);
    } else {
      onSubmit();
    }
  };

  useEffect(() => {
    if (requiresAuthentication && paymentIntentClientSecret) {
      triggerStripeAuth();
    }
  }, [requiresAuthentication]);

  const triggerStripeAuth = async () => {
    if (stripe) {
      const result = await stripe.confirmCardPayment(paymentIntentClientSecret);
      if (result.error) {
        stripeAuthErrorCallback();
      }
      if (!result.error) {
        stripeAuthSuccessCallback();
      }
    }
  };

  const handlePromoCodeChange = e => setPromoCode(e.target.value.trim());
  return !requiresAuthentication && !paymentIntentClientSecret ? (
    <div className="payment">
      {title && <h1>{title}</h1>}
      {subtitle && (
        <small className="subtitle">
          <strong>{subtitle}</strong>
        </small>
      )}
      <div className="price">
        {showProrating
          ? billingWithProration(
              cost,
              intl,
              prorating,
              period,
              currency,
              payment.promoCode,
              t,
              lastRedeemedCoupon,
            )
          : billing(
              cost,
              intl,
              isCurrentPlanDIY,
              currency,
              payment.promoCode,
              typeOfPayment,
              billingPeriod,
              toggleBillingPeriod,
              t,
              lastRedeemedCoupon,
            )}
        <img src={visa} alt="Visa" height="30" />
        <img src={mastercard} alt="Mastercard" height="30" />
        <img src={amex} alt="American Express" height="30" />
      </div>

      {children}

      {enablePromoBox && (
        <div className="promo-box">
          <div className="promo-box-input">
            <TextField
              size="small"
              margin="none"
              color="primary"
              placeholder={t('Enter promo code')}
              value={promoCode}
              onChange={handlePromoCodeChange}
            />
            <Button
              variant="contained"
              className="apply-promo-btn"
              disabled={!promoCode}
              onClick={() => {
                getPromoCodeDetails(promoCode, productId, setIsUpdated);
              }}
              size="small"
            >
              <Trans>Apply Code</Trans>
            </Button>
          </div>

          {payment.promoCode ? (
            payment.promoCode.isValid ? (
              !lastRedeemedCoupon ||
              payment.promoCode.coupon.id !== lastRedeemedCoupon.id ? (
                <div className="promo-box-msg">
                  <CheckCircleOutlineIcon color="success" />
                  <div className="promo_success_box">
                    <span>
                      <Trans>Promo code applied</Trans>.
                    </span>
                    <span>
                      <Trans>You save</Trans>{' '}
                      <FormattedNumber
                        maximumFractionDigits={2}
                        format={intl.currency}
                        currency={currency}
                        value={
                          getDiscount(
                            cost,
                            payment.promoCode.coupon.percent_off,
                          ) || 0
                        }
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div className="promo-box-msg">
                  <CancelIcon color="error" />
                  <div className="promo_error_box">
                    <span>
                      <Trans>You have already redeemed this code.</Trans>
                    </span>
                  </div>
                </div>
              )
            ) : (
              <div className="promo-box-msg">
                <CancelIcon color="error" />
                <div className="promo_error_box">
                  <span>
                    <Trans>Invalid code. Check your code and try again</Trans>
                  </span>
                </div>
              </div>
            )
          ) : (
            <></>
          )}
        </div>
      )}

      {!pending && (
        <RaisedButton
          label={submitLabel}
          onClick={handleSubmit}
          disabled={shouldDisable(
            pending,
            showProrating,
            prorating,
            number,
            cvc,
            expiry,
            useDefaultCard,
          )}
          backgroundColor={themeStyles.primaryColor}
          disabledBackgroundColor={themeStyles.primaryColorLight}
          style={styles.buttonRoot}
          labelStyle={styles.primaryLabelStyle}
        />
      )}
      {pending && <CircularProgress />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  ) : (
    <p style={{ textAlign: 'center' }}>
      <Trans>
        You are being redirected to your banking providers payment page to
        complete the payment. Do not reload this page.
      </Trans>
    </p>
  );
};

Payment.propTypes = {
  // passed as props to parent container
  title: PropTypes.node,
  subtitle: PropTypes.node,
  cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  intl: PropTypes.shape({
    currency: PropTypes.string.isRequired,
  }).isRequired,
  submitLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // passed from payment state via connect by parent container
  children: PropTypes.node.isRequired,
  pending: PropTypes.bool.isRequired,
  error: PropTypes.string,
  showProrating: PropTypes.bool,
  prorating: PropTypes.object,
  period: PropTypes.string,
  number: PropTypes.object,
  cvc: PropTypes.object,
  expiry: PropTypes.object,
  stripe: PropTypes.object,
  stripeAuthSuccessCallback: PropTypes.func,
  stripeAuthErrorCallback: PropTypes.func,
  paymentIntentClientSecret: PropTypes.string,
  requiresAuthentication: PropTypes.bool,
};

Payment.defaultProps = {
  title: null,
  subtitle: null,
  error: null,
  showProrating: false,
  prorating: {},
  period: null,
  number: {},
  cvc: {},
  expiry: {},
  enablePromoBox: false,
};

export default Payment;
