import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CTAButton } from '../../../../components/buttons';

import Standard from './Standard';

const ThankYou = ({ content, onRequestClose, history }) => {
  const { t } = useTranslation();

  const closeAndRedirect = () => {
    onRequestClose();
    history.push(history.location.pathname.replace('dashboard', 'build'));
  };

  return (
    <Standard title="" content={content} onRequestClose={closeAndRedirect}>
      <CTAButton
        outlineRGB="194, 206, 50"
        borderColor="#aca400"
        onClick={closeAndRedirect}
        label={t("Let's get building!")}
      />
    </Standard>
  );
};

ThankYou.propTypes = {
  content: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

ThankYou.defaultProps = {
  content: '',
};

export default withRouter(ThankYou);
