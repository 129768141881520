import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Container from '../../../../../app/components/container';
import SectionHeader from '../../../components/sectionHeader';

import ImageUpload from '../../../internals/imageUpload';

const cropSettingsIcon = [
  {
    dimensions: [
      '512x512',
      '192x192',
      '180x180',
      '167x167',
      '152x152',
      '144x144',
      '120x120',
      '96x96',
      '72x72',
      '48x48',
    ],
  },
];

class AppIcon extends Component {
  static propTypes = {
    icon: PropTypes.string,
    match: PropTypes.object.isRequired,
    logo: PropTypes.string,
    account: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    icon: null,
    logo: null,
  };

  componentWillMount() {
    this.props.onAddIconListener();
  }

  componentWillUnmount() {
    this.props.onRemoveIconListener();
  }

  renderLogoUpload = () => {
    const { logo, t } = this.props;
    const fbLogoLocation = `apps/${this.props.match.params.appname}/draft/meta_data/logo`;

    return (
      <ImageUpload
        targetId="app_logo"
        title={t('App Settings > Logo')}
        src={logo}
        firebaseUrl={fbLogoLocation}
        fileType="png"
        height="221px"
        width="248px"
      />
    );
  };

  render() {
    const { integration } = this.props.account;
    const { t } = this.props;
    return (
      <Container>
        {!integration && (
          <SectionHeader
            title={t('App Logo')}
            tooltip={t("Used as the app's logo in the top bar")}
          />
        )}
        {!integration && this.renderLogoUpload()}
        <SectionHeader
          title={t('App Icon')}
          tooltip={t(
            "Used as the app's screen icon - minimum size 512px x 512px",
          )}
        />
        <ImageUpload
          label={t("Your App's Icon")}
          targetId="app_icon_interface"
          caption={t(
            'This icon will be displayed on a phones homescreen when the app is installed.',
          )}
          title={t('App Settings > Icons')}
          src={this.props.icon}
          firebaseUrl={`icons/${this.props.match.params.appname}/`}
          config={cropSettingsIcon}
          fileType="png"
          height="264px"
          width="276px"
          imageType={'icon'}
          noDelete
        />
      </Container>
    );
  }
}

export default withTranslation()(AppIcon);
