import { connect } from 'react-redux';

import HtmlEditor from './htmlEditor';
import { htmlChange } from './actions';

const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value, type) => {
    dispatch(
      htmlChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
        type,
      ),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HtmlEditor);
