import React, { useState } from 'react';
import IconButton from 'material-ui/IconButton';
import Menu, { MenuItem } from 'material-ui/Menu';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Popover from 'material-ui/Popover';
import { Cog } from '../../../components/icons';

const styles = {
  menuStyle: {
    padding: '2px 0',
  },
  menuItem: {
    minHeight: 36,
    fontSize: 13,
  },
};

const ThumbsContainer = ({
  sources,
  deleteImage,
  mainImage,
  onChangeMainImage,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [srcLocation, setSrcLocation] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSrcLocation(null);
    setImageSrc(null);
  };

  return (
    <div className="thumbsContainer">
      {sources &&
        Object.keys(sources).map(key => {
          const item = sources[key];
          const className = key === mainImage ? 'thumb thumbSelected' : 'thumb';
          return (
            <div className={className} key={item.image}>
              <div className="thumbButton">
                <IconButton
                  color="secondary"
                  onClick={event => {
                    setSrcLocation(key);
                    setImageSrc(item.image);
                    handleClick(event);
                  }}
                >
                  <Cog />
                </IconButton>
              </div>
              <div className="thumbInner preview">
                <LazyLoadImage
                  alt=""
                  height={100}
                  src={item.image}
                  width={100}
                />
              </div>
            </div>
          );
        })}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onRequestClose={handleClose}
      >
        <Menu menuItemStyle={styles.menuItem}>
          <MenuItem
            onClick={() => {
              handleClose();
              const isMainImage = mainImage === srcLocation ? true : false;
              deleteImage(`${srcLocation}/image`, imageSrc, isMainImage);
            }}
          >
            Delete
          </MenuItem>
          <MenuItem
            onClick={() => {
              onChangeMainImage(srcLocation);
              handleClose();
            }}
            disabled={mainImage === srcLocation}
          >
            Set Main Image
          </MenuItem>
        </Menu>
      </Popover>
    </div>
  );
};

export default ThumbsContainer;
