import React from 'react';
import DriversTable from '../../components/DriversTable/DriversTable';

const Drivers = props => {
  const { appname, isAppAdmin, history } = props;
  return (
    <div>
      <DriversTable
        appname={appname}
        isAppAdmin={isAppAdmin}
        history={history}
      />
    </div>
  );
};

export default Drivers;
