const lazyDataReducer = (state = {}, action) => {
  switch (action.type) {
    case 'RECEIVE_LAZY_DATA':
      return {
        ...state,
        [action.key]: action.data,
      };

    case 'RECEIVE_ITEM_DATA':
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          chunks: {
            ...(state[action.key] && state[action.key].chunks),
            [action.itemKey]: action.data,
          },
          key: action.key,
        },
      };

    case 'HYDRATE_SCHEDULE_FILTERS':
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          filters: action.data,
        },
      };
    case 'RECEIVE_ALL_LAZY_DATA':
      return {
        ...action.data,
      };

    default:
      return state;
  }
};

export default lazyDataReducer;
