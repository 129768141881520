import React from 'react';

import './styles.css';

const TabView = () => (
  <svg
    className="icon tabview"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <g id="Layer_1" data-name="Layer 1">
      <path d="M31,27.91H29.39a1,1,0,0,1,0-2H30V2H6.09v.61a1,1,0,0,1-2,0V1a1,1,0,0,1,1-1H31a1,1,0,0,1,1,1V26.91A1,1,0,0,1,31,27.91Z" />
      <path d="M25.55,32H1a1,1,0,0,1-1-1V6.46a1,1,0,0,1,1-1H25.55a1,1,0,0,1,1,1V31A1,1,0,0,1,25.55,32ZM2,30H24.55V7.46H2Z" />
      <circle style={{ fill: '#5eaa61' }} cx="13" cy="18.73" r="10" />
    </g>
  </svg>
);

export default TabView;
