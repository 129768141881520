import styled from 'styled-components';

export const container = styled.div`
  width: 100%;
  height:150px;
  display: flex;
  border: 1px solid lightgray;
  padding: 1em 0;
`;

export const imageContainer = styled.div`
  width: 40%;
  height: 100%;
  margin: 0 0.5em;
`;

export const image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
`;

export const details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const primaryDetails = styled.span`
  font-size: 1.2em;
  font-weight: bold;
  margin:0.5em;
`;

export const secondaryDetails = styled.span`
  font-size: 0.8em;
  color: #959393;
  margin:0.5em;
`;
