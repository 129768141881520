const initialState = null;

const customDomain = (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVE_CUSTOM_DOMAIN':
      return {
        ...state,
        ...action.data,
      };

    // To reset customDomain data once user sign off
    case 'DOMAIN_REMOVED':
    case 'LOGOUT_SUCCESS':
    case 'CHANGING_APP':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default customDomain;
