import { connect } from 'react-redux';

import Transactions from './Transactions';
import {
  loadTransactions,
  selectTransaction,
  selectAllTransactions,
  deselectAllTransactions,
  filterData,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.auth.transactions,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadTransactions: () =>
    dispatch(loadTransactions(ownProps.match.params.appname)),
  onFilterTransactionData: filter => dispatch(filterData(filter)),
  onSelect: key => dispatch(selectTransaction(key)),
  onSelectAllTransactions: () => dispatch(selectAllTransactions()),
  onDeselectAllTransactions: () => dispatch(deselectAllTransactions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
