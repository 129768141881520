import { combineReducers } from 'redux';

import imageUpload from './imageUpload/reducer';
import appDomainInput from './appDomainInput/reducer';
import domainChangeModal from './domainChangeModal/reducer';
import multipleImageUpload from './multipleImageUpload/reducer';

export default combineReducers({
  imageUpload,
  appDomainInput,
  domainChangeModal,
  multipleImageUpload,
});
