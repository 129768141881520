import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AccountModal from './AccountModal';
import { toggleAccountModal } from './actions';
import { toggleUserMenu } from '../../modules/editor/internals/accountsDropdown/actions';
import { setActiveAccountTab } from '../../modules/editor/modules/build/modules/buildMenu/actions';

const mapStateToProps = state => ({
  open: state.account.modal,
  user: state.account.auth.user,
  auth: state.account.auth,
  profile: state.account.profile,
  staffProfile: state.account.staffProfile,
  integration: state.account.organisation.integration,
  role: state.account.profile.role,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
  isAppSumoUser:
    (state.account.organisation.appsumo_id &&
      state.account.organisation.appsumo_id.length > 0) ||
    false,
  isSubAdminWithMulipleApps:
    (state.account.auth.profile.admin_appnames &&
      Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
    false,
});

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(toggleAccountModal()),
  onAccountSettingsTouchTap: () => {
    dispatch(toggleUserMenu(false));
    dispatch(toggleAccountModal());
  },
  setActiveAccountTab: value => dispatch(setActiveAccountTab(value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountModal),
);
