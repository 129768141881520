import { connect } from 'react-redux';
import queryString from 'query-string';

import { components } from '../../../../../plans';
import CustomDomain from './CustomDomain';
import {
  updateCustomDomainData,
  deleteCustomDomain,
  deletePlatformCustomDomain,
  checkIsDomainSetupDone,
} from './actions';
import {
  closeCreateComponentUI,
  createComponent,
  componentTypeChange,
} from '../../build/modules//create/createComponent/actions';
import { openSnackbar } from '../../../../../app/modules/snackbar/actions';

import {
  addToDiyComponents,
  loadDiyComponents,
} from '../../../../../app/actions';

const mapStateToProps = (state, ownProps) => {
  const isInTrial = !state.account.organisation.trial_expired || false;
  const subscription =
    state.account.subscriptions.items[ownProps.match.params.appname] || {};
  const plan = (subscription && subscription.plan) || '';
  const customDomainAddon =
    (state.app.addons &&
      state.app.addons.diyComponents &&
      state.app.addons.diyComponents.CustomDomain &&
      state.app.addons.diyComponents.CustomDomain.name) ||
    '';
  const customDomainPlatformAddon =
    (state.app.addons &&
      state.app.addons.diyComponents &&
      state.app.addons.diyComponents.CustomDomainPlatform &&
      state.app.addons.diyComponents.CustomDomainPlatform.name) ||
    '';
  const showAddonCard = plan.indexOf('diy') !== -1 && customDomainAddon === '';
  const showAddonCardPlatform =
    plan.indexOf('diy') !== -1 && customDomainPlatformAddon === '';

  const freeTrialPlan = state.account.organisation.trial_expired
    ? null
    : 'accelerate';

  const isAppAdmin =
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false;

  return {
    ...ownProps,
    ...state.editor.build.create.createComponent,
    appname: ownProps.match.params.appname,
    intl: state.account.intl,
    subscription: freeTrialPlan || state.app.appContext.subscription || 'start',
    accountId: state.account.auth.profile.account,
    diyComponents: state.app.addons.diyComponents || {},
    isProcessing: state.app.addons.isProcessing || false,
    subscriptionItem: state.account.subscriptions,
    diyPopupOpen:
      state.editor.build.create.createComponent.diyPopupOpen || false,
    ...state.editor.shared.appName[ownProps.match.params.appname],
    customDomain: state.app.customDomain,
    match: ownProps.match,
    history: ownProps.history,
    showAddonCard,
    showAddonCardPlatform,
    hasAccess:
      isInTrial ||
      plan.indexOf('deluxe') !== -1 ||
      plan.indexOf('accelerate') !== -1 ||
      plan.indexOf('expert') !== -1 ||
      plan.indexOf('expert_now') !== -1 ||
      plan.indexOf('expert_new') !== -1 ||
      plan.includes('beezer_tier') ||
      (plan.indexOf('diy') !== -1 && customDomainAddon),
    isPublished: state.editor.data.isPublished[ownProps.match.params.appname],
    hasAccessPlatform:
      isInTrial ||
      plan.indexOf('deluxe') !== -1 ||
      plan.indexOf('accelerate') !== -1 ||
      plan.indexOf('expert') !== -1 ||
      plan.indexOf('expert_now') !== -1 ||
      plan.indexOf('expert_new') !== -1 ||
      plan.includes('beezer_tier') ||
      (plan.indexOf('diy') !== -1 && customDomainPlatformAddon),
    isPublished: state.editor.data.isPublished[ownProps.match.params.appname],
    isAppAdmin,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { appname } = ownProps.match.params;
  const query = queryString.parse(ownProps.location.search);
  const router = {
    history: ownProps.history,
    location: ownProps.location,
    match: ownProps.match,
  };

  return {
    updateCustomDomainData: customDomain => {
      dispatch(updateCustomDomainData(customDomain));
    },
    onLoad: () => {
      dispatch(loadDiyComponents(appname));
    },
    onSubmit: componentType =>
      dispatch(createComponent(appname, query, componentType, router)),
    onCancel: () => dispatch(closeCreateComponentUI()),
    onTypeChange: value => dispatch(componentTypeChange(value)),
    onConfirm: (componentType, currency) =>
      dispatch(
        addToDiyComponents(
          componentType,
          components[componentType],
          appname,
          currency,
        ),
      ).then(() => {
        dispatch({ type: 'TOGGLE_DIY_POPUP_STATE', value: false });
      }),
    toggleDiyPopup: value => {
      dispatch({ type: 'TOGGLE_DIY_POPUP_STATE', value });
    },
    deleteCustomDomain: user =>
      dispatch(deleteCustomDomain(ownProps.match.params.appname, user)),
    deletePlatformCustomDomain: user =>
      dispatch(deletePlatformCustomDomain(ownProps.match.params.appname, user)),
    copyTextSuccess: message => dispatch(openSnackbar(message)),
    checkIsDomainSetupDone: (user, domainType) =>
      dispatch(
        checkIsDomainSetupDone(ownProps.match.params.appname, user, domainType),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomDomain);
