import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Doughnut, Pie } from 'react-chartjs-2';
import { Trans, withTranslation } from 'react-i18next';

import ModalDetails from '../../../components/modalDetails';
import ModalDetailsCharts from '../../../components/modalDetailsCharts';
import {
  formatDate,
  downloadCSV,
  downloadImage,
  capitaliseString,
} from '../../../../../utils';

import './styles.css';

class HistoryDetails extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    data: PropTypes.object,
    responseKey: PropTypes.string,
    onRequestClose: PropTypes.func.isRequired,
    totalResponses: PropTypes.number.isRequired,
    currentResponse: PropTypes.number.isRequired,
    onPreviousResponse: PropTypes.func.isRequired,
    onNextResponse: PropTypes.func.isRequired,
    historys: PropTypes.object.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    groups: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: null,
    responseKey: null,
    onReply: null,
    onFavorite: null,
    onDownload: null,
    onDeleteSelected: null,
    onForward: null,
  };

  state = {
    confirmDeleteDialogOpen: false,
    chartIndex: 0,
    showCharts: false,
  };

  getHistoryFields = data => {
    const { t } = this.props;
    return Object.keys(data).map(
      key =>
        key !== 'messageId' &&
        key !== 'cancelled' &&
        key !== 'date' && (
          <div className="field" key={key}>
            {key !== 'isScheduled' && (
              <span className="label">{capitaliseString(key)}</span>
            )}
            {key === 'pushImage' && data[key] !== '' && (
              <img src={data[key]} className="pushImageWrap" />
            )}
            {key !== 'isScheduled' && key !== 'pushImage' && (
              <span className="value">
                {key === 'visible'
                  ? this.formatBool(data[key])
                  : typeof data[key] !== 'object'
                  ? data[key]
                  : data[key].ref}
              </span>
            )}
            {key === 'isScheduled' && (
              <span className="label">
                <Trans>Scheduled</Trans>
              </span>
            )}
            {key === 'isScheduled' && (
              <span className="value">
                {data[key].cancelled
                  ? t('Cancelled')
                  : moment(data[key].date).isAfter()
                  ? t('Scheduled')
                  : t('Already sent')}
              </span>
            )}
            {key === 'filteredList' && (
              <div className="filteredList">
                {data[key].map(_data => (
                  <h5>{_data}</h5>
                ))}
              </div>
            )}
            {key === 'groupsFilteredList' && (
              <div className="itemList">
                {data[key].map(
                  value =>
                    this.props.groups &&
                    this.props.groups[value] && (
                      <h5> {this.props.groups[value].name}</h5>
                    ),
                )}
              </div>
            )}
            {key === 'locationFilteredList' && (
              <div className="itemList">
                {this.props.countries && this.props.countries.length > 0 && (
                  <h5>
                    {
                      this.props.countries.find(
                        country => country.value === data[key][0],
                      ).name
                    }
                  </h5>
                )}
              </div>
            )}
            {key === 'groupsFiltered' && (
              <div className="itemList">
                <h5> {data[key] ? t('Yes') : t('No')}</h5>
              </div>
            )}
            {key === 'locationFiltered' && (
              <div className="itemList">
                <h5> {data[key] ? t('Yes') : t('No')}</h5>
              </div>
            )}
          </div>
        ),
    );
  };

  getCSV = data => {
    const { t } = this.props;
    let cols = [t('Date'), t('Title'), t('Message'), t('Sent to')];

    let values = [
      `${formatDate(data.date)}`,
      `${data.title}`,
      `${data.message}`,
      `${data.filteredList ? data.filteredList.join('; ') : t('Everyone')}`,
    ];
    if (data.pushImage && data.pushImage != '') {
      cols.push('image');
      values.push(`"${data.pushImage}"`);
    } else if (data.link && data.link.type === 'video') {
      cols.push('video_link');
      values.push(`"${data.link.ref}"`);
    }
    let body = `"${cols.join('","')}"\n`;
    body += `${values.join('","')}\n`;

    return body;
  };

  getCharts = notificationData => {
    const { t } = this.props;
    return [
      <div className="chart-container">
        <div className="chart-title">
          <Trans>Notification Delivery Stats</Trans>
        </div>
        <Doughnut
          data={{
            labels: [t('Successful'), t('Converted'), t('Failed')],
            datasets: [
              {
                data: [
                  notificationData.successful,
                  notificationData.converted,
                  notificationData.failed,
                ],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
              },
            ],
          }}
        />
      </div>,
      <div className="chart-container">
        <div className="chart-title">
          <Trans>Chrome Delivery Stats</Trans>
        </div>
        <Pie
          data={{
            labels: [
              t('Android Successful'),
              t('Android Errored'),
              t('Android Failed'),
            ],
            datasets: [
              {
                data: [
                  notificationData.platform_delivery_stats.chrome_web_push
                    .successful,
                  notificationData.platform_delivery_stats.chrome_web_push
                    .errored,
                  notificationData.platform_delivery_stats.chrome_web_push
                    .failed,
                ],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
              },
            ],
          }}
        />
      </div>,
      <div className="chart-container">
        <div className="chart-title">
          <Trans>Browsers Success</Trans>
        </div>
        <Doughnut
          data={{
            labels: Object.keys(notificationData.platform_delivery_stats),
            datasets: [
              {
                data: Object.values(
                  notificationData.platform_delivery_stats,
                ).map(item => item.successful),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
              },
            ],
          }}
        />
      </div>,
    ];
  };

  getNotificationDetails = data => (
    <div>
      <div className="history-modal-content-title">
        <Trans>Notification Details</Trans>
      </div>
      <div className="date">
        <span className="label">
          <Trans>Date Sent</Trans>
        </span>
        <span className="value">{formatDate(data.date)}</span>
      </div>
      <div className="fields">
        {this.getHistoryFields(data)}
        <div className="field" key="cancelled">
          <span className="label">
            <Trans>Cancelled</Trans>
          </span>
          <span className="value">
            {data.cancelled ? this.formatBool(data.cancelled) : 'No'}
          </span>
        </div>
      </div>
    </div>
  );

  formatBool = bool => {
    const { t } = this.props;
    return bool ? t('Yes') : t('No');
  };

  handleCloseDeleteDialog = () =>
    this.setState({ confirmDeleteDialogOpen: false });

  render() {
    const {
      data,
      totalResponses,
      currentResponse,
      onPreviousResponse,
      onNextResponse,
      historys,
      responseKey,
      onDeleteSelected,
      t,
    } = this.props;
    const notificationData = historys.historyDetails.notificationData || null;
    return data ? (
      <ModalDetails
        {...this.props}
        title={`${t('History')} - ${data && data.title}`}
        onNextItem={() => onNextResponse(currentResponse, historys)}
        onPreviousItem={() => onPreviousResponse(currentResponse, historys)}
        totalItems={totalResponses}
        currentItem={currentResponse}
        navigationText={t('responses')}
        onDownloadCsv={() => downloadCSV(this.getCSV(data), 'history-response')}
        onDownloadPng={() => {
          const canvas = document.getElementsByClassName(
            'chartjs-render-monitor',
          )[0];
          const img = canvas.toDataURL('image/png');
          downloadImage(img);
        }}
        deleteConfirmationMessage={t(
          'Are you sure you want to permanently delete this response?',
        )}
        onDelete={() => onDeleteSelected([responseKey])}
        chartsToggled={
          !notificationData
            ? null
            : () => this.setState({ showCharts: !this.state.showCharts })
        }
        content={
          <div className="history-modal-content">
            {!this.state.showCharts ? (
              this.getNotificationDetails(data)
            ) : (
              <div>
                {notificationData &&
                  notificationData.platform_delivery_stats && (
                    <ModalDetailsCharts
                      maxIndex={this.getCharts(notificationData).length}
                      charts={this.getCharts(notificationData)}
                    />
                  )}
              </div>
            )}
          </div>
        }
      />
    ) : null;
  }
}

export default withTranslation()(HistoryDetails);
