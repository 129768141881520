import _flatten from 'lodash/flatten';
import firebase from '../../../../../../firebase';
import { INTERNAL_APP_PATHS } from '../../../../../../config/constants';

export const editPage = (title, pageType, tabs) => ({
  type: 'EDIT_PAGE',
  title,
  pageType,
  tabs,
});

const reportErr = (page, err) => ({
  type: 'PAGE_UPDATE_ERROR',
  page,
  err,
});

const toPath = title =>
  title
    .toLowerCase()
    .replace(/[!@#$%^&*()_+=[\]{};':"\\|,.<>/?]/g, '')
    .trim()
    .replace(/\s+/g, '-');

export const updatePage = (appname, page, nextState) => (
  dispatch,
  getState,
) => {
  const alwaysData = getState().editor.data.alwaysData;

  const pageRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${page}`);
  const prevState = alwaysData[page];

  const layoutData = getState().editor.data.layoutData[appname] || {
    childRoutes: [],
  };

  // Get all paths except the current page path
  const paths = layoutData.childRoutes
    ? layoutData.childRoutes
        .filter(key => key !== page)
        .map(key => alwaysData[key].path)
    : [];
  paths.push(...INTERNAL_APP_PATHS);

  // make sure new path does not clash with current path
  let path = toPath(nextState.title.value);

  // if path already exists, append a timestamp
  if (paths.indexOf(path) !== -1) {
    path = path.concat('-', Date.now().toString());
  }

  dispatch({
    type: 'UPDATE_PAGE',
    appname,
    page,
    nextState,
  });

  const update = {
    title: nextState.title.value,
    path,
    subtitle: nextState.subtitle.value,
    type: nextState.type,
    hidden: nextState.hidden || false,
    scrollDown: nextState.scrollDown,
  };

  // take action if page type has been changed
  if (prevState.type !== nextState.type) {
    // eslint-disable-next-line defauislt-case
    switch (nextState.type) {
      case 'View':
        update.tabs = null;
        // flatten any tab components into the view components array
        if (prevState.type === 'TabView') {
          update.components = _flatten(
            prevState.tabs.map(tab => tab.components),
          ).filter(component => typeof component === 'string');
        }
        if (prevState.type === 'NestedPage') {
          update.components = prevState.components || null;
          update.parentPageKey = null;
          update.order = null;
        }

        break;
      case 'TabView':
        update.components = null;
        // create tabs & move the view components to the first new tab
        update.tabs = nextState.tabs.map((tab, i) => ({
          title: tab.title.value,
          components: i === 0 ? prevState.components || [] : [],
        }));
        if (prevState.type === 'NestedPage') {
          update.parentPageKey = null;
          update.order = null;
        }
        break;

      case 'NestedPage':
        const parentPageKey = nextState.parentPageKey;
        const parentPagePath = alwaysData[parentPageKey]
          ? alwaysData[parentPageKey].path
          : '';
        update.path = `${parentPagePath}/${path}`;
        update.parentPageKey = nextState.parentPageKey;

        break;
      case 'NestedTabPage':
        update.components = null;
        // create tabs & move the view components to the first new tab
        update.tabs = nextState.tabs.map((tab, i) => ({
          title: tab.title.value,
          components: i === 0 ? prevState.components || [] : [],
        }));

        const parentPage = nextState.parentPageKey;
        const parentPath = alwaysData[parentPage]
          ? alwaysData[parentPage].path
          : '';
        update.path = `${parentPath}/${path}`;
        update.parentPageKey = nextState.parentPageKey;

        break;
    }
  } else if (prevState.type === 'TabView') {
    update.tabs = nextState.tabs.map(tab => ({
      title: tab.title.value,
      components: tab.components || [],
    }));
  } else if (prevState.type === 'NestedPage') {
    const parentPageKey = nextState.parentPageKey;
    const parentPagePath = alwaysData[parentPageKey]
      ? alwaysData[parentPageKey].path
      : '';
    update.path = `${parentPagePath}/${path}`;
    update.parentPageKey = nextState.parentPageKey;
  } else if (prevState.type === 'NestedTabPage') {
    update.tabs = nextState.tabs.map(tab => ({
      title: tab.title.value,
      components: tab.components || [],
    }));
    const parentPageKey = nextState.parentPageKey;
    const parentPagePath = alwaysData[parentPageKey]
      ? alwaysData[parentPageKey].path
      : '';
    update.path = `${parentPagePath}/${path}`;
    update.parentPageKey = nextState.parentPageKey;
  }
  return pageRef.update(update).catch(err => dispatch(reportErr(page, err)));
};
