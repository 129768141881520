import { combineReducers } from 'redux';

import googleAnalytics from './modules/googleAnalytics/reducer';
import appContext from './modules/appContext/reducer';
import toolbar from './modules/toolbar/reducer';
import customDomain from './modules/customDomain/reducer';
import snackbar from './modules/snackbar/reducer';
import dialog from './modules/dialog/reducer';
import notices from './modules/notices/reducer';
import publish from './modules/publishDialog/reducer';
import upgrade from './modules/upgradeButton/reducer';
import addons from './modules/diyComponents/reducer';
import welcomeMsg from './modules/appSumoWelcome/reducer';
import publishMsg from './modules/appSumoPublishNotice/reducer';
import halloweenMsg from './modules/halloweenOfferNotice/reducer';
import promoOfferPopUp from './modules/promoOfferNotice/reducer';
import appcues from './modules/appcues/reducer';
import appPreference from './modules/appPreference/reducer';
import adilloMsg from './modules/adilloMessage/reducers';

export default combineReducers({
  appPreference,
  googleAnalytics,
  appContext,
  customDomain,
  toolbar,
  snackbar,
  dialog,
  notices,
  publish,
  upgrade,
  addons,
  welcomeMsg,
  publishMsg,
  halloweenMsg,
  promoOfferPopUp,
  appcues,
  adilloMsg,
});
