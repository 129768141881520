const initialState = {
  loading: false,
  groupImage: null,
  name: {
    value: '',
    isValid: false,
  },
  order: {
    value: 0,
    isValid: null,
  },
  hasSubscriptionPlan: {
    value: false,
    isValid: true,
  },
  description: {
    value: '',
    isValid: false,
  },
  price: {
    value: 0,
    isValid: true,
  },
  currency: {
    value: '',
    isValid: false,
  },
  oldPrice: {
    value: 0,
    isValid: true,
  },
  isUpdated: false,
  selected: {},
  features: [],
  hideNav: false,
  error: false,
  hideSubscription: false,
  hasUpdatedImage: false,
};

const editGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_UPDATED_IMAGE_STATUS':
      return {
        ...state,
        hasUpdatedImage: action.value,
      };
    case 'EDIT_GROUP_IMAGE':
      return {
        ...state,
        groupImage: action.groupImage,
      };
    case 'EDIT_GROUP_LOADING':
      return {
        ...state,
        loading: action.value,
      };
    case 'EDIT_GROUP_DATA':
      return {
        ...state,
        ...action.payload,
        isUpdated: false,
      };
    case 'EDIT_GROUP_FIELD_CHANGE':
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };
    case 'UPDATED_GROUP_DATA':
      return {
        ...state,
        isUpdated: action.value,
      };
    case 'EDIT_GROUP_FEATURE_ADDED':
      return {
        ...state,
        features: [...state.features, action.value],
      };
    case 'EDIT_HIDE_NAV_DATA':
      return {
        ...state,
        hideNav: action.payload,
      };
    case 'EDIT_HIDE_SUBSCRIPTION_DATA':
      return {
        ...state,
        hideSubscription: action.payload,
      };
    case 'EDIT_GROUP_REMOVE_FEATURE':
      const data = [...state.features];
      data.splice(action.value, 1);
      return {
        ...state,
        features: data,
      };
    case 'ERROR_UPDATING_SUBSCRIPTION_PRODUCT':
      return {
        ...state,
        error: true,
        loading: false,
      };
    case 'RESET_EDIT_GROUP_FORM':
      return { ...initialState, hideNav: state.hideNav };
    default:
      return state;
  }
};

export default editGroupReducer;
