export const planConstants = {
  lifetime: {
    united_states: {
      price: 650,
      currency: 'USD',
    },
    austria: {
      price: 560,
      currency: 'EUR',
    },
    belgium: {
      price: 560,
      currency: 'EUR',
    },
    bulgaria: {
      price: 560,
      currency: 'EUR',
    },
    croatia: {
      price: 560,
      currency: 'EUR',
    },
    cyprus: {
      price: 560,
      currency: 'EUR',
    },
    czechia: {
      price: 560,
      currency: 'EUR',
    },
    denmark: {
      price: 560,
      currency: 'EUR',
    },
    estonia: {
      price: 560,
      currency: 'EUR',
    },
    finland: {
      price: 560,
      currency: 'EUR',
    },
    france: {
      price: 560,
      currency: 'EUR',
    },
    germany: {
      price: 560,
      currency: 'EUR',
    },
    greece: {
      price: 560,
      currency: 'EUR',
    },
    hungary: {
      price: 560,
      currency: 'EUR',
    },
    ireland: {
      price: 560,
      currency: 'EUR',
    },
    italy: {
      price: 560,
      currency: 'EUR',
    },
    latvia: {
      price: 560,
      currency: 'EUR',
    },
    lithuania: {
      price: 560,
      currency: 'EUR',
    },
    luxembourg: {
      price: 560,
      currency: 'EUR',
    },
    malta: {
      price: 560,
      currency: 'EUR',
    },
    netherlands: {
      price: 560,
      currency: 'EUR',
    },
    poland: {
      price: 560,
      currency: 'EUR',
    },
    portugal: {
      price: 560,
      currency: 'EUR',
    },
    romania: {
      price: 560,
      currency: 'EUR',
    },
    slovakia: {
      price: 560,
      currency: 'EUR',
    },
    slovenia: {
      price: 560,
      currency: 'EUR',
    },
    spain: {
      price: 560,
      currency: 'EUR',
    },
    sweden: {
      price: 560,
      currency: 'EUR',
    },
    australia: {
      price: 900,
      currency: 'AUD',
    },
    new_zealand: {
      price: 900,
      currency: 'NZD',
    },

    canada: {
      price: 860,
      currency: 'CAD',
    },
    south_africa: {
      price: 10000,
      currency: 'ZAR',
    },
  },
};
