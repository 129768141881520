import firebase from '../../../../../../../firebase';
import { validateField } from '../../../validators';
import { getAppAdmins } from '../../../actions';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';
import { PROFILE_UPDATED } from '../../../config';

export const EDIT_ADMIN_FIELD_CHANGE = 'EDIT_ADMIN_FIELD_CHANGE';
export const OPEN_EDIT_ADMIN_DIALOG = 'OPEN_EDIT_ADMIN_DIALOG';
export const CLOSE_EDIT_ADMIN_DIALOG = 'CLOSE_EDIT_ADMIN_DIALOG';
export const ADMIN_VALIDATION_ERROR = 'ADMIN_VALIDATION_ERROR';
export const ADMIN_NOT_AVAILABLE = 'ADMIN_NOT_AVAILABLE';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const LOADING_ADMIN = 'LOADING_ADMIN';
export const LOADED_ADMIN_DETAILS = 'LOADED_ADMIN_DETAILS';
export const ADMIN_DETAILS_UPDATE_REQUEST = 'ADMIN_DETAILS_UPDATE_REQUEST';
export const ADMIN_DETAILS_UPDATED = 'ADMIN_DETAILS_UPDATED';

const fieldChange = (prop, value, isValid) => ({
  type: EDIT_ADMIN_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

export const closeDialog = () => ({ type: CLOSE_EDIT_ADMIN_DIALOG });

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;

  return dispatch(fieldChange(prop, value, isValid));
};

export const validationError = prop => ({
  type: ADMIN_VALIDATION_ERROR,
  prop,
});

export const registrationError = err => ({
  type: REGISTRATION_ERROR,
  err,
});

export const loadingAdmin = () => ({
  type: LOADING_ADMIN,
});

export const loadAdmin = prop => ({
  type: LOADED_ADMIN_DETAILS,
  prop,
});

export const closeModal = (app, history) => dispatch => {
  dispatch(closeDialog());
  history.push(`/${app}/members/admins`);
};

export const getAdminDetails = admin => (dispatch, getState) => {
  dispatch(loadingAdmin());
  const { chunks } = getState().auth.appAdmins.data;
  const adminDetails = chunks[admin];

  if (adminDetails && adminDetails.adminAppname) {
    const data = {
      firstName: { value: adminDetails.firstName, isValid: true },
      lastName: { value: adminDetails.lastName, isValid: true },
      email: { value: adminDetails.email, isValid: true },
      adminAppname: { value: adminDetails.adminAppname, isValid: true },
      loading: false,
    };
    dispatch(loadAdmin(data));
  }
};

export const updateAccount = (admin, history, app) => (dispatch, getState) => {
  dispatch({ type: ADMIN_DETAILS_UPDATE_REQUEST });
  const {
    firstName,
    lastName,
    adminAppname,
  } = getState().auth.appAdmins.editAdmin;
  const update = {
    firstName: firstName.value,
    lastName: lastName.value,
    adminAppname: adminAppname.value,
    lastUpdated: Date.now(),
  };

  return firebase
    .database()
    .ref(`users/${admin}`)
    .update(update)
    .then(() => {
      dispatch({
        type: ADMIN_DETAILS_UPDATED,
        key: admin,
        data: update,
      });
      dispatch(getAppAdmins());
      dispatch(closeModal(app, history));
      dispatch(openSnackbar(PROFILE_UPDATED));
    });
};
