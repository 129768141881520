import firebase from '../../../../../../../firebase';
import { capitaliseString } from '../../../../../../../utils';

const addingFormField = (appname, id, value) => ({
  type: 'ADDING_EVENT_ITEM',
  appname,
  id,
  value,
});

const reportErr = (lazyDataId, err) => ({
  type: 'FORM_FIELD_ERROR',
  key: lazyDataId,
  err,
});

export const designChange = (appname, key, type, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${key}/design`);

  dataRef.child(type).set(value);

  return {
    type: 'FORM_DESIGN_CHANGE',
    appname,
    key,
    designType: type,
    value,
  };
};

export const fieldChange = (appname, dataId, field, value, type) => {
  const dataRef = type
    ? firebase
        .database()
        .ref(`apps/${appname}/draft/lazy_data/${dataId}/${type}`)
    : firebase.database().ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child(field).set(value);

  return {
    type: 'FORM_FIELD_CHANGE',
    appname,
    dataId,
    value,
  };
};

export const removeField = (appname, dataId, index, key, calendarType) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}/${calendarType}/fields`);

  dataRef
    .child('order')
    .transaction(items => [
      ...items.slice(0, index),
      ...items.slice(index + 1),
    ]);

  dataRef
    .child('chunks')
    .child(key)
    .remove();

  return {
    type: 'FORM_REMOVE_FIELD',
    appname,
    dataId,
    key,
    index,
  };
};

export const addField = (appname, dataId, value, calendarType) => dispatch => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}/${calendarType}/fields`);
  const key = dataRef.child('chunks').push({
    type: capitaliseString(value),
    label: capitaliseString(value),
    isRequired: true,
    isPublic: true,
    isSearchable: false,
    isGridVisible: false,
  }).key;

  dispatch(addingFormField(appname, dataId, value));

  return dataRef
    .child('order')
    .transaction(items => {
      if (!items) return [key];

      return [...items, key];
    })
    .then(data => data.snapshot.val())
    .catch(err => dispatch(reportErr(err)));
};

const findIndex = (array, element) => {
  if (element === null) {
    return array.length;
  }

  return array.indexOf(element);
};

const moveArray = (oldIndex, newIndex, arr) => {
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const rearrangeField = (
  appname,
  lazydata,
  id,
  siblingId,
) => dispatch => {
  const itemsRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${lazydata}/fields/order`);

  itemsRef
    .transaction(array => {
      if (array === null) return null;

      const oIndex = findIndex(array, id);
      let nIndex = findIndex(array, siblingId);

      if (nIndex > oIndex) {
        nIndex -= 1;
      }

      return moveArray(oIndex, nIndex, array);
    })
    .then(
      dispatch({
        type: 'REARRANGE_FORM_FIELD',
        id,
        siblingId,
      }),
    );
};
