import { compose } from 'redux';

export const lowerCase = s => s.toLowerCase();
const trim = s => s.trim();
const replace = (char = '_') => s => s.replace(/ /g, char);
const removeSpecialCharacter = s => s.replace(/[!@#$%^&* ]/g, '');

/**
 * Function which transform a string to a string key
 */
export const stringToKey = compose(replace(), trim, lowerCase);

/**
 * Function to format a string to an expected Domain
 */
export const parseDomain = compose(removeSpecialCharacter, lowerCase);

/**
 * Function for capitalizing words
 */
export const capitaliseString = (str = '') =>
  str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());

/**
 * Function for converting Hex Code to Symbols
 */
export const deSanitize = string => {
  const map = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#x27;': "'",
    '&#x2F;': '/',
  };
  const reg = /&(.*?);/gi;
  return string.toString().replace(reg, match => map[match]);
};
