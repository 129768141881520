import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Image from './Image';
import {
  fieldChange,
  handleDesignChange,
  createLink,
  fieldLinkChange,
} from './actions';

const mapStateToProps = (state, ownProps) => {
  const layoutData =
    state.editor.data.layoutData[ownProps.match.params.appname];
  const childRoutes = layoutData && layoutData.childRoutes;
  return {
    ...ownProps,
    childRoutes:
      childRoutes &&
      childRoutes.map(key => ({ ...state.editor.data.alwaysData[key], key })),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onLinkChange: (field, value) =>
    dispatch(
      fieldLinkChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onDesignChange: property => (type, value) =>
    dispatch(
      handleDesignChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        type,
        value,
        property,
      ),
    ),
  createLink: () =>
    dispatch(
      createLink(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        true,
      ),
    ),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Image),
);
