import i18n from 'i18next';
import firebase from '../../../../firebase';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';

export const REQUEST_PUSH_HISTORY = 'REQUEST_PUSH_HISTORY';
export const RECEIVE_PUSH_HISTORY = 'RECEIVE_PUSH_HISTORY';
export const REPORT_PUSH_HISTORY_REQUEST_ERROR =
  'REPORT_PUSH_HISTORY_REQUEST_ERROR';
export const UPDATE_NOTIFICATION_VISIBILITY = 'UPDATE_NOTIFICATION_VISIBILITY';
export const FILTER_NOTIFICATIONS = 'FILTER_NOTIFICATIONS';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const SELECT_NOTIFICATION = 'SELECT_NOTIFICATION';
export const SELECT_ALL_NOTIFICATIONS = 'SELECT_ALL_NOTIFICATIONS';
export const DESELECT_ALL_NOTIFICATIONS = 'DESELECT_ALL_NOTIFICATIONS';
export const SORT_NOTIFICATIONS = 'SORT_NOTIFICATIONS';
export const CANCEL_PUSH = 'CANCEL_PUSH';
export const CANCEL_PUSH_SUCCESS = 'CANCEL_PUSH_SUCCESS';
export const FETCHING_NOTIFICATION_DATA = 'FETCHING_NOTIFICATION_DATA';
export const RECEIVE_NOTIFICATION_DATA = 'RECEIVE_NOTIFICATION_DATA';
export const NO_MESSAGE_ID = 'NO_MESSAGE_ID';

const requestPushHistory = appname => ({
  type: REQUEST_PUSH_HISTORY,
  appname,
});

export const reportCancelPushSuccess = key => ({
  type: CANCEL_PUSH_SUCCESS,
  key,
});

const receivePushHistory = (appname, data) => ({
  type: RECEIVE_PUSH_HISTORY,
  appname,
  data,
});

const reportError = err => ({
  type: REPORT_PUSH_HISTORY_REQUEST_ERROR,
  err: err.message,
});

const updateNotificationVisibility = selected => ({
  type: UPDATE_NOTIFICATION_VISIBILITY,
  selected,
});

const notificationsDeleted = selected => ({
  type: DELETE_NOTIFICATION,
  selected,
});

export const filterPushHistory = filter => dispatch =>
  dispatch({
    type: FILTER_NOTIFICATIONS,
    payload: filter,
  });

export const getPushHistory = appname => dispatch => {
  dispatch(requestPushHistory(appname));

  return firebase
    .database()
    .ref(`push_history/${appname}`)
    .once('value')
    .then(snapshot => {
      const data = snapshot.val();
      return dispatch(receivePushHistory(appname, data));
    })
    .catch(err => dispatch(reportError(err)));
};

export const changeSelected = (appname, selected) => dispatch => {
  firebase
    .database()
    .ref(`push_history/${appname}`)
    .transaction(responses => {
      if (responses === null) return null;
      Object.entries(responses).forEach(response => {
        if (selected.indexOf(response[0]) >= 0) {
          if (response[1].visible === undefined) {
            response[1].visible = false;
          } else if (response[1].visible === true) {
            response[1].visible = false;
          } else if (response[1].visible === false) {
            response[1].visible = true;
          }
        }
      });
      return responses;
    })
    .then(() => {
      dispatch(updateNotificationVisibility(selected));
    });
};

export const deleteSelected = (appname, selected) => dispatch => {
  firebase
    .database()
    .ref(`push_history/${appname}`)
    .transaction(responses => {
      if (responses === null) return null;
      const result = {};
      Object.entries(responses).forEach(response => {
        if (selected.indexOf(response[0]) < 0) {
          result[response[0]] = response[1];
        }
      });
      return result;
    })
    .then(() => {
      dispatch(notificationsDeleted(selected));
    });
};

export const selectNotification = key => ({
  type: SELECT_NOTIFICATION,
  key,
});

export const selectAllNotifications = () => ({
  type: SELECT_ALL_NOTIFICATIONS,
});

export const deselectAllNotifications = () => ({
  type: DESELECT_ALL_NOTIFICATIONS,
});

export const sortTableData = cols => dispatch =>
  dispatch({ type: SORT_NOTIFICATIONS, column: cols });

export const cancelPush = messageKey => (dispatch, getState) => {
  dispatch({ type: CANCEL_PUSH });

  const { profile } = getState().account.auth;
  const { appname } = getState().app.appContext;

  const { messageId } = getState().engage.history.data.chunks[messageKey];

  if (!messageId || !messageKey) {
    dispatch(openSnackbar(i18n.t('Could not cancel this Push Notification')));
  }

  const payload = {
    appname,
    messageId,
    messageKey,
    account: profile.account,
  };

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_PUSH_API}/cancel_push`, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify(payload),
        mode: 'cors',
      });
    })
    .then(res => {
      if (res.status === 200) {
        dispatch(reportCancelPushSuccess(messageKey));
        dispatch(getPushHistory(appname));
        dispatch(
          openSnackbar(i18n.t('Push Notification successfully cancelled')),
        );
      } else {
        res.json().then(body => {
          dispatch(reportError(body));
        });
      }
    });
};

export const fetchNotificationData = (appname, messageId) => dispatch => {
  if (!messageId) {
    return dispatch({
      type: NO_MESSAGE_ID,
    });
  }
  dispatch({
    type: FETCHING_NOTIFICATION_DATA,
    messageId,
  });
  return fetch(
    `${process.env.REACT_APP_PUSH_API}/notification/${appname}/${messageId}`,
  )
    .then(res => res.json())
    .then(body => {
      if (body && body.code === 404) {
        return dispatch(reportError(body.message));
      }
      return dispatch({
        type: RECEIVE_NOTIFICATION_DATA,
        body,
      });
    })
    .catch(error => dispatch(reportError(error.message)));
};
