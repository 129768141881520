import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CircularProgress } from 'material-ui';

import Chart from '../chart';
import Events from '../events';
import firebase from '../../../../firebase';
import { isFreePlan } from '../../../account/modules/integration/actions';
import ErrorMessage from '../../../../app/components/errorMessage';
import Map from '../map';
import { Trans } from 'react-i18next';
import styles from './analytics.module.scss';
import { RoundedThinButton } from '../../../../app/components/buttons';

function AppAnalytics(props) {
  const {
    plans,
    integrationType,
    appname,
    onUpdateGaTrackerId,
    view,
    plan,
    is14DayTrialUser,
    trialExpired,
  } = props;

  const isWixWeeblyFreePlan = isFreePlan(plans, integrationType, appname);
  const chartClassName = isWixWeeblyFreePlan ? 'col-xs-11' : 'col-xs-8';
  const [gaValue, setGaValue] = useState('');
  const [gaButtonDisabled, setGaButtonDisabled] = useState(false);
  const [gaIdError, setGaIdError] = useState(false);
  const [gaIdPresent, setGaIdPresent] = useState(false);
  const [gaIdLoading, setGaIdLoading] = useState(true);
  const [showGAInput, setShowGAInput] = useState(false);
  useEffect(() => {
    setGaIdLoading(true);

    (async () => {
      try {
        const snapshot = await firebase
          .database()
          .ref(`/apps/${appname}`)
          .once('value');
        const value = await snapshot.val().gaTrackingId;
        if (value) {
          setGaIdPresent(true);
          setGaValue(value);
        }
        setGaIdLoading(false);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (plan) {
      if (plan !== 'something') setShowGAInput(true);
      else setShowGAInput(false);
    } else if (!trialExpired) setShowGAInput(true);
    else if (!is14DayTrialUser) setShowGAInput(true);
  }, [plan]);

  const onLinkClick = async value => {
    setGaIdError(false);

    const isGaId = /^(G|UA|YT|MO)-[a-zA-Z0-9-]+$/.test(value);
    if (!isGaId) {
      setGaIdError(true);
      return;
    }
    setGaButtonDisabled(true);

    try {
      await onUpdateGaTrackerId(value);
      setGaButtonDisabled(false);
      setGaIdPresent(true);
    } catch (err) {
      console.error(err);
      setGaButtonDisabled(false);
    }
  };
  return (
    <div className="scroll-container white-bg">
      <div className="flex-container">
        <div className="row">
          <div className={chartClassName}>
            <Chart view={view} />
            {integrationType !== 'Wix' ? (
              <>
                <Map />
                {showGAInput ? (
                  <div className={styles.container_ga}>
                    {gaIdLoading && <CircularProgress />}
                    {!gaIdLoading && !gaIdPresent && (
                      <>
                        <h2>
                          <Trans>
                            Enter your Google Analytics Tracking ID or
                            Measurement ID (for GA4) to link to Google Analytics
                          </Trans>
                        </h2>
                        <div className={styles.container_input}>
                          <input
                            className={styles.input_ga}
                            type="text"
                            placeholder="UA-XXXXXX-X or G-XXXXXXX"
                            value={gaValue}
                            onChange={e => setGaValue(e.target.value)}
                          />
                          <button
                            className={styles.button_ga}
                            onClick={() => onLinkClick(gaValue)}
                            disabled={gaButtonDisabled}
                          >
                            <Trans>Link</Trans>
                          </button>
                        </div>
                        {gaIdError && (
                          <ErrorMessage>
                            <Trans>
                              Please enter a valid Tracking Id or Measurement
                              ID!
                            </Trans>
                          </ErrorMessage>
                        )}
                        <a
                          className={styles.help_ga}
                          href="https://support.google.com/analytics/answer/1008080?hl=en"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Trans>
                            How to get a Tracking ID or Measurement ID?
                          </Trans>
                        </a>
                      </>
                    )}
                    {!gaIdLoading && gaIdPresent && (
                      <>
                        <h2 style={{ marginBottom: '40px' }}>
                          <Trans>
                            You have linked your Apps to Google Analytics
                          </Trans>
                        </h2>
                        <a
                          className={`${styles.button_ga}`}
                          href="https://analytics.google.com/analytics/web/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Trans>Open Google Analytics</Trans>
                        </a>
                        <div
                          className={`${styles.help_ga} ${styles.help_ga_change}`}
                          style={{ display: 'block' }}
                          onClick={() => setGaIdPresent(false)}
                        >
                          <Trans>
                            Change your Tracking ID or Measurement ID
                          </Trans>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <center>
                    {' '}
                    <RoundedThinButton className="success">
                      <Trans>upgrade to integrate with Google Analytics </Trans>
                    </RoundedThinButton>
                  </center>
                )}
              </>
            ) : null}
          </div>

          {!isWixWeeblyFreePlan && (
            <div className="col-xs-4">
              <Events />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AppAnalytics;
