import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Container from '../../../../../../../app/components/container';
import ButtonLinkMenu from '../../../../../components/buttonLinkMenu';
import DesignToggle from '../../../../../components/designToggle';
import ButtonDesignMenu from '../../../../../components/buttonDesignMenu';
import { Tabs, Tab } from '../../../../../components/tabs';

class Button extends Component {
  static propTypes = {
    link: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
    pages: PropTypes.array.isRequired,
    onContentChange: PropTypes.func.isRequired,
    onDesignChange: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    await this.props.onLoadGoogleAnalytics();
  }

  handleChange = prop => value => this.props.onContentChange(prop, value);
  handleChangeLinkSection = (prop, value) => {
    const propTransformed = prop
      .replace(/link\/linkType\//g, 'action/')
      .replace(/link\/button\//g, '');
    this.props.onContentChange(propTransformed, value);
  };

  render() {
    const {
      link,
      component,
      pages,
      onDesignChange,
      onGetIconUrls,
      customIconsArray,
      t,
    } = this.props;

    return (
      <Tabs>
        <Tab label={t('content')}>
          <Container>
            <ButtonLinkMenu
              link={link}
              pages={pages}
              buttonOptional={false}
              googleAnalytics={this.props.googleAnalytics}
              gaAction={this.props.data.gaAction}
              gaCategory={this.props.data.gaCategory}
              gaEnabled={this.props.data.gaEnabled}
              onChange={this.handleChangeLinkSection}
              iconEnabled={this.props.data.iconEnabled || false}
              onGetIconUrls={onGetIconUrls}
              btnIconUrl={this.props.data.btnIconUrl}
              customIconsArray={customIconsArray}
            />
          </Container>
        </Tab>
        <Tab label={t('design')}>
          <Container>
            <DesignToggle
              component={component}
              onDesignChange={(el, isToggled) =>
                onDesignChange(null)(el, isToggled)
              }
            />
            <ButtonDesignMenu
              component={component}
              onDesignChange={onDesignChange()}
            />
          </Container>
        </Tab>
        <Tab
          label={t('layout')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    );
  }
}

export default withTranslation()(Button);
