import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Food from './Food';
import { specialItemsToggle, designChange } from './actions';

const mapStateToProps = (state, ownProps) => ({
  appname: ownProps.match.params.appname,
  ...ownProps,
  design: ownProps.component.design,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSpecialItemsToggle: value =>
    dispatch(
      specialItemsToggle(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        !value,
      ),
    ),
  onDesignChange: (field, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        field,
        value,
      ),
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Food));
