import React from 'react';
import { Trans } from 'react-i18next';
import './styles.css';

const Social = () => (
  <svg
    className="icon vote-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36.17 36.17"
  >
    <title>
      <Trans>Vote Data</Trans>
    </title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M16.72,5.54l-.09-.74-.73,0h-.2A15.7,15.7,0,1,0,31.39,20.64v-.76H16.72ZM29.86,21.38A14.19,14.19,0,1,1,15.22,6.29V21.38Z"
        />
        <path
          class="cls-1"
          d="M20.75,0H20V16.61H35.41l.7-.07.06-.7V15.7A15.77,15.77,0,0,0,20.75,0Zm.74,15.1V1.55A14.27,14.27,0,0,1,34.66,15.11Z"
        />
      </g>
    </g>
  </svg>
);

export default Social;
