import firebase from '../../../../../firebase';
import { validateField } from '../validators';

export const openEditContactDialog = key => (dispatch, getState) => {
  const contact = getState().distribute.contacts.data.chunks[key];

  return dispatch({
    type: 'OPEN_EDIT_CONTACT_DIALOG',
    key,
    ...contact,
  });
};

export const closeEditContactDialog = () => ({
  type: 'CLOSE_EDIT_CONTACT_DIALOG',
});

export const contactFieldChange = (field, value, isValid) => ({
  type: 'CONTACT_FIELD_CHANGE',
  field,
  isValid,
  value,
});

export const formFieldChange = (prop, value, other = null) => dispatch => {
  let isValid = value.length > 0 ? validateField[prop](value) : null;
  if (prop === 'mobile' && other !== null && other.code !== 'GB') {
    isValid = value.length > 0 ? validateField['international'](value) : null;
  } else if (prop === 'country' && other !== null) {
    const isMobileValid =
      other.length > 0
        ? validateField[value.code !== 'GB' ? 'international' : 'mobile'](other)
        : null;
    dispatch(contactFieldChange('mobile', other, isMobileValid));
  }
  return dispatch(contactFieldChange(prop, value, isValid));
};

export const saveContactChange = (appname, cameFromDrivers, history) => (
  dispatch,
  getState,
) => {
  const {
    name,
    email,
    mobile,
    country,
    key,
  } = getState().distribute.contacts.editContact;

  const payload = {
    name: name.value,
    email: email.value,
    mobile: mobile.value,
    country: country.value,
  };

  dispatch({
    type: 'SAVE_CONTACT_CHANGE',
    appname,
  });

  return firebase
    .database()
    .ref(`contacts/${appname}/${key}`)
    .update(payload)
    .then(() =>
      dispatch({
        type: 'CONTACT_UPDATED',
        key,
        ...payload,
      }),
    )
    .then(() => {
      if (cameFromDrivers) {
        history.push(`/${appname}/ecommerce/drivers`);
      }
    });
};
