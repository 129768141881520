import _map from 'lodash/map';

const initialState = [];

const appSumoBilling = (state = initialState, action) => {
  switch (action.type) {
    case 'HYDRATE_APPSUMO_BILLING':
      return action.data;

    case 'LOGOUT_SUCCESS':
      return [];
    default:
      return state;
  }
};

export default appSumoBilling;
