import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import Divider from 'material-ui/Divider';

import ButtonLinkMenu from '../../components/buttonLinkMenu';
import SectionHeader from '../sectionHeader';
import TextField from '../../../../app/components/textField';

const ButtonLabelSetter = ({
  onChange,
  label,
  link,
  pages,
  handleChangeLinkSection,
  noButtonLink,
  gaEnabled,
  gaCategory,
  gaAction,
  googleAnalytics,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ width: '100%', marginBottom: '15px' }}>
      <Divider
        style={{
          width: '100%',
          height: '2px',
        }}
      />
      <SectionHeader
        title={t('Button')}
        tooltip={t('A label to be displayed on the button')}
      />
      <TextField
        id="button-label"
        onChange={onChange}
        value={label}
        placeholder="Label"
      />
      {link && pages ? (
        <ButtonLinkMenu
          noButtonLink={noButtonLink}
          link={link}
          pages={pages}
          buttonOptional={false}
          onChange={handleChangeLinkSection}
          googleAnalytics={googleAnalytics}
          gaEnabled={gaEnabled}
          gaCategory={gaCategory}
          gaAction={gaAction}
        />
      ) : null}
    </div>
  );
};

ButtonLabelSetter.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

ButtonLabelSetter.defaultProps = {};

export default ButtonLabelSetter;
