export const NEW_CONTACT_ADDED = 'NEW_CONTACT_ADDED';
export const LIST_UPLOAD_COMPLETE = 'LIST_UPLOAD_COMPLETE';
export const CONTACT_DELETED = 'CONTACT_DELETED';
export const CONTACT_UPDATED = 'CONTACT_UPDATED';
export const LOAD_CONTACTS = 'LOAD_CONTACTS';
export const HYDRATE_CONTACTS = 'HYDRATE_CONTACTS';
export const SELECT_CONTACT = 'SELECT_CONTACT';
export const SELECT_ALL_CONTACTS = 'SELECT_ALL_CONTACTS';
export const DESELECT_ALL_CONTACTS = 'DESELECT_ALL_CONTACTS';
export const CONTACTS_DELETED = 'CONTACTS_DELETED';
export const SEARCH_CONTACTS = 'SEARCH_CONTACTS';
export const SELECT_GROUP = 'SELECT_GROUP';
export const SELECT_ALL_GROUPS = 'SELECT_ALL_GROUPS';
export const DESELECT_ALL_GROUPS = 'DESELECT_ALL_GROUPS';
export const SELECT_WORKFORCE_GROUP = 'SELECT_WORKFORCE_GROUP';
export const REQUEST_GROUPS = 'REQUEST_GROUPS';
export const LOAD_WORKFORCE_GROUPS = 'LOAD_WORKFORCE_GROUPS';
export const LOAD_GROUPS = 'LOAD_GROUPS';
export const HYDRATE_GROUPS = 'HYDRATE_GROUPS';
export const RECEIVE_WORKFORCE_GROUPS = 'RECEIVE_WORKFORCE_GROUPS';
export const RECEIVE_GROUPS = 'RECEIVE_GROUPS';
export const REQUEST_USERS = 'REQUEST_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const NO_USERS_EXIST = 'NO_USERS_EXIST';
export const SET_PAGE_TYPE = 'SET_PAGE_TYPE';
export const RESET_CONTACT_SELECTION = 'RESET_CONTACT_SELECTION';
