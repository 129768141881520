import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
.st0{fill:#FFFFFF;}
.st1{fill:none;stroke:#D0D2D3;stroke-width:5.1639;stroke-miterlimit:6.8852;}
.st2{fill:#D0D2D3;}
.st3{fill:none;stroke:#F1F1F2;stroke-width:1.7213;stroke-miterlimit:6.8852;}
.st4{fill:none;stroke:#DBDBDB;stroke-width:1.7213;stroke-miterlimit:6.8852;}
.st5{fill:#F1F1F2;}
.st6{fill:none;stroke:#DBDBDB;stroke-width:2.5819;stroke-miterlimit:6.8854;}
.st7{fill:none;stroke:#DBDBDB;stroke-width:2.5819;stroke-miterlimit:6.8852;}
.st8{fill:none;stroke:#D0D2D3;stroke-width:5.1639;stroke-miterlimit:17.2129;}
.st9{fill:#E5E5E5;}
.st10{fill:#FFFFFF;stroke:#CBCBCB;stroke-miterlimit:10;}
.st11{fill:#F6F6F6;}
.st12{fill:#D1D2D2;}
.st13{fill:#FFFFFF;stroke:#CBCBCB;stroke-width:0.25;stroke-miterlimit:10;}
`;

const VerticalSlider = () => (
  <Svg
    className="layout layout-header-large"
    width="750.000000pt"
    height="1334.000000pt"
    viewBox="0 0 750 1334"
    preserveAspectRatio="xMidYMid meet"
  >
    <g id="Layer_2_1_">
	<g id="Layer_1-2">
		<path class="st0" d="M58.3,210.6h-2.4c-1.9,0-3.4-1.5-3.4-3.4V157c0-1.9,1.5-3.4,3.4-3.4h2.4"/>
		<path class="st1" d="M58.3,210.6h-2.4c-1.9,0-3.4-1.5-3.4-3.4V157c0-1.9,1.5-3.4,3.4-3.4h2.4"/>
		<path class="st0" d="M58.3,375.8h-2.1c-1.9,0-3.4-1.5-3.4-3.4v-94c0-1.9,1.5-3.4,3.4-3.4h2.1"/>
		<path class="st1" d="M58.3,375.8h-2.1c-1.9,0-3.4-1.5-3.4-3.4v-94c0-1.9,1.5-3.4,3.4-3.4h2.1"/>
		<path class="st0" d="M691.4,274.9h1.4c2.9,0,5.2,2.3,5.2,5.2v90.5c0,2.9-2.3,5.2-5.2,5.2h-1.4"/>
		<path class="st1" d="M691.4,274.9h1.4c2.9,0,5.2,2.3,5.2,5.2v90.5c0,2.9-2.3,5.2-5.2,5.2h-1.4"/>
		<path class="st0" d="M58.4,500.1H53c-1.9,0,0-1.5,0-3.4v-94c0-1.9-1.9-3.4,0-3.4h5.5"/>
		<path class="st1" d="M58.4,500.1H53c-1.9,0,0-1.5,0-3.4v-94c0-1.9-1.9-3.4,0-3.4h5.5"/>
		<path class="st0" d="M147.6,1335.4c-47.9,0-86.7-38.9-86.8-86.8V93.3c0-47.9,38.9-86.7,86.8-86.8h454.6
			c47.9,0,86.7,38.9,86.8,86.8v1155.3c0,47.9-38.9,86.7-86.8,86.8L147.6,1335.4L147.6,1335.4z"/>
		<path class="st2" d="M602,9.2c46.4,0.1,84.1,37.7,84.2,84.2v1155.3c0,46.5-37.7,84.2-84.2,84.2H147.6
			c-46.4-0.1-84.1-37.7-84.2-84.2V93.3c0-46.5,37.7-84.2,84.2-84.2L602,9.2 M602,4H147.6C98.3,4,58.3,44,58.3,93.3v1155.3
			c0,49.3,40,89.3,89.4,89.3h454.6c49.3,0,89.3-40,89.3-89.3V93.3C691.4,44,651.4,4.1,602,4z"/>
		<path class="st3" d="M670.2,1179.6c0,2.9-2.3,5.2-5.2,5.2H83.7c-2.9,0-5.2-2.3-5.2-5.2V146.9c0.1-2.8,2.3-5.1,5.2-5.2H665
			c2.9,0,5.2,2.3,5.2,5.2V1179.6L670.2,1179.6z"/>
		<path class="st4" d="M670.2,1179.6c0,2.9-2.3,5.2-5.2,5.2H83.7c-2.9,0-5.2-2.3-5.2-5.2V146.9c0.1-2.8,2.3-5.1,5.2-5.2H665
			c2.9,0,5.2,2.3,5.2,5.2V1179.6L670.2,1179.6z"/>
		
			<ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 149.6414 327.7259)" class="st5" cx="267.4" cy="75.9" rx="11.4" ry="11.4"/>
		
			<ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 149.6414 327.7259)" class="st6" cx="267.4" cy="75.9" rx="11.4" ry="11.4"/>
		<circle class="st5" cx="373.4" cy="30.5" r="6.9"/>
		<circle class="st7" cx="373.4" cy="30.5" r="6.9"/>
		<circle class="st8" cx="373.4" cy="1262.3" r="51.8"/>
		<path class="st9" d="M424.2,76.3c0,2.9-2.3,5.2-5.2,5.2h-92.9c-2.9,0-5.2-2.3-5.2-5.2v-0.2c0-2.9,2.3-5.2,5.2-5.2H419
			C421.9,71.1,424.2,73.4,424.2,76.3z"/>
		<path class="st4" d="M424.2,76.3c0,2.9-2.3,5.2-5.2,5.2h-92.9c-2.9,0-5.2-2.3-5.2-5.2v-0.2c0-2.9,2.3-5.2,5.2-5.2H419
			C421.9,71.1,424.2,73.4,424.2,76.3z"/>
	</g>
</g>
<g>
	<g id="change3_2_">
		<rect x="174.6" y="182.6" class="st10" width="400" height="350.5"/>
	</g>
	<g id="change2_2_">
		<path class="st11" d="M269.2,333c58.6,0,67.8,125.7,91.5,125.7s34.2-75.1,67.7-75.1c46.1,0,69.1,135.9,146.6,135.9
			c0.1,0,0.2,0,0.3,0V183.1H174.6v311.6C188.4,444.1,223.9,333,269.2,333z M486.9,230.6c31.5,0,57.1,25.5,57.1,57.1
			c0,31.5-25.5,57.1-57.1,57.1c-31.5,0-57-25.5-57-57.1C429.8,256.1,455.4,230.6,486.9,230.6z"/>
	</g>
	<g id="change1_2_">
		<path class="st12" d="M575.3,532.1v-12.7c-0.1,0-0.2,0-0.3,0c-77.5,0-100.5-135.9-146.6-135.9c-33.5,0-44,75.1-67.7,75.1
			c-23.7,0-32.9-125.7-91.5-125.7c-45.3,0-80.8,111.2-94.6,161.8v37.4H575.3L575.3,532.1z"/>
		<circle class="st12" cx="486.9" cy="287.6" r="57.1"/>
	</g>
</g>
<g>
	<g id="change3_3_">
		<rect x="179.9" y="791.1" class="st10" width="400" height="350.5"/>
	</g>
	<g id="change2_3_">
		<path class="st11" d="M274.5,941.5c58.6,0,67.8,125.7,91.5,125.7s34.2-75.1,67.7-75.1c46.1,0,69.1,135.9,146.6,135.9
			c0.1,0,0.2,0,0.3,0V791.6H179.9v311.6C193.7,1052.6,229.2,941.5,274.5,941.5z M492.2,839.1c31.5,0,57.1,25.5,57.1,57.1
			c0,31.5-25.5,57.1-57.1,57.1c-31.5,0-57-25.5-57-57.1C435.1,864.6,460.7,839.1,492.2,839.1z"/>
	</g>
	<g id="change1_3_">
		<path class="st12" d="M580.7,1140.6v-12.7c-0.1,0-0.2,0-0.3,0c-77.5,0-100.5-135.9-146.6-135.9c-33.5,0-44,75.1-67.7,75.1
			c-23.7,0-32.9-125.7-91.5-125.7c-45.3,0-80.8,111.2-94.6,161.8v37.4H580.7L580.7,1140.6z"/>
		<circle class="st12" cx="492.2" cy="896.1" r="57.1"/>
	</g>
</g>
<g>
	<g>
		<g>
			<g id="change3_12_">
				<rect x="106.5" y="424.5" class="st13" width="531.2" height="465.5"/>
			</g>
			<g id="change2_12_">
				<path class="st11" d="M232.1,624.2c77.8,0,90,166.9,121.5,166.9s45.4-99.7,89.9-99.7c61.2,0,91.8,180.5,194.7,180.5
					c0.1,0,0.3,0,0.4,0V425.2H106.5V839C124.8,771.8,171.9,624.2,232.1,624.2z M521.2,488.2c41.8,0,75.8,33.9,75.8,75.8
					c0,41.8-33.9,75.8-75.8,75.8c-41.8,0-75.7-33.9-75.7-75.8C445.4,522.1,479.4,488.2,521.2,488.2z"/>
			</g>
			<g id="change1_12_">
				<path class="st12" d="M638.6,888.6v-16.9c-0.1,0-0.3,0-0.4,0c-102.9,0-133.5-180.5-194.7-180.5c-44.5,0-58.4,99.7-89.9,99.7
					s-43.7-166.9-121.5-166.9c-60.2,0-107.3,147.7-125.6,214.9v49.7H638.6L638.6,888.6z"/>
				<circle class="st12" cx="521.2" cy="563.9" r="75.8"/>
			</g>
		</g>
	</g>
</g>
  </Svg>
);

export default VerticalSlider;
