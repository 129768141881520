import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from 'material-ui/Dialog';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/styles';
import { Trans } from 'react-i18next';
import { FlatButton } from '../../../app/components/buttons';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const actions = (action, onClose, resetState) => [
  <FlatButton
    style={{ margin: 20 }}
    label="Cancel"
    secondaryWhite
    onClick={() => onClose()}
  />,
  <FlatButton
    style={{ margin: 20 }}
    label="Confirm"
    primary
    onClick={() => {
      action();
      onClose();
      resetState();
    }}
  />,
];

const EmailDialog = ({ open, onClose, action }) => {
  const fields = {
    subject: '',
    cc: '',
    bcc: '',
    message: '',
  };
  const [emailFields, setEmailFields] = useState(fields);
  const classes = useStyles();

  const onFieldChange = e => {
    setEmailFields({
      ...emailFields,
      [e.target.id]: e.target.value,
    });
  };

  const resetState = () => setEmailFields(fields);
  const { subject, cc, bcc, message } = emailFields;
  return (
    <Dialog
      actions={
        action !== null
          ? actions(() => action(emailFields), onClose, resetState)
          : [actions(action, onClose)[0]]
      }
      open={open}
      onRequestClose={() => onClose()}
      style={{ textAlign: 'center' }}
      actionsContainerStyle={{ textAlign: 'center' }}
    >
      <DialogTitle>
        <Trans>Email to all the selected users</Trans>
      </DialogTitle>
      <FormControl className={classes.margin} fullWidth>
        <InputLabel htmlFor="subject">
          <Trans>Subject</Trans>
        </InputLabel>
        <Input id="subject" value={subject} onChange={onFieldChange} />
      </FormControl>
      <FormControl className={classes.margin} fullWidth>
        <InputLabel htmlFor="cc">
          <Trans>CC</Trans>
        </InputLabel>
        <Input id="cc" value={cc} onChange={onFieldChange} helper />
        <FormHelperText id="cc-helper-text">
          <Trans>
            To include multiple emails seperate them by commas (Eg:
            abc@email.com, abcd@email.com)
          </Trans>
        </FormHelperText>
      </FormControl>

      <FormControl className={classes.margin} fullWidth>
        <InputLabel htmlFor="bcc">BCC</InputLabel>
        <Input id="bcc" value={bcc} onChange={onFieldChange} />
        <FormHelperText id="bcc-helper-text">
          <Trans>
            To include multiple emails seperate them by commas (Eg:
            abc@email.com, abcd@email.com)
          </Trans>
        </FormHelperText>
      </FormControl>

      <FormControl className={classes.margin} fullWidth>
        <InputLabel htmlFor="message">
          <Trans>Message</Trans>
        </InputLabel>
        <Input
          id="message"
          multiline={true}
          value={message}
          onChange={onFieldChange}
        />
      </FormControl>
    </Dialog>
  );
};

EmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  action: PropTypes.func,
};

EmailDialog.defaultProps = {
  action: null,
};

export default EmailDialog;
