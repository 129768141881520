import React from 'react';

const Back = ({ iconStyle }) => (
  <svg
    style={iconStyle}
    className="icon back"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <g id="Layer_1" data-name="Layer 1">
      <path d="M16,32A16,16,0,1,1,32,16,16,16,0,0,1,16,32ZM16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Z" />
      <path d="M13.27,21.09a1,1,0,0,1-.71-.29L8.48,16.71a1,1,0,0,1,0-1.41l4.09-4.09A1,1,0,0,1,14,12.62L10.6,16,14,19.38a1,1,0,0,1-.71,1.71Z" />
      <path d="M22.82,17H9.18a1,1,0,0,1,0-2H22.82a1,1,0,1,1,0,2Z" />
    </g>
  </svg>
);

export default Back;
