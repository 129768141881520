import {
  EDIT_STAFF_FIELD_CHANGE,
  CLOSE_EDIT_STAFF_DIALOG,
  REGISTRATION_ERROR,
  LOADING_STAFF,
  LOADED_STAFF_DETAILS,
  STAFF_DETAILS_UPDATE_REQUEST,
  STAFF_DETAILS_UPDATED,
} from './actions';

const initialState = {
  pending: true,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  firstName: {
    value: '',
    isValid: null,
  },
  lastName: {
    value: '',
    isValid: null,
  },
  email: {
    value: '',
    isValid: null,
  },
};

const editStaffReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_EDIT_STAFF_DIALOG:
      return initialState;

    case EDIT_STAFF_FIELD_CHANGE:
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };

    case REGISTRATION_ERROR:
      return {
        ...state,
        error: action.err,
        pending: false,
      };

    case LOADING_STAFF:
      return {
        ...state,
        pending: true,
      };

    case STAFF_DETAILS_UPDATE_REQUEST:
      return {
        ...state,
        pending: true,
      };

    case LOADED_STAFF_DETAILS:
      return {
        ...state,
        pending: false,
        ...action.prop,
      };

    case STAFF_DETAILS_UPDATED:
      return {
        ...state,
        key: action.staff,
        data: action.update,
      };

    default:
      return state;
  }
};

export default editStaffReducer;
