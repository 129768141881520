/**
 * Function which sorts an array of react components based on props.
 */
export const propSort = prop => (a, b) => {
  if (a.props[prop] < b.props[prop]) {
    return -1;
  }
  if (a.props[prop] > b.props[prop]) {
    return 1;
  }
  return 0;
};

/**
 * Returns "array of objects" from firebase data "object of objects"
 * @param object object of Objects
 * @example objectToArray({someID: {someObject}, someID: {someObject}})
 * @returns [{id: someID, value: {someObject}}, {id: someID, value: {someObject}}]
 */
export const objectToArray = object =>
  object ? Object.entries(object).map(e => ({ id: e[0], value: e[1] })) : [];

/**
 * Returns data like firebase "objects of objects" from "array of objects"
 * @param object array of Objects
 * @example arrayToObject([{id: someID, value: {someObject}}, {id: someID, value: {someObject}}])
 * @returns ({someID: {someObject}, someID: {someObject}})
 */
export const arrayToObject = array => {
  const object = {};
  array.forEach(i => {
    object[i.id] = i.value;
  });
  return object;
};

/**
 * Function which sorts an array of object.
 */
export const sortArray = (array, prop, isAscending = true) => {
  const order = isAscending ? 1 : -1;
  return array.sort((a, b) => {
    let x = a[1][prop];
    let y = b[1][prop];
    if (typeof x === 'string' && typeof y === 'string') {
      x = x.toLowerCase();
      y = y.toLowerCase();
    }
    if (x < y) {
      return -order;
    }
    if (x > y) {
      return order;
    }
    return 0;
  });
};
