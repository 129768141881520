import React from 'react';

import { Phone } from '../icons';
import ResponsiveIconButton from './ResponsiveIconButton';

const MobileButton = props => (
  <ResponsiveIconButton {...props}>
    <Phone />
  </ResponsiveIconButton>
);

export default MobileButton;
