import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import TextField from '../../../../../../../app/components/textField';
import DesignToggle from '../../../../../components/designToggle';
import Container from '../../../../../../../app/components/container';
import { Tabs, Tab } from '../../../../../components/tabs';

import './styles.css';
import ButtonDesignMenu from '../../../../../components/buttonDesignMenu/ButtonDesignMenu';

class RadioStreaming extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    onReceiveMedia: PropTypes.func.isRequired,
    onDesignToggle: PropTypes.func.isRequired,
    onDesignChange: PropTypes.func.isRequired,
    openSnackbar: PropTypes.func.isRequired,
    pending: PropTypes.bool,
  };

  static defaultProps = {
    pending: false,
  };

  render() {
    const { component, data, handleChange, onDesignChange, t } = this.props;

    return (
      <Tabs>
        <Tab label={t('content')}>
          <Container>
            <TextField
              id="radioStreamingUrl"
              placeholder={t('Enter Radio Streaming url')}
              value={data.radioStreamingUrl}
              onChange={value => handleChange('radioStreamingUrl', value)}
            />
          </Container>
          <Container>
            <TextField
              id="playButtonTitle"
              placeholder={t('Play Button Title')}
              value={data.playButtonTitle}
              onChange={value => handleChange('playButtonTitle', value)}
            />
          </Container>
          <Container>
            <TextField
              id="pauseButtonTitle"
              placeholder={t('Pause Button Title')}
              value={data.pauseButtonTitle}
              onChange={value => handleChange('pauseButtonTitle', value)}
            />
          </Container>
        </Tab>
        <Tab label={t('design')}>
          <Container>
            <DesignToggle
              component={component}
              onDesignChange={(el, isToggled) =>
                onDesignChange(null)(el, isToggled)
              }
            />
            <ButtonDesignMenu
              component={component}
              onDesignChange={onDesignChange()}
            />
          </Container>
        </Tab>
        <Tab
          label={t('design')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />

        <Tab
          label={t('layout')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    );
  }
}

export default withTranslation()(RadioStreaming);
