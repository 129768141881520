import _pick from 'lodash/pick';
import i18n from 'i18next';
import firebase from '../../../../../../firebase';
import { objectToArray, arrayToObject } from '../../../../../../utils';

import {
  REQUEST_MARKETPLACE_VENDORS,
  STOP_RECEIVING_MARKETPLACE_VENDORS,
  RECEIVE_MARKETPLACE_VENDORS,
  NO_MARKETPLACE_VENDORS_EXIST,
  VENDOR_REPORTS,
  NO_VENDOR_REPORTS,
  FILTER_MARKETPLACE_VENDORS_DATA,
  SELECT_MARKETPLACE_VENDOR,
  SELECT_ALL_MARKETPLACE_VENDORS,
  DESELECT_ALL_MARKETPLACE_VENDORS,
  DELETE_SELECTED,
  EMAIL_SELECTED,
  ACTION_ERROR,
  SORT_VENDORS,
} from './types';

const requestVendors = appname => ({
  type: REQUEST_MARKETPLACE_VENDORS,
  appname,
});

export const getVendors = appname => dispatch => {
  dispatch(requestVendors(appname));

  firebase
    .database()
    .ref(`app_users/${appname}`)
    .on('value', snap => {
      if (!snap.exists()) {
        dispatch({
          type: NO_MARKETPLACE_VENDORS_EXIST,
        });
      } else {
        const users = objectToArray(snap.val());
        const vendors = arrayToObject(
          users
            .filter(user => user.value.userType === 'vendor')
            .sort((user1, user2) => {
              if (user1.value.vendorApprovalStatus === undefined) {
                return -1;
              } else if (user2.value.vendorApprovalStatus === undefined) {
                return 1;
              } else {
                return 0;
              }
            }),
        );
        dispatch({
          type: RECEIVE_MARKETPLACE_VENDORS,
          payload: { vendors },
        });
      }
    });
};

export const getAllVendorReports = appname => async dispatch => {
  const response = await fetch(
    `${process.env.REACT_APP_SELLER_API}/get_all_reports?appname=${appname}`,
  );

  if (response.ok) {
    dispatch({
      type: VENDOR_REPORTS,
      payload: await response.json(),
    });
  } else {
    dispatch({
      type: NO_VENDOR_REPORTS,
    });
  }
};

export const filterVendorData = filter => dispatch =>
  dispatch({
    type: FILTER_MARKETPLACE_VENDORS_DATA,
    payload: filter,
  });

export const selectVendor = key => ({
  type: SELECT_MARKETPLACE_VENDOR,
  key,
});

export const selectAllVendors = () => ({
  type: SELECT_ALL_MARKETPLACE_VENDORS,
});

export const deselectAllVendors = () => ({
  type: DESELECT_ALL_MARKETPLACE_VENDORS,
});

export const deleteSelectedSuccess = (appname, selected) => dispatch =>
  dispatch({
    type: DELETE_SELECTED,
    appname,
    selected,
  });

export const emailSelectedSuccess = () => dispatch =>
  dispatch({
    type: EMAIL_SELECTED,
  });

export const reportActionError = (
  error,
  appname,
  selected,
  action,
) => dispatch =>
  dispatch({ type: ACTION_ERROR, error, appname, selected, action });

const deleteUser = (appname, email) =>
  new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;

    return user.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_MEMBER_API}/member`, {
        method: 'delete',
        mode: 'cors',
        body: JSON.stringify({
          appname,
          email,
          accessToken: token,
          requestOrigin: 'platform',
        }),
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            return resolve(res);
          }

          throw new Error(
            i18n.t('Opps, something went wrong. Please try again.'),
          );
        })
        .catch(reject);
    });
  });

const emailUsers = (appname, userEmails, emailFields) =>
  new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;
    const { uid } = user;

    return user.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_MARKETPLACE_API}/mail-users`, {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
          appname,
          uid,
          userEmails,
          emailFields,
          accessToken: token,
          requestOrigin: 'platform',
        }),
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            return resolve(res);
          }

          throw new Error(
            i18n.t('Opps, something went wrong. Please try again.'),
          );
        })
        .catch(reject);
    });
  });

export const deleteSelected = (appname, selected, chunks) => dispatch => {
  Object.values(_pick(chunks, selected)).forEach(user => {
    deleteUser(appname, user.email)
      .then(data => {
        dispatch(deleteSelectedSuccess(appname, selected, data));
        dispatch(getVendors(appname));
      })
      .catch(error => {
        dispatch(reportActionError(error));
      });
  });
};

export const emailSelected = (
  appname,
  selected,
  chunks,
  emailFields,
) => dispatch => {
  const userEmails = [];
  Object.values(_pick(chunks, selected)).forEach(user => {
    userEmails.push(user.email);
  });
  emailUsers(appname, userEmails, emailFields)
    .then(() => {
      dispatch(emailSelectedSuccess());
    })
    .catch(error => {
      dispatch(reportActionError(error));
    });
};

export const stopGetVendors = appname => dispatch => {
  firebase
    .database()
    .ref(`app_users/${appname}`)
    .off('value');
  dispatch({
    type: STOP_RECEIVING_MARKETPLACE_VENDORS,
    value: appname,
  });
};

export const sortTableData = cols => dispatch =>
  dispatch({ type: SORT_VENDORS, column: cols });
