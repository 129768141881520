import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import LinearProgress from 'material-ui/LinearProgress';
import { Trans, useTranslation } from 'react-i18next';
import { FlatButton } from '../../../app/components/buttons';

import { getAppUrl } from '../../../utils/appDetails';

const PublishDialog = ({
  open,
  title,
  match,
  requestingPublish,
  publishSuccessful,
  publishing,
  handlePublish,
  handleClose,
  handleCloseAfterPublish,
  customDomain,
}) => {
  const { t } = useTranslation();

  let actions;

  const publishActions = [
    <FlatButton
      label={t('Cancel')}
      onClick={handleClose}
      disabled={requestingPublish}
      secondaryWhite
    />,
    <FlatButton
      label={t('Publish')}
      onClick={handlePublish}
      disabled={requestingPublish}
      primary
    />,
  ];

  const successActions = [
    <FlatButton label={t('Ok')} onClick={handleCloseAfterPublish} primary />,
  ];

  const url = getAppUrl(match.params.appname, customDomain);

  if (!requestingPublish && !publishSuccessful) {
    actions = publishActions;
  } else if (requestingPublish) {
    actions = null;
  } else if (publishSuccessful) {
    actions = successActions;
  }

  const appname = (match && match.params && match.params.appname) || title;

  return (
    <Dialog
      maxWidth={'sm'}
      title={`${t('Publish')} ${appname}`}
      actions={actions}
      open={open}
      modal
      autoScrollBodyContent
      style={{ textAlign: 'center' }}
      actionsContainerStyle={{ textAlign: 'center' }}
      contentClassName="customModal"
      paperClassName="customModalPaper"
    >
      {!requestingPublish && !publishSuccessful && (
        <p>
          <Trans>
            Hold up! Are you sure you want to publish these changes? You can’t
            undo this (but you can make more changes afterwards).
          </Trans>
        </p>
      )}
      {(requestingPublish || publishing) && (
        <div>
          <p>
            <Trans>We’re building your app. This won’t take long...</Trans>
          </p>
          <LinearProgress color="#261ed5" mode="indeterminate" />
        </div>
      )}
      {publishSuccessful && (
        <div>
          <p>
            <Trans>Boom. Your app is live. See it in action</Trans> &nbsp;
            <a href={url} rel="noopener noreferrer" target="_blank">
              {url}
            </a>
          </p>
        </div>
      )}
    </Dialog>
  );
};

PublishDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  match: PropTypes.object.isRequired,
  requestingPublish: PropTypes.bool.isRequired,
  publishSuccessful: PropTypes.bool.isRequired,
  publishing: PropTypes.bool.isRequired,
  handlePublish: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCloseAfterPublish: PropTypes.func.isRequired,
  customDomain: PropTypes.object,
};

PublishDialog.defaultProps = {
  title: null,
  customDomain: null,
};

export default PublishDialog;
