import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Register from './Register';
import { registerUser, resetRegistrationForm, loginUser } from '../actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.account.register,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: cb => {
    dispatch(
      registerUser(
        cb,
        queryString.parse(ownProps.location.search).referrer,
        null,
        queryString.parse(ownProps.location.search).ref &&
          queryString.parse(ownProps.location.search),
      ),
    );
  },
  onLeave: () => dispatch(resetRegistrationForm()),
  onLogin: (email, password, cb) => dispatch(loginUser(email, password, cb)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Register),
);
