import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RaisedButton } from '../../../../components/buttons';

import Standard from './Standard';
import themeStyles from '../../../../../_export.scss';

const styles = {
  buttonRoot: {
    margin: '15px auto',
    width: '200px',
  },
  labelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.primaryTextColor,
    textTransform: 'capitalize',
  },
};

const OfferNotice = ({ content, onRequestClose, history }) => {
  const { t } = useTranslation();

  const closeAndRedirect = () => {
    onRequestClose();
    history.push(
      history.location.pathname.replace('editor/build', 'choose_plan'),
    );
  };

  return (
    <Standard
      title={t('Welcome to Beezer')}
      content={content}
      onRequestClose={onRequestClose}
    >
      <RaisedButton
        label={t("Let's have a look!")}
        backgroundColor={themeStyles.primaryColor}
        disabledBackgroundColor={themeStyles.primaryColorLight}
        style={styles.buttonRoot}
        labelStyle={styles.labelStyle}
        onClick={closeAndRedirect}
      />
    </Standard>
  );
};

OfferNotice.propTypes = {
  content: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

OfferNotice.defaultProps = {
  content: '',
};

export default withRouter(OfferNotice);
