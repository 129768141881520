import React from 'react';

const ForumIcon = () => (
  <svg
    height="512pt"
    viewBox="0 0 484.366 484.366"
    width="512pt"
    className="icon"
  >
    <g>
      <path
        d="M483.629,457.833l-7.731-38.872c-3.07-15.4-15.117-27.43-30.517-30.478l-10.474-2.07
		c-6.014-1.188-10.738-5.866-11.998-11.881l-1.982-9.621c-0.647-3.079,0.472-6.269,2.895-8.275
		c9.449-7.826,18.095-19.354,23.133-32.958c6.945-1.253,13.291-7.937,15.338-17.141c2.52-11.401-2.504-22.218-11.211-24.156
		c-0.111-0.015-0.221-0.008-0.316-0.015c-2.628-29.613-14.03-51.54-51.964-51.54c-37.288,0-49.13,21.919-51.917,51.523
		c-0.111,0.023-0.236,0.017-0.362,0.031c-8.724,1.939-13.732,12.755-11.212,24.156c2.046,9.204,8.377,15.888,15.338,17.141
		c5.039,13.612,13.7,25.132,23.131,32.966c2.426,1.999,3.545,5.189,2.914,8.259l-2,9.622c-1.244,6.015-5.968,10.7-12,11.896
		l-10.471,2.063c-15.401,3.048-27.446,15.078-30.518,30.478l-7.732,38.872c-1.29,6.52,0.396,13.282,4.615,18.416
		c4.22,5.141,10.518,8.117,17.163,8.117h126.101c6.647,0,12.945-2.977,17.165-8.117
		C483.236,471.115,484.921,464.353,483.629,457.833z"
      />
      <path
        d="M162.662,418.961c-3.071-15.4-15.116-27.43-30.518-30.478l-10.472-2.07c-6.016-1.188-10.738-5.866-11.999-11.881
		l-1.984-9.621c-0.646-3.079,0.473-6.269,2.898-8.275c9.447-7.826,18.092-19.354,23.131-32.958
		c6.945-1.253,13.292-7.937,15.338-17.141c2.52-11.401-2.504-22.218-11.212-24.156c-0.109-0.015-0.221-0.008-0.314-0.015
		c-2.63-29.613-14.032-51.54-51.966-51.54c-37.288,0-49.13,21.919-51.916,51.523c-0.111,0.023-0.237,0.017-0.363,0.031
		c-8.724,1.939-13.731,12.755-11.212,24.156c2.048,9.204,8.377,15.888,15.339,17.141c5.038,13.612,13.699,25.132,23.133,32.966
		c2.423,1.999,3.542,5.189,2.911,8.259l-1.999,9.622c-1.245,6.015-5.968,10.7-11.999,11.896l-10.473,2.063
		c-15.399,3.048-27.446,15.078-30.516,30.478l-7.733,38.872c-1.292,6.52,0.394,13.282,4.613,18.416
		c4.22,5.141,10.519,8.117,17.165,8.117h126.101c6.646,0,12.943-2.977,17.163-8.117c4.22-5.134,5.905-11.896,4.615-18.416
		L162.662,418.961z"
      />
      <path
        d="M334.491,253.329c1.718-1.686,2.017-4.348,0.74-6.37L317.5,210.78h24.612c12.692,0,22.959-10.267,22.959-22.958V83.773
		c0-12.691-10.267-22.958-22.959-22.958H284.92v66.199c0,24.07-19.574,43.643-43.65,43.643h-67.995l-17.637,11.787v5.378
		c0,12.691,10.282,22.958,22.958,22.958h74.279l75.206,43.146C330.097,255.257,332.775,255.014,334.491,253.329z"
      />
      <path
        d="M77.753,149.974h24.613L84.21,186.404c-1.307,2.032-0.977,4.7,0.74,6.377c0.977,0.962,2.252,1.448,3.559,1.448
		c0.993,0,1.969-0.282,2.835-0.857l75.647-43.398h74.278c12.676,0,22.959-10.291,22.959-22.959V22.959
		C264.229,10.283,253.946,0,241.27,0H77.753C65.062,0,54.795,10.283,54.795,22.959v104.056
		C54.795,139.683,65.062,149.974,77.753,149.974z M205.304,57.815c9.48,0,17.18,7.685,17.18,17.172c0,9.479-7.7,17.164-17.18,17.164
		c-9.48,0-17.165-7.685-17.165-17.164C188.139,65.5,195.824,57.815,205.304,57.815z M159.511,57.815
		c9.479,0,17.165,7.685,17.165,17.172c0,9.479-7.686,17.164-17.165,17.164c-9.479,0-17.18-7.685-17.18-17.164
		C142.332,65.5,150.033,57.815,159.511,57.815z M113.704,57.815c9.513,0,17.181,7.685,17.181,17.172
		c0,9.479-7.668,17.164-17.181,17.164c-9.464,0-17.163-7.685-17.163-17.164C96.541,65.5,104.24,57.815,113.704,57.815z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default ForumIcon;
