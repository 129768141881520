import tinycolor from 'tinycolor2';

import firebase from '../../../../../../firebase';

// eslint-disable-next-line
export const handleColorChange = (appname, value, prop) => {
  const color = tinycolor(value, { format: 'rgba' });
  const lighter = color.lighten().toString();
  const darker = color.darken().toString();
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/design_data/palette`);

  dataRef.child(prop).set(value);
  dataRef.child(`${prop}_light`).set(lighter);
  dataRef.child(`${prop}_dark`).set(darker);

  return {
    type: 'COLOR_CHANGE',
    prop,
    value,
  };
};
