import React from 'react';
import PropTypes from 'prop-types';
import TouchRipple from 'material-ui/internal/TouchRipple';

import './styles.css';

const CardContent = ({ children, isSelectable }) => {
  if (isSelectable) {
    return <TouchRipple>{children}</TouchRipple>;
  }
  return <div>{children}</div>;
};

CardContent.propTypes = {
  children: PropTypes.node,
  isSelectable: PropTypes.bool,
};

CardContent.defaultProps = {
  children: null,
  isSelectable: true,
};

const SelectableCard = ({
  active,
  onClick,
  children,
  boxShadowOnHover,
  comingSoon,
  disabled,
  disabledIcon,
  disabledNotice,
  grayOut,
  highlightBorder,
  isSelectable,
  className,
}) => {
  if (!disabled) {
    return (
      <button
        className={`selectable-card
          ${active ? ' active' : ''}
          ${boxShadowOnHover ? ' box-shadow-on-hover' : ''}
          ${highlightBorder ? ' highlight-border' : ''}
          ${isSelectable ? '' : ' not-selectable'}
          ${grayOut ? ' disabled' : ''}
          ${className ? className : ''}`}
        onClick={onClick}
        disabled={!isSelectable}
      >
        <CardContent isSelectable={isSelectable}>{children}</CardContent>
      </button>
    );
  }

  return (
    <div
      className={`selectable-card
        ${active ? ' active' : ''}
        ${boxShadowOnHover ? ' box-shadow-on-hover' : ''}
        ${comingSoon ? 'coming-soon' : ''}
        disabled`}
    >
      <TouchRipple>{children}</TouchRipple>
      {disabledIcon}
      {disabledNotice}
    </div>
  );
};

SelectableCard.propTypes = {
  active: PropTypes.bool,
  boxShadowOnHover: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  comingSoon: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledIcon: PropTypes.node,
  disabledNotice: PropTypes.node,
  grayOut: PropTypes.bool,
  highlightBorder: PropTypes.bool,
  isSelectable: PropTypes.bool,
};

SelectableCard.defaultProps = {
  active: false,
  boxShadowOnHover: false,
  onClick: () => ({}),
  children: null,
  comingSoon: false,
  disabled: false,
  disabledIcon: null,
  disabledNotice: null,
  grayOut: false,
  highlightBorder: false,
  isSelectable: true,
};

export default SelectableCard;
