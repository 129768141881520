import React from 'react';
import { Route, Switch } from 'react-router-dom';

import EditCPDModal from './module';

function EditCPDModalRouter() {
  return (
    <Switch>
      <Route path="/:appname/staff/cpds/:memberbody/:cpd?" exact>
        {props => <EditCPDModal {...props} />}
      </Route>
      <Route path="/:appname/members/cpds/:memberbody/:cpd?" exact>
        {props => <EditCPDModal {...props} />}
      </Route>
    </Switch>
  );
}

export default EditCPDModalRouter;
