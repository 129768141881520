import styled from 'styled-components';
import { red500 } from 'material-ui/styles/colors';

const Warning = styled.p`
  color: ${red500};
  font-size: 13px;
  padding-left: 18px;
  padding-right: 18px;
`;

export default Warning;
