import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import RoundedThin from '../roundedThin';
import { getAppUrl } from '../../../../utils/appDetails';
import TooltipElement from '../../tooltipElement';

const ViewApp = props => {
  const customDomain = useSelector(({ app }) => app && app.customDomain) || '';

  const response = props.isPublished ? (
    <a
      className="app-link"
      href={getAppUrl(props.match.params.appname, customDomain)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <RoundedThin className="publish test-btn">
        <Trans>View App</Trans>
      </RoundedThin>
    </a>
  ) : (
    /* eslint-disable-next-line */
    <a className="app-link flex-row justify-center flex-align-center" disabled>
      <TooltipElement
        tooltip={'You have not published your app yet'}
        verticalPosition="top"
        horizontalPosition="right"
      />
      <RoundedThin className="publish test-btn">
        <Trans>View App</Trans>
      </RoundedThin>
    </a>
  );
  return response;
};

export default ViewApp;
