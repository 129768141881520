import FormData from 'form-data';
import i18n from 'i18next';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';
import firebase from '../../../../../../../firebase';

export const fieldChange = (appname, dataId, field, value, index) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}/files/${index}`);
  dataRef.child(field).set(value);

  return {
    type: 'TEXT_VALUE_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

const PDFUploadStart = () => ({
  type: 'PDF_UPLOAD_START',
});

const PDFUploadSuccess = () => ({
  type: 'PDF_UPLOAD_SUCCESS',
});

const PDFUploadError = message => ({
  type: 'PDF_UPLOAD_FAILURE',
  message,
});

export const deleteFile = (firebaseUrl, filename) => dispatch => {
  const user = firebase.auth().currentUser;
  const myHeaders = new Headers();

  return user
    .getIdToken()
    .then(token => {
      // dispatch(PDFUploadStart());
      myHeaders.append('x-access-token', token);
      myHeaders.append('Content-Type', 'application/json');

      return fetch(`${process.env.REACT_APP_IMAGES_API}/delete_pdf_file`, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify({ firebaseUrl, filename }),
        mode: 'cors',
      });
    })
    .then(res => res.json())
    .then(res => {
      if (res.error) {
        dispatch(
          openSnackbar(
            i18n.t(
              'An error occurred while deleting your file, Please try again.',
            ),
          ),
        );
      } else {
        dispatch(openSnackbar(i18n.t('File deleted successfully')));
      }
    })
    .catch(error => {
      console.log(error);
      dispatch(
        openSnackbar(
          i18n.t(
            'An error occurred while deleting your file, Please try again.',
          ),
        ),
      );
    });
};

export const uploadFiles = (
  files,
  firebaseUrl,
  appname,
  setUploadDialogOpen,
  setFilesList,
) => dispatch => {
  const form = new FormData();

  console.log(files, firebaseUrl, '>>>', appname);
  const user = firebase.auth().currentUser;
  const myHeaders = new Headers();

  form.append('firebaseUrl', firebaseUrl);
  form.append('appname', appname);
  files.forEach(file => form.append('files', file));

  return user
    .getIdToken()
    .then(token => {
      dispatch(PDFUploadStart());
      myHeaders.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_IMAGES_API}/upload_multiple_pdf`, {
        method: 'post',
        headers: myHeaders,
        body: form,
        mode: 'cors',
      });
    })
    .then(res => res.json())
    .then(res => {
      if (res.error) {
        dispatch(PDFUploadError(res.error));
        dispatch(
          openSnackbar(
            i18n.t(
              'An error occurred while uploading your files, Please try again.',
            ),
          ),
        );
      } else {
        dispatch(PDFUploadSuccess());
        dispatch(openSnackbar(i18n.t('All PDF files successfully uploaded.')));
      }
    })
    .catch(error => {
      dispatch(
        openSnackbar(
          i18n.t(
            'An error occurred while uploading your files, Please try again.',
          ),
        ),
      );
      dispatch(PDFUploadError(error.message));
    })
    .finally(() => {
      setUploadDialogOpen(false);
      setFilesList([]);
    });
};
