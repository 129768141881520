import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from '@material-ui/core';
import { Trans, withTranslation } from 'react-i18next';

import TextField from '../../../../app/components/textField';
import ImageUpload from '../../../editor/internals/imageUpload';
import Subheader from '../../../../app/components/subheader';
import Panel from '../../../../app/components/panel';
import Container from '../../../../app/components/container';
import { CircularProgress } from '../../../../app/components/progress';
import CircularValidation from '../../../../app/components/circularValidation';
import PushNotification from '../../components/pushNotification';
import NotificationLinkMenu from '../../components/notificationLinkMenu';
import NotificationScheduleMenu from '../../components/notificationScheduleMenu';
import SectionHeader from '../../../editor/components/sectionHeader';
import { getAppUrl } from '../../../../utils/appDetails';
import TooltipElement from '../../../../app/components/tooltipElement';
import PushDisabledWrapper from './PushDisabledWrapper';

import './styles.css';
import BeezerRadioButton from '../../../../app/components/radiobutton/RadioButton';
import UsersTable from '../../components/usersTable/UsersTable';
import GroupsTable from '../../components/groupsTable/GroupsTable';
import LocationFilter from '../../components/locationFilter/LocationFilter';
import UpgradeDialog from '../../../memberLogin/modules/upgradeDialog';

class Push extends Component {
  static propTypes = {
    onInstalls: PropTypes.func.isRequired,
    installs: PropTypes.number.isRequired,

    getUsers: PropTypes.func.isRequired,
    filtered: PropTypes.bool.isRequired,
    onEnableFilter: PropTypes.func.isRequired,
    onDisableFilter: PropTypes.func.isRequired,
    getGroups: PropTypes.func.isRequired,

    onFiltersChange: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,

    onPlayerIdChange: PropTypes.func.isRequired,
    playerIds: PropTypes.array.isRequired,

    onSelectedFilterChange: PropTypes.func.isRequired,
    selectedFilter: PropTypes.string.isRequired,
    componentsPending: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    getRecipients: PropTypes.func.isRequired,
    loadContent: PropTypes.func.isRequired,
    loadAppContent: PropTypes.func.isRequired,
    integration: PropTypes.object,
    link: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onLinkChange: PropTypes.func.isRequired,
    pages: PropTypes.array.isRequired,
    recipients: PropTypes.number,
    resetInputs: PropTypes.func.isRequired,
    subscription: PropTypes.object,
    title: PropTypes.object.isRequired,
    pushImage: PropTypes.string,
    history: PropTypes.object.isRequired,
    onAddNotificationComponent: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
    scheduledTime: PropTypes.object,
    onToggleChange: PropTypes.func.isRequired,
    isScheduled: PropTypes.bool.isRequired,
    textColor: PropTypes.object,
    topBarColor: PropTypes.object,
    appname: PropTypes.string,
    customDomain: PropTypes.object,
    onGetCountries: PropTypes.func.isRequired,
    filteredCountries: PropTypes.array.isRequired,
    onGetStates: PropTypes.func.isRequired,
    filteredStates: PropTypes.array.isRequired,
    onGetCities: PropTypes.func.isRequired,
    filteredCities: PropTypes.array.isRequired,
    isAppAdmin: PropTypes.bool,
    isWixUser: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    recipients: null,
    installs: undefined,
    integration: null,
    subscription: {},
    customDomain: {},
    scheduledTime: null,
    appname: '',
    isAppAdmin: false,
    isWixUser: false,
  };

  state = {
    view: 'mobile',
    enableInfoDialog: false,
  };

  toggleInfoDialog = () => {
    this.setState({ enableInfoDialog: !this.state.enableInfoDialog });
  };

  async componentDidMount() {
    this.props.onInstalls();
    this.props.getRecipients();
    this.props.loadContent();
    this.props.getUsers();
    this.props.getGroups();
    this.props.loadAppContent();
  }

  componentWillUnmount() {
    this.props.resetInputs();
  }

  checkIfValid = text => {
    if (!text.value) {
      return null;
    } else if (!text.isValid) {
      return false;
    }
    return true;
  };

  render() {
    const {
      installs,
      componentsPending,
      enabled,
      integration,
      link,
      match,
      message,
      onChange,
      onLinkChange,
      pages,
      recipients,
      subscription,
      title,
      onAddNotificationComponent,
      history,
      pushImage,
      textColor,
      topBarColor,
      appname,
      customDomain,
      onGetCountries,
      filteredCountries,
      onGetStates,
      filteredStates,
      onGetCities,
      filteredCities,
      isAppAdmin,
      isWixUser,
      appSettings,
      t,
    } = this.props;

    const containerHeight = { height: 'calc(100% - 166px)' };
    const infoDialog = (
      <Dialog
        open={this.state.enableInfoDialog}
        onClose={this.toggleInfoDialog}
      >
        <DialogTitle>
          <Trans>How push notifications work for your end users?</Trans>
        </DialogTitle>
        <DialogContent>
          <p>
            <Trans>
              In order to receive Push Notifications, the end user needs to
              first give permission.
            </Trans>
          </p>
          <p>
            <Trans>
              When the end user opens the app for the first time on their mobile
              device, they will be prompted to allow Push Notifications from the
              app. If the end user allows Push Notifications, they will be
              receiving them every time when you send one. If they do not allow
              push notifications the end user will then only receive in-app
              notifications.
            </Trans>
          </p>
          <p>
            <Trans>
              Notifications can include rich media, be scheduled and also be
              filtered by user, group or location.
            </Trans>
          </p>
          <b style={{ margin: 0 }}>
            <Trans>
              Note: Currently iOS does not support push notifications via a PWA.
              For that reason all iOS users will receive in-app notifications.
              Instead of a Push Notification, you can choose to send a SMS
              notification to IOS users by enabling the Engage via SMS in the
              SMS section on the left hand side.
            </Trans>
          </b>
        </DialogContent>

        {!isAppAdmin && (
          <DialogActions>
            <Button
              href="https://beezer.zendesk.com/hc/en-us/articles/360021351731-Notification-Component"
              target="_blank"
              rel="noopener"
            >
              <Trans>Know More</Trans>
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );

    const content = (
      <div style={containerHeight} className="notification-panel">
        <div className="row middle-xs">
          <div className="col-xs-3">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Subheader title={t('New Notifications: ')} />
              <IconButton
                color="primary"
                aria-label={t('information')}
                component="span"
                onClick={this.toggleInfoDialog}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </span>
          </div>
        </div>
        <Panel style={{ position: 'relative' }}>
          <div className="row">
            <div
              className="col-xs-6 notification-container__scrollable scroll-container"
              // style={{ overflow: 'scroll', height: '900px' }}
            >
              <Container>
                <div className="push-message-container">
                  <UpgradeDialog />
                  <SectionHeader title={t('Push Notification Message')} />
                  <TextField
                    id="title"
                    placeholder={t('Title')}
                    {...title}
                    maxLength={60}
                    showCounter
                    onChange={value => onChange('title', value)}
                    required
                    rightInputAction={
                      <CircularValidation isValid={this.checkIfValid(title)} />
                    }
                  />
                  <TextField
                    id="message"
                    placeholder={t('Message')}
                    {...message}
                    maxLength={280}
                    showCounter
                    onChange={value => onChange('message', value)}
                    required
                    multiLine
                    rightInputAction={
                      <CircularValidation
                        isValid={this.checkIfValid(message)}
                      />
                    }
                  />
                  <ImageUpload
                    title={t('Image for push notification')}
                    src={pushImage}
                    firebaseUrl={`/apps/${match.params.appname}/temp_data/pushImage`}
                    showCropAreaSizes
                    height="220px"
                    width="320px"
                  />
                </div>
                <NotificationLinkMenu
                  link={link}
                  pages={pages}
                  isPageAllowed={!integration}
                  onChange={onLinkChange}
                  subscription={subscription}
                />

                <NotificationScheduleMenu
                  link={link}
                  pages={pages}
                  isPageAllowed={!integration}
                  date={this.props.scheduledTime}
                  onChange={timestamp => {
                    this.props.onDateChange(timestamp);
                  }}
                  isToggled={this.props.isScheduled}
                  onToggle={isToggled => {
                    this.props.onToggleChange(isToggled);
                  }}
                  subscription={subscription}
                  recipients={recipients}
                />

                {!isWixUser && (
                  <>
                    <UsersTable
                      onSelectedFilterChange={this.props.onSelectedFilterChange}
                      selectedFilter={this.props.selectedFilter}
                      playerIds={this.props.playerIds}
                      filters={this.props.filters}
                      onFiltersChange={this.props.onFiltersChange}
                      filtered={this.props.filtered}
                      items={this.props.items}
                      users={this.props.chunks}
                      onFilteredListChange={this.props.onFilteredListChange}
                      onEnableFilter={this.props.onEnableFilter}
                      onDisableFilter={this.props.onDisableFilter}
                    />

                    <GroupsTable
                      onSelectedFilterChange={this.props.onSelectedFilterChange}
                      selectedFilter={this.props.selectedFilter}
                      playerIds={this.props.playerIds}
                      onPlayerIdChange={this.props.onPlayerIdChange}
                      groupsFiltered={this.props.groupsFiltered}
                      groupsItems={this.props.groupsItems}
                      groups={this.props.groupsChunks}
                      onFilteredGroupsListChange={
                        this.props.onFilteredGroupsListChange
                      }
                      onEnableGroupsFilter={this.props.onEnableGroupsFilter}
                      onDisableGroupsFilter={this.props.onDisableGroupsFilter}
                      users={this.props.chunks}
                    />
                  </>
                )}

                <LocationFilter
                  onSelectedFilterChange={this.props.onSelectedFilterChange}
                  selectedFilter={this.props.selectedFilter}
                  onFiltersChange={this.props.onFiltersChange}
                  locationFiltered={this.props.locationFiltered}
                  onFilteredLocationListChange={
                    this.props.onFilteredLocationListChange
                  }
                  onEnableLocationFilter={this.props.onEnableLocationFilter}
                  onDisableLocationFilter={this.props.onDisableLocationFilter}
                  onGetCountries={onGetCountries}
                  filteredCountries={filteredCountries}
                  onGetStates={onGetStates}
                  filteredStates={filteredStates}
                  onGetCities={onGetCities}
                  filteredCities={filteredCities}
                  appname={appname}
                />
              </Container>
            </div>
            <div className="col-xs-6 notification-preview-wrapper">
              <BeezerRadioButton
                id="previewButton"
                className="preview-toggle"
                label1={t('Mobile')}
                label2={t('Desktop')}
                onChange={() => {
                  this.setState({
                    view: this.state.view === 'mobile' ? 'desktop' : 'mobile',
                  });
                }}
                checked={this.state.view !== 'mobile'}
              />
              <PushNotification
                url={getAppUrl(match.params.appname, customDomain, false)}
                title={title.value}
                message={message.value}
                view={this.state.view}
                image={pushImage}
                textColor={textColor}
                topBarColor={topBarColor}
                appname={appname}
                appSettings={appSettings}
              />
              <div className="recipients-container">
                <div className="num-recipients">
                  {recipients === null ? (
                    <CircularProgress
                      style={{ margin: '18px' }}
                      thickness={7}
                    />
                  ) : (
                    <strong>{recipients}</strong>
                  )}
                  <br />
                  <Trans>Recipient(s) of Push Notifications</Trans>
                </div>
                <TooltipElement
                  tooltip={t(
                    'Number of users who have agreed in app to allow push notifications.',
                  )}
                  className="push__tooltip"
                />
                <div className="num-recipients">
                  {installs === undefined ? (
                    <CircularProgress
                      style={{ margin: '18px' }}
                      thickness={7}
                    />
                  ) : (
                    <strong>{installs}</strong>
                  )}
                  <br />
                  <Trans>Recipient(s) of In-App Notifications</Trans>
                </div>
                <TooltipElement
                  tooltip={t(
                    'Number of users who will receive in-app notifications. Includes recipients of Push Notifications.',
                  )}
                  className="push__tooltip"

                  // iconStyle={{ marginLeft: -24 }}
                />
              </div>
            </div>
          </div>
        </Panel>
      </div>
    );
    return !componentsPending ? (
      enabled ? (
        <>
          {content}
          {infoDialog}
        </>
      ) : (
        <PushDisabledWrapper
          history={history}
          onAddNotificationComponent={onAddNotificationComponent}
          appname={match.params.appname}
        >
          {content}
        </PushDisabledWrapper>
      )
    ) : (
      <CircularProgress />
    );
  }
}

export default withTranslation()(Push);
