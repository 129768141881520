import i18n from 'i18next';
import firebase from '../../../../../../../../../firebase';
import { validateField } from '../../../validators';
import { getAppAdmins } from '../../../actions';
import { openSnackbar } from '../../../../../../../../../app/modules/snackbar/actions';
import { PROFILE_UPDATED } from '../../../config';

export const EDIT_ADMIN_FIELD_CHANGE = 'EDIT_ADMIN_FIELD_CHANGE';
export const OPEN_EDIT_ADMIN_DIALOG = 'OPEN_EDIT_ADMIN_DIALOG';
export const CLOSE_EDIT_ADMIN_DIALOG = 'CLOSE_EDIT_ADMIN_DIALOG';
export const ADMIN_VALIDATION_ERROR = 'ADMIN_VALIDATION_ERROR';
export const ADMIN_NOT_AVAILABLE = 'ADMIN_NOT_AVAILABLE';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const LOADING_ADMIN = 'LOADING_ADMIN';
export const LOADED_ADMIN_DETAILS = 'LOADED_ADMIN_DETAILS';
export const ADMIN_DETAILS_UPDATE_REQUEST = 'ADMIN_DETAILS_UPDATE_REQUEST';
export const ADMIN_DETAILS_UPDATED = 'ADMIN_DETAILS_UPDATED';
export const UPDATE_ADMIN_SECTIONS_ACCESS = 'UPDATE_ADMIN_SECTIONS_ACCESS';

const fieldChange = (prop, value, isValid) => ({
  type: EDIT_ADMIN_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

export const closeDialog = () => ({ type: CLOSE_EDIT_ADMIN_DIALOG });

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;

  return dispatch(fieldChange(prop, value, isValid));
};

export const validationError = prop => ({
  type: ADMIN_VALIDATION_ERROR,
  prop,
});

export const registrationError = err => ({
  type: REGISTRATION_ERROR,
  err,
});

export const loadingAdmin = () => ({
  type: LOADING_ADMIN,
});

export const loadAdmin = prop => ({
  type: LOADED_ADMIN_DETAILS,
  prop,
});

export const closeModal = (app, history) => dispatch => {
  dispatch(closeDialog());
  history.push(`/${app}/editor/build/admins`);
};

export const getAdminDetails = admin => (dispatch, getState) => {
  dispatch(loadingAdmin());
  const { chunks } = getState().editor.build.appAdmins.data;
  const adminDetails = chunks[admin];

  if (adminDetails && adminDetails.adminAppname) {
    const data = {
      firstName: { value: adminDetails.firstName, isValid: true },
      lastName: { value: adminDetails.lastName, isValid: true },
      email: { value: adminDetails.email, isValid: true },
      adminAppname: {
        value: adminDetails.adminAppname.split(','),
        isValid: true,
      },
      adminSMSCredits: { value: adminDetails.adminSMSCredits, isValid: true },
      adminWhatsappSMSCredits: {
        value: adminDetails.adminWhatsappSMSCredits,
        isValid: true,
      },
      sectionsAccess: adminDetails.sectionsAccess || {
        build: true,
        distribute: true,
        engage: true,
        analytics: true,
        member_login: true,
        business_tools: true,
      },
      loading: false,
    };
    dispatch(loadAdmin(data));
  }
};

export const updateAccount = (admin, history, app) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: ADMIN_DETAILS_UPDATE_REQUEST });
  const {
    firstName,
    lastName,
    adminAppname,
    creditsToAdd,
    whatsappCreditsToAdd,
    sectionsAccess,
  } = getState().editor.build.appAdmins.editAdmin;
  const adminAccount = getState().account.organisation.key;
  const appNames = adminAppname.value.filter(item => item !== '');
  const update = {
    firstName: firstName.value,
    lastName: lastName.value,
    ...(appNames.length === 1
      ? { admin_appname: appNames[0] }
      : { admin_appnames: Object.assign({}, appNames) }),
    lastUpdated: Date.now(),
  };

  const appAdminAccountSnap = await firebase
    .database()
    .ref(`users/${admin}/account`)
    .once('value');
  const appAdminAccount =
    (appAdminAccountSnap.exists() && appAdminAccountSnap.val()) || null;
  let appAdminCredits = 0;
  let appAdminWhatsappCredits = 0;

  firebase
    .database()
    .ref(`accounts/${adminAccount}/sms_credit`)
    .transaction(smsCredits => {
      if (smsCredits >= creditsToAdd.value) {
        appAdminCredits = creditsToAdd.value;
        return Number(smsCredits) - Number(creditsToAdd.value);
      }
      return smsCredits;
    })
    .then(() => {
      firebase
        .database()
        .ref(`accounts/${appAdminAccount}/sms_credit`)
        .transaction(smsCredits => {
          if (!smsCredits) {
            return appAdminCredits;
          }
          return Number(smsCredits) + Number(appAdminCredits);
        });
    });

  firebase
    .database()
    .ref(`accounts/${adminAccount}/whatsapp_sms_credit`)
    .transaction(whatsappCredits => {
      if (whatsappCredits >= whatsappCreditsToAdd.value) {
        appAdminWhatsappCredits = whatsappCreditsToAdd.value;
        return Number(whatsappCredits) - Number(whatsappCreditsToAdd.value);
      }
      return Number(whatsappCredits);
    })
    .then(() => {
      firebase
        .database()
        .ref(`accounts/${appAdminAccount}/whatsapp_sms_credit`)
        .transaction(whatsappCredits => {
          if (!whatsappCredits) {
            return Number(appAdminWhatsappCredits);
          }
          return Number(whatsappCredits) + Number(appAdminWhatsappCredits);
        });
    });

  const userRef = firebase.database().ref(`users/${admin}`);
  userRef.once('value', snapshot => {
    const data = snapshot.val();
    if (appNames.length > 1 && 'admin_appname' in data) {
      delete data.admin_appname;
    }

    if (appNames.length === 1 && 'admin_appnames' in data) {
      delete data.admin_appnames;
    }

    const details = {
      ...data,
      firstName: firstName.value,
      lastName: lastName.value,
      lastUpdated: Date.now(),
      ...(appNames.length === 1
        ? { admin_appname: appNames[0] }
        : { admin_appnames: Object.assign({}, appNames) }),
      lastVisited: appNames[0],
      sectionsAccess,
    };

    const apps = appNames.reduce((obj, item) => {
      obj[item] = { title: item };
      return obj;
    }, {});

    userRef.set(details);

    firebase
      .database()
      .ref(`accounts/${data.account}/apps`)
      .set(apps)
      .then(() => {
        dispatch({
          type: ADMIN_DETAILS_UPDATED,
          key: admin,
          data: update,
        });
        dispatch(getAppAdmins());
        dispatch(closeModal(app, history));
        dispatch(openSnackbar(i18n.t('The App admin has been updated.')));
      });
  });
};

export const updateSubAdminSectionAccess = (sectionName, value) => dispatch => {
  try {
    dispatch({ type: UPDATE_ADMIN_SECTIONS_ACCESS, field: sectionName, value });
  } catch (error) {
    console.log(error);
  }
};
