import _keys from 'lodash/keys';
import _pickBy from 'lodash/pickBy';
import _union from 'lodash/union';
import _mapValues from 'lodash/mapValues';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import { combineReducers } from 'redux';

import {
  REQUEST_PUSH_HISTORY,
  RECEIVE_PUSH_HISTORY,
  REPORT_PUSH_HISTORY_REQUEST_ERROR,
  UPDATE_NOTIFICATION_VISIBILITY,
  FILTER_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  SELECT_NOTIFICATION,
  SELECT_ALL_NOTIFICATIONS,
  DESELECT_ALL_NOTIFICATIONS,
  SORT_NOTIFICATIONS,
  CANCEL_PUSH_SUCCESS,
} from './actions';
import historyDetails from './historyDetails/reducer';

const initialState = {
  pending: false,
  chunks: {},
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  selected: [],
  selectAll: false,
  order: 'desc',
  colSorted: 'date',
};

const sortItems = (filteredChunks, sortBy, order) =>
  _keys(filteredChunks).sort((a, b) => {
    let keyA = filteredChunks[a][sortBy];
    let keyB = filteredChunks[b][sortBy];
    if (sortBy === 'date') {
      keyA = new Date(filteredChunks[a][sortBy]);
      keyB = new Date(filteredChunks[b][sortBy]);
    }
    if (order === 'desc') {
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
    } else if (order === 'asc') {
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
    }
    return 0;
  });

const filterChunks = (chunks, filter = '') => {
  let filteredChunks = chunks || {};

  if (filter) {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`(${filter})`, 'g');
      return rgx.test(chunk.message) || rgx.test(chunk.title);
    });
  }
  return { filteredChunks };
};

const isVisible = item => {
  if (item.visible === undefined) {
    return false;
  } else if (item.visible === true) {
    return false;
  } else if (item.visible === false) {
    return true;
  }
  return item.visible;
};

const pushHistory = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PUSH_HISTORY:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_PUSH_HISTORY: {
      return {
        ...state,
        pending: false,
        chunks: action.data,
        ...filterChunks(action.data, state.filter),
        filter: action.filter,
        items: sortItems(action.data, state.colSorted, state.order),
        error: null,
      };
    }

    case REPORT_PUSH_HISTORY_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.err,
      };

    case UPDATE_NOTIFICATION_VISIBILITY: {
      const chunks = {
        ...state.chunks,
      };
      action.selected.forEach(key => {
        chunks[key] = {
          ...state.chunks[key],
          visible: isVisible(chunks[key]),
        };
      });
      return {
        ...state,
        chunks,
        ...filterChunks(chunks, state.filter),
        selectAll: false,
        selected: [],
      };
    }

    case FILTER_NOTIFICATIONS: {
      const { filteredChunks } = filterChunks(state.chunks, action.payload);
      return {
        ...state,
        filter: action.payload,
        ...filteredChunks,
        items: sortItems(filteredChunks, state.colSorted, state.order),
      };
    }

    case SELECT_NOTIFICATION: {
      const prevState = state.filteredChunks[action.key].selected || false;

      const selected = state.selected ? state.selected : [];

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(selected, action.key)
            : selected.concat(action.key),
      };
    }

    case SELECT_ALL_NOTIFICATIONS: {
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: true,
      }));
      const selected = _union(state.selected, _keys(filteredChunks));

      return {
        ...state,
        filteredChunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_NOTIFICATIONS:
      return {
        ...state,
        filteredChunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    case DELETE_NOTIFICATION: {
      let items = state.items;
      action.selected.forEach(key => {
        items = _without(items, key);
      });
      return {
        ...state,
        items,
        selected: [],
        selectAll: false,
      };
    }

    case SORT_NOTIFICATIONS:
      return {
        ...state,
        order: state.order === 'desc' ? 'asc' : 'desc',
        items: sortItems(
          state.filteredChunks,
          action.column.id,
          state.order === 'desc' ? 'asc' : 'desc',
        ),
        colSorted: action.column.id,
      };

    case CANCEL_PUSH_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default combineReducers({
  data: pushHistory,
  historyDetails,
});
