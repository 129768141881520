import React from 'react';
import { Route, Switch } from 'react-router-dom';
import IconButton from 'material-ui/IconButton';
import _map from 'lodash/map';

import { stringToKey } from '../../../../../../utils/string';
import {
  AppIcon,
  AppDomain,
  SplashScreen,
  AppMenu,
} from '../../../../components/icons';

const routes = [
  { ref: 'App Icon', icon: <AppIcon /> },
  { ref: 'App Name', icon: <AppDomain /> },
  { ref: 'App Splash Screen', icon: <SplashScreen /> },
  { ref: 'Navigation Menu', icon: <AppMenu /> },
];

const buildRouteOptions = () =>
  _map(routes, route => {
    const link = stringToKey(route.ref);
    return (
      <Route
        key={link}
        path={`/:appname/editor/customise/${link}`}
        render={() => (
          <div>
            <IconButton className="customise-menu-icon" disabled>
              {route.icon}
            </IconButton>
            <span>{route.ref}</span>
          </div>
        )}
      />
    );
  });

const SidebarHeader = () => (
  <div className="sidebar-header">
    <div className="primary">
      <h5 className="title" style={{ width: '100%' }}>
        <Switch>{buildRouteOptions()}</Switch>
      </h5>
    </div>
  </div>
);

export default SidebarHeader;
