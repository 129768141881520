import { combineReducers } from 'redux';

import livestream from './modules/livestream/reducer';
import push from './modules/push/reducer';
import sms from './modules/sms/reducer';
import history from './modules/history/reducer';
import scan from './modules/scan/reducer';
import zoom from './modules/zoom/reducer';
import forum from './modules/forum/reducer';
import blog from './modules/Blog/reducer';

import { structure } from './reducers';

export default combineReducers({
  forum,
  livestream,
  push,
  history,
  structure,
  sms,
  scan,
  zoom,
  blog,
});
