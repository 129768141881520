import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import './styles.css';
import ColorPicker from '../../../editor/components/colorPicker/ColorPicker';
import PreferenceLogo from './modules/preferenceLogo';

const Preferences = ({ appColor, onUpdateAppThemeColor }) => {
  const { t } = useTranslation();

  const handleColorChange = value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    onUpdateAppThemeColor(rgba);
  };

  return (
    <div className="preferences">
      <div>
        <div className="header-section">
          <h3>
            <Trans>Theme</Trans>
          </h3>
          <p>
            <Trans>Customize the look of your dashboard.</Trans>
          </p>
        </div>
        <div className="preferences-section">
          <div className="colorPicker">
            <ColorPicker
              label={t('Color')}
              color={appColor}
              onChange={color => handleColorChange(color)}
              height={22}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="header-section">
          <h3>
            <Trans>Logo</Trans>
          </h3>
          <p>
            <Trans>Add a custom Logo to your dashboard.</Trans>
          </p>
          <span>
            <Trans>We recommend a 91 x 50 logo size for the best result.</Trans>
          </span>
        </div>
        <div className="preferences-section">
          <div className="preference-logo">
            <h5>
              <Trans>Logo</Trans>
            </h5>
            <PreferenceLogo />
          </div>
        </div>
      </div>
    </div>
  );
};

Preferences.propTypes = {
  appColor: PropTypes.string.isRequired,
  onUpdateAppThemeColor: PropTypes.func.isRequired,
};

export default Preferences;
