import React from 'react';

import CircularProgress from '../circularProgress';
import './styles.css';

const ProgressOverlay = () => (
  <div className="progress-overlay">
    <CircularProgress />
  </div>
);

export default ProgressOverlay;
