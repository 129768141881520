import { connect } from 'react-redux';

import Account from './Account';

import {
  openDeleteUserDialog,
  closeDeleteUserDialog,
} from './deleteUserByEmailModal/actions';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  openDeleteUserDialog: () => dispatch(openDeleteUserDialog()),
  closeDeleteUserDialog: () => dispatch(closeDeleteUserDialog()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Account);
