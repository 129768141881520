import React, { Fragment } from 'react';
import OneThirdCenterImage from './images/oneThirdCenter.png';
import './styles.css';

const OneThirdCenter = () => {
  return (
    <Fragment>
      <img
        className="imageWrapper"
        src={OneThirdCenterImage}
        alt="One Third Center"
      />
    </Fragment>
  );
};

export default OneThirdCenter;
