import firebase from '../../../../../firebase';
import { validateField } from '../validators';

const newGroupFieldChange = (prop, value, isValid) => ({
  type: 'NEW_GROUP_FIELD_CHANGE',
  prop,
  value,
  isValid,
});

export const openNewGroupDialog = () => ({ type: 'OPEN_NEW_GROUP_DIALOG' });

export const closeNewGroupDialog = () => ({ type: 'CLOSE_NEW_GROUP_DIALOG' });

export const errorCreatingSubscription = () => ({
  type: 'ERROR_CREATING_SUBSCRIPTION_PRODUCT',
});

export const formFieldChange = (prop, value) => dispatch => {
  let isValid =
    prop === 'name' && value.length > 0 ? validateField[prop](value) : null;
  if (prop === 'price') {
    isValid = value.length === 0 ? false : true;
  }
  return dispatch(newGroupFieldChange(prop, value, isValid));
};

export const addGroup = appname => (dispatch, getState) => {
  const {
    name,
    hasSubscriptionPlan,
    description,
    price,
    freeTrialPeriod,
    groupImage,
    billingCycle,
    billingType,
    hasFreeTrial,
    currency,
    features,
    order,
    enableTax,
  } = getState().auth.groups.newGroup;

  let group = {
    name: name.value,
    ...(hasSubscriptionPlan.value && { order: order.value }),
    date: Date.now(),
    access: {
      someKey: false,
    },
  };

  dispatch({ type: 'ADD_NEW_GROUP' });
  if (hasSubscriptionPlan.value) {
    const payload = {
      attributes: [],
      description: description.value,
      name: name.value,
      price: price.value,
      order: order.value,
      freeTrialPeriod: freeTrialPeriod.value || '',
      billingType: billingType.value,
      billingCycle: billingCycle.value,
      hasFreeTrial: hasFreeTrial.value,
      currency: currency.value,
      appname,
      features,
      enableTax: enableTax.value,
    };
    if (groupImage) {
      payload.images = [groupImage];
    }

    group.subscriptionPlan = { ...payload };
    createSubscriptionProduct(payload)
      .then(response => {
        group.subscriptionPlan = { ...payload, ...response };
        dispatch(createGroup(appname, group));
      })
      .catch(err => {
        dispatch(errorCreatingSubscription());
      });
  } else {
    group.subscriptionPlan = {};
    dispatch(createGroup(appname, group));
  }
};

export const createGroup = (appname, group) => dispatch => {
  return firebase
    .database()
    .ref(`app_groups/${appname}/groups`)
    .push(group)
    .then(ref => {
      dispatch({
        type: 'NEW_GROUP_ADDED',
        key: ref.key,
        data: group,
      });
    });
};

export const updateGroupImage = groupImage => ({
  type: 'UPDATE_GROUP_IMAGE',
  groupImage,
});

export const loadContent = appname => dispatch => {
  const groupImageRef = firebase
    .database()
    .ref(`apps/${appname}/temp_data/groupImage`);
  groupImageRef.on('value', snapshot => {
    let image = snapshot.val();
    dispatch(updateGroupImage(image));
  });
};

export const createSubscriptionProduct = payload => {
  const myHeaders = new Headers();
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(token => {
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('x-access-token', token);
        fetch(`${process.env.REACT_APP_PAYMENT_API}/add_subscription_plan`, {
          method: 'post',
          headers: myHeaders,
          body: JSON.stringify(payload),
          mode: 'cors',
        })
          .then(data => {
            if (data.status == 200) {
              return data.json();
            } else if (data.status === 422) {
              throw data.json();
            }
          })
          .then(response => {
            return resolve(response);
          })
          .catch(err => {
            reject(err);
          });
      });
  });
};
