import { validateField } from '../../../validators';
import firebase from '../../../../../../../firebase';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';
import { EVENT_UPDATED } from '../../../config';
import { STAFF_ADMIN } from '../../../../../../../config';

export const EDIT_FIELD_CHANGE_EVENT = 'EDIT_FIELD_CHANGE_EVENT';
export const CLOSE_EDIT_DIALOG_EVENT = 'CLOSE_EDIT_DIALOG_EVENT';
export const UPDATE_REQUEST_EVENT = 'UPDATE_REQUEST_EVENT';
export const UPDATED_EVENT = 'UPDATED_EVENT';
export const LOADING_EVENT = 'LOADING_EVENT';
export const LOADED_DETAILS_EVENT = 'LOADED_DETAILS_EVENT';
export const RESET_EVENT = 'RESET_EVENT';

const fieldChange = (prop, id, value, isValid) => ({
  type: EDIT_FIELD_CHANGE_EVENT,
  prop,
  id,
  value,
  isValid,
});

export const closeDialog = () => ({ type: CLOSE_EDIT_DIALOG_EVENT });

export const resetData = () => ({
  type: RESET_EVENT,
});

export const loadingEvent = () => ({
  type: LOADING_EVENT,
});

export const loadEvent = prop => ({
  type: LOADED_DETAILS_EVENT,
  prop,
});

export const formFieldChange = (isRequired, prop, id, value, index) => (
  dispatch,
  getState,
) => {
  let data = value;
  const valid = data ? validateField[prop](data) : null;
  const isValid = isRequired ? valid : true;
  // merge dates to array [fromDate, toDate]
  if (prop === 'DateRange' || prop === 'Daterange') {
    const dateRange = getState().staff.events.edit.fieldsData[id].value;
    if (index === 0) {
      data = dateRange[1] ? [value, dateRange[1]] : [value, value];
    } else {
      data = dateRange[0] ? [dateRange[0], value] : [value, value];
    }
  }
  return dispatch(fieldChange(prop, id, data, isValid));
};

export const closeModal = (app, history, role) => dispatch => {
  dispatch(closeDialog());
  if (role === STAFF_ADMIN) {
    history.push(`/${app}/staff/events`);
  } else {
    history.push(`/${app}/members/events`);
  }
};

export const getEventDetails = (appname, event, memberbody) => dispatch => {
  dispatch(loadingEvent());
  return firebase
    .database()
    .ref(`app_events/${appname}/events/${memberbody}/${event}`)
    .once('value')
    .then(snapshot => {
      const data = snapshot.exists() ? snapshot.val() : {};
      dispatch(loadEvent(data));
    });
};

export const updateEvent = (eventID, history, appname, memberbody) => (
  dispatch,
  getState,
) => {
  dispatch({ type: UPDATE_REQUEST_EVENT });
  const { fieldsData } = getState().staff.events.edit;
  const { role } = getState().account.profile;
  fieldsData.updatedAt = Date.now();
  fieldsData.memberbody = memberbody;
  return firebase
    .database()
    .ref(`app_events/${appname}/events/${memberbody}/${eventID}`)
    .update(fieldsData)
    .then(() => {
      dispatch(closeModal(appname, history, role));
      dispatch({
        type: UPDATED_EVENT,
        key: eventID,
        data: fieldsData,
      });
      dispatch(openSnackbar(EVENT_UPDATED));
    });
};
