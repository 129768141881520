import React from 'react';

const SplashScreen = () => (
  <svg
    className="icon splash-screen"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 41.5"
  >
    <title>Splash Screen</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M20.6,0H3.4A3.41,3.41,0,0,0,0,3.4V38.1a3.41,3.41,0,0,0,3.4,3.4H20.6A3.41,3.41,0,0,0,24,38.1V3.4A3.41,3.41,0,0,0,20.6,0Zm0,40H3.4a1.9,1.9,0,0,1-1.9-1.9V35.3H20.12V33.8H1.5V3.4A1.9,1.9,0,0,1,3.4,1.5H20.6a1.9,1.9,0,0,1,1.9,1.9V6.3H4.5V7.8h18V38.1A1.9,1.9,0,0,1,20.6,40Z"
        />
        <path
          class="cls-1"
          d="M12.31,11.88a8.25,8.25,0,1,0,8.25,8.24A8.26,8.26,0,0,0,12.31,11.88Zm0,15a6.75,6.75,0,1,1,6.75-6.76A6.76,6.76,0,0,1,12.31,26.88Z"
        />
      </g>
    </g>
  </svg>
);

export default SplashScreen;
