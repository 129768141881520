export function userGroupStatus(user, parents, children) {
  console.log({ user, parents, children });
  if (user.fkAcademyUserType === 'parent') {
    if (parents && parents[user.userId])
      return [true, 'Remove parent from group'];
    else return [true, 'Add parent to group'];
  }
  if (user.fkAcademyUserType === 'child') {
    if (children && children[user.userId])
      return [true, 'Remove child from group'];
    else return [true, 'Add child to group'];
  }
  return [null, 'Use groups section to manage coach'];
}
