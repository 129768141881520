import { connect } from 'react-redux';

import { openCreateTabUI, openCreatePageUI } from '../create/actions';
import { launchRemoveDialog } from '../remove/actions';
import {
  reOrderPages,
  submitAppcuesForms,
  checkAppcuesVisibleStatus,
  submitAppcuesReferFriend,
  setActiveAccountTab,
} from './actions';
import { launchDuplicatePageDialog } from '../create/duplicatePage/actions';

import {
  addIsPublishedListener,
  removeIsPublishedListener,
} from '../../../../../../app/modules/toolbar/actions';

import { openNewAdminDialog } from '../../../../../memberLogin/modules/admins/newAdmin/actions';
import { toggleAccountModal } from '../../../../../../modules/account/actions';
import { toggleUserMenu } from '../../../../../../modules/editor/internals/accountsDropdown/actions';

import { routeHasUnavailableComponent as _routeHasUnavailableComponent } from '../../../../../../utils';

import BuildMenu from './BuildMenu';
import { getAppAdmins } from '../admins/actions';

const mapComponent = alwaysData => componentKey => ({
  ...alwaysData[componentKey],
  key: componentKey,
});

const mapStateToProps = (state, ownProps) => {
  const layoutData =
    state.editor.data.layoutData[ownProps.match.params.appname];
  const alwaysData = state.editor.data.alwaysData;
  const diys = state.app.addons.diyComponents || {};
  const addons = Object.keys(diys);
  const specialAddons = state.editor.build.create.createComponent.specialAddons;
  const specialAddonWithoutSubscription = specialAddons.filter(item => {
    return (
      !item.hasActiveSubscription &&
      item.hasFreeTrial &&
      Date.now() > item.expiryDate
    );
  });

  const isAppsumoUser = state.account.organisation.appsumo_id || false;

  if (!layoutData) {
    return { ...ownProps };
  }

  const freeTrialPlan = state.account.organisation.trial_expired
    ? null
    : 'accelerate';

  const subscriptions = state.account.subscriptions.items || [];
  const subscription = subscriptions[ownProps.match.params.appname];
  const plan = subscription && subscription.plan;
  const routeHasUnavailableComponent = _routeHasUnavailableComponent(
    freeTrialPlan || plan || 'start',
  );

  const mappedIndexRoute = {
    ...alwaysData[layoutData.index],
    title: 'Homepage',
    key: layoutData.index,
  };

  const mappedChildRoutes = layoutData.childRoutes
    ? layoutData.childRoutes.map(key => {
        let route = {
          ...alwaysData[key],
          components: alwaysData[key].components,
          key,
        };

        const tabs = (route && route.tabs) || [];

        if (route.type === 'TabView' || route.type === 'NestedTabPage') {
          route = {
            ...route,
            components:
              typeof route.components !== 'undefined'
                ? route.components.map(mapComponent(alwaysData))
                : [],
          };

          return {
            ...route,
            hasUnavailaleComponent: routeHasUnavailableComponent(
              route,
              addons,
              specialAddonWithoutSubscription,
              isAppsumoUser,
            ),
            tabs: tabs.map(tab => ({
              ...tab,
              components: tab.components
                ? tab.components.map(mapComponent(alwaysData))
                : [],
              hasUnavailaleComponent: routeHasUnavailableComponent(tab),
            })),
          };
        }

        route = {
          ...route,
          components: route.components
            ? route.components.map(mapComponent(alwaysData))
            : [],
        };

        return {
          ...route,
          hasUnavailaleComponent: routeHasUnavailableComponent(
            route,
            addons,
            specialAddonWithoutSubscription,
            isAppsumoUser,
          ),
        };
      })
    : [];
  const isAppAdmin =
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false;
  const appcuesVisibilityStatus = state.app.appcues;
  const role = state.account.profile.role;
  const isSubAdminWithMulipleApps =
    (state.account.auth.profile.admin_appnames &&
      Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
    false;
  return {
    ...ownProps,
    app: state.app,
    index: mappedIndexRoute,
    childRoutes: mappedChildRoutes,
    isPublished: state.editor.data.isPublished[ownProps.match.params.appname],
    isAppAdmin,
    plan,
    appcuesVisibilityStatus,
    role,
    isSubAdminWithMulipleApps,
    account: state.account.profile.account,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  removePage: key =>
    dispatch(
      launchRemoveDialog(ownProps.match.params.appname, { page: key }, 'PAGE'),
    ),
  onRearrangePage: ({ idToMove, moveTo, collection, newCollection }) =>
    dispatch(
      reOrderPages({
        idToMove,
        moveTo,
        collection,
        newCollection,
        appname: ownProps.match.params.appname,
      }),
    ),
  onAddTab: () => dispatch(openCreateTabUI()),
  onAddPage: () => dispatch(openCreatePageUI()),
  onAddIsPublishedListener: () =>
    dispatch(addIsPublishedListener(ownProps.match.params.appname)),
  onRemoveIsPublishedListener: () =>
    dispatch(removeIsPublishedListener(ownProps.match.params.appname)),
  duplicatePage: key => dispatch(launchDuplicatePageDialog(key)),
  getAppAdmins: () => dispatch(getAppAdmins()),
  onAddAdmin: () => dispatch(openNewAdminDialog(ownProps.match.params.appname)),
  openReferalModal: () => {
    dispatch(toggleAccountModal());
  },
  submitAppcuesForms: (type, data) =>
    dispatch(submitAppcuesForms(ownProps.match.params.appname, type, data)),
  submitAppcuesReferFriend: type => dispatch(submitAppcuesReferFriend(type)),
  checkAppcuesVisibleStatus: () => dispatch(checkAppcuesVisibleStatus()),
  onAccountSettingsTouchTap: () => {
    dispatch(toggleUserMenu(false));
    dispatch(toggleAccountModal());
  },
  setActiveAccountTab: value => dispatch(setActiveAccountTab(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuildMenu);
