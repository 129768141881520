import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';

import { planConstants } from '../../../../../modules/account/modules/subscribe/planConstants';

import * as noticeTypes from '../noticeTypes';

const NoticeDialog = props => {
  const {
    account,
    autoPopup,
    content,
    handleClose,
    handleCloseAutoPopup,
    noticeId,
    open,
    title,
    type,
    appname,
    country,
    isOn14DaysTrial,
  } = props;
  let finalContent;
  if (
    country &&
    planConstants.lifetime[country.toLowerCase()] &&
    content &&
    content.includes(
      "<img src='https://beezer-img-storage.s3.eu-west-2.amazonaws.com/output-onlinepngtools.png' alt='image to go here' height='111px' />",
    )
  ) {
    finalContent = content.replace(
      "<img src='https://beezer-img-storage.s3.eu-west-2.amazonaws.com/output-onlinepngtools.png' alt='image to go here' height='111px' />",
      '',
    );
  }
  if (
    country &&
    planConstants.lifetime[country.toLowerCase()] &&
    content &&
    content.includes(
      "<img src='https://beezer-img-storage.s3.eu-west-2.amazonaws.com/output-onlinepngtools.png' alt='Offer Image' height='111px' />",
    )
  ) {
    finalContent = content.replace(
      "<img src='https://beezer-img-storage.s3.eu-west-2.amazonaws.com/output-onlinepngtools.png' alt='Offer Image' height='111px' />",
      '',
    );
  }

  if (!open) {
    return <Dialog open={open} />;
  }

  const NoticeLayout = noticeTypes[type];
  const close = autoPopup
    ? () => handleCloseAutoPopup(account, noticeId)
    : handleClose;

  return (
    <Dialog
      open={open}
      onRequestClose={close}
      contentStyle={{ borderRadius: 3, overflow: 'hidden' }}
      autoScrollBodyContent
      className="notice-dialog"
    >
      <NoticeLayout
        isOn14DaysTrial={isOn14DaysTrial}
        content={finalContent || content || undefined}
        onRequestClose={close}
        title={title}
        appname={appname}
      />
    </Dialog>
  );
};

NoticeDialog.propTypes = {
  account: PropTypes.string,
  appname: PropTypes.string,
  autoPopup: PropTypes.bool,
  content: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleCloseAutoPopup: PropTypes.func.isRequired,
  noticeId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(noticeTypes)),
};

NoticeDialog.defaultProps = {
  account: '',
  appname: '',
  autoPopup: false,
  content: '',
  title: undefined,
  type: 'Standard',
  noticeId: null,
};

export default NoticeDialog;
