import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import EditContactDialog from './EditContact';

import {
  saveContactChange,
  closeEditContactDialog,
  formFieldChange,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.distribute.contacts.editContact,
  isIntegration: state.account.organisation.integration !== null,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFieldChange: (field, value, other = null) =>
    dispatch(formFieldChange(field, value, other)),
  onSubmit: (cameFromDrivers, history) =>
    dispatch(
      saveContactChange(
        ownProps.match.params.appname,
        cameFromDrivers,
        history,
      ),
    ),
  onRequestClose: () => dispatch(closeEditContactDialog()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditContactDialog),
);
