import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Trans, useTranslation } from 'react-i18next';

const PricingDetails = ({ subscriptionPlan }) => {
  const { t } = useTranslation();

  const capitalize = word => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  };
  return (
    <div class="text-field-wrapper">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Trans>Type</Trans>
        </Grid>
        <Grid item xs={12} sm={6}>
          {capitalize(subscriptionPlan.billingType)}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Trans>Billing Period</Trans>
        </Grid>
        <Grid item xs={12} sm={6}>
          {capitalize(subscriptionPlan.billingCycle)}
        </Grid>
        {subscriptionPlan.hasFreeTrial && (
          <React.Fragment>
            <Grid item xs={12} sm={6}>
              <Trans>Free Trial</Trans>
            </Grid>
            <Grid item xs={12} sm={6}>
              {`${subscriptionPlan.freeTrialPeriod} ${t('Days')}`}
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12} sm={6}>
          Tax Enabled
        </Grid>
        <Grid item xs={12} sm={6}>
          {subscriptionPlan.enableTax ? 'Yes' : 'No'}
        </Grid>
      </Grid>
    </div>
  );
};
export default PricingDetails;
