import { newValidator } from '../../../../utils';

const nameValidator = newValidator({
  minLength: 2,
  required: true,
});

const emailValidator = newValidator({
  type: 'email',
  required: true,
});

const passwordValidator = newValidator({
  minLength: 6,
  required: true,
});

const titleValidator = newValidator({
  required: true,
  maxLength: 45,
  minLength: 3,
  type: 'title',
});

export const validateField = {
  firstName: value => nameValidator(value),
  lastName: value => nameValidator(value),
  email: value => emailValidator(value),
  password: value => passwordValidator(value),
  adminAppname: value => titleValidator(value),
};
