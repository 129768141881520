import firebase from '../../../../../../../../firebase';
import { validateField } from '../../validators';
import { openSnackbar } from '../../../../../../../../app/modules/snackbar/actions';
import { WORKFORCE_GROUP_FIELD_CREATED } from '../../../../config';

export const NEW_FIELD_FIELD_CHANGE = 'NEW_FIELD_FIELD_CHANGE';
export const OPEN_NEW_FIELD_DIALOG = 'OPEN_NEW_FIELD_DIALOG';
export const CLOSE_NEW_FIELD_DIALOG = 'CLOSE_NEW_FIELD_DIALOG';
export const ADD_NEW_FIELD = 'ADD_NEW_FIELD';
export const NEW_FIELD_ADDED = 'NEW_FIELD_ADDED';

const newGroupFieldChange = (prop, value, isValid) => ({
  type: NEW_FIELD_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

export const openMemberGroupFieldDialog = () => ({
  type: OPEN_NEW_FIELD_DIALOG,
});

export const closeMemberGroupFieldDialog = () => ({
  type: CLOSE_NEW_FIELD_DIALOG,
});

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;

  return dispatch(newGroupFieldChange(prop, value, isValid));
};

export const addField = (appname, groupID) => (dispatch, getState) => {
  const {
    name,
    type,
    options,
    isMultiple,
    isRequired,
    isSearchable,
    isPublic,
  } = getState().auth.memberGroups.edit.createField;

  const field = {
    name: name.value,
    options: options.value,
    type: type.value,
    isRequired: isRequired.value,
    isMultiple: isMultiple.value,
    isSearchable: isSearchable.value,
    isPublic: isPublic.value,
    createdAt: Date.now(),
  };

  dispatch({ type: ADD_NEW_FIELD });
  return firebase
    .database()
    .ref(`workforce_groups/${appname}/${groupID}/fields`)
    .push(field)
    .then(ref => {
      dispatch({
        type: NEW_FIELD_ADDED,
        key: ref.key,
        data: field,
      });
      dispatch(openSnackbar(WORKFORCE_GROUP_FIELD_CREATED));
    });
};
