import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCellMui from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/styles';
import { TreeView, TreeItem } from '@material-ui/lab';
import { Trans, useTranslation } from 'react-i18next';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Print from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import InfoIcon from 'material-ui/svg-icons/action/info';
import moment from 'moment';

import { objectToArray, downloadPrintPDF } from '../../../../../../utils';
import {
  religions,
  sexualOrientations,
  gender,
  disability,
  martialStatus,
  leaveTypes,
  genderReassignments,
} from '../../../workforceMembers/config';
import DownloadExcel from '../../components/DownloadExcel';
import Modal from '../../components/Modal';
import Filters from '../../components/Filters';

const TableCell = withStyles({
  head: {
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
})(TableCellMui);

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  center: {
    textAlign: 'center',
    width: '100%',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  labelIcon: {
    marginRight: '1px',
  },
  labelText: {
    fontWeight: 'bold',
    flexGrow: 1,
    color: 'rgba(0, 0, 0, 0.54) !important',
  },
  width60: {
    width: '60%',
  },
  PL25: {
    paddingLeft: '25px',
  },
});

const Equalities = ({ usersData, workforceGroups }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [modalData, setModalData] = React.useState({});
  const [isOpen, setOpen] = React.useState(false);
  const [users, setUsers] = React.useState(usersData);

  React.useEffect(() => {
    setUsers(usersData);
  }, [usersData]);

  const wfPostCodes = objectToArray(users).filter(
    i => i.value.postCode && i.value.workforceMember && i.value.workforceGroups,
  );

  const userPostCodes = objectToArray(users).filter(
    i => i.value.postCode && !i.value.workforceMember,
  );

  const onFilter = dates => {
    const clonedUsersData = _.cloneDeep(usersData);
    const filteredData = _.pickBy(clonedUsersData, val => {
      const dateToFilter = new Date(
        moment(val.created_at).format('YYYY/MM/DD'),
      ).getTime();
      if (dates.from && dates.to) {
        return (
          dateToFilter >= dates.from.getTime() &&
          dateToFilter <= dates.to.getTime()
        );
      } else if (dates.from) {
        return dateToFilter >= dates.from.getTime();
      } else if (dates.to) {
        return dateToFilter <= dates.to.getTime();
      }
      return true;
    });
    setUsers(filteredData);
  };

  const generateData = actionType => {
    const equalities = { data: [] };
    equalities.columns = [
      t('Type'),
      t('Sub Type'),
      t('Total of Sub Types'),
      t('Individual Users'),
      t('Workforce Members'),
      t('Workforce Group'),
      t('Sum of Workforce Group'),
    ];
    // Disability data preparation
    disability.forEach(type => {
      objectToArray(workforceGroups).forEach(group => {
        equalities.data.push([
          'Disability',
          type,
          _.size(_.pickBy(users, { disability: type })),
          _.size(
            _.pickBy(users, {
              disability: type,
              workforceMember: undefined,
            }),
          ),
          _.size(
            _.pickBy(users, {
              disability: type,
              workforceMember: true,
            }),
          ),
          group.value.name,
          _.size(
            _.pickBy(users, {
              workforceGroups: { [group.id]: true },
              workforceMember: true,
              disability: type,
            }),
          ),
        ]);
      });
    });
    // Gender data preparation
    gender.forEach(type => {
      objectToArray(workforceGroups).forEach(group => {
        equalities.data.push([
          'Gender',
          type,
          _.size(_.pickBy(users, { gender: type })),
          _.size(
            _.pickBy(users, {
              gender: type,
              workforceMember: undefined,
            }),
          ),
          _.size(
            _.pickBy(users, {
              gender: type,
              workforceMember: true,
            }),
          ),
          group.value.name,
          _.size(
            _.pickBy(users, {
              workforceGroups: { [group.id]: true },
              workforceMember: true,
              gender: type,
            }),
          ),
        ]);
      });
    });
    // Sexual Orientations data preparation
    sexualOrientations.forEach(type => {
      objectToArray(workforceGroups).forEach(group => {
        equalities.data.push([
          'Sexual Orientation',
          type,
          _.size(_.pickBy(users, { sexualOrientation: type })),
          _.size(
            _.pickBy(users, {
              sexualOrientation: type,
              workforceMember: undefined,
            }),
          ),
          _.size(
            _.pickBy(users, {
              sexualOrientation: type,
              workforceMember: true,
            }),
          ),
          group.value.name,
          _.size(
            _.pickBy(users, {
              workforceGroups: { [group.id]: true },
              workforceMember: true,
              sexualOrientation: type,
            }),
          ),
        ]);
      });
    });
    // Religions data preparation
    religions.forEach(type => {
      objectToArray(workforceGroups).forEach(group => {
        equalities.data.push([
          'Religion',
          type,
          _.size(_.pickBy(users, { religion: type })),
          _.size(
            _.pickBy(users, {
              religion: type,
              workforceMember: undefined,
            }),
          ),
          _.size(
            _.pickBy(users, {
              religion: type,
              workforceMember: true,
            }),
          ),
          group.value.name,
          _.size(
            _.pickBy(users, {
              workforceGroups: { [group.id]: true },
              workforceMember: true,
              religion: type,
            }),
          ),
        ]);
      });
    });
    // Gender Reassignment data preparation
    genderReassignments.forEach(type => {
      objectToArray(workforceGroups).forEach(group => {
        equalities.data.push([
          'Gender Reassignment (Transidentity)',
          type,
          _.size(_.pickBy(users, { genderReassignment: type })),
          _.size(
            _.pickBy(users, {
              genderReassignment: type,
              workforceMember: undefined,
            }),
          ),
          _.size(
            _.pickBy(users, {
              genderReassignment: type,
              workforceMember: true,
            }),
          ),
          group.value.name,
          _.size(
            _.pickBy(users, {
              workforceGroups: { [group.id]: true },
              workforceMember: true,
              genderReassignment: type,
            }),
          ),
        ]);
      });
    });
    // Leave Types data preparation
    leaveTypes.forEach(type => {
      objectToArray(workforceGroups).forEach(group => {
        equalities.data.push([
          'Pregnant or on Maternity/Paternity leave',
          type,
          _.size(_.pickBy(users, { leaveType: type })),
          _.size(
            _.pickBy(users, {
              leaveType: type,
              workforceMember: undefined,
            }),
          ),
          _.size(
            _.pickBy(users, {
              leaveType: type,
              workforceMember: true,
            }),
          ),
          group.value.name,
          _.size(
            _.pickBy(users, {
              workforceGroups: { [group.id]: true },
              workforceMember: true,
              leaveType: type,
            }),
          ),
        ]);
      });
    });
    //Martial Status data preparation
    martialStatus.forEach(type => {
      objectToArray(workforceGroups).forEach(group => {
        equalities.data.push([
          'Marriage/Civil Partnership',
          type,
          _.size(_.pickBy(users, { martialStatus: type })),
          _.size(
            _.pickBy(users, {
              martialStatus: type,
              workforceMember: undefined,
            }),
          ),
          _.size(
            _.pickBy(users, {
              martialStatus: type,
              workforceMember: true,
            }),
          ),
          group.value.name,
          _.size(
            _.pickBy(users, {
              workforceGroups: { [group.id]: true },
              workforceMember: true,
              martialStatus: type,
            }),
          ),
        ]);
      });
    });
    // All PostCodes data Preparation for individual users
    const postCodesIndividualUser = { data: [] };
    postCodesIndividualUser.columns = [t('User Name'), t('Post Code')];
    userPostCodes.forEach(user => {
      postCodesIndividualUser.data.push([
        `${user.value.firstName} ${user.value.lastName}`,
        user.value.postCode,
      ]);
    });
    if (!userPostCodes.length) {
      postCodesIndividualUser.data.push(['', '']);
    }
    // All PostCodes data Preparation for workforce members
    const postCodesWorkforceMembers = { data: [] };
    postCodesWorkforceMembers.columns = [
      t('Workforce Group'),
      t('User Name'),
      t('Post Code'),
    ];
    objectToArray(workforceGroups).forEach(group => {
      wfPostCodes
        .filter(user => user.value.workforceGroups[group.id])
        .forEach(user => {
          postCodesWorkforceMembers.data.push([
            group.value.name,
            `${user.value.firstName} ${user.value.lastName}`,
            user.value.postCode,
          ]);
        });
    });

    if (actionType === 'excel') {
      return [
        {
          multiDataSet: [equalities],
          name: t('Equalities'),
        },
        {
          multiDataSet: [postCodesIndividualUser],
          name: t('Individual Users Post Codes'),
        },
        {
          multiDataSet: [postCodesWorkforceMembers],
          name: t('Workforce Members Post Codes'),
        },
      ];
    }
    return downloadPrintPDF(
      [[equalities], [postCodesIndividualUser], [postCodesWorkforceMembers]],
      [
        t('Equalities'),
        t('Individual Users Post Codes'),
        t('Workforce Members Post Codes'),
      ],
      'dvTable',
    );
  };

  const prepareModalData = (e, input) => {
    e.stopPropagation(true);
    if (input) {
      const tableData = {
        columns: [
          { title: t('First Name'), key: 'firstName' },
          { title: t('Last Name'), key: 'lastName' },
          { title: t('Email'), key: 'email' },
          {
            title: t('Joining Date'),
            key: 'created_at',
            render: value => moment(value).format('DD/MM/YYYY'),
          },
        ],
      };
      tableData.rows = Object.values(input);
      setModalData(tableData);
      setOpen(true);
    }
    return true;
  };

  const prepareModalPostCodesData = (e, input) => {
    e.stopPropagation(true);
    if (input) {
      const tableData = {
        columns: [
          {
            title: t('Full Name'),
            key: 'firstName',
            render: (value, row) => `${row.firstName} ${row.lastName}`,
          },
          { title: t('Email'), key: 'email' },
          { title: t('PostCode'), key: 'postCode' },
          {
            title: t('Joining Date'),
            key: 'created_at',
            render: value => moment(value).format('DD/MM/YYYY'),
          },
        ],
      };
      tableData.rows = input.map(item => item.value);
      setModalData(tableData);
      setOpen(true);
    }
    return true;
  };

  return (
    <TableContainer id="equalities-table" component={Paper}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '24px',
        }}
      >
        <Filters onFilter={onFilter} report="equalities" />
        <DownloadExcel
          element={
            <Tooltip title={t('Export To Excel')} placement="top">
              <IconButton aria-label={t('Export To Excel')} edge="end">
                <SaveAlt fontSize="small" />
              </IconButton>
            </Tooltip>
          }
          data={generateData('excel')}
          filename={t('Equalities Report')}
        />

        <Tooltip title="Print" placement="top">
          <IconButton
            aria-label={t('Print')}
            onClick={e => generateData('print')}
            edge="end"
          >
            <Print fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <Table className={classes.table} aria-label="equalities table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Trans>Type</Trans>
            </TableCell>
            <TableCell align="left" className={classes.width60}>
              <Trans>Additional Data</Trans>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <Trans>Post Codes</Trans>
            </TableCell>
            <TableCell component="th" scope="row">
              <TreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
              >
                <TreeItem
                  nodeId="postcodes-user"
                  className={classes.PL25}
                  label={
                    <div className={classes.labelRoot}>
                      <Typography variant="body2" className={classes.labelText}>
                        {`${t('Individual Users')} (${userPostCodes.length})`}
                      </Typography>
                      <Typography variant="caption" color="inherit">
                        <Tooltip title={t('View Details')} placement="top">
                          <IconButton>
                            <InfoIcon
                              color="#a2a2a2"
                              style={{ height: 18, width: 18 }}
                              onClick={e =>
                                prepareModalPostCodesData(e, userPostCodes)
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </div>
                  }
                >
                  {userPostCodes.map(i => (
                    <TreeItem
                      nodeId={`postcode-user-${i.id}`}
                      key={`postcode-user-${i.id}`}
                      label={
                        <div className={classes.labelRoot}>
                          <Typography
                            variant="body2"
                            className={classes.labelText}
                          >
                            {`${i.value.firstName} ${i.value.lastName}`}
                          </Typography>
                          <Typography variant="caption" color="inherit">
                            {i.value.postCode}
                          </Typography>
                        </div>
                      }
                    />
                  ))}
                </TreeItem>
                <TreeItem
                  nodeId="postcodes-wfuser"
                  label={
                    <div className={classes.labelRoot}>
                      <Typography variant="body2" className={classes.labelText}>
                        {`${t('Workforce Members')} (${wfPostCodes.length})`}
                      </Typography>
                      <Typography variant="caption" color="inherit">
                        <Tooltip title={t('View Details')} placement="top">
                          <IconButton>
                            <InfoIcon
                              color="#a2a2a2"
                              style={{ height: 18, width: 18 }}
                              onClick={e =>
                                prepareModalPostCodesData(e, wfPostCodes)
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </div>
                  }
                >
                  {objectToArray(workforceGroups).map(group => {
                    const usersInGroup = wfPostCodes.filter(
                      user => user.value.workforceGroups[group.id],
                    );
                    return (
                      <TreeItem
                        key={`postcodes-wf-${group.id}`}
                        nodeId={`postcodes-wf-${group.id}`}
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {group.value.name} ({usersInGroup.length})
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalPostCodesData(e, usersInGroup)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      >
                        {usersInGroup.map(i => (
                          <TreeItem
                            nodeId={`postcode-wfuser-${i.id}`}
                            key={`postcode-wfuser-${i.id}`}
                            label={
                              <div className={classes.labelRoot}>
                                <Typography
                                  variant="body2"
                                  className={classes.labelText}
                                >
                                  {`${i.value.firstName} ${i.value.lastName}`}
                                </Typography>
                                <Typography variant="caption" color="inherit">
                                  {i.value.postCode}
                                </Typography>
                              </div>
                            }
                          />
                        ))}
                      </TreeItem>
                    );
                  })}
                </TreeItem>
              </TreeView>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Trans>Disability</Trans>
            </TableCell>
            <TableCell component="th" scope="row">
              <TreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
              >
                {disability.map(type => {
                  const havingType = _.pickBy(users, { disability: type });
                  const userHavingType = _.pickBy(users, {
                    disability: type,
                    workforceMember: undefined,
                  });
                  const workforceHavingType = _.pickBy(users, {
                    disability: type,
                    workforceMember: true,
                  });
                  return (
                    <TreeItem
                      nodeId={`disability-${type}`}
                      key={`disability-${type}`}
                      label={
                        <div className={classes.labelRoot}>
                          <Typography
                            variant="body2"
                            className={classes.labelText}
                          >
                            {`${type} (${_.size(havingType)})`}
                          </Typography>
                          <Typography variant="caption" color="inherit">
                            <Tooltip title={t('View Details')} placement="top">
                              <IconButton>
                                <InfoIcon
                                  color="#a2a2a2"
                                  style={{ height: 18, width: 18 }}
                                  onClick={e => prepareModalData(e, havingType)}
                                />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </div>
                      }
                    >
                      <TreeItem
                        nodeId="disability-user"
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Individual Users')} (${_.size(
                                userHavingType,
                              )})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalData(e, userHavingType)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      />
                      <TreeItem
                        nodeId="disability-user"
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Workforce Members')} (${_.size(
                                workforceHavingType,
                              )})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalData(e, workforceHavingType)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      >
                        {objectToArray(workforceGroups).map(group => {
                          const userInGroup = _.pickBy(users, {
                            workforceGroups: { [group.id]: true },
                            workforceMember: true,
                            disability: type,
                          });
                          return (
                            <TreeItem
                              key={`disability-wf-${group.id}`}
                              nodeId={`disability-wf-${group.id}`}
                              label={
                                <div className={classes.labelRoot}>
                                  <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                  >
                                    {group.value.name} ({_.size(userInGroup)})
                                  </Typography>
                                  <Typography variant="caption" color="inherit">
                                    <Tooltip
                                      title={t('View Details')}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <InfoIcon
                                          color="#a2a2a2"
                                          style={{ height: 18, width: 18 }}
                                          onClick={e =>
                                            prepareModalData(e, userInGroup)
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </div>
                              }
                            />
                          );
                        })}
                      </TreeItem>
                    </TreeItem>
                  );
                })}
              </TreeView>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Trans>Gender</Trans>
            </TableCell>
            <TableCell component="th" scope="row">
              <TreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
              >
                {gender.map(type => {
                  const havingType = _.pickBy(users, { gender: type });
                  const userHavingType = _.pickBy(users, {
                    gender: type,
                    workforceMember: undefined,
                  });
                  const workforceHavingType = _.pickBy(users, {
                    gender: type,
                    workforceMember: true,
                  });
                  return (
                    <TreeItem
                      nodeId={`gender-${type}`}
                      key={`gender-${type}`}
                      label={
                        <div className={classes.labelRoot}>
                          <Typography
                            variant="body2"
                            className={classes.labelText}
                          >
                            {`${type} (${_.size(havingType)})`}
                          </Typography>
                          <Typography variant="caption" color="inherit">
                            <Tooltip title={t('View Details')} placement="top">
                              <IconButton>
                                <InfoIcon
                                  color="#a2a2a2"
                                  style={{ height: 18, width: 18 }}
                                  onClick={e => prepareModalData(e, havingType)}
                                />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </div>
                      }
                    >
                      <TreeItem
                        nodeId="gender-user"
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Individual Users')} (${_.size(
                                userHavingType,
                              )})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalData(e, userHavingType)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      />
                      <TreeItem
                        nodeId="gender-workforce"
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Workforce Members')} (${_.size(
                                workforceHavingType,
                              )})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalData(e, workforceHavingType)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      >
                        {objectToArray(workforceGroups).map(group => {
                          const userInGroup = _.pickBy(users, {
                            workforceGroups: { [group.id]: true },
                            workforceMember: true,
                            gender: type,
                          });
                          return (
                            <TreeItem
                              key={`gender-wf-${group.id}`}
                              nodeId={`gender-wf-${group.id}`}
                              label={
                                <div className={classes.labelRoot}>
                                  <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                  >
                                    {group.value.name} ({_.size(userInGroup)})
                                  </Typography>
                                  <Typography variant="caption" color="inherit">
                                    <Tooltip
                                      title={t('View Details')}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <InfoIcon
                                          color="#a2a2a2"
                                          style={{ height: 18, width: 18 }}
                                          onClick={e =>
                                            prepareModalData(e, userInGroup)
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </div>
                              }
                            />
                          );
                        })}
                      </TreeItem>
                    </TreeItem>
                  );
                })}
              </TreeView>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Trans>Sexual Orientation</Trans>
            </TableCell>
            <TableCell component="th" scope="row">
              <TreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
              >
                {sexualOrientations.map(type => {
                  const havingType = _.pickBy(users, {
                    sexualOrientation: type,
                  });
                  const userHavingType = _.pickBy(users, {
                    sexualOrientation: type,
                    workforceMember: undefined,
                  });
                  const workforceHavingType = _.pickBy(users, {
                    sexualOrientation: type,
                    workforceMember: true,
                  });
                  return (
                    <TreeItem
                      nodeId={`sexualOrientation-${type}`}
                      key={`sexualOrientation-${type}`}
                      label={
                        <div className={classes.labelRoot}>
                          <Typography
                            variant="body2"
                            className={classes.labelText}
                          >
                            {`${type} (${_.size(havingType)})`}
                          </Typography>
                          <Typography variant="caption" color="inherit">
                            <Tooltip title={t('View Details')} placement="top">
                              <IconButton>
                                <InfoIcon
                                  color="#a2a2a2"
                                  style={{ height: 18, width: 18 }}
                                  onClick={e => prepareModalData(e, havingType)}
                                />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </div>
                      }
                    >
                      <TreeItem
                        nodeId="sexualOrientation-user"
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Individual Users')} (${_.size(
                                userHavingType,
                              )})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalData(e, userHavingType)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      />
                      <TreeItem
                        nodeId="sexualOrientation-user"
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Workforce Members')} (${_.size(
                                workforceHavingType,
                              )})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalData(e, workforceHavingType)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      >
                        {objectToArray(workforceGroups).map(group => {
                          const userInGroup = _.pickBy(users, {
                            workforceGroups: { [group.id]: true },
                            workforceMember: true,
                            sexualOrientation: type,
                          });
                          return (
                            <TreeItem
                              key={`sexualOrientation-wf-${group.id}`}
                              nodeId={`sexualOrientation-wf-${group.id}`}
                              label={
                                <div className={classes.labelRoot}>
                                  <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                  >
                                    {group.value.name} ({_.size(userInGroup)})
                                  </Typography>
                                  <Typography variant="caption" color="inherit">
                                    <Tooltip
                                      title={t('View Details')}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <InfoIcon
                                          color="#a2a2a2"
                                          style={{ height: 18, width: 18 }}
                                          onClick={e =>
                                            prepareModalData(e, userInGroup)
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </div>
                              }
                            />
                          );
                        })}
                      </TreeItem>
                    </TreeItem>
                  );
                })}
              </TreeView>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Trans>Religion</Trans>
            </TableCell>
            <TableCell component="th" scope="row">
              <TreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
              >
                {religions.map(type => {
                  const havingType = _.pickBy(users, { religion: type });
                  const userHavingType = _.pickBy(users, {
                    religion: type,
                    workforceMember: undefined,
                  });
                  const workforceHavingType = _.pickBy(users, {
                    religion: type,
                    workforceMember: true,
                  });
                  return (
                    <TreeItem
                      nodeId={`religion-${type}`}
                      key={`religion-${type}`}
                      label={
                        <div className={classes.labelRoot}>
                          <Typography
                            variant="body2"
                            className={classes.labelText}
                          >
                            {`${type} (${_.size(havingType)})`}
                          </Typography>
                          <Typography variant="caption" color="inherit">
                            <Tooltip title={t('View Details')} placement="top">
                              <IconButton>
                                <InfoIcon
                                  color="#a2a2a2"
                                  style={{ height: 18, width: 18 }}
                                  onClick={e => prepareModalData(e, havingType)}
                                />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </div>
                      }
                    >
                      <TreeItem
                        nodeId="religion-user"
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Individual Users')} (${_.size(
                                userHavingType,
                              )})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalData(e, userHavingType)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      />
                      <TreeItem
                        nodeId="religion-user"
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Workforce Members')} (${_.size(
                                workforceHavingType,
                              )})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalData(e, workforceHavingType)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      >
                        {objectToArray(workforceGroups).map(group => {
                          const userInGroup = _.pickBy(users, {
                            workforceGroups: { [group.id]: true },
                            workforceMember: true,
                            disability: type,
                          });
                          return (
                            <TreeItem
                              key={`religion-wf-${group.id}`}
                              nodeId={`religion-wf-${group.id}`}
                              label={
                                <div className={classes.labelRoot}>
                                  <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                  >
                                    {group.value.name} ({_.size(userInGroup)})
                                  </Typography>
                                  <Typography variant="caption" color="inherit">
                                    <Tooltip
                                      title={t('View Details')}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <InfoIcon
                                          color="#a2a2a2"
                                          style={{ height: 18, width: 18 }}
                                          onClick={e =>
                                            prepareModalData(e, userInGroup)
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </div>
                              }
                            />
                          );
                        })}
                      </TreeItem>
                    </TreeItem>
                  );
                })}
              </TreeView>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Trans>Gender Reassignment (Transidentity)</Trans>
            </TableCell>
            <TableCell component="th" scope="row">
              <TreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
              >
                {genderReassignments.map(type => {
                  const havingType = _.pickBy(users, {
                    genderReassignment: type,
                  });
                  const userHavingType = _.pickBy(users, {
                    genderReassignment: type,
                    workforceMember: undefined,
                  });
                  const workforceHavingType = _.pickBy(users, {
                    genderReassignment: type,
                    workforceMember: true,
                  });
                  return (
                    <TreeItem
                      nodeId={`genderReassignment-${type}`}
                      key={`genderReassignment-${type}`}
                      label={
                        <div className={classes.labelRoot}>
                          <Typography
                            variant="body2"
                            className={classes.labelText}
                          >
                            {`${type} (${_.size(havingType)})`}
                          </Typography>
                          <Typography variant="caption" color="inherit">
                            <Tooltip title={t('View Details')} placement="top">
                              <IconButton>
                                <InfoIcon
                                  color="#a2a2a2"
                                  style={{ height: 18, width: 18 }}
                                  onClick={e => prepareModalData(e, havingType)}
                                />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </div>
                      }
                    >
                      <TreeItem
                        nodeId="genderReassignment-user"
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`Individual Users (${_.size(userHavingType)})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalData(e, userHavingType)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      />
                      <TreeItem
                        nodeId="genderReassignment-user"
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Workforce Members')} (${_.size(
                                workforceHavingType,
                              )})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalData(e, workforceHavingType)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      >
                        {objectToArray(workforceGroups).map(group => {
                          const userInGroup = _.pickBy(users, {
                            workforceGroups: { [group.id]: true },
                            workforceMember: true,
                            genderReassignment: type,
                          });
                          return (
                            <TreeItem
                              key={`genderReassignment-wf-${group.id}`}
                              nodeId={`genderReassignment-wf-${group.id}`}
                              label={
                                <div className={classes.labelRoot}>
                                  <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                  >
                                    {group.value.name} ({_.size(userInGroup)})
                                  </Typography>
                                  <Typography variant="caption" color="inherit">
                                    <Tooltip
                                      title={t('View Details')}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <InfoIcon
                                          color="#a2a2a2"
                                          style={{ height: 18, width: 18 }}
                                          onClick={e =>
                                            prepareModalData(e, userInGroup)
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </div>
                              }
                            />
                          );
                        })}
                      </TreeItem>
                    </TreeItem>
                  );
                })}
              </TreeView>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Trans>Pregnant or on Maternity/Paternity leave</Trans>
            </TableCell>
            <TableCell component="th" scope="row">
              <TreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
              >
                {leaveTypes.map(type => {
                  const havingType = _.pickBy(users, {
                    leaveType: type,
                  });
                  const userHavingType = _.pickBy(users, {
                    leaveType: type,
                    workforceMember: undefined,
                  });
                  const workforceHavingType = _.pickBy(users, {
                    leaveType: type,
                    workforceMember: true,
                  });
                  return (
                    <TreeItem
                      nodeId={`leaveType-${type}`}
                      key={`leaveType-${type}`}
                      label={
                        <div className={classes.labelRoot}>
                          <Typography
                            variant="body2"
                            className={classes.labelText}
                          >
                            {`${type} (${_.size(havingType)})`}
                          </Typography>
                          <Typography variant="caption" color="inherit">
                            <Tooltip title={t('View Details')} placement="top">
                              <IconButton>
                                <InfoIcon
                                  color="#a2a2a2"
                                  style={{ height: 18, width: 18 }}
                                  onClick={e => prepareModalData(e, havingType)}
                                />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </div>
                      }
                    >
                      <TreeItem
                        nodeId="leaveType-user"
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`Individual Users (${_.size(userHavingType)})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalData(e, userHavingType)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      />
                      <TreeItem
                        nodeId="leaveType-user"
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Workforce Members')} (${_.size(
                                workforceHavingType,
                              )})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalData(e, workforceHavingType)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      >
                        {objectToArray(workforceGroups).map(group => {
                          const userInGroup = _.pickBy(users, {
                            workforceGroups: { [group.id]: true },
                            workforceMember: true,
                            leaveType: type,
                          });
                          return (
                            <TreeItem
                              key={`leaveType-wf-${group.id}`}
                              nodeId={`leaveType-wf-${group.id}`}
                              label={
                                <div className={classes.labelRoot}>
                                  <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                  >
                                    {group.value.name} ({_.size(userInGroup)})
                                  </Typography>
                                  <Typography variant="caption" color="inherit">
                                    <Tooltip
                                      title={t('View Details')}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <InfoIcon
                                          color="#a2a2a2"
                                          style={{ height: 18, width: 18 }}
                                          onClick={e =>
                                            prepareModalData(e, userInGroup)
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </div>
                              }
                            />
                          );
                        })}
                      </TreeItem>
                    </TreeItem>
                  );
                })}
              </TreeView>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Trans>Marriage/Civil Partnership</Trans>
            </TableCell>
            <TableCell component="th" scope="row">
              <TreeView
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
              >
                {martialStatus.map(type => {
                  const havingType = _.pickBy(users, {
                    martialStatus: type,
                  });
                  const userHavingType = _.pickBy(users, {
                    martialStatus: type,
                    workforceMember: undefined,
                  });
                  const workforceHavingType = _.pickBy(users, {
                    martialStatus: type,
                    workforceMember: true,
                  });
                  return (
                    <TreeItem
                      nodeId={`martialStatus-${type}`}
                      key={`martialStatus-${type}`}
                      label={
                        <div className={classes.labelRoot}>
                          <Typography
                            variant="body2"
                            className={classes.labelText}
                          >
                            {`${type} (${_.size(havingType)})`}
                          </Typography>
                          <Typography variant="caption" color="inherit">
                            <Tooltip title={t('View Details')} placement="top">
                              <IconButton>
                                <InfoIcon
                                  color="#a2a2a2"
                                  style={{ height: 18, width: 18 }}
                                  onClick={e => prepareModalData(e, havingType)}
                                />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </div>
                      }
                    >
                      <TreeItem
                        nodeId="martialStatus-user"
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`Individual Users (${_.size(userHavingType)})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalData(e, userHavingType)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      />
                      <TreeItem
                        nodeId="martialStatus-user"
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`Workforce Members (${_.size(
                                workforceHavingType,
                              )})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e =>
                                      prepareModalData(e, workforceHavingType)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      >
                        {objectToArray(workforceGroups).map(group => {
                          const userInGroup = _.pickBy(users, {
                            workforceGroups: { [group.id]: true },
                            workforceMember: true,
                            genderReassignment: type,
                          });
                          return (
                            <TreeItem
                              key={`martialStatus-wf-${group.id}`}
                              nodeId={`martialStatus-wf-${group.id}`}
                              label={
                                <div className={classes.labelRoot}>
                                  <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                  >
                                    {group.value.name} ({_.size(userInGroup)})
                                  </Typography>
                                  <Typography variant="caption" color="inherit">
                                    <Tooltip
                                      title={t('View Details')}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <InfoIcon
                                          color="#a2a2a2"
                                          style={{ height: 18, width: 18 }}
                                          onClick={e =>
                                            prepareModalData(e, userInGroup)
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </div>
                              }
                            />
                          );
                        })}
                      </TreeItem>
                    </TreeItem>
                  );
                })}
              </TreeView>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {/*Used for to generate temporary tables for export */}
      <div id="dvTable" style={{ display: 'none' }} />
      {/*Used for to generate printed pdf */}
      <iframe
        title={t("Content to Print")}
        id="ifmcontentstoprint"
        style={{
          height: '0px',
          width: '0px',
          position: 'absolute',
          display: 'none',
        }}
      />
      <Modal {...modalData} open={isOpen} onClose={() => setOpen(false)} />
    </TableContainer>
  );
};
Equalities.propTypes = {
  usersData: PropTypes.object,
  workforceGroups: PropTypes.object,
};

Equalities.defaultProps = {
  usersData: {},
  workforceGroups: {},
};

export default Equalities;
