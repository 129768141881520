import { combineReducers } from 'redux';
import _pickBy from 'lodash/pickBy';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';

import {
  REQUEST_FORM_DATA,
  RECEIVE_FORM_DATA,
  RECEIVE_FORM_DATA_UPDATE,
  REQUEST_ALL_FORMS,
  RECEIVE_ALL_FORMS,
  REPORT_FORM_DATA_REQUEST_ERROR,
  FILTER_FORM_DATA,
  SELECT_RESPONSE,
  SELECT_ALL_RESPONSES,
  DESELECT_ALL_RESPONSES,
  FAVOURITE_SELECTED,
  DELETE_SELECTED,
  SORT_FORM_TABLE_DATA,
  CHANGE_FORM,
  UPDATE_STATUS,
} from './actions';
import formDetails from './formDetails/reducer';

const initialState = {
  pending: false,
  chunks: {},
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  selected: [],
  selectAll: false,
  forms: [],
  order: 'desc',
  colSorted: 'date',
  allForms: {},
  formNames: [],
  selectedForm: '',
};

const filterChunks = (chunks, filter = '') => {
  let filteredChunks = chunks;

  if (filter !== '') {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`(${filter})`, 'gi');
      const results = Object.values(chunk.chunks).filter(item =>
        rgx.test(item.value),
      );
      return rgx.test(chunk.title) || results.length > 0;
    });
  }
  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const sortItems = (filteredChunks, sortBy, order) =>
  _keys(filteredChunks).sort((a, b) => {
    let keyA = filteredChunks[a][sortBy];
    let keyB = filteredChunks[b][sortBy];
    if (sortBy === 'date') {
      keyA = new Date(filteredChunks[a][sortBy]);
      keyB = new Date(filteredChunks[b][sortBy]);
    }
    if (sortBy === 'email') {
      filteredChunks[a]['order'].forEach((key, i) => {
        if (filteredChunks[a].chunks[key].label == 'Email') {
          keyA = filteredChunks[a].chunks[key].value.toLowerCase();
        }
      });
      filteredChunks[b]['order'].forEach((key, i) => {
        if (filteredChunks[b].chunks[key].label == 'Email') {
          keyB = filteredChunks[b].chunks[key].value.toLowerCase();
        }
      });
    }

    if (sortBy === 'name') {
      filteredChunks[a]['order'].forEach((key, i) => {
        if (filteredChunks[a].chunks[key].label == 'Name') {
          keyA = filteredChunks[a].chunks[key].value.toLowerCase();
        }
      });
      filteredChunks[b]['order'].forEach((key, i) => {
        if (filteredChunks[b].chunks[key].label == 'Name') {
          keyB = filteredChunks[b].chunks[key].value.toLowerCase();
        }
      });
    }
    if (order === 'desc') {
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
    } else if (order === 'asc') {
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
    }
    return 0;
  });

const form = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FORM_DATA: {
      return {
        ...state,
        pending: true,
        error: null,
      };
    }
    case UPDATE_STATUS: {
      return {
        ...state,
        pending: true,
      };
    }

    case RECEIVE_FORM_DATA: {
      return {
        ...state,
        chunks: action.data,
        items: sortItems(action.data, state.colSorted, state.order),
        pending: false,
        filteredChunks: action.data,
        filter: action.filter,
        allForms: action.allRecords,
        formNames: action.formNames,
        selectedForm: action.selectedForm,
        error: null,
      };
    }
    case CHANGE_FORM: {
      return {
        ...state,
        chunks: action.data,
        pending: false,
        items: sortItems(action.data, state.colSorted, state.order),
        filteredChunks: action.data,
        selectedForm: action.selectedForm,
        error: null,
      };
    }

    case RECEIVE_FORM_DATA_UPDATE: {
      return {
        ...state,
        chunks: action.data,
        items: sortItems(action.data, state.colSorted, state.order),
        ...filterChunks(action.data, state.filter),
        pending: false,
        error: null,
      };
    }

    case REQUEST_ALL_FORMS: {
      return {
        ...state,
        pending: true,
        error: state.error ? state.error : null,
      };
    }

    case RECEIVE_ALL_FORMS: {
      return {
        ...state,
        forms: action.data,
        pending: false,
        error: state.error ? state.error : null,
      };
    }

    case REPORT_FORM_DATA_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.err,
        selected: [],
      };

    case FILTER_FORM_DATA: {
      return {
        ...state,
        filter: action.payload,
        ...filterChunks(state.chunks, action.payload),
      };
    }

    case SELECT_RESPONSE: {
      const prevState = state.filteredChunks[action.key].selected;

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case SELECT_ALL_RESPONSES: {
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: true,
      }));
      const selected = _union(state.selected, _keys(filteredChunks));

      return {
        ...state,
        filteredChunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_RESPONSES:
      return {
        ...state,
        filteredChunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    case FAVOURITE_SELECTED:
      return {
        ...state,
      };

    case DELETE_SELECTED:
      return {
        ...state,
      };

    case SORT_FORM_TABLE_DATA:
      return {
        ...state,
        order: state.order === 'desc' ? 'asc' : 'desc',
        items: sortItems(
          state.filteredChunks,
          action.column.id,
          state.order === 'desc' ? 'asc' : 'desc',
        ),
        colSorted: action.column.id,
      };

    default:
      return state;
  }
};

export default combineReducers({
  data: form,
  formDetails,
});
