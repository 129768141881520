import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import { Trans, withTranslation } from 'react-i18next';
import ResultsTable from 'app/components/resultsTable';
import TryAgain from 'app/components/tryAgain';
import ActionMenu from 'app/components/actionMenu';
import { sortArray, downloadCSV } from 'utils';
import TableCheckbox from '../../components/tableCheckbox';
import Header from '../../components/header';
import NewMemberBody from './modules/create';
import EditMemberBody from './modules/edit';
import { HS_FEATURES_ENABLED_APPS } from '../../../../config';
import './styles.css';
import themeStyles from '../../../../_export.scss';

const isCheckboxDisabled = (error, pending) => error && !pending;

let memberBodyFlag = false;
class MemberBodies extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object.isRequired,
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    chunks: PropTypes.object,
    getMemberBodies: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func.isRequired,
    onSelectAllMemberBodies: PropTypes.func.isRequired,
    onDeselectAllMemberBodies: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    onFilterMemberBodyData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    filter: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: '',
    chunks: {},
    filteredChunks: {},
    items: [],
    query: '',
    selected: [],
    selectAll: false,
  };

  componentWillMount() {
    memberBodyFlag =
      HS_FEATURES_ENABLED_APPS.indexOf(this.props.match.params.appname) > -1;
  }

  getSelectAllCheckBox = () => {
    const {
      error,
      pending,
      chunks,
      selected,
      selectAll,
      onSelectAllMemberBodies,
      onDeselectAllMemberBodies,
      onDeleteSelected,
      t,
    } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllMemberBodies() : onDeselectAllMemberBodies()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDownloadSelected={() => {
            downloadCSV(
              this.getCsvData(selected, chunks),
              memberBodyFlag ? 'member-bodies' : 'event-organizers',
            );
          }}
          onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          text={memberBodyFlag ? t('memberBodies') : t('eventOrganizers')}
        />
      </div>
    );
  };

  getTitle = (selected, memberBodies) => {
    const { t } = this.props;
    return (
      <span>
        {memberBodyFlag ? t('MemberBodies') : t('Event Organizers')}:{' '}
        {selected.length
          ? `${selected.length} / ${Object.keys(memberBodies).length}`
          : Object.keys(memberBodies).length}
      </span>
    );
  };

  getSortedItems = () => {
    let sortedItems = sortArray(
      Object.entries(this.props.filteredChunks),
      'name',
    );
    sortedItems = sortedItems.map(entity => entity[0]);
    return sortedItems;
  };

  getCsvData = (selected, chunks) => {
    const { t } = this.props;
    const csvColumns = [
      memberBodyFlag ? t('Member Body Name') : t('Event Organizer Name'),
      memberBodyFlag ? t('Admin User Name') : t('Contact Name'),
      t('Email'),
      t('Number'),
      t('Date Created'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selected.forEach(key => {
      data += `${chunks[key].name},${
        chunks[key][memberBodyFlag ? 'adminUsername' : 'primaryContactName']
      },${chunks[key][memberBodyFlag ? 'adminEmail' : 'primaryContactEmail']},${
        chunks[key].primaryContactNumber
      },${
        chunks[key].date ? moment(chunks[key].date).format('DD/MM/YYYY') : '-'
      }\n`;
    });

    return data;
  };

  render() {
    const {
      pending,
      items,
      error,
      getMemberBodies,
      chunks,
      onSelect,
      filteredChunks,
      onFilterMemberBodyData,
      onSelectAllMemberBodies,
      onDeselectAllMemberBodies,
      selected,
      onDeleteSelected,
      history,
      match,
      t,
    } = this.props;

    const eventOrganizersInstructions = (
      <p className="instructions-container">
        <p className="instructions-section-1">
          <Trans>
            In order to add an Event or Course, you would need to add an Event
            Organizer. An Event Organizer can be added by clicking on the '+'
            button.
          </Trans>
        </p>
        <p className="instructions-section-2">
          <span>
            <Trans>
              If you wish to display a list of Event organizers in the app for
              your users to see, you can add an Event Organizer component to a
              chosen page in the app.
            </Trans>
          </span>
          <br />
          <span>
            <Trans>
              To do so, visit Build Pages. Click the '+' button to open the
              Components menu. Find the component Event Organizer and add it to
              the page you have selected.
            </Trans>
          </span>
        </p>
          
      </p>
    );
    const body = error ? (
      <TryAgain
        message={t('Oops, something went wrong...')}
        onRetry={getMemberBodies}
      />
    ) : (
      <ResultsTable
        items={this.getSortedItems()}
        chunks={filteredChunks}
        loading={pending}
        pending={pending}
        error={Object.keys(filteredChunks).length === 0 ? 'yes' : null}
        emptyMessage={`${t('Looks like no')} ${
          memberBodyFlag ? t('member bodies') : t('event organizers')
        } ${t('have been added yet')}`}
        optionalMessage={!memberBodyFlag && eventOrganizersInstructions}
        onRowTouchTap={memberBody =>
          history.push(
            `/${match.params.appname}/members/member-bodies/${memberBody}`,
          )
        }
        columns={[
          {
            id: 'name',
            header: memberBodyFlag
              ? t('Member Body Name')
              : t('Event Organizer Name'),
            classNames: 'col-xs-2',
          },
          {
            id: memberBodyFlag ? 'adminUsername' : 'primaryContactName',
            header: memberBodyFlag ? t('Admin User Name') : t('Contact Name'),
            classNames: 'col-xs-2',
          },
          {
            id: memberBodyFlag ? 'adminEmail' : 'primaryContactEmail',
            header: t('Email'),
            classNames: 'col-xs-3',
          },
          {
            id: 'primaryContactNumber',
            header: t('Phone Number'),
            classNames: 'col-xs-2',
          },
          {
            id: 'date',
            header: t('Date Created'),
            classNames: 'col-xs-2',
            containerElement: ({ value }) => (
              <span>{value && moment(value).format('DD/MM/YYYY')}</span>
            ),
          },
          {
            id: 'selected',
            header: this.getSelectAllCheckBox(),
            classNames: `col-xs-1 ${
              items.length < 4 ? 'fix-left' : 'selected'
            }`,
            containerElement: ({ value, rowId }) =>
              rowId !== 'default' && (
                <>
                  <TableCheckbox
                    value={value}
                    onSelect={() => onSelect(rowId)}
                  />
                  <ActionMenu
                    selected={[rowId]}
                    onDownloadSelected={() => {
                      downloadCSV(
                        this.getCsvData([rowId], chunks),
                        memberBodyFlag ? 'member-bodies' : 'event-organizers',
                      );
                    }}
                    onDeleteSelected={() => onDeleteSelected([rowId], chunks)}
                    onEditSelected={() =>
                      history.push(
                        `/${match.params.appname}/members/member-bodies/${rowId}`,
                      )
                    }
                    text={
                      memberBodyFlag ? t('memberBodies') : t('eventOrganizers')
                    }
                  />
                </>
              ),
          },
        ]}
      />
    );

    return (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <Header
          {...this.props}
          title={this.getTitle(selected, chunks)}
          onChange={onFilterMemberBodyData}
          onSelectAllResponses={onSelectAllMemberBodies}
          onDeselectAllResponses={onDeselectAllMemberBodies}
          actionMenu={
            <ActionMenu
              selected={selected}
              onDeleteSelected={() => onDeleteSelected(selected, chunks)}
              text={memberBodyFlag ? t('memberBodies') : t('eventOrganizers')}
            />
          }
        />
        {body}
        <NewMemberBody memberBodyFlag={memberBodyFlag} />
        <EditMemberBody />
      </section>
    );
  }
}

export default withTranslation()(MemberBodies);
