const initialState = {
  pending: false,
  success: false,
  oldPassword: {
    value: '',
  },
  newPassword: {
    value: '',
  },
  confirmPassword: {
    value: '',
  },
  errorMessage: null,
};

const resetPassword = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_PASSWORD_FIELD_CHANGE':
      return {
        ...state,
        [action.prop]: {
          value: action.value,
        },
      };

    case 'INVALIDATE_RESET_PASSWORD_FIELD':
      return {
        ...state,
        [action.prop]: {
          ...state[action.prop],
          isValid: false,
        },
      };

    case 'RESET_PASSWORD_ERROR':
      return {
        ...state,
        pending: false,
        isValid: false,
        errorMessage: action.err.message,
      };

    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...initialState,
        success: true,
      };

    case 'CLEAR_RESET_PASSWORD_FORM':
      return initialState;

    case 'SUBMIT_RESET_PASSWORD':
      return {
        ...state,
        errorMessage: null,
        pending: true,
      };

    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
};

export default resetPassword;
