import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CoursesAttendees from './Course-Attendees';
import { getCourses, selectMemberBody } from '../courses/actions';
import {
  setSelectedCourse,
  getCourseAttendees,
  filterCourseData,
  selectAllCourseAttendees,
  selectAttendee,
  deselectAllCourseAttendees,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.staff.coursesAttendees.listAttendees,
  coursesList: state.staff.courses.list.filteredChunks,
  eventOrganizer: state.staff.courses.list.memberbody,
  profile: state.account.profile,
  memberBodies: state.auth.memberBodies.data.chunks,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectMemberBody: key => dispatch(selectMemberBody(key)),
  setSelectedCourse: courseId => dispatch(setSelectedCourse(courseId)),
  getCourses: () => dispatch(getCourses(ownProps.match.params.appname)),
  getCourseAttendees: () =>
    dispatch(getCourseAttendees(ownProps.match.params.appname)),
  filterCourseData: filter => dispatch(filterCourseData(filter)),
  onSelect: key => dispatch(selectAttendee(key)),
  onSelectAllCourseAttendees: () => dispatch(selectAllCourseAttendees()),
  onDeselectAllCourseAttendees: () => dispatch(deselectAllCourseAttendees()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CoursesAttendees),
);
