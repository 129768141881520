import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import Modal from '../../../modules/account/components/modal';
import { CTAButton } from '../../../app/components/buttons';
import Logo from '../../../app//components/logo';
import './styles.css';

const Container = styled.div`
  padding: 0 15px 15px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-flow: inherit;
  max-height: 100%;
  .img_wrapper {
    position: relative;
    display: block;
    height: 65%;
    img {
      max-width: 65%;
      max-height: 60%;
      padding: 10px 20px 0;
    }
  }
`;

const marginStyle = {
  marginTop: '35px',
};

const AdilloMessage = ({ showMessage, toggleAdilloMessage }) => {
  const handleClose = () => {
    toggleAdilloMessage(false);
  };

  const stateContentText = () => {
    return (
      <span style={marginStyle}>
        <strong>
          <Trans>
            Welcome to Beezer. You're just seconds away from building a
            brilliant progressive web app.
          </Trans>
        </strong>
        <div>
          <p>
            <Trans>
              We're setting up your account and getting your app-building
              platform ready. This won't take long.
            </Trans>
          </p>
        </div>
      </span>
    );
  };

  return (
    <Modal
      open={showMessage}
      onRequestClose={handleClose}
      showHeader={true}
      bodyClassName="beezer-modal"
    >
      <Container>
        {stateContentText()}
        <div className="img_wrapper adillo_img_wrapper">
          {/* <img alt="modal_image" src={adilloLogo} /> */}
          <Logo height={'175px'} width={'175px'} />
        </div>
        <span className="adillo_message">
          <strong>
            <Trans>Special Discount:</Trans>{' '}
          </strong>
          <Trans>
            50% OFF for one year on Everything plan. Click the Upgrade button to
            get your discount
          </Trans>
        </span>
        <CTAButton
          label={<Trans>Customize your app</Trans>}
          // borderColor="rgb(156, 150, 6)"
          outlineRGB={'195, 207, 47'}
          style={{ margin: '10px auto', display: 'block', maxWidth: '230px' }}
          onClick={handleClose}
        />
      </Container>
    </Modal>
  );
};

export default AdilloMessage;
