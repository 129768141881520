const initialState = {
    isPending: false,
    items: []
};

const referralList = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_PENDING_STATE':
            return {
                ...state,
                isPending: action.payload,
            };
        case 'UPDATE_ITEMS':
            return {
                ...state,
                items: action.payload,
            };
        default:
            return state;
    }
};

export default referralList;