const initialState = {
  appMenu: false,
  userMenu: false,
  isPublished: true,
};

const toolbar = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_APP_MENU':
      return {
        ...state,
        appMenu: action.open !== undefined ? action.open : !state.appMenu,
      };

    case 'TOGGLE_USER_MENU':
      return {
        ...state,
        userMenu: action.open !== undefined ? action.open : !state.userMenu,
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default toolbar;
