import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import { size, isEmpty } from 'lodash';
import { whitelabeledHost } from '../../../../../../utils/whitelabeledHost';

import { downloadCSV } from 'utils';
import ResultsTable from 'app/components/resultsTable';
import Table from 'app/components/table';
import Header from 'app/components/header';
import TryAgain from 'app/components/tryAgain';
import ActionMenu from 'app/components/actionMenu';
import { FlatButton } from 'app/components/buttons';
import TableCheckbox from '../../components/tableCheckbox';
import NewFilter from './newFilter';
import EditFilter from './editFilter';
import themeStyles from '../../../../../../_export.scss';
import { Trans, useTranslation } from 'react-i18next';

const isWhiteLabeledHost = whitelabeledHost();
const isCheckboxDisabled = (error, pending) => error && !pending;

const Filters = props => {
  const {
    pending,
    items,
    error,
    getFilters,
    chunks,
    onSelect,
    filteredChunks,
    onFilterData,
    selectAll,
    onSelectAllFilters,
    onDeselectAllFilters,
    selected,
    onDeleteSelected,
    addNewFilter,
    history,
    match,
    isAppAdmin,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    getFilters();
  }, []);

  const getCsvData = (selected, chunks) => {
    const csvColumns = [
      t('Category Name'),
      t('Description'),
      t('Date Created'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selected.forEach(key => {
      data += `${chunks[key].name},${chunks[key].description},${chunks[key]
        .date && moment(chunks[key].date).format('DD/MM/YYYY')}\n`;
    });

    return data;
  };

  const getTitle = (selected, filters) => (
    <span>
      <Trans>Categories</Trans>:{' '}
      {selected.length
        ? `${selected.length} / ${Object.keys(filters).length}`
        : Object.keys(filters).length}
    </span>
  );

  const getSelectAllCheckBox = () => (
    <div style={{ display: 'flex' }}>
      <Checkbox
        checked={selectAll}
        onChange={(e, isChecked) =>
          isChecked ? onSelectAllFilters() : onDeselectAllFilters()
        }
        disabled={isCheckboxDisabled(error, pending)}
        style={{ marginLeft: -2, color: themeStyles.primaryColor }}
        disableRipple
      />
      <ActionMenu
        selected={selected}
        onDeleteSelected={() => onDeleteSelected(selected)}
        onDownloadSelected={() => {
          downloadCSV(getCsvData(selected, chunks), 'filters');
        }}
        text={t('filters')}
      />
    </div>
  );

  const suffixClass = items.length < 4 ? 'fix-left' : 'selected';

  const generateTableColumns = () => [
    {
      id: 'name',
      header: t('Category Name'),
      classNames: 'col-xs-2',
    },
    {
      id: 'description',
      header: t('Description'),
      classNames: 'col-xs-6 description',
      containerElement: ({ value }) => <span>{value}</span>,
    },
    {
      id: 'date',
      header: t('Date Created'),
      classNames: 'col-xs-3',
      containerElement: ({ value }) => (
        <span>{value && moment(value).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      id: 'selected',
      header: getSelectAllCheckBox(),
      classNames: `col-xs-1 ${suffixClass}`,
      containerElement: ({ value, rowId }) =>
        rowId !== 'default' && (
          <>
            <TableCheckbox value={value} onSelect={() => onSelect(rowId)} />
            <ActionMenu
              selected={[rowId]}
              onDeleteSelected={() => onDeleteSelected([rowId])}
              onDownloadSelected={() => {
                downloadCSV(getCsvData([rowId], chunks), 'filter');
              }}
              onEditSelected={() =>
                history.push(
                  `/${match.params.appname}/ecommerce/marketplace/categories/${rowId}`,
                )
              }
              text={t('filter')}
            />
          </>
        ),
    },
  ];

  const getPlaceholderText = () => (
    <>
      <Trans>
        Start by adding some categories of your products and services. All
        product / service categories will be listed under this section. Once you
        have added categories, the sellers will be able to add their product or
        service to a specific category before publishing it in the marketplace.
        To add a new category, click on the + sign next to your profile icon at
        the top right-hand side of your screen.
      </Trans>
      {!isAppAdmin && !isWhiteLabeledHost && (
        <span>
          <Trans>Click</Trans>{' '}
          <a
            href="https://beezer.zendesk.com/hc/en-us/articles/6185359080849#h_01G54FFJQ6D21B1XW9Q41MXN0B"
            rel="noopener noreferrer"
            style={{
              color: '#261ed5',
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
            download
            target="_blank"
          >
            <Trans>here</Trans>
          </a>{' '}
          <Trans>to find out more about categories.</Trans>
        </span>
      )}
    </>
  );

  return (
    <div style={{ height: 'calc(100% - 168px)' }}>
      <Header
        title={getTitle(selected, chunks)}
        onChange={!isEmpty(chunks) ? onFilterData : null}
      />
      {error ? (
        <TryAgain
          message={t('Opps, something went wrong...')}
          onRetry={getFilters}
        />
      ) : (
        <ResultsTable
          items={items}
          chunks={filteredChunks}
          pending={pending}
          error={isEmpty(filteredChunks)}
          columns={generateTableColumns()}
          emptyMessage={getPlaceholderText()}
          onRowTouchTap={filter =>
            history.push(
              `/${match.params.appname}/ecommerce/marketplace/categories/${filter}`,
            )
          }
        />
      )}
      <NewFilter />
    </div>
  );
};

Filters.propTypes = {
  pending: PropTypes.bool.isRequired,
  filteredChunks: PropTypes.object,
  error: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  chunks: PropTypes.object,
  getFilters: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  onSelectAllFilters: PropTypes.func.isRequired,
  onDeselectAllFilters: PropTypes.func.isRequired,
  onDeleteSelected: PropTypes.func.isRequired,
  onFilterData: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  filter: PropTypes.string,
};

Filters.defaultProps = {
  error: null,
  filter: null,
  chunks: {},
  filteredChunks: {},
  items: [],
  query: '',
  selected: [],
  selectAll: false,
};

export default Filters;
