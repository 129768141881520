import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
.st0{fill:#FFFFFF;}
.st1{fill:none;stroke:#D0D2D3;stroke-width:5.1639;stroke-miterlimit:6.8852;}
.st2{fill:#D0D2D3;}
.st3{fill:none;stroke:#F1F1F2;stroke-width:1.7213;stroke-miterlimit:6.8852;}
.st4{fill:none;stroke:#DBDBDB;stroke-width:1.7213;stroke-miterlimit:6.8852;}
.st5{fill:#F1F1F2;}
.st6{fill:none;stroke:#DBDBDB;stroke-width:2.5819;stroke-miterlimit:6.8855;}
.st7{fill:none;stroke:#DBDBDB;stroke-width:2.5819;stroke-miterlimit:6.8852;}
.st8{fill:none;stroke:#D0D2D3;stroke-width:5.1639;stroke-miterlimit:17.2129;}
.st9{fill:#E5E5E5;}
.st10{fill:#FFFFFF;stroke:#CBCBCB;stroke-miterlimit:10;}
.st11{fill:#F6F6F6;}
.st12{fill:#D1D2D2;}
`;

const Blocks = () => (
  <Svg
    className="layout layout-header-large"
    width="750.000000pt"
    height="1334.000000pt"
    viewBox="0 0 750 1334"
    preserveAspectRatio="xMidYMid meet"
  >
    <g id="Layer_2_1_">
	<g id="Layer_1-2">
		<path class="st0" d="M58.3,206.6h-2.4c-1.9,0-3.4-1.5-3.4-3.4V153c0-1.9,1.5-3.4,3.4-3.4h2.4"/>
		<path class="st1" d="M58.3,206.6h-2.4c-1.9,0-3.4-1.5-3.4-3.4V153c0-1.9,1.5-3.4,3.4-3.4h2.4"/>
		<path class="st0" d="M58.3,371.8h-2.1c-1.9,0-3.4-1.5-3.4-3.4v-94c0-1.9,1.5-3.4,3.4-3.4h2.1"/>
		<path class="st1" d="M58.3,371.8h-2.1c-1.9,0-3.4-1.5-3.4-3.4v-94c0-1.9,1.5-3.4,3.4-3.4h2.1"/>
		<path class="st0" d="M691.4,270.9h1.4c2.9,0,5.2,2.3,5.2,5.2v90.5c0,2.9-2.3,5.2-5.2,5.2h-1.4"/>
		<path class="st1" d="M691.4,270.9h1.4c2.9,0,5.2,2.3,5.2,5.2v90.5c0,2.9-2.3,5.2-5.2,5.2h-1.4"/>
		<path class="st0" d="M58.4,496.1H53c-1.9,0,0-1.5,0-3.4v-94c0-1.9-1.9-3.4,0-3.4h5.5"/>
		<path class="st1" d="M58.4,496.1H53c-1.9,0,0-1.5,0-3.4v-94c0-1.9-1.9-3.4,0-3.4h5.5"/>
		<path class="st0" d="M147.6,1331.4c-47.9,0-86.7-38.9-86.8-86.8V89.3c0-47.9,38.9-86.7,86.8-86.8h454.6
			c47.9,0,86.7,38.9,86.8,86.8v1155.3c0,47.9-38.9,86.7-86.8,86.8L147.6,1331.4L147.6,1331.4z"/>
		<path class="st2" d="M602,5.2c46.4,0.1,84.1,37.7,84.2,84.2v1155.3c0,46.5-37.7,84.2-84.2,84.2H147.6
			c-46.4-0.1-84.1-37.7-84.2-84.2V89.3c0-46.5,37.7-84.2,84.2-84.2L602,5.2 M602,0H147.6C98.3,0,58.3,40,58.3,89.3v1155.3
			c0,49.3,40,89.3,89.4,89.3h454.6c49.3,0,89.3-40,89.3-89.3V89.3C691.4,40,651.4,0.1,602,0z"/>
		<path class="st3" d="M670.2,1175.6c0,2.9-2.3,5.2-5.2,5.2H83.7c-2.9,0-5.2-2.3-5.2-5.2V142.9c0.1-2.8,2.3-5.1,5.2-5.2H665
			c2.9,0,5.2,2.3,5.2,5.2V1175.6L670.2,1175.6z"/>
		<path class="st4" d="M670.2,1175.6c0,2.9-2.3,5.2-5.2,5.2H83.7c-2.9,0-5.2-2.3-5.2-5.2V142.9c0.1-2.8,2.3-5.1,5.2-5.2H665
			c2.9,0,5.2,2.3,5.2,5.2V1175.6L670.2,1175.6z"/>
		
			<ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 153.5575 324.3571)" class="st5" cx="267.4" cy="71.9" rx="11.4" ry="11.4"/>
		
			<ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 153.5575 324.3571)" class="st6" cx="267.4" cy="71.9" rx="11.4" ry="11.4"/>
		<circle class="st5" cx="373.4" cy="26.5" r="6.9"/>
		<circle class="st7" cx="373.4" cy="26.5" r="6.9"/>
		<circle class="st8" cx="373.4" cy="1258.3" r="51.8"/>
		<path class="st9" d="M424.2,72.3c0,2.9-2.3,5.2-5.2,5.2h-92.9c-2.9,0-5.2-2.3-5.2-5.2v-0.2c0-2.9,2.3-5.2,5.2-5.2H419
			C421.9,67.1,424.2,69.4,424.2,72.3z"/>
		<path class="st4" d="M424.2,72.3c0,2.9-2.3,5.2-5.2,5.2h-92.9c-2.9,0-5.2-2.3-5.2-5.2v-0.2c0-2.9,2.3-5.2,5.2-5.2H419
			C421.9,67.1,424.2,69.4,424.2,72.3z"/>
	</g>
</g>
<g>
	<g id="change3_1_">
		<rect x="78.5" y="134.1" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_1_">
		<path class="st11" d="M125,208.2c28.9,0,33.4,61.9,45.1,61.9s16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0V134.3
			H78.5v153.5C85.2,262.9,102.7,208.2,125,208.2z M232.3,157.7c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C204.2,170.3,216.8,157.7,232.3,157.7z"/>
	</g>
	<g id="change1_1_">
		<path class="st12" d="M275.8,306.2V300c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			c-11.7,0-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7v18.4H275.8L275.8,306.2z"/>
		<circle class="st12" cx="232.3" cy="185.8" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_2_">
		<rect x="275.8" y="134.1" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_2_">
		<path class="st11" d="M322.4,208.2c28.9,0,33.4,61.9,45.1,61.9s16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0V134.3
			H275.8v153.5C282.6,262.9,300.1,208.2,322.4,208.2z M429.6,157.7c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C401.5,170.3,414.1,157.7,429.6,157.7z"/>
	</g>
	<g id="change1_2_">
		<path class="st12" d="M473.2,306.2V300c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			c-11.7,0-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7v18.4H473.2L473.2,306.2z"/>
		<circle class="st12" cx="429.6" cy="185.8" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_3_">
		<rect x="472.8" y="134.1" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_3_">
		<path class="st11" d="M519.4,208.2c28.9,0,33.4,61.9,45.1,61.9c11.7,0,16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0
			V134.3H472.8v153.5C479.6,262.9,497.1,208.2,519.4,208.2z M626.7,157.7c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C598.5,170.3,611.1,157.7,626.7,157.7z"/>
	</g>
	<g id="change1_3_">
		<path class="st12" d="M670.2,306.2V300c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			s-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7v18.4H670.2L670.2,306.2z"/>
		<circle class="st12" cx="626.7" cy="185.8" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_6_">
		<rect x="79.1" y="309" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_6_">
		<path class="st11" d="M125.7,383.1c28.9,0,33.4,61.9,45.1,61.9s16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0V309.2
			H79.1v153.5C85.9,437.8,103.4,383.1,125.7,383.1z M232.9,332.6c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C204.8,345.2,217.4,332.6,232.9,332.6z"/>
	</g>
	<g id="change1_6_">
		<path class="st12" d="M276.5,481.1v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			c-11.7,0-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7v18.4H276.5L276.5,481.1z"/>
		<circle class="st12" cx="232.9" cy="360.7" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_5_">
		<rect x="276.5" y="309" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_5_">
		<path class="st11" d="M323.1,383.1c28.9,0,33.4,61.9,45.1,61.9c11.7,0,16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0
			V309.2H276.5v153.5C283.3,437.8,300.8,383.1,323.1,383.1z M430.3,332.6c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C402.2,345.2,414.8,332.6,430.3,332.6z"/>
	</g>
	<g id="change1_5_">
		<path class="st12" d="M473.9,481.1v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			c-11.7,0-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7v18.4H473.9L473.9,481.1z"/>
		<circle class="st12" cx="430.3" cy="360.7" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_4_">
		<rect x="473.5" y="309" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_4_">
		<path class="st11" d="M520.1,383.1c28.9,0,33.4,61.9,45.1,61.9s16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0V309.2
			H473.5v153.5C480.3,437.8,497.8,383.1,520.1,383.1z M627.3,332.6c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C599.2,345.2,611.8,332.6,627.3,332.6z"/>
	</g>
	<g id="change1_4_">
		<path class="st12" d="M670.9,481.1v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37S549,383,520.1,383
			c-22.3,0-39.8,54.8-46.6,79.7v18.4H670.9L670.9,481.1z"/>
		<circle class="st12" cx="627.3" cy="360.7" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_9_">
		<rect x="79.3" y="483.9" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_9_">
		<path class="st11" d="M125.9,558c28.9,0,33.4,61.9,45.1,61.9c11.7,0,16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0
			V484.1H79.3v153.5C86.1,612.7,103.6,558,125.9,558z M233.1,507.5c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C205,520.1,217.6,507.5,233.1,507.5z"/>
	</g>
	<g id="change1_9_">
		<path class="st12" d="M276.7,656v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			c-11.7,0-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7V656H276.7L276.7,656z"/>
		<circle class="st12" cx="233.1" cy="535.6" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_8_">
		<rect x="276.7" y="483.9" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_8_">
		<path class="st11" d="M323.3,558c28.9,0,33.4,61.9,45.1,61.9c11.7,0,16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0
			V484.1H276.7v153.5C283.5,612.7,300.9,558,323.3,558z M430.5,507.5c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C402.4,520.1,415,507.5,430.5,507.5z"/>
	</g>
	<g id="change1_8_">
		<path class="st12" d="M474,656v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			c-11.7,0-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7V656H474L474,656z"/>
		<circle class="st12" cx="430.5" cy="535.6" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_7_">
		<rect x="473.7" y="483.9" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_7_">
		<path class="st11" d="M520.3,558c28.9,0,33.4,61.9,45.1,61.9c11.7,0,16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0
			V484.1H473.7v153.5C480.5,612.7,498,558,520.3,558z M627.5,507.5c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C599.4,520.1,612,507.5,627.5,507.5z"/>
	</g>
	<g id="change1_7_">
		<path class="st12" d="M671,656v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37s-16.2-61.9-45.1-61.9
			c-22.3,0-39.8,54.8-46.6,79.7V656H671L671,656z"/>
		<circle class="st12" cx="627.5" cy="535.6" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_12_">
		<rect x="79.5" y="658.8" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_12_">
		<path class="st11" d="M126.1,732.9c28.9,0,33.4,61.9,45.1,61.9s16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0V659
			H79.5v153.5C86.3,787.6,103.8,732.9,126.1,732.9z M233.3,682.4c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C205.2,695,217.8,682.4,233.3,682.4z"/>
	</g>
	<g id="change1_12_">
		<path class="st12" d="M276.8,830.9v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			c-11.7,0-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7v18.4H276.8L276.8,830.9z"/>
		<circle class="st12" cx="233.3" cy="710.5" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_11_">
		<rect x="276.8" y="658.8" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_11_">
		<path class="st11" d="M323.4,732.9c28.9,0,33.4,61.9,45.1,61.9c11.7,0,16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0
			V659H276.8v153.5C283.6,787.6,301.1,732.9,323.4,732.9z M430.7,682.4c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C402.5,695,415.1,682.4,430.7,682.4z"/>
	</g>
	<g id="change1_11_">
		<path class="st12" d="M474.2,830.9v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			s-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7v18.4H474.2L474.2,830.9z"/>
		<circle class="st12" cx="430.7" cy="710.5" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_10_">
		<rect x="473.9" y="658.8" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_10_">
		<path class="st11" d="M520.4,732.9c28.9,0,33.4,61.9,45.1,61.9s16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0V659
			H473.9v153.5C480.7,787.6,498.1,732.9,520.4,732.9z M627.7,682.4c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C599.6,695,612.2,682.4,627.7,682.4z"/>
	</g>
	<g id="change1_10_">
		<path class="st12" d="M671.2,830.9v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			c-11.7,0-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7v18.4H671.2L671.2,830.9z"/>
		<circle class="st12" cx="627.7" cy="710.5" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_15_">
		<rect x="79.1" y="833.7" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_15_">
		<path class="st11" d="M125.7,907.8c28.9,0,33.4,61.9,45.1,61.9c11.7,0,16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0
			V833.9H79.1v153.5C85.9,962.5,103.4,907.8,125.7,907.8z M232.9,857.3c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C204.8,869.9,217.4,857.3,232.9,857.3z"/>
	</g>
	<g id="change1_15_">
		<path class="st12" d="M276.4,1005.8v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			c-11.7,0-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7v18.4H276.4L276.4,1005.8z"/>
		<circle class="st12" cx="232.9" cy="885.4" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_14_">
		<rect x="276.4" y="833.7" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_14_">
		<path class="st11" d="M323,907.8c28.9,0,33.4,61.9,45.1,61.9c11.7,0,16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0
			V833.9H276.4v153.5C283.2,962.5,300.7,907.8,323,907.8z M430.3,857.3c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C402.1,869.9,414.7,857.3,430.3,857.3z"/>
	</g>
	<g id="change1_14_">
		<path class="st12" d="M473.8,1005.8v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			c-11.7,0-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7v18.4H473.8L473.8,1005.8z"/>
		<circle class="st12" cx="430.3" cy="885.4" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_13_">
		<rect x="473.5" y="833.7" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_13_">
		<path class="st11" d="M520.1,907.8c28.9,0,33.4,61.9,45.1,61.9c11.7,0,16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0
			V833.9H473.5v153.5C480.3,962.5,497.7,907.8,520.1,907.8z M627.3,857.3c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C599.2,869.9,611.8,857.3,627.3,857.3z"/>
	</g>
	<g id="change1_13_">
		<path class="st12" d="M670.8,1005.8v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			s-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7v18.4H670.8L670.8,1005.8z"/>
		<circle class="st12" cx="627.3" cy="885.4" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_18_">
		<rect x="79.6" y="1008.6" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_18_">
		<path class="st11" d="M126.2,1082.7c28.9,0,33.4,61.9,45.1,61.9c11.7,0,16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0
			v-165.7H79.6v153.5C86.4,1137.4,103.9,1082.7,126.2,1082.7z M233.5,1032.2c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C205.3,1044.8,218,1032.2,233.5,1032.2z"/>
	</g>
	<g id="change1_18_">
		<path class="st12" d="M277,1180.7v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			c-11.7,0-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7v18.4H277L277,1180.7z"/>
		<circle class="st12" cx="233.5" cy="1060.3" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_17_">
		<rect x="277" y="1008.6" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_17_">
		<path class="st11" d="M323.6,1082.7c28.9,0,33.4,61.9,45.1,61.9c11.7,0,16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0
			v-165.7H277v153.5C283.8,1137.4,301.3,1082.7,323.6,1082.7z M430.8,1032.2c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C402.7,1044.8,415.3,1032.2,430.8,1032.2z"/>
	</g>
	<g id="change1_17_">
		<path class="st12" d="M474.4,1180.7v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			s-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7v18.4H474.4L474.4,1180.7z"/>
		<circle class="st12" cx="430.8" cy="1060.3" r="28.1"/>
	</g>
</g>
<g>
	<g id="change3_16_">
		<rect x="474" y="1008.6" class="st10" width="197" height="172.6"/>
	</g>
	<g id="change2_16_">
		<path class="st11" d="M520.6,1082.7c28.9,0,33.4,61.9,45.1,61.9s16.8-37,33.3-37c22.7,0,34,66.9,72.2,66.9c0,0,0.1,0,0.1,0v-165.7
			H474v153.5C480.8,1137.4,498.3,1082.7,520.6,1082.7z M627.9,1032.2c15.5,0,28.1,12.6,28.1,28.1c0,15.5-12.6,28.1-28.1,28.1
			c-15.5,0-28.1-12.6-28.1-28.1C599.7,1044.8,612.3,1032.2,627.9,1032.2z"/>
	</g>
	<g id="change1_16_">
		<path class="st12" d="M671.4,1180.7v-6.3c0,0-0.1,0-0.1,0c-38.2,0-49.5-66.9-72.2-66.9c-16.5,0-21.7,37-33.3,37
			s-16.2-61.9-45.1-61.9c-22.3,0-39.8,54.8-46.6,79.7v18.4H671.4L671.4,1180.7z"/>
		<circle class="st12" cx="627.9" cy="1060.3" r="28.1"/>
	</g>
</g>
  </Svg>
);

export default Blocks;
