import React, { useState, useEffect } from 'react';
import ImageDialog from './ImageDialog';
import ImagePreview from './imagePreview';
import Thumbnail from './Thumbnail';
import HiddenFileInput from './HiddenFileInput';
import CropDialog from './CropDialog';
import Warning from '../../../../app/components/warning';
import DialogConfirmation from './DialogConfirmation';
import FileAndUrlDialog from './FileAndUrlDialog';
import './styles.css';

const formatsAllowed = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB

const MultipleImageUpload = ({
  open,
  onOpenDialog,
  onCloseDialog,
  onReceiveImage,
  files,
  currentCropIndex,
  selectImage,
  onReceiveCrop,
  error,
  warning,
  onReportError,
  onClearError,
  onSubmit,
  firebaseUrl,
  appName,
  onResetImageUploads,
  pending,
  sources,
  deleteDialog,
  deleteRequestPending,
  onOpenDeleteDialog,
  onCloseDeleteDialog,
  onDeleteImage,
  removeImageFromDraft,
  mainImage,
  updateMainImage,
  onFileAndUrlDialogCancel,
  onOpenFileAndUrlDialog,
  fileAndUrlDialogOpen,
  onFileUploadUrlChange,
  fileUploadUrl,
}) => {
  let fileSelector = React.createRef(null);

  const [deleteImageSrc, setDeleteImageSrc] = useState(null);
  const [srcLocation, setSrcLocation] = useState(null);
  const [deleteMainImage, setDeleteMainImage] = useState(false);
  const [hideUploadDialog, setHideUploadDialog] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const handleBackClick = () => {
    onCloseDialog();
    onResetImageUploads();
  };

  const handleSubmit = () => {
    const setMainImage = !sources;
    onSubmit(firebaseUrl, appName, setMainImage, 'jpeg');
  };

  const generateErrorMsg = (fileSize, fileType, fileFetch) => {
    if (fileSize && fileType && fileFetch) {
      return "File is too large. Please make sure it's under 2MB to and valid file format.";
    } else if (fileSize && fileFetch) {
      return "File is too large. Please make sure it's under 2MB to proceed.";
    } else if (fileType && fileFetch) {
      return 'File does not have valid file format.';
    } else if (fileSize && fileType) {
      return "Some files are too large. Please make sure it's under 2MB to and valid file format.";
    } else if (fileSize) {
      return "Some files are too large. Please make sure it's under 2MB to proceed.";
    } else if (fileType) {
      return 'Some files does not have valid file format.';
    } else if (fileFetch) {
      return 'Cannot get image from this url';
    }
    return '';
  };

  const onFileUpload = async e => {
    onClearError();
    let itemsProcessed = 0;
    let fileSizeError = false;
    let fileTypeError = false;
    let fileFetchError = false;

    let file;
    if (!e.target.files && fileUploadUrl) {
      try {
        const res = await fetch(fileUploadUrl);
        file = await res.blob(); // Gets the response and returns it as a blob
        if (!formatsAllowed.includes(file.type) || file.size > MAX_IMAGE_SIZE) {
          fileFetchError = true;
        }
      } catch (err) {
        console.log(err);
        setUploadError(
          'Invalid URL. Please right click on the image and select "Copy Image Address"',
        );
        fileFetchError = true;
        setHideUploadDialog(true);
        handleBackClick();
      }
    }
    const allFiles = e.target && e.target.files ? [...e.target.files] : [file];
    allFiles.forEach(file => {
      itemsProcessed++;
      if (file && file.size > MAX_IMAGE_SIZE) {
        fileSizeError = true;
      }
      if (
        file &&
        formatsAllowed.includes(file.type) &&
        file.size < MAX_IMAGE_SIZE
      ) {
        file.preview = window.URL.createObjectURL(file);
        onReceiveImage(file);
      } else {
        fileTypeError = true;
      }
      if (itemsProcessed === e.target ? e.target.files.length : files.length) {
        if (fileSizeError || fileTypeError || fileFetchError) {
          onFileAndUrlDialogCancel();
          onReportError(
            generateErrorMsg(fileSizeError, fileTypeError, fileFetchError),
          );
        }
      }
      setHideUploadDialog(true);
      // onFileAndUrlDialogCancel();
      onFileUploadUrlChange('');
      if (!fileFetchError) {
        onOpenDialog();
      }
    });
  };

  const openFileSelector = () => {
    fileSelector.click();
  };

  const onReceiveCropImage = crop => {
    onReceiveCrop(currentCropIndex, crop);
  };

  const deleteImage = (location, src, isMainImage) => {
    setDeleteImageSrc(src);
    setSrcLocation(location);
    setDeleteMainImage(isMainImage);
    onOpenDeleteDialog();
  };

  const onChangeMainImage = node => {
    updateMainImage(firebaseUrl, node);
  };

  const errorMessage = (error || warning) && (
    <Warning>{error || warning}</Warning>
  );

  return (
    <div className="gallery-wrapper">
      <ImagePreview
        pending={pending}
        handleClick={onOpenFileAndUrlDialog}
        sources={sources}
        deleteImage={deleteImage}
        mainImage={mainImage}
        onChangeMainImage={onChangeMainImage}
        appName={appName}
      />
      <HiddenFileInput
        innerRef={node => {
          fileSelector = node;
        }}
        onChange={onFileUpload}
      />
      <ImageDialog
        open={open}
        title="Gallery"
        cancelLabel={'Back'}
        submitLabel={'Submit'}
        changeLabel={'Add More'}
        submitDisabled={!(files.length > 0)}
        changeDisabled={false}
        onCancel={handleBackClick}
        onSubmit={() => {
          handleSubmit();
          onFileAndUrlDialogCancel();
        }}
        onChange={() => {
          onOpenFileAndUrlDialog();
          setHideUploadDialog(false);
        }}
      >
        <div className="image-dialog">
          <div className="crop-section-container">
            <div className="crop-section">
              <CropDialog
                onReceiveCrop={onReceiveCropImage}
                currentCropIndex={currentCropIndex}
                file={files[currentCropIndex]}
              />
              {errorMessage}
            </div>
          </div>
          <div className="thumbnail-section-container">
            <div className="thumbnail-section">
              <Thumbnail
                files={files}
                imageHandler={selectImage}
                currentCropIndex={currentCropIndex}
                removeImageFromDraft={removeImageFromDraft}
              />
            </div>
          </div>
        </div>
      </ImageDialog>
      <DialogConfirmation
        open={deleteDialog}
        onClose={() => {
          onCloseDeleteDialog();
          setDeleteImageSrc(null);
          setSrcLocation(null);
          setDeleteMainImage(false);
        }}
        message="Are you sure you want to remove this image?"
        action={() => {
          onDeleteImage(
            deleteImageSrc,
            firebaseUrl,
            `${firebaseUrl}/${srcLocation.substring(
              0,
              srcLocation.length - 6,
            )}`,
            deleteMainImage,
          ).finally(() => {
            setDeleteImageSrc(null);
            setSrcLocation(null);
            setDeleteMainImage(false);
          });
        }}
        pending={deleteRequestPending}
      />

      <FileAndUrlDialog
        open={fileAndUrlDialogOpen}
        onCancel={() => {
          onFileAndUrlDialogCancel();
          handleBackClick();
        }}
        openFileSelector={openFileSelector}
        url={fileUploadUrl}
        onInputChange={onFileUploadUrlChange}
        handleFileChange={onFileUpload}
        appName={appName}
        hideUploadDialog={hideUploadDialog}
        uploadError={uploadError}
        setUploadError={setUploadError}
      />
    </div>
  );
};
export default MultipleImageUpload;
