import styled from 'styled-components';

const RoundedThin = styled.button`
  color: #333;
  text-align: center;
  font-size: 11px;
  height: 35px;
  padding: 0 20px;
  font-weight: 800;
  border-radius: 20px;
  transition: background-color 0.5s ease-in-out;
  background-color: ${props => props.theme.mainWhite};
  &:hover {
    background-color: ${props => props.theme.mainGrey};
  }
  &.success {
    color: ${props => props.theme.primaryColor};
    background-color: ${props => props.theme.secondaryColor};
    &:hover {
      background-color: ${props => props.theme.secondaryColor};
    }
  }
  &.publish {
    height: 35px;
    padding: 0 40px;
    font-size: 20px;
    font-weight: bold;
    color: ${props => props.theme.primaryTextColor};
    background-color: ${props => props.theme.primaryColor};
    &:hover {
      box-shadow: 0px 0px 15px ${props => props.theme.primaryColorLight};
    }
  }
  &.info {
    color: ${props => props.theme.mainWhite};
    background-color: ${props => props.theme.infoColor};
    &:hover {
      background-color: ${props => props.theme.infoColorDark};
    }
  }
`;

export default RoundedThin;
