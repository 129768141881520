import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';

import Container from '../../../../../../../app/components/container';
import TextField from '../../../../../../../app/components/textField';
import TextArea from '../../../../../../../app/components/textArea';
import SectionHeader from '../../../../../components/sectionHeader';
import ImageUpload from '../../../../../internals/imageUpload';
import SocialLinks from '../socialLinks';
import TextEditor from '../../../../../../../app/components/textEditor';

class RosterItem extends Component {
  static propTypes = {
    component: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  getAvatarUrl = () => {
    const { match, location, component } = this.props;

    const item = queryString.parse(location.search).item;

    return `apps/${match.params.appname}/draft/lazy_data/${component.lazy_data}/items/chunks/${item}/avatar`;
  };

  handleChange = field => value => this.props.onChange(field, value);

  render() {
    const { avatar, title, subtitle, text, company } = this.props.data;
    const { t } = this.props;
    const toolbarButtons = {
      fonts: true,
      fontSize: true,
      bold: true,
      italic: true,
      underline: true,
      url: true,
      image: true,
      video: true,
      orderedList: true,
      unorderedList: true,
      negativeIntent: true,
      positiveIndent: true,
      textAlign: true,
      textColor: true,
      textBackground: true,
      clearTextFormatting: true,
      codeBlock: true,
      blockquote: true,
    };

    return (
      <Container>
        <SectionHeader
          title={t('Avatar')}
          tooltip={t('Upload an image for the avatar')}
        />
        <ImageUpload
          title={t('Roster Item > Avatar')}
          src={avatar}
          targetId="roster_item"
          firebaseUrl={this.getAvatarUrl()}
          tooltip={t('Minimum image size 240x240 pixels')}
          height="160px"
          width="160px"
          avatar
        />
        <TextField
          id="roster-item-title"
          value={title}
          onChange={this.handleChange('title')}
          placeholder={t('Title')}
        />
        <TextField
          id="roster-item-subtitle"
          value={subtitle}
          onChange={this.handleChange('subtitle')}
          placeholder={t('Subtitle')}
        />
        <TextField
          id="roster-item-company"
          value={company}
          onChange={this.handleChange('company')}
          placeholder={t('Company')}
        />
        <TextEditor
          id="roster-item-text"
          value={text}
          onChange={this.handleChange('text')}
          placeholder={t('Description/Bio')}
          toolbar={toolbarButtons}
        />

        <SocialLinks data={this.props.data} component={this.props.component} />
      </Container>
    );
  }
}

export default withTranslation()(RosterItem);
