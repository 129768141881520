import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Staff from './Staff';
import {
  selectStaff,
  selectAllStaffs,
  deselectAllStaffs,
  deleteStaffs,
  filterStaffData,
  getStaffs,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  pending: state.auth.staff.data.pending,
  chunks: state.auth.staff.data.chunks,
  filteredChunks: state.auth.staff.data.filteredChunks,
  error: state.auth.staff.data.error,
  items: state.auth.staff.data.items,
  filter: state.auth.staff.data.filter,
  selected: state.auth.staff.data.selected,
  selectAll: state.auth.staff.data.selectAll,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelect: key => dispatch(selectStaff(key)),
  onSelectAllStaffs: () => dispatch(selectAllStaffs()),
  onDeselectAllStaffs: () => dispatch(deselectAllStaffs()),
  onDeleteSelected: () => dispatch(deleteStaffs(ownProps.match.params.appname)),
  onFilterStaffData: filter => dispatch(filterStaffData(filter)),
  getStaffs: () => dispatch(getStaffs(ownProps.match.params.appname)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
