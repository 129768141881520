import firebase from '../../../../../../../firebase';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';

export const htmlChange = (appname, dataId, value, type) => dispatch => {
  try {
    const dataRef = firebase
      .database()
      .ref(`apps/${appname}/draft/lazy_data/${dataId}`);
    if (type) {
      dataRef.child(type).set(value);
    } else {
      dataRef.child('content').set(value);
    }
    dispatch(openSnackbar('HTML code submitted successfully.'));
    return {
      type: 'HTML_FIELD_CHANGE',
      appname,
      dataId,
      value,
    };
  } catch (error) {
    console.log(error);
  }
};
