const pricing = {
  monthly: {
    title: 'Monthly',
    singular: 'month',
    multiplier: 1,
    comparison: 1,
  },
  yearly: {
    title: 'Yearly',
    singular: 'year',
    multiplier: 12,
    comparison: 0.8,
  },
  lifetime: {
    title: 'Lifetime',
    singular: 'lifetime',
    multiplier: 1,
    comparison: 1,
  },
};

export default pricing;
