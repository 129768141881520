import { connect } from 'react-redux';

import NewUserForm from '../../newUser';

import { validateUser } from './actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(validateUser(ownProps.onNextStep)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewUserForm);
