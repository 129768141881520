const initialState = {
  open: false,
  addon_payload: {},
  loading: false,
  nextInvoiceAmount: 0,
  addon: null,
};

const authUpgradeDialog = (state = initialState, action) => {
  switch (action.type) {
    case 'UPGRADE_AUTH_MODEL':
      return {
        open: action.open,
        loading: action.loading || false,
        addon: action.addon || null,
      };

    case 'UPGRADE_AUTH_MODEL_ADDON':
      return {
        ...state,
        open: action.payload.open,
        addon_payload: action.payload.addon_payload,
      };
    case 'UPGRADE_AUTH_MODEL_INVOICE_AMOUNT':
      return {
        ...state,
        nextInvoiceAmount: action.payload,
      };

    default:
      return state;
  }
};

export default authUpgradeDialog;
