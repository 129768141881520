import React from 'react';

const Pages = () => (
  <svg
    className="icon pages"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30.72 44.63"
  >
    <title>Pages</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M30.72,0H3V3.12H0v41.5H27.75V41.5h3ZM26.25,43.12H1.5V4.62H26.25Zm3-3.12H27.75V3.12H4.47V1.5H29.22Z"
        />
        <rect class="cls-1" x="4.34" y="10" width="18.75" height="1.5" />
        <rect class="cls-1" x="4.34" y="13.75" width="18.75" height="1.5" />
        <rect class="cls-1" x="4.34" y="17.5" width="18.75" height="1.5" />
        <rect class="cls-1" x="4.34" y="21.25" width="18.75" height="1.5" />
        <rect class="cls-1" x="4.34" y="25" width="18.75" height="1.5" />
        <rect class="cls-1" x="4.34" y="28.75" width="18.75" height="1.5" />
        <rect class="cls-1" x="4.34" y="32.5" width="18.75" height="1.5" />
        <rect class="cls-1" x="4.34" y="36.25" width="18.75" height="1.5" />
      </g>
    </g>
  </svg>
);

export default Pages;
