import React from 'react';
import { AccessAlarm, Book, ListAlt } from '@material-ui/icons';
import {
  Distribute as DistributeIcon,
  Engage as EngageIcon,
  Analytics as AnalyticsIcon,
  MemberLogin as MemberLoginIcon,
  EcommerceIcon,
  PgmolIcon,
} from '../../components/icons';

import {
  STAFF_ADMIN,
  HS_FEATURES_ENABLED_APPS,
  FOOTBALL_ACADEMY_APPS,
  PGMOL_APPS,
} from '../../../config';

const superadmin = [
  {
    key: 'distribute',
    label: 'Distribute',
    href: '/distribute',
    icon: <DistributeIcon />,
  },
  {
    key: 'engage',
    label: 'Engage',
    href: '/engage',
    icon: <EngageIcon />,
  },
  {
    key: 'analytics',
    label: 'Analytics',
    href: '/analytics',
    icon: <AnalyticsIcon />,
  },
  {
    key: 'member-login',
    label: 'Member Login',
    href: '/members',
    icon: <MemberLoginIcon />,
  },
  {
    key: 'E-Commerce',
    label: 'Business Tools',
    href: '/ecommerce',
    icon: <EcommerceIcon />,
  },
];

const footballMenu = [
  {
    key: 'distribute',
    label: 'Distribute',
    href: '/distribute',
    icon: <DistributeIcon />,
  },
  {
    key: 'engage',
    label: 'Engage',
    href: '/engage',
    icon: <EngageIcon />,
  },
  {
    key: 'analytics',
    label: 'Analytics',
    href: '/analytics',
    icon: <AnalyticsIcon />,
  },
  {
    key: 'member-login',
    label: 'Member Login',
    href: '/members',
    icon: <MemberLoginIcon />,
  },
  {
    key: 'E-Commerce',
    label: 'Business Tools',
    href: '/ecommerce',
    icon: <EcommerceIcon />,
  },
  {
    key: 'Football',
    label: 'Football',
    href: '/fkacademy',
    icon: <EcommerceIcon />,
  },
];
const horseScotlandStaffAdmin = [
  {
    key: 'member-body',
    label: 'Member Body',
    href: '/staff/member-bodies',
    icon: <DistributeIcon />,
  },
  {
    key: 'events',
    label: 'Events',
    href: '/staff/events',
    icon: <AccessAlarm>Outlined</AccessAlarm>,
  },
  {
    key: 'courses',
    label: 'Courses',
    href: '/staff/courses',
    icon: <Book>Outlined</Book>,
  },
  {
    key: 'cpds',
    label: 'CPDs',
    href: '/staff/cpds',
    icon: <ListAlt>Outlined</ListAlt>,
  },
];

const staffAdmin = [
  {
    key: 'event-organizers',
    label: 'Your Event Organizer',
    href: '/staff/member-bodies',
    icon: <DistributeIcon />,
  },
  {
    key: 'events',
    label: 'Events',
    href: '/staff/events',
    icon: <AccessAlarm>Outlined</AccessAlarm>,
  },
  {
    key: 'courses',
    label: 'Courses',
    href: '/staff/courses',
    icon: <Book>Outlined</Book>,
  },
];

const pgmolMenu = [
  {
    key: 'distribute',
    label: 'Distribute',
    href: '/distribute',
    icon: <DistributeIcon />,
  },
  {
    key: 'engage',
    label: 'Engage',
    href: '/engage',
    icon: <EngageIcon />,
  },
  {
    key: 'analytics',
    label: 'Analytics',
    href: '/analytics',
    icon: <AnalyticsIcon />,
  },
  {
    key: 'member-login',
    label: 'Member Login',
    href: '/members',
    icon: <MemberLoginIcon />,
  },
  {
    key: 'pgmol',
    label: 'PGMOL',
    href: '/pgmol',
    icon: <PgmolIcon />,
  },
  {
    key: 'E-Commerce',
    label: 'Business Tools',
    href: '/ecommerce',
    icon: <EcommerceIcon />,
  },
];

export const usersMenu = (role, appname) => {
  if (FOOTBALL_ACADEMY_APPS.indexOf(appname) > -1) {
    return footballMenu;
  } else if (PGMOL_APPS.indexOf(appname) > -1) {
    return pgmolMenu;
  } else if (role === STAFF_ADMIN) {
    if (HS_FEATURES_ENABLED_APPS.indexOf(appname) > -1) {
      return horseScotlandStaffAdmin;
    } else {
      return staffAdmin;
    }
  } else {
    return superadmin;
  }
};
