import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SignupAs from './SignupAs';

import { toggleSignupAs, updateSelectedGroups } from './actions';

const mapStateToProps = (state, ownProps) => {
  const defaultGroup = state.auth.groups.data.chunks.default;
  return {
    ...ownProps,
    enabled:
      defaultGroup && defaultGroup.signupAs && defaultGroup.signupAs.enabled
        ? defaultGroup.signupAs.enabled
        : false,
    signupGroups:
      defaultGroup && defaultGroup.signupAs && defaultGroup.signupAs.groups
        ? defaultGroup.signupAs.groups
        : [],
    chunks: state.auth.groups.data.chunks,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleSignupAs: value =>
    dispatch(toggleSignupAs(ownProps.match.params.appname, value)),
  updateSelectedGroups: value =>
    dispatch(updateSelectedGroups(ownProps.match.params.appname, value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignupAs),
);
