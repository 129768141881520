import React from 'react';

const Create = () => (
  <svg
    className="icon create"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <g id="Layer_1" data-name="Layer 1">
      <path d="M16,32a16,16,0,1,1,11.31-4.68A15.95,15.95,0,0,1,16,32ZM16,2a14,14,0,1,0,9.9,4.1A13.91,13.91,0,0,0,16,2Z" />
      <path d="M16,24.71a1,1,0,0,1-1-1V8.29a1,1,0,0,1,2,0V23.71A1,1,0,0,1,16,24.71Z" />
      <path d="M23.71,17H8.28a1,1,0,1,1,0-2H23.71a1,1,0,1,1,0,2Z" />
    </g>
  </svg>
);

export default Create;
