import { connect } from 'react-redux';

import ReferralsList from './ReferralsList';
import { fetchReferrals } from './actions';

const mapStateToProps = state => ({
    ...state.account.referral.referralList
});

const mapDispatchToProps = dispatch => ({
    fetchReferrals: () => dispatch(fetchReferrals()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReferralsList);
