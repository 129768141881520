export const SET_EVENT_ATTENDEE_DETAILS = 'SET_EVENT_ATTENDEE_DETAILS';
export const CLOSE_VIEW_ATTENDEE_DIALOG = 'CLOSE_VIEW_ATTENDEE_DIALOG';

export const closeDialog = () => ({ type: CLOSE_VIEW_ATTENDEE_DIALOG });

export const getAttendeeDetails = (app, history, attendee) => (
  dispatch,
  getState,
) => {
  //   dispatch(loadingStaff());
  const { chunks } = getState().staff.eventsAttendees.listAttendees;
  const attendeeDetails = chunks[attendee];
  if (attendeeDetails) {
    const data = {
      name: attendeeDetails.name,
      userType: attendeeDetails.userType,
      email: attendeeDetails.email,
      date: attendeeDetails.created_at,
      message: attendeeDetails.message,

      loading: false,
    };
    dispatch({ type: SET_EVENT_ATTENDEE_DETAILS, value: data });
  } else {
    dispatch(closeDialog());
    history.push(`/${app}/members/events-attendees`);
  }
};

export const closeModal = (app, history) => dispatch => {
  dispatch(closeDialog());
  history.push(`/${app}/members/events-attendees`);
};
