import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';
import MenuItem from 'material-ui/MenuItem';
import { Trans, useTranslation } from 'react-i18next';

import { FlatButton } from '../../../../../app/components/buttons';
import { CircularProgress } from '../../../../../app/components/progress';
import CircularValidation from '../../../../../app/components/circularValidation';
import TextField from '../../../../../app/components/textField';
import MobileTextField from '../../../../../app/components/mobileTextField';
import Container from '../../../../../app/components/container';
import SelectField from '../../../../../app/components/selectField';
import { countries } from '../../../utils';

const countryOptions = isIntergration =>
  countries(isIntergration).map(country => (
    <MenuItem
      key={country.code}
      value={JSON.stringify(country)}
      primaryText={country.name}
    />
  ));

const EditContactDialog = ({
  open,
  name,
  email,
  mobile,
  onSubmit,
  pending,
  isDirty,
  onFieldChange,
  onRequestClose,
  country,
  isIntegration,
  location,
  history,
  match,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    if (location.cameFromDrivers) {
      history.replace();
      history.push(`/${match.params.appname}/ecommerce/drivers`);
    }
    onRequestClose();
  };

  console.log({ cameFromDrivers: location.cameFromDrivers });

  return (
    <Dialog open={open} onRequestClose={handleClose} autoScrollBodyContent>
      <div className="contacts-form">
        <div>
          <h5>
            <Trans>Edit Contact</Trans>
          </h5>
          <div className="modal-controls-container">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
          <TextField
            id="name"
            placeholder={t('Name')}
            {...name}
            onChange={value => onFieldChange('name', value)}
            rightInputAction={
              <CircularValidation
                isValid={name.isValid}
                errorMsg={t('Name must be at least 2 characters long')}
                defaultMsg={t("Contact's name required")}
                showDefault={!name.value}
              />
            }
          />
          <TextField
            id="email"
            placeholder={t('Email')}
            {...email}
            onChange={value => onFieldChange('email', value)}
            rightInputAction={
              <CircularValidation
                isValid={email.isValid}
                errorMsg={t('Email address is invalid')}
                defaultMsg={t('Email or phone number required')}
                showDefault={!email.value && !mobile.value}
              />
            }
          />
          <SelectField
            value={JSON.stringify(country.value)}
            label={t('Please select the contacts country')}
            options={countryOptions(isIntegration)}
            onChange={(value, countrySelected) =>
              onFieldChange(
                'country',
                JSON.parse(countrySelected),
                mobile.value,
              )
            }
          />
          <MobileTextField
            id="number"
            placeholder={t('Mobile')}
            {...mobile}
            onChange={value => onFieldChange('mobile', value, country.value)}
            dialCode={country.value ? country.value.dialCode : '44'}
            rightInputAction={
              <CircularValidation
                isValid={mobile.isValid}
                errorMsg={t('Mobile number must have 11 digits')}
                defaultMsg={t('Email or phone number required')}
                showDefault={!email.value && !mobile.value}
              />
            }
          />
        </div>
        <Container>
          {!pending && (
            <FlatButton
              label={t('Save Changes')}
              disabled={
                !isDirty ||
                name.value.length < 2 ||
                name.isValid === false ||
                mobile.isValid === false ||
                email.isValid === false ||
                (!email.value && !mobile.value)
              }
              onClick={() => onSubmit(location.cameFromDrivers, history)}
              primary
            />
          )}
          {pending && <CircularProgress size={12} />}
        </Container>
      </div>
    </Dialog>
  );
};

EditContactDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  name: PropTypes.object.isRequired,
  email: PropTypes.object.isRequired,
  mobile: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  country: PropTypes.shape({
    value: PropTypes.shape({
      code: PropTypes.string.isRequired,
      dialCode: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isIntegration: PropTypes.bool.isRequired,
};

export default EditContactDialog;
