import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { Tab } from 'material-ui/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TabsMui from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/styles';
import Download from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from 'material-ui/svg-icons/action/info';

import { Empty as EmptyIcon } from '../../../../../../../../app/components/icons';
import { objectToArray } from '../../../../../../../../utils';
import FormFields from '../../components/form';
import { FlatButton } from '../../../../../../../../app/components/buttons';
import { CircularProgress } from '../../../../../../../../app/components/progress';
// import Tabs from '../../../../../../../account/components/tabs';

import './styles.css';

const Tabs = withStyles({
  root: {
    borderBottom: '4px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(TabsMui);

const styles = {
  tab: {
    fontSize: '11px',
    height: 40,
    width: 120,
    backgroundColor: '#fff',
  },
  tabInner: {
    textAlign: 'center',
  },
};

const useStyles = makeStyles({
  panel: {
    width: '100%',
  },
});

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box style={styles.noPadding} p={3}>
        {children}
      </Box>
    )}
  </Typography>
);
TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const Groups = props => {
  const {
    pending,
    onSubmit,
    error,
    allGroups,
    workforceGroups,
    savedData,
    onFieldChange,
    deleteFile,
    updateStatus,
    updateCOCStatus,
    subscriptionGroups,
  } = props;

  const selectedGroups = workforceGroups ? objectToArray(workforceGroups) : [];
  const [subStatus, setSubStatus] = useState(false);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const result =
      subscriptionGroups &&
      subscriptionGroups['-LzzeESav5w5QNIuVWPj'] &&
      subscriptionGroups['-LzzeESav5w5QNIuVWPj'];

    if (result && result.status === 'active') setSubStatus(true);
  }, [subscriptionGroups]);

  const displayError = error ? (
    <div style={{ color: 'red', textAlign: 'center' }}>
      <strong>{error}</strong>
    </div>
  ) : null;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();
  const tabs = selectedGroups.map(group =>
    allGroups[group.id] ? (
      <Tab key={`tab-${group.id}`} label={allGroups[group.id].name} />
    ) : (
      false
    ),
  );

  const tabsPanel = selectedGroups.map((group, index) => {
    const codeofConduct =
      savedData[group.id] && savedData[group.id].codeofConduct;
    const isCOC = allGroups[group.id].isCOC && allGroups[group.id].cOCDoc;
    // const disabled =
    //   isCOC &&
    //   (!codeofConduct || (codeofConduct && codeofConduct.status !== 'active'));

    return allGroups[group.id] ? (
      <TabPanel
        key={`tab-${group.id}`}
        value={value}
        index={index}
        className={classes.panel}
      >
        <Grid container spacing={3} key={`container-${group.id}`}>
          <Grid item xs={8}>
            <FormFields
              key={`form-${group.id}`}
              {...{
                type: { value: group.id },
                onFieldChange,
                groups: allGroups,
                fieldsData: savedData[group.id],
                deleteFile,
              }}
            />
            {displayError}
            <div style={styles.tabInner}>
              {!pending ? (
                <FlatButton
                  label={'Save'}
                  key={`button-${group.id}`}
                  onClick={() => onSubmit(group.id)}
                  disabled={
                    !savedData[group.id] ||
                    !!objectToArray(savedData[group.id]).find(
                      i =>
                        !i.value.isValid &&
                        i.id !== 'createdAt' &&
                        i.id !== 'status' &&
                        i.id !== 'isOnline' &&
                        i.id !== 'codeofConduct' &&
                        i.id !== 'updatedAt',
                    )
                  }
                  primary
                />
              ) : (
                <CircularProgress size={18} />
              )}
            </div>
          </Grid>
          <Grid item xs={1} style={{ maxWidth: 1 }}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs={3} align="center">
            <Grid container spacing={0} justify="center" align="center">
              {isCOC && codeofConduct && (
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: '21px' }}
                  >
                    Code of Conduct
                  </Typography>
                  <Typography variant="overline" display="block" gutterBottom>
                    <Chip
                      variant="outlined"
                      size="medium"
                      label={
                        codeofConduct && codeofConduct.status
                          ? codeofConduct.status
                          : 'Not Uploaded'
                      }
                    />
                    {codeofConduct && codeofConduct.path && (
                      <Tooltip title="Download Agreement" placement="top">
                        <IconButton
                          aria-label="Download"
                          style={{ display: 'inline-block' }}
                          color="primary"
                          onClick={() =>
                            window.open(codeofConduct.path, '_blank')
                          }
                        >
                          <Download fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    )}

                    {codeofConduct &&
                      codeofConduct.path &&
                      codeofConduct.status === 'active' && (
                        <React.Fragment>
                          <Tooltip
                            title="Decline and Request Again"
                            placement="top"
                          >
                            <IconButton
                              style={{ display: 'inline-block' }}
                              display="inline-block"
                              aria-label="Disapprove"
                              onClick={() =>
                                updateCOCStatus(group.id, {
                                  status: 'rejected',
                                  path: codeofConduct.path,
                                })
                              }
                              color="secondary"
                            >
                              <ClearIcon fontSize="large" />
                            </IconButton>
                          </Tooltip>
                        </React.Fragment>
                      )}
                  </Typography>
                  {codeofConduct && codeofConduct.status === 'pending' && (
                    <div>
                      <Tooltip title="Approve Agreement" placement="top">
                        <IconButton
                          aria-label="Approve"
                          style={{ display: 'inline-block' }}
                          color="primary"
                          onClick={() =>
                            updateCOCStatus(group.id, {
                              status: 'active',
                              path: codeofConduct.path,
                            })
                          }
                        >
                          <CheckIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Decline and Request Again"
                        placement="top"
                      >
                        <IconButton
                          style={{ display: 'inline-block' }}
                          display="inline-block"
                          aria-label="Disapprove"
                          onClick={() =>
                            updateCOCStatus(group.id, {
                              status: 'rejected',
                              path: codeofConduct.path,
                            })
                          }
                          color="secondary"
                        >
                          <ClearIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: '21px' }}
                >
                  Group Status
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  gutterBottom
                  style={{ marginTop: '18px' }}
                >
                  <Chip
                    variant="outlined"
                    size="medium"
                    label={
                      savedData[group.id] ? savedData[group.id].status : ''
                    }
                  />
                </Typography>
                <Tooltip title="Approve" placement="top">
                  <IconButton
                    aria-label="Approve"
                    style={{ display: 'inline-block' }}
                    disabled={
                      savedData[group.id] &&
                      savedData[group.id].status === 'active'
                    }
                    color="primary"
                    onClick={() => updateStatus(group.id, 'active')}
                  >
                    <CheckIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Decline" placement="top">
                  <IconButton
                    style={{ display: 'inline-block' }}
                    display="inline-block"
                    aria-label="Disapprove"
                    disabled={
                      savedData[group.id] &&
                      savedData[group.id].status === 'rejected'
                    }
                    onClick={() => updateStatus(group.id, 'rejected')}
                    color="secondary"
                  >
                    <ClearIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
                {/* {isCOC &&
                  ((codeofConduct && codeofConduct.status !== 'active') ||
                    !codeofConduct) && (
                    <Tooltip
                      title="You can't update group status until the code of conduct has approved"
                      placement="top"
                    >
                      <IconButton
                        style={{ display: 'inline-block' }}
                        display="inline-block"
                      >
                        <InfoIcon
                          color="#70C5E7"
                          style={{ height: 18, width: 18 }}
                        />
                      </IconButton>
                    </Tooltip>
                  )} */}
                <Tooltip title="Approve or Dispprove a member" placement="top">
                  <IconButton
                    style={{ display: 'inline-block' }}
                    display="inline-block"
                  >
                    <InfoIcon
                      color="#70C5E7"
                      style={{ height: 18, width: 18 }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
              {selectedGroups[value] &&
                selectedGroups[value].id === '-LzzeESav5w5QNIuVWPj' && (
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ marginTop: '21px' }}
                    >
                      Community Member Subscription Status
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      gutterBottom
                      style={{ marginTop: '18px' }}
                    >
                      <Chip
                        variant="outlined"
                        size="medium"
                        color={`${subStatus ? 'primary' : 'secondary'}`}
                        label={subStatus ? 'Active' : 'Inactive'}
                      />
                    </Typography>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
    ) : (
      false
    );
  });

  return (
    <div className="group-tabs-container">
      <AppBar
        position="static"
        color="default"
        style={{ backgroundColor: '#fff' }}
      >
        {selectedGroups.length > 0 ? (
          <React.Fragment>
            <Paper elevation={0}>
              <Tabs
                value={value}
                indicatorColor="primary"
                variant="scrollable"
                onChange={handleChange}
                aria-label="Reports"
                scrollButtons="auto"
              >
                {tabs}
              </Tabs>
            </Paper>
            {tabsPanel}
          </React.Fragment>
        ) : (
          <div className="empty-state">
            <EmptyIcon />
            <h5>Looks like no group assigned yet</h5>
          </div>
        )}
      </AppBar>
    </div>
  );
};

Groups.propTypes = {
  error: PropTypes.string,
  allGroups: PropTypes.object.isRequired,
  workforceGroups: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  savedData: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  updateCOCStatus: PropTypes.func.isRequired,
};

Groups.defaultProps = {
  error: null,
  workforceGroups: {},
  deleteFile: null,
};

export default Groups;
