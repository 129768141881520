import i18n from 'i18next';
import firebase from '../../../../../../../firebase';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';
import { loadDiyComponents } from '../../../../../../../app/actions';

export const closeDuplicateComponentDialog = () => dispatch => {
  dispatch({ type: 'CLOSE_DUPLICATE_COMPONENT_DIALOG' });
  dispatch({ type: 'SET_DUPLICATE_COMPONENT', payload: '' });
  dispatch({ type: 'RESET_DUPLICATE_COMPONENT_FORM' });
  dispatch({ type: 'SET_SELECTED_PAGE', payload: '' });
  dispatch({
    type: 'SET_PENDING',
    payload: false,
  });
};

export const setPageSelect = value => dispatch => {
  dispatch({ type: 'SET_SELECTED_PAGE', payload: value });
};

const getHref = (target, router, page, parentPage, tab) => {
  const { match, location } = router;
  // const { page, tab, parentPage } = queryString.parse(location.search);

  const tabQuery = tab ? `tab=${tab}&` : '';

  return {
    pathname: `/${match.params.appname}/editor/build/edit`,
    search: `page=${page}&${tabQuery}component=${target}${
      parentPage ? `&parentPage=${parentPage}` : ``
    }`,
  };
};

export const duplicateComponent = (appname, router) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: 'SET_PENDING',
    payload: true,
  });
  const selectedPage = getState().editor.build.create.duplicateComponent
    .selectedPage;
  const selectedComponent = getState().editor.build.create.duplicateComponent
    .selectedComponent;

  const getPageKeys = selectedPage.split('/');

  let mainPageKey = '';
  let parentPage;
  let tabNo;
  if (getPageKeys.length <= 2) {
    mainPageKey = getPageKeys[0];
  } else if (getPageKeys[0] === 'tab') {
    mainPageKey = getPageKeys[1];
    tabNo = getPageKeys[2];
  } else {
    mainPageKey = getPageKeys[0];
    tabNo = getPageKeys[2];
    parentPage = getPageKeys[1];
  }

  if (
    mainPageKey === 'homepage' &&
    (selectedComponent.type === 'WordPress' ||
      selectedComponent.type === 'Shopify' ||
      selectedComponent.type === 'Woocommerce')
  ) {
    dispatch(
      openSnackbar(
        `${selectedComponent.type} ${i18n.t(
          'component can not be placed on the homepage',
        )}`,
      ),
    );
  } else {
    if (selectedComponent.type === 'Zoom') {
      const pageRef = firebase
        .database()
        .ref(`apps/${appname}/draft/always_data/${mainPageKey}`);
      const snapshot = await pageRef.once('value');
      const value = snapshot.val();
      if (value && value.components && value.components.length) {
        dispatch(
          openSnackbar(
            `${selectedComponent.type} ${i18n.t(
              'component can not be placed on a page that has other components',
            )}`,
          ),
        );
        return;
      }
      if (value && value.type === 'TabView') {
        dispatch(
          openSnackbar(
            `${selectedComponent.type} ${i18n.t(
              'component can not be placed on a tab page',
            )}`,
          ),
        );
        return;
      }
    } else {
      const pageRef = firebase
        .database()
        .ref(`apps/${appname}/draft/always_data/${mainPageKey}/unique`);
      const snapshot = await pageRef.once('value');
      const value = snapshot.val();
      if (value) {
        dispatch(
          openSnackbar(
            `${selectedComponent.type} ${i18n.t(
              "component can't be placed on a page that has a Zoom component",
            )}`,
          ),
        );
        return;
      }
    }

    const alwaysDataRef = firebase
      .database()
      .ref(`apps/${appname}/draft/always_data`);
    const lazyDataRef = firebase
      .database()
      .ref(`apps/${appname}/draft/lazy_data`);

    const componentsArrayRef =
      getPageKeys.length === 3
        ? alwaysDataRef.child(
            `${mainPageKey}/tabs/${getPageKeys[2]}/components`,
          )
        : alwaysDataRef.child(`${mainPageKey}/components`);

    lazyDataRef
      .push(selectedComponent.lazyData)
      .then(snapshot => {
        // update not_private permissions for new components
        // so that new components will be public when Member Login is enabled
        firebase
          .database()
          .ref(`app_groups/${appname}/not_private`)
          .update({ [snapshot.key]: true });

        return snapshot;
      })
      .then(snapshot => {
        // Set all ratings to 0 on rating creation
        // Rating requires this array to work correctly
        if (selectedComponent.type === 'Rating') {
          firebase
            .database()
            .ref(`ratings/${appname}/${snapshot.key}/totals`)
            .set([0, 0, 0, 0, 0]);
        }

        if (selectedComponent.type === 'EventCalendar') {
          firebase
            .database()
            .ref(`app_events/${appname}/lazy_data`)
            .set(snapshot.key);
          firebase
            .database()
            .ref(`app_courses/${appname}/lazy_data`)
            .set(snapshot.key);
        }
        const setType =
          selectedComponent.type === 'EventOrganizers'
            ? 'Memberbodies'
            : selectedComponent.type;

        return alwaysDataRef.push({
          type: setType,
          lazy_data: snapshot.key,
          ...(selectedComponent.design && { design: selectedComponent.design }),
          layout: selectedComponent.layout,
          created: Date.now(),
        });
      })
      .then(snapshot => {
        if (selectedComponent.type === 'Zoom') {
          alwaysDataRef
            .child(`${mainPageKey}/unique`)
            .transaction(value => true);
        } else {
          alwaysDataRef
            .child(`${mainPageKey}/unique`)
            .transaction(value => false);
        }
        componentsArrayRef.transaction(value => {
          if (!value) return [snapshot.key];

          return [...value, snapshot.key];
        });
        return snapshot.key;
      })
      .then(key => {
        router.history.push(
          getHref(key, router, mainPageKey, parentPage, tabNo),
        );

        dispatch(closeDuplicateComponentDialog());
        dispatch(loadDiyComponents(appname));
        return key;
      });
  }
};
