import { combineReducers } from 'redux';
import { structure } from './reducers';
import booking from './modules/bookings/reducer';
import marketplace from './modules/marketplace/reducer';

const initialState = {
  addInventoryDialogOpen: false,
  isImageUploaded: 2,
  inventoryItem: {},
};

const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_INVENTORY_DIALOG':
      return {
        ...state,
        addInventoryDialogOpen: action.payload,
      };
    case 'ADD_INVENTORY_DIALOG':
      return {
        ...state,
        inventoryItem: action.payload,
      };
    case 'INVENTORY_ITEM_CHANGE':
      return {
        ...state,
        inventoryItem: action.payload,
      };
    case 'IMAGE_UPLOAD_SUCCESS':
      return {
        ...state,
        isImageUploaded: Math.random(),
      };
    case 'IMAGE_DELETED':
      return {
        ...state,
        isImageUploaded: Math.random(),
      };

    default:
      return state;
  }
};

export default combineReducers({
  inventory: inventoryReducer,
  structure,
  marketplace,
  booking,
});
