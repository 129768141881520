/* eslint-disable no-console */
import moment from 'moment';

import firebase from '../../../../firebase';
import {
  loadSubscription,
  selectApp,
} from '../../../../app/modules/appContext/actions';
import { resetEngageState } from '../../../../modules/engage/modules/push/actions';
import { getAppUrl } from '../../../../utils/appDetails';
import { receiveCustomDomain } from '../../../../app/modules/customDomain/actions';
import { loadProfile } from '../../../account/modules/auth/login/actions';
export const SAVE_APP_NAME = 'SAVE_APP_NAME';

export const noticeSet = () => ({
  type: 'OFFER_NOTICE_SET',
});

const receiveLayoutData = (appname, data) => ({
  type: 'RECEIVE_LAYOUT_DATA',
  data,
  appname,
});

const changingApp = appname => ({
  type: 'CHANGING_APP',
  appname,
});

const appChangeSuccess = appname => ({
  type: 'APP_CHANGE_SUCCESS',
  appname,
});

const appChangeError = (appname, err) => ({
  type: 'APP_CHANGE_ERROR',
  appname,
  err,
});

export const toggleAppMenu = open => ({
  type: 'TOGGLE_APP_MENU',
  open,
});

export const toggleUserMenu = open => ({
  type: 'TOGGLE_USER_MENU',
  open,
});

export const changeApp = (appname, oldAppname) => dispatch => {
  const user = firebase.auth().currentUser;
  dispatch(changingApp(appname));

  return firebase
    .database()
    .ref(`users/${user.uid}`)
    .update({ lastVisited: appname })
    .then(() => dispatch(selectApp(appname)))
    .then(() => dispatch(resetEngageState()))
    .then(() => dispatch(removeListeners(oldAppname)))
    .then(() => dispatch(loadContent(appname)))
    .then(() => dispatch(appChangeSuccess(appname)))
    .then(() => dispatch(loadSubscription(appname)))
    .then(() => dispatch(loadProfile()))
    .catch(err => dispatch(appChangeError(appname, err)));
};

const saveIntegrationAppData = (meta, appname) => ({
  type: SAVE_APP_NAME,
  meta,
  appname,
});

const receiveAlwaysData = (data, key) => ({
  type: 'RECEIVE_ALWAYS_DATA',
  data,
  key,
});

const receiveLazyData = (data, key) => ({
  type: 'RECEIVE_LAZY_DATA',
  data,
  key,
});

const receiveMetaData = (appname, data) => ({
  type: 'RECEIVE_META_DATA',
  data,
  appname,
});

const receiveDesignData = (appname, data) => ({
  type: 'RECEIVE_DESIGN_DATA',
  data,
  appname,
});

const hydrateAlwaysData = data => ({
  type: 'HYDRATE_ALWAYS_DATA',
  data,
});

const hydrateLazyData = data => ({
  type: 'HYDRATE_LAZY_DATA',
  data,
});

const loadingContent = appname => ({
  type: 'LOADING_DRAFT_CONTENT',
  appname,
});

const contentLoaded = appname => ({
  type: 'DRAFT_CONTENT_LOADED',
  appname,
});

const modifyEditionStart = (appname, start) => ({
  type: 'DRAFT_EDITION_START_MODIFIED',
  appname,
  start,
});

export const receiveEditionDate = (appname, start) => ({
  type: 'RECEIVE_DRAFT_EDITION_START',
  start,
  appname,
});

const loadingIcon = appname => ({
  type: 'LOADING_ICON_CONTENT',
  appname,
});

const hydrateIconData = (data, appname) => ({
  type: 'HYDRATE_ICON_DATA',
  data,
  appname,
});

const integrationNodeUpdated = account => ({
  type: 'INTEGRATION_NODE_UPDATED',
  account,
});

const integrationNodeFailUpdated = account => ({
  type: 'INTEGRATION_NODE_FAIL_UPDATED',
  account,
});

const receivePreviusPublishedDomain = (appname, previusDomain) => ({
  type: 'PREVIUS_PUBLISHED_DOMAIN',
  previusDomain,
  appname,
});

const intanceIdInvalid = appname => ({
  type: 'WIX_INSTANCE_ID_INVALID',
  appname,
});

export const checkUsersSubscription = appname => async dispatch => {
  try {
    const sub = await firebase
      .database()
      .ref(`apps/${appname}/subscription`)
      .once('value')
      .then(snapshot => snapshot.val());

    if (sub) {
      return dispatch({
        type: 'SAVE_USER_SUBSCRIPTION',
        isUserSubscribed: true,
      });
    }

    return dispatch({
      type: 'SAVE_USER_SUBSCRIPTION',
      isUserSubscribed: false,
    });
  } catch (error) {
    dispatch({
      type: 'FAILED_SAVING_USER_SUBSCRIPTION',
      error,
    });
    return console.error(error);
  }
};

export const receivePublished = (appname, isPublished) => ({
  type: 'IS_PUBLISHED',
  appname,
  isPublished,
});

export const addIsPublishedListener = appname => dispatch => {
  const appRef = firebase.database().ref(`apps/${appname}`);

  appRef
    .child('published/meta_data')
    .on('value', snapshot =>
      dispatch(receivePublished(appname, snapshot.val() !== null)),
    );

  appRef
    .child('published/meta_data')
    .once('value')
    .then(snapshot =>
      dispatch(receivePublished(appname, snapshot.val() !== null)),
    );
};

export const removeIsPublishedListener = appname => {
  const appRef = firebase.database().ref(`apps/${appname}`);
  appRef.child('published/meta_data').off('value');

  return {
    type: 'REMOVE_IS_PUBLISHED_LISTENER',
    appname,
  };
};

export const loadIcon = appname => dispatch => {
  dispatch(loadingIcon(appname));

  const iconRef = firebase.database().ref(`icons/${appname}/`);
  // we load all draft data once before attaching listeners
  iconRef.once('value').then(iconSnapshot => {
    const data = iconSnapshot.val();
    dispatch(hydrateIconData(data, appname));

    // listen for changes to icon
    return iconRef.on('value', snapshot => {
      const nextData = snapshot.val();
      dispatch(hydrateIconData(nextData, appname));
    });
  });
};

export const loadContent = appname => dispatch => {
  dispatch(loadingContent(appname));
  dispatch(loadIcon(appname));

  const appRef = firebase.database().ref(`apps/${appname}`);
  const draftRef = appRef.child('draft');
  const customDomainRef = appRef.child('custom_domain');
  const layoutDataRef = draftRef.child('layout_data');
  const alwaysDataRef = draftRef.child('always_data');
  const lazyDataRef = draftRef.child('lazy_data');
  const metaDataRef = draftRef.child('meta_data');
  const designDataRef = draftRef.child('design_data');
  const appPreviusDomain = appRef.child('previus_published_domain');
  // we load all draft data once to get initial state
  // before attaching listeners
  // listeners for lazy_data is only attached when specific components are mounted

  customDomainRef.once('value').then(snapshot => {
    customDomainRef.on('value', sp => {
      const customDomain = sp.val();

      return dispatch(receiveCustomDomain(customDomain));
    });

    const customDomain = snapshot.val();

    return dispatch(receiveCustomDomain(customDomain));
  });

  draftRef.once('value').then(draftSnapshot => {
    const data = draftSnapshot.val();

    if (data === null) return null;

    dispatch(hydrateAlwaysData(data.always_data));
    dispatch(hydrateLazyData(data.lazy_data));
    dispatch(receiveLayoutData(appname, data.layout_data));
    dispatch(receiveMetaData(appname, data.meta_data));
    dispatch(receiveDesignData(appname, data.design_data));

    dispatch(contentLoaded(appname));

    layoutDataRef.on('value', snapshot =>
      dispatch(receiveLayoutData(appname, snapshot.val())),
    );

    metaDataRef.on('value', snapshot =>
      dispatch(receiveMetaData(appname, snapshot.val())),
    );

    designDataRef.on('value', snapshot =>
      dispatch(receiveDesignData(appname, snapshot.val())),
    );

    alwaysDataRef.on('child_added', snapshot =>
      dispatch(receiveAlwaysData(snapshot.val(), snapshot.key)),
    );

    alwaysDataRef.on('child_changed', snapshot =>
      dispatch(receiveAlwaysData(snapshot.val(), snapshot.key)),
    );

    lazyDataRef.on('child_added', snapshot =>
      dispatch(receiveLazyData(snapshot.val(), snapshot.key)),
    );

    appPreviusDomain.on('value', previusDomain =>
      dispatch(receivePreviusPublishedDomain(appname, previusDomain.val())),
    );

    draftRef.on('child_changed', () => {
      let modifiedOn = moment(new Date()).valueOf();

      appRef.child('edition/start').transaction(date => {
        modifiedOn = date || modifiedOn;
        dispatch(modifyEditionStart(appname, modifiedOn));
        return modifiedOn;
      });
    });

    return null;
  });
};

export const widgetConfiguration = (appname, type) => dispatch => {
  if (type !== 'Wix') {
    return;
  }
  const instanceId = window.Wix.Utils.getInstanceId();
  try {
    if (instanceId) {
      firebase
        .database()
        .ref(`integration/wix/${instanceId}`)
        .update({
          appname,
          url: getAppUrl(appname),
        });
      dispatch(integrationNodeUpdated(instanceId));
    } else {
      dispatch(intanceIdInvalid(appname));
    }
  } catch (err) {
    dispatch(integrationNodeFailUpdated(instanceId));
  }
};

export const removeListeners = appname => {
  const iconRef = firebase.database().ref(`icons/${appname}/`);
  const draftRef = firebase.database().ref(`apps/${appname}/draft`);
  const layoutDataRef = draftRef.child('layout_data');
  const alwaysDataRef = draftRef.child('always_data');
  const lazyDataRef = draftRef.child('lazy_data');
  const metaDataRef = draftRef.child('meta_data');
  const designDataRef = draftRef.child('design_data');
  const appPreviusDomain = firebase
    .database()
    .ref(`apps/${appname}/previus_published_domain`);

  layoutDataRef.off('value');

  alwaysDataRef.off('child_added');
  alwaysDataRef.off('child_changed');
  lazyDataRef.off('child_added');
  lazyDataRef.off('child_changed');
  appPreviusDomain.off('value');
  metaDataRef.off('value');

  designDataRef.off('value');
  draftRef.off('child_changed');
  iconRef.off('value');

  return {
    type: 'REMOVE_APP_LISTENERS',
    appname,
  };
};

export const saveMetaData = (appname, nextState) => dispatch => {
  const metaDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/meta_data`);
  metaDataRef.update(nextState);
  dispatch(saveIntegrationAppData(nextState, appname));
};

export const addOfferNotice = (offer, account) => (dispatch, getState) => {
  let appsumoUserId = getState().account.register.appsumoUserId;
  if (!appsumoUserId) {
    let content = '';
    let mobileContent = '';
    if (offer && offer.coupon) {
      content = offer.welcome_notice;
      mobileContent = offer.welcome_notice_mobile || '';
    }

    dispatch({ type: 'ADDING_OFFER_NOTICE' });

    return firebase
      .database()
      .ref(`accounts/${account}`)
      .child('notices')
      .push({ content, mobileContent, type: 'Offer', autoPopup: true })
      .catch(err => console.error(err));
  }
};

export const addPageContentUpdateListner = (
  appname,
  currentPage,
  prevPage,
) => dispatch => {
  const appRef = firebase.database().ref(`apps/${appname}`);
  const draftRef = appRef.child('draft');
  const alwaysDataRef = draftRef.child('always_data');
  if (alwaysDataRef && currentPage) {
    const currentPageDataRef = alwaysDataRef.child(currentPage);
    const prevPageDataRef = prevPage ? alwaysDataRef.child(prevPage) : null;
    dispatch({ type: 'COMPONENT_UPDATED', currentPage, appname });
    draftRef.once('value').then(draftSnapshot => {
      const data = draftSnapshot.val();

      if (data === null) return null;

      if (prevPageDataRef) {
        prevPageDataRef.off('value');
      }

      currentPageDataRef.on('value', snapshot => {
        if (
          snapshot.val().isUpdateAvailable === undefined ||
          !snapshot.val().isUpdateAvailable
        ) {
          currentPageDataRef.update({
            isUpdateAvailable: true,
          });
        }
      });

      return null;
    });
  }
};
