import { connect } from 'react-redux';
import queryString from 'query-string';

import PageSettings from './PageSettings';
import { updatePage } from './actions';
import { launchRemoveDialog } from '../remove/actions';

const mapStateToProps = (state, ownProps) => {
  const { page } = queryString.parse(ownProps.location.search);
  const pageData = state.editor.data.alwaysData[page];
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];
  const appname = ownProps.match.params.appname;

  return {
    ...ownProps,
    ...pageData,
    pageKey: page,
    pages: layout
      ? layout.childRoutes.map(key => state.editor.data.alwaysData[key])
      : [],
    appname,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { appname } = ownProps.match.params;
  const { page } = queryString.parse(ownProps.location.search);

  return {
    onSubmit: nextState => dispatch(updatePage(appname, page, nextState)),
    launchRemoveDialog: () =>
      dispatch(launchRemoveDialog(appname, { page }, 'PAGE')),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageSettings);
