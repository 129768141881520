import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';

import Push from './Push';
import {
  installs,
  getUsers,
  enableFilter,
  disableFilter,
  filteredListChange,
  getGroups,
  enableGroupsFilter,
  disableGroupsFilter,
  filteredGroupsListChange,
  enableLocationFilter,
  disableLocationFilter,
  filteredLocationListChange,
  filtersChange,
  playerIdChange,
  selectedFilterChange,
  sendPush,
  handlePropChange,
  getRecipients,
  onLinkFormChange,
  resetUserInputs,
  addNotificationComponent,
  onDateChange,
  onToggleChange,
  clearPushImage,
  loadContent,
  getCountries,
  getStates,
  getCities,
} from './actions';

import { loadContent as loadAppContent } from '../../../editor/actions';

const mapStateToProps = (state, ownProps) => {
  const appname = ownProps.match.params.appname;
  const isWixUser =
    state.account.organisation &&
    state.account.organisation.integration &&
    state.account.organisation.integration.type === 'Wix';
  return {
    ...ownProps,
    accountId: state.account.auth.profile.account,
    topBarColor: {
      ..._get(state, `editor.design.${appname}.components.Navigation.Topbar`),
    },
    appSettings: {
      ..._get(state, `editor.shared.appName.${appname}`),
    },
    appname: _get(
      state,
      `editor.shared.appName.${appname}.meta.title`,
      appname,
    ),
    textColor: _get(state, `editor.design.${appname}.palette.textColor`),
    integration: _get(state, 'account.organisation.integration'),
    pages: _get(state, 'engage.structure.routes') || [],
    pushImage: _get(state, 'engage.push.pushImage') || null,
    ...state.engage.push,
    domain: ownProps.match.params.appname,
    subscription:
      state.account.subscriptions.items[ownProps.match.params.appname] || false,
    customDomain: state.app.customDomain,
    isAppAdmin:
      (state.account.auth.profile.admin_appname &&
        state.account.auth.profile.admin_appname.length > 0) ||
      false,
    isWixUser,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInstalls: () => dispatch(installs(ownProps.match.params.appname)),
  getUsers: () => dispatch(getUsers(ownProps.match.params.appname)),
  onEnableFilter: () => dispatch(enableFilter()),
  onFilteredListChange: list => dispatch(filteredListChange(list)),
  onDisableFilter: () => dispatch(disableFilter()),
  getGroups: () => dispatch(getGroups(ownProps.match.params.appname)),
  onEnableGroupsFilter: () => dispatch(enableGroupsFilter()),
  onFilteredGroupsListChange: list => dispatch(filteredGroupsListChange(list)),
  onDisableGroupsFilter: () => dispatch(disableGroupsFilter()),
  onEnableLocationFilter: () => dispatch(enableLocationFilter()),
  onFilteredLocationListChange: list =>
    dispatch(filteredLocationListChange(list)),
  onDisableLocationFilter: () => dispatch(disableLocationFilter()),
  onFiltersChange: list => dispatch(filtersChange(list)),
  onPlayerIdChange: list => dispatch(playerIdChange(list)),
  onSelectedFilterChange: value => dispatch(selectedFilterChange(value)),

  onChange: (prop, value) => dispatch(handlePropChange(prop, value)),
  onLinkChange: (prop, value) => dispatch(onLinkFormChange(prop, value)),
  onDateChange: value => dispatch(onDateChange(value)),
  onToggleChange: value => dispatch(onToggleChange(value)),
  onSubmit: message => dispatch(sendPush(message)),
  resetInputs: () => {
    dispatch(resetUserInputs());
  },
  getRecipients: () => dispatch(getRecipients(ownProps.match.params.appname)),
  onGetCountries: () => dispatch(getCountries(ownProps.match.params.appname)),
  onGetStates: country =>
    dispatch(getStates(ownProps.match.params.appname, country)),
  onGetCities: (country, state) =>
    dispatch(getCities(ownProps.match.params.appname, country, state)),

  onAddNotificationComponent: () =>
    dispatch(
      addNotificationComponent(ownProps.match.params.appname, ownProps.history),
    ),
  loadContent: () => dispatch(loadContent(ownProps.match.params.appname)),
  loadAppContent: () => dispatch(loadAppContent(ownProps.match.params.appname)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Push));
