import {
  GET_ALL_SUBSCRIBERS,
  REQUEST_ALL_SUBSCRIBERS,
  GET_ALL_ATTENDED,
  REQUEST_ALL_ATTENDED,
  GET_ALL_ITEMS,
} from './actions';

const initialState = {
  error: null,
  processingSubscribers: false,
  subscribers: {},
  processingAttended: false,
  attended: {},
  eventsCoursesCpds: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SUBSCRIBERS:
      return {
        ...state,
        subscribers: action.value,
        processingSubscribers: false,
      };
    case REQUEST_ALL_SUBSCRIBERS:
      return {
        ...state,
        processingSubscribers: true,
      };
    case GET_ALL_ATTENDED:
      return {
        ...state,
        attended: action.value,
        processingAttended: false,
      };
    case REQUEST_ALL_ATTENDED:
      return {
        ...state,
        processingAttended: true,
      };
    case GET_ALL_ITEMS:
      return {
        ...state,
        eventsCoursesCpds: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
