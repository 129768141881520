import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'material-ui/IconButton';
import Dialog from 'material-ui/Dialog';
import Divider from 'material-ui/Divider';
import Close from 'material-ui/svg-icons/navigation/close';

import ModalDetailsNavigation from '../../components/modalDetailsNavigation';
import ModalDetailsActions from '../../components/modalDetailsActions';

import './styles.css';

const ModalDetails = ({
  open,
  onRequestClose,
  title,
  onNextItem,
  onPreviousItem,
  totalItems,
  currentItem,
  navigationText,
  onDownloadCsv,
  onDownloadPng,
  deleteConfirmationMessage,
  onDelete,
  content,
  chartsToggled,
}) => (
  <Dialog
    open={open}
    onRequestClose={onRequestClose}
    autoScrollBodyContent
    contentClassName="modal-detail-content"
    paperClassName="modal-detail-paper"
  >
    <div className="modal-details-content">
      <div className="modal-heading-container">
        <h5>{title}</h5>
        <IconButton onClick={onRequestClose}>
          <Close />
        </IconButton>
      </div>
      <Divider />
      <div className="inner-container">
        <div className="icons">
          <ModalDetailsNavigation
            onNextItem={onNextItem}
            onPreviousItem={onPreviousItem}
            totalItems={totalItems}
            currentItem={currentItem}
            text={navigationText}
          />
          <ModalDetailsActions
            onDownloadCsv={onDownloadCsv}
            onDownloadPng={onDownloadPng}
            deleteConfirmationMessage={deleteConfirmationMessage}
            onDelete={onDelete}
            chartsToggled={chartsToggled}
          />
        </div>
        <Divider />
        {content}
      </div>
    </div>
  </Dialog>
);

ModalDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onNextItem: PropTypes.func.isRequired,
  onPreviousItem: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
  currentItem: PropTypes.number.isRequired,
  navigationText: PropTypes.string.isRequired,
  onDownloadCsv: PropTypes.func.isRequired,
  onDownloadPng: PropTypes.func,
  deleteConfirmationMessage: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  chartsToggled: PropTypes.func,
};

ModalDetails.defaultProps = {
  data: null,
  responseKey: null,
  onReply: null,
  onFavorite: null,
  onDelete: null,
  onForward: null,
  currentFeedbackKey: null,
  chartsToggled: null,
};

export default ModalDetails;
