const bundles = {
  small: {
    amount: 100,
    prices: {
      total: {
        USD: 13.59,
        GBP: 10,
      },
      breakdown: {
        USD: 0.14,
        GBP: 0.1,
      },
    },
  },
  medium: {
    amount: 250,
    prices: {
      total: {
        USD: 27.18,
        GBP: 20,
      },
      breakdown: {
        USD: 0.14,
        GBP: 0.1,
      },
    },
  },
  large: {
    amount: 500,
    prices: {
      total: {
        USD: 54.36,
        GBP: 40,
      },
      breakdown: {
        USD: 0.1,
        GBP: 0.08,
      },
    },
  },
  extraLarge: {
    amount: 1000,
    prices: {
      total: {
        USD: 95.13,
        GBP: 70,
      },
      breakdown: {
        USD: 0.09,
        GBP: 0.07,
      },
    },
  },
  gigantic: {
    amount: 10000,
    prices: {
      total: {
        USD: 665,
        GBP: 500,
      },
      breakdown: {
        USD: 0.07,
        GBP: 0.05,
      },
    },
  },
};

export default bundles;
