import i18n from 'i18next';
import firebase from '../../../../firebase';
import { objectToArray, arrayToObject } from '../../../../utils/object';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';

export const REQUEST_FORM_DATA = 'REQUEST_FORM_DATA';
export const RECEIVE_FORM_DATA = 'RECEIVE_FORM_DATA';
export const WATCHING_FORM_DATA = 'WATCHING_FORM_DATA';
export const RECEIVE_FORM_DATA_UPDATE = 'RECEIVE_FORM_DATA_UPDATE';
export const REMOVING_FORM_DATA_LISTENER = 'REMOVING_FORM_DATA_LISTENER';
export const REQUEST_ALL_FORMS = 'REQUEST_ALL_FORMS';
export const RECEIVE_ALL_FORMS = 'RECEIVE_ALL_FORMS';
export const REPORT_FORM_DATA_REQUEST_ERROR = 'REPORT_FORM_DATA_REQUEST_ERROR';
export const FILTER_FORM_DATA = 'FILTER_FORM_DATA';
export const FAVOURITE_SELECTED = 'FAVOURITE_SELECTED';
export const DOWNLOAD_SELECTED = 'DOWNLOAD_SELECTED';
export const DELETE_SELECTED = 'DELETE_SELECTED';
export const ACTION_ERROR = 'ACTION_ERROR';
export const SELECT_RESPONSE = 'SELECT_RESPONSE';
export const SELECT_ALL_RESPONSES = 'SELECT_ALL_RESPONSES';
export const DESELECT_ALL_RESPONSES = 'DESELECT_ALL_RESPONSES';
export const SORT_FORM_TABLE_DATA = 'SORT_FORM_TABLE_DATA';
export const CHANGE_FORM = 'CHANGE_FORM';
export const UPDATE_STATUS = 'UPDATE_STATUS';
const requestFormData = appname => ({
  type: REQUEST_FORM_DATA,
  appname,
});

const receiveFormData = (
  appname,
  data,
  allRecords,
  formNames,
  selectedForm,
) => ({
  type: RECEIVE_FORM_DATA,
  appname,
  data,
  allRecords,
  formNames,
  selectedForm,
});

const watchingFormData = appname => ({
  type: WATCHING_FORM_DATA,
  appname,
});

const receiveFormDataUpdate = (appname, data) => ({
  type: RECEIVE_FORM_DATA_UPDATE,
  appname,
  data,
});

const offFormData = appname => ({
  type: REMOVING_FORM_DATA_LISTENER,
  appname,
});

const requestAllForms = appname => ({
  type: REQUEST_ALL_FORMS,
  appname,
});

const receiveAllForms = (appname, data) => ({
  type: RECEIVE_ALL_FORMS,
  appname,
  data,
});

const reportError = err => ({
  type: REPORT_FORM_DATA_REQUEST_ERROR,
  err: err.message || err,
});

export const filterFormData = filter => dispatch =>
  dispatch({
    type: FILTER_FORM_DATA,
    payload: filter,
  });

export const favouriteSelectedSuccess = (appname, selected, data) => dispatch =>
  dispatch({
    type: FAVOURITE_SELECTED,
    appname,
    selected,
    data,
  });

export const deleteSelectedSuccess = (appname, selected) => dispatch =>
  dispatch({
    type: DELETE_SELECTED,
    appname,
    selected,
  });

export const reportActionError = (
  error,
  appname,
  selected,
  action,
) => dispatch =>
  dispatch({ type: ACTION_ERROR, error, appname, selected, action });

export const changeSelectedForm = (allForms, formId) => dispatch => {
  if (!formId) {
    return;
  }
  dispatch({ type: UPDATE_STATUS });

  const result = objectToArray(allForms);

  const selectedFormData = result.filter(
    item => item.value.title === formId.value,
  );
  dispatch({
    type: CHANGE_FORM,
    data: arrayToObject(selectedFormData),
    selectedForm: formId,
  });
};
export const getFormData = (appname, refetch = false) => (
  dispatch,
  getState,
) => {
  const selectedForm = getState().analytics.form.data.selectedForm;
  dispatch(requestFormData(appname));

  return firebase
    .database()
    .ref(`forms/${appname}`)
    .orderByChild('id')
    .once('value')
    .then(snapshot => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const result = objectToArray(data);
        const formIds = [...new Set(result.map(item => item.value.title))];

        const otherForms = formIds.filter(item => item !== selectedForm.value);

        const allForms = formIds.map(item => {
          const filteredResult = result.find(i => i.value.title === item);

          return {
            value: item,
            label: filteredResult.value.title,
            date: filteredResult.value.date,
          };
        });
        const formNames = allForms.sort(
          (a, b) => new Date(b.date) - new Date(a.date),
        );

        const form = refetch ? selectedForm : formNames[0];
        const formData = result.filter(item => item.value.title === form.value);
        const otherFormData = result.filter(
          item => item.value.title === otherForms[0],
        );
        return dispatch(
          receiveFormData(
            appname,
            arrayToObject(
              formData.length === 0 && refetch ? otherFormData : formData,
            ),
            data,
            formNames,
            formData.length === 0 && refetch ? otherForms[0] : form,
          ),
        );
      }
      dispatch(receiveFormData(appname, {}, {}, [], 'formIds[0]'));
      dispatch(reportError(i18n.t('No form responses')));
    })
    .catch(err => dispatch(reportError(err)));
};

export const watchFormData = appname => dispatch => {
  dispatch(watchingFormData(appname));

  return firebase
    .database()
    .ref(`forms/${appname}`)
    .on('value', snapshot => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return dispatch(receiveFormDataUpdate(appname, data));
      }
      dispatch(receiveFormDataUpdate(appname, {}));
      return dispatch(reportError(i18n.t('No form responses')));
    });
};

export const removeFormDataWatcher = appname => dispatch => {
  firebase
    .database()
    .ref(`forms/${appname}`)
    .off('value');

  return dispatch(offFormData(appname));
};

export const getAllForms = appname => dispatch => {
  dispatch(requestAllForms(appname));

  return firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data`)
    .once('value')
    .then(snapshot => {
      if (snapshot.exists()) {
        const data = Object.entries(snapshot.val())
          .map(item => (item[1].fields && item[1].sendTo ? item[0] : null))
          .filter(item => item);
        return dispatch(receiveAllForms(appname, data));
      }
      return dispatch(reportError(i18n.t('No form components in app')));
    })
    .catch(err => dispatch(reportError(err)));
};

export const selectResponse = key => ({
  type: SELECT_RESPONSE,
  key,
});

export const selectAllResponses = () => ({ type: SELECT_ALL_RESPONSES });

export const deselectAllResponses = () => ({ type: DESELECT_ALL_RESPONSES });

export const favouriteSelected = (appname, selected) => dispatch =>
  firebase
    .database()
    .ref(`forms/${appname}`)
    .transaction(responses => {
      if (responses === null) return null;
      Object.entries(responses).forEach(response => {
        if (selected.indexOf(response[0]) >= 0) {
          response[1].favourite = !response[1].favourite;
        }
      });
      return responses;
    })
    .then(data =>
      dispatch(
        favouriteSelectedSuccess(appname, selected, data.snapshot.val()),
      ),
    )
    .catch(error => reportActionError(error, appname, selected, 'favourite'));

export const deleteSelected = (appname, selected) => dispatch => {
  dispatch(openSnackbar(i18n.t('Deleting in progress. Please wait..')));

  return firebase
    .database()
    .ref(`forms/${appname}`)
    .transaction(responses => {
      if (responses === null) return null;
      const result = {};
      Object.entries(responses).forEach(response => {
        if (selected.indexOf(response[0]) < 0) {
          result[response[0]] = response[1];
        }
      });
      return result;
    })
    .then(data => {
      const message =
        selected.length > 0
          ? 'Form responses successfully deleted.'
          : 'Form response successfully deleted.';
      dispatch(getAllForms(appname));
      dispatch(getFormData(appname, true));
      dispatch(openSnackbar(i18n.t(message)));

      // dispatch(deleteSelectedSuccess(appname, selected, data.snapshot.val()));
    })
    .catch(error => reportActionError(error, appname, selected, 'delete'));
};

export const sortTableData = cols => dispatch =>
  dispatch({ type: SORT_FORM_TABLE_DATA, column: cols });
