import React from 'react';

const GoogleMap = () => (
	<svg 
		id="Layer_1"
		class="googlemap-style"
		width="50"
		version="1.1"
		viewBox="0 0 150 150"
	>
		<g enable-background="new    ">
			<g>
				<defs>
					<rect id="SVGID_1_" x="18.6" y="5.1" width="89" height="103.3"/>
				</defs>
				<clipPath id="SVGID_2_">
					<use href="#SVGID_1_"  overflow="visible"/>
				</clipPath>
				<g clip-path="url(#SVGID_2_)">
					<defs>
						<rect id="SVGID_3_" x="-777.6" y="-835.7" width="2171" height="5414.7"/>
					</defs>
					<clipPath id="SVGID_4_">
						<use href="#SVGID_3_"  overflow="visible"/>
					</clipPath>
					<g clip-path="url(#SVGID_4_)">
						<defs>
							<path id="SVGID_5_" d="M106.4,64.2c0,23.8-19.3,43.2-43.2,43.2c-23.8,0-43.2-19.3-43.2-43.2c0-23.8,19.3-43.2,43.2-43.2
								C87.1,21.1,106.4,40.4,106.4,64.2"/>
						</defs>
						<clipPath id="SVGID_6_">
							<use href="#SVGID_5_"  overflow="visible"/>
						</clipPath>
						<g clip-path="url(#SVGID_6_)">
							<defs>
								<rect id="SVGID_7_" x="18.6" y="5.1" width="89" height="103.3"/>
							</defs>
							<clipPath id="SVGID_8_">
								<use href="#SVGID_7_"  overflow="visible"/>
							</clipPath>
							<rect x="12.1" y="13.1" clip-path="url(#SVGID_8_)" fill="#F8D846" width="102.2" height="102.2"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<defs>
						<rect id="SVGID_9_" x="-777.6" y="-835.7" width="2171" height="5414.7"/>
					</defs>
					<clipPath id="SVGID_10_">
						<use href="#SVGID_9_"  overflow="visible"/>
					</clipPath>
					<g clip-path="url(#SVGID_10_)">
						<defs>
							<path id="SVGID_11_" d="M117.9,70.3V57.2h-7.2v13.1h-9.9V61h-7.2c-0.8,0-1.5,0.7-1.5,1.5c0,0.8-0.7,1.5-1.5,1.5h-3.4v-8.5
								c0-0.8-0.7-1.5-1.5-1.5h-0.5c-0.8,0-1.5-0.7-1.5-1.5v-3.2h-7.2V54h-8.3v14.9H46.8V55.6l-14,6.4v11.6c0,0.8-0.7,1.5-1.5,1.5
								h-0.5c-0.8,0-1.5-0.7-1.5-1.5v-0.2c0-0.8-0.7-1.5-1.5-1.5h-4.1c-0.8,0-1.5,0.7-1.5,1.5v0.2c0,0.8-0.7,1.5-1.5,1.5H20
								c-0.8,0-1.5-0.7-1.5-1.5V62h-7.2v6.9H4.2V120h42.5l11.9-7.9h63.5V70.3H117.9"/>
						</defs>
						<clipPath id="SVGID_12_">
							<use href="#SVGID_11_"  overflow="visible"/>
						</clipPath>
						<g clip-path="url(#SVGID_12_)">
							<defs>
								<rect id="SVGID_13_" x="18.6" y="5.1" width="89" height="103.3"/>
							</defs>
							<clipPath id="SVGID_14_">
								<use href="#SVGID_13_"  overflow="visible"/>
							</clipPath>
							<g clip-path="url(#SVGID_14_)" enable-background="new    ">
								<g>
									<defs>
										<rect id="SVGID_15_" x="18.6" y="48" width="89" height="58.8"/>
									</defs>
									<clipPath id="SVGID_16_">
										<use href="#SVGID_15_"  overflow="visible"/>
									</clipPath>
									<g clip-path="url(#SVGID_16_)">
										<defs>
											<path id="SVGID_17_" d="M106.4,63.5c0,23.8-19.3,43.2-43.2,43.2c-23.8,0-43.2-19.3-43.2-43.2c0-23.8,19.3-43.2,43.2-43.2
												C87.1,20.4,106.4,39.7,106.4,63.5"/>
										</defs>
										<clipPath id="SVGID_18_">
											<use href="#SVGID_17_"  overflow="visible"/>
										</clipPath>
										<g clip-path="url(#SVGID_18_)">
											<defs>
												<rect id="SVGID_19_" x="18.6" y="48" width="89" height="60.4"/>
											</defs>
											<clipPath id="SVGID_20_">
												<use href="#SVGID_19_"  overflow="visible"/>
											</clipPath>
											<rect x="12.1" y="12.4" clip-path="url(#SVGID_20_)" fill="#3F8CF4" width="102.2" height="102.2"/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<defs>
						<rect id="SVGID_21_" x="-777.6" y="-835.7" width="2171" height="5414.7"/>
					</defs>
					<clipPath id="SVGID_22_">
						<use href="#SVGID_21_"  overflow="visible"/>
					</clipPath>
					<g clip-path="url(#SVGID_22_)">
						<defs>
							<circle id="SVGID_23_" cx="63.2" cy="64.3" r="43.2"/>
						</defs>
						<clipPath id="SVGID_24_">
							<use href="#SVGID_23_"  overflow="visible"/>
						</clipPath>
						<g clip-path="url(#SVGID_24_)">
							<defs>
								<rect id="SVGID_25_" x="18.6" y="5.1" width="89" height="103.3"/>
							</defs>
							<clipPath id="SVGID_26_">
								<use href="#SVGID_25_"  overflow="visible"/>
							</clipPath>
							<g clip-path="url(#SVGID_26_)" enable-background="new    ">
								<g>
									<defs>
										<rect id="SVGID_27_" x="18.6" y="55.9" width="89" height="52.4"/>
									</defs>
									<clipPath id="SVGID_28_">
										<use href="#SVGID_27_"  overflow="visible"/>
									</clipPath>
									<g clip-path="url(#SVGID_28_)">
										<defs>
											<ellipse id="SVGID_29_" cx="63.2" cy="127.6" rx="82.9" ry="46.5"/>
										</defs>
										<clipPath id="SVGID_30_">
											<use href="#SVGID_29_"  overflow="visible"/>
										</clipPath>
										<g clip-path="url(#SVGID_30_)">
											<defs>
												<rect id="SVGID_31_" x="18.6" y="21" width="89" height="87.4"/>
											</defs>
											<clipPath id="SVGID_32_">
												<use href="#SVGID_31_"  overflow="visible"/>
											</clipPath>
											<rect x="-27.6" y="73.2" clip-path="url(#SVGID_32_)" fill="#20A362" width="181.7" height="108.9"/>
										</g>
									</g>
									<g clip-path="url(#SVGID_28_)">
										<defs>
											<rect id="SVGID_33_" x="18.6" y="21" width="89" height="87.4"/>
										</defs>
										<clipPath id="SVGID_34_">
											<use href="#SVGID_33_"  overflow="visible"/>
										</clipPath>
										
											<ellipse clip-path="url(#SVGID_34_)" fill="none" stroke="#ECECEC" stroke-width="4.949" stroke-miterlimit="10" cx="63.2" cy="127.6" rx="82.9" ry="46.5"/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<defs>
						<rect id="SVGID_35_" x="-777.6" y="-835.7" width="2171" height="5414.7"/>
					</defs>
					<clipPath id="SVGID_36_">
						<use href="#SVGID_35_"  overflow="visible"/>
					</clipPath>
					<g clip-path="url(#SVGID_36_)">
						<defs>
							<path id="SVGID_37_" d="M91.8,33.4C91.8,16.4,76,3,58.3,7.2c-7.4,1.7-13.4,6.8-17,13.5c-7.4,13.8-1.7,24.1,5,32.3
								c0,0,14.6,16.9,17.6,44.1c0,0,0,1,0.9,1c0.9,0,0.9-1,0.9-1C68.7,69.9,83.3,53,83.3,53C87.8,47.5,91.8,41.1,91.8,33.4"/>
						</defs>
						<clipPath id="SVGID_38_">
							<use href="#SVGID_37_"  overflow="visible"/>
						</clipPath>
						<g clip-path="url(#SVGID_38_)">
							<defs>
								<rect id="SVGID_39_" x="18.6" y="5.1" width="89" height="103.3"/>
							</defs>
							<clipPath id="SVGID_40_">
								<use href="#SVGID_39_"  overflow="visible"/>
							</clipPath>
							<rect x="29.9" y="-1.5" clip-path="url(#SVGID_40_)" fill="#DC4A3D" width="69.8" height="107.5"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
		<defs>
			<rect id="SVGID_41_" x="-777.6" y="-835.7" width="2171" height="5414.7"/>
		</defs>
		<clipPath id="SVGID_42_">
			<use href="#SVGID_41_"  overflow="visible"/>
		</clipPath>
		<g clip-path="url(#SVGID_42_)">
			<defs>
				<path id="SVGID_43_" d="M74.3,33.1c0,5.3-4.2,9.6-9.4,9.6c-5.2,0-9.4-4.3-9.4-9.6c0-5.3,4.2-9.6,9.4-9.6
					C70,23.5,74.3,27.8,74.3,33.1"/>
			</defs>
			<clipPath id="SVGID_44_">
				<use href="#SVGID_43_"  overflow="visible"/>
			</clipPath>
			<g clip-path="url(#SVGID_44_)">
				<defs>
					<rect id="SVGID_45_" x="18.6" y="5.1" width="89" height="103.3"/>
				</defs>
				<clipPath id="SVGID_46_">
					<use href="#SVGID_45_"  overflow="visible"/>
				</clipPath>
				<rect x="47.4" y="15.6" clip-path="url(#SVGID_46_)" fill="#812A28" width="34.8" height="35"/>
			</g>
		</g>
	</g>
			</g>
		</g>
	</svg>
);

export default GoogleMap;

