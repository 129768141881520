const initialState = {
  fetching: true,
  error: null,
  items: {},
  hasUpdated: false,
};

const subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case 'RETEIVE_SUBSCRIPTIONS':
      return {
        ...state,
        fetching: true,
      };
    case 'CANCEL_PLAN_SUCCESS':
      return {
        ...state,
        hasUpdated: true,
      };

    case 'RETREIVE_SUBSCRIPTIONS_FAILED':
      return {
        ...state,
        fetching: false,
        error: action.err,
      };

    case 'HYDRATE_SUBSCRIPTIONS': {
      const items = action.data.reduce(
        (next, item) => ({
          ...next,
          [item.appname]: {
            ...item.subscription,
          },
        }),
        {},
      );

      return {
        fetching: false,
        items: {
          ...items,
        },
      };
    }
    case 'UPDATE_SUBSCRIPTION':
      return {
        ...state,
        items: {
          ...state.items,
          [action.appname]: {
            ...state[action.appname],
            ...action.data,
          },
        },
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default subscriptions;
