import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactList from 'react-list';

import Container from '../container';
import { CircularProgress } from '../progress';

import TableHead from './TableHead';
import TableRow from './TableRow';
import './styles.scss';

class Table extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    cols: PropTypes.array.isRequired,
    chunks: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    onRowTouchTap: PropTypes.func,
    sortTable: PropTypes.func,
    order: PropTypes.string,
    colSorted: PropTypes.string,
    colsToSort: PropTypes.array,
    pgmolTable: PropTypes.bool,
    isCustom: PropTypes.bool,
  };

  static defaultProps = {
    chunks: {},
    loading: false,
    onRowTouchTap: null,
    sortTable: null,
    order: 'desc',
    colSorted: null,
    colsToSort: [],
    pgmolTable: false,
    isCustom: false,
  };

  handleRowTouchTap = key => () => this.props.onRowTouchTap(key);

  renderRow = (index, key) => (
    <>
      <TableRow
        key={key}
        onClick={
          this.props.onRowTouchTap
            ? this.handleRowTouchTap(this.props.items[index])
            : null
        }
        id={this.props.items[index]}
        data={this.props.chunks[this.props.items[index]]}
        cols={this.props.cols}
      />
    </>
  );

  render() {
    const element = this.props.loading ? (
      <Container>
        <CircularProgress />
      </Container>
    ) : (
      <div className="table">
        <TableHead
          cols={this.props.cols}
          onColClick={col => this.props.sortTable(col)}
          order={this.props.order}
          colSorted={this.props.colSorted}
          colsToSort={this.props.colsToSort}
        />

        <div
          className="table-body scroll-container"
          style={{ height: this.props.isCustom ? '55%' : '99%' }}
        >
          <ReactList
            itemRenderer={this.renderRow}
            length={this.props.items.length}
            type="uniform"
            minSize={this.props.pgmolTable ? 22 : 1}
          />
        </div>
      </div>
    );

    return element;
  }
}

export default Table;
