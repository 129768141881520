import React from 'react';

const Cog = () => (
  <svg
    className="icon cog"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32.86 33.6"
  >
    <title>Settings</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M30.74,18.9a2.54,2.54,0,0,1,0-4.2l2.12-1.46-.13-.5A17.7,17.7,0,0,0,32,10.5l-.2-.48-2.57.07a2.55,2.55,0,0,1-2.47-3.4l.86-2.42-.4-.34a17,17,0,0,0-1.9-1.38l-.44-.28-2,1.57a2.5,2.5,0,0,1-2.34.4,2.5,2.5,0,0,1-1.66-1.7L18.13.08l-.52,0a18.58,18.58,0,0,0-2.36,0l-.52,0L14,2.54a2.5,2.5,0,0,1-1.66,1.7A2.5,2.5,0,0,1,10,3.84L8,2.27l-.44.28a17,17,0,0,0-1.9,1.38l-.4.34.86,2.42A2.51,2.51,0,0,1,5.75,9a2.46,2.46,0,0,1-2.13,1.05L1.05,10l-.2.48a16.22,16.22,0,0,0-.72,2.24l-.13.5L2.12,14.7a2.49,2.49,0,0,1,1.11,2.1,2.5,2.5,0,0,1-1.11,2.1L0,20.35l.13.51A16.22,16.22,0,0,0,.85,23.1l.2.48,2.57-.07a2.52,2.52,0,0,1,2.13,1,2.5,2.5,0,0,1,.34,2.35l-.86,2.42.4.33a17.12,17.12,0,0,0,1.9,1.39l.44.27,2-1.56a2.5,2.5,0,0,1,2.34-.41A2.54,2.54,0,0,1,14,31.05l.72,2.47.52,0c.39,0,.78.05,1.18.05s.79,0,1.18-.05l.52,0,.72-2.47a2.54,2.54,0,0,1,1.66-1.7,2.5,2.5,0,0,1,2.34.41l2,1.56.44-.27a18.49,18.49,0,0,0,1.9-1.39l.4-.33-.86-2.42a2.5,2.5,0,0,1,.34-2.35,2.43,2.43,0,0,1,2.13-1l2.57.07.2-.48a17.7,17.7,0,0,0,.72-2.24l.13-.51Zm.06,3.15-1.52,0a4,4,0,0,0-3.38,1.66,4,4,0,0,0-.54,3.74l.5,1.43c-.29.23-.59.45-.89.65l-1.21-.92a4,4,0,0,0-6.35,2.06L17,32.09H15.87l-.43-1.46a4,4,0,0,0-6.35-2.06l-1.2.92c-.31-.2-.61-.42-.9-.65l.51-1.43A4,4,0,0,0,3.58,22l-1.52,0c-.13-.35-.24-.7-.35-1.05L3,20.14A4.07,4.07,0,0,0,4.73,16.8,4,4,0,0,0,3,13.46L1.71,12.6c.11-.35.22-.71.35-1.06l1.52.05A4,4,0,0,0,7.5,6.19L7,4.76q.44-.34.9-.66L9.09,5a4.07,4.07,0,0,0,3.72.64A4,4,0,0,0,15.44,3l.43-1.45c.37,0,.75,0,1.11,0L17.41,3A4,4,0,0,0,20,5.67,4.06,4.06,0,0,0,23.76,5L25,4.1c.3.21.6.43.89.66l-.5,1.42a4.05,4.05,0,0,0,3.92,5.41l1.52-.05c.13.35.24.71.34,1.06l-1.25.86a4,4,0,0,0,0,6.68l1.25.86C31,21.35,30.93,21.7,30.8,22.05Z"
        />
        <path
          class="cls-1"
          d="M16.43,10a6.76,6.76,0,1,0,6.76,6.76A6.76,6.76,0,0,0,16.43,10Zm0,12a5.26,5.26,0,1,1,5.26-5.25A5.26,5.26,0,0,1,16.43,22.05Z"
        />
      </g>
    </g>
  </svg>
);

export default Cog;
