import { APP_SPLASH_SUBMITTED, SAVE_APP_SPLASH } from './actions';

const initialState = {
  newColorChosen: false,
};

const appSplashScreen = (state, action) => {
  switch (action.type) {
    case SAVE_APP_SPLASH:
      return {
        ...state,
        newColorChosen: true,
      };
    case APP_SPLASH_SUBMITTED:
      return {
        ...state,
        newColorChosen: false,
      };
    default:
      return initialState;
  }
};

export default appSplashScreen;
