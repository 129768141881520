/* eslint-disable no-console */
import moment from 'moment';

import firebase from '../../firebase';
import {
  loadSubscription,
  selectApp,
} from '../../app/modules/appContext/actions';
import { receiveCustomDomain } from '../../app/modules/customDomain/actions';
import { resetEngageState } from '../../modules/engage/modules/push/actions';
import { getAppUrl } from '../../utils/appDetails';
import {
  updateContact,
  getListByPlanName,
} from '../account/modules/auth/login/actions';
import { getAllComponents } from './modules/build/modules/create/createComponent/actions';
import { loadProfile } from '../account/modules/auth/login/actions';
import { updateAccount } from '../account/actions';
import { fetchAppPreference } from '../../app/modules/appPreference/action';
import { openSnackbar } from '../../app/modules/snackbar/actions';

export const SAVE_APP_NAME = 'SAVE_APP_NAME';

export const noticeSet = () => ({
  type: 'OFFER_NOTICE_SET',
});

const receiveLayoutData = (appname, data) => ({
  type: 'RECEIVE_LAYOUT_DATA',
  data,
  appname,
});

const changingApp = appname => ({
  type: 'CHANGING_APP',
  appname,
});

const appChangeSuccess = appname => ({
  type: 'APP_CHANGE_SUCCESS',
  appname,
});

const appChangeError = (appname, err) => ({
  type: 'APP_CHANGE_ERROR',
  appname,
  err,
});

export const toggleAppMenu = open => ({
  type: 'TOGGLE_APP_MENU',
  open,
});

export const toggleUserMenu = open => ({
  type: 'TOGGLE_USER_MENU',
  open,
});

export const changeApp = (appname, oldAppname) => (dispatch, getState) => {
  const user = firebase.auth().currentUser;
  dispatch(changingApp(appname));
  return firebase
    .database()
    .ref(`users/${user.uid}`)
    .update({ lastVisited: appname })
    .then(() => dispatch(selectApp(appname)))
    .then(() => dispatch(resetEngageState()))
    .then(() => dispatch(removeListeners(oldAppname)))
    .then(() => dispatch(loadContent(appname)))
    .then(() => dispatch(appChangeSuccess(appname)))
    .then(() => dispatch(loadProfile()))
    .then(() => dispatch(loadSubscription(appname)))
    .then(() => dispatch(fetchAppPreference(appname)))
    .then(() => {
      const currentPlan =
        getState().app.appContext.subscription || 'free_trial';
      const account = getState().account.profile.account || '';

      const lists = getListByPlanName(
        currentPlan,
        getState().account.organisation,
      );

      return dispatch(updateContact({ account, appname, currentPlan, lists }));
    })
    .catch(err => dispatch(appChangeError(appname, err)));
};

const saveIntegrationAppData = (meta, appname) => ({
  type: SAVE_APP_NAME,
  meta,
  appname,
});

const receiveAlwaysData = (data, key) => ({
  type: 'RECEIVE_ALWAYS_DATA',
  data,
  key,
});

const receiveLazyData = (data, key) => ({
  type: 'RECEIVE_LAZY_DATA',
  data,
  key,
});

const receiveMetaData = (appname, data) => ({
  type: 'RECEIVE_META_DATA',
  data,
  appname,
});

const receiveDesignData = (appname, data) => ({
  type: 'RECEIVE_DESIGN_DATA',
  data,
  appname,
});

const receiveDraftData = data => ({
  type: 'RECEIVE_DRAFT_DATA',
  data,
});

const hydrateAlwaysData = data => ({
  type: 'HYDRATE_ALWAYS_DATA',
  data,
});

const hydrateLazyData = data => ({
  type: 'HYDRATE_LAZY_DATA',
  data,
});

const loadingContent = appname => ({
  type: 'LOADING_DRAFT_CONTENT',
  appname,
});

const contentLoaded = appname => ({
  type: 'DRAFT_CONTENT_LOADED',
  appname,
});

const modifyEditionStart = (appname, start) => ({
  type: 'DRAFT_EDITION_START_MODIFIED',
  appname,
  start,
});

export const receiveEditionDate = (appname, start) => ({
  type: 'RECEIVE_DRAFT_EDITION_START',
  start,
  appname,
});

const loadingIcon = appname => ({
  type: 'LOADING_ICON_CONTENT',
  appname,
});

const hydrateIconData = (data, appname) => ({
  type: 'HYDRATE_ICON_DATA',
  data,
  appname,
});

const integrationNodeUpdated = account => ({
  type: 'INTEGRATION_NODE_UPDATED',
  account,
});

const integrationNodeFailUpdated = account => ({
  type: 'INTEGRATION_NODE_FAIL_UPDATED',
  account,
});

const receivePreviusPublishedDomain = (appname, previusDomain) => ({
  type: 'PREVIUS_PUBLISHED_DOMAIN',
  previusDomain,
  appname,
});

const receivePaypalData = (appname, paypal) => ({
  type: 'RECEIVE_PAYPAL_DATA',
  paypal,
  appname,
});

const intanceIdInvalid = appname => ({
  type: 'WIX_INSTANCE_ID_INVALID',
  appname,
});

const receiveGaTrackingId = gaTrackingId => ({
  type: 'RECEIVE_GA_TRACKING_ID',
  data: gaTrackingId,
});

export const checkUsersSubscription = appname => async dispatch => {
  try {
    const sub = await firebase
      .database()
      .ref(`apps/${appname}/subscription`)
      .once('value')
      .then(snapshot => snapshot.val());

    if (sub) {
      return dispatch({
        type: 'SAVE_USER_SUBSCRIPTION',
        isUserSubscribed: true,
      });
    }

    return dispatch({
      type: 'SAVE_USER_SUBSCRIPTION',
      isUserSubscribed: false,
    });
  } catch (error) {
    dispatch({
      type: 'FAILED_SAVING_USER_SUBSCRIPTION',
      error,
    });
    return console.error(error);
  }
};

export const receivePublished = (appname, isPublished) => ({
  type: 'IS_PUBLISHED',
  appname,
  isPublished,
});

export const addIsPublishedListener = appname => dispatch => {
  const appRef = firebase.database().ref(`apps/${appname}`);

  appRef
    .child('published/meta_data')
    .on('value', snapshot =>
      dispatch(receivePublished(appname, snapshot.val() !== null)),
    );

  appRef
    .child('published/meta_data')
    .once('value')
    .then(snapshot =>
      dispatch(receivePublished(appname, snapshot.val() !== null)),
    );
};

export const removeIsPublishedListener = appname => {
  const appRef = firebase.database().ref(`apps/${appname}`);
  appRef.child('published/meta_data').off('value');

  return {
    type: 'REMOVE_IS_PUBLISHED_LISTENER',
    appname,
  };
};

export const loadIcon = appname => dispatch => {
  dispatch(loadingIcon(appname));

  const iconRef = firebase.database().ref(`icons/${appname}/`);
  // we load all draft data once before attaching listeners
  iconRef.once('value').then(iconSnapshot => {
    const data = iconSnapshot.val();
    dispatch(hydrateIconData(data, appname));

    // listen for changes to icon
    return iconRef.on('value', snapshot => {
      const nextData = snapshot.val();
      dispatch(hydrateIconData(nextData, appname));
    });
  });
};

export const loadContent = appname => (dispatch, getState) => {
  dispatch(loadingContent(appname));
  dispatch(loadIcon(appname));

  const appRef = firebase.database().ref(`apps/${appname}`);
  const draftRef = appRef.child('draft');
  const customDomainRef = appRef.child('custom_domain');
  const gaRef = appRef.child('gaTrackingId');
  const layoutDataRef = draftRef.child('layout_data');
  const alwaysDataRef = draftRef.child('always_data');
  const lazyDataRef = draftRef.child('lazy_data');
  const metaDataRef = draftRef.child('meta_data');
  const designDataRef = draftRef.child('design_data');
  const appPreviusDomain = appRef.child('previus_published_domain');
  const appPaypalData = appRef.child('paypal');

  // we load all draft data once to get initial state
  // before attaching listeners
  // listeners for lazy_data is only attached when specific components are mounted

  customDomainRef.once('value').then(snapshot => {
    customDomainRef.on('value', sp => {
      const customDomain = sp.val();

      return dispatch(receiveCustomDomain(customDomain));
    });

    const customDomain = snapshot.val();

    return dispatch(receiveCustomDomain(customDomain));
  });

  appPreviusDomain
    .once('value')
    .then(previusDomain =>
      dispatch(receivePreviusPublishedDomain(appname, previusDomain.val())),
    );

  gaRef.once('value').then(snapshot => {
    const gaTrackingId = snapshot.val();

    return dispatch(receiveGaTrackingId(gaTrackingId));
  });

  appPaypalData
    .once('value')
    .then(data => dispatch(receivePaypalData(appname, data.val())));

  draftRef.once('value').then(draftSnapshot => {
    const data = draftSnapshot.val();

    if (data === null) return null;
    const accountId = getState().account.profile.account;
    if (data.always_data) {
      for (let dataId in data.always_data) {
        data.always_data = {
          ...data.always_data,
          [dataId]: {
            ...data.always_data[dataId],
            key: dataId,
          },
        };
      }
    }
    dispatch(hydrateAlwaysData(data.always_data));
    dispatch(hydrateLazyData(data.lazy_data));
    dispatch(receiveLayoutData(appname, data.layout_data));
    dispatch(receiveMetaData(appname, data.meta_data));
    dispatch(receiveDesignData(appname, data.design_data));

    dispatch(receiveDraftData(data));

    dispatch(contentLoaded(appname));

    layoutDataRef.on('value', snapshot => {
      dispatch(receiveLayoutData(appname, snapshot.val()));
      dispatch(getAllComponents(appname));
    });

    metaDataRef.on('value', snapshot =>
      dispatch(receiveMetaData(appname, snapshot.val())),
    );

    designDataRef.on('value', snapshot => {
      dispatch(receiveDesignData(appname, snapshot.val()));
      const accountOrg = getState().account;
      const adminAppName = accountOrg.profile.admin_appname;
      const isAdminApp = (adminAppName && adminAppName.length > 0) || false;
      const data = { appname };
      data[`{${appname}} template`] = snapshot.val().skin;
    });

    alwaysDataRef.limitToLast(10).on('child_added', snapshot => {
      dispatch(receiveAlwaysData(snapshot.val(), snapshot.key));
      dispatch(getAllComponents(appname));
    });

    alwaysDataRef.on('child_changed', snapshot => {
      dispatch(receiveAlwaysData(snapshot.val(), snapshot.key));
      dispatch(getAllComponents(appname));
    });

    lazyDataRef
      .limitToFirst(10)
      .on('child_added', snapshot =>
        dispatch(receiveLazyData(snapshot.val(), snapshot.key)),
      );

    appPreviusDomain.on('value', previusDomain =>
      dispatch(receivePreviusPublishedDomain(appname, previusDomain.val())),
    );

    draftRef.on('child_changed', () => {
      let modifiedOn = moment(new Date()).valueOf();

      appRef.child('edition/start').transaction(date => {
        modifiedOn = date || modifiedOn;
        dispatch(modifyEditionStart(appname, modifiedOn));
        return modifiedOn;
      });
    });

    return null;
  });
};

export const widgetConfiguration = (appname, type) => dispatch => {
  if (type !== 'Wix') {
    return;
  }
  const instanceId = window.Wix.Utils.getInstanceId();
  try {
    if (instanceId) {
      firebase
        .database()
        .ref(`integration/wix/${instanceId}`)
        .update({
          appname,
          url: getAppUrl(appname),
        });
      dispatch(integrationNodeUpdated(instanceId));
    } else {
      dispatch(intanceIdInvalid(appname));
    }
  } catch (err) {
    dispatch(integrationNodeFailUpdated(instanceId));
  }
};

export const removeListeners = appname => {
  const iconRef = firebase.database().ref(`icons/${appname}/`);
  const draftRef = firebase.database().ref(`apps/${appname}/draft`);
  const layoutDataRef = draftRef.child('layout_data');
  const alwaysDataRef = draftRef.child('always_data');
  const lazyDataRef = draftRef.child('lazy_data');
  const metaDataRef = draftRef.child('meta_data');
  const designDataRef = draftRef.child('design_data');
  const appPreviusDomain = firebase
    .database()
    .ref(`apps/${appname}/previus_published_domain`);
  const accessibilitySettingsRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/accessibility`);

  layoutDataRef.off('value');

  alwaysDataRef.off('child_added');
  alwaysDataRef.off('child_changed');
  lazyDataRef.off('child_added');
  lazyDataRef.off('child_changed');
  appPreviusDomain.off('value');
  metaDataRef.off('value');

  designDataRef.off('value');
  draftRef.off('child_changed');
  iconRef.off('value');
  accessibilitySettingsRef.off('value');
  return {
    type: 'REMOVE_APP_LISTENERS',
    appname,
  };
};

export const saveMetaData = (appname, nextState) => dispatch => {
  const user = firebase.auth().currentUser;

  firebase
    .database()
    .ref(`users/${user.uid}/account`)
    .on('value', snapshot => {
      const accountName = snapshot.val();
      firebase
        .database()
        .ref(`accounts/${accountName}/apps/${appname}`)
        .update({ title: nextState.title });
    });

  const metaDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/meta_data`);
  metaDataRef.update(nextState);
  dispatch(saveIntegrationAppData(nextState, appname));
};

export const addOfferNotice = (offer, account) => (dispatch, getState) => {
  let content = '';
  let mobileContent = '';

  const accountOrg = getState().account;
  const isAppAdmin =
    (getState().account.auth.profile.admin_appname &&
      getState().account.auth.profile.admin_appname.length > 0) ||
    false;

  if (
    !accountOrg.organisation.appsumo_id &&
    !isAppAdmin &&
    !offer.coupon === 'lifetime'
  ) {
    if (offer && offer.coupon) {
      if (
        offer.coupon === 'lifetime' &&
        accountOrg &&
        accountOrg.organisation &&
        accountOrg.organisation.offerEligibility &&
        (accountOrg.organisation.offerEligibility.lifetime === false ||
          accountOrg.organisation.offerEligibility.lifetime === true)
      ) {
        return;
      }

      content = offer.welcome_notice;
      mobileContent = offer.welcome_notice_mobile || '';
    }

    dispatch({ type: 'ADDING_OFFER_NOTICE' });

    firebase
      .database()
      .ref(`accounts/${account}`)
      .child('notices')
      .push({ content, mobileContent, type: 'Offer', autoPopup: true })
      .catch(err => console.error(err));
  }
};

export const savePaypalData = (appname, nextState) => dispatch => {
  const metaDataRef = firebase.database().ref(`apps/${appname}/paypal`);
  metaDataRef.update(nextState).then(() => {
    dispatch(openSnackbar(`Paypal settings updated.`));
  });
};

export const addPageContentUpdateListner = (
  appname,
  currentPage,
  prevPage,
) => dispatch => {
  const appRef = firebase.database().ref(`apps/${appname}`);
  const draftRef = appRef.child('draft');
  const alwaysDataRef = draftRef.child('always_data');
  if (alwaysDataRef && currentPage) {
    const currentPageDataRef = alwaysDataRef.child(currentPage);
    const prevPageDataRef = prevPage ? alwaysDataRef.child(prevPage) : null;
    dispatch({ type: 'COMPONENT_UPDATED', currentPage, appname });
    draftRef.once('value').then(draftSnapshot => {
      const data = draftSnapshot.val();

      if (data === null) return null;

      if (prevPageDataRef) {
        prevPageDataRef.off('value');
      }

      currentPageDataRef.on('value', snapshot => {
        if (
          snapshot.val().isUpdateAvailable === undefined ||
          !snapshot.val().isUpdateAvailable
        ) {
          currentPageDataRef.update({
            isUpdateAvailable: true,
          });
        }
      });

      return null;
    });
  }
};

export const isAppValid = appname => async (dispatch, getState) => {
  dispatch({
    type: 'VALIDATING_APP',
  });
  const appStructure = getState().editor.structure;
  const subscriptions = getState().account.subscriptions;
  const isWixUser =
    getState().account.integration &&
    getState().account.integration.type === 'Wix';
  const accountId = getState().account.organisation.key;
  const subscription = subscriptions.items[appname];
  const underReview = getState().account.organisation.underReview;
  const passedReview = getState().account.organisation.passedReview;
  const hasPlan =
    subscription && subscription.plan && subscription.plan !== 'free';

  if (passedReview && !underReview && !hasPlan && !isWixUser) {
    dispatch({
      type: 'APP_VALIDATION_DONE',
    });
    return true;
  }

  if (hasPlan || isWixUser || appStructure.routes.length > 1) {
    dispatch({
      type: 'APP_VALIDATION_DONE',
    });
    return true;
  } else {
    const headers = new Headers();
    const user = firebase.auth().currentUser;
    const body = JSON.stringify({
      appname,
      accountId,
    });
    user.getIdToken().then(token => {
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);
      fetch(`${process.env.REACT_APP_ACCOUNT_API}/account-under-review`, {
        method: 'post',
        headers,
        body,
        mode: 'cors',
      })
        .then(() => {
          dispatch(updateAccount('underReview', true));
          dispatch(updateAccount('passedReview', null));
        })
        .catch(err => console.error(err));
    });
    dispatch({
      type: 'APP_VALIDATION_DONE',
    });
    return false;
  }
};
