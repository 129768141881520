import firebase from '../../../../firebase';
import { publishApp } from '../../../../app/modules/publishDialog/actions';
import seedComponent from '../../../editor/modules/build/modules/create/createComponent/seedComponent';
import { INTERNAL_APP_PATHS } from '../../../../config/constants';

const ADD_LIVESTREAM_COMPONENT_ERROR = 'ADD_LIVESTREAM_COMPONENT_ERROR';
const ADD_PAGE_ERROR = 'ADD_PAGE_ERROR';
const LIVESTREAM_PAGE_CREATED = 'LIVESTREAM_PAGE_CREATED';
const LIVESTREAM_COMPONENT_ADDED = 'LIVESTREAM_COMPONENT_ADDED';

export const updateChecked = (
  appname,
  hasLivestreamAccess,
  user,
) => async () => {
  const userRef = firebase.database().ref(`app_users/${appname}/${user}/`);
  await userRef.update({ hasLivestreamAccess: !hasLivestreamAccess });
};

export const toggleBan = (appname, livestreamBanned, user) => async () => {
  const userRef = firebase.database().ref(`app_users/${appname}/${user}/`);
  await userRef.update({ livestreamBanned: !livestreamBanned });
};

export const reportAddLivestreamComponentError = err => ({
  type: ADD_LIVESTREAM_COMPONENT_ERROR,
  err,
});

export const reportAddPageError = err => ({
  type: ADD_PAGE_ERROR,
  err,
});

const toPath = title =>
  title
    .toLowerCase()
    .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '')
    .replace(/\s+/g, '-')
    .trim();

const createLivestreamPage = (appname, getState, alwaysDataRef) => {
  const childRoutesRef = firebase
    .database()
    .ref(`apps/${appname}/draft/layout_data/childRoutes`);
  const layoutData = getState().engage.structure.routes || [];
  const form = getState().editor.build.create.createPage;
  const pageTitle = 'Livestream';
  const paths = layoutData ? layoutData.map(item => item.path) : [];
  paths.push(...INTERNAL_APP_PATHS);
  const tabs = null;
  let newPageKey;
  let path = toPath(pageTitle);

  if (paths.indexOf(path) !== -1) {
    path = path.concat('-', Date.now().toString());
  }

  return alwaysDataRef
    .push({
      title: pageTitle,
      type: form.type,
      path,
      tabs,
    })
    .then(pageRef => {
      newPageKey = pageRef.key;

      return childRoutesRef
        .transaction(value => {
          if (!value) return [newPageKey];
          return [...value, pageRef.key];
        })
        .then(() => newPageKey);
    });
};

const createLivestreamComponent = async (
  appname,
  pageKey,
  alwaysDataRef,
  account,
  palette,
) => {
  const lazyDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data`);
  const componentsArrayRef = alwaysDataRef.child(`${pageKey}/components`);

  const livestreamComponentSeedData = seedComponent(
    'Livestream',
    account,
    palette,
    null,
  );

  const snapshot = await lazyDataRef.push(livestreamComponentSeedData.content);
  // update not_private permissions for new components
  // so that new components will be public when Member Login is enabled
  firebase
    .database()
    .ref(`app_groups/${appname}/not_private`)
    .update({ [snapshot.key]: true });
  const snapshot1 = snapshot;
  const snapshot2 = alwaysDataRef.push({
    type: 'Livestream',
    lazy_data: snapshot1.key,
    design: livestreamComponentSeedData.design,
    layout: livestreamComponentSeedData.layout,
  });
  componentsArrayRef.transaction(value2 => {
    if (!value2) return [snapshot2.key];

    return [...value2, snapshot2.key];
  });
  const key = snapshot2.key;
  return key;
};

export const addLivestreamComponent = (appname, history) => (
  dispatch,
  getState,
) => {
  const alwaysDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data`);
  createLivestreamPage(appname, getState, alwaysDataRef)
    .then(newPageKey => {
      dispatch({ type: LIVESTREAM_PAGE_CREATED, newPageKey });
      const account = getState().account.profile.account;
      const palette = getState().editor.design[appname].palette;
      createLivestreamComponent(
        appname,
        newPageKey,
        alwaysDataRef,
        account,
        palette,
      )
        .then(key => {
          dispatch({ type: LIVESTREAM_COMPONENT_ADDED, key });
          dispatch(publishApp(appname));
          history.push(
            history.location.pathname.replace(
              'engage/livestream',
              `editor/build/list?page=${newPageKey}&component=${key}`,
            ),
          );
        })
        .catch(err => dispatch(reportAddLivestreamComponentError(err)));
    })
    .catch(err => dispatch(reportAddPageError(err)));
};
