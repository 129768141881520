import firebase from 'firebase';
import { isEmpty, flatten } from 'lodash';
import i18n from 'i18next';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import { loadContent } from '../../../../actions';

export const changePageAccess = ({
  appname,
  publicPages,
  privatePages,
}) => async dispatch => {
  let publicComponents = {};
  try {
    const nodesToUpdate = [];
    publicPages.forEach(page => {
      let components = null;
      components = page.components;

      if (page.type === 'TabView') {
        components =
          !isEmpty(page.tabs) &&
          flatten(
            page.tabs.map(tab => !isEmpty(tab.components) && tab.components),
          );
      }

      if (!isEmpty(components)) {
        components.forEach(component => {
          publicComponents = {
            ...publicComponents,
            [component.lazy_data]: true,
          };
        });
      }

      nodesToUpdate.push(
        firebase
          .database()
          .ref(`apps/${appname}/draft/always_data/${page.key}`)
          .update({
            publicPage: true,
          })
          .catch(err => {
            console.error(err);
          }),
      );
    });
    privatePages.forEach(page => {
      nodesToUpdate.push(
        firebase
          .database()
          .ref(`apps/${appname}/draft/always_data/${page.key}`)
          .update({
            publicPage: false,
          })
          .catch(err => {
            console.error(err);
          }),
      );
    });
    nodesToUpdate.push(
      firebase
        .database()
        .ref(`app_groups/${appname}/publicComponents/draft`)
        .set(publicComponents),
    );
    await Promise.all(nodesToUpdate);
    dispatch(loadContent(appname));
    dispatch(openSnackbar(i18n.t('Updated your page access settings')));
    return;
  } catch (err) {
    console.error(err);
    dispatch(
      openSnackbar(i18n.t('Something went wrong could not save your settings')),
    );
  }
};
