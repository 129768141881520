import ReactGA from 'react-ga';
import _get from 'lodash/get';
import firebase from '../../../firebase';
import moment from 'moment';
import {
  addIsPublishedListener,
  receiveEditionDate,
  receivePublished,
  widgetConfiguration,
} from '../../../modules/editor/actions';
import { updateContact } from '../../../modules/account/modules/auth/login/actions';

const database = firebase.database();

export const REQUEST_PUBLISH = 'REQUEST_PUBLISH';
export const APP_PUBLISHED = 'APP_PUBLISHED';
export const LAUNCH_PUBLISH_DIALOG_DOMAIN_CHANGE =
  'LAUNCH_PUBLISH_DIALOG_DOMAIN_CHANGE';

export const launchPublishDialog = appname => ({
  type: 'LAUNCH_PUBLISH_DIALOG',
  appname,
});

export const closePublishDialog = () => ({
  type: 'CLOSE_PUBLISH_DIALOG',
});

export const requestPublish = appname => ({
  type: REQUEST_PUBLISH,
  appname,
});

export const launchPublishDialogDomainChange = appname => ({
  type: LAUNCH_PUBLISH_DIALOG_DOMAIN_CHANGE,
  appname,
});

export const appPublished = appname => ({
  type: APP_PUBLISHED,
  appname,
});

export const resetPublishState = appname => ({
  type: 'RESET_PUBLISH_STATE',
  appname,
});

const iconDataUpdated = (appname, modified) => ({
  type: 'ICON_DATA_UPDATED',
  appname,
  modified,
});

const reportError = err => ({
  type: 'PUBLISH_ERROR',
  err,
});

const publish = (appname, dispatch, getState) => {
  const type = _get(getState(), 'account.organisation.integration.type');
  const accountId = _get(getState(), 'account.profile.account');

  const admin_appname = getState().account.profile.admin_appname
    ? getState().account.profile.admin_appname
    : null;
  const referrer = getState().account.organisation.referrer;

  if (!appname) return null;
  dispatch(requestPublish(appname));

  ReactGA.event({
    category: 'User',
    action: 'Publish app',
  });

  const appRef = database.ref(`apps/${appname}`);
  const publishingRef = appRef.child('publishing');

  const onPublishEnd = snapshot => {
    if (!snapshot.exists()) {
      publishingRef.off('value', onPublishEnd);
      if (type) {
        dispatch(widgetConfiguration(appname, type));
      }
      dispatch(appPublished(appname));
      dispatch(receivePublished(appname, true));
      addIsPublishedListener(appname);
      const curDate = Date.now();
      const data = { appname };
      data[`{${appname}} last_publish`] = curDate;

      if (referrer === 'adillo') {
        appRef.child('version').once('value', snapshot => {
          if (snapshot.exists()) {
            if (snapshot.val() === 0) {
              dispatch({ type: 'TOGGLE_ADILLO_MSG', data: true });
            }
          }
        });
      }
      // Call Greshmarketer API
      dispatch(
        updateContact({
          account: accountId,
          app_published: moment(curDate).format('DD/MM/YYYY hh:mm:ss'),
          admin_appname,
        }),
      );
      dispatch(receiveEditionDate(appname));
      dispatch(iconDataUpdated(appname, false));
    }
  };

  const onPublishStart = snapshot => {
    if (snapshot.exists()) {
      publishingRef.off('value', onPublishStart);
      publishingRef.on('value', onPublishEnd);
    }
  };

  // Listen for worker starting build task
  publishingRef.on('value', onPublishStart);
};

/**
 * Sends tasks to build queue
 * publishing property will be set as null when done
 */
export const publishApp = appname => (dispatch, getState) => {
  const customDomain = _get(getState(), 'app.customDomain');
  const buildQueue = database.ref('queues/builder/tasks');
  publish(appname, dispatch, getState);
  database
    .ref(`apps/${appname}/draft/design_data/skin`)
    .once('value', skinSnap => {
      const value = { appname, skin: skinSnap.val() };
      if (customDomain && customDomain.enabled) {
        value.custom_domain = customDomain;
      }

      return buildQueue.push(value);
    })
    .catch(err => dispatch(reportError(err)));
};
