import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import Modal from '../../../../../account/components/modal';
import { CTAButton } from '../../../../../../app/components/buttons';

const Header = styled.h1`
  font-size: 40px;
  color: rgba(30, 30, 30, 0.6);
  text-align: center;
  text-transform: capitalize;
  margin: 0 0 5px;
`;

const Container = styled.div`
  padding: 0 15px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-flow: inherit;
  max-height: 100%;
  align-items: center;
  strong: {
    font-weight: bold;
  }
  p {
    margin: 5px 0;
  }
  .img_wrapper {
    position: relative;
    display: block;
    height: 100%;
    img {
      max-width: 65%;
      padding: 20px;
    }
  }
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    .img_wrapper {
      height: 20%;
      img {
        height: 100%;
      }
    }
  }
`;

const SkipButton = styled.button`
  margin: 0 auto;
  font-size: 14px;
`;

const marginStyle = {
  marginTop: '15px',
};

const PageUpdateModal = ({
  open,

  handleClose,
  handleSave,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      showHeader={false}
      bodyClassName="beezer-modal beezer-medium-modal"
    >
      <Header>
        <Trans>Notice</Trans>
      </Header>
      <Container>
        <div>
          {' '}
          <strong style={marginStyle}>
            <Trans>Your are about to change the page title</Trans>
          </strong>
          <p>
            <span style={{ color: '#fe4d4d', display: 'inline' }}>
              <Trans>Warning:</Trans>
            </span>{' '}
            <Trans>
              If you update the page title and you have added it as a navigation
              link in any component, you will have to update the navigation
              links in those components as well.
            </Trans>
          </p>
        </div>

        <CTAButton
          label={t('Save')}
          outlineRGB="195, 207, 47"
          style={{
            margin: '10px auto',
            display: 'block',
            maxWidth: '210px',
            marginBottom: '1rem',
            alignItems: 'center',
          }}
          onClick={() => handleSave()}
        />
        <SkipButton onClick={() => handleClose()}>
          {' '}
          <Trans>Cancel</Trans>{' '}
        </SkipButton>
      </Container>
    </Modal>
  );
};

PageUpdateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default PageUpdateModal;
