const initialState = {
  open: false,
  message: '',
  autoHideDuration: 4000,
  onActionClick: undefined,
};

const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_SNACKBAR':
      return {
        ...state,
        message: action.message,
        onActionClick: action.onActionClick,
        open: true,
      };

    case 'CLOSE_SNACKBAR':
      return {
        ...state,
        open: false,
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default snackbar;
