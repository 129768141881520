import React, { Component } from 'react';
import { Card } from 'material-ui/Card';
import { withTranslation } from 'react-i18next';
import Container from '../../../../../../../app/components/container';
import { Tabs, Tab } from '../../../../../components/tabs';

class EventCourses extends Component {
  static defaultProps = {
    filters: [],
  };

  state = {};

  render() {
    const { t } = this.props;
    return (
      <div className="events-list-container">
        <Tabs>
          <Tab label={t('content')}>
            <Container>
              <Card style={{ textAlign: 'center' }}>
                <img alt="Component Icon" src={require('./icon.png')} />
              </Card>
            </Container>
          </Tab>
          <Tab
            label={t('design')}
            disabled
            style={{
              color: '#d9d9d9',
            }}
          />
          <Tab
            label={t('layout')}
            disabled
            style={{
              color: '#d9d9d9',
            }}
          />
        </Tabs>
      </div>
    );
  }
}

export default withTranslation()(EventCourses);
