import { combineReducers } from 'redux';

import createApp from './createApp/reducer';
import createComponent from './createComponent/reducer';
import createPage from './createPage/reducer';
import createTab from './createTab/reducer';
import duplicatePage from './duplicatePage/reducer';
import duplicateApp from './duplicateApp/reducer';
import duplicateComponent from './duplicateComponent/reducer';

export default combineReducers({
  createApp,
  createComponent,
  createPage,
  createTab,
  duplicatePage,
  duplicateApp,
  duplicateComponent,
});
