import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
.st0{fill:#FFFFFF;}
.st1{fill:none;stroke:#D0D2D3;stroke-width:5.1639;stroke-miterlimit:6.8852;}
.st2{fill:#D0D2D3;}
.st3{fill:none;stroke:#F1F1F2;stroke-width:1.7213;stroke-miterlimit:6.8852;}
.st4{fill:none;stroke:#DBDBDB;stroke-width:1.7213;stroke-miterlimit:6.8852;}
.st5{fill:#F1F1F2;}
.st6{fill:none;stroke:#DBDBDB;stroke-width:2.5819;stroke-miterlimit:6.8854;}
.st7{fill:none;stroke:#DBDBDB;stroke-width:2.5819;stroke-miterlimit:6.8852;}
.st8{fill:none;stroke:#D0D2D3;stroke-width:5.1639;stroke-miterlimit:17.2129;}
.st9{fill:#E5E5E5;}
.st10{fill:#FFFFFF;stroke:#CBCBCB;stroke-miterlimit:10;}
.st11{fill:#F6F6F6;}
.st12{fill:#D1D2D2;}
`;

const HorizontalSliderCaption = () => (
  <Svg
    className="layout layout-header-large"
    width="750.000000pt"
    height="1334.000000pt"
    viewBox="0 0 750 1334"
    preserveAspectRatio="xMidYMid meet"
  >
    <g id="Layer_2_1_">
	<g id="Layer_1-2">
		<path class="st0" d="M58.3,206.6h-2.4c-1.9,0-3.4-1.5-3.4-3.4V153c0-1.9,1.5-3.4,3.4-3.4h2.4"/>
		<path class="st1" d="M58.3,206.6h-2.4c-1.9,0-3.4-1.5-3.4-3.4V153c0-1.9,1.5-3.4,3.4-3.4h2.4"/>
		<path class="st0" d="M58.3,371.8h-2.1c-1.9,0-3.4-1.5-3.4-3.4v-94c0-1.9,1.5-3.4,3.4-3.4h2.1"/>
		<path class="st1" d="M58.3,371.8h-2.1c-1.9,0-3.4-1.5-3.4-3.4v-94c0-1.9,1.5-3.4,3.4-3.4h2.1"/>
		<path class="st0" d="M691.4,270.9h1.4c2.9,0,5.2,2.3,5.2,5.2v90.5c0,2.9-2.3,5.2-5.2,5.2h-1.4"/>
		<path class="st1" d="M691.4,270.9h1.4c2.9,0,5.2,2.3,5.2,5.2v90.5c0,2.9-2.3,5.2-5.2,5.2h-1.4"/>
		<path class="st0" d="M58.4,496.1H53c-1.9,0,0-1.5,0-3.4v-94c0-1.9-1.9-3.4,0-3.4h5.5"/>
		<path class="st1" d="M58.4,496.1H53c-1.9,0,0-1.5,0-3.4v-94c0-1.9-1.9-3.4,0-3.4h5.5"/>
		<path class="st0" d="M147.6,1331.4c-47.9,0-86.7-38.9-86.8-86.8V89.3c0-47.9,38.9-86.7,86.8-86.8h454.6
			c47.9,0,86.7,38.9,86.8,86.8v1155.3c0,47.9-38.9,86.7-86.8,86.8L147.6,1331.4L147.6,1331.4z"/>
		<path class="st2" d="M602,5.2c46.4,0.1,84.1,37.7,84.2,84.2v1155.3c0,46.5-37.7,84.2-84.2,84.2H147.6
			c-46.4-0.1-84.1-37.7-84.2-84.2V89.3c0-46.5,37.7-84.2,84.2-84.2L602,5.2 M602,0H147.6C98.3,0,58.3,40,58.3,89.3v1155.3
			c0,49.3,40,89.3,89.4,89.3h454.6c49.3,0,89.3-40,89.3-89.3V89.3C691.4,40,651.4,0.1,602,0z"/>
		<path class="st3" d="M670.2,1175.6c0,2.9-2.3,5.2-5.2,5.2H83.7c-2.9,0-5.2-2.3-5.2-5.2V142.9c0.1-2.8,2.3-5.1,5.2-5.2H665
			c2.9,0,5.2,2.3,5.2,5.2V1175.6L670.2,1175.6z"/>
		<path class="st4" d="M670.2,1175.6c0,2.9-2.3,5.2-5.2,5.2H83.7c-2.9,0-5.2-2.3-5.2-5.2V142.9c0.1-2.8,2.3-5.1,5.2-5.2H665
			c2.9,0,5.2,2.3,5.2,5.2V1175.6L670.2,1175.6z"/>
		
			<ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 153.5898 324.3667)" class="st5" cx="267.4" cy="71.9" rx="11.4" ry="11.4"/>
		
			<ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 153.5898 324.3667)" class="st6" cx="267.4" cy="71.9" rx="11.4" ry="11.4"/>
		<circle class="st5" cx="373.4" cy="26.5" r="6.9"/>
		<circle class="st7" cx="373.4" cy="26.5" r="6.9"/>
		<circle class="st8" cx="373.4" cy="1258.3" r="51.8"/>
		<path class="st9" d="M424.2,72.3c0,2.9-2.3,5.2-5.2,5.2h-92.9c-2.9,0-5.2-2.3-5.2-5.2v-0.2c0-2.9,2.3-5.2,5.2-5.2H419
			C421.9,67.1,424.2,69.4,424.2,72.3z"/>
		<path class="st4" d="M424.2,72.3c0,2.9-2.3,5.2-5.2,5.2h-92.9c-2.9,0-5.2-2.3-5.2-5.2v-0.2c0-2.9,2.3-5.2,5.2-5.2H419
			C421.9,67.1,424.2,69.4,424.2,72.3z"/>
	</g>
</g>
<g>
	<g id="change3_2_">
		<rect x="491.2" y="602.9" class="st10" width="177.3" height="155.4"/>
	</g>
	<g id="change2_2_">
		<path class="st11" d="M533.2,669.6c26,0,30.1,55.7,40.6,55.7s15.2-33.3,30-33.3c20.4,0,30.6,60.2,65,60.2c0,0,0.1,0,0.1,0V603.2
			H491.2v138.1C497.3,718.9,513.1,669.6,533.2,669.6z M629.7,624.2c14,0,25.3,11.3,25.3,25.3c0,14-11.3,25.3-25.3,25.3
			c-14,0-25.3-11.3-25.3-25.3C604.4,635.5,615.7,624.2,629.7,624.2z"/>
	</g>
	<g id="change1_2_">
		<path class="st12" d="M668.9,757.9v-5.6c0,0-0.1,0-0.1,0c-34.4,0-44.6-60.2-65-60.2c-14.9,0-19.5,33.3-30,33.3
			c-10.5,0-14.6-55.7-40.6-55.7c-20.1,0-35.8,49.3-41.9,71.7v16.6H668.9L668.9,757.9z"/>
		<circle class="st12" cx="629.7" cy="649.5" r="25.3"/>
	</g>
</g>
<g>
	<g id="change3_3_">
		<rect x="78" y="602.9" class="st10" width="177.3" height="155.4"/>
	</g>
	<g id="change2_3_">
		<path class="st11" d="M120,669.6c26,0,30.1,55.7,40.6,55.7c10.5,0,15.2-33.3,30-33.3c20.4,0,30.6,60.2,65,60.2c0,0,0.1,0,0.1,0
			V603.2H78v138.1C84.2,718.9,99.9,669.6,120,669.6z M216.5,624.2c14,0,25.3,11.3,25.3,25.3c0,14-11.3,25.3-25.3,25.3
			c-14,0-25.3-11.3-25.3-25.3C191.2,635.5,202.5,624.2,216.5,624.2z"/>
	</g>
	<g id="change1_3_">
		<path class="st12" d="M255.7,757.9v-5.6c0,0-0.1,0-0.1,0c-34.4,0-44.6-60.2-65-60.2c-14.9,0-19.5,33.3-30,33.3
			c-10.5,0-14.6-55.7-40.6-55.7c-20.1,0-35.8,49.3-41.9,71.7v16.6H255.7L255.7,757.9z"/>
		<circle class="st12" cx="216.5" cy="649.5" r="25.3"/>
	</g>
</g>
<g>
	<g id="change3_1_">
		<rect x="215.6" y="548.4" class="st10" width="301.2" height="263.9"/>
	</g>
	<g id="change2_1_">
		<path class="st11" d="M286.9,661.6c44.1,0,51.1,94.7,68.9,94.7s25.8-56.6,51-56.6c34.7,0,52,102.3,110.4,102.3c0.1,0,0.2,0,0.2,0
			V548.7H215.6v234.6C226,745.3,252.8,661.6,286.9,661.6z M450.8,584.5c23.7,0,43,19.2,43,43c0,23.7-19.2,43-43,43
			c-23.7,0-42.9-19.2-42.9-43C407.8,603.7,427.1,584.5,450.8,584.5z"/>
	</g>
	<g id="change1_1_">
		<path class="st12" d="M517.4,811.5V802c-0.1,0-0.2,0-0.2,0c-58.4,0-75.7-102.3-110.4-102.3c-25.2,0-33.1,56.6-51,56.6
			c-17.8,0-24.8-94.7-68.9-94.7c-34.1,0-60.8,83.7-71.2,121.8v28.2H517.4L517.4,811.5z"/>
		<circle class="st12" cx="450.8" cy="627.4" r="43"/>
	</g>
</g>
<g>
	<g id="change3_4_">
		<rect x="84.9" y="855.5" class="st10" width="97.3" height="77.6"/>
	</g>
	<g id="change2_4_">
		<path class="st11" d="M107.9,888.8c14.3,0,16.5,27.8,22.3,27.8s8.3-16.6,16.5-16.6c11.2,0,16.8,30.1,35.7,30.1c0,0,0,0,0.1,0
			v-74.4H84.9v69C88.3,913.4,96.9,888.8,107.9,888.8z M160.8,866.1c7.7,0,13.9,5.6,13.9,12.6c0,7-6.2,12.6-13.9,12.6
			c-7.7,0-13.9-5.6-13.9-12.6C147,871.8,153.2,866.1,160.8,866.1z"/>
	</g>
	<g id="change1_4_">
		<path class="st12" d="M182.3,932.8V930c0,0,0,0-0.1,0c-18.8,0-24.4-30.1-35.7-30.1c-8.1,0-10.7,16.6-16.5,16.6
			c-5.8,0-8-27.8-22.3-27.8c-11,0-19.6,24.6-23,35.8v8.3H182.3L182.3,932.8z"/>
		<ellipse class="st12" cx="160.8" cy="878.7" rx="13.9" ry="12.6"/>
	</g>
</g>
<g>
	<g id="change3_5_">
		<rect x="208.6" y="855.5" class="st10" width="97.3" height="77.6"/>
	</g>
	<g id="change2_5_">
		<path class="st11" d="M231.6,888.8c14.3,0,16.5,27.8,22.3,27.8s8.3-16.6,16.5-16.6c11.2,0,16.8,30.1,35.7,30.1c0,0,0,0,0.1,0
			v-74.4h-97.4v69C212,913.4,220.6,888.8,231.6,888.8z M284.6,866.1c7.7,0,13.9,5.6,13.9,12.6c0,7-6.2,12.6-13.9,12.6
			c-7.7,0-13.9-5.6-13.9-12.6C270.7,871.8,276.9,866.1,284.6,866.1z"/>
	</g>
	<g id="change1_5_">
		<path class="st12" d="M306.1,932.8V930c0,0,0,0-0.1,0c-18.8,0-24.4-30.1-35.7-30.1c-8.1,0-10.7,16.6-16.5,16.6s-8-27.8-22.3-27.8
			c-11,0-19.6,24.6-23,35.8v8.3H306.1L306.1,932.8z"/>
		<ellipse class="st12" cx="284.6" cy="878.7" rx="13.9" ry="12.6"/>
	</g>
</g>
<g>
	<g id="change3_6_">
		<rect x="330.3" y="855.5" class="st10" width="97.3" height="77.6"/>
	</g>
	<g id="change2_6_">
		<path class="st11" d="M353.3,888.8c14.3,0,16.5,27.8,22.3,27.8c5.8,0,8.3-16.6,16.5-16.6c11.2,0,16.8,30.1,35.7,30.1
			c0,0,0,0,0.1,0v-74.4h-97.4v69C333.6,913.4,342.3,888.8,353.3,888.8z M406.2,866.1c7.7,0,13.9,5.6,13.9,12.6
			c0,7-6.2,12.6-13.9,12.6c-7.7,0-13.9-5.6-13.9-12.6C392.3,871.8,398.6,866.1,406.2,866.1z"/>
	</g>
	<g id="change1_6_">
		<path class="st12" d="M427.7,932.8V930c0,0,0,0-0.1,0c-18.8,0-24.4-30.1-35.7-30.1c-8.1,0-10.7,16.6-16.5,16.6
			c-5.8,0-8-27.8-22.3-27.8c-11,0-19.6,24.6-23,35.8v8.3H427.7L427.7,932.8z"/>
		<ellipse class="st12" cx="406.2" cy="878.7" rx="13.9" ry="12.6"/>
	</g>
</g>
<g>
	<g id="change3_7_">
		<polygon class="st10" points="450.7,854.7 547.9,856.3 546.4,933.8 449.2,932.3 		"/>
	</g>
	<g id="change2_7_">
		<path class="st11" d="M473,888.4c14.2,0.2,16,28.1,21.7,28.2c5.8,0.1,8.6-16.5,16.8-16.4c11.2,0.2,16.2,30.3,35.1,30.6
			c0,0,0,0,0.1,0l1.4-74.4l-97.4-1.6l-1.3,68.9C452.9,912.6,462,888.2,473,888.4z M526.4,866.6c7.7,0.1,13.8,5.9,13.6,12.9
			c-0.1,7-6.4,12.5-14.1,12.4c-7.7-0.1-13.8-5.9-13.6-12.9C512.4,872,518.8,866.4,526.4,866.6z"/>
	</g>
	<g id="change1_7_">
		<path class="st12" d="M546.6,933.6l0.1-2.8c0,0,0,0-0.1,0c-18.8-0.3-23.9-30.5-35.1-30.6c-8.1-0.1-11,16.4-16.8,16.4
			s-7.5-27.9-21.7-28.2c-11-0.2-20.1,24.3-23.7,35.4l-0.2,8.3L546.6,933.6L546.6,933.6z"/>
		<ellipse class="st12" cx="526.2" cy="879.2" rx="13.9" ry="12.6"/>
	</g>
</g>
<g>
	<g id="change3_8_">
		<polygon class="st10" points="571.1,854.7 668.4,856.3 666.9,933.8 569.6,932.3 		"/>
	</g>
	<g id="change2_8_">
		<path class="st11" d="M593.5,888.4c14.2,0.2,16,28.1,21.7,28.2c5.8,0.1,8.6-16.5,16.8-16.4c11.2,0.2,16.2,30.3,35.1,30.6
			c0,0,0,0,0.1,0l1.4-74.4l-97.4-1.6l-1.3,68.9C573.4,912.6,582.5,888.2,593.5,888.4z M646.9,866.6c7.7,0.1,13.8,5.9,13.6,12.9
			c-0.1,7-6.4,12.5-14.1,12.4c-7.7-0.1-13.8-5.9-13.6-12.9C632.9,872,639.2,866.4,646.9,866.6z"/>
	</g>
	<g id="change1_8_">
		<path class="st12" d="M667.1,933.6l0.1-2.8c0,0,0,0-0.1,0c-18.8-0.3-23.9-30.5-35.1-30.6c-8.1-0.1-11,16.4-16.8,16.4
			c-5.8-0.1-7.5-27.9-21.7-28.2c-11-0.2-20.1,24.3-23.7,35.4l-0.2,8.3L667.1,933.6L667.1,933.6z"/>
		<ellipse class="st12" cx="646.6" cy="879.2" rx="13.9" ry="12.6"/>
	</g>
</g>
  </Svg>
);

export default HorizontalSliderCaption;
