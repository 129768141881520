import {
  REQUEST_PUBLISH,
  APP_PUBLISHED,
  LAUNCH_PUBLISH_DIALOG_DOMAIN_CHANGE,
} from './actions';

const initialState = {
  open: false,
  appname: null,
  requestingPublish: false,
  publishSuccessful: false,
  publishing: false,
};

const publish = (state = initialState, action) => {
  switch (action.type) {
    case 'LAUNCH_PUBLISH_DIALOG':
      return {
        ...state,
        open: true,
      };

    case 'CLOSE_PUBLISH_DIALOG':
      return {
        ...state,
        open: false,
      };

    case REQUEST_PUBLISH:
      return {
        ...state,
        appname: action.appname,
        requestingPublish: true,
      };

    case APP_PUBLISHED:
      return {
        ...state,
        requestingPublish: false,
        publishSuccessful: true,
        publishing: false,
      };

    case LAUNCH_PUBLISH_DIALOG_DOMAIN_CHANGE: {
      return {
        ...state,
        open: true,
        appname: action.appname,
        requestingPublish: true,
      };
    }

    case 'RESET_PUBLISH_STATE':
      return {
        ...state,
        requestingPublish: false,
        publishSuccessful: false,
        publishing: false,
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default publish;
