import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import { Trans, withTranslation } from 'react-i18next';

import ImagePreviewButton from './ImagePreviewButton';
import ImagePreviewCTA from './ImagePreviewCTA';
import AvatarBackdrop from './AvatarBackdrop';
import { ProgressOverlay } from '../../../../../app/components/progress';

const Img = styled.div`
  z-index: -1;
  width: 100%;
  display: block;
  height: ${props => props.height || '150px'};
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.srcUrl})`};
  background-size: cover;
`;

const Video = props => (
  <video
    key={props.src}
    width="100%"
    height="100%"
    controls
    style={{ 'object-fit': 'cover' }}
  >
    <source src={props.src} type="video/mp4" />
    <source src={props.src} type="video/webm" />
    <track
      src="captions_en.vtt"
      kind="captions"
      srcLang="en"
      label="english_captions"
    />
    <Trans>Your browser does not support the video tag.</Trans>
  </video>
);

class ImagePreview extends Component {
  static propTypes = {
    src: PropTypes.string,
    avatar: PropTypes.bool,
    pending: PropTypes.bool,
    height: PropTypes.string,
    width: PropTypes.string,
    noDelete: PropTypes.bool,
    showCropAreaSizes: PropTypes.bool,
    onDeleteImage: PropTypes.func.isRequired,
    onChangeImage: PropTypes.func.isRequired,
    isVideoAllowed: PropTypes.bool,
    type: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    src: null,
    avatar: false,
    height: null,
    showCropAreaSizes: false,
    width: null,
    pending: false,
    noDelete: false,
    isVideoAllowed: false,
    type: null,
  };

  state = {
    popover: false,
    anchorEl: null,
    image: null,
    isVideo: false,
  };

  componentWillReceiveProps(nextProps) {
    this.loadImageDimensions(nextProps.src);
  }

  handleClick = e => {
    // This prevents ghost click.
    e.preventDefault();

    const isDefaultPlaceholderImage =
      this.props.src === '/images/8880cd96-6861-4899-8326-7ec181fdfb58.png';

    if (
      this.props.src === null ||
      this.props.src === '' ||
      isDefaultPlaceholderImage
    ) {
      return this.props.onChangeImage();
    }

    return this.setState({
      popover: true,
      anchorEl: e.currentTarget,
    });
  };

  handleRequestClose = () => this.setState({ popover: false });

  handleDeleteImage = () => {
    this.props.onDeleteImage();
    this.handleRequestClose();
  };

  handleChangeImage = () => {
    this.props.onChangeImage();
    this.handleRequestClose();
  };

  isVideo = fileName => {
    const parts = fileName.split('.');
    const ext = parts[parts.length - 1];
    return !!['mov', 'avi', 'mp4', 'mpg', 'm4v', 'webm'].includes(
      ext.toLowerCase(),
    );
  };

  loadImageDimensions = src => {
    if (!src) {
      return;
    }
    this.setState({ isVideo: false });
    if (this.props.isVideoAllowed && this.isVideo(src)) {
      this.setState({ isVideo: true });
    } else {
      const image = new Image();
      image.src = src;
      image.onload = () => this.setState({ image, isVideo: false });
    }
  };

  showImageDimensions = () => {
    const { t } = this.props;
    return (
      this.state.image &&
      this.props.showCropAreaSizes && (
        <p style={{ textAlign: 'center' }}>
          {`${t('Image size')}: ${this.state.image.naturalWidth}px ${t('by')} ${
            this.state.image.naturalHeight
          }px`}
        </p>
      )
    );
  };
  render() {
    const {
      src,
      pending,
      avatar,
      onDeleteImage,
      onChangeImage,
      t,
      ...other
    } = this.props;

    const isDefaultPlaceholderImage =
      this.props.src === '/images/8880cd96-6861-4899-8326-7ec181fdfb58.png';

    const loader = pending && <ProgressOverlay />;
    // const backdrop = avatar && <AvatarBackdrop />;
    const image = src && !this.state.isVideo && !isDefaultPlaceholderImage && (
      <Img srcUrl={src} height={this.props.height} />
    );
    const video = this.props.isVideoAllowed && src && this.state.isVideo && (
      <Video src={src} />
    );
    const cta = !image && !video && !pending && (
      <ImagePreviewCTA avatar={avatar}>
        <Trans>Upload</Trans>{' '}
        {this.props.type === 'image' ? t('an Image') : t('a File')}
      </ImagePreviewCTA>
    );

    const popover = src && (
      <Popover
        open={this.state.popover}
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onRequestClose={this.handleRequestClose}
      >
        <Menu>
          {!this.props.noDelete && (
            <MenuItem
              primaryText={
                this.props.isVideoAllowed ? t('Delete File') : t('Delete Image')
              }
              onClick={this.handleDeleteImage}
            />
          )}
          <MenuItem
            primaryText={
              this.props.isVideoAllowed ? t('Change File') : t('Change Image')
            }
            onClick={this.handleChangeImage}
          />
        </Menu>
      </Popover>
    );

    return (
      <div>
        <ImagePreviewButton
          disabled={pending}
          avatar={avatar}
          {...other}
          onClick={this.handleClick}
        >
          {cta}
          {image}
          {video}
          {loader}
          {popover}
        </ImagePreviewButton>
        {!video && src && this.showImageDimensions()}
      </div>
    );
  }
}

export default withTranslation()(ImagePreview);
