import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Edit from './Edit';

import {
  updateCourse,
  formFieldChange,
  getCourseDetails,
  closeModal,
} from './actions';

const mapStateToProps = state => ({
  ...state.staff.courses.edit,
  filteredChunks: state.staff.courses.list.filteredChunks,
  cities: state.staff.cities,
  fields: state.staff.courses.list.fields,
  role: state.account.profile.role,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCourseDetails: () =>
    dispatch(
      getCourseDetails(
        ownProps.match.params.appname,
        ownProps.match.params.course,
        ownProps.match.params.memberbody,
      ),
    ),
  onSubmit: () =>
    dispatch(
      updateCourse(
        ownProps.match.params.course,
        ownProps.history,
        ownProps.match.params.appname,
        ownProps.match.params.memberbody,
      ),
    ),
  onRequestClose: role =>
    ownProps.match
      ? dispatch(
          closeModal(ownProps.match.params.appname, ownProps.history, role),
        )
      : null,
  onFieldChange: (isRequired, type, field, value, index) =>
    dispatch(formFieldChange(isRequired, type, field, value, index)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Edit),
);
