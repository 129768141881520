import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';

import Woocommerce from './Woocommerce';
import { fieldChange, onFetchWoocommerceCategories } from './actions';

class WoocommerceContainer extends Component {
  componentDidMount() {
    const {
      src,
      csecret,
      ckey,
      activeCategory,
      showProductsOfSpecificCategory,
    } = this.props.component;

    if (src && csecret && ckey) {
      this.props.onFetchWoocommerceCategories(
        src,
        csecret,
        ckey,
        activeCategory,
        showProductsOfSpecificCategory,
      );
    }
  }

  async componentWillReceiveProps(nextProps) {
    const {
      src,
      csecret,
      ckey,
      activeCategory,
      showProductsOfSpecificCategory,
    } = nextProps.component;
    if (
      this.props.component.src !== nextProps.component.src ||
      this.props.component.csecret !== nextProps.component.csecret ||
      this.props.component.ckey !== nextProps.component.ckey
    ) {
      try {
        await this.props.onFetchWoocommerceCategories(
          src,
          csecret,
          ckey,
          activeCategory,
          showProductsOfSpecificCategory,
        );
      } catch (e) {
        console.error(e);
      }
    }
  }

  render() {
    return <Woocommerce {...this.props} />;
  }
}

WoocommerceContainer.propTypes = {
  component: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFieldChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        queryString.parse(ownProps.location.search).component,
        field,
        value,
      ),
    ),
  onFetchWoocommerceCategories: (
    src,
    csecret,
    ckey,
    activeCategory,
    showProductsOfSpecificCategory,
  ) =>
    dispatch(
      onFetchWoocommerceCategories(
        ownProps.match.params.appname,
        queryString.parse(ownProps.location.search).component,
        src,
        activeCategory,
        showProductsOfSpecificCategory,
        csecret,
        ckey,
      ),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WoocommerceContainer);
