import React from 'react';

import './styles.css';

const Builder = () => (
  <svg
    className="icon builder"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23.18 28.61"
  >
    <title>Build</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          className="cls-1"
          points="7.15 0 23.18 0.03 13.64 11.36 21.56 11.53 3.1 28.61 8.07 16.53 0 16.44 7.15 0"
        />
      </g>
    </g>
  </svg>
);

export default Builder;
