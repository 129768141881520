import { connect } from 'react-redux';

import Forum from './Forum';
import { likes, designChange } from './actions';
import { get } from 'lodash';
import { toggleAuth } from '../../../../../../memberLogin/modules/memberLoginMenu/actions';

const mapStateToProps = (state, ownProps) => ({
  appname: ownProps.match.params.appname,
  ...ownProps,
  design: ownProps.component.design,
  memberLoginEnabled: get(state, 'auth.structure.enabled', false),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleAuth: (v, type) =>
    dispatch(toggleAuth(ownProps.match.params.appname, v, type)),

  onChangeLikes: value =>
    dispatch(
      likes(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        !value,
      ),
    ),
  onDesignChange: (field, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        field,
        value,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forum);
