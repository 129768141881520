import {
  OPEN_RATING_DETAIL,
  CLOSE_RATING_DETAIL_DIALOG,
  PREVIOUS_RATING_RESPONSE,
  NEXT_RATING_RESPONSE,
  PREVIOUS_RATING_FEEDBACK,
  NEXT_RATING_FEEDBACK,
} from './actions';
import { FAVOURITE_SELECTED, DELETE_SELECTED_RATING } from '../actions';

const initialState = {
  open: false,
  data: null,
  key: null,
  currentFeedbackKey: null,
};

const ratingDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_RATING_DETAIL:
      return {
        ...state,
        open: true,
        data: action.rating,
        key: action.key,
        currentFeedbackKey: Object.keys(action.rating.feedback)[
          Object.keys(action.rating.feedback).length - 1
        ],
      };

    case CLOSE_RATING_DETAIL_DIALOG:
      return initialState;

    case PREVIOUS_RATING_RESPONSE: {
      const index = Object.keys(action.items).indexOf(action.key);
      const key = Object.keys(action.items)[index];
      if (key) {
        return {
          ...state,
          open: true,
          data: action.ratings[key],
          key,
          currentFeedbackKey: Object.keys(action.ratings[key].feedback)[
            Object.keys(action.ratings[key].feedback).length - 1
          ],
        };
      }
      return {
        ...state,
      };
    }

    case NEXT_RATING_RESPONSE: {
      const index = Object.keys(action.items).indexOf(action.key);
      const key = Object.keys(action.items)[index];

      if (key) {
        return {
          ...state,
          open: true,
          data: action.ratings[key],
          key,
          currentFeedbackKey: Object.keys(action.ratings[key].feedback)[
            Object.keys(action.ratings[key].feedback).length - 1
          ],
        };
      }
      return {
        ...state,
      };
    }

    case PREVIOUS_RATING_FEEDBACK: {
      const feedback = Object.keys(state.data.feedback);
      const index = feedback.indexOf(action.key);
      const newKey = feedback[index - 1];
      if (newKey) {
        return {
          ...state,
          open: true,
          currentFeedbackKey: newKey,
        };
      }
      return {
        ...state,
      };
    }

    case NEXT_RATING_FEEDBACK: {
      const feedback = Object.keys(state.data.feedback);
      const index = feedback.indexOf(action.key);
      const newKey = feedback[index + 1];
      if (newKey) {
        return {
          ...state,
          open: true,
          currentFeedbackKey: newKey,
        };
      }
      return {
        ...state,
      };
    }

    case FAVOURITE_SELECTED: {
      return {
        ...state,
        data: action.data[action.selected],
      };
    }

    case DELETE_SELECTED_RATING: {
      if (action.data.feedback === undefined) {
        return {
          ...state,
          open: false,
        };
      }
      return {
        ...state,
        data: action.data,
        currentFeedbackKey: Object.keys(action.data.feedback)[0],
      };
    }

    default:
      return state;
  }
};

export default ratingDetailsReducer;
