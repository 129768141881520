import React, { useState } from 'react';

export const AppointmentContext = React.createContext({});

export const AppointmentProvider = ({ children }) => {
  const [name, setName] = useState('');
  const [meetingUrl, setMeetingUrl] = useState('');
  const [description, setDescription] = useState('');
  const [cost, setCost] = useState(0);
  const [capacity, setCapacity] = useState(1);
  const [date, setDate] = useState('');
  const [key, setKey] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isBookingPaid, setIsBookingPaid] = useState(false);
  const [appointmentType, setAppointmentType] = useState(0);
  const [appointmentDuration, setAppointmentDuration] = useState(30);
  const [timezone, setTimezone] = useState({
    value: '',
    label: '',
  });
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedStaffMember, setSelectedStaffMember] = useState([]);
  const [location, setLocation] = useState({ value: '', locationData: {} });
  const [eventData, setEventData] = useState();
  const [stripeKeyError, setStripeKeyError] = useState(false);
  const [isRecurringBooking, setIsRecurringBooking] = useState(false);
  const [recurringType, setRecurringType] = useState('days');
  const [recurringValue, setRecurringValue] = useState(2);
  const [appointmentError, setAppointmentError] = useState(null);
  const [slotsError, setSlotsError] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  return (
    <AppointmentContext.Provider
      value={{
        name,
        setName,
        description,
        setDescription,
        cost,
        setCost,
        capacity,
        setCapacity,
        date,
        setDate,
        key,
        setKey,
        isButtonDisabled,
        setIsButtonDisabled,
        isBookingPaid,
        setIsBookingPaid,
        appointmentType,
        setAppointmentType,
        appointmentDuration,
        setAppointmentDuration,
        timezone,
        setTimezone,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        selectedStaffMember,
        setSelectedStaffMember,
        location,
        setLocation,
        eventData,
        setEventData,
        stripeKeyError,
        setStripeKeyError,
        isRecurringBooking,
        setIsRecurringBooking,
        recurringType,
        setRecurringType,
        recurringValue,
        setRecurringValue,
        appointmentError,
        setAppointmentError,
        slotsError,
        setSlotsError,
        imageUrl,
        setImageUrl,
        submitted,
        setSubmitted,
        meetingUrl,
        setMeetingUrl,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};
