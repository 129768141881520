import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import _map from 'lodash/map';
import { Link } from 'react-router-dom';
import Divider from 'material-ui/Divider';
import Popover, { PopoverAnimationVertical } from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import ExpandMore from 'material-ui/svg-icons/navigation/expand-more';
import { withTranslation } from 'react-i18next';

import { whitelabeledHost } from '../../../utils/whitelabeledHost';
import Logo from '../../components/logo';
import Avatar from '../../components/avatar';
import { STAFF_ADMIN } from '../../../config';
import ViewApp from '../../components/buttons/viewApp';

import './styles.css';

const styles = {
  menuStyle: {
    padding: '2px 0',
  },
  menuItem: {
    minHeight: 36,
    fontSize: 13,
  },
  divider: {
    margin: 0,
  },
  menuButton: {
    padding: 0,
    margin: 6,
  },
  underline: {
    borderTop: 'none',
  },
  helpButton: {
    margin: 3,
    height: 48,
    width: 48,
    minWidth: 'none',
    backgroundColor: 'rgba(246,246,246,1)',
  },
  helpButtonLabel: {
    padding: 0,
    fontSize: 14,
  },
  tooltip: {
    top: '12px',
    left: '20px',
    paddingRight: '20px',
  },
};

// test stripe account grant+test@beezer.com Beezerify!23
const isWhiteLabelledHost = whitelabeledHost();

class Toolbar extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    apps: PropTypes.object,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    userMenu: PropTypes.bool.isRequired,
    appMenu: PropTypes.bool.isRequired,
    toggleUserMenu: PropTypes.func.isRequired,
    toggleAppMenu: PropTypes.func.isRequired,
    onAppChange: PropTypes.func.isRequired,
    onAccountSettingsTouchTap: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    type: PropTypes.string,
    onAddIsPublishedListener: PropTypes.func.isRequired,
    isPublished: PropTypes.bool,
    onRemoveIsPublishedListener: PropTypes.func.isRequired,
    onDocumentationTouchTap: PropTypes.func.isRequired,
  };

  static defaultProps = {
    apps: {},
    type: '',
    isPublished: true,
  };

  componentWillMount() {
    this.props.onAddIsPublishedListener();
  }

  componentWillUnmount() {
    if (this.props.appMenu === true) {
      this.props.toggleAppMenu(false);
    }
    this.props.onRemoveIsPublishedListener();
  }

  getCurrentAppTitle = () => {
    const { apps, match } = this.props;
    const current = _find(
      apps,
      (app, domain) => domain === match.params.appname,
    );

    return current && current.title;
  };

  handleUserMenuClick = event => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    this.props.toggleUserMenu();
  };

  handleAppMenuClick = event => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    this.props.toggleAppMenu();
  };

  handleAppClick = appname => {
    this.props.toggleAppMenu(false);
    if (appname) {
      this.props.history.push(`/${appname}/editor`);
      this.props.onAppChange(appname);
    }
  };

  isWixDashboard = () => this.props.type === 'Wix' && this.isInsideIFrame();
  // Platform inside an iframe
  isInsideIFrame = () => window.self !== window.top;

  mailTo = () => {
    // TODO: this should be a _blank link?
    window.location.href = 'mailto:support@beezer.com';
  };

  render() {
    const { match, profile, t } = this.props;

    const currentApp = this.getCurrentAppTitle();
    const appQuantity = this.props.apps && Object.keys(this.props.apps).length;
    const menuItems = _map(this.props.apps, (app, domain) => (
      <MenuItem
        key={domain}
        value={domain}
        primaryText={app.title}
        onClick={() => this.handleAppClick(domain)}
      />
    ));

    // Check to see if the user is a legacy user
    const userIsLegacy = this.props.account.organisation.legacy_flag;

    const appLimit = typeof userIsLegacy === 'number' ? userIsLegacy : 50;
    const appMenu = userIsLegacy ? (
      <button className="app-menu-btn" onClick={this.handleAppMenuClick}>
        <span>{currentApp}</span>
        <ExpandMore color="#616161" />
      </button>
    ) : null;

    const createNewApp =
      userIsLegacy && appQuantity < appLimit
        ? [
            <Divider key="divider" style={styles.divider} />,
            <MenuItem
              key="create-new-app"
              value={null}
              primaryText={t('Create New App')}
              onClick={() => this.props.history.push('/create')}
            />,
          ]
        : null;

    const userSettings = (
      <div
        ref={node => {
          this.userMenuRef = node;
        }}
      >
        <IconButton
          style={styles.menuButton}
          onClick={this.handleUserMenuClick}
        >
          <Avatar />
        </IconButton>
        <Popover
          open={this.props.userMenu}
          anchorEl={this.userMenuRef}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          animation={PopoverAnimationVertical}
          onRequestClose={this.handleUserMenuClick}
        >
          <Menu style={styles.menuStyle} menuItemStyle={styles.menuItem}>
            <MenuItem
              onClick={this.props.onAccountSettingsTouchTap}
              primaryText={t('Account Settings')}
              innerDivStyle={styles.menuItem}
            />
            <Divider style={styles.divider} />
            <a
              href="https://beezer.zendesk.com/hc"
              target="blank"
              rel="noopener noreferrer"
            >
              <MenuItem
                onClick={this.props.onDocumentationTouchTap}
                primaryText={t('Documentation')}
                innerDivStyle={styles.menuItem}
              />
            </a>
            <Divider style={styles.divider} />
            <MenuItem
              onClick={this.mailTo}
              primaryText="support@beezer.com"
              innerDivStyle={styles.menuItem}
            />
            {!this.isWixDashboard() && <Divider style={styles.divider} />}
            {!this.isWixDashboard() && (
              <MenuItem
                onClick={this.props.onLogout}
                primaryText={t('Log Out')}
                innerDivStyle={styles.menuItem}
              />
            )}
          </Menu>
        </Popover>
      </div>
    );

    const appLink = match.params.appname ? <ViewApp {...this.props} /> : null;

    return (
      <section className="toolbar border-bottom">
        <div className="toolbar-group">
          {!this.props.isAppAdmin && !isWhiteLabelledHost && (
            <div className="logo-container border-right">
              <Link
                to={
                  profile.role === STAFF_ADMIN
                    ? `/${this.props.match.params.appname}/staff/member-bodies`
                    : `/${this.props.profile.lastVisited ||
                        this.props.match.params.appname}/editor`
                }
              >
                <Logo width={68} />
              </Link>
            </div>
          )}
          <div
            ref={node => {
              this.appMenuRef = node;
            }}
          >
            {appMenu}
            <Popover
              open={this.props.appMenu}
              anchorEl={this.appMenuRef}
              anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
              animation={PopoverAnimationVertical}
              onRequestClose={this.handleAppMenuClick}
            >
              <Menu style={styles.menuStyle} menuItemStyle={styles.menuItem}>
                {menuItems}

                {createNewApp}
              </Menu>
            </Popover>
          </div>
          {appLink}
        </div>
        <div className="toolbar-group-right">
          {/* <Link to='/help'>
            <FlatButton
              style={styles.helpButton}
              labelStyle={styles.helpButtonLabel}
              label='Help'
            />
          </Link> */}
          {/* <span className='seperator' /> */}
          <span className="seperator" />
          {userSettings}
        </div>
      </section>
    );
  }
}

export default withTranslation()(Toolbar);
