import firebase from '../../../../../../../firebase';

export const AWAITING_EVENT_DATA = 'AWAITING_EVENT_DATA';
export const RECIEVED_EVENT_DATA = 'RECIEVED_EVENT_DATA';
export const EVENT_DATA_ERROR = 'EVENT_DATA_ERROR';
export const FILTERING_EVENT_DATA = 'FILTERING_EVENT_DATA';
export const ADDING_FILTER_ITEM = 'ADDING_FILTER_ITEM';

export const updateData = (appname, lazyKey, key, value) => dispatch =>
  firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${lazyKey}/${key}`)
    .set(value)
    .then(snapshot => {
      // dispatch({ type: RECIEVED_EVENT_DATA, eventList: snapshot.val() });
    })
    .catch(error => {
      // dispatch({ type: EVENT_DATA_ERROR, error });
    });

export const addFilterItem = (appname, dataId, title) => dispatch => {
  dispatch({ type: ADDING_FILTER_ITEM });
  return firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}/filters`)
    .push({
      value: '',
      key: title.toLowerCase(),
      field: title.toLowerCase(),
      type: 'text',
      title: title.replace(/\b\w/g, char => char.toUpperCase()),
    })
    .then(snap => snap.key);
};
