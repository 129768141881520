import React from 'react';
import styled from 'styled-components';

const SectionHeaderTitle = styled.h5`
  margin: 0;
  color: ${props => props.theme.primaryColor};
  font-weight: 700;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  text-align: left;
`;
const SectionHeaderActionsContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const SectionHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 0px;
  padding-right: 18px;
  padding-top: 18px;
  margin-top: ${props => props.borderTop && '24px'};
  margin-bottom: ${props => props.borderBottom && '4px'};
  padding-bottom: ${props => props.borderBottom && '20px'};
  border-top: ${props => props.borderTop && '1px solid rgba(0, 0, 0, 0.2)'};
  border-bottom: ${props =>
    props.borderBottom && '1px solid rgba(0, 0, 0, 0.2)'};
`;

const SectionHeader = props => {
  return (
    <SectionHeaderContainer {...props}>
      <SectionHeaderTitle>{props.title}</SectionHeaderTitle>
      {props.actions && (
        <SectionHeaderActionsContainer>
          <div>{props.actions}</div>
        </SectionHeaderActionsContainer>
      )}
    </SectionHeaderContainer>
  );
};

export default SectionHeader;
