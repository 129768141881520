import React from 'react';
import { Route } from 'react-router-dom';

import ViewCourseAttendeeModal from './modules/modal';

function ViewCourseAttendeeRouter() {
  return (
    <Route path="/:appname/members/courses-attendees/:attendeeId?">
      {props => <ViewCourseAttendeeModal {...props} />}
    </Route>
  );
}

export default ViewCourseAttendeeRouter;
