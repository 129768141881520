import React from 'react';
import PropTypes from 'prop-types';

import { VisitLink } from '../';
import Logo from '../../../../../../app/components/logo';
import AppSumoLogo from '../../../../../../app/components/appSumoLogo';

import './styles.css';
import themeStyles from '../../../../../../_export.scss';
const InnerLayout = ({
  content,
  form,
  formTitle,
  formSubTitle,
  showPartnerLogo,
  referrerLogo,
  referrerStatusText,
  promoImage,
  headerText,
  subHeaderText,
  referrerLogo2,
}) => (
  <div className="inner-layout-container">
    <div className="row height-100">
      <div className="col-sm-5 col-xs-12 banner-section" key="content">
        {headerText && <h1 className="header-text">{headerText}</h1>}
        {subHeaderText && (
          <>
            <h2 className="subheader-text">{subHeaderText}</h2>
            <h2 className="subheader-text">14-Day Free Trial Included</h2>
          </>
        )}

        <header className="logo-container">
          <Logo color={themeStyles.mainWhite} />
          {showPartnerLogo && (
            <>
              <span>with</span>
              <AppSumoLogo />
            </>
          )}

          {promoImage && (
            <img width="250px" src={promoImage} alt="promo-image" />
          )}

          {referrerLogo && (
            <>
              <span id="status-text">{referrerStatusText || 'with'}</span>
              <img width="150px" src={referrerLogo} alt="referrer" />
            </>
          )}
        </header>
        {content}
        {referrerLogo2 && (
          <>
            <img width="450px" src={referrerLogo2} alt="referrer" />
          </>
        )}
        {/* <VisitLink className="visit-link-desktop" /> */}
      </div>
      <div className="col-sm-6 col-xs-12 form-layout" key="form">
        <h4>{formTitle}</h4>
        <span>{formSubTitle}</span>
        <div className="inner-layout-form-container">{form}</div>
        <VisitLink className="visit-link-mobile" />
      </div>
    </div>
  </div>
);

InnerLayout.propTypes = {
  content: PropTypes.element.isRequired,
  form: PropTypes.element.isRequired,
  formTitle: PropTypes.string,
  showPartnerLogo: PropTypes.bool,
  formSubTitle: PropTypes.string,
  promoImage: PropTypes.string,
};

InnerLayout.defaultProps = {
  showPartnerLogo: false,
  formTitle: 'SIGN UP FOR A BEEZER ACCOUNT NOW',
  formSubTitle: '',
  promoImage: '',
};

export default InnerLayout;
