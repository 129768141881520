import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import { Trans, withTranslation } from 'react-i18next';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import Toggle from '../../../../app/components/toggle';
import SectionHeader from '../../../editor/components/sectionHeader';
import TextField from '../../../../app/components/textField';
import SelectField from '../../../../app/components/selectField';

import './style.css';

class NotificationLinkMenu extends Component {
  static propTypes = {
    borderTop: PropTypes.bool,
    isPageAllowed: PropTypes.bool.isRequired,
    link: PropTypes.shape({
      type: PropTypes.oneOf(['web', 'page', 'video']),
      page: PropTypes.string.isRequired,
      web: PropTypes.string.isRequired,
      video: PropTypes.string.isRequired,
      webValid: PropTypes.bool.isRequired,
      videoValid: PropTypes.bool.isRequired,
      enabled: PropTypes.bool.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    pages: PropTypes.array.isRequired,
    subscription: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    link: null,
    borderTop: false,
    isPageAllowed: false,
  };

  isLinkOptionsAllowed = () => {
    return this.props.isPageAllowed ? (
      <RadioButtonGroup
        className="radio-wrapper"
        name="buttonLink"
        defaultSelected={this.props.link.type || 'page'}
        onChange={(elm, value) => this.props.onChange('type', value)}
      >
        <RadioButton id="page" value="page" disabled={this.isLinkDisabled()} />
        <RadioButton id="web" value="web" disabled={this.isLinkDisabled()} />
        <RadioButton
          id="video"
          value="video"
          disabled={this.isLinkDisabled()}
        />
      </RadioButtonGroup>
    ) : (
      <RadioButtonGroup
        className="radio-wrapper"
        name="buttonLink"
        defaultSelected={this.props.link.type || 'web'}
        onChange={(elm, value) => this.props.onChange('type', value)}
      >
        <RadioButton id="web" value="web" disabled={this.isLinkDisabled()} />
        <RadioButton
          id="video"
          value="video"
          disabled={this.isLinkDisabled()}
        />
      </RadioButtonGroup>
    );
  };

  isLinkDisabled = () => !this.props.link.enabled;

  dropdownNavigationOptions = () =>
    this.props.pages.map(
      (page, index) =>
        page.type !== 'Popup' && (
          <MenuItem
            key={page.path}
            value={index ? page.path : '/'}
            primaryText={page.title}
          />
        ),
    );

  shouldShowError = field => {
    const { webValid, web, type, enabled, videoValid, video } = this.props.link;

    if (!enabled) {
      return false;
    }
    switch (field) {
      case 'web':
        return (
          !webValid &&
          web.length > 0 && (
            <small className="error-message">
              <Trans>
                Invalid web page, correct format is https://example.com
              </Trans>{' '}
            </small>
          )
        );

      case 'video':
        return (
          !videoValid &&
          video.length > 0 && (
            <small className="error-message">
              <Trans>
                Invalid video url, correct format is https://example.com
              </Trans>{' '}
            </small>
          )
        );
    }
  };

  isPlanPremium = () => {
    const { subscription } = this.props;
    if (
      subscription &&
      subscription.plan &&
      subscription.plan.toLowerCase() !== 'free'
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { link, borderTop, isPageAllowed, t } = this.props;

    return (
      <div className="notification-link-menu-wrapper">
        <SectionHeader
          title={t('Push Notifications Link')}
          actions={
            <Toggle
              onToggle={(e, isToggled) => {
                this.props.onChange('enabled', isToggled);
              }}
              toggled={this.props.link.enabled}
            />
          }
          tooltip={t('Add a link to the push notification')}
          borderTop={borderTop}
        />
        <div className="notification-link-container">
          {this.isLinkOptionsAllowed()}
          <div
            className={`link-radio-option-wrapper ${isPageAllowed &&
              'hasOptions'}`}
          >
            {isPageAllowed && (
              <SelectField
                className="text-field-wrapper"
                label={t('Select a page')}
                value={link.page}
                options={this.dropdownNavigationOptions()}
                onChange={(index, payload) =>
                  this.props.onChange('page', payload)
                }
                disabled={this.isLinkDisabled() || link.type !== 'page'}
              />
            )}
            <TextField
              id="custom-button-webpage"
              value={link.web}
              onChange={value => this.props.onChange('web', value)}
              placeholder={t('Webpage Link')}
              disabled={this.isLinkDisabled() || link.type !== 'web'}
              hintText={t('eg. https://example.com')}
            />
            {this.shouldShowError('web')}
            <TextField
              id="custom-button-video"
              value={link.video}
              onChange={value => this.props.onChange('video', value)}
              placeholder={t('Video Link')}
              disabled={this.isLinkDisabled() || link.type !== 'video'}
              hintText="eg. https://example.com"
            />
            {this.shouldShowError('video')}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(NotificationLinkMenu);
