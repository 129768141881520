import { connect } from 'react-redux';

import ClickbankThankYou from './ClickbankThankYou';

import { recordUserInfo, failedClickbank } from './actions';

const mapStateToProps = state => ({
  ...state.account.clickbankThankyou,

});

const mapDispatchToProps = dispatch => ({
  recordUserInfo: (userInfo) => dispatch(recordUserInfo(userInfo)),
  failedClickbank: () => dispatch(failedClickbank())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClickbankThankYou);
