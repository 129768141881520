import firebase from '../../../../firebase';
import {
  LOAD_CONTACTS,
  HYDRATE_CONTACTS,
  SELECT_CONTACT,
  SELECT_ALL_CONTACTS,
  DESELECT_ALL_CONTACTS,
  CONTACTS_DELETED,
  SEARCH_CONTACTS,
  SELECT_GROUP,
  SELECT_ALL_GROUPS,
  DESELECT_ALL_GROUPS,
  SELECT_WORKFORCE_GROUP,
  REQUEST_GROUPS,
  LOAD_GROUPS,
  HYDRATE_GROUPS,
  RECEIVE_WORKFORCE_GROUPS,
  RECEIVE_GROUPS,
  LOAD_WORKFORCE_GROUPS,
  REQUEST_USERS,
  RECEIVE_USERS,
  NO_USERS_EXIST,
  SET_PAGE_TYPE,
  RESET_CONTACT_SELECTION,
} from './types';

export const loadContacts = appname => dispatch => {
  dispatch({
    type: LOAD_CONTACTS,
    appname,
  });

  return firebase
    .database()
    .ref(`contacts/${appname}`)
    .once('value', snapshot => {
      const data = snapshot.val();

      dispatch({
        type: HYDRATE_CONTACTS,
        appname,
        data,
      });
    });
};

export const loadGroups = appname => dispatch => {
  dispatch({
    type: LOAD_GROUPS,
    appname,
  });

  return firebase
    .database()
    .ref(`app_groups/${appname}`)
    .once('value', snapshot => {
      const data = snapshot.val();

      dispatch({
        type: HYDRATE_GROUPS,
        appname,
        data,
      });
    });
};

export const getWorkforceGroups = appname => async dispatch => {
  dispatch(loadWorkforceGroups(appname));
  const workforceGroupsUsers = await getWorkforceGroupsUsers(appname);
  const workforceGroupsRef = await firebase
    .database()
    .ref(`workforce_groups/${appname}`)
    .once('value');
  const workforceGroupsData =
    workforceGroupsRef.exists() && workforceGroupsRef.val();
  const workforceGroupsAndUsersData = getWorkforceGroupsAndUsers(
    workforceGroupsUsers,
    workforceGroupsData,
  );
  dispatch({
    type: RECEIVE_WORKFORCE_GROUPS,
    value: workforceGroupsAndUsersData,
  });
};

const getWorkforceGroupsUsers = async appname => {
  const workforceGroupsDataRef = await firebase
    .database()
    .ref(`workforce_groups_data/${appname}`)
    .once('value');
  const workforceGroupsUsers =
    workforceGroupsDataRef.exists() && workforceGroupsDataRef.val();
  return workforceGroupsUsers;
};

const getWorkforceGroupsAndUsers = (users, groups) => {
  Object.keys(groups).forEach(groupID => {
    let groupUsers = {};
    //only get the userID
    if (users[groupID]) {
      //make data structure consistent with regular groups data
      groupUsers = Object.keys(users[groupID]).reduce(
        (allGroupUsers, currentUser) => ({
          ...allGroupUsers,
          [currentUser]: true,
        }),
        {},
      );
    }
    groups[groupID] = {
      ...groups[groupID],
      users: groupUsers,
    };
  });
  return groups;
};
export const requestGroups = appname => ({
  type: REQUEST_GROUPS,
  appname,
});

const loadWorkforceGroups = appname => ({
  type: LOAD_WORKFORCE_GROUPS,
  appname,
});

export const getGroups = appname => dispatch => {
  dispatch(requestGroups(appname));

  firebase
    .database()
    .ref(`app_groups/${appname}/groups`)
    .once('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_GROUPS,
          value: snap.val(),
        });
      }
    });
};

export const selectContact = key => ({
  type: SELECT_CONTACT,
  key,
});

export const selectGroup = key => ({
  type: SELECT_GROUP,
  key,
});

export const selectWorkforceGroup = key => ({
  type: SELECT_WORKFORCE_GROUP,
  key,
});

const requestUsers = appname => ({
  type: REQUEST_USERS,
  appname,
});

export const getUsers = appname => dispatch => {
  dispatch(requestUsers(appname));

  firebase
    .database()
    .ref(`app_users/${appname}`)
    .on('value', snap => {
      if (!snap.exists()) {
        dispatch({
          type: NO_USERS_EXIST,
        });
      } else {
        dispatch({
          type: RECEIVE_USERS,
          value: snap.val(),
        });
      }
    });
};

export const setType = pageType => ({
  type: SET_PAGE_TYPE,
  pageType,
});

export const resetContactSelection = pageType => ({
  type: RESET_CONTACT_SELECTION,
});

export const selectAllContacts = () => ({ type: SELECT_ALL_CONTACTS });

export const deselectAllContacts = () => ({ type: DESELECT_ALL_CONTACTS });

export const selectAllGroups = () => ({ type: SELECT_ALL_GROUPS });

export const deselectAllGroups = () => ({ type: DESELECT_ALL_GROUPS });

export const searchContacts = query => dispatch => {
  dispatch({
    type: SEARCH_CONTACTS,
    query,
  });
};

export const deleteMultipleContacts = (
  appname,
  selected,
  finalFunc,
) => dispatch =>
  firebase
    .database()
    .ref(`contacts/${appname}`)
    .transaction(contacts => {
      if (contacts === null) return null;
      const result = {};
      Object.entries(contacts).forEach(contact => {
        if (selected.indexOf(contact[0]) < 0) {
          result[contact[0]] = contact[1];
        }
      });
      return result;
    })
    .then(() => dispatch(loadContacts(appname)))
    .then(() =>
      dispatch({
        type: CONTACTS_DELETED,
        appname,
        length: selected.length,
      }),
    )
    .then(finalFunc);
