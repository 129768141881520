import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckCircle from 'material-ui/svg-icons/action/check-circle';
import Cancel from 'material-ui/svg-icons/navigation/cancel';

import TextField from '../../../../app/components/textField';
import InputGroupAppend from '../../../../app/components/inputGroupAppend';
import { CircularProgress } from '../../../../app/components/progress';
import { getAppDomain } from '../../../../utils/appDetails';
import themeStyles from '../../../../_export.scss';
import { whitelabeledHost } from '../../../../utils/whitelabeledHost';
class AppDomainInput extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    currentDomain: PropTypes.string,
    domain: PropTypes.string,
    fetchingDomain: PropTypes.bool,
    validDomain: PropTypes.bool,
    availableDomain: PropTypes.bool,
    onDomainChange: PropTypes.func.isRequired,
    isAppAdmin: PropTypes.bool,
  };

  static defaultProps = {
    currentDomain: null,
    domain: null,
    fetchingDomain: false,
    availableDomain: true,
    validDomain: true,
    isAppAdmin: false,
  };

  componentWillMount() {
    if (!this.props.domain || this.props.currentDomain !== this.props.domain) {
      this.props.onDomainChange(this.props.match.params.appname);
    }
  }

  showErrorMsg = () => {
    if (!this.props.validDomain) {
      return (
        <small className="error-message error-in-panel">
          App name must be a string between 3 and 21 characters, no contain
          special characters or spaces
        </small>
      );
    } else if (!this.props.availableDomain && !this.props.fetchingDomain) {
      return (
        <small className="error-message error-in-panel">
          This name is unavailable. Please choose a different one
        </small>
      );
    }
    return null;
  };
  isWhiteLabelledHost = whitelabeledHost();

  render() {
    return (
      <div>
        <InputGroupAppend
          appendText={
            !this.props.isAppAdmin &&
            !this.isWhiteLabelledHost &&
            getAppDomain()
          }
        >
          <TextField
            id="app_domain"
            value={this.props.domain}
            hintText="App name/domain"
            onChange={this.props.onDomainChange}
            rightActionContianerStyle={{
              bottom: 2,
            }}
            rightInputAction={
              this.props.fetchingDomain ? (
                <CircularProgress size={16} thickness={3} />
              ) : this.props.availableDomain ? (
                <CheckCircle
                  color={themeStyles.primaryColor}
                  style={{ height: 18, width: 18 }}
                />
              ) : (
                <Cancel color="#D95350" style={{ height: 18, width: 18 }} />
              )
            }
          />
        </InputGroupAppend>
        {this.showErrorMsg()}
      </div>
    );
  }
}

export default AppDomainInput;
