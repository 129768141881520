export const REQUEST_MARKETPLACE_BUYERS = 'REQUEST_MARKETPLACE_BUYERS';
export const STOP_RECEIVING_MARKETPLACE_BUYERS =
  'STOP_RECEIVING_MARKETPLACE_BUYERS';
export const RECEIVE_MARKETPLACE_BUYERS = 'RECEIVE_MARKETPLACE_BUYERS';
export const NO_MARKETPLACE_BUYERS_EXIST = 'NO_MARKETPLACE_BUYERS_EXIST';
export const FILTER_MARKETPLACE_BUYERS_DATA = 'FILTER_MARKETPLACE_BUYERS_DATA';
export const SELECT_MARKETPLACE_BUYER = 'SELECT_MARKETPLACE_BUYER';
export const CLOSE_MARKETPLACE_BUYERS_DIALOG =
  'CLOSE_MARKETPLACE_BUYERS_DIALOG';
export const SELECT_ALL_MARKETPLACE_BUYERS = 'SELECT_ALL_MARKETPLACE_BUYERS';
export const DESELECT_ALL_MARKETPLACE_BUYERS =
  'DESELECT_ALL_MARKETPLACE_BUYERS';
export const OPEN_MARKETPLACE_BUYERS_DIALOG = 'OPEN_MARKETPLACE_BUYERS_DIALOG';
export const DELETE_SELECTED = 'DELETE_SELECTED';
export const EMAIL_SELECTED = 'EMAIL_SELECTED';
export const ACTION_ERROR = 'ACTION_ERROR';
export const REQUEST_GROUPS = 'REQUEST_GROUPS';
export const RECEIVE_GROUPS = 'RECEIVE_GROUPS';
export const SORT_BUYERS = 'SORT_BUYERS';
