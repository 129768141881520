const offer = (state = {}, action) => {
  switch (action.type) {
    case 'SAVE_USER_SUBSCRIPTION':
      return {
        ...state,
        isUserSubscribed: action.isUserSubscribed,
      };
    case 'SAVING_OFFER_FROM_QUERYSTRING':
      return {
        ...state,
        offer: action.offer,
      };
    case 'RESET_QUERY_STRING_OFFER':
      return {
        ...state,
        offer: null,
      };
    case 'SAVE_OFFER_DETAILS':
      return {
        ...state,
        offerDetails: action.offerDetails,
      };

    case 'LOGOUT_SUCCESS':
      return {};
    default:
      return state;
  }
};

export default offer;
