import firebase from '../../../../../../firebase';

export const updateField = (appname, toggled, field) => dispatch =>
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default`)
    .update({ [`${field}`]: toggled })
    .then(() =>
      dispatch({
        type: 'TOGGLE_FIELD',
        appname,
        field,
        toggled,
      }),
    );

export const toggleDiyPopup = (appname, value) => dispatch => {
  dispatch({
    type: 'TOGGLE_DIY_ADD_POPUP',
    appname,
    value,
  });
};
