import React from 'react';
import { useQuery } from 'react-query';

import OrdersTable from '../../components/OrdersTable/OrdersTable';

import { getAllDrivers, getMetaData } from '../../api';

const Orders = props => {
  const { appname, isAppAdmin } = props;

  const { data: driverData } = useQuery(
    ['allDrivers', { appname }],
    getAllDrivers,
  );

  const { data: metaData } = useQuery(
    ['ecommerceMetaData', { appname }],
    getMetaData,
  );

  return (
    <div>
      <OrdersTable
        isAppAdmin={isAppAdmin}
        driverData={driverData}
        metaData={metaData}
        appname={appname}
      />
    </div>
  );
};

export default Orders;
