import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import AppSidebar from '../appSidebar';
import PublishDialog from '../publishDialog';
import Subscribe, {
  SelectPlan,
} from '../../../modules/account/modules/subscribe';
import PayForAddon from '../../../modules/account/modules/payForAddon';
import Analytics from '../../../modules/analytics';
import EditorPreview from '../../../modules/editor';
import Distribute from '../../../modules/distribute';
import Engage from '../../../modules/engage';
import MemberLogin from '../../../modules/memberLogin';
import MobileOverlay from '../mobileOverlay';
import Offers from '../../../modules/account/modules/offers';
import StaffLogin from '../../../modules/staff';
import { STAFF_ADMIN } from '../../../config';
import PrivateRoute from '../../components/privateRoute';
import Ecommerce from '../../../modules/ecommerce';
import AppSumoWelcome from '../appSumoWelcome';
import MemberLoginFeature from '../../../modules/memberLogin/modules/intro';
import EcommerceFeature from '../../../modules/ecommerce/modules/features';
import FkAcademy from '../../../modules/fkAcademy';
import Pgmol from '../../../modules/pgmol';
import AppSumoPublishNotice from '../appSumoPublishNotice';
import HalloweenOfferNotice from '../halloweenOfferNotice';
import PromoOfferNotice from '../promoOfferNotice';
import { PROMO_DETAILS } from '../../../config/constants';
import AdilloMessage from '../adilloMessage';
import './styles.css';

class AppContext extends Component {
  static propTypes = {
    account: PropTypes.object,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    recordLastVisited: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
  };

  static defaultProps = {
    account: {},
    subscription: {},
    role: '',
    accountId: '',
  };

  componentWillMount() {
    if (this.currentAppExists()) {
      return this.props.recordLastVisited();
    }

    return this.props.history.replace('/not_found');
  }

  componentDidMount() {
    this.displayPromoOfferPopUp();
  }
  displayPromoOfferPopUp = () => {
    if (this.currentAppExists()) {
      const currentDate = Date.now();
      const isPromoValid = () => {
        const promoPlan = PROMO_DETAILS.plan;
        const isPromoInDuration =
          PROMO_DETAILS.startDate <= currentDate &&
          PROMO_DETAILS.endDate >= currentDate;
        const subscribedPlan = this.props.subscription.plan || '';
        const subscribedToPromoPlan = subscribedPlan.includes(promoPlan);
        return (
          isPromoInDuration &&
          !subscribedToPromoPlan &&
          !this.props.isAppsumoUser &&
          !this.props.isAppAdmin
        );
      };
      if (
        !window.sessionStorage.getItem('displayed_promo_offer_popup') &&
        isPromoValid()
      ) {
        window.sessionStorage.setItem('displayed_promo_offer_popup', true);
        this.props.openPromoOfferPopup();
      }
    }
  };

  currentAppExists = () => {
    const { account, match } = this.props;

    if (!account.apps) {
      return false;
    }

    return typeof account.apps[match.params.appname] === 'object';
  };

  render() {
    const { account, is14DayTrialUser, referrer, t } = this.props;

    const inFreeTrial = !account.trial_expired || false;

    return (
      <div className="size-100">
        {/* <Toolbar /> */}

        <AppSidebar />
        <div id="main">
          <Switch>
            {/* Blocked url's for staff admin */}
            {this.props.role === STAFF_ADMIN && (
              <Switch>
                {/* for staff admins */}
                <Route path="/:appname/staff" component={StaffLogin} />

                <Redirect
                  from={`/${this.props.match.params.appname}/choose_plan`}
                  to={`/${this.props.match.params.appname}/staff/member-bodies`}
                />
                <Redirect
                  from={`/${this.props.match.params.appname}/subscribe`}
                  to={`/${this.props.match.params.appname}/staff/member-bodies`}
                />
                <Redirect
                  from={`/${this.props.match.params.appname}/analytics`}
                  to={`/${this.props.match.params.appname}/staff/member-bodies`}
                />
                <Redirect
                  from={`/${this.props.match.params.appname}/distribute`}
                  to={`/${this.props.match.params.appname}/staff/member-bodies`}
                />
                <Redirect
                  from={`/${this.props.match.params.appname}/ecommerce`}
                  to={`/${this.props.match.params.appname}/staff/member-bodies`}
                />
                <Redirect
                  from={`/${this.props.match.params.appname}/editor`}
                  to={`/${this.props.match.params.appname}/staff/member-bodies`}
                />
                <Redirect
                  from={`/${this.props.match.params.appname}/engage`}
                  to={`/${this.props.match.params.appname}/staff/member-bodies`}
                />
                <Redirect
                  from={`/${this.props.match.params.appname}/members`}
                  to={`/${this.props.match.params.appname}/staff/member-bodies`}
                />
                <Redirect
                  from={`/${this.props.match.params.appname}/offer`}
                  to={`/${this.props.match.params.appname}/staff/member-bodies`}
                />
              </Switch>
            )}
            <Route path="/:appname/choose_plan" component={SelectPlan} />
            <Route path="/:appname/subscribe" component={Subscribe} />
            <Route path="/:appname/pay-for-addon" component={PayForAddon} />

            <PrivateRoute
              {...this.props}
              path="/:appname/analytics"
              component={Analytics}
              requiresUpgrade={
                Object.keys(this.props.subscription).length === 0 &&
                !inFreeTrial &&
                is14DayTrialUser
              }
              logout={this.props.onLogout}
              heading={t('Your 14-day free trial has expired!')}
              content={t(
                'Thank you for creating your awesome app with Beezer. We hope you have enjoyed your 14-day free trial. To continue using Beezer, you can sign up for one of our paid plans.',
              )}
            />
            <PrivateRoute
              {...this.props}
              path="/:appname/distribute"
              component={Distribute}
              requiresUpgrade={
                Object.keys(this.props.subscription).length === 0 &&
                !inFreeTrial &&
                is14DayTrialUser
              }
              logout={this.props.onLogout}
              heading={t('Your 14-day free trial has expired!')}
              content={t(
                'Thank you for creating your awesome app with Beezer. We hope you have enjoyed your 14-day free trial. To continue using Beezer, you can sign up for one of our paid plans.',
              )}
            />
            <PrivateRoute
              {...this.props}
              path="/:appname/editor"
              component={EditorPreview}
              requiresUpgrade={
                Object.keys(this.props.subscription).length === 0 &&
                !inFreeTrial &&
                is14DayTrialUser
              }
              logout={this.props.onLogout}
              heading={t('Your 14-day free trial has expired!')}
              content={t(
                'Thank you for creating your awesome app with Beezer. We hope you have enjoyed your 14-day free trial. To continue using Beezer, you can sign up for one of our paid plans.',
              )}
            />
            <PrivateRoute
              {...this.props}
              path="/:appname/engage"
              component={Engage}
              requiresUpgrade={
                Object.keys(this.props.subscription).length === 0 &&
                !inFreeTrial &&
                is14DayTrialUser
              }
              logout={this.props.onLogout}
              heading={t('Your 14-day free trial has expired!')}
              content={t(
                'Thank you for creating your awesome app with Beezer. We hope you have enjoyed your 14-day free trial. To continue using Beezer, you can sign up for one of our paid plans.',
              )}
            />
            <PrivateRoute
              {...this.props}
              path="/:appname/members"
              component={MemberLogin}
              requiresUpgrade={
                Object.keys(this.props.subscription).length === 0 &&
                !inFreeTrial
              }
              plan={'Everything'}
              feature={'Member Login'}
              heading={t('Upgrade your account!')}
              content={t(
                'Thank you for creating your awesome app with Beezer. Please upgrade your account to use our Member Login Feature',
              )}
            >
              <MemberLoginFeature />
            </PrivateRoute>
            <Route path="/:appname/offer" component={Offers} />
            <PrivateRoute
              {...this.props}
              path="/:appname/pgmol"
              component={Pgmol}
              requiresUpgrade={false}
              plan={'Everything'}
              feature={'PGMOL APP'}
              heading={`Upgrade your account!`}
              content={`Thank you for creating your awesome app with Beezer. Please upgrade your account to use our Member Login Feature`}
            />
            <PrivateRoute
              {...this.props}
              path="/:appname/ecommerce"
              component={Ecommerce}
              requiresUpgrade={
                Object.keys(this.props.subscription).length === 0 &&
                !inFreeTrial
              }
              plan={'Everything'}
              feature={'Business Tools'}
              heading={t('Upgrade your account!')}
              content={t(
                'Thank you for creating your awesome app with Beezer. Please upgrade your account to use our Member Login Feature',
              )}
            >
              <EcommerceFeature />
            </PrivateRoute>
            <PrivateRoute
              {...this.props}
              path="/:appname/fkacademy"
              component={FkAcademy}
              requiresUpgrade={false}
              plan={'Everything'}
              feature={'Football App'}
              heading={`Upgrade your account!`}
              content={`Thank you for creating your awesome app with Beezer. Please upgrade your account to use our Member Login Feature`}
            />
          </Switch>
        </div>
        <PublishDialog />
        <MobileOverlay />
        {this.props.isAppsumoUser &&
          !this.props.isAppAdmin &&
          this.props.accountId !== '' && <AppSumoPublishNotice />}
        {this.props.isAppsumoUser &&
          !this.props.isAppAdmin &&
          this.props.accountId !== '' && <AppSumoWelcome />}
        {referrer === 'halloweenOffer' &&
          Object.keys(this.props.subscription).length === 0 &&
          moment(moment().format('YYYY-MM-DD')).isBefore(
            '2021-11-01',
            'day',
          ) && <HalloweenOfferNotice />}
        <PromoOfferNotice />
        <AdilloMessage />
      </div>
    );
  }
}

export default withTranslation()(AppContext);
