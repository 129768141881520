import styled from 'styled-components';

const SectionHeaderTitle = styled.h5`
  margin: 0;
  color: ${props => (props.enabled ? 'var(--primaryColor)' : 'rgba(0,0,0,.4)')};
  font-weight: 600;
  font-size: 24px;
  font-family: 'Raleway';
  text-align: left;
`;

export default SectionHeaderTitle;
