import firebase from '../../../../../firebase';

export const UPDATE_APP_MENU_BAR_DESIGN = 'UPDATE_APP_MENU_BAR_DESIGN';
export const TOGGLED_SHOW_SHARE_MENU = 'TOGGLED_SHOW_SHARE_MENU';
export const UPDATE_TEXT_ALIGN = 'UPDATE_TEXT_ALIGN';

const menuBarUpdated = appname => ({
  type: UPDATE_APP_MENU_BAR_DESIGN,
  appname,
});

const toggledShowShareMenu = (appname, toggled) => ({
  type: TOGGLED_SHOW_SHARE_MENU,
  appname,
  toggled,
});

const textAlignUpdated = (appname, align) => ({
  type: UPDATE_TEXT_ALIGN,
  appname,
  align,
});

export const updateAppMenuBarDesign = (
  appname,
  attr,
  color,
  barDef,
) => dispatch => {
  const designMenuBarRef = firebase
    .database()
    .ref(`apps/${appname}/draft/design_data/components/Navigation/${barDef}`);

  designMenuBarRef.update({
    [attr]: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
  });

  dispatch(menuBarUpdated(appname));
};

export const updateAppMenuLayout = (appname, layout) => dispatch =>
  firebase
    .database()
    .ref(`apps/${appname}/draft/design_data/components/Navigation`)
    .update({
      layout,
    })
    .then(() => dispatch(menuBarUpdated(appname, layout)));

export const toggleShowShareMenu = (appname, toggled) => dispatch =>
  firebase
    .database()
    .ref(`apps/${appname}/draft/design_data/components/Navigation`)
    .update({
      showShareButton: toggled,
    })
    .then(() => dispatch(toggledShowShareMenu(appname, toggled)));

export const updateTextAlign = (appname, align) => dispatch =>
  firebase
    .database()
    .ref(`apps/${appname}/draft/design_data/components/Navigation`)
    .update({
      textAlign: align,
    })
    .then(() => dispatch(textAlignUpdated(appname, align)));
