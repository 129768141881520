import { combineReducers } from 'redux';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import _pickBy from 'lodash/pickBy';

import {
  REQUEST_TRANSACTION_HISTORY,
  RECEIVE_TRANSACTION_HISTORY,
  FILTER_TRANSACTION_DATA,
  ERROR_RECEIVING_TRANSACTIONS,
  SELECT_TRANSACTION,
  SELECT_ALL_TRANSACTIONS,
  DESELECT_ALL_TRANSACTIONS,
  FINISH_REQUEST_TRANSACTION_HISTORY,
} from './actions';

const initialState = {
  pending: false,
  chunks: {},
  selected: [],
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
};

const filterChunks = (chunks, filterHolder = '') => {
  let filteredChunks = chunks;
  const filter = filterHolder.replace(/[^a-zA-Z0-9 ]/g, '');

  if (filter !== '') {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`(${filter})`, 'gi');
      const customer =
        chunk.customer && chunk.customer.email ? chunk.customer.email : null;
      return rgx.test(customer);
    });
  }
  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_TRANSACTION_HISTORY:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case FINISH_REQUEST_TRANSACTION_HISTORY:
      return {
        ...state,
        pending: false,
        error: null,
      };

    case RECEIVE_TRANSACTION_HISTORY:
      return {
        ...state,
        chunks: action.data,
        selected: [],
        filteredChunks: action.data,
        items: _keys(action.data),
        pending: false,
        error: null,
      };

    case FILTER_TRANSACTION_DATA:
      return {
        ...state,
        ...filterChunks(state.chunks, action.payload),
        filter: action.payload,
      };

    case ERROR_RECEIVING_TRANSACTIONS:
      return {
        ...initialState,
        error: true,
        pending: false,
      };

    case SELECT_TRANSACTION: {
      const prevState = state.filteredChunks[action.key].selected;

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case SELECT_ALL_TRANSACTIONS: {
      const filteredChunks = _pick(state.filteredChunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: true,
      }));
      const selected = _union(state.selected, _keys(filteredChunks));

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_TRANSACTIONS:
      return {
        ...state,
        filteredChunks: _mapValues(state.filteredChunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };
    default:
      return state;
  }
};

export default subscriptions;
