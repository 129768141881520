const initialState = {
  name: {
    value: '',
    required: true,
  },
  workfield: {
    value: null,
    required: true,
  },
  email: {
    value: '',
    required: true,
  },
  password: {
    value: '',
    required: true,
    minLength: 6,
  },
  agreements: {
    value: false,
    required: true,
  },
  pending: false,
  error: null,
  offers: {},
  isFetchingOffers: true,
  validatedUserKey: null,
  appsumoUser: null,
  appsumoUserError: null,
  appsumoUserId: null,
  referrer: null,
};

const register = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_OFFERS':
      return {
        ...state,
        isFetchingOffers: true,
      };

    case 'RECEIVE_OFFERS':
      return {
        ...state,
        offers: action.offers,
        isFetchingOffers: false,
      };

    case 'RECEIVE_OFFERS_ERROR':
      return {
        ...state,
        error: action.error,
        isFetchingOffers: false,
      };

    case 'REGISTRATION_FIELD_CHANGE':
      return {
        ...state,
        [action.prop]: {
          ...state[action.prop],
          value: action.value,
          isValid: undefined,
        },
      };

    case 'VALIDATING_NEW_USER':
    case 'SUBMIT_REGISTRATION':
      return {
        ...state,
        error: null,
        pending: true,
      };

    case 'NEW_USER_VALIDATION_PASSED':
      return {
        ...state,
        error: null,
        pending: false,
        validatedUserKey: action.key,
      };

    case 'REGISTRATION_VALIDATION_ERROR':
      return {
        ...state,
        [action.prop]: {
          ...state[action.prop],
          isValid: false,
        },
        pending: false,
      };

    case 'REGISTRATION_ERROR':
      return {
        ...state,
        error: action.err,
        pending: false,
      };

    case 'RESET_REGISTRATION_FORM':
    case 'REGISTRATION_COMPLETE':
      return {
        ...initialState,
        appsumoUser: state.appsumoUser,
        appsumoUserError:state.appsumoUserError,
        appsumoUserId: state.appsumoUserId,
        offers: state.offers,
        validatedUserKey: state.validatedUserKey,
      };

    case 'APPSUMO_USER_DETAIL':
      return {
        ...state,
        appsumoUser: action.data,
        appsumoUserId: action.id,
        appsumoUserError: null,
        ['email']: {
          ...state['email'],
          value: action.data && action.data.activation_email,
          isValid: true,
        },
      };

    case 'APPSUMO_USER_ERROR':
      return {
        ...state,
        appsumoUserError: action.err,
        ['email']: {
          ...state['email'],
          value: action.data && action.data.activation_email,
          isValid: true,
        },
      };

      case 'REFERRER_DETAILS':
        return {
          ...state,
          referrer: action.payload,
        };

    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        offers: state.offers,
      };

    default:
      return state;
  }
};

export default register;
