import firebase from '../../../../../../firebase';

import {
  REQUEST_FILTERS,
  RECEIVE_FILTERS,
  SELECT_FILTER,
  FILTER_DATA,
  SELECT_ALL_FILTERS,
  DESELECT_ALL_FILTERS,
} from './types';

const requestFilters = appname => ({
  type: REQUEST_FILTERS,
  appname,
});

export const selectFilter = key => ({
  type: SELECT_FILTER,
  key,
});

export const getFilters = appname => dispatch => {
  dispatch(requestFilters(appname));

  firebase
    .database()
    .ref(`marketplace_filters/${appname}`)
    .on('value', snap => {
      dispatch({
        type: RECEIVE_FILTERS,
        value: snap.exists() ? snap.val() : [],
      });
    });
};

export const filterData = filter => dispatch =>
  dispatch({
    type: FILTER_DATA,
    payload: filter,
  });

export const selectAllFilters = () => ({ type: SELECT_ALL_FILTERS });
export const deselectAllFilters = () => ({ type: DESELECT_ALL_FILTERS });

export const searchFilters = query => dispatch =>
  dispatch({
    type: 'SEARCH_FILTERS',
    query,
  });

export const deleteFilter = (appname, key) => dispatch => {
  if (key !== 'default') {
    firebase
      .database()
      .ref(`marketplace_filters/${appname}/${key}`)
      .remove()
      .then(() =>
        dispatch({
          type: 'FILTER_DELETED',
          appname,
          key,
        }),
      );
  }
};

export const deleteFilters = (appname, selected) => dispatch => {
  console.log([selected]);
  Promise.all(selected.map(key => dispatch(deleteFilter(appname, key))));
};
