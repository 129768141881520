import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  Select,
  MenuItem,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import TooltipElement from 'app/components/tooltipElement';
import ImageUpload from '../../../editor/internals/imageUpload';
import firebase from '../../../../firebase';
import DeleteButton from './components/DeleteButton';
import { Trans, useTranslation } from 'react-i18next';

import {
  addItemToInventory,
  getAllItems,
  updateItemFromInventory,
} from '../../api';
import styles from './styles.module.scss';
import themeStyles from '../../../../_export.scss';
const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    color: themeStyles.primaryTextColor,
    backgroundColor: themeStyles.primaryColor,
  },
  title: {
    marginLeft: 20,
    flex: 1,
  },
}));

const AddInventoryItem = props => {
  const {
    addInventoryDialogOpen,
    onAddInventoryDialogOpen,
    appname,
    isImageUploaded,
    onInventoryItemChange,
    inventoryItem,
  } = props;
  const { t } = useTranslation();

  const isUpdate = Object.keys(inventoryItem).length > 0;
  const classes = useStyles();

  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(
    isUpdate ? updateItemFromInventory : addItemToInventory,
  );

  const { data } = useQuery(['inventoryItems', { appname }], getAllItems);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [prevPrice, setPrevPrice] = useState('');
  const [price, setPrice] = useState('');
  const [id, setId] = useState('');
  const [stock, setStock] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [category, setCategory] = useState('default');
  const [prevCategory, setPrevCategory] = useState('');
  const [status, setStatus] = useState('draft');
  const [key, setKey] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [idError, setIdError] = useState('');
  useEffect(() => {
    if (isUpdate) {
      inventoryItem.name && setName(inventoryItem.name);
      inventoryItem.description && setDescription(inventoryItem.description);
      inventoryItem.prevPrice && setPrevPrice(inventoryItem.prevPrice);
      inventoryItem.price && setPrice(inventoryItem.price);
      inventoryItem.id && setId(inventoryItem.id);
      inventoryItem.stock && setStock(inventoryItem.stock);
      inventoryItem.imageUrl && setImageUrl(inventoryItem.imageUrl);
      inventoryItem.category && setCategory(inventoryItem.category);
      inventoryItem.category && setPrevCategory(inventoryItem.category);
      inventoryItem.status && setStatus(inventoryItem.status);
      inventoryItem.key && setKey(inventoryItem.key);
    } else {
      setName('');
      setDescription('');
      setPrevPrice('');
      setPrice('');
      setId('');
      setStock('');
      setImageUrl('');
      setCategory('default');
      setPrevCategory('');
      setStatus('draft');
      setKey('');
    }
  }, [inventoryItem, isUpdate]);

  useEffect(() => {
    const getImageUrl = async () => {
      const imageRef = firebase
        .database()
        .ref(`/food_order/${appname}/temp_images`);
      const snapshot = await imageRef.once('value');
      const value = snapshot.val();
      setImageUrl(value);
    };
    getImageUrl();
  }, [isImageUploaded]);

  const handleClose = () => {
    onAddInventoryDialogOpen(false);
    onInventoryItemChange({});
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsButtonDisabled(true);
    await mutateAsync({
      appname,
      name,
      description,
      prevPrice,
      price,
      id,
      stock,
      imageUrl,
      category,
      prevCategory,
      status,
      key,
    });
    queryClient.invalidateQueries('inventoryItems');
    onAddInventoryDialogOpen(false);
    onInventoryItemChange({});
    setIsButtonDisabled(false);
  };

  const updateId = e => {
    setIdError(false);
    setId(e.target.value);
    const idExists =
      data.finalItems &&
      Object.keys(data.finalItems).findIndex(
        itemKey =>
          data.finalItems[itemKey].id.toLowerCase() ===
            e.target.value.toLowerCase() && inventoryItem.key !== itemKey,
      ) > -1;
    idExists && setIdError(true);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={addInventoryDialogOpen}
        onClose={handleClose}
        disableEnforceFocus={true}
      >
        <AppBar className={classes.appBar}>
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2>
              <Trans>Add Item</Trans>
            </h2>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <form className={styles.form} onSubmit={e => handleSubmit(e)}>
          <div className={styles.main_container}>
            <div className={styles.title_container}>
              <h2>
                <Trans>Item Details</Trans>
              </h2>
              <div>
                <label htmlFor="name">
                  <Trans>Name</Trans>
                </label>
                <input
                  id="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder={t('Type here...')}
                  required
                />
              </div>

              <div>
                <label htmlFor="description">
                  <Trans>Description</Trans>
                </label>
                <input
                  id="description"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  placeholder={t('Type here...')}
                />
              </div>
            </div>

            <div className={styles.container}>
              <h2>
                <Trans>Media</Trans>
              </h2>
              <ImageUpload
                title={`${t('Box')} > ${t('Crop Image')}`}
                src={imageUrl}
                firebaseUrl={`/food_order/${appname}/temp_images`}
                showCropAreaSizes
                height="220px"
                width="320px"
              />
            </div>
            <div className={styles.title_container}>
              <h2>
                <Trans>Inventory</Trans>
              </h2>
              <div>
                <label htmlFor="id">
                  <Trans>ID</Trans>
                </label>
                <input
                  id="id"
                  value={id}
                  onChange={updateId}
                  placeholder={t('Type here...')}
                  required
                  className={idError && styles.errorInput}
                />
                {idError && (
                  <p className={styles.error}>
                    <span>
                      <Trans>ID already exists.</Trans>{' '}
                    </span>
                  </p>
                )}
              </div>
              <div>
                <label htmlFor="stock">
                  <Trans>Stock</Trans>
                </label>
                <input
                  id="stock"
                  value={stock}
                  type="number"
                  min="0"
                  onChange={e => setStock(e.target.value)}
                  placeholder={t('Type here...')}
                  required
                />
              </div>
            </div>

            <div className={styles.title_container}>
              <h2>
                <Trans>Pricing</Trans>
              </h2>
              <div>
                <label htmlFor="prevPrice">
                  <Trans>Previous Price</Trans>
                  <TooltipElement
                    tooltip={t(
                      "To show a reduced price, move the product's original price into Previous Price. Enter a lower value into Price.",
                    )}
                    iconStyle={{ marginLeft: 24, color: '#261ed5' }}
                    horizontalPosition="right"
                  />
                </label>
                <input
                  id="prevPrice"
                  value={prevPrice}
                  type="number"
                  min="0"
                  step="0.01"
                  onChange={e => setPrevPrice(e.target.value)}
                  placeholder={t('Type here...')}
                />
              </div>

              <div>
                <label htmlFor="price">
                  <Trans>Price</Trans>
                </label>
                <input
                  id="price"
                  value={price}
                  type="number"
                  min="0"
                  step="0.01"
                  onChange={e => setPrice(e.target.value)}
                  placeholder={t('Type here...')}
                  required
                />
              </div>
            </div>
          </div>

          <div className={styles.secondary_container}>
            <div>
              <div>
                <Trans>CATEGORY</Trans>
              </div>
              <Select
                value={category}
                onChange={e => setCategory(e.target.value)}
              >
                <MenuItem value="default">
                  <Trans>Default</Trans>
                </MenuItem>
                <MenuItem value="specials">
                  <Trans>Specials</Trans>
                </MenuItem>
              </Select>
            </div>

            <div>
              <div>
                <Trans>STATUS</Trans>
              </div>
              <Select value={status} onChange={e => setStatus(e.target.value)}>
                <MenuItem value="draft">
                  <Trans>Draft</Trans>
                </MenuItem>
                <MenuItem value="active">
                  <Trans>Active</Trans>
                </MenuItem>
              </Select>
              {status === 'draft' ? (
                <div>
                  <Trans>This product will be hidden.</Trans>
                </div>
              ) : (
                <div>
                  <Trans>This product will be visible.</Trans>
                </div>
              )}
            </div>
            <button
              className={styles.button}
              type="submit"
              disabled={isButtonDisabled || idError}
            >
              {isLoading
                ? t('SAVING...')
                : isUpdate
                ? t('UPDATE ITEM')
                : t('ADD ITEM')}
            </button>
            {isUpdate && (
              <DeleteButton
                isButtonDisabled={isButtonDisabled}
                setIsButtonDisabled={setIsButtonDisabled}
                onAddInventoryDialogOpen={onAddInventoryDialogOpen}
                onInventoryItemChange={onInventoryItemChange}
                appname={appname}
                category={category}
                itemKey={key}
              />
            )}
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default AddInventoryItem;
