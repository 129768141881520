import { connect } from 'react-redux';

import Login from './Login';
import { loginUser, saveOfferFromQueryString } from './actions';
import { loadOffers } from '../../register/offer/actions';

// class LoginWrapper extends Component {
//   static propTypes = {
//     isFetching: PropTypes.bool.isRequired,
//     handleSubmit: PropTypes.func.isRequired,
//     error: PropTypes.string,
//   }
//
//   state = {
//     email: '',
//     password: '',
//   }
//
//   render() {
//     return (
//       <Login
//         email={this.state.email}
//         onEmailChange={(val) => this.setState({ email: val })}
//         password={this.state.password}
//         onPasswordChange={(val) => this.setState({ password: val })}
//         onSubmit={() => this.props.handleSubmit(this.state)}
//         {...this.props}
//       />
//     );
//   }
// }

const mapStateToProps = state => ({
  ...state.account.auth,
  offers: state.account.register.offers,
  offerQuery: state.account.offer.offer || '',
});

const mapDispatchToProps = dispatch => ({
  onSubmit: (credentials, offer) => dispatch(loginUser(credentials, offer)),
  saveOfferFromQueryString: offer => dispatch(saveOfferFromQueryString(offer)),
  loadOffers: () => dispatch(loadOffers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
