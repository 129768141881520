import React from 'react';
import PropTypes from 'prop-types';

import TooltipElement from '../../../../app/components/tooltipElement';
import SectionHeaderContainer from './SectionHeaderContainer';
import SectionHeaderTitle from './SectionHeaderTitle';
import SectionHeaderActionsContainer from './SectionHeaderActionsContainer';

const SectionHeader = ({
  title,
  tooltip,
  actions,
  tooltipType,
  enabled,
  tooltipHorizontal,
  other,
}) => {
  return (
    <SectionHeaderContainer {...other}>
      <SectionHeaderTitle enabled={enabled}>{title}</SectionHeaderTitle>
      <SectionHeaderActionsContainer>
        <div>{actions}</div>
        {tooltip ? (
          <TooltipElement
            tooltip={tooltip}
            iconStyle={{ marginLeft: 24, color: '#261ed5' }}
            tooltipType={tooltipType}
          />
        ) : null}
      </SectionHeaderActionsContainer>
    </SectionHeaderContainer>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.node,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  borderTop: PropTypes.bool,
  tooltipType: PropTypes.string,
  other: PropTypes.object,
  enabled: PropTypes.bool,
  tooltipHorizontal: PropTypes.string,
};

SectionHeader.defaultProps = {
  actions: null,
  tooltip: null,
  borderTop: false,
  tooltipType: 'info',
  other: null,
  enabled: true,
  tooltipHorizontal: 'left',
};

export default SectionHeader;
