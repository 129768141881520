export const getAllItems = async ({ queryKey }) => {
  /* eslint-disable no-unused-vars */
  const [_key, { appname }] = queryKey;
  const response = await fetch(
    `${process.env.REACT_APP_FOOD_API}/get_inventory_items/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const addItemToInventory = async ({ ...data }) => {
  const response = await fetch(
    `${process.env.REACT_APP_FOOD_API}/add_to_inventory`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const updateItemFromInventory = async ({ ...data }) => {
  const response = await fetch(
    `${process.env.REACT_APP_FOOD_API}/update_from_inventory`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const deleteItemFromInventory = async ({ appname, category, key }) => {
  const response = await fetch(
    `${process.env.REACT_APP_FOOD_API}/delete_from_inventory/${appname}/${category}/${key}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const setMetaData = async ({ ...data }) => {
  const response = await fetch(
    `${process.env.REACT_APP_FOOD_API}/set_meta_data`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const markOrderAsDelivered = async ({ ...data }) => {
  const response = await fetch(
    `${process.env.REACT_APP_FOOD_API}/mark_order_as_delivered`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const getMetaData = async ({ queryKey }) => {
  const [_key, { appname }] = queryKey;

  const response = await fetch(
    `${process.env.REACT_APP_FOOD_API}/get_meta_data/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const getStripeDetails = async ({ queryKey }) => {
  const [_key, { appname }] = queryKey;

  const response = await fetch(
    `${process.env.REACT_APP_FOOD_API}/get_stipe_details/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const getAllContacts = async ({ queryKey }) => {
  /* eslint-disable no-unused-vars */
  const [_key, { appname }] = queryKey;
  const response = await fetch(
    `${process.env.REACT_APP_FOOD_API}/get_contacts/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const getAllOrders = async ({ queryKey }) => {
  /* eslint-disable no-unused-vars */
  const [_key, { appname }] = queryKey;
  const response = await fetch(
    `${process.env.REACT_APP_FOOD_API}/get_orders/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const getAllDrivers = async ({ queryKey }) => {
  /* eslint-disable no-unused-vars */
  const [_key, { appname }] = queryKey;
  const response = await fetch(
    `${process.env.REACT_APP_FOOD_API}/get_drivers/${appname}`,
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};

export const assignDriverToOrder = async ({ ...data }) => {
  console.log(data, '2121');
  const response = await fetch(
    `${process.env.REACT_APP_ORDER_API}/assign_driver_to_order`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
};
