import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  ${props =>
    typeof props.onClick === 'function' ? 'cursor: pointer;' : ''} > svg {
    margin: 2px;
    ${props =>
      typeof props.onStarClick === 'function' ? 'cursor: pointer;' : ''};
  }
`;

export default Container;
