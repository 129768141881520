import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  toggleBlogModal,
  formFieldChange,
  multipleValueFieldChange,
  addBlog,
  getBlogs,
  selectAllBlogs,
  selectBlog,
  deleteBlogs,
  deselectAllBlogs,
  filterBlogData,
  updateBlog,
  toggleBlogCategoriesModal,
  addBlogCategory,
  getBlogCategories,
  getBlogSaveBlogConfig,
  removeBlogCategory,
  toggleSaveBlog,
} from './actions';
import Blog from './Blog';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  blogData: state.engage.blog,
});

const mapDisptachToProps = (dispatch, ownProps) => ({
  toggleBlogModal: value => dispatch(toggleBlogModal(value)),
  onFieldChange: (field, value) => dispatch(formFieldChange(field, value)),
  onMultipleValueFieldChange: (field, value) =>
    dispatch(multipleValueFieldChange(field, value)),
  onSubmit: () => dispatch(addBlog(ownProps.match.params.appname)),
  getBlogs: () => dispatch(getBlogs(ownProps.match.params.appname)),

  onSelect: key => dispatch(selectBlog(key)),
  onSelectAllBlogs: () => dispatch(selectAllBlogs()),
  onDeselectAllBlogs: () => dispatch(deselectAllBlogs()),
  onDeleteSelected: rowId =>
    dispatch(deleteBlogs(ownProps.match.params.appname, rowId)),
  onFilterBlogData: filter => dispatch(filterBlogData(filter)),
  onUpdateBlog: (rowId, data) => dispatch(updateBlog(rowId, data)),

  toggleBlogCategoriesModal: value =>
    dispatch(toggleBlogCategoriesModal(value)),
  getBlogCategories: () =>
    dispatch(getBlogCategories(ownProps.match.params.appname)),
  addBlogCategory: category =>
    dispatch(addBlogCategory(ownProps.match.params.appname, category)),
  onToggleSaveBlog: value =>
    dispatch(toggleSaveBlog(ownProps.match.params.appname, value)),
  getBlogSaveBlogConfig: () =>
    dispatch(getBlogSaveBlogConfig(ownProps.match.params.appname)),
  onRemoveBlogCategory: categoryKey =>
    dispatch(removeBlogCategory(ownProps.match.params.appname, categoryKey)),
});

export default withRouter(connect(mapStateToProps, mapDisptachToProps)(Blog));
