import i18n from 'i18next';

//format transactionsData
//return [{col1:val1, col2:val2}]
//where col1 is column name and val1 is col1 value
export const formatTransations = data =>
  data.map(item => ({
    Amount: (item.amount / 100).toFixed(2),
    Currency: item.currency,
    Status: item.paid ? 'Succeeded' : 'Failed',
    Description: item.description,
    Customer: item.customer
      ? item.customer.email
      : item.billing_details
      ? item.billing_details.name
      : '',
    Invoice: item.invoice ? item.invoice.invoice_pdf : i18n.t('No Invoice'),
    Date: item.created ? new Date(item.created * 1000).toUTCString() : null,
  }));
