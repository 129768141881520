const initialState = {
  appColor: '#261ed5',
  appLogo: null,
};

const appPreference = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_APP_PREFERENCE':
      return {
        ...state,
        ...action.data,
      };

    case 'FETCH_APP_PREFERENCE':
    default:
      return state;
  }
};

export default appPreference;
