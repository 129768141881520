import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog';
import { FormattedNumber } from 'react-intl';
import PropTypes from 'prop-types';
import { CircularProgress } from '../progress';

import { FlatButton } from '../buttons';

const WarningDialog = ({ open = true, onRequestClose }) => {
  const isProcessing = false;

  const publishActions = [
    <FlatButton
      label="Close"
      onClick={() => {
        onRequestClose();
      }}
      primary
    />,
  ];

  return (
    <Dialog
      open={open}
      onRequestClose={onRequestClose}
      autoScrollBodyContent
      style={{ textAlign: 'center' }}
      actionsContainerStyle={{ textAlign: 'center' }}
      contentClassName="customModal"
      paperClassName="customModalPaper"
      title="Notice"
      actions={publishActions}
    >
      {isProcessing ? (
        <CircularProgress color="secondary" />
      ) : (
        <div>
          <div>
            <p style={{ fontSize: '17px', marginTop: '8px' }}>
              Please note that once you enable Member login, your app users will
              have the option to Sign up to the app. If you wish to restrict
              page access, go to Groups & Subscriptions and select pages to be
              included in the Default Group. You can further add more Groups or
              paid Subscriptions with different page access.
            </p>
          </div>
        </div>
      )}
    </Dialog>
  );
};

WarningDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default WarningDialog;
