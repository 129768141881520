/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Toggle from 'material-ui/Toggle';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Trans, withTranslation } from 'react-i18next';
import { objectToArray } from '../../../../../../../utils';

import { FlatButton } from '../../../../../../../app/components/buttons';
import FormFields from '../../../../../components/form';

import './styles.css';

class Edit extends React.Component {
  componentDidMount() {
    if (this.props.match && this.props.match.params.course) {
      this.props.getCourseDetails();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match &&
      JSON.stringify(this.props.match) !== JSON.stringify(prevProps.match)
    ) {
      this.props.getCourseDetails();
    }
  }

  render() {
    const {
      pending,
      error,
      onSubmit,
      onRequestClose,
      onFieldChange,
      match,
      fields,
      fieldsData,
      role,
      t,
    } = this.props;

    const { isRecurringCourse } = fieldsData;

    return (
      <Dialog
        open={!!(match && match.params.course)}
        onClose={onRequestClose}
        autoScrollBodyContent
        scroll="paper"
        maxWidth="lg"
      >
        <div className="user-courses-modal-content">
          <div className="modal-heading">
            <DialogTitle>
              <Trans>Edit Course</Trans>
            </DialogTitle>
          </div>
          <div>
            <FormFields
              fields={fields}
              onFieldChange={onFieldChange}
              fieldsData={fieldsData}
              isRecurringEvent={isRecurringCourse}
            />
            <div style={{ padding: '10px 7px' }}>
              <div className="field-wrapper">
                <Toggle
                  onToggle={(v, b) => {}}
                  toggled={isRecurringCourse}
                  label={t('Recurring course')}
                  disabled={true}
                />
              </div>
            </div>
            <div className="submit-button-container">
              <FlatButton
                label={pending ? t('Saving...') : t('Save')}
                onClick={onSubmit}
                primary
                disabled={
                  pending ||
                  !fieldsData ||
                  !!objectToArray(fieldsData).find(
                    i =>
                      !i.value.isValid &&
                      i.id !== 'createdAt' &&
                      i.id !== 'memberbody' &&
                      i.id !== 'updatedAt' &&
                      i.id !== 'isRecurringCourse' &&
                      i.id !== 'recurringType' &&
                      i.id !== 'recurringValue',
                  )
                }
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

Edit.propTypes = {
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  match: PropTypes.object,
  onRequestClose: PropTypes.func.isRequired,
  getCourseDetails: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  fieldsData: PropTypes.object.isRequired,
  role: PropTypes.string,
};

Edit.defaultProps = {
  error: null,
  fields: {},
  fieldsData: {},
  match: {},
  role: 'admin',
};

export default withTranslation()(Edit);
