import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PayForAddon from './PayForAddon';
import { subscribe, reloadUserAccount } from './actions';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    ...state.account.subscribe,
    intl: state.account.intl,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubscribe: ({
    component,
    period,
    callback,
    price,
    componentName,
    currency,
    stripe,
    productId,
  }) =>
    dispatch(
      subscribe({
        appname: ownProps.match.params.appname,
        component,
        period,
        callback,
        price,
        componentName,
        currency,
        stripe,
        productId,
      }),
    ),
  reloadUserAccount: callback => dispatch(reloadUserAccount(callback)),
  displayPaymentError: msg => dispatch(openSnackbar(msg)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PayForAddon),
);
