import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import Dialog from 'material-ui/Dialog';
import { FlatButton } from '../../../app/components/buttons';

const actions = (action, onClose) => [
  <FlatButton
    style={{ margin: 20 }}
    label={<Trans>Cancel</Trans>}
    secondaryWhite
    onClick={() => onClose()}
  />,
  <FlatButton
    style={{ margin: 20 }}
    label={<Trans>Confirm</Trans>}
    primary
    onClick={() => {
      action();
      onClose();
    }}
  />,
];

const DialogConfirmation = ({ open, onClose, message, action, title }) => (
  <Dialog
    actions={
      action !== null ? actions(action, onClose) : [actions(action, onClose)[0]]
    }
    {...(title && { title })}
    open={open}
    onRequestClose={() => onClose()}
    autoScrollBodyContent
    style={{ textAlign: 'center' }}
    actionsContainerStyle={{ textAlign: 'center' }}
    contentClassName="customModal"
    paperClassName="customModalPaper"
  >
    <span
      style={{ fontSize: '14px' }}
      dangerouslySetInnerHTML={{
        __html: message,
      }}
    />
  </Dialog>
);

DialogConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  action: PropTypes.func,
  title: PropTypes.string,
};

DialogConfirmation.defaultProps = {
  action: null,
  title: null,
};

export default DialogConfirmation;
