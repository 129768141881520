import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { makeStyles } from '@material-ui/styles';
import { Trans, useTranslation } from 'react-i18next';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Checkbox,
  CircularProgress,
  Paper,
} from '@material-ui/core';

import ActionMenu from 'app/components/actionMenu';
import Search from 'app/components/search';
import { Empty as EmptyIcon } from 'app/components/icons';
import { downloadCSV, formatDate } from 'utils';
import LivestreamDisabledWrapper from './components/LivestreamDisabledWrapper';
import styles from './styles.module.scss';
import themeStyles from '../../../../_export.scss';

const useStyles = makeStyles(() => ({
  table: {
    '& .MuiTableCell-head': {
      color: 'black',
      backgroundColor: '#EAEEFF',
      fontSize: 14,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
      textAlign: 'center',
      borderBottom: 'none',
    },
    '& .MuiTableCell-body': {
      color: 'black',
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Raleway, sans-serif',
      textAlign: 'center',
      borderBottom: '1px solid #d3d3d3',
    },
    '& td > .options-container': {
      '& span': {
        color: `${themeStyles.primaryColor}!important`,
      },
      '& svg': {
        fill: `${themeStyles.primaryColor}!important`,
      },
    },
  },
  tableLeftCorner: { borderTopLeftRadius: 10 },
  tableRightCorner: {
    borderTopRightRadius: 10,
    '& span': {
      color: `${themeStyles.primaryColor}!important`,
    },
    '& svg': {
      fill: `${themeStyles.primaryColor}!important`,
    },
  },
}));

const Livestream = ({
  chunks: users,
  filteredChunks: filteredUsers,
  getUsers,
  getGroups,
  history,
  pending,
  onToggleBan,
  onUpdateChecked,
  onAddLivestreamComponent,
  livestreamComponentEnabled,
  onViewAllUsers,
  onViewBannedUsers,
  onFilterUserData,
  onSelectAllUsers,
  onDeselectAllUsers,
  onDeleteSelected,
  selectAll,
  selected,
  onSelect,
  groups,
  filter,
  resetUsersData,
}) => {
  const { t } = useTranslation();
  const [totalUsers, setTotalUsers] = useState(0);
  const [userType, setUserType] = useState('all');
  const classes = useStyles();

  const handleBanClick = (livestreamBanned, user) => {
    onToggleBan(livestreamBanned, user);
  };

  const handleCheck = (hasLivestreamAccess, user) => {
    confirmAlert({
      title: `${t('Confirm to')} ${
        hasLivestreamAccess ? t('remove') : t('grant')
      } ${t('access.')}`,
      message: `${
        hasLivestreamAccess
          ? `${t('Are you sure you want to remove')} ${
              filteredUsers[user].email
            } ${t('from having moderator access?')}`
          : `${t('Are you sure you want to grant')} ${
              filteredUsers[user].email
            } ${t(
              'moderator access? Please make sure this is the right email to prevent misuse.',
            )}`
      }`,
      buttons: [
        {
          label: t('Yes'),
          onClick: () => onUpdateChecked(hasLivestreamAccess, user),
        },
        {
          label: t('No'),
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    resetUsersData();
    getUsers();
    getGroups();
    return () => {
      resetUsersData();
    };
  }, []);

  const onUserTypeChange = userType =>
    userType === 'all' ? onViewAllUsers() : onViewBannedUsers();

  useEffect(() => {
    onUserTypeChange(userType);
  }, [userType]);

  useEffect(() => {
    const size = Object.keys(filteredUsers).length;
    setTotalUsers(size);
  }, [filteredUsers]);

  const getCsvData = (selectedUserKeys, users) => {
    const csvColumns = [
      t('First name'),
      t('Last name'),
      t('Email'),
      t('Date created'),
      t('Groups'),
      t('Phone'),
      t('Verified'),
      t('Moderator access'),
      t('Ban status'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selectedUserKeys.forEach(userKey => {
      const groupNames = [];
      const groupKeys = Object.keys(
        users[userKey].groups ? users[userKey].groups : {},
      );
      groupKeys.forEach(groupKey => {
        if (groups[groupKey]) groupNames.push(groups[groupKey].name);
      });

      data += `${users[userKey].firstName},${users[userKey].lastName},${
        users[userKey].email
      },${formatDate(users[userKey].created_at)},"${groupNames.join(
        ',',
      )}",${users[userKey].phoneNumber || '-'},${
        users[userKey].emailVerified ? t('yes') : t('no')
      },${users[userKey].hasLivestreamAccess ? t('yes') : t('no')},${
        users[userKey].livestreamBanned ? t('banned') : '-'
      }\n`;
    });

    return data;
  };

  const content = (
    <React.Fragment>
      <div className={styles.header}>
        <span className={styles.header_text}>
          <Trans>Total Users</Trans>: <b>{totalUsers}</b>
        </span>
        <div className={styles.header_search}>
          <Search
            name="search"
            placeholder={t('Search...')}
            value={filter}
            onChange={onFilterUserData}
            onClear={() => onFilterUserData('')}
          />
        </div>
      </div>
      <div className={styles.user_type}>
        <div onClick={() => setUserType('all')}>
          <Trans>All Users</Trans>
        </div>
        <div onClick={() => setUserType('banned')}>
          <Trans>Banned Users</Trans>
        </div>
      </div>
      <div className={styles.user_type_text}>
        {userType === 'all' ? t('All Users') : t('Banned Users')}
      </div>
      {pending && <CircularProgress />}
      {totalUsers === 0 && !pending ? (
        <div className="empty-state">
          <EmptyIcon />
          <h5>
            <Trans>You don't have any users.</Trans>
          </h5>
        </div>
      ) : (
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <Table
            aria-label={t('customized table')}
            stickyHeader
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableLeftCorner}>
                  <Trans>User Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Email</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Username</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Moderator Access</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Ban Status</Trans>
                </TableCell>
                <TableCell className={classes.tableRightCorner}>
                  <div className="options-container">
                    <Checkbox
                      disableRipple
                      style={{ color: themeStyles.primaryColor }}
                      checked={!!selectAll}
                      onChange={(e, isChecked) =>
                        isChecked ? onSelectAllUsers() : onDeselectAllUsers()
                      }
                    />
                    <ActionMenu
                      selected={selected}
                      onDeleteSelected={() => onDeleteSelected(selected, users)}
                      onDownloadSelected={() => {
                        downloadCSV(getCsvData(selected, users), 'users');
                      }}
                      text={t('users')}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers &&
                Object.keys(filteredUsers).map(key => (
                  <TableRow key={filteredUsers[key].email}>
                    <TableCell component="th" scope="row">
                      {`${filteredUsers[key].firstName} ${filteredUsers[key].lastName}` ||
                        '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {filteredUsers[key].email || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {filteredUsers[key].userName || '-'}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        disableRipple
                        style={{ color: themeStyles.primaryColor }}
                        checked={!!filteredUsers[key].hasLivestreamAccess}
                        onClick={() =>
                          handleCheck(
                            !!filteredUsers[key].hasLivestreamAccess,
                            key,
                          )
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <button
                        className={styles.button}
                        style={{
                          backgroundColor: `${
                            filteredUsers[key].livestreamBanned
                              ? 'green'
                              : 'red'
                          }`,
                        }}
                        onClick={() =>
                          handleBanClick(
                            filteredUsers[key].livestreamBanned,
                            key,
                          )
                        }
                      >
                        {filteredUsers[key].livestreamBanned
                          ? t('UNBAN')
                          : t('BAN')}
                      </button>
                    </TableCell>
                    <TableCell>
                      <div className="options-container">
                        <Checkbox
                          checked={!!filteredUsers[key].selected}
                          onChange={() => onSelect(key)}
                          style={{ color: themeStyles.primaryColor }}
                          disableRipple
                        />
                        <ActionMenu
                          selected={[key]}
                          onDownloadSelected={() =>
                            downloadCSV(getCsvData([key], users), 'users')
                          }
                          onDeleteSelected={() =>
                            onDeleteSelected([key], users)
                          }
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  );

  return !livestreamComponentEnabled ? (
    <LivestreamDisabledWrapper
      onAddLivestreamComponent={onAddLivestreamComponent}
      history={history}
    >
      {content}
    </LivestreamDisabledWrapper>
  ) : (
    content
  );
};

export default Livestream;
