import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Ecwid from './Ecwid';
import { storeIdChange } from './actions';

const mapStateToProps = state => ({
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onEcwidIdChange: value =>
    dispatch(
      storeIdChange(
        ownProps.match.params.appname,
        queryString.parse(ownProps.location.search).component,
        value,
      ),
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ecwid));
