import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { Trans, useTranslation } from 'react-i18next';
import { EditorView } from '@codemirror/view';
// import { githubDark } from '@uiw/codemirror-theme-github';
import { vscodeDarkInit } from '@uiw/codemirror-theme-vscode';
import styled from 'styled-components';
import dompurify from 'dompurify';
import SectionHeader from '../../../../../../../modules/editor/components/sectionHeader';

const HtmlWrapper = styled.div`
  margin: 1.4rem 0;
  padding: 0 1.5rem;
`;

const ButtonWrapper = styled.button`
  margin-top: 20px;
  padding: 9px;
  width: 100% !important;
  font-size: 20px;
  font-weight: bold;
  color: ${props => `${props.theme.primaryTextColor}`};
  height: 100% !important;
  background-color: ${props => `${props.theme.primaryColor} !important`};
`;

const HtmlEditor = props => {
  const { t } = useTranslation();
  const [htmlCode, setHtmlCode] = React.useState();
  const onChange = React.useCallback((value, viewUpdate) => {
    setHtmlCode(value);
  }, []);

  useEffect(() => {
    setHtmlCode(props.data.content);
  }, [props.data.content]);

  const saveHtmlCode = () => {
    props.onChange(
      dompurify.sanitize(
        htmlCode,

        {
          FORCE_BODY: true,
          ADD_TAGS: ['script', 'link'],
          USE_PROFILES: { html: true },
        },
      ),
    );
  };
  return (
    <div>
      <SectionHeader
        title={t('Custom HTML Code')}
        tooltip={t('Add custom HTML code.')}
      />

      <HtmlWrapper>
        <CodeMirror
          value={htmlCode}
          height="450px"
          theme={vscodeDarkInit({
            settings: {
              caret: '#c6c6c6',
              fontFamily: 'monospace',
            },
          })}
          extensions={[html(), EditorView.lineWrapping]}
          onChange={onChange}
        />
        <ButtonWrapper onClick={saveHtmlCode}>
          <Trans>Save</Trans>
        </ButtonWrapper>
      </HtmlWrapper>
    </div>
  );
};

HtmlEditor.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default HtmlEditor;
