import firebase from '../../firebase';

export const updateDraftData = data => ({
  type: 'RECEIVE_DRAFT_DATA',
  data,
});

export const loadContent = appname => dispatch => {
  const ref = firebase.database().ref(`apps/${appname}/draft`);

  ref.once('value').then(snapshot => {
    const data = snapshot.val();
    if (data === null) return null;

    dispatch(updateDraftData(data));
    return null;
  });
};
