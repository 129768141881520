import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { withRouter } from 'react-router-dom';
import { CTAButton } from '../../../../../../app/components/buttons';
import DisabledWrapper from '../../../../../engage/components/disabledWrapper';
import { addMarketplaceComponent } from '../../../../actions';

const MarketplaceDisabledWrapper = ({ match, history, children }) => {
  const { t } = useTranslation();
  const [btnLabel, setBtnLabel] = useState(t('Do it for me!'));
  const dispatch = useDispatch();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const appname = match.params.appname;
  const infoIcon = (
    <Tooltip
      title={t(
        "This will add a new page 'Marketplace' and add Marketplace component to that page in your app",
      )}
    >
      <InfoOutlinedIcon fontSize="small" />
    </Tooltip>
  );

  const onAddMarketplaceComponent = () =>
    dispatch(addMarketplaceComponent(appname, history));
  const instructions = (
    <span>
      <span
        role="button"
        tabIndex={0}
        onClick={() => {
          const memberRoute = /members(.*)/;
          history.push(
            history.location.pathname.replace(
              memberRoute,
              `editor/build/list?page=homepage`,
            ),
          );
        }}
        style={{ borderBottom: '1px solid black', cursor: 'pointer' }}
      >
        <Trans>Go to pages</Trans>
      </span>
      &ndash;&gt;{' '}
      <Trans>
        Select a page &ndash;&gt; Click the &quot;Add Component&quot; button
      </Trans>{' '}
      (<span style={{ fontWeight: 600 }}>&#43;</span> <Trans>icon</Trans>)
      &ndash;&gt;{' '}
      <Trans>
        Select Marketplace component &ndash;&gt; Click button &quot;Add
        Marketplace Component&quot;
      </Trans>
    </span>
  );

  return (
    <DisabledWrapper
      title={t('Marketplace Component has not been added to your app yet!')}
      message={
        <span>
          <span>
            <Trans>
              To use this feature add Marketplace Component to your app
            </Trans>
          </span>
          <br />
          {instructions}
        </span>
      }
      content={
        <div>
          <br />
          <CTAButton
            disabled={btnDisabled}
            label={btnLabel}
            height={35}
            minWidth={100}
            icon={infoIcon}
            onClick={() => {
              onAddMarketplaceComponent();
              setBtnDisabled(true);
              setBtnLabel(t('Adding...'));
            }}
          />
        </div>
      }
    >
      {children}
    </DisabledWrapper>
  );
};

MarketplaceDisabledWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  history: PropTypes.object.isRequired,
  appname: PropTypes.string.isRequired,
};

export default withRouter(MarketplaceDisabledWrapper);
