import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Vendor from './Vendor';
import {
  getVendorDetails,
  stopGetVendorDetails,
  getProducts,
  stopGetProducts,
  changeVendorApproval,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.ecommerce.marketplace.vendor,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getVendorDetails: () =>
    dispatch(
      getVendorDetails(
        ownProps.match.params.appname,
        ownProps.match.params.vendorID,
      ),
    ),
  stopGetVendorDetails: () =>
    dispatch(
      stopGetVendorDetails(
        ownProps.match.params.appname,
        ownProps.match.params.vendorID,
      ),
    ),
  getProducts: () =>
    dispatch(
      getProducts(
        ownProps.match.params.appname,
        ownProps.match.params.vendorID,
      ),
    ),
  stopGetProducts: () =>
    dispatch(
      stopGetProducts(
        ownProps.match.params.appname,
        ownProps.match.params.vendorID,
      ),
    ),
  changeVendorApproval: (vendorApprovalStatus, message) =>
    dispatch(
      changeVendorApproval({
        appname: ownProps.match.params.appname,
        vendorID: ownProps.match.params.vendorID,
        vendorApprovalStatus,
        message,
      }),
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Vendor));
