const initialState = {
  email: {
    value: '',
    isValid: null,
  },
  password: {
    value: '',
    isValid: null,
  },
  confirmPassword: {
    value: '',
    isValid: null,
  },
};

const newContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'NEW_USER_FIELD_CHANGE':
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };

    default:
      return state;
  }
};

export default newContactReducer;
