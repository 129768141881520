import React from 'react';

const Text = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.95 50.5"
  >
    <title>file</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path class="cls-1" d="M35,50.5H0V0H35ZM1.5,49h32V1.5H1.5Z" />
        <path class="cls-1" d="M35,12.5H0V0H35ZM1.5,11h32V1.5H1.5Z" />
        <rect class="cls-1" x="6.75" y="17" width="22" height="1.5" />
        <rect class="cls-1" x="6.75" y="23" width="22" height="1.5" />
        <rect class="cls-1" x="6.75" y="29" width="22" height="1.5" />
        <rect class="cls-1" x="6.75" y="35" width="22" height="1.5" />
        <rect class="cls-1" x="6.75" y="41" width="22" height="1.5" />
      </g>
    </g>
  </svg>
);

export default Text;
