import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import Grid from '@material-ui/core/Grid';
import Toggle from 'material-ui/Toggle';
import IconButton from 'material-ui/IconButton';
import Delete from 'material-ui/svg-icons/action/delete';
import CloudUpload from 'material-ui/svg-icons/file/cloud-upload';
import Dropzone from 'react-dropzone';
import Download from '@material-ui/icons/CloudDownload';
import { Trans, withTranslation } from 'react-i18next';

import TextField from '../../../../../../app/components/textField';
import SelectField from '../../../../../../app/components/selectField';
import CircularValidation from '../../../../../../app/components/circularValidation';
import CreateField from './modules/create';
import DialogConfirmation from '../../../../../../app/components/dialogConfirmation';
import { OPTIONS } from '../../../../../../config';
import { CircularProgress } from '../../../../../../app/components/progress';

const gridStyles = {
  border: '1px solid #f3f3f3',
  borderRadius: '5px',
  marginBottom: '10px',
  background: '#fbfbfb',
};

class Edit extends Component {
  state = {
    confirmDeleteDialogOpen: false,
  };

  componentDidMount() {
    this.props.getGroupDetails();
  }

  toggleConfirmBox = () =>
    this.setState(prevState => ({
      confirmDeleteDialogOpen: !prevState.confirmDeleteDialogOpen,
    }));

  render() {
    const {
      error,
      type,
      updateGroup,
      groupDetails,
      fields,
      updateField,
      deleteField,
      onFileUpload,
      fileloading,
      t,
    } = this.props;

    const displayError = error ? (
      <div>
        <strong>{error}</strong>
      </div>
    ) : null;

    const fieldOptions = OPTIONS.map(data => (
      <MenuItem key={data.id} value={data.id} primaryText={data.value} />
    ));

    const fieldsUI = fields.map(id => (
      <Grid
        container
        spacing={0}
        key={`field-${id}`}
        alignItems="center"
        style={gridStyles}
      >
        <Grid item xs={3}>
          <TextField
            id="name"
            placeholder={t('Name')}
            label={t('Name')}
            multiLine
            value={groupDetails.fields[id].name}
            onChange={value =>
              value.length >= 2 ? updateField('name', id, value) : null
            }
            rightInputAction={
              <CircularValidation
                isValid={groupDetails.fields[id].name.length > 2}
                defaultMsg={t("Type's name required")}
                errorMsg={t('Name must be at least 2 characters long')}
                showDefault={!groupDetails.fields[id].name}
              />
            }
          />
        </Grid>
        <Grid item xs={2}>
          <SelectField
            className="offset-top"
            options={fieldOptions}
            label={t('Select Type')}
            onChange={(i, payload) => updateField('type', id, payload)}
            value={groupDetails.fields[id].type}
            autoWidth
            rightInputAction={
              <CircularValidation
                isValid={type.isValid}
                defaultMsg={t('Type type is required')}
                errorMsg={t('Type type is required')}
                showDefault={!groupDetails.fields[id].type}
              />
            }
          />
        </Grid>
        <Grid item xs={2}>
          <Toggle
            className="small-toggle"
            onToggle={(v, b) => updateField('isRequired', id, b)}
            toggled={groupDetails.fields[id].isRequired || false}
            label={t('Required')}
          />
        </Grid>
        <Grid item xs={2}>
          <Toggle
            className="small-toggle"
            onToggle={(v, b) => updateField('isSearchable', id, b)}
            toggled={groupDetails.fields[id].isSearchable || false}
            label={t('Searchable')}
          />
        </Grid>
        <Grid item xs={2}>
          <Toggle
            className="small-toggle-last"
            onToggle={(v, b) => updateField('isPublic', id, b)}
            toggled={groupDetails.fields[id].isPublic || false}
            label={t('Publicly Visible')}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            className="delete-icon"
            onClick={() => {
              this.setState({ item: id });
              this.toggleConfirmBox();
            }}
            tooltip={t('Delete')}
          >
            <Delete color="action" />
          </IconButton>
        </Grid>
        {groupDetails.fields[id].type === 'Dropdown' && (
          <React.Fragment>
            <Grid item xs={3}>
              <TextField
                id="options"
                placeholder={t('Dropdown Options')}
                label={t('Dropdown Options')}
                multiLine
                value={groupDetails.fields[id].options}
                onChange={value =>
                  value.length >= 2 ? updateField('options', id, value) : null
                }
                rightInputAction={
                  <CircularValidation
                    isValid={
                      groupDetails.fields[id].options &&
                      groupDetails.fields[id].options.length > 1
                    }
                    defaultMsg={t('Add multiple options using comma separated')}
                    errorMsg={t('Options is required')}
                    showDefault={!groupDetails.fields[id].options}
                  />
                }
              />
            </Grid>
            <Grid item xs={2}>
              <Toggle
                onToggle={(v, b) => updateField('isMultiple', id, b)}
                toggled={groupDetails.fields[id].isMultiple || false}
                label={t('Multiple Selection')}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    ));

    const form = (
      <div className="types-panel">
        <div className="types-panel-title">
          <Trans>Field Management</Trans>
        </div>
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <TextField
              id="name"
              placeholder={t('Group Name')}
              label={t('Select Type')}
              value={groupDetails.name}
              onChange={value => updateGroup('name', value)}
              rightInputAction={
                <CircularValidation
                  isValid={groupDetails.name.length >= 2}
                  defaultMsg={t("Type's name required")}
                  errorMsg={t('Name must be at least 2 characters long')}
                  showDefault={!groupDetails.name.value}
                />
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Toggle
              className="small-toggle-coc"
              onToggle={(v, b) => updateGroup('isCOC', b)}
              toggled={groupDetails.isCOC || false}
              label={t('Enable Code of Conduct')}
            />
          </Grid>
          {groupDetails.isCOC && (
            <Grid item xs={3}>
              <div className="upload-container">
                <Dropzone
                  className="dropzone"
                  onDrop={onFileUpload}
                  multiple={false}
                  accept=""
                  disableClick={fileloading}
                >
                  {fileloading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <React.Fragment>
                      <div className="dropzone-title">
                        {groupDetails.cOCDoc
                          ? t('Sample Code of Conduct Uploaded')
                          : t('Drop your Sample Code of Conduct here')}
                      </div>
                      {groupDetails.cOCDoc ? (
                        <React.Fragment>
                          <IconButton
                            aria-label={t('Download')}
                            style={{ display: 'inline-block' }}
                            color="primary"
                            onClick={e => {
                              e.stopPropagation(true);
                              window.open(groupDetails.cOCDoc, '_blank');
                            }}
                          >
                            <Download fontSize="large" />
                          </IconButton>
                        </React.Fragment>
                      ) : (
                        <div>
                          <CloudUpload />
                          <div className="dropzone-text">
                            <Trans>or</Trans>{' '}
                            <u>
                              <Trans>select a file from your computer</Trans>
                            </u>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </Dropzone>
              </div>
            </Grid>
          )}
        </Grid>
        {fieldsUI}
        <CreateField />
      </div>
    );

    return (
      <div className="types-container">
        <div className="list-fieldset">{displayError}</div>
        <DialogConfirmation
          open={this.state.confirmDeleteDialogOpen}
          onClose={this.toggleConfirmBox}
          message={t(
            `Are you sure you want to permenantly delete the field? This will also remove all the data which belongs from this field.`,
          )}
          action={() => deleteField(this.state.item)}
        />
        {form}
      </div>
    );
  }
}

Edit.propTypes = {
  error: PropTypes.string,
  name: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  type: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  updateGroup: PropTypes.func.isRequired,
  getGroupDetails: PropTypes.func.isRequired,
  groupDetails: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  updateField: PropTypes.func.isRequired,
  deleteField: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  fileloading: PropTypes.bool.isRequired,
};

Edit.defaultProps = {
  error: null,
};

export default withTranslation()(Edit);
