import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { resetPassword, recoverEmail, verifyEmail } from './actions';

import RecoverEmail from './RecoverEmail';
import ResetPassword from './ResetPassword';
import VerifyEmail from './VerifyEmail';

const FbActionHandler = ({
  handleResetPassword,
  handleRecoverEmail,
  handleVerifyEmail,
  errorMessage,
  email,
  message,
  location,
  history,
}) => {
  const query = queryString.parse(location.search);
  return (
    <div>
      {query.mode === 'resetPassword' && (
        <ResetPassword
          handleResetPassword={handleResetPassword}
          oobCode={query.oobCode}
          errorMessage={errorMessage}
          history={history}
        />
      )}
      {query.mode === 'recoverEmail' && (
        <RecoverEmail
          handleRecoverEmail={handleRecoverEmail}
          oobCode={query.oobCode}
          email={email}
          errorMessage={errorMessage}
          history={history}
        />
      )}
      {query.mode === 'verifyEmail' && (
        <VerifyEmail
          handleVerifyEmail={handleVerifyEmail}
          oobCode={query.oobCode}
          message={message}
          history={history}
        />
      )}
    </div>
  );
};

FbActionHandler.propTypes = {
  handleResetPassword: PropTypes.func.isRequired,
  handleRecoverEmail: PropTypes.func.isRequired,
  handleVerifyEmail: PropTypes.func.isRequired,
  mode: PropTypes.string,
  oobCode: PropTypes.string,
  errorMessage: PropTypes.string,
  email: PropTypes.string,
  message: PropTypes.string,
  location: PropTypes.any,
  history: PropTypes.any,
};

const mapStateToProps = (state, ownProps) => ({
  ...state.account.firebaseActions,
  ...ownProps.location.query,
});

const mapDispatchToProps = dispatch => ({
  handleResetPassword: (actionCode, newPass) =>
    dispatch(resetPassword(actionCode, newPass)),
  handleRecoverEmail: actionCode => dispatch(recoverEmail(actionCode)),
  handleVerifyEmail: actionCode => dispatch(verifyEmail(actionCode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FbActionHandler);
