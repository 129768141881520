import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Panel from '../../../../app/components/panel';

import './styles.css';

const Intro = ({ match, isAppSumoUser, isAppAdmin }) => {
  const { t } = useTranslation();

  return (
    <Panel style={{ position: 'relative', margin: '20px 0px' }}>
      <div className="ml-intro-content">
        <img
          src={require('./member-login.svg')}
          alt={t('screenshot of member login feature')}
        />
        <p>
          <Trans>Member Login gives you the option to provide</Trans>{' '}
          <strong>
            <Trans>Registration</Trans>
          </strong>{' '}
          <Trans>and</Trans>{' '}
          <strong>
            <Trans>Sign-in</Trans>
          </strong>{' '}
          <Trans>capability for your app.</Trans>{' '}
          <Trans>
            This means you can provide access to exclusive content for your
            users. Once a user registers via your Mobile App they will appear in
            the Member Login area where you can decide, as the App owner, which
            group they should be designated to. You can choose which Pages are
            available to view for each Group. You can also select which Pages
            are available when they first register via the 'My Default Group'.
          </Trans>
        </p>
        {!isAppSumoUser && !isAppAdmin && (
          <p>
            <Trans>
              Please be aware, Member Login is only available to accounts
              subscribed to our
            </Trans>{' '}
            <Link
              className="dark-text decorate"
              to={`/${match.params.appname}/choose_plan?plan=deluxe`}
            >
              Everything <Trans>plan.</Trans>
            </Link>{' '}
            <Trans>
              You can upgrade your plan by clicking the ‘Upgrade’ button within
              your App Builder (located bottom left).
            </Trans>
          </p>
        )}
      </div>
    </Panel>
  );
};

Intro.propTypes = {
  match: PropTypes.object.isRequired,
  isAppSumoUser: PropTypes.bool,
};

export default Intro;
