import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Offers from './Offers';
import { subscribe } from '../subscribe/actions';
import { openLegalModal } from '../legal/actions';
import { addThankYouNotification, addToAnalytics, resetOffer } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  payment: state.account.payment,
  subscription:
    state.account.subscriptions.items[ownProps.match.params.appname],
  promotion: state.account.offer,
  account: state.account.auth.profile.account,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubscribe: ({ plan, period, coupon, callback }) =>
    dispatch(
      subscribe({
        appname: ownProps.match.params.appname,
        plan,
        period,
        callback,
        coupon,
      }),
    ),
  onOpenLegalModal: selectedModal => dispatch(openLegalModal(selectedModal)),
  resetOffer: () => dispatch(resetOffer()),
  addThankYouNotification: (account, content) =>
    dispatch(addThankYouNotification(account, content)),
  addToAnalytics: (accountID, offerID) =>
    dispatch(addToAnalytics(accountID, offerID)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Offers),
);
