import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import { Trans, useTranslation } from 'react-i18next';
import { FlatButton } from '../../../../../../app/components/buttons';
import { CircularProgress } from '../../../../../../app/components/progress';
import FormFields from '../../../../components/form';
import { objectToArray } from '../../../../../../utils';

import './styles.css';

const New = ({
  open,
  pending,
  onSubmit,
  onRequestClose,
  onFieldChange,
  fields,
  fieldsData,
}) => {
  const { t } = useTranslation();

  const buttonSubmit = !pending ? (
    <FlatButton
      label={t('Create')}
      onClick={onSubmit}
      primary
      disabled={
        !fieldsData ||
        !!objectToArray(fieldsData).find(
          i => !i.value.isValid && i.id !== 'createdAt' && i.id !== 'updatedAt',
        )
      }
    />
  ) : (
    <CircularProgress size={18} />
  );

  return (
    <Dialog open={open} onRequestClose={onRequestClose} autoScrollBodyContent>
      <div className="user-cpds-modal-content">
        <div className="modal-heading">
          <h5>
            <Trans>Create New CPD</Trans>
          </h5>
        </div>
        <div>
          <FormFields
            fields={fields}
            onFieldChange={onFieldChange}
            fieldsData={fieldsData}
          />
          <div className="submit-button-container">{buttonSubmit}</div>
        </div>
      </div>
    </Dialog>
  );
};

New.propTypes = {
  open: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  fieldsData: PropTypes.object.isRequired,
};

New.defaultProps = {
  error: null,
  fields: {},
  fieldsData: {},
};

export default New;
