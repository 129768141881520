import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { withTranslation } from 'react-i18next';
import Container from '../../../../../../../app/components/container';
import Toggle from '../../../../../../../app/components/toggle';
import ComponentItem from '../../../../../components/componentItem';
import SectionHeader from '../../../../../components/sectionHeader';
import CreateField from '../../../../../components/createField';
import ButtonDesignMenu from '../../../../../components/buttonDesignMenu';
import ColorPicker from '../../../../../components/colorPicker';
import ImageDesignMenu from '../../../../../components/imageDesignMenu';
import DesignToggle from '../../../../../components/designToggle';
import { Tabs, Tab } from '../../../../../components/tabs';
import DraggableListWrapper from '../../../../../components/draggableListWrapper';

import LayoutTab from '../../tabs/layoutTab';
import LayoutOption from '../../tabs/layoutTab/layoutOption';

import headerSmall from '../header/headerSmall';
import headerMedium from '../header/headerMedium';
import headerLarge from '../header/headerLarge';
import headerFullScreen from '../header/headerFullScreen';

class Slider extends Component {
  static propTypes = {
    appname: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onAddItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    rearrangeItems: PropTypes.func.isRequired,
    component: PropTypes.object.isRequired,
    onButtonDesignChange: PropTypes.func.isRequired,
    onDesignChange: PropTypes.func.isRequired,
  };

  getItemHref = key => {
    const { match, location } = this.props;

    return {
      pathname: `/${match.params.appname}/editor/build/item`,
      search: `${location.search}&item=${key}`,
    };
  };

  sliderLayoutsOptions = () => {
    const { t } = this.props;
    const style = {
      width: '100%',
      heigth: '100%',
    };
    return {
      options: [
        <LayoutOption
          key="slider_layout"
          wrapperKey="slider_layout"
          grid={2}
          tooltip={t('Change your slider component template')}
          title={t('Slider templates')}
          active={this.props.component.layout}
          items={[
            {
              value: 'FullScreen',
              icon: headerFullScreen,
              style,
              dimensions: {
                mobile: '430px by 873px',
                desktop: '1630px by 730px',
              },
            },
            {
              value: 'Large',
              icon: headerLarge,
              style,
              dimensions: {
                mobile: '430px by 699px',
                desktop: '1630px by 500px',
              },
            },
            {
              value: 'Medium',
              icon: headerMedium,
              style,
              dimensions: {
                mobile: '430px by 466px',
                desktop: '1630px by 300px',
              },
            },
            {
              value: 'Small',
              icon: headerSmall,
              style,
              dimensions: {
                mobile: '430px by 279px',
                desktop: '1630px by 220px',
              },
            },
          ]}
        />,
      ],
    };
  };

  handleMove = (el, target, source, sibling) => {
    if (sibling) {
      return this.props.rearrangeItems(el.id, sibling.id);
    }

    return this.props.rearrangeItems(el.id, null);
  };

  handleNavigate = key => () => {
    const href = this.getItemHref(key);

    return this.props.history.push(href);
  };

  handleDesignChange = prop => (el, value) =>
    this.props.onDesignChange(prop, value);

  handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    this.props.onDesignChange(prop, rgba);
  };

  buttonToggle = () => (
    <Toggle
      onToggle={this.handleDesignChange('/buttonDesign/enabled')}
      toggled={this.props.component.design.buttonDesign.enabled}
      disabled={!this.props.component.design.enabled}
    />
  );

  renderItems = () => {
    const { data } = this.props;
    const slides = _get(data, 'slides');
    const order = _get(data, 'slides.order');

    if (!slides || !order) return [];

    return order.map(key => {
      const title = slides.chunks[key] && slides.chunks[key].title;

      return (
        <ComponentItem
          id={key}
          key={key}
          label={title}
          onClick={this.handleNavigate(key)}
          onDelete={() => this.props.onRemoveItem(key)}
        />
      );
    });
  };

  render() {
    const items = this.renderItems();
    const { appname, component, t } = this.props;
    const { design } = component;

    return (
      <React.Fragment>
        <Tabs>
          <Tab label={t('Content')}>
            <Container>
              <div
                key="someId"
                style={{ paddingTop: 24, margin: -12, width: 379 }}
              >
                <SectionHeader title={t('Slides')} />
                <CreateField
                  id="slide-creation"
                  hintText={t('New Slide')}
                  tooltip={t('Add New Slide')}
                  onSubmit={title => this.props.onAddItem(title)}
                />
                <DraggableListWrapper
                  items={items}
                  onPositionChange={this.handleMove}
                />
              </div>
            </Container>
          </Tab>
          <Tab label={t('design')}>
            <Container>
              <DesignToggle
                component={this.props.component}
                onDesignChange={this.props.onDesignChange}
              />
              <ColorPicker
                label={t('Text Color')}
                color={design.textColor}
                height={22}
                disabled={!this.props.component.design.enabled}
                onChange={this.handleColorChange('textColor')}
              />
              <ColorPicker
                label={t('Background Color')}
                color={design.backgroundColor}
                height={22}
                disabled={!design.enabled}
                onChange={this.handleColorChange('backgroundColor')}
              />
              <ImageDesignMenu
                component={this.props.component}
                onDesignChange={this.props.onDesignChange}
              />
              <SectionHeader
                title={t('Button')}
                actions={this.buttonToggle()}
                tooltip={t('Define the styles for your button')}
                borderTop
              />
              <ButtonDesignMenu
                component={this.props.component}
                onDesignChange={this.props.onButtonDesignChange}
              />
            </Container>
          </Tab>
          <Tab label={t('layout')}>
            <LayoutTab
              componentKey={component.key}
              appname={appname}
              {...this.sliderLayoutsOptions()}
            />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Slider);
