import React from 'react';
import './styles.css';

const Features = () => (
  <svg
    className="icon feature"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    viewBox="0.0 0.0 350.1 519.2"
    zoomAndPan="magnify"
    style={{ fill: 'rgb(0, 0, 0)' }}
    role="img"
    aria-label="Feature List Outline Illustration"
  >
    <g id="__id30_ssv2605qt7">
      <path
        d="M330.61,519.21H19.5c-10.75,0-19.5-8.75-19.5-19.5V54.59c0-10.75,8.75-19.5,19.5-19.5h311.11 c10.75,0,19.5,8.75,19.5,19.5v445.12C350.11,510.47,341.36,519.21,330.61,519.21z M19.5,50.09c-2.44,0-4.5,2.06-4.5,4.5v445.12 c0,2.44,2.06,4.5,4.5,4.5h311.11c2.44,0,4.5-2.06,4.5-4.5V54.59c0-2.44-2.06-4.5-4.5-4.5H19.5z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
    <g id="__id31_ssv2605qt7">
      <path
        d="M46.74,85.19c-4.14,0-7.5-3.36-7.5-7.5V7.5c0-4.14,3.36-7.5,7.5-7.5s7.5,3.36,7.5,7.5v70.19 C54.24,81.83,50.89,85.19,46.74,85.19z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
    <g id="__id32_ssv2605qt7">
      <path
        d="M305.12,85.19c-4.14,0-7.5-3.36-7.5-7.5V7.5c0-4.14,3.36-7.5,7.5-7.5s7.5,3.36,7.5,7.5v70.19 C312.62,81.83,309.27,85.19,305.12,85.19z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
    <g id="__id33_ssv2605qt7">
      <path
        d="M175.93,85.19c-4.14,0-7.5-3.36-7.5-7.5V7.5c0-4.14,3.36-7.5,7.5-7.5c4.14,0,7.5,3.36,7.5,7.5v70.19 C183.43,81.83,180.08,85.19,175.93,85.19z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
    <g id="__id34_ssv2605qt7">
      <path
        d="M111.34,85.19c-4.14,0-7.5-3.36-7.5-7.5V7.5c0-4.14,3.36-7.5,7.5-7.5s7.5,3.36,7.5,7.5v70.19 C118.84,81.83,115.48,85.19,111.34,85.19z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
    <g id="__id35_ssv2605qt7">
      <path
        d="M240.53,85.19c-4.14,0-7.5-3.36-7.5-7.5V7.5c0-4.14,3.36-7.5,7.5-7.5s7.5,3.36,7.5,7.5v70.19 C248.03,81.83,244.67,85.19,240.53,85.19z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
    <g id="__id36_ssv2605qt7">
      <path
        d="M305.75,180.49H151.43c-4.14,0-7.5-3.36-7.5-7.5s3.36-7.5,7.5-7.5h154.31c4.14,0,7.5,3.36,7.5,7.5 S309.89,180.49,305.75,180.49z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
    <g id="__id37_ssv2605qt7">
      <path
        d="M305.75,282.76H151.43c-4.14,0-7.5-3.36-7.5-7.5c0-4.14,3.36-7.5,7.5-7.5h154.31c4.14,0,7.5,3.36,7.5,7.5 C313.25,279.4,309.89,282.76,305.75,282.76z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
    <g id="__id38_ssv2605qt7">
      <path
        d="M101.77,228.35c-2.48,0-4.96-0.61-7.25-1.84l-11.85-6.39c-0.07-0.04-0.15-0.04-0.22,0l-11.98,6.14 c-5.2,2.66-11.34,2.15-16.03-1.33s-6.95-9.22-5.9-14.97l2.42-13.24c0.01-0.08-0.01-0.15-0.07-0.21l-9.54-9.5 c-4.14-4.12-5.55-10.12-3.69-15.66c1.87-5.54,6.62-9.46,12.41-10.23l13.34-1.79c0.08-0.01,0.14-0.06,0.18-0.13l6.09-12.01 c2.64-5.21,7.92-8.36,13.75-8.34c5.84,0.06,11.04,3.37,13.57,8.64l5.83,12.14c0.03,0.07,0.1,0.12,0.17,0.13c0,0,0,0,0,0l13.3,2.08 c5.77,0.9,10.44,4.93,12.19,10.5c1.74,5.57,0.2,11.54-4.02,15.57l-9.74,9.29c-0.06,0.05-0.08,0.13-0.07,0.21l2.13,13.29 c0.93,5.77-1.46,11.45-6.22,14.83C107.94,227.4,104.86,228.35,101.77,228.35z M82.56,205.08c2.49,0,4.98,0.61,7.24,1.83l11.85,6.39 c0.07,0.04,0.11,0.05,0.11,0.05c0.07,0,0.22-0.11,0.24-0.17c0-0.01,0.01-0.04-0.01-0.12l-2.13-13.29 c-0.79-4.95,0.9-9.98,4.53-13.44l9.74-9.29c0.06-0.05,0.07-0.08,0.08-0.09c0.01-0.03,0-0.14-0.05-0.23 c-0.03-0.05-0.04-0.06-0.15-0.08l-13.3-2.08c-4.96-0.77-9.21-3.94-11.38-8.46l-5.83-12.14c-0.03-0.07-0.06-0.1-0.06-0.1 c-0.05-0.04-0.24-0.04-0.3,0c0,0-0.03,0.03-0.06,0.1l-6.09,12.01c-2.27,4.47-6.59,7.54-11.56,8.21l-13.34,1.79 c-0.08,0.01-0.11,0.02-0.12,0.03c-0.02,0.02-0.08,0.11-0.1,0.22c-0.01,0.06-0.01,0.07,0.08,0.15l9.54,9.5 c3.55,3.54,5.14,8.6,4.24,13.53l-2.42,13.24c-0.02,0.12-0.01,0.12,0.02,0.17c0.07,0.08,0.17,0.13,0.2,0.13h0 c0.01,0,0.04-0.01,0.11-0.04l11.98-6.14C77.8,205.64,80.18,205.08,82.56,205.08z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
    <g id="__id39_ssv2605qt7">
      <path
        d="M101.77,331.3c-2.48,0-4.96-0.61-7.25-1.84l-11.85-6.39c-0.07-0.04-0.15-0.04-0.22,0l-11.98,6.14 c-5.2,2.66-11.34,2.15-16.03-1.33c-4.69-3.48-6.95-9.22-5.9-14.97l2.42-13.24c0.01-0.08-0.01-0.15-0.07-0.21l-9.54-9.5 c-4.14-4.12-5.55-10.12-3.69-15.66c1.86-5.54,6.62-9.46,12.41-10.24l13.34-1.79c0.08-0.01,0.14-0.06,0.18-0.13l6.09-12.01 c2.64-5.21,7.92-8.43,13.75-8.34c5.84,0.06,11.04,3.37,13.57,8.64l5.83,12.14c0.03,0.07,0.1,0.12,0.18,0.13l13.3,2.08 c5.77,0.9,10.44,4.93,12.19,10.5c1.75,5.58,0.2,11.54-4.02,15.58l-9.74,9.29c-0.06,0.05-0.08,0.13-0.07,0.21l2.13,13.29 c0.93,5.77-1.46,11.45-6.22,14.83C107.94,330.35,104.86,331.3,101.77,331.3z M82.56,308.04c2.49,0,4.98,0.61,7.24,1.83l11.85,6.39 c0.08,0.04,0.11,0.04,0.11,0.04c0.07,0,0.22-0.11,0.24-0.17c0-0.01,0.01-0.04-0.01-0.12l-2.13-13.29 c-0.79-4.95,0.9-9.97,4.53-13.44l9.74-9.29c0.08-0.08,0.08-0.09,0.08-0.15c-0.01-0.11-0.07-0.21-0.09-0.22 c-0.01,0-0.04-0.02-0.12-0.03l-13.3-2.08h0c-4.96-0.78-9.21-3.94-11.38-8.46l-5.83-12.14c-0.05-0.11-0.06-0.11-0.12-0.12 c-0.11-0.03-0.22,0-0.24,0.02c0,0-0.03,0.03-0.06,0.1l-6.09,12.01c-2.27,4.47-6.59,7.54-11.56,8.21l-13.34,1.79 c-0.12,0.02-0.12,0.03-0.15,0.07c-0.06,0.09-0.07,0.21-0.06,0.23c0,0.01,0.02,0.04,0.08,0.09l9.54,9.5 c3.55,3.54,5.14,8.6,4.24,13.53L63.3,315.6c-0.02,0.12-0.01,0.12,0.02,0.17c0.07,0.08,0.17,0.13,0.2,0.13l0,0 c0.01,0,0.04-0.01,0.11-0.04l11.98-6.13C77.8,308.6,80.18,308.04,82.56,308.04z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
    <g id="__id40_ssv2605qt7">
      <path
        d="M101.77,434.26c-2.48,0-4.96-0.61-7.25-1.84l-11.85-6.39c-0.07-0.04-0.15-0.04-0.22,0l-11.98,6.14 c-5.2,2.66-11.34,2.15-16.03-1.33c-4.69-3.48-6.95-9.22-5.9-14.97l2.42-13.24c0.01-0.08-0.01-0.16-0.07-0.21l-9.54-9.5 c-4.14-4.12-5.55-10.12-3.69-15.66c1.86-5.54,6.62-9.46,12.41-10.24l13.34-1.79c0.08-0.01,0.14-0.06,0.18-0.13l6.09-12.01 c2.64-5.21,7.92-8.45,13.75-8.34c5.84,0.06,11.04,3.37,13.57,8.64l5.83,12.14c0.03,0.07,0.1,0.12,0.18,0.13l13.3,2.08 c5.77,0.9,10.44,4.93,12.19,10.5c1.75,5.58,0.2,11.54-4.02,15.58l-9.74,9.29c-0.06,0.05-0.08,0.13-0.07,0.21l2.13,13.29 c0.93,5.77-1.46,11.45-6.22,14.83C107.94,433.3,104.86,434.26,101.77,434.26z M82.56,410.99c2.49,0,4.98,0.61,7.24,1.83l11.85,6.39 c0.07,0.04,0.1,0.05,0.11,0.05c0.07,0,0.22-0.11,0.24-0.17c0-0.01,0.01-0.04-0.01-0.12l-2.13-13.29c-0.79-4.95,0.9-9.98,4.53-13.44 l9.74-9.29c0.08-0.08,0.08-0.09,0.08-0.15c-0.01-0.11-0.07-0.21-0.09-0.22c-0.01,0-0.04-0.02-0.12-0.03l-13.3-2.08c0,0,0,0,0,0 c-4.96-0.78-9.21-3.94-11.38-8.46l-5.83-12.14c-0.05-0.11-0.06-0.11-0.12-0.12c-0.11-0.03-0.22,0-0.24,0.02 c-0.01,0-0.03,0.03-0.06,0.1l-6.09,12.01c-2.27,4.47-6.59,7.54-11.56,8.21l-13.34,1.79c-0.12,0.02-0.12,0.03-0.15,0.07 c-0.06,0.09-0.07,0.21-0.06,0.23c0,0.01,0.02,0.04,0.08,0.09l9.54,9.5c3.55,3.54,5.14,8.6,4.24,13.53l-2.42,13.24 c-0.02,0.11-0.03,0.14,0.09,0.23s0.15,0.07,0.25,0.02l11.98-6.14C77.8,411.55,80.18,410.99,82.56,410.99z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
    <g id="__id41_ssv2605qt7">
      <path
        d="M305.75,385.03H151.43c-4.14,0-7.5-3.36-7.5-7.5s3.36-7.5,7.5-7.5h154.31c4.14,0,7.5,3.36,7.5,7.5 S309.89,385.03,305.75,385.03z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
    <g id="__id42_ssv2605qt7">
      <path
        d="M255.29,418.91H151.43c-4.14,0-7.5-3.36-7.5-7.5s3.36-7.5,7.5-7.5h103.85c4.14,0,7.5,3.36,7.5,7.5 S259.43,418.91,255.29,418.91z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
    <g id="__id43_ssv2605qt7">
      <path
        d="M255.29,316.7H151.43c-4.14,0-7.5-3.36-7.5-7.5s3.36-7.5,7.5-7.5h103.85c4.14,0,7.5,3.36,7.5,7.5 S259.43,316.7,255.29,316.7z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
    <g id="__id44_ssv2605qt7">
      <path
        d="M255.29,214.48H151.43c-4.14,0-7.5-3.36-7.5-7.5s3.36-7.5,7.5-7.5h103.85c4.14,0,7.5,3.36,7.5,7.5 S259.43,214.48,255.29,214.48z"
        style={{ fill: 'rgb(38, 30, 213)' }}
      />
    </g>
  </svg>
);

export default Features;
