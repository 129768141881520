import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const CustomCircularProgress = ({ size, thickness, style }) => (
  <CircularProgress
    size={size}
    thickness={thickness}
    color={'inherit'}
    style={style}
  />
);

CustomCircularProgress.propTypes = {
  size: PropTypes.number,
  thickness: PropTypes.number,
  style: PropTypes.object,
};

CustomCircularProgress.defaultProps = {
  size: 30,
  thickness: 5,
  style: {},
};

export default CustomCircularProgress;
