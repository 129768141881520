import * as React from 'react';

const PlMatchSupportIcon = props => (
  <svg
    viewBox="0.2 1 49.6905 47.9999"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>PL Match Support</title>
    <g
      transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, 0, 50)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M126 419 c-9 -39 -16 -78 -16 -85 0 -7 -24 -31 -54 -53 -40 -30 -54 -46 -54 -65 0 -14 5 -23 10 -20 14 9 37 -16 31 -33 -4 -11 -3 -14 6 -9 17 11 41 -12 34 -31 -4 -11 -3 -14 6 -9 17 11 41 -12 34 -31 -4 -11 -3 -14 6 -9 16 10 41 -12 35 -29 -4 -10 0 -12 15 -8 14 3 23 0 27 -11 7 -19 54 -22 72 -4 7 7 12 20 12 29 0 19 -49 83 -57 75 -3 -3 7 -19 21 -37 60 -71 -15 -35 -94 45 -30 31 -59 56 -64 56 -17 0 115 -128 148 -144 l31 -16 -28 0 c-23 0 -50 21 -130 99 -56 55 -99 103 -94 107 4 4 17 -2 30 -14 33 -31 46 -27 16 4 l-24 26 24 19 24 20 18 -27 c22 -34 44 -47 68 -39 16 5 19 0 19 -31 0 -32 13 -53 34 -54 9 0 0 50 -14 77 -9 18 -7 32 10 73 11 27 17 50 13 50 -5 0 -18 -22 -29 -50 -24 -58 -49 -65 -80 -25 -18 23 -19 30 -8 77 11 51 13 53 44 51 56 -2 82 -12 76 -28 -9 -21 3 -19 15 4 24 44 50 121 41 121 -5 0 -12 -11 -16 -25 -7 -28 -13 -29 -84 -13 -35 8 -49 16 -46 25 3 7 2 13 -3 13 -5 0 -16 -32 -25 -71z m103 11 c30 -7 40 -30 14 -30 -23 1 -93 17 -100 23 -3 3 -2 10 2 16 6 10 16 9 84 -9z"
        style={{
          fill: 'rgb(41, 31, 212)',
        }}
      />
      <path
        d="M313 345 c-43 -19 -73 -69 -73 -124 0 -37 6 -49 39 -82 35 -35 44 -39 88 -39 137 0 183 177 62 242 -40 21 -72 22 -116 3z m92 -25 c9 -29 -2 -40 -41 -40 -29 0 -34 3 -34 23 0 31 7 37 41 37 19 0 30 -6 34 -20z m-91 -5 c-4 -8 -14 -15 -23 -15 -14 1 -13 3 3 15 25 19 27 19 20 0z m1 -74 c-4 -21 -13 -31 -25 -31 -42 0 -48 65 -6 72 30 6 39 -6 31 -41z m161 24 c6 -26 -9 -55 -30 -55 -13 0 -38 59 -30 71 3 5 16 9 29 9 18 0 26 -7 31 -25z m-72 -11 c11 -29 6 -49 -15 -62 -17 -11 -25 -11 -44 2 -21 14 -23 19 -14 46 9 24 17 30 39 30 16 0 30 -7 34 -16z m67 -77 c-8 -8 -11 -7 -11 4 0 20 13 34 18 19 3 -7 -1 -17 -7 -23z m-133 4 c28 -24 28 -37 1 -52 -30 -16 -61 16 -48 49 10 27 17 27 47 3z m110 -14 c4 -32 -31 -56 -54 -36 -20 17 -17 34 9 52 29 21 41 16 45 -16z m-58 -53 c0 -2 -9 -4 -21 -4 -11 0 -18 4 -14 10 5 8 35 3 35 -6z"
        style={{
          fill: 'rgb(41, 31, 212)',
        }}
      />
    </g>
  </svg>
);
export default PlMatchSupportIcon;
