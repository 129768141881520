import React from 'react';

const PayNow = () => (
  <svg
    className="icon"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
  >
    <g>
      <path
        d="M23.6,35.7c-1.5,0.4-3,0.6-4.6,0.6c-9.6,0-17.5-7.8-17.5-17.5S9.4,1.4,19,1.4s17.5,7.8,17.5,17.5
		c0,1.6-0.2,3.1-0.6,4.5l1.3,0.3c0.4-1.6,0.7-3.2,0.7-4.9C37.9,8.4,29.4,0,19,0S0.2,8.4,0.2,18.8S8.7,37.6,19,37.6
		c1.7,0,3.3-0.2,4.9-0.7L23.6,35.7z"
      />
      <path
        d="M45.9,50l-9.6-9.6l-5.2,5.2l-5.2-19.4l19.4,5.2l-5.2,5.2l9.6,9.6L45.9,50z M36.3,38.3l9.6,9.6l1.7-1.7L38,36.5
		l4.4-4.4L28,28.2l3.9,14.4L36.3,38.3z"
      />
      <g>
        <path
          d="M22.2,14.6c-0.3-1.3-1.1-1.9-2.5-1.9c-0.7,0-1.3,0.3-1.8,0.8c-0.5,0.5-0.7,1.4-0.7,2.6v2.7h4.5v1.4h-4.5v2.6
			c0,1.1-0.3,1.9-0.9,2.3h4.6c0.8,0,1.4-0.2,1.8-0.5c0.4-0.3,0.6-0.9,0.7-1.6h2.1c-0.1,1.3-0.5,2.2-1.2,2.9c-0.7,0.6-1.8,1-3.4,1
			h-8.1v-1.7h0.7c0.6,0,1.1-0.2,1.4-0.5c0.3-0.4,0.4-0.9,0.4-1.7v-2.7h-2.3v-1.4h2.3V16c0-1.6,0.4-2.9,1.2-3.8
			c0.8-0.9,1.9-1.4,3.4-1.4c1.2,0,2.1,0.3,2.9,0.9c0.7,0.6,1.2,1.6,1.4,2.8H22.2z"
        />
      </g>
    </g>
  </svg>
);

export default PayNow;
