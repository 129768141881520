import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';

import Shopify from './Shopify';
import {
  designChange,
  handleDefaultColorChange,
  fieldChange,
  fetchShopifyCategories,
  initStorefront,
} from './actions';

class ShopifyContainer extends Component {
  constructor(props) {
    super(props);
    const { src, token } = this.props.component;
    this.props.initialize(src, token);
  }

  componentDidMount() {
    const {
      src,
      activeCategory,
      showProductsOfSpecificCategory,
    } = this.props.component;
    this.props.onFetchShopifyCategories(
      src,
      activeCategory,
      showProductsOfSpecificCategory,
    );
  }

  async componentWillReceiveProps(nextProps) {
    const {
      src,
      token,
      activeCategory,
      showProductsOfSpecificCategory,
    } = nextProps.component;
    if (
      this.props.component.src !== nextProps.component.src ||
      this.props.component.token !== nextProps.component.token
    ) {
      try {
        await this.props.initialize(src, token);
        await this.props.onFetchShopifyCategories(
          src,
          activeCategory,
          showProductsOfSpecificCategory,
        );
      } catch (e) {
        console.error(e);
      }
    }
  }

  render() {
    return <Shopify {...this.props} />;
  }
}

ShopifyContainer.propTypes = {
  component: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const designData = state.editor.design[ownProps.match.params.appname];

  if (!designData) {
    return {
      ...ownProps,
      palette: {},
    };
  }

  return {
    ...designData,
    isAppAdmin:
      (state.account.auth.profile.admin_appname &&
        state.account.auth.profile.admin_appname.length > 0) ||
      false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFieldChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        queryString.parse(ownProps.location.search).component,
        field,
        value,
      ),
    ),
  onDesignChange: (field, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        queryString.parse(ownProps.location.search).component,
        field,
        value,
      ),
    ),
  onDefaultColorChange: (field, value) =>
    dispatch(
      handleDefaultColorChange(ownProps.match.params.appname, value, field),
    ),
  initialize: (src, token) => dispatch(initStorefront(src, token)),
  onFetchShopifyCategories: (
    src,
    activeCategory,
    showProductsOfSpecificCategory,
  ) =>
    dispatch(
      fetchShopifyCategories(
        ownProps.match.params.appname,
        queryString.parse(ownProps.location.search).component,
        src,
        activeCategory,
        showProductsOfSpecificCategory,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopifyContainer);
