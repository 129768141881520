import React, { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import LinearProgress from 'material-ui/LinearProgress';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Link } from 'react-router-dom';
import {
  get,
  set,
  reduce,
  keys,
  pickBy,
  isEmpty,
  size,
  groupBy,
  mapValues,
} from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { MoreVert } from '@material-ui/icons';
import {
  TableContainer,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Box,
  Collapse,
  Typography,
  Chip,
  Avatar,
  Menu,
  Checkbox,
} from '@material-ui/core';
import { downloadCSV } from 'utils';
import { Empty as EmptyIcon } from 'app/components/icons';
import Search from 'app/components/search';
import ActionMenu from 'app/components/actionMenu';
import { getAllAppointments, getAllBookings } from '../../api';
import styles from './styles.module.scss';
import themeStyles from '../../../../../../_export.scss';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-head': {
      color: 'black',
      backgroundColor: '#EAEEFF',
      fontSize: 16,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
    },
    '& .MuiTableCell-body': {
      color: 'black',
      fontSize: 16,
      fontWeight: 400,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
      borderBottom: 'none',
    },

    '& .options-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .MuiCheckbox-root': {
      width: 24,
      height: 24,
    },
    '& .action-menu-container': {
      width: 24,
      height: 24,
    },
  },
});

const today = Date.now();

const AppointmentsTable = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    appname,
    onAppointmentsDialogOpen,
    onAppointmentsItemChange,
    deleteAppointmentSlot,
    loading,
  } = props;
  const queryClient = useQueryClient();

  const [appointmentData, setAppointmentData] = useState({});
  const [bookingData, setBookingData] = useState({});
  const [open, setOpen] = useState({});
  const [searchText, setSearchText] = useState('');
  const [dateOrderFilter, setDateOrderFilter] = useState('desc');
  const [appointmentStatusFilter, setAppointmentStatusFilter] = useState('all');
  const [timeFilter, setTimeFilter] = useState('all');
  const [selectedMenu, setSelectedMenu] = useState({
    open: false,
    key: null,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [chunks, setChunks] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const { data, error, isLoading, isError } = useQuery(
    ['appointmentItems', { appname }],
    getAllAppointments,
  );

  const {
    data: bookingItems,
    error: bookingError,
    isLoading: bookingLoading,
  } = useQuery(['appointmentBookings', { appname }], getAllBookings);

  /** 
  ### Sort appointments by date order 
  * @param {{order:string, bookings:object}} 
  * @returns {object} sorted appointments object
  **/
  const orderAppointmentsByDate = ({ order, appointments }) =>
    Object.fromEntries(
      Object.entries(appointments).sort(([, apt1], [, apt2]) =>
        order === 'asc' ? apt1.date - apt2.date : apt2.date - apt1.date,
      ),
    );

  const getCsvData = (selected, chunks) => {
    const csvColumns = [
      t('Appointment Name'),
      t('Description'),
      t('Date/Time'),
      t('Duration'),
      t('Price'),
      t('Status'),
    ];
    let data = `${csvColumns.join(',')}\n`;
    selected.forEach(key => {
      data += `${chunks[key].name || '-'},${chunks[key].description ||
        '-'},${moment(get(chunks[key], 'date', 0)).format(`MM/DD/YY`)} ${moment(
        get(chunks[key], 'date', 0),
      ).format('HH:mm ')},${`${chunks[key].duration} min` || 'NA'},${chunks[key]
        .cost || '0'},${
        today < chunks[key].date ? t('Active') : t('Expired')
      }\n`;
    });
    return data;
  };

  useEffect(() => {
    if (data) {
      const orderedAppointmentsData = orderAppointmentsByDate({
        order: dateOrderFilter,
        appointments: data,
      });
      setAppointmentData(orderedAppointmentsData);
    } else setAppointmentData({});
  }, [data]);

  useEffect(() => {
    if (bookingItems) {
      // Step 1: Group booking data by appointmentDetail.key
      const items = JSON.parse(JSON.stringify(bookingItems));
      const grouped = groupBy(items, item => item.appointmentDetail.key);

      // Step 2: Map to get the count of items for each key
      const result = mapValues(grouped, group => group.length);

      setBookingData(result);
    } else setBookingData({});
  }, [bookingItems]);

  useEffect(() => {
    data && handleApplyFilter();
  }, [searchText, timeFilter, dateOrderFilter, appointmentStatusFilter]);
  const handleApplyFilter = async () => {
    let timeValue = [0, 'days'];
    let pastDate = 0;

    if (timeFilter && timeFilter !== 'all') {
      timeValue = timeFilter.split(':');
      pastDate = moment()
        .subtract(timeValue[0], timeValue[1])
        .valueOf();
    }

    const appointments = Object.entries(data);
    const res = appointments.filter(appointment => {
      const timeFilter = pastDate < appointment[1].date;
      const searchFilter =
        appointment[1].name.toLowerCase().includes(searchText.toLowerCase()) ||
        appointment[1].description
          .toLowerCase()
          .includes(searchText.toLowerCase());
      let statusFilter = true;

      if (appointmentStatusFilter === 'active') {
        statusFilter = today < appointment[1].date;
      } else if (appointmentStatusFilter === 'expired') {
        statusFilter = today > appointment[1].date;
      }
      return timeFilter && (searchFilter || !searchText) && statusFilter;
    });

    let filteredAppointmentData = {};
    let orderedAppointmentsData = {};
    if (!isEmpty(res)) {
      filteredAppointmentData = res.reduce(
        (object, [key, value]) => ({ ...object, [key]: value }),
        {},
      );
      orderedAppointmentsData = orderAppointmentsByDate({
        order: dateOrderFilter,
        appointments: filteredAppointmentData,
      });
    }

    setAppointmentData(orderedAppointmentsData);
  };

  useEffect(() => {
    setChunks(appointmentData);
  }, [appointmentData]);

  const onSelect = id => {
    const modifiedChunks = { ...chunks };
    const prevValue = get(modifiedChunks, `${id}.selected`, false);
    set(modifiedChunks, `${id}.selected`, !prevValue);
    setChunks(modifiedChunks);
    const selectedItems = keys(pickBy(modifiedChunks, chunk => chunk.selected));
    setSelected(selectedItems);
  };

  const selectAllAppointments = () => {
    const allIds = keys(chunks);
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: true,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected(allIds);
    setSelectAll(true);
  };

  const deselectAllAppointments = () => {
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: false,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected([]);
    setSelectAll(false);
  };

  if (isLoading || bookingLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  if (isError || bookingError) {
    return (
      <span>
        <Trans>Error:</Trans> {error.message}
      </span>
    );
  }

  const onDeleteSelected = selected => {
    Promise.all(
      selected.map(key =>
        fetch(
          `${process.env.REACT_APP_BOOKING_API}/delete-appointment/${appname}/${key}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ appname, key }),
          },
        ),
      ),
    ).then(() => {
      queryClient.invalidateQueries('appointmentItems');
    });
  };

  const handleDropDownOpen = key => {
    if (appointmentData && open[key]) {
      setOpen({ [key]: !open[key] });
    } else {
      setOpen({ [key]: true });
    }
  };

  const handleSlotDelete = async (rowCount, key, appointmentKey) => {
    const tempData = { ...appointmentData };
    if (tempData[key].slots.length === 1) {
      delete tempData[key];
    } else {
      tempData[key].slots.splice(rowCount, 1);
      tempData[key].numSlots -= 1;
    }

    setAppointmentData(tempData);
    await deleteAppointmentSlot(rowCount, appointmentKey);
  };

  const handleClick = (event, key) => {
    setSelectedMenu({ open: true, key });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedMenu({ open: false, key: null });
    setAnchorEl(null);
  };

  const handleEdit = key => {
    onAppointmentsDialogOpen(true);
    onAppointmentsItemChange(appointmentData[key]);
  };

  return (
    <div className="scroll-container">
      <div className={`row middle-xs table-heading ${styles.scrollHeader}`}>
        <div className="col-xs-2">
          <span className={styles.scrollHeader}>
            <Trans>Appointments:</Trans> {size(appointmentData)}
          </span>
        </div>
        {!isEmpty(data) ? (
          <>
            <div className="col-xs-6">
              <Search
                name="search"
                placeholder={t('Search...')}
                value={searchText}
                onChange={val => setSearchText(val)}
                onClear={() => setSearchText('')}
              />
            </div>
            <div className="col-xs-2">
              <FormControl fullWidth>
                <InputLabel htmlFor="bookings-date" style={{ left: '-15px' }}>
                  <Trans>Appointments From</Trans>
                </InputLabel>
                <Select
                  variant="standard"
                  id="bookings-date"
                  value={timeFilter}
                  onChange={e => setTimeFilter(e.target.value)}
                >
                  <MenuItem value="all">
                    <Trans>All time</Trans>
                  </MenuItem>
                  <MenuItem value="30:days">
                    <Trans>Past 30 Days</Trans>
                  </MenuItem>
                  <MenuItem value="3:months">
                    <Trans>Past 3 Months</Trans>
                  </MenuItem>
                  <MenuItem value="6:months">
                    <Trans>Past 6 Months</Trans>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-xs-2">
              <FormControl fullWidth>
                <InputLabel htmlFor="bookings-date" style={{ left: '-15px' }}>
                  <Trans>Status</Trans>
                </InputLabel>
                <Select
                  variant="standard"
                  id="bookings-status"
                  value={appointmentStatusFilter}
                  onChange={e => setAppointmentStatusFilter(e.target.value)}
                >
                  <MenuItem value="all">
                    <Trans>All</Trans>
                  </MenuItem>
                  <MenuItem value="active">
                    <Trans>Active</Trans>
                  </MenuItem>
                  <MenuItem value="expired">
                    <Trans>Expired</Trans>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {isEmpty(chunks) ? (
        <div className="empty-state">
          <EmptyIcon />
          <h5>
            <Trans>You haven't added any appointments yet.</Trans>
          </h5>
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <Trans>Click the</Trans>&nbsp;
            <AddCircleIcon />
            &nbsp;<Trans>button to add a new appointment.</Trans>
          </p>
          <p style={{ textAlign: 'center' }}>
            <Trans>
              You can add multiple appointments which will be displayed in a
              list within the app. Your app users will be able to click on an
              appointment, see appointment details and book it. You can create
              free and paid appointments.
            </Trans>
          </p>
          <p style={{ textAlign: 'center' }}>
            <Trans>
              To enable payment via the app, go to Settings and add your Stripe
              API keys.
            </Trans>{' '}
            <Link to={`/${appname}/ecommerce/bookings/settings`}>
              <Trans>Add Stripe API Keys</Trans>
            </Link>
            .{' '}
            {!props.isAppAdmin ? (
              <a
                href="https://beezer.zendesk.com/hc/en-us/articles/5842150159377-Booking-Staff-Members"
                rel="noreferrer noopener"
                target="_blank"
                style={{ color: themeStyles.primaryColor }}
              >
                <Trans>Find out more.</Trans>
              </a>
            ) : (
              ''
            )}
          </p>
        </div>
      ) : (
        <>
          <TableContainer
            component={Paper}
            style={{ maxHeight: 650, borderRadius: 15, boxShadow: 'none' }}
          >
            <Table
              stickyHeader
              aria-label="customized table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell /> */}
                  <TableCell>
                    <Trans>Appointment Name</Trans>
                  </TableCell>
                  <TableCell max-width="25%">
                    <Trans>Description</Trans>
                  </TableCell>
                  <TableCell>
                    <div className={styles.dateHeader}>
                      <Trans>Date/Time</Trans>
                      {dateOrderFilter === 'desc' ? (
                        <IconButton
                          onClick={() => setDateOrderFilter('asc')}
                          color="primary"
                          title={t('Sort by oldest')}
                        >
                          <ArrowDropUpIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => setDateOrderFilter('desc')}
                          color="primary"
                          title={t('Sort by newest')}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                  {/* <TableCell>Slots</TableCell> */}
                  <TableCell>
                    <Trans>Duration</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Price</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Status</Trans>
                  </TableCell>
                  <TableCell>
                    <div className="options-container">
                      <Checkbox
                        disableRipple
                        checked={selectAll || false}
                        onChange={(e, isChecked) =>
                          isChecked
                            ? selectAllAppointments()
                            : deselectAllAppointments()
                        }
                        style={{ color: themeStyles.primaryColor }}
                      />
                      <ActionMenu
                        selected={selected}
                        onDeleteSelected={() => onDeleteSelected(selected)}
                        onDownloadSelected={() => {
                          downloadCSV(
                            getCsvData(selected, chunks),
                            t('appointments'),
                          );
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appointmentData &&
                  Object.keys(chunks).map((key, i) => (
                    <>
                      <TableRow
                        hover
                        key={`${key}--${i}`}
                        onClick={() => {
                          handleEdit(key);
                        }}
                      >
                        {/* <TableCell component="th" scope="row">
                          {chunks[key] && chunks[key].slots && (
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleDropDownOpen(key)}
                            >
                              {open && open[key] ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          )}
                        </TableCell> */}

                        <TableCell component="th" scope="row">
                          {chunks[key].name || '-'}
                        </TableCell>
                        <TableCell width="25%" component="th" scope="row">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <span
                              style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                maxWidth: 220,
                              }}
                            >
                              {chunks[key].description || '-'}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {moment(get(chunks[key], 'date', 0)).format(
                            `MM/DD/YY`,
                          )}{' '}
                          {moment(get(chunks[key], 'date', 0)).format('HH:mm ')}
                          {/* -
                          {moment(get(appointmentData[key], 'date', 0))
                            .add(
                              'minutes',
                              get(appointmentData[key], 'duration', 0),
                            )
                            .format('HH:mm ')} */}
                        </TableCell>
                        {/* <TableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {appointmentData[key].numSlots || '1'}
                        </TableCell> */}
                        <TableCell component="th" scope="row" align="center">
                          {`${chunks[key].duration} ${t('min')}` || 'NA'}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {chunks[key].cost || '0'}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {bookingData &&
                          bookingData[key] === chunks[key].capacity ? (
                            <span className={styles.notAvailable}>
                              <Trans>Fully Booked</Trans>
                            </span>
                          ) : (
                            <>
                              {today < chunks[key].date ? (
                                <span className={styles.success}>
                                  <Trans>Active</Trans>
                                </span>
                              ) : (
                                <span className={styles.error}>
                                  <Trans>Expired</Trans>
                                </span>
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div className="options-container">
                            <Checkbox
                              disableRipple
                              checked={chunks[key].selected || false}
                              onClick={e => e.stopPropagation()}
                              onChange={e => {
                                e.stopPropagation();
                                onSelect(key);
                              }}
                              style={{ color: themeStyles.primaryColor }}
                            />
                            <ActionMenu
                              selected={[key]}
                              onEditSelected={() => handleEdit(key)}
                              onDeleteSelected={() => onDeleteSelected([key])}
                              onDownloadSelected={() =>
                                downloadCSV(
                                  getCsvData([key], chunks),
                                  t('appointments'),
                                )
                              }
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      {chunks[key] && chunks[key].slots && (
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={12}
                          >
                            <Collapse
                              in={open[key]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  component="div"
                                >
                                  <Trans>Slots Added:</Trans>
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        <Trans>Slot Capacity</Trans>
                                      </TableCell>
                                      <TableCell>
                                        <Trans>Staff members</Trans>
                                      </TableCell>
                                      <TableCell align="left">
                                        <Trans>Slots</Trans>
                                      </TableCell>
                                      <TableCell align="left">
                                        <Trans>Delete</Trans>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {chunks[key] &&
                                      chunks[key].slots.length > 0 &&
                                      chunks[key].slots.map(
                                        (slotsRow, rowCount) => (
                                          <TableRow
                                            key={`${appointmentData[key].key}-${rowCount}`}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {slotsRow.capacity}
                                            </TableCell>

                                            <TableCell>
                                              {slotsRow &&
                                              slotsRow.staffMembers ? (
                                                <>
                                                  {slotsRow.staffMembers.map(
                                                    staffData => (
                                                      <Chip
                                                        avatar={
                                                          <Avatar>
                                                            {staffData.name.substring(
                                                              0,
                                                              1,
                                                            )}
                                                          </Avatar>
                                                        }
                                                        key={staffData.name}
                                                        label={staffData.name}
                                                        clickable
                                                        color="primary"
                                                        size="small"
                                                        style={{
                                                          margin: '3px',
                                                        }}
                                                      />
                                                    ),
                                                  )}
                                                </>
                                              ) : (
                                                t('No staff member assigned')
                                              )}
                                            </TableCell>

                                            <TableCell align="left">
                                              {slotsRow.slots &&
                                                slotsRow.slots.length > 0 &&
                                                slotsRow.slots.map((_, i) => (
                                                  <Chip
                                                    label={`${slotsRow.slots[i].time}`}
                                                    size="small"
                                                    style={{ margin: '3px' }}
                                                    key={`${slotsRow.slots[i].time}-${i}`}
                                                    disabled={
                                                      slotsRow.slots[i]
                                                        .capacity ===
                                                      slotsRow.slots[i].booked
                                                    }
                                                  />
                                                ))}
                                            </TableCell>
                                            <TableCell>
                                              <IconButton
                                                aria-label={t('delete')}
                                                color="secondary"
                                                onClick={() =>
                                                  handleSlotDelete(
                                                    rowCount,
                                                    key,
                                                    chunks[key].key,
                                                  )
                                                }
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        ),
                                      )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {loading && (
            <div className={styles.bookingLoadingContainer}>
              <div>
                <LinearProgress color="#261ed5" mode="indeterminate" />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AppointmentsTable;
