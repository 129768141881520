import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RenderToLayer from 'material-ui/internal/RenderToLayer';
import { Provider } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { SimpleToolbar } from '../../../../../../../app/modules/toolbar';
import TextField from '../../../../../../../app/components/textField';
import Create from '../components/create';

import { store } from '../../../../../../../';

class CreateTab extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onTitleChange: PropTypes.func.isRequired,
  };
  renderLayer = () => {
    const { form, onCancel, onTitleChange, t } = this.props;

    return (
      <Router>
        <div className="overlay">
          <Provider store={store}>
            <SimpleToolbar onExit={onCancel} />
          </Provider>
          <Create
            title={t('Create New Tab')}
            submitLabel={t('Add Tab')}
            pending={form.pending}
            isValid={form.title.isValid}
            onSubmit={this.props.onSubmit}
            onCancel={onCancel}
          >
            <TextField
              id="tab_title"
              {...form.title}
              onChange={onTitleChange}
              placeholder={t('Tab Title')}
            />
          </Create>
        </div>
      </Router>
    );
  };

  render() {
    return (
      <RenderToLayer
        open={this.props.form.open}
        render={this.renderLayer}
        useLayerForClickAway={false}
      />
    );
  }
}

export default withTranslation()(CreateTab);
