import React from 'react';

const MarketplaceInfo = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080.000000 1080.000000"
      className="icon marketplace-info"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M3613 9089 c-61 -18 -78 -56 -113 -249 -17 -96 -35 -199 -41 -229
        l-11 -53 -948 7 -949 8 -36 -23 c-43 -27 -65 -66 -65 -117 0 -35 340 -1076
        369 -1131 6 -12 29 -31 51 -42 38 -19 59 -20 643 -20 l603 0 51 -122 51 -123
        -642 -5 c-485 -4 -646 -8 -663 -17 -83 -48 -94 -167 -22 -228 l31 -25 774 0
        c855 0 817 -3 850 63 29 56 20 100 -55 279 -39 92 -71 169 -71 172 0 3 5 6 12
        6 6 0 30 9 53 20 30 15 47 31 58 58 10 22 41 226 77 502 33 256 81 588 108
        737 l47 273 302 0 302 0 36 35 c70 70 40 199 -53 225 -46 13 -704 12 -749 -1z
        m-964 -799 l756 0 -52 -387 c-28 -214 -52 -389 -52 -390 -1 -2 -287 -3 -636
        -3 l-634 0 -90 278 c-50 152 -108 331 -131 396 l-40 119 62 -7 c33 -3 401 -6
        817 -6z"
        />
        <path
          d="M8120 9085 c-179 -34 -313 -115 -402 -243 -52 -75 -68 -140 -68 -279
        l0 -123 -67 0 c-86 0 -145 -25 -169 -72 -14 -27 -31 -200 -91 -908 -40 -481
        -71 -889 -68 -907 6 -34 60 -89 98 -98 12 -3 424 -4 915 -3 l894 3 33 37 c19
        20 37 47 40 60 3 13 -27 419 -68 904 -80 963 -75 930 -143 964 -16 8 -64 16
        -106 18 l-78 4 0 119 c0 178 -35 265 -146 370 -139 129 -373 192 -574 154z
        m255 -279 c65 -20 130 -61 162 -103 26 -34 28 -44 31 -150 l4 -113 -327 0
        -327 0 4 114 c3 110 4 116 33 152 83 102 266 146 420 100z m517 -1321 c31
        -374 57 -699 57 -722 l1 -43 -705 0 c-557 0 -705 3 -705 13 -1 25 111 1393
        116 1415 l4 22 588 -2 587 -3 57 -680z"
        />
        <path
          d="M6803 6970 c-13 -5 -135 -121 -272 -257 -137 -136 -322 -320 -410
        -407 l-160 -159 -39 25 c-109 73 -262 129 -401 148 -194 26 -439 -28 -606
        -134 -30 -20 -59 -36 -63 -36 -4 0 -190 183 -414 407 l-407 407 -60 4 c-60 4
        -62 4 -101 -36 -35 -35 -40 -45 -40 -86 0 -26 6 -59 14 -74 8 -15 194 -206
        414 -426 l400 -399 -35 -54 c-47 -74 -85 -162 -114 -263 -33 -118 -33 -335 -1
        -456 26 -97 67 -191 117 -270 l37 -57 -415 -416 c-373 -373 -416 -420 -423
        -456 -17 -92 44 -165 140 -165 l50 0 423 422 422 421 39 -25 c125 -83 295
        -142 446 -154 164 -13 370 35 521 121 44 26 83 47 87 48 4 1 186 -176 405
        -394 219 -218 409 -403 423 -412 36 -25 120 -22 154 4 51 40 69 104 46 165 -6
        15 -195 209 -420 431 l-409 404 36 57 c101 157 146 314 146 502 -1 189 -40
        329 -139 492 l-37 60 415 417 c284 284 418 425 422 444 4 15 2 45 -4 66 -21
        78 -110 122 -187 91z m-1197 -946 c361 -120 547 -513 409 -864 -73 -186 -247
        -341 -440 -390 -513 -131 -964 366 -779 859 85 225 308 399 539 420 88 8 207
        -3 271 -25z"
        />
        <path
          d="M2225 6571 c-77 -36 -115 -95 -115 -181 0 -60 13 -96 48 -133 98
        -104 262 -83 330 43 20 38 23 55 19 104 -12 142 -154 226 -282 167z"
        />
        <path
          d="M3145 6571 c-157 -72 -152 -299 7 -366 86 -35 180 -9 241 68 25 30
        32 49 35 96 5 74 -10 120 -53 162 -60 59 -154 75 -230 40z"
        />
        <path
          d="M7595 3810 c-33 -13 -66 -56 -76 -97 -5 -21 -9 -124 -9 -230 l0 -192
        -225 -3 -225 -3 -31 -31 c-23 -23 -32 -43 -36 -75 -3 -26 33 -322 87 -714 73
        -540 95 -675 111 -701 41 -66 3 -64 990 -64 957 0 927 -2 976 51 12 13 26 44
        31 69 25 113 185 1347 179 1373 -4 15 -20 42 -36 60 l-29 32 -231 3 -231 3 0
        215 c0 238 -4 259 -63 294 -31 19 -54 20 -597 19 -311 0 -574 -4 -585 -9z
        m975 -390 l0 -130 -390 0 -390 0 0 130 0 130 390 0 390 0 0 -130z m505 -427
        c-8 -42 -135 -988 -135 -1001 0 -9 -188 -12 -760 -12 -418 0 -760 3 -760 7 0
        10 -129 963 -135 1001 l-6 32 900 0 901 0 -5 -27z"
        />
        <path
          d="M7600 2757 c-74 -26 -96 -102 -88 -312 4 -107 8 -126 28 -155 43 -60
        122 -76 181 -37 60 40 69 72 69 247 0 128 -3 161 -18 189 -32 64 -103 92 -172
        68z"
        />
        <path
          d="M8133 2759 c-39 -12 -80 -59 -89 -103 -3 -19 -4 -101 -2 -183 3 -165
        10 -186 71 -223 59 -36 136 -18 178 41 23 33 24 41 24 199 0 180 -8 216 -54
        249 -34 24 -87 33 -128 20z"
        />
        <path
          d="M8666 2760 c-34 -11 -63 -36 -82 -72 -18 -34 -20 -338 -3 -371 52
        -101 175 -116 234 -29 24 36 25 41 25 211 0 200 -8 226 -80 255 -45 18 -52 18
        -94 6z"
        />
        <path
          d="M1514 3671 c-65 -40 -64 -34 -64 -377 0 -307 0 -311 23 -345 14 -22
        38 -39 65 -49 l42 -14 2 -552 3 -552 25 -27 c52 -57 11 -55 1022 -55 613 0
        946 4 970 10 24 7 48 24 65 47 l28 36 3 546 3 546 42 20 c23 11 52 35 64 54
        23 33 23 40 23 328 0 326 -3 347 -62 383 -33 20 -47 20 -1128 20 -1060 0
        -1096 -1 -1126 -19z m2046 -381 l0 -130 -920 0 -920 0 0 130 0 130 920 0 920
        0 0 -130z m-140 -855 l0 -455 -785 0 -785 0 0 455 0 455 785 0 785 0 0 -455z"
        />
        <path
          d="M2455 2756 c-98 -44 -113 -169 -29 -235 25 -20 39 -21 210 -21 l183
        0 35 31 c20 17 40 46 45 65 19 70 -30 154 -100 168 -60 12 -312 6 -344 -8z"
        />
      </g>
    </svg>
  );
};

export default MarketplaceInfo;
