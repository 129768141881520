import {
  plansAppSumo as appsumoPlans,
  DEFAULT_WHATSAPP_CREDIT,
} from '../plans';

/**
 * ### Get the default whatsapp credits for an account
 * @param {{isAppAdmin: boolean, trialExpired: boolean, appSubscription: string}} accountDetails
 * @returns {number} returns the default whatsapp credits
 */
export const getDefaultWhatsappCredits = ({
  isAppAdmin,
  trialExpired,
  appSubscription,
}) => {
  let whatsappSMSCredit = 0;
  //if subscription exists get the credits amount in subscription
  if (appSubscription) {
    if (appsumoPlans[appSubscription]) {
      whatsappSMSCredit = appsumoPlans[appSubscription].whatsapp_sms_credit;
    } else {
      whatsappSMSCredit = DEFAULT_WHATSAPP_CREDIT;
    }
  } else if (!trialExpired) {
    whatsappSMSCredit = DEFAULT_WHATSAPP_CREDIT;
  }
  if (isAppAdmin) {
    whatsappSMSCredit = 0;
  }
  return whatsappSMSCredit;
};
