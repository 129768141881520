import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import SidebarHeader from './SidebarHeader';

const mapStateToProps = (state, ownProps) => {
  const { item, component, page } = queryString.parse(ownProps.location.search);
  const pageData = state.editor.data.alwaysData[page];
  const componentData = state.editor.data.alwaysData[component];
  const lazyData =
    componentData && state.editor.data.lazyData[componentData.lazy_data];
  const itemData = lazyData && lazyData.chunks && lazyData.chunks[item];

  return {
    ...ownProps,
    page: pageData,
    component: componentData,
    item: itemData,
  };
};

export default withRouter(connect(mapStateToProps)(SidebarHeader));
