import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Thing = ({ children, className }) => (
  <div className={`thing ${className}`}>{children}</div>
);

Thing.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Thing.defaultProps = {
  className: '',
};

export default Thing;
