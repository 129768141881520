import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import TextField from '../../../../app/components/textField';
import { SimpleToolbar } from '../../../../app/modules/toolbar';
import { RaisedButton } from '../../../../app/components/buttons';

import { store } from '../../../../';

import './styles.css';
import themeStyles from '../../../../_export.scss';
const styles = {
  buttonRoot: {
    margin: '25px',
  },
  ForgotLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.primaryTextColor,
    padding: '0 30px',
    textTransform: 'capitalize',
  },
  textFieldUnderlineStyle: {
    borderColor: themeStyles.mainGrey,
  },
  textFieldLabelStyle: {
    color: themeStyles.mainGrey,
    fontSize: '20px',
  },
};
class ForgottenPassword extends Component {
  static propTypes = {
    email: PropTypes.object.isRequired,
    pending: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    onEmailChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    clearForm: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.clearForm();
  }

  render() {
    const { email, pending, success, onEmailChange, onSubmit } = this.props;

    return (
      <Router>
        <div className="viewport forgotten-password">
          <Provider store={store}>
            <SimpleToolbar />
          </Provider>
          <div className="forgotten-password-form flex-1 flex-align-center justify-center">
            {!success && (
              <div>
                <TextField
                  id="email"
                  type="text"
                  placeholder="Your Email"
                  {...email}
                  onChange={onEmailChange}
                  labelStyle={styles.textFieldLabelStyle}
                  underlineStyle={styles.textFieldUnderlineStyle}
                  required
                />

                <RaisedButton
                  label="Send Recovery Link"
                  backgroundColor={themeStyles.secondaryTextColor}
                  disabledBackgroundColor={themeStyles.primaryColorLight}
                  style={styles.buttonRoot}
                  labelStyle={styles.ForgotLabelStyle}
                  onClick={onSubmit}
                  disabled={pending}
                />
              </div>
            )}
            {success && (
              <div className="success-message">
                <p>
                  If you have an account with us you will receive an email
                  shortly.
                </p>
              </div>
            )}
          </div>
        </div>
      </Router>
    );
  }
}

export default ForgottenPassword;
