import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { components, plans } from '../../../../../../../plans';

const getPlan = componentType => {
  if (!componentType) return plans.accelerate;
  const component = components[componentType];
  if (!component) return plans.accelerate;

  return plans[component.plan || 'accelerate'];
};

const Disabled = props => {
  const { t } = useTranslation();

  return (
    <Link
      styles={{
        cursor: 'pointer',
        display: 'block',
        padding: '20px 40px',
        textAlign: 'center',
      }}
      to={`/${props.match.params.appname}/choose_plan`}
    >
      <h3>
        <Trans>Component Disabled</Trans>
      </h3>
      <p>
        <Trans>The</Trans> {props.component.type}{' '}
        <Trans>component is not available on your current plan.</Trans>
      </p>
      <p>
        <img
          src={getPlan(props.component.type).icon}
          style={{ width: 110 }}
          alt=""
        />
      </p>
      <p>
        <Trans>Upgrade to the</Trans>{' '}
        {props.subscription
          ? `Tier 2 ${t('or higher')}`
          : getPlan(props.component.type).title}{' '}
        <Trans>plan to use this component.</Trans>
      </p>
    </Link>
  );
};

Disabled.propTypes = {
  component: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(Disabled);
