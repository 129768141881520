import React from 'react';
import PropTypes from 'prop-types';

import { Empty as EmptyIcon } from '../icons';
import Table from '../table';
import './styles.scss';

const ResultsTable = ({
  error,
  pending,
  emptyMessage,
  items,
  chunks,
  columns,
  onRowTouchTap,
  sortTable,
  order,
  colSorted,
  colsToSort,
  optionalMessage,
  pgmolTable,
  isCustom,
}) => {
  return error && !pending ? (
    <div className="empty-state">
      <EmptyIcon className="empty-icon" />
      <p>{emptyMessage}</p>
      {optionalMessage}
    </div>
  ) : (
    <Table
      items={items}
      chunks={chunks}
      loading={pending}
      cols={columns}
      onRowTouchTap={onRowTouchTap}
      sortTable={sortTable}
      order={order}
      colSorted={colSorted}
      colsToSort={colsToSort}
      pgmolTable={pgmolTable}
      isCustom={isCustom}
    />
  );
};
ResultsTable.propTypes = {
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  items: PropTypes.array,
  chunks: PropTypes.object,
  columns: PropTypes.array,
  onRowTouchTap: PropTypes.func.isRequired,
  sortTable: PropTypes.func,
  order: PropTypes.string,
  colSorted: PropTypes.string,
  colsToSort: PropTypes.array,
  optionalMessage: PropTypes.node,
  pgmolTable: PropTypes.bool,
  isCustom: PropTypes.bool,
};

ResultsTable.defaultProps = {
  error: null,
  items: [],
  chunks: {},
  columns: [],
  order: 'desc',
  colSorted: null,
  colsToSort: [],
  sortTable: null,
  optionalMessage: null,
  pgmolTable: false,
  isCustom: false,
};

export default ResultsTable;
