import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RenderToLayer from 'material-ui/internal/RenderToLayer';
import { Tab, Tabs } from 'material-ui/Tabs';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { PLANS_WITH_ADDONS, TIER_1_PLAN_ID } from 'plans/constants';
import { SimpleToolbar } from 'app/modules/toolbar';
import { RadioMenu } from 'app/components/radioMenu';
import { objectToArray } from '../../../../../../../utils/object';
import {
  HS_FEATURES_ENABLED_APPS,
  componentInstructionLinks,
  FOOTBALL_ACADEMY_APPS,
  PGMOL_APPS,
} from '../../../../../../../config';
import CreateComponentItem from './CreateComponentItem';
import Create from '../components/create';
import firebase from '../../../../../../../firebase';
import themeStyles from '../../../../../../../_export.scss';

import { store } from '../../../../../../../';

import {
  Text,
  Button,
  Delegates,
  LoyaltyIcon,
  FoodIcon,
  File,
  Form,
  IframeWidget,
  Header,
  Image,
  Media,
  MarketplaceIcon,
  Notification,
  PopUp,
  PayNow,
  Rating,
  Roster,
  Schedule,
  Slider,
  Voting,
  Appointedd,
  Countdown,
  EventListIcon,
  FacebookIcon,
  GoogleIcon,
  InstagramIcon,
  ShopifyIcon,
  WoocommerceIcon,
  TwitterIcon,
  VimeoIcon,
  WordpressIcon,
  YoutubeIcon,
  LivestreamIcon,
  ForumIcon,
  RadioStreaming,
  Workouthistory,
  Workouttimer,
  ZoomIcon,
  Booking,
  FeedbackIcon,
  HtmlIcon,
  PDFViewer,
  SubscriptionPlans,
  BlogIcon,
  TitleIcon,
  PlMatchIcon,
  RegionalPerformanceIcon,
  EventOrganizerIcon,
} from './svg';

import { components, plans, plansWixWeebly } from '../../../../../../../plans';
import { specialAddonComponents } from '../../../../../../../plans/planComponents';
import Search from '../../../../../../../app/components/search';

const styles = {
  inkBarStyle: { display: 'none' },
  contentContainerStyle: {
    backgroundColor: '#fff',
    borderTop: '1px solid rgb(196, 196, 196)',
    paddingTop: 20,
  },
  buttonStyle: {
    fontWeight: 'bold',
    fontSize: '1.01rem',
  },
  tabItemContainerStyle: {
    justifyContent: 'space-around',
  },
};

const Overlay = styled.div`
  .radio-menu {
    width: 100%;

    .tabs > div:first-child,
    .tabs > div:nth-child(2) {
      margin: 0 auto;
      width: 650px !important;
    }

    .tabs > div:first-child button,
    .tabs > div:first-child button > div {
      width: fit-content !important;
    }

    .tabs > div:first-child button::before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 4px;
      background-color: ${themeStyles.primaryColor};
      transform-origin: bottom right;
      transform: scaleX(0);
      transition: transform 0.5s ease;
    }

    .tabs > div:first-child button.active:before {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }
`;

Overlay.defaultProps = {
  className: 'overlay',
};

const planNames = Object.keys(plans).concat(Object.keys(plansWixWeebly));
const getNextInvoiceAmount = ({ appname, accountId, subscriptionItem }) => {
  const subscription = subscriptionItem.items[appname];
  const subscriptionId = subscription.id;

  const payload = {
    subscriptionId,
    accountId,
  };

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      const options = {
        method: 'post',
        body: JSON.stringify(payload),
        headers,
        mode: 'cors',
      };
      return fetch(
        `${process.env.REACT_APP_PAYMENT_API}/get_next_invoice_amount`,
        options,
      )
        .then(res => res.json())
        .then(body => {
          const { amount } = body;
          return amount;
        })
        .catch(error => null);
    });
};

const ALLOW_ACCOUNT = '-Mklu-CA6MKMuE_CgW68';

const oneOfOrIncludes = planNames => {
  return (props, propName, componentName) => {
    const propValue = props[propName];
    if (!propValue) {
      return new Error(
        `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${propValue}\`.`,
      );
    }

    if (planNames.includes(propValue)) {
      return null; // Valid value
    }

    if (planNames.some(planName => propValue.includes(planName))) {
      return null; // Valid substring
    }

    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. ` +
        `Expected one of ${JSON.stringify(
          planNames,
        )} or a string including one of these values as a substring.`,
    );
  };
};

class CreateComponent extends Component {
  components = () => {
    const { t } = this.props;
    return [
      {
        label: 'Header',
        value: 'Header',
        text: t(
          'Add a promotional image with a combination of text and a button',
        ),
        svg: <Header />,
        disabled: 'Header',
        instructionLink: componentInstructionLinks.header.link,
        instructionSubAdminLink: componentInstructionLinks.header.subAdminLink,
      },
      {
        label: 'Image',
        value: 'Image',
        text: t(
          'Display a graphic to your audience through the use of an image.',
        ),
        svg: <Image />,
        disabled: 'Image',
        instructionLink: componentInstructionLinks.image.link,
        instructionSubAdminLink: componentInstructionLinks.image.subAdminLink,
      },
      {
        label: 'Text',
        value: 'Text',
        text: t('Engage your audience by adding text to your page.'),
        svg: <Text />,
        disabled: 'Text',
        instructionLink: componentInstructionLinks.text.link,
        instructionSubAdminLink: componentInstructionLinks.text.subAdminLink,
      },
      {
        label: 'Slider',
        value: 'Slider',
        text: t(
          'Add an image carousel to your app, with editable text and buttons.',
        ),
        svg: <Slider />,
        disabled: 'Slider',
        instructionLink: componentInstructionLinks.slider.link,
        instructionSubAdminLink: componentInstructionLinks.slider.subAdminLink,
      },
      {
        label: 'Radio Streaming',
        value: 'RadioStreaming',
        text: t(
          'Listen to live stream of Radio channel that plays throughout the app.',
        ),
        svg: <RadioStreaming />,
        disabled: 'RadioStreaming',
        instructionLink: componentInstructionLinks.radioStreaming.link,
        instructionSubAdminLink:
          componentInstructionLinks.radioStreaming.subAdminLink,
      },
      {
        label: 'Button',
        value: 'Button',
        text: t('Insert a button into your mobile app.'),
        svg: <Button />,
        disabled: 'Button',
        instructionLink: componentInstructionLinks.button.link,
        instructionSubAdminLink: componentInstructionLinks.button.subAdminLink,
      },
      {
        label: 'Delegates',
        value: 'Delegates',
        text: t(
          'Display a list of delegates at an event or use this to display a list of staff or people.',
        ),
        svg: <Delegates />,
        disabled: 'Delegates',
        instructionLink: componentInstructionLinks.delegates.link,
        instructionSubAdminLink:
          componentInstructionLinks.delegates.subAdminLink,
        industries: ['event'],
      },
      {
        label: 'Loyalty & Attendance Card',
        value: 'Loyalty',
        text: t(
          'Display a Loyalty & Attendance Card to help with customer relationships and retention.',
        ),
        svg: <LoyaltyIcon />,
        disabled: 'Loyalty',
        instructionLink: componentInstructionLinks.loyalty.link,
        instructionSubAdminLink: componentInstructionLinks.loyalty.subAdminLink,
      },
      {
        label: 'Food Ordering',
        value: 'Food',
        text: t(
          'A component that lets you sell food and receive payments via stripe.',
        ),
        svg: <FoodIcon />,
        disabled: 'Food',
        instructionLink: componentInstructionLinks.foodOrder.link,
        instructionSubAdminLink:
          componentInstructionLinks.foodOrder.subAdminLink,
        industries: ['cafe', 'pub / bar', 'reopening pub / restaurant'],
      },

      {
        label: 'Forum',
        value: 'Forum',
        text: t('Add a forum where your app users can start discussions.'),
        svg: <ForumIcon />,
        disabled: 'Forum',
        instructionLink: componentInstructionLinks.forum.link,
        instructionSubAdminLink: componentInstructionLinks.forum.subAdminLink,
      },
      {
        label: 'Form',
        value: 'Form',
        text: t(
          'Adding a form lets you capture key information from your audience.',
        ),
        svg: <Form />,
        disabled: 'Form',
        instructionLink: componentInstructionLinks.form.link,
        instructionSubAdminLink: componentInstructionLinks.form.subAdminLink,
      },
      {
        label: 'Rating',
        value: 'Rating',
        text: t('Add this component to collect ratings and comments.'),
        svg: <Rating />,
        disabled: 'Rating',
        instructionLink: componentInstructionLinks.rating.link,
        instructionSubAdminLink: componentInstructionLinks.rating.subAdminLink,
      },
      {
        label: 'Roster',
        value: 'Roster',
        text: t(
          'Add this component to preview team members and help them connect.',
        ),
        svg: <Roster />,
        disabled: 'Roster',
        instructionLink: componentInstructionLinks.roster.link,
        instructionSubAdminLink: componentInstructionLinks.roster.subAdminLink,
      },
      {
        label: 'Schedule',
        value: 'Schedule',
        text: t('Keep your audience informed by adding your event schedule.'),
        svg: <Schedule />,
        disabled: 'Schedule',
        instructionLink: componentInstructionLinks.schedule.link,
        instructionSubAdminLink:
          componentInstructionLinks.schedule.subAdminLink,
      },
      {
        label: 'File',
        value: 'File',
        text: t(
          'Deliver great information more easily by adding a file to your app for users to download.',
        ),
        svg: <File />,
        disabled: 'File',
        instructionLink: componentInstructionLinks.file.link,
        instructionSubAdminLink: componentInstructionLinks.file.subAdminLink,
      },
      {
        label: 'PDF Library',
        value: 'PDFViewer',
        text: t(
          'Build a library of PDFs that users can read and browse through.',
        ),
        svg: <PDFViewer />,
        disabled: 'PDFViewer',
        instructionLink: componentInstructionLinks.pdfViewer.link,
        instructionSubAdminLink:
          componentInstructionLinks.pdfViewer.subAdminLink,
      },
      {
        label: 'Media',
        value: 'Media',
        text: t(
          'Deliver great information more easily by adding a media to your app for users to download, listen and watch.',
        ),
        svg: <Media />,
        disabled: 'Media',
        instructionLink: componentInstructionLinks.media.link,
        instructionSubAdminLink: componentInstructionLinks.media.subAdminLink,
      },
      {
        label: 'Vote',
        value: 'Vote',
        text: t('Engage your audience with real time voting'),
        svg: <Voting />,
        disabled: 'Vote',
        instructionLink: componentInstructionLinks.vote.link,
        instructionSubAdminLink: componentInstructionLinks.vote.subAdminLink,
      },
      {
        label: 'Workout Timer',
        value: 'Timer',
        text: t('Workout Timer'),
        svg: <Workouttimer />,
        disabled: 'Timer',
        instructionLink: componentInstructionLinks.workout.link,
        instructionSubAdminLink: componentInstructionLinks.workout.subAdminLink,
        industries: ['health & fitness'],
      },
      {
        label: 'iFrame Widget',
        value: 'IFrame',
        text: t(
          'Drive traffic to your eCommerce or booking engines by adding external links.',
        ),
        svg: <IframeWidget />,
        disabled: 'IFrame',
        instructionLink: componentInstructionLinks.iframe.link,
        instructionSubAdminLink: componentInstructionLinks.iframe.subAdminLink,
      },
      {
        label: 'Notification',
        value: 'Notification',
        text: t(
          'Display a feed containing your push notifications within your app',
        ),
        svg: <Notification />,
        disabled: 'Notification',
        instructionLink: componentInstructionLinks.notification.link,
        instructionSubAdminLink:
          componentInstructionLinks.notification.subAdminLink,
      },
      {
        label: 'Countdown',
        value: 'Countdown',
        text: t('Countdown to an important Event.'),
        svg: <Countdown />,
        disabled: 'Countdown',
        instructionLink: componentInstructionLinks.countdown.link,
        instructionSubAdminLink:
          componentInstructionLinks.countdown.subAdminLink,
      },
      {
        label: 'Popup',
        value: 'Popup',
        text: t('Add popup to your application'),
        svg: <PopUp />,
        disabled: 'Popup',
        instructionLink: componentInstructionLinks.popup.link,
        instructionSubAdminLink: componentInstructionLinks.popup.subAdminLink,
      },
      {
        label: 'Pay Now',
        value: 'PayNow',
        text: t('Add pay now button to your application'),
        svg: <PayNow />,
        disabled: 'PayNow',
        instructionLink: componentInstructionLinks.payNow.link,
        instructionSubAdminLink: componentInstructionLinks.payNow.subAdminLink,
      },
      {
        label: 'Image Gallery',
        value: 'Gallery',
        text: t(
          'Display a graphic to your audience through the use of an image.',
        ),
        svg: <Image />,
        disabled: 'Gallery',
        instructionLink: componentInstructionLinks.imageGallery.link,
        instructionSubAdminLink:
          componentInstructionLinks.imageGallery.subAdminLink,
      },
      {
        label: 'Event Organizer',
        value: 'EventOrganizers',
        text: t('All users to see event organizers list.'),
        svg: <Form />,
        disabled: 'EventOrganizers',
        instructionLink: componentInstructionLinks.eventOrganizer.link,
        instructionSubAdminLink:
          componentInstructionLinks.eventOrganizer.subAdminLink,
      },
      {
        label: 'Event Calendar',
        value: 'EventCalendar',
        text: t('All users to filter a list of events and courses'),
        svg: <Form />,
        disabled: 'EventCalendar',
        instructionLink: componentInstructionLinks.eventCalendar.link,
        instructionSubAdminLink:
          componentInstructionLinks.eventCalendar.subAdminLink,
      },
      {
        label: 'Subscription Plans',
        value: 'SubscriptionPlans',
        text: t('Display a list of subscription plans'),
        svg: <SubscriptionPlans />,
        disabled: 'SubscriptionPlans',
        instructionLink: componentInstructionLinks.subscriptionPlans.link,
        instructionSubAdminLink:
          componentInstructionLinks.subscriptionPlans.subAdminLink,
      },

      {
        label: 'Video Feedback',
        value: 'Feedback',
        text: t(
          'Allow your users to upload videos in the app and receive feedback from the app admin.',
        ),
        svg: <FeedbackIcon />,
        disabled: 'Feedback',
        instructionLink: componentInstructionLinks.feedback.link,
        instructionSubAdminLink:
          componentInstructionLinks.feedback.subAdminLink,
      },
      // {
      //   label: 'Title Component',
      //   value: 'Title',
      //   text: t('Add Title Component'),
      //   svg: <EventListIcon />,
      //   disabled: 'Title',
      // },
      // {
      //   label: 'Blog',
      //   value: 'Blog',
      //   text: t('Add Blog'),
      //   svg: <EventListIcon />,
      //   disabled: 'Blog',
      // },
    ];
  };

  integrations = () => {
    const { t } = this.props;
    return [
      {
        label: 'Facebook Page',
        value: 'FacebookPage',
        text: t(
          'Engage with your user community by embedding your Facebook page into your app.',
        ),
        svg: <FacebookIcon />,
        disabled: 'FacebookPage',
        instructionLink: componentInstructionLinks.facebookPage.link,
        instructionSubAdminLink:
          componentInstructionLinks.facebookPage.subAdminLink,
      },
      {
        label: 'Google Maps',
        value: 'GoogleMaps',
        text: t('Display a location on a map within your app.'),
        svg: <GoogleIcon />,
        disabled: 'GoogleMaps',
        instructionLink: componentInstructionLinks.googleMaps.link,
        instructionSubAdminLink:
          componentInstructionLinks.googleMaps.subAdminLink,
      },
      {
        label: 'Instagram Feed',
        value: 'InstagramFeed',
        text: t(
          'Share your awesome Instagram content with your app users and grow your audience',
        ),
        svg: <InstagramIcon />,
        disabled: 'InstagramFeed',
        instructionLink: componentInstructionLinks.instagramFeed.link,
        instructionSubAdminLink:
          componentInstructionLinks.instagramFeed.subAdminLink,
      },
      {
        label: 'Twitter',
        value: 'Twitter',
        text: t(
          'Encourage discussion and participation by adding a Twitter actions.',
        ),
        svg: <TwitterIcon />,
        disabled: 'Twitter',
        instructionLink: componentInstructionLinks.twitter.link,
        instructionSubAdminLink: componentInstructionLinks.twitter.subAdminLink,
      },
      {
        label: 'WordPress',
        value: 'WordPress',
        text: t('Connect your WordPress blog to your mobile app.'),
        svg: <WordpressIcon />,
        disabled: 'WordPress',
        instructionLink: componentInstructionLinks.wordPress.link,
        instructionSubAdminLink:
          componentInstructionLinks.wordPress.subAdminLink,
      },
      {
        label: 'YouTube',
        value: 'YouTube',
        text: t(
          'Deliver great content by adding playlists or individual videos.',
        ),
        svg: <YoutubeIcon />,
        disabled: 'YouTube',
        instructionLink: componentInstructionLinks.youTube.link,
        instructionSubAdminLink: componentInstructionLinks.youTube.subAdminLink,
      },
      {
        label: 'Livestream',
        value: 'Livestream',
        text: t(
          'Engage with your audience by livestreaming to them and allowing them to comment.',
        ),
        svg: <LivestreamIcon />,
        disabled: 'Livestream',
        instructionLink: componentInstructionLinks.liveStream.link,
        instructionSubAdminLink:
          componentInstructionLinks.liveStream.subAdminLink,
      },
      {
        label: 'Canva',
        value: 'Canva',
        text: t('Embed Canva to your application'),
        svg: <IframeWidget />,
        disabled: 'Canva',
        instructionLink: componentInstructionLinks.canva.link,
        instructionSubAdminLink: componentInstructionLinks.canva.subAdminLink,
      },
      {
        label: 'Vimeo Video',
        value: 'VimeoVideo',
        text: t(
          'Deliver great content by adding channels or individual videos.',
        ),
        svg: <VimeoIcon />,
        disabled: 'VimeoVideo',
        instructionLink: componentInstructionLinks.vimeoVideo.link,
        instructionSubAdminLink:
          componentInstructionLinks.vimeoVideo.subAdminLink,
      },
      {
        label: 'Zoom',
        value: 'Zoom',
        text: t('Have a video conference with your users powered by zoom.'),
        svg: <ZoomIcon />,
        disabled: 'Zoom',
        instructionLink: componentInstructionLinks.zoom.link,
        instructionSubAdminLink: componentInstructionLinks.zoom.subAdminLink,
      },
      {
        label: 'Custom HTML',
        value: 'Html',
        text: t('Embed custom HTML, CSS and JS scripts.'),
        svg: <HtmlIcon />,
        disabled: 'Html',
        instructionLink: componentInstructionLinks.html.link,
        instructionSubAdminLink: componentInstructionLinks.html.subAdminLink,
      },
    ];
  };

  booking = () => {
    const { t } = this.props;
    return [
      {
        label: 'Appointedd',
        value: 'Appointedd',
        text: t('Connect an Appointedd booking app to your mobile app.'),
        svg: <Appointedd />,
        disabled: 'Appointedd',
        instructionLink: componentInstructionLinks.appointedd.link,
        instructionSubAdminLink:
          componentInstructionLinks.appointedd.subAdminLink,
      },
      {
        label: 'Booking',
        value: 'Appointment',
        text: t('Appointment booking functionality'),
        svg: <Booking />,
        disabled: 'Appointment',
        instructionLink: componentInstructionLinks.appointment.link,
        instructionSubAdminLink:
          componentInstructionLinks.appointment.subAdminLink,
      },
    ];
  };

  ecommerce = () => {
    const { t } = this.props;
    return [
      {
        label: 'Shopify',
        value: 'Shopify',
        text: t('Connect a Shopify store to your mobile app.'),
        svg: <ShopifyIcon />,
        disabled: 'Shopify',
        instructionLink: componentInstructionLinks.shopify.link,
        instructionSubAdminLink: componentInstructionLinks.shopify.subAdminLink,
      },
      {
        label: 'Woocommerce',
        value: 'Woocommerce',
        text: t('Connect a Woocommerce store to your mobile app.'),
        svg: <WoocommerceIcon />,
        disabled: 'Woocommerce',
        instructionLink: componentInstructionLinks.woocommerce.link,
        instructionSubAdminLink:
          componentInstructionLinks.woocommerce.subAdminLink,
      },
    ];
  };

  marketplace = () => {
    const { t } = this.props;
    return [
      {
        label: 'Marketplace Products & Services',
        value: 'Marketplace',
        text: t(
          'A component that displays all approved products and services added by vendors in your Marketplace app. For more info, navigate to Business Tools -> Marketplace in your Beezer Dashboard.',
        ),
        svg: <MarketplaceIcon />,
        disabled: 'Marketplace',
        instructionLink: componentInstructionLinks.marketplace.link,
        instructionSubAdminLink:
          componentInstructionLinks.marketplace.subAdminLink,
        instructionLink: componentInstructionLinks.marketplace.link,
      },
    ];
  };

  industries = () => {
    const { t } = this.props;
    return [
      t('Agency / Consulting'),
      t('Blank App'),
      t('Cafe'),
      t('Charity'),
      t('Educational'),
      t('Entertainer / Artist'),
      t('Event'),
      t('Health & Fitness'),
      t('Maintenance Service'),
      t('Online Training Program'),
      t('Pub / Bar'),
      t('Real Estate'),
      t('Religious Organisation'),
      t('Reopening Pub / Restaurant'),
      t('Retail'),
      t('Salon'),
      t('Sports Club / Team'),
      t('Wedding'),
    ].reduce((base, label) => ({ ...base, [label]: false }), {});
  };

  state = {
    activeTab: 'components',
    searchValue: '',
    filteredComponents: this.components(),
    filteredIntegrations: this.integrations(),
    filteredBooking: this.booking(),
    filteredEcommerce: this.ecommerce(),
    filteredMarketplace: this.marketplace(),
    industries: this.industries(),
    filteredIndustries: [],
    nextInvoiceAmount: 0,
  };

  static propTypes = {
    open: PropTypes.bool,
    pending: PropTypes.bool,
    type: PropTypes.string.isRequired,
    onTypeChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
    getAllComponents: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    subscription: oneOfOrIncludes(planNames).isRequired,
    match: PropTypes.object.isRequired,
    diyComponents: PropTypes.object,
    getSpecialComponents: PropTypes.func.isRequired,
    specialAddons: PropTypes.array.isRequired,
    accountId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    open: false,
    pending: false,
    diyComponents: null,
  };

  async componentDidMount() {
    this.props.onLoad();
    this.props.getAllComponents();
    await this.props.getSpecialComponents();
    this.props.getAuth();
    if (this.props.subscription && this.props.subscription.includes('diy')) {
      getNextInvoiceAmount({
        appname: this.props.appname,
        accountId: this.props.accountId,
        subscriptionItem: this.props.subscriptionItem,
      }).then(amount => {
        this.setState({
          nextInvoiceAmount: amount,
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.payment.requiresAuthentication !==
        this.props.payment.requiresAuthentication &&
      prevProps.payment.paymentIntentClientSecret !==
        this.props.payment.paymentIntentClientSecret &&
      prevProps.payment.paymentType !== this.props.payment.paymentType &&
      this.props.payment.paymentType === 'addon'
    ) {
      this.triggerStripeAuth();
    }
    if (prevProps.initialSearchValue !== this.props.initialSearchValue) {
      this.onSearchChange(this.props.initialSearchValue);
    }
  }

  triggerStripeAuth = async () => {
    const { error } = await this.props.stripe.confirmCardPayment(
      this.props.payment.paymentIntentClientSecret,
    );

    if (!error) {
      const currency = this.props.subscription.split('-')[1];

      this.props.updateAddOnSubscription(
        this.props.payment.componentToAddon,
        currency,
      );
      this.props.addOnPaymentSucceeded();
    }
    if (error) {
      this.props.addOnPaymentFailed(error);
    }
  };

  hasExpired = component => {
    const isSpecialComponent = this.props.specialAddons.filter(
      item => item.component === component,
    );

    return (
      isSpecialComponent.length > 0 &&
      isSpecialComponent[0].hasFreeTrial &&
      Date.now() > isSpecialComponent[0].expiryDate
    );
  };

  isDisabled = component => {
    if (this.props.accountId === ALLOW_ACCOUNT && component === 'PDFViewer') {
      return false;
    }

    //If account is appsumo account and component is of type special addon and user account has no special addon
    if (
      this.props.isAppSumAccount &&
      specialAddonComponents.includes(component) &&
      !this.props.specialAddons.some(item => item.component === component)
    ) {
      return true;
    }

    //If account is appsumo account and component is of type special addon and user account has this special addon with an active subscription

    if (
      this.props.isAppSumAccount &&
      specialAddonComponents.includes(component) &&
      this.props.specialAddons.some(item => {
        return (
          item.component === component && item.hasActiveSubscription === true
        );
      })
    ) {
      return false;
    }

    //If account is appsumo account and component is of type special addon and user account has this special addon which has free trial that has expired with not active subscription
    if (
      this.props.isAppSumAccount &&
      specialAddonComponents.includes(component) &&
      this.props.specialAddons.some(item => {
        return (
          item.component === component &&
          item.hasActiveSubscription !== true &&
          item.hasFreeTrial &&
          Date.now() > item.expiryDate
        );
      })
    ) {
      return true;
    }

    if (
      this.props.isAppSumAccount &&
      this.props.subscription !== TIER_1_PLAN_ID
    ) {
      return false;
    }
    let subscriptions;
    if (this.props.subscription && this.props.subscription.includes('diy'))
      subscriptions = 'diy';
    if (this.props.subscription && this.props.subscription.includes('deluxe'))
      subscriptions = 'deluxe';

    if (
      this.props.subscription &&
      this.props.subscription.includes('something')
    )
      subscriptions = 'something';

    if (PLANS_WITH_ADDONS.includes(subscriptions || this.props.subscription)) {
      if (
        plans[subscriptions || this.props.subscription].components.includes(
          component,
        )
      ) {
        return false;
      } else if (this.props.diyComponents) {
        const subscribedComponents = objectToArray(this.props.diyComponents);
        const currentDate = moment();
        const componentItem = subscribedComponents.find(
          item => item.id === component,
        );

        if (componentItem) {
          if (
            componentItem.value.status === 'deleted' &&
            !currentDate.isAfter(
              moment(componentItem.value.created).add(1, 'month'),
            )
          ) {
            return false;
          } else if (componentItem.value.status !== 'deleted') {
            return false;
          }
          return true;
        }
      }
    } else {
      return !plans[
        subscriptions || this.props.subscription
      ].components.includes(component);
    }
    return true;
  };

  handleTabActive = tabName => this.setState({ activeTab: tabName });

  getComponents = val => {
    const componentTypes = [
      'components',
      'integrations',
      'booking',
      'ecommerce',
      'marketplace',
      'components',
    ];

    this.setState({ searchValue: val });
    componentTypes.forEach(type => {
      const filteredType = `filtered${type.charAt(0).toUpperCase() +
        type.slice(1)}`;
      const filteredComponents = this[type]().filter(
        c =>
          c.label.toLowerCase().includes(val.toLowerCase()) ||
          c.text.toLowerCase().includes(val.toLowerCase()),
      );

      // console.log(
      //   {
      //     filteredType,
      //     val,
      //     filteredComponents,
      //     type,
      //     text: `filtered${type.charAt(0).toUpperCase() + type.slice(1)}`,
      //   },
      //   'sjd12345',
      // );

      if (filteredComponents.length > 0 && val !== '') {
        this.handleTabActive(type);
      } else if (val === '') {
        this.handleTabActive(componentTypes[0]);
      }

      this.setState({
        [filteredType]: filteredComponents,
      });
    });
  };

  onSearchChange = val => {
    this.getComponents(val);
  };

  handleCancel = source => {
    this.getComponents('');
    this.props.onCancel();
    if (source === 'upgrade') {
      this.props.history.push(`/${this.props.appname}/choose_plan`);
    }
  };

  handleConfirmIndustries = industries => {
    const filteredIndustries = Object.entries(industries)
      .filter(([, checked]) => checked)
      .map(([label]) => label);
    this.setState({ filteredIndustries, industries });
  };

  filterIndustries = unfilteredComponents => {
    if (!this.state.filteredIndustries.length) return unfilteredComponents;

    const industryTags = this.state.filteredIndustries.map(industry =>
      industry.toLowerCase(),
    );

    return unfilteredComponents.filter(component => {
      const hasIndustries = component.industries;

      const hasTargetIndustries =
        hasIndustries &&
        component.industries.some(componentIndustry =>
          industryTags.includes(componentIndustry),
        );

      return !hasIndustries || hasTargetIndustries;
    });
  };

  searchBar = () => {
    const { t } = this.props;
    return (
      <div className="search-input-wrapper--container">
        <Search
          name="search"
          placeholder={t('Search components')}
          value={this.state.searchValue}
          onChange={val => this.onSearchChange(val)}
          onClear={() => this.onSearchChange('')}
          withFilterPopup
          filterItems={this.state.industries}
          handleFilterConfirm={this.handleConfirmIndustries}
          filterName="industry"
        />
      </div>
    );
  };

  renderLayer = () => {
    const {
      pending,
      type,
      onSubmit,
      onCancel,
      onTypeChange,
      match,
      toggleAuth,
      loginEnabled,
      t,
    } = this.props;

    const {
      activeTab,
      filteredComponents,
      filteredIntegrations,
      filteredBooking,
      filteredEcommerce,
      filteredMarketplace,
      nextInvoiceAmount,
    } = this.state;

    const buttonLabel =
      components[type].name === 'Feedback'
        ? 'Video Feedback'
        : components[type].name;
    return (
      <Overlay>
        <Provider store={store}>
          <SimpleToolbar onExit={this.handleCancel} />
        </Provider>
        {this.props.payment.requiresAuthentication &&
        this.props.payment.paymentIntentClientSecret &&
        this.props.payment.paymentType === 'addon' ? (
          <p style={{ textAlign: 'center' }}>
            <Trans>
              You are being redirected to your banking providers payment page to
              complete the payment. Do not reload this page.
            </Trans>
          </p>
        ) : (
          <Create
            title={t('Create New Component')}
            subtext={t(
              'Start creating impactful, effective mobile apps by selecting from the components below.',
            )}
            submitLabel={`${t('Add')} ${buttonLabel} ${t('Component')}`}
            pending={pending}
            onSubmit={() => onSubmit(type)}
            onCancel={this.handleCancel}
            className="create-new-component"
            toggleAuth={toggleAuth}
            loginEnabled={loginEnabled}
          >
            <div style={{ width: '90%', padding: '0 36px' }}>
              <RadioMenu onChange={onTypeChange} selected={type}>
                <Tabs
                  className="tabs"
                  inkBarStyle={styles.inkBarStyle}
                  contentContainerStyle={styles.contentContainerStyle}
                  tabItemContainerStyle={styles.tabItemContainerStyle}
                  style={{ width: '100%' }}
                  value={activeTab}
                >
                  <Tab
                    key="components"
                    value="components"
                    label={t('Components')}
                    buttonStyle={styles.buttonStyle}
                    onActive={() => this.handleTabActive('components')}
                    className={activeTab === 'components' ? 'active' : ''}
                  >
                    {this.searchBar()}
                    {this.filterIndustries(filteredComponents).map(
                      component => {
                        return (
                          <CreateComponentItem
                            {...this.props}
                            appname={this.props.match.params.appname}
                            key={component.label}
                            label={component.label}
                            value={component.value}
                            text={component.text}
                            svg={component.svg}
                            disabled={this.isDisabled(component.disabled)}
                            instructionLink={component.instructionLink}
                            instructionSubAdminLink={
                              component.instructionSubAdminLink
                            }
                            nextInvoiceAmount={nextInvoiceAmount}
                            hasTrialExpired={this.hasExpired(
                              component.disabled,
                            )}
                            handleChange={onTypeChange}
                            category={'components'}
                            onCancel={this.handleCancel}
                          />
                        );
                      },
                    )}

                    {FOOTBALL_ACADEMY_APPS.indexOf(match.params.appname) >
                      -1 && (
                      <React.Fragment>
                        <CreateComponentItem
                          {...this.props}
                          appname={this.props.match.params.appname}
                          label="Event List"
                          value="EventList"
                          text={t('Allow users to filter a list of events')}
                          svg={<EventListIcon />}
                          onCancel={this.handleCancel}
                        />
                        <CreateComponentItem
                          {...this.props}
                          appname={this.props.match.params.appname}
                          label="Event/Course/CPD"
                          value="EventCourses"
                          text={t(
                            'All users to filter a list of events, courses and cpds',
                          )}
                          svg={<Form />}
                          disabled={this.isDisabled('EventCourses')}
                          onCancel={this.handleCancel}
                        />
                        <CreateComponentItem
                          {...this.props}
                          appname={this.props.match.params.appname}
                          label="Workforce Profiles"
                          value="Workforces"
                          text={t(
                            'All users to see workforce groups like coaches, educators etc.',
                          )}
                          svg={<Form />}
                          disabled={this.isDisabled('Workforces')}
                          onCancel={this.handleCancel}
                        />
                        <CreateComponentItem
                          {...this.props}
                          appname={this.props.match.params.appname}
                          label="Member Bodies"
                          value="Memberbodies"
                          text={t('All users to see member bodies list.')}
                          svg={<Form />}
                          disabled={this.isDisabled('Memberbodies')}
                          onCancel={this.handleCancel}
                        />
                        <CreateComponentItem
                          {...this.props}
                          appname={this.props.match.params.appname}
                          label="Fk Academy Groups"
                          value="FkAcademyGroups"
                          text={t(
                            'Allow parents, coaches and children to view and interact with groups.',
                          )}
                          svg={<EventListIcon />}
                          onCancel={this.handleCancel}
                        />
                      </React.Fragment>
                    )}
                    {PGMOL_APPS.indexOf(match.params.appname) > -1 && (
                      <React.Fragment>
                        <CreateComponentItem
                          {...this.props}
                          appname={this.props.match.params.appname}
                          label="Regional Performance Centres"
                          value="PgmolRegionPerformanceCentre"
                          text="Display Regional Performance Centres"
                          svg={<RegionalPerformanceIcon />}
                          onCancel={this.handleCancel}
                        />
                        <CreateComponentItem
                          {...this.props}
                          appname={this.props.match.params.appname}
                          label="PL Match Day Support"
                          value="PgmolPlMatchSupport"
                          text="Display PL Match Day Support"
                          svg={<PlMatchIcon />}
                          onCancel={this.handleCancel}
                        />
                        <CreateComponentItem
                          {...this.props}
                          appname={this.props.match.params.appname}
                          label="Title Component"
                          value="Title"
                          text="Add Title Component"
                          svg={<EventListIcon />}
                          onCancel={this.handleCancel}
                        />

                        {/* <CreateComponentItem
                            {...this.props}
                             appname={this.props.match.params.appname}
                            label="Title Component"
                            value="Title"
                            text="Add Title Component"
                            svg={<EventListIcon />}
                            onCancel={this.handleCancel}
                          />
                          <CreateComponentItem
                            {...this.props}
                             appname={this.props.match.params.appname}
                            label="Blog"
                            value="Blog"
                            text="Add Blog"
                            svg={<EventListIcon />}
                            onCancel={this.handleCancel}
                          /> */}
                      </React.Fragment>
                    )}

                    {HS_FEATURES_ENABLED_APPS.indexOf(match.params.appname) >
                      -1 && (
                      <React.Fragment>
                        <CreateComponentItem
                          {...this.props}
                          appname={this.props.match.params.appname}
                          label="Event List"
                          value="EventList"
                          text={t('Allow users to filter a list of events')}
                          svg={<EventListIcon />}
                          onCancel={this.handleCancel}
                        />
                        <CreateComponentItem
                          {...this.props}
                          appname={this.props.match.params.appname}
                          label="Event/Course/CPD"
                          value="EventCourses"
                          text={t(
                            'All users to filter a list of events, courses and cpds',
                          )}
                          svg={<Form />}
                          disabled={this.isDisabled('EventCourses')}
                          onCancel={this.handleCancel}
                        />
                        <CreateComponentItem
                          {...this.props}
                          appname={this.props.match.params.appname}
                          label="Workforce Profiles"
                          value="Workforces"
                          text={t(
                            'All users to see workforce groups like coaches, educators etc.',
                          )}
                          svg={<Form />}
                          disabled={this.isDisabled('Workforces')}
                          onCancel={this.handleCancel}
                        />
                        <CreateComponentItem
                          {...this.props}
                          appname={this.props.match.params.appname}
                          label="Member Bodies"
                          value="Memberbodies"
                          text={t('All users to see member bodies list.')}
                          svg={<Form />}
                          disabled={this.isDisabled('Memberbodies')}
                          onCancel={this.handleCancel}
                        />
                      </React.Fragment>
                    )}
                  </Tab>
                  <Tab
                    key="integrations"
                    value="integrations"
                    label={t('Integrations')}
                    buttonStyle={styles.buttonStyle}
                    onActive={() => this.handleTabActive('integrations')}
                    className={activeTab === 'integrations' ? 'active' : ''}
                  >
                    {this.searchBar()}
                    {filteredIntegrations.map(component => {
                      return (
                        <CreateComponentItem
                          {...this.props}
                          appname={this.props.match.params.appname}
                          key={component.label}
                          label={component.label}
                          value={component.value}
                          text={component.text}
                          svg={component.svg}
                          disabled={this.isDisabled(component.disabled)}
                          instructionLink={component.instructionLink}
                          instructionSubAdminLink={
                            component.instructionSubAdminLink
                          }
                          onCancel={this.handleCancel}
                        />
                      );
                    })}
                  </Tab>
                  <Tab
                    key="booking"
                    label={t('Booking')}
                    value="booking"
                    buttonStyle={styles.buttonStyle}
                    onActive={() => this.handleTabActive('booking')}
                    className={activeTab === 'booking' ? 'active' : ''}
                  >
                    {this.searchBar()}

                    {filteredBooking.map(component => (
                      <CreateComponentItem
                        {...this.props}
                        appname={this.props.match.params.appname}
                        key={component.label}
                        label={component.label}
                        value={component.value}
                        text={component.text}
                        svg={component.svg}
                        disabled={this.isDisabled(component.disabled)}
                        instructionLink={component.instructionLink}
                        instructionSubAdminLink={
                          component.instructionSubAdminLink
                        }
                        onCancel={this.handleCancel}
                      />
                    ))}
                  </Tab>
                  <Tab
                    key="ecommerce"
                    label={t('eCommerce')}
                    value="ecommerce"
                    buttonStyle={styles.buttonStyle}
                    onActive={() => this.handleTabActive('ecommerce')}
                    className={activeTab === 'ecommerce' ? 'active' : ''}
                  >
                    {this.searchBar()}
                    {filteredEcommerce.map(component => (
                      <CreateComponentItem
                        {...this.props}
                        appname={this.props.match.params.appname}
                        key={component.label}
                        label={component.label}
                        value={component.value}
                        text={component.text}
                        svg={component.svg}
                        disabled={this.isDisabled(component.disabled)}
                        instructionLink={component.instructionLink}
                        instructionSubAdminLink={
                          component.instructionSubAdminLink
                        }
                        onCancel={this.handleCancel}
                      />
                    ))}
                  </Tab>
                  <Tab
                    key="marketplace"
                    label={t('marketplace')}
                    value="marketplace"
                    buttonStyle={styles.buttonStyle}
                    onActive={() => this.handleTabActive('marketplace')}
                    className={activeTab === 'marketplace' ? 'active' : ''}
                  >
                    {this.searchBar()}
                    {filteredMarketplace.map(component => (
                      <CreateComponentItem
                        {...this.props}
                        appname={this.props.match.params.appname}
                        key={component.label}
                        label={component.label}
                        value={component.value}
                        text={component.text}
                        svg={component.svg}
                        disabled={this.isDisabled(component.disabled)}
                        instructionLink={component.instructionLink}
                        instructionSubAdminLink={
                          component.instructionSubAdminLink
                        }
                        onCancel={this.handleCancel}
                      />
                    ))}
                  </Tab>
                </Tabs>
              </RadioMenu>
            </div>
          </Create>
        )}
      </Overlay>
    );
  };

  render() {
    return (
      <RenderToLayer
        render={this.renderLayer}
        useLayerForClickAway={false}
        open={this.props.open}
      />
    );
  }
}

export default withTranslation()(CreateComponent);
