import { connect } from 'react-redux';

import AppSumoPublishNotice from './AppSumoPublishNotice';
import {
  closeAppSumoPublishNotice,
  checkAppSumoPublishNoticeOccurence,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  appname: state.app.appContext.appname,
  auth: state.account.auth,
  account: state.account.organisation,
  isAppSumAccount: state.account.organisation.appsumo_id !== '' ? true : false,
  appsumo_publish: state.app.publishMsg.open,
  accountId: state.account.profile.account,
});

const mapDisptachToProps = (dispatch, ownProps) => ({
  closePublish: (appname, accountId, publish) =>
    dispatch(closeAppSumoPublishNotice(appname, accountId, publish)),
  checkAppSumoPublishNoticeOccurence: accountId =>
    dispatch(checkAppSumoPublishNoticeOccurence(accountId)),
});

export default connect(
  mapStateToProps,
  mapDisptachToProps,
)(AppSumoPublishNotice);
