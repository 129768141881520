import { isEmpty } from 'lodash';

import firebase from '../../../../firebase';
import { publishApp } from '../../../../app/modules/publishDialog/actions';
import seedComponent from '../../../editor/modules/build/modules/create/createComponent/seedComponent';
import { INTERNAL_APP_PATHS } from '../../../../config/constants';
export const SCAN_BARCODE = 'SCAN_BARCODE';
export const ADD_SCAN_COMPONENT_ERROR = 'ADD_SCAN_COMPONENT_ERROR';
export const ADD_SCAN_PAGE_ERROR = 'ADD_SCAN_PAGE_ERROR';
export const SCAN_PAGE_CREATED = 'SCAN_PAGE_CREATED';
export const SCAN_COMPONENT_ADDED = 'SCAN_COMPONENT_ADDED';
export const REQUEST_USERS = 'REQUEST_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const NO_USERS_EXIST = 'NO_USERS_EXIST';
const ADD_PAGE_ERROR = 'ADD_PAGE_ERROR';
const ADD_ZOOM_COMPONENT_ERROR = 'ADD_ZOOM_COMPONENT_ERROR';
const ZOOM_PAGE_CREATED = 'ZOOM_PAGE_CREATED';
const ZOOM_COMPONENT_ADDED = 'ZOOM_COMPONENT_ADDED';

const requestUsers = appname => ({
  type: REQUEST_USERS,
  appname,
});

export const getUsers = appname => dispatch => {
  dispatch(requestUsers(appname));

  firebase
    .database()
    .ref(`app_users/${appname}`)
    .on('value', snap => {
      const buyers = {};
      snap.forEach(childSnapshot => {
        if (childSnapshot.hasChild('email')) {
          buyers[childSnapshot.key] = childSnapshot.val();
        }
      });
      if (!isEmpty(buyers)) {
        dispatch({
          type: RECEIVE_USERS,
          value: buyers,
        });
      } else {
        dispatch({
          type: NO_USERS_EXIST,
        });
      }
    });
};

export const updateChecked = (appname, isZoomHost, user) => async () => {
  const userRef = firebase.database().ref(`app_users/${appname}/${user}/`);
  await userRef.update({ isZoomHost: !isZoomHost });
};

export const updateAssistantChecked = (
  appname,
  isZoomAssistant,
  user,
) => async () => {
  const userRef = firebase.database().ref(`app_users/${appname}/${user}/`);
  await userRef.update({ isZoomAssistant: !isZoomAssistant });
};

export const reportAddZoomComponentError = err => ({
  type: ADD_ZOOM_COMPONENT_ERROR,
  err,
});

export const reportAddPageError = err => ({
  type: ADD_PAGE_ERROR,
  err,
});

const toPath = title =>
  title
    .toLowerCase()
    .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '')
    .replace(/\s+/g, '-')
    .trim();

const createZoomPage = (appname, getState, alwaysDataRef) => {
  const childRoutesRef = firebase
    .database()
    .ref(`apps/${appname}/draft/layout_data/childRoutes`);
  const layoutData = getState().engage.structure.routes || [];
  const form = getState().editor.build.create.createPage;
  const pageTitle = 'Zoom';
  const paths = layoutData ? layoutData.map(item => item.path) : [];
  paths.push(...INTERNAL_APP_PATHS);
  const tabs = null;
  let newPageKey;
  let path = toPath(pageTitle);

  if (paths.indexOf(path) !== -1) {
    path = path.concat('-', Date.now().toString());
  }

  return alwaysDataRef
    .push({
      title: pageTitle,
      type: form.type,
      path,
      tabs,
    })
    .then(pageRef => {
      newPageKey = pageRef.key;

      return childRoutesRef
        .transaction(value => {
          if (!value) return [newPageKey];
          return [...value, pageRef.key];
        })
        .then(() => newPageKey);
    });
};

const createZoomComponent = async (
  appname,
  pageKey,
  alwaysDataRef,
  account,
  palette,
) => {
  const lazyDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data`);
  const componentsArrayRef = alwaysDataRef.child(`${pageKey}/components`);

  const zoomComponentSeedData = seedComponent('Zoom', account, palette, null);

  const snapshot = await lazyDataRef.push(zoomComponentSeedData.content);
  // update not_private permissions for new components
  // so that new components will be public when Member Login is enabled
  firebase
    .database()
    .ref(`app_groups/${appname}/not_private`)
    .update({ [snapshot.key]: true });
  const snapshot1 = snapshot;
  const snapshot2 = alwaysDataRef.push({
    type: 'Zoom',
    lazy_data: snapshot1.key,
    design: zoomComponentSeedData.design,
    layout: zoomComponentSeedData.layout,
  });
  componentsArrayRef.transaction(value2 => {
    if (!value2) return [snapshot2.key];

    return [...value2, snapshot2.key];
  });
  const key = snapshot2.key;
  return key;
};

export const addZoomComponent = (appname, history) => (dispatch, getState) => {
  const alwaysDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data`);
  createZoomPage(appname, getState, alwaysDataRef)
    .then(newPageKey => {
      dispatch({ type: ZOOM_PAGE_CREATED, newPageKey });
      const account = getState().account.profile.account;
      const palette = getState().editor.design[appname].palette;
      createZoomComponent(appname, newPageKey, alwaysDataRef, account, palette)
        .then(key => {
          dispatch({ type: ZOOM_COMPONENT_ADDED, key });
          dispatch(publishApp(appname));
          history.push(
            history.location.pathname.replace(
              'engage/zoom',
              `editor/build/list?page=${newPageKey}&component=${key}`,
            ),
          );
        })
        .catch(err => dispatch(reportAddZoomComponentError(err)));
    })
    .catch(err => dispatch(reportAddPageError(err)));
};
