import _pickBy from 'lodash/pickBy';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import { combineReducers } from 'redux';
import viewAttendeeReducer from './viewAttendees/modules/modal/reducer';
import { escapeSepcialChars } from '../../../distribute/utils';

import {
  SET_SELECTED_EVENT,
  SET_EVENT_ATTENDEES,
  SET_EVENT_ATTENDEES_ITEMS,
  FILTER_EVENT_ATTENDEE_DATA,
  SELECT_ALL_EVENT_ATTENDEES,
  DESELECT_ALL_EVENT_ATTENDEES,
  SELECT_ATTENDEE,
} from './actions';

const INITIAL_STATE = {
  selectedEvent: null,
  eventAttendees: {},
  pending: false,
  chunks: {},
  selected: [],
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  filteredChunksAll: {},
};

const filterChunks = (chunks, filterHolder = '') => {
  let filteredChunks = chunks;
  const filter = escapeSepcialChars(filterHolder);

  if (filter !== '') {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`^${filter}.*$`, 'i');
      return (
        rgx.test(chunk.email) ||
        rgx.test(chunk.name) ||
        rgx.test(chunk.userType)
      );
      // return rgx.test(chunk.email);
    });
  }
  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const eventsAttendees = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_ALL_EVENT_ATTENDEES: {
      const filteredChunks = _pick(state.filteredChunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: true,
      }));
      const selected = _union(state.selected, _keys(filteredChunks));

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_EVENT_ATTENDEES:
      return {
        ...state,
        filteredChunks: _mapValues(state.filteredChunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    case SET_SELECTED_EVENT: {
      return {
        ...state,
        selectedEvent: action.value,
      };
    }
    case SELECT_ATTENDEE: {
      const prevState = state.filteredChunks[action.key].selected;

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }
    case SET_EVENT_ATTENDEES: {
      return {
        ...state,
        chunks: action.value,
        selected: [],
        filteredChunks: action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };
    }
    case SET_EVENT_ATTENDEES_ITEMS: {
      return { ...state, items: action.value };
    }

    case FILTER_EVENT_ATTENDEE_DATA:
      return {
        ...state,
        ...filterChunks(state.chunks, action.payload),
        filter: action.payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  listAttendees: eventsAttendees,
  viewAttendee: viewAttendeeReducer,
});
