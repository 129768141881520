import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { STAFF_ADMIN } from '../../../../config';

import styles from './styles.module.scss';
import DialogConfirmation from '../../../../app/components/dialogConfirmation';
import { markOrderAsDelivered } from '../../api';

const OrderDetailsCard = props => {
  const { t } = useTranslation();
  const { orderDetails, appname, setOpenDialog } = props;
  const profile = useSelector(state => state.account.profile);
  const staffProfile = useSelector(state => state.account.staffProfile);
  const role = useSelector(state => state.account.profile.role);

  const [orderDeliveredDialog, setOrderDeliveredDialog] = useState(false);
  const queryClient = useQueryClient();
  const setOrderAsDelivered = async () => {
    const data = {
      appname,
      orderId: orderDetails.key,
      adminName:
        role === STAFF_ADMIN
          ? `${staffProfile.firstName.value} ${staffProfile.lastName.value}`
          : profile.name.value,
    };
    await markOrderAsDelivered(data);
    queryClient.invalidateQueries('foodOrders');
    setOrderDeliveredDialog(false);
    setOpenDialog(false);
  };
  return (
    <>
      <div className={styles.orders_placed_card}>
        <h2>
          <Trans>Items</Trans>
        </h2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Name</Trans>
                </TableCell>
                <TableCell align="right">
                  <Trans>Price</Trans>
                </TableCell>
                <TableCell align="right">
                  <Trans>Quantity</Trans>
                </TableCell>
                <TableCell align="right">
                  <Trans>Total</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderDetails.cart &&
                orderDetails.cart.map(item => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                      {item.name}
                    </TableCell>
                    <TableCell align="right">
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: orderDetails.metaData.currency,
                      }).format(Number(item.price))}
                    </TableCell>
                    <TableCell align="right">{item.quantity}</TableCell>
                    <TableCell align="right">
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: orderDetails.metaData.currency,
                      }).format((item.price * item.quantity).toFixed(2))}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell align="right" colSpan={3}>
                  <b>
                    <Trans>SubTotal</Trans>
                  </b>
                </TableCell>
                <TableCell align="right" colSpan={1}>
                  <b>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: orderDetails.metaData.currency,
                    }).format(Number(orderDetails.subTotal))}
                  </b>
                </TableCell>
              </TableRow>
              {orderDetails.discount > 0 && (
                <TableRow>
                  <TableCell align="right" colSpan={3}>
                    <b>
                      <Trans>Discount</Trans>
                    </b>
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    <b>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: orderDetails.metaData.currency,
                      }).format(Number(orderDetails.discount))}
                    </b>
                  </TableCell>
                </TableRow>
              )}
              {orderDetails.tax > 0 && (
                <TableRow>
                  <TableCell align="right" colSpan={3}>
                    <b>
                      <Trans>Tax</Trans>
                    </b>
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    <b>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: orderDetails.metaData.currency,
                      }).format(Number(orderDetails.tax))}
                    </b>
                  </TableCell>
                </TableRow>
              )}
              {orderDetails.deliveryCharge > 0 && (
                <TableRow>
                  <TableCell align="right" colSpan={3}>
                    <b>
                      <Trans>Delivery Charge</Trans>
                    </b>
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    <b>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: orderDetails.metaData.currency,
                      }).format(Number(orderDetails.deliveryCharge))}
                    </b>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell align="right" colSpan={3}>
                  <b>
                    <Trans>Total</Trans>
                  </b>
                </TableCell>
                <TableCell align="right" colSpan={1}>
                  <b>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: orderDetails.metaData.currency,
                    }).format(Number(orderDetails.finalBill))}
                  </b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className={styles.orders_placed_text}>
          <b>
            <Trans>Customer Email</Trans> -{' '}
          </b>
          {orderDetails.userEmail || 'Not Available'}
        </div>
        <div className={styles.orders_placed_text}>
          <b>
            <Trans>Customer Phone</Trans> -{' '}
          </b>
          {orderDetails.userNumber || 'Not Available'}
        </div>
        <div className={styles.orders_placed_text}>
          <b>
            <Trans>Delivery Type</Trans> -{' '}
          </b>
          {orderDetails.deliveryType === 'pickup' ? 'Pickup' : 'Home Delivery'}
        </div>

        {orderDetails.deliveryType === 'pickup' && (
          <div className={styles.orders_placed_text}>
            <b>
              <Trans>Pickup Code</Trans> -{' '}
            </b>
            <b> {orderDetails.pickUpCode}</b>
          </div>
        )}

        {orderDetails.deliveryType === 'home' && (
          <>
            <div className={styles.orders_placed_text}>
              <b>
                <Trans>Delivery Address</Trans> -{' '}
              </b>
              {(orderDetails.addressDetails &&
                orderDetails.addressDetails.location) ||
                t('Not Available')}
            </div>
            <div className={styles.orders_placed_text}>
              <b>
                <Trans>Delivery Address Type</Trans> -{' '}
              </b>
              {(orderDetails.addressDetails &&
                orderDetails.addressDetails.type) ||
                t('Not Available')}
            </div>
          </>
        )}
        <div className={styles.orders_placed_text}>
          <b>
            <Trans>Ordered at</Trans> -{' '}
          </b>
          {moment(orderDetails.orderedAt).format('MMMM Do YYYY, h:mm:ss a')}
        </div>
        <div className={styles.orders_placed_text}>
          <b>
            <Trans>Payment Mode</Trans> -{' '}
          </b>
          {orderDetails.paymentOption === 'card' && t('Card')}
          {orderDetails.paymentOption === 'cash_on_delivery' &&
            t('Cash on delivery')}
        </div>
        {!orderDetails.pickUpCode && (
          <div className={styles.orders_placed_text}>
            <b>
              <Trans>Delivery status</Trans> -{' '}
            </b>
            <>
              {orderDetails.deliveryStatus ? (
                <span style={{ color: 'green' }}>
                  <Trans>Delivered</Trans>
                </span>
              ) : orderDetails.orderCancelled === true ? (
                <span style={{ color: 'red' }}>
                  <Trans>Order Cancelled</Trans>
                </span>
              ) : (
                <span style={{ color: 'red' }}>
                  <Trans>Not Delivered</Trans>
                </span>
              )}
              {orderDetails.deliveryType === 'pickup' && (
                <span style={{ color: 'red' }}>
                  <Trans>Order was picked up</Trans>
                </span>
              )}
            </>
          </div>
        )}
        {orderDetails.deliveryStatus && (
          <React.Fragment>
            <div className={styles.orders_placed_text}>
              {orderDetails.deliveryType !== 'pickup' ? (
                <b>
                  <Trans>Delivered at</Trans> -{' '}
                </b>
              ) : (
                <b>
                  <Trans>Pickup Time</Trans> -{' '}
                </b>
              )}
              {orderDetails.deliveredAt
                ? moment(orderDetails.deliveredAt).format(
                    'MMMM Do YYYY, h:mm:ss a',
                  )
                : t('Not Available')}
            </div>
            {orderDetails.deliveryType !== 'pickup' && (
              <div className={styles.orders_placed_text}>
                <b>
                  <Trans>Delivered by</Trans> -{' '}
                </b>
                {orderDetails.deliverBy || t('Not Available')}
              </div>
            )}
          </React.Fragment>
        )}
        {!(orderDetails.deliveryStatus || orderDetails.orderCancelled) && (
          <div className={styles.actionContainer}>
            <button
              className={styles.button}
              onClick={() => setOrderDeliveredDialog(true)}
            >
              <Trans>Mark Order as Delivered</Trans>
            </button>
          </div>
        )}
      </div>
      <DialogConfirmation
        open={orderDeliveredDialog}
        action={setOrderAsDelivered}
        message={t('Are you sure you want to mark this order as delivered?')}
        onClose={() => setOrderDeliveredDialog(false)}
      />
    </>
  );
};

export default OrderDetailsCard;
