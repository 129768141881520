import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import * as yup from 'yup';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { toggleFkAcademyGroupDialog, setFkAcademyGroupId } from '../../actions';
import ImageUpload from '../../../editor/internals/imageUpload';
import ErrorMessage from '../../../../app/components/errorMessage';
import firebase from '../../../../firebase';
import {
  getFkAcademyGroup,
  setFkAcademyGroup,
  getFkAcademyCoaches,
} from '../../api';
import { ProgressOverlay } from '../../../../app/components/progress';
import TryAgain from '../../../../app/components/tryAgain';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';

import styles from './styles.module.scss';

const schema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  availableSlots: yup
    .number()
    .positive('available slots must be a positive number')
    .integer()
    .required('available slots should be 1 or greater')
    .typeError('available slots should be 1 or greater'),
  coach: yup
    .string('need to select a coach')
    .required('need to select a coach'),
  status: yup
    .string('need to select a status')
    .required('need to select a status'),
  imageUrl: yup
    .string()
    .required('image is required')
    .typeError('image is required'),
});

function GroupsDialog({ match }) {
  const dispatch = useDispatch();
  const { appname } = match.params;
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading: isSubmitting } = useMutation(
    setFkAcademyGroup,
  );
  const fkGroupId = useSelector(({ fkAcademy }) => fkAcademy.fkGroupId);
  const fkGroupDialogOpen = useSelector(
    ({ fkAcademy }) => fkAcademy.fkGroupDialogOpen,
  );

  const [tempImageUrl, setTempImageUrl] = useState('');

  useEffect(() => {
    firebase
      .database()
      .ref(`/apps/${appname}/temp_data/fk_academy_groups_image_url`)
      .on('value', snapshot => {
        const value = snapshot.val();
        if (value && !fkGroupId)
          setValue('imageUrl', value, {
            shouldValidate: true,
          });
        else if (value && fkGroupId && setTempImageUrl !== '') {
          setValue('imageUrl', value, {
            shouldValidate: true,
          });
        }
        setTempImageUrl(value);
      });

    return () =>
      firebase
        .database()
        .ref(`/apps/${appname}/temp_data/fk_academy_groups_image_url`)
        .off();
  }, []);

  async function onSubmit(data) {
    try {
      await mutateAsync({
        appname,
        ...data,
        deleteTempImage: (group && group.imageUrl) === tempImageUrl,
        groupId: fkGroupId,
      });
    } catch (error) {
      dispatch(openSnackbar('Something went wrong. Try again.'));
      return;
    }
    queryClient.invalidateQueries('fkAcademyGroups');
    reset({
      title: '',
      description: '',
      availableSlots: '',
      coach: '',
      imageUrl: '',
      status: 'draft',
    });
    dispatch(
      openSnackbar(`Group ${fkGroupId ? 'updated' : 'added'} successfully!`),
    );
    dispatch(setFkAcademyGroupId(''));
    dispatch(toggleFkAcademyGroupDialog(false));
  }

  const { data: { data: group } = {}, error, isLoading, isError } = useQuery(
    ['fkAcademyGroup', fkGroupId],
    () => getFkAcademyGroup(appname, fkGroupId),
    { enabled: !!(fkGroupId && appname) },
  );

  const { data: { data: coaches } = {} } = useQuery(
    'fkAcademyCoaches',
    () => getFkAcademyCoaches(appname),
    { enabled: !!appname },
  );

  const defaultValues = group || {
    title: '',
    description: '',
    availableSlots: '',
    coach: '',
    imageUrl: '',
    status: 'draft',
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (group) reset(defaultValues);
  }, [group]);

  const status = watch('status');
  const imageUrl = watch('imageUrl');

  if (isLoading) return <ProgressOverlay />;

  if (isError) return <TryAgain message={error.message} />;

  return (
    <Dialog
      fullScreen
      open={fkGroupDialogOpen}
      onClose={() => {
        reset({
          title: '',
          description: '',
          availableSlots: '',
          coach: '',
          imageUrl: '',
          status: 'draft',
        });
        dispatch(setFkAcademyGroupId(''));
        dispatch(toggleFkAcademyGroupDialog(false));
      }}
    >
      <AppBar>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>Add Group</h2>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              reset({
                title: '',
                description: '',
                availableSlots: '',
                coach: '',
                imageUrl: '',
                status: 'draft',
              });
              dispatch(setFkAcademyGroupId(''));
              dispatch(toggleFkAcademyGroupDialog(false));
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.main_container}>
            <div className={styles.input_container}>
              <div>
                <label htmlFor="title">Title</label>
                <input {...register('title')} />
                <ErrorMessage>
                  {errors && errors.title && errors.title.message}
                </ErrorMessage>
              </div>

              <div>
                <label htmlFor="description">Description</label>
                <input {...register('description')} />
                <ErrorMessage>
                  {errors && errors.description && errors.description.message}
                </ErrorMessage>
              </div>
            </div>

            <div className={styles.image_container}>
              <h2>Image</h2>
              <ImageUpload
                targetId="fk_academy_groups_image"
                title="Group Image"
                src={imageUrl || tempImageUrl}
                firebaseUrl={`/apps/${appname}/temp_data/fk_academy_groups_image_url`}
                showCropAreaSizes
                height="220px"
                width="320px"
                noDelete={true}
                noUploadViaUrl={true}
              />
              <ErrorMessage>
                {errors && errors.imageUrl && errors.imageUrl.message}
              </ErrorMessage>
            </div>

            <div className={styles.input_container}>
              <div>
                <label htmlFor="availableSlots">Available Slots</label>
                <input {...register('availableSlots')} type="number" />
                <ErrorMessage>
                  {errors &&
                    errors.availableSlots &&
                    errors.availableSlots.message}
                </ErrorMessage>
              </div>
            </div>
          </div>

          <div className={styles.secondary_container}>
            <Controller
              control={control}
              name="coach"
              render={({ field }) => (
                <FormControl>
                  <InputLabel id="fk-academy-coach">Select Coach</InputLabel>
                  <Select
                    labelId="fk-academy-coach"
                    title="Select Coach"
                    errors={errors.coach}
                    {...field}
                  >
                    {coaches &&
                      coaches.length &&
                      coaches.map(coach => (
                        <MenuItem key={coach.key} value={coach.key}>
                          {coach.firstName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
            <div
              style={{
                padding: '10px',
                border: '1px solid',
                margin: '10px 0',
                borderRadius: '10px',
                wordBreak: 'break-word',
              }}
            >
              Coaches can signup using the link{' '}
              {`https://${appname}${
                process.env.NODE_ENV === 'production' ? '.' : '.staging.'
              }beezer.com/sign-up?fk-academy-coach=true`}
            </div>
            <ErrorMessage>
              {errors && errors.coach && errors.coach.message}
            </ErrorMessage>

            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <FormControl>
                  <InputLabel id="fk-academy-status">Status</InputLabel>
                  <Select
                    labelId="fk-academy-status"
                    title="Status"
                    errors={errors.status}
                    {...field}
                  >
                    <MenuItem value="draft">Draft</MenuItem>
                    <MenuItem value="active">Active</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <ErrorMessage>
              {errors && errors.status && errors.status.message}
            </ErrorMessage>
            {status === 'draft' ? (
              <div>Will only be visible in app preview.</div>
            ) : (
              <div>Will be visible to everyone.</div>
            )}

            <input
              className={styles.button}
              type="submit"
              value={
                isSubmitting
                  ? `${fkGroupId ? 'UPDATING...' : 'ADDING...'}`
                  : fkGroupId
                  ? 'UPDATE'
                  : 'ADD'
              }
              disabled={isSubmitting}
            />
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(GroupsDialog);
