import React from 'react';

const Text = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39.5 39.5"
  >
    <title>media</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M19.75,39.5A19.75,19.75,0,1,1,39.5,19.75,19.77,19.77,0,0,1,19.75,39.5Zm0-38A18.25,18.25,0,1,0,38,19.75,18.27,18.27,0,0,0,19.75,1.5Z"
        />
        <path
          class="cls-1"
          d="M13.89,28.17V11.33l14.58,8.42Zm1.5-14.24V25.57l10.08-5.82Z"
        />
      </g>
    </g>
  </svg>
);

export default Text;
