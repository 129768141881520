import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconButton from 'material-ui/IconButton';

const styles = {
  iconButton: {
    height: 38,
    width: 38,
    padding: 0,
  },
};

const ResponsiveIconButton = ({ className, children, tooltip, onClick }) => (
  <div className={className}>
    <IconButton
      style={styles.iconButton}
      className={className}
      tooltip={tooltip}
      onClick={onClick}
    >
      {children}
    </IconButton>
  </div>
);

ResponsiveIconButton.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default styled(ResponsiveIconButton)`
  background-color: rgb(246, 246, 246);
  border: ${props =>
    props.selected
      ? '2px solid rgb(182, 198, 37)'
      : '2px solid rgb(193, 193, 193)'};
  border-radius: 50%;
  margin: 12px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-shadow: ${props =>
    props.selected &&
    'rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px'};
`;
