import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../components/Modal';

const DragonsDenOfferTerms = ({ open, handleClose }) => (
  <Modal
    open={open}
    title="Beezer - Dragons’ Den Offer Terms and Conditions"
    handleClose={handleClose}
  >
    <p>
      The Beezer Dragons’ Den Offer is the property of Hermes Apps Ltd.
      (“Beezer”) and is subject to the following Terms and Conditions, as well
      as to the Beezer{' '}
      <a
        href="https://www.beezer.com/terms-conditions-and-cookie-policy/"
        target="blank"
        className="underline-text"
      >
        Terms of Use and Privacy Policy
      </a>
      , as amended from time to time, as applicable:
    </p>
    <ol type="I">
      <li>
        The Dragons’ Den Offer can be redeemed upon your first purchase of any
        yearly Beezer Eveyrthing Plans applicable to PWA&#39;s only as available
        on the{' '}
        <a
          href="https://www.beezer.com/pricing/"
          target="blank"
          className="underline-text"
        >
          Beezer.com/pricing
        </a>{' '}
        site (the &quot;Applicable Eveyrthing Plan&quot;) ;
      </li>
      <li>
        The Dragons’ Den Offer grants a discount of fifty percent (50%) on the
        price of your first yearly Applicable Eveyrthing Plan selected, as
        published on the{' '}
        <a
          href="https://www.beezer.com/pricing/"
          target="blank"
          className="underline-text"
        >
          Beezer.com/pricing
        </a>{' '}
        site on the date of purchase for which the Voucher is used;
      </li>
      <li>
        The Dragons’ Den Offer is redeemed automatically upon subscription to
        the Applicable Eveyrthing Plan via the Dragons’ Den Offer Web Page;
      </li>
      <li>
        The Dragons’ Den Offer is redeemable immediately and until Monday, 26th
        November, 2018 Midnight (expiration date). Beezer is not responsible for
        any Offer not redeemed by the Expiration Date. Failure to use a Offer by
        the expiration date shall result in the forfeiture of such Offer;
      </li>
      <li>The Dragons’ Den Offer may be used only once;</li>
      <li>
        The Dragons’ Den Offer may not be redeemed for any Beezer services and
        products other than your first purchase of an yearly Applicable
        Eveyrthing Plans, nor otherwise for any previous purchases, online
        orders, special orders or gift cards. The Dragons’ Den Offer cannot be
        used to pay for taxes, shipping and handling fees or other applicable
        ancillary charges which may result from your purchase using the Offer;
      </li>
      <li>
        No cash value, credit or change will be given in exchange for this
        Offer;
      </li>
      <li>
        The Dragons’ Den Offer discount is applicable for the first subscription
        cycle using the Offer web page only. Following the expiration of the
        first subscription cycle using the Offer, the applicable terms specified
        in the Beezer website in connection with prices, charges and other
        relevant information will apply. Applicable Premium Plans Terms and
        Conditions apply;
      </li>
      <li>The Offer holder is responsible for any applicable sales tax;</li>
      <li>
        The Dragons’ Den Offer may not be combined with any other Offer or
        discount, voucher or promotion or sale available to the Offer holder.
        The Offer holder may use one Offer discount in conjunction with the
        Applicable Eveyrthing Plan purchase;
      </li>
      <li>
        The Offer holder is solely responsible for the lawful use of the Offer.
        In any event of fraud, misrepresentation, impersonation or any abuse or
        violation of these terms and conditions, or of the Beezer Terms of Use
        or Privacy Policy, Beezer reserves the right to take any or all
        available legal or administrative actions, without prejudice to any
        other remedies available to it under applicable law;
      </li>
      <li>
        Other applicable restrictions and exclusions may apply. Vouchers are
        void where prohibited by applicable law.
      </li>
    </ol>
  </Modal>
);

DragonsDenOfferTerms.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DragonsDenOfferTerms;
