import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Toggle from 'material-ui/Toggle';
import { Trans, withTranslation } from 'react-i18next';
import CircularValidation from '../../../../../../../app/components/circularValidation';
import Container from '../../../../../../../app/components/container';
import TextField from '../../../../../../../app/components/textField';
import SectionHeader from '../../../../../components/sectionHeader';
import ImageUpload from '../../../../../internals/imageUpload';
import themeStyles from '../../../../../../../_export.scss';

class VoteItem extends Component {
  static propTypes = {
    component: PropTypes.object.isRequired,
    data: PropTypes.object,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: {
      avatar: { '120x120': '' },
      title: '',
      subtitle: '',
    },
  };

  getAvatarUrl = key => {
    const { match, component } = this.props;

    return `apps/${match.params.appname}/draft/lazy_data/${component.lazy_data}/options/chunks/${key}/avatar`;
  };

  handleChange = field => value => this.props.onChange(field, value, true);

  getTooltipText = () => {
    const { t } = this.props;
    return this.requiredDisabled()
      ? t('At least one field must be required')
      : t(
          'Users will have to fill in this field to be able to submit the form',
        );
  };

  handleToggle = (field, value) => this.props.onChange(field, value, true);

  handleDropdownOption = (field, value) =>
    this.props.onChange(field, value, true);

  requiredDisabled = () =>
    this.props.fieldsData &&
    Object.entries(this.props.fieldsData.chunks).filter(
      field =>
        field[1].required &&
        queryString.parse(this.props.location.search).item !== field[0],
    ).length === 0 &&
    this.props.data.required;

  render() {
    const { data, location, onChange, t } = this.props;

    const { item } = queryString.parse(location.search);

    const styles = {
      toggleContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
    };

    return (
      <>
        {data && data.voteForm ? (
          <Container>
            <SectionHeader
              title={t('Field Label')}
              tooltip={t(
                'Provide a helpful label to your users for this field.',
              )}
            />

            {data.type === 'dropdown' && (
              <div style={styles.toggleContainer}>
                <SectionHeader
                  title={t('Multiple field')}
                  tooltip={t('Users will be add multiple fields')}
                  tooltipType={'info'}
                  actions={
                    <Toggle
                      id="form-field-multiple-toggle"
                      toggled={data.multiple}
                      onToggle={(field, value) =>
                        this.handleToggle('multiple', value, true)
                      }
                      trackSwitchedStyle={{
                        backgroundColor: themeStyles.primaryColorLight,
                      }}
                      thumbSwitchedStyle={{
                        backgroundColor: themeStyles.primaryColor,
                      }}
                    />
                  }
                />
              </div>
            )}

            <TextField
              id="form-field-label-field"
              value={data.label || ''}
              onChange={this.handleChange('label')}
              placeholder={t('Label')}
              isValid={data.label !== ''}
              errorText={t('You need to provide either a question or a label')}
            />
            {data.type === 'dropdown' && (
              <TextField
                id="options"
                placeholder={t('Dropdown Options')}
                label={t('Dropdown Options')}
                multiLine
                value={data.options || ''}
                onChange={value =>
                  value.length >= 2
                    ? this.handleDropdownOption('options', value, true)
                    : null
                }
                rightInputAction={
                  <CircularValidation
                    isValid={data.options && data.options.split(',').length > 1}
                    defaultMsg={t('Add multiple options using comma separated')}
                    errorMsg={t('Options is required')}
                    showDefault={!data.options}
                  />
                }
              />
            )}
          </Container>
        ) : (
          <Container>
            <SectionHeader title={t('Avatar')} />
            <ImageUpload
              title={t('Vote Item')}
              src={data && data.avatar}
              firebaseUrl={this.getAvatarUrl(item)}
              height="160px"
              width="160px"
              avatar
            />
            <TextField
              id="vote-title"
              placeholder={t('Title')}
              value={data && data.title}
              onChange={value => onChange('title', value)}
            />
            <TextField
              id="vote-subtitle"
              placeholder={t('Subtitle')}
              value={data && data.subtitle}
              onChange={value => onChange('subtitle', value)}
            />
          </Container>
        )}
      </>
    );
  }
}

export default withTranslation()(VoteItem);
