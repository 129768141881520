import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NewMember from './NewMember';

import { addGroup, closeMemberGroupDialog, formFieldChange } from './actions';

const mapStateToProps = state => ({
  ...state.auth.workforceMembers.listPage.createModal,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(addGroup(ownProps.match.params.appname)),
  onRequestClose: () => dispatch(closeMemberGroupDialog()),
  onFieldChange: (field, value) => dispatch(formFieldChange(field, value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewMember),
);
