import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import firebase from '../../../../../../firebase';
import {
  ADD_APPOINTMENTS_DIALOG,
  APPOINTMENT_ITEM_CHANGE,
  APPOINTMENT_RESET_DATA,
} from './types';
import i18n from 'i18next';

export const addAppointmentDialogOpen = isOpen => ({
  type: ADD_APPOINTMENTS_DIALOG,
  payload: isOpen,
});

export const appointmentItemChange = item => ({
  type: APPOINTMENT_ITEM_CHANGE,
  payload: item,
});

export const resetAppointmentData = () => ({
  type: APPOINTMENT_RESET_DATA,
});

export const deleteAppointmentSlot = (
  appname,
  slotIndex,
  appointmentKey,
) => async (dispatch, getState) => {
  dispatch({ type: 'TOGGLE_LOADING', payload: true });
  const myHeaders = new Headers();
  await firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);

      let payload = {
        appname,
        slotIndex,
        appointmentKey,
      };

      return fetch(
        `${process.env.REACT_APP_BOOKING_API}/delete_appointment_slot`,
        {
          method: 'post',
          headers: myHeaders,
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      );
    })
    .then(res => {
      dispatch({ type: 'TOGGLE_LOADING', payload: false });
      return res.json();
    })
    .catch(error => {
      dispatch({ type: 'TOGGLE_LOADING', payload: false });
      dispatch(
        openSnackbar(i18n.t('An error occurred while deleting the slot.')),
      );
      console.log(error);
    });
};
