import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Panel from '../../../../../../app/components/panel';

import './style.css';

const Intro = ({ match }) => {
  const { t } = useTranslation();

  return (
    <Panel style={{ position: 'relative', margin: '20px 0px' }}>
      <div className="marketplace-intro-content">
        <img
          src={require('./marketplace.svg')}
          alt={t('screenshot of member login feature')}
        />

        <p>
          <Trans>
            The Marketplace feature works hand in hand with the Marketplace
            component which can be added from the Components menu in the section
            Build -> Pages.
          </Trans>
        </p>
        <p>
          <Trans>
            The Marketplace feature allows you to create a marketplace app where
            buyers can meet sellers and facilitate online sales via the app.
            Your buyers and sellers can sign up for the app and create their
            buyer and seller accounts. Sellers can add products and services to
            your app, assign their pricing and sell them via the app if you
            enable online payments in the Settings section.
          </Trans>
        </p>
        <p>
          <Trans>
            You can monetize your marketplace app by applying an additional
            transaction fee to each sale made via the app. All payments will be
            collected through the Stripe payment gateway.
          </Trans>
        </p>
      </div>
    </Panel>
  );
};

Intro.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Intro;
