// this simply keeps track of whether the initial draft data has been loaded
const loaded = (state = {}, action) => {
  switch (action.type) {
    case 'LOADING_DRAFT_CONTENT':
      return {
        ...state,
        [action.appname]: false,
      };

    case 'DRAFT_CONTENT_LOADED':
      return {
        ...state,
        [action.appname]: true,
      };

    default:
      return state;
  }
};

export default loaded;
