import firebase from '../../../firebase';

export const setAppPreference = data => ({
  type: 'SET_APP_PREFERENCE',
  data,
});

export const fetchAppPreference = appname => async dispatch => {
  const appThemeRef = firebase.database().ref(`apps/${appname}/preferences`);
  try {
    const snapshot = await appThemeRef.once('value');
    const data = await snapshot.val();
    const appPreference = {
      appColor: data && data.color ? data.color : 'rgba(40,31,214,1)',
      appLogo: data && data.logo ? data.logo : null,
    };
    dispatch(setAppPreference(appPreference));
  } catch (err) {
    console.error(err);
  }
};

export const updateAppThemeColor = (appname, color) => async dispatch => {
  try {
    const appThemeRef = firebase.database().ref(`apps/${appname}/preferences`);
    appThemeRef.update({ color });

    dispatch(fetchAppPreference(appname));
  } catch (err) {
    console.error(err);
  }
};
