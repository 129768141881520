import { newValidator } from '../../../../../../../../utils';

const nameValidator = newValidator({
  minLength: 1,
  required: true,
});

const dateValidator = newValidator({
  date: true,
});

const dropdownValidator = newValidator({
  dropdown: true,
});

const numberValidator = newValidator({
  number: true,
});

const fileValidator = newValidator({
  dropZoneFile: true,
});

export const validateField = {
  type: value => nameValidator(value),
  Text: value => nameValidator(value),
  Date: value => dateValidator(value),
  ExpiryDate: value => dateValidator(value),
  Location: value => nameValidator(value),
  Number: value => numberValidator(value),
  File: value => fileValidator(value),
  Dropdown: value => dropdownValidator(value),
};
