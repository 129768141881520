import React from 'react';

const Phone = () => (
  <svg version="1.1" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
    <title />
    <desc />
    <defs />
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g id="Social_icons" transform="translate(-522.000000, -252.000000)">
        <g id="Viber" transform="translate(522.000000, 252.000000)">
          <circle
            cx="30"
            cy="30"
            fill="#7F4DA0"
            r="30"
            transform="translate(30.000000, 30.000000) scale(1, -1) translate(-30.000000, -30.000000) "
          />
          <path
            d="M33.4689363,28.263105 C34.6371056,28.0144158 35.5325751,27.5703632 36.2974877,26.8539246 C37.2818143,25.9244133 37.8215144,24.7994178 38.0586427,23.1830092 C38.2187417,22.1290929 38.1535182,21.7146584 37.7799245,21.3712372 C37.4300855,21.0515238 36.7837921,21.0396699 36.3923632,21.3416301 C36.1077367,21.5548017 36.0187695,21.7797832 35.9535461,22.389592 C35.8765058,23.2007735 35.734143,23.7691652 35.4910953,24.2961509 C34.9692194,25.415181 34.0501163,25.9954376 32.4965365,26.1849015 C31.7670744,26.273734 31.5476713,26.3566121 31.3105321,26.6348536 C30.8776233,27.1499853 31.0436307,27.9848196 31.6425469,28.2927232 C31.8679684,28.4052085 31.9628439,28.4170623 32.4608661,28.3874551 C32.7692363,28.3696908 33.2258887,28.3164199 33.4689363,28.263105 L33.4689363,28.263105 Z"
            fill="#FFFFFF"
            id="Path"
            transform="translate(34.603956, 24.762180) rotate(-85.000000) translate(-34.603956, -24.762180) "
          />
          <path
            d="M32.2058916,29.5601833 C35.8053739,29.0332635 38.5923354,27.3635401 40.4187249,24.651809 C41.4446304,23.1242329 42.0850153,21.3302142 42.3044294,19.405957 C42.3814697,18.7013613 42.3814697,17.41652 42.298521,17.2033484 C42.2213707,17.0020856 41.9723156,16.7296886 41.7588099,16.6172473 C41.527579,16.4988076 41.0354762,16.5106066 40.7626665,16.6527539 C40.3060141,16.8836349 40.1696697,17.2507638 40.1696697,18.2454548 C40.1696697,19.7789304 39.7723324,21.395339 39.0845592,22.6505731 C38.3018004,24.0834173 37.1633052,25.2675722 35.7757329,26.0906076 C34.58382,26.8011027 32.8226539,27.3280334 31.2156675,27.458272 C30.6344876,27.5056874 30.3142896,27.6240831 30.0948865,27.8786718 C29.7569633,28.263554 29.7212928,28.7845853 30.0059194,29.2167731 C30.3142786,29.6963762 30.7886562,29.7733549 32.2058916,29.5601833 L32.2058916,29.5601833 Z"
            fill="#FFFFFF"
            id="Path"
            transform="translate(36.088051, 23.104534) rotate(-85.000000) translate(-36.088051, -23.104534) "
          />
          <path
            d="M18.2411681,48.688127 C18.4487543,48.6171027 18.7689523,48.4513465 18.952795,48.3329069 C20.0793854,47.5868501 23.2163069,43.5784899 24.2422124,41.5771991 C24.8292017,40.4345052 25.0248612,39.587817 24.8411286,38.9602 C24.6512675,38.2852225 24.3370879,37.9299474 22.9316804,36.7990524 C22.3683356,36.3432009 21.8405514,35.8754406 21.7576027,35.7510905 C21.544108,35.4431869 21.3720822,34.8392776 21.3720822,34.4129783 C21.3781006,33.4241867 22.0184856,31.6301681 22.8605485,30.2505948 C23.5127503,29.1789252 24.6809195,27.8053613 25.837272,26.75139 C27.1951923,25.5079548 28.3930136,24.6613765 29.7450255,23.9922435 C31.4824479,23.127846 32.5439248,22.908775 33.3207642,23.2698945 C33.5164236,23.358727 33.7239109,23.4771667 33.7892334,23.5303827 C33.8484384,23.5837085 34.3050908,24.1403014 34.803212,24.7559986 C35.763795,25.9639162 35.9831981,26.1592796 36.6414183,26.3842611 C37.4775728,26.668457 38.3314524,26.5915333 39.1912405,26.1533801 C39.8435523,25.8158584 41.2666961,24.9336636 42.1857993,24.2942367 C43.3954374,23.4475485 45.980842,21.3397158 46.3308021,20.9192721 C46.9474434,20.1614164 47.0542458,19.1904881 46.6390732,18.1187085 C46.2002561,16.9879234 44.4924858,14.8681929 43.3005729,13.9622795 C42.2213707,13.1451985 41.4564582,12.8314944 40.4483769,12.7840789 C39.6182409,12.7426619 39.2742992,12.8136861 38.2128223,13.2518393 C29.8873773,16.6800198 23.2400395,21.7955674 17.9624389,28.8236941 C15.2051295,32.4946095 13.1059302,36.3017069 11.6708596,40.2509188 C10.8348152,42.5541038 10.7933354,43.5547492 11.4811086,44.7329937 C11.777662,45.2303172 13.0407068,46.4618876 13.9598099,47.148675 C15.489756,48.2855134 16.1953645,48.7059132 16.7587092,48.824298 C17.1441307,48.907209 17.8141677,48.8420843 18.2411681,48.688127 L18.2411681,48.688127 Z"
            fill="#FFFFFF"
            id="Path"
            transform="translate(28.944384, 30.817210) rotate(-90.000000) translate(-28.944384, -30.817210) "
          />
          <path
            d="M31.7967264,31.280856 C33.8366106,31.0262673 35.4850769,30.5348543 37.2937301,29.6526485 C39.0726214,28.7822417 40.2111276,27.9592613 41.7173301,26.4613143 C43.128646,25.0462234 43.9114048,23.9745098 44.7415409,22.3107847 C45.8978933,19.9897804 46.5561135,17.2306889 46.6687143,14.1933009 C46.7102931,13.1571928 46.6806411,12.926213 46.4433918,12.6302182 C45.9927578,12.0558171 45.0024128,12.150659 44.6644896,12.7959744 C44.5576872,13.009146 44.5280352,13.1926116 44.4924637,14.0215903 C44.4331487,15.2945778 44.3442916,16.1175582 44.1663573,17.1004503 C43.4666573,20.9548752 41.6165241,24.0337352 38.6634452,26.2422432 C36.2025902,28.0895108 33.6586654,28.9895248 30.3261834,29.1848882 C29.199494,29.250013 29.0038236,29.2914191 28.74886,29.4868374 C28.2743724,29.8598657 28.2507388,30.7361171 28.7072812,31.1446521 C28.9859993,31.3992957 29.1816588,31.4348023 30.1482602,31.4051952 C30.6523008,31.3874418 31.3934807,31.3282275 31.7967264,31.280856 L31.7967264,31.280856 Z"
            fill="#FFFFFF"
            id="Path"
            transform="translate(37.531483, 21.832234) rotate(-85.000000) translate(-37.531483, -21.832234) "
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Phone;
