import styled from 'styled-components';

const ImagePreviewButton = styled.button`
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.height || '200px'};
  width: ${props => props.width || '100%'};
  padding: 0;
  margin: 24px auto;
  border: 1px solid var(--primaryColor);
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: ${props => (props.avatar ? '100%' : '17px')};
  overflow: hidden;
  background-color: transparent;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.02), 0 2px 10px 0 rgba(0, 0, 0, 0.04); */
  transition: all 300ms ease-out;

  &:hover {
    background-color: ${props => !props.disabled && 'rgba(0, 0, 0, 0.2)'};
  }
`;

export default ImagePreviewButton;
