import { connect } from 'react-redux';

import AppName from './AppName';
import { loadAppNameData, saveChanges, updateMetaData } from './actions';

const mapStateToProps = (state, ownProps) => ({
  appDomain: {
    ...state.editor.internals.appDomainInput,
    domain:
      state.editor.internals.appDomainInput.domain ||
      ownProps.match.params.appname,
  },
  isUpdating: state.editor.shared.appName.isUpdating,
  ...state.editor.shared.appName[ownProps.match.params.appname],
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData: () => dispatch(loadAppNameData(ownProps.match.params.appname)),
  updateValue: (attr, value, validations) =>
    dispatch(
      updateMetaData(ownProps.match.params.appname, attr, value, validations),
    ),
  onSubmit: (meta, domain) => {
    dispatch(saveChanges(ownProps.match.params.appname, meta, domain));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppName);
