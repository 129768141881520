import { combineReducers } from 'redux';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import _pickBy from 'lodash/pickBy';
import createModal from './modules/createModal/reducer';

import {
  REQUEST_WORKFORCE_MEMBERS,
  RECEIVE_WORKFORCE_MEMBERS,
  REPORT_WORKFORCE_MEMBERS_REQUEST_ERROR,
  SELECT_WORKFORCE_MEMBER,
  FILTER_WORKFORCE_MEMBER_DATA,
  SELECT_ALL_WORKFORCE_MEMBERS,
  DESELECT_ALL_WORKFORCE_MEMBERS,
  RECEIVE_WORKFORCE_GROUP_DATA,
} from './actions';

const initialState = {
  pending: false,
  chunks: {},
  selected: [],
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  allGroupsData: {},
};

const filterChunks = (chunks, filterHolder = '') => {
  let filteredChunks = chunks;
  // const filter = filterHolder.replace(/[^a-zA-Z0-9 ]/g, '');

  if (filterHolder !== '') {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`(${filterHolder})`, 'gi');

      return (
        rgx.test(chunk.firstName) ||
        rgx.test(chunk.lastName) ||
        rgx.test(chunk.email)
      );
    });
  }
  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const fields = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_WORKFORCE_MEMBERS:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_WORKFORCE_MEMBERS:
      return {
        ...state,
        chunks: action.value,
        selected: [],
        filteredChunks: action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };

    case RECEIVE_WORKFORCE_GROUP_DATA:
      return {
        ...state,
        allGroupsData: action.value,
      };
    case FILTER_WORKFORCE_MEMBER_DATA:
      return {
        ...state,
        ...filterChunks(state.chunks, action.payload),
        filter: action.payload,
      };

    case REPORT_WORKFORCE_MEMBERS_REQUEST_ERROR:
      return {
        pending: false,
        error: action.err,
      };

    case SELECT_WORKFORCE_MEMBER: {
      const prevState = state.filteredChunks[action.key].selected;
      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case SELECT_ALL_WORKFORCE_MEMBERS: {
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: !chunk.default,
      }));
      const selected = _union(
        state.selected,
        Object.keys(filteredChunks).filter(chunk => chunk !== 'default'),
      );

      return {
        ...state,
        filteredChunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_WORKFORCE_MEMBERS:
      return {
        ...state,
        filteredChunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    default:
      return state;
  }
};

export default combineReducers({
  data: fields,
  createModal,
});
