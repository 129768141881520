import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddIcon from 'material-ui/svg-icons/content/add';
import { withTranslation } from 'react-i18next';
import PageHeader from '../../app/components/pageHeader';
import { CTAButton, FloatingActionButton } from '../../app/components/buttons';
import { HS_FEATURES_ENABLED_APPS } from '../../config';

import Users from './modules/users';
// import Admins from './modules/admins';
import CustomEmails from './modules/customEmails';
import Groups from './modules/groups';
import Reports from './modules/reports';
import Types from './modules/memberTypes';
import Events from '../staff/modules/events';
import Courses from '../staff/modules/courses';
import EventsAttendees from '../staff/modules/events-attendees';
import CoursesAttendees from '../staff/modules/courses-attendees';
import CPDs from '../staff/modules/cpds';
import WorkforceMembers from './modules/workforceMembers';
import Staff from './modules/staff';
import MemberBodies from './modules/memberBodies';
import Settings from './modules/settings';
import UpgradeDialog from './modules/upgradeDialog';
import Intro from './modules/intro';
import AccountsDropdown from '../editor/internals/accountsDropdown';
import Subscriptions from './modules/subscriptions';
import Transactions from './modules/Transactions';
import Diary from './modules/Diary';
import DiarySettings from './modules/Diary/settings';
// import CustomEmailsAdmin from './modules/customEmailsAdmins';

class Auth extends Component {
  static propTypes = {
    groups: PropTypes.object.isRequired,
    onAddGroup: PropTypes.func.isRequired,
    onAddStaff: PropTypes.func.isRequired,
    onAddMemberBody: PropTypes.func.isRequired,
    onAddType: PropTypes.func.isRequired,
    onAddWorkforce: PropTypes.func.isRequired,
    onAddField: PropTypes.func.isRequired,
    enabled: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    getGroups: PropTypes.func.isRequired,
    getStaffs: PropTypes.func.isRequired,
    getAppAdmins: PropTypes.func.isRequired,
    getMemberBodies: PropTypes.func.isRequired,
    backToGroup: PropTypes.func.isRequired,
    backToWorkforceList: PropTypes.func.isRequired,
    getTypes: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    removeGroupsAndUsersListeners: PropTypes.func.isRequired,
    onPublish: PropTypes.func.isRequired,
    loadContent: PropTypes.func,
    isPublished: PropTypes.bool,
    edited: PropTypes.bool,
    addIsPublishedListener: PropTypes.func.isRequired,
    removeIsPublishedListener: PropTypes.func.isRequired,
    getWorkforceList: PropTypes.func.isRequired,
    onAddEvent: PropTypes.func.isRequired,
    onAddCourse: PropTypes.func.isRequired,
    onAddCPD: PropTypes.func.isRequired,
    onAddAdmin: PropTypes.func.isRequired,
    onAddEventCalendarComponent: PropTypes.func,
    eventComponentEnabled: PropTypes.bool,
    componentsPending: PropTypes.bool,
    fetchedRoutesData: PropTypes.bool,
    role: PropTypes.string,
    memberLoginSectionsAccess: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isPublished: false,
    edited: false,
  };

  componentDidMount() {
    this.props.getGroups();
    this.props.getStaffs();
    this.props.getAppAdmins();
    this.props.getMemberBodies();
    this.props.getTypes();
    this.props.getUsers();
    this.props.addIsPublishedListener();
    this.props.getWorkforceList();
    this.props.loadSubscriptions();
    this.props.loadDiaryData();
    if (!this.props.fetchedRoutesData) {
      this.props.loadContent();
    }
  }

  componentWillUnmount() {
    this.props.removeGroupsAndUsersListeners();
    this.props.removeIsPublishedListener();
  }

  publishButtonProps = () => {
    const { groups, isPublished } = this.props;

    if (!groups) {
      return {};
    }

    const hasUpdateReady = Object.keys(groups).reduce((val, key) => {
      const group = groups[key];

      if (val === true || !group.last_updated) {
        return val;
      }

      if (
        (group.last_updated && !group.last_published) ||
        group.last_updated > group.last_published
      ) {
        return true;
      }

      return val;
    }, false);

    if (hasUpdateReady || !isPublished) {
      return {
        // borderColor: 'rgb(156, 150, 6)',
        outlineRGB: '195, 207, 47',
      };
    }

    return {};
  };

  render() {
    const {
      onAddGroup,
      onAddStaff,
      onAddAdmin,
      onAddType,
      onAddWorkforce,
      onAddField,
      backToGroup,
      backToWorkforceList,
      match,
      enabled,
      onAddMemberBody,
      onAddEvent,
      onAddCourse,
      onAddCPD,
      onAddEventCalendarComponent,
      componentsPending,
      eventComponentEnabled,
      role,
      memberLoginSectionsAccess,
      t,
    } = this.props;
    return (
      <div className="flex-container sidebar-extended background-texture overflow-scroll-container">
        <PageHeader
          title={t('Member Login')}
          userSettings={<AccountsDropdown />}
          actions={
            <div style={{ paddingRight: '10px' }}>
              {memberLoginSectionsAccess && (
                <Switch>
                  <Route
                    path="/:appname/members/groups"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={onAddGroup}
                          />
                        </div>
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <CTAButton
                            label={t('Publish')}
                            {...this.publishButtonProps()}
                            onClick={this.props.onPublish}
                          />
                        </div>
                      </div>
                    )}
                  />

                  <Route
                    path="/:appname/members/staff"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={onAddStaff}
                          />
                        </div>
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <CTAButton
                            label={t('Publish')}
                            {...this.publishButtonProps()}
                            onClick={this.props.onPublish}
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path="/:appname/members/types/:groupID"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={onAddField}
                          />
                        </div>
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <CTAButton label="Back" onClick={backToGroup} />
                        </div>
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <CTAButton
                            label={t('Publish')}
                            {...this.publishButtonProps()}
                            onClick={this.props.onPublish}
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path="/:appname/members/types"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={onAddType}
                          />
                        </div>
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <CTAButton
                            label={t('Publish')}
                            {...this.publishButtonProps()}
                            onClick={this.props.onPublish}
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    exact
                    path="/:appname/members/workforce"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={onAddWorkforce}
                          />
                        </div>
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <CTAButton
                            label={t('Publish')}
                            {...this.publishButtonProps()}
                            onClick={this.props.onPublish}
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path="/:appname/members/workforce/:memberID"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <CTAButton
                            label={t('Back')}
                            onClick={backToWorkforceList}
                          />
                        </div>
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <CTAButton
                            label={t('Publish')}
                            {...this.publishButtonProps()}
                            onClick={this.props.onPublish}
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path="/:appname/members/member-bodies"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={onAddMemberBody}
                          />
                        </div>
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <CTAButton
                            label={t('Publish')}
                            {...this.publishButtonProps()}
                            onClick={this.props.onPublish}
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path="/:appname/members/courses"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={onAddCourse}
                          />
                        </div>
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <CTAButton
                            label={t('Publish')}
                            {...this.publishButtonProps()}
                            onClick={this.props.onPublish}
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path="/:appname/members/events"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={onAddEvent}
                          />
                        </div>
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <CTAButton
                            label={t('Publish')}
                            {...this.publishButtonProps()}
                            onClick={this.props.onPublish}
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Route
                    path="/:appname/members/cpds"
                    render={() => (
                      <div className="row middle-xs">
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <FloatingActionButton
                            icon={<AddIcon />}
                            onClick={onAddCPD}
                          />
                        </div>
                        <div className="col" style={{ paddingRight: '10px' }}>
                          <CTAButton
                            label={t('Publish')}
                            {...this.publishButtonProps()}
                            onClick={this.props.onPublish}
                          />
                        </div>
                      </div>
                    )}
                  />
                </Switch>
              )}
            </div>
          }
        />
        <UpgradeDialog />
        {memberLoginSectionsAccess && (
          <Switch>
            {enabled && (
              <Redirect
                exact
                from={`/${match.params.appname}/members`}
                to={
                  HS_FEATURES_ENABLED_APPS.indexOf(match.params.appname) > -1
                    ? `/${match.params.appname}/members/member-bodies`
                    : `/${match.params.appname}/members/groups`
                }
              />
            )}
            {!enabled && (
              <Redirect
                exact
                from={`/${match.params.appname}/members/users`}
                to={`/${match.params.appname}/members`}
              />
            )}
            {!enabled && (
              <Redirect
                exact
                from={`/${match.params.appname}/members/groups`}
                to={`/${match.params.appname}/members`}
              />
            )}
            {!enabled && (
              <Redirect
                exact
                from={`/${match.params.appname}/members/subscriptions`}
                to={`/${match.params.appname}/members`}
              />
            )}
            {!enabled && (
              <Redirect
                exact
                from={`/${match.params.appname}/members/transactions`}
                to={`/${match.params.appname}/members`}
              />
            )}
            <Route path="/:appname/members" component={Intro} exact />
            <Route path="/:appname/members/setup" component={Settings} />
            <Route path="/:appname/members/settings" component={Settings} />
            <Route
              path="/:appname/members/subscriptions"
              component={Subscriptions}
            />
            <Route
              path="/:appname/members/transactions"
              component={Transactions}
            />
            <Route path="/:appname/members/users" component={Users} />
            {/* <Route path="/:appname/members/admins" component={Admins} /> */}
            {/* <Route
              path="/:appname/members/custom-emails-admins"
              component={CustomEmailsAdmin}
            /> */}
            <Route path="/:appname/members/staff" component={Staff} />
            <Route
              path="/:appname/members/custom-emails"
              component={CustomEmails}
            />
            <Route
              path="/:appname/members/member-bodies"
              component={MemberBodies}
            />
            <Route
              path="/:appname/members/events"
              render={() => (
                <Events
                  componentsPending={componentsPending}
                  eventComponentEnabled={eventComponentEnabled}
                  onAddEventCalendarComponent={onAddEventCalendarComponent}
                  role={role}
                />
              )}
            />
            <Route
              path="/:appname/members/courses"
              render={() => (
                <Courses
                  componentsPending={componentsPending}
                  eventComponentEnabled={eventComponentEnabled}
                  onAddEventCalendarComponent={onAddEventCalendarComponent}
                  role={role}
                />
              )}
            />
            <Route
              path="/:appname/members/events-attendees"
              render={() => (
                <EventsAttendees
                  componentsPending={componentsPending}
                  eventComponentEnabled={eventComponentEnabled}
                  onAddEventCalendarComponent={onAddEventCalendarComponent}
                  role={role}
                />
              )}
            />
            <Route
              path="/:appname/members/courses-attendees"
              render={() => (
                <CoursesAttendees
                  componentsPending={componentsPending}
                  eventComponentEnabled={eventComponentEnabled}
                  onAddEventCalendarComponent={onAddEventCalendarComponent}
                  role={role}
                />
              )}
            />
            <Route path="/:appname/members/cpds" component={CPDs} />
            <Route path="/:appname/members/types" component={Types} />
            <Route
              path="/:appname/members/workforce"
              component={WorkforceMembers}
            />
            <Route path="/:appname/members/groups" component={Groups} />
            <Route path="/:appname/members/analytics" component={Reports} />
            <Route path="/:appname/members/diary" component={Diary} />
            <Route
              path="/:appname/members/diary-settings"
              component={DiarySettings}
            />
          </Switch>
        )}
      </div>
    );
  }
}

export default withTranslation()(Auth);
