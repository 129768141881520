import React from 'react';
import UsersTable from '../../components/UsersTable';
import UsersDialog from '../../components/UsersDialog';
import UsersDetailsDialog from '../../components/UsersDetailsDialog';

function Groups() {
  return (
    <>
      <UsersTable />
      <UsersDialog />
      <UsersDetailsDialog />
    </>
  );
}

export default Groups;
