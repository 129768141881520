import React from 'react';
import { Trans } from 'react-i18next';
import './styles.css';

const Social = () => (
  <svg
    className="icon rating-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44.22 43.63"
  >
    <title>
      <Trans>Rating Data</Trans>
    </title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M22,0,20,4.32l-4.71.77,3.48,3.27L18,13.08l4.18-2.3,4.27,2.14-.9-4.69L29,4.84l-4.74-.6ZM24,7.74l.47,2.48L22.19,9.08,20,10.3l.38-2.49L18.52,6.08,21,5.67l1.07-2.28,1.16,2.24,2.5.31Z"
        />
        <path
          class="cls-1"
          d="M6.74,0l-2,4.32L0,5.09,3.48,8.36l-.72,4.72L7,10.78l4.26,2.14-.89-4.69,3.35-3.39-4.74-.6ZM8.69,7.74l.48,2.48L6.91,9.08,4.7,10.3l.38-2.49L3.24,6.08l2.49-.41L6.81,3.39,8,5.63l2.51.31Z"
        />
        <path
          class="cls-1"
          d="M24.21,19.52,22,15.28,20,19.6l-4.71.77,3.48,3.27L18,28.36l4.18-2.3,4.27,2.14-.9-4.69L29,20.11Zm.23,6-2.25-1.13L20,25.58l.38-2.5-1.84-1.72L21,21l1.07-2.29,1.16,2.25,2.5.31L24,23Z"
        />
        <path
          class="cls-1"
          d="M6.74,15.28l-2,4.32L0,20.37l3.48,3.27-.72,4.72L7,26.06l4.26,2.14-.89-4.69,3.35-3.4-4.74-.59ZM8.69,23l.48,2.47L6.91,24.36,4.7,25.58l.38-2.5L3.24,21.36,5.73,21l1.08-2.29L8,20.91l2.51.31Z"
        />
        <path
          class="cls-1"
          d="M8.93,34.79,6.74,30.55l-2,4.32L0,35.65l3.48,3.26-.72,4.72L7,41.33l4.26,2.14-.89-4.68,3.35-3.4Zm.24,6L6.91,39.64,4.7,40.85l.38-2.49L3.24,36.63l2.49-.41,1.08-2.28L8,36.18l2.51.32L8.69,38.29Z"
        />
        <path
          class="cls-1"
          d="M44.22,4.84l-4.73-.6L37.29,0l-2,4.32-4.71.77L34,8.36l-.72,4.72,4.19-2.3,4.26,2.14-.89-4.69ZM37.47,9.08,35.25,10.3l.38-2.49L33.8,6.08l2.48-.41,1.08-2.28,1.16,2.24,2.5.31-1.77,1.8.47,2.48Z"
        />
      </g>
    </g>
  </svg>
);

export default Social;
