import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Trans, withTranslation } from 'react-i18next';

import Table from '../../../../app/components/table';
import TryAgain from '../../../../app/components/tryAgain';
import Header from '../../components/header';

class Subscriptions extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.array,
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    chunks: PropTypes.array,
    loadSubscriptions: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    onFilterSubscriptionData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    filter: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
    chunks: [],
    filteredChunks: [],
    items: [],
    query: '',
    selected: [],
    selectAll: false,
  };

  getTitle = (selected, subscriptions) => {
    const { t } = this.props;
    return (
      <span>
        <Trans>Subscribers:</Trans>{' '}
        {selected.length
          ? `${selected.length} / ${Object.keys(subscriptions).length}`
          : Object.keys(subscriptions).length}
      </span>
    );
  };

  render() {
    const {
      pending,
      items,
      error,
      loadSubscriptions,
      chunks,
      filteredChunks,
      onFilterSubscriptionData,
      selected,
      history,
      match,
      t,
    } = this.props;

    const body = error ? (
      <TryAgain
        message={t('Oops, something went wrong...')}
        onRetry={loadSubscriptions}
      />
    ) : (
      <Table
        items={items}
        chunks={filteredChunks}
        loading={pending}
        cols={[
          {
            id: 'subscriptionPlan',
            header: t('Subscription Plan'),
            classNames: 'col-xs-5',
            containerElement: ({ value }) => (
              <span>{value ? value.name : ''}</span>
            ),
          },
          {
            id: 'email',
            header: t('User'),
            classNames: 'col-xs-5',
          },
          {
            id: 'created',
            header: t('Date Created'),
            classNames: 'col-xs-2',
            containerElement: ({ value }) => (
              <span>{value && moment.unix(value).format('DD/MM/YYYY')}</span>
            ),
          },
        ]}
      />
    );

    return (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <Header
          {...this.props}
          title={this.getTitle(selected, chunks)}
          onChange={onFilterSubscriptionData}
        />
        {body}
      </section>
    );
  }
}

export default withTranslation()(Subscriptions);
