import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddIcon from 'material-ui/svg-icons/content/add';
import PageHeader from '../../app/components/pageHeader';
import { FloatingActionButton } from '../../app/components/buttons';
import MemberBodies from './modules/memberBodies';
import Events from './modules/events';
import CPDs from './modules/cpds';
import Courses from './modules/courses';
import AccountsDropdown from '../editor/internals/accountsDropdown';
import { HS_FEATURES_ENABLED_APPS } from '../../config';

class Auth extends React.PureComponent {
  static propTypes = {
    onAddEvent: PropTypes.func.isRequired,
    onAddCourse: PropTypes.func.isRequired,
    onAddCPD: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    loadContent: PropTypes.func,
    eventComponentEnabled: PropTypes.bool,
    componentsPending: PropTypes.bool,
    fetchedRoutesData: PropTypes.bool,
    role: PropTypes.string,
  };

  static defaultProps = {
    isPublished: false,
    edited: false,
  };

  componentDidMount() {
    if (!this.props.fetchedRoutesData) {
      this.props.loadContent();
    }
  }

  isHorseScotlandApp = () =>
    HS_FEATURES_ENABLED_APPS.indexOf(this.props.match.params.appname) > -1;

  render() {
    const {
      onAddEvent,
      onAddCourse,
      onAddCPD,
      profile,
      eventComponentEnabled,
      componentsPending,
      role,
    } = this.props;
    return (
      <div className="editor-body background-texture">
        <div className="flex-container" style={{ height: '100%' }}>
          <PageHeader
            title={
              <Switch>
                <Route
                  path="/:appname/staff/member-bodies"
                  render={() =>
                    this.isHorseScotlandApp()
                      ? 'Member Body'
                      : 'Event Organizers'
                  }
                />
                <Route path="/:appname/staff/events" render={() => 'Events'} />
                <Route
                  path="/:appname/staff/courses"
                  render={() => 'Courses'}
                />
                <Route path="/:appname/staff/cpds" render={() => 'CPDs'} />
              </Switch>
            }
            actions={
              <div>
                <Switch>
                  <Route
                    path="/:appname/staff/events"
                    render={() =>
                      profile.memberBodies ? (
                        <div className="row middle-xs">
                          <div className="col" style={{ paddingRight: '10px' }}>
                            <FloatingActionButton
                              icon={<AddIcon />}
                              onClick={onAddEvent}
                            />
                          </div>
                        </div>
                      ) : (
                        false
                      )
                    }
                  />
                  <Route
                    path="/:appname/staff/courses"
                    render={() =>
                      profile.memberBodies ? (
                        <div className="row middle-xs">
                          <div className="col" style={{ paddingRight: '10px' }}>
                            <FloatingActionButton
                              icon={<AddIcon />}
                              onClick={onAddCourse}
                            />
                          </div>
                        </div>
                      ) : (
                        false
                      )
                    }
                  />
                  <Route
                    path="/:appname/staff/cpds"
                    render={() =>
                      profile.memberBodies ? (
                        <div className="row middle-xs">
                          <div className="col" style={{ paddingRight: '10px' }}>
                            <FloatingActionButton
                              icon={<AddIcon />}
                              onClick={onAddCPD}
                            />
                          </div>
                        </div>
                      ) : (
                        false
                      )
                    }
                  />
                </Switch>
              </div>
            }
            userSettings={<AccountsDropdown />}
          />
          <Switch>
            <Route
              path="/:appname/staff/member-bodies"
              component={MemberBodies}
            />
            <Route
              path="/:appname/staff/events"
              render={() => (
                <Events
                  componentsPending={componentsPending}
                  eventComponentEnabled={eventComponentEnabled}
                  role={role}
                />
              )}
            />
            <Route
              path="/:appname/staff/courses"
              render={() => (
                <Courses
                  componentsPending={componentsPending}
                  eventComponentEnabled={eventComponentEnabled}
                  role={role}
                />
              )}
            />
            <Route path="/:appname/staff/cpds" component={CPDs} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Auth;
