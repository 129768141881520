import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { LoadingScreen } from '../../../../app/components/progress';

const beezerDomainList = [
  'https://create.beezer.com',
  'https://beezer-staging.firebaseapp.com',
];

class InstagramLogin extends Component {
  componentDidMount() {
    const { history } = this.props;
    if (history.location.search.includes('code')) {
      const { code, state } = queryString.parse(history.location.search);

      const parsedCode = code.split('=origin=')[0];
      const domain = code.split('=origin=')[1];

      if (domain && !beezerDomainList.includes(domain)) {
        const parsedDomain = domain.split('#_')[0];
        window.location.href = `${parsedDomain}/instagram_login?code=${parsedCode}#_`;
      } else {
        this.props.recordAccessToken(parsedCode, state, history);
      }
      // this.props.recordAccessToken(code, state, history);
    } else if (history.location.search.includes('error')) {
      // this.props.recordAccessToken('error', history);
      history.go(-3);
    }
  }
  render() {
    return <LoadingScreen loading />;
  }
}

InstagramLogin.propTypes = {
  recordAccessToken: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default InstagramLogin;
