import React from 'react';
import TextField from '@material-ui/core/TextField';
import Edit from '@material-ui/icons/Edit';
import { useDispatch } from 'react-redux';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { updatePageNames } from '../../modules/Diary/actions';

const Wrapper = styled.div`{
  textField: {
    width: 450,
    color: 'black',
    opacity: 1,
    borderBottom: 0,
    '&:before': {
      borderBottom: 0,
    },
  },
  textDisabled: {
    color: 'black',
    borderBottom: 0,
    '&:before': {
      backgroundColor:#ffff !important;
      borderBottom: 0,
    },
  },
  btnIcons: {
    marginLeft: 10,
  },
  
}`;

const styles = {
  helperText: {
    fontSize: '12px',
    display: 'block',
    marginTop: '2px',
  },
  error: {
    fontSize: '12px',
    color: '#ff0000',
  },
};

function EditableTextField(props) {
  const { t } = useTranslation();
  const [editMode, setEditMode] = React.useState(false);
  const [mouseOver, setMouseOver] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const dispatch = useDispatch();
  const { name, match, diaryPages = {}, maxLength } = props;

  React.useEffect(() => {
    diaryPages[name] && setInputValue(diaryPages[name] || '');
  }, [diaryPages[name]]);

  const handleChange = event => {
    // event.stopPropagation();
    // event.preventDefault();
    // String(event.target.value).length <= maxLength &&
    setInputValue(event.target.value);
  };
  const setFieldValue = value => {
    dispatch(updatePageNames(match.params.appname, name, value));
  };

  const handleMouseOver = event => {
    if (!mouseOver) {
      setMouseOver(true);
    }
  };

  const handleMouseOut = event => {
    if (mouseOver) {
      setMouseOver(false);
    }
  };

  const handleClick = () => {
    if (editMode) {
      const newInputValue = inputValue.slice(0, 16);
      setInputValue(newInputValue);
      setFieldValue(newInputValue);
    }

    setEditMode(!editMode);
    setMouseOver(false);
  };

  const helperText = `${t('Title should not be more than')} ${16} ${t(
    'characters',
  )}`;

  return (
    <Wrapper>
      <TextField
        name={name}
        value={inputValue}
        onChange={handleChange}
        className="textField"
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
        InputProps={{
          classes: {
            disabled: 'textDisabled',
          },
          endAdornment: mouseOver ? (
            <InputAdornment position="end">
              <IconButton onClick={handleClick}>
                {editMode ? (
                  <DoneIcon
                    style={{
                      color: inputValue.length > maxLength ? 'red' : 'green',
                    }}
                  />
                ) : (
                  <Edit />
                )}
              </IconButton>
            </InputAdornment>
          ) : (
            ''
          ),
        }}
        maxLength={maxLength}
        placeholder={t('Enter Title')}
        variant="standard"
        disabled={!editMode}
        color={inputValue.length > maxLength ? 'error' : 'success'}
      />
      <div>
        {inputValue.length > maxLength && editMode && (
          <span style={styles.error}>
            {maxLength - inputValue.length} {t('characters left')}
          </span>
        )}
        {helperText && <span style={styles.helperText}>{helperText}</span>}
      </div>{' '}
    </Wrapper>
  );
}

export default EditableTextField;
