import React from 'react';

import './styles.css';

const Close = () => (
  <svg
    className="icon close"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path d="M16,32A16,16,0,1,1,32,16,16,16,0,0,1,16,32ZM16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Z" />
      <path d="M21.45,22.45a1,1,0,0,1-.71-.29L9.84,11.25a1,1,0,1,1,1.41-1.41L22.16,20.75a1,1,0,0,1-.71,1.71Z" />
      <path d="M10.55,22.45a1,1,0,0,1-.71-1.71L20.75,9.84a1,1,0,1,1,1.41,1.41L11.25,22.16A1,1,0,0,1,10.55,22.45Z" />
    </g>
  </svg>
);

export default Close;
