import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import { useTranslation } from 'react-i18next';

import TextField from '../../../../app/components/textField';
import { BlockButton } from '../../../../app/components/buttons';
import { Close } from '../../../../app/components/icons';

import './styles.css';

const TabPageForm = ({ tabs = [], onTabChange, onAdd, onRemove }) => {
  const { t } = useTranslation();

  const mappedTabs = tabs.map((tab, index) => (
    <div key={tab.key}>
      <TextField
        id={tab.key}
        value={tab.title.value}
        isValid={tab.title.isValid}
        onChange={val => onTabChange(index, val)}
        placeholder={`${t('Tab')} ${t(index + 1)} ${t('Title')}`}
        maxLength={21}
        rightInputAction={
          index > 1 && (
            <IconButton
              onClick={() => onRemove(index)}
              tooltip={t('Remove Tab')}
            >
              <Close />
            </IconButton>
          )
        }
        required
      />
    </div>
  ));

  return (
    <div className="tab-form">
      {mappedTabs}
      {tabs.length < 4 && <BlockButton label={t('Add Tab')} onClick={onAdd} />}
    </div>
  );
};

TabPageForm.propTypes = {
  tabs: PropTypes.array.isRequired,
  onTabChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default TabPageForm;
