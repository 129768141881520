import {
  DOMAIN_UPDATED,
  CHECKING_DOMAIN_AVAILABILITY,
  SHOW_DOMAIN_INPUT,
  ASSERT_DOMAIN_AVAILABILITY,
  CREATING_INTEGRATION_APP,
  INTEGRATION_APP_CREATED,
} from './actions';

const initialState = {
  domain: null,
  showDomainInput: false,
  availableDomain: true,
  creatingApp: false,
  appCreated: false,
  fetchingDomain: false,
};

const createIntegrationApp = (state = initialState, action) => {
  switch (action.type) {
    case CHECKING_DOMAIN_AVAILABILITY:
      return {
        ...state,
        fetchingDomain: true,
      };

    case CREATING_INTEGRATION_APP:
      return {
        ...state,
        creatingApp: true,
      };

    case INTEGRATION_APP_CREATED:
      return {
        ...state,
        appCreated: true,
        creatingApp: false,
      };

    case ASSERT_DOMAIN_AVAILABILITY:
      return {
        ...state,
        availableDomain: action.available,
        fetchingDomain: false,
      };

    case SHOW_DOMAIN_INPUT:
      return {
        ...state,
        showDomainInput: true,
      };

    case DOMAIN_UPDATED: {
      return {
        ...state,
        domain: action.domain,
      };
    }

    default:
      return state;
  }
};

export default createIntegrationApp;
