import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import SidebarHeader from './SidebarHeader';
import { launchRemoveDialog } from '../remove/actions';
import { openCreateComponentUI, getSpecialComponents } from '../create/actions';

const mapStateToProps = (state, ownProps) => {
  const { item, component, page } = queryString.parse(ownProps.location.search);
  const pageData = state.editor.data.alwaysData[page];
  const componentData = state.editor.data.alwaysData[component];
  const lazyData =
    componentData && state.editor.data.lazyData[componentData.lazy_data];
  const itemData = lazyData && lazyData.chunks && lazyData.chunks[item];

  const fieldsData =
    lazyData &&
    lazyData.fields &&
    lazyData.fields.chunks &&
    lazyData.fields.chunks[item];

  return {
    ...ownProps,
    page: pageData,
    component: componentData,
    item: itemData,
    fields: fieldsData,
    isAppAdmin:
      (state.account.auth.profile.admin_appname &&
        state.account.auth.profile.admin_appname.length > 0) ||
      false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDeleteComponent: () => {
    const query = queryString.parse(ownProps.location.search);

    return dispatch(
      launchRemoveDialog(
        ownProps.match.params.appname,
        query,
        'COMPONENT',
        ownProps.history,
      ),
    );
  },
  onDeleteItem: () => {
    const query = queryString.parse(ownProps.location.search);

    return dispatch(
      launchRemoveDialog(
        ownProps.match.params.appname,
        query,
        'ITEM',
        ownProps.history,
      ),
    );
  },
  onDeletePage: () => {
    const query = queryString.parse(ownProps.location.search);

    return dispatch(
      launchRemoveDialog(ownProps.match.params.appname, query, 'PAGE'),
    );
  },
  onUndo: () => dispatch(),
  openCreateComponentUI: () => {
    dispatch(getSpecialComponents());
    dispatch(openCreateComponentUI());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarHeader),
);
