import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import Checkbox from 'material-ui/Checkbox';
import Divider from 'material-ui/Divider';
import { Provider, ReactReduxContext } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import TextField from '../../../../../../app/components/textField';
import { FlatButton } from '../../../../../../app/components/buttons';
import CircularValidation from '../../../../../../app/components/circularValidation';
import { CircularProgress } from '../../../../../../app/components/progress';
import ImageUpload from '../../../../../editor/internals/imageUpload';
import './styles.css';

const NewMemberBody = ({
  open,
  pending,
  error,
  onSubmit,
  onRequestClose,
  name,
  postalAddress,
  postCode,
  adminUsername,
  adminEmail,
  primaryContactName,
  primaryContactEmail,
  primaryContactNumber,
  secondaryContactName,
  secondaryContactEmail,
  secondaryContactNumber,
  landline,
  mobile,
  memberNumber,
  memberNumberMale,
  memberNumberFemale,
  memberNumberDisabled,
  memberNumberSmid,
  onFieldChange,
  filteredChunks,
  availableStaffMembers,
  onFieldChangeWithUniqueCheck,
  onFormFieldChangeNonRequired,
  staffs,
  disabledStaffs,
  banner,
  attachBanner,
  memberBodyFlag,
}) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    attachBanner();
  }, []);
  const [staffMembers, setStaffMembers] = useState({});

  const buttonSubmit = !pending ? (
    <FlatButton
      label={t('Create')}
      onClick={onSubmit}
      disabled={
        !name.isValid ||
        (memberBodyFlag && (!adminUsername.isValid || !adminEmail.isValid)) ||
        !primaryContactName.isValid ||
        !primaryContactEmail.isValid ||
        !primaryContactNumber.isValid
      }
      primary
    />
  ) : (
    <CircularProgress size={18} />
  );

  const onStaffMemberAsignment = (key, flag) => {
    staffMembers[key] = flag;
    setStaffMembers(staffMembers);
    onFormFieldChangeNonRequired('staffMembers', staffMembers);
  };

  return (
    <ReactReduxContext.Consumer>
      {(ctx => (
        <Dialog
          open={open}
          onRequestClose={onRequestClose}
          autoScrollBodyContent
        >
          <div className="user-memberBodies-modal-content">
            <div className="modal-heading">
              <h5>
                {memberBodyFlag
                  ? t(`Create a Member Body`)
                  : t('Create an Event Organizer')}
              </h5>
            </div>
            <div>
              <Divider />
              <div className="row">
                <div className="col-sm-6">
                  <div className="users-list-container">
                    <TextField
                      id="name"
                      placeholder={
                        memberBodyFlag
                          ? t('Member Body Name')
                          : t('Event Organizer Name')
                      }
                      {...name}
                      onChange={value =>
                        onFieldChangeWithUniqueCheck(
                          'name',
                          value,
                          filteredChunks,
                        )
                      }
                      rightInputAction={
                        <CircularValidation
                          isValid={name.isValid}
                          defaultMsg={
                            memberBodyFlag
                              ? t('Member Body Name required')
                              : t('Event Organizer Name Required')
                          }
                          errorMsg={
                            memberBodyFlag
                              ? t('Member Body Name already exists')
                              : t('Event Organizer Name already exists')
                          }
                          showDefault={!name.value}
                        />
                      }
                    />
                    <TextField
                      id="postalAddress"
                      placeholder={t('Postal Address')}
                      {...postalAddress}
                      onChange={value => onFieldChange('postalAddress', value)}
                    />
                    <TextField
                      id="postCode"
                      placeholder={
                        memberBodyFlag ? t('Post code Address') : t('Post Code')
                      }
                      {...postCode}
                      onChange={value => onFieldChange('postCode', value)}
                    />

                    {memberBodyFlag && (
                      <>
                        <TextField
                          id="adminUsername"
                          placeholder={t('Admin Username')}
                          {...adminUsername}
                          onChange={value =>
                            onFieldChange('adminUsername', value)
                          }
                          rightInputAction={
                            <CircularValidation
                              isValid={adminUsername.isValid}
                              defaultMsg={t('admin username required')}
                              errorMsg={t(
                                'admin username must be at least 2 characters long',
                              )}
                              showDefault={!adminUsername.value}
                            />
                          }
                        />

                        <TextField
                          id="adminEmail"
                          placeholder={t('Admin Email')}
                          {...adminEmail}
                          onChange={value => onFieldChange('adminEmail', value)}
                          rightInputAction={
                            <CircularValidation
                              isValid={adminEmail.isValid}
                              defaultMsg={t('admin email required')}
                              errorMsg={t('Please use a valid email address')}
                              showDefault={!adminEmail.value}
                            />
                          }
                        />
                      </>
                    )}

                    <TextField
                      id="primaryContactName"
                      placeholder={t('Primary Contact Name')}
                      {...primaryContactName}
                      onChange={value =>
                        onFieldChange('primaryContactName', value)
                      }
                      rightInputAction={
                        <CircularValidation
                          isValid={primaryContactName.isValid}
                          defaultMsg={t('Primary Contact Name required')}
                          errorMsg={t(
                            'Primary Contact Name must be at least 2 characters long',
                          )}
                          showDefault={!primaryContactName.value}
                        />
                      }
                    />

                    <TextField
                      id="primaryContactEmail"
                      placeholder={t('Primary Contact Email')}
                      {...primaryContactEmail}
                      onChange={value =>
                        onFieldChange('primaryContactEmail', value)
                      }
                      rightInputAction={
                        <CircularValidation
                          isValid={primaryContactEmail.isValid}
                          defaultMsg={t('Primary Contact Email required')}
                          errorMsg={t(
                            'Primary Contact Email must be at least 2 characters long',
                          )}
                          showDefault={!primaryContactEmail.value}
                        />
                      }
                    />

                    <TextField
                      id="primaryContactNumber"
                      placeholder={t('Primary Contact Number')}
                      {...primaryContactNumber}
                      onChange={value =>
                        onFieldChange('primaryContactNumber', value)
                      }
                      rightInputAction={
                        <CircularValidation
                          isValid={primaryContactNumber.isValid}
                          defaultMsg={t('Primary Contact Number required')}
                          errorMsg={t(
                            'Primary Contact Number must be at least 2 characters long',
                          )}
                          showDefault={!primaryContactNumber.value}
                        />
                      }
                    />

                    <TextField
                      id="secondaryContactName"
                      placeholder={t('Secondary Contact Name')}
                      {...secondaryContactName}
                      onChange={value =>
                        onFormFieldChangeNonRequired(
                          'secondaryContactName',
                          value,
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <TextField
                    id="secondaryContactEmail"
                    placeholder={t('Secondary Contact Email')}
                    {...secondaryContactEmail}
                    onChange={value =>
                      onFormFieldChangeNonRequired(
                        'secondaryContactEmail',
                        value,
                      )
                    }
                  />
                  <TextField
                    id="secondaryContactNumber"
                    placeholder={t('Secondary Contact Number')}
                    {...secondaryContactNumber}
                    onChange={value =>
                      onFormFieldChangeNonRequired(
                        'secondaryContactNumber',
                        value,
                      )
                    }
                  />

                  <TextField
                    id="landline"
                    placeholder={t('Landline')}
                    {...landline}
                    onChange={value =>
                      onFormFieldChangeNonRequired('landline', value)
                    }
                  />

                  <TextField
                    id="mobile"
                    placeholder={t('Mobile')}
                    {...mobile}
                    onChange={value =>
                      onFormFieldChangeNonRequired('mobile', value)
                    }
                  />
                  {memberBodyFlag && (
                    <React.Fragment>
                      <TextField
                        id="memberNumber"
                        placeholder={t('Number of Members')}
                        {...memberNumber}
                        onChange={value =>
                          onFormFieldChangeNonRequired('memberNumber', value)
                        }
                      />

                      <TextField
                        id="memberNumberMale"
                        placeholder={t('Number of Male Members')}
                        {...memberNumberMale}
                        onChange={value =>
                          onFormFieldChangeNonRequired(
                            'memberNumberMale',
                            value,
                          )
                        }
                      />

                      <TextField
                        id="memberNumberFemale"
                        placeholder={t('Number of Female Members')}
                        {...memberNumberFemale}
                        onChange={value =>
                          onFormFieldChangeNonRequired(
                            'memberNumberFemale',
                            value,
                          )
                        }
                      />

                      <TextField
                        id="memberNumberDisabled"
                        placeholder={t('Number of Disabled Members')}
                        {...memberNumberDisabled}
                        onChange={value =>
                          onFormFieldChangeNonRequired(
                            'memberNumberDisabled',
                            value,
                          )
                        }
                      />

                      <TextField
                        id="memberNumberSmid"
                        placeholder={t('Number of SIMD Members')}
                        {...memberNumberSmid}
                        onChange={value =>
                          onFormFieldChangeNonRequired(
                            'memberNumberSmid',
                            value,
                          )
                        }
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
              <Divider />
              <div className="modal-heading-container">
                <h5>
                  <Trans>Banner Image</Trans>
                </h5>
              </div>
              <div className="row staffs-list">
                <div className="col-sm-12">
                  <div className="member-bodies">
                    <Provider store={ctx.store}>
                      <ImageUpload
                        title={t('Memberbody Banner')}
                        targetId="header_memberbody"
                        src={banner}
                        firebaseUrl={`/member_bodies/tempBanner/banner`}
                        tooltip={t('Upload an header icon - minimum 460x160px')}
                        height="160px"
                        width="460px"
                        avatar={false}
                      />
                    </Provider>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="modal-heading-container">
                <h5>{`${t('Assign to')} ${memberBodyFlag ? '' : t('Event')} ${t(
                  'Staff Members',
                )}`}</h5>
              </div>
              <div className="row staffs-list">
                <div className="col-sm-12">
                  <div className="member-bodies">
                    {staffs.map(data => (
                      <div className="member-body" key={data.id}>
                        <div
                          className={
                            disabledStaffs[data.id]
                              ? 'row checkboxes selection-disabled'
                              : 'row checkboxes'
                          }
                        >
                          <div className="col-sm-1">
                            <span className="select">
                              <Checkbox
                                disabled={!!disabledStaffs[data.id]}
                                onCheck={(e, isChecked) => {
                                  e.stopPropagation();
                                  onStaffMemberAsignment(data.id, isChecked);
                                }}
                              />
                            </span>
                          </div>
                          <div className="col-sm-11">
                            <span
                              className="name"
                              style={{
                                display: 'inline-block',
                                height: '24px',
                                verticalAlign: 'middle',
                              }}
                            >
                              {data.value.firstName} {data.value.lastName}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <Divider />
              <div className="submit-button-container">{buttonSubmit}</div>
            </div>
          </div>
        </Dialog>
      ))
        // eslint-disable-next-line no-extra-bind
        .bind(this)}
    </ReactReduxContext.Consumer>
  );
};

NewMemberBody.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  name: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  banner: PropTypes.string,
  onFieldChange: PropTypes.func.isRequired,
  onFieldChangeWithUniqueCheck: PropTypes.func.isRequired,
  onFormFieldChangeNonRequired: PropTypes.func.isRequired,
  availableStaffMembers: PropTypes.array.isRequired,
  filteredChunks: PropTypes.object.isRequired,
  postalAddress: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  postCode: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  adminUsername: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  adminEmail: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  primaryContactName: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  primaryContactEmail: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  primaryContactNumber: PropTypes.shape({
    value: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
  secondaryContactName: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  secondaryContactEmail: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  secondaryContactNumber: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  landline: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  mobile: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  memberNumber: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  memberNumberMale: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  memberNumberFemale: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  memberNumberDisabled: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  memberNumberSmid: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  staffs: PropTypes.array.isRequired,
  disabledStaffs: PropTypes.object.isRequired,
  attachBanner: PropTypes.func.isRequired,
  memberBodyFlag: PropTypes.bool,
};

NewMemberBody.defaultProps = {
  error: null,
  banner: '',
};

export default NewMemberBody;
