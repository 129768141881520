/* eslint-disable jsx-a11y/href-no-hash */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { Trans, withTranslation } from 'react-i18next';

import Panel from '../../../../app/components/panel';

import { Widget as WidgetIcon } from '../../components/icons';
import './styles.css';

import { getAppUrl } from '../../../../utils/appDetails';

class Widget extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    customDomain: PropTypes.object,
  };

  static defaultProps = {
    customDomain: {},
  };

  state = {
    downloadRef: {},
  };

  setDownloadRef = (size, ref) => {
    const downloadRef = { ...this.state.downloadRef };
    downloadRef[size] = ref;
    if (!this.state.downloadRef[size]) {
      this.setState({ downloadRef });
    }
  };

  download = size => {
    const { t } = this.props;
    const sizes = [150, 300, 500];
    const canvas = document.querySelectorAll('canvas');
    const downloadRef = { ...this.state.downloadRef };
    downloadRef[size].download = `${t('QR Code')} ${size}x${size}.png`;
    downloadRef[size].href = canvas[sizes.indexOf(size)].toDataURL();
    this.setState({ downloadRef });
  };

  render() {
    const appUrl = getAppUrl(
      this.props.match.params.appname,
      this.props.customDomain,
    );

    return (
      <div className="row">
        <div className="col-xs-12">
          <Panel icon={<WidgetIcon />} title="QR Codes" padded>
            <div className="row">
              <div className="col-xs-12">
                <h3>
                  <Trans>Don’t forget your offline audience</Trans>
                </h3>
                <p>
                  <Trans>
                    Share the QR codes for people to scan with their phone –
                    this will take them straight to your app.
                  </Trans>
                </p>
              </div>
            </div>
            <div className="qr-container row">
              <div className="col-xs-4 qr-image">
                <QRCode size={60} value={appUrl} />
                {/* eslint-disable-next-line */}
                <a
                  className="download-qr"
                  role="button"
                  tabIndex={0}
                  ref={ref => {
                    this.setDownloadRef(150, ref);
                  }}
                  onClick={() => {
                    this.download(150);
                  }}
                  download
                >
                  <Trans>QR code</Trans> <br />
                  150x150 px
                </a>
              </div>
              <div className="col-xs-4 qr-image">
                <QRCode size={100} value={appUrl} />
                {/* eslint-disable-next-line */}
                <a
                  className="download-qr"
                  role="button"
                  tabIndex={0}
                  ref={ref => {
                    this.setDownloadRef(300, ref);
                  }}
                  onClick={() => {
                    this.download(300);
                  }}
                  download
                >
                  <Trans>QR code</Trans> <br />
                  300x300 px
                </a>
              </div>
              <div className="col-xs-4 qr-image">
                <QRCode size={140} value={appUrl} />
                {/* eslint-disable-next-line */}
                <a
                  className="download-qr"
                  role="button"
                  tabIndex={0}
                  ref={ref => {
                    this.setDownloadRef(500, ref);
                  }}
                  onClick={() => {
                    this.download(500);
                  }}
                  download
                >
                  <Trans>QR code</Trans> <br />
                  500x500 px
                </a>
              </div>
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Widget);
