export function whitelabeledHost() {
  const allowedHosts = [
    'create.beezer.com',
    'beezer-staging.firebaseapp.com',
    'localhost:3005',
    'localhost:3006',
    '127.0.0.1:3005',
  ];

  return !allowedHosts.includes(window.location.host);
}
