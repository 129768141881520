import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EnhancedButton from 'material-ui/internal/EnhancedButton';

const GradientButton = styled.button`
  position: relative;
  display: inline-block;
  color: ${props => {
    if (props.disabled) {
      return `${props.theme.mainWhite} !important`;
    }

    if (props.primary) {
      return `${props.theme.primaryTextColor} !important`;
    }

    if (props.secondary) {
      return `${props.theme.primaryColor} !important`;
    }

    if (props.primaryWhite) {
      return `${props.theme.primaryColor} !important`;
    }
    if (props.secondaryWhite) {
      return `${props.theme.secondaryTextColor} !important`;
    }

    return `${props.theme.primaryTextColor} !important`;
  }};
  overflow: hidden;
  padding: 0;
  margin: 12px;
  min-width: 120px;
  border-radius: 32px;
  background-color: ${props => {
    if (props.disabled) {
      return `${props.theme.disabledColor} !important`;
    }

    if (props.primary) {
      return `${props.theme.primaryColor} !important`;
    }

    if (props.secondary) {
      return `${props.theme.secondaryColor} !important`;
    }

    if (props.primaryWhite || props.secondaryWhite) {
      return 'transparent !important';
    }

    return `${props.theme.primaryColor} !important`;
  }};
  border: ${props =>
    props.secondary || props.secondaryWhite
      ? `1px solid ${props.theme.mainGrey}`
      : null};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')} !important;
  transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &:before {
    border-radius: inherit;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }

  &:hover {
    &:before {
      opacity: ${props => {
        if (props.onHoverOpacity) return props.onHoverOpacity;
        return !props.disabled && 1;
      }};
    }
  }
`;

const ButtonLabel = styled.span`
  position: relative;
  font-size: 14px;
  font-family: ${props =>
    props.labelFont === 'raleway'
      ? `'Raleway', sans-serif`
      : 'Montserrat, sans-serif'};
  font-weight: ${props => (props.labelWeight === 'normal' ? 'normal' : 600)};
  text-transform: uppercase;
  line-height: 32px;
  text-align: center;
  padding-left: ${props => (props.labelNoPadding ? 0 : 25)}px;
  padding-right: ${props => (props.labelNoPadding ? 0 : 24)}px;
  z-index: 1;
`;

// this will remove default material-ui styles so we can use css without needing to !important
const negatedStyles = {
  fontFamily: null,
  fontWeight: null,
  fontSize: null,
  padding: null,
  margin: null,
  // required to pass this
  backgroundColor: 'transparent',
  border: null,
};

const imageStyle = {
  width: '30px',
  verticalAlign: 'middle',
  marginBottom: '4px',
  marginLeft: '12px',
  marginRight: '-17px',
};

const FlatButton = ({
  label,
  src,
  labelFont,
  labelWeight,
  labelNoPadding,
  ...other
}) => (
  <EnhancedButton
    {...other}
    style={negatedStyles}
    containerElement={<GradientButton />}
  >
    {src && <img src={src} style={imageStyle} alt="button" />}
    <ButtonLabel
      labelFont={labelFont}
      labelWeight={labelWeight}
      labelNoPadding={labelNoPadding}
    >
      {label}
    </ButtonLabel>
  </EnhancedButton>
);

FlatButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FlatButton;
