import firebase from '../firebase';

export const remove500OfferAccountIds = () => {
  return new Promise((resolve, reject) =>
    firebase
      .database()
      .ref('remove_500_offer')
      .once('value')
      .then(snapshot => {
        if (snapshot.exists()) {
          return resolve(Object.keys(snapshot.val()));
        }
        return resolve([]);
      }),
  );
};
