export const OPEN_FORM_DETAIL = 'OPEN_FORM_DETAIL';
export const CLOSE_FORM_DETAIL_DIALOG = 'CLOSE_FORM_DETAIL_DIALOG';
export const PREVIOUS_FORM_RESPONSE = 'PREVIOUS_FORM_RESPONSE';
export const NEXT_FORM_RESPONSE = 'NEXT_FORM_RESPONSE';

export const openFormDetailDialog = key => (dispatch, getState) => {
  const form = getState().analytics.form.data.chunks[key];

  return dispatch({
    type: OPEN_FORM_DETAIL,
    key,
    form,
  });
};

export const closeFormDetailDialog = () => ({
  type: CLOSE_FORM_DETAIL_DIALOG,
});

export const previousResponse = (index, forms) => ({
  type: PREVIOUS_FORM_RESPONSE,
  index,
  forms,
});

export const nextResponse = (index, forms) => ({
  type: NEXT_FORM_RESPONSE,
  index,
  forms,
});
