import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { RaisedButton } from '../../../../components/buttons';
import Standard from './Standard';
import themeStyles from '../../../../../_export.scss';

const styles = {
  buttonRoot: {
    margin: '15px auto',
    width: '200px',
  },
  labelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.primaryTextColor,
    textTransform: 'capitalize',
  },
};

const defaultContent = (match, isOn14DaysTrial) => (
  <div style={{ fontSize: '13px', color: '#1e2023' }}>
    <h2>
      <Trans>Thanks for signing up to our fully featured free trial!</Trans>
    </h2>
    <div
      style={{
        borderBottom: '2px solid #efeff4',
        width: '70%',
        margin: '0 auto',
      }}
    />
    <p>
      <Trans>
        For the next {isOn14DaysTrial ? '14 days' : '30 days'}, you will have
        full access to all the features of our{' '}
        {
          <Link
            className="dark-text decorate"
            to={`/${match.params.appname}/choose_plan?plan=deluxe`}
          >
            Everything plan.
          </Link>
        }
      </Trans>
    </p>
    <p>
      <Trans>
        After the trial expires, your account will revert to the Free plan.
        Don&#39;t worry - nothing will be lost, but you won&#39;t have access to
        all of the paid features. To regain access to them, simply{' '}
        <Link
          className="dark-text decorate"
          to={`/${match.params.appname}/choose_plan`}
        >
          upgrade your account
        </Link>{' '}
        to the plan that suits you best!
      </Trans>
    </p>
    <h1 style={{ color: themeStyles.secondaryColor, fontStyle: 'italic' }}>
      <strong>
        <Trans>Enjoy!</Trans>
      </strong>
    </h1>
  </div>
);

const Welcome = ({
  isOn14DaysTrial,
  content,
  onRequestClose,
  history,
  match,
}) => {
  const { t } = useTranslation();

  const closeAndRedirect = () => {
    onRequestClose();
    history.push(history.location.pathname.replace('dashboard', 'build'));
  };

  return (
    <Standard title={t('Welcome to Beezer')} onRequestClose={onRequestClose}>
      {content || defaultContent(match, isOn14DaysTrial)}

      <RaisedButton
        label={t('Build Your App')}
        backgroundColor={themeStyles.primaryColor}
        disabledBackgroundColor={themeStyles.primaryColorLight}
        style={styles.buttonRoot}
        labelStyle={styles.labelStyle}
        onClick={closeAndRedirect}
      />
    </Standard>
  );
};

Welcome.propTypes = {
  isOn14DaysTrial: PropTypes.object,
  content: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

Welcome.defaultProps = {
  content: null,
};

export default withRouter(Welcome);
