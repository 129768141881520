import url from 'url';
import firebase from '../../../../../../../firebase';

export const urlChange = (appname, dataId, urlString) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  const parsedUrl = url.parse(urlString, true);

  const listId = parsedUrl.query.list;
  const videoId = parsedUrl.query.v;

  if (listId) {
    dataRef.child('videoId').set(null);
    dataRef.child('listId').set(listId);
  } else if (videoId) {
    dataRef.child('videoId').set(videoId);
    dataRef.child('listId').set(null);
  }

  if (urlString) {
    dataRef.child('rawUrl').set(urlString);
  }

  return {
    type: 'TEXT_VALUE_CHANGE',
    appname,
    dataId,
    field: 'rawUrl',
    urlString,
  };
};

export const loop = (appname, dataId, enableLoop) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('loop').set(enableLoop);

  return {
    type: 'LOOP_VALUE_CHANGE',
    appname,
    dataId,
    field: 'loop',
    enableLoop,
  };
};

export const muted = (appname, dataId, enableMuted) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('muted').set(enableMuted);

  return {
    type: 'MUTED_VALUE_CHANGE',
    appname,
    dataId,
    field: 'muted',
    enableMuted,
  };
};

export const autoPlay = (appname, dataId, enableAutoPlay) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('autoPlay').set(enableAutoPlay);

  return {
    type: 'AUTOPLAY_VALUE_CHANGE',
    appname,
    dataId,
    field: 'autoPlay',
    enableAutoPlay,
  };
};
