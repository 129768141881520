import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCellMui from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles, withStyles } from '@material-ui/styles';
import { TreeView, TreeItem } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from 'material-ui/svg-icons/action/info';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import { CircularProgress } from '../../../../../../../app/components/progress';
import { objectToArray } from '../../../../../../../utils';
import Modal from '../../../components/Modal';
import Filters from '../../../components/Filters';
import IconButton from '@material-ui/core/IconButton';

const TableCell = withStyles({
  head: {
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
})(TableCellMui);

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  center: {
    textAlign: 'center',
    width: '100%',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  labelIcon: {
    marginRight: '1px',
  },
  labelText: {
    fontWeight: 'bold',
    flexGrow: 1,
    color: 'rgba(0, 0, 0, 0.54) !important',
  },
  width60: {
    width: '60%',
  },
  PL25: {
    paddingLeft: '25px',
  },
});

const Attended = React.forwardRef(
  (
    {
      processingAttended,
      attendedData,
      workforceGroups,
      eventsCoursesCpds,
      users,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [modalData, setModalData] = React.useState({});
    const [isOpen, setOpen] = React.useState(false);
    const [attended, setAttended] = React.useState(attendedData);

    React.useEffect(() => {
      setAttended(attendedData);
    }, [attendedData]);

    let reportData = {
      event: { workforce: {} },
      course: { workforce: {} },
      cpd: { workforce: {} },
    };
    const getSize = (Size = {}, type, path) => {
      let obj = {};
      const data = Size[path] || {};
      objectToArray(data).forEach(user => {
        const userName = users[user.id]
          ? `${users[user.id].firstName ? users[user.id].firstName : ''} ${
              users[user.id].lastName ? users[user.id].lastName : ''
            }`
          : 'N/A';
        const email = users[user.id] ? users[user.id].email : 'N/A';
        obj = {
          ...obj,
          ..._.pickBy(user.value, value => {
            const valRef = value;
            valRef.userName = userName;
            valRef.email = email;
            valRef.eventTitle = eventsCoursesCpds
              ? eventsCoursesCpds[type][value.memberbody][value.eventID][
                  '-LuHSoQ5krd8oIDeSeTF'
                ].value
              : '';
            return value.status === 'yes';
          }),
        };
      });
      reportData = {
        ...reportData,
        ...{ [type]: { ...reportData[type], ...{ [path]: obj } } },
      };
      return _.size(obj);
    };

    // get all users for events
    const eventUsers = getSize(attended.event, 'event', 'user');
    const eventWF = getSize(attended.event, 'event', 'workforce');

    // get all users for courses
    const courseUsers = getSize(attended.course, 'course', 'user');
    const courseWF = getSize(attended.course, 'course', 'workforce');

    // get all users for cpds
    const cpdUsers = getSize(attended.cpd, 'cpd', 'user');
    const cpdWF = getSize(attended.cpd, 'cpd', 'workforce');

    const applyFilter = (obj, dates) =>
      _.pickBy(obj, val => {
        const dateToFilter = new Date(
          moment(val.created_at).format('YYYY/MM/DD'),
        ).getTime();
        if (dates.from && dates.to) {
          return (
            dateToFilter >= dates.from.getTime() &&
            dateToFilter <= dates.to.getTime()
          );
        } else if (dates.from) {
          return dateToFilter >= dates.from.getTime();
        } else if (dates.to) {
          return dateToFilter <= dates.to.getTime();
        }
        return true;
      });

    const onFilter = dates => {
      const clonedAttendedData = _.cloneDeep(attendedData);
      // filters for events/courses/cpd
      const moduleTypes = ['event', 'course', 'cpd'];
      const userTypes = ['user', 'workforce'];
      moduleTypes.forEach(type =>
        userTypes.forEach(userType => {
          objectToArray(clonedAttendedData[type][userType]).forEach(item => {
            clonedAttendedData[type][userType][item.id] = applyFilter(
              item.value,
              dates,
            );
          });
        }),
      );
      setAttended(clonedAttendedData);
    };

    React.useImperativeHandle(ref, () => ({
      prepareReportData() {
        const rData = { data: [] };
        const types = ['Event', 'Course', 'CPD'];
        rData.columns = [
          t('Type'),
          t('Total Attended in Type'),
          t('Total Individual Attended'),
          t('Total Workforce Attended'),
          t('Workforce Group Specific'),
          t('Total Attended By Workforce Group'),
        ];
        types.forEach(type => {
          let totalIndividual = 0;
          let totalWF = 0;
          if (type === 'Event') {
            totalIndividual = eventUsers;
            totalWF = eventWF;
          } else if (type === 'Course') {
            totalIndividual = courseUsers;
            totalWF = courseWF;
          } else if (type === 'CPD') {
            totalIndividual = cpdUsers;
            totalWF = cpdWF;
          }
          objectToArray(workforceGroups).forEach(group => {
            rData.data.push([
              type,
              totalIndividual + totalWF,
              totalIndividual,
              totalWF,
              group.value.name,
              _.size(
                _.pickBy(reportData[type.toLowerCase()].workforce, {
                  groupID: group.id,
                }),
              ),
            ]);
          });
        });
        return [rData];
      },
    }));

    const prepareModalData = (e, itemType, userType, groupID) => {
      e.stopPropagation(true);
      const tableData = {
        columns: [
          { title: t('Event/Course/CPD'), key: 'eventTitle' },
          { title: t('User'), key: 'userName' },
          { title: t('Email'), key: 'email' },
          {
            title: t('Attended Date'),
            key: 'created_at',
            render: value => moment(value).format('DD/MM/YYYY'),
          },
        ],
      };
      if (userType && groupID) {
        tableData.rows = Object.values(reportData[itemType][userType]).filter(
          i => i.groupID === groupID,
        );
      } else if (userType) {
        tableData.rows = Object.values(reportData[itemType][userType]);
      } else {
        let rows = [];
        Object.values(reportData[itemType]).forEach(item => {
          rows = [...rows, ...Object.values(item)];
        });
        tableData.rows = rows;
      }
      setModalData(tableData);
      setOpen(true);
    };
    return (
      <AccordionDetails>
        {processingAttended ? (
          <div className={classes.center}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <TableContainer>
            <Filters onFilter={onFilter} />
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Trans>Type</Trans>
                  </TableCell>
                  <TableCell align="left" className={classes.width60}>
                    <Trans>Additional Data</Trans>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Trans>Event</Trans>
                  </TableCell>
                  <TableCell align="left">
                    <TreeView
                      defaultCollapseIcon={<ArrowDropDownIcon />}
                      defaultExpandIcon={<ArrowRightIcon />}
                    >
                      <TreeItem
                        nodeId={`node-event`}
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Total')} (${eventUsers + eventWF})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e => prepareModalData(e, 'event')}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      >
                        <TreeItem
                          nodeId={`node-user`}
                          className={classes.PL25}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('Individual Users')} (${eventUsers})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(e, 'event', 'user')
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        />
                        <TreeItem
                          nodeId={`node-event-workforce`}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('By Workforce Members')} (${eventWF})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(
                                          e,
                                          'event',
                                          'workforce',
                                        )
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        >
                          {objectToArray(workforceGroups).map(group => (
                            <TreeItem
                              nodeId={`node-event-${group.id}`}
                              key={`node-event-${group.id}`}
                              label={
                                <div className={classes.labelRoot}>
                                  <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                  >
                                    {group.value.name} (
                                    {_.size(
                                      _.pickBy(reportData.event.workforce, {
                                        groupID: group.id,
                                      }),
                                    )}
                                    )
                                  </Typography>
                                  <Typography variant="caption" color="inherit">
                                    <Tooltip
                                      title={t('View Details')}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <InfoIcon
                                          color="#a2a2a2"
                                          style={{ height: 18, width: 18 }}
                                          onClick={e =>
                                            prepareModalData(
                                              e,
                                              'event',
                                              'workforce',
                                              group.id,
                                            )
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </div>
                              }
                            />
                          ))}
                        </TreeItem>
                      </TreeItem>
                    </TreeView>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Trans>Course</Trans>
                  </TableCell>
                  <TableCell align="left">
                    <TreeView
                      defaultCollapseIcon={<ArrowDropDownIcon />}
                      defaultExpandIcon={<ArrowRightIcon />}
                    >
                      <TreeItem
                        nodeId={`node-course`}
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Total')} (${courseUsers + courseWF})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e => prepareModalData(e, 'course')}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      >
                        <TreeItem
                          nodeId={`node-user`}
                          className={classes.PL25}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('Individual Users')} (${courseUsers})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(e, 'course', 'user')
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        />
                        <TreeItem
                          nodeId={`node-course-workforce`}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('Workforce Members')} (${courseWF})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(
                                          e,
                                          'course',
                                          'workforce',
                                        )
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        >
                          {objectToArray(workforceGroups).map(group => (
                            <TreeItem
                              nodeId={`node-course-${group.id}`}
                              key={`node-course-${group.id}`}
                              label={
                                <div className={classes.labelRoot}>
                                  <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                  >
                                    {group.value.name} (
                                    {_.size(
                                      _.pickBy(reportData.course.workforce, {
                                        groupID: group.id,
                                      }),
                                    )}
                                    )
                                  </Typography>
                                  <Typography variant="caption" color="inherit">
                                    <Tooltip
                                      title={t('View Details')}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <InfoIcon
                                          color="#a2a2a2"
                                          style={{ height: 18, width: 18 }}
                                          onClick={e =>
                                            prepareModalData(
                                              e,
                                              'course',
                                              'workforce',
                                              group.id,
                                            )
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </div>
                              }
                            />
                          ))}
                        </TreeItem>
                      </TreeItem>
                    </TreeView>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CPD
                  </TableCell>
                  <TableCell align="left">
                    <TreeView
                      defaultCollapseIcon={<ArrowDropDownIcon />}
                      defaultExpandIcon={<ArrowRightIcon />}
                    >
                      <TreeItem
                        nodeId={`node-cpd`}
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Total')} (${cpdUsers + cpdWF})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e => prepareModalData(e, 'cpd')}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      >
                        <TreeItem
                          nodeId={`node-user`}
                          className={classes.PL25}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('Individual Users')} (${cpdUsers})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(e, 'cpd', 'user')
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        />
                        <TreeItem
                          nodeId={`node-cpd-workforce`}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('Workforce Members')} (${cpdWF})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(e, 'cpd', 'workforce')
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        >
                          {objectToArray(workforceGroups).map(group => (
                            <TreeItem
                              nodeId={`node-cpd-${group.id}`}
                              key={`node-cpd-${group.id}`}
                              label={
                                <div className={classes.labelRoot}>
                                  <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                  >
                                    {group.value.name} (
                                    {_.size(
                                      _.pickBy(reportData.cpd.workforce, {
                                        groupID: group.id,
                                      }),
                                    )}
                                    )
                                  </Typography>
                                  <Typography variant="caption" color="inherit">
                                    <Tooltip
                                      title={t('View Details')}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <InfoIcon
                                          color="#a2a2a2"
                                          style={{ height: 18, width: 18 }}
                                          onClick={e =>
                                            prepareModalData(
                                              e,
                                              'cpd',
                                              'workforce',
                                              group.id,
                                            )
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </div>
                              }
                            />
                          ))}
                        </TreeItem>
                      </TreeItem>
                    </TreeView>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Modal {...modalData} open={isOpen} onClose={() => setOpen(false)} />
      </AccordionDetails>
    );
  },
);
Attended.propTypes = {
  processingAttended: PropTypes.bool,
  attendedData: PropTypes.object,
  workforceGroups: PropTypes.object,
  eventsCoursesCpds: PropTypes.object,
  users: PropTypes.object,
};

Attended.defaultProps = {
  processingAttended: false,
  attendedData: {
    cpd: {},
    course: {},
    event: {},
  },
  workforceGroups: {},
  users: {},
  eventsCoursesCpds: {},
};

export default Attended;
