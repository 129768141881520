import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import { useTranslation } from 'react-i18next';
import Container from 'app/components/container';
import SectionHeader from 'modules/editor/components/sectionHeader';
import './styles.css';

const Privacy = props => {
  const {
    getPrivacySettings,
    updatePrivacySettings,
    appname,
    isCookieConsentEnabled,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    getPrivacySettings();
  }, [appname]);
  return (
    <Container>
      <SectionHeader
        title={t('Cookie consent')}
        tooltip={t('Ask your users for cookie consent.')}
      />
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        <Toggle
          toggled={isCookieConsentEnabled}
          label={t('Enable cookie consent popup')}
          onToggle={(i, toggled) => {
            updatePrivacySettings('isCookieConsentEnabled', toggled);
          }}
          className="full-width toggle-days-sec"
        />
      </div>
    </Container>
  );
};

Privacy.propTypes = {
  getPrivacySettings: PropTypes.func.isRequired,
  updatePrivacySettings: PropTypes.func.isRequired,
  appname: PropTypes.string.isRequired,
  isCookieConsentEnabled: PropTypes.bool.isRequired,
};

export default Privacy;
