import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation, Trans } from 'react-i18next';
import style from './forum.module.scss';
import { CTAButton } from '../../../../../../../app/components/buttons';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';

const ForumDisabledWrapper = ({ toggleAuth, appname, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const showNotification = () => {
    dispatch(openSnackbar(t('Your member login is now enabled.')));
  };
  return (
    <Dialog
      open={open}
      onRequestClose={() => {}}
      style={{ textAlign: 'center' }}
      actionsContainerStyle={{
        textAlign: 'center',
      }}
      className={style.forumDisabledDialog}
    >
      <DialogTitle>
        <Trans>To use the Forum Component, please enable</Trans>{' '}
        <a
          href={`/${appname}/members/groups`}
          style={{ textDecoration: 'underline' }}
        >
          <Trans>Member Login</Trans>
        </a>
      </DialogTitle>
      <div>
        <CTAButton
          disabled={btnDisabled}
          label={t('Do it for me!')}
          height={35}
          minWidth={100}
          onClick={() => {
            toggleAuth(true);
            setBtnDisabled(true);
            showNotification();
          }}
        />
      </div>
    </Dialog>
  );
};

ForumDisabledWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  appname: PropTypes.string.isRequired,
  toggleAuth: PropTypes.func.isRequired,
};

ForumDisabledWrapper.defaultProps = {
  open: false,
};

export default ForumDisabledWrapper;
