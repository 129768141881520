import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Divider from 'material-ui/Divider';

import { FlatButton } from '../../../../app/components/buttons';

import './styles.scss';

const FileAndUrlDialog = ({
  open,
  onCancel,
  openFileSelector,
  url,
  onInputChange,
  handleFileChange,
  hideUploadDialog,
  uploadError,
  setUploadError,
}) => {
  const actions = [
    <FlatButton label="Cancel" onClick={onCancel} secondaryWhite />,
  ];
  return (
    <Dialog
      // title={
      //   <h3 className="multiple_upload_file_dialog_title">Upload Images</h3>
      // }
      open={open}
      actions={actions}
      actionsContainerStyle={{
        textAlign: 'center',
        padding: 16,
      }}
      bodyStyle={{
        position: 'relative',
        overflowY: 'auto',
        padding: '0px 24px',
        minHeight: '250px',
      }}
      contentStyle={{
        textAlign: 'center',
        maxHeight: '100%',
        transform: 'translate(0px, 32px)',
      }}
      onRequestClose={onCancel}
      autoScrollBodyContent
      className="multiple_upload_file_dialog"
      style={{ zIndex: `${hideUploadDialog ? 1400 : 1500}` }}
    >
      <br />
      <div className="user-memberBodies-modal-content pgmol-modal-content">
        <div className="modal-heading pgmol-modal-heading file_modal_heading">
          <h5> Upload Images</h5>
          <HighlightOff
            className="pgmol-heading-close-icon"
            onClick={onCancel}
          />
        </div>
      </div>
      <Divider />

      <div>
        {uploadError.length > 0 && (
          <div style={{ display: 'flex', marginTop: '2vh' }}>
            <span style={{ color: 'red', fontSize: '0.9rem' }}>
              {uploadError}
            </span>
          </div>
        )}
        <div className="url_upload_container">
          <input
            className="url_upload_input"
            placeholder="Enter or paste a URL here"
            value={url}
            onChange={e => {
              onInputChange(e.target.value);
              setUploadError('');
            }}
          />
          <button className="url_upload_button" onClick={handleFileChange}>
            Upload via URL
          </button>
        </div>
        <h3 className="url_upload_or_text">OR</h3>
        <button className="url_upload_button_file" onClick={openFileSelector}>
          Upload Files
        </button>
      </div>
    </Dialog>
  );
};

FileAndUrlDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  openFileSelector: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  hideUploadDialog: PropTypes.bool.isRequired,
  uploadError: PropTypes.string.isRequired,
  setUploadError: PropTypes.func.isRequired,
};

export default FileAndUrlDialog;
