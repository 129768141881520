import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, BrowserRouter as Router } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import RenderToLayer from 'material-ui/internal/RenderToLayer';
import MenuItem from 'material-ui/MenuItem';
import { withTranslation } from 'react-i18next';
import { CreateWithSteps } from '../components/create';
import TabPage from '../../../../../components/tabPage';
import { SimpleToolbar } from '../../../../../../../app/modules/toolbar';
import TextField from '../../../../../../../app/components/textField';
import {
  RadioMenu,
  RadioItem,
} from '../../../../../../../app/components/radioMenu';
import SelectField from '../../../../../../../app/components/selectField';

import { store } from '../../../../../../../';

import {
  StandardPageIcon,
  TabPageIcon,
  NestedPageIcon,
  NestedTabPageIcon,
  PopupBoxIcon,
} from './svg';
import './styles.scss';
import { Grid } from '@material-ui/core';
import {
  createPage,
  titleChange,
  tabChange,
  typeChange,
  addTab,
  removeTab,
  closeCreatePageUI,
  parentPageChange,
} from './actions';

const componentTypes = [
  {
    label: 'Standard Page',
    value: 'View',
    text:
      'Choose a standard page if you want to show your audience a single page full of great content.',
    svg: <StandardPageIcon />,
  },
  {
    label: 'Tab Page',
    value: 'TabView',
    text: 'Use a tab page if you want to add multiple tabs within one page.',
    svg: <TabPageIcon />,
  },
  {
    label: 'Popup Box',
    value: 'Popup',
    text:
      'Choose a popup box to add a page which will be displayed as a popup over another page.',
    svg: <PopupBoxIcon />,
  },
  {
    label: 'Nested Page',
    value: 'NestedPage',
    text: 'Nest a page under a parent page.',
    svg: <NestedPageIcon />,
  },
  {
    label: 'Nested Tab Page',
    value: 'NestedTabPage',
    text: 'Nest a Tab page under a parent page.',
    svg: <NestedTabPageIcon />,
  },
];
class CreatePage extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    pages: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onTitleChange: PropTypes.func.isRequired,
    onTabChange: PropTypes.func.isRequired,
    onTypeChange: PropTypes.func.isRequired,
    onAddTab: PropTypes.func.isRequired,
    onRemoveTab: PropTypes.func.isRequired,
    onParentPageChange: PropTypes.func.isRequired,
  };

  renderTabForm = () => {
    const { t } = this.props;
    return (
      <div key="tabForm">
        <TextField
          id="page_title"
          {...this.props.form.title}
          onChange={this.props.onTitleChange}
          placeholder={t('Page Title')}
        />
        <TabPage
          title={this.props.form.title}
          tabs={this.props.form.tabs}
          onTitleChange={this.props.onTitleChange}
          onTabChange={this.props.onTabChange}
          onAdd={this.props.onAddTab}
          onRemove={this.props.onRemoveTab}
        />
      </div>
    );
  };

  dropdownNavigationOptions = () =>
    this.props.pages.map(
      (page, index) =>
        page.type !== 'Popup' &&
        page.type !== 'NestedPage' && (
          <MenuItem key={page.key} value={page.key} primaryText={page.title} />
        ),
    );

  renderNestedPageForm = () => {
    const { t } = this.props;
    return (
      <div key="nestedPageForm">
        <TextField
          id="page_title"
          {...this.props.form.title}
          onChange={this.props.onTitleChange}
          placeholder={t('Page Title')}
        />
        <SelectField
          label={t('Parent Page')}
          value={this.props.form.parentPage}
          options={this.dropdownNavigationOptions()}
          onChange={(i, val) => {
            this.props.onParentPageChange(val);
          }}
        />
      </div>
    );
  };

  renderNestedTabPageForm = () => {
    const { t } = this.props;
    return (
      <div key="nestedPageForm">
        <TextField
          id="page_title"
          {...this.props.form.title}
          onChange={this.props.onTitleChange}
          placeholder={t('Page Title')}
        />
        <SelectField
          label={t('Parent Page')}
          value={this.props.form.parentPage}
          options={this.dropdownNavigationOptions()}
          onChange={(i, val) => {
            this.props.onParentPageChange(val);
          }}
        />
        <TabPage
          title={this.props.form.title}
          tabs={this.props.form.tabs}
          onTitleChange={this.props.onTitleChange}
          onTabChange={this.props.onTabChange}
          onAdd={this.props.onAddTab}
          onRemove={this.props.onRemoveTab}
        />
      </div>
    );
  };

  renderStandardForm = () => {
    const { t } = this.props;
    return (
      <div key="standardForm">
        <TextField
          id="page_title"
          {...this.props.form.title}
          onChange={this.props.onTitleChange}
          placeholder={t('Page Title')}
        />
      </div>
    );
  };

  renderContent = () => {
    const { t } = this.props;
    const componentTypes = [
      {
        label: t('Standard Page'),
        value: 'View',
        text: t(
          'Choose a standard page if you want to show your audience a single page full of great content.',
        ),
        svg: <StandardPageIcon />,
      },
      {
        label: t('Tab Page'),
        value: 'TabView',
        text: t(
          'Use a tab page if you want to add multiple tabs within one page.',
        ),
        svg: <TabPageIcon />,
      },
      {
        label: t('Popup Box'),
        value: 'Popup',
        text: t(
          'Choose a popup box to add a page which will be displayed as a popup over another page.',
        ),
        svg: <StandardPageIcon />,
      },
      {
        label: t('Nested Page'),
        value: 'NestedPage',
        text: t('Nest a page under a parent page.'),
        svg: <StandardPageIcon />,
      },
      {
        label: t('Nested Tab Page'),
        value: 'NestedTabPage',
        text: t('Nest a Tab page under a parent page.'),
        svg: <StandardPageIcon />,
      },
    ];

    return [
      <RadioMenu
        onChange={this.props.onTypeChange}
        selected={this.props.form.type}
      >
        <Grid container spacing={3} justifyContent="center">
          {componentTypes.map(component => (
            <Grid item xs={2} sm={2}>
              <RadioItem {...component} />
            </Grid>
          ))}
        </Grid>
      </RadioMenu>,
      this.getContentByFormType(),
    ];
  };

  getContentByFormType = () => {
    switch (this.props.form.type) {
      case 'TabView':
        return this.renderTabForm();
      case 'NestedPage':
        return this.renderNestedPageForm();
      case 'NestedTabPage':
        return this.renderNestedTabPageForm();
      default:
        return this.renderStandardForm();
    }
  };
  renderLayer = () => {
    const { form, onCancel, t } = this.props;
    const content = this.renderContent();
    return (
      <Router>
        <div className="overlay create-page">
          <Provider store={store}>
            <SimpleToolbar onExit={onCancel} />
            <CreateWithSteps
              title={t('Create New Page')}
              steps={form.steps}
              stepContent={content}
              submitLabel={t('Add Page')}
              pending={form.pending}
              onSubmit={this.props.onSubmit}
              onCancel={onCancel}
              className="create-new-component"
            />
          </Provider>
        </div>
      </Router>
    );
  };

  render() {
    return (
      <RenderToLayer
        open={this.props.form.open}
        render={this.renderLayer}
        useLayerForClickAway={false}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];

  return {
    ...ownProps,
    pages: layout
      ? layout.childRoutes.map(key => state.editor.data.alwaysData[key])
      : [],
    form: state.editor.build.create.createPage,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () =>
    dispatch(createPage(ownProps.match.params.appname, ownProps.history)),
  onCancel: () => dispatch(closeCreatePageUI()),
  onTitleChange: title => dispatch(titleChange(title)),
  onTabChange: (index, val) => dispatch(tabChange(index, val)),
  onTypeChange: type => dispatch(typeChange(type)),
  onAddTab: () => dispatch(addTab()),
  onRemoveTab: index => dispatch(removeTab(index)),
  onParentPageChange: value => dispatch(parentPageChange(value)),
});

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePage)),
);
