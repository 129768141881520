const initialState = {
  visible: false,
};

const appContext = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_DIY_ADDON_PROMPT':
      return {
        ...state,
        visible: action.value,
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default appContext;
