import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import ChartWrapper from './ChartWrapper';
import { initializeChart, listenAnalytics, offAnalytics } from './actions';
import { openWixPlans } from '../../../account/modules/integration/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.analytics.chart[ownProps.match.params.appname],
  plans: state.account.subscriptions.items,
  integrationType: _get(state, 'account.organisation.integration.type'),
  appname: ownProps.match.params.appname,
  accountId: state.account.auth.profile.account,
  trialExpired: state.account.organisation.trial_expired || false,
});

const mapDispatchToProps = dispatch => ({
  initialize: (appname, step, num, dateTime, customStartDate, customEndDate) =>
    dispatch(
      initializeChart(
        appname,
        step,
        num,
        dateTime,
        customStartDate,
        customEndDate,
      ),
    ),
  setListener: (
    appname,
    type,
    step,
    num,
    dateTime,
    orderField,
    customStartDate,
    customEndDate,
  ) =>
    dispatch(
      listenAnalytics(
        appname,
        type,
        step,
        num,
        dateTime,
        orderField,
        customStartDate,
        customEndDate,
      ),
    ),
  removeListener: (appname, type) => dispatch(offAnalytics(appname, type)),
  openWixPlans: (appname, plan, history, accountId) =>
    dispatch(openWixPlans(appname, plan, history, accountId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChartWrapper),
);
