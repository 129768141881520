import { connect } from 'react-redux';

import InstagramFeed from './InstagramFeed';
import { menuChange, recordLastEdited, removeAccessToken } from './actions';
import { openLegalModal } from '../../../../../../account/modules/legal/actions';

const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = (dispatch, ownProps) => ({
  recordLastEdited: () =>
    dispatch(
      recordLastEdited(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
      ),
    ),
  onChange: (field, value) =>
    dispatch(
      menuChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  removeAccessToken: () =>
    dispatch(
      removeAccessToken(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
      ),
    ),
  onOpenLegalModal: selectedModal => () =>
    dispatch(openLegalModal(selectedModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstagramFeed);
