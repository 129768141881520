import { combineReducers } from 'redux';

import settings from './modules/settings/reducer';
import users from './modules/users/reducer';
import groups from './modules/groups/reducer';
import memberBodies from './modules/memberBodies/reducer';
import appAdmins from './modules/admins/reducer';
import staff from './modules/staff/reducer';
import memberGroups from './modules/memberTypes/reducer';
import workforceMembers from './modules/workforceMembers/reducer';
import authUpgradeDialog from './modules/upgradeDialog/reducer';
import report from './modules/reports/reducer';
import { structure } from './reducers';
import subscriptions from './modules/subscriptions/reducer';
import transactions from './modules/Transactions/reducer';
import diaryData from './modules/Diary/reducer';
import customEmails from './modules/customEmails/reducer';
import customEmailsAdmins from './modules/customEmailsAdmins/reducers';

export default combineReducers({
  settings,
  users,
  groups,
  memberBodies,
  staff,
  appAdmins,
  memberGroups,
  workforceMembers,
  authUpgradeDialog,
  structure,
  report,
  subscriptions,
  transactions,
  diaryData,
  customEmails,
  customEmailsAdmins,
});
