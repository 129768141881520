import firebase from '../../../firebase';

export const closeAppSumoWelcome = (accountId) => dispatch => {
    dispatch({ type: 'TOGGLE_WELCOME_MSG', data: false });
    const accountRef = firebase.database().ref(`accounts/${accountId}`);
    accountRef.update({ appsumo_welcome: true });
}

export const checkAppSumoWelcomeMsgOccurence = (accountId) => dispatch => {
    firebase.database().ref(`accounts/${accountId}/appsumo_welcome`).once('value', snapshot => {
        if (!snapshot.exists()) {
            dispatch({ type: 'TOGGLE_WELCOME_MSG', data: true });
        }
    });
}