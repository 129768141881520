import { combineReducers } from 'redux';

import internals from './internals/reducer';
import build from './modules/build/reducer';
import customise from './modules/customise/reducer';
import shared from './modules/shared/reducer';
import design from './reducers/design';
import settings from './reducers/settings';
import data from './reducers';
import { structure } from '../engage/reducers';

const offerNoticeSet = (state = false, action) => {
  switch (action.type) {
    case 'OFFER_NOTICE_SET':
      return true;
    default:
      return state;
  }
};

const validatingApp = (state = false, action) => {
  switch (action.type) {
    case 'VALIDATING_APP':
      return true;

    case 'APP_VALIDATION_DONE':
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  build,
  customise,
  shared,
  data,
  design,
  internals,
  settings,
  offerNoticeSet,
  validatingApp,
  structure,
});
