import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { LogoToolbar } from '../toolbar';
import { FlatButton } from '../../components/buttons';

const NotFound = props => {
  const { history, apps } = props;

  const handleClick = () => {
    if (!apps && Object.keys(apps).length === 0) {
      history.goBack();
      return;
    }
    const url = `/${Object.keys(apps)[0]}/editor/build`;

    history.push(url);
  };
  return (
    <div>
      <LogoToolbar />
      <div className="row center-xs">
        <div className="col">
          <h1>
            <Trans>Not Found</Trans>
          </h1>
          <FlatButton
            label={<Trans>Go Back</Trans>}
            onClick={() => handleClick()}
          />
        </div>
      </div>
    </div>
  );
};

NotFound.propTypes = {
  history: PropTypes.object.isRequired,
  apps: PropTypes.object,
};

NotFound.defaultProps = {
  apps: {},
};

export default NotFound;
