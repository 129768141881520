import {
  UPDATE_APP_NAME,
  HYDRATE_APP_NAME,
  REPLACE_APP_NAME_DATA,
  FIELD_VALIDATION_CHECK,
  RECEIVE_META_DATA,
  IS_UPDATING,
} from './actions';
import { SAVE_APP_NAME } from '../../../actions';

const INITIAL_STATE = {
  isUpdating: false,
};

const appName = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE_APP_NAME:
      return {
        ...state,
        [action.appname]: {
          meta: {
            ...action.meta,
          },
        },
      };
    case IS_UPDATING:
      return {
        ...state,
        isUpdating: action.value,
      };
    case UPDATE_APP_NAME:
      return {
        ...state,
        [action.appname]: {
          ...state[action.appname],
          meta: {
            ...state[action.appname].meta,
            ...action.meta,
          },
          modified: true,
        },
      };

    case RECEIVE_META_DATA: {
      return {
        ...state,
        [action.appname]: {
          meta: {
            ...action.data,
          },
        },
      };
    }

    case REPLACE_APP_NAME_DATA: {
      return {
        [action.appname]: {
          ...state[action.previusAppname],
          modified: false,
        },
      };
    }

    case SAVE_APP_NAME:
      return {
        ...state,
        [action.appname]: {
          ...state[action.appname],
          modified: false,
        },
      };

    case FIELD_VALIDATION_CHECK:
      return {
        ...state,
        [action.appname]: {
          ...state[action.appname],
          validations: {
            ...action.validations,
            ...state[action.appname].validations,
            [action.attr]: action.invalid,
          },
          modified: true,
        },
      };

    default:
      return state;
  }
};

export default appName;
