import React from 'react';

const WordpressIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 49.5 49.5"
  >
    <title>Wordpress component</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M24.75,0A24.75,24.75,0,1,0,49.5,24.75,24.77,24.77,0,0,0,24.75,0Zm0,48A23.25,23.25,0,1,1,48,24.75,23.28,23.28,0,0,1,24.75,48Z"
        />
        <path
          class="cls-1"
          d="M17.78,44.31l.74.24a20.84,20.84,0,0,0,12.6,0l.75-.24L24.74,25.07Zm12.13-1a19.43,19.43,0,0,1-10.18,0l5-13.91Z"
        />
        <path
          class="cls-1"
          d="M43,14.8,41.7,12.5l-.15,2.62c-.37,6.6-5.15,21.69-6.6,26.16l-.66,2,1.78-1.16A20.75,20.75,0,0,0,43,14.8ZM37.08,39.53c0-.11.08-.24.11-.35s.09-.27.13-.42.1-.29.14-.44.18-.58.28-.88l.1-.34c.07-.24.15-.47.22-.72s.08-.27.13-.4c.1-.35.21-.7.31-1.06l.09-.29c.09-.31.18-.61.28-.93,0-.11.07-.23.1-.35.12-.39.23-.79.35-1.2,0-.09,0-.17.07-.26l.3-1c0-.12.07-.23.1-.35.12-.42.24-.84.35-1.27a2,2,0,0,0,.07-.23l.3-1.14a2.45,2.45,0,0,0,.08-.29c.12-.44.23-.88.35-1.32a1.46,1.46,0,0,1,.05-.2c.1-.4.2-.79.29-1.18,0-.08,0-.16.07-.25.1-.43.21-.87.31-1.3,0-.06,0-.13,0-.19.09-.39.18-.77.26-1.14,0-.08,0-.15,0-.23.09-.42.18-.83.26-1.23a1.29,1.29,0,0,1,0-.19c.07-.36.14-.71.2-1.05a1.18,1.18,0,0,1,0-.2c.06-.39.13-.76.18-1.12v-.06a19.23,19.23,0,0,1-5.66,21.62Z"
        />
        <path
          class="cls-1"
          d="M15.85,42,6.77,17.35h0l-.69-1.88-.71,1.87A20.75,20.75,0,0,0,14.79,43l1.75,1L15.85,42ZM5.5,24.75a19.23,19.23,0,0,1,.62-4.84l7.57,20.6A19.26,19.26,0,0,1,5.5,24.75Z"
        />
        <path
          class="cls-1"
          d="M14,13v1.5H10.66L18.79,36l4.75-14.23L21.3,14.5H18.5V13h11v1.5H26.14l8.65,21.36L38,27c1.86-5.21.67-6.95-.83-9.15A17.82,17.82,0,0,1,35.44,15c-1.1-2.46.7-4.06,2.39-5l1-.53L38,8.74A20.75,20.75,0,0,0,24.75,4c-7.39,0-13.42,2.78-17,7.82L7,13H14ZM24.75,5.5A19.23,19.23,0,0,1,36.16,9.25l-.06,0-.3.24c-.19.15-.38.3-.55.46s-.19.2-.29.29-.27.31-.39.47a1.94,1.94,0,0,0-.23.33,5.5,5.5,0,0,0-.28.48l-.15.35a2.76,2.76,0,0,0-.16.51,3.59,3.59,0,0,0-.08.35,2.62,2.62,0,0,0-.05.55,2.17,2.17,0,0,0,0,.36,4.58,4.58,0,0,0,.08.62c0,.1,0,.2.05.3a5.9,5.9,0,0,0,.32,1,19.35,19.35,0,0,0,1.83,3.15c1.41,2.06,2.26,3.31.65,7.8l-1.83,5.14L28.36,16H31V11.5H17V16h3.2L22,21.74l-3.25,9.77L12.84,16H15.5V11.5H9.93C13.27,7.62,18.47,5.5,24.75,5.5Z"
        />
      </g>
    </g>
  </svg>
);

export default WordpressIcon;
