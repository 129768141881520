import { combineReducers } from 'redux';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';

import { escapeSepcialChars } from '../../../distribute/utils';

const initialState = {
  chunks: {},
  // items current displayed
  items: [],
  // full array of items
  original: [],
  // items currently selected
  selected: [],
  query: '',
  groupQuery: '',
  pageType: null,
  pending: true,
  tableType: 'user',
  open: false,
  message: {
    value: null,
  },
  isPendingSMS: false,
  smsPopup: 'disable',
  groupChunks: {},
  groupDataPending: true,
  groupFilteredChunks: {},
  groupItems: [],
  groupSelected: [],
  groupOriginal: [],
};

const smsEngageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_SMS_ENGAGE_DIALOG':
      return {
        ...state,
        open: true,
      };

    case 'SMS_POPUP_REQUIRED':
      return {
        ...state,
        smsPopup: action.value ? 'enable' : 'disable',
      };
    case 'SMS_ENGAGE_MESSAGE_CHANGE':
      return {
        ...state,
        message: {
          isValid: action.value !== '',
          value: action.value,
        },
      };

    case 'CLOSE_SMS_ENGAGE_DIALOG':
      return {
        ...state,
        open: false,
      };
    case 'DISPATCH_SMS_ENGAGE':
      return {
        ...state,
        isPendingSMS: true,
      };

    case 'SMS_ENGAGE_DISPATCH_COMPLETE':
      return {
        ...state,
        open: false,
        isPendingSMS: false,
        message: {
          value: '',
        },
      };
    case 'SET_SMS_TABLE_TYPE':
      return {
        ...state,
        tableType: action.tableType,
      };
    case 'LOAD_SMS_REQUEST':
      return {
        ...state,
        pending: true,
      };
    // save data in chunks as is and extract keys into items array to allow sort filtering
    case 'HYDRATE_SMS_REQUEST': {
      const keys = _keys(action.data);

      return {
        ...state,
        chunks: action.data,
        items: keys,
        // make a copy of the item keys to preserve original order
        // to allow us to filter on items directly later
        original: [...keys],
        pending: false,
        selected: [],
      };
    }

    case 'SEARCH_SMS_ENGAGE': {
      const getItems = () => {
        // escape special chars
        const escapedQuery = escapeSepcialChars(`${action.query}`);
        const searchExp = new RegExp(`^${escapedQuery}.*$`, 'i');

        return state.original.filter(key => {
          return (
            (state.chunks[key].device &&
              searchExp.test(state.chunks[key].device.os)) ||
            searchExp.test(state.chunks[key].phoneNumber) ||
            searchExp.test(state.chunks[key].firstName) ||
            searchExp.test(state.chunks[key].lastName)
          );
        });
      };

      return {
        ...state,
        items: action.query === '' ? [...state.original] : getItems(),
        query: action.query,
      };
    }
    case 'SEARCH_GROUP_SMS_ENGAGE': {
      const getItems = () => {
        // escape special chars
        const escapedQuery = escapeSepcialChars(`${action.query}`);
        const searchExp = new RegExp(`${escapedQuery}`, 'i');

        return state.groupOriginal.filter(key => {
          return searchExp.test(state.groupChunks[key].name);
        });
      };

      return {
        ...state,
        groupItems: action.query === '' ? [...state.groupOriginal] : getItems(),
        groupQuery: action.query,
      };
    }

    case 'SELECT_ENGAGE_SMS': {
      // if the prevState is false or undefined, we are selecting,
      // otherwise if true we are deselecting
      const prevState = state.chunks[action.key].selected;

      return {
        ...state,
        chunks: {
          ...state.chunks,
          [action.key]: {
            ...state.chunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }
    case 'SELECT_ALL_SMS': {
      // we only select all from the items current displayed
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: true,
      }));
      const selected = _union(state.selected, _keys(filteredChunks));

      return {
        ...state,
        chunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case 'DESELECT_ALL_SMS':
      return {
        ...state,
        chunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };
    case 'SELECT_ALL_GROUPS': {
      // we only select all from the items current displayed
      const selectedChunks = _mapValues(state.groupFilteredChunks, chunk => ({
        ...chunk,
        groupSelected: true,
      }));

      const groupSelected = _union(
        state.groupSelected,
        _keys(state.groupFilteredChunks),
      );

      return {
        ...state,
        groupChunks: {
          ...state.groupChunks,
          ...selectedChunks,
        },
        groupFilteredChunks: {
          ...state.groupChunks,
          ...selectedChunks,
        },
        groupSelected,
        groupSelectAll: true,
      };
    }

    case 'DESELECT_ALL_GROUPS':
      return {
        ...state,
        groupChunks: _mapValues(state.groupChunks, chunk => ({
          ...chunk,
          groupSelected: false,
        })),
        groupFilteredChunks: _mapValues(state.groupFilteredChunks, chunk => ({
          ...chunk,
          groupSelected: false,
        })),
        groupSelected: [],
        groupSelectAll: false,
      };

    case 'SELECT_GROUP': {
      // if the prevState is false or undefined, we are selecting,
      // otherwise if true we are deselecting
      const prevState =
        state.groupFilteredChunks[action.key] &&
        state.groupFilteredChunks[action.key].groupSelected;

      return {
        ...state,
        groupFilteredChunks: {
          ...state.groupFilteredChunks,
          [action.key]: {
            ...state.groupFilteredChunks[action.key],
            groupSelected: !prevState,
            selected: !prevState,
          },
        },
        groupSelected:
          prevState === true
            ? _without(state.groupSelected, action.key)
            : state.groupSelected.concat(action.key),
      };
    }
    case 'HYDRATE_GROUPS': {
      const groups = action.data ? action.data.groups : {};
      const keys = _keys(groups);

      return {
        ...state,
        groupChunks: groups,
        groupFilteredChunks: groups,
        groupItems: keys,
        // make a copy of the item keys to preserve original order
        // to allow us to filter on items directly later
        groupOriginal: [...keys],
        groupDataPending: false,
        groupSelected: [],
      };
    }

    // case 'RECEIVE_GROUPS':
    //   return {
    //     ...state,
    //     chunks: action.value,
    //     selected: [],
    //     filteredChunks: action.value,
    //     items: _keys(action.value),
    //     pending: false,
    //     error: null,
    //   };

    default:
      return state;
  }
};

export default combineReducers({
  data: smsEngageReducer,
});
