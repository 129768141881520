import firebase from '../../../../../../firebase';

// export const onFromEmailChange = value => dispatch => {
//   dispatch({
//     type: 'SET_FROM_EMAIL',
//     value,
//   });
// };

//3
export const getEmailList = appname => async dispatch => {
  const myHeaders = new Headers();
  await firebase
    .auth()
    .currentUser.getIdToken()
    .then(async token => {
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);
      return fetch(`${process.env.REACT_APP_ADMIN_API}?appname=${appname}`, {
        method: 'get',
        headers: myHeaders,
        mode: 'cors',
      });
    })
    .then(async response => {
      return response.json();
    })
    .then(result => {
      const data = Object.entries(result.emails_data).map(item => ({
        key: item[0],
        value: item[1].title,
      }));

      dispatch({
        type: 'GET_EMAIL_LIST',
        value: data,
      });
    })
    .catch(err => console.log(err));
};
