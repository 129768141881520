import * as React from 'react';

const RegionalPerformanceIcon = props => (
  <svg
    viewBox="0.1026 0 49.8274 49.6656"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Regional Performance </title>
    <g
      transform="matrix(0.1000000014901161, 0, 0, -0.1000000014901161, 0, 49.99999999999999)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M233 486 c-4 -7 -18 -18 -29 -24 -20 -11 -21 -14 -9 -49 12 -36 16 -38 59 -40 40 -2 46 1 46 18 0 11 5 29 10 39 8 16 6 21 -12 31 -13 7 -27 18 -31 25 -4 8 -12 14 -17 14 -5 0 -13 -6 -17 -14z m42 -30 c15 -8 22 -17 16 -21 -6 -3 -11 -14 -11 -24 0 -13 -8 -18 -30 -18 -22 0 -30 5 -30 18 0 10 -5 21 -11 25 -7 4 -2 11 13 19 12 7 24 13 24 14 1 1 14 -5 29 -13z"
        style={{
          fill: 'rgb(41, 31, 212)',
        }}
      />
      <path
        d="M62 402 c-26 -22 -30 -30 -20 -40 7 -7 13 -22 13 -34 0 -20 5 -23 45 -23 40 0 45 3 48 25 2 14 9 31 14 38 6 7 9 14 7 15 -2 1 -18 12 -36 25 -18 12 -33 22 -35 22 -2 0 -18 -13 -36 -28z m52 -7 c3 -8 13 -15 23 -15 13 0 14 -3 5 -12 -7 -7 -12 -19 -12 -28 0 -12 -9 -17 -30 -17 -21 0 -30 5 -30 17 0 9 -5 21 -12 28 -9 9 -8 12 5 12 10 0 20 7 23 15 4 8 10 15 14 15 4 0 10 -7 14 -15z"
        style={{
          fill: 'rgb(41, 31, 212)',
        }}
      />
      <path
        d="M380 415 c-7 -8 -18 -15 -25 -15 -16 0 -26 -25 -15 -36 5 -5 10 -20 12 -34 3 -22 8 -25 48 -25 39 0 45 3 45 22 1 11 7 28 14 37 8 9 12 18 10 19 -2 1 -18 12 -36 25 -38 26 -37 26 -53 7z m34 -20 c3 -8 13 -15 23 -15 13 0 14 -3 5 -12 -7 -7 -12 -19 -12 -28 0 -12 -9 -17 -30 -17 -21 0 -30 5 -30 17 0 9 -5 21 -12 28 -9 9 -8 12 5 12 10 0 20 7 23 15 4 8 10 15 14 15 4 0 10 -7 14 -15z"
        style={{
          fill: 'rgb(41, 31, 212)',
        }}
      />
      <path
        d="M161 283 c-86 -31 -170 -150 -159 -225 3 -20 9 -23 61 -26 55 -3 58 -2 67 25 20 57 36 78 74 97 32 16 44 17 76 7 36 -11 68 -36 58 -46 -3 -3 -29 -14 -59 -24 -61 -22 -80 -42 -63 -69 17 -27 38 -25 87 8 51 34 62 36 71 15 8 -22 114 -21 123 2 10 25 -14 103 -45 148 -61 88 -188 126 -291 88z m79 -53 c0 -41 -3 -50 -18 -50 -10 0 -27 -7 -38 -15 -19 -13 -24 -11 -54 20 -19 20 -29 38 -25 45 10 17 85 50 113 50 20 0 22 -5 22 -50z m106 31 c58 -26 61 -37 24 -76 -30 -31 -35 -33 -54 -20 -11 8 -28 15 -38 15 -15 0 -18 9 -18 50 0 45 2 50 23 50 12 0 41 -9 63 -19z m-229 -93 c29 -30 30 -35 17 -52 -8 -11 -14 -30 -14 -43 0 -21 -4 -23 -50 -23 -47 0 -50 2 -50 25 0 41 40 125 59 125 4 0 21 -15 38 -32z m341 -18 c12 -24 22 -57 22 -72 0 -27 -2 -28 -50 -28 -59 0 -68 20 -20 45 17 9 30 22 30 30 0 18 -9 19 -45 5 -15 -6 -31 -7 -37 -4 -12 8 52 76 67 71 5 -2 20 -23 33 -47z m-48 -34 c0 -2 -25 -18 -56 -36 -39 -22 -60 -28 -67 -21 -10 10 -6 13 83 46 43 17 40 16 40 11z m-142 -74 c3 -17 -2 -22 -17 -22 -14 0 -21 6 -21 18 0 38 33 41 38 4z"
        style={{
          fill: 'rgb(41, 31, 212)',
        }}
      />
    </g>
  </svg>
);
export default RegionalPerformanceIcon;
