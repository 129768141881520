import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import SportScotlandData from './SportScotlandData';
import {
  getTypes,
  getTypesData,
} from '../../../../../memberLogin/modules/memberTypes/modules/listing/actions';
import { getSubscribers, getAttended, getEventsCoursesCPDs } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  workforceGroups: state.auth.memberGroups.listPage.data.chunks,
  typesData: state.auth.memberGroups.listPage.data.typesData,
  ...state.auth.report.sportscotland,
  users: state.auth.users.chunks,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getTypes: () => dispatch(getTypes(ownProps.match.params.appname)),
  getTypesData: () => dispatch(getTypesData(ownProps.match.params.appname)),
  getSubscribers: () => dispatch(getSubscribers(ownProps.match.params.appname)),
  getAttended: () => dispatch(getAttended(ownProps.match.params.appname)),
  getEventsCoursesCPDs: () =>
    dispatch(getEventsCoursesCPDs(ownProps.match.params.appname)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SportScotlandData),
);
