import styled from 'styled-components';

const Square = styled.button`
  color: #333;
  text-align: center;
  height: 32px;
  font-size: 14px;
  font-weight: 800;
  border-radius: 5px;
  transition: background-color 0.5s ease-in-out;
  background-color: #ffffff;
  &:hover {
    background-color: #ededed;
  }
  &.success {
    color: #ffffff;
    background-color: #00ff52;
    &:hover {
      background-color: rgb(69, 156, 97);
    }
  }
  &.danger {
    color: #ffffff;
    background-color: #d9534f;
    &:hover {
      background-color: #be2f2b;
    }
  }
  &.info {
    color: #ffffff;
    background-color: #5bc0de;
    &:hover {
      background-color: #39accd;
    }
  }
`;

export default Square;
