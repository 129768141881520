import styled from 'styled-components';

const ModalToolbarTitle = styled.h5`
  flex: 1;
  text-align: left;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
`;

export default ModalToolbarTitle;
