import {
  DOMAIN_UPDATED,
  CHECKING_DOMAIN_AVAILABILITY,
  ASSERT_DOMAIN_AVAILABILITY,
  DOMAIN_UPDATE_ERROR,
} from './actions';

const initialState = {
  domain: null,
  fetchingDomain: false,
  isModified: false,
  availableDomain: true,
  validDomain: true,
};

const appDomainInput = (state = initialState, action) => {
  switch (action.type) {
    case CHECKING_DOMAIN_AVAILABILITY:
      return {
        ...state,
        fetchingDomain: true,
      };

    case ASSERT_DOMAIN_AVAILABILITY:
      return {
        ...state,
        availableDomain: action.available,
        fetchingDomain: false,
      };

    case DOMAIN_UPDATED:
      return {
        ...state,
        isModified: action.isModified,
        domain: action.domain,
        validDomain:
          action.valid !== undefined ? action.valid : state.validDomain,
      };

    case DOMAIN_UPDATE_ERROR:
      return {
        ...state,
        isModified: false,
        domain: action.domain,
        validDomain: true,
      };

    default:
      return state;
  }
};

export default appDomainInput;
