import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';

import Container from '../../../../../../../app/components/container';
import TextField from '../../../../../../../app/components/textField';
import SectionHeader from '../../../../../components/sectionHeader';
import { LOCATION_FEATURE_ENABLED_APPS } from '../../../../../../../config';
import CircularValidation from '../../../../../../../app/components/circularValidation';
import themeStyles from '../../../../../../../_export.scss';

class FormFieldItem extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  getTooltipText = () => {
    const { t } = this.props;
    return this.requiredDisabled()
      ? t('At least one field must be required')
      : t(
          'Users will have to fill in this field to be able to submit the form',
        );
  };
  handleChange = field => value => this.props.onChange(field, value);
  handleToggle = (field, value) => this.props.onChange(field, value);
  handleDropdownOption = (field, value) => this.props.onChange(field, value);
  requiredDisabled = () =>
    this.props.fields &&
    Object.entries(this.props.fields.chunks).filter(
      field =>
        field[1].required &&
        queryString.parse(this.props.location.search).item !== field[0],
    ).length === 0 &&
    this.props.data.required;

  render() {
    const {
      label,
      type,
      required,
      question,
      id,
      multiple,
      options,
    } = this.props.data;
    const { t } = this.props;
    const styles = {
      toggleContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
    };
    return (
      <Container>
        <SectionHeader
          title={t('Field Label')}
          tooltip={t('Provide a helpful label to your users for this field')}
        />
        <div style={styles.toggleContainer}>
          <SectionHeader
            title={t('Required field')}
            tooltip={this.getTooltipText()}
            tooltipType={this.requiredDisabled() ? 'warning' : 'info'}
            actions={
              <Toggle
                id="form-field-required-toggle"
                toggled={required}
                onToggle={(field, value) =>
                  this.requiredDisabled()
                    ? () => {}
                    : this.handleToggle('required', value)
                }
                disabled={this.requiredDisabled()}
                trackSwitchedStyle={{
                  backgroundColor: themeStyles.primaryColorLight,
                }}
                thumbSwitchedStyle={{
                  backgroundColor: themeStyles.primaryColor,
                }}
              />
            }
          />
        </div>
        {type === 'dropdown' && (
          <div style={styles.toggleContainer}>
            <SectionHeader
              title={t('Multiple field')}
              tooltip={t('Users will be add multiple fields')}
              tooltipType={'info'}
              actions={
                <Toggle
                  id="form-field-multiple-toggle"
                  toggled={multiple}
                  onToggle={(field, value) =>
                    this.handleToggle('multiple', value)
                  }
                  trackSwitchedStyle={{
                    backgroundColor: themeStyles.primaryColorLight,
                  }}
                  thumbSwitchedStyle={{
                    backgroundColor: themeStyles.primaryColor,
                  }}
                />
              }
            />
          </div>
        )}
        {type !== 'email' && (
          <TextField
            id="form-field-label-field"
            value={question || ''}
            onChange={this.handleChange('question')}
            placeholder={t('Question')}
            isValid={question !== ''}
            multiLine
          />
        )}
        <TextField
          id="form-field-label-field"
          value={label || ''}
          onChange={this.handleChange('label')}
          placeholder={t('Label')}
          required={!question}
          isValid={label !== ''}
          errorText={t('You need to provide either a question or a label')}
        />
        {LOCATION_FEATURE_ENABLED_APPS.indexOf(
          this.props.match.params.appname,
        ) > -1 && (
          <TextField
            id="form-field-id-field"
            value={id || ''}
            onChange={this.handleChange('id')}
            placeholder="Id"
            required={true}
            isValid={id !== ''}
            errorText={t('You need to provide id')}
          />
        )}
        {type === 'dropdown' && (
          <TextField
            id="options"
            placeholder={t('Dropdown Options')}
            label={t('Dropdown Options')}
            multiLine
            value={options || ''}
            onChange={value =>
              value.length >= 2
                ? this.handleDropdownOption('options', value)
                : null
            }
            rightInputAction={
              <CircularValidation
                isValid={options && options.split(',').length > 1}
                defaultMsg={t('Add multiple options using comma separated')}
                errorMsg={t('Options is required')}
                showDefault={!options}
              />
            }
          />
        )}
      </Container>
    );
  }
}

export default withTranslation()(FormFieldItem);
