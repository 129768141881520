import firebase from '../../../../../../../../firebase';
import { validateField } from '../../validators';
import { openSnackbar } from '../../../../../../../../app/modules/snackbar/actions';
import { WORKFORCE_GROUP_CREATED } from '../../../../config';

export const NEW_MEMBER_GROUP_WORKFORCE_TYPE_CHANGE =
  'NEW_MEMBER_GROUP_WORKFORCE_TYPE_CHANGE';
export const OPEN_NEW_MEMBER_GROUP_DIALOG = 'OPEN_NEW_MEMBER_GROUP_DIALOG';
export const CLOSE_NEW_MEMBER_GROUP_DIALOG = 'CLOSE_NEW_MEMBER_GROUP_DIALOG';
export const ADD_NEW_MEMBER_GROUP = 'ADD_NEW_MEMBER_GROUP';
export const NEW_MEMBER_GROUP_ADDED = 'NEW_MEMBER_GROUP_ADDED';

const newGroupFieldChange = (prop, value, isValid) => ({
  type: NEW_MEMBER_GROUP_WORKFORCE_TYPE_CHANGE,
  prop,
  value,
  isValid,
});

export const openMemberGroupDialog = () => ({
  type: OPEN_NEW_MEMBER_GROUP_DIALOG,
});

export const closeMemberGroupDialog = () => ({
  type: CLOSE_NEW_MEMBER_GROUP_DIALOG,
});

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;

  return dispatch(newGroupFieldChange(prop, value, isValid));
};

export const addGroup = appname => (dispatch, getState) => {
  const { name } = getState().auth.memberGroups.listPage.createGroup;

  const group = {
    name: name.value,
    createdAt: Date.now(),
  };

  dispatch({ type: ADD_NEW_MEMBER_GROUP });

  return firebase
    .database()
    .ref(`workforce_groups/${appname}`)
    .push(group)
    .then(ref => {
      dispatch({
        type: NEW_MEMBER_GROUP_ADDED,
        key: ref.key,
        data: group,
      });
      dispatch(openSnackbar(WORKFORCE_GROUP_CREATED));
    });
};
