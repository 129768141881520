import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import Analytics from './Analytics';
import {
  fetchDevicesData,
  fetchNotificationsData,
  fetchCsvData,
  updateGaTrackerId,
  // fetchGaTrackerId,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  plans: state.account.subscriptions.items,
  integrationType: _get(state, 'account.organisation.integration.type'),
  appname: ownProps.match.params.appname,
  plan:
    state.account.subscriptions.items[ownProps.match.params.appname] &&
    state.account.subscriptions.items[ownProps.match.params.appname].plan,
  is14DayTrialUser: state.account.organisation.isOn14DaysTrial || false,
  trialExpired: state.account.organisation.trial_expired || false,
  analyticSectionsAccess: state.account.profile.sectionsAccess.analytics,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetchDevices: () =>
    dispatch(fetchDevicesData(ownProps.match.params.appname)),
  onFetchNotifications: () =>
    dispatch(fetchNotificationsData(ownProps.match.params.appname)),
  onFetchCsv: () => dispatch(fetchCsvData(ownProps.match.params.appname)),
  onUpdateGaTrackerId: value =>
    dispatch(updateGaTrackerId(ownProps.match.params.appname, value)),
  // onFetchGaTrackerId: () =>
  //   dispatch(fetchGaTrackerId(ownProps.match.params.appname)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Analytics),
);
