import React, { Fragment } from 'react';
import HalfImage from './images/half.png';
import './styles.css';

const Half = () => {
  return (
    <Fragment>
      <img className="imageWrapper" src={HalfImage} alt="Half" />
    </Fragment>
  );
};

export default Half;
