import React from 'react';

const Text = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50.5 31.48"
  >
    <title>header</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path class="cls-1" d="M50.5,31.48H0V0H50.5ZM1.5,30H49V1.5H1.5Z" />
        <polygon
          class="cls-1"
          points="30.61 21.33 25.05 11.7 19.49 21.33 23.8 21.33 23.8 29.99 26.3 29.99 26.3 21.33 30.61 21.33"
        />
        <path
          class="cls-1"
          d="M27.05,30.74h-4V22.08H18.19L25.05,10.2,31.9,22.08H27.05Zm-2.5-1.5h1V20.58h3.76L25.05,13.2l-4.26,7.38h3.76Z"
        />
      </g>
    </g>
  </svg>
);

export default Text;
