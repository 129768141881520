const initialState = {
  pending: false,
  open: false,
  type: 'Text',
  diyPopupOpen: false,
  specialAddons: [],
  initialSearchValue: '',
};

const createComponent = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SPECIAL_ADDONS': {
      return {
        ...state,
        specialAddons: action.value,
      };
    }
    case 'OPEN_CREATE_COMPONENT_UI':
      return {
        ...state,
        initialSearchValue: action.value,
        open: true,
      };

    case 'CLOSE_CREATE_COMPONENT_UI':
      return {
        ...state,
        initialSearchValue: '',
        open: false,
      };

    case 'CREATING_COMPONENT':
      return {
        ...state,
        pending: true,
      };

    case 'CREATE_COMPONENT_TYPE_CHANGE':
      return {
        ...state,
        type: action.value,
      };
    case 'TOGGLE_DIY_POPUP_STATE':
      return {
        ...state,
        diyPopupOpen: action.value,
      };

    case 'RESET_COMPONENT_FORM':
      return initialState;

    default:
      return state;
  }
};

export default createComponent;
