import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Trans, withTranslation } from 'react-i18next';

import ResultsTable from '../../../../app/components/resultsTable';
import TryAgain from '../../../../app/components/tryAgain';
import Header from '../../components/header';
import EditMemberBody from './editMemberBody';
import { HS_FEATURES_ENABLED_APPS } from '../../../../config';

class MemberBodies extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    chunks: PropTypes.object,
    getMemberBodies: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    onFilterMemberBodyData: PropTypes.func.isRequired,
    onAddEventCalendarComponent: PropTypes.func,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    filter: PropTypes.string,
    profile: PropTypes.object.isRequired,
    componentsPending: PropTypes.bool,
    eventComponentEnabled: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
    chunks: {},
    filteredChunks: {},
    items: [],
    query: '',
    selected: [],
    selectAll: false,
  };

  componentDidMount() {
    this.props.getMemberBodies(this.props.profile.memberBodies);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.location &&
      this.props.location.pathname !== nextProps.location.pathname &&
      nextProps.location.pathname ===
        `/${this.props.match.params.appname}/staff/member-bodies`
    ) {
      this.props.getMemberBodies(this.props.profile.memberBodies);
    }
  }

  getTitle = (selected, memberBodies) => {
    const { t } = this.props;
    return (
      <span>
        {this.isHorseScotlandApp()
          ? t('MemberBodies')
          : t('Event Organizers')}{' '}
        {selected.length
          ? `${selected.length} / ${Object.keys(memberBodies).length}`
          : Object.keys(memberBodies).length}
      </span>
    );
  };

  isHorseScotlandApp = () =>
    HS_FEATURES_ENABLED_APPS.indexOf(this.props.match.params.appname) > -1;

  render() {
    const {
      pending,
      items,
      error,
      getMemberBodies,
      chunks,
      filteredChunks,
      onFilterMemberBodyData,
      selected,
      history,
      match,
      profile,
      t,
    } = this.props;

    const body = error ? (
      <TryAgain
        message={t('Oops, something went wrong...')}
        onRetry={() => getMemberBodies(profile.memberBodies)}
      />
    ) : (
      <ResultsTable
        items={items}
        chunks={filteredChunks}
        loading={pending}
        pending={pending}
        error={Object.keys(filteredChunks).length === 0 ? 'yes' : null}
        emptyMessage={
          this.isHorseScotlandApp()
            ? t('Looks like no member bodies have been assigned to you yet')
            : t('Looks like no events organizers have been assigned to you yet')
        }
        onRowTouchTap={memberBody =>
          history.push(
            `/${match.params.appname}/staff/member-bodies/${memberBody}`,
          )
        }
        columns={[
          {
            id: 'name',
            header: this.isHorseScotlandApp()
              ? t('Member Body Name')
              : t('Event Organizer Name'),
            classNames: 'col-xs-2',
          },
          {
            id: this.isHorseScotlandApp()
              ? 'adminUsername'
              : 'primaryContactName',
            header: this.isHorseScotlandApp()
              ? t('Admin User Name')
              : t('Contact Name'),
            classNames: 'col-xs-2',
          },
          {
            id: this.isHorseScotlandApp()
              ? 'adminEmail'
              : 'primaryContactEmail',
            header: t('Email'),
            classNames: 'col-xs-3',
          },
          {
            id: 'primaryContactName',
            header: t('Phone Number'),
            classNames: 'col-xs-2',
          },

          {
            id: 'date',
            header: t('Date Created'),
            classNames: 'col-xs-2',
            containerElement: ({ value }) => (
              <span>{value && moment(value).format('DD/MM/YYYY')}</span>
            ),
          },
        ]}
      />
    );

    return (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <Header
          {...this.props}
          title={this.getTitle(selected, chunks)}
          onChange={onFilterMemberBodyData}
          onSelectAllResponses={() => {}}
          onDeselectAllResponses={() => {}}
        />
        {body}

        <EditMemberBody />
      </section>
    );
  }
}

export default withTranslation()(MemberBodies);
