import queryString from 'query-string';
import { connect } from 'react-redux';

import ScheduleItem from './ScheduleItem';
import {
  scheduleFieldChange,
  getScheduleFilters,
  toggleDialog,
  createLink,
} from './actions';
import { get } from 'lodash';
import { getGroupsData } from 'modules/memberLogin/modules/groups/actions';
const mapStateToProps = (state, ownProps) => {
  const lazyData = state.editor.data.lazyData[ownProps.component.lazy_data];
  const layoutData =
    state.editor.data.layoutData[ownProps.match.params.appname];
  const childRoutes = layoutData && layoutData.childRoutes;
  const groupsData = get(state, 'auth.groups.data.chunks', {});
  return {
    ...ownProps,
    filters: lazyData.filters,
    childRoutes:
      childRoutes &&
      childRoutes.map(key => ({ ...state.editor.data.alwaysData[key], key })),
    groupsData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getFilters: () =>
    dispatch(
      getScheduleFilters(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
      ),
    ),
  onChange: (path, value) => {
    const { item, itinerary } = queryString.parse(ownProps.location.search);

    return dispatch(
      scheduleFieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        item,
        itinerary,
        path,
        value,
      ),
    );
  },
  createLink: () => {
    const { item, itinerary } = queryString.parse(ownProps.location.search);

    return dispatch(
      createLink(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        item,
        itinerary,
        true,
      ),
    );
  },
  onDialogToggle: isToggled =>
    dispatch(
      toggleDialog(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        queryString.parse(ownProps.location.search).item,
        queryString.parse(ownProps.location.search).itinerary,
        isToggled,
      ),
    ),
  getGroupsData: () => dispatch(getGroupsData(ownProps.match.params.appname)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleItem);
