import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const Container = ({ ref, className, children, style }) => (
  <div ref={ref} className={`form-container ${className}`} style={style}>
    {children}
  </div>
);

Container.propTypes = {
  ref: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

Container.defaultProps = {
  ref: () => {},
  className: '',
  style: undefined,
};

export default Container;
