import { connect } from 'react-redux';

import File from './File';
import { designChange, fieldChange, uploadFile } from './actions';

const fileFirebaseUrl = (appName, dataId) =>
  `apps/${appName}/draft/lazy_data/${dataId}/url`;

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.editor.build.fileUpload,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onReceivePdf: file =>
    dispatch(
      uploadFile(
        file,
        fileFirebaseUrl(
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
        ),
      ),
    ),
  handleChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onButtonDesignChange: (type, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        `buttonDesign/${type}`,
        value,
      ),
    ),
  onDesignToggle: value =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        'enabled',
        value,
      ),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(File);
