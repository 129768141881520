const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  duplicateContacts: {},
  isValid: true,
  data: null,
  name: {
    value: '',
    isValid: null,
  },
  email: {
    value: '',
    isValid: null,
  },
  mobile: {
    value: '',
    isValid: null,
  },
  country: {
    value: {
      code: 'GB',
      dialCode: '+44',
      name: 'United Kingdom',
    },
  },
};

const newContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_NEW_CONTACT_DIALOG':
      return {
        ...state,
        open: true,
      };

    case 'CLOSE_NEW_CONTACT_DIALOG':
      return {
        ...state,
        open: false,
      };

    case 'NEW_CONTACT_FIELD_CHANGE':
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };

    case 'ADD_NEW_CONTACT':
      return {
        ...state,
        pending: true,
      };

    case 'CLOSE_NEW_LIST_DIALOG':
      return {
        ...state,
        open: false,
      };

    case 'NEW_LIST_NAME_CHANGE':
      return {
        ...state,
        name: action.value,
      };

    case 'IMPORT_LIST':
    case 'UPLOAD_LIST':
      return {
        ...state,
        pending: true,
      };

    case 'RECEIVE_LIST':
      return {
        ...state,
        data: action.data,
        pending: false,
        error: null,
      };

    case 'IMPORT_ERROR':
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case 'DUPLICATE_CONTACT':
      return {
        ...state,
        pending: false,
        duplicateContacts: action.result,
        newContactsContacts: action.newContacts,
      };

    case 'NEW_CONTACT_ADDED':
    case 'LIST_UPLOAD_COMPLETE':
    case 'CANCEL_IMPORT':
    case 'IMPORT_COMPLETE':
      return initialState;

    default:
      return state;
  }
};

export default newContactReducer;
