import { connect } from 'react-redux';

import Distribute from './Distribute';
import {
  openDistributeDialog,
  closeDistributeDialog,
  setDefaultMessage,
  setWhatsappMessage,
  messageChange,
  changeDistributeMode,
  sendSMS,
  sendEmail,
  sendWhatsapp,
  facebookShare,
  subjectChange,
  loadAppTitle,
  whatsappMessageChange,
} from './actions';

import { openNewContactDialog } from './modules/contacts/newContact/actions';
import { HS_FEATURES_ENABLED_APPS } from '../../config';
import { loadCustomDomain } from '../../app/modules/customDomain/actions';
import { getDefaultWhatsappCredits } from '../../utils';

const mapStateToProps = (state, ownProps) => {
  const selectedContacts = state.distribute.contacts.data.selected.length;
  const selectedGroups = state.distribute.contacts.data.groupSelected.length;
  const selectedWorkforceGroups =
    state.distribute.contacts.data.workforceGroupsSelected.length;

  const selected =
    HS_FEATURES_ENABLED_APPS.indexOf(ownProps.match.params.appname) > -1
      ? selectedContacts + selectedGroups + selectedWorkforceGroups
      : selectedContacts + selectedGroups;

  const isAppAdmin =
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false;
  let whatsappSMSCredit = state.account.organisation.whatsapp_sms_credit;

  const trialExpired = state.account.organisation.trial_expired;

  const appSubscription = state.app.appContext.subscription;
  //if whatsapp credits does not exists on account get the default amount
  if (whatsappSMSCredit === undefined || whatsappSMSCredit === null) {
    whatsappSMSCredit = getDefaultWhatsappCredits({
      isAppAdmin,
      trialExpired,
      appSubscription,
    });
  }

  return {
    ...ownProps,
    ...state.distribute.share,
    selected,
    subscription: state.app.appContext.subscription,
    smsCredit: state.account.organisation.sms_credit,
    organisation: state.account.organisation,
    isAppAdmin,
    is14DayTrialUser: state.account.organisation.isOn14DaysTrial || false,
    whatsappSMSCredit,
    ditributeSectionsAccess: state.account.profile.sectionsAccess.distribute,
  };
};

const mapDisptachToProps = (dispatch, ownProps) => ({
  openDistributeDialog: () => dispatch(openDistributeDialog()),
  closeDistributeDialog: customDomain =>
    dispatch(
      closeDistributeDialog(ownProps.match.params.appname, customDomain),
    ),
  setDefaultMessage: customDomain =>
    dispatch(setDefaultMessage(ownProps.match.params.appname, customDomain)),
  setWhatsappMessage: customDomain =>
    dispatch(setWhatsappMessage(ownProps.match.params.appname, customDomain)),
  onAddContact: () =>
    dispatch(openNewContactDialog(ownProps.match.params.appname)),
  onLoadAppTitle: () => dispatch(loadAppTitle(ownProps.match.params.appname)),
  onMessageChange: value => dispatch(messageChange(value)),
  onWhatsappMessageChange: value => dispatch(whatsappMessageChange(value)),
  onSubjectChange: value => dispatch(subjectChange(value)),
  onModeChange: mode => dispatch(changeDistributeMode(mode)),
  onSendEmail: () => dispatch(sendEmail(ownProps.match.params.appname)),
  onSendSMS: () => dispatch(sendSMS(ownProps.match.params.appname)),
  onSendWhatsapp: () => dispatch(sendWhatsapp(ownProps.match.params.appname)),
  onFacebookShare: customDomain =>
    dispatch(facebookShare(ownProps.match.params.appname, customDomain)),
  loadCustomDomain: () =>
    dispatch(loadCustomDomain(ownProps.match.params.appname)),
});

export default connect(mapStateToProps, mapDisptachToProps)(Distribute);
