import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectProduct, deselectProduct } from './actions';
import Products from './Products';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.ecommerce.marketplace.products,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectProduct: product => dispatch(selectProduct(product)),
  deselectProduct: () => dispatch(deselectProduct()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Products),
);
