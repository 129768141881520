import React from 'react';

import './styles.css';

const PaymentHistory = () => (
  <svg
    className="icon payment-history"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
  >
    <g>
      <g>
        <path
          d="M22.7,18.9c-0.2-1.1-0.9-1.6-2.1-1.6c-0.6,0-1,0.2-1.4,0.7c-0.4,0.4-0.6,1.1-0.6,2.1v2.2h3.7v1.1h-3.7v2.1
                c0,0.9-0.3,1.6-0.8,1.9h3.7c0.7,0,1.1-0.1,1.5-0.4c0.3-0.3,0.5-0.7,0.6-1.3h1.7c-0.1,1-0.4,1.8-1,2.3c-0.6,0.5-1.5,0.8-2.8,0.8H15
                v-1.4h0.6c0.5,0,0.9-0.1,1.1-0.4c0.2-0.3,0.4-0.8,0.4-1.4v-2.2h-1.9v-1.1h1.9v-2.2c0-1.3,0.3-2.4,0.9-3.1c0.6-0.7,1.6-1.1,2.8-1.1
                c1,0,1.7,0.3,2.4,0.8c0.6,0.5,1,1.3,1.1,2.3H22.7z"
        />
      </g>
      <path
        d="M26.6,31.9c0-0.2,0-0.4,0-0.7H1.3V13.5H39v5.2c0.2,0,0.4,0,0.7,0s0.4,0,0.7,0v-6.5H0v20.3h26.6
            C26.6,32.3,26.6,32.1,26.6,31.9z"
      />
      <path
        d="M39.7,42.2c-5.7,0-10.3-4.6-10.3-10.3S34,21.5,39.7,21.5S50,26.2,50,31.9S45.4,42.2,39.7,42.2z M39.7,22.9
            c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S44.7,22.9,39.7,22.9z"
      />
      <polygon points="46.8,32.5 39,32.5 39,27 40.3,27 40.3,31.2 46.8,31.2 	" />
    </g>
  </svg>
);

export default PaymentHistory;
