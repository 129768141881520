import {
  OPEN_HISTORY_DETAIL,
  CLOSE_HISTORY_DETAIL_DIALOG,
  PREVIOUS_HISTORY_RESPONSE,
  NEXT_HISTORY_RESPONSE,
} from './actions';
import {
  DELETE_NOTIFICATION,
  RECEIVE_NOTIFICATION_DATA,
  NO_MESSAGE_ID,
} from '../actions';

const initialState = {
  open: false,
  data: null,
  key: null,
};

const historyDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_HISTORY_DETAIL:
      return {
        ...state,
        open: true,
        data: action.history,
        key: action.key,
      };

    case CLOSE_HISTORY_DETAIL_DIALOG:
      return initialState;

    case PREVIOUS_HISTORY_RESPONSE: {
      const key =
        action.historys.data.items[
          action.historys.data.items.indexOf(state.key) - 1
        ];
      if (key) {
        return {
          ...state,
          open: true,
          data: action.historys.data.chunks[key],
          key,
        };
      }
      return {
        ...state,
      };
    }

    case NEXT_HISTORY_RESPONSE: {
      const key =
        action.historys.data.items[
          action.historys.data.items.indexOf(state.key) + 1
        ];
      if (key) {
        return {
          ...state,
          open: true,
          data: action.historys.data.chunks[key],
          key,
        };
      }
      return {
        ...state,
      };
    }

    case DELETE_NOTIFICATION: {
      return {
        ...state,
        open: false,
      };
    }

    case RECEIVE_NOTIFICATION_DATA:
      return { ...state, notificationData: action.body };

    case NO_MESSAGE_ID:
      return { ...state, notificationData: null };

    default:
      return state;
  }
};

export default historyDetailsReducer;
