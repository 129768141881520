import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import DisabledWrapper from '../../../../engage/components/disabledWrapper';
import CTAButton from '../../../../../app/components/buttons/cta/CTA';

const LivestreamDisabledWrapper = ({
  onAddLivestreamComponent,
  history,
  children,
}) => {
  const { t } = useTranslation();

  const [btnLabel, setBtnLabel] = useState(t('Do it for me!'));
  const [btnDisabled, setBtnDisabled] = useState(false);

  const infoIcon = (
    <Tooltip
      title={t(
        "This will add a new page 'Livestream' and add Livestream component to that page in your app",
      )}
    >
      <InfoOutlinedIcon fontSize="small" />
    </Tooltip>
  );

  const instructions = (
    <span>
      <span
        role="button"
        tabIndex={0}
        onClick={() => {
          history.push(
            history.location.pathname.replace(
              'engage/livestream',
              'editor/build/list?page=homepage',
            ),
          );
        }}
        style={{ borderBottom: '1px solid black', cursor: 'pointer' }}
      >
        <Trans>Go to pages</Trans>
      </span>
      &ndash;&gt; <Trans>Select a page</Trans> &ndash;&gt;{' '}
      <Trans>Click the</Trans> &quot;<Trans>Add Component</Trans>&quot;{' '}
      <Trans>button</Trans> (<span style={{ fontWeight: 600 }}>&#43;</span>{' '}
      <Trans>icon</Trans>) &ndash;&gt;{' '}
      <Trans>Select Livestream component</Trans> &ndash;&gt;{' '}
      <Trans>Click button</Trans>
      &quot;<Trans>Add Livestream Component</Trans>&quot;
    </span>
  );

  return (
    <DisabledWrapper
      title={t('Livestream Component has not been added to your app yet!')}
      message={
        <span>
          <span>
            <Trans>
              To use this feature add Livestream Component to your app
            </Trans>
          </span>
          <br />
          {instructions}
        </span>
      }
      content={
        <div>
          <br />
          <CTAButton
            disabled={btnDisabled}
            label={btnLabel}
            height={35}
            minWidth={100}
            icon={infoIcon}
            onClick={() => {
              onAddLivestreamComponent();
              setBtnDisabled(true);
              setBtnLabel(t('Adding...'));
            }}
          />
        </div>
      }
    >
      {children}
    </DisabledWrapper>
  );
};

LivestreamDisabledWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  history: PropTypes.object.isRequired,
  onAddLivestreamComponent: PropTypes.func.isRequired,
  appname: PropTypes.string.isRequired,
};

LivestreamDisabledWrapper.defaultProps = {
  onAddLivestreamComponent: () => {},
};

export default LivestreamDisabledWrapper;
