import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import DisabledWrapper from '../../../engage/components/disabledWrapper';
import CTAButton from '../../../../app/components/buttons/cta/CTA';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { STAFF_ADMIN } from '../../../../config';

const EventCalendarDisabledWrapper = ({
  role,
  history,
  onAddEventCalendarComponent,
  children,
  appname,
}) => {
  const { t } = useTranslation();
  const [btnLabel, setBtnLabel] = useState(t('Do it for me!'));
  const [btnDisabled, setBtnDisabled] = useState(false);

  const infoIcon = (
    <Tooltip
      title={t(
        "This will add a new page 'Event' and add EventCalendar component to that page in your app",
      )}
    >
      <InfoOutlinedIcon fontSize="small" />
    </Tooltip>
  );

  const instructions = (
    <span>
      <span
        role="button"
        tabIndex={0}
        onClick={() => {
          const memberRoute = /members(.*)/;
          history.push(
            history.location.pathname.replace(
              memberRoute,
              `editor/build/list?page=homepage`,
            ),
          );
        }}
        style={{ borderBottom: '1px solid black', cursor: 'pointer' }}
      >
        <Trans>Go to pages</Trans>
      </span>
      &ndash;&gt; <Trans>Select a page</Trans> &ndash;&gt;{' '}
      <Trans>Click the</Trans> &quot;<Trans>Add Component</Trans>&quot;{' '}
      <Trans>button</Trans> (<span style={{ fontWeight: 600 }}>&#43;</span>{' '}
      <Trans>icon</Trans>) &ndash;&gt;{' '}
      <Trans>Select EventCalendar component</Trans>
      &ndash;&gt; <Trans>Click button</Trans> &quot;
      <Trans>Add EventCalendar Component</Trans>&quot;
    </span>
  );

  const contactMessage = (
    <span style={{ fontWeight: 'bold' }}>
      <Trans>Contact your administrator to activate this feature</Trans>
    </span>
  );
  return (
    <DisabledWrapper
      title={t('EventCalendar Component has not been added to your app yet!')}
      message={
        <span>
          <span>
            <Trans>
              To use this feature add EventCalendar Component to your app
            </Trans>
          </span>
          <br />
          {role !== STAFF_ADMIN ? instructions : contactMessage}
        </span>
      }
      content={
        role !== STAFF_ADMIN ? (
          <div>
            <br />
            <CTAButton
              disabled={btnDisabled}
              label={btnLabel}
              height={35}
              minWidth={100}
              icon={infoIcon}
              onClick={() => {
                onAddEventCalendarComponent();
                setBtnDisabled(true);
                setBtnLabel('Adding...');
              }}
            />
          </div>
        ) : null
      }
    >
      {children}
    </DisabledWrapper>
  );
};

EventCalendarDisabledWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  history: PropTypes.object.isRequired,
  onAddEventCalendarComponent: PropTypes.func.isRequired,
  appname: PropTypes.string.isRequired,
};

EventCalendarDisabledWrapper.defaultProps = {
  content: null,
  role: '',
  onAddEventCalendarComponent: () => {},
};

export default EventCalendarDisabledWrapper;
