import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import { IconButton } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

import './styles.scss';
import InfoIcon from '../../components/tooltipElement/Info';

class TableHead extends Component {
  static propTypes = {
    cols: PropTypes.array,
    onColClick: PropTypes.func,
    order: PropTypes.string,
    colSorted: PropTypes.string,
    colsToSort: PropTypes.array,
  };

  static defaultProps = {
    cols: [],
    onColClick: null,
    order: 'desc',
    colSorted: null,
    colsToSort: [],
  };

  state = {
    enableInfoDialog: false,
  };

  shouldComponentUpdate(nextProps) {
    return !_isEqual(this.props.cols, nextProps.cols);
  }

  mapCol = (col, i) => (
    <div
      key={col.id}
      id={col.id}
      className={`th ${col.classNames} ${i} ${
        this.props.onColClick ? 'clickable' : ''
      }`}
    >
      {col.header}
      {this.props.onColClick !== null &&
        this.props.colsToSort.indexOf(col.id) !== -1 && (
          <IconButton
            onClick={() => this.props.onColClick(col)}
            style={{ padding: 0 }}
          >
            {this.props.order === 'asc' && this.props.colSorted === col.id ? (
              <ArrowDropUp style={{ fill: '#261ed5' }} />
            ) : (
              <ArrowDropDown style={{ fill: '#261ed5' }} />
            )}
          </IconButton>
        )}
      {col.tooltip && (
        <span
          style={{ marginLeft: '10px', marginTop: '10px' }}
          onClick={() => col.tooltipAction()}
        >
          <InfoIcon />
        </span>
      )}
    </div>
  );

  renderCols = () => this.props.cols.map(this.mapCol);
  toggleInfoDialog = () => this.setState({ enableInfoDialog: false });

  render() {
    const cols = this.renderCols();

    return (
      <div>
        <div className="row middle-xs table-header">{cols}</div>
      </div>
    );
  }
}

export default TableHead;
