import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import Toggle from 'material-ui/Toggle';

import TextField from '../../../../../../../app/components/textField';
import ErrorMessage from '../../../../../../../app/components/errorMessage';
import Container from '../../../../../../../app/components/container';
import { Tabs, Tab } from '../../../../../components/tabs';
import TooltipElement from '../../../../../../../app/components/tooltipElement/TooltipElement';
import style from './youtube.module.scss';

const styles = {
  toggle: {
    marginTop: 24,
    marginBottom: 12,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 6,
    width: '90%',
  },
  toggleLabel: {
    fontSize: 14,
  },
  tooltip: {
    right: 18,
    top: 7,
    position: 'relative',
  },
};

const YouTube = ({
  data,
  onChange,
  onChangeLoop,
  onChangeMuted,
  onChangeAutoPlay,
}) => {
  const { t } = useTranslation();

  const isYouTubeVideo = data.rawUrl && data.rawUrl.indexOf('www.youtube.com');
  const handleToggleLoop = prop => (e, isToggled) =>
    onChangeLoop(prop, isToggled);
  const handleToggleMuted = prop => (e, isToggled) =>
    onChangeMuted(prop, isToggled);
  const handleToggleAutoPlay = prop => (e, isToggled) =>
    onChangeAutoPlay(prop, isToggled);
  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container>
          <TextField
            id="youTube"
            placeholder={t('URL of the video and playlist')}
            hintText="https://www.youtube.com/watch?v=abcdefghij"
            value={data.rawUrl}
            onChange={onChange}
          />
          {isYouTubeVideo === -1 && (
            <ErrorMessage>
              <Trans>Please enter a valid url</Trans>
            </ErrorMessage>
          )}
        </Container>

        <Container style={{ display: 'flex', flexDirection: 'row' }}>
          <Toggle
            label={t('Loop')}
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.loop}
            onToggle={handleToggleLoop(data.loop)}
            // trackSwitchedStyle={TrackSwitchedColor}
            // thumbSwitchedStyle={ThumbSwitchedColor}
          />
          <TooltipElement
            className={style.tooltip}
            tooltip={t('Allows the video to play in a repeat mode.')}
            verticalPosition="top"
            horizontalPosition="left"
          />
        </Container>

        <Container style={{ display: 'flex', flexDirection: 'row' }}>
          <Toggle
            label={t('Muted')}
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.muted}
            onToggle={handleToggleMuted(data.muted)}
            // trackSwitchedStyle={TrackSwitchedColor}
            // thumbSwitchedStyle={ThumbSwitchedColor}
          />

          <TooltipElement
            className={style.tooltip}
            tooltip={t('Audio output of the video when played will be muted.')}
            verticalPosition="top"
            horizontalPosition="left"
          />
        </Container>

        <Container style={{ display: 'flex', flexDirection: 'row' }}>
          <Toggle
            label={t('Autoplay')}
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.autoPlay}
            onToggle={handleToggleAutoPlay(data.autoPlay)}
            // trackSwitchedStyle={TrackSwitchedColor}
            // thumbSwitchedStyle={ThumbSwitchedColor}
          />

          <TooltipElement
            className={style.tooltip}
            tooltip={t(
              'Video starts automatically when a user visits your app.',
            )}
            verticalPosition="top"
            horizontalPosition="left"
          />
        </Container>
      </Tab>
      <Tab
        label={t('design')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
      <Tab
        label={t('layout')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
    </Tabs>
  );
};

YouTube.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeLoop: PropTypes.func.isRequired,
  onChangeMuted: PropTypes.func.isRequired,
  onChangeAutoPlay: PropTypes.func.isRequired,
};

export default YouTube;
