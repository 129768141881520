import {
  CLOSE_EDIT_DIALOG_CPD,
  EDIT_FIELD_CHANGE_CPD,
  LOADING_CPD,
  LOADED_DETAILS_CPD,
  UPDATE_REQUEST_CPD,
  UPDATED_CPD,
  RESET_CPD,
} from './actions';

const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  fieldsData: {},
};

const editCPDReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_EDIT_DIALOG_CPD:
      return {
        ...state,
        open: false,
      };

    case EDIT_FIELD_CHANGE_CPD:
      return {
        ...state,
        fieldsData: {
          ...state.fieldsData,
          [action.id]: {
            value: action.value,
            isValid: action.isValid,
            type: action.prop,
          },
        },
      };

    case LOADING_CPD:
      return {
        ...state,
        pending: true,
      };

    case LOADED_DETAILS_CPD:
      return {
        ...state,
        pending: false,
        fieldsData: action.prop,
      };

    case UPDATE_REQUEST_CPD:
      return {
        ...state,
        pending: true,
      };

    case UPDATED_CPD:
      return {
        ...initialState,
        key: action.key,
      };

    case RESET_CPD:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default editCPDReducer;
