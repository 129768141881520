import { TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'white',
    },
  },
}))(TableRow);

export default StyledTableRow;
