import React from 'react';
import { Link } from 'react-router-dom';
import style from './marketplace.module.scss';
import { Trans } from 'react-i18next';
const Marketplace = ({ appname }) => {
  return (
    <div className={style.container}>
      <p>
        <Trans>
          This component allows users to sell and buy products & services on the
          app. On this page, you will see the list of products & services added
          by the app users whom you approved as Sellers in your app.
        </Trans>
      </p>
      <p>
        <Trans>To configure this component go to</Trans>{' '}
        <Link to={`/${appname}/ecommerce/marketplace/settings`}>
          <Trans>Business Tools</Trans> - <Trans>Marketplace</Trans> -{' '}
          <Trans>Settings</Trans>
        </Link>
        .
      </p>
    </div>
  );
};

export default Marketplace;
