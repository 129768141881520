import firebase from '../../firebase';

export const FETCHING_DEVICES_DATA = 'FETCHING_DEVICES_DATA';
export const FETCHED_DEVICES_DATA = 'FETCHED_DEVICES_DATA';
export const FETCHING_NOTIFICATIONS_DATA = 'FETCHING_NOTIFICATIONS_DATA';
export const FETCHED_NOTIFICATIONS_DATA = 'FETCHED_NOTIFICATIONS_DATA';
export const FETCHING_CSV_DATA = 'FETCHING_CSV_DATA';
export const FETCHED_CSV_DATA = 'FETCHED_CSV_DATA';
export const REPORT_FETCH_ONESIGNAL_DATA_ERROR =
  'REPORT_FETCH_ONESIGNAL_DATA_ERROR';
export const GA_TRACKING_ID_UPDATED = 'GA_TRACKING_ID_UPDATED';
export const GA_TRACKING_ID_UPDATE_FAILED = 'GA_TRACKING_ID_UPDATE_FAILED';
export const GA_TRACKING_ID_FETCHED = 'GA_TRACKING_ID_FETCHED';
export const GA_TRACKING_ID_FETCH_FAILED = 'GA_TRACKING_ID_FETCH_FAILED';

const reportError = err => ({
  type: REPORT_FETCH_ONESIGNAL_DATA_ERROR,
  err: err.message,
});

export const addOffersAnalytics = async (eventToTrack, offer, account) => {
  try {
    const timestamp = Date.now();
    return await firebase
      .database()
      .ref(`offers_analytics/${offer}`)
      .child(account)
      .update({
        [eventToTrack]: timestamp,
        account,
      });
  } catch (err) {
    return console.error(err);
  }
};

//visited
//offersOpened
//subscribeOpened
//subscribed

// account: {
//   event1: {
//     timestamp
//   },
//   event1: {
//     timestamp
//   },
//   event1: {
//     timestamp
//   },
// }

export const fetchDevicesData = appname => dispatch => {
  dispatch({
    type: FETCHING_DEVICES_DATA,
  });
  fetch(`${process.env.REACT_APP_PUSH_API}/devices/${appname}`)
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: FETCHED_DEVICES_DATA,
        body,
      }),
    )
    .catch(error => dispatch(reportError(error)));
};

export const fetchNotificationsData = appname => dispatch => {
  dispatch({
    type: FETCHING_NOTIFICATIONS_DATA,
  });
  fetch(`${process.env.REACT_APP_PUSH_API}/notifications/${appname}`)
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: FETCHED_NOTIFICATIONS_DATA,
        body,
      }),
    );
};

export const fetchCsvData = appname => dispatch => {
  dispatch({
    type: FETCHING_CSV_DATA,
  });
  const payload = {
    appname,
  };
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_PUSH_API}/export_devices`, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify(payload),
        mode: 'cors',
      });
    })
    .then(res => {
      if (res.status === 200) {
        res.json().then(data =>
          dispatch({
            type: FETCHED_CSV_DATA,
            body: data,
          }),
        );
      } else {
        res.json().then(body => {
          dispatch(reportError(body));
        });
      }
    });
};

export const updateGaTrackerId = (appname, value) => async dispatch => {
  try {
    await firebase
      .database()
      .ref(`/apps/${appname}`)
      .update({ gaTrackingId: value });
    dispatch({
      type: GA_TRACKING_ID_UPDATED,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: GA_TRACKING_ID_UPDATE_FAILED,
    });
  }
};
