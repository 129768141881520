import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SmsEngage from './Sms';

import {
  loadSMSRequest,
  searchSmsReuqest,
  selectSMS,
  setTabletype,
  selectAllSMS,
  deselectAllSMS,
  closeDialog,
  openDialog,
  messageChange,
  sendSMS,
  handleSmsPopup,
  smsPopupRequired,
  deleteSelected,
  loadGroups,
  getGroups,
  selectAllGroups,
  selectGroup,
  deselectAllGroups,
  searchGroupSmsReuqest,
} from './actions';

import { getUsers } from '../../../memberLogin/modules/users/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.engage.sms.data,
  open: state.engage.sms.data.open,
  sms_restricted: state.account.organisation.sms_restricted || false,
  isPendingSMS: state.engage.sms.data.isPendingSMS,
  smsPopup: state.engage.sms.data.smsPopup,
  message: state.engage.sms.data.message,
  tableType: state.engage.sms.data.tableType,
  subscription: state.app.appContext.subscription,
  smsCredit: state.account.organisation.sms_credit,
  organisation: state.account.organisation,
  integration: state.account.organisation.integration,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
  users: state.auth.users.filteredChunks,
  accountId: state.account.profile.account,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  openDialog: () => dispatch(openDialog()),
  closeDialog: () => dispatch(closeDialog()),
  setTabletype: type => dispatch(setTabletype(type)),
  loadSMSRequest: type =>
    dispatch(loadSMSRequest(type, ownProps.match.params.appname)),
  onSearch: query => dispatch(searchSmsReuqest(query)),
  onSearchGroup: query => dispatch(searchGroupSmsReuqest(query)),
  onSelect: key => dispatch(selectSMS(key)),
  onSelectAllSMS: () => dispatch(selectAllSMS()),
  onDeselectAllSMS: () => dispatch(deselectAllSMS()),
  onMessageChange: value => dispatch(messageChange(value)),
  onSendSMS: () => dispatch(sendSMS(ownProps.match.params.appname)),
  handleSmsPopup: value =>
    dispatch(handleSmsPopup(value, ownProps.match.params.appname)),
  smsPopupRequired: () =>
    dispatch(smsPopupRequired(ownProps.match.params.appname)),
  onDeleteSelected: (type, selected) =>
    dispatch(deleteSelected(ownProps.match.params.appname, type, selected)),
  loadGroups: () => dispatch(loadGroups(ownProps.match.params.appname)),
  onGroupSelect: key => dispatch(selectGroup(key)),
  onSelectAllGroups: key => dispatch(selectAllGroups(key)),
  onDeselectAllGroups: key => dispatch(deselectAllGroups(key)),
  getGroups: () => dispatch(getGroups(ownProps.match.params.appname)),
  getUsers: () => dispatch(getUsers(ownProps.match.params.appname)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SmsEngage),
);
