import firebase from '../../../../../../../firebase';
// eslint-disable-next-line
export const stampsChange = (appname, dataId, field, numberOfVisits) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('numberOfVisits').set(numberOfVisits);

  return {
    type: 'STAMPS_VALUE_CHANGE',
    appname,
    dataId,
    field,
    numberOfVisits,
  };
};

export const designChange = (appname, dataId, field, value) => {
  const dataRef =
    field === 'layout'
      ? firebase.database().ref(`apps/${appname}/draft/always_data/${dataId}`)
      : firebase
          .database()
          .ref(`apps/${appname}/draft/always_data/${dataId}/design`);

  dataRef.child(field).set(value);

  return {
    type: 'BOX_FIELD_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

export const fieldChange = (appname, dataId, field, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child(field).set(value);

  return {
    type: 'HEADER_FIELD_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

export const freeItemChange = (appname, dataId, field, freeItem) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('freeItem').set(freeItem);

  return {
    type: 'FREE_ITEM_VALUE_CHANGE',
    appname,
    dataId,
    field,
    freeItem,
  };
};

export const getIconUrls = () => async () => {
  const iconUrls = [];

  const iconsRef = firebase.database().ref('loyalty_icons/iconsArray');
  const iconsSnapshot = await iconsRef.once('value');
  const iconsData = await iconsSnapshot.val();
  iconsData.forEach(icon => {
    firebase
      .storage()
      .ref(`loyalty-icons/${icon}`)
      .getDownloadURL()
      .then(url => {
        iconUrls.push(url);
      });
  });

  return {
    type: 'GET_ICON_URLS',
    iconUrls,
  };
};
