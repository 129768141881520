import firebase from '../../../../../../firebase';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import { PROFILE_DELETED } from './config';
import i18n from 'i18next';

export const REQUEST_ADMINS = 'REQUEST_ADMINS';
export const RECEIVE_ADMINS = 'RECEIVE_ADMINS';
export const REPORT_ADMINS_REQUEST_ERROR = 'REPORT_ADMINS_REQUEST_ERROR';
export const SELECT_ADMIN = 'SELECT_ADMIN';
export const FILTER_ADMIN_DATA = 'FILTER_ADMIN_DATA';
export const SELECT_ALL_ADMINS = 'SELECT_ALL_ADMINS';
export const DESELECT_ALL_ADMINS = 'DESELECT_ALL_ADMINS';
export const DELETE_VALIDATION_ERROR = 'DELETE_VALIDATION_ERROR';
export const ADMIN_DELETE_ERROR = 'ADMIN_DELETE_ERROR';
export const SEARCH_ADMINS = 'SEARCH_ADMINS';
export const ADMIN_DELETED = 'ADMIN_DELETED';
export const REQUEST_ADMINS_DELETE = 'REQUEST_ADMINS_DELETE';

export const openNewAdminDialog = () => dispatch => {
  dispatch({ type: 'OPEN_NEW_ADMIN_DIALOG' });
};

const requestAdmins = () => ({
  type: REQUEST_ADMINS,
});

const requestAdminsDelete = () => ({
  type: REQUEST_ADMINS_DELETE,
});

export const selectAdmin = key => ({
  type: SELECT_ADMIN,
  key,
});

export const validationError = prop => ({
  type: DELETE_VALIDATION_ERROR,
  prop,
});

export const filterData = (filteredChunks, sortedItems) => dispatch =>
  dispatch({
    type: FILTER_ADMIN_DATA,
    payload: {
      filteredChunks,
      sortedItems,
    },
  });
export const getAppAdmins = () => dispatch => {
  dispatch(requestAdmins());
  const parent = firebase.auth().currentUser.uid;
  const userRef = firebase.database().ref('users');
  userRef.child(parent).on('value', snap => {
    const admins = snap.val().app_admins
      ? Object.keys(snap.val().app_admins)
      : [];
    const result = {};
    Promise.all(
      admins.map(async userID => {
        // Getting firstName, lastName, email of userID
        const {
          firstName,
          lastName,
          email,
          createdAt,
          adminAppname,
          adminSMSCredits,
          adminWhatsappSMSCredits,
          test,
          account,
          sectionsAccess,
        } = await userRef
          .child(userID)
          .once('value')
          .then(async snapshot => {
            const adminAccount =
              (snapshot.val() && snapshot.val().account) || null;
            let adminSMSCredits = 0;
            let adminWhatsappSMSCredits = 0;
            let sectionsAccess = {
              build: true,
              distribute: true,
              engage: true,
              analytics: true,
              member_login: true,
              business_tools: true,
            };
            const account = (snapshot.val() && snapshot.val().account) || '';
            if (adminAccount) {
              try {
                const smsCredSnap = await firebase
                  .database()
                  .ref(`accounts/${adminAccount}/sms_credit`)
                  .once('value');

                const whatsappCredSnap = await firebase
                  .database()
                  .ref(`accounts/${adminAccount}/whatsapp_sms_credit`)
                  .once('value');
                if (smsCredSnap.exists() && smsCredSnap.val()) {
                  adminSMSCredits = smsCredSnap.val();
                }
                if (whatsappCredSnap.exists() && whatsappCredSnap.val()) {
                  adminWhatsappSMSCredits = whatsappCredSnap.val();
                }
              } catch (err) {
                console.error(err);
              }
            }

            if (snapshot.val() && snapshot.val().sectionsAccess) {
              sectionsAccess = {
                ...sectionsAccess,
                ...snapshot.val().sectionsAccess,
              };
            }

            return {
              firstName: (snapshot.val() && snapshot.val().firstName) || '',
              lastName: (snapshot.val() && snapshot.val().lastName) || '',
              email: (snapshot.val() && snapshot.val().email) || '',
              createdAt: (snapshot.val() && snapshot.val().createdAt) || '',
              adminAppname:
                (snapshot.val() && snapshot.val().admin_appname) ||
                (snapshot.val() &&
                  snapshot.val().admin_appnames &&
                  snapshot.val().admin_appnames.join(',')) ||
                '',
              adminSMSCredits,
              adminWhatsappSMSCredits,
              account,
              sectionsAccess,
            };
          });

        if (adminAppname) {
          // Done Getting details of userID
          result[userID] = {
            firstName,
            lastName,
            email,
            createdAt,
            adminAppname,
            adminSMSCredits,
            adminWhatsappSMSCredits,
            account,
            sectionsAccess: sectionsAccess,
          };
        }
      }),
    ).then(() => {
      dispatch({
        type: RECEIVE_ADMINS,
        value: result,
      });
    });
  });
};

export const filterAdminData = filter => dispatch =>
  dispatch({
    type: FILTER_ADMIN_DATA,
    payload: filter,
  });

export const selectAllAdmins = () => ({ type: SELECT_ALL_ADMINS });
export const deselectAllAdmins = () => ({ type: DESELECT_ALL_ADMINS });

export const reportActionError = (
  error,
  appname,
  selected,
  action,
) => dispatch =>
  dispatch({ type: ADMIN_DELETE_ERROR, error, appname, selected, action });

export const deleteUser = (appname, userID) =>
  new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;

    return user.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_ADMIN_API}`, {
        method: 'delete',
        mode: 'cors',
        body: JSON.stringify({
          appname,
          userID,
          user: user.uid,
          requestOrigin: 'platform',
        }),
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            return resolve(res);
          }

          throw new Error(
            i18n.t('Opps, something went wrong. Please try again.'),
          );
        })
        .catch(reject);
    });
  });

export const deleteAdmins = appname => (dispatch, getState) => {
  dispatch(requestAdminsDelete());
  const { selected } = getState().auth.appAdmins.data;
  selected.map(key =>
    deleteUser(appname, key)
      .then(data => {
        dispatch({
          type: ADMIN_DELETED,
          appname,
          data,
        });
        dispatch(openSnackbar(i18n.t('The App admin has been deleted.')));
      })
      .catch(error => {
        dispatch(reportActionError(error));
      }),
  );
};
