import firebase from '../../../firebase';

export const closeAppSumoPublishNotice = (
  appname,
  accountId,
  publish,
) => dispatch => {
  if (publish) dispatch({ type: 'LAUNCH_PUBLISH_DIALOG', appname });
  dispatch({ type: 'TOGGLE_PUBLISH_MSG', data: false });
  const accountRef = firebase.database().ref(`accounts/${accountId}`);
  accountRef.update({ appsumo_publish: true });
};

export const checkAppSumoPublishNoticeOccurence = accountId => dispatch => {
  firebase
    .database()
    .ref(`accounts/${accountId}/appsumo_publish`)
    .once('value', snapshot => {
      if (!snapshot.exists()) {
        dispatch({ type: 'TOGGLE_PUBLISH_MSG', data: true });
      }
    });
};
