import React from 'react';

import './styles.css';

const Facebook = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30.14 64.86"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        style={{ fill: '#fff' }}
        d="M29,32.45H19.88V64.86H6.41V32.45H0V21H6.41V13.59C6.41,8.3,8.92,0,20,0L30,0V11.15H22.74c-1.19,0-2.86.59-2.86,3.12V21H30.14Zm0,0"
      />
    </g>
  </svg>
);

export default Facebook;
