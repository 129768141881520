import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SimpleToolbar from './SimpleToolbar';
import { logout } from '../../../../modules/account/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isAuthenticated: state.account.auth.isAuthenticated,
  lastVisited: state.account.profile.lastVisited,
  integrationType: state.account.organisation.integration
    ? state.account.organisation.integration.type
    : '',
  appsumoUser: state.account.organisation.appsumo_id,
  isAppAdmin: state.account.auth.profile.admin_appname || false,
});

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(logout()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SimpleToolbar),
);
