import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';

import DraggableListWrapper from '../../../../../components/draggableListWrapper';
import ComponentItem from '../../../../../components/componentItem';
import CreateField from '../../../../../components/createField';
import Container from '../../../../../../../app/components/container';
import { Tabs, Tab } from '../../../../../components/tabs';
import TextArea from '../../../../../../../app/components/textArea';

import './styles.css';

class EventList extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    filters: PropTypes.array,
    onUpdateData: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onAddItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
  };

  static defaultProps = {
    filters: [],
  };

  state = {};

  onSubmitNewItem = item => {
    this.props.onAddItem(item).then(key => {
      this.props.history.push(this.getItemHref(key));
    });
  };

  getItemHref = key => {
    const { match, location } = this.props;

    return {
      pathname: `/${match.params.appname}/editor/build/item`,
      search: `${location.search}&item=${key}`,
    };
  };

  handleRemoveOption(key) {
    this.props.onRemoveItem(key);
  }

  handleNavigate = key => () => {
    this.props.history.push(this.getItemHref(key));
  };

  renderOptions = filters =>
    Object.keys(filters).map(key => (
      <ComponentItem
        label={filters[key].title}
        onClick={this.handleNavigate(key)}
        onDelete={() => this.handleRemoveOption(key)}
      />
    ));

  render() {
    const { data, onUpdateData, t } = this.props;
    return (
      <div className="events-list-container">
        <Tabs>
          <Tab label={t('content')}>
            <Container>
              {this.props.data && this.props.data.filters && (
                <DraggableListWrapper
                  items={this.renderOptions(this.props.data.filters)}
                />
              )}
              <CreateField
                id="add-filter"
                hintText={t('Add Filter')}
                tooltip={t('Add Filter')}
                onSubmit={this.onSubmitNewItem}
              />
              <TextArea
                id="data"
                value={data && data.eventList ? data.eventList : ''}
                onChange={val => onUpdateData('eventList', val)}
                label={t('Event List Data')}
              />
              <p>
                <a
                  href="https://jsonlint.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>Please ensure json data is valid</Trans>
                </a>
              </p>
            </Container>
          </Tab>
          <Tab label={t('design')} disabled />
          <Tab label={t('layout')} disabled />
        </Tabs>
      </div>
      // ) : (
      // <Container>
      // <CircularProgress size={24} thickness={3} />
      // </Container>
    );
  }
}

export default withTranslation()(EventList);
