import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextField from '../../../../../../../app/components/textField';
import Container from '../../../../../../../app/components/container';
import { Tabs, Tab } from '../../../../../components/tabs';

const VimeoVideo = ({ data, onChange }) => {
  const { t } = useTranslation();

  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container>
          <TextField
            id="vimeoVideoId"
            placeholder={t('URL of the video')}
            hintText="https://vimeo.com/326416222"
            value={data.rawUrl}
            onChange={onChange}
          />
        </Container>
      </Tab>
      <Tab
        label={t('design')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
      <Tab
        label={t('layout')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
    </Tabs>
  );
};

VimeoVideo.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default VimeoVideo;
