import React from 'react';
import PropTypes from 'prop-types';
import InfoOutline from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import Sidebar, { SidebarItem } from '../../../../app/components/sidebar';
import Toggle from '../../../../app/components/toggle';
import themeStyles from '../../../../_export.scss';
import { Trans, withTranslation } from 'react-i18next';

import {
  Inventory as InventoryIcon,
  Orders as OrdersIcon,
  Drivers as DriversIcon,
  Settings as SettingsIcon,
  Buyers as BuyersIcon,
  Vendors as VendorsIcon,
  MarketplaceFilters as MarketplaceFiltersIcon,
  MarketplaceInfo as MarketplaceInfoIcon,
  Appointment as AppointmentIcon,
  Booking as BookingIcon,
  Boxes as BoxesIcon,
  List as ListIcon,
} from '../../components/icons';
import ViewApp from '../../../../app/components/buttons/viewApp';

class EcommerceMenu extends React.Component {
  activeCheck = prefix =>
    this.props.location.pathname ===
    `/${this.props.match.params.appname}/ecommerce/${prefix}`;

  componentDidMount() {
    this.props.getMarketplaceStatus();
    this.props.getBookingStatus();
    this.props.getFoodOrderStatus();
    this.props.onAddIsPublishedListener();
  }

  componentWillUnmount() {
    this.props.stopGetFoodOrderStatus();
    this.props.stopGetMarketplaceStatus();
    this.props.onRemoveIsPublishedListener();
    this.props.stopGetBookingStatus();
  }

  openLink = link => {
    window.open(link, '_blank');
  };

  infoIcon = pdf => (
    <Tooltip
      title={this.props.t('How to use')}
      onClick={() => this.openLink(pdf)}
      style={{ color: themeStyles.primaryColor }}
    >
      <InfoOutline fontSize="small" />
    </Tooltip>
  );

  render() {
    const {
      match,
      isPublished,
      marketplaceEnabled,
      foodOrderEnabled,
      toggleMarketplace,
      toggleFoodOrder,
      bookingEnabled,
      toggleBooking,
      isAppAdmin,
      isSubAdminWithMulipleApps,
      t,
    } = this.props;

    const baseHref = `/${match.params.appname}/ecommerce`;
    return (
      <Sidebar animate>
        <ViewApp isPublished={isPublished} match={match} />
        <div
          className={`ml-switch-container ${foodOrderEnabled ? 'enabled' : ''}`}
        >
          <span className="ml-switch-label">
            <Trans>Enable Food Orders</Trans>
          </span>
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => {
              toggleFoodOrder(b);
              if (b)
                this.props.history.push(
                  `/${this.props.match.params.appname}/ecommerce/features`,
                );
            }}
            toggled={foodOrderEnabled}
          />
        </div>
        {foodOrderEnabled && (
          <>
            <SidebarItem
              label={t('How it works')}
              href={`${baseHref}/features`}
              active={this.activeCheck('features')}
              icon={<ListIcon />}
            />
            <SidebarItem
              label={t('Inventory')}
              href={`${baseHref}/inventory`}
              active={this.activeCheck('inventory')}
              icon={<InventoryIcon />}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                rightAction: this.infoIcon(
                  'https://kb-articles.s3.eu-west-2.amazonaws.com/Business%2BTools_%2BHow%2Bto%2Badd%2BInventory%2B-%2BFood%2BOrders.pdf',
                ),
              })}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                displayRightAction: true,
              })}
            />
            <SidebarItem
              label={t('Orders')}
              href={`${baseHref}/orders`}
              active={this.activeCheck('orders')}
              icon={<OrdersIcon />}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                rightAction: this.infoIcon(
                  'https://kb-articles.s3.eu-west-2.amazonaws.com/Business%2BTools_%2BOrders%2BSection%2B-%2BFood%2BOrders.pdf',
                ),
              })}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                displayRightAction: true,
              })}
            />
            <SidebarItem
              label={t('Drivers')}
              href={`${baseHref}/drivers`}
              active={this.activeCheck('drivers')}
              icon={<DriversIcon />}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                rightAction: this.infoIcon(
                  'https://kb-articles.s3.eu-west-2.amazonaws.com/Business%2BTools_%2BDrivers%2BSection%2B-%2BFood%2BOrders.pdf',
                ),
              })}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                displayRightAction: true,
              })}
            />
            <SidebarItem
              label={t('Settings')}
              href={`${baseHref}/settings`}
              active={this.activeCheck('settings')}
              icon={<SettingsIcon />}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                rightAction: this.infoIcon(
                  'https://kb-articles.s3.eu-west-2.amazonaws.com/Business%2BTools%2B-%2BFood%2BOrders.pdf',
                ),
              })}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                displayRightAction: true,
              })}
            />
          </>
        )}

        <div
          className={`ml-switch-container ${bookingEnabled ? 'enabled' : ''}`}
        >
          <span className="ml-switch-label">
            <Trans>Enable Booking</Trans>
          </span>
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => {
              toggleBooking(b);
              if (b)
                this.props.history.push(
                  `/${this.props.match.params.appname}/ecommerce/bookings/features`,
                );
            }}
            toggled={bookingEnabled}
          />
        </div>

        {bookingEnabled && (
          <>
            <SidebarItem
              label={t('How it works')}
              href={`${baseHref}/bookings/features`}
              active={this.activeCheck('bookings/features')}
              icon={<ListIcon />}
            />

            <SidebarItem
              label={t('Staff Members')}
              href={`${baseHref}/bookings/staff`}
              active={this.activeCheck('bookings/staff')}
              icon={<DriversIcon />}
              enabled={bookingEnabled}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                rightAction: this.infoIcon(
                  'https://kb-articles.s3.eu-west-2.amazonaws.com/Booking+-+Staff+Members.pdf',
                ),
              })}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                displayRightAction: true,
              })}
            />
            <SidebarItem
              label={t('Appointments')}
              href={`${baseHref}/appointments`}
              active={this.activeCheck('appointments')}
              icon={<AppointmentIcon />}
              enabled={bookingEnabled}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                rightAction: this.infoIcon(
                  'https://kb-articles.s3.eu-west-2.amazonaws.com/Booking+-1Appointments.pdf',
                ),
              })}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                displayRightAction: true,
              })}
            />
            <SidebarItem
              label={t('Bookings')}
              href={`${baseHref}/bookings/list`}
              active={this.activeCheck('bookings/list')}
              icon={<BookingIcon />}
              enabled={bookingEnabled}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                rightAction: this.infoIcon(
                  'https://kb-articles.s3.eu-west-2.amazonaws.com/Booking+-+Accept+Bookings.pdf',
                ),
              })}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                displayRightAction: true,
              })}
            />

            <SidebarItem
              label={t('Settings')}
              href={`${baseHref}/bookings/settings`}
              active={this.activeCheck('bookings/settings')}
              icon={<SettingsIcon />}
              enabled={bookingEnabled}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                rightAction: this.infoIcon(
                  'https://kb-articles.s3.eu-west-2.amazonaws.com/Booking+-+Settings.pdf',
                ),
              })}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                displayRightAction: true,
              })}
            />
          </>
        )}

        <div
          className={`ml-switch-container ${
            marketplaceEnabled ? 'enabled' : ''
          }`}
        >
          <span className="ml-switch-label">
            <Trans>Enable Marketplace</Trans>
          </span>
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => {
              toggleMarketplace(b);
              if (b)
                this.props.history.push(
                  `/${this.props.match.params.appname}/ecommerce/marketplace/features`,
                );
            }}
            toggled={marketplaceEnabled}
          />
        </div>
        {marketplaceEnabled && (
          <>
            <SidebarItem
              label={t('How it works')}
              href={`${baseHref}/marketplace/features`}
              active={this.activeCheck('marketplace/features')}
              icon={<MarketplaceInfoIcon />}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                rightAction: this.infoIcon(
                  'https://kb-articles.s3.eu-west-2.amazonaws.com/Marketplace+-+App+admins.pdf',
                ),
              })}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                displayRightAction: true,
              })}
            />

            <SidebarItem
              label={t('Buyers')}
              href={`${baseHref}/marketplace/buyers`}
              active={this.activeCheck('marketplace/buyers')}
              icon={<BuyersIcon />}
            />

            <SidebarItem
              label={t('Sellers')}
              href={`${baseHref}/marketplace/sellers`}
              active={this.activeCheck('marketplace/sellers')}
              icon={<VendorsIcon />}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                rightAction: this.infoIcon(
                  'https://kb-articles.s3.eu-west-2.amazonaws.com/Marketplace+Admin+-+Seller+Payouts.pdf',
                ),
              })}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                displayRightAction: true,
              })}
            />
            <SidebarItem
              label={t('Products & Services')}
              href={`${baseHref}/marketplace/products`}
              active={this.activeCheck('marketplace/products')}
              icon={<BoxesIcon />}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                rightAction: this.infoIcon(
                  'https://kb-articles.s3.eu-west-2.amazonaws.com/Marketplace+-+Products+and+Services.pdf',
                ),
              })}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                displayRightAction: true,
              })}
            />
            <SidebarItem
              label={t('Categories')}
              href={`${baseHref}/marketplace/categories`}
              active={this.activeCheck('marketplace/categories')}
              icon={<MarketplaceFiltersIcon />}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                rightAction: this.infoIcon(
                  'https://kb-articles.s3.eu-west-2.amazonaws.com/Marketplace+-+Product+Categories.pdf',
                ),
              })}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                displayRightAction: true,
              })}
            />

            <SidebarItem
              label={t('Settings')}
              href={`${baseHref}/marketplace/settings`}
              active={this.activeCheck('marketplace/settings')}
              icon={<SettingsIcon />}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                rightAction: this.infoIcon(
                  'https://kb-articles.s3.eu-west-2.amazonaws.com/Settings+for+Marketplace.pdf',
                ),
              })}
              {...((isAppAdmin || isSubAdminWithMulipleApps) && {
                displayRightAction: true,
              })}
            />
          </>
        )}
      </Sidebar>
    );
  }
}

EcommerceMenu.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  customDomain: PropTypes.object,
  integration: PropTypes.object,
  isPublished: PropTypes.bool,
  marketplaceEnabled: PropTypes.bool.isRequired,
  toggleMarketplace: PropTypes.func.isRequired,
  stopGetMarketplaceStatus: PropTypes.func.isRequired,
  getMarketplaceStatus: PropTypes.func.isRequired,
};

EcommerceMenu.defaultProps = {
  integration: null,
  customDomain: {},
  marketplaceEnabled: false,
};

export default withTranslation()(EcommerceMenu);
