import React, { useState } from 'react';
import './styles.css';
import EnhancedButton from 'material-ui/internal/EnhancedButton';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import VisibilityOff from 'material-ui/svg-icons/action/visibility-off';
import Pip from '../../../../../../components/pip';
import { useDispatch } from 'react-redux';
import { launchRemoveDialog } from '../../../remove/actions';
import { useTranslation } from 'react-i18next';
import IconButton from 'material-ui/IconButton';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Delete } from '../../../../../../components/icons';
import { launchDuplicatePageDialog } from '../../../create/duplicatePage/actions';
import DraggableListWrapper from '../../../../../../components/draggableListWrapper';
import { reOrderNestedPages } from '../../actions';
import TabHeader from '../tabItem/TabHeader';
import TabItem from '../tabItem/TabItem';
import { openCreateTabUI } from '../../../../../build/modules/create/createTab/actions';

const ModifiedPip = Pip.extend`
  margin: 0px;
`;
const styles = {
  iconButton: {
    display: 'block',
    height: 24,
    width: 24,
    padding: 3,
  },
};
const NestedPages = ({ nestedPages, history, match, location }) => {
  const { t } = useTranslation();
  const [layoutChanged, setLayoutChanged] = useState(false);
  const dispatch = useDispatch();
  const isActive = key => {
    const { page, tab = '' } = queryString.parse(history.location.search);
    return !tab ? key === page : false;
  };

  const hiddenIcon = hidden =>
    hidden && (
      <VisibilityOff
        color="rgb(167, 169, 172)"
        style={{ height: 18, width: 18, marginRight: 12 }}
      />
    );

  const pip = hasUnavailaleComponent =>
    hasUnavailaleComponent ? <ModifiedPip /> : null;

  const deletePage = (e, page) => {
    e.stopPropagation();
    dispatch(
      launchRemoveDialog(match.params.appname, { page: page.key }, 'PAGE'),
    );
  };

  const onReorderPages = (el, container, source, sibling) => {
    dispatch(reOrderNestedPages(match.params.appname, container));
  };
  const duplicatePage = (e, page) => {
    e.stopPropagation();
    dispatch(launchDuplicatePageDialog(page.key));
  };

  const sortPages = (page1, page2) => {
    if (page1.order === undefined) {
      return 1;
    }

    if (page2.order === undefined) {
      return -1;
    }

    return page1.order - page2.order;
  };

  const getTabHref = (key, parentPageKey, i) => ({
    pathname: `/${match.params.appname}/editor/build/list`,
    search: `page=${key}&parentPage=${parentPageKey}&tab=${i}`,
  });

  const isActiveTab = (key, i) =>
    key === queryString.parse(location.search).page &&
    i === Number(queryString.parse(location.search).tab);

  const renderTabs = (tabs, page) =>
    tabs &&
    tabs.map((tab, i) => (
      <TabItem
        // tabs are array based
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        label={tab.title}
        href={getTabHref(page.key, page.parentPageKey, i)}
        active={isActiveTab(page.key, i)}
        hasUnavailaleComponent={tab.hasUnavailaleComponent}
      />
    ));
  const nestedPageItems = () => {
    const sortedNestedPages = layoutChanged
      ? nestedPages
      : nestedPages.sort(sortPages);

    return sortedNestedPages.map(page => {
      const tabs = renderTabs(page.tabs, page);
      return (
        <div id={page.key} className="nested-page-item">
          <EnhancedButton
            containerElement={
              <div
                className={`nested-page ${isActive(page.key) ? 'active' : ''}`}
                tabIndex={0}
              />
            }
            onClick={e => history.push(page.href)}
          >
            <div className="page-title-container">
              <span className="button-group">
                <IconButton
                  className="hover-icon-button"
                  tooltip={t('Delete page')}
                  style={styles.iconButton}
                  onClick={e => deletePage(e, page)}
                >
                  <Delete className="small" />
                </IconButton>
                <IconButton
                  className="hover-icon-button"
                  tooltip={t('Duplicate a Page')}
                  style={styles.iconButton}
                  onClick={e => duplicatePage(e, page)}
                >
                  <FileCopyOutlinedIcon color="primary" fontSize="small" />
                </IconButton>
              </span>
              <div className="nested-page">
                <span>
                  {page.title}
                  {pip(page.hasUnavailaleComponent)}
                </span>
                {hiddenIcon(page.hidden)}
              </div>
            </div>
          </EnhancedButton>
          {page.tabs && (
            <>
              <div id={page.id}>
                <>
                  <div className="menu-tabs">
                    <TabHeader
                      tabs={[...page.tabs]}
                      onAddTab={() => {
                        dispatch(openCreateTabUI());
                      }}
                    />
                    {tabs}
                  </div>
                </>
              </div>
            </>
          )}
        </div>
      );
    });
  };
  return (
    <DraggableListWrapper
      items={nestedPageItems()}
      onPositionChange={(el, container, source, sibling) => {
        onReorderPages(el, container, source, sibling);
        setLayoutChanged(true);
        return true; //update the view
      }}
      className="nested-pages-draggable"
    />
  );
};
export default withRouter(NestedPages);
