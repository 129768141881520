import { connect } from 'react-redux';
import MultipleImageUpload from './MultipleImageUpload';

import {
  openDialog,
  closeDialog,
  receiveImage,
  selectImage,
  receiveCrop,
  reportError,
  clearError,
  submitImageCrop,
  resetImageUploads,
  openDeleteDialog,
  closeDeleteDialog,
  deleteImage,
  removeImageFromDraft,
  updateMainImage,
  openFileAndUrlDialog,
  fileAndUrlDialogCancel,
  fileUploadUrlChange,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.editor.internals.multipleImageUpload,
  appName: state.app.appContext.appname,
  sources: ownProps.data.gallery,
  mainImage: ownProps.data.mainImage,
  fileAndUrlDialogOpen:
    state.editor.internals.multipleImageUpload.fileAndUrlDialogOpen,
  fileUploadUrl: state.editor.internals.multipleImageUpload.fileUploadUrl,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  onOpenDialog: () => dispatch(openDialog()),
  onCloseDialog: () => dispatch(closeDialog()),
  onReceiveImage: file => dispatch(receiveImage(file)),
  selectImage: (index, imageSrc) => dispatch(selectImage(index, imageSrc)),
  onReceiveCrop: (index, crop) => dispatch(receiveCrop(index, crop)),
  onReportError: message => dispatch(reportError(message)),
  onClearError: () => dispatch(clearError()),
  onResetImageUploads: () => dispatch(resetImageUploads()),
  onOpenDeleteDialog: () => dispatch(openDeleteDialog()),
  onCloseDeleteDialog: () => dispatch(closeDeleteDialog()),
  onDeleteImage: (src, componentFirebaseUrl, firebaseUrl, deleteMainImage) =>
    dispatch(
      deleteImage(src, componentFirebaseUrl, firebaseUrl, deleteMainImage),
    ),
  onSubmit: (url, appName, setMainImage, fileType) =>
    dispatch(submitImageCrop(url, appName, setMainImage, fileType)),
  removeImageFromDraft: index => dispatch(removeImageFromDraft(index)),
  updateMainImage: (firebaseUrl, node) =>
    dispatch(updateMainImage(firebaseUrl, node)),
  onOpenFileAndUrlDialog: () => dispatch(openFileAndUrlDialog()),
  onFileAndUrlDialogCancel: () => dispatch(fileAndUrlDialogCancel()),
  onFileUploadUrlChange: value => dispatch(fileUploadUrlChange(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultipleImageUpload);
