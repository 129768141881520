/* eslint jsx-a11y/no-autofocus: 0 */

import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Save from 'material-ui/svg-icons/navigation/check';
import moment from 'moment';

const Span = styled.span`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  font-size: 13px;
  padding: 0;
  margin: 1rem;
  position: relative;

  &,
  &:active,
  &:focus {
    outline: none;
  }

  &.editable-text-form {
    align-items: stretch;
    height: calc(100% - 8px);
  }

  input {
    background: transparent;
    border: none;
    border: 1px solid black;
    display: block;

    font-size: inherit;
    line-height: 1.4em;
    margin: 0 2px;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.4);
    text-decoration-style: dotted;
    width: 50%;

    &,
    &:active,
    &:focus {
      outline: none;
    }
  }

  .controls {
    bottom: 0;
    display: flex;
    position: absolute;
    right: -2rem;
    top: 0;
    margin-left: 1rem;

    button {
      cursor: pointer;
    }

    svg {
      border: 1px solid transparent;
      border-radius: 8px;
      vertical-align: middle;

      &:hover {
        border-color: currentColor;
      }
    }
  }
`;

class EditableTimer extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  };
  static defaultProps = {
    value: '00:00:00',
  };
  constructor(props) {
    super(props);
    const time = this.props.value ? this.props.value : '00:00:00';
    const parts = time.split(':');
    this.state = {
      editing: false,
      value: this.props.text,
      hours: parts[0],
      minutes: parts[1],
      seconds: parts[2],
      time,
    };
  }

  toggleEditing = () => this.setState({ editing: !this.state.editing });

  handleChangeMinutes = event => {
    const parts = this.state.time.split(':');
    let time;
    let minutes;
    if (event.target.value < 60 && event.target.value > -1) {
      time = `${parts[0]}:${
        event.target.value < 10 ? `0${event.target.value}` : event.target.value
      }:${parts[2]}`;
      minutes = event.target.value;
    } else if (event.target.value > 59) {
      time = `${parts[0]}:${event.target.value}:${parts[2]}`;
      minutes = 59;
    } else if (event.target.value < 0) {
      time = `${parts[0]}:00:${parts[2]}`;
      minutes = 0;
    }
    if (minutes && time) {
      this.setState({
        minutes,
        time,
      });
      this.props.onChange(time);
    }
  };

  handleChangeSeconds = event => {
    const parts = this.state.time.split(':');
    let time;
    let seconds;
    if (event.target.value < 60 && event.target.value > -1) {
      time = `${parts[0]}:${parts[1]}:${
        event.target.value < 10 ? `0${event.target.value}` : event.target.value
      }`;
      seconds = event.target.value;
    } else if (event.target.value > 59) {
      seconds = 59;
      time = `${parts[0]}:${parts[1]}:${seconds}`;
    } else if (event.target.value < 0) {
      time = `${parts[0]}:${parts[1]}:00`;
      seconds = 0;
    }
    if (seconds && time) {
      this.setState({
        seconds,
        time,
      });
      this.props.onChange(time);
    }
  };

  handleSave = () => {
    this.toggleEditing();
  };

  renderDisplay = () => (
    <Span
      className={`${this.props.className} editable-text editable-text-display`}
      onClick={this.toggleEditing}
      role="button"
      tabIndex={0}
    >
      {moment(`1900-01-01 ${this.state.time}`).format('mm:ss')}
    </Span>
  );

  renderForm = () => (
    <Span
      className={`${this.props.className} editable-text editable-text-form`}
    >
      <input
        max="59"
        min="0"
        type="number"
        value={this.state.minutes}
        onChange={this.handleChangeMinutes}
      />
      <input
        max="59"
        min="0"
        type="number"
        value={this.state.seconds}
        onChange={this.handleChangeSeconds}
      />
      <span className="controls">
        <button onClick={this.handleSave}>
          <Save style={{ height: 16, width: 16 }} />
        </button>
      </span>
    </Span>
  );

  render() {
    return this.state.editing ? this.renderForm() : this.renderDisplay();
  }
}

EditableTimer.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

EditableTimer.defaultProps = {
  className: '',
  text: '',
};

export default EditableTimer;
