import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TooltipElement from '../../../../../app/components/tooltipElement';

const ModalToolbarTooltip = styled(TooltipElement)`
  position: absolute;
  left: calc(66% - 12px);
  top: calc(50% - 12px);
`;

const ModalToolbarActions = ({ className, children, tooltip }) => (
  <div className={className}>
    {children}
    <ModalToolbarTooltip tooltip={tooltip} />
  </div>
);

ModalToolbarActions.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default styled(ModalToolbarActions)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
