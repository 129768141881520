import React from 'react';
import _get from 'lodash/get';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Tabs, Tab } from '../../../../../components/tabs';
import Container from '../../../../../../../app/components/container';
import DesignToggle from '../../../../../components/designToggle';
import ColorPicker from '../../../../../components/colorPicker';
import SectionHeader from '../../../../../components/sectionHeader';

const Booking = props => {
  const {
    component,
    onDesignChange,
    design,
    appname,
    history,
    toggleBooking,
  } = props;
  const { t } = useTranslation();

  const handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    onDesignChange(prop, rgba);
  };

  const handleClick = () => {
    toggleBooking(true);
    history.push(`/${appname}/ecommerce/appointments`);
  };

  return (
    <React.Fragment>
      <Tabs>
        <Tab label={t('content')}>
          <Container>
            <SectionHeader
              title={t('Booking')}
              tooltip={
                <span>
                  <Trans>Follow the instructions below</Trans> <br />
                  <Trans>to add an Appointment</Trans>
                </span>
              }
            />
            <div style={{ padding: '0 30px', marginTop: 14 }}>
              <p>
                <Trans>To add appointments click on Business Tools</Trans> ->
                <Trans>Bookings</Trans> ->{' '}
                <a style={{ cursor: 'pointer' }} onClick={() => handleClick()}>
                  <Trans>Appointments</Trans>
                </a>
              </p>
            </div>
          </Container>
        </Tab>

        <Tab label={t('design')}>
          <Container>
            <DesignToggle
              component={component}
              onDesignChange={onDesignChange}
            />
            <ColorPicker
              label={t('Background Color')}
              color={design.backgroundColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('backgroundColor')}
            />
          </Container>
        </Tab>
        <Tab
          label={t('content')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
        <Tab
          label={t('layout')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    </React.Fragment>
  );
};

export default Booking;
