import Marketplace from './Marketplace';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getMarketplaceSettings } from './modules/settings/actions';
import { appHasComponent } from '../../../../utils';
const mapStateToProps = (state, ownProps) => {
  const marketplaceComponentExists = appHasComponent(
    state.engage.structure,
    'Marketplace',
  );

  return {
    ...ownProps,
    marketplaceEnabled: state.ecommerce.marketplace.isEnabled,
    marketplaceComponentExists,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMarketplaceSettings: () =>
    dispatch(getMarketplaceSettings(ownProps.match.params.appname)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Marketplace),
);
