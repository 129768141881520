import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NewMemberBody from './NewMemberBody';
import { objectToArray } from '../../../../../../utils';

import {
  addMemberBody,
  closeNewMemberBodyDialog,
  formFieldChange,
  formFieldUniqueCheck,
  formFieldChangeNonRequired,
  attachBanner,
} from './actions';

const disabledStaffs = filteredChunks => {
  let usedSatffs = {};
  objectToArray(filteredChunks).forEach(i => {
    usedSatffs = { ...usedSatffs, ...i.value.staffMembers };
  });
  return usedSatffs;
};

const mapStateToProps = state => ({
  ...state.auth.memberBodies.newMemberBody,
  filteredChunks: state.auth.memberBodies.data.filteredChunks,
  availableStaffMembers: state.auth.memberBodies.data.availableStaffMembers,
  staffs: objectToArray(state.auth.staff.data.chunks),
  disabledStaffs: disabledStaffs(state.auth.memberBodies.data.filteredChunks),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(addMemberBody(ownProps.match.params.appname)),
  onRequestClose: () => dispatch(closeNewMemberBodyDialog()),
  onFieldChange: (field, value) => dispatch(formFieldChange(field, value)),
  onFormFieldChangeNonRequired: (field, value) =>
    dispatch(formFieldChangeNonRequired(field, value)),
  onFieldChangeWithUniqueCheck: (field, value, filteredChunks) =>
    dispatch(formFieldUniqueCheck(field, value, filteredChunks)),
  attachBanner: () => dispatch(attachBanner()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewMemberBody),
);
