import { connect } from 'react-redux';

import IFrame from './IFrame';
import { fieldChange, handleDesignChange } from './actions';

const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onDesignChange: property => (type, value) =>
    dispatch(
      handleDesignChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        type,
        value,
        property,
      ),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IFrame);
