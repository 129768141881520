import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProductsTable from './ProductsTable';
import {
  getProducts,
  stopGetProducts,
  filterProductData,
  selectProduct,
  selectAllProducts,
  deselectAllProducts,
  deleteSelected,
  sortTableData,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.ecommerce.marketplace.productsTable,
  ...state.account.profile,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getProducts: () => dispatch(getProducts(ownProps.match.params.appname)),
  stopGetProducts: () =>
    dispatch(stopGetProducts(ownProps.match.params.appname)),
  onFilterProductData: filter => dispatch(filterProductData(filter)),
  onSort: cols => dispatch(sortTableData(cols)),
  onSelect: key => dispatch(selectProduct(key)),
  onSelectAllProducts: () => dispatch(selectAllProducts()),
  onDeselectAllProducts: () => dispatch(deselectAllProducts()),
  onDeleteSelected: (selected, chunks) =>
    dispatch(deleteSelected(ownProps.match.params.appname, selected, chunks)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductsTable),
);
