import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';
import { Trans, useTranslation } from 'react-i18next';

import Container from '../../../../app/components/container';

import TextArea from '../../../../app/components/textArea';
import { CircularProgress } from '../../../../app/components/progress';
import { FlatButton } from '../../../../app/components/buttons';

import {
  USER_CLICKED_BUY_SMS,
  sendSlackNotification,
  PLATFORM_EVENTS,
} from '../../../../utils';

import './styles.css';

const ShareSmsForm = ({
  closeDialog,
  message,
  isPendingSMS,
  onMessageChange,
  selected,
  onSendSMS,
  location,
  smsCredit,
  isAppAdmin,
  selectedContacts,
  ...other
}) => {
  const { t } = useTranslation();
  const contacts = selectedContacts || selected.length;
  const onButtonClick = ({ notificationTitle, nextPage }) => {
    const { match, subscription, organisation, history } = other;

    sendSlackNotification({
      appname: match.params.appname,
      notificationTitle,
      account: organisation.key,
      currentPlan: subscription,
      slackChannel: PLATFORM_EVENTS,
    });
    history.push(nextPage);
  };

  const errorMessage =
    message.value && message.value.length
      ? `Message length exceeded! Please limit to 90 characters.`
      : '';

  return (
    <div className="contacts-form">
      <h5>
        <Trans>Send SMS Notification to Selected Users</Trans>
      </h5>
      <div className="modal-controls-container">
        <IconButton onClick={closeDialog}>
          <Close />
        </IconButton>
      </div>
      <Container className="send-sms">
        <TextArea
          border={false}
          id="sms-body"
          required
          value={message.value}
          label={t('Message')}
          onChange={value => onMessageChange(value)}
          maxLength={90}
          disabled={isPendingSMS}
          isValid={!!(message.value && message.value.length <= 90)}
          {...(message.value &&
            message.value.length > 90 && { errorText: errorMessage })}
        />
      </Container>
      <div className="sms-container">
        <div className="sms-left-container">
          <div className="sms-left-title">
            <Trans>SMS LEFT</Trans>
          </div>
          <br />
          <div className="sms-left-number">{smsCredit}</div>
        </div>
        {!isAppAdmin && (
          <div className="sms-button-container">
            <FlatButton
              label={t('Buy more SMS')}
              onClick={() => {
                const nextPage = {
                  pathname: '/choose_sms_bundle',
                  state: { origin: location.pathname },
                };
                onButtonClick({
                  notificationTitle: USER_CLICKED_BUY_SMS,
                  nextPage,
                });
                closeDialog();
              }}
              secondary
            />
          </div>
        )}
      </div>
      <p className="confirmation-message">
        <Trans>Share with</Trans>{' '}
        <strong>{selectedContacts || selected.length}</strong>
        &nbsp;
        {contacts.length > 1
          ? t('selected contacts')
          : t('selected contact')}{' '}
        <Trans>by SMS</Trans>
      </p>
      <Container>
        {isPendingSMS && <CircularProgress size={18} />}
        {!isPendingSMS && (
          <FlatButton
            label={t('Confirm')}
            onClick={onSendSMS}
            style={{
              margin: 12,
            }}
            primary
            disabled={message.value && message.value.length > 90}
          />
        )}
      </Container>
    </div>
  );
};

ShareSmsForm.propTypes = {
  open: PropTypes.bool.isRequired,
  isPendingSMS: PropTypes.bool.isRequired,
  smsCredit: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  openDialog: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onMessageChange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  setDefaultMessage: PropTypes.func.isRequired,
  onAddContact: PropTypes.func.isRequired,
  onSendSMS: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  selected: PropTypes.array,
  selectedContacts: PropTypes.array,
};

ShareSmsForm.defaultProps = {
  selected: [],
  selectedContacts: [],
};

export default withRouter(ShareSmsForm);
