import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import { Trans, withTranslation } from 'react-i18next';
import { downloadCSV } from 'utils';

import TryAgain from 'app/components/tryAgain';
import ActionMenu from 'app/components/actionMenu';
import ResultsTable from 'app/components/resultsTable';
import TableCheckbox from '../../components/tableCheckbox';
import Header from '../../components/header';
import NewStaff from './newStaff';
import EditStaff from './editStaff';
import { HS_FEATURES_ENABLED_APPS } from '../../../../config';
import themeStyles from '../../../../_export.scss';

let staffFlag = false;
const isCheckboxDisabled = (error, pending) => error && !pending;

class Staff extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    chunks: PropTypes.object,
    getStaffs: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func.isRequired,
    onSelectAllStaffs: PropTypes.func.isRequired,
    onDeselectAllStaffs: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    onFilterStaffData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    filter: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
    chunks: {},
    filteredChunks: {},
    items: [],
    query: '',
    selected: [],
    selectAll: false,
  };

  componentWillMount() {
    staffFlag =
      HS_FEATURES_ENABLED_APPS.indexOf(this.props.match.params.appname) > -1;
  }

  getSelectAllCheckBox = () => {
    const {
      error,
      pending,
      chunks,
      selected,
      selectAll,
      onSelectAllStaffs,
      onDeselectAllStaffs,
      onDeleteSelected,
      t,
    } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllStaffs() : onDeselectAllStaffs()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDownloadSelected={() => {
            downloadCSV(
              this.getCsvData(selected, chunks),
              staffFlag ? 'staff' : 'event-staff',
            );
          }}
          onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          text={staffFlag ? t('staff') : t('event staff')}
        />
      </div>
    );
  };

  getTitle = (selected, staff) => {
    const { t } = this.props;
    return (
      <span>
        {staffFlag ? t('Staff') : t('Event Organizer Admins')}:{' '}
        {selected.length
          ? `${selected.length} / ${Object.keys(staff).length}`
          : Object.keys(staff).length}
      </span>
    );
  };

  getCsvData = (selected, chunks) => {
    const { t } = this.props;
    const csvColumns = [t('Name'), t('Email'), t('Date Created')];
    let data = `${csvColumns.join(',')}\n`;

    selected.forEach(key => {
      data += `${chunks[key].firstName} ${chunks[key].lastName},${
        chunks[key].email
      },${
        chunks[key].createdAt
          ? moment(chunks[key].createdAt).format('DD/MM/YYYY')
          : '-'
      }\n`;
    });

    return data;
  };

  render() {
    const {
      pending,
      items,
      error,
      getStaffs,
      chunks,
      onSelect,
      filteredChunks,
      onFilterStaffData,
      onSelectAllStaffs,
      onDeselectAllStaffs,
      selected,
      onDeleteSelected,
      history,
      match,
      t,
    } = this.props;

    const eventOrganizersAdminsInstructions = (
      <p className="instructions-container">
        <p className="instructions-section-1">
          <span>
            {' '}
            <Trans>
              An Event Organizer Admin can be added here by clicking on the '+'
              button.
            </Trans>
          </span>
          <br />
          <span>
            <Trans>
              You need to assign each Event Organizer Admin to an existing Event
              Organizer (you can have multiple admins assigned to one event
              organizer).
            </Trans>
          </span>
        </p>
        <p className="instructions-section-2">
          <span>
            <Trans>
              Event Organizer Admin can login with their login credentials that
              they received via email.
            </Trans>
          </span>
          <br />
          <span>
            <Trans>
              This would give them limited access to your app admin dashboard
              with the following permissions:
            </Trans>
             
            <ul style={{ listStyleType: 'decimal' }}>
              <li>
                <Trans>
                  Edit and update the event organizer profile that they have
                  been assigned to.
                </Trans>
              </li>
              <li>
                <Trans>Add/Edit/Delete Events.</Trans>
              </li>
              <li>
                <Trans>Add/Edit/Delete Courses (if applicable).</Trans>
              </li>
            </ul>
          </span>
        </p>
          
      </p>
    );

    const body = error ? (
      <TryAgain
        message={t('Oops, something went wrong...')}
        onRetry={getStaffs}
      />
    ) : (
      <ResultsTable
        items={items}
        chunks={filteredChunks}
        error={Object.keys(filteredChunks).length === 0 ? 'yes' : null}
        pending={pending}
        loading={pending}
        emptyMessage={`${t('Looks like no')} ${staffFlag ? '' : t('event')} ${t(
          'organizer admins have been added yet',
        )}`}
        onRowTouchTap={staff =>
          history.push(`/${match.params.appname}/members/staff/${staff}`)
        }
        optionalMessage={!staffFlag && eventOrganizersAdminsInstructions}
        columns={[
          {
            ids: ['firstName', 'lastName'],
            header: t('Name'),
            classNames: 'col-xs-3',
            containerElement: ({ value }) => <div>{value}</div>,
          },
          {
            id: 'email',
            header: t('Email'),
            classNames: 'col-xs-5',
          },
          {
            id: 'createdAt',
            header: t('Date Created'),
            classNames: 'col-xs-3',
            containerElement: ({ value }) => (
              <span>{value && moment(value).format('DD/MM/YYYY')}</span>
            ),
          },
          {
            id: 'selected',
            header: this.getSelectAllCheckBox(),
            classNames: `col-xs-1 ${
              items.length < 4 ? 'fix-left' : 'selected'
            }`,
            containerElement: ({ value, rowId }) =>
              rowId !== 'default' && (
                <>
                  <TableCheckbox
                    value={value}
                    onSelect={() => onSelect(rowId)}
                  />
                  <ActionMenu
                    selected={[rowId]}
                    onDownloadSelected={() => {
                      downloadCSV(
                        this.getCsvData([rowId], chunks),
                        staffFlag ? 'staff' : 'event-staff',
                      );
                    }}
                    onDeleteSelected={() => onDeleteSelected([rowId], chunks)}
                    onEditSelected={() =>
                      history.push(
                        `/${match.params.appname}/members/staff/${rowId}`,
                      )
                    }
                    text={staffFlag ? t('staff') : t('event staff')}
                  />
                </>
              ),
          },
        ]}
      />
    );

    return (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <Header
          {...this.props}
          title={this.getTitle(selected, chunks)}
          onChange={onFilterStaffData}
          onSelectAllResponses={onSelectAllStaffs}
          onDeselectAllResponses={onDeselectAllStaffs}
          actionMenu={
            <ActionMenu
              selected={selected}
              onDownloadSelected={() => {
                downloadCSV(
                  this.getCsvData(selected, chunks),
                  staffFlag ? 'staff' : 'event-staff',
                );
              }}
              onDeleteSelected={() => onDeleteSelected(selected, chunks)}
              text={staffFlag ? t('staff') : t('event staff')}
            />
          }
        />
        {body}
        <NewStaff />
        <EditStaff />
      </section>
    );
  }
}

export default withTranslation()(Staff);
