import { combineReducers } from 'redux';
import _keys from 'lodash/keys';
import createField from './modules/create/reducer';

import {
  REQUEST_WORKFORCE_GROUP_DETAILS,
  REQUEST_WORKFORCE_GROUP_FIELD_UPDATE,
  RECEIVE_WORKFORCE_GROUP_FIELD_UPDATE,
  RECEIVE_WORKFORCE_GROUP_DETAILS,
  WORKFORCE_GROUP_FIELD_DELETE,
  COC_UPLOAD_START,
  COC_UPLOAD_SUCCESS,
  COC_UPLOAD_FAILURE,
} from './actions';

const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  name: {
    value: '',
    isValid: null,
  },
  type: {
    value: '',
    isValid: null,
  },
  options: {
    value: '',
    isValid: null,
  },
  groupDetails: {
    name: '',
  },
  fields: [],
  fileloading: false,
};

const editGroup = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_WORKFORCE_GROUP_DETAILS:
      return {
        ...state,
      };

    case REQUEST_WORKFORCE_GROUP_FIELD_UPDATE:
      return {
        ...state,
      };

    case WORKFORCE_GROUP_FIELD_DELETE:
      return {
        ...state,
        fieldID: action.fieldID,
      };

    case RECEIVE_WORKFORCE_GROUP_FIELD_UPDATE:
      return {
        ...state,
        data: action.data,
      };

    case COC_UPLOAD_START:
      return {
        ...state,
        fileloading: true,
      };

    case COC_UPLOAD_FAILURE:
    case COC_UPLOAD_SUCCESS:
      return {
        ...state,
        fileloading: false,
      };

    case RECEIVE_WORKFORCE_GROUP_DETAILS:
      return {
        ...state,
        groupDetails: action.value,
        fields: _keys(action.value.fields),
        pending: false,
        error: null,
      };

    default:
      return state;
  }
};

export default combineReducers({
  editGroup,
  createField,
});
