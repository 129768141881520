import { connect } from 'react-redux';

import RadioStreaming from './RadioStreaming';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';
import { fieldChange } from './actions';
import { handleDesignChange } from '../button/actions';
import _get from 'lodash/get';

const mapStateToProps = (state, ownProps) => {
  const appname = ownProps.match.params.appname;
  const palette = _get(state, `editor.design.${appname}.palette`);
  const componentDesign = ownProps.component.design || {};
  const border = {
    width: 1,
    style: 'solid',
    color: palette.alternateTextColor,
    ...componentDesign.border,
  };
  const design = {
    ...ownProps.component.design,
    enabled: componentDesign.enabled,
    buttonDesign: {
      enabled: componentDesign.enabled,
      textColor: componentDesign.textColor || palette.alternateTextColor,
      backgroundColor: componentDesign.backgroundColor || palette.primary1Color,
      borderRadius: componentDesign.borderRadius || 32,
      border,
    },
  };
  return {
    ...ownProps,
    component: {
      design,
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  openSnackbar: message => dispatch(openSnackbar(message)),
  onDesignChange: property => (type, value) =>
    dispatch(
      handleDesignChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        type,
        value,
        property,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RadioStreaming);
