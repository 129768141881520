export const countries = isIntegration => {
  if (isIntegration) {
    return [
      {
        code: 'AF',
        dialCode: '+93',
        name: 'Afghanistan',
      },
      {
        code: 'AL',
        dialCode: '+355',
        name: 'Albania',
      },

      {
        code: 'AD',
        dialCode: '+376',
        name: 'Andorra',
      },
      {
        code: 'AO',
        dialCode: '+244',
        name: 'Angola',
      },
      {
        code: 'AI',
        dialCode: '+1 264',
        name: 'Anguilla',
      },
      {
        code: 'AG',
        dialCode: '+1268',
        name: 'Antigua and Barbuda',
      },
      {
        code: 'AR',
        dialCode: '+54',
        name: 'Argentina',
      },
      {
        code: 'AM',
        dialCode: '+374',
        name: 'Armenia',
      },
      {
        code: 'AW',
        dialCode: '+297',
        name: 'Aruba',
      },
      {
        code: 'AU',
        dialCode: '+61',
        name: 'Australia',
      },
      {
        code: 'AT',
        dialCode: '+43',
        name: 'Austria',
      },
      {
        code: 'BS',
        dialCode: '+1 242',
        name: 'Bahamas',
      },
      {
        code: 'BB',
        dialCode: '+1 246',
        name: 'Barbados',
      },
      {
        code: 'BY',
        dialCode: '+375',
        name: 'Belarus',
      },
      {
        code: 'BZ',
        dialCode: '+501',
        name: 'Belize',
      },
      {
        code: 'BM',
        dialCode: '+1 441',
        name: 'Bermuda',
      },
      {
        code: 'BT',
        dialCode: '+975',
        name: 'Bhutan',
      },
      {
        code: 'BO',
        dialCode: '+591',
        name: 'Bolivia, Plurinational State of',
      },
      {
        code: 'BA',
        dialCode: '+387',
        name: 'Bosnia and Herzegovina',
      },
      {
        code: 'BR',
        dialCode: '+55',
        name: 'Brazil',
      },
      {
        code: 'IO',
        dialCode: '+246',
        name: 'British Indian Ocean Territory',
      },
      {
        code: 'BN',
        dialCode: '+673',
        name: 'Brunei Darussalam',
      },
      {
        code: 'BG',
        dialCode: '+359',
        name: 'Bulgaria',
      },
      {
        code: 'BF',
        dialCode: '+226',
        name: 'Burkina Faso',
      },
      {
        code: 'CA',
        dialCode: '+1',
        name: 'Canada',
      },
      {
        code: 'CV',
        dialCode: '+238',
        name: 'Cape Verde',
      },
      {
        code: 'KY',
        dialCode: '+ 345',
        name: 'Cayman Islands',
      },
      {
        code: 'CF',
        dialCode: '+236',
        name: 'Central African Republic',
      },
      {
        code: 'TD',
        dialCode: '+235',
        name: 'Chad',
      },
      {
        code: 'CL',
        dialCode: '+56',
        name: 'Chile',
      },
      {
        code: 'CN',
        dialCode: '+86',
        name: 'China',
      },
      {
        code: 'CX',
        dialCode: '+61',
        name: 'Christmas Island',
      },
      {
        code: 'CC',
        dialCode: '+61',
        name: 'Cocos (Keeling) Islands',
      },
      {
        code: 'CO',
        dialCode: '+57',
        name: 'Colombia',
      },
      {
        code: 'KM',
        dialCode: '+269',
        name: 'Comoros',
      },
      {
        code: 'CG',
        dialCode: '+242',
        name: 'Congo',
      },
      {
        code: 'CD',
        dialCode: '+243',
        name: 'Congo, The Democratic Republic of the',
      },
      {
        code: 'CK',
        dialCode: '+682',
        name: 'Cook Islands',
      },
      {
        code: 'CR',
        dialCode: '+506',
        name: 'Costa Rica',
      },
      {
        code: 'HR',
        dialCode: '+385',
        name: 'Croatia',
      },
      {
        code: 'CU',
        dialCode: '+53',
        name: 'Cuba',
      },
      {
        code: 'CY',
        dialCode: '+537',
        name: 'Cyprus',
      },
      {
        code: 'CZ',
        dialCode: '+420',
        name: 'Czech Republic',
      },
      {
        code: 'DK',
        dialCode: '+45',
        name: 'Denmark',
      },
      {
        code: 'DM',
        dialCode: '+1 767',
        name: 'Dominica',
      },
      {
        code: 'DO',
        dialCode: '+1 849',
        name: 'Dominican Republic',
      },
      {
        code: 'EC',
        dialCode: '+593',
        name: 'Ecuador',
      },
      {
        code: 'EG',
        dialCode: '+20',
        name: 'Egypt',
      },
      {
        code: 'SV',
        dialCode: '+503',
        name: 'El Salvador',
      },
      {
        code: 'GQ',
        dialCode: '+240',
        name: 'Equatorial Guinea',
      },
      {
        code: 'ET',
        dialCode: '+251',
        name: 'Ethiopia',
      },
      {
        code: 'FK',
        dialCode: '+500',
        name: 'Falkland Islands (Malvinas)',
      },
      {
        code: 'FO',
        dialCode: '+298',
        name: 'Faroe Islands',
      },
      {
        code: 'FI',
        dialCode: '+358',
        name: 'Finland',
      },
      {
        code: 'FR',
        dialCode: '+33',
        name: 'France',
      },
      {
        code: 'PF',
        dialCode: '+689',
        name: 'French Polynesia',
      },
      {
        code: 'GA',
        dialCode: '+241',
        name: 'Gabon',
      },
      {
        code: 'GM',
        dialCode: '+220',
        name: 'Gambia',
      },
      {
        code: 'GE',
        dialCode: '+995',
        name: 'Georgia',
      },
      {
        code: 'DE',
        dialCode: '+49',
        name: 'Germany',
      },
      {
        code: 'GH',
        dialCode: '+233',
        name: 'Ghana',
      },
      {
        code: 'GI',
        dialCode: '+350',
        name: 'Gibraltar',
      },
      {
        code: 'GR',
        dialCode: '+30',
        name: 'Greece',
      },
      {
        code: 'GL',
        dialCode: '+299',
        name: 'Greenland',
      },
      {
        code: 'GD',
        dialCode: '+1 473',
        name: 'Grenada',
      },
      {
        code: 'GP',
        dialCode: '+590',
        name: 'Guadeloupe',
      },
      {
        code: 'GU',
        dialCode: '+1 671',
        name: 'Guam',
      },
      {
        code: 'GT',
        dialCode: '+502',
        name: 'Guatemala',
      },
      {
        code: 'GY',
        dialCode: '+595',
        name: 'Guyana',
      },
      {
        code: 'HT',
        dialCode: '+509',
        name: 'Haiti',
      },
      {
        code: 'VA',
        dialCode: '+379',
        name: 'Holy See (Vatican City State)',
      },
      {
        code: 'HN',
        dialCode: '+504',
        name: 'Honduras',
      },
      {
        code: 'HK',
        dialCode: '+852',
        name: 'Hong Kong',
      },
      {
        code: 'HU',
        dialCode: '+36',
        name: 'Hungary',
      },
      {
        code: 'IS',
        dialCode: '+354',
        name: 'Iceland',
      },
      {
        code: 'IN',
        dialCode: '+91',
        name: 'India',
      },
      {
        code: 'ID',
        dialCode: '+62',
        name: 'Indonesia',
      },
      {
        code: 'IR',
        dialCode: '+98',
        name: 'Iran, Islamic Republic of',
      },
      {
        code: 'IE',
        dialCode: '+353',
        name: 'Ireland',
      },
      {
        code: 'IL',
        dialCode: '+972',
        name: 'Israel',
      },

      {
        code: 'IT',
        dialCode: '+39',
        name: 'Italy',
      },
      {
        code: 'JM',
        dialCode: '+1 876',
        name: 'Jamaica',
      },
      {
        code: 'JP',
        dialCode: '+81',
        name: 'Japan',
      },
      {
        code: 'JO',
        dialCode: '+962',
        name: 'Jordan',
      },
      {
        code: 'KZ',
        dialCode: '+7 7',
        name: 'Kazakhstan',
      },
      {
        code: 'KE',
        dialCode: '+254',
        name: 'Kenya',
      },
      {
        code: 'KI',
        dialCode: '+686',
        name: 'Kiribati',
      },
      {
        code: 'KP',
        dialCode: '+850',
        name: "Korea, Democratic People's Republic of",
      },
      {
        code: 'KR',
        dialCode: '+82',
        name: 'Korea, Republic of',
      },
      {
        code: 'KW',
        dialCode: '+965',
        name: 'Kuwait',
      },
      {
        code: 'KG',
        dialCode: '+996',
        name: 'Kyrgyzstan',
      },
      {
        code: 'LA',
        dialCode: '+856',
        name: "Lao People's Democratic Republic",
      },
      {
        code: 'LV',
        dialCode: '+371',
        name: 'Latvia',
      },
      {
        code: 'LB',
        dialCode: '+961',
        name: 'Lebanon',
      },
      {
        code: 'LR',
        dialCode: '+231',
        name: 'Liberia',
      },
      {
        code: 'LY',
        dialCode: '+218',
        name: 'Libyan Arab Jamahiriya',
      },
      {
        code: 'LI',
        dialCode: '+423',
        name: 'Liechtenstein',
      },
      {
        code: 'LT',
        dialCode: '+370',
        name: 'Lithuania',
      },
      {
        code: 'LU',
        dialCode: '+352',
        name: 'Luxembourg',
      },
      {
        code: 'MO',
        dialCode: '+853',
        name: 'Macao',
      },
      {
        code: 'MK',
        dialCode: '+389',
        name: 'Macedonia, The Former Yugoslav Republic of',
      },
      {
        code: 'MG',
        dialCode: '+261',
        name: 'Madagascar',
      },
      {
        code: 'MW',
        dialCode: '+265',
        name: 'Malawi',
      },
      {
        code: 'MY',
        dialCode: '+60',
        name: 'Malaysia',
      },
      {
        code: 'MV',
        dialCode: '+960',
        name: 'Maldives',
      },
      {
        code: 'MT',
        dialCode: '+356',
        name: 'Malta',
      },
      {
        code: 'MR',
        dialCode: '+222',
        name: 'Mauritania',
      },
      {
        code: 'MU',
        dialCode: '+230',
        name: 'Mauritius',
      },
      {
        code: 'MX',
        dialCode: '+52',
        name: 'Mexico',
      },
      {
        code: 'FM',
        dialCode: '+691',
        name: 'Micronesia, Federated States of',
      },
      {
        code: 'MD',
        dialCode: '+373',
        name: 'Moldova, Republic of',
      },
      {
        code: 'MN',
        dialCode: '+976',
        name: 'Mongolia',
      },
      {
        code: 'ME',
        dialCode: '+382',
        name: 'Montenegro',
      },
      {
        code: 'MS',
        dialCode: '+1664',
        name: 'Montserrat',
      },
      {
        code: 'MA',
        dialCode: '+212',
        name: 'Morocco',
      },
      {
        code: 'MZ',
        dialCode: '+258',
        name: 'Mozambique',
      },
      {
        code: 'NA',
        dialCode: '+264',
        name: 'Namibia',
      },
      {
        code: 'NR',
        dialCode: '+674',
        name: 'Nauru',
      },
      {
        code: 'NP',
        dialCode: '+977',
        name: 'Nepal',
      },
      {
        code: 'AN',
        dialCode: '+599',
        name: 'Netherlands Antilles',
      },
      {
        code: 'NZ',
        dialCode: '+64',
        name: 'New Zealand',
      },
      {
        code: 'NI',
        dialCode: '+505',
        name: 'Nicaragua',
      },
      {
        code: 'NU',
        dialCode: '+683',
        name: 'Niue',
      },
      {
        code: 'NF',
        dialCode: '+672',
        name: 'Norfolk Island',
      },
      {
        code: 'MP',
        dialCode: '+1 670',
        name: 'Northern Mariana Islands',
      },
      {
        code: 'NO',
        dialCode: '+47',
        name: 'Norway',
      },

      {
        code: 'PW',
        dialCode: '+680',
        name: 'Palau',
      },

      {
        code: 'PA',
        dialCode: '+507',
        name: 'Panama',
      },
      {
        code: 'PG',
        dialCode: '+675',
        name: 'Papua New Guinea',
      },
      {
        code: 'PY',
        dialCode: '+595',
        name: 'Paraguay',
      },
      {
        code: 'PE',
        dialCode: '+51',
        name: 'Peru',
      },
      {
        code: 'PH',
        dialCode: '+63',
        name: 'Philippines',
      },
      {
        code: 'PN',
        dialCode: '+872',
        name: 'Pitcairn',
      },
      {
        code: 'PL',
        dialCode: '+48',
        name: 'Poland',
      },
      {
        code: 'PT',
        dialCode: '+351',
        name: 'Portugal',
      },
      {
        code: 'PR',
        dialCode: '+1 939',
        name: 'Puerto Rico',
      },
      {
        code: 'QA',
        dialCode: '+974',
        name: 'Qatar',
      },
      {
        code: 'RE',
        dialCode: '+262',
        name: 'Réunion',
      },
      {
        code: 'RO',
        dialCode: '+40',
        name: 'Romania',
      },
      {
        code: 'RW',
        dialCode: '+250',
        name: 'Rwanda',
      },
      {
        code: 'BL',
        dialCode: '+590',
        name: 'Saint Barthélemy',
      },
      {
        code: 'SH',
        dialCode: '+290',
        name: 'Saint Helena, Ascension and Tristan Da Cunha',
      },
      {
        code: 'KN',
        dialCode: '+1 869',
        name: 'Saint Kitts and Nevis',
      },
      {
        code: 'LC',
        dialCode: '+1 758',
        name: 'Saint Lucia',
      },
      {
        code: 'MF',
        dialCode: '+590',
        name: 'Saint Martin',
      },
      {
        code: 'VC',
        dialCode: '+1 784',
        name: 'Saint Vincent and the Grenadines',
      },
      {
        code: 'WS',
        dialCode: '+685',
        name: 'Samoa',
      },
      {
        code: 'SM',
        dialCode: '+378',
        name: 'San Marino',
      },
      {
        code: 'SA',
        dialCode: '+966',
        name: 'Saudi Arabia',
      },
      {
        code: 'RS',
        dialCode: '+381',
        name: 'Serbia',
      },
      {
        code: 'SL',
        dialCode: '+232',
        name: 'Sierra Leone',
      },
      {
        code: 'SG',
        dialCode: '+65',
        name: 'Singapore',
      },
      {
        code: 'SI',
        dialCode: '+386',
        name: 'Slovenia',
      },
      {
        code: 'SB',
        dialCode: '+677',
        name: 'Solomon Islands',
      },
      {
        code: 'ZA',
        dialCode: '+27',
        name: 'South Africa',
      },
      {
        code: 'GS',
        dialCode: '+500',
        name: 'South Georgia and the South Sandwich Islands',
      },
      {
        code: 'ES',
        dialCode: '+34',
        name: 'Spain',
      },

      {
        code: 'SD',
        dialCode: '+249',
        name: 'Sudan',
      },
      {
        code: 'SR',
        dialCode: '+597',
        name: 'Suriname',
      },
      {
        code: 'SJ',
        dialCode: '+47',
        name: 'Svalbard and Jan Mayen',
      },
      {
        code: 'SZ',
        dialCode: '+268',
        name: 'Swaziland',
      },
      {
        code: 'CH',
        dialCode: '+41',
        name: 'Switzerland',
      },
      {
        code: 'SY',
        dialCode: '+963',
        name: 'Syrian Arab Republic',
      },
      {
        code: 'TW',
        dialCode: '+886',
        name: 'Taiwan',
      },

      {
        code: 'TZ',
        dialCode: '+255',
        name: 'Tanzania, United Republic of',
      },
      {
        code: 'TH',
        dialCode: '+66',
        name: 'Thailand',
      },
      {
        code: 'TG',
        dialCode: '+228',
        name: 'Togo',
      },
      {
        code: 'TK',
        dialCode: '+690',
        name: 'Tokelau',
      },
      {
        code: 'TO',
        dialCode: '+676',
        name: 'Tonga',
      },
      {
        code: 'TT',
        dialCode: '+1 868',
        name: 'Trinidad and Tobago',
      },
      {
        code: 'TR',
        dialCode: '+90',
        name: 'Turkey',
      },
      {
        code: 'TC',
        dialCode: '+1 649',
        name: 'Turks and Caicos Islands',
      },
      {
        code: 'UG',
        dialCode: '+256',
        name: 'Uganda',
      },
      {
        code: 'UA',
        dialCode: '+380',
        name: 'Ukraine',
      },
      {
        code: 'AE',
        dialCode: '+971',
        name: 'United Arab Emirates',
      },
      {
        code: 'GB',
        dialCode: '+44',
        name: 'United Kingdom',
      },
      {
        code: 'US',
        dialCode: '+1',
        name: 'United States',
      },
      {
        code: 'UY',
        dialCode: '+598',
        name: 'Uruguay',
      },
      {
        code: 'VU',
        dialCode: '+678',
        name: 'Vanuatu',
      },
      {
        code: 'VE',
        dialCode: '+58',
        name: 'Venezuela, Bolivarian Republic of',
      },
      {
        code: 'VG',
        dialCode: '+1 284',
        name: 'Virgin Islands, British',
      },
      {
        code: 'VI',
        dialCode: '+1 340',
        name: 'Virgin Islands, U.S.',
      },
      {
        code: 'WF',
        dialCode: '+681',
        name: 'Wallis and Futuna',
      },
      {
        code: 'YE',
        dialCode: '+967',
        name: 'Yemen',
      },
      {
        code: 'ZM',
        dialCode: '+260',
        name: 'Zambia',
      },
    ];
  } else {
    return [
      {
        code: 'AD',
        dialCode: '+376',
        name: 'Andorra',
      },
      {
        code: 'AO',
        dialCode: '+244',
        name: 'Angola',
      },
      {
        code: 'AI',
        dialCode: '+1 264',
        name: 'Anguilla',
      },
      {
        code: 'AG',
        dialCode: '+1268',
        name: 'Antigua and Barbuda',
      },
      {
        code: 'AW',
        dialCode: '+297',
        name: 'Aruba',
      },
      {
        code: 'AU',
        dialCode: '+61',
        name: 'Australia',
      },
      {
        code: 'AT',
        dialCode: '+43',
        name: 'Austria',
      },
      {
        code: 'BS',
        dialCode: '+1 242',
        name: 'Bahamas',
      },
      {
        code: 'BH',
        dialCode: '+973',
        name: 'Bahrain',
      },
      {
        code: 'BB',
        dialCode: '+1 246',
        name: 'Barbados',
      },
      {
        code: 'BY',
        dialCode: '+375',
        name: 'Belarus',
      },
      {
        code: 'BE',
        dialCode: '+32',
        name: 'Belgium',
      },
      {
        code: 'BZ',
        dialCode: '+501',
        name: 'Belize',
      },
      {
        code: 'BJ',
        dialCode: '+229',
        name: 'Benin',
      },
      {
        code: 'BM',
        dialCode: '+1 441',
        name: 'Bermuda',
      },
      {
        code: 'BT',
        dialCode: '+975',
        name: 'Bhutan',
      },
      {
        code: 'BO',
        dialCode: '+591',
        name: 'Bolivia, Plurinational State of',
      },
      {
        code: 'BR',
        dialCode: '+55',
        name: 'Brazil',
      },
      {
        code: 'IO',
        dialCode: '+246',
        name: 'British Indian Ocean Territory',
      },
      {
        code: 'BN',
        dialCode: '+673',
        name: 'Brunei Darussalam',
      },
      {
        code: 'BG',
        dialCode: '+359',
        name: 'Bulgaria',
      },
      {
        code: 'BF',
        dialCode: '+226',
        name: 'Burkina Faso',
      },
      {
        code: 'KH',
        dialCode: '+855',
        name: 'Cambodia',
      },
      {
        code: 'CA',
        dialCode: '+1',
        name: 'Canada',
      },
      {
        code: 'KY',
        dialCode: '+ 345',
        name: 'Cayman Islands',
      },
      {
        code: 'CF',
        dialCode: '+236',
        name: 'Central African Republic',
      },
      {
        code: 'CL',
        dialCode: '+56',
        name: 'Chile',
      },
      {
        code: 'CN',
        dialCode: '+86',
        name: 'China',
      },
      {
        code: 'CX',
        dialCode: '+61',
        name: 'Christmas Island',
      },
      {
        code: 'CC',
        dialCode: '+61',
        name: 'Cocos (Keeling) Islands',
      },
      {
        code: 'CO',
        dialCode: '+57',
        name: 'Colombia',
      },
      {
        code: 'KM',
        dialCode: '+269',
        name: 'Comoros',
      },
      {
        code: 'CG',
        dialCode: '+242',
        name: 'Congo',
      },
      {
        code: 'CD',
        dialCode: '+243',
        name: 'Congo, The Democratic Republic of the',
      },
      {
        code: 'CK',
        dialCode: '+682',
        name: 'Cook Islands',
      },
      {
        code: 'CR',
        dialCode: '+506',
        name: 'Costa Rica',
      },
      {
        code: 'HR',
        dialCode: '+385',
        name: 'Croatia',
      },
      {
        code: 'CZ',
        dialCode: '+420',
        name: 'Czech Republic',
      },
      {
        code: 'DK',
        dialCode: '+45',
        name: 'Denmark',
      },
      {
        code: 'DM',
        dialCode: '+1 767',
        name: 'Dominica',
      },
      {
        code: 'DO',
        dialCode: '+1 849',
        name: 'Dominican Republic',
      },
      {
        code: 'EC',
        dialCode: '+593',
        name: 'Ecuador',
      },
      {
        code: 'SV',
        dialCode: '+503',
        name: 'El Salvador',
      },
      {
        code: 'GQ',
        dialCode: '+240',
        name: 'Equatorial Guinea',
      },
      {
        code: 'EE',
        dialCode: '+372',
        name: 'Estonia',
      },
      {
        code: 'ET',
        dialCode: '+251',
        name: 'Ethiopia',
      },
      {
        code: 'ET',
        dialCode: '+251',
        name: 'Ethiopia',
      },
      {
        code: 'FO',
        dialCode: '+298',
        name: 'Faroe Islands',
      },
      {
        code: 'FJ',
        dialCode: '+679',
        name: 'Fiji',
      },
      {
        code: 'FI',
        dialCode: '+358',
        name: 'Finland',
      },
      {
        code: 'FR',
        dialCode: '+33',
        name: 'France',
      },
      {
        code: 'PF',
        dialCode: '+689',
        name: 'French Polynesia',
      },
      {
        code: 'GA',
        dialCode: '+241',
        name: 'Gabon',
      },
      {
        code: 'GM',
        dialCode: '+220',
        name: 'Gambia',
      },
      {
        code: 'DE',
        dialCode: '+49',
        name: 'Germany',
      },
      {
        code: 'GH',
        dialCode: '+233',
        name: 'Ghana',
      },
      {
        code: 'GI',
        dialCode: '+350',
        name: 'Gibraltar',
      },
      {
        code: 'GR',
        dialCode: '+30',
        name: 'Greece',
      },
      {
        code: 'GL',
        dialCode: '+299',
        name: 'Greenland',
      },
      {
        code: 'GD',
        dialCode: '+1 473',
        name: 'Grenada',
      },
      {
        code: 'GU',
        dialCode: '+1 671',
        name: 'Guam',
      },
      {
        code: 'GT',
        dialCode: '+502',
        name: 'Guatemala',
      },
      {
        code: 'GN',
        dialCode: '+224',
        name: 'Guinea',
      },
      {
        code: 'GW',
        dialCode: '+245',
        name: 'Guinea-Bissau',
      },
      {
        code: 'GY',
        dialCode: '+595',
        name: 'Guyana',
      },
      {
        code: 'HT',
        dialCode: '+509',
        name: 'Haiti',
      },
      {
        code: 'VA',
        dialCode: '+379',
        name: 'Holy See (Vatican City State)',
      },
      {
        code: 'HN',
        dialCode: '+504',
        name: 'Honduras',
      },
      {
        code: 'HK',
        dialCode: '+852',
        name: 'Hong Kong',
      },
      {
        code: 'IS',
        dialCode: '+354',
        name: 'Iceland',
      },
      {
        code: 'IN',
        dialCode: '+91',
        name: 'India',
      },
      {
        code: 'ID',
        dialCode: '+62',
        name: 'Indonesia',
      },
      {
        code: 'IR',
        dialCode: '+98',
        name: 'Iran, Islamic Republic of',
      },
      {
        code: 'IQ',
        dialCode: '+964',
        name: 'Iraq',
      },
      {
        code: 'IE',
        dialCode: '+353',
        name: 'Ireland',
      },
      {
        code: 'IL',
        dialCode: '+972',
        name: 'Israel',
      },
      {
        code: 'JM',
        dialCode: '+1 876',
        name: 'Jamaica',
      },
      {
        code: 'JP',
        dialCode: '+81',
        name: 'Japan',
      },
      {
        code: 'KZ',
        dialCode: '+7 7',
        name: 'Kazakhstan',
      },
      {
        code: 'KE',
        dialCode: '+254',
        name: 'Kenya',
      },
      {
        code: 'KP',
        dialCode: '+850',
        name: "Korea, Democratic People's Republic of",
      },
      {
        code: 'KR',
        dialCode: '+82',
        name: 'Korea, Republic of',
      },
      {
        code: 'KW',
        dialCode: '+965',
        name: 'Kuwait',
      },
      {
        code: 'KG',
        dialCode: '+996',
        name: 'Kyrgyzstan',
      },
      {
        code: 'LA',
        dialCode: '+856',
        name: "Lao People's Democratic Republic",
      },
      {
        code: 'LV',
        dialCode: '+371',
        name: 'Latvia',
      },
      {
        code: 'LB',
        dialCode: '+961',
        name: 'Lebanon',
      },
      {
        code: 'LY',
        dialCode: '+218',
        name: 'Libyan Arab Jamahiriya',
      },
      {
        code: 'LI',
        dialCode: '+423',
        name: 'Liechtenstein',
      },
      {
        code: 'LT',
        dialCode: '+370',
        name: 'Lithuania',
      },
      {
        code: 'LU',
        dialCode: '+352',
        name: 'Luxembourg',
      },
      {
        code: 'MO',
        dialCode: '+853',
        name: 'Macao',
      },
      {
        code: 'MK',
        dialCode: '+389',
        name: 'Macedonia, The Former Yugoslav Republic of',
      },
      {
        code: 'MG',
        dialCode: '+261',
        name: 'Madagascar',
      },
      {
        code: 'MW',
        dialCode: '+265',
        name: 'Malawi',
      },
      {
        code: 'MY',
        dialCode: '+60',
        name: 'Malaysia',
      },
      {
        code: 'MV',
        dialCode: '+960',
        name: 'Maldives',
      },
      {
        code: 'MT',
        dialCode: '+356',
        name: 'Malta',
      },
      {
        code: 'MU',
        dialCode: '+230',
        name: 'Mauritius',
      },
      {
        code: 'MX',
        dialCode: '+52',
        name: 'Mexico',
      },
      {
        code: 'MD',
        dialCode: '+373',
        name: 'Moldova, Republic of',
      },
      {
        code: 'MN',
        dialCode: '+976',
        name: 'Mongolia',
      },
      {
        code: 'ME',
        dialCode: '+382',
        name: 'Montenegro',
      },
      {
        code: 'MS',
        dialCode: '+1664',
        name: 'Montserrat',
      },
      {
        code: 'MZ',
        dialCode: '+258',
        name: 'Mozambique',
      },
      {
        code: 'NA',
        dialCode: '+264',
        name: 'Namibia',
      },
      {
        code: 'NR',
        dialCode: '+674',
        name: 'Nauru',
      },
      {
        code: 'NL',
        dialCode: '+31',
        name: 'Netherlands',
      },
      {
        code: 'AN',
        dialCode: '+599',
        name: 'Netherlands Antilles',
      },
      {
        code: 'NZ',
        dialCode: '+64',
        name: 'New Zealand',
      },
      {
        code: 'NI',
        dialCode: '+505',
        name: 'Nicaragua',
      },
      {
        code: 'NE',
        dialCode: '+227',
        name: 'Niger',
      },
      {
        code: 'NG',
        dialCode: '+234',
        name: 'Nigeria',
      },
      {
        code: 'NU',
        dialCode: '+683',
        name: 'Niue',
      },
      {
        code: 'NF',
        dialCode: '+672',
        name: 'Norfolk Island',
      },
      {
        code: 'MP',
        dialCode: '+1 670',
        name: 'Northern Mariana Islands',
      },
      {
        code: 'NO',
        dialCode: '+47',
        name: 'Norway',
      },
      {
        code: 'PA',
        dialCode: '+507',
        name: 'Panama',
      },
      {
        code: 'PG',
        dialCode: '+675',
        name: 'Papua New Guinea',
      },
      {
        code: 'PY',
        dialCode: '+595',
        name: 'Paraguay',
      },
      {
        code: 'PE',
        dialCode: '+51',
        name: 'Peru',
      },
      {
        code: 'PH',
        dialCode: '+63',
        name: 'Philippines',
      },
      {
        code: 'PN',
        dialCode: '+872',
        name: 'Pitcairn',
      },
      {
        code: 'PL',
        dialCode: '+48',
        name: 'Poland',
      },
      {
        code: 'PT',
        dialCode: '+351',
        name: 'Portugal',
      },
      {
        code: 'PR',
        dialCode: '+1 939',
        name: 'Puerto Rico',
      },
      {
        code: 'QA',
        dialCode: '+974',
        name: 'Qatar',
      },
      {
        code: 'RE',
        dialCode: '+262',
        name: 'Réunion',
      },
      {
        code: 'RU',
        dialCode: '+7',
        name: 'Russia',
      },
      {
        code: 'RW',
        dialCode: '+250',
        name: 'Rwanda',
      },
      {
        code: 'BL',
        dialCode: '+590',
        name: 'Saint Barthélemy',
      },
      {
        code: 'SH',
        dialCode: '+290',
        name: 'Saint Helena, Ascension and Tristan Da Cunha',
      },
      {
        code: 'KN',
        dialCode: '+1 869',
        name: 'Saint Kitts and Nevis',
      },
      {
        code: 'LC',
        dialCode: '+1 758',
        name: 'Saint Lucia',
      },
      {
        code: 'MF',
        dialCode: '+590',
        name: 'Saint Martin',
      },
      {
        code: 'VC',
        dialCode: '+1 784',
        name: 'Saint Vincent and the Grenadines',
      },
      {
        code: 'WS',
        dialCode: '+685',
        name: 'Samoa',
      },
      {
        code: 'ST',
        dialCode: '+239',
        name: 'Sao Tome and Principe',
      },
      {
        code: 'SA',
        dialCode: '+966',
        name: 'Saudi Arabia',
      },
      {
        code: 'SN',
        dialCode: '+221',
        name: 'Senegal',
      },
      {
        code: 'RS',
        dialCode: '+381',
        name: 'Serbia',
      },
      {
        code: 'SC',
        dialCode: '+248',
        name: 'Seychelles',
      },
      {
        code: 'SL',
        dialCode: '+232',
        name: 'Sierra Leone',
      },
      {
        code: 'SG',
        dialCode: '+65',
        name: 'Singapore',
      },
      {
        code: 'SK',
        dialCode: '+421',
        name: 'Slovakia',
      },
      {
        code: 'SI',
        dialCode: '+386',
        name: 'Slovenia',
      },
      {
        code: 'ZA',
        dialCode: '+27',
        name: 'South Africa',
      },
      {
        code: 'GS',
        dialCode: '+500',
        name: 'South Georgia and the South Sandwich Islands',
      },
      {
        code: 'ES',
        dialCode: '+34',
        name: 'Spain',
      },
      {
        code: 'SD',
        dialCode: '+249',
        name: 'Sudan',
      },
      {
        code: 'SR',
        dialCode: '+597',
        name: 'Suriname',
      },
      {
        code: 'SJ',
        dialCode: '+47',
        name: 'Svalbard and Jan Mayen',
      },
      {
        code: 'SZ',
        dialCode: '+268',
        name: 'Swaziland',
      },
      {
        code: 'SE',
        dialCode: '+46',
        name: 'Sweden',
      },
      {
        code: 'CH',
        dialCode: '+41',
        name: 'Switzerland',
      },
      {
        code: 'TW',
        dialCode: '+886',
        name: 'Taiwan',
      },
      {
        code: 'TZ',
        dialCode: '+255',
        name: 'Tanzania, United Republic of',
      },
      {
        code: 'TH',
        dialCode: '+66',
        name: 'Thailand',
      },
      {
        code: 'TG',
        dialCode: '+228',
        name: 'Togo',
      },
      {
        code: 'TK',
        dialCode: '+690',
        name: 'Tokelau',
      },
      {
        code: 'TO',
        dialCode: '+676',
        name: 'Tonga',
      },
      {
        code: 'TT',
        dialCode: '+1 868',
        name: 'Trinidad and Tobago',
      },
      {
        code: 'TR',
        dialCode: '+90',
        name: 'Turkey',
      },
      {
        code: 'TC',
        dialCode: '+1 649',
        name: 'Turks and Caicos Islands',
      },
      {
        code: 'UG',
        dialCode: '+256',
        name: 'Uganda',
      },
      {
        code: 'AE',
        dialCode: '+971',
        name: 'United Arab Emirates',
      },
      {
        code: 'GB',
        dialCode: '+44',
        name: 'United Kingdom',
      },
      {
        code: 'US',
        dialCode: '+1',
        name: 'United States',
      },
      {
        code: 'UY',
        dialCode: '+598',
        name: 'Uruguay',
      },
      {
        code: 'VU',
        dialCode: '+678',
        name: 'Vanuatu',
      },
      {
        code: 'VE',
        dialCode: '+58',
        name: 'Venezuela, Bolivarian Republic of',
      },
      {
        code: 'VN',
        dialCode: '+84',
        name: 'Viet Nam',
      },
      {
        code: 'VG',
        dialCode: '+1 284',
        name: 'Virgin Islands, British',
      },
      {
        code: 'VI',
        dialCode: '+1 340',
        name: 'Virgin Islands, U.S.',
      },
      {
        code: 'WF',
        dialCode: '+681',
        name: 'Wallis and Futuna',
      },
      {
        code: 'ZM',
        dialCode: '+260',
        name: 'Zambia',
      },
      {
        code: 'ZW',
        dialCode: '+263',
        name: 'Zimbabwe',
      },
    ];
  }
};
