export const REQUEST_MARKETPLACE_VENDORS = 'REQUEST_MARKETPLACE_VENDORS';
export const STOP_RECEIVING_MARKETPLACE_VENDORS =
  'STOP_RECEIVING_MARKETPLACE_VENDORS';

export const RECEIVE_MARKETPLACE_VENDORS = 'RECEIVE_MARKETPLACE_VENDORS';
export const NO_MARKETPLACE_VENDORS_EXIST = 'NO_MARKETPLACE_VENDORS_EXIST';

export const VENDOR_REPORTS = 'VENDOR_REPORTS';
export const NO_VENDOR_REPORTS = 'NO_VENDOR_REPORTS';

export const FILTER_MARKETPLACE_VENDORS_DATA =
  'FILTER_MARKETPLACE_VENDORS_DATA';
export const SELECT_MARKETPLACE_VENDOR = 'SELECT_MARKETPLACE_VENDOR';
export const CLOSE_MARKETPLACE_VENDORS_DIALOG =
  'CLOSE_MARKETPLACE_VENDORS_DIALOG';
export const SELECT_ALL_MARKETPLACE_VENDORS = 'SELECT_ALL_MARKETPLACE_VENDORS';
export const DESELECT_ALL_MARKETPLACE_VENDORS =
  'DESELECT_ALL_MARKETPLACE_VENDORS';
export const OPEN_MARKETPLACE_VENDORS_DIALOG =
  'OPEN_MARKETPLACE_VENDORS_DIALOG';
export const DELETE_SELECTED = 'DELETE_SELECTED';
export const EMAIL_SELECTED = 'EMAIL_SELECTED';
export const ACTION_ERROR = 'ACTION_ERROR';
export const SORT_VENDORS = 'SORT_VENDORS';
