import React from 'react';

const WoocommerceIcon = () => (
    <svg
        className="icon woocommerceicon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 105.71 70.71"
    >
        <g transform="translate(0.000000,105.000000) scale(0.100000,-0.100000)">
            <path d="M533 690 c261 -5 280 -6 293 -24 10 -14 14 -51 14 -133 0 -151 -2
            -153 -144 -153 -99 0 -104 -1 -99 -20 7 -25 2 -25 -47 0 -33 17 -59 20 -170
            20 -172 0 -170 -2 -170 155 0 102 2 117 20 135 11 11 16 20 11 20 -6 0 -17
            -10 -25 -22 -12 -17 -16 -49 -16 -136 0 -107 1 -114 25 -137 23 -24 29 -25
            157 -25 129 0 135 -1 187 -30 30 -17 56 -30 57 -30 2 0 -1 12 -6 26 -6 14 -10
            27 -10 30 0 2 43 4 95 4 88 0 98 2 120 25 24 23 25 30 25 140 0 110 -1 117
            -25 140 l-24 25 -273 -2 -273 -2 278 -6z"/>
            <path d="M457 653 c-14 -13 -7 -25 8 -13 22 18 36 4 25 -26 -6 -14 -10 -33 -9
            -42 0 -9 6 1 13 23 7 22 11 46 8 53 -4 13 -34 17 -45 5z"/>
            <path d="M234 636 c-3 -8 -4 -23 -1 -33 3 -12 5 -8 6 10 1 28 25 38 34 15 3
            -8 6 -6 6 5 1 21 -37 24 -45 3z"/>
            <path d="M521 604 c-12 -15 -21 -34 -21 -43 0 -9 8 -1 17 17 9 19 26 38 37 42
            16 7 17 9 4 9 -9 1 -26 -11 -37 -25z"/>
            <path d="M614 609 c20 -16 26 -29 26 -60 0 -75 -40 -119 -94 -105 -22 5 -37
            30 -50 81 -3 11 -3 2 -1 -20 7 -62 55 -91 107 -64 11 6 35 44 54 84 19 41 44
            81 57 91 16 12 17 15 4 10 -10 -3 -21 -6 -23 -6 -3 0 -14 -19 -24 -42 -16 -36
            -20 -39 -20 -18 0 32 -26 70 -47 70 -11 0 -8 -6 11 -21z"/>
            <path d="M772 618 c8 -7 20 -22 26 -33 9 -15 11 -16 12 -4 0 16 -30 49 -45 49
            -5 0 -2 -6 7 -12z"/>
            <path d="M67 572 l-47 -48 50 -49 c27 -27 50 -45 50 -39 0 5 -18 28 -40 49
            l-40 39 42 43 c23 23 40 45 37 48 -3 2 -26 -17 -52 -43z"/>
            <path d="M362 608 c-5 -7 -20 -33 -33 -58 l-23 -45 -13 55 -12 55 5 -50 c10
            -83 18 -86 53 -17 17 34 36 62 41 62 12 0 14 -5 30 -69 6 -23 14 -40 17 -37 7
            7 -16 85 -30 104 -5 6 -13 12 -17 12 -4 0 -12 -6 -18 -12z"/>
            <path d="M435 590 c-4 -17 -5 -34 -2 -36 2 -3 7 10 11 27 8 42 0 50 -9 9z"/>
            <path d="M978 567 l42 -43 -40 -39 c-22 -21 -37 -41 -35 -44 7 -7 85 70 85 84
            0 13 -72 85 -85 85 -5 0 10 -19 33 -43z"/>
            <path d="M556 571 c-22 -25 -18 -84 6 -89 26 -5 53 44 42 75 -12 34 -26 38
            -48 14z m40 -15 c7 -29 -11 -66 -31 -66 -19 0 -21 60 -3 78 18 18 27 15 34
            -12z"/>
            <path d="M716 568 c-26 -37 -18 -88 15 -88 33 0 53 72 27 98 -17 17 -25 15
            -42 -10z m42 -25 c3 -31 -19 -60 -38 -48 -22 14 -1 90 23 82 7 -2 13 -17 15
            -34z"/>
            <path d="M241 568 c-2 -31 21 -122 35 -145 21 -33 31 -29 62 27 15 27 29 50
            32 50 3 0 13 -18 23 -40 33 -71 83 -77 74 -9 l-4 34 -1 -37 c-1 -21 -6 -38
            -10 -38 -15 0 -40 33 -61 79 l-19 44 -25 -50 c-31 -60 -51 -80 -63 -61 -5 7
            -16 47 -25 88 -10 41 -18 67 -18 58z"/>
            <path d="M472 525 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
            <path d="M800 506 c0 -7 -9 -25 -21 -40 -34 -43 -92 -31 -112 24 -4 12 -6 11
            -6 -2 -1 -27 35 -58 68 -58 20 0 37 9 55 31 23 27 34 59 21 59 -3 0 -5 -6 -5
            -14z"/>
        </g>
    </svg>
);

export default WoocommerceIcon;
