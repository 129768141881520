import { newValidator } from '../../../../../../../utils';

const initialState = {
  open: false,
  pending: false,
  title: {
    value: '',
    isValid: null,
    required: true,
    maxLength: 42,
  },
};

const titleValidator = newValidator(initialState.title);

const createTab = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_CREATE_TAB_UI':
      return {
        ...state,
        open: true,
      };

    case 'CLOSE_CREATE_TAB_UI':
      return {
        ...state,
        open: false,
      };

    case 'CREATE_TAB_TITLE_CHANGE':
      return {
        ...state,
        title: {
          ...state.title,
          value: action.value,
          isValid: titleValidator(action.value),
        },
      };

    case 'CREATING_TAB':
      return {
        ...state,
        pending: true,
      };

    case 'RESET_CREATE_TAB_FORM':
      return initialState;

    default:
      return state;
  }
};

export default createTab;
