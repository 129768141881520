import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { TableContainer } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Trans, useTranslation } from 'react-i18next';

import styles from './style.module.scss';
const StyledTableCell = withStyles(theme => ({
  head: {
    background: '#f0f0f0',
    color: 'black',
  },
  body: {
    fontSize: 14,
    height: '80px',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'white',
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: 600,
  },
});

const CitiesTable = props => {
  const { allStatesNames, type, cities, country } = props;
  const { t } = useTranslation();

  const [selectedState, setSelectedState] = useState(allStatesNames[0]);
  const [selectedcities, setSelectedCities] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    setSelectedState(allStatesNames[0]);
  }, [allStatesNames]);

  useEffect(() => {
    let finalCities = cities[country][selectedState];
    finalCities = Object.entries(finalCities);
    setSelectedCities(finalCities);
  }, [selectedState]);

  const handleStateChange = e => {
    setSelectedState(e.target.value);
  };
  return (
    <>
      <div className={styles.container}>
        <h3 className={styles.title}>
          {type === 4 ? (
            <>
              <Trans>Showing views for cities in</Trans> {selectedState}
            </>
          ) : (
            <>
              <Trans>Showing installs for cities in</Trans> {selectedState}
            </>
          )}
        </h3>
        <Select value={selectedState} onChange={handleStateChange}>
          {allStatesNames.length &&
            allStatesNames.map(state => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
        </Select>
      </div>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Trans>City</Trans>
              </StyledTableCell>
              <StyledTableCell>
                <Trans>Number Of</Trans>{' '}
                {type === 4 ? t('Views') : t('Installs')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedcities &&
              selectedcities.length > 0 &&
              selectedcities.map(city => (
                <StyledTableRow key={city[0]}>
                  <StyledTableCell component="th" scope="row">
                    {city[0]}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {city[1]}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CitiesTable;
