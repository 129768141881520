import emailValidator from 'email-validator';

const hasOwnProperty = (object, prop) =>
  Object.prototype.hasOwnProperty.call(object, prop);

export const newValidator = props => {
  const validators = {};
  let keys;

  if (hasOwnProperty(props, 'required')) {
    validators.required = value =>
      (typeof value === 'string' || typeof value === 'object') &&
      (value !== '' || value.length > 0);
  }

  if (hasOwnProperty(props, 'dropdown')) {
    validators.dropdown = value => !!value.length;
  }

  if (hasOwnProperty(props, 'date')) {
    validators.date = value =>
      typeof value.getMonth === 'function' || value !== '';
  }

  if (hasOwnProperty(props, 'dateRange')) {
    validators.dateRange = value =>
      value &&
      typeof new Date(value[0]).getMonth === 'function' &&
      typeof new Date(value[1]).getMonth === 'function';
  }

  if (hasOwnProperty(props, 'maxLength')) {
    validators.maxLength = value =>
      typeof value === 'string' && value.length <= props.maxLength;
  }

  if (hasOwnProperty(props, 'minLength')) {
    validators.minLength = value =>
      typeof value === 'string' && value.length >= props.minLength;
  }

  if (hasOwnProperty(props, 'dropZoneFile')) {
    validators.dropZoneFile = value =>
      typeof value === 'object' && value !== '' && value.length > 0;
  }

  switch (props.type) {
    case 'email':
      validators.type = email => emailValidator.validate(email);
      break;

    case 'domain':
      validators.type = domain => /^[a-z0-9_]*$/g.test(domain);
      break;

    case 'mobile':
      validators.type = mobile => /^([0-9]{10})$/g.test(mobile);
      break;

    case 'title':
      validators.type = title => !/<.+>|<\/.+>/g.test(title);
      break;

    case 'description':
      validators.type = description => !/<.+>|<\/.+>/g.test(description);
      break;

    case 'international':
      validators.type = internationalMobile =>
        /^(?:[0-9]){6,14}[0-9]$/g.test(internationalMobile);
      break;

    case 'password':
      validators.type = password =>
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g.test(password);
      break;

    default:
    // do nothing
  }

  try {
    keys = Object.keys(validators);
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
  }

  return function validate(value) {
    return keys.reduce((next, current) => {
      if (next === false) return next;

      return validators[current](value);
    }, true);
  };
};
