import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Subheader from '../subheader';
import Search from '../search';

const Header = ({ chunks, title, filter, onChange, dataFilters }) => {
  const { t } = useTranslation();
  return (
    <div className="row middle-xs">
      <div className={dataFilters ? 'col-xs-3' : 'col-xs-2'}>
        <Subheader title={title} />
      </div>

      <div className="col-xs-9 row">
        <div className={dataFilters ? 'col-xs-8' : 'col-xs-10'}>
          <Search
            name="search"
            placeholder={t('Search...')}
            value={filter}
            onChange={val => onChange(val)}
            onClear={() => onChange('')}
          />
        </div>
        <div
          className={dataFilters ? 'col-xs-4' : 'col-xs-1'}
          style={{ alignContent: 'center' }}
        >
          {dataFilters}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  title: PropTypes.element.isRequired,
  filter: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectAll: PropTypes.bool,
  onSelectAllResponses: PropTypes.func,
  onDeselectAllResponses: PropTypes.func,
  actionMenu: PropTypes.element,
  dataFilters: PropTypes.element,
};

Header.defaultProps = {
  actionMenu: null,
  error: '',
  selectAll: false,
  dataFilters: null,
  filter: null,
  onSelectAllResponses: null,
  onDeselectAllResponses: null,
};

export default Header;
