import React from 'react';

import './styles.css';

const Drivers = () => (
  <svg
    className="icon drivers"
    version="1.0"
    viewBox="0 0 754.95337 755.33264"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="matrix(0.1,0,0,-0.1,-162.59724,917.92464)"
      stroke="none"
      id="g18"
    >
      <path
        d="M 5075,9165 C 4508,9111 3993,8950 3525,8681 2621,8161 1977,7309 1730,6305 c -79,-321 -105,-543 -104,-910 0,-298 11,-425 60,-695 258,-1415 1345,-2586 2739,-2950 352,-92 596,-123 975,-124 270,-1 378,7 610,45 1002,163 1901,729 2485,1564 358,512 571,1066 656,1710 32,243 33,655 1,900 -92,706 -329,1289 -748,1845 -474,630 -1163,1105 -1931,1334 -440,131 -958,183 -1398,141 z m 589,-275 c 695,-52 1362,-315 1921,-758 145,-115 421,-388 534,-529 414,-517 657,-1090 753,-1769 29,-212 32,-625 5,-839 -54,-422 -157,-766 -342,-1140 -113,-229 -257,-457 -411,-650 -112,-140 -387,-415 -529,-529 -454,-362 -972,-603 -1524,-710 -250,-49 -387,-61 -676,-60 -358,0 -553,25 -900,113 -437,111 -903,346 -1280,647 -149,118 -449,422 -563,569 -301,387 -495,768 -621,1216 -168,598 -175,1225 -21,1824 323,1257 1348,2254 2607,2536 355,79 694,105 1047,79 z"
        id="path10"
      />
      <path
        d="M 5185,8634 C 4621,8597 4022,8390 3560,8071 3004,7688 2591,7163 2357,6540 c -90,-237 -149,-475 -182,-723 -20,-156 -19,-178 8,-218 48,-73 2,-69 868,-67 l 775,3 42,27 c 48,33 123,125 217,267 153,233 321,358 579,431 408,114 1180,103 1534,-22 247,-87 361,-180 532,-433 110,-162 167,-227 223,-254 43,-21 54,-21 805,-21 827,0 806,-1 850,55 33,42 36,82 13,242 -87,609 -310,1128 -690,1603 -103,130 -373,399 -501,500 -469,370 -1007,598 -1605,681 -136,18 -504,32 -640,23 z m 540,-289 c 966,-113 1815,-681 2284,-1529 108,-196 203,-431 261,-650 30,-113 70,-305 70,-338 0,-17 -37,-18 -634,-18 h -634 l -39,53 c -22,28 -57,79 -78,112 -59,95 -157,215 -227,281 -227,212 -523,313 -1043,355 -182,15 -564,6 -745,-16 -560,-69 -850,-231 -1090,-610 -24,-38 -62,-94 -84,-122 l -41,-53 h -634 -633 l 7,58 c 16,133 81,383 140,546 100,272 260,560 447,800 103,133 308,344 438,453 455,379 1034,623 1620,682 145,14 474,12 615,-4 z"
        id="path12"
      />
      <path
        d="m 2228,5248 c -41,-24 -68,-70 -68,-116 0,-17 7,-84 15,-149 137,-1048 777,-1964 1725,-2466 352,-186 797,-318 1193,-353 65,-5 75,-4 107,18 75,50 70,-29 70,1132 0,1032 0,1038 -21,1075 -37,67 -30,65 -399,72 -326,5 -337,6 -412,31 -153,51 -296,166 -377,302 -19,32 -60,134 -91,226 -31,92 -64,180 -74,195 -37,56 -18,55 -858,55 h -773 z m 1486,-345 c 66,-205 131,-319 251,-439 125,-125 263,-203 450,-254 69,-19 114,-23 328,-27 l 247,-5 v -859 -859 h -21 c -27,0 -256,46 -329,66 -622,172 -1173,534 -1566,1029 -180,227 -366,556 -460,810 -70,192 -154,521 -154,605 0,20 7,20 613,20 h 613 z"
        id="path14"
      />
      <path
        d="m 6960,5259 c -52,-21 -69,-50 -121,-208 -28,-86 -65,-183 -81,-215 -66,-136 -165,-234 -303,-301 -136,-67 -193,-75 -532,-75 -323,0 -338,-3 -375,-71 -17,-32 -18,-86 -18,-1080 V 2263 l 23,-33 c 12,-19 37,-43 55,-53 31,-19 41,-19 147,-8 232,23 426,67 690,156 512,171 999,491 1358,892 467,522 755,1159 828,1830 11,104 11,113 -8,144 -10,19 -34,44 -53,56 l -33,23 -776,-1 c -491,0 -785,-4 -801,-10 z m 1380,-288 c 0,-31 -29,-179 -60,-301 -97,-387 -277,-751 -539,-1095 -96,-126 -355,-388 -487,-492 -337,-267 -709,-454 -1109,-558 -131,-34 -281,-65 -316,-65 -18,0 -19,23 -19,859 v 859 l 243,4 c 221,5 251,8 342,32 342,90 573,320 691,689 l 28,87 h 613 c 594,0 613,-1 613,-19 z"
        id="path16"
      />
    </g>
  </svg>
);

export default Drivers;
