import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { RaisedButton } from 'app/components/buttons';
import Logo from 'app/components/logo';
import { whitelabeledHost } from 'utils/whitelabeledHost';

import './styles.css';

const isWhiteLabeledHost = whitelabeledHost();

const styles = {
  buttonRoot: {
    margin: '12px',
    width: '200px',
  },
  labelStyle: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#414042',
  },
};

const MobileOverlay = ({ onLogout, title, description, content }) => (
  <div className="mobile-overlay background-texture">
    {!isWhiteLabeledHost ? <Logo width={150} /> : null}
    {content ? (
      <div dangerouslySetInnerHTML={{ __html: content }} />
    ) : (
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    )}
    <RaisedButton
      label={<Trans>Log Out</Trans>}
      backgroundColor="rgb(0, 255, 82)"
      style={styles.buttonRoot}
      labelStyle={styles.labelStyle}
      onClick={onLogout}
    />
  </div>
);

MobileOverlay.propTypes = {
  onLogout: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
};

MobileOverlay.defaultProps = {
  title: 'Congratulations on creating your first app!',
  description: `${
    !isWhiteLabeledHost ? 'Beezer is' : 'We are'
  } coming to mobile soon. Until our mobile editing experience is
      ready, visit ${
        !isWhiteLabeledHost ? 'create.beezer.com' : 'us'
      } on your desktop or tablet to customize your
      app.`,
  content: '',
};

export default MobileOverlay;
