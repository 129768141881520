import React from 'react';

const Forum = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 60 60"
    style={{ enableBackground: 'new 0 0 512 512' }}
    xmlSpace="preserve"
  >
    <g transform="matrix(1.4999999999999993,0,0,1.4999999999999993,-14.9997496604919,-14.999999523162828)">
      <path
        d="M44.558 41.406c1.154-.884 1.91-2.261 1.91-3.823 0-2.666-2.167-4.834-4.833-4.834S36.8 34.917 36.8 37.583c0 1.56.756 2.936 1.907 3.82a7.89 7.89 0 0 0-2.068 1.204 9.134 9.134 0 0 0-3.498-2.318 5.46 5.46 0 0 0 2.324-4.466c0-3.014-2.452-5.466-5.466-5.466s-5.466 2.452-5.466 5.466a5.46 5.46 0 0 0 2.324 4.466 9.134 9.134 0 0 0-3.498 2.318 7.89 7.89 0 0 0-2.068-1.204c1.151-.884 1.907-2.26 1.907-3.82 0-2.666-2.168-4.834-4.834-4.834s-4.834 2.168-4.834 4.834c0 1.562.757 2.94 1.911 3.823-2.963 1.17-5.069 4.059-5.069 7.435 0 .434.352.786.785.786H48.842a.785.785 0 0 0 .785-.786c0-3.377-2.106-6.265-5.069-7.435zm-6.187-3.823c0-1.8 1.465-3.264 3.264-3.264s3.264 1.464 3.264 3.264c0 1.797-1.461 3.259-3.258 3.263h-.01a3.267 3.267 0 0 1-3.26-3.263zm-12.267-1.76c0-2.148 1.748-3.896 3.896-3.896s3.896 1.748 3.896 3.896c0 2.148-1.748 3.896-3.896 3.896s-3.896-1.748-3.896-3.896zM15.1 37.583c0-1.8 1.465-3.264 3.264-3.264s3.264 1.464 3.264 3.264a3.267 3.267 0 0 1-3.26 3.263h-.01a3.267 3.267 0 0 1-3.258-3.263zm3.258 4.833h.01a6.351 6.351 0 0 1 4.014 1.42 9.059 9.059 0 0 0-1.465 4.22h-8.927c.388-3.172 3.096-5.638 6.368-5.64zm4.13 5.64c.394-3.796 3.612-6.767 7.511-6.767s7.117 2.97 7.511 6.767zm16.593 0a9.059 9.059 0 0 0-1.465-4.22 6.352 6.352 0 0 1 4.014-1.42h.01c3.273.001 5.98 2.467 6.368 5.64zM17.766 25.682h9.754l1.777 3.554a.785.785 0 0 0 1.404 0l1.778-3.554h9.755a.785.785 0 0 0 .785-.785V11.158a.785.785 0 0 0-.785-.785H17.766a.785.785 0 0 0-.785.785v13.74c0 .433.351.784.785.784zm.785-13.738H41.45v12.168h-9.456a.785.785 0 0 0-.702.434L30 27.13l-1.292-2.584a.785.785 0 0 0-.702-.434h-9.454z"
        fill="currentColor"
        opacity="1"
        data-original="currentColor"
        className=""
      />
    </g>
  </svg>
);

export default Forum;
