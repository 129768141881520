import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NewStaff from './NewStaff';

import { addStaff, closeNewStaffDialog, formFieldChange } from './actions';

const mapStateToProps = state => ({
  ...state.auth.staff.newStaff,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(addStaff(ownProps.match.params.appname)),
  onRequestClose: () => dispatch(closeNewStaffDialog()),
  onFieldChange: (field, value) => dispatch(formFieldChange(field, value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewStaff),
);
