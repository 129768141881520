import firebase from '../../../../../../firebase';
import { objectToArray, arrayToObject } from '../../../../../../utils';

export const GET_ALL_SUBSCRIBERS = 'GET_ALL_SUBSCRIBERS';
export const REQUEST_ALL_SUBSCRIBERS = 'REQUEST_ALL_SUBSCRIBERS';
export const GET_ALL_ATTENDED = 'GET_ALL_ATTENDED';
export const REQUEST_ALL_ATTENDED = 'REQUEST_ALL_ATTENDED';
export const GET_ALL_ITEMS = 'GET_ALL_ITEMS';

const request = () => ({
  type: REQUEST_ALL_SUBSCRIBERS,
});
const requestAttended = () => ({
  type: REQUEST_ALL_ATTENDED,
});

export const getSubscribers = app => dispatch => {
  dispatch(request());

  firebase
    .database()
    .ref(`subscribers/${app}`)
    .on('value', snap => {
      dispatch({
        type: GET_ALL_SUBSCRIBERS,
        value: snap.exists() ? snap.val() : {},
      });
    });
};

export const getAttended = app => dispatch => {
  dispatch(requestAttended());
  // get all events/courses/cpd
  firebase
    .database()
    .ref(`subscriber_attendees/${app}`)
    .on('value', snap => {
      const attended = snap.exists() ? snap.val() : {};
      dispatch({
        type: GET_ALL_ATTENDED,
        value: attended,
      });
    });
};

export const getEventsCoursesCPDs = app => async dispatch => {
  const event = await firebase
    .database()
    .ref(`app_events/${app}/events`)
    .once('value')
    .then(snap => snap.val());
  const cpd = await firebase
    .database()
    .ref(`app_cpds/${app}/cpds`)
    .once('value')
    .then(snap => snap.val());
  const course = await firebase
    .database()
    .ref(`app_courses/${app}/courses`)
    .once('value')
    .then(snap => snap.val());

  dispatch({
    type: GET_ALL_ITEMS,
    value: { event, course, cpd },
  });
};
