import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import WordPress from './WordPress';
import {
  fieldChange,
  testApi,
  fetchWpCategories,
  designChange,
} from './actions';

class WordPressContainer extends Component {
  componentDidMount() {
    this.fetchWpCategories();
  }

  componentWillMount() {
    this.testApi();
  }

  componentDidUpdate(prevProps) {
    this.testApi();
    if (
      prevProps.data.testConnection !== this.props.data.testConnection &&
      this.props.data.testConnection === 'success'
    ) {
      this.props.onFetchWpCategories(this.state.apiSource);
    }
  }

  fetchWpCategories = () => {
    const { apiSource, testConnection } = this.props.data;
    if (apiSource && testConnection === 'success') {
      this.props.onFetchWpCategories(apiSource);
    }
  };

  testApi = () => {
    const { apiKey, apiSource, apiType, testConnection } = this.props.data;

    const apiSourceHasChanged = !(
      this.state && this.state.apiSource === apiSource
    );

    if (apiSourceHasChanged) {
      this.setState({ apiSource, testConnection });
    } else {
      return;
    }

    if (apiType && apiSource) {
      this.props.onTestApi(apiType, apiSource, apiKey);
    }
  };

  render() {
    return <WordPress {...this.props} />;
  }
}

WordPressContainer.propTypes = {
  data: PropTypes.object.isRequired,
  onTestApi: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];

  return {
    ...ownProps,
    pages: layout
      ? layout.childRoutes.map(key => state.editor.data.alwaysData[key])
      : [],
    design: ownProps.component.design,
    appname: ownProps.match.params.appname,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onTestApi: (apiType, apiSource, apiKey) =>
    dispatch(
      testApi(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        apiType,
        apiSource,
        apiKey,
      ),
    ),
  onFetchWpCategories: apiSource =>
    dispatch(
      fetchWpCategories(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        apiSource,
      ),
    ),
  onToggle: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onDesignChange: (field, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        field,
        value,
      ),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WordPressContainer);
