import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useQuery, useQueryClient } from 'react-query';
import { makeStyles } from '@material-ui/styles';
import {
  TableContainer,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { get, set, reduce, keys, pickBy } from 'lodash';
import { downloadCSV } from 'utils';
import ActionMenu from 'app/components/actionMenu';
import { Empty as EmptyIcon } from '../../../../../../app/components/icons';

import { getAllStaff } from '../../api';
import themeStyles from '../../../../../../_export.scss';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-head': {
      color: 'black',
      backgroundColor: '#EAEEFF',
      fontSize: 16,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
    },
    '& .MuiTableCell-body': {
      color: 'black',
      fontSize: 16,
      fontWeight: 400,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
      borderBottom: 'none',
    },
    '& .options-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .MuiCheckbox-root': {
      width: 24,
      height: 24,
    },
    '& .action-menu-container': {
      width: 24,
      height: 24,
    },
  },
});

const StaffTable = ({
  appname,
  onAddStaffMemberDialog,
  onStaffMemberChange,
  allStaffData,
  error,
  isError,
  isLoading,
  isAppAdmin,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [chunks, setChunks] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [selectedMenu, setSelectedMenu] = React.useState({
    open: false,
    key: null,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, key) => {
    setSelectedMenu({ open: true, key });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedMenu({ open: false, key: null });
    setAnchorEl(null);
  };

  const onDeleteSelected = selected => {
    Promise.all(
      selected.map(key =>
        fetch(`${process.env.REACT_APP_BOOKING_API}/delete-staff`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ appname, key }),
        }),
      ),
    ).then(() => {
      queryClient.invalidateQueries('staffMembers');
    });
  };

  const handleEdit = staffMember => {
    onStaffMemberChange(staffMember);
    onAddStaffMemberDialog(true);
    handleClose();
  };

  const onSelect = id => {
    const modifiedChunks = { ...chunks };
    const prevValue = get(modifiedChunks, `${id}.selected`, false);
    set(modifiedChunks, `${id}.selected`, !prevValue);
    setChunks(modifiedChunks);
    const selectedItems = keys(pickBy(modifiedChunks, chunk => chunk.selected));
    setSelected(selectedItems);
  };

  const selectAllStaff = () => {
    const allIds = keys(chunks);
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: true,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected(allIds);
    setSelectAll(true);
  };

  const deselectAllStaff = () => {
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: false,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected([]);
    setSelectAll(false);
  };

  const getCsvData = (selected, allStaffMembers) => {
    const csvColumns = [t('Name'), t('Email'), t('Telephone'), t('Dial code')];
    let data = `${csvColumns.join(',')}\n`;
    selected.forEach(key => {
      data += `${allStaffMembers[key].name},${allStaffMembers[key].email},${allStaffMembers[key].mobile},${allStaffMembers[key].dialCode}\n`;
    });
    return data;
  };

  useEffect(() => {
    if (allStaffData) setChunks(allStaffData);
  }, [allStaffData]);

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  if (isError) {
    return (
      <span>
        <Trans>Error</Trans>: {error.message}
      </span>
    );
  }

  return (
    <div>
      {!allStaffData || Object.keys(chunks).length === 0 ? (
        <div className="empty-state">
          <EmptyIcon />
          <h5>
            <Trans>You haven't added any staff member yet.</Trans>
          </h5>
          <p style={{ display: 'flex', alignItems: 'center' }}>
            <Trans>Click the</Trans>&nbsp;
            <AddCircleIcon />
            &nbsp;<Trans>button to add a new staff member.</Trans>
          </p>
          <p style={{ textAlign: 'center' }}>
            <Trans>
              The first step before you start creating appointments is to add
              one or more Staff Members. You will be able to assign your Staff
              Members to appointments, and they will receive email notifications
              with any appointment updates, e.g. when a new appointment has been
              assigned to them & when an appointment has been cancelled.
            </Trans>
          </p>
          <p style={{ textAlign: 'center' }}>
            <Trans>
              If only you as an admin will be taking booking, you can add
              yourself as a Staff Member.
            </Trans>{' '}
            {!isAppAdmin ? (
              <a
                href="https://beezer.zendesk.com/hc/en-us/articles/5842150159377-Booking-Staff-Members"
                rel="noreferrer noopener"
                target="_blank"
                style={{ color: themeStyles.primaryColor }}
              >
                <Trans>Find out more.</Trans>
              </a>
            ) : (
              ''
            )}
          </p>
        </div>
      ) : (
        <TableContainer
          style={{ maxHeight: 650, borderRadius: 15, boxShadow: 'none' }}
          component={Paper}
        >
          <Table
            stickyHeader
            aria-label="customized table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Email</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Mobile</Trans>
                </TableCell>
                <TableCell>
                  <div className="options-container">
                    <Checkbox
                      disableRipple
                      checked={selectAll || false}
                      onChange={(e, isChecked) =>
                        isChecked ? selectAllStaff() : deselectAllStaff()
                      }
                      style={{ color: themeStyles.primaryColor }}
                    />
                    <ActionMenu
                      selected={selected}
                      onDeleteSelected={() => onDeleteSelected(selected)}
                      onDownloadSelected={() => {
                        downloadCSV(
                          getCsvData(selected, chunks),
                          t('staff-members'),
                        );
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chunks &&
                Object.keys(chunks).map((key, i) => (
                  <TableRow
                    hover
                    key={key}
                    onClick={() => handleEdit({ key, ...chunks[key] })}
                  >
                    <TableCell component="th" scope="row">
                      {chunks[key].name || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {chunks[key].email || '-'}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {chunks[key].dialCode}
                      {chunks[key].mobile}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="options-container">
                        <Checkbox
                          disableRipple
                          checked={chunks[key].selected || false}
                          onClick={e => e.stopPropagation()}
                          onChange={e => {
                            e.stopPropagation();
                            onSelect(key);
                          }}
                          style={{ color: themeStyles.primaryColor }}
                        />
                        <ActionMenu
                          selected={[key]}
                          onEditSelected={() =>
                            handleEdit({ key, ...chunks[key] })
                          }
                          onDeleteSelected={() => onDeleteSelected([key])}
                          onDownloadSelected={() =>
                            downloadCSV(
                              getCsvData([key], chunks),
                              t('inventory'),
                            )
                          }
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default StaffTable;
