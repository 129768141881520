import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { Trans, withTranslation } from 'react-i18next';

import { Tabs, Tab } from '../../../../../components/tabs';
import TextField from '../../../../../../../app/components/textField';
import Toggle from '../../../../../../../app/components/toggle';
import SelectField from '../../../../../../../app/components/selectField';

import SectionHeader from '../../../../../components/sectionHeader';
import ButtonDesignMenu from '../../../../../components/buttonDesignMenu';
import DesignToggle from '../../../../../components/designToggle';
import Container from '../../../../../../../app/components/container';

class Popup extends Component {
  static propTypes = {
    appname: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    design: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
    pages: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onDesignChange: PropTypes.func.isRequired,
    onButtonDesignChange: PropTypes.func.isRequired,
  };

  getDimensions = index =>
    this.dimensions.medium && this.dimensions.medium[index];

  handleChange = prop => value => this.props.onChange(prop, value);
  handleDesign = prop => (e, value) => this.props.onDesignChange(prop, value);
  handleChangeLinkSection = (prop, value) => this.props.onChange(prop, value);
  handleToggle = prop => (e, isToggled) => this.props.onChange(prop, isToggled);
  onRadioChange = event =>
    this.props.onChange('popupShowAs', event.target.value);

  handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    this.props.onDesignChange(prop, rgba);
  };

  dropdownNavigationOptions = () =>
    this.props.pages.map(
      (page, index) =>
        page.type === 'Popup' && (
          <MenuItem
            key={page.path}
            value={index ? page.path : '/'}
            primaryText={page.title}
          />
        ),
    );

  render() {
    const {
      data,
      component,
      onDesignChange,
      onButtonDesignChange,
      t,
    } = this.props;

    const sectionToggle = section => (
      <Toggle
        onToggle={this.handleDesign(`/${section}/enabled`)}
        toggled={component.design[section].enabled}
        disabled={!component.design.enabled}
      />
    );

    return (
      <Tabs>
        <Tab label={t('content')}>
          <Container>
            <SectionHeader title={t('Popup Page')} tooltip="" />
            <SelectField
              label={t('Select Popup Page')}
              value={data.popup}
              options={this.dropdownNavigationOptions()}
              onChange={(index, payload) => this.handleChange('popup')(payload)}
            />

            <SectionHeader title={t('Display popup on')} tooltip="" borderTop />
            <RadioButtonGroup
              className="radio-button-group"
              label={t('Show Popup on')}
              name="showPopupAs"
              onChange={(el, val) => this.handleChange('showPopupAs')(val)}
              valueSelected={data.showPopupAs}
            >
              {[
                { label: t('Startup'), value: 'startup' },
                { label: t('Click'), value: 'click' },
              ].map(item => (
                <RadioButton
                  key={item.value}
                  value={item.value}
                  label={item.label}
                  style={{
                    fontSize: 12,
                    lineHeight: 22,
                  }}
                />
              ))}
            </RadioButtonGroup>
            <TextField
              id="buttonText"
              value={data.buttonText}
              onChange={this.handleChange('buttonText')}
              placeholder={t('Button Text')}
              hidden={data.showPopupAs === 'startup'}
            />

            {data.showPopupAs === 'startup' ? (
              <>
                <SectionHeader title={t('Popup visible to')} borderTop />
                <Toggle
                  label={t('Logged In users only')}
                  toggled={data.toggle_loggedInUsersOnly}
                  onToggle={this.handleToggle('toggle_loggedInUsersOnly')}
                  className="full-width toggle-days-sec"
                />

                <SectionHeader
                  title={t('Popup Frequency')}
                  tooltip={t(
                    'Choose how often a popup appears for a user ( -1 = infinite )',
                  )}
                  borderTop
                />

                <RadioButtonGroup
                  className="radio-wrapper"
                  label={t('Show Popup on')}
                  name="popupFrequencyType"
                  onChange={(el, val) =>
                    this.handleChange('popupFrequencyType')(val)
                  }
                  valueSelected={data.popupFrequencyType}
                  style={{
                    width: '100%',
                    padding: '0 1.125rem',
                    margin: '1.125rem 0',
                  }}
                >
                  <RadioButton
                    id="everytime"
                    value="everytime"
                    label={t('Everytime')}
                  />
                  <RadioButton
                    id="sessionbased"
                    value="sessionbased"
                    label={t('Session Based')}
                    disabled={!data.toggle_loggedInUsersOnly}
                  />
                  <RadioButton
                    id="frequencybased"
                    value="frequencybased"
                    label={t('Frequency Based')}
                  />
                </RadioButtonGroup>

                <TextField
                  id="popupFrequency"
                  type="number"
                  value={data.popupFrequency}
                  onChange={this.handleChange('popupFrequency')}
                  placeholder={t('Frequency')}
                  hidden={data.popupFrequencyType === 'everytime'}
                />
              </>
            ) : null}
          </Container>
        </Tab>
        <Tab label={t('design')}>
          <Container>
            <DesignToggle
              component={component}
              onDesignChange={onDesignChange}
            />
            <SectionHeader
              title={t('Button')}
              actions={sectionToggle('buttonDesign')}
              tooltip={t('Define the styles for your button')}
              borderTop
            />
            <ButtonDesignMenu
              component={component}
              onDesignChange={onButtonDesignChange}
            />
          </Container>
        </Tab>
        <Tab
          label={t('layout')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    );
  }
}

export default withTranslation()(Popup);
