import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import Engage from './Engage';
import { appHasComponent } from '../../utils';
import { isAppSubjectToNotificationsCredit } from '../../utils/appDetails';
import { sendPush } from './modules/push/actions';

import { loadContent } from './actions';

import { loadDiyComponents } from '../../app/actions';
import { toggleBlogModal } from '../engage/modules/Blog/actions';

class EngageContainer extends Component {
  componentDidMount() {
    this.props.loadContent();
  }

  render() {
    return <Engage {...this.props} />;
  }
}

EngageContainer.propTypes = {
  loadContent: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const enabled = appHasComponent(state.engage.structure, 'Notification');
  const isSubjectToCredit = isAppSubjectToNotificationsCredit(
    _get(
      state,
      `account.subscriptions.items[${ownProps.match.params.appname}].plan`,
    ),
    state.account.organisation.trial_expired,
  );
  const paths = ownProps.location.pathname.split('/');
  return {
    ...ownProps,
    ...state.engage.push,
    componentsPending: state.engage.structure.pending,
    isSubjectToCredit,
    credit: state.account.organisation.push_notifications_credit || 0,
    enabled,
    isPushNotification:
      paths[paths.length - 1] === 'push' ||
      paths[paths.length - 1] === 'history',
    isNotHistory:
      paths[paths.length - 1] === 'push' &&
      paths[paths.length - 1] !== 'history',
    isIntegration: state.account.organisation.integration !== null,
    subscription:
      state.account.subscriptions.items[ownProps.match.params.appname] || null,
    diyComponents: state.app.addons.diyComponents || null,
    isBlog: paths[paths.length - 1] === 'blog',
    blogModalOpen: state.engage.blog.modalOpen,
    engageSectionsAccess: state.account.profile.sectionsAccess.engage,
  };
};

const mapDisptachToProps = (dispatch, ownProps) => ({
  openEngageDialog: () => false,
  loadContent: () => {
    dispatch(loadDiyComponents(ownProps.match.params.appname));
    dispatch(loadContent(ownProps.match.params.appname));
  },
  onSubmit: message => dispatch(sendPush(message)),
  toggleBlogModal: value => dispatch(toggleBlogModal(value)),
});

export default connect(mapStateToProps, mapDisptachToProps)(EngageContainer);
