import React from 'react';

import './styles.css';

const Engage = () => (
  <svg
    className="icon engage"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 51.13 31.81"
  >
    <title>Engage</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M24.93,23.15V14.81A5.76,5.76,0,0,0,20,9.13a4.81,4.81,0,1,0-4.17,0,5.76,5.76,0,0,0-4.91,5.68v9.5h14ZM14.62,4.81a3.31,3.31,0,1,1,3.31,3.31A3.31,3.31,0,0,1,14.62,4.81ZM12.43,21.58V14.81a4.26,4.26,0,0,1,4.25-4.25h2.5a4.26,4.26,0,0,1,4.25,4.25v8h-11Z"
        />
        <path
          className="cls-1"
          d="M41.18,23.15V14.81a5.76,5.76,0,0,0-4.92-5.68,4.81,4.81,0,1,0-4.17,0,5.76,5.76,0,0,0-4.91,5.68v9.5h14ZM30.87,4.81a3.31,3.31,0,1,1,3.31,3.31A3.31,3.31,0,0,1,30.87,4.81ZM28.68,21.58V14.81a4.26,4.26,0,0,1,4.25-4.25h2.5a4.26,4.26,0,0,1,4.25,4.25v8h-11Z"
        />
        <path
          className="cls-1"
          d="M43.2,19l.36.1a.57.57,0,0,0,.22,0A.75.75,0,0,0,44,17.65l-.36-.11A.75.75,0,1,0,43.2,19Z"
        />
        <path
          className="cls-1"
          d="M46.93,20.42a.84.84,0,0,0,.34.08.75.75,0,0,0,.34-1.42c-.22-.12-.46-.23-.71-.34a.75.75,0,1,0-.61,1.37C46.51,20.21,46.73,20.31,46.93,20.42Z"
        />
        <path
          className="cls-1"
          d="M4,26.38c-.21-.12-.41-.24-.6-.37a.76.76,0,0,0-1,.21.74.74,0,0,0,.21,1c.21.14.44.28.68.42a.74.74,0,0,0,.37.1A.75.75,0,0,0,4,26.38Z"
        />
        <path
          className="cls-1"
          d="M6.22,19.68a.75.75,0,0,0-.54-1.4L5,18.57a.76.76,0,0,0-.39,1,.75.75,0,0,0,.69.45A.72.72,0,0,0,5.55,20Z"
        />
        <path
          className="cls-1"
          d="M3.07,21.31a.76.76,0,0,0,.17-1,.75.75,0,0,0-1.05-.17,7,7,0,0,0-.65.52.76.76,0,0,0-.06,1.06.78.78,0,0,0,.56.25.73.73,0,0,0,.5-.19C2.7,21.59,2.88,21.45,3.07,21.31Z"
        />
        <path
          className="cls-1"
          d="M7.25,27.82l-.69-.24A.75.75,0,0,0,6,29l.73.26a.67.67,0,0,0,.24,0,.75.75,0,0,0,.24-1.46Z"
        />
        <path
          className="cls-1"
          d="M50.75,24.19a.74.74,0,0,0-1,.28,3.46,3.46,0,0,1-.35.49A.74.74,0,0,0,49.46,26a.7.7,0,0,0,.49.19.74.74,0,0,0,.56-.26,4.27,4.27,0,0,0,.52-.73A.74.74,0,0,0,50.75,24.19Z"
        />
        <path
          className="cls-1"
          d="M10.79,28.81l-.72-.17a.75.75,0,0,0-.36,1.45l.75.18.17,0a.76.76,0,0,0,.73-.59A.74.74,0,0,0,10.79,28.81Z"
        />
        <path
          className="cls-1"
          d="M1.5,23.69a.75.75,0,0,0-1.5.12,3.56,3.56,0,0,0,.23,1,.75.75,0,0,0,.7.46.72.72,0,0,0,.28,0,.75.75,0,0,0,.41-1A1.52,1.52,0,0,1,1.5,23.69Z"
        />
        <path
          className="cls-1"
          d="M40.3,28.89l-.72.16a.75.75,0,0,0,.14,1.48h.15l.75-.16a.75.75,0,0,0-.32-1.47Z"
        />
        <path
          className="cls-1"
          d="M29.26,30.24l-.74,0a.75.75,0,0,0,0,1.5h0l.75,0a.75.75,0,0,0-.06-1.5Z"
        />
        <path
          className="cls-1"
          d="M33,30l-.74.06a.76.76,0,0,0-.69.81.74.74,0,0,0,.75.69h.06l.76-.07a.74.74,0,0,0,.67-.81A.75.75,0,0,0,33,30Z"
        />
        <path
          className="cls-1"
          d="M36.66,29.55l-.74.11a.75.75,0,0,0,.1,1.49h.1l.75-.11a.75.75,0,1,0-.21-1.49Z"
        />
        <path
          className="cls-1"
          d="M14.43,29.49l-.73-.11a.75.75,0,1,0-.25,1.47l.75.13h.12a.75.75,0,0,0,.74-.63A.76.76,0,0,0,14.43,29.49Z"
        />
        <path
          className="cls-1"
          d="M47.2,26.57l-.64.32a.75.75,0,0,0-.35,1,.73.73,0,0,0,.67.43.88.88,0,0,0,.32-.07l.71-.36a.75.75,0,1,0-.71-1.32Z"
        />
        <path
          className="cls-1"
          d="M43.86,27.94l-.71.22a.75.75,0,0,0,.22,1.47.76.76,0,0,0,.21,0l.74-.23a.75.75,0,0,0-.46-1.43Z"
        />
        <path
          className="cls-1"
          d="M18.11,30l-.74-.07a.74.74,0,0,0-.82.66.75.75,0,0,0,.66.83l.76.08H18a.75.75,0,0,0,.07-1.5Z"
        />
        <path
          className="cls-1"
          d="M21.82,30.22l-.74,0a.76.76,0,0,0-.79.71.75.75,0,0,0,.7.79l.76,0h0a.75.75,0,0,0,0-1.5Z"
        />
        <path
          className="cls-1"
          d="M49.6,22.45a.75.75,0,0,0,.62.32.74.74,0,0,0,.61-1.17,4.33,4.33,0,0,0-.56-.67.75.75,0,0,0-1.06,0,.75.75,0,0,0,0,1.06A4.24,4.24,0,0,1,49.6,22.45Z"
        />
        <path
          className="cls-1"
          d="M25.54,30.31h-.75a.75.75,0,0,0,0,1.5h.76a.75.75,0,0,0,0-1.5Z"
        />
        <path
          className="cls-1"
          d="M9.94,17.74A.76.76,0,0,0,9,17.21l-.37.1a.75.75,0,0,0,.2,1.47.71.71,0,0,0,.2,0l.35-.1A.76.76,0,0,0,9.94,17.74Z"
        />
      </g>
    </g>
  </svg>
);

export default Engage;
