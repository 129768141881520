import { connect } from 'react-redux';

import Booking from './Booking';
import { designChange } from './actions';
import { toggleBooking } from '../../../../../../ecommerce/modules/ecommerceMenu/actions';

const mapStateToProps = (state, ownProps) => {
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];

  return {
    ...ownProps,
    googleAnalytics: state.app.googleAnalytics,
    pages: layout
      ? layout.childRoutes.map(key => state.editor.data.alwaysData[key])
      : [],
    design: ownProps.component.design,
    layoutType: ownProps.component.layout,
    appname: ownProps.match.params.appname,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDesignChange: (field, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        field,
        value,
      ),
    ),
  toggleBooking: v => dispatch(toggleBooking(ownProps.match.params.appname, v)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
