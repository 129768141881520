import React from 'react';

const AppIcon = () => (
  <svg
    className="icon app-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30.72 30.02"
  >
    <title>App Icon</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M13.25,5.88a7.37,7.37,0,0,1,7.37,7.37h1.5a8.87,8.87,0,1,0-8.87,8.87v-1.5a7.37,7.37,0,0,1,0-14.74Z"
        />
        <path
          class="cls-1"
          d="M1.5,13.25a11.75,11.75,0,0,1,23.5,0h1.5A13.25,13.25,0,1,0,13.25,26.5V25A11.76,11.76,0,0,1,1.5,13.25Z"
        />
        <path
          class="cls-1"
          d="M24.6,21.06,28,17.63,14.79,14.08l3.54,13.24,3.44-3.43L27.9,30l2.82-2.83Zm-2.83.71-2.66,2.65-2.2-8.22,8.22,2.2-2.66,2.66,6.13,6.13-.7.7Z"
        />
      </g>
    </g>
  </svg>
);

export default AppIcon;
