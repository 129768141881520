import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';

import { FlatButton } from '../../../../app/components/buttons';

import './styles.scss';

const FileAndUrlDialog = ({
  open,
  onCancel,
  openFileSelector,
  url,
  onInputChange,
  handleFileChange,
}) => {
  const actions = [
    <FlatButton label="Cancel" onClick={onCancel} secondaryWhite />,
  ];
  return (
    <Dialog
      title="Add An Image"
      open={open}
      actions={actions}
      actionsContainerStyle={{
        textAlign: 'center',
        padding: 16,
      }}
      bodyStyle={{
        position: 'relative',
        overflowY: 'auto',
        padding: '0px 24px',
        minHeight: '350px',
      }}
      contentStyle={{
        textAlign: 'center',
        maxHeight: '100%',
        transform: 'translate(0px, 32px)',
      }}
      onRequestClose={onCancel}
      autoScrollBodyContent
    >
      <div>
        <div className="url_upload_container">
          <input
            className="url_upload_input"
            placeholder="Enter a url"
            value={url}
            onChange={e => onInputChange(e.target.value)}
          />
          <button className="url_upload_button" onClick={handleFileChange}>
            Upload Via Url
          </button>
        </div>
        <h3 className="url_upload_or_text">OR</h3>
        <button className="url_upload_button_file" onClick={openFileSelector}>
          Upload File
        </button>
      </div>
    </Dialog>
  );
};

FileAndUrlDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  openFileSelector: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
};

export default FileAndUrlDialog;
