import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import { useTranslation, Trans } from 'react-i18next';
import { FlatButton } from '../buttons';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const actions = (action, onClose, resetState) => [
  <FlatButton
    style={{ margin: 20 }}
    label={<Trans>Cancel</Trans>}
    secondaryWhite
    onClick={() => onClose()}
  />,
  <FlatButton
    style={{ margin: 20 }}
    label={<Trans>Confirm</Trans>}
    primary
    onClick={() => {
      action();
      onClose();
      resetState();
    }}
  />,
];

const ApprovalDialog = ({ open, onClose, dialogTitle, action }) => {
  const [message, setMessage] = useState('');
  const classes = useStyles();

  const onFieldChange = e => {
    setMessage(e.target.value);
  };

  const resetState = () => setMessage('');
  return (
    <Dialog
      actions={
        action !== null
          ? actions(() => action(message), onClose, resetState)
          : [actions(action, onClose)[0]]
      }
      open={open}
      onRequestClose={() => onClose()}
      style={{ textAlign: 'center' }}
      actionsContainerStyle={{ textAlign: 'center' }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <FormControl className={classes.margin} fullWidth>
        <InputLabel htmlFor="subject" style={{ left: '-15px' }}>
          <Trans>Message</Trans>
        </InputLabel>
        <Input id="subject" value={message} onChange={onFieldChange} />
      </FormControl>
    </Dialog>
  );
};

ApprovalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  action: PropTypes.func,
};

ApprovalDialog.defaultProps = {
  action: null,
  message: '',
  open: false,
};

export default ApprovalDialog;
