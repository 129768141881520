import {
  NEW_STAFF_FIELD_CHANGE,
  OPEN_NEW_STAFF_DIALOG,
  CLOSE_NEW_STAFF_DIALOG,
  REGISTRATION_ERROR,
  ADD_NEW_STAFF,
  NEW_STAFF_ADDED,
} from './actions';

const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  firstName: {
    value: '',
    isValid: null,
  },
  lastName: {
    value: '',
    isValid: null,
  },
  email: {
    value: '',
    isValid: null,
  },
  password: {
    value: '',
    isValid: null,
  },
};

const newStaffReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_NEW_STAFF_DIALOG:
      return {
        ...state,
        open: true,
      };

    case CLOSE_NEW_STAFF_DIALOG:
      return {
        ...state,
        open: false,
      };

    case NEW_STAFF_FIELD_CHANGE:
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };

    case REGISTRATION_ERROR:
      return {
        ...state,
        error: action.err,
        pending: false,
      };

    case ADD_NEW_STAFF:
      return {
        ...state,
        pending: true,
      };

    case NEW_STAFF_ADDED:
      return initialState;

    default:
      return state;
  }
};

export default newStaffReducer;
