import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import EnhancedButton from 'material-ui/internal/EnhancedButton';
import IconButton from 'material-ui/IconButton';
import HtmlToText from 'html-to-text';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Delete, Drag } from '../icons';
import './styles.css';
import locked from './locked.png';

const fieldId = [
  '-MBsA0V9u4eKHU8aRFDh',
  '-MEXsV8uPEPQrL5Cxqy0',
  '-MEXzMQp6Ys-AYPO1_Hg',
  '-MD_Cf2cn07K_E0mwQSv',
];

const styles = {
  button: {
    display: 'block',
    width: 'calc(100% - 24px)',
    borderRaidus: 6,
    padding: 0,
    margin: 6,
  },
  disabledIconContainer: {
    verticalAlign: 'middle',
  },
  disabledIcon: {
    marginRight: 16,
    width: 16,
  },
  iconButton: {
    display: 'block',
    height: 24,
    width: 24,
    padding: 3,
  },
};

/**
 * This is a custom version of the material-ui ListItem
 * Created specifically for the design requireents of the Beezer platform
 */
class ComponentItem extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    isSpecialComponent: PropTypes.bool,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onDuplicate: PropTypes.func,
    openCreateComponentUI: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    isSpecialComponent: false,
    label: 'Untitled',
    onDuplicate: () => {},
    openCreateComponentUI: () => {},
  };

  static contextTypes = {
    muiTheme: PropTypes.object.isRequired,
  };

  handleDelete = e => {
    e.stopPropagation();
    this.props.onDelete();
  };

  handleTouchTap = (e, label) => {
    if (this.props.disabled && !this.props.isSpecialComponent) {
      const componentMap = {
        PayNow: 'Pay Now',
        'Canva Widget': 'Canva',
      };
      this.props.openCreateComponentUI(componentMap[label] || label);
      // this.props.history.push(
      //   `/${this.props.match.params.appname}/choose_plan`,
      // );
      return;
    }

    e.stopPropagation();
    this.props.onClick();
  };

  handleDuplicateButtonClick = event => {
    // Stop the event from bubbling up to the list-item
    event.stopPropagation();
    this.props.onDuplicate();
  };

  getLabel = label => {
    if (!label) {
      return 'Untitled';
    }

    if (label === 'Html') {
      return label.toUpperCase();
    } else if (label === 'Feedback') {
      return 'Video Feedback';
    } else if (label.includes('https://')) {
      return label.split(' ')[0];
    }
    return label;
  };

  render() {
    let { label } = this.props;
    const { disabled, id } = this.props;

    label = HtmlToText.fromString(label, {
      wordwrap: 130,
    });
    const disabledIndicator = (
      <IconButton
        tooltip="Premium Component"
        style={styles.disabledIconContainer}
      >
        <img
          className="disabled-icon"
          src={locked}
          style={styles.disabledIcon}
          alt=""
        />
      </IconButton>
    );

    return (
      <EnhancedButton
        onClick={e => this.handleTouchTap(e, label)}
        style={styles.button}
        containerElement={<div id={id} className="component-item" />}
      >
        <div className="component-item-inner-div">
          <span className="button-group">
            {!(fieldId.indexOf(id) > -1) && (
              <IconButton
                tooltip="Delete"
                style={styles.iconButton}
                onClick={this.handleDelete}
              >
                <Delete className="small" />
              </IconButton>
            )}
            <IconButton
              className="hover-icon-button"
              tooltip="Duplicate"
              style={styles.iconButton}
              onClick={this.handleDuplicateButtonClick}
            >
              <FileCopyOutlinedIcon color="primary" fontSize="small" />
            </IconButton>
          </span>
          <span className="component-item-label">
            {this.getLabel(label)}
            {disabled ? disabledIndicator : null}
          </span>
          <Drag />
        </div>
      </EnhancedButton>
    );
  }
}

export default withRouter(ComponentItem);
