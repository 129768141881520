import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

const iconStyle = {
  height: 18,
  width: 18,
  fill: '#e39b00',
  fillRule: 'evenodd',
};
/* eslint-disable max-len */
const Warning = () => (
  <SvgIcon viewBox="0 0 78.561 78.561" style={iconStyle}>
    <g>
      <g>
        <circle cx="39.28" cy="57.772" r="3.632" />
        <path
          d="M38.792,48.347c1.104,0,2-0.896,2-2v-19c0-1.104-0.896-2-2-2s-2,0.896-2,2v19C36.792,47.451,37.688,48.347,38.792,48.347z    "
          fill="#e39b00"
        />
        <path
          d="M46.57,11.542l-0.091-0.141c-1.852-2.854-3.766-5.806-7.199-5.806c-3.578,0-5.45,2.994-7.26,5.891    c-0.009,0.014-0.065,0.104-0.074,0.119L0.278,65.266C0.096,65.574,0,65.735,0,66.092c0,3.896,3.135,6.874,6.988,6.874h64.585    c3.854,0,6.988-2.979,6.988-6.874c0-0.357-0.096-0.614-0.277-0.921L46.57,11.542z M71.573,68.966H6.988    c-1.461,0-2.717-0.951-2.95-2.394l31.374-53.061c1.554-2.487,2.572-3.963,3.868-3.963c1.261,0,2.457,1.87,3.843,4.006    l31.399,53.007C74.29,68.003,73.034,68.966,71.573,68.966z"
          fill="#e39b00"
        />
      </g>
    </g>
  </SvgIcon>
);

export default Warning;
