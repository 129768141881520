import React from 'react';

const RadioStreaming = () => (
  <svg
    className="icon"
    viewBox="0 0 384 384.00029"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m336 96h-199.761719l173.378907-65.015625c8.269531-3.113281 12.472656-12.328125 9.359374-20.601563-3.097656-8.269531-12.335937-12.453124-20.601562-9.367187l-253.734375 95.152344c-24.90625 1.746093-44.640625 22.496093-44.640625 47.832031v192c0 26.472656 21.527344 48 48 48h288c26.472656 0 48-21.527344 48-48v-192c0-26.472656-21.527344-48-48-48zm16 240c0 8.824219-7.175781 16-16 16h-288c-8.824219 0-16-7.175781-16-16v-192c0-8.808594 7.160156-15.984375 15.96875-16 .007812 0 .023438.007812.03125.007812.03125 0 .054688-.007812.089844-.007812h287.910156c8.824219 0 16 7.175781 16 16zm0 0" />
    <path d="m144 160c-44.113281 0-80 35.886719-80 80s35.886719 80 80 80 80-35.886719 80-80-35.886719-80-80-80zm0 128c-26.472656 0-48-21.527344-48-48s21.527344-48 48-48 48 21.527344 48 48-21.527344 48-48 48zm0 0" />
    <path d="m304 160h-32c-8.832031 0-16 7.167969-16 16s7.167969 16 16 16h32c8.832031 0 16-7.167969 16-16s-7.167969-16-16-16zm0 0" />
    <path d="m304 224h-32c-8.832031 0-16 7.167969-16 16s7.167969 16 16 16h32c8.832031 0 16-7.167969 16-16s-7.167969-16-16-16zm0 0" />
    <path d="m304 288h-32c-8.832031 0-16 7.167969-16 16s7.167969 16 16 16h32c8.832031 0 16-7.167969 16-16s-7.167969-16-16-16zm0 0" />
  </svg>
);

export default RadioStreaming;
