const initialState = {
  enabled: false,
  diaryEnabled: false,
  eventsEnabled: false,
  hasEnabledAuth: false,
  diaryPublicAccess: false,
};

const structure = (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVE_AUTH_STATUS': {
      return {
        ...state,
        enabled: action.value,
        type: action.authType,
      };
    }
    case 'RECEIVE_AUTH_TOGGLE_STATUS': {
      return {
        ...state,
        hasEnabledAuth: action.value,
      };
    }

    case 'DIARY_TOGGLE_FIELD': {
      console.log('Reducer DIARY_TOGGLE_FIELD' + ' Action =' + action.value);
      return {
        ...state,
        diaryEnabled: action.value,
      };
    }

    case 'EVENTS_TOGGLE_FIELD': {
      console.log('Reducer EVENTS_TOGGLE_FIELD' + ' Action =' + action.value);
      return {
        ...state,
        eventsEnabled: action.value,
      };
    }

    case 'RECEIVE_GROUPS':
      const diaryPublicAccess = action.value.default.diaryPublicAccess || false;
      return {
        ...state,
        diaryPublicAccess,
      };

    default:
      return state;
  }
};

export default structure;
