import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Sidebar, { SidebarItem } from '../../../../app/components/sidebar';

import {
  Contacts as ContactsIcon,
  ShareViaSms as ShareViaSmsIcon,
  ShareViaEmail as ShareViaEmailIcon,
  History as HistoryIcon,
  QR as QRIcon,
  Widget as WidgetIcon,
  SocialMedia as SocialMediaIcon,
} from '../../components/icons';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import ViewApp from '../../../../app/components/buttons/viewApp';

class DistributeMenu extends React.Component {
  activeCheck = prefix => {
    return (
      this.props.location.pathname ===
      `/${this.props.match.params.appname}/distribute/${prefix}`
    );
  };

  componentWillMount() {
    this.props.onAddIsPublishedListener();
  }

  componentWillUnmount() {
    this.props.onRemoveIsPublishedListener();
  }

  render() {
    const { match, integration, isPublished, t } = this.props;
    const baseHref = `/${match.params.appname}/distribute`;
    return (
      <Sidebar animate>
        <ViewApp isPublished={isPublished} match={match} />
        <SidebarItem
          label={t('Contacts')}
          href={`${baseHref}/contacts`}
          active={this.activeCheck('contacts')}
          icon={<ContactsIcon />}
        />
        {/* whatsapp */}
        <SidebarItem
          label={t('Share Via Whatsapp')}
          href={`${baseHref}/share_whatsapp`}
          active={this.activeCheck('share_whatsapp')}
          icon={<WhatsAppIcon />}
        />
        <SidebarItem
          label={t('Share Via SMS')}
          href={`${baseHref}/share_sms`}
          active={this.activeCheck('share_sms')}
          icon={<ShareViaSmsIcon />}
        />
        <SidebarItem
          label={t('Share Via Email')}
          href={`${baseHref}/share_email`}
          active={this.activeCheck('share_email')}
          icon={<ShareViaEmailIcon />}
        />
        <SidebarItem
          label={t('Share History')}
          href={`${baseHref}/history`}
          active={this.activeCheck('history')}
          icon={<HistoryIcon />}
        />
        <SidebarItem
          label={t('Social Media')}
          href={`${baseHref}/social`}
          active={this.activeCheck('social')}
          icon={<SocialMediaIcon customDomain={this.props.customDomain} />}
        />
        {!integration && (
          <SidebarItem
            label={t('Widget')}
            href={`${baseHref}/widget`}
            active={this.activeCheck('widget')}
            icon={<WidgetIcon />}
          />
        )}
        <SidebarItem
          label={t('QR Code')}
          href={`${baseHref}/qr`}
          active={this.activeCheck('qr')}
          icon={<QRIcon />}
        />
      </Sidebar>
    );
  }
}

DistributeMenu.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  customDomain: PropTypes.object,
  integration: PropTypes.object,
  isPublished: PropTypes.bool,
};

DistributeMenu.defaultProps = {
  integration: null,
  customDomain: {},
};

export default withTranslation()(DistributeMenu);
