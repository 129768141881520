import React, { useRef } from 'react';
import JoditEditor, { Jodit } from 'jodit-react';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import MenuItem from 'material-ui/MenuItem';
import { Trans, useTranslation } from 'react-i18next';
import { Tabs, Tab } from '../../../../../components/tabs';
import Container from '../../../../../../../app/components/container';
import ColorPicker from '../../../../../components/colorPicker';
import DesignToggle from '../../../../../components/designToggle';
import Range from '../../../../../../../app/components/range';
import Subtitle from '../../../../../../../app/components/subtitle';
import { FlatButton } from '../../../../../../../app/components/buttons';
import SectionHeader from '../../../../../components/sectionHeader';
import Toggle from '../../../../../../../app/components/toggle';
import TextField from '../../../../../../../app/components/textField';
import SelectField from '../../../../../../../app/components/selectField';
import './styles.css';
// props.match.params.appname

const Title = props => {
  const {
    onChange,
    onDesignChange,
    editorContent,
    component,
    design,
    linkOptional = true,
    link,
    tooltip,
    borderTop,
    pages,
  } = props;
  const { t } = useTranslation();

  const editor = useRef(null);
  let editorData = editorContent;

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: t('Start typings...'),
    uploader: {
      insertImageAsBase64URI: true,
    },
    controls: {
      classSpan: {
        list: {
          'pgmol-margin-top': 'Margin Top',
          'pgmol-margin-bottom': 'Margin Bottom',
        },
      },
      fontsize: {
        list: Jodit.atom([
          8,
          9,
          10,
          12,
          14,
          16,
          17,
          18,
          19,
          20,
          21,
          24,
          30,
          36,
          48,
          60,
          72,
          80,
          96,
        ]),
      },
    },
    disablePlugins: ['paste'],
  };

  const updateTextEditorChange = () => {
    onChange('editorContent', editorData);
  };

  const handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    onDesignChange(prop, rgba);
  };

  const handleOptionElChange = prop => (e, isToggled) =>
    onChange(prop, isToggled);

  const hasToggleToEnableLink = () =>
    linkOptional ? (
      <Toggle
        onToggle={(e, isToggled) => {
          handleOptionElChange('link/enabled')(e, isToggled);
        }}
        toggled={link && link.enabled}
      />
    ) : null;

  const isLinkDisabled = () =>
    !link || (link && !link.enabled) || (linkOptional && link && !link.enabled);

  const dropdownNavigationOptions = () =>
    pages &&
    pages.map(
      (page, index) =>
        page.type !== 'Popup' && (
          <MenuItem
            key={page.path}
            value={index ? page.path : '/'}
            primaryText={page.title}
          />
        ),
    );

  const handleLinkChange = (index, payload) => {
    onChange('link/page', payload);
    onChange('link/selectedOption', 'page');
  };

  const handleChange = prop => value => onChange(prop, value);

  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container className="pgmol-newsletter-container">
          <JoditEditor
            ref={editor}
            value={editorData}
            config={config}
            tabIndex={1}
            onChange={newContent => {
              editorData = newContent;
            }}
          />
          <FlatButton
            label={t('Save Changes')}
            disabled={false}
            primary
            onClick={updateTextEditorChange}
          />
          <SectionHeader
            title={t('Navigation Link')}
            actions={hasToggleToEnableLink()}
            tooltip={tooltip}
            borderTop={borderTop}
          />
          <div className="button-link-container">
            <RadioButtonGroup
              className="radio-wrapper"
              name="buttonLink"
              defaultSelected={
                (link && link.linkType && link.linkType.selectedOption) ||
                'page'
              }
              onChange={handleOptionElChange('link/selectedOption')}
            >
              <RadioButton id="page" value="page" disabled={isLinkDisabled()} />
              <RadioButton id="web" value="web" disabled={isLinkDisabled()} />
              <RadioButton
                id="phone"
                value="phone"
                disabled={isLinkDisabled()}
              />
              <RadioButton
                id="email"
                value="email"
                disabled={isLinkDisabled()}
              />
            </RadioButtonGroup>
            <div className="link-radio-option-wrapper">
              <SelectField
                label={t('Select a page')}
                value={link && link.linkType && link.linkType.page}
                options={dropdownNavigationOptions()}
                onChange={handleLinkChange}
                disabled={
                  isLinkDisabled() ||
                  (link &&
                    link.linkType &&
                    link.linkType.selectedOption !== 'page')
                }
              />
              <TextField
                id="custom-button-webpage"
                value={link && link.linkType && link.linkType.web}
                onChange={handleChange('link/web')}
                placeholder={t('Webpage Link')}
                disabled={
                  isLinkDisabled() ||
                  (link &&
                    link.linkType &&
                    link.linkType.selectedOption !== 'web')
                }
              />
              <TextField
                id="custom-button-phone"
                value={link && link.linkType && link.linkType.phone}
                onChange={handleChange('link/phone')}
                placeholder={t('Phone Number')}
                disabled={
                  isLinkDisabled() ||
                  (link &&
                    link.linkType &&
                    link.linkType.selectedOption !== 'phone')
                }
              />

              <TextField
                id="custom-button-email"
                value={link && link.linkType && link.linkType.email}
                onChange={handleChange('link/email')}
                placeholder={t('Email')}
                disabled={
                  isLinkDisabled() ||
                  (link &&
                    link.linkType &&
                    link.linkType.selectedOption !== 'email')
                }
              />
            </div>
          </div>
        </Container>
      </Tab>
      <Tab label={t('design')}>
        <Container>
          <DesignToggle
            component={component}
            onDesignChange={(el, isToggled) => onDesignChange(el, isToggled)}
          />
          <ColorPicker
            label={t('Background Color')}
            color={design.backgroundColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('backgroundColor')}
          />
          <ColorPicker
            label={t('Border Color')}
            color={design.borderColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('borderColor')}
          />
          <Subtitle className={!component.design.enabled}>
            <Trans>Border Width</Trans>
          </Subtitle>
          <Range
            min={0}
            max={100}
            step={1}
            label={
              !design.borderWidth || design.borderWidth === 0
                ? '0px'
                : `${design.borderWidth}px`
            }
            value={design.borderWidth}
            onChange={(el, val) => onDesignChange('borderWidth', val)}
            disabled={!component.design.enabled}
            width="320px"
          />

          <Subtitle className={!component.design.enabled}>
            <Trans>Border Radius</Trans>
          </Subtitle>
          <Range
            min={0}
            max={100}
            step={1}
            label={
              !design.borderRadius || design.borderRadius === 0
                ? '0px'
                : `${design.borderRadius}px`
            }
            value={design.borderRadius}
            onChange={(el, val) => onDesignChange('borderRadius', val)}
            disabled={!component.design.enabled}
            width="320px"
          />

          <Subtitle className={!component.design.enabled}>
            <Trans>Margin Top</Trans>
          </Subtitle>
          <Range
            min={0}
            max={100}
            step={1}
            label={
              !design.marginTop || design.marginTop === 0
                ? '0px'
                : `${design.marginTop}px`
            }
            value={design.marginTop}
            onChange={(el, val) => onDesignChange('marginTop', val)}
            disabled={!component.design.enabled}
            width="320px"
          />

          <Subtitle className={!component.design.enabled}>
            <Trans>Margin Bottom</Trans>
          </Subtitle>
          <Range
            min={0}
            max={100}
            step={1}
            label={
              !design.marginBottom || design.marginBottom === 0
                ? '0px'
                : `${design.marginBottom}px`
            }
            value={design.marginBottom}
            onChange={(el, val) => onDesignChange('marginBottom', val)}
            disabled={!component.design.enabled}
            width="320px"
          />
        </Container>
      </Tab>
      <Tab
        label={t('layout')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
    </Tabs>
  );
};

export default Title;
