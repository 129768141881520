import React from 'react';

const Text = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.95 50.5"
  >
    <title>form</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path class="cls-1" d="M35,50.5H0V0H35ZM1.5,49h32V1.5H1.5Z" />
        <rect class="cls-1" x="6.65" y="9.07" width="13" height="1.5" />
        <path
          class="cls-1"
          d="M24.65,12.57A2.75,2.75,0,1,1,27.4,9.82,2.75,2.75,0,0,1,24.65,12.57Zm0-4A1.25,1.25,0,1,0,25.9,9.82,1.25,1.25,0,0,0,24.65,8.57Z"
        />
        <rect class="cls-1" x="6.65" y="18.07" width="13" height="1.5" />
        <path
          class="cls-1"
          d="M24.65,21.57a2.75,2.75,0,1,1,2.75-2.75A2.75,2.75,0,0,1,24.65,21.57Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,24.65,17.57Z"
        />
        <rect class="cls-1" x="6.65" y="27.07" width="13" height="1.5" />
        <path
          class="cls-1"
          d="M24.65,30.57a2.75,2.75,0,1,1,2.75-2.75A2.75,2.75,0,0,1,24.65,30.57Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,24.65,26.57Z"
        />
        <rect class="cls-1" x="6.65" y="36.07" width="13" height="1.5" />
        <path
          class="cls-1"
          d="M24.65,39.57a2.75,2.75,0,1,1,2.75-2.75A2.75,2.75,0,0,1,24.65,39.57Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,24.65,35.57Z"
        />
      </g>
    </g>
  </svg>
);

export default Text;
