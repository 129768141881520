import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Done from '@material-ui/icons/Done';
import Checkbox from '@material-ui/core/Checkbox';
import XLSX from 'xlsx';
import CreateModal from './modules/createModal';
import TableCheckbox from '../../../../components/tableCheckbox';
import TryAgain from '../../../../../../app/components/tryAgain';
import Header from '../../../../components/header';
import ActionMenu from '../../../../../../app/components/actionMenu';
import ResultsTable from '../../../../../../app/components/resultsTable';
import {
  downloadCSV,
  formatDate,
  objectToArray,
} from '../../../../../../utils';
import { formatPersonalData, formatAllGroupsData } from '../../exportData';

const isCheckboxDisabled = (error, pending) => error && !pending;

class Listing extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    chunks: PropTypes.object,
    getWorkforceList: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func.isRequired,
    onSelectAllTypes: PropTypes.func.isRequired,
    onDeselectAllTypes: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    onFilterTypeData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    filter: PropTypes.string,
    allGroupsData: PropTypes.object.isRequired,
    getWorkforceGroupData: PropTypes.func.isRequired,
    allGroups: PropTypes.object.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
    chunks: {},
    filteredChunks: {},
    items: [],
    query: '',
    selected: [],
    selectAll: false,
  };

  componentDidMount() {
    this.props.getWorkforceGroupData();
  }

  getTitle = (selected, staff) => (
    <span>
      Workforce Members:{' '}
      {selected.length
        ? `${selected.length} / ${Object.keys(staff).length}`
        : Object.keys(staff).length}
    </span>
  );

  getSelectAllCheckBox = () => {
    const {
      error,
      pending,
      selected,
      chunks,
      selectAll,
      onSelectAllTypes,
      onDeselectAllTypes,
    } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllTypes() : onDeselectAllTypes()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: '#261ed5' }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDownloadSelected={() => {
            this.downloadUserData(selected, chunks);
          }}
          text="members"
        />
      </div>
    );
  };

  downloadUserData = (selected, contacts) => {
    const { allGroupsData, allGroups, chunks } = this.props;
    const groupsData = {};
    const formatData = objectToArray(contacts);
    const filterData = formatData.filter(item => selected.includes(item.id));
    const formatAllGroupsInfo = objectToArray(allGroupsData);
    const filterGroupsData = formatAllGroupsInfo.filter(group => {
      //  const groupUsers = objectToArray(group.value);
      const filterGroupUsers = Object.keys(group.value).filter(item =>
        selected.includes(item),
      );
      if (filterGroupUsers.length > 0) {
        filterGroupUsers.map(i => {
          if (group.id) {
            groupsData[`${group.id}`] = {
              ...(groupsData[group.id]
                ? Object.values(groupsData[group.id])
                : {}),
              [i]: group.value[i],
            };
          }
        });
      }
    });

    const data = formatPersonalData(filterData, allGroups);
    const allUsersGroupsData = formatAllGroupsData(
      allGroups,
      selected.length === Object.keys(chunks).length
        ? allGroupsData
        : groupsData,
      chunks,
    );
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, 'Profile');
    //Loop through formatted data
    allUsersGroupsData.map(item =>
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.json_to_sheet(item.data),
        item.name.length > 13 ? `${item.name.substring(0, 10)}...` : item.name,
      ),
    );
    XLSX.writeFile(wb, 'Profile.xlsx');
  };

  getCSVData = (selected, contacts) => {
    const csvColumns = [
      'First Name',
      'Last Name',
      'Disability',
      'Phone Number',
      'Date of Birth',
      'Email',
      'Ethnic Group',
      'Gender',
      'Landline',
      'Local Authority',
      'Organisation',
      'Post Code',
      'Postal Address',
      'Religion',
      'Sexual Orientation',
      'Created',
      'Groups',
      'Verified',
    ];
    let data = `${csvColumns.join(',')}\n`;

    selected.forEach(key => {
      data += `${contacts[key].firstName},${contacts[key].lastName},${
        contacts[key].disability ? contacts[key].disability : 'N/A'
      },${contacts[key].phoneNumber ? contacts[key].phoneNumber : 'N/A'},${
        contacts[key].dob ? new Date(contacts[key].dob).toDateString() : 'N/A'
      },${contacts[key].email},${
        contacts[key].ethnicGroup ? contacts[key].ethnicGroup : 'N/A'
      },${contacts[key].gender ? contacts[key].gender : 'N/A'},${
        contacts[key].landline ? contacts[key].landline : 'N/A'
      },${
        contacts[key].localAuthority ? contacts[key].localAuthority : 'N/A'
      },${contacts[key].organisation ? contacts[key].organisation : 'N/A'},${
        contacts[key].postCode ? contacts[key].postCode : 'N/A'
      },${contacts[key].postalAddress ? contacts[key].postalAddress : 'N/A'},${
        contacts[key].religion ? contacts[key].religion : 'N/A'
      },${
        contacts[key].sexualOrientation
          ? contacts[key].sexualOrientation
          : 'N/A'
      },${formatDate(contacts[key].created_at)},${Object.keys(
        contacts[key].groups,
      )
        .map(group => group)
        .join(' ')},${contacts[key].emailVerified ? 'yes' : 'no'}\n`;
    });

    return data;
  };

  render() {
    const {
      pending,
      items,
      error,
      getWorkforceList,
      chunks,
      onSelect,
      filteredChunks,
      onFilterTypeData,
      onSelectAllTypes,
      onDeselectAllTypes,
      selected,
      onDeleteSelected,
      history,
      match,
    } = this.props;
    const body = error ? (
      <TryAgain
        message="Opps, something went wrong..."
        onRetry={getWorkforceList}
      />
    ) : (
      <ResultsTable
        items={items}
        chunks={filteredChunks}
        error={Object.keys(filteredChunks).length === 0 ? 'yes' : null}
        loading={pending}
        pending={pending}
        emptyMessage="Looks like no workforce members have signed up yet"
        onRowTouchTap={memberId =>
          history.push(`/${match.params.appname}/members/workforce/${memberId}`)
        }
        columns={[
          {
            id: 'firstName',
            header: 'First Name',
            classNames: 'col-xs-3',
          },
          {
            id: 'lastName',
            header: 'Last Name',
            classNames: 'col-xs-2',
          },
          {
            id: 'email',
            header: 'Email',
            classNames: 'col-xs-3',
          },
          {
            id: 'emailVerified',
            header: 'Verified',
            classNames: 'col-xs-1 email-verified',
            containerElement: ({ value }) => <div>{value && <Done />}</div>,
          },
          {
            id: 'created_at',
            header: 'Date Created',
            classNames: 'col-xs-2',
            containerElement: ({ value }) => (
              <span>{value && moment(value).format('DD/MM/YYYY')}</span>
            ),
          },
          {
            id: 'selected',
            header: this.getSelectAllCheckBox(),
            classNames: 'col-xs-1 selected',
            containerElement: ({ value, rowId }) => (
              <>
                <TableCheckbox value={value} onSelect={() => onSelect(rowId)} />
                <ActionMenu
                  isItemOption
                  selected={[rowId]}
                  text="member"
                  onDownloadSelected={() => {
                    this.downloadUserData([rowId], chunks);
                  }}
                />
              </>
            ),
          },
        ]}
      />
    );

    return (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <Header
          {...this.props}
          title={this.getTitle(selected, chunks)}
          onChange={onFilterTypeData}
          onSelectAllResponses={onSelectAllTypes}
          onDeselectAllResponses={onDeselectAllTypes}
          actionMenu={
            <ActionMenu
              selected={selected}
              onDeleteSelected={() => onDeleteSelected(selected, chunks)}
              onDownloadSelected={() => {
                downloadCSV(this.getCSVData(selected, chunks), 'users');
              }}
              text="workforce members"
            />
          }
        />
        {body}
        <CreateModal />
      </section>
    );
  }
}

export default Listing;
