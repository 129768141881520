import i18n from 'i18next';
import firebase from '../../../../firebase';
import { loadAccount } from '../../actions';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';
import {
  submitPayment,
  submitCardDetails,
  reportPaymentSuccess,
  reportPaymentError,
  clearAutopopupNotices,
  subscriptionPaymentRequiresAuthentication,
} from '../payment/actions';
/**
 * submitPaymentForm
 * Submits the payment form to the backend.
 *  */

export const subscribe = ({
  appname,
  component,
  period,
  callback,
  price,
  componentName,
  currency,
  stripe,
  productId,
}) => (dispatch, getState) => {
  const { payment, profile } = getState().account;
  const endpoint = 'subscribe_specialAddons';

  dispatch(submitPayment());
  // response will be present if a card is being submitted with the request
  const paymentHandler = ({ response, useDefaultCard }) => {
    const payload = {
      appname,
      component,
      useDefaultCard,
      period,
      price,
      productId,
      componentName,
      currency: currency.toLowerCase(),
    };

    if (response) {
      payload.token = response.id;
      payload.card = response.card;
    }

    return firebase
      .auth()
      .currentUser.getIdToken()
      .then(token => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('x-access-token', token);

        const options = {
          method: 'post',
          headers,
          body: JSON.stringify(payload),
          mode: 'cors',
        };

        return fetch(
          `${process.env.REACT_APP_PAYMENT_API}/${endpoint}`,
          options,
        );
      })
      .then(async res => {
        if (res.status === 200) {
          dispatch(reportPaymentSuccess());
          dispatch(clearAutopopupNotices());
          // callback after successful payment for redirect
          callback();
        } else if (res.status === 202) {
          const { subscription, diySubToCancel } = await res.json();
          dispatch(
            subscriptionPaymentRequiresAuthentication(
              subscription,
              diySubToCancel,
            ),
          );
        } else {
          dispatch(openSnackbar(i18n.t('Failed to Process, please try again')));
          dispatch({
            type: 'PAYMENT_FAILURE',
            err: i18n.t('Failed to Process, please try again'),
          });
          dispatch(
            reportPaymentError(i18n.t('Failed to Process, please try again')),
          );
        }
      });
  };

  const { useDefaultCard } = payment;
  if (useDefaultCard) {
    return paymentHandler({ useDefaultCard })
      .then(() => dispatch(loadAccount(profile.account)))
      .catch(error => {
        dispatch(openSnackbar(error.message));
        dispatch({
          type: 'PAYMENT_FAILURE',
          err: error.message,
        });
        dispatch(reportPaymentError(error.message));
      });
  }

  return dispatch(submitCardDetails(stripe))
    .then(response => paymentHandler({ useDefaultCard, response }))
    .then(() => dispatch(loadAccount(profile.account)))
    .catch(error => {
      const errorMsg =
        error.message ===
        'Your card was declined. Your request was in live mode, but used a known test card.'
          ? i18n.t('Your card was declined. You are using a known Test Card.')
          : error.message || i18n.t('We are unable to process your payment.');
      dispatch(openSnackbar(errorMsg));
      dispatch({ type: 'PAYMENT_FAILURE', err: errorMsg });
      dispatch(reportPaymentError(errorMsg));
    });
};

export const reloadUserAccount = callback => (dispatch, getState) => {
  const { profile } = getState().account;
  dispatch(loadAccount(profile.account));
  callback.callback();
};
