import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Edit from './Edit';

import {
  updateGroup,
  getGroupDetails,
  updateField,
  deleteField,
  fileUpload,
} from './actions';

const mapStateToProps = state => ({
  ...state.auth.memberGroups.edit.editGroup,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getGroupDetails: () =>
    dispatch(
      getGroupDetails(
        ownProps.match.params.appname,
        ownProps.match.params.groupID,
      ),
    ),
  updateField: (name, fieldID, value) =>
    dispatch(
      updateField(
        ownProps.match.params.appname,
        ownProps.match.params.groupID,
        name,
        fieldID,
        value,
      ),
    ),
  updateGroup: (item, value) =>
    dispatch(
      updateGroup(
        ownProps.match.params.appname,
        ownProps.match.params.groupID,
        item,
        value,
      ),
    ),
  deleteField: id =>
    dispatch(
      deleteField(
        ownProps.match.params.appname,
        ownProps.match.params.groupID,
        id,
      ),
    ),
  onFileUpload: file =>
    dispatch(
      fileUpload(
        ownProps.match.params.appname,
        ownProps.match.params.groupID,
        file,
      ),
    ),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Edit),
);
