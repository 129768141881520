import { connect } from 'react-redux';
import queryString from 'query-string';

import ComponentList from './ComponentList';

import {
  getSpecialComponents,
  openCreateComponentUI,
} from '../create/createComponent/actions';
import {
  rearrangeComponents,
  rearrangeTabComponents,
  getAllLazyData,
  duplicateComponentDialog,
} from './actions';
import { launchRemoveDialog } from '../remove/actions';

const mapStateToProps = (state, ownProps) => {
  const { page, tab } = queryString.parse(ownProps.location.search);
  const pageData = state.editor.data.alwaysData[page];
  const freeTrialPlan = state.account.organisation.trial_expired
    ? null
    : 'accelerate';
  const subscription =
    freeTrialPlan || state.app.appContext.subscription || 'start';

  if (!pageData) {
    return {
      ...ownProps,
      subscription,
      loading: true,
    };
  }

  const currentView = tab
    ? pageData.tabs
      ? pageData.tabs[tab]
      : []
    : pageData;

  if (!currentView) {
    return {
      ...ownProps,
      subscription,
      loading: true,
    };
  }
  return {
    ...ownProps,
    ...currentView,
    subscription,
    ...state.editor.build.create.createComponent,
    diyComponents: state.app.addons.diyComponents || {},
    components:
      currentView.components &&
      currentView.components.map(key => {
        return {
          ...state.editor.data.alwaysData[key],
          lazyData:
            state.editor.data.lazyData[
              state.editor.data.alwaysData[key].lazy_data
            ],

          key,
          label:
            state.editor.data.lazyData[
              state.editor.data.alwaysData[key].lazy_data
            ] &&
            state.editor.data.lazyData[
              state.editor.data.alwaysData[key].lazy_data
            ].hasOwnProperty('label')
              ? state.editor.data.lazyData[
                  state.editor.data.alwaysData[key].lazy_data
                ].label
              : '',
        };
      }),
    isAppSumAccount:
      state.account.organisation.appsumo_id !== '' ? true : false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeComponent: key =>
    dispatch(
      launchRemoveDialog(
        ownProps.match.params.appname,
        {
          ...queryString.parse(ownProps.location.search),
          component: key,
        },
        'COMPONENT',
      ),
    ),
  duplicateComponent: item => dispatch(duplicateComponentDialog(item)),
  rearrangeComponents: (id, siblingId) =>
    dispatch(
      rearrangeComponents(
        id,
        siblingId,
        queryString.parse(ownProps.location.search).page,
        ownProps.match.params.appname,
      ),
    ),
  rearrangeTabComponents: (id, siblingId) =>
    dispatch(
      rearrangeTabComponents(
        id,
        siblingId,
        queryString.parse(ownProps.location.search).tab,
        queryString.parse(ownProps.location.search).page,
        ownProps.match.params.appname,
      ),
    ),

  getAllLazyData: () => dispatch(getAllLazyData(ownProps.match.params.appname)),
  getSpecialComponents: () => dispatch(getSpecialComponents()),
  openCreateComponentUI: label => {
    dispatch(getSpecialComponents());
    dispatch(openCreateComponentUI(label));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ComponentList);
