import React from 'react';
import { Trans } from 'react-i18next';

const Notices = () => (
  <div>
    <h1>
      <Trans>Notices</Trans>
    </h1>
    <p>
      <Trans>
        This component should be a drop-down list which lists the user&apos;s
        notifications. We should implement a flag on each component so the user
        can mark them as &apos;read&apos;, in addition to allowing the user to
        remove notifications from their queue.
      </Trans>
    </p>
  </div>
);

export default Notices;
