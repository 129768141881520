import React from 'react';

const Text = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 41.5 53.5"
  >
    <title>vote</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect
          class="cls-1"
          x="21.09"
          y="25"
          width="11.31"
          height="1.5"
          transform="translate(-10.37 26.46) rotate(-45)"
        />
        <rect
          class="cls-1"
          x="26"
          y="20.09"
          width="1.5"
          height="11.31"
          transform="translate(-10.37 26.46) rotate(-45)"
        />
        <rect
          class="cls-1"
          x="21.09"
          y="10"
          width="11.31"
          height="1.5"
          transform="translate(0.23 22.06) rotate(-45)"
        />
        <rect
          class="cls-1"
          x="26"
          y="5.09"
          width="1.5"
          height="11.31"
          transform="translate(0.23 22.06) rotate(-45)"
        />
        <rect
          class="cls-1"
          x="21.09"
          y="40"
          width="11.31"
          height="1.5"
          transform="translate(-20.98 30.85) rotate(-45)"
        />
        <rect
          class="cls-1"
          x="26"
          y="35.09"
          width="1.5"
          height="11.31"
          transform="translate(-20.98 30.85) rotate(-45)"
        />
        <path
          class="cls-1"
          d="M38.22,50.5H3.28V0H38.22ZM4.78,49H36.72V1.5H4.78Z"
        />
        <path
          class="cls-1"
          d="M37.75,53.5h-34a3.75,3.75,0,0,1,0-7.5H4v1.5H3.75a2.25,2.25,0,0,0,0,4.5h34a2.25,2.25,0,0,0,0-4.5h-.28V46h.28a3.75,3.75,0,0,1,0,7.5Z"
        />
        <rect class="cls-1" x="10.75" y="40" width="9" height="1.5" />
        <rect class="cls-1" x="10.65" y="25.07" width="9" height="1.5" />
        <rect class="cls-1" x="10.56" y="10.14" width="9" height="1.5" />
      </g>
    </g>
  </svg>
);

export default Text;
