import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Chart } from 'react-google-charts';

import styles from './styles.module.scss';
import CitiesTable from '../citiesTable/CitiesTable';
import { Trans, useTranslation } from 'react-i18next';

const Map = props => {
  const {
    onThreeMonthsVisits,
    onSixMonthsVisits,
    onOneYearVisits,
    onThreeMonthsInstalls,
    onSixMonthsInstalls,
    onOneYearInstalls,
    map,
  } = props;
  const { t } = useTranslation();
  const [time, setTime] = useState(3);
  const [type, setType] = useState(4);

  const [data, setData] = useState({});
  const [selectedCountryIso, setSelectedCountryIso] = useState('Worldwide');
  const [options, setOptions] = useState({});

  const [selectedCountryName, setSelectedCountryName] = useState('');
  const [allStatesNames, setAllStatesNames] = useState([]);

  useEffect(() => {
    (async () => {
      await onThreeMonthsVisits();
      await onSixMonthsVisits();
      await onOneYearVisits();
      await onThreeMonthsInstalls();
      await onSixMonthsInstalls();
      await onOneYearInstalls();
    })();
  }, []);

  useEffect(() => {
    if (map.threeMonthsVisits) {
      setData(map.threeMonthsVisits);
    }
  }, [map.threeMonthsVisits]);

  const handleSelectChange = e => {
    setOptions({});
    setSelectedCountryIso('Worldwide');
    setAllStatesNames([]);
    setTime(e.target.value);

    if (type === 4) {
      if (e.target.value === 1) {
        setData(map.oneYearVisits);
      } else if (e.target.value === 2) {
        setData(map.sixMonthsVisits);
      } else if (e.target.value === 3) {
        setData(map.threeMonthsVisits);
      }
    } else if (type === 5) {
      if (e.target.value === 1) {
        setData(map.oneYearInstalls);
      } else if (e.target.value === 2) {
        setData(map.sixMonthsInstalls);
      } else if (e.target.value === 3) {
        setData(map.threeMonthsInstalls);
      }
    }
  };

  const handleSelectTypeChange = e => {
    setSelectedCountryIso('Worldwide');
    setAllStatesNames([]);
    setOptions({});
    setType(e.target.value);
    if (e.target.value === 4) {
      if (time === 1) {
        setData(map.oneYearVisits);
      } else if (time === 2) {
        setData(map.sixMonthsVisits);
      } else if (time === 3) {
        setData(map.threeMonthsVisits);
      }
    } else if (e.target.value === 5) {
      if (time === 1) {
        setData(map.oneYearInstalls);
      } else if (time === 2) {
        setData(map.sixMonthsInstalls);
      } else if (time === 3) {
        setData(map.threeMonthsInstalls);
      }
    }
  };

  const handleCountryChange = e => {
    const index = e.target.value;

    let countryIso;
    let countryName;
    if (index === 'Worldwide') {
      countryIso = 'Worldwide';
      countryName = 'Worldwide';
    } else {
      countryIso = data.countriesIso[index][1];
      countryName = data.countriesIso[index][0];
    }

    setSelectedCountryIso(index);
    if (countryIso === 'Worldwide') {
      setSelectedCountryName('');
      setAllStatesNames([]);
      setOptions({});
      if (type === 4) {
        if (time === 1) {
          setData(map.oneYearVisits);
        } else if (time === 2) {
          setData(map.sixMonthsVisits);
        } else if (time === 3) {
          setData(map.threeMonthsVisits);
        }
      } else if (type === 5) {
        if (time === 1) {
          setData(map.oneYearInstalls);
        } else if (time === 2) {
          setData(map.sixMonthsInstalls);
        } else if (time === 3) {
          setData(map.threeMonthsInstalls);
        }
      }
      return;
    }
    setSelectedCountryName(countryName);
    setOptions({
      region: countryIso,
      displayMode: 'regions',
      resolution: 'provinces',
    });
    if (type === 4) {
      if (time === 1) {
        setData({
          ...data,
          allCountries: Object.entries(map.oneYearVisits.states[countryName]),
        });
        setAllStatesNames(Object.keys(map.oneYearVisits.states[countryName]));
      } else if (time === 2) {
        setData({
          ...data,
          allCountries: Object.entries(map.sixMonthsVisits.states[countryName]),
        });
        setAllStatesNames(Object.keys(map.sixMonthsVisits.states[countryName]));
      } else if (time === 3) {
        setData({
          ...data,
          allCountries: Object.entries(
            map.threeMonthsVisits.states[countryName],
          ),
        });
        setAllStatesNames(
          Object.keys(map.threeMonthsVisits.states[countryName]),
        );
      }
    } else if (type === 5) {
      if (time === 1) {
        setData({
          ...data,
          allCountries: Object.entries(map.oneYearInstalls.states[countryName]),
        });
        setAllStatesNames(Object.keys(map.oneYearInstalls.states[countryName]));
      } else if (time === 2) {
        setData({
          ...data,
          allCountries: Object.entries(
            map.sixMonthsInstalls.states[countryName],
          ),
        });
        setAllStatesNames(
          Object.keys(map.sixMonthsInstalls.states[countryName]),
        );
      } else if (time === 3) {
        setData({
          ...data,
          allCountries: Object.entries(
            map.threeMonthsInstalls.states[countryName],
          ),
        });
        setAllStatesNames(
          Object.keys(map.threeMonthsInstalls.states[countryName]),
        );
      }
    }
  };
  return (
    <div style={{ margin: '44px 12px 44px 24px' }}>
      <div className={styles.selectors}>
        <Select value={time} onChange={handleSelectChange}>
          <MenuItem value={1}>
            <Trans>Past year</Trans>
          </MenuItem>
          <MenuItem value={2}>
            <Trans>Past 6 months</Trans>
          </MenuItem>
          <MenuItem value={3}>
            <Trans>Past 3 months</Trans>
          </MenuItem>
        </Select>
        <Select value={type} onChange={handleSelectTypeChange}>
          <MenuItem value={4}>
            <Trans>Views</Trans>
          </MenuItem>
          <MenuItem value={5}>
            <Trans>Installs</Trans>
          </MenuItem>
        </Select>

        <Select value={selectedCountryIso} onChange={handleCountryChange}>
          <MenuItem name="Worldwide" value="Worldwide">
            <Trans>Worldwide</Trans>
          </MenuItem>
          {data &&
            data.countriesIso &&
            data.countriesIso.length &&
            data.countriesIso.map((country, index) => (
              <MenuItem key={country[1]} name={country[0]} value={index}>
                {country[0]}
              </MenuItem>
            ))}
        </Select>
      </div>
      {data && (
        <Chart
          width={'100%'}
          chartType="GeoChart"
          data={
            Object.keys(data).length
              ? [
                  [t('Country'), type === 4 ? t('Views') : t('Installs')],
                  ...data.allCountries,
                ]
              : [[t('Country'), type === 4 ? t('Views') : t('Installs')]]
          }
          mapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
          rootProps={{ 'data-testid': '1' }}
          options={options}
        />
      )}
      {selectedCountryName && allStatesNames.length ? (
        <CitiesTable
          country={selectedCountryName}
          type={type}
          allStatesNames={allStatesNames}
          cities={data.cities}
        />
      ) : null}
    </div>
  );
};

export default Map;
