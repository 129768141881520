import React from 'react';
import PropTypes from 'prop-types';
import Payment from '../../payment';

const ddoffer31 = ({ onOpenLegalModal, onSubscribe, content }) => (
  <div className="offer-overlay">
    <div>
      <img src={content.offerLogo} alt="dragons den logo" width={300} />
    </div>
    <div className="offer-content">
      <div className="offer-details-container">
        <div>
          <h1>ACCELERATE</h1>
          <p>You should pay</p>
        </div>
        <div>
          <img
            src={content.planLogo}
            height={100}
            width={100}
            alt="plan logo"
          />
        </div>

        <div className="prices">
          <div className="diagonal-line" />
          <span>
            <span className="price-display-bold">£191.88</span>
            <span className="price-display">/year</span>
          </span>
        </div>
      </div>
      <div className="offer-details-container">
        <div>
          <h1>ACCELERATE</h1>
          <p>You pay</p>
        </div>
        <div>
          <img
            src={content.planLogo}
            height={100}
            width={100}
            alt="plan logo"
          />
        </div>

        <div>
          <span className="price-display-bold">£95.94</span>
          <span className="price-display">/year</span>
        </div>
      </div>
      <Payment submitLabel="Subscribe" cost={95.94} onSubmit={onSubscribe} />
    </div>
    <div className="offer-terms-conditions">
      <p>
        By accepting this offer you agree to its&#39;{' '}
        <button
          className="inline-button underline-text"
          onClick={() => onOpenLegalModal('dragons-den-terms')}
        >
          Terms of Service
        </button>
      </p>
    </div>
  </div>
);

ddoffer31.propTypes = {
  onOpenLegalModal: PropTypes.func.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  content: PropTypes.shape({
    planLogo: PropTypes.string.isRequired,
    offerLogo: PropTypes.string.isRequired,
  }).isRequired,
};

export default ddoffer31;
