import firebase from '../../../../firebase';
import i18n from 'i18next';

export const REQUEST_RATING_DATA = 'REQUEST_RATING_DATA';
export const RECEIVE_RATING_DATA = 'RECEIVE_RATING_DATA';
export const WATCHING_RATING_DATA = 'WATCHING_RATING_DATA';
export const RECEIVE_RATING_DATA_UPDATE = 'RECEIVE_RATING_DATA_UPDATE';
export const REMOVING_RATING_DATA_LISTENER = 'REMOVING_RATING_DATA_LISTENER';
export const REQUEST_ALL_RATINGS = 'REQUEST_ALL_RATINGS';
export const RECEIVE_ALL_RATINGS = 'RECEIVE_ALL_RATINGS';
export const REPORT_RATING_DATA_REQUEST_ERROR =
  'REPORT_RATING_DATA_REQUEST_ERROR';
export const FILTER_RATING_DATA = 'FILTER_RATING_DATA';
export const FAVOURITE_SELECTED = 'FAVOURITE_SELECTED';
export const DOWNLOAD_SELECTED = 'DOWNLOAD_SELECTED';
export const DELETE_SELECTED_RATINGS = 'DELETE_SELECTED_RATINGS';
export const DELETE_SELECTED_RATING = 'DELETE_SELECTED_RATING';
export const ACTION_ERROR = 'ACTION_ERROR';
export const SELECT_RATING = 'SELECT_RATING';
export const SELECT_ALL_RATINGS = 'SELECT_ALL_RATINGS';
export const DESELECT_ALL_RATINGS = 'DESELECT_ALL_RATINGS';
export const SORT_RATINGS = 'SORT_RATINGS';

const watchingRatingData = appname => ({
  type: WATCHING_RATING_DATA,
  appname,
});

const receiveRatingDataUpdate = (appname, data) => ({
  type: RECEIVE_RATING_DATA_UPDATE,
  appname,
  data,
});

const offRatingData = appname => ({
  type: REMOVING_RATING_DATA_LISTENER,
  appname,
});

const requestAllRatings = appname => ({
  type: REQUEST_ALL_RATINGS,
  appname,
});

const receiveAllRatings = (appname, data) => ({
  type: RECEIVE_ALL_RATINGS,
  appname,
  data,
});

const reportError = err => ({
  type: REPORT_RATING_DATA_REQUEST_ERROR,
  err: err.message || err,
});

export const filterRatingData = filter => dispatch =>
  dispatch({
    type: FILTER_RATING_DATA,
    payload: filter,
  });

export const favouriteSelectedSuccess = (appname, selected, data) => dispatch =>
  dispatch({
    type: FAVOURITE_SELECTED,
    appname,
    selected,
    data,
  });

export const deleteSelectedSuccess = (appname, selected) => dispatch =>
  dispatch({
    type: DELETE_SELECTED_RATINGS,
    appname,
    selected,
  });

export const deleteSelectedRatingSuccess = (
  appname,
  ratingComponent,
  selected,
  data,
) => dispatch =>
  dispatch({
    type: DELETE_SELECTED_RATING,
    appname,
    ratingComponent,
    data,
  });

export const reportActionError = (
  error,
  appname,
  selected,
  action,
) => dispatch =>
  dispatch({ type: ACTION_ERROR, error, appname, selected, action });

export const watchRatingData = appname => dispatch => {
  dispatch(watchingRatingData(appname));

  return firebase
    .database()
    .ref(`ratings/${appname}`)
    .on('value', snapshot => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        return dispatch(receiveRatingDataUpdate(appname, data));
      }
      dispatch(receiveRatingDataUpdate(appname, {}));
      return dispatch(reportError(i18n.t('No rating responses')));
    });
};

export const removeRatingDataWatcher = appname => dispatch => {
  firebase
    .database()
    .ref(`ratings/${appname}`)
    .off('value');

  return dispatch(offRatingData(appname));
};

export const getAllRatings = appname => dispatch => {
  dispatch(requestAllRatings(appname));

  return firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data`)
    .once('value')
    .then(snapshot => {
      if (snapshot.exists()) {
        const data = [];
        Object.entries(snapshot.val()).forEach(item => {
          if (item[1].type === 'Rating') {
            data[item[0]] = { ...item[1] };
          }
        });
        if (Object.keys(data).length > 0) {
          return dispatch(receiveAllRatings(appname, data));
        }
        return dispatch(reportError(i18n.t('No rating components in app')));
      }
      return dispatch(reportError(i18n.t('No rating components in app')));
    })
    .catch(err => dispatch(reportError(err)));
};

export const selectResponse = key => ({
  type: SELECT_RATING,
  key,
});

export const selectAllResponses = () => ({ type: SELECT_ALL_RATINGS });

export const deselectAllResponses = () => ({ type: DESELECT_ALL_RATINGS });

export const favouriteSelected = (appname, selected) => dispatch =>
  firebase
    .database()
    .ref(`ratings/${appname}`)
    .transaction(responses => {
      if (responses === null) return null;
      Object.entries(responses).forEach(response => {
        if (selected.indexOf(response[0]) >= 0) {
          response[1].favourite = !response[1].favourite;
        }
      });
      return responses;
    })
    .then(data =>
      dispatch(
        favouriteSelectedSuccess(appname, selected, data.snapshot.val()),
      ),
    )
    .catch(error => reportActionError(error, appname, selected, 'favourite'));

export const deleteSelected = (appname, selected) => dispatch =>
  firebase
    .database()
    .ref(`ratings/${appname}`)
    .transaction(responses => {
      if (responses === null) return null;
      const result = {};
      Object.entries(responses).forEach(response => {
        if (selected.indexOf(response[0]) < 0) {
          result[response[0]] = response[1];
        } else {
          result[response[0]] = { totals: [0, 0, 0, 0, 0] };
        }
      });
      return result;
    })
    .then(data =>
      dispatch(deleteSelectedSuccess(appname, selected, data.snapshot.val())),
    )
    .catch(error => reportActionError(error, appname, selected, 'delete'));

export const deleteSelectedRating = (
  appname,
  ratingComponent,
  selected,
) => dispatch =>
  firebase
    .database()
    .ref(`ratings/${appname}/${ratingComponent}`)
    .transaction(response => {
      if (response === null) return null;
      const feedback = {};
      const totals = [...response.totals];
      Object.entries(response.feedback).forEach(rating => {
        if (selected !== rating[0]) {
          feedback[rating[0]] = rating[1];
        } else {
          totals[rating[1].userRating - 1] -= 1;
        }
      });
      const result = { ...response, feedback, totals };
      return result;
    })
    .then(data =>
      dispatch(
        deleteSelectedRatingSuccess(
          appname,
          ratingComponent,
          selected,
          data.snapshot.val(),
        ),
      ),
    )
    .catch(error => reportActionError(error, appname, selected, 'delete'));

export const sortTableData = cols => dispatch =>
  dispatch({ type: SORT_RATINGS, column: cols });
