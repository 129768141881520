export const referrerList = {
  capterra: {
    name: 'capterra',
    logoImageUrl:
      'https://firebasestorage.googleapis.com/v0/b/beezer-c947f.appspot.com/o/capterra%2Fcap_logo_full_color_whitetype_rgb.png?alt=media&token=ef3202fc-6abe-4dfd-90c1-507f8bdb4267',
  },
  tedXGlasgow: {
    name: 'tedXGlasgow',
    logoImageUrl:
      'https://firebasestorage.googleapis.com/v0/b/beezer-c947f.appspot.com/o/tedxlogo%2FImage%20from%20iOS.png?alt=media&token=3b2bd501-b481-42cd-bbc8-fbcc989c8003',
    offer: {
      content: '50% OFF',
      products: ['deluxe'],
      conditionsLink: 'https://www.beezer.com/terms-conditions-50-off-tedx/',
      discountPercent: 50,
      coupons: {
        tedxeverything50offmonthly: {
          billingPeriod: 'monthly',
          name: 'tedxeverything50offmonthly',
        },
        tedxeverything50offyearly: {
          billingPeriod: 'yearly',
          name: 'tedxeverything50offyearly',
        },
      },
    },

    registerPageContent:
      'Welcome to Beezer! You are now ready to start your 14-day free trial and embark on an exciting journey to establish your mobile presence. Once the free trial is over, you will be able to enjoy an exclusive 50% discount on our Everything plan to maximize the potential of your app for half the price.',
  },
  halloweenOffer: {
    name: 'halloweenOffer',
    logoImageUrl:
      'https://firebasestorage.googleapis.com/v0/b/beezer-c947f.appspot.com/o/halloween%2Fhlo.png?alt=media&token=e24fdceb-645c-4ee6-adf9-e6dd1d317430',
    offer: {
      content: '40% OFF',
      products: ['deluxe', 'something', 'something_yearly', 'diy', 'expert'],
      conditionsLink:
        'https://www.beezer.com/terms-conditions-halloween-offer/',
      discountPercent: 40,
      coupons: {
        halloween_40_off_monthly: {
          billingPeriod: 'monthly',
          name: 'halloween_40_off_monthly',
        },
        halloween_40_off_yearly: {
          billingPeriod: 'yearly',
          name: 'halloween_40_off_yearly',
        },
      },
    },
    statusText: 'celebrates',
    registerPageContent:
      'Welcome to Beezer! Halloween is coming and spooky gifts should come with it. To celebrate this hair-rising, spine-chilling event, we are launching a limited-time 40% discount for all of our plans. Remember that this discount will only last until 00:00 am (GMT +01:00) on Sunday, 31st of October. Are you ready to embark on an exciting journey to establish your mobile presence? Get 100% of Beezer for 40% less.',
  },
  adillo: {
    name: 'adillo',
    logoImageUrl: '',
    logoImageUrl2:
      'https://firebasestorage.googleapis.com/v0/b/beezer-c947f.appspot.com/o/adillo%2Funnamed-removebg-preview.png?alt=media&token=d109b095-0e41-409f-98e6-24ca277dd7d7',
    offer: {
      content: '50% OFF',
      products: ['deluxe'],
      conditionsLink: '',
      discountPercent: 50,
      coupons: {
        adilloeverything50offmonthly: {
          billingPeriod: 'monthly',
          name: 'adilloeverything50offmonthly',
        },
        adilloeverything50offyearly: {
          billingPeriod: 'yearly',
          name: 'adilloeverything50offyearly',
        },
      },
    },
    statusText: '',
    headerText: 'Special Offer for Adilo Users',
    subHeaderText:
      'Sign up now and get 50% OFF for the first 12 months on Everything plan!',
    registerPageContent:
      "Welcome to the brave new world of Beezer mobile apps. We're going to get you up and running with your own app in no time.",
    registerPageContent2:
      'With the Beezer platform you can Create, Distribute and Manage your mobile app from one location. Customize your app, choose from a great range of features and start engaging with your audiences on your own mobile channel - what are you waiting for!',
  },
};
