import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';
import { withTranslation } from 'react-i18next';
import { FlatButton } from '../../../../../app/components/buttons';
import { CircularProgress } from '../../../../../app/components/progress';
import ContactForm from './ContactForm';
import UploadForm from './UploadForm';

import './styles.css';

class NewContact extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    data: PropTypes.array,
    error: PropTypes.object,
    duplicateContacts: PropTypes.object,
    pending: PropTypes.bool.isRequired,
    onFileUpload: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    name: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
      .isRequired,
    email: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
      .isRequired,
    mobile: PropTypes.shape({
      value: PropTypes.string,
      isValid: PropTypes.bool,
    }).isRequired,
    onFieldChange: PropTypes.func.isRequired,
    country: PropTypes.shape({
      value: PropTypes.shape({
        code: PropTypes.string.isRequired,
        dialCode: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  static defaultProps = {
    data: null,
    error: null,
    duplicateContacts: {},
  };

  state = {
    importMultipleContacts: false,
  };

  handleClose = () => {
    if (
      this.props.location.cameFromDrivers ||
      this.props.location.type === 'create'
    ) {
      this.props.history.replace();
      this.props.history.push(
        `/${this.props.match.params.appname}/ecommerce/drivers`,
      );
    }
    if (this.props.location.cameFromBookingStaff) {
      this.props.history.replace();
      this.props.history.push(
        `/${this.props.match.params.appname}/ecommerce/bookings/staff`,
      );
    }
    this.setState({ importMultipleContacts: false });
    this.props.onRequestClose();
  };

  render() {
    const {
      open,
      data,
      pending,
      onSubmit,
      onCancel,
      duplicateContacts,
      name,
      email,
      mobile,
      t,
    } = this.props;
    const buttonSubmit = !pending ? (
      !this.state.importMultipleContacts || data ? (
        <FlatButton
          label={data ? t('Import') : t('Create')}
          onClick={() =>
            onSubmit(
              this.props.location.cameFromDrivers || false,
              this.props.location.cameFromBookingStaff || false,
              this.props.history,
            )
          }
          disabled={
            !data &&
            (!name.isValid ||
              mobile.isValid === false ||
              email.isValid === false ||
              (!email.value && !mobile.value))
          }
          primary
        />
      ) : null
    ) : (
      <CircularProgress size={22} />
    );

    const importOptions = !pending ? (
      <div>
        <FlatButton label={t('Cancel')} onClick={onCancel} secondaryWhite />
        <FlatButton
          label={t('Import')}
          onClick={() =>
            onSubmit(
              this.props.location.cameFromDrivers || false,
              this.props.location.cameFromBookingStaff || false,
              this.props.history,
            )
          }
          primary
        />
      </div>
    ) : (
      <CircularProgress size={22} />
    );

    return (
      <Dialog
        open={
          open ||
          (this.props.location.cameFromDrivers &&
            this.props.location.type === 'create') ||
          this.props.location.cameFromBookingStaff
        }
        onRequestClose={this.handleClose}
        autoScrollBodyContent
      >
        <div className="contacts-form">
          <div className="contacts-form-content">
            <div className="list-fieldset import-contacts-container">
              <h5>
                {!this.state.importMultipleContacts
                  ? t('Create Single Contact')
                  : t('Import Multiple Contacts')}
              </h5>
              <div className="modal-controls-container">
                <IconButton onClick={this.handleClose}>
                  <Close />
                </IconButton>
              </div>
              {!this.state.importMultipleContacts ? (
                <ContactForm {...this.props} />
              ) : (
                <UploadForm {...this.props} />
              )}
            </div>
          </div>
          {Object.keys(duplicateContacts).length ? importOptions : buttonSubmit}
          <div className="import-multiple-link">
            <span
              onClick={() =>
                this.setState({
                  importMultipleContacts: !this.state.importMultipleContacts,
                })
              }
              role="button"
              tabIndex={0}
            >
              {!this.state.importMultipleContacts
                ? t('or import multiple contacts')
                : t('or create a single contact')}
            </span>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(NewContact);
