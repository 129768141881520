import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import EngageMenu from './EngageMenu';

const mapStateToProps = (state, ownProps) => {
  const isAppAdmin =
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false;
  const isSubAdminWithMulipleApps =
    (state.account.auth.profile.admin_appnames &&
      Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
    false;
  return {
    ...ownProps,
    isAppAdmin,
    isSubAdminWithMulipleApps,
    integration: state.account.organisation.integration,
    isPublished:
      state.editor.data.isPublished[ownProps.match.params.appname] || false,
  };
};

export default withRouter(connect(mapStateToProps)(EngageMenu));
