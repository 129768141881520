import { isEmpty } from 'lodash';

import firebase from '../../../../firebase';
import { publishApp } from '../../../../app/modules/publishDialog/actions';
import seedComponent from '../../../editor/modules/build/modules/create/createComponent/seedComponent';
import { INTERNAL_APP_PATHS } from '../../../../config/constants';

export const SCAN_BARCODE = 'SCAN_BARCODE';
export const ADD_SCAN_COMPONENT_ERROR = 'ADD_SCAN_COMPONENT_ERROR';
export const ADD_SCAN_PAGE_ERROR = 'ADD_SCAN_PAGE_ERROR';
export const SCAN_PAGE_CREATED = 'SCAN_PAGE_CREATED';
export const SCAN_COMPONENT_ADDED = 'SCAN_COMPONENT_ADDED';
export const REQUEST_USERS = 'REQUEST_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const NO_USERS_EXIST = 'NO_USERS_EXIST';
const ADD_LOYALTY_COMPONENT_ERROR = 'ADD_LOYALTY_COMPONENT_ERROR';
const LOYALTY_PAGE_CREATED = 'LOYALTY_PAGE_CREATED';
const LOYALTY_COMPONENT_ADDED = 'LOYALTY_COMPONENT_ADDED';

const requestUsers = appname => ({
  type: REQUEST_USERS,
  appname,
});

export const getUsers = appname => dispatch => {
  dispatch(requestUsers(appname));

  firebase
    .database()
    .ref(`app_users/${appname}`)
    .on('value', snap => {
      const buyers = {};
      snap.forEach(childSnapshot => {
        if (childSnapshot.hasChild('email')) {
          buyers[childSnapshot.key] = childSnapshot.val();
        }
      });
      if (!isEmpty(buyers)) {
        dispatch({
          type: RECEIVE_USERS,
          value: buyers,
        });
      } else {
        dispatch({
          type: NO_USERS_EXIST,
        });
      }
    });
};

export const scanBarcode = (appname, uid) => async dispatch => {
  const scanRef = firebase
    .database()
    .ref(`app_users/${appname}/${uid}/visitedStamps`);
  const snapshot = await scanRef.once('value');

  if (!snapshot.val()) {
    firebase
      .database()
      .ref(`app_users/${appname}/${uid}`)
      .update({ visitedStamps: 1 });
  } else
    firebase
      .database()
      .ref(`app_users/${appname}/${uid}`)
      .update({ visitedStamps: snapshot.val() + 1 });
  dispatch({ type: SCAN_BARCODE });
};

export const reportAddScannerComponentError = err => ({
  type: ADD_SCAN_COMPONENT_ERROR,
  err,
});

export const reportAddPageError = err => ({
  type: ADD_SCAN_PAGE_ERROR,
  err,
});

const toPath = title =>
  title
    .toLowerCase()
    .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '')
    .replace(/\s+/g, '-')
    .trim();

const createScannerPage = (appname, getState, alwaysDataRef) => {
  const childRoutesRef = firebase
    .database()
    .ref(`apps/${appname}/draft/layout_data/childRoutes`);
  const layoutData = getState().engage.structure.routes || [];
  const form = getState().editor.build.create.createPage;
  const pageTitle = 'Scanner';
  const paths = layoutData ? layoutData.map(item => item.path) : [];
  paths.push(...INTERNAL_APP_PATHS);
  const tabs = null;
  let newPageKey;
  let path = toPath(pageTitle);

  if (paths.indexOf(path) !== -1) {
    path = path.concat('-', Date.now().toString());
  }

  return alwaysDataRef
    .push({
      title: pageTitle,
      type: form.type,
      path,
      tabs,
    })
    .then(pageRef => {
      newPageKey = pageRef.key;

      return childRoutesRef
        .transaction(value => {
          if (!value) return [newPageKey];
          return [...value, pageRef.key];
        })
        .then(() => newPageKey);
    });
};

const createScannerComponent = (appname, pageKey, alwaysDataRef) => {
  const lazyDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data`);
  const componentsArrayRef = alwaysDataRef.child(`${pageKey}/components`);

  const scannerComponentSeedData = {
    content: {
      type: 'Scanner',
    },
    layout: 'Standard',
    design: {},
  };

  return lazyDataRef
    .push(scannerComponentSeedData.content)
    .then(snapshot => {
      firebase
        .database()
        .ref(`app_groups/${appname}/not_private`)
        .update({ [snapshot.key]: true });

      return snapshot;
    })
    .then(snapshot =>
      alwaysDataRef.push({
        type: 'Scanner',
        lazy_data: snapshot.key,
        design: scannerComponentSeedData.design,
        layout: scannerComponentSeedData.layout,
      }),
    )
    .then(snapshot => {
      componentsArrayRef.transaction(value => {
        if (!value) return [snapshot.key];

        return [...value, snapshot.key];
      });
      return snapshot.key;
    })
    .then(key => key);
};

export const addScannerComponent = (appname, history) => (
  dispatch,
  getState,
) => {
  const alwaysDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data`);
  createScannerPage(appname, getState, alwaysDataRef)
    .then(newPageKey => {
      dispatch({ type: SCAN_PAGE_CREATED, newPageKey });
      createScannerComponent(appname, newPageKey, alwaysDataRef)
        .then(key => {
          dispatch({ type: SCAN_COMPONENT_ADDED, key });
          dispatch(publishApp(appname));
          history.push(
            history.location.pathname.replace(
              'engage/scan',
              `editor/build/list?page=${newPageKey}&component=${key}`,
            ),
          );
        })
        .catch(err => dispatch(reportAddScannerComponentError(err)));
    })
    .catch(err => dispatch(reportAddPageError(err)));
};

export const updateChecked = (appname, hasScannerAccess, user) => async () => {
  const userRef = firebase.database().ref(`app_users/${appname}/${user}/`);
  await userRef.update({ hasScannerAccess: !hasScannerAccess });
};

export const reportAddLoyaltyComponentError = err => ({
  type: ADD_LOYALTY_COMPONENT_ERROR,
  err,
});

const createLoyaltyPage = (appname, getState, alwaysDataRef) => {
  const childRoutesRef = firebase
    .database()
    .ref(`apps/${appname}/draft/layout_data/childRoutes`);
  const layoutData = getState().engage.structure.routes || [];
  const form = getState().editor.build.create.createPage;
  const pageTitle = 'Loyalty';
  const paths = layoutData ? layoutData.map(item => item.path) : [];
  paths.push(...INTERNAL_APP_PATHS);
  const tabs = null;
  let newPageKey;
  let path = toPath(pageTitle);

  if (paths.indexOf(path) !== -1) {
    path = path.concat('-', Date.now().toString());
  }

  return alwaysDataRef
    .push({
      title: pageTitle,
      type: form.type,
      path,
      tabs,
    })
    .then(pageRef => {
      newPageKey = pageRef.key;

      return childRoutesRef
        .transaction(value => {
          if (!value) return [newPageKey];
          return [...value, pageRef.key];
        })
        .then(() => newPageKey);
    });
};

const createLoyaltyComponent = async (
  appname,
  pageKey,
  alwaysDataRef,
  account,
  palette,
) => {
  const lazyDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data`);
  const componentsArrayRef = alwaysDataRef.child(`${pageKey}/components`);

  const loyaltyComponentSeedData = seedComponent(
    'Loyalty',
    account,
    palette,
    null,
  );

  const snapshot = await lazyDataRef.push(loyaltyComponentSeedData.content);
  // update not_private permissions for new components
  // so that new components will be public when Member Login is enabled
  firebase
    .database()
    .ref(`app_groups/${appname}/not_private`)
    .update({ [snapshot.key]: true });
  const snapshot1 = snapshot;
  const snapshot2 = alwaysDataRef.push({
    type: 'Loyalty',
    lazy_data: snapshot1.key,
    design: loyaltyComponentSeedData.design,
    layout: loyaltyComponentSeedData.layout,
  });
  componentsArrayRef.transaction(value2 => {
    if (!value2) return [snapshot2.key];

    return [...value2, snapshot2.key];
  });
  const key = snapshot2.key;
  return key;
};

export const addLoyaltyComponent = (appname, history) => (
  dispatch,
  getState,
) => {
  const alwaysDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data`);
  createLoyaltyPage(appname, getState, alwaysDataRef)
    .then(newPageKey => {
      dispatch({ type: LOYALTY_PAGE_CREATED, newPageKey });
      const account = getState().account.profile.account;
      const palette = getState().editor.design[appname].palette;
      createLoyaltyComponent(
        appname,
        newPageKey,
        alwaysDataRef,
        account,
        palette,
      )
        .then(key => {
          dispatch({ type: LOYALTY_COMPONENT_ADDED, key });
          dispatch(publishApp(appname));
          history.push(
            history.location.pathname.replace(
              'engage/scan',
              `editor/build/list?page=${newPageKey}&component=${key}`,
            ),
          );
        })
        .catch(err => dispatch(reportAddLoyaltyComponentError(err)));
    })
    .catch(err => dispatch(reportAddPageError(err)));
};
