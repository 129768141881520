import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import OldCheckbox from 'material-ui/Checkbox';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from 'material-ui/MenuItem';
import Dialog from 'material-ui/Dialog';
import ResultsTable from 'app/components/resultsTable';
import SelectField from 'app/components/selectField';
import Header from 'app/components/header';
import ActionMenu from 'app/components/actionMenu';
import NotificationPanel from 'app/components/notificationPanel';
import { CTAButton, SquareButton } from 'app/components/buttons';
import BeezerRadioButton from 'app/components/radiobutton/RadioButton';
import UpgradePlan from 'app/modules/upgradePlan';
import { objectToArray } from '../../../../utils/array';
import {
  USER_CLICKED_BUY_SMS,
  sendSlackNotification,
  PLATFORM_EVENTS,
  downloadCSV,
  formatDate,
} from 'utils';
import ShareSmsForm from '../../components/shareSmsForm';
import './styles.css';
import themeStyles from '../../../../_export.scss';

const isCheckboxDisabled = (error, pending) => error && !pending;

class Sms extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    query: PropTypes.string,
    chunks: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    smsCredit: PropTypes.number,
    integration: PropTypes.shape({
      type: PropTypes.oneOf(['Wix', 'Weebly']),
    }),
    subscription: PropTypes.string,
    loadContacts: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSelectAllContacts: PropTypes.func.isRequired,
    onDeselectAllContacts: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    onEditContact: PropTypes.func.isRequired,
    openDistributeDialog: PropTypes.func.isRequired,
    setType: PropTypes.func.isRequired,
    pageType: PropTypes.string,
    resetSelection: PropTypes.func.isRequired,
    selectAll: PropTypes.bool,
    organisation: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    smsPopup: PropTypes.string,
    handleSmsPopup: PropTypes.func.isRequired,
    smsPopupRequired: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    groupSelected: PropTypes.arrayOf(PropTypes.string),
    users: PropTypes.object,
  };

  static defaultProps = {
    chunks: {},
    integration: {
      type: '',
    },
    items: [],
    groupItems: [],
    original: [],
    pageType: '',
    selectAll: false,
    selected: [],
    smsCredit: 0,
    subscription: null,
    query: '',
    smsPopup: '',
    groupSelected: [],
    users: {},
  };

  componentDidMount() {
    const {
      loadSMSRequest,
      smsPopupRequired,
      chunks,
      loadGroups,
      getGroups,
      getUsers,
    } = this.props;
    smsPopupRequired();
    loadSMSRequest();
    loadGroups();
    getGroups();
    getUsers();
  }

  // NOTE: This update handles resetting the contact selection State
  // on page change, i.e from share_sms to share_email
  componentDidUpdate() {}

  componentWillUnmount() {
    ///this.props.resetSelection();
  }

  getCsvData = (selected, users) => {
    const { t } = this.props;
    const csvColumns = [t('Name'), t('Mobile'), t('Device')];
    let data = `"${csvColumns.join('","')}"\n`;

    selected.forEach(key => {
      data += `${users[key].firstName} ${users[key].lastName},${
        users[key].phoneNumber
      },${users[key].device.os || ''}`;
    });

    return data;
  };

  getSelectAllCheckbox = () => {
    const {
      onSelectAllSMS,
      onDeselectAllSMS,
      onDeleteSelected,
      error,
      pending,
      selected,
      selectAll,
      chunks,
      tableType,
      t,
    } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllSMS() : onDeselectAllSMS()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDownloadSelected={() =>
            downloadCSV(this.getCsvData(selected, chunks), 'sms-users')
          }
          onDeleteSelected={() => onDeleteSelected(tableType, selected)}
          text={t('sms users')}
        />
      </div>
    );
  };

  onButtonClick = ({ notificationTitle, nextPage }) => {
    const { match, subscription, organisation, history } = this.props;

    sendSlackNotification({
      appname: match.params.appname,
      notificationTitle,
      account: organisation.key,
      currentPlan: subscription,
      slackChannel: PLATFORM_EVENTS,
    });
    history.push(nextPage);
  };

  getGroupColumns = () => {
    const { groupFilteredChunks, t } = this.props;
    const groupSuffixClass = groupFilteredChunks
      ? Object.keys(groupFilteredChunks).length < 3
        ? 'fix-left'
        : 'selected'
      : 'fix-left';
    return [
      {
        id: 'name',
        header: t('Name'),
        classNames: 'col-xs-4',
      },
      {
        id: 'users',
        header: t('Users'),
        classNames: 'col-xs-1',
      },
      {
        id: 'mobileProvided',
        header: t('Mobile Provided'),
        classNames: 'col-xs-2',
      },
      {
        id: 'created',
        header: t('Date Created'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) => (
          <span>{value && formatDate(value, 'DD/MM/YYYY')}</span>
        ),
      },
      {
        id: 'lastUpdated',
        header: t('Last Updated'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) => (
          <span>{value && formatDate(value, 'DD/MM/YYYY')}</span>
        ),
      },

      {
        id: 'groupSelected',
        header: this.getSelectAllGroupsCheckBox(),
        classNames: `col-xs-1 ${groupSuffixClass}`,
        containerElement: ({ value, rowId }) => {
          return (
            <>
              <OldCheckbox
                checked={value}
                onClick={e => {
                  e.stopPropagation();
                }}
                onCheck={e => {
                  e.stopPropagation();
                  this.props.onGroupSelect(rowId);
                }}
              />
              <ActionMenu
                selected={[rowId]}
                onDownloadSelected={() => {
                  downloadCSV(
                    this.getGroupsCsvData([rowId], groupFilteredChunks),
                    'groups',
                  );
                }}
                text={t('group')}
              />
            </>
          );
        },
      },
    ];
  };

  getSelectAllGroupsCheckBox = () => {
    const {
      error,
      pending,
      groupSelected,
      groupFilteredChunks,
      groupSelectAll,
      onSelectAllGroups,
      onDeselectAllGroups,
      t,
    } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={groupSelectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllGroups() : onDeselectAllGroups()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={groupSelected}
          onDownloadSelected={() => {
            downloadCSV(
              this.getGroupsCsvData(groupSelected, groupFilteredChunks),
              'groups',
            );
          }}
          text={t('groups')}
        />
      </div>
    );
  };

  getColumns = () => {
    const { chunks, items, onDeleteSelected, tableType, t } = this.props;
    return [
      {
        id: 'firstName',
        header: t('Name'),
        classNames: 'col-xs-4',
        containerElement: ({ value, rowId }) => {
          const row = chunks[rowId];
          const name = row && row.lastName ? `${value} ${row.lastName}` : value;
          return <span>{name}</span>;
        },
      },
      {
        id: 'phoneNumber',
        header: t('Mobile'),
        classNames: 'col-xs-4',
      },
      {
        id: 'device',
        header: t('Device'),
        classNames: 'col-xs-3',
        containerElement: ({ value }) => (value && value.os ? value.os : '-'),
      },
      {
        id: 'selected',
        header: this.getSelectAllCheckbox(),
        classNames: `col-xs-1 ${items.length < 4 ? 'fix-left' : 'selected'}`,
        containerElement: ({ value, rowId }) => {
          return (
            <>
              <OldCheckbox
                checked={value}
                onClick={e => {
                  e.stopPropagation();
                }}
                onCheck={e => {
                  e.stopPropagation();
                  this.props.onSelect(rowId);
                }}
              />
              <ActionMenu
                selected={[rowId]}
                onDownloadSelected={() =>
                  downloadCSV(this.getCsvData([rowId], chunks), 'sms-user')
                }
                onDeleteSelected={() => onDeleteSelected(tableType, [rowId])}
                text={t('sms user')}
              />
            </>
          );
        },
      },
    ];
  };

  tableTypeChange = value => {
    const { setTabletype, loadSMSRequest } = this.props;
    setTabletype(value);
    loadSMSRequest(value);
  };

  getFilter = () => {
    const { t } = this.props;
    const userOpt = [
      { key: 'visitor', val: t('Visitors') },
      { key: 'user', val: t('Users') },
    ];
    return (
      <div className="row middle-xs">
        <div className="col-xs-3" />
        <div className="col-xs-5">
          <div className="col-xs-4">
            <SelectField
              className="offset-top"
              label={t('Select Users')}
              value={this.props.tableType}
              options={userOpt.map(opt => (
                <MenuItem key={opt.key} value={opt.key} primaryText={opt.val} />
              ))}
              onChange={(index, value) => this.tableTypeChange(value)}
            />
          </div>
        </div>
        <div className="col-xs-4">
          <BeezerRadioButton
            id="previewButton"
            className="preview-toggle"
            label1={t('Disable')}
            label2={t('Enable')}
            onChange={() =>
              this.props.handleSmsPopup(
                this.props.smsPopup === 'disable' ? 'enable' : 'disable',
              )
            }
            checked={this.props.smsPopup !== 'disable'}
          />
        </div>
      </div>
    );
  };

  shouldShowUpgradeMessage = () =>
    (!this.props.subscription && (
      <span>
        <Trans>Upgrade your subscription to the</Trans>
        <Link
          className="dark-text decorate"
          to={`/${this.props.match.params.appname}/choose_plan?plan=deluxe`}
        >
          {' '}
          {this.props.integration ? 'premium' : 'everything'}{' '}
          <Trans>plan</Trans>{' '}
        </Link>
        <Trans>to receive</Trans>{' '}
        <strong>
          {' '}
          <Trans>40 SMS</Trans>{' '}
        </strong>
      </span>
    )) || <span />;

  defineSubscriptionTypeOfAction = () => {
    const {
      location,
      openDialog,
      selected,
      subscription,
      smsCredit,
      groupSelected,
      t,
    } = this.props;

    const totalSelected = groupSelected.length + selected.length;

    const primary = (
      <CTAButton
        label={t('Share')}
        height={36}
        minWidth={90}
        disabled={totalSelected === 0}
        onClick={openDialog}
      />
    );
    const secondary = !this.props.isAppAdmin ? (
      subscription ? (
        <SquareButton
          onClick={() => {
            const nextPage = {
              pathname: '/choose_sms_bundle',
              state: { origin: location.pathname },
            };
            this.onButtonClick({
              notificationTitle: USER_CLICKED_BUY_SMS,
              nextPage,
            });
          }}
        >
          <Trans>Buy SMS</Trans>
        </SquareButton>
      ) : (
        <UpgradePlan />
      )
    ) : (
      <></>
    );

    if (smsCredit <= 0) {
      return { secondary };
    }
    return { primary };
  };

  notficationPanel = () => {
    const { selected, smsCredit, groupSelected, t } = this.props;
    const totalSelected = groupSelected.length + selected.length;
    const state =
      totalSelected === 0 || smsCredit < totalSelected
        ? 'secondary'
        : 'primary';

    return (
      <NotificationPanel
        title={t('contacts selected')}
        selected={totalSelected}
        credit={smsCredit}
        state={state}
        message={this.shouldShowUpgradeMessage()}
        actions={this.defineSubscriptionTypeOfAction()}
        accountId={this.props.accountId}
        sms_restricted={this.props.sms_restricted}
      />
    );
  };

  getUsersWithPhoneNumber = groupUsers => {
    if (!groupUsers) {
      return '0';
    }
    const { users } = this.props;
    const groupUserKeys = Object.keys(groupUsers);
    const formatUsers = objectToArray(users);
    const groupUserDetails = formatUsers.filter(item =>
      groupUserKeys.includes(item.id),
    );

    const usersWithPhoneNumber = groupUserDetails.filter(
      item => item.value.phoneNumber && item.value.receiveSMS,
    );

    return usersWithPhoneNumber.length === 0
      ? '0'
      : usersWithPhoneNumber.length;
  };

  render() {
    const {
      chunks,
      items,
      onEditContact,
      onSearch,
      onSearchGroup,
      pending,
      selectAll,
      query,
      groupQuery,
      onSelectAllSMS,
      onDeselectAllSMS,
      groupDataPending,
      groupFilteredChunks,
      groupItems,
      tableType,
      selected,
      groupSelected,
      groupChunks,
      t,
    } = this.props;

    const selectedContacts = selected.length + groupSelected.length;

    const containerHeight = {
      // height: `calc(100% - 244px)`,
    };

    const tableData = {};
    if (chunks && Object.keys(chunks).length > 0 && items && items.length > 0) {
      items.map(item => {
        const itemDetails = chunks[item];
        tableData[item] = {
          ...itemDetails,
          mobile: `${
            itemDetails.country ? itemDetails.country.dialCode : '44'
          } ${itemDetails.mobile}`,
          country: itemDetails.country && itemDetails.country.name,
        };
      });
    }

    const groupTableData = {};
    if (
      groupFilteredChunks &&
      Object.keys(groupFilteredChunks).length > 0 &&
      groupItems &&
      groupItems.length > 0
    ) {
      groupItems.map(item => {
        const itemDetails = groupFilteredChunks[item];
        groupTableData[item] = {
          ...itemDetails,
          name: `${itemDetails.name ? itemDetails.name : t('Unknown')}`,
          lastUpdated: itemDetails.last_updated && itemDetails.last_updated,
          created: itemDetails.date && itemDetails.date,
          users: itemDetails.users ? Object.keys(itemDetails.users).length : 0,
          mobileProvided: itemDetails.users
            ? this.getUsersWithPhoneNumber(itemDetails.users)
            : '0',
        };
      });
    }

    return (
      <div style={containerHeight} className="sms-engage scroll-container">
        {this.getFilter()}
        <div
          className="table-wrapper"
          style={{ height: tableType === 'visitor' ? '66vh' : '40vh' }}
        >
          <Header
            selectAll={selectAll}
            onSelectAllSMS={onSelectAllSMS}
            onDeselectAllSMS={onDeselectAllSMS}
            actionMenu={<React.Fragment />}
            filter={query}
            title={
              <span>
                <Trans>Total Users</Trans>: <b>{items.length}</b>
              </span>
            }
            onChange={onSearch}
            pending={pending}
            disabled={!items.length}
          />
          <ResultsTable
            items={items}
            chunks={tableData}
            loading={pending}
            onRowTouchTap={onEditContact}
            columns={this.getColumns()}
            pending={pending}
            error={
              items.length === 0 ? t('There are no users to display') : null
            }
            emptyMessage={t('Looks like no one has provided permission yet.')}
          />
        </div>
        {tableType === 'user' && (
          <div className="group-container" styl={{ marginTop: '20px' }}>
            <Header
              actionMenu={<React.Fragment />}
              filter={groupQuery}
              title={
                <span>
                  <Trans>Total Groups</Trans>: <b>{groupItems.length || '0'}</b>
                </span>
              }
              onChange={onSearchGroup}
              pending={groupDataPending}
              disabled={!groupItems.length}
            />

            <ResultsTable
              items={groupItems}
              chunks={groupTableData}
              loading={groupDataPending}
              columns={this.getGroupColumns()}
              pending={groupDataPending}
              error={
                groupItems.length === 0
                  ? t('There are no contacts to display')
                  : null
              }
              emptyMessage={t("Looks like you've not created any groups yet")}
              isCustom={true}
            />
          </div>
        )}
        {(!pending || !groupDataPending) && this.notficationPanel()}
        <Dialog
          open={this.props.open}
          onRequestClose={this.props.closeDialog}
          autoScrollBodyContent
        >
          <ShareSmsForm selectedContacts={selectedContacts} {...this.props} />;
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(Sms);
