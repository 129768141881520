const initialState = {
  customIconsArray: [],
};

const buttonComponent = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_ICONS_ARRAY':
      return {
        ...state,
        customIconsArray: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default buttonComponent;
