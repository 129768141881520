import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getMemberBodies } from './modules/memberBodies/actions';
import StaffLogin from './StaffLogin';
import { openNewEventDialog } from './modules/events/modules/create/actions';
import { openNewCourseDialog } from './modules/courses/modules/create/actions';
import { openNewCPDDialog } from './modules/cpds/modules/create/actions';
import { loadContent } from './actions';
import { appHasComponent } from '../../utils';
class StaffLoginContainer extends Component {
  componentDidMount() {
    this.props.loadContent();
  }

  render() {
    return <StaffLogin {...this.props} />;
  }
}

StaffLoginContainer.propTypes = {
  loadContent: PropTypes.func.isRequired,
  onAddGroup: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const enabled = state.auth.structure.enabled || false;
  const legacyFlag = state.account.organisation.legacy_flag || false;
  const subscription =
    state.account.subscriptions.items[ownProps.match.params.appname] || {};
  const eventComponentEnabled =
    appHasComponent(state.editor.structure, 'EventCalendar') ||
    appHasComponent(state.editor.structure, 'EventCourses');
  return {
    ...ownProps,
    groups: state.auth.groups.data.chunks,
    eventComponentEnabled,
    componentsPending: state.editor.structure.pending,
    role:state.account.auth.profile.role,
    fetchedRoutesData: state.editor.structure.routes.length > 0,
    enabled:
      enabled &&
      (legacyFlag || (subscription && subscription.plan === 'accelerate')),
    isPublished: state.editor.data.isPublished[ownProps.match.params.appname],
    profile: state.account.profile,
  };
};

const mapDisptachToProps = (dispatch, ownProps) => ({
  getStaffMemberBodies: memberBodyIDs =>
    dispatch(getMemberBodies(ownProps.match.params.appname, memberBodyIDs)),
  onAddEvent: () => dispatch(openNewEventDialog(ownProps.match.params.appname)),
  onAddCourse: () =>
    dispatch(openNewCourseDialog(ownProps.match.params.appname)),
  onAddCPD: () => dispatch(openNewCPDDialog(ownProps.match.params.appname)),
  loadContent: () => dispatch(loadContent(ownProps.match.params.appname))
});

export default connect(mapStateToProps, mapDisptachToProps)(StaffLogin);
