import i18n from 'i18next';
import firebase from '../../../../../firebase';
import { toObject } from '../../../../../utils';
import { validateField } from '../validators';
import { countries, countryCodes } from './countries';

const newContactFieldChange = (prop, value, isValid) => ({
  type: 'NEW_CONTACT_FIELD_CHANGE',
  prop,
  value,
  isValid,
});

export const openNewContactDialog = () => ({ type: 'OPEN_NEW_CONTACT_DIALOG' });

export const closeNewListDialog = () => ({ type: 'CLOSE_NEW_CONTACT_DIALOG' });

export const importError = errorText => ({
  type: 'IMPORT_ERROR',
  error: new Error(errorText),
});

export const formFieldChange = (prop, value, other = null) => dispatch => {
  let isValid = value.length > 0 ? validateField[prop](value) : null;

  if (prop === 'mobile' && other !== null && other.code !== 'GB') {
    isValid = value.length > 0 ? validateField.international(value) : null;
  } else if (prop === 'country' && other !== null) {
    const isMobileValid =
      other.length > 0
        ? validateField[value.code !== 'GB' ? 'international' : 'mobile'](other)
        : null;
    dispatch(newContactFieldChange('mobile', other, isMobileValid));
  }

  if (prop === 'name') {
    const isNameValid = !(value.length > 20);
    return dispatch(newContactFieldChange(prop, value, isNameValid));
  }

  return dispatch(newContactFieldChange(prop, value, isValid));
};

const findDuplicates = (jsonData, appname, dispatch) =>
  firebase
    .database()
    .ref(`contacts/${appname}`)
    .once('value', snapshot => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const result = {};
        const newContacts = [];
        Object.entries(data).forEach(contact => {
          jsonData.forEach(newContact => {
            if (newContact.email === contact[1].email) {
              result[contact[0]] = newContact;
            } else {
              newContacts.push(newContact);
            }
          });
        });
        if (Object.keys(result).length > 0) {
          dispatch({ type: 'DUPLICATE_CONTACT', result, newContacts });
        }
      }
    });

const convertCsvToJson = (csv, dispatch) => {
  const lines = /\r\n/g.test(csv)
    ? csv.split('\r\n')
    : /\n/g.test(csv)
    ? csv.split('\n')
    : csv.split('\r');
  const result = [];
  const headers = lines[0].split(',').map(item => item.trim().toLowerCase());

  if (lines.length > 10001) {
    dispatch(
      importError(
        `${i18n.t(
          'The maximum number of contacts that can be imported at once is 10,000, your csv files has',
        )} ${lines.length} ${i18n.t('contacts')}`,
      ),
    );

    return false;
  }

  for (let i = 1; i < lines.length; i += 1) {
    const obj = {};
    const currentline = lines[i].split(',');

    for (let j = 0; j < headers.length; j += 1) {
      obj[headers[j]] = currentline[j];
    }

    if (obj.name.length > 20) {
      dispatch(importError('Name cannot be more than 20 characters.'));
      setTimeout(() => {
        dispatch({
          type: 'IMPORT_ERROR',
          error: null,
        });
      }, 20000);
      return false;
    }
    if (obj.name.includes(',')) {
      obj.name = obj.name.replace(',', '');
    }

    if (obj.email !== undefined) {
      const validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const validPhoneRegex = /^[0-9]{4,15}$/;

      if (obj.email && !obj.email.match(validEmailRegex)) {
        dispatch(
          importError(
            `${i18n.t(
              'The email column contains an invalid email with value',
            )} ${obj.email}. ${i18n.t(
              'Please enter only valid email addresses.',
            )}`,
          ),
        );
        setTimeout(() => {
          dispatch({
            type: 'IMPORT_ERROR',
            error: null,
          });
        }, 20000);
        return false;
      }
      // if (!obj.telephone) {
      //    dispatch(importError("The mobile number(s) can't be blank"));

      //    return false;
      // }
      if (obj.telephone) {
        const fullNumber = obj.telephone
          ? obj.telephone.replace(/[^0-9+]+/g, '')
          : obj.mobile.replace(/[^0-9+]+/g, '');

        const numberWithoutLeadingZeroes = fullNumber.replace(/[^1-9]*/, '');

        const matchingCountry = countryCodes
          .filter(code => numberWithoutLeadingZeroes.startsWith(code))
          .map(item => parseInt(item, 10));

        if (
          obj.telephone !== undefined &&
          !obj.telephone.trim().match(validPhoneRegex)
        ) {
          dispatch(
            importError(
              `${i18n.t(
                'The telephone column contains an invalid phone number with value',
              )} "${obj.telephone}". ${i18n.t(
                'Phone numbers should include a minimum of 4 digits with no special characters or spaces.',
              )}`,
            ),
          );
          setTimeout(() => {
            dispatch({
              type: 'IMPORT_ERROR',
              error: null,
            });
          }, 20000);

          return false;
        }

        // if the country code starts with a 1 then matchingCountry will have more
        // than one entry (eg. [1, 1345] for Cayman Islands).
        // order is not always guaranteed, that's why Math.max is used
        // USA country codes are always formatted like +1 XXX, where XXX is 100-999
        // Thanks to that even if the local number starts with 345, an area code will be needed as well
        // That ensures that if the number starts with 1 and it's the only match
        // we will still be able to send the SMS correctly
        const countryCode = Math.max(...matchingCountry);
        obj.country = {
          code: countries[countryCode].code,
          dialCode: `+${countryCode}`,
          name: countries[countryCode].name,
        };

        obj.mobile = `${numberWithoutLeadingZeroes.replace(
          numberWithoutLeadingZeroes.indexOf('+') >= 0
            ? obj.country.dialCode
            : obj.country.dialCode.replace(/\+/, ''),
          '',
        )}`;
      }
      obj.created = new Date().toISOString();
      result.push(obj);
    }
  }

  return result;
};

export const importList = (appname, accepted) => dispatch => {
  const reader = new FileReader();
  dispatch({ type: 'IMPORT_LIST' });
  reader.onload = e => {
    try {
      const data = convertCsvToJson(e.target.result, dispatch);
      if (data) {
        findDuplicates(data, appname, dispatch).then(() => {
          dispatch({ type: 'RECEIVE_LIST', data });
        });
      }
    } catch (error) {
      dispatch({
        type: 'IMPORT_ERROR',
        error,
      });
    }
  };

  if (accepted.length > 0) {
    reader.readAsText(accepted[0]);
  } else {
    dispatch({
      type: 'IMPORT_ERROR',
      error: new Error(i18n.t('File type not accepted in upload')),
    });
  }
};

export const addContact = (
  appname,
  cameFromDrivers,
  cameFromBookingStaff,
  history,
) => (dispatch, getState) => {
  const {
    name,
    email,
    mobile,
    country,
    data,
  } = getState().distribute.contacts.newContact;

  if (data) {
    dispatch({ type: 'UPLOAD_LIST' });
    const dataRef = firebase.database().ref(`contacts/${appname}`);
    const upload = toObject(data);

    return dataRef.update(upload).then(() =>
      dispatch({
        type: 'LIST_UPLOAD_COMPLETE',
        data: upload,
      }),
    );
  }

  const contact = {
    name: name.value,
    email: email.value,
    mobile: mobile.value,
    country: country.value,
    created: new Date().toISOString(),
    isDriver: cameFromDrivers,
    isBookingStaff: cameFromBookingStaff,
  };

  dispatch({ type: 'ADD_NEW_CONTACT' });

  return firebase
    .database()
    .ref(`contacts/${appname}`)
    .push(contact)
    .then(ref =>
      dispatch({
        type: 'NEW_CONTACT_ADDED',
        key: ref.key,
        data: contact,
      }),
    )
    .then(() => {
      if (cameFromDrivers) {
        history.push(`/${appname}/ecommerce/drivers`);
      }
      if (cameFromBookingStaff) {
        history.push(`/${appname}/ecommerce/bookings/staff`);
      }
    });
};

export const cancelImport = () => dispatch => {
  dispatch({ type: 'CANCEL_IMPORT' });
};

export const overwriteContacts = appname => (dispatch, getState) => {
  const {
    duplicateContacts,
    newContacts,
  } = getState().distribute.contacts.newContact;

  Object.entries(duplicateContacts).forEach(duplicateContact => {
    firebase
      .database()
      .ref(`contacts/${appname}/${duplicateContact[0]}`)
      .update(duplicateContact[1]);
  });

  if (newContacts && newContacts.length)
    newContacts.forEach(newContact => {
      firebase
        .database()
        .ref(`contacts/${appname}`)
        .push(newContact);
    });

  firebase
    .database()
    .ref(`contacts/${appname}`)
    .once('value', snapshot => {
      dispatch({
        type: 'HYDRATE_CONTACTS',
        appname,
        data: snapshot.val(),
      });
      dispatch({
        type: 'IMPORT_COMPLETE',
      });
    });
};
