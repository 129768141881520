import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ProfileFields from './ProfileFields';

import {
  updateToggleField,
  updateCustomField,
  updateCustomToggleField,
  formFieldChange,
  addField,
  fetchCustomUserProfileField,
  handleDelete,
  handleDuplicate,
  closeFieldDialog,
  openFieldDialog,
  handleCustomFieldClick,
} from './actions';

const mapStateToProps = (state, ownProps) => {
  const defaultGroup = state.auth.groups.data.chunks.default;
  return {
    ...ownProps,
    profileFields: defaultGroup ? defaultGroup.profileFields : true,
    firstNameRequired: defaultGroup ? defaultGroup.firstNameRequired : false,
    lastNameRequired: defaultGroup ? defaultGroup.lastNameRequired : false,
    organisationRequired: defaultGroup
      ? defaultGroup.organisationRequired
      : false,
    dobRequired: defaultGroup ? defaultGroup.dobRequired : false,
    roleRequired: defaultGroup ? defaultGroup.roleRequired : false,
    phoneNumberRequired: defaultGroup
      ? defaultGroup.phoneNumberRequired
      : false,
    linkedInAddressRequired: defaultGroup
      ? defaultGroup.linkedInAddressRequired
      : false,
    twitterAddressRequired: defaultGroup
      ? defaultGroup.twitterAddressRequired
      : false,
    websiteAddressRequired: defaultGroup
      ? defaultGroup.websiteAddressRequired
      : false,
    interestsRequired: defaultGroup ? defaultGroup.interestsRequired : false,
    interestsVisible: defaultGroup ? defaultGroup.interestsVisible : false,
    firstNameVisible: defaultGroup ? defaultGroup.firstNameVisible : false,
    lastNameVisible: defaultGroup ? defaultGroup.lastNameVisible : false,
    organisationVisible: defaultGroup
      ? defaultGroup.organisationVisible
      : false,
    dobVisible: defaultGroup ? defaultGroup.dobVisible : false,
    roleVisible: defaultGroup ? defaultGroup.roleVisible : false,
    phoneNumberVisible: defaultGroup ? defaultGroup.phoneNumberVisible : false,
    linkedInAddressVisible: defaultGroup
      ? defaultGroup.linkedInAddressVisible
      : false,
    twitterAddressVisible: defaultGroup
      ? defaultGroup.twitterAddressVisible
      : false,
    websiteAddressVisible: defaultGroup
      ? defaultGroup.websiteAddressVisible
      : false,
    interestsVisible: defaultGroup ? defaultGroup.interestsVisible : false,
    userProfileField: state.auth.settings.profileFields.userProfileField,
    customProfileFields:
      defaultGroup && defaultGroup.customProfileFields
        ? Object.entries(defaultGroup.customProfileFields)
        : [],
    open: state.auth.settings.profileFields.open || false,
    customFieldKey: state.auth.settings.profileFields.customFieldKey || '',
    groupsData: state.auth.groups.data.chunks,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateToggleField: (value, field) =>
    dispatch(updateToggleField(ownProps.match.params.appname, value, field)),
  updateCustomToggleField: (value, field, key) =>
    dispatch(
      updateCustomToggleField(ownProps.match.params.appname, value, field, key),
    ),
  updateCustomField: key =>
    dispatch(updateCustomField(ownProps.match.params.appname, key)),
  onFieldChange: (field, value) => dispatch(formFieldChange(field, value)),
  handleDelete: key =>
    dispatch(handleDelete(ownProps.match.params.appname, key)),
  handleDuplicate: (data, previousKey) =>
    dispatch(handleDuplicate(ownProps.match.params.appname, data, previousKey)),
  handleCustomFieldClick: (key, index) =>
    dispatch(handleCustomFieldClick(key, index)),
  fetchCustomUserProfileField: () =>
    dispatch(fetchCustomUserProfileField(ownProps.match.params.appname)),
  openFieldDialog: () => dispatch(openFieldDialog()),
  closeFieldDialog: () => dispatch(closeFieldDialog()),
  onSubmit: () => dispatch(addField(ownProps.match.params.appname)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileFields),
);
