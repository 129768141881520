import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import EditStaff from './EditStaff';

import {
  updateAccount,
  closeModal,
  formFieldChange,
  getStaffDetails,
} from './actions';

const mapStateToProps = state => ({
  ...state.auth.staff.editStaff,
  chunks: state.auth.staff.data.chunks,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getStaffDetails: () => dispatch(getStaffDetails(ownProps.match.params.staff)),
  onSubmit: () =>
    dispatch(
      updateAccount(
        ownProps.match.params.staff,
        ownProps.history,
        ownProps.match.params.appname,
      ),
    ),
  onRequestClose: () =>
    dispatch(closeModal(ownProps.match.params.appname, ownProps.history)),
  onFieldChange: (field, value) => dispatch(formFieldChange(field, value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditStaff),
);
