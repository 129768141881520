import { connect } from 'react-redux';

import InstagramLogin from './InstagramLogin';

import { recordAccessToken } from './actions';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  recordAccessToken: (code, state, history) => dispatch(recordAccessToken(code, state, history)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstagramLogin);
