import i18n from 'i18next';
import firebase from '../../../../firebase';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';
import { countries } from './countries';

export const openDialog = () => ({ type: 'OPEN_SMS_ENGAGE_DIALOG' });
export const requestGroups = appname => ({
  type: 'REQUEST_GROUPS',
  appname,
});

export const closeDialog = appname => dispatch => {
  dispatch({
    type: 'SET_SMS_ENGAGE_MESSAGE',
    appname,
  });
  dispatch({
    type: 'CLOSE_SMS_ENGAGE_DIALOG',
  });
};

export const dispatchSmsEngage = payload => ({
  type: 'DISPATCH_SMS_ENGAGE',
  payload,
});

export const SmsEngageDispatchComplete = () => ({
  type: 'SMS_ENGAGE_DISPATCH_COMPLETE',
});

export const messageChange = value => ({
  type: 'SMS_ENGAGE_MESSAGE_CHANGE',
  value,
});

export const parseNumber = contact => {
  const num = contact.mobile.replace(/^\+/, '');
  const dialCode = contact.country.dialCode.replace(/^\+/, '');
  const regEx = new RegExp(`^${dialCode}`);

  if (regEx.test(num)) {
    return num;
  }

  // if (contact.country.code === 'GB') {
  //   return num.replace(/^0/, dialCode);
  // }
  return `${dialCode}${num}`;
};

const removeCodeFromPhoneNumber = (number, countryCode) => {
  // Regular expression pattern to match the specified country code
  const countryCodePattern = new RegExp(
    `^${countryCode.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`,
  );

  // Check if the string contains the country code
  if (countryCodePattern.test(number)) {
    // Remove the country code
    return number.replace(countryCodePattern, '');
  }
  return number;
};

export const sendSMS = appname => (dispatch, getState) => {
  const accountKey = getState().account.profile.account;
  const appUsers = getState().auth.users.filteredChunks;

  const sms = getState().engage.sms;

  const receiverName = [];
  const numbers = [];
  sms.data.selected.map(key => {
    receiverName.push(sms.data.chunks[key].firstName);
    if (!numbers.includes(sms.data.chunks[key].phoneNumber)) {
      numbers.push(sms.data.chunks[key].phoneNumber);
    }
  });

  sms.data.groupSelected.forEach(key => {
    const users = sms.data.groupFilteredChunks[key].users
      ? Object.keys(sms.data.groupFilteredChunks[key].users)
      : null;

    if (users) {
      users.map(userID => {
        if (appUsers[userID].phoneNumber && appUsers[userID].receiveSMS) {
          // const countryCode = countries[appUsers[userID].countryCode].dialCode;
          // const mobile = removeCodeFromPhoneNumber(
          //   appUsers[userID].phoneNumber.trim(),
          //   countryCode,
          // );
          const userPhone = appUsers[userID].phoneNumber.trim();

          if (!numbers.includes(userPhone)) {
            numbers.push(userPhone);
            receiverName.push(appUsers[userID].firstName);
          }
        }
      });
    }
  });

  const payload = {
    message: sms.data.message.value,
    account: accountKey,
    appname,
    numbers,
    receiverName,
  };

  dispatch(dispatchSmsEngage(payload));

  return firebase
    .database()
    .ref('queues/sms/tasks')
    .push(payload)
    .then(() => {
      dispatch(SmsEngageDispatchComplete());
      dispatch(
        openSnackbar(
          i18n.t(
            'The SMS Notification has been sent. It will be delivered shortly.',
          ),
        ),
      );
    });
};

export const smsPopupRequired = appName => dispatch =>
  firebase
    .database()
    .ref(`apps/${appName}/show_sms_popup`)
    .on('value', snapshot =>
      dispatch({
        type: 'SMS_POPUP_REQUIRED',
        value: snapshot.val(),
      }),
    );

export const handleSmsPopup = (value, appName) => dispatch => {
  const showSmsPopup =
    value === 'enable' ? { show_sms_popup: true } : { show_sms_popup: false };
  return firebase
    .database()
    .ref(`apps/${appName}`)
    .update(showSmsPopup)
    .then(() => {
      dispatch(
        openSnackbar(
          `${i18n.t('SMS Popup')} ${value.charAt(0).toUpperCase()}${value.slice(
            1,
          )}d.`,
        ),
      );
    });
};

export const loadSMSRequest = (type, appname) => dispatch => {
  dispatch({
    type: 'LOAD_SMS_REQUEST',
    appname,
  });

  const path =
    type === 'visitor'
      ? `visitor_sms_permissions/${appname}`
      : `app_users/${appname}`;

  return firebase
    .database()
    .ref(path)
    .orderByChild(`receiveSMS`)
    .equalTo(true)
    .once(
      'value',
      snapshot => {
        const data = snapshot.val();

        dispatch({
          type: 'HYDRATE_SMS_REQUEST',
          appname,
          data,
        });
      },
      err => {
        console.log(err);
      },
    );
};

export const setTabletype = tableType => dispatch => {
  dispatch({
    type: 'SET_SMS_TABLE_TYPE',
    tableType,
  });
};

export const selectSMS = key => ({
  type: 'SELECT_ENGAGE_SMS',
  key,
});

export const selectAllSMS = () => ({ type: 'SELECT_ALL_SMS' });

export const deselectAllSMS = () => ({ type: 'DESELECT_ALL_SMS' });

export const searchSmsReuqest = query => dispatch =>
  dispatch({
    type: 'SEARCH_SMS_ENGAGE',
    query,
  });
export const searchGroupSmsReuqest = query => dispatch =>
  dispatch({
    type: 'SEARCH_GROUP_SMS_ENGAGE',
    query,
  });

export const deleteSelected = (appname, type, selected) => dispatch => {
  const refType = type === 'user' ? 'app_users' : 'visitor_sms_permissions';
  selected.forEach(async user => {
    if (refType === 'app_users') {
      const ref = firebase.database().ref(`${refType}/${appname}/${user}/`);
      await ref.update({ receiveSMS: false });
    } else {
      await firebase
        .database()
        .ref(`${refType}/${appname}/${user}/`)
        .remove();
    }
  });
  dispatch(loadSMSRequest(type, appname));
};

export const selectGroup = key => ({
  type: 'SELECT_GROUP',
  key,
});

export const selectAllGroups = () => ({ type: 'SELECT_ALL_GROUPS' });

export const deselectAllGroups = () => ({ type: 'DESELECT_ALL_GROUPS' });

export const loadGroups = appname => dispatch => {
  dispatch({
    type: 'LOAD_GROUPS',
    appname,
  });

  return firebase
    .database()
    .ref(`app_groups/${appname}`)
    .once('value', snapshot => {
      const data = snapshot.val();

      dispatch({
        type: 'HYDRATE_GROUPS',
        appname,
        data,
      });
    });
};

export const getGroups = appname => dispatch => {
  dispatch(requestGroups(appname));

  firebase
    .database()
    .ref(`app_groups/${appname}/groups`)
    .once('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: 'RECEIVE_GROUPS',
          value: snap.val(),
        });
      }
    });
};
