import { Toggle } from 'material-ui';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Tabs, Tab } from '../../../../../components/tabs';
import Container from '../../../../../../../app/components/container';
import SectionHeader from '../../../../../components/sectionHeader';
import TooltipElement from '../../../../../../../app/components/tooltipElement';
import ColorPicker from '../../../../../components/colorPicker';
import DesignToggle from '../../../../../components/designToggle';

import style from './food.module.scss';

const styles = {
  toggle: {
    marginTop: 24,
    marginBottom: 12,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 6,
    width: '90%',
  },
  toggleLabel: {
    fontSize: 14,
  },
  tooltip: {
    right: 18,
    top: 7,
    position: 'relative',
  },
};

const Food = props => {
  const {
    data,
    onSpecialItemsToggle,
    onDesignChange,
    design,
    component,
  } = props;
  const { t } = useTranslation();
  const handleToggleSpecialItems = prop => (e, isToggled) =>
    onSpecialItemsToggle(prop, isToggled);
  const handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    onDesignChange(prop, rgba);
  };

  return (
    <React.Fragment>
      <Tabs>
        <Tab label={t('content')}>
          <Container style={{ display: 'flex', flexDirection: 'row' }}>
            <Toggle
              label={t('Enable Special Items Section')}
              style={styles.toggle}
              labelStyle={styles.toggleLabel}
              toggled={data.specialItems}
              onToggle={handleToggleSpecialItems(data.specialItems)}
            />

            <TooltipElement
              className={style.tooltip}
              tooltipType="info"
              tooltip={t('Hide special section from your app.')}
              verticalPosition="top"
              horizontalPosition="left"
            />
          </Container>
        </Tab>
        <Tab label={t('design')}>
          <Container>
            <DesignToggle
              component={component}
              onDesignChange={onDesignChange}
            />

            <SectionHeader
              title={t('Special Items')}
              tooltip={
                <span>
                  <Trans>Customize the look of the special items.</Trans>
                </span>
              }
            />

            <ColorPicker
              label={t('Our Specials Banner color')}
              color={design.secondaryColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('secondaryColor')}
            />

            <ColorPicker
              label={t('Background Color')}
              color={design.backgroundColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('backgroundColor')}
            />
            <ColorPicker
              label={`${t('Primary Text Color')} <br/><small><strong>${t(
                '(Title, Price & Description)',
              )}</strong></small>`}
              color={design.primaryColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('primaryColor')}
            />

            <ColorPicker
              label={t('Old Price Color')}
              color={design.tertiaryColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('tertiaryColor')}
            />
            <ColorPicker
              label={t('Add to Basket')}
              color={design.addToBasket}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('addToBasket')}
            />
            <ColorPicker
              label={`${t('Add to Basket')} <br/><small><strong>${t(
                '(Hover)',
              )}</strong></small>`}
              color={design.addToBasketHover}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('addToBasketHover')}
            />
            <ColorPicker
              label={`${t('Bottom Line Color')} <br/><small><strong>${t(
                '(seperator)',
              )}</strong></small>`}
              color={design.separator}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('separator')}
            />

            <SectionHeader
              title={t('Default Items')}
              tooltip={
                <span>
                  <Trans>Customize the look of the default items.</Trans>
                </span>
              }
            />

            <ColorPicker
              label={t('Add to basket')}
              color={design.secondaryColor2}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('secondaryColor2')}
            />

            <ColorPicker
              label={`${'Primary Text Color'} <br/><small><strong>${t(
                '(Title, Price & Description)',
              )}</strong></small>`}
              color={design.primaryColor2}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('primaryColor2')}
            />

            <ColorPicker
              label={t('Old price color')}
              color={design.tertiaryColor2}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('tertiaryColor2')}
            />

            <SectionHeader
              title={t('Checkout Cart')}
              tooltip={
                <span>
                  <Trans>Customize the look of the checkout cart.</Trans>
                </span>
              }
            />
            <ColorPicker
              label={t('Checkout Cart Color')}
              color={design.checkoutColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('checkoutColor')}
            />
            <ColorPicker
              label={t('Checkout Button color')}
              color={design.checkoutButtonColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('checkoutButtonColor')}
            />
            <ColorPicker
              label={t('Text color')}
              color={design.checkoutTexColor}
              height={22}
              disabled={!component.design.enabled}
              onChange={handleColorChange('checkoutTexColor')}
            />
          </Container>
        </Tab>
        <Tab
          label={t('layout')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    </React.Fragment>
  );
};

export default Food;
