import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  .cls-1 {
    fill: #fff;
  }
  .cls-2,
  .cls-4,
  .cls-5,
  .cls-7,
  .cls-8 {
    fill: none;
  }
  .cls-2,
  .cls-8 {
    stroke: #d0d2d3;
    stroke-width: 3px;
  }
  .cls-2 {
    stroke-linejoin: round;
  }
  .cls-3 {
    fill: #d0d2d3;
  }
  .cls-4 {
    stroke: #f1f1f2;
  }
  .cls-4,
  .cls-5,
  .cls-7,
  .cls-8 {
    stroke-miterlimit: 10;
  }
  .cls-5,
  .cls-7 {
    stroke: #dbdbdb;
  }
  .cls-5 {
    stroke-width: 2px;
  }
  .cls-6 {
    fill: #f1f1f2;
  }
  .cls-7 {
    stroke-width: 1.5px;
  }
  .cls-9 {
    fill: #e5e5e5;
  }
  .cls-10 {
    fill: #b7b7b7;
  }
`;

const ScheduleLayoutFrankfurter = () => (
  <Svg
    className="layout frankfurter"
    xmlns="http://www.w3.org/2000/svg"
    width="750.000000pt"
    height="1334.000000pt"
    viewBox="0 0 378.19 775"
    preserveAspectRatio="xMidYMid meet"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M5.09,120H3.69a2,2,0,0,1-2-2V88.9a2,2,0,0,1,2-2h1.4"
        />
        <path
          className="cls-2"
          d="M5.09,120H3.69a2,2,0,0,1-2-2V88.9a2,2,0,0,1,2-2h1.4"
        />
        <path
          className="cls-1"
          d="M5.09,216H3.89a2,2,0,0,1-2-2V159.4a2,2,0,0,1,2-2h1.2"
        />
        <path
          className="cls-2"
          d="M5.09,216H3.89a2,2,0,0,1-2-2V159.4a2,2,0,0,1,2-2h1.2"
        />
        <path
          className="cls-1"
          d="M372.89,157.4h.8a3,3,0,0,1,3,3V213a3,3,0,0,1-3,3h-.8"
        />
        <path
          className="cls-2"
          d="M372.89,157.4h.8a3,3,0,0,1,3,3V213a3,3,0,0,1-3,3h-.8"
        />
        <path
          className="cls-1"
          d="M5.19,288.2H2c-1.1,0,0-.9,0-2V231.6c0-1.1-1.1-2,0-2h3.2"
        />
        <path
          className="cls-2"
          d="M5.19,288.2H2c-1.1,0,0-.9,0-2V231.6c0-1.1-1.1-2,0-2h3.2"
        />
        <path
          className="cls-1"
          d={`M56.89,773.5a50.45,50.45,0,0,1-50.4-50.4V51.9A50.45,50.45,0,0,1,56.89,
          1.5H321a50.45,50.45,0,0,1,50.4,50.4V723.1A50.45,50.45,0,0,1,321,773.5H56.89Z`}
        />
        <path
          className="cls-3"
          d={`M321,3a49,49,0,0,1,48.9,48.9V723.1A48.91,48.91,0,0,1,321,772H56.89A49,
          49,0,0,1,8,723.1V51.9A48.91,48.91,0,0,1,56.89,3H321m0-3H56.89A51.92,51.92,
          0,0,0,5,51.9V723.1A51.92,51.92,0,0,0,56.89,775H321a51.92,51.92,0,0,0,51.9-51.9V51.9A51.92,51.92,0,0,0,321,0Z`}
        />
        <path
          className="cls-4"
          d="M360.59,683a3,3,0,0,1-3,3H19.89a3,3,0,0,1-3-3V83a3.08,3.08,0,0,1,3-3h337.7a3,3,0,0,1,3,3Z"
        />
        <path
          className="cls-5"
          d="M360.59,683a3,3,0,0,1-3,3H19.89a3,3,0,0,1-3-3V83a3.08,3.08,0,0,1,3-3h337.7a3,3,0,0,1,3,3Z"
        />
        <circle className="cls-6" cx="126.59" cy="41.8" r="6.6" />
        <circle className="cls-7" cx="126.59" cy="41.8" r="6.6" />
        <circle className="cls-6" cx="188.09" cy="15.4" r="4" />
        <circle className="cls-7" cx="188.09" cy="15.4" r="4" />
        <circle className="cls-8" cx="188.09" cy="731" r="30.1" />
        <path
          className="cls-9"
          d="M217.69,42a3,3,0,0,1-3,3h-54.1a3,3,0,0,1-3-3v-.1a3,3,0,0,1,3-3h54.1A3.1,3.1,0,0,1,217.69,42Z"
        />
        <path
          className="cls-5"
          d="M217.69,42a3,3,0,0,1-3,3h-54.1a3,3,0,0,1-3-3v-.1a3,3,0,0,1,3-3h54.1A3.1,3.1,0,0,1,217.69,42Z"
        />
        <path
          className="cls-10"
          d="M313.59,134.5h0a12,12,0,0,0-12,12h0a12,12,0,0,0,12,12h0a12,12,0,0,0,12-12h0A12,12,0,0,0,313.59,134.5Z"
        />
        <path
          className="cls-10"
          d="M331.69,274.2H137.89a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h193.8a6.27,6.27,0,0,0,6.3-6.3h0A6.27,6.27,0,0,0,331.69,274.2Z"
        />
        <path
          className="cls-10"
          d="M81.29,204H39.39a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h41.9a6.27,6.27,0,0,0,6.3-6.3h0A6.27,6.27,0,0,0,81.29,204Z"
        />
        <path
          className="cls-10"
          d="M81.29,511.2H39.39a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h41.9a6.27,6.27,0,0,0,6.3-6.3h0A6.27,6.27,0,0,0,81.29,511.2Z"
        />
        <path
          className="cls-10"
          d="M195.39,204h-57.9a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h57.9a6.27,6.27,0,0,0,6.3-6.3h0A6.27,6.27,0,0,0,195.39,204Z"
        />
        <path
          className="cls-10"
          d="M336.39,294.1H134.69a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h201.7a3.22,3.22,0,0,0,3.2-3.2h0A3.22,3.22,0,0,0,336.39,294.1Z"
        />
        <path
          className="cls-10"
          d="M260.79,140.5h-201a6,6,0,0,0-6,6h0a6,6,0,0,0,6,6h201a6,6,0,0,0,6-6h0A6,6,0,0,0,260.79,140.5Z"
        />
        <path
          className="cls-10"
          d="M336.39,307.8H134.69a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h201.7a3.22,3.22,0,0,0,3.2-3.2h0A3.16,3.16,0,0,0,336.39,307.8Z"
        />
        <path
          className="cls-10"
          d="M301.69,321.5h-167a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h167a3.22,3.22,0,0,0,3.2-3.2h0A3.16,3.16,0,0,0,301.69,321.5Z"
        />
        <path
          className="cls-10"
          d="M211.09,113h-149a6,6,0,0,0-6,6h0a6,6,0,0,0,6,6h149a6,6,0,0,0,6-6h0A6,6,0,0,0,211.09,113Z"
        />
        <rect className="cls-3" x="17.09" y="184" width="344" height="3" />
        <rect className="cls-3" x="131.59" y="247" width="208" height="3" />
        <rect className="cls-3" x="17.59" y="482" width="344" height="3" />
        <path
          className="cls-10"
          d="M156.79,339.9h-18.9a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h18.9a6.27,6.27,0,0,0,6.3-6.3h0A6.27,6.27,0,0,0,156.79,339.9Z"
        />
        <path
          className="cls-10"
          d="M331.69,511.2H137.89a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h193.8a6.27,6.27,0,0,0,6.3-6.3h0A6.27,6.27,0,0,0,331.69,511.2Z"
        />
        <path
          className="cls-10"
          d="M336.39,531.1H134.69a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h201.7a3.22,3.22,0,0,0,3.2-3.2h0A3.22,3.22,0,0,0,336.39,531.1Z"
        />
        <path
          className="cls-10"
          d="M336.39,544.8H134.69a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h201.7a3.22,3.22,0,0,0,3.2-3.2h0A3.16,3.16,0,0,0,336.39,544.8Z"
        />
        <path
          className="cls-10"
          d="M301.69,558.5h-167a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h167a3.22,3.22,0,0,0,3.2-3.2h0A3.16,3.16,0,0,0,301.69,558.5Z"
        />
        <path
          className="cls-10"
          d="M156.79,576.9h-18.9a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h18.9a6.27,6.27,0,0,0,6.3-6.3h0A6.27,6.27,0,0,0,156.79,576.9Z"
        />
        <path
          className="cls-10"
          d="M331.69,374.2H137.89a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h193.8a6.27,6.27,0,0,0,6.3-6.3h0A6.27,6.27,0,0,0,331.69,374.2Z"
        />
        <path
          className="cls-10"
          d="M336.39,394.1H134.69a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h201.7a3.22,3.22,0,0,0,3.2-3.2h0A3.22,3.22,0,0,0,336.39,394.1Z"
        />
        <path
          className="cls-10"
          d="M336.39,407.8H134.69a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h201.7a3.22,3.22,0,0,0,3.2-3.2h0A3.16,3.16,0,0,0,336.39,407.8Z"
        />
        <path
          className="cls-10"
          d="M301.69,421.5h-167a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h167a3.22,3.22,0,0,0,3.2-3.2h0A3.16,3.16,0,0,0,301.69,421.5Z"
        />
        <path
          className="cls-10"
          d="M156.79,439.9h-18.9a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h18.9a6.27,6.27,0,0,0,6.3-6.3h0A6.27,6.27,0,0,0,156.79,439.9Z"
        />
        <rect className="cls-3" x="103.09" y="184" width="3" height="502" />
      </g>
    </g>
  </Svg>
);

export default ScheduleLayoutFrankfurter;
