import firebase from '../../../../../../../firebase';

export const fieldChange = (appname, dataId, field, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child(field).set(value);

  return {
    type: 'FACEBOOK_FIELD_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

export const toggleFacebookOption = (
  appname,
  lazyDataId,
  field,
  value,
) => dispatch =>
  firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${lazyDataId}/${field}`)
    .set(value)
    .then(() =>
      dispatch({
        type: 'TOGGLE_FACEBOOK_OPTION',
        appname,
        lazyDataId,
        value,
      }),
    );
