import React from 'react';

import './styles.css';

const Drag = () => (
  <svg
    className="icon drag"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34 13.64"
  >
    <g id="Layer_1" data-name="Layer 1">
      <path d="M33,2H1A1,1,0,0,1,1,0H33a1,1,0,0,1,0,2Z" />
      <path d="M33,7.82H1a1,1,0,0,1,0-2H33a1,1,0,0,1,0,2Z" />
      <path d="M33,13.64H1a1,1,0,0,1,0-2H33a1,1,0,0,1,0,2Z" />
    </g>
  </svg>
);

export default Drag;
