import React from 'react';
import PropTypes from 'prop-types';
import Cards from 'react-credit-cards';
import { Trans, useTranslation } from 'react-i18next';
import 'react-credit-cards/es/styles-compiled.css';

import { FlatButton } from '../../../../app/components/buttons';

import './styles.css';

const pad = str => (Number(str) < 10 ? `0${str}` : str);

/* eslint-disable camelcase */
const CreditCard = ({
  last4,
  brand,
  exp_month,
  exp_year,
  onAddCard,
  onRemoveCard,
}) => {
  const { t } = useTranslation();

  return (
    <div className="credit-card-container">
      <div className="credit-card">
        <Cards
          number={`************${last4}`}
          name={brand}
          expiry={`${pad(exp_month)}${exp_year.toString().substr(2)}`}
          cvc={''}
          type={brand.toLowerCase()}
        />
      </div>
      <FlatButton label={t('Change Card')} onClick={onAddCard} secondary />
      <FlatButton
        label={t('Remove Card')}
        onClick={onRemoveCard}
        secondaryWhite
      />
    </div>
  );
};

CreditCard.propTypes = {
  last4: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  exp_month: PropTypes.number.isRequired,
  exp_year: PropTypes.number.isRequired,
  onAddCard: PropTypes.func.isRequired,
  onRemoveCard: PropTypes.func.isRequired,
};

export default CreditCard;
