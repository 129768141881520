import { connect } from 'react-redux';

import Subscriptions from './Subscriptions';
import { loadSubscriptions, filterData } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  items: state.auth.subscriptions.items,
  chunks: state.auth.subscriptions.chunks,
  filteredChunks: state.auth.subscriptions.filteredChunks,
  error: state.auth.subscriptions.error,
  pending: state.auth.subscriptions.pending,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadSubscriptions: () =>
    dispatch(loadSubscriptions(ownProps.match.params.appname)),
  onFilterSubscriptionData: filter => dispatch(filterData(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
