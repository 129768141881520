import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  .cls-1 {
    fill: #fff;
  }
  .cls-2,
  .cls-4,
  .cls-5,
  .cls-7,
  .cls-8 {
    fill: none;
  }
  .cls-2,
  .cls-8 {
    stroke: #d0d2d3;
    stroke-width: 3px;
  }
  .cls-2 {
    stroke-linejoin: round;
  }
  .cls-3 {
    fill: #d0d2d3;
  }
  .cls-4 {
    stroke: #f1f1f2;
  }
  .cls-4,
  .cls-5,
  .cls-7,
  .cls-8 {
    stroke-miterlimit: 10;
  }
  .cls-5,
  .cls-7 {
    stroke: #dbdbdb;
  }
  .cls-5 {
    stroke-width: 2px;
  }
  .cls-6 {
    fill: #f1f1f2;
  }
  .cls-7 {
    stroke-width: 1.5px;
  }
  .cls-9 {
    fill: #e5e5e5;
  }
  .cls-10 {
    fill: #b7b7b7;
  }
`;

const ScheduleLayoutIce = () => (
  <Svg
    className="layout ice"
    width="750.000000pt"
    height="1334.000000pt"
    viewBox="0 0 378.19 775"
    preserveAspectRatio="xMidYMid meet"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M5.09,120H3.69a2,2,0,0,1-2-2V88.9a2,2,0,0,1,2-2h1.4"
        />
        <path
          className="cls-2"
          d="M5.09,120H3.69a2,2,0,0,1-2-2V88.9a2,2,0,0,1,2-2h1.4"
        />
        <path
          className="cls-1"
          d="M5.09,216H3.89a2,2,0,0,1-2-2V159.4a2,2,0,0,1,2-2h1.2"
        />
        <path
          className="cls-2"
          d="M5.09,216H3.89a2,2,0,0,1-2-2V159.4a2,2,0,0,1,2-2h1.2"
        />
        <path
          className="cls-1"
          d="M372.89,157.4h.8a3,3,0,0,1,3,3V213a3,3,0,0,1-3,3h-.8"
        />
        <path
          className="cls-2"
          d="M372.89,157.4h.8a3,3,0,0,1,3,3V213a3,3,0,0,1-3,3h-.8"
        />
        <path
          className="cls-1"
          d="M5.19,288.2H2c-1.1,0,0-.9,0-2V231.6c0-1.1-1.1-2,0-2h3.2"
        />
        <path
          className="cls-2"
          d="M5.19,288.2H2c-1.1,0,0-.9,0-2V231.6c0-1.1-1.1-2,0-2h3.2"
        />
        <path
          className="cls-1"
          d={`M57,773.5a50.45,50.45,0,0,1-50.4-50.4V51.9A50.45,50.45,
          0,0,1,57,1.5h264.1a50.45,50.45,0,0,1,50.4,50.4V723.1a50.45,50.45,0,0,1-50.4,50.4H57Z`}
        />
        <path
          className="cls-3"
          d={`M321,3a49,49,0,0,1,48.9,48.9V723.1A48.91,48.91,0,0,1,321,772H57a49,49,0,0,
          1-48.9-48.9V51.9A48.91,48.91,0,0,1,57,3H321m0-3H57A51.92,51.92,0,0,0,5.09,51.9V723.1A51.92,
          51.92,0,0,0,57,775h264.1A51.92,51.92,0,0,0,373,723.1V51.9A52.07,52.07,0,0,0,321,0Z`}
        />
        <path
          className="cls-4"
          d="M360.59,683a3,3,0,0,1-3,3H19.89a3,3,0,0,1-3-3V83a3.08,3.08,0,0,1,3-3h337.7a3,3,0,0,1,3,3V683Z"
        />
        <path
          className="cls-5"
          d="M360.59,683a3,3,0,0,1-3,3H19.89a3,3,0,0,1-3-3V83a3.08,3.08,0,0,1,3-3h337.7a3,3,0,0,1,3,3V683Z"
        />
        <circle className="cls-6" cx="126.59" cy="41.8" r="6.6" />
        <circle className="cls-7" cx="126.59" cy="41.8" r="6.6" />
        <circle className="cls-6" cx="188.19" cy="15.4" r="4" />
        <circle className="cls-7" cx="188.19" cy="15.4" r="4" />
        <circle className="cls-8" cx="188.19" cy="731" r="30.1" />
        <path
          className="cls-9"
          d="M217.69,42a3,3,0,0,1-3,3h-54a3,3,0,0,1-3-3v-.1a3,3,0,0,1,3-3h54A3.1,3.1,0,0,1,217.69,42Z"
        />
        <path
          className="cls-5"
          d="M217.69,42a3,3,0,0,1-3,3h-54a3,3,0,0,1-3-3v-.1a3,3,0,0,1,3-3h54A3.1,3.1,0,0,1,217.69,42Z"
        />
        <rect className="cls-3" x="47.19" y="501" width="284" height="3" />
        <rect className="cls-3" x="47.69" y="184" width="284" height="3" />
        <path
          className="cls-10"
          d="M218.19,217h-57.9a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h57.9a6.27,6.27,0,0,0,6.3-6.3h0A6.34,6.34,0,0,0,218.19,217Z"
        />
        <path
          className="cls-10"
          d="M302.09,255.2H76.29a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h225.8a6.27,6.27,0,0,0,6.3-6.3h0A6.27,6.27,0,0,0,302.09,255.2Z"
        />
        <path
          className="cls-10"
          d="M290,275.1H88.39a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h201.7a3.22,3.22,0,0,0,3.2-3.2h0A3.38,3.38,0,0,0,290,275.1Z"
        />
        <path
          className="cls-10"
          d="M290,288.8H88.39a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h201.7a3.22,3.22,0,0,0,3.2-3.2h0A3.31,3.31,0,0,0,290,288.8Z"
        />
        <path
          className="cls-10"
          d="M272.69,302.5h-167a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h167a3.22,3.22,0,0,0,3.2-3.2h0A3.29,3.29,0,0,0,272.69,302.5Z"
        />
        <path
          className="cls-10"
          d="M198.69,320.9h-19a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h19a6.27,6.27,0,0,0,6.3-6.3h0A6.34,6.34,0,0,0,198.69,320.9Z"
        />
        <path
          className="cls-10"
          d="M218.19,370h-57.9a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h57.9a6.27,6.27,0,0,0,6.3-6.3h0A6.34,6.34,0,0,0,218.19,370Z"
        />
        <path
          className="cls-10"
          d="M302.09,408.2H76.29a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h225.8a6.27,6.27,0,0,0,6.3-6.3h0A6.27,6.27,0,0,0,302.09,408.2Z"
        />
        <path
          className="cls-10"
          d="M290,428.1H88.39a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h201.7a3.22,3.22,0,0,0,3.2-3.2h0A3.38,3.38,0,0,0,290,428.1Z"
        />
        <path
          className="cls-10"
          d="M272.69,441.8h-167a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h167a3.22,3.22,0,0,0,3.2-3.2h0A3.29,3.29,0,0,0,272.69,441.8Z"
        />
        <path
          className="cls-10"
          d="M198.69,460.9h-19a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h19a6.27,6.27,0,0,0,6.3-6.3h0A6.34,6.34,0,0,0,198.69,460.9Z"
        />
        <path
          className="cls-10"
          d="M218.19,537h-57.9a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h57.9a6.27,6.27,0,0,0,6.3-6.3h0A6.34,6.34,0,0,0,218.19,537Z"
        />
        <path
          className="cls-10"
          d="M302.09,575.2H76.29a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h225.8a6.27,6.27,0,0,0,6.3-6.3h0A6.27,6.27,0,0,0,302.09,575.2Z"
        />
        <path
          className="cls-10"
          d="M290,595.1H88.39a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h201.7a3.22,3.22,0,0,0,3.2-3.2h0A3.38,3.38,0,0,0,290,595.1Z"
        />
        <path
          className="cls-10"
          d="M272.69,608.8h-167a3.22,3.22,0,0,0-3.2,3.2h0a3.22,3.22,0,0,0,3.2,3.2h167a3.22,3.22,0,0,0,3.2-3.2h0A3.29,3.29,0,0,0,272.69,608.8Z"
        />
        <path
          className="cls-10"
          d="M198.69,627.9h-19a6.27,6.27,0,0,0-6.3,6.3h0a6.27,6.27,0,0,0,6.3,6.3h19a6.27,6.27,0,0,0,6.3-6.3h0A6.34,6.34,0,0,0,198.69,627.9Z"
        />
        <path
          className="cls-10"
          d="M312.69,134.5h0a12,12,0,0,0-12,12h0a12,12,0,0,0,12,12h0a12,12,0,0,0,12-12h0A12,12,0,0,0,312.69,134.5Z"
        />
        <path
          className="cls-10"
          d="M259.89,140.5h-201a6,6,0,0,0-6,6h0a6,6,0,0,0,6,6h201a6,6,0,0,0,6-6h0A6,6,0,0,0,259.89,140.5Z"
        />
        <path
          className="cls-10"
          d="M210.19,113h-149a6,6,0,0,0-6,6h0a6,6,0,0,0,6,6h149a6,6,0,0,0,6-6h0A6,6,0,0,0,210.19,113Z"
        />
      </g>
    </g>
  </Svg>
);

export default ScheduleLayoutIce;
