import {
  CLOSE_EDIT_DIALOG_EVENT,
  EDIT_FIELD_CHANGE_EVENT,
  LOADING_EVENT,
  LOADED_DETAILS_EVENT,
  UPDATE_REQUEST_EVENT,
  UPDATED_EVENT,
  RESET_EVENT,
} from './actions';

const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  fieldsData: {},
};

const editEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_EDIT_DIALOG_EVENT:
      return {
        ...state,
        open: false,
      };

    case EDIT_FIELD_CHANGE_EVENT:
      return {
        ...state,
        fieldsData: {
          ...state.fieldsData,
          [action.id]: {
            value: action.value,
            isValid: action.isValid,
            type: action.prop,
          },
        },
      };

    case LOADING_EVENT:
      return {
        ...state,
        pending: true,
      };

    case LOADED_DETAILS_EVENT:
      return {
        ...state,
        pending: false,
        fieldsData: action.prop,
      };

    case UPDATE_REQUEST_EVENT:
      return {
        ...state,
        pending: true,
      };

    case UPDATED_EVENT:
      return {
        ...initialState,
        key: action.key,
      };

    case RESET_EVENT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default editEventReducer;
