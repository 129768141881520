import { updateSubscription } from '../subscriptions/actions';
import { reportPaymentSuccess, reportPaymentError } from '../payment/actions';

export const openingWixPlans = () => ({
  type: 'OPEN_WIX_PLANS',
});

export const isFreePlan = (plans, integrationType, appname) => {
  if (integrationType === 'Weebly' || integrationType === 'Wix') {
    if (!plans[appname]) {
      return true;
    }
    const { plan } = plans[appname];
    if (plan !== 'premium') {
      return true;
    }
  }
  return false;
};

const postRequest = (apiUrl, payload) =>
  new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    return fetch(apiUrl, {
      method: 'post',
      headers: myHeaders,
      body: JSON.stringify(payload),
      mode: 'cors',
    })
      .then(res => {
        if (res.status === 200) {
          return resolve();
        }
        return reject();
      })
      .catch(() => reject());
  });

export const openWixPlans = (appname, plan, history, accountId) => dispatch => {
  const isTesting = false;
  dispatch(openingWixPlans());

  if (isTesting) {
    const apiUrl = `${process.env.REACT_APP_PAYMENT_API}/wix_plan`;
    const payload = {};
    payload.appname = appname;
    payload.plan = plan;
    payload.accountId = accountId;
    payload.period = 'monthly';
    postRequest(apiUrl, payload)
      .then(() => {
        dispatch(updateSubscription(appname, { plan }));
        dispatch(reportPaymentSuccess());
      })
      .catch(() => dispatch(reportPaymentError()));
    return history.push(`/${appname}/editor`);
  }
  return window.Wix.Dashboard.openBillingPage();
};
