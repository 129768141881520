import { connect } from 'react-redux';

import Payment from './Payment';
import {
  onFilterPaymentHistory,
  getPaymentHistory,
  sortTableData,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  pending: state.analytics.payment.data.pending,
  chunks: state.analytics.payment.data.chunks,
  filteredChunks: state.analytics.payment.data.filteredChunks,
  error: state.analytics.payment.data.error,
  items: state.analytics.payment.data.items,
  selected: state.analytics.payment.data.selected,
  selectAll: state.analytics.payment.data.selectAll,
  filter: state.analytics.payment.data.filter,
  order: state.analytics.payment.data.order,
  colSorted: state.analytics.payment.data.colSorted,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFilterPaymentHistory: filter => dispatch(onFilterPaymentHistory(filter)),
  onSort: cols => dispatch(sortTableData(cols)),
  getPaymentHistory: () =>
    dispatch(getPaymentHistory(ownProps.match.params.appname)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
