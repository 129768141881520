import React from 'react';

const PopUp = () => (
  <svg
    className="icon"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
  >
    <g>
      <rect x="17.1" y="7" width="31.9" height="2" />
      <path
        d="M46.7,1.3H19.4c-1.8,0-3.3,1.5-3.3,3.3v8.6h2V4.6c0-0.7,0.6-1.3,1.3-1.3h27.4c0.7,0,1.3,0.6,1.3,1.3v24.7
		c0,0.7-0.6,1.3-1.3,1.3h-8.6v2h8.6c1.8,0,3.3-1.5,3.3-3.3V4.6C50,2.8,48.5,1.3,46.7,1.3z"
      />
      <path
        d="M30.6,48.7H3.3c-1.8,0-3.3-1.5-3.3-3.3V20.7c0-1.8,1.5-3.3,3.3-3.3h27.4c1.8,0,3.3,1.5,3.3,3.3v24.7
		C33.9,47.2,32.4,48.7,30.6,48.7z M3.3,19.4C2.6,19.4,2,20,2,20.7v24.7c0,0.7,0.6,1.3,1.3,1.3h27.4c0.7,0,1.3-0.6,1.3-1.3V20.7
		c0-0.7-0.6-1.3-1.3-1.3H3.3z"
      />
      <rect x="1" y="23.1" width="31.9" height="2" />
    </g>
  </svg>
);

export default PopUp;
