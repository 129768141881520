export const countryCodes = [
  '93',
  '355',
  '213',
  '376',
  '244',
  '1264',
  '1268',
  '54',
  '374',
  '297',
  '61',
  '43',
  '1242',
  '880',
  '1246',
  '375',
  '501',
  '1441',
  '975',
  '387',
  '55',
  '246',
  '226',
  '1',
  '238',
  '1345',
  '236',
  '235',
  '56',
  '86',
  '61',
  '57',
  '269',
  '242',
  '682',
  '506',
  '385',
  '53',
  '537',
  '420',
  '45',
  '1767',
  '1849',
  '593',
  '20',
  '503',
  '240',
  '251',
  '298',
  '358',
  '33',
  '241',
  '220',
  '995',
  '49',
  '233',
  '350',
  '30',
  '299',
  '1473',
  '590',
  '1671',
  '502',
  '595',
  '509',
  '504',
  '36',
  '354',
  '91',
  '62',
  '353',
  '972',
  '39',
  '1876',
  '81',
  '962',
  '7',
  '254',
  '686',
  '965',
  '996',
  '371',
  '961',
  '231',
  '423',
  '370',
  '352',
  '261',
  '265',
  '60',
  '960',
  '356',
  '222',
  '230',
  '52',
  '976',
  '382',
  '1664',
  '212',
  '264',
  '674',
  '977',
  '599',
  '505',
  '683',
  '672',
  '1670',
  '47',
  '968',
  '92',
  '680',
  '507',
  '675',
  '595',
  '51',
  '63',
  '48',
  '351',
  '1939',
  '974',
  '40',
  '250',
  '685',
  '378',
  '966',
  '381',
  '232',
  '65',
  '386',
  '677',
  '27',
  '500',
  '34',
  '94',
  '249',
  '597',
  '268',
  '41',
  '992',
  '66',
  '228',
  '690',
  '676',
  '1868',
  '216',
  '90',
  '1649',
  '256',
  '380',
  '971',
  '44',
  '598',
  '678',
  '681',
  '967',
  '260',
  '591',
  '673',
  '61',
  '243',
  '500',
  '379',
  '852',
  '98',
  '850',
  '82',
  '856',
  '218',
  '853',
  '389',
  '691',
  '373',
  '258',
  '970',
  '872',
  '262',
  '590',
  '290',
  '1869',
  '1758',
  '590',
  '1784',
  '47',
  '963',
  '886',
  '255',
  '58',
  '1284',
  '1340',
  '359',
];

export const countries = {
  '1': { code: 'US', dialCode: '+1', name: 'United States' },
  '7': { code: 'KZ', dialCode: '+7', name: 'Kazakhstan' },
  '20': { code: 'EG', dialCode: '+20', name: 'Egypt' },
  '27': { code: 'ZA', dialCode: '+27', name: 'South Africa' },
  '30': { code: 'GR', dialCode: '+30', name: 'Greece' },
  '33': { code: 'FR', dialCode: '+33', name: 'France' },
  '34': { code: 'ES', dialCode: '+34', name: 'Spain' },
  '36': { code: 'HU', dialCode: '+36', name: 'Hungary' },
  '39': { code: 'IT', dialCode: '+39', name: 'Italy' },
  '40': { code: 'RO', dialCode: '+40', name: 'Romania' },
  '41': { code: 'CH', dialCode: '+41', name: 'Switzerland' },
  '43': { code: 'AT', dialCode: '+43', name: 'Austria' },
  '44': { code: 'GB', dialCode: '+44', name: 'United Kingdom' },
  '45': { code: 'DK', dialCode: '+45', name: 'Denmark' },
  '47': { code: 'SJ', dialCode: '+47', name: 'Svalbard and Jan Mayen' },
  '48': { code: 'PL', dialCode: '+48', name: 'Poland' },
  '49': { code: 'DE', dialCode: '+49', name: 'Germany' },
  '51': { code: 'PE', dialCode: '+51', name: 'Peru' },
  '52': { code: 'MX', dialCode: '+52', name: 'Mexico' },
  '53': { code: 'CU', dialCode: '+53', name: 'Cuba' },
  '54': { code: 'AR', dialCode: '+54', name: 'Argentina' },
  '55': { code: 'BR', dialCode: '+55', name: 'Brazil' },
  '56': { code: 'CL', dialCode: '+56', name: 'Chile' },
  '57': { code: 'CO', dialCode: '+57', name: 'Colombia' },
  '58': {
    code: 'VE',
    dialCode: '+58',
    name: 'Venezuela, Bolivarian Republic of',
  },
  '60': { code: 'MY', dialCode: '+60', name: 'Malaysia' },
  '61': { code: 'CC', dialCode: '+61', name: 'Cocos (Keeling) Islands' },
  '62': { code: 'ID', dialCode: '+62', name: 'Indonesia' },
  '63': { code: 'PH', dialCode: '+63', name: 'Philippines' },
  '65': { code: 'SG', dialCode: '+65', name: 'Singapore' },
  '66': { code: 'TH', dialCode: '+66', name: 'Thailand' },
  '81': { code: 'JP', dialCode: '+81', name: 'Japan' },
  '82': { code: 'KR', dialCode: '+82', name: 'Korea, Republic of' },
  '86': { code: 'CN', dialCode: '+86', name: 'China' },
  '90': { code: 'TR', dialCode: '+90', name: 'Turkey' },
  '91': { code: 'IN', dialCode: '+91', name: 'India' },
  '93': { code: 'AF', dialCode: '+93', name: 'Afghanistan' },
  '98': {
    code: 'IR',
    dialCode: '+98',
    name: 'Iran, Islamic Republic of',
  },
  '212': { code: 'MA', dialCode: '+212', name: 'Morocco' },
  '218': { code: 'LY', dialCode: '+218', name: 'Libyan Arab Jamahiriya' },
  '220': { code: 'GM', dialCode: '+220', name: 'Gambia' },
  '222': { code: 'MR', dialCode: '+222', name: 'Mauritania' },
  '359': { code: 'BF', dialCode: '+359', name: 'Bulgaria' },
  '226': { code: 'BF', dialCode: '+226', name: 'Burkina Faso' },
  '228': { code: 'TG', dialCode: '+228', name: 'Togo' },
  '230': { code: 'MU', dialCode: '+230', name: 'Mauritius' },
  '231': { code: 'LR', dialCode: '+231', name: 'Liberia' },
  '232': { code: 'SL', dialCode: '+232', name: 'Sierra Leone' },
  '233': { code: 'GH', dialCode: '+233', name: 'Ghana' },
  '235': { code: 'TD', dialCode: '+235', name: 'Chad' },
  '236': {
    code: 'CF',
    dialCode: '+236',
    name: 'Central African Republic',
  },
  '238': { code: 'CV', dialCode: '+238', name: 'Cape Verde' },
  '240': { code: 'GQ', dialCode: '+240', name: 'Equatorial Guinea' },
  '241': { code: 'GA', dialCode: '+241', name: 'Gabon' },
  '242': { code: 'CG', dialCode: '+242', name: 'Congo' },
  '243': {
    code: 'CD',
    dialCode: '+243',
    name: 'Congo, The Democratic Republic of the',
  },
  '244': { code: 'AO', dialCode: '+244', name: 'Angola' },
  '246': {
    code: 'IO',
    dialCode: '+246',
    name: 'British Indian Ocean Territory',
  },
  '249': { code: 'SD', dialCode: '+249', name: 'Sudan' },
  '250': { code: 'RW', dialCode: '+250', name: 'Rwanda' },
  '251': { code: 'ET', dialCode: '+251', name: 'Ethiopia' },
  '254': { code: 'KE', dialCode: '+254', name: 'Kenya' },
  '255': {
    code: 'TZ',
    dialCode: '+255',
    name: 'Tanzania, United Republic of',
  },
  '256': { code: 'UG', dialCode: '+256', name: 'Uganda' },
  '258': { code: 'MZ', dialCode: '+258', name: 'Mozambique' },
  '260': { code: 'ZM', dialCode: '+260', name: 'Zambia' },
  '261': { code: 'MG', dialCode: '+261', name: 'Madagascar' },
  '262': { code: 'RE', dialCode: '+262', name: 'Réunion' },
  '264': { code: 'NA', dialCode: '+264', name: 'Namibia' },
  '265': { code: 'MW', dialCode: '+265', name: 'Malawi' },
  '268': { code: 'SZ', dialCode: '+268', name: 'Swaziland' },
  '269': { code: 'KM', dialCode: '+269', name: 'Comoros' },
  '290': {
    code: 'SH',
    dialCode: '+290',
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
  },
  '297': { code: 'AW', dialCode: '+297', name: 'Aruba' },
  '298': { code: 'FO', dialCode: '+298', name: 'Faroe Islands' },
  '299': { code: 'GL', dialCode: '+299', name: 'Greenland' },
  '350': { code: 'GI', dialCode: '+350', name: 'Gibraltar' },
  '351': { code: 'PT', dialCode: '+351', name: 'Portugal' },
  '352': { code: 'LU', dialCode: '+352', name: 'Luxembourg' },
  '353': { code: 'IE', dialCode: '+353', name: 'Ireland' },
  '354': { code: 'IS', dialCode: '+354', name: 'Iceland' },
  '972': {
    code: 'IL',
    dialCode: '+972',
    name: 'Israel',
  },
  '355': { code: 'AL', dialCode: '+355', name: 'Albania' },
  '356': { code: 'MT', dialCode: '+356', name: 'Malta' },
  '358': { code: 'FI', dialCode: '+358', name: 'Finland' },
  '370': { code: 'LT', dialCode: '+370', name: 'Lithuania' },
  '371': { code: 'LV', dialCode: '+371', name: 'Latvia' },
  '373': { code: 'MD', dialCode: '+373', name: 'Moldova, Republic of' },
  '374': { code: 'AM', dialCode: '+374', name: 'Armenia' },
  '375': { code: 'BY', dialCode: '+375', name: 'Belarus' },
  '376': { code: 'AD', dialCode: '+376', name: 'Andorra' },
  '378': { code: 'SM', dialCode: '+378', name: 'San Marino' },
  '379': {
    code: 'VA',
    dialCode: '+379',
    name: 'Holy See (Vatican City State)',
  },
  '380': { code: 'UA', dialCode: '+380', name: 'Ukraine' },
  '381': { code: 'RS', dialCode: '+381', name: 'Serbia' },
  '382': { code: 'ME', dialCode: '+382', name: 'Montenegro' },
  '385': { code: 'HR', dialCode: '+385', name: 'Croatia' },
  '386': { code: 'SI', dialCode: '+386', name: 'Slovenia' },
  '387': { code: 'BA', dialCode: '+387', name: 'Bosnia and Herzegovina' },
  '389': {
    code: 'MK',
    dialCode: '+389',
    name: 'Macedonia, The Former Yugoslav Republic of',
  },
  '420': { code: 'CZ', dialCode: '+420', name: 'Czech Republic' },
  '423': { code: 'LI', dialCode: '+423', name: 'Liechtenstein' },
  '500': {
    code: 'FK',
    dialCode: '+500',
    name: 'Falkland Islands (Malvinas)',
  },
  '501': { code: 'BZ', dialCode: '+501', name: 'Belize' },
  '502': { code: 'GT', dialCode: '+502', name: 'Guatemala' },
  '503': { code: 'SV', dialCode: '+503', name: 'El Salvador' },
  '504': { code: 'HN', dialCode: '+504', name: 'Honduras' },
  '505': { code: 'NI', dialCode: '+505', name: 'Nicaragua' },
  '506': { code: 'CR', dialCode: '+506', name: 'Costa Rica' },
  '507': { code: 'PA', dialCode: '+507', name: 'Panama' },
  '509': { code: 'HT', dialCode: '+509', name: 'Haiti' },
  '537': { code: 'CY', dialCode: '+537', name: 'Cyprus' },
  '590': { code: 'MF', dialCode: '+590', name: 'Saint Martin' },
  '591': {
    code: 'BO',
    dialCode: '+591',
    name: 'Bolivia, Plurinational State of',
  },
  '593': { code: 'EC', dialCode: '+593', name: 'Ecuador' },
  '595': { code: 'PY', dialCode: '+595', name: 'Paraguay' },
  '597': { code: 'SR', dialCode: '+597', name: 'Suriname' },
  '598': { code: 'UY', dialCode: '+598', name: 'Uruguay' },
  '599': { code: 'AN', dialCode: '+599', name: 'Netherlands Antilles' },
  '672': { code: 'NF', dialCode: '+672', name: 'Norfolk Island' },
  '673': { code: 'BN', dialCode: '+673', name: 'Brunei Darussalam' },
  '674': { code: 'NR', dialCode: '+674', name: 'Nauru' },
  '675': { code: 'PG', dialCode: '+675', name: 'Papua New Guinea' },
  '676': { code: 'TO', dialCode: '+676', name: 'Tonga' },
  '677': { code: 'SB', dialCode: '+677', name: 'Solomon Islands' },
  '678': { code: 'VU', dialCode: '+678', name: 'Vanuatu' },
  '680': { code: 'PW', dialCode: '+680', name: 'Palau' },
  '681': { code: 'WF', dialCode: '+681', name: 'Wallis and Futuna' },
  '682': { code: 'CK', dialCode: '+682', name: 'Cook Islands' },
  '683': { code: 'NU', dialCode: '+683', name: 'Niue' },
  '685': { code: 'WS', dialCode: '+685', name: 'Samoa' },
  '686': { code: 'KI', dialCode: '+686', name: 'Kiribati' },
  '690': { code: 'TK', dialCode: '+690', name: 'Tokelau' },
  '691': {
    code: 'FM',
    dialCode: '+691',
    name: 'Micronesia, Federated States of',
  },
  '850': {
    code: 'KP',
    dialCode: '+850',
    name: "Korea, Democratic People's Republic of",
  },
  '852': { code: 'HK', dialCode: '+852', name: 'Hong Kong' },
  '853': { code: 'MO', dialCode: '+853', name: 'Macao' },
  '856': {
    code: 'LA',
    dialCode: '+856',
    name: "Lao People's Democratic Republic",
  },
  '872': { code: 'PN', dialCode: '+872', name: 'Pitcairn' },
  '886': {
    code: 'TW',
    dialCode: '+886',
    name: 'Taiwan',
  },
  '960': { code: 'MV', dialCode: '+960', name: 'Maldives' },
  '961': { code: 'LB', dialCode: '+961', name: 'Lebanon' },
  '962': { code: 'JO', dialCode: '+962', name: 'Jordan' },
  '963': { code: 'SY', dialCode: '+963', name: 'Syrian Arab Republic' },
  '965': { code: 'KW', dialCode: '+965', name: 'Kuwait' },
  '966': { code: 'SA', dialCode: '+966', name: 'Saudi Arabia' },
  '967': { code: 'YE', dialCode: '+967', name: 'Yemen' },
  '971': { code: 'AE', dialCode: '+971', name: 'United Arab Emirates' },
  '974': { code: 'QA', dialCode: '+974', name: 'Qatar' },
  '975': { code: 'BT', dialCode: '+975', name: 'Bhutan' },
  '976': { code: 'MN', dialCode: '+976', name: 'Mongolia' },
  '977': { code: 'NP', dialCode: '+977', name: 'Nepal' },
  '995': { code: 'GE', dialCode: '+995', name: 'Georgia' },
  '996': { code: 'KG', dialCode: '+996', name: 'Kyrgyzstan' },
  '1242': { code: 'BS', dialCode: '+1 242', name: 'Bahamas' },
  '1246': { code: 'BB', dialCode: '+1246', name: 'Barbados' },
  '1264': { code: 'AI', dialCode: '+1264', name: 'Anguilla' },
  '1268': { code: 'AG', dialCode: '+1268', name: 'Antigua and Barbuda' },
  '1284': {
    code: 'VG',
    dialCode: '+1284',
    name: 'Virgin Islands, British',
  },
  '1340': { code: 'VI', dialCode: '+1340', name: 'Virgin Islands, U.S.' },
  '1345': { code: 'KY', dialCode: '+1345', name: 'Cayman Islands' },
  '1441': { code: 'BM', dialCode: '+1441', name: 'Bermuda' },
  '1473': { code: 'GD', dialCode: '+1473', name: 'Grenada' },
  '1649': {
    code: 'TC',
    dialCode: '+1649',
    name: 'Turks and Caicos Islands',
  },
  '1664': { code: 'MS', dialCode: '+1664', name: 'Montserrat' },
  '1670': {
    code: 'MP',
    dialCode: '+1670',
    name: 'Northern Mariana Islands',
  },
  '1671': { code: 'GU', dialCode: '+1671', name: 'Guam' },
  '1758': { code: 'LC', dialCode: '+1758', name: 'Saint Lucia' },
  '1767': { code: 'DM', dialCode: '+1767', name: 'Dominica' },
  '1784': {
    code: 'VC',
    dialCode: '+1784',
    name: 'Saint Vincent and the Grenadines',
  },
  '1849': { code: 'DO', dialCode: '+1849', name: 'Dominican Republic' },
  '1868': { code: 'TT', dialCode: '+1868', name: 'Trinidad and Tobago' },
  '1869': { code: 'KN', dialCode: '+1869', name: 'Saint Kitts and Nevis' },
  '1876': { code: 'JM', dialCode: '+1876', name: 'Jamaica' },
  '1939': { code: 'PR', dialCode: '+1939', name: 'Puerto Rico' },
};
