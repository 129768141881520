import { combineReducers } from 'redux';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import _pickBy from 'lodash/pickBy';

import newAdmin from './newAdmin/reducer';
import editAdmin from './editAdmin/modules/modal/reducer';
import { escapeSepcialChars } from '../../../../../distribute/utils';

import {
  REQUEST_ADMINS,
  REQUEST_ADMINS_DELETE,
  RECEIVE_ADMINS,
  REPORT_ADMINS_REQUEST_ERROR,
  SELECT_ADMIN,
  FILTER_ADMIN_DATA,
  SELECT_ALL_ADMINS,
  DESELECT_ALL_ADMINS,
  DELETE_VALIDATION_ERROR,
} from './actions';

const initialState = {
  pending: false,
  chunks: {},
  selected: [],
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
};

const filterChunks = (chunks, filterHolder = '') => {
  console.log('chunks--->', chunks);
  console.log('filterHolder--->', filterHolder);
  let filteredChunks = chunks;
  const filter = escapeSepcialChars(filterHolder);

  if (filter !== '') {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`^${filter}.*$`, 'i');
      const fullName = `${chunk.firstName} ${chunk.lastName}`;
      return (
        rgx.test(chunk.email) ||
        rgx.test(chunk.firstName) ||
        rgx.test(chunk.lastName) ||
        rgx.test(fullName)
      );
      // return rgx.test(chunk.email);
    });
  }

  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const admin = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ADMINS:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case REQUEST_ADMINS_DELETE:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_ADMINS:
      return {
        ...state,
        chunks: action.value,
        selected: [],
        filteredChunks: action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };

    case FILTER_ADMIN_DATA:
      return {
        ...state,
        ...filterChunks(state.chunks, action.payload),
        filter: action.payload,
      };

    case REPORT_ADMINS_REQUEST_ERROR:
      return {
        pending: false,
        error: action.err,
      };

    case SELECT_ADMIN: {
      const prevState = state.filteredChunks[action.key].selected;
      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case SELECT_ALL_ADMINS: {
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: !chunk.default,
      }));
      const selected = _union(
        state.selected,
        Object.keys(filteredChunks).filter(chunk => chunk !== 'default'),
      );

      return {
        ...state,
        filteredChunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_ADMINS:
      return {
        ...state,
        filteredChunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    case DELETE_VALIDATION_ERROR:
      return {
        ...state,
        error: action.err,
        pending: false,
      };

    default:
      return state;
  }
};

export default combineReducers({
  data: admin,
  newAdmin,
  editAdmin,
});
