import firebase from '../../../../../firebase';

export const LOGIN_SUCCESS_INTEGRATION = 'LOGIN_SUCCESS_INTEGRATION';
export const LOGIN_ERROR_INTEGRATION = 'LOGIN_ERROR_INTEGRATION';

const loginSuccess = () => ({
  type: LOGIN_SUCCESS_INTEGRATION,
});

const loginError = errorMessage => ({
  type: LOGIN_ERROR_INTEGRATION,
  errorMessage,
});

export const logInUser = token => async dispatch => {
  try {
    await firebase.auth().signInWithCustomToken(token);
    dispatch(loginSuccess());
  } catch (error) {
    dispatch(loginError(error));
  }
};
