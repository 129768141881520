const initialState = {
  firstNameRequired: false,
  lastNameRequired: false,
  phoneNumberRequired: false,
  linkedInAddressRequired: false,
  twitterAddressRequired: false,
  websiteAddressRequired: false,
  organisationRequired: false,
  dobRequired: false,
  roleRequired: false,
  interestsRequired: false,
  addToDiyPopup: false,
  firstNameVisible: false,
  lastNameVisible: false,
  organisationVisible: false,
  roleVisible: false,
  dobVisible: false,
  phoneNumberVisible: false,
  linkedInAddressVisible: false,
  twitterAddressVisible: false,
  websiteAddressVisible: false,
  interestsVisible: false,
  userProfileField: {
    name: { value: '', isValid: null, isDirty: false },
    type: { value: '', isValid: null, isDirty: false },
    visibleToGroups: { value: ['default'], isValid: true, isDirty: false },
    options: { value: '', isValid: null, isDirty: false },
    isRequired: { value: false },
    isVisible: { value: false },
    isMultiple: { value: false },
  },
  pending: false,
  customProfileFields: null,
  open: false,
  customFieldKey: '',
};

const profileFields = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_FIELD_DIALOG':
      return {
        ...state,
        open: true,
      };

    case 'CLOSE_FIELD_DIALOG':
      return {
        ...state,
        open: false,
        userProfileField: initialState.userProfileField,
        customFieldKey: '',
      };

    case 'TOGGLE_FIELD':
      return {
        ...state,
        [action.field]: action.toggled,
      };

    case 'TOGGLE_DIY_ADD_POPUP':
      return {
        ...state,
        addToDiyPopup: action.value,
      };

    case 'NEW_FIELD_CHANGE':
      return {
        ...state,
        userProfileField: {
          ...state.userProfileField,
          [action.prop]: {
            value: action.value,
            isValid: action.isValid,
            isDirty: true,
          },
        },
      };

    case 'ADD_NEW_FIELD':
      return {
        ...state,
        userProfileField: {
          ...state.userProfileField,
        },
        pending: true,
        customFieldKey: '',
      };

    case 'NEW_FIELD_ADDED':
      return {
        ...state,
        userProfileField: initialState.userProfileField,
        open: false,
      };

    case 'UPDATE_CUSTOM_FIELD':
      return {
        ...state,
        userProfileField: initialState.userProfileField,
        open: false,
        customFieldKey: '',
      };

    case 'CUSTOM_PROFILE_FIELDS':
      return {
        ...state,
        customProfileFields: action.data,
      };

    case 'CUSTOM_FIELD_CLICK':
      return {
        ...state,
        open: true,
        userProfileField: state.customProfileFields && {
          ...state.userProfileField,
          ...state.customProfileFields[action.index][1],
        },
        pending: true,
        customFieldKey: action.key,
      };

    default:
      return state;
  }
};

export default profileFields;
