import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Trans, withTranslation } from 'react-i18next';
import ComponentItem from '../../../../components/componentItem';
import Container from '../../../../../../app/components/container';
import { CircularProgress } from '../../../../../../app/components/progress';
import DraggableListWrapper from '../../../../components/draggableListWrapper';
import {
  plans,
  components,
  plansWixWeebly,
  specialAddonPrices,
} from '../../../../../../plans';
import {
  PLANS_WITH_ADDONS,
  TIER_1_PLAN_ID,
} from '../../../../../../plans/constants';
import { HS_FEATURES_ENABLED_APPS } from '../../../../../../config';
import { objectToArray } from '../../../../../../utils/object';

const planNames = Object.keys(plans).concat(Object.keys(plansWixWeebly));

class ComponentList extends Component {
  static propTypes = {
    components: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    removeComponent: PropTypes.func.isRequired,
    rearrangeComponents: PropTypes.func.isRequired,
    rearrangeTabComponents: PropTypes.func.isRequired,
    subscription: PropTypes.oneOf(planNames).isRequired,
    getAllLazyData: PropTypes.func.isRequired,
    getSpecialComponents: PropTypes.func.isRequired,
    specialAddons: PropTypes.array.isRequired,
    duplicateComponent: PropTypes.func.isRequired,
    openCreateComponentUI: PropTypes.func.isRequired,
  };

  static defaultProps = {
    components: [],
    loading: false,
  };

  async componentDidMount() {
    this.props.getAllLazyData();
    await this.props.getSpecialComponents();
  }

  getComponentTitle = item => {
    const { t } = this.props;
    if (!components[item.type]) {
      return t('Unknown Component');
    } else if (
      item.lazyData &&
      (item.lazyData.title || item.lazyData.caption || item.lazyData.label)
    ) {
      return `${components[item.type].name} - ${item.lazyData.title ||
        item.lazyData.caption ||
        item.lazyData.label}`;
    } else if (
      item.type === 'Memberbodies' &&
      !(HS_FEATURES_ENABLED_APPS.indexOf(this.props.match.params.appname) > -1)
    ) {
      return t('Event Organizers');
    }
    return components[item.type].name;
  };

  getComponentList = () => {
    const removeComponent = this.props.removeComponent;
    const listComponents = this.props.components;
    const { duplicateComponent } = this.props;

    return listComponents.map(item => (
      <ComponentItem
        key={item.key}
        id={item.key}
        label={this.getComponentTitle(item)}
        onClick={() => this.onClickHandler(item.type, item.key)}
        onDelete={() => removeComponent(item.key)}
        onDuplicate={() => duplicateComponent(item)}
        onDrag={() => {}}
        disabled={this.isDisabled(item.type)}
        isSpecialComponent={this.isSpecialComponent(item.type)}
        openCreateComponentUI={this.props.openCreateComponentUI}
      />
    ));
  };

  getHref = target => {
    const { match, location, parentPageKey } = this.props;
    const { page, tab, parentPage } = queryString.parse(location.search);

    const tabQuery = tab ? `tab=${tab}&` : '';
    const parentKey = parentPageKey || parentPage;
    return {
      pathname: `/${match.params.appname}/editor/build/edit`,
      search: parentKey
        ? `page=${page}&${tabQuery}component=${target}&parentPage=${parentKey}`
        : `page=${page}&${tabQuery}component=${target}`,
    };
  };

  onClickHandler = (component, key) => {
    const specialComponent = this.props.specialAddons.filter(
      item => item.component === component,
    );
    if (
      specialComponent.length > 0 &&
      !specialComponent[0].hasActiveSubscription &&
      specialComponent[0].hasFreeTrial &&
      Date.now() > specialComponent[0].expiryDate
    ) {
      this.props.history.push({
        pathname: `/${this.props.match.params.appname}/pay-for-addon`,
        query: {
          component: components[component],
          price_details: specialAddonPrices[component],
        },
      });
    } else {
      this.props.history.push(this.getHref(key));
    }
  };

  hasActiveSubscription = component => {
    const specialComponent = this.props.specialAddons.filter(
      item => item.component === component,
    );
    if (
      specialComponent.length > 0 &&
      specialComponent[0].hasActiveSubscription
    ) {
      return true;
    }
    return false;
  };

  isSpecialComponent = component => {
    const specialComponent = this.props.specialAddons.filter(
      item => item.component === component,
    );
    if (specialComponent.length > 0) {
      return true;
    }
    return false;
  };

  isDisabled = component => {
    const specialComponent = this.props.specialAddons.filter(
      item => item.component === component,
    );
    if (
      specialComponent.length > 0 &&
      specialComponent[0].hasFreeTrial &&
      Date.now() > specialComponent[0].expiryDate &&
      !specialComponent[0].hasActiveSubscription
    ) {
      return true;
    }
    if (
      this.props.isAppSumAccount &&
      this.props.subscription !== TIER_1_PLAN_ID
    ) {
      return false;
    }
    let subscriptions;
    if (this.props.subscription && this.props.subscription.includes('diy'))
      subscriptions = 'diy';
    if (this.props.subscription && this.props.subscription.includes('deluxe'))
      subscriptions = 'deluxe';

    if (
      this.props.subscription &&
      this.props.subscription.includes('something')
    )
      subscriptions = 'something';

    if (PLANS_WITH_ADDONS.includes(subscriptions || this.props.subscription)) {
      if (
        plans[subscriptions || this.props.subscription].components.includes(
          component,
        )
      ) {
        return false;
      } else if (this.props.diyComponents) {
        const subscribedComponents = objectToArray(this.props.diyComponents);
        const currentDate = moment();
        const componentItem = subscribedComponents.find(
          item => item.id === component,
        );

        if (componentItem) {
          if (
            componentItem.value.status === 'deleted' &&
            !currentDate.isAfter(
              moment(componentItem.value.created).add(1, 'month'),
            )
          ) {
            return false;
          } else if (componentItem.value.status !== 'deleted') {
            return false;
          }
          return true;
        }
      }
    } else {
      return !plans[
        subscriptions || this.props.subscription
      ].components.includes(component);
    }
    return true;
  };

  handleMove = (el, target, source, sibling) => {
    const { tab } = queryString.parse(this.props.location.search);

    if (tab) {
      if (sibling) {
        return this.props.rearrangeTabComponents(el.id, sibling.id);
      }

      return this.props.rearrangeTabComponents(el.id, null);
    }

    if (sibling) {
      return this.props.rearrangeComponents(el.id, sibling.id);
    }

    return this.props.rearrangeComponents(el.id, null);
  };
  render() {
    const componentList = this.getComponentList();
    if (this.props.loading) {
      return (
        <Container>
          <CircularProgress size={24} thickness={3} />
        </Container>
      );
    }

    return (
      <DraggableListWrapper
        items={componentList}
        onPositionChange={this.handleMove}
      />
    );
  }
}

export default withTranslation()(ComponentList);
