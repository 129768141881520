import React from 'react';
import './styles.css';
const Buyers = () => (
  <svg
    version="1.0"
    className="icon buyers"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1080.000000 1080.000000"
  >
    <metadata>
      Created by potrace 1.16, written by Peter Selinger 2001-2019
    </metadata>
    <g
      transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M5280 9294 c-190 -28 -287 -57 -430 -130 -125 -64 -211 -127 -308
-226 -95 -97 -150 -172 -211 -288 -163 -312 -184 -671 -57 -1000 139 -360 452
-638 824 -730 562 -140 1133 122 1386 635 87 176 126 340 126 531 0 457 -261
880 -668 1084 -106 53 -247 98 -362 116 -86 13 -237 17 -300 8z m223 -294
c192 -22 381 -110 519 -239 130 -122 212 -255 265 -428 23 -78 26 -104 27
-238 0 -174 -17 -254 -84 -393 -51 -106 -89 -161 -169 -245 -354 -370 -934
-379 -1297 -22 -209 205 -308 479 -274 760 43 363 289 659 639 769 130 41 238
51 374 36z"
      />
      <path
        d="M4845 6704 c-311 -43 -467 -97 -669 -231 -161 -108 -289 -243 -537
-571 -649 -859 -620 -818 -655 -937 -35 -119 -17 -280 42 -383 l25 -43 -50
-45 c-70 -63 -145 -179 -172 -266 -13 -40 -24 -74 -24 -75 -1 -1 -54 7 -118
18 -109 19 -121 19 -171 5 -54 -16 -105 -57 -131 -106 -7 -14 -99 -430 -205
-925 -138 -647 -191 -912 -187 -942 2 -23 16 -62 31 -87 48 -82 35 -79 946
-225 448 -72 829 -131 846 -131 42 0 92 18 130 46 67 50 64 33 174 1014 61
544 100 924 96 952 -5 50 -44 118 -81 142 -29 19 -112 42 -206 57 l-76 11 -6
92 c-7 108 -26 186 -66 266 l-29 59 37 25 c57 40 109 98 237 266 65 85 178
233 251 329 l133 173 0 -1788 c0 -1683 1 -1791 18 -1823 27 -54 65 -76 127
-76 62 0 100 22 127 76 17 32 18 151 18 2060 l0 2026 -23 34 c-46 69 -139 87
-204 41 -16 -11 -193 -235 -394 -497 -200 -263 -381 -499 -402 -525 -52 -67
-112 -95 -202 -95 -59 0 -78 5 -117 28 -123 72 -132 212 -23 357 455 607 763
1008 813 1062 172 182 379 289 648 335 96 16 169 18 599 18 421 0 506 -3 605
-18 271 -43 479 -151 660 -344 41 -44 142 -169 225 -279 82 -109 255 -338 383
-507 129 -170 242 -328 252 -350 44 -101 34 -177 -34 -243 -97 -93 -252 -83
-347 22 -28 31 -132 167 -484 629 -321 422 -333 435 -399 435 -43 0 -105 -31
-129 -64 l-22 -31 0 -2035 c0 -1928 1 -2037 18 -2067 48 -88 163 -97 237 -18
l25 27 5 1805 5 1806 67 -89 c37 -49 111 -145 164 -214 53 -69 144 -188 202
-264 67 -88 128 -156 164 -184 32 -25 57 -48 55 -51 -58 -104 -87 -209 -88
-321 l-1 -86 -107 -18 c-128 -21 -176 -41 -218 -89 -62 -71 -64 -26 48 -1035
92 -838 103 -922 126 -970 29 -62 89 -103 162 -109 29 -3 289 35 701 102 360
58 727 117 815 131 88 14 174 30 190 36 83 31 134 103 135 190 0 39 -343 1680
-381 1822 -16 61 -83 121 -151 136 -45 9 -70 8 -162 -9 -60 -11 -113 -18 -117
-16 -4 3 -10 20 -13 39 -14 74 -87 199 -161 274 l-71 72 33 68 c18 37 38 94
44 127 23 122 -13 282 -87 394 -49 74 -710 945 -790 1041 -140 168 -291 294
-452 378 -123 64 -209 94 -363 128 l-124 28 -570 2 c-313 1 -583 0 -600 -2z
m-1405 -2398 c53 -35 96 -112 110 -198 7 -38 10 -71 8 -73 -2 -2 -98 11 -213
30 -116 18 -220 35 -233 37 l-22 3 29 60 c75 155 210 215 321 141z m4134 -6
c25 -17 57 -45 70 -63 25 -33 71 -126 64 -131 -1 -2 -95 -18 -208 -35 -113
-18 -215 -35 -227 -38 -21 -5 -23 -2 -23 39 0 103 53 202 133 246 58 32 128
26 191 -18z m-4669 -459 c105 -17 357 -58 560 -91 204 -33 389 -63 413 -67 30
-4 42 -10 41 -22 -1 -45 -175 -1591 -179 -1595 -5 -4 -1431 223 -1439 230 -2
2 72 359 165 795 l169 791 40 -5 c22 -3 126 -19 230 -36z m5275 -13 c6 -29 82
-386 169 -793 l158 -740 -236 -38 c-130 -21 -454 -74 -721 -117 -266 -43 -486
-77 -488 -76 -1 2 -40 349 -87 772 -46 423 -87 785 -89 804 l-6 36 268 42
c147 24 425 69 617 102 193 32 362 59 377 59 24 1 28 -4 38 -51z"
      />
      <path
        d="M5329 3959 c-74 -44 -69 54 -69 -1220 l0 -1143 22 -32 c57 -83 181
-84 239 -3 l24 34 0 1145 0 1145 -22 31 c-45 62 -128 81 -194 43z"
      />
    </g>
  </svg>
);

export default Buyers;
