//get dark background color for contrast
const colorLuminance = (hexString, luminosity) => {
  let hex = hexString;
  let lum = luminosity;
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = '#';
  let c;
  let i;
  for (i = 0; i < 3; i += 1) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 254)).toString(16);
    // rgb += ('00' + c).substr(c.length);
    rgb += `00${c}`.substr(c.length);
  }

  return rgb;
};

export const getRandomColor = () =>
  colorLuminance(`#${Math.floor(Math.random() * 16777215)}`);
// colorLuminance('#' + Math.floor(Math.random() * 16777215).toString(16));
