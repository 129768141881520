import React from 'react';

import './styles.css';

const View = () => (
  <svg
    className="icon view"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <g id="Layer_1" data-name="Layer 1">
      <circle style={{ fill: '#5eaa61' }} cx="16" cy="16" r="12" />
      <path d="M31,32H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H31a1,1,0,0,1,1,1V31A1,1,0,0,1,31,32ZM2,30H30V2H2Z" />
    </g>
  </svg>
);

export default View;
