import { connect } from 'react-redux';

import PDFViewer from './PDFViewer';
import { fieldChange, uploadFiles, deleteFile } from './actions';

const firebaseUrl = (appName, dataId, keyname) =>
  keyname
    ? `apps/${appName}/draft/lazy_data/${dataId}/files/${keyname}`
    : `apps/${appName}/draft/lazy_data/${dataId}/files`;

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.editor.build.pdfViewer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onReceivePdf: (files, setUploadDialogOpen, setFilesList) =>
    dispatch(
      uploadFiles(
        files,
        firebaseUrl(
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
        ),
        ownProps.match.params.appname,
        setUploadDialogOpen,
        setFilesList,
      ),
    ),
  onDeletePdf: (filename, keyname) =>
    dispatch(
      deleteFile(
        firebaseUrl(
          ownProps.match.params.appname,
          ownProps.component.lazy_data,
          keyname,
        ),
        filename,
      ),
    ),
  handleChange: (field, value, index) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
        index,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PDFViewer);
