import React from 'react';

const Workouttimer = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 42.81 49.53"
  >
    <title>Workout Timer component</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M20.75,49.53A20.75,20.75,0,1,1,41.5,28.66h0A20.77,20.77,0,0,1,20.87,49.53Zm0-40h-.12A19.25,19.25,0,0,0,20.75,48h.12A19.27,19.27,0,0,0,40,28.67h0A19.26,19.26,0,0,0,20.75,9.53Z"
        />
        <path
          class="cls-1"
          d="M20.75,44.63A15.85,15.85,0,1,1,36.6,28.69h0A15.84,15.84,0,0,1,20.85,44.63Zm0-30.2h-.09a14.35,14.35,0,0,0,.09,28.7h.09A14.34,14.34,0,0,0,35.1,28.69h0A14.34,14.34,0,0,0,20.75,14.43Z"
        />
        <path class="cls-1" d="M20.68,17.78A11,11,0,1,0,31.75,28.71l-11,.07Z" />
        <rect
          class="cls-1"
          x="16.87"
          y="4.8"
          width="1.5"
          height="4"
          transform="translate(-0.04 0.11) rotate(-0.34)"
        />
        <rect
          class="cls-1"
          x="22.87"
          y="4.76"
          width="1.5"
          height="4"
          transform="translate(-0.04 0.14) rotate(-0.34)"
        />
        <path
          class="cls-1"
          d="M15.32,5.56A2,2,0,0,1,13.93,5a2,2,0,0,1-.58-1.39V2a2,2,0,0,1,2-2L25.86,0a2.12,2.12,0,0,1,1.39.57A1.92,1.92,0,0,1,27.84,2V3.52a2,2,0,0,1-2,2l-10.56.06ZM25.87,1.5h0l-10.56.06a.48.48,0,0,0-.47.48V3.6a.47.47,0,0,0,.14.33.39.39,0,0,0,.33.13L25.88,4a.47.47,0,0,0,.47-.47V2a.44.44,0,0,0-.14-.33A.47.47,0,0,0,25.87,1.5Z"
        />
        <rect
          class="cls-1"
          x="33.27"
          y="10.81"
          width="2.89"
          height="1.5"
          transform="translate(2.09 28.12) rotate(-45.32)"
        />
        <rect
          class="cls-1"
          x="36.36"
          y="13.86"
          width="2.89"
          height="1.5"
          transform="translate(0.85 31.25) rotate(-45.37)"
        />
        <path
          class="cls-1"
          d="M40.61,15.51a2.2,2.2,0,0,1-1.54-.64L34.44,10.3a2.21,2.21,0,0,1,0-3.11,2.21,2.21,0,0,1,3.11,0l4.63,4.58a2.23,2.23,0,0,1,.65,1.55,2.22,2.22,0,0,1-2.2,2.21Zm-.49-1.7a.62.62,0,0,0,.5.2.75.75,0,0,0,.49-.21.7.7,0,0,0,.2-.49.71.71,0,0,0-.21-.5L36.47,8.24a.69.69,0,0,0-1,0,.64.64,0,0,0-.2.49.7.7,0,0,0,.2.49Z"
        />
      </g>
    </g>
  </svg>
);

export default Workouttimer;
