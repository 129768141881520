import React from 'react';

const LoyaltyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    height="512px"
    viewBox="0 0 128 128"
    width="512px"
    data-name="Layer 2"
    className="icon"
  >
    <title>Loyalty Component</title>
    <g>
      <g>
        <path
          d="m127.965 29.046a5.733 5.733 0 0 0 -5.7-5.147h-116.54a5.734 5.734 0 0 0 -5.7 5.147c-.017.198-.025 69.329-.025 69.329a5.732 5.732 0 0 0 5.725 5.725h116.545a5.732 5.732 0 0 0 5.724-5.726s-.008-69.13-.029-69.328zm-3.471 69.329a2.227 2.227 0 0 1 -2.224 2.226h-116.545a2.228 2.228 0 0 1 -2.225-2.226v-57.522h120.994zm0-61.022h-120.994v-7.728a2.268 2.268 0 0 1 2.247-2.225h116.5a2.268 2.268 0 0 1 2.247 2.226z"
          // data-original="#000000"
          // class="active-path"
          // data-old_color="#000000"
          // fill="#261ED4"
        />
        <path
          d="m106.185 66.607a9.625 9.625 0 1 0 -9.625-9.625 9.636 9.636 0 0 0 9.625 9.625zm0-15.75a6.125 6.125 0 1 1 -6.125 6.125 6.132 6.132 0 0 1 6.125-6.125z"
          // data-original="#000000"
          // class="active-path"
          // data-old_color="#000000"
          // fill="#261ED4"
        />
        <path
          d="m21.815 90.164a9.625 9.625 0 1 0 -9.625-9.625 9.636 9.636 0 0 0 9.625 9.625zm0-15.75a6.125 6.125 0 1 1 -6.125 6.125 6.132 6.132 0 0 1 6.125-6.125z"
          // data-original="#000000"
          // class="active-path"
          // data-old_color="#000000"
          // fill="#261ED4"
        />
        <path
          d="m49.938 90.164a9.625 9.625 0 1 0 -9.625-9.625 9.636 9.636 0 0 0 9.625 9.625zm0-15.75a6.125 6.125 0 1 1 -6.125 6.125 6.132 6.132 0 0 1 6.125-6.125z"
          // data-original="#000000"
          // class="active-path"
          // data-old_color="#000000"
          // fill="#261ED4"
        />
        <path
          d="m78.062 90.164a9.625 9.625 0 1 0 -9.625-9.625 9.636 9.636 0 0 0 9.625 9.625zm0-15.75a6.125 6.125 0 1 1 -6.125 6.125 6.133 6.133 0 0 1 6.125-6.125z"
          // data-original="#000000"
          // class="active-path"
          // data-old_color="#000000"
          // fill="#261ED4"
        />
        <path
          d="m106.185 90.164a9.625 9.625 0 1 0 -9.625-9.625 9.636 9.636 0 0 0 9.625 9.625zm0-15.75a6.125 6.125 0 1 1 -6.125 6.125 6.132 6.132 0 0 1 6.125-6.125z"
          // data-original="#000000"
          // class="active-path"
          // data-old_color="#000000"
          // fill="#261ED4"
        />
        <path
          d="m15.82 59.684-.384 4.276a1.75 1.75 0 0 0 2.43 1.767l3.949-1.687 3.95 1.687a1.768 1.768 0 0 0 2.435-1.767l-.384-4.276 2.825-3.234a1.752 1.752 0 0 0 -.929-2.858l-4.186-.956-2.2-3.686a1.75 1.75 0 0 0 -3 0l-2.2 3.686-4.186.956a1.751 1.751 0 0 0 -.94 2.858zm3.8-3.8a1.746 1.746 0 0 0 1.112-.808l1.085-1.815 1.083 1.812a1.751 1.751 0 0 0 1.113.808l2.06.471-1.39 1.592a1.751 1.751 0 0 0 -.425 1.308l.189 2.106-1.947-.831a1.758 1.758 0 0 0 -1.375 0l-1.944.831.189-2.106a1.751 1.751 0 0 0 -.425-1.308l-1.39-1.592z"
          // data-original="#000000"
          // class="active-path"
          // data-old_color="#000000"
          // fill="#261ED4"
        />
        <path
          d="m43.943 59.684-.384 4.276a1.75 1.75 0 0 0 2.43 1.767l3.949-1.687 3.95 1.687a1.768 1.768 0 0 0 2.43-1.767l-.384-4.276 2.825-3.234a1.752 1.752 0 0 0 -.929-2.858l-4.186-.956-2.2-3.686a1.749 1.749 0 0 0 -3 0l-2.2 3.686-4.186.956a1.751 1.751 0 0 0 -.929 2.858zm3.8-3.8a1.751 1.751 0 0 0 1.113-.808l1.084-1.815 1.085 1.815a1.751 1.751 0 0 0 1.113.808l2.06.471-1.39 1.592a1.751 1.751 0 0 0 -.425 1.308l.189 2.106-1.944-.831a1.758 1.758 0 0 0 -1.375 0l-1.944.831.189-2.106a1.751 1.751 0 0 0 -.425-1.308l-1.39-1.592z"
          // data-original="#000000"
          // class="active-path"
          // data-old_color="#000000"
          // fill="#261ED4"
        />
        <path
          d="m72.066 59.684-.384 4.276a1.75 1.75 0 0 0 2.43 1.767l3.95-1.687 3.949 1.687a1.768 1.768 0 0 0 2.43-1.767l-.384-4.276 2.825-3.234a1.751 1.751 0 0 0 -.929-2.858l-4.186-.956-2.2-3.686a1.749 1.749 0 0 0 -3 0l-2.2 3.686-4.186.956a1.752 1.752 0 0 0 -.929 2.858zm3.8-3.8a1.751 1.751 0 0 0 1.113-.808l1.085-1.815 1.084 1.815a1.751 1.751 0 0 0 1.113.808l2.06.471-1.39 1.592a1.751 1.751 0 0 0 -.425 1.308l.189 2.106-1.944-.831a1.758 1.758 0 0 0 -1.375 0l-1.944.831.189-2.106a1.751 1.751 0 0 0 -.425-1.308l-1.396-1.595z"
          // data-original="#000000"
          // class="active-path"
          // data-old_color="#000000"
          // fill="#261ED4"
        />
      </g>
    </g>{' '}
  </svg>
);

export default LoyaltyIcon;
