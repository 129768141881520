import {
  OPEN_NEW_FILTER_DIALOG,
  CLOSE_NEW_FILTER_DIALOG,
  NEW_FILTER_ADDED,
  NEW_FILTER_FIELD_CHANGE,
  ADD_NEW_FILTER,
} from './types';

const initialState = {
  pending: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  name: {
    value: '',
    isValid: null,
  },
  description: {
    value: '',
    isValid: null,
  },
  filterType: {
    value:'',
    isValid:null
  }
};

const newFilter = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_NEW_FILTER_DIALOG:
      return {
        ...state,
        filterImage: null,
        open: true,
      };

    case CLOSE_NEW_FILTER_DIALOG:
      return {
        ...state,
        open: false,
      };

    case NEW_FILTER_FIELD_CHANGE:
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };

    case ADD_NEW_FILTER:
      return {
        ...state,
        pending: true,
      };

    case NEW_FILTER_ADDED:
      return initialState;

    default:
      return state;
  }
};

export default newFilter;
