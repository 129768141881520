import { connect } from 'react-redux';

import YouTube from './YouTube';
import { urlChange, loop, muted, autoPlay } from './actions';

const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: value =>
    dispatch(
      urlChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
      ),
    ),
  onChangeLoop: value =>
    dispatch(
      loop(ownProps.match.params.appname, ownProps.component.lazy_data, !value),
    ),
  onChangeMuted: value =>
    dispatch(
      muted(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        !value,
      ),
    ),
  onChangeAutoPlay: value =>
    dispatch(
      autoPlay(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        !value,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(YouTube);
