import React from 'react';
import PropTypes from 'prop-types';

import { FlatButton } from '../buttons';
import './styles.css';

const TryAgain = ({ message, onRetry }) => (
  <div className="try-again">
    <h5 className="try-again-fail-message">{message}</h5>
    <FlatButton label="Try Again" onClick={onRetry} primary />
  </div>
);

TryAgain.propTypes = {
  message: PropTypes.string.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export default TryAgain;
