import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import _get from 'lodash/get';
import AppDomainInput from './AppDomainInput';
import { domainChange } from './actions';

const mapStateToProps = state => ({
  ...state,
  ...state.editor.internals.appDomainInput,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDomainChange: domain =>
    dispatch(domainChange(ownProps.match.params.appname, domain)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppDomainInput),
);
