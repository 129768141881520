import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MenuItem from 'material-ui/MenuItem';
import Toggle from 'material-ui/Toggle';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import Divider from 'material-ui/Divider';
import debounce from 'lodash/debounce';
import { Trans, withTranslation } from 'react-i18next';
import SelectField from '../../../../../../../app/components/selectField';
import TextField from '../../../../../../../app/components/textField';
import ErrorMessage from '../../../../../../../app/components/errorMessage';
import DraggableListWrapper from '../../../../../components/draggableListWrapper';
import ComponentItem from '../../../../../components/componentItem';
import SectionHeader from '../../../../../components/sectionHeader';
import CreateField from '../../../../../components/createField';
import Container from '../../../../../../../app/components/container';
import { CircularProgress } from '../../../../../../../app/components/progress';
import ButtonDesignMenu from '../../../../../components/buttonDesignMenu';
import DesignToggle from '../../../../../components/designToggle';
import { Tabs, Tab } from '../../../../../components/tabs';
import DeleteError from '../../../../../../editor/components/deleteError';
import TitleSetter from '../../../../../../editor/components/titleSetter';
import { capitaliseString } from '../../../../../../../utils';
import ButtonLabelSetter from '../../../../../../editor/components/buttonLabelSetter';
import themeStyles from '../../../../../../../_export.scss';
import './styles.css';

const minFields = 2;
const TrackSwitchedColor = { backgroundColor: themeStyles.primaryColorLight };
const ThumbSwitchedColor = { backgroundColor: themeStyles.primaryColor };

const styles = {
  picker: {
    width: 290,
  },
  toggle: {
    marginTop: 24,
    marginBottom: 12,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 6,
  },
  toggleLabel: {
    fontSize: 14,
  },
  dragWrapper: {
    width: '100%',
  },
  divider: {
    width: '100%',
    height: '2px',
    marginTop: '20px',
  },
};

let types = [
  'text',
  'email',
  'checkbox',
  'file',
  'number',
  'phone number',
  'dropdown',
  'location',
];

// this never changes, so mapping once on runtime is fine...
let options = types.map(
  type =>
    type !== 'file' &&
    type !== 'location' &&
    type !== 'dropdown' && (
      <MenuItem key={type} value={type} primaryText={capitaliseString(type)} />
    ),
);

class Vote extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onAddItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    setPropVote: PropTypes.func.isRequired,
    rearrangeItems: PropTypes.func.isRequired,
    onButtonDesignChange: PropTypes.func.isRequired,
  };

  state = {
    deleteObjectError: false,
    gaToggled: false,
    gaCategoryValue: '',
    gaActionValue: '',
  };

  async componentDidMount() {
    this.setState({ gaToggled: this.props.data.gaEnabled || false });
    this.setState({ gaCategoryValue: this.props.data.gaCategory || '' });
    this.setState({ gaActionValue: this.props.data.gaAction || '' });
    await this.props.onLoadGoogleAnalytics();
    !this.props.googleAnalytics.id &&
      this.setState({ gaToggled: false }, () => {
        this.handleChange('gaEnabled')(false);
      });
  }

  onToggle(fbNode, value) {
    const { data, setPropVote } = this.props;

    if (value && !data[fbNode].timestamp) {
      setPropVote('timestamp', new Date().toISOString(), fbNode);
    }
    setPropVote('enabled', value, fbNode);
  }

  handleRecordUserDataToggle(fbNode, value) {
    this.props.setPropVote(fbNode, value);
  }

  renderItems = () => {
    const { fields } = this.props.data;
    return fields.order.map((key, i) => (
      <>
        <ComponentItem
          id={key}
          key={key}
          label={`${fields.chunks[key].type.charAt(0).toUpperCase() +
            fields.chunks[key].type.slice(1)}${
            fields.chunks[key].label ? ` - ${fields.chunks[key].label}` : ''
          }`}
          onClick={this.handleNavigate(key)}
          onDelete={() => this.handleRemove(key, i)}
        />
      </>
    ));
  };

  handleAddField = value => {
    this.setState({ deleteObjectError: false });
    this.props
      .onAddField(value)
      .then(items => this.handleNavigate(items.pop())());
  };

  onDateChange(fbNode, date) {
    const { data, setPropVote } = this.props;

    const currentDate = new Date(data[fbNode].timestamp);
    if (!moment(date).isSame(currentDate, 'day')) {
      date.setHours(currentDate.getHours());
      date.setMinutes(currentDate.getMinutes());
    }

    setPropVote('timestamp', moment(date).toISOString(), fbNode);
  }

  onSubmitNewItem = item => {
    this.props
      .onAddItem(item)
      .then(items => this.handleNavigate(items.pop())());
  };

  onToggleTitleDisplay = enabled =>
    this.props.setPropVote('displayTitle', enabled);

  onChangeTitle = debounce(item => {
    this.props.setPropVote('title', item);
  }, 800);

  onChangeButtonLabel = label => this.props.setPropVote('buttonLabel', label);

  getItemHref = key => {
    const { match, location } = this.props;

    return {
      pathname: `/${match.params.appname}/editor/build/item`,
      search: `${location.search}&item=${key}`,
    };
  };

  handleChange = prop => value => this.props.onChange(prop, value);

  handleGaToggle = () => {
    if (!this.props.googleAnalytics.id) {
      return;
    }
    this.setState({ gaToggled: !this.state.gaToggled }, () => {
      this.handleChange('gaEnabled')(this.state.gaToggled);
    });
  };

  handleGaCategoryChange = value => {
    this.setState({ gaCategoryValue: value }, () => {
      this.handleChange('gaCategory')(value);
    });
  };

  handleGaActionChange = value => {
    this.setState({ gaActionValue: value }, () => {
      this.handleChange('gaAction')(value);
    });
  };

  handleNavigate = key => () => {
    const href = this.getItemHref(key);

    return this.props.history.push(href);
  };

  votingTimeToogle = (title, ref, data, min = new Date()) => {
    const { t } = this.props;
    const date =
      data.enabled && data.timestamp ? new Date(data.timestamp) : new Date();
    const minDate = typeof min === 'string' ? new Date(min) : min;

    return (
      data.enabled && (
        <Container>
          <DatePicker
            style={styles.picker}
            hintText={`${title} ${t('Date')}`}
            value={date}
            minDate={minDate}
            onChange={(e, timestamp) => this.onDateChange(ref, timestamp)}
          />
          <TimePicker
            style={styles.picker}
            hintText={`${title} ${t('Time')}`}
            value={date}
            onChange={(e, timestamp) => this.onDateChange(ref, timestamp)}
          />
        </Container>
      )
    );
  };

  handleMove = (el, target, source, sibling) =>
    sibling
      ? this.props.rearrangeItems(el.id, sibling.id)
      : this.props.rearrangeItems(el.id, null);

  handleFieldsMove = (el, target, source, sibling) =>
    sibling
      ? this.props.onRearrangeField(el.id, sibling.id)
      : this.props.onRearrangeField(el.id, null);

  handleRemoveOption = key => {
    const { data } = this.props;

    if (
      data &&
      data.options &&
      data.options.chunks &&
      Object.keys(data.options.chunks).length <= minFields
    ) {
      return this.state.deleteObjectError
        ? null
        : this.setState({ deleteObjectError: true });
    }
    return this.props.onRemoveItem(key);
  };

  handleRemove = (key, index) => {
    const { data } = this.props;

    if (
      data &&
      data.fields &&
      data.fields.chunks &&
      Object.keys(data.fields.chunks).length <= minFields
    ) {
      return this.state.deleteObjectError
        ? null
        : this.setState({ deleteObjectError: true });
    }
    return this.props.onRemoveField(index, key);
  };

  contentTab = () => {
    const { data, t } = this.props;
    const { title, displayTitle } = data;
    return (
      <Container>
        <TitleSetter
          displayTitle={displayTitle !== undefined ? displayTitle : true}
          onToggleTitleDisplay={this.onToggleTitleDisplay}
          onChangeTitle={this.onChangeTitle}
          title={title}
          typeOfComponent="vote"
          isValid={title !== '' && title !== undefined}
          required
        />
        <Toggle
          label={t('Enable Vote Opening Time')}
          style={styles.toggle}
          labelStyle={styles.toggleLabel}
          toggled={data.start.enabled}
          onToggle={(e, enabled) => this.onToggle('start', enabled)}
          trackSwitchedStyle={TrackSwitchedColor}
          thumbSwitchedStyle={ThumbSwitchedColor}
        />
        {this.votingTimeToogle(t('Vote Opening'), 'start', data.start)}

        <Toggle
          label={t('Enable Vote Closing Time')}
          style={styles.toggle}
          labelStyle={styles.toggleLabel}
          toggled={data.end.enabled}
          onToggle={(e, enabled) => this.onToggle('end', enabled)}
          trackSwitchedStyle={TrackSwitchedColor}
          thumbSwitchedStyle={ThumbSwitchedColor}
        />
        {this.votingTimeToogle(
          t('Vote Closing'),
          'end',
          data.end,
          data.start.timestamp,
        )}
        <Divider style={styles.divider} />
        <SectionHeader title={t('Vote Options')} />
        <CreateField
          id="roster-label"
          hintText={t('Add Vote Option')}
          tooltip={t('Add Vote Option')}
          onSubmit={this.onSubmitNewItem}
        />
        <div style={styles.dragWrapper}>
          <DraggableListWrapper
            items={this.renderVoteOptions()}
            onPositionChange={this.handleMove}
          />
          {this.state.deleteObjectError && (
            <DeleteError text={t('vote options')} number={minFields} />
          )}
        </div>
        <ButtonLabelSetter
          onChange={this.onChangeButtonLabel}
          label={data.buttonLabel || t('Submit Vote')}
        />
        <Divider style={styles.divider} />
        <SectionHeader
          title={t('Record user voting data')}
          actions={
            <Toggle
              onToggle={(e, enabled) =>
                this.handleRecordUserDataToggle('recordUserData', enabled)
              }
              toggled={data.recordUserData || false}
            />
          }
          tooltip={t('User data will recorded when they submit the vote')}
          tooltipType={'info'}
        />
        {data.recordUserData && (
          <>
            <SelectField
              label={t('Add Input Type')}
              options={options}
              onChange={(i, value) => this.handleAddField(value)}
            />
            {this.props.data && this.props.data.fields && (
              <div style={{ width: '100%' }}>
                <DraggableListWrapper
                  items={this.renderItems()}
                  onPositionChange={this.handleFieldsMove}
                />
                {this.state.deleteObjectError && (
                  <DeleteError text={t('form fields')} number={0} />
                )}
              </div>
            )}
          </>
        )}
        <Divider style={styles.divider} />

        <div style={{ width: '100%' }}>
          <SectionHeader
            title={t('Google Analytics Events')}
            actions={
              <Toggle
                onToggle={this.handleGaToggle}
                toggled={this.state.gaToggled}
              />
            }
            tooltip={
              !this.props.googleAnalytics.id
                ? t('You need to enable Google Analytics to use this feature')
                : t('Set google analytics events on button click')
            }
            tooltipType={!this.props.googleAnalytics.id ? 'warning' : 'info'}
          />
          <TextField
            value={this.state.gaCategoryValue}
            onChange={value => this.handleGaCategoryChange(value)}
            placeholder={t('GA Category')}
            disabled={!this.state.gaToggled}
          />
          {!this.state.gaCategoryValue && this.state.gaToggled && (
            <ErrorMessage style={{ textAlign: 'center' }}>
              <Trans>GA Category is required!</Trans>
            </ErrorMessage>
          )}
          <TextField
            value={this.state.gaActionValue}
            onChange={value => this.handleGaActionChange(value)}
            placeholder={t('GA Action')}
            disabled={!this.state.gaToggled}
          />
          {!this.state.gaActionValue && this.state.gaToggled && (
            <ErrorMessage style={{ textAlign: 'center' }}>
              <Trans>GA Action is required!</Trans>
            </ErrorMessage>
          )}
        </div>
      </Container>
    );
  };

  renderVoteOptions = () => {
    const { data } = this.props;

    if (!data.options || !data.options.order) return [];

    return data.options.order.map(key => {
      const title = data.options.chunks[key] && data.options.chunks[key].title;
      return (
        <ComponentItem
          id={key}
          key={key}
          label={title}
          onClick={this.handleNavigate(key)}
          onDelete={() => this.handleRemoveOption(key)}
        />
      );
    });
  };

  render() {
    const { data, component, onButtonDesignChange, t } = this.props;

    return data && data.start ? (
      <Tabs className="vote-tabs">
        <Tab label={t('content')}>{this.contentTab()}</Tab>
        {component.design && (
          <Tab label={t('design')}>
            <Container>
              <DesignToggle
                component={component}
                onDesignChange={(el, isToggled) =>
                  onButtonDesignChange(null)(el, isToggled)
                }
              />
              <SectionHeader
                title={t('Button')}
                tooltip={t('Define the style of your Button')}
              />
              <ButtonDesignMenu
                component={component}
                onDesignChange={onButtonDesignChange('buttonDesign')}
              />
            </Container>
          </Tab>
        )}
        <Tab
          label={t('layout')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    ) : (
      <Container>
        <CircularProgress size={24} thickness={3} />
      </Container>
    );
  }
}

export default withTranslation()(Vote);
