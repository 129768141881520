import _pick from 'lodash/pick';
import { isEmpty } from 'lodash';
import i18n from 'i18next';
import firebase from '../../../../../../firebase';
import { objectToArray, arrayToObject } from '../../../../../../utils';

import {
  REQUEST_MARKETPLACE_BUYERS,
  STOP_RECEIVING_MARKETPLACE_BUYERS,
  RECEIVE_MARKETPLACE_BUYERS,
  NO_MARKETPLACE_BUYERS_EXIST,
  FILTER_MARKETPLACE_BUYERS_DATA,
  SELECT_MARKETPLACE_BUYER,
  SELECT_ALL_MARKETPLACE_BUYERS,
  DESELECT_ALL_MARKETPLACE_BUYERS,
  OPEN_MARKETPLACE_BUYERS_DIALOG,
  CLOSE_MARKETPLACE_BUYERS_DIALOG,
  DELETE_SELECTED,
  ACTION_ERROR,
  EMAIL_SELECTED,
  REQUEST_GROUPS,
  RECEIVE_GROUPS,
  SORT_BUYERS,
} from './types';

export const openBuyerDialog = () => dispatch =>
  dispatch({
    type: OPEN_MARKETPLACE_BUYERS_DIALOG,
  });

export const closeBuyerDialog = () => ({
  type: CLOSE_MARKETPLACE_BUYERS_DIALOG,
});

const requestBuyers = appname => ({
  type: REQUEST_MARKETPLACE_BUYERS,
  appname,
});

const requestGroups = appname => ({
  type: REQUEST_GROUPS,
  appname,
});

export const getBuyers = appname => dispatch => {
  dispatch(requestBuyers(appname));

  firebase
    .database()
    .ref(`app_users/${appname}`)
    .on('value', snap => {
      if (!snap.exists()) {
        dispatch({
          type: NO_MARKETPLACE_BUYERS_EXIST,
        });
      } else {
        const buyers = {};
        snap.forEach(childSnapshot => {
          if (childSnapshot.hasChild('email')) {
            buyers[childSnapshot.key] = childSnapshot.val();
          }
        });
        if (!isEmpty(buyers)) {
          dispatch({
            type: RECEIVE_MARKETPLACE_BUYERS,
            value: buyers,
          });
        } else {
          dispatch({
            type: NO_MARKETPLACE_BUYERS_EXIST,
          });
        }
      }
    });
};

export const getGroups = appname => dispatch => {
  dispatch(requestGroups(appname));

  firebase
    .database()
    .ref(`app_groups/${appname}/groups`)
    .on('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_GROUPS,
          value: snap.val(),
        });
      }
    });
};

export const filterBuyerData = filter => dispatch =>
  dispatch({
    type: FILTER_MARKETPLACE_BUYERS_DATA,
    payload: filter,
  });

export const selectBuyer = key => ({
  type: SELECT_MARKETPLACE_BUYER,
  key,
});

export const selectAllBuyers = () => ({
  type: SELECT_ALL_MARKETPLACE_BUYERS,
});

export const deselectAllBuyers = () => ({
  type: DESELECT_ALL_MARKETPLACE_BUYERS,
});

export const deleteSelectedSuccess = (appname, selected) => dispatch =>
  dispatch({
    type: DELETE_SELECTED,
    appname,
    selected,
  });

export const emailSelectedSuccess = () => dispatch =>
  dispatch({
    type: EMAIL_SELECTED,
  });

export const reportActionError = (
  error,
  appname,
  selected,
  action,
) => dispatch =>
  dispatch({ type: ACTION_ERROR, error, appname, selected, action });

const emailUsers = (appname, userEmails, emailFields) =>
  new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;
    const { uid } = user;

    return user.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_MARKETPLACE_API}/mail-users`, {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
          appname,
          uid,
          userEmails,
          emailFields,
          accessToken: token,
          requestOrigin: 'platform',
        }),
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            return resolve(res);
          }

          throw new Error(
            i18n.t('Opps, something went wrong. Please try again.'),
          );
        })
        .catch(reject);
    });
  });

const deleteUser = (appname, email) =>
  new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;

    return user.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_MEMBER_API}/member`, {
        method: 'delete',
        mode: 'cors',
        body: JSON.stringify({
          appname,
          email,
          accessToken: token,
          requestOrigin: 'platform',
        }),
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            return resolve(res);
          }

          throw new Error(
            i18n.t('Opps, something went wrong. Please try again.'),
          );
        })
        .catch(reject);
    });
  });

export const deleteSelected = (appname, selected, chunks) => dispatch => {
  Object.values(_pick(chunks, selected)).forEach(user => {
    deleteUser(appname, user.email)
      .then(data => {
        dispatch(deleteSelectedSuccess(appname, selected, data));
        dispatch(getBuyers(appname));
      })
      .catch(error => {
        dispatch(reportActionError(error));
      });
  });
};

export const emailSelected = (
  appname,
  selected,
  chunks,
  emailFields,
) => dispatch => {
  const userEmails = [];
  Object.values(_pick(chunks, selected)).forEach(user => {
    userEmails.push(user.email);
  });
  emailUsers(appname, userEmails, emailFields)
    .then(() => {
      dispatch(emailSelectedSuccess());
    })
    .catch(error => {
      dispatch(reportActionError(error));
    });
};

export const stopGetBuyers = appname => dispatch => {
  firebase
    .database()
    .ref(`app_users/${appname}`)
    .off('value');
  dispatch({
    type: STOP_RECEIVING_MARKETPLACE_BUYERS,
    value: appname,
  });
};

export const sortTableData = cols => dispatch =>
  dispatch({ type: SORT_BUYERS, column: cols });
