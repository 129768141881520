import styled from 'styled-components';

const Pip = styled.span`
  background: #e75054;
  border-radius: 4px;
  display: inline-block;
  height: 4px;
  margin: 20px 4px;
  vertical-align: top;
  width: 4px;
  position: absolute;
`;

export default Pip;
