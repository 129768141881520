/* eslint-disable camelcase */
import { objectToArray } from '../../../../utils';
import { validateField } from '../../../memberLogin/modules/memberBodies/validators';
import firebase from '../../../../firebase';

export const TOGGLE_PL_MATCH_SUPPORT_MODAL = 'TOGGLE_PL_MATCH_SUPPORT_MODAL';

export const NEW_PL_MATCH_SUPPORT_FIELD_CHANGE =
  'NEW_PL_MATCH_SUPPORT_FIELD_CHANGE';
export const ADD_NEW_PL_MATCH_SUPPORT_CENTRE =
  'ADD_NEW_PL_MATCH_SUPPORT_CENTRE';
export const NEW_PL_MATCH_SUPPORT_CENTRE_ADDED =
  'NEW_PL_MATCH_SUPPORT_CENTRE_ADDED';
export const REQUEST_PL_MATCH_SUPPORT_CENTRE =
  'REQUEST_PL_MATCH_SUPPORT_CENTRE';
export const RECEIVE_PL_MATCH_SUPPORT_CENTRE =
  'RECEIVE_PL_MATCH_SUPPORT_CENTRE';
export const SELECT_ALL_PL_MATCH_SUPPORT_CENTRES =
  'SELECT_ALL_PL_MATCH_SUPPORT_CENTRES';
export const DESELECT_ALL_PL_MATCH_SUPPORT_CENTRES =
  'DESELECT_ALL_PL_MATCH_SUPPORT_CENTRES';
export const SELECT_PL_MATCH_SUPPORT_CENTRE = 'SELECT_PL_MATCH_SUPPORT_CENTRE';
export const FILTER_PL_MATCH_SUPPORT_CENTRE_DATA =
  'FILTER_PL_MATCH_SUPPORT_CENTRE_DATA';
export const OPEN_PL_MATCH_SUPPORT_CENTRE_DIALOG_UPDATE_DATA =
  'OPEN_PL_MATCH_SUPPORT_CENTRE_DIALOG_UPDATE_DATA';
export const CLEAR_HOTEL_NOT_AVAILABLE_FIELDS =
  'CLEAR_HOTEL_NOT_AVAILABLE_FIELDS';
export const CLEAR_THERAPIST_NOT_AVAILABLE_FIELDS =
  'CLEAR_THERAPIST_NOT_AVAILABLE_FIELDS';
export const TOGGLE_PL_SNACKBAR = 'TOGGLE_PL_SNACKBAR';

export const toggleMatchSupportModal = value => ({
  type: TOGGLE_PL_MATCH_SUPPORT_MODAL,
  value,
});

const newPlMatchSupportFieldChange = (prop, value, isValid) => ({
  type: NEW_PL_MATCH_SUPPORT_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

export const selectMatchSupportCentre = key => ({
  type: SELECT_PL_MATCH_SUPPORT_CENTRE,
  key,
});

export const selectAllMatchCentres = () => ({
  type: SELECT_ALL_PL_MATCH_SUPPORT_CENTRES,
});
export const deselectAllMatchCentres = () => ({
  type: DESELECT_ALL_PL_MATCH_SUPPORT_CENTRES,
});

export const requestPlMatchSupportCentre = appname => ({
  type: REQUEST_PL_MATCH_SUPPORT_CENTRE,
  appname,
});

export const filterMatchSupportData = filter => dispatch => {
  dispatch({
    type: FILTER_PL_MATCH_SUPPORT_CENTRE_DATA,
    payload: filter,
  });
};

export const formFieldUniqueCheck = (
  prop,
  value,
  filteredChunks,
) => dispatch => {
  const dataToCheck = objectToArray(filteredChunks);

  const isValid = !dataToCheck.find(
    i => i.value.location.toLowerCase() === value.toLowerCase(),
  );

  return dispatch(newPlMatchSupportFieldChange(prop, value, isValid));
};

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;

  // if (prop.includes('_email') || prop.includes('_phone')) {
  //   tempValue = value && value.trim().replace(/\s/g, '');
  // } else
  if (prop === 'hotel_available' && !value) {
    dispatch({ type: CLEAR_HOTEL_NOT_AVAILABLE_FIELDS });
  } else if (prop === 'therapist_available' && !value) {
    dispatch({ type: CLEAR_THERAPIST_NOT_AVAILABLE_FIELDS });
  }
  return dispatch(newPlMatchSupportFieldChange(prop, value, isValid));
};

export const getPlMatchSupportCentre = appname => dispatch => {
  dispatch(requestPlMatchSupportCentre(appname));
  firebase
    .database()
    .ref(`pgmol/pl_match_support_centre/${appname}`)
    .on('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_PL_MATCH_SUPPORT_CENTRE,
          value: snap.val(),
        });
      } else {
        dispatch({
          type: RECEIVE_PL_MATCH_SUPPORT_CENTRE,
          value: [],
        });
      }
    });
};

export const addPlMatchSupportCentre = appname => (dispatch, getState) => {
  const {
    location,
    hotel_available,
    hotel,
    hotel_lat,
    hotel_lng,
    hotel_phone,
    therapist_available,
    therapist,
    therapist_phone,
    therapist_email,
    availability,
    isUpdate,
    rowId,
  } = getState().pgmol.plMatchSupport;

  const centrePayload = {
    location: location.value,
    hotel: hotel.value,
    hotel_lat: hotel_lat.value,
    hotel_lng: hotel_lng.value,
    hotel_available: hotel_available.value,
    hotel_phone: hotel_phone.value,
    therapist_available: therapist_available.value,
    therapist: therapist.value,
    therapist_phone: therapist_phone.value,
    therapist_email: therapist_email.value,
    availability: availability.value,
    date: Date.now(),
  };

  let isError = false;

  if (centrePayload.hotel_available && !centrePayload.hotel.length) {
    dispatch(newPlMatchSupportFieldChange('hotel', '', false));
    isError = true;
  }
  if (centrePayload.hotel_available && !centrePayload.hotel_phone.length) {
    dispatch(newPlMatchSupportFieldChange('hotel_phone', '', false));
    isError = true;
  }

  if (
    centrePayload.hotel_available &&
    centrePayload.therapist_available &&
    !centrePayload.therapist.length
  ) {
    dispatch(newPlMatchSupportFieldChange('therapist', '', false));
    isError = true;
  }
  if (
    centrePayload.hotel_available &&
    centrePayload.therapist_available &&
    !centrePayload.therapist_phone.length
  ) {
    dispatch(newPlMatchSupportFieldChange('therapist_phone', '', false));
    isError = true;
  }
  if (
    centrePayload.hotel_available &&
    centrePayload.therapist_available &&
    !centrePayload.therapist_email.length
  ) {
    dispatch(newPlMatchSupportFieldChange('therapist_email', '', false));
    isError = true;
  }
  if (
    centrePayload.hotel_available &&
    centrePayload.therapist_available &&
    !centrePayload.availability.length
  ) {
    dispatch(newPlMatchSupportFieldChange('availability', '', false));
    isError = true;
  }

  if (isError) {
    return dispatch({ type: 'TOGGLE_PL_SNACKBAR' });
  }

  dispatch({ type: ADD_NEW_PL_MATCH_SUPPORT_CENTRE });
  if (isUpdate) {
    return firebase
      .database()
      .ref(`pgmol/pl_match_support_centre/${appname}/${rowId}`)
      .update(centrePayload)
      .then(() => {
        const newCentreID = rowId;
        dispatch({
          type: NEW_PL_MATCH_SUPPORT_CENTRE_ADDED,
          key: newCentreID,
          data: centrePayload,
        });
        // reset temporary image
        firebase
          .database()
          .ref(`pgmol/tempImages/images`)
          .set(null);
      });
  }
  return firebase
    .database()
    .ref(`pgmol/pl_match_support_centre/${appname}`)
    .push(centrePayload)
    .then(ref => {
      const newCentreID = ref.key;
      dispatch({
        type: NEW_PL_MATCH_SUPPORT_CENTRE_ADDED,
        key: newCentreID,
        data: centrePayload,
      });
      // reset temporary image
      firebase
        .database()
        .ref(`pgmol/tempImages/images`)
        .set(null);
    });
};

export const deletePlMatchSupportCentre = (appname, centerId) => {
  if (centerId !== 'default') {
    firebase
      .database()
      .ref(`pgmol/pl_match_support_centre/${appname}/${centerId}`)
      .remove();
  }
};

export const deletePlMatchSupportCentres = (appname, selectedRowId) => (
  dispatch,
  getState,
) => {
  const { selected } = getState().pgmol.plMatchSupport;
  if (!selected.length && selectedRowId.length) {
    Promise.all(
      selectedRowId.map(key => deletePlMatchSupportCentre(appname, key)),
    );
  } else {
    Promise.all(selected.map(key => deletePlMatchSupportCentre(appname, key)));
  }
};

export const updatePlMatchSupportCentre = (rowId, data) => dispatch => {
  dispatch({
    type: OPEN_PL_MATCH_SUPPORT_CENTRE_DIALOG_UPDATE_DATA,
    key: rowId,
    data,
  });
};

export const togglePlSnackbar = () => dispatch => {
  return dispatch({ type: 'TOGGLE_PL_SNACKBAR' });
};
