import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Product from '../product';
import * as Styled from './styles';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { objectToArray } from '../../../../../../utils/array';
const Products = ({
  allProducts,
  selectedProduct,
  selectProduct,
  deselectProduct,
  showProductActions,
  history,
  match,
}) => {
  const { t } = useTranslation();
  const goToProduct = productId => {
    history.push(
      `/${match.params.appname}/ecommerce/marketplace/products/${productId}`,
    );
  };
  return (
    <>
      {allProducts.length > 0 ? (
        <Grid container spacing={3}>
          {allProducts.map(product => (
            <Grid
              key={product.id}
              item
              xs={12}
              sm={6}
              md={4}
              onClick={() => goToProduct(product.id)}
            >
              <Styled.container>
                <Styled.imageContainer>
                  {product.value.photoURL ? (
                    <Styled.image
                      alt={product.value.name}
                      src={
                        product.value.photoURL &&
                        objectToArray(product.value.photoURL)[0].value.image
                      }
                    />
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Trans>No product image added</Trans>
                    </div>
                  )}
                </Styled.imageContainer>
                <Styled.details>
                  <Styled.primaryDetails>
                    {product.value.name}
                  </Styled.primaryDetails>
                  <Styled.secondaryDetails>
                    {product.value.approved
                      ? t('Approved')
                      : product.value.rejected
                      ? t('Rejected')
                      : t('Pending')}
                  </Styled.secondaryDetails>
                  <Styled.secondaryDetails>
                    {moment(product.updated_at).format('DD-MM-YYYY')}
                  </Styled.secondaryDetails>
                </Styled.details>
              </Styled.container>
            </Grid>
          ))}
        </Grid>
      ) : (
        <span>
          <Trans>No products added yet.</Trans>{' '}
        </span>
      )}
      <Modal
        open={selectedProduct ? true : false}
        onBackdropClick={deselectProduct}
        aria-labelledby="product-details"
      >
        <Product
          product={selectedProduct}
          deselectProduct={deselectProduct}
          showProductActions={showProductActions}
        />
      </Modal>
    </>
  );
};

Products.defaultProps = {
  allProducts: [],
};
export default Products;
