import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import firebase from '../../../../../firebase';
import { objectToArray } from '../../../../../utils/object';

import components from '../../../../../plans/components';

import styles from './styles.module.scss';

const DiyPlansInfo = props => {
  const [revalidate, setRevalidate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [diyAddons, setDiyAddons] = useState({});
  const { appname, onCancelPlan } = props;
  const plan = useSelector(
    state => state.account.subscriptions.items[appname].plan,
  );
  const period = useSelector(
    state => state.account.subscriptions.items[appname].period,
  );

  useEffect(() => {
    (async () => {
      try {
        const snapshot = await firebase
          .database()
          .ref(`/apps/${appname}/diyComponents`)
          .once('value');
        const value = await snapshot.val();

        if (value) {
          setDiyAddons(value);
          setRevalidate(false);
        } else {
          setDiyAddons({});
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [revalidate]);

  const handleCancelAddon = async (component, componentType) => {
    setLoading(true);
    let currency = 'gbp';
    if (plan) {
      const formattedPlan = plan.replace('2023', '');

      currency = formattedPlan.includes('-')
        ? formattedPlan.split('-')[1]
        : 'gbp';
    }
    let addonDetails = {
      appname,
      componentType,
      name: component.name,
      plan: 'start',
      price: component[currency] ? component[currency].price : component.price,
      subscriptionPlan: 'addon',
      currency,
      removeAddon: true,
      period,
    };

    if (componentType === 'Diary') {
      addonDetails = {
        ...addonDetails,
        subscriptionDetails:
          (diyAddons &&
            diyAddons['Diary'] &&
            diyAddons['Diary'].subscriptionId) ||
          {},
      };
    }

    await onCancelPlan(appname, 'start', true, addonDetails);
    setLoading(false);
    setRevalidate(true);
  };

  return (
    <div>
      {plan &&
        plan.includes('diy') &&
        diyAddons &&
        Object.keys(diyAddons).length !== 0 && <h2>Addons</h2>}
      <div className={styles.addon_container}>
        {loading && <div>Removing Addon...</div>}
        {!loading &&
          plan &&
          plan.includes('diy') &&
          diyAddons &&
          Object.keys(diyAddons).length !== 0 &&
          objectToArray(diyAddons).map(addon => {
            return (
              addon.value.status !== 'deleted' && (
                <div key={addon.id}>
                  <Tooltip title="Cancel Addon" placement="right">
                    <svg
                      className="icon close"
                      onClick={() =>
                        handleCancelAddon(components[addon.id], addon.id)
                      }
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32"
                    >
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path d="M16,32A16,16,0,1,1,32,16,16,16,0,0,1,16,32ZM16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Z" />
                        <path d="M21.45,22.45a1,1,0,0,1-.71-.29L9.84,11.25a1,1,0,1,1,1.41-1.41L22.16,20.75a1,1,0,0,1-.71,1.71Z" />
                        <path d="M10.55,22.45a1,1,0,0,1-.71-1.71L20.75,9.84a1,1,0,1,1,1.41,1.41L11.25,22.16A1,1,0,0,1,10.55,22.45Z" />
                      </g>
                    </svg>
                  </Tooltip>
                  <div>
                    {components[addon.id].name === 'CustomDomain'
                      ? 'Custom Domain'
                      : components[addon.id].name}
                  </div>
                </div>
              )
            );
          })}
      </div>
    </div>
  );
};

export default DiyPlansInfo;
