import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckCirle from 'material-ui/svg-icons/action/check-circle';

import TextField from '../../../../../../../app/components/textField';
import {
  CircularProgress,
  LoadingScreen,
} from '../../../../../../../app/components/progress';
import { FlatButton } from '../../../../../../../app/components/buttons';

class CreateIntegrationApp extends Component {
  static propTypes = {
    integration: PropTypes.object,
    createIntegrationApp: PropTypes.shape({
      fetchingDomain: PropTypes.bool,
      availableDomain: PropTypes.bool,
      showDomainInput: PropTypes.bool,
      domain: PropTypes.string,
      appCreated: PropTypes.bool,
    }),
    apps: PropTypes.object,
    history: PropTypes.object.isRequired,
    submitAppCreation: PropTypes.func.isRequired,
    onDomainChange: PropTypes.func.isRequired,
    defineDefaultDomain: PropTypes.func.isRequired,
  };

  static defaultProps = {
    apps: null,
    createIntegrationApp: {
      showDomainInput: false,
      availableDomain: true,
      domain: null,
      fetchingDomain: false,
      appCreated: false,
    },
    integration: {
      type: '',
      siteUrl: '',
      siteTitle: '',
    },
  };

  componentDidMount() {
    const {
      integration,
      createIntegrationApp,
      apps,
      defineDefaultDomain,
    } = this.props;
    console.log({ mount: this.props }, '123sjd');
    if (apps) {
      return this.props.history.push(`/${this.appName()}/editor/customise`);
    }
    if (!integration) {
      return this.props.history.push('/');
    }
    if (!createIntegrationApp.domain) {
      const domain = this.getAppDomain(integration);
      console.log({ domain }, '123sjd');
      defineDefaultDomain(domain);
    }
  }

  componentWillReceiveProps() {
    const { appCreated, domain } = this.props.createIntegrationApp;

    if (appCreated) {
      this.props.history.push(`/${domain}/editor/customise`);
    }
  }

  getAppDomain = integration => {
    let domain = integration.siteUrl || 'yourapp';

    if (domain.indexOf('wixsite.com') !== -1) {
      domain = domain.match(/[^/][\w-]+$/g);
      domain = domain[0];
    }

    const removeExp = [
      'www.',
      'https://',
      'http://',
      '.weebly.com',
      '.co.uk',
      '.com',
      /-/g,
      /\//g,
      /\.\w+/g,
    ];

    removeExp.forEach(exp => {
      domain = domain.replace(exp, '');
    });

    return domain;
  };

  appName = () => Object.keys(this.props.apps)[0];

  renderPage = () => {
    const {
      showDomainInput,
      fetchingDomain,
      availableDomain,
      domain,
    } = this.props.createIntegrationApp;

    return showDomainInput ? (
      <div className="create-content">
        <div className="create-domain-container">
          <div className="create-domain-input-container">
            {console.log('123sjd', { props: this.props })}
            <TextField
              id="domain"
              value={domain}
              hintText="Your Domain"
              onChange={this.props.onDomainChange}
              rightActionContianerStyle={{
                bottom: 2,
              }}
              rightInputAction={
                fetchingDomain ? (
                  <CircularProgress size={16} thickness={3} />
                ) : (
                  availableDomain && (
                    <CheckCirle
                      color="#78ffc5"
                      style={{ height: 18, width: 18 }}
                    />
                  )
                )
              }
            />
          </div>
        </div>
        <FlatButton
          style={{ margin: 20 }}
          label="CREATE APP"
          onClick={() => this.props.submitAppCreation(domain)}
          disabled={!availableDomain}
          primary
        />
      </div>
    ) : (
      <LoadingScreen loading />
    );
  };

  render() {
    return <div>{this.renderPage()}</div>;
  }
}

export default CreateIntegrationApp;
