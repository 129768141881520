import { combineReducers } from 'redux';
import redeemBonus from './modules/redeemBonus/reducer';
import referralList from './modules/referralsList/reducer';
import redeemHistory from './modules/redeemHistory/reducer';
import redeemRequest from './modules/redeemRequest/reducer';

const initialState = {
  link: '',
  copied: false,
};
const copyToShare = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_REFERRAL_LINK':
      return {
        ...state,
        link: action.payload,
      };
    case 'REFERRAL_COPIED':
      return {
        ...state,
        copied: action.payload,
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
};

export default combineReducers({
  copyToShare,
  redeemBonus,
  referralList,
  redeemHistory,
  redeemRequest,
});
