import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PlMatchSupport from './PlMatchSupport';
import {
  toggleMatchSupportModal,
  formFieldUniqueCheck,
  formFieldChange,
  addPlMatchSupportCentre,
  getPlMatchSupportCentre,
  selectMatchSupportCentre,
  selectAllMatchCentres,
  deselectAllMatchCentres,
  deletePlMatchSupportCentres,
  updatePlMatchSupportCentre,
  filterMatchSupportData,
  togglePlSnackbar,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  plMatchSupportData: state.pgmol.plMatchSupport,
});

const mapDisptachToProps = (dispatch, ownProps) => ({
  toggleMatchSupportModal: value => dispatch(toggleMatchSupportModal(value)),
  onFieldChangeWithUniqueCheck: (field, value, filteredChunks) =>
    dispatch(formFieldUniqueCheck(field, value, filteredChunks)),
  onFieldChange: (field, value) => dispatch(formFieldChange(field, value)),

  onSubmit: () =>
    dispatch(addPlMatchSupportCentre(ownProps.match.params.appname)),
  getPlMatchSupportCentre: () =>
    dispatch(getPlMatchSupportCentre(ownProps.match.params.appname)),

  onSelect: key => dispatch(selectMatchSupportCentre(key)),
  onSelectAllMatchCentres: () => dispatch(selectAllMatchCentres()),
  onDeselectAllMatchCentres: () => dispatch(deselectAllMatchCentres()),
  onDeleteSelected: rowId =>
    dispatch(deletePlMatchSupportCentres(ownProps.match.params.appname, rowId)),
  onFilterMatchSupportData: filter => dispatch(filterMatchSupportData(filter)),
  onUpdatePlMatchSupportCentre: (rowId, data) =>
    dispatch(updatePlMatchSupportCentre(rowId, data)),
  onTogglePlSnackbar: () => dispatch(togglePlSnackbar()),
});

export default withRouter(
  connect(mapStateToProps, mapDisptachToProps)(PlMatchSupport),
);
