import React from 'react';
import PolygonMap from '../../components/polygonMap/PolygonMap';
import MetaData from '../../components/MetaData/MetaData';

const Settings = props => {
  const { appname, isAppAdmin } = props;
  return (
    <div style={{ overflow: 'scroll' }}>
      <PolygonMap appname={appname} />
      <MetaData appname={appname} isAppAdmin={isAppAdmin} />
    </div>
  );
};

export default Settings;
