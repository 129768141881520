import firebase from '../../../../../../firebase';

import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';

export const validateRequestData = (acc, token) => dispatch => {
    dispatch({ type: 'TOGGLE_REDEEM_REQUEST_PENDING_STATUS', payload: true });
    return new Promise((resolve, reject) => {
        firebase.
            database()
            .ref(`accounts/${acc}`)
            .child(`referral_bonus_payments/${token}`)
            .once('value')
            .then(snapshot => {
                if (!snapshot.exists()) {
                    dispatch(openSnackbar('Invalid Request Details.'));
                    dispatch({ type: 'TOGGLE_REDEEM_REQUEST_PENDING_STATUS', payload: false });
                    return reject('Invalid Request Details');
                } else {
                    dispatch({
                        type: 'UPDATE_REDEEM_REQUEST_DETAIL',
                        payload: {
                            ...snapshot.val(),
                            token: snapshot.key,
                        }
                    });
                    dispatch({ type: 'VALIDATE_REDEEM_REQUEST', payload: true });
                    dispatch({ type: 'TOGGLE_REDEEM_REQUEST_PENDING_STATUS', payload: false });
                    return resolve(true);
                }
            })
            .catch((error) => {
                dispatch({ type: 'TOGGLE_REDEEM_REQUEST_PENDING_STATUS', payload: false });
                dispatch(openSnackbar('An error occurred while fetch Redeem Request Details.'));
                return reject(error);
            });
    });
}

export const updateRequestStatus = (status) => (dispatch, getState) => {
    dispatch({ type: 'TOGGLE_APPROVAL_REQUEST_STATUS', payload: true });
    const { token, accountId } = getState().account.referral.redeemRequest.details;
    const params = { request_key: token, account: accountId, status };
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return new Promise((resolve, reject) => {
        return fetch(`${process.env.REACT_APP_REFERRAL_API}/update-redeem-status`, {
            method: 'post',
            mode: 'cors',
            body: JSON.stringify(params),
            headers,
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch({ type: 'APPROVAL_REQUEST_STATUS_UPDATION', payload: true });
                    dispatch({ type: 'TOGGLE_APPROVAL_REQUEST_STATUS', payload: false });
                    return resolve(res);
                } else {
                    dispatch({ type: 'APPROVAL_REQUEST_ERROR', payload: true });
                    dispatch({ type: 'TOGGLE_APPROVAL_REQUEST_STATUS', payload: false });
                    dispatch(
                        openSnackbar(
                            'An error occurred while processing your request, Please contact customer support.',
                        ),
                    );
                    return reject();
                }
            })
            .catch(error => {
                dispatch({ type: 'APPROVAL_REQUEST_ERROR', payload: true });
                dispatch({ type: 'TOGGLE_APPROVAL_REQUEST_STATUS', payload: false });
                dispatch(
                    openSnackbar(
                        'An error occurred while processing your request, Please contact customer support.',
                    ),
                );
                return reject(error);
            });
    });
}