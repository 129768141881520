/* eslint-disable linebreak-style */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Toggle from 'material-ui/Toggle';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateTimePicker from '@material-ui/lab/DateTimePicker';
import TimePicker from '@material-ui/lab/TimePicker';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/core';
import { FlatButton } from '../../../../../../app/components/buttons';
import {
  addItemToAppointment,
  getAllStaff,
  updateItemFromAppointment,
} from '../../api';
import './styles.css';
import MenuItem from '@material-ui/core/MenuItem';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import Row from './Row';
import LocationField from '../../../../../../app/components/locationField';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';
import ImageUpload from '../../../../../editor/internals/imageUpload/';
import firebase from '../../../../../../firebase';
import { getRandomColor } from '../../../../../../utils/color';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';

import { AppointmentContext } from '../../modules/appointments/context/AppointmentContext';

const today = Date.now();
const AddAppointment = props => {
  const {
    addAppointmentsDialogOpen,
    onAppointmentsDialogOpen,
    appname,
    onAppointmentsItemChange,
    appointmentItem,
    onResetAppointmentData,
  } = props;
  const { t } = useTranslation();
  const timezones = momentTimezone.tz.names();
  const formatTimezones = timezones.map(item => ({ label: item, value: item }));

  const isUpdate = Object.keys(appointmentItem).length > 0;
  const accountId = useSelector(state => state.account.organisation.key);
  const isImageUploaded = useSelector(
    state => state.ecommerce.inventory.isImageUploaded,
  );
  const getImgUrl = () =>
    `/bookings/${appname}/temp_images/appointments/${accountId}`;

  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(
    isUpdate ? updateItemFromAppointment : addItemToAppointment,
  );
  const { data, error, loading, isError } = useQuery(
    ['staffMembers', { appname }],
    getAllStaff,
  );

  const {
    name,
    setName,
    description,
    setDescription,
    cost,
    setCost,
    capacity,
    setCapacity,
    date,
    setDate,
    key,
    setKey,
    isButtonDisabled,
    setIsButtonDisabled,
    isBookingPaid,
    setIsBookingPaid,
    appointmentType,
    setAppointmentType,
    appointmentDuration,
    timezone,
    setTimezone,
    setAppointmentDuration,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    selectedStaffMember,
    setSelectedStaffMember,
    location,
    setLocation,
    eventData,
    setEventData,
    stripeKeyError,
    setStripeKeyError,
    isRecurringBooking,
    setIsRecurringBooking,
    recurringType,
    setRecurringType,
    recurringValue,
    setRecurringValue,
    appointmentError,
    setAppointmentError,
    slotsError,
    setSlotsError,
    imageUrl,
    setImageUrl,
    submitted,
    setSubmitted,
    meetingUrl,
    setMeetingUrl,
  } = useContext(AppointmentContext);

  useEffect(() => {
    const checkStripeKeyExists = async () => {
      setStripeKeyError(false);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BOOKING_API}/stripe-key-exists/${appname}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        const { status } = await response.json();

        setStripeKeyError(status);
      } catch (err) {
        setStripeKeyError(true);
        setIsBookingPaid(false);
      }
    };
    checkStripeKeyExists();
  }, []);

  useEffect(() => {
    if (isUpdate) {
      setAppointmentType(appointmentItem.appointmentType);
      appointmentItem.name && setName(appointmentItem.name);
      appointmentItem.meetingUrl && setMeetingUrl(appointmentItem.meetingUrl);
      appointmentItem.description &&
        setDescription(appointmentItem.description);
      appointmentItem.cost && setCost(appointmentItem.cost);
      appointmentItem.date && setDate(appointmentItem.date);
      appointmentItem.key && setKey(appointmentItem.key);
      appointmentItem.isBookingPaid &&
        setIsBookingPaid(appointmentItem.isBookingPaid);
      appointmentItem.capacity && setCapacity(appointmentItem.capacity);
      appointmentItem.date &&
        setSelectedStartDate(moment(appointmentItem.date));
      appointmentItem.duration &&
        setAppointmentDuration(appointmentItem.duration);
      appointmentItem.timezone && setTimezone(appointmentItem.timezone);

      setEventData({
        name: appointmentItem.name,
        description: appointmentItem.description,
        slotDuration: '30',
        numSlots: appointmentItem.numSlots,
        slots: appointmentItem.slots,
      });
      appointmentItem.location &&
        setLocation({
          value: appointmentItem.location.value,
          locationData: appointmentItem.location.locationData,
        });
      setImageUrl(appointmentItem.imageUrl);
      setSelectedStaffMember(
        appointmentItem.staffMembers.map(staff => staff.key),
      );
    } else if (!isImageUploaded && !isUpdate) {
      setName('');
      setMeetingUrl('');
      setDescription('');
      setCost(0);
      setDate('');
      setKey('');
      setIsBookingPaid(false);
      setIsRecurringBooking(false);
      setAppointmentDuration(30);
      setTimezone({ value: '', label: '' });
      setEventData(null);
      setCapacity(1);
    }
  }, [appointmentItem, isUpdate]);

  useEffect(() => {
    const getImageUrl = async () => {
      const imageRef = firebase.database().ref(getImgUrl());
      const snapshot = await imageRef.once('value');
      const value = snapshot.val();
      setImageUrl(value);
    };

    isImageUploaded && getImageUrl();
  }, [isImageUploaded]);

  const handleClose = async () => {
    await firebase
      .database()
      .ref(getImgUrl())
      .set(null);
    setImageUrl(null);
    onResetAppointmentData();
  };
  const isAppointmentInvalid =
    (isUpdate && today > date) ||
    isButtonDisabled ||
    (!isUpdate && selectedStaffMember && selectedStaffMember.length === 0) ||
    name.length === 0 ||
    description.length === 0 ||
    !selectedStartDate ||
    capacity < 0 ||
    (isBookingPaid && cost <= 0) ||
    (isBookingPaid && stripeKeyError) ||
    (appointmentType === 1
      ? eventData
        ? eventData.slots.length < 1
        : true
      : false);

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitted(true);
    if (isAppointmentInvalid) {
      return;
    }

    setAppointmentError(null);
    setSlotsError(null);

    const formatDate = moment(selectedStartDate).valueOf();
    // console.log(moment(formatDate).format());
    const timeZoneDate = moment(formatDate).format();

    let payload = {
      appname,
      name,
      description,
      cost,
      isBookingPaid,
      key,
      isRecurringBooking,
      recurringValue,
      recurringType,
      imageUrl,
      location,
      meetingUrl,
      timezone,
      backgroundColor: getRandomColor(),
      ...(timezone &&
        timezone.label !== '' && { timezoneDate: timeZoneDate.split('+')[0] }),
    };
    if (appointmentType === 0) {
      const staffMemberAvailability = [];
      selectedStaffMember.forEach(staffKey => {
        staffMemberAvailability.push({
          ...data[staffKey],
          key: staffKey,
          availableCapacity: parseInt(capacity, 10),
        });
      });

      payload = {
        ...payload,
        date: formatDate,

        appointmentType,
        staffMembers: selectedStaffMember.map(staffKey => ({
          ...data[staffKey],
          key: staffKey,
        })),
        capacity: parseInt(capacity, 10) || 1,
        duration: appointmentDuration,

        numSlots: 1,
        booked: 0,
        staffMemberAvailability,
      };
    } else {
      payload = {
        ...payload,
        ...eventData,
        name,
        description,
        appointmentType,
      };
    }

    if (
      date &&
      payload.date &&
      !moment(payload.date).isSame(moment(date), 'day')
    ) {
      setAppointmentError(t('Changing of date is not permitted.'));
      return;
    }

    setIsButtonDisabled(true);
    await mutateAsync(payload);
    setImageUrl(null);

    queryClient.invalidateQueries('appointmentItems');
    await firebase
      .database()
      .ref(getImgUrl())
      .set(null);
    onAppointmentsDialogOpen(false);
    onAppointmentsItemChange({});
    setIsButtonDisabled(false);
    onResetAppointmentData();
  };

  const addTimeSlots = () => {
    setSlotsError(null);
    const startTime = moment(moment(selectedStartDate).valueOf());
    const tempEndTime = moment(moment(selectedEndDate).valueOf());
    const t = moment(tempEndTime).format('h:mm:ss a');
    const endTime = moment(
      `${moment(startTime).format('MM-DD-YYYY')} ${t}`,
      'MM-DD-YYYY h:mm:ss a',
    );
    const timeStops = [];

    if (date && !moment(startTime).isSame(moment(date), 'day')) {
      setSlotsError(t('Changing of date is not permitted.'));
    }

    if (endTime.isBefore(startTime)) {
      endTime.add(1, 'day');
    }

    while (startTime <= endTime) {
      timeStops.push(new moment(startTime).format('HH:mm'));
      startTime.add(appointmentDuration, 'minutes');
    }

    for (let i = 1; i < timeStops.length; i += 1) {
      const staffMemberAvailability = [];
      selectedStaffMember.forEach(staffKey => {
        staffMemberAvailability.push({
          ...data[staffKey],
          key: staffKey,
          availableCapacity: parseInt(capacity, 10),
        });
      });

      timeStops[i - 1] = {
        capacity: parseInt(capacity, 10),
        duration: appointmentDuration,
        time: `${timeStops[i - 1]} - ${timeStops[i]}`,
        booked: 0,
        staffMemberAvailability,
      };
    }
    timeStops.pop();

    const tempSlots = (eventData && eventData.slots) || [];
    tempSlots.push({
      capacity: parseInt(capacity),
      staffMembers: selectedStaffMember.map(staffKey => data[staffKey]),
      slots: timeStops,
      duration: appointmentDuration,
    });

    const tempData = {
      slotDuration: appointmentDuration,
      date: moment(endTime).valueOf(),
      numSlots: tempSlots.length || 0,
      type: appointmentType,
      slots: tempSlots,
      capacity: parseInt(capacity),
    };

    setEventData(tempData);
  };

  const removeSlotFromRow = index => {
    const tempData = { ...eventData };
    tempData.numSlots -= 1;
    tempData.slots.splice(index, 1);
    setEventData(tempData);
  };

  return (
    // <MuiPickersUtilsProvider utils={MomentUtils}>
    <Dialog
      data-testid="add-appointment"
      open={addAppointmentsDialogOpen}
      onClose={handleClose}
      autoScrollBodyContent
      disableEnforceFocus={true}
      scroll="paper"
      maxWidth="lg"
    >
      <div className="appointment-modal-content">
        <DialogTitle data-testid="title">
          <Trans>Add an Appointment</Trans>
        </DialogTitle>
        <form onSubmit={handleSubmit} data-testid="form">
          <fieldset disabled={isUpdate && today > date}>
            <div className="field-wrapper">
              <div>
                <Trans>Image</Trans>
              </div>
              <ImageUpload
                targetId="appointment_header_image"
                src={imageUrl}
                label="Image"
                caption={t('This image will be displayed for appointments')}
                firebaseUrl={getImgUrl()}
                title={t('Appointment Image')}
                tooltip={t('Minimum image size 240x240 pixels')}
                showCropAreaSizes
              />
            </div>

            <div className="field-wrapper">
              <TextField
                type="text"
                id="name"
                label={t('Name')}
                value={name}
                required
                fullWidth
                variant="standard"
                onChange={e => setName(e.target.value)}
              />
            </div>

            <div className="field-wrapper">
              <TextField
                type="text"
                id="description"
                label={t('Description')}
                required
                fullWidth
                variant="standard"
                value={description}
                multiline
                onChange={e => setDescription(e.target.value)}
              />
            </div>

            {/* <div className="field-wrapper">
          <FormControl fullWidth>
            <InputLabel id="appointmentType-label">Appointment Type</InputLabel>
            <Select
              labelId="appointmentType-label"
              id="appointmentType"
              value={appointmentType}
              onChange={e => setAppointmentType(e.target.value)}
              disabled={isUpdate}
              variant="standard"
            >
              <MenuItem value={0}>Simple (one time appointment)</MenuItem>
              <MenuItem value={1}>Multiple (multiple slots)</MenuItem>
            </Select>
          </FormControl>
        </div> */}

            <div className="field-wrapper">
              <FormControl fullWidth>
                <InputLabel id="duration" style={{ left: '-15px' }}>
                  <Trans>Duration</Trans>
                </InputLabel>
                <Select
                  labelId="duration"
                  id="durationSelect"
                  data-testid="durationSelect"
                  variant="standard"
                  value={appointmentDuration}
                  disabled={isUpdate && today > date}
                  onChange={e => setAppointmentDuration(e.target.value)}
                >
                  <MenuItem value={15}>
                    <Trans>15 mins</Trans>
                  </MenuItem>
                  <MenuItem value={30}>
                    <Trans>30 mins</Trans>
                  </MenuItem>
                  <MenuItem value={45}>
                    <Trans>45 mins</Trans>
                  </MenuItem>
                  <MenuItem value={60}>
                    <Trans>1 hr</Trans>
                  </MenuItem>
                  <MenuItem value={120}>
                    <Trans>2 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={180}>
                    <Trans>3 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={240}>
                    <Trans>4 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={300}>
                    <Trans>5 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={360}>
                    <Trans>6 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={420}>
                    <Trans>7 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={480}>
                    <Trans>8 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={540}>
                    <Trans>9 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={600}>
                    <Trans>10 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={660}>
                    <Trans>11 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={720}>
                    <Trans>12 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={780}>
                    <Trans>13 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={840}>
                    <Trans>14 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={900}>
                    <Trans>15 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={960}>
                    <Trans>16 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1020}>
                    <Trans>17 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1080}>
                    <Trans>18 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1140}>
                    <Trans>19 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1200}>
                    <Trans>20 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1260}>
                    <Trans>21 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1320}>
                    <Trans>22 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1380}>
                    <Trans>23 hrs</Trans>
                  </MenuItem>
                  <MenuItem value={1440}>
                    <Trans>24 hrs</Trans>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="field-wrapper">
              {/* <Select
                  labelId="timezone"
                  id="durationSelect"
                  variant="standard"
                  value={timezone}
                  onChange={e => setTimezone(e.target.value)}
                >
                  {timezones.map(zone => (
                    <MenuItem key={zone} value={zone}>
                      {zone}
                    </MenuItem>
                  ))}
                </Select> */}
              <Autocomplete
                options={formatTimezones}
                getOptionLabel={option => option.label}
                value={timezone}
                onChange={(event, newValue) => {
                  setTimezone(newValue);
                }}
                renderInput={params => (
                  <TextField
                    fullWidth
                    className="autocomplete-field"
                    {...params}
                    label="Time Zone"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        border: '0px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                      },
                      endAdornment: null,
                    }}
                  />
                )}
              />
            </div>

            <div className="field-wrapper">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  renderInput={props => (
                    <TextField fullWidth variant="standard" {...props} />
                  )}
                  disableOpenPicker={isUpdate && today > date}
                  label={t('Appointment Time')}
                  value={selectedStartDate}
                  minDate={moment(new Date())}
                  ampm={false}
                  onChange={value => {
                    setSelectedStartDate(value);
                  }}
                  // timezone={timezone ? timezone.label : ''}
                />
              </LocalizationProvider>
            </div>

            {appointmentType === 1 && (
              <div className="field-wrapper">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    label={t('End Time')}
                    value={selectedEndDate}
                    onChange={value => setSelectedEndDate(value)}
                    renderInput={params => (
                      <TextField fullWidth variant="standard" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </div>
            )}

            <div className="field-wrapper">
              <TextField
                fullWidth
                type="number"
                required
                id="capacity"
                label={t('Capacity')}
                variant="standard"
                value={capacity}
                inputProps={{
                  min: 1,
                  step: 1,
                }}
                error={!capacity || capacity <= 0}
                helperText={
                  (!capacity || capacity <= 0) &&
                  t('Capacity has to be greater than 0')
                }
                onChange={e => setCapacity(parseInt(e.target.value))}
              />
            </div>
            <div className="field-wrapper">
              <FormControl fullWidth error={!data} required>
                <InputLabel id="staff-members-label" style={{ left: '-15px' }}>
                  <Trans>Select Staff Member</Trans>
                </InputLabel>

                <Select
                  labelId="staff-members-label"
                  id="staffMemberSelect"
                  multiple
                  value={selectedStaffMember}
                  disabled={isUpdate}
                  onChange={e => setSelectedStaffMember(e.target.value)}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected =>
                    data && (
                      <div>
                        {isUpdate
                          ? selectedStaffMember.map(value => (
                              <Chip
                                key={value}
                                label={data[value] && data[value].name}
                              />
                            ))
                          : selected.map(value => (
                              <Chip
                                key={value}
                                label={data[value] && data[value].name}
                              />
                            ))}
                      </div>
                    )
                  }
                >
                  {data &&
                    Object.keys(data).map(dataKey => (
                      <MenuItem key={dataKey} value={dataKey}>
                        {data[dataKey].name}
                      </MenuItem>
                    ))}
                </Select>
                {!data && (
                  <FormHelperText>
                    <Link
                      to={`/${appname}/ecommerce/bookings/staff`}
                      style={{ color: 'red' }}
                    >
                      <Trans>
                        Please add a staff member in order to proceed.
                      </Trans>
                    </Link>
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            {appointmentType === 1 && (
              <>
                <FlatButton
                  onClick={addTimeSlots}
                  label={'Add Slot'}
                  disabled={
                    selectedStaffMember.length === 0 || !selectedEndDate
                  }
                />

                {slotsError && (
                  <span style={{ color: 'red', margin: '2vh 0' }}>
                    <Trans>Error</Trans> - {slotsError}
                  </span>
                )}
              </>
            )}

            <div className="field-wrapper">
              <LocationField
                multiLine
                required={false}
                value={location.value}
                label={t('Location')}
                latlong={location.locationData}
                onChange={(value, locationData) => {
                  setLocation({ value, locationData });
                }}
              />
            </div>

            <div className="field-wrapper">
              <TextField
                type="url"
                id="url"
                label="Online meeting URL"
                value={meetingUrl}
                fullWidth
                variant="standard"
                onChange={e => setMeetingUrl(e.target.value)}
              />
            </div>

            <div className="field-wrapper">
              <Toggle
                onToggle={(v, b) => setIsRecurringBooking(b)}
                toggled={isRecurringBooking}
                label={t('Recurring booking')}
                disabled={isUpdate}
              />
            </div>

            <Grow in={isRecurringBooking} unmountOnExit>
              <div className="field-wrapper">
                <FormControl component="fieldset" fullWidth>
                  <RadioGroup
                    aria-label="days"
                    defaultValue="days"
                    name="radio-buttons-group"
                    row
                    value={recurringType}
                    onChange={e => setRecurringType(e.target.value)}
                  >
                    <FormControlLabel
                      value="days"
                      control={<Radio />}
                      label={t('Day')}
                    />
                    <FormControlLabel
                      value="weeks"
                      control={<Radio />}
                      label={t('Week')}
                    />
                  </RadioGroup>
                </FormControl>
                <div>
                  <TextField
                    type="number"
                    required
                    label={` ${t('Number of')} ${recurringType} ${t(
                      'to repeat appointment:',
                    )}`}
                    fullWidth
                    variant="standard"
                    value={recurringValue}
                    onChange={e =>
                      e.target.value >= 0 && setRecurringValue(e.target.value)
                    }
                    minimum={2}
                  />
                </div>
              </div>
            </Grow>

            <div className="field-wrapper">
              <Toggle
                onToggle={(v, b) => setIsBookingPaid(b)}
                toggled={isBookingPaid}
                label={t('Paid Booking')}
              />
            </div>

            <Grow in={stripeKeyError && isBookingPaid} unmountOnExit>
              <div>
                <p className="subscription-error-msg">
                  <Trans>Add stripe keys to add paid appointments.</Trans>
                </p>
                <p className="subscription-link">
                  <Link
                    to={{
                      pathname: `/${appname}/ecommerce/bookings/settings`,
                      hash: '#user-app-subscriptions',
                    }}
                  >
                    <Trans>Click here</Trans>
                  </Link>{' '}
                  <Trans>to add keys</Trans>
                </p>
              </div>
            </Grow>

            <Grow in={isBookingPaid} unmountOnExit>
              <div className="field-wrapper">
                <TextField
                  type="number"
                  label={t('Cost')}
                  value={cost}
                  variant="standard"
                  fullWidth
                  inputProps={{
                    min: 0,
                    step: 0.01,
                  }}
                  onChange={e =>
                    setCost(parseFloat(parseFloat(e.target.value).toFixed(2)))
                  }
                />
              </div>
            </Grow>

            {eventData && eventData.slots && (
              <div>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>
                          <Trans>Name</Trans>
                        </TableCell>
                        <TableCell align="left">
                          <Trans>Description</Trans>
                        </TableCell>
                        <TableCell align="left">
                          <Trans>Date</Trans>
                        </TableCell>
                        <TableCell align="left">
                          <Trans>No of slots</Trans>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <Row
                        row={eventData}
                        isUpdate={isUpdate}
                        removeSlotFromRow={removeSlotFromRow}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}

            {(!isUpdate || (isUpdate && today < date)) && (
              <div>
                <FlatButton
                  label={
                    isLoading
                      ? t('SAVING...')
                      : isUpdate
                      ? t('Update')
                      : t('ADD')
                  }
                  type="submit"
                  primary
                />
              </div>
            )}
            {appointmentError && (
              <span style={{ color: 'red', margin: '3vh 0' }}>
                <Trans>Error</Trans> - {appointmentError}
              </span>
            )}
          </fieldset>
        </form>
      </div>
    </Dialog>
    // </MuiPickersUtilsProvider>
  );
};

export default AddAppointment;
