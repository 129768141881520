import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import WeeblyWixToolbar from './weeblyWixToolbar';
import { logout } from '../../../../modules/account/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isAuthenticated: state.account.auth.isAuthenticated,
  lastVisited: state.account.profile.lastVisited,
});

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(logout()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WeeblyWixToolbar),
);
