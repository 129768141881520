import firebase from '../../../../../../../firebase';
import { validateField } from '../validators';
import {
  OPEN_NEW_FILTER_DIALOG,
  CLOSE_NEW_FILTER_DIALOG,
  NEW_FILTER_ADDED,
  NEW_FILTER_FIELD_CHANGE,
  ADD_NEW_FILTER,
} from './types';
const newFilterFieldChange = (prop, value, isValid) => ({
  type: NEW_FILTER_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

export const openNewFilterDialog = () => ({ type: OPEN_NEW_FILTER_DIALOG });

export const closeNewFilterDialog = () => ({ type: CLOSE_NEW_FILTER_DIALOG });

export const formFieldChange = (prop, value) => dispatch => {
  const isValid =
    prop === 'name' && value.length > 0 ? validateField[prop](value) : null;

  return dispatch(newFilterFieldChange(prop, value, isValid));
};

export const addFilter = appname => (dispatch, getState) => {
  const {
    name,
    description,
    // filterType,
  } = getState().ecommerce.marketplace.filters.newFilter;

  let filter = {
    name: name.value,
    date: Date.now(),
    description: description.value,
    filterType: 'Product',
  };

  dispatch({ type: ADD_NEW_FILTER });
  dispatch(createFilter(appname, filter));
};

export const createFilter = (appname, filter) => dispatch => {
  return firebase
    .database()
    .ref(`marketplace_filters/${appname}`)
    .push(filter)
    .then(ref => {
      dispatch({
        type: NEW_FILTER_ADDED,
        key: ref.key,
        data: filter,
      });
    });
};
