import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import queryString from 'query-string';
import {
  fieldChange,
  addField,
  removeField,
  rearrangeField,
  designChange,
} from './actions';

import EventCalendar from './EventCalendar';

const mapStateToProps = (state, ownProps) => {
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];
  const { action } = ownProps.data;
  const link = {
    button: {
      label: get(ownProps.data, 'buttonText', ''),
    },
    linkType: {
      page: get(action, 'page', ''),
      web: get(action, 'web', ''),
      selectedOption: get(action, 'selectedOption', ''),
    },
    enabled: get(ownProps, 'data.link.enabled', false),
  };

  return {
    ...ownProps,
    pages: layout
      ? layout.childRoutes.map(key => state.editor.data.alwaysData[key])
      : [],
    link,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFieldChange: (field, value, type) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
        type,
      ),
    ),

  onAddField: (value, calendarType) =>
    dispatch(
      addField(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
        calendarType,
      ),
    ),
  onRemoveField: (index, key, calendarType) =>
    dispatch(
      removeField(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        index,
        key,
        calendarType,
      ),
    ),
  onRearrangeField: (id, siblingId) =>
    dispatch(
      rearrangeField(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        id,
        siblingId,
      ),
    ),
  onDesignChange: (field, value) => {
    return dispatch(
      designChange(
        ownProps.match.params.appname,
        queryString.parse(ownProps.location.search).component,
        field,
        value,
      ),
    );
  },

  // onButtonDesignChange: (type, value) =>
  //   dispatch(
  //     designChange(
  //       ownProps.match.params.appname,
  //       ownProps.component.key,
  //       `buttonDesign/${type}`,
  //       value,
  //     ),
  //   ),
  // onDesignToggle: value =>
  //   dispatch(
  //     designChange(
  //       ownProps.match.params.appname,
  //       ownProps.component.key,
  //       'enabled',
  //       value,
  //     ),
  //   ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventCalendar),
);
