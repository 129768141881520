import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import CheckBox from 'material-ui/svg-icons/toggle/check-box';
import CheckBoxOutlineBlank from 'material-ui/svg-icons/toggle/check-box-outline-blank';

import { RaisedButton } from '../../../../../../../app/components/buttons';
import Container from '../../../../../../../app/components/container';
import { Tabs, Tab } from '../../../../../components/tabs';
import Toggle from '../../../../../../../app/components/toggle';
import TextField from '../../../../../../../app/components/textField';
import CheckboxField from '../../../../../../../app/components/checkbox';
import Legal from '../../../../../../account/modules/legal';

const styles = {
  radioButton: {
    fontSize: 18,
    padding: '12px 0',
    margin: '6px 0',
    display: 'block',
  },
  radioButtonGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '0rem 1rem',
  },
  error: {
    color: '#f00',
    fontSize: '1rem',
    textAlign: 'center',
  },
  loginButton: {
    marginTop: '1.5rem',
  },
};

class InstagramFeed extends Component {
  handleToggle = prop => (e, isToggled) => this.props.onChange(prop, isToggled);
  handleLogin = () => {
    this.props.recordLastEdited();
    window.location.href =
      'https://api.instagram.com/oauth/authorize/' +
      `?client_id=${process.env.REACT_APP_INSTAGRAM_API_KEY}` +
      `&redirect_uri=${process.env.REACT_APP_INSTAGRAM_REDIRECT_URI}?state=${this.props.component.lazy_data}@@${this.props.match.params.appname}=origin=${window.location.origin}` +
      `&response_type=code&scope=user_profile,user_media&state=${this.props.component.lazy_data}@@${this.props.match.params.appname}=origin=${window.location.origin}`;
  };

  handleLogout = () => {
    this.props.removeAccessToken();
  };

  state = {
    checked: false,
  };

  render() {
    const { data, onChange, onOpenLegalModal } = this.props;
    const checkboxLabel = (
      <span>
        I have read and agree to
        <button
          className="inline-button underline-text"
          onClick={onOpenLegalModal('instagram')}
        >
          Beezer&#39;s&nbsp;Data Use Processes for Instagram Basic Display API
        </button>
      </span>
    );

    if (!data.access_token) {
      return (
        <Tabs>
          <Tab label="content">
            <Container>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: '20px',
                }}
              >
                <CheckboxField
                  id="agreements"
                  checked={this.state.checked}
                  label={checkboxLabel}
                  onChange={() =>
                    this.setState({ checked: !this.state.checked })
                  }
                />
              </div>
              <RaisedButton
                label="log in"
                style={styles.loginButton}
                onClick={this.handleLogin}
                disabled={!this.state.checked}
              />
              <Legal />
            </Container>
          </Tab>
          <Tab
            label="design"
            disabled
            style={{
              color: '#d9d9d9',
            }}
          />
          <Tab
            label="layout"
            disabled
            style={{
              color: '#d9d9d9',
            }}
          />
        </Tabs>
      );
    }

    if (data.access_token === 'error') {
      return (
        <Tabs>
          <Tab label="content">
            <Container>
              <p style={styles.error}>
                An error occurred with your Instagram login. Please delete this
                component and try again.
              </p>
            </Container>
          </Tab>
        </Tabs>
      );
    }

    return (
      <Tabs>
        <Tab label="content">
          <Container>
            <RadioButtonGroup
              name="layout"
              defaultSelected="instagram"
              style={styles.radioButtonGroup}
              value={data.display}
              onChange={e => onChange('display', e.target.value)}
            >
              {/* <RadioButton
                value="simple"
                label="Simple"
                uncheckedIcon={<CheckBoxOutlineBlank />}
                checkedIcon={<CheckBox />}
                style={styles.radioButton}
              /> */}
              <RadioButton
                value="instagram"
                label="Instagram"
                uncheckedIcon={<CheckBoxOutlineBlank />}
                checkedIcon={<CheckBox />}
                style={styles.radioButton}
              />
            </RadioButtonGroup>
            <Toggle
              label="Instagram Share"
              toggled={data.instagramShare}
              onToggle={this.handleToggle('instagramShare')}
              className="full-width toggle-button"
            />
            {data.instagramShare && (
              <React.Fragment>
                <TextField
                  id="share"
                  name="share"
                  placeholder="Enter a Share Url"
                  hintText="URL"
                  value={data.shareUrl}
                  onChange={value => onChange('shareUrl', value)}
                />
              </React.Fragment>
            )}
            <RaisedButton
              label="log out"
              style={styles.loginButton}
              onClick={this.handleLogout}
            />
          </Container>
        </Tab>
        <Tab
          label="design"
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
        <Tab
          label="layout"
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    );
  }
}

InstagramFeed.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  recordLastEdited: PropTypes.func.isRequired,
  removeAccessToken: PropTypes.func.isRequired,
  onOpenLegalModal: PropTypes.func.isRequired,
};

export default InstagramFeed;
