import React from 'react';

const GoogleIcon = () => (
  <svg
    class="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 65.79 51.65"
  >
    <title>Google Maps component</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          class="cls-1"
          points="9.28 38.05 0 28.77 9.28 19.49 10.34 20.55 2.12 28.77 10.34 36.99 9.28 38.05"
        />
        <polygon
          class="cls-1"
          points="56.51 38.05 55.45 36.99 63.66 28.77 55.45 20.55 56.51 19.49 65.79 28.77 56.51 38.05"
        />
        <path
          class="cls-1"
          d="M32,51.65,18.06,22.17A15.56,15.56,0,0,1,16.93,11.5,15.57,15.57,0,0,1,47,11.5h0a15.56,15.56,0,0,1-1.13,10.67ZM32,1.5A14.1,14.1,0,0,0,18.38,11.9a14,14,0,0,0,1,9.63L32,48.13,44.5,21.53a14,14,0,0,0,1-9.63h0A14.1,14.1,0,0,0,32,1.5Zm0,19.67a5.87,5.87,0,0,1-5.86-5.86A5.86,5.86,0,0,1,32,9.44a5.87,5.87,0,0,1,0,11.73Zm0-10.23a4.38,4.38,0,0,0-4.27,3.49,4.24,4.24,0,0,0-.09.88A4.36,4.36,0,1,0,32,10.94Z"
        />
      </g>
    </g>
  </svg>
);

export default GoogleIcon;
