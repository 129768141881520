import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Chart from './Chart';
import moment from 'moment';

class ChartWrapper extends Component {
  static propTypes = {
    labels: PropTypes.array,
    maxValue: PropTypes.number,
    installs: PropTypes.object,
    shares: PropTypes.object,
    views: PropTypes.object,
    settings: PropTypes.object,
    component: PropTypes.object,
    match: PropTypes.object,
    initialized: PropTypes.bool,
    chartDatasets: PropTypes.array.isRequired,
    dropdownRangeOptions: PropTypes.object,
    initialize: PropTypes.func.isRequired,
    setListener: PropTypes.func.isRequired,
    removeListener: PropTypes.func.isRequired,
  };

  static defaultProps = {
    settings: {
      step: 'week',
      numWeeks: 13,
      dateFormat: 'DD MMM',
      orderField: 'timestamp',
    },
    installs: {
      count: 0,
      values: [],
    },
    shares: {
      count: 0,
      values: [],
    },
    visits: {
      count: 0,
      values: [],
    },
    labels: [],
    dropdownRangeOptions: {
      options: [
        { title: 'Past Year', numWeeks: 52 },
        { title: 'Past Six Months', numWeeks: 26 },
        { title: 'Past Three Months', numWeeks: 13 },
      ],
      displayFormats: [
        { title: 'Weekly', format: 'DD MMM', step: 'week' },
        { title: 'Monthly', format: 'MMM', step: 'month' },
      ],
    },
    chartDatasets: ['installs', 'shares', 'visits'],
  };

  componentWillMount() {
    const { appname } = this.props.match.params;
    const { step, numWeeks, dateFormat } = this.props.settings;

    if (!this.props.initialized) {
      this.props.initialize(appname, step, numWeeks, dateFormat);
    }
  }

  componentDidMount() {
    const settings = this.props.settings;

    setTimeout(() => {
      this.addListeners(settings, this.props);
    }, 600);
  }

  componentWillReceiveProps(nextProps) {
    const { appname } = this.props.match.params;
    const settings = nextProps.settings;
    if (this.props.view !== nextProps.view) {
      this.requestDataOnChange({ step: nextProps.view });
    }

    if (appname !== nextProps.match.params.appname) {
      // remove previous listeners
      this.removeListeners();
      // set new app listeners
      if (!nextProps.initialized) {
        this.props.initialize(
          nextProps.match.params.appname,
          settings.step,
          settings.numWeeks,
          settings.dateFormat,
        );
      }
      setTimeout(() => {
        this.addListeners(settings, nextProps);
      }, 600);
    }
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  /**
   * On dropdown change update the listeners
   * and the chart's configuration
   */
  requestDataOnChange = filters => {
    const { appname } = this.props.match.params;
    let criteria = this.defineNewCriteria(filters);

    let savedDateFrom = localStorage.getItem('customDateFrom');
    let savedDateTo = localStorage.getItem('customDateTo');

    if (savedDateFrom && savedDateTo) {
      criteria.customStartDate = moment(savedDateFrom);
      criteria.customEndDate = moment(savedDateTo);
    }

    this.removeListeners();
    this.props.initialize(
      appname,
      criteria.step,
      criteria.numWeeks,
      criteria.dateFormat,
      criteria.customStartDate,
      criteria.customEndDate,
    );
    this.addListeners(criteria, this.props);
  };

  /**
   * Redifine new settings to get
   * the data to reder the chart
   */
  defineNewCriteria = filters => {
    const { dropdownRangeOptions, settings } = this.props;
    const criteria = Object.assign(settings, filters);
    const display = dropdownRangeOptions.displayFormats.find(
      format => format.step === criteria.step,
    );
    criteria.dateFormat = display.format;

    return criteria;
  };

  /**
   * Remove actives dataset's listeners
   */
  removeListeners() {
    const { appname } = this.props.match.params;
    const { chartDatasets } = this.props;

    chartDatasets.forEach(param => {
      this.props.removeListener(appname, param);
    });
  }

  /**
   * Add new dataset's listeners
   */
  addListeners(settings, props) {
    const { appname } = props.match.params;
    const { chartDatasets } = this.props;
    chartDatasets.forEach(param => {
      this.props.setListener(
        appname,
        param,
        settings.step,
        settings.numWeeks,
        settings.dateFormat,
        settings.orderField,
        settings.customStartDate,
        settings.customEndDate,
      );
    });
  }

  render() {
    return <Chart callback={this.requestDataOnChange} {...this.props} />;
  }
}

export default ChartWrapper;
