import firebase from '../../../../../firebase';
import { validateField } from '../validators';
import { openSnackbar } from '../../../../../app/modules/snackbar/actions';
import { PROFILE_CREATED } from '../config';
import { remove500OfferAccountIds } from '../../../../../utils';
export const NEW_ADMIN_FIELD_CHANGE = 'NEW_ADMIN_FIELD_CHANGE';
export const OPEN_NEW_ADMIN_DIALOG = 'OPEN_NEW_ADMIN_DIALOG';
export const CLOSE_NEW_ADMIN_DIALOG = 'CLOSE_NEW_ADMIN_DIALOG';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const ADD_NEW_ADMIN = 'ADD_NEW_ADMIN';
export const NEW_ADMIN_ADDED = 'NEW_ADMIN_ADDED';

const newAdminFieldChange = (prop, value, isValid) => ({
  type: NEW_ADMIN_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

export const openNewAdminDialog = () => ({ type: OPEN_NEW_ADMIN_DIALOG });

export const closeNewAdminDialog = () => ({ type: CLOSE_NEW_ADMIN_DIALOG });

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;

  return dispatch(newAdminFieldChange(prop, value, isValid));
};

export const registrationError = err => ({
  type: REGISTRATION_ERROR,
  err,
});

const addAdminAccountToRemove500Offer = (account) => {
  firebase.database().ref('remove_500_offer').update({[account]: true})
}

const checkParentAccountInRemove500OfferAccounts = async (account) => {
  const accIds = await remove500OfferAccountIds();
  if(accIds.indexOf(account) > -1){
    return true;
  }
  return false;
}

export const createAccount = (
  firstName,
  lastName,
  email,
  password,
  parentAccount,
  adminAppname,
) =>
  new Promise((resolve, reject) => {
    const { currentUser } = firebase.auth();
    return currentUser.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);
      return fetch(`${process.env.REACT_APP_ADMIN_API}`, {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
          parentOwner: currentUser.uid,
          parentAccount,
          adminAppname,
        }),
        headers,
      })
        .then(res => {
          res.json().then(data => {
            resolve(data);
          });
        })
        .catch(reject);
    });
  });

export const addAdmin = () => (dispatch, getState) => {
  const {
    firstName,
    lastName,
    email,
    password,
    adminAppname,
  } = getState().auth.appAdmins.newAdmin;
  const parentAccount = getState().account.auth.profile.account;
  dispatch({ type: ADD_NEW_ADMIN });

  return createAccount(
    firstName.value,
    lastName.value,
    email.value,
    password.value,
    parentAccount,
    adminAppname.value,
  )
    .then(res => {
      if (!res.uid) {
        return dispatch(registrationError(res.message));
      }
      checkParentAccountInRemove500OfferAccounts(parentAccount) && addAdminAccountToRemove500Offer(res.account)
      dispatch({
        type: NEW_ADMIN_ADDED,
        key: res.uid,
        data: {
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          adminAppname: adminAppname.value,
        },
      });
      return dispatch(openSnackbar(PROFILE_CREATED));
    })
    .catch(error => dispatch(registrationError(error.message)));
};
