import i18n from 'i18next';
import firebase from '../../../../../../firebase';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import { WORKFORCE_GROUP_FIELD_DELETED } from '../../config';

export const FIELD_VALIDATION_ERROR = 'FIELD_VALIDATION_ERROR';
export const REQUEST_WORKFORCE_GROUP_DETAILS =
  'REQUEST_WORKFORCE_GROUP_DETAILS';
export const REQUEST_WORKFORCE_GROUP_FIELD_UPDATE =
  'REQUEST_WORKFORCE_GROUP_FIELD_UPDATE';
export const RECEIVE_WORKFORCE_GROUP_FIELD_UPDATE =
  'RECEIVE_WORKFORCE_GROUP_FIELD_UPDATE';
export const RECEIVE_WORKFORCE_GROUP_DETAILS =
  'RECEIVE_WORKFORCE_GROUP_DETAILS';
export const WORKFORCE_GROUP_FIELD_DELETE = 'WORKFORCE_GROUP_FIELD_DELETE';
export const COC_UPLOAD_START = 'COC_UPLOAD_START';
export const COC_UPLOAD_SUCCESS = 'COC_UPLOAD_SUCCESS';
export const COC_UPLOAD_FAILURE = 'COC_UPLOAD_FAILURE';

const request = () => ({
  type: REQUEST_WORKFORCE_GROUP_DETAILS,
});

const requestUpdateField = () => ({
  type: REQUEST_WORKFORCE_GROUP_FIELD_UPDATE,
});

const FileUploadStart = () => ({
  type: COC_UPLOAD_START,
});

const FileUploadSuccess = () => ({
  type: COC_UPLOAD_SUCCESS,
});

const FileUploadError = message => ({
  type: COC_UPLOAD_FAILURE,
  message,
});

export const updateGroup = (app, groupID, item, value) => dispatch => {
  dispatch(requestUpdateField());
  const update = {
    [item]: value,
    updatedAt: Date.now(),
  };
  firebase
    .database()
    .ref(`workforce_groups/${app}/${groupID}`)
    .update(update)
    .then(() => {
      dispatch({
        type: RECEIVE_WORKFORCE_GROUP_FIELD_UPDATE,
        data: update,
      });
    });
};

export const updateField = (app, groupID, name, fieldID, value) => dispatch => {
  dispatch(requestUpdateField());
  const update = {
    [name]: value,
    updatedAt: Date.now(),
  };
  firebase
    .database()
    .ref(`workforce_groups/${app}/${groupID}/fields/${fieldID}`)
    .update(update)
    .then(() => {
      dispatch({
        type: RECEIVE_WORKFORCE_GROUP_FIELD_UPDATE,
        data: update,
      });
    });
};

export const getGroupDetails = (app, groupID) => dispatch => {
  dispatch(request());

  firebase
    .database()
    .ref(`workforce_groups/${app}/${groupID}`)
    .on('value', snap => {
      dispatch({
        type: RECEIVE_WORKFORCE_GROUP_DETAILS,
        value: snap.exists() ? snap.val() : [],
      });
    });
};

export const deleteField = (app, groupID, fieldID) => dispatch => {
  firebase
    .database()
    .ref(`workforce_groups/${app}/${groupID}/fields/${fieldID}`)
    .remove()
    .then(() => {
      dispatch({
        type: WORKFORCE_GROUP_FIELD_DELETE,
        fieldID,
      });
      dispatch(openSnackbar(WORKFORCE_GROUP_FIELD_DELETED));
    });
};

export const fileUpload = (appName, groupID, files) => async dispatch => {
  files.forEach(async file => {
    // process upload for blob only
    if (!file.preview.includes('blob:http')) {
      return false;
    }
    const response = await fetch(file.preview);
    if (response.ok) {
      // if HTTP-status is 200-299
      // get the response body (the method explained below)
      const blobData = await response.blob();
      const form = new FormData();
      const user = firebase.auth().currentUser;
      const myHeaders = new Headers();
      const firebaseUrl = `workforce_groups/${appName}/${groupID}/cOCDoc`;

      form.append('firebaseUrl', firebaseUrl);
      form.append('file', blobData, file.name);

      return user
        .getIdToken()
        .then(token => {
          dispatch(FileUploadStart());
          myHeaders.append('x-access-token', token);

          return fetch(`${process.env.REACT_APP_IMAGES_API}/upload_file`, {
            method: 'post',
            headers: myHeaders,
            body: form,
            mode: 'cors',
          });
        })
        .then(res => res.json())
        .then(res => {
          if (res.error) {
            dispatch(FileUploadError(res.error));
          } else {
            dispatch(FileUploadSuccess());
          }
        })
        .catch(error => dispatch(FileUploadError(error.message)));
    }
    return dispatch(
      FileUploadError(
        `${i18n.t('File upload error HTTP-Error:')} ${response.status}`,
      ),
    );
  });
};
