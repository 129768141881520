import styled from 'styled-components';

import avatar from './avatar.svg';

const AvatarBackdrop = styled.img.attrs({
  src: avatar,
})`
  position: absolute;
  z-index: -2;
  width: 120%;
  fill: #fff;
`;

export default AvatarBackdrop;
