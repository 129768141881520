const initialState = {
  isFetching: false,
  isAuthenticated: false,
  isResolved: false,
  error: null,
  user: {},
  profile: {},
  platformLang: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVE_LOGOUT':
      return initialState;

    case 'LOGIN_REQUEST':
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      };
    case 'PLATFORM_LANG':
      return {
        ...state,
        platformLang: action.value,
      };

    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        isResolved: true,
        errorMessage: '',
        user: action.user,
      };

    case 'LOGIN_FAILURE':
      return {
        ...state,
        isFetching: false,
        isResolved: true,
        isAuthenticated: false,
        error: action.message,
      };

    case 'HYDRATE_PROFILE':
      return {
        ...state,
        profile: action.data,
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
        isResolved: true,
      };

    case 'CHANGING_APP':
      return {
        ...state,
        profile: {
          ...state.profile,
          isChanging: true,
        },
      };

    case 'APP_CHANGE_SUCCESS':
      return {
        ...state,
        profile: {
          ...state.profile,
          lastVisited: action.appname,
          isChanging: false,
        },
      };

    case 'APP_CHANGE_ERROR':
      return {
        ...state,
        profile: {
          ...state.profile,
          isChanging: false,
          appChangeError: action.err,
        },
      };

    default:
      return state;
  }
};

export default auth;
