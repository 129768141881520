import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'material-ui/Checkbox';

const TableCheckbox = ({ value, onSelect }) => (
  <Checkbox
    checked={value}
    onClick={e => {
      e.stopPropagation();
    }}
    onCheck={e => {
      e.stopPropagation();
      onSelect();
    }}
  />
);

TableCheckbox.propTypes = {
  value: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

TableCheckbox.defaultProps = {
  value: null,
};

export default TableCheckbox;
