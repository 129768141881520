import React, { Component } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import _get from 'lodash/get';
// import es6-shim polyfill to support IE & older browsers
import 'es6-shim';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import '../utils/polyfills';
import { STAFF_ADMIN } from '../config';
import ClickbankThankYou from '../modules/account/modules/clickbankThankyou';
import { LoadingScreen } from './components/progress';
import NotFound from './modules/notFound';
import Dialog from './modules/dialog';
import Snackbar from './modules/snackbar';
import AppContext from './modules/appContext';
import OlarkChat from './modules/olarkChat';
import { CreateApp } from '../modules/editor/modules/build';
import CreateIntegrationApp from '../modules/editor/modules/customise/modules/create/createIntegrationApp';
import {
  AccountModal,
  Login,
  Register,
  IntegrationsLogin,
  NotPublished,
  ForgottenPassword,
  FirebaseActions,
  SMSBundles,
  WhatsappBundles,
  BuySMS,
  BuyCredits,
  InstagramLogin,
  UpdatePassword,
} from '../modules/account';
import { JAN_50_OFF } from '../plans';

import RedeemRequest from '../modules/account/modules/referral/modules/redeemRequest';
import './styles.css';
import { whitelabeledHost } from '../utils/whitelabeledHost';
import AppMenu from '../modules/editor/modules/build/modules/appMenu';
import DuplicateApp from '../modules/editor/modules/build/modules/create/duplicateApp';

class App extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    startListeningToAuthChange: PropTypes.func.isRequired,
    loadOffers: PropTypes.func.isRequired,
    saveOfferFromQueryString: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    appColor: PropTypes.string.isRequired,
    isAppAdmin: PropTypes.bool.isRequired,
    isSubAdminWithMulipleApps: PropTypes.bool.isRequired,
    getPlatformTranslation: PropTypes.func.isRequired,
    //  onLoadFetchAppThemeColor: PropTypes.func.isRequired,
  };

  static defaultProps = {
    role: '',
  };

  state = {
    isLoading: true,
  };

  async componentDidMount() {
    // await this.props.onLoadFetchAppThemeColor();
    const { isAppAdmin, role, isSubAdminWithMulipleApps } = this.props;
    const isWhiteLabeledHost = whitelabeledHost();
    const isSubAdmin =
      isAppAdmin || role === STAFF_ADMIN || isSubAdminWithMulipleApps;

    const query = queryString.parse(this.props.location.search);

    this.props.loadOffers();

    if (query && query.offer && query.offer !== JAN_50_OFF) {
      this.props.saveOfferFromQueryString(query.offer);
    }

    this.props.startListeningToAuthChange(user => {
      if (user && this.props.history.location.pathname !== '/reset_password') {
        if (this.siteNotPublished()) {
          return this.props.history.push(
            `/integrations/notpublished?integrationName=${this.siteNotPublished()}`,
          );
        } else if (this.isWixUser()) {
          return this.props.history.push('/integrations/create');
        }
        window.Wix.Dashboard.getEditorUrl(url =>
          // Not wix user inside Wix dashboard
          window.top.location.replace(window.location.href),
        );
        if (this.isWeeblyUser()) {
          return this.props.history.push('/integrations/create');
        } else if (!this.props.account.apps) {
          return this.props.history.push('/create');
        } else if (this.props.location.pathname === '/') {
          // redirection for staff admin
          if (this.props.role === STAFF_ADMIN) {
            const app = Object.keys(this.props.account.apps)[0];
            return this.props.history.push(`/${app}/staff/member-bodies`);
          }
          // redirect to dashboard
          const lastVisited =
            this.props.auth.profile.lastVisited ||
            Object.keys(this.props.account.apps)[0];
          return this.props.history.push(`/${lastVisited}/editor`);
        } else if (this.props.location.pathname === '/reactivate') {
          if (query && query.offer && query.offer !== JAN_50_OFF) {
            this.props.setOfferEligibilityForReturningUser(
              this.props.auth,
              this.props.offers[query.offer],
            );
            // redirect to dashboard
            const lastVisited =
              this.props.auth.profile.lastVisited ||
              Object.keys(this.props.account.apps)[0];
            return this.props.history.push(`/${lastVisited}/editor`);
          }
        }
      } else if (
        this.props.history.location.pathname !== '/usermgmt' &&
        this.props.history.location.pathname !== '/register' &&
        this.props.history.location.pathname !== '/reset_password' &&
        this.props.history.location.pathname !== '/integrations/notpublished' &&
        this.props.history.location.pathname !== '/integrations/login' &&
        this.props.history.location.pathname !== '/' &&
        this.props.history.location.pathname !== '/redeem-request' &&
        this.props.history.location.pathname !== '/clickbank-thank-you-page'
      ) {
        // redirect to login
        return this.props.history.push('/');
      }

      if (query && query.offer && query.offer !== JAN_50_OFF) {
        return this.props.history.push('/register');
      }

      if (this.props.account) {
        this.props.getPlatformTranslation(this.props.account.key);
      }

      return null;
    });

    const targetNode = document.body;

    const observer = new MutationObserver(mutationsList => {
      mutationsList.forEach(mutation => {
        if (mutation.type === 'childList') {
          const injectedElement = document.querySelector('#fc_frame');
          if (injectedElement && (isWhiteLabeledHost || isSubAdmin)) {
            injectedElement.style.display = 'none';
          }
        }
      });
    });

    const config = { childList: true, subtree: true };
    observer.observe(targetNode, config);

    this.observer = observer;
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  componentDidUpdate(prevProps) {
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('--primaryColor', this.props.appColor);

    if (
      // prevProps.isAppAdmin !== this.props.isAppAdmin &&
      this.props.isAppAdmin ||
      this.props.isSubAdminWithMulipleApps
    ) {
      if (window.fcWidget) window.fcWidget.hide();
    }

    if (this.props.account && this.props.account.key) {
      if (this.props.auth.platformLang) {
        i18n.changeLanguage(this.props.auth.platformLang);
      }
    }
  }

  // A user coming from wix
  isWixUser = () => _get(this.props.account, 'integration.type') === 'Wix';

  // Check if wix or weebly site is not published
  siteNotPublished = () => {
    const name = 'integrationName';
    const url = window.location.href;
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return false;
    if (!results[2]) return false;
    const parameter = decodeURIComponent(results[2].replace(/\+/g, ' '));
    return parameter === 'wix' || parameter === 'weebly' ? parameter : false;
  };

  isWeeblyUser = () =>
    _get(this.props.account, 'integration.type') === 'Weebly';

  // Platform inside an iframe
  isInsideIFrame = () => window.self !== window.top;

  render() {
    const isWhiteLabeledHost = whitelabeledHost();

    return (
      <div className="size-100">
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/reset_password" component={UpdatePassword} />
          <Route exact path="/reactivate" component={Login} />
          <Route
            exact
            path="/integrations/login"
            component={IntegrationsLogin}
          />
          <Route
            exact
            path="/integrations/notpublished"
            component={NotPublished}
          />
          <Route
            exact
            path="/integrations/create"
            component={CreateIntegrationApp}
          />
          <Route
            path="/(thank-you-page-anything-monthly|thank-you-page-anything-yearly|thank-you-page-everything-monthly|thank-you-page-everything-yearly|thank-you-page-something-yearly|thank-you-page-something-monthly|thank-you-page-99-offer-everything-monthly|thank-you-page-lifetime-offer)/"
            component={() => {
              window.location.href = `https://www.beezer.com${window.location.pathname}`;
              return null;
            }}
          />
          <Route
            path="/clickbank-thank-you-page"
            component={ClickbankThankYou}
          />
          <Route exact path="/register">
            {!isWhiteLabeledHost ? <Register /> : <Redirect to="/login" />}
          </Route>
          <Route path="/redeem-request" component={RedeemRequest} />
          {this.props.auth.isResolved && (
            <Switch>
              <Route exact path="/create" component={CreateApp} />
              <Route
                exact
                path="/forgot_password"
                component={ForgottenPassword}
              />
              <Route path="/usermgmt" component={FirebaseActions} />
              <Route path="/choose_sms_bundle" component={SMSBundles} />
              <Route
                path="/choose_whatsapp_credits_bundle"
                component={WhatsappBundles}
              />

              <Route path="/app_menu" component={AppMenu} />
              <Route path="/duplicate_app" component={DuplicateApp} />
              <Route path="/purchase_sms" component={BuySMS} />
              <Route path="/purchase_credits" component={BuyCredits} />
              <Route path="/instagram_login" component={InstagramLogin} />
              <Route path="/not_found" component={NotFound} />
              <Route path="/:appname" component={AppContext} />
            </Switch>
          )}
        </Switch>
        <AccountModal />
        <Dialog />
        <div className="notificationWrapper">
          <Snackbar />
        </div>
        <LoadingScreen loading={!this.props.auth.isResolved} />
        <OlarkChat />
      </div>
    );
  }
}

export default App;
