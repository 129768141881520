import firebase from '../../firebase';
import seedComponent from '../editor/modules/build/modules/create/createComponent/seedComponent';
import { INTERNAL_APP_PATHS } from '../../config';
export const addInventoryDialogOpen = isOpen => ({
  type: 'ADD_INVENTORY_DIALOG',
  payload: isOpen,
});

export const inventoryItemChange = item => ({
  type: 'INVENTORY_ITEM_CHANGE',
  payload: item,
});

const toPath = title =>
  title
    .toLowerCase()
    .replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g, '')
    .replace(/\s+/g, '-')
    .trim();

const createMarketplacePage = (appname, getState, alwaysDataRef) => {
  const childRoutesRef = firebase
    .database()
    .ref(`apps/${appname}/draft/layout_data/childRoutes`);
  const layoutData = getState().engage.structure.routes || [];
  const form = getState().editor.build.create.createPage;
  const pageTitle = 'Marketplace';
  const paths = layoutData ? layoutData.map(item => item.path) : [];
  paths.push(...INTERNAL_APP_PATHS);
  const tabs = null;
  let newPageKey;
  let path = toPath(pageTitle);

  if (paths.indexOf(path) !== -1) {
    path = path.concat('-', Date.now().toString());
  }

  return alwaysDataRef
    .push({
      title: pageTitle,
      type: form.type,
      path,
      tabs,
    })
    .then(pageRef => {
      newPageKey = pageRef.key;

      return childRoutesRef
        .transaction(value => {
          if (!value) return [newPageKey];
          return [...value, pageRef.key];
        })
        .then(() => newPageKey);
    });
};

const createMarketplaceComponent = (
  appname,
  pageKey,
  alwaysDataRef,
  account,
  palette,
) => {
  const lazyDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data`);
  const componentsArrayRef = alwaysDataRef.child(`${pageKey}/components`);

  const marketplaceSeedData = seedComponent('Marketplace', account, {}, null);

  return lazyDataRef
    .push(marketplaceSeedData.content)
    .then(snapshot => {
      // update not_private permissions for new components
      // so that new components will be public when Member Login is enabled
      firebase
        .database()
        .ref(`app_groups/${appname}/not_private`)
        .update({ [snapshot.key]: true });

      return snapshot;
    })
    .then(snapshot => {
      return alwaysDataRef.push({
        type: 'Marketplace',
        lazy_data: snapshot.key,
        design: marketplaceSeedData.design,
        layout: marketplaceSeedData.layout,
      });
    })
    .then(snapshot => {
      componentsArrayRef.transaction(value => {
        if (!value) return [snapshot.key];

        return [...value, snapshot.key];
      });
      return snapshot.key;
    })
    .then(key => key);
};

export const addMarketplaceComponent = (appname, history) => (
  dispatch,
  getState,
) => {
  const alwaysDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data`);
  createMarketplacePage(appname, getState, alwaysDataRef)
    .then(newPageKey => {
      const account = getState().account.profile.account;
      createMarketplaceComponent(appname, newPageKey, alwaysDataRef, account)
        .then(key => {
          const ecommerceRoute = /ecommerce(.*)/;
          history.push(
            history.location.pathname.replace(
              ecommerceRoute,
              `editor/build/list?page=${newPageKey}&component=${key}`,
            ),
          );
        })
        .catch(err => console.error(err));
    })
    .catch(err => console.error(err));
};
