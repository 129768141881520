import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Checkbox from 'material-ui/Checkbox';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { objectToArray } from '../../../../../../../utils/object';
import SectionHeader from '../../../../../../editor/components/sectionHeader';
import Container from '../../../../../../../app/components/container';
import { Tabs, Tab } from '../../../../../components/tabs';
import DesignToggle from '../../../../../components/designToggle';
import ButtonDesignMenu from '../../../../../components/buttonDesignMenu';

import './styles.css';

const ButtonWrapper = styled.button`
  margin-top: 20px;
  padding: 9px;
  width: 100% !important;
  font-size: 20px;
  font-weight: bold;
  color: ${props => `${props.theme.primaryTextColor}`};
  height: 100% !important;
  background-color: ${props =>
    !props.disabled ? `${props.theme.primaryColor} !important` : '#cccccc'};
`;
const Div = styled.div`
  width: 100%;
  padding: 15px 0px;
`;

const SubscriptionPlans = props => {
  const {
    getGroups,
    onSubmit,
    data,
    match,
    component,
    onDesignToggle,
    onBtnDesignChange,
  } = props;
  const { t } = useTranslation();
  const [checkedItems, setCheckedItems] = useState([]);
  const groups = useSelector(state => state.auth.groups.data.filteredChunks);
  const subscriptionGroups = objectToArray(groups).filter(
    item => item.value.subscriptionPlan,
  );

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    setCheckedItems(data ? data.subscriptionItems : []);
  }, [data]);

  const saveSubscriptions = () => {
    onSubmit(checkedItems);
  };

  const handleChange = (checked, key) => {
    const items = checkedItems.filter(item => item === key);
    const otherItems = checkedItems.filter(item => item !== key);
    if (items.length > 0) {
      setCheckedItems([...otherItems]);
    } else {
      setCheckedItems([...checkedItems, key]);
    }
  };

  return (
    <div className="subscription-wrapper">
      <Tabs>
        <Tab label={t('content')}>
          <Container>
            <SectionHeader
              title={t('Subscription Plans')}
              tooltip={t('Select subscription plans to display')}
            />

            {Object.keys(subscriptionGroups).length > 0 && (
              <div className="container">
                <p>
                  <Trans>Select Subscription Plans to display</Trans>
                </p>
              </div>
            )}
            <div className="container">
              <div className="items">
                {Object.keys(subscriptionGroups).length > 0 ? (
                  Object.keys(subscriptionGroups).map(key => {
                    const plan = subscriptionGroups[key];
                    return (
                      <div className="item">
                        <Checkbox
                          label={plan.value.name}
                          style={{
                            paddingLeft: 18,
                            paddingRight: 18,
                            marginTop: 18,
                            marginBottom: 18,
                          }}
                          labelStyle={{ fontSize: 14 }}
                          checked={checkedItems.includes(plan.id)}
                          onCheck={(e, checked) =>
                            handleChange(checked, plan.id)
                          }
                        />
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <p>
                      <Trans>No Subscription Plans yet.</Trans>{' '}
                      <Link to={`/${match.params.appname}/members/groups`}>
                        <Trans>Click here</Trans>
                      </Link>{' '}
                      <Trans>to create a subscription plan</Trans>
                    </p>
                  </div>
                )}
              </div>
            </div>

            {Object.keys(subscriptionGroups).length > 0 && (
              <div className="container">
                <ButtonWrapper
                  disabled={checkedItems.length === 0}
                  onClick={saveSubscriptions}
                >
                  <Trans>Save</Trans>
                </ButtonWrapper>
              </div>
            )}
          </Container>
        </Tab>
        <Tab label={t('design')}>
          <Container>
            <DesignToggle
              component={component}
              onDesignChange={(e, isToggled) => onDesignToggle(isToggled)}
            />
            <ButtonDesignMenu
              component={component}
              onDesignChange={onBtnDesignChange()}
            />
          </Container>
        </Tab>
      </Tabs>
    </div>
  );
};

SubscriptionPlans.propTypes = {
  getGroups: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  onDesignToggle: PropTypes.func.isRequired,
  onBtnDesignChange: PropTypes.func.isRequired,
};

export default SubscriptionPlans;
