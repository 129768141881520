import { connect } from 'react-redux';

import SMSBundles from './SMSBundles';
import { setAmount } from '../buySMS/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  profile: state.account.profile,
  intl: state.account.intl,
  integration: state.account.organisation.integration,
});

const mapDispatchToProps = dispatch => ({
  setAmount: (key, integration) =>
    dispatch(setAmount({ bundle: key }, integration)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SMSBundles);
