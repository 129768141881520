import FormData from 'form-data';
import queryString from 'query-string';
import firebase from '../../../../firebase';
import { dataURLtoBlob } from '../../../../utils';
import { fetchRegionalCentreImages } from '../../../pgmol/modules/RegionalPerformanceCentre/actions';

export const openDialog = () => ({
  type: 'OPEN_IMAGES_UPLOAD_DIALOG',
});

export const closeDialog = () => ({
  type: 'CLOSE_IMAGES_UPLOAD_DIALOG',
});

export const openFileAndUrlDialog = () => ({
  type: 'OPEN_FILE_AND_URL_DIALOG',
});

export const fileAndUrlDialogCancel = () => ({
  type: 'CLOSE_FILE_AND_URL_DIALOG',
});

export const fileUploadUrlChange = value => ({
  type: 'CHANGE_FILE_UPLOAD_URL',
  payload: value,
});

export const receiveImage = file => ({
  type: 'RECEIVED_IMAGES',
  file,
  config: [{}],
});

export const selectImage = index => ({
  type: 'SELECT_IMAGE_INDEX',
  index,
});

export const receiveCrop = (index, crop) => ({
  type: 'UPDATE_CROP_DIMENSIONS',
  index,
  crop,
});

export const reportError = message => ({
  type: 'IMAGES_UPLOAD_FAILURE',
  message,
});

export const clearError = () => ({
  type: 'CLEAR_IMAGES_UPLOAD_ERROR',
});

export const imageUploadSuccess = () => ({
  type: 'IMAGES_UPLOAD_SUCCESS',
});

export const resetImageUploads = () => ({
  type: 'RESET_IMAGES_UPLOAD',
});

export const responseReceived = () => ({
  type: 'SUBMIT_IMAGE_RESPONSE_RECEVIED',
});

export const openDeleteDialog = () => ({
  type: 'OPEN_DELETE_DIALOG',
});

export const closeDeleteDialog = () => ({
  type: 'CLOSE_DELETE_DIALOG',
});

export const removeImageFromDraft = index => ({
  type: 'REMOVE_IMAGE_FROM_DRAFT',
  index,
});

export const markMainImage = firebaseUrl => dispatch => {
  firebase
    .database()
    .ref(`${firebaseUrl}`)
    .once('value', snapshot => {
      if (snapshot.exists()) {
        const images = snapshot.val();
        const keyList = Object.keys(images);
        const componentRef = firebaseUrl.replace('/gallery', '');
        firebase
          .database()
          .ref(`${componentRef}`)
          .update({ mainImage: keyList[0] });
      }
    });
};

const drawImage = (image, crop, fileType = 'jpeg') => {
  const imageWidth = image.naturalWidth;
  const imageHeight = image.naturalHeight;

  const cropX = (crop.x / 100) * imageWidth;
  const cropY = (crop.y / 100) * imageHeight;

  const cropWidth = (crop.width / 100) * imageWidth;
  const cropHeight = (crop.height / 100) * imageHeight;

  const canvas = document.createElement('canvas');

  canvas.width = cropWidth;
  canvas.height = cropHeight;

  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    cropX,
    cropY,
    cropWidth,
    cropHeight,
    0,
    0,
    cropWidth,
    cropHeight,
  );

  const dataurl = ctx.canvas.toDataURL(`image/${fileType}`, 1.0);

  return {
    dataUrl: dataurl,
    cropWidth,
    cropHeight,
  };
};

const cropImages = (dataurl, cropArray, fileType) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      let result;
      try {
        if (dataurl.includes('image/gif')) {
          result = {
            dataurl,
            dimensions: `${image.width}x${image.height}`,
          };
        } else if (cropArray.height === 100 && cropArray.width === 0) {
          result = {
            dataurl,
            dimensions: `${image.width}x${image.height}`,
          };
        } else {
          const { dataUrl, cropWidth, cropHeight } = drawImage(
            image,
            cropArray,
            fileType,
          );
          result = {
            dataurl: dataUrl,
            dimensions: cropArray.dimensions
              ? cropArray.dimensions
              : `${cropWidth}x${cropHeight}`,
          };
        }
      } catch (e) {
        return reject(e);
      }

      return resolve(result);
    };
    image.onerror = () =>
      reject(new Error('Something went wrong, please try again'));

    image.setAttribute('crossOrigin', 'anonymous');
    image.src = dataurl;
  });

const readImage = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onabort = () => reject(new Error('file reading was aborted'));
    reader.onerror = () => reject(new Error('file reading has failed'));

    reader.readAsDataURL(file);
  });

export const uploadImage = (dataurl, sizes, firebaseUrl, appName) => {
  const form = new FormData();
  const myHeaders = new Headers();
  const user = firebase.auth().currentUser;

  form.append('sizes', sizes);
  form.append('firebaseUrl', firebaseUrl);
  form.append('appName', appName);
  if (dataurl) {
    form.append('img', dataURLtoBlob(dataurl));
  }
  form.append('isMultiple', true);
  return user.getIdToken().then(token => {
    myHeaders.append('x-access-token', token);

    const endpoint = 'upload';

    return fetch(`${process.env.REACT_APP_IMAGES_API}/${endpoint}`, {
      method: 'post',
      headers: myHeaders,
      body: form,
      mode: 'cors',
    }).then(res => {
      if (res.status !== 200) {
        throw new Error('Something went wrong, please try again');
      }
    });
  });
};

const uploadPromise = (file, cropArray, fileType, firebaseUrl, appName) =>
  new Promise((resolve, reject) => {
    readImage(file)
      .then(dataurl => cropImages(dataurl, cropArray, fileType))
      .then(image =>
        uploadImage(image.dataurl, image.dimensions, firebaseUrl, appName),
      )
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });

export const submitImageCrop = (
  firebaseUrl,
  appName,
  setMainImage,
  fileType,
) => (dispatch, getState) => {
  const { files } = getState().editor.internals.multipleImageUpload;

  dispatch({ type: 'SUBMIT_IMAGES_CROP' });
  dispatch(closeDialog());
  dispatch(resetImageUploads());

  const returnedObjects = [];
  const promises = [];
  for (let i = 0; i < files.length; i++) {
    const item = files[i];
    promises.push(
      uploadPromise(
        item.file,
        item.cropDetails,
        fileType,
        firebaseUrl,
        appName,
      ).then(upload => returnedObjects.push(upload)),
    );
  }
  return Promise.all(promises)
    .then(() => {
      dispatch(imageUploadSuccess());
      if (setMainImage) {
        setTimeout(() => {
          dispatch(markMainImage(firebaseUrl));
        }, 2000);
      }

      if (firebaseUrl && firebaseUrl.includes('regional-performance-centre')) {
        const location = firebaseUrl.split(
          `pgmol/tempImages/regional-performance-centre/${appName}/`,
        )[1];
        dispatch(fetchRegionalCentreImages(firebaseUrl, appName, location));
      }

      files.forEach(f => URL.revokeObjectURL(f.preview));
    })
    .catch(err => {
      if (setMainImage) {
        dispatch(markMainImage(firebaseUrl));
      }
      if (err.message === 'Failed to fetch') {
        return dispatch(
          reportError(
            'Unable to reach the network at this time. Please try again.',
          ),
        );
      }
      return dispatch(reportError(err.message));
    })
    .finally(() => dispatch(responseReceived()));
};

export const deleteImage = (
  src,
  componentFirebaseUrl,
  firebaseUrl,
  deleteMainImage,
) => dispatch => {
  const myHeaders = new Headers();
  const user = firebase.auth().currentUser;
  const filename = src.replace(/https:\/\/.*\.com/, '');
  const query = queryString.stringify({ filename, firebaseUrl });
  dispatch({ type: 'GALLERY_IMAGE_DELETE_REQUEST' });
  return new Promise((resolve, reject) => {
    user
      .getIdToken()
      .then(token => {
        myHeaders.append('x-access-token', token);

        return fetch(`${process.env.REACT_APP_IMAGES_API}/delete?${query}`, {
          method: 'delete',
          headers: myHeaders,
          mode: 'cors',
        });
      })
      .then(res => {
        if (res.status !== 200) {
          throw new Error('Failed to delete image, please try again');
        }
        if (deleteMainImage) {
          console.log(componentFirebaseUrl);
          dispatch(markMainImage(componentFirebaseUrl));
        }
        dispatch({ type: 'GALLERY_IMAGE_DELETED' });
        dispatch(closeDeleteDialog());
        resolve();
      })
      .catch(error => {
        dispatch(reportError('Something went wrong, please try again.'));
        dispatch(closeDeleteDialog());
        reject(error);
      });
  });
};

export const updateMainImage = (firebaseUrl, node) => dispatch => {
  const componentRef = firebaseUrl.replace('/gallery', '');
  firebase
    .database()
    .ref(`${componentRef}`)
    .update({ mainImage: node });
};
