import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Toolbar from './Toolbar';
import {
  changeApp,
  toggleUserMenu,
  toggleAppMenu,
  addIsPublishedListener,
  removeIsPublishedListener,
} from './actions';
import { toggleAccountModal, logout } from '../../../modules/account/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  appMenu: state.app.toolbar.appMenu,
  userMenu: state.app.toolbar.userMenu,
  profile: state.account.auth.profile,
  account: state.account,
  apps: state.account.organisation.apps,
  ...state.account.organisation.integration,
  isPublished: state.editor.data.isPublished[ownProps.match.params.appname],
  isAppAdmin: state.account.auth.profile.admin_appname || false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleAppMenu: open => dispatch(toggleAppMenu(open)),
  toggleUserMenu: open => dispatch(toggleUserMenu(open)),
  onLogout: () => {
    dispatch(toggleUserMenu(false));
    dispatch(logout());
  },
  onAppChange: newApp =>
    dispatch(changeApp(newApp, ownProps.match.params.appname)),
  onAccountSettingsTouchTap: () => {
    dispatch(toggleUserMenu(false));
    dispatch(toggleAccountModal());
  },
  onAddIsPublishedListener: () =>
    dispatch(addIsPublishedListener(ownProps.match.params.appname)),
  onRemoveIsPublishedListener: () =>
    dispatch(removeIsPublishedListener(ownProps.match.params.appname)),
  onDocumentationTouchTap: () => dispatch(toggleUserMenu(false)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Toolbar),
);
