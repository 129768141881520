import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  Select,
  MenuItem,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import OrderDetailsCard from '../OrderDetailsCard/OrderDetailsCard';

import { assignDriverToOrder } from '../../api';

import styles from './styles.module.scss';
import themeStyles from '../../../../_export.scss';

const useStyles = makeStyles(() => ({
  appBar: {
    position: 'relative',
    color: themeStyles.primaryTextColor,
    backgroundColor: themeStyles.primaryColor,
  },
}));

const OrderDetails = props => {
  const {
    openDialog,
    setOpenDialog,
    orderDetails,
    driverData,
    metaData,
    appname,
    setRefreshChildData,
    isButtonDisabled,
    setIsButtonDisabled,
  } = props;
  const { t } = useTranslation();
  const [driverAssignKey, setDriverAssignKey] = useState(
    orderDetails.driverKey ? orderDetails.driverKey : '',
  );
  useEffect(() => {
    setDriverAssignKey(orderDetails.driverKey ? orderDetails.driverKey : '');
  }, [orderDetails]);

  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(assignDriverToOrder);

  const classes = useStyles();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsButtonDisabled(true);

    await mutateAsync({
      appname,
      driverDetails: { ...driverData[driverAssignKey], key: driverAssignKey },
      orderSummary: orderDetails,
      metaData,
    });
    queryClient.invalidateQueries('foodOrders');
    setIsButtonDisabled(false);
    setRefreshChildData(true);
  };

  return (
    <React.Fragment>
      <Dialog fullScreen open={openDialog} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1>
              <Trans>Order Details</Trans>
            </h1>
            <IconButton
              style={{ marginLeft: 'auto' }}
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div>
          <OrderDetailsCard
            orderDetails={orderDetails}
            appname={appname}
            setOpenDialog={setOpenDialog}
          />
          {orderDetails.deliveryType !== 'pickup' && (
            <form className={styles.form} onSubmit={e => handleSubmit(e)}>
              <h3>
                <Trans>Assign Driver</Trans>
              </h3>
              <Select
                disabled={orderDetails.deliveryStatus}
                value={driverAssignKey}
                onChange={e => setDriverAssignKey(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  <Trans>Select a driver</Trans>
                </MenuItem>
                {driverData &&
                  Object.keys(driverData).length > 0 &&
                  Object.keys(driverData).map(key => (
                    <MenuItem
                      key={key}
                      value={key}
                      disabled={orderDetails.driverKey === key}
                    >
                      {driverData[key].name} ({driverData[key].email})
                    </MenuItem>
                  ))}
              </Select>
              <button
                className={styles.button}
                disabled={
                  orderDetails.deliveryStatus ||
                  !driverAssignKey ||
                  isButtonDisabled
                }
              >
                {isButtonDisabled
                  ? `${t('Assigning...')}`
                  : orderDetails.deliveryStatus
                  ? `${t('Delivered by')} ${orderDetails.deliverBy}`
                  : driverAssignKey
                  ? `${t('Assign')}`
                  : `${t('Select a driver to assign')}`}
              </button>
              {orderDetails.deliverBy && (
                <div>
                  <Trans>Currently assigned to</Trans> {orderDetails.deliverBy}
                </div>
              )}
            </form>
          )}
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default OrderDetails;
