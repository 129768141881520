/* global fetch Headers atob Blob*/
import FormData from 'form-data';
import queryString from 'query-string';
import firebase from '../../../../firebase';
import { dataURLtoBlob } from '../../../../utils';
import { fetchAppPreference } from '../../../../app/modules/appPreference/action';
import { fetchBlogImage } from '../../../engage/modules/Blog/actions';
import { setUpdatedImageStatus } from '../../../../modules/memberLogin/modules/groups/editGroup/actions';

export const openDialog = targetId => ({
  type: 'OPEN_IMAGE_CROP_DIALOG',
  targetId,
});

export const closeDialog = targetId => ({
  type: 'CLOSE_IMAGE_CROP_DIALOG',
  targetId,
});

export const openFileAndUrlDialog = targetId => ({
  type: 'OPEN_FILE_AND_URL_DIALOG',
  targetId,
});

export const fileAndUrlDialogCancel = targetId => ({
  type: 'CLOSE_FILE_AND_URL_DIALOG',
  targetId,
});

export const fileUploadUrlChange = (targetId, value) => ({
  type: 'CHANGE_FILE_UPLOAD_URL',
  targetId,
  value,
});

export const initializeCropper = (targetId, config) => ({
  type: 'RECEIVE_INITIAL_SETTINGS',
  targetId,
  config,
});

export const receiveCrop = (targetId, crop) => ({
  type: 'RECEIVE_CROP_DIMENSIONS',
  targetId,
  crop,
});

/**
 * Receives event from input type file
 */
export const receiveImage = (targetId, file) => ({
  type: 'RECEIVE_IMAGE',
  targetId,
  file,
});

export const clearError = targetId => ({
  type: 'CLEAR_IMAGE_UPLOAD_ERROR',
  targetId,
});

export const resetImageCrop = targetId => ({
  type: 'RESET_IMAGE_PICKER',
  targetId,
});

export const prevStep = targetId => ({
  type: 'PREV_IMAGE_CROP_STEP',
  targetId,
});

export const nextStep = targetId => ({
  type: 'NEXT_IMAGE_CROP_STEP',
  targetId,
});

export const setStep = (targetId, index) => ({
  type: 'SET_IMAGE_CROP_STEP',
  index,
  targetId,
});

const imageUploadSuccess = targetId => ({
  type: 'IMAGE_UPLOAD_SUCCESS',
  targetId,
});

export const reportError = (targetId, message) => ({
  type: 'IMAGE_UPLOAD_FAILURE',
  message,
  targetId,
});

export const warnUser = (targetId, message) => ({
  type: 'WARN_USER_OF_IMAGE',
  message,
  targetId,
});

export const clearWarning = targetId => ({
  type: 'CLEAR_WARNING',
  targetId,
});

export const deleteComponentData = targetId => ({
  type: 'REMOVE_UPLOAD_COMPONENT_DATA',
  targetId,
});

export const deleteTempImage = appname => ({
  targetId: undefined,
  type: 'IMAGE_DELETED',
  firebaseUrl: `/apps/${appname}/temp_data/editGroupImage`,
});

export const deleteImage = (targetId, src, firebaseUrl) => dispatch => {
  const myHeaders = new Headers();
  const user = firebase.auth().currentUser;
  const filename = src.replace(/https:\/\/.*\.com/, '');
  const query = queryString.stringify({ filename, firebaseUrl });

  user
    .getIdToken()
    .then(token => {
      myHeaders.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_IMAGES_API}/delete?${query}`, {
        method: 'delete',
        headers: myHeaders,
        mode: 'cors',
      });
    })
    .then(res => {
      if (res.status !== 200) {
        throw new Error('Failed to delete image, please try again');
      }

      dispatch({ targetId, type: 'IMAGE_DELETED', firebaseUrl });
    })
    .catch(() =>
      dispatch(
        reportError(targetId, 'Something went wrong, please try again.'),
      ),
    );
};

const readImage = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onabort = () => reject(new Error('file reading was aborted'));
    reader.onerror = () => reject(new Error('file reading has failed'));

    reader.readAsDataURL(file);
  });

const drawImage = (image, crop, fileType = 'jpeg') => {
  const imageWidth = image.naturalWidth;
  const imageHeight = image.naturalHeight;

  const cropX = (crop.x / 100) * imageWidth;
  const cropY = (crop.y / 100) * imageHeight;

  const cropWidth = (crop.width / 100) * imageWidth;
  const cropHeight = (crop.height / 100) * imageHeight;

  const canvas = document.createElement('canvas');

  canvas.width = cropWidth;
  canvas.height = cropHeight;

  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    cropX,
    cropY,
    cropWidth,
    cropHeight,
    0,
    0,
    cropWidth,
    cropHeight,
  );

  const dataurl = ctx.canvas.toDataURL(`image/${fileType}`, 1.0);

  return {
    dataUrl: dataurl,
    cropWidth,
    cropHeight,
  };
};

const cropImages = (dataurl, cropArray, fileType) =>
  new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => {
      let result;
      try {
        if (dataurl.includes('image/gif')) {
          result = [
            {
              dataurl,
              dimensions: `${image.width}x${image.height}`,
            },
          ];
        } else {
          result = cropArray.map(crop => {
            if (crop.height === 100 && crop.width === 0) {
              return {
                dataurl,
                dimensions: `${image.width}x${image.height}`,
              };
            }
            const { dataUrl, cropWidth, cropHeight } = drawImage(
              image,
              crop,
              fileType,
            );

            return {
              dataurl: dataUrl,
              dimensions: crop.dimensions
                ? crop.dimensions
                : `${cropWidth}x${cropHeight}`,
            };
          });
        }
      } catch (e) {
        return reject(e);
      }

      return resolve(result);
    };

    image.onerror = () =>
      reject(new Error('Something went wrong, please try again'));

    image.setAttribute('crossOrigin', 'anonymous');
    image.src = dataurl;
  });

export const uploadImage = (
  dataurl,
  sizes,
  firebaseUrl,
  appName,
  imageType,
) => {
  const form = new FormData();
  const myHeaders = new Headers();
  const user = firebase.auth().currentUser;

  form.append('sizes', sizes);
  form.append('firebaseUrl', firebaseUrl);
  form.append('appName', appName);
  if (dataurl) {
    form.append('img', dataURLtoBlob(dataurl));
  }
  form.append('imageType', imageType);
  return user.getIdToken().then(token => {
    myHeaders.append('x-access-token', token);

    let endpoint;
    if (imageType) {
      endpoint = imageType === 'customLogo' ? 'upload_image' : 'upload_icon';
    } else {
      endpoint = 'upload';
    }

    return fetch(`${process.env.REACT_APP_IMAGES_API}/${endpoint}`, {
      method: 'post',
      headers: myHeaders,
      body: form,
      mode: 'cors',
    }).then(res => {
      if (res.status !== 200) {
        if (imageType === 'splash') {
          throw new Error(
            'Please add your app icon before uploading splash screen image. Then try again.',
          );
        } else {
          throw new Error('Something went wrong, please try again');
        }
      }
    });
  });
};

const uploadImages = (images, firebaseUrl, appName, imageType) => {
  const requests = images.map(image =>
    uploadImage(
      image.dataurl,
      image.dimensions,
      firebaseUrl,
      appName,
      imageType,
    ),
  );

  return Promise.all(requests);
};

export const submitImageCrop = (
  targetId,
  firebaseUrl,
  fileType,
  appName,
  imageType,
  source,
) => (dispatch, getState) => {
  const { file, cropArray } = getState().editor.internals.imageUpload[targetId];

  dispatch({ targetId, type: 'SUBMIT_IMAGE_CROP' });
  dispatch(closeDialog(targetId));
  // read then load the image in a new image instance before cropping

  if (file.type.match('video.*')) {
    uploadMedia(file, firebaseUrl)
      .then(() => dispatch(imageUploadSuccess(targetId)))
      .catch(err => {
        if (err.message === 'Failed to fetch') {
          return dispatch(
            reportError(
              targetId,
              'Unable to reach the network at this time. Please try again.',
            ),
          );
        }
        return dispatch(reportError(targetId, err.message));
      });
  } else {
    readImage(file)
      .then(dataurl => cropImages(dataurl, cropArray, fileType))
      .then(images => uploadImages(images, firebaseUrl, appName, imageType))
      .then(() => dispatch(imageUploadSuccess(targetId)))
      .then(
        () =>
          imageType === 'customLogo' && dispatch(fetchAppPreference(appName)),
      )
      .then(() => {
        if (firebaseUrl && firebaseUrl.includes(`blog/${appName}/tempImages`)) {
          dispatch(fetchBlogImage(firebaseUrl));
        }

        if (source === 'group') {
          dispatch(setUpdatedImageStatus(true));
        }
      })
      .catch(err => {
        if (err.message === 'Failed to fetch') {
          return dispatch(
            reportError(
              targetId,
              'Unable to reach the network at this time. Please try again.',
            ),
          );
        }

        return dispatch(reportError(targetId, err.message));
      });
  }
};

const uploadMedia = (media, firebaseUrl) =>
  new Promise((resolve, reject) => {
    const form = new FormData();
    const user = firebase.auth().currentUser;
    const myHeaders = new Headers();
    form.append('firebaseUrl', firebaseUrl);
    form.append('file', media);
    return user
      .getIdToken()
      .then(token => {
        myHeaders.append('x-access-token', token);
        return fetch(`${process.env.REACT_APP_IMAGES_API}/upload_file`, {
          method: 'post',
          headers: myHeaders,
          body: form,
          mode: 'cors',
        });
      })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          reject(res.error);
        } else {
          resolve();
        }
      })
      .catch(error => reject(error.message));
  });
