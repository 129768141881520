import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';
import { Trans, useTranslation } from 'react-i18next';

import TextField from '../../../../../../../../app/components/textField';
import { FlatButton } from '../../../../../../../../app/components/buttons';
import CircularValidation from '../../../../../../../../app/components/circularValidation';
import { CircularProgress } from '../../../../../../../../app/components/progress';

import './styles.css';

const NewGroup = ({
  open,
  pending,
  error,
  onSubmit,
  onRequestClose,
  name,
  onFieldChange,
}) => {
  const { t } = useTranslation();

  const displayError = error ? (
    <div>
      <strong>{error.message}</strong>
    </div>
  ) : null;

  const groupForm = (
    <div>
      <h5>
        <Trans>Create a group</Trans>
      </h5>
      <TextField
        id="name"
        placeholder={t('Name')}
        {...name}
        onChange={value => onFieldChange('name', value)}
        rightInputAction={
          <CircularValidation
            isValid={name.isValid}
            defaultMsg={t("Group's name required")}
            errorMsg={t('Name must be at least 2 characters long')}
            showDefault={!name.value}
          />
        }
      />
    </div>
  );

  const buttonSubmit = !pending ? (
    <FlatButton
      label={t('Create')}
      onClick={onSubmit}
      disabled={!name.isValid}
      primary
    />
  ) : (
    <CircularProgress size={18} />
  );

  return (
    <Dialog open={open} onRequestClose={onRequestClose} autoScrollBodyContent>
      <div className="add-group">
        <div className="modal-controls-container">
          <IconButton onClick={onRequestClose}>
            <Close />
          </IconButton>
        </div>
        <div className="list-fieldset">{displayError}</div>
        {groupForm}
        {buttonSubmit}
      </div>
    </Dialog>
  );
};

NewGroup.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  name: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

NewGroup.defaultProps = {
  error: null,
};

export default NewGroup;
