const initialState = {
  pending: false,
  success: false,
  oldEmail: {
    value: '',
  },
  newEmail: {
    value: '',
  },
  errorMessage: null,
};

const resetEmail = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_EMAIL_FIELD_CHANGE':
      return {
        ...state,
        [action.prop]: {
          value: action.value,
        },
      };

    case 'INVALIDATE_RESET_EMAIL_FIELD':
      return {
        ...state,
        [action.prop]: {
          ...state[action.prop],
          isValid: false,
        },
      };

    case 'RESET_EMAIL_ERROR':
      return {
        ...state,
        pending: false,
        isValid: false,
        errorMessage: action.err.message,
      };

    case 'RESET_EMAIL_SUCCESS':
      return {
        ...initialState,
        success: true,
      };

    case 'CLEAR_RESET_EMAIL_FORM':
      return initialState;

    case 'SUBMIT_RESET_EMAIL':
      return {
        ...state,
        errorMessage: null,
        pending: true,
      };

    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
};

export default resetEmail;
