import {
  RECEIVED_PRORATING,
  FAILED_FETCHING_PRORATING,
  FETCHING_PRORATING,
} from './actions';

const initialState = {
  prorating: {
    isFetching: null,
    adjustment: null,
    hasError: null,
    prorationDate: null,
  },
};

const subscribe = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SUBSCRIPTION_PURCHASE_PLAN':
      return {
        ...state,
        plan: action.plan,
        billingPeriod: action.billingPeriod,
        displayName: action.displayName,
        cost: action.cost,
        currency: action.currency,
      };
    case RECEIVED_PRORATING:
      return {
        ...state,
        prorating: {
          isFetching: false,
          adjustment: action.proratingAdjustment,
          prorationDate: action.prorationDate,
          hasError: false,
        },
      };

    case FAILED_FETCHING_PRORATING:
      return {
        ...state,
        prorating: {
          isFetching: false,
          adjustment: null,
          prorationDate: null,
          hasError: true,
        },
      };

    case FETCHING_PRORATING:
      return {
        ...state,
        prorating: {
          isFetching: true,
          adjustment: null,
          prorationDate: null,
          hasError: null,
        },
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default subscribe;
