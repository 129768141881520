import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import { withRouter } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { FlatButton } from '../../../../../../app/components/buttons';
import { accelerate } from '../../../../../../plans/images';
import '../../../../../memberLogin/modules/upgradeDialog/styles.css';
import { components, specialAddonPrices } from '../../../../../../plans';

class FeedbackUpgradeDialog extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    groups: {},
  };

  handleClick() {
    const component = 'Feedback';
    this.props.history.push({
      pathname: `/${this.props.match.params.appname}/pay-for-addon`,
      query: {
        component: components[component],
        price_details: specialAddonPrices[component],
      },
    });
  }

  publishActions = () => [
    <FlatButton label="Upgrade" onClick={() => this.handleClick()} primary />,
  ];

  render() {
    const { history, match, open, onRequestClose, t } = this.props;

    return (
      <Dialog
        open={open}
        onRequestClose={onRequestClose}
        autoScrollBodyContent
        style={{ textAlign: 'center' }}
        actionsContainerStyle={{ textAlign: 'center' }}
        contentClassName="customModal"
        paperClassName="customModalPaper"
        title={t('Upgrade to use component')}
        actions={this.publishActions()}
      >
        <div>
          <img
            width={24}
            src={accelerate}
            alt={t('Upgrade Plan')}
            style={{ position: 'relative', top: 1 }}
          />
          <p>
            <Trans>
              Looks like your 30-day free trial for this component has expired.
              Please upgrade to continue using it.
            </Trans>
          </p>
        </div>
      </Dialog>
    );
  }
}

export default withRouter(withTranslation()(FeedbackUpgradeDialog));
