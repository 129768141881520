import { combineReducers } from 'redux';
import _keys from 'lodash/keys';
import _pickBy from 'lodash/pickBy';

import {
  REQUEST_STAFF_MEMBER_BODIES,
  RECEIVE_STAFF_MEMBER_BODIES,
  FILTER_STAFF_MEMBER_BODY_DATA,
} from './actions';

const initialState = {
  pending: false,
  chunks: {},
  selected: [],
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  availableStaffMembers: [],
  filteredChunksAll: {},
};

const filterChunks = (chunks, filterHolder = '') => {
  let filteredChunks = chunks;
  const filter = filterHolder.replace(/[^a-zA-Z0-9 ]/g, '');

  if (filter !== '') {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`(${filter})`, 'gi');

      return rgx.test(chunk.name);
    });
  }
  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const memberBodies = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_STAFF_MEMBER_BODIES:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_STAFF_MEMBER_BODIES:
      return {
        ...state,
        chunks: action.value,
        selected: [],
        filteredChunks: action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };

    case FILTER_STAFF_MEMBER_BODY_DATA:
      return {
        ...state,
        ...filterChunks(state.chunks, action.payload),
        filter: action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  data: memberBodies,
});
