import React from 'react';
import './styles.scss';

const TabIcon = () => (
  <svg
    className="tab-icon icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 162.14 141.08"
  >
    <title>Tab Page</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M37,107.78H6.12A6.12,6.12,0,0,1,0,101.66V39.32A6.12,6.12,0,0,1,6.12,33.2H37a6.12,6.12,0,0,1,6.12,6.12V58.86H40.44V39.32A3.44,3.44,0,0,0,37,35.89H6.12A3.43,3.43,0,0,0,2.7,39.32v62.34a3.42,3.42,0,0,0,3.42,3.42H37a3.43,3.43,0,0,0,3.43-3.42V80.36h2.69v21.3A6.12,6.12,0,0,1,37,107.78Z"
        />
        <rect class="cls-1" x="1.35" y="93.95" width="34.82" height="2.7" />
        <rect class="cls-1" x="8.09" y="44.53" width="34.82" height="2.7" />
        <path
          class="cls-1"
          d="M157.19,141.08h-102a5,5,0,0,1-5-5V77.86L33.35,70.54l16.91-7.33V4.94a5,5,0,0,1,5-4.94h102a5,5,0,0,1,4.95,4.94V136.13A5,5,0,0,1,157.19,141.08ZM40.13,70.54,53,76.09v60a2.25,2.25,0,0,0,2.25,2.25h102a2.25,2.25,0,0,0,2.25-2.25V4.94a2.24,2.24,0,0,0-2.25-2.24h-102A2.24,2.24,0,0,0,53,4.94V65Z"
        />
        <path
          class="cls-1"
          d="M138.53,115.87H75.72V25.11h62.81Zm-60.11-2.7h57.41V27.81H78.42Z"
        />
        <path
          class="cls-1"
          d="M112.74,51.48l-6.23-5.24L101,50.85v-25h11.7Zm-6.23-8.77,3.53,3V28.5h-6.3V45.05Z"
        />
        <path
          class="cls-1"
          d="M96.56,51.48l-6.22-5.24-5.47,4.61v-25H96.56Zm-6.22-8.77,3.53,3V28.5H87.56V45.05Z"
        />
        <path
          class="cls-1"
          d="M128.91,51.48l-6.22-5.24-5.48,4.61v-25h11.7Zm-6.22-8.77,3.53,3V28.5h-6.31V45.05Z"
        />
      </g>
    </g>
  </svg>
);

export default TabIcon;
