const initialState = {
  showBeezerAppTour: false,
  lastStepVisited: 0,
  showBeezerSurveyForm1: false, // about where did you heared about beezer,
  showBeezerRatingForm1: false, // rate beezer experience between 1 - 10,
  showBeezerReferFriend: false, // show a popup displaying refer to a friend pop-up
  recentlyShowedAppcues: false,
  activeAccountTab: 0,
};

const appcues = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_ACC_TAB':
      return {
        ...state,
        activeAccountTab: action.value,
      };
    case 'SET_APPCUES_DISPLAY_DATA':
      return {
        ...state,
        ...action.data,
      };
    case 'RESET_APPCUES_DATA':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default appcues;
