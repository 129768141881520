import { newValidator } from '../../../../utils';

const nameValidator = newValidator({
  minLength: 2,
  required: true,
});

export const validateField = {
  name: value => nameValidator(value),
};
