import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import IconButton from 'material-ui/IconButton';
import { withTranslation } from 'react-i18next';

import SectionHeader from '../../../../../components/sectionHeader';
import CreateField from '../../../../../components/createField';
import DatePicker from '../../../../../components/datePicker';
import ColorPicker from '../../../../../components/colorPicker';
import DesignToggle from '../../../../../components/designToggle';
import { Create } from '../../../../../components/icons';
import { propSort } from '../../../../../../../utils';
import LayoutFrankfurter from './scheduleLayoutFrankfurter';
import LayoutIce from './scheduleLayoutIce';

import { Tabs, Tab } from '../../../../../components/tabs';
import TimeSlot from './TimeSlot';
import Filters from './Filters';
import Container from '../../../../../../../app/components/container/Container';
import LayoutTab from '../../tabs/layoutTab';
import LayoutOption from '../../tabs/layoutTab/layoutOption';

class Schedule extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
    appname: PropTypes.string.isRequired,
    onCategoryChange: PropTypes.func.isRequired,
    onAddCategory: PropTypes.func.isRequired,
    onRemoveCategory: PropTypes.func.isRequired,
    onAddSlot: PropTypes.func.isRequired,
    onRemoveSlot: PropTypes.func.isRequired,
    onAddItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
    onDesignChange: PropTypes.func.isRequired,
    rearrangeItems: PropTypes.func.isRequired,
  };

  static defaultProps = {
    itinerary: {},
    categories: {},
  };

  getContentTab = () => {
    const {
      data,
      onAddCategory,
      onRemoveCategory,
      onCategoryChange,
      t,
    } = this.props;

    const children =
      data && data.itinerary
        ? _map(data.itinerary, (slot, key) => {
            return (
              <TimeSlot
                id={key}
                key={key}
                chunks={slot.items && slot.items.chunks}
                timestamp={slot.timestamp}
                order={slot.items && slot.items.order}
                onRemoveSlot={this.handleRemoveSlot(key)}
                onRemoveItem={this.handleRemoveItem(key)}
                onAddItem={this.handleAddItem(key)}
                onDateChange={this.handleEditDate(key)}
                rearrangeItems={this.handleRearrangeItems(key)}
              />
            );
          }).sort(propSort('timestamp'))
        : null;

    const filterInterface =
      data && data.categories ? (
        <Filters
          filters={data.categories}
          onChange={(key, field, value) => onCategoryChange(key, field, value)}
          onAdd={() => onAddCategory(this.state.filterLabel)}
          onRemove={onRemoveCategory}
        />
      ) : null;

    return (
      <div style={{ paddingTop: 24, maxWidth: '100%' }}>
        <SectionHeader title={t('Categories')} />
        <CreateField
          id="filter-label"
          hintText={t('Create Category')}
          tooltip={t('Add Category')}
          onSubmit={onAddCategory}
        />
        {filterInterface}
        <SectionHeader
          title={t('Programs')}
          actions={
            <IconButton onClick={this.handleAddDate} tooltip={t('New Date')}>
              <Create />
            </IconButton>
          }
          borderTop
        />
        {children}
        <DatePicker
          ref={node => {
            this.datePicker = node;
          }}
          onChange={this.handleDateChange}
        />
      </div>
    );
  };

  handleAddItem = itinerary => () => {
    this.props.onAddItem(itinerary);
  };

  handleAddDate = () => this.datePicker.open();

  handleDateChange = ts => {
    this.props.onAddSlot(ts);
  };

  handleEditDate = key => ts => this.props.onDateChange(key, ts);

  handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    this.props.onDesignChange(prop, rgba);
  };

  handleRemoveSlot = itinerary => () => this.props.onRemoveSlot(itinerary);

  handleRemoveItem = itinerary => item =>
    this.props.onRemoveItem(itinerary, item);

  handleRearrangeItems = itinerary => (id, siblingId) =>
    this.props.rearrangeItems(id, siblingId, itinerary);

  scheduleLayoutsOptions = () => {
    const style = {
      width: '100%',
      heigth: '100%',
    };
    const { t } = this.props;
    return [
      <LayoutOption
        key="schedule_layout"
        wrapperKey="schedule_layout"
        grid={2}
        tooltip={t('Change your schedule component template')}
        title={t('Schedule templates')}
        active={this.props.component.layout}
        items={[
          { value: 'Frankfurter', icon: LayoutFrankfurter, style },
          { value: 'Ice', icon: LayoutIce, style },
        ]}
      />,
    ];
  };

  render() {
    const { design } = this.props.component;
    const { t } = this.props;
    return (
      <Tabs>
        <Tab label={t('Content')}>
          <Container>{this.getContentTab()}</Container>
        </Tab>
        <Tab label={t('design')}>
          <Container>
            <DesignToggle
              component={this.props.component}
              onDesignChange={this.props.onDesignChange}
            />
            <ColorPicker
              label={t('Date Color')}
              color={design.dateColor}
              height={22}
              disabled={!this.props.component.design.enabled}
              onChange={this.handleColorChange('dateColor')}
            />
            <ColorPicker
              label={t('Time Color')}
              color={design.timeColor}
              height={22}
              disabled={!this.props.component.design.enabled}
              onChange={this.handleColorChange('timeColor')}
            />
            <ColorPicker
              label={t('Title Color')}
              color={design.titleColor}
              height={22}
              disabled={!design.enabled}
              onChange={this.handleColorChange('titleColor')}
            />
          </Container>
        </Tab>
        <Tab label={t('layout')}>
          <LayoutTab
            componentKey={this.props.component.key}
            appname={this.props.appname}
            options={this.scheduleLayoutsOptions()}
          />
        </Tab>
      </Tabs>
    );
  }
}

export default withTranslation()(Schedule);
