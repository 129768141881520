import moment from 'moment';
import firebase from '../../../../firebase';

// listens to Shares, Views and Installs from last 5 days
export const listenForEvent = (appname, type) => dispatch => {
  const end = moment()
    .subtract(3, 'months')
    .valueOf();

  firebase
    .database()
    .ref(`analytics/${appname}/${type}`)
    .orderByChild('timestamp')
    .startAt(end)
    .on('child_added', snapshot => {
      const value = snapshot.val();

      dispatch({
        type: 'ANALYTICS_EVENT',
        eventType: type,
        key: snapshot.key,
        appname,
        value,
      });
    });
};

export const removeEventListener = (appname, type) => {
  firebase
    .database()
    .ref(`analytics/${appname}/${type}`)
    .off();

  return {
    type: 'OFF_EVENTS_LISTENER',
    eventType: type,
    appname,
  };
};

export const clearEvents = () => ({
  type: 'CLEAR_ANALYTICS_EVENTS',
});
