import queryString from 'query-string';
import i18n from 'i18next';

import firebase from '../../../../../../../firebase';
import {
  openDialog,
  dialogActionComplete,
  reportDialogError,
} from '../../../../../../../app/modules/dialog/actions';

export const SCHEDULE_CHANGE_CATEGORY = 'SCHEDULE_CHANGE_CATEGORY';
export const SCHEDULE_ADD_CATEGORY = 'SCHEDULE_ADD_CATEGORY';

export const dateChange = (appname, dataId, itinerary, ts) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child(`itinerary/${itinerary}/timestamp`).set(ts);

  return {
    type: 'SCHEDULE_DATE_CHANGE',
    appname,
    dataId,
    itinerary,
    ts,
  };
};

const getItemHref = (target, router, itinerary) => {
  const { match, location } = router;
  const { page, tab, component, parentPage } = queryString.parse(
    location.search,
  );

  const tabQuery = tab ? `tab=${tab}&` : '';
  const parentPageQuery =
    parentPage === 'NestedPage' ? `&parentPage=${parentPage}` : ``;

  return {
    pathname: `/${match.params.appname}/editor/build/item`,
    search: `page=${page}&${tabQuery}component=${component}&itinerary=${itinerary}&item=${target}${parentPageQuery}`,
  };
};

export const addItem = (appname, dataId, itinerary, router) => {
  const dataRef = firebase
    .database()
    .ref(
      `apps/${appname}/draft/lazy_data/${dataId}/itinerary/${itinerary}/items`,
    );

  const newItem = {
    title: '',
    subtitle: '',
    category: 'all',
    dialog: {
      enabled: false,
      avatar: '',
      name: '',
      position: '',
      text: '',
    },
  };

  const chunkRef = dataRef.child('chunks').push(newItem);

  dataRef.child('order').transaction(values => {
    if (!values) return [chunkRef.key];

    return [...values, chunkRef.key];
  });

  router.history.push(getItemHref(chunkRef.key, router, itinerary));

  return {
    type: 'SCHEDULE_ADD_ITEM',
    appname,
    dataId,
    itinerary,
  };
};

export const addSlot = (appname, dataId, timestamp, router) => dispatch => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  const newSlot = {
    items: {
      order: [],
    },
    timestamp,
  };

  const newRef = dataRef.child('itinerary').push(newSlot);

  dispatch({
    type: 'SCHEDULE_ADD_SLOT',
    appname,
    dataId,
    timestamp,
  });

  dispatch(addItem(appname, dataId, newRef.key, router));
};

export const removeSlot = (appname, dataId, itinerary) => dispatch => {
  const options = {
    buttonLabel: i18n.t('Delete'),
    title: i18n.t('Delete Schedule Slot'),
    text: i18n.t(
      "Are you sure you want to delete this time slot? Once it's deleted it's gone",
    ),
  };

  return dispatch(openDialog(options))
    .then(val => {
      if (val.action === 'submit') {
        const dataRef = firebase
          .database()
          .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

        dataRef.child(`itinerary/${itinerary}`).remove();
      }
    })
    .then(() => dispatch(dialogActionComplete()))
    .catch(err => dispatch(reportDialogError(err)));
};

export const changeCategory = (appname, dataId, categoryId, field, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child(`categories/${categoryId}/${field}`).set(value);

  return {
    type: SCHEDULE_CHANGE_CATEGORY,
    appname,
    dataId,
    categoryId,
    value,
  };
};

export const addCategory = (appname, dataId, label) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child('categories').push({
    color: 'rgb(240, 240, 240)',
    label,
  });

  return {
    type: SCHEDULE_ADD_CATEGORY,
    appname,
    dataId,
    label,
  };
};

export const removeCategory = (appname, dataId, categoryId) => dispatch => {
  const options = {
    buttonLabel: i18n.t('Delete'),
    title: i18n.t('Delete Category'),
    text: i18n.t(
      "Are you sure you want to delete this category? Once it's deleted it's gone",
    ),
  };

  return dispatch(openDialog(options))
    .then(val => {
      if (val.action === 'submit') {
        const dataRef = firebase
          .database()
          .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

        dataRef.child(`categories/${categoryId}`).remove();
      }
    })
    .then(() => dispatch(dialogActionComplete()))
    .catch(err => dispatch(reportDialogError(err)));
};

const findIndex = (array, element) => {
  if (element === null) {
    return array.length;
  }

  return array.indexOf(element);
};

const moveArray = (oldIndex, newIndex, arr) => {
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

const rearrangeScheduleItem = (
  id,
  siblingId,
  lazydata,
  itinerary,
  appname,
) => ({
  type: 'REARRANGE_SCHEDULE_ITEM',
  id,
  siblingId,
  lazydata,
  itinerary,
  appname,
});

export const rearrangeItems = (
  id,
  siblingId,
  appname,
  lazydata,
  itinerary,
) => dispatch => {
  dispatch(rearrangeScheduleItem(id, siblingId, lazydata, itinerary, appname));
  const itemsRef = firebase
    .database()
    .ref(
      `apps/${appname}/draft/lazy_data/${lazydata}/itinerary/${itinerary}/items/order`,
    );

  itemsRef.transaction(array => {
    if (array === null) return null;

    const oIndex = findIndex(array, id);
    let nIndex = findIndex(array, siblingId);

    if (nIndex > oIndex) {
      nIndex -= 1;
    }

    return moveArray(oIndex, nIndex, array);
  });
};

export const designChange = (appname, key, type, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${key}/design`);

  dataRef.child(type).set(value);

  return {
    type: 'ITEM_DESIGN_CHANGE',
    appname,
    key,
    designType: type,
    value,
  };
};
