import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import { Tab } from 'material-ui/Tabs';
import Link from '@material-ui/core/Link';
import { Trans, useTranslation } from 'react-i18next';
import Tabs from '../../components/tabs';

import './styles.css';
import RedeemBonus from './modules/redeemBonus';
import ReferralsList from './modules/referralsList';
import RedeemHistory from './modules/redeemHistory';
import DialogInfo from './components/DialogInfo';

const styles = {
  tab: {
    fontSize: '11px',
    height: 40,
    width: 120,
  },
};

const wrapperStyles = {
  display: 'grid',
  rowGap: '10px',
};

const Referral = ({ link, fetchReferralLink, onCopy }) => {
  const [infoDialog, setInfoDialog] = useState(false);
  const { t } = useTranslation();
  const toggleHandler = () => {
    setInfoDialog(!infoDialog);
  };

  useEffect(() => {
    fetchReferralLink();
  }, []);

  return (
    <div>
      <div className="referral">
        <div className="referral-section referral-container">
          <h3 className="header">
            <Trans>Refer a Friend</Trans>
          </h3>
          <div className="refer-friend">
            <div className="help-text">
              <Trans>
                Use your unique referral link to invite your friends to sign up
                for Beezer.
              </Trans>
              <b>
                <Trans>
                  For every one of your referrals that signs up to a paid Beezer
                  subscription, you'll earn cash back in
                </Trans>
              </b>
              <Trans>
                the form of redeemable bonus points at the value of{' '}
                <span>
                  <span style={{ textDecoration: 'line-through' }}>50%</span>{' '}
                  <span style={{ color: '#1976d2', fontWeight: 500 }}>
                    100% (HALLOWEEN SPECIAL)
                  </span>{' '}
                  of your referral's first subscription payment.
                </span>
              </Trans>
              <span className="more">
                <Link href="#" onClick={e => toggleHandler()}>
                  <Trans>Find out more</Trans>.
                </Link>
              </span>
            </div>
            <div className="details flex-row subscription">
              <div>
                <span
                  className="share-button"
                  onClick={() => {
                    copy(link);
                    onCopy(true);
                  }}
                >
                  <Trans>Copy to share</Trans>
                </span>
              </div>
            </div>
          </div>
        </div>
        <RedeemBonus />
      </div>
      <div className="referral">
        <div className="account-tabs referral-tabs">
          <Tabs>
            <Tab style={styles.tab} label={t('Referrals')}>
              <ReferralsList />
            </Tab>
            <Tab style={styles.tab} label={t('Redeem History')}>
              <RedeemHistory />
            </Tab>
          </Tabs>
        </div>
      </div>
      <DialogInfo
        open={infoDialog}
        onClose={toggleHandler}
        title={t('Refer a Friend')}
      >
        <div style={wrapperStyles}>
          <div>
            <Trans>
              Invite your friends to sign up for Beezer using your unique
              referral link! For every friend who subscribes to a paid Beezer
              plan through your referral, you’ll receive cashback in the form of
              redeemable bonus points equal to
            </Trans>{' '}
            <span style={{ textDecoration: 'line-through' }}>50%</span>{' '}
            <span style={{ color: '#1976d2', fontWeight: 500 }}>
              100% (HALLOWEEN SPECIAL)
            </span>{' '}
            of their first subscription payment.
          </div>
          <div>
            <Trans>
              For instance, if your friend subscribes to the Everything Monthly
              plan (£65), you’ll earn £65 in your account as soon as they make
              their payment. If they go for the Everything Yearly plan, you’ll
              receive a whopping £702!
            </Trans>
          </div>

          <div>
            <Trans>
              If they take advantage of the Halloween Special 20% OFF coupon and
              sign up for the Everything Monthly plan, they’ll pay £52, and
              you’ll receive £52 in return.
            </Trans>
          </div>

          <div>
            <Trans>
              You can redeem your points at any time and transfer the money to
              your saved payment method.
            </Trans>
          </div>

          <div>
            <Trans>
              For more information, feel free to reach out to us at{' '}
              <a
                style={{
                  color: '#1976d2',
                  fontWeight: 500,
                  textDecoration: 'underline',
                }}
                href="mailto:support@beezer.com"
              >
                support@beezer.com
              </a>
              .
            </Trans>
          </div>
        </div>
      </DialogInfo>
    </div>
  );
};
export default Referral;
