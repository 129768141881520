import firebase from '../../../../../firebase';
import { RECEIVED_PRIVACY_SETTINGS, UPDATED_PRIVACY_SETTINGS } from './types';

export const receivedPrivacySettings = settings => ({
  type: RECEIVED_PRIVACY_SETTINGS,
  payload: settings,
});

export const updatedPrivacySettings = () => ({
  type: UPDATED_PRIVACY_SETTINGS,
});

export const updatePrivacySettings = (appname, field, value) => dispatch => {
  let newValue = null;
  if (field === 'isCookieConsentEnabled') {
    newValue = {
      [field]: value,
    };
  }
  firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/privacy`)
    .update(newValue)
    .then(() => dispatch(updatedPrivacySettings()));
};

export const privacySettingsListener = (
  prevAppname,
  currentAppname,
) => dispatch => {
  firebase
    .database()
    .ref(`apps/${prevAppname}/draft/always_data/privacy`)
    .off('value');
  firebase
    .database()
    .ref(`apps/${currentAppname}/draft/always_data/privacy`)
    .on('value', snapshot => {
      if (snapshot.exists()) dispatch(receivedPrivacySettings(snapshot.val()));
    });
};

export const getPrivacySettings = appname => dispatch => {
  firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/privacy`)
    .on('value', snapshot => {
      if (snapshot.exists()) {
        dispatch(receivedPrivacySettings(snapshot.val()));
      } else {
        dispatch(receivedPrivacySettings({ isCookieConsentEnabled: false }));
      }
    });
};
