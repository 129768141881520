import React from 'react';

import './styles.css';

const SocialMedia = () => (
  <svg
    className="icon social-media"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 61.22 41.5"
  >
    <title>Social Media</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M22.5,38.1A1.9,1.9,0,0,1,20.6,40H3.4a1.9,1.9,0,0,1-1.9-1.9V35.3H20.12V33.8H1.5V3.4A1.9,1.9,0,0,1,3.4,1.5H20.6a1.9,1.9,0,0,1,1.9,1.9V6.3H4.5V7.8h18v6.48H24V3.4A3.41,3.41,0,0,0,20.6,0H3.4A3.41,3.41,0,0,0,0,3.4V38.1a3.41,3.41,0,0,0,3.4,3.4H20.6A3.41,3.41,0,0,0,24,38.1V26.25H22.5Z"
        />
        <path
          class="cls-1"
          d="M58.47,7.53H30.72A2.75,2.75,0,0,0,28,10.28V16.7l-9.41,4.08L28,24.85v6.43A2.75,2.75,0,0,0,30.72,34H58.47a2.75,2.75,0,0,0,2.75-2.75v-21A2.75,2.75,0,0,0,58.47,7.53Zm1.25,23.75a1.25,1.25,0,0,1-1.25,1.25H30.72a1.25,1.25,0,0,1-1.25-1.25V23.87l-7.14-3.09,7.14-3.09V10.28A1.25,1.25,0,0,1,30.72,9H58.47a1.25,1.25,0,0,1,1.25,1.25Z"
        />
        <path
          class="cls-1"
          d="M49.14,14.51c-2.21,0-4,2.2-4,4.92,0-2.72-1.79-4.92-4-4.92s-4,2.2-4,4.92a4.48,4.48,0,0,0,.61,2.38,8.48,8.48,0,0,0,2.39,2.6l5,3.64,5-3.64a8.48,8.48,0,0,0,2.39-2.6,4.48,4.48,0,0,0,.61-2.38C53.14,16.71,51.35,14.51,49.14,14.51Z"
        />
      </g>
    </g>
  </svg>
);

export default SocialMedia;
