import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from 'material-ui/Dialog';
import { FlatButton } from '../../../../app/components/buttons';

const DialogConfirmation = ({ open, onClose, message, action }) => {
  const { t } = useTranslation();

  const actions = (actionFunc, onCloseFunc) => [
    <FlatButton label={t('Cancel')} secondaryWhite onClick={() => onClose()} />,
    <FlatButton
      label={t('Confirm')}
      primary
      onClick={() => {
        actionFunc();
        onCloseFunc();
      }}
    />,
  ];
  return (
    <Dialog
      actions={
        action !== null
          ? actions(action, onClose)
          : [actions(action, onClose)[0]]
      }
      open={open}
      onRequestClose={() => onClose()}
      style={{ textAlign: 'center' }}
      actionsContainerStyle={{ textAlign: 'center' }}
    >
      {message}
    </Dialog>
  );
};

DialogConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  action: PropTypes.func,
};

DialogConfirmation.defaultProps = {
  action: null,
};

export default DialogConfirmation;
