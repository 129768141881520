// always_data contains page and component metadata
const alwaysData = (state = {}, action) => {
  switch (action.type) {
    case 'HYDRATE_ALWAYS_DATA':
      return {
        ...state,
        ...action.data,
      };

    case 'RECEIVE_ALWAYS_DATA':
      return {
        ...state,
        [action.key]: {
          ...action.data,
          key: action.key,
        },
      };

    default:
      return state;
  }
};

export default alwaysData;
