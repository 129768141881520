import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

// import { FlatButton } from '../../../../app/components/buttons';
// import DeleteUserByEmailModal from './deleteUserByEmailModal';

import './styles.css';
import ResetEmail from '../resetEmail';

class Account extends Component {
  static propTypes = {
    openDeleteUserDialog: PropTypes.func,
  };

  static defaultProps = {
    openDeleteUserDialog: null,
  };

  deleteUser = () => {
    this.props.openDeleteUserDialog();
  };

  render() {
    return (
      <div className="account">
        <div className="account-section delete-user-settings">
          <h3 className="header">
            <Trans>Delete Account</Trans>
          </h3>
          <p>
            <Trans>Feature coming soon!</Trans>
          </p>
          {/* <FlatButton label="Delete Account" onClick={this.deleteUser} />
          <p className="delete-text">
            Your account wont be deleted when you press the above button. You
            will be asked to confirm your email before your account is deleted.
          </p>
          <DeleteUserByEmailModal /> */}
        </div>
        <div className="account-section change-email-settings">
          <h3 className="header">
            <Trans>Change Email</Trans>
          </h3>
          <ResetEmail />
        </div>
      </div>
    );
  }
}

export default Account;
