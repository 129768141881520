import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';

import TextField from '../../../../app/components/textField';
import { FlatButton } from '../../../../app/components/buttons';

import './styles.css';

class ResetPassword extends Component {
  static propTypes = {
    oldPassword: PropTypes.object.isRequired,
    newPassword: PropTypes.object.isRequired,
    confirmPassword: PropTypes.object.isRequired,
    pending: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    success: PropTypes.bool.isRequired,
    onOldPasswordChange: PropTypes.func.isRequired,
    onNewPasswordChange: PropTypes.func.isRequired,
    onConfirmPasswordChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    clearForm: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    errorMessage: null,
  };

  componentWillUnmount() {
    this.props.clearForm();
  }

  render() {
    const {
      oldPassword,
      newPassword,
      confirmPassword,
      pending,
      success,
      errorMessage,
      onOldPasswordChange,
      onNewPasswordChange,
      onConfirmPasswordChange,
      onSubmit,
      t,
    } = this.props;

    return (
      <div className="reset-password">
        <div>
          <div className="reset-password-fieldset">
            <TextField
              id="old-password"
              type="password"
              placeholder={t('Old Password')}
              {...oldPassword}
              onChange={onOldPasswordChange}
              required
            />
            <TextField
              id="new-password"
              type="password"
              placeholder={t('New Password')}
              {...newPassword}
              onChange={onNewPasswordChange}
              required
            />
            <TextField
              id="confirm-password"
              type="confirm_password"
              placeholder={t('Confirm New Password')}
              {...confirmPassword}
              onChange={onConfirmPasswordChange}
              required
            />
          </div>
          {errorMessage && <p>{errorMessage}</p>}
          <FlatButton
            label={t('Change Password')}
            onClick={onSubmit}
            disabled={pending}
            primary
          />
          {success && (
            <div className="success-message">
              <p>
                <Trans>Your password has been reset.</Trans>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ResetPassword);
