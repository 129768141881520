import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Roster from './Roster';
import { addRosterItem, rearrangeItems, designChange } from './actions';
import { launchRemoveDialog } from '../../remove/actions';

const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddItem: title =>
    dispatch(
      addRosterItem(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        title,
      ),
    ),
  onRemoveItem: item =>
    dispatch(
      launchRemoveDialog(
        ownProps.match.params.appname,
        {
          ...queryString.parse(ownProps.location.search),
          item,
        },
        'ITEM',
      ),
    ),
  rearrangeItems: (id, siblingId) =>
    dispatch(
      rearrangeItems(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        id,
        siblingId,
      ),
    ),
  onDesignChange: (field, value) => {
    return dispatch(
      designChange(
        ownProps.match.params.appname,
        queryString.parse(ownProps.location.search).component,
        field,
        value,
      ),
    );
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Roster));
