const initialState = {
  openNotice: null,
};

const notice = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_NOTICE':
      return {
        ...state,
        openNotice: action.noticeId,
      };

    case 'CLOSE_NOTICE':
      return initialState;

    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default notice;
