import React, { Component } from 'react';
import ReactImageCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

class CropDialog extends Component {
  state = {
    crop: {},
    cropAreaSizes: {},
  };

  setCrop = (nextProps, image) => {
    if (!image) {
      return null;
    }
    const { file, onReceiveCrop } = nextProps;
    const { cropDetails } = file;
    const { width, height, x, y } = cropDetails;
    const cropWidth = width || 100;
    const cropHeight = height || 100;
    return this.setState(
      {
        crop: {
          width: cropWidth,
          height: cropHeight,
          x: x,
          y: y,
        },
        image,
        cropAreaSizes: this.calculateCropAreaSize(cropWidth, cropHeight, image),
      },
      () => onReceiveCrop(this.state.crop),
    );
  };

  calculateCropAreaSize = (cropWidth, cropHeight, image = this.state.image) => {
    if (!image) {
      return {};
    }
    return {
      width: Math.round(image.naturalWidth * (cropWidth / 100)),
      heigth: Math.round(image.naturalHeight * (cropHeight / 100)),
    };
  };

  calculateWidthPercentageFromAspect = (aspect, image) =>
    (aspect * image.height * 100) / image.width;

  handleImageLoad = image => {
    this.setCrop(this.props, image, true);
  };

  hasAreaSizeMessage = () => {
    const { cropAreaSizes } = this.state;
    return (
      cropAreaSizes && (
        <p>
          {`Current area selected: ${cropAreaSizes.width}px by ${cropAreaSizes.heigth}px`}
        </p>
      )
    );
  };

  handleChange = crop => {
    const cropAreaSizes = this.calculateCropAreaSize(crop.width, crop.height);
    return this.setState({ crop, cropAreaSizes });
  };

  componentDidUpdate(prevProps) {
    if (this.props.currentCropIndex !== prevProps.currentCropIndex) {
      this.setCrop(this.props, this.state.image);
    }
  }

  render() {
    const { file, onReceiveCrop } = this.props;

    const cropSrc =
      file && file.file && file.file.preview ? file.file.preview : '';
    const isGif = file && file.file && file.file.type === 'image/gif';

    const reactCrop =
      // eslint-disable-next-line no-nested-ternary
      cropSrc && !isGif ? (
        <div>
          <ReactImageCrop
            src={cropSrc}
            crop={this.state.crop}
            minWidth={this.state.minWidth}
            onImageLoaded={this.handleImageLoad}
            onChange={this.handleChange}
            onComplete={onReceiveCrop}
            crossorigin="true"
            keepSelection
          />
          {this.hasAreaSizeMessage()}
        </div>
      ) : isGif ? (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img src={cropSrc} className="crop-gif-image" />
      ) : (
        <span />
      );
    return reactCrop;
  }
}

export default CropDialog;
