import { validateField } from '../../validators';
import firebase from '../../../../../../firebase';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import { CPD_CREATED, MEMBERBODY_REQUIRED } from '../../config';

export const NEW_FIELD_CHANGE_CPD = 'NEW_FIELD_CHANGE_CPD';
export const OPEN_NEW_DIALOG_CPD = 'OPEN_NEW_DIALOG_CPD';
export const CLOSE_NEW_DIALOG_CPD = 'CLOSE_NEW_DIALOG_CPD';
export const ADD_NEW_CPD = 'ADD_NEW_CPD';
export const ADDED_CPD = 'ADDED_CPD';

const newFieldChange = (prop, id, value, isValid) => ({
  type: NEW_FIELD_CHANGE_CPD,
  prop,
  id,
  value,
  isValid,
});

export const openNewCPDDialog = () => (dispatch, getState) => {
  const { memberbody } = getState().staff.cpds.list;
  if (memberbody) {
    dispatch({
      type: OPEN_NEW_DIALOG_CPD,
    });
  } else {
    dispatch(openSnackbar(MEMBERBODY_REQUIRED));
  }
};

export const closeNewCPDDialog = () => ({
  type: CLOSE_NEW_DIALOG_CPD,
});

export const formFieldChange = (isRequired, prop, id, value, index) => (
  dispatch,
  getState,
) => {
  let data = value;
  const valid = data ? validateField[prop](data) : null;
  const isValid = isRequired ? valid : true;
  // merge dates to array [fromDate, toDate]
  if (prop === 'DateRange') {
    const dateRange = getState().staff.cpds.create.fieldsData[id].value;
    if (index === 0) {
      data = dateRange[1] ? [value, dateRange[1]] : [value, value];
    } else {
      data = dateRange[0] ? [dateRange[0], value] : [value, value];
    }
  }
  return dispatch(newFieldChange(prop, id, data, isValid));
};

export const addCPD = appname => (dispatch, getState) => {
  const { fieldsData } = getState().staff.cpds.create;
  const { memberbody } = getState().staff.cpds.list;

  fieldsData.createdAt = Date.now();
  fieldsData.memberbody = memberbody;
  dispatch({ type: ADD_NEW_CPD });
  return firebase
    .database()
    .ref(`app_cpds/${appname}/cpds/${memberbody}`)
    .push(fieldsData)
    .then(ref => {
      dispatch({
        type: ADDED_CPD,
        key: ref.key,
        data: fieldsData,
      });
      dispatch(openSnackbar(CPD_CREATED));
    });
};
