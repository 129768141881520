const initialState = null;

const googleAnalytics = (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVE_GA_TRACKING_ID':
      return {
        ...state,
        id: action.data,
      };
      case 'LOGOUT_SUCCESS':
        return {
          ...initialState,
        };
  
    default:
      return state;
  }
};

export default googleAnalytics;
