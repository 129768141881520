import React from 'react';

import './styles.css';

const ShareViaSms = () => (
  <svg
    className="icon share-via-sms"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 61.22 41.5"
  >
    <title>Share Via SMS</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M22.5,38.1A1.9,1.9,0,0,1,20.6,40H3.4a1.9,1.9,0,0,1-1.9-1.9V35.3H20.12V33.8H1.5V3.4A1.9,1.9,0,0,1,3.4,1.5H20.6a1.9,1.9,0,0,1,1.9,1.9V6.3H4.5V7.8h18v6.48H24V3.4A3.41,3.41,0,0,0,20.6,0H3.4A3.41,3.41,0,0,0,0,3.4V38.1a3.41,3.41,0,0,0,3.4,3.4H20.6A3.41,3.41,0,0,0,24,38.1V26.25H22.5Z"
        />
        <path
          class="cls-1"
          d="M37.38,20l-1.84-.72a2.39,2.39,0,0,1-.74-.39.76.76,0,0,1-.24-.59.78.78,0,0,1,.37-.65,1.78,1.78,0,0,1,1-.26c.9,0,1.42.36,1.56,1.1H39a2.64,2.64,0,0,0-.89-1.84A3.27,3.27,0,0,0,36,16a3.65,3.65,0,0,0-2.26.63,2,2,0,0,0-.82,1.67,1.86,1.86,0,0,0,.52,1.4,4.4,4.4,0,0,0,1.5.88l1.67.67a2.37,2.37,0,0,1,.81.45.92.92,0,0,1,.24.68.81.81,0,0,1-.45.72,1.89,1.89,0,0,1-1,.27,3.38,3.38,0,0,1-.86-.09,1.27,1.27,0,0,1-1.07-1.32H32.72a2.68,2.68,0,0,0,1,2.13,3.56,3.56,0,0,0,2.28.69,3.87,3.87,0,0,0,2.44-.67,2.17,2.17,0,0,0,.86-1.81,2,2,0,0,0-.51-1.38A3.77,3.77,0,0,0,37.38,20Z"
        />
        <polygon
          class="cls-1"
          points="44.85 21.13 42.54 16.11 40.34 16.11 40.34 24.55 41.88 24.55 41.88 17.96 44.35 23.63 45.35 23.63 47.74 18.14 47.74 24.55 49.29 24.55 49.29 16.11 47.17 16.11 44.85 21.13"
        />
        <path
          class="cls-1"
          d="M55,20l-1.83-.72a2.34,2.34,0,0,1-.75-.39.76.76,0,0,1-.24-.59.81.81,0,0,1,.37-.65,1.84,1.84,0,0,1,1-.26c.9,0,1.42.36,1.56,1.1h1.52a2.68,2.68,0,0,0-.89-1.84,3.3,3.3,0,0,0-2.2-.65,3.62,3.62,0,0,0-2.25.63,2,2,0,0,0-.82,1.67,1.86,1.86,0,0,0,.52,1.4,4.25,4.25,0,0,0,1.49.88l1.68.67a2.31,2.31,0,0,1,.8.45.89.89,0,0,1,.25.68.81.81,0,0,1-.45.72,1.94,1.94,0,0,1-1,.27,3.33,3.33,0,0,1-.86-.09,1.27,1.27,0,0,1-1.08-1.32H50.3a2.65,2.65,0,0,0,1,2.13,3.55,3.55,0,0,0,2.27.69,3.9,3.9,0,0,0,2.45-.67,2.17,2.17,0,0,0,.85-1.81,2,2,0,0,0-.5-1.38A3.74,3.74,0,0,0,55,20Z"
        />
        <path
          class="cls-1"
          d="M58.47,10.53H30.72A2.75,2.75,0,0,0,28,13.28V16.7l-9.41,4.08L28,24.85v3.43A2.75,2.75,0,0,0,30.72,31H58.47a2.75,2.75,0,0,0,2.75-2.75v-15A2.75,2.75,0,0,0,58.47,10.53Zm1.25,17.75a1.25,1.25,0,0,1-1.25,1.25H30.72a1.25,1.25,0,0,1-1.25-1.25V23.87l-7.14-3.09,7.14-3.09V13.28A1.25,1.25,0,0,1,30.72,12H58.47a1.25,1.25,0,0,1,1.25,1.25Z"
        />
      </g>
    </g>
  </svg>
);

export default ShareViaSms;
