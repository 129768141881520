/* eslint no-underscore-dangle: 0 */
const parseShopifyCategories = receivedCategories =>
  receivedCategories.map(category => {
    return {
      id: category.id,
      name: category.title,
      slug: category.handle,
      type: category.type.name,
      updatedAt: category.updatedAt,
    };
  });

export default parseShopifyCategories;
