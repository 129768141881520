const addMonths = (date, months) => {
  return date.setMonth(date.getMonth() + months);
};

const addYears = (date, years) => {
  return date.setFullYear(date.getFullYear() + years);
};

export const getNextRenewalDate = (type, interval, start_date) => {
  let nextDate = null;
  switch (type) {
    case 'month': {
      nextDate = new Date(addMonths(new Date(start_date), interval)).getTime();
      while (nextDate < Date.now()) {
        nextDate = addMonths(new Date(nextDate), interval);
      }
      break;
    }
    case 'year': {
      nextDate = new Date(addYears(new Date(start_date), interval)).getTime();
      while (nextDate < Date.now()) {
        nextDate = addYears(new Date(nextDate), interval);
      }
      break;
    }

    default:
      break;
  }
  return nextDate;
};
