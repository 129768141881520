import { connect } from 'react-redux';

import Appointments from './Appointments';
import {
  addAppointmentDialogOpen,
  appointmentItemChange,
  deleteAppointmentSlot,
  resetAppointmentData,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.ecommerce.booking.appointments,
  appname: ownProps.match.params.appname,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});

const mapDisptachToProps = (dispatch, ownProps) => ({
  onAddAppointmentDialogOpen: isOpen =>
    dispatch(addAppointmentDialogOpen(isOpen)),
  onAppointmentItemChange: item => dispatch(appointmentItemChange(item)),
  deleteAppointmentSlot: (slotIndex, appointmentKey) =>
    dispatch(
      deleteAppointmentSlot(
        ownProps.match.params.appname,
        slotIndex,
        appointmentKey,
      ),
    ),
  onResetAppointmentData: () => dispatch(resetAppointmentData()),
});

export default connect(mapStateToProps, mapDisptachToProps)(Appointments);
