import { fetchNotificationData } from '../actions';

export const OPEN_HISTORY_DETAIL = 'OPEN_HISTORY_DETAIL';
export const CLOSE_HISTORY_DETAIL_DIALOG = 'CLOSE_HISTORY_DETAIL_DIALOG';
export const PREVIOUS_HISTORY_RESPONSE = 'PREVIOUS_HISTORY_RESPONSE';
export const NEXT_HISTORY_RESPONSE = 'NEXT_HISTORY_RESPONSE';

export const openHistoryDetailDialog = key => (dispatch, getState) => {
  const history = getState().engage.history.data.chunks[key];

  return dispatch({
    type: OPEN_HISTORY_DETAIL,
    key,
    history,
  });
};

export const closeHistoryDetailDialog = () => ({
  type: CLOSE_HISTORY_DETAIL_DIALOG,
});

export const previousResponse = (appname, index, historys) => dispatch => {
  const messageId =
    historys.data.filteredChunks[historys.data.items[index - 2]].messageId;
  dispatch(fetchNotificationData(appname, messageId));
  return dispatch({
    type: PREVIOUS_HISTORY_RESPONSE,
    index,
    historys,
  });
};

export const nextResponse = (appname, index, historys) => dispatch => {
  const messageId =
    historys.data.filteredChunks[historys.data.items[index]].messageId;
  dispatch(fetchNotificationData(appname, messageId));
  return dispatch({
    type: NEXT_HISTORY_RESPONSE,
    index,
    historys,
  });
};
