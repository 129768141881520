import { combineReducers } from 'redux';
import _pickBy from 'lodash/pickBy';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';

import {
  RECEIVE_RATING_DATA_UPDATE,
  REQUEST_ALL_RATINGS,
  RECEIVE_ALL_RATINGS,
  REPORT_RATING_DATA_REQUEST_ERROR,
  FILTER_RATING_DATA,
  SELECT_RATING,
  SELECT_ALL_RATINGS,
  DESELECT_ALL_RATINGS,
  FAVOURITE_SELECTED,
  DELETE_SELECTED_RATINGS,
  SORT_RATINGS,
} from './actions';
import ratingDetails from './ratingDetails/reducer';

const initialState = {
  pending: false,
  chunks: {},
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  selected: [],
  selectAll: false,
  ratings: [],
  filteredRatings: [],
  order: 'desc',
  colSorted: 'date',
};

const filterChunks = (chunks, ratings, filter = '') => {
  let filteredRatings = ratings;
  let filteredChunks = chunks;
  let items = Object.keys(filteredChunks);

  if (filter !== '') {
    const rgx = new RegExp(`(${filter})`, 'g');
    filteredRatings = _pickBy(ratings, chunk => rgx.test(chunk.title));
    items = Object.keys(filteredRatings);
    filteredChunks = Object.keys(chunks)
      .filter(chunk => items.indexOf(chunk) >= 0)
      .reduce(
        (obj, key) => ({
          ...obj,
          [key]: chunks[key],
        }),
        {},
      );
  }

  return { filteredChunks, filteredRatings, items };
};

const sortItems = (filteredChunks, sortBy, order) =>
  _keys(filteredChunks)
    .filter(key => filteredChunks[key].feedback)
    .sort((a, b) => {
      let keyA = filteredChunks[a][sortBy];
      let keyB = filteredChunks[b][sortBy];
      if (sortBy === 'date') {
        keyA = new Date(filteredChunks[a][sortBy]);
        keyB = new Date(filteredChunks[b][sortBy]);
      }
      if (order === 'desc') {
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
      } else if (order === 'asc') {
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
      }
      return 0;
    });

const rating = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_RATING_DATA_UPDATE: {
      return {
        ...state,
        chunks: action.data,
        ...filterChunks(action.data, state.filteredRatings, state.filter),
        items: sortItems(action.data, state.colSorted, state.order),
        pending: false,
        error: null,
      };
    }

    case REQUEST_ALL_RATINGS: {
      return {
        ...state,
        pending: true,
        error: null,
      };
    }

    case RECEIVE_ALL_RATINGS: {
      return {
        ...state,
        ratings: action.data,
        ...filterChunks(state.filteredChunks, action.data, state.filter),
        items: sortItems(state.filteredChunks, state.colSorted, state.order),
        pending: false,
        error: null,
      };
    }

    case REPORT_RATING_DATA_REQUEST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.err,
        selected: [],
      };

    case FILTER_RATING_DATA: {
      const { filteredChunks, filteredRatings } = filterChunks(
        state.chunks,
        state.ratings,
        action.payload,
      );
      return {
        ...state,
        filter: action.payload,
        filteredChunks,
        filteredRatings,
        items: sortItems(filteredChunks, state.colSorted, state.order),
      };
    }

    case SELECT_RATING: {
      const prevState = state.filteredChunks[action.key].selected;

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case SELECT_ALL_RATINGS: {
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: true,
      }));
      const selected = _union(state.selected, _keys(filteredChunks));

      return {
        ...state,
        filteredChunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_RATINGS:
      return {
        ...state,
        filteredChunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    case FAVOURITE_SELECTED:
      return {
        ...state,
      };

    case DELETE_SELECTED_RATINGS:
      return {
        ...state,
      };

    case SORT_RATINGS:
      return {
        ...state,
        order: state.order === 'desc' ? 'asc' : 'desc',
        items: sortItems(
          state.filteredChunks,
          action.column.id,
          state.order === 'desc' ? 'asc' : 'desc',
        ),
        colSorted: action.column.id,
      };

    default:
      return state;
  }
};

export default combineReducers({
  data: rating,
  ratingDetails,
});
