import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCellMui from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TreeView, TreeItem } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles, withStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from 'material-ui/svg-icons/action/info';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import moment from 'moment';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import { Trans, useTranslation } from 'react-i18next';

import { objectToArray, arrayToObject } from '../../../../../../../utils';
import Modal from '../../../components/Modal';
import Filters from '../../../components/Filters';

const ExpansionPanelDetails = withStyles({
  root: {
    flexDirection: 'column',
  },
})(AccordionDetails);

const TableCell = withStyles({
  head: {
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
})(TableCellMui);

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  panel: {
    width: '100%',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  labelIcon: {
    marginRight: '1px',
  },
  labelText: {
    fontWeight: 'bold',
    flexGrow: 1,
    color: 'rgba(0, 0, 0, 0.54) !important',
  },
  center: {
    textAlign: 'center',
    width: '100%',
  },
});

const NoOfSystems = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { workforceGroups, workforceTypesData, users } = props;
  const [modalData, setModalData] = React.useState({});
  const [isOpen, setOpen] = React.useState(false);
  const [typesData, setTypesData] = React.useState(false);
  const userIdList = _.keys(users);
  React.useEffect(() => {
    setTypesData(workforceTypesData);
  }, [workforceTypesData]);

  const onFilter = dates => {
    const clonedTypesData = _.cloneDeep(workforceTypesData);
    objectToArray(clonedTypesData).forEach(group => {
      const workforces = group.value;
      const groupID = group.id;
      const filteredList = objectToArray(workforces).filter(user => {
        const dateToFilter = new Date(
          moment(user.value.createdAt).format('YYYY/MM/DD'),
        ).getTime();
        if (dates.from && dates.to) {
          return (
            dateToFilter >= dates.from.getTime() &&
            dateToFilter <= dates.to.getTime()
          );
        } else if (dates.from) {
          return dateToFilter >= dates.from.getTime();
        } else if (dates.to) {
          return dateToFilter <= dates.to.getTime();
        }
        return true;
      });
      // group.value = arrayToObject(filteredList);
      clonedTypesData[groupID] = arrayToObject(filteredList);
    });
    setTypesData(clonedTypesData);
  };

  React.useImperativeHandle(ref, () => ({
    prepareReportData() {
      const reportData = { data: [] };
      reportData.columns = [
        t('No of Users in Group'),
        t('Group'),
        t('Field'),
        t('No of users in Field'),
      ];
      objectToArray(workforceGroups).forEach(data => {
        const dropdowns = objectToArray(data.value.fields).filter(
          i => i.value.type === 'Dropdown',
        );
        if (dropdowns.length) {
          dropdowns.forEach(
            field =>
              field.value.options &&
              field.value.options.split(',').forEach(item => {
                reportData.data.push([
                  typesData[data.id]
                    ? Object.keys(typesData[data.id]).length
                    : 0,
                  data.value.name,
                  `${field.value.name} - ${item}`,
                  objectToArray(typesData[data.id]).filter(
                    i =>
                      i.value &&
                      i.value[field.id] &&
                      i.value[field.id].value === item,
                  ).length,
                ]);
              }),
          );
        } else {
          reportData.data.push([
            typesData[data.id] ? Object.keys(typesData[data.id]).length : 0,
            data.value.name,
            ' ',
            ' ',
          ]);
        }
      });
      return [reportData];
    },
  }));

  const prepareModalData = (e, input, type, groupID) => {
    e.stopPropagation(true);
    if (input) {
      const tableData = {
        columns: [
          { title: t('First Name'), key: 'firstName' },
          { title: t('Last Name'), key: 'lastName' },
          { title: t('Email'), key: 'email' },
          {
            title: t('Group Joining Date'),
            key: 'groupAssignment',
            render: value => moment(value).format('DD/MM/YYYY'),
          },
        ],
      };
      switch (type) {
        case 'total': {
          tableData.rows = Object.values(
            _.pickBy(users, (record, userID) => {
              const recordRef = record;
              if (input[userID]) {
                recordRef.groupAssignment = input[userID].createdAt;
              }
              return !!input[userID];
            }),
          );
          break;
        }
        case 'status': {
          const userIDs = input.map(i => i.id);
          tableData.rows = Object.values(
            _.pickBy(users, (record, userID) => {
              const recordRef = record;
              const userIndex = userIDs.indexOf(userID);
              if (userIndex > -1) {
                recordRef.groupAssignment = input[userIndex].value.createdAt;
                return true;
              }
              return false;
            }),
          );
          break;
        }
        case 'subfield': {
          tableData.rows = Object.values(
            _.pickBy(users, (record, userID) => {
              const recordRef = record;
              const userIndex = input.indexOf(userID);
              if (userIndex > -1) {
                recordRef.groupAssignment =
                  workforceTypesData[groupID][userID].createdAt;
                return true;
              }
              return false;
            }),
          );
          break;
        }
        case 'fields': {
          let userIds = [];
          input.forEach(i => {
            userIds = [...userIds, ...i.filteredItems];
          });
          tableData.rows = Object.values(
            _.pickBy(users, (record, userID) => {
              const recordRef = record;
              const userIndex = userIds.indexOf(userID);
              if (userIndex > -1) {
                recordRef.groupAssignment =
                  workforceTypesData[groupID][userID].createdAt;
                return true;
              }
              return false;
            }),
          );
          break;
        }
        default:
          return false;
      }
      setModalData(tableData);
      setOpen(true);
    }
    return true;
  };

  return (
    <ExpansionPanelDetails>
      <Filters onFilter={onFilter} />
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Workforce Group</Trans>
              </TableCell>
              <TableCell align="center">
                <Trans>Active/Pending/Rejected</Trans>
              </TableCell>
              <TableCell align="left">
                <Trans>Additional Data</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {objectToArray(workforceGroups).map(data => {
              const groupID = data.id;
              const dataArray = objectToArray(typesData[groupID]).filter(
                user => userIdList.includes(user.id), //remove deleted users
              );
              const activeUsers = dataArray.filter(
                i => i.value.status === 'active',
              );
              const pendingUsers = dataArray.filter(
                i => i.value.status === 'pending',
              );
              const rejectedUsers = dataArray.filter(
                i => i.value.status === 'rejected',
              );
              return (
                <TableRow key={`item-${groupID}`}>
                  <TableCell component="th" scope="row">
                    <List component="nav">
                      <ListItem
                        button
                        onClick={e =>
                          prepareModalData(e, typesData[groupID], 'total')
                        }
                      >
                        {data.value.name} (
                        {typesData[groupID]
                          ? Object.keys(typesData[groupID]).length
                          : 0}
                        )
                      </ListItem>
                    </List>
                  </TableCell>
                  <TableCell align="center">
                    <List component="nav">
                      <ListItem
                        button
                        onClick={e =>
                          prepareModalData(e, activeUsers, 'status')
                        }
                      >
                        <Typography
                          variant="body2"
                          className={classes.labelText}
                        >
                          <Trans>Active</Trans>
                        </Typography>
                        <Typography variant="caption" color="inherit">
                          {activeUsers.length}
                        </Typography>
                      </ListItem>
                      <ListItem
                        button
                        onClick={e =>
                          prepareModalData(e, pendingUsers, 'status')
                        }
                      >
                        <Typography
                          variant="body2"
                          className={classes.labelText}
                        >
                          <Trans>Pending</Trans>
                        </Typography>
                        <Typography variant="caption" color="inherit">
                          {pendingUsers.length}
                        </Typography>
                      </ListItem>
                      <ListItem
                        button
                        onClick={e =>
                          prepareModalData(e, rejectedUsers, 'status')
                        }
                      >
                        <Typography
                          variant="body2"
                          className={classes.labelText}
                        >
                          <Trans>Inactive</Trans>
                        </Typography>
                        <Typography variant="caption" color="inherit">
                          {rejectedUsers.length}
                        </Typography>
                      </ListItem>
                    </List>
                  </TableCell>
                  <TableCell align="left">
                    <TreeView
                      defaultCollapseIcon={<ArrowDropDownIcon />}
                      defaultExpandIcon={<ArrowRightIcon />}
                    >
                      {objectToArray(data.value.fields)
                        .filter(i => i.value.type === 'Dropdown')
                        .map(field => {
                          const itemsData = [];
                          field.value.options.split(',').forEach(item => {
                            //remove whitespaces
                            const modifiedItem =
                              typeof item === 'string'
                                ? item.replaceAll(/\s+/g, ' ').trim()
                                : item;

                            //check if item exist for this field
                            const filteredItems = dataArray
                              .filter(
                                i =>
                                  i.value[field.id] &&
                                  i.value[field.id].value &&
                                  i.value[field.id].value.includes(
                                    modifiedItem,
                                  ),
                              )
                              .map(i => i.id);
                            itemsData.push({
                              item,
                              length: filteredItems.length,
                              filteredItems,
                            });
                          });
                          return (
                            <TreeItem
                              nodeId={`node-${field.id}`}
                              key={`node-${field.id}`}
                              label={
                                <div className={classes.labelRoot}>
                                  <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                  >
                                    {field.value.name} (
                                    {_.sumBy(itemsData, 'length')})
                                  </Typography>
                                  <Typography variant="caption" color="inherit">
                                    <Tooltip
                                      title={t('View Details')}
                                      placement="top"
                                    >
                                      <IconButton
                                        style={{ display: 'inline-block' }}
                                        display="inline-block"
                                      >
                                        <InfoIcon
                                          color="#a2a2a2"
                                          style={{ height: 18, width: 18 }}
                                          onClick={e =>
                                            prepareModalData(
                                              e,
                                              itemsData,
                                              'fields',
                                              groupID,
                                            )
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </div>
                              }
                            >
                              {itemsData.map(option => (
                                <TreeItem
                                  nodeId={option.item}
                                  key={option.item}
                                  label={
                                    <div className={classes.labelRoot}>
                                      <Typography
                                        variant="body2"
                                        className={classes.labelText}
                                      >
                                        {option.item} ({option.length})
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        color="inherit"
                                      >
                                        <Tooltip
                                          title={t('View Details')}
                                          placement="top"
                                        >
                                          <IconButton
                                            style={{ display: 'inline-block' }}
                                            display="inline-block"
                                          >
                                            <InfoIcon
                                              color="#a2a2a2"
                                              style={{ height: 18, width: 18 }}
                                              onClick={e =>
                                                prepareModalData(
                                                  e,
                                                  option.filteredItems,
                                                  'subfield',
                                                  groupID,
                                                )
                                              }
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </Typography>
                                    </div>
                                  }
                                />
                              ))}
                            </TreeItem>
                          );
                        })}
                    </TreeView>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal {...modalData} open={isOpen} onClose={() => setOpen(false)} />
    </ExpansionPanelDetails>
  );
});
NoOfSystems.propTypes = {
  workforceGroups: PropTypes.object,
  workforceTypesData: PropTypes.object,
  users: PropTypes.object,
};

NoOfSystems.defaultProps = {
  workforceGroups: {},
  workforceTypesData: {},
  users: {},
};

export default NoOfSystems;
