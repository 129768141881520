import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Modal from '../../../modules/account/components/modal';
import { CTAButton } from '../../components/buttons';
import './styles.css';
import { PROMO_DETAILS } from '../../../config';

const Container = styled.div`
  text-align: center;
  display: grid;
  place-items: center;
`;

const PromoOfferNotice = ({
  closePromoOfferPopup,
  showPopup,
  history,
  appname,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    closePromoOfferPopup();
  };

  const onUpdate = () => {
    history.push(`/${appname}/choose_plan`);
    closePromoOfferPopup();
  };

  const htmlDecode = input => {
    let e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  };

  const getContent = () => {
    return (
      <div id="offer-content">
        <center>
          <div id="offer-image">
            <img src={PROMO_DETAILS.promoImageDark} alt="promo-image" />
          </div>
        </center>
        <div
          dangerouslySetInnerHTML={{
            __html: htmlDecode(PROMO_DETAILS.popUpContent),
          }}
        ></div>
      </div>
    );
  };

  return (
    <Modal
      open={showPopup}
      onRequestClose={handleClose}
      showHeader={true}
      bodyClassName="promo-offer-modal"
      modalHeight={'fit-content'}
    >
      <Container>
        {getContent()}

        <CTAButton
          height={50}
          label={t('Upgrade today')}
          outlineRGB={'195, 207, 47'}
          style={{ margin: '10px auto', display: 'block', maxWidth: '230px' }}
          onClick={onUpdate}
        />
      </Container>
    </Modal>
  );
};

export default PromoOfferNotice;
