import i18n from 'i18next';
import firebase from '../../../../../../../firebase';

const addingSliderItem = lazyDataId => ({
  type: 'ADDING_SLIDER_ITEM',
  key: lazyDataId,
});

const reportErr = (lazyDataId, err) => ({
  type: 'SLIDER_ITEM_ERROR',
  key: lazyDataId,
  err,
});

const rearrangeSliderItem = (id, siblingId) => ({
  type: 'REARRANGE_SLIDER_ITEM',
  id,
  siblingId,
});

export const addSliderItem = (appname, dataId, title) => dispatch => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);
  const baseItem = {
    image: null,
    subtitle: null,
    link: {
      enabled: true,
      button: {
        enabled: true,
        label: i18n.t('Button'),
      },
      linkType: {
        page: '/',
        selectedOption: 'page',
        web: 'https://www.example.com',
      },
    },
    title,
  };

  dispatch(addingSliderItem(dataId));

  const itemRef = dataRef.child('slides/chunks').push(baseItem);

  return dataRef
    .child('slides/order')
    .transaction(items => {
      if (!items) return [itemRef.key];

      return items.concat(itemRef.key);
    })
    .catch(err => dispatch(reportErr(err)));
};

const findIndex = (array, element) => {
  if (element === null) {
    return array.length;
  }

  return array.indexOf(element);
};

const moveArray = (oldIndex, newIndex, arr) => {
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const rearrangeItems = (
  appname,
  lazydata,
  id,
  siblingId,
) => dispatch => {
  const itemsRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${lazydata}/slides/order`);

  itemsRef
    .transaction(array => {
      if (array === null) return null;

      const oIndex = findIndex(array, id);
      let nIndex = findIndex(array, siblingId);

      if (nIndex > oIndex) {
        nIndex -= 1;
      }

      return moveArray(oIndex, nIndex, array);
    })
    .then(dispatch(rearrangeSliderItem(id, siblingId)));
};

export const designChange = (appname, key, type, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${key}/design`);

  dataRef.child(type).set(value);

  return {
    type: 'ITEM_DESIGN_CHANGE',
    appname,
    key,
    designType: type,
    value,
  };
};
