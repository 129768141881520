import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';

import './styles.css';

const styles = {
  label: {
    fontSize: 13,
    fontWeight: 500,
    fontFamily: "'Montserrat', sans-serif",
    lineHeight: '40px',
  },
  root: {
    height: 40,
    minWidth: 160,
    borderRadius: 30,
    margin: 0,
    overflow: 'hidden',
    display: 'block',
  },
  button: {
    borderRadius: 30,
    overflow: 'hidden',
  },
  ripple: {
    borderRadius: 30,
    overflow: 'hidden',
  },
};

const applyStyle = (base, custom) => ({ ...base, ...custom });

class RaisedAction extends Component {
  static propTypes = {
    style: PropTypes.object,
    buttonStyle: PropTypes.object,
    labelStyle: PropTypes.object,
    backgroundColor: PropTypes.string,
    disabledBackgroundColor: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    styleClass: PropTypes.string,
  };

  static defaultProps = {
    style: {},
    buttonStyle: {},
    labelStyle: {},
    backgroundColor: '#fff', // 'rgb(184, 201, 36)',
    disabledBackgroundColor: undefined,
    disabled: false,
    wrapped: false,
  };

  render() {
    const {
      style,
      buttonStyle,
      labelStyle,
      label,
      backgroundColor,
      disabledBackgroundColor,
      onClick,
      disabled,
    } = this.props;
    const addonProps = {};
    if (this.props.id) {
      addonProps.id = this.props.id;
    }
    if (this.props.styleClass) {
      addonProps.className = this.props.styleClass;
    }
    return (
      <RaisedButton
        {...addonProps}
        label={label}
        onClick={onClick}
        disabled={disabled}
        backgroundColor={backgroundColor}
        disabledBackgroundColor={disabledBackgroundColor}
        style={applyStyle(styles.root, style)}
        buttonStyle={applyStyle(styles.button, buttonStyle)}
        labelStyle={applyStyle(styles.label, labelStyle)}
        rippleStyle={styles.ripple}
        overlayStyle={styles.ripple}
      />
    );
  }
}

export default RaisedAction;
