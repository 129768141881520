const initialState = {
  appname: '',
  fetching: false,
  subscription: undefined,
};

const appContext = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_SUBSCRIPTION':
      return {
        ...state,
        fetching: true,
      };

    case 'UPDATE_SUBSCRIPTION':
    case 'RECEIVE_SUBSCRIPTION':
      return {
        ...state,
        fetching: false,
        subscription: (action.data && action.data.plan) || null,
      };

    case 'SELECT_APP':
      return {
        ...state,
        appname: action.appname,
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default appContext;
