import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import IconButton from 'material-ui/IconButton';
import { Trans, useTranslation } from 'react-i18next';
import { Create } from '../../../../../../components/icons';

import '../viewItem/styles.css';

const styles = {
  button: {
    padding: 0,
    height: '24px',
    width: '24px',
  },
};

// NOTE: limit tabs to 4 - once we migrate to material-ui v1 we can remove this, as tabs should be swipeable
const TabHeader = ({ tabs, onAddTab }) => {
  const { t } = useTranslation();
  return (
    <div className="tabs-header">
      <span className="tabs-header-title">
        <Trans>Tabs</Trans>
      </span>
      {tabs.length < 4 && (
        <IconButton
          style={styles.button}
          tooltip={t('Add Tab')}
          tooltipPosition="bottom-left"
          onClick={onAddTab}
        >
          <Create />
        </IconButton>
      )}
    </div>
  );
};

TabHeader.propTypes = {
  tabs: PropTypes.array.isRequired,
  onAddTab: PropTypes.func.isRequired,
};

export default withRouter(TabHeader);
