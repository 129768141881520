import { combineReducers } from 'redux';
import _keys from 'lodash/keys';
import createField from './modules/create/reducer';

import {
  REQUEST_MARKETPLACE_FILTER_DETAILS,
  REQUEST_MARKETPLACE_FILTER_FIELD_UPDATE,
  RECEIVE_MARKETPLACE_FILTER_FIELD_UPDATE,
  RECEIVE_MARKETPLACE_FILTER_DETAILS,
  MARKETPLACE_FILTER_FIELD_DELETED,
} from './types';

const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  name: {
    value: '',
    isValid: null,
  },
  type: {
    value: '',
    isValid: null,
  },
  options: {
    value: '',
    isValid: null,
  },
  filterDetails: {
    name: '',
    description:'',
    filterType:''
  },
  fields: [],
  fileloading: false,
};

const editFilter = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_MARKETPLACE_FILTER_DETAILS:
      return {
        ...state,
      };

    case REQUEST_MARKETPLACE_FILTER_FIELD_UPDATE:
      return {
        ...state,
      };

    case MARKETPLACE_FILTER_FIELD_DELETED:
      return {
        ...state,
        fieldID: action.fieldID,
      };

    case RECEIVE_MARKETPLACE_FILTER_FIELD_UPDATE:
      return {
        ...state,
        data: action.data,
      };

    case RECEIVE_MARKETPLACE_FILTER_DETAILS:
      return {
        ...state,
        filterDetails: action.value,
        fields: _keys(action.value.fields),
        pending: false,
        error: null,
      };

    default:
      return state;
  }
};

export default combineReducers({
  editFilter,
  createField,
});
