import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
// Material Imports
import Popover, { PopoverAnimationVertical } from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';
import AccountsIcon from '../../assets/Accounts';
import { whitelabeledHost } from '../../../../utils/whitelabeledHost';
const isWhiteLabelledHost = whitelabeledHost();

const styles = {
  menuStyle: {
    padding: '2px 0',
  },
  menuItem: {
    minHeight: 36,
    fontSize: 13,
  },
  divider: {
    margin: 0,
  },

  underline: {
    borderTop: 'none',
  },
  helpButton: {
    margin: 3,
    height: 48,
    width: 48,
    minWidth: 'none',
    backgroundColor: 'rgba(246,246,246,1)',
  },
  helpButtonLabel: {
    padding: 0,
    fontSize: 14,
  },
  tooltip: {
    top: '12px',
    left: '20px',
    paddingRight: '20px',
  },
};

class AccountsDropdown extends Component {
  mailTo = () => {
    // TODO: this should be a _blank link?
    window.location.href = 'mailto:support@beezer.com';
  };

  isWixDashboard = () => this.props.type === 'Wix' && this.isInsideIFrame();
  // Platform inside an iframe
  isInsideIFrame = () => window.self !== window.top;

  handleUserMenuClick = event => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    this.props.toggleUserMenu();
  };

  render() {
    const { t } = this.props;
    return (
      <div
        ref={node => {
          this.userMenuRef = node;
        }}
      >
        <div onClick={this.handleUserMenuClick}>
          <AccountsIcon className="menuButton" />
        </div>
        <Popover
          open={this.props.userMenu}
          anchorEl={this.userMenuRef}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          animation={PopoverAnimationVertical}
          onRequestClose={this.handleUserMenuClick}
        >
          <Menu style={styles.menuStyle} menuItemStyle={styles.menuItem}>
            <MenuItem
              onClick={this.props.onAccountSettingsTouchTap}
              primaryText={t('Account Settings')}
              innerDivStyle={styles.menuItem}
            />
            {!this.props.isSubAccount && !this.props.isSubAdminWithMulipleApps && (
              <>
                <Divider style={styles.divider} />
                <a
                  href="https://beezer.zendesk.com/hc"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <MenuItem
                    onClick={this.props.onDocumentationTouchTap}
                    primaryText={t('Documentation')}
                    innerDivStyle={styles.menuItem}
                  />
                </a>
              </>
            )}
            {!this.props.isSubAccount &&
            !isWhiteLabelledHost &&
            !this.props.isSubAdminWithMulipleApps ? (
              <>
                <Divider style={styles.divider} />
                <MenuItem
                  onClick={this.mailTo}
                  primaryText="support@beezer.com"
                  innerDivStyle={styles.menuItem}
                />
              </>
            ) : null}

            {!this.isWixDashboard() && <Divider style={styles.divider} />}
            {!this.isWixDashboard() && (
              <MenuItem
                onClick={this.props.onLogout}
                primaryText={t('Log Out')}
                innerDivStyle={styles.menuItem}
              />
            )}
          </Menu>
        </Popover>
      </div>
    );
  }
}

export default withTranslation()(AccountsDropdown);
