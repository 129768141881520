import firebase from '../../../../../../firebase';

export const togglePrivacyPolicy = (appname, toggled) => dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/privacyPolicy`)
    .update({ enabled: toggled })
    .then(() =>
      dispatch({
        type: 'TOGGLE_PRIVACY_POLICY',
        appname,
        toggled,
      }),
    );
};

export const updatePrivacyPolicyUrl = (appname, url) => dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/privacyPolicy`)
    .update({ url })
    .then(() =>
      dispatch({
        type: 'UPDATE_PRIVACY_POLICY_URL',
        appname,
        url,
      }),
    );
};
