import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import Modal from '../../../modules/account/components/modal';
import { rocketSignUp } from './images';
import { CTAButton } from '../../../app/components/buttons';
import './styles.css';

const Container = styled.div`
  padding: 0 15px 15px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-flow: inherit;
  max-height: 100%;
  .img_wrapper {
    position: relative;
    display: block;
    height: 65%;
    img {
      max-width: 65%;
      max-height: 60%;
      padding: 10px 20px 0;
    }
  }
`;

const marginStyle = {
  marginTop: '35px',
};

const AppSumoWelcome = ({
  accountId,
  isAppSumAccount,
  appsumo_welcome,
  closeWelcome,
  checkAppSumoWelcomeMsgOccurence,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    checkAppSumoWelcomeMsgOccurence(accountId);
  }, [accountId]);

  const handleClose = () => {
    closeWelcome(accountId);
  };

  const stateContentText = () => {
    return (
      <span style={marginStyle}>
        <strong>
          <Trans>
            Welcome to Beezer. You're just seconds away from building a
            brilliant progressive web app.
          </Trans>
        </strong>
        <div>
          <p>
            <Trans>
              We're setting up your account and getting your app-building
              platform ready. This won't take long.
            </Trans>
          </p>
        </div>
      </span>
    );
  };

  return (
    <Modal
      open={appsumo_welcome && isAppSumAccount}
      onRequestClose={handleClose}
      showHeader={true}
      bodyClassName="beezer-modal"
    >
      <Container>
        {stateContentText()}
        <div className="img_wrapper">
          <img alt="modal_image" src={rocketSignUp} />
        </div>
        <CTAButton
          label={t('Customize your app')}
          // borderColor="rgb(156, 150, 6)"
          outlineRGB={'195, 207, 47'}
          style={{ margin: '10px auto', display: 'block', maxWidth: '230px' }}
          onClick={handleClose}
        />
      </Container>
    </Modal>
  );
};

export default AppSumoWelcome;
