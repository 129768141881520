import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AddAPhoto from 'material-ui/svg-icons/image/add-a-photo';

const Label = styled.span`
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: #888;
  line-height: 21px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ImagePreviewCTA = ({ children }) => (
  <LabelContainer>
    <AddAPhoto style={{ width: 42, height: 42 }} color="#888" />
    <Label>{children}</Label>
  </LabelContainer>
);

ImagePreviewCTA.propTypes = {
  children: PropTypes.string.isRequired,
};

export default ImagePreviewCTA;
