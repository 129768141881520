import { connect } from 'react-redux';

import PromoOfferNotice from './PromoOfferNotice';
import { closePromoOfferPopup, openPromoOfferPopup } from './actions';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  showPopup: state.app.promoOfferPopUp.open || false,
  appname: ownProps.match.params.appname,
});

const mapDisptachToProps = (dispatch, ownProps) => ({
  closePromoOfferPopup: () => dispatch(closePromoOfferPopup()),
  openPromoOfferPopup: () => dispatch(openPromoOfferPopup()),
});

export default withRouter(
  connect(mapStateToProps, mapDisptachToProps)(PromoOfferNotice),
);
