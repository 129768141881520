const initialState = {
  open: false,
  pending: false,
  title: '',
  text: '',
  buttonLabel: 'Submit',
  cancelLabel: 'Cancel',
  query: {},
  error: null,
};

const dialog = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_DIALOG':
      return {
        open: true,
        pending: false,
        title: action.title,
        text: action.text,
        buttonLabel: action.buttonLabel,
        cancelLabel: action.cancelLabel,
        error: null,
      };

    case 'SUBMIT_DIALOG_ACTION':
      return {
        ...state,
        error: null,
        pending: true,
      };

    case 'CLOSE_DIALOG':
    case 'DIALOG_ACTION_COMPLETE':
      return initialState;

    case 'REPORT_DIALOG_ERROR':
      return {
        ...state,
        pending: false,
        error: action.err,
      };

      case 'LOGOUT_SUCCESS':
      return {
        ...initialState,
      };


    default:
      return state;
  }
};

export default dialog;
