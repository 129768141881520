import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingScreen } from '../../../../../app/components/progress';

class IntegrationsLogin extends Component {
  static propTypes = {
    onReceiveToken: PropTypes.func.isRequired,
    customtoken: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    isWix: PropTypes.bool,
    onLogout: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isWix: false,
  };

  componentDidMount() {
    // Logout before login to ensure the user is in the correct session
    this.props.onLogout();

    const platform = this.props.isWix ? 'wix' : 'weebly';
    console.log(
      'sjdtoken',
      { customtoken: this.props.customtoken, platform },
      this.props,
    );
    if (this.props.customtoken) {
      this.props.onReceiveToken(this.props.customtoken, platform);
    } else {
      this.props.history.push('/');
    }
  }

  render() {
    return <LoadingScreen loading />;
  }
}

export default IntegrationsLogin;
