import _keys from 'lodash/keys';
import firebase from '../../../../firebase';
import { objectToArray, arrayToObject, formatDate } from '../../../../utils';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';
import { CPD_DELETED } from './config';

export const REQUEST_CPDS = 'REQUEST_CPDS';
export const RECEIVE_CPDS = 'RECEIVE_CPDS';
export const FILTER_CPDS = 'FILTER_CPDS';
export const SELECT_CPD = 'SELECT_CPD';
export const SELECT_ALL_CPDS = 'SELECT_ALL_CPDS';
export const DESELECT_ALL_CPDS = 'DESELECT_ALL_CPDS';
export const GET_FIELDS_CPD = 'GET_FIELDS_CPD';
export const INITIATE_FIELDS_CPD = 'INITIATE_FIELDS_CPD';
export const INITIATE_MEMBER_BODY_CPD = 'INITIATE_MEMBER_BODY_CPD';
export const DELETED_CPD = 'DELETED_CPD';

export const request = appname => ({
  type: REQUEST_CPDS,
  appname,
});

export const selectCPD = key => ({
  type: SELECT_CPD,
  key,
});

export const deletedDone = data => ({
  type: DELETED_CPD,
  data,
});

export const initiateMemberBody = key => ({
  type: INITIATE_MEMBER_BODY_CPD,
  key,
});

export const filterCPDData = (filter, keys) => dispatch => {
  dispatch({
    type: FILTER_CPDS,
    payload: filter,
    keys,
  });
};

export const selectAllCPDs = () => ({
  type: SELECT_ALL_CPDS,
});

export const deselectAllCPDs = () => ({
  type: DESELECT_ALL_CPDS,
});

export const getCPDFields = appname => dispatch =>
  firebase
    .database()
    .ref(`app_cpds/${appname}/fields`)
    .on('value', snap => {
      if (snap.exists()) {
        const fields = snap.val();
        dispatch({
          type: GET_FIELDS_CPD,
          value: fields,
        });
        // create fieldsData object
        const fieldsData = {};
        Object.keys(fields).forEach(key => {
          fieldsData[key] = {
            value: fields[key].defaultValue,
            isValid: fields[key].isRequired ? null : true,
          };
        });
        dispatch({
          type: INITIATE_FIELDS_CPD,
          value: fieldsData,
        });
      } else {
        dispatch({
          type: GET_FIELDS_CPD,
          value: {},
        });
      }
    });

export const selectMemberBody = key => (dispatch, getState) => {
  if (key) {
    dispatch(initiateMemberBody(key));
  } else {
    const { memberBodies } = getState().account.profile;
    dispatch(initiateMemberBody(_keys(memberBodies)[0]));
  }
};

export const getCPDs = appname => (dispatch, getState) => {
  dispatch(request(appname));

  const { memberbody } = getState().staff.cpds.list;
  return firebase
    .database()
    .ref(`app_cpds/${appname}/cpds/${memberbody}`)
    .on('value', snap => {
      if (snap.exists()) {
        // convert object to compatible version for table
        const list = arrayToObject(
          objectToArray(snap.val()).map(data => ({
            id: data.id,
            value: arrayToObject(
              objectToArray(data.value).map(i => ({
                id: i.id,
                value:
                  i.value.type === 'DateRange'
                    ? i.value.value
                        .map(date => formatDate(date, 'YYYY/MM/DD'))
                        .join(' To ')
                    : i.value.value,
              })),
            ),
          })),
        );
        dispatch({
          type: RECEIVE_CPDS,
          value: list,
        });
      } else {
        dispatch({
          type: RECEIVE_CPDS,
          value: {},
        });
      }
    });
};

export const deleteCPDs = appname => (dispatch, getState) => {
  const { selected, memberbody } = getState().staff.cpds.list;
  return Promise.all(
    selected.map(cpdID =>
      firebase
        .database()
        .ref(`app_cpds/${appname}/cpds/${memberbody}/${cpdID}`)
        .remove()
        .then(async () => {
          // delete the cpd dependencies
          const subscribers = await firebase
            .database()
            .ref(`subscribers/${appname}/cpd/${cpdID}`)
            .once('value')
            .then(snapshot => (snapshot.exists() ? snapshot.val() : {}));
          if (subscribers.workforce) {
            const workforces = objectToArray(subscribers.workforce);
            await Promise.all(
              workforces.map(item =>
                firebase
                  .database()
                  .ref(
                    `subscriber_attendees/${appname}/cpd/workforce/${item.value.uid}`,
                  )
                  .orderByChild('eventID')
                  .equalTo(cpdID)
                  .once('value')
                  .then(snapshot => {
                    if (snapshot.exists()) {
                      Object.keys(snapshot.val()).map(deleteKey =>
                        firebase
                          .database()
                          .ref(
                            `subscriber_attendees/${appname}/cpd/workforce/${item.value.uid}/${deleteKey}`,
                          )
                          .remove(),
                      );
                    }
                  }),
              ),
            );
          }
          if (subscribers.user) {
            const users = objectToArray(subscribers.user);
            await Promise.all(
              users.map(item =>
                firebase
                  .database()
                  .ref(
                    `subscriber_attendees/${appname}/cpd/user/${item.value.uid}`,
                  )
                  .orderByChild('eventID')
                  .equalTo(cpdID)
                  .once('value')
                  .then(snapshot => {
                    if (snapshot.exists()) {
                      Object.keys(snapshot.val()).map(deleteKey =>
                        firebase
                          .database()
                          .ref(
                            `subscriber_attendees/${appname}/cpd/user/${item.value.uid}/${deleteKey}`,
                          )
                          .remove(),
                      );
                    }
                  }),
              ),
            );
          }

          await firebase
            .database()
            .ref(`subscribers/${appname}/cpd/${cpdID}`)
            .remove();
        }),
    ),
  ).then(() => {
    dispatch(deletedDone(selected));
    dispatch(openSnackbar(CPD_DELETED));
  });
};
