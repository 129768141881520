import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NewAdmin from './NewAdmin';

import {
  addAdmin,
  closeNewAdminDialog,
  formFieldChange,
  checkAppDetails,
  newSubAdminSectionAccess,
} from './actions';
import { getDefaultWhatsappCredits } from '../../../../../../../utils';

const mapStateToProps = state => {
  const isAppAdmin =
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false;
  const trialExpired = state.account.organisation.trial_expired;
  const appSubscription = state.app.appContext.subscription;
  let whatsappSMSCredits = state.account.organisation.whatsapp_sms_credit;
  //if whatsapp credits does not exists on account get the default amount
  if (whatsappSMSCredits === undefined || whatsappSMSCredits === null) {
    whatsappSMSCredits = getDefaultWhatsappCredits({
      isAppAdmin,
      trialExpired,
      appSubscription,
    });
  }

  return {
    ...state.editor.build.appAdmins.newAdmin,
    apps: state.account.organisation.apps,
    smsCredits: state.account.organisation.sms_credit,
    whatsappSMSCredits,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(addAdmin()),
  onRequestClose: () => dispatch(closeNewAdminDialog()),
  onFieldChange: (field, value) => dispatch(formFieldChange(field, value)),
  checkAppDetails: apps => dispatch(checkAppDetails(apps)),
  newSubAdminSectionAccess: (sectionName, value) =>
    dispatch(newSubAdminSectionAccess(sectionName, value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewAdmin),
);
