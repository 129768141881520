import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Divider, Checkbox, MenuItem } from 'material-ui';
import Toggle from 'material-ui/Toggle';
import { Trans, withTranslation } from 'react-i18next';
import DesignToggle from 'modules/editor/components/designToggle';
import ColorPicker from 'modules/editor/components/colorPicker';
import Container from '../../../../../../../app/components/container';
import { Tabs, Tab } from '../../../../../components/tabs';
import SelectField from '../../../../../../../app/components/selectField';
import SectionHeader from '../../../../../../editor/components/sectionHeader';
import { capitaliseString } from '../../../../../../../utils';
import DraggableListWrapper from '../../../../../components/draggableListWrapper';
import ComponentItem from '../../../../../components/componentItem';
import ButtonLabelSetter from '../../../../../../editor/components/buttonLabelSetter';
import DeleteError from '../../../../../../editor/components/deleteError';
import themeStyles from '../../../../../../../_export.scss';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Subtitle from '../../../../../../../app/components/subtitle';
import Range from '../../../../../../../app/components/range';

const types = ['text', 'email', 'checkbox', 'dropdown', 'DateRange'];
const options = types.map(type => (
  <MenuItem
    key={type}
    value={type}
    primaryText={capitaliseString(type === 'DateRange' ? 'Date Range' : type)}
  />
));

const minFields = 1;

class EventCalendar extends Component {
  static propTypes = {
    data: PropTypes.shape({
      event: PropTypes.shape({
        // title: PropTypes.string,
        // displayTitle: PropTypes.bool,
        // caption: PropTypes.string,
        fields: PropTypes.shape({
          order: PropTypes.arrayOf(PropTypes.string),
          chunks: PropTypes.object,
        }),
        buttonText: PropTypes.string,
        autoFillEmail: PropTypes.bool,
      }),
      course: PropTypes.shape({
        // title: PropTypes.string,
        // displayTitle: PropTypes.bool,
        // caption: PropTypes.string,
        fields: PropTypes.shape({
          order: PropTypes.arrayOf(PropTypes.string),
          chunks: PropTypes.object,
        }),
        buttonText: PropTypes.string,
        autoFillEmail: PropTypes.bool,
      }),
      enableCourses: PropTypes.bool,
    }).isRequired,
    component: PropTypes.object.isRequired,
    onFieldChange: PropTypes.func.isRequired,
    // onButtonDesignChange: PropTypes.func.isRequired,
    onAddField: PropTypes.func.isRequired,
    onRemoveField: PropTypes.func.isRequired,
    // onDesignToggle: PropTypes.func.isRequired,
    onRearrangeField: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    filters: [],
  };

  state = {
    deleteObjectError: false,
  };

  handleMove = (el, target, source, sibling) =>
    sibling
      ? this.props.onRearrangeField(el.id, sibling.id)
      : this.props.onRearrangeField(el.id, null);

  getItemHref = (key, type) => ({
    pathname: `/${this.props.match.params.appname}/editor/build/item`,
    search: `${this.props.location.search}&item=${key}&calendarType=${type}`,
  });

  onChangeButtonText = (value, type) => {
    this.props.onFieldChange('buttonText', value, type);
  };

  handleNavigate = (key, type) => () => {
    const href = this.getItemHref(key, type);
    return this.props.history.push(href);
  };

  handleRemove = (key, index, type) => {
    const { data } = this.props;
    if (
      data[type] &&
      data[type].fields &&
      data[type].fields.chunks &&
      Object.keys(data[type].fields.chunks).length <= minFields
    ) {
      return this.state.deleteObjectError
        ? null
        : this.setState({ deleteObjectError: true });
    }
    return this.props.onRemoveField(index, key, type);
  };

  handleAddField = (value, type) => {
    this.setState({ deleteObjectError: false });
    this.props
      .onAddField(value, type)
      .then(items => this.handleNavigate(items.pop(), type)());
  };

  onToggleCourseDisplay = isToggled =>
    this.props.onFieldChange('enableCourses', isToggled, null);

  renderItems = type => {
    const { data } = this.props;
    return data[type].fields.order.map((key, i) => {
      if (data[type].fields.chunks[key]) {
        const label =
          data[type].fields.chunks[key].type.charAt(0).toUpperCase() +
          data[type].fields.chunks[key].type.slice(1);
        return (
          <ComponentItem
            id={key}
            key={key}
            label={`
            ${label === 'DateRange' ? 'Date Range' : label}
              ${
                data[type].fields.chunks[key].label
                  ? ` - ${data[type].fields.chunks[key].label}`
                  : ''
              }`}
            onClick={this.handleNavigate(key, type)}
            onDelete={() => this.handleRemove(key, i, type)}
          />
        );
      }
    });
  };

  handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    this.props.onDesignChange(prop, rgba);
  };

  render() {
    const { event, course, enableCourses } = this.props.data;
    const appname = this.props.match.params.appname;
    const { fields } = this.props.data.event;
    const { component, onDesignChange, t } = this.props;
    return (
      <React.Fragment>
        <Tabs>
          <Tab label={t('events')}>
            <div className="event-list-container">
              <Container>
                <SectionHeader
                  title={t('Event Fields')}
                  tooltip={t('Add Event Details eg:Date, Description, etc.')}
                />
                {/* <Divider
                  style={{
                    width: '100%',
                    height: '2px',
                  }}
                /> */}

                <SelectField
                  label={t('Add Input Type')}
                  options={options}
                  onChange={(i, value) => this.handleAddField(value, 'event')}
                />
                {fields && (
                  <div style={{ width: '100%' }}>
                    <DraggableListWrapper
                      items={this.renderItems('event')}
                      onPositionChange={this.handleMove}
                    />
                    {this.state.deleteObjectError && (
                      <DeleteError text={t('form fields')} number={minFields} />
                    )}
                  </div>
                )}
                <Link to={`/${appname}/members/events`}>
                  <Button variant="text" size="small" color="primary">
                    <Trans>Add Events</Trans>
                  </Button>
                </Link>
                <ButtonLabelSetter
                  onChange={value => this.onChangeButtonText(value, 'event')}
                  label={event.buttonText || t('Submit')}
                />
                {event.buttonText === '' && <span>dcecec</span>}
              </Container>
            </div>
          </Tab>
          <Tab label={t('courses')}>
            <SectionHeader
              title={`${t('Enable Courses')}`}
              className="section-header"
              actions={
                <Toggle
                  toggled={enableCourses}
                  onToggle={(e, isToggled) =>
                    this.onToggleCourseDisplay(isToggled)
                  }
                  trackSwitchedStyle={{
                    backgroundColor: themeStyles.primaryColorLight,
                  }}
                  thumbSwitchedStyle={{
                    backgroundColor: themeStyles.primaryColor,
                  }}
                />
              }
            />
            {/* <Divider
              style={{
                width: '100%',
                height: '2px',
              }}
            /> */}
            {enableCourses && (
              <div className="calendar-list-container">
                <Container>
                  <SectionHeader
                    title={t('Course Fields')}
                    tooltip={t('Add Course Details eg:Date, Description, etc.')}
                  />
                  {/* <Divider
                    style={{
                      width: '100%',
                      height: '2px',
                    }}
                  /> */}

                  <SelectField
                    label={t('Add Input Type')}
                    options={options}
                    onChange={(i, value) =>
                      this.handleAddField(value, 'course')
                    }
                  />
                  {fields && (
                    <div style={{ width: '100%' }}>
                      <DraggableListWrapper
                        items={this.renderItems('course')}
                        onPositionChange={this.handleMove}
                      />
                      {this.state.deleteObjectError && (
                        <DeleteError
                          text={t('form fields')}
                          number={minFields}
                        />
                      )}
                    </div>
                  )}
                  <Link to={`/${appname}/members/courses`}>
                    <Button variant="text" size="small" color="primary">
                      <Trans>Add Courses</Trans>
                    </Button>
                  </Link>
                  <ButtonLabelSetter
                    onChange={value => this.onChangeButtonText(value, 'course')}
                    label={course.buttonText || t('Submit')}
                  />
                  {course.buttonText === '' && <span>dcecec</span>}
                </Container>
              </div>
            )}
          </Tab>
          <Tab label={t('design')}>
            <Container>
              <DesignToggle
                component={component}
                onDesignChange={onDesignChange}
              />

              <ColorPicker
                label={t('Title Color')}
                color={
                  (component.design && component.design.titleColor) ||
                  'rgb(0,0,0)'
                }
                height={22}
                disabled={
                  component &&
                  component.design &&
                  component.design.enabled === undefined
                    ? true
                    : component.design !== undefined
                    ? !component.design.enabled
                    : true
                }
                onChange={this.handleColorChange('titleColor')}
              />
              <Subtitle
                className={
                  component &&
                  component.design &&
                  !component.design.enabled &&
                  'disabled'
                }
              >
                <Trans>Title Font Size</Trans>
              </Subtitle>
              <Range
                min={1}
                max={36}
                step={1}
                label={
                  component && component.design && component.design.titleSize
                    ? `${component.design.titleSize}px`
                    : '19px'
                }
                value={
                  component && component.design && component.design.titleSize
                    ? component.design.titleSize
                    : 19
                }
                onChange={(el, val) =>
                  this.props.onDesignChange('titleSize', val)
                }
                disabled={
                  component &&
                  component.design &&
                  component.design.enabled === undefined
                    ? true
                    : component.design !== undefined
                    ? !component.design.enabled
                    : true
                }
                width="320px"
              />
              <ColorPicker
                label={t('Location Text Color')}
                color={
                  (component.design && component.design.subTitleColor) ||
                  'rgb(0,0,0)'
                }
                height={22}
                disabled={
                  component.design && component.design.enabled === undefined
                    ? true
                    : component.design !== undefined
                    ? !component.design.enabled
                    : true
                }
                onChange={this.handleColorChange('subTitleColor')}
              />
              <Subtitle
                className={
                  component &&
                  component.design &&
                  !component.design.enabled &&
                  'disabled'
                }
              >
                <Trans>Location Font Size</Trans>
              </Subtitle>
              <Range
                min={1}
                max={36}
                step={1}
                label={
                  component && component.design && component.design.subTitleSize
                    ? `${component.design.subTitleSize}px`
                    : '14px'
                }
                value={
                  component && component.design && component.design.subTitleSize
                    ? component.design.subTitleSize
                    : 14
                }
                onChange={(el, val) =>
                  this.props.onDesignChange('subTitleSize', val)
                }
                disabled={
                  component &&
                  component.design &&
                  component.design.enabled === undefined
                    ? true
                    : component.design !== undefined
                    ? !component.design.enabled
                    : true
                }
                width="320px"
              />
              <ColorPicker
                label={t('Description Text Color')}
                color={
                  (component.design && component.design.descriptionColor) ||
                  'rgb(0,0,0)'
                }
                height={22}
                disabled={
                  component.design && component.design.enabled === undefined
                    ? true
                    : component.design !== undefined
                    ? !component.design.enabled
                    : true
                }
                onChange={this.handleColorChange('descriptionColor')}
              />
              <Subtitle
                className={
                  component &&
                  component.design &&
                  !component.design.enabled &&
                  'disabled'
                }
              >
                <Trans>Description Font Size</Trans>
              </Subtitle>
              <Range
                min={1}
                max={36}
                step={1}
                label={
                  component &&
                  component.design &&
                  component.design.descriptionSize
                    ? `${component.design.descriptionSize}px`
                    : '14px'
                }
                value={
                  component &&
                  component.design &&
                  component.design.descriptionSize
                    ? component.design.descriptionSize
                    : 14
                }
                onChange={(el, val) =>
                  this.props.onDesignChange('descriptionSize', val)
                }
                disabled={
                  component &&
                  component.design &&
                  component.design.enabled === undefined
                    ? true
                    : component.design !== undefined
                    ? !component.design.enabled
                    : true
                }
                width="320px"
              />

              <ColorPicker
                label={t('Date Color')}
                color={
                  (component &&
                    component.design &&
                    component.design &&
                    component.design.dateColor) ||
                  'rgb(0,0,0)'
                }
                height={22}
                disabled={
                  component &&
                  component.design &&
                  component.design.enabled === undefined
                    ? true
                    : component.design !== undefined
                    ? !component.design.enabled
                    : true
                }
                onChange={this.handleColorChange('dateColor')}
              />

              <ColorPicker
                label={t('Body Color')}
                color={
                  (component &&
                    component.design &&
                    component.design &&
                    component.design.bodyColor) ||
                  'rgb(0,0,0)'
                }
                height={22}
                disabled={
                  component &&
                  component.design &&
                  component.design.enabled === undefined
                    ? true
                    : component.design !== undefined
                    ? !component.design.enabled
                    : true
                }
                onChange={this.handleColorChange('bodyColor')}
              />
              <Subtitle
                className={
                  component &&
                  component.design &&
                  !component.design.enabled &&
                  'disabled'
                }
              >
                <Trans>Body Font Size</Trans>
              </Subtitle>
              <Range
                min={1}
                max={36}
                step={1}
                label={
                  component && component.design && component.design.bodySize
                    ? `${component.design.bodySize}px`
                    : '11px'
                }
                value={
                  component && component.design && component.design.bodySize
                    ? component.design.bodySize
                    : 11
                }
                onChange={(el, val) =>
                  this.props.onDesignChange('bodySize', val)
                }
                disabled={
                  component &&
                  component.design &&
                  component.design.enabled === undefined
                    ? true
                    : component.design !== undefined
                    ? !component.design.enabled
                    : true
                }
                width="320px"
              />
            </Container>
          </Tab>
          {/* <Tab
            label={t('layout')}
            disabled
            style={{
              color: '#d9d9d9',
            }}
          /> */}
        </Tabs>
      </React.Fragment>
    );
  }
}

export default withTranslation()(EventCalendar);
