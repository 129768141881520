import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Trans, useTranslation } from 'react-i18next';
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody,
  Table,
} from '@material-ui/core';
import moment from 'moment';
import themeStyles from '../../../../../../_export.scss';

const useStyles = makeStyles(() => ({
  table: {
    '& .MuiTableCell-head': {
      color: 'black',
      backgroundColor: '#EAEEFF',
      fontSize: 14,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
      textAlign: 'center',
      borderBottom: 'none',
    },
    '& .MuiTableCell-body': {
      color: 'black',
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Raleway, sans-serif',
      textAlign: 'center',
      borderBottom: '1px solid #d3d3d3',
    },
    '& tbody td > .options-container': {
      '& span': {
        color: `${themeStyles.primaryColor}!important`,
      },
      '& svg': {
        fill: `${themeStyles.primaryColor}!important`,
      },
    },
  },
  tableLeftCorner: { borderTopLeftRadius: 10 },
  tableRightCorner: {
    borderTopRightRadius: 10,
  },
}));

const LoyaltyTable = props => {
  const { allStamps } = props;
  const { t } = useTranslation();

  const { table, tableLeftCorner, tableRightCorner } = useStyles();
  return (
    <div>
      <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
        <Table aria-label={t('customized table')} className={table}>
          <TableHead>
            <TableRow>
              <TableCell className={tableLeftCorner}>
                <Trans>Visit Number</Trans>
              </TableCell>
              <TableCell className={tableRightCorner}>
                <Trans>Visited At</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(allStamps).map(key => (
              <TableRow key={key}>
                <TableCell>{allStamps[key].visit}</TableCell>
                <TableCell>
                  {moment(allStamps[key].timestamp).format(
                    'MMMM Do YYYY, h:mm:ss a',
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

LoyaltyTable.propTypes = {
  allStamps: PropTypes.object.isRequired,
};

export default LoyaltyTable;
