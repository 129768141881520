import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from 'prop-types';

const Star = ({ color, fill, percentage, stroke, ...props }) => {
  const uid = Math.random()
    .toString(36)
    .substr(2, 16);
  return (
    <SvgIcon {...props} viewBox="0 -32 576 576">
      <defs>
        <linearGradient id={`gradient-${uid}`} x1="0" x2="100%" y1="0" y2="0">
          <stop offset={percentage} stopColor={color || fill} />
          <stop offset={percentage} stopColor={fill} />
        </linearGradient>
      </defs>
      <path
        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7
        103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2
        50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382
        150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
        fill={`url(#gradient-${uid})`}
        strokeWidth="5%"
        stroke={color || stroke}
      />
    </SvgIcon>
  );
};

Star.propTypes = {
  fill: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
};

export default Star;
