import _get from 'lodash/get';

import { savePaypalData } from '../../../actions';
import { newValidator } from '../../../../../utils';

export const RECEIVE_META_DATA = 'RECEIVE_META_DATA';
export const UPDATE_PAYPAL_SETTING = 'UPDATE_PAYPAL_SETTING';
export const HYDRATE_APP_PAYPAL_DATA = 'HYDRATE_APP_PAYPAL_DATA';
export const LOADING_APP_NAME = 'LOADING_APP_NAME';
export const REPLACE_APP_NAME_DATA = 'REPLACE_APP_NAME_DATA';
export const FIELD_VALIDATION_CHECK = 'FIELD_VALIDATION_CHECK';

const titleValidator = newValidator({
  required: true,
  maxLength: 45,
  minLength: 3,
  type: 'title',
});

const descriptionValidator = newValidator({
  maxLength: 320,
  type: 'description',
});

const loadingAppName = appname => ({
  type: LOADING_APP_NAME,
  appname,
});

const updatePaypalSetting = (paypal, appname) => ({
  type: UPDATE_PAYPAL_SETTING,
  appname,
  paypal,
});

const hydrateAppPaypalData = (data, appname) => ({
  type: HYDRATE_APP_PAYPAL_DATA,
  data,
  appname,
});

export const replaceAppData = (appname, previusAppname) => ({
  type: REPLACE_APP_NAME_DATA,
  previusAppname,
  appname,
});

export const loadPaypalData = appname => (dispatch, getState) => {
  const paypalData = _get(getState(), `editor.data.paypalData.${appname}`);
  dispatch(hydrateAppPaypalData(paypalData, appname));
};

export const validationChecker = (appname, invalid, attr, validations) => ({
  type: FIELD_VALIDATION_CHECK,
  appname,
  attr,
  invalid,
  validations,
});

export const updatePaypalData = (appname, attr, value) => dispatch => {
  dispatch(updatePaypalSetting({ [attr]: value }, appname));
};

export const saveChanges = (appname, nextState) => dispatch => {
  dispatch(savePaypalData(appname, nextState));
};
