import _get from 'lodash/get';

import { saveMetaData } from '../../../actions';
import { toggleDomainChangeModal } from '../../../internals/domainChangeModal/actions';
import { newValidator } from '../../../../../utils';
import { loadCustomDomain } from '../../../../../app/modules/customDomain/actions';
export const RECEIVE_META_DATA = 'RECEIVE_META_DATA';
export const UPDATE_APP_NAME = 'UPDATE_APP_NAME';
export const HYDRATE_DOMAIN = 'HYDRATE_DOMAIN';
export const LOADING_APP_NAME = 'LOADING_APP_NAME';
export const REPLACE_APP_NAME_DATA = 'REPLACE_APP_NAME_DATA';
export const FIELD_VALIDATION_CHECK = 'FIELD_VALIDATION_CHECK';
export const UPDATE_CUSTOM_DOMAIN_DATA = 'UPDATE_CUSTOM_DOMAIN_DATA';
export const DOMAIN_REMOVED = 'DOMAIN_REMOVED';

const titleValidator = newValidator({
  required: true,
  maxLength: 45,
  minLength: 3,
  type: 'title',
});

const descriptionValidator = newValidator({
  maxLength: 320,
  type: 'description',
});

const loadingAppName = appname => ({
  type: LOADING_APP_NAME,
  appname,
});

const updateAppName = (meta, appname) => ({
  type: UPDATE_APP_NAME,
  appname,
  meta,
});

export const updateCustomDomainData = customDomain => ({
  type: UPDATE_CUSTOM_DOMAIN_DATA,
  customDomain,
});

const hydrateDomain = (meta, appname) => ({
  type: HYDRATE_DOMAIN,
  meta,
  appname,
});

const domainRemoved = () => ({
  type: DOMAIN_REMOVED,
});

export const replaceAppData = (appname, previusAppname) => ({
  type: REPLACE_APP_NAME_DATA,
  previusAppname,
  appname,
});

export const loadAppNameData = appname => (dispatch, getState) => {
  dispatch(loadingAppName(appname));
  const metaData = _get(getState(), `editor.data.metaData.${appname}`);
  dispatch(hydrateDomain(metaData, appname));
};

export const validationChecker = (appname, invalid, attr, validations) => ({
  type: FIELD_VALIDATION_CHECK,
  appname,
  attr,
  invalid,
  validations,
});

const fieldValidator = (appname, attr, value, validations) => {
  switch (attr) {
    case 'title': {
      let isValid = titleValidator(value);
      if (isValid) {
        isValid = !(value.indexOf('"') > -1 || value.indexOf("'") > -1);
      }

      return validationChecker(appname, isValid, attr, validations);
    }
    case 'description': {
      let isValid = descriptionValidator(value);
      if (isValid) {
        isValid = !(value.indexOf('"') > -1 || value.indexOf("'") > -1);
      }

      return validationChecker(appname, isValid, attr, validations);
    }
    default:
      return null;
  }
};

export const updateMetaData = (
  appname,
  attr,
  value,
  validations,
) => dispatch => {
  dispatch(fieldValidator(appname, attr, value, validations));
  dispatch(updateAppName({ [attr]: value }, appname));
};

export const saveChanges = (appname, nextState, domain) => dispatch => {
  if (appname !== domain) {
    dispatch(toggleDomainChangeModal(appname, domain));
    return;
  }
  dispatch(saveMetaData(appname, nextState));
};

export const checkIsDomainSetupDone = (
  appname,
  user,
  domainType,
) => async dispatch => {
  try {
    const myHeaders = new Headers();
    const customDomainApi = `${process.env.REACT_APP_CUSTOM_DOMAIN_API}`;

    const token = await user.getIdToken();
    myHeaders.append('x-access-token', token);
    myHeaders.append('Content-Type', 'application/json');

    await fetch(`${customDomainApi}/is-setup-done`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        appname,
        uid: user.uid,
        domainType,
      }),
      mode: 'cors',
    });
    return dispatch(loadCustomDomain(appname));
  } catch (error) {
    console.log('Error at check domain setup', error);
  }
};

export const deleteCustomDomain = (appname, user) => async dispatch => {
  try {
    const myHeaders = new Headers();
    const customDomainApi = `${process.env.REACT_APP_CUSTOM_DOMAIN_API}`;

    const token = await user.getIdToken();
    myHeaders.append('x-access-token', token);
    myHeaders.append('Content-Type', 'application/json');

    const res = await fetch(customDomainApi, {
      method: 'DELETE',
      headers: myHeaders,
      body: JSON.stringify({
        appname,
        uid: user.uid,
      }),
      mode: 'cors',
    });

    // Handle response
    if (res.status === 200) {
      dispatch(domainRemoved());
      return dispatch(loadCustomDomain(appname));
    }
  } catch (error) {
    if (process.env.REACT_APP_PLATFORM_ENV !== 'production') {
      console.log('Error at removeCustomDomain', error);
    }
  }

  return null;
};

export const deletePlatformCustomDomain = (appname, user) => async dispatch => {
  try {
    const myHeaders = new Headers();
    const uid = user.uid;
    const customDomainApi = `${process.env.REACT_APP_CUSTOM_DOMAIN_API}/platform`;

    const token = await user.getIdToken();
    myHeaders.append('x-access-token', token);
    myHeaders.append('Content-Type', 'application/json');

    const res = await fetch(customDomainApi, {
      method: 'DELETE',
      headers: myHeaders,
      body: JSON.stringify({
        appname,
        uid,
      }),
      mode: 'cors',
    });

    // Handle response
    if (res.status == 200) {
      dispatch(domainRemoved());
      return dispatch(loadCustomDomain(appname));
    }
  } catch (error) {
    if (process.env.REACT_APP_PLATFORM_ENV !== 'production') {
      console.log('Error at removeCustomDomain', error);
    }
  }

  return null;
};
