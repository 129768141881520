import React from 'react';
import { useQuery } from 'react-query';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { ProgressOverlay } from '../../../../app/components/progress';
import TryAgain from '../../../../app/components/tryAgain';
import { Empty as EmptyIcon } from '../../../../app/components/icons';
import {
  StyledTableCell,
  StyledTableRow,
} from '../../../../app/components/table';

import {
  setFkAcademyUserId,
  toggleFkAcademyUserDetailsDialog,
  toggleFkAcademyUserDialog,
} from '../../actions';
import { getAllFkAcademyUsers } from '../../api';

import styles from './styles.module.scss';

function UsersTable({ match }) {
  const { appname } = match.params;
  const dispatch = useDispatch();

  const { data: { data: users } = {}, error, isLoading, isError } = useQuery(
    'appUsers',
    () => getAllFkAcademyUsers(appname),
    {
      enabled: !!appname,
    },
  );

  if (isLoading) return <ProgressOverlay />;

  if (isError) return <TryAgain message={error.message} />;

  if (!users)
    return (
      <div className="empty-state">
        <EmptyIcon />
        <h5>No users have joined yet.</h5>
      </div>
    );

  return (
    <TableContainer style={{ maxHeight: 650 }} component={Paper}>
      <Table stickyHeader aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>First Name</StyledTableCell>
            <StyledTableCell>Last Name</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Phone</StyledTableCell>
            <StyledTableCell>Approval Status</StyledTableCell>
            <StyledTableCell>Edit</StyledTableCell>
            <StyledTableCell>Details</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users &&
            users.length &&
            users.map(user => (
              <StyledTableRow key={user.key}>
                <StyledTableCell component="th" scope="row">
                  {user.firstName || '-'}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {user.lastName || '-'}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {user.email || 0}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {(user &&
                    user.fkAcademyUserType &&
                    user.fkAcademyUserType.toUpperCase()) ||
                    '-'}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {user.phoneNumber || '-'}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      user.fkAcademyApprovalStatus === 'granted'
                        ? 'green'
                        : !user.fkAcademyApprovalStatus
                        ? 'yellowgreen'
                        : 'darkred',
                    fontWeight: 'bold',
                  }}
                  component="th"
                  scope="row"
                >
                  {user.fkAcademyApprovalStatus || 'pending'}
                </StyledTableCell>

                <StyledTableCell component="th" scope="row">
                  <button
                    className={styles.button}
                    onClick={() => {
                      dispatch(setFkAcademyUserId(user.key));
                      dispatch(toggleFkAcademyUserDialog(true));
                    }}
                  >
                    EDIT
                  </button>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <button
                    className={styles.button}
                    onClick={() => {
                      dispatch(setFkAcademyUserId(user.key));
                      dispatch(toggleFkAcademyUserDetailsDialog(true));
                    }}
                  >
                    VIEW DETAILS
                  </button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default withRouter(UsersTable);
