import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCellMui from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles, withStyles } from '@material-ui/styles';
import { TreeView, TreeItem } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from 'material-ui/svg-icons/action/info';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import { Trans, useTranslation } from 'react-i18next';

import { CircularProgress } from '../../../../../../../app/components/progress';
import { objectToArray } from '../../../../../../../utils';
import Modal from '../../../components/Modal';
import Filters from '../../../components/Filters';

const TableCell = withStyles({
  head: {
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
})(TableCellMui);

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  center: {
    textAlign: 'center',
    width: '100%',
  },
  width60: {
    width: '60%',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  labelIcon: {
    marginRight: '1px',
  },
  labelText: {
    fontWeight: 'bold',
    flexGrow: 1,
    color: 'rgba(0, 0, 0, 0.54) !important',
  },
  PL25: {
    paddingLeft: '25px',
  },
});

const Subscribers = React.forwardRef(
  (
    {
      processingSubscribers,
      subscribersData,
      workforceGroups,
      eventsCoursesCpds,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [modalData, setModalData] = React.useState({});
    const [isOpen, setOpen] = React.useState(false);
    const [subscribers, setSubscribers] = React.useState(subscribersData);

    React.useEffect(() => {
      setSubscribers(subscribersData);
    }, [subscribersData]);

    let reportData = {
      event: { workforce: {} },
      course: { workforce: {} },
      cpd: { workforce: {} },
    };
    const getSize = (Size = {}, type, path) => {
      let obj = {};
      Object.values(Size).forEach(item => {
        const dataObj = item[path] || {};
        Object.values(dataObj).forEach(dataItem => {
          const dataItemRef = dataItem;
          dataItemRef.eventTitle = _.get(
            eventsCoursesCpds,
            `${type}.${dataItem.memberbody}.${dataItem.eventID}.
            -LuHSoQ5krd8oIDeSeTF
          .value`,
            '',
          );
        });
        obj = { ...obj, ...dataObj };
      });
      reportData = {
        ...reportData,
        ...{ [type]: { ...reportData[type], ...{ [path]: obj } } },
      };
      return _.size(obj);
    };

    // get all users for events
    const eventVisitors = getSize(subscribers.event, 'event', 'visitor');
    const eventUsers = getSize(subscribers.event, 'event', 'user');
    const eventWF = getSize(subscribers.event, 'event', 'workforce');

    // get all users for courses
    const courseVisitors = getSize(subscribers.course, 'course', 'visitor');
    const courseUsers = getSize(subscribers.course, 'course', 'user');
    const courseWF = getSize(subscribers.course, 'course', 'workforce');

    // get all users for cpds
    const cpdVisitors = getSize(subscribers.cpd, 'cpd', 'visitor');
    const cpdUsers = getSize(subscribers.cpd, 'cpd', 'user');
    const cpdWF = getSize(subscribers.cpd, 'cpd', 'workforce');

    const applyFilter = (obj, dates) =>
      _.pickBy(obj, val => {
        const dateToFilter = new Date(
          moment(val.created_at).format('YYYY/MM/DD'),
        ).getTime();
        if (dates.from && dates.to) {
          return (
            dateToFilter >= dates.from.getTime() &&
            dateToFilter <= dates.to.getTime()
          );
        } else if (dates.from) {
          return dateToFilter >= dates.from.getTime();
        } else if (dates.to) {
          return dateToFilter <= dates.to.getTime();
        }
        return true;
      });

    const onFilter = dates => {
      const clonedSubscribersData = _.cloneDeep(subscribersData);
      // filters for events/courses/cpd
      const moduleTypes = ['event', 'course', 'cpd'];
      const userTypes = ['user', 'workforce', 'visitor'];
      moduleTypes.forEach(type =>
        objectToArray(clonedSubscribersData[type]).forEach(item => {
          userTypes.forEach(userType => {
            clonedSubscribersData[type][item.id][userType] = applyFilter(
              item.value[userType],
              dates,
            );
          });
        }),
      );
      setSubscribers(clonedSubscribersData);
    };

    React.useImperativeHandle(ref, () => ({
      prepareReportData() {
        const rData = { data: [] };
        const types = ['Event', 'Course', 'CPD'];
        rData.columns = [
          t('Type'),
          t('Total Subscriptions'),
          t('By Visitors'),
          t('By Individual Users'),
          t('By Workforce Members'),
          t('Workforce Group Specific'),
          t('Total Subscriptions By Workforce Group'),
        ];
        types.forEach(type => {
          let totalIndividual = 0;
          let totalWF = 0;
          let totalVisitor = 0;
          if (type === 'Event') {
            totalIndividual = eventUsers;
            totalWF = eventWF;
            totalVisitor = eventVisitors;
          } else if (type === 'Course') {
            totalIndividual = courseUsers;
            totalWF = courseWF;
            totalVisitor = courseVisitors;
          } else if (type === 'CPD') {
            totalIndividual = cpdUsers;
            totalWF = cpdWF;
            totalVisitor = cpdVisitors;
          }
          objectToArray(workforceGroups).forEach(group => {
            rData.data.push([
              type,
              totalIndividual + totalWF + totalVisitor,
              totalVisitor,
              totalIndividual,
              totalWF,
              group.value.name,
              _.size(
                _.pickBy(reportData[type.toLowerCase()].workforce, {
                  groupID: group.id,
                }),
              ),
            ]);
          });
        });
        return [rData];
      },
    }));

    const prepareModalData = (e, itemType, userType, groupID) => {
      e.stopPropagation(true);
      const tableData = {
        columns: [
          { title: t('Event/Course/CPD'), key: 'eventTitle' },
          { title: t('Name'), key: 'name' },
          { title: t('Email'), key: 'email' },
          {
            title: t('Subscription Date'),
            key: 'created_at',
            render: value => moment(value).format('DD/MM/YYYY'),
          },
        ],
      };
      if (userType && groupID) {
        tableData.rows = Object.values(reportData[itemType][userType]).filter(
          i => i.groupID === groupID,
        );
      } else if (userType) {
        tableData.rows = Object.values(reportData[itemType][userType]);
      } else {
        let rows = [];
        Object.values(reportData[itemType]).forEach(item => {
          rows = [...rows, ...Object.values(item)];
        });
        tableData.rows = rows;
      }
      setModalData(tableData);
      setOpen(true);
    };
    return (
      <AccordionDetails>
        {processingSubscribers ? (
          <div className={classes.center}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <TableContainer>
            <Filters onFilter={onFilter} />
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Trans>Type</Trans>
                  </TableCell>
                  <TableCell align="left" className={classes.width60}>
                    <Trans>Additional Data</Trans>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Trans>Event</Trans>
                  </TableCell>
                  <TableCell align="left">
                    <TreeView
                      defaultCollapseIcon={<ArrowDropDownIcon />}
                      defaultExpandIcon={<ArrowRightIcon />}
                    >
                      <TreeItem
                        nodeId={`node-event`}
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Total')} (${eventVisitors +
                                eventUsers +
                                eventWF})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e => prepareModalData(e, 'event')}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      >
                        <TreeItem
                          nodeId={`node-event-visitor`}
                          className={classes.PL25}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('By Visitors')} (${eventVisitors})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(e, 'event', 'visitor')
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        />
                        <TreeItem
                          nodeId={`node-event-user`}
                          className={classes.PL25}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('By Individual Users')} (${eventUsers})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(e, 'event', 'user')
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        />
                        <TreeItem
                          nodeId={`node-event-wf`}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('By Workforce Members')} (${eventWF})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(
                                          e,
                                          'event',
                                          'workforce',
                                        )
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        >
                          {objectToArray(workforceGroups).map(group => (
                            <TreeItem
                              nodeId={`node-event-${group.id}`}
                              key={`node-event-${group.id}`}
                              label={
                                <div className={classes.labelRoot}>
                                  <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                  >
                                    {group.value.name} (
                                    {_.size(
                                      _.pickBy(reportData.event.workforce, {
                                        groupID: group.id,
                                      }),
                                    )}
                                    )
                                  </Typography>
                                  <Typography variant="caption" color="inherit">
                                    <Tooltip
                                      title={t('View Details')}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <InfoIcon
                                          color="#a2a2a2"
                                          style={{ height: 18, width: 18 }}
                                          onClick={e =>
                                            prepareModalData(
                                              e,
                                              'event',
                                              'workforce',
                                              group.id,
                                            )
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </div>
                              }
                            />
                          ))}
                        </TreeItem>
                      </TreeItem>
                    </TreeView>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Trans>Course</Trans>
                  </TableCell>
                  <TableCell align="left">
                    <TreeView
                      defaultCollapseIcon={<ArrowDropDownIcon />}
                      defaultExpandIcon={<ArrowRightIcon />}
                    >
                      <TreeItem
                        nodeId={`node-course`}
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Total')} (${courseVisitors +
                                courseUsers +
                                courseWF})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e => prepareModalData(e, 'course')}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      >
                        <TreeItem
                          nodeId={`node-course-visitor`}
                          className={classes.PL25}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('By Visitors')} (${courseVisitors})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(e, 'course', 'visitor')
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        />
                        <TreeItem
                          nodeId={`node-course-user`}
                          className={classes.PL25}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('By Individual Users')} (${courseUsers})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(e, 'course', 'user')
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        />
                        <TreeItem
                          nodeId={`node-course-wf`}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('By Workforce Members')} (${courseWF})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(
                                          e,
                                          'course',
                                          'workforce',
                                        )
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        >
                          {objectToArray(workforceGroups).map(group => (
                            <TreeItem
                              nodeId={`node-course-${group.id}`}
                              key={`node-course-${group.id}`}
                              label={
                                <div className={classes.labelRoot}>
                                  <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                  >
                                    {group.value.name} (
                                    {_.size(
                                      _.pickBy(reportData.course.workforce, {
                                        groupID: group.id,
                                      }),
                                    )}
                                    )
                                  </Typography>
                                  <Typography variant="caption" color="inherit">
                                    <Tooltip
                                      title={t('View Details')}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <InfoIcon
                                          color="#a2a2a2"
                                          style={{ height: 18, width: 18 }}
                                          onClick={e =>
                                            prepareModalData(
                                              e,
                                              'course',
                                              'workforce',
                                              group.id,
                                            )
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </div>
                              }
                            />
                          ))}
                        </TreeItem>
                      </TreeItem>
                    </TreeView>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CPD
                  </TableCell>
                  <TableCell align="left">
                    <TreeView
                      defaultCollapseIcon={<ArrowDropDownIcon />}
                      defaultExpandIcon={<ArrowRightIcon />}
                    >
                      <TreeItem
                        nodeId={`node-cpd`}
                        label={
                          <div className={classes.labelRoot}>
                            <Typography
                              variant="body2"
                              className={classes.labelText}
                            >
                              {`${t('Total')} (${cpdVisitors +
                                cpdUsers +
                                cpdWF})`}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                              <Tooltip
                                title={t('View Details')}
                                placement="top"
                              >
                                <IconButton>
                                  <InfoIcon
                                    color="#a2a2a2"
                                    style={{ height: 18, width: 18 }}
                                    onClick={e => prepareModalData(e, 'cpd')}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </div>
                        }
                      >
                        <TreeItem
                          nodeId={`node-cpd-visitor`}
                          className={classes.PL25}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('By Visitors')} (${cpdVisitors})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(e, 'cpd', 'visitor')
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        />
                        <TreeItem
                          nodeId={`node-cpd-user`}
                          className={classes.PL25}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('By Individual Users')} (${cpdUsers})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(e, 'cpd', 'user')
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        />
                        <TreeItem
                          nodeId={`node-cpd-wf`}
                          label={
                            <div className={classes.labelRoot}>
                              <Typography
                                variant="body2"
                                className={classes.labelText}
                              >
                                {`${t('By Workforce Members')} (${cpdWF})`}
                              </Typography>
                              <Typography variant="caption" color="inherit">
                                <Tooltip
                                  title={t('View Details')}
                                  placement="top"
                                >
                                  <IconButton>
                                    <InfoIcon
                                      color="#a2a2a2"
                                      style={{ height: 18, width: 18 }}
                                      onClick={e =>
                                        prepareModalData(e, 'cpd', 'workforce')
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                            </div>
                          }
                        >
                          {objectToArray(workforceGroups).map(group => (
                            <TreeItem
                              nodeId={`node-cpd-${group.id}`}
                              key={`node-cpd-${group.id}`}
                              label={
                                <div className={classes.labelRoot}>
                                  <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                  >
                                    {group.value.name} (
                                    {_.size(
                                      _.pickBy(reportData.cpd.workforce, {
                                        groupID: group.id,
                                      }),
                                    )}
                                    )
                                  </Typography>
                                  <Typography variant="caption" color="inherit">
                                    <Tooltip
                                      title={t('View Details')}
                                      placement="top"
                                    >
                                      <IconButton>
                                        <InfoIcon
                                          color="#a2a2a2"
                                          style={{ height: 18, width: 18 }}
                                          onClick={e =>
                                            prepareModalData(
                                              e,
                                              'cpd',
                                              'workforce',
                                              group.id,
                                            )
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                </div>
                              }
                            />
                          ))}
                        </TreeItem>
                      </TreeItem>
                    </TreeView>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Modal {...modalData} open={isOpen} onClose={() => setOpen(false)} />
      </AccordionDetails>
    );
  },
);
Subscribers.propTypes = {
  processingSubscribers: PropTypes.bool,
  subscribersData: PropTypes.object,
  workforceGroups: PropTypes.object,
  eventsCoursesCpds: PropTypes.object,
};

Subscribers.defaultProps = {
  processingSubscribers: false,
  subscribersData: {
    event: {},
    course: {},
    cpd: {},
  },
  workforceGroups: {},
  eventsCoursesCpds: null,
};

export default Subscribers;
