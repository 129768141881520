import { connect } from 'react-redux';

import TextEditor from './TextEditor';

import { textChange, alignChange } from './actions';

const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value, type) => {
    dispatch(
      textChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
        type,
      ),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TextEditor);
