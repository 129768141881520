import { combineReducers } from 'redux';

const initialState = {
  emailTypeList: [],
  emailType: null,
  pending: true,
  templateText: '',
  customEmail: {
    subject: '',
    fromEmail: '',
    topContent: '',
    bottomContent: '',
    mailTo: '',
    footerTeamName: '',
    headerImage: ''
  }
};

const customEmailReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'SAVE_CUSTOM_EMAIL_COMPLETE':
      return {
        ...state,
        pending: true,
      };  

    case 'SET_EMAIL_DATA':
      return {
        ...state,
        customEmail: action.emailData,
        pending: true
      };  

    case 'GET_EMAIL_LIST':
      return {
        ...state,
        emailTypeList: action.value,
        pending: true
      };

    case 'GET_TEMPLATE_TEXT':
      return {
        ...state,
        templateText: action.value,
      };  
      
    case 'SET_SUBJECT':
      return {
        ...state,
        customEmail: {
          ...state.customEmail,
          subject: action.value,
        },
        pending: false
      };

    case 'SET_HEADER_IMAGE':
      return {
        ...state,
        customEmail: {
          ...state.customEmail,
          headerImage: action.value,
        },
        pending: false
      };  

    case 'SET_MAIL_TO':
      return {
        ...state,
        customEmail: {
          ...state.customEmail,
          mailTo: action.value,
        },
        pending: false
      };

    case 'SET_FOOTER_TEAM_NAME':
      return {
        ...state,
        customEmail: {
          ...state.customEmail,
          footerTeamName: action.value,
        },
        pending: false
      };

    // case 'SET_FROM_EMAIL':
    //   return {
    //     ...state,
    //     customEmail: {
    //       ...state.customEmail,
    //       fromEmail: action.value,
    //     },
    //     pending: false
    //   };  

    case 'SET_MESSAGE':
      return {
        ...state,
        customEmail: {
          ...state.customEmail,
          [action.contentType]: action.value,
        },
        pending: false
      };    
      
    case 'SET_EMAIL_TYPE':
      return {
        ...state,
        emailType: action.emailType,
      };

      default:
      return state;
  }
};

export default combineReducers({
  data: customEmailReducer,
});
