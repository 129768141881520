import firebase from '../../../../../../firebase';
import { objectToArray } from '../../../../../../utils';

export const REQUEST_WORKFORCE_MEMBER_DETAILS =
  'REQUEST_WORKFORCE_MEMBER_DETAILS';
export const REQUEST_WORKFORCE_MEMBER_FIELD_UPDATE =
  'REQUEST_WORKFORCE_MEMBER_FIELD_UPDATE';
export const RECEIVE_WORKFORCE_MEMBER_FIELD_UPDATE =
  'RECEIVE_WORKFORCE_MEMBER_FIELD_UPDATE';
export const RECEIVE_SAVED_GROUP_DATA = 'RECEIVE_SAVED_GROUP_DATA';
export const RECEIVE_WORKFORCE_MEMBER_DETAILS =
  'RECEIVE_WORKFORCE_MEMBER_DETAILS';

const request = () => ({
  type: REQUEST_WORKFORCE_MEMBER_DETAILS,
});

const requestUpdateField = () => ({
  type: REQUEST_WORKFORCE_MEMBER_FIELD_UPDATE,
});

export const sendNotification = async (user, profile, status, appName) => {
  const auth = firebase.auth().currentUser;
  const myHeaders = new Headers();

  const token = await auth.getIdToken();
  myHeaders.append('x-access-token', token);
  myHeaders.append('Content-Type', 'application/json');
  return fetch(`${process.env.REACT_APP_WORKFORCE_API}/notification`, {
    method: 'post',
    headers: myHeaders,
    body: JSON.stringify({
      user,
      profile,
      appName,
      source: 'platform',
      status,
      type: 'CCAgreementAction',
    }),
    mode: 'cors',
  });
};

export const updateGroupName = (app, memberID, name, value) => dispatch => {
  dispatch(requestUpdateField());
  const update = {
    [name]: value,
    updated_at: Date.now(),
  };
  firebase
    .database()
    .ref(`app_users/${app}/${memberID}`)
    .update(update)
    .then(() => {
      dispatch({
        type: RECEIVE_WORKFORCE_MEMBER_FIELD_UPDATE,
        data: update,
      });
    });
  if (name === 'codeofConduct') {
    sendNotification(memberID, 'Code of Conduct', value.status, app);
  }
};

export const updateField = (
  app,
  memberID,
  name,
  fieldID,
  value,
) => dispatch => {
  dispatch(requestUpdateField());
  const update = {
    [name]: value,
    updatedAt: Date.now(),
  };
  firebase
    .database()
    .ref(`workforce_groups/${app}/${memberID}/fields/${fieldID}`)
    .update(update)
    .then(() => {
      dispatch({
        type: RECEIVE_WORKFORCE_MEMBER_FIELD_UPDATE,
        data: update,
      });
    });
};

export const getGroupsDetails = (app, memberID, groupID) => dispatch => {
  dispatch(request());
  firebase
    .database()
    .ref(`workforce_groups_data/${app}/${groupID}/${memberID}`)
    .on('value', snap => {
      dispatch({
        type: RECEIVE_SAVED_GROUP_DATA,
        value: snap.exists() ? snap.val() : [],
        groupID,
      });
    });
};

export const getMemberDetails = (app, memberID) => dispatch => {
  dispatch(request());
  firebase
    .database()
    .ref(`app_users/${app}/${memberID}`)
    .on('value', snap => {
      const value = snap.exists() ? snap.val() : [];
      dispatch({
        type: RECEIVE_WORKFORCE_MEMBER_DETAILS,
        value,
      });
      if (snap.exists()) {
        const groups = value.workforceGroups
          ? objectToArray(value.workforceGroups)
          : [];
        groups.forEach(groupData =>
          dispatch(getGroupsDetails(app, memberID, groupData.id)),
        );
      }
    });
};
