import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import App from './App';
import {
  setOfferEligibilityForReturningUser,
  startListeningToAuthChange,
  saveOfferFromQueryString,
  getPlatformTranslation,
} from '../modules/account/modules/auth/login/actions';
import { loadOffers } from '../modules/account/modules/register/offer/actions';

const mapStateToProps = state => ({
  appColor: state.app.appPreference.appColor,
  auth: state.account.auth,
  account: state.account.organisation,
  role: state.account.auth.profile.role,
  offers: state.account.register.offers,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
  appname: state.app.appContext.appname,
  isSubAdminWithMulipleApps:
    (state.account.auth.profile.admin_appnames &&
      Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
    false,
});

const mapDispatchToProps = dispatch => ({
  startListeningToAuthChange: callback =>
    dispatch(startListeningToAuthChange(callback)),
  saveOfferFromQueryString: offer => dispatch(saveOfferFromQueryString(offer)),
  getPlatformTranslation: accountId =>
    dispatch(getPlatformTranslation(accountId)),
  loadOffers: () => dispatch(loadOffers()),
  setOfferEligibilityForReturningUser: () =>
    dispatch(setOfferEligibilityForReturningUser()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
