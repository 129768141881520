import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import Toggle from 'material-ui/Toggle';
import Divider from 'material-ui/Divider';

import SectionHeader from '../sectionHeader';
import TextField from '../../../../app/components/textField';
import themeStyles from '../../../../_export.scss';
const styles = {
  divider: {
    width: '100%',
    height: '2px',
    marginTop: '20px',
  },
};

const TitleSetter = ({
  displayTitle,
  onToggleTitleDisplay,
  onChangeTitle,
  title,
  typeOfComponent,
  required,
  isValid,
  errorText,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ width: '100%' }}>
      <SectionHeader
        title={`${typeOfComponent.charAt(0).toUpperCase() +
          typeOfComponent.slice(1)} ${t('name')}`}
        tooltip={`${t('Add a')} ${typeOfComponent} ${t('name')}.`}
      />
      <TextField
        id="vote-title"
        hintText={`${t('Add the title for your')} ${typeOfComponent}`}
        tooltip={`${t('Add the title for your')} ${typeOfComponent}`}
        onChange={onChangeTitle}
        value={title}
        required={displayTitle ? required : false}
        isValid={isValid}
        errorText={errorText}
      />
      <SectionHeader
        title={`${t('Display')} ${typeOfComponent} ${t('name')}`}
        className="section-header"
        actions={
          <Toggle
            toggled={displayTitle}
            onToggle={(e, isToggled) => onToggleTitleDisplay(isToggled)}
            trackSwitchedStyle={{
              backgroundColor: themeStyles.primaryColorLight,
            }}
            thumbSwitchedStyle={{ backgroundColor: themeStyles.primaryColor }}
          />
        }
      />
      <Divider style={styles.divider} />
    </div>
  );
};

TitleSetter.propTypes = {
  displayTitle: PropTypes.bool.isRequired,
  onToggleTitleDisplay: PropTypes.func.isRequired,
  onChangeTitle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  typeOfComponent: PropTypes.string.isRequired,
  required: PropTypes.bool,
  errorText: PropTypes.string,
};

TitleSetter.defaultProps = {
  required: true,
  errorText: '',
};

export default TitleSetter;
