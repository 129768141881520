import { connect } from 'react-redux';

import PaypalSetting from './PaypalSetting';
import { loadPaypalData, saveChanges, updatePaypalData } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...state.editor.shared.paypal[ownProps.match.params.appname],
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
  isSubAdminWithMulipleApps:
    (state.account.auth.profile.admin_appnames &&
      Object.keys(state.account.auth.profile.admin_appnames).length > 1) ||
    false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData: () => dispatch(loadPaypalData(ownProps.match.params.appname)),
  updateValue: (attr, value) =>
    dispatch(updatePaypalData(ownProps.match.params.appname, attr, value)),
  onSubmit: paypal => {
    dispatch(saveChanges(ownProps.match.params.appname, paypal));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaypalSetting);
