import firebase from '../../../../../../../firebase';
import { validateField } from '../../../validators';
import { objectToArray } from '../../../../../../../utils';

export const EDIT_MEMBER_BODY_FIELD_CHANGE = 'EDIT_MEMBER_BODY_FIELD_CHANGE';
export const CLOSE_EDIT_MEMBER_BODY_DIALOG = 'CLOSE_EDIT_MEMBER_BODY_DIALOG';
export const MEMBER_BODY_UPDATE_REQUEST = 'MEMBER_BODY_UPDATE_REQUEST';
export const MEMBER_BODY_UPDATED = 'MEMBER_BODY_UPDATED';
export const LOADING_MEMBER_BODY = 'LOADING_MEMBER_BODY';
export const LOADED_MEMBER_BODY_DETAILS = 'LOADED_MEMBER_BODY_DETAILS';
export const EDIT_MEMBER_BODY_STAFF_FIELD_CHANGE =
  'EDIT_MEMBER_BODY_STAFF_FIELD_CHANGE';
export const MEMBER_BODY_RESET = 'MEMBER_BODY_RESET';

const fieldChange = (prop, value, isValid) => ({
  type: EDIT_MEMBER_BODY_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

const editMemberBodyStaffFieldChange = (prop, value, staffID) => ({
  type: EDIT_MEMBER_BODY_STAFF_FIELD_CHANGE,
  prop,
  value,
  staffID,
});
export const closeDialog = () => ({ type: CLOSE_EDIT_MEMBER_BODY_DIALOG });

export const resetData = () => ({
  type: MEMBER_BODY_RESET,
});

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;
  return dispatch(fieldChange(prop, value, isValid));
};

export const formFieldChangeNonRequired = (
  prop,
  value,
  staffID,
) => dispatch => {
  const isValid = true;
  if (prop === 'staffMembers') {
    return dispatch(editMemberBodyStaffFieldChange(prop, value, staffID));
  }
  return dispatch(fieldChange(prop, value, isValid));
};

export const formStaffFieldChangeNonRequired = (prop, value) => (
  dispatch,
  getState,
) => {
  const { staffMembers } = getState().auth.memberBodies.editMemberBody;
  if (staffMembers[prop] && value === false) {
    delete staffMembers[prop];
  } else if (value === true) {
    staffMembers[prop] = value;
  }
  return dispatch(editMemberBodyStaffFieldChange('staffMembers', staffMembers));
};

export const formFieldUniqueCheck = (
  prop,
  value,
  filteredChunks,
  memberBody,
) => dispatch => {
  let isValid = true;
  if (value !== '') {
    Object.keys(filteredChunks).forEach(key => {
      if (
        key !== memberBody &&
        filteredChunks[key].name &&
        filteredChunks[key].name === value
      ) {
        isValid = false;
      }
    });
  } else {
    isValid = null;
  }
  return dispatch(fieldChange(prop, value, isValid));
};

export const loadingMemberBody = () => ({
  type: LOADING_MEMBER_BODY,
});

export const loadMemberBody = prop => ({
  type: LOADED_MEMBER_BODY_DETAILS,
  prop,
});

export const closeModal = (app, history) => dispatch => {
  dispatch(closeDialog());
  dispatch({
    type: MEMBER_BODY_RESET,
  });
  history.push(`/${app}/members/member-bodies`);
};

export const getMemberBodyDetails = (appname, memberBody) => async dispatch => {
  dispatch(loadingMemberBody());
  const memberBodyRef = firebase
    .database()
    .ref(`member_bodies/${appname}/${memberBody}`);
  return memberBodyRef.on('value', snapshot => {
    if (snapshot.exists()) {
      const data = snapshot.val();
      const finalData = {
        staffMembers: data.staffMembers || {},
        name: { value: data.name, isValid: true },
        postalAddress: { value: data.postalAddress, isValid: true },
        postCode: { value: data.postCode, isValid: true },
        adminUsername: { value: data.adminUsername, isValid: true },
        adminEmail: { value: data.adminEmail, isValid: true },
        primaryContactName: {
          value: data.primaryContactName,
          isValid: true,
        },
        primaryContactEmail: {
          value: data.primaryContactEmail,
          isValid: true,
        },
        primaryContactNumber: {
          value: data.primaryContactNumber,
          isValid: true,
        },
        secondaryContactName: {
          value: data.secondaryContactName,
          isValid: true,
        },
        secondaryContactEmail: {
          value: data.secondaryContactEmail,
          isValid: true,
        },
        secondaryContactNumber: {
          value: data.secondaryContactNumber,
          isValid: true,
        },
        landline: { value: data.landline, isValid: true },
        mobile: { value: data.mobile, isValid: true },
        memberNumber: { value: data.memberNumber, isValid: true },
        memberNumberMale: {
          value: data.memberNumberMale,
          isValid: true,
        },
        memberNumberFemale: {
          value: data.memberNumberFemale,
          isValid: true,
        },
        memberNumberDisabled: {
          value: data.memberNumberDisabled,
          isValid: true,
        },
        memberNumberSmid: {
          value: data.memberNumberSmid,
          isValid: true,
        },
        loading: false,
        banner: data.banner || '',
      };
      dispatch(loadMemberBody(finalData));
    }
  });
};

export const updateMemberBody = (memberBodyID, history, appname) => (
  dispatch,
  getState,
) => {
  dispatch({ type: MEMBER_BODY_UPDATE_REQUEST });
  const {
    name,
    postalAddress,
    postCode,
    adminUsername,
    adminEmail,
    primaryContactName,
    primaryContactEmail,
    primaryContactNumber,
    secondaryContactName,
    secondaryContactEmail,
    secondaryContactNumber,
    landline,
    mobile,
    memberNumber,
    memberNumberMale,
    memberNumberFemale,
    memberNumberDisabled,
    memberNumberSmid,
    staffMembers,
  } = getState().auth.memberBodies.editMemberBody;

  const selectedStaffsArray = objectToArray(staffMembers);

  Object.keys(staffMembers).forEach(staffID => {
    if (!staffMembers[staffID]) {
      delete staffMembers[staffID];
    }
  });

  const update = {
    name: name.value,
    postalAddress: postalAddress.value,
    postCode: postCode.value,
    adminUsername: adminUsername.value,
    adminEmail: adminEmail.value,
    primaryContactName: primaryContactName.value,
    primaryContactEmail: primaryContactEmail.value,
    primaryContactNumber: primaryContactNumber.value,
    secondaryContactName: secondaryContactName.value,
    secondaryContactEmail: secondaryContactEmail.value,
    secondaryContactNumber: secondaryContactNumber.value,
    landline: landline.value,
    mobile: mobile.value,
    memberNumber: memberNumber.value,
    memberNumberMale: memberNumberMale.value,
    memberNumberFemale: memberNumberFemale.value,
    memberNumberDisabled: memberNumberDisabled.value,
    memberNumberSmid: memberNumberSmid.value,
    staffMembers: staffMembers || {},
    lastUpdated: Date.now(),
  };
  return firebase
    .database()
    .ref(`member_bodies/${appname}/${memberBodyID}`)
    .update(update)
    .then(() => {
      // update memberbody in staff members account
      Promise.all(
        selectedStaffsArray.map(staff =>
          staff.value
            ? firebase
                .database()
                .ref(`users/${staff.id}`)
                .child('memberBodies')
                .update({ [memberBodyID]: true })
            : firebase
                .database()
                .ref(`users/${staff.id}`)
                .child('memberBodies')
                .child(memberBodyID)
                .set(null),
        ),
      ).then(() => {
        dispatch({
          type: MEMBER_BODY_UPDATED,
          key: memberBodyID,
          data: update,
        });
        dispatch(closeModal(appname, history));
      });
    });
};
