import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';

import SelectField from '../../../../../../../../app/components/selectField';
import { FlatButton } from '../../../../../../../../app/components/buttons';
import CircularValidation from '../../../../../../../../app/components/circularValidation';
import { CircularProgress } from '../../../../../../../../app/components/progress';
import { objectToArray } from '../../../../../../../../utils';
import FormFields from '../../components/form';

import './styles.css';

const CreateGroup = ({
  open,
  pending,
  error,
  onSubmit,
  onRequestClose,
  type,
  onFieldChange,
  groups,
  fieldsData,
  groupChange,
  workforceGroups,
  deleteFile,
}) => {
  const [selectedID, setValue] = React.useState('');
  const groupsArray = objectToArray(groups);
  const displayError = error ? (
    <div>
      <strong>{error.message}</strong>
    </div>
  ) : null;

  const fieldOptions = groupsArray.map(data => (
    <MenuItem
      key={data.id}
      value={data.id}
      primaryText={data.value.name}
      disabled={workforceGroups[data.id]}
    />
  ));

  const onTypeChange = id => {
    setValue(id);
    const defaultFieldValues = objectToArray(groups[id].fields).map(i => ({
      [i.id]: { value: '', isValid: i.value.isRequired ? null : true },
    }));
    groupChange('type', id, Object.assign({}, ...defaultFieldValues));
  };

  const form = (
    <div>
      <h5>Add a Group</h5>
      <SelectField
        className="offset-top"
        options={fieldOptions}
        label="Select Group"
        value={selectedID}
        onChange={(i, payload) => onTypeChange(payload)}
        autoWidth
        rightInputAction={
          <CircularValidation
            isValid={type.isValid}
            defaultMsg="Type type is required"
            errorMsg="Type type is required"
            showDefault={!type.value}
          />
        }
      />
      <FormFields
        {...{ type, onFieldChange, groups, fieldsData, deleteFile }}
      />
    </div>
  );

  const buttonSubmit = !pending ? (
    <FlatButton
      label={'Create'}
      onClick={onSubmit}
      disabled={
        !type.isValid || !!objectToArray(fieldsData).find(i => !i.value.isValid)
      }
      primary
    />
  ) : (
    <CircularProgress size={18} />
  );
  return (
    <Dialog open={open} onRequestClose={onRequestClose} autoScrollBodyContent>
      <div className="add-field">
        <div className="modal-controls-container">
          <IconButton onClick={onRequestClose}>
            <Close />
          </IconButton>
        </div>
        <div className="list-fieldset">{displayError}</div>
        {form}
        {buttonSubmit}
      </div>
    </Dialog>
  );
};

CreateGroup.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  type: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  onFieldChange: PropTypes.func.isRequired,
  groups: PropTypes.object.isRequired,
  fieldsData: PropTypes.object.isRequired,
  groupChange: PropTypes.func.isRequired,
  workforceGroups: PropTypes.object.isRequired,
  deleteFile: PropTypes.func.isRequired,
};

CreateGroup.defaultProps = {
  error: null,
  workforceGroups: {},
  deleteFile: null,
};

export default CreateGroup;
