import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CTAButton } from '../../../../app/components/buttons';
import UpgradeButton from '../../../../app/modules/upgradeButton';
import { PLANS_WITH_ADDONS } from '../../../../plans/constants';

// const definePanelMessage = () => {
//   const {
//     error,
//     title,
//     message,
//     enabled,
//     isSubjectToCredit,
//     credit,
//     integration,
//   } = this.props;

//   if (isSubjectToCredit && credit < 1) {
//     return (
//       <span>
//         Upgrade to the {integration ? 'premium' : 'accelerate'} plan to receive
//         unlimited push notifications.
//       </span>
//     );
//   }

//   if (error) return <span>{error}</span>;

//   if (!enabled || !title.isValid || !message.isValid) {
//     return <span>Write what you want to say and hit ‘send’.</span>;
//   }

//   return (
//     <span>
//       The message will appear in the notification area of the app and users who
//       have ‘allowed’ notifications will receive a pop up message on their
//       device.
//     </span>
//   );
// };

const PushButton = ({
  link,
  enabled,
  isSubjectToCredit,
  credit,
  pending,
  title,
  message,
  onSubmit,
  diyComponents,
  subscription,
}) => {
  const { t } = useTranslation();

  const isDiyPlan = () => {
    return (
      subscription &&
      subscription.plan &&
      PLANS_WITH_ADDONS.includes(subscription.plan.toLowerCase())
    );
  };

  const hasAddon = addon => {
    const allAddons = diyComponents ? Object.keys(diyComponents) : {};
    return diyComponents ? allAddons.includes(addon) : false;
  };

  const noCreditsLeft = () => {
    if (isDiyPlan() && hasAddon('Notification')) {
      return false;
    }
    return isSubjectToCredit && credit < 1;
  };
  const isLinkInvalid = link.enabled && link.type === 'web' && !link.webValid;
  const isVideoInvalid =
    link.enabled && link.type === 'video' && !link.videoValid;
  const disableBtn =
    !enabled ||
    isLinkInvalid ||
    isVideoInvalid ||
    (isDiyPlan() && hasAddon('Notification')
      ? false
      : isSubjectToCredit && credit === 0) ||
    pending ||
    !title.isValid ||
    !message.isValid;
  const sendButton = (
    <CTAButton
      label={t('Send')}
      height={35}
      minWidth={100}
      onClick={onSubmit}
      disabled={disableBtn}
    />
  );
  const upgradeButton = (
    <UpgradeButton
      diyComponents={diyComponents}
      hidePlanName
      position="push-notifications"
      noPlanTitle
    />
  );
  return noCreditsLeft() ? upgradeButton : sendButton;
};

PushButton.propTypes = {
  link: PropTypes.object.isRequired,
  enabled: PropTypes.bool.isRequired,
  isSubjectToCredit: PropTypes.bool.isRequired,
  credit: PropTypes.number.isRequired,
  pending: PropTypes.bool.isRequired,
  title: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PushButton;
