import { connect } from 'react-redux';

import Accessibility from './Accessibility';
import { updateAccessibilitySettings } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...state.editor.shared.accessibility
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateAccessibilitySettings: (field, value) =>
    dispatch(updateAccessibilitySettings(ownProps.match.params.appname, field, value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Accessibility);
