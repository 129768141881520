/* eslint-disable no-restricted-syntax */
import queryString from 'query-string';
import { objectToArray } from '../../../../utils';
import { validateField } from '../../../memberLogin/modules/memberBodies/validators';
import firebase from '../../../../firebase';

export const TOGGLE_REGIONAL_PERFORMANCE_CENTRE_MODAL =
  'TOGGLE_REGIONAL_PERFORMANCE_CENTRE_MODAL';
export const NEW_REGIONAL_PERFORMANCE_FIELD_CHANGE =
  'NEW_REGIONAL_PERFORMANCE_FIELD_CHANGE';
export const ADD_NEW_REGIONAL_PERFORMANCE_CENTRE =
  'ADD_NEW_REGIONAL_PERFORMANCE_CENTRE';
export const NEW_REGIONAL_PERFORMANCE_CENTRE_ADDED =
  'NEW_REGIONAL_PERFORMANCE_CENTRE_ADDED';
export const REQUEST_REGIONAL_PERFORMANCE_CENTRE =
  'REQUEST_REGIONAL_PERFORMANCE_CENTRE';
export const RECEIVE_REGIONAL_PERFORMANCE_CENTRE =
  'RECEIVE_REGIONAL_PERFORMANCE_CENTRE';
export const SELECT_ALL_PERFORMANCE_CENTRES = 'SELECT_ALL_PERFORMANCE_CENTRES';
export const DESELECT_ALL_PERFORMANCE_CENTRES =
  'DESELECT_ALL_PERFORMANCE_CENTRES';
export const SELECT_PERFORMANCE_CENTRE = 'SELECT_PERFORMANCE_CENTRE';
export const FILTER_PERFORMANCE_CENTRE_DATA = 'FILTER_PERFORMANCE_CENTRE_DATA';
export const OPEN_REGIONAL_PERFORMANCE_CENTRE_DIALOG_UPDATE_DATA =
  'OPEN_REGIONAL_PERFORMANCE_CENTRE_DIALOG_UPDATE_DATA';

export const NEW_REGIONAL_PERFORMANCE_SERVICES_INFO_FIELD_CHANGE =
  'NEW_REGIONAL_PERFORMANCE_SERVICES_INFO_FIELD_CHANGE';

export const TOGGLE_REGIONAL_SNACKBAR = 'TOGGLE_REGIONAL_SNACKBAR';
export const RESET_REGIONAL_PERFORMANCE_SERVICES_INFO_FIELD =
  'RESET_REGIONAL_PERFORMANCE_SERVICES_INFO_FIELD';

export const togglePerformanceModal = value => ({
  type: TOGGLE_REGIONAL_PERFORMANCE_CENTRE_MODAL,
  value,
});

const newRegionalPerformanceFieldChange = (prop, value, isValid) => ({
  type: NEW_REGIONAL_PERFORMANCE_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

const newRegionalPerformanceServiceInfoFieldChange = (
  fieldType,
  field,
  value,
  isValid,
) => ({
  type: NEW_REGIONAL_PERFORMANCE_SERVICES_INFO_FIELD_CHANGE,
  fieldType,
  field,
  value,
  isValid,
});

export const selectPerformanceCentre = key => ({
  type: SELECT_PERFORMANCE_CENTRE,
  key,
});

export const selectAllPerformanceCentres = () => ({
  type: SELECT_ALL_PERFORMANCE_CENTRES,
});
export const deselectAllPerformanceCentres = () => ({
  type: DESELECT_ALL_PERFORMANCE_CENTRES,
});

export const requestRegionalPerformanceCentres = appname => ({
  type: REQUEST_REGIONAL_PERFORMANCE_CENTRE,
  appname,
});

export const filterPerformanceCentreData = filter => dispatch =>
  dispatch({
    type: FILTER_PERFORMANCE_CENTRE_DATA,
    payload: filter,
  });

export const formFieldUniqueCheck = (
  prop,
  value,
  filteredChunks,
) => dispatch => {
  const dataToCheck = objectToArray(filteredChunks);

  const isValid = !dataToCheck.find(
    i => i.value.location.toLowerCase() === value.toLowerCase(),
  );

  return dispatch(newRegionalPerformanceFieldChange(prop, value, isValid));
};

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;
  return dispatch(newRegionalPerformanceFieldChange(prop, value, isValid));
};

export const multipleValueFieldChange = (prop, value) => (
  dispatch,
  getState,
) => {
  const services1 = [
    'nutrition',
    'physiotherapy',
    'pitch_session',
    'rehab_facilities',
    'soft_tissue_therapy',
    'strength_and_conditioning',
  ];

  const services2 = value.map(serv => serv.value);
  const resultArray = services1.filter(service => !services2.includes(service));

  resultArray.forEach(serviceName => {
    dispatch({
      type: RESET_REGIONAL_PERFORMANCE_SERVICES_INFO_FIELD,
      fieldType: serviceName,
    });
  });

  return dispatch(newRegionalPerformanceFieldChange(prop, value, true));
};

export const servicesInfoFieldChange = (type, field, value) => dispatch => {
  // if (field.includes('_email') || field.includes('_phone')) {
  //   tempValue = value && value.trim().replace(/\s/g, '');
  // }

  return dispatch(
    newRegionalPerformanceServiceInfoFieldChange(type, field, value, true),
  );
};

export const getRegionalPerformanceCentre = appname => dispatch => {
  dispatch(requestRegionalPerformanceCentres(appname));
  firebase
    .database()
    .ref(`pgmol/regional_performance_centre/${appname}`)
    .on('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_REGIONAL_PERFORMANCE_CENTRE,
          value: snap.val(),
        });
      } else {
        dispatch({
          type: RECEIVE_REGIONAL_PERFORMANCE_CENTRE,
          value: [],
        });
      }
    });
};

export const addRegionPerformanceCentre = appname => (dispatch, getState) => {
  const {
    location,
    address,
    address_lat,
    address_lng,
    services,
    servicesInfo,
    images,
    isUpdate,
    rowId,
    additional_info,
  } = getState().pgmol.regionalPerformanceCentre;

  const centrePayload = {
    location: location.value,
    address: address.value,
    address_lat: address_lat.value,
    address_lng: address_lng.value,
    services: services.value,
    servicesInfo: servicesInfo.value,
    images: images.value,
    date: Date.now(),
    additional_info: additional_info.value,
  };

  let isError = false;

  if (!centrePayload.address.length) {
    isError = true;
    dispatch(newRegionalPerformanceFieldChange('address', '', false));
  }
  if (!services.value.length) {
    isError = true;
    dispatch(newRegionalPerformanceFieldChange('services', [], false));
  }

  for (const service of services.value) {
    const { value } = service;

    if (!servicesInfo.value[value].contact_1_name.value.length) {
      isError = true;
      dispatch(
        newRegionalPerformanceServiceInfoFieldChange(
          value,
          'contact_1_name',
          '',
          false,
        ),
      );
    }
    if (!servicesInfo.value[value].contact_1_phone.value.length) {
      isError = true;
      dispatch(
        newRegionalPerformanceServiceInfoFieldChange(
          value,
          'contact_1_phone',
          '',
          false,
        ),
      );
    }
    if (!servicesInfo.value[value].contact_1_email.value.length) {
      isError = true;
      dispatch(
        newRegionalPerformanceServiceInfoFieldChange(
          value,
          'contact_1_email',
          '',
          false,
        ),
      );
    }
    if (!servicesInfo.value[value].timetable.value.length) {
      isError = true;
      dispatch(
        newRegionalPerformanceServiceInfoFieldChange(
          value,
          'timetable',
          '',
          false,
        ),
      );
    }
    if (servicesInfo.value[value].contact_2_name.value.length) {
      if (!servicesInfo.value[value].contact_2_phone.value.length) {
        isError = true;
        dispatch(
          newRegionalPerformanceServiceInfoFieldChange(
            value,
            'contact_2_phone',
            '',
            false,
          ),
        );
      }
      if (!servicesInfo.value[value].contact_2_email.value.length) {
        isError = true;
        dispatch(
          newRegionalPerformanceServiceInfoFieldChange(
            value,
            'contact_2_email',
            '',
            false,
          ),
        );
      }
    }
  }

  if (isError) {
    return dispatch({ type: 'TOGGLE_REGIONAL_SNACKBAR' });
  }

  dispatch({ type: ADD_NEW_REGIONAL_PERFORMANCE_CENTRE });
  if (isUpdate) {
    return firebase
      .database()
      .ref(`pgmol/regional_performance_centre/${appname}/${rowId}`)
      .update(centrePayload)
      .then(() => {
        const newCentreID = rowId;
        dispatch({
          type: NEW_REGIONAL_PERFORMANCE_CENTRE_ADDED,
          key: newCentreID,
          data: centrePayload,
        });
        // reset temporary image
        firebase
          .database()
          .ref(
            `pgmol/tempImages/regional-performance-centre/${appname}/${location.value}`,
          )
          .set(null);
      });
  }
  return firebase
    .database()
    .ref(`pgmol/regional_performance_centre/${appname}`)
    .push(centrePayload)
    .then(ref => {
      const newCentreID = ref.key;
      dispatch({
        type: NEW_REGIONAL_PERFORMANCE_CENTRE_ADDED,
        key: newCentreID,
        data: centrePayload,
      });
      // reset temporary image
      firebase
        .database()
        .ref(
          `pgmol/tempImages/regional-performance-centre/${appname}/${location.value}`,
        )
        .set(null);
    });
};

export const deletePerformanceCentre = (appname, centerId) => {
  if (centerId !== 'default') {
    firebase
      .database()
      .ref(`pgmol/regional_performance_centre/${appname}/${centerId}`)
      .remove();
  }
};

export const deletePerformanceCentres = (appname, selectedRowId) => (
  dispatch,
  getState,
) => {
  const { selected } = getState().pgmol.regionalPerformanceCentre;
  if (!selected.length && selectedRowId.length) {
    Promise.all(
      selectedRowId.map(key => deletePerformanceCentre(appname, key)),
    );
  } else {
    Promise.all(selected.map(key => deletePerformanceCentre(appname, key)));
  }
};

export const updateRegionalPerformanceCentre = (rowId, data) => dispatch => {
  dispatch({
    type: OPEN_REGIONAL_PERFORMANCE_CENTRE_DIALOG_UPDATE_DATA,
    key: rowId,
    data,
  });
};

export const fetchRegionalCentreImages = (dbRef, appname, location) => async (
  dispatch,
  getState,
) => {
  const { images } = getState().pgmol.regionalPerformanceCentre;
  const pgmolRef = firebase.database().ref(`${dbRef}`);
  return pgmolRef.once('value', snapshot => {
    // dispatch(loadBanner(snapshot.exists ? snapshot.val() : ''));
    let value = [...images.value];
    if (snapshot.exists()) {
      let temp = { ...snapshot.val() };
      if (temp['mainImage']) {
        delete temp['mainImage'];
      }
      Object.keys(temp).forEach(imgKey =>
        value.push({ key: imgKey, url: temp[imgKey].image }),
      );

      firebase
        .database()
        .ref(
          `pgmol/tempImages/regional-performance-centre/${appname}/${location}`,
        )
        .set(null);

      return dispatch(newRegionalPerformanceFieldChange('images', value, true));
    }
    // return dispatch(newRegionalPerformanceFieldChange('images', [], true));
  });
};

export const deleteRegionalPerformanceCentreImage = (
  src,
  appname,
  rowId,
  index,
) => async (dispatch, getState) => {
  // console.log({ src, appname, rowId, index }, 'sjd123');
  const firebaseUrl = `/pgmol/regional_performance_centre/${appname}/${rowId}/images`;

  const images = getState().pgmol.regionalPerformanceCentre.images.value;


  const myHeaders = new Headers();
  const user = firebase.auth().currentUser;
  const filename = src.replace(/https:\/\/.*\.com/, '');
  const query = queryString.stringify({
    filename,
    firebaseUrl,
    removeFromFirebase: false,
  });
  dispatch({ type: 'GALLERY_IMAGE_DELETE_REQUEST' });

  return new Promise((resolve, reject) =>
    user
      .getIdToken()
      .then(token => {
        myHeaders.append('x-access-token', token);

        return fetch(`${process.env.REACT_APP_IMAGES_API}/delete?${query}`, {
          method: 'delete',
          headers: myHeaders,
          mode: 'cors',
        });
      })
      .then(res => {
        if (res.status !== 200) {
          throw new Error('Failed to delete image, please try again');
        }

        dispatch({ type: 'GALLERY_IMAGE_DELETED' });
        images.splice(index, 1);
        dispatch(newRegionalPerformanceFieldChange('images', images, true));
        resolve();
      })
      .catch(error => {
        // dispatch(reportError('Something went wrong, please try again.'));
        // dispatch(closeDeleteDialog());
        reject(error);
      }),
  );
};

export const toggleRegionalSnackbar = () => dispatch => {
  return dispatch({ type: 'TOGGLE_REGIONAL_SNACKBAR' });
};
