const intlFormats = {
  number: {
    USD: {
      style: 'currency',
      currency: 'USD',
    },
    GBP: {
      style: 'currency',
      currency: 'GBP',
    },
  },
};

export default intlFormats;
