import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import New from './New';

import {
  addCourse,
  closeNewCourseDialog,
  formFieldChange,
  setRecurringData,
} from './actions';

const mapStateToProps = state => ({
  ...state.staff.courses.create,
  fields: state.staff.courses.list.fields,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(addCourse(ownProps.match.params.appname)),
  setRecurringData: (id, value) => dispatch(setRecurringData(id, value)),
  onRequestClose: () => dispatch(closeNewCourseDialog()),
  onFieldChange: (isRequired, type, field, value, index) =>
    dispatch(formFieldChange(isRequired, type, field, value, index)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(New));
