import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import PropTypes from 'prop-types';
import rgbHex from 'rgb-hex';
import { SketchPicker, ChromePicker } from 'react-color';
import ImageResize from 'quill-image-resize-module-react'; // import as default
import { Trans, withTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import firebase from '../../../firebase';
import { dataURLtoBlob } from '../../../utils';
import { ProgressOverlay } from '../../../app/components/progress';
import Warning from '../../../app/components/warning';
import SectionHeader from '../../../modules/editor/components/sectionHeader';
import TextField from '../../../app/components/textField';
import './style.css';

Quill.register('modules/imageResize', ImageResize);

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = [
  'extra-small',
  'small',
  'medium',
  'large',
  '16px',
  '17px',
  '32px',
  '36px',
  '42px',
  '50px',
];
Quill.register(Size, true);

Quill.register('modules/maxlength', (quill, options) => {
  quill.on('text-change', () => {
    const text = quill.getText();
    if (text.length > options.value) quill.history.undo();
  });
});

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = [
  'arial',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
  'times-new-roman',
  'impact',
  'modak',
  'teko',
  'fondamento',
  'titillium-web',
  'roboto',
  'open-sans',
  'lato',
  'lexend',
  'oswald',
  'source-sans-pro',
  'montserrat',
  'raleway',
  'pt-sans',
  'lora',
  'nunito-sans',
  'concert-one',
  'prompt',
  'alegreya',
  'b612',
  'varela',
  'vollkorn',
  'crimson-text',
  'cairo',
  'biorhyme',
  'playfair-display',
  'archivo',
  'fjalla-one',
  'rubik',
  'ubuntu',
  'work-sans',
  'gill-sans',
  'museo',
];
Quill.register(Font, true);

const Link = Quill.import('formats/link');
// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = [
  'http',
  'https',
  'mailto',
  'tel',
  'radar',
  'rdar',
  'smb',
  'sms',
];

const styles = {
  componentLabel: {
    marginBottom: '2.4rem',
  },
};

class CustomLinkSanitizer extends Link {
  static sanitize(url) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url);
    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;
    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(protocol =>
      sanitizedUrl.startsWith(protocol),
    );
    if (hasWhitelistedProtocol) return sanitizedUrl;
    // if not, then append only 'http' to not to be a relative URL
    return `http://${sanitizedUrl}`;
  }
}

Quill.register(CustomLinkSanitizer, true);

/*
 * Editor component with custom toolbar and content containers
 */
class BeezerTextEditor extends React.Component {
  state = {
    editorHtml: this.props.value,
    loading: false,
    errMsg: null,
    fontColor: '#fff',
    displayColorPicker: false,
    background: '#fff',
    displayBackgroundPicker: false,
  };

  // Color handler to open color picker when custom color is clicked
  colorHandler = value => {
    if (value === 'custom-color') {
      this.setState({ displayColorPicker: true });
    } else {
      this.quillRef.editor.format('color', value);
    }
  };

  backgroundHandler = value => {
    if (value === 'custom-color') {
      this.setState({ displayBackgroundPicker: true });
    } else {
      this.quillRef.editor.format('background', value);
    }
  };

  handleChangeComplete = color => {
    const c = color;
    this.setState(
      { fontColor: `#${rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a)}` },
      () => {
        this.quillRef.editor.format('color', this.state.fontColor);
      },
    );
  };

  handleClose = () => {
    this.quillRef.editor.format('color', this.state.fontColor);
    this.setState({ displayColorPicker: false });
  };

  handleBackgroundChangeComplete = color => {
    const c = color;
    this.setState(
      { background: `#${rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a)}` },
      () => {
        this.quillRef.editor.format('background', this.state.background);
      },
    );
  };

  handleBackgroundClose = () => {
    this.quillRef.editor.format('background', this.state.background);
    this.setState({ displayBackgroundPicker: false });
  };

  handleChange = html => {
    const blacklistedStrings = [
      'blob.core.windows',
      'blob.core.windows.net',
      'Interfaith Community Service',
      'THIS FILE HAS BEEN SUCCESSFULLY, SHARED WITH YOU SECURELY',
      'SHARED WITH YOU SECURELY',
      'April Schiller',
      'llp.blob',
    ];

    const wordFound = blacklistedStrings.some(s => html.includes(s));

    if (!wordFound) {
      this.setState({ editorHtml: html });
    }
  };

  getHtmlForLegacyContent = (value, fontClass) =>
    `<div class="legacy-styling"><p class="ql-align-center"><span class="${fontClass}" style="color: rgb(255, 255, 255);">${value}</span></p></div>`;

  componentDidMount() {
    let content = this.props.value;
    const isHTML = RegExp.prototype.test.bind(/<[a-z][\s\S]*>/i);
    if (!isHTML(content) && content !== '') {
      let fontClass = '';
      if (this.props.id === 'subtitle') {
        fontClass = 'ql-size-small';
      } else if (this.props.id === 'title') {
        fontClass = 'ql-size-large';
      }
      content = this.getHtmlForLegacyContent(content, fontClass);
      this.handleChange(content);
    }
  }

  readImage = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onabort = () =>
        reject(new Error(this.props.t('file reading was aborted')));
      reader.onerror = () =>
        reject(new Error(this.props.t('file reading has failed')));
      reader.readAsDataURL(file);
    });

  getSizes = dataurl =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        let result;
        try {
          result = {
            dataurl,
            dimensions: `${image.width}x${image.height}`,
          };
        } catch (e) {
          return reject(e);
        }
        return resolve(result);
      };
      image.onerror = () =>
        reject(
          new Error(this.props.t('Something went wrong, please try again')),
        );
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = dataurl;
    });

  uploadImage = (dataurl, sizes) => {
    const form = new FormData();
    const myHeaders = new Headers();
    const user = firebase.auth().currentUser;
    form.append('sizes', sizes);
    if (dataurl) {
      form.append('img', dataURLtoBlob(dataurl));
    }
    return user.getIdToken().then(token => {
      myHeaders.append('x-access-token', token);
      return fetch(
        `${process.env.REACT_APP_IMAGES_API}/upload_text_editor_image`,
        {
          method: 'post',
          headers: myHeaders,
          body: form,
          mode: 'cors',
        },
      ).then(res => {
        if (res.status !== 200) {
          this.setState({
            errMsg: this.props.t('Something went wrong, please try again'),
          });
        } else {
          this.setState({ errMsg: null });
          return res.json();
        }
      });
    });
  };

  handleSaveContent = () => {
    this.props.onChange(this.state.editorHtml);
  };

  handleTextLabelChange = value => {
    this.props.onChange(value, 'label');
  };

  imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0];
      // file type is only image.
      if (/^image\//.test(file.type)) {
        this.saveToServer(file);
      } else {
        this.setState({ errMsg: this.props.t('You could only upload Image') });
      }
    };
  };

  saveToServer = file => {
    this.setState({ loading: true });
    this.readImage(file)
      .then(dataurl => this.getSizes(dataurl))
      .then(response => this.uploadImage(response.dataurl, response.dimensions))
      .then(response => {
        const range = this.quillRef.getEditor().getSelection();
        this.quillRef
          .getEditor()
          .insertEmbed(range.index, 'image', response.url, 'user');
        this.setState({ loading: false, error: null });
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: this.props.t('Failed to save to server'),
        });
      });
  };

  renderToolbar = () => (
    <div id={`toolbar-${this.props.id}`}>
      {this.props.toolbar.fonts && (
        <select className="ql-font">
          <option value="arial">Arial </option>
          <option value="comic-sans">Comic Sans</option>
          <option value="courier-new">Courier New</option>
          <option value="georgia">Georgia</option>
          <option value="helvetica">Helvetica</option>
          <option value="lucida">Lucida</option>
          <option value="times-new-roman">Times New Roman</option>
          <option value="impact">Impact</option>
          <option value="modak">Modak</option>
          <option value="teko">Teko</option>
          <option value="fondamento">Fondamento</option>
          <option value="titillium-web">Titillium Web</option>
          <option value="roboto">Roboto</option>
          <option value="open-sans">Open Sans</option>
          <option value="lato">Lato</option>
          <option value="lexend">Lexend</option>
          <option value="oswald">Oswald</option>
          <option value="source-sans-pro">Source Sans Pro</option>
          <option value="montserrat">Montserrat</option>
          <option value="raleway">Raleway</option>
          <option value="pt-sans">PT Sans</option>
          <option value="lora">Lora</option>
          <option value="nunito-sans">Nunito Sans</option>
          <option value="concert-one">Concert One</option>
          <option value="prompt">Prompt</option>
          <option value="alegreya">Alegreya</option>
          <option value="b612">B612</option>
          <option value="varela">Varela</option>
          <option value="vollkorn">Vollkorn</option>
          <option value="crimson-text">Crimson Text</option>
          <option value="cairo">Cairo</option>
          <option value="biorhyme">BioRhyme</option>
          <option value="playfair-display">Playfair Display</option>
          <option value="archivo">Archivo</option>
          <option value="fjalla-one">Fjalla One</option>
          <option value="rubik">Rubik</option>
          <option value="ubuntu">Ubuntu</option>
          <option value="work-sans">Work Sans</option>
          <option value="gill-sans">Gill Sans</option>
          <option value="museo">Museo</option>
        </select>
      )}
      {this.props.toolbar.fontSize && (
        <select className="ql-size">
          <option value="extra-small">10 px</option>
          <option value="small">15 px</option>
          <option value="16px">16 px</option>
          <option value="17px">17 px</option>
          <option value="medium">20 px</option>
          <option value="large">25 px</option>
          <option value="32px">32 px</option>
          <option value="36px">36 px</option>
          <option value="42px">42 px</option>
          <option value="50px">50 px</option>
        </select>
      )}
      {this.props.toolbar.bold && <button className="ql-bold" />}
      {this.props.toolbar.italic && <button className="ql-italic" />}
      {this.props.toolbar.underline && <button className="ql-underline" />}
      {this.props.toolbar.url && <button className="ql-link" />}
      {this.props.toolbar.image && <button className="ql-image" />}
      {this.props.toolbar.video && <button className="ql-video" />}
      {this.props.toolbar.orderedList && (
        <button className="ql-list" value="ordered" />
      )}
      {this.props.toolbar.unorderedList && (
        <button className="ql-list" value="bullet" />
      )}
      {this.props.toolbar.negativeIntent && (
        <button className="ql-indent" value="-1" />
      )}
      {this.props.toolbar.positiveIndent && (
        <button type="button" className="ql-indent" value="+1" />
      )}

      {this.props.toolbar.textAlign && <select className="ql-align" />}
      {this.props.toolbar.textColor && (
        <select className="ql-color">
          <option value="#000000" />
          <option value="#E60103" />
          <option value="#FF9900" />
          <option value="#FEFF00" />
          <option value="#058A03" />
          <option value="#0066CC" />
          <option value="#9834FF" />
          <option value="#FFFFFF" />
          <option value="#FACCCC" />
          <option value="#FFECCB" />
          <option value="#FEFFCB" />
          <option value="#CBE8CC" />
          <option value="#CCE0F5" />
          <option value="#EAD7FF" />
          <option value="#BBBBBB" />
          <option value="#F06565" />
          <option value="#FFC166" />
          <option value="#FEFF65" />
          <option value="#65BA66" />
          <option value="#66A2E0" />
          <option value="#C286FF" />
          <option value="#888888" />
          <option value="#A10002" />
          <option value="#B26B04" />
          <option value="#B2B200" />
          <option value="#036102" />
          <option value="#0147B2" />
          <option value="#6C25B1" />
          <option value="#444444" />
          <option value="#5D0100" />
          <option value="#663D02" />
          <option value="#676601" />
          <option value="#033700" />
          <option value="#002966" />
          <option value="#3D1566" />
          <option value="custom-color" />
        </select>
      )}
      {this.props.toolbar.textBackground && (
        <select className="ql-background">
          <option value="#000000" />
          <option value="#E60103" />
          <option value="#FF9900" />
          <option value="#FEFF00" />
          <option value="#058A03" />
          <option value="#0066CC" />
          <option value="#9834FF" />
          <option value="#FFFFFF" />
          <option value="#FACCCC" />
          <option value="#FFECCB" />
          <option value="#FEFFCB" />
          <option value="#CBE8CC" />
          <option value="#CCE0F5" />
          <option value="#EAD7FF" />
          <option value="#BBBBBB" />
          <option value="#F06565" />
          <option value="#FFC166" />
          <option value="#FEFF65" />
          <option value="#65BA66" />
          <option value="#66A2E0" />
          <option value="#C286FF" />
          <option value="#888888" />
          <option value="#A10002" />
          <option value="#B26B04" />
          <option value="#B2B200" />
          <option value="#036102" />
          <option value="#0147B2" />
          <option value="#6C25B1" />
          <option value="#444444" />
          <option value="#5D0100" />
          <option value="#663D02" />
          <option value="#676601" />
          <option value="#033700" />
          <option value="#002966" />
          <option value="#3D1566" />
          <option value="custom-color" />
        </select>
      )}
      {this.props.toolbar.clearTextFormatting && (
        <button className="ql-clean" />
      )}
      {this.props.toolbar.codeBlock && <button className="ql-code-block" />}
      {this.props.toolbar.blockquote && <button className="ql-blockquote" />}
      <button className="ql-saveContent" disabled={this.props.disabled}>
        <Trans>Save</Trans>
      </button>
    </div>
  );
  render() {
    const { errMsg, loading } = this.state;
    const { t } = this.props;
    const MODULES = {
      toolbar: {
        container: `#toolbar-${this.props.id}`,
        handlers: {
          saveContent: this.handleSaveContent,
          image: this.imageHandler,
          color: this.colorHandler,
          background: this.backgroundHandler,
        },
      },
      imageResize: {
        handleStyles: {
          backgroundColor: 'black',
          border: 'none',
          color: 'white',
        },
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
      // maxlength: {
      //   value: 10,
      //   history: { delay: 100, userOnly: true },
      // },
    };

    const errorMessage = errMsg && <Warning>{errMsg}</Warning>;

    const formats = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'color',
      'background',
      'align',
      'code-block',
      'video',
    ];
    return (
      <div className="text-editor">
        {this.props.id === 'text' && (
          <div style={styles.componentLabel}>
            <SectionHeader
              title={t('Component Label')}
              tooltip={t('Add a label to easily identify this component.')}
            />
            <TextField
              id="box-name"
              value={this.props.label}
              onChange={this.handleTextLabelChange}
              placeholder={t('Component label')}
            />
          </div>
        )}
        {this.renderToolbar()}
        {loading && <ProgressOverlay />}
        <ReactQuill
          value={this.state.editorHtml}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          modules={MODULES}
          formats={formats}
          readOnly={this.props.disabled}
          preserveWhitespace
          ref={el => {
            this.quillRef = el;
          }}
        />
        {errorMessage}
        <div
          className={
            this.state.displayColorPicker
              ? 'react-color-picker-text-editor'
              : 'react-color-picker-text-editor-hidden'
          }
        >
          <SketchPicker
            color={this.state.fontColor}
            onChangeComplete={this.handleChangeComplete}
            presetColors={[
              '#D0021B',
              '#F5A623',
              '#F8E71C',
              '#8B572A',
              '#7ED321',
              '#417505',
              '#BD10E0',
              '#9013FE',
              '#4A90E2',
              '#50E3C2',
              '#B8E986',
            ]}
          />
          <div
            className="react-color-picker-text-editor-done"
            onClick={this.handleClose}
          >
            DONE
          </div>
        </div>
        <div
          className={
            this.state.displayBackgroundPicker
              ? 'react-color-picker-text-editor'
              : 'react-color-picker-text-editor-hidden'
          }
        >
          <SketchPicker
            color={this.state.background}
            onChangeComplete={this.handleBackgroundChangeComplete}
            presetColors={[
              '#D0021B',
              '#F5A623',
              '#F8E71C',
              '#8B572A',
              '#7ED321',
              '#417505',
              '#BD10E0',
              '#9013FE',
              '#4A90E2',
              '#50E3C2',
              '#B8E986',
            ]}
          />
          <div
            className="react-color-picker-text-editor-done"
            onClick={this.handleBackgroundClose}
          >
            <Trans>DONE</Trans>
          </div>
        </div>
      </div>
    );
  }
}

BeezerTextEditor.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

BeezerTextEditor.defaultProps = {
  id: 'toolbar',
  value: '',
  placeholder: 'Enter text...',
};

export default withTranslation()(BeezerTextEditor);
