import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReferralsTable from '../../components/ReferralsTable';

const RedeemHistory = props => {
  const { isPending, fetchRedeemHistory, items } = props;
  const { t } = useTranslation();

  const headers = [t('Date of Request'), t('Amount'), t('Status')];

  const dataAttributes = ['created_at', 'formattedAmount', 'status'];

  useEffect(() => {
    fetchRedeemHistory();
  }, []);

  return (
    <ReferralsTable
      headers={headers}
      rows={items}
      loading={isPending}
      dataAttributes={dataAttributes}
    />
  );
};

export default RedeemHistory;
