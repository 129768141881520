import _pickBy from 'lodash/pickBy';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import { filter as filterObject } from 'utils';
import {
  REQUEST_USERS,
  RECEIVE_USERS,
  NO_USERS_EXIST,
  RECEIVE_GROUPS,
  NO_GROUPS_EXIST,
  FILTER_USER_DATA,
  SELECT_USER,
  SELECT_ALL_USERS,
  DESELECT_ALL_USERS,
  OPEN_USER_GROUPS_DIALOG,
  CLOSE_USER_GROUPS_DIALOG,
  TOGGLE_SELECTED_GROUP,
  SAVE_USER_GROUP_CHANGES,
  SAVE_USER_GROUP_CHANGES_SUCCESSFULLY,
  REMOVE_USER_GROUP_SUCCESSFULLY,
  SELECT_ALL_GROUPS,
  DESELECT_ALL_GROUPS,
  APPROVED_SCOTTISH_APP_USER,
  RECEIVE_PAGE_COUNT_ANALYTICS,
  REQUEST_PAGE_COUNT_ANALYTICS,
  NO_PAGE_COUNT_ANALYTICS_EXIST,
  FILTER_USERS_DATA,
  VIEW_BANNED_USERS,
  VIEW_ALL_USERS,
  RESET_USERS_DATA,
  EMAIL_VERIFIED,
  IS_SUBMITTING,
  TOGGLE_DIARY_PERMISSION,
} from './actions';
import { escapeSepcialChars } from '../../../distribute/utils';

const initialState = {
  pending: false,
  chunks: {},
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  selected: [],
  groups: {},
  userGroupsDialogOpen: false,
  selectedUsers: {},
  selectedGroups: [],
  selectAllGroups: false,
  pageAnalyticsData: {},
  pageAnalyticsDataPending: false,
  verficationMessage: null,
  isSubmitting: false,
};

const filterChunks = (chunks, filterHolder = '') => {
  let filteredChunks = chunks;
  const filter = escapeSepcialChars(filterHolder);

  if (filter !== '') {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`^${filter}.*$`, 'i');
      const fullName = `${chunk.firstName} ${chunk.lastName}`;
      return (
        rgx.test(chunk.email) ||
        rgx.test(chunk.firstName) ||
        rgx.test(chunk.lastName) ||
        rgx.test(fullName)
      );
      // return rgx.test(chunk.email);
    });
  }

  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const filterChunksData = (chunks, sortedItems) => {
  let filteredChunks = chunks;

  filteredChunks = _pickBy(chunks, (chunk, key) => {
    return sortedItems.includes(key);
  });
  return filteredChunks;
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case REQUEST_USERS:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case REQUEST_PAGE_COUNT_ANALYTICS:
      return {
        ...state,
        pageAnalyticsDataPending: true,
        error: null,
      };

    case RECEIVE_PAGE_COUNT_ANALYTICS:
      return {
        ...state,
        pageAnalyticsData: action.value,
        pageAnalyticsDataPending: false,
      };

    case NO_PAGE_COUNT_ANALYTICS_EXIST:
      return {
        ...state,
        pageAnalyticsData: {},
        pageAnalyticsDataPending: false,
      };

    case RECEIVE_USERS: {
      return {
        ...state,
        chunks: action.value,
        filteredChunks: action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };
    }

    case NO_USERS_EXIST:
      return {
        ...state,
        pending: false,
        chunks: {},
        items: [],
        filteredChunks: {},
        selected: [],
        selectAll: false,
      };

    case RECEIVE_GROUPS:
      return {
        ...state,
        groups: action.value,
      };

    case NO_GROUPS_EXIST:
      return {
        ...state,
      };

    case FILTER_USER_DATA:
      return {
        ...state,
        ...filterChunks(state.chunks, action.payload),
        filter: action.payload,
      };

    case FILTER_USERS_DATA:
      return {
        ...state,
        filteredChunks: filterChunksData(
          action.payload.filteredChunks,
          action.payload.sortedItems,
        ),
        items: action.payload.sortedItems,
      };

    case SELECT_USER: {
      const prevState = state.filteredChunks[action.key].selected;

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case SELECT_ALL_USERS: {
      const filteredChunks = _pick(state.filteredChunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: true,
      }));
      const selected = _union(state.selected, _keys(filteredChunks));

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_USERS:
      return {
        ...state,
        filteredChunks: _mapValues(state.filteredChunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    case OPEN_USER_GROUPS_DIALOG: {
      const selectedGroups = [];
      const selectedUsers = _pick(state.chunks, state.selected);
      if (
        selectedUsers &&
        Object.keys(selectedUsers) &&
        Object.keys(selectedUsers).length === 1 &&
        state.selected &&
        state.selected[0] &&
        selectedUsers[state.selected[0]].groups
      ) {
        Object.keys(selectedUsers[state.selected[0]].groups).forEach(key => {
          selectedGroups.push(key);
        });

        return {
          ...state,
          userGroupsDialogOpen: true,
          selectedUsers,
          selectedGroups,
        };
      }
      return {
        ...state,
        userGroupsDialogOpen: true,
        selectedUsers,
      };
    }

    case CLOSE_USER_GROUPS_DIALOG:
      return {
        ...state,
        userGroupsDialogOpen: false,
        selectedUsers: {},
        selectedGroups: [],
      };

    case SAVE_USER_GROUP_CHANGES:
      return {
        ...state,
      };

    case SAVE_USER_GROUP_CHANGES_SUCCESSFULLY:
      return {
        ...state,
        userGroupsDialogOpen: false,
        selectedGroups: [],
        selectAllGroups: false,
        selectedUsers: {},
      };
    case APPROVED_SCOTTISH_APP_USER:
      return {
        ...state,
        userGroupsDialogOpen: false,
        selectedGroups: [],
        selectAllGroups: false,
        selectedUsers: {},
      };

    case REMOVE_USER_GROUP_SUCCESSFULLY:
      return {
        ...state,
        userGroupsDialogOpen: false,
        selectedGroups: [],
        selectAllGroups: false,
      };

    case TOGGLE_SELECTED_GROUP: {
      const prevState = state.selectedGroups.indexOf(action.key) >= 0;
      return {
        ...state,
        selectedGroups: prevState
          ? _without(state.selectedGroups, action.key)
          : state.selectedGroups.concat(action.key),
      };
    }

    case SELECT_ALL_GROUPS: {
      return {
        ...state,
        selectAllGroups: true,
        selectedGroups: Object.keys(state.groups),
      };
    }

    case DESELECT_ALL_GROUPS:
      return {
        ...state,
        selectAllGroups: false,
        selectedGroups: [],
      };

    case VIEW_BANNED_USERS: {
      const filteredChunks = filterObject(
        state.chunks,
        chunk => chunk.livestreamBanned,
      );
      return { ...state, filteredChunks };
    }
    case VIEW_ALL_USERS: {
      return { ...state, filteredChunks: state.chunks };
    }

    case RESET_USERS_DATA: {
      return { ...state, selectAll: false };
    }
    case EMAIL_VERIFIED: {
      return {
        ...state,
        verficationMessage: action.payload,
      };
    }
    case TOGGLE_DIARY_PERMISSION: {
      return {
        ...state,
        verficationMessage: action.payload,
      };
    }

    default:
      return state;
  }
};

export default users;
