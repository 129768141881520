import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import { useTranslation } from 'react-i18next';
import Toggle from '../../../../app/components/toggle';
import SectionHeader from '../../../editor/components/sectionHeader';

import './style.css';

const styles = {
  picker: {
    width: 290,
  },
  toggle: {
    marginTop: 24,
    marginBottom: 12,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 6,
  },
  toggleLabel: {
    fontSize: 14,
  },
};

const isToggleDisabled = recipients => recipients === null || recipients === 0;

const NotificationScheduleMenu = ({
  date,
  onChange,
  isToggled,
  onToggle,
  recipients,
}) => {
  const { t } = useTranslation();

  return (
    <div className="notification-link-menu-wrapper">
      <SectionHeader
        title={t('Schedule Push Notification')}
        actions={
          <Toggle
            onToggle={(e, value) => {
              onToggle(value);
            }}
            toggled={isToggled}
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            disabled={isToggleDisabled(recipients)}
          />
        }
        tooltip={
          recipients === null || recipients === 0
            ? t(
                'This requires at least one user to allow receiving push notifications on your app.',
              )
            : t('Schedule the push notification to be sent later')
        }
        tooltipType={isToggleDisabled(recipients) ? 'warning' : 'info'}
      />
      <div className="notification-link-container">
        <div>
          <DatePicker
            style={styles.picker}
            hintText={t('Date')}
            value={date}
            onChange={(e, timestamp) => {
              onChange(timestamp);
            }}
            disabled={!isToggled}
          />
          <TimePicker
            style={styles.picker}
            hintText={t('Time')}
            value={date}
            onChange={(e, timestamp) => {
              onChange(timestamp);
            }}
            disabled={!isToggled}
          />
        </div>
      </div>
    </div>
  );
};
NotificationScheduleMenu.propTypes = {
  date: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isToggled: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  recipients: PropTypes.number,
};

NotificationScheduleMenu.defaultProps = {
  date: null,
  recipients: null,
};

export default NotificationScheduleMenu;
