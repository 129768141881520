import {
  RECEIVE_VENDOR_DETAILS,
  REQUEST_VENDOR_DETAILS,
  STOP_RECEIVING_VENDOR_DETAILS,
  REQUEST_VENDOR_PRODUCTS,
  RECEIVE_VENDOR_PRODUCTS,
  NO_VENDOR_PRODUCTS_EXIST,
  STOP_RECEIVING_VENDOR_PRODUCTS,
} from './types';
import firebase from '../../../../../../firebase';
import { objectToArray } from '../../../../../../utils';
const request = () => ({
  type: REQUEST_VENDOR_DETAILS,
});

const requestProducts = () => ({
  type: REQUEST_VENDOR_PRODUCTS,
});

export const getVendorDetails = (appname, vendorID) => dispatch => {
  dispatch(request());
  firebase
    .database()
    .ref(`app_users/${appname}/${vendorID}`)
    .on('value', snap => {
      const value = snap.exists() ? snap.val() : {};
      dispatch({
        type: RECEIVE_VENDOR_DETAILS,
        value,
      });
    });
};

export const stopGetVendorDetails = (appname, vendorID) => dispatch => {
  firebase
    .database()
    .ref(`app_users/${appname}/${vendorID}`)
    .off('value');
  dispatch({
    type: STOP_RECEIVING_VENDOR_DETAILS,
  });
};

export const getProducts = (appname, vendorID) => dispatch => {
  dispatch(requestProducts());
  firebase
    .database()
    .ref(`marketplace_products/${appname}`)
    .orderByChild('vendorID')
    .equalTo(vendorID)
    .on('value', snap => {
      if (!snap.exists()) {
        dispatch({
          type: NO_VENDOR_PRODUCTS_EXIST,
        });
      } else {
        let products = objectToArray(snap.val());
        products = products.filter(i => i.value.status === 'Active');
        dispatch({
          type: RECEIVE_VENDOR_PRODUCTS,
          value: products,
        });
      }
    });
};

export const stopGetProducts = (appname, vendorID) => dispatch => {
  firebase
    .database()
    .ref(`marketplace_products/${appname}`)
    .off('value');
  dispatch({
    type: STOP_RECEIVING_VENDOR_PRODUCTS,
    value: appname,
  });
};

export const changeVendorApproval = ({
  appname,
  vendorID,
  vendorApprovalStatus,
  message,
}) => async dispatch => {
  const user = firebase.auth().currentUser;
  const { uid } = user;
  const headers = new Headers();
  const accessToken = await user.getIdToken();
  headers.append('Content-Type', 'application/json');
  headers.append('x-access-token', accessToken);

  const body = JSON.stringify({
    uid,
    appname,
    vendorID,
    vendorApprovalStatus,
    message,
    requestOrigin: 'platform',
  });
  return await fetch(
    `${process.env.REACT_APP_MARKETPLACE_API}/marketplace-user/update-vendor-status`,
    {
      method: 'POST',
      mode: 'cors',
      headers,
      body,
    },
  );
};
