import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Edit from './Edit';

import { updateGroupName, getMemberDetails, updateField } from './actions';

import { openMemberDetailDialog } from './modules/create/actions';

const mapStateToProps = state => ({
  otherDetails: state.auth.workforceMembers.edit,
  ...state.auth.workforceMembers.edit.editGroup,
  fieldsVisibility: state.auth.groups.data.chunks.default,
  allGroups: state.auth.memberGroups.listPage.data.chunks,
  ...state.auth.workforceMembers.edit.groupTabs,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMemberDetails: () =>
    dispatch(
      getMemberDetails(
        ownProps.match.params.appname,
        ownProps.match.params.memberID,
      ),
    ),
  updateField: (name, fieldID, value) =>
    dispatch(
      updateField(
        ownProps.match.params.appname,
        ownProps.match.params.memberID,
        name,
        fieldID,
        value,
      ),
    ),
  updateGroupName: (name, value) =>
    dispatch(
      updateGroupName(
        ownProps.match.params.appname,
        ownProps.match.params.memberID,
        name,
        value,
      ),
    ),
  onAddWorkforceDetails: () =>
    dispatch(openMemberDetailDialog(ownProps.match.params.appname)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Edit));
