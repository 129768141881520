const paypalData = (state = {}, action) => {
  switch (action.type) {
    // case 'RECEIVE_PAYPAL_DATA':
    //   return {
    //     ...state,
    //     [action.appname]: action.paypal,
    //   };

    default:
      return state;
  }
};

export default paypalData;
