import React from 'react';

const Text = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50.52 55.5"
  >
    <title>schedule</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path class="cls-1" d="M20.81,38.39H16.92V24H13.81V21.19h7Z" />
        <path
          class="cls-1"
          d="M39,35H36.66v3.44H32.77V35H24.4V32l7.8-10.87h4.46V31.83H39Zm-6.08-3V25.25l-4.81,6.66Z"
        />
        <path class="cls-1" d="M50.52,53h-48V5h48ZM4,51.51H49v-45H4Z" />
        <polygon
          class="cls-1"
          points="48.02 55.5 0 55.5 0 7.48 3.24 7.48 3.24 8.98 1.5 8.98 1.5 54 46.52 54 46.52 52.26 48.02 52.26 48.02 55.5"
        />
        <path
          class="cls-1"
          d="M22.27,6.48H11.63V5.32a5.32,5.32,0,0,1,10.64,0ZM13.15,5h7.6a3.81,3.81,0,0,0-7.6,0Z"
        />
        <path
          class="cls-1"
          d="M40.55,6.48H29.91V5.32a5.32,5.32,0,0,1,10.64,0ZM31.42,5H39a3.82,3.82,0,0,0-7.61,0Z"
        />
        <rect class="cls-1" x="8.23" y="14.12" width="36.55" height="1.5" />
        <rect class="cls-1" x="8.23" y="44.03" width="36.55" height="1.5" />
      </g>
    </g>
  </svg>
);

export default Text;
