import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Rating from './Rating';
import {
  fieldChange,
  handleDesignChange,
  loadGoogleAnalytics,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  googleAnalytics: state.app.googleAnalytics,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onContentChange: (field, value) =>
    dispatch(
      fieldChange(
        `apps/${ownProps.match.params.appname}/draft/lazy_data/${ownProps.component.lazy_data}/${field}`,
        value,
      ),
    ),
  onLoadGoogleAnalytics: () =>
    dispatch(loadGoogleAnalytics(ownProps.match.params.appname)),

  onDesignChange: property => (type, value) =>
    dispatch(
      handleDesignChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        type,
        value,
        property,
      ),
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Rating));
