/* global fetch Headers*/
import i18n from 'i18next';
import firebase from '../../../../firebase';

const verifyingPasswordResetCode = () => ({
  type: 'VERIFYING_PASSWORD_RESET',
});

const verifyPasswordResetCodeSuccess = () => ({
  type: 'VERIFY_PASSWORD_RESET_SUCCESS',
});

// const verifyPasswordResetCodeError = (err) => ({
//   type: 'VERIFY_PASSWORD_RESET_ERROR',
//   isFetching: false,
//   err,
// });

const resettingPassword = () => ({
  type: 'RESETTING_PASSWORD',
});

const resetPasswordSuccess = () => ({
  type: 'RESET_PASSWORD_SUCCESS',
});

const recoveringEmail = () => ({
  type: 'RECOVERING_EMAIL',
});

const recoverEmailSuccess = email => ({
  type: 'RECOVER_EMAIL_SUCCESS',
  email,
});

const recoverEmailError = err => ({
  type: 'RECOVER_EMAIL_ERROR',
  err,
});

const verifyingEmail = () => ({
  type: 'VERIFYING_EMAIL',
});

const verifyEmailSuccess = message => ({
  type: 'VERIFY_EMAIL_SUCCESS',
  message,
});

const verifyEmailError = err => ({
  type: 'VERIFY_EMAIL_ERROR',
  err,
});

/**
 * Loads user profile when logged in.
 * Redirects and flushes data when logged out.
 */
export const resetPassword = (actionCode, newPass) => dispatch => {
  dispatch(verifyingPasswordResetCode());

  return firebase
    .auth()
    .verifyPasswordResetCode(actionCode)
    .then(() => {
      dispatch(verifyPasswordResetCodeSuccess());
      dispatch(resettingPassword());
      return firebase.auth().confirmPasswordReset(actionCode, newPass);
    })
    .then(() => dispatch(resetPasswordSuccess()));
};

export const recoverEmail = actionCode => dispatch => {
  dispatch(recoveringEmail());

  firebase
    .auth()
    .checkActionCode(actionCode)
    .then(info => {
      dispatch(recoverEmailSuccess(info.data.email));
      return firebase.auth().applyActionCode(actionCode);
    })
    .catch(error => {
      dispatch(recoverEmailError(error));
    });
};

export const verifyEmail = actionCode => dispatch => {
  dispatch(verifyingEmail());

  return firebase
    .auth()
    .applyActionCode(actionCode)
    .then(() =>
      dispatch(verifyEmailSuccess(i18n.t('Your email has been verified'))),
    )
    .catch(error => dispatch(verifyEmailError(error)));
};
