import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import Container from '../../../../../../../app/components/container';
import TextField from '../../../../../../../app/components/textField';
import SectionHeader from '../../../../../components/sectionHeader';

class GoogleMapsItem extends Component {
  static propTypes = {
    component: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  getAvatarUrl = () => {
    const { match, location, component } = this.props;

    const item = queryString.parse(location.search).item;

    return `apps/${match.params.appname}/draft/lazy_data/${component.lazy_data}/items/chunks/${item}/avatar`;
  };

  handleChange = field => value => this.props.onChange(field, value);

  render() {
    const { title, address } = this.props.data;
    const { t } = this.props;

    return (
      <Container>
        <SectionHeader
          title={t('Google Maps Marker')}
          tooltip={t('Enter details for your google maps marker')}
        />
        <TextField
          id="google-maps-item-title"
          value={title}
          onChange={this.handleChange('title')}
          placeholder={t('Title')}
        />
        <TextField
          id="google-maps-item-address"
          value={address}
          onChange={this.handleChange('address')}
          placeholder={t('Address')}
        />
      </Container>
    );
  }
}

export default withTranslation()(GoogleMapsItem);
