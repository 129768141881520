import { connect } from 'react-redux';

import Listing from './Listing';
import {
  selectType,
  selectAllTypes,
  deselectAllTypes,
  deleteTypes,
  filterTypeData,
  getTypes,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  pending: state.auth.memberGroups.listPage.data.pending,
  chunks: state.auth.memberGroups.listPage.data.chunks,
  filteredChunks: state.auth.memberGroups.listPage.data.filteredChunks,
  error: state.auth.memberGroups.listPage.data.error,
  items: state.auth.memberGroups.listPage.data.items,
  filter: state.auth.memberGroups.listPage.data.filter,
  selected: state.auth.memberGroups.listPage.data.selected,
  selectAll: state.auth.memberGroups.listPage.data.selectAll,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelect: key => dispatch(selectType(key)),
  onSelectAllTypes: () => dispatch(selectAllTypes()),
  onDeselectAllTypes: () => dispatch(deselectAllTypes()),
  onDeleteSelected: () => dispatch(deleteTypes(ownProps.match.params.appname)),
  onFilterTypeData: filter => dispatch(filterTypeData(filter)),
  getTypes: () => dispatch(getTypes(ownProps.match.params.appname)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Listing);
