import { openSnackbar } from '../../../../app/modules/snackbar/actions';

export const fetchReferralLink = () => (dispatch, getState) => {
    const account = getState().account.profile.account;
    const referralLink = `${window.location.origin}/register?referrer=${account}`;
    dispatch({ type: 'FETCH_REFERRAL_LINK', payload: referralLink });
}

export const onCopy = (flag) => dispatch => {
    dispatch({ type: 'REFERRAL_COPIED', payload: flag });
    dispatch(openSnackbar('Copied!!!'));
}