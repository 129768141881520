import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import { Route, Switch } from 'react-router-dom';
import queryString from 'query-string';
import IconButton from 'material-ui/IconButton';
import { Security } from '@material-ui/icons';
import { Trans, withTranslation } from 'react-i18next';

import { stringToKey, capitaliseString } from '../../../../../../utils/string';
import { HS_FEATURES_ENABLED_APPS } from '../../../../../../config';
import themeStyles from '../../../../../../_export.scss';
import {
  Back,
  Create,
  Cog,
  Delete,
  View,
  TabView,
  Design,
  AppIcon,
  AppDomain,
  SplashScreen,
  CustomDomain,
  AppMenu,
} from '../../../../components/icons';

// These refs are transformed with stringToKey to match a route
// therefore the ref should match a route
const routes = [
  { ref: 'Design', icon: <Design /> },
  { ref: 'App Icon Design', icon: <AppIcon /> },
  { ref: 'App Name', icon: <AppDomain /> },
  { ref: 'Custom Domain', icon: <CustomDomain /> },
  { ref: 'App Splash Screen', icon: <SplashScreen /> },
  { ref: 'Navigation Menu', icon: <AppMenu /> },
  { ref: 'Paypal Settings', icon: <AppMenu /> },
  { ref: 'Accessibility', icon: <AppMenu /> },
  { ref: 'Privacy', icon: <Security /> },
  { ref: 'Admins', icon: <AppMenu /> },
  { ref: 'custom-emails-admins', icon: <AppMenu /> },
];

class SidebarHeader extends Component {
  static propTypes = {
    page: PropTypes.object,
    component: PropTypes.object,
    item: PropTypes.object,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onDeletePage: PropTypes.func.isRequired,
    onDeleteComponent: PropTypes.func.isRequired,
    onDeleteItem: PropTypes.func.isRequired,
    openCreateComponentUI: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    page: { title: '...' },
    component: { type: '...' },
    item: {},
  };

  getPageSettingsHref = () => {
    const { parentPage, tab } = queryString.parse(this.props.location.search);

    return {
      pathname: `/${this.props.match.params.appname}/editor/build/page_settings`,
      search: `page=${queryString.parse(this.props.location.search).page}${
        parentPage ? `&parentPage=${parentPage}` : ``
      }${tab ? `&tab=${tab}` : ``}`,
    };
  };

  getComponentBackHref = () => {
    const { match, page, location } = this.props;

    const { parentPage } = queryString.parse(location.search);
    const pageKey =
      page.key || new URLSearchParams(window.location.search).get('page');

    return {
      pathname: `/${match.params.appname}/editor/build/list`,
      search: `page=${pageKey}${
        page.type === 'TabView' || page.type === 'NestedTabPage' ? '&tab=0' : ''
      }${parentPage ? `&parentPage=${parentPage}` : ``}`,
    };
  };

  getItemBackHref = () => {
    const { match, location } = this.props;

    const { page, tab, component, parentPage } = queryString.parse(
      location.search,
    );
    return {
      pathname: `/${match.params.appname}/editor/build/edit`,
      search: `page=${page}${tab ? `&tab=${tab}` : ''}&component=${component}${
        parentPage ? `&parentPage=${parentPage}` : ``
      }`,
    };
  };

  getItemLabel = item =>
    item.title
      ? typeof item.title === 'object'
        ? capitaliseString(item.title.value)
        : capitaliseString(item.title)
      : item.type
      ? capitaliseString(item.type)
      : item.name;

  handleComponentBackClick = e => {
    if (e) {
      e.stopPropagation();
    }

    this.props.history.push(this.getComponentBackHref());
  };

  handleItemBackClick = e => {
    if (e) {
      e.stopPropagation();
    }
    this.props.history.push(this.getItemBackHref());
  };

  handleSettingsClick = e => {
    if (e) {
      e.stopPropagation();
    }

    this.props.history.push(this.getPageSettingsHref());
  };

  handleHomepageItemBackClick = e => {
    if (e) {
      e.stopPropagation();
    }

    this.props.history.push(
      `/${this.props.match.params.appname}/build/homepage`,
    );
  };

  handleRemoveItem = () => {
    this.props.onDeleteItem();
    this.handleItemBackClick();
  };

  handleRemovePage = () =>
    this.props
      .onDeletePage()
      .then(() =>
        this.props.history.push(
          `/${this.props.match.params.appname}/editor/build/pages`,
        ),
      );

  hasItemRoute = () => {
    const { item, component, onDeleteComponent, fields, t } = this.props;

    return (
      <>
        {item && !fields && (
          <Route
            path="/:appname/editor/build/item"
            render={() => (
              <div>
                <div className="component-toolbar">
                  <div className="title">
                    <IconButton onClick={this.handleComponentBackClick}>
                      <Back iconStyle={{ color: themeStyles.mainWhite }} />
                    </IconButton>
                    <span>
                      <Trans>Component</Trans>/{component.type}
                    </span>
                  </div>
                  <div className="actions">
                    <span className="split" />
                    <IconButton
                      tooltip={t('Delete Component')}
                      onClick={onDeleteComponent}
                    >
                      <Delete iconStyle={{ color: themeStyles.mainWhite }} />
                    </IconButton>
                  </div>
                </div>
                <div className="item-toolbar">
                  <div className="title">
                    <IconButton onClick={this.handleItemBackClick}>
                      <Back iconStyle={{ color: themeStyles.mainWhite }} />
                    </IconButton>
                    <span>
                      <Trans>Item</Trans>/{this.getItemLabel(item)}
                    </span>
                  </div>
                  <div className="actions">
                    <span className="split" />
                    <IconButton
                      tooltip={t('Delete Item')}
                      onClick={this.handleRemoveItem}
                    >
                      <Delete iconStyle={{ color: themeStyles.mainWhite }} />
                    </IconButton>
                  </div>
                </div>
              </div>
            )}
          />
        )}
        {fields && (
          <Route
            path="/:appname/editor/build/item"
            render={() => (
              <div>
                <div className="component-toolbar">
                  <div className="title">
                    <IconButton onClick={this.handleComponentBackClick}>
                      <Back iconStyle={{ color: themeStyles.mainWhite }} />
                    </IconButton>
                    <span>
                      <Trans>Component</Trans>/{component.type}
                    </span>
                  </div>
                  <div className="actions">
                    <span className="split" />
                    <IconButton
                      tooltip={t('Delete Component')}
                      onClick={onDeleteComponent}
                    >
                      <Delete iconStyle={{ color: themeStyles.mainWhite }} />
                    </IconButton>
                  </div>
                </div>
                <div className="item-toolbar">
                  <div className="title">
                    <IconButton onClick={this.handleItemBackClick}>
                      <Back iconStyle={{ color: themeStyles.mainWhite }} />
                    </IconButton>
                    <span>
                      <Trans>Item</Trans>/{this.getItemLabel(fields)}
                    </span>
                  </div>
                  <div className="actions">
                    <span className="split" />
                    <IconButton
                      tooltip={t('Delete Item')}
                      onClick={this.handleRemoveItem}
                    >
                      <Delete iconStyle={{ color: themeStyles.mainWhite }} />
                    </IconButton>
                  </div>
                </div>
              </div>
            )}
          />
        )}
      </>
    );
  };

  buildRoutesIconButtons = () =>
    _map(routes, route => {
      const link = stringToKey(route.ref);

      return (
        <Route
          key={link}
          path={`/:appname/editor/build/${link}`}
          render={() => route.icon}
        />
      );
    });

  buildRoutesHeader = () => {
    const { t } = this.props;
    return _map(routes, route => {
      let label = route.ref;
      const link = stringToKey(route.ref);
      if (this.props.isAppAdmin && route.ref === 'App Name') {
        label = 'Meta Data';
      }

      if (route.ref === 'custom-emails-admins') {
        label = 'Custom Emails Sent To App Admins';
      } else if (route.ref === 'Admins') {
        label = 'App Admins';
      }
      return (
        <Route
          key={link}
          path={`/:appname/editor/build/${link}`}
          render={() => (
            <div>
              <span>{t(label)}</span>
            </div>
          )}
        />
      );
    });
  };
  getHeaderTitle = () => {
    const { tab } = queryString.parse(this.props.location.search);

    return (
      (tab &&
        this.props.page.tabs &&
        this.props.page.tabs[tab] &&
        this.props.page.tabs[tab].title) ||
      this.props.page.title
    );
  };

  render() {
    const { tab } = queryString.parse(this.props.location.search);
    const {
      page,
      component,
      onDeleteComponent,
      openCreateComponentUI,
      match,
      t,
    } = this.props;
    const componentType =
      component.type === 'Memberbodies' &&
      !(HS_FEATURES_ENABLED_APPS.indexOf(match.params.appname) > -1)
        ? 'EventOrganizers'
        : component.type;

    const headerTitle = this.getHeaderTitle();

    return (
      <div className="sidebar-header">
        <div className="primary">
          {/* <IconButton disabled>
            <Switch>
              <Route
                path="/:appname/editor/build/(list|edit|item|page_settings)"
                render={() =>
                  page.type === 'TabView' ? <TabView /> : <View />
                }
              />
              {this.buildRoutesIconButtons()}
            </Switch>
          </IconButton> */}
          <Route
            path="/:appname/editor/build/(list|edit|item)"
            render={() => (
              <div className="actions">
                <IconButton
                  onClick={this.handleSettingsClick}
                  tooltip={t('Page Settings')}
                >
                  <Cog />
                </IconButton>
              </div>
            )}
          />
          <h5
            className={`title ${headerTitle &&
              headerTitle.length > 21 &&
              'pgmol-page-title'}`}
          >
            <Switch>
              <Route
                path="/:appname/editor/build/(list|edit|item|page_settings)"
                render={() => <span>{headerTitle}</span>}
              />
              {this.buildRoutesHeader()}
            </Switch>
          </h5>

          <Route
            path="/:appname/editor/build/(list|edit|item)"
            render={() => (
              <div className="actions">
                <IconButton
                  onClick={openCreateComponentUI}
                  tooltip={t('Add Component')}
                  style={{
                    display:
                      page.type === 'NestedTabPage' && !tab ? 'none' : 'block',
                  }}
                >
                  <Create />
                </IconButton>
              </div>
            )}
          />
        </div>
        <Route
          path="/:appname/editor/build/edit"
          render={() => (
            <div className="component-toolbar">
              <div className="title">
                <IconButton onClick={this.handleComponentBackClick}>
                  <Back iconStyle={{ color: themeStyles.mainWhite }} />
                </IconButton>
                <span>
                  <Trans>Component</Trans>/
                  {componentType === 'Html'
                    ? componentType.toUpperCase()
                    : componentType === 'EventCalendar'
                    ? 'Event Calendar'
                    : componentType}
                </span>
              </div>
              <div className="actions">
                <span className="split" />
                <IconButton
                  tooltip={t('Delete Component')}
                  onClick={onDeleteComponent}
                >
                  <Delete iconStyle={{ color: themeStyles.mainWhite }} />
                </IconButton>
              </div>
            </div>
          )}
        />
        {this.hasItemRoute()}
        <Route
          path="/:appname/editor/build/page_settings"
          render={() => (
            <div className="item-toolbar">
              <div className="title">
                <IconButton onClick={this.handleComponentBackClick}>
                  <Back iconStyle={{ color: themeStyles.mainWhite }} />
                </IconButton>
                <span>
                  <Trans>Page Settings</Trans>
                </span>
              </div>
              <div className="actions">
                <span className="split" />
                <IconButton
                  tooltip={t('Delete Page')}
                  onClick={this.handleRemovePage}
                >
                  <Delete iconStyle={{ color: themeStyles.mainWhite }} />
                </IconButton>
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

export default withTranslation()(SidebarHeader);
