import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const BeezerRadioButton = ({
  id,
  className,
  checked,
  onChange,
  label1,
  label2,
  type,
}) => {
  return (
    <label htmlFor={id} className={className}>
      <span>{label1}</span>
      <div className="switch">
        <input
          id={id}
          className="toggle"
          type="checkbox"
          checked={checked}
          onClick={onChange}
        />
        <span
          className={`slider round ${
            !checked && type === 'actionBtn' ? 'disabled' : ''
          }`}
        />
      </div>
      <span>{label2}</span>
    </label>
  );
};

BeezerRadioButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  label1: PropTypes.string,
  label2: PropTypes.string,
};

BeezerRadioButton.defaultProps = {
  checked: false,
  className: '',
  onChange: () => {},
  label1: '',
  label2: '',
};

export default BeezerRadioButton;
