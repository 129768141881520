import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectVendor, deselectVendor } from './actions';
import FavoriteVendors from './FavoriteVendors';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.ecommerce.marketplace.favoriteVendors,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectVendor: vendor => dispatch(selectVendor(vendor)),
  deselectVendor: () => dispatch(deselectVendor()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FavoriteVendors),
);
