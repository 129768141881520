import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'material-ui/DatePicker';
import styled from 'styled-components';
import Chip from 'material-ui/Chip';
import Dropdown from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import moment from 'moment';

import TextField from '../../../../../../../../app/components/textField';
import LocationField from '../../../../../../../../app/components/locationField';
import CircularValidation from '../../../../../../../../app/components/circularValidation';
import { objectToArray } from '../../../../../../../../utils';
import Dropzone from '../../../../../../../../app/components/dropzone';

import './styles.css';
import themeStyles from '../../../../../../../../_export.scss';

const Files = styled.div`
  color: #c1c1c1;
`;

const FilesList = ({ files, onRequestDelete }) =>
  files.map(
    (file, index) =>
      file && (
        <Chip
          key={`file${Math.random()
            .toString(36)
            .substring(7)}`}
          style={{ margin: 6 }}
          labelStyle={{ maxWidth: '300px', overflow: 'hidden' }}
          onRequestDelete={() => onRequestDelete(index)}
          title={file.name}
        >
          <a href={file.preview} target="_blank" rel="noopener noreferrer">
            {file.name}
          </a>
        </Chip>
      ),
  );

FilesList.propTypes = {
  onRequestDelete: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
};

FilesList.defaultProps = {
  onRequestDelete: null,
  files: [],
};

const MenuProps = {
  paperprops: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

const Form = ({ type, onFieldChange, groups, fieldsData, deleteFile }) => {
  // React.useEffect(() => {
  //   if (window.google === undefined) {
  //     const PLACES_API_KEY = 'AIzaSyBtm0fe0-bUXXmf5qDugt0ayiVf3WUhwiE';
  //     // create elements for google map and places lib
  //     const script = document.createElement('script');
  //     // load the google places library
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=${PLACES_API_KEY}&libraries=places`;
  //     document.head.appendChild(script);
  //   }
  // }, []);
  const groupID = type.value;
  const fields = groupID ? objectToArray(groups[groupID].fields) : [];
  const processField = obj => {
    const fieldID = obj.id;
    const data = obj.value;
    switch (data.type) {
      case 'Date':
      case 'ExpiryDate': {
        const value =
          fieldsData && fieldsData[fieldID] && fieldsData[fieldID].value;
        return (
          <div className="date-wrapper" key={`field-container-${fieldID}`}>
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label
              className={
                fieldsData && fieldsData[fieldID] && fieldsData[fieldID].value
                  ? 'with-data'
                  : 'without-data'
              }
            >{`${data.name} ${data.isRequired ? '*' : ''}`}</label>
            <DatePicker
              openToYearSelection
              style={{ width: '100%' }}
              name={fieldID}
              key={`field${fieldID}`}
              className="date-picker"
              value={value ? new Date(value) : null}
              onChange={(e, timestamp) => {
                onFieldChange(
                  data.isRequired,
                  data.type,
                  fieldID,
                  timestamp.getTime(),
                  groupID,
                );
              }}
              formatDate={date => moment(new Date(value)).format('DD-MM-YYYY')}
            />
          </div>
        );
      }
      case 'Location': {
        return (
          <LocationField
            key={`field${fieldID}`}
            id={`field${fieldID}`}
            placeholder={`${data.name} ${data.isRequired ? '*' : ''}`}
            value={
              fieldsData && fieldsData[fieldID] ? fieldsData[fieldID].value : ''
            }
            latlong={
              fieldsData && fieldsData[fieldID]
                ? fieldsData[fieldID].additionalData
                : null
            }
            multiLine
            onChange={(value, latlong) =>
              onFieldChange(
                data.isRequired,
                data.type,
                fieldID,
                value,
                groupID,
                latlong,
              )
            }
            rightInputAction={
              <CircularValidation
                defaultMsg={`${data.name}'s required`}
                errorMsg={`${data.name}'s required`}
                showDefault={data.isRequired}
              />
            }
          />
        );
      }
      case 'Text':
      case 'Number': {
        return (
          <TextField
            type={data.type === 'Number' ? 'number' : 'text'}
            key={`field${fieldID}`}
            id={`field${fieldID}`}
            placeholder={`${data.name} ${data.isRequired ? '*' : ''}`}
            value={
              fieldsData && fieldsData[fieldID] ? fieldsData[fieldID].value : ''
            }
            multiLine
            onChange={value =>
              onFieldChange(data.isRequired, data.type, fieldID, value, groupID)
            }
            rightInputAction={
              <CircularValidation
                defaultMsg={`${data.name}'s required`}
                errorMsg={`${data.name}'s required`}
                showDefault={data.isRequired}
              />
            }
          />
        );
      }
      case 'Dropdown': {
        return data.isMultiple ? (
          <Dropdown
            multiple
            floatingLabelStyle={{ fontSize: '12px' }}
            underlineStyle={{ width: '95%' }}
            fullWidth
            id={`field${fieldID}`}
            key={`field${fieldID}`}
            className="dropdown-wrapper"
            floatingLabelText={`${data.name} ${data.isRequired ? '*' : ''}`}
            dropDownMenuProps={MenuProps}
            selectionRenderer={selected => selected.join(', ')}
            value={
              fieldsData && fieldsData[fieldID] ? fieldsData[fieldID].value : ''
            }
            onChange={(i, key, value) =>
              value !== undefined &&
              onFieldChange(data.isRequired, data.type, fieldID, value, groupID)
            }
            input={<Input />}
          >
            {data.options.split(',').map(item => {
              //remove whitespaces
              const modifiedItem =
                typeof item === 'string'
                  ? item.replaceAll(/\s+/g, ' ').trim()
                  : item;
              return (
                <MenuItem
                  key={modifiedItem + Math.random()}
                  value={modifiedItem}
                  dense
                >
                  <Checkbox
                    style={{ color: themeStyles.primaryColor }}
                    checked={
                      fieldsData &&
                      fieldsData[fieldID] &&
                      fieldsData[fieldID].value
                        ? fieldsData[fieldID].value.indexOf(modifiedItem) > -1
                        : false
                    }
                  />
                  <ListItemText primary={item} style={{ display: 'inline' }} />
                </MenuItem>
              );
            })}
          </Dropdown>
        ) : (
          <Dropdown
            floatingLabelStyle={{ fontSize: '12px' }}
            underlineStyle={{ width: '95%' }}
            fullWidth
            id={`field${fieldID}`}
            key={`field${fieldID}`}
            className="dropdown-wrapper"
            floatingLabelText={`${data.name} ${data.isRequired ? '*' : ''}`}
            value={
              fieldsData && fieldsData[fieldID] ? fieldsData[fieldID].value : ''
            }
            onChange={(i, key, value) =>
              onFieldChange(data.isRequired, data.type, fieldID, value, groupID)
            }
          >
            {data.options.split(',').map(item => (
              <MenuItem
                key={item + Math.random()}
                value={item}
                primaryText={item}
              />
            ))}
          </Dropdown>
        );
      }
      case 'File': {
        return (
          <div className="dropzone-wrapper" key={`files-container-${fieldID}`}>
            <span className="dropzone-label">
              {`${data.name} ${data.isRequired ? '*' : ''}`}
            </span>
            <Dropzone
              width="314px"
              className="dropzone-file-uploader"
              accept=""
              onDrop={acceptedFiles =>
                onFieldChange(
                  data.isRequired,
                  data.type,
                  fieldID,
                  acceptedFiles,
                  groupID,
                )
              }
            />
            <Files>
              <FilesList
                files={
                  fieldsData && fieldsData[fieldID] && fieldsData[fieldID].value
                    ? Array.isArray(fieldsData[fieldID].value)
                      ? fieldsData[fieldID].value
                      : [fieldsData[fieldID].value]
                    : []
                }
                onRequestDelete={index => deleteFile(fieldID, index, groupID)}
              />
            </Files>
          </div>
        );
      }
      default: {
        return false;
      }
    }
  };

  const formFields = fields.map((obj, index) => processField(obj, index));

  return (
    <div className="add-field" style={{ minHeight: '100px' }}>
      {formFields}
    </div>
  );
};

Form.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
  groups: PropTypes.object.isRequired,
  type: PropTypes.object.isRequired,
  fieldsData: PropTypes.object.isRequired,
  deleteFile: PropTypes.func.isRequired,
};

Form.defaultProps = {
  error: null,
  fieldsData: {},
  deleteFile: null,
};

export default Form;
