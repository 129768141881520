import { combineReducers } from 'redux';

import create from './modules/create/reducer';
import appAdmins from './modules/admins/reducer';

import pdfUpload from './modules/componentInterface/file/reducer';
import pdfViewer from './modules/componentInterface/PDFViewer/reducer';
import mediaUpload from './modules/componentInterface/media/reducer';
import appMenu from './modules/appMenu/reducer';
import buttonComponent from './modules/componentInterface/button/reducer';

export default combineReducers({
  create,
  pdfUpload,
  mediaUpload,
  appMenu,
  buttonComponent,
  appAdmins,
  pdfViewer,
});
