/* eslint-disable no-extra-boolean-cast */
/* eslint-disable dot-notation */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { BuildMenu } from '../../../modules/editor/modules/build';
import { CustomiseMenu } from '../../../modules/editor/modules/customise';
import { DistributeMenu } from '../../../modules/distribute';
import { AnalyticsMenu } from '../../../modules/analytics';
import { EngageMenu } from '../../../modules/engage';
import { MemberLoginMenu } from '../../../modules/memberLogin';
import { EcommerceMenu } from '../../../modules/ecommerce';
import { FkAcademyMenu } from '../../../modules/fkAcademy';
import { PgmolMenu } from '../../../modules/pgmol';
import MenuItem from './MenuItem';
import UpgradeButton from '../upgradeButton';
import { usersMenu } from './menu';
import { STAFF_ADMIN } from '../../../config';
import './styles.css';
import { Build as BuildIcon } from '../../components/icons';
import Logo from '../../components/logo';
import { mainWhite } from '../../../_export.scss';
import { whitelabeledHost } from '../../../utils/whitelabeledHost';
import { PGMOL_APPS } from '../../../config/constants';

const linksMapping = {
  build: 'build',
  distribute: 'distribute',
  engage: 'engage',
  analytics: 'analytics',
  'member-login': 'member_login',
  'E-Commerce': 'business_tools',
  pgmol: 'pgmol',
};

class AppSidebar extends Component {
  static propTypes = {
    appname: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    isAppAdmin: PropTypes.bool.isRequired,
    integration: PropTypes.shape({
      siteTitle: PropTypes.string,
      siteUrl: PropTypes.string,
      type: PropTypes.oneOf(['Wix', 'Weebly']),
    }),
    role: PropTypes.string.isRequired,
    appLogo: PropTypes.string.isRequired,
    isSubAdminWithMulipleApps: PropTypes.bool.isRequired,
    sectionsAccess: PropTypes.object.isRequired,
  };

  static defaultProps = {
    integration: null,
    role: '',
  };

  defineNavOptsForUser = () => {
    const { appname, integration, t } = this.props;
    const editorModule = integration ? 'customise' : 'build';
    return (
      <MenuItem
        key={editorModule}
        label={t(`${editorModule}`)}
        href={`/${appname}/editor/${editorModule}`}
        icon={<BuildIcon />}
        active={this.isActive(`/${appname}/editor/${editorModule}`)}
        appname={appname}
      />
    );
  };

  isActive = pathname => this.props.location.pathname.indexOf(pathname) !== -1;
  isWhiteLabelledHost = whitelabeledHost();

  render() {
    const {
      appname,
      integration,
      role,
      isAppAdmin,
      isSubAdminWithMulipleApps,
      sectionsAccess,
      t,
    } = this.props;
    const links = usersMenu(role, appname).filter(link => {
      if (
        sectionsAccess[linksMapping[link.key]] === true ||
        sectionsAccess[linksMapping[link.key]] === undefined
      ) {
        return true;
      }
      return false;
    });

    return (
      <div
        id="sidebar"
        className={`${PGMOL_APPS.indexOf(appname) > -1 && 'pgmol-sidebar'}`}
      >
        <div
          className={`main-menu ${PGMOL_APPS.indexOf(appname) > -1 &&
            'pgmol-main-menu'}`}
          style={{ width: `${PGMOL_APPS.indexOf(appname) > -1 && '137px'}` }}
        >
          <div>
            {this.props.appLogo === null ? (
              isAppAdmin === false &&
              !isSubAdminWithMulipleApps &&
              !this.isWhiteLabelledHost ? (
                <Logo width="100%" height="50px" color={mainWhite} />
              ) : null
            ) : (
              <img
                src={this.props.appLogo}
                width="100%"
                height="50px"
                alt="logo"
              />
            )}

            <div className="main-menu-list">
              {role !== STAFF_ADMIN && this.defineNavOptsForUser()}
              {links.map(path => {
                if (
                  !integration &&
                  (path.key === 'member-login' || path.key === 'E-Commerce')
                ) {
                  return (
                    <MenuItem
                      key={path.key}
                      label={t(`${path.label}`)}
                      href={`/${appname}${path.href}`}
                      icon={path.icon}
                      active={this.isActive(`/${appname}${path.href}`)}
                      appname={appname}
                    />
                  );
                } else if (
                  path.key !== 'member-login' &&
                  path.key !== 'E-Commerce'
                ) {
                  return (
                    <MenuItem
                      key={path.key}
                      label={t(`${path.label}`)}
                      href={`/${appname}${path.href}`}
                      icon={path.icon}
                      active={this.isActive(`/${appname}${path.href}`)}
                      appname={appname}
                    />
                  );
                }
                return false;
              })}
            </div>
          </div>
          {role !== STAFF_ADMIN && <UpgradeButton position="app-sidebar" />}
        </div>
        <Switch>
          {sectionsAccess['distribute'] && (
            <Route path="/:appname/distribute" component={DistributeMenu} />
          )}
          {sectionsAccess['build'] && (
            <Route path="/:appname/editor/build" component={BuildMenu} />
          )}
          {sectionsAccess['build'] && (
            <Route
              path="/:appname/editor/customise"
              component={CustomiseMenu}
            />
          )}
          {sectionsAccess['analytics'] && (
            <Route path="/:appname/analytics" component={AnalyticsMenu} />
          )}
          {sectionsAccess['engage'] && (
            <Route path="/:appname/engage" component={EngageMenu} />
          )}
          {sectionsAccess['member_login'] && (
            <Route path="/:appname/members" component={MemberLoginMenu} />
          )}
          {sectionsAccess['business_tools'] && (
            <Route path="/:appname/ecommerce" component={EcommerceMenu} />
          )}

          <Route path="/:appname/fkacademy" component={FkAcademyMenu} />
          <Route path="/:appname/pgmol" component={PgmolMenu} />
        </Switch>
      </div>
    );
  }
}

export default withTranslation()(AppSidebar);
