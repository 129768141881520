import {
  ADD_APPOINTMENTS_DIALOG,
  APPOINTMENT_ITEM_CHANGE,
  IMAGE_UPLOAD_SUCCESS,
  IMAGE_DELETED,
  TOGGLE_LOADING,
  APPOINTMENT_RESET_DATA,
} from './types';

const initialState = {
  addAppointmentsDialogOpen: false,
  appointmentItem: {},
  isImageUploaded: false,
  loading: false,
};

const appointments = (state = initialState, action) => {
  switch (action.type) {
    case ADD_APPOINTMENTS_DIALOG:
      return {
        ...state,
        addAppointmentsDialogOpen: action.payload,
      };
    case APPOINTMENT_ITEM_CHANGE:
      return {
        ...state,
        appointmentItem: action.payload,
      };

    case IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        isImageUploaded: Math.random(),
      };

    case IMAGE_DELETED:
      return {
        ...state,
        isImageUploaded: Math.random(),
      };
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case APPOINTMENT_RESET_DATA:
      return initialState;

    default:
      return { ...state };
  }
};

export default appointments;
