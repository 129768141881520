import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;

  .disabled-section {
    position: relative;
    z-index: 1;
    opacity:0.3;
    }
  }
  height: 100vh;
  .disabled-message {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 2;

    .container {
      background: rgba(255, 255, 255, 0.9);
      border: 1px solid #d4d4d4;
      border-radius: 20px;
      padding: 35px;
      max-width: 500px;
    }
  }
`;

const DisabledWrapper = ({ children, message, title, content }) => (
  <Wrapper>
    <div className="disabled-section">{children}</div>
    <div className="disabled-message">
      <div className="container">
        <h3>{title}</h3>
        <p>{message}</p>
        {content && <div>{content}</div>}
      </div>
    </div>
  </Wrapper>
);

DisabledWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  message: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.element,
};

DisabledWrapper.defaultProps = {
  content: null,
};

export default DisabledWrapper;
