import { connect } from 'react-redux';

import Admins from './Admins';
import {
  selectAdmin,
  selectAllAdmins,
  deselectAllAdmins,
  deleteAdmins,
  filterAdminData,
  getAppAdmins,
  openNewAdminDialog,
  filterData,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  pending: state.auth.appAdmins.data.pending,
  chunks: state.auth.appAdmins.data.chunks,
  filteredChunks: state.auth.appAdmins.data.filteredChunks,
  error: state.auth.appAdmins.data.error,
  items: state.auth.appAdmins.data.items,
  filter: state.auth.appAdmins.data.filter,
  selected: state.auth.appAdmins.data.selected,
  selectAll: state.auth.appAdmins.data.selectAll,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFilterData: (filteredChunks, sortedItems) =>
    dispatch(filterData(filteredChunks, sortedItems)),
  onSelect: key => dispatch(selectAdmin(key)),
  onSelectAllAdmins: () => dispatch(selectAllAdmins()),
  onDeselectAllAdmins: () => dispatch(deselectAllAdmins()),
  onDeleteSelected: () => dispatch(deleteAdmins(ownProps.match.params.appname)),
  onFilterAdminData: filter => dispatch(filterAdminData(filter)),
  getAppAdmins: () => dispatch(getAppAdmins(ownProps.match.params.appname)),
  openNewAdminDialog: () => dispatch(openNewAdminDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admins);
