const initialState = {
  pending: false,
  success: false,
  email: {
    value: '',
  },
};

const forgottenPassword = (state = initialState, action) => {
  switch (action.type) {
    case 'FORGOTTEN_PASSWORD_FIELD_CHANGE':
      return {
        ...state,
        [action.prop]: {
          value: action.value,
        },
      };

    case 'FORGOTTEN_PASSWORD_ERROR': {
      return {
        ...state,
        pending: false,
        isValid: false,
        [action.prop]: {
          ...state[action.prop],
          isValid: false,
        },
      };
    }

    case 'FORGOTTEN_PASSWORD_SUCCESS':
      return {
        ...initialState,
        success: true,
      };

    case 'CLEAR_FORGOTTEN_PASSWORD_FORM':
      return initialState;

    case 'SUBMIT_FORGOTTEN_PASSWORD':
      return {
        ...state,
        pending: true,
      };

    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
};

export default forgottenPassword;
