import styled from 'styled-components';
import PropTypes from 'prop-types';

const SectionHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 18px;
  margin-top: ${props => props.borderTop && '24px'};
  margin-bottom: ${props => props.borderBottom && '4px'};
  padding-bottom: ${props => props.borderBottom && '20px'};
  border-top: ${props => props.borderTop && '1px solid rgba(0, 0, 0, 0.2)'};
  border-bottom: ${props =>
    props.borderBottom && '1px solid rgba(0, 0, 0, 0.2)'};
`;

SectionHeaderContainer.propTypes = {
  borderTop: PropTypes.bool,
  borderBottom: PropTypes.bool,
};

SectionHeaderContainer.defaultProps = {
  borderTop: false,
  borderBottom: false,
};

export default SectionHeaderContainer;
