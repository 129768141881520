import moment from 'moment';

import firebase from '../../../../firebase';

export const initializeChart = (
  appname,
  step,
  numWeeks,
  dateFormat,
  customStartDate,
  customEndDate,
) => {
  return {
    type: 'INITIALIZE_LINE_CHART',
    appname,
    step,
    numWeeks,
    dateFormat,
    customStartDate,
    customEndDate,
  };
};

export const listenAnalytics = (
  appname,
  type,
  step,
  numWeeks,
  dateFormat,
  orderField,
  customStartDate,
  customEndDate,
) => dispatch => {
  let periodStart;
  let periodEnd;

  if (customStartDate === undefined) {
    periodStart = moment()
      .subtract(numWeeks, 'week')
      .valueOf();
    const weekend = moment().isoWeekday(7);
    periodEnd = moment(weekend).valueOf();
  } else {
    periodStart = moment(customStartDate).valueOf();
    periodEnd = moment(customEndDate).valueOf();
  }

  dispatch({
    type: 'QUERY_LINE_CHART_DATA',
    key: type,
    appname,
    step,
    numWeeks,
    dateFormat,
    customStartDate,
    customEndDate,
  });

  orderField = orderField || 'timestamp';

  return firebase
    .database()
    .ref(`analytics/${appname}/${type}`)
    .orderByChild(orderField)
    .startAt(periodStart)
    .endAt(periodEnd)
    .on('value', snapshot => {
      const data = snapshot.val();
      dispatch({
        type: 'RECEIVE_LINE_CHART_DATA',
        key: type,
        appname,
        step,
        numWeeks,
        data,
        dateFormat,
        customStartDate,
        customEndDate,
      });
    });
};

export const offAnalytics = (appname, type) => {
  const analyticsRef = firebase.database().ref(`analytics/${appname}/${type}`);

  analyticsRef.off('value');
  return {
    type: 'RECIEVE_LINE_CHART_DATA',
    appname,
  };
};
