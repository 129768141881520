import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';

import {
  MobileButton,
  DesktopButton,
} from '../../../components/responsiveButton';
import ModalToolbarTitle from './ModalToolbarTitle';
import ModalToolbarActions from './ModalToolbarActions';
import ModalToolbarClose from './ModalToolbarClose';

const ModalToolbar = ({
  className,
  title,
  cropArray,
  currentIndex,
  onSelectStep,
  onClose,
}) => {
  const actions = cropArray.length > 1 && (
    <ModalToolbarActions tooltip="Crop images seperately for Mobile and Desktop for best results">
      <MobileButton
        tooltip={`Desktop ${cropArray[0].dimensions}`}
        onClick={() => onSelectStep(0)}
        selected={currentIndex === 0}
      />
      <DesktopButton
        tooltip={`Desktop ${cropArray[1].dimensions}`}
        onClick={() => onSelectStep(1)}
        selected={currentIndex === 1}
      />
    </ModalToolbarActions>
  );

  return (
    <div className={className}>
      <ModalToolbarTitle>{title}</ModalToolbarTitle>
      {actions}
      <ModalToolbarClose>
        <IconButton onClick={onClose} tooltip="Close">
          <Close />
        </IconButton>
      </ModalToolbarClose>
    </div>
  );
};

ModalToolbar.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cropArray: PropTypes.array.isRequired,
  currentIndex: PropTypes.number.isRequired,
  onSelectStep: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default styled(ModalToolbar)`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 420px;
  padding: 12px 24px;
`;
