import React from 'react';
import PropTypes from 'prop-types';
import EnhancedButton from 'material-ui/internal/EnhancedButton';

import './styles.css';

const BeezerListItem = ({
  title,
  subtitle,
  leftAction,
  rightAction,
  onClick,
}) => {
  const body = [];
  const children = [];

  if (leftAction) {
    body.push(<div className="list-item-action-left">{leftAction}</div>);
  }

  body.push(
    <div>
      <div className="list-item-title">{title}</div>
      <div className="list-item-subtitle">
        <i>{subtitle}</i>
      </div>
    </div>,
  );

  children.push(body);

  if (rightAction) {
    children.push(<div className="list-item-action-right">{rightAction}</div>);
  }

  if (onClick) {
    return (
      <EnhancedButton className="list-item interactive" onClick={onClick}>
        <div className="list-item-inner-div">{children}</div>
      </EnhancedButton>
    );
  }

  return (
    <div className="list-item">
      <div className="list-item-inner-div">{children}</div>
    </div>
  );
};

BeezerListItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  leftAction: PropTypes.node,
  rightAction: PropTypes.node,
  onClick: PropTypes.func,
};

BeezerListItem.defaultProps = {
  title: '',
  subtitle: '',
  leftAction: null,
  rightAction: null,
  onClick: undefined,
};

export default BeezerListItem;
