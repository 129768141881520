import firebase from '../../../../firebase';
import {
  updateContact,
  receiveLogin,
  sendEmailVerification,
} from '../auth/login/actions';
import {
  createAccount,
  createContact,
  addWelcomeNotice,
  resetQueryStringOffer,
} from '../register/actions';

export const recordUserInfo = data => async (dispatch, getState) => {
  const password = Math.random()
    .toString(20)
    .substr(2, 8);
  dispatch({ type: 'INITIATE_CLICKBANK_REGISTRATION' });
  const userInfo = await decryptClickbankParams(data);
  const appsumoUserId = getState().account.register.appsumoUserId;
  const isAppAdmin =
    (getState().account.auth.profile.admin_appname &&
      getState().account.auth.profile.admin_appname.length > 0) ||
    false;
  if (userInfo === 'error') {
    return dispatch(failedClickbank());
  }
  saveDataToClickbankUsers(userInfo);

  return firebase
    .auth()
    .createUserWithEmailAndPassword(userInfo.cemail, password)
    .then(() => createAccount(userInfo.cemail, userInfo.cname))
    .then(() => createContact(userInfo.cemail, userInfo.cname))
    .then(() => dispatch(receiveLogin()))
    .then(profile => {
      setReceiptInAccount(userInfo.cbreceipt, profile.account);
      dispatch(
        updateContact({
          account: profile.account,
          lists: 'new-signups',
        }),
      );
      return profile;
    })
    .then(profile => profile.account)
    .then(account => !appsumoUserId && !isAppAdmin && addWelcomeNotice(account))
    .then(() => dispatch(resetQueryStringOffer()))
    .then(() => {
      dispatch(sendEmailVerification(userInfo.cemail));
      handleRegistrationResponse(userInfo, 'success');
      dispatch({ type: 'SUCCESS_CLICKBANK_REGISTRATION' });
    })
    .catch(error => {
      handleRegistrationResponse(userInfo, 'error', error);
      dispatch({ type: 'ERROR_CLICKBANK_REGISTRATION' });
    });
};

export const failedClickbank = () => ({
  type: 'FAILED_CLICKBANK',
});

const saveDataToClickbankUsers = userInfo =>
  firebase
    .database()
    .ref('clickbank_users')
    .child(userInfo.cbreceipt)
    .update({ ...userInfo, isActive: true });

const handleRegistrationResponse = (userInfo, status, error) => {
  const cbRef = firebase
    .database()
    .ref(`clickbank_users/${userInfo.cbreceipt}`);
  if (error) {
    cbRef.update({ status, error });
    if (error.code === 'auth/email-already-in-use') {
      changeSubscriptionPlan(userInfo.cbreceipt, userInfo.cemail);
    }
  } else {
    cbRef.update({ status });
  }
};

const setReceiptInAccount = (cbreceipt, account) =>
  firebase
    .database()
    .ref(`accounts/${account}/cbreceipt`)
    .set(cbreceipt);

const changeSubscriptionPlan = (cbreceipt, email) => {
  try {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    fetch(`${process.env.REACT_APP_CLICKBANK_API}/change-subscription-plan`, {
      headers,
      method: 'post',
      mode: 'cors',
      body: JSON.stringify({
        cbreceipt,
        email,
      }),
    });
  } catch (err) {
    console.log('err', err);
  }
};

const decryptClickbankParams = async data => {
  try {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const response = await fetch(
      `${process.env.REACT_APP_CLICKBANK_API}/decrypt-clickbank-data`,
      {
        headers,
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
          data,
        }),
      },
    );
    const json = await response.json();
    if (response && json && response.status === 200) {
      return JSON.parse(json);
    }
    return 'error';
  } catch (error) {
    console.log('err', error);
    return 'error';
  }
};
