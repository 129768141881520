import {
  ADD_STAFF_DIALOG,
  STAFF_MEMBER_CHANGE,
  RESET_STAFF_DATA,
} from './types';
export const addStaffDialogOpen = isOpen => ({
  type: ADD_STAFF_DIALOG,
  payload: isOpen,
});

export const resetStaffData = () => ({
  type: RESET_STAFF_DATA,
});

export const staffMemberChange = member => ({
  type: STAFF_MEMBER_CHANGE,
  payload: member,
});
