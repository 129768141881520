import React from 'react';
import PropTypes from 'prop-types';
import PopoverMui from '@material-ui/core/Popover';
import DatePicker from 'material-ui/DatePicker';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import { Trans, useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/styles';

const Popover = withStyles({
  paper: {
    height: '140px',
    padding: '0 34px',
    width: '260px',
  },
})(PopoverMui);

const Filters = ({ onFilter, report }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dates, setDates] = React.useState({ from: null, to: null });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onDateChange = val => {
    const dateRange = { ...dates, ...val };
    setDates(dateRange);
    onFilter(dateRange);
  };

  const clearDates = () => {
    const dateRange = { from: null, to: null };
    setDates(dateRange);
    onFilter(dateRange);
  };

  return (
    <div
      style={
        !report
          ? {
              display: 'flex',
              paddingBottom: '6px',
              justifyContent: 'flex-end',
              paddingRight: '24px',
              position: 'absolute',
              top: '9px',
              right: '53px',
            }
          : {}
      }
    >
      <Tooltip title={t('Filters')} placement="top">
        <IconButton
          aria-label={t('Print')}
          edge="end"
          onClick={handleClick}
          aria-describedby={id}
        >
          <FilterListIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container spacing={0} justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h6"
              align="center"
              style={{ marginTop: '16px', marginBottom: '-16px' }}
            >
              <Trans>Select a Date Range</Trans>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <DatePicker
              id="from"
              name="from"
              style={{ width: '100%' }}
              className="date-picker"
              autoOk
              value={dates.from}
              floatingLabelText={t('From Date')}
              onChange={(e, timestamp) => onDateChange({ from: timestamp })}
              textFieldStyle={{ width: '87px' }}
              maxDate={dates.to ? new Date(dates.to) : null}
            />
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={5}>
            <DatePicker
              id="to"
              name="to"
              style={{ width: '100%' }}
              autoOk
              className="date-picker"
              value={dates.to}
              onChange={(e, timestamp) => onDateChange({ to: timestamp })}
              floatingLabelText={t('To Date')}
              textFieldStyle={{ width: '87px' }}
              minDate={dates.from ? new Date(dates.from) : null}
            />
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              paddingTop: '25px',
            }}
          >
            <Tooltip title={t('Clear All')} placement="top">
              <IconButton aria-label={t('Clear Filter')} edge="end">
                <DeleteIcon fontSize="small" onClick={clearDates} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
};

Filters.propTypes = {
  onFilter: PropTypes.func.isRequired,
  report: PropTypes.string,
};

Filters.defaultProps = {
  report: '',
};

export default Filters;
