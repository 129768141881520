import { connect } from 'react-redux';

import CPDs from './CPDs';
import {
  filterCPDData,
  getCPDs,
  selectCPD,
  selectAllCPDs,
  deselectAllCPDs,
  deleteCPDs,
  getCPDFields,
  selectMemberBody,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.staff.cpds.list,
  profile: state.account.profile,
  memberBodies: state.auth.memberBodies.data.chunks,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFilterCPDData: (filter, keys) => dispatch(filterCPDData(filter, keys)),
  getCPDs: () => dispatch(getCPDs(ownProps.match.params.appname)),
  onSelect: key => dispatch(selectCPD(key)),
  onSelectAllCPDs: () => dispatch(selectAllCPDs()),
  onDeselectAllCPDs: () => dispatch(deselectAllCPDs()),
  onDeleteSelected: () => dispatch(deleteCPDs(ownProps.match.params.appname)),
  onGetCPDFields: () => dispatch(getCPDFields(ownProps.match.params.appname)),
  selectMemberBody: key => dispatch(selectMemberBody(key)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CPDs);
