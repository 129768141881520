import React from 'react';

const YoutubeIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 49.41 36.99"
  >
    <title>youtube component</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M20.08,26.76V10.23L34.4,18.5Zm1.5-13.94V24.17L31.4,18.5Z"
        />
        <path
          class="cls-1"
          d="M24.67,37c-5.76,0-11.52-.25-17.24-.74A8.08,8.08,0,0,1,0,28.15V8.84A8.08,8.08,0,0,1,7.43.74a201.48,201.48,0,0,1,34.3,0l.24,0a8.09,8.09,0,0,1,7.44,8.1V28.15A8.09,8.09,0,0,1,42,36.25h0l-.24,0C36.07,36.75,30.37,37,24.67,37Zm0-35.49c-5.72,0-11.44.24-17.11.73A6.59,6.59,0,0,0,1.5,8.84V28.15a6.59,6.59,0,0,0,6.06,6.61,200.49,200.49,0,0,0,34,0l.25,0a6.58,6.58,0,0,0,6.06-6.6V8.84a6.58,6.58,0,0,0-6.06-6.6l-.25,0Q33.17,1.5,24.67,1.5Z"
        />
      </g>
    </g>
  </svg>
);

export default YoutubeIcon;
