import React, { useState } from 'react';

export const StaffContext = React.createContext({});

export const StaffProvider = ({ children }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [dialCode, setDialCode] = useState('+44');

  return (
    <StaffContext.Provider
      value={{
        name,
        setName,
        email,
        setEmail,
        mobile,
        setMobile,
        imageUrl,
        setImageUrl,
        isButtonDisabled,
        setIsButtonDisabled,
        dialCode,
        setDialCode,
      }}
    >
      {children}
    </StaffContext.Provider>
  );
};
