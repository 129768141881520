import React from 'react';
import PropTypes from 'prop-types';
import CheckCircle from 'material-ui/svg-icons/action/check-circle';
import InfoCircle from 'material-ui/svg-icons/action/info';
import ErrorCirlce from 'material-ui/svg-icons/alert/error';
import styled from 'styled-components';

const CircularValidationWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  svg {
    height: 18px !important;
    width: 18px !important;
  }
  div {
    border: 2px solid ${props => props.color || '#f3f3f3'};
    position: absolute;
    z-index: 2;
    background: white;
    width: 140px;
    bottom: 24px;
    text-align: center;
    padding: 4px 10px;
    font-size: 10px;
    color: #333;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
    }
    &:before {
      bottom: -8px;
      left: 60px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid ${props => props.color || '#f3f3f3'};
    }
    &:after {
      bottom: -6px;
      left: 62px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #fff;
    }
  }
  &:hover {
    div {
      opacity: 1;
    }
  }
`;

const CircularValidation = ({
  isValid,
  errorMsg,
  successMsg,
  defaultMsg,
  showDefault,
  style = { height: 18, width: 18 },
  hideValidIcon,
}) => {
  const states = {
    success: {
      icon: <CheckCircle color="#78ffc5" style={style} />,
      color: '#78ffc5',
      msg: successMsg,
    },
    error: {
      icon: <ErrorCirlce color="#D9534F" style={style} />,
      color: '#D9534F',
      msg: errorMsg,
    },
    default: {
      icon: <InfoCircle color="#70C5E7" style={style} />,
      color: '#70C5E7',
      msg: defaultMsg,
    },
    null: {
      icon: <></>,
      color: '',
      msg: '',
    },
  };
  const getState = () => {
    if (isValid === null) {
      if (showDefault) return states.default;
    } else {
      return isValid
        ? hideValidIcon
          ? states.null
          : states.success
        : states.error;
    }
    return {};
  };

  const { msg, icon, color } = getState();
  return icon ? (
    <CircularValidationWrapper color={color}>
      {msg && <div> {msg} </div>}
      {icon}
    </CircularValidationWrapper>
  ) : (
    <span />
  );
};

CircularValidation.propTypes = {
  isValid: PropTypes.bool,
  errorMsg: PropTypes.string,
  successMsg: PropTypes.string,
  defaultMsg: PropTypes.string,
  showDefault: PropTypes.bool,
  style: PropTypes.object,
  hideValidIcon: PropTypes.bool,
};

CircularValidation.defaultProps = {
  isValid: null,
  errorMsg: null,
  successMsg: null,
  defaultMsg: null,
  showDefault: false,
  style: null,
  hideValidIcon: false,
};

export default CircularValidation;
