import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { FlatButton } from '../buttons';
import { withTranslation } from 'react-i18next';
import MemberLoginUpgradeDialog from '../../../modules/memberLogin/modules/upgradeDialog';

import UpgradeDialog from '../dialog';

class PrivateRoute extends React.Component {
  state = {
    showDialog: true,
  };

  toggleDialog = () => {
    this.setState({ showDialog: false });
    this.props.onRequestClose()
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.props.requiresUpgrade) {
        this.setState({ showDialog: true });
      }
    }
  }

  render() {
    const { t } = this.props;
    return this.props.requiresUpgrade === true ? (
      <div className="scroll-container white-bg">
        <div className="flex-container" style={{ marginLeft: '12vw' }}>
          <div
            className="empty-state"
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '2vh',
            }}
          >
            {/* <Upgrade /> */}
            {/* <FlatButton
              label="Upgrade"
              onClick={() =>
                this.props.history.push(
                  `/${this.props.match.params.appname}/choose_plan`,
                )
              }
              primary
            /> */}
            {this.props.logout && (
              <FlatButton
                label={t('Log Out')}
                onClick={() => this.props.logout()}
                primary
              />
            )}
          </div>
          {this.props.children}

          <MemberLoginUpgradeDialog
            plan={this.props.plan}
            feature={this.props.feature}
          />

          <UpgradeDialog
            open={this.state.showDialog}
            match={this.props.match}
            history={this.props.history}
            heading={this.props.heading || t('Upgrade Plan')}
            content={this.props.content}
            onRequestClose={this.toggleDialog}
          />
        </div>
      </div>
    ) : (
      <Route path={this.props.path}>
        <this.props.component {...this.props} />
      </Route>
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  path: PropTypes.string,
  requiresUpgrade: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

PrivateRoute.defaultProps = {
  component: null,
  path: '',
  requiresUpgrade: '',
  onRequestClose: () => {},
};

export default withTranslation()(PrivateRoute);
