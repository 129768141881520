import React from 'react';
import PropTypes from 'prop-types';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import './styles.css';
import themeStyles from '../../../../_export.scss';

const CustomFloatingActionButton = ({ icon, onClick }) => (
  <span className="floating-action-btn-outline">
    <span>
      <FloatingActionButton
        onClick={onClick}
        backgroundColor={themeStyles.mainWhite}
        iconStyle={{ fill: themeStyles.primaryColor }}
        zDepth={1}
        mini
      >
        {icon}
      </FloatingActionButton>
    </span>
  </span>
);

CustomFloatingActionButton.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CustomFloatingActionButton;
