import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Container from '../../../../../../../app/components/container';
import { Tabs, Tab } from '../../../../../components/tabs';

import SectionHeader from '../../../../../components/sectionHeader';
import DesignToggle from '../../../../../components/designToggle';
import ColorPicker from '../../../../../components/colorPicker';

import Toggle from 'material-ui/Toggle';
import LayoutOption from '../../tabs/layoutTab/layoutOption';
import LayoutTab from '../../tabs/layoutTab';
import HorizontalSlider from './layouts/HorizontalSlider';
import VerticalSlider from './layouts/VerticalSlider';
import HorizontalSliderCaption from './layouts/HorizontalSliderCaption';
import VerticalSliderCaption from './layouts/VerticalSliderCaption';
import Blocks from './layouts/Blocks';
import MultipleImageUpload from '../../../../../internals/multipleImageUpload';
import SelectField from '../../../../../../../app/components/selectField';
import MenuItem from 'material-ui/MenuItem';
import TextField from '../../../../../../../app/components/textField';
import { FlatButton } from '../../../../../../../app/components/buttons';
import themeStyles from '../../../../../../../_export.scss';
const Gallery = ({
  component,
  appname,
  onChange,
  onDesignChange,
  data,
  design,
  updateCaptionText,
  onCaptionToggle,
  onDownloadToggle,
}) => {
  const { t } = useTranslation();
  const [captionsToggle, setCaptionsToggle] = useState(false);
  const [captionText, setCaptionText] = useState();
  const [downloadToggle, setDownloadToggle] = useState(false);

  useEffect(() => {
    setCaptionsToggle(data.showCaption);
    setDownloadToggle(data.allowDownloads);
    setCaptionText(data.gallery);
  }, []);

  useEffect(() => {
    if (captionText !== data.gallery) {
      setCaptionText(data.gallery);
    }
  }, [data.gallery]);

  const galleryLayoutOption = () => {
    const style = {
      width: '100%',
      heigth: '100%',
    };
    return {
      options: [
        <LayoutOption
          key="gallery_layout"
          wrapperKey="gallery_layout"
          grid={2}
          tooltip={t('Change your gallery component template')}
          title={t('Gallery templates')}
          active={component.layout}
          items={[
            { value: 'HorizontalSlider', icon: HorizontalSlider, style },
            { value: 'VerticalSlider', icon: VerticalSlider, style },
            {
              value: 'HorizontalSliderCaption',
              icon: HorizontalSliderCaption,
              style,
            },
            {
              value: 'VerticalSliderCaption',
              icon: VerticalSliderCaption,
              style,
            },
            { value: 'Blocks', icon: Blocks, style },
          ]}
        />,
      ],
    };
  };

  const handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    onDesignChange(prop, rgba);
  };

  const handleCaptionTextChange = (value, id) => {
    setCaptionText({
      ...captionText,
      [id]: { caption: value, image: captionText[id].image },
    });
  };

  const saveCaptionHandler = () => {
    updateCaptionText(captionText, component);
  };

  const handleCaptionToggle = isToggled => {
    setCaptionsToggle(isToggled);
    onCaptionToggle(isToggled);
  };

  const handleDownloadToggle = isToggled => {
    setDownloadToggle(isToggled);
    onDownloadToggle(isToggled);
  };

  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container>
          <SectionHeader
            title={t('Image Upload')}
            tooltip={t('Upload an image for your Gallery')}
          />
          <MultipleImageUpload
            data={data}
            firebaseUrl={`/apps/${appname}/draft/lazy_data/${component.lazy_data}/gallery`}
          />

          <SectionHeader
            title={t('Image Download')}
            tooltip={t('Add download button for images')}
            actions={
              <Toggle
                toggled={downloadToggle}
                onToggle={(e, isToggled) => handleDownloadToggle(isToggled)}
                trackSwitchedStyle={{
                  backgroundColor: themeStyles.primaryColorLight,
                }}
                thumbSwitchedStyle={{
                  backgroundColor: themeStyles.primaryColor,
                }}
              />
            }
          />

          {captionText && (
            <SectionHeader
              title={t('Add Captions')}
              tooltip={t('Add Captions to Images')}
              actions={
                <Toggle
                  toggled={captionsToggle}
                  onToggle={(e, isToggled) => handleCaptionToggle(isToggled)}
                  trackSwitchedStyle={{
                    backgroundColor: themeStyles.primaryColorLight,
                  }}
                  thumbSwitchedStyle={{
                    backgroundColor: themeStyles.primaryColor,
                  }}
                />
              }
            />
          )}

          {captionsToggle ? (
            <>
              {Object.keys(captionText).map((imgId, index) => (
                <TextField
                  id={imgId}
                  key={index}
                  value={
                    captionText[imgId].caption !== undefined
                      ? captionText[imgId].caption
                      : ''
                  }
                  onChange={value => handleCaptionTextChange(value, imgId)}
                  placeholder={`${t('Caption for Image')} ${index + 1}`}
                />
              ))}
              <FlatButton
                label={t('Save Changes')}
                disabled={false}
                primary
                onClick={saveCaptionHandler}
              />
            </>
          ) : null}
        </Container>
      </Tab>
      <Tab label={t('design')}>
        <Container>
          <DesignToggle component={component} onDesignChange={onDesignChange} />
          <ColorPicker
            label={t('Background Color')}
            color={design.backgroundColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('backgroundColor')}
          />
          <ColorPicker
            label={t('Border Color')}
            color={design.borderColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('borderColor')}
          />
          <ColorPicker
            label={t('Caption Background Color')}
            color={design.captionBackgroundColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('captionBackgroundColor')}
          />
          <ColorPicker
            label={t('Caption Font Color')}
            color={design.captionFontColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('captionFontColor')}
          />
          <SelectField
            label={t('No of Images in Column')}
            value={data.imageColumns}
            disabled={!component.design.enabled}
            options={[
              <MenuItem key="1" value={1} primaryText="1" />,
              <MenuItem key="2" value={2} primaryText="2" />,
              <MenuItem key="2" value={3} primaryText="3" />,
              <MenuItem key="2" value={4} primaryText="4" />,
              <MenuItem key="2" value={5} primaryText="5" />,
            ]}
            onChange={(index, value) => onChange('imageColumns', value)}
          />
        </Container>
      </Tab>
      <Tab label={t('layout')}>
        <LayoutTab
          componentKey={component.key}
          appname={appname}
          {...galleryLayoutOption()}
        />
      </Tab>
    </Tabs>
  );
};

export default Gallery;
