import { connect } from 'react-redux';

import Listing from './Listing';
import {
  selectType,
  selectAllTypes,
  deselectAllTypes,
  deleteMember,
  filterTypeData,
  getWorkforceList,
  getWorkforceGroupData,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  pending: state.auth.workforceMembers.listPage.data.pending,
  chunks: state.auth.workforceMembers.listPage.data.chunks,
  allRecords: state.auth.workforceMembers.listPage.data,
  filteredChunks: state.auth.workforceMembers.listPage.data.filteredChunks,
  error: state.auth.workforceMembers.listPage.data.error,
  items: state.auth.workforceMembers.listPage.data.items,
  filter: state.auth.workforceMembers.listPage.data.filter,
  selected: state.auth.workforceMembers.listPage.data.selected,
  selectAll: state.auth.workforceMembers.listPage.data.selectAll,
  allGroups: state.auth.memberGroups.listPage.data.chunks,
  allGroupsData: state.auth.workforceMembers.listPage.data.allGroupsData,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelect: key => dispatch(selectType(key)),
  onSelectAllTypes: () => dispatch(selectAllTypes()),
  onDeselectAllTypes: () => dispatch(deselectAllTypes()),
  onDeleteSelected: () => dispatch(deleteMember(ownProps.match.params.appname)),
  onFilterTypeData: filter => dispatch(filterTypeData(filter)),
  getWorkforceList: () =>
    dispatch(getWorkforceList(ownProps.match.params.appname)),

  getWorkforceGroupData: () =>
    dispatch(getWorkforceGroupData(ownProps.match.params.appname)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
