import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import DisabledWrapper from './DisabledWrapper';
// import CreateUser from './modules/createUser';
// import DefaultGroup from './modules/defaultGroup';
// import AuthType from './AuthType';
import PrivacyPolicy from './modules/privacyPolicy';
import SignupAs from './modules/signupAs';
import VerificationPage from './modules/verificationPage';
import ChatProfileSettings from './modules/chatProfileSettings';
import ProfileFields from './modules/profileFields';
import FieldsVisibility from './modules/fieldsVisibility';
import { HS_FEATURES_ENABLED_APPS } from '../../../../config';
import Subscriptions from './modules/subscriptions';
import RestrictedAccess from './modules/restrictedAccess';
import ChatAdmin from './modules/ChatAdmin/ChatAdmin';
import './styles.css';
import PublicPages from './modules/publicPages';

class Settings extends Component {
  static propTypes = {
    enabled: PropTypes.bool.isRequired,
    // type: PropTypes.string.isRequired,
    // toggleAuth: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
  };

  static defaultProps = {
    error: null,
    type: 'single',
  };

  renderContent = () => (
    <div className="auth-panel">
      {/* <AuthType {...this.props} /> */}
      {/* <CreateUser /> */}
      {/* <DefaultGroup /> */}
      <SignupAs />
      <PrivacyPolicy />
      <VerificationPage />
      <ChatProfileSettings />
      <ProfileFields />
      {HS_FEATURES_ENABLED_APPS.indexOf(this.props.match.params.appname) >
        -1 && <FieldsVisibility />}
      <Subscriptions />
      <RestrictedAccess />
      <PublicPages appname={this.props.match.params.appname} />
      <ChatAdmin
        users={this.props.users}
        appname={this.props.match.params.appname}
      />
    </div>
  );

  render() {
    const { t } = this.props;
    const content = this.renderContent();

    return this.props.enabled ? (
      content
    ) : (
      <DisabledWrapper
        title={t('User Authentication Unavailable')}
        message={t(
          `To activate user authentication in your app please toggle it in the left hand menu`,
        )}
      >
        {content}
      </DisabledWrapper>
    );
  }
}

export default withTranslation()(Settings);
