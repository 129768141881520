import React from 'react';
import PropTypes from 'prop-types';

import { whitelabeledHost } from '../../../utils/whitelabeledHost';
import Logo from '../../components/logo';

import './styles.css';

const isWhiteLabeledHost = whitelabeledHost();

const LogoToolbar = ({ inverted }) => (
  <section className="toolbar simple-toolbar transparent">
    <div className="logo-container">
      {!isWhiteLabeledHost && <Logo inverted={inverted} />}
    </div>
  </section>
);

LogoToolbar.propTypes = {
  inverted: PropTypes.bool,
};

LogoToolbar.defaultProps = {
  inverted: false,
};

export default LogoToolbar;
