import { connect } from 'react-redux';

import BuyCredits from './BuyCredits';
import { setAmount, createPaymentIntent } from './actions';
import { withRouter } from 'react-router-dom';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';
const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.account.buyCredits,
  profile: state.account.profile,
  intl: state.account.intl,
  integration: state.account.organisation.integration,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAmount: integration =>
    dispatch(setAmount(ownProps.location.query, integration)),
  handleCharge: (stripe, cost, metadata) =>
    dispatch(createPaymentIntent(stripe, cost, metadata)),
  displayPaymentError: msg => dispatch(openSnackbar(msg)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BuyCredits),
);
