/* eslint-disable linebreak-style */
import {
  accelerateComponents,
  appSumoBasicTierComponents,
} from './planComponents';

import {
  TIER_1_PLAN_ID,
  TIER_2_PLAN_ID,
  TIER_3_PLAN_ID,
  TIER_4_PLAN_ID,
  TIER_5_PLAN_ID,
} from './constants';

const NUMBER_OF_APPS = 'Number of Apps';
const SUB_ACCOUNTS = 'Sub Accounts';
const INITIAL_SMS = 'Initial SMS credit';
const REMOVE_BRANDING = 'Remove Branding/White Labeling';
const ADVANCED_FEATURES = 'Advanced Business Tool Features';

export const featues = [
  NUMBER_OF_APPS,
  SUB_ACCOUNTS,
  INITIAL_SMS,
  REMOVE_BRANDING,
  ADVANCED_FEATURES,
];

export const plans = {
  [TIER_1_PLAN_ID]: {
    isAppSumoPlan: true,
    plan_id: TIER_1_PLAN_ID,
    title: 'Tier 1',
    components: appSumoBasicTierComponents,
    subTitle: 'One Time Purchase of',
    dealPrice: '$79',
    annualRetailValue: '$456',
    sms_credit: 300,
    whatsapp_sms_credit: 300,
    legacy_flag: 3,
    features: [
      {
        feature: NUMBER_OF_APPS,
        value: '3',
      },
      {
        feature: SUB_ACCOUNTS,
        value: false,
      },
      {
        feature: INITIAL_SMS,
        value: '300',
      },
    ],
  },
  [TIER_2_PLAN_ID]: {
    isAppSumoPlan: true,
    plan_id: TIER_2_PLAN_ID,
    title: 'Tier 2',
    components: accelerateComponents,
    subTitle: 'One Time Purchase of',
    dealPrice: '$139',
    annualRetailValue: '$1200',
    sms_credit: 600,
    whatsapp_sms_credit: 600,
    legacy_flag: 15,
    features: [
      {
        feature: NUMBER_OF_APPS,
        value: '15',
      },
      {
        feature: SUB_ACCOUNTS,
        value: '15',
      },
      {
        feature: INITIAL_SMS,
        value: '600',
      },
      {
        feature: REMOVE_BRANDING,
        value: true,
      },
      {
        feature: ADVANCED_FEATURES,
        value: true,
      },
    ],
  },
  [TIER_3_PLAN_ID]: {
    isAppSumoPlan: true,
    plan_id: TIER_3_PLAN_ID,
    title: 'Tier 3',
    components: accelerateComponents,
    subTitle: 'One Time Purchase of',
    dealPrice: '$199',
    annualRetailValue: '$3600',
    sms_credit: 1200,
    whatsapp_sms_credit: 1200,
    legacy_flag: 50,
    features: [
      {
        feature: NUMBER_OF_APPS,
        value: '50',
      },
      {
        feature: SUB_ACCOUNTS,
        value: '50',
      },
      {
        feature: INITIAL_SMS,
        value: '1200',
      },
      {
        feature: REMOVE_BRANDING,
        value: true,
      },
      {
        feature: ADVANCED_FEATURES,
        value: true,
      },
    ],
  },
  [TIER_4_PLAN_ID]: {
    isAppSumoPlan: true,
    plan_id: TIER_4_PLAN_ID,
    title: 'Tier 4',
    components: accelerateComponents,
    subTitle: 'One Time Purchase of',
    dealPrice: '$259',
    annualRetailValue: '$7200',
    sms_credit: 2000,
    whatsapp_sms_credit: 2000,
    legacy_flag: 100,
    features: [
      {
        feature: NUMBER_OF_APPS,
        value: '100',
      },
      {
        feature: SUB_ACCOUNTS,
        value: '100',
      },
      {
        feature: INITIAL_SMS,
        value: '2000',
      },
      {
        feature: REMOVE_BRANDING,
        value: true,
      },
      {
        feature: ADVANCED_FEATURES,
        value: true,
      },
    ],
  },
  [TIER_5_PLAN_ID]: {
    isAppSumoPlan: true,
    plan_id: TIER_5_PLAN_ID,
    title: 'Tier 5',
    components: accelerateComponents,
    subTitle: 'One Time Purchase of',
    dealPrice: '$590',
    annualRetailValue: '$9900',
    sms_credit: 4000,
    whatsapp_sms_credit: 4000,
    legacy_flag: 10000,
    features: [
      {
        feature: NUMBER_OF_APPS,
        value: 'Unlimited',
      },
      {
        feature: SUB_ACCOUNTS,
        value: 'Unlimited',
      },
      {
        feature: INITIAL_SMS,
        value: '4000',
      },
      {
        feature: REMOVE_BRANDING,
        value: true,
      },
      {
        feature: ADVANCED_FEATURES,
        value: true,
      },
    ],
  },
};

export default plans;
