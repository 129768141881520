import styled from 'styled-components';

const HiddenInput = styled.input.attrs({
  type: 'file',
  accept: 'image/jpeg,image/jpg,image/png,image/gif',
})`
  display: none;
`;

export default HiddenInput;
