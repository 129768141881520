import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import RatingDetails from './RatingDetails';

import {
  closeRatingDetailDialog,
  previousResponse,
  nextResponse,
  previousFeedback,
  nextFeedback,
} from './actions';
import { deleteSelectedRating } from '../actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.analytics.rating.ratingDetails,
  responseKey: state.analytics.rating.ratingDetails.key,
  totalResponses: state.analytics.rating.data.items.length,
  currentResponse:
    state.analytics.rating.data.items
      .map(key => key === state.analytics.rating.ratingDetails.key)
      .indexOf(true) + 1,
  ratings: state.analytics.rating.data.ratings,
  items: state.analytics.rating.data.items,
  filteredChunks: state.analytics.rating.data.filteredChunks,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRequestClose: () => dispatch(closeRatingDetailDialog()),
  onPreviousResponse: (currentResponse, ratings, items) =>
    dispatch(previousResponse(currentResponse, ratings, items)),
  onNextResponse: (currentResponse, ratings, items) =>
    dispatch(nextResponse(currentResponse, ratings, items)),
  onDelete: (ratingComponent, selected) =>
    dispatch(
      deleteSelectedRating(
        ownProps.match.params.appname,
        ratingComponent,
        selected,
      ),
    ),
  onPreviousFeedback: currentFeedback =>
    dispatch(previousFeedback(currentFeedback)),
  onNextFeedback: currentFeedback => dispatch(nextFeedback(currentFeedback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RatingDetails),
);
