import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IconButton from 'material-ui/IconButton';
import Download from 'material-ui/svg-icons/file/cloud-download';
import Delete from 'material-ui/svg-icons/action/delete';
import DataUsage from 'material-ui/svg-icons/device/data-usage';
import Notes from 'material-ui/svg-icons/action/list';
import { withTranslation } from 'react-i18next';
import DialogConfirmation from '../../components/dialogConfirmation';

import './styles.css';

class ModalDetailsActions extends Component {
  static propTypes = {
    onDownloadCsv: PropTypes.func.isRequired,
    onDownloadPng: PropTypes.func,
    deleteConfirmationMessage: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    chartsToggled: PropTypes.func,
  };

  static defaultProps = {
    chartsToggled: null,
    onDownloadPng: null,
  };

  state = {
    confirmDeleteDialogOpen: false,
    showCharts: false,
  };

  handleCloseDeleteDialog = () =>
    this.setState({ confirmDeleteDialogOpen: false });

  render() {
    const {
      onDownloadCsv,
      onDownloadPng,
      deleteConfirmationMessage,
      onDelete,
      chartsToggled,
      t,
    } = this.props;
    return (
      <div className="modal-details-actions">
        {chartsToggled && (
          <IconButton
            tooltip={`${t('View')} ${
              this.state.showCharts ? `${t('Details')}` : `${t('Charts')}`
            }`}
            onClick={() => {
              chartsToggled();
              this.setState({ showCharts: !this.state.showCharts });
            }}
          >
            {!this.state.showCharts ? <DataUsage /> : <Notes />}
          </IconButton>
        )}
        <IconButton
          onClick={
            chartsToggled && onDownloadPng && this.state.showCharts
              ? onDownloadPng
              : onDownloadCsv
          }
          tooltip={`${t('Download')}`}
        >
          <Download />
        </IconButton>
        <IconButton
          onClick={() => {
            this.setState({ confirmDeleteDialogOpen: true });
          }}
          tooltip={`${t('Delete')}`}
        >
          <Delete />
        </IconButton>
        <DialogConfirmation
          open={this.state.confirmDeleteDialogOpen}
          onClose={this.handleCloseDeleteDialog}
          message={deleteConfirmationMessage}
          action={onDelete}
        />
      </div>
    );
  }
}

export default withTranslation()(ModalDetailsActions);
