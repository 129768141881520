import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Loyalty from './Loyalty';
import {
  stampsChange,
  freeItemChange,
  designChange,
  fieldChange,
  getIconUrls,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  design: ownProps.component.design,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onStampsChange: (field, value) =>
    dispatch(
      stampsChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onFreeItemChange: (field, value) =>
    dispatch(
      freeItemChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onDesignChange: (field, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        field,
        value,
      ),
    ),
  onChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onGetIconUrls: () => dispatch(getIconUrls()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Loyalty),
);
