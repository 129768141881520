const buySMS = (state = {}, action) => {
  switch (action.type) {
    case 'SET_SMS_BUY_AMOUNT':
      return {
        bundle: action.bundle,
        amount: action.amount,
        cost: action.cost,
      };
      
    case 'LOGOUT_SUCCESS':
      return {};
    default:
      return state;
  }
};

export default buySMS;
