import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Modal from '../../../account/components/modal';
import { CTAButton } from '../../../../app/components/buttons';
import { getAppUrl } from '../../../../utils/appDetails';
import { rocket } from './images';
import { whitelabeledHost } from '../../../../utils/whitelabeledHost';

const isWhiteLabeledHost = whitelabeledHost();

const Header = styled.h1`
  font-size: 40px;
  color: rgba(30, 30, 30, 0.6);
  text-align: center;
  text-transform: capitalize;
  margin: 0 0 5px;
`;

const Container = styled.div`
  padding: 0 15px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-flow: inherit;
  max-height: 100%;
  align-items: center;
  strong: {
    font-weight: bold;
  }
  p {
    margin: 5px 0;
  }
  .img_wrapper {
    position: relative;
    display: block;
    height: 100%;
    img {
      max-width: 65%;
      padding: 20px;
    }
  }
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    .img_wrapper {
      height: 20%;
      img {
        height: 100%;
      }
    }
  }
`;

const SkipButton = styled.button`
  margin: 0 auto;
  font-size: 14px;
`;

const marginStyle = {
  marginTop: '15px',
};

const DomainChangeModal = ({
  open,
  domain,
  metaPendingState,
  handleClose,
  handleSave,
  isAppAdmin,
}) => (
  <Modal open={open} showHeader={false} bodyClassName="beezer-modal">
    <Header>Domain Change</Header>
    <Container>
      <strong style={marginStyle}>
        Your are about to change your app domain.
      </strong>
      {!isAppAdmin && !isWhiteLabeledHost ? (
        <>
          <p style={marginStyle}>
            This action will make your app available in a new url. In order to{' '}
            <br />
            change the url your app will be published automatically and you will{' '}
            <br />
            be able to find it at <strong> {getAppUrl(domain)}</strong>
          </p>
          <p>
            <span style={{ color: '#fe4d4d', display: 'inline' }}>
              Warning:
            </span>{' '}
            Any existing users will not receive updates <br />
            unless they reinstall the app using the new URL.
          </p>
        </>
      ) : (
        <p>
          <span style={{ color: '#fe4d4d', display: 'inline' }}>Warning:</span>{' '}
          This action will automatically publish your app.
        </p>
      )}

      <div className="img_wrapper">
        <img alt="modal_image" src={rocket} />
      </div>
      <CTAButton
        label="Save & Publish"
        outlineRGB="195, 207, 47"
        style={{
          margin: '10px auto',
          display: 'block',
          maxWidth: '210px',
          marginBottom: '1rem',
          alignItems: 'center',
          height: '53px',
        }}
        onClick={() => handleSave(metaPendingState, domain)}
      />
      <SkipButton onClick={handleClose}> Cancel </SkipButton>
    </Container>
  </Modal>
);

DomainChangeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  domain: PropTypes.string,
  metaPendingState: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  isAppAdmin: PropTypes.bool,
};

DomainChangeModal.defaultProps = {
  integration: {},
  metaPendingState: {},
  domain: '',
  isAppAdmin: false,
};

export default DomainChangeModal;
