import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { LogoToolbar } from '../../../../app/modules/toolbar';
import './styles.css';
import TextField from '../../../../app/components/textField';
import themeStyles from '../../../../_export.scss';
import { RaisedButton } from '../../../../app/components/buttons';
import firebase from '../../../../firebase';

const styles = {
  buttonRoot: {
    marginTop: '40px',
  },
  SignUpLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.secondaryTextColor,
  },
  LogInLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.primaryTextColor,
  },
  textFieldUnderlineStyle: {
    borderColor: themeStyles.mainGrey,
  },
  textFieldLabelStyle: {
    color: themeStyles.mainGrey,
    fontSize: '20px',
  },
  ResetLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.primaryTextColor,
    padding: '0 30px',
    textTransform: 'capitalize',
  },
  errorText: {
    color: '#FF0000',
    fontSize: '14px',
    fontWeight: 500,
  },
  message: {
    width: '100%',
    maxWidth: '500px',
  },
};

const UpdatePassword = ({ pending, success, error, location, submitForm }) => {
  const [details, setDetails] = useState({
    password: '',
    confirmPassword: '',
  });
  const query = new URLSearchParams(location.search);
  const logintoken = query.get('logintoken');
  const email = query.get('email');

  const [validationError, setValidationError] = useState('');

  const handleInputChange = (name, value) => {
    setDetails(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (logintoken) {
      const signIn = async () => {
        try {
          await firebase.auth().signInWithCustomToken(logintoken);
        } catch (err) {
          console.error(err);
        }
      };
      signIn();
    }
  }, []);

  const submitFormHandler = async e => {
    e.preventDefault();
    if (details.password === '' || details.password === '') {
      setValidationError('All fields are required.');
    } else if (details.password !== details.confirmPassword) {
      setValidationError('Passwords must be the same.');
    } else if (details.password.length < 6) {
      setValidationError('Password must be a minimum of 6 characters.');
    } else {
      setValidationError('');
      const accessToken = await firebase.auth().currentUser.getIdToken();

      const data = {
        email,
        accessToken,
        requestOrigin: 'platform',
        newPassword: details.password,
      };

      submitForm(data, setDetails);
    }
  };
  return (
    <div className="white-backdrop">
      <LogoToolbar />
      <div className="updatePassword">
        <div style={styles.message}>
          {success && (
            <div className="notification notificationSuccess">
              Password successfully updated. <Link to="/">Click here</Link> to
              login
            </div>
          )}
          {error && (
            <div className="notification notificationError">
              Oops unable to update password. <Link to="/">Click here</Link> to
              go back
            </div>
          )}
        </div>
        {!success && (
          <>
            <div className="updatePassword-header">
              <p>Reset your password</p>
            </div>

            <div className="login-input-container">
              <div className="login-input-group">
                <form
                  onSubmit={submitFormHandler}
                  autoComplete="off"
                  noValidate
                >
                  <TextField
                    id="password"
                    type="password"
                    placeholder="Enter New Password"
                    name="password"
                    value={details.password}
                    onChange={val => handleInputChange('password', val)}
                    labelStyle={styles.textFieldLabelStyle}
                    underlineStyle={styles.textFieldUnderlineStyle}
                  />
                  <TextField
                    id="password"
                    type="password"
                    placeholder="Confiirm New Password"
                    name="confirmPassword"
                    value={details.confirmPassword}
                    onChange={val => handleInputChange('confirmPassword', val)}
                    labelStyle={styles.textFieldLabelStyle}
                    underlineStyle={styles.textFieldUnderlineStyle}
                  />
                  <RaisedButton
                    label="Reset Password"
                    backgroundColor={themeStyles.secondaryTextColor}
                    disabledBackgroundColor={themeStyles.primaryColorLight}
                    style={styles.buttonRoot}
                    labelStyle={styles.ResetLabelStyle}
                    onClick={submitFormHandler}
                    disabled={pending}
                  />
                  {validationError && (
                    <p style={styles.errorText}>{validationError}</p>
                  )}
                </form>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

UpdatePassword.propTypes = {
  pending: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  submitForm: PropTypes.func.isRequired,
};

export default withRouter(UpdatePassword);
