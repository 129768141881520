import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PrivacyPolicy from './PrivacyPolicy';

import { togglePrivacyPolicy, updatePrivacyPolicyUrl } from './actions';

const mapStateToProps = (state, ownProps) => {
  const defaultGroup = state.auth.groups.data.chunks.default;
  return {
    ...ownProps,
    enabled:
      defaultGroup &&
      defaultGroup.privacyPolicy &&
      defaultGroup.privacyPolicy.enabled
        ? defaultGroup.privacyPolicy.enabled
        : false,
    url:
      defaultGroup &&
      defaultGroup.privacyPolicy &&
      defaultGroup.privacyPolicy.url
        ? defaultGroup.privacyPolicy.url
        : '',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  togglePrivacyPolicy: value =>
    dispatch(togglePrivacyPolicy(ownProps.match.params.appname, value)),
  changePrivacyPolicyUrl: value =>
    dispatch(updatePrivacyPolicyUrl(ownProps.match.params.appname, value)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PrivacyPolicy),
);
