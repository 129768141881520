import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Home = ({ fill }) => (
  <svg
    className="icon homepage"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <g id="Layer_1" data-name="Layer 1">
      <polygon
        style={{ fill }}
        points="28.27 30.99 21.45 30.99 21.45 20.08 10.54 20.08 10.54 30.99 3.73 30.99 3.73 14.37 16 0.99 28.27 14.38 28.27 30.99"
      />
      <path d="M27.27,32H21.45a1,1,0,0,1,0-2h5.82V14.38a1,1,0,0,1,2,0V30A2,2,0,0,1,27.27,32Z" />
      <path d="M10.55,32H4.73a2,2,0,0,1-2-2V14.38a1,1,0,0,1,2,0V30h5.82a1,1,0,1,1,0,2Z" />
      <path d="M31,18.35a1,1,0,0,1-.74-.32L16,2.47,1.74,18A1,1,0,1,1,.26,16.67L15.26.31a1,1,0,0,1,1.47,0l15,16.36A1,1,0,0,1,31,18.35Z" />
      <path d="M21.45,32a1,1,0,0,1-1-1V21.08H11.55V31a1,1,0,1,1-2,0V21.08a2,2,0,0,1,2-2h8.91a2,2,0,0,1,2,2V31A1,1,0,0,1,21.45,32Z" />
    </g>
  </svg>
);

Home.propTypes = {
  fill: PropTypes.string,
};

Home.defaultProps = {
  fill: '#5eaa61',
};

export default Home;
