import firebase from '../../../../firebase';

import { openSnackbar } from '../../../../app/modules/snackbar/actions';

export const fieldChange = (prop, value) => ({
  type: 'PROFILE_FIELD_CHANGE',
  prop,
  value,
});

const profileUpdated = () => ({
  type: 'PROFILE_UPDATED',
});

const reportError = err => ({
  type: 'PROFILE_UPDATE_ERROR',
  err,
});

const reportStripeCustomerUpdateError = err => ({
  type: 'FAILED_STRIPE_CUSTOMER_UPDATE',
  err,
});

export const saveChanges = () => (dispatch, getState) => {
  const {
    account,
    name,
    organisation,
    city,
    country,
    workfield
  } = getState().account.profile;

  const { email } = getState().account.auth.user;

  const { uid } = firebase.auth().currentUser;
  const userRef = firebase.database().ref(`users/${uid}`);
  const accountRef = firebase.database().ref(`accounts/${account}`);

  const source = {
    email,
    name: name.value,
    address: {
      line1: organisation.value || null,
      city: city.value || null,
      country: country.value || null,
    },
  };

  return Promise.all([
    userRef.child('name').set(name.value),
    accountRef.child('organisation').set(organisation.value),
    accountRef.child('city').set(city.value),
    accountRef.child('country').set(country.value),
    accountRef.child('workfield').set(workfield.value),

  ])
    .then(() => {
      dispatch(profileUpdated());
      dispatch(openSnackbar('Profile updated.'));
      const myHeaders = new Headers();
      return firebase
        .database()
        .ref(`accounts/${account}/stripe_customer_id`)
        .once(
          'value',
          snapshot => {
            if (snapshot.val()) {
              const payload = {
                customerId: snapshot.val(),
                source,
              };
              return firebase
                .auth()
                .currentUser.getIdToken()
                .then(token => {
                  myHeaders.append('Content-Type', 'application/json');
                  myHeaders.append('x-access-token', token);

                  return fetch(
                    `${process.env.REACT_APP_PAYMENT_API}/update_stripe_customer`,
                    {
                      method: 'post',
                      headers: myHeaders,
                      body: JSON.stringify(payload),
                      mode: 'cors',
                    },
                  );
                })
                .then(res => {
                  if (res.status === 200) {
                    dispatch({ type: 'STRIPE_CUSTOMER_UPDATED' });
                    dispatch(openSnackbar('Stripe customer updated'));
                  } else {
                    dispatch(
                      reportStripeCustomerUpdateError(
                        'Failed to Update Stripe Customer, please try again',
                      ),
                    );
                  }
                  return res.json();
                });
            }
            dispatch(
              reportStripeCustomerUpdateError(
                `No stripe customer found with ${account} account id`,
              ),
            );
            return null;
          },
          err => {
            dispatch(
              reportStripeCustomerUpdateError(
                `Failed to Update Stripe Customer, please try again ${err}`,
              ),
            );
          },
        );
    })
    .catch(err => reportError(err.message));
};
