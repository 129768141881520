import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddIcon from 'material-ui/svg-icons/content/add';
import { Trans, useTranslation } from 'react-i18next';

import PageHeader from '../../app/components/pageHeader';
import PageHeaderCounter from '../../app/components/pageHeaderCounter';
import Livestream from './modules/livestream';
import Forum from './modules/forum';
import Push from './modules/push';
import Sms from './modules/sms';
import PushButton from './modules/push/PushButton';
import History from './modules/history';
import Scan from './modules/scan';
import Zoom from './modules/zoom';
import Feedback from './modules/feedback';
import Blog from './modules/Blog';
import { PLANS_WITH_ADDONS } from '../../plans/constants';
import AccountsDropdown from '../editor/internals/accountsDropdown';
import { FloatingActionButton } from '../../app/components/buttons';

import './styles.css';

const noCreditsLeft = (isSubjectToCredit, credit) =>
  isSubjectToCredit && credit < 1;
const isLinkInvalid = link =>
  link.enabled && link.type === 'web' && !link.webValid;
const isVideoInvalid = link =>
  link.enabled && link.type === 'video' && !link.videoValid;
const disableBtn = (
  enabled,
  link,
  isSubjectToCredit,
  credit,
  pending,
  title,
  message,
) =>
  !enabled ||
  isLinkInvalid(link) ||
  isVideoInvalid(link) ||
  (isSubjectToCredit && credit === 0) ||
  pending ||
  !title.isValid ||
  !message.isValid;

const isDisabled = (
  enabled,
  link,
  isSubjectToCredit,
  credit,
  pending,
  title,
  message,
) =>
  disableBtn(
    enabled,
    link,
    isSubjectToCredit,
    credit,
    pending,
    title,
    message,
  ) && !noCreditsLeft(isSubjectToCredit, credit);

const Engage = props => {
  const {
    componentsPending,
    enabled,
    isSubjectToCredit,
    credit,
    match,
    isPushNotification,
    isNotHistory,
    link,
    pending,
    title,
    message,
    onSubmit,
    diyComponents,
    subscription,
    isBlog,
    blogModalOpen,
    toggleBlogModal,
    engageSectionsAccess,
  } = props;
  const { t } = useTranslation();

  const isDiyPlan = () =>
    subscription &&
    subscription.plan &&
    PLANS_WITH_ADDONS.includes(subscription.plan.toLowerCase());

  const hasAddon = addon => {
    const allAddons = diyComponents ? Object.keys(diyComponents) : {};
    return diyComponents ? allAddons.includes(addon) : false;
  };

  return (
    <div className="engage-container editor-body background-texture">
      <div
        className="flex-container sidebar-extended"
        style={{ height: '100%' }}
      >
        <PageHeader
          title={t('Engage your users')}
          userSettings={<AccountsDropdown />}
          actions={
            isBlog ? (
              <FloatingActionButton
                icon={<AddIcon />}
                onClick={() => toggleBlogModal(!blogModalOpen)}
              />
            ) : (
              isPushNotification && (
                <div className="push-header-actions">
                  <PageHeaderCounter
                    amount={
                      // eslint-disable-next-line no-nested-ternary
                      isDiyPlan() && hasAddon('Notification')
                        ? t('Unlimited')
                        : isSubjectToCredit
                        ? credit
                        : t('Unlimited')
                    }
                    title={t("Push Notifications")}
                  />
                  {isNotHistory && (
                    <div
                      className={`submit-button-container ${
                        // eslint-disable-next-line no-nested-ternary
                        isDiyPlan() && hasAddon('Notification')
                          ? ''
                          : isDisabled(
                              enabled,
                              link,
                              isSubjectToCredit,
                              credit,
                              pending,
                              title,
                              message,
                              isSubjectToCredit,
                              credit,
                            )
                          ? 'disabled'
                          : ''
                      }`}
                    >
                      <PushButton
                        link={link}
                        enabled={
                          isDiyPlan() && hasAddon('Notification')
                            ? true
                            : enabled
                        }
                        isSubjectToCredit={isSubjectToCredit}
                        credit={credit}
                        pending={pending}
                        title={title}
                        message={message}
                        onSubmit={onSubmit}
                        diyComponents={diyComponents}
                        subscription={subscription}
                      />
                    </div>
                  )}
                </div>
              )
            )
          }
        />
        {engageSectionsAccess && (
          <Switch>
            <Redirect
              exact
              from={`/${match.params.appname}/engage`}
              to={`/${match.params.appname}/engage/push`}
            />
            <Route
              path="/:appname/engage/push"
              render={() => (
                <Push
                  componentsPending={componentsPending}
                  enabled={enabled}
                  isSubjectToCredit={isSubjectToCredit}
                  credit={credit}
                />
              )}
            />
            <Route path="/:appname/engage/history" component={History} />
            <Route path="/:appname/engage/sms" component={Sms} />
            <Route path="/:appname/engage/livestream" component={Livestream} />
            <Route path="/:appname/engage/scan" component={Scan} />
            <Route path="/:appname/engage/zoom" component={Zoom} />
            <Route path="/:appname/engage/forum" component={Forum} />
            <Route path="/:appname/engage/feedback" component={Feedback} />
            <Route path="/:appname/engage/blog" component={Blog} />
          </Switch>
        )}
      </div>
    </div>
  );
};

Engage.propTypes = {
  componentsPending: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  isSubjectToCredit: PropTypes.bool.isRequired,
  credit: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  isPushNotification: PropTypes.bool.isRequired,
  isNotHistory: PropTypes.bool.isRequired,
  link: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired,
  title: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  diyComponents: PropTypes.object,
  subscription: PropTypes.object.isRequired,
  isBlog: PropTypes.bool.isRequired,
  blogModalOpen: PropTypes.bool.isRequired,
  toggleBlogModal: PropTypes.func.isRequired,
  engageSectionsAccess: PropTypes.bool.isRequired,
};

export default Engage;
