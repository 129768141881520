import firebase from '../../../../../../firebase';

export const updateField = (appname, toggled, field) => dispatch =>
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default`)
    .update({ [`${field}`]: toggled })
    .then(() =>
      dispatch({
        type: 'TOGGLE_VISIBLE_FIELD',
        appname,
        field,
        toggled,
      }),
    );
