export const FIELD_VALIDATION_ERROR = 'FIELD_VALIDATION_ERROR';
export const REQUEST_MARKETPLACE_FILTER_DETAILS =
  'REQUEST_MARKETPLACE_FILTER_DETAILS';
export const REQUEST_MARKETPLACE_FILTER_FIELD_UPDATE =
  'REQUEST_MARKETPLACE_FILTER_FIELD_UPDATE';
export const RECEIVE_MARKETPLACE_FILTER_FIELD_UPDATE =
  'RECEIVE_MARKETPLACE_FILTER_FIELD_UPDATE';
export const RECEIVE_MARKETPLACE_FILTER_DETAILS =
  'RECEIVE_MARKETPLACE_FILTER_DETAILS';
export const MARKETPLACE_FILTER_FIELD_DELETED = 'MARKETPLACE_FILTER_FIELD_DELETED';

