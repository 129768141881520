import i18n from 'i18next';
import firebase from '../../firebase';

import { openSnackbar } from '../../app/modules/snackbar/actions';
import { getAppUrl } from '../../utils/appDetails';

export const setDefaultMessage = (appname, customDomain) => dispatch =>
  dispatch({
    type: 'SET_DISTRIBUTE_MESSAGE',
    appname,
    customDomain,
  });

export const setWhatsappMessage = (appname, customDomain) => dispatch =>
  dispatch({
    type: 'SET_WHATSAPP_MESSAGE',
    appname,
    customDomain,
  });

export const changeDistributeMode = mode => ({
  type: 'CHANGE_DISTRIBUTE_MODE',
  mode,
});

export const numberChange = value => ({
  type: 'DISTRIBUTE_NUMBER_CHANGE',
  value,
});

export const emailChange = value => ({
  type: 'DISTRIBUTE_EMAIL_CHANGE',
  value,
});

export const messageChange = value => ({
  type: 'DISTRIBUTE_MESSAGE_CHANGE',
  value,
});

export const whatsappMessageChange = value => ({
  type: 'WHATSAPP_MESSAGE_CHANGE',
  value,
});

export const subjectChange = value => ({
  type: 'DISTRIBUTE_SUBJECT_CHANGE',
  value,
});

export const dispatchDistribute = payload => ({
  type: 'DISPATCH_DISTRIBUTE',
  payload,
});

export const distributeDispatchComplete = () => ({
  type: 'DISTRIBUTE_DISPATCH_COMPLETE',
});

export const openDistributeDialog = () => ({ type: 'OPEN_DISTRIBUTE_DIALOG' });

export const closeDistributeDialog = (appname, customDomain) => dispatch => {
  dispatch({
    type: 'SET_DISTRIBUTE_MESSAGE',
    appname,
    customDomain,
  });
  dispatch({
    type: 'SET_WHATSAPP_MESSAGE',
    appname,
    customDomain,
  });
  dispatch({
    type: 'CLOSE_DISTRIBUTE_DIALOG',
  });
};

export const parseNumber = contact => {
  if (contact) {
    const num = contact.mobile.replace(/^\+/, '');
    const dialCode = contact.country.dialCode.replace(/^\+/, '');
    const regEx = new RegExp(`^${dialCode}`);

    if (regEx.test(num)) {
      return num;
    }

    // if (contact.country.code === 'GB') {
    //   return num.replace(/^0/, dialCode);
    // }
    return `${dialCode}${num}`;
  }
};

export const sendSMS = appname => (dispatch, getState) => {
  const accountKey = getState().account.profile.account;

  const appUsers = getState().auth.users.filteredChunks;
  let receiverName = [];

  const { share, contacts, app } = { ...getState().distribute };
  const customDomain = (app && app.customDomain) || '';

  const numbers = contacts.data.selected
    .map(key => {
      receiverName.push(contacts.data.chunks[key].name);
      if (contacts.data.chunks[key].mobile) {
        return {
          mobile: contacts.data.chunks[key].mobile,
          country: contacts.data.chunks[key].country,
        };
      }
    })
    .map(parseNumber);

  contacts.data.groupSelected.forEach(key => {
    const users = contacts.data.groupFilteredChunks[key].users
      ? Object.keys(contacts.data.groupFilteredChunks[key].users)
      : null;

    if (users) {
      users.map(userID => {
        if (appUsers[userID].phoneNumber) {
          if (!numbers.includes(appUsers[userID].phoneNumber.slice(1))) {
            numbers.push(appUsers[userID].phoneNumber.slice(1));
            receiverName.push(appUsers[userID].firstName);
          }
        }
      });
    }
  });

  contacts.data.workforceGroupsSelected.forEach(key => {
    const users = contacts.data.workforceGroupsFilteredChunks[key].users
      ? Object.keys(contacts.data.workforceGroupsFilteredChunks[key].users)
      : null;

    if (users) {
      users.map(userID => {
        if (appUsers[userID].phoneNumber) {
          if (!numbers.includes(appUsers[userID].phoneNumber.slice(1))) {
            numbers.push(appUsers[userID].phoneNumber.slice(1));
            receiverName.push(appUsers[userID].firstName);
          }
        }
      });
    }
  });
  const filterNumbers = numbers.filter(e => e != null);
  if (filterNumbers.length > 0) {
    const payload = {
      message: share.message.value,
      account: accountKey,
      appname,
      numbers,
      receiverName,
    };

    dispatch(dispatchDistribute(payload));

    return firebase
      .database()
      .ref('queues/sms/tasks')
      .push(payload)
      .then(() => {
        dispatch(distributeDispatchComplete());
        dispatch(setDefaultMessage(appname, customDomain));
        dispatch(setWhatsappMessage(appname, customDomain));
        dispatch(
          openSnackbar(
            i18n.t('Distribution started. Your SMS will be delivered shortly.'),
          ),
        );
      });
  } else {
    dispatch(distributeDispatchComplete());
    dispatch(openSnackbar(i18n.t('Your contacts have no phone numbers')));
  }
};

export const sendWhatsapp = appname => (dispatch, getState) => {
  const { share, contacts } = getState().distribute;
  const accountKey = getState().account.profile.account;
  const appUsers = getState().auth.users.filteredChunks;

  let receiverName = [];

  const appUrl = getAppUrl(
    appname,
    getState().app.customDomain ? getState().app.customDomain : null,
  );
  const numbers = contacts.data.selected
    .map(key => {
      receiverName.push(contacts.data.chunks[key].name);
      if (contacts.data.chunks[key].mobile) {
        return {
          mobile: contacts.data.chunks[key].mobile,
          country: contacts.data.chunks[key].country,
        };
      }
    })
    .map(parseNumber);

  contacts.data.groupSelected.forEach(key => {
    const users = contacts.data.groupFilteredChunks[key].users
      ? Object.keys(contacts.data.groupFilteredChunks[key].users)
      : null;

    if (users) {
      users.map(userID => {
        if (appUsers[userID].phoneNumber) {
          if (!numbers.includes(appUsers[userID].phoneNumber.slice(1))) {
            numbers.push(appUsers[userID].phoneNumber.slice(1));
            receiverName.push(appUsers[userID].firstName);
          }
        }
      });
    }
  });

  contacts.data.workforceGroupsSelected.forEach(key => {
    const users = contacts.data.workforceGroupsFilteredChunks[key].users
      ? Object.keys(contacts.data.workforceGroupsFilteredChunks[key].users)
      : null;

    if (users) {
      users.map(userID => {
        if (appUsers[userID].phoneNumber) {
          if (!numbers.includes(appUsers[userID].phoneNumber.slice(1))) {
            numbers.push(appUsers[userID].phoneNumber.slice(1));
            receiverName.push(appUsers[userID].firstName);
          }
        }
      });
    }
  });

  const filterNumbers = numbers.filter(e => e != null);
  if (filterNumbers.length > 0) {
    const payload = {
      message: share.whatsappMessage.value.value,
      messageIndex: share.whatsappMessage.value.index,
      account: accountKey,
      appname,
      numbers,
      receiverName,
      appUrl,
    };

    dispatch(dispatchDistribute(payload));

    return firebase
      .database()
      .ref('queues/whatsapp/tasks')
      .push(payload)
      .then(() => {
        dispatch(distributeDispatchComplete());
        dispatch(
          openSnackbar(
            i18n.t(
              'Distribution started, your message will be delivered shortly.',
            ),
          ),
        );
      })
      .catch(err => {
        dispatch(distributeDispatchComplete());
        console.log(err);
      });
  } else {
    dispatch(distributeDispatchComplete());
    dispatch(openSnackbar(i18n.t('Your contacts have no phone numbers')));
  }
};

export const sendEmail = appname => (dispatch, getState) => {
  const { share, contacts } = getState().distribute;
  const appUsers = getState().auth.users.filteredChunks;

  let receiverName = [];

  const emails = contacts.data.selected.map(key => {
    receiverName.push(contacts.data.chunks[key].name);
    return contacts.data.chunks[key].email;
  });

  contacts.data.groupSelected.forEach(key => {
    const users = contacts.data.groupFilteredChunks[key].users
      ? Object.keys(contacts.data.groupFilteredChunks[key].users)
      : null;

    if (users) {
      users.map(userID => {
        if (
          appUsers &&
          appUsers[userID] &&
          !emails.includes(appUsers[userID].email) &&
          appUsers[userID].email
        ) {
          emails.push(appUsers[userID].email);
          receiverName.push(appUsers[userID].firstName);
        }
      });
    }
  });

  contacts.data.workforceGroupsSelected.forEach(key => {
    const users = contacts.data.workforceGroupsFilteredChunks[key].users
      ? Object.keys(contacts.data.workforceGroupsFilteredChunks[key].users)
      : null;

    if (users) {
      users.map(userID => {
        if (
          appUsers &&
          appUsers[userID] &&
          !emails.includes(appUsers[userID].email) &&
          appUsers[userID].email
        ) {
          emails.push(appUsers[userID].email);
          receiverName.push(appUsers[userID].firstName);
        }
      });
    }
  });

  const payload = {
    message: share.message.value,
    subject: share.subject.value ? share.subject.value : '',
    appname,
    emails,
    receiverName,
  };

  dispatch(dispatchDistribute(payload));

  firebase
    .database()
    .ref('queues/emails/tasks')
    .push(payload)
    .then(() => {
      dispatch(distributeDispatchComplete());
      dispatch(
        openSnackbar(
          i18n.t(
            'Distribution started, your emails will be delivered shortly.',
          ),
        ),
      );
    });
};

export const facebookShare = (appname, customDomain) => {
  window.FB.ui({
    method: 'share',
    href: getAppUrl(appname, customDomain),
  });

  return {
    type: 'FACEBOOK_SHARE',
    appname,
  };
};

const receiveMetaData = (appname, data, customDomain) => ({
  type: 'RECEIVE_APP_TITLE',
  data,
  appname,
  customDomain,
});

export const loadAppTitle = appname => async dispatch => {
  const [titleSnap, customDomainSnap] = await Promise.all([
    firebase
      .database()
      .ref(`apps/${appname}`)
      .child('draft')
      .child('meta_data')
      .child('title')
      .once('value'),
    firebase
      .database()
      .ref(`apps/${appname}/custom_domain`)
      .once('value'),
  ]);

  dispatch(receiveMetaData(appname, titleSnap.val(), customDomainSnap.val()));
};
