import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'material-ui/Tabs';
import { Provider, useSelector, useDispatch } from 'react-redux';
import i18n from 'i18next';
import { store } from '../../';

import Tabs from './components/tabs';
import Modal from './components/modal';
import AccountHeader from './components/accountHeader';

import Subscriptions from './modules/subscriptions';
import Billing from './modules/billing';
import AppSumoBilling from './modules/appSumoBilling';
import Account from './modules/account';
import Profile from './modules/profile';
import Referral from './modules/referral';
import StaffProfile from './modules/staffProfile';
import ResetPassword from './modules/resetPassword';
import Preferences from './modules/preferences';
import { STAFF_ADMIN } from '../../config';
import { setActiveAccountTab } from '../editor/modules/build/modules/buildMenu/actions';

import './styles.scss';

const styles = {
  tab: {
    fontSize: '11px',
    height: 40,
    width: 120,
  },
};

const defineOptionalTabs = (
  integration,
  role,
  isAppAdmin,
  isAppSumoUser,
  isSubAdminWithMulipleApps,
  activeAccountTab,
  setActiveTab,
) => {
  if (integration && integration.type) {
    return integration.type === 'Wix' ? <WixTabs /> : <WeeblyTabs />;
  }

  if (isAppAdmin || isSubAdminWithMulipleApps) {
    return <AppAdminPlatformTabs />;
  }

  return role !== STAFF_ADMIN ? (
    <CorePlatformTabs
      isAppSumoUser={isAppSumoUser}
      activeAccountTab={activeAccountTab}
      setActiveTab={setActiveTab}
    />
  ) : (
    <StaffPlatformTabs />
  );
};

const AccountModal = props => {
  const {
    user,
    profile,
    staffProfile,
    open,
    handleClose,
    integration,
    role,
    isAppAdmin,
    isAppSumoUser,
    isSubAdminWithMulipleApps,
    location,
    auth,
  } = props;

  const dispatch = useDispatch();
  const activeAccountTab = useSelector(
    state => state.app.appcues.activeAccountTab,
  );

  useEffect(() => {
    if (auth.isAuthenticated && location.hash === '#?settings=billing') {
      setTimeout(() => {
        props.onAccountSettingsTouchTap();
        props.setActiveAccountTab(3);
      }, 3000);
    }
  }, [auth]);

  const handleCloseModal = () => {
    handleClose();
    dispatch(setActiveAccountTab(0));
    if (window.location.hash) {
      window.history.replaceState('', document.title, window.location.pathname);
    }
  };
  return (
    <Modal
      open={open}
      onRequestClose={handleCloseModal}
      header={
        <AccountHeader
          email={user.email}
          name={
            role === STAFF_ADMIN
              ? `${staffProfile.firstName.value} ${staffProfile.lastName.value}`
              : profile.name.value
          }
        />
      }
    >
      {defineOptionalTabs(
        integration,
        role,
        isAppAdmin,
        isAppSumoUser,
        isSubAdminWithMulipleApps,
        activeAccountTab,
        props.setActiveAccountTab,
      )}
    </Modal>
  );
};

const WeeblyTabs = () => (
  <Tabs>
    <Tab style={styles.tab} label={i18n.t('Plans')}>
      <Subscriptions />
    </Tab>
    <Tab style={styles.tab} label={i18n.t('Billing')}>
      <Billing />
    </Tab>
  </Tabs>
);

const WixTabs = () => (
  <Provider store={store}>
    <Tabs>
      <Tab style={styles.tab} label={i18n.t('Plans')}>
        <Subscriptions />
      </Tab>
    </Tabs>
  </Provider>
);
const CorePlatformTabs = ({
  isAppSumoUser,
  activeAccountTab,
  setActiveTab,
}) => (
  <Provider store={store}>
    <div className="account-tabs">
      <Tabs value={activeAccountTab} setActiveTab={setActiveTab}>
        <Tab style={styles.tab} label={i18n.t('Profile')} value={0}>
          <Profile />
        </Tab>
        <Tab style={styles.tab} label={i18n.t('Password')} value={1}>
          <ResetPassword />
        </Tab>
        <Tab style={styles.tab} label={i18n.t('Plans')} value={2}>
          <Subscriptions />
        </Tab>
        <Tab style={styles.tab} label={i18n.t('Billing')} value={3}>
          {isAppSumoUser ? <AppSumoBilling /> : <Billing />}
        </Tab>
        <Tab style={styles.tab} label={i18n.t('Account')} value={4}>
          <Account />
        </Tab>
        <Tab style={styles.tab} label={i18n.t('Referral')} value={5}>
          <Referral />
        </Tab>
        <Tab style={styles.tab} label={i18n.t('Preferences')} value={6}>
          <Preferences />
        </Tab>
      </Tabs>
    </div>
  </Provider>
);

const AppAdminPlatformTabs = () => (
  <Provider store={store}>
    <div className="account-tabs">
      <Tabs>
        <Tab style={styles.tab} label={i18n.t('Profile')}>
          <Profile />
        </Tab>
        <Tab style={styles.tab} label={i18n.t('Password')}>
          <ResetPassword />
        </Tab>
      </Tabs>
    </div>
  </Provider>
);

const StaffPlatformTabs = () => (
  <Provider store={store}>
    <div className="account-tabs">
      <Tabs>
        <Tab style={styles.tab} label={i18n.t('Profile')}>
          <StaffProfile />
        </Tab>
        <Tab style={styles.tab} label={i18n.t('Password')}>
          <ResetPassword />
        </Tab>
        <Tab style={styles.tab} label={i18n.t('Account')}>
          <Account />
        </Tab>
      </Tabs>
    </div>
  </Provider>
);

AccountModal.propTypes = {
  user: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  staffProfile: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  integration: PropTypes.object,
  role: PropTypes.string.isRequired,
  isAppAdmin: PropTypes.bool,
  isAppSumoUser: PropTypes.bool,
  isSubAdminWithMulipleApps: PropTypes.bool.isRequired,
};

AccountModal.defaultProps = {
  integration: null,
  role: '',
  isAppAdmin: false,
  isAppSumoUser: false,
};

export default AccountModal;
