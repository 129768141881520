import React from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Vendor from '../favoriteVendor';
import * as Styled from './styles';
import moment from 'moment';
import { Trans } from 'react-i18next';

const Vendors = ({
  allVendors,
  selectedVendor,
  selectVendor,
  deselectVendor,
}) => {
  return (
    <>
      {allVendors.length > 0 ? (
        <Grid container spacing={3}>
          {allVendors.map(vendor => (
            <>
              <Grid
                key={vendor.id}
                item
                xs={12}
                sm={6}
                md={4}
                onClick={() => selectVendor(vendor)}
              >
                <Styled.container>
                  <Styled.imageContainer>
                    {vendor.value.photURL ? (
                      <Styled.image
                        alt={vendor.value.name}
                        src={vendor.value.photoURL}
                      />
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Trans>No profile image added</Trans>
                      </div>
                    )}
                  </Styled.imageContainer>
                  <Styled.details>
                    <Styled.primaryDetails>
                      {`${vendor.value.firstName} ${vendor.value.lastName}`}
                    </Styled.primaryDetails>
                    <Styled.secondaryDetails>
                      {vendor.value.email}
                    </Styled.secondaryDetails>
                    <Styled.secondaryDetails>
                      {moment(vendor.created_at).format('DD-MM-YYYY')}
                    </Styled.secondaryDetails>
                  </Styled.details>
                </Styled.container>
              </Grid>
            </>
          ))}
        </Grid>
      ) : (
        <span>
          {' '}
          <Trans>No vendors added yet.</Trans>{' '}
        </span>
      )}
      <Modal
        open={selectedVendor ? true : false}
        onBackdropClick={deselectVendor}
        aria-labelledby="vendor-details"
      >
        <>
          <Vendor vendor={selectedVendor} deselectVendor={deselectVendor} />
        </>
      </Modal>
    </>
  );
};

Vendors.defaultProps = {
  allVendors: [],
};
export default Vendors;
