import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const Download = ({ data, element, filename }) => (
  <ExcelFile element={element} filename={filename}>
    {data.map(dataSet => (
      <ExcelSheet
        key={dataSet.name}
        dataSet={dataSet.multiDataSet}
        name={dataSet.name}
      />
    ))}
  </ExcelFile>
);

Download.propTypes = {
  data: PropTypes.array,
  element: PropTypes.node.isRequired,
  filename: PropTypes.string.isRequired,
};

Download.defaultProps = {
  columns: [],
  data: [],
};

export default Download;
