import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Zoom from './Zoom';
import { fieldChange } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  design: ownProps.component.design,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Zoom));
