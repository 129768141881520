import React from 'react';

const Zoom = () => (
  <svg
    className="icon zoom-icon"
    width="1440"
    height="1440"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsSvg="http://www.w3.org/2000/svg"
    viewBox="0 0 1419 1418"
  >
    <title>Zoom</title>
    <g className="layer">
      <title>Layer 1</title>
      <g
        id="svg_1"
        transform="matrix(0.198158 0 0 -0.198664 -657.513 -753.857)"
      >
        <path
          d="m5268.33,-3857.67c-456,-48 -891,-254 -1230,-581c-327,-314 -528,-691 -617,-1153l-23,-120l0,-1705l0,-1705l28,-143c112,-581 436,-1066 929,-1389c196,-129 482,-247 705,-292c232,-47 187,-46 1913,-46c1528,0 1643,1 1750,18c365,57 691,193 976,405c107,80 327,298 406,402c252,335 398,724 429,1146c7,100 9,659 7,1704c-4,1431 -5,1563 -22,1660c-41,244 -93,412 -191,617c-108,226 -229,396 -411,575c-322,317 -679,505 -1124,590l-115,22l-1665,2c-916,1 -1701,-2 -1745,-7zm3395,-353c280,-36 553,-144 794,-315c94,-66 263,-225 339,-319c221,-272 355,-606 386,-968c15,-162 15,-3078 1,-3229c-58,-615 -428,-1154 -975,-1420c-191,-93 -391,-150 -617,-174c-133,-15 -3117,-15 -3258,0c-234,23 -412,76 -630,186c-484,243 -822,690 -932,1235l-23,113l0,1690l0,1690l23,105c86,393 272,719 556,972c275,247 622,402 986,441c19,2 755,5 1635,5c1343,2 1619,0 1715,-12z"
          id="svg_2"
        />
        <path
          d="m8915,-6020.67c-37,-17 -783,-477 -962,-593c-21,-14 -23,-12 -57,61c-104,225 -298,394 -538,468l-73,23l-1201,3c-1333,3 -1246,7 -1301,-65l-28,-36l0,-956c0,-1066 -4,-1007 70,-1167c101,-221 302,-400 520,-462c147,-43 172,-43 1371,-40l1150,3l42,27c75,50 77,56 80,381c2,158 6,287 9,287c3,0 200,-121 438,-270c238,-148 452,-279 476,-292c60,-32 183,-32 250,-1c66,30 124,86 156,151l28,57l0,1110l0,1110l-25,50c-34,69 -99,129 -166,154c-77,29 -173,28 -239,-3zm81,-2263c-2,-3 -230,137 -505,309l-501,313l2,329l3,329l500,313l500,313l3,-951c1,-523 0,-953 -2,-955zm-1725,1857c70,-21 168,-76 212,-118c45,-43 106,-142 130,-212c22,-65 22,-69 25,-872l3,-808l-1038,0c-1139,0 -1112,-1 -1234,61c-120,60 -228,198 -258,329c-7,29 -11,332 -11,843l0,797l1054,0c1019,0 1056,-1 1117,-20z"
          id="svg_3"
        />
      </g>
    </g>
  </svg>
);

export default Zoom;
