import styled from 'styled-components';

const TextAreaInput = styled.textarea`
  padding: 6px;
  margin-top: 8px;
  width: 100%;
  max-width: 100%;
  min-height: 160px;
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  line-height: 21px;
  -webkit-appearance: none;
  background: ${props => (props.disabled ? 'rgb(242, 242, 242)' : '#fff')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'text')};
  border: 1px solid #333333
  border-radius: 3px;
  resize: vertical;

  position: relative;

  &:focus {
    outline: none;
    border: 1px solid rgb(0, 124, 143);
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ececec;
  }

  &::-webkit-scrollbar-track {
    background-color: #f6f6f6;
    outline: none;
  }
`;

export default TextAreaInput;
