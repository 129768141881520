import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ChatProfileSettings from './ChatProfileSettings';
import { PLANS_WITH_ADDONS } from '../../../../../../plans/constants';

import { updateField, toggleDiyPopup } from './actions';

import {
  addToDiyComponents,
  loadDiyComponents,
} from '../../../../../../app/actions';

const mapStateToProps = (state, ownProps) => {
  const defaultGroup = state.auth.groups.data.chunks.default;

  return {
    ...ownProps,
    chatEnabled: defaultGroup ? defaultGroup.chatEnabled : false,
    profileEnabled: defaultGroup ? defaultGroup.profileEnabled : true,
    signupEnabled: defaultGroup ? defaultGroup.signupEnabled : true,
    onlyAdminChat: defaultGroup ? defaultGroup.onlyAdminChat : false,
    enableGroupChat: defaultGroup ? defaultGroup.enableGroupChat : true,
    diaryEnabled: defaultGroup ? defaultGroup.diaryEnabled : false,
    subscription:
      state.account.subscriptions.items[ownProps.match.params.appname] || {},
    addToDiyPopup: state.auth.settings.profileFields.addToDiyPopup,
    diyComponents: state.app.addons.diyComponents || {},
    isProcessing: state.app.addons.isProcessing || false,
    intl: state.account.intl,
    isAppSumoAccount:
      (state.account.organisation.appsumo_id &&
        state.account.organisation.appsumo_id.length > 0) ||
      false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateField: (value, field) =>
    dispatch(updateField(ownProps.match.params.appname, value, field)),
  handdleDiyPopupClose: value => {
    dispatch(toggleDiyPopup(ownProps.match.params.appname, false));
  },
  handleChatToggle: (value, field, subscription, addons) => {
    if (
      PLANS_WITH_ADDONS.includes(subscription.plan) &&
      !addons.includes('UserChat')
    ) {
      return dispatch(toggleDiyPopup(ownProps.match.params.appname, true));
    }
    return dispatch(updateField(ownProps.match.params.appname, value, field));
  },
  onAddonAdd: (type, component) => {
    dispatch(
      addToDiyComponents(type, component, ownProps.match.params.appname),
    ).then(() => {
      dispatch(toggleDiyPopup(ownProps.match.params.appname, false));
    });
    dispatch(updateField(ownProps.match.params.appname, true, 'chatEnabled'));
  },
  loadDiyData: () => {
    dispatch(loadDiyComponents(ownProps.match.params.appname));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChatProfileSettings),
);
