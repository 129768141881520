import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Trans, useTranslation } from 'react-i18next';
import firebase from '../../../../../../firebase';
import SectionHeader from './SectionHeader';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'block',
    marginTop: 20,
  },
  formControl: {
    margin: '2rem 0',
    minWidth: 150,
  },
  inputLabelRoot: {
    left: '-15px',
  },
}));

const ChatAdmin = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [userKey, setUserKey] = useState('none');
  const [open, setOpen] = useState(false);

  const { users, appname } = props;

  useEffect(() => {
    if (!Object.keys(users).length) return;
    Object.keys(users).forEach(key => {
      if (users[key].isChatAdmin) {
        setUserKey(key);
      }
    });
  }, [users]);

  const handleChange = async event => {
    if (event.target.value !== userKey) {
      const myHeaders = new Headers();
      const token = await firebase.auth().currentUser.getIdToken();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);

      const payload = {
        appname,
        prevKey: userKey,
        nextKey: event.target.value,
      };

      await fetch(`${process.env.REACT_APP_CHAT_API}/set_user_as_admin`, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify(payload),
        mode: 'cors',
      });
    }

    setUserKey(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (!Object.keys(users).length) return <div />;

  return (
    <div className="auth-panel">
      <div className="auth-panel-title" id="chatSettings">
        <Trans>Chat Settings</Trans>
      </div>
      <div className="auth-panel">
        <SectionHeader title={t('Chat Admin')} />

        <FormControl className={classes.formControl}>
          <InputLabel id="chat-admin-label" className={classes.inputLabelRoot}>
            <Trans>Select A Chat Admin</Trans>
          </InputLabel>
          <Select
            labelId="chat-admin-label"
            id="chat-admin-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={userKey}
            onChange={handleChange}
            variant="standard"
            fullWidth
          >
            <MenuItem value="none">
              <em>
                <Trans>None</Trans>
              </em>
            </MenuItem>
            {Object.keys(users).map(key => (
              <MenuItem key={key} value={key}>
                {users[key].email}
              </MenuItem>
            ))}
          </Select>
          <div style={{ marginTop: 10, fontSize: '0.8rem' }}>
            <Trans>
              A chat admin will be displayed as an admin in user chats. Users
              will also be able to chat with the admin anonymously.
            </Trans>
          </div>
        </FormControl>
      </div>
    </div>
  );
};

export default ChatAdmin;
