import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import DelegatesItem from './delegatesItem';
import FormFieldItem from './formFieldItem';
import GoogleMapsItem from './googleMapsItem';
import RosterItem from './rosterItem';
import ScheduleItem from './scheduleItem';
import SliderItem from './sliderItem';
import VoteItem from './voteItem';
import EventFilterItem from './eventFilterItem';
import EventCalendarItem from './eventCalendarItem';
import Container from '../../../../../../app/components/container';
import { CircularProgress } from '../../../../../../app/components/progress';

const NoMatch = () => <div>Unknown Item Type</div>;

class ItemInterface extends Component {
  static propTypes = {
    view: PropTypes.object,
    component: PropTypes.object,
    loading: PropTypes.bool,
    location: PropTypes.object.isRequired,
    setListener: PropTypes.func.isRequired,
    removeListener: PropTypes.func.isRequired,
  };

  static defaultProps = {
    loading: false,
    view: {},
    component: {},
  };

  componentDidMount() {
    const { component, location, setListener } = this.props;
    const { item, itinerary, calendarType } = queryString.parse(
      location.search,
    );

    if (typeof component.lazy_data === 'string') {
      setListener(
        component.lazy_data,
        component.type,
        item,
        itinerary,
        calendarType,
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const { component, location, setListener } = this.props;
    const { item, itinerary, calendarType } = queryString.parse(
      location.search,
    );

    if (component.lazy_data !== nextProps.component.lazy_data) {
      setListener(
        nextProps.component.lazy_data,
        nextProps.component.type,
        item,
        itinerary,
        calendarType,
      );
    }
  }

  componentWillUnmount() {
    const { component, location, removeListener } = this.props;
    const { item, itinerary } = queryString.parse(location.search);

    removeListener(component.lazy_data, component.type, item, itinerary);
  }

  getItemUI = component => {
    switch (component.type) {
      case 'Delegates':
        return DelegatesItem;

      case 'Form':
        return FormFieldItem;

      case 'EventCalendar':
        return EventCalendarItem;

      case 'GoogleMaps':
        return GoogleMapsItem;

      case 'Roster':
        return RosterItem;

      case 'Schedule':
        return ScheduleItem;

      case 'Slider':
        return SliderItem;

      case 'Vote':
        return VoteItem;

      case 'EventList':
        return EventFilterItem;

      default:
        return NoMatch;
    }
  };

  render() {
    const { view, loading, component, location } = this.props;

    const ItemUI = this.getItemUI(component);

    if (loading) {
      return (
        <Container>
          <CircularProgress size={24} thickness={3} />
        </Container>
      );
    }

    return <ItemUI title={view.title} {...this.props} />;
  }
}

export default ItemInterface;
