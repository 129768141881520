import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './styles.css';

class RadioMenu extends Component {
  static propTypes = {
    children: PropTypes.node,
    selected: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
    onChange: PropTypes.func,
    imageStyle: PropTypes.object,
  };

  static defaultProps = {
    children: null,
    selected: null,
    onChange: null,
    imageStyle: null,
  };

  static childContextTypes = {
    onSelect: PropTypes.func,
    selected: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
    ]),
    imageStyle: PropTypes.object,
  };

  state = {
    selected: null,
  };

  getChildContext() {
    return {
      onSelect: this.handleChange,
      selected: this.props.selected,
      imageStyle: this.props.imageStyle,
    };
  }

  handleChange = value => {
    this.setState({ selected: value });

    this.props.onChange(value);
  };

  render() {
    return <div className="radio-menu">{this.props.children}</div>;
  }
}

export default RadioMenu;
