import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import AppIcon from '../../../shared/appIcon';
import AppName from '../../../shared/appName';
import AppNavigationMenu from '../../../shared/appNavigationMenu';
import AppSplashScreen from '../../../shared/appSplashScreen';

import SidebarHeader from '../sidebarHeader';
import Sidebar from '../../../../../../app/components/sidebar';

const CustomiseInterface = () => (
  <Sidebar type="large" header={<SidebarHeader />} animate>
    <Switch>
      <Route path="/:appname/editor/customise/app_icon" component={AppIcon} />
      <Route path="/:appname/editor/customise/app_name" component={AppName} />
      <Route
        path="/:appname/editor/customise/navigation_menu"
        component={AppNavigationMenu}
      />
      <Route
        path="/:appname/editor/customise/app_splash_screen"
        component={AppSplashScreen}
      />
    </Switch>
  </Sidebar>
);

export default withRouter(CustomiseInterface);
