import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import Subscriptions from './Subscriptions';

import { getSubscriptions, launchCancelPlanDialog } from './actions';
import { toggleAccountModal } from '../../actions';
import { loadIcon } from '../../../editor/actions';
import { openWixPlans } from '../integration/actions';
import { getBilling } from '../billing/actions';

const mapStateToProps = state => ({
  freeTrial: state.account.organisation.free_trial,
  isOn14DaysTrial: state.account.organisation.isOn14DaysTrial,
  apps: state.account.organisation.apps,
  intl: state.account.intl,
  plans: state.account.subscriptions.items,
  // external modules
  icons: state.editor.settings,
  integrationType: _get(state, 'account.organisation.integration.type'),
  accountId: state.account.auth.profile.account,
  organisation: state.account.organisation,
  subscription: state.app.appContext.subscription,
  customDomain: state.app.customDomain,
  isAppsumoAccount: state.account.organisation.appsumo_id || null,
  appsumoBilling: state.account.appSumoBilling,
  trialExpired:
    (state.account.organisation && state.account.organisation.trial_expired) ||
    true,
  billing: state.account.billing,
});

const mapDispatchToProps = dispatch => {
  return {
    getSubscriptions: () => dispatch(getSubscriptions()),
    onCancelPlan: (plan, appname, isAddon, addonDetails) =>
      dispatch(launchCancelPlanDialog(plan, appname, isAddon, addonDetails)),
    // onDeleteApp: (plan, appname) => dispatch(launchDeleteAppDialog(plan, appname)),
    toggleAccountModal: () => dispatch(toggleAccountModal()),
    loadIcon: appname => dispatch(loadIcon(appname)),
    openWixPlans: (appname, plan, history, accountId) =>
      dispatch(openWixPlans(appname, plan, history, accountId)),
    // getDiyPlans: appname => dispatch(getDiyPlans(appname)),
    getBilling: () => dispatch(getBilling()),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subscriptions),
);
