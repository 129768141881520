import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RTE from 'react-rte';
import HtmlToText from 'html-to-text';
import MenuItem from 'material-ui/MenuItem';
import { Trans, withTranslation } from 'react-i18next';

import NotificationModal from '../../../../../../app/components/sidebar/notificationModal';
import SelectField from '../../../../../../app/components/selectField';
import { FlatButton } from '../../../../../../app/components/buttons';
import { Empty as EmptyIcon } from '../../../../../../app/components/icons';
import Container from '../../../../../../app/components/container';
import TextField from '../../../../../../app/components/textField';
import ImageUpload from '../../../../../editor/internals/imageUpload';
import SectionHeader from '../../../../../editor/components/sectionHeader';

class CustomEmailAdmin extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    getEmailType: PropTypes.func.isRequired,
    loadContent: PropTypes.func.isRequired,
    customEmail: PropTypes.object.isRequired,
    onSubjectChange: PropTypes.func.isRequired,
    onMailToChange: PropTypes.func.isRequired,
    onFooterTeamNameChange: PropTypes.func.isRequired,
    onFromEmailChange: PropTypes.func.isRequired,
    templateText: PropTypes.string.isRequired,
    saveCustomEmail: PropTypes.func.isRequired,
    isAppAdmin: PropTypes.bool,
  };

  static defaultProps = {
    customEmail: {
      subject: '',
      fromEmail: '',
      topContent: '',
      bottomContent: '',
      mailTo: '',
      footerTeamName: '',
      headerImage: '',
    },
    templateText: '',
    emailType: null,
    emailTypeList: [],
    isAppAdmin: false,
  };

  state = {
    topContent: RTE.createEmptyValue(),
    bottomContent: RTE.createEmptyValue(),
    showNotification: false,
    notificationType: '',
  };

  componentDidMount() {
    const { getEmailList, loadContent } = this.props;
    getEmailList();
    if (this.props.customEmail.headerImage) return;
    loadContent('adminEmailImage');
  }

  componentWillReceiveProps(nextProps) {
    if (
      !nextProps.customEmail.hasOwnProperty('topContent') ||
      nextProps.customEmail.topContent !== this.stringVal
    ) {
      const topContent = nextProps.customEmail.topContent
        ? nextProps.customEmail.topContent
        : '';
      this.setState({
        topContent: RTE.createValueFromString(topContent, 'html'),
      });
    }
    if (
      !nextProps.customEmail.hasOwnProperty('bottomContent') ||
      nextProps.customEmail.bottomContent !== this.stringVal
    ) {
      const bottomContent = nextProps.customEmail.bottomContent
        ? nextProps.customEmail.bottomContent
        : '';

      this.setState({
        bottomContent: RTE.createValueFromString(bottomContent, 'html'),
      });
    }
  }

  emailTypeChange = value => {
    const {
      setEmailType,
      getCustomEmailTemplate,
      getDefaultTemplateText,
      setTempData,
      setEmailContent,
      t,
    } = this.props;

    setTempData(value, 'adminEmailImage', 'adminHeaderImage');
    getDefaultTemplateText(value);
    getCustomEmailTemplate(value);
    setEmailType(value);
    setEmailContent(t('Loading...'));
  };

  getFilter = () => {
    const { emailTypeList, t } = this.props;
    return (
      <div className="row middle-xs">
        <div className="col-xs-2">
          <Trans>Custom Emails</Trans>
        </div>
        <div className="col-xs-6">
          <div className="col-xs-12">
            <SelectField
              className="offset-top"
              label={t('Select Email Type')}
              value={this.props.emailType}
              options={emailTypeList.map(opt => (
                <MenuItem
                  key={opt.key}
                  value={opt.key}
                  primaryText={t(opt.value)}
                />
              ))}
              onChange={(index, value) => this.emailTypeChange(value)}
            />
          </div>
        </div>
      </div>
    );
  };

  handleChangeEditor = (value, contentType) => {
    this.setState({ [contentType]: value }, () => {
      this.stringVal = value.toString('html');

      this.props.onMessageChange(this.stringVal, contentType);
    });
  };

  saveEmail = emailType => {
    const regexPattern = /\{\{.*?\}\}/g;
    const { saveCustomEmail, displayErrorMessage } = this.props;
    const { topContent, bottomContent } = this.state;
    const formattedTopContent = HtmlToText.fromString(topContent._cache.html);
    const formattedBottomContent = HtmlToText.fromString(
      bottomContent._cache.html,
    );

    if (formattedTopContent.match(regexPattern)) {
      this.setState({
        showNotification: true,
        notificationType: 'Top content',
      });
      return;
    }

    if (formattedBottomContent.match(regexPattern)) {
      this.setState({
        showNotification: true,
        notificationType: 'Bottom content',
      });
      return;
    }

    saveCustomEmail(emailType);
  };

  closeNotification = () => {
    this.setState({ showNotification: false });
  };

  openNotification = () => {
    this.setState({ showNotification: true });
  };

  notificationText = () => {
    const { notificationType } = this.state;
    return (
      <p style={{ marginTop: '8px' }}>
        <span
          dangerouslySetInnerHTML={{
            __html: `Dynamic values (e.g. {{email}}, {{planname}}, etc.) are not allowed
        in the`,
          }}
        />{' '}
        <span style={{ textTransform: 'capitalize' }}>{notificationType}</span>{' '}
        of Custom emails.
      </p>
    );
  };

  render() {
    const {
      pending,
      onSubjectChange,
      emailType,
      match,
      onMailToChange,
      onFooterTeamNameChange,
      customEmail,
      saveCustomEmail,
      templateText,
      isAppAdmin,
      t,
    } = this.props;

    const { subject, headerImage, mailTo, footerTeamName } = customEmail;
    const { showNotification } = this.state;
    const notification = this.notificationText();

    return (
      <div className="auth-container">
        {this.getFilter()}
        {emailType ? (
          <div>
            <Container>
              <SectionHeader
                title={t('Subject')}
                tooltip={t('Subject of the mail')}
              />
              <TextField
                id="email-subject"
                value={subject}
                onChange={value => onSubjectChange(value)}
                maxLength={75}
                isValid={subject && subject.length < 75}
                placeholder={t('Subject')}
              />
              <SectionHeader
                title={t('Upload Header Image')}
                tooltip={t('Upload an image for your Header background')}
              />
              <ImageUpload
                title={t('Box > Crop Image')}
                src={headerImage}
                firebaseUrl={`/apps/${match.params.appname}/temp_data/adminEmailImage`}
                showCropAreaSizes
                height="220px"
                width="320px"
              />
              <SectionHeader
                title={t('Top Content')}
                tooltip={t(
                  'This content is placed just above the Default Text',
                )}
              />
              <RTE
                label={t('Body')}
                className="rte"
                placeholder={t('Type Here')}
                // editorClassName="rte-editor"
                // toolbarClassName="rte-toolbar"
                value={this.state.topContent}
                onChange={value => this.handleChangeEditor(value, 'topContent')}
              />
              <SectionHeader
                title={t('Default Template Text')}
                tooltip={t('This is the fixed text present in the email')}
              />
              <pre className="template-text">{templateText}</pre>
              <SectionHeader
                title={t('Bottom Content')}
                tooltip={t(
                  'This content is placed just below the Default Text',
                )}
              />
              <RTE
                label={t('Body')}
                className="rte"
                placeholder={t('Type Here')}
                // editorClassName="rte-editor"
                // toolbarClassName="rte-toolbar"
                value={this.state.bottomContent}
                onChange={value =>
                  this.handleChangeEditor(value, 'bottomContent')
                }
              />
              <SectionHeader
                title={t('Reply To')}
                tooltip={t(
                  'End users will reply on this email. Please enter email address otherwise it will consider your registered email address.',
                )}
              />
              <TextField
                id="email-subject"
                value={mailTo}
                onChange={value => onMailToChange(value)}
                maxLength={75}
                isValid={mailTo && mailTo.length < 75}
                placeholder={t('Reply To')}
              />
              <SectionHeader
                title={t('Team Name')}
                tooltip={t(
                  'Change the team name present at footer of the email.',
                )}
              />
              <TextField
                id="email-subject"
                value={footerTeamName}
                onChange={value => onFooterTeamNameChange(value)}
                isValid={footerTeamName}
                placeholder={t('Team Name')}
              />
            </Container>
            <Container style={{ marginBottom: '7vh' }}>
              <FlatButton
                label={t('Save')}
                onClick={() => this.saveEmail(emailType)}
                style={{
                  margin: 12,
                }}
                disabled={pending}
                primary
              />
            </Container>
          </div>
        ) : (
          <div className="empty-state">
            <EmptyIcon />
            <h5 style={{ fontSize: '17px' }}>
              <Trans>
                In this section you can customise the automated emails sent by
                the app to your end users. Please select the email type that you
                wish to customise from the drop down menu.
              </Trans>
            </h5>
          </div>
        )}

        <NotificationModal
          showNotification={showNotification}
          closeNotification={this.closeNotification}
          text={notification}
        />
      </div>
    );
  }
}

export default withTranslation()(CustomEmailAdmin);
