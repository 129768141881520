import firebase from '../../../../firebase';

export const hydrateBilling = data => ({ type: 'HYDRATE_BILLING', data });

export const requestBilling = () => ({ type: 'REQUEST_BILLING' });

export const getBilling = () => (dispatch, getState) => {
  const { organisation } = getState().account;
  const myHeaders = new Headers();

  dispatch(requestBilling());

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_PAYMENT_API}/get_invoices`, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify({ accountId: organisation.key }),
        mode: 'cors',
      })
        .then(res => res.json())
        .then(data => {
          dispatch(hydrateBilling(data.invoices));
        });
    });

  return firebase
    .database()
    .ref('billing')
    .child(organisation.key)
    .on('value', snapshot => {
      const data = snapshot.val();

      dispatch(hydrateBilling(data));
    });
};
