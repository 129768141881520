import firebase from '../../../../../../../firebase';

export const changeLayout = (
  dataId,
  appname,
  value,
  layoutId = '',
  type = 'mobile',
) => {
  if (type === 'mobile') {
    const dataRef = firebase
      .database()
      .ref(`apps/${appname}/draft/always_data/${dataId}/layout/${layoutId}`);

    dataRef.set(value);
  } else {
    const dataRef = firebase
      .database()
      .ref(
        `apps/${appname}/draft/always_data/${dataId}/desktopLayout/${layoutId}`,
      );

    dataRef.set(value);
  }
  return {
    type: 'COMPONENT_LAYOUT_CHANGE',
    appname,
    dataId,
    value,
  };
};
