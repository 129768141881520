import React from 'react';

import './styles.css';

const Widget = () => (
  <svg
    className="icon widget"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 41.99 31.72"
  >
    <title>Widget</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M4.1,5a.68.68,0,1,0-.68-.68A.67.67,0,0,0,4.1,5Z"
        />
        <path
          class="cls-1"
          d="M6.57,5a.69.69,0,1,0-.68-.69A.69.69,0,0,0,6.57,5Z"
        />
        <path
          class="cls-1"
          d="M9,5A.68.68,0,1,0,9,3.66a.67.67,0,0,0-.68.68A.67.67,0,0,0,9,5Z"
        />
        <path
          class="cls-1"
          d="M39.07.1,3,0A2.92,2.92,0,0,0,.07,2.92L0,28.69a2.91,2.91,0,0,0,.85,2.07,3,3,0,0,0,2.07.87l23.39.06h0l12.67,0h0a2.93,2.93,0,0,0,2.93-2.92L42,3A2.93,2.93,0,0,0,39.07.1ZM2,1.92A1.4,1.4,0,0,1,3,1.5H3l36.05.1A1.44,1.44,0,0,1,40.49,3V6.89L1.56,6.79V2.93A1.39,1.39,0,0,1,2,1.92Zm.93,28.21A1.44,1.44,0,0,1,1.5,28.69l.06-20.4,38.92.1,0,10.63L29.27,19h0a2.94,2.94,0,0,0-2.93,2.92l0,8.28ZM39,30.22h0l-11.17,0,0-8.27a1.44,1.44,0,0,1,1.43-1.43h0l11.17,0,0,8.28A1.43,1.43,0,0,1,39,30.22Z"
        />
        <polygon
          class="cls-1"
          points="35.03 22.22 33.53 22.21 33.53 24.28 31.47 24.27 31.46 25.77 33.53 25.78 33.52 27.84 35.02 27.84 35.03 25.78 37.09 25.79 37.09 24.29 35.03 24.28 35.03 22.22"
        />
      </g>
    </g>
  </svg>
);

export default Widget;
