import { connect } from 'react-redux';

import ForgottenPassword from './ForgottenPassword';

import { fieldChange, clearForm, submitForgottenPassword } from './actions';

const mapStateToProps = state => ({
  ...state.account.forgottenPassword,
});

const mapDispatchToProps = dispatch => ({
  onEmailChange: value => dispatch(fieldChange('email', value)),
  onSubmit: () => dispatch(submitForgottenPassword()),
  clearForm: () => dispatch(clearForm()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgottenPassword);
