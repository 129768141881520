import React from 'react';
import { get } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';

const Feedback = ({ history, match }) => {
  const appname = get(match, 'params.appname', '');

  return (
    <div style={{ padding: '0 30px' }}>
      <p>
        <Trans>
          The Video Feedback component will allow your app users to submit
          videos into the app and send them for feedback to the app admin.
        </Trans>
      </p>
      <p>
        <Trans>
          This page will display the list of submitted videos by the app user.
          As an admin you can see the list of uploaded videos and add your
          feedback on each video in the Video Feedback section.
        </Trans>
      </p>
      <p>
        <Trans>Visit Engage</Trans> →
        <Link to={`/${appname}/engage/feedback`}>
          <Trans>Video Feedback</Trans>
        </Link>
        .
      </p>
    </div>
  );
};

export default withRouter(Feedback);
