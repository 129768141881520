import React from 'react';
import TextField from '@material-ui/core/TextField';
import StaticDateRangePicker from '@material-ui/lab/StaticDateRangePicker';
import AdapterDateMoment from '@material-ui/lab/AdapterMoment';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import Box from '@material-ui/core/Box';
import { Trans } from 'react-i18next';

export default function DatePicker({ onDateChange, style }) {
  const [value, setValue] = React.useState([null, null]);

  const handleDateChange = newValue => {
    if (newValue[0] && newValue[1]) {
      localStorage.setItem('customDateFrom', newValue[0]);
      localStorage.setItem('customDateTo', newValue[1]);
      onDateChange({
        numWeeks: null,
        customStartDate: newValue[0],
        customEndDate: newValue[1],
      });
    }
  };

  return (
    <div style={style}>
      <LocalizationProvider dateAdapter={AdapterDateMoment}>
        <StaticDateRangePicker
          displayStaticWrapperAs="desktop"
          value={value}
          onChange={newValue => {
            handleDateChange(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} variant="standard" />
              <Box sx={{ mx: 2 }}>
                {' '}
                <Trans>TO</Trans>{' '}
              </Box>
              <TextField {...endProps} variant="standard" />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
