import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AddIcon from 'material-ui/svg-icons/content/add';
import IconButton from '@material-ui/core/IconButton';
import Dropdown from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import DatePicker from 'material-ui/DatePicker';
import XLSX from 'xlsx';

import TextField from '../../../../../../app/components/textField';
import CircularValidation from '../../../../../../app/components/circularValidation';
import CreateField from './modules/create';
import GroupTabs from './modules/groups';
import DialogConfirmation from '../../../../../../app/components/dialogConfirmation';
import {
  ethnicGroups,
  religions,
  sexualOrientations,
  localAuthorities,
  gender,
  disability,
  martialStatus,
  leaveTypes,
  genderReassignments,
} from '../../config';

import { formatPersonalData, formatGroupData } from '../../exportData';

const Edit = props => {
  const {
    error,
    updateGroupName,
    groupDetails,
    getMemberDetails,
    onAddWorkforceDetails,
    fieldsVisibility,
    allGroups,
    savedData,
  } = props;
  const [confirmDeleteDialogOpen, setValue] = React.useState(false);

  useEffect(() => {
    getMemberDetails();
  }, []);

  const toggleConfirmBox = () => {
    setValue(!confirmDeleteDialogOpen);
  };

  // const handleExportData = () => {
  //   const data = formatPersonalData(groupDetails);
  //   const userGroupsData = formatGroupData(allGroups, savedData);
  //   const wb = XLSX.utils.book_new();

  //   const ws = XLSX.utils.json_to_sheet(data);

  //   XLSX.utils.book_append_sheet(wb, ws, 'profile');
  //   //Loop through formatted data
  //   userGroupsData.map(item =>
  //     XLSX.utils.book_append_sheet(
  //       wb,
  //       XLSX.utils.json_to_sheet(item.data),
  //       item.name,
  //     ),
  //   );
  //   XLSX.writeFile(wb, 'Profile.xlsx');
  // };

  const displayError = error ? (
    <div>
      <strong>{error}</strong>
    </div>
  ) : null;

  const dropdownLabel = {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.45)',
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  };

  const linkedInAddress =
    groupDetails.socialLinks && groupDetails.socialLinks[0]
      ? groupDetails.socialLinks[0]
      : '';

  const twitterAddress =
    groupDetails.socialLinks && groupDetails.socialLinks[1]
      ? groupDetails.socialLinks[1]
      : '';

  const websiteAddress =
    groupDetails.socialLinks && groupDetails.socialLinks[2]
      ? groupDetails.socialLinks[2]
      : '';

  const facebookAddress =
    groupDetails.socialLinks && groupDetails.socialLinks[3]
      ? groupDetails.socialLinks[3]
      : '';
  const form = (
    <div className="types-panel">
      <div className="types-panel-title">
        <div>
          <h6>Workforce Member Details</h6>
        </div>
        <div>
          {/* {groupDetails.firstName && (
            <button className="download" onClick={handleExportData}>
              Download Record
            </button>
          )} */}
        </div>
      </div>
      <Grid container spacing={0} style={{ width: '96%' }}>
        <Grid item xs={3}>
          <TextField
            id="firstName"
            placeholder="First Name"
            value={groupDetails.firstName}
            onChange={value => updateGroupName('firstName', value)}
            rightInputAction={
              <CircularValidation
                isValid={groupDetails.firstName.length >= 2}
                defaultMsg="Workforce's first name required"
                errorMsg="First name must be at least 2 characters long"
                showDefault={!groupDetails.firstName}
              />
            }
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="lastName"
            placeholder="Last Name"
            value={groupDetails.lastName}
            onChange={value => updateGroupName('lastName', value)}
            rightInputAction={
              <CircularValidation
                isValid={groupDetails.lastName.length >= 2}
                defaultMsg="Workforce's Last name required"
                errorMsg="Last name must be at least 2 characters long"
                showDefault={!groupDetails.lastName}
              />
            }
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="phoneNumber"
            placeholder="Phone Number"
            value={groupDetails.phoneNumber}
            onChange={value => updateGroupName('phoneNumber', value)}
            rightInputAction={
              <CircularValidation
                isValid={
                  !!groupDetails.phoneNumber &&
                  groupDetails.phoneNumber.length >= 2
                }
                defaultMsg="Workforce's Phone Number required"
                errorMsg="Phone Number must be at least 2 characters long"
                showDefault={!groupDetails.phoneNumber}
              />
            }
          />
        </Grid>
        {fieldsVisibility && fieldsVisibility.organisationVisible !== false && (
          <Grid item xs={3}>
            <TextField
              id="organisation"
              placeholder="Organisation"
              value={groupDetails.organisation}
              onChange={value => updateGroupName('organisation', value)}
            />
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.roleVisible !== false && (
          <Grid item xs={3}>
            <TextField
              id="role"
              placeholder="Role"
              value={groupDetails.role}
              onChange={value => updateGroupName('role', value)}
            />
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.landlineVisible !== false && (
          <Grid item xs={3}>
            <TextField
              id="landline"
              placeholder="Landline Number"
              value={groupDetails.landline}
              onChange={value => updateGroupName('landline', value)}
              rightInputAction={
                <CircularValidation
                  isValid={
                    !!groupDetails.landline && groupDetails.landline.length >= 2
                  }
                  defaultMsg="Workforce's Landline Number required"
                  errorMsg="Landline Number must be at least 2 characters long"
                  showDefault={!groupDetails.landline}
                />
              }
            />
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.genderVisible !== false && (
          <Grid item xs={3}>
            <Dropdown
              underlineStyle={{ width: '95%' }}
              className="text-field-wrapper"
              floatingLabelText="Gender"
              value={groupDetails.gender}
              onChange={(e, key, val) => updateGroupName('gender', val)}
              floatingLabelStyle={dropdownLabel}
            >
              {gender.map(item => (
                <MenuItem key={item} value={item} primaryText={item} />
              ))}
            </Dropdown>
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.dobVisible !== false && (
          <Grid item xs={3}>
            <DatePicker
              fullWidth
              id="dob"
              className="text-field-wrapper"
              floatingLabelText="Date of Birth"
              value={groupDetails.dob ? new Date(groupDetails.dob) : null}
              onChange={(e, timestamp) =>
                updateGroupName('dob', timestamp.getTime())
              }
              floatingLabelStyle={dropdownLabel}
            />
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.disabilityVisible !== false && (
          <Grid item xs={3}>
            <Dropdown
              underlineStyle={{ width: '95%' }}
              className="text-field-wrapper"
              floatingLabelText="Disability"
              value={groupDetails.disability}
              onChange={(e, key, val) => updateGroupName('disability', val)}
              floatingLabelStyle={dropdownLabel}
            >
              {disability.map(item => (
                <MenuItem key={item} value={item} primaryText={item} />
              ))}
            </Dropdown>
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.postalAddressVisible !== false && (
          <Grid item xs={3}>
            <TextField
              id="postalAddress"
              placeholder="Postal Address"
              value={groupDetails.postalAddress}
              onChange={value => updateGroupName('postalAddress', value)}
              rightInputAction={
                <CircularValidation
                  isValid={
                    !!groupDetails.postalAddress &&
                    groupDetails.postalAddress.length >= 2
                  }
                  defaultMsg="Workforce's Postal Address required"
                  errorMsg="Postal Address must be at least 2 characters long"
                  showDefault={!groupDetails.postalAddress}
                />
              }
            />
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.postCodeVisible !== false && (
          <Grid item xs={3}>
            <TextField
              id="postCode"
              placeholder="Post Code"
              value={groupDetails.postCode}
              onChange={value => updateGroupName('postCode', value)}
              rightInputAction={
                <CircularValidation
                  isValid={
                    !!groupDetails.postCode && groupDetails.postCode.length >= 2
                  }
                  defaultMsg="Workforce's Post Code required"
                  errorMsg="Post Code must be at least 2 characters long"
                  showDefault={!groupDetails.postCode}
                />
              }
            />
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.localAuthorityVisible !== false && (
          <Grid item xs={3}>
            <Dropdown
              underlineStyle={{ width: '95%' }}
              className="text-field-wrapper"
              floatingLabelText="Local Authority"
              value={groupDetails.localAuthority}
              onChange={(e, key, val) => updateGroupName('localAuthority', val)}
              floatingLabelStyle={dropdownLabel}
            >
              {localAuthorities.map(item => (
                <MenuItem key={item} value={item} primaryText={item} />
              ))}
            </Dropdown>
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.ethnicGroupVisible !== false && (
          <Grid item xs={3}>
            <Dropdown
              underlineStyle={{ width: '95%' }}
              className="text-field-wrapper"
              floatingLabelText="Ethnic group"
              value={groupDetails.ethnicGroup}
              onChange={(e, key, val) => updateGroupName('ethnicGroup', val)}
              floatingLabelStyle={dropdownLabel}
            >
              {ethnicGroups.map(item => (
                <MenuItem key={item} value={item} primaryText={item} />
              ))}
            </Dropdown>
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.religionVisible !== false && (
          <Grid item xs={3}>
            <Dropdown
              underlineStyle={{ width: '95%' }}
              className="text-field-wrapper"
              floatingLabelText="Religion"
              value={groupDetails.religion}
              onChange={(e, key, val) => updateGroupName('religion', val)}
              floatingLabelStyle={dropdownLabel}
            >
              {religions.map(item => (
                <MenuItem key={item} value={item} primaryText={item} />
              ))}
            </Dropdown>
          </Grid>
        )}
        {fieldsVisibility &&
          fieldsVisibility.sexualOrientationVisible !== false && (
            <Grid item xs={3}>
              <Dropdown
                underlineStyle={{ width: '95%' }}
                className="text-field-wrapper"
                floatingLabelText="Sexual Orientation"
                value={groupDetails.sexualOrientation}
                onChange={(e, key, val) =>
                  updateGroupName('sexualOrientation', val)
                }
                floatingLabelStyle={dropdownLabel}
              >
                {sexualOrientations.map(item => (
                  <MenuItem key={item} value={item} primaryText={item} />
                ))}
              </Dropdown>
            </Grid>
          )}
        {fieldsVisibility &&
          fieldsVisibility.genderReassignmentVisible !== false && (
            <Grid item xs={3}>
              <Dropdown
                underlineStyle={{ width: '95%' }}
                className="text-field-wrapper"
                floatingLabelText="Gender Reassignment"
                value={groupDetails.genderReassignment}
                onChange={(e, key, val) =>
                  updateGroupName('genderReassignment', val)
                }
                floatingLabelStyle={dropdownLabel}
              >
                {genderReassignments.map(item => (
                  <MenuItem key={item} value={item} primaryText={item} />
                ))}
              </Dropdown>
            </Grid>
          )}
        {fieldsVisibility && fieldsVisibility.leaveTypeVisible !== false && (
          <Grid item xs={3}>
            <Dropdown
              underlineStyle={{ width: '95%' }}
              className="text-field-wrapper"
              floatingLabelText="Maternity/Paternity leave"
              value={groupDetails.leaveType}
              onChange={(e, key, val) => updateGroupName('leaveType', val)}
              floatingLabelStyle={dropdownLabel}
            >
              {leaveTypes.map(item => (
                <MenuItem key={item} value={item} primaryText={item} />
              ))}
            </Dropdown>
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.martialStatusVisible !== false && (
          <Grid item xs={3}>
            <Dropdown
              underlineStyle={{ width: '95%' }}
              className="text-field-wrapper"
              floatingLabelText="Marriage/Civil Partnership"
              value={groupDetails.martialStatus}
              onChange={(e, key, val) => updateGroupName('martialStatus', val)}
              floatingLabelStyle={dropdownLabel}
            >
              {martialStatus.map(item => (
                <MenuItem key={item} value={item} primaryText={item} />
              ))}
            </Dropdown>
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.linkedInAddressVisible !== false && (
          <Grid item xs={3}>
            <TextField
              id="linkedInAddress"
              placeholder="LinkedIn Address"
              value={groupDetails.socialLinks && groupDetails.socialLinks[0]}
              onChange={value =>
                updateGroupName('socialLinks', [
                  value,
                  twitterAddress,
                  websiteAddress,
                  facebookAddress,
                ])
              }
            />
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.twitterAddressVisible !== false && (
          <Grid item xs={3}>
            <TextField
              id="twitterAddress"
              placeholder="Twitter Address"
              value={groupDetails.socialLinks && groupDetails.socialLinks[1]}
              onChange={value =>
                updateGroupName('socialLinks', [
                  linkedInAddress,
                  value,
                  websiteAddress,
                  facebookAddress,
                ])
              }
            />
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.websiteAddressVisible !== false && (
          <Grid item xs={3}>
            <TextField
              id="websiteAddress"
              placeholder="Website Address"
              value={groupDetails.socialLinks && groupDetails.socialLinks[2]}
              onChange={value =>
                updateGroupName('socialLinks', [
                  linkedInAddress,
                  twitterAddress,
                  value,
                  facebookAddress,
                ])
              }
            />
          </Grid>
        )}
        {fieldsVisibility && fieldsVisibility.facebookAddressVisible !== false && (
          <Grid item xs={3}>
            <TextField
              id="facebookAddress"
              placeholder="Facebook Address"
              value={groupDetails.socialLinks && groupDetails.socialLinks[3]}
              onChange={value =>
                updateGroupName('socialLinks', [
                  linkedInAddress,
                  twitterAddress,
                  websiteAddress,
                  value,
                ])
              }
            />
          </Grid>
        )}
      </Grid>
      <div style={{ marginTop: '20px' }} className="types-panel-title">
        Groups{' '}
        <IconButton
          aria-label="Add"
          size="small"
          onClick={onAddWorkforceDetails}
          style={{ display: 'inline' }}
        >
          <AddIcon />
        </IconButton>
      </div>
      <GroupTabs onSubmit={() => {}} pending={false} />
      <CreateField />
    </div>
  );

  return (
    <div className="types-container">
      <div className="list-fieldset">{displayError}</div>
      <DialogConfirmation
        open={confirmDeleteDialogOpen}
        onClose={toggleConfirmBox}
        message={`Are you sure you want to permenantly delete the field? This will also remove all the data which belongs from this field.`}
        // action={() => deleteMember(this.state.item)}
      />
      {form}
    </div>
  );
};

Edit.propTypes = {
  error: PropTypes.string,
  type: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  updateGroupName: PropTypes.func.isRequired,
  getMemberDetails: PropTypes.func.isRequired,
  onAddWorkforceDetails: PropTypes.func.isRequired,
  groupDetails: PropTypes.object.isRequired,
  fieldsVisibility: PropTypes.object,
  allGroups: PropTypes.object,
  savedData: PropTypes.object,
};

Edit.defaultProps = {
  error: null,
  fieldsVisibility: null,
};

export default Edit;
