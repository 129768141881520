import React, { useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import IconButton from 'material-ui/IconButton';
import TextField from '../../../../../../../app/components/textField';
import Container from '../../../../../../../app/components/container';
import { FlatButton } from '../../../../../../../app/components/buttons';
import { Tabs, Tab } from '../../../../../components/tabs';
import { Close } from '../../../../../../../app/components/icons';
import UploadDialog from './UploadDialog';
import { objectToArray } from '../../../../../../../utils/object';
import './styles.css';

const PDFViewer = ({
  data,
  handleChange,
  onReceivePdf,
  pending,
  onDeletePdf,
}) => {
  const { t } = useTranslation();
  const [filesList, setFilesList] = useState([]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  //sort in ascending order
  const sortedData =
    data && data.files && Object.keys(data.files).length
      ? objectToArray(data.files).sort((file1, file2) => {
          return file1.value.created > file2.value.created
            ? 1
            : file1.value.created < file2.value.created
            ? -1
            : 0;
        })
      : [];

  const fileRef = useRef(null);

  const openFileSelector = () => {
    fileRef.current.click();
  };

  const fileChange = e => {
    const files = Object.values(e.target.files);
    setFilesList([...filesList, ...files]);
  };

  const handleTitleChange = (e, i) => {
    handleChange('title', e, i);
  };

  const openUploadDialog = () => {
    setUploadDialogOpen(true);
  };

  const closeUploadDialog = () => {
    setUploadDialogOpen(false);
    setFilesList([]);
  };

  const handleUploadFiles = async () => {
    onReceivePdf(filesList, setUploadDialogOpen, setFilesList);
  };

  const handleDeleteFile = async (filename, keyname) => {
    onDeletePdf(filename, keyname);
  };

  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container>
          <FlatButton
            label={t('Add File')}
            onClick={openUploadDialog}
            disabled={pending}
          />
          {sortedData.length > 0 ? (
            <div className="pdf-container">
              {sortedData.map(fileKey => (
                <div key={fileKey} className={'pdf-card'}>
                  <div className="delete-icon">
                    <IconButton
                      onClick={() =>
                        handleDeleteFile(fileKey.value.aws_key, fileKey.id)
                      }
                    >
                      <Close />
                    </IconButton>
                  </div>
                  <div className="inner-pdf-container">
                    <h4>
                      <Trans>Name</Trans> - <em>{fileKey.value.name}</em>
                    </h4>
                    <div className="pdf-title-input">
                      <h4>
                        <Trans>Title</Trans>
                      </h4>
                      <TextField
                        id="standard-search"
                        label={t('Search field')}
                        type="search"
                        variant="standard"
                        value={fileKey.value.title || ''}
                        onChange={e => handleTitleChange(e, sortedData.id)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <h3>
              <Trans>No files uploaded</Trans>
            </h3>
          )}
          <input
            type="file"
            className="hidden-input"
            ref={fileRef}
            onChange={fileChange}
            accept=".pdf"
            multiple
          />

          {uploadDialogOpen && (
            <UploadDialog
              open={uploadDialogOpen}
              openFileSelector={openFileSelector}
              filesList={filesList}
              onCancel={closeUploadDialog}
              onUpload={handleUploadFiles}
              pending={pending}
            />
          )}
        </Container>
      </Tab>
    </Tabs>
  );
};

export default PDFViewer;
