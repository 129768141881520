import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import RosterItem from './RosterItem';
import { itemFieldChange } from '../actions';

const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) => {
    const { item } = queryString.parse(ownProps.location.search);

    return dispatch(
      itemFieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        ownProps.component.type,
        item,
        field,
        value,
      ),
    );
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RosterItem),
);
