import { connect } from 'react-redux';
import { resetStaffData, staffMemberChange } from './actions';

import Staff from './Staff';
const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.ecommerce.booking.staff,
  appname: ownProps.match.params.appname,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});

const mapDisptachToProps = (dispatch, ownProps) => ({
  onStaffMemberChange: member => dispatch(staffMemberChange(member)),
  onResetStaffData: () => dispatch(resetStaffData()),
});

export default connect(mapStateToProps, mapDisptachToProps)(Staff);
