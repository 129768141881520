import queryString from 'query-string';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import OfferWrapper from './Offer';

import { registerUser, resetRegistrationForm, loginUser } from '../actions';
import { registerUserWithOffer } from './actions';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    pending: state.account.register.pending,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const query = queryString.parse(ownProps.location.search);

  return {
    onRegisterUser: callback =>
      dispatch(registerUserWithOffer(query.offer, callback)),
    onLeave: () => dispatch(resetRegistrationForm()),
    onSubmit: (cb, offer) => {
      dispatch(
        registerUser(
          cb,
          queryString.parse(ownProps.location.search).referrer,
          offer,
        ),
      );
    },
    onLogin: (email, password, cb) => dispatch(loginUser(email, password, cb)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferWrapper),
);
