import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import styled from 'styled-components';
import { filter } from 'lodash';
import { useTranslation } from 'react-i18next';

import TextField from '../../../../../../../app/components/textField';
import { Tabs, Tab } from '../../../../../components/tabs';
import Container from '../../../../../../../app/components/container';
import DesignToggle from '../../../../../components/designToggle';
import SectionHeader from '../../../../../components/sectionHeader';
import SelectField from '../../../../../../../app/components/selectField';
import ColorPicker from '../../../../../components/colorPicker';
import MenuItem from 'material-ui/MenuItem';

import LayoutTab from '../../tabs/layoutTab';
import LayoutOption from '../../tabs/layoutTab/layoutOption';
import wordpressGridScreen from './wordpressGridScreen';
import wordpressListGridScreen from './wordpressListGridScreen';
import wordpressListScreen from './wordpressListScreen';
import themeStyles from '../../../../../../../_export.scss';

import { parseDomain } from 'parse-domain';

const FullWidth = styled.div`
  width: 100%;
`;

const WordPress = ({
  data,
  onChange,
  onToggle,
  onDesignChange,
  component,
  design,
  appname,
}) => {
  const { t } = useTranslation();

  const defaultFeedback = {
    testing: t('Testing connection...'),
    success: t('Successfully connected!'),
    failure: t('Connection failed.'),
  };

  const validateApiSource = (apiSource, testConnection, feedback) => {
    if (apiSource === '') {
      return {
        feedback: '',
        isValid: false,
      };
    }

    const protocolRegex = /http?s:\/\//i;
    const { subDomains } = parseDomain(apiSource.replace(protocolRegex, ''));
    //domain should have www if it is a root domain
    let regex = /^https:\/\/w{3}.*/;
    let validationFeedback = `${t(
      'Please enter a valid url - make sure it starts with',
    )} https://www ${t('eg')} (https://www.example.com)`;

    //for subdomain only the protocol is required
    if (subDomains && subDomains.length > 0) {
      regex = /^https:\/\/.*/;
      validationFeedback = `${t(
        'Please enter a valid url - make sure it starts with',
      )} https:// ${t('eg')} (https://blog.example.com)`;
    }

    if (!regex.test(apiSource)) {
      return {
        feedback: validationFeedback,
        isValid: false,
      };
    }

    return {
      feedback: apiSource && feedback[testConnection],
      isValid: testConnection !== 'failure',
    };
  };

  const categoryChange = (field, value, categories, onChange) => {
    let activeCategory = filter(categories, { id: value });
    if (value === 'all') {
      activeCategory = [
        {
          id: 'all',
          name: 'All',
          slug: 'page',
          pages: {},
          count: null,
          pageCount: null,
        },
      ];
    }
    onChange(field, activeCategory);
  };
  const feedback = { ...defaultFeedback };
  const apiValidation = validateApiSource(
    data.apiSource,
    data.testConnection,
    feedback,
  );

  const handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    onDesignChange(prop, rgba);
  };

  const Categories = () => {
    const categoryLinks = Object.keys(data.categories)
      .filter(key => key !== 'all')
      .sort()
      .map(key => {
        const { name, slug, id } = data.categories[key];
        return <MenuItem key={slug} value={id} primaryText={name} />;
      });

    categoryLinks.unshift(
      <MenuItem key="index" value="all" primaryText={t('Latest')} />,
    );
    return categoryLinks.length ? (
      <SelectField
        label={t('Select a category')}
        value={
          data.activeCategory && data.activeCategory.length > 0
            ? data.activeCategory[0].id
            : null
        }
        options={categoryLinks}
        onChange={(index, value) =>
          categoryChange('activeCategory', value, data.categories, onChange)
        }
        disabled={!data.showSpecificCategory}
      />
    ) : null;
  };

  const switches = (title, field, propsValue, defaultValue) => (
    <FullWidth>
      <SectionHeader
        title={title}
        actions={
          <Toggle
            toggled={propsValue !== undefined ? propsValue : defaultValue}
            onToggle={(e, isToggled) => onToggle(field, isToggled)}
            trackSwitchedStyle={{
              backgroundColor: themeStyles.primaryColorLight,
            }}
            thumbSwitchedStyle={{ backgroundColor: themeStyles.primaryColor }}
          />
        }
      />
      <br />
    </FullWidth>
  );

  const renderCategoryCheck = () => {
    if (data.testConnection === 'success') {
      return (
        <FullWidth>
          {switches(
            t('Display Categories'),
            'displayCategories',
            data.displayCategories,
            true,
          )}
          {switches(
            t('Show specific category'),
            'showSpecificCategory',
            data.showSpecificCategory,
            false,
          )}
          {Categories()}
        </FullWidth>
      );
    }
    return null;
  };

  const wordpressLayoutsOptions = () => {
    const style = {
      width: '100%',
      heigth: '100%',
    };
    return {
      options: [
        <LayoutOption
          key="wordpress_layout"
          wrapperKey="wordpress_layout"
          grid={2}
          tooltip={t('Change your wordpress component template')}
          title={t('Wordpress templates')}
          active={component.layout}
          items={[
            { value: 'List', icon: wordpressListScreen, style },
            { value: 'ListGrid', icon: wordpressListGridScreen, style },
            { value: 'Grid', icon: wordpressGridScreen, style },
          ]}
        />,
      ],
    };
  };

  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container>
          <TextField
            id="apiSource"
            name="apiSource"
            placeholder={"Enter the WordPress's URL"}
            value={data.apiSource}
            onChange={value => onChange('apiSource', value)}
            feedback={apiValidation.feedback}
            isValid={apiValidation.isValid}
          />
          <br />
          {renderCategoryCheck()}
        </Container>
      </Tab>
      <Tab label={t('design')}>
        <Container>
          <DesignToggle component={component} onDesignChange={onDesignChange} />
          <ColorPicker
            label={t('Title Color')}
            color={design.titleColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('titleColor')}
          />
          <ColorPicker
            label={t('Text Color')}
            color={design.textColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('textColor')}
          />
          <ColorPicker
            label={t('Card Color')}
            color={design.cardColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('cardColor')}
          />
        </Container>
      </Tab>
      <Tab label={t('layout')}>
        <LayoutTab
          componentKey={component.key}
          appname={appname}
          {...wordpressLayoutsOptions()}
        />
      </Tab>
    </Tabs>
  );
};

WordPress.propTypes = {
  appname: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  design: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  component: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onDesignChange: PropTypes.func.isRequired,
};

export default WordPress;
