import styled from 'styled-components';

const Subtitle = styled.h5`
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  margin: 1rem 0;
  padding: 0 1.5rem;
  width: 100%;
  &.disabled {
    color: rgba(0, 0, 0, 0.4);
  }
`;

export default Subtitle;
