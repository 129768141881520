import _keys from 'lodash/keys';

import {
  SCAN_BARCODE,
  REQUEST_USERS,
  RECEIVE_USERS,
  NO_USERS_EXIST,
} from './actions';

const initialState = {
  pending: false,
  chunks: {},
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  selected: [],
};

const scanReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_USERS:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_USERS: {
      const filteredChunks = {};
      if (Object.keys(state.filteredChunks).length) {
        Object.keys(action.value).forEach(key => {
          filteredChunks[key] = action.value[key];
          filteredChunks[key].selected =
            state.filteredChunks[key] && state.filteredChunks[key].selected
              ? state.filteredChunks[key].selected
              : false;
        });
      }
      return {
        ...state,
        chunks: action.value,
        filteredChunks: Object.keys(filteredChunks).length
          ? filteredChunks
          : action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };
    }

    case NO_USERS_EXIST:
      return {
        ...state,
        pending: false,
        chunks: {},
        items: [],
        filteredChunks: {},
        selected: [],
        selectAll: false,
      };
    case SCAN_BARCODE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default scanReducer;
