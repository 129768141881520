import firebase from '../../../../../firebase';
import { uploadImage } from '../../../../editor/internals/imageUpload/actions';

export const SAVE_APP_SPLASH = 'SAVE_APP_SPLASH';
export const APP_SPLASH_SUBMITTED = 'APP_SPLASH_SUBMITTED';

const saveAppData = appname => ({
  type: SAVE_APP_SPLASH,
  appname,
});

const submitSplashColor = () => ({ type: APP_SPLASH_SUBMITTED });

export const saveSplashScreenSetting = (appname, attr, value) => dispatch => {
  const designSplashRef = firebase
    .database()
    .ref(`apps/${appname}/draft/design_data/splash_screen`);

  designSplashRef.update({ [attr]: value });

  dispatch(saveAppData(appname));
};

export const saveSplashBackground = (appname, color) => dispatch => {
  const splashColorRef = firebase
    .database()
    .ref(`apps/${appname}/draft/design_data/splash_screen`);

  splashColorRef.update({
    backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
  });

  dispatch(saveAppData(appname));
};

export const onColorSubmit = appname => dispatch => {
  uploadImage(null, null, null, appname, 'color');
  dispatch(submitSplashColor());
};
