import React from 'react';

const HtmlIcon = () => (
  <svg
    className="icon htmlIcon"
    stroke="currentColor"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    height="3.09375em"
    width="3.09375em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5"
    />
  </svg>
);

export default HtmlIcon;
