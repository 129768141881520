import { connect } from 'react-redux';

import AdilloMessage from './AdilloMessage';
import { toggleAdilloMessage } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  showMessage: state.app.adilloMsg.open,
});

const mapDisptachToProps = (dispatch, ownProps) => ({
  toggleAdilloMessage: value => dispatch(toggleAdilloMessage(value)),
});

export default connect(mapStateToProps, mapDisptachToProps)(AdilloMessage);
