import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'material-ui/Tabs';

const styles = {
  inkBarStyle: {
    backgroundColor: '#261ed5',
    width: 120,
    height: '4px',
  },
  contentContainerStyle: {
    borderTop: '1px solid rgb(196, 196, 196)',
  },
};

const CustomTabs = ({ value, children, setActiveTab }) => {
  const childrenLength = children.length || 0;
  const tabItemContainerStyle = {
    width: styles.inkBarStyle.width * childrenLength,
  };

  return (
    <Tabs
      inkBarStyle={styles.inkBarStyle}
      contentContainerStyle={styles.contentContainerStyle}
      tabItemContainerStyle={tabItemContainerStyle}
      value={value}
      {...(setActiveTab && { onChange: val => setActiveTab(val) })}
    >
      {children}
    </Tabs>
  );
};

CustomTabs.propTypes = {
  value: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  setActiveTab: PropTypes.func,
};

export default CustomTabs;
