import { connect } from 'react-redux';

import AppContext from './AppContext';
import { loadSubscription, recordLastVisited, selectApp } from './actions';
import { logout } from '../../../modules/account/actions';
import { openPromoOfferPopup } from '../promoOfferNotice/actions';
import { fetchAppPreference } from '../appPreference/action';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  auth: state.account.auth,
  account: state.account.organisation,
  role: state.account.profile.role,
  subscription:
    state.account.subscriptions.items[ownProps.match.params.appname],
  accountId: state.account.profile.account,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
  isAppsumoUser: state.account.organisation.appsumo_id || false,
  is14DayTrialUser: state.account.organisation.isOn14DaysTrial || false,
  referrer: state.account.organisation.referrer,
});

const mapDisptachToProps = (dispatch, ownProps) => ({
  openPromoOfferPopup: () => dispatch(openPromoOfferPopup()),
  recordLastVisited: () => {
    dispatch(recordLastVisited(ownProps.match.params.appname));
    dispatch(selectApp(ownProps.match.params.appname));
    dispatch(loadSubscription(ownProps.match.params.appname));
    dispatch(fetchAppPreference(ownProps.match.params.appname));
  },
  onLogout: () => {
    dispatch(logout());
  },
});

export default connect(mapStateToProps, mapDisptachToProps)(AppContext);
