import _pickBy from 'lodash/pickBy';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import {
  REQUEST_MARKETPLACE_BUYERS,
  RECEIVE_MARKETPLACE_BUYERS,
  NO_MARKETPLACE_BUYERS_EXIST,
  FILTER_MARKETPLACE_BUYERS_DATA,
  SELECT_MARKETPLACE_BUYER,
  SELECT_ALL_MARKETPLACE_BUYERS,
  DESELECT_ALL_MARKETPLACE_BUYERS,
  OPEN_MARKETPLACE_BUYERS_DIALOG,
  CLOSE_MARKETPLACE_BUYERS_DIALOG,
  EMAIL_SELECTED,
  DELETE_SELECTED,
  REQUEST_GROUPS,
  RECEIVE_GROUPS,
  SORT_BUYERS,
} from './types';

const initialState = {
  pending: false,
  chunks: {},
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  selected: [],
  buyerDialogOpen: false,
  selectAll: false,
  selectedBuyers: {},
};

const filterChunks = (chunks, filterHolder = '') => {
  let filteredChunks = chunks;
  const filter = filterHolder.replace(/[^a-zA-Z0-9 ]/g, '');

  if (filter !== '') {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`(${filter})`, 'gi');
      const fullName = `${chunk.firstName} ${chunk.lastName}`;
      return (
        rgx.test(chunk.email) ||
        rgx.test(chunk.firstName) ||
        rgx.test(chunk.lastName) ||
        rgx.test(fullName)
      );
      // return rgx.test(chunk.email);
    });
  }
  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const sortItems = (filteredChunks, sortBy, order) =>
  _keys(filteredChunks).sort((a, b) => {
    let keyA = filteredChunks[a][sortBy];
    let keyB = filteredChunks[b][sortBy];
    if (sortBy === 'date') {
      keyA = new Date(filteredChunks[a][sortBy]);
      keyB = new Date(filteredChunks[b][sortBy]);
    }
    if (order === 'desc') {
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
    } else if (order === 'asc') {
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
    }
    return 0;
  });

const users = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_MARKETPLACE_BUYERS:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_MARKETPLACE_BUYERS: {
      const filteredChunks = {};
      if (Object.keys(state.filteredChunks).length) {
        Object.keys(action.value).forEach(key => {
          filteredChunks[key] = action.value[key];
          filteredChunks[key].selected =
            state.filteredChunks[key] && state.filteredChunks[key].selected
              ? state.filteredChunks[key].selected
              : false;
        });
      }
      return {
        ...state,
        chunks: action.value,
        filteredChunks: Object.keys(filteredChunks).length
          ? filteredChunks
          : action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };
    }

    case REQUEST_GROUPS:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_GROUPS:
      return {
        ...state,
        groups: action.value,
        pending: false,
        error: null,
      };

    case NO_MARKETPLACE_BUYERS_EXIST:
      return {
        ...state,
        pending: false,
        chunks: {},
        items: [],
        filteredChunks: {},
        selected: [],
        selectAll: false,
      };

    case FILTER_MARKETPLACE_BUYERS_DATA:
      return {
        ...state,
        ...filterChunks(state.chunks, action.payload),
        filter: action.payload,
      };

    case SELECT_MARKETPLACE_BUYER: {
      const prevState = state.filteredChunks[action.key].selected;

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case SELECT_ALL_MARKETPLACE_BUYERS: {
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: true,
      }));
      const selected = _union(state.selected, _keys(filteredChunks));

      return {
        ...state,
        filteredChunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_MARKETPLACE_BUYERS:
      return {
        ...state,
        filteredChunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    case OPEN_MARKETPLACE_BUYERS_DIALOG: {
      const selectedGroups = [];
      const selectedBuyers = _pick(state.chunks, state.selected);
      if (
        selectedBuyers &&
        Object.keys(selectedBuyers) &&
        Object.keys(selectedBuyers).length === 1 &&
        state.selected &&
        state.selected[0] &&
        selectedBuyers[state.selected[0]].groups
      ) {
        Object.keys(selectedBuyers[state.selected[0]].groups).forEach(key => {
          selectedGroups.push(key);
        });

        return {
          ...state,
          buyerDialogOpen: true,
          selectedBuyers,
          selectedGroups,
        };
      }
      return {
        ...state,
        buyerDialogOpen: true,
        selectedBuyers,
      };
    }

    case CLOSE_MARKETPLACE_BUYERS_DIALOG:
      return {
        ...state,
        buyerDialogOpen: false,
        selectedBuyers: {},
        selectedGroups: [],
      };

    case EMAIL_SELECTED:
    case DELETE_SELECTED:
      return {
        ...state,
      };

    case SORT_BUYERS:
      return {
        ...state,
        order: state.order === 'desc' ? 'asc' : 'desc',
        items: sortItems(
          state.filteredChunks,
          action.column.id,
          state.order === 'desc' ? 'asc' : 'desc',
        ),
        colSorted: action.column.id,
      };

    default:
      return state;
  }
};

export default users;
