import {
  REQUEST_BUYER_DETAILS,
  RECEIVE_BUYER_DETAILS,
  RECEIVED_VENDORS,
  RECEIVED_PRODUCTS,
  NO_PRODUCTS_FOUND,
  NO_VENDORS_FOUND,
} from './types';

const initialState = {
  pending: false,
  error: null,
  buyer: {},
  allProducts: [],
  allVendors: [],
};

const buyer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_BUYER_DETAILS:
      return {
        ...state,
        buyer: action.value,
        pending: false,
        error: null,
      };

    case RECEIVED_PRODUCTS: {
      return {
        ...state,
        allProducts: action.value,
        pending: false,
        error: null,
      };
    }

    case NO_PRODUCTS_FOUND:
      return {
        ...state,
        pending: false,
        allProducts: [],
      };

    case RECEIVED_VENDORS: {
      return {
        ...state,
        allVendors: action.value,
        pending: false,
        error: null,
      };
    }

    case NO_VENDORS_FOUND:
      return {
        ...state,
        pending: false,
        allVendors: [],
      };

    default:
      return state;
  }
};

export default buyer;
