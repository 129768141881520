import _map from 'lodash/map';
import _first from 'lodash/first';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import moment from 'moment';
import i18n from 'i18next';
import firebase from '../../../../../../firebase';
import {
  openDialog,
  dialogActionComplete,
  reportDialogError,
} from '../../../../../../app/modules/dialog/actions';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import { whitelabeledHost } from '../../../../../../utils/whitelabeledHost';

const reOrderingPagesSuccess = () => ({
  type: 'REORDERING_PAGES_SUCCESS',
});

const reOrderingPagesError = err => ({
  type: 'REORDERING_PAGES_ERROR',
  err,
});

const findIndex = (array, element) => {
  if (element === null) {
    return array.length;
  }

  return array.indexOf(element);
};

const moveArray = (oldIndex, newIndex, arr) => {
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);

  return arr;
};

const movePage = (idToMove, moveTo, appname) => dispatch => {
  firebase
    .database()
    .ref(`apps/${appname}/draft/layout_data/childRoutes`)
    .transaction(childRoutes => {
      if (childRoutes === null) return null;

      const oIndex = findIndex(childRoutes, idToMove);
      let nIndex = findIndex(childRoutes, moveTo);
      if (childRoutes.length > 2) {
        if (nIndex > oIndex) {
          nIndex -= 1;
        }
        return moveArray(oIndex, nIndex, childRoutes);
      }
      return _reverse(childRoutes);
    })
    .then(() => dispatch(reOrderingPagesSuccess()))
    .catch(error => dispatch(reOrderingPagesError(error.message)));
};

const rollBackToOriginal = (appname, collection) => dispatch => {
  const originalOrder = _map(collection, 'key');

  firebase
    .database()
    .ref(`apps/${appname}/draft/layout_data/childRoutes`)
    .set(originalOrder)
    .then(() => dispatch(reOrderingPagesSuccess()))
    .catch(error => dispatch(reOrderingPagesError(error.message)));
};

// Currently this function is only here as wordpress breaks when it's the homepage component
// We need to think of a solution to this when we refactor wordpress component
const checkComponentType = (idToMove, collection, moveTo, homepageId) =>
  new Promise((resolve, reject) => {
    const toMoveData = _find(collection, ['key', idToMove]);

    if (toMoveData.components) {
      _forEach(toMoveData.components, value => {
        if (value.type === 'WordPress' && moveTo === homepageId) {
          reject(i18n.t('WordPress component cannot be made your homepage'));
        }
      });
    } else if (toMoveData.tabs) {
      _forEach(toMoveData.tabs, value => {
        _forEach(value.components, v => {
          if (v.key.type === 'WordPress' && moveTo === homepageId) {
            reject(i18n.t('WordPress component cannot be made your homepage'));
          }
        });
      });
    }

    resolve();
  });

const changeCurrentHomepage = ({
  appname,
  homepageId,
  newHomepageId,
}) => async dispatch => {
  try {
    await firebase
      .database()
      .ref(`apps/${appname}/draft/always_data/${newHomepageId}`)
      .child('isHomepage')
      .set(true);

    await firebase
      .database()
      .ref(`apps/${appname}/draft/always_data/${homepageId}`)
      .child('isHomepage')
      .set(false);

    return dispatch({
      type: 'CHANGED_HOMEPAGE',
      newHomepage: newHomepageId,
      oldHomePage: homepageId,
    });
  } catch (error) {
    dispatch(reOrderingPagesError(error.message));
  }
};

export const reOrderPages = ({
  idToMove,
  moveTo,
  collection,
  newCollection,
  appname,
}) => dispatch => {
  const homepageId = _first(collection).key;
  const newHomepageId = _first(newCollection);
  dispatch(movePage(idToMove, moveTo, appname));
  checkComponentType(idToMove, collection, moveTo, homepageId)
    .then(() => {
      if (
        (homepageId === idToMove || homepageId === moveTo) &&
        moveTo !== idToMove
      ) {
        const options = {
          buttonLabel: i18n.t('Change'),
          title: i18n.t('New Homepage'),
          text: i18n.t(
            "You are about to change your app's homepage. Are you sure you wish to continue?",
          ),
        };

        openDialog(options)(dispatch)
          .then(val => {
            if (val.action === 'cancel') {
              dispatch(rollBackToOriginal(appname, collection));
            }
          })
          .then(() =>
            dispatch(
              changeCurrentHomepage({ appname, homepageId, newHomepageId }),
            ),
          )
          .then(() => dispatch(dialogActionComplete()))
          .catch(err => dispatch(reportDialogError(err)));
      }
    })
    .catch(err => {
      dispatch(rollBackToOriginal(appname, collection));
      dispatch(openSnackbar(err));
    });
};

export const reOrderNestedPages = (
  appname,
  pagesContainer,
) => async dispatch => {
  let pagesOrder = [];
  const pagesToUpdate = [];
  if (pagesContainer && pagesContainer.childNodes.length > 0) {
    const pages = pagesContainer.childNodes;

    pagesOrder = Array.from(pages).map((page, index) => ({
      pageId: page.id,
      order: index,
    }));
    pagesOrder.forEach(page =>
      pagesToUpdate.push(
        firebase
          .database()
          .ref(`apps/${appname}/draft/always_data`)
          .child(page.pageId)
          .update({ order: page.order }),
      ),
    );
    await Promise.all(pagesToUpdate);
    return dispatch(reOrderingPagesSuccess());
  }
};
const updateAppcuesVisibilityStatusInDB = (type, uid) => {
  const data =
    type === 'referFriend' ? { [type]: moment().valueOf() } : { [type]: true };
  return firebase
    .database()
    .ref(`users/${uid}/`)
    .child('appcues_popups_shown')
    .update(data);
};

export const checkAppcuesVisibleStatus = () => (dispatch, getState) => {
  const isSubAdmin =
    (getState().account.auth.profile.admin_appname &&
      getState().account.auth.profile.admin_appname.length > 0) ||
    false;

  const recentlyShowed = getState().app.appcues.recentlyShowedAppcues;
  const isWhiteLabelledHost = whitelabeledHost();

  if (isSubAdmin || isWhiteLabelledHost) {
    dispatch({ type: 'RESET_APPCUES_DATA' });
  } else if (recentlyShowed) {
    return dispatch({
      type: 'SET_APPCUES_DISPLAY_DATA',
      data: {
        showBeezerAppTour: false,
        showBeezerSurveyForm1: false,
        showBeezerRatingForm1: false,
        showBeezerReferFriend: false,
        recentlyShowedAppcues: true,
      },
    });
  } else {
    const appcuesPopopStatus =
      (getState().account.auth.profile.appcues_popups_shown &&
        getState().account.auth.profile.appcues_popups_shown) ||
      {};

    const uid = getState().account.auth.profile.uid;

    if (!appcuesPopopStatus.beezerAppTour) {
      dispatch({
        type: 'SET_APPCUES_DISPLAY_DATA',
        data: { showBeezerAppTour: true },
      });
      updateAppcuesVisibilityStatusInDB('beezerAppTour', uid, dispatch);
    } else if (!appcuesPopopStatus.surveyForm1) {
      dispatch({
        type: 'SET_APPCUES_DISPLAY_DATA',
        data: { showBeezerSurveyForm1: true },
      });
    } else if (!appcuesPopopStatus.ratingForm1) {
      dispatch({
        type: 'SET_APPCUES_DISPLAY_DATA',
        data: { showBeezerRatingForm1: true },
      });
    } else if (!appcuesPopopStatus.referFriend) {
      const accountCreatedDate = moment(
        getState().account.auth.profile.created_at,
      );
      const currentDate = moment();
      const daysDiffernce = currentDate.diff(accountCreatedDate, 'days');

      if (daysDiffernce % 10 === 0) {
        dispatch({
          type: 'SET_APPCUES_DISPLAY_DATA',
          data: { showBeezerReferFriend: true },
        });
      }
    } else if (appcuesPopopStatus.referFriend) {
      const lastDateShown = getState().account.auth.profile.appcues_popups_shown
        .referFriend;
      const currentDate = moment();
      const daysDiffernce = currentDate.diff(lastDateShown, 'days');
      if (daysDiffernce > 0 && daysDiffernce % 30 === 0) {
        dispatch({
          type: 'SET_APPCUES_DISPLAY_DATA',
          data: { showBeezerReferFriend: true },
        });
      }
    }

    setTimeout(() => {
      dispatch({
        type: 'SET_APPCUES_DISPLAY_DATA',
        data: { recentlyShowedAppcues: true },
      });
    }, 3000);
  }
};

export const submitAppcuesForms = (appname, type, data) => (
  dispatch,
  getState,
) => {
  const uid = getState().account.profile.uid;
  const email = getState().account.profile.email;
  const name = getState().account.profile.name.value;

  return firebase
    .database()
    .ref(`beezer_analytics/${type}`)
    .child(uid)
    .set({
      appname,
      uid,
      email,
      name,
      data,
      timestamp: moment().valueOf(),
    })
    .then(() => updateAppcuesVisibilityStatusInDB(type, uid))
    .then(() => {
      if (type === 'ratingForm1') {
        dispatch({
          type: 'SET_APPCUES_DISPLAY_DATA',
          data: { showBeezerRatingForm1: false },
        });
      } else if (type === 'surveyForm1') {
        dispatch({
          type: 'SET_APPCUES_DISPLAY_DATA',
          data: { showBeezerSurveyForm1: false },
        });
      }
    })
    .catch(err => console.error(err));
};

export const submitAppcuesReferFriend = type => (dispatch, getState) => {
  const uid = getState().account.profile.uid;
  return updateAppcuesVisibilityStatusInDB(type, uid)
    .then(() => {
      dispatch({
        type: 'SET_APPCUES_DISPLAY_DATA',
        data: { referFriend: false },
      });
    })
    .catch(err => console.error(err));
};
export const setActiveAccountTab = value => dispatch => {
  dispatch({
    type: 'SET_ACTIVE_ACC_TAB',
    value,
  });
};
