import React from 'react';
import moment from 'moment';
import { withStyles, makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { TableContainer } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { downloadFile } from '../../../../utils';

const StyledTableCell = withStyles(theme => ({
  head: {
    background: '#f0f0f0',
    color: 'black',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  body: {
    fontSize: 14,
    height: '80px',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'white',
    },
    whiteSpace: 'nowrap',
    width: '100%',
  },
}))(TableRow);

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [createData('Frozen yoghurt', 159, 6.0, 24, 4.0)];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableContainer: {
    maxHeight: 200,
  },
});

const getTableData = props => {
  const {
    firstNameVisible,
    lastNameVisible,
    organisationVisible,
    roleVisible,
    phoneNumberVisible,
    interestVisible,
    dobVisible,
    enabled,
  } = props.defaultGroup;
  const { t } = useTranslation();

  const row = [];
  if (props && props.defaultGroup && props.rowData) {
    props.rowData.map((item, i) => {
      if (!row[i]) {
        row[i] = [];
      }
      if (firstNameVisible)
        row[i].push({
          title: t('First Name'),
          value: props.rowData[i].firstName,
        });
      if (lastNameVisible)
        row[i].push({
          title: t('Last Name'),
          value: props.rowData[i].lastName,
        });
      row[i].push({ title: 'Email', value: props.rowData[i].email });
      if (organisationVisible)
        row[i].push({
          title: t('Organisation'),
          value: props.rowData[i].organisation,
        });
      if (roleVisible)
        row[i].push({ title: t('Role'), value: props.rowData[i].role });
      if (phoneNumberVisible)
        row[i].push({
          title: t('Phone Number'),
          value: props.rowData[i].phoneNumber,
        });
      if (interestVisible)
        row[i].push({ title: t('Interest'), value: props.rowData[i].interest });
      if (dobVisible)
        row[i].push({
          title: t('Date Of Birth'),
          value: props.rowData[i].dob
            ? moment(props.rowData[i].dob).format('DD/MM/YY')
            : '',
        });
      row[i].push({
        title: t('Allow Chat'),
        value: props.rowData[i].allowChat,
      });
      // row[i].push({ title: 'Is Active', value: props.rowData[i].isActive });
      row[i].push({
        title: t('Show Email In Search'),
        value: props.rowData[i].showEmailInSearch,
      });
      row[i].push({
        title: t('Verified'),
        value: props.rowData[i].emailVerified,
      });
      row[i].push({
        title: t('Date Created'),
        value: props.rowData[i].createdAt,
      });
      row[i].push({
        title: t('Last Logged in'),
        value: props.rowData[i].lastLoggedIn,
      });
      if (props.rowData[i].socialLinks)
        row[i].push({
          title: t('Social Links'),
          value: props.rowData[i].socialLinks,
        });

      props.rowData[i].customFieldData &&
        props.rowData[i].customFieldData.forEach(customData => {
          let customDataValue;

          switch (customData.type.value) {
            case 'Dropdown':
              customDataValue =
                customData.value && customData.isMultiple.value
                  ? customData.value.join(',')
                  : customData.value;
              break;
            case 'ExpiryDate':
            case 'Date':
              customDataValue =
                customData.value && moment(customData.value).format('DD/MM/YY');
              break;

            case 'File':
              customDataValue =
                customData.value &&
                customData.value.map(file => downloadFile(file.preview));
              break;
            default:
              customDataValue = customData.value;
              break;
          }
          row[i].push({ title: customData.name.value, value: customDataValue });
        });
    });
  }

  return row;
};

export default function CustomizedTables(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const data = getTableData(props);
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label={t('customized table')}>
        <TableHead>
          <TableRow>
            {data[0].map(row => (
              <StyledTableCell key={row.title}>{row.title}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => {
            return (
              <StyledTableRow key="row">
                {row.map(user => (
                  <StyledTableCell>{user.value || '-'}</StyledTableCell>
                ))}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
