import React from 'react';

const Text = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50.5 31.48"
  >
    <title>image</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path class="cls-1" d="M50.5,31.48H0V0H50.5ZM1.5,30H49V1.5H1.5Z" />
        <path
          class="cls-1"
          d="M44.5,26.58H6V4.89h.75a22,22,0,0,1,15.14,6l3.16,3L44.5,14Zm-37-1.5H43v-9.6l-18.55-.09L20.86,12A20.5,20.5,0,0,0,7.5,6.41Z"
        />
        <path
          class="cls-1"
          d="M39.5,14.74H38a5.25,5.25,0,0,0-10.5,0H26a6.75,6.75,0,0,1,13.5,0Z"
        />
      </g>
    </g>
  </svg>
);

export default Text;
