import { RECEIVED_APPS_DATA, LOADING_APPS_DATA } from './actions';
const initialState = {
  appsData: [],
  loading: false,
};
const appMenu = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_APPS_DATA:
      return {
        ...state,
        loading: true,
      };
    case RECEIVED_APPS_DATA:
      return {
        ...state,
        appsData: action.data,
        loading: false,
      };

    default:
      return state;
  }
};

export default appMenu;
