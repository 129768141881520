import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';

import CustomEmails from './customEmail';

import {
  setEmailType,
  getEmailList,
  onSubjectChange,
  onMailToChange,
  onFooterTeamNameChange,
  onMessageChange,
  saveCustomEmail,
  getCustomEmailTemplate,
  getDefaultTemplateText,
  loadContent,
  setTempData,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.engage.sms.data,
  open: state.engage.sms.data.open,
  isPendingSMS: state.engage.sms.data.isPendingSMS,
  smsPopup: state.engage.sms.data.smsPopup,
  message: state.engage.sms.data.message,
  subscription: state.app.appContext.subscription,
  smsCredit: state.account.organisation.sms_credit,
  organisation: state.account.organisation,
  integration: state.account.organisation.integration,
  emailTypeList: state.auth.customEmails.data.emailTypeList,
  templateText: state.auth.customEmails.data.templateText,
  emailType: state.auth.customEmails.data.emailType,
  customEmail: state.auth.customEmails.data.customEmail,
  pending: state.auth.customEmails.data.pending,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setEmailType: type => dispatch(setEmailType(type)),
  getEmailList: () => dispatch(getEmailList(ownProps.match.params.appname)),
  loadContent: nodeName =>
    dispatch(loadContent(ownProps.match.params.appname, nodeName)),
  getCustomEmailTemplate: emailType =>
    dispatch(getCustomEmailTemplate(ownProps.match.params.appname, emailType)),
  getDefaultTemplateText: emailType =>
    dispatch(getDefaultTemplateText(emailType)),
  onSubjectChange: value => dispatch(onSubjectChange(value)),
  onMailToChange: value => dispatch(onMailToChange(value)),
  onFooterTeamNameChange: value => dispatch(onFooterTeamNameChange(value)),
  onMessageChange: (value, contentType) =>
    dispatch(onMessageChange(value, contentType)),
  saveCustomEmail: emailType =>
    dispatch(saveCustomEmail(ownProps.match.params.appname, emailType)),
  setTempData: emailType =>
    dispatch(setTempData(ownProps.match.params.appname, emailType)),
  displayErrorMessage: msg => dispatch(openSnackbar(msg)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomEmails),
);
