import React from 'react';

const TripAdvisor = () => (
	<svg 
		enable-background="new 0 0 512 512"
		id="Layer_1" 
		version="1.1" 
		viewBox="0 0 512 512" 
		space="preserve" 
		xmlns="http://www.w3.org/2000/svg" 
		xlink="http://www.w3.org/1999/xlink"
	>
		<circle cx="256" cy="256" fill="#34e0a1" id="ellipse" r="256"/>
		<g id="logo">
			<path d="M256.9,347.2c-1.1-1.1-1.8-2.2-2.5-3.3c-0.8-1-1.5-2-2.2-3c-0.8-1.1-1.5-2.2-2.3-3.3c-0.7-1-1.5-2-2.2-3.1   c-0.8-1.1-1.5-2.2-2.3-3.3c-0.7-1-1.5-2-2.2-3c-0.8-1.1-1.5-2.2-2.3-3.3c-0.7-1-1.3-2.1-2.4-3c-17.1,19-38.3,29-63.9,28.4   c-19.3-0.4-36.3-7.2-50.8-19.9c-30.8-26.6-38-73.8-11.5-109.4c-3.3-10.1-7.2-19.9-14.3-28.1c0.4-0.7,1-0.5,1.4-0.5   c3.1,0,6.2,0,9.3,0c13.7,0,27.3,0,41,0c0.9,0,1.8,0,2.6,0c0.3-0.3,0.5-0.4,0.6-0.6c2.7-3.9,6.1-7,10-9.7c6-4.3,12.6-7.4,19.5-10   c8-3,16.2-5,24.6-6.5c12.4-2.2,24.9-3.3,37.5-3.7c9-0.3,18-0.2,27,0.3c13.6,0.7,27,2.2,40.3,5.4c7.9,1.9,15.7,4.4,23.1,7.9   c4.8,2.2,9.3,4.9,13.4,8.1c2.6,2.1,5.1,4.3,7.1,7.1c0.6,0.8,1.3,1.3,2.2,1.4c1.2,0.1,2.3,0.3,3.5,0.3c15.8,0,31.5,0,47.2,0   c0.9,0,1.8,0,2.9,0c-0.3,1.3-1.3,1.9-1.9,2.8c-0.6,0.9-1.3,1.7-1.9,2.6s-1.2,1.8-1.7,2.7c-0.6,0.9-1.1,1.9-1.6,2.8   c-0.6,1-1.1,2.1-1.6,3.1s-0.9,2-1.3,3s-0.9,2-1.3,3s-0.8,2-1.1,3c-0.4,1-0.7,2-1.1,3c14.4,18.3,20.5,38.9,17.5,61.9   c-2.7,20.4-12.1,37.5-27.8,50.8c-17.1,14.5-37.1,20.9-59.5,18.9c-22.3-2-40.6-12.1-55.2-29.1c-1.6,2.2-2.8,4.5-4.1,6.6   c-1.3,2.1-2.6,4.3-3.8,6.5c-1.3,2.2-2.6,4.5-4,6.7C259.5,342.8,258.4,345,256.9,347.2z M241.8,269.6c-0.1-35.9-29-64.9-64.8-65   c-37-0.1-65,30.1-65.1,64.6c-0.1,35.8,29,65.1,64.8,65.2C211.3,334.7,241.6,306.7,241.8,269.6z M400.1,269.8   c0.3-34.8-27.9-65-64.7-65.1c-37.2-0.1-65,30.4-65.2,64.7c-0.1,35.4,28.7,65,64.6,65.2C370.6,334.7,400.1,305.6,400.1,269.8z    M178.7,193.7c0.2,0,0.4,0.1,0.6,0.1c8.5-0.9,16.8,0.1,24.9,2.5c14.7,4.2,26.5,12.6,35.7,24.7c6.5,8.5,11,18,14.5,28.1   c0.8,2.3,1.5,4.6,2.2,6.9c0.8-0.2,0.7-0.9,0.9-1.4c1.5-5.1,3.3-10.1,5.4-15c4.4-10,10.1-19.2,18-26.8c14.9-14.2,32.7-20.4,53.1-19   c0.3,0,0.6,0,1,0c-0.3-1-1.1-1.5-1.7-2c-3.1-2.6-6.5-4.9-10.1-6.8c-6.6-3.6-13.5-6.4-20.8-8.5c-10.2-2.9-20.6-4.6-31.2-5.2   c-11.8-0.7-23.6-0.6-35.4,0.5c-7.7,0.8-15.3,2.1-22.8,4.1c-8.6,2.3-16.8,5.5-24.4,10c-3.2,1.9-6.2,3.9-9,6.3   C179.3,192.7,178.7,193,178.7,193.7z" fill="#000000"/>
			<path d="M215.6,269.7c0.1,20.8-17,38.9-39.1,38.7c-21.3-0.1-38.6-17.4-38.5-39.1c0.2-22.2,18.5-38.7,39-38.6   C198,230.9,215.8,248.2,215.6,269.7z M176.9,296.5c13.5,0.1,26.8-10.7,26.8-26.9c0-15.2-12.1-27-27-26.9   c-16,0.1-26.8,13.3-26.8,26.9C149.9,284.4,162,296.7,176.9,296.5z" fill="#000000"/>
			<path d="M335.1,230.8c21.3-0.2,38.9,17.4,39,38.6c0.1,20.5-16.4,38.8-38.6,39c-22.1,0.2-39.1-17.9-39.1-38.8   C296.3,248.1,314,230.7,335.1,230.8z M362.1,269.6c0.2-13.7-10.8-26.9-26.7-26.9c-16.1-0.1-27.1,13.2-27.1,26.9   c0,14.6,11.9,26.9,26.8,26.9C349.8,296.6,362.2,284.5,362.1,269.6z" fill="#000000"/>
			<path d="M176.9,256.5c7.3,0.1,13,6,13,13.3c0,5.9-4.6,12.9-13.1,13c-7.5,0.1-13.2-5.9-13.2-13.2   C163.7,262.3,169.6,256.4,176.9,256.5z" fill="#000000"/>
			<path d="M348.4,269.5c0,6.8-5.2,13.2-13.1,13.2c-7.7,0-13.3-6.1-13.2-13.1c0.1-8.3,6.8-13.1,13-13.1   C342.3,256.5,348.3,262.2,348.4,269.5z" fill="#000000"/>
		</g>
	</svg>
);

export default TripAdvisor;
