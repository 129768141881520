import i18n from 'i18next';
import firebase from '../../../../../../../firebase';
import { openSnackbar } from '../../../../../../../app/modules/snackbar/actions';
import {
  FIELD_VALIDATION_ERROR,
  REQUEST_MARKETPLACE_FILTER_DETAILS,
  REQUEST_MARKETPLACE_FILTER_FIELD_UPDATE,
  RECEIVE_MARKETPLACE_FILTER_FIELD_UPDATE,
  RECEIVE_MARKETPLACE_FILTER_DETAILS,
  MARKETPLACE_FILTER_FIELD_DELETED,
} from './types';
const request = () => ({
  type: REQUEST_MARKETPLACE_FILTER_DETAILS,
});

const requestUpdateField = () => ({
  type: REQUEST_MARKETPLACE_FILTER_FIELD_UPDATE,
});

export const updateFilter = (app, categoryID, item, value) => dispatch => {
  dispatch(requestUpdateField());
  const update = {
    [item]: value,
    updatedAt: Date.now(),
  };
  firebase
    .database()
    .ref(`marketplace_filters/${app}/${categoryID}`)
    .update(update)
    .then(() => {
      dispatch({
        type: RECEIVE_MARKETPLACE_FILTER_FIELD_UPDATE,
        data: update,
      });
    });
};

export const updateField = (
  app,
  categoryID,
  name,
  fieldID,
  value,
) => dispatch => {
  dispatch(requestUpdateField());
  const update = {
    [name]: value,
    updatedAt: Date.now(),
  };
  firebase
    .database()
    .ref(`marketplace_filters/${app}/${categoryID}/fields/${fieldID}`)
    .update(update)
    .then(() => {
      dispatch({
        type: RECEIVE_MARKETPLACE_FILTER_FIELD_UPDATE,
        data: update,
      });
    });
};

export const getFilterDetails = (app, categoryID) => dispatch => {
  dispatch(request());

  firebase
    .database()
    .ref(`marketplace_filters/${app}/${categoryID}`)
    .on('value', snap => {
      dispatch({
        type: RECEIVE_MARKETPLACE_FILTER_DETAILS,
        value: snap.exists() ? snap.val() : [],
      });
    });
};

export const deleteField = (app, categoryID, fieldID) => dispatch => {
  firebase
    .database()
    .ref(`marketplace_filters/${app}/${categoryID}/fields/${fieldID}`)
    .remove()
    .then(() => {
      dispatch({
        type: MARKETPLACE_FILTER_FIELD_DELETED,
        fieldID,
      });
      dispatch(openSnackbar(i18n.t('Field Deleted')));
    });
};
