import React, { useEffect, useState } from 'react';
import SimpleToolbar from '../../../../../../app/modules/toolbar/simpleToolbar';
import { Provider } from 'react-redux';
import './styles.css';
import { store } from '../../../../../../index';
import { getAppUrl } from '../../../../../../utils/appDetails';
import RoundedThin from '../../../../../../app/components/buttons/roundedThin';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogConfirmation from '../../../../../../app/components/dialogConfirmation';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import Search from '../../../../../../app/components/search/';

const AppMenu = ({
  getAppsDetails,
  apps,
  history,
  appsData,
  loading,
  onAppChange,
  onDeleteApp,
  lastVisitedApp,
  getSubscriptions,
  userIsLegacy,
  appAdmins,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    getAppsDetails(apps);
    getSubscriptions();
  }, [apps]);

  const [openDialog, setOpenDialog] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [selectedApp, setSelectedApp] = useState('');
  const appLimit = typeof userIsLegacy === 'number' ? userIsLegacy : 50;
  const appQuantity = apps && Object.keys(apps).length;

  const handleAppClick = appname => {
    if (appname) {
      history.push(`/${appname}/editor`);
      onAppChange(appname, lastVisitedApp);
    }
  };

  const handleDuplicateApp = appname => {
    history.push({ pathname: `/duplicate_app`, state: { appToCopy: appname } });
  };

  const goToLastVisitedApp = () => {
    history.push(`/${lastVisitedApp}/editor`);
  };
  const deleteApp = async () => {
    await onDeleteApp(appAdmins, selectedApp);
    if (lastVisitedApp === selectedApp) {
      const newAppsList = Object.keys(apps)
        .filter(appname => appname !== selectedApp)
        .reduce((obj, appname) => {
          obj.push(appname);
          return obj;
        }, []);
      onAppChange(newAppsList[0], lastVisitedApp);
    }
  };
  const handleDeleteApp = appname => {
    setDialogMessage(
      `${t('Are you sure you want to delete')} ${appname} ${t('app?')}`,
    );
    setSelectedApp(appname);
    setOpenDialog(true);
  };

  return (
    <div className="overlay">
      <Provider store={store}>
        <SimpleToolbar onExit={goToLastVisitedApp} />
      </Provider>
      <div className="menu-container">
        <div className="section-title">
          <Trans>Apps</Trans>
        </div>
        <div className="apps-search-bar">
          <Search
            name="search"
            placeholder={t('Search for app')}
            value={searchText}
            onChange={val => setSearchText(val)}
            onClear={() => setSearchText('')}
          />
        </div>

        {loading ? (
          <div className="loading-indicator">
            <CircularProgress size={75} color="primary" />
            <Trans>Getting your apps</Trans>
          </div>
        ) : (
          <div className="apps-container">
            {appsData
              .sort((app1, app2) => {
                //display last visited app at top
                if (app1.name === lastVisitedApp) return -1;
                if (app2.name === lastVisitedApp) return 1;

                return 0;
              })
              .filter(appData => appData.name.includes(searchText))
              .map(appData => (
                <div
                  className={`app-container ${lastVisitedApp === appData.name &&
                    'current-app'}`}
                >
                  <div className="icon-container">
                    <img
                      src={appData.logo || '/beezer_icon192.png'}
                      alt="app-icon"
                    />
                  </div>
                  <div className="app-details scroll-container">
                    <div className="name field">
                      <span className="title">
                        <Trans>Name</Trans>:{' '}
                      </span>
                      <span className="value">{appData.name}</span>
                    </div>
                    <div className="description field">
                      <span className="title">
                        <Trans>Description</Trans>:{' '}
                      </span>
                      <span className="value">{appData.description}</span>
                    </div>
                    <div className="version field">
                      <span className="title">
                        <Trans>Version</Trans>:{' '}
                      </span>
                      <span className="value">
                        {appData.version || t('Not Published')}
                      </span>
                    </div>
                    {appData.last_published && (
                      <div className="version field">
                        <span className="title">
                          <Trans>Last published:</Trans>{' '}
                        </span>
                        <span className="value">
                          {moment(appData.last_published).format('DD MMM YYYY')}
                        </span>
                      </div>
                    )}
                    <div className="url">
                      <a
                        href={getAppUrl(appData.name, appData.customDomain)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getAppUrl(appData.name, appData.customDomain)}
                      </a>
                    </div>

                    <div className="app-actions">
                      {userIsLegacy && appQuantity < appLimit && (
                        <Button
                          className="duplicate-btn"
                          variant="text"
                          title={t('Duplicate App')}
                          onClick={() => handleDuplicateApp(appData.name)}
                        >
                          <Trans>Duplicate App</Trans>
                        </Button>
                      )}
                      {Object.keys(apps).length > 1 && (
                        <Button
                          className="delete-btn"
                          variant="text"
                          title={t('Delete App')}
                          onClick={() => handleDeleteApp(appData.name)}
                        >
                          <Trans>Delete App</Trans>
                        </Button>
                      )}
                    </div>
                  </div>
                  {appData.name !== lastVisitedApp && (
                    <div className="action-container">
                      <RoundedThin
                        className="view-app-btn"
                        onClick={() => handleAppClick(appData.name)}
                      >
                        <Trans>Go to App</Trans>
                      </RoundedThin>
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
      <DialogConfirmation
        title="Delete App"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        action={deleteApp}
        message={dialogMessage}
      />
    </div>
  );
};

export default AppMenu;
