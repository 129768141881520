import { connect } from 'react-redux';

import Legal from './Legal';
import { closeLegalModal } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  open: state.account.legal.open,
  selectedModal: state.account.legal.selectedModal,
});

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeLegalModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Legal);
