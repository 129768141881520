import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import Container from '../../../../../../../app/components/container';
import SectionHeader from '../../../../../components/sectionHeader';
import './styles.css';
import EditableTimer from '../../../../../../../app/components/editableTImer';
import { Tabs, Tab } from '../../../../../components/tabs';
import LayoutTab from '../../tabs/layoutTab';
import LayoutOption from '../../tabs/layoutTab/layoutOption';
import timerMedium from './timerMedium';
import timerLarge from './timerLarge';
import timerFullScreen from './timerFullScreen';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/styles';
import themeStyles from '../../../../../../../_export.scss';

const StyledLabel = withStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    borderBottom: '4px solid #e8e8e8',
    columnGap: '4rem',
    margin: '0px',
    marginTop: '18px',
  },
  label: {
    color: themeStyles.primaryColor,
    fontWeight: 'bolder',
  },
})(FormControlLabel);
class Timer extends Component {
  static propTypes = {
    onChangeCycles: PropTypes.func.isRequired,
    onChangeSets: PropTypes.func.isRequired,
    onChangeWarmup: PropTypes.func.isRequired,
    onChangeExercise: PropTypes.func.isRequired,
    onChangeRest: PropTypes.func.isRequired,
    onChangeTitle: PropTypes.func.isRequired,
    data: PropTypes.shape({
      cycle: PropTypes.string,
      sets: PropTypes.string,
      warmup: PropTypes.string,
      exercise: PropTypes.string,
      rest: PropTypes.string,
      recovery: PropTypes.string,
      title: PropTypes.string,
      titleState: PropTypes.string,
    }).isRequired,
  };

  handleOnchangeTitle = e => {
    const value = e.target.value;
    if (value.length <= 8) {
      this.props.onChangeTitle(value);
    }
  };

  timerLayoutOptions = () => {
    const { t } = this.props;
    const style = {
      width: '100%',
      heigth: '100%',
    };
    return {
      options: [
        <LayoutOption
          key="timer_layout"
          wrapperKey="timer_layout"
          grid={2}
          tooltip={t('Change your timer component template')}
          title={t('Timer templates')}
          active={this.props.component.layout}
          items={[
            { value: 'Medium', icon: timerMedium, style },
            { value: 'Large', icon: timerLarge, style },
            { value: 'FullScreen', icon: timerFullScreen, style },
          ]}
        />,
      ],
    };
  };

  render() {
    const {
      component,
      match: {
        params: { appname },
      },
      t,
    } = this.props;
    return (
      <Tabs>
        <Tab label={t('content')}>
          <Container>
            <SectionHeader
              title={t('Workout Timer title')}
              tooltip={t('Workout Timer title of your timer')}
            />
            <input
              value={this.props.data.title}
              onChange={this.handleOnchangeTitle}
              type="text"
            />

            <SectionHeader
              title={t('Warmup interval')}
              tooltip={t('Warmup interval of your timer')}
            />
            <EditableTimer
              value={this.props.data.warmup}
              onChange={this.props.onChangeWarmup}
            />
            <SectionHeader
              title={t('Exercise interval')}
              tooltip={t('Exercise interval of your timer')}
            />
            <EditableTimer
              value={this.props.data.exercise}
              onChange={this.props.onChangeExercise}
            />
            <SectionHeader
              title={t('Rest interval')}
              tooltip={t('Rest interval of your timer')}
            />

            <EditableTimer
              value={this.props.data.rest}
              onChange={this.props.onChangeRest}
            />
            <SectionHeader
              title={t('Number of sets')}
              tooltip={t('Number of sets of your timer')}
            />
            <input
              min="1"
              value={this.props.data.sets}
              onChange={this.props.onChangeSets}
              type="number"
            />

            <SectionHeader
              title={t('Number of Cycles')}
              tooltip={t('Number of Cycles of your timer')}
            />
            <input
              min="1"
              value={this.props.data.cycle}
              onChange={this.props.onChangeCycles}
              type="number"
            />
            <div>
              <StyledLabel
                classes={{ label: 'timer-settings-label' }}
                color="primary"
                control={
                  <Switch
                    checked={
                      this.props.data.allow_settings === undefined
                        ? true
                        : this.props.data.allow_settings
                    }
                    onChange={this.props.onChangeSettingsStatus}
                    name="checkedA"
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                }
                label={t('Allow users to modify settings')}
                labelPlacement="start"
              />
            </div>
          </Container>
        </Tab>

        <Tab label={t('layout')}>
          <LayoutTab
            componentKey={component.key}
            appname={appname}
            {...this.timerLayoutOptions()}
          />
        </Tab>
      </Tabs>
    );
  }
}

export default withTranslation()(Timer);
