import {
  SELECT_IMAGE,
  OPEN_PRODUCT_APPROVAL_DIALOG,
  CLOSE_PRODUCT_APPROVAL_DIALOG,
  PRODUCT_UPDATE_ERROR,
} from './types';
const initialState = {
  selectedImage: null,
  productApprovalDialogOpen: false,
};

const product = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_IMAGE: {
      return {
        ...state,
        selectedImage: {
          src: action.value.imageSrc,
          index: action.value.index,
        },
      };
    }

    case OPEN_PRODUCT_APPROVAL_DIALOG: {
      return {
        ...state,
        productApprovalDialogOpen: true,
      };
    }

    case CLOSE_PRODUCT_APPROVAL_DIALOG: {
      return {
        ...state,
        productApprovalDialogOpen: false,
      };
    }

    case PRODUCT_UPDATE_ERROR: {
      return {
        ...state,
        error: action.value,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default product;
