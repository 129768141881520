import { newValidator } from '../../../../../../../utils';

const validateTitle = newValidator({ required: true, maxLength: 21 });

const initialState = {
  pageKey: '',
  open: false,
  pending: false,
  error: null,
  title: {
    value: '',
    isValid: null,
    maxLength: 21,
    required: true,
  },
};

const duplicatePage = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DUPLICATE_PAGE_KEY':
      return {
        ...state,
        pageKey: action.payload,
      };
    case 'OPEN_DUPLICATE_PAGE_DIALOG':
      return {
        ...state,
        open: true,
      };

    case 'CLOSE_DUPLICATE_PAGE_DIALOG':
      return {
        ...state,
        open: false,
      };

    case 'CREATING_DUPLICATE_PAGE':
      return {
        ...state,
        pending: true,
        error: null,
      };

    case 'DUPLICATE_PAGE_CREATED':
      return {
        ...state,
        pending: false,
        error: null,
      };

    case 'DUPLICATE_PAGE_ERROR':
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case 'DUPLICATE_PAGE_TITLE_CHANGE': {
      return {
        ...state,
        title: {
          ...state.title,
          value: action.value,
          isValid: validateTitle(action.value),
        },
      };
    }

    case 'RESET_DUPLICATE_PAGE_FORM':
      return initialState;

    default:
      return state;
  }
};

export default duplicatePage;
