import firebase from '../../../firebase';

export const receiveCustomDomain = customDomain => ({
  type: 'RECEIVE_CUSTOM_DOMAIN',
  data: customDomain,
});

export const loadCustomDomain = appname => dispatch => {
  firebase
    .database()
    .ref(`apps/${appname}/custom_domain`)
    .once('value')
    .then(snapshot => {
      const customDomain = snapshot.val();
      return dispatch(receiveCustomDomain(customDomain));
    });
};
