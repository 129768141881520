import { connect } from 'react-redux';

import ImageUpload from './ImageUpload';

import {
  initializeCropper,
  receiveCrop,
  openDialog,
  closeDialog,
  deleteImage,
  deleteComponentData,
  submitImageCrop,
  receiveImage,
  resetImageCrop,
  prevStep,
  nextStep,
  setStep,
  warnUser,
  clearWarning,
  reportError,
  clearError,
  openFileAndUrlDialog,
  fileAndUrlDialogCancel,
  fileUploadUrlChange,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.editor.internals.imageUpload[ownProps.targetId],
  appName: state.app.appContext.appname,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  initialize: crop => dispatch(initializeCropper(ownProps.targetId, crop)),
  onOpenDialog: () => dispatch(openDialog(ownProps.targetId)),
  onCloseDialog: () => dispatch(closeDialog(ownProps.targetId)),
  onDeleteImage: () =>
    dispatch(
      deleteImage(ownProps.targetId, ownProps.src, ownProps.firebaseUrl),
    ),
  onUnmontComponent: () => dispatch(deleteComponentData(ownProps.targetId)),
  // Crops and uploads image
  onSubmit: (url, fileType, appName, imageType, source) =>
    dispatch(
      submitImageCrop(
        ownProps.targetId,
        url,
        fileType,
        appName,
        imageType,
        source,
      ),
    ),
  onReceiveImage: image => dispatch(receiveImage(ownProps.targetId, image)),
  onReceiveCrop: crop => dispatch(receiveCrop(ownProps.targetId, crop)),
  onPrevStep: () => dispatch(prevStep(ownProps.targetId)),
  onNextStep: () => dispatch(nextStep(ownProps.targetId)),
  onSelectStep: index => dispatch(setStep(ownProps.targetId, index)),
  onLeave: () => dispatch(resetImageCrop(ownProps.targetId)),
  onWarn: message => dispatch(warnUser(ownProps.targetId, message)),
  clearWarning: () => dispatch(clearWarning(ownProps.targetId)),
  reportError: errorMessage =>
    dispatch(reportError(ownProps.targetId, errorMessage)),
  clearError: () => dispatch(clearError(ownProps.targetId)),
  onOpenFileAndUrlDialog: () =>
    dispatch(openFileAndUrlDialog(ownProps.targetId)),
  onFileAndUrlDialogCancel: () =>
    dispatch(fileAndUrlDialogCancel(ownProps.targetId)),
  onFileUploadUrlChange: value =>
    dispatch(fileUploadUrlChange(ownProps.targetId, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
