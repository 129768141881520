import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TabsMui from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/styles';
import { lightBlue, deepOrange, deepPurple } from '@material-ui/core/colors';
import { Trans, useTranslation } from 'react-i18next';

import { objectToArray } from '../../../../utils';
import { SportScotlandData, Equalities } from './modules';

const Tabs = withStyles({
  root: {
    borderBottom: '4px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(TabsMui);

const useStyles = makeStyles({
  panel: {
    width: '100%',
  },
});

const styles = {
  root: {
    marginTop: '2rem',
  },
  paper: {
    display: 'flex',
    padding: '24px',
    textAlign: 'center',
    marginRight: '19px',
  },
  content: {
    flex: '1 0 auto',
  },
  header: {
    color: '#fff',
    fontWeight: 'bold',
  },
  no: {
    color: '#fff',
    fontSize: '31px',
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
  },
  noPadding: {
    paddingLeft: 0,
    paddingTop: '5px',
  },
};

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box style={styles.noPadding} p={3}>
        {children}
      </Box>
    )}
  </Typography>
);
TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const Reports = ({
  getMemberBodies,
  memberBodies,
  workforceMembers,
  users,
}) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    getMemberBodies();
  }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();
  return (
    <div className="scroll-container white-bg">
      <div className="row" style={styles.root}>
        <Paper style={{ ...styles.paper, backgroundColor: lightBlue[400] }}>
          <div style={styles.details}>
            <Typography
              variant="overline"
              display="block"
              gutterBottom
              style={styles.header}
            >
              <Trans>No of Member Bodies</Trans>
            </Typography>
            <div>
              <Typography variant="h3" style={styles.no}>
                {objectToArray(memberBodies).length}
              </Typography>
            </div>
          </div>
        </Paper>
        <Paper style={{ ...styles.paper, backgroundColor: deepOrange[400] }}>
          <div style={styles.details}>
            <Typography
              variant="overline"
              display="block"
              gutterBottom
              style={styles.header}
            >
              <Trans>No of Workforce Members</Trans>
            </Typography>
            <div>
              <Typography variant="h3" style={styles.no}>
                {objectToArray(workforceMembers).length}
              </Typography>
            </div>
          </div>
        </Paper>
        <Paper style={{ ...styles.paper, backgroundColor: deepPurple[400] }}>
          <div style={styles.details}>
            <Typography
              variant="overline"
              display="block"
              gutterBottom
              style={styles.header}
            >
              <Trans>No of Individual Users</Trans>
            </Typography>
            <div>
              <Typography variant="h3" style={styles.no}>
                {
                  objectToArray(users).filter(i => !i.value.workforceMember)
                    .length
                }
              </Typography>
            </div>
          </div>
        </Paper>
      </div>
      <div className="row" style={styles.root}>
        <Paper elevation={0}>
          <Tabs
            value={value}
            indicatorColor="primary"
            variant="scrollable"
            onChange={handleChange}
            aria-label="Reports"
            scrollButtons="auto"
          >
            <Tab label={t('Sportscotland Required Data')} />
            <Tab label={t('Equalities Report')} />
            <Tab label={t('Board Reports')} />
            <Tab label={t('Member body Reports')} />
            <Tab label={t('Discipline Reports')} />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0} className={classes.panel}>
          <SportScotlandData />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.panel}>
          <Equalities />
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.panel}>
          <Trans>Comming Soon</Trans>
        </TabPanel>
        <TabPanel value={value} index={3} className={classes.panel}>
          <Trans>Comming Soon</Trans>
        </TabPanel>
        <TabPanel value={value} index={4} className={classes.panel}>
          <Trans>Comming Soon</Trans>
        </TabPanel>
      </div>
    </div>
  );
};
Reports.propTypes = {
  getMemberBodies: PropTypes.func.isRequired,
  memberBodies: PropTypes.object,
  workforceMembers: PropTypes.object,
  users: PropTypes.object,
};

Reports.defaultProps = {
  memberBodies: {},
  workforceMembers: {},
  users: {},
};

export default Reports;
