import firebase from '../../firebase';

export async function getHeaders() {

  const headers = new Headers();

  const token = await firebase.auth().currentUser.getIdToken();
  headers.append('Content-Type', 'application/json');
  headers.append('x-access-token', token);

  return headers;
}

export async function getAllFkAcademyGroups(appname) {
  const headers = await getHeaders();
  const response = await fetch(
    `${process.env.REACT_APP_FK_ACADEMY}/get-groups?appname=${appname}&isPreview=true`,
    {
      headers,
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
}

export async function getFkAcademyGroup(appname, id) {
  const headers = await getHeaders();
  const response = await fetch(
    `${process.env.REACT_APP_FK_ACADEMY}/get-group?appname=${appname}&groupId=${id}`,
    {
      headers,
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
}

export async function setFkAcademyGroup(data) {
  const headers = await getHeaders();
  const response = await fetch(
    `${process.env.REACT_APP_FK_ACADEMY}/set-group`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
}


export async function getFkAcademyUser(appname, id) {
  const headers = await getHeaders();

  const response = await fetch(
    `${process.env.REACT_APP_APP_USERS}/get-app-user-by-admin?appname=${appname}&userId=${id}`,
    {
      headers,
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
}

export async function getAllFkAcademyUsers(appname) {
  const headers = await getHeaders();
  const response = await fetch(
    `${process.env.REACT_APP_APP_USERS}/get-app-users-by-admin?appname=${appname}`,
    {
      headers,
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
}

export async function updateFkAcademyAppUser(appname, userId, data) {
  const headers = await getHeaders();
  const response = await fetch(
    `${process.env.REACT_APP_APP_USERS}/update-app-user-by-admin?appname=${appname}&userId=${userId}`,
    {
      method: 'PUT',
      headers,
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
}

export async function getFkAcademyCoaches(appname) {
  const headers = await getHeaders();
  const response = await fetch(
    `${process.env.REACT_APP_FK_ACADEMY}/get-coaches?appname=${appname}`,
    {
      headers,
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
}

export async function getFkAcademyEvents(appname, groupId) {
  const headers = await getHeaders();
  const response = await fetch(
    `${process.env.REACT_APP_FK_ACADEMY}/get-events?appname=${appname}&groupId=${groupId}`,
    {
      headers,
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
}

export async function getFkAcademyUserGroups(appname, userId) {
  const headers = await getHeaders();
  const response = await fetch(
    `${process.env.REACT_APP_FK_ACADEMY}/user-groups?appname=${appname}&userId=${userId}`,
    {
      headers,
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
}

export async function updateParentsGroupStatus(appname, data) {
  const headers = await getHeaders();
  const response = await fetch(
    `${process.env.REACT_APP_FK_ACADEMY}/parent-group-status?appname=${appname}`,
    {
      method: 'PUT',
      headers,
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
}

export async function updateChildsGroupStatus(appname, data) {
  const headers = await getHeaders();
  const response = await fetch(
    `${process.env.REACT_APP_FK_ACADEMY}/child-group-status?appname=${appname}`,
    {
      method: 'PUT',
      headers,
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    throw new Error(response.json().message);
  }

  return response.json();
}
