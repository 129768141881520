import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalDetails from '../../../components/modalDetails';
import {
  formatDate,
  downloadCSV,
  capitaliseString,
} from '../../../../../utils';
import { deSanitize } from '../../../../../utils/string';
import Chip from 'material-ui/Chip';
import { withTranslation, Trans } from 'react-i18next';
import './styles.css';

class FormDetails extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    data: PropTypes.object,
    responseKey: PropTypes.string,
    onRequestClose: PropTypes.func.isRequired,
    totalResponses: PropTypes.number.isRequired,
    currentResponse: PropTypes.number.isRequired,
    onPreviousResponse: PropTypes.func.isRequired,
    onNextResponse: PropTypes.func.isRequired,
    forms: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: null,
    responseKey: null,
    onReply: null,
    onFavorite: null,
    onDownload: null,
    onDelete: null,
    onForward: null,
  };
  state = {
    confirmDeleteDialogOpen: false,
  };

  downloadFile = value => {
    if (value) {
      const filename = value.substr(value.lastIndexOf('/') + 1);
      return (
        <Chip
          style={{ margin: 6 }}
          labelStyle={{ maxWidth: '300px', overflow: 'hidden' }}
          title={filename}
        >
          <a href={value}>{filename}</a>
        </Chip>
      );
    }
  };

  getFormFields = data => {
    const { t } = this.props;
    return data.order.map(key => (
      <div className="field" key={data.chunks[key].label}>
        <span className="label">{deSanitize(data.chunks[key].label)}</span>
        <span className="value">
          {typeof data.chunks[key].value !== 'boolean' ? (
            typeof data.chunks[key].value !== 'string' ? (
              data.chunks[key].value || 'n/a'
            ) : data.chunks[key].type !== 'file' ? (
              data.chunks[key].type !== 'location' ? (
                deSanitize(data.chunks[key].value)
              ) : (
                <a
                  href={`https://what3words.com/${data.chunks[key].value}`}
                  target="_blank"
                >
                  {deSanitize(data.chunks[key].value)}
                </a>
              )
            ) : (
              this.downloadFile(data.chunks[key].value)
            )
          ) : `${data.chunks[key].value}` === 'true' ? (
            t('Selected')
          ) : (
            t('Not Selected')
          )}
        </span>
      </div>
    ));
  };

  getCSV = data => {
    const { t } = this.props;
    const cols = [t('Date'), t('Title')];
    let values = `"${formatDate(data.date)}","${data.title}",`;
    data.order.forEach(key => {
      const label = data.chunks[key].label || data.chunks[key].question;
      cols.push(capitaliseString(label));
      values += `"${data.chunks[key].value || 'n/a'}",`;
    });
    let body = `"${cols.join('","')}"\n`;
    body += `${values}\n`;
    return body;
  };

  getModalContent = data => (
    <div className="form-modal-content">
      <div className="date">
        <span className="label">
          <Trans>Date Received</Trans>
        </span>
        <span className="value">{formatDate(data.date)}</span>
      </div>
      <div className="fields">{this.getFormFields(data)}</div>
    </div>
  );

  handleCloseDeleteDialog = () =>
    this.setState({ confirmDeleteDialogOpen: false });

  render() {
    const {
      data,
      responseKey,
      totalResponses,
      currentResponse,
      onPreviousResponse,
      onNextResponse,
      forms,
      onDelete,
      t,
    } = this.props;
    return data ? (
      <ModalDetails
        {...this.props}
        title={`${t('Form')} - ${data && data.title}`}
        onNextItem={() => onNextResponse(currentResponse, forms)}
        onPreviousItem={() => onPreviousResponse(currentResponse, forms)}
        totalItems={totalResponses}
        currentItem={currentResponse}
        navigationText={t('responses')}
        onDownloadCsv={() => downloadCSV(this.getCSV(data), t('form-response'))}
        deleteConfirmationMessage={t(
          'Are you sure you want to permanently delete this response?',
        )}
        onDelete={() => onDelete([responseKey])}
        content={this.getModalContent(data)}
      />
    ) : null;
  }
}

export default withTranslation()(FormDetails);
