import _keys from 'lodash/keys';

import {
  GET_INSTALLS,
  REQUEST_USERS,
  RECEIVE_USERS,
  REQUEST_GROUPS,
  RECEIVE_GROUPS,
  ENABLE_FILTER,
  FILTERED_LIST_CHANGE,
  DISABLE_FILTER,
  ENABLE_GROUPS_FILTER,
  FILTERED_GROUPS_LIST_CHANGE,
  DISABLE_GROUPS_FILTER,
  ENABLE_LOCATION_FILTER,
  FILTERED_LOCATION_LIST_CHANGE,
  DISABLE_LOCATION_FILTER,
  FILTERS_CHANGE,
  PLAYER_ID_CHANGE,
  SELECTED_FILTER_CHANGE,
  SUBMIT_PUSH,
  PUSH_SUCCESS,
  PUSH_FAILURE,
  PUSH_FORM_CHANGE,
  RECEIVE_PUSH_RECIPIENTS,
  RESET_ENGAGE_STATE,
  RESET_USER_INPUTS,
  PUSH_LINK_FORM_CHANGE,
  PUSH_DATE_CHANGE,
  PUSH_DATE_TOGGLE_CHANGE,
  UPDATE_PUSH_IMAGE,
  NO_USERS_EXIST,
  GET_COUNTRIES,
  GET_STATES,
  GET_CITIES,
} from './actions';

const initialState = {
  installs: undefined,

  pending: false,
  chunks: {},
  filteredChunks: {},
  error: null,
  filtered: false,
  filteredList: [],

  groupsPending: false,
  groupsChunks: {},
  groupsSelected: [],
  groupsItems: [],
  groupsFilteredChunks: {},
  groupsFilter: '',
  groupsError: null,
  groupsFiltered: false,
  groupsFilteredList: [],

  locationFiltered: false,
  locationFilteredList: [],

  filters: [],
  playerIds: [],
  selectedFilter: '',

  title: {
    value: '',
    isValid: null,
  },
  message: {
    value: '',
    isValid: null,
  },
  link: {
    type: 'web',
    page: '/',
    web: '',
    video: '',
    enabled: false,
    webValid: false,
    videoValid: false,
  },
  pushImage: '',
  recipients: null,
  pending: false,
  error: false,
  scheduledTime: new Date(),
  isScheduled: false,

  filteredCountries: [],
  filteredStates: [],
  filteredCities: [],
};

// TODO: Extend Validation
const validate = (prop, value) => {
  switch (prop) {
    case 'segment':
      return true;

    case 'title':
      return value.length !== 0 && value.length <= 60;

    case 'message':
      return value.length !== 0 && value.length <= 280;

    default:
      return false;
  }
};

const pushReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSTALLS:
      return {
        ...state,
        installs: action.payload,
      };
    case REQUEST_USERS:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case NO_USERS_EXIST:
      return {
        ...state,
        pending: false,
        error: null,
      };

    case FILTERED_LIST_CHANGE:
      return {
        ...state,
        filteredList: action.payload,
      };

    case FILTERED_GROUPS_LIST_CHANGE:
      return {
        ...state,
        groupsFilteredList: action.payload,
      };

    case FILTERED_LOCATION_LIST_CHANGE:
      return {
        ...state,
        locationFilteredList: action.payload,
      };

    case FILTERS_CHANGE:
      return {
        ...state,
        filters: action.payload,
      };

    case PLAYER_ID_CHANGE:
      return {
        ...state,
        playerIds: action.payload,
      };

    case SELECTED_FILTER_CHANGE:
      return {
        ...state,
        selectedFilter: action.payload,
      };

    case RECEIVE_USERS: {
      const filteredChunks = {};
      if (Object.keys(state.filteredChunks).length) {
        Object.keys(action.value).forEach(key => {
          filteredChunks[key] = action.value[key];
          filteredChunks[key].selected =
            state.filteredChunks[key] && state.filteredChunks[key].selected
              ? state.filteredChunks[key].selected
              : false;
        });
      }
      return {
        ...state,
        chunks: action.value,
        filteredChunks: Object.keys(filteredChunks).length
          ? filteredChunks
          : action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };
    }
    case ENABLE_FILTER:
      return {
        ...state,
        filtered: true,
      };

    case DISABLE_FILTER:
      return {
        ...state,
        filtered: false,
      };

    case ENABLE_GROUPS_FILTER:
      return {
        ...state,
        groupsFiltered: true,
      };

    case DISABLE_GROUPS_FILTER:
      return {
        ...state,
        groupsFiltered: false,
      };

    case ENABLE_LOCATION_FILTER:
      return {
        ...state,
        locationFiltered: true,
      };

    case DISABLE_LOCATION_FILTER:
      return {
        ...state,
        locationFiltered: false,
      };

    case REQUEST_GROUPS:
      return {
        ...state,
        groupsPending: true,
        groupsError: null,
      };

    case RECEIVE_GROUPS:
      return {
        ...state,
        groupsChunks: action.value,
        groupsSelected: [],
        groupsFilteredChunks: action.value,
        groupsItems: _keys(action.value),
        groupsPending: false,
        groupsError: null,
      };

    case SUBMIT_PUSH:
      return {
        ...state,
        pushImage: action.pushImage,
        title: {
          ...state.title,
          isValid: validate('title', state.title.value),
        },
        message: {
          ...state.message,
          isValid: validate('message', state.message.value),
        },
        pending: true,
        error: false,
      };

    case RESET_ENGAGE_STATE:
      return {
        ...initialState,
        recipients: state.recipients,
      };

    case PUSH_SUCCESS:
      return {
        ...initialState,
        filtered: state.filtered,
        filteredList: state.filteredList,
        filteredChunks: state.filteredChunks,
        chunks: state.chunks,
        items: state.items,
        groupsFiltered: state.groupsFiltered,
        groupsFilteredList: state.groupsFilteredList,
        groupsFilteredChunks: state.groupsFilteredChunks,
        groupsChunks: state.groupsChunks,
        groupsItems: state.groupsItems,
        recipients: state.recipients,
        locationFiltered: state.locationFiltered,
        locationFilteredList: state.locationFilteredList,
        filters: state.filters,
        playerIds: state.playerIds,
        selectedFilter: state.selectedFilter,
      };

    case PUSH_FAILURE:
      return {
        ...state,
        error: action.err.message,
        pending: false,
      };

    case PUSH_FORM_CHANGE:
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: validate(action.prop, action.value),
        },
      };

    case PUSH_LINK_FORM_CHANGE:
      return {
        ...state,
        link: {
          ...state.link,
          [action.prop]: action.payload.value,
          webValid: action.payload.webValid,
          videoValid: action.payload.videoValid,
        },
      };

    case RECEIVE_PUSH_RECIPIENTS:
      return {
        ...state,
        recipients: action.value,
      };

    case RESET_USER_INPUTS:
      return initialState;

    case PUSH_DATE_CHANGE:
      return {
        ...state,
        scheduledTime: action.scheduledTime,
      };
    case UPDATE_PUSH_IMAGE: {
      return {
        ...state,
        pushImage: action.pushImage,
      };
    }
    case PUSH_DATE_TOGGLE_CHANGE:
      return {
        ...state,
        isScheduled: action.isScheduled,
      };

    case GET_COUNTRIES:
      return {
        ...state,
        filteredCountries: action.payload,
      };

    case GET_STATES:
      return {
        ...state,
        filteredStates: action.payload,
      };

    case GET_CITIES:
      return {
        ...state,
        filteredCities: action.payload,
      };

    default:
      return state;
  }
};

export default pushReducer;
