import firebase from '../../../../firebase';
import { objectToArray } from '../../../../utils';

export const REQUEST_STAFF_MEMBER_BODIES = 'REQUEST_STAFF_MEMBER_BODIES';
export const RECEIVE_STAFF_MEMBER_BODIES = 'RECEIVE_STAFF_MEMBER_BODIES';
export const FILTER_STAFF_MEMBER_BODY_DATA = 'FILTER_STAFF_MEMBER_BODY_DATA';

export const requestMemberBodies = appname => ({
  type: REQUEST_STAFF_MEMBER_BODIES,
  appname,
});

export const getMemberBodies = (appname, memberBodyIDs) => dispatch => {
  dispatch(requestMemberBodies(appname));
  const memberBodyIDsArr = memberBodyIDs ? objectToArray(memberBodyIDs) : [];
  const memberBodies = {};
  if (memberBodyIDsArr.length) {
    Promise.all(
      memberBodyIDsArr.map(async data => {
        const { values } = await firebase
          .database()
          .ref(`member_bodies/${appname}/${data.id}`)
          .once('value')
          .then(snap => ({
            values: snap.val(),
          }));
        memberBodies[data.id] = values;
      }),
    ).then(() => {
      dispatch({
        type: RECEIVE_STAFF_MEMBER_BODIES,
        value: memberBodies,
      });
    });
  } else {
    dispatch({
      type: RECEIVE_STAFF_MEMBER_BODIES,
      value: {},
    });
  }
};

export const filterMemberBodyData = filter => dispatch =>
  dispatch({
    type: FILTER_STAFF_MEMBER_BODY_DATA,
    payload: filter,
  });
