import { combineReducers } from 'redux';

const initialState = {
  emailTypeList: [],
  emailType: null,
  pending: true,
  templateText: '',
  customEmail: {
    subject: '',
    fromEmail: '',
    topContent: '',
    bottomContent: '',
    mailTo: '',
    footerTeamName: '',
    headerImage: '',
  },
};

const customAdminEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default combineReducers({
  data: customAdminEmailReducer,
});
