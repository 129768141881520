import _mapValues from 'lodash/mapValues';
import i18n from 'i18next';
import firebase from '../../../firebase';
import { openSnackbar } from '../snackbar/actions';
import { getSubscriptions } from '../../../modules/account/actions';
import {
  reportPaymentSuccess,
  reportPaymentError,
  reportPaymentRequiresAuthentication,
} from '../../../modules/account/modules/payment/actions';
import {
  closeCreateComponentUI,
  openCreateComponentUI,
  getSpecialComponents,
} from '../../../modules/editor/modules/build/modules/create/createComponent/actions';
import { components, specialAddonPrices } from '../../../plans';

export const setProcessingSpinner = value => ({
  type: 'SET_PROCESSING_SPINNER',
  value,
});

export const addToDiyComponents = (
  componentType,
  component,
  appname,
  currency,
) => async dispatch => {
  const myHeaders = new Headers();
  dispatch(setProcessingSpinner(true));

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);

      let payload = {
        appname,
        componentType,
        ...component,
        currency,
      };

      // adjusting quantity to make it compatible with addon dynamic pricing
      if (payload[payload.currency])
        payload = { ...payload, price: payload[payload.currency].price };

      if (componentType === 'Diary') {
        payload = {
          ...payload,
          addonPlanName: 'diary',
        };
        return fetch(
          `${process.env.REACT_APP_PAYMENT_API}/add_customaddon_plan`,
          {
            method: 'post',
            headers: myHeaders,
            body: JSON.stringify(payload),
            mode: 'cors',
          },
        );
      }

      return fetch(`${process.env.REACT_APP_PAYMENT_API}/add_addon`, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify(payload),
        mode: 'cors',
      });
    })
    .then(async res => {
      if (res.status === 200) {
        let dataToUpload = {
          ...component,
        };
        if (componentType === 'Diary') {
          const result = await res.json();
          dataToUpload['subscriptionId'] = result.data || {};
        }

        if (componentType === 'MemberLogin') {
          firebase
            .database()
            .ref(`apps/${appname}/draft/always_data/auth`)
            .set({ type: 'auth' });

          const snap = await firebase
            .database()
            .ref(`app_groups/${appname}/groups`)
            .once('value');

          if (!snap.exists()) {
            firebase
              .database()
              .ref(`apps/${appname}/draft/lazy_data`)
              .once('value')
              .then(lazyData => {
                firebase
                  .database()
                  .ref(`app_groups/${appname}`)
                  .set({
                    groups: {
                      default: {
                        name: i18n.t('My Default Group'),
                        default: true,
                        date: Date.now(),
                        access: {
                          // provide seed key, so access node exists
                          // ...and because we aren't handling this correctly in other places at the moment
                          someKey: true,
                        },
                      },
                    },
                    not_private: _mapValues(lazyData.val(), () => true),
                  });
              });
          }
        }
        return firebase
          .database()
          .ref(`apps/${appname}/diyComponents/${componentType}`)
          .set({ ...dataToUpload, created: Date.now() })
          .then(() => {
            if (componentType === 'Diary') {
              window.location.href = `${window.location.origin}/${appname}/members/diary`;
            } else if (componentType === 'MemberLogin') {
              window.location.href = `${window.location.origin}/${appname}/members/groups`;
            }
          });
      } else if (res.status === 202) {
        const response = await res.json();
        if (response.errorCode === 'invoice_payment_intent_requires_action') {
          dispatch(
            reportPaymentRequiresAuthentication(
              response.paymentIntentClientSecret,
              'addon',
              componentType,
              response.subscriptionItemId,
            ),
          );
        }
        return response.errorCode;
      }
      return res.json();
    })
    .then(errorCode => {
      if (errorCode !== 'invoice_payment_intent_requires_action') {
        dispatch(getSubscriptions());
        dispatch(setProcessingSpinner(false));
      } else {
        return errorCode;
      }
    })
    .catch(error => {
      dispatch(setProcessingSpinner(false));
      dispatch(reportPaymentError());
      dispatch(
        openSnackbar(
          'An error occurred while processing your payment, Please contact customer support.',
        ),
      );
    });
};

export const updateAddOnSubscription = (
  componentType,
  component,
  appname,
  currency,
) => async (dispatch, getState) => {
  const myHeaders = new Headers();
  dispatch(setProcessingSpinner(true));
  const subscriptionItemId = getState().account.payment.subscriptionItemId;
  return await firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);

      let payload = {
        appname,
        componentType,
        ...component,
        currency,
        subscriptionItemId,
      };

      // adjusting quantity to make it compatible with addon dynamic pricing
      if (payload[payload.currency])
        payload = { ...payload, price: payload[payload.currency].price };

      return fetch(`${process.env.REACT_APP_PAYMENT_API}/update_addon_plan`, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify(payload),
        mode: 'cors',
      });
    })
    .then(async res => {
      dispatch(reportPaymentSuccess());

      if (res.status === 200) {
        firebase
          .database()
          .ref(`apps/${appname}/diyComponents/${componentType}`)
          .set({ ...component });
        return {
          type: 'ADD_TO_DIY_COMPONENTS',
          component,
        };
      }
      return res.json();
    })
    .then(() => {
      dispatch(getSubscriptions());
      dispatch(setProcessingSpinner(false));
    })
    .catch(error => {
      dispatch(setProcessingSpinner(false));
      dispatch(reportPaymentError());
      dispatch(
        openSnackbar(
          'An error occurred while processing your payment, Please contact customer support.',
        ),
      );
    });
};

export const loadDiyComponents = appname => (dispatch, getState) => {
  firebase
    .database()
    .ref(`apps/${appname}/diyComponents`)
    .on('value', snapshot =>
      dispatch({
        type: 'LOADING_DIY_COMPONENTS',
        value: snapshot.val(),
      }),
    );
};

export const addSpecialAddonComponents = (
  componentType,
  currency,
  closePopup,
  hasTrialExpired,
  history,
  appname,
  handleChange,
) => async (dispatch, getState) => {
  const account = getState().account.profile.account;
  dispatch({ type: 'SET_PROCESSING_SPINNER', value: true });
  if (hasTrialExpired) {
    // dispatch(openSnackbar(`Coming soon.`));
    dispatch({ type: 'SET_PROCESSING_SPINNER', value: false });
    history.push({
      pathname: `/${appname}/pay-for-addon`,
      query: {
        component: components[componentType],
        price_details: specialAddonPrices[componentType],
      },
    });
  } else {
    try {
      const todayDate = new Date();
      await firebase
        .database()
        .ref(`accounts/${account}/specialAddons`)
        .push({
          component: componentType,
          hasFreeTrial: true,
          createdAt: todayDate.getTime(),
          expiryDate: todayDate.setDate(todayDate.getDate() + 30),
        });

      dispatch(getSpecialComponents());
      handleChange(componentType);
      dispatch(
        openSnackbar(
          `${componentType} has been added to your account. Add the component to continue`,
        ),
      );
      closePopup();
      dispatch({ type: 'SET_PROCESSING_SPINNER', value: false });
    } catch (error) {
      dispatch({ type: 'SET_PROCESSING_SPINNER', value: false });
      dispatch(
        openSnackbar(
          'An error occurred while adding component, Please contact customer support.',
        ),
      );
    }
  }
};
