import React from 'react';

const Text = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40.69 43.3"
  >
    <title>notifaction</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M32.84,8.38a.71.71,0,0,0,.53-.22l4.34-4.34a.74.74,0,0,0,0-1.06.75.75,0,0,0-1.06,0L32.31,7.1a.75.75,0,0,0,0,1.06A.74.74,0,0,0,32.84,8.38Z"
        />
        <path
          class="cls-1"
          d="M39.94,12.22H34.41a.75.75,0,0,0,0,1.5h5.53a.75.75,0,0,0,0-1.5Z"
        />
        <path
          class="cls-1"
          d="M27.5,6.81a.76.76,0,0,0,.75-.75V.75a.75.75,0,0,0-1.5,0V6.06A.76.76,0,0,0,27.5,6.81Z"
        />
        <path
          class="cls-1"
          d="M31.87,29.44A3.16,3.16,0,0,1,30,26.56V19.64a9.85,9.85,0,0,0-9.16-9.82,3.79,3.79,0,0,0-7.43,0,9.85,9.85,0,0,0-9.16,9.82v6.92a3.3,3.3,0,0,1-.11.82,3.31,3.31,0,0,1-1.76,2.06,4.17,4.17,0,0,0,1.81,7.93H11.9v.68a5.25,5.25,0,0,0,10.5,0v-.68h7.66a4.17,4.17,0,0,0,1.81-7.93ZM17.12,8.26A2.28,2.28,0,0,1,19.27,9.8H15A2.28,2.28,0,0,1,17.12,8.26ZM5.59,27.77a5,5,0,0,0,.16-1.21V19.64A8.35,8.35,0,0,1,14,11.3h6.25a8.35,8.35,0,0,1,8.25,8.34v6.92a5,5,0,0,0,.16,1.21,4.66,4.66,0,0,0,1.21,2H4.38A4.66,4.66,0,0,0,5.59,27.77ZM20.9,38.05a3.75,3.75,0,0,1-7.5,0v-.68h7.5Zm9.16-2.18H22.4V35.8H11.9v.07H4.19A2.65,2.65,0,0,1,2.5,31.14v.16h29V31a2.66,2.66,0,0,1-1.44,4.91Z"
        />
      </g>
    </g>
  </svg>
);

export default Text;
