import Client from 'shopify-buy';
import i18n from 'i18next';
import { filter, isEmpty } from 'lodash';
import tinycolor from 'tinycolor2';

import firebase from '../../../../../../../firebase';
import parseShopifyCategories from './parseShopifyCategories';

export const INIT_STOREFRONT = 'SHOPIFY: INITIALIZED STOREFRONT';

const api = {};

function getClientByDomain(domain) {
  if (api[domain] === undefined) {
    throw new Error(i18n.t('Shopify domain has not been initialized'));
  }

  return api[domain];
}

export function initStorefront(domain, storefrontAccessToken) {
  let client;
  try {
    client = Client.buildClient({
      storefrontAccessToken,
      domain,
    });
  } catch (e) {
    console.error(e);
  }

  api[domain] = client;

  return {
    type: INIT_STOREFRONT,
    domain,
  };
}

export const handleDefaultColorChange = (appname, value, prop) => {
  const color = tinycolor(value, { format: 'rgba' });
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/design_data/palette`);

  dataRef.child(prop).set(value);

  return {
    type: 'COLOR_CHANGE',
    prop,
    value,
  };
};

export const designChange = (appname, dataId, field, value) => {
  const dataRef =
    field === 'layout'
      ? firebase.database().ref(`apps/${appname}/draft/always_data/${dataId}`)
      : firebase
          .database()
          .ref(`apps/${appname}/draft/always_data/${dataId}/design`);

  dataRef.child(field).set(value);

  return {
    type: 'BOX_FIELD_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

export const fieldChange = (appname, dataId, field, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${dataId}`);

  dataRef.child(field).set(value);

  return {
    type: 'FIELD_VALUE_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

/* ============ Fetch first all categories from Storefront API =========== */
export function fetchShopifyCategories(
  appname,
  dataId,
  domain,
  activeCategory = {},
  showProductsOfSpecificCategory,
) {
  return dispatch => {
    dispatch({
      type: 'FETCHING_SHOPIFY_CATEGORIES',
      domain,
    });

    const client = getClientByDomain(domain);
    // Fetch all collections, including their products
    return client.collection
      .fetchAllWithProducts({ first: 20, productsFirst: 1 })
      .then(collections => {
        // Filtering collections which don't have products
        const categories = filter(
          collections,
          collection => collection.products.length > 0,
        );
        dispatch(
          fieldChange(
            appname,
            dataId,
            'categories',
            parseShopifyCategories(categories),
          ),
        );

        if (categories.length > 0 && !showProductsOfSpecificCategory) {
          const status =
            showProductsOfSpecificCategory !== undefined
              ? showProductsOfSpecificCategory
              : true;
          dispatch(
            fieldChange(
              appname,
              dataId,
              'showProductsOfSpecificCategory',
              status,
            ),
          );
        }
        if (categories.length > 0 && isEmpty(activeCategory))
          dispatch(
            fieldChange(
              appname,
              dataId,
              'activeCategory',
              parseShopifyCategories(categories)[0],
            ),
          );

        return categories;
      })
      .catch(e => {
        console.error(e);
      });
  };
}
