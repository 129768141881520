const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  name: {
    value: '',
    isValid: null,
  },
  postalAddress: {
    value: '',
    isValid: null,
  },
  postCode: {
    value: '',
    isValid: null,
  },
  adminUsername: {
    value: '',
    isValid: null,
  },
  adminEmail: {
    value: '',
    isValid: null,
  },
  primaryContactName: {
    value: '',
    isValid: null,
  },
  primaryContactEmail: {
    value: '',
    isValid: null,
  },
  primaryContactNumber: {
    value: '',
    isValid: null,
  },
  secondaryContactName: {
    value: '',
    isValid: null,
  },
  secondaryContactEmail: {
    value: '',
    isValid: null,
  },
  secondaryContactNumber: {
    value: '',
    isValid: null,
  },
  landline: {
    value: '',
    isValid: null,
  },
  mobile: {
    value: '',
    isValid: null,
  },
  memberNumber: {
    value: '',
    isValid: null,
  },
  memberNumberMale: {
    value: '',
    isValid: null,
  },
  memberNumberFemale: {
    value: '',
    isValid: null,
  },
  memberNumberDisabled: {
    value: '',
    isValid: null,
  },
  memberNumberSmid: {
    value: '',
    isValid: null,
  },
  staffMembers: {},
};

const editMemberBodyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLOSE_EDIT_MEMBER_BODY_DIALOG':
      return {
        ...state,
        open: false,
      };

    case 'EDIT_MEMBER_BODY_FIELD_CHANGE':
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };
    case 'EDIT_MEMBER_BODY_STAFF_FIELD_CHANGE':
      return {
        ...state,
        staffMembers: {
          ...state.staffMembers,
          [action.staffID]: action.value,
        },
      };
    case 'LOADING_MEMBER_BODY':
      return {
        ...state,
        pending: true,
      };
    case 'LOADED_MEMBER_BODY_DETAILS':
      return {
        ...state,
        pending: false,
        ...action.prop,
      };

    case 'MEMBER_BODY_UPDATE_REQUEST':
      return {
        ...state,
        pending: true,
      };

    case 'MEMBER_BODY_UPDATED':
      return {
        ...initialState,
        key: action.key,
        data: action.update,
      };
    case 'MEMBER_BODY_RESET':
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default editMemberBodyReducer;
