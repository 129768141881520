const initialState = {
  pending: false,
  isDirty: false,
  open: false,
  error: null,
  isValid: true,
  data: null,
  name: {
    value: '',
    isValid: null,
  },
  order: {
    value: 0,
    isValid: null,
  },
  description: {
    value: '',
    isValid: null,
  },
  price: {
    value: '',
    isValid: false,
  },
  billingType: {
    value: 'recurring',
    isValid: null,
  },
  billingCycle: {
    value: 'day',
    isValid: null,
  },
  hasSubscriptionPlan: {
    value: false,
    isValid: true,
  },
  hasFreeTrial: {
    value: false,
    isValid: true,
  },
  freeTrialPeriod: {
    value: false,
    isValid: true,
  },
  groupImage: null,
  currency: {
    value: 'GBP',
    isValid: null,
  },
  features: [],
  enableTax: {
    value: false,
    isValid: true,
  },
};

const newGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_NEW_GROUP_DIALOG':
      return {
        ...state,
        groupImage: null,
        open: true,
      };

    case 'CLOSE_NEW_GROUP_DIALOG':
      return {
        ...initialState,
      };

    case 'NEW_GROUP_FIELD_CHANGE':
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };

    case 'ADD_NEW_GROUP':
      return {
        ...state,
        pending: true,
      };

    case 'UPDATE_GROUP_IMAGE':
      return {
        ...state,
        groupImage: action.groupImage,
      };
    case 'NEW_GROUP_ADDED':
      return initialState;

    case 'NEW_GROUP_FEATURE_ADDED':
      return {
        ...state,
        features: [...state.features, action.value],
      };
    case 'NEW_GROUP_REMOVE_FEATURE':
      const data = state.features;
      data.splice(action.value, 1);
      return {
        ...state,
        features: data,
      };
    case 'ERROR_CREATING_SUBSCRIPTION_PRODUCT':
      return {
        ...state,
        error: true,
        pending: false,
      };
    case 'RESET_STATE':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default newGroupReducer;
