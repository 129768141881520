import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Buyers from './Buyers';
import {
  getBuyers,
  getGroups,
  stopGetBuyers,
  filterBuyerData,
  selectBuyer,
  selectAllBuyers,
  deselectAllBuyers,
  openBuyerDialog,
  closeBuyerDialog,
  deleteSelected,
  emailSelected,
  sortTableData,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.ecommerce.marketplace.buyers,
  ...state.account.profile,
  isAppAdmin:
    (state.account.auth.profile.admin_appname &&
      state.account.auth.profile.admin_appname.length > 0) ||
    false,
  groups: state.auth.users.groups,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getBuyers: () => dispatch(getBuyers(ownProps.match.params.appname)),
  getGroups: () => dispatch(getGroups(ownProps.match.params.appname)),
  stopGetBuyers: () => dispatch(stopGetBuyers(ownProps.match.params.appname)),
  onFilterBuyerData: filter => dispatch(filterBuyerData(filter)),
  onSort: cols => dispatch(sortTableData(cols)),
  onSelect: key => dispatch(selectBuyer(key)),
  onSelectAllBuyers: () => dispatch(selectAllBuyers()),
  onDeselectAllBuyers: () => dispatch(deselectAllBuyers()),
  onOpenBuyerDialog: key => dispatch(openBuyerDialog(key)),
  onRequestClose: () => dispatch(closeBuyerDialog()),
  onDeleteSelected: (selected, chunks) =>
    dispatch(deleteSelected(ownProps.match.params.appname, selected, chunks)),
  onEmailSelected: (selected, chunks, emailFields) =>
    dispatch(
      emailSelected(
        ownProps.match.params.appname,
        selected,
        chunks,
        emailFields,
      ),
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Buyers));
