import React from 'react';

const Countdown = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250.000000 300.000000"
  >
    <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
      <path d="M350 2903 c-30 -11 -70 -57 -76 -88 -7 -41 17 -98 50 -120 25 -17 26
      -20 26 -124 0 -240 33 -423 97 -543 63 -116 138 -185 399 -365 219 -151 223
      -160 107 -250 -43 -33 -121 -88 -173 -123 -206 -137 -302 -238 -360 -376 -41
      -98 -60 -219 -67 -421 -6 -169 -7 -173 -30 -189 -33 -21 -56 -79 -49 -119 4
      -17 20 -45 37 -61 l30 -29 892 -3 c656 -2 899 1 920 9 45 19 67 55 67 112 0
      43 -4 54 -33 81 l-34 32 -6 159 c-19 448 -89 574 -453 822 -156 107 -234 171
      -234 193 0 22 78 86 234 193 161 109 287 220 335 296 73 114 107 263 118 526
      l6 159 34 32 c29 27 33 38 33 80 0 29 -7 59 -16 72 -38 54 -1 52 -956 51 -486
      0 -890 -3 -898 -6z m1798 -49 c51 -35 47 -100 -6 -131 l-29 -17 -6 -180 c-6
      -199 -30 -348 -71 -439 -51 -115 -152 -217 -331 -337 -153 -102 -253 -181
      -271 -216 -18 -33 -18 -35 0 -68 18 -35 122 -117 281 -224 162 -109 261 -206
      310 -307 54 -109 85 -305 85 -533 0 -108 1 -113 23 -121 57 -22 69 -88 23
      -128 l-27 -23 -882 0 c-933 1 -900 -1 -925 47 -19 35 -14 54 23 89 l35 32 10
      184 c25 459 76 548 460 808 66 45 142 102 170 126 43 40 50 51 50 84 0 33 -7
      44 -50 84 -28 24 -104 81 -170 126 -279 189 -365 283 -414 451 -26 88 -35 161
      -46 357 l-10 184 -35 32 c-37 35 -42 54 -23 89 25 48 -8 46 923 47 792 0 883
      -2 903 -16z"/>
      <path d="M425 2670 c-8 -13 12 -343 26 -414 37 -205 125 -316 383 -488 137
      -92 239 -173 259 -206 9 -17 17 -44 17 -62 0 -18 -8 -45 -17 -62 -20 -33 -122
      -114 -260 -206 -51 -34 -118 -83 -150 -108 -191 -151 -246 -300 -255 -684 l-3
      -115 31 -3 c27 -3 40 7 118 92 101 110 304 296 414 379 109 81 203 127 263
      127 107 0 284 -126 571 -408 241 -237 242 -237 246 -87 3 119 -12 283 -34 374
      -40 160 -135 273 -359 426 -242 165 -285 206 -285 275 0 69 45 112 280 271
      227 153 324 267 364 430 22 91 37 256 34 374 l-3 100 -817 3 c-539 1 -819 -1
      -823 -8z m1611 -77 c-3 -27 -8 -97 -11 -157 -17 -328 -82 -432 -400 -646 -215
      -144 -275 -207 -275 -287 0 -59 13 -86 63 -134 43 -39 86 -71 297 -219 232
      -162 299 -286 315 -586 3 -60 8 -131 11 -159 l5 -50 -178 175 c-193 190 -285
      269 -406 348 -184 120 -277 109 -504 -62 -105 -79 -270 -230 -407 -372 -99
      -103 -92 -113 -76 106 26 348 79 438 391 652 170 117 252 185 274 229 19 36
      19 102 0 138 -20 40 -104 113 -210 184 -316 212 -388 294 -429 487 -14 65 -36
      292 -36 373 l0 27 790 0 791 0 -5 -47z"/>
    </g>
  </svg>
);

export default Countdown;
