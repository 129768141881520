import firebase from '../../../../firebase';

export const REQUEST_SUBSCRIPTIONS = 'REQUEST_SUBSCRIPTIONS';
export const RECEIVE_USER_SUBSCRIPTIONS = 'RECEIVE_USER_SUBSCRIPTIONS';
export const REPORT_GROUPS_REQUEST_ERROR = 'REPORT_GROUPS_REQUEST_ERROR';
export const SELECT_GROUP = 'SELECT_GROUP';
export const FILTER_SUBSCRIPTION_DATA = 'FILTER_SUBSCRIPTION_DATA';
export const SELECT_ALL_GROUPS = 'SELECT_ALL_GROUPS';
export const DESELECT_ALL_GROUPS = 'DESELECT_ALL_GROUPS';

const requestSubscriptions = appname => ({
  type: REQUEST_SUBSCRIPTIONS,
  appname,
});

export const selectGroup = key => ({
  type: SELECT_GROUP,
  key,
});

export const loadSubscriptions = appname => dispatch => {
  dispatch(requestSubscriptions(appname));
  return firebase
    .database()
    .ref(`app_subscriptions/${appname}`)
    .once('value')
    .then(snap => {
      dispatch({
        type: RECEIVE_USER_SUBSCRIPTIONS,
        data: snap.val(),
      });
    });
};

export const filterData = filter => dispatch =>
  dispatch({
    type: FILTER_SUBSCRIPTION_DATA,
    payload: filter,
  });

export const selectAllGroups = () => ({ type: 'SELECT_ALL_GROUPS' });
export const deselectAllGroups = () => ({ type: 'DESELECT_ALL_GROUPS' });

export const searchGroups = query => dispatch =>
  dispatch({
    type: 'SEARCH_GROUPS',
    query,
  });

export const deleteGroup = (appname, key) => dispatch => {
  if (key !== 'default') {
    firebase
      .database()
      .ref(`app_groups/${appname}/groups/${key}`)
      .remove()
      .then(() =>
        dispatch({
          type: 'GROUP_DELETED',
          appname,
          key,
        }),
      );
  }
};

export const deleteGroups = appname => (dispatch, getState) => {
  const { selected } = getState().auth.groups.data;

  Promise.all(selected.map(key => dispatch(deleteGroup(appname, key))));
};
