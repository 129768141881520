import { connect } from 'react-redux';
import queryString from 'query-string';

import ItemInterface from './ItemInterface';
import { loadItemData, offItemData } from './actions';

const mapStateToProps = (state, ownProps) => {
  const { component, page } = queryString.parse(ownProps.location.search);
  const loaded = state.editor.data.loaded[ownProps.match.params.appname];
  const { item } = queryString.parse(ownProps.location.search);

  if (!loaded) {
    return {
      ...ownProps,
      loading: true,
    };
  }

  const viewData = state.editor.data.alwaysData[page];
  const componentData = state.editor.data.alwaysData[component];

  if (componentData === undefined) {
    return {
      ...ownProps,
      loading: true,
      view: viewData,
    };
  }

  const lazyData = state.editor.data.lazyData[componentData.lazy_data];
  if (lazyData === undefined || !lazyData.chunks) {
    return {
      ...ownProps,
      loading: true,
      view: viewData,
      component: componentData,
    };
  }

  return {
    ...ownProps,
    view: viewData,
    component: componentData,
    data: lazyData.chunks[item],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setListener: (lazyDataId, type, itemKey, itinerary, calendarType) =>
    dispatch(
      loadItemData(
        ownProps.match.params.appname,
        lazyDataId,
        type,
        itemKey,
        itinerary,
        calendarType,
      ),
    ),
  removeListener: (lazyDataId, type, itemKey, itinerary) =>
    dispatch(
      offItemData(
        ownProps.match.params.appname,
        lazyDataId,
        type,
        itemKey,
        itinerary,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemInterface);
