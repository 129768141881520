import { newValidator } from '../../../../../../../utils';
import firebase from '../../../../../../../firebase';

const checkingDomainAvailability = () => ({
  type: 'CHECKING_DOMAIN_AVAILABILITY',
});

const assertDomainAvailability = value => ({
  type: 'ASSERT_DOMAIN_AVAILABILITY',
  value,
});

// validate domain in the action so we know whether to check domain availability
const domainValidator = newValidator({
  required: true,
  maxLength: 60,
  type: 'domain',
});

export const resetData = () => ({
  type: 'RESET_DUPLICATE_APP_DATA',
});
export const domainChange = value => dispatch => {
  const isValid = domainValidator(value);
  dispatch({
    type: 'DUPLICATE_APP_DOMAIN_CHANGE',
    value,
    isValid,
  });
  if (isValid) {
    dispatch(checkingDomainAvailability());

    firebase
      .database()
      .ref('apps_directory')
      .child(value)
      .once('value')
      .then(snapshot => {
        const isAvailable = !snapshot.exists();
        if (isAvailable) {
          firebase
            .database()
            .ref('apps')
            .child(value)
            .once('value')
            .then(appsSnapshot => {
              dispatch(assertDomainAvailability(!appsSnapshot.exists()));
            });
        } else {
          dispatch(assertDomainAvailability(isAvailable));
        }
      });
  }
};

export const titleChange = value => ({
  type: 'DUPLICATE_APP_TITLE_CHANGE',
  value,
});

export const duplicateApp = ({
  appToCopy,
  newAppDomain,
  newAppTitle,
  callback,
}) => async (dispatch, getState) => {
  dispatch({ type: 'DUPLICATING_APP' });
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  const uid = user.uid;
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('x-access-token', token);
  await fetch(`${process.env.REACT_APP_EDIT_APP_API}/duplicate_app`, {
    method: 'post',
    mode: 'cors',
    body: JSON.stringify({
      appToCopy,
      newAppDomain,
      newAppTitle,
      uid,
      requestOrigin: 'platform',
    }),
    headers,
  });
  const newApp = {
    [newAppDomain]: {
      title: newAppTitle,
    },
  };

  dispatch({ type: 'APP_DUPLICATED' });

  return callback();
};
