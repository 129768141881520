import firebase from '../../../../../firebase';

import {
  submitPayment,
  submitCardDetails,
  reportPaymentSuccess,
  reportPaymentError,
} from '../../payment/actions';
import { receiveLogin, sendEmailVerification } from '../../auth/login/actions';
import { resetRegistrationForm } from '../actions';
import { removeFromDropOffList } from './validateUser/actions';

const requestOffers = () => ({
  type: 'REQUEST_OFFERS',
});

const receiveOffers = offers => ({
  type: 'RECEIVE_OFFERS',
  offers,
});

export const loadOffers = () => dispatch => {
  dispatch(requestOffers());
  firebase
    .database()
    .ref('offers')
    .once('value')
    .then(snapshot => dispatch(receiveOffers(snapshot.val())));
};

const createAccountWithOffer = (
  email,
  name,
  offer,
  stripeToken,
  card,
) => dispatch => {
  const user = firebase.auth().currentUser;

  dispatch({
    type: 'CREATE_ACCOUNT_WITH_OFFER',
    email,
    name,
    offer,
    stripeToken,
    card,
  });

  return user.getIdToken().then(token => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('x-access-token', token);

    return fetch(`${process.env.REACT_APP_CREATE_API}/offer`, {
      method: 'post',
      mode: 'cors',
      body: JSON.stringify({ email, name, offer, stripeToken, card }),
      headers,
    }).then(res => {
      if (res.status === 200) {
        return res;
      }

      throw new Error('Oops, something went wrong. Please try again.');
    });
  });
};

export const registerUserWithOffer = (offer, callback) => (
  dispatch,
  getState,
) => {
  const { name, email, password } = getState().account.register;

  dispatch(submitPayment());

  dispatch(submitCardDetails())
    .then(response =>
      firebase
        .auth()
        .createUserWithEmailAndPassword(email.value, password.value)
        .then(() =>
          dispatch(
            createAccountWithOffer(
              email.value,
              name.value,
              offer,
              response.id,
              response.card,
            ),
          ),
        )
        .then(() => {
          // callback to allow redirect in component
          callback(true);
          dispatch(reportPaymentSuccess());
          dispatch(resetRegistrationForm());
        })
        .then(() => dispatch(receiveLogin()))
        .then(() => dispatch(sendEmailVerification(email.value)))
        .then(() => dispatch(removeFromDropOffList())),
    )
    .catch(error => {
      dispatch(reportPaymentError(error.message));
    });
};
