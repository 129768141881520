import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RenderToLayer from 'material-ui/internal/RenderToLayer';
import { Link, withRouter, BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { store } from '../../../../';
import { SimpleToolbar } from '../../../../app/modules/toolbar';
import Container from '../../../../app/components/container';
import {
  plans,
  plansWixWeebly,
  pricing,
  PLANS_WITH_ADDONS,
  JAN_50_OFF,
} from '../../../../plans';
import { CTAButton } from '../../../../app/components/buttons';
import Payment from '../payment';

import { addOffersAnalytics } from './../../../analytics/actions';
import { getPriceAfterCoupon } from './actions';
import { referrerList } from '../register/referrerList';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
class Subscribe extends Component {
  static propTypes = {
    account: PropTypes.string.isRequired,
    addThankYouNotification: PropTypes.func.isRequired,
    billingPeriod: PropTypes.string,
    plan: PropTypes.string,
    integrationType: PropTypes.string,
    displayName: PropTypes.string,
    cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    subscription: PropTypes.object,
    payment: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    setPlan: PropTypes.func.isRequired,
    onSubscribe: PropTypes.func.isRequired,
    updateSubscription: PropTypes.func.isRequired,
    getProratingAdjustment: PropTypes.func.isRequired,
    prorating: PropTypes.object,
    location: PropTypes.object.isRequired,
    offers: PropTypes.object.isRequired,
    offerEligibility: PropTypes.object,
    history: PropTypes.object.isRequired,
    displayPaymentError: PropTypes.func,
  };

  static defaultProps = {
    billingPeriod: 'monthly',
    plan: 'deluxe',
    displayName: '',
    cost: 0,
    submitting: false,
    subscription: {},
    integrationType: '',
    accountId: '',
    prorating: {},
    offerEligibility: {},
  };

  state = {
    coupon: null,
  };

  componentDidMount() {
    const {
      account,
      location,
      offerEligibility,
      setPlan,
      getProratingAdjustment,
      country,
    } = this.props;
    let isOffer = false;

    if (location.query && location.query.isOffer) {
      isOffer = true;

      const offer = Object.keys(offerEligibility)[0];

      addOffersAnalytics('subscribeOpened', offer, account);

      ReactGA.event({
        category: 'User',
        action: `Subscribe page opened with offer ${offer}`,
      });
    }

    setPlan(isOffer, country.toLowerCase());

    const shouldGetProratingAdjustment =
      this.hasPlan() && !this.hasCancelledPlan();

    if (shouldGetProratingAdjustment) {
      getProratingAdjustment();
    }
  }

  shouldComponentUpdate(nextProps) {
    // if a plan is now defined but payment is still pending
    // do not allow component to update in order to prevent flash of
    // 'already subscribed' message before redirect

    if (nextProps.subscription.plan && nextProps.payment.pending) {
      return false;
    }

    return true;
  }

  setCouponCode = coupon => this.setState({ coupon });
  updateSubscription = async () => {
    const { plan, billingPeriod, currency, displayName, location } = this.props;
    const period = billingPeriod;
    const productId = location && location.query && location.query.product_id;
    const actualPlan = plan.split('-')[0];

    const price =
      period === 'yearly'
        ? plans[actualPlan].year_price * 12
        : plans[actualPlan].price;
    const callback = this.callback;
    const isChangingPlan = this.isChangingPlan();
    const planName = `${displayName} ${pricing[billingPeriod].title} Plan`;
    const coupon = this.state.coupon;
    //update and activate the subscription since the payment has now suceeded
    this.props.updateSubscription({
      plan,
      period,
      callback,
      isChangingPlan,
      price,
      coupon,
      planName,
      currency,
      productId,
    });
  };
  hasPlan = () =>
    this.props.subscription &&
    this.props.subscription.plan &&
    this.props.subscription.plan !== 'free';

  isChangingPlan = () =>
    this.hasPlan() && !this.hasSelectedSamePlan() && !this.hasCancelledPlan();

  hasSelectedSamePeriod = () => {
    const { billingPeriod, subscription } = this.props;
    const samePeriod =
      subscription.period === pricing[billingPeriod].title.toLowerCase();

    return samePeriod;
  };

  hasSelectedSamePlanName = () => {
    const { plan, subscription } = this.props;
    const samePlanName = subscription.plan === plan;

    return samePlanName;
  };

  hasSelectedSamePlan = () =>
    this.hasSelectedSamePlanName() && this.hasSelectedSamePeriod();

  isCurrentPlanDIY = () => {
    const { subscription } = this.props;
    return PLANS_WITH_ADDONS.includes(subscription.plan);
  };

  showProrating = () =>
    !this.hasSelectedSamePlanName() &&
    this.hasSelectedSamePeriod() &&
    !this.hasCancelledPlan() &&
    !this.isCurrentPlanDIY();

  renderThankYouPage = (plan, billingPeriod, coupon = '') => {
    let planOffer = '';
    if (coupon === JAN_50_OFF) {
      planOffer = `${plan} ${billingPeriod}`;
    } else if (coupon === 'AZRBkYZf') {
      planOffer = 'black_friday_offer';
    } else {
      planOffer = `${plan} ${billingPeriod}`;
    }
    const { history, match } = this.props;

    switch (planOffer) {
      case 'diy monthly':
        window.location.replace(
          `https://www.beezer.com/thank-you-page-anything-monthly`,
        );
        break;
      case 'diy yearly':
        window.location.replace(
          `https://www.beezer.com/thank-you-page-anything-yearly`,
        );
        break;
      case 'deluxe monthly':
        window.location.replace(
          `https://www.beezer.com/thank-you-page-everything-monthly`,
        );
        break;
      case 'deluxe yearly':
        window.location.replace(
          `https://www.beezer.com/thank-you-page-everything-yearly`,
        );
        break;
      case 'lifetime':
        window.location.replace(
          `https://www.beezer.com/thank-you-page-lifetime-offer`,
        );
        break;
      case 'something yearly':
        window.location.replace(
          'https://www.beezer.com/thank-you-page-something-yearly',
        );
        break;
      case 'something monthly':
        window.location.replace(
          'https://www.beezer.com/thank-you-page-something-monthly',
        );
        break;
      case 'everything-3-months-off':
        window.location.replace(
          `https://www.beezer.com/thank-you-page-99-offer-everything-monthly`,
        );
        break;
      case 'halloween_40_off_monthly':
        window.location.replace('https://www.beezer.com/thank-you-halloween/');
        break;
      case 'halloween_40_off_yearly':
        window.location.replace('https://www.beezer.com/thank-you-halloween/');
        break;
      case 'black_friday_offer':
        window.location.replace(
          'https://www.beezer.com/thank-you-black-friday/',
        );
        break;
      default:
        history.push(`/${match.params.appname}/editor`);
        break;
    }
  };

  getOfferCode = () => {
    const { offerEligibility } = this.props;

    const offerCode =
      (offerEligibility &&
        Object.keys(offerEligibility) &&
        Object.keys(offerEligibility)) ||
      '';

    return offerCode;
  };

  getRefferalOfferCode = () => {
    let offerCode = null;
    let referralOfferEligibility =
      referrerList[this.props.referrer] &&
      referrerList[this.props.referrer].offer.products.includes(this.props.plan)
        ? true
        : false;

    this.props.referralOfferEligibility &&
      Object.keys(referrerList[this.props.referrer].offer.coupons).forEach(
        offer => {
          if (
            !(
              this.props.referralOfferEligibility &&
              this.props.referralOfferEligibility[offer]
            ) //if offer eligibility is false then coupon has been redeemed
          ) {
            referralOfferEligibility = false;
          }
        },
      );

    if (
      referralOfferEligibility &&
      referrerList[this.props.referrer].offer &&
      referrerList[this.props.referrer].offer.products.includes(this.props.plan)
    ) {
      offerCode = referrerList[this.props.referrer].offer;
    }
    return offerCode;
  };

  getPromoCode = () => {
    let promoCode = null;
    if (this.props.payment.promoCode && this.props.payment.promoCode.isValid) {
      promoCode = this.props.payment.promoCode;
    }
    return promoCode;
  };

  callback = () => {
    const {
      account,
      addThankYouNotification,
      offers,
      plan,
      billingPeriod,
      location,
      accountId,
      callFreshmarketerApi,
      currency,
    } = this.props;
    callFreshmarketerApi(accountId);
    const offerCode = this.getOfferCode();
    const referralOfferCode = this.getRefferalOfferCode();
    const promoCode = this.getPromoCode();
    if (
      offerCode &&
      offers[offerCode[offerCode.indexOf(location.query.coupon)]]
    ) {
      const priceToPay = this.getPrice();
      const offerToUse =
        offers[offerCode[offerCode.indexOf(location.query.coupon)]];
      const { coupon, subscribe_notice } = offerToUse;
      const couponPrice = coupon === 'lifetime' ? priceToPay * 2 : priceToPay;
      const priceAfterCoupon = getPriceAfterCoupon(coupon, couponPrice);
      if (window.fbq) {
        // Facebook analytics
        window.fbq('track', 'Subscribe', {
          value: priceAfterCoupon,
          currency: currency || 'GBP',
        });
      }
      addOffersAnalytics('subscribed', coupon, account);
      addThankYouNotification(account, subscribe_notice);

      this.renderThankYouPage(plan, billingPeriod, coupon);
    } else if (!offerCode && referralOfferCode) {
      let coupon = '';
      Object.keys(referralOfferCode.coupons).forEach(couponCode => {
        if (
          referralOfferCode.coupons[couponCode].billingPeriod === billingPeriod
        ) {
          coupon = couponCode;
        }
      });
      const priceAfterCoupon = getPriceAfterCoupon(coupon, this.props.cost);

      if (window.fbq) {
        // Facebook analytics
        window.fbq('track', 'Subscribe', {
          value: priceAfterCoupon,
          currency: currency || 'GBP',
        });
      }
      addOffersAnalytics('subscribed', coupon, account);
      this.renderThankYouPage(plan, billingPeriod, coupon);
    } else if (
      !offerCode &&
      !referralOfferCode &&
      promoCode &&
      promoCode.isValid
    ) {
      let coupon = promoCode.coupon.id;

      const priceAfterCoupon = getPriceAfterCoupon(
        { discountPercent: promoCode.coupon.percent_off },
        this.props.cost,
      );

      if (window.fbq) {
        // Facebook analytics
        window.fbq('track', 'Subscribe', {
          value: priceAfterCoupon,
          currency: currency || 'GBP',
        });
      }
      addOffersAnalytics('subscribed', coupon, account);
      this.renderThankYouPage(plan, billingPeriod, coupon);
    } else {
      this.renderThankYouPage(plan, billingPeriod);
    }
  };

  handleSubscribe = () => {
    const {
      offers,
      onSubscribe,
      plan,
      billingPeriod,
      location,
      cost,
      displayName,
      currency,
      stripe,
      account,
    } = this.props;
    const isChangingPlan = this.isChangingPlan();
    const productId = location && location.query && location.query.product_id;

    const period = billingPeriod;
    const offerCode = this.getOfferCode();
    const planName = `${displayName} ${pricing[billingPeriod].title} Plan`;
    let priceToPay = `${cost * pricing[billingPeriod].multiplier}/${
      pricing[billingPeriod].singular
    }`;
    priceToPay = parseFloat(priceToPay).toFixed(2);

    if (
      ((location.query && location.query.isOffer) || offerCode) &&
      location.query &&
      location.query.coupon &&
      offers[offerCode[offerCode.indexOf(location.query.coupon)]]
    ) {
      const offerToUse =
        offers[offerCode[offerCode.indexOf(location.query.coupon)]];
      const { coupon } = offerToUse;
      this.setCouponCode(coupon);
      const priceToPay = this.getPrice();
      const couponPrice = coupon === 'lifetime' ? priceToPay * 2 : priceToPay;
      const priceAfterCoupon = getPriceAfterCoupon(coupon, couponPrice);

      return onSubscribe({
        plan,
        period,
        coupon,
        price: priceAfterCoupon,
        callback: this.callback,
        planName,
        currency,
        stripe,
        productId,
      });
    }

    let referralOfferCode = this.getRefferalOfferCode();
    if (referralOfferCode) {
      let coupon = '';
      Object.keys(referralOfferCode.coupons).forEach(couponCode => {
        if (
          referralOfferCode.coupons[couponCode].billingPeriod === billingPeriod
        ) {
          coupon = couponCode;
        }
      });
      const price = this.props.cost;
      this.setCouponCode(coupon);

      return onSubscribe({
        plan,
        period,
        coupon,
        price,
        callback: this.callback,
        planName,
        currency,
        stripe,
        productId,
      });
    }

    let promoCode = this.getPromoCode();
    if (promoCode) {
      let coupon = '';

      coupon = promoCode.coupon.id;
      const price = this.props.cost;
      this.setCouponCode(coupon);

      return onSubscribe({
        plan,
        period,
        coupon,
        price,
        isChangingPlan,
        callback: this.callback,
        planName,
        currency,
        stripe,
        productId,
      });
    }

    return onSubscribe({
      plan,
      period,
      callback: this.callback,
      isChangingPlan,
      price: priceToPay,
      planName,
      currency,
      stripe,
      productId,
    });
  };

  hasCancelledPlan = () => this.props.subscription.cancelled === true;

  canSubscribeToSelectedPlan = () =>
    !this.hasPlan() || this.hasCancelledPlan() || this.isChangingPlan();

  getPrice = () => {
    const { billingPeriod, cost, location } = this.props;

    let offerCode = this.getOfferCode();
    if (offerCode === '') {
      offerCode = this.getRefferalOfferCode();
    }
    const priceAfterCoupon = getPriceAfterCoupon(offerCode, cost);
    const price = `${priceAfterCoupon * pricing[billingPeriod].multiplier}/${
      pricing[billingPeriod].singular
    }`;
    const priceToPay =
      location.query && location.query.isOffer
        ? location.query.cost
        : parseFloat(price).toFixed(2);

    return priceToPay;
  };

  paymentAuthError = () => {
    this.props.history.push(`/${this.props.match.params.appname}/choose_plan`);
    this.props.displayPaymentError(
      'Your subscription payment was unsuccessful. Try again later',
    );
  };
  renderContent = () => {
    const {
      billingPeriod,
      displayName,
      subscription,
      integrationType,
      currency,
      location,
    } = this.props;

    const isWeebly = integrationType === 'Weebly';
    const planList = isWeebly ? plansWixWeebly : plans;
    const sectionName = isWeebly ? 'customise' : 'build';
    const productId = location && location.query && location.query.product_id;
    const priceToPay = this.getPrice();

    if (this.canSubscribeToSelectedPlan()) {
      return (
        <Payment
          title="Let's finish powering you up!"
          subtitle={`Subscription - ${displayName} ${pricing[billingPeriod].title} Plan`}
          cost={priceToPay}
          onSubmit={this.handleSubscribe}
          submitLabel="Subscribe"
          period={pricing[billingPeriod].singular}
          productId={productId}
          showProrating={this.showProrating()}
          prorating={this.props.prorating}
          isCurrentPlanDIY={this.isCurrentPlanDIY()}
          currency={currency}
          stripe={this.props.stripe}
          stripeAuthSuccessCallback={this.updateSubscription}
          stripeAuthErrorCallback={this.paymentAuthError}
          enablePromoBox={true}
        />
      );
    }

    return (
      <Container>
        <p style={{ textAlign: 'center' }}>
          <img src={planList[subscription.plan].icon} alt="" />
        </p>
        <h3>
          You are already subscribed to the {planList[subscription.plan].title}{' '}
          plan.
        </h3>
        <p style={{ textAlign: 'center' }}>
          <Link
            to={`/${this.props.match.params.appname}/editor/${sectionName}`}
          >
            <CTAButton label={`${sectionName} your app`} />
          </Link>
        </p>
      </Container>
    );
  };

  renderLayer = () => (
    <Provider store={store}>
      <Elements stripe={this.props.stripe}>
        <div className="overlay">
          <SimpleToolbar />
          {this.renderContent()}
        </div>
      </Elements>
    </Provider>
  );

  render() {
    return (
      <RenderToLayer
        render={this.renderLayer}
        useLayerForClickAway={false}
        open
      />
    );
  }
}

const InjectedSubscribe = props => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <Subscribe elements={elements} stripe={stripe} {...props} />
      )}
    </ElementsConsumer>
  );
};

export default withRouter(InjectedSubscribe);
