import { connect } from 'react-redux';

import Referral from './Referral';
import { fetchReferralLink, onCopy } from './actions';

const mapStateToProps = state => ({
    ...state.account.referral.copyToShare,
});

const mapDispatchToProps = dispatch => ({
    fetchReferralLink: () => dispatch(fetchReferralLink()),
    onCopy: (flag) => dispatch(onCopy(flag))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Referral);
