import firebase from '../../../../../../../../firebase';
import { validateField } from './validators';
import { openSnackbar } from '../../../../../../../../app/modules/snackbar/actions';
import {
  WORKFORCE_GROUP_UPDATED,
  WORKFORCE_GROUP_APPROVED,
  WORKFORCE_GROUP_DISAPPROVED,
  COD_APPROVED,
  COD_DISAPPROVED,
} from '../../../../config';
import { objectToArray } from '../../../../../../../../utils';

export const REQUEST_WORKFORCE_MEMBER_FIELD_UPDATE =
  'REQUEST_WORKFORCE_MEMBER_FIELD_UPDATE';
export const RECEIVE_WORKFORCE_MEMBER_FIELD_UPDATE =
  'RECEIVE_WORKFORCE_MEMBER_FIELD_UPDATE';
export const EDIT_GROUP_FIELD_CHANGE = 'EDIT_GROUP_FIELD_CHANGE';
export const SAVE_GROUP_ASSIGNMENT_ERROR = 'SAVE_GROUP_ASSIGNMENT_ERROR';
export const REQUEST_UPDATE_GROUP_DETAILS = 'REQUEST_UPDATE_GROUP_DETAILS';
export const RESPONSE_UPDATE_GROUP_DETAILS = 'RESPONSE_UPDATE_GROUP_DETAILS';
export const DELETE_WORKFORCE_FILE = 'DELETE_WORKFORCE_FILE';
export const WF_FILE_UPLOAD_START = 'WF_FILE_UPLOAD_START';
export const WF_FILE_UPLOAD_SUCCESS = 'WF_FILE_UPLOAD_SUCCESS';
export const WF_FILE_UPLOAD_FAILURE = 'WF_FILE_UPLOAD_FAILURE';

const requestUpdateField = () => ({
  type: REQUEST_WORKFORCE_MEMBER_FIELD_UPDATE,
});

export const updateField = (
  app,
  memberID,
  name,
  fieldID,
  value,
) => dispatch => {
  dispatch(requestUpdateField());
  const update = {
    [name]: value,
    updatedAt: Date.now(),
  };
  firebase
    .database()
    .ref(`workforce_groups/${app}/${memberID}/fields/${fieldID}`)
    .update(update)
    .then(() => {
      dispatch({
        type: RECEIVE_WORKFORCE_MEMBER_FIELD_UPDATE,
        data: update,
      });
    });
};

const newGroupFieldChange = (prop, id, value, isValid, groupID, latlong) => ({
  type: EDIT_GROUP_FIELD_CHANGE,
  latlong,
  prop,
  id,
  value,
  isValid,
  groupID,
});

const removeFile = file => ({
  type: DELETE_WORKFORCE_FILE,
  file,
});

export const formFieldChange = (
  isRequired,
  prop,
  id,
  value,
  groupID,
  latlong,
) => (dispatch, getState) => {
  let data = value;
  // To merge multiple files
  if (prop === 'File') {
    const { savedData } = getState().auth.workforceMembers.edit.groupTabs;
    const files = value.map(i => ({
      preview: i.preview,
      name: i.name,
    }));
    data =
      savedData[groupID][id] && savedData[groupID][id].value
        ? [...savedData[groupID][id].value, ...files]
        : files;
  }

  const valid = data ? validateField[prop](data) : null;
  const isValid = isRequired ? valid : true;

  return dispatch(
    newGroupFieldChange(prop, id, data, isValid, groupID, latlong),
  );
};

export const deleteFile = (id, index, groupID) => (dispatch, getState) => {
  const { savedData } = getState().auth.workforceMembers.edit.groupTabs;
  const allGroups = getState().auth.memberGroups.listPage.data.chunks;
  // collect file to remove from s3
  const file = savedData[groupID][id].value[index];
  if (file && !file.preview.includes('blob:http')) {
    dispatch(removeFile(file.preview));
  }
  // remove file from files array
  const data = savedData[groupID][id] ? savedData[groupID][id].value : [];
  data.splice(index, 1);
  const isRequired = allGroups[groupID].fields[id].isRequired;

  const isValid = isRequired ? !!data.length : true;
  return dispatch(newGroupFieldChange('File', id, data, isValid, groupID));
};

export const assigningError = err => ({
  type: SAVE_GROUP_ASSIGNMENT_ERROR,
  err,
});

const FileUploadStart = () => ({
  type: WF_FILE_UPLOAD_START,
});

const FileUploadSuccess = () => ({
  type: WF_FILE_UPLOAD_SUCCESS,
});

const FileUploadError = (message, id, index, groupID, url) => (
  dispatch,
  getState,
) => {
  if (url) {
    dispatch(deleteFile(id, index, groupID));
    firebase
      .database()
      .ref(url.substring(0, url.lastIndexOf('/')))
      .remove();
  }
  return dispatch({
    type: WF_FILE_UPLOAD_FAILURE,
    message,
  });
};

const NotificationError = message => ({
  type: 'NOTIFICATION_FAILURE',
  message,
});

const NotificationSuccess = () => ({
  type: 'NOTIFICATION_SUCCESS',
});

export const uploadFiles = (
  files,
  appName,
  memberID,
  groupID,
  fieldID,
) => dispatch => {
  files &&
    files.forEach(async (file, index) => {
      // process upload for blob only
      if (!file.preview.includes('blob:http')) {
        return false;
      }
      const response = await fetch(file.preview);
      const firebaseUrl = `workforce_groups_data/${appName}/${groupID}/${memberID}/${fieldID}/value/${index}/preview`;

      if (response.ok) {
        // if HTTP-status is 200-299
        // get the response body (the method explained below)
        const blobData = await response.blob();
        const form = new FormData();
        const user = firebase.auth().currentUser;
        const myHeaders = new Headers();

        form.append('firebaseUrl', firebaseUrl);
        form.append('file', blobData, file.name);

        return user
          .getIdToken()
          .then(token => {
            dispatch(FileUploadStart());
            myHeaders.append('x-access-token', token);

            return fetch(`${process.env.REACT_APP_IMAGES_API}/upload_file`, {
              method: 'post',
              headers: myHeaders,
              body: form,
              mode: 'cors',
            });
          })
          .then(res => res.json())
          .then(res => {
            if (res.error) {
              dispatch(
                FileUploadError(
                  res.error,
                  fieldID,
                  index,
                  groupID,
                  firebaseUrl,
                ),
              );
            } else {
              dispatch(FileUploadSuccess());
            }
          })
          .catch(error =>
            dispatch(
              FileUploadError(
                error.message,
                fieldID,
                index,
                groupID,
                firebaseUrl,
              ),
            ),
          );
      }
      return dispatch(
        FileUploadError(
          `File upload error HTTP-Error: ${response.status}`,
          fieldID,
          index,
          groupID,
          firebaseUrl,
        ),
      );
    });
};

export const saveDetails = (type, memberID, appName, fields, filesToDelete) =>
  new Promise((resolve, reject) => {
    const { currentUser } = firebase.auth();
    return currentUser.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);
      return fetch(`${process.env.REACT_APP_WORKFORCE_API}/update-group`, {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
          groupID: type,
          memberID,
          appName,
          fieldsData: fields,
          filesToDelete,
        }),
        headers,
      })
        .then(res => {
          res.json().then(data => {
            resolve(data);
          });
        })
        .catch(reject);
    });
  });

export const updateGroupDetails = (appName, memberID, groupID) => (
  dispatch,
  getState,
) => {
  const {
    savedData,
    filesToDelete,
  } = getState().auth.workforceMembers.edit.groupTabs;
  const filesArray = objectToArray(savedData[groupID]).filter(
    i => i.value.prop === 'File',
  );

  const field = {
    type: groupID,
    memberID,
    appName,
    savedData: savedData[groupID],
    filesToDelete,
  };

  dispatch({ type: REQUEST_UPDATE_GROUP_DETAILS });

  return saveDetails(...Object.values(field))
    .then(res => {
      if (!res.uid) {
        dispatch(assigningError(res));
      } else if (filesArray.length) {
        Promise.all(
          filesArray.map(data =>
            // uplaod files to S3 bucket by using pre builded function
            dispatch(
              uploadFiles(
                data.value.value,
                appName,
                memberID,
                groupID,
                data.id,
              ),
            ),
          ),
        ).then(() => {
          dispatch({
            type: RESPONSE_UPDATE_GROUP_DETAILS,
            key: res.uid,
            data: field,
          });
          dispatch(openSnackbar(WORKFORCE_GROUP_UPDATED));
        });
      } else {
        dispatch({
          type: RESPONSE_UPDATE_GROUP_DETAILS,
          key: res.uid,
          data: field,
        });
        dispatch(openSnackbar(WORKFORCE_GROUP_UPDATED));
      }
    })
    .catch(error => dispatch(assigningError(error)));
};

export const sendNotification = (
  user,
  profile,
  status,
  appName,
  type,
) => dispatch => {
  const auth = firebase.auth().currentUser;
  const myHeaders = new Headers();

  return auth
    .getIdToken()
    .then(token => {
      myHeaders.append('x-access-token', token);
      myHeaders.append('Content-Type', 'application/json');

      return fetch(`${process.env.REACT_APP_WORKFORCE_API}/notification`, {
        method: 'post',
        headers: myHeaders,
        body: JSON.stringify({
          user,
          profile,
          appName,
          source: 'platform',
          status,
          ...(type && { type }),
        }),
        mode: 'cors',
      });
    })
    .then(res => res.json())
    .then(res => {
      if (res.error) {
        dispatch(NotificationError(res.error));
      } else {
        dispatch(NotificationSuccess());
      }
    })
    .catch(error => dispatch(NotificationError(error.message)));
};

export const updateStatus = (appName, memberID, groupID, status) => (
  dispatch,
  getState,
) => {
  const workforceDataRef = firebase
    .database()
    .ref(`workforce_groups_data/${appName}/${groupID}/${memberID}`);

  const allGroups = getState().auth.memberGroups.listPage.data.chunks;

  return workforceDataRef
    .update({
      status,
      updatedAt: Date.now(),
    })
    .then(() => {
      dispatch({
        type: RESPONSE_UPDATE_GROUP_DETAILS,
        status,
      });
      dispatch(
        openSnackbar(
          status === 'active'
            ? WORKFORCE_GROUP_APPROVED
            : WORKFORCE_GROUP_DISAPPROVED,
        ),
      );
      dispatch(
        sendNotification(memberID, allGroups[groupID].name, status, appName),
      );
    })
    .catch(error => dispatch(assigningError(error)));
};

export const updateCOCStatus = (appName, memberID, groupID, data) => (
  dispatch,
  getState,
) => {
  const workforceDataRef = firebase
    .database()
    .ref(
      `workforce_groups_data/${appName}/${groupID}/${memberID}/codeofConduct`,
    );
  const allGroups = getState().auth.memberGroups.listPage.data.chunks;
  const { status } = data;
  return workforceDataRef
    .update({
      ...data,
      updatedAt: Date.now(),
    })
    .then(() => {
      dispatch({
        type: RESPONSE_UPDATE_GROUP_DETAILS,
        status,
      });
      dispatch(
        openSnackbar(status === 'active' ? COD_APPROVED : COD_DISAPPROVED),
      );
      dispatch(
        sendNotification(
          memberID,
          allGroups[groupID].name,
          status,
          appName,
          'CCAgreementAction',
        ),
      );
    })
    .catch(error => dispatch(assigningError(error)));
};

// export const getSubscriptionStatus = groupId => async (dispatch, getState) => {
//   const appname = getState().app.appContext.appname;
//   const uid = getState().account.auth.profile.uid;

//   try {
//     const snapshot = await firebase
//       .database()
//       .ref(`app_users/${appname}/${uid}/subscriptionGroups/${groupId}`)
//       .once('value');

//     if (!snapshot.exists()) {
//       return false;
//     }
//     const data = snapshot.val();

//     if (data.status === 'active') return true;

//     return false;
//   } catch (err) {

//     console.log(err);
//     return false;
//   }
// };
