import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Editor from '../../../../../../../app/components/textEditor';
import './styles.css';

class TextEditor extends Component {
  static propTypes = {
    data: PropTypes.shape({
      content: PropTypes.string,
      label: PropTypes.string,
      align: PropTypes.string,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  handleChange = (value, type) => {
    this.props.onChange(value, type);
  };

  render() {
    const toolbarButtons = {
      fonts: true,
      fontSize: true,
      bold: true,
      italic: true,
      underline: true,
      url: true,
      image: true,
      video: true,
      orderedList: true,
      unorderedList: true,
      negativeIntent: true,
      positiveIndent: true,
      textAlign: true,
      textColor: true,
      textBackground: true,
      clearTextFormatting: true,
      codeBlock: true,
      blockquote: true,
    };
    const { t } = this.props;
    return (
      <div>
        <Editor
          id="text"
          value={this.props.data.content}
          label={this.props.data.label}
          onChange={this.handleChange}
          placeholder={t('Text')}
          toolbar={toolbarButtons}
        />
      </div>
    );
  }
}

export default withTranslation()(TextEditor);
