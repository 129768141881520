import React from 'react';

const Text = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50.5 39.72"
  >
    <title>frame widget</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path class="cls-1" d="M50.5,39.72H0V4.78H50.5Zm-49-1.5H49V6.28H1.5Z" />
        <path class="cls-1" d="M50.5,6.72H0V0H50.5Zm-49-1.5H49V1.5H1.5Z" />
        <polygon
          class="cls-1"
          points="16.22 28.28 9.69 21.75 16.22 15.22 17.28 16.28 11.81 21.75 17.28 27.22 16.22 28.28"
        />
        <polygon
          class="cls-1"
          points="34.28 28.28 33.22 27.22 38.69 21.75 33.22 16.28 34.28 15.22 40.81 21.75 34.28 28.28"
        />
        <rect
          class="cls-1"
          x="14.64"
          y="21.5"
          width="21.21"
          height="1.5"
          transform="translate(-0.34 44.1) rotate(-81.88)"
        />
      </g>
    </g>
  </svg>
);

export default Text;
