export const REQUEST_MARKETPLACE_PRODUCTS = 'REQUEST_MARKETPLACE_PRODUCTS';
export const STOP_RECEIVING_MARKETPLACE_PRODUCTS =
  'STOP_RECEIVING_MARKETPLACE_PRODUCTS';
export const RECEIVE_MARKETPLACE_PRODUCTS = 'RECEIVE_MARKETPLACE_PRODUCTS';

export const NO_MARKETPLACE_PRODUCTS_EXIST = 'NO_MARKETPLACE_PRODUCTS_EXIST';
export const FILTER_MARKETPLACE_PRODUCTS_DATA =
  'FILTER_MARKETPLACE_PRODUCTS_DATA';
export const SELECT_MARKETPLACE_PRODUCT = 'SELECT_MARKETPLACE_PRODUCT';
export const CLOSE_MARKETPLACE_PRODUCTS_DIALOG =
  'CLOSE_MARKETPLACE_PRODUCTS_DIALOG';
export const SELECT_ALL_MARKETPLACE_PRODUCTS =
  'SELECT_ALL_MARKETPLACE_PRODUCTS';
export const DESELECT_ALL_MARKETPLACE_PRODUCTS =
  'DESELECT_ALL_MARKETPLACE_PRODUCTS';
export const OPEN_MARKETPLACE_PRODUCTS_DIALOG =
  'OPEN_MARKETPLACE_PRODUCTS_DIALOG';
export const DELETE_SELECTED = 'DELETE_SELECTED';
export const ACTION_ERROR = 'ACTION_ERROR';
export const SORT_MARKETPLACE_PRODUCTS = 'SORT_MARKETPLACE_PRODUCTS';
