import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';

import AppDomainInput from '../../../internals/appDomainInput';
import TextArea from '../../../../../app/components/textArea';
import TextField from '../../../../../app/components/textField';
import { FlatButton } from '../../../../../app/components/buttons';
import Container from '../../../../../app/components/container';
import SectionHeader from '../../../components/sectionHeader';

class AppName extends Component {
  static propTypes = {
    meta: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
    appDomain: PropTypes.shape({
      isModified: PropTypes.bool,
      domain: PropTypes.string,
      validDomain: PropTypes.bool,
      availableDomain: PropTypes.bool,
    }),
    validations: PropTypes.shape({
      title: PropTypes.bool,
      description: PropTypes.bool,
    }),
    match: PropTypes.object.isRequired,
    modified: PropTypes.bool.isRequired,
    loadData: PropTypes.func.isRequired,
    updateValue: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    meta: {
      title: '',
      description: '',
    },
    validations: {
      title: true,
      description: true,
    },
    appDomain: {},
    modified: false,
  };

  componentWillMount() {
    this.props.loadData();
  }

  isValidForm = () => {
    const { validDomain, availableDomain, isModified } = this.props.appDomain;
    const { validations, modified } = this.props;
    return (
      (modified || isModified) &&
      validDomain &&
      availableDomain &&
      validations.title &&
      validations.description
    );
  };

  handleChange = attr => value =>
    this.props.updateValue(attr, value, this.props.validations);

  showTitleErrorMsg = () =>
    !this.props.validations.title && (
      <small className="error-message error-in-panel">
        <Trans>
          App title must be a string between 3 and 45 characters and not contain
          any html tags, single quotes or double quotes
        </Trans>
      </small>
    );

  showDescriptionErrorMsg = () =>
    !this.props.validations.description && (
      <small className="error-message error-in-panel">
        <Trans>
          App meta description must be no longer than 320 characters and not
          contain any html tags, single quotes or double quotes
        </Trans>
      </small>
    );

  render() {
    const { appDomain, meta, match, isAppAdmin, isUpdating, t } = this.props;

    return (
      <Container>
        {!isAppAdmin && (
          <>
            <SectionHeader
              title={t('App Name')}
              tooltip={t('Change your app name and domain')}
            />
            <AppDomainInput currentDomain={match.params.appname} />
          </>
        )}
        <SectionHeader
          title={t('App Title')}
          tooltip={t(
            'This is the title of the App that will appear on the device',
          )}
        />
        <TextField
          id="title"
          value={meta.title}
          onChange={this.handleChange('title')}
          maxLength={45}
          required
        />
        {this.showTitleErrorMsg()}
        <SectionHeader
          title={t('Meta Description')}
          tooltip={t(
            'A description of your app that will show up in search engines',
          )}
        />
        <TextArea
          id="Description"
          value={meta.description}
          onChange={this.handleChange('description')}
          label=""
        />
        {this.showDescriptionErrorMsg()}
        <FlatButton
          label={isUpdating ? t('Please wait...') : t('Save')}
          onClick={() => this.props.onSubmit(meta, appDomain.domain)}
          disabled={!this.isValidForm() || isUpdating}
          primary
        />
      </Container>
    );
  }
}

export default withTranslation()(AppName);
