import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';

import { FlatButton } from '../../../../app/components/buttons';

const ImageDialog = ({
  open,
  title,
  cancelLabel,
  submitLabel,
  children,
  submitDisabled,
  onCancel,
  onSubmit,
  fullScreen,
  onChange,
  changeLabel,
  changeDisabled,
}) => {
  const actions = [
    <FlatButton label={cancelLabel} onClick={onCancel} secondaryWhite />,
    <FlatButton
      label={changeLabel}
      onClick={onChange}
      disabled={changeDisabled}
      secondary
    />,
    <FlatButton
      label={submitLabel}
      onClick={onSubmit}
      disabled={submitDisabled}
      primary
    />,
  ];

  return (
    <Dialog
      title={title}
      fullScreen={fullScreen}
      open={open}
      actions={actions}
      actionsContainerStyle={{
        textAlign: 'center',
        padding: 16,
      }}
      onRequestClose={onCancel}
      autoScrollBodyContent
    >
      {children}
    </Dialog>
  );
};

ImageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  cancelLabel: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  changeLabel: PropTypes.string.isRequired,
  changeDisabled: PropTypes.bool.isRequired,
};

ImageDialog.defaultProps = {
  fullScreen: false,
};

export default ImageDialog;
