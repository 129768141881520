import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Trans, useTranslation } from 'react-i18next';
import Toggle from 'material-ui/Toggle';
import Radio from '@material-ui/core/Radio';
import Grow from '@material-ui/core/Grow';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { FlatButton } from '../../../../../../app/components/buttons';
import { CircularProgress } from '../../../../../../app/components/progress';
import FormFields from '../../../../components/form';
import { objectToArray } from '../../../../../../utils';

import './styles.css';

const New = ({
  open,
  pending,
  onSubmit,
  onRequestClose,
  onFieldChange,
  fields,
  fieldsData,
  isRecurringCourse,
  recurringType,
  recurringValue,
  setRecurringData,
}) => {
  const { t } = useTranslation();
  const buttonSubmit = !pending ? (
    <FlatButton
      label={t('Create')}
      onClick={onSubmit}
      primary
      disabled={
        !fieldsData ||
        !!objectToArray(fieldsData).find(
          i => !i.value.isValid && i.id !== 'createdAt' && i.id !== 'updatedAt',
        )
      }
    />
  ) : (
    <CircularProgress size={18} />
  );

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      autoScrollBodyContent
      scroll="paper"
      maxWidth="lg"
    >
      <div className="user-courses-modal-content">
        <div className="modal-heading">
          <DialogTitle>
            <Trans>Create New Course</Trans>
          </DialogTitle>
        </div>
        <div>
          <FormFields
            fields={fields}
            onFieldChange={onFieldChange}
            fieldsData={fieldsData}
            isRecurringEvent={isRecurringCourse}
          />

          <div style={{ padding: '10px 7px' }}>
            <div className="field-wrapper">
              <Toggle
                onToggle={(v, b) => setRecurringData('isRecurringCourse', b)}
                toggled={isRecurringCourse}
                label={t('Recurring course')}
                // disabled={isUpdate}
              />
            </div>

            <Grow in={isRecurringCourse} unmountOnExit>
              <div className="field-wrapper">
                <FormControl component="fieldset" fullWidth>
                  <RadioGroup
                    aria-label="weeks"
                    defaultValue="weeks"
                    name="radio-buttons-group"
                    row
                    value={recurringType}
                    onChange={e =>
                      setRecurringData('recurringType', e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="weeks"
                      control={<Radio />}
                      label={t('Week')}
                    />
                    <FormControlLabel
                      value="months"
                      control={<Radio />}
                      label={t('Month')}
                    />
                    <FormControlLabel
                      value="years"
                      control={<Radio />}
                      label={t('Year')}
                    />
                  </RadioGroup>
                </FormControl>
                <div>
                  <TextField
                    type="number"
                    required
                    label={` ${t('Number of')} ${recurringType} ${t(
                      'to repeat course:',
                    )}`}
                    fullWidth
                    variant="standard"
                    value={recurringValue}
                    onChange={e =>
                      e.target.value >= 0 &&
                      setRecurringData('recurringValue', e.target.value)
                    }
                    minimum={2}
                  />
                </div>
              </div>
            </Grow>
          </div>

          <div className="submit-button-container">{buttonSubmit}</div>
        </div>
      </div>
    </Dialog>
  );
};

New.propTypes = {
  open: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  fieldsData: PropTypes.object.isRequired,
  isRecurringCourse: PropTypes.bool.isRequired,
  recurringType: PropTypes.string.isRequired,
  recurringValue: PropTypes.number.isRequired,
  setRecurringData: PropTypes.func.isRequired,
};

New.defaultProps = {
  error: null,
  fields: {},
  fieldsData: {},
};

export default New;
