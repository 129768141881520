import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/styles';
import {
  TableContainer,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Checkbox,
} from '@material-ui/core';
import moment from 'moment';
import { get, set, reduce, keys, pickBy, isEmpty } from 'lodash';

import { downloadCSV } from 'utils';
import ActionMenu from 'app/components/actionMenu';
import { Empty as EmptyIcon } from 'app/components/icons';
import OrderDetails from '../OrderDetails/OrderDetails';
import { getAllOrders } from '../../api';
import styles from './styles.module.scss';
import themeStyles from '../../../../_export.scss';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-head': {
      color: 'black',
      backgroundColor: '#EAEEFF',
      fontSize: 16,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
    },
    '& .MuiTableCell-body': {
      color: 'black',
      fontSize: 16,
      fontWeight: 400,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
      borderBottom: 'none',
    },
    '& .options-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .MuiCheckbox-root': {
      width: 24,
      height: 24,
    },
    '& .action-menu-container': {
      width: 24,
      height: 24,
    },
  },
});

const OrdersTable = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [orderKey, setOrderKey] = useState('');
  const [refreshChildData, setRefreshChildData] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [timeRadioValue, setTimeRadioValue] = useState('');
  const [deliveryRadioValue, setDeliveryRadioValue] = useState();
  const [emailCheckedValue, setEmailCheckedValue] = useState(false);
  const [phoneCheckedValue, setPhoneCheckedValue] = useState(false);
  const [emailFilter, setEmailFilter] = useState('');
  const [phoneFilter, setPhoneFilter] = useState('');
  const [orderData, setOrderData] = useState();
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const { appname, driverData, metaData, isAppAdmin } = props;
  const { data, error, isLoading, isError } = useQuery(
    ['foodOrders', { appname }],
    getAllOrders,
    { refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (refreshChildData && !isEmpty(data)) {
      setOrderDetails(data[orderKey]);
      setRefreshChildData(false);
      setIsButtonDisabled(false);
    }

    setOrderData(data);
  }, [data]);

  const getCsvData = (selectedItems, chunks) => {
    const csvColumns = [
      t('User Email'),
      t('User Number'),
      t('Total Paid'),
      t('Payment Method'),
      t('Delivery Status'),
      t('Product'),
      t('Quantity'),
      t('Order No'),
      t('Date'),
    ];
    let itemsData = `${csvColumns.join(',')}\n`;

    selectedItems.forEach(key => {
      itemsData += `${chunks[key].userEmail || '-'},${chunks[key].userNumber ||
        '-'},${chunks[key].finalBill || '-'},${
        chunks[key].paymentOption === 'card' ? t('Card') : ''
      }${
        chunks[key].paymentOption === 'cash_on_delivery'
          ? t('Cash on delivery')
          : ''
      },${
        chunks[key].deliveryStatus === true
          ? t('Delivered')
          : chunks[key].orderCancelled === true
          ? t('Order Cancelled')
          : t('Not Delivered')
      },${chunks[key].cart.map(item => item.name).join(';')},${chunks[key].cart
        .map(item => item.quantity)
        .join(';')},${chunks[key].orderNo || '-'},${moment(
        chunks[key].orderedAt,
      ).format('DD/MM/YY') || '-'}\n`;
    });

    return itemsData;
  };

  const handleApplyFilter = async () => {
    const timeValue = timeRadioValue.split(':');
    let delivered = null;

    if (deliveryRadioValue === 'delivered') delivered = true;
    if (deliveryRadioValue === 'undelivered') delivered = false;

    const pastDate = moment()
      .subtract(timeValue[0], timeValue[1])
      .valueOf();

    const test = Object.entries(data);
    const res = test.filter(
      key =>
        pastDate < key[1].orderedAt &&
        (delivered !== null ? key[1].deliveryStatus === delivered : true) &&
        (emailCheckedValue ? key[1].userEmail === emailFilter : true) &&
        (phoneCheckedValue ? key[1].userNumber === phoneFilter : true),
    );

    const obj = Object.fromEntries(res);
    setOrderData(obj);
    setOpenFilterModal(false);
    setTimeRadioValue('');
    setDeliveryRadioValue('');
    setEmailFilter('');
    setPhoneFilter('');
  };

  const handleRemoveFilter = () => {
    setOrderData(data);
    setOpenFilterModal(false);
    setTimeRadioValue('');
    setDeliveryRadioValue('');
    setEmailFilter('');
    setPhoneFilter('');
  };

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  if (isError) {
    return (
      <span>
        <Trans>Error:</Trans> {error.message}
      </span>
    );
  }

  const docLink =
    'https://beezer.zendesk.com/hc/en-us/articles/360020307757-Orders-Section-Buisness-Tool';
  const docPDFLink =
    'https://firebasestorage.googleapis.com/v0/b/beezer-c947f.appspot.com/o/Instructions%2FOrders%20Section%20-%20Business%20Tool.pdf?alt=media&token=aa8061b9-0aea-46eb-a1cd-e803710efa0b';
  const getInstructionLink = () =>
    isAppAdmin ? (
      <a
        href={docPDFLink}
        rel="noopener noreferrer"
        style={{ color: themeStyles.primaryColor, fontWeight: 'bold' }}
        download
        target="_blank"
      >
        <Trans>Learn how orders work.</Trans>
      </a>
    ) : (
      <a
        href={docLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: themeStyles.primaryColor, fontWeight: 'bold' }}
      >
        <Trans>Learn how orders work.</Trans>
      </a>
    );

  const onSelect = id => {
    const modifiedChunks = { ...orderData };
    const prevValue = get(modifiedChunks, `${id}.selected`, false);
    set(modifiedChunks, `${id}.selected`, !prevValue);
    setOrderData(modifiedChunks);
    const selectedItems = keys(pickBy(modifiedChunks, chunk => chunk.selected));
    setSelected(selectedItems);
  };

  const selectAllOrders = () => {
    const allIds = keys(orderData);
    const modifiedChunks = reduce(
      orderData,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: true,
        },
      }),
      {},
    );
    setOrderData(modifiedChunks);
    setSelected(allIds);
    setSelectAll(true);
  };

  const deselectAllOrders = () => {
    const modifiedChunks = reduce(
      orderData,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: false,
        },
      }),
      {},
    );
    setOrderData(modifiedChunks);
    setSelected([]);
    setSelectAll(false);
  };

  const onEditSelected = key => {
    setOpenDialog(true);
    setOrderDetails(orderData[key]);
    setOrderKey(key);
  };

  return (
    <div>
      {!orderData || Object.keys(orderData).length === 0 ? (
        <div className="empty-state">
          <EmptyIcon />
          <h5>
            <Trans>You don&apos;t have any orders yet.</Trans>
          </h5>
          <p>
            <Trans>
              Use your app to allow your app users to place food orders. Add
              food inventory, accept orders, assign orders to drivers and
              deliver them directly to your customers.
            </Trans>
          </p>
          <h5>{getInstructionLink()}</h5>
        </div>
      ) : (
        <TableContainer
          style={{ maxHeight: 650, borderRadius: 15, boxShadow: 'none' }}
          component={Paper}
        >
          <Table
            stickyHeader
            aria-label="customized table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Date Ordered</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Customer Email</Trans>
                </TableCell>
                <TableCell>
                  <Trans>To Number</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Total Paid</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Payment Method</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Delivery Status</Trans>
                </TableCell>
                <TableCell>
                  <div className="options-container">
                    <Checkbox
                      disableRipple
                      checked={selectAll || false}
                      onChange={(e, isChecked) =>
                        isChecked ? selectAllOrders() : deselectAllOrders()
                      }
                      style={{ color: themeStyles.primaryColor }}
                    />
                    <ActionMenu
                      selected={selected}
                      onDownloadSelected={() => {
                        downloadCSV(
                          getCsvData(selected, orderData),
                          t('orders'),
                        );
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderData &&
                Object.keys(orderData).length > 0 &&
                Object.keys(orderData).map(key => (
                  <TableRow key={key} hover onClick={() => onEditSelected(key)}>
                    <TableCell component="th" scope="row">
                      {moment(orderData[key].orderedAt).format('DD/MM/YY') ||
                        '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {orderData[key].userEmail || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {orderData[key].userNumber || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {orderData[key].finalBill || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {orderData[key].paymentOption === 'card' && t('Card')}
                      {orderData[key].paymentOption === 'cash_on_delivery' &&
                        t('Cash on delivery')}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {orderData[key].deliveryStatus === true
                        ? t('Delivered')
                        : orderData[key].orderCancelled === true
                        ? t('Order Cancelled')
                        : t('Not Delivered')}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="options-container">
                        <Checkbox
                          disableRipple
                          checked={orderData[key].selected || false}
                          onClick={e => e.stopPropagation()}
                          onChange={e => {
                            e.stopPropagation();
                            onSelect(key);
                          }}
                          style={{ color: themeStyles.primaryColor }}
                        />
                        <ActionMenu
                          selected={[key]}
                          onEditSelected={() => onEditSelected(key)}
                          onDownloadSelected={() => {
                            downloadCSV(
                              getCsvData([key], orderData),
                              t('orders'),
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <OrderDetails
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            orderDetails={orderDetails}
            driverData={driverData}
            metaData={metaData}
            appname={appname}
            setRefreshChildData={setRefreshChildData}
            isButtonDisabled={isButtonDisabled}
            setIsButtonDisabled={setIsButtonDisabled}
          />
        </TableContainer>
      )}
    </div>
  );
};
// <Button onClick={() => setOpenFilterModal(true)}>Filter Orders</Button>
// <Modal
//   open={openFilterModal}
//   onClose={() => setOpenFilterModal(false)}
//   className={styles.dateModal}
// >
//   <div className={styles.modalContainer}>
//     <div className={styles.modalLeft}>
//       <div
//         className="time-filter-container"
//         style={{ marginLeft: '5vw' }}
//       >
//         <h3>Time Filter</h3>
//         <RadioGroup
//           aria-label="time-filter"
//           name="time-filter"
//           value={timeRadioValue}
//           onChange={e => setTimeRadioValue(e.target.value)}
//         >
//           <FormControlLabel
//             value="30:days"
//             control={<Radio />}
//             label="Last 30 Days"
//           />
//           <FormControlLabel
//             value="3:months"
//             control={<Radio />}
//             label="Last 3 Months"
//           />
//           <FormControlLabel
//             value="6:months"
//             control={<Radio />}
//             label="Last 6 Months"
//           />
//         </RadioGroup>
//       </div>
//       {/* DELIVERY STATUS */}
//       <div
//         className="delivery-filter-container"
//         style={{ marginLeft: '5vw' }}
//       >
//         <h3>Delivery Filter</h3>
//         <RadioGroup
//           aria-label="delivery-filter"
//           name="delivery-filter"
//           value={deliveryRadioValue}
//           onChange={e => setDeliveryRadioValue(e.target.value)}
//         >
//           <FormControlLabel
//             value="delivered"
//             control={<Radio />}
//             label="Delivered"
//           />
//           <FormControlLabel
//             value="undelivered"
//             control={<Radio />}
//             label="Not Delivered"
//           />
//         </RadioGroup>
//       </div>
//       <div style={{ marginLeft: '5vw' }}>
//         <Button
//           variant="contained"
//           color="primary"
//           className={styles.filterBtn}
//           onClick={handleApplyFilter}
//           style={{ margin: '15px' }}
//         >
//           APPLY FILTER
//         </Button>
//         <Button
//           variant="contained"
//           color="secondary"
//           className={styles.filterBtn}
//           onClick={handleRemoveFilter}
//           style={{ margin: '15px' }}
//         >
//           REMOVE FILTER
//         </Button>
//       </div>
//     </div>
//     <div className={styles.modalRight}>
//       {/* EMail FILTER */}
//       <div
//         className="email-filter-container"
//         style={{ marginLeft: '5vw' }}
//       >
//         <h3>Email Filter</h3>

//         <FormControlLabel
//           control={
//             <Checkbox
//               checked={emailCheckedValue}
//               onChange={e => setEmailCheckedValue(!emailCheckedValue)}
//               name="Email"
//             />
//           }
//           label="Email"
//         />
//         {emailCheckedValue ? (
//           <TextField
//             required
//             onChange={e => setEmailFilter(e.target.value)}
//           />
//         ) : null}
//       </div>
//       {/* Mobile FILTER */}
//       <div
//         className="phone-filter-container"
//         style={{ marginLeft: '5vw' }}
//       >
//         <h3>Phone Filter</h3>
//         <FormControlLabel
//           control={
//             <Checkbox
//               checked={phoneCheckedValue}
//               onChange={e => setPhoneCheckedValue(!phoneCheckedValue)}
//               name="Phone"
//             />
//           }
//           label="Phone"
//         />
//         {phoneCheckedValue ? (
//           <TextField
//             required
//             onChange={e => setPhoneFilter(e.target.value)}
//           />
//         ) : null}
//       </div>
//     </div>
//   </div>
// </Modal>

export default OrdersTable;
