import { newValidator } from '../../../../../../../utils';

const validateTitle = newValidator({ required: true, maxLength: 21 });

const initialState = {
  showNotification: false,
  open: false,
  pending: false,
  error: null,
  title: {
    value: '',
    isValid: null,
    maxLength: 21,
    required: true,
  },
  parentPage: null,
  type: 'View',
  tabs: [
    {
      title: {
        value: '',
        isValid: null,
      },
    },
    {
      title: {
        value: '',
        isValid: null,
      },
    },
  ],
  steps: [
    {
      title: 'Select Page Type',
      isValid: true,
    },
    {
      title: 'Page Settings',
      isValid: false,
    },
  ],
};

const checkPageSettingsValidity = state => {
  if (state.type === 'View' || state.type === 'Popup') {
    return state.title && state.title.isValid;
  } else if (state.type === 'TabView') {
    return (
      state.title &&
      state.title.isValid &&
      state.tabs.every(tab => tab.title.isValid)
    );
  } else if (state.type === 'NestedPage') {
    return state.title && state.title.isValid && state.parentPage;
  } else if (state.type === 'NestedTabPage') {
    return (
      state.title &&
      state.title.isValid &&
      state.tabs.every(tab => tab.title.isValid) &&
      state.parentPage
    );
  }
};

const getSteps = state => [
  {
    title: 'Select Page Type',
    isValid:
      state.type === 'View' ||
      state.type === 'Popup' ||
      state.type === 'TabView' ||
      state.type === 'NestedPage' ||
      state.type === 'NestedTabPage',
  },
  {
    title: 'Page Settings',
    isValid: checkPageSettingsValidity(state),
  },
];

const getTab = () => ({
  title: {
    value: '',
    isValid: null,
  },
});

const createPage = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      return {
        ...state,
        showNotification: action.value,
      };
    case 'OPEN_CREATE_PAGE_UI':
      return {
        ...state,
        open: true,
      };

    case 'CLOSE_CREATE_PAGE_UI':
      return {
        ...state,
        open: false,
      };

    case 'CREATING_PAGE':
      return {
        ...state,
        pending: true,
        error: null,
      };

    case 'PAGE_CREATED':
      return {
        ...state,
        pending: false,
        error: null,
      };

    case 'CREATE_PAGE_ERROR':
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case 'CREATE_PAGE_TYPE_CHANGE': {
      const nextState = {
        ...state,
        type: action.value,
      };

      return {
        ...nextState,
        steps: getSteps(nextState),
      };
    }

    case 'CREATE_PAGE_TITLE_CHANGE': {
      const nextState = {
        ...state,
        title: {
          ...state.title,
          value: action.value,
          isValid: validateTitle(action.value),
        },
      };

      return {
        ...nextState,
        steps: getSteps(nextState),
      };
    }

    case 'CREATE_PAGE_TAB_CHANGE': {
      const nextState = {
        ...state,
        tabs: [
          ...state.tabs.slice(0, action.index),
          {
            title: {
              value: action.value,
              isValid: validateTitle(action.value),
            },
          },
          ...state.tabs.slice(action.index + 1),
        ],
      };

      return {
        ...nextState,
        steps: getSteps(nextState),
      };
    }

    case 'CREATE_PAGE_ADD_TAB': {
      const nextState = {
        ...state,
        tabs: state.tabs.concat(getTab()),
      };

      return {
        ...nextState,
        steps: getSteps(nextState),
      };
    }

    case 'CREATE_PAGE_REMOVE_TAB': {
      const nextState = {
        ...state,
        tabs: [
          ...state.tabs.slice(0, action.index),
          ...state.tabs.slice(action.index + 1),
        ],
      };

      return {
        ...nextState,
        steps: getSteps(nextState),
      };
    }

    case 'CREATE_PAGE_PARENT_PAGE_CHANGE': {
      const nextState = {
        ...state,
        parentPage: action.value,
      };
      return {
        ...nextState,
        steps: getSteps(nextState),
      };
    }
    case 'RESET_CREATE_PAGE_FORM':
      return initialState;

    default:
      return state;
  }
};

export default createPage;
