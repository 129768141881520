import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import Container from '../../../../../../../app/components/container';
import TextField from '../../../../../../../app/components/textField';
import SectionHeader from '../../../../../components/sectionHeader';
import ImageUpload from '../../../../../internals/imageUpload';
import ButtonLinkMenu from '../../../../../../editor/components/buttonLinkMenu';
import SocialLinks from '../socialLinks';

import './styles.css';

class DelegatesItem extends Component {
  static propTypes = {
    component: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    childRoutes: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  getAvatarUrl = () => {
    const { match, location, component } = this.props;

    const item = queryString.parse(location.search).item;

    return `apps/${match.params.appname}/draft/lazy_data/${component.lazy_data}/items/chunks/${item}/avatar`;
  };

  handleChange = prop => value => this.props.onChange(prop, value);

  handleChangeLinkSection = (prop, value) => this.props.onChange(prop, value);

  render() {
    const { avatar, title, subtitle, company, link } = this.props.data;
    const { t } = this.props;
    return (
      <Container>
        <SectionHeader
          title={t('Avatar')}
          tooltip={t('Upload an image for the avatar')}
        />
        <ImageUpload
          title={t('Delegates Item > Avatar')}
          src={avatar}
          firebaseUrl={this.getAvatarUrl()}
          tooltip={t('Minimum image size 240x240 pixels')}
          height="160px"
          width="160px"
          avatar
        />
        <TextField
          id="delegates-item-title"
          value={title}
          onChange={this.handleChange('title')}
          placeholder={t('Title')}
        />
        <TextField
          id="delegates-item-subtitle"
          value={subtitle}
          onChange={this.handleChange('subtitle')}
          placeholder={t('Subtitle')}
        />
        <TextField
          id="delegates-item-company"
          value={company}
          onChange={this.handleChange('company')}
          placeholder={t('Company')}
        />
        <ButtonLinkMenu
          link={link}
          pages={this.props.childRoutes}
          onChange={this.handleChangeLinkSection}
          noButtonLink
          borderTop
          tooltip={t('Try linking to the Roster page with the same delegate.')}
          googleAnalytics={this.props.googleAnalytics}
          gaEnabled={this.props.data.gaEnabled}
          gaCategory={this.props.data.gaCategory}
          gaAction={this.props.data.gaAction}
        />
        <SocialLinks data={this.props.data} component={this.props.component} />
      </Container>
    );
  }
}

export default withTranslation()(DelegatesItem);
