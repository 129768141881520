import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HtmlToText from 'html-to-text';
import ActionMenu from 'app/components/actionMenu';
import { Trans, useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { downloadCSV, formatDate } from 'utils';
import Table from '../../../../app/components/table';
import TryAgain from '../../../../app/components/tryAgain';
import Header from '../../components/header';
import TableCheckbox from '../../components/tableCheckbox';
import themeStyles from '../../../../_export.scss';

const isCheckboxDisabled = (error, pending) => error && !pending;

const Diary = props => {
  const {
    loadDiaryData,
    onFilterDiaryData,
    error,
    pending,
    items,
    filteredChunks,
    selected,
    chunks,
    selectAll,
    onSelect,
    onSelectAllDiaries,
    onDeselectAllDiaries,
    deleteDiaryEntry,
  } = props;

  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDiaryKey, setSelectedDiaryKey] = useState('');

  const descriptionElementRef = React.useRef(null);

  useEffect(() => {
    if (openDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDialog]);

  const getCsvData = (selected, diaries) => {
    const csvColumns = [
      t('First name'),
      t('Last name'),
      t('Username'),
      t('Email'),
      t('Title'),
      t('Created'),
      t('Content'),
      t('Views'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selected.forEach(key => {
      console.log(diaries[key]);
      data += `${diaries[key].firstName},${diaries[key].lastName},${diaries[key]
        .author || '-'},${diaries[key].email},${
        diaries[key].title
      },${formatDate(diaries[key].created)},"${HtmlToText.fromString(
        diaries[key].body,
        {
          ignoreHref: true,
          ignoreImage: true,
        },
      )
        .split('')
        .map(character => (character === '"' ? `"${character}` : character))
        .join('')}",${diaries[key].views}\n`;
    });

    return data;
  };

  const getSelectAllCheckBox = () => (
    <div style={{ display: 'flex' }}>
      <Checkbox
        checked={selectAll}
        onChange={(e, isChecked) =>
          isChecked ? onSelectAllDiaries() : onDeselectAllDiaries()
        }
        disabled={isCheckboxDisabled(error, pending)}
        style={{ marginLeft: -2, color: themeStyles.primaryColor }}
        disableRipple
      />
      <ActionMenu
        selected={selected}
        onDownloadSelected={() => {
          downloadCSV(getCsvData(selected, chunks), 'diaries');
        }}
        text={t('diaries')}
      />
    </div>
  );

  const getTitle = (selected, transactions) => (
    <span
      style={{
        fontFamily: 'Raleway, sans-serif',
        fontWeight: 600,
        fontSize: 20,
      }}
    >
      <Trans>Diary Entries</Trans>:{' '}
      {selected.length
        ? `${selected.length} / ${Object.keys(transactions).length}`
        : Object.keys(transactions).length}
    </span>
  );

  const handleRowClicked = diaryKey => {
    setOpenDialog(true);
    setSelectedDiaryKey(diaryKey);
  };

  const handleDeleteDiary = diaryKey => {
    deleteDiaryEntry(diaryKey);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedDiaryKey('');
  };

  const renderDialog = () => {
    const data = filteredChunks[selectedDiaryKey];
    if (!data) return null;
    return (
      <Dialog
        open={openDialog}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              height: '100%',
              maxWidth: '80%',
            },
          },
        }}
      >
        <DialogTitle id="scroll-dialog-title">{data.title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            dangerouslySetInnerHTML={{ __html: data.body }}
          ></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Trans>Close</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const suffixClass = items.length < 4 ? 'fix-left' : 'selected';

  const renderBody = () => {
    if (error) {
      return (
        <TryAgain
          message={t('Oops, something went wrong...')}
          onRetry={loadDiaryData}
        />
      );
    } else if (items.length) {
      return (
        <Table
          items={items}
          chunks={filteredChunks}
          loading={pending}
          onRowTouchTap={key => handleRowClicked(key)}
          cols={[
            {
              id: 'firstName',
              header: t('First Name'),
              classNames: 'col-xs-1',
              containerElement: ({ value }) => (
                <strong>{value ? value : ''}</strong>
              ),
            },
            {
              id: 'lastName',
              header: t('Last Name'),
              classNames: 'col-xs-1',
              containerElement: ({ value }) => (
                <strong>{value ? value : ''}</strong>
              ),
            },
            {
              id: 'author',
              header: t('Username'),
              classNames: 'col-xs-2',
              containerElement: ({ value, rowId }) => {
                const row = filteredChunks[rowId];
                return <strong>{value ? value : row.email}</strong>;
              },
            },
            {
              id: 'title',
              header: t('Title'),
              classNames: 'col-xs-2',
              containerElement: ({ value }) => {
                return (
                  // <span>{value.email ? value.email : value.description}</span>

                  <span
                    style={{
                      textAlign: 'center',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '200px',
                    }}
                  >
                    {value}
                  </span>
                );
              },
            },
            {
              id: 'created',
              header: t('Date Created'),
              classNames: 'col-xs-2',
              containerElement: ({ value }) => {
                return (
                  // <span>{value.email ? value.email : value.description}</span>
                  <span>{moment(value).format('MMMM Do YYYY')}</span>
                );
              },
            },
            {
              id: 'body',
              header: t('Content'),
              classNames: 'col-xs-2',
              containerElement: ({ value }) => {
                return (
                  // <span>{value.email ? value.email : value.description}</span>
                  <span
                    style={{
                      textAlign: 'center',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '200px',
                    }}
                  >
                    {HtmlToText.fromString(value, {
                      ignoreHref: true,
                      ignoreImage: true,
                    }).substring(0, 50)}
                  </span>
                );
              },
            },
            {
              id: 'views',
              header: t('Views'),
              classNames: 'col-xs-1',
              containerElement: ({ value }) => {
                return (
                  // <span>{value.email ? value.email : value.description}</span>
                  <span>{value}</span>
                );
              },
            },
            {
              id: 'selected',
              header: getSelectAllCheckBox(),
              classNames: `col-xs-1 ${suffixClass}`,
              containerElement: ({ value, rowId }) => (
                <>
                  <TableCheckbox
                    value={value}
                    onSelect={() => onSelect(rowId)}
                  />
                  <ActionMenu
                    selected={[rowId]}
                    onDeleteSelected={() => handleDeleteDiary(rowId)}
                    onDownloadSelected={() =>
                      downloadCSV(getCsvData([rowId], chunks), 'diary')
                    }
                  />
                </>
              ),
            },
          ]}
        />
      );
    }
    return (
      <center>
        <p className="instructions-container">
          <p className="instructions-section-1">
            <Trans>
              The User Diary component can be used by your app users to write
              down notes, ideas, or any other information.
            </Trans>{' '}
            <span>
              <Trans>
                Notes can be written and edited on the go. Images and videos can
                also be added to it, making it a digital space for users to
                store and organise their thoughts, ideas, and memories, and
                access them from anywhere with an internet connection.
              </Trans>
            </span>{' '}
            <span>
              <Trans>
                All submitted user diaries will be available in this section.
                See more on User Diary
              </Trans>{' '}
              <a
                href="https://beezer.zendesk.com/hc/en-us/articles/5805605321617-My-Diary-Component"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Trans>here.</Trans>
              </a>
            </span>
          </p>
        </p>
      </center>
    );
  };

  return (
    <section style={{ height: 'calc(100% - 168px)' }}>
      <Header
        {...props}
        title={getTitle(selected, chunks)}
        onChange={onFilterDiaryData}
      />
      {renderBody()}
      {renderDialog()}
    </section>
  );
};

export default Diary;

Diary.defaultProps = {
  error: null,
  filter: null,
  chunks: [],
  filteredChunks: [],
  items: [],
  query: '',
  selected: [],
  selectAll: false,
  pending: true,
};
