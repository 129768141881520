const headers = new Headers({
  Accept: 'application/json, text/javascript, */*; q=0.01',
  'Content-Type': 'application/json',
});

export const setLocationData = (appname, mapLayers) =>
  fetch(`${process.env.REACT_APP_FOOD_API}/set_delivery_locations`, {
    method: 'POST',
    mode: 'cors',
    headers,
    body: JSON.stringify({
      appname,
      layers: mapLayers,
    }),
  });

export const deleteLocationData = (appname, ids) =>
  fetch(`${process.env.REACT_APP_FOOD_API}/delete_delivery_locations`, {
    method: 'POST',
    mode: 'cors',
    headers,
    body: JSON.stringify({
      appname,
      ids,
    }),
  });
