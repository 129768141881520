import React from 'react';
import {
  List,
  People,
  PeopleOutline,
  AccessAlarm,
  Book,
  ListAlt,
  Poll,
  Subscriptions,
  History,
  MenuBook,
  MailOutline,
} from '@material-ui/icons';

import {
  GroupsIcon,
  UsersIcon,
  SettingsIcon,
  AttendeesIcon,
  CourseAttendeesIcon,
} from './../../components/icons';

import { HS_FEATURES_ENABLED_APPS } from '../../../../config';
import { EXPERT_NEW, APP_SUMO_EXPERT_PLANS } from '../../../../plans/constants';
import PaymentHistory from '../../../analytics/components/icons/PaymentHistory';

const urls = [
  {
    label: 'Groups & Subscriptions',
    href: '/members/groups',
    icon: <GroupsIcon />,
  },
  {
    label: 'Users & Subscribers',
    href: '/members/users',
    icon: <UsersIcon />,
  },
  {
    label: 'Diary',
    href: '/members/diary',
    icon: <MenuBook />,
  },
  {
    label: 'Events',
    href: '/members/events',
    icon: <AccessAlarm />,
  },
  {
    label: 'Custom Emails',
    href: '/members/custom-emails',
    icon: <MailOutline />,
  },
  {
    label: 'Settings',
    href: '/members/settings',
    icon: <SettingsIcon />,
  },
];

export const eventUrls = [
  {
    label: 'Event Organizer',
    href: '/members/member-bodies',
    icon: <List />,
  },
  {
    label: 'Event Organizer Admins',
    href: '/members/staff',
    icon: <People />,
  },
  {
    label: 'Events',
    href: '/members/events',
    icon: <AccessAlarm />,
  },

  {
    label: 'Courses',
    href: '/members/courses',
    icon: <Book />,
  },
  {
    label: 'Event Attendees',
    href: '/members/events-attendees',
    icon: <AttendeesIcon />,
  },
  {
    label: 'Course Attendees',
    href: '/members/courses-attendees',
    icon: <CourseAttendeesIcon />,
  },
];

export const diaryUrl = [
  {
    label: 'Diary',
    href: '/members/diary',
    icon: <MenuBook />,
  },
  {
    label: 'Diary Settings',
    href: '/members/diary-settings',
    icon: <SettingsIcon />,
  },
];

const horseScotlandUrls = [
  {
    label: 'Transaction History',
    href: '/members/transactions',
    icon: <History />,
  },
  {
    label: 'Member Body',
    href: '/members/member-bodies',
    icon: <List />,
  },

  {
    label: 'Staff Members',
    href: '/members/staff',
    icon: <People />,
  },
  {
    label: 'Events',
    href: '/members/events',
    icon: <AccessAlarm />,
  },
  {
    label: 'Courses',
    href: '/members/courses',
    icon: <Book />,
  },
  {
    label: 'CPDs',
    href: '/members/cpds',
    icon: <ListAlt />,
  },
  {
    label: 'Workforce Groups',
    href: '/members/types',
    icon: <GroupsIcon />,
    flexibleRoute: true,
  },
  {
    label: 'Workforce Members',
    href: '/members/workforce',
    icon: <PeopleOutline />,
    flexibleRoute: true,
  },
  {
    label: 'Groups',
    href: '/members/groups',
    icon: <GroupsIcon />,
  },
  {
    label: 'Users',
    href: '/members/users',
    icon: <UsersIcon />,
  },
  {
    label: 'Reports',
    href: '/members/analytics',
    icon: <Poll />,
  },
  {
    label: 'Settings',
    href: '/members/settings',
    icon: <SettingsIcon />,
  },
];

export const loginMenu = (app, plan, groups) => {
  if (HS_FEATURES_ENABLED_APPS.indexOf(app) > -1) {
    return horseScotlandUrls;
  }
  let menu = [...urls];
  // const appAdminLabel = 'App Admins';
  // const appAdminCustomEmailLabel = 'Custom Emails (App Admins)';

  // if (plan === EXPERT_NEW || APP_SUMO_EXPERT_PLANS.includes(plan)) {
  //   menu = [
  //     ...menu,
  //     {
  //       label: appAdminLabel,
  //       href: '/members/admins',
  //       icon: <People />,
  //     },
  //     {
  //       label: appAdminCustomEmailLabel,
  //       href: '/members/custom-emails-admins',
  //       icon: <People />,
  //     },
  //   ];
  // }

  if (groups.data.chunks.default && groups.data.chunks.default.diaryEnabled) {
    menu = [...menu];
  }

  if (
    groups.data.chunks.default &&
    groups.data.chunks.default.userSubscriptions
  ) {
    // adds transaction history as third item in menu (second index in array)
    menu.splice(2, 0, {
      label: 'Subscription Payments',
      href: '/members/transactions',
      icon: <PaymentHistory />,
    });

    return menu;
  }

  return menu;
};
