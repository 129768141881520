import { newValidator } from '../../../../utils';

const nameValidator = newValidator({
  minLength: 2,
  required: true,
});

const emailValidator = newValidator({
  type: 'email',
  required: true,
});

const defaultValidator = newValidator({
  minLength: 2,
  required: true,
});

const pgmolVaidator = newValidator({
  minLength: 1,
  required: true,
});

export const validateField = {
  name: value => nameValidator(value),
  adminEmail: value => emailValidator(value),
  postalAddress: value => defaultValidator(value),
  postCode: value => defaultValidator(value),
  adminUsername: value => defaultValidator(value),
  primaryContactName: value => defaultValidator(value),
  primaryContactEmail: value => emailValidator(value),
  primaryContactNumber: value => defaultValidator(value),
  address: value => pgmolVaidator(value),
  coach: value => pgmolVaidator(value),
  coach_phone: value => pgmolVaidator(value),
  coach_email: value => emailValidator(value),
  physio: value => pgmolVaidator(value),
  physio_phone: value => pgmolVaidator(value),
  physio_email: value => emailValidator(value),
  timetable: value => pgmolVaidator(value),
  hotel: value => pgmolVaidator(value),
  therapist: value => pgmolVaidator(value),
  therapist_phone: value => pgmolVaidator(value),
  therapist_email: value => pgmolVaidator(value),
  availability: value => pgmolVaidator(value),
  hotel_phone: value => pgmolVaidator(value),
  address_lng: value => pgmolVaidator(value),
  address_lat: value => pgmolVaidator(value),
  hotel_lng: value => pgmolVaidator(value),
  hotel_lat: value => pgmolVaidator(value),
  title: value => pgmolVaidator(value),
  content: value => pgmolVaidator(value),
  additional_info: value => defaultValidator(value),
};
