import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Panel = ({
  icon,
  title,
  action,
  padded,
  children,
  toolbarTitle,
  leftToolbarAction,
  rightToolbarAction,
  displayToolbar,
  style,
}) => (
  <div className="panel" style={style}>
    {title && (
      <div className="panel-header">
        <div className="panel-title-container">
          {icon}
          <h2 className="panel-header-title">{title}</h2>
        </div>
        {action}
      </div>
    )}
    {displayToolbar && (
      <div className="panel-toolbar">
        <div className="panel-toolbar-title-container">
          {leftToolbarAction}
          <h5 className="panel-toolbar-title">{toolbarTitle}</h5>
        </div>
        {rightToolbarAction}
      </div>
    )}
    <div
      className={`panel-scroll-area scroll-container ${
        padded ? 'padded' : ''
      } ${title ? 'title' : ''}`}
    >
      {children}
    </div>
  </div>
);

Panel.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  action: PropTypes.node,
  padded: PropTypes.bool,
  children: PropTypes.node.isRequired,
  toolbarTitle: PropTypes.string,
  leftToolbarAction: PropTypes.node,
  rightToolbarAction: PropTypes.node,
  displayToolbar: PropTypes.bool,
  style: PropTypes.object,
};

Panel.defaultProps = {
  icon: null,
  title: null,
  action: null,
  padded: null,
  toolbarTitle: null,
  leftToolbarAction: null,
  rightToolbarAction: null,
  displayToolbar: false,
  style: null,
};

export default Panel;
