import { connect } from 'react-redux';

import VimeoVideo from './VimeoVideo';
import { urlChange } from './actions';

const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: value =>
    dispatch(
      urlChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
      ),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VimeoVideo);
