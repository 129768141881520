import firebase from '../../../../firebase';
import plans from '../../../../plans/plansAppSumo';

export const hydrateAppSumoBilling = data => ({
  type: 'HYDRATE_APPSUMO_BILLING',
  data,
});

export const requestAppSumoBilling = () => ({ type: 'REQUEST_BILLING' });

export const getAppSumoBilling = () => (dispatch, getState) => {
  const { organisation } = getState().account;
  dispatch(requestAppSumoBilling());

  return firebase
    .database()
    .ref('billing')
    .child(organisation.key)
    .once('value', snapshot => {
      const data = snapshot.val();
      if (data) {
        let billingData = Object.values(data);
        if (billingData && billingData.length > 0) {
          billingData = billingData[billingData.length - 1];
          const billDetails = {
            type: 'appsumo',
            plan: billingData.plan && billingData.plan.id,
            amount: `$${billingData.amount / 100}`,
          };

          dispatch(hydrateAppSumoBilling(billDetails));
        }
      }
    });
};
