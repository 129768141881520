import { connect } from 'react-redux';

import Gallery from './Gallery';
import {
  fieldChange,
  designChange,
  updateCaptionText,
  updateCaptionToggle,
  updateDownloadToggle,
} from './actions';

const mapStateToProps = (state, ownProps) => {
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];

  return {
    ...ownProps,
    googleAnalytics: state.app.googleAnalytics,
    pages: layout
      ? layout.childRoutes.map(key => state.editor.data.alwaysData[key])
      : [],
    design: ownProps.component.design,
    layoutType: ownProps.component.layout,
    appname: ownProps.match.params.appname,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onDesignChange: (field, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        field,
        value,
      ),
    ),

  onCaptionToggle: value =>
    dispatch(
      updateCaptionToggle(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
      ),
    ),

  onDownloadToggle: value =>
    dispatch(
      updateDownloadToggle(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
      ),
    ),

  updateCaptionText: data =>
    dispatch(
      updateCaptionText(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        data,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
