import { connect } from 'react-redux';

import AppSumoWelcome from './AppSumoWelcome';
import { closeAppSumoWelcome, checkAppSumoWelcomeMsgOccurence } from './actions';

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    auth: state.account.auth,
    account: state.account.organisation,
    isAppSumAccount: state.account.organisation.appsumo_id !== '' ? true : false,
    appsumo_welcome: state.app.welcomeMsg.open,
    accountId: state.account.profile.account
});

const mapDisptachToProps = (dispatch, ownProps) => ({
    closeWelcome: (accountId) => dispatch(closeAppSumoWelcome(accountId)),
    checkAppSumoWelcomeMsgOccurence: (accountId) => dispatch(checkAppSumoWelcomeMsgOccurence(accountId))
});

export default connect(
    mapStateToProps,
    mapDisptachToProps,
)(AppSumoWelcome);
