import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import MenuItem from 'material-ui/MenuItem';
import Toggle from 'material-ui/Toggle';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';
import { Trans, useTranslation } from 'react-i18next';

import TextField from '../../../../../../../../../app/components/textField';
import SelectField from '../../../../../../../../../app/components/selectField';
import { FlatButton } from '../../../../../../../../../app/components/buttons';
import CircularValidation from '../../../../../../../../../app/components/circularValidation';
import { CircularProgress } from '../../../../../../../../../app/components/progress';
import { MARKETPLACE_FILTER_FIELD_OPTIONS } from '../../../../../../../../../config';

import './styles.css';

const CreateField = ({
  open,
  pending,
  error,
  onSubmit,
  onRequestClose,
  name,
  type,
  isRequired,
  onFieldChange,
  options,
  isMultiple,
}) => {
  const { t } = useTranslation();
  const [isOptions, setVisible] = React.useState(false);
  const onChangetype = payload => {
    setVisible(payload === 'Dropdown');
    onFieldChange('type', payload);
  };

  const displayError = error ? (
    <div>
      <strong>{error.message}</strong>
    </div>
  ) : null;

  const fieldOptions = MARKETPLACE_FILTER_FIELD_OPTIONS.map(data => (
    <MenuItem key={data.id} value={data.id} primaryText={data.value} />
  ));
  const form = (
    <div>
      <h5>
        <Trans>Create a field</Trans>
      </h5>
      <TextField
        id="name"
        placeholder={t('Name')}
        {...name}
        multiLine
        onChange={value => onFieldChange('name', value)}
        rightInputAction={
          <CircularValidation
            isValid={name.isValid}
            defaultMsg={t('Name is required')}
            errorMsg={t('Name must be at least 2 characters long')}
            showDefault={!name.value}
          />
        }
      />
      <SelectField
        className="offset-top"
        options={fieldOptions}
        label={t('Select Type')}
        onChange={(i, payload) => onChangetype(payload)}
        {...type}
        fullWidth
        rightInputAction={
          <CircularValidation
            isValid={type.isValid}
            defaultMsg={t('Type type is required')}
            errorMsg={t('Type type is required')}
            showDefault={!type.value}
          />
        }
      />
      {isOptions && (
        <React.Fragment>
          <TextField
            id="options"
            placeholder={t('Dropdown Options')}
            {...options}
            multiLine
            onChange={value => onFieldChange('options', value)}
            rightInputAction={
              <CircularValidation
                isValid={options.isValid}
                defaultMsg={t('Add multiple options using comma separated')}
                errorMsg={t('Options must be at least 2 characters long')}
                showDefault={!options.value}
              />
            }
          />
          <Toggle
            className="small-toggle"
            onToggle={(v, b) => onFieldChange('isMultiple', b)}
            toggled={isMultiple.value}
            label={t('Multiple Selection')}
          />
        </React.Fragment>
      )}
      <Toggle
        className="small-toggle"
        onToggle={(v, b) => onFieldChange('isRequired', b)}
        toggled={isRequired.value}
        label={t('Required')}
      />
    </div>
  );

  const buttonSubmit = !pending ? (
    <FlatButton
      label={t('Create')}
      onClick={onSubmit}
      disabled={
        !name.isValid ||
        !type.isValid ||
        (type.value === 'Dropdown' && !options.isValid)
      }
      primary
    />
  ) : (
    <CircularProgress size={18} />
  );

  return (
    <Dialog open={open} onRequestClose={onRequestClose} autoScrollBodyContent>
      <div className="add-field">
        <div className="modal-controls-container">
          <IconButton onClick={onRequestClose}>
            <Close />
          </IconButton>
        </div>
        <div className="list-fieldset">{displayError}</div>
        {form}
        {buttonSubmit}
      </div>
    </Dialog>
  );
};

CreateField.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  name: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  options: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  type: PropTypes.shape({ value: PropTypes.string, isValid: PropTypes.bool })
    .isRequired,
  isMultiple: PropTypes.shape({
    value: PropTypes.bool,
  }).isRequired,
  isRequired: PropTypes.shape({
    value: PropTypes.bool,
  }).isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

CreateField.defaultProps = {
  error: null,
};

export default CreateField;
