import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialTextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { createFilterOptions } from '@material-ui/core';
import Container from '../../../../../app/components/container';
import TextField from '../../../../../app/components/textField';
import Checkbox from '../../../../../app/components/checkbox';
import { CircularProgress } from '../../../../../app/components/progress';
import { RaisedButton } from '../../../../../app/components/buttons';
import ErrorMessage from '../../../../../app/components/errorMessage';
import Legal from '../../legal';
import { industries } from '../../../constants';
import themeStyles from '../../../../../_export.scss';
import './styles.css';

const filter = createFilterOptions();
const useStyles = makeStyles(theme => ({
  //Autocomplete input styles to match with current text field styles
  root: props => ({
    position: 'relative !important',
    marginTop: '1em !important',
    '& .MuiInput-root': {
      borderBottom:
        props.isWorkfieldValid === false
          ? `1px solid rgb(244, 67, 54)`
          : '1px solid #D3D3D3',
      transform: 'scaleX(1)',
      transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
      '&::before': {
        borderBottom: '0px !important',
      },
      '&::after': {
        borderBottom: '0px !important',
      },
      '&:hover': {
        '&:not(.Mui-disabled)': {
          '&::before': {
            borderBottom: '0px !important',
          },
        },
      },
    },
    '& .MuiInputLabel-root': {
      color: '#D3D3D3 !important',
      fontSize: '20px',
    },
  }),
}));
const styles = {
  buttonRoot: {
    margin: '0 0 15px 0',
    width: 'fit-content',
  },
  RegisterLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.primaryTextColor,
  },
  textFieldUnderlineStyle: {
    borderColor: themeStyles.mainGrey,
  },
  textFieldLabelStyle: {
    color: themeStyles.mainGrey,
    fontSize: '20px',
  },
  textFieldStyle: {
    position: 'relative',
    marginTop: '14px',
  },
  errorText: {
    fontSize: 12,
    position: 'absolute',
    bottom: -30,
    height: 25,
    lineHeight: '12px',
    fontFamily: 'Raleway Karla sans-serif',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
  },
};

const NewUser = ({
  agreements,
  email,
  error,
  name,
  onFieldChange,
  onOpenLegalModal,
  onSubmit,
  password,
  pending,
  appsumoUser,
  appsumoUserError,
  workfield,
  isDealFuelUser,
}) => {
  //load recaptcha
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    document.body.appendChild(script);
    window.captchaVerificationCallback = checkCaptcha;
  }, []);

  const classes = useStyles({ isWorkfieldValid: workfield.isValid });
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const checkCaptcha = () => {
    const isCaptchaVerified = !!window.grecaptcha.getResponse();
    setCaptchaVerified(isCaptchaVerified);
  };

  const button = pending ? (
    <CircularProgress />
  ) : (
    <RaisedButton
      label={
        appsumoUser || isDealFuelUser
          ? 'Register & Activate Account'
          : 'Register'
      }
      id="user-signup"
      styleClass="user-signup-button"
      disabled={pending || !agreements.value || !captchaVerified}
      onClick={onSubmit}
      backgroundColor={themeStyles.secondaryTextColor}
      disabledBackgroundColor={themeStyles.primaryColorLight}
      style={styles.buttonRoot}
      labelStyle={styles.RegisterLabelStyle}
    />
  );

  const errorMessage = error && (
    <ErrorMessage>{error || appsumoUserError}</ErrorMessage>
  );

  const checkboxLabel = (
    <span>
      I have read and agree to Beezer&#39;s&nbsp;
      <br />
      <button
        className="inline-button underline-text"
        onClick={onOpenLegalModal('terms-and-conditions')}
      >
        Terms of Service
      </button>
      &nbsp; and{' '}
      <button
        className="inline-button underline-text"
        onClick={onOpenLegalModal('end-user-license')}
      >
        End User Licence
      </button>
    </span>
  );

  const reCaptchaSiteKey =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_RECAPTCHA_TEST_KEY
      : process.env.REACT_APP_RECAPTCHA_APP_KEY;

  return (
    <div>
      <Container>
        <Legal />
        <TextField
          id="name"
          placeholder="Full Name"
          {...name}
          onChange={onFieldChange('name')}
          labelStyle={styles.textFieldLabelStyle}
          underlineStyle={styles.textFieldUnderlineStyle}
          accessCheck={true}
        />

        <Autocomplete
          value={workfield.value}
          style={{ height: '72px', lineHeight: '24px' }}
          id="workfield"
          onChange={(event, newValue) => {
            if (newValue && newValue.name && !newValue.inputValue) {
              onFieldChange('workfield')(newValue.name);
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              onFieldChange('workfield')(newValue.inputValue);
            } else if (newValue === null) {
              onFieldChange('workfield')(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                suggestion: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          handleHomeEndKeys
          options={industries}
          getOptionLabel={option => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.suggestion;
            }
            // Regular option
            return option.name;
          }}
          freeSolo
          fullWidth
          renderInput={params => (
            <MaterialTextField
              {...params}
              InputProps={{
                ref: params.InputProps.ref,
                endAdornment: params.InputProps.endAdornment,
              }}
              classes={{ root: classes.root }}
              error={workfield.isValid === false}
              helperText={
                workfield.isValid === false ? '* This field is required' : ''
              }
              FormHelperTextProps={{ style: styles.errorText }}
              label="Choose your Industry"
              variant="standard"
            />
          )}
        />
        {appsumoUser && appsumoUser.activation_email ? (
          <TextField
            id="email"
            placeholder="Email"
            disabled={true}
            {...email}
            value={email.value}
            labelStyle={styles.textFieldLabelStyle}
            underlineStyle={styles.textFieldUnderlineStyle}
          />
        ) : (
          <TextField
            id="email"
            type="email"
            placeholder="Email"
            {...email}
            onChange={onFieldChange('email')}
            labelStyle={styles.textFieldLabelStyle}
            underlineStyle={styles.textFieldUnderlineStyle}
            accessCheck={true}
          />
        )}
        <TextField
          id="password"
          type="password"
          placeholder="Password"
          {...password}
          onChange={onFieldChange('password')}
          labelStyle={styles.textFieldLabelStyle}
          underlineStyle={styles.textFieldUnderlineStyle}
          accessCheck={true}
        />
        <div
          className="g-recaptcha captcha-box"
          data-sitekey={reCaptchaSiteKey}
          data-callback="captchaVerificationCallback"
          data-expired-callback="captchaVerificationCallback"
        ></div>

        <Checkbox
          id="agreements"
          checked={agreements.value}
          label={checkboxLabel}
          {...agreements}
          onChange={() => onFieldChange('agreements')(!agreements.value)}
        />

        {button}
        {errorMessage}
      </Container>
    </div>
  );
};

NewUser.propTypes = {
  agreements: PropTypes.object.isRequired,
  email: PropTypes.object.isRequired,
  error: PropTypes.string,
  name: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onOpenLegalModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  password: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired,
  isDealFuelUser: PropTypes.bool,
};

NewUser.defaultProps = {
  error: null,
  isDealFuelUser: false,
};

export default NewUser;
