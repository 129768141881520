import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AddAPhoto from 'material-ui/svg-icons/image/add-a-photo';
import FileUpload from 'material-ui/svg-icons/file/file-upload';
import themeStyles from '../../../../../_export.scss';

const Label = styled.span`
  font-size: ${props => (props.avatar ? 18 : 24)}px;
  font-family: 'Raleway';
  font-weight: normal;
  color: var(--primaryColor);
  line-height: 21px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ImagePreviewCTA = ({ children, avatar = false }) => (
  <LabelContainer>
    <FileUpload
      style={{
        width: 42,
        height: 42,
        marginBottom: 12,
      }}
      color={themeStyles.primaryColor}
    />
    <Label avatar={avatar}>{children}</Label>
  </LabelContainer>
);

ImagePreviewCTA.propTypes = {
  children: PropTypes.string.isRequired,
  avatar: PropTypes.bool,
};

ImagePreviewCTA.defaultProps = {
  avatar: false,
};

export default ImagePreviewCTA;
