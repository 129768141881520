import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';

import { FlatButton } from '../../../../app/components/buttons';

import './style.css';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      password2: '',
    };
  }

  onChange(prop, value) {
    this.setState({
      [prop]: value,
    });
  }

  submitPassword(state) {
    this.onChange('errorMessage', '');

    if (state.password !== state.password2) {
      this.onChange('errorMessage', 'Your passwords do not match');
    } else if (state.password.length <= 6) {
      this.onChange(
        'errorMessage',
        'Your password must be more than 6 characters long',
      );
    } else {
      this.props
        .handleResetPassword(this.props.oobCode, this.state.password)
        .then(() => this.props.history.push('/'))
        .catch(e => this.onChange('errorMessage', e.message));
    }
  }

  render() {
    const { errorMessage } = this.props;

    return (
      <div className="center">
        <h1>Reset your Password</h1>
        <div>
          <TextField
            type="password"
            id="password"
            onChange={(e, value) => this.onChange('password', value)}
            value={this.state.password}
            placeholder="Password"
          />
          <br />
          <TextField
            type="password"
            id="password2"
            onChange={(e, value) => this.onChange('password2', value)}
            value={this.state.password2}
            placeholder="Password"
          />
          <FlatButton
            onClick={() => this.submitPassword(this.state)}
            label="Submit"
            primary
            className="submit-button-border-overwrite"
          />
          {errorMessage && <p>{errorMessage}</p>}
          {this.state.errorMessage && <p>{this.state.errorMessage}</p>}
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  handleResetPassword: PropTypes.func.isRequired,
  oobCode: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  history: PropTypes.object,
};

export default ResetPassword;
