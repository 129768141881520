import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { useQuery } from 'react-query';

import { getAllFkAcademyGroups, getFkAcademyUser } from '../../api';
import { toggleFkAcademyUserDetailsDialog } from '../../actions';
import { CircularProgress } from '../../../../app/components/progress';
import FkGroupCard from '../FkGroupCard';

function UsersDetailsDialog({ match }) {
  const dispatch = useDispatch();
  const { appname } = match.params;

  const open = useSelector(
    ({ fkAcademy }) => fkAcademy.fkUserDetailsDialogOpen,
  );

  const { data: { data: groups } = {}, error, isLoading, isError } = useQuery(
    'fkAcademyGroups',
    () => getAllFkAcademyGroups(appname),
    {
      enabled: !!appname,
    },
  );

  const userId = useSelector(({ fkAcademy }) => fkAcademy.fkUserId);

  const { data: { data: user } = {} } = useQuery(
    ['fkAcademyUser', userId],
    () => getFkAcademyUser(appname, userId),
    { enabled: !!(userId && appname) },
  );

  if (isLoading) return <CircularProgress />;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => dispatch(toggleFkAcademyUserDetailsDialog(false))}
    >
      <AppBar>
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>
            Assign/remove from groups (Note: This will not affect users slots,
            but will reduce total slots available for group)
          </h2>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => dispatch(toggleFkAcademyUserDetailsDialog(false))}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent
        style={{
          marginTop: 64,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(290px, 300px))',
          gap: 15,
          placeContent: 'flex-start',
        }}
      >
        {!groups.length && <h2>No groups yet!</h2>}
        {groups &&
          groups.length &&
          groups.map(group => (
            <FkGroupCard
              key={group.key}
              groupId={group.key}
              availableSlots={group.availableSlots}
              filledSlots={group.filledSlots}
              coach={group.coach}
              description={group.description}
              imageUrl={group.imageUrl}
              title={group.title}
              parents={group.parents}
              children={group.children}
              user={user}
            />
          ))}
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(UsersDetailsDialog);
