import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import RenderToLayer from 'material-ui/internal/RenderToLayer';
import { withRouter } from 'react-router-dom';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { Provider } from 'react-redux';
import Payment from '../payment';
import { SimpleToolbar } from '../../../../app/modules/toolbar';
import { store } from '../../../../';

class PayForAddon extends Component {
  state = {
    billingPeriod: null,
  };
  componentDidMount() {
    if (this.props.location.query === undefined) {
      this.props.history.goBack();
    }
  }

  getPrice = () => {
    if (this.props.location.query === undefined) {
      this.props.history.goBack();
      return;
    }
    const price = this.props.location.query.component.price;
    const priceToPay = parseFloat(price).toFixed(2);

    return priceToPay;
  };

  renderThankYouPage = component => {
    const { history, match } = this.props;

    switch (component) {
      case 'PDFViewer':
        window.location.replace(
          `https://www.beezer.com/thank-you-page-pdf-library-addon-plan`,
        );
        break;
      case 'Feedback':
        window.location.replace(
          `https://www.beezer.com/thank-you-page-video-feedback-addon-plan`,
        );
        break;
      default:
        history.push(`/${match.params.appname}/editor`);
        break;
    }
  };

  callback = component => {
    this.renderThankYouPage(component);
  };

  handleAddonSubscription = billingPeriod => {
    this.setState({
      billingPeriod,
    });
    const { intl, stripe, onSubscribe } = this.props;
    const price_details = this.props.location.query.price_details;
    const price =
      billingPeriod === 'monthly'
        ? price_details.price
        : price_details.year_price;

    const productId =
      billingPeriod === 'monthly'
        ? price_details.monthly_product_id
        : price_details.yearly_product_id;
    const data = {
      component: price_details.componentName,
      period: billingPeriod,
      callback: () => this.callback(price_details.componentName),
      coupon: null,
      price,
      componentName: price_details.title,
      currency: intl.currency,
      stripe,
      productId,
    };
    return this.props.onSubscribe(data);
  };

  paymentAuthError = () => {
    this.props.displayPaymentError(
      this.props.t(
        'Your subscription payment was unsuccessful. Try again later',
      ),
    );
  };

  reloadUserAccount = async () => {
    this.props.reloadUserAccount({
      callback: () => this.callback(this.state.billingPeriod),
    });
  };
  renderContent = () => {
    const { currency, location, t } = this.props;

    const productId = location && location.query && location.query.product_id;
    const priceToPay = this.getPrice();
    const componentName =
      this.props.location.query && this.props.location.query.component.name;
    return (
      <Payment
        title={t('Subscribe to use this component!')}
        subtitle={`${t('Component Name')} - ${componentName} ${t('Component')}`}
        cost={priceToPay}
        onSubmit={this.handleAddonSubscription}
        submitLabel={t('Subscribe')}
        productId={productId}
        showProrating={false}
        prorating={this.props.prorating}
        isCurrentPlanDIY={false}
        currency={currency}
        stripe={this.props.stripe}
        stripeAuthSuccessCallback={this.reloadUserAccount}
        stripeAuthErrorCallback={this.paymentAuthError}
        enablePromoBox={false}
        typeOfPayment="specialAddons"
      />
    );
  };

  renderLayer = () => (
    <Provider store={store}>
      <Elements stripe={this.props.stripe}>
        <div className="overlay">
          <SimpleToolbar />
          {this.renderContent()}
        </div>
      </Elements>
    </Provider>
  );

  render() {
    return (
      <RenderToLayer
        render={this.renderLayer}
        useLayerForClickAway={false}
        open
      />
    );
  }
}

const EnhancedPayForAddon = withTranslation()(PayForAddon);

const InjectedSubscribe = props => (
  <ElementsConsumer>
    {({ elements, stripe }) => (
      <EnhancedPayForAddon elements={elements} stripe={stripe} {...props} />
    )}
  </ElementsConsumer>
);

export default withRouter(InjectedSubscribe);
