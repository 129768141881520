import React from 'react';
import PropTypes from 'prop-types';
import CloudDone from 'material-ui/svg-icons/file/cloud-done';
import CloudUpload from 'material-ui/svg-icons/file/cloud-upload';
import Dropzone from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';

import { downloadCSV } from '../../../../../utils';

const UploadForm = ({ onFileUpload, data, error, duplicateContacts }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="upload-container">
        <Dropzone
          className="dropzone"
          onDrop={onFileUpload}
          multiple={false}
          accept="text/csv,application/vnd.ms-excel"
        >
          <div className="dropzone-title">
            {data ? t('Uploaded') : t('Drop your files here')}
          </div>
          {data ? (
            <CloudDone />
          ) : (
            <div>
              <CloudUpload />
              <div className="dropzone-text">
                <Trans>or</Trans>{' '}
                <u>
                  <Trans>select a file from your computer</Trans>
                </u>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      <div className="instructions">
        <p>
          <Trans>Please use csv file type, you can download a template</Trans>{' '}
          <span
            onClick={() =>
              downloadCSV(
                'Name,Email,Telephone\nJane Doe,jane.doe@example.com,44123456789\n',
                'contacts-template',
              )
            }
            role="button"
            tabIndex={0}
          >
            <Trans>here</Trans>
          </span>
        </p>
      </div>
      {data ? (
        <p className="confirmation-message">
          <Trans>Your file contains:</Trans> <strong>{data.length}</strong>{' '}
          <Trans>contacts.</Trans>
        </p>
      ) : null}
      {error ? <p className="error-text">{error.message}</p> : null}
      {Object.keys(duplicateContacts).length ? (
        <p className="error-text">
          <Trans>A contact already exist with the same email.</Trans> <br />
          <Trans>Importing will create duplicates.</Trans>
        </p>
      ) : null}
    </div>
  );
};

UploadForm.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
  data: PropTypes.array,
  error: PropTypes.object,
  duplicateContacts: PropTypes.object.isRequired,
};

UploadForm.defaultProps = {
  data: null,
  error: null,
};

export default UploadForm;
