import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import InputLabel from '@material-ui/core/InputLabel';
import { Trans, useTranslation } from 'react-i18next';
import themeStyles from '../../../../_export.scss';
import { FlatButton } from '../../../../app/components/buttons';
import ErrorMessage from '../../../../app/components/errorMessage';
import './styles.css';

const CreditCardForm = ({
  number,
  cvc,
  expiry,
  onChange,
  useExistingCard,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const CARD_OPTIONS = {
    style: {
      base: {
        iconColor: themeStyles.primaryColor,
        color: themeStyles.primaryColor,
        fontWeight: 500,
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: themeStyles.primaryColorDark,
        },
        '::placeholder': {
          color: themeStyles.primaryColorLight,
        },
      },
      invalid: {
        iconColor: themeStyles.accentColor,
        color: themeStyles.accentColor,
      },
    },
  };
  const stripe = useStripe();
  const elements = useElements();
  const [cardNumberError, setCardNumberError] = useState(null);
  const [cvcError, setCvcError] = useState(null);
  const [expiryError, setExpiryError] = useState(null);

  return (
    <div className="card-form">
      <div className="card-number-container">
        <InputLabel color="primary">
          <Trans>Card Number</Trans>
        </InputLabel>
        <CardNumberElement
          onChange={e => {
            let numberError = e.error;
            if (numberError) {
              setCardNumberError(numberError.message);
              onChange('number', null, numberError);
            }
            if (e.complete) {
              setCardNumberError(null);
              let cardNumber = elements.getElement(CardNumberElement);
              onChange('number', cardNumber, numberError);
            }
          }}
          options={{ ...CARD_OPTIONS, showIcon: true }}
        />
        {cardNumberError && <ErrorMessage>{cardNumberError}</ErrorMessage>}
      </div>
      <div className="card-cvc-expiry-container">
        <div className="card-expiry-container">
          <InputLabel color="primary">
            <Trans>Expiry Date</Trans>
          </InputLabel>
          <CardExpiryElement
            onChange={e => {
              let expiryError = e.error;
              if (expiryError) {
                setExpiryError(expiryError.message);
                onChange('expiry', null, expiryError);
              }
              if (e.complete) {
                setExpiryError(null);
                let cardExpiry = elements.getElement(CardExpiryElement);
                onChange('expiry', cardExpiry, null);
              }
            }}
            options={CARD_OPTIONS}
          />
          {expiryError && <ErrorMessage>{expiryError}</ErrorMessage>}
        </div>
        <div className="card-cvc-container">
          <InputLabel color="primary">
            <Trans>CVC Number</Trans>
          </InputLabel>
          <CardCvcElement
            onChange={e => {
              let cvcError = e.error;
              if (cvcError) {
                setCvcError(cvcError.message);
                onChange('cvc', null, cvcError);
              }
              if (e.complete) {
                setCvcError(null);
                let cardCvc = elements.getElement(CardCvcElement);
                onChange('cvc', cardCvc, cvcError);
              }
            }}
            options={CARD_OPTIONS}
          />
          {cvcError && <ErrorMessage>{cvcError}</ErrorMessage>}
        </div>
      </div>
      <div>
        {useExistingCard && (
          <FlatButton label={t('Cancel')} onClick={useExistingCard} />
        )}
        {onSubmit && (
          <FlatButton
            label={t('Submit')}
            onClick={() => onSubmit(stripe)}
            primary
            disabled={
              !number.value ||
              !cvc.value ||
              !expiry.value ||
              number.error ||
              cvc.error ||
              expiry.error
            }
          />
        )}
      </div>
    </div>
  );
};
CreditCardForm.propTypes = {
  number: PropTypes.object.isRequired,
  cvc: PropTypes.object.isRequired,
  expiry: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  useExistingCard: PropTypes.func,
  onSubmit: PropTypes.func,
};

CreditCardForm.defaultProps = {
  error: null,
  useExistingCard: null,
  onSubmit: null,
};

export default CreditCardForm;
