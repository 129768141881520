import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import { FlatButton } from '../buttons';
import { Trans, useTranslation } from 'react-i18next';

const NotificationModal = ({
  showNotification,
  closeNotification,
  text,
  action,
}) => {
  const { t } = useTranslation();

  const actions = action
    ? action
    : [
        <FlatButton
          label={t('Close')}
          onClick={() => {
            closeNotification();
          }}
          primary
        />,
      ];
  return (
    <Dialog
      actions={actions}
      title={t('Notice')}
      open={showNotification}
      onRequestClose={closeNotification}
      autoScrollBodyContent
      style={{ textAlign: 'center' }}
      actionsContainerStyle={{ textAlign: 'center' }}
      contentClassName="customModal"
      paperClassName="customModalPaper"
    >
      <div>
        <div>{text}</div>
      </div>
    </Dialog>
  );
};

NotificationModal.propTypes = {
  showNotification: PropTypes.bool,
  closeNotification: PropTypes.bool,
  text: PropTypes.element.isRequired,
};

NotificationModal.defaultProps = {
  showNotification: false,
  closeNotification: null,
};

export default NotificationModal;
