import { combineReducers } from 'redux';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';

import { escapeSepcialChars } from '../../utils';
import editContact from './editContact/reducer';
import newContact from './newContact/reducer';
import {
  LOAD_CONTACTS,
  HYDRATE_CONTACTS,
  SELECT_CONTACT,
  SELECT_ALL_CONTACTS,
  DESELECT_ALL_CONTACTS,
  SEARCH_CONTACTS,
  SELECT_GROUP,
  SELECT_ALL_GROUPS,
  DESELECT_ALL_GROUPS,
  SELECT_WORKFORCE_GROUP,
  REQUEST_GROUPS,
  LOAD_GROUPS,
  HYDRATE_GROUPS,
  RECEIVE_WORKFORCE_GROUPS,
  RECEIVE_GROUPS,
  REQUEST_USERS,
  RECEIVE_USERS,
  SET_PAGE_TYPE,
  NEW_CONTACT_ADDED,
  LIST_UPLOAD_COMPLETE,
  CONTACT_DELETED,
  CONTACT_UPDATED,
} from './types';

const initialState = {
  chunks: {},
  // items current displayed
  items: [],
  // full array of items
  original: [],
  // items currently selected
  selected: [],
  query: '',
  pageType: null,
  pending: true,

  workforceGroupsChunks: {},
  // items current displayed
  workforceGroupsItems: [],
  // full array of items
  workforceGroupsSelected: [],
  workforceGroupsFilteredChunks: {},
  workforceGroupsPending: true,
  workforceGroupsError: null,

  groupChunks: {},
  groupDataPending: true,
  groupFilteredChunks: {},
  groupItems: [],
  groupSelected: [],
  groupOriginal: [],
  filteredChunks: {},
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CONTACTS:
      return {
        ...state,
        pending: true,
      };

    // save data in chunks as is and extract keys into items array to allow sort filtering
    case HYDRATE_CONTACTS: {
      const keys = _keys(action.data);

      return {
        ...state,
        chunks: action.data,
        items: keys,
        // make a copy of the item keys to preserve original order
        // to allow us to filter on items directly later
        original: [...keys],
        pending: false,
      };
    }

    case LOAD_GROUPS: {
      return {
        ...state,
        workforceGroupsPending: true,
      };
    }

    case HYDRATE_GROUPS: {
      const groups = action.data ? action.data.groups : {};
      const keys = _keys(groups);

      return {
        ...state,
        groupFilteredChunks: groups,
        groupItems: keys,
        // make a copy of the item keys to preserve original order
        // to allow us to filter on items directly later
        groupOriginal: [...keys],
        groupDataPending: false,
      };
    }

    case RECEIVE_WORKFORCE_GROUPS: {
      return {
        ...state,
        workforceGroupsChunks: action.value,
        workforceGroupsSelected: [],
        workforceGroupsFilteredChunks: action.value,
        workforceGroupsItems: _keys(action.value),
        workforceGroupsPending: false,
        workforceGroupsError: null,
      };
    }

    case REQUEST_GROUPS:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_GROUPS:
      return {
        ...state,
        chunks: action.value,
        selected: [],
        filteredChunks: action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };

    case REQUEST_USERS:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_USERS: {
      const filteredChunks = {};
      if (Object.keys(state.filteredChunks).length) {
        Object.keys(action.value).forEach(key => {
          filteredChunks[key] = action.value[key];
          filteredChunks[key].selected =
            state.filteredChunks[key] && state.filteredChunks[key].selected
              ? state.filteredChunks[key].selected
              : false;
        });
      }
      return {
        ...state,
        chunks: action.value,
        filteredChunks: Object.keys(filteredChunks).length
          ? filteredChunks
          : action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };
    }

    case SEARCH_CONTACTS: {
      const getItems = () => {
        // escape special chars
        const escapedQuery = escapeSepcialChars(action.query);
        const searchExp = new RegExp(`^${escapedQuery}.*$`, 'i');

        return state.original.filter(
          key =>
            searchExp.test(state.chunks[key].name) ||
            searchExp.test(state.chunks[key].email) ||
            searchExp.test(state.chunks[key].mobile),
        );
      };

      return {
        ...state,
        items: action.query === '' ? [...state.original] : getItems(),
        query: action.query,
      };
    }

    // case CONTACTS_FILTER_TAGS: {
    //   const getItems = () =>
    //     state.items.filter(item => item.tags.includes(action.tag));

    //   return {
    //     ...state,
    //     items: getItems(),
    //   };
    // }

    // case CONTACTS_FILTER: {
    //   const filterArray = item => {
    //     if (item[action.filter]) {
    //       return true;
    //     }

    //     return false;
    //   };

    //   return {
    //     ...state,
    //     items: state.items.filter(filterArray),
    //   };
    // }

    // case 'CONTACTS_ORDER': {
    //   let sortArray = (a, b) => {
    //     if (
    //       state.data.chunks[a][action.filter] <
    //       state.data.chunks[b][action.filter]
    //     )
    //       return -1;
    //     if (
    //       state.data.chunks[a][action.filter] >
    //       state.data.chunks[b][action.filter]
    //     )
    //       return 1;
    //     return 0;
    //   };

    //   if (action.order === 'DESC') {
    //     sortArray = (a, b) => {
    //       if (a[action.filter] < b[action.filter]) return 1;
    //       if (a[action.filter] > b[action.filter]) return -1;
    //       return 0;
    //     };
    //   }

    //   return {
    //     ...state,
    //     items: state.items.sort(sortArray),
    //   };
    // }

    // case 'CONTACTS_RESET':
    //   return {
    //     ...state,
    //     items: [...state.original],
    //   };

    // Toggles whether a contact is selected
    case SELECT_CONTACT: {
      // if the prevState is false or undefined, we are selecting,
      // otherwise if true we are deselecting

      const prevState = state.chunks[action.key].selected;

      return {
        ...state,
        chunks: {
          ...state.chunks,
          [action.key]: {
            ...state.chunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case SELECT_GROUP: {
      // if the prevState is false or undefined, we are selecting,
      // otherwise if true we are deselecting
      const prevState =
        state.groupFilteredChunks[action.key] &&
        state.groupFilteredChunks[action.key].groupSelected;

      return {
        ...state,
        groupFilteredChunks: {
          ...state.groupFilteredChunks,
          [action.key]: {
            ...state.groupFilteredChunks[action.key],
            groupSelected: !prevState,
            selected: !prevState,
          },
        },
        groupSelected:
          prevState === true
            ? _without(state.groupSelected, action.key)
            : state.groupSelected.concat(action.key),
      };
    }

    case SELECT_WORKFORCE_GROUP: {
      // if the prevState is false or undefined, we are selecting,
      // otherwise if true we are deselecting
      const prevState =
        state.workforceGroupsFilteredChunks[action.key] &&
        state.workforceGroupsFilteredChunks[action.key].groupSelected;

      return {
        ...state,
        workforceGroupsFilteredChunks: {
          ...state.workforceGroupsFilteredChunks,
          [action.key]: {
            ...state.workforceGroupsFilteredChunks[action.key],
            groupSelected: !prevState,
            selected: !prevState,
          },
        },
        workforceGroupsSelected:
          prevState === true
            ? _without(state.workforceGroupsSelected, action.key)
            : state.workforceGroupsSelected.concat(action.key),
      };
    }

    case SELECT_ALL_CONTACTS: {
      // we only select all from the items current displayed
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: true,
      }));
      const selected = _union(state.selected, _keys(filteredChunks));

      return {
        ...state,
        chunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_CONTACTS:
      return {
        ...state,
        chunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    case SELECT_ALL_GROUPS: {
      // we only select all from the items current displayed
      const selectedChunks = _mapValues(state.groupFilteredChunks, chunk => ({
        ...chunk,
        selected: true,
      }));

      const groupSelected = _union(
        state.groupSelected,
        _keys(state.groupFilteredChunks),
      );

      return {
        ...state,
        groupChunks: {
          ...state.groupChunks,
          ...selectedChunks,
        },
        groupSelected,
        groupSelectAll: true,
      };
    }

    case DESELECT_ALL_GROUPS:
      return {
        ...state,
        groupChunks: _mapValues(state.groupChunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        groupSelected: [],
        groupSelectAll: false,
      };

    // action dispatched when contact is added in newContact/actions
    case NEW_CONTACT_ADDED:
      return {
        ...state,
        chunks: {
          ...state.chunks,
          [action.key]: action.data,
        },
        items: state.items.concat(action.key),
        original: state.original.concat(action.key),
      };

    case LIST_UPLOAD_COMPLETE: {
      const keys = _keys(action.data);

      return {
        ...state,
        chunks: {
          ...state.chunks,
          ...action.data,
        },
        original: state.original.concat(keys),
        items: state.items.concat(keys),
      };
    }

    case CONTACT_DELETED:
      return {
        ...state,
        chunks: _omit(state.chunks, action.key),
        original: _without(state.original, action.key),
        items: _without(state.items, action.key),
        selected: _without(state.selected, action.key),
      };

    case CONTACT_UPDATED:
      return {
        ...state,
        chunks: {
          ...state.chunks,
          [action.key]: {
            ...state.chunks[action.key],
            name: action.name,
            email: action.email,
            mobile: action.mobile,
            country: action.country,
          },
        },
      };

    case SET_PAGE_TYPE:
      return {
        ...state,
        pageType: action.pageType,
      };

    default:
      return state;
  }
};

export default combineReducers({
  data: contactsReducer,
  editContact,
  newContact,
});
