import React from 'react';
import PropTypes from 'prop-types';

import {
  EndUserLicense,
  TermsAndConditions,
  DragonsDenOfferTerms,
  InstagramTermsAndConditions,
} from './components';
import './styles.css';

const modals = {
  'end-user-license': EndUserLicense,
  'terms-and-conditions': TermsAndConditions,
  'dragons-den-terms': DragonsDenOfferTerms,
  instagram: InstagramTermsAndConditions,
};

const Legal = ({ open, selectedModal, handleClose }) => {
  if (!open || !modals[selectedModal]) {
    return null;
  }

  const Modal = modals[selectedModal];

  return <Modal open={open} handleClose={handleClose} />;
};

Legal.propTypes = {
  open: PropTypes.bool,
  selectedModal: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};

Legal.defaultProps = {
  open: false,
  selectedModal: null,
};

export default Legal;
