import React, { Component } from 'react';
import PropTypes from 'prop-types';

class VerifyEmail extends Component {
  componentWillMount() {
    this.props.handleVerifyEmail(this.props.oobCode);
  }

  render() {
    const { message } = this.props;
    return (
      <div className="center">
        <h1>Email Verification</h1>
        <div>{message && <p>{message}</p>}</div>
      </div>
    );
  }
}

VerifyEmail.propTypes = {
  handleVerifyEmail: PropTypes.func.isRequired,
  oobCode: PropTypes.string.isRequired,
  message: PropTypes.string,
};

export default VerifyEmail;
