import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import MenuItem from 'material-ui/MenuItem';
import { Trans, withTranslation } from 'react-i18next';

import TextField from '../../../../../app/components/textField';
import SelectField from '../../../../../app/components/selectField';
import { FlatButton } from '../../../../../app/components/buttons';
import Container from '../../../../../app/components/container';
import SectionHeader from '../../../components/sectionHeader';
import { whitelabeledHost } from '../../../../../utils/whitelabeledHost';

class PaypalSetting extends Component {
  static propTypes = {
    paypal: PropTypes.shape({
      sandboxId: PropTypes.string,
      productionId: PropTypes.string,
      currency: PropTypes.string,
      testMode: PropTypes.bool,
      showPaymentHistory: PropTypes.bool,
    }),
    modified: PropTypes.bool.isRequired,
    updateValue: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isAppAdmin: PropTypes.bool,
    isSubAdminWithMulipleApps: PropTypes.bool,
  };

  static defaultProps = {
    meta: {
      sandboxId: '',
      productionId: '',
      currency: '',
      testMode: false,
    },
    paypal: {
      sandboxId: '',
      productionId: '',
      currency: '',
      testMode: false,
      showPaymentHistory: false,
    },
    modified: false,
    isAppAdmin: false,
    isSubAdminWithMulipleApps: false,
  };

  componentWillMount() {
    // this.props.loadData()
  }

  isValidForm = () => {
    const { modified } = this.props;
    return modified;
  };

  handleChange = attr => value => this.props.updateValue(attr, value);

  render() {
    const { paypal, isAppAdmin, isSubAdminWithMulipleApps, t } = this.props;
    const isWhiteLabeledHost = whitelabeledHost();

    const currencies = [
      { text: 'Australian dollar', code: 'AUD' },
      { text: 'Canadian dollar', code: 'CAD' },
      { text: 'Czech koruna', code: 'CZK' },
      { text: 'Danish krone', code: 'DKK' },
      { text: 'Euro', code: 'EUR' },
      { text: 'Hong Kong dollar', code: 'HKD' },
      { text: 'Israeli new shekel', code: 'ILS' },
      { text: 'Mexican peso', code: 'MXN' },
      { text: 'New Zealand dollar', code: 'NZD' },
      { text: 'Norwegian krone', code: 'NOK' },
      { text: 'Philippine peso', code: 'PHP' },
      { text: 'Polish złoty', code: 'PLN' },
      { text: 'Pound sterling', code: 'GBP' },
      { text: 'Russian ruble', code: 'RUB' },
      { text: 'Singapore dollar', code: 'SGD' },
      { text: 'Swedish krona', code: 'SEK' },
      { text: 'Swiss franc', code: 'CHF' },
      { text: 'Thai baht', code: 'THB' },
      { text: 'United States dollar', code: 'USD' },
    ];

    return (
      <Container>
        <a
          style={{
            color: 'blue',
            padding: '10px 30px',
            fontWeight: 'bold',
          }}
          href={
            isAppAdmin || isSubAdminWithMulipleApps || isWhiteLabeledHost
              ? 'https://kb-articles.s3.eu-west-2.amazonaws.com/Pay+Now+Component.pdf'
              : 'https://beezer.zendesk.com/hc/en-us/articles/360042628252-PayNow-Component'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Trans>Click here to learn how to setup your paypal account.</Trans>
        </a>

        <SectionHeader
          title={t('Sandbox Id')}
          tooltip={t('Add sandbox id of your paypal account')}
        />
        <TextField
          id="sandboxId"
          value={paypal ? paypal.sandboxId : ''}
          onChange={this.handleChange('sandboxId')}
          required
        />

        <SectionHeader
          title={t('Production Id')}
          tooltip={t('Add production id of your paypal account')}
        />
        <TextField
          id="productionId"
          value={paypal ? paypal.productionId : ''}
          onChange={this.handleChange('productionId')}
          required
        />

        <SectionHeader
          title={t('Currency')}
          tooltip={t('Add supported currency for your app')}
        />
        <SelectField
          className="offset-top"
          label={t('Select Currency')}
          options={currencies.map(currency => (
            <MenuItem
              key={currency.code}
              value={currency.code}
              primaryText={currency.text}
            />
          ))}
          value={paypal ? paypal.currency : ''}
          onChange={(i, payload) => {
            this.handleChange('currency')(payload);
          }}
        />

        <SectionHeader
          title={t('Test mode')}
          tooltip={t('Enable test mode for your paypal account')}
        />
        <Toggle
          label=""
          toggled={paypal ? paypal.testMode : false}
          onToggle={(i, toggled) => this.handleChange('testMode')(toggled)}
          className="full-width toggle-days-sec"
        />

        <SectionHeader
          title={t('Payment History')}
          tooltip={t('Show/hide the payment history in your app')}
        />
        <Toggle
          label=""
          toggled={paypal ? paypal.showPaymentHistory : false}
          onToggle={(i, toggled) =>
            this.handleChange('showPaymentHistory')(toggled)
          }
          className="full-width toggle-days-sec"
        />

        <FlatButton
          label={t('Save')}
          onClick={() => this.props.onSubmit(paypal)}
          disabled={!this.isValidForm()}
          primary
        />
      </Container>
    );
  }
}

export default withTranslation()(PaypalSetting);
