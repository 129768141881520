import React from 'react';
import { Route, Switch } from 'react-router-dom';

import EditCourseModal from './module';

function EditCourseModalRouter() {
  return (
    <Switch>
      <Route path="/:appname/staff/courses/:memberbody/:course?" exact>
        {props => <EditCourseModal {...props} />}
      </Route>
      <Route path="/:appname/members/courses/:memberbody/:course?" exact>
        {props => <EditCourseModal {...props} />}
      </Route>
    </Switch>
  );
}

export default EditCourseModalRouter;
