import { connect } from 'react-redux';

import Form from './Form';
import {
  getFormData,
  watchFormData,
  removeFormDataWatcher,
  getAllForms,
  selectResponse,
  selectAllResponses,
  deselectAllResponses,
  filterFormData,
  favouriteSelected,
  deleteSelected,
  sortTableData,
  changeSelectedForm,
} from './actions';
import { openFormDetailDialog } from './formDetails/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...ownProps.match.params,
  ...state.analytics.form,
  ...state.analytics.form.data,
  accountId: state.account.auth.profile.account,
  subscription:
    state.account.subscriptions.items[ownProps.match.params.appname] || {},
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getFormData: () => dispatch(getFormData(ownProps.match.params.appname)),
  watchFormData: () => dispatch(watchFormData(ownProps.match.params.appname)),
  removeFormDataWatcher: () =>
    dispatch(removeFormDataWatcher(ownProps.match.params.appname)),
  getAllForms: () => dispatch(getAllForms(ownProps.match.params.appname)),
  onSelect: key => dispatch(selectResponse(key)),
  onSelectAllResponses: () => dispatch(selectAllResponses()),
  onDeselectAllResponses: () => dispatch(deselectAllResponses()),
  onOpenFormDetail: key => dispatch(openFormDetailDialog(key)),
  onFilterFormData: filter => dispatch(filterFormData(filter)),
  onFavouriteSelected: selected =>
    dispatch(favouriteSelected(ownProps.match.params.appname, selected)),
  onDeleteSelected: selected =>
    dispatch(deleteSelected(ownProps.match.params.appname, selected)),
  onSort: cols => dispatch(sortTableData(cols)),
  changeSelectedForm: (allForms, formId) =>
    dispatch(changeSelectedForm(allForms, formId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
