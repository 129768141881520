import { SELECT_VENDOR, DESELECT_VENDOR } from './types';

export const selectVendor = vendor => ({
  type: SELECT_VENDOR,
  value: vendor,
});

export const deselectVendor = () => ({
  type: DESELECT_VENDOR,
});
