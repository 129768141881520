import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Badge = ({ label }) => <span className={`badge ${label}`}>{label}</span>;

Badge.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Badge;
