/* eslint-disable no-case-declarations */
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import _pickBy from 'lodash/pickBy';

import {
  TOGGLE_REGIONAL_PERFORMANCE_CENTRE_MODAL,
  NEW_REGIONAL_PERFORMANCE_FIELD_CHANGE,
  ADD_NEW_REGIONAL_PERFORMANCE_CENTRE,
  NEW_REGIONAL_PERFORMANCE_CENTRE_ADDED,
  REQUEST_REGIONAL_PERFORMANCE_CENTRE,
  RECEIVE_REGIONAL_PERFORMANCE_CENTRE,
  SELECT_PERFORMANCE_CENTRE,
  SELECT_ALL_PERFORMANCE_CENTRES,
  DESELECT_ALL_PERFORMANCE_CENTRES,
  FILTER_PERFORMANCE_CENTRE_DATA,
  OPEN_REGIONAL_PERFORMANCE_CENTRE_DIALOG_UPDATE_DATA,
  NEW_REGIONAL_PERFORMANCE_SERVICES_INFO_FIELD_CHANGE,
  TOGGLE_REGIONAL_SNACKBAR,
  RESET_REGIONAL_PERFORMANCE_SERVICES_INFO_FIELD,
} from './actions';

const initialState = {
  pending: false,
  chunks: {},
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  selected: [],
  modalOpen: false,
  selectAll: false,
  isUpdate: false,
  rowId: '',
  location: {
    value: '',
    isValid: null,
  },
  address: {
    value: '',
    isValid: null,
  },
  address_lat: {
    value: '',
    isValid: null,
  },
  address_lng: {
    value: '',
    isValid: null,
  },
  services: {
    value: [],
    isValid: null,
  },
  servicesInfo: {
    value: {
      nutrition: {
        contact_1_name: {
          value: '',
          isValid: null,
        },
        contact_1_phone: {
          value: '',
          isValid: null,
        },
        contact_1_email: {
          value: '',
          isValid: null,
        },
        contact_2_name: {
          value: '',
          isValid: null,
        },
        contact_2_phone: {
          value: '',
          isValid: null,
        },
        contact_2_email: {
          value: '',
          isValid: null,
        },
        timetable: {
          value: '',
          isValid: null,
        },
      },
      pitch_session: {
        contact_1_name: {
          value: '',
          isValid: null,
        },
        contact_1_phone: {
          value: '',
          isValid: null,
        },
        contact_1_email: {
          value: '',
          isValid: null,
        },
        contact_2_name: {
          value: '',
          isValid: null,
        },
        contact_2_phone: {
          value: '',
          isValid: null,
        },
        contact_2_email: {
          value: '',
          isValid: null,
        },
        timetable: {
          value: '',
          isValid: null,
        },
      },
      physiotherapy: {
        contact_1_name: {
          value: '',
          isValid: null,
        },
        contact_1_phone: {
          value: '',
          isValid: null,
        },
        contact_1_email: {
          value: '',
          isValid: null,
        },
        contact_2_name: {
          value: '',
          isValid: null,
        },
        contact_2_phone: {
          value: '',
          isValid: null,
        },
        contact_2_email: {
          value: '',
          isValid: null,
        },
        timetable: {
          value: '',
          isValid: null,
        },
      },
      rehab_facilities: {
        contact_1_name: {
          value: '',
          isValid: null,
        },
        contact_1_phone: {
          value: '',
          isValid: null,
        },
        contact_1_email: {
          value: '',
          isValid: null,
        },
        contact_2_name: {
          value: '',
          isValid: null,
        },
        contact_2_phone: {
          value: '',
          isValid: null,
        },
        contact_2_email: {
          value: '',
          isValid: null,
        },
        timetable: {
          value: '',
          isValid: null,
        },
      },
      soft_tissue_therapy: {
        contact_1_name: {
          value: '',
          isValid: null,
        },
        contact_1_phone: {
          value: '',
          isValid: null,
        },
        contact_1_email: {
          value: '',
          isValid: null,
        },
        contact_2_name: {
          value: '',
          isValid: null,
        },
        contact_2_phone: {
          value: '',
          isValid: null,
        },
        contact_2_email: {
          value: '',
          isValid: null,
        },
        timetable: {
          value: '',
          isValid: null,
        },
      },
      strength_and_conditioning: {
        contact_1_name: {
          value: '',
          isValid: null,
        },
        contact_1_phone: {
          value: '',
          isValid: null,
        },
        contact_1_email: {
          value: '',
          isValid: null,
        },
        contact_2_name: {
          value: '',
          isValid: null,
        },
        contact_2_phone: {
          value: '',
          isValid: null,
        },
        contact_2_email: {
          value: '',
          isValid: null,
        },
        timetable: {
          value: '',
          isValid: null,
        },
      },
    },
    isValid: null,
  },

  images: {
    value: [],
    isValid: null,
  },
  snackbarOpen: false,
  additional_info: {
    value: '',
    isValid: null,
  },
};

const filterChunks = (chunks, filterHolder = '') => {
  let filteredChunks = chunks;
  const filter = filterHolder.replace(/[^a-zA-Z0-9 ]/g, '');

  if (filter !== '') {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`(${filter})`, 'gi');

      let isServiceFound;
      for (let i = 0; i < chunk.services.length; i += 1) {
        const service = chunk.services[i];
        if (rgx.test(service.label)) {
          isServiceFound = true;
          break;
        }
      }

      return (
        rgx.test(chunk.location) ||
        rgx.test(chunk.address) ||
        rgx.test(chunk.additional_info) ||
        isServiceFound
      );
    });
  }
  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const regionalPerformanceCentreReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_REGIONAL_PERFORMANCE_CENTRE_MODAL: {
      if (action.value) {
        return {
          ...state,
          modalOpen: action.value,
        };
      }
      return {
        ...state,
        modalOpen: action.value,
        isUpdate: false,
        rowId: '',
        location: {
          value: '',
          isValid: null,
        },
        address: {
          value: '',
          isValid: null,
        },
        address_lat: {
          value: '',
          isValid: null,
        },
        address_lng: {
          value: '',
          isValid: null,
        },
        services: {
          value: [],
          isValid: null,
        },
        servicesInfo: {
          value: {
            nutrition: {
              contact_1_name: {
                value: '',
                isValid: null,
              },
              contact_1_phone: {
                value: '',
                isValid: null,
              },
              contact_1_email: {
                value: '',
                isValid: null,
              },
              contact_2_name: {
                value: '',
                isValid: null,
              },
              contact_2_phone: {
                value: '',
                isValid: null,
              },
              contact_2_email: {
                value: '',
                isValid: null,
              },
              timetable: {
                value: '',
                isValid: null,
              },
            },
            pitch_session: {
              contact_1_name: {
                value: '',
                isValid: null,
              },
              contact_1_phone: {
                value: '',
                isValid: null,
              },
              contact_1_email: {
                value: '',
                isValid: null,
              },
              contact_2_name: {
                value: '',
                isValid: null,
              },
              contact_2_phone: {
                value: '',
                isValid: null,
              },
              contact_2_email: {
                value: '',
                isValid: null,
              },
              timetable: {
                value: '',
                isValid: null,
              },
            },
            physiotherapy: {
              contact_1_name: {
                value: '',
                isValid: null,
              },
              contact_1_phone: {
                value: '',
                isValid: null,
              },
              contact_1_email: {
                value: '',
                isValid: null,
              },
              contact_2_name: {
                value: '',
                isValid: null,
              },
              contact_2_phone: {
                value: '',
                isValid: null,
              },
              contact_2_email: {
                value: '',
                isValid: null,
              },
              timetable: {
                value: '',
                isValid: null,
              },
            },
            rehab_facilities: {
              contact_1_name: {
                value: '',
                isValid: null,
              },
              contact_1_phone: {
                value: '',
                isValid: null,
              },
              contact_1_email: {
                value: '',
                isValid: null,
              },
              contact_2_name: {
                value: '',
                isValid: null,
              },
              contact_2_phone: {
                value: '',
                isValid: null,
              },
              contact_2_email: {
                value: '',
                isValid: null,
              },
              timetable: {
                value: '',
                isValid: null,
              },
            },
            soft_tissue_therapy: {
              contact_1_name: {
                value: '',
                isValid: null,
              },
              contact_1_phone: {
                value: '',
                isValid: null,
              },
              contact_1_email: {
                value: '',
                isValid: null,
              },
              contact_2_name: {
                value: '',
                isValid: null,
              },
              contact_2_phone: {
                value: '',
                isValid: null,
              },
              contact_2_email: {
                value: '',
                isValid: null,
              },
              timetable: {
                value: '',
                isValid: null,
              },
            },
            strength_and_conditioning: {
              contact_1_name: {
                value: '',
                isValid: null,
              },
              contact_1_phone: {
                value: '',
                isValid: null,
              },
              contact_1_email: {
                value: '',
                isValid: null,
              },
              contact_2_name: {
                value: '',
                isValid: null,
              },
              contact_2_phone: {
                value: '',
                isValid: null,
              },
              contact_2_email: {
                value: '',
                isValid: null,
              },
              timetable: {
                value: '',
                isValid: null,
              },
            },
          },
          isValid: null,
        },
        images: {
          value: [],
          isValid: null,
        },
        snackbarOpen: false,
        additional_info: {
          value: '',
          isValid: null,
        },
      };
    }

    case NEW_REGIONAL_PERFORMANCE_FIELD_CHANGE:
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          isValid: action.isValid,
        },
      };

    case NEW_REGIONAL_PERFORMANCE_SERVICES_INFO_FIELD_CHANGE:
      const tempState = { ...state };
      tempState.servicesInfo.value[action.fieldType][action.field] = {
        value: action.value,
        isValid: action.isValid,
      };
      return {
        ...tempState,
      };

    case ADD_NEW_REGIONAL_PERFORMANCE_CENTRE:
      return {
        ...state,
        pending: true,
      };

    case NEW_REGIONAL_PERFORMANCE_CENTRE_ADDED:
      return {
        ...state,
        modalOpen: false,
        pending: false,
        isUpdate: false,
        rowId: '',
        location: {
          value: '',
          isValid: null,
        },
        address: {
          value: '',
          isValid: null,
        },

        address_lat: {
          value: '',
          isValid: null,
        },
        address_lng: {
          value: '',
          isValid: null,
        },

        services: {
          value: [],
          isValid: null,
        },
        servicesInfo: {
          value: {
            nutrition: {
              contact_1_name: {
                value: '',
                isValid: null,
              },
              contact_1_phone: {
                value: '',
                isValid: null,
              },
              contact_1_email: {
                value: '',
                isValid: null,
              },
              contact_2_name: {
                value: '',
                isValid: null,
              },
              contact_2_phone: {
                value: '',
                isValid: null,
              },
              contact_2_email: {
                value: '',
                isValid: null,
              },
              timetable: {
                value: '',
                isValid: null,
              },
            },
            pitch_session: {
              contact_1_name: {
                value: '',
                isValid: null,
              },
              contact_1_phone: {
                value: '',
                isValid: null,
              },
              contact_1_email: {
                value: '',
                isValid: null,
              },
              contact_2_name: {
                value: '',
                isValid: null,
              },
              contact_2_phone: {
                value: '',
                isValid: null,
              },
              contact_2_email: {
                value: '',
                isValid: null,
              },
              timetable: {
                value: '',
                isValid: null,
              },
            },
            physiotherapy: {
              contact_1_name: {
                value: '',
                isValid: null,
              },
              contact_1_phone: {
                value: '',
                isValid: null,
              },
              contact_1_email: {
                value: '',
                isValid: null,
              },
              contact_2_name: {
                value: '',
                isValid: null,
              },
              contact_2_phone: {
                value: '',
                isValid: null,
              },
              contact_2_email: {
                value: '',
                isValid: null,
              },
              timetable: {
                value: '',
                isValid: null,
              },
            },
            rehab_facilities: {
              contact_1_name: {
                value: '',
                isValid: null,
              },
              contact_1_phone: {
                value: '',
                isValid: null,
              },
              contact_1_email: {
                value: '',
                isValid: null,
              },
              contact_2_name: {
                value: '',
                isValid: null,
              },
              contact_2_phone: {
                value: '',
                isValid: null,
              },
              contact_2_email: {
                value: '',
                isValid: null,
              },
              timetable: {
                value: '',
                isValid: null,
              },
            },
            soft_tissue_therapy: {
              contact_1_name: {
                value: '',
                isValid: null,
              },
              contact_1_phone: {
                value: '',
                isValid: null,
              },
              contact_1_email: {
                value: '',
                isValid: null,
              },
              contact_2_name: {
                value: '',
                isValid: null,
              },
              contact_2_phone: {
                value: '',
                isValid: null,
              },
              contact_2_email: {
                value: '',
                isValid: null,
              },
              timetable: {
                value: '',
                isValid: null,
              },
            },
            strength_and_conditioning: {
              contact_1_name: {
                value: '',
                isValid: null,
              },
              contact_1_phone: {
                value: '',
                isValid: null,
              },
              contact_1_email: {
                value: '',
                isValid: null,
              },
              contact_2_name: {
                value: '',
                isValid: null,
              },
              contact_2_phone: {
                value: '',
                isValid: null,
              },
              contact_2_email: {
                value: '',
                isValid: null,
              },
              timetable: {
                value: '',
                isValid: null,
              },
            },
          },
          isValid: null,
        },
        images: {
          value: [],
          isValid: null,
        },
        snackbarOpen: false,
        additional_info: {
          value: '',
          isValid: null,
        },
      };

    case REQUEST_REGIONAL_PERFORMANCE_CENTRE:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_REGIONAL_PERFORMANCE_CENTRE:
      return {
        ...state,
        chunks: action.value,
        selected: [],
        filteredChunks: action.value,
        items: _keys(action.value),
        pending: false,
        error: null,
      };

    case SELECT_PERFORMANCE_CENTRE: {
      const prevState =
        (state.filteredChunks[action.key] &&
          state.filteredChunks[action.key].selected) ||
        false;

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case SELECT_ALL_PERFORMANCE_CENTRES: {
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: !chunk.default,
      }));
      const selected = _union(
        state.selected,
        Object.keys(filteredChunks).filter(chunk => chunk !== 'default'),
      );

      return {
        ...state,
        filteredChunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_PERFORMANCE_CENTRES:
      return {
        ...state,
        filteredChunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    case FILTER_PERFORMANCE_CENTRE_DATA:
      return {
        ...state,
        ...filterChunks(state.chunks, action.payload),
        filter: action.payload,
      };

    case OPEN_REGIONAL_PERFORMANCE_CENTRE_DIALOG_UPDATE_DATA:
      return {
        ...state,
        modalOpen: true,
        isUpdate: true,
        rowId: action.key,
        location: {
          value: action.data.location || '',
          isValid: true,
        },
        address: {
          value: action.data.address || '',
          isValid: true,
        },
        address_lat: {
          value: action.data.address_lat || '',
          isValid: null,
        },
        address_lng: {
          value: action.data.address_lng || '',
          isValid: null,
        },
        services: {
          value: action.data.services || [],
          isValid: true,
        },
        servicesInfo: {
          value: action.data.servicesInfo || {
            nutrition: {
              contact_1_name: '',
              contact_1_phone: '',
              contact_1_email: '',
              contact_2_name: '',
              contact_2_phone: '',
              contact_2_email: '',
              timetable: '',
            },
            pitch_session: {
              contact_1_name: '',
              contact_1_phone: '',
              contact_1_email: '',
              contact_2_name: '',
              contact_2_phone: '',
              contact_2_email: '',
              timetable: '',
            },
            physiotherapy: {
              contact_1_name: '',
              contact_1_phone: '',
              contact_1_email: '',
              contact_2_name: '',
              contact_2_phone: '',
              contact_2_email: '',
              timetable: '',
            },
            rehab_facilities: {
              contact_1_name: '',
              contact_1_phone: '',
              contact_1_email: '',
              contact_2_name: '',
              contact_2_phone: '',
              contact_2_email: '',
              timetable: '',
            },
            soft_tissue_therapy: {
              contact_1_name: '',
              contact_1_phone: '',
              contact_1_email: '',
              contact_2_name: '',
              contact_2_phone: '',
              contact_2_email: '',
              timetable: '',
            },
            strength_and_conditioning: {
              contact_1_name: '',
              contact_1_phone: '',
              contact_1_email: '',
              contact_2_name: '',
              contact_2_phone: '',
              contact_2_email: '',
              timetable: '',
            },
          },
          isValid: null,
        },
        coach: {
          value: action.data.coach || '',
          isValid: true,
        },
        coach_phone: {
          value: action.data.coach_phone || '',
          isValid: null,
        },
        coach_email: {
          value: action.data.coach_email || '',
          isValid: null,
        },
        physio: {
          value: action.data.physio || '',
          isValid: true,
        },
        physio_phone: {
          value: action.data.physio_phone || '',
          isValid: null,
        },
        physio_email: {
          value: action.data.physio_email || '',
          isValid: null,
        },
        timetable: {
          value: action.data.timetable || '',
          isValid: true,
        },

        images: {
          value: action.data.images || [],
          isValid: true,
        },
        snackbarOpen: false,
        additional_info: {
          value: action.data.additional_info || '',
          isValid: true,
        },
      };

    case TOGGLE_REGIONAL_SNACKBAR: {
      return {
        ...state,
        snackbarOpen: !state.snackbarOpen,
      };
    }

    case RESET_REGIONAL_PERFORMANCE_SERVICES_INFO_FIELD:
      const temp = { ...state };
      temp.servicesInfo.value[action.fieldType] = {
        contact_1_name: {
          value: '',
          isValid: null,
        },
        contact_1_phone: {
          value: '',
          isValid: null,
        },
        contact_1_email: {
          value: '',
          isValid: null,
        },
        contact_2_name: {
          value: '',
          isValid: null,
        },
        contact_2_phone: {
          value: '',
          isValid: null,
        },
        contact_2_email: {
          value: '',
          isValid: null,
        },
        timetable: {
          value: '',
          isValid: null,
        },
      };
      return {
        ...temp,
      };

    default:
      return state;
  }
};

export default regionalPerformanceCentreReducer;
