/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { CircularProgress } from '../../../../app/components/progress';
import { capitaliseString } from '../../../../utils/string';
import Container from '../../../../app/components/container';
import './styles.css';

import iPhoneImage from '../../../assets/images/MockPhone11.png';
import iPhone12Image from '../../../assets/images/MockPhone12.png';
import iPadImage from '../../../assets/images/MockPad.png';
import { PGMOL_APPS } from '../../../../config/constants';

const Preview = props => {
  const [needIpad, setNeedIpad] = useState(false);
  const iframeRef = useRef();
  const iframeWrapperRef = useRef();

  const resetFrameScale = () => {
    if (iframeRef.current) {
      iframeRef.current.style.removeProperty('transform');
      iframeRef.current.style.removeProperty('height');
    }
  };
  const scaleFrame = () => {
    const previewArea = iframeWrapperRef.current;
    if (previewArea) {
      const previewWidth = previewArea.clientWidth;
      const displayIpad = props.desktop && previewWidth < 850;
      setNeedIpad(displayIpad);
      //only scale iframe for desktop
      if (displayIpad || !props.desktop) {
        return resetFrameScale();
      }

      if (!displayIpad && iframeRef.current) {
        const previewHeight = previewArea.offsetHeight;
        const frameScale = previewWidth / 1366;
        const scaledHeight = previewHeight / frameScale;
        iframeRef.current.style.transform = `translate(-50%, -50%)  scale(${frameScale}) translateZ(0) rotate(0.00000000001deg)`;
        iframeRef.current.style.height = `${scaledHeight}px`;
      }
    }
  };

  useEffect(() => {
    const preview = window.document.getElementById('iframe');
    if (preview) {
      const { tab } = queryString.parse(props.location.search);
      const path = props.currentPath;

      if (tab) {
        preview.contentWindow &&
          preview.contentWindow.postMessage({ path, tab }, '*');
      } else {
        preview.contentWindow &&
          preview.contentWindow.postMessage({ path }, '*');
      }
      const plan = props.trialExpired ? props.subscription : 'accelerate';

      preview.contentWindow && preview.contentWindow.postMessage({ plan }, '*');
    }

    //scale preview on window resize
    window.addEventListener('resize', scaleFrame);
    return () => window.removeEventListener('resize', scaleFrame);
  }, [props]);

  useEffect(() => {
    scaleFrame();
  }, [iframeWrapperRef.current && iframeWrapperRef.current.clientWidth, props]);

  const onLoad = () => {
    const preview = window.document.getElementById('iframe');

    if (preview) {
      if (props.location) {
        const { tab } = queryString.parse(props.location.search);
        const path = props.currentPath;
        // wait for website to load before pushing new path
        if (tab) {
          preview.contentWindow &&
            preview.contentWindow.postMessage({ path, tab }, '*');
        } else {
          preview.contentWindow &&
            preview.contentWindow.postMessage({ path }, '*');
        }
      }

      const plan = props.trialExpired ? props.subscription : 'accelerate';

      preview.contentWindow && preview.contentWindow.postMessage({ plan }, '*');
    }
  };

  const getSplashBackground = () => {
    const {
      backgroundType,
      backgroundColor,
      splashScreenImage,
    } = props.splashSettings;
    return {
      [`background${capitaliseString(backgroundType)}`]:
        backgroundType === 'color'
          ? backgroundColor
          : `url(${splashScreenImage})`,
    };
  };

  const previewType = () => {
    const segments = props.location.pathname.split('/');
    return segments.pop() || segments.pop();
  };

  // const previewUrl = () =>
  //   `${process.env.REACT_APP_PREVIEW_URL}?appname=${props.match.params.appname}&disableView=${props.disableView}`;

  // open new window in the middle of the screen
  // const popupCenter = (url, title, w, h) => {
  //   // Fixes position on a dual monitor setup
  //   const dualScreenLeft = window.screenLeft
  //     ? window.screenLeft
  //     : window.screenX; //  Most browsers :  Firefox
  //   const dualScreenTop = window.screenTop ? window.screenTop : window.screenY;

  //   const width = window.innerWidth
  //     ? window.innerWidth
  //     : document.documentElement.clientWidth;
  //   const height = window.innerHeight
  //     ? window.innerHeight
  //     : document.documentElement.clientHeight;

  //   const left = width / 2 - w / 2 + dualScreenLeft;
  //   const top = height / 2 - h / 2 + dualScreenTop;
  //   const newWindow = window.open(
  //     url,
  //     title,
  //     `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`,
  //   );

  //   // Puts focus on the newWindow
  //   if (window.focus) {
  //     newWindow.focus();
  //   }
  // };

  // const wixOverlay = () =>
  //   props.integration &&
  //   props.integration.type === 'Wix' && (
  //     <div className="hide-wix-preview">
  //       <span>
  //         Click on the button to see your app preview in a new window!
  //       </span>
  //       <CTAButton
  //         label="Open"
  //         height={38}
  //         minWidth={90}
  //         // borderColor="rgb(156, 150, 6)"
  //         outlineRGB={'227,227,227'}
  //         style={{ margin: '10px auto', display: 'block', maxWidth: '230px' }}
  //         onClick={() => popupCenter(previewUrl(), 'xtf', '400', '700')}
  //       />
  //     </div>
  //   );

  const previewContent = () => {
    switch (previewType()) {
      case 'app_splash_screen': {
        return (
          <>
            <div id={getAppSplashScreenId()} style={getSplashBackground()} />
          </>
        );
      }
      default: {
        return (
          <iframe
            id="iframe"
            className={
              props.integration && props.integration.type === 'Wix'
                ? 'wix'
                : props.desktop
                ? 'desktop'
                : null
            }
            ref={iframeRef}
            title="app-preview"
            frameBorder="0"
            onResize
            scrolling="yes"
            src={`${process.env.REACT_APP_PREVIEW_URL}?appname=${props.match.params.appname}&disableView=${props.disableView}`}
            onLoad={onLoad}
          />
        );
      }
    }
  };

  const getPreviewBackground = () => {
    if (props.desktop && needIpad) {
      return { class: 'ipad-frame', src: iPadImage };
    }
    return {
      class: `mobile-frame ${PGMOL_APPS.indexOf(props.match.params.appname) >
        -1 && 'pgmol-mobile-frame'}`,
      src:
        PGMOL_APPS.indexOf(props.match.params.appname) > -1
          ? iPhone12Image
          : iPhoneImage,
    };
  };

  const getAppSplashScreenId = () => {
    if (props.desktop) {
      if (needIpad) {
        return 'ipad-splash';
      }
      return 'desktop-splash';
    }
    return 'splash';
  };

  const previewBackground = getPreviewBackground();
  if (!props.match.params.appname) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <div
      className={
        needIpad
          ? 'ipad-app-preview'
          : props.desktop
          ? 'desktop-app-preview'
          : `mobile-app-preview ${PGMOL_APPS.indexOf(
              props.match.params.appname,
            ) > -1 && 'pgmol-mobile-app-preview'}`
      }
    >
      <div
        id="iframeWrapper"
        ref={iframeWrapperRef}
        className={
          needIpad && props.desktop ? 'iframe-wrapper-ipad' : 'iframe-wrapper'
        }
      >
        {/* <div className="iframe-wrapper wix-overlay"> */}
        {/* Removed Wix overlay for the time being */}
        {/*wixOverlay()*/}
        {props.desktop ? null : (
          <div
            className="mobile-toolbar"
            style={
              previewType() === 'app_splash_screen'
                ? getSplashBackground()
                : { background: props.topBarColor.backgroundColor }
            }
          />
        )}
        {previewContent()}
      </div>
      {(!props.desktop || needIpad) && (
        <img className={previewBackground.class} src={previewBackground.src} />
      )}
    </div>
  );
};

Preview.propTypes = {
  disableView: PropTypes.bool,
  splashSettings: PropTypes.shape({
    backgroundType: PropTypes.string,
    backgroundColor: PropTypes.string,
    splashScreenImage: PropTypes.string,
    icon: PropTypes.string,
    icons: PropTypes.object,
    '96x96': PropTypes.string,
  }),
  subscription: PropTypes.string,
  integration: PropTypes.object,
  trialExpired: PropTypes.bool,
  currentPath: PropTypes.string,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  desktop: PropTypes.bool.isRequired,
};

Preview.defaultProps = {
  disableView: false,
  subscription: null,
  trialExpired: null,
  currentPath: null,
  integration: {},
  splashSettings: {},
};

export default withRouter(Preview);
