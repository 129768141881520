import { connect } from 'react-redux';
import Product from './Product';
import { withRouter } from 'react-router-dom';
import {
  selectImage,
  updateProductApprovalStatus,
  openProductApprovalDialog,
  closeProductApprovalDialog,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.ecommerce.marketplace.product,
  selectedCurrency:
    state.ecommerce.marketplace.settings.data.marketplace_settings
      .selectedCurrency,
  vendorApprovalEnabled:
    state.ecommerce.marketplace.settings.data.marketplace_settings
      .vendorApproval &&
    state.ecommerce.marketplace.settings.data.marketplace_settings
      .vendorApproval.isEnabled,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setImage: (imageSrc, index) => dispatch(selectImage(imageSrc, index)),
  updateProductApprovalStatus: (product, productApprovalStatus, message) =>
    dispatch(
      updateProductApprovalStatus(
        ownProps.match.params.appname,
        product,
        productApprovalStatus,
        message,
      ),
    ),
  openProductApprovalDialog: () => dispatch(openProductApprovalDialog()),
  closeProductApprovalDialog: () => dispatch(closeProductApprovalDialog()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Product),
);
