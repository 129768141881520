import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: 10px;
    > svg {
      font-size: 2.5rem;
      color: #000000;
    }
  }
`;
