import { connect } from 'react-redux';

import CreateApp from './CreateApp';

import { domainChange, titleChange, skinChange, createNewApp, filterTemplates } from './actions';

import { logout } from '../../../../../../account/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.editor.build.create.createApp,
  // external modules
  organisation: state.account.organisation,
  profile: state.account.profile,
});

const mapDispatchToProps = dispatch => ({
  onDomainChange: value => dispatch(domainChange(value)),
  onTitleChange: value => dispatch(titleChange(value)),
  onSkinChange: value => dispatch(skinChange(value)),
  onSubmit: callback => dispatch(createNewApp(callback)),
  onLogout: () => dispatch(logout()),
  onFilterTemplates : (searchText) => dispatch(filterTemplates(searchText))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateApp);
