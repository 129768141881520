import React from 'react';
import { Route, Switch } from 'react-router-dom';

import EditEventModal from './module';

function EditEventModalRouter() {
  return (
    <Switch>
      <Route path="/:appname/staff/events/:memberbody/:event?" exact>
        {props => <EditEventModal {...props} />}
      </Route>
      <Route path="/:appname/members/events/:memberbody/:event?" exact>
        {props => <EditEventModal {...props} />}
      </Route>
    </Switch>
  );
}

export default EditEventModalRouter;
