import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';

import { FlatButton } from '../../../../app/components/buttons';

const CropDialog = ({
  open,
  title,
  cancelLabel,
  changeLabel,
  submitLabel,
  children,
  changeDisabled,
  submitDisabled,
  onCancel,
  onChange,
  onSubmit,
}) => {
  const actions = [
    <FlatButton label={cancelLabel} onClick={onCancel} secondaryWhite />,
    <FlatButton
      label={changeLabel}
      onClick={onChange}
      disabled={changeDisabled}
      secondary
    />,
    <FlatButton
      label={submitLabel}
      onClick={onSubmit}
      disabled={submitDisabled}
      primary
    />,
  ];

  return (
    <Dialog
      title={title}
      open={open}
      actions={actions}
      actionsContainerStyle={{
        textAlign: 'center',
        padding: 16,
      }}
      bodyStyle={{
        position: 'relative',
        overflowY: submitDisabled ? 'hidden' : 'auto',
        padding: '0px 24px',
        minHeight: '350px',
      }}
      contentStyle={{
        textAlign: 'center',
        maxHeight: '100%',
        transform: 'translate(0px, 32px)',
      }}
      onRequestClose={onCancel}
      autoScrollBodyContent
    >
      {children}
    </Dialog>
  );
};

CropDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  cancelLabel: PropTypes.string.isRequired,
  changeLabel: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  changeDisabled: PropTypes.bool.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CropDialog;
