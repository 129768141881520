import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const Row = props => {
  const { t } = useTranslation();
  const { row, removeSlotFromRow } = props;

  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  const handleSlotDelete = index => {
    removeSlotFromRow(index);
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label={t('expand row')}
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="left">{row.description}</TableCell>

        <TableCell align="left">
          {moment(row.date).format('DD-MM-YY')}
        </TableCell>
        <TableCell align="left">{row.numSlots}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                <Trans>Slots Added:</Trans>
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Trans>Capacity</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Staff members</Trans>
                    </TableCell>
                    <TableCell align="left">
                      <Trans>Slots</Trans>
                    </TableCell>
                    <TableCell align="left">
                      <Trans>Duration</Trans>
                    </TableCell>
                    <TableCell align="left">
                      <Trans>Delete</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row &&
                    row.slots.length > 0 &&
                    row.slots.map((slotsRow, index) => (
                      <TableRow key={slotsRow.date}>
                        <TableCell component="th" scope="row">
                          {slotsRow.capacity}
                        </TableCell>
                        <TableCell>
                          {slotsRow.staffMembers &&
                            slotsRow.staffMembers.length &&
                            slotsRow.staffMembers.map(data => (
                              <Chip
                                avatar={
                                  <Avatar>{data.name.substring(0, 1)}</Avatar>
                                }
                                label={data.name}
                                clickable
                                color="primary"
                                size="small"
                                style={{ margin: '3px' }}
                              />
                            ))}
                        </TableCell>

                        <TableCell align="left">
                          {slotsRow.slots &&
                            slotsRow.slots.length > 0 &&
                            slotsRow.slots.map(slot => (
                              <Chip
                                label={`${slot.time} `}
                                size="small"
                                style={{ margin: '3px' }}
                              />
                            ))}
                        </TableCell>
                        <TableCell>{slotsRow.duration}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleSlotDelete(index)}
                            aria-label="delete"
                            color="secondary"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Row;
