const initialState = {
  noSubscriptionsText: '',
  subscriptionsText: '',
  stripeApiKey: '',
  buttonLabel: '',
};

const restrictedAccess = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_RESTRICTED_ACCESS_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      };
      case 'SET_DEFAULT_RESTRICTED_ACCESS_LAYOUT':
        return {
          ...state,
          headerText: action.payload.headerText,
          layout:action.payload.layout,
          subHeaderText: action.payload.subHeaderText
        }
    default:
      return state;
  }
};

export default restrictedAccess;
