import firebase from '../../../../../../../firebase';
import { isEmpty, map, omit } from 'lodash';
import WooCommerceRestApi from '@woocommerce/woocommerce-rest-api';

export const isValidURL = string => {
  const res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  );
  return res !== null;
};

export const fieldChange = (appname, dataId, field, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${dataId}`);

  dataRef.child(field).set(value);

  return {
    type: 'FIELD_VALUE_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

export const onFetchWoocommerceCategories = (
  appname,
  dataId,
  domain,
  activeCategory = {},
  showProductsOfSpecificCategory,
  csecret,
  ckey,
  pageNum = 1,
) => dispatch => {
  if (domain && isValidURL(domain)) {
    dispatch({
      type: 'FETCHING_WOOCOMMERCE_CATEGORIES',
      domain,
    });

    const Woocommerce = new WooCommerceRestApi({
      url: domain,
      consumerKey: ckey,
      consumerSecret: csecret,
      wpAPI: true,
      version: 'wc/v1',
      queryStringAuth: true,
    });

    Woocommerce.get('products/categories', {
      orderby: 'name',
      hide_empty: true,
      per_page: 100,
      page: pageNum,
    })
      .then(categories => {
        categories = map(categories.data, elem =>
          omit(elem, ['yoast_head', 'yoast_head_json']),
        );
        dispatch(fieldChange(appname, dataId, 'categories', categories));

        if (isEmpty(activeCategory))
          dispatch(
            fieldChange(appname, dataId, 'activeCategory', categories[0]),
          );

        return categories;
      })
      .catch(error => console.error(error));
  } else {
    dispatch({
      type: 'INVALID_WOOCOMMERCE_DOMAIN',
      error: 'Invalid Woocommerce Domain',
    });
  }
};
