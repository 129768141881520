import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Delete = ({ className, iconStyle }) => (
  <svg
    style={iconStyle}
    className={`icon delete ${className || ''}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 23.5"
  >
    <title>bin</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M16.64,23.5H5.36L2.11,4H19.89ZM6.64,22h8.72L18.11,5.5H3.89Z"
        />
        <path
          class="cls-1"
          d="M14.42,5.5H7.58V3.42a3.42,3.42,0,0,1,6.84,0ZM9.08,4h3.84V3.42a1.92,1.92,0,0,0-3.84,0Z"
        />
        <rect class="cls-1" y="4" width="22" height="1.5" />
        <rect class="cls-1" x="8" y="19" width="6" height="1.5" />
      </g>
    </g>
  </svg>
);

Delete.propTypes = {
  className: PropTypes.string,
  iconStyle: PropTypes.object,
};

export default Delete;
