import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RaisedButton } from '../../../../app/components/buttons';
import {
  LIFETIME_COUPON,
  BEEZER_ONE_YEAR_COUPON,
  BEEZER_19_ONE_YEAR,
} from '../../../../plans';
import themeStyles from '../../../../_export.scss';
const styles = {
  PrimaryLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.primaryTextColor,
  },
  buttonRoot: {
    margin: '12px',
    width: '70px',
    minWidth: '70px',
  },
};

const OfferCard = props => {
  const {
    offer,
    history,
    match,
    billingPeriodToggle,
    currency,
    dynamicPrice,
  } = props;

  const {
    price,
    plan,
    is_yearly_disabled: isYearlyDisabled,
    initial_price_to_pay: initialPriceToPay,
    period,
    coupon,
    content,
  } = offer;

  const { customPeriodText } = content;

  if (isYearlyDisabled && billingPeriodToggle === 'yearly') {
    return null;
  }

  let { monthly_price: monthlyPrice } = offer;

  const handleCardTap = () => {
    let cost = dynamicPrice || price;

    if (initialPriceToPay) {
      cost = initialPriceToPay;
    }

    history.push({
      pathname: `/${match.params.appname}/subscribe`,
      query: {
        plan,
        billingPeriod: period,
        cost,
        isOffer: true,
        coupon,
      },
    });
  };

  const isFloat = n => Number(n) === n && n % 1 !== 0;

  const getPrice = () => {
    if (!monthlyPrice) {
      monthlyPrice = price / 12;
    }

    let parsedPrice = price;

    if (
      coupon !== LIFETIME_COUPON &&
      coupon !== BEEZER_ONE_YEAR_COUPON &&
      coupon !== BEEZER_19_ONE_YEAR &&
      billingPeriodToggle === 'monthly'
    ) {
      parsedPrice = monthlyPrice;
    }

    if (initialPriceToPay) {
      parsedPrice = initialPriceToPay;
    }

    parsedPrice = isFloat(parsedPrice) ? parsedPrice.toFixed(2) : parsedPrice;

    return parsedPrice;
  };

  const getPeriodText = () => {
    if (customPeriodText) {
      return customPeriodText;
    }
    if (
      offer.coupon === BEEZER_ONE_YEAR_COUPON ||
      offer.period === 'lifetime'
    ) {
      return offer.period;
    }
    return billingPeriodToggle;
  };

  const hasOfferIcon = offer.content.imageSmall.trim();

  return (
    <div className="plan-card-holder">
      <div className={`plan-card `}>
        {hasOfferIcon && !dynamicPrice && (
          <img
            height={'80px'}
            width={'auto'}
            id="offerImage"
            src={offer.content.imageSmall}
            alt="Offer Plan Icon"
            style={{ position: 'absolute', top: '-83px' }}
          />
        )}
        <span className="plan-title">Offer</span>
        <div className="separator" />
        <h3 className="flex-column align-center">
          <strong className="plan-price">
            {dynamicPrice || getPrice()}
            <span className="plan-format">{currency || 'GBP'}</span>
          </strong>
          <span className="plan-period">{getPeriodText()}</span>
        </h3>

        <span className="plan-desc">
          <p
            className="plan-description"
            dangerouslySetInnerHTML={{
              __html:
                offer.content.planDescription || offer.content.description,
            }}
          />
        </span>
      </div>

      <RaisedButton
        label="Go!"
        backgroundColor={themeStyles.primaryColor}
        disabledBackgroundColor={themeStyles.primaryColorLight}
        style={styles.buttonRoot}
        labelStyle={styles.PrimaryLabelStyle}
        onClick={() => handleCardTap()}
      />
    </div>
  );
};

OfferCard.propTypes = {
  offer: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  billingPeriodToggle: PropTypes.bool.isRequired,
};

export default withRouter(OfferCard);
