import firebase from '../../../../firebase';

export const REQUEST_DIARY_DATA = 'REQUEST_DIARY_DATA';
export const RECEIVE_DIARY_DATA = 'RECEIVE_DIARY_DATA';
export const FILTER_DIARY_DATA = 'FILTER_DIARY_DATA';
export const SELECT_DIARY = 'SELECT_DIARY';
export const SELECT_ALL_DIARIES = 'SELECT_ALL_DIARIES';
export const DESELECT_ALL_DIARIES = 'DESELECT_ALL_DIARIES';
export const DELETE_DIARY_ENTRY = 'DELETE_DIARY_ENTRY';

const requestDiaryData = appname => ({
  type: REQUEST_DIARY_DATA,
  appname,
});

const requestDiaryEntryDelete = (appname, key) => ({
  type: DELETE_DIARY_ENTRY,
  appname,
  key,
});

const receiveDiaryData = data => {
  return {
    type: RECEIVE_DIARY_DATA,
    data,
  };
};

export const loadDiaryData = appname => dispatch => {
  const myHeaders = new Headers();

  dispatch(requestDiaryData(appname));
  firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);
      return fetch(
        `${process.env.REACT_APP_DIARY_API}/get_diary_entries_admin?appname=${appname}`,
        {
          method: 'GET',
          headers: myHeaders,
          mode: 'cors',
        },
      )
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then(response => dispatch(receiveDiaryData(response)));
    });
};

export const deleteDiaryEntry = (appname, diaryKey) => dispatch => {
  const myHeaders = new Headers();

  dispatch(requestDiaryEntryDelete(appname, diaryKey));
  firebase
    .auth()
    .currentUser.getIdToken()
    .then(token => {
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('x-access-token', token);
      const { uid } = firebase.auth().currentUser;
      return fetch(`${process.env.REACT_APP_DIARY_API}/delete_diary_entry`, {
        method: 'DELETE',
        headers: myHeaders,
        mode: 'cors',
        body: JSON.stringify({ appname, key: diaryKey, uid, isAdmin: true }),
      })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then(response => dispatch(loadDiaryData(appname)));
    });
};

export const filterDiaryData = filter => dispatch =>
  dispatch({
    type: FILTER_DIARY_DATA,
    payload: filter,
  });

export const selectDiary = key => ({
  type: SELECT_DIARY,
  key,
});

export const selectAllDiaries = () => ({ type: SELECT_ALL_DIARIES });

export const deselectAllDiaries = () => ({
  type: DESELECT_ALL_DIARIES,
});

export const onSetDefaultGroupData = (appname, params) => dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default`)
    .update({ ...params });
};

export const updateSelectedUsers = (appname, users) => dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/publicAccessUsers`)
    .set(users)
    .then(() =>
      dispatch({
        type: 'SET_PUBLIC_ACCESS_USERS',
        appname,
        users,
      }),
    );
};

export const updatePageNames = (appname, fieldName, value) => dispatch => {
  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/diaryPages`)
    .update({ [fieldName]: value });
};
