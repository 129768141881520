import _pickBy from 'lodash/pickBy';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import {
  REQUEST_MARKETPLACE_PRODUCTS,
  RECEIVE_MARKETPLACE_PRODUCTS,
  NO_MARKETPLACE_PRODUCTS_EXIST,
  FILTER_MARKETPLACE_PRODUCTS_DATA,
  SELECT_MARKETPLACE_PRODUCT,
  SELECT_ALL_MARKETPLACE_PRODUCTS,
  DESELECT_ALL_MARKETPLACE_PRODUCTS,
  DELETE_SELECTED,
  SORT_MARKETPLACE_PRODUCTS,
} from './types';

const initialState = {
  pending: false,
  chunks: {},
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
  selected: [],
  productDialogOpen: false,
  selectAll: false,
  selectedProducts: {},
  approvedProducts: [],
  rejectedProducts: [],
  pendingProducts: [],
};

const filterChunks = (chunks, filterHolder = {}) => {
  let filteredChunks = chunks;
  const searchText = filterHolder.searchText.replace(/[^a-zA-Z0-9 ]/g, '');
  const productStatusFilter = filterHolder.productStatusFilter;
  filteredChunks = _pickBy(
    filteredChunks,
    chunk =>
      chunk.productStatus === productStatusFilter ||
      productStatusFilter === 'all',
  );
  if (searchText !== '') {
    filteredChunks = _pickBy(filteredChunks, chunk => {
      const rgx = new RegExp(`(${searchText})`, 'gi');
      return rgx.test(chunk.name);
    });
  }
  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const sortItems = (filteredChunks, sortBy, order) =>
  _keys(filteredChunks).sort((a, b) => {
    let keyA = filteredChunks[a][sortBy];
    let keyB = filteredChunks[b][sortBy];
    if (sortBy === 'date') {
      keyA = new Date(filteredChunks[a][sortBy]);
      keyB = new Date(filteredChunks[b][sortBy]);
    }
    if (order === 'desc') {
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
    } else if (order === 'asc') {
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
    }
    return 0;
  });

const products = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_MARKETPLACE_PRODUCTS:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVE_MARKETPLACE_PRODUCTS: {
      const filteredChunks = {};
      if (Object.keys(state.filteredChunks).length) {
        Object.keys(action.payload.products).forEach(key => {
          filteredChunks[key] = action.payload.products[key];
          filteredChunks[key].selected =
            state.filteredChunks[key] && state.filteredChunks[key].selected
              ? state.filteredChunks[key].selected
              : false;
        });
      }
      return {
        ...state,
        chunks: action.payload.products,

        filteredChunks: Object.keys(filteredChunks).length
          ? filteredChunks
          : action.payload.products,
        items: _keys(action.payload.products),
        pending: false,
        error: null,
      };
    }

    case RECEIVE_MARKETPLACE_PRODUCTS: {
      const filteredChunks = {};
      if (Object.keys(state.filteredChunks).length) {
        Object.keys(action.payload.products).forEach(key => {
          filteredChunks[key] = action.payload.products[key];
          filteredChunks[key].selected =
            state.filteredChunks[key] && state.filteredChunks[key].selected
              ? state.filteredChunks[key].selected
              : false;
        });
      }
      return {
        ...state,
        chunks: action.payload.products,
        filteredChunks: Object.keys(filteredChunks).length
          ? filteredChunks
          : action.payload.products,
        items: _keys(action.payload.products),
        pending: false,
        error: null,
      };
    }
    case NO_MARKETPLACE_PRODUCTS_EXIST:
      return {
        ...state,
        pending: false,
        chunks: {},
        items: [],
        filteredChunks: {},
        selected: [],
        selectAll: false,
      };

    case FILTER_MARKETPLACE_PRODUCTS_DATA:
      return {
        ...state,
        ...filterChunks(state.chunks, action.payload),
        filter: action.payload,
      };

    case SELECT_MARKETPLACE_PRODUCT: {
      const prevState = state.filteredChunks[action.key].selected;

      return {
        ...state,
        filteredChunks: {
          ...state.filteredChunks,
          [action.key]: {
            ...state.filteredChunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case SELECT_ALL_MARKETPLACE_PRODUCTS: {
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunk => ({
        ...chunk,
        selected: true,
      }));
      const selected = _union(state.selected, _keys(filteredChunks));

      return {
        ...state,
        filteredChunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case DESELECT_ALL_MARKETPLACE_PRODUCTS:
      return {
        ...state,
        filteredChunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    case DELETE_SELECTED:
      return {
        ...state,
      };

    case SORT_MARKETPLACE_PRODUCTS:
      return {
        ...state,
        order: state.order === 'desc' ? 'asc' : 'desc',
        items: sortItems(
          state.filteredChunks,
          action.column.id,
          state.order === 'desc' ? 'asc' : 'desc',
        ),
        colSorted: action.column.id,
      };

    default:
      return state;
  }
};

export default products;
