import firebase from '../../../../../../../firebase';

export const textChange = (appname, dataId, value, type) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);
  if (type) {
    dataRef.child(type).set(value);
  } else {
    dataRef.child('content').set(value);
  }

  return {
    type: 'TEXT_VALUE_CHANGE',
    appname,
    dataId,
    value,
  };
};
