import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroup, RadioButton } from 'material-ui/RadioButton';
import { Trans, withTranslation } from 'react-i18next';

import Container from '../../../../../app/components/container';
import ColorPicker from '../../../components/colorPicker';
import SectionHeader from '../../../components/sectionHeader';
import { FlatButton } from '../../../../../app/components/buttons';

import ImageUpload from '../../../internals/imageUpload';
import './style.css';

class AppSplashScreen extends Component {
  static propTypes = {
    splashScreen: PropTypes.shape({
      backgroundType: PropTypes.oneOf(['color', 'image']),
      backgroundColor: PropTypes.string,
      splashScreenImage: PropTypes.string,
    }).isRequired,
    onColorSubmit: PropTypes.func.isRequired,
    onSplashColorChange: PropTypes.func.isRequired,
    onSplashScreenSettingUpdate: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    newColorChosen: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    splashScreen: {
      backgroundType: 'color',
      backgroundColor: '#fff',
      splashScreenImage: '',
    },
  };

  componentWillUnmount() {
    const { backgroundType, splashScreenImage } = this.props.splashScreen;
    if (backgroundType === 'image' && !splashScreenImage) {
      this.onChangeBackgroundType('color');
    }
  }

  onChangeBackgroundType = value =>
    this.props.onSplashScreenSettingUpdate('backgroundType', value);

  splashEditorOptionComponents = () => {
    const { backgroundColor, splashScreenImage } = this.props.splashScreen;
    const { newColorChosen, onColorSubmit, t } = this.props;

    switch (this.props.splashScreen.backgroundType) {
      case 'color':
        return (
          <div className={'color-picker-container'}>
            <ColorPicker
              label={t('Background Color')}
              color={backgroundColor}
              height={22}
              onChange={this.props.onSplashColorChange}
            />
            <div className={'color-save-button-container'}>
              <FlatButton
                label={t('Save')}
                primary
                disabled={!newColorChosen}
                onClick={() => onColorSubmit()}
              />
            </div>
          </div>
        );
      case 'image': {
        const firebaseUrl = `apps/${this.props.match.params.appname}/draft/design_data/splash_screen/splashScreenImage`;

        const imageType = 'splash';

        return (
          <ImageUpload
            label={t('App Splash&#39;s background')}
            targetId="app_splash_bg_integration"
            caption={t('This image will be displayed in the splash screen.')}
            title={t('App Splash Screen > Image')}
            src={splashScreenImage}
            config={[{ aspect: 750 / 1334, dimensions: ['750x1334'] }]}
            firebaseUrl={firebaseUrl}
            fileType="png"
            height="200px"
            width="200px"
            imageType={imageType}
          />
        );
      }
      default:
        return null;
    }
  };

  render() {
    const { backgroundType } = this.props.splashScreen;
    const { t } = this.props;

    return (
      <Container>
        <SectionHeader
          title={t('Splash Screen')}
          tooltip={t(
            'Fill your Splash Screen background with an image or a color',
          )}
        />
        <RadioButtonGroup
          className="radio-button-group-splash"
          label={t('Background Style')}
          name="background-style"
          onChange={(el, val) => this.onChangeBackgroundType(val)}
          valueSelected={backgroundType}
        >
          <RadioButton
            key="color"
            value="color"
            label={t('Use Color')}
            style={{ width: 'auto' }}
          />
          <RadioButton
            key="image"
            value="image"
            label={t('Use Image')}
            style={{ width: 'auto' }}
          />
        </RadioButtonGroup>
        <SectionHeader
          borderTop
          title={`${t('Background')} ${t(backgroundType)}`}
        />
        {this.splashEditorOptionComponents()}
      </Container>
    );
  }
}

export default withTranslation()(AppSplashScreen);
