import { combineReducers } from 'redux';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';
import _pickBy from 'lodash/pickBy';

import {
  REQUEST_SUBSCRIPTIONS,
  RECEIVE_USER_SUBSCRIPTIONS,
  FILTER_SUBSCRIPTION_DATA,
} from './actions';

const initialState = {
  pending: false,
  chunks: {},
  selected: [],
  items: [],
  filteredChunks: {},
  filter: '',
  error: null,
};

const filterChunks = (chunks, filterHolder = '') => {
  let filteredChunks = chunks;
  const filter = filterHolder.replace(/[^a-zA-Z0-9 ]/g, '');

  if (filter !== '') {
    filteredChunks = _pickBy(chunks, chunk => {
      const rgx = new RegExp(`(${filter})`, 'gi');
      const planName =
        chunk.subscriptionPlan && chunk.subscriptionPlan.name
          ? chunk.subscriptionPlan.name
          : null;
      const user = chunk.email ? chunk.email : null;
      return rgx.test(planName) || rgx.test(user);
    });
  }
  const items = Object.keys(filteredChunks);

  return { filteredChunks, items };
};

const subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SUBSCRIPTIONS:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case RECEIVE_USER_SUBSCRIPTIONS:
      return {
        ...state,
        chunks: action.data || {},
        selected: [],
        filteredChunks: action.data,
        items: _keys(action.data),
        pending: false,
        error: null,
      };
    case FILTER_SUBSCRIPTION_DATA:
      return {
        ...state,
        ...filterChunks(state.chunks, action.payload),
        filter: action.payload,
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
};

export default subscriptions;
