import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import TooltipElement from '../../../../../../../../app/components/tooltipElement';

const LayoutItem = styled.button`
  padding: 15px;
  margin: 15px auto 8px auto;
  width: ${props => props.gridSize};
  border-radius: 15px;
  display: inline-block;
  transition: all 0.5s ease;
  border: 1px solid transparent;
  &:hover:not(.active),
  &:focus:not(.active) {
    border-color: rgba(0, 0, 0, 0.2);
    background: rgba(235, 235, 235, 1);
  }
  &.active {
    background: linear-gradient(
      rgba(33, 30, 213, 0.5),
      rgba(120, 255, 197, 0.5)
    );
  }
  & > svg.layout {
    width: ${props => props.iconStyle && props.iconStyle.width};
    height: ${props => props.iconStyle && props.iconStyle.heigth};
  }
`;

const TemplateWrapper = styled.div`
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
`;

const SectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 10px;
  justify-content: space-between;
`;

const SectionHeader = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  text-overflow: ellipsis;
  justify-content: space-between;
  background: rgba(246, 246, 246, 1);
`;

const Hint = styled.div`
  margin: 6px;
  text-align: center;
  width: 100%;
  font-size: 0.9rem;
`;

class LayoutOption extends Component {
  static propTypes = {
    wrapperKey: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    grid: PropTypes.number.isRequired,
    active: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    type: PropTypes.string,
  };

  static contextTypes = {
    onLayoutSelected: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tooltip: null,
    type: 'mobile',
  };

  calculateDimensionsItem = (grid = 1) => {
    const marginPerc = 2;
    return `${(100 - marginPerc * grid) * (1 / grid)}%`;
  };

  mapTemplateItems = () => {
    const { items, grid, active, type } = this.props;

    return items.map(item => {
      const Icon = item.icon;
      const size = item.size;
      return (
        <>
          <LayoutItem
            className={active === item.value && 'active'}
            key={item.value}
            gridSize={this.calculateDimensionsItem(grid)}
            iconStyle={item.style}
            onClick={() => this.context.onLayoutSelected(item.value, type)}
          >
            <Icon />
          </LayoutItem>
          {item.size && <Hint>Recommended image size : {size}</Hint>}
          {item.dimensions && (
            <>
              <Hint>Desktop image size : {item.dimensions.desktop}</Hint>
              <Hint>Mobile image size : {item.dimensions.mobile}</Hint>
            </>
          )}
        </>
      );
    });
  };

  render() {
    const { wrapperKey, title, tooltip, items } = this.props;

    return (
      <TemplateWrapper key={wrapperKey}>
        <SectionHeader>
          {title}
          {tooltip && (
            <TooltipElement tooltip={tooltip} iconStyle={{ marginLeft: 24 }} />
          )}
        </SectionHeader>
        <SectionGrid>{this.mapTemplateItems(items)}</SectionGrid>
      </TemplateWrapper>
    );
  }
}

export default LayoutOption;
