import React, { Component } from 'react';
import Clipboard from 'clipboard';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import SocialIcons from '../../components/socialIcons';
import Panel from '../../../../app/components/panel';

import { SocialMedia as SocialMediaIcon } from '../../components/icons';
import './styles.css';

class SocialMedia extends Component {
  static propTypes = {
    customDomain: PropTypes.object,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    customDomain: {},
  };

  state = {
    open: false,
  };

  componentDidMount() {
    this.clipboard = new Clipboard('#copy-content');
  }

  handleOpen = () => this.setState({ open: true });

  handleClose = () => this.setState({ open: false });

  render() {
    const { t } = this.props;
    return (
      <div className="row">
        <div className="col-xs-12">
          <Panel icon={<SocialMediaIcon />} title={t('Social Networks')} padded>
            <SocialIcons customDomain={this.props.customDomain} />
          </Panel>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SocialMedia);
