/* eslint jsx-a11y/no-autofocus: 0 */

import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Save from 'material-ui/svg-icons/navigation/check';
import Close from 'material-ui/svg-icons/navigation/close';

const Span = styled.span`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  font-size: 13px;
  margin-right: 2rem;
  padding: 0;
  position: relative;

  &,
  &:active,
  &:focus {
    outline: none;
  }

  &.editable-text-form {
    align-items: stretch;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4) inset;
    height: calc(100% - 8px);
  }

  input {
    background: transparent;
    border: none;
    display: block;
    font-size: inherit;
    line-height: 1.4em;
    margin: 0;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.4);
    text-decoration-style: dotted;
    width: 100%;

    &,
    &:active,
    &:focus {
      outline: none;
    }
  }

  .controls {
    bottom: 0;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;

    button {
      cursor: pointer;
    }

    svg {
      border: 1px solid transparent;
      border-radius: 8px;
      vertical-align: middle;

      &:hover {
        border-color: currentColor;
      }
    }
  }
`;

class EditableText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      value: this.props.text,
    };
  }

  toggleEditing = () => this.setState({ editing: !this.state.editing });

  handleChange = event => this.setState({ value: event.target.value });

  handleSave = () => {
    this.props.onSave(this.state.value);
    this.toggleEditing();
  };

  handleClose = () => {
    this.setState({ value: this.props.text });
    this.toggleEditing();
  };

  renderDisplay = () => (
    <Span
      className={`${this.props.className} editable-text editable-text-display`}
      onClick={this.toggleEditing}
      role="button"
      tabIndex={0}
    >
      {this.state.value || this.props.placeholder}
    </Span>
  );

  renderForm = () => (
    <Span
      className={`${this.props.className} editable-text editable-text-form`}
    >
      <input
        type="text"
        value={this.state.value}
        onChange={this.handleChange}
        autoFocus
      />
      <span className="controls">
        <button onClick={this.handleSave}>
          <Save style={{ height: 16, width: 16 }} />
        </button>
        <button onClick={this.handleClose}>
          <Close style={{ height: 16, width: 16 }} />
        </button>
      </span>
    </Span>
  );

  render() {
    return this.state.editing ? this.renderForm() : this.renderDisplay();
  }
}

EditableText.propTypes = {
  className: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  text: PropTypes.string,
};

EditableText.defaultProps = {
  className: '',
  text: '',
};

export default EditableText;
