import React from 'react';

const ShopifyIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 77.79 50.71"
  >
    <title>shopify component</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polygon
          class="cls-1"
          points="9.28 18.81 0 28.09 9.28 37.37 10.34 36.31 2.12 28.09 10.34 19.87 9.28 18.81"
        />
        <polygon
          class="cls-1"
          points="68.5 18.81 67.44 19.87 75.67 28.09 67.44 36.31 68.5 37.37 77.79 28.09 68.5 18.81"
        />
        <path
          class="cls-1"
          d="M56,10.23a1.23,1.23,0,0,0-1.11-1.05l-3.67-.27-2.7-2.7a1.94,1.94,0,0,0-1.81-.33l-.79.25C44.4,2.39,42.16,1.5,40.45,1.46A3.93,3.93,0,0,0,37.55,0C30.3,0,27.36,9.33,26.65,12.12l-4,1.26c-1.64.51-1.9.8-2.11,2.38-.14,1-3.58,27.7-3.72,28.84l-.09.7,28.61,5.39.15,0,16-3.46-.11-.7C61.28,46.19,56,10.59,56,10.23ZM22,16c.12-.85.12-.85,1.08-1.15l4.42-1.38.41-.13.09-.41C28.45,11,31,1.5,37.55,1.5a2.59,2.59,0,0,1,2,1.17l.24.33.41,0c2-.13,3.57,1.36,4.6,4.33L45,8l.7-.22,1.45-.45a.69.69,0,0,1,.2,0L44.43,49l-26-4.9C18.93,39.75,21.88,16.85,22,16ZM45.92,49.08,48.75,8.57l1.8,1.79.27,0,3.72.28C55,13.47,59,41.17,59.75,46.08Z"
        />
        <path
          class="cls-1"
          d="M34.28,10.12,34,11.42l6.31-2V8.65a11.84,11.84,0,0,0-.64-4.21l-.29-.72-.71.3C37.46,4.53,35.35,6,34.28,10.12Zm1.87-.95a6.92,6.92,0,0,1,2.33-3.35,12.29,12.29,0,0,1,.28,2.53Z"
        />
        <path
          class="cls-1"
          d="M33.56,11.13c.92-4.82,3.12-6.52,4.35-7.1l1.18-.56L38,2.73a2.08,2.08,0,0,0-1.19-.39c-5,0-7.49,6.68-8.33,9.56l-.39,1.34,5.36-1.67ZM30.38,11c.82-2.35,2.37-5.66,4.79-6.75a12.39,12.39,0,0,0-3,6.19Z"
        />
        <path
          class="cls-1"
          d="M34.47,26.44c-1.06-.79-2-1.46-2-2.09s.3-1.57,2.33-1.57a7.87,7.87,0,0,1,3.29.79l.78.42,2.34-7-.53-.31a8.91,8.91,0,0,0-4.19-.83c-7.32,0-10.65,5.07-10.65,9.79,0,3.42,1.93,5,3.48,6.26,1.2,1,1.94,1.62,1.94,2.67a1.16,1.16,0,0,1-1.21,1.15c-1.5,0-3.51-1.44-4.15-2L25,33l-1.73,5.74.25.31a10.35,10.35,0,0,0,7.83,3.27c4.54,0,7.71-3.48,7.71-8.46C39.1,29.85,36.42,27.88,34.47,26.44ZM31.39,40.8A9.07,9.07,0,0,1,25,38.36l.85-2.81a8.16,8.16,0,0,0,4.23,1.67,2.63,2.63,0,0,0,2.71-2.65c0-1.81-1.27-2.84-2.49-3.84-1.44-1.17-2.93-2.38-2.93-5.09,0-4.13,2.83-8.29,9.15-8.29a9,9,0,0,1,2.88.41L38,21.89a9.34,9.34,0,0,0-3.19-.61c-3.65,0-3.83,2.56-3.83,3.07,0,1.39,1.2,2.27,2.58,3.29,1.89,1.39,4,3,4,6.2C37.6,38,35.1,40.8,31.39,40.8Z"
        />
        <path
          class="cls-1"
          d="M40.74,7.81V9.29L44.54,8.1l-.24-.72c-.74-2.3-1.83-3.55-3.22-3.73l-1.2-.15.39,1.14A9.63,9.63,0,0,1,40.74,7.81Zm1.88-.68-.39.12c0-.37,0-.73-.09-1.07A6.46,6.46,0,0,1,42.62,7.13Z"
        />
      </g>
    </g>
  </svg>
);

export default ShopifyIcon;
