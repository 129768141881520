import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import Dialog from 'material-ui/Dialog';
import Divider from 'material-ui/Divider';
import Toggle from 'material-ui/Toggle';
import ActionMenu from 'app/components/actionMenu';
import Checkbox from '@material-ui/core/Checkbox';
import ResultsTable from 'app/components/resultsTable';
import TryAgain from 'app/components/tryAgain';
import { Empty as EmptyIcon } from 'app/components/icons';
import { sortArray } from 'utils';
import Chip from '@material-ui/core/Chip';
import DialogConfirmation from 'app/components/dialogConfirmation';
import { FlatButton } from 'app/components/buttons';
import TextField from 'app/components/textField';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableCheckbox from '../../../memberLogin/components/tableCheckbox';
import Header from '../../../memberLogin/components/header';
import themeStyles from '../../../../_export.scss';
import BlogModal from './BlogModal';

import './styles.css';

const isCheckboxDisabled = (error, pending) => error && !pending;

const useStyles = makeStyles(() => ({
  appBar: {
    position: 'relative',
    color: themeStyles.primaryTextColor,
    backgroundColor: themeStyles.primaryColor,
  },
  title: {
    marginLeft: 20,
    flex: 1,
  },
  table: {
    '& .MuiTableCell-head': {
      color: 'black',
      backgroundColor: '#EAEEFF',
      fontSize: 14,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
      textAlign: 'center',
      borderBottom: 'none',
    },
    '& .MuiTableCell-body': {
      color: 'black',
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Raleway, sans-serif',
      textAlign: 'center',
      borderBottom: '1px solid #d3d3d3',
    },
    '& td > .options-container': {
      '& span': {
        color: `${themeStyles.primaryColor}!important`,
      },
      '& svg': {
        fill: `${themeStyles.primaryColor}!important`,
      },
    },
  },
  tableLeftCorner: { borderTopLeftRadius: 10 },
  tableRightCorner: { borderTopRightRadius: 10 },
}));

const Blog = props => {
  const {
    blogData,
    toggleBlogModal,
    match: {
      params: { appname },
    },
    onFieldChange,
    onMultipleValueFieldChange,
    onSubmit,
    onSelect,
    onSelectAllBlogs,
    onDeselectAllBlogs,
    onDeleteSelected,
    onFilterBlogData,
    onUpdateBlog,
    getBlogs,
    toggleBlogCategoriesModal,
    addBlogCategory,
    getBlogCategories,
    onToggleSaveBlog,
    getBlogSaveBlogConfig,
    onRemoveBlogCategory,
  } = props;

  const {
    selected,
    chunks,
    error,
    filteredChunks,
    pending,
    items,
    selectAll,
    blogCategories,
    categoriesModalOpen,
    saveBlog,
  } = blogData;
  const { t } = useTranslation();

  const classes = useStyles();

  const [cat, setCat] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [
    showDeleteCategoryConfirmation,
    setShowDeleteCategoryConfirmation,
  ] = useState({ open: false, categoryKey: '' });
  const [totalBlogs, setTotalBlogs] = useState(0);

  useEffect(() => {
    getBlogs();
    getBlogSaveBlogConfig();
  }, []);

  useEffect(() => {
    setCat('');
  }, [blogCategories]);

  useEffect(() => {
    const size = Object.keys(filteredChunks).length;
    setTotalBlogs(size);
  }, [filteredChunks]);

  const getTitle = (blogSelected, blogs) => (
    <span>
      <Trans>Blog Articles:</Trans>{' '}
      {blogSelected.length
        ? `${blogSelected.length} / ${Object.keys(blogs).length}`
        : Object.keys(blogs).length}
    </span>
  );

  // const getSortedItems = () => {
  //   let sortedItems = sortArray(Object.entries(filteredChunks), 'location');
  //   sortedItems = sortedItems.map(entity => entity[0]);
  //   return sortedItems;
  // };

  // const getSelectAllCheckBox = () => (
  //   <div style={{ display: 'flex' }}>
  //     <Checkbox
  //       checked={selectAll}
  //       onChange={(e, isChecked) =>
  //         isChecked ? onSelectAllBlogs() : onDeselectAllBlogs()
  //       }
  //       disabled={isCheckboxDisabled(error, pending)}
  //       style={{ marginLeft: -2, color: themeStyles.primaryColor }}
  //       disableRipple
  //     />
  //     <ActionMenu
  //       selected={selected}
  //       onDeleteSelected={() => onDeleteSelected(selected, chunks)}
  //       text={'blog'}
  //       customDeleteText="Are you sure you wish to delete?"
  //     />
  //   </div>
  // );

  function parseHTMLString(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return doc.body.textContent.substring(0, 150) || '';
  }

  // const body = error ? (
  //   <TryAgain message="Oops, something went wrong..." onRetry={getBlogs} />
  // ) : (
  //   <ResultsTable
  //     items={getSortedItems()}
  //     chunks={filteredChunks}
  //     loading={pending}
  //     pending={pending}
  //     error={Object.keys(filteredChunks).length === 0 ? 'yes' : null}
  //     emptyMessage={`Looks like no blog articles have been added yet.`}
  //     onRowTouchTap={rowId => onUpdateBlog(rowId, filteredChunks[rowId])}
  //     columns={[
  //       {
  //         id: 'title',
  //         header: 'Title',
  //         classNames: 'col-xs-2 regional-table-text-left',
  //       },
  //       {
  //         id: 'content',
  //         header: 'Content',
  //         classNames: 'col-xs-4 regional-table-text-left',
  //         containerElement: ({ value }) => (
  //           <span>{parseHTMLString(value)}</span>
  //         ),
  //       },
  //       {
  //         id: 'categories',
  //         header: 'Categories',
  //         classNames: 'col-xs-3 regional-table-text-left',
  //         containerElement: ({ value }) => (
  //           <span>
  //             {value &&
  //               value.map(({ label }) => (
  //                 <Chip sx={{ margin: '5px' }} label={label || ''} />
  //               ))}
  //           </span>
  //         ),
  //       },
  //       {
  //         id: 'date',
  //         header: 'Date Created',
  //         classNames: 'col-xs-2 regional-table-text-left',
  //         containerElement: ({ value }) => (
  //           <span>{value && moment(value).format('DD/MM/YYYY')}</span>
  //         ),
  //       },
  //       {
  //         id: 'selected',
  //         header: getSelectAllCheckBox(),
  //         classNames: `col-xs-1 regional-table-text-left ${
  //           items.length < 4 ? 'fix-left' : 'selected'
  //         }`,
  //         containerElement: ({ value, rowId }) =>
  //           rowId !== 'default' && (
  //             <>
  //               <TableCheckbox value={value} onSelect={() => onSelect(rowId)} />
  //               <ActionMenu
  //                 selected={[rowId]}
  //                 onDeleteSelected={() => onDeleteSelected([rowId], chunks)}
  //                 onEditSelected={() =>
  //                   onUpdateBlog(rowId, filteredChunks[rowId])
  //                 }
  //                 text={'regionalPerformanceCentre'}
  //                 customDeleteText="Are you sure you wish to delete?"
  //               />
  //             </>
  //           ),
  //       },
  //     ]}
  //   />
  // );

  const handleBlogCategoriesModalOpen = () => {
    getBlogCategories();
    toggleBlogCategoriesModal(true);
  };

  const handleBlogCategoriesModalClose = () => {
    toggleBlogCategoriesModal(false);
  };

  const toggleSaveBlog = value => {
    if (!value) {
      onToggleSaveBlog(false);
    }
    setShowConfirmationDialog(value);
  };

  const toggleSaveBlogFeatureConfirmed = () => {
    onToggleSaveBlog(true);
  };

  return (
    <section style={{ height: 'calc(100% - 60px)' }}>
      <div className="pgmol-blog-toggle-save-container">
        <Toggle
          style={{ margin: '0' }}
          onToggle={(v, b) => toggleSaveBlog(b)}
          toggled={saveBlog}
          label={t('Enable Users to Save Blog Articles')}
          trackSwitchedStyle={{
            backgroundColor: themeStyles.primaryColorLight,
          }}
          thumbSwitchedStyle={{ backgroundColor: themeStyles.primaryColor }}
        />
      </div>
      <div className="pgmol-blog-header-container">
        <Header
          {...blogData}
          title={getTitle(selected, chunks)}
          onChange={onFilterBlogData}
          onSelectAllResponses={onSelectAllBlogs}
          onDeselectAllResponses={onDeselectAllBlogs}
          actionMenu={
            <ActionMenu
              selected={selected}
              onDeleteSelected={() => onDeleteSelected(selected, chunks)}
              text={'plMatchSupportCentre'}
              customDeleteText={t('Are you sure you wish to delete?')}
            />
          }
          fullWidthHeader
        />
        <FlatButton
          label={t('Categories')}
          onClick={handleBlogCategoriesModalOpen}
          primary
        />
      </div>
      {/* <div className="pgmol-table-container">{body}</div> */}
      {pending && <CircularProgress />}

      <div className="pgmol-table-container pgmol-blog-table">
        {totalBlogs === 0 && !pending ? (
          <div className="empty-state">
            <EmptyIcon />
            <h5>
              <Trans>Looks like no blog posts have been added yet.</Trans>
            </h5>
          </div>
        ) : (
          <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
            <Table
              aria-label="customized table"
              stickyHeader
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell width="18%" className={classes.tableLeftCorner}>
                    <Trans>Title</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Content</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Categories</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Date Created</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Edit</Trans>
                  </TableCell>
                  <TableCell className={classes.tableRightCorner}>
                    <div className="options-container">
                      <Checkbox
                        disableRipple
                        style={{ color: themeStyles.primaryColor }}
                        checked={!!selectAll}
                        onChange={(e, isChecked) =>
                          isChecked ? onSelectAllBlogs() : onDeselectAllBlogs()
                        }
                      />

                      <ActionMenu
                        selected={selected}
                        onDeleteSelected={() =>
                          onDeleteSelected(selected, chunks)
                        }
                        text={'plMatchSupportCentre'}
                        customDeleteText={t('Are you sure you wish to delete?')}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredChunks &&
                  Object.keys(filteredChunks).map(key => (
                    <TableRow key={filteredChunks[key].date}>
                      <TableCell component="th" scope="row">
                        {parseHTMLString(filteredChunks[key].title) || '-'}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {parseHTMLString(filteredChunks[key].content) || '-'}...
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {filteredChunks[key].categories &&
                          filteredChunks[key].categories.map(({ label }) => (
                            <Chip sx={{ margin: '5px' }} label={label || ''} />
                          ))}
                      </TableCell>
                      <TableCell>
                        {moment(filteredChunks[key].date).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        <button
                          className={'edit-button'}
                          onClick={() => onUpdateBlog(key, filteredChunks[key])}
                        >
                          <Trans>Edit</Trans>
                        </button>
                      </TableCell>

                      <TableCell>
                        <div className="options-container">
                          <Checkbox
                            checked={!!filteredChunks[key].selected}
                            onChange={() => onSelect(key)}
                            style={{ color: themeStyles.primaryColor }}
                            disableRipple
                          />
                          <ActionMenu
                            selected={[key]}
                            onDeleteSelected={() =>
                              onDeleteSelected([key], chunks)
                            }
                            text={'blog'}
                            customDeleteText={t(
                              'Are you sure you wish to delete?',
                            )}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      {categoriesModalOpen && (
        <Dialog
          open={categoriesModalOpen || false}
          onRequestClose={handleBlogCategoriesModalClose}
          autoScrollBodyContent
          style={{ zIndex: 1200 }}
        >
          <div className="user-memberBodies-modal-content">
            <div className="modal-heading pgmol-modal-heading">
              <Trans>Blog Categories</Trans>
            </div>
          </div>
          <div className="pgmol-blog-categories-modal-container">
            <Divider />
            <div>
              <TextField
                id="new_category"
                placeholder={t('New Category')}
                value={cat}
                onChange={value => setCat(value)}
              />
              <FlatButton
                label={t('Add new')}
                onClick={() => addBlogCategory(cat)}
                disabled={!cat.length}
                primary
              />
            </div>
            <h4>
              <Trans>Added categories</Trans>
            </h4>
            <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '15px' }}>
              {blogCategories &&
                Object.keys(blogCategories).map(categoryId => (
                  <Chip
                    key={categoryId}
                    sx={{
                      marginRight: '5px',
                      marginBottom: '5px',
                      cursor: 'pointer',
                    }}
                    label={blogCategories[categoryId] || ''}
                    onDelete={() =>
                      setShowDeleteCategoryConfirmation({
                        open: true,
                        categoryKey: categoryId,
                      })
                    }
                  />
                ))}
            </div>
          </div>
        </Dialog>
      )}
      {blogData && blogData.modalOpen && (
        <BlogModal
          data={blogData}
          toggleBlogModal={toggleBlogModal}
          appname={appname}
          onFieldChange={onFieldChange}
          onMultipleValueFieldChange={onMultipleValueFieldChange}
          onSubmit={onSubmit}
          getBlogCategories={getBlogCategories}
          blogCategories={blogCategories}
        />
      )}
      {showConfirmationDialog && (
        <DialogConfirmation
          open={showConfirmationDialog}
          onClose={() => setShowConfirmationDialog(false)}
          message={t(
            `When you enable users to save blog articles, Member Login will be automatically enabled. Would you like to proceed?`,
          )}
          action={toggleSaveBlogFeatureConfirmed}
        />
      )}

      {showDeleteCategoryConfirmation.open && (
        <DialogConfirmation
          open={showDeleteCategoryConfirmation.open}
          onClose={() =>
            setShowDeleteCategoryConfirmation({ open: false, categoryKey: '' })
          }
          message={t(
            `Deletion will remove this category from any articles that it has been assigned to. Are you sure you want to delete this category.`,
          )}
          action={() =>
            onRemoveBlogCategory(showDeleteCategoryConfirmation.categoryKey)
          }
        />
      )}
    </section>
  );
};

export default Blog;
