import { debounce } from 'lodash';

import parseWPCategories from './parseWPCategories';
import firebase from '../../../../../../../firebase';

export const fieldChange = (appname, dataId, field, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child(field).set(value);

  return {
    type: 'TEXT_VALUE_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

export const designChange = (appname, dataId, field, value) => {
  const dataRef =
    field === 'layout'
      ? firebase.database().ref(`apps/${appname}/draft/always_data/${dataId}`)
      : firebase
          .database()
          .ref(`apps/${appname}/draft/always_data/${dataId}/design`);

  dataRef.child(field).set(value);

  return {
    type: 'BOX_FIELD_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};

const debounceTestApi = debounce(
  (appname, dataId, apiType, apiSource, apiKey, dispatch) => {
    dispatch(fieldChange(appname, dataId, 'testConnection', 'testing'));

    let url;
    let test;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    switch (apiType) {
      case 'wordpress': {
        const match = apiSource.match(/:\/\/(.*).wordpress.com/);
        url = match
          ? `https://public-api.wordpress.com/wp/v2/sites/${match[1]}.wordpress.com/`
          : `${apiSource}/wp-json/wp/v2/`;
        test = response => response.namespace === 'wp/v2';
        break;
      }

      // case 'categories': {
      //   url = `${apiSource}/wp-json/wp/v2/categories`;
      //   test = response => {
      //     response.namespace === 'wp/v2/categories';
      //     console.log('response', response);
      //   };
      //   //test = some function();
      //   break;
      // }

      default:
        dispatch(fieldChange(appname, dataId, 'testConnection', 'failure'));
        return false;
    }

    return fetch(url, { headers, method: 'get' })
      .then(response => response.json())
      .then(response => {
        if (test(response)) {
          return;
        }
        Promise.reject();
      })
      .then(() =>
        dispatch(fieldChange(appname, dataId, 'testConnection', 'success')),
      )
      .catch(() =>
        dispatch(fieldChange(appname, dataId, 'testConnection', 'failure')),
      );
  },
  2000,
);

export const testApi = (
  appname,
  dataId,
  apiType,
  apiSource,
  apiKey,
) => dispatch => {
  debounceTestApi(appname, dataId, apiType, apiSource, apiKey, dispatch);
};

export const fetchWpCategories = (appname, dataId, apiSource) => {
  return dispatch => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const url = `${apiSource}/wp-json/wp/v2/categories?per_page=100`;
    fetch(url, { headers, method: 'get' })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response) {
          dispatch(
            fieldChange(
              appname,
              dataId,
              'categories',
              parseWPCategories(response),
            ),
          );
          return;
        }
        return;
      })
      .catch(err => console.log(err));
  };
};
