const initialState = {
  pending: false,
  error: null,
  useDefaultCard: false,
  // termsAccepted: false,
  number: {
    value: null,
    error: null,
  },
  cvc: {
    value: null,
    error: null,
  },
  expiry: {
    value: null,
    error: null,
  },
  subscription: null,
  requiresAuthentication: false,
  paymentType: null,
  componentToAddon: null,
  paymentIntentClientSecret: null,
  subscriptionItemId: null,
  diySubToCancel: null,
  promoCode: null,
};

const payment = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_LOCAL_CARD':
      return {
        ...initialState,
      };
    case 'HYDRATE_CARDS':
      return {
        ...state,
        defaultCard: {
          ...action.data,
        },
        hasDefaultCard: true,
      };

    case 'USE_DEFAULT_CARD':
      return {
        ...state,
        useDefaultCard: true,
      };

    case 'USE_NEW_CARD':
      return {
        ...state,
        useDefaultCard: false,
      };

    case 'PAYMENT_FORM_CHANGE':
      return {
        ...state,
        [action.prop]: {
          value: action.value,
          error: action.error,
        },
      };

    case 'SUBMIT_PAYMENT_DETAILS':
      return {
        ...state,
        pending: true,
        error: null,
      };

    case 'PAYMENT_FAILURE':
      return {
        ...state,
        pending: false,
        error: action.err,
        subscription: null,
        requiresAuthentication: false,
        paymentIntentClientSecret: null,
        paymentType: null,
        componentToAddon: null,
        diySubToCancel: null,
        subscriptionItemId: null,
      };

    case 'PAYMENT_SUCCESS':
      return {
        ...state,
        useDefaultCard: false,
        subscription: null,
        requiresAuthentication: false,
        paymentIntentClientSecret: null,
        componentToAddon: null,
        paymentType: null,
        subscriptionItemId: null,
        diySubToCancel: null,
      };

    case 'PAYMENT_INTENT_CREATED':
      return {
        ...state,
        requiresAuthentication: true,
        paymentIntentClientSecret: action.paymentIntent.client_secret,
      };

    case 'SUBSCRIPTION_PAYMENT_REQUIRES_AUTHENTICATION':
      return {
        ...state,
        subscription: action.subscription,
        requiresAuthentication: true,
        paymentIntentClientSecret:
          action.subscription.latest_invoice.payment_intent.client_secret,
        diySubToCancel: action.diySubToCancel,
      };

    case 'PAYMENT_REQUIRES_AUTHENTICATION': {
      return {
        ...state,
        paymentIntentClientSecret: action.paymentIntentClientSecret,
        requiresAuthentication: true,
        componentToAddon: action.componentToAddon,
        paymentType: action.paymentType,
        subscriptionItemId: action.subscriptionItemId,
      };
    }

    case 'FETCHING_PROMO_CODE': {
      return {
        ...state,
        pending: true,
      };
    }

    case 'FETCHED_PROMO_CODE': {
      return {
        ...state,
        pending: false,
        promoCode: action.promoCodeDetails,
      };
    }
    case 'LOGOUT_SUCCESS':
    case 'CLEAR_PAYMENT_FORM':
      return initialState;

    default:
      return state;
  }
};

export default payment;
