import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { SquareButton } from '../../../app/components/buttons';
import {
  USER_CLICKED_UPGRADE,
  PLATFORM_EVENTS,
  sendSlackNotification,
} from '../../../utils';

class UpgradePlan extends Component {
  static propTypes = {
    appname: PropTypes.string.isRequired,
    className: PropTypes.string,
    integration: PropTypes.shape({
      siteTitle: PropTypes.string,
      siteUrl: PropTypes.string,
      type: PropTypes.oneOf(['Wix', 'Weebly']),
    }),
    history: PropTypes.object.isRequired,
    subscription: PropTypes.string,
    accountId: PropTypes.string.isRequired,
    displayWixPayment: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    integration: null,
    subscription: null,
  };

  handleSubscribe = () => {
    const {
      appname,
      history,
      displayWixPayment,
      integration,
      subscription,
      accountId,
    } = this.props;

    sendSlackNotification({
      appname,
      notificationTitle: USER_CLICKED_UPGRADE,
      account: accountId,
      currentPlan: subscription,
      slackChannel: PLATFORM_EVENTS,
    });

    if (!integration || integration.type === 'Weebly') {
      return history.push(`/${appname}/choose_plan`);
    }

    return displayWixPayment(appname, history);
  };

  render() {
    const { className } = this.props;

    return (
      <SquareButton className={className} onClick={this.handleSubscribe}>
        <Trans>Upgrade</Trans>
      </SquareButton>
    );
  }
}

export default UpgradePlan;
