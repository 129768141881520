import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../../../../app/components/textField';
import Container from '../../../../app/components/container';
import { FlatButton } from '../../../../app/components/buttons';
import Legal from '../legal';
import './styles.css';

const Profile = ({
  firstName,
  lastName,
  pending,
  isDirty,
  error,
  onNameChange,
  onSubmit,
  onOpenLegalModal,
}) => (
  <div className="profile-form-container">
    <div className="profile-form">
      <div className="profile-fieldset">
        <Legal />
        <div>
          <TextField
            id="firstName"
            placeholder="First Name"
            {...firstName}
            onChange={value => onNameChange('firstName', value)}
          />
          <TextField
            id="lastName"
            placeholder="First Name"
            {...lastName}
            onChange={value => onNameChange('lastName', value)}
          />
        </div>
      </div>
      <Container>
        <small>{error}</small>
      </Container>
      <Container>
        <FlatButton
          label="Save Changes"
          onClick={onSubmit}
          disabled={pending || !isDirty}
          primary
        />
      </Container>
      <Container className="legal-links-container">
        <button
          className="inline-button underline-text"
          onClick={() => onOpenLegalModal('terms-and-conditions')}
        >
          Terms of Service
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          className="inline-button underline-text"
          onClick={() => onOpenLegalModal('end-user-license')}
        >
          End User Licence
        </button>
      </Container>
    </div>
  </div>
);

Profile.propTypes = {
  firstName: PropTypes.object.isRequired,
  lastName: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onNameChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onOpenLegalModal: PropTypes.func.isRequired,
};

Profile.defaultProps = {
  error: null,
};

export default Profile;
