import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { Provider } from 'react-redux';

import {
  SelectableCardMenu,
  SelectableCard,
  CardChip,
} from '../../../../app/components/selectableCardMenu';
import { SimpleToolbar } from '../../../../app/modules/toolbar';
import whatsappBundles from '../../../../plans/whatsappBundles';
import whatsappBundlesWixWeebly from '../../../../plans/whatsappBundlesWixWeebly';
import { store } from '../../../..';

import sendImg from './Send.png';
import './styles.css';

const WhatsappBundles = ({
  history,
  location,
  intl,
  integration,
  setAmount,
}) => {
  // Fetch the wix url where the user can purchase SMS
  const fetchWixSMSUrl = key => {
    const { wixSMSPackages } = integration;

    if (!wixSMSPackages) return;

    switch (key) {
      case 'extraLarge':
        return wixSMSPackages['1000SMS'];
      case 'large':
        return wixSMSPackages['500SMS'];
      case 'medium':
        return wixSMSPackages['250SMS'];
      case 'small':
        return wixSMSPackages['100SMS'];
      default:
    }
  };

  const mapAvailablePlans = () => {
    const plan = integration ? whatsappBundlesWixWeebly : whatsappBundles;
    const isWix = integration && integration.type === 'Wix';

    return Object.keys(plan).map(key => (
      <SelectableCard
        key={key}
        onClick={() => {
          if (isWix) {
            const url = fetchWixSMSUrl(key);
            if (url) window.open(url, '_blank');
          } else {
            setAmount(key, integration);
            history.push({
              pathname: '/purchase_credits',
              query: {
                bundle: key,
              },
              state: { origin: location.state.origin },
            });
          }
        }}
        boxShadowOnHover
      >
        <h5 className="sms-bundle-title">
          {whatsappBundles[key].amount} Credits
        </h5>
        <div className="sms-price-container">
          <p className="sms-bundle-price">
            <FormattedNumber
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              format={intl.currency}
              value={whatsappBundles[key].prices.total[intl.currency]}
            />
          </p>
          <small className="sms-bundle-breakdown">
            <FormattedNumber
              maximumFractionDigits={2}
              format={intl.currency}
              value={whatsappBundles[key].prices.breakdown[intl.currency]}
            />
            <br />
            per message
          </small>
        </div>
        <CardChip label="Select Bundle" />
      </SelectableCard>
    ));
  };

  return (
    <div className="overlay">
      <Provider store={store}>
        <SimpleToolbar />
      </Provider>
      <SelectableCardMenu title="Select a bundle" image={sendImg}>
        {mapAvailablePlans()}
      </SelectableCardMenu>
    </div>
  );
};

WhatsappBundles.propTypes = {
  setAmount: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    currency: PropTypes.string.isRequired,
  }).isRequired,
  integration: PropTypes.shape({
    type: PropTypes.oneOf(['Wix', 'Weebly']),
  }),
};

WhatsappBundles.defaultProps = {
  integration: {
    type: '',
  },
};

export default WhatsappBundles;
