import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CreateIntegrationApp from './CreateIntegrationApp';
import { createNewApp, createNewDomain, domainChange } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state,
  apps: state.account.organisation.apps,
  createIntegrationApp: state.editor.customise.createIntegrationApp,
  integration: state.account.organisation.integration,
});

const mapDispatchToProps = dispatch => ({
  submitAppCreation: (domain, callback) =>
    dispatch(createNewApp(domain, callback)),
  onDomainChange: domain => dispatch(domainChange(domain)),
  defineDefaultDomain: domain => dispatch(createNewDomain(domain)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CreateIntegrationApp),
);
