import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import Dialog from 'material-ui/Dialog';
import { FlatButton } from '../../../../app/components/buttons';

const actions = (action, onClose) => [
  <FlatButton
    label={i18n.t('Cancel')}
    secondaryWhite
    onClick={() => onClose()}
  />,
  <FlatButton
    label={i18n.t('Confirm')}
    primary
    onClick={() => {
      action();
      onClose();
    }}
  />,
];

const DialogConfirmation = ({ open, onClose, message, action }) => {
  return (
    <Dialog
      actions={
        action !== null
          ? actions(action, onClose)
          : [actions(action, onClose)[0]]
      }
      open={open}
      onRequestClose={() => onClose()}
      style={{ textAlign: 'center' }}
      actionsContainerStyle={{ textAlign: 'center' }}
    >
      {message}
    </Dialog>
  );
};

DialogConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  action: PropTypes.func,
};

DialogConfirmation.defaultProps = {
  action: null,
};

export default DialogConfirmation;
