import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LayoutContainer = styled.div`
  display: block;
  padding: 20px 10px;
`;

class LayoutTab extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    onLayoutChange: PropTypes.func.isRequired,
  };

  static childContextTypes = {
    onLayoutSelected: PropTypes.func.isRequired,
  };

  getChildContext() {
    return { onLayoutSelected: this.props.onLayoutChange };
  }

  render() {
    return <LayoutContainer>{this.props.options}</LayoutContainer>;
  }
}

export default LayoutTab;
