import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Trans, useTranslation } from 'react-i18next';

import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {
  Checkbox,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import ActionMenu from 'app/components/actionMenu';

import { downloadCSV, objectToArray, arrayToObject } from 'utils';

import styles from './styles.module.scss';
import Search from '../../../../app/components/search';
import { Empty as EmptyIcon } from '../../../../app/components/icons';
import LoyaltyTable from './components/loyaltyTable/LoyaltyTable';
import LoyaltyDisabledWrapper from './components/LoyaltyDisabledWrapper';
import themeStyles from '../../../../_export.scss';

const useStyles = makeStyles(() => ({
  appBar: {
    position: 'relative',
    color: themeStyles.primaryTextColor,
    backgroundColor: themeStyles.primaryColor,
  },
  title: {
    marginLeft: 20,
    flex: 1,
  },
  table: {
    '& .MuiTableCell-head': {
      color: 'black',
      backgroundColor: '#EAEEFF',
      fontSize: 14,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
      textAlign: 'center',
      borderBottom: 'none',
    },
    '& .MuiTableCell-body': {
      color: 'black',
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Raleway, sans-serif',
      textAlign: 'center',
      borderBottom: '1px solid #d3d3d3',
    },
    '& td > .options-container': {
      '& span': {
        color: `${themeStyles.primaryColor}!important`,
      },
      '& svg': {
        fill: `${themeStyles.primaryColor}!important`,
      },
    },
  },
  tableLeftCorner: { borderTopLeftRadius: 10 },
  tableRightCorner: { borderTopRightRadius: 10 },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const Scan = ({
  chunks: users,
  filteredChunks: filteredUsers,
  getUsers,
  getGroups,
  history,
  pending,
  filter,
  loyaltyComponentEnabled,
  resetUsersData,
  selectAll,
  selected,
  groups,
  onSelect,
  onUpdateChecked,
  onFilterUserData,
  onSelectAllUsers,
  onDeselectAllUsers,
  onAddLoyaltyComponent,
  onDeleteSelected,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [modalBody, setModalBody] = useState({});
  const [loyaltyCards, setLoyaltyCards] = useState({});
  const [totalUsers, setTotalUsers] = useState(0);
  const [freeItem, setFreeItem] = useState('');
  const classes = useStyles();

  const handleClickOpen = user => {
    setOpen(true);
    const { firstName, lastName, email, loyalty } = filteredUsers[user];
    setLoyaltyCards(loyalty);
    setFreeItem(
      loyalty && Object.keys(loyalty).length > 0 && Object.keys(loyalty)[0],
    );
    setModalBody({ firstName, lastName, email, loyalty });
  };

  const handleSelectChange = event => {
    setFreeItem(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setModalBody({});
    setLoyaltyCards({});
  };

  const handleCheck = (hasScannerAccess, user) => {
    confirmAlert({
      title: `${t('Confirm to')} ${
        hasScannerAccess ? t('remove') : t('grant')
      } ${t('access.')}`,
      message: `${
        hasScannerAccess
          ? `${t('Are you sure you want to remove')} ${
              filteredUsers[user].email
            } ${t('from having access to the scanner?')}`
          : `${t('Are you sure you want to grant')} ${
              filteredUsers[user].email
            } ${t(
              'access to the scanner? Please make sure this is the right email to prevent misuse.',
            )}`
      }`,
      buttons: [
        {
          label: t('Yes'),
          onClick: () => onUpdateChecked(hasScannerAccess, user),
        },
        {
          label: t('No'),
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    resetUsersData();
    getUsers();
    getGroups();
    return () => {
      resetUsersData();
    };
  }, []);

  useEffect(() => {
    const size = Object.keys(filteredUsers).length;
    setTotalUsers(size);
  }, [filteredUsers]);

  const getLastScanned = user =>
    moment(
      Object.values(user.loyalty)
        .map(loyalty => {
          const timeStamps = Object.values(loyalty.all_stamps);
          return timeStamps[timeStamps.length - 1].timestamp;
        })
        .reduce((latestStamp, currentStamp) =>
          latestStamp < currentStamp ? currentStamp : latestStamp,
        ),
    ).format('MMMM Do YYYY h:mm:ss a');

  const getCsvData = (selectedUserKeys, users) => {
    const csvColumns = [
      t('First Name'),
      t('Last Name'),
      t('Email'),
      t('Verified'),
      t('Scanner Access'),
      t('Last Scanned'),
      t('Free Item'),
      t('Total Visits'),
      t('Visits Breakdown'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selectedUserKeys.forEach(userKey => {
      const {
        firstName,
        lastName,
        email,
        emailVerified,
        hasScannerAccess,
      } = users[userKey];

      let freeitem = [];
      let visits = 0;
      let visitStats = [];
      if (users[userKey].loyalty) {
        const keys = Object.keys(users[userKey].loyalty);
        freeitem = keys.map(item => users[userKey].loyalty[item].freeItem);
        // freeitem = users[userKey].loyalty[keys[0]].freeItem;

        let allStamps = {};
        keys.map(key => {
          let stamp = {};
          visits += users[userKey].loyalty[key].visits || 0;
          if (users[userKey].loyalty[key]) {
            const formatStamps = objectToArray(
              users[userKey].loyalty[key].all_stamps,
            );
            const formattedResult = formatStamps.map(item => {
              return {
                ...item,
                value: {
                  ...item.value,
                  freebie: users[userKey].loyalty[key].freeItem,
                },
              };
            });
            stamp = arrayToObject(formattedResult);
          }

          // const stamp = users[userKey].loyalty[key]
          //   ? users[userKey].loyalty[key].all_stamps
          //   : {};
          allStamps = { ...allStamps, ...stamp };
        });

        const allVisits = objectToArray(allStamps);
        visitStats = allVisits.map(item => {
          // visits += item.value.visit;
          return `${item.value.visit} ${t('visit')}${
            item.value.visit > 1 ? 's' : ''
          } ${t('on')} ${item.value.visit} ${moment(
            item.value.timestamp,
          ).format('MMMM Do YYYY h:mm:ss a')} ${t('for')} ${
            item.value.freebie
          }`;
        });
      }

      data += `${firstName},${lastName},${email},${
        emailVerified ? t('yes') : t('no')
      },${hasScannerAccess ? t('yes') : t('no')},${
        users[userKey].loyalty ? getLastScanned(users[userKey]) : '-'
      },${freeitem.join('|')},${parseInt(visits, 10)}, ${visitStats.join(
        ' | ',
      )}\n`;
    });

    return data;
  };

  const content = (
    <>
      <div className={styles.header}>
        <span className={styles.header_text}>
          <Trans>Total Users</Trans>: <b>{totalUsers}</b>
        </span>
        <div className={styles.header_search}>
          <Search
            name="search"
            placeholder={t('Search...')}
            value={filter}
            onChange={onFilterUserData}
            onClear={() => onFilterUserData('')}
          />
        </div>
      </div>

      {pending && <CircularProgress />}
      {totalUsers === 0 && !pending ? (
        <div className="empty-state">
          <EmptyIcon />
          <h5>
            <Trans>You don't have any users.</Trans>
          </h5>
        </div>
      ) : (
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <Table
            aria-label={t('customized table')}
            stickyHeader
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell width="18%" className={classes.tableLeftCorner}>
                  <Trans>User Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Email</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Verified</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Scanner Access</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Scans</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Last Scanned</Trans>
                </TableCell>
                <TableCell className={classes.tableRightCorner}>
                  <div className="options-container">
                    <Checkbox
                      disableRipple
                      style={{ color: themeStyles.primaryColor }}
                      checked={!!selectAll}
                      onChange={(e, isChecked) =>
                        isChecked ? onSelectAllUsers() : onDeselectAllUsers()
                      }
                    />
                    <ActionMenu
                      selected={selected}
                      onDeleteSelected={() => onDeleteSelected(selected, users)}
                      onDownloadSelected={() => {
                        downloadCSV(getCsvData(selected, users), 'users');
                      }}
                      text={t('users')}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers &&
                Object.keys(filteredUsers).map(key => (
                  <TableRow key={filteredUsers[key].email}>
                    <TableCell component="th" scope="row">
                      {`${filteredUsers[key].firstName} ${filteredUsers[key].lastName}` ||
                        '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {filteredUsers[key].email || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {filteredUsers[key].emailVerified ? <Done /> : <Clear />}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={!!filteredUsers[key].hasScannerAccess}
                        onClick={() =>
                          handleCheck(
                            !!filteredUsers[key].hasScannerAccess,
                            key,
                          )
                        }
                        style={{
                          color: themeStyles.primaryColor,
                        }}
                        disableRipple
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <button
                        className={styles.button}
                        onClick={() => handleClickOpen(key)}
                      >
                        <Trans>Check Scans</Trans>
                      </button>
                    </TableCell>
                    <TableCell>
                      {filteredUsers[key].loyalty
                        ? getLastScanned(filteredUsers[key])
                        : '-'}
                    </TableCell>
                    <TableCell>
                      <div className="options-container">
                        <Checkbox
                          checked={!!filteredUsers[key].selected}
                          onChange={() => onSelect(key)}
                          style={{ color: themeStyles.primaryColor }}
                          disableRipple
                        />
                        <ActionMenu
                          selected={[key]}
                          onDeleteSelected={() =>
                            onDeleteSelected([key], users)
                          }
                          onDownloadSelected={() =>
                            downloadCSV(getCsvData([key], users), 'users')
                          }
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {modalBody && Object.keys(modalBody).length > 0 && (
              <Typography variant="h3" className={classes.title}>
                {modalBody.firstName && modalBody.firstName}{' '}
                {modalBody.lastName && modalBody.lastName}
              </Typography>
            )}
          </Toolbar>
        </AppBar>
        {loyaltyCards && Object.keys(loyaltyCards).length > 0 && (
          <>
            <div className={styles.loyalty_container}>
              <div>
                <Trans>Showing details for</Trans>{' '}
                {loyaltyCards[freeItem].freeItem}{' '}
                <Trans>loyalty card. Stamps collected</Trans>{' '}
                {loyaltyCards[freeItem].visits}/
                {loyaltyCards[freeItem].numberOfVisits}.
              </div>
              <Select value={freeItem} onChange={handleSelectChange}>
                {Object.keys(loyaltyCards).map(key => (
                  <MenuItem key={key} value={key}>
                    {loyaltyCards[key].freeItem}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div style={{ margin: '60px auto 0', width: '80%' }}>
              {loyaltyCards[freeItem].all_stamps ? (
                <LoyaltyTable allStamps={loyaltyCards[freeItem].all_stamps} />
              ) : (
                <div className="empty-state">
                  <EmptyIcon />
                  <h5>
                    <Trans>This card does not have any data yet.</Trans>
                  </h5>
                </div>
              )}
            </div>
          </>
        )}
        {!modalBody.loyalty && (
          <div className="empty-state">
            <EmptyIcon />
            <h5>
              <Trans>This user does not have any scans yet.</Trans>
            </h5>
          </div>
        )}
      </Dialog>
    </>
  );

  return !loyaltyComponentEnabled ? (
    <LoyaltyDisabledWrapper
      onAddLoyaltyComponent={onAddLoyaltyComponent}
      history={history}
    >
      {content}
    </LoyaltyDisabledWrapper>
  ) : (
    content
  );
};

export default Scan;
