import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactImageCrop, { makeAspectCrop } from 'react-image-crop';
import _isEqual from 'lodash/isEqual';
import 'react-image-crop/dist/ReactCrop.css';

import Warning from '../../../../app/components/warning';
import HiddenInput from './HiddenInput';
import HiddenFileInput from './HiddenFileInput';
import CropDialog from './CropDialog';
import ModalToolbar from './modalToolbar';
import ImagePreview from './imagePreview';
import FileAndUrlDialog from './FileAndUrlDialog';

import './styles.css';

const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB
const formatAllowedToCrop = ['image/png', 'image/jpeg', 'image/jpg'];
const formatsAllowed = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/gif',
  'video/mp4',
];

class ImageUpload extends Component {
  static propTypes = {
    appName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageType: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    src: PropTypes.string,
    targetId: PropTypes.string.isRequired,
    firebaseUrl: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    pending: PropTypes.bool.isRequired,
    avatar: PropTypes.bool,
    showCropAreaSizes: PropTypes.bool,
    file: PropTypes.object,
    fileType: PropTypes.oneOf(['jpeg', 'png']),
    config: PropTypes.array,
    cropArray: PropTypes.array.isRequired,
    currentIndex: PropTypes.number.isRequired,
    error: PropTypes.string,
    warning: PropTypes.string,
    clearWarning: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onPrevStep: PropTypes.func.isRequired,
    onNextStep: PropTypes.func.isRequired,
    onSelectStep: PropTypes.func.isRequired,
    onReceiveCrop: PropTypes.func.isRequired,
    onOpenDialog: PropTypes.func.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    onReceiveImage: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onWarn: PropTypes.func.isRequired,
    onUnmontComponent: PropTypes.func.isRequired,
    noDelete: PropTypes.bool,
    clearError: PropTypes.func.isRequired,
    reportError: PropTypes.func.isRequired,
    isVideoAllowed: PropTypes.bool,
    onFileAndUrlDialogCancel: PropTypes.func.isRequired,
    onOpenFileAndUrlDialog: PropTypes.func.isRequired,
    fileAndUrlDialogOpen: PropTypes.bool.isRequired,
    onFileUploadUrlChange: PropTypes.func.isRequired,
    fileUploadUrl: PropTypes.string.isRequired,
  };

  static defaultProps = {
    height: null,
    width: null,
    config: null,
    minWidth: 0,
    minHeight: 0,
    showCropAreaSizes: false,
    file: null,
    fileType: 'jpeg',
    crop: null,
    cropArray: [],
    open: false,
    currentIndex: 0,
    pending: true,
    src: null,
    avatar: false,
    error: null,
    warning: null,
    noDelete: false,
    firebaseUrl: '',
    imageType: '',
    targetId: new Date().toISOString(),
    isVideoAllowed: false,
    fileAndUrlDialogOpen: false,
    fileUploadUrl: '',
    source: null,
  };

  state = {
    crop: {},
    cropAreaSizes: {},
  };

  componentWillMount() {
    const { config } = this.props;
    // settings for this instance of cropper
    // are passed parent component
    if (config) {
      this.props.initialize(config);
    } else {
      this.props.initialize([{}]);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!_isEqual(this.props.config, nextProps.config)) {
      this.props.initialize(nextProps.config);
    }

    if (nextProps.currentIndex !== this.props.currentIndex) {
      this.setCrop(nextProps, this.state.image);
    }
  }

  componentWillUnmount() {
    const { file } = this.props;

    if (file) {
      // Important: react-dropzone doesn't manage dropped files.
      // You need to destroy the object URL yourself whenever you
      // don't need the preview by calling window.URL.revokeObjectURL(file.preview);
      // to avoid memory leaks.
      window.URL.revokeObjectURL(file.preview);
    }
    //this.props.onUnmontComponent();
  }

  getAspectRatio = dimensions => {
    const values = dimensions instanceof Array ? dimensions[0] : dimensions;
    if (!values) return 0;

    const sizes = values.split('x');
    const width = parseInt(sizes[0], 10);
    const height = parseInt(sizes[1], 10);

    return width / height;
  };

  /**
   * @param {object} nextProps
   * @param {file} image
   * @param {bool} reset
   */

  setCrop = (nextProps, image, reset) => {
    if (!image) {
      return null;
    }

    const { cropArray, currentIndex, onReceiveCrop, avatar } = nextProps;

    const { dimensions, aspect, width, height } = cropArray[currentIndex];
    let cropAspect = aspect;
    let cropWidth =
      width && aspect
        ? this.calculateWidthPercentageFromAspect(aspect, image)
        : 100;
    const cropHeight = height || 100;
    // only set aspect, width, height if not already set
    // e.g. when navigating back we wish to skip this step and preserve previous crop
    if (!cropAspect || reset) {
      cropAspect = dimensions ? this.getAspectRatio(dimensions) : cropAspect;
      // calculate width for instances where aspect ratio of image and crop is incompatibtle with 100% width
      if (
        (cropAspect > 0 && image.width * cropAspect > image.height) ||
        avatar
      ) {
        cropAspect = 1;
      }
    }

    if (cropAspect > 0) {
      if (image.width > image.height) {
        cropWidth = (image.height / image.width) * cropAspect * 100;
      }
      const xAxis = (100 - cropWidth) / 2;
      return this.setState(
        {
          crop:
            this.props.imageType === 'customLogo'
              ? {
                  unit: 'px',
                  x: 2,
                  y: 2,
                  width: 91,
                  height: 50,
                }
              : makeAspectCrop(
                  {
                    aspect: cropAspect,
                    width: cropWidth,
                    x: xAxis,
                    y: 0,
                  },
                  image.width / image.height,
                ),
          image,
          cropAreaSizes: this.calculateCropAreaSize(
            cropWidth,
            cropHeight,
            image,
          ),
        },
        () => onReceiveCrop(this.state.crop),
      );
    }

    return this.setState(
      {
        crop: {
          width: cropWidth,
          height: cropHeight,
          x: 0,
          y: 0,
        },
        image,
        cropAreaSizes: this.calculateCropAreaSize(cropWidth, cropHeight, image),
      },
      () => onReceiveCrop(this.state.crop),
    );
  };

  calculateCropAreaSize = (cropWidth, cropHeight, image = this.state.image) => {
    if (!image) {
      return {};
    }
    return {
      width: Math.round(image.naturalWidth * (cropWidth / 100)),
      heigth: Math.round(image.naturalHeight * (cropHeight / 100)),
    };
  };

  calculateWidthPercentageFromAspect = (aspect, image) =>
    (aspect * image.height * 100) / image.width;

  handleAction = () => {
    const { file, onOpenDialog } = this.props;
    if (!file) {
      return this.props.onOpenFileAndUrlDialog();
    }
    return onOpenDialog();
  };

  handleFileChange = async e => {
    const {
      error,
      clearError,
      reportError,
      onReceiveImage,
      fileUploadUrl,
    } = this.props;
    let file;
    if (!e.target.files && fileUploadUrl) {
      try {
        const res = await fetch(fileUploadUrl);
        file = await res.blob(); // Gets the response and returns it as a blob
      } catch (err) {
        this.props.onFileAndUrlDialogCancel();
        reportError(`Cannot get image from this url`);
      }
    }

    if (e.target && e.target.files && e.target.files[0])
      file = e.target.files[0];

    if (file && file.size > MAX_IMAGE_SIZE) {
      const msg = this.props.isVideoAllowed ? 'file' : 'image';
      this.props.onFileAndUrlDialogCancel();
      reportError(
        `This ${msg} is too large. Please make sure it's under 2MB to proceed.`,
      );
    } else if (
      file &&
      file.type &&
      this.props.isVideoAllowed &&
      !formatsAllowed.includes(file.type)
    ) {
      this.props.onFileAndUrlDialogCancel();
      reportError(`This file format is not supported`);
    } else if (file && file.size < MAX_IMAGE_SIZE && error) {
      clearError();
    }

    if (file) {
      file.preview = window.URL.createObjectURL(file);
      this.props.onFileAndUrlDialogCancel();
      this.props.onFileUploadUrlChange('');
      onReceiveImage(file);
    }

    if (!file && error) {
      reportError('Failed to upload image, please refresh and try again');
    }
  };

  handleImageLoad = image => {
    const { clearWarning } = this.props;

    clearWarning();
    this.setCrop(this.props, image, true);
  };

  handleChange = crop => {
    const cropAreaSizes = this.calculateCropAreaSize(crop.width, crop.height);

    return this.setState({ crop, cropAreaSizes });
  };

  handleBackClick = () => {
    const { currentIndex, onPrevStep, onCloseDialog } = this.props;

    const isFirst = currentIndex === 0;

    if (isFirst) {
      return onCloseDialog();
    }

    return onPrevStep();
  };

  handleSubmit = () => {
    const {
      appName,
      cropArray,
      currentIndex,
      firebaseUrl,
      fileType,
      imageType,
      minHeight,
      minWidth,
      onNextStep,
      onSubmit,
      onWarn,
      file,
      source,
    } = this.props;

    const { image } = this.state;
    const isFinal = cropArray.length - 1 === currentIndex;

    if (
      formatAllowedToCrop.includes(file.type) &&
      (image.naturalWidth < this.props.minWidth ||
        image.naturalHeight < this.props.minHeight)
    ) {
      onWarn(`
        We recommend you select an image of at least ${minWidth}x${minHeight}px.
        The image you selected is only ${image.naturalWidth}x${image.naturalHeight}px.
      This is to ensure the image will not look blurred.
    `);
    }

    if (isFinal) {
      return onSubmit(firebaseUrl, fileType, appName, imageType, source);
    }

    return onNextStep();
  };

  openFileSelector = () => this.fileSelector.click();

  hasAreaSizeMessage = () => {
    const { showCropAreaSizes } = this.props;
    const { cropAreaSizes } = this.state;
    return (
      showCropAreaSizes &&
      cropAreaSizes && (
        <p>
          {`Current area selected: ${cropAreaSizes.width}px by ${cropAreaSizes.heigth}px`}
        </p>
      )
    );
  };

  render() {
    const {
      title,
      src,
      file,
      cropArray,
      currentIndex,
      open,
      pending,
      error,
      warning,
      onSelectStep,
      onCloseDialog,
      onReceiveCrop,
      onFileAndUrlDialogCancel,
      fileAndUrlDialogOpen,
      onFileUploadUrlChange,
      fileUploadUrl,
    } = this.props;

    const isFinal = cropArray.length - 1 === currentIndex;
    const isFirst = currentIndex === 0;

    const titleNode = (
      <ModalToolbar
        title={title}
        cropArray={cropArray}
        currentIndex={currentIndex}
        onSelectStep={onSelectStep}
        onClose={onCloseDialog}
      />
    );

    const cropSrc = file && file.preview ? file.preview : src;
    const isGif = file && file.type === 'image/gif';
    const isVideo = file && file.type.match('video.*');
    const reactCrop =
      // eslint-disable-next-line no-nested-ternary
      cropSrc && !isVideo && !isGif ? (
        <div>
          {this.hasAreaSizeMessage()}
          <ReactImageCrop
            src={cropSrc}
            crop={this.state.crop}
            minWidth={this.state.minWidth}
            onImageLoaded={this.handleImageLoad}
            onChange={this.handleChange}
            onComplete={onReceiveCrop}
            crossorigin="true"
            keepSelection
            style={{ maxWidth: '50%' }}
          />
        </div>
      ) : isGif ? (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img src={cropSrc} />
      ) : this.props.isVideoAllowed && isVideo ? (
        // eslint-disable-next-line jsx-a11y/alt-text
        <video width="100%" controls>
          <source src={cropSrc} type="video/mp4" />
          <source src={cropSrc} type="video/webm" />
          <track
            src="captions_en.vtt"
            kind="captions"
            srcLang="en"
            label="english_captions"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <span />
      ); // provide empty span as children are required

    const errorMessage = (error || warning) && (
      <Warning>{error || warning}</Warning>
    );
    return (
      <div>
        <ImagePreview {...this.props} onChangeImage={this.handleAction} />
        {errorMessage}
        {this.props.isVideoAllowed ? (
          <HiddenFileInput
            innerRef={node => {
              this.fileSelector = node;
            }}
            onChange={this.handleFileChange}
          />
        ) : (
          <HiddenInput
            innerRef={node => {
              this.fileSelector = node;
            }}
            onChange={this.handleFileChange}
          />
        )}
        <CropDialog
          open={open}
          title={titleNode}
          cancelLabel={isFirst ? 'Cancel' : 'Back'}
          changeLabel={
            this.props.isVideoAllowed ? 'Change File' : 'Change Image'
          }
          submitLabel={isFinal ? 'Submit' : 'Next'}
          changeDisabled={pending}
          submitDisabled={pending || error || false}
          onCancel={this.handleBackClick}
          onChange={this.props.onOpenFileAndUrlDialog}
          onSubmit={this.handleSubmit}
        >
          {reactCrop}
          {errorMessage}
        </CropDialog>
        <FileAndUrlDialog
          open={fileAndUrlDialogOpen}
          onCancel={onFileAndUrlDialogCancel}
          openFileSelector={this.openFileSelector}
          url={fileUploadUrl}
          onInputChange={onFileUploadUrlChange}
          handleFileChange={this.handleFileChange}
        />
      </div>
    );
  }
}

export default ImageUpload;
