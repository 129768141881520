import firebase from '../../../../../../firebase';

export const addFeedback = async ({
  feedbackId,
  name,
  userId,
  appname,
  feedbackText,
  rating,
  complete,
  feedbackPagePath,
}) => {
  const body = JSON.stringify({
    feedbackId,
    name,
    userId,
    appname,
    feedbackText,
    rating,
    feedbackPagePath,
    complete,
  });
  const headers = new Headers();
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  headers.append('x-access-token', token);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(
    `${process.env.REACT_APP_FEEDBACK_API}/add-feedback`,
    {
      method: 'post',
      headers,
      body,
      mode: 'cors',
    },
  );

  if (!response.ok) {
    const errorMsg = await response.json();
    throw new Error(errorMsg.message);
  }
  return response.json();
};
