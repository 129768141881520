import React from 'react';
import { Route } from 'react-router-dom';

import EditGroupModal from './container';

function EditGroupModalRouter() {
  return (
    <Route path="/:appname/members/groups/:group?">
      {props => <EditGroupModal {...props} />}
    </Route>
  );
}

export default EditGroupModalRouter;
