import React from 'react';

import './styles.css';

const History = () => (
  <svg
    className="icon push-history-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40.5 46.97"
  >
    <title>Push Notification History</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M36.75,5.64h-3.9L35.91,3l-1-1.13-5.14,4.5,5.14,4.49,1-1.12L33,7.14h3.74A2.25,2.25,0,0,1,39,9.39v28a2.25,2.25,0,0,1-2.25,2.25H26.44a6.45,6.45,0,1,0,0,1.5H36.75a3.76,3.76,0,0,0,3.75-3.75v-28A3.75,3.75,0,0,0,36.75,5.64ZM20,45.47a5,5,0,1,1,5-5A5,5,0,0,1,20,45.47Z"
        />
        <path
          class="cls-1"
          d="M4.44,37,7.5,39.64H3.75A2.25,2.25,0,0,1,1.5,37.39v-28A2.25,2.25,0,0,1,3.75,7.14h9.81a6.52,6.52,0,1,0,0-1.5H3.75A3.75,3.75,0,0,0,0,9.39v28a3.76,3.76,0,0,0,3.75,3.75h3.6L4.44,43.69l1,1.12,5.13-4.49-5.13-4.5ZM20,1.5a5,5,0,1,1-5,5A5,5,0,0,1,20,1.5Z"
        />
        <path
          class="cls-1"
          d="M20,30.92h.54a2.1,2.1,0,0,0,2.1-2.09v0h2.28A2,2,0,0,0,25.74,25a.71.71,0,0,1-.38-.44h0a.5.5,0,0,1,0-.17V21.93a4,4,0,0,0-3.42-4,1.84,1.84,0,0,0-3.4,0,4,4,0,0,0-3.42,4v2.49a.5.5,0,0,1,0,.17.71.71,0,0,1-.38.44,2,2,0,0,0-1.12,1.81,2,2,0,0,0,2,1.95h2.3v0A2.1,2.1,0,0,0,20,30.92Zm1.14-2.09a.59.59,0,0,1-.6.59H20a.59.59,0,0,1-.6-.59v0H21.1Zm-5.54-1.54a.48.48,0,0,1-.48-.48.49.49,0,0,1,.28-.44A2.21,2.21,0,0,0,16.53,25a2.15,2.15,0,0,0,.07-.56V21.93a2.54,2.54,0,0,1,2.53-2.53h.75v-.75a.34.34,0,0,1,.68,0v.75h.75a2.54,2.54,0,0,1,2.53,2.53v2.49a2.1,2.1,0,0,0,.07.56,2.21,2.21,0,0,0,1.17,1.39.48.48,0,0,1-.2.92H22.6v0H17.86v0Z"
        />
      </g>
    </g>
  </svg>
);

export default History;
