import { newValidator } from '../../../../../../utils';

const nameValidator = newValidator({
  minLength: 2,
  required: true,
});
const typeValidator = newValidator({
  required: true,
});

export const validateField = {
  name: value => nameValidator(value),
  options: value => nameValidator(value),
  type: value => typeValidator(value),
};
