import { SET_COURSE_ATTENDEE_DETAILS } from './actions';

const INITIAL_STATE = {
  attendeeDetails: {},
};
const viewAttendeeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COURSE_ATTENDEE_DETAILS:
      return {
        ...state,
        attendeeDetails: action.value,
      };

    default:
      return state;
  }
};

export default viewAttendeeReducer;
