import firebase from '../../../../firebase';

export const REQUEST_PAYMENT_HISTORY = 'REQUEST_PAYMENT_HISTORY';
export const RECEIVE_PAYMENT_HISTORY = 'RECEIVE_PAYMENT_HISTORY';
export const FILTER_PAYMENT_DATA = 'FILTER_PAYMENT_DATA';
export const SORT_PAYMENT_DATA = 'SORT_PAYMENT_DATA';

export const requestPaymentHistory = appname => ({
  type: REQUEST_PAYMENT_HISTORY,
  appname,
});

export const sortTableData = cols => dispatch =>
  dispatch({ type: SORT_PAYMENT_DATA, column: cols });

export const getPaymentHistory = appname => dispatch => {
  dispatch(requestPaymentHistory(appname));
  firebase
    .database()
    .ref(`payments/${appname}`)
    .on(
      'value',
      snap => {
        if (snap.exists()) {
          dispatch({
            type: RECEIVE_PAYMENT_HISTORY,
            value: snap.val(),
          });
        } else {
          dispatch({
            type: RECEIVE_PAYMENT_HISTORY,
            value: [],
          });
        }
      },
      err => console.log(err),
    );
};

export const onFilterPaymentHistory = filter => dispatch =>
  dispatch({
    type: FILTER_PAYMENT_DATA,
    payload: filter,
  });
