import firebase from '../../../../firebase';
import { objectToArray, arrayToObject, formatDate } from '../../../../utils';

export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT';
export const SET_EVENT_ATTENDEES = 'SET_EVENT_ATTENDEES';
export const SET_EVENT_ATTENDEES_ITEMS = 'SET_EVENT_ATTENDEES_ITEMS';
export const FILTER_EVENT_ATTENDEE_DATA = 'FILTER_EVENT_ATTENDEE_DATA';
export const SELECT_ALL_EVENT_ATTENDEES = 'SELECT_ALL_EVENT_ATTENDEES';
export const DESELECT_ALL_EVENT_ATTENDEES = 'DESELECT_ALL_EVENT_ATTENDEES';
export const SELECT_ATTENDEE = 'SELECT_ATTENDEE';

export const setSelectedEvent = payload => ({
  type: SET_SELECTED_EVENT,
  value: payload,
});

export const selectAllEventAttendees = () => ({
  type: SELECT_ALL_EVENT_ATTENDEES,
});

export const deselectAllEventAttendees = () => ({
  type: DESELECT_ALL_EVENT_ATTENDEES,
});

export const selectAttendee = key => ({
  type: SELECT_ATTENDEE,
  key,
});

export const getEventAttendees = appname => (dispatch, getState) => {
  const { selectedEvent } = getState().staff.eventsAttendees.listAttendees;
  return firebase
    .database()
    .ref(`subscribers/${appname}/event/${selectedEvent}`)
    .on('value', snap => {
      if (snap.exists()) {
        const result = snap.val();
        let allUsers = [];
        const users = objectToArray(result);

        users.map(item => {
          const allItems = objectToArray(item.value).map(user => {
            return {
              ...user,
              value: {
                ...user.value,
                userType: item.id === 'user' ? 'registered user' : item.id,
              },
            };
          });
          allUsers = [...allUsers, ...allItems];
        });
        const finalValue = arrayToObject(allUsers);
        dispatch({
          type: SET_EVENT_ATTENDEES,
          value: finalValue,
        });
        dispatch({
          type: SET_EVENT_ATTENDEES_ITEMS,
          value: Object.keys(finalValue),
        });
      } else {
        dispatch({
          type: SET_EVENT_ATTENDEES,
          value: [],
        });
      }
    });
};

export const filterEventData = filter => dispatch =>
  dispatch({
    type: FILTER_EVENT_ATTENDEE_DATA,
    payload: filter,
  });
