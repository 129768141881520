import firebase from '../../../../firebase';

export const setPending = value => ({
  type: 'PENDING',
  payload: value,
});

export const setSuccess = value => ({
  type: 'SUCCESS',
  payload: value,
});

export const setError = value => ({
  type: 'ERROR',
  payload: value,
});

export const updateUserPassword = (data, setDetails) => async dispatch => {
  dispatch(setPending(true));
  try {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });

    const body = JSON.stringify(data);

    const response = await fetch(`${process.env.REACT_APP_MEMBER_API}/member`, {
      method: 'PUT',
      mode: 'cors',
      headers,
      body,
    });

    const json = await response.json();
    firebase.auth().signOut(); //changing password invalidates session so sign out the user

    if (response.status === 200) {
      dispatch(setSuccess(true));
    } else {
      dispatch(setError(true));
    }
    setDetails({
      password: '',
      confirmPassword: '',
    });
    dispatch(setPending(false));
  } catch (error) {
    console.error(error);
    setDetails({
      password: '',
      confirmPassword: '',
    });
    dispatch(setPending(false));
    dispatch(setError(true));
  }
};
