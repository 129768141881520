import firebase from '../../../../firebase';
import { newValidator } from '../../../../utils';
import { removeListeners } from '../../actions';
import {
  replaceAppData,
  setIsUpdating,
} from '../../../editor/modules/shared/appName/actions';
import { loadProfile } from '../../../account/modules/auth/login/actions';
import { loadAccount, getSubscriptions } from '../../../account/actions';
import { changeApp } from '../../../../app/modules/toolbar/actions';
import {
  publishApp,
  launchPublishDialogDomainChange,
} from '../../../../app/actions';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';

export const CHECKING_DOMAIN_AVAILABILITY = 'CHECKING_DOMAIN_AVAILABILITY';
export const ASSERT_DOMAIN_AVAILABILITY = 'ASSERT_DOMAIN_AVAILABILITY';
export const DOMAIN_UPDATED = 'DOMAIN_UPDATED';
export const DOMAIN_UPDATE_ERROR = 'DOMAIN_UPDATE_ERROR';
export const SHOW_DOMAIN_INPUT = 'SHOW_DOMAIN_INPUT';
export const CREATING_INTEGRATION_APP = 'CREATING_INTEGRATION_APP';

const checkingDomainAvailability = () => ({
  type: CHECKING_DOMAIN_AVAILABILITY,
});

const domainUpdate = (domain, valid, isModified) => ({
  type: DOMAIN_UPDATED,
  domain,
  isModified,
  valid,
});

const domainUpdateError = domain => ({
  type: DOMAIN_UPDATE_ERROR,
  domain,
});

const assertDomainAvailability = available => ({
  type: ASSERT_DOMAIN_AVAILABILITY,
  available,
});

const domainValidator = newValidator({
  required: true,
  maxLength: 60,
  minLength: 3,
  type: 'domain',
});

export const domainChange = (appname, domain) => dispatch => {
  const isValid = domainValidator(domain);
  dispatch(domainUpdate(domain, isValid, domain !== appname));
  if (!isValid || domain === appname) {
    dispatch(assertDomainAvailability(isValid));
  } else {
    dispatch(checkingDomainAvailability());
    firebase
      .database()
      .ref('apps_directory')
      .child(domain)
      .once('value')
      .then(snapshot => {
        const isAvailable = !snapshot.exists();
        if (isAvailable) {
          firebase
            .database()
            .ref('apps')
            .child(domain)
            .once('value')
            .then(appsSnapshot => {
              dispatch(assertDomainAvailability(!appsSnapshot.exists()));
            });
        } else {
          dispatch(assertDomainAvailability(isAvailable));
        }
      });
  }
};

const buildHeaders = token => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('x-access-token', token);

  return headers;
};

export const attemptSaveAppDomain = (appname, domain, history) => async (
  dispatch,
  getState,
) => {
  const account = getState().account.auth.profile.account;
  const parent = getState().account.auth.profile.parent;
  if (appname !== domain) {
    dispatch(removeListeners(appname));
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      const headers = buildHeaders(token);
      const response = await fetch(
        `${process.env.REACT_APP_CREATE_API}/replace`,
        {
          headers,
          method: 'post',
          mode: 'cors',
          body: JSON.stringify({
            account,
            domain,
            appname,
            parent,
          }),
        },
      );
      const res = await response.json();
      if (res.error) {
        throw new Error(res.error);
      }

      const { integration } = getState().account.organisation;
      const page = integration ? 'customise' : 'build';
      dispatch(loadAccount(account));
      dispatch(changeApp(domain));
      dispatch(getSubscriptions());
      dispatch(loadProfile());
      dispatch(domainUpdate(domain));
      dispatch(launchPublishDialogDomainChange(domain));
      dispatch(replaceAppData(domain, appname));
      dispatch(publishApp(domain));
      dispatch(setIsUpdating(false));
      history.push(`/${domain}/editor/${page}/app_name`);
    } catch (err) {
      dispatch(domainUpdateError(appname));
      dispatch(setIsUpdating(false));
      dispatch(openSnackbar('An error occurred, please try again later.'));
    }
  }
};
