import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';

import TextField from '../../../../app/components/textField';
import Container from '../../../../app/components/container';
import { Create } from '../icons';

import './styles.css';

class CreateField extends Component {
  static propTypes = {
    id: PropTypes.any.isRequired,
    hintText: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tooltip: undefined,
  };

  state = {
    value: '',
  };

  handleChange = value => {
    this.setState({ value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.onSubmit(this.state.value);
    this.setState({ value: '' });
  };

  render() {
    return (
      <Container>
        <form className="create-field" onSubmit={this.handleSubmit}>
          <div className="input-action-group">
            <TextField
              id={this.props.id}
              placeholder={this.props.hintText}
              value={this.state.value}
              onChange={this.handleChange}
            />
            {this.state.value && (
              <span className="input-action">
                <IconButton type="submit" tooltip={this.props.tooltip}>
                  <Create />
                </IconButton>
              </span>
            )}
          </div>
        </form>
      </Container>
    );
  }
}

export default CreateField;
