import firebase from '../../../../../../firebase';
import { validateField } from '../../validators';
import { objectToArray } from '../../../../../../utils';

export const NEW_MEMBER_BODY_FIELD_CHANGE = 'NEW_MEMBER_BODY_FIELD_CHANGE';
export const OPEN_NEW_MEMBER_BODY_DIALOG = 'OPEN_NEW_MEMBER_BODY_DIALOG';
export const CLOSE_NEW_MEMBER_BODY_DIALOG = 'CLOSE_NEW_MEMBER_BODY_DIALOG';
export const ADD_NEW_MEMBER_BODY = 'ADD_NEW_MEMBER_BODY';
export const NEW_MEMBER_BODY_ADDED = 'NEW_MEMBER_BODY_ADDED';
export const GET_AVAILABLE_STAFF = 'GET_AVAILABLE_STAFF';
export const NEW_MEMBER_BODY_STAFF_FIELD_CHANGE =
  'NEW_MEMBER_BODY_STAFF_FIELD_CHANGE';
export const LOADED_BANNER_DETAILS = 'LOADED_BANNER_DETAILS';

const newMemberBodyFieldChange = (prop, value, isValid) => ({
  type: NEW_MEMBER_BODY_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

const staffAssignmentChange = (prop, value) => ({
  type: NEW_MEMBER_BODY_STAFF_FIELD_CHANGE,
  prop,
  value,
});

export const openNewMemberBodyDialog = () => ({
  type: OPEN_NEW_MEMBER_BODY_DIALOG,
});

export const closeNewMemberBodyDialog = () => ({
  type: CLOSE_NEW_MEMBER_BODY_DIALOG,
});

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;
  return dispatch(newMemberBodyFieldChange(prop, value, isValid));
};

export const formFieldChangeNonRequired = (prop, value) => dispatch => {
  const isValid = true;
  if (prop === 'staffMembers') {
    return dispatch(staffAssignmentChange(prop, value));
  }
  return dispatch(newMemberBodyFieldChange(prop, value, isValid));
};

export const formFieldUniqueCheck = (
  prop,
  value,
  filteredChunks,
) => dispatch => {
  const dataToCheck = objectToArray(filteredChunks);
  const isValid = !dataToCheck.find(i => i.value.name === value);
  return dispatch(newMemberBodyFieldChange(prop, value, isValid));
};

export const loadBanner = value => ({
  type: LOADED_BANNER_DETAILS,
  value,
});

export const addMemberBody = appname => (dispatch, getState) => {
  const {
    name,
    postalAddress,
    postCode,
    adminUsername,
    adminEmail,
    primaryContactName,
    primaryContactEmail,
    primaryContactNumber,
    secondaryContactName,
    secondaryContactEmail,
    secondaryContactNumber,
    landline,
    mobile,
    memberNumber,
    memberNumberMale,
    memberNumberFemale,
    memberNumberDisabled,
    memberNumberSmid,
    staffMembers,
    banner,
  } = getState().auth.memberBodies.newMemberBody;
  const selectedStaffsArray = objectToArray(staffMembers);
  Object.keys(staffMembers).forEach(staffID => {
    if (!staffMembers[staffID]) {
      delete staffMembers[staffID];
    }
  });

  const memberBody = {
    name: name.value,
    postalAddress: postalAddress.value,
    postCode: postCode.value,
    adminUsername: adminUsername.value,
    adminEmail: adminEmail.value,
    primaryContactName: primaryContactName.value,
    primaryContactEmail: primaryContactEmail.value,
    primaryContactNumber: primaryContactNumber.value,
    secondaryContactName: secondaryContactName.value,
    secondaryContactEmail: secondaryContactEmail.value,
    secondaryContactNumber: secondaryContactNumber.value,
    landline: landline.value,
    mobile: mobile.value,
    memberNumber: memberNumber.value,
    memberNumberMale: memberNumberMale.value,
    memberNumberFemale: memberNumberFemale.value,
    memberNumberDisabled: memberNumberDisabled.value,
    memberNumberSmid: memberNumberSmid.value,
    staffMembers,
    banner,
    date: Date.now(),
  };
  dispatch({ type: ADD_NEW_MEMBER_BODY });
  return firebase
    .database()
    .ref(`member_bodies/${appname}`)
    .push(memberBody)
    .then(ref => {
      const memberBodyID = ref.key;

      // update memberbody in staff members account
      Promise.all(
        selectedStaffsArray.map(staff =>
          staff.value
            ? firebase
                .database()
                .ref(`users/${staff.id}`)
                .child('memberBodies')
                .update({ [memberBodyID]: true })
            : firebase
                .database()
                .ref(`users/${staff.id}`)
                .child('memberBodies')
                .child(memberBodyID)
                .set(null),
        ),
      ).then(() => {
        dispatch({
          type: NEW_MEMBER_BODY_ADDED,
          key: memberBodyID,
          data: memberBody,
        });
        // reset temporary image
        firebase
          .database()
          .ref(`member_bodies/tempBanner/banner`)
          .set(null);
      });
    });
};

export const attachBanner = () => async dispatch => {
  const memberBodyRef = firebase
    .database()
    .ref(`member_bodies/tempBanner/banner`);
  return memberBodyRef.on('value', snapshot => {
    dispatch(loadBanner(snapshot.exists ? snapshot.val() : ''));
  });
};
