import firebase from '../../../../../../../firebase';

export const launchDuplicatePageDialog = pageKey => dispatch => {
  dispatch({ type: 'OPEN_DUPLICATE_PAGE_DIALOG' });
  dispatch({ type: 'SET_DUPLICATE_PAGE_KEY', payload: pageKey });
};

export const closeDuplicatePageDialog = () => dispatch => {
  dispatch({ type: 'CLOSE_DUPLICATE_PAGE_DIALOG' });
  dispatch({ type: 'SET_DUPLICATE_PAGE_KEY', payload: '' });
  dispatch({ type: 'RESET_DUPLICATE_PAGE_FORM' });
};

export const titleChange = value => ({
  type: 'DUPLICATE_PAGE_TITLE_CHANGE',
  value,
});

const resetForm = () => ({
  type: 'RESET_DUPLICATE_PAGE_FORM',
});

const creatingDuplicatePage = () => ({
  type: 'CREATING_DUPLICATE_PAGE',
});

const reportError = err => ({
  type: 'DUPLICATE_PAGE_ERROR',
  err,
});

export const duplicatePage = (appname, history) => async (
  dispatch,
  getState,
) => {
  dispatch(creatingDuplicatePage());
  try {
    const form = getState().editor.build.create.duplicatePage;
    const page = form.pageKey;
    const newPageName = form.title.value;
    // Set up custom domain
    const myHeaders = new Headers();
    const user = firebase.auth().currentUser;
    const duplicatePageAPI = `${process.env.REACT_APP_PAGE_SETTINGS_API}/duplicate`;

    const token = await user.getIdToken();
    myHeaders.append('x-access-token', token);
    myHeaders.append('Content-Type', 'application/json');
    const response = await fetch(duplicatePageAPI, {
      method: 'post',
      headers: myHeaders,
      body: JSON.stringify({ appname, page, newPageName }),
      mode: 'cors',
    });
    if (!response.ok) {
      throw new Error(response.json().message);
    } else {
      const data = await response.json();
      const pageLocation = `/${appname}/editor/build/list?page=${data.page}`;
      history.push(pageLocation);
      dispatch(closeDuplicatePageDialog());
      dispatch(resetForm());
    }
  } catch (err) {
    dispatch(reportError(err));
  }
};
