import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Clipboard from 'clipboard';
import ContentCopy from 'material-ui/svg-icons/content/content-copy';
import FlatButton from 'material-ui/FlatButton';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';

import Panel from '../../../../app/components/panel';

import { Widget as WidgetIcon } from '../../components/icons';
import { getAppUrl } from '../../../../utils/appDetails';
import './styles.css';

class Widget extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    isPublished: PropTypes.bool,
    customDomain: PropTypes.object,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isPublished: false,
    customDomain: {},
  };

  state = {
    open: false,
  };

  componentDidMount() {
    this.clipboard = new Clipboard('#copy-content');
  }

  handleOpen = () => this.setState({ open: true });

  handleClose = () => this.setState({ open: false });

  render() {
    const { t } = this.props;
    const copy = `<iframe
      height="230"
      width="200"
      frameborder="0"
      src="${getAppUrl(
        this.props.match.params.appname,
        this.props.customDomain,
      )}/widget.html">
    </iframe>`;

    return (
      <div className="row">
        <div className="col-xs-12">
          <Panel icon={<WidgetIcon />} title={t('Widgets')} padded>
            {this.props.isPublished ? (
              <div className="col-xs-8">
                <div className="row">
                  <div className="col-xs-12">
                    <h3>
                      <Trans>
                        Your own customized website widget is just a click away
                      </Trans>
                    </h3>
                    <p>
                      <Trans>
                        Our website widgets take your logo and create a
                        customized button which can be placed on your website.
                        When a visitor clicks the button they are given the
                        opportunity to download the app. It is quick and easy
                        and a great way to increase your user base.
                      </Trans>
                    </p>
                    <p>
                      <Trans>
                        Please note that you need to re-publish your app if you
                        changed the icon to see the new widget.
                      </Trans>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-xs-12">
                    <iframe
                      id="widget-embed"
                      title="website-widget"
                      height={230}
                      width={200}
                      frameBorder={0}
                      src={`${getAppUrl(
                        this.props.match.params.appname,
                        this.props.customDomain,
                      )}/widget.html`}
                    />
                  </div>
                  <div className="col-lg-8 col-xs-12">
                    <pre className="code-snippet"> {copy} </pre>
                    <textarea
                      readOnly
                      id="iframe-cpy"
                      type="text"
                      value={copy}
                      style={{
                        display: 'block',
                        width: 0,
                        height: 0,
                        opacity: 0,
                      }}
                    />
                    <FlatButton
                      id="copy-content"
                      icon={<ContentCopy />}
                      data-clipboard-target="#iframe-cpy"
                      label={t('Copy to Clipboard')}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-xs-12">
                  <h3>
                    <Trans>
                      You must publish your app for the first time before using
                      a Widget.
                    </Trans>
                  </h3>
                  <br />
                  <h3>
                    <Trans>To publish your app please go to the</Trans>
                    <Link
                      to={`/${this.props.match.params.appname}/editor/build`}
                    >
                      <strong>
                        {' '}
                        <Trans>editor.</Trans>
                      </strong>
                    </Link>
                  </h3>
                </div>
              </div>
            )}
          </Panel>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Widget);
