import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import Divider from 'material-ui/Divider';
import { Trans, useTranslation } from 'react-i18next';

import themeStyles from '../../../../../../_export.scss';
import '../../styles.scss';

const PrivacyPolicy = ({
  enabled,
  url,
  togglePrivacyPolicy,
  changePrivacyPolicyUrl,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="auth-panel">
        <div className="auth-panel-title">
          <Trans>Privacy Policy</Trans>
        </div>
        <div className="auth-panel">
          <Toggle
            style={{ margin: '0' }}
            onToggle={(v, b) => togglePrivacyPolicy(b)}
            toggled={enabled}
            label={t('Enabled')}
            trackSwitchedStyle={{
              backgroundColor: themeStyles.primaryColorLight,
            }}
            thumbSwitchedStyle={{ backgroundColor: themeStyles.primaryColor }}
          />
          <p>
            <Trans>
              On the signup page of your app, a link can be displayed to your
              users
            </Trans>
          </p>
          <TextField
            id="url"
            value={url}
            className="auth-panel-privacy-url-textbox"
            floatingLabelText={t('Link to your privacy policy')}
            underlineShow={false}
            onChange={(event, value) => changePrivacyPolicyUrl(value)}
          />
          <Divider />
        </div>
      </div>
    </div>
  );
};

PrivacyPolicy.propTypes = {
  enabled: PropTypes.bool,
  url: PropTypes.string,
  togglePrivacyPolicy: PropTypes.func.isRequired,
  changePrivacyPolicyUrl: PropTypes.func.isRequired,
};

PrivacyPolicy.defaultProps = {
  enabled: false,
  url: '',
};

export default PrivacyPolicy;
