/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactGA from 'react-ga';
import { render } from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import thunkMiddleware from 'redux-thunk';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { IntlProvider } from 'react-intl';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ThemeProvider as StyledThemeProvider, css } from 'styled-components';
import './pollyfills';
import './theme.scss';
import theme from './material-ui-theme';
import themeStyles from './_export.scss';
import App from './app';
import reducer from './reducer';
import intlFormats from './Intl/formats';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import './i18n';

const queryClient = new QueryClient();

let middleware = [thunkMiddleware];
// Only use logger in dev env
if (
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'staging'
) {
  const loggerMiddleware = require('redux-logger')();

  middleware = [...middleware, loggerMiddleware];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  middleware = composeEnhancers(applyMiddleware(...middleware));
} else {
  middleware = applyMiddleware(...middleware);
}

export const store = createStore(reducer, middleware);

const logPageView = () => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.set({ page: window.location.pathname + window.location.search });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize([{ trackingId: 'UA-101921015-1' }]);

  logPageView();
}

const mui4Theme = createTheme({
  palette: {
    primary: {
      light: themeStyles.primaryColorLight,
      main: themeStyles.primaryColor,
      dark: themeStyles.primaryColorDark,
      contrastText: themeStyles.primaryTextColor,
    },
  },
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

render(
  <QueryClientProvider client={queryClient}>
    {/*mui v1 theme provider */}
    <MuiThemeProvider muiTheme={getMuiTheme(theme)}>
      {/*mui v5 theme provider */}

      <ThemeProvider theme={mui4Theme}>
        {/*styled components  theme provider */}
        <StyledThemeProvider theme={themeStyles}>
          <Provider store={store}>
            <IntlProvider locale="en" formats={intlFormats}>
              <Elements stripe={stripePromise}>
                <Router onUpdate={logPageView}>
                  <App />
                </Router>
              </Elements>
            </IntlProvider>
          </Provider>
        </StyledThemeProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
