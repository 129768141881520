import { connect } from 'react-redux';

import Livestream from './Livestream';
import {
  urlChange,
  loop,
  muted,
  autoPlay,
  comments,
  commentsAuth,
  banBadWords,
  urlType,
  iframeUrl,
} from './actions';

const mapStateToProps = (state, ownProps) => ({
  appname: ownProps.match.params.appname,
  ...ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: value =>
    dispatch(
      urlChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
      ),
    ),
  onIframeUrlChange: value =>
    dispatch(
      iframeUrl(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
      ),
    ),
  onChangeLoop: value =>
    dispatch(
      loop(ownProps.match.params.appname, ownProps.component.lazy_data, !value),
    ),
  onChangeMuted: value =>
    dispatch(
      muted(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        !value,
      ),
    ),
  onChangeAutoPlay: value =>
    dispatch(
      autoPlay(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        !value,
      ),
    ),
  onChangeComments: value =>
    dispatch(
      comments(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        !value,
      ),
    ),
  onChangeCommentsAuth: value =>
    dispatch(
      commentsAuth(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        !value,
      ),
    ),
  onChangeCommentsBanBadWords: value =>
    dispatch(
      banBadWords(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        !value,
      ),
    ),
  onUrlTypeChange: value =>
    dispatch(
      urlType(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Livestream);
