import { connect } from 'react-redux';

import UpdatePassword from './UpdatePassword';

import { updateUserPassword } from './action';

const mapStateToProps = state => ({
  ...state.account.updatedPasswordReducer,
});

const mapDispatchToProps = dispatch => ({
  submitForm: (data, setDetails) =>
    dispatch(updateUserPassword(data, setDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
