import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PgmolMenu from './PgmolMenu';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

export default withRouter(connect(mapStateToProps)(PgmolMenu));
