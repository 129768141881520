import React, { Fragment } from 'react';
import TwoThirdImageLeftImage from './images/twoThirdImageLeft.png';
import './styles.css';

const TwoThirdImageLeft = () => {
  return (
    <Fragment>
      <img
        className="imageWrapper"
        src={TwoThirdImageLeftImage}
        alt="Two Third Left"
      />
    </Fragment>
  );
};

export default TwoThirdImageLeft;
