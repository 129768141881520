import i18n from 'i18next';
import firebase from '../../../../../../../../../firebase';
import { validateField } from '../../../validators';
import { openSnackbar } from '../../../../../../../../../app/modules/snackbar/actions';
import {
  NEW_FIELD_FIELD_CHANGE,
  OPEN_NEW_FIELD_DIALOG,
  CLOSE_NEW_FIELD_DIALOG,
  ADD_NEW_FIELD,
  NEW_FIELD_ADDED,
} from './types';

const newFilterFieldChange = (prop, value, isValid) => ({
  type: NEW_FIELD_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

export const openUserFilterFieldDialog = () => ({
  type: OPEN_NEW_FIELD_DIALOG,
});

export const closeUserFilterFieldDialog = () => ({
  type: CLOSE_NEW_FIELD_DIALOG,
});

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;

  return dispatch(newFilterFieldChange(prop, value, isValid));
};

export const addField = (appname, categoryID) => (dispatch, getState) => {
  const {
    name,
    type,
    options,
    isMultiple,
    isRequired,
    isSearchable,
    isPublic,
  } = getState().ecommerce.marketplace.filters.edit.createField;

  const field = {
    name: name.value,
    options: options.value,
    type: type.value,
    isRequired: isRequired.value,
    isMultiple: isMultiple.value,
    isSearchable: isSearchable.value,
    isPublic: isPublic.value,
    createdAt: Date.now(),
  };

  dispatch({ type: ADD_NEW_FIELD });
  return firebase
    .database()
    .ref(`marketplace_filters/${appname}/${categoryID}/fields`)
    .push(field)
    .then(ref => {
      dispatch({
        type: NEW_FIELD_ADDED,
        key: ref.key,
        data: field,
      });
      dispatch(openSnackbar(i18n.t('Field Created')));
    });
};
