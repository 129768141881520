import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import SocialLinks from './SocialLinks';
import {
  addSocialLinkToDelegate,
  removeSocialLinkFromDelegate,
  itemFieldChange,
} from '../actions';

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeSocialLinkFromDelegate: link => {
    const { item } = queryString.parse(ownProps.location.search);

    return dispatch(
      removeSocialLinkFromDelegate(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        item,
        link,
      ),
    );
  },
  addSocialLinkToDelegate: link => {
    const { item } = queryString.parse(ownProps.location.search);

    return dispatch(
      addSocialLinkToDelegate(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        item,
        link,
      ),
    );
  },
  onChange: (field, value) => {
    const { item } = queryString.parse(ownProps.location.search);

    return dispatch(
      itemFieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        ownProps.component.type,
        item,
        field,
        value,
      ),
    );
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SocialLinks),
);
