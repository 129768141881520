import firebase from '../../../../firebase';

export const fieldChange = (prop, value) => ({
  type: 'FORGOTTEN_PASSWORD_FIELD_CHANGE',
  prop,
  value,
});

const forgottenPasswordError = prop => ({
  type: 'FORGOTTEN_PASSWORD_ERROR',
  prop,
});

const reportSuccess = () => ({
  type: 'FORGOTTEN_PASSWORD_SUCCESS',
});

export const clearForm = () => ({
  type: 'CLEAR_FORGOTTEN_PASSWORD_FORM',
});

export const submitForgottenPassword = () => async (dispatch, getState) => {
  const { email } = getState().account.forgottenPassword;

  dispatch({ type: 'SUBMIT_FORGOTTEN_PASSWORD' });

  const auth = firebase.auth();

  const response = await fetch(
    `${process.env.REACT_APP_MEMBER_API}/reset_password`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email.value, source: 'platform' }),
    },
  );

  if (!response.ok) {
    dispatch(forgottenPasswordError(response.json().message));
  }

  dispatch(reportSuccess());

  // return response.json();
  // return auth
  //   .sendPasswordResetEmail(email.value)
  //   .then(() => dispatch(reportSuccess()))
  //   .catch(err => {
  //     dispatch(forgottenPasswordError(err));
  //   });
};
