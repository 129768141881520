const initialState = {
    dialogOpen: false,
    isPending: false,
    bonusPoints: 0.00
};

const redeemBonus = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_DIALOG':
            return {
                ...state,
                dialogOpen: action.payload,
            };
        case 'TOGGLE_PENDING_STATE':
            return {
                ...state,
                isPending: action.payload,
            };
        case 'UPDATE_BONUS_POINTS':
            return {
                ...state,
                bonusPoints: action.payload,
            };
        default:
            return state;
    }
};

export default redeemBonus;
