import React from 'react';

const Vendors = () => {
  return (
    <svg
      className="icon vendors"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080.000000 1080.000000"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M3060 9354 c-83 -18 -189 -70 -265 -131 -74 -60 -157 -179 -186 -271
-30 -92 -37 -264 -15 -353 48 -189 183 -347 361 -422 l60 -25 3 -1950 2 -1951
-57 -16 c-176 -51 -309 -181 -363 -354 -19 -61 -20 -93 -20 -1030 0 -1056 -2
-1025 58 -1135 15 -28 56 -81 91 -118 76 -82 187 -142 291 -158 92 -14 4672
-14 4760 1 196 31 373 194 425 390 12 45 15 214 15 1015 0 947 0 963 -21 1030
-26 86 -60 145 -117 207 -62 66 -146 119 -231 147 l-71 23 0 1948 0 1948 83
39 c211 99 343 291 355 513 6 131 -10 215 -63 326 -73 149 -187 249 -357 311
l-73 27 -2300 2 c-1945 1 -2310 -1 -2365 -13z m4656 -371 c191 -102 189 -374
-2 -469 -38 -19 -87 -19 -2304 -22 -2542 -3 -2311 -10 -2394 74 -49 49 -76
113 -76 183 0 119 61 204 174 243 48 16 178 17 2306 15 l2255 -2 41 -22z
m-296 -2788 l0 -1935 -260 0 -260 0 0 398 c0 429 -5 490 -54 636 -68 203 -193
378 -361 506 -89 68 -270 164 -332 176 l-33 6 33 38 c53 63 128 196 159 283
41 114 58 211 58 329 0 448 -297 833 -727 944 -128 33 -327 38 -446 11 -192
-45 -353 -133 -487 -267 -138 -138 -236 -325 -270 -516 -18 -102 -8 -326 19
-429 34 -128 116 -284 193 -366 l24 -26 -44 -12 c-69 -18 -216 -94 -302 -157
-212 -153 -365 -393 -414 -654 -13 -66 -16 -161 -16 -491 l0 -409 -260 0 -260
0 0 1935 0 1935 2020 0 2020 0 0 -1935z m-1866 1028 c159 -39 314 -164 389
-313 156 -311 27 -682 -289 -832 -72 -34 -177 -58 -254 -58 -132 0 -307 66
-404 151 -204 180 -266 489 -145 732 130 264 414 393 703 320z m488 -1594
c190 -65 338 -191 421 -360 72 -148 77 -186 77 -629 l0 -380 -1141 0 -1140 0
3 418 c4 398 5 420 26 483 85 255 290 435 553 488 24 5 287 9 584 8 l540 -2
77 -26z m-822 -1818 l0 -89 -47 -12 c-126 -31 -263 -137 -327 -253 -110 -196
-81 -448 71 -617 58 -64 127 -107 231 -143 77 -26 104 -30 232 -34 80 -2 157
-9 172 -15 41 -15 96 -81 104 -123 14 -78 -34 -161 -110 -190 -35 -14 -70 -16
-168 -14 -118 4 -126 5 -161 32 -43 33 -77 93 -77 137 0 43 -22 89 -61 129
-40 39 -67 51 -119 51 -107 0 -180 -78 -180 -193 0 -128 54 -253 154 -353 74
-74 131 -109 229 -140 l57 -18 0 -88 0 -88 -1057 0 c-697 0 -1071 4 -1095 10
-48 14 -97 55 -114 97 -12 26 -14 198 -14 953 0 992 -2 947 52 997 58 55 6 52
1161 53 l1067 0 0 -89z m2539 69 c49 -24 89 -71 96 -113 3 -18 4 -444 3 -948
l-3 -916 -28 -36 c-59 -78 35 -72 -1169 -75 l-1078 -2 0 88 0 88 68 22 c157
51 281 169 342 327 34 87 35 252 2 348 -48 138 -134 234 -277 309 l-80 42
-180 7 c-99 3 -189 10 -200 14 -107 43 -147 173 -80 261 45 58 91 74 225 74
190 0 239 -38 265 -203 7 -42 60 -109 106 -133 35 -18 100 -18 142 0 44 18 95
78 103 122 9 50 -3 151 -26 220 -28 79 -71 144 -142 209 -65 60 -121 93 -210
121 l-58 18 0 88 0 88 1070 0 c1043 0 1072 -1 1109 -20z"
        />
      </g>
    </svg>
  );
};

export default Vendors;
