import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _without from 'lodash/without';
import _mapValues from 'lodash/mapValues';
import _union from 'lodash/union';

import { escapeSepcialChars } from '../../utils';

const initialState = {
  pending: true,
  query: '',
  chunks: {},
  items: [],
  original: [],
  selected: [],
};

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_SHARE_HISTORY':
      return {
        ...state,
        pending: true,
      };

    case 'RECEIVE_SHARE_EVENT': {
      const keys = _keys(action.data);

      return {
        ...state,
        chunks: action.data === null ? {} : action.data,
        items: keys,
        original: keys,
        pending: false,
      };
    }

    case 'SEARCH_SHARE_HISTORY': {
      const getItems = () => {
        // escape special chars
        const escapedQuery = escapeSepcialChars(action.query);
        const searchExp = new RegExp(`^${escapedQuery}.*$`, 'i');

        return state.original.filter(
          key =>
            searchExp.test(state.chunks[key].destination) ||
            searchExp.test(state.chunks[key].message),
        );
      };

      return {
        ...state,
        items: action.query === '' ? [...state.original] : getItems(),
        query: action.query,
      };
    }

    case 'SELECT_HISTORY': {
      const prevState = state.chunks[action.key].selected;

      return {
        ...state,
        chunks: {
          ...state.chunks,
          [action.key]: {
            ...state.chunks[action.key],
            selected: !prevState,
          },
        },
        selected:
          prevState === true
            ? _without(state.selected, action.key)
            : state.selected.concat(action.key),
      };
    }

    case 'SELECT_ALL_HISTORY': {
      const filteredChunks = _pick(state.chunks, state.items);
      const selectedChunks = _mapValues(filteredChunks, chunks => ({
        ...chunks,
        selected: true,
      }));

      const selected = _union(state.selected, _keys(filteredChunks));

      return {
        ...state,
        chunks: {
          ...state.chunks,
          ...selectedChunks,
        },
        selected,
        selectAll: true,
      };
    }

    case 'DESELECT_ALL_HISTORY':
      return {
        ...state,
        chunks: _mapValues(state.chunks, chunk => ({
          ...chunk,
          selected: false,
        })),
        selected: [],
        selectAll: false,
      };

    default:
      return state;
  }
};

export default historyReducer;
