import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  .cls-1 {
    fill: #fff;
  }
  .cls-2,
  .cls-4,
  .cls-5,
  .cls-7,
  .cls-8 {
    fill: none;
  }
  .cls-2,
  .cls-8 {
    stroke: #d0d2d3;
    stroke-width: 3px;
  }
  .cls-2 {
    stroke-linejoin: round;
  }
  .cls-3 {
    fill: #d0d2d3;
  }
  .cls-4 {
    stroke: #f1f1f2;
  }
  .cls-10,
  .cls-4,
  .cls-5,
  .cls-7,
  .cls-8 {
    stroke-miterlimit: 10;
  }
  .cls-10,
  .cls-5,
  .cls-7 {
    stroke: #dbdbdb;
  }
  .cls-10,
  .cls-5 {
    stroke-width: 2px;
  }
  .cls-6 {
    fill: #f1f1f2;
  }
  .cls-7 {
    stroke-width: 1.5px;
  }
  .cls-9 {
    fill: #e5e5e5;
  }
  .cls-10 {
    fill: #efefef;
  }
`;

const headerMedium = () => (
  <Svg
    className="layout layout-header-large"
    width="750.000000pt"
    height="1334.000000pt"
    viewBox="0 0 378.19 775"
    preserveAspectRatio="xMidYMid meet"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M5.09,120H3.69a2,2,0,0,1-2-2V88.9a2,2,0,0,1,2-2h1.4"
        />
        <path
          className="cls-2"
          d="M5.09,120H3.69a2,2,0,0,1-2-2V88.9a2,2,0,0,1,2-2h1.4"
        />
        <path
          className="cls-1"
          d="M5.09,216H3.89a2,2,0,0,1-2-2V159.4a2,2,0,0,1,2-2h1.2"
        />
        <path
          className="cls-2"
          d="M5.09,216H3.89a2,2,0,0,1-2-2V159.4a2,2,0,0,1,2-2h1.2"
        />
        <path
          className="cls-1"
          d="M372.89,157.4h.8a3,3,0,0,1,3,3V213a3,3,0,0,1-3,3h-.8"
        />
        <path
          className="cls-2"
          d="M372.89,157.4h.8a3,3,0,0,1,3,3V213a3,3,0,0,1-3,3h-.8"
        />
        <path
          className="cls-1"
          d="M5.19,288.2H2c-1.1,0,0-.9,0-2V231.6c0-1.1-1.1-2,0-2h3.2"
        />
        <path
          className="cls-2"
          d="M5.19,288.2H2c-1.1,0,0-.9,0-2V231.6c0-1.1-1.1-2,0-2h3.2"
        />
        <path
          className="cls-1"
          d="M57,773.5a50.45,50.45,0,0,1-50.4-50.4V51.9A50.45,50.45,0,0,1,57,1.5h264.1a50.45,50.45,0,0,1,50.4,50.4V723.1a50.44,50.44,0,0,1-50.4,50.4Z"
        />
        <path
          className="cls-3"
          d={`M321,3a49,49,0,0,1,48.9,48.9V723.1A48.91,48.91,0,0,1,321,772H56.89A49,49,0,0,1,8,723.1V51.9A48.91,48.91,0,0,1,56.89,
          3H321m0-3H56.89A51.92,51.92,0,0,0,5,51.9V723.1A51.92,51.92,0,0,0,56.89,775H321a51.92,51.92,0,0,0,51.9-51.9V51.9A51.92,51.92,0,0,0,321,0Z`}
        />
        <path
          className="cls-4"
          d="M360.59,683a3,3,0,0,1-3,3H19.89a3,3,0,0,1-3-3V83a3.08,3.08,0,0,1,3-3h337.7a3,3,0,0,1,3,3Z"
        />
        <path
          className="cls-5"
          d="M360.59,683a3,3,0,0,1-3,3H19.89a3,3,0,0,1-3-3V83a3.08,3.08,0,0,1,3-3h337.7a3,3,0,0,1,3,3Z"
        />
        <circle className="cls-6" cx="126.59" cy="41.8" r="6.6" />
        <circle className="cls-7" cx="126.59" cy="41.8" r="6.6" />
        <circle className="cls-6" cx="188.19" cy="15.4" r="4" />
        <circle className="cls-7" cx="188.19" cy="15.4" r="4" />
        <circle className="cls-8" cx="188.19" cy="731" r="30.1" />
        <path
          className="cls-9"
          d="M217.69,42a3,3,0,0,1-3,3h-54a3,3,0,0,1-3-3v-.1a3,3,0,0,1,3-3h54.1A3.08,3.08,0,0,1,217.69,42Z"
        />
        <path
          className="cls-5"
          d="M217.69,42a3,3,0,0,1-3,3h-54a3,3,0,0,1-3-3v-.1a3,3,0,0,1,3-3h54.1A3.08,3.08,0,0,1,217.69,42Z"
        />
        <path
          className="cls-10"
          d="M360.59,362.55c0,1.2-1.3,2.11-3,2.11H19.89c-1.6,0-3-.92-3-2.11V82.11c0-1.13,1.4-2.11,3-2.11h337.7c1.7,0,3,1,3,2.11Z"
        />
      </g>
    </g>
  </Svg>
);

export default headerMedium;
