import _keys from 'lodash/keys';
import firebase from '../../../../firebase';
import { objectToArray, arrayToObject, formatDate } from '../../../../utils';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';
import {
  EVENT_DELETED,
  EVENT_DUPLICATED,
  EVENT_DUPLICATE_ERROR,
} from './config';
import { HS_FEATURES_ENABLED_APPS } from '../../../../config';

export const REQUEST_EVENTS = 'REQUEST_EVENTS';
export const RECEIVE_EVENTS = 'RECEIVE_EVENTS';
export const FILTER_EVENTS = 'FILTER_EVENTS';
export const SELECT_EVENT = 'SELECT_EVENT';
export const SELECT_ALL_EVENTS = 'SELECT_ALL_EVENTS';
export const DESELECT_ALL_EVENTS = 'DESELECT_ALL_EVENTS';
export const GET_FIELDS_EVENT = 'GET_FIELDS_EVENT';
export const INITIATE_FIELDS_EVENT = 'INITIATE_FIELDS_EVENT';
export const INITIATE_MEMBER_BODY_EVENT = 'INITIATE_MEMBER_BODY_EVENT';
export const DELETED_EVENT = 'DELETED_EVENT';

export const request = appname => ({
  type: REQUEST_EVENTS,
  appname,
});

export const selectEvent = key => ({
  type: SELECT_EVENT,
  key,
});

export const deletedDone = data => ({
  type: DELETED_EVENT,
  data,
});

export const initiateMemberBody = key => ({
  type: INITIATE_MEMBER_BODY_EVENT,
  key,
});

export const filterEventData = (filter, keys) => dispatch => {
  dispatch({
    type: FILTER_EVENTS,
    payload: filter,
    keys,
  });
};

export const selectAllEvents = () => ({
  type: SELECT_ALL_EVENTS,
});

export const deselectAllEvents = () => ({
  type: DESELECT_ALL_EVENTS,
});

export const getEventFields = appname => dispatch => {
  const isHorsescotland = HS_FEATURES_ENABLED_APPS.indexOf(appname) > -1;
  if (isHorsescotland) {
    firebase
      .database()
      .ref(`app_events/${appname}/fields`)
      .on('value', snap => {
        if (snap.exists()) {
          const fields = snap.val();
          dispatch({
            type: GET_FIELDS_EVENT,
            value: fields,
          });
          // create fieldsData object
          const fieldsData = {};
          Object.keys(fields).forEach(key => {
            fieldsData[key] = {
              value: fields[key].defaultValue,
              isValid: fields[key].isRequired ? null : true,
            };
          });
          dispatch({
            type: INITIATE_FIELDS_EVENT,
            value: fieldsData,
          });
        } else {
          dispatch({
            type: GET_FIELDS_EVENT,
            value: {},
          });
        }
      });
  } else {
    firebase
      .database()
      .ref(`app_events/${appname}/lazy_data`)
      .on('value', snap => {
        if (snap.exists()) {
          firebase
            .database()
            .ref(`apps/${appname}/draft/lazy_data/${snap.val()}/event`)
            .on('value', snap => {
              if (snap.exists()) {
                const fields = snap.val().fields.chunks;
                dispatch({
                  type: GET_FIELDS_EVENT,
                  value: fields,
                });
                // create fieldsData object
                const fieldsData = {};
                Object.keys(fields).forEach(key => {
                  fieldsData[key] = {
                    value: fields[key].defaultValue || '',
                    isValid: fields[key].isRequired ? null : true,
                  };
                });
                dispatch({
                  type: INITIATE_FIELDS_EVENT,
                  value: fieldsData,
                });
              } else {
                dispatch({
                  type: GET_FIELDS_EVENT,
                  value: {},
                });
              }
            });
        }
      });
  }
};

export const selectMemberBody = key => (dispatch, getState) => {
  if (key) {
    dispatch(initiateMemberBody(key));
  } else {
    const { memberBodies } = getState().account.profile;
    dispatch(initiateMemberBody(_keys(memberBodies)[0]));
  }
};

export const getEvents = appname => (dispatch, getState) => {
  dispatch(request(appname));

  const { memberbody } = getState().staff.events.list;
  return firebase
    .database()
    .ref(`app_events/${appname}/events/${memberbody}`)
    .on('value', snap => {
      if (snap.exists()) {
        // convert object to compatible version for table
        const list = arrayToObject(
          objectToArray(snap.val()).map(data => ({
            id: data.id,
            value: arrayToObject(
              objectToArray(data.value).map(i => ({
                id: i.id,
                value:
                  i.value.type === 'DateRange'
                    ? i.value.value
                        .map(date => formatDate(date, 'YYYY/MM/DD'))
                        .reduce((final, date, index) =>
                          index === 0
                            ? `${date}`
                            : date === final
                            ? final
                            : `${final} - ${date}`,
                        )
                    : i.value.value,
              })),
            ),
          })),
        );
        dispatch({
          type: RECEIVE_EVENTS,
          value: list,
        });
      } else {
        dispatch({
          type: RECEIVE_EVENTS,
          value: {},
        });
      }
    });
};

export const deleteEvents = appname => (dispatch, getState) => {
  const { selected, memberbody } = getState().staff.events.list;
  return Promise.all(
    selected.map(eventID =>
      firebase
        .database()
        .ref(`app_events/${appname}/events/${memberbody}/${eventID}`)
        .remove()
        .then(async () => {
          // delete the event dependencies
          const subscribers = await firebase
            .database()
            .ref(`subscribers/${appname}/event/${eventID}`)
            .once('value')
            .then(snapshot => (snapshot.exists() ? snapshot.val() : {}));
          if (subscribers.workforce) {
            const workforces = objectToArray(subscribers.workforce);
            await Promise.all(
              workforces.map(item =>
                firebase
                  .database()
                  .ref(
                    `subscriber_attendees/${appname}/event/workforce/${item.value.uid}`,
                  )
                  .orderByChild('eventID')
                  .equalTo(eventID)
                  .once('value')
                  .then(snapshot => {
                    if (snapshot.exists()) {
                      Object.keys(snapshot.val()).map(deleteKey =>
                        firebase
                          .database()
                          .ref(
                            `subscriber_attendees/${appname}/event/workforce/${item.value.uid}/${deleteKey}`,
                          )
                          .remove(),
                      );
                    }
                  }),
              ),
            );
          }
          if (subscribers.user) {
            const users = objectToArray(subscribers.user);
            await Promise.all(
              users.map(item =>
                firebase
                  .database()
                  .ref(
                    `subscriber_attendees/${appname}/event/user/${item.value.uid}`,
                  )
                  .orderByChild('eventID')
                  .equalTo(eventID)
                  .once('value')
                  .then(snapshot => {
                    if (snapshot.exists()) {
                      Object.keys(snapshot.val()).map(deleteKey =>
                        firebase
                          .database()
                          .ref(
                            `subscriber_attendees/${appname}/event/user/${item.value.uid}/${deleteKey}`,
                          )
                          .remove(),
                      );
                    }
                  }),
              ),
            );
          }

          await firebase
            .database()
            .ref(`subscribers/${appname}/event/${eventID}`)
            .remove();
        }),
    ),
  ).then(() => {
    dispatch(deletedDone(selected));
    dispatch(openSnackbar(EVENT_DELETED));
  });
};

export const onDuplicateSelected = (appname, rowId) => (dispatch, getState) => {
  const { memberbody } = getState().staff.events.list;
  return firebase
    .database()
    .ref(`app_events/${appname}/events/${memberbody}/${rowId}`)
    .on('value', snap => {
      if (snap.exists()) {
        const data = snap.val();

        const dataKeys = Object.keys(data);
        const eventName = data[dataKeys[0]].value;
        data[dataKeys[0]].value = `${eventName} Copy`;

        return firebase
          .database()
          .ref(`app_events/${appname}/events/${memberbody}`)
          .push(data)
          .then(ref => {
            // dispatch({
            //   type: DUPLICATED_EVENT,
            //   key: ref.key,
            // });

            dispatch(openSnackbar(EVENT_DUPLICATED));
          });
      } else {
        dispatch(openSnackbar(EVENT_DUPLICATE_ERROR));
      }
    });
};
