import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import { Trans, withTranslation } from 'react-i18next';

import AddToPlanDialog from '../../../../../../app/components/addToPlanDialog/';
import * as images from '../../../../../editor/modules/build/modules/create/createComponent/images';
import { features } from '../../../../../../plans';

import themeStyles from '../../../../../../_export.scss';
import '../../styles.scss';

const TrackSwitchedColor = { backgroundColor: themeStyles.primaryColorLight };
const ThumbSwitchedColor = { backgroundColor: themeStyles.primaryColor };

class ChatProfileSettings extends React.Component {
  state = {
    selectChatAdmin: false,
  };
  componentDidMount() {
    this.props.loadDiyData();
  }

  render() {
    const {
      chatEnabled,
      profileEnabled,
      signupEnabled,
      updateField,
      handleChatToggle,
      subscription,
      match,
      handdleDiyPopupClose,
      addToDiyPopup,
      history,
      onAddonAdd,
      diyComponents,
      isProcessing,
      intl,
      isAppSumoAccount,
      onlyAdminChat,
      enableGroupChat,
      t,
    } = this.props;
    const { selectChatAdmin } = this.state;
    const enableChat = enableGroupChat === undefined ? true : enableGroupChat;

    return (
      <div>
        <div className="auth-panel">
          <div className="auth-panel-title">
            <Trans>User Features</Trans>
          </div>
          <div className="auth-panel">
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'signupEnabled')}
              toggled={signupEnabled}
              label={t('User Sign Up')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => updateField(b, 'profileEnabled')}
              toggled={profileEnabled}
              label={t('User Profile')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: '0' }}
              onToggle={(v, b) => {
                if (onlyAdminChat === false || !onlyAdminChat) {
                  handleChatToggle(
                    b,
                    'chatEnabled',
                    subscription,
                    Object.keys(diyComponents),
                  );
                  if (!b) {
                    updateField(b, 'enableGroupChat');
                  }
                }
              }}
              toggled={chatEnabled}
              label={t('User Chat')}
              trackSwitchedStyle={TrackSwitchedColor}
              thumbSwitchedStyle={ThumbSwitchedColor}
            />
            <Toggle
              style={{ margin: 0 }}
              onToggle={(e, value) => {
                updateField(value, 'onlyAdminChat');
                if (value) {
                  handleChatToggle(
                    false,
                    'chatEnabled',
                    subscription,
                    Object.keys(diyComponents),
                  );
                  updateField(false, 'enableGroupChat');
                }
                this.setState({ selectChatAdmin: value });
              }}
              toggled={onlyAdminChat}
              label={t('Only Chat with Admin')}
            />
            {selectChatAdmin && (
              <div className="notification-admin">
                <a href="#chatSettings">
                  <Trans>Select a Chat admin.</Trans>
                </a>
              </div>
            )}

            <Toggle
              style={{ margin: 0 }}
              onToggle={(e, value) => {
                if (onlyAdminChat === false || !onlyAdminChat) {
                  updateField(value, 'enableGroupChat');

                  if (value) {
                    handleChatToggle(
                      value,
                      'chatEnabled',
                      subscription,
                      Object.keys(diyComponents),
                    );
                  }
                }
              }}
              toggled={enableGroupChat}
              label={t('Group Chat')}
            />
            <AddToPlanDialog
              open={addToDiyPopup}
              intl={intl}
              subscription={subscription}
              match={match}
              history={history}
              heading={t('Add to existing plan')}
              component={features.UserChat}
              isProcessing={isProcessing}
              onRequestClose={() => {
                handdleDiyPopupClose(false);
              }}
              image={images.comingSoon}
              onAddonAdd={component => {
                onAddonAdd('UserChat', component);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

ChatProfileSettings.propTypes = {
  chatEnabled: PropTypes.bool,
  profileEnabled: PropTypes.bool,
  signupEnabled: PropTypes.bool,
  updateField: PropTypes.func.isRequired,
  isAppSumoAccount: PropTypes.bool.isRequired,
  enableGroupChat: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

ChatProfileSettings.defaultProps = {
  chatEnabled: false,
  profileEnabled: true,
  signupEnabled: true,
  isAppSumoAccount: true,
};

export default withTranslation()(ChatProfileSettings);
