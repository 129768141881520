import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import { useTranslation } from 'react-i18next';

import { RaisedButton } from '../../../../../../../app/components/buttons';
import { FormattedNumber } from 'react-intl';
import { CircularProgress } from '../../../../../../../app/components/progress';
import {
  plans,
  ADDON_MONTHLY_PRICE,
  ADDON_YEARLY_PRICE,
} from '../../../../../../../plans';

import {
  APP_SUMO_PLANS,
  componentNewNames,
} from '../../../../../../../plans/constants';
import './styles.scss';
import themeStyles from '../../../../../../../_export.scss';

const styles = {
  buttonRoot: {
    margin: '12px',
    width: '200px',
  },
  SecondaryLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.primaryColor,
  },
  PrimaryLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.primaryTextColor,
  },
  textFieldUnderlineStyle: {
    borderColor: themeStyles.mainGrey,
  },
  textFieldLabelStyle: {
    color: themeStyles.mainGrey,
    fontSize: '20px',
  },
  actionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
};

const DiyDialog = props => {
  const {
    open,
    onClose,
    component,
    action,
    intl,
    isProcessing,
    subscriptionItem,
    appname,
    nextInvoiceAmount,
    hasTrialExpired,
    isLoading,
  } = props;
  const { t } = useTranslation();

  let period = 'monthly';
  let plan = 'diy';
  let addOnQty = 0;
  const [invoicePrice, setInvoicePrice] = useState(0);

  useEffect(() => {
    const price = calculatePrice();

    setInvoicePrice(price);
  }, [nextInvoiceAmount]);
  if (
    subscriptionItem &&
    subscriptionItem.items &&
    subscriptionItem.items[appname]
  ) {
    const subscription = subscriptionItem.items[appname];
    period = subscription.period;
    plan = subscription.plan;

    if (subscription.addOn) {
      addOnQty = !nextInvoiceAmount ? subscription.addOn.quantity : 0;
    }
  }

  const actions = (action, onClose, isProcessing) => (
    <div style={styles.actionContainer}>
      <RaisedButton
        label={t('Cancel')}
        primary
        onClick={() => onClose()}
        backgroundColor={themeStyles.secondaryColor}
        style={styles.buttonRoot}
        labelStyle={styles.SecondaryLabelStyle}
        disabled={isProcessing}
      />
      <RaisedButton
        label={t('Confirm')}
        secondary
        onClick={() => {
          action();
        }}
        disabled={isProcessing}
        backgroundColor={themeStyles.primaryColor}
        style={styles.buttonRoot}
        labelStyle={styles.PrimaryLabelStyle}
      />
    </div>
  );

  const calculatePrice = () => {
    let currency;
    let finalPlan;

    let price = nextInvoiceAmount ? nextInvoiceAmount / 100 : null;

    if (plan && plan.includes('-')) {
      const formattedPlan = plan.replace('2023', '');
      currency = formattedPlan.split('-')[1];
      if (currency.includes('_')) {
        currency = currency.split('_')[0];
      }
    }

    if (plan && plan.includes('-')) {
      finalPlan = plan.split('-')[0];
    } else {
      finalPlan = plan;
    }

    if (plans[finalPlan]) {
      if (!price) {
        if (currency) {
          price =
            period === 'yearly'
              ? plans[finalPlan][currency].year_price * 12
              : plans[finalPlan][currency].price;
        } else {
          price =
            period === 'yearly'
              ? plans[finalPlan].year_price * 12
              : plans[finalPlan].price;
        }
      }
      let addOnPrice;
      if (currency) {
        addOnPrice =
          (addOnQty + component[currency] && component[currency].price) *
          (period === 'yearly' ? ADDON_YEARLY_PRICE : ADDON_MONTHLY_PRICE);
      } else {
        addOnPrice =
          (addOnQty + component.price) *
          (period === 'yearly' ? ADDON_YEARLY_PRICE : ADDON_MONTHLY_PRICE);
      }

      if (APP_SUMO_PLANS.includes(plan)) {
        return addOnPrice;
      }

      return price + addOnPrice;
    }
  };
  let currency;
  if (plan && plan.includes('-')) {
    const formattedPlan = plan.replace('2023', '');
    currency = formattedPlan.split('-')[1];
  }

  return (
    <Dialog
      open={open}
      actions={
        isLoading
          ? null
          : action !== null
          ? actions(action, onClose, isProcessing)
          : [actions(action, onClose, isProcessing)[0]]
      }
      onRequestClose={onClose}
      autoScrollBodyContent
      style={{ textAlign: 'center' }}
      actionsContainerStyle={{ textAlign: 'center' }}
      contentClassName="customModal"
      paperClassName="customModalPaper"
    >
      <div className="diy-dialog-content">
        {isLoading ? (
          <>
            <h2>Please wait...</h2>
          </>
        ) : (
          <>
            <h2>{`Add ${
              componentNewNames[component.name]
                ? componentNewNames[component.name]
                : component.name
            } component?`}</h2>
            <p>
              {APP_SUMO_PLANS.includes(plan) ? (
                <>
                  {!hasTrialExpired ? (
                    'Add on a 30-day free trial'
                  ) : (
                    <span>
                      You will be charged{' '}
                      <FormattedNumber
                        maximumFractionDigits={2}
                        format={intl.currency}
                        currency={currency || 'gbp'}
                        value={invoicePrice}
                      />{' '}
                      per month{' '}
                    </span>
                  )}
                </>
              ) : (
                <>
                  Your {period} fee will now be &nbsp;
                  <FormattedNumber
                    maximumFractionDigits={2}
                    format={intl.currency}
                    currency={currency || 'gbp'}
                    value={invoicePrice}
                  />
                </>
              )}
            </p>
          </>
        )}

        {isProcessing ? <CircularProgress color="secondary" /> : null}
      </div>
    </Dialog>
  );
};

DiyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  action: PropTypes.func,
};

DiyDialog.defaultProps = {
  action: null,
  mesage:""
};

export default DiyDialog;
