import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import VoteDetails from './VoteDetails';

import {
  closeVoteDetailDialog,
  previousResponse,
  nextResponse,
} from './actions';
import { favouriteSelected, deleteSelected } from '../actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.analytics.vote.voteDetails,
  responseKey: state.analytics.vote.voteDetails.key,
  totalResponses: state.analytics.vote.data.items.length,
  currentResponse:
    state.analytics.vote.data.items
      .map(key => key === state.analytics.vote.voteDetails.key)
      .indexOf(true) + 1,
  votes: state.analytics.vote.data.votes,
  items: state.analytics.vote.data.items,
  filteredChunks: state.analytics.vote.data.filteredChunks,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRequestClose: () => dispatch(closeVoteDetailDialog()),
  onPreviousResponse: (currentResponse, votes, items) =>
    dispatch(previousResponse(currentResponse, votes, items)),
  onNextResponse: (currentResponse, votes, items) =>
    dispatch(nextResponse(currentResponse, votes, items)),
  onFavourite: selected =>
    dispatch(favouriteSelected(ownProps.match.params.appname, selected)),
  onDelete: selected =>
    dispatch(deleteSelected(ownProps.match.params.appname, selected)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VoteDetails),
);
