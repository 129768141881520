import React, { useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import ActionMenu from 'app/components/actionMenu';
import Checkbox from '@material-ui/core/Checkbox';
import ResultsTable from 'app/components/resultsTable';
import TryAgain from 'app/components/tryAgain';
import { sortArray } from 'utils';
import Chip from '@material-ui/core/Chip';
import TableCheckbox from '../../../memberLogin/components/tableCheckbox';
import RegionalPerformanceModal from './RegionalPerformanceModal';
import Header from '../../../memberLogin/components/header';
import themeStyles from '../../../../_export.scss';
import './styles.css';

const isCheckboxDisabled = (error, pending) => error && !pending;

const RegionalPerformanceCentre = props => {
  const {
    regionalPerformanceCentreData,
    togglePerformanceModal,
    match: {
      params: { appname },
    },
    onFieldChangeWithUniqueCheck,
    onFieldChange,
    onMultipleValueFieldChange,
    onSubmit,
    getRegionalPerformanceCentre,
    onFilterPerformanceCentreData,
    onSelectAllPerformanceCentres,
    onDeselectAllPerformanceCentres,
    onDeleteSelected,
    onSelect,
    onUpdateRegionalPerformanceCentre,
    fetchRegionalCentreImages,
    onServicesInfoFieldChange,
    onToggleRegionalSnackbar,
    onDeleteImage,
  } = props;

  const {
    selected,
    chunks,
    error,
    filteredChunks,
    pending,
    items,
    selectAll,
    snackbarOpen,
  } = regionalPerformanceCentreData;

  useEffect(() => {
    getRegionalPerformanceCentre();
  }, []);

  const getTitle = (centreSelected, RegionalPerformanceCentres) => (
    <span>
      Regional Performance Centres:{' '}
      {centreSelected.length
        ? `${centreSelected.length} / ${
            Object.keys(RegionalPerformanceCentres).length
          }`
        : Object.keys(RegionalPerformanceCentres).length}
    </span>
  );

  const getSortedItems = () => {
    let sortedItems = sortArray(Object.entries(filteredChunks), 'location');
    sortedItems = sortedItems.map(entity => entity[0]);
    return sortedItems;
  };

  const getSelectAllCheckBox = () => {
    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked
              ? onSelectAllPerformanceCentres()
              : onDeselectAllPerformanceCentres()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          text={'regionalPerformanceCentres'}
          customDeleteText="Are you sure you wish to delete?"
        />
      </div>
    );
  };

  const body = error ? (
    <TryAgain
      message="Oops, something went wrong..."
      onRetry={getRegionalPerformanceCentre}
    />
  ) : (
    <ResultsTable
      items={getSortedItems()}
      chunks={filteredChunks}
      loading={pending}
      pending={pending}
      error={Object.keys(filteredChunks).length === 0 ? 'yes' : null}
      emptyMessage={`Sorry, no results found.`}
      onRowTouchTap={rowId =>
        onUpdateRegionalPerformanceCentre(rowId, filteredChunks[rowId])
      }
      pgmolTable
      columns={[
        {
          id: 'location',
          header: 'Location',
          classNames: 'col-xs-2 regional-table-text-left',
        },
        {
          id: 'address',
          header: 'Address',
          classNames: 'col-xs-4 regional-table-text-left',
          containerElement: ({ value }) => <pre>{value}</pre>,
        },
        {
          id: 'services',
          header: 'Services',
          classNames: 'col-xs-5 regional-table-text-left',
          containerElement: ({ value }) => (
            <span>
              {value &&
                value.map(({ label }) => (
                  <Chip sx={{ margin: '5px' }} label={label || ''} />
                ))}
            </span>
          ),
        },

        {
          id: 'selected',
          header: getSelectAllCheckBox(),
          classNames: `col-xs-1 regional-table-text-left ${
            items.length < 4 ? 'fix-left' : 'selected'
          }`,
          containerElement: ({ value, rowId }) =>
            rowId !== 'default' && (
              <>
                <TableCheckbox value={value} onSelect={() => onSelect(rowId)} />
                <ActionMenu
                  selected={[rowId]}
                  onDeleteSelected={() => onDeleteSelected([rowId], chunks)}
                  onEditSelected={() =>
                    onUpdateRegionalPerformanceCentre(
                      rowId,
                      filteredChunks[rowId],
                    )
                  }
                  text={'regionalPerformanceCentre'}
                  customDeleteText="Are you sure you wish to delete?"
                />
              </>
            ),
        },
      ]}
    />
  );

  return (
    <section style={{ height: '80vh', overflow: 'hidden' }}>
      <Header
        {...regionalPerformanceCentreData}
        title={getTitle(selected, chunks)}
        onChange={onFilterPerformanceCentreData}
        onSelectAllResponses={onSelectAllPerformanceCentres}
        onDeselectAllResponses={onDeselectAllPerformanceCentres}
        actionMenu={
          <ActionMenu
            selected={selected}
            onDeleteSelected={() => onDeleteSelected(selected, chunks)}
            text={'regioanlPerformanceCentre'}
            customDeleteText="Are you sure you wish to delete?"
          />
        }
        fullWidthHeader
      />
      <div className="pgmol-table-container">{body}</div>
      {regionalPerformanceCentreData.modalOpen && (
        <RegionalPerformanceModal
          data={regionalPerformanceCentreData}
          togglePerformanceModal={togglePerformanceModal}
          appname={appname}
          onFieldChangeWithUniqueCheck={onFieldChangeWithUniqueCheck}
          onFieldChange={onFieldChange}
          onMultipleValueFieldChange={onMultipleValueFieldChange}
          onSubmit={onSubmit}
          fetchRegionalCentreImages={fetchRegionalCentreImages}
          onServicesInfoFieldChange={onServicesInfoFieldChange}
          onToggleRegionalSnackbar={onToggleRegionalSnackbar}
          onDeleteImage={onDeleteImage}
        />
      )}
    </section>
  );
};

export default withRouter(RegionalPerformanceCentre);
