import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';

import ButtonLinkMenu from '../../../../../components/buttonLinkMenu';
import Container from '../../../../../../../app/components/container';
import TextField from '../../../../../../../app/components/textField';
import SectionHeader from '../../../../../components/sectionHeader';
import ImageUpload from '../../../../../internals/imageUpload';

class SliderItem extends Component {
  static propTypes = {
    component: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    googleAnalytics: PropTypes.object.isRequired,
    onContentChange: PropTypes.func.isRequired,
    childRoutes: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  getImageUrl = () => {
    const { match, location, component } = this.props;

    const item = queryString.parse(location.search).item;

    return `apps/${match.params.appname}/draft/lazy_data/${component.lazy_data}/slides/chunks/${item}/image`;
  };

  getDesktopImageUrl = () => {
    const { match, location, component } = this.props;

    const item = queryString.parse(location.search).item;

    return `apps/${match.params.appname}/draft/lazy_data/${component.lazy_data}/slides/chunks/${item}/desktopImage`;
  };

  handleChange = field => value => this.props.onContentChange(field, value);
  handleChangeLinkSection = (prop, value) =>
    this.props.onContentChange(prop, value);

  render() {
    const { image, title, subtitle, link, desktopImage } = this.props.data;
    const { t } = this.props;

    return (
      <Container>
        <SectionHeader
          title={t('Image')}
          tooltip={t('Upload an image for the slide')}
        />
        <ImageUpload
          targetId="slider_component_upload"
          title={t('Slider Item > Image')}
          src={image}
          firebaseUrl={this.getImageUrl()}
          showCropAreaSizes
          tooltip={t('Minimum image size 240x240 pixels')}
          height="220px"
          width="320px"
        />
        <SectionHeader
          title={t('Desktop Image')}
          tooltip={t('Upload a desktop image for the slide')}
        />
        <ImageUpload
          targetId="desktop_slider_component_upload"
          title={t('Slider Item > Desktop Image')}
          src={desktopImage}
          firebaseUrl={this.getDesktopImageUrl()}
          showCropAreaSizes
          tooltip={t('Minimum image size 240x240 pixels')}
          height="220px"
          width="320px"
        />
        <TextField
          id="roster-item-title"
          value={title}
          onChange={this.handleChange('title')}
          placeholder={t('Title')}
        />
        <TextField
          id="roster-item-subtitle"
          value={subtitle}
          onChange={this.handleChange('subtitle')}
          placeholder={t('Subtitle')}
        />
        <ButtonLinkMenu
          link={link}
          pages={this.props.childRoutes}
          buttonOptional
          onChange={this.handleChangeLinkSection}
          borderTop
          googleAnalytics={this.props.googleAnalytics}
          gaEnabled={this.props.data.gaEnabled}
          gaCategory={this.props.data.gaCategory}
          gaAction={this.props.data.gaAction}
        />
      </Container>
    );
  }
}

export default withTranslation()(SliderItem);
