import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import DelegatesItem from './DelegatesItem';
import { itemFieldChange } from '../actions';

const mapStateToProps = (state, ownProps) => {
  const layoutData =
    state.editor.data.layoutData[ownProps.match.params.appname];
  const childRoutes = layoutData && layoutData.childRoutes;
  return {
    ...ownProps,
    googleAnalytics: state.app.googleAnalytics,
    childRoutes:
      childRoutes &&
      childRoutes.map(key => ({ ...state.editor.data.alwaysData[key], key })),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) => {
    const { item } = queryString.parse(ownProps.location.search);

    return dispatch(
      itemFieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        ownProps.component.type,
        item,
        field,
        value,
      ),
    );
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DelegatesItem),
);
