import { connect } from 'react-redux';

import RedeemHistory from './RedeemHistory';
import { fetchRedeemHistory } from './actions';

const mapStateToProps = state => ({
    ...state.account.referral.redeemHistory
});

const mapDispatchToProps = dispatch => ({
    fetchRedeemHistory: () => dispatch(fetchRedeemHistory()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RedeemHistory);
