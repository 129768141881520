import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import { Trans, useTranslation } from 'react-i18next';
import ImageCarousel from '../../components/imageCarousel';
import { Button, CircularProgress, Fade } from '@material-ui/core';
import { capitalize, get } from 'lodash';
import './styles.css';
import ApprovalDialog from '../../../../../../app/components/approvalDialog/ApprovalDialog';
const getPrice = (price, currency = 'USD') =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(price);
const Product = ({
  match,
  openProductApprovalDialog,
  closeProductApprovalDialog,
  updateProductApprovalStatus,
  productApprovalDialogOpen,
  vendorApprovalEnabled,
}) => {
  const { t } = useTranslation();
  const productApprovedDialogTitle = t(
    'Are you sure you want to approve this product?',
  );
  const productRejectedDialogTitle = t(
    'Are you sure you want to reject this product?',
  );
  const productId = match.params.productId;
  const appname = match.params.appname;
  const [productDetails, setProductDetails] = useState({});
  const [vendorDetails, setVendorDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [productApprovalStatus, setProductApprovalStatus] = useState(null);
  const currency = useSelector(state =>
    get(
      state,
      'ecommerce.marketplace.settings.data.marketplace_settings.selectedCurrency',
      'USD',
    ),
  );
  const getProductDetails = () => {
    setLoading(true);
    firebase
      .database()
      .ref(`marketplace_products/${appname}/${productId}`)
      .on('value', snap => {
        const product = snap.exists() && snap.val() ? snap.val() : {};
        setProductDetails({ id: productId, ...product });
        setLoading(false);
      });
  };

  const getVendorDetails = vendorId => {
    firebase
      .database()
      .ref(`app_users/${appname}/${vendorId}`)
      .once('value', snap => {
        const vendor = snap.exists() ? snap.val() : {};
        setVendorDetails(vendor);
      });
  };
  useEffect(() => {
    getProductDetails();
    return () =>
      firebase
        .database()
        .ref(`marketplace_products/${productId} `)
        .off('value');
  }, [productId]);

  useEffect(() => {
    productDetails.vendorID && getVendorDetails(productDetails.vendorID);
  }, [productDetails]);

  const disableApproveButton =
    productDetails.approved ||
    (vendorApprovalEnabled && !vendorDetails.vendorApprovalStatus);

  const disableRejectButton =
    productDetails.rejected ||
    (vendorApprovalEnabled && !vendorDetails.vendorApprovalStatus);

  const isHTML = RegExp.prototype.test.bind(/<[a-z][\s\S]*>/i);

  return (
    <div>
      <Fade in={loading} unmountOnExit mountOnEnter appear>
        <CircularProgress color="primary" />
      </Fade>

      <Fade in={!loading} mountOnEnter appear>
        <div>
          <div className="productContainer">
            <div className="productImage">
              <ImageCarousel images={get(productDetails, 'photoURL', {})} />
            </div>
            <div className="productDetails scroll-container">
              <div className="fields">
                <span className="name">
                  <Trans>Seller</Trans>:
                </span>
                <Link
                  className="seller-link"
                  to={`/${appname}/ecommerce/marketplace/sellers/${productDetails.vendorID}`}
                >
                  <span>
                    {capitalize(get(vendorDetails, 'firstName', 'N/A'))}
                  </span>
                </Link>
              </div>
              <div className="fields">
                <span className="name">
                  <Trans>Name</Trans>:
                </span>
                <span>{get(productDetails, 'name', 'N/A')}</span>
              </div>
              <div className="fields">
                <span className="name">
                  <Trans>Sub Title</Trans>:{' '}
                </span>
                <span>{get(productDetails, 'subTitle', 'N/A')}</span>
              </div>
              {productDetails.location && (
                <div className="fields">
                  <span className="name">
                    <Trans>Location</Trans>:
                  </span>
                  <span>
                    <span>{get(productDetails, 'location.value', 'N/A')}</span>
                  </span>
                </div>
              )}

              <div className="fields">
                <span className="name">
                  <Trans>Price</Trans>:
                </span>
                <span>{getPrice(productDetails.price, currency)}</span>
              </div>
              {Object.keys(get(productDetails, 'filter.fields', {})).map(
                fieldId => (
                  <div className="fields">
                    <div className="name">
                      {get(
                        productDetails,
                        `filter.fields.${fieldId}.name`,
                        'N/A',
                      )}
                      :
                    </div>
                    <div className="value">
                      {get(
                        productDetails,
                        `filter.fields.${fieldId}.data.value`,
                        'N/A',
                      )}
                    </div>
                  </div>
                ),
              )}
              <div className="fields scroll-container productDescription">
                <span className="name ">
                  <Trans>Description</Trans>:
                </span>

                {isHTML(get(productDetails, 'description', '')) ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: get(productDetails, 'description', 'N/A'),
                    }}
                  ></span>
                ) : (
                  <span>{get(productDetails, 'description', 'N/A')}</span>
                )}
              </div>
              {vendorApprovalEnabled && !vendorDetails.vendorApprovalStatus && (
                <p className="approve-vendor-msg">
                  <Trans>
                    To approve the product first approve the Seller.
                  </Trans>
                </p>
              )}
              <div className="action-container">
                <Button
                  color="primary"
                  variant="contained"
                  className={`productAcceptButton ${disableApproveButton &&
                    'disabledButton'}`}
                  onClick={() => {
                    setProductApprovalStatus(true);
                    openProductApprovalDialog();
                  }}
                  disabled={disableApproveButton}
                >
                  <Trans>Approve Product</Trans>
                </Button>
                <Button
                  variant="contained"
                  className={`productRejectButton ${disableRejectButton &&
                    'disabledButton'}`}
                  onClick={() => {
                    setProductApprovalStatus(false);
                    openProductApprovalDialog();
                  }}
                  disabled={disableRejectButton}
                >
                  <Trans>Reject Product</Trans>
                </Button>
              </div>
            </div>
          </div>
          <ApprovalDialog
            open={productApprovalDialogOpen}
            onClose={closeProductApprovalDialog}
            dialogTitle={
              productApprovalStatus
                ? productApprovedDialogTitle
                : productRejectedDialogTitle
            }
            action={message =>
              updateProductApprovalStatus(
                productDetails,
                productApprovalStatus,
                message,
              )
            }
          />
        </div>
      </Fade>
    </div>
  );
};

export default Product;
