import { newValidator } from '../../../../utils';

const emailValidator = newValidator({
  type: 'email',
});

const mobileValidator = newValidator({
  type: 'mobile',
});

const internationalValidator = newValidator({
  type: 'international',
});

const nameValidator = newValidator({
  minLength: 2,
  required: true,
});

export const validateField = {
  email: value => emailValidator(value),
  mobile: value => mobileValidator(value),
  name: value => nameValidator(value),
  international: value => internationalValidator(value),
};
