import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';

import { LogoToolbar } from '../../../../../app/modules/toolbar';
import { CircularProgress } from '../../../../../app/components/progress';
import { RaisedButton } from '../../../../../app/components/buttons';
import TextField from '../../../../../app/components/textField';
import './styles.css';
import themeStyles from '../../../../../_export.scss';
import { whitelabeledHost } from '../../../../../utils/whitelabeledHost';

const styles = {
  buttonRoot: {
    margin: '12px',
  },
  SignUpLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.secondaryTextColor,
  },
  LogInLabelStyle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: themeStyles.primaryTextColor,
  },
  textFieldUnderlineStyle: {
    borderColor: themeStyles.mainGrey,
  },
  textFieldLabelStyle: {
    color: themeStyles.mainGrey,
    fontSize: '20px',
  },
};

class Login extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  state = {
    email: '',
    password: '',
  };

  submitForm = e => {
    e.preventDefault();
    const offer = this.props.offers[this.props.offerFromQuery];
    this.props.onSubmit(this.state, offer);
  };

  render() {
    const { isFetching, error, t } = this.props;
    const isWhiteLabeledHost = whitelabeledHost();

    return (
      <div className="white-backdrop">
        <LogoToolbar />
        <div className="login">
          <div className="login-header">
            <p>
              <Trans>
                Login to your account and start creating amazing apps!
              </Trans>
            </p>
          </div>
          <div className="login-input-container">
            {!isFetching && (
              <div className="login-input-group">
                <form onSubmit={this.submitForm} autoComplete="off" noValidate>
                  <TextField
                    id="email"
                    type="email"
                    placeholder={t('Email Address')}
                    value={this.state.email}
                    onChange={val => this.setState({ email: val })}
                    labelStyle={styles.textFieldLabelStyle}
                    underlineStyle={styles.textFieldUnderlineStyle}
                  />
                  <TextField
                    id="password"
                    type="password"
                    placeholder={t('Password')}
                    value={this.state.password}
                    onChange={val => this.setState({ password: val })}
                    labelStyle={styles.textFieldLabelStyle}
                    underlineStyle={styles.textFieldUnderlineStyle}
                  />
                  <button type="submit" />
                </form>
                <div className="to-forgotten-password">
                  <Link
                    className="dark-text small-text align-center decorate"
                    to="/forgot_password"
                  >
                    <Trans>Forgot your password?</Trans>
                  </Link>
                </div>
              </div>
            )}
            {isFetching && (
              <div className="flex-1 flex-align-center justify-center">
                <CircularProgress />
              </div>
            )}
          </div>
          {error && <small className="validation-err">{error}</small>}
          <div className="flex-row " style={{ marginTop: '10px' }}>
            {!isWhiteLabeledHost && (
              <Link className="dark-text decorate" to="/register">
                <RaisedButton
                  label={t('Sign Up')}
                  backgroundColor={themeStyles.secondaryColorLight}
                  disabledBackgroundColor={themeStyles.primaryColorLight}
                  style={styles.buttonRoot}
                  labelStyle={styles.SignUpLabelStyle}
                />
              </Link>
            )}
            <RaisedButton
              label={t('Login')}
              backgroundColor={themeStyles.secondaryTextColor}
              disabledBackgroundColor={themeStyles.primaryColorLight}
              style={styles.buttonRoot}
              labelStyle={styles.LogInLabelStyle}
              onClick={e => this.submitForm(e)}
              disabled={isFetching}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Login);
