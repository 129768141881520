import { combineReducers } from 'redux';

import app from './app/reducer';
import account from './modules/account/reducer';
import editor from './modules/editor/reducer';
import analytics from './modules/analytics/reducer';
import distribute from './modules/distribute/reducer';
import engage from './modules/engage/reducer';
import auth from './modules/memberLogin/reducer';
import staff from './modules/staff/reducer';
import ecommerce from './modules/ecommerce/reducer';
import fkAcademy from './modules/fkAcademy/reducer';
import pgmol from './modules/pgmol/reducer';

export default combineReducers({
  app,
  account,
  editor,
  analytics,
  distribute,
  engage,
  auth,
  staff,
  ecommerce,
  fkAcademy,
  pgmol,
});
