import { connect } from 'react-redux';

import Rating from './Rating';
import {
  watchRatingData,
  removeRatingDataWatcher,
  getAllRatings,
  selectResponse,
  selectAllResponses,
  deselectAllResponses,
  filterRatingData,
  favouriteSelected,
  deleteSelected,
  sortTableData,
} from './actions';
import { openRatingDetailDialog } from './ratingDetails/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...ownProps.match.params,
  ...state.analytics.rating,
  ...state.analytics.rating.data,
  accountId: state.account.auth.profile.account,
  subscription:
    state.account.subscriptions.items[ownProps.match.params.appname] || {},
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  watchRatingData: () =>
    dispatch(watchRatingData(ownProps.match.params.appname)),
  removeRatingDataWatcher: () =>
    dispatch(removeRatingDataWatcher(ownProps.match.params.appname)),
  getAllRatings: () => dispatch(getAllRatings(ownProps.match.params.appname)),
  onSelect: key => dispatch(selectResponse(key)),
  onSelectAllResponses: () => dispatch(selectAllResponses()),
  onDeselectAllResponses: () => dispatch(deselectAllResponses()),
  onOpenRatingDetail: key => dispatch(openRatingDetailDialog(key)),
  onFilterRatingData: filter => dispatch(filterRatingData(filter)),
  onFavouriteSelected: selected =>
    dispatch(favouriteSelected(ownProps.match.params.appname, selected)),
  onDeleteSelected: selected =>
    dispatch(deleteSelected(ownProps.match.params.appname, selected)),
  onSort: cols => dispatch(sortTableData(cols)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rating);
