import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import EditFilter from './EditFilter';

import {
  updateFilter,
  getFilterDetails,
  updateField,
  deleteField,
} from './actions';

const mapStateToProps = state => ({
  ...state.ecommerce.marketplace.filters.edit.editFilter,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getFilterDetails: () =>
    dispatch(
      getFilterDetails(
        ownProps.match.params.appname,
        ownProps.match.params.categoryID,
      ),
    ),
  updateField: (name, fieldID, value) =>
    dispatch(
      updateField(
        ownProps.match.params.appname,
        ownProps.match.params.categoryID,
        name,
        fieldID,
        value,
      ),
    ),
  updateFilter: (item, value) =>
    dispatch(
      updateFilter(
        ownProps.match.params.appname,
        ownProps.match.params.categoryID,
        item,
        value,
      ),
    ),
  deleteField: id =>
    dispatch(
      deleteField(
        ownProps.match.params.appname,
        ownProps.match.params.categoryID,
        id,
      ),
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditFilter),
);
