import React from 'react';

import './styles.css';

const History = () => (
  <svg
    className="icon history-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40.5 46.97"
  >
    <title>Share History</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M20,17a6.49,6.49,0,1,0,6.48,6.48A6.49,6.49,0,0,0,20,17Zm0,11.47a5,5,0,1,1,5-5A5,5,0,0,1,20,28.47Z"
        />
        <path
          class="cls-1"
          d="M36.75,5.64h-3.9L35.91,3l-1-1.13L29.8,6.32l5.13,4.49,1-1.12L33,7.14h3.74A2.25,2.25,0,0,1,39,9.39v28a2.25,2.25,0,0,1-2.25,2.25H26.44a6.45,6.45,0,1,0,0,1.5H36.75a3.76,3.76,0,0,0,3.75-3.75v-28A3.75,3.75,0,0,0,36.75,5.64ZM20,45.47a5,5,0,1,1,5-5A5,5,0,0,1,20,45.47Z"
        />
        <path
          class="cls-1"
          d="M4.44,37,7.5,39.64H3.75A2.25,2.25,0,0,1,1.5,37.39v-28A2.25,2.25,0,0,1,3.75,7.14h9.81a6.52,6.52,0,1,0,0-1.5H3.75A3.75,3.75,0,0,0,0,9.39v28a3.76,3.76,0,0,0,3.75,3.75h3.6L4.44,43.69l1,1.12,5.13-4.49-5.13-4.5ZM20,1.5a5,5,0,1,1-5,5A5,5,0,0,1,20,1.5Z"
        />
      </g>
    </g>
  </svg>
);

export default History;
