const getLocationData = async (appname, time, type) => {
  const body = JSON.stringify({ appname, time, type });
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  const response = await fetch(
    `${process.env.REACT_APP_LOCATION_ANALYTICS_API}/get_location_analytics`,
    {
      method: 'POST',
      mode: 'cors',
      headers,
      body,
    },
  );

  const json = await response.json();

  return json;
};

const firebaseLocationData = async (
  appname,
  time,
  dispatch,
  type,
  disptchType,
) => {
  const json = await getLocationData(appname, time, type);

  dispatch({
    type: disptchType,
    appname,
    value: json,
  });
};

export const threeMonthsVisits = appname => dispatch => {
  firebaseLocationData(appname, 3, dispatch, 'visits', 'THREE_MONTHS_VISITS');
};

export const sixMonthsVisits = appname => dispatch => {
  firebaseLocationData(appname, 6, dispatch, 'visits', 'SIX_MONTHS_VISITS');
};
export const oneYearVisits = appname => dispatch => {
  firebaseLocationData(appname, 12, dispatch, 'visits', 'ONE_YEAR_VISITS');
};

export const threeMonthsInstalls = appname => dispatch => {
  firebaseLocationData(
    appname,
    3,
    dispatch,
    'installs',
    'THREE_MONTHS_INSTALLS',
  );
};

export const sixMonthsInstalls = appname => dispatch => {
  firebaseLocationData(appname, 6, dispatch, 'installs', 'SIX_MONTHS_INSTALLS');
};
export const oneYearInstalls = appname => dispatch => {
  firebaseLocationData(appname, 12, dispatch, 'installs', 'ONE_YEAR_INSTALLS');
};
