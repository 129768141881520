import React from 'react';

const PdfViewer = props => (
  <svg
    enableBackground="new 0 0 65 65"
    id="Layer_1"
    viewBox="0 0 21.03 21.03"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={36}
    height={36}
    {...props}
  >
    <g fill="#261ed4">
      <path d="M1.133 9.154H1.617c0 -0.907 0.355 -1.779 1.002 -2.427c0.645 -0.645 1.521 -1.002 2.427 -1.002v-0.485c-1.036 0 -2.039 0.42 -2.78 1.133C1.553 7.118 1.133 8.088 1.133 9.154z" />
      <path
        height={1.5}
        width={1.5}
        x={14.9}
        y={54}
        d="M4.822 17.471H5.304V17.955H4.822V17.471z"
      />
      <path
        height={1.5}
        width={1.5}
        x={14.9}
        y={57.7}
        d="M4.822 18.667H5.304V19.152H4.822V18.667z"
      />
      <path d="M19.572 3.073V1.521h-1.553V0H4.529v4.044C1.94 4.304 -0.098 6.47 -0.098 9.154c0 2.523 1.813 4.594 4.207 5.047v0.938c-0.42 0.032 -0.777 0.389 -0.777 0.808v3.366c0 0.938 0.777 1.715 1.715 1.715c0.938 0 1.715 -0.777 1.715 -1.715V15.95c0 -0.454 -0.355 -0.808 -0.777 -0.808v-0.938c0.583 -0.098 1.099 -0.323 1.617 -0.583v7.441h10.612l2.91 -2.91V3.073H19.572zM19.089 3.073H7.604v1.617c-0.323 -0.195 -0.681 -0.355 -1.068 -0.454V2.006H19.089V3.073zM5.014 0.485h12.52v1.068H6.082v2.555c-0.323 -0.064 -0.681 -0.098 -1.002 -0.098c0 0 -0.032 0 -0.032 0V0.485zM0.42 9.154c0 -2.555 2.103 -4.659 4.659 -4.659c2.555 0 4.659 2.103 4.659 4.659c0 2.555 -2.103 4.659 -4.659 4.659C2.491 13.782 0.42 11.711 0.42 9.154zM5.951 15.625c0.195 0 0.323 0.161 0.323 0.323v3.366c0 0.681 -0.552 1.23 -1.23 1.23s-1.23 -0.552 -1.23 -1.23V15.95c0 -0.195 0.161 -0.323 0.323 -0.323h0.454v-1.358c0.161 0 0.293 0.032 0.454 0.032c0.161 0 0.293 0 0.454 -0.032v1.358H5.951zM8.088 13.265c1.261 -0.938 2.103 -2.427 2.103 -4.141c0 -1.684 -0.84 -3.203 -2.103 -4.141V3.56h12.52v14.203h-2.78v2.78H8.088V13.265zM20.317 18.247l-1.973 1.973v-1.973H20.317z" />
      <path
        height={1.5}
        width={29.1}
        x={30.8}
        y={17.3}
        d="M9.965 5.598H19.378V6.082H9.965V5.598z"
      />
      <path
        height={1.5}
        width={26.1}
        x={33.8}
        y={25.1}
        d="M10.934 8.122H19.378V8.607H10.934V8.122z"
      />
      <path
        height={1.5}
        width={27.8}
        x={32}
        y={33}
        d="M10.352 10.677H19.346V11.162H10.352V10.677z"
      />
      <path
        height={1.5}
        width={30.9}
        x={28.9}
        y={40.9}
        d="M9.35 13.231H19.346V13.716H9.35V13.231z"
      />
      <path
        height={1.5}
        width={30.9}
        x={28.9}
        y={48.8}
        d="M9.35 15.787H19.346V16.273H9.35V15.787z"
      />
    </g>
  </svg>
);

export default PdfViewer;
