/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
  get,
  orderBy,
  map,
  size,
  set,
  reduce,
  keys,
  capitalize,
  pickBy,
  pick,
  mapValues,
} from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import BeezerRadioButton from 'app/components/radiobutton/RadioButton';
import { objectToArray, arrayToObject, downloadCSV } from '../../../../utils';
import firebase from '../../../../firebase';
import ResultsTable from '../../../../app/components/resultsTable';
import TableCheckbox from '../../../../app/components/tableCheckbox';
import TryAgain from '../../../../app/components/tryAgain';
import Header from '../../../../app/components/header';
import ActionMenu from '../../../../app/components/actionMenu';
import FeedbackDialog from './modules/FeedbackDialog';
import themeStyles from '../../../../_export.scss';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';
import { getSpecialComponents } from '../../../../modules/editor/modules/build/modules/create/createComponent/actions';
import FeedbackUpgradeDialog from './modules/UpgradeDialog/FeedbackUpgradeDialog';

const isCheckboxDisabled = (error, pending) => error && !pending;

const Feedback = ({ match }) => {
  const { t } = useTranslation();
  const [feedbackChunks, setFeedbackChunks] = useState({});
  const [filteredChunks, setFilteredChunks] = useState({});
  const [pending, setPending] = useState(false);
  const [ids, setIds] = useState([]);
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [feedbackToView, setFeedbackToView] = useState(null);
  const [videoDelete, setVideoDelete] = useState('disable');
  const specialAddons = useSelector(
    state => state.editor.build.create.createComponent.specialAddons,
  );
  const isSpecialComponent = specialAddons.filter(
    item => item.component === 'Feedback',
  );

  const hasExpired =
    isSpecialComponent.length > 0 &&
    isSpecialComponent[0].hasFreeTrial &&
    Date.now() > isSpecialComponent[0].expiryDate &&
    !isSpecialComponent[0].hasActiveSubscription;
  const appname = get(match, 'params.appname', '');
  const dispatch = useDispatch();

  const Wrapper = styled.div`
    padding-top: 18px;
  `;

  const sortArray = ({ array, sortProperties, sortOrder }) => {
    const sortedArray = orderBy(array, sortProperties, sortOrder);
    return sortedArray;
  };

  const getFeedbacks = () => {
    try {
      setError(false);
      setPending(true);
      firebase
        .database()
        .ref(`feedback/${appname}`)
        .on('value', snap => {
          setPending(false);

          if (snap.exists()) {
            const transformedObj = reduce(
              snap.val(),
              (feedbackResult, userFeedbacks, userId) => ({
                ...feedbackResult,
                ...reduce(
                  userFeedbacks,
                  (userFeedbacksResult, feedback, feedbackId) => ({
                    ...userFeedbacksResult,
                    [feedbackId]: {
                      ...feedback,
                      id: feedbackId,
                      userId,
                    },
                  }),
                  {},
                ),
              }),
              {},
            );
            const feedbacks = objectToArray(transformedObj);
            const sortProperties = ['value.created', 'value.complete'];
            const sortOrder = ['desc', 'desc'];
            const sortedFeedbacks = sortArray({
              array: feedbacks,
              sortProperties,
              sortOrder,
            });
            const items = map(sortedFeedbacks, feedback => feedback.id);
            const chunks = arrayToObject(sortedFeedbacks);
            setFeedbackChunks(chunks);
            setFilteredChunks(chunks);
            setIds(items);
          }
        });
    } catch (err) {
      setError(err);
    }
  };

  const getCanDeleteVideo = () => {
    try {
      setError(false);
      setPending(true);
      firebase
        .database()
        .ref(`apps/${appname}/canDeleteVideoFeedback`)
        .on('value', snap => {
          setPending(false);

          if (snap.exists()) {
            const value = snap.val() === false ? 'disable' : 'enable';
            setVideoDelete(value);
          }
        });
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    getFeedbacks();
    getCanDeleteVideo();
    dispatch(getSpecialComponents());
    return () => {
      firebase
        .database()
        .ref(`feedback/${appname}`)
        .off('value');
    };
  }, []);

  const onDeleteSelected = feedbackData => {
    const { id, userId } = feedbackData;
    firebase
      .database()
      .ref(`feedback/${appname}/${userId}`)
      .child(id)
      .set(null)
      .then(() => {
        getFeedbacks();
        dispatch(openSnackbar(t('Video feedback deleted successfully.')));
      })
      .catch(err => dispatch(openSnackbar(t('Unable to video feedback!!'))));
  };

  const handleDeleteVideoToggle = value => {
    const canDeleteVideo =
      value === 'enable'
        ? { canDeleteVideoFeedback: true }
        : { canDeleteVideoFeedback: false };
    return firebase
      .database()
      .ref(`apps/${appname}`)
      .update(canDeleteVideo)
      .then(() => {
        dispatch(
          openSnackbar(`${t('Delete Video Feedback')} ${t(`${value}d`)}`),
        );
      });
  };

  const filterChunks = () => {
    const filterText = searchText.replace(/^\s+|\s+$|\s+(?=\s)/g, ''); //remove whitespace
    let modifiedChunks = { ...feedbackChunks };

    if (filterText !== '') {
      modifiedChunks = pickBy(modifiedChunks, chunk => {
        const rgx = new RegExp(`(${filterText})`, 'gi');
        return (
          rgx.test(chunk.fullName) ||
          rgx.test(chunk.userEmail) ||
          rgx.test(chunk.name)
        );
      });
    }
    modifiedChunks = mapValues(modifiedChunks, chunk => ({
      ...chunk,
      selected: false,
    }));
    const filteredIds = keys(modifiedChunks);
    setFilteredChunks(modifiedChunks);
    setIds(filteredIds);
    setSelectAll(false);
  };

  useEffect(() => {
    filterChunks();
  }, [searchText]);

  const getTitle = (selectedFeedbacks, feedbacks) => (
    <span>
      Feedback:{' '}
      {selectedFeedbacks.length
        ? `${selectedFeedbacks.length} / ${Object.keys(feedbacks).length}`
        : Object.keys(feedbacks).length}
    </span>
  );

  const formatDate = date => moment(date).format('DD/MM/YYYY');

  const getCsvData = (selectedFeedbacks, feedback) => {
    const csvColumns = [
      t('Name'),
      t('Email'),
      t('Title'),
      t('Upload Date'),
      t('Status'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selectedFeedbacks.forEach(key => {
      data += `${feedback[key].fullName},${feedback[key].userEmail},${
        feedback[key].name
      },${formatDate(feedback[key].created)},${
        feedback[key].complete ? t('Done') : t('Pending')
      }\n`;
    });

    return data;
  };

  const onSelect = id => {
    const modifiedChunks = { ...filteredChunks };
    const prevValue = get(modifiedChunks, `${id}.selected`, false);
    set(modifiedChunks, `${id}.selected`, !prevValue);
    setFilteredChunks(modifiedChunks);
    const selectedItems = keys(pickBy(modifiedChunks, chunk => chunk.selected));
    setSelected(selectedItems);
  };

  const selectAllFeedbacks = () => {
    const allIds = keys(filteredChunks);
    const modifiedChunks = reduce(
      filteredChunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: true,
        },
      }),
      {},
    );
    setFilteredChunks(modifiedChunks);
    setSelected(allIds);
    setSelectAll(true);
  };

  const deselectAllFeedbacks = () => {
    const modifiedChunks = reduce(
      feedbackChunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: false,
        },
      }),
      {},
    );
    setFilteredChunks(pick(modifiedChunks, ids));
    setSelected([]);
    setSelectAll(false);
  };

  const getSelectAllCheckBox = () => (
    <div style={{ display: 'flex' }}>
      <Checkbox
        checked={selectAll}
        onChange={(e, isChecked) =>
          isChecked ? selectAllFeedbacks() : deselectAllFeedbacks()
        }
        disabled={isCheckboxDisabled(error, pending)}
        style={{ marginLeft: -2, color: themeStyles.primaryColor }}
        disableRipple
      />
      <ActionMenu
        selected={selected}
        onDownloadSelected={() => {
          downloadCSV(getCsvData(selected, filteredChunks), 'feedbacks');
        }}
        text={t('feedbacks')}
      />
    </div>
  );

  const generateTableColumns = () => {
    const suffixClass = ids.length < 4 ? 'fix-left' : 'selected';
    const columns = [
      {
        id: 'fullName',
        header: t('User Name'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) => <div>{capitalize(value)}</div>,
      },
      {
        id: 'userEmail',
        header: t('User Email'),
        classNames: 'col-xs-3',
        containerElement: ({ value }) => <div>{value}</div>,
      },
      {
        id: 'name',
        header: t('Title'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) => <div>{capitalize(value)}</div>,
      },
      {
        id: 'created',
        header: t('Date of upload'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) => (
          <span>{value && formatDate(value)}</span>
        ),
      },
      {
        id: 'complete',
        header: t('Status'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) =>
          value ? (
            <div className="success">
              <Trans>Done</Trans>
            </div>
          ) : (
            <div className="pending">
              <Trans>Pending</Trans>
            </div>
          ),
      },
      {
        id: 'selected',
        header: getSelectAllCheckBox(),
        classNames: `col-xs-1 ${suffixClass}`,
        containerElement: ({ value, rowId }) => (
          <>
            <TableCheckbox
              value={value || false}
              onSelect={() => onSelect(rowId)}
            />
            <ActionMenu
              selected={[rowId]}
              onDownloadSelected={() => {
                downloadCSV(getCsvData([rowId], filteredChunks), 'feedbacks');
              }}
              onEditSelected={() => setFeedbackToView(filteredChunks[rowId])}
              onDeleteSelected={() => onDeleteSelected(filteredChunks[rowId])}
              text={t('feedbacks')}
            />
          </>
        ),
      },
    ];
    return columns;
  };

  return (
    <>
      {error ? (
        <TryAgain
          message={t('Oops, something went wrong...')}
          onRetry={getFeedbacks}
        />
      ) : (
        <>
          <div className="row middle-xs">
            <div className="col-xs-6" />

            <div className="col-xs-6">
              <Wrapper>
                <BeezerRadioButton
                  id="previewButton"
                  className="preview-toggle justify-right"
                  label1={t('Allow users to delete videos')}
                  label2=""
                  onChange={() =>
                    handleDeleteVideoToggle(
                      videoDelete === 'disable' ? 'enable' : 'disable',
                    )
                  }
                  checked={videoDelete !== 'disable'}
                  type="actionBtn"
                />
              </Wrapper>
            </div>
          </div>
          <div style={{ height: 'calc(100% - 166px)' }}>
            <Header
              title={getTitle(selected, filteredChunks)}
              selectAll={selectAll}
              onChange={value => setSearchText(value)}
              onSelectAllResponses={selectAllFeedbacks}
              onDeselectAllResponses={deselectAllFeedbacks}
              actionMenu={
                <ActionMenu
                  selected={selected}
                  onDownloadSelected={() => {
                    downloadCSV(
                      getCsvData(selected, filteredChunks),
                      'feedbacks',
                    );
                  }}
                  text={t('feedbacks')}
                />
              }
            />
            <ResultsTable
              items={ids}
              chunks={filteredChunks}
              pending={pending}
              error={size(filteredChunks) === 0}
              columns={generateTableColumns()}
              emptyMessage={t(
                'Looks like no feedbacks have been submitted yet.',
              )}
              onRowTouchTap={id => {
                setFeedbackToView(filteredChunks[id]);
              }}
            />
          </div>
        </>
      )}
      <FeedbackDialog
        feedback={feedbackToView}
        onClose={() => setFeedbackToView(null)}
      />
      <FeedbackUpgradeDialog open={hasExpired} />
    </>
  );
};

Feedback.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Feedback);
