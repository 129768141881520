import React from 'react';

const FoodIcon = () => (
  <svg className="icon" version="1.0" viewBox="0 0 876.15112 562.08435">
    <g transform="matrix(0.1,0,0,-0.1,-101.84948,821)" stroke="none" id="g26">
      <path
        d="m 1539,8181 c -45,-46 -32,-123 26,-149 20,-9 626,-12 2600,-12 H 6740 V 5935 3850 H 5039 3338 l -29,21 c -58,41 -176,88 -264,104 -162,31 -337,1 -481,-81 l -77,-44 h -459 -460 l -29,-29 -29,-29 v -261 c 0,-180 4,-268 12,-286 24,-53 40,-55 381,-55 h 315 l 15,-69 c 32,-140 137,-306 245,-386 71,-53 201,-114 278,-130 163,-35 346,-11 487,62 108,55 241,191 295,303 23,47 49,115 59,153 l 16,67 h 1731 1732 l 17,-72 c 43,-187 186,-370 349,-450 109,-54 177,-71 298,-75 205,-8 362,47 501,175 112,104 172,203 209,347 l 18,70 632,5 632,5 24,28 24,28 v 902 c 0,1002 2,969 -67,1100 -49,93 -147,191 -239,239 -113,60 -166,68 -436,68 h -238 v 578 c 0,639 -2,658 -63,787 -89,184 -269,316 -470,344 -49,7 -311,11 -702,11 h -624 l -3,441 -3,441 -28,24 -28,24 H 4224 1568 Z M 8331,7052 c 81,-34 148,-89 196,-160 79,-118 77,-94 81,-749 l 3,-583 h -835 -836 v 760 761 l 668,-3 667,-3 z m 279,-2442 v -760 h -208 c -207,0 -207,0 -246,26 -114,78 -280,121 -426,110 -126,-9 -252,-49 -337,-107 l -42,-29 h -205 -206 v 760 760 h 835 835 z m 703,739 c 114,-37 202,-122 249,-236 l 23,-58 3,-602 3,-603 h -396 -395 v 760 760 h 225 c 201,0 231,-2 288,-21 z M 3125,3746 c 137,-63 241,-190 280,-341 87,-340 -214,-671 -562,-617 -246,39 -433,253 -433,496 0,133 45,245 139,348 147,160 378,206 576,114 z m 4821,16 c 174,-66 297,-220 326,-408 24,-155 -26,-298 -147,-420 -199,-201 -493,-207 -699,-13 -113,105 -161,227 -154,394 3,97 6,107 46,186 56,112 119,179 222,236 111,62 282,73 406,25 z M 2295,3618 c -27,-51 -61,-148 -70,-200 l -6,-38 h -254 -255 v 140 140 h 304 305 z m 4875,38 c 0,-2 -15,-36 -34,-75 -19,-39 -40,-100 -47,-136 l -13,-65 H 5344 3612 l -16,73 c -9,39 -31,102 -49,140 l -33,67 h 1828 c 1005,0 1828,-2 1828,-4 z m 2420,-136 v -140 h -560 -560 l -6,28 c -17,76 -39,142 -65,194 l -29,58 h 610 610 z"
        id="path12"
      />
      <path
        d="m 7183,5110 c -12,-10 -24,-29 -28,-41 -10,-32 3,-84 26,-105 20,-18 40,-19 269,-19 229,0 249,1 269,19 23,21 36,73 26,105 -4,12 -16,31 -28,41 -19,19 -36,20 -267,20 -231,0 -248,-1 -267,-20 z"
        id="path14"
      />
      <path
        d="m 2222,7415 c -56,-24 -68,-113 -22,-159 20,-21 27,-21 685,-21 h 665 l 26,26 c 47,48 26,143 -37,160 -13,4 -307,7 -654,8 -522,0 -636,-2 -663,-14 z"
        id="path16"
      />
      <path
        d="m 1687,6712 c -49,-30 -53,-116 -6,-154 20,-17 71,-18 749,-18 678,0 729,1 749,18 48,39 43,124 -8,155 -27,16 -83,17 -743,17 -657,-1 -715,-2 -741,-18 z"
        id="path18"
      />
      <path
        d="m 1045,6005 c -36,-35 -35,-102 1,-136 l 26,-24 h 948 948 l 26,24 c 36,34 37,101 1,136 l -24,25 h -951 -951 z"
        id="path20"
      />
      <path
        d="m 1690,5323 c -36,-24 -40,-33 -40,-84 0,-36 5,-50 26,-70 l 26,-24 h 728 728 l 25,23 c 47,44 40,124 -12,155 -27,16 -85,17 -743,17 -654,0 -716,-1 -738,-17 z"
        id="path22"
      />
      <path
        d="m 2199,4611 c -21,-22 -29,-39 -29,-66 0,-27 8,-44 29,-66 l 29,-29 h 652 c 689,0 697,0 714,45 8,21 8,79 0,100 -17,45 -25,45 -714,45 h -652 z"
        id="path24"
      />
    </g>
  </svg>
);

export default FoodIcon;
