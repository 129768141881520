import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Redirect, Route, Switch } from 'react-router-dom';
import AppAnalytics from './modules/appAnalytics/appAnalytics';
import Form from '../analytics/modules/form';
import Vote from '../analytics/modules/vote';
import Payment from '../analytics/modules/payment';
import Rating from '../analytics/modules/rating';
import PageHeader from '../../app/components/pageHeader';
import AccountsDropdown from '../editor/internals/accountsDropdown/';
import BeezerRadioButton from '../../app/components/radiobutton/RadioButton';
import { Trans, useTranslation } from 'react-i18next';

const Analytics = props => {
  const {
    plans,
    integrationType,
    appname,
    onUpdateGaTrackerId,
    match,
    location,
    plan,
    is14DayTrialUser,
    trialExpired,
    analyticSectionsAccess,
  } = props;
  const { t } = useTranslation();
  const [view, setView] = useState('week');

  return (
    <div className="flex-container sidebar-extended">
      <PageHeader
        title={t('Analytics')}
        userSettings={<AccountsDropdown />}
        // check if url ends in app
        preAction={
          location.pathname.split('/')[3] === 'app' && (
            <BeezerRadioButton
              id="timeToggle"
              className="preview-toggle"
              label1={t('Weekly')}
              label2={t('Monthly')}
              onChange={() => setView(view === 'week' ? 'month' : 'week')}
              checked={view !== 'week'}
            />
          )
        }
      />

      {analyticSectionsAccess && (
        <Switch>
          <Redirect
            exact
            from={`/${match.params.appname}/analytics`}
            to={`/${match.params.appname}/analytics/app`}
          />
          <Route
            path="/:appname/analytics/app"
            render={() => (
              <AppAnalytics
                plans={plans}
                integrationType={integrationType}
                appname={appname}
                onUpdateGaTrackerId={onUpdateGaTrackerId}
                view={view}
                plan={plan}
                is14DayTrialUser={is14DayTrialUser}
                trialExpired={trialExpired}
              />
            )}
          />
          <Route path="/:appname/analytics/form" component={Form} />
          <Route path="/:appname/analytics/vote" component={Vote} />
          <Route path="/:appname/analytics/rating" component={Rating} />
          <Route path="/:appname/analytics/payment" component={Payment} />
        </Switch>
      )}
    </div>
  );
};

Analytics.propTypes = {
  plans: PropTypes.object,
  integrationType: PropTypes.string,
  appname: PropTypes.string,
  onUpdateGaTrackerId: PropTypes.func,
  analyticSectionsAccess: PropTypes.bool.isRequired,
};

Analytics.defaultProps = {
  integrationType: '',
  plans: {},
  appname: '',
  onUpdateGaTrackerId: () => {},
};

export default Analytics;
