import { LOGIN_SUCCESS_INTEGRATION } from './action';

const integrationLogin = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS_INTEGRATION:
      return {
        ...state,
        action,
      };
    case 'LOGOUT_SUCCESS':
      return {};
    default:
      return state;
  }
};

export default integrationLogin;
