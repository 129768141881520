import firebase from '../../../../firebase';

export const resetOffer = () => ({
  type: 'RESET_PROMOTION_OFFER',
});

export const addThankYouNotification = (account, content) => async dispatch => {
  try {
    await firebase
      .database()
      .ref(`accounts/${account}`)
      .child('notices')
      .push({
        type: 'ThankYou',
        autoPopup: true,
        content,
      });

    dispatch({
      type: 'ADDED_THANK_YOU_NOTICE',
    });
  } catch (error) {
    dispatch({
      type: 'ERROR_ADDING_THANK_YOU_NOTICE',
      error,
    });
    console.error(error);
  }
};

export const addToAnalytics = (accountID, offerID) => async dispatch => {
  try {
    await firebase
      .database()
      .ref(`offers/${offerID}`)
      .child('redeemedBy')
      .push({
        account: accountID,
        timestamp: Date.now(),
      });

    dispatch({
      type: 'ADDED_OFFER_ANALYTICS',
    });
  } catch (error) {
    dispatch({
      type: 'ERROR_ADDING_OFFER_ANALYTICS',
      error,
    });
    console.error(error);
  }
};
