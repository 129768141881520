import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import WelcomeModal from './WelcomeModal';
import { toggleWelcomeModal } from './actions';

const mapStateToProps = (state, ownProps) => ({
  ...state.editor.customise.welcomeModal,
  domain: ownProps.match.params.appname,
  ...state.account.organisation.integration,
});

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(toggleWelcomeModal()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WelcomeModal),
);
