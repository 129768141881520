import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Checkbox from '@material-ui/core/Checkbox';
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';
import { Trans, withTranslation } from 'react-i18next';
import { downloadCSV, formatDate, objectToArray, arrayToObject } from 'utils';

import Table from '../../../../app/components/table';
import TableCheckbox from '../../components/tableCheckbox';
import TryAgain from '../../../../app/components/tryAgain';
import Header from '../../components/header';
import ActionMenu from '../../../../app/components/actionMenu';
import NewGroup from './newGroup';
import EditGroup from './editGroup';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from '@material-ui/core';
import { sortArray } from '../../../../utils/';
import { whitelabeledHost } from '../../../../utils/whitelabeledHost';
import themeStyles from '../../../../_export.scss';

const isWhiteLabelledHost = whitelabeledHost();

const isCheckboxDisabled = (error, pending) => error && !pending;

class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedItems: this.props.items,
      enableInfoDialog: false,
      enableOrderInfoDialog: false,
      dateSortArrowUp: true,
      groupNameSortArrowUp: null,
      usersSortArrowUp: null,
      orderSortArrowUp: null,
      groupsFilterSelected: 'none',
      subscriptionPlanFilterSelected: 'All',
    };
  }

  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    chunks: PropTypes.object,
    getGroups: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func.isRequired,
    onSelectAllGroups: PropTypes.func.isRequired,
    onDeselectAllGroups: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    onFilterGroupData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    filter: PropTypes.string,
    users: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    loadGroup: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
    chunks: {},
    filteredChunks: {},
    items: [],
    query: '',
    selected: [],
    selectAll: false,
  };

  componentDidMount() {
    this.sortByDate();
  }

  componentDidUpdate(prevProps) {
    let sortedItems = [];
    if (prevProps.filteredChunks !== this.props.filteredChunks) {
      if (this.state.dateSortArrowUp !== null) {
        sortedItems = sortArray(
          Object.entries(this.props.filteredChunks),
          'date',
          this.state.dateSortArrowUp,
        );
      }

      if (this.state.groupNameSortArrowUp !== null) {
        sortedItems = sortArray(
          Object.entries(this.props.filteredChunks),
          'name',
          this.state.groupNameSortArrowUp,
        );
      }
      if (this.state.usersSortArrowUp !== null) {
        sortedItems = sortArray(
          Object.entries(this.props.filteredChunks),
          'totalUsers',
          this.state.usersSortArrowUp,
        );
      }

      sortedItems = sortedItems.map(entity => entity[0]);
      this.setState({ sortedItems });
    }
  }

  handleGroupFilterChange = event => {
    this.setState(
      { groupsFilterSelected: event ? event.target.value : 'none' },
      () => this.sortChunks(),
    );
  };

  handleSubscriptionPlanSelected = event => {
    this.setState(
      { subscriptionPlanFilterSelected: event ? event.target.value : 'All' },
      () => {
        this.sortChunks();
      },
    );
  };

  sortChunks = () => {
    const { groupsFilterSelected, subscriptionPlanFilterSelected } = this.state;
    const sortedItems = [];
    if (
      groupsFilterSelected === 'none' &&
      subscriptionPlanFilterSelected === 'All'
    ) {
      const groupKeys = Object.keys(this.props.chunks);
      this.setState({ sortedItems: groupKeys });
      this.props.onFilterData(this.props.chunks, groupKeys);
      return;
    }

    const formatGroups = objectToArray(this.props.chunks);
    const filteredGroups = formatGroups.filter(item => {
      let group = item.id;
      let subscriptionPlan = true;
      if (subscriptionPlanFilterSelected !== 'All') {
        subscriptionPlan =
          subscriptionPlanFilterSelected === 'Yes'
            ? item.value.subscriptionPlan
            : !item.value.subscriptionPlan;
      }

      if (groupsFilterSelected !== 'none') {
        group = item.id === groupsFilterSelected;
      }

      return group && subscriptionPlan;
    });

    sortedItems.push(...Object.keys(arrayToObject(filteredGroups)));
    this.setState({ sortedItems });
    this.props.onFilterData(this.props.chunks, sortedItems);
  };

  sortByDate = () => {
    let filteredChunks = {};
    const sortedItems = [];
    let myList = Object.entries(this.props.filteredChunks);
    if (this.state.dateSortArrowUp) {
      myList = myList.sort((x, y) => x[1].date - y[1].date);
    } else {
      myList = myList.sort((x, y) => y[1].date - x[1].date);
    }
    myList.forEach(item => {
      filteredChunks = {
        ...filteredChunks,
        [item[0]]: {
          ...item[1],
        },
      };
      sortedItems.push(item[0]);
    });

    this.setState({
      sortedItems,
      dateSortArrowUp: !this.state.dateSortArrowUp,
      groupNameSortArrowUp: null,
      usersSortArrowUp: null,
      orderSortArrowUp: null,
    });

    this.props.onFilterData(filteredChunks, sortedItems);
  };

  sortByGroupName = () => {
    let filteredChunks = {};
    const sortedItems = [];
    let myList = Object.entries(this.props.filteredChunks);
    if (this.state.groupNameSortArrowUp) {
      myList = myList.sort(
        (x, y) => x[1].name.toLowerCase() - y[1].name.toLowerCase(),
      );
    } else {
      myList = myList.sort(
        (x, y) => y[1].name.toLowerCase() - x[1].name.toLowerCase(),
      );
    }
    myList.forEach(item => {
      filteredChunks = {
        ...filteredChunks,
        [item[0]]: {
          ...item[1],
        },
      };
      sortedItems.push(item[0]);
    });

    this.setState({
      sortedItems,
      groupNameSortArrowUp: !this.state.groupNameSortArrowUp,
      usersSortArrowUp: null,
      dateSortArrowUp: null,
      orderSortArrowUp: null,
    });

    this.props.onFilterData(filteredChunks, sortedItems);
  };

  sortByUsers = () => {
    let filteredChunks = {};
    const sortedItems = [];
    let myList = Object.entries(this.props.filteredChunks);
    if (this.state.usersSortArrowUp) {
      myList = myList.sort((x, y) => x[1].totalUsers - y[1].totalUsers);
    } else {
      myList = myList.sort((x, y) => y[1].totalUsers - x[1].totalUsers);
    }
    myList.forEach(item => {
      filteredChunks = {
        ...filteredChunks,
        [item[0]]: {
          ...item[1],
        },
      };
      sortedItems.push(item[0]);
    });

    this.setState({
      sortedItems,
      usersSortArrowUp: !this.state.usersSortArrowUp,
      groupNameSortArrowUp: null,
      dateSortArrowUp: null,
      orderSortArrowUp: null,
    });

    this.props.onFilterData(filteredChunks, sortedItems);
  };

  sortByOrder = () => {
    let filteredChunks = {};
    const sortedItems = [];
    let myList = Object.entries(this.props.filteredChunks);
    if (this.state.orderSortArrowUp) {
      myList = myList.sort((x, y) => x[1].order - y[1].order);
    } else {
      myList = myList.sort((x, y) => y[1].order - x[1].order);
    }
    myList.forEach(item => {
      filteredChunks = {
        ...filteredChunks,
        [item[0]]: {
          ...item[1],
        },
      };
      sortedItems.push(item[0]);
    });

    this.setState({
      sortedItems,
      orderSortArrowUp: !this.state.orderSortArrowUp,
      usersSortArrowUp: null,
      groupNameSortArrowUp: null,
      dateSortArrowUp: null,
    });

    this.props.onFilterData(filteredChunks, sortedItems);
  };

  toggleInfoDialog = () => {
    this.setState({ enableInfoDialog: !this.state.enableInfoDialog });
  };

  getTitle = (selected, groups) => (
    <span
      style={{
        fontFamily: 'Raleway, sans-serif',
        fontWeight: 600,
        fontSize: 20,
      }}
    >
      <Trans>Groups & Subscriptions</Trans>:{' '}
      {selected.length
        ? `${selected.length} / ${Object.keys(groups).length}`
        : Object.keys(groups).length}
      <IconButton
        color="primary"
        aria-label={this.props.t('information')}
        component="span"
        onClick={this.toggleInfoDialog}
      >
        <InfoOutlinedIcon />
      </IconButton>
    </span>
  );

  getCsvData = (selected, groups) => {
    const { t } = this.props;
    const csvColumns = [
      t('Group Name'),
      t('Users'),
      t('Date Created'),
      t('Subscripition Plan'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selected.forEach(key => {
      data += `${groups[key].name},${groups[key].totalUsers},${formatDate(
        groups[key].date,
      )},${!!groups[key].subscriptionPlan}\n`;
    });

    return data;
  };

  toggleOrderInfoDialog = () => {
    this.setState({ enableOrderInfoDialog: !this.state.enableOrderInfoDialog });
  };

  getSelectAllCheckBox = () => {
    const {
      onSelectAllGroups,
      onDeselectAllGroups,
      error,
      pending,
      selected,
      onDeleteSelected,
      chunks,
      selectAll,
      t,
    } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllGroups() : onDeselectAllGroups()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          onDownloadSelected={() => {
            downloadCSV(this.getCsvData(selected, chunks), 'groups');
          }}
          text={t('groups')}
        />
      </div>
    );
  };

  render() {
    const {
      pending,
      items,
      error,
      getGroups,
      chunks,
      onSelect,
      filteredChunks,
      onFilterGroupData,
      onSelectAllGroups,
      onDeselectAllGroups,
      selected,
      onDeleteSelected,
      history,
      match,
      users,
      t,
    } = this.props;

    let body;

    if (error) {
      body = (
        <TryAgain
          message={t('Oops, something went wrong...')}
          onRetry={getGroups}
        />
      );
    } else {
      const allUsers = objectToArray(users);
      const groupData = filteredChunks;
      Object.keys(groupData).map(item => {
        const groupUsers = allUsers.filter(value =>
          value.value.groups
            ? Object.keys(value.value.groups).includes(item)
            : false,
        );
        groupData[item].totalUsers = groupUsers.length;
      });
      const subscriptionGroups = Object.values(groupData).filter(
        item => item.order,
      );

      body = (
        <Table
          items={this.state.sortedItems}
          chunks={groupData}
          loading={pending}
          onRowTouchTap={groupId => {
            this.props.loadGroup(match.params.appname, groupId);
            history.push(`/${match.params.appname}/members/groups/${groupId}`);
          }}
          cols={[
            {
              id: 'name',
              header: (
                <>
                  <Trans>Group Name</Trans>{' '}
                  {this.state.groupNameSortArrowUp ? (
                    <ArrowDropUpIcon
                      onClick={() => this.sortByGroupName()}
                      className="arrowUpDown"
                      style={{
                        position: 'absolute',
                        top: -5,
                        color:
                          this.state.groupNameSortArrowUp !== null && '#261ed5',
                      }}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      onClick={() => this.sortByGroupName()}
                      className="arrowUpDown"
                      style={{
                        position: 'absolute',
                        top: -5,
                        color:
                          this.state.groupNameSortArrowUp !== null && '#261ed5',
                      }}
                    />
                  )}
                </>
              ),
              classNames: 'col-xs-2',
            },
            {
              id: 'totalUsers',
              header: (
                <>
                  <Trans>Users</Trans>{' '}
                  {this.state.usersSortArrowUp ? (
                    <ArrowDropUpIcon
                      onClick={() => this.sortByUsers()}
                      className="arrowUpDown"
                      style={{
                        position: 'absolute',
                        top: -5,
                        color:
                          this.state.usersSortArrowUp !== null && '#261ed5',
                      }}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      onClick={() => this.sortByUsers()}
                      className="arrowUpDown"
                      style={{
                        position: 'absolute',
                        top: -5,
                        color:
                          this.state.usersSortArrowUp !== null && '#261ed5',
                      }}
                    />
                  )}
                </>
              ),
              classNames: 'col-xs-2',
              containerElement: ({ value }) => <span>{value}</span>,
            },
            {
              id: 'date',
              header: (
                <>
                  <Trans>Date Created</Trans>{' '}
                  {this.state.dateSortArrowUp ? (
                    <ArrowDropUpIcon
                      onClick={() => this.sortByDate()}
                      className="arrowUpDown"
                      style={{
                        position: 'absolute',
                        top: -5,
                        color: this.state.dateSortArrowUp !== null && '#261ed5',
                      }}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      onClick={() => this.sortByDate()}
                      className="arrowUpDown"
                      style={{
                        position: 'absolute',
                        top: -5,
                        color: this.state.dateSortArrowUp !== null && '#261ed5',
                      }}
                    />
                  )}
                </>
              ),
              classNames: 'col-xs-2',
              containerElement: ({ value }) => (
                <span>{value && moment(value).format('DD/MM/YYYY')}</span>
              ),
            },
            {
              id: 'subscriptionPlan',
              header: t('Subscription Plan'),
              classNames:
                subscriptionGroups.length > 0 ? 'col-xs-3' : 'col-xs-5',

              containerElement: ({ value }) => (
                <div>{value ? <Done /> : <Clear />}</div>
              ),
            },
            {
              ...(subscriptionGroups.length > 0
                ? {
                    id: 'order',
                    header: t('Order'),
                    classNames: 'col-xs-2',
                    containerElement: ({ value }) => (
                      <div>{value || 'N/A'}</div>
                    ),
                    tooltip: true,
                    tooltipAction: () => this.toggleOrderInfoDialog(),
                  }
                : {}),
            },
            {
              id: 'selected',
              header: this.getSelectAllCheckBox(),
              classNames: `${'col-xs-1'} ${
                items.length < 4 ? 'fix-left' : 'selected'
              }`,
              containerElement: ({ value, rowId }) => (
                <>
                  <TableCheckbox
                    value={value}
                    onSelect={() => onSelect(rowId)}
                  />
                  <ActionMenu
                    selected={[rowId]}
                    onDeleteSelected={() => onDeleteSelected([rowId], chunks)}
                    onDownloadSelected={() => {
                      downloadCSV(this.getCsvData([rowId], chunks), 'groups');
                    }}
                    onEditSelected={() =>
                      history.push(
                        `/${match.params.appname}/members/groups/${rowId}`,
                      )
                    }
                    text={t('group')}
                  />
                </>
              ),
            },
          ]}
        />
      );
    }

    const infoDialog = (
      <Dialog
        open={this.state.enableInfoDialog}
        onClose={this.toggleInfoDialog}
      >
        <DialogTitle>
          <Trans>How to use group and subscriptions</Trans>
        </DialogTitle>
        <DialogContent>
          <p>
            <u>
              <Trans>Groups:</Trans>
            </u>
          </p>
          <p>
            <Trans>
              The Groups & Subscriptions section allows you to add a new Group
              or a new Subscription plan by clicking on the '+' button at the
              top right corner.
            </Trans>{' '}
          </p>
          <p>
            <Trans>
              When you add a new Group, you can select what pages in the app
              will be visible to the particular Group.
            </Trans>
          </p>
          <p>
            <Trans>
              You can then visit the Users section to assign your app users to a
              particular Group or Enable 'Sign Up As A Group Feature' in
              settings.
            </Trans>
          </p>
          <p>
            <Trans>
              Once assigned, the users will be able to see the content of pages
              which are visible to their Group.
            </Trans>
          </p>
          <p>
            <u>
              <Trans>Subscriptions:</Trans>
            </u>
          </p>
          <p>
            <Trans>
              When you add a new Subscription plan, you can select the settings
              of the subscription (cost, recurrence period, etc) and what pages
              in the app will be available within the particular Subscription
              plan.
            </Trans>
          </p>
          <p>
            <Trans>
              You can then visit the Settings section → Restricted Access page
              to choose the layout of the page on which your Subscription plans
              will be displayed. Upon signing up on the app, your users will be
              able to see the Restricted Access page and choose a subscription
              plan to subscribe to.
            </Trans>
          </p>{' '}
          <p>
            <Trans>
              You can see the subscribed users in the sections 'Users &
              Subscribers', and the subscription history in the section
              'Transaction History'.
            </Trans>
          </p>
        </DialogContent>
        {!this.props.isAppAdmin && !isWhiteLabelledHost && (
          <DialogActions>
            <Button
              href="https://beezer.zendesk.com/hc/en-us/articles/360021107392-Member-Login"
              target="_blank"
              rel="noopener"
            >
              <Trans>Know More</Trans>
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );

    const orderInfoDialog = (
      <Dialog
        open={this.state.enableOrderInfoDialog}
        onClose={this.toggleOrderInfoDialog}
      >
        <DialogTitle>
          <Trans>Order</Trans>
        </DialogTitle>
        <DialogContent>
          <p>
            <Trans>
              The Order in which your Subscription plans are displayed in the
              app, e.g. The Subscription plan with Order value '1' is displayed
              first on the Subscriptions page in the app.
            </Trans>
          </p>
        </DialogContent>
      </Dialog>
    );
    return (
      <section style={{ height: 'calc(100% - 168px)' }}>
        <Header
          {...this.props}
          title={this.getTitle(selected, filteredChunks)}
          onChange={onFilterGroupData}
          filteredColumns={['groups', 'subscriptionPlan']}
          type="group"
          groups={chunks}
          groupsFilterSelected={this.state.groupsFilterSelected}
          handleGroupFilterChange={this.handleGroupFilterChange}
          subscriptionPlanFilterSelected={
            this.state.subscriptionPlanFilterSelected
          }
          handleSubscriptionPlanSelected={this.handleSubscriptionPlanSelected}
        />
        {body}
        {infoDialog}
        {orderInfoDialog}
        <NewGroup />
        <EditGroup />
      </section>
    );
  }
}

export default withTranslation()(Groups);
