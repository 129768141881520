import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import OldCheckbox from 'material-ui/Checkbox';
import Checkbox from '@material-ui/core/Checkbox';
import { Trans, withTranslation } from 'react-i18next';

import ResultsTable from '../../../../app/components/resultsTable';
import Header from '../../../../app/components/header';
import ActionMenu from '../../../../app/components/actionMenu';
import NotificationPanel from '../../../../app/components/notificationPanel';
import { CTAButton } from '../../../../app/components/buttons';

import NewContactDialog from './newContact';
import EditContactDialog from './editContact';
import UpgradePlan from '../../../../app/modules/upgradePlan';
import {
  USER_CLICKED_BUY_SMS,
  sendSlackNotification,
  PLATFORM_EVENTS,
  formatDate,
  downloadCSV,
} from '../../../../utils';
import './contact.css';
import { HS_FEATURES_ENABLED_APPS } from '../../../../config';
import themeStyles from '../../../../_export.scss';

const isCheckboxDisabled = (error, pending) => error && !pending;

class Contacts extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    query: PropTypes.string,
    chunks: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    smsCredit: PropTypes.number,
    integration: PropTypes.shape({
      type: PropTypes.oneOf(['Wix', 'Weebly']),
    }),
    subscription: PropTypes.string,
    loadContacts: PropTypes.func.isRequired,
    loadGroups: PropTypes.func.isRequired,
    getGroups: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSelectAllContacts: PropTypes.func.isRequired,
    onDeselectAllContacts: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    onEditContact: PropTypes.func.isRequired,
    openDistributeDialog: PropTypes.func.isRequired,
    setType: PropTypes.func.isRequired,
    pageType: PropTypes.string,
    resetSelection: PropTypes.func.isRequired,
    contactsSelectAll: PropTypes.bool,
    groupsSelectAll: PropTypes.bool,
    organisation: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    customDomain: PropTypes.object,
    groupDataPending: PropTypes.bool,
    groupChunks: PropTypes.object,
    groupFilteredChunks: PropTypes.object,
    groupError: PropTypes.string,
    groupItems: PropTypes.arrayOf(PropTypes.string),
    groupSelected: PropTypes.array,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    chunks: {},
    customDomain: {},
    integration: {
      type: '',
    },
    items: [],
    original: [],
    pageType: '',
    selectAll: false,
    selected: [],
    smsCredit: 0,
    subscription: null,
    query: '',
  };

  componentDidMount() {
    const {
      loadContacts,
      setType,
      loadGroups,
      getGroups,
      getWorkforceGroups,
      getUsers,
    } = this.props;
    getGroups();
    getUsers();
    loadContacts();
    loadGroups();
    if (this.isHorseScotlandApp()) {
      getWorkforceGroups();
    }

    setType(this.getTypePage());
  }

  // NOTE: This update handles resetting the contact selection State
  // on page change, i.e from share_sms to share_email
  componentDidUpdate() {
    const { pageType, resetSelection, setType } = this.props;
    if (pageType !== this.getTypePage()) {
      resetSelection();
      setType(this.getTypePage());
    }
  }

  componentWillUnmount() {
    this.props.resetSelection();
  }

  isHorseScotlandApp = () =>
    HS_FEATURES_ENABLED_APPS.indexOf(this.props.match.params.appname) > -1;

  onButtonClick = ({ notificationTitle, nextPage }) => {
    const { match, subscription, organisation, history } = this.props;

    sendSlackNotification({
      appname: match.params.appname,
      notificationTitle,
      account: organisation.key,
      currentPlan: subscription,
      slackChannel: PLATFORM_EVENTS,
    });
    history.push(nextPage);
  };

  getTypePage = () => {
    const path = this.props.location.pathname
      .replace(/(.*?\/|)distribute(\/|$)/, '')
      .match(/(.*?)(?=\/|$)/)[0];

    switch (path) {
      case 'share_email':
        return 'Email';

      case 'share_sms':
        return 'SMS';

      case 'share_whatsapp':
        return 'Whatsapp';

      default:
        return 'contacts';
    }
  };

  getSelectAllUsersCheckBox = () => {
    const {
      error,
      pending,
      selected,
      chunks,
      selectAll,
      onSelectAllContacts,
      onDeselectAllContacts,
      onDeleteSelected,
      t,
    } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllContacts() : onDeselectAllContacts()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={selected}
          onDeleteSelected={() => onDeleteSelected(selected, chunks)}
          onDownloadSelected={() => {
            downloadCSV(this.getUsersCsvData(selected, chunks), 'users');
          }}
          text={t('users')}
        />
      </div>
    );
  };

  getSelectAllGroupsCheckBox = () => {
    const {
      error,
      pending,
      groupSelected,
      groupFilteredChunks,
      groupSelectAll,
      onSelectAllGroups,
      onDeselectAllGroups,
      t,
    } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={groupSelectAll}
          onChange={(e, isChecked) =>
            isChecked ? onSelectAllGroups() : onDeselectAllGroups()
          }
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        <ActionMenu
          selected={groupSelected}
          onDownloadSelected={() => {
            downloadCSV(
              this.getGroupsCsvData(groupSelected, groupFilteredChunks),
              'groups',
            );
          }}
          text={t('groups')}
        />
      </div>
    );
  };

  getUsersCsvData = (selected, contacts) => {
    const { t } = this.props;
    const csvColumns = [
      t('Name'),
      t('Email'),
      t('Telephone'),
      t('Created'),
      t('Country'),
      t('Country Code'),
      t('Dial Code'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selected.forEach(key => {
      data += `${contacts[key].name},${contacts[key].email},${
        contacts[key].country ? contacts[key].country.dialCode : '44'
      }${contacts[key].mobile},${formatDate(contacts[key].date)},${
        contacts[key].country.name
      },${contacts[key].country.code},${contacts[key].country.dialCode}\n`;
    });

    return data;
  };

  getUsersCsvData = (selected, contacts) => {
    const { t } = this.props;
    const csvColumns = [
      t('Name'),
      t('Email'),
      t('Telephone'),
      t('Created'),
      t('Country'),
      t('Country Code'),
      t('Dial Code'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selected.map(key => {
      if (contacts[key]) {
        data += `${contacts[key].name ? contacts[key].name : ''},${
          contacts[key].email ? contacts[key].email : ''
        },${
          contacts[key].country
            ? contacts[key].country.dialCode
            : contacts[key].mobile
            ? '44'
            : ''
        }${contacts[key].mobile ? contacts[key].mobile : ''},${formatDate(
          contacts[key].date,
        )},${contacts[key].country ? contacts[key].country.name : ''},${
          contacts[key].country ? contacts[key].country.code : ''
        },${contacts[key].country ? contacts[key].country.dialCode : ''}\n`;
      }
    });

    return data;
  };

  getGroupsCsvData = (selected, groups) => {
    const { t } = this.props;
    const csvColumns = [
      t('Group Name'),
      t('Users'),
      t('Date Created'),
      t('Last Updated'),
      t('Is Subscription Plan'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selected.forEach(key => {
      data += `${groups[key].name},${
        groups[key].users ? Object.keys(groups[key].users).length : 0
      },${formatDate(groups[key].date)},${formatDate(
        groups[key].last_updated,
      )},${groups[key].subscriptionPlan ? true : false}\n`;
    });

    return data;
  };

  getColumns = () => {
    const { chunks, onDeleteSelected, onEditContact, items, t } = this.props;
    const suffixClass = items.length < 3 ? 'fix-left' : 'selected';

    return [
      {
        id: 'name',
        header: t('Name'),
        classNames: 'col-xs-2',
      },
      {
        id: 'email',
        header: t('Email'),
        classNames: 'col-xs-2',
      },
      {
        id: 'country',
        header: t('Country'),
        classNames: 'col-xs-3',
      },
      {
        id: 'mobile',
        header: t('Mobile'),
        classNames: 'col-xs-2',
      },
      {
        id: 'created',
        header: t('Date Created'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) => (
          <span>{value && formatDate(value, 'DD/MM/YYYY')}</span>
        ),
      },
      {
        id: 'selected',
        header: this.getSelectAllUsersCheckBox(),
        classNames: `col-xs-1 ${suffixClass}`,
        containerElement: ({ value, rowId }) => (
          <>
            <OldCheckbox
              checked={value}
              onClick={e => {
                e.stopPropagation();
              }}
              onCheck={e => {
                e.stopPropagation();

                this.props.onSelect(rowId);
              }}
            />
            <ActionMenu
              selected={[rowId]}
              onEditSelected={() => onEditContact(rowId)}
              onDownloadSelected={() => {
                downloadCSV(this.getUsersCsvData([rowId], chunks), 'contacts');
              }}
              onDeleteSelected={() => onDeleteSelected([rowId])}
              text={t('contact')}
            />
          </>
        ),
      },
    ];
  };

  getGroupColumns = () => {
    const { groupFilteredChunks, t } = this.props;
    const groupSuffixClass =
      Object.keys(groupFilteredChunks).length < 3 ? 'fix-left' : 'selected';
    return [
      {
        id: 'name',
        header: t('Name'),
        classNames: 'col-xs-4',
      },
      {
        id: 'users',
        header: t('Users'),
        classNames: 'col-xs-1',
      },
      {
        id: 'created',
        header: t('Date Created'),
        classNames: 'col-xs-3',
        containerElement: ({ value }) => (
          <span>{value && formatDate(value, 'DD/MM/YYYY')}</span>
        ),
      },
      {
        id: 'lastUpdated',
        header: t('Last Updated'),
        classNames: 'col-xs-3',
        containerElement: ({ value }) => (
          <span>{value && formatDate(value, 'DD/MM/YYYY')}</span>
        ),
      },

      {
        id: 'selected',
        header: this.getSelectAllGroupsCheckBox(),
        classNames: `col-xs-1 ${groupSuffixClass}`,
        containerElement: ({ value, rowId }) => (
          <>
            <OldCheckbox
              checked={value}
              onClick={e => {
                e.stopPropagation();
              }}
              onCheck={e => {
                e.stopPropagation();
                this.props.onGroupSelect(rowId);
              }}
            />
            <ActionMenu
              selected={[rowId]}
              onDownloadSelected={() => {
                downloadCSV(
                  this.getGroupsCsvData([rowId], groupFilteredChunks),
                  'groups',
                );
              }}
              text={t('group')}
            />
          </>
        ),
      },
    ];
  };

  getWorkforceGroupColumns = () => {
    const { t } = this.props;
    return [
      {
        id: 'name',
        header: t('Name'),
        classNames: 'col-xs-4',
      },
      {
        id: 'users',
        header: t('Users'),
        classNames: 'col-xs-1',
      },
      {
        id: 'created',
        header: t('Date Created'),
        classNames: 'col-xs-3',
        containerElement: ({ value }) => (
          <span>{value && formatDate(value, 'DD/MM/YYYY')}</span>
        ),
      },
      {
        id: 'lastUpdated',
        header: t('Last Updated'),
        classNames: 'col-xs-3',
        containerElement: ({ value }) => (
          <span>{value && formatDate(value, 'DD/MM/YYYY')}</span>
        ),
      },

      {
        id: 'selected',
        header: '',
        classNames: 'col-xs-1',
        containerElement: ({ value, rowId }) => (
          <Checkbox
            checked={value}
            onClick={e => {
              e.stopPropagation();
            }}
            onCheck={e => {
              e.stopPropagation();
              this.props.onWorkforceGroupSelect(rowId);
            }}
          />
        ),
      },
    ];
  };

  getActionMenu = () => {
    const { selected, onDeleteSelected, chunks, t } = this.props;
    return (
      <ActionMenu
        selected={selected}
        onDownloadSelected={() => {
          downloadCSV(this.getUsersCsvData(selected, chunks), 'contacts');
        }}
        onDeleteSelected={() => onDeleteSelected(selected)}
        text={t('contacts')}
      />
    );
  };

  shouldShowUpgradeMessage = type => {
    const { t } = this.props;
    return (
      (type === 'SMS' && !this.props.subscription && (
        <span>
          <Trans>Upgrade your subscription to the</Trans>
          <Link
            className="dark-text decorate"
            to={`/${this.props.match.params.appname}/choose_plan?plan=deluxe`}
          >
            {' '}
            {this.props.integration ? 'premium' : 'everything'}{' '}
            <Trans>plan</Trans>{' '}
          </Link>
          <Trans>to receive</Trans>{' '}
          <strong>
            {' '}
            <Trans>40 SMS</Trans>{' '}
          </strong>
        </span>
      )) || <span />
    );
  };

  defineSubscriptionTypeOfAction = type => {
    const {
      location,
      openDistributeDialog,
      selected,
      subscription,
      groupSelected,
      workforceGroupsSelected,
      t,
    } = this.props;

    const primary = (
      <CTAButton
        label={t('Share')}
        height={36}
        minWidth={90}
        disabled={
          this.isHorseScotlandApp()
            ? selected.length === 0 &&
              groupSelected.length === 0 &&
              workforceGroupsSelected.length === 0
            : selected.length === 0 && groupSelected.length === 0
        }
        onClick={openDistributeDialog}
      />
    );
    const secondaryButtonLabel =
      (type === 'SMS' && t('Buy SMS')) ||
      (type === 'Whatsapp' && t('Buy Credits'));
    const secondaryButtonPath =
      (type === 'SMS' && '/choose_sms_bundle') ||
      (type === 'Whatsapp' && '/choose_whatsapp_credits_bundle');
    const secondary = !this.props.isAppAdmin ? (
      subscription ? (
        <CTAButton
          label={secondaryButtonLabel}
          height={36}
          minWidth={90}
          onClick={() => {
            const nextPage = {
              pathname: secondaryButtonPath,
              state: { origin: location.pathname },
            };
            this.onButtonClick({
              notificationTitle: USER_CLICKED_BUY_SMS,
              nextPage,
            });
          }}
        />
      ) : (
        <UpgradePlan />
      )
    ) : (
      <></>
    );

    //display buttons for sms and whatsapp
    if (type === 'SMS' || type === 'Whatsapp') {
      return {
        primary,
        secondary,
      };
    }

    return { primary };
  };

  notificationPanel = type => {
    const {
      selected,
      groupSelected,
      workforceGroupsSelected,
      smsCredit,
      whatsappSMSCredit,
      t,
    } = this.props;
    const state =
      selected.length === 0 ||
      groupSelected.length === 0 ||
      workforceGroupsSelected.length === 0 ||
      ((type === 'SMS' || type === 'Whatsapp') &&
        (smsCredit < selected.length || whatsappSMSCredit < selected.length))
        ? 'secondary'
        : 'primary';
    const selectedContacts = this.isHorseScotlandApp()
      ? selected.length + groupSelected.length + workforceGroupsSelected.length
      : selected.length + groupSelected.length;

    return (
      type !== 'contacts' && (
        <NotificationPanel
          title={t('contacts selected')}
          selectionKey={
            (type === 'SMS' && `${type} ${t('left')}`) ||
            (type === 'Whatsapp' && `${t('credits left')}`) ||
            ''
          }
          selected={selectedContacts}
          credit={
            type === 'SMS'
              ? smsCredit
              : type === 'Whatsapp'
              ? whatsappSMSCredit
              : ''
          }
          state={state}
          message={this.shouldShowUpgradeMessage(type)}
          actions={this.defineSubscriptionTypeOfAction(type)}
          accountId={this.props.accountId}
          sms_restricted={this.props.sms_restricted}
        />
      )
    );
  };

  render() {
    const {
      chunks,
      items,
      onEditContact,
      onSearch,
      pending,
      selectAll,
      query,
      onSelectAllContacts,
      onDeselectAllContacts,
      workforceGroupsDataPending,
      workforceGroupsFilteredChunks,
      workforceGroupsItems,
      groupDataPending,
      groupFilteredChunks,
      groupItems,
      t,
    } = this.props;
    const type = this.getTypePage();
    const tableData = {};
    const groupTableData = {};
    const workforceGroupsTableData = {};

    if (chunks && Object.keys(chunks).length > 0) {
      Object.entries(chunks).forEach(chunk => {
        if (items.indexOf(chunk[0]) > -1) {
          tableData[chunk[0]] = {
            ...chunk[1],
            mobile: `${
              chunk[1].country
                ? chunk[1].country.dialCode
                : chunk[1].mobile
                ? '44'
                : ''
            } ${chunk[1].mobile || ''}`,
            country: chunk[1].country && chunk[1].country.name,
          };
        }
      });
    }

    if (groupFilteredChunks && Object.keys(groupFilteredChunks).length > 0) {
      Object.entries(groupFilteredChunks).forEach(chunk => {
        if (groupItems.indexOf(chunk[0]) > -1) {
          groupTableData[chunk[0]] = {
            ...chunk[1],
            name: `${chunk[1].name ? chunk[1].name : t('Unknown')}`,
            lastUpdated: chunk[1].last_updated && chunk[1].last_updated,
            created: chunk[1].date && chunk[1].date,
            users: chunk[1].users ? Object.keys(chunk[1].users).length : 0,
          };
        }
      });
    }

    if (
      this.isHorseScotlandApp() &&
      workforceGroupsFilteredChunks &&
      Object.keys(workforceGroupsFilteredChunks).length > 0
    ) {
      Object.entries(workforceGroupsFilteredChunks).forEach(chunk => {
        if (workforceGroupsItems.indexOf(chunk[0]) > -1) {
          workforceGroupsTableData[chunk[0]] = {
            ...chunk[1],
            name: `${chunk[1].name ? chunk[1].name : t('Unknown')}`,
            lastUpdated: chunk[1].updatedAt && chunk[1].updatedAt,
            created: chunk[1].createdAt && chunk[1].createdAt,
            users: chunk[1].users ? Object.keys(chunk[1].users).length : 0,
          };
        }
      });
    }

    return (
      <div className="contact-main-container scroll-container">
        <div className="contact-header">
          <Header
            selectAll={selectAll}
            onSelectAllResponses={onSelectAllContacts}
            onDeselectAllResponses={onDeselectAllContacts}
            filter={query}
            title={
              <span>
                <Trans>Total Contacts</Trans>: <b>{items.length}</b>
              </span>
            }
            onChange={onSearch}
            actionMenu={this.getActionMenu()}
            pending={pending}
            disabled={!items.length}
          />
        </div>
        <div className="contact-container">
          <ResultsTable
            items={items}
            chunks={tableData}
            loading={pending}
            onRowTouchTap={onEditContact}
            columns={this.getColumns()}
            pending={pending}
            error={
              items.length === 0 ? t('There are no contacts to display') : null
            }
            emptyMessage={t("Looks like you've not created any contacts yet")}
          />

          <NewContactDialog />
          <EditContactDialog />
        </div>
        {this.isHorseScotlandApp() && (
          <div className="group-container">
            <h3 className="subheader">
              <span>
                <Trans>Total Workforce Groups:</Trans>{' '}
                <b>{workforceGroupsItems.length || '0'}</b>
              </span>
            </h3>

            <ResultsTable
              items={workforceGroupsItems}
              chunks={workforceGroupsTableData}
              loading={workforceGroupsDataPending}
              columns={this.getWorkforceGroupColumns()}
              pending={workforceGroupsDataPending}
              error={
                workforceGroupsItems.length === 0
                  ? t('There are no contacts to display')
                  : null
              }
              emptyMessage={t("Looks like you've not created any groups yet")}
            />
          </div>
        )}
        <div className="group-container">
          <h3 className="subheader">
            <span>
              <Trans>Total Groups</Trans>: <b>{groupItems.length || '0'}</b>
            </span>
          </h3>

          <ResultsTable
            items={groupItems}
            chunks={groupTableData}
            loading={groupDataPending}
            columns={this.getGroupColumns()}
            pending={groupDataPending}
            error={
              groupItems.length === 0
                ? t('There are no contacts to display')
                : null
            }
            emptyMessage={t("Looks like you've not created any groups yet")}
          />
        </div>
        <div className="share-container">
          {(!pending || !groupDataPending) && this.notificationPanel(type)}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Contacts);
