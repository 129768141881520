import { combineReducers } from 'redux';

import editionData from './editionData';
import alwaysData from './alwaysData';
import layoutData from './layoutData';
import lazyData from './lazyData';
import metaData from './metaData';
import loaded from './loaded';
import iconData from './iconData';
import previusAppDomain from './previusAppDomain';
import isPublished from './isPublished';
import toolbar from './toolbar';
import paypalData from './paypalData';

/* state.data */
export default combineReducers({
  previusAppDomain,
  editionData,
  alwaysData,
  layoutData,
  lazyData,
  metaData,
  iconData,
  loaded,
  isPublished,
  toolbar,
  paypalData,
});
