import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import New from './New';

import {
  addEvent,
  closeNewEventDialog,
  formFieldChange,
  setRecurringData,
} from './actions';

const mapStateToProps = state => ({
  ...state.staff.events.create,
  fields: state.staff.events.list.fields,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(addEvent(ownProps.match.params.appname)),
  setRecurringData: (id, value) => dispatch(setRecurringData(id, value)),
  onRequestClose: () => dispatch(closeNewEventDialog()),
  onFieldChange: (isRequired, type, field, value, index) =>
    dispatch(formFieldChange(isRequired, type, field, value, index)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(New));
