import moment from 'moment';

export const formatDate = (ts, format = 'DD/MM/YY HH:mm') =>
  moment(ts).format(format);

export const formatDateNoTime = (ts, format = 'DD/MM/YY') =>
  moment(ts).format(format);

export const isValidDate = date => {
  if (date.length < 10) {
    return false;
  }

  const formattedDate = new Date(date);
  return (
    formattedDate &&
    Object.prototype.toString.call(formattedDate) === '[object Date]' &&
    !isNaN(formattedDate)
  );
};
