import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { Trans, withTranslation } from 'react-i18next';

import Container from '../../../../../../../app/components/container';
import TextField from '../../../../../../../app/components/textField';
import SectionHeader from '../../../../../components/sectionHeader';

class EventFilterItem extends Component {
  static propTypes = {
    component: PropTypes.object.isRequired,
    data: PropTypes.object,
    match: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: {
      options: '',
      name: '',
      key: '',
    },
  };

  getAvatarUrl = key => {
    const { match, component } = this.props;

    return `apps/${match.params.appname}/draft/lazy_data/${component.lazy_data}/options/chunks/${key}/avatar`;
  };

  render() {
    const { data, onChange, t } = this.props;

    return (
      <Container>
        <SectionHeader title={t('Filter')} />
        <SelectField
          value={data.type}
          onChange={(e, i, val) => onChange('type', val)}
        >
          {[
            { title: t('Select Drop Down'), type: 'select' },
            { title: t('Text Field'), type: 'text' },
          ].map(opt => (
            <MenuItem
              key={opt.title}
              value={opt.type}
              primaryText={opt.title}
            />
          ))}
        </SelectField>
        {data.type === 'select' && (
          <div style={{ padding: '0 18px' }}>
            <TextField
              id="filter-options"
              placeholder={t('Options')}
              value={data.options}
              onChange={value => onChange('options', value)}
            />
            <p>
              <Trans>
                Options must be separated by commas (e.g. option1, option2,
                option3)
              </Trans>
            </p>
          </div>
        )}
        <TextField
          id="filter-key"
          placeholder={t('Key')}
          value={data.key}
          onChange={value => onChange('key', value)}
        />
      </Container>
    );
  }
}

export default withTranslation()(EventFilterItem);
