const initialState = {
  open: false,
  pending: false,
  files: [],
  currentCropIndex: 0,
  error: null,
  warning: null,
  deleteDialog: false,
  deleteRequestPending: false,
  fileAndUrlDialogOpen: false,
  fileUploadUrl: '',
};

const isEmpty = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

const multipleImageUpload = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_IMAGES_UPLOAD_DIALOG':
      return {
        ...state,
        open: true,
      };
    case 'CLOSE_IMAGES_UPLOAD_DIALOG': {
      return {
        ...state,
        open: false,
      };
    }
    case 'RECEIVED_IMAGES': {
      const newFile = {
        cropDetails: { width: 100, height: 100, x: 0, y: 0 },
        file: action.file,
      };
      return {
        ...state,
        files: [...state.files, newFile],
      };
    }
    case 'SELECT_IMAGE_INDEX':
      return {
        ...state,
        currentCropIndex: action.index,
      };
    case 'UPDATE_CROP_DIMENSIONS':
      const files = [...state.files];
      files[action.index] = {
        ...files[action.index],
        cropDetails: { ...action.crop },
      };
      return {
        ...state,
        files,
      };
    case 'IMAGES_UPLOAD_FAILURE':
      return {
        ...state,
        warning: null,
        error: action.message,
        pending: false,
      };
    case 'CLEAR_IMAGES_UPLOAD_ERROR':
      return {
        ...state,
        error: null,
      };
    case 'RESET_IMAGES_UPLOAD':
      return {
        ...state,
        files: [],
        currentCropIndex: 0,
      };
    case 'SUBMIT_IMAGES_CROP':
      return {
        ...state,
        warning: null,
        error: null,
        pending: true,
      };
    case 'SUBMIT_IMAGE_RESPONSE_RECEVIED':
      return {
        ...state,
        pending: false,
      };
    case 'OPEN_DELETE_DIALOG':
      return {
        ...state,
        deleteDialog: true,
      };
    case 'CLOSE_DELETE_DIALOG':
      return {
        ...state,
        deleteDialog: false,
      };
    case 'OPEN_FILE_AND_URL_DIALOG': {
      return {
        ...state,
        fileAndUrlDialogOpen: true,
      };
    }

    case 'CLOSE_FILE_AND_URL_DIALOG': {
      return {
        ...state,
        fileAndUrlDialogOpen: false,
      };
    }

    case 'CHANGE_FILE_UPLOAD_URL': {
      return {
        ...state,
        fileUploadUrl: action.payload,
      };
    }
    case 'GALLERY_IMAGE_DELETE_REQUEST':
      return {
        ...state,
        deleteRequestPending: true,
      };
    case 'GALLERY_IMAGE_DELETED':
      return {
        ...state,
        deleteRequestPending: false,
      };
    case 'REMOVE_IMAGE_FROM_DRAFT':
      const cloneFiles = [...state.files];
      cloneFiles.splice(action.index, 1);
      let newCropIndex = state.currentCropIndex;
      if (action.index === state.currentCropIndex) {
        newCropIndex = 0;
      }
      return {
        ...state,
        files: cloneFiles,
        currentCropIndex: newCropIndex,
      };
    default:
      return state;
  }
};

export default multipleImageUpload;
