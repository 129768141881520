import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';

import NewFilter from './NewFilter';

import {
  addFilter,
  closeNewFilterDialog,
  formFieldChange,
} from './actions';

const mapStateToProps = state => ({
  ...state.ecommerce.marketplace.filters.newFilter,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(addFilter(ownProps.match.params.appname)),
  onRequestClose: () => dispatch(closeNewFilterDialog()),
  onFieldChange: (field, value) => dispatch(formFieldChange(field, value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewFilter),
);
