import _pick from 'lodash/pick';
import i18n from 'i18next';
import firebase from '../../../../../../firebase';
import { objectToArray, arrayToObject } from '../../../../../../utils';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';

import {
  REQUEST_MARKETPLACE_PRODUCTS,
  STOP_RECEIVING_MARKETPLACE_PRODUCTS,
  RECEIVE_MARKETPLACE_PRODUCTS,
  NO_MARKETPLACE_PRODUCTS_EXIST,
  FILTER_MARKETPLACE_PRODUCTS_DATA,
  SELECT_MARKETPLACE_PRODUCT,
  SELECT_ALL_MARKETPLACE_PRODUCTS,
  DESELECT_ALL_MARKETPLACE_PRODUCTS,
  DELETE_SELECTED,
  ACTION_ERROR,
  SORT_MARKETPLACE_PRODUCTS,
} from './types';

const requestProducts = appname => ({
  type: REQUEST_MARKETPLACE_PRODUCTS,
  appname,
});

export const getProducts = appname => dispatch => {
  dispatch(requestProducts(appname));

  firebase
    .database()
    .ref(`marketplace_products/${appname}`)
    .on('value', snap => {
      if (!snap.exists()) {
        dispatch({
          type: NO_MARKETPLACE_PRODUCTS_EXIST,
        });
      } else {
        let products = snap.val();
        Object.keys(products).forEach(productId => {
          if (products[productId].approved && !products[productId].rejected) {
            return (products[productId].productStatus = 'approved');
          }
          if (!products[productId].approved && products[productId].rejected) {
            return (products[productId].productStatus = 'rejected');
          }
          return (products[productId].productStatus = 'pending');
        });
        const formattedProducts = objectToArray(products);
        const sortProducts = formattedProducts.sort(
          (a, b) => b.value.created_at - a.value.created_at,
        );
        const reorderProducts = arrayToObject(sortProducts);
        dispatch({
          type: RECEIVE_MARKETPLACE_PRODUCTS,
          payload: { products: reorderProducts },
        });
      }
    });
};

export const filterProductData = filter => dispatch =>
  dispatch({
    type: FILTER_MARKETPLACE_PRODUCTS_DATA,
    payload: filter,
  });

export const selectProduct = key => ({
  type: SELECT_MARKETPLACE_PRODUCT,
  key,
});

export const selectAllProducts = () => ({
  type: SELECT_ALL_MARKETPLACE_PRODUCTS,
});

export const deselectAllProducts = () => ({
  type: DESELECT_ALL_MARKETPLACE_PRODUCTS,
});

export const deleteSelectedSuccess = (appname, selected) => dispatch =>
  dispatch({
    type: DELETE_SELECTED,
    appname,
    selected,
  });

export const reportActionError = (
  error,
  appname,
  selected,
  action,
) => dispatch =>
  dispatch({ type: ACTION_ERROR, error, appname, selected, action });

const deleteUser = (appname, email) =>
  new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;

    return user.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_MEMBER_API}/member`, {
        method: 'delete',
        mode: 'cors',
        body: JSON.stringify({
          appname,
          email,
          accessToken: token,
          requestOrigin: 'platform',
        }),
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            return resolve(res);
          }

          throw new Error(
            i18n.t('Opps, something went wrong. Please try again.'),
          );
        })
        .catch(reject);
    });
  });

const emailUsers = (appname, userEmails, emailFields) =>
  new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;
    const { uid } = user;

    return user.getIdToken().then(token => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-access-token', token);

      return fetch(`${process.env.REACT_APP_MARKETPLACE_API}/mail-users`, {
        method: 'post',
        mode: 'cors',
        body: JSON.stringify({
          appname,
          uid,
          userEmails,
          emailFields,
          accessToken: token,
          requestOrigin: 'platform',
        }),
        headers,
      })
        .then(res => {
          if (res.status === 200) {
            return resolve(res);
          }

          throw new Error(
            i18n.t('Opps, something went wrong. Please try again.'),
          );
        })
        .catch(reject);
    });
  });

export const deleteSelected = (appname, selected, chunks) => dispatch => {
  selected.forEach(product => {
    firebase
      .database()
      .ref(`marketplace_products/${appname}`)
      .child(product)
      .set(null)
      .then(() => {
        dispatch(deleteSelectedSuccess(appname, selected));
        dispatch(getProducts(appname));
        dispatch(openSnackbar('Product deleted successfully.'));
      })
      .catch(error => {
        dispatch(reportActionError(error));
      });
  });
};

export const stopGetProducts = appname => dispatch => {
  firebase
    .database()
    .ref(`app_users/${appname}`)
    .off('value');
  dispatch({
    type: STOP_RECEIVING_MARKETPLACE_PRODUCTS,
    value: appname,
  });
};

export const sortTableData = cols => dispatch =>
  dispatch({ type: SORT_MARKETPLACE_PRODUCTS, column: cols });
