import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import _map from 'lodash/map';
import MenuItem from 'material-ui/MenuItem';
import { withTranslation } from 'react-i18next';

import TextField from '../../../../../../../app/components/textField';
import SelectField from '../../../../../../../app/components/selectField';
import TextArea from '../../../../../../../app/components/textArea';
import Toggle from '../../../../../../../app/components/toggle';
import Container from '../../../../../../../app/components/container';
import SectionHeader from '../../../../../components/sectionHeader';
import AppLinkMenu from '../../../../../components/appLinkMenu';

import ImageUpload from '../../../../../internals/imageUpload';

class ScheduleItem extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
    filters: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    getFilters: PropTypes.func.isRequired,
    onDialogToggle: PropTypes.func.isRequired,
    childRoutes: PropTypes.array.isRequired,
    createLink: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    filters: {},
  };

  componentDidMount() {
    this.props.getGroupsData();

    const { component, getFilters } = this.props;

    if (typeof component.lazy_data === 'string') {
      getFilters(component.lazy_data);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { component, getFilters } = this.props;

    if (component.lazy_data !== nextProps.component.lazy_data) {
      getFilters(nextProps.component.lazy_data);
    }
  }
  getAvatarUrl = () => {
    const { match, location, component } = this.props;

    const { item, itinerary } = queryString.parse(location.search);

    return `apps/${match.params.appname}/draft/lazy_data/${component.lazy_data}/itinerary/${itinerary}/items/chunks/${item}/dialog/avatar`;
  };

  handleToggle = (e, isToggled) => this.props.onDialogToggle(isToggled);

  handleChange = path => value => this.props.onChange(path, value);

  render() {
    const { data, filters, groupsData, t } = this.props;

    const { dialog } = data;

    let categories;
    let disableCategories;

    if (filters) {
      disableCategories = false;
      categories = filters;
    } else {
      disableCategories = true;
      data.category = 'no-categories-available';
      categories = { 'no-categories-available': { label: 'No Categories' } };
    }

    const categoryOptions = _map(categories, (filter, key) => (
      <MenuItem key={key} value={key} primaryText={filter.label} />
    ));

    const groupOptions = _map(
      { all: { name: 'Visible to all' }, ...groupsData },
      (group, key) => (
        <MenuItem key={key} value={key} primaryText={group.name} />
      ),
    );

    const dialogToggle = (
      <Toggle onToggle={this.handleToggle} toggled={dialog.enabled} />
    );

    const imageUpload = dialog && (
      <ImageUpload
        title={t('Schedule Item > Avatar')}
        targetId="avatar_schedule_item"
        src={dialog && dialog.avatar}
        firebaseUrl={this.getAvatarUrl()}
        tooltip={t('Upload an avatar - minimum 240x240px')}
        height="160px"
        width="160px"
        avatar
      />
    );

    return (
      <div>
        <Container>
          <TextField
            id="title"
            value={data.title}
            placeholder={t('Title')}
            onChange={this.handleChange(['title'])}
          />
          <TextField
            id="subtitle"
            value={data.subtitle}
            placeholder={t('Subtitle')}
            onChange={this.handleChange(['subtitle'])}
          />
          <SelectField
            value={data.category}
            label={t('Category')}
            onChange={(i, value) => this.handleChange(['category'])(value)}
            options={categoryOptions}
            disabled={disableCategories}
          />
          <SelectField
            value={data.groupVisibility}
            label={t('Group Visibility')}
            onChange={(i, value) =>
              this.handleChange(['groupVisibility'])(value)
            }
            multiple
            options={groupOptions}
          />
          <AppLinkMenu
            link={data.link}
            pages={this.props.childRoutes}
            onChange={this.props.onChange}
            createLink={this.props.createLink}
          />
        </Container>
        <Container>
          <SectionHeader
            title={t('Dialog')}
            actions={dialogToggle}
            tooltip={t('Enable a dialog for this schedule item')}
          />
          {imageUpload}
          <TextField
            id="speaker_name"
            placeholder={t('Speaker Name')}
            value={dialog.name}
            onChange={this.handleChange(['dialog', 'name'])}
            disabled={!dialog.enabled}
          />
          <TextField
            id="speaker_position"
            placeholder={t('Speaker Position')}
            value={dialog.position}
            onChange={this.handleChange(['dialog', 'position'])}
            disabled={!dialog.enabled}
          />
          <TextArea
            id="event_description"
            value={dialog.description}
            label={t('Event Description')}
            onChange={this.handleChange(['dialog', 'description'])}
            disabled={!dialog.enabled}
          />
        </Container>
      </div>
    );
  }
}

export default withTranslation()(ScheduleItem);
