import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import { updateContact } from '../auth/login/actions';
import Subscribe from './Subscribe';
import {
  updateSubscription,
  subscribe,
  setPlan,
  getProratingAdjustment,
} from './actions';
import { addToAnalytics, addThankYouNotification } from '../offers/actions';
import moment from 'moment';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state.account.subscribe,
  payment: state.account.payment,
  subscription:
    state.account.subscriptions.items[ownProps.match.params.appname],
  integrationType: _get(state, 'account.organisation.integration.type'),
  accountId: _get(state, 'account.auth.profile.account'),
  offers: state.account.register.offers,
  referralOfferEligibility: state.account.organisation.referralOfferEligibility,
  redeemedCoupons: state.account.organisation.redeemedCoupons,
  referrer: state.account.organisation.referrer,
  offerEligibility: state.account.organisation.offerEligibility,
  account: state.account.organisation.key,
  country:
    (state.account.auth.profile.ip_data &&
      state.account.auth.profile.ip_data.showDynamicPricing &&
      state.account.auth.profile.ip_data.country &&
      state.account.auth.profile.ip_data.country
        .replace(/\d+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_')) ||
    '',
  appsumoId: state.account.organisation.appsumo_id || null,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setPlan: (isOffer, country) => {
    dispatch(setPlan(ownProps.location.query, isOffer, country));
  },
  getProratingAdjustment: () =>
    dispatch(
      getProratingAdjustment({
        appname: ownProps.match.params.appname,
      }),
    ),
  onSubscribe: ({
    plan,
    period,
    callback,
    isChangingPlan,
    coupon,
    price,
    planName,
    currency,
    stripe,
    productId,
  }) =>
    dispatch(
      subscribe({
        appname: ownProps.match.params.appname,
        plan,
        period,
        callback,
        isChangingPlan,
        coupon,
        price,
        planName,
        currency,
        stripe,
        productId,
      }),
    ),
  updateSubscription: ({
    plan,
    period,
    callback,
    isChangingPlan,
    coupon,
    price,
    planName,
    currency,
    productId,
  }) =>
    dispatch(
      updateSubscription({
        appname: ownProps.match.params.appname,
        plan,
        period,
        callback,
        isChangingPlan,
        coupon,
        price,
        planName,
        currency,
        productId,
      }),
    ),
  addThankYouNotification: (account, content) =>
    dispatch(addThankYouNotification(account, content)),
  addToAnalytics: (account, offerId) =>
    dispatch(addToAnalytics(account, offerId)),
  callFreshmarketerApi: account =>
    dispatch(
      updateContact({
        account,
        plan_upgrade_completed: moment(new Date()).format(
          'DD/MM/YYYY hh:mm:ss',
        ),
        isAppsumoAccount:
          ownProps.account && !!ownProps.account.organisation.appsumo_id,
      }),
    ),
  displayPaymentError: msg => dispatch(openSnackbar(msg)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subscribe),
);
