import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

const iconStyle = {
  height: 18,
  width: 18,
  fill: '#261ed5',
  fillRule: 'evenodd',
};
/* eslint-disable max-len */
const Info = () => (
  <SvgIcon viewBox="0 0 60 60" style={iconStyle}>
    <path d="M51.211,51.213 C45.546,56.879 38.012,60.000 29.998,60.000 C21.985,60.000 14.451,56.879 8.786,51.213 C3.120,45.547 0.000,38.014 0.000,29.999 C0.000,21.986 3.120,14.452 8.786,8.787 C14.451,3.121 21.985,-0.000 29.998,-0.000 C38.012,-0.000 45.546,3.121 51.211,8.787 C56.879,14.452 60.000,21.986 60.000,29.999 C60.000,38.014 56.879,45.547 51.211,51.213 ZM49.797,10.201 C44.509,4.912 37.478,2.000 29.998,2.000 C22.519,2.000 15.488,4.912 10.200,10.201 C4.912,15.489 2.000,22.520 2.000,29.999 C2.000,37.479 4.912,44.510 10.200,49.798 C15.488,55.088 22.519,58.000 29.998,58.000 C37.478,58.000 44.509,55.088 49.797,49.798 C55.087,44.510 58.000,37.479 58.000,29.999 C58.000,22.520 55.087,15.489 49.797,10.201 ZM34.000,47.000 L26.000,47.000 C25.448,47.000 25.000,46.552 25.000,46.000 C25.000,45.448 25.448,45.000 26.000,45.000 L29.001,45.000 L29.001,27.001 L26.000,27.001 C25.448,27.001 25.000,26.553 25.000,26.001 C25.000,25.448 25.448,25.001 26.000,25.001 L30.000,25.001 C30.553,25.001 31.001,25.448 31.001,26.001 L31.001,45.000 L34.000,45.000 C34.553,45.000 35.001,45.448 35.001,46.000 C35.001,46.552 34.553,47.000 34.000,47.000 ZM30.000,20.001 C27.795,20.001 26.000,18.206 26.000,16.001 C26.000,13.795 27.795,12.001 30.000,12.001 C32.206,12.001 34.000,13.795 34.000,16.001 C34.000,18.206 32.206,20.001 30.000,20.001 ZM30.000,14.001 C28.897,14.001 28.000,14.898 28.000,16.001 C28.000,17.104 28.897,18.001 30.000,18.001 C31.104,18.001 32.000,17.104 32.000,16.001 C32.000,14.898 31.104,14.001 30.000,14.001 Z" />
  </SvgIcon>
);

export default Info;
