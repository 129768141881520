import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { launchPublishDialog } from '../../app/actions';
import { getGroups } from './modules/groups/actions';
import { getStaffs } from './modules/staff/actions';
import { getAppAdmins } from './modules/admins/actions';
import { getMemberBodies } from './modules/memberBodies/actions';
import { getTypes } from './modules/memberTypes/modules/listing/actions';
import { getWorkforceList } from './modules/workforceMembers/modules/listing/actions';
import { getUsers } from './modules/users/actions';
import { openNewGroupDialog } from './modules/groups/newGroup/actions';
import { openMemberGroupDialog } from './modules/memberTypes/modules/listing/modules/createGroup/actions';
import { openWorkforceMemberDialog } from './modules/workforceMembers/modules/listing/modules/createModal/actions';
import { openMemberGroupFieldDialog } from './modules/memberTypes/modules/edit/modules/create/actions';
import { openNewStaffDialog } from './modules/staff/newStaff/actions';
import { openNewAdminDialog } from './modules/admins/newAdmin/actions';
import { openNewMemberBodyDialog } from './modules/memberBodies/modules/create/actions';
import {
  loadContent,
  removeGroupsAndUsersListeners,
  addEventCalendarComponent,
} from './actions';
import {
  addIsPublishedListener,
  removeIsPublishedListener,
} from '../../app/modules/toolbar/actions';
import { openNewEventDialog } from '../staff/modules/events/modules/create/actions';
import { openNewCourseDialog } from '../staff/modules/courses/modules/create/actions';
import { openNewCPDDialog } from '../staff/modules/cpds/modules/create/actions';

import MemberLogin from './MemberLogin';
import { loadSubscriptions } from './modules/subscriptions/actions';
import { loadTransactions } from './modules/Transactions/actions';
import { loadDiaryData } from './modules/Diary/actions';
import { appHasComponent } from '../../utils';
import { APP_SUMO_PLANS } from '../../plans';

class MemberLoginContainer extends Component {
  componentDidMount() {
    this.props.loadContent();
  }

  render() {
    return <MemberLogin {...this.props} />;
  }
}

MemberLoginContainer.propTypes = {
  loadContent: PropTypes.func.isRequired,
  onAddGroup: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const enabled = state.auth.structure.enabled || false;
  const legacyFlag = state.account.organisation.legacy_flag || false;
  const isInTrial = !state.account.organisation.trial_expired || false;
  const subscription =
    state.account.subscriptions.items[ownProps.match.params.appname] || {};
  const eventComponentEnabled = appHasComponent(
    state.editor.structure,
    'EventCalendar',
  );
  return {
    ...ownProps,
    eventComponentEnabled,
    componentsPending: state.editor.structure.pending,
    fetchedRoutesData: state.editor.structure.routes.length > 0,
    groups: state.auth.groups.data.chunks,
    role: state.account.auth.profile.role,
    enabled:
      enabled &&
      (legacyFlag ||
        isInTrial ||
        (subscription && subscription.plan.includes('deluxe')) ||
        (subscription && subscription.plan.includes('accelerate')) ||
        (subscription && subscription.plan.includes('diy')) ||
        (subscription && subscription.plan.includes('launch')) ||
        (subscription && subscription.plan.includes('expert')) ||
        (subscription &&
          subscription.plan &&
          APP_SUMO_PLANS.includes(subscription.plan))),
    isPublished: state.editor.data.isPublished[ownProps.match.params.appname],
    memberLoginSectionsAccess:
      state.account.profile.sectionsAccess.member_login,
  };
};

const backToGroup = ownProps =>
  ownProps.history.push(`/${ownProps.match.params.appname}/members/types`);

const backToWorkforceList = ownProps =>
  ownProps.history.push(`/${ownProps.match.params.appname}/members/workforce`);

const mapDisptachToProps = (dispatch, ownProps) => ({
  removeGroupsAndUsersListeners: () =>
    removeGroupsAndUsersListeners(ownProps.match.params.appname),
  getUsers: () => dispatch(getUsers(ownProps.match.params.appname)),
  getStaffs: () => dispatch(getStaffs(ownProps.match.params.appname)),
  getAppAdmins: () => dispatch(getAppAdmins()),
  getMemberBodies: () =>
    dispatch(getMemberBodies(ownProps.match.params.appname)),
  getTypes: () => dispatch(getTypes(ownProps.match.params.appname)),
  getWorkforceList: () =>
    dispatch(getWorkforceList(ownProps.match.params.appname)),
  getGroups: () => dispatch(getGroups(ownProps.match.params.appname)),
  loadContent: () => dispatch(loadContent(ownProps.match.params.appname)),
  onAddGroup: () => dispatch(openNewGroupDialog(ownProps.match.params.appname)),
  onAddStaff: () => dispatch(openNewStaffDialog(ownProps.match.params.appname)),
  onAddAdmin: () => dispatch(openNewAdminDialog(ownProps.match.params.appname)),
  onAddMemberBody: () =>
    dispatch(openNewMemberBodyDialog(ownProps.match.params.appname)),
  onAddType: () =>
    dispatch(openMemberGroupDialog(ownProps.match.params.appname)),
  onAddWorkforce: () =>
    dispatch(openWorkforceMemberDialog(ownProps.match.params.appname)),
  onAddField: () =>
    dispatch(openMemberGroupFieldDialog(ownProps.match.params.appname)),
  backToGroup: () => backToGroup(ownProps),
  backToWorkforceList: () => backToWorkforceList(ownProps),
  onPublish: () => dispatch(launchPublishDialog(ownProps.match.params.appname)),
  addIsPublishedListener: () => dispatch(addIsPublishedListener()),
  removeIsPublishedListener: () => dispatch(removeIsPublishedListener()),
  onAddEvent: () => dispatch(openNewEventDialog(ownProps.match.params.appname)),
  onAddCourse: () =>
    dispatch(openNewCourseDialog(ownProps.match.params.appname)),
  onAddCPD: () => dispatch(openNewCPDDialog(ownProps.match.params.appname)),
  loadSubscriptions: () =>
    dispatch(loadSubscriptions(ownProps.match.params.appname)),
  loadDiaryData: () => dispatch(loadDiaryData(ownProps.match.params.appname)),
  onAddEventCalendarComponent: () =>
    dispatch(
      addEventCalendarComponent(
        ownProps.match.params.appname,
        ownProps.history,
      ),
    ),
});

export default connect(mapStateToProps, mapDisptachToProps)(MemberLogin);
