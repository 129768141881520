import firebase from '../../../../../../../firebase';

export const recordLastEdited = (appname, dataId) => (dispatch, getState) => {
  const { user } = getState().account.auth;
  const userRef = firebase.database().ref(`users/${user.uid}/`);

  return userRef
    .child('lastEdited')
    .set(`apps/${appname}/draft/lazy_data/${dataId}`);
};

export const removeAccessToken = (appname, lazy_data_id) => (dispatch, getState) => {
  const { profile } = getState().account;
  const dataRef = firebase.database().ref(profile.lastEdited);
  const instaRef = firebase.database().ref(`social_media/instagramFeed/${appname}`)
  instaRef.child(lazy_data_id).set({});
  dataRef.child('access_token').set({});
  dataRef.child('instagramShare').set({});
  dataRef.child('shareUrl').set({});
  dataRef.child('expires_in').set({});
  return dataRef
};

export const menuChange = (appname, dataId, field, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);

  dataRef.child(field).set(value);

  return {
    type: 'INSTAGRAM_LAYOUT_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};
