import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import styled from 'styled-components';
import { filter } from 'lodash';
import MenuItem from 'material-ui/MenuItem';
import { Trans, useTranslation } from 'react-i18next';
import DesignToggle from 'modules/editor/components/designToggle';
import ColorPicker from 'modules/editor/components/colorPicker';
import TextField from '../../../../../../../app/components/textField';
import Container from '../../../../../../../app/components/container';
import SectionHeader from '../../../../../components/sectionHeader';
import { Tabs, Tab } from '../../../../../components/tabs';
import SelectField from '../../../../../../../app/components/selectField';
import logo from './logo.png';
import themeStyles from '../../../../../../../_export.scss';
import { whitelabeledHost } from '../../../../../../../utils/whitelabeledHost';

const isWhiteLabeledHost = whitelabeledHost();

const FullWidth = styled.div`
  width: 100%;
`;

const categoryChange = (field, value, categories, onFieldChange) => {
  const activeCategory = filter(categories, { id: value });
  onFieldChange(field, activeCategory[0]);
};
const TrackSwitchedColor = { backgroundColor: themeStyles.primaryColorLight };
const ThumbSwitchedColor = { backgroundColor: themeStyles.primaryColor };

const Shopify = ({
  palette,
  component,
  onFieldChange,
  onDesignChange,
  onDefaultColorChange,
  isAppAdmin,
}) => {
  const { t } = useTranslation();

  const handleColorChange = (prop, defaultProp) => value => {
    const { r, g, b, a } = value.rgb;
    const rgba = `rgba(${r},${g},${b},${a})`;
    onDesignChange(prop, rgba);
    onDefaultColorChange(defaultProp, rgba);
  };

  const switches = (title, field, propsValue, defaultValue) => (
    <FullWidth>
      <SectionHeader
        title={title}
        actions={
          <Toggle
            toggled={propsValue !== undefined ? propsValue : defaultValue}
            onToggle={(e, isToggled) => onFieldChange(field, isToggled)}
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
        }
      />
      <br />
    </FullWidth>
  );

  const displayOutOfStockItems = (title, field, propsValue, defaultValue) => (
    <FullWidth>
      <SectionHeader
        title={title}
        actions={
          <Toggle
            toggled={propsValue !== undefined ? propsValue : defaultValue}
            onToggle={(e, isToggled) => onFieldChange(field, isToggled)}
            trackSwitchedStyle={TrackSwitchedColor}
            thumbSwitchedStyle={ThumbSwitchedColor}
          />
        }
      />
      <br />
    </FullWidth>
  );

  const Categories = () => {
    const categories = component.categories || [];
    const categoryLinks = categories.map(category => (
      <MenuItem
        key={category.slug}
        value={category.id}
        primaryText={category.name}
      />
    ));

    return categoryLinks.length ? (
      <SelectField
        label={t('Select a category')}
        value={component.activeCategory ? component.activeCategory.id : null}
        options={categoryLinks}
        onChange={(index, value) =>
          categoryChange(
            'activeCategory',
            value,
            component.categories,
            onFieldChange,
          )
        }
        disabled={!component.showProductsOfSpecificCategory}
      />
    ) : null;
  };

  const renderContent = () => {
    return (
      <FullWidth>
        <SectionHeader
          title="Shopify"
          tooltip={
            <span>
              <Trans>Follow the instructions below</Trans> <br />
              <Trans>to add an Shopify eCommerce component</Trans>
            </span>
          }
        />
        <div style={{ padding: '0 30px', marginTop: 24 }}>
          <p>
            <Trans>Enter your</Trans>&nbsp;
            <span style={{ color: '#DA1A26' }}>
              &quot;<Trans>Shopify Domain</Trans>&quot;
            </span>
            &nbsp;<Trans>and</Trans>&nbsp;
            <span style={{ color: '#DA1A26' }}>
              &quot;<Trans>Storefront Access Token</Trans>&quot;
            </span>
            &nbsp;<Trans>below if you have already set up an account and</Trans>
            &nbsp;
            <span style={{ color: '#DA1A26' }}>
              <Trans>Storefront App</Trans>
            </span>
            &nbsp;<Trans>with Shopify.</Trans>
          </p>
        </div>
        <div style={{ padding: '0 12px' }}>
          <TextField
            id="title"
            name="title"
            placeholder={t('Enter your Shopify Domain')}
            value={component.src}
            onChange={value => onFieldChange('src', value)}
          />
          {component.src && component.src.includes('http') && (
            <small style={{ color: 'red', marginLeft: '20px' }}>
              <Trans>please remove</Trans> http:// <Trans>or</Trans> https://
              <Trans>from the url.</Trans>
            </small>
          )}
          <TextField
            id="title"
            name="title"
            placeholder={t('Enter your Storefront Access Token')}
            value={component.token}
            onChange={value => onFieldChange('token', value)}
          />
          {component.categories &&
            component.categories.length > 0 &&
            switches(
              t('Show products of specific category'),
              'showProductsOfSpecificCategory',
              component.showProductsOfSpecificCategory,
              component.showProductsOfSpecificCategory || true,
            )}
          {displayOutOfStockItems(
            t('Display out of stock products'),
            'displayOutOfStockItems',
            component.displayOutOfStockItems,
            component.displayOutOfStockItems || true,
          )}
          {Categories()}
        </div>
        {!isAppAdmin && !isWhiteLabeledHost && (
          <div style={{ padding: '0 30px' }}>
            <p>
              <a
                href="https://beezer.zendesk.com/hc/en-us/articles/360021355751-Shopify"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Trans>Learn more</Trans>
              </a>
              &nbsp;
              <Trans>
                about how to setup your Shopify eCommerce component.
              </Trans>
            </p>
          </div>
        )}
        <Container>
          <img
            style={{ maxWidth: 190, marginTop: 24 }}
            src={logo}
            alt="Shopify logo"
          />
        </Container>
      </FullWidth>
    );
  };

  const renderDesign = () => {
    return (
      <Container>
        <DesignToggle component={component} onDesignChange={onDesignChange} />
        <ColorPicker
          label={t('Text Color')}
          color={
            component.design.enabled
              ? component.design.textColor || palette.shopifyDefaultTextColor
              : palette.alternateTextColor
          }
          height={22}
          onChange={handleColorChange('textColor', 'shopifyDefaultTextColor')}
          disabled={!component.design.enabled}
        />
        <ColorPicker
          label={t('Background Color')}
          color={
            component.design.enabled
              ? component.design.backgroundColor ||
                palette.shopifyDefaultBackgroundColor
              : palette.primary1Color
          }
          height={22}
          onChange={handleColorChange(
            'backgroundColor',
            'shopifyDefaultBackgroundColor',
          )}
          disabled={!component.design.enabled}
        />
      </Container>
    );
  };

  return (
    <Tabs>
      <Tab label={t('content')}>{renderContent()}</Tab>
      <Tab label={t('design')}>{renderDesign()}</Tab>
      <Tab
        label={t('layout')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
    </Tabs>
  );
};

Shopify.propTypes = {
  component: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onDesignChange: PropTypes.func.isRequired,
  palette: PropTypes.object.isRequired,
  isAppAdmin: PropTypes.bool.isRequired,
  onDefaultColorChange: PropTypes.func.isRequired,
};

Shopify.defaultProps = {
  component: {},
};

export default Shopify;
