import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import { Trans, withTranslation } from 'react-i18next';

import TextField from '../../../../../../../app/components/textField';
import ErrorMessage from '../../../../../../../app/components/errorMessage';
import SectionHeader from '../../../../../components/sectionHeader';
import Container from '../../../../../../../app/components/container';
import ButtonLabelSetter from '../../../../../../editor/components/buttonLabelSetter';
import AutoFillEmail from '../../../../../../editor/components/autoFillEmail';
import DesignToggle from '../../../../../components/designToggle';
import ButtonDesignMenu from '../../../../../components/buttonDesignMenu';
import TitleSetter from '../../../../../../editor/components/titleSetter';
import TextArea from '../../../../../../../app/components/textArea';
import { Tabs, Tab } from '../../../../../components/tabs';
import themeStyles from '../../../../../../../_export.scss';
import { validateField } from '../form/validator';
import CircularValidation from '../../../../../../../app/components/circularValidation';

class Rating extends Component {
  static propTypes = {
    component: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    onContentChange: PropTypes.func.isRequired,
    onDesignChange: PropTypes.func.isRequired,
  };

  state = {
    gaToggled: false,
    gaCategoryValue: '',
    gaActionValue: '',
  };

  async componentDidMount() {
    this.setState({ gaToggled: this.props.data.gaEnabled || false });
    this.setState({ gaCategoryValue: this.props.data.gaCategory || '' });
    this.setState({ gaActionValue: this.props.data.gaAction || '' });
    await this.props.onLoadGoogleAnalytics();
    !this.props.googleAnalytics.id &&
      this.setState({ gaToggled: false }, () => {
        this.handleChange('gaEnabled')(false);
      });
  }

  onToggleDisplay = (field, isToggled) =>
    this.props.onContentChange(field, isToggled);

  handleChange = prop => value => this.props.onContentChange(prop, value);
  handleInputChange = (prop, value) => this.props.onContentChange(prop, value);
  handleAutoFillEmailChange = value =>
    this.props.onContentChange('autoFillEmail', value);

  handleGaToggle = () => {
    if (!this.props.googleAnalytics.id) {
      return;
    }
    this.setState({ gaToggled: !this.state.gaToggled }, () => {
      this.handleChange('gaEnabled')(this.state.gaToggled);
    });
  };

  handleGaCategoryChange = value => {
    this.setState({ gaCategoryValue: value }, () => {
      this.handleChange('gaCategory')(value);
    });
  };

  handleGaActionChange = value => {
    this.setState({ gaActionValue: value }, () => {
      this.handleChange('gaAction')(value);
    });
  };

  render() {
    const { component, data, onDesignChange, t } = this.props;

    return (
      <Tabs>
        <Tab label={t('content')}>
          <Container className="rating-component-container">
            <TitleSetter
              displayTitle={
                data.displayTitle !== undefined ? data.displayTitle : false
              }
              onToggleTitleDisplay={this.handleChange('displayTitle')}
              onChangeTitle={this.handleChange('title')}
              title={data.title}
              typeOfComponent="rating"
              isValid={data.title !== '' && data.title !== undefined}
              required
            />
            <TextArea
              id="rating-caption"
              label={t('Enter some instructions (optional)')}
              value={data.caption}
              onChange={this.handleChange('caption')}
            />
            <ButtonLabelSetter
              onChange={this.handleChange('buttonLabel')}
              label={data.buttonLabel || t('Add Review')}
              googleAnalytics={this.props.googleAnalytics}
              gaEnabled={this.props.data.gaEnabled}
              gaCategory={this.props.data.gaCategory}
              gaAction={this.props.data.gaAction}
            />
            <SectionHeader
              title={`${t('Additional Email Address')}`}
              className="section-header"
              actions={
                <Toggle
                  toggled={data.additionalEmail}
                  onToggle={(e, isToggled) =>
                    this.onToggleDisplay('additionalEmail', isToggled)
                  }
                  trackSwitchedStyle={{
                    backgroundColor: themeStyles.primaryColorLight,
                  }}
                  thumbSwitchedStyle={{
                    backgroundColor: themeStyles.primaryColor,
                  }}
                />
              }
            />
            <TextField
              value={data.newEmailId}
              onChange={value => this.handleInputChange('newEmailId', value)}
              placeholder={t('Email Address')}
              disabled={!data.additionalEmail}
              rightInputAction={
                data.additionalEmail && (
                  <CircularValidation
                    isValid={
                      data.newEmailId &&
                      data.newEmailId.length > 0 &&
                      validateField.email(data.newEmailId)
                    }
                    defaultMsg={t('Additional email is required')}
                    errorMsg={t(
                      'Additional email is required in correct format',
                    )}
                    showDefault={
                      !data.newEmailId || data.newEmailId.length === 0
                    }
                  />
                )
              }
            />

            <div style={{ width: '100%' }}>
              <SectionHeader
                title={t('Google Analytics Events')}
                actions={
                  <Toggle
                    onToggle={this.handleGaToggle}
                    toggled={this.state.gaToggled}
                  />
                }
                tooltip={
                  !this.props.googleAnalytics.id
                    ? t(
                        'You need to enable Google Analytics to use this feature',
                      )
                    : t('Set google analytics events on button click')
                }
                tooltipType={
                  !this.props.googleAnalytics.id ? 'warning' : 'info'
                }
              />
              <TextField
                value={this.state.gaCategoryValue}
                onChange={value => this.handleGaCategoryChange(value)}
                placeholder={t('GA Category')}
                disabled={!this.state.gaToggled}
              />
              {!this.state.gaCategoryValue && this.state.gaToggled && (
                <ErrorMessage style={{ textAlign: 'center' }}>
                  <Trans>GA Category is required!</Trans>
                </ErrorMessage>
              )}
              <TextField
                value={this.state.gaActionValue}
                onChange={value => this.handleGaActionChange(value)}
                placeholder={t('GA Action')}
                disabled={!this.state.gaToggled}
              />
              {!this.state.gaActionValue && this.state.gaToggled && (
                <ErrorMessage style={{ textAlign: 'center' }}>
                  <Trans>GA Action is required!</Trans>
                </ErrorMessage>
              )}
            </div>

            <AutoFillEmail
              onChange={this.handleAutoFillEmailChange}
              isChecked={data.autoFillEmail}
            />
          </Container>
        </Tab>
        <Tab label={t('design')}>
          <Container>
            <DesignToggle
              component={component}
              onDesignChange={(el, isToggled) =>
                onDesignChange(null)(el, isToggled)
              }
            />
            <ButtonDesignMenu
              component={component}
              onDesignChange={onDesignChange('buttonDesign')}
            />
          </Container>
        </Tab>
        <Tab
          label={t('layout')}
          disabled
          style={{
            color: '#d9d9d9',
          }}
        />
      </Tabs>
    );
  }
}

export default withTranslation()(Rating);
