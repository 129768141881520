import React, { useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Intro from './modules/intro';
import Vendors from './modules/vendors';
import Vendor from './modules/vendor';
import Buyer from './modules/buyer';
import Buyers from './modules/buyers';
import Filters from './modules/filters';
import EditFilter from './modules/filters/editFilter';
import Settings from './modules/settings';
import MarketplaceDisabledWrapper from './components/marketplaceDisabledWrapper';
import ProductsTable from './modules/productsTable';
import Product from './modules/product';
const Marketplace = props => {
  useEffect(() => {
    props.getMarketplaceSettings();
  }, []);
  return (
    <Switch>
      {!props.marketplaceEnabled && (
        <Redirect
          exact
          from={`/${props.match.path}`}
          to={`/:appname/ecommerce`}
        />
      )}
      {!props.marketplaceEnabled && (
        <Redirect
          exact
          from={`/${props.match.path}/sellers`}
          to={`/:appname/ecommerce`}
        />
      )}
      {!props.marketplaceEnabled && (
        <Redirect
          exact
          from={`/${props.match.path}/buyers`}
          to={`/:appname/ecommerce`}
        />
      )}
      {!props.marketplaceEnabled && (
        <Redirect
          exact
          from={`/${props.match.path}/products`}
          to={`/:appname/ecommerce`}
        />
      )}

      {!props.marketplaceEnabled && (
        <Redirect
          exact
          from={`/${props.match.path}/filters`}
          to={`/:appname/ecommerce`}
        />
      )}

      {!props.marketplaceEnabled && (
        <Redirect
          exact
          from={`/${props.match.path}/settings`}
          to={`/:appname/ecommerce`}
        />
      )}
      <Route exact path={`/:appname/ecommerce/marketplace/features`}>
        <Intro {...props} />
      </Route>

      <Route path={`/:appname/ecommerce/marketplace/sellers/:vendorID`}>
        {props.marketplaceComponentExists ? (
          <Vendor {...props} />
        ) : (
          <MarketplaceDisabledWrapper>
            <Vendor {...props} />
          </MarketplaceDisabledWrapper>
        )}
      </Route>

      <Route path={`/:appname/ecommerce/marketplace/sellers`}>
        {props.marketplaceComponentExists ? (
          <Vendors {...props} />
        ) : (
          <MarketplaceDisabledWrapper>
            <Vendors {...props} />
          </MarketplaceDisabledWrapper>
        )}
      </Route>

      <Route path={`/:appname/ecommerce/marketplace/buyers/:buyerID`}>
        {props.marketplaceComponentExists ? (
          <Buyer {...props} />
        ) : (
          <MarketplaceDisabledWrapper>
            <Buyer {...props} />
          </MarketplaceDisabledWrapper>
        )}
      </Route>

      <Route path={`/:appname/ecommerce/marketplace/buyers`}>
        {props.marketplaceComponentExists ? (
          <Buyers {...props} />
        ) : (
          <MarketplaceDisabledWrapper>
            <Buyers {...props} />
          </MarketplaceDisabledWrapper>
        )}
      </Route>
      <Route path={`/:appname/ecommerce/marketplace/products/:productId`}>
        {props.marketplaceComponentExists ? (
          <Product {...props} />
        ) : (
          <MarketplaceDisabledWrapper>
            <Product {...props} />
          </MarketplaceDisabledWrapper>
        )}
      </Route>
      <Route path={`/:appname/ecommerce/marketplace/products`}>
        {props.marketplaceComponentExists ? (
          <ProductsTable {...props} />
        ) : (
          <MarketplaceDisabledWrapper>
            <ProductsTable {...props} />
          </MarketplaceDisabledWrapper>
        )}
      </Route>

      <Route exact path={`/:appname/ecommerce/marketplace/categories`}>
        {props.marketplaceComponentExists ? (
          <Filters {...props} />
        ) : (
          <MarketplaceDisabledWrapper>
            <Filters {...props} />
          </MarketplaceDisabledWrapper>
        )}
      </Route>

      <Route path={`/:appname/ecommerce/marketplace/categories/:categoryID`}>
        {props.marketplaceComponentExists ? (
          <EditFilter {...props} />
        ) : (
          <MarketplaceDisabledWrapper>
            <EditFilter {...props} />
          </MarketplaceDisabledWrapper>
        )}
      </Route>

      <Route exact path={`/:appname/ecommerce/marketplace/settings`}>
        {props.marketplaceComponentExists ? (
          <Settings {...props} />
        ) : (
          <MarketplaceDisabledWrapper>
            <Settings {...props} />
          </MarketplaceDisabledWrapper>
        )}
      </Route>
    </Switch>
  );
};

export default Marketplace;
