import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import DraggableListWrapper from '../../../../../components/draggableListWrapper';
import ComponentItem from '../../../../../components/componentItem';
import SectionHeader from '../../../../../components/sectionHeader';
import CreateField from '../../../../../components/createField';
import { Tabs, Tab } from '../../../../../components/tabs';

class Delegates extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onAddItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    rearrangeItems: PropTypes.func.isRequired,
  };

  getItemHref = key => {
    const { match, location } = this.props;

    return {
      pathname: `/${match.params.appname}/editor/build/item`,
      search: `${location.search}&item=${key}`,
    };
  };

  handleMove = (el, target, source, sibling) => {
    if (sibling) {
      return this.props.rearrangeItems(el.id, sibling.id);
    }

    return this.props.rearrangeItems(el.id, null);
  };

  handleNavigate = key => () => {
    const href = this.getItemHref(key);

    return this.props.history.push(href);
  };

  renderItems = () => {
    const { data } = this.props;

    if (!data || !data.items || !data.items.order) return [];

    return data.items.order.map(key => {
      const title = data.items.chunks[key] && data.items.chunks[key].title;

      return (
        <ComponentItem
          id={key}
          key={key}
          label={title}
          onClick={this.handleNavigate(key)}
          onDelete={() => this.props.onRemoveItem(key)}
        />
      );
    });
  };

  render() {
    const items = this.renderItems();
    const { t } = this.props;
    return (
      <React.Fragment>
        <Tabs>
          <Tab label={t('Content')}>
            <SectionHeader title={t('Delegates Items')} />
            <CreateField
              id="delegates-label"
              hintText={t('Add a new delegate')}
              tooltip={t('Create New Item')}
              onSubmit={this.props.onAddItem}
            />
            <DraggableListWrapper
              items={items}
              onPositionChange={this.handleMove}
            />
          </Tab>
          <Tab
            label={t('design')}
            disabled
            style={{
              color: '#d9d9d9',
            }}
          />
          <Tab
            label={t('layout')}
            disabled
            style={{
              color: '#d9d9d9',
            }}
          />
        </Tabs>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Delegates);
