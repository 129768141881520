import React, { useState, useEffect, useCallback, useRef } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import { size } from 'lodash';
import {
  CarouselContainer,
  CarouselItem,
  CarouselItemCounter,
  CarouselContainerInner,
  LeftCarouselButton,
  RightCarouselButton,
} from './styles';

const Carousel = ({ children }) => {
  const getPrevElement = list => {
    const sibling = list[0].previousElementSibling;
    if (sibling instanceof HTMLElement) {
      return sibling;
    }

    return sibling;
  };

  function getNextElement(list) {
    const sibling = list[list.length - 1].nextElementSibling;

    if (sibling instanceof HTMLElement) {
      return sibling;
    }

    return null;
  }

  const usePosition = ref => {
    const [prevElement, setPrevElement] = useState(null);
    const [nextElement, setNextElement] = useState(null);
    const [activeElementPosition, setActiveElementPosition] = useState(0);
    useEffect(() => {
      const element = ref.current;

      const update = () => {
        const rect = element.getBoundingClientRect();
        const visibleElements = Array.from(element.children).filter(
          (child, index) => {
            const childRect = child.getBoundingClientRect();
            const isElementVisible =
              Math.floor(childRect.left) + 5 >= Math.floor(rect.left) &&
              Math.floor(childRect.right) <= Math.floor(rect.right) + 5;
            if (isElementVisible) {
              setActiveElementPosition(index);
            }
            return isElementVisible;
          },
        );

        if (visibleElements.length > 0) {
          setPrevElement(getPrevElement(visibleElements));
          setNextElement(getNextElement(visibleElements));
        }
      };

      update();
      element.addEventListener('scroll', update, { passive: true });

      return () => {
        element.removeEventListener('scroll', update, { passive: true });
      };
    }, [ref, children]);

    const scrollToElement = React.useCallback(
      element => {
        const currentNode = ref.current;

        if (!currentNode || !element) return;

        const newScrollPosition =
          element.offsetLeft +
          element.getBoundingClientRect().width / 2 -
          currentNode.getBoundingClientRect().width / 2;
        currentNode.scroll({
          left: newScrollPosition,
          behavior: 'smooth',
        });
      },
      [ref],
    );

    const scrollRight = useCallback(() => scrollToElement(nextElement), [
      scrollToElement,
      nextElement,
    ]);

    const scrollLeft = useCallback(() => scrollToElement(prevElement), [
      scrollToElement,
      prevElement,
    ]);

    return {
      hasItemsOnLeft: prevElement !== null,
      hasItemsOnRight: nextElement !== null,
      scrollRight,
      scrollLeft,
      activeElementPosition,
    };
  };

  const ref = useRef();

  const {
    hasItemsOnLeft,
    hasItemsOnRight,
    scrollRight,
    scrollLeft,
    activeElementPosition,
  } = usePosition(ref);

  return (
    <CarouselContainer role="region" aria-label="carousel">
      <CarouselContainerInner innerRef={ref}>
        {React.Children.map(children, (child, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CarouselItem key={index}>{child}</CarouselItem>
        ))}
      </CarouselContainerInner>
      {size(children) > 1 ? (
        <CarouselItemCounter>
          {activeElementPosition + 1}/{size(children)}
        </CarouselItemCounter>
      ) : (
        <></>
      )}

      <LeftCarouselButton
        size="large"
        hasItemsOnLeft={hasItemsOnLeft}
        onClick={scrollLeft}
        aria-label="Previous slide"
      >
        <ArrowBackIcon />
      </LeftCarouselButton>
      <RightCarouselButton
        size="large"
        hasItemsOnRight={hasItemsOnRight}
        onClick={scrollRight}
        aria-label="Next slide"
      >
        <ArrowForwardIcon />
      </RightCarouselButton>
    </CarouselContainer>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Carousel;
