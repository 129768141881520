import { connect } from 'react-redux';

import Timer from './Timer';

import {
  cycleChange,
  setsChange,
  restChange,
  exerciseChange,
  warmupChange,
  titleChange,
  changeSettingsStatus,
} from './actions';

const mapStateToProps = (state, ownProps) => ownProps;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangeRest: value =>
    dispatch(
      restChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
      ),
    ),
  onChangeTitle: value =>
    dispatch(
      titleChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
      ),
    ),

  onChangeExercise: value =>
    dispatch(
      exerciseChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
      ),
    ),
  onChangeWarmup: value =>
    dispatch(
      warmupChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
      ),
    ),
  onChangeCycles: e =>
    dispatch(
      cycleChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        e.target.value,
      ),
    ),
  onChangeSets: e =>
    dispatch(
      setsChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        e.target.value,
      ),
    ),
  onChangeSettingsStatus: e =>
    dispatch(
      changeSettingsStatus(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        e.target.checked,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
