import { connect } from 'react-redux';

import MobileOverlay from './MobileOverlay';
import { logout } from '../../../modules/account/modules/auth/logout/actions';
import { findFirst } from '../../../utils';

const mapStateToProps = (state, ownProps) => {
  const allNotices = state.account.organisation.notices;

  if (allNotices) {
    const autoPopup = findFirst(allNotices, o => k => o[k].autoPopup);

    return {
      ...ownProps,
      content: allNotices[autoPopup] ? allNotices[autoPopup].mobileContent : '',
    };
  }

  return {
    ...ownProps,
    content: '',
  };
};

const mapDisptachToProps = dispatch => ({
  onLogout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDisptachToProps,
)(MobileOverlay);
