import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import Clear from 'material-ui/svg-icons/content/clear';
import FilterPopup from 'app/components/filterPopup';
import { withTranslation } from 'react-i18next';
import './styles.css';

class Search extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onClear: PropTypes.func,
    withFilterPopup: PropTypes.bool,
    filterItems: PropTypes.objectOf(PropTypes.bool),
    handleFilterReset: PropTypes.func,
    handleFilterConfirm: PropTypes.func,
    filterName: PropTypes.string,
    fullWidthHeader: PropTypes.bool,
    fullWidthHeader: PropTypes.bool,
  };

  static defaultProps = {
    onClear: null,
    placeholder: '',
    value: '',
    withFilterPopup: false,
    filterItems: [],
    handleFilterReset: null,
    handleFilterConfirm: null,
    filterName: '',
    fullWidthHeader: false,
  };

  state = {
    query: this.props.value,
    filterOpen: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ query: nextProps.value });
    }
  }

  handleChange = e => {
    this.props.onChange(e.target.value);
  };

  handleClear = () => {
    this.setState({ query: '' });
    this.props.onClear();
  };

  handleTogglePopup = () =>
    this.setState({ filterOpen: !this.state.filterOpen });

  render() {
    const { query, filterOpen } = this.state;
    const {
      placeholder,
      withFilterPopup,
      value,
      onClear,
      filterItems,
      handleFilterReset,
      handleFilterConfirm,
      filterName,
      t,
      fullWidthHeader,
    } = this.props;

    const hasFilteredItems = Object.values(filterItems).some(
      checked => checked === true,
    );

    return (
      <div className={`search-input-wrapper ${fullWidthHeader && 'top-0'}`}>
        <input
          type="text"
          className="search"
          value={query}
          name={this.props.name}
          placeholder={placeholder}
          onChange={this.handleChange}
        />
        <SearchIcon className="search-icon" />
        {withFilterPopup ? (
          <>
            <div
              className={`filter-button${
                value && onClear ? '-with-clear' : ''
              }`}
            >
              <IconButton
                onClick={this.handleTogglePopup}
                tooltip={t('Filter by industry')}
                className={hasFilteredItems ? 'filtered' : ''}
              >
                <FilterListIcon />
              </IconButton>
            </div>
            <FilterPopup
              open={filterOpen}
              items={filterItems}
              handleFilterClose={this.handleTogglePopup}
              handleFilterReset={handleFilterReset}
              handleFilterConfirm={handleFilterConfirm}
              name={filterName}
            />
          </>
        ) : null}
        {value && onClear && (
          <div className="clear-button">
            <IconButton onClick={this.handleClear} tooltip={t('Reset')}>
              <Clear />
            </IconButton>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(Search);
