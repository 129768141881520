import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from '@material-ui/icons';
import { Slide } from '@material-ui/core';
import styles from './styles.module.scss';

const ImageCarousel = ({ images }) => {
  const [activeImage, setActiveImage] = useState(0);
  const [direction, setDirection] = useState('right');
  const nextImage = () => {
    setDirection('right');

    const lastImageIndex = Object.keys(images).length - 1;

    if (activeImage === lastImageIndex) {
      setActiveImage(0);
    } else {
      setActiveImage(activeImage + 1);
    }
  };

  const prevImage = () => {
    setDirection('left');
    if (activeImage === 0) {
      const lastImageIndex = Object.keys(images).length - 1;
      setActiveImage(lastImageIndex);
    } else {
      setActiveImage(activeImage - 1);
    }
  };
  return (
    <div className={styles.container}>
      {images &&
        Object.keys(images).map((imageObj, key) => (
          <Slide
            in={activeImage === key}
            unmountOnExit
            mountOnEnter
            direction={direction}
          >
            <div className={styles.imageContainer}>
              <img src={images[imageObj].image} alt="" />
            </div>
          </Slide>
        ))}
      {images && Object.keys(images).length > 1 && (
        <>
          <button
            className={`${styles.forwardButton} ${styles.iconButton}`}
            onClick={nextImage}
            color="primary"
          >
            <ArrowForwardIosOutlined />
          </button>
          <button
            className={`${styles.backButton} ${styles.iconButton}`}
            onClick={prevImage}
            color="primary"
          >
            <ArrowBackIosOutlined />
          </button>
        </>
      )}
    </div>
  );
};

ImageCarousel.propTypes = {
  images: PropTypes.object,
};
export default ImageCarousel;
