import React from 'react';
import PropTypes from 'prop-types';

const userSubscribed = ({ content }) => (
  <div className="invalid-offer-overlay">
    <div>
      <img src={content.offerLogo} alt="dragons den logo" width={300} />
    </div>
    <div className="invalid-offer">
      <span>
        You have recently subscribed, unfortunately this means the offer is not
        valid.
      </span>
      <span>
        If you have any queries, please contact us at{' '}
        <strong>
          <a
            href="mailto:support@beezer.com?Subject=Dragons&#39;%20Den%20Offer"
            target="blank"
            className="support-email-address"
          >
            support@beezer.com
          </a>
        </strong>
      </span>
    </div>
  </div>
);

userSubscribed.propTypes = {
  content: PropTypes.shape({
    offerLogo: PropTypes.string.isRequired,
  }).isRequired,
};

export default userSubscribed;
