const initialState = {
  enabled: false,
};

const structure = (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVE_AUTH_STATUS': {
      return {
        ...state,
        enabled: action.value,
        type: action.authType,
      };
    }

    default:
      return state;
  }
};

export default structure;
