import React from 'react';
import { useQuery } from 'react-query';
import { getAllStaff } from '../../api';
import StaffMemberDialog from '../../components/staffMemberDialog';
import StaffTable from '../../components/staffTable/StaffTable';
const Staff = props => {
  const {
    isImageUploaded,
    addStaffDialogOpen,
    appname,
    staffMember,
    onAddStaffMemberDialog,
    onStaffMemberChange,
    onResetStaffData,
    isAppAdmin,
  } = props;
  const { data: allStaffData, error, isLoading, isError } = useQuery(
    ['staffMembers', { appname }],
    getAllStaff,
  );

  return (
    <div>
      <StaffTable
        appname={appname}
        onAddStaffMemberDialog={onAddStaffMemberDialog}
        onStaffMemberChange={onStaffMemberChange}
        allStaffData={allStaffData}
        error={error}
        isLoading={isLoading}
        isError={isError}
        isAppAdmin={isAppAdmin}
      />
      {addStaffDialogOpen && (
        <StaffMemberDialog
          appname={appname}
          open={addStaffDialogOpen}
          onAddStaffMemberDialog={onAddStaffMemberDialog}
          isImageUploaded={isImageUploaded}
          staffMember={staffMember}
          onStaffMemberChange={onStaffMemberChange}
          onResetStaffData={onResetStaffData}
          allStaffData={allStaffData}
        />
      )}
    </div>
  );
};

export default Staff;
