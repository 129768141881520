import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import Users from './Users';
import {
  getUsers,
  filterUserData,
  selectUser,
  filterData,
  selectAllUsers,
  deselectAllUsers,
  deleteSelected,
  openUserGroupDialog,
  closeUserGroupDialog,
  toggleSelectedGroup,
  saveUserGroupChanges,
  selectAllGroups,
  deselectAllGroups,
  approveScottishAppUser,
  getPageCountAnalytics,
  resetUsersData,
  verifyUserEmail,
  toogleUserDiaryPermission,
} from './actions';

const mapStateToProps = (state, ownProps) => {
  const defaultGroup = state.auth.groups.data.chunks.default;
  const verificationPage = get(defaultGroup, 'verification_page', null);

  return {
    ...ownProps,
    ...state.auth.users,
    ...state.account.profile,
    enabled: (verificationPage && verificationPage.enabled) || false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetUsersData: () => dispatch(resetUsersData()),
  getUsers: () => dispatch(getUsers(ownProps.match.params.appname)),
  onFilterUserData: filter => dispatch(filterUserData(filter)),
  onFilterData: (filteredChunks, sortedItems) =>
    dispatch(filterData(filteredChunks, sortedItems)),
  onSelect: key => dispatch(selectUser(key)),
  onSelectAllUsers: () => dispatch(selectAllUsers()),
  onDeselectAllUsers: () => dispatch(deselectAllUsers()),
  onSelectAllGroups: () => dispatch(selectAllGroups()),
  onDeselectAllGroups: () => dispatch(deselectAllGroups()),
  onOpenUserGroupDialog: key => dispatch(openUserGroupDialog(key)),
  toggleSelectedGroup: key => dispatch(toggleSelectedGroup(key)),
  onVerifyUser: userId =>
    dispatch(verifyUserEmail(ownProps.match.params.appname, userId)),
  onToogleUserDiaryPermission: (userId, value) =>
    dispatch(
      toogleUserDiaryPermission(ownProps.match.params.appname, userId, value),
    ),
  onApproveScottishAppUser: (uid, selectedUser, groups) =>
    dispatch(
      approveScottishAppUser(
        ownProps.match.params.appname,
        uid,
        selectedUser,
        groups,
      ),
    ),
  onSaveUserGroupChanges: (
    selectedUsers,
    selectedGroups,
    updateNotNeeded,
    chunks,
    groups,
  ) =>
    dispatch(
      saveUserGroupChanges(
        ownProps.match.params.appname,
        selectedUsers,
        selectedGroups,
        updateNotNeeded,
        chunks,
        groups,
      ),
    ),
  onDeleteSelected: (selected, chunks) =>
    dispatch(deleteSelected(ownProps.match.params.appname, selected, chunks)),
  onRequestClose: () => dispatch(closeUserGroupDialog()),
  onRemoveGroup: (selectedGroups, selectedUsers, chunks, groups) =>
    dispatch(
      saveUserGroupChanges(
        ownProps.match.params.appname,
        selectedUsers,
        selectedGroups,
        false,
        chunks,
        groups,
      ),
    ),
  getPageCountAnalytics: uid =>
    dispatch(getPageCountAnalytics(ownProps.match.params.appname, uid)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
