import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from 'material-ui/Checkbox';
import { useTranslation } from 'react-i18next';

import Search from '../../../../app/components/search';
import SelectField from '../../../../app/components/selectField';
import { objectToArray } from '../../../../utils';

const Header = ({
  error,
  pending,
  title,
  filter,
  onChange,
  selectAll,
  onSelectAllResponses,
  onDeselectAllResponses,
  actionMenu,
  rightAfterSearch,
}) => {
  const { t } = useTranslation();

  return (
    <div className="row middle-xs">
      <div className={rightAfterSearch ? 'col-xs-2' : 'col-xs-3'}>
        <span
          style={{
            fontFamily: 'Raleway, sans-serif',
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          {title}
        </span>
      </div>
      {rightAfterSearch && (
        <div className={rightAfterSearch ? 'col-xs-4' : 'col-xs-3'}>
          {rightAfterSearch}
        </div>
      )}
      <div className={rightAfterSearch ? 'col-xs-5' : 'col-xs-6'}>
        <Search
          name="search"
          placeholder={t('Search...')}
          value={filter}
          onChange={val => onChange(val)}
          onClear={() => onChange('')}
        />
      </div>
      <div className={rightAfterSearch ? 'col-xs-1' : 'col-xs-3'}>
        <div className="row table-buttons">
          {actionMenu && (
            <div className="check-all">
              <Checkbox
                checked={selectAll}
                onCheck={(e, isChecked) =>
                  isChecked ? onSelectAllResponses() : onDeselectAllResponses()
                }
                disabled={error && !pending}
              />
            </div>
          )}
          {actionMenu && <div className="actions">{actionMenu}</div>}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  error: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  title: PropTypes.element.isRequired,
  filter: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectAll: PropTypes.bool,
  onSelectAllResponses: PropTypes.func.isRequired,
  onDeselectAllResponses: PropTypes.func.isRequired,
  actionMenu: PropTypes.element,
  rightAfterSearch: PropTypes.node,
};

Header.defaultProps = {
  actionMenu: null,
  error: '',
  selectAll: false,
  filter: '',
  rightAfterSearch: false,
};

export default Header;
