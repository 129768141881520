import React from 'react';

const Pencil = () => (
  <svg
    className="icon pencil"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12.75 48.51"
  >
    <title>Design</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M11.56,3.75V2a2,2,0,0,0-2-2H3.31a2,2,0,0,0-2,2V3.75H0V39H1l5.44,9.51,2.72-4.76h0L11.87,39h.88V3.75ZM2.81,2a.5.5,0,0,1,.5-.5H9.57a.49.49,0,0,1,.49.5V3.75H2.81ZM6.44,45.49l-.14-.24h.28Zm1-1.74h-2L2.73,39h7.42Zm3.82-6.25H4.06V10.12H2.56V37.5H1.5V9h9.75ZM1.5,7.5V5.25h9.75V7.5Z"
        />
      </g>
    </g>
  </svg>
);

export default Pencil;
