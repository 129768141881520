import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateMarketplaceSettings } from './actions';
import Marketplace from './Marketplace';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  profile: { ...state.account.profile },
  appname: ownProps.match.params.appname,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateMarketplaceSettings: (name, data) =>
    dispatch(
      updateMarketplaceSettings(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        name,
        data,
      ),
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Marketplace),
);
