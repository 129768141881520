import moment from 'moment';
import { validateField } from '../../validators';
import firebase from '../../../../../../firebase';
import { openSnackbar } from '../../../../../../app/modules/snackbar/actions';
import { EVENT_CREATED, MEMBERBODY_REQUIRED } from '../../config';
import { objectToArray } from '../../../../../../utils';

export const NEW_FIELD_CHANGE_EVENT = 'NEW_FIELD_CHANGE_EVENT';
export const OPEN_NEW_DIALOG_EVENT = 'OPEN_NEW_DIALOG_EVENT';
export const CLOSE_NEW_DIALOG_EVENT = 'CLOSE_NEW_DIALOG_EVENT';
export const ADD_NEW_EVENT = 'ADD_NEW_EVENT';
export const ADDED_EVENT = 'ADDED_EVENT';
export const SET_RECURRING_DATA = 'SET_RECURRING_DATA';

const newFieldChange = (prop, id, value, isValid) => ({
  type: NEW_FIELD_CHANGE_EVENT,
  prop,
  id,
  value,
  isValid,
});

export const setRecurringData = (id, value) => ({
  type: SET_RECURRING_DATA,
  id,
  value,
});

export const openNewEventDialog = () => (dispatch, getState) => {
  const { memberbody } = getState().staff.events.list;
  if (memberbody) {
    dispatch({
      type: OPEN_NEW_DIALOG_EVENT,
    });
  } else {
    dispatch(openSnackbar(MEMBERBODY_REQUIRED));
  }
};

export const closeNewEventDialog = () => ({
  type: CLOSE_NEW_DIALOG_EVENT,
});

export const formFieldChange = (isRequired, prop, id, value, index) => (
  dispatch,
  getState,
) => {
  let data = value;
  const valid = data ? validateField[prop](data) : null;
  const isValid = isRequired ? valid : true;
  // merge dates to array [fromDate, toDate]
  if (prop === 'DateRange' || prop === 'Daterange') {
    const dateRange = getState().staff.events.create.fieldsData[id].value;
    if (index === 0) {
      data = dateRange[1] ? [value, dateRange[1]] : [value, value];
    } else {
      data = dateRange[0] ? [dateRange[0], value] : [value, value];
    }
  }
  return dispatch(newFieldChange(prop, id, data, isValid));
};

export const addEvent = appname => (dispatch, getState) => {
  const {
    fieldsData,
    isRecurringEvent,
    recurringType,
    recurringValue,
  } = getState().staff.events.create;
  const { memberbody, fields } = getState().staff.events.list;

  fieldsData.createdAt = Date.now();
  fieldsData.memberbody = memberbody;
  dispatch({ type: ADD_NEW_EVENT });
  if (isRecurringEvent) {
    const allFields = objectToArray(fields);

    const dateRangeField = allFields.find(
      item => item.value.type === 'DateRange',
    );

    const { id } = dateRangeField;
    const eventDate = fieldsData[id].value[0];
    for (let i = 0; i < parseInt(recurringValue, 10); i += 1) {
      const newDate = moment(eventDate)
        .add(i, recurringType)
        .valueOf();
      fieldsData[id].value[0] = newDate;
      fieldsData[id].value[1] = newDate;
      fieldsData.isRecurringEvent = isRecurringEvent;
      fieldsData.recurringType = recurringType;
      fieldsData.recurringValue = recurringValue;
      const ref = firebase
        .database()
        .ref(`app_events/${appname}/events/${memberbody}`);
      const newRef = ref.push(fieldsData);
      dispatch({
        type: ADDED_EVENT,
        key: newRef.key,
        data: fieldsData,
      });
    }

    dispatch(openSnackbar(EVENT_CREATED));
  } else {
    return firebase
      .database()
      .ref(`app_events/${appname}/events/${memberbody}`)
      .push(fieldsData)
      .then(ref => {
        dispatch({
          type: ADDED_EVENT,
          key: ref.key,
          data: fieldsData,
        });
        dispatch(openSnackbar(EVENT_CREATED));
      });
  }
};
