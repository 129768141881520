import React from 'react';
import { Link } from 'react-router-dom';
import Toggle from 'material-ui/Toggle';
import TextField from 'app/components/textField';
import { Trans, useTranslation } from 'react-i18next';

import ColorPicker from '../../../../../components/colorPicker';
import DesignToggle from '../../../../../components/designToggle';

import { Tabs, Tab } from '../../../../../components/tabs';
import SectionHeader from '../../../../../components/sectionHeader';
import Container from '../../../../../../../app/components/container';
import themeStyles from '../../../../../../../_export.scss';

// props.match.params.appname

const subTitleStyles = {
  margin: 0,
  color: 'rgba(40,31,214,1)',
  fontWeight: 600,
  fontSize: '18px',
  fontFamily: 'Raleway',
  textAlign: 'left',
  width: '100%',
  paddingLeft: '24px',
};

const Blog = props => {
  const {
    data: { titleEnabled, titleText },
    onChange,
    onDesignChange,
    component,
    design,
  } = props;
  const { t } = useTranslation();

  const handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    onDesignChange(prop, rgba);
  };

  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container>
          <SectionHeader
            title={t(`Title`)}
            className="section-header"
            actions={
              <Toggle
                toggled={titleEnabled}
                onToggle={(e, isToggled) => onChange('titleEnabled', isToggled)}
                trackSwitchedStyle={{
                  backgroundColor: themeStyles.primaryColorLight,
                }}
                thumbSwitchedStyle={{
                  backgroundColor: themeStyles.primaryColor,
                }}
              />
            }
          />
          <TextField
            value={titleText}
            onChange={value => onChange('titleText', value)}
            // placeholder="Blogs"
            disabled={!titleEnabled}
          />
          <br />
          <br />
          <p
            style={{
              padding: '0px 18px',
              fontFamily: 'Raleway, sans-serif',
              fontSize: '14px',
            }}
          >
            <Trans>Edit Blog posts in Engage</Trans> -&gt;{' '}
            <Link to={`/${props.match.params.appname}/engage/blog`}>
              <Trans>Blog</Trans>
            </Link>
          </p>
        </Container>
      </Tab>
      <Tab label={t('design')}>
        <Container>
          <DesignToggle
            component={component}
            onDesignChange={(el, isToggled) => onDesignChange(el, isToggled)}
          />

          <ColorPicker
            label={t('Background Color')}
            color={design.backgroundColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('backgroundColor')}
          />
          <ColorPicker
            label={t('Title Color')}
            color={design.titleColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('titleColor')}
          />

          <h6 style={{ ...subTitleStyles }}>
            <Trans>Categories</Trans>
          </h6>
          <ColorPicker
            label={t('Text Color')}
            color={design.categoryTextColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('categoryTextColor')}
          />
          <ColorPicker
            label={t('Background Color')}
            color={design.categoryBackgroundColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('categoryBackgroundColor')}
          />
          <ColorPicker
            label={t('Selected Text Color')}
            color={design.categorySelectedTextColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('categorySelectedTextColor')}
          />
          <ColorPicker
            label={t('Selected Background Color')}
            color={design.categorySelectedBackgroundColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('categorySelectedBackgroundColor')}
          />
          <h6 style={{ ...subTitleStyles }}>
            <Trans>Article Title</Trans>
          </h6>
          <ColorPicker
            label={t('Artice Title Color')}
            color={design.blogTitleColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('blogTitleColor')}
          />
          <h6 style={{ ...subTitleStyles }}>
            <Trans>Button</Trans>{' '}
          </h6>
          <ColorPicker
            label={t('Button Text Color')}
            color={design.buttonTextColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('buttonTextColor')}
          />
          <ColorPicker
            label={t('Button Background Color')}
            color={design.buttonBackgroundColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('buttonBackgroundColor')}
          />
          <ColorPicker
            label={t('Button Border Color')}
            color={design.buttonBorderColor}
            height={22}
            disabled={!component.design.enabled}
            onChange={handleColorChange('buttonBorderColor')}
          />
        </Container>
      </Tab>
      <Tab
        label={t('layout')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
    </Tabs>
  );
};

export default Blog;
