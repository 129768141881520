import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  togglePerformanceModal,
  formFieldUniqueCheck,
  formFieldChange,
  multipleValueFieldChange,
  addRegionPerformanceCentre,
  getRegionalPerformanceCentre,
  selectPerformanceCentre,
  selectAllPerformanceCentres,
  deselectAllPerformanceCentres,
  deletePerformanceCentres,
  filterPerformanceCentreData,
  updateRegionalPerformanceCentre,
  fetchRegionalCentreImages,
  servicesInfoFieldChange,
  toggleRegionalSnackbar,
  deleteRegionalPerformanceCentreImage,
} from './actions';
import { uploadMedia } from '../../../editor/modules/build/modules/componentInterface/media/actions';
import RegionalPerformanceCentre from './RegionalPerformanceCentre';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  regionalPerformanceCentreData: state.pgmol.regionalPerformanceCentre,
});

const mapDisptachToProps = (dispatch, ownProps) => ({
  togglePerformanceModal: value => dispatch(togglePerformanceModal(value)),
  onFieldChangeWithUniqueCheck: (field, value, filteredChunks) =>
    dispatch(formFieldUniqueCheck(field, value, filteredChunks)),
  onFieldChange: (field, value) => dispatch(formFieldChange(field, value)),
  onMultipleValueFieldChange: (field, value) =>
    dispatch(multipleValueFieldChange(field, value)),

  onSubmit: () =>
    dispatch(addRegionPerformanceCentre(ownProps.match.params.appname)),
  getRegionalPerformanceCentre: () =>
    dispatch(getRegionalPerformanceCentre(ownProps.match.params.appname)),

  onSelect: key => dispatch(selectPerformanceCentre(key)),
  onSelectAllPerformanceCentres: () => dispatch(selectAllPerformanceCentres()),
  onDeselectAllPerformanceCentres: () =>
    dispatch(deselectAllPerformanceCentres()),
  onDeleteSelected: rowId =>
    dispatch(deletePerformanceCentres(ownProps.match.params.appname, rowId)),
  onFilterPerformanceCentreData: filter =>
    dispatch(filterPerformanceCentreData(filter)),
  onUpdateRegionalPerformanceCentre: (rowId, data) =>
    dispatch(updateRegionalPerformanceCentre(rowId, data)),
  fetchRegionalCentreImages: dbRef =>
    dispatch(fetchRegionalCentreImages(dbRef)),
  onServicesInfoFieldChange: (type, field, value) =>
    dispatch(servicesInfoFieldChange(type, field, value)),
  uploadMedia: (media, firebaseUrl) =>
    dispatch(uploadMedia(media, firebaseUrl)),
  onToggleRegionalSnackbar: () => dispatch(toggleRegionalSnackbar()),

  onDeleteImage: (src, appname, rowId, index) =>
    dispatch(deleteRegionalPerformanceCentreImage(src, appname, rowId, index)),
});

export default withRouter(
  connect(mapStateToProps, mapDisptachToProps)(RegionalPerformanceCentre),
);
