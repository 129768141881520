import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';

import Events from './Events';

import { listenForEvent, removeEventListener, clearEvents } from './actions';

const mapStateToProps = (state, ownProps) => {
  const eventState = state.analytics.events[ownProps.match.params.appname];

  if (!eventState) {
    return {
      events: [],
      plans: state.account.subscriptions.items,
      integrationType: _get(state, 'account.organisation.integration.type'),
      appname: ownProps.match.params.appname,
    };
  }

  return {
    events: eventState.events,
    plans: state.account.subscriptions.items,
    integrationType: _get(state, 'account.organisation.integration.type'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setListener: type =>
    dispatch(listenForEvent(ownProps.match.params.appname, type)),
  removeListener: type =>
    dispatch(removeEventListener(ownProps.match.params.appname, type)),
  clearEvents: () => dispatch(clearEvents()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Events),
);
