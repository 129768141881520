import i18n from 'i18next';
import firebase from '../../../../../../../firebase';

const addingRosterItem = lazyDataId => ({
  type: 'ADDING_ROSTER_ITEM',
  key: lazyDataId,
});

const reportErr = (lazyDataId, err) => ({
  type: 'ROSTER_ITEM_ERROR',
  key: lazyDataId,
  err,
});

const rearrangeRosterItem = (id, siblingId) => ({
  type: 'REARRANGE_ROSTER_ITEM',
  id,
  siblingId,
});

export const addRosterItem = (appname, dataId, title) => dispatch => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);
  const baseItem = {
    avatar:
      'https://beezer-img-storage.s3.amazonaws.com/UploadImageForUser.png',
    subtitle: i18n.t('Subtitle'),
    company: i18n.t('Company'),
    text: `<p class="ql-align-center">${i18n.t('Description')}</p>`,
    linkedin: 'https://www.linkedin.com',
    twitter: 'https://www.twitter.com',
    title,
  };

  dispatch(addingRosterItem(dataId));

  const itemRef = dataRef.child('items/chunks').push(baseItem);

  return dataRef
    .child('items/order')
    .transaction(items => {
      if (!items) return [itemRef.key];

      return items.concat(itemRef.key);
    })
    .catch(err => dispatch(reportErr(err)));
};

const findIndex = (array, element) => {
  if (element === null) {
    return array.length;
  }

  return array.indexOf(element);
};

const moveArray = (oldIndex, newIndex, arr) => {
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const rearrangeItems = (
  appname,
  lazydata,
  id,
  siblingId,
) => dispatch => {
  const itemsRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${lazydata}/items/order`);

  itemsRef
    .transaction(array => {
      if (array === null) return null;

      const oIndex = findIndex(array, id);
      let nIndex = findIndex(array, siblingId);

      if (nIndex > oIndex) {
        nIndex -= 1;
      }

      return moveArray(oIndex, nIndex, array);
    })
    .then(dispatch(rearrangeRosterItem(id, siblingId)));
};

export const designChange = (appname, dataId, field, value) => {
  console.log(field);
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${dataId}/design`);

  dataRef.child(field).set(value);

  return {
    type: 'BOX_FIELD_CHANGE',
    appname,
    dataId,
    field,
    value,
  };
};
