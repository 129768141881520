import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import { withTranslation } from 'react-i18next';
import { stringToKey } from '../../../../../../utils/string';
import Sidebar, { SidebarItem } from '../../../../../../app/components/sidebar';
import {
  AppDomain,
  AppIcon,
  AppMenu,
  // StarredPages,
  SplashScreen,
} from '../../../../components/icons';

const customiseNavigationOpts = [
  {
    label: 'App Name',
    icon: <AppDomain />,
  },
  {
    label: 'App Icon',
    icon: <AppIcon />,
  },
  // {
  //   label: 'Install Screen',
  //   icon: <Pencil />
  // },
  {
    label: 'App Splash Screen',
    icon: <SplashScreen />,
  },
  {
    label: 'Navigation Menu',
    icon: <AppMenu />,
  },
  // {
  //   label: 'Starred Page',
  //   icon: <StarredPages />
  // }
];

class CustomiseMenu extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    childRoutes: [],
  };

  isActive = pathname => this.props.location.pathname.indexOf(pathname) !== -1;

  renderNavigationsItems = () => {
    const { appname } = this.props.match.params;

    return _map(customiseNavigationOpts, item => {
      const key = stringToKey(item.label);
      const { t } = this.props;
      return (
        <SidebarItem
          key={key}
          label={t(item.label)}
          href={`/${appname}/editor/customise/${key}`}
          active={this.isActive(key)}
          icon={item.icon}
        />
      );
    });
  };

  render() {
    return <Sidebar animate> {this.renderNavigationsItems()} </Sidebar>;
  }
}

export default withTranslation()(CustomiseMenu);
