import React, { useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'material-ui/Toggle';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { useTranslation } from 'react-i18next';

import firebase from '../../../../../../../firebase';
import TextField from '../../../../../../../app/components/textField';
import Container from '../../../../../../../app/components/container';
import { Tabs, Tab } from '../../../../../components/tabs';
import TooltipElement from '../../../../../../../app/components/tooltipElement/TooltipElement';
import SectionHeader from '../../../../../components/sectionHeader';

import style from './livestream.module.scss';

const styles = {
  toggle: {
    marginTop: 24,
    marginBottom: 12,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 6,
    width: '90%',
  },
  toggleLabel: {
    fontSize: 14,
  },
  tooltip: {
    right: 18,
    top: 7,
    position: 'relative',
  },
};

const Livestream = props => {
  const {
    data,
    onChange,
    onChangeLoop,
    onChangeMuted,
    onChangeAutoPlay,
    onChangeComments,
    onChangeCommentsAuth,
    onChangeCommentsBanBadWords,
    onUrlTypeChange,
    onIframeUrlChange,
    appname,
  } = props;

  const [memberLoginEnabled, setMemberLoginEnabled] = useState(false);
  const [urlType, setUrlType] = useState('url');
  const [urlDone, setUrlDone] = useState(false);
  const [hasUpdated, setHasUpdated] = useState(false);
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (!urlDone) {
      if (props.data.urlType) {
        setUrlType(props.data.urlType);
      }
      setUrlDone(true);
    }
  }, [props.data.urlType]);

  useEffect(() => {
    firebase
      .database()
      .ref(`apps/${appname}/draft/always_data/auth`)
      .on('value', snap => {
        if (snap.exists()) {
          setMemberLoginEnabled(true);
        }
      });
  }, []);

  const handleToggleLoop = prop => (e, isToggled) =>
    onChangeLoop(prop, isToggled);
  const handleToggleMuted = prop => (e, isToggled) =>
    onChangeMuted(prop, isToggled);
  const handleToggleAutoPlay = prop => (e, isToggled) =>
    onChangeAutoPlay(prop, isToggled);
  const handleToggleComments = prop => (e, isToggled) =>
    onChangeComments(prop, isToggled);
  const handleToggleCommentsAuth = prop => (e, isToggled) =>
    onChangeCommentsAuth(prop, isToggled);
  const handleToggleBanBadWords = prop => (e, isToggled) =>
    onChangeCommentsBanBadWords(prop, isToggled);

  const handleSelectChange = e => {
    setUrlType(e.target.value);
    onUrlTypeChange(e.target.value);
  };

  return (
    <Tabs>
      <Tab label={t('content')}>
        <Container>
          <SectionHeader title={t('Enter Url or iFrame link')} />

          <div className="button-link-container">
            <RadioButtonGroup
              className="radio-wrapper"
              valueSelected={urlType}
              onChange={handleSelectChange}
            >
              <RadioButton id="url" value="url" />
              <RadioButton id="frame" value="iframe" />
            </RadioButtonGroup>
            <div className="link-radio-option-wrapper">
              <TextField
                placeholder={t('URL of the video')}
                value={data.rawUrl}
                onChange={onChange}
                disabled={urlType !== 'url'}
              />
              <TextField
                placeholder={t('iFrame of the video')}
                value={data.iframeUrl}
                onChange={onIframeUrlChange}
                disabled={urlType !== 'iframe'}
              />
            </div>
          </div>
        </Container>

        <Container style={{ display: 'flex', flexDirection: 'row' }}>
          <Toggle
            label={t('Loop')}
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.loop}
            onToggle={handleToggleLoop(data.loop)}
            disabled={data.urlType === 'iframe'}
          />
          <TooltipElement
            className={style.tooltip}
            tooltipType={data.urlType === 'iframe' ? 'warning' : 'info'}
            tooltip={
              data.urlType === 'iframe'
                ? t('This feature does not work with iFrame')
                : t('Allows the video to play in a repeat mode.')
            }
            verticalPosition="top"
            horizontalPosition="left"
          />
        </Container>

        <Container style={{ display: 'flex', flexDirection: 'row' }}>
          <Toggle
            label={t('Muted')}
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.muted}
            onToggle={handleToggleMuted(data.muted)}
            disabled={data.urlType === 'iframe'}
          />

          <TooltipElement
            className={style.tooltip}
            tooltipType={data.urlType === 'iframe' ? 'warning' : 'info'}
            tooltip={
              data.urlType === 'iframe'
                ? t('This feature does not work with iFrame')
                : t('Audio output of the video when played will be muted.')
            }
            verticalPosition="top"
            horizontalPosition="left"
          />
        </Container>

        <Container style={{ display: 'flex', flexDirection: 'row' }}>
          <Toggle
            label={t('Autoplay')}
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.autoPlay}
            onToggle={handleToggleAutoPlay(data.autoPlay)}
            disabled={data.urlType === 'iframe'}
          />

          <TooltipElement
            className={style.tooltip}
            tooltipType={data.urlType === 'iframe' ? 'warning' : 'info'}
            tooltip={
              data.urlType === 'iframe'
                ? t('This feature does not work with iFrame')
                : t('Video starts automatically when a user visits your app.')
            }
            verticalPosition="top"
            horizontalPosition="left"
          />
        </Container>

        <Container style={{ display: 'flex', flexDirection: 'row' }}>
          <Toggle
            label={t('Allow Comments')}
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.comments}
            onToggle={handleToggleComments(data.comments)}
          />

          <TooltipElement
            className={style.tooltip}
            tooltip={t('Allow users to comment.')}
            verticalPosition="top"
            horizontalPosition="left"
          />
        </Container>

        <Container style={{ display: 'flex', flexDirection: 'row' }}>
          <Toggle
            label={t('Only Signed In Users Can Comment')}
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.commentsAuth}
            onToggle={handleToggleCommentsAuth(data.commentsAuth)}
            disabled={!data.comments || !memberLoginEnabled}
          />

          <TooltipElement
            className={style.tooltip}
            tooltip={
              // eslint-disable-next-line no-nested-ternary
              !data.comments
                ? t('Enable comments to use this feature.')
                : !memberLoginEnabled
                ? t('Enable member login to use this feature.')
                : t('This mode will give you the ability to moderate comments.')
            }
            tooltipType={
              !data.comments || !memberLoginEnabled ? 'warning' : 'info'
            }
            verticalPosition="top"
            horizontalPosition="left"
          />
        </Container>

        <Container style={{ display: 'flex', flexDirection: 'row' }}>
          <Toggle
            label={t('Ban Bad Words')}
            style={styles.toggle}
            labelStyle={styles.toggleLabel}
            toggled={data.banBadWords}
            onToggle={handleToggleBanBadWords(data.banBadWords)}
            disabled={!data.comments}
          />

          <TooltipElement
            className={style.tooltip}
            tooltip={
              !data.comments
                ? t('Enable comments to use this feature.')
                : t('Censors common bad words in the chat.')
            }
            tooltipType={!data.comments ? 'warning' : 'info'}
            verticalPosition="top"
            horizontalPosition="left"
          />
        </Container>
      </Tab>
      <Tab
        label={t('design')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
      <Tab
        label={t('layout')}
        disabled
        style={{
          color: '#d9d9d9',
        }}
      />
    </Tabs>
  );
};

Livestream.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeLoop: PropTypes.func.isRequired,
  onChangeMuted: PropTypes.func.isRequired,
  onChangeAutoPlay: PropTypes.func.isRequired,
  onChangeComments: PropTypes.func.isRequired,
  onChangeCommentsAuth: PropTypes.func.isRequired,
  onChangeCommentsBanBadWords: PropTypes.func.isRequired,
  onUrlTypeChange: PropTypes.func.isRequired,
  appname: PropTypes.string.isRequired,
};

export default Livestream;
