import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;

  .disabled-section {
    position: relative;
    z-index: 1;

    > div > div:first-child {
      opacity: 0.3;
    }

    > div > div:last-child {
      background: #f9f9f9;

      > * {
        opacity: 0.3;
      }
    }
  }

  .disabled-message {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 2;

    div {
      background: rgba(255, 255, 255, 0.9);
      border: 1px solid #d4d4d4;
      border-radius: 20px;
      max-width: 400px;
    }
  }
`;

const DisabledWrapper = ({ children, message, title }) => (
  <Wrapper>
    <div className="disabled-section">{children}</div>
    <div className="disabled-message">
      <div>
        <h3>{title}</h3>
        <p style={{ padding: '10px' }}>{message}</p>
      </div>
    </div>
  </Wrapper>
);

DisabledWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default DisabledWrapper;
